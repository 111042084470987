import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import {
  DropdownYears,
  EmptyFolderSvg,
  GridBody,
  MainPageGrid,
  PlusIcon,
  SkeletonRows,
} from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import Header from '../../../components/ui/Header';
import { useFrequencies } from '../../../context/frequencies/WithFrequencies';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { FrequenciesHeader, FrequencyRow } from './SchoolPaymentFrequenciesGrid';

export const PAGE_SIZE = 5;
export const SKELETON_COLUMNS = 7;

export const SchoolPaymentFrequenciesContent: FC = () => {
  const { $t } = useIntl();
  const { schoolYears } = useSchoolYears();
  const {
    currentSchoolYear,
    sortedFrequencies,
    canEdit,
    isLoading,
    updateFrequency,
    updatingFrequency,
  } = useFrequencies();

  const navigate = useNavigate();

  const emptyStub = (
    <Stack alignItems="center" pt={5.5} pb={2.5}>
      <Box sx={{ mb: 5.5, '& svg': { width: 250, height: 250 } }}>
        <EmptyFolderSvg />
      </Box>

      <Typography textAlign="center" variant="h3" color="text.primary" mb={3}>
        {$t({ id: 'frequencies-NoFrequenciesForThisYear' })}
      </Typography>

      {canEdit && (
        <Button
          startIcon={<PlusIcon />}
          // TODO TR-6100 Add navigation, to be implemented later
          onClick={() => {}}
        >
          {$t({ id: 'frequencies-SetFrequencies' })}
        </Button>
      )}
    </Stack>
  );

  const renderContent = () => {
    if (!isLoading && !sortedFrequencies?.length) {
      return emptyStub;
    }

    return (
      <MainPageGrid>
        <FrequenciesHeader />
        <GridBody
          sx={(theme) => ({
            ' .GridRow .MuiTypography-root': {
              ...theme.typography.h3,
            },
          })}
        >
          {isLoading ? (
            <SkeletonRows columnsCount={SKELETON_COLUMNS} amount={PAGE_SIZE} />
          ) : (
            sortedFrequencies?.map((frequency) => (
              <FrequencyRow
                frequency={frequency}
                key={frequency.id}
                canEdit={canEdit}
                onToggleSwitch={updateFrequency}
                updating={updatingFrequency}
              />
            ))
          )}
        </GridBody>
      </MainPageGrid>
    );
  };

  return (
    <>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="section-FrequenciesAndDates" />
      </Box>

      <Card>
        <CardHeader
          title={
            <Typography variant="h2">
              {$t({ id: 'frequencies-AutomaticFrequencyBilling' })}
            </Typography>
          }
          action={
            <DropdownYears
              years={schoolYears}
              defaultYear={currentSchoolYear}
              currentYear={currentSchoolYear}
              onYearChange={({ id }) => navigate(`/settings/frequencies/year/${id}`)}
              //TR-6100 Temporary disabled. In future user should be able to select year
              disabled
            />
          }
        />
        <CardContent>{renderContent()}</CardContent>
      </Card>
    </>
  );
};
