import { Box, Checkbox, Stack, StackProps, Typography } from '@mui/material';
import { FilterValue, SelectOption } from '@schooly/api';
import { RadioOnIcon, RadioOnIconNegative, RadioOnIconPositive } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { renderError } from 'apps/web/src/components/ui/Input/helpers';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BaseFormInputProps, useFormInput2 } from 'apps/web/src/components/ui/Input/utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FormCheckboxStyled } from 'apps/web/src/components/uikit-components/FormCheckbox/FormCheckbox.styled';
import React, { useCallback } from 'react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

type PickedInputProps = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue'>;

export interface ControlRadioGroupProps<T>
  extends Omit<ControllerProps<FieldValues>, 'name' | 'render' | 'defaultValue'>,
    BaseFormInputProps,
    PickedInputProps {
  options: SelectOption<T>[];
  label?: React.ReactNode;
  withBorder?: boolean;
  withBackground?: boolean;
  spaceBetween?: number;
  onChange?: (value: T) => void;
  containerProps?: Omit<StackProps, 'children'>;
}

export const ControlRadioGroup = <T extends any = FilterValue>({
  options,
  label,
  withBorder = true,
  spaceBetween,
  withBackground,
  containerProps,
  ...props
}: ControlRadioGroupProps<T>) => {
  const { control, error, fullName, value } = useFormInput2(props);

  const handleClick = useCallback(
    (onChange: (val?: T) => void, optionValue: T) => () => {
      props.onChange?.(optionValue);
      onChange(optionValue);
    },
    [props],
  );

  return (
    <Controller
      {...props}
      control={control}
      name={fullName}
      render={({ field }) => (
        <Stack gap={1}>
          {label && <Typography variant="h3">{label}</Typography>}
          <Stack
            direction="row"
            alignItems="center"
            position="relative"
            gap={spaceBetween ?? 1.5}
            {...containerProps}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                pointerEvents: 'none',
                opacity: 0,
              }}
            >
              <input name={field.name} />
            </Box>
            {options.map((option) => {
              const CheckedIcon = option.checkColor
                ? option.checkColor === 'POSITIVE'
                  ? RadioOnIconPositive
                  : RadioOnIconNegative
                : RadioOnIcon;

              return (
                <FormCheckboxStyled
                  key={`${option.value}`}
                  control={
                    <Checkbox
                      disabled={option.disabled}
                      name={field.name}
                      checked={`${option.value}` === String(value)}
                      onChange={handleClick(field.onChange, option.value)}
                      checkedIcon={<CheckedIcon className="reset-svg-currentColor" />}
                    />
                  }
                  label={
                    option.labelTextId ? <FormattedMessage id={option.labelTextId} /> : option.label
                  }
                  error={error}
                  withBorder={withBorder}
                  withBackground={withBackground}
                />
              );
            })}
            {renderError(error)}
          </Stack>
        </Stack>
      )}
    />
  );
};
