import { Box, Stack, Typography } from '@mui/material';
import {
  IColumnSort,
  Report,
  SchoolYear,
  SORT_DIRECTION,
  useGetReportsForRelationQuery,
} from '@schooly/api';
import { Loading, ModalSearch } from '@schooly/style';
import { DropdownYears } from '@schooly/style';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { ListViewReports } from '../../../components/uikit-components/ListViewReports/ListViewReports';
import { useProfile } from '../../../context/profile/useProfile';
import useSchoolYears from '../../../hooks/useSchoolYears';

const defaultSort = [
  { columnTextId: 'report_date', direction: SORT_DIRECTION.DESC },
  { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
] as IColumnSort<keyof Report | 'report_date'>[];

export const ProfileModalReports: FC = () => {
  const { formatMessage } = useIntl();
  const { schoolMembership } = useProfile();
  const { isLoading: schoolYearsFetching, defaultValidity, schoolYears } = useSchoolYears();
  const [schoolYear, setSchoolYear] = useState<SchoolYear | undefined>(defaultValidity);
  const [filter, setFilter] = useState('');

  const { data: reports } = useGetReportsForRelationQuery(
    {
      relationId: schoolMembership?.relation_id || '',
      dateFrom: schoolYear?.start,
      dateTo: schoolYear?.end,
      query: filter,
      sort: defaultSort,
    },
    { enabled: !!schoolMembership, refetchOnMount: 'always' },
  );

  return (
    <Box className="section section-wrapper">
      <Stack direction="row" justifyContent="space-between" mb={2.5} alignItems="center" mt={-0.75}>
        <Typography variant="h2">
          <FormattedMessage id="section-Reports" />
        </Typography>
        <Stack direction="row" alignItems="center" gap={2}>
          <ModalSearch
            value={filter}
            onChange_MemoizedCallbackOnly={setFilter}
            withDebounce
            placeholder={formatMessage({ id: 'people-Search' })}
          />
          <DropdownYears
            years={schoolYears}
            defaultYear={defaultValidity}
            currentYear={schoolYear}
            onYearChange={setSchoolYear}
          />
        </Stack>
      </Stack>

      {!reports || schoolYearsFetching ? (
        <Loading />
      ) : (
        <Stack
          gap={2.5}
          height="100%"
          sx={(theme) => ({
            overflowY: 'scroll',
            [theme.breakpoints.down('md')]: {
              marginRight: -2.5,
            },
          })}
        >
          {!reports?.length ? (
            <NoSearchResultsFound type="small" />
          ) : (
            <ListViewReports reports={reports} id={schoolMembership?.relation_id!} />
          )}
        </Stack>
      )}
    </Box>
  );
};
