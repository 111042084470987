import { IconButton, IconButtonProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FC } from 'react';

import { CheckboxSquareFilledIcon, CheckboxSquareIcon } from '../assets/assets';

export const CheckboxSquareIconButton: FC<{ isActive?: boolean } & IconButtonProps> = ({
  isActive,
  sx,
  ...rest
}) => (
  <IconButton
    {...rest}
    sx={(theme) => ({
      '&:hover': {
        '.icon-inactive #rect': {
          stroke: theme.palette.primary.main,
        },
      },
      ...((typeof sx === 'function' ? sx(theme) : sx) as SystemStyleObject),
    })}
  >
    {isActive ? (
      <CheckboxSquareFilledIcon className="reset-svg-currentColor" />
    ) : (
      <CheckboxSquareIcon className="reset-svg-currentColor icon-inactive" />
    )}
  </IconButton>
);
