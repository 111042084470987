import { Box, Stack, Typography } from '@mui/material';
import { ArchiveIcon, TagSelect } from '@schooly/style';
import { FC } from 'react';

export type ProfileModalEmploymentCaseRowDetailsRowProps = {
  title: string;
  values: { name: string; archived?: boolean }[];
};

export const ProfileModalEmploymentCaseRowDetailsRow: FC<ProfileModalEmploymentCaseRowDetailsRowProps> =
  ({ title, values }) => (
    <Stack flexDirection="row" alignItems="center">
      <Box sx={{ minWidth: 80 }}>
        <Typography>{title}</Typography>
      </Box>
      <Box sx={{ lineHeight: 2 }}>
        {values.map(({ name, archived }, i) => (
          <>
            <TagSelect
              size="small"
              sx={{ backgroundColor: 'white' }}
              label={name}
              key={name}
              icon={archived ? <ArchiveIcon /> : undefined}
            />
            {i < values.length - 1 ? ', ' : ''}
          </>
        ))}
      </Box>
    </Stack>
  );
