import { FC } from 'react';

export const EmptySchoolSvg: FC = () => (
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M111.777 161.237C111.777 162.056 112.277 162.793 113.039 163.096L277.027 228.161V109.767C277.027 108.947 276.527 108.211 275.765 107.908L172.854 67.0648C172.384 66.8784 172.002 66.5212 171.785 66.065L162.012 45.52C161.795 45.0638 161.414 44.7067 160.944 44.5203L114.515 26.087C113.201 25.5657 111.777 26.5332 111.777 27.9459V161.237Z"
      fill="#24275B"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M93.6904 207.882L258.894 273.448V121.803L93.6904 56.2375V207.882Z"
      fill="white"
      stroke="#E7E9F4"
      strokeMiterlimit="10"
    />
    <path
      d="M93.6904 174.631C93.6904 175.451 94.1907 176.187 94.9527 176.49L258.894 241.555V123.162C258.894 122.342 258.393 121.605 257.631 121.302L154.768 80.5056C154.298 80.3193 153.916 79.962 153.699 79.5057L143.926 58.9609C143.709 58.5047 143.328 58.1476 142.858 57.9612L96.4284 39.5279C95.1154 39.0066 93.6904 39.9741 93.6904 41.3868V174.631Z"
      fill="white"
      stroke="#E7E9F4"
      strokeMiterlimit="10"
    />
    <path
      d="M173.292 234.792C201.035 238.718 226.281 222.453 229.679 198.463C233.077 174.473 213.341 151.842 185.598 147.916C157.854 143.989 132.608 160.254 129.21 184.244C125.812 208.234 145.548 230.865 173.292 234.792Z"
      fill="#24275B"
    />
    <path
      d="M242.073 202.262C245.728 205.915 236.966 211.909 228.861 220.011C220.755 228.113 211.197 231.719 207.589 228.066C203.982 224.414 207.542 214.906 215.648 206.804C223.754 198.702 235.045 195.237 242.073 202.262Z"
      fill="#8E90AD"
    />
    <path
      d="M233.965 223.563C242.07 215.46 245.689 205.942 242.048 202.302C238.407 198.663 228.885 202.281 220.779 210.383C212.673 218.485 209.054 228.003 212.695 231.643C216.336 235.282 225.859 231.665 233.965 223.563Z"
      fill="white"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M207.262 215.656C203.139 213.877 202.202 210.083 205.106 207.133C208.058 204.182 213.774 203.246 217.897 204.978"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M214.525 208.069C211.245 205.025 211.667 201.091 215.415 199.358C219.163 197.625 224.879 198.656 228.159 201.7"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M140.779 130.187C140.779 141.895 133.423 155.195 122.084 166.248C118.477 168.215 114.822 169.994 111.074 171.493C100.11 175.895 88.9125 179.267 78.6049 180.579C76.4496 180.86 55.1316 152.947 58.8329 146.484C68.6252 129.437 77.6678 119.883 94.3475 113.186C119.976 102.93 140.779 110.517 140.779 130.187Z"
      fill="#8E90AD"
      stroke="#8E90AD"
      strokeMiterlimit="10"
    />
    <path
      d="M102.875 95.062L90.1777 135.01C88.0224 141.848 91.0679 147.234 97.0651 147C103.062 146.765 109.669 141.052 111.824 134.167L124.521 94.219L102.875 95.062Z"
      fill="white"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M130.424 86.6865C133.639 82.7652 134.103 77.8322 131.461 75.6683C128.819 73.5044 124.071 74.929 120.857 78.8503C117.642 82.7716 117.178 87.7046 119.82 89.8685C122.462 92.0324 127.21 90.6078 130.424 86.6865Z"
      fill="white"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M133.189 96.4666C133.189 107.004 124.661 118.993 114.073 123.208C107.607 125.784 101.891 124.894 98.4706 121.335L98.4238 121.288L97.3462 119.977L85.8672 106.067L108.731 72.4413L126.161 71.8325L132.017 90.2378C132.767 91.9706 133.189 94.0781 133.189 96.4666Z"
      fill="white"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M88.9132 104.99C87.8825 105.412 86.8517 105.74 85.821 106.021C83.8063 103.492 82.6818 100.026 82.6818 95.8579C82.6818 83.5878 92.6146 69.6316 104.89 64.7142C114.87 60.7334 123.303 63.8712 126.115 71.7859"
      fill="white"
    />
    <path
      d="M88.9133 104.99C87.8825 105.412 86.8517 105.74 85.821 106.021C83.8063 103.492 82.6818 100.026 82.6818 95.8579C82.6818 83.5878 92.6146 69.6316 104.89 64.7142C114.87 60.7334 123.303 63.8712 126.115 71.7859"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M101.798 97.6379C101.798 93.4698 105.171 88.7866 109.341 87.1006L101.798 97.6379Z"
      fill="white"
    />
    <path
      d="M101.798 97.6379C101.798 93.4698 105.171 88.7866 109.341 87.1006"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M94.2542 112.858C90.0843 114.544 86.7109 112.53 86.7109 108.362C86.7109 104.194 90.0843 99.5107 94.2542 97.8247"
      fill="white"
    />
    <path
      d="M94.2542 112.858C90.0843 114.544 86.7109 112.53 86.7109 108.362C86.7109 104.194 90.0843 99.5107 94.2542 97.8247"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M117.165 80.5432C120.819 77.1245 125.411 76.6561 127.332 79.5598L117.165 80.5432Z"
      fill="white"
    />
    <path
      d="M117.165 80.5432C120.819 77.1245 125.411 76.6561 127.332 79.5598"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M108.107 96.0091C108.829 95.1288 108.927 94.0165 108.326 93.5247C107.726 93.0329 106.654 93.3478 105.933 94.2281C105.211 95.1084 105.113 96.2207 105.713 96.7125C106.314 97.2043 107.385 96.8894 108.107 96.0091Z"
      fill="#24275B"
    />
    <path
      d="M125.057 85.0455C125.779 84.1652 125.877 83.0529 125.277 82.5611C124.676 82.0693 123.604 82.3842 122.883 83.2645C122.161 84.1448 122.063 85.2571 122.663 85.7489C123.264 86.2407 124.336 85.9257 125.057 85.0455Z"
      fill="#24275B"
    />
    <path d="M118.008 98.6681L124.521 97.3568L116.462 87.1472" fill="white" />
    <path
      d="M118.008 98.6681L124.521 97.3568L116.462 87.1472"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path d="M92.332 85.2738L96.9236 91.1748L124.52 68.6482" fill="white" />
    <path
      d="M92.332 85.2738L96.9236 91.1748L124.52 68.6482"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M113.697 110.47C113.697 110.47 110.37 109.767 107.044 108.456L113.697 110.47Z"
      fill="white"
    />
    <path
      d="M113.697 110.47C113.697 110.47 110.37 109.767 107.044 108.456"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M93.3445 208.749C93.5702 209.069 93.885 209.316 94.2497 209.459L259 274L218.694 217.764L188.958 175.586C188.734 175.268 188.422 175.023 188.061 174.879L29.6519 112.01C27.804 111.276 26.134 113.396 27.2794 115.021L93.3445 208.749Z"
      fill="#E7E9F4"
    />
    <path
      d="M100.671 139.974C97.0166 143.721 97.7194 148.264 102.217 150.137C106.715 151.963 113.321 150.418 116.976 146.671"
      fill="white"
    />
    <path
      d="M100.671 139.974C97.0166 143.721 97.7194 148.264 102.217 150.137C106.715 151.963 113.321 150.418 116.976 146.671"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M52.7407 120.866C49.3205 124.379 49.9764 128.641 54.1932 130.374C58.4099 132.106 64.5945 130.655 68.0148 127.095"
      fill="white"
    />
    <path
      d="M52.7407 120.866C49.3205 124.379 49.9764 128.641 54.1932 130.374C58.4099 132.106 64.5945 130.655 68.0148 127.095"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M55.7861 121.522C52.647 123.817 51.4288 127.095 52.975 129.671"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M60.0028 123.348C57.1448 125.596 56.0203 128.734 57.5196 131.216"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M103.858 140.536C100.531 142.971 99.1725 146.437 100.859 149.2"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M108.401 142.503C105.309 144.939 104.137 148.264 105.777 150.933"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M132.861 191.584C132.861 191.584 108.638 164.749 107.794 174.725C107.232 181.187 112.339 186.058 114.916 187.135C117.493 188.212 118.008 179.689 100.907 169.573C83.8055 159.457 93.832 178.986 96.7369 179.642C99.6418 180.298 101.656 170.837 79.3545 161.096"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M164.391 219.543C164.391 219.543 147.149 200.529 143.307 201.044C139.512 201.513 148.274 208.584 144.994 209.615C141.714 210.645 129.064 193.926 124.379 194.488C119.693 195.05 129.86 202.356 123.723 200.951C117.585 199.546 107.605 187.603 101.233 187.369"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path d="M225.722 211.441L233.453 219.122" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M227.83 209.896L235.514 217.623" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M232 205.728L239.684 213.455" stroke="#24275B" strokeMiterlimit="10" />
    <path
      d="M206.699 161.096C208.386 165.264 206.371 168.636 202.201 168.636C198.031 168.636 193.346 165.264 191.659 161.096"
      stroke="white"
      strokeMiterlimit="10"
    />
  </svg>
);
