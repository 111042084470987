import { FC, useMemo } from 'react';

import { useAttendanceCodes } from '../../../../hooks/useAttendanceCodes';
import { AddAttendanceCodeModalContent } from './AddAttendanceCodeModalContent';

const getEmptyCode = () => ({
  id: undefined,
  name: undefined,
  in_school: undefined,
  code: undefined,
  description: undefined,
  is_present: undefined,
});

export const AddAttendanceCodeModal: FC = () => {
  const {
    codeId,
    isEditing,
    attendanceCodes,
    saveAttendanceCode,
    deleteAttendanceCode,
    isLoading,
    isDeleting,
    onClose,
  } = useAttendanceCodes();

  const code = useMemo(() => {
    if (!codeId) {
      return getEmptyCode();
    }

    return attendanceCodes.find((c) => c.id === codeId) || getEmptyCode();
  }, [codeId, attendanceCodes]);

  return (
    <AddAttendanceCodeModalContent
      code={code}
      onClose={onClose}
      isLoading={isLoading}
      isDeleting={isDeleting}
      isEditing={isEditing}
      onSubmit={saveAttendanceCode}
      onDelete={() => deleteAttendanceCode(code)}
    />
  );
};
