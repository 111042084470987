import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RouterState } from '../../context/router/RouterStateContext';
import { AppLocation } from '../../hooks/useAppLocation';

export interface StackItem {
  location: AppLocation;
  state?: RouterState;
}

export interface IRouterState {
  stack: StackItem[];
}

export const initialState: IRouterState = {
  stack: [],
};

export const extraActions = {};

const slice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    push: (state, action: PayloadAction<StackItem>) => {
      const { state: routerState, ...payload } = action.payload;
      const newStack: StackItem[] = [...state.stack, payload];

      if (routerState && newStack.length > 1) {
        newStack[newStack.length - 2].state = routerState;
      }

      state.stack = newStack;
    },
    pop: (state) => {
      if (state.stack.length) {
        state.stack = [...state.stack.slice(0, -1)];
      }
    },
    clean: (state) => {
      state.stack = [];
    },
    replaceLast: (state, action: PayloadAction<StackItem>) => {
      const { state: routerState, ...payload } = action.payload;

      if (state.stack.length > 0) {
        const newStack = [...state.stack.slice(0, -1)];

        // find and remove duplicates from the end of the stack
        while (
          newStack.length > 0 &&
          newStack[newStack.length - 1].location.pathname === action.payload.location.pathname
        ) {
          newStack.splice(newStack.length - 1, 1);
        }

        newStack.push(payload);

        state.stack = newStack;
      } else {
        state.stack = [...state.stack, payload];
      }
    },
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
