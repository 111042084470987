import { Box } from '@mui/material';
import { theme } from '@schooly/style';
import type { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import React, { useMemo } from 'react';
import { FC, PropsWithChildren } from 'react';

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
].map((m) => m.toUpperCase());
interface CalendarChartEmptyProps extends PropsWithChildren {
  pending: boolean;
  opacity?: number;
}

export const CalendarChartEmpty: FC<CalendarChartEmptyProps> = ({ children, opacity = 1 }) => {
  const options: EChartsOption = useMemo(
    () => ({
      animation: false,
      visualMap: {
        min: 0,
        max: 100,
        type: 'piecewise',
        orient: 'horizontal',
        left: 'right',
        top: 0,
        calculable: true,
        show: false,
      },

      calendar: {
        left: 32,
        width: '97%',
        cellSize: [12, 40],
        top: 15,
        range: '2016',
        splitLine: {
          lineStyle: {
            color: theme.palette.common.grey,
          },
        },
        itemStyle: {
          borderColor: theme.palette.common.light2,
        },
        yearLabel: {
          show: false,
        },
        dayLabel: {
          color: theme.palette.common.grey2,
        },
        monthLabel: {
          color: theme.palette.common.grey2,
          position: 'end',
          triggerEvent: true,
          padding: 10,
          nameMap: MONTH_NAMES,
        },
      },
      grid: {
        containLabel: true,
        left: 0,
        height: '100%',
        top: 0,
      },
      series: [
        {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          calendarIndex: 0,
          data: [],
          name: '2016',
        },
      ],
    }),
    [],
  );

  return (
    <Box position="relative">
      <Box
        position="absolute"
        sx={(theme) => ({
          height: '100%',
          width: '100%',
          zIndex: theme.zIndex.drawer,
        })}
      >
        {children}
      </Box>
      <ReactECharts
        style={{
          width: '100%',
          height: 360,
          opacity,
        }}
        option={options}
      />
    </Box>
  );
};
