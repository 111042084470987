import { ChipProps } from '@mui/material';
import { SchoolUserRole } from '@schooly/constants';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { $enum } from 'ts-enum-util';

export type UserRoleTagSelectProps = {
  role: SchoolUserRole;
} & Omit<ChipProps, 'label' | 'role'>;

const OPTIONS = $enum(SchoolUserRole).map((value, key) => ({ value, label: key }));

export const UserRoleTagSelect: FC<UserRoleTagSelectProps> = ({ role, ...props }) => {
  const label = OPTIONS.find((o) => o.value === role)?.label;

  return <TagSelect label={label} {...props} />;
};
