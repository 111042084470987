import { useSearchParams } from 'react-router-dom';

export const CHARTS_SEARCH_PARAM = 'charts';
export const ARRANGE_BY_SEARCH_PARAM = 'arrange_by';
export const ZEROES_SEARCH_PARAM = 'zeroes';

export const useChartOpenFromSearchParams = () => {
  const [searchParams] = useSearchParams();
  const chartsQueryParamValue = searchParams.get(CHARTS_SEARCH_PARAM);
  const arrangeByQueryParamValue = searchParams.get(ARRANGE_BY_SEARCH_PARAM);

  return (
    chartsQueryParamValue === 'true' ||
    Boolean(arrangeByQueryParamValue && chartsQueryParamValue == null)
  );
};

export const useChartZeroesFromSearchParams = () => {
  const [searchParams] = useSearchParams();
  const zeroesQueryParamValue = searchParams.get(ZEROES_SEARCH_PARAM);

  return zeroesQueryParamValue === 'true' || zeroesQueryParamValue == null;
};
