import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.5C3 3.22386 3.22386 3 3.5 3H16.5C16.7761 3 17 3.22386 17 3.5C17 3.77614 16.7761 4 16.5 4H3.5C3.22386 4 3 3.77614 3 3.5ZM3 6.75C3 6.47386 3.22386 6.25 3.5 6.25H16.5C16.7761 6.25 17 6.47386 17 6.75C17 7.02614 16.7761 7.25 16.5 7.25H3.5C3.22386 7.25 3 7.02614 3 6.75ZM3.5 10.5C3.22386 10.5 3 10.2761 3 10C3 9.72386 3.22386 9.5 3.5 9.5H16.5C16.7761 9.5 17 9.72386 17 10C17 10.2761 16.7761 10.5 16.5 10.5H3.5ZM3 13.25C3 12.9739 3.22386 12.75 3.5 12.75H16.5C16.7761 12.75 17 12.9739 17 13.25C17 13.5261 16.7761 13.75 16.5 13.75H3.5C3.22386 13.75 3 13.5261 3 13.25ZM3.5 16C3.22386 16 3 16.2239 3 16.5C3 16.7761 3.22386 17 3.5 17H16.5C16.7761 17 17 16.7761 17 16.5C17 16.2239 16.7761 16 16.5 16H3.5Z"
      fill="#5D6688"
    />
  </svg>
);
