import { Components, Theme } from '@mui/material';

import { MuiAutocomplete } from './MuiAutocomplete';
import { MuiButton } from './MuiButton';
import { MuiCard } from './MuiCard';
import { MuiCardContent } from './MuiCardContent';
import { MuiCardHeader } from './MuiCardHeader';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiChip } from './MuiChip';
import { MuiDialog } from './MuiDialog';
import { MuiFormControlLabel } from './MuiFormControlLabel';
import { MuiIcon } from './MuiIcon';
import { MuiIconButton } from './MuiIconButton';
import { MuiLink } from './MuiLink';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiPopover } from './MuiPopover';
import { MuiSelect } from './MuiSelect';
import { MuiSkeleton } from './MuiSkeleton';
import { MuiSwitch } from './MuiSwitch';
import { MuiTableSortLabel } from './MuiTableSortLabel';
import { MuiTextField } from './MuiTextField';
import { MuiTooltip } from './MuiTooltip';

declare module '@mui/material/OverridableComponent' {
  export interface CommonProps {
    /* Used in IconButton */
    inverse?: boolean;
    /* Used in Chip */
    flat?: boolean;
  }
}

const components: Components<Theme> = {
  MuiAutocomplete,
  MuiButton,
  MuiCard,
  MuiCardContent,
  MuiCardHeader,
  MuiCheckbox,
  MuiChip,
  MuiDialog,
  MuiFormControlLabel,
  MuiIcon,
  MuiIconButton,
  MuiMenuItem,
  MuiPopover,
  MuiSelect,
  MuiSwitch,
  MuiTableSortLabel,
  MuiTextField,
  MuiSkeleton,
  MuiLink,
  MuiTooltip,
};

export default components;
