import { Box, Stack, Typography } from '@mui/material';
import { isSignUpWithoutEvent, SignUpStatuses } from '@schooly/api';
import { RichText } from '@schooly/components/form-rich-text';
import { DropdownIcon, SimpleButton } from '@schooly/style';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { useSignUp } from '../../../context/signUps/WithSignUp';
import { EventPreviewCriteria } from '../../Events/EventPreviewModal/EventPreviewCriteria';
import { EventPreviewInvitees } from '../../Events/EventPreviewModal/EventPreviewInvitees';
import { SignUpPreviewRespondents } from './SignUpPreviewRespondents/SignUpPreviewRespondents';

export const SignUpPreviewWithoutEvent: FC = () => {
  const { $t } = useIntl();
  const { signUp } = useSignUp();
  const [openDescription, setOpenDescription] = useState(false);

  if (!signUp || !isSignUpWithoutEvent(signUp)) {
    return null;
  }

  return (
    <Stack gap={2}>
      <EventPreviewInvitees invitee_type={signUp.invitee_type} />
      <EventPreviewCriteria criteria={signUp.criteria} />

      <Stack pt={2} alignItems="flex-start">
        {signUp.status === SignUpStatuses.Draft ? (
          <Typography variant="h2">{$t({ id: 'eventSignUps-create-Description' })}</Typography>
        ) : (
          <SimpleButton
            endIcon={<DropdownIcon />}
            sx={{
              color: 'text.secondary',

              '& .MuiButton-endIcon': {
                transform: openDescription ? 'rotate(180deg)' : undefined,
              },
            }}
            onClick={() => setOpenDescription((open) => !open)}
          >
            <Typography variant="h2">{$t({ id: 'eventSignUps-create-Description' })}</Typography>
          </SimpleButton>
        )}

        {(signUp.status === SignUpStatuses.Draft || openDescription) && (
          <Box pt={2}>
            <RichText value={signUp.description ?? ''} readOnly />
          </Box>
        )}
      </Stack>

      <SignUpPreviewRespondents />
    </Stack>
  );
};
