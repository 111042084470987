import { Box, Icon, IconButton, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, FilterKeys } from '@schooly/api';
import {
  clearLastAppliedFilter,
  DateSelect,
  StoredFilterSections,
} from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import { Counter, DeleteIcon, LockIcon, NewTabIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { addYears, format, isAfter, isBefore, isEqual } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import {
  Controller,
  FieldArrayWithId,
  FieldPath,
  FieldPathValue,
  FieldValues,
  useFormContext,
  Validate,
} from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SchoolGeneralSchoolYearsForm } from './SchoolGeneralSchoolYearsModal';
import {
  decrementDay,
  getInUseCount,
  getSchoolYearEndDate,
  getSchoolYearInUseLabelId,
  incrementDay,
} from './utils';

type SchoolYearFormRowProps = {
  yearField: FieldArrayWithId<SchoolGeneralSchoolYearsForm, 'schoolYears', 'id'>;
  fieldIndex: number;
  shouldFocusLastItem: boolean;
  onDelete: () => void;
  onShowRefreshButton: () => void;
};

const IN_USE_TOOLTIP_KEYS = ['periods', 'registrations_with_current_status'] as const;

export const SchoolYearFormRow: FC<SchoolYearFormRowProps> = ({
  fieldIndex,
  yearField,
  shouldFocusLastItem,
  onDelete,
  onShowRefreshButton,
}) => {
  const { $t } = useIntl();

  const { control, getValues, watch } = useFormContext<SchoolGeneralSchoolYearsForm>();
  const schoolYearsValue = watch('schoolYears');
  const schoolYearsCount = schoolYearsValue?.length ?? 0;
  const startDateField = getValues(`schoolYears.${fieldIndex}.start`);
  const startDate = startDateField ? newDateTimezoneOffset(startDateField) : undefined;
  const endDate = startDate ? getSchoolYearEndDate(startDate) : undefined;

  const canBeDeleted = Boolean(!yearField.originId || yearField.can_be_deleted);

  const previousNotEmptyField = useMemo(
    () =>
      schoolYearsValue?.reduce(
        (acc, next, idx) => (fieldIndex <= idx || !next.start ? acc : next),
        {},
      ),
    [fieldIndex, schoolYearsValue],
  );

  const nextYearField = schoolYearsValue?.[fieldIndex + 1];

  const nextYearStartDate = nextYearField?.start
    ? newDateTimezoneOffset(nextYearField.start)
    : null;

  const previousYearEndDate = previousNotEmptyField?.start
    ? getSchoolYearEndDate(newDateTimezoneOffset(previousNotEmptyField.start))
    : undefined;

  const hasDaysBefore =
    !!previousYearEndDate && !!startDate && !isEqual(decrementDay(startDate), previousYearEndDate);

  const hasDaysAfter =
    !!nextYearStartDate && !!endDate ? !isEqual(decrementDay(nextYearStartDate), endDate) : true;

  const presetDate =
    previousYearEndDate && !startDate ? incrementDay(previousYearEndDate) : undefined;

  const yearInUse = yearField.in_use_info;

  const renderDeleteYearTooltip = () => {
    if (!yearInUse || !yearField.originId) return null;
    const hasStarted = !!yearInUse['has_started'];

    if (hasStarted) {
      return <Typography>{$t({ id: 'school-tabs-SchoolYears-InUse-HasStarted' })}</Typography>;
    }

    const inUseCounts = IN_USE_TOOLTIP_KEYS.filter((k) => !!getInUseCount(k, yearInUse));

    if (!inUseCounts.length) return null;

    return (
      <Stack gap={1.25}>
        <Typography>{$t({ id: 'school-tabs-SchoolYears-DisabledTitle' })}</Typography>

        {inUseCounts.map((key) => {
          const counter = getInUseCount(key, yearInUse);

          const onClick = () => {
            switch (key) {
              case 'periods':
                window.open(`/settings/school_periods/${yearField.originId}`);
                break;
              case 'registrations_with_current_status':
                const appliesFrom = yearInUse[key].applies_from;
                if (!appliesFrom) return;
                clearLastAppliedFilter(StoredFilterSections.Students);

                window.open(
                  `/students?${`${FilterKeys.Date}=${encodeURIComponent(
                    JSON.stringify([appliesFrom]),
                  )}`}`,
                );
                break;

              default:
                const exhaustiveCheck: never = key;
                console.error(
                  `Unhandled key in school-tabs-SchoolYears-DisabledTitle: ${exhaustiveCheck}`,
                );
            }

            onShowRefreshButton();
          };

          return (
            <DeleteSchoolYearTooltipItem
              key={key}
              title={$t({ id: getSchoolYearInUseLabelId(key) })}
              counter={counter}
              onClick={onClick}
            />
          );
        })}
      </Stack>
    );
  };

  const disabled = !hasDaysBefore && !hasDaysAfter;

  const showDelete = (schoolYearsValue?.length ?? 0) > 1 && canBeDeleted;

  const shouldDisableDate = useCallback(
    (date: Date) => {
      const isOverlapsToPreviousYear = previousYearEndDate
        ? isBefore(date, incrementDay(previousYearEndDate))
        : false;

      const isOverlapsToNextYear = nextYearStartDate
        ? isAfter(addYears(date, 1), nextYearStartDate)
        : false;

      return isOverlapsToPreviousYear || isOverlapsToNextYear;
    },
    [nextYearStartDate, previousYearEndDate],
  );

  const handleValidateName = useCallback<
    Validate<FieldPathValue<FieldValues, FieldPath<FieldValues>>, SchoolGeneralSchoolYearsForm>
  >(
    (newValue, form) => {
      if (!newValue || !form.schoolYears) return true;

      const nameNotUnique = form.schoolYears.some(({ name }, idx) => {
        if (!name || idx === fieldIndex) return false;

        return name.trim().toLowerCase() === newValue.trim().toLowerCase();
      });

      return nameNotUnique
        ? $t({
            id: 'school-tabs-SchoolYears-Exists',
          })
        : true;
    },
    [$t, fieldIndex],
  );

  return (
    <>
      <Box>
        <ControlTextField
          name={`schoolYears.${fieldIndex}.name`}
          control={control}
          label={$t({ id: 'school-tabs-SchoolYears-SchoolYearName' })}
          autoFocus={shouldFocusLastItem && fieldIndex === schoolYearsCount - 1}
          fullWidth
          rules={{
            required: true,
            validate: handleValidateName,
          }}
          hideLabel
        />
      </Box>

      <Box sx={!endDate ? { gridColumn: '2 / span 2' } : undefined}>
        <Controller
          control={control}
          name={`schoolYears.${fieldIndex}.start`}
          rules={{ required: true }}
          render={({ field: startDateField, fieldState }) => {
            return (
              <DateSelect
                ref={startDateField.ref}
                onSetDate={(date) => {
                  if (shouldDisableDate(date)) {
                    return;
                  }
                  startDateField.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                }}
                date={startDateField.value}
                placeholder={$t({ id: 'school-tabs-SchoolYears-StartDate' })}
                requiredLabel="required"
                disabled={(!!endDate && !canBeDeleted) || disabled}
                error={fieldState.error}
                withoutRightIcon={!!endDate && !canBeDeleted}
                hideTodayButton
                sx={{
                  '.MuiStack-root': {
                    flexWrap: 'nowrap',
                  },
                }}
                renderRightIcon={
                  disabled
                    ? () => {
                        return (
                          <Tooltip
                            title={$t({
                              id: 'school-tabs-SchoolYears-NoDatesToSelect',
                            })}
                          >
                            <Icon>
                              <LockIcon />
                            </Icon>
                          </Tooltip>
                        );
                      }
                    : undefined
                }
                expandedContainerStyleProps={(theme) => ({
                  minWidth: 300,
                  border: `1px solid ${theme.palette.common.light3} !important`,
                  marginTop: '-1px',
                  marginBottom: '-1px',
                })}
                presetDate={presetDate}
                CalendarProps={{
                  shouldDisableDate,
                }}
              />
            );
          }}
        />
      </Box>

      {!!endDate && (
        <Box>
          <DateSelect
            onSetDate={() => {}}
            date={format(endDate, DEFAULT_DATE_FORMAT_FNS)}
            placeholder={$t({ id: 'school-tabs-SchoolYears-EndDate' })}
            disabled
            renderRightIcon={() =>
              showDelete ? (
                <Tooltip title={$t({ id: 'school-tabs-SchoolYears-EndDateHint' })}>
                  <IconButton className="lock-icon" inverse>
                    <LockIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )
            }
          />
        </Box>
      )}

      <Stack justifyContent="center" alignSelf="flex-start" pt={1.5}>
        {showDelete ? (
          <IconButton inverse onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  padding: theme.spacing(1.25),
                }),
              },
            }}
            title={renderDeleteYearTooltip()}
          >
            <IconButton inverse>
              <LockIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </>
  );
};

type DeleteSchoolYearTooltipItemProps = {
  title: string;
  counter: number;
  onClick?: () => void;
};

const DeleteSchoolYearTooltipItem: FC<DeleteSchoolYearTooltipItemProps> = ({
  counter,
  onClick,
  title,
}) => (
  <MenuItem
    onClick={onClick}
    sx={(theme) => ({
      justifyContent: 'space-between',
      '.newTabIcon': {
        visibility: 'hidden',
      },
      '.counter': {
        bgcolor: 'transparent',
        color: theme.palette.common.grey2,
        border: `1px solid ${theme.palette.common.grey2}`,
      },

      ':hover': {
        '.MuiTypography-h3': {
          color: theme.palette.primary.main,
        },
        '.newTabIcon': {
          visibility: 'visible',
        },
        '.counter': {
          bgcolor: theme.palette.primary.main,
          color: theme.palette.background.paper,
          borderColor: theme.palette.primary.main,
        },
      },
      m: 0,
      py: 0.25,
      px: 0.5,
    })}
  >
    <Stack direction="row" gap={0.5} maxWidth={140}>
      <Typography color="common.grey2" variant="h3" whiteSpace="initial">
        {title}

        <Counter
          className="counter"
          sx={{
            px: counter >= 10 ? 0.4 : 0.7,
            py: 0,
            ml: 0.75,
          }}
        >
          {counter}
        </Counter>
      </Typography>
    </Stack>

    {onClick && (
      <Icon className="newTabIcon" fontSize="small">
        <NewTabIcon />
      </Icon>
    )}
  </MenuItem>
);
