import { Box, Stack, styled } from '@mui/material';

import { GridRowItem, GridRowName } from '../Grid/Grid';

export const ListViewRowReports = styled(Box)(({ theme }) => ({
  flex: '0 0 180px',

  [theme.breakpoints.down('lg')]: {
    flex: '0 0 280px',
  },

  [theme.breakpoints.down('md')]: {
    flex: '0 0 120px',
  },

  marginLeft: theme.spacing(2.5),
  overflow: 'hidden',
  color: theme.palette.text.primary,

  '.GridRowItem:hover &': {
    color: theme.palette.primary.main,
  },
}));

export const ListViewRowStatus = styled(Box)(({ theme }) => ({
  flex: '0 0 130px',
  marginLeft: theme.spacing(2.5),

  [theme.breakpoints.only('md')]: {
    flex: '0 0 170px',
  },

  '& .UIKit-TagStatus': {
    fontSize: theme.spacing(1.5),
  },
}));

type ListViewRowActionsProps = {
  showActions?: boolean;
};

export const ListViewRowActions = styled(Box)<ListViewRowActionsProps>(({ theme, showActions }) => {
  const size = theme.spacing(2.5);

  const showActionsProps = {
    display: 'unset',
    color: theme.palette.text.secondary,
  };

  return {
    position: 'relative',
    marginLeft: theme.spacing(2.5),
    flex: `0 0 ${size}`,
    fontSize: size,
    minWidth: size,

    '& .MuiIconButton-root': {
      position: 'absolute',
      transform: 'translateY(-50%)',
      display: 'none',

      '.GridRowItem:hover &': {
        display: 'unset',
      },

      [theme.breakpoints.down('lg')]: showActionsProps,
      ...(showActions && showActionsProps),
    },
  };
});

export const ListViewRowItemForRelation = styled(GridRowItem)(({ theme }) => ({
  gap: theme.spacing(1.5),
}));

export const ListViewExtraRow = styled(GridRowItem)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0,
}));

export const ListViewAssessmentsNameForRelation = styled(GridRowName)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  paddingRight: theme.spacing(2),

  '& a': {
    color: theme.palette.text.primary,

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const ListViewAssessmentsTags = styled(Stack)(({ theme }) => ({
  flex: '0 0 195px',
  flexDirection: 'row',

  width: theme.spacing(24),
  alignItems: 'center',
  gap: theme.spacing(),

  [theme.breakpoints.down('lg')]: {
    flex: '0 0 250px',
  },

  '& .UIKit-TagGroup': {
    maxWidth: theme.spacing(15),
  },
  '& .UIKit-TagSubject': {
    maxWidth: theme.spacing(15),
  },
}));

export const ListViewAssessmentsStatus = styled(Box)(({ theme }) => ({
  flex: '0 0 10px',
  margin: theme.spacing(0.75),
}));

export const ListViewAssessmentsStatusPublished = styled(ListViewAssessmentsStatus)(
  ({ theme }) => ({
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    borderRadius: '50%',
    background: theme.palette.success.main,
    margin: 0,
  }),
);

export const ListViewAssessmentsComments = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',

  '& .UIKit-Tag': {
    backgroundColor: theme.palette.background.paper,
  },
}));
