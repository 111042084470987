import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AgeGroupSelectMultiple,
  GroupSelectMultiple,
  PropertyTypeSelectMultiple,
  toggleMultipleValueArrayProperty,
} from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { CrossIcon, ModalContent, ModalFooter, ModalHeader } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useSchool } from '../../../hooks/useSchool';

export type AddStudentsFilters = {
  [FilterKeys.Status]?: string[];
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.House]?: string[];
  [FilterKeys.Group]?: string[];
  [FilterKeys.SingleDate]?: string[];
  [FilterKeys.Date]?: string[];
};

type AddStudentsFiltersKey =
  | FilterKeys.Status
  | FilterKeys.AgeGroup
  | FilterKeys.House
  | FilterKeys.Group;

type AddStudentsFiltersPopupProps = {
  filters: AddStudentsFilters;
  defaultFilters: AddStudentsFilters;
  onClose: () => void;
  onSetFilters: (v: AddStudentsFilters) => void;
};

export const AddStudentsFiltersPopup: FC<AddStudentsFiltersPopupProps> = ({
  onClose,
  onSetFilters,
  filters: initialFilters,
  defaultFilters,
}) => {
  const { $t } = useIntl();

  const { schoolId } = useSchool();
  const { permissions } = useAuth();
  const isGroupViewer = permissions.includes('group_viewer');

  const [filters, setFilters] = useState<AddStudentsFilters>(initialFilters);

  const handleApply = useCallback(() => {
    onSetFilters(filters);
    onClose();
  }, [onSetFilters, filters, onClose]);

  const handleClear = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const {
    onSelectStudentStatusId,
    onSelectAgeGroup,
    onSelectGroupId,
    onSelectHouseId,
    onClearStudentStatus,
    onClearAgeGroup,
    onClearGroup,
    onClearHouse,
  } = useMemo(() => {
    const updateFilter = (key: AddStudentsFiltersKey) => (id: string) => {
      setFilters((v) => ({
        ...v,
        [key]:
          v[key] && v[key]?.includes(id)
            ? v[key]?.filter((ct) => ct !== id)
            : [...(v[key] || []), id],
      }));
    };
    const updateFilterMultipleValue = (key: AddStudentsFiltersKey) => (ids: string[]) => {
      setFilters((v) => ({
        ...v,
        [key]: toggleMultipleValueArrayProperty(v[key], ids),
      }));
    };
    const clearFilter = (key: AddStudentsFiltersKey) => () => {
      setFilters((v) => ({
        ...v,
        [key]: [],
      }));
    };

    return {
      onSelectStudentStatusId: updateFilter(FilterKeys.Status),
      onSelectAgeGroup: updateFilterMultipleValue(FilterKeys.AgeGroup),
      onSelectHouseId: updateFilter(FilterKeys.House),
      onSelectGroupId: updateFilter(FilterKeys.Group),
      onClearStudentStatus: clearFilter(FilterKeys.Status),
      onClearAgeGroup: clearFilter(FilterKeys.AgeGroup),
      onClearHouse: clearFilter(FilterKeys.House),
      onClearGroup: clearFilter(FilterKeys.Group),
    };
  }, []);

  if (!schoolId) return null;
  return (
    <Dialog
      fullWidth
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndex.modal + 1,

        '& .MuiDialog-paperFullWidth': {
          width: 600,
          height: 'unset',
        },
      })}
      open
      onClose={onClose}
    >
      <ModalHeader active title={$t({ id: 'reports-Filter-Title' })}>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalContent active>
        <FilterRow label={$t({ id: 'schoolProperty-Status' })}>
          <PropertyTypeSelectMultiple
            borderOnHover
            allowArchived
            schoolId={schoolId}
            onClear={onClearStudentStatus}
            optionsTitle={$t({ id: 'schoolProperty-Status-plural' })}
            userRole={SchoolUserRole.Student}
            propertyType={SchoolPropertyType.Status}
            selectedIds={filters.status || []}
            onSelectId={onSelectStudentStatusId}
          />
        </FilterRow>
        <FilterRow label={$t({ id: 'schoolProperty-AgeGroup' })}>
          <AgeGroupSelectMultiple
            borderOnHover
            allowArchived
            schoolId={schoolId}
            onClear={onClearAgeGroup}
            optionsTitle={$t({ id: 'schoolProperty-AgeGroup-plural' })}
            userRole={SchoolUserRole.Student}
            selectedIds={filters.age_group || []}
            onSelect={onSelectAgeGroup}
          />
        </FilterRow>
        <FilterRow label={$t({ id: 'schoolProperty-House' })}>
          <PropertyTypeSelectMultiple
            borderOnHover
            allowArchived
            schoolId={schoolId}
            onClear={onClearHouse}
            optionsTitle={$t({ id: 'schoolProperty-House-plural' })}
            userRole={SchoolUserRole.Student}
            propertyType={SchoolPropertyType.House}
            selectedIds={filters.house || []}
            onSelectId={onSelectHouseId}
          />
        </FilterRow>
        {isGroupViewer && (
          <FilterRow label={$t({ id: 'groups-Group' })}>
            <GroupSelectMultiple
              borderOnHover
              onClear={onClearGroup}
              schoolId={schoolId}
              filters={filters}
              selectedIds={filters.group || []}
              onSelectGroupId={onSelectGroupId}
            />
          </FilterRow>
        )}
      </ModalContent>
      <ModalFooter active>
        <Button variant="outlined" onClick={handleClear}>
          {$t({ id: 'filter-Clear' })}
        </Button>
        <Button onClick={handleApply}>{$t({ id: 'filter-Apply' })}</Button>
      </ModalFooter>
    </Dialog>
  );
};

const FilterRow: FC<PropsWithChildren<{ label: string }>> = ({ label, children }) => (
  <Stack flexDirection="row" alignItems="center" gap={2}>
    <Box sx={{ flex: '1 1 15%' }}>
      <Typography color="common.grey2">{label}</Typography>
    </Box>
    <Box sx={{ flex: '1 1 85%' }}>{children}</Box>
  </Stack>
);
