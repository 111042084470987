import { Components, createTheme, PaletteColorOptions, Theme, TypeText } from '@mui/material';
import React, { CSSProperties } from 'react';

import components from './components/components';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h3_bold: true;
    h3_medium: true;
    h3_italic: true;
    body3: true;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    fontWeightSemiBold: React.CSSProperties['fontWeight'];
    fontWeightExtraBold: React.CSSProperties['fontWeight'];
    h3_bold: React.CSSProperties;
    h3_medium: React.CSSProperties;
    h3_italic: React.CSSProperties;
    body3: React.CSSProperties;
    headerCell: React.CSSProperties;
  }

  interface TypographyOptions {
    fontWeightSemiBold?: React.CSSProperties['fontWeight'];
    fontWeightExtraBold?: React.CSSProperties['fontWeight'];
    h3_bold?: React.CSSProperties;
    h3_medium?: React.CSSProperties;
    h3_italic?: React.CSSProperties;
    body3?: React.CSSProperties;
    headerCell?: React.CSSProperties;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    lightBg: string;
    light2: string;
    light3: string;

    grey: string;
    grey2: string;
    grey3: string;
    grey7: string;

    greenOld: string;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    shape: {
      borderRadiusSm: number | string;
      borderRadius: number | string;
      borderRadiusLg: number | string;
    };
  }

  interface ThemeOptions {
    shape: {
      borderRadiusSm?: number | string;
      borderRadius?: number | string;
      borderRadiusLg?: number | string;
    };
  }

  interface PaletteColor {
    superLight?: string;
  }

  interface SimplePaletteColorOptions {
    superLight?: string;
  }

  interface TypeBackground {
    button: {
      error: {
        hover: string;
      };
    };
  }

  interface Mixins {
    borderValue: (width?: string) => string;
    border: (width?: string) => {
      border: string;
    };
    borderControlValue: (width?: string) => string;
    borderControl: (width?: string) => {
      border: string;
    };
    dropdownShadow: () => CSSProperties;
  }
}

const colorsCommon: Partial<Theme['palette']['common']> = {
  lightBg: '#f8f9fe',
  light2: '#e7e9f4',
  light3: '#c7c8e0',

  grey: '#b3bbcb',
  grey2: '#5d6688',
  grey3: '#8e90ad',
  grey7: '#eceef6',

  greenOld: '#68df83',
};

const colorsPrimary: PaletteColorOptions = {
  main: '#24275b',
  light: '#3d417a',
};

const colorsText: Partial<TypeText> = {
  primary: colorsCommon.grey2,
  secondary: colorsCommon.grey,
  disabled: colorsCommon.light2,
};

const theme = createTheme({
  palette: {
    primary: colorsPrimary,
    text: colorsText,
    background: {
      default: colorsCommon.lightBg,
      paper: '#fff',
      button: {
        error: {
          hover: '#ff6b6b',
        },
      },
    },
    error: {
      main: '#eb5757',
      light: '#fce6e6',
      superLight: '#fef7f7',
    },
    success: {
      main: '#6fcf97',
      light: '#b7e7cb',
      superLight: '#f1faf5',
    },
    warning: {
      main: '#f2994a',
      light: 'rgba(242, 153, 74, 0.15)',
      superLight: 'rgba(242, 153, 74, 0.05)',
    },

    divider: colorsCommon.light2,

    common: colorsCommon,
  },
  typography: {
    fontFamily: 'Heebo, Helvetica, sans-serif',
    fontSize: 12,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    htmlFontSize: 12,
    h1: {
      fontSize: 28,
      fontWeight: 400,
      lineHeight: '40px',
      color: colorsPrimary.main,
    },
    h2: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '28px',
      color: colorsPrimary.main,
    },
    h3: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '22px',
      color: colorsPrimary.main,
    },
    h3_bold: {
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '22px',
      letterSpacing: '0.01em',
      color: colorsPrimary.main,
    },
    h3_medium: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.01em',
    },
    h3_italic: {
      fontFamily: 'Helvetica', // no italic for Heebo
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '20px',
      color: colorsPrimary.main,
    },
    h4: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.02em',
      color: colorsCommon.grey3,
    },
    body1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.01em',
    },
    body2: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.01em',
    },
    body3: {
      fontSize: 10,
      fontWeight: 700,
      lineHeight: '14px',
      letterSpacing: '0.01em',
    },
    caption: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.01em',
    },
    headerCell: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.01em',
      color: colorsCommon.grey,
    },
  },
  mixins: {
    borderValue: (width?: string) => `${width ?? '1px'} solid ${theme.palette.divider}`,
    border: (width?: string) => ({
      border: theme.mixins.borderValue(width),
    }),
    borderControlValue: (width?: string) =>
      `${width ?? '1px'} solid ${theme.palette.common.light3}`,
    borderControl: (width?: string) => ({
      border: theme.mixins.borderControlValue(width),
    }),
    dropdownShadow: () => ({ boxShadow: '0px 10px 20px 0px #25275A0D' }),
  },
  shape: {
    borderRadiusSm: 4,
    borderRadius: 8,
    borderRadiusLg: 16,
  },

  components: components as Components,
});

export default theme;
