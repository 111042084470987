import Joi from 'joi';

import emptyString from '../../../utils/joi/emptyString';

export type CloseAccountFormData = {
  password: string;
};

export const schema = Joi.object<CloseAccountFormData>({
  password: emptyString(true),
});
