import {
  Icon,
  IconButton,
  Stack,
  TableHeadProps,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { PaymentFrequency, Product, ProductBillingType } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ArrowAngleIcon,
  DropdownIcon,
  GridCell,
  GridHead,
  LockIcon,
  Price,
  PRICE_SUBTEXT_CLASS_NAME,
  RecurringIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getCurrencySymbol, getVariantPrice, useProductFrequency } from './helpers';
import { ProductApplicable } from './ProductsApplicable';
import {
  AllFrequenciesNotInUse,
  FrequenciesTooltip,
  HalfDayIconComponent,
  NotInUseFrequencyLabel,
} from './SchoolProductCommon';

const HOVER_TEXT_CLASS_NAME = 'hoverText';

type ProductsHeaderProps = {
  frequencies: PaymentFrequency[];
};

export const ProductsHeader: FC<TableHeadProps & ProductsHeaderProps> = ({
  frequencies,
  ...rest
}) => {
  const { $t } = useIntl();

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width="20px" />
      <GridCell
        width={frequencies.length <= 2 ? '250px' : '200px'}
        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { width: '20%' } })}
      >
        {$t({ id: 'products-Name' })}
      </GridCell>
      <GridCell width="70px">{$t({ id: 'products-HalfDay' })}</GridCell>
      <GridCell width="30%">{$t({ id: 'products-TheProductIsApplicableTo' })}</GridCell>

      {frequencies.map((f) => {
        return (
          <GridCell
            key={f.id}
            width="100px"
            sx={(theme) => ({ [theme.breakpoints.down('lg')]: { width: '70px' } })}
          >
            <Stack direction="row" alignItems="center" gap={0.5}>
              {$t({ id: `frequencies-${f.type}` })}
              {!f.in_use && (
                <Icon>
                  <LockIcon />
                </Icon>
              )}
            </Stack>
          </GridCell>
        );
      })}
      <GridCell width="40px" />
    </GridHead>
  );
};

type ProductRowProps = {
  product: Product;
  schoolId: string;
  isSelected?: boolean;
  onSelect?: (v: string) => void;
  frequencies: PaymentFrequency[];
  canViewFrequency: boolean;
};

export const ProductRow: FC<ProductRowProps> = ({
  product,
  schoolId,
  frequencies,
  canViewFrequency,
}) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { formatMessage } = useIntl();
  const [isExpanded, expand, collapse] = useFlag();
  const [rowHovered, setRowHovered] = useState(false);
  const theme = useTheme();

  const { name, id, types } = product;

  const hoverProps = {
    onMouseEnter: () => setRowHovered(true),
    onMouseLeave: () => setRowHovered(false),
    sx: {
      ...(rowHovered && {
        'td.MuiTableCell-root': {
          backgroundColor: theme.palette.background.default,
          '.MuiTypography-root': {
            color: theme.palette.primary.main,
          },
          [` .${HOVER_TEXT_CLASS_NAME}, .${PRICE_SUBTEXT_CLASS_NAME}`]: {
            color: 'common.grey2',
          },
        },
      }),
    },
  };
  const { sx: hoverStyles, ...restHoverProps } = hoverProps;

  const { allTypesNotActive, productTypeNotActiveMap } = useProductFrequency({
    frequencies: frequencies,
    product,
  });

  const typeNames = product.types.map((t, i, arr) => {
    const typeName = `"${t.name}"`;
    if (arr.length <= 1) return typeName;

    const lastIndex = arr.length - 1;
    const separator = i < lastIndex - 1 ? ',' : '';
    return i !== lastIndex
      ? `${typeName}${separator}`
      : ` ${formatMessage({ id: 'and' })} ${typeName}`;
  });

  return (
    <>
      <TableRow
        ref={ref}
        component={Link}
        to={`/settings/products/${id}`}
        sx={(theme) => ({
          '&:not(:first-of-type)': {
            '& .MuiTableCell-root': {
              borderTop: theme.mixins.borderValue(),
            },
          },
          ...hoverStyles,
        })}
        {...restHoverProps}
      >
        <GridCell borderBottom={false} />
        <GridCell borderBottom={false}>
          <Stack direction="row" gap={0.75}>
            <TypographyWithOverflowHint variant="h3" color="text.primary">
              {name}
            </TypographyWithOverflowHint>
            {product.type === ProductBillingType.Recurring && (
              <Icon sx={{ color: 'common.grey2' }}>
                <RecurringIcon />
              </Icon>
            )}
            {allTypesNotActive && !isExpanded && (
              <AllFrequenciesNotInUse
                name={`${formatMessage({
                  id: product.types.length > 1 ? 'products-Type-plural' : 'products-Type',
                })}
                ${typeNames.join('')}
                `}
                plural={typeNames.length > 1}
              />
            )}
          </Stack>
        </GridCell>
        <GridCell borderBottom={false} colSpan={2} />
        {!!frequencies.length && <GridCell colSpan={frequencies.length} borderBottom={false} />}
        <GridCell
          onClick={(e) => {
            e.preventDefault();
            isExpanded ? collapse() : expand();
          }}
          borderBottom={false}
        >
          {!!types.length && (
            <IconButton
              inverse
              sx={(theme) => ({
                mr: 1.5,
                transform: isExpanded ? 'rotate(180deg)' : undefined,
                color: rowHovered ? theme.palette.common.grey2 : undefined,
              })}
            >
              <DropdownIcon />
            </IconButton>
          )}
        </GridCell>
      </TableRow>

      {isExpanded &&
        types.map((type, typeIndex) => {
          const isFirstType = typeIndex === 0;
          const notActive = productTypeNotActiveMap[type.id];

          const currencySymbol = getCurrencySymbol(type.billing_connection.legal_entity_currency);

          return (
            <>
              {type.variants.map((variant, index) => {
                const isFirstVariant = index === 0;

                return (
                  <TableRow
                    key={variant.id}
                    component={Link}
                    to={`/settings/products/${id}`}
                    {...hoverProps}
                  >
                    <GridCell borderBottom={false} py={1.25} />
                    <GridCell borderBottom={false} p={0} pl={1} height={44}>
                      {isFirstVariant && (
                        <Stack direction="row" gap={2} alignItems="center" height="100%">
                          <Icon
                            sx={(theme) => ({
                              color: rowHovered
                                ? theme.palette.common.grey2
                                : theme.palette.common.grey,
                            })}
                          >
                            <ArrowAngleIcon />
                          </Icon>
                          <Stack
                            height="100%"
                            width="100%"
                            py={1.25}
                            sx={(theme) => ({ borderTop: `1px solid ${theme.palette.divider}` })}
                            direction="row"
                            gap={0.5}
                          >
                            <Stack justifyContent="center">
                              <Typography variant="h3" color="text.primary">
                                {type.name}
                              </Typography>
                            </Stack>

                            {notActive && (
                              <AllFrequenciesNotInUse
                                name={`${formatMessage({ id: 'products-Type' })} ${type.name}`}
                              />
                            )}
                          </Stack>
                        </Stack>
                      )}
                    </GridCell>
                    <GridCell borderBottom={false} borderTop={isFirstVariant} py={1.25}>
                      <HalfDayIconComponent
                        sx={(theme) => ({
                          color: rowHovered
                            ? theme.palette.primary.main
                            : theme.palette.common.grey2,
                          alignSelf: 'flex-start',
                        })}
                        isHalfDay={variant.half_day}
                        showEmpty={false}
                      />
                    </GridCell>
                    <GridCell borderBottom={false} borderTop={true} py={1.25}>
                      <ProductApplicable
                        pt={0.25}
                        color="text.primary"
                        schoolId={schoolId}
                        selectedAgeGroupIds={variant.age_groups}
                        selectedSubjectIds={variant.subjects}
                      />
                    </GridCell>
                    {frequencies.map((frequency) => {
                      const price = getVariantPrice(variant, frequency.id);

                      return (
                        <GridCell
                          key={frequency.id}
                          borderBottom={false}
                          borderTop={true}
                          py={1.25}
                        >
                          <FrequenciesTooltip
                            title={
                              !frequency.in_use && !!price ? (
                                <NotInUseFrequencyLabel
                                  frequency={frequency}
                                  canView={canViewFrequency}
                                />
                              ) : (
                                ''
                              )
                            }
                          >
                            {price ? (
                              <TypographyWithOverflowHint>
                                <Price
                                  price={price}
                                  currency={currencySymbol}
                                  variant="body1"
                                  color="text.primary"
                                />
                              </TypographyWithOverflowHint>
                            ) : (
                              <Typography color="text.secondary" className={HOVER_TEXT_CLASS_NAME}>
                                -
                              </Typography>
                            )}
                          </FrequenciesTooltip>
                        </GridCell>
                      );
                    })}
                    <GridCell
                      borderTop={isFirstVariant && !isFirstType}
                      borderBottom={false}
                      py={1.25}
                    />
                  </TableRow>
                );
              })}
            </>
          );
        })}
    </>
  );
};
