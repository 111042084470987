import { Components, Theme } from '@mui/material';

export const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      width: 30,
      height: 20,
      padding: 0,

      '& ~ .MuiFormControlLabel-label': {
        ...theme.typography.h3,
        color: ownerState.checked ? theme.palette.primary.main : undefined,
      },

      '.MuiFormControlLabel-labelPlacementTop &': {
        marginTop: theme.spacing(),
      },

      '.MuiFormControlLabel-labelPlacementEnd &': {
        marginRight: theme.spacing(),
      },

      '.MuiFormControlLabel-labelPlacementBottom &': {
        marginBottom: theme.spacing(),
      },

      '.MuiFormControlLabel-labelPlacementStart &': {
        marginLeft: theme.spacing(),
      },

      '&:hover .MuiSwitch-track, .MuiFormControlLabel-root:hover & .MuiSwitch-track': {
        borderColor: theme.palette.primary.main,
      },
    }),
    track: ({ theme }) => ({
      backgroundColor: 'unset',
      border: theme.mixins.borderControlValue(),
      borderRadius: 10,
    }),
    thumb: ({ theme, ownerState }) => ({
      width: 10,
      height: 10,
      boxShadow: 'unset',
      backgroundColor: ownerState.checked
        ? theme.palette.primary.main
        : theme.palette.text.secondary,
      borderRadius: '50%',

      '.Mui-checked &': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    switchBase: () => ({
      padding: 5,

      '&.Mui-checked': {
        transform: 'translateX(10px)',

        '& + .MuiSwitch-track': {
          backgroundColor: 'unset',
        },
      },
    }),
  },
};
