import { IconButton } from '@mui/material';
import { DoubleCheckIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Hint from '../../../../components/common/Hint';
import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import { PopoverSize } from '../../../../components/ui/Popover';
import {
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { useMessage } from '../../../../context/messages/useMessage';

export const MessagesModalPreviewRecipientsParents: FC = () => {
  const { actualParents } = useMessage();

  return (
    <>
      {actualParents.map((parent) => (
        <GridRowStyled key={parent.school_user_relation.relation_id}>
          <GridRowItem noVerticalPadding>
            <GridRowName>
              <PersonCardBasic
                user={parent.school_user_relation}
                userType="parent"
                isListItem
                isUsernameClickable
              />
            </GridRowName>
            <GridRowCell>
              {parent.read ? (
                <Hint
                  icon={
                    <IconButton
                      sx={{ color: 'success.main', '&:hover': { color: 'success.main' } }}
                    >
                      <DoubleCheckIcon />
                    </IconButton>
                  }
                  size={PopoverSize.Small}
                >
                  <FormattedMessage id="messages-HasBeenRead" />
                </Hint>
              ) : (
                <IconButton sx={{ color: 'text.secondary' }}>
                  <DoubleCheckIcon />
                </IconButton>
              )}
            </GridRowCell>
          </GridRowItem>
        </GridRowStyled>
      ))}
    </>
  );
};
