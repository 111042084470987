import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import {
  CustomField,
  CustomFieldApplicableTo,
  GET_CUSTOM_FIELDS_FOR_SCHOOL_QUERY,
  useArchiveCustomFieldMutation,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { PlusIcon } from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../components/uikit/SimpleButton/SimpleButton';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../components/uikit-components/Grid/Grid';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { SchoolCustomFieldsDefault } from './SchoolCustomFieldsDefault';
import { SchoolCustomFieldsRow } from './SchoolCustomFieldsRow';

export const SchoolCustomFieldsContent: FC = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const { showError, showNotification } = useNotifications();
  const { customFields, activeCustomFields, archivedCustomFields, fetching } = useCustomFields({
    refetchOnMount: 'always',
  });
  const { mutateAsync: archiveCustomFieldForSchool } = useArchiveCustomFieldMutation();

  const queryClient = useQueryClient();

  const [showArchived, setShowArchived] = useState(false);

  const filteredCustomFields = activeCustomFields?.filter(
    (c) => !c.applicable_to.includes(CustomFieldApplicableTo.DefaultApplicationValues),
  );

  const hasCustomFields = Boolean(filteredCustomFields?.length);
  const hasArchivedCustomFields = Boolean(archivedCustomFields?.length);

  const handleEditClick = (fieldId: string) => {
    navigate(`/settings/custom_fields/${fieldId}`);
  };

  const handleRestoreClick = async (field: CustomField) => {
    await archiveCustomFieldForSchool(
      { id: field.id, archived: false },
      {
        onSuccess: () => {
          showNotification({
            textId: 'school-sections-CustomFields-Message-Restored',
            values: { name: field.label },
            type: 'success',
          });
        },
        onError: showError,
      },
    );

    queryClient.invalidateQueries([GET_CUSTOM_FIELDS_FOR_SCHOOL_QUERY]);
  };

  return (
    <Stack gap={2.5}>
      <SchoolCustomFieldsDefault />

      <Card>
        <CardHeader
          sx={{ pb: hasCustomFields || fetching ? undefined : 2.5 }}
          title={
            <Stack direction="row" alignItems="center">
              <FormattedMessage id="school-sections-CustomFields" />

              {fetching ? (
                <Skeleton
                  variant="circular"
                  sx={(theme) => ({
                    ml: 1,
                    width: theme.spacing(2.5),
                    height: theme.spacing(2.5),
                  })}
                />
              ) : (
                hasCustomFields && <Counter>{filteredCustomFields?.length}</Counter>
              )}
            </Stack>
          }
          action={
            <>
              {!fetching && hasArchivedCustomFields && (
                <FormControlLabel
                  sx={(theme) => ({
                    m: theme.spacing(0, 3, 0),
                    '&:hover': {
                      color: 'primary.main',
                      ' .MuiSwitch-track, .Mui-checked+.MuiSwitch-track': { opacity: 1 },
                    },
                  })}
                  control={
                    <Switch
                      checked={showArchived}
                      onChange={(event, value) => setShowArchived(value)}
                    />
                  }
                  label={$t({ id: 'action-ShowArchived' })}
                />
              )}

              {!fetching && (
                <Link to="new">
                  <SimpleButton
                    startIcon={<PlusIcon />}
                    data-test-id="school-settings-custom-fields-add-button"
                  >
                    <FormattedMessage id="school-sections-CustomFields-AddCustomField" />
                  </SimpleButton>
                </Link>
              )}
            </>
          }
        />
        {(hasCustomFields || (hasArchivedCustomFields && showArchived)) && (
          <CardContent>
            <GridContainer
              sx={{
                borderRadius: 0,
              }}
            >
              {fetching && (
                <Stack>
                  {new Array(customFields?.length).fill(true).map((_, index) => (
                    <GridRowStyled
                      key={index}
                      noBorder
                      noBorderRadius
                      sx={{ '&:hover': { backgroundColor: 'background.paper' } }}
                    >
                      <GridRowItem>
                        <GridRowName>
                          <Skeleton />
                        </GridRowName>
                      </GridRowItem>
                    </GridRowStyled>
                  ))}
                </Stack>
              )}
              {!fetching &&
                filteredCustomFields?.map((field) => (
                  <SchoolCustomFieldsRow
                    key={field.id}
                    field={field}
                    onRowClick={() => handleEditClick(field.id)}
                  />
                ))}

              {!fetching && showArchived && hasArchivedCustomFields && (
                <>
                  <GridRowStyled
                    noBorderRadius
                    sx={{ '&:hover': { backgroundColor: 'background.paper' } }}
                  >
                    <GridRowItem>
                      <Typography
                        color="text.secondary"
                        variant="h4"
                        mt={hasArchivedCustomFields ? 2 : 0}
                      >
                        <FormattedMessage id="filter-Archived" />
                      </Typography>
                    </GridRowItem>
                  </GridRowStyled>

                  {archivedCustomFields?.map((field) => (
                    <SchoolCustomFieldsRow
                      key={field.id}
                      field={field}
                      onRowClick={() => handleRestoreClick(field)}
                    />
                  ))}
                </>
              )}
            </GridContainer>
          </CardContent>
        )}
      </Card>
    </Stack>
  );
};
