import { Chip, ChipProps, IconButton, styled } from '@mui/material';

import { CrossIcon } from '../../assets/assets';

export interface TagSelectProps extends ChipProps {
  outlined?: boolean;
}

export const TagSelect = styled(Chip, {
  shouldForwardProp: (prop) => !(['outlined'] as PropertyKey[]).includes(prop),
})<TagSelectProps>(({ theme, outlined }) => ({
  ...theme.typography.h3,
  backgroundColor: theme.palette.background.default,
  height: 'unset',

  '&.MuiChip-colorDefault': {
    color: theme.palette.primary.main,
  },

  ...(outlined && {
    '&::before': {
      content: '" "',
      position: 'absolute',
      height: 'calc(100% + 6px)',
      width: 'calc(100% + 6px)',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadiusLg,
    },
  }),

  '&.MuiChip-clickable:hover': {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.common.light3,
  },

  '& .MuiChip-label': {
    padding: theme.spacing('1px', 1),
  },

  '& .MuiChip-icon': {
    marginLeft: theme.spacing(1),
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    color: theme.palette.primary.main,
  },

  '& .MuiChip-iconSmall': {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },

  '& .MuiChip-deleteIcon': {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.5),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },

  '& .MuiChip-deleteIconSmall': {
    marginRight: 1,
    width: theme.spacing(2),
    height: theme.spacing(2),
  },

  '&.MuiChip-sizeSmall': {
    ...theme.typography.body1,

    '& .MuiChip-label': {
      padding: theme.spacing(0, 1),
    },
  },

  '&.MuiChip-filled': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,

    '& .MuiChip-icon': {
      color: theme.palette.primary.contrastText,
    },

    '&.MuiChip-clickable:hover, &.hoveredTag': {
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
}));

TagSelect.defaultProps = {
  className: 'TagSelect',
  variant: 'outlined',
  deleteIcon: (
    <IconButton
      sx={{
        p: '3px',

        '&:hover': {
          bgcolor: 'common.light2',
          color: 'text.primary',
        },
      }}
    >
      <CrossIcon />
    </IconButton>
  ),
};
