import { Stack } from '@mui/material';
import { ApplicationCustomField } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import { convertCustomFieldsToApplicationCustomFields } from '@schooly/utils/application-helpers';
import isEqual from 'lodash.isequal';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { CreateCustomField } from '../../CreateCustomField.type';
import { CustomFieldFormContainer } from '../../CustomFieldsFormContainer';
import { ApplicationAdditionalInformationPreview } from '../ApplicationPreviewCommon/ApplicationAdditionalInformationPreview';
import { EditDialog } from './EditDialog';

export interface ApplicationEditablePreviewGeneralCustomFieldsProps {
  generalCustomFields: CreateCustomField[];
  onSubmit: (v: CreateCustomField[], type: string) => void;
  canEdit: boolean;
  withNotes?: boolean;
  updating?: Record<string, boolean>;
  updatingIcon?: React.ReactNode;
}

interface CreateGeneralCustomFieldsForm {
  custom_fields: CreateCustomField[];
}

export const ApplicationEditablePreviewGeneralCustomFields: FC<ApplicationEditablePreviewGeneralCustomFieldsProps> =
  ({ generalCustomFields, onSubmit, canEdit, withNotes, updating, updatingIcon }) => {
    const { formatMessage } = useIntl();

    const [selectedCustomField, setSelectedCustomField] =
      useState<ApplicationCustomField | null>(null);

    const [isFormOpen, showFormModal, hideFormModal] = useFlag();

    const form = useForm<CreateGeneralCustomFieldsForm>({
      mode: 'onChange',
      values: {
        custom_fields: generalCustomFields,
      },
    });

    useEffect(() => {
      if (!form.formState.isValid) {
        form.trigger();
      }
    }, [form, generalCustomFields]);

    const onEditClick = (customField: ApplicationCustomField | null) => {
      setSelectedCustomField(customField);
      showFormModal();
    };

    const normalizedGeneralCustomFields =
      convertCustomFieldsToApplicationCustomFields(generalCustomFields);

    return (
      <FormProvider {...form}>
        <form>
          <Stack flex="1 1 50%" spacing={3}>
            <ApplicationAdditionalInformationPreview
              customFields={normalizedGeneralCustomFields}
              editable={canEdit}
              updating={updating}
              updatingIcon={updatingIcon}
              withTitle={false}
              maxWidth={800}
              onEdit={(customField) => onEditClick(customField)}
            />
          </Stack>

          <EditDialog
            name={formatMessage({ id: 'userType-general-information' })}
            isOpen={isFormOpen}
            key={`edit-general-info`}
            onClose={() => {
              form.reset({ custom_fields: generalCustomFields });
              hideFormModal();
            }}
            onConfirm={async () => {
              const isValid = await form.trigger();

              if (!isValid) {
                return;
              }

              const updatedCustomFields = form.getValues();

              if (!isEqual(updatedCustomFields, generalCustomFields)) {
                onSubmit(updatedCustomFields.custom_fields || [], '');
              }

              hideFormModal();
            }}
          >
            {selectedCustomField && (
              <CustomFieldFormContainer selectedCustomField={selectedCustomField} />
            )}
          </EditDialog>
        </form>
      </FormProvider>
    );
  };
