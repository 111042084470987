import { Stack, Typography } from '@mui/material';
import { USER_ROLE_PERMISSION_OPTIONS } from '@schooly/constants';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import FormWrapper from '../../../../components/ui/FormWrapper';
import { FormSelect } from '../../../../components/ui/Input';
import {
  CreateUserRoleModalSection,
  PermissionsToAssign,
  useUserRoleModal,
} from '../Context/useUserRoleModal';

interface UserRolePermissionsToAssignProps {
  permissionsToAssign: PermissionsToAssign;
}

export const UserRolePermissionsToAssign: FC<UserRolePermissionsToAssignProps> = ({
  permissionsToAssign,
}) => {
  const { mode, canManageRole, actions } = useUserRoleModal({});

  const inputForm = useForm<{ selectedPermissionsToAssign: PermissionsToAssign }>({
    mode: 'onChange',
    defaultValues: {
      selectedPermissionsToAssign: permissionsToAssign,
    },

    resolver: (values) => {
      actions.setPermissionsToAssign(values.selectedPermissionsToAssign);
      return { values, errors: {} };
    },
  });

  return (
    <Stack px={mode === CreateUserRoleModalSection.Configuration ? 2.5 : 0} gap={1}>
      <Typography variant="h3">
        <FormattedMessage id="userRoles-StaffAssignee" />
      </Typography>
      <FormWrapper form={inputForm} onSubmit={() => {}}>
        <FormSelect
          name="selectedPermissionsToAssign"
          labelTextId="userRoles-Permissions"
          multiLabelTextId="userRoles-Permissions"
          multiple
          options={USER_ROLE_PERMISSION_OPTIONS}
          locked={!canManageRole}
        />
      </FormWrapper>
    </Stack>
  );
};
