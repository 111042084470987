import { Button, Stack } from '@mui/material';
import {
  GetStaffQueryFilters,
  GetStaffQuerySort,
  StaffArrangeBy,
  StaffSearchResult,
  useGetStaffQuery,
  useGroupAvailableCriteriaCountQuery,
} from '@schooly/api';
import {
  ArrangedByCollapsableSection,
  ArrangedByCollapsableSectionSkeleton,
  getArrangeBySectionsFromAvailableCriteria,
  getCombinedRowsFromSearchResults,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { PROPERTIES_TEXT_IDS, SchoolUserRole } from '@schooly/constants';
import { EyeIcon, GridBody, MainPageGrid, SkeletonRows } from '@schooly/style';
import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StaffHeader, StaffRow } from './StaffGrid';

type StaffArrangedByListProps = {
  schoolId: string;
  arrangeBy: StaffArrangeBy;
  filters: GetStaffQueryFilters;
  showZeroCounts?: boolean;
  sort?: GetStaffQuerySort;
  query?: string;
  onChangeSort: (v: GetStaffQuerySort) => void;
  onSetTotalCount: (v?: number) => void;
};
export const StaffArrangedByList: FC<StaffArrangedByListProps> = ({
  schoolId,
  arrangeBy,
  filters,
  showZeroCounts = true,
  sort,
  query,
  onChangeSort,
  onSetTotalCount,
}) => {
  const { $t } = useIntl();

  const { data, isLoading } = useGroupAvailableCriteriaCountQuery(
    {
      schoolId,
      relationRole: SchoolUserRole.Staff,
      fromDate: filters.date![0]!,
      schoolPropertyIds: [
        ...(filters.age_group || []),
        ...(filters.status || []),
        ...(filters.house || []),
        ...(filters.department || []),
        ...(filters.subject || []),
      ],
      nationalities: filters.nationality || [],
      genders: filters.gender || [],
      showNoneCounts: true,
      searchQuery: query,
    },
    { refetchOnMount: true, enabled: !!filters.date?.[0] },
  );

  useEffect(() => {
    onSetTotalCount(data?.total_count);
  }, [onSetTotalCount, data?.total_count]);

  const sections = useMemo(() => {
    if (!data) return [];

    return getArrangeBySectionsFromAvailableCriteria({
      criteria: data.available_criteria,
      arrangeByKey: arrangeBy,
      allowZeroCounts: showZeroCounts,
    });
  }, [data, arrangeBy, showZeroCounts]);

  if (isLoading) return <ArrangedByCollapsableSectionSkeleton />;

  if (!sections.length) return <MainGridNoResultsStub textId="staff-NoResults-title" />;

  return (
    <>
      {sections.map((section) => (
        <ArrangedByCollapsableSection
          key={section.arrangeByKey + section.arrangeByValue}
          isExpanded={sections.length === 1}
          count={section.count}
          title={
            section.isNoneSection
              ? `${$t({ id: 'without' })}  ${$t({ id: PROPERTIES_TEXT_IDS[section.arrangeByKey] })}`
              : section.title
          }
        >
          <ArrangedByStaffGrid
            count={section.count}
            sort={sort}
            filters={filters}
            arrangeByKey={section.arrangeByKey}
            arrangeByValue={!section.isNoneSection ? section.arrangeByValue : undefined}
            schoolId={schoolId}
            query={query}
            onChangeSort={onChangeSort}
          />
        </ArrangedByCollapsableSection>
      ))}
    </>
  );
};

type ArrangedByStaffGridProps = {
  count: number;
  filters: GetStaffQueryFilters;
  arrangeByKey?: StaffArrangeBy;
  arrangeByValue?: string | number;
  schoolId: string;
  sort?: GetStaffQuerySort;
  query?: string;
  onChangeSort: (v: GetStaffQuerySort) => void;
};

const PAGE_SIZE = 30;
const HEADER_OFFSET = 50;

export const ArrangedByStaffGrid: FC<ArrangedByStaffGridProps> = ({
  count,
  sort,
  filters: initialFilters,
  schoolId,
  onChangeSort,
  arrangeByKey,
  arrangeByValue,
  query,
}) => {
  const filters = useMemo(
    () =>
      arrangeByKey
        ? {
            ...initialFilters,
            [arrangeByKey]: [arrangeByValue === undefined ? 'none' : arrangeByValue],
          }
        : initialFilters,
    [initialFilters, arrangeByKey, arrangeByValue],
  );

  const { data, isLoading, params, setParams, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetStaffQuery(
      {
        schoolId,
        pageSize: PAGE_SIZE,
        sort,
        filters,
        query,
      },
      { refetchOnMount: 'always' },
    );

  useEffect(() => {
    setParams((params) => ({ ...params, filters, sort, query }));
  }, [sort, filters, query, setParams]);

  const entries = useMemo(
    () =>
      getCombinedRowsFromSearchResults(
        data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as StaffSearchResult[]) ??
          [],
      ),
    [data?.pages],
  );

  return (
    <MainPageGrid
      bottomElement={
        hasNextPage && !isFetchingNextPage ? (
          <Stack mt={1} alignItems="center">
            <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
              <FormattedMessage id="action-ShowMoreButton" />
            </Button>
          </Stack>
        ) : undefined
      }
    >
      <StaffHeader
        sort={params.sort}
        onChangeSort={onChangeSort}
        sx={{
          position: 'sticky',
          bgcolor: 'white',
          zIndex: 3,
          top: HEADER_OFFSET,
        }}
      />
      <GridBody>
        {entries?.map((entry) => (
          <StaffRow schoolId={schoolId} combinedStaffRow={entry} key={entry.id} />
        ))}
        {(isLoading || isFetchingNextPage) && (
          <SkeletonRows
            columnsCount={6}
            amount={Math.min(PAGE_SIZE, count - (entries?.length || 0))}
          />
        )}
      </GridBody>
    </MainPageGrid>
  );
};
