import { ChipProps } from '@mui/material';
import { usePrev } from '@react-spring/shared';
import { Recurrence } from '@schooly/api';
import { Spin, TagSelect } from '@schooly/style';
import { FC, useEffect } from 'react';

export type RecurrenceTagSelectProps = {
  id: string;
  schoolId: string;
  onClear: () => void;
  recurrence: Recurrence | null;
} & Omit<ChipProps, 'label'>;

export const RecurrenceTagSelect: FC<RecurrenceTagSelectProps> = ({
  id,
  schoolId,
  onClear,
  recurrence,
  ...props
}) => {
  const selectedOptionTitle = recurrence?.title;

  const prevTitle = usePrev(selectedOptionTitle);

  useEffect(() => {
    if (prevTitle && !selectedOptionTitle) {
      onClear();
    }
  }, [onClear, prevTitle, selectedOptionTitle]);

  return <TagSelect label={selectedOptionTitle ? selectedOptionTitle : <Spin />} {...props} />;
};
