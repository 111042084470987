export const INVITE_ID_SEARCH_PARAM = 'invite_id';
export const SIGNUP_ON_UNAUTHORIZED_SEARCH_PARAM = 'redirect_to_signup';
export const PARENT_APPLICATIONS_SCHOOL_ID_PARAM = 'school_id';
export const PARENT_APPLICATIONS_LANGUAGE_PARAM = 'language';
export const PARENT_APPLICATIONS_TRANSLUCENT_PARAM = 'translucent';
export const REDIRECT_SEARCH_PARAM = 'auth_redirect';
export const TOKEN_SEARCH_PARAM = 'token';
export const EMAIL_SEARCH_PARAM = 'email';
export const FROM_SEARCH_PARAM = 'from';
export const PRODUCTS_YEAR_ID_PARAM = 'year_id';
