import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import { InternalAccount, Product, XeroAccount } from '@schooly/api';
import { DropdownSelect } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import {
  CrossIcon,
  DoneIcon,
  ModalContent,
  ModalHeader,
  ModalMain,
  TypographyWithOverflowHint,
} from '@schooly/style';
import React, { FC, PropsWithChildren, ReactNode, useCallback, useRef } from 'react';
import {
  Controller,
  ControllerProps,
  FieldValues,
  FormProvider,
  get,
  useForm,
  useFormContext,
} from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

export type ReplaceProductsAccountsForm = {
  replace_to_accounts: Array<{
    product: Product;
    account: XeroAccount | InternalAccount;
  }>;
};

export type ReplaceProductsAccountsContentProps = {
  title: ReactNode;
  onClose?: () => void;
  products: Product[];
  accounts: Array<XeroAccount | InternalAccount>;
  onSubmit: (f: ReplaceProductsAccountsForm) => void;
};

export const ReplaceProductsAccountsContent: FC<
  PropsWithChildren<ReplaceProductsAccountsContentProps>
> = ({ title, onClose, products, accounts, onSubmit, children }) => {
  const { $t } = useIntl();
  const { showError } = useNotifications();

  const form = useForm<ReplaceProductsAccountsForm>({
    defaultValues: { replace_to_accounts: products.map((product) => ({ product })) },
  });

  const handleError = useCallback(() => {
    showError({ message: $t({ id: 'legalEntities-SelectAccountErrorLabel' }) });
  }, [$t, showError]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, handleError)}>
        <ModalHeader
          sx={{
            py: 1.75,
          }}
          active
          title={title}
        >
          {onClose && (
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          )}
        </ModalHeader>
        <ModalMain pt={0}>
          <ModalContent
            active
            sx={{
              pt: 0,
            }}
          >
            {products.map((p, i) => {
              return (
                <Box key={p.id} height={72} borderBottom={'1px solid #eee'}>
                  <Stack direction="row" gap={2} pl={1}>
                    <Typography pt={3} variant="h3" color="common.grey2" width={300}>
                      {p.name}
                    </Typography>

                    <AccountSelect
                      rules={{
                        required: true,
                      }}
                      accounts={accounts}
                      name={`replace_to_accounts.${i}.account`}
                    />
                  </Stack>
                </Box>
              );
            })}
          </ModalContent>
        </ModalMain>
        {children}
      </form>
    </FormProvider>
  );
};

type AccountSelectProps<A> = {
  accounts: A[];
  name: string;
} & Omit<ControllerProps<FieldValues>, 'render'>;

export const AccountSelect = <A extends { id: string; name: string }>({
  accounts,
  name,
  ...props
}: AccountSelectProps<A>) => {
  const dropdownRef = useRef<DropdownSelect | null>(null);
  const { control, formState } = useFormContext();
  const error = get(formState.errors, name);
  const { $t } = useIntl();

  return (
    <Stack
      pt={2.25}
      width={500}
      sx={{
        '& .MuiTypography-root': {
          ...(error && { color: 'error.main' }),
        },
      }}
    >
      <Controller
        {...props}
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <DropdownSelect
              ref={dropdownRef}
              placeholder={
                !field.value?.account ? $t({ id: 'legalEntities-SelectAccount' }) : undefined
              }
              hasValues={!!field.value?.account}
              error={error ? { type: error.type, message: error.message } : undefined}
              borderOnHover
              renderContent={() => (
                <>
                  {accounts.map((account) => {
                    const isSelected = field.value?.account?.id === account.id;
                    return (
                      <Stack
                        key={account.id}
                        onClick={() => {
                          field.onChange({ account });
                          dropdownRef.current?.close();
                        }}
                        {...field}
                        flexDirection="row"
                        alignItems="center"
                        px={1}
                        gap={2}
                        py={0.5}
                        sx={(theme) => ({
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: theme.palette.background.default,
                          },
                        })}
                      >
                        <TypographyWithOverflowHint
                          flex={1}
                          color={!isSelected ? 'common.grey2' : undefined}
                          variant="h3"
                          noWrap
                        >
                          {account.name}
                        </TypographyWithOverflowHint>

                        <Icon
                          sx={{
                            visibility: isSelected ? 'visible' : 'hidden',
                          }}
                        >
                          <DoneIcon />
                        </Icon>
                      </Stack>
                    );
                  })}
                </>
              )}
              layoutStyleProps={{
                minHeight: '35px',
                '.right-icon': {
                  top: 8,
                },
              }}
            >
              {() => <Typography variant="h3">{field.value?.account?.name}</Typography>}
            </DropdownSelect>
          );
        }}
      />
    </Stack>
  );
};
