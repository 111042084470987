import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { eachMonthOfInterval, parse } from 'date-fns';
import { useMemo } from 'react';

import { AnnualPlannerCalendarWithDates } from './scheme';

export const useAnnualPlannerGrid = (params: AnnualPlannerCalendarWithDates) => {
  return useMemo(() => {
    const start =
      typeof params.start === 'string'
        ? parse(params.start, DEFAULT_DATE_FORMAT_FNS, new Date())
        : params.start;

    const end =
      typeof params.end === 'string'
        ? parse(params.end, DEFAULT_DATE_FORMAT_FNS, new Date())
        : params.end;

    const rows = 31;

    const months = eachMonthOfInterval({ start, end });
    const days = new Array(rows).fill(true).map((_, index) => index + 1);

    return {
      start,
      end,
      cols: months.length,
      rows,
      months,
      days,
    };
  }, [params.start, params.end]);
};
