import React from 'react';

import buildClassName from '../../../utils/buildClassName';
import {
  LongStringWithTooltip,
  LongStringWithTooltipProps,
} from '../LongStringWithTooltip/LongStringWithTooltip';

export const LongNameWithTooltip: React.FC<LongStringWithTooltipProps> = ({
  paragraphClassName,
  ...props
}) => {
  return (
    <LongStringWithTooltip
      paragraphClassName={buildClassName('h3 gray-700 weight-400', paragraphClassName)}
      {...props}
    />
  );
};
