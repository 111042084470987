import { Stack, styled } from '@mui/material';

import SearchInput from '../../../../components/ui/SearchInput';

export const GroupPreviewModalAssessmentsHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2.5),
  gap: theme.spacing(2.5),
}));

export const GroupPreviewModalAssessmentsSearchInput = styled(SearchInput)(({ theme }) => ({
  maxWidth: theme.spacing(61),
  margin: 0,

  '& .form-control': {
    border: 'none',
  },

  '.input-group-text': {
    border: 'none',
  },

  [theme.breakpoints.down('lg')]: {
    height: 36,
    '&.SearchInput': {
      '& input:not(:focus)': {
        border: theme.mixins.borderValue(),
        borderLeft: 'none',
      },
      '& .form-control': {
        border: theme.mixins.borderValue(),
        borderLeft: 'none',
      },
      '& .input-group-text': {
        border: theme.mixins.borderValue(),
      },
    },
  },
}));

export const GroupPreviewModalAssessmentsMonths = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2.5),
}));

export const GroupPreviewModalAssessmentsMonthName = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1.25),
}));
