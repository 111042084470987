import { FC, useEffect, useState } from 'react';
import { useDragLayer } from 'react-dnd';

import {
  AnnualPlannerCalendarCell,
  AnnualPlannerCalendarGrid,
} from '../AnnualPlannerCalendar.styled';
import { ANNUAL_PLANNER_DAY_CELL_WIDTH, AnnualPlannerCalendarWithDates } from '../scheme';
import { useAnnualPlannerGrid } from '../useAnnualPlannerGrid';
import { getAnnualPlannerCell } from '../utils';
import { AnnualPlannerDropLayoutTarget } from './AnnualPlannerDropLayoutTarget';

export interface AnnualPlannerDropLayoutProps extends AnnualPlannerCalendarWithDates {}

export const AnnualPlannerDropLayout: FC<AnnualPlannerDropLayoutProps> = (props) => {
  const { isDragging } = useDragLayer((monitor) => ({ isDragging: monitor.isDragging() }));
  const { start, end, cols, months, days } = useAnnualPlannerGrid(props);

  const [zIndex, setZIndex] = useState<number | undefined>(-1);

  /*
   * Changes `zIndex` on next tick as react-dnd starts really dragging on next tick after
   * the `isDragging` flag is set.
   * If set zIndex synchronously, the real drag is not yet started which will break
   * the entire process as the layout will cover the dragging item from above.
   */
  useEffect(() => {
    setTimeout(() => {
      setZIndex(isDragging ? undefined : -1);
    }, 0);
  }, [isDragging]);

  return (
    <AnnualPlannerCalendarGrid
      className={[
        AnnualPlannerCalendarGrid.defaultProps?.className ?? '',
        'AnnualPlannerCalendar-DropLayout-root',
      ].join(' ')}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        borderTop: '1px solid transparent',
        borderLeft: '1px solid transparent',
        gridTemplateColumns: `${ANNUAL_PLANNER_DAY_CELL_WIDTH}px repeat(${cols}, 1fr)`,

        zIndex,
      }}
    >
      <AnnualPlannerCalendarCell
        sx={(theme) => ({
          borderRight: theme.mixins.borderValue(),
          borderBottom: theme.mixins.borderValue(),
        })}
      >
        {/* top-left empty cell */}
      </AnnualPlannerCalendarCell>

      {months.map((month) => {
        return (
          <AnnualPlannerCalendarCell
            key={`header-col-${month.getMonth()}-${month.getFullYear()}`}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
            })}
          >
            {/* header months names */}
          </AnnualPlannerCalendarCell>
        );
      })}

      {days.map((day) => (
        <>
          <AnnualPlannerCalendarCell
            key={`col-date-${day}`}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
            })}
          >
            {/* left-side day nums */}
          </AnnualPlannerCalendarCell>
          {months.map((month) => {
            const { date } = getAnnualPlannerCell({
              month,
              day,
              start,
              end,
            });

            return (
              <AnnualPlannerDropLayoutTarget
                key={`col-${day}-${month.getMonth()}-${month.getFullYear()}`}
                className={[
                  AnnualPlannerCalendarCell.defaultProps?.className ?? '',
                  'AnnualPlannerCalendar-DropLayout-cell',
                ].join(' ')}
                date={date}
              />
            );
          })}
        </>
      ))}
    </AnnualPlannerCalendarGrid>
  );
};
