import { Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback } from 'react';

import { ColumnRecordActive } from '../../scheme';
import { CalendarRecordCellActiveStyled, CalendarRecordCellContent } from '../CalendarWeek.styled';

export interface CalendarRecordActiveProps extends ColumnRecordActive {}

export const CalendarRecordCellActive: FC<CalendarRecordActiveProps> = ({
  start,
  end,
  isPast,
  size,
  record,
}) => {
  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      record.onClick?.(event, record);
    },
    [record],
  );

  const renderContent = () => (
    <CalendarRecordCellActiveStyled
      size={size}
      isPast={isPast}
      onClick={record.onClick && handleClick}
      shadowed={record.shadowed}
    >
      <CalendarRecordCellContent>
        <Typography variant="caption">
          {format(start, 'HH:mm')} - {format(end, 'HH:mm')}
        </Typography>
      </CalendarRecordCellContent>
    </CalendarRecordCellActiveStyled>
  );

  return record.tooltip ? (
    <Tooltip {...record.tooltip}>{renderContent()}</Tooltip>
  ) : (
    renderContent()
  );
};
