import { Typography } from '@mui/material';
import {
  GetMiniListQueryFilters,
  MINI_LIST_QUERY_FILTER_KEYS,
  SchoolRelation,
  SchoolUserType,
  SimpleListResult,
  useGetMiniListQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { pickOnlyParamsFromFilterKeys } from '../utils';
import { renderUserTags } from './UserExpandedSelect';
import { UserSelectRow } from './UserSelectRow';

type UserSelectMultipleProps = PropsWithChildren<{
  type: SchoolUserType;
  schoolId: string;
  selectedIds: string[];
  currentUser?: SchoolRelation;
  onSelectUserId: (v: string) => void;
  filters?: GetMiniListQueryFilters;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;
export const UserSelectMultiple: FC<UserSelectMultipleProps> = ({
  type,
  schoolId,
  selectedIds,
  currentUser,
  placeholder,
  onSelectUserId,
  filters = {},
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();
  const { permissions } = useAuth();
  const [queryEnabled, setQueryEnabled] = useState(false);
  const canRequestData = permissions.includes(`${type}_viewer`);

  const { data, isLoading, hasNextPage, params, setParams, isFetchingNextPage, fetchNextPage } =
    useGetMiniListQuery(
      {
        schoolId,
        type,
        query: '',
        filters: pickOnlyParamsFromFilterKeys(MINI_LIST_QUERY_FILTER_KEYS, filters),
      },
      { enabled: queryEnabled && canRequestData },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(() => {
    const currentUserRow = currentUser ? (
      <UserSelectRow
        overrideName={formatMessage({ id: 'filter-Me' })}
        user={currentUser}
        isSelected={selectedIds.includes(currentUser.relation_id)}
        onClick={() => {
          onSelectUserId(currentUser.relation_id);
          handleChangeQuery('');
        }}
      />
    ) : undefined;

    if (!canRequestData) {
      return (
        <>
          {currentUserRow}
          <Typography p={1}>
            <FormattedMessage id="error-NoPermissionsToRequest" />
          </Typography>
        </>
      );
    }

    if (!data) return <SelectContentSkeleton />;

    const entries =
      data.pages.reduce<SimpleListResult[]>((prev, curr) => [...prev, ...curr.results], []) ?? [];

    if (!entries.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {currentUserRow}
        {entries.map((user) => {
          if (!user.user_id) return null;
          return (
            <UserSelectRow
              onClick={() => {
                onSelectUserId(user.relation_id);
                handleChangeQuery('');
              }}
              key={user.user_id}
              user={user}
              isSelected={selectedIds.includes(user.user_id)}
            />
          );
        })}
      </>
    );
  }, [
    formatMessage,
    canRequestData,
    currentUser,
    data,
    handleChangeQuery,
    onSelectUserId,
    selectedIds,
  ]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={placeholder}
      hasValues={selectedIds.length > 0}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={canRequestData && hasNextPage}
      onFetchNextPage={fetchNextPage}
    >
      {(opened) => (
        <>
          {renderUserTags({
            ids: selectedIds,
            tagProps: {
              currentUser,
              schoolId,
              userType: type,
              size: placeholder ? 'small' : undefined,
              sx: { maxWidth: 200 },
            },
            onDelete: opened ? onSelectUserId : undefined,
          })}
          {opened && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={params.query || ''}
              onChangeText={handleChangeQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
