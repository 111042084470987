import { Icon, IconButton, Stack, Typography } from '@mui/material';
import { ProductBillingType } from '@schooly/api';
import {
  CrossIcon,
  EditIcon,
  ModalHeader,
  RecurringIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

type SchoolProductModalHeaderProps = {
  name: string;
  obligatory: boolean;
  statusName?: string;
  onClose: () => void;
  onEdit?: () => void;
  triggerTextId: string;
  type: ProductBillingType;
  renderProductName?: () => ReactNode;
};
export const SchoolProductModalHeader: FC<SchoolProductModalHeaderProps> = ({
  name,
  obligatory,
  statusName,
  onClose,
  onEdit,
  triggerTextId,
  type,
  renderProductName,
}) => {
  const { $t } = useIntl();
  return (
    <ModalHeader
      active
      sx={(theme) => ({ paddingBottom: theme.spacing(1.5) })}
      title={
        <Stack mt={-0.5}>
          <Stack direction="row" gap={0.5} alignItems="center">
            {!renderProductName ? (
              <Typography variant="h2" color="primary.main">
                {name}
              </Typography>
            ) : (
              renderProductName()
            )}
          </Stack>
          <Stack flexDirection="row" gap={4}>
            {type === ProductBillingType.Recurring && (
              <Stack flexDirection="row" gap={0.5}>
                <Icon sx={{ color: 'common.grey2' }}>
                  <RecurringIcon />
                </Icon>

                <TypographyWithOverflowHint color="text.primary">
                  {$t({ id: 'products-ProductType-Recurring' })}
                </TypographyWithOverflowHint>
              </Stack>
            )}

            <TypographyWithOverflowHint color="text.primary">
              {$t({
                id: obligatory ? 'products-Product-Required' : 'products-Product-Optional',
              })}
            </TypographyWithOverflowHint>

            <TypographyWithOverflowHint color="text.primary">
              {$t({ id: triggerTextId }, { status: statusName ? `"${statusName}"` : undefined })}
            </TypographyWithOverflowHint>
          </Stack>
        </Stack>
      }
    >
      <Stack flexDirection="row" gap={2}>
        {!!onEdit && (
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
        )}
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </Stack>
    </ModalHeader>
  );
};
