import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateRangeSelect } from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import { COUNTRY_OPTIONS, LANGUAGE_OPTIONS } from '@schooly/constants';
import { DeleteIcon, PlusIcon, SimpleButton } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
import { format } from 'date-fns';
import { FC, useCallback, useRef } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { CreateChildForm } from './CreateChildContent.type';

const getEmptySchoolHistory = () => ({
  school_name: undefined,
  start_date: undefined,
  end_date: undefined,
  country: undefined,
  language: undefined,
});

export const PreviousSchoolFormContainer: FC = () => {
  const { control, getValues, setValue, clearErrors } = useFormContext<CreateChildForm>();
  const { $t } = useIntl();

  const containerRef = useRef<HTMLDivElement>(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'school_history',
  });

  const addEmptySchoolHistory = useCallback(() => {
    append(getEmptySchoolHistory());
    if (containerRef) {
      setTimeout(() => containerRef.current?.scrollIntoView({ behavior: 'smooth' }), 100);
    }
  }, [append]);

  return (
    <Stack gap={3} ref={containerRef}>
      {fields.map((f, index) => {
        return (
          <Stack direction="row" gap={2} alignItems="flex-start" key={f.id} position="relative">
            <NamedInputForErrorScroll name={`school_history.${index}.value`} />
            <Stack spacing={2} sx={{ width: '100%' }}>
              <ControlTextField
                name={`school_history.${index}.school_name`}
                label={$t({ id: 'school-create-SchoolName' })}
                rules={{
                  required: true,
                }}
                fullWidth
              />
              <Stack width="100%">
                <Controller
                  control={control}
                  name={`school_history.${index}.start_date`}
                  rules={{ required: true }}
                  render={({ fieldState }) => {
                    const { start_date, end_date } = getValues(`school_history.${index}`);

                    return (
                      <DateRangeSelect
                        onSetDate={(date) => {
                          setValue(
                            `school_history.${index}.start_date`,
                            format(date[0], DEFAULT_DATE_FORMAT_FNS),
                          );
                          setValue(
                            `school_history.${index}.end_date`,
                            format(date[1], DEFAULT_DATE_FORMAT_FNS),
                          );
                          clearErrors([
                            `school_history.${index}.start_date`,
                            `school_history.${index}.end_date`,
                          ]);
                        }}
                        date={start_date && end_date ? [start_date, end_date] : []}
                        popperZIndex={(theme) => theme.zIndex.tooltip}
                        error={fieldState.error}
                      />
                    );
                  }}
                />
              </Stack>

              <Stack direction="row" gap={2}>
                <FormSelect2
                  name={`school_history.${index}.country`}
                  labelTextId="applications-SchoolHistory-Country"
                  rules={{
                    required: true,
                  }}
                  options={COUNTRY_OPTIONS}
                />
                <FormSelect2
                  name={`school_history.${index}.language`}
                  labelTextId="applications-SchoolHistory-Language"
                  rules={{
                    required: true,
                  }}
                  options={LANGUAGE_OPTIONS}
                />
              </Stack>
            </Stack>
            <IconButton
              inverse
              sx={{ pt: 1.5 }}
              onClick={() => {
                remove(index);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      })}

      {fields.length <= 1 && (
        <Box pt={1.75}>
          <SimpleButton startIcon={<PlusIcon />} onClick={addEmptySchoolHistory}>
            <Typography variant="h3">
              <FormattedMessage id="applications-SchoolHistory-AddSchool" />
            </Typography>
          </SimpleButton>
        </Box>
      )}
    </Stack>
  );
};

const NamedInputForErrorScroll: FC<{ name: string }> = ({ name }) => (
  <Box
    sx={{
      position: 'absolute',
      left: 0,
      top: '50%',
      pointerEvents: 'none',
      opacity: 0,
    }}
  >
    <input name={name} />
  </Box>
);
