import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="5" r="4" fill="#24275B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 19C19 14.0294 14.9706 10 10 10C5.02944 10 1 14.0294 1 19H19Z"
      fill="#24275B"
    />
  </svg>
);
