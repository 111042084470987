import { Components, Theme } from '@mui/material';

export const MuiCardContent: Components<Theme>['MuiCardContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(2.5),

      '&:last-child': {
        paddingBottom: theme.spacing(2.5),
      },
    }),
  },
};
