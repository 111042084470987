import { SuggestedChangeDataSet } from '@schooly/constants';
import { LocationIcon } from '@schooly/style';
import React from 'react';

import { useProfile } from '../../../context/profile/useProfile';
import { getUserAddress } from '../../../helpers/users';
import AddressModal from './AddressModal';
import ProfileProperty from './ProfileProperty';

interface AddressPropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}

const AddressProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
}: AddressPropertyParams) => {
  const { user, canEditProfile, hasEditingPermission } = useProfile();

  const value = user && getUserAddress(user);

  return (
    <ProfileProperty
      value={value}
      canEdit={canEditProfile && hasEditingPermission}
      params={{ size, iconColor, color }}
      renderIcon={() => <LocationIcon />}
      editValueTextId="peopleDetail-Address"
      valueTextId="peopleDetail-Address"
      dataSet={SuggestedChangeDataSet.Address}
      modalController={({ isOpen, hide }) => <AddressModal isOpen={isOpen} onClose={hide} />}
    />
  );
};

export default AddressProperty;
