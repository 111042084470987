import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" y="2" width="1" height="4" rx="0.5" fill="#B3BBCB" />
    <rect x="9" y="2" width="1" height="4" rx="0.5" fill="#B3BBCB" />
    <rect x="13" y="2" width="1" height="4" rx="0.5" fill="#B3BBCB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7C2.44772 7 2 7.44771 2 8V10H18V8C18 7.44772 17.5523 7 17 7H3ZM18 11H2V17C2 17.5523 2.44772 18 3 18H17C17.5523 18 18 17.5523 18 17V11Z"
      fill="#B3BBCB"
    />
  </svg>
);
