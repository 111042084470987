import { IconButton, Stack, Typography } from '@mui/material';
import { USER_ROLE_PERMISSION_OPTIONS } from '@schooly/constants';
import {
  Counter,
  CrossIcon,
  EditIcon,
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  ModalContent,
  PlusIcon,
  SimpleButton,
} from '@schooly/style';
import React, { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { ModalSmall } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { SchoolCreateUserRoleModal, SchoolCreateUserRoleSubmit } from './SchoolCreateUserRoleModal';
import { SchoolCreateUserRole } from './useSchoolCreate';

type SchoolCreateAccessModalProps = {
  title?: string;
  roles: SchoolCreateUserRole[];
  onClose: () => void;
  onSubmit: (roles: SchoolCreateUserRole[]) => void;
};

export const generateEmptyRole = () => ({
  name: '',
  description: '',
  editable: true,
  permission_to_assign: [],
  permissions: [],
});

export const SchoolCreateAccessModal: FC<SchoolCreateAccessModalProps> = ({
  title,
  roles,
  onClose,
  onSubmit,
}) => {
  const { $t } = useIntl();

  const [editRoleAtIndex, setEditRoleAtIndex] =
    useState<[SchoolCreateUserRole, number | undefined] | null>(null);

  const editRole = editRoleAtIndex?.[0];
  const atIndex = editRoleAtIndex?.[1];

  const handleCloseEditModal = useCallback(() => setEditRoleAtIndex(null), []);

  const handleSubmit = useCallback(
    (v: SchoolCreateUserRoleSubmit) => {
      const permissionToAssignSubmit = USER_ROLE_PERMISSION_OPTIONS.filter((option) =>
        v.permission_to_assign.some((p) => p === option.value),
      );

      onSubmit(
        atIndex === undefined
          ? [
              ...roles,
              {
                ...v,
                permission_to_assign: permissionToAssignSubmit,
              },
            ]
          : roles.map((r, i) =>
              i === atIndex
                ? {
                    ...v,
                    permission_to_assign: permissionToAssignSubmit,
                  }
                : r,
            ),
      );
      handleCloseEditModal();
    },
    [atIndex, handleCloseEditModal, onSubmit, roles],
  );

  const handleDelete = useCallback(() => {
    onSubmit(roles.filter((_, i) => i !== atIndex));
    handleCloseEditModal();
  }, [atIndex, handleCloseEditModal, onSubmit, roles]);

  return (
    <>
      <ModalSmall open onClose={onClose}>
        <ModalHeader active title={title}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Typography variant="h2">{$t({ id: 'school-tabs-Access' })}</Typography>
              <Counter sx={{ minWidth: 20, textAlign: 'center', fontWeight: 600 }}>
                {roles.length}
              </Counter>
            </Stack>
            <SimpleButton
              startIcon={<PlusIcon />}
              onClick={() => setEditRoleAtIndex([generateEmptyRole(), undefined])}
            >
              {$t({ id: 'school-tabs-Access-AddUserRole' })}
            </SimpleButton>
          </Stack>
          <GridContainer sx={{ mt: 2 }}>
            {roles?.map((role, i) => {
              return (
                <GridRowStyled
                  key={i}
                  sx={{
                    '& :hover': {
                      '.editRoleIcon': {
                        visibility: 'visible',
                      },
                    },
                  }}
                >
                  <GridRowItem>
                    <>
                      <GridRowName>{role.name}</GridRowName>

                      <IconButton
                        className="editRoleIcon"
                        sx={{
                          visibility: 'hidden',
                        }}
                        inverse
                        onClick={() => setEditRoleAtIndex([role, i])}
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  </GridRowItem>
                </GridRowStyled>
              );
            })}
          </GridContainer>
        </ModalContent>
      </ModalSmall>
      {editRole && (
        <SchoolCreateUserRoleModal
          role={editRole}
          onClose={handleCloseEditModal}
          onSubmit={handleSubmit}
          onDelete={atIndex !== undefined && roles.length > 1 ? handleDelete : undefined}
          otherRoles={roles.filter((_, i) => i !== atIndex)}
        />
      )}
    </>
  );
};
