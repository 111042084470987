import { Stack } from '@mui/material';
import { AssessmentMethodType } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import {
  AssessmentMethodTypeTagSelect,
  AssessmentMethodTypeTagSelectProps,
} from './AssessmentMethodTypeTagSelect';

type AssessmentMethodTypeExpandedSelectProps = PropsWithChildren<{
  selectedTypes: AssessmentMethodType[];
  onSelectType: (v: AssessmentMethodType) => void;
  onClear: () => void;
  onClose: () => void;
}>;

const OPTIONS = [
  AssessmentMethodType.Comment,
  AssessmentMethodType.Grade,
  AssessmentMethodType.Score,
];

export const AssessmentMethodTypeExpandedSelect: FC<AssessmentMethodTypeExpandedSelectProps> = ({
  selectedTypes,
  onSelectType,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderAssessmentMethodTypeTags({
          types: OPTIONS,
          tagProps: (type) => ({
            variant: selectedTypes.includes(type) ? 'filled' : undefined,
          }),
          onClick: onSelectType,
        })}
      </Stack>
    );
  }, [onSelectType, selectedTypes]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedTypes.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={renderContent}
    >
      {renderAssessmentMethodTypeTags({
        tagProps: { size: 'small' },
        types: selectedTypes,
        onDelete: onSelectType,
      })}
    </ExpandedSelect>
  );
};

type RenderAssessmentMethodTypeTagsParams = {
  types: AssessmentMethodType[];
  onDelete?: (v: AssessmentMethodType) => void;
  onClick?: (v: AssessmentMethodType) => void;
  tagProps?:
    | Omit<AssessmentMethodTypeTagSelectProps, 'methodType'>
    | ((v: AssessmentMethodType) => Omit<AssessmentMethodTypeTagSelectProps, 'methodType'>);
};

export const renderAssessmentMethodTypeTags = ({
  types,
  onDelete,
  onClick,
  tagProps,
}: RenderAssessmentMethodTypeTagsParams) =>
  types.map((v) => (
    <AssessmentMethodTypeTagSelect
      key={v}
      methodType={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
