import React from 'react';

import { useFilters } from '../../../../context/filters/useFilters';
import FilterControl from '../FilterControl';
import { IControl } from '../HeaderFilterPopup';

export interface StaffControlProps {
  control: IControl;
  shouldOpen: boolean;
  isOpen?: boolean;
}

export const GroupControl: React.FC<StaffControlProps> = ({ control, shouldOpen, isOpen }) => {
  const { listOptions } = useFilters();

  return (
    <FilterControl
      filterKey={control.key}
      titleTextId={control.titleTextId}
      popupTitleTextId={control.popupTitleTextId}
      options={listOptions.group}
      groupOptions={control.groupOptions}
      shouldOpen={shouldOpen}
      isFocusable={isOpen}
      isHeaderFilterPopupOpen={isOpen}
    />
  );
};
