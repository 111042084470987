import { Icon, Stack } from '@mui/material';
import { Event, EventsStatuses, GET_EVENT_QUERY, SignUpStatuses, SignUpType } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { getEventsStatusLabel } from '@schooly/components/filters';
import { HeaderActions } from '@schooly/components/header-actions';
import {
  RecurringConfirmOptions,
  RecurringConfirmType,
  RecurringLabel,
  useRecurringConfirmDialog,
} from '@schooly/components/recurring';
import { EditIcon, PublishIcon, UnpublishIcon } from '@schooly/style';
import { isNotFalse } from '@schooly/utils/predicates';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useEventNotifications } from '../../../context/events/useEventNotifications';
import { useEvent } from '../../../context/events/WithEvent';

interface EventPreviewActionsProps {
  event: Event;
  isIncomplete?: boolean;
}

export const EventPreviewActions: FC<EventPreviewActionsProps> = ({ event, isIncomplete }) => {
  const {
    isStarted,
    actions: { publishing, updateList, submitAction },
    canEdit,
  } = useEvent();
  const { showEventNotification } = useEventNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const { getRecurringApplyTypeConfirm } = useRecurringConfirmDialog();
  const navigate = useNavigate();
  const { $t } = useIntl();
  const queryClient = useQueryClient();
  const isForPublish = event.event_status === EventsStatuses.Draft && !isStarted;
  const followingCount = event?.recurring_state?.following_count;
  const recurringState = event.recurring_state;

  const confirmModalOptions: RecurringConfirmOptions[] = useMemo(
    () => [
      {
        value: RecurringConfirmType.Single,
        label: $t({
          id: 'events-recurring-ThisEvent',
        }),
      },
      {
        value: RecurringConfirmType.CurrentAndFollowing,
        label: $t({
          id:
            event.event_status === EventsStatuses.Published
              ? 'events-recurring-ThisAndFollowingPublishedEvents'
              : 'events-recurring-ThisAndFollowingDraftEvents',
        }),
      },
    ],
    [$t, event.event_status],
  );

  const confirmModalTitle = $t(
    {
      id:
        event.event_status === EventsStatuses.Published
          ? 'events-recurring-Cancel'
          : 'events-recurring-Publish',
    },
    {
      eventTitle: `"${event.title}"`,
    },
  );

  const handlePublish = useCallback(async () => {
    let withFollowing;

    if (followingCount) {
      const applyType = await getRecurringApplyTypeConfirm({
        options: confirmModalOptions,
        title: confirmModalTitle,
        subtitle: recurringState && <RecurringLabel recurringState={recurringState} />,
        actionButtonTextId: 'action-Publish',
      });
      if (!applyType) return false;
      withFollowing = applyType?.type === RecurringConfirmType.CurrentAndFollowing;
    }

    const notifyParents = await getConfirmation({
      textId: 'events-notification-NotifyAfterAction',
      textValues: {
        action: 'publishing',
      },
    });

    const res = await submitAction({
      event_ids: [event.id],
      action: 'publish',
      notify_parents: notifyParents,
      withFollowing,
    });

    if (!res) return false;

    queryClient.invalidateQueries([GET_EVENT_QUERY, { eventId: event.id }]);
    updateList();

    if (event?.recurring_state) {
      const recurrenceId = event.recurring_state.recurrence_id;
      const eventsCount = res?.event_count ?? 0;

      showEventNotification({
        count: eventsCount,
        id: event.id,
        title: event.title,
        textId: 'events-notification-Published',
        multipleTextId: 'events-notification-PublishedMultiple',
        recurrenceId,
      });
    }

    return true;
  }, [
    confirmModalOptions,
    confirmModalTitle,
    event.id,
    event.recurring_state,
    event.title,
    followingCount,
    getConfirmation,
    getRecurringApplyTypeConfirm,
    queryClient,
    recurringState,
    showEventNotification,
    submitAction,
    updateList,
  ]);

  const handleCancel = useCallback(async () => {
    let withFollowing;

    if (followingCount) {
      const applyType = await getRecurringApplyTypeConfirm({
        options: confirmModalOptions,
        title: confirmModalTitle,
        subtitle: recurringState && <RecurringLabel recurringState={recurringState} />,
      });
      if (!applyType) return false;
      withFollowing = applyType?.type === RecurringConfirmType.CurrentAndFollowing;
    }

    const notifyParents = await getConfirmation({
      textId: 'events-notification-NotifyAfterAction',
      textValues: {
        action: 'cancelling',
      },
    });

    const res = await submitAction({
      event_ids: [event.id],
      action: 'cancel',
      notify_parents: notifyParents,
      withFollowing,
    });
    if (!res) return false;

    queryClient.invalidateQueries([GET_EVENT_QUERY, { eventId: event.id }]);
    updateList();

    if (event?.recurring_state) {
      const recurrenceId = event.recurring_state.recurrence_id;
      const eventsCount = res?.event_count ?? 0;

      showEventNotification({
        count: eventsCount,
        id: event.id,
        title: event.title,
        textId: 'events-notification-Canceled',
        multipleTextId: 'events-notification-CanceledMultiple',
        recurrenceId,
      });
    }

    return true;
  }, [
    followingCount,
    getConfirmation,
    submitAction,
    event.id,
    event.recurring_state,
    event.title,
    queryClient,
    updateList,
    getRecurringApplyTypeConfirm,
    confirmModalOptions,
    confirmModalTitle,
    recurringState,
    showEventNotification,
  ]);

  const selectActions = useMemo(() => {
    return [
      isForPublish &&
        canEdit && {
          titleTextId: 'events-action-Publish',
          pendingTitle: $t({
            id: 'publishing',
          }),
          icon: <PublishIcon className="reset-svg-currentColor" />,
          handler: handlePublish,
        },
      event.event_status === EventsStatuses.Published &&
        canEdit && {
          titleTextId: 'events-action-Cancel',
          icon: <UnpublishIcon />,
          pendingTitle: $t({
            id: 'cancelling',
          }),
          handler: handleCancel,
        },
    ].filter(isNotFalse);
  }, [$t, canEdit, event.event_status, handleCancel, handlePublish, isForPublish]);

  const styleType = useMemo(() => {
    if (event.event_status === EventsStatuses.Canceled) {
      return 'error';
    } else if (event.event_status === EventsStatuses.Published) {
      return 'success';
    }

    return undefined;
  }, [event.event_status]);

  const hasNoTimeSlotsForSignUps =
    !event.date_times.length &&
    event.sign_ups?.some((s) => s.status === SignUpStatuses.Draft && s.type === SignUpType.Slots);

  const title = useMemo(() => {
    return isIncomplete
      ? $t({ id: 'status-Draft' })
      : $t({ id: getEventsStatusLabel(event.event_status) });
  }, [$t, event.event_status, isIncomplete]);

  const tooltip = useMemo(() => {
    if (!canEdit || event.event_status === EventsStatuses.Published) return;

    if (isIncomplete) return $t({ id: 'events-IncompleteHint' });

    if (hasNoTimeSlotsForSignUps) {
      return $t({ id: 'events-SignUp-Duration-NoTimeSlots' });
    }

    const isCriteriaEmpty = Object.values(event.criteria).every((v) => !v.length);

    if (isStarted || isCriteriaEmpty) {
      return (
        <Stack gap={1.25}>
          <FormattedMessage
            id={isCriteriaEmpty ? 'events-action-CriteriaIsEmpty' : 'events-action-Started'}
          />
          <Stack
            onClick={() => navigate('edit')}
            direction="row"
            gap={1}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Icon>
              <EditIcon />
            </Icon>
            <FormattedMessage
              id={isCriteriaEmpty ? 'events-action-SelectInvitees' : 'events-action-EditStartDate'}
            />
          </Stack>
        </Stack>
      );
    }
  }, [
    $t,
    canEdit,
    event.criteria,
    event.event_status,
    hasNoTimeSlotsForSignUps,
    isIncomplete,
    isStarted,
    navigate,
  ]);

  return (
    <HeaderActions
      title={title ?? ''}
      tooltipTitle={tooltip}
      actions={selectActions}
      styleType={styleType}
      pending={publishing}
      disabled={
        event.event_status === EventsStatuses.Canceled ||
        event.event_status === EventsStatuses.Past ||
        isIncomplete ||
        hasNoTimeSlotsForSignUps ||
        !canEdit
      }
    />
  );
};
