import {
  BaseUser,
  ReEnrollmentClosedRegistration,
  SchoolReEnrollmentResponse,
  StudentRegistration,
  useDeleteEnrollmentMutation,
  useUpdateEnrollmentMutation,
  useUpdateReEnrollment,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { validateReEnrollmentChange, ValidateReEnrollmentChangeProps } from '../ProfileModal/utils';

export type CanceledEnrollmentParams = {
  id: string;
  statusId: string;
  user: BaseUser;
  rejectedReEnrollment: StudentRegistration;
};

export type ReEnrollmentProps = {
  enrollment: StudentRegistration;
  prevEnrollments: StudentRegistration[];
  user: BaseUser;
};

export type RejectReEnrollmentProps = {
  canceledEnrollment: ReEnrollmentClosedRegistration;
  rejectedReEnrollment: StudentRegistration;
  onSuccess: () => void;
};
export type EnrollmentChangesProps = {
  reEnrollment: StudentRegistration;
} & ValidateReEnrollmentChangeProps;

type UseReEnrollmentActionsProps = {
  fetchEnrollments: () => void;
  reEnrollmentData?: SchoolReEnrollmentResponse;
};

export const useReEnrollmentActions = ({
  fetchEnrollments,
  reEnrollmentData,
}: UseReEnrollmentActionsProps) => {
  const updateReEnrollment = useUpdateReEnrollment();
  const deleteEnrollment = useDeleteEnrollmentMutation();
  const updateEnrollment = useUpdateEnrollmentMutation();
  const { getConfirmation } = useConfirmationDialog();

  const navigate = useNavigate();
  const location = useLocation();

  const handleAcceptReEnrollment = useCallback(
    async ({ enrollment }: ReEnrollmentProps) => {
      await updateReEnrollment.mutateAsync({
        reEnrollmentId: enrollment.id,
        type: 'accepted',
      });
      fetchEnrollments();
    },
    [fetchEnrollments, updateReEnrollment],
  );

  const navigateToPrevEnrollment = useCallback(
    (id: string, initialState: CanceledEnrollmentParams) => {
      navigate(`registration?id=${id}`, {
        state: { backgroundLocation: location, initialState },
      });
    },
    [location, navigate],
  );

  const handleRejectReEnrollment = useCallback(
    async ({ enrollment, prevEnrollments, user }: ReEnrollmentProps) => {
      if (!reEnrollmentData?.enabled || !reEnrollmentData?.status_ids.length) return;

      const prevCurrentRegistration = prevEnrollments.find((r) =>
        r.statuses.some((s) => s.school_property.category?.current),
      );

      if (!prevCurrentRegistration) return;

      const [statusId] = reEnrollmentData.status_ids;

      navigateToPrevEnrollment(prevCurrentRegistration.id, {
        id: uuidv4(),
        statusId,
        user,
        rejectedReEnrollment: enrollment,
      });
    },
    [reEnrollmentData?.enabled, reEnrollmentData?.status_ids, navigateToPrevEnrollment],
  );

  const handleDeleteEnrollment = useCallback(
    async (id: string) => {
      await deleteEnrollment.mutateAsync(id);
      fetchEnrollments();
    },
    [deleteEnrollment, fetchEnrollments],
  );

  const handleCancelEnrollment = useCallback(
    async ({ canceledEnrollment, rejectedReEnrollment, onSuccess }: RejectReEnrollmentProps) => {
      await updateReEnrollment.mutateAsync(
        {
          reEnrollmentId: rejectedReEnrollment.id,
          type: 'rejected',
          enrollment: canceledEnrollment,
        },
        { onSuccess },
      );
    },
    [updateReEnrollment],
  );

  const handleEnrollmentChange = useCallback(
    async ({ reEnrollment, ...props }: EnrollmentChangesProps) => {
      const hasInconsistencies = validateReEnrollmentChange(props);

      const deleteReEnrollmentConfirmed = hasInconsistencies
        ? await getConfirmation({
            textId: 'enrollment-ChangeRegistrationWithReEnrollmentRemove',
            textValues: { yearName: reEnrollment.school_year.name },
          })
        : false;

      if (deleteReEnrollmentConfirmed) {
        await handleDeleteEnrollment(reEnrollment.id);
      } else if (hasInconsistencies) return false;
      return true;
    },
    [getConfirmation, handleDeleteEnrollment],
  );

  return useMemo(
    () => ({
      handleAcceptReEnrollment,
      handleRejectReEnrollment,
      updateReEnrollment,
      isReEnrollmentUpdating:
        updateReEnrollment.isLoading || deleteEnrollment.isLoading || updateEnrollment.isLoading,
      handleCancelEnrollment,
      handleDeleteEnrollment,
      handleEnrollmentChange,
    }),
    [
      handleAcceptReEnrollment,
      handleRejectReEnrollment,
      updateReEnrollment,
      deleteEnrollment.isLoading,
      updateEnrollment.isLoading,
      handleCancelEnrollment,
      handleDeleteEnrollment,
      handleEnrollmentChange,
    ],
  );
};
