import { DATE_FORMAT_RANGE_FNS, GroupValidity } from '@schooly/api';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, isEqual } from 'date-fns';

export const formatGroupValidity = <T extends { validity: GroupValidity }>(
  group: T,
  dateFormat = DATE_FORMAT_RANGE_FNS,
) => {
  const { from_school_year, to_school_year, date_from, date_to } = group.validity || {};

  if (date_from && date_to) {
    const fromDate = newDateTimezoneOffset(date_from);
    const toDate = newDateTimezoneOffset(date_to);

    return isEqual(fromDate, toDate)
      ? format(fromDate, dateFormat)
      : `${format(fromDate, dateFormat)} – ${format(toDate, dateFormat)}`;
  }

  if (from_school_year) {
    return !to_school_year || from_school_year.id === to_school_year.id
      ? from_school_year.name
      : `${from_school_year.name}–${to_school_year.name}`;
  }

  return '';
};
