import {
  Box,
  ClickAwayListener,
  Grow,
  Icon,
  Popper,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useFlag } from '@schooly/hooks/use-flag';
import { ChevronUpIcon, CrossIcon, LockIcon } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ExtendedFieldError, getInputErrorText } from 'apps/web/src/components/ui/Input/utils';
import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

import { LoadMore } from './LoadMore';

export type DropdownSelectProps = {
  name?: string;
  opened?: boolean;
  label?: string;
  requiredLabel?: 'optional' | 'required';
  placeholder?: string | null;
  borderOnHover?: boolean;
  hasValues?: boolean;
  maxExpandedContainerWidth?: number;
  startIcon?: ReactNode;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  disabled?: boolean;
  children: (opened: boolean) => ReactNode;
  onClear?: () => void;
  renderContent: () => ReactNode;
  onFetchNextPage?: () => Promise<any>;
  onClickInputArea?: () => void;
  onToggle?: (isOpened: boolean) => void;
  onOpen?: () => void;
  onClose?: (opened: boolean) => void;
  error?: ExtendedFieldError;
  renderRightIcon?: () => JSX.Element;
  sx?: SxProps<Theme>;
  layoutStyleProps?: SxProps<Theme>;
  expandedContainerStyleProps?: SxProps<Theme>;
  withoutRightIcon?: boolean;
  popperZIndex?: number | ((theme: Theme) => number | undefined);
  isLoading?: boolean;
  showNextPageLoading?: boolean;
  helperText?: string;
  testId?: string;
  emptyLabelProps?: TypographyProps;
};

export type DropdownSelect = {
  open: () => void;
  close: () => void;
  focus: () => void;
  blur: () => void;
};

const DROPDOWN_INIT_DELAY = 300;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DropdownSelect = forwardRef<DropdownSelect, DropdownSelectProps>(
  (
    {
      name,
      opened: forceOpened,
      disabled,
      startIcon,
      requiredLabel,
      borderOnHover,
      hasValues,
      maxExpandedContainerWidth,
      placeholder: placeholderLabel,
      isFetchingNextPage,
      hasNextPage,
      renderContent,
      onClear,
      children,
      onFetchNextPage,
      onClickInputArea,
      onClose,
      onOpen,
      onToggle,
      error,
      renderRightIcon,
      layoutStyleProps,
      expandedContainerStyleProps,
      withoutRightIcon,
      popperZIndex,
      isLoading,
      showNextPageLoading = true,
      helperText,
      sx,
      testId,
      emptyLabelProps,
    },
    ref,
  ) => {
    const { $t } = useIntl();
    const { ref: anchorRef } = useResizeDetector<HTMLDivElement | null>();
    const [isPopperBottom, setPopperBottom] = useState(true);
    const [opened, openDropdown, closeDropdown] = useFlag();

    const open = useCallback(() => {
      openDropdown();
      onOpen?.();
      onToggle?.(true);
    }, [openDropdown, onOpen, onToggle]);

    const close = useCallback(() => {
      closeDropdown();
      onClose?.(opened);
      onToggle?.(false);
    }, [closeDropdown, onClose, onToggle, opened]);

    useEffect(() => {
      if (forceOpened === undefined) return;

      setTimeout(() => (forceOpened ? open() : close()), DROPDOWN_INIT_DELAY);
    }, [close, forceOpened, open]);

    useImperativeHandle(
      ref,
      () => ({
        open,
        close,
        focus: open,
        blur: close,
      }),
      [open, close],
    );

    const renderedChildren = children(opened);
    const forceLabelToTop = !!renderedChildren && opened;

    const popperOptions = useMemo(
      () => ({
        modifiers: [
          {
            name: 'topLogger',
            enabled: true,
            phase: 'main',
            fn({ state }: { state: { placement: 'top-start' | 'bottom-start' } }) {
              setPopperBottom(state.placement !== 'top-start');
            },
          },
        ],
      }),
      [],
    );

    const required = requiredLabel && !disabled && !hasValues && !forceLabelToTop && (
      <Typography
        color="common.grey"
        className="required-label"
        variant="h3"
        sx={(theme) => ({
          pointerEvents: 'none',
          fontStyle: 'italic',
          position: 'absolute',
          right: parseInt(theme.spacing(5)),
        })}
      >
        {$t({ id: requiredLabel === 'required' ? 'input-required' : 'input-optional' })}
      </Typography>
    );

    const label = (hasValues || forceLabelToTop) && placeholderLabel && (
      <Typography
        color="common.grey"
        className="label"
        sx={(theme) => ({
          left: theme.spacing(1.5),
          pointerEvents: 'none',
          position: 'absolute',
          top: theme.spacing(0.25),
          fontSize: theme.spacing(1.25),
        })}
      >
        {placeholderLabel}
      </Typography>
    );

    const emptyLabel = (!opened || !onClickInputArea) && !hasValues && !forceLabelToTop && (
      <Typography
        variant="h3"
        className="emptyLabel"
        color="common.grey"
        sx={{
          pointerEvents: 'none',
        }}
        {...emptyLabelProps}
      >
        {placeholderLabel !== undefined ? placeholderLabel : $t({ id: 'filter-All' })}
      </Typography>
    );

    const RightIcon = !!renderRightIcon
      ? renderRightIcon
      : disabled
      ? LockIcon
      : opened && hasValues && !!onClear
      ? CrossIcon
      : ChevronUpIcon;

    const renderedContent = opened && renderContent();

    const hasContentToRender = !!renderedContent;

    return (
      <>
        <ClickAwayListener onClickAway={close}>
          <div>
            <Stack
              data-test-id={testId}
              ref={anchorRef}
              className="dropdownContainer"
              sx={(theme) => ({
                position: 'relative',
                background: 'white',
                borderRadius: theme.spacing(1),
                border: `1px solid ${theme.palette.common.light3}`,
                ...(disabled || isLoading
                  ? {
                      pointerEvents: 'none',
                      backgroundColor: theme.palette.background.default,
                    }
                  : {}),
                zIndex: opened ? theme.zIndex.tooltip - 2 : undefined,
                outline: `0 solid ${theme.palette.background.default}`,
                transition: 'all .2s',
                '&:hover': {
                  outline: !opened ? `2px solid ${theme.palette.background.default}` : undefined,
                },
                ...(borderOnHover && !opened && !error
                  ? {
                      '&:not(:hover)': {
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                        '.required-label, .right-icon': {
                          opacity: 0,
                        },
                      },
                    }
                  : {}),
                ...(hasContentToRender
                  ? isPopperBottom
                    ? {
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      }
                    : {
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                      }
                  : {}),
                ...(error && !opened
                  ? {
                      borderColor: theme.palette.error.main,
                      outline: theme.mixins.borderValue('2px'),
                      outlineColor: theme.palette.error.superLight,
                      '&:hover': {
                        outlineColor: theme.palette.error.superLight,
                      },
                    }
                  : {}),
                ...((typeof sx === 'function' ? sx(theme) : sx) as SystemStyleObject),
              })}
            >
              {!!name && (
                <Stack
                  position="absolute"
                  left={0}
                  top={0}
                  sx={{ pointerEvents: 'none', opacity: 0, width: 1 }}
                >
                  <input name={name} />
                </Stack>
              )}
              <Stack
                sx={(theme) => ({
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: 0.5,
                  position: 'relative',
                  minHeight: theme.spacing(5.25),
                  padding: label
                    ? theme.spacing(1.75, 5, 0.25, 1.5)
                    : theme.spacing(0.5, 5, 0.5, 1.5),
                  ...((typeof layoutStyleProps === 'function'
                    ? layoutStyleProps(theme)
                    : layoutStyleProps) as SystemStyleObject),
                })}
              >
                {label}
                {startIcon}
                {emptyLabel}
                {opened && onClickInputArea && (
                  <Box
                    position="absolute"
                    left={0}
                    top={0}
                    right={0}
                    bottom={0}
                    onClick={onClickInputArea}
                  />
                )}
                {renderedChildren}
                {!opened && (
                  <Box
                    position="absolute"
                    onClick={open}
                    sx={{ cursor: 'pointer' }}
                    left={0}
                    top={0}
                    right={0}
                    bottom={0}
                  />
                )}
                {required}
                {!withoutRightIcon && (
                  <Icon
                    className="right-icon"
                    sx={(theme) => ({
                      fontSize: theme.spacing(2),
                      cursor: 'pointer',
                      position: 'absolute',
                      display: 'inline-flex',
                      top: theme.spacing(1.625),
                      right: theme.spacing(1.5),
                      color: theme.palette.common.grey,
                      pointerEvents: 'auto',
                      overflow: 'visible',
                      '&:hover': {
                        color: theme.palette.text.primary,
                      },
                      '.svg-icon': {
                        transform: !opened && !disabled ? 'rotate(180deg)' : undefined,
                      },
                    })}
                    onClick={() => {
                      if (disabled) return;
                      if (opened && hasValues && !!onClear) {
                        return onClear();
                      }
                      return opened ? close() : open();
                    }}
                  >
                    <RightIcon />
                  </Icon>
                )}
              </Stack>
            </Stack>

            {error && (
              <Typography
                pl={1.25}
                pt={0.25}
                display="inline-block"
                variant="caption"
                color="error.main"
              >
                <FormattedMessage {...getInputErrorText(error)} />
              </Typography>
            )}
            {helperText && !error && (
              <Typography pl={1.5} variant="caption" color="primary.main">
                {helperText}
              </Typography>
            )}

            {hasContentToRender && (
              <Popper
                open
                placement="bottom-start"
                popperOptions={popperOptions}
                anchorEl={anchorRef.current}
                sx={(theme) => ({
                  zIndex: popperZIndex
                    ? typeof popperZIndex === 'function'
                      ? popperZIndex(theme)
                      : popperZIndex
                    : theme.zIndex.tooltip - 1,
                })}
                transition
              >
                {({ TransitionProps }) => {
                  const inputWidth = (anchorRef.current?.clientWidth || 100) + 2;

                  return (
                    <Grow {...TransitionProps}>
                      <Stack
                        sx={(theme) => ({
                          position: 'relative',
                          backgroundColor: 'white',
                          maxHeight: 300,
                          overflowY: 'scroll',
                          ...{ border: `1px solid ${theme.palette.common.light3}` },
                          ...(isPopperBottom
                            ? {
                                borderBottomRightRadius: theme.spacing(1),
                                borderBottomLeftRadius: theme.spacing(1),
                                borderTop: 0,
                              }
                            : {
                                borderTopRightRadius: theme.spacing(1),
                                borderTopLeftRadius: theme.spacing(1),
                                borderBottom: 0,
                              }),
                          ...theme.mixins.dropdownShadow(),
                          ...(maxExpandedContainerWidth && maxExpandedContainerWidth > inputWidth
                            ? {
                                minWidth: inputWidth,
                                maxWidth: maxExpandedContainerWidth,
                                border: `1px solid ${theme.palette.common.light3} !important`,
                                marginTop: '-1px',
                                marginBottom: '-1px',
                              }
                            : { width: inputWidth }),
                          ...((typeof expandedContainerStyleProps === 'function'
                            ? expandedContainerStyleProps(theme)
                            : expandedContainerStyleProps) as SystemStyleObject),
                        })}
                      >
                        {renderedContent}
                        {hasNextPage && (
                          <Stack gap={1} m={showNextPageLoading ? 1 : 0}>
                            {onFetchNextPage && (
                              <LoadMore
                                isLoading={!!isFetchingNextPage}
                                onFetchNextPage={onFetchNextPage}
                              />
                            )}
                            {showNextPageLoading && (
                              <>
                                <Skeleton variant="rectangular" height={20} width="90%" />
                                <Skeleton variant="rectangular" height={20} width="80%" />
                              </>
                            )}
                          </Stack>
                        )}
                      </Stack>
                    </Grow>
                  );
                }}
              </Popper>
            )}
          </div>
        </ClickAwayListener>
      </>
    );
  },
);
