import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 5C14 7.20914 12.2091 9 10 9C7.79086 9 6 7.20914 6 5C6 2.79086 7.79086 1 10 1C12.2091 1 14 2.79086 14 5Z"
      fill="#B3BBCB"
    />
    <path
      d="M15.7362 12.0646C14.1788 10.775 12.1799 10 10 10C5.02944 10 1 14.0294 1 19H12.7578C12.2791 18.285 12 17.4251 12 16.5C12 14.2751 13.6147 12.4273 15.7362 12.0646Z"
      fill="#B3BBCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4801 13.4238C19.7983 13.689 19.8413 14.1619 19.5762 14.4801L16.5504 18.1111L14.4697 16.0303C14.1768 15.7374 14.1768 15.2626 14.4697 14.9697C14.7626 14.6768 15.2374 14.6768 15.5303 14.9697L16.4496 15.8889L18.4238 13.5199C18.689 13.2017 19.1619 13.1587 19.4801 13.4238Z"
      fill="#B3BBCB"
    />
  </svg>
);
