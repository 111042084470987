import { Box, Button, FormControlLabel, IconButton, Stack, Switch, TextField } from '@mui/material';
import {
  CheckIcon,
  CrossIcon,
  DragIcon,
  ModalHeader,
  ModalSmall,
  PlusIcon,
  RollBackIcon,
  Spin,
} from '@schooly/style';
import React, { FC } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormProvider } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../../components/uikit/Dnd/dnd.styled';
import {
  ModalContent,
  ModalFooter,
  ModalMain,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { SchoolGeneralAgeGroupRow } from './SchoolGeneralAgeGroupRow';
import { SchoolGeneralAgeGroupsLevel } from './SchoolGeneralAgeGroupsLevel';
import {
  AgeGroupsDroppable,
  NO_LEVEL_DROPPABLE_ID,
  useSchoolGeneralAgeGroupsModal,
} from './useSchoolGeneralAgeGroupsModal';

export const SchoolGeneralAgeGroupsModalContent: FC = () => {
  const { $t } = useIntl();

  const {
    handleDragEnd,
    handleClose,
    addAgeGroup,
    handleSubmit,
    handleRestoreAgeGroup,
    addSchoolLevel,
    handleDeleteSchoolLevel,
    setShowArchived,
    validateAgeGroup,
    getAllAgeGroups,
    getActiveAgeGroupsCount,
    validateSchoolLevel,

    showArchived,
    form,
    saving,
    autoSwitchedArchivedOn,
    shouldFocusAgeGroupInput,
    shouldFocusLevelInput,
  } = useSchoolGeneralAgeGroupsModal();

  const schoolLevels = form.watch('schoolLevels') ?? [];
  const ageGroupsWithNoLevel = form.watch('ageGroupsWithNoLevel') ?? [];
  const archivedAgeGroups = form.watch('archivedAgeGroups') ?? [];

  return (
    <ModalSmall open onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ModalHeader title={$t({ id: 'school-tabs-AgeGroups' })} active>
            {archivedAgeGroups.length > 0 && (
              <FormControlLabel
                control={
                  <Switch checked={showArchived} onChange={(_, value) => setShowArchived(value)} />
                }
                label={$t({ id: 'action-ShowArchived' })}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    whiteSpace: 'nowrap',
                  },
                }}
              />
            )}
            {!saving && (
              <IconButton onClick={handleClose}>
                <CrossIcon />
              </IconButton>
            )}
          </ModalHeader>
          <ModalMain>
            <ModalContent flat active>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={AgeGroupsDroppable.LEVEL} type={AgeGroupsDroppable.LEVEL}>
                  {(provided) => (
                    <DndListWrapper
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      p={2.5}
                      pb={3}
                      gap={3}
                      sx={{ overflow: 'visible', height: 'unset' }}
                    >
                      {schoolLevels.map((level, index) => {
                        const levelId = level.id || level.id_tmp || '';
                        return (
                          <Draggable
                            key={levelId}
                            draggableId={levelId}
                            index={index}
                            isDragDisabled={schoolLevels.length < 2}
                          >
                            {(provided) => (
                              <Stack
                                direction="row"
                                alignItems="flex-start"
                                gap={1}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={provided.draggableProps.style}
                              >
                                {schoolLevels.length > 1 && (
                                  <IconButton
                                    inverse
                                    {...provided.dragHandleProps}
                                    sx={(theme) => ({ mt: theme.spacing(3.5) })}
                                  >
                                    <DragIcon />
                                  </IconButton>
                                )}
                                <SchoolGeneralAgeGroupsLevel
                                  levelIndex={index}
                                  handleDeleteSchoolLevel={handleDeleteSchoolLevel}
                                  shouldFocusLevelInput={shouldFocusLevelInput}
                                  schoolLevelsCount={schoolLevels.length}
                                  validateAgeGroup={validateAgeGroup}
                                  autoSwitchedArchivedOn={autoSwitchedArchivedOn}
                                  setShowArchived={setShowArchived}
                                  getAllAgeGroups={getAllAgeGroups}
                                  validateSchoolLevel={validateSchoolLevel}
                                />
                              </Stack>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                      <Button
                        variant="outlined"
                        startIcon={<PlusIcon />}
                        onClick={addSchoolLevel}
                        sx={{ alignSelf: 'flex-start' }}
                      >
                        <FormattedMessage id="school-tabs-AgeGroups-AddSchoolLevel" />
                      </Button>
                    </DndListWrapper>
                  )}
                </Droppable>
                <Droppable droppableId={NO_LEVEL_DROPPABLE_ID} type={AgeGroupsDroppable.AGEGROUP}>
                  {(provided) => (
                    <DndListWrapper
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      alignItems="flex-start"
                      gap={2}
                      p={2.5}
                      pt={0}
                      sx={{ overflow: 'visible', height: 'unset' }}
                    >
                      {ageGroupsWithNoLevel.map((ageGroup, index) => (
                        <Draggable
                          key={ageGroup.id || ageGroup.id_tmp}
                          draggableId={ageGroup.id || ageGroup.id_tmp || ''}
                          index={index}
                        >
                          {(provided) => (
                            <SchoolGeneralAgeGroupRow
                              provided={provided}
                              shouldFocus={shouldFocusAgeGroupInput}
                              ageGroupIndex={index}
                              validateAgeGroup={validateAgeGroup}
                              getAllAgeGroups={getAllAgeGroups}
                              activeAgeGroupsTotalCount={getActiveAgeGroupsCount()}
                              autoSwitchedArchivedOn={autoSwitchedArchivedOn}
                              setShowArchived={setShowArchived}
                            />
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}

                      {!!ageGroupsWithNoLevel.length && (
                        <Box py={1}>
                          <Button
                            variant="outlined"
                            startIcon={<PlusIcon />}
                            onClick={() => addAgeGroup()}
                          >
                            <FormattedMessage id="school-tabs-AgeGroups-AddAgeGroup" />
                          </Button>
                        </Box>
                      )}

                      {showArchived &&
                        archivedAgeGroups.map((ageGroup, index) => (
                          <Stack
                            key={ageGroup.id || ageGroup.id_tmp}
                            direction="row"
                            alignItems="center"
                            gap={1}
                            pl={3.5}
                            width="100%"
                          >
                            <TextField
                              value={ageGroup.name}
                              fullWidth
                              disabled
                              label={$t({ id: 'school-tabs-AgeGroups-AgeGroupName' })}
                            />

                            <IconButton onClick={handleRestoreAgeGroup(index)}>
                              <RollBackIcon />
                            </IconButton>
                          </Stack>
                        ))}
                    </DndListWrapper>
                  )}
                </Droppable>
              </DragDropContext>
            </ModalContent>
          </ModalMain>
          <ModalFooter sx={{ justifyContent: 'space-between' }} active>
            <Button variant="outlined" disabled={saving} onClick={handleClose}>
              <FormattedMessage id="action-Cancel" />
            </Button>
            <Button type="submit" disabled={saving} endIcon={saving ? <Spin /> : <CheckIcon />}>
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
