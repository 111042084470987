import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  keyframes,
  Stack,
  Typography,
} from '@mui/material';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { FormTextField } from '@schooly/components/form-text-field';
import { LanguageSelect } from '@schooly/components/language-select';
import { EDUCATION_SYSTEM_OPTIONS, SchoolKind, SchoolType } from '@schooly/constants';
import {
  ADDITIONAL_KIND_OPTIONS,
  REGULAR_KIND_OPTIONS,
  SCHOOL_TYPE_OPTIONS,
} from '@schooly/constants';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  EditIcon,
  LogoSvg,
  RadioOnIcon,
  Spin,
} from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC, ReactNode, useCallback } from 'react';
import { Controller, FormProvider } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { FormRadioGroup } from '../../../components/uikit-components/FormCheckbox/FormRadioGroup';
import { useRouter } from '../../../context/router/useRouter';
import {
  LanguageSelectWrapper,
  LogoLink,
  SchoolCreateContentForm,
  SchoolCreateLayout,
} from './SchoolCreate.styled';
import { SchoolCreateConfigure } from './SchoolCreateConfigure';
import { SchoolCreateSaving } from './SchoolCreateSaving';
import { SchoolCreateTitle } from './SchoolCreateTitle';
import {
  MusicAcademySchoolSvg,
  PrimaryAndSecondarySchoolSvg,
  PrimarySchoolSvg,
  SecondarySchoolSvg,
  SportsAcademySchoolSvg,
} from './SchoolType.svg';
import { SchoolCreateState, useSchoolCreate } from './useSchoolCreate';

const SCHOOL_IMAGES: { [k in SchoolKind]: ReactNode } = {
  [SchoolKind.Elementary]: <PrimarySchoolSvg />,
  [SchoolKind.High]: <SecondarySchoolSvg />,
  [SchoolKind.ElementaryAndHigh]: <PrimaryAndSecondarySchoolSvg />,
  [SchoolKind.Music]: <MusicAcademySchoolSvg />,
  [SchoolKind.Sports]: <SportsAcademySchoolSvg />,
};
export const SchoolCreate: FC = () => {
  const { $t } = useIntl();
  const { goBack } = useRouter();

  const { saving, form, state, handleSchoolCreated, handleNext, setState } = useSchoolCreate();
  const { getConfirmation } = useConfirmationDialog();

  const name = form.watch('name');
  const code = form.watch('code');
  const kind = form.watch('kind');
  const type = form.watch('type');

  const educationSystem = form.watch('educationSystem');

  const handleClose = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({
        message: $t({ id: 'school-create-CancelConfirmAction' }),
      }))
    ) {
      return;
    }
    goBack();
  }, [goBack, getConfirmation, form.formState.isDirty, $t]);

  const renderButton = () => {
    if (state === SchoolCreateState.Saving) return null;
    if (state === SchoolCreateState.Configure) {
      return (
        <>
          <Stack flexDirection="row" justifyContent="space-between">
            <Button
              disabled={saving}
              variant="outlined"
              startIcon={<ArrowLeftIcon />}
              onClick={() => setState(SchoolCreateState.Kind)}
            >
              {$t({ id: 'action-Back' })}
            </Button>
            <Button
              disabled={saving}
              endIcon={saving ? <Spin /> : <ArrowRightIcon />}
              onClick={handleNext}
            >
              {$t({ id: 'action-LetsStart' })}
            </Button>
          </Stack>
        </>
      );
    }

    return (
      <Box>
        <Button
          disabled={saving}
          endIcon={saving ? <Spin /> : <ArrowRightIcon />}
          onClick={handleNext}
        >
          {state === SchoolCreateState.Kind ||
          (state === SchoolCreateState.Type && type === SchoolType.Other)
            ? $t({ id: 'action-Configure' })
            : $t({ id: 'action-Next' })}
        </Button>
      </Box>
    );
  };

  const renderTitle = () => {
    if (state === SchoolCreateState.Saving) return null;
    if (state === SchoolCreateState.Configure)
      return (
        <Typography
          variant="h1"
          onClick={() => setState(SchoolCreateState.NameFromConfigure)}
          sx={{ cursor: 'pointer', '&:not(:hover) .svg-icon': { opacity: 0 } }}
        >
          {name}

          <Icon sx={{ ml: 1 }}>
            <EditIcon />
          </Icon>
        </Typography>
      );

    return <SchoolCreateTitle state={state} form={form} onSetState={setState} />;
  };

  const renderContent = () => {
    switch (state) {
      case SchoolCreateState.Name:
      case SchoolCreateState.NameFromConfigure:
        return (
          <Stack flexDirection="row" gap={2}>
            <Stack flex={2.5}>
              <Controller
                control={form.control}
                rules={{ required: true, minLength: 10 }}
                name="name"
                render={({ field, fieldState }) => {
                  return (
                    <FormTextField
                      label={$t({ id: 'school-create-SchoolName' })}
                      autoFocus
                      fullWidth
                      canClear
                      inputRef={field.ref}
                      required
                      error={!!fieldState.error}
                      helperText={getControllerErrorText(
                        fieldState.error,
                        { required: true, minLength: 10 },
                        $t,
                      )}
                      {...field}
                      onChange={(e) => {
                        form.clearErrors('name');
                        field.onChange(e);
                      }}
                      onBlur={() => {
                        if (code) return;

                        const generatedCode = generateAcronym(name);

                        form.clearErrors('code');
                        form.setValue('code', generatedCode);
                      }}
                    />
                  );
                }}
              />
            </Stack>
            <Stack flex={1}>
              <Controller
                control={form.control}
                rules={{ required: true }}
                name="code"
                render={({ field, fieldState }) => {
                  return (
                    <FormTextField
                      label={$t({ id: 'school-create-Acronym' })}
                      fullWidth
                      canClear
                      inputRef={field.ref}
                      required
                      error={!!fieldState.error}
                      helperText={getControllerErrorText(fieldState.error, { required: true }, $t)}
                      {...field}
                      onChange={(e) => {
                        form.clearErrors('code');
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              />
            </Stack>
          </Stack>
        );
      case SchoolCreateState.Type:
        return <FormRadioGroup options={SCHOOL_TYPE_OPTIONS} name="type" withBackground />;
      case SchoolCreateState.System:
        return (
          <FormRadioGroup
            options={EDUCATION_SYSTEM_OPTIONS}
            name="educationSystem"
            withBackground
          />
        );
      case SchoolCreateState.Kind:
        const options =
          type === SchoolType.Regular ? REGULAR_KIND_OPTIONS : ADDITIONAL_KIND_OPTIONS;

        return (
          <Controller
            control={form.control}
            name="kind"
            render={({ field }) => (
              <Stack sx={{ gap: 1.5, flexDirection: 'row', animation: `${fadeIn} .5s` }}>
                {options.map((option) => {
                  const isChecked = option.value === kind;
                  const onSelect = () => form.setValue('kind', option.value);
                  return (
                    <Box
                      key={option.value}
                      onClick={onSelect}
                      sx={(theme) => ({
                        cursor: 'pointer',
                        backgroundColor: 'white',
                        borderRadius: theme.spacing(1),
                        border: `1px solid ${theme.palette.text.secondary}`,
                        '.option-image': {
                          transition: 'opacity .3s',
                        },
                        '&:not(:hover) .option-image': {
                          opacity: isChecked ? 1 : 0.5,
                        },
                      })}
                    >
                      <FormControlLabel
                        sx={{ ml: 0 }}
                        control={
                          <Checkbox
                            key={`${option.value}`}
                            name={field.name}
                            checked={isChecked}
                            onChange={onSelect}
                            checkedIcon={<RadioOnIcon className="reset-svg-currentColor" />}
                          />
                        }
                        label={$t({
                          id:
                            typeof option.labelTextId === 'object'
                              ? option.labelTextId[educationSystem]
                              : option.labelTextId,
                        })}
                      />
                      <Box className="option-image">{SCHOOL_IMAGES[option.value]}</Box>
                    </Box>
                  );
                })}
              </Stack>
            )}
          />
        );

      case SchoolCreateState.Configure:
        return <SchoolCreateConfigure form={form} />;
      case SchoolCreateState.Saving:
        return <SchoolCreateSaving handleSchoolCreated={handleSchoolCreated} />;
    }
  };

  return (
    <SchoolCreateLayout
      sx={(theme) => ({
        [theme.breakpoints.down('lg')]: {
          padding: () => {
            if (state === SchoolCreateState.Kind) return theme.spacing(18, 0, 0, 0);
            if (state === SchoolCreateState.Configure) return theme.spacing(0, 1, 0, 20);
          },
        },
      })}
    >
      <LogoLink onClick={handleClose}>
        <LogoSvg />
      </LogoLink>
      <LanguageSelectWrapper>
        <LanguageSelect />
      </LanguageSelectWrapper>
      <FormProvider {...form}>
        <SchoolCreateContentForm sx={saving ? { pointerEvents: 'none' } : undefined}>
          <Stack
            sx={(theme) => ({
              padding: theme.spacing(4, 2, 0, 0),
              marginRight: theme.spacing(-2),
              gap: theme.spacing(3),
              overflow: state === SchoolCreateState.Configure ? 'scroll' : undefined,
            })}
          >
            {renderTitle()}
            {renderContent()}
          </Stack>
          {renderButton()}
        </SchoolCreateContentForm>
      </FormProvider>
    </SchoolCreateLayout>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const generateAcronym = (schoolName: string) => {
  if (!schoolName.length) return '';

  const notInclude = [
    // CONJUNCTIONS
    'for',
    'and',
    'nor',
    'but',
    'or',
    'yet',
    'so',
    // ARTICLES
    'a',
    'an',
    'the',

    // PREPOSITIONS
    'aboard',
    'about',
    'above',
    'across',
    'after',
    'against',
    'along',
    'amid',
    'among',
    'anti',
    'around',
    'as',
    'at',
    'before',
    'behind',
    'below',
    'beneath',
    'beside',
    'besides',
    'between',
    'beyond',
    'but',
    'by',
    'concerning',
    'considering',
    'despite',
    'down',
    'during',
    'except',
    'excepting',
    'excluding',
    'following',
    'for',
    'from',
    'in',
    'inside',
    'into',
    'like',
    'minus',
    'near',
    'of',
    'off',
    'on',
    'onto',
    'opposite',
    'over',
    'past',
    'per',
    'plus',
    'regarding',
    'round',
    'save',
    'since',
    'than',
    'through',
    'to',
    'toward',
    'towards',
    'under',
    'underneath',
    'unlike',
    'until',
    'up',
    'upon',
    'versus',
    'via',
    'with',
    'within',
    'without',
  ];

  return schoolName
    .split(' ')
    .filter((w) => !notInclude.includes(w.toLowerCase()))
    .map((w) => w[0].toUpperCase())
    .join('');
};
