import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { Company, CompanySave, useCheckCompanyUniqueMutation } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { AREA_CODE_OPTIONS, COUNTRY_OPTIONS, VALID_EMAIL_REGEXP } from '@schooly/constants';
import {
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderInput,
  ModalSmall,
  Spin,
} from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { getCountryFromPhoneNumber, getFormattedPhoneNumber } from '@schooly/utils/phone-number';
import { isNotEmpty } from '@schooly/utils/predicates';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import FormSelect2 from '../../../components/ui/Input/FormSelect2';
import { CompanyForm } from '../../../context/companies/WithCompany';

interface CompanyCreateModalContentProps {
  company?: Company;
  schoolId: string;
  onSave: (v: CompanySave) => void;
  onDelete?: () => void;
  onClose: () => void;
  isSaving: boolean;
  isDeleting: boolean;
}

const defaultValues: CompanyForm = {
  name: '',
  tax_number: '',
  bank_account: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  country: null,
  region: '',
  zip_code: '',
  contact_name: '',
  phoneCode: '',
  telephone: '',
  email: '',
  note: null,
};

export const CompanyCreateModalContent: FC<CompanyCreateModalContentProps> = ({
  onClose,
  onSave,
  isDeleting,
  isSaving,
  onDelete,
  company,
  schoolId,
}) => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const checkCompanyUnique = useCheckCompanyUniqueMutation();
  const { isLoading: isValidating } = checkCompanyUnique;

  const formDefaultValues = useMemo(() => {
    if (company) {
      const { telephone, student_count, id, ...companyForForm } = company;
      return {
        ...companyForForm,
        phoneCode: getCountryFromPhoneNumber(company.telephone)?.code || '',
        telephone: getFormattedPhoneNumber(company.telephone),
      };
    } else {
      return defaultValues;
    }
  }, [company]);

  const form = useForm<CompanyForm>({
    defaultValues: formDefaultValues,
  });

  const handleClose = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({
        textId: 'school-edit-CloseUnsavedConfirmation',
      }))
    )
      return;

    onClose();
  }, [form.formState.isDirty, getConfirmation, onClose]);

  const nameChanged = form.formState.dirtyFields.name;
  const taxNumberChanged = form.formState.dirtyFields.tax_number;

  const handleSubmit = useCallback(
    async (v: CompanyForm) => {
      const { phoneCode, telephone, country, note, ...data } = v;
      if (!isNotEmpty(country)) return;

      const companyForSave = {
        ...data,
        country,
        telephone: phoneCode + telephone,
        note: note ?? undefined,
      };

      const shouldValidate = !company?.id || nameChanged || taxNumberChanged;

      if (!shouldValidate) {
        onSave(companyForSave);
        return;
      }

      checkCompanyUnique.mutate(
        {
          schoolId,
          name: !company?.id || nameChanged ? companyForSave.name : undefined,
          tax_number: !company?.id || taxNumberChanged ? companyForSave.tax_number : undefined,
        },
        {
          onSuccess: ({ name_is_unique, tax_number_duplicate_company_name }) => {
            if (tax_number_duplicate_company_name) {
              form.setError('tax_number', {
                type: 'validate',
                message: $t(
                  { id: 'companies-CompanyTaxNumberExists' },
                  { companyName: tax_number_duplicate_company_name },
                ),
              });
              form.setFocus('tax_number');
            }
            if (!name_is_unique) {
              form.setError('name', {
                type: 'validate',
                message: $t({ id: 'companies-CompanyNameExists' }),
              });
              form.setFocus('name');
            }
            if (!name_is_unique || tax_number_duplicate_company_name) return;

            onSave(companyForSave);
          },
          onError: showError,
        },
      );
    },
    [
      $t,
      checkCompanyUnique,
      company,
      form,
      nameChanged,
      onSave,
      schoolId,
      showError,
      taxNumberChanged,
    ],
  );

  const actionsDisabled = isDeleting || isValidating || isSaving;

  const deleteButton = !!onDelete && (
    <Button
      variant="outlined"
      startIcon={isDeleting ? <Spin /> : <DeleteIcon />}
      disabled={actionsDisabled}
      onClick={onDelete}
    >
      {$t({ id: 'action-Delete' })}
    </Button>
  );

  return (
    <ModalSmall open onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ModalHeader
            active
            title={
              <Controller
                control={form.control}
                name="name"
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <ModalHeaderInput
                      placeholder={$t({ id: 'companies-CompanyName' })}
                      autoFocus={!field.value}
                      error={!!fieldState.error}
                      helperText={getControllerErrorText(fieldState.error, undefined, $t)}
                      {...field}
                    />
                  );
                }}
              />
            }
          >
            <IconButton onClick={handleClose} disabled={actionsDisabled}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>

          <ModalContent active display="flex" flexDirection="column">
            <Stack gap={4}>
              <CompanyCreateModalRowsContainer>
                <ControlTextField
                  name="tax_number"
                  control={form.control}
                  label={$t({ id: 'companies-TaxNumber' })}
                  canClear
                  fullWidth
                  rules={{
                    required: true,
                  }}
                />
                <ControlTextField
                  name="bank_account"
                  control={form.control}
                  label={$t({ id: 'companies-BankAccount' })}
                  canClear
                  fullWidth
                  rules={{
                    required: true,
                  }}
                />
              </CompanyCreateModalRowsContainer>
              <CompanyCreateModalRowsContainer containerTitle={$t({ id: 'companies-Address' })}>
                <ControlTextField
                  name="address_line_1"
                  control={form.control}
                  label={$t({ id: 'companies-AddressLine1' })}
                  canClear
                  fullWidth
                  rules={{
                    required: true,
                  }}
                />
                <ControlTextField
                  name="address_line_2"
                  control={form.control}
                  label={$t({ id: 'companies-AddressLine2' })}
                  canClear
                  fullWidth
                />
                <Stack direction="row" gap={1.25}>
                  <ControlTextField
                    name="city"
                    control={form.control}
                    label={$t({ id: 'peopleDetail-City' })}
                    canClear
                    fullWidth
                    rules={{
                      required: true,
                    }}
                  />
                  <ControlTextField
                    name="region"
                    control={form.control}
                    label={$t({ id: 'companies-StateProvince' })}
                    canClear
                    fullWidth
                    rules={{
                      required: true,
                    }}
                  />
                </Stack>
                <Stack direction="row" gap={1.25}>
                  <ControlTextField
                    name="zip_code"
                    control={form.control}
                    label={$t({ id: 'companies-ZipCode' })}
                    canClear
                    fullWidth
                    rules={{
                      required: true,
                    }}
                  />
                  <FormSelect2
                    name="country"
                    labelTextId="companies-Country"
                    options={COUNTRY_OPTIONS}
                    optionClassName="CountryCodeSelectOption"
                    rules={{
                      required: true,
                    }}
                  />
                </Stack>
              </CompanyCreateModalRowsContainer>
              <CompanyCreateModalRowsContainer
                containerTitle={$t({ id: 'companies-ContactPerson' })}
              >
                <ControlTextField
                  name="contact_name"
                  control={form.control}
                  label={$t({ id: 'companies-ContactPersonName' })}
                  canClear
                  fullWidth
                  rules={{
                    required: true,
                  }}
                />
                <Stack direction="row" gap={1.25}>
                  <Box flex={1.5}>
                    <FormSelect2
                      name="phoneCode"
                      labelTextId="AreaCode"
                      options={AREA_CODE_OPTIONS}
                      noRequiredLabel
                      showSelectedValue
                      optionClassName="CountryCodeSelectOption"
                      rules={{
                        required: true,
                      }}
                    />
                  </Box>
                  <Box flex={2.5}>
                    <ControlTextField
                      name="telephone"
                      control={form.control}
                      label={$t({ id: 'companies-PhoneNumber' })}
                      canClear
                      fullWidth
                      rules={{
                        required: true,
                      }}
                    />
                  </Box>
                </Stack>
                <ControlTextField
                  name="email"
                  control={form.control}
                  label={$t({ id: 'companies-EmailAddress' })}
                  canClear
                  fullWidth
                  //TODO: Refactor email validation - TR-4353
                  rules={{
                    required: true,
                    pattern: {
                      value: VALID_EMAIL_REGEXP,
                      message: $t({
                        id: 'input-ErrorInvalidEmail',
                      }),
                    },
                  }}
                />
              </CompanyCreateModalRowsContainer>

              <ControlTextField
                name="note"
                control={form.control}
                label={$t({ id: 'companies-Note' })}
                canClear
                fullWidth
              />
            </Stack>
          </ModalContent>
          <ModalFooter active sx={{ justifyContent: company && 'space-between' }}>
            {company && deleteButton}
            <Button
              endIcon={isSaving || isValidating ? <Spin /> : <CheckIcon />}
              disabled={actionsDisabled}
              type="submit"
            >
              {$t({ id: 'action-Save' })}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalSmall>
  );
};

interface CompanyCreateModalRowsContainerProps extends PropsWithChildren {
  containerTitle?: string;
}

export const CompanyCreateModalRowsContainer: FC<CompanyCreateModalRowsContainerProps> = ({
  containerTitle,
  children,
}) => {
  return (
    <Stack gap={1}>
      {containerTitle && <Typography variant="h4">{containerTitle}</Typography>}
      <Stack gap={2}>{children}</Stack>
    </Stack>
  );
};
