import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="260px"
    height="32px"
    viewBox="0 0 260 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>minus</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="minus" fill="#000000" fillRule="nonzero">
        <path
          d="M250.25003,32 L9.74997024,32 C4.36796778,32 0,24.832031 0,16 C0,7.167969 4.36796778,0 9.74997024,0 L250.25003,0 C255.632032,0 260,7.167969 260,16 C260,24.832031 255.632032,32 250.25003,32 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
);
