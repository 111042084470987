import { AttendanceCode, AttendanceDetail } from '@schooly/api';

export function getAttendanceDetailByCode(
  code: AttendanceCode,
  attendanceDetails?: AttendanceDetail[],
) {
  return attendanceDetails?.find((detail) => detail.attendance_code_id === code.id);
}

export function getAttendanceDetailsByCodes(
  codes: string[],
  attendanceDetails?: AttendanceDetail[],
) {
  return attendanceDetails?.filter(
    (detail) =>
      detail.attendance_code_id &&
      !!codes.find((code) => code === detail.attendance_code_id)?.length,
  );
}

export function getRegisterTotal(attendanceDetails?: AttendanceDetail[]): number {
  return attendanceDetails?.reduce((sum, detail) => sum + detail.count, 0) ?? 0;
}

export function getNoStatusTotal(attendanceDetails?: AttendanceDetail[]): number {
  return (
    attendanceDetails?.reduce(
      (sum, detail) => (!detail.attendance_code_id ? sum + detail.count : sum),
      0,
    ) ?? 0
  );
}

export function getPresentTotal(
  codes: AttendanceCode[],
  attendanceDetails?: AttendanceDetail[],
): number {
  const presentTotalCodes = codes
    .filter((code) => code.in_school || code.is_present)
    .map((code) => code.id);

  const attendanceDetailsByCodes = getAttendanceDetailsByCodes(
    presentTotalCodes,
    attendanceDetails,
  );

  return (
    attendanceDetailsByCodes?.reduce((sum, detail) => {
      return sum + detail.count;
    }, 0) ?? 0
  );
}

export function getAbsentTotal(codes: AttendanceCode[], attendanceDetails?: AttendanceDetail[]) {
  const absentTotalCodes = codes
    .filter((code) => !code.in_school && !code.is_present)
    .map((code) => code.id);

  const attendanceDetailsByCodes = getAttendanceDetailsByCodes(absentTotalCodes, attendanceDetails);

  return (
    attendanceDetailsByCodes?.reduce((sum, detail) => {
      return sum + detail.count;
    }, 0) ?? 0
  );
}
