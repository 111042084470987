import { Button, IconButton, Stack } from '@mui/material';
import { AssessmentsGradeUpdate, AssessmentsGradeUpdateOption } from '@schooly/api';
import { CrossIcon, DeleteIcon, PlusIcon } from '@schooly/style';
import { FC, useCallback } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ControlTextField } from '../../../components/uikit-components/FormTextField/ControlTextField';
import { ControlModalHeaderInput } from '../../../components/uikit-components/Modal/ControlModalHeaderInput';
import {
  ModalContent,
  ModalMain,
  ModalSmall,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';

type SchoolAssessmentsGradeModalContentProps = {
  grade?: AssessmentsGradeUpdate;
  onClose: () => void;
  isSaving?: boolean;
  isNewItem: boolean;
  isArchiving?: boolean;
  onArchiveClick?: () => void;
  onSubmit: (v: AssessmentsGradeUpdate) => void;
  onDelete?: () => void;
};

const generateAssessmentsGradeListOption: () => AssessmentsGradeUpdateOption = () => ({
  name: '',
});

export const SchoolAssessmentsGradeModalContent: FC<SchoolAssessmentsGradeModalContentProps> = ({
  grade: initialGrade,
  onClose,
  onArchiveClick,
  onSubmit,
  onDelete,
  isNewItem,
  isSaving,
  isArchiving,
}) => {
  const { $t } = useIntl();

  const form = useForm<AssessmentsGradeUpdate>({
    defaultValues: initialGrade
      ? {
          name: initialGrade.name,
          description: initialGrade.description,
          options: initialGrade.options.map((o) => ({ name: o.name })),
        }
      : {
          name: '',
          description: '',
          options: [generateAssessmentsGradeListOption()],
        },
  });

  const {
    fields: options,
    append,
    remove,
    replace,
  } = useFieldArray({
    control: form.control,
    name: 'options',
  });

  const addAssessmentSelectListOption = useCallback(() => {
    append(generateAssessmentsGradeListOption());
  }, [append]);

  const deleteAssessmentSelectListOption = useCallback(
    (index: number) => () => {
      if (options.length > 1) {
        remove(index);
      } else {
        replace([generateAssessmentsGradeListOption()]);
      }
    },
    [options.length, remove, replace],
  );

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader
            active
            disabled={isSaving}
            title={
              <ControlModalHeaderInput
                control={form.control}
                name="name"
                rules={{ required: true }}
                placeholder={$t({ id: 'assessments-GradeSystemName' })}
                autoFocus={isNewItem}
              />
            }
          >
            {!isSaving && (
              <IconButton onClick={onClose}>
                <CrossIcon />
              </IconButton>
            )}
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              <Stack alignItems="flex-start" gap={2} sx={{ height: '100%' }}>
                {options?.map((field, index) => (
                  <Stack key={field.id} direction="row" gap={1} sx={{ width: '100%' }}>
                    <ControlTextField
                      name={`options.${index}.name`}
                      control={form.control}
                      rules={{ required: true }}
                      label={$t({ id: 'assessments-GradeName' })}
                      fullWidth
                      canClear
                    />
                    {options.length > 1 && (
                      <IconButton inverse onClick={deleteAssessmentSelectListOption(index)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                ))}
                <Button
                  variant="text"
                  startIcon={<PlusIcon />}
                  onClick={addAssessmentSelectListOption}
                >
                  <FormattedMessage id="assessments-AddGrade" />
                </Button>
                <ControlTextField
                  name="description"
                  control={form.control}
                  label={$t({ id: 'assessments-AddGradeDescription' })}
                  fullWidth
                  multiline
                  rows={2}
                  canClear
                />
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooterWithActions
            active
            saving={isSaving}
            archiving={isArchiving}
            showDeleteButton={!!onDelete}
            showArchiveButton={!!onArchiveClick}
            onArchiveClick={onArchiveClick}
            onDeleteClick={onDelete}
            isNewItem={isNewItem}
          />
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
