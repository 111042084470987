import { FC, PropsWithChildren, useState } from 'react';

import { MaintenanceContext } from './MaintenanceContext';

export const WithMaintenance: FC<PropsWithChildren> = ({ children }) => {
  const [init, setInit] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [timestamp, setTimestamp] = useState(0);

  return (
    <MaintenanceContext.Provider
      value={{
        init,
        enabled,
        timestamp,
        setInit,
        setEnabled,
        setTimestamp,
      }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
};
