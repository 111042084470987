import { Box, Stack, Typography } from '@mui/material';
import { AvailableCriteria, GroupMember, GroupUserType } from '@schooly/api';
import { DATE_FORMAT, Genders, getLabelIdForOption } from '@schooly/constants';
import { GridRowName, theme } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { PropsWithChildren, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PersonCardBasic from '../../../../../components/common/PersonCard/PersonCardBasic';
import { GridCell, GridContainer } from '../../../../../components/uikit-components/Grid/Grid';
import { Table, TableCell, TableRow } from '../../../../../components/uikit-components/Table/Table';
import { GroupPreviewMembersListCardsWrapper } from './GroupPreviewMembersList.styled';
import { ViewType } from './ListViewSwitch';

interface GroupPreviewMembersListProps extends PropsWithChildren {
  groupCriteria?: AvailableCriteria[];
  groupMembers?: GroupMember[];
  userType: GroupUserType;
  headerTitle?: string;
  isDisabled?: boolean;
  tableView: ViewType;
}

export const GroupPreviewMembersList: React.FC<GroupPreviewMembersListProps> = ({
  groupCriteria,
  groupMembers,
  userType,
  headerTitle = 'Title',
  isDisabled,
  tableView,
}) => {
  const { formatMessage } = useIntl();

  const isCompressed = tableView === 'compressedView';
  const columns: TableCell[] = useMemo(() => {
    const baseColumn = {
      id: headerTitle,
      renderContent: () => {
        return <GridCell py={isCompressed ? 0.75 : 1.25}>{headerTitle}</GridCell>;
      },
    };
    return userType === 'staff'
      ? [baseColumn]
      : [
          baseColumn,
          {
            id: 'DOB',
            renderContent: () => (
              <GridCell py={isCompressed ? 0.75 : 1}>
                <FormattedMessage id="date-of-birthday" />
              </GridCell>
            ),
          },
          {
            id: 'Gender',
            renderContent: () => (
              <GridCell py={isCompressed ? 0.75 : 1}>
                <FormattedMessage id="peopleDetail-Gender" />
              </GridCell>
            ),
          },
        ];
  }, [headerTitle, isCompressed, userType]);

  const rows: TableRow[] = useMemo(() => {
    if (!groupMembers) {
      return [];
    }
    return groupMembers.map((member) => {
      const gender = Number.isInteger(member.user.gender)
        ? formatMessage({
            id: getLabelIdForOption({
              key: Genders[member.user.gender!],
              optionsKey: 'gender',
            }),
          })
        : '';

      const baseRow = {
        id: 'date',
        renderContent: () => (
          <GridCell noVerticalPadding>
            <GridRowName sx={{ ...(!isCompressed && { pt: 0.75, pb: 0.9 }), maxWidth: 520 }}>
              <Box
                sx={{
                  '& .card-body': {
                    padding: '0 !important',
                  },
                  '.PersonCard__info': {
                    p: isCompressed ? 0 : undefined,
                  },
                }}
                minHeight={isCompressed ? 25 : undefined}
              >
                <PersonCardBasic
                  isListItem
                  isUsernameClickable
                  user={member.user}
                  userType={userType}
                  key={member.user.relation_id}
                  noAvatar={isCompressed}
                />
              </Box>
            </GridRowName>
          </GridCell>
        ),
      };

      const cellArray: TableCell[] =
        userType === 'staff'
          ? [baseRow]
          : [
              baseRow,
              {
                id: 'DOB',
                renderContent: () => (
                  <GridCell
                    sx={{
                      width: 100,
                    }}
                    noVerticalPadding
                  >
                    <Typography color="text.primary">
                      {member.user.date_of_birth
                        ? format(newDateTimezoneOffset(member.user.date_of_birth), DATE_FORMAT)
                        : ''}
                    </Typography>
                  </GridCell>
                ),
              },
              {
                id: 'Gender',
                renderContent: () => (
                  <GridCell
                    sx={{
                      width: 90,
                    }}
                    noVerticalPadding
                  >
                    <Typography color="text.primary">{gender}</Typography>
                  </GridCell>
                ),
              },
            ];

      return {
        id: member.user.user_id ?? '',
        cells: cellArray,
        props: () => ({
          sx: {
            '&:hover': {
              '.MuiTypography-root': {
                color: theme.palette.primary.main,
              },
            },
          },
        }),
      };
    });
  }, [formatMessage, groupMembers, isCompressed, userType]);

  if (!groupMembers?.length) {
    return null;
  }

  return (
    <GridContainer
      sx={{
        mb: 0,
      }}
    >
      <Stack gap={2}>
        {!!groupMembers?.length && (
          <GroupPreviewMembersListCardsWrapper disabled={isDisabled}>
            <Table withDefaultHover rows={rows} columns={columns} />
          </GroupPreviewMembersListCardsWrapper>
        )}
      </Stack>
    </GridContainer>
  );
};
