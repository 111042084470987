import { Mutation, useIsMutating, useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash.isequal';
import { useEffect, useState } from 'react';

// Temporary solution before migrating to react query 5. After migration use the useMutationState hook
export const useMutationFromKey = (mutationKey: string[]) => {
  const [mutateState, setMutateState] = useState<Mutation>();
  const isLoading = useIsMutating(mutationKey);
  const queryClient = useQueryClient();

  useEffect(() => {
    return queryClient.getMutationCache().subscribe(({ type, mutation }) => {
      if (type === 'updated' && isEqual(mutation.options.mutationKey, mutationKey)) {
        setMutateState(mutation);
      }
    });
  }, [mutationKey, queryClient]);

  return { mutateState, isLoading: Boolean(isLoading) };
};
