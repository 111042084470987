import { Stack, Tooltip, Typography } from '@mui/material';
import { Assessment, usePerformAssessmentActionMutation } from '@schooly/api';
import { ApiError, AssessmentAction, ReportStatuses } from '@schooly/api';
import { HeaderActions } from '@schooly/components/header-actions';
import { NotificationActions, useNotifications } from '@schooly/components/notifications';
import { DATE_FORMAT } from '@schooly/constants';
import { PublishIcon, ReportGeneratedIcon, SimpleButton, UnpublishIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import AssessmentStatuses from '../../../constants/assessmentStatuses';
import { useLastRefreshTimeContext } from '../../../context/LastRefreshTimeContext';
import { useRouter } from '../../../context/router/useRouter';
import useAppLocation from '../../../hooks/useAppLocation';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';

interface AssessmentsPreviewActionsProps {
  assessment?: Assessment;
  isIncomplete?: boolean;
  onUpdate: () => Promise<any>;
}

export const AssessmentsPreviewActions: FC<AssessmentsPreviewActionsProps> = ({
  assessment,
  isIncomplete,
  onUpdate,
}) => {
  const navigate = useNavigate();
  const location = useAppLocation();
  const { showNotification } = useNotifications();
  const { id } = useParams<'id'>();
  const { closeAndClean } = useRouter();
  const { updateLastRefreshTime } = useLastRefreshTimeContext();
  const { $t } = useIntl();

  const performAssessmentActionMutation = usePerformAssessmentActionMutation();

  const isAssessmentPublished = assessment?.assessment_status === AssessmentStatuses.Published;
  const isReportPublished = assessment?.reports?.some(
    (r) => r.report_status === ReportStatuses.Published,
  );
  const hideButton = isAssessmentPublished && isReportPublished;

  const [title, tooltipTitle] = useMemo(() => {
    if (isIncomplete) {
      return [$t({ id: 'status-Draft' }), $t({ id: 'assessments-IncompleteHint' })];
    }

    if (isAssessmentPublished) {
      return [
        `${$t({ id: 'status-Published' })}${' '} ${
          assessment?.publish_date
            ? format(newDateTimezoneOffset(assessment.publish_date), DATE_FORMAT)
            : ''
        }`,
        undefined,
      ];
    }

    return [$t({ id: 'status-NotPublished' }), undefined];
  }, [$t, assessment?.publish_date, isAssessmentPublished, isIncomplete]);

  const performAction = useCallback(
    async (action: AssessmentAction) => {
      if (!id) return;

      const notificationActions: NotificationActions | undefined =
        action === AssessmentAction.Publish
          ? [
              {
                textId: 'assessments-ViewAssessment',
                handler: () =>
                  navigate(`/assessments/${id}`, {
                    state: location.state,
                  }),
                buttonColor: 'light',
              },
              {
                textId: 'assessments-Unpublish',
                handler: async () => {
                  try {
                    await performAssessmentActionMutation.mutateAsync({
                      id,
                      action: AssessmentAction.Unpublish,
                    });

                    await onUpdate();
                    navigate(`/assessments/${id}`, {
                      state: location.state,
                    });
                  } catch (error) {
                    showNotification({
                      message: (error as ApiError).reason,
                      type: 'error',
                    });
                  }
                },
                isOutline: true,
                buttonColor: 'white-text',
              },
            ]
          : undefined;

      const response = await performAssessmentActionMutation.mutateAsync({
        id,
        action,
      });

      await onUpdate();

      showNotification({
        message: response.success,
        type: 'success',
        actions: notificationActions,
      });

      updateLastRefreshTime();

      if (action === AssessmentAction.Publish) {
        closeAndClean();
      }
    },
    [
      id,
      performAssessmentActionMutation,
      navigate,
      location.state,
      onUpdate,
      showNotification,
      updateLastRefreshTime,
      closeAndClean,
    ],
  );

  const [publishAssessment, isPublishing] = useRequestWithProgress(performAction);

  const handleClick = useCallback(async () => {
    const action = isAssessmentPublished ? AssessmentAction.Unpublish : AssessmentAction.Publish;
    await publishAssessment(action);
    return true;
  }, [isAssessmentPublished, publishAssessment]);

  const actions = useMemo(() => {
    return [
      {
        titleTextId: $t({
          id: isAssessmentPublished ? 'assessments-Unpublish' : 'assessments-Publish',
        }),
        handler: !assessment?.autogenerated ? handleClick : undefined,
        icon: isAssessmentPublished ? (
          <UnpublishIcon />
        ) : (
          <PublishIcon className="reset-svg-currentColor" />
        ),
        pendingTitle: $t({ id: isAssessmentPublished ? 'unPublishing' : 'publishing' }),
      },
    ];
  }, [$t, handleClick, isAssessmentPublished, assessment?.autogenerated]);

  if (!assessment) {
    return null;
  }

  const headerActions = (
    <HeaderActions
      title={title ?? ''}
      tooltipTitle={tooltipTitle}
      actions={actions}
      styleType={isAssessmentPublished ? 'success' : undefined}
      pending={isPublishing}
      disabled={
        !assessment?.can_be_edited || assessment.autogenerated || isIncomplete || hideButton
      }
    />
  );

  return assessment.autogenerated ? (
    <Tooltip
      title={
        <Stack alignItems="flex-start" gap={1}>
          <Typography>{$t({ id: 'assessments-AutoGeneratedAction' })}</Typography>
          <SimpleButton
            onClick={() => {
              navigate(`/reports/${assessment?.generated_from_report_id}`);
            }}
            sx={(theme) => ({
              '.svg-icon': {
                width: theme.spacing(2),
                height: theme.spacing(2),
              },
              fontSize: theme.spacing(1.5),
            })}
            startIcon={<ReportGeneratedIcon />}
          >
            {$t({ id: 'assessments-GoToReport' })}
          </SimpleButton>
        </Stack>
      }
    >
      <Stack>{headerActions}</Stack>
    </Tooltip>
  ) : (
    headerActions
  );
};
