import { Box, Button, Paper } from '@mui/material';
import { PlusIcon } from '@schooly/style';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { Menu, MenuItemArrow } from '../../components/common/ContextMenu/ContextMenu.styled';
import {
  ContextMenuItem,
  ContextMenuItemProps,
} from '../../components/common/ContextMenu/ContextMenuItem';
import { useCheckSchoolSettings } from '../../hooks/useCheckSchoolSettings';

export const SchoolPageAddPropertyButton: FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const { hasSubjects, hasDepartments, hasHouses } = useCheckSchoolSettings();

  const handlePopoverOpen = useCallback(() => {
    setAnchorEl(buttonRef.current);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const items = useMemo<ContextMenuItemProps[]>(
    () =>
      [
        !hasSubjects && {
          titleTextId: 'school-AddSubjects',
          onMenuClick: () => {
            handlePopoverClose();
            navigate('/settings/subjects');
          },
        },
        !hasHouses && {
          titleTextId: 'school-AddHouses',
          onMenuClick: () => {
            handlePopoverClose();
            navigate('/settings/houses');
          },
        },
        !hasDepartments && {
          titleTextId: 'school-AddDepartments',
          onMenuClick: () => {
            handlePopoverClose();
            navigate('/settings/departments');
          },
        },
      ].filter((item) => item) as unknown as ContextMenuItemProps[],
    [handlePopoverClose, hasDepartments, hasHouses, hasSubjects, navigate],
  );

  if (items.length < 1) {
    return null;
  }

  return (
    <Box>
      <Button ref={buttonRef} startIcon={<PlusIcon />} onClick={handlePopoverOpen}>
        <FormattedMessage id="school-AddNewProperty" />
      </Button>

      <MenuItemArrow anchorEl={anchorEl} open={open}>
        <Paper />
      </MenuItemArrow>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiList-root': {
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            p: 1,
            width: 215,
          },

          '& .MuiMenuItem-root': {
            m: 0,
            px: 1,
            py: 0.25,
            width: 'unset',
          },
        }}
      >
        {items.map((item) => (
          <ContextMenuItem key={item.titleTextId} {...item} />
        ))}
      </Menu>
    </Box>
  );
};
