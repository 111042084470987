import { getUserAgent } from './userAgent';

export const isIOS = (): boolean => {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
  const userAgent = getUserAgent();
  return /iPhone|iPad|iPod/i.test(userAgent);
};

export const isAndroid = (): boolean => {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
  const userAgent = getUserAgent();
  return /android/i.test(userAgent);
};
