import { ChipProps } from '@mui/material';
import { FilterKeys, useGetAssessmentsQuery } from '@schooly/api';
import { FC } from 'react';

import { RecurrenceLabelSelect } from './RecurrenceSelectLabel';

export type AssessmentLabelSelectProps = {
  id: string;
  schoolId: string;
  title: string;
} & Omit<ChipProps, 'label'>;

export const AssessmentRecurrenceLabelSelect: FC<AssessmentLabelSelectProps> = ({
  id,
  schoolId,
  title,
}) => {
  const { data } = useGetAssessmentsQuery(
    {
      school_id: schoolId,
      query: '',
      filters: { [FilterKeys.RecurrenceId]: [id] },
    },
    { enabled: !title },
  );

  const firstAssessment = data?.pages?.[0]?.results?.[0];
  const selectedOptionTitle = title || firstAssessment?.recurring_state?.recurrence_name;

  return <RecurrenceLabelSelect title={selectedOptionTitle} />;
};
