import { styled } from '@mui/material';
import { ModalContent, ModalFooter, ModalHeader, ModalSmall } from '@schooly/style';

export const AuthenticationModal = styled(ModalSmall)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.common.light2,
  },
  '& .MuiDialog-paper': {
    width: '100%',
    height: '100%',

    boxShadow: '0px 30px 50px rgba(37, 39, 90, 0.1)',
    backgroundColor: 'white',

    [theme.breakpoints.up('md')]: {
      minWidth: 700,
      minHeight: '100%',
    },
  },
}));

type AuthenticationModalHeaderProps = {
  noBorder?: boolean;
};

export const AuthenticationModalHeader = styled(ModalHeader)<AuthenticationModalHeaderProps>(
  ({ noBorder }) => ({
    backgroundColor: 'white',
    borderBottomColor: noBorder ? 'transparent' : undefined,
    paddingBottom: noBorder ? 0 : undefined,
  }),
);

export const AuthenticationModalContent = styled(ModalContent)(({ theme }) => ({
  backgroundColor: 'white',
  paddingTop: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(3.5),
  },
}));

export const AuthenticationModalFooter = styled(ModalFooter)(() => ({
  backgroundColor: 'white',
}));
