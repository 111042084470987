import { Stack } from '@mui/material';
import { ConductVisibility } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import { CONDUCT_STATUS_OPTIONS } from './ConductVisibilitySelectMultiple';
import {
  ConductVisibilityTagSelect,
  ConductVisibilityTagSelectProps,
} from './ConductVisibilityTagSelect';

type ConductVisibilityExpandedSelectProps = PropsWithChildren<{
  selectedVisibility: ConductVisibility[];
  onClear: () => void;
  onSelectConductVisibility: (v: ConductVisibility) => void;
  onClose: () => void;
}>;

export const ConductVisibilityExpandedSelect: FC<ConductVisibilityExpandedSelectProps> = ({
  selectedVisibility,
  onSelectConductVisibility,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderConductVisibilityTags({
          visibilities: CONDUCT_STATUS_OPTIONS.map((o) => o.value),
          tagProps: (status) => ({
            variant: selectedVisibility.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectConductVisibility,
        })}
      </Stack>
    );
  }, [onSelectConductVisibility, selectedVisibility]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedVisibility.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={renderContent}
    >
      {renderConductVisibilityTags({
        visibilities: selectedVisibility,
        tagProps: { size: 'small' },
        onDelete: onSelectConductVisibility,
      })}
    </ExpandedSelect>
  );
};

type RenderConductVisibilityTagsParams = {
  visibilities: ConductVisibility[];
  onDelete?: (v: ConductVisibility) => void;
  onClick?: (v: ConductVisibility) => void;
  tagProps?:
    | Omit<ConductVisibilityTagSelectProps, 'visibility'>
    | ((v: ConductVisibility) => Omit<ConductVisibilityTagSelectProps, 'visibility'>);
};

export const renderConductVisibilityTags = ({
  visibilities,
  onDelete,
  onClick,
  tagProps,
}: RenderConductVisibilityTagsParams) =>
  visibilities.map((v) => (
    <ConductVisibilityTagSelect
      key={v}
      visibility={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
