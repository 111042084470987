import { Box, Stack, Typography } from '@mui/material';
import {
  ApiError,
  Comment,
  CONDUCT_ENTRIES_GROUP_BY_FILTER_KEYS,
  CONDUCT_ENTRIES_QUERY_FILTER_KEYS,
  ConductEntry,
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GET_CONDUCT_ENTRIES_QUERY,
  GetConductEntriesQueryFilters,
  GetConductEntriesQuerySort,
  PagedResponse,
  SORT_DIRECTION,
  useAddEntryCommentMutation,
  useEditEntryCommentMutation,
  useGetConductEntriesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { ConductChart, ConductChartIconButton } from '@schooly/components/charts';
import {
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useFiltersStateFromSearchParams,
  useGroupByFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolPropertyType } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ChartIcon,
  GridBody,
  MainPageGrid,
  PlusIcon,
  SkeletonGridLoader,
  SkeletonRows,
} from '@schooly/style';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { endOfToday, format, startOfToday } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../components/common/AccessDenied';
import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import { DropdownComments } from '../../components/uikit-components/DropdownCommentsV2/DropdownComments';
import { CommentRowCell } from '../../components/uikit-components/DropdownCommentsV2/DropdownCommentsWrappers/CommentRowCell';
import useAppLocation from '../../hooks/useAppLocation';
import { useSchool } from '../../hooks/useSchool';
import useSchoolYears from '../../hooks/useSchoolYears';
import useUserCounts from '../../hooks/useUserCounts';
import { commentToDropdownCommentItem } from '../../utils/convertComments';
import { ConductExport } from './ConductExport';
import { ConductEntriesFilters } from './ConductFilters';
import { ConductEntriesHeader, ConductEntryRow, ConductEntryRowProps } from './ConductGrid';
import { ConductNoStudentsStub } from './ConductNoStudentsStub';

type ConductContentProps = {
  initialFilters?: GetConductEntriesQueryFilters;
  initialGroupBy?: FilterKeys.Student | null;
};

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 8;

export const ConductContent: FC<ConductContentProps> = ({ initialFilters, initialGroupBy }) => {
  const { showNotification } = useNotifications();
  const { formatMessage } = useIntl();
  const { userCounts } = useUserCounts();
  const { schoolId = '' } = useSchool();
  const queryClient = useQueryClient();

  const { lastAppliedFilter, lastAppliedGroupBy } = useLastAppliedFiltersState({
    type: StoredFilterSections.Conduct,
    filterKeys: CONDUCT_ENTRIES_QUERY_FILTER_KEYS,
    groupByKeys: CONDUCT_ENTRIES_GROUP_BY_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const groupByFromSearchParams = useGroupByFromSearchParams([FilterKeys.Student]);
  const groupByFromFiltersOrProps = useMemo(() => {
    if (lastAppliedGroupBy !== undefined) {
      return lastAppliedGroupBy;
    }
    if (groupByFromSearchParams !== undefined) {
      return groupByFromSearchParams;
    }
    return initialGroupBy;
  }, [groupByFromSearchParams, initialGroupBy, lastAppliedGroupBy]);

  const [groupBy, setGroupBy] = useState<FilterKeys.Student | null>(
    groupByFromFiltersOrProps === undefined ? FilterKeys.Student : groupByFromFiltersOrProps,
  );
  const { permissions, currentStaff } = useAuth();
  const [isChartsOpened, openCharts, closeCharts] = useFlag(false);
  const navigate = useNavigate();
  const location = useAppLocation();
  const [hoverId, setHoverId] = useState<string | null>(null);
  const { showError } = useNotifications();
  const [pendingEntriesId, setPendingEntriesId] = useState<Set<string>>(new Set());

  const defaultFilters: GetConductEntriesQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [
        format(startOfToday(), DEFAULT_DATE_FORMAT_FNS),
        format(endOfToday(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [],
  );

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: CONDUCT_ENTRIES_QUERY_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const addEntryCommentMutation = useAddEntryCommentMutation();
  const editEntryCommentMutation = useEditEntryCommentMutation();

  const {
    data,
    setParams,
    params,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    error,
  } = useGetConductEntriesQuery(
    {
      schoolId,
      pageSize: PAGE_SIZE,
      filters: lastAppliedFilter ?? initialFiltersState,
      sort: { columnTextId: 'student', direction: SORT_DIRECTION.ASC },
      query: '',
    },
    { refetchOnMount: 'always' },
  );

  useSyncFiltersStateWithSearchParams({ pathname: '/conduct', filters: params.filters, groupBy });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Conduct,
    filters: params.filters,
    groupBy: groupBy ?? null,
    schoolId: schoolId || '',
  });

  const handleChangeSort = useCallback(
    (sort: GetConductEntriesQuerySort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  const { defaultValidity } = useSchoolYears();

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetConductEntriesQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const canEdit = permissions.includes('conduct_manager');
  const canView = permissions.includes('conduct_viewer');

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const handleAddButtonClick = useCallback(() => {
    navigate('/conduct/new', {
      state: { backgroundLocation: location },
    });
  }, [location, navigate]);

  const entries = useMemo(
    () => data?.pages.reduce<ConductEntry[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  const groupedEntries = useMemo(() => {
    if (groupBy === FilterKeys.Student) {
      // group by students
      return entries?.reduce<Array<Array<ConductEntry>>>((prev, entry, index) => {
        if (
          prev.length > 0 &&
          prev[prev.length - 1]?.[0]?.student.relation_id === entry.student.relation_id
        ) {
          prev[prev.length - 1].push(entry);
        } else {
          prev.push([entry]);
        }

        return prev;
      }, []);
    } else {
      // convert to ConductEntry[][]
      return entries?.map((entry) => [entry]);
    }
  }, [entries, groupBy]);

  const addPendingEntry = (entry: ConductEntry) =>
    setPendingEntriesId((set) => {
      set.add(entry.id);
      return new Set(set);
    });

  const removePendingEntry = (entry: ConductEntry) =>
    setPendingEntriesId((set) => {
      set.delete(entry.id);
      return new Set(set);
    });

  const handleCommentAdd = (entry: ConductEntry) => async (comment: string) => {
    if (!comment || !currentStaff) return;

    addPendingEntry(entry);

    try {
      const result = await addEntryCommentMutation.mutateAsync({
        entryId: entry.id,
        comment: { comment, creator_relation_id: currentStaff.relation_id },
      });

      const newComment: Comment = {
        comment,
        id: result.comment_id,
        creator_title: currentStaff.title,
        creator_relation_id: currentStaff.relation_id,
        creator_known_as: currentStaff.known_as,
        creator_last_name: currentStaff.last_name,
        creator_given_name: currentStaff.given_name,
      };

      queryClient.setQueryData(
        [GET_CONDUCT_ENTRIES_QUERY, params],
        (data?: InfiniteData<PagedResponse<ConductEntry>>) =>
          data
            ? {
                ...data,
                pages: data.pages.map((page) => ({
                  ...page,
                  results: page.results.map((r) =>
                    r.id === entry.id ? { ...r, comments: [...r.comments, newComment] } : r,
                  ),
                })),
              }
            : undefined,
      );
    } catch (e) {
      showError(e as ApiError);
    }

    removePendingEntry(entry);
  };

  const total = data?.pages[0]?.count;
  const noResults = !isLoading && !entries.length;
  const showCharts = isChartsOpened && !noResults;

  const handleCommentEdit =
    (entry: ConductEntry) => async (comment: string, relationId: string) => {
      if (comment === undefined || !currentStaff) return;

      const existingComment = relationId
        ? entry.comments.find((comment) => comment.creator_relation_id === relationId)
        : undefined;

      if (!existingComment || existingComment.comment === comment) {
        return;
      }

      addPendingEntry(entry);

      try {
        await editEntryCommentMutation.mutateAsync({
          entryId: entry.id,
          commentId: existingComment.id,
          comment: { comment },
        });

        queryClient.setQueryData(
          [GET_CONDUCT_ENTRIES_QUERY, params],
          (data?: InfiniteData<PagedResponse<ConductEntry>>) =>
            data
              ? {
                  ...data,
                  pages: data.pages.map((page) => ({
                    ...page,
                    results: page.results.map((r) =>
                      r.id === entry.id
                        ? {
                            ...r,
                            comments: comment
                              ? r.comments.map((c) =>
                                  c.id === existingComment.id ? { ...c, comment } : c,
                                )
                              : r.comments.filter((c) => c.id !== existingComment.id),
                          }
                        : r,
                    ),
                  })),
                }
              : undefined,
        );
      } catch (e) {
        showError(e as ApiError);
      }

      removePendingEntry(entry);
    };

  const conductLogsCount = data?.pages?.[0].count;

  const handleExportSuccess = useCallback(() => {
    showNotification({
      message: formatMessage({ id: 'conduct-ExportSuccess' }, { count: conductLogsCount }),
      type: 'info',
    });
  }, [showNotification, conductLogsCount, formatMessage]);

  if (error || !canView) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          buttonTextId="conduct-NewLog"
          pageTitleTextId="conduct-Title"
          showActionButton={permissions.includes('conduct_manager')}
          buttonIcon={<PlusIcon />}
          buttonDisabled={!userCounts?.student}
          onButtonClick={handleAddButtonClick}
          actionsContent={
            !!conductLogsCount && (
              <Box sx={{ mr: 2.5 }}>
                <ConductExport params={params} onSuccess={handleExportSuccess}>
                  <Typography variant="h3" color="primary.main">
                    {formatMessage({ id: 'conduct-Export' }, { count: conductLogsCount })}
                  </Typography>
                </ConductExport>
              </Box>
            )
          }
        >
          <Stack gap={2} direction="row" alignItems="center" width="100%">
            <PageHeaderSearchInput
              value={params.query || ''}
              onChangeText={handleSetFiltersQuery}
              placeholder={formatMessage({ id: 'people-Search' })}
            />
          </Stack>
        </PageHeader>

        <ConductEntriesFilters
          groupBy={groupBy}
          onSetGroupBy={setGroupBy}
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          defaultSchoolYear={defaultValidity}
          defaultUserFilters={defaultUserFilters}
          defaultUserGroupBy={initialGroupBy === undefined ? FilterKeys.Student : initialGroupBy}
        />
      </Stack>
      {showCharts && (
        <Stack mt={3}>
          <ConductChart
            schoolId={schoolId}
            schoolPropertyType={SchoolPropertyType.House}
            conductTypeOptionIds={params.filters.conduct_type}
            onCloseChart={closeCharts}
            filters={params.filters}
          />
        </Stack>
      )}
      <MainPageGrid mt={2.4}>
        <ConductEntriesHeader
          sort={params.sort}
          onChangeSort={handleChangeSort}
          rightIcon={
            !showCharts ? (
              <ConductChartIconButton
                schoolId={schoolId}
                conductTypeOptionIds={params.filters.conduct_type}
                onClick={openCharts}
                disabled={!userCounts?.student || noResults}
                inverse
              >
                <ChartIcon />
              </ConductChartIconButton>
            ) : undefined
          }
        />
        <GridBody>
          {groupedEntries?.map((records) => {
            const entry = records[0];

            if (!entry) return null;

            const subEntries = records.slice(1);

            const isExpanded = expandedRows[entry.student.relation_id];

            const entryRowProps = (entry: ConductEntry): ConductEntryRowProps => ({
              schoolId,
              entry,
              hoverIdStudentId: hoverId,
              isExpanded,
              onMouseEnter: () => setHoverId(entry.student.relation_id),
              onMouseLeave: () => setHoverId(null),
              isEditable: canEdit,
              commentsCellContent: (
                <CommentRowCell rowStyleProps={{ my: -1 }}>
                  {(onToggle, cellRef) => (
                    <DropdownComments
                      comments={entry.comments.map(commentToDropdownCommentItem)}
                      onAdd={handleCommentAdd(entry)}
                      onEdit={handleCommentEdit(entry)}
                      disabled={pendingEntriesId.has(entry.id)}
                      canAdd={canEdit}
                      canEditOwn={canEdit}
                      canEditOther={canEdit}
                      getParentRef={() => cellRef}
                      onToggle={(val) => {
                        setHoverId('');
                        onToggle(val);
                      }}
                      popoverMargin={2}
                    />
                  )}
                </CommentRowCell>
              ),
            });

            const toggleExpand =
              groupBy === 'student' && subEntries.length
                ? () =>
                    setExpandedRows((expandedRows) => ({
                      ...expandedRows,
                      [entry.student.relation_id]: !expandedRows[entry.student.relation_id],
                    }))
                : undefined;

            const onClickEntry = (entry: ConductEntry) =>
              canEdit
                ? () =>
                    navigate(`/conduct/${entry.id}/edit`, {
                      state: { backgroundLocation: location },
                    })
                : undefined;

            const onClick = !isExpanded && !!toggleExpand ? toggleExpand : onClickEntry(entry);

            return [
              <ConductEntryRow
                key={entry.id}
                {...entryRowProps(entry)}
                subEntries={subEntries}
                onClick={onClick}
                onExpand={toggleExpand}
              />,
              ...(expandedRows[entry.student.relation_id]
                ? subEntries.map((subEntry, index) => (
                    <ConductEntryRow
                      isLast={index === subEntries.length - 1}
                      key={subEntry.id}
                      onClick={onClickEntry(subEntry)}
                      {...entryRowProps(subEntry)}
                    />
                  ))
                : []),
            ];
          })}
          {isLoading && <SkeletonRows columnsCount={10} amount={PAGE_SIZE} />}
          <SkeletonGridLoader
            isFetching={isLoading || isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            columnsCount={SKELETON_COLS}
            amount={Math.min(
              PAGE_SIZE,
              total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
            )}
          />
        </GridBody>
      </MainPageGrid>

      {!userCounts?.student ? (
        <ConductNoStudentsStub />
      ) : (
        noResults && (
          <NoListItemsStub
            titleText={<FormattedMessage id="conduct-NoConductExist-title" />}
            subTitleText={
              canEdit ? <FormattedMessage id="conduct-NoConductExist-subtitle" /> : undefined
            }
            buttonText={canEdit ? <FormattedMessage id="conduct-NewLog" /> : undefined}
            onButtonClick={handleAddButtonClick}
            type="conduct"
          />
        )
      )}
    </>
  );
};
