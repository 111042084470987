import { FilterKeys } from '@schooly/api';
import { ECElementEvent } from 'echarts';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

export interface ChartsContextProps {
  schoolId: string;
  isChartsOpened: boolean;
  showZeroValues: boolean;
  onToggleChartsOpened: () => void;
  onToggleZeroValues: () => void;
  chartSelectedElement?: ECElementEvent;
  breakDownBy?: FilterKeys;
  setBreakDownBy: Dispatch<SetStateAction<FilterKeys | undefined>>;
  setChartSelectedElement: Dispatch<SetStateAction<ECElementEvent | undefined>>;
}

export const ChartsContext = createContext<ChartsContextProps>({
  schoolId: '',
  isChartsOpened: false,
  showZeroValues: false,
  onToggleChartsOpened: () => {},
  onToggleZeroValues: () => {},
  setBreakDownBy: () => {},
  setChartSelectedElement: () => {},
});

type WithChartsProps = PropsWithChildren<{
  schoolId: string;
  isOpen?: boolean;
  showZeroValues?: boolean;
}>;

export const WithCharts: FC<WithChartsProps> = ({
  children,
  schoolId,
  isOpen,
  showZeroValues: propShowZeroValues,
}) => {
  const [isChartsOpened, setChartsVisible] = useState(Boolean(isOpen));
  const [showZeroValues, setShowZeroValues] = useState(Boolean(propShowZeroValues));
  const [breakDownBy, setBreakDownBy] = useState<FilterKeys | undefined>();
  const [chartSelectedElement, setChartSelectedElement] = useState<ECElementEvent>();

  const handleToggleChartsOpened = useCallback(() => {
    setChartsVisible((v) => !v);
  }, []);

  const handleToggleZeroValues = useCallback(() => {
    setShowZeroValues((v) => !v);
  }, []);

  return (
    <ChartsContext.Provider
      value={{
        breakDownBy,
        schoolId,
        isChartsOpened,
        showZeroValues,
        chartSelectedElement,
        setBreakDownBy,
        onToggleChartsOpened: handleToggleChartsOpened,
        onToggleZeroValues: handleToggleZeroValues,
        setChartSelectedElement,
      }}
    >
      {children}
    </ChartsContext.Provider>
  );
};

export const useCharts = () => useContext(ChartsContext);
