import { Time } from '@schooly/api';
import { isTimeInPast } from '@schooly/components/filters';
import { TIME_FORMAT } from '@schooly/constants';
import { getClientLanguage } from '@schooly/utils/translations';
import {
  addMinutes,
  formatDuration,
  intervalToDuration,
  isAfter,
  isToday,
  parse,
  setDefaultOptions,
  startOfToday,
} from 'date-fns';
import * as locale from 'date-fns/locale';
import moment from 'moment';

/*
This code uses the fact that getTimezoneOffset returns a greater value during Standard Time versus Daylight Saving Time (DST).
Thus it determines the expected output during Standard Time, and it compares whether the output of the given date the same (Standard) or less (DST).

https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
*/

export const MIN_CONSISTENT_YEAR = 1900;
export const MIN_OFFSET_YEAR = 1930;
export const MAX_CONSISTENT_YEAR = 9999;

const stdTimezoneOffset = () => {
  const fullYear = new Date().getFullYear();

  const jan = new Date(fullYear, 0, 1);
  const jul = new Date(fullYear, 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

const timezoneOffset = stdTimezoneOffset();

export const newDateTimezoneOffset = (initialDateString?: string | null) => {
  if (!initialDateString) return new Date();

  const dateFromString = new Date(initialDateString);
  if (dateFromString.getFullYear() < MIN_OFFSET_YEAR) return dateFromString;

  return addMinutes(dateFromString, timezoneOffset);
};

/**
 * Fixes the timezone offset of the given date string in datepickers
 * https://schooly.atlassian.net/browse/TR-6474
 * https://stackoverflow.com/questions/17545708/parse-date-without-timezone-javascript
 */
export const getDateWithoutTime = (initialDateString: string): Date => {
  const date = new Date(initialDateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const isDateInPast = (date: string, startTime?: Time) => {
  const currentDate = newDateTimezoneOffset(date);
  const today = startOfToday();

  if (isAfter(today, currentDate)) {
    return true;
  }

  if (isToday(currentDate) && startTime) {
    return isTimeInPast(startTime);
  }

  return false;
};

export const isTimesRangeValid = (timeFrom: string, timeTo: string) => {
  const start = parse(timeFrom, TIME_FORMAT, new Date());
  const end = parse(timeTo, TIME_FORMAT, new Date());
  return start < end;
};

export const getMinutesDurationLabel = (minutes: number) =>
  formatDuration(
    intervalToDuration({
      start: new Date(2000, 0, 1, 1, 0, 0),
      end: new Date(2000, 0, 1, 1, minutes, 0),
    }),
    { format: ['hours', 'minutes'] },
  );

function instanceOfDateFnsLocaleKey(str: string): str is keyof typeof locale {
  return str in locale;
}

export const getDateFnsLocaleByLangCode = (langCode: string): Locale => {
  const dateFnsLocale = instanceOfDateFnsLocaleKey(langCode) ? locale[langCode] : locale.enUS;

  return {
    ...dateFnsLocale,
    options: {
      ...dateFnsLocale.options,
      weekStartsOn: 1,
    },
  };
};

export function initDateDefaultOptions(overrideLanguage?: string) {
  const langCode = getClientLanguage(overrideLanguage);
  const dateFnsLocale = getDateFnsLocaleByLangCode(langCode);

  moment.locale(langCode, { week: { dow: 1 } });
  setDefaultOptions({
    locale: dateFnsLocale,
    weekStartsOn: 1,
  });
}
