import {
  ApiError,
  FilterValue,
  GET_ATTENDANCE_CODES_QUERY,
  GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY,
  NotificationAttendanceSettings,
  NotificationSettings,
  RQUseQueryOptions,
  useChangeNotificationAttendanceSettingsMutation,
  useGetNotificationAttendanceSettings,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export const useNotificationSettings = (options?: RQUseQueryOptions<NotificationSettings>) => {
  const { schoolId = '' } = useAuth();
  const { showError } = useNotifications();
  const { data } = useGetNotificationAttendanceSettings({ schoolId }, { ...options });
  const updateAttendanceNotificationSettings = useChangeNotificationAttendanceSettingsMutation();
  const queryClient = useQueryClient();
  const [selectedCodes, setSelectedCodes] = useState<FilterValue[]>();

  const attendanceCodeIds = useMemo(
    () =>
      (data?.attendance.notifications || []).reduce<string[]>(
        (acc, { id, has_push_notifications }) => (has_push_notifications ? [...acc, id] : acc),
        [],
      ),
    [data?.attendance.notifications],
  );

  const changeAttendanceNotifications = useCallback(
    async ({ notifications, enabled }: NotificationAttendanceSettings) => {
      try {
        await updateAttendanceNotificationSettings.mutateAsync({
          notifications,
          enabled: Boolean(notifications.length) ? enabled : false,
          schoolId,
        });

        queryClient.invalidateQueries([GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY]);
        queryClient.invalidateQueries([GET_ATTENDANCE_CODES_QUERY]);

        return data?.attendance;
      } catch (err) {
        showError(err as ApiError);
        setSelectedCodes(attendanceCodeIds);
      }
    },
    [
      updateAttendanceNotificationSettings,
      schoolId,
      queryClient,
      data?.attendance,
      showError,
      attendanceCodeIds,
    ],
  );

  const onCodesSubmit = useCallback(
    async (enabled: boolean) => {
      if (selectedCodes === undefined || isEqual(attendanceCodeIds, selectedCodes)) {
        return;
      }

      const codes = selectedCodes ?? attendanceCodeIds;

      const data = codes.map((id) => {
        return { id: id.toString() };
      });

      const response = await changeAttendanceNotifications({
        enabled,
        notifications: data ?? [],
      });

      if (response) {
        return response;
      }
    },
    [attendanceCodeIds, selectedCodes, changeAttendanceNotifications],
  );

  const onToggleSwitch = useCallback(
    async (enabled: boolean) => {
      const codes = selectedCodes ?? attendanceCodeIds;

      const data = codes.map((id) => {
        return { id: id.toString() };
      });

      const response = await changeAttendanceNotifications({
        enabled,
        notifications: data,
      });

      if (response) {
        return response;
      }
    },
    [selectedCodes, attendanceCodeIds, changeAttendanceNotifications],
  );

  return {
    fetching: !data,
    updating: updateAttendanceNotificationSettings.isLoading,
    attendanceNotificationsEnabled: !!data?.attendance.enabled,
    selectedCodes,
    setSelectedCodes,
    onCodesSubmit,
    onToggleSwitch,
    attendanceCodeIds,
  };
};
