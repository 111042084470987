import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50012 14.915L3.79303 9.20795C3.40251 8.81742 3.40251 8.18426 3.79303 7.79373C4.18356 7.40321 4.81672 7.40321 5.20724 7.79373L9.50015 12.0866L16.2944 5.29274C16.6849 4.90223 17.3181 4.90224 17.7086 5.29278C18.0991 5.68331 18.0991 6.31647 17.7086 6.70699L9.50012 14.915Z"
      fill="white"
    />
  </svg>
);
