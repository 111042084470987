import { AgeGroup, FilterKeys, SchoolProperty } from '@schooly/api';
import {
  GENDER_OPTIONS,
  NATIONALITY_OPTIONS,
  PROPERTIES_TEXT_IDS,
  SchoolPropertyType,
} from '@schooly/constants';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { BarClickData } from './ChartBar';

export const SCHOOL_PROPERTIES = [
  SchoolPropertyType.AgeGroup,
  SchoolPropertyType.House,
  SchoolPropertyType.Status,
];

export const useGetLabel = ({
  arrangeBy,
  schoolProperties,
}: {
  arrangeBy?: FilterKeys | null;
  schoolProperties?: Array<SchoolProperty | AgeGroup>;
}) => {
  const { $t } = useIntl();

  return useCallback(
    ({ dataKey, dataValue, name }: BarClickData) => {
      if (!arrangeBy) {
        return '';
      }

      if (dataValue === FilterKeys.TutorGroup) {
        return `${$t({ id: PROPERTIES_TEXT_IDS[dataValue] })}`;
      }

      if (name) {
        return name;
      }

      if (!dataKey) {
        return $t({ id: PROPERTIES_TEXT_IDS[arrangeBy] });
      }

      if (dataValue === null && dataKey in PROPERTIES_TEXT_IDS) {
        return `${$t({ id: 'without' })} ${$t({ id: PROPERTIES_TEXT_IDS[dataKey] })}`;
      }

      if (dataKey in SCHOOL_PROPERTIES) {
        return schoolProperties?.find((p) => p.id === dataValue)?.name || '';
      }

      switch (dataKey) {
        case FilterKeys.Gender:
          return GENDER_OPTIONS.find((o) => o.value === dataValue)?.label || '';
        case FilterKeys.Nationality:
          return NATIONALITY_OPTIONS.find((o) => o.value === dataValue)?.label || '';
      }

      return 'LABEL';
    },
    [$t, arrangeBy, schoolProperties],
  );
};
