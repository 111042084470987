import {
  CustomFieldApplicableTo,
  CustomFieldDataType,
  CustomFieldPlaceOfUse,
  SelectOption,
} from '@schooly/api';

export const APPLICABLE_TO_OPTIONS: SelectOption<number>[] = [
  {
    value: CustomFieldApplicableTo.GeneralInformation,
    labelTextId: 'school-sections-GeneralInformation',
  },
  {
    value: CustomFieldApplicableTo.Staff,
    labelTextId: 'userType-staff-plural',
  },
  {
    value: CustomFieldApplicableTo.Student,
    labelTextId: 'userType-student',
  },
  {
    value: CustomFieldApplicableTo.Parent,
    labelTextId: 'userType-parent',
  },
];

export const REFERS_TO_APPLICATION_OPTIONS: SelectOption<number>[] = [
  {
    value: CustomFieldApplicableTo.GeneralInformation,
    labelTextId: 'school-sections-GeneralInformation',
  },
  {
    value: CustomFieldApplicableTo.Student,
    labelTextId: 'userType-student',
  },
  {
    value: CustomFieldApplicableTo.Parent,
    labelTextId: 'userType-parent',
  },
];

export const REFERS_TO_PROFILE_OPTIONS: SelectOption<number>[] = [
  {
    value: CustomFieldApplicableTo.Student,
    labelTextId: 'userType-student',
  },
  {
    value: CustomFieldApplicableTo.Staff,
    labelTextId: 'userType-staff-plural',
  },
  {
    value: CustomFieldApplicableTo.Parent,
    labelTextId: 'userType-parent',
  },
];

export const APPLICATION_PROFILE_OPTIONS: SelectOption<CustomFieldPlaceOfUse>[] = [
  {
    value: CustomFieldPlaceOfUse.Application,
    labelTextId: 'input-Application',
  },
  {
    value: CustomFieldPlaceOfUse.Profile,
    labelTextId: 'input-Profile',
  },
];

export const TYPE_OPTIONS: SelectOption<CustomFieldDataType>[] = [
  {
    value: CustomFieldDataType.STRING,
    labelTextId: 'school-sections-CustomFields-CustomFieldType-string',
  },
  {
    value: CustomFieldDataType.NUMBER,
    labelTextId: 'school-sections-CustomFields-CustomFieldType-number',
  },
  {
    value: CustomFieldDataType.BOOLEAN,
    labelTextId: 'school-sections-CustomFields-CustomFieldType-boolean',
  },
  {
    value: CustomFieldDataType.DATE,
    labelTextId: 'school-sections-CustomFields-CustomFieldType-date',
  },
  {
    value: CustomFieldDataType.SELECT,
    labelTextId: 'school-sections-CustomFields-CustomFieldType-select',
  },
];

export const SELECT_LABEL_MAX_BODY_LENGTH = 100;
