import { BoxProps } from '@mui/material';
import React, { FC } from 'react';
import { useDrag } from 'react-dnd';

import {
  AnnualPlannerCalendarRecordCellProps,
  AnnualPlannerCalendarRecordGroup,
} from '../AnnualPlannerCalendar.styled';
import { UseAnnualPlannerCellItem } from '../useAnnualPlannerCell';
import { ANNUAL_PLANNER_DND_TYPE } from '../WithAnnualPlannerDnD';
import { AnnualPlannerRecordsLayoutDragPreview } from './AnnualPlannerRecordsLayoutDragPreview';

export interface AnnualPlannerRecordsLayoutCellGroupProps
  extends BoxProps,
    AnnualPlannerCalendarRecordCellProps {
  group: UseAnnualPlannerCellItem;
  draggable?: boolean;
}

export const AnnualPlannerRecordsLayoutCellGroup: FC<AnnualPlannerRecordsLayoutCellGroupProps> = ({
  group,
  draggable,
  ...props
}) => {
  const [, drag, preview] = useDrag(
    () => ({
      type: ANNUAL_PLANNER_DND_TYPE,
      item: group,
      canDrag: () => Boolean(draggable),
    }),
    [group],
  );

  return draggable ? (
    <>
      <AnnualPlannerRecordsLayoutDragPreview ref={preview} group={group} />
      <AnnualPlannerCalendarRecordGroup ref={drag} {...props} />
    </>
  ) : (
    <AnnualPlannerCalendarRecordGroup {...props} />
  );
};
