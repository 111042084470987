import { Avatar, AvatarProps } from '@mui/material';
import { WithAvatar, WithName } from '@schooly/api';
import { usePrivateImage } from '@schooly/hooks/use-private-image';
import { getUserInitials } from '@schooly/utils/user-helpers';
import React, { useMemo } from 'react';

export type AvatarAuthProps = {
  user: WithName & WithAvatar;
} & AvatarProps;

export const AvatarAuth: React.FC<AvatarAuthProps> = ({ user, ...avatarProps }) => {
  const { isLoading, response } = usePrivateImage(user.profile_picture);

  const displayedPicture = useMemo(() => {
    if (!isLoading && response !== undefined) {
      return response;
    }

    return user.profile_picture;
  }, [user, isLoading, response]);

  const initials = getUserInitials(user);

  return (
    <Avatar src={displayedPicture || undefined} {...avatarProps}>
      {initials}
    </Avatar>
  );
};
