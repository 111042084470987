import { Box } from '@mui/material';
import { FC } from 'react';

import { AnnualPlannerCreateExpanded } from './AnnualPlannerCreatePopover/AnnualPlannerCreateExpanded';
import { AnnualPlannerCreatePopover } from './AnnualPlannerCreatePopover/AnnualPlannerCreatePopover';
import { useAnnualPlannerCreateRecord } from './AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import { AnnualPlannerDropLayout } from './layouts/AnnualPlannerDropLayout';
import { AnnualPlannerMainGridLayout } from './layouts/AnnualPlannerMainGridLayout';
import {
  AnnualPlannerRecordsLayout,
  AnnualPlannerRecordsLayoutProps,
} from './layouts/AnnualPlannerRecordsLayout';

export interface AnnualPlannerCalendarProps extends AnnualPlannerRecordsLayoutProps {}

export const AnnualPlannerCalendar: FC<AnnualPlannerCalendarProps> = ({ start, end, records }) => {
  const { isOpen } = useAnnualPlannerCreateRecord();

  return (
    <Box className="AnnualPlannerCalendar-root" sx={{ position: 'relative', flex: '1 1 100%' }}>
      <AnnualPlannerMainGridLayout start={start} end={end} />
      <AnnualPlannerRecordsLayout start={start} end={end} records={records} />
      <AnnualPlannerDropLayout start={start} end={end} />

      {isOpen && (
        <>
          <AnnualPlannerCreatePopover />
          <AnnualPlannerCreateExpanded />
        </>
      )}
    </Box>
  );
};
