import { Stack } from '@mui/material';
import { FC } from 'react';

import { useSignUp } from '../../../context/signUps/WithSignUp';
import { EventPreviewCriteria } from '../../Events/EventPreviewModal/EventPreviewCriteria';
import { EventPreviewInvitees } from '../../Events/EventPreviewModal/EventPreviewInvitees';
import { SignUpPreviewDateAndTime } from './SignUpPreviewDateAndTime';
import { SignUpPreviewRespondents } from './SignUpPreviewRespondents/SignUpPreviewRespondents';

export const SignUpPreviewWithEvent: FC = () => {
  const { signUp } = useSignUp();

  if (!signUp?.event) {
    return null;
  }

  return (
    <Stack gap={2}>
      {signUp.event.invitee_type && (
        <EventPreviewInvitees invitee_type={signUp.event.invitee_type} />
      )}
      <EventPreviewCriteria criteria={signUp.event.criteria} />
      <SignUpPreviewRespondents />
      <SignUpPreviewDateAndTime />
    </Stack>
  );
};
