import {
  AgeGroup,
  AssessmentsGradeUpdate,
  AttendanceCode,
  ConductTypeCreate,
  CreateSchoolWithPropertiesResponse,
  DEFAULT_DATE_FORMAT_FNS,
  GroupSubject,
  SchoolLevel,
  SchoolProperty,
  SchoolYear,
  useChangeLastSchoolMutation,
  useCreateSchoolMutation,
  useCreateSchoolWithPropertiesMutation,
  useGetCategoriesQuery,
  UserRole,
  UserRolePermissions,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import {
  SchoolEducationSystem,
  SchoolKind,
  SchoolPresetsLanguage,
  SchoolPropertyType,
  SchoolType,
} from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { addYears, format, subDays } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-lts';

import { clearReactQueryStorageCache } from '../../../queryClient';
import { PermissionsToAssign } from '../../UserRoles/CreateUserRoleModal/Context/useUserRoleModal';
import { prepareStatusesToSave } from '../SchoolTune/tabs/statuses/hooks/useSchoolTuneStatuses';
import { SchoolTuneStatusType } from '../SchoolTune/tabs/statuses/scheme';
import {
  generateSchoolPresets,
  getInitialAgeGroups,
  getInitialAssessmentGrades,
  getInitialAttendanceCodes,
  getInitialConductTypes,
  getInitialSchoolLevels,
  getInitialSchoolYears,
} from './schoolPresets';

export enum SchoolCreateState {
  NameFromConfigure = 0,
  Name = 1,
  Type = 2,
  System = 3,
  Kind = 4,
  Configure = 5,
  Saving = 6,
}

export type SchoolCreateUserRole = Omit<
  UserRole,
  'id' | 'member_type' | 'member_count' | 'school_property_ids' | 'is_current' | 'editable'
> & {
  permission_to_assign: PermissionsToAssign;
  permissions: UserRolePermissions[];
};

export type FormSchoolYear = Omit<SchoolYear, 'id' | 'in_use_info' | 'period_groups'> & {
  id?: string;
};

export interface SchoolCreateForm {
  name: string;
  code: string;
  kind: SchoolKind;
  type: SchoolType;
  educationSystem: SchoolEducationSystem;
  schoolYears: FormSchoolYear[];
  ageGroups: Omit<AgeGroup, 'id'>[];
  schoolLevels: (Omit<SchoolLevel, 'id'> & { id_tmp?: string })[];
  departments: Omit<SchoolProperty, 'id'>[];
  studentStatuses: SchoolTuneStatusType;
  staffStatuses: SchoolTuneStatusType;
  subjects: Omit<GroupSubject, 'id'>[];
  userRoles: SchoolCreateUserRole[];
  conductTypes: ConductTypeCreate[];
  attendanceCodes: Omit<AttendanceCode, 'id'>[];
  assessmentGrades: AssessmentsGradeUpdate[];
}

const emptySchoolStatuses = {
  prospective: [],
  current: [],
  former: [],
  unsuccessful: [],
};

const defaultSchoolType = SchoolKind.Elementary;

const defaultValues: SchoolCreateForm = {
  name: '',
  code: '',
  type: SchoolType.Regular,
  kind: defaultSchoolType,
  educationSystem: SchoolEducationSystem.US,
  schoolYears: [],
  schoolLevels: [],
  ageGroups: [],
  departments: [],
  studentStatuses: emptySchoolStatuses,
  staffStatuses: emptySchoolStatuses,
  subjects: [],
  userRoles: [],
  conductTypes: [],
  assessmentGrades: [],
  attendanceCodes: [],
};

export const useSchoolCreate = () => {
  const { showError } = useNotifications();
  const [state, setState] = useState<SchoolCreateState>(SchoolCreateState.Name);
  const [configuredSchool, setConfiguredSchool] =
    useState<CreateSchoolWithPropertiesResponse | null>(null);
  const form = useForm<SchoolCreateForm>({
    defaultValues: {
      ...defaultValues,
      ...generateSchoolPresets(defaultSchoolType, SchoolPresetsLanguage.EN),
    },
  });
  const educationSystem = form.watch('educationSystem');
  const presetsLanguage =
    educationSystem === SchoolEducationSystem.BR
      ? SchoolPresetsLanguage.PT
      : SchoolPresetsLanguage.EN;

  const createSchoolWithPropertiesMutation = useCreateSchoolWithPropertiesMutation();
  const changeLastSchool = useChangeLastSchoolMutation();
  const createSchoolMutation = useCreateSchoolMutation();

  const { data } = useGetCategoriesQuery();

  const changeSchool = useCallback(
    (id: string, to: 'tune' | 'completed') => {
      changeLastSchool.mutate(id, {
        onError: showError,
        onSuccess: () => {
          clearReactQueryStorageCache();
          window.location.href = to === 'tune' ? '/settings/tune' : '/students/school_created';
        },
      });
    },
    [changeLastSchool, showError],
  );

  const handleSchoolCreated = useMemo(() => {
    if (
      !configuredSchool?.school.id ||
      createSchoolMutation.isLoading ||
      createSchoolMutation.isSuccess
    )
      return;

    return () => {
      setConfiguredSchool(null);
      changeSchool(configuredSchool.school.id, 'completed');
    };
  }, [
    changeSchool,
    configuredSchool?.school.id,
    createSchoolMutation.isSuccess,
    createSchoolMutation.isLoading,
  ]);

  const handleNext = async () => {
    const {
      type,
      kind,
      name,
      code,
      subjects,
      schoolLevels,
      ageGroups,
      departments,
      schoolYears,
      staffStatuses,
      studentStatuses,
      educationSystem,
      userRoles,
      assessmentGrades,
      attendanceCodes,
      conductTypes,
    } = form.getValues();

    switch (state) {
      case SchoolCreateState.Name:
      case SchoolCreateState.NameFromConfigure:
        const nameAndCodePresented = await form.trigger(['name', 'code']);
        if (!nameAndCodePresented) break;

        setState(
          state === SchoolCreateState.Name ? SchoolCreateState.Type : SchoolCreateState.Configure,
        );
        break;
      case SchoolCreateState.Type:
        switch (type) {
          case SchoolType.Other:
            createSchoolMutation.mutate(
              {
                name,
                code,
              },
              {
                onError: showError,
                onSuccess: ({ id }) => changeSchool(id, 'tune'),
              },
            );
            break;
          case SchoolType.Regular:
            form.setValue('kind', SchoolKind.Elementary);
            setState(SchoolCreateState.System);
            break;
          case SchoolType.Additional:
            form.setValue('kind', SchoolKind.Music);
            setState(SchoolCreateState.Kind);
            break;
        }

        break;
      case SchoolCreateState.System:
        setState(SchoolCreateState.Kind);
        break;
      case SchoolCreateState.Kind: {
        const { staffStatuses, studentStatuses, subjects, userRoles, departments } =
          generateSchoolPresets(kind, presetsLanguage);

        form.setValue('schoolLevels', getInitialSchoolLevels(kind, educationSystem));
        form.setValue('ageGroups', getInitialAgeGroups(kind, educationSystem));
        form.setValue('schoolYears', getInitialSchoolYears(kind, educationSystem));
        form.setValue(
          'assessmentGrades',
          getInitialAssessmentGrades(kind, educationSystem, presetsLanguage),
        );
        form.setValue(
          'conductTypes',
          getInitialConductTypes(kind, educationSystem, presetsLanguage),
        );
        form.setValue(
          'attendanceCodes',
          getInitialAttendanceCodes(kind, educationSystem, presetsLanguage),
        );
        form.setValue('staffStatuses', staffStatuses);
        form.setValue('studentStatuses', studentStatuses);
        form.setValue('departments', departments);
        form.setValue('subjects', subjects);
        form.setValue('userRoles', userRoles);

        setState(SchoolCreateState.Configure);
        break;
      }
      case SchoolCreateState.Configure: {
        if (!data?.categories) break;

        const statuses = prepareStatusesToSave(
          data.categories,
          {
            staff: staffStatuses,
            students: studentStatuses,
          },
          (categoryId, index) => (status, i) => ({
            name: status.name ?? '',
            category_id: categoryId,
            order: index + i,
            type: SchoolPropertyType.Status,
          }),
        );

        setState(SchoolCreateState.Saving);
        createSchoolWithPropertiesMutation.mutate(
          {
            school: { name, code, type, education_system: educationSystem },
            school_years: schoolYears.map((year) => {
              const end =
                year.end ||
                format(
                  subDays(addYears(newDateTimezoneOffset(year.start), 1), 1),
                  DEFAULT_DATE_FORMAT_FNS,
                );

              return {
                ...year,
                end,
              };
            }),
            subjects,
            attendances: attendanceCodes.map((a, i) => ({ ...a, id: undefined, order: i })),
            conducts: conductTypes.map((a, i) => ({ ...a, id: undefined, order: i })),
            grades: assessmentGrades.map((a, i) => ({
              ...a,
              id: undefined,
              options: a.options.map((o, i) => ({ ...o, order: i })),
            })),
            properties: [...statuses, ...departments],
            age_groups: ageGroups.map((g) => ({ ...g, level_id: g.level_id ?? undefined })),
            school_levels: schoolLevels,
            accesses: userRoles.map((r) => ({
              ...r,
              permission_to_assign: r.permission_to_assign.map((p) => p.value),
            })),
          },
          {
            onError: (e) => {
              setState(SchoolCreateState.Configure);
              showError(e);
            },
            onSuccess: setConfiguredSchool,
          },
        );
        break;
      }
      default:
        break;
    }
  };

  return {
    state,
    form,
    saving:
      createSchoolMutation.isLoading ||
      createSchoolWithPropertiesMutation.isLoading ||
      changeLastSchool.isLoading,
    handleSchoolCreated,
    setState,
    handleNext,
  };
};
