import {
  ApiError,
  DEFAULT_DATE_FORMAT_FNS,
  listGroups,
  MAX_PAGE_SIZE,
  useCreateAttendanceRegisterMutation,
  useDeleteAttendanceRegisterMutation,
  useEditAttendanceRegisterMutation,
  useGetAttendanceRegisterQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { ModalConfirmationDialogProps } from '../../../components/uikit-components/Modal/ModalConfirmationDialog';
import {
  RouterStateContext,
  RouterStateContextProps,
} from '../../../context/router/RouterStateContext';
import { useRouter } from '../../../context/router/useRouter';
import useAppLocation from '../../../hooks/useAppLocation';
import { FormState, useForm } from '../../../hooks/useForm';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import { formatDateDefault } from '../../../utils/formatDate';

export enum AttendanceRegisterModalMode {
  Initial,
  Groups,
}

export interface AttendanceRegisterCreateModalState
  extends FormState<AttendanceRegisterCreateModalState> {
  name: string;
  date: string;
  selectedGroupIds: string[];
  focusedField?: keyof AttendanceRegisterCreateModalState;
  submitError?: ApiError;
  mode: AttendanceRegisterModalMode;
  confirmationDialogProps: ModalConfirmationDialogProps;
}

const CONTEXT_NAME = 'AttendanceRegisterCreateModal';

export const useAttendanceRegisterCreateModal = () => {
  const {
    state,
    contextName,
    setState,
    setContextName,
    setContextState,
    onInputChangeHandler,
    setStateHandler,
  } = useContext(RouterStateContext) as RouterStateContextProps<AttendanceRegisterCreateModalState>;
  const { $t } = useIntl();
  const { getConfirmation } = useConfirmationDialog();
  const { schoolId = '' } = useAuth();
  const { closeAndClean, goBack } = useRouter();
  const { showNotification, showError } = useNotifications();
  const location = useAppLocation();
  const navigate = useNavigate();
  const { id: registerId } = useParams();
  const invalidateQueries = useInvalidateListQueriesFor('attendance');

  const { data: register, isLoading } = useGetAttendanceRegisterQuery(registerId || '', {
    enabled: !!registerId,
    refetchOnMount: 'always',
  });

  const hasState = !!state;

  useEffect(() => {
    if (!register || !hasState) return;

    setContextState({
      name: register.name,
      date: register.register_date,
      selectedGroupIds: register.group_details.map((g) => g.id),
    });
  }, [register, hasState, setContextState]);

  const createAttendanceRegister = useCreateAttendanceRegisterMutation();
  const editAttendanceRegister = useEditAttendanceRegisterMutation();
  const deleteAttendanceRegister = useDeleteAttendanceRegisterMutation();

  const form = useForm({
    core: true,
    state,
    setState,
    rules: {
      name: {
        required: true,
      },
      date: {
        required: true,

        async: (state) => {
          if (state?.submitError?.reason?.startsWith('There is already a register for')) {
            return { id: 'attendance-DateExistsError' };
          }
        },
      },
      selectedGroupIds: {
        custom: (state) => {
          if (!state?.selectedGroupIds?.length) {
            return { id: 'attendance-GroupsError' };
          }
        },
      },
    },
  });

  useEffect(() => {
    if (!state) {
      // TODO: Get rid of state initialization race.
      //  RouterState reconcile mechanism might produce a race between the reconciled state
      //  and general initialization. Currently it's managed by placing general initialization
      //  into the end of the EventLoop, but would be batter to have a more proper way.

      setContextName(CONTEXT_NAME);
      setTimeout(() =>
        setContextState({
          name: $t({ id: 'attendance-Daily' }),
          mode: AttendanceRegisterModalMode.Initial,
          ...(!registerId && { focusedField: 'date' }),
        }),
      );
    }
  }, [contextName, $t, registerId, setContextName, setContextState, state]);

  const addGroupIds = useCallback(
    (groupIds: string[]) => {
      const resultGroupIds = [
        ...(state?.selectedGroupIds || []).filter((id) => !groupIds.includes(id)),
        ...groupIds,
      ];

      form.set('selectedGroupIds', resultGroupIds);
    },
    [form, state?.selectedGroupIds],
  );

  const removeGroupId = useCallback(
    (groupId: string) => {
      form.set(
        'selectedGroupIds',
        (state?.selectedGroupIds || []).filter((id) => id !== groupId),
      );
    },
    [form, state?.selectedGroupIds],
  );

  const onFieldEditClickHandler = useCallback(
    (key?: keyof AttendanceRegisterCreateModalState) => {
      return () =>
        setContextState({ mode: AttendanceRegisterModalMode.Initial, focusedField: key });
    },
    [setContextState],
  );

  const closeModal = useCallback(
    async (force = false) => {
      if (!force && state?.formTouched) {
        const isConfimed = await getConfirmation({ textId: 'attendance-CloseModalWarning' });

        isConfimed && goBack();
      } else {
        goBack();
      }
    },
    [state?.formTouched, getConfirmation, goBack],
  );

  const setName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.set('name', e.target.value);
    },
    [form],
  );

  const [loadGroups, groupsLoadingOnDateChange] = useRequestWithProgress(listGroups);

  const onDateChange = useCallback(
    async (date: Date | null) => {
      const prevDate = state?.date;

      const singleDate = date ? format(date, DEFAULT_DATE_FORMAT_FNS) : '';

      setContextState({ submitError: undefined, focusedField: undefined });
      form.set('date', singleDate);

      if (!state?.selectedGroupIds?.length) {
        return;
      }

      const { results: groupsToDate } = (await loadGroups({
        schoolId,
        pageSize: MAX_PAGE_SIZE,
        singleDate,
      }))!;

      if (!groupsToDate.length) {
        return;
      }

      const groupIdsForDate = groupsToDate.map((g) => g.id);

      const allEditedGroupsAreAvailable = state.selectedGroupIds?.every((id) =>
        groupIdsForDate.includes(id),
      );

      if (!allEditedGroupsAreAvailable) {
        if (await getConfirmation({ textId: 'attendance-ChangingDateWarning' })) {
          const allowedGroups = state.selectedGroupIds?.filter((id) =>
            groupIdsForDate.includes(id),
          );

          setContextState({ selectedGroupIds: allowedGroups });
          return;
        }
        setContextState({ date: prevDate });
      }
    },
    [
      state?.date,
      state?.selectedGroupIds,
      setContextState,
      form,
      loadGroups,
      schoolId,
      getConfirmation,
    ],
  );

  const createRegister = async () => {
    if (!schoolId || !state?.date) {
      return;
    }

    setContextState({ submitError: undefined });

    createAttendanceRegister.mutate(
      {
        schoolId,
        register: {
          name: state.name,
          register_date: formatDateDefault(state.date)!,
          group_ids: state.selectedGroupIds,
        },
      },
      {
        onSuccess: (r) => {
          closeModal(true);

          showNotification({
            textId: 'attendance-RegisterCreated',
            type: 'success',
            actions: [
              {
                textId: 'attendance-ViewRegister',
                handler: () =>
                  navigate(`/attendance/${r.id}`, {
                    state: location.state,
                  }),
                buttonColor: 'light',
              },
            ],
          });
          invalidateQueries();
        },
        onError: (e) => {
          showError(e);
          setContextState({
            submitError: e,
            mode: AttendanceRegisterModalMode.Initial,
            focusedField: undefined,
          });
        },
      },
    );
  };

  // const requestRegister = async () => {
  //   if (!registerId) {
  //     return;
  //   }

  //   const register = await getAttendanceRegister(registerId);

  //   setContextState({
  //     name: register.name,
  //     date: register.register_date,
  //     selectedGroups: registerGroups as GroupBase[],
  //   });
  // };

  const processPreSaveConfirmation = async (
    preSaveConfirmation: { entries_to_delete?: number; success: string },
    onConfirm: VoidFunction,
    messageTextId: string,
  ) => {
    if (preSaveConfirmation.entries_to_delete) {
      if (
        !(await getConfirmation({
          textId: messageTextId,
          textValues: { entriesCount: preSaveConfirmation.entries_to_delete },
        }))
      )
        return;

      onConfirm();
      invalidateQueries();
    } else {
      closeAndClean();

      showNotification({
        textId: messageTextId,
        type: 'success',
      });

      invalidateQueries();
    }
  };

  const editRegister = async (confirmed = false) => {
    if (!registerId) {
      return;
    }

    if (!registerId) {
      return;
    }

    editAttendanceRegister.mutate(
      {
        id: registerId,
        register: {
          name: state.name,
          register_date: formatDateDefault(state.date)!,
          group_ids: state.selectedGroupIds,
        },
        confirmed,
      },
      {
        onSuccess: (res) =>
          processPreSaveConfirmation(
            res,
            () => editRegister(true),
            res.entries_to_delete
              ? 'attendance-RegisterUpdateConfirmation'
              : 'attendance-RegisterUpdated',
          ),
        onError: showError,
      },
    );
  };

  const deleteRegister = async (confirmed = false) => {
    if (!registerId) {
      return;
    }

    deleteAttendanceRegister.mutate(
      {
        id: registerId,
        confirmed,
      },
      {
        onSuccess: (res) =>
          processPreSaveConfirmation(
            res,
            () => deleteRegister(true),
            res.entries_to_delete
              ? 'attendance-RegisterDeleteConfirmation'
              : 'attendance-RegisterDeleted',
          ),
        onError: showError,
      },
    );
  };

  const clearFocusField = useCallback(() => {
    setContextState({ focusedField: undefined });
  }, [setContextState]);

  return {
    ...state,
    form,
    saving: editAttendanceRegister.isLoading || createAttendanceRegister.isLoading,
    deleting: deleteAttendanceRegister.isLoading,
    groupsLoadingOnDateChange,
    loading: !state || !!(registerId && isLoading),
    registerId,
    actions: {
      onInputChangeHandler,
      onDateChange,
      addGroupIds,
      removeGroupId,
      setStateHandler,
      onFieldEditClickHandler,
      submitRegister: registerId ? editRegister : createRegister,
      deleteRegister,
      closeModal,
      setName,
      clearFocusField,
    },
  };
};
