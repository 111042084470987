import { Icon, IconButton, Link, Stack, Typography } from '@mui/material';
import { Attention2Icon, NewTabIcon } from '@schooly/style';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { RegistrationConflictRow } from './RegistrationConflictRow';
import { ActiveRegistrations } from './StudentRegistrationForm';

interface AddRegistrationModalProps extends PropsWithChildren {
  getRegistrationLink: (id: string) => string;
  onRegistrationClick: () => void;
  selectedAgeGroupId?: string;
  selectedHouseId?: string;
  registrationConflicts: ActiveRegistrations[];
  endIcon: ReactNode;
}

export const RegistrationConflicts: FC<AddRegistrationModalProps> = ({
  getRegistrationLink,
  onRegistrationClick,
  selectedAgeGroupId,
  selectedHouseId,
  children,
  endIcon,
  registrationConflicts,
}) => {
  return (
    <Stack
      sx={(theme) => ({
        borderRadius: '8px',
        backgroundColor: theme.palette.error.superLight,
        border: `1px solid ${theme.palette.error.main}`,
        gap: 1,
        p: 2,
        mt: 1,
      })}
    >
      <Stack direction="row" gap={1.75} alignItems="center">
        <Icon
          sx={{
            color: 'error.main',
            '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
          }}
        >
          <Attention2Icon />
        </Icon>
        <Typography
          variant="h3"
          pr={4}
          sx={{
            color: 'error.main',
          }}
        >
          <FormattedMessage id="students-RegistrationConflicts" />
        </Typography>
        {endIcon}
      </Stack>
      {registrationConflicts.map((c) => {
        const isStatusCurrent = !!c.school_property.category?.current;

        const isAgeGroupInvalid = selectedAgeGroupId
          ? selectedAgeGroupId === c.ageGroup?.id
          : false;
        const isHouseInvalid = selectedHouseId ? selectedHouseId === c.house?.id : false;
        return (
          <RegistrationConflictRow
            key={c.id}
            registration={c}
            isStatusInvalid={isStatusCurrent}
            isYearInvalid={!isStatusCurrent}
            isAgeGroupInvalid={!isStatusCurrent && isAgeGroupInvalid}
            isHouseInvalid={!isStatusCurrent && isHouseInvalid}
            endIcon={
              <Link
                href={getRegistrationLink(c.registrationId)}
                target="_blank"
                onClick={onRegistrationClick}
              >
                <IconButton size="small" inverse>
                  <NewTabIcon />
                </IconButton>
              </Link>
            }
          />
        );
      })}
      {children}
    </Stack>
  );
};
