import { CancelToken } from 'axios';

import { AssessmentForReport, AssessmentMethod } from './assessments';
import { IColumnSort } from './endpoints/people';
import { BaseUserSchoolRelation } from './relations';
import { SchoolProperty } from './schools';
import { UserFilter } from './users';

export interface ReportBase {
  id: string;
  name: string;
}

export interface Report extends ReportBase {
  report_status?: ReportStatuses;
  scheduled_publish_date?: string;
  actual_publish_date?: string;
  assessments?: AssessmentForReport[];
}

export interface ReportForSchool extends ReportBase {}

export type AreaOfLearning = {
  id?: string;
  assessment_id?: string;
  name: string;
  methods: AssessmentMethod[];
};
export interface ReportForAssessment extends Report {
  report_status: ReportStatuses;
  areas_of_learning: AreaOfLearning[];
  age_groups: Pick<SchoolProperty, 'name' | 'id'>[];
  subjects: Pick<SchoolProperty, 'name' | 'id'>[];
  with_tutor_feedback: boolean;
}

export interface ReportUpdate {
  name: string;
  scheduled_publish_date: string;
  assessment_ids: string[];
}

export enum ReportStatuses {
  Published,
  Unpublished,
  Draft,
}

export interface ListReportsForSchool {
  filters?: Partial<UserFilter>;
  page_number?: number;
  page_size?: number;
  school_id: string;
  sort?: IColumnSort<keyof Report | 'report_date'>[];
  query?: string;
}

export interface ListReportsForRelation {
  relationId: string;
  dateFrom?: string;
  dateTo?: string;
  query?: string;
  sort?: IColumnSort<keyof Report | 'report_date'>[];
  token?: CancelToken;
}

export interface ListReportRecipients {
  report_id: string;
  filters?: UserFilter;
  pageNumber?: number;
  pageSize?: number;
  sort?: IColumnSort<keyof BaseUserSchoolRelation>[];
  query?: string;
  token?: CancelToken;
}

export enum ReportAction {
  Publish = 'publish',
  Unpublish = 'unpublish',
}
