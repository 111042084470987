import { Icon, Skeleton, Stack } from '@mui/material';
import {
  EVENTS_FILTER_KEYS,
  FilterSection,
  GetEventsQueryFilters,
  useGetFiltersQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useChartOpenFromSearchParams } from '@schooly/components/charts';
import { eventFilterAdapter, pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SkeletonRows,
} from '@schooly/style';
import { FC } from 'react';
import React, { useMemo } from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { EventsAndSignUpsPageHeader } from '../EventsAndSignUpsPageHeader/EventsAndSignUpsPageHeader';
import { PAGE_SIZE, SKELETON_ROWS } from '../Messages/MessagesContent';
import { EventsContent } from './EventsContent';

interface EventsPageProps {}

export const EventsPage: FC<EventsPageProps> = () => {
  const { schoolId = '', relationId = '' } = useAuth();

  const { data: filters, isLoading } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Events },
    { enabled: !!schoolId && !!relationId },
  );

  const isChartsOpen = useChartOpenFromSearchParams();

  const initialFilters = useMemo(() => {
    const initialFilters: GetEventsQueryFilters = {};

    if (isLoading) return;

    const defaultFilter = filters?.find((f) => f.is_default);
    if (defaultFilter) {
      return {
        initialFilters: pickOnlyParamsFromFilterKeys(
          EVENTS_FILTER_KEYS,
          eventFilterAdapter(defaultFilter.filter).normalized,
        ),
      };
    }

    return { initialFilters };
  }, [filters, isLoading]);

  const renderContent = () => {
    if (!initialFilters || !schoolId) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <EventsAndSignUpsPageHeader value="events" loading />
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_ROWS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_ROWS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    return <EventsContent isChartsOpen={isChartsOpen} {...initialFilters} />;
  };
  return <MainLayout>{renderContent()}</MainLayout>;
};
