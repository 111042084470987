import { MenuItem } from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';
import { SignUpType } from '@schooly/api';
import { ControlSelect } from '@schooly/components/form-select';
import { getMinutesDurationLabel } from '@schooly/utils/date';
import { isAfter, isBefore, parse } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SignUpForm, useSignUp } from '../../../context/signUps/WithSignUp';

const step = 15; // minutes

export interface SignUpDurationProps {
  onClickNoOptions?: () => void;
}

export const SignUpDuration: FC<SignUpDurationProps> = ({ onClickNoOptions }) => {
  const { $t } = useIntl();
  const { signUp } = useSignUp();
  const { watch, setValue } = useFormContext<SignUpForm>();
  const [open, setOpen] = useState(false);

  const type = watch('type');
  const dateTimes = watch('event.date_times');
  const duration = watch('duration');

  const options = useMemo(() => {
    let count = 0;

    if (dateTimes?.length) {
      let minSlot = 0;

      dateTimes?.forEach((slot) => {
        if (!slot[0] || !slot[1]) {
          return;
        }

        const times = slot.map((item) => parse(item as string, 'HH:mm', new Date()));
        const start = isAfter(times[0], times[1]) ? times[1] : times[0];
        const end = isBefore(times[1], times[0]) ? times[0] : times[1];

        const duration = end.getTime() - start.getTime();

        if (!minSlot || duration < minSlot) {
          minSlot = duration;
        }
      });

      count = Math.floor(minSlot / (step * 1000 * 60));
    }

    return new Array(count)
      .fill(1)
      .map((value, index) => (index + 1) * step)
      .map((minutes) => ({
        value: minutes,
        label: getMinutesDurationLabel(minutes),
      }));
  }, [dateTimes]);

  useEffect(() => {
    if (type !== SignUpType.Slots) {
      setValue('duration', undefined);
    }
  }, [setValue, type]);

  useEffect(() => {
    if (!options.some((option) => option.value === duration)) {
      setValue('duration', undefined);
    }
  }, [duration, options, setValue]);

  const handleOpen = useCallback<Exclude<SelectProps['onOpen'], undefined>>(() => {
    if (options.length) {
      setOpen(true);
    } else {
      onClickNoOptions?.();
    }
  }, [onClickNoOptions, options.length]);

  const handleClose = useCallback<Exclude<SelectProps['onClose'], undefined>>(() => {
    setOpen(false);
  }, []);

  if (type !== SignUpType.Slots) {
    return null;
  }

  return (
    <ControlSelect
      name="duration"
      label={$t({ id: 'eventSignUps-create-Duration' })}
      renderValue={(minutes) => getMinutesDurationLabel(minutes as number)}
      rules={{ required: true }}
      locked={Boolean(signUp?.signed_up_count)}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      disabled={!options.length && !onClickNoOptions}
      noRequiredLabel
      fullWidth
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </ControlSelect>
  );
};
