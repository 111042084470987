import { Box, IconButton, Stack, StackProps, TableRow, Tooltip, Typography } from '@mui/material';
import {
  Event,
  EventsStatuses,
  FilterKeys,
  GetEventsQueryFilters,
  GetEventsQuerySort,
  getTypedObjectKeys,
  SchoolProperty,
  useGetSchoolPropertiesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  CheckOverflow,
  getEventsStatusLabel,
  getParticipantLabel,
} from '@schooly/components/filters';
import { RecurrenceInfo } from '@schooly/components/recurring';
import { RenderSchoolProperty } from '@schooly/components/render-school-property';
import { DATE_FORMAT_SHORT, SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import {
  CalendarSettingsIcon,
  CheckboxSquareIconButton,
  GridBody,
  GridCell,
  GridHead,
  HolidayIcon,
  SignUpIcon,
  TagSelect,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { isDateInPast, newDateTimezoneOffset } from '@schooly/utils/date';
import { isNotEmpty } from '@schooly/utils/predicates';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import {
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';

type EventsHeaderProps = {
  sort?: GetEventsQuerySort;
  onChangeSort?: (v: GetEventsQuerySort) => void;
  isSelectedAll?: boolean;
  onSelectAll?: () => void;
  rightIcon?: ReactNode;
};

export const EventsHeader: FC<EventsHeaderProps> = ({
  onChangeSort,
  sort,
  rightIcon,
  isSelectedAll,
  onSelectAll,
}) => {
  const { $t } = useIntl();

  return (
    <GridHead borderBottom>
      <GridCell width="50px" center>
        <CheckboxSquareIconButton
          isActive={isSelectedAll}
          disabled={!onSelectAll}
          onClick={onSelectAll}
        />
      </GridCell>
      <GridCell
        width="150px"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 50,
          },
        })}
      >
        {$t({ id: 'events-grid-date' })}
      </GridCell>
      <GridCell
        width="235px"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 200,
          },
        })}
      >
        {$t({ id: 'events-grid-title' })}
      </GridCell>

      <GridCell
        width="50%"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: '155px',
          },
        })}
      >
        {$t({ id: 'events-grid-invitees' })}
      </GridCell>
      <GridCell
        width="140px"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 95,
          },
        })}
      >
        {$t({ id: 'events-grid-participation' })}
      </GridCell>
      <GridCell
        width="100px"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 70,
          },
        })}
      >
        {$t({ id: 'events-LinkedSignUp' })}
      </GridCell>
      <GridCell
        width="140px"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 60,
          },
        })}
      >
        {$t({ id: 'events-status' })}
      </GridCell>
      <GridCell pr={1} right width="40px">
        {rightIcon}
      </GridCell>
    </GridHead>
  );
};

type EventRowProps = {
  event: Event;
  isSelected?: boolean;
  onSelect?: (v: string) => void;
  onAddFilters: (v: GetEventsQueryFilters) => void;
};

export const getEventDate = ({ start, end }: { start: string; end: string }) => {
  const firstDate = newDateTimezoneOffset(start);
  const lastDate = newDateTimezoneOffset(end);

  if (firstDate.getTime() === lastDate.getTime()) {
    return format(firstDate, DATE_FORMAT_SHORT).toUpperCase();
  }

  return `${format(firstDate, DATE_FORMAT_SHORT).toUpperCase()} - ${format(
    lastDate,
    DATE_FORMAT_SHORT,
  ).toUpperCase()}`;
};

export const EventRow: FC<EventRowProps> = ({ event, isSelected, onSelect, onAddFilters }) => {
  const location = useAppLocation();
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { $t } = useIntl();

  const date = useMemo(() => getEventDate(event), [event]);
  const isForPublish =
    event.event_status === EventsStatuses.Draft &&
    !isDateInPast(event.start, event.date_times[0]?.[0] ?? null);
  const recurrenceId = event.recurring_state?.recurrence_id;
  const recurringState = event.recurring_state;

  const handleSelect: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      onSelect?.(event.id);

      const node = ref.current;
      if (!node) return;

      const scrollIfRowHidden = () => {
        const { y, height } = node.getBoundingClientRect();
        const yOffset = window.innerHeight - y;
        const isHiddenByBulkActionBar = !isSelected && yOffset <= height * 1.5;
        isHiddenByBulkActionBar && window.scrollBy({ behavior: 'smooth', top: height });
      };

      setTimeout(scrollIfRowHidden, 300);
    },
    [event.id, onSelect, isSelected],
  );

  const selectEventStyles = useMemo(
    () =>
      isForPublish
        ? {
            '& .EventsGridRow-event': {
              '.date, .checkbox': {
                transition: 'opacity .2s',
              },
              '.checkbox': {
                position: 'absolute',
                paddingLeft: 0.9,
              },
            },

            ...(isSelected
              ? {
                  '& .EventsGridRow-event .date': {
                    opacity: 0,
                  },
                }
              : {
                  '&:not(:hover) .EventsGridRow-event .checkbox': {
                    opacity: 0,
                  },
                  '&:hover .EventsGridRow-event': {
                    '.date': {
                      opacity: 0,
                    },
                    '.checkbox': {
                      opacity: 1,
                    },
                  },
                }),
          }
        : {},
    [isForPublish, isSelected],
  );

  return (
    <GridBody
      sx={(theme) => ({
        '&.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
          borderBottom: theme.mixins.borderValue(),
        },

        ...selectEventStyles,

        '&:hover': {
          '& .EventsGridRow-event td': {
            '.MuiTypography-root:not(.status, .date)': {
              color: theme.palette.primary.main,
            },

            '.date': {
              color: theme.palette.common.grey2,
            },

            '.signup:not(:hover)': {
              color: theme.palette.text.primary,
            },

            '.status': {
              color:
                event.event_status === EventsStatuses.Past ||
                event.event_status === EventsStatuses.Draft
                  ? theme.palette.primary.main
                  : undefined,
            },
            backgroundColor: theme.palette.background.default,
          },
        },
      })}
    >
      <TableRow
        ref={ref}
        component={Link}
        to={`/events/${event.id}`}
        state={{ backgroundLocation: location }}
        className="EventsGridRow-event"
      >
        <GridCell pl={1}>
          <Stack whiteSpace="nowrap">
            <Typography className="date" color="common.grey">
              {date}
            </Typography>

            {isForPublish && (
              <CheckboxSquareIconButton
                onClick={handleSelect}
                isActive={isSelected}
                className="checkbox"
              />
            )}
          </Stack>
        </GridCell>
        <GridCell />
        <GridCell>
          <Stack direction="row" gap={0.5}>
            <TypographyWithOverflowHint variant="h3" color="common.grey2" noWrap>
              {event.title}
            </TypographyWithOverflowHint>
            {!!recurringState && recurrenceId && (
              <RecurrenceInfo
                recurringState={recurringState}
                onClick={() =>
                  onAddFilters({
                    [FilterKeys.RecurrenceId]: [recurrenceId],
                  })
                }
              />
            )}
            {event.event_type === 'period' && (
              <Tooltip
                title={$t({ id: 'events-SystemEventDescription' })}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      padding: theme.spacing(1.25),
                    }),
                  },
                }}
                disableInteractive
              >
                <IconButton inverse>
                  <CalendarSettingsIcon />
                </IconButton>
              </Tooltip>
            )}

            {event.event_type === 'holiday' && (
              <Tooltip
                title={$t({ id: 'event-holiday-hint' })}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      padding: theme.spacing(1.25),
                    }),
                  },
                }}
                disableInteractive
              >
                <IconButton inverse>
                  <HolidayIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </GridCell>
        <GridCell>
          <InviteesCellContent criteria={event.criteria} />
        </GridCell>
        <GridCell>
          <ParticipationCellContent event={event} />
        </GridCell>
        <GridCell>
          {event.sign_ups?.[0] && (
            <Link to={`/signups/${event.sign_ups[0].id}`}>
              <Tooltip title={event.sign_ups[0].title}>
                <IconButton className="signup" inverse>
                  <SignUpIcon />
                </IconButton>
              </Tooltip>
            </Link>
          )}
        </GridCell>
        <GridCell>
          <StatusCellContent event={event} />
        </GridCell>
        <GridCell />
      </TableRow>
    </GridBody>
  );
};

type EventsCellContentProps = {
  event: Event;
};

export const StatusCellContent: React.FC<EventsCellContentProps> = ({ event }) => {
  const { $t } = useIntl();

  const color = useMemo(() => {
    if (event.event_status === EventsStatuses.Canceled) {
      return 'error.main';
    } else if (event.event_status === EventsStatuses.Published) {
      return 'success.main';
    }

    return 'common.grey2';
  }, [event.event_status]);

  return (
    <Typography className="status" color={color}>
      {$t({ id: getEventsStatusLabel(event.event_status) })}
    </Typography>
  );
};

type CellContentProps = {
  event: Event;
};

export const ParticipationCellContent: React.FC<CellContentProps> = ({ event }) => {
  const { $t } = useIntl();

  return !!event.invitee_type ? (
    <Typography color="common.grey2">
      {$t({ id: getParticipantLabel(event.invitee_type) })}
    </Typography>
  ) : null;
};

interface UseEventTagsProps {
  schoolId?: string;
  criteria?: Event['criteria'];
  tagRenderer: (v: {
    name: string;
    id: string;
    property?: Pick<SchoolProperty, 'archived' | 'name'>;
    tooltipTitle?: string;
  }) => ReactNode;
  unionDivider?: ReactNode;
  divider?: ReactNode;
  showComma?: boolean;
}

export function useEventTags({
  schoolId = '',
  criteria,
  tagRenderer,
  divider,
  unionDivider,
  showComma,
}: UseEventTagsProps) {
  const { formatMessage } = useIntl();
  const { data } = useGetSchoolPropertiesQuery(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { enabled: Boolean(schoolId), refetchOnMount: 'always' },
  );

  return useMemo(() => {
    if (!criteria) {
      return [];
    }

    return getTypedObjectKeys(criteria)
      .map((k) => {
        if (k === 'groups') {
          return (
            criteria['groups']?.flatMap(({ name, id }) => ({
              name,
              id,
            })) ?? []
          );
        }
        return (
          criteria[k]?.map((cId) => {
            let val;
            let tooltipTitle;

            if (k === 'age_group') {
              val = data?.age_groups.find(({ id }) => id === cId);
              tooltipTitle = formatMessage({
                id: `schoolProperty-Archived-${SchoolPropertyType.AgeGroup}`,
              });
            } else {
              const t = k === 'student_status' ? SchoolPropertyType.Status : k;
              val = data?.school_properties.find(({ type, id }) => type === t && id === cId);
              tooltipTitle = formatMessage({
                id: `schoolProperty-Archived-${val?.type}`,
              });
            }

            return val
              ? {
                  id: cId,
                  name: val.name,
                  property: val,
                  tooltipTitle,
                }
              : null;
          }, []) ?? []
        );
      })
      .flatMap((a, i, arr) => {
        const canShowComma = showComma && !!a.length && arr.slice(i + 1).some((d) => !!d.length);

        return [
          ...a.map(
            (n, i) =>
              n && (
                <Stack direction="row" alignItems="center">
                  {i !== 0 && unionDivider}
                  {tagRenderer(n)}
                </Stack>
              ),
          ),
          canShowComma &&
            (divider ? (
              divider
            ) : (
              <Box
                sx={{
                  padding: (theme) => theme.spacing(0, 1),
                }}
              >
                {' ,'}
              </Box>
            )),
        ];
      })
      .filter(isNotEmpty);
  }, [
    criteria,
    data?.age_groups,
    data?.school_properties,
    divider,
    formatMessage,
    showComma,
    tagRenderer,
    unionDivider,
  ]);
}

export type InviteesCellContentProps = {
  criteria: Event['criteria'];
} & StackProps;

export const InviteesCellContent: FC<InviteesCellContentProps> = ({ criteria, ...props }) => {
  const { schoolId = '' } = useAuth();
  const [hiddenTags, setHiddenTags] = useState<Set<number>>(new Set());
  const [showTooltip, setShowTooltip] = useState(false);
  const { ref } = useResizeDetector<HTMLDivElement>();

  const [maxWidth, setMaxWidth] = useState<number>();

  const setTagHidden = useCallback((index: number, isHidden: boolean) => {
    setHiddenTags((tags) => {
      isHidden ? tags.add(index) : tags.delete(index);
      return new Set(tags);
    });
  }, []);

  useEffect(() => {
    const calculateMaxWidth = debounce(() => {
      setMaxWidth(ref.current?.offsetWidth ? ref.current?.offsetWidth - 30 : undefined);
    }, 400);

    window.addEventListener('resize', calculateMaxWidth);

    return () => window.removeEventListener('resize', calculateMaxWidth);
  }, [ref]);

  const tags = useEventTags({
    schoolId,
    criteria,
    tagRenderer: (n) => (
      <TagSelect
        sx={(theme) => ({
          ...theme.typography.body1,
          maxWidth,
        })}
        label={
          n.property && n.tooltipTitle ? (
            <RenderSchoolProperty property={n.property} tooltipTitle={n.tooltipTitle} />
          ) : (
            n.name
          )
        }
        key={n.id}
      />
    ),
  });

  const tagNodes = useMemo(
    () =>
      tags.map((tagNode, i) =>
        i === 0 ? (
          tagNode
        ) : (
          <CheckOverflow
            maxWidth={maxWidth ?? undefined}
            index={i}
            onHideTag={setTagHidden}
            key={`_${i}`}
          >
            {tagNode}
          </CheckOverflow>
        ),
      ),
    [tags, maxWidth, setTagHidden],
  );

  return (
    <Tooltip
      arrow
      open={showTooltip}
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            maxWidth: '100%',
            borderRadius: theme.spacing(1),
            padding: 0,
            overflow: 'hidden',
            minWidth: 0,
          }),
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-4, -40],
            },
          },
        ],
      }}
      placement="bottom-start"
      title={
        <Stack
          direction="column"
          sx={(theme) => ({
            position: 'relative',
            padding: theme.spacing(0.5),
            flexWrap: 'wrap',
            gap: 0.5,
          })}
        >
          {tags}
        </Stack>
      }
    >
      <Stack ref={ref} flexDirection="row" alignItems="center" {...props}>
        {tagNodes}
        {!!hiddenTags.size && (
          <TagSelect
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            sx={(theme) => ({ ...theme.typography.body1, backgroundColor: 'white', ml: 0.5 })}
            label={`+${hiddenTags.size}`}
          />
        )}
      </Stack>
    </Tooltip>
  );
};
