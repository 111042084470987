import { ChipProps } from '@mui/material';
import { InviteStatus } from '@schooly/constants';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export type InviteStatusTagSelectProps = {
  status: InviteStatus;
} & Omit<ChipProps, 'label' | 'role'>;

export const InviteStatusTagSelect: FC<InviteStatusTagSelectProps> = ({ status, ...props }) => {
  return (
    <TagSelect label={<FormattedMessage id={`parents-InviteStatus-${status}`} />} {...props} />
  );
};
