import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M12.3619097 14.4476961l2.8451971 2.8451971c.3905243.3905243.3905243 1.0236893 0 1.4142136-.3905243.3905243-1.0236893.3905243-1.4142136 0l-3.5-3.5c-.36948895-.369489-.39221192-.961159-.0521498-1.3578982L13 10.6300759V3.54917049C11.2963643 2.51059307 9.63309995 2 8 2c-1.63309994 0-3.29636433.51059306-4.99999998 1.54917048L3 10.6300759l2.7592566 3.2191327c.34006212.3967392.31733915.9884092-.05214982 1.3578982l-3.5 3.5c-.39052429.3905243-1.02368927.3905243-1.41421356 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136l2.8451971-2.8451971-2.39734692-2.7969047C1.08539216 11.4695483 1 11.2387113 1 11V3c0-.33569722.16843826-.64899746.44845946-.83414809C3.62093279.72940779 5.80915618 0 8 0c2.1908438 0 4.3790672.7294078 6.5515405 2.16585191C14.8315617 2.35100254 15 2.66430278 15 3v8c0 .2387113-.0853922.4695483-.2407434.6507914l-2.3973469 2.7969047z"
        id="person"
      />
    </defs>
    <use transform="matrix(-1 0 0 1 20 3)" xlinkHref="#person" />
  </svg>
);
