import { Icon, IconButton, Stack, TableRow, Tooltip, Typography } from '@mui/material';
import {
  AssessmentForSchool,
  FilterKeys,
  GetAssessmentsQueryFilters,
  GetAssessmentsQuerySort,
  SORT_DIRECTION,
} from '@schooly/api';
import { RecurrenceInfo } from '@schooly/components/recurring';
import { AssessmentStatuses, DATE_FORMAT } from '@schooly/constants';
import {
  GridCell,
  GridHead,
  ReportGeneratedIcon,
  ReportsIcon,
  SimpleButton,
  SortableCell,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';

type AssessmentsHeaderProps = {
  sort?: GetAssessmentsQuerySort;
  onChangeSort: (v: GetAssessmentsQuerySort) => void;
  chartIcon?: React.ReactNode;
};

export const AssessmentsHeader: FC<AssessmentsHeaderProps> = ({
  onChangeSort,
  sort,
  chartIcon,
}) => {
  const { formatMessage } = useIntl();
  const handleSort = (columnTextId: GetAssessmentsQuerySort['columnTextId']) => () => {
    onChangeSort({
      columnTextId,
      direction:
        columnTextId === sort?.columnTextId
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  return (
    <GridHead borderBottom>
      <SortableCell
        label={formatMessage({ id: 'assessments-Name' })}
        sort={sort}
        columnTextId="name"
        onChangeSort={handleSort}
        pl={2}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            width: 145,
          },
        })}
      />
      <GridCell
        width="180px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 150,
          },
          [theme.breakpoints.only('md')]: {
            width: 120,
          },
          [theme.breakpoints.down('md')]: {
            width: 150,
          },
        })}
      >
        {formatMessage({ id: 'assessments-GroupsStudents' })}
      </GridCell>
      <GridCell
        width="110px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 90,
          },
          [theme.breakpoints.only('md')]: {
            width: 70,
          },
          [theme.breakpoints.down('md')]: {
            width: 90,
          },
        })}
      >
        {formatMessage({ id: 'assessments-Type' })}
      </GridCell>
      <SortableCell
        label={formatMessage({ id: 'assessments-AssessmentDate' })}
        sort={sort}
        columnTextId="assessment_date"
        onChangeSort={handleSort}
        width="140px"
        sx={(theme) => ({
          [theme.breakpoints.only('md')]: {
            width: 120,
          },
        })}
      />
      <SortableCell
        label={formatMessage({ id: 'assessments-AssessmentStatus' })}
        sort={sort}
        columnTextId="assessment_status"
        onChangeSort={handleSort}
        width="180px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 150,
          },
          [theme.breakpoints.only('md')]: {
            width: 120,
          },
          [theme.breakpoints.down('md')]: {
            width: 150,
          },
        })}
      />
      <GridCell
        width="200px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 160,
          },
          [theme.breakpoints.only('md')]: {
            width: 120,
          },
          [theme.breakpoints.down('md')]: {
            width: 160,
          },
        })}
      >
        {formatMessage({ id: 'assessments-Reports' })}
      </GridCell>
      <GridCell pr={1} right width="40px">
        {chartIcon}
      </GridCell>
    </GridHead>
  );
};

type AssessmentRowProps = {
  assessment: AssessmentForSchool;
  onAddFilters: (v: GetAssessmentsQueryFilters) => void;
};

export const AssessmentRow: FC<AssessmentRowProps> = ({ assessment, onAddFilters }) => {
  const { $t } = useIntl();
  const location = useAppLocation();
  const autoGeneratedFromReport = assessment.autogenerated;
  const navigate = useNavigate();
  const recurrenceId = assessment.recurring_state?.recurrence_id;
  const recurringState = assessment.recurring_state;
  return (
    <TableRow
      component={Link}
      to={`/assessments/${assessment.id}`}
      state={{ backgroundLocation: location }}
      sx={(theme) => ({
        '&:hover td': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <GridCell pl={2}>
        <Stack flexDirection="row" gap={1}>
          <TypographyWithOverflowHint variant="h3" color="common.grey2" noWrap>
            {assessment.name}
          </TypographyWithOverflowHint>
          {autoGeneratedFromReport && (
            <Tooltip
              title={
                <Stack alignItems="flex-start" gap={1}>
                  <Typography>{$t({ id: 'assessments-AutoGenerated' })}</Typography>
                  <SimpleButton
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/reports/${assessment.generated_from_report_id}`);
                    }}
                    sx={(theme) => ({
                      '.svg-icon': {
                        width: theme.spacing(2),
                        height: theme.spacing(2),
                      },
                      fontSize: theme.spacing(1.5),
                    })}
                    startIcon={<ReportGeneratedIcon />}
                  >
                    {$t({ id: 'assessments-GoToReport' })}
                  </SimpleButton>
                </Stack>
              }
            >
              <IconButton inverse>
                <ReportGeneratedIcon />
              </IconButton>
            </Tooltip>
          )}
          {!!recurringState && recurrenceId && (
            <RecurrenceInfo
              recurringState={recurringState}
              onClick={() =>
                onAddFilters({
                  [FilterKeys.RecurrenceId]: [recurrenceId],
                })
              }
            />
          )}
        </Stack>
      </GridCell>
      <GridCell>
        <GroupsCellContent assessment={assessment} />
      </GridCell>
      <GridCell>
        <MethodCellContent assessment={assessment} />
      </GridCell>
      <GridCell>
        <Typography color="common.grey2">
          {format(newDateTimezoneOffset(assessment.assessment_date), DATE_FORMAT)}
        </Typography>
      </GridCell>
      <GridCell>
        <StatusCellContent assessment={assessment} />
      </GridCell>
      <GridCell>
        <ReportsCellContent assessment={assessment} />
      </GridCell>
      <GridCell />
    </TableRow>
  );
};

type StatusCellContentProps = {
  assessment: AssessmentForSchool;
};

export const StatusCellContent: React.FC<StatusCellContentProps> = ({ assessment }) => {
  const { $t } = useIntl();

  const isPublished = assessment.assessment_status === AssessmentStatuses.Published;
  const isIncomplete = !isPublished && (!assessment?.methods.length || !assessment?.groups.length);

  const currentStatus = isIncomplete ? (
    $t({ id: 'status-Draft' })
  ) : isPublished ? (
    <>
      {$t({ id: 'status-Published' })}{' '}
      {assessment.publish_date
        ? format(newDateTimezoneOffset(assessment.publish_date), DATE_FORMAT)
        : ''}
    </>
  ) : (
    $t({ id: 'status-NotPublished' })
  );

  return (
    <Typography color={isPublished ? 'success.main' : 'common.grey2'}>{currentStatus}</Typography>
  );
};

type CellContentProps = {
  assessment: AssessmentForSchool;
};

export const MethodCellContent: React.FC<CellContentProps> = ({ assessment }) => {
  const { formatMessage } = useIntl();
  const method = assessment.methods[0];
  const ref = useRef<HTMLDivElement>(null);

  if (!method) return null;

  const restMethodsLength = assessment.methods.length - 1;

  const content = (
    <Typography ref={ref} color="common.grey2">
      {formatMessage({ id: `assessments-Type-${method.method_type}` })}{' '}
      {!!restMethodsLength && `+${restMethodsLength}`}
    </Typography>
  );

  return (
    <>
      <Stack flexDirection="row">
        {!!restMethodsLength ? (
          <Tooltip
            disableInteractive
            placement="bottom-start"
            arrow
            title={
              <>
                {assessment.methods.map((m) => (
                  <Typography key={m.method_type}>
                    {formatMessage({ id: `assessments-Type-${m.method_type}` })}
                  </Typography>
                ))}
              </>
            }
          >
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </Stack>
    </>
  );
};

export const GroupsCellContent: React.FC<CellContentProps> = ({ assessment }) => {
  const group = assessment.groups[0];
  const ref = useRef<HTMLDivElement>(null);

  if (!group) return null;

  const restGroupsLength = assessment.groups.length - 1;

  const content = (
    <Typography ref={ref} color="common.grey2" noWrap>
      {group.name}
      {!!restGroupsLength && ` +${restGroupsLength}`}
    </Typography>
  );

  return (
    <>
      {!!restGroupsLength ? (
        <Tooltip
          disableInteractive
          placement="bottom-start"
          arrow
          title={
            <>
              {assessment.groups.map((g) => (
                <Typography key={g.id}>{g.name}</Typography>
              ))}
            </>
          }
        >
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </>
  );
};

export const ReportsCellContent: React.FC<CellContentProps> = ({ assessment }) => {
  const ref = useRef<HTMLDivElement>(null);

  const reports = assessment.reports;
  if (!reports) return null;

  const report = reports[0];
  if (!report) return null;

  const restReportsLength = reports.length - 1;

  const content = (
    <Stack flexDirection="row" gap={1} ref={ref} alignItems="center">
      <Icon sx={{ color: 'common.grey2' }}>
        <ReportsIcon />
      </Icon>
      <Stack textOverflow="ellipsis" overflow="hidden" maxWidth="120px">
        <Typography
          component={Link}
          to={`/reports/${report.id}`}
          color="common.grey2"
          noWrap
          sx={{
            minWidth: 0,
            pointerEvents: restReportsLength ? 'none' : undefined,
            '&:hover': { textDecoration: 'underline' },
          }}
        >
          {report.name}
        </Typography>
      </Stack>
      {!!restReportsLength && <Typography color="common.grey2">+{restReportsLength}</Typography>}
    </Stack>
  );

  return !!restReportsLength ? (
    <Tooltip
      arrow
      placement="bottom-start"
      title={
        <Stack>
          {reports.map((r) => (
            <Typography
              component={Link}
              to={`/reports/${r.id}`}
              key={r.id}
              sx={{ '&:hover': { textDecoration: 'underline' } }}
            >
              {r.name}
            </Typography>
          ))}
        </Stack>
      }
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};
