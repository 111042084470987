import { createContext, FC, PropsWithChildren, useContext } from 'react';

import { UseSelectIds, useSelectIds } from './useSelectIds';

const SelectIdsContext = createContext<UseSelectIds>({
  selectedIds: new Set(),
  toggleSelectAll: () => {},
  toggleSelectId: () => {},
  toggleSelectedIds: () => {},
  clearSelectedIds: () => {},
});

export const WithSelectIds: FC<PropsWithChildren> = ({ children }) => {
  const selectIds = useSelectIds();

  return <SelectIdsContext.Provider value={selectIds}>{children}</SelectIdsContext.Provider>;
};

export const useSelectIdsContext = () => useContext(SelectIdsContext);
