import { Popover, PopoverOrigin, Stack, SxProps } from '@mui/material';
import { WithName } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, {
  FC,
  RefAttributes,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import useFlag from '../../../hooks/useFlag';
import theme from '../../../theme/theme';
import { Comments } from './Comments/Comments';
import { DropdownCommentIcon } from './DropdownCommentIcon';

export interface DropdownCommentsItem extends WithName {
  id?: string;
  comment: string;
  relation_id: string;
}

export interface DropdownCommentsProps {
  // TODO generic type
  comments?: DropdownCommentsItem[];
  canAdd?: boolean;
  canEditOwn?: boolean;
  canEditOther?: boolean;
  maxRows?: number;
  open?: boolean;
  plainCommentsListOnly?: boolean;
  iconOnly?: boolean;
  showAllComments?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
  onAdd?: (value: string) => Promise<void> | void;
  onEdit?: (value: string, selected: string) => Promise<void> | void;
  menuProps?: SxProps;
  renderIcon?: (disabled?: boolean) => React.ReactNode;
  getParentRef?: () => RefObject<HTMLDivElement | HTMLSpanElement>;
  tableView?: boolean;
  popoverMargin?: number;
  onToggle?: (val: boolean) => void;
  placement?: PopoverOrigin;
  error?: boolean;
  autoFocusOnEdit?: boolean;
  disableEscapeKeyDown?: boolean;
  saveOnEnterPress?: boolean;
}

const DROPDOWN_COMMENTS_POPOVER_WIDTH = 400;
export const DROPDOWN_COMMENTS_POPOVER_HEIGHT = 400;
export const DROPDOWN_COMMENTS_POPOVER_HEIGHT_SMALL = 300;
export const SCREEN_HEIGHT_SMALL = 800;
export const MIN_GAP_FOR_SCREEN_EDGE_AND_POPOVER = 10;

const popoverHeight =
  window.innerHeight < SCREEN_HEIGHT_SMALL
    ? DROPDOWN_COMMENTS_POPOVER_HEIGHT_SMALL
    : DROPDOWN_COMMENTS_POPOVER_HEIGHT;

export const DropdownComments: FC<DropdownCommentsProps & RefAttributes<HTMLElement>> =
  React.forwardRef<HTMLElement, DropdownCommentsProps>(
    (
      {
        comments,
        canAdd = true,
        canEditOwn = true,
        canEditOther = false,
        maxRows,
        open,
        plainCommentsListOnly,
        iconOnly,
        showAllComments,
        disabled,
        onClick,
        onFocus,
        onAdd,
        onEdit,
        renderIcon,
        getParentRef,
        tableView,
        popoverMargin,
        onToggle,
        placement,
        error,
        autoFocusOnEdit,
        disableEscapeKeyDown,
        saveOnEnterPress,
      },
      containerRef,
    ) => {
      const iconWrapperRef = useRef<HTMLDivElement>(null);
      const [isOpen, showSelect, hideSelect] = useFlag();
      const [position, setPosition] = useState<PopoverOrigin>(
        placement
          ? placement
          : {
              vertical: 'top',
              horizontal: 'left',
            },
      );

      const getScrollParent = useCallback((node: HTMLElement): HTMLElement | null => {
        if (node.scrollHeight > node.clientHeight) {
          return node;
        } else if (!node.parentElement) {
          return null;
        }

        return getScrollParent(node.parentElement) ?? document.body;
      }, []);

      const getAnchorEl = useCallback(() => {
        const parentRef = getParentRef?.();
        return parentRef?.current ?? iconWrapperRef.current;
      }, [getParentRef]);

      const getPositionEls = useCallback(() => {
        const anchorElement = getAnchorEl();
        const containerElement = containerRef as React.MutableRefObject<HTMLElement> | undefined;

        if (containerElement) {
          return { anchorElement, containerElement: containerElement.current };
        } else
          return {
            containerElement: anchorElement ? getScrollParent(anchorElement) : null,
            anchorElement,
          };
      }, [containerRef, getAnchorEl, getScrollParent]);

      const findPosition = useCallback(() => {
        const { anchorElement, containerElement } = getPositionEls();

        const containerRect = containerElement?.getBoundingClientRect();
        const anchorRect = anchorElement?.getBoundingClientRect();

        if (anchorRect && containerRect) {
          const horizontalPosition =
            containerRect.width - anchorRect.right > DROPDOWN_COMMENTS_POPOVER_WIDTH
              ? 'left'
              : 'right';

          const getVerticalPosition = () => {
            const windowHeight = window.innerHeight;

            const bottomSpace =
              windowHeight - anchorRect.bottom - MIN_GAP_FOR_SCREEN_EDGE_AND_POPOVER;

            const topSpace = anchorRect.top - MIN_GAP_FOR_SCREEN_EDGE_AND_POPOVER;

            if (popoverHeight < bottomSpace) {
              return 'bottom';
            }

            if (popoverHeight < topSpace) {
              return 'top';
            }

            return bottomSpace > topSpace ? 'bottom' : 'top';
          };

          setPosition({
            vertical: getVerticalPosition(),
            horizontal: horizontalPosition,
          });
        }
      }, [getPositionEls]);

      const { currentStaff } = useAuth();
      const hasComments = !!comments?.filter((comment) => Boolean(comment.comment)).length;
      const canOpen = !disabled && (canAdd || hasComments);

      const ownComment = useMemo(
        () => comments?.find((comment) => comment.relation_id === currentStaff?.relation_id),
        [comments, currentStaff?.relation_id],
      );

      const showInput = !ownComment && canAdd;

      const handleBlur = useCallback(
        (text: string, relation_id?: string) => {
          const formattedText = text.trim();
          if (relation_id) {
            onEdit?.(formattedText, relation_id);
          } else {
            onAdd?.(formattedText);
          }
        },
        [onAdd, onEdit],
      );

      const handleClose = useCallback(() => {
        hideSelect();
        onToggle?.(false);
      }, [hideSelect, onToggle]);

      const positionTransformProps: PopoverOrigin = useMemo(
        () => ({
          ...position,
          vertical: position.vertical === 'top' ? 'bottom' : 'top',
        }),
        [position],
      );

      const popoverMarginTop = useMemo(() => {
        if (!popoverMargin) {
          return undefined;
        }

        return position.vertical === 'bottom' ? popoverMargin : -popoverMargin;
      }, [popoverMargin, position.vertical]);

      const handleFocus = useCallback(
        (e: React.FocusEvent<HTMLDivElement, Element>) => {
          if (onFocus) {
            onFocus(e);
            return;
          }

          if (!canOpen) {
            return;
          }

          if (!placement) {
            findPosition();
          }

          showSelect();
          onToggle?.(true);
        },
        [canOpen, findPosition, onFocus, onToggle, placement, showSelect],
      );

      useEffect(() => {
        if (!placement && open) {
          findPosition();
        }
      }, [findPosition, open, placement]);

      const renderButton = useMemo(
        () => (
          <Stack
            display="inline-flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <span>
              {renderIcon ? (
                renderIcon?.(disabled)
              ) : (
                <DropdownCommentIcon
                  disabled={disabled ?? !canOpen}
                  hasComments={hasComments}
                  error={!!error}
                />
              )}
            </span>
          </Stack>
        ),
        [canOpen, disabled, error, hasComments, renderIcon],
      );

      if (plainCommentsListOnly) {
        return (
          <Comments
            comments={comments}
            maxRows={maxRows}
            showInput={showInput}
            canEditOwn={canEditOwn}
            canEditOther={canEditOther}
            onBlur={handleBlur}
            showAllComments={showAllComments}
            tableView={tableView}
            plainCommentsListOnly
          />
        );
      }

      return (
        <>
          <Stack
            tabIndex={canOpen ? -1 : undefined}
            onClick={!!disabled || !canOpen ? undefined : onClick}
            onFocus={handleFocus}
            ref={iconWrapperRef}
            sx={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              padding: (theme) => theme.spacing(0.5),
              backgroundColor: error ? 'error.superLight' : undefined,
            }}
          >
            {renderButton}
          </Stack>

          <Popover
            open={!iconOnly && (open || isOpen)}
            anchorEl={getAnchorEl()}
            anchorOrigin={position}
            transformOrigin={positionTransformProps}
            PaperProps={{
              style: {
                position: 'relative',
                overflow: 'auto',
                padding: '0px !important',
                width: DROPDOWN_COMMENTS_POPOVER_WIDTH,
                display: 'table',
                color: theme.palette.primary.main,
                boxShadow: `0px 5px 40px rgba(36, 39, 91, 0.15)`,
                marginTop: popoverMarginTop,
                ...theme.typography.body1,
              },
            }}
            onClose={handleClose}
            disableRestoreFocus
            disableEscapeKeyDown={disableEscapeKeyDown}
          >
            <Comments
              comments={comments}
              maxRows={maxRows}
              showInput={showInput}
              canEditOwn={canEditOwn}
              canEditOther={canEditOther}
              onBlur={handleBlur}
              showAllComments={showAllComments}
              placement={position}
              tableView={tableView}
              autoFocusOnEdit={autoFocusOnEdit}
              saveOnEnterPress={saveOnEnterPress}
            />
          </Popover>
        </>
      );
    },
  );
