import { IconButton, Stack, Typography } from '@mui/material';
import { StudentSchoolRelation, SyncUser } from '@schooly/api';
import { CrossIcon } from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { ModalConfirmationDialog } from '../../../components/uikit-components/Modal/ModalConfirmationDialog';
import { DuplicatesStatus } from './CompareDuplicatesContent';
import { CompareDuplicatesUserCard } from './CompareDuplicatesUserCard';

interface CompareDuplicatesConfirmationModalProps {
  onClose: () => void;
  open: boolean;
  user?: SyncUser | StudentSchoolRelation;
  confirmation: DuplicatesStatus;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const CompareDuplicatesConfirmationModal: FC<CompareDuplicatesConfirmationModalProps> = ({
  confirmation,
  open,
  onClose,
  onConfirm,
  onCancel,
  user,
  isLoading,
}) => {
  const { $t } = useIntl();
  const isMerging = confirmation === DuplicatesStatus.Duplicates;

  return (
    <ModalConfirmationDialog
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: 600,
          '& .MuiDialogContent-root': {
            width: '100%',
            pt: 0,
          },
        },
      }}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={() => {
        onClose();
        onConfirm();
      }}
      open={open}
      confirmLabelId="yes"
      cancelLabelId="no"
      content={
        <>
          <Stack direction="row" alignItems="flex-start" pt={2} pb={user ? 2 : 4}>
            <Typography variant="h1" sx={{ whiteSpace: 'pre', textWrap: 'wrap' }}>
              {$t({
                id: isMerging
                  ? 'profile-Duplicates-ConfirmationMerge'
                  : 'profile-Duplicates-ConfirmationMarkAsNonDuplicates',
              })}
            </Typography>
            <IconButton inverse onClick={onClose} disabled={isLoading}>
              <CrossIcon />
            </IconButton>
          </Stack>
          {user && <CompareDuplicatesUserCard user={user} isRelationToBePreserved />}
        </>
      }
    />
  );
};
