import { IconButton, Stack, Typography } from '@mui/material';
import {
  ATTENDANCE_REGISTERS_FILTER_KEYS,
  DEFAULT_DATE_FORMAT_FNS,
  DefaultSchoolYear,
  FilterKeys,
  FilterSection,
  GetAttendanceRegistersQueryFilters,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AgeGroupExpandedSelect,
  DateRangeDropdown,
  FilterDropdown,
  FiltersContainer,
  getSelectedItemsWithGrouping,
  MoreButton,
  MoreButtonOption,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  renderPropertyGroupTags,
  SelectedItem,
  SelectedItemWithGrouping,
  toggleMultipleValueArrayProperty,
} from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { CloseSmallIcon } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type AttendanceFiltersProps = {
  schoolId: string;
  filters: GetAttendanceRegistersQueryFilters;
  defaultFilters: GetAttendanceRegistersQueryFilters;
  notActualInitialDate?: boolean;
  defaultSchoolYear?: DefaultSchoolYear;
  defaultUserFilters: GetAttendanceRegistersQueryFilters;
  defaultShowByPresentAbsent: boolean;
  onSetFilters: (v: GetAttendanceRegistersQueryFilters) => void;
  onSetShowByPresentAbsent: (v: boolean) => void;
};

export const AttendanceFilters: FC<AttendanceFiltersProps> = ({
  defaultFilters,
  filters: actualFilters,
  schoolId,
  notActualInitialDate,
  defaultSchoolYear,
  defaultUserFilters,
  defaultShowByPresentAbsent,
  onSetFilters,
  onSetShowByPresentAbsent,
}) => {
  const { $t } = useIntl();
  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
  const { currentStaff } = useAuth();

  const [dateChanged, setDateChanged] = useState(false);
  const [draftShowByPresentAbsent, setDraftShowByPresentAbsent] = useState(
    defaultShowByPresentAbsent,
  );

  const [draftFilters, setDraftFilters] =
    useState<GetAttendanceRegistersQueryFilters>(actualFilters);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleResetToDefault = useCallback(() => {
    setDraftFilters(defaultUserFilters);
    setDraftShowByPresentAbsent(false);
  }, [defaultUserFilters]);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  useEffect(() => {
    setDraftFilters(actualFilters);
  }, [actualFilters]);

  const handleApply = useMemo(() => {
    const showByPresentAbsentChanged = draftShowByPresentAbsent !== defaultShowByPresentAbsent;

    if (
      !ATTENDANCE_REGISTERS_FILTER_KEYS.some((key) => {
        const draftFiltersForKey = [...(draftFilters[key] || [])];
        const actualFiltersForKey = [...(actualFilters[key] || [])];

        return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
      }) &&
      !showByPresentAbsentChanged
    )
      return undefined;

    return () => {
      onSetFilters(draftFilters);
      onSetShowByPresentAbsent(draftShowByPresentAbsent);
    };
  }, [
    onSetFilters,
    actualFilters,
    draftFilters,
    onSetShowByPresentAbsent,
    defaultShowByPresentAbsent,
    draftShowByPresentAbsent,
  ]);

  const onSetDate = useCallback(
    (v: [Date, Date]) =>
      setDraftFilters((filters) => {
        const updatedDates = [
          format(v[0], DEFAULT_DATE_FORMAT_FNS),
          format(v[1], DEFAULT_DATE_FORMAT_FNS),
        ];

        if (updatedDates.join('') === filters.date?.join('')) return filters;

        setDateChanged(true);
        return {
          ...filters,
          [FilterKeys.Date]: updatedDates,
        };
      }),
    [],
  );

  const handleClearFilters = useCallback(() => {
    setDraftFilters(defaultFilters);
  }, [defaultFilters]);

  const { date: filtersDate, age_group: filtersAgeGroup } = draftFilters;

  const dateLabel = $t({ id: 'schoolProperty-Period' });
  const ageGroupLabel = $t({ id: 'schoolProperty-AgeGroup' });

  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    setDraftFilters((filters) => ({ ...filters, [v]: filters[v] !== undefined ? undefined : [] }));
  }, []);

  const {
    getAgeGroupsByLevelId,
    getAgeGroupById,
    schoolLevelsWithAgeGroupsMap,
    getSchoolLevelById,
  } = useAgeGroups({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });

  const { onClearAgeGroup, onSelectAgeGroup } = useMemo(() => {
    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    const onSelectAgeGroup = (v: string[]) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(filters[FilterKeys.AgeGroup], v),
      }));
    };

    return {
      onSelectAgeGroup,
      onClearAgeGroup: clearFilter(FilterKeys.AgeGroup),
    };
  }, []);

  const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups =
      filtersAgeGroup?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [filtersAgeGroup, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  const filterOptions: MoreButtonOption<keyof GetAttendanceRegistersQueryFilters>[] = [
    { value: FilterKeys.Date, label: dateLabel, required: true },
    { value: FilterKeys.AgeGroup, label: ageGroupLabel },
  ];

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        onSaveFilter={handleSaveFilter}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        schoolId={schoolId}
        section={FilterSection.Attendance}
        filters={draftFilters}
        defaultSchoolYear={defaultSchoolYear}
        onSetFilters={(v) => {
          onSetFilters(pickOnlyParamsFromFilterKeys(ATTENDANCE_REGISTERS_FILTER_KEYS, v));
        }}
      />
      {filtersDate && (
        <DateRangeDropdown
          schoolId={schoolId}
          date={filtersDate}
          dateChanged={dateChanged}
          onSetDate={onSetDate}
          notActualInitialDate={notActualInitialDate}
          openLabel={dateLabel}
          defaultSchoolYear={defaultSchoolYear}
        />
      )}
      {draftShowByPresentAbsent && (
        <Stack direction="row" gap={1}>
          <Typography variant="h3">
            <FormattedMessage id="filter-ShowByPresentAbsent" />
          </Typography>
          <IconButton
            sx={{ fontSize: (theme) => theme.spacing(2) }}
            onClick={() => setDraftShowByPresentAbsent(false)}
          >
            <CloseSmallIcon />
          </IconButton>
        </Stack>
      )}
      {filtersAgeGroup && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.AgeGroup)}
          label={ageGroupLabel}
          tags={(open) =>
            renderPropertyGroupTags({
              selectedItems: selectedItemsForAgeGroups,
              onClick: open,
              getProperty: (i) =>
                i.isGroup
                  ? getSchoolLevelById(i.id)
                  : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
              getTooltip: (i) =>
                i.isGroup
                  ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                      <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                    ))
                  : null,
              tagProps: {
                userRole: SchoolUserRole.Student,
                sx: { maxWidth: 200 },
              },
            })
          }
        >
          {(onClose) => (
            <AgeGroupExpandedSelect
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersAgeGroup}
              onSelect={onSelectAgeGroup}
              onClose={onClose}
              onClear={onClearAgeGroup}
            />
          )}
        </FilterDropdown>
      )}
      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterOptions}
        selectedOptions={ATTENDANCE_REGISTERS_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        onToggleOption={toggleFiltersVisible}
        isSelectedShowByPresentAbsent={draftShowByPresentAbsent}
        onToggleShowByPresentAbsent={() => setDraftShowByPresentAbsent(!draftShowByPresentAbsent)}
      />
    </FiltersContainer>
  );
};
