import { AxiosInstance, AxiosResponse } from 'axios';
import moment from 'moment';

// storage-keys
export const MAINTENANCE_ENABLED = 'maintenance-enabled';
export const MAINTENANCE_TIMESTAMP = 'maintenance-timestamp';

export const TIME_MARGIN = 1000;

// header values
const MAINTENANCE_ENABLED_HEADER = 'x-maintenance-in-progress';
const MAINTENANCE_TIMESTAMP_HEADER = 'x-maintenance-start';

const isMaintenanceEnabled = false;
let maintenanceTimestamp: number | undefined;

export interface MaintenanceEvent {
  enabled?: boolean;
  timestamp?: number;
}

const checkMaintenance = (response: AxiosResponse) => {
  const enabled: string | undefined = response.headers[MAINTENANCE_ENABLED_HEADER];
  const timestamp: string | undefined = response.headers[MAINTENANCE_TIMESTAMP_HEADER];

  window.dispatchEvent(
    new CustomEvent<MaintenanceEvent>('maintenance', {
      detail: {
        enabled: enabled === 'true',
        timestamp: timestamp ? Number(timestamp) * 1000 : 0,
      },
    }),
  );
};

export default function maintenanceMiddleware(instance: AxiosInstance) {
  return instance.interceptors.response.use(
    (response) => {
      if (!response.request.responseURL.includes('avatar')) {
        checkMaintenance(response);
      }

      return response;
    },
    (error) => {
      checkMaintenance(error.response);
      return Promise.reject(error);
    },
  );
}

export function getIsMaintenanceEnabled() {
  // Start maintenance once counter reached 00:00 + 1s margin
  if (
    maintenanceTimestamp &&
    maintenanceTimestamp > 0 &&
    moment(maintenanceTimestamp).diff(moment()) <= TIME_MARGIN
  ) {
    return true;
  }

  return isMaintenanceEnabled;
}

export function getMaintenanceTimestamp() {
  return maintenanceTimestamp;
}
