import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ArchiveIcon, DeleteIcon, LockIcon } from '@schooly/style';
import React, { FC } from 'react';

export interface SchoolGeneralRowActionProps {
  index: number;
  id?: string;
  isLocked?: boolean;
  lockMessage?: React.ReactNode;
  onArchive?: (index: number) => void;
  onDelete?: (index: number) => void;
  deleteIcon?: EmotionJSX.Element;
}

export const SchoolGeneralRowAction: FC<SchoolGeneralRowActionProps> = ({
  id,
  index,
  isLocked,
  lockMessage,
  onArchive,
  onDelete,
  deleteIcon = <DeleteIcon />,
}) => {
  if (isLocked) {
    return lockMessage ? (
      <Tooltip title={<Box sx={{ whiteSpace: 'pre-wrap' }}>{lockMessage}</Box>}>
        <IconButton inverse>
          <LockIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton inverse>
        <LockIcon />
      </IconButton>
    );
  }

  if (id) {
    return (
      <IconButton inverse onClick={() => onArchive?.(index)}>
        <ArchiveIcon />
      </IconButton>
    );
  }

  return (
    <IconButton inverse onClick={() => onDelete?.(index)}>
      {deleteIcon}
    </IconButton>
  );
};
