import {
  ReportAction,
  ReportForAssessment,
  ReportStatuses,
  useGetReportQuery,
  usePerformReportActionMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { NotificationActions, useNotifications } from '@schooly/components/notifications';
import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';
import { ReportsPreviewLocation } from '../../redux/slices/reports/previewSlice';
import { useRouter } from '../router/useRouter';

export interface ReportContextProps {
  report?: ReportForAssessment;
  id?: string;
  isLoading: boolean;
  isFetching: boolean;
  canEdit: boolean;
  canCreate: boolean;
  canView: boolean;
  isChangingStatus: boolean;
  refetch: () => void;
  onChangeStatus: (status: ReportAction) => Promise<void>;
}

export const ReportContext = createContext<ReportContextProps>({
  report: undefined,
  id: undefined,
  isLoading: false,
  isFetching: false,
  canEdit: false,
  canView: false,
  canCreate: false,
  refetch: () => {},
  onChangeStatus: async () => {},
  isChangingStatus: false,
});

export const WithReport: FC<PropsWithChildren<{ id?: string }>> = ({ id: propId, children }) => {
  const { id: paramId } = useParams<'id'>();
  const id = propId ?? paramId;
  const location = useAppLocation<ReportsPreviewLocation>();
  const invalidateReportQueries = useInvalidateListQueriesFor('report');
  const { showNotification } = useNotifications();
  const { permissions } = useAuth();
  const { showError } = useNotifications();
  const { closeAndClean } = useRouter();
  const navigate = useNavigate();
  const { data, isFetching, isLoading, refetch } = useGetReportQuery(id || '', {
    enabled: !!id,
    refetchOnMount: 'always',
    onError: showError,
  });

  const changeReportStatus = usePerformReportActionMutation();

  const canView = permissions.includes('assessment_viewer');
  const canCreate =
    permissions.includes('assessment_creator') || permissions.includes('assessment_manager');
  const canEdit = !!data && data.report_status !== ReportStatuses.Published && canCreate;

  const onChangeStatus = useCallback(
    async (status: ReportAction) => {
      if (!id) return;

      const notificationActions: NotificationActions | undefined =
        status === ReportAction.Publish
          ? [
              {
                textId: 'reports-ViewReport',
                handler: () =>
                  navigate(`/reports/${id}`, {
                    state: location.state,
                  }),
                buttonColor: 'light',
              },
              {
                textId: 'reports-Unpublish',
                handler: () =>
                  changeReportStatus.mutate(
                    {
                      id,
                      status: ReportAction.Unpublish,
                    },
                    {
                      onError: showError,
                      onSuccess: () => {
                        invalidateReportQueries();
                        navigate(`/reports/${id}`, {
                          state: location.state,
                        });
                      },
                    },
                  ),

                isOutline: true,
                buttonColor: 'white-text',
              },
            ]
          : undefined;

      changeReportStatus.mutate(
        {
          id,
          status,
        },
        {
          onError: showError,
          onSuccess: (response) => {
            showNotification({
              message: response.success,
              type: 'success',
              actions: notificationActions,
            });
            invalidateReportQueries();
            if (status === ReportAction.Publish) {
              closeAndClean();
            } else refetch();
          },
        },
      );
    },
    [
      id,
      changeReportStatus,
      showError,
      navigate,
      location.state,
      invalidateReportQueries,
      showNotification,
      refetch,
      closeAndClean,
    ],
  );

  return (
    <ReportContext.Provider
      value={{
        id,
        isChangingStatus: changeReportStatus.isLoading,
        isFetching,
        isLoading,
        report: data,
        canEdit,
        canView,
        canCreate,
        refetch,
        onChangeStatus,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = () => {
  return useContext(ReportContext);
};
