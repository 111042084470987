import { SuggestedChange } from '@schooly/api';
import { StringOrNull } from '@schooly/api';
import { SuggestedChangeDataSet } from '@schooly/constants';
import { PhoneIcon } from '@schooly/style';
import { formatPhoneNumberWithCode } from '@schooly/utils/phone-number';
import React from 'react';

import { useProfile } from '../../../context/profile/useProfile';
import PhoneNumberModal from './PhoneNumberModal';
import ProfileProperty from './ProfileProperty';

interface PhoneNumberPropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
  value?: StringOrNull;
  suggestedChanges?: SuggestedChange[];
  hideSuggestedChanges?: boolean;
}

const PhoneNumberProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
  value,
  hideSuggestedChanges,
}: PhoneNumberPropertyParams) => {
  const { canEditProfile, hasEditingPermission } = useProfile();

  return (
    <ProfileProperty
      value={value}
      canEdit={canEditProfile && hasEditingPermission}
      params={{ size, iconColor, color }}
      renderIcon={() => <PhoneIcon />}
      renderValue={formatPhoneNumberWithCode}
      valueTextId="peopleDetail-PhoneNumber"
      editValueTextId="peopleDetail-AddPhoneNumber"
      dataSet={SuggestedChangeDataSet.Telephones}
      hideSuggestedChanges={hideSuggestedChanges}
      modalController={({ isOpen, hide }) => <PhoneNumberModal isOpen={isOpen} onClose={hide} />}
    />
  );
};

export default PhoneNumberProperty;
