import {
  Assessment,
  ASSESSMENTS_FILTER_KEYS,
  GetAssessmentsQueryFilters,
  useGetAssessmentsQuery,
} from '@schooly/api';
import { FC, useCallback, useMemo, useState } from 'react';

import { DropdownSelectProps } from '../../DropdownSelect';
import { pickOnlyParamsFromFilterKeys } from '../../utils';
import { AssessmentRecurrenceLabelSelect } from '../RecurrenceLabelSelect/AssessmentRecurrenceSelectLabel';
import { getRecurrences } from '../utils';
import { RecurrenceSelect } from './RecurrenceSelect';

type AssessmentRecurrenceSelectProps = {
  schoolId: string;
  selectedId?: string;
  onSelectRecurrence: (v: string) => void;
  filters?: GetAssessmentsQueryFilters;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;
export const AssessmentRecurrenceSelect: FC<AssessmentRecurrenceSelectProps> = ({
  schoolId,
  filters = {},
  selectedId,
  onSelectRecurrence,
  onClear,
  onClose,
  ...dropdownProps
}) => {
  const [queryEnabled, setQueryEnabled] = useState(false);

  const { data, hasNextPage, params, setParams, isLoading, isFetchingNextPage, fetchNextPage } =
    useGetAssessmentsQuery(
      {
        school_id: schoolId,
        query: '',
        filters: pickOnlyParamsFromFilterKeys(ASSESSMENTS_FILTER_KEYS, filters),
      },
      {
        enabled: queryEnabled,
      },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const assessments = useMemo(
    () => data?.pages.reduce<Assessment[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );
  const recurrences = getRecurrences(assessments);

  const renderLabel = useCallback(
    (title: string) => {
      return (
        <AssessmentRecurrenceLabelSelect title={title} id={selectedId ?? ''} schoolId={schoolId} />
      );
    },
    [schoolId, selectedId],
  );

  return (
    <RecurrenceSelect
      {...dropdownProps}
      onToggle={setQueryEnabled}
      schoolId={schoolId}
      selectedId={selectedId}
      onSelectRecurrence={onSelectRecurrence}
      onClear={onClear}
      onClose={onClose}
      recurrences={recurrences}
      isLoading={isLoading || isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      handleChangeQuery={handleChangeQuery}
      hasNextPage={hasNextPage}
      query={params.query}
      renderLabel={renderLabel}
    />
  );
};
