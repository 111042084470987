import { Genders, Nationalities, SchoolUserRole, UserContexts } from '@schooly/constants';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CancelToken } from 'axios';
import { format, isAfter, isBefore } from 'date-fns';
import { useState } from 'react';

import { DEFAULT_DATE_FORMAT_FNS } from '../constants';
import {
  ChangeGroupSubjectAttendanceDefault,
  CreateGroupResponse,
  GetGroupDetailsRequest,
  GetGroupDetailsResponse,
  GetGroupsAggregatedDataRequest,
  GetGroupSubjectsProps,
  GetGroupSubjectsResponse,
  GroupsAggregatedData,
} from './apiTypes/endpoints/groups';
import {
  AvailableRelationGroupRequest,
  Group,
  GroupLimitationsUpdate,
  GroupType,
  GroupUpdate,
  RelationGroup,
  UpdateGroupForRelationRequest,
} from './apiTypes/groups';
import {
  ApiError,
  AvailableCriteria,
  AvailableCriteriaCount,
  FilterElementType,
  IListGroupArguments,
  PagedResponse,
  SORT_DIRECTION,
} from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { SchoolYear } from './apiTypes/schools';
import {
  FilterKeys,
  UserWithGroupMembership,
  UserWithGroupMembershipNormalized,
} from './apiTypes/users';
import * as api from './requests';
import { getSortParams } from './utils/getSortParam';
import { newDateTimezoneOffset } from './utils/newDateTimezoneOffset';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

export const MAX_PAGE_SIZE = 9999;
const DEFAULT_PAGE_SIZE = 50;

const GROUPS_URL = '/groups/';
const GROUP_SUBJECTS_URL = '/groups/subjects/';
const GROUP_AVAILABLE_CRITERIA_URL = '/groups/available-criteria/';
const GROUP_AVAILABLE_CRITERIA_COUNT_URL = '/groups/available-criteria-by-count/';
const CHECK_GROUP_NAME_URL = '/groups/check-group-name/';
const GROUPS_AGGREGATED_DATA_URL = '/groups/aggregate';

export const GET_GROUP_DETAILS_QUERY = `${GROUPS_URL}/GET_GROUP_DETAILS_QUERY`;
export const GET_MEMBERS_FOR_GROUP_QUERY = `${GROUPS_URL}/GET_MEMBERS_FOR_GROUP_QUERY`;

export function listGroups({
  schoolId,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber,
  query,
  filters,
  token,
  sort,
  relationIds,
  singleDate,
  min,
}: IListGroupArguments): Promise<PagedResponse<Group>> {
  const onlyTutorGroups = filters?.only_tutor_groups?.join('') === '1';

  const params = removeObjectUndefinedNullValues({
    page_size: pageSize,
    page_number: pageNumber,

    // TODO: uncomment when backend is ready to process student/staff props sort
    // sort_by: getSortParam(
    //   x ? { columnTextId: arrangeBy, direction: SORT_DIRECTION.ASC } : sort,
    // ),
    sort_by: getSortParams(sort),

    query: query?.toLowerCase(),
    school_id: schoolId,

    date_from: filters?.date?.[0],
    date_to: filters?.date?.[1] || filters?.date?.[0],

    intersect_date_from: filters?.intersect_date?.[0],
    intersect_date_to: filters?.intersect_date?.[1] || filters?.intersect_date?.[0],

    single_date: filters?.single_date?.[0] || singleDate,

    student_status: filters?.student_status?.join(','),
    staff_status: filters?.staff_status?.join(','),

    age_group: filters?.age_group?.join(',') || filters?.student_age_group?.join(','),
    //staff_age_group: filters?.staff_age_group?.join(','),

    student_house: filters?.student_house?.join(','),
    staff_house: filters?.staff_house?.join(','),

    subject: onlyTutorGroups ? undefined : filters?.subject?.join(','),
    group_type: onlyTutorGroups ? GroupType.TutorGroup : undefined,

    assessment_ids: filters?.assessmentIds?.join(','),

    relation_ids:
      relationIds?.length || filters?.staff?.length
        ? [...(relationIds || []), ...(filters?.staff || [])].join(',')
        : undefined,
    min,
  });

  return api.get(GROUPS_URL, { params, cancelToken: token });
}

export const GET_GROUPS_QUERY = `${GROUPS_URL}/GET_GROUPS_QUERY`;

export const GROUPS_QUERY_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.IntersectDate,
  FilterKeys.SingleDate,
  FilterKeys.AgeGroup,
  FilterKeys.StaffHouse,
  FilterKeys.StudentHouse,
  FilterKeys.StaffStatus,
  FilterKeys.StudentStatus,
  FilterKeys.Subject,
  FilterKeys.OnlyTutorGroups,
  FilterKeys.Staff,
] as const;

export type GroupArrangeBy = FilterKeys.Subject | FilterKeys.AgeGroup;

export type GetGroupsQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.IntersectDate]?: string[];
  [FilterKeys.SingleDate]?: string[];
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.StaffHouse]?: string[];
  [FilterKeys.StudentHouse]?: string[];
  [FilterKeys.StaffStatus]?: string[];
  [FilterKeys.StudentStatus]?: string[];
  [FilterKeys.Staff]?: string[];
  [FilterKeys.OnlyTutorGroups]?: string[];
  [FilterKeys.Subject]?: string[];
};

export const GROUPS_ARRANGE_BY_FILTER_KEYS = [FilterKeys.Subject, FilterKeys.AgeGroup] as const;

export type GetGroupsQuerySort = {
  columnTextId: 'name' | 'students' | 'staff' | 'subject' | 'validity';
  direction: SORT_DIRECTION;
};

export const useGetGroupsQuery = (
  initialParams: Omit<IListGroupArguments, 'filters' | 'sort'> & {
    filters: GetGroupsQueryFilters;
    sort?: GetGroupsQuerySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<Group>>,
) => {
  const queryClient = useQueryClient();
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<Group>, ApiError>(
    [GET_GROUPS_QUERY, params],
    ({ pageParam }) => listGroups({ pageNumber: pageParam, ...params }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      onSuccess: (data) => {
        for (const page of data.pages) {
          for (const group of page.results) {
            queryClient.setQueryData([GET_GROUP_DETAILS_QUERY, group.id], { group });
          }
        }
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export function getGroupDetails({
  id,
  date,
  date_from,
  date_to,
  with_absence_request,
}: GetGroupDetailsRequest): Promise<GetGroupDetailsResponse> {
  const params = removeObjectUndefinedNullValues({
    date,
    date_from,
    date_to,
    with_absence_request,
  });
  return api.get(`${GROUPS_URL}${id}`, { params });
}

export const useGetGroupDetailsQuery = (
  params: GetGroupDetailsRequest,
  options?: RQUseQueryOptions<GetGroupDetailsResponse>,
) => {
  return useQuery<GetGroupDetailsResponse, ApiError>(
    [
      GET_GROUP_DETAILS_QUERY,
      params.id,
      JSON.stringify({
        date: params.date,
        date_from: params.date_from,
        date_to: params.date_to,
        with_absence_request: params.with_absence_request,
      }),
    ],
    () => getGroupDetails(params),
    options,
  );
};

export function getGroupMembersForGroup({
  id,
  date,
  user_role,
}: GetGroupDetailsRequest & { user_role: number }): Promise<{ users: UserWithGroupMembership[] }> {
  return api.get(`${GROUPS_URL}members-for-group/${id}`, {
    params: {
      date,
      user_role,
    },
  });
}

export const useGetMembersForGroupQuery = (
  params: GetGroupDetailsRequest & { user_role: number },
  options?: RQUseQueryOptions<{ users: UserWithGroupMembership[] }>,
) => {
  return useQuery<{ users: UserWithGroupMembership[] }, ApiError>(
    [
      GET_MEMBERS_FOR_GROUP_QUERY,
      params.id,
      params.user_role,
      { date: params.date, date_from: params.date_from, date_to: params.date_to },
    ],
    () => getGroupMembersForGroup(params),
    options,
  );
};

/**
 * Does the `useGetMembersForGroupQuery` and groups members by actual/not actual
 * (transforms the original response).
 */
export const useGetActualMembersForGroupQuery = (
  params: GetGroupDetailsRequest & { user_role: number },
  options?: RQUseQueryOptions<{
    actual: UserWithGroupMembershipNormalized[];
    notActual: UserWithGroupMembershipNormalized[];
  }>,
) => {
  return useQuery<
    {
      actual: UserWithGroupMembershipNormalized[];
      notActual: UserWithGroupMembershipNormalized[];
    },
    ApiError
  >(
    [
      GET_MEMBERS_FOR_GROUP_QUERY,
      params.id,
      params.user_role,
      { data: params.date, date_from: params.date_from, date_to: params.date_to },
    ],
    () => getGroupMembersForGroup(params) as any,
    {
      // Using cast to `any` as the data is being mutated and the eventual result will be
      // totally different from the response.
      select: ((response: { users: UserWithGroupMembership[] }) => {
        const currentDate = newDateTimezoneOffset(params.date);
        const actualUsers: Record<string, UserWithGroupMembershipNormalized> = {};
        const notActualUsers: Record<string, UserWithGroupMembershipNormalized> = {};

        response.users.forEach((u) => {
          const {
            start,
            end,
            school_properties,
            individual,
            criteria_type,
            enum_criteria,
            ...userData
          } = u;

          const memberStartDateTime = newDateTimezoneOffset(start);
          const memberEndDateTime = newDateTimezoneOffset(end);

          const container =
            isBefore(memberEndDateTime, currentDate) || isAfter(memberStartDateTime, currentDate)
              ? notActualUsers
              : actualUsers;
          const duplicate = container[u.relation_id];

          if (duplicate) {
            container[u.relation_id] = {
              ...userData,
              membership: [
                ...duplicate.membership,
                { start, end, criteria_type, enum_criteria, school_properties, individual },
              ],
            };
          } else {
            container[u.relation_id] = {
              ...userData,
              membership: [
                { start, end, criteria_type, enum_criteria, school_properties, individual },
              ],
            };
          }
        });

        const actual = Object.values(actualUsers);
        const notActual = Object.values(notActualUsers).filter(
          ({ relation_id }) => !actual.some((u) => u.relation_id === relation_id),
        );

        if (!actual.length && !Object.values(notActualUsers).length) {
          return { actual: [], notActual: [] };
        }

        return {
          actual,
          notActual,
        };
      }) as any,
      ...options,
    },
  );
};

export function createGroup(schoolId: string, group: GroupUpdate): Promise<CreateGroupResponse> {
  const data = {
    group: {
      school_id: schoolId,
      ...group,
    },
  };

  if ((data.group.limited_to_students as GroupLimitationsUpdate)?.subject_ids) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { subject_ids, ...limitedToStudents } = data.group
      .limited_to_students as GroupLimitationsUpdate;
    data.group.limited_to_students = limitedToStudents;
  }

  if ((data.group.limited_to_students as GroupLimitationsUpdate)?.subject_ids) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { subject_ids, ...limitedToStudents } = data.group
      .limited_to_students as GroupLimitationsUpdate;
    data.group.limited_to_students = limitedToStudents;
  }

  return api.post(GROUPS_URL, data);
}

export function updateGroup(groupId: string, groupUpdate: GroupUpdate): Promise<{ group: Group }> {
  const data = {
    group: groupUpdate,
  };

  return api.patch(`${GROUPS_URL}${groupId}`, data);
}

export function getGroupSubjects({
  schoolId,
  showArchived = true,
}: GetGroupSubjectsProps): Promise<GetGroupSubjectsResponse> {
  const params = removeObjectUndefinedNullValues({
    school_id: schoolId,
    show_archived: showArchived,
  });
  return api.get(GROUP_SUBJECTS_URL, { params });
}

export const GET_GROUP_SUBJECTS_QUERY = `${GROUPS_URL}GET_GROUP_SUBJECTS_QUERY`;

export const useGetGroupSubjectsQuery = (
  params: GetGroupSubjectsProps,
  options?: RQUseQueryOptions<GetGroupSubjectsResponse>,
) => {
  return useQuery<GetGroupSubjectsResponse, ApiError>(
    [GET_GROUP_SUBJECTS_QUERY, params.schoolId, params.showArchived],
    () => getGroupSubjects(params),
    {
      ...options,
    },
  );
};

export function changeGroupSubjectAttendanceDefault({
  schoolId,
  subjects,
}: ChangeGroupSubjectAttendanceDefault): Promise<{ success: string }> {
  return api.patch(`${GROUP_SUBJECTS_URL}attendance/${schoolId}`, { subjects });
}

interface GroupAvailableCriteriaParams {
  schoolId: string;
  fromDate: string;
  toDate?: string;
  relationRole?: SchoolUserRole;
  schoolPropertyIds?: string[];
  genders?: Genders[];
  nationalities?: Nationalities[];
  token?: CancelToken | undefined;
  type?: FilterElementType;
  showNoneCounts?: boolean;
  searchQuery?: string;
}

const prepareGroupAvailableCriteriaRequestParams = ({
  schoolId,
  fromDate,
  toDate,
  relationRole,
  schoolPropertyIds,
  genders,
  nationalities,
  showNoneCounts,
  type,
  searchQuery,
}: GroupAvailableCriteriaParams) => ({
  school_id: schoolId,
  relation_role: relationRole,
  date_from: fromDate || format(newDateTimezoneOffset(), DEFAULT_DATE_FORMAT_FNS),
  type,
  show_none_counts: showNoneCounts ? '1' : undefined,
  date_to: toDate,
  ...(schoolPropertyIds?.length && {
    limited_to_school_property_ids: schoolPropertyIds?.join(','),
  }),
  ...(genders?.length && {
    limited_to_genders: genders?.join(','),
  }),
  ...(nationalities?.length && {
    limited_to_nationalities: nationalities?.join(','),
  }),
  ...(searchQuery?.length && {
    search_query: searchQuery,
  }),
});

type GetGroupAvailableCriteriaCountResponse = {
  available_criteria: AvailableCriteriaCount[];
  total_count: number;
};

export function getGroupAvailableCriteriaCount(
  params: GroupAvailableCriteriaParams,
): Promise<GetGroupAvailableCriteriaCountResponse> {
  return api.get(GROUP_AVAILABLE_CRITERIA_COUNT_URL, {
    params: prepareGroupAvailableCriteriaRequestParams(params),
    cancelToken: params.token,
  });
}

export const GET_GROUP_AVAILABLE_CRITERIA_COUNT_QUERY = `${GROUPS_URL}/GET_GROUP_AVAILABLE_CRITERIA_COUNT_QUERY`;

export const useGroupAvailableCriteriaCountQuery = (
  params: GroupAvailableCriteriaParams,
  options?: RQUseQueryOptions<GetGroupAvailableCriteriaCountResponse>,
) => {
  return useQuery<GetGroupAvailableCriteriaCountResponse, ApiError>(
    [GET_GROUP_AVAILABLE_CRITERIA_COUNT_QUERY, params],
    () => getGroupAvailableCriteriaCount(params),
    options,
  );
};

export const GET_GROUP_AVAILABLE_CRITERIA_QUERY = `${GROUPS_URL}/GET_GROUP_AVAILABLE_CRITERIA_QUERY`;

export const useGetGroupAvailableCriteria = (
  params: GroupAvailableCriteriaParams,
  options?: RQUseQueryOptions<{ available_criteria: AvailableCriteria[] }>,
) => {
  return useQuery<{ available_criteria: AvailableCriteria[] }, ApiError>(
    [GET_GROUP_AVAILABLE_CRITERIA_QUERY, params],
    () => getGroupAvailableCriteria(params),
    options,
  );
};

export function getGroupAvailableCriteria(
  params: GroupAvailableCriteriaParams,
): Promise<{ available_criteria: AvailableCriteria[] }> {
  return api.get(GROUP_AVAILABLE_CRITERIA_URL, {
    params: prepareGroupAvailableCriteriaRequestParams(params),
    cancelToken: params.token,
  });
}

export function checkGroupName(
  school_id: string,
  name: string,
  schoolYears?: SchoolYear[],
  fromSchoolYearId?: string,
  toSchoolYearId?: string,
  fromDate?: string,
  toDate?: string,
): Promise<{ exists: boolean; group?: Group }> {
  const from = fromSchoolYearId
    ? schoolYears?.find((year) => year.id === fromSchoolYearId)?.start
    : fromDate;

  const to = toSchoolYearId ? schoolYears?.find((year) => year.id === toSchoolYearId)?.end : toDate;

  const params = {
    name,
    school_id,
    date_from: from,
    date_to: to || from,
  };

  return api.get(CHECK_GROUP_NAME_URL, { params });
}

export function deleteGroup(groupId: string): Promise<{}> {
  return api.remove(`${GROUPS_URL}${groupId}`);
}

// TODO move all interfaces to /apiTypes/
export interface UserGroupsRequest {
  context: UserContexts;
  relationId: string;
  date_from?: string;
  date_to?: string;
}

export interface RelationGroups {
  groups: RelationGroup[];
  count: number;
}

export type GroupForRelationRequest = Omit<UserGroupsRequest, 'context'> & {
  search_query?: string;
};

export function getGroupsForUser({
  context,
  relationId,
  date_from,
  date_to,
}: UserGroupsRequest): Promise<{ groups: Group[] }> {
  const params = {
    date_from,
    date_to,
  };

  return api.get(`${GROUPS_URL}for-user/${relationId}/?context=${context}`, { params });
}

export function getGroupsForRelation({
  relationId,
  ...params
}: GroupForRelationRequest): Promise<RelationGroups> {
  return api.get(`${GROUPS_URL}for-relation/${relationId}`, { params });
}

export const GET_GROUPS_FOR_RELATION_QUERY = `${GROUPS_URL}GET_GROUPS_FOR_RELATION_QUERY`;

export const useGetGroupsForRelationQuery = (
  params: GroupForRelationRequest,
  options?: RQUseQueryOptions<RelationGroups>,
) => {
  return useQuery<RelationGroups, ApiError>(
    [GET_GROUPS_FOR_RELATION_QUERY, params.relationId, params],
    () => getGroupsForRelation(params),
    options,
  );
};

export const GET_AVAILABLE_RELATION_GROUPS_QUERY = `${GROUPS_URL}GET_AVAILABLE_RELATION_GROUPS_QUERY`;

export const getAvailableRelationGroups = ({
  relationId,
  ...params
}: AvailableRelationGroupRequest): Promise<{ groups: Group[]; count: number }> => {
  return api.get(`${GROUPS_URL}available-for-relation/${relationId}`, { params });
};

export const useGetAvailableRelationGroupsQuery = (
  params: AvailableRelationGroupRequest,
  options?: RQUseQueryOptions<{ groups: Group[]; count: number }>,
) => {
  return useQuery<{ groups: Group[]; count: number }, ApiError>(
    [GET_AVAILABLE_RELATION_GROUPS_QUERY, params.relationId, params],
    () => getAvailableRelationGroups(params),
    {
      ...options,
    },
  );
};

export function getGroupsAggregatedData({
  schoolId,
  filters,
  arrangeBy,
  query,
}: GetGroupsAggregatedDataRequest): Promise<GroupsAggregatedData> {
  const onlyTutorGroups = filters?.only_tutor_groups?.join('') === '1';

  const params = removeObjectUndefinedNullValues({
    school_id: schoolId,

    date_from: filters?.date?.[0],
    date_to: filters?.date?.[1] || filters?.date?.[0],

    student_status: filters?.student_status?.join(','),
    staff_status: filters?.staff_status?.join(','),

    age_group: filters?.age_group?.join(','),

    student_house: filters?.student_house?.join(','),
    staff_house: filters?.staff_house?.join(','),

    subject: onlyTutorGroups ? undefined : filters?.subject?.join(','),
    group_type: onlyTutorGroups ? GroupType.TutorGroup : undefined,

    relation_ids: filters?.staff?.join(','),
    arrange_by: arrangeBy,
    query: query,
  });
  return api.get(`${GROUPS_URL}aggregate`, { params });
}

export function updateGroupsForRelation({
  relationId,
  ...body
}: UpdateGroupForRelationRequest): Promise<Group[]> {
  return api.patch(`${GROUPS_URL}for-relation/${relationId}`, body);
}

export const useUpdateGroupsForRelationMutation = (
  options?: RQUseMutationOptions<Group[], UpdateGroupForRelationRequest>,
): RQUseMutationResult<Group[], UpdateGroupForRelationRequest> => {
  return useMutation(updateGroupsForRelation, options);
};

export const GET_GROUPS_AGGREGATED_DATA_QUERY = `${GROUPS_AGGREGATED_DATA_URL}/GET_GROUPS_AGGREGATED_DATA_QUERY`;

export const useGetGroupsAggregatedData = (
  params: GetGroupsAggregatedDataRequest,
  options?: RQUseQueryOptions<GroupsAggregatedData>,
) => {
  return useQuery<GroupsAggregatedData, ApiError>(
    [GET_GROUPS_AGGREGATED_DATA_QUERY, params],
    () => getGroupsAggregatedData(params),
    options,
  );
};

export interface ExportGroupStudentListRequest {
  exportType: number;
  mimeType: 'application/pdf' | 'text/csv';
  groupId: string;
  studentDetails: number;
  primaryContactDetails: number;
  medicalInfo: number;
}

export function exportGroupStudentList({
  groupId,
  exportType,
  mimeType,
  studentDetails,
  primaryContactDetails,
  medicalInfo,
}: ExportGroupStudentListRequest): Promise<ArrayBuffer> {
  const params = {
    export_type: exportType,
    student_details: studentDetails,
    primary_contact_details: primaryContactDetails,
    medical_info: medicalInfo,
  };
  return api.get(`/export/students/${groupId}`, {
    params,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: mimeType,
    },
  });
}
