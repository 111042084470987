import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16284 2.62744C4.16284 2.21323 4.49863 1.87744 4.91284 1.87744H18C18.4142 1.87744 18.75 2.21323 18.75 2.62744C18.75 2.63618 18.7498 2.64489 18.7495 2.65356C18.7498 2.66223 18.75 2.67094 18.75 2.67968V16.1598C18.75 16.574 18.4142 16.9098 18 16.9098C17.5858 16.9098 17.25 16.574 17.25 16.1598V4.43128L2.53045 19.1576C2.23763 19.4506 1.76275 19.4507 1.46979 19.1579C1.17683 18.865 1.17672 18.3902 1.46955 18.0972L16.1825 3.37744H4.91284C4.49863 3.37744 4.16284 3.04165 4.16284 2.62744Z"
      fill="#24275B"
    />
  </svg>
);
