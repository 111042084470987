export enum ParentsSelectBy {
  StudentCriteria = 'SelectByStudentCriteria',
  IndividualStudent = 'SelectByIndividualStudent',
  IndividualParent = 'SelectIndividualParent',
}

export enum StaffSelectBy {
  StaffCriteria = 'SelectByStaffCriteria',
  IndividualStaff = 'SelectIndividualStaff',
  IndividualStudent = 'SelectByIndividualStudent',
}

export const SEARCH_PLACEHOLDER_TEXT_IDS = {
  parent: 'messages-ParentsSearch',
  staff: 'messages-StaffSearch',
  student: 'messages-StudentsSearch',
};
