import { Stack } from '@mui/material';
import { ConductVisibility } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';
import { $enum } from 'ts-enum-util';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { renderConductVisibilityTags } from './ConductVisibilityExpandedSelect';

export const CONDUCT_STATUS_OPTIONS = $enum(ConductVisibility)
  .map((value, key) => {
    return {
      value,
      labelTextId: `conduct-Status-${(key as string).toLowerCase()}`,
    };
  })
  .reverse();

type ConductVisibilitySelectMultipleProps = PropsWithChildren<{
  selectedVisibility: ConductVisibility[];
  onSelectConductVisibility: (v: ConductVisibility) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const ConductVisibilitySelectMultiple: FC<ConductVisibilitySelectMultipleProps> = ({
  selectedVisibility,
  onSelectConductVisibility,
  placeholder,
  ...dropdownProps
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderConductVisibilityTags({
          visibilities: CONDUCT_STATUS_OPTIONS.map((o) => o.value),
          tagProps: (status) => ({
            variant: selectedVisibility.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectConductVisibility,
        })}
      </Stack>
    );
  }, [onSelectConductVisibility, selectedVisibility]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selectedVisibility.length}
      renderContent={renderContent}
    >
      {(opened) => (
        <>
          {renderConductVisibilityTags({
            visibilities: selectedVisibility,
            tagProps: { size: placeholder ? 'small' : undefined },
            onDelete: opened ? onSelectConductVisibility : undefined,
          })}
        </>
      )}
    </DropdownSelect>
  );
};
