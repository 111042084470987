import { CollapsePanelAction, CollapsePanelState } from '@schooly/style';
import React, { FC, PropsWithChildren, useReducer } from 'react';

import { CollapsiblePanelContext, initialState } from './CollapsiblePanelContext';

const reducer = (state: CollapsePanelState, action: CollapsePanelAction) => {
  switch (action.type) {
    case 'buttonClick': {
      return {
        ...initialState,
        isOpen: !state.isOpen,
      };
    }
    case 'buttonHover': {
      if (state.isOpen) {
        return state;
      }

      const value = Boolean(action.value);

      return !value
        ? {
            ...state,
            isOpenByHover: value,
            buttonHover: value,
          }
        : {
            ...state,
            buttonHover: value,
            isOpenByHover: true,
            absolutePosition: true,
          };
    }
    case 'panelHover': {
      if (state.isOpen) {
        return state;
      }

      const value = Boolean(action.value);

      return !value
        ? {
            ...state,
            isOpenByHover: value,
            menuHover: value,
          }
        : {
            ...state,
            menuHover: value,
            absolutePosition: true,
            isOpenByHover: true,
          };
    }
    case 'resetStyles': {
      return { ...state, absolutePosition: false };
    }
    case 'reset': {
      return { ...state, isOpenByHover: false, absolutePosition: false, menuHover: false };
    }

    default:
      return state;
  }
};

export const WithCollapsiblePanel: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CollapsiblePanelContext.Provider value={{ state, dispatch }}>
      {children}
    </CollapsiblePanelContext.Provider>
  );
};
