import { Button } from '@mui/material';
import { Product, useGetProductsListQuery } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { DoneIcon, Loading, ModalFooter, ModalLarge, Spin } from '@schooly/style';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  ReplaceProductsAccountsContent,
  ReplaceProductsAccountsContentProps,
} from '../SchoolLegalEntityCreate/ReplaceProductsAccountsContent';

type ReplaceProductsAccountsOnRemoveModalProps = {
  legalEntityId: string;
  schoolId: string;
  accounts: ReplaceProductsAccountsContentProps['accounts'];
  onReplace: ReplaceProductsAccountsContentProps['onSubmit'];
  onClose: () => void;
  canDelete: boolean;
  title: ReactNode;
  onRemove: () => void;
  isRemoving: boolean;
};

export const ReplaceProductsAccountsOnRemoveModal: FC<ReplaceProductsAccountsOnRemoveModalProps> =
  ({ legalEntityId, schoolId, canDelete, onClose, onRemove, isRemoving, onReplace, ...props }) => {
    const { $t } = useIntl();
    const { getConfirmation } = useConfirmationDialog();

    const { data, isLoading } = useGetProductsListQuery(
      { query: '', schoolId, legalEntityIds: [legalEntityId], pageSize: 10 },
      { refetchOnMount: 'always' },
    );

    const entries = useMemo(
      () => data?.pages.reduce<Product[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

      [data?.pages],
    );

    const handleClose = useCallback(async () => {
      const confirmed = await getConfirmation({
        cancelTextId: 'no',
        confirmTextId: 'yes',
        textId: 'legalEntities-CloseConfirmation',
      });
      if (confirmed) {
        onClose();
      }
    }, [getConfirmation, onClose]);

    return (
      <ModalLarge open onClose={!isRemoving ? handleClose : undefined}>
        {!data || isLoading ? (
          <Loading />
        ) : (
          <ReplaceProductsAccountsContent
            onSubmit={onReplace}
            products={entries}
            onClose={!isRemoving ? handleClose : undefined}
            {...props}
          >
            <ModalFooter active>
              <Button
                variant="outlined"
                disabled={
                  isRemoving ||
                  //TR-6076 Archiving without replacing will be available after product archiving is done
                  !canDelete
                }
                onClick={onRemove}
              >
                {$t({
                  id: canDelete
                    ? 'action-DeleteWithoutReplacing'
                    : 'action-ArchiveWithoutReplacing',
                })}
              </Button>
              <Button
                endIcon={isRemoving ? <Spin /> : <DoneIcon />}
                type="submit"
                disabled={isRemoving}
              >
                {$t({ id: 'action-Replace' })}
              </Button>
            </ModalFooter>
          </ReplaceProductsAccountsContent>
        )}
      </ModalLarge>
    );
  };
