import {
  AnnualPlan,
  AnnualPlanRecordReport,
  AnnualPlanRecordTypes,
  ApiError,
  Assessment,
  GET_ANNUAL_PLAN_QUERY,
  getReport,
  ReportForAssessment,
  ReportStatuses,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SubmitHandler } from 'react-hook-form-lts';

import { useReportActions } from '../../../../../context/report/useReportActions';
import { AnnualPlannerCreateForm } from '../../scheme';
import { AnnualPlannerCreateRecordContext } from '../WithAnnualPlannerCreate';

export interface AnnualPlannerCreateReportContextProps {
  disableSaveReport: boolean;
  setDisableSaveReport: Dispatch<
    SetStateAction<AnnualPlannerCreateReportContextProps['disableSaveReport']>
  >;
  isSavingReport: boolean;
  canDeleteReport: boolean;
  deleteReport: () => Promise<void>;
  isDeletingReport: boolean;
  submitReport: SubmitHandler<AnnualPlannerCreateForm & { withNotifications?: boolean }>;
  addReportToQueryCache: (
    data: ReportForAssessment & { assessment_ids?: Array<Assessment['id']> },
  ) => void;
  updateReportInQueryCache: (
    id: ReportForAssessment['id'],
    data: Partial<ReportForAssessment & { assessment_ids?: Array<Assessment['id']> }>,
  ) => void;
  deleteReportFromQueryCache: (id: ReportForAssessment['id']) => void;
}

export const AnnualPlannerCreateReportContext =
  createContext<AnnualPlannerCreateReportContextProps>({
    disableSaveReport: false,
    setDisableSaveReport: () => {},
    isSavingReport: false,
    canDeleteReport: false,
    deleteReport: async () => {},
    isDeletingReport: false,
    submitReport: () => {},
    addReportToQueryCache: () => {},
    updateReportInQueryCache: () => {},
    deleteReportFromQueryCache: () => {},
  });

export const WithAnnualPlannerCreateReport: FC<PropsWithChildren> = ({ children }) => {
  const { record, close } = useContext(AnnualPlannerCreateRecordContext);
  const reportActions = useReportActions();

  const { showError } = useNotifications();

  const [disableSaveReport, setDisableSaveReport] = useState(false);
  const [fetchingOriginal, setFetchingOriginal] = useState(false);

  const queryClient = useQueryClient();

  const isPublished =
    (record?.details as AnnualPlanRecordReport)?.report_status === ReportStatuses.Published;

  const canDeleteReport = Boolean(
    record && record.type === AnnualPlanRecordTypes.REPORT && !isPublished,
  );

  const addReportToQueryCache = useCallback<
    AnnualPlannerCreateReportContextProps['addReportToQueryCache']
  >(
    (data) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        return {
          ...input,
          records: [
            ...input.records,
            {
              type: AnnualPlanRecordTypes.REPORT,
              id: data.id,
              scheduled_publish_date: data.scheduled_publish_date,
              report_status: data.report_status,
              name: data.name,
              assessment_ids: data.assessments?.map((assessment) => assessment.id) ?? [],
            },
          ],
        };
      });
    },
    [queryClient],
  );

  const updateReportInQueryCache = useCallback<
    AnnualPlannerCreateReportContextProps['updateReportInQueryCache']
  >(
    (id, data) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        return {
          ...input,
          records: input.records.map((record) =>
            record.type === AnnualPlanRecordTypes.REPORT && record.id === id
              ? {
                  ...record,
                  ...data,
                }
              : record,
          ),
        };
      });
    },
    [queryClient],
  );

  const deleteReportFromQueryCache = useCallback<
    AnnualPlannerCreateReportContextProps['deleteReportFromQueryCache']
  >(
    (id) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        return {
          ...input,
          records: input.records.filter(
            (record) => !(record.type === AnnualPlanRecordTypes.REPORT && record.id === id),
          ),
        };
      });
    },
    [queryClient],
  );

  const deleteReport = useCallback<
    AnnualPlannerCreateReportContextProps['deleteReport']
  >(async () => {
    const details = record?.details as AnnualPlanRecordReport;

    if (!details.id) {
      return;
    }

    reportActions.deleteReport(details, {
      onSuccess: () => {
        close();
      },
    });
  }, [close, record?.details, reportActions]);

  const submitReport = useCallback<AnnualPlannerCreateReportContextProps['submitReport']>(
    async ({ withNotifications, ...values }) => {
      if (!values.report?.name) {
        return;
      }

      if (values.originId) {
        try {
          setFetchingOriginal(true);

          const data = await getReport(values.originId);

          setFetchingOriginal(false);

          if (!data) {
            return;
          }

          const {
            areas_of_learning,
            with_tutor_feedback,
            report_status,
            subjects,
            age_groups,
            assessments,
          } = data;

          reportActions.updateReport(
            data.id,
            {
              name: values.report.name,
              scheduled_publish_date: values.date[0],
              areas_of_learning,
              with_tutor_feedback,
              report_status,
              subject_ids: subjects.map((subject) => subject.id),
              age_group_ids: age_groups.map((ageGroup) => ageGroup.id),
              assessment_ids: assessments?.map((assessment) => assessment.id) ?? [],
              withNotifications,
            },
            {
              onSuccess: () => {
                close();
              },
            },
          );
        } catch (error) {
          showError(error as ApiError);
        }
      } else {
        reportActions.createReport(
          {
            name: values.report.name,
            scheduled_publish_date: values.date[0],
            areas_of_learning: [],
            with_tutor_feedback: false,
            report_status: ReportStatuses.Draft,
            subject_ids: [],
            age_group_ids: [],
            assessment_ids: [],
            withNotifications,
          },
          {
            onSuccess: () => {
              close();
            },
          },
        );
      }
    },
    [close, reportActions, showError],
  );

  const value = useMemo<AnnualPlannerCreateReportContextProps>(
    () => ({
      disableSaveReport,
      setDisableSaveReport,
      isSavingReport: fetchingOriginal || reportActions.saving,
      canDeleteReport,
      deleteReport,
      isDeletingReport: reportActions.deleting,
      submitReport,
      addReportToQueryCache,
      updateReportInQueryCache,
      deleteReportFromQueryCache,
    }),
    [
      disableSaveReport,
      fetchingOriginal,
      reportActions.saving,
      reportActions.deleting,
      canDeleteReport,
      deleteReport,
      submitReport,
      addReportToQueryCache,
      updateReportInQueryCache,
      deleteReportFromQueryCache,
    ],
  );

  return (
    <AnnualPlannerCreateReportContext.Provider value={value}>
      {children}
    </AnnualPlannerCreateReportContext.Provider>
  );
};

export const useAnnualPlannerCreateReport = () => {
  return useContext(AnnualPlannerCreateReportContext);
};
