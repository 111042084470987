import { Box, Stack, SxProps, TableCellProps, TableRowProps } from '@mui/material';
import { Loading } from '@schooly/style';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { HoverShowProps } from '../../../context/table/tableHover/TableHoverContext';
import { WithTableHover } from '../../../context/table/tableHover/WithTableHover';
import { Grid } from '../Grid/Grid';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';

export interface TableCell {
  id: string;
  renderContent: (data: TableCellProps) => ReactNode;
  hoverProps?: HoverShowProps;
}
export interface TableRow {
  id: string;
  cells: TableCell[];
  // To provide props for specific row
  props?: (row: TableRow) => TableRowProps & SxProps;
  multiRowHoverId?: string;
}

interface TableProps extends PropsWithChildren {
  columns: TableCell[];
  rows: TableRow[];
  isLoading?: boolean;
  isEmpty?: boolean;
  renderEmptyContent?: ReactNode;
  withDefaultHover?: boolean;
  loaderColor?: string;
  hoverStyles?: SxProps;
  emptyContentSxProps?: SxProps;
}

export const Table: FC<TableProps> = ({
  columns,
  rows,
  isLoading,
  renderEmptyContent,
  isEmpty,
  loaderColor,
  hoverStyles,
  withDefaultHover,
  emptyContentSxProps = {
    overflowY: 'auto',
    mx: 2.5,
  },
}) => {
  if (!!isLoading) {
    return (
      <>
        <Box sx={{ overflowY: 'auto', mb: 2.5 }}>
          <Grid stickyHeader>{<TableHeader columns={columns} />}</Grid>
        </Box>
        <Stack p={7.5} flexGrow={1} color={loaderColor}>
          <Loading />
        </Stack>
      </>
    );
  }
  // TODO make as renderProp
  if (isEmpty) {
    return (
      <>
        <Box sx={emptyContentSxProps}>
          <Grid stickyHeader>{<TableHeader columns={columns} />}</Grid>
        </Box>
        {renderEmptyContent}
      </>
    );
  }

  return (
    <Grid stickyHeader sx={{ height: '100%' }}>
      {/* TODO Implement the ability to switch hover logic */}
      <WithTableHover hoverStyles={hoverStyles}>
        <TableHeader withoutDefaultHover={!withDefaultHover} columns={columns} />
        <TableBody withoutDefaultHover={!withDefaultHover} rows={rows} />
      </WithTableHover>
    </Grid>
  );
};
