import {
  ApiError,
  AvailableRelationGroupRequest,
  FilterValue,
  Group,
  GroupType,
  RelationGroup,
  useGetAvailableRelationGroupsQuery,
  useGetGroupsForRelationQuery,
  useUpdateGroupsForRelationMutation,
} from '@schooly/api';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProfileModalMode } from '../../../../context/profile/helpers';
import { useProfile } from '../../../../context/profile/useProfile';
import {
  RouterStateContext,
  RouterStateContextProps,
} from '../../../../context/router/RouterStateContext';
import { getRouteModalPathname } from '../../../../helpers/misc';
import useQueryStringParams from '../../../../hooks/useQueryStringParams';
import useSchoolYears from '../../../../hooks/useSchoolYears';

export enum ManageGroupModalMode {
  Individual,
  ByCriteria,
}

export interface ManageRelationGroupContextState {
  mode: ManageGroupModalMode;
  filterRelatedGroupsParams: {
    subjects?: FilterValue[];
    onlyTutorGroups?: FilterValue[];
    searchQuery?: string;
  };
  currentGroups: RelationGroup[];
}

export const CONTEXT_NAME = 'ManageRelationGroup';

export const getInitialState = (): ManageRelationGroupContextState => ({
  mode: ManageGroupModalMode.Individual,
  filterRelatedGroupsParams: {},
  currentGroups: [],
});

export const useManageRelationGroup = () => {
  // TODO remove profile context, get userType from props and user from endpoint
  const { user, userType, schoolMembership } = useProfile();
  const { showError } = useNotifications();
  const relationId = schoolMembership?.relation_id || '';

  const invalidateUserQueries = useInvalidateListQueriesFor(userType || 'student');
  const invalidateGroupQueries = useInvalidateListQueriesFor('group');

  const { defaultValidity, getSchoolYearById } = useSchoolYears();

  const { schoolYearId = defaultValidity?.id } = useQueryStringParams();
  const updateGroupsForRelation = useUpdateGroupsForRelationMutation();
  const [mode, setMode] = useState(ManageGroupModalMode.Individual);

  const schoolYear = useMemo(
    () => getSchoolYearById(schoolYearId),
    [getSchoolYearById, schoolYearId],
  );

  const { state, setState, setContextState, setContextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<ManageRelationGroupContextState>;

  const navigate = useNavigate();

  const [relatedGroupsParams, setRelatedGroupsParams] = useState<AvailableRelationGroupRequest>({
    relationId,
    date_from: schoolYear?.start,
    date_to: schoolYear?.end,
  });

  const { data: relatedGroupsData, isLoading: isRelatedGroupsFetching } =
    useGetAvailableRelationGroupsQuery(relatedGroupsParams, { onError: showError });

  const { data, isLoading: isLoadingGroupsForRelation } = useGetGroupsForRelationQuery(
    {
      relationId,
      date_from: schoolYear?.start,
      date_to: schoolYear?.end,
    },
    { enabled: !!relationId },
  );

  const activeGroups = useMemo(() => {
    if (!data || !schoolYear || !defaultValidity) return;

    return data.groups.filter((group) => {
      const currentDateTime = new Date().getTime();
      const currentSchoolYearStartTime = new Date(schoolYear.start).getTime();
      const defaultSchoolYearStartTime = new Date(defaultValidity.start).getTime();

      const futureYear = currentSchoolYearStartTime > defaultSchoolYearStartTime;

      return group.memberships.some(({ end, start }) => {
        const memberStartDateTime = new Date(start).getTime();
        const memberEndDateTime = new Date(end).getTime();

        return futureYear
          ? memberStartDateTime > currentDateTime
          : memberStartDateTime <= currentDateTime && memberEndDateTime >= currentDateTime;
      });
    });
  }, [data, defaultValidity, schoolYear]);

  useEffect(() => {
    if (!activeGroups) return;
    setContextState({ currentGroups: activeGroups });
  }, [activeGroups, setContextState]);

  useEffect(() => {
    if (!relationId || !schoolYear || !state?.filterRelatedGroupsParams) {
      return;
    }

    const { subjects, onlyTutorGroups, searchQuery } = state.filterRelatedGroupsParams;

    let params: AvailableRelationGroupRequest = {
      relationId,
      date_from: schoolYear.start,
      date_to: schoolYear.end,
    };

    if (onlyTutorGroups?.includes('1')) {
      params = { ...params, group_type: GroupType.TutorGroup };
    } else if (subjects?.length) {
      params = { ...params, subject_ids: subjects?.join(',') };
    }

    if (!!searchQuery) {
      params = { ...params, search_query: searchQuery };
    }

    setRelatedGroupsParams(params);
  }, [relationId, schoolYear, state?.filterRelatedGroupsParams]);

  useEffect(() => {
    setContextName(CONTEXT_NAME);
  }, [setContextName]);

  const closeModal = useCallback(async () => {
    if (!user) return;

    setState(getInitialState());

    navigate({
      pathname: getRouteModalPathname(userType, user),
      hash: `#${ProfileModalMode.Groups}`,
    });
  }, [user, setState, navigate, userType]);

  const removeGroup = useCallback(
    ({ memberships, ...group }: RelationGroup) => {
      setState({
        ...state,
        currentGroups: state.currentGroups.filter((g) => g.id !== group.id),
      });
    },
    [setState, state],
  );

  const addGroup = useCallback(
    (addedGroup: Group) => {
      const group = {
        ...addedGroup,
        memberships: [],
      };
      setState({
        ...state,
        currentGroups: state?.currentGroups ? [...state.currentGroups, group] : [group],
      });
    },
    [setState, state],
  );

  const saveGroups = useCallback(
    async (groups: RelationGroup[]) => {
      if (!schoolYear || !relationId) {
        return;
      }

      try {
        await updateGroupsForRelation.mutateAsync({
          relationId,
          date_from: schoolYear.start,
          date_to: schoolYear.end,
          group_ids: groups.map((g) => g.id),
        });
        invalidateUserQueries();
        invalidateGroupQueries();
        closeModal();
      } catch (err) {
        showError(err as ApiError);
      }
    },
    [
      closeModal,
      invalidateGroupQueries,
      invalidateUserQueries,
      relationId,
      schoolYear,
      showError,
      updateGroupsForRelation,
    ],
  );

  const filterRelatedGroups = useCallback(
    (filterRelatedGroupsParams: ManageRelationGroupContextState['filterRelatedGroupsParams']) => {
      setContextState({ filterRelatedGroupsParams });
    },
    [setContextState],
  );

  const setDefaultFilter = useCallback(
    async (subjects: FilterValue[], onlyTutorGroups: FilterValue[]) => {
      if (!subjects.length && !onlyTutorGroups.length) {
        return;
      }

      filterRelatedGroups({ subjects, onlyTutorGroups });
    },
    [filterRelatedGroups],
  );

  return {
    ...state,
    schoolYear,
    mode,
    isFetching: isLoadingGroupsForRelation,
    relatedGroups: relatedGroupsData?.groups,
    isRelatedGroupsFetching,
    user,
    userType,
    isSaving: updateGroupsForRelation.isLoading,
    actions: {
      addGroup,
      removeGroup,
      closeModal,
      setMode,
      filterRelatedGroups,
      saveGroups,
      setDefaultFilter,
    },
  };
};
