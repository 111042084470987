import { Icon } from '@mui/material';
import { RecurringState } from '@schooly/api';
import { ChevronUpIcon, RecurringIcon, SidebarExpandableCard, Spin } from '@schooly/style';
import { PropsWithChildren } from 'react';

type RecurringExpandableCardProps<T> = {
  item: T;
  expanded: boolean;
  onToggle: () => void;
  items: Array<Omit<T, 'recurring_state'> & { recurring_state: RecurringState | null }>;
  fetching: boolean;
} & PropsWithChildren;

export const RecurringExpandableCard = <
  T extends { name: string; id: string; recurring_state: RecurringState },
>({
  item,
  expanded,
  onToggle,
  items,
  fetching,
  children,
}: RecurringExpandableCardProps<T>) => (
  <SidebarExpandableCard
    item={item}
    itemsCount={items?.length}
    onCardClick={onToggle}
    expanded={expanded}
    startIcon={
      <Icon>
        <RecurringIcon />
      </Icon>
    }
    endIcon={
      <Icon
        sx={(theme) => ({
          cursor: 'pointer',
          color: theme.palette.common.grey,
          fontSize: theme.spacing(2),

          overflow: 'visible',
          '&:hover': {
            color: theme.palette.text.primary,
          },
          '.svg-icon': {
            transform: !expanded && !fetching ? 'rotate(180deg)' : undefined,
          },
        })}
      >
        {fetching ? <Spin /> : <ChevronUpIcon />}
      </Icon>
    }
  >
    {children}
  </SidebarExpandableCard>
);
