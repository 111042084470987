import { Stack, Typography } from '@mui/material';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { Spin, TagSelectProperty } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { filterPropertiesByQuery } from '../utils';
import { renderPropertyTypeTags } from './PropertyTypeExpandedSelect';

export const LABEL_TEXT_IDS = {
  [SchoolPropertyType.Status]: 'schoolProperty-Status-plural',
  [SchoolPropertyType.Department]: 'schoolProperty-Department-plural',
  [SchoolPropertyType.AgeGroup]: 'schoolProperty-AgeGroup-plural',
  [SchoolPropertyType.Campus]: 'schoolProperty-Campus-plural',
  [SchoolPropertyType.House]: 'schoolProperty-House-plural',
};

type StudentStaffPropertyTypeExpandedSelectProps = PropsWithChildren<{
  propertyType: SchoolPropertyType;
  schoolId: string;
  selectedStudentIds: string[];
  selectedStaffIds: string[];
  onSelectStaffId: (v: string) => void;
  onSelectStudentId: (v: string) => void;
  onClear: () => void;
  onClose: () => void;
}>;
export const StudentStaffPropertyTypeExpandedSelect: FC<StudentStaffPropertyTypeExpandedSelectProps> =
  ({
    propertyType,
    schoolId,
    selectedStudentIds,
    onSelectStudentId,
    onSelectStaffId,
    selectedStaffIds,
    onClose,
    onClear,
  }) => {
    const { $t } = useIntl();
    const inputRef = useRef<HTMLInputElement>(null);

    const {
      activePropertiesMap: staffActivePropertiesMap,
      archivedPropertiesMap: staffArchivedPropertiesMap,
      isLoading: staffPropertiesFetching,
    } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Staff });
    const {
      activePropertiesMap: studentActivePropertiesMap,
      archivedPropertiesMap: studentArchivedPropertiesMap,
      isLoading: studentPropertiesFetching,
    } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Student });

    const [query, setQuery] = useState('');

    const renderContent = useCallback(() => {
      if (studentPropertiesFetching || staffPropertiesFetching) return <SelectContentSkeleton />;

      const studentActiveProperties = filterPropertiesByQuery(
        query,
        studentActivePropertiesMap[propertyType],
      );

      const staffActiveProperties = filterPropertiesByQuery(
        query,
        staffActivePropertiesMap[propertyType],
      );

      const studentArchivedProperties = filterPropertiesByQuery(
        query,
        studentArchivedPropertiesMap[propertyType],
      );

      const staffArchivedProperties = filterPropertiesByQuery(
        query,
        staffArchivedPropertiesMap[propertyType],
      );

      if (
        !studentActiveProperties.length &&
        !staffActiveProperties.length &&
        !studentArchivedProperties.length &&
        !staffArchivedProperties.length
      )
        return (
          <Typography p={1}>
            <FormattedMessage id="input-NoOptionsFound" />
          </Typography>
        );

      return (
        <>
          {!!studentActiveProperties.length && (
            <>
              <Typography variant="h4" mt={1} ml={1}>
                {$t({ id: 'userType-student' })} {$t({ id: LABEL_TEXT_IDS[propertyType] })}
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
                {studentActiveProperties.map((property) => (
                  <TagSelectProperty
                    key={property.id}
                    userRole={SchoolUserRole.Student}
                    property={property}
                    defaultValue={<Spin />}
                    variant={selectedStudentIds?.includes(property.id) ? 'filled' : undefined}
                    onClick={() => {
                      onSelectStudentId(property.id);
                      setQuery('');
                    }}
                  />
                ))}
              </Stack>
            </>
          )}
          {!!staffActiveProperties.length && (
            <>
              <Typography variant="h4" mt={1} ml={1}>
                {$t({ id: 'userType-staff' })} {$t({ id: LABEL_TEXT_IDS[propertyType] })}
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
                {staffActiveProperties.map((property) => (
                  <TagSelectProperty
                    key={property.id}
                    userRole={SchoolUserRole.Staff}
                    property={property}
                    defaultValue={<Spin />}
                    variant={selectedStaffIds?.includes(property.id) ? 'filled' : undefined}
                    onClick={() => {
                      onSelectStaffId(property.id);
                      setQuery('');
                    }}
                  />
                ))}
              </Stack>
            </>
          )}
          {Boolean(!!studentArchivedProperties.length || !!staffArchivedProperties.length) && (
            <>
              <Typography variant="h4" mt={1} ml={1}>
                <FormattedMessage id="filter-Archived" />
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
                {studentArchivedProperties.map((property) => (
                  <TagSelectProperty
                    key={property.id}
                    userRole={SchoolUserRole.Student}
                    property={property}
                    defaultValue={<Spin />}
                    variant={selectedStudentIds?.includes(property.id) ? 'filled' : undefined}
                    onClick={() => {
                      onSelectStudentId(property.id);
                      setQuery('');
                    }}
                  />
                ))}
                {staffArchivedProperties.map((property) => (
                  <TagSelectProperty
                    key={property.id}
                    userRole={SchoolUserRole.Staff}
                    property={property}
                    defaultValue={<Spin />}
                    variant={selectedStaffIds?.includes(property.id) ? 'filled' : undefined}
                    onClick={() => {
                      onSelectStaffId(property.id);
                      setQuery('');
                    }}
                  />
                ))}
              </Stack>
            </>
          )}
        </>
      );
    }, [
      studentPropertiesFetching,
      staffPropertiesFetching,
      studentActivePropertiesMap,
      propertyType,
      staffActivePropertiesMap,
      studentArchivedPropertiesMap,
      staffArchivedPropertiesMap,
      $t,
      query,
      selectedStudentIds,
      onSelectStudentId,
      selectedStaffIds,
      onSelectStaffId,
    ]);

    return (
      <ExpandedSelect
        hasSelectedValue={selectedStaffIds.length + selectedStudentIds.length > 0}
        onClose={onClose}
        onClear={onClear}
        onClickInputArea={() => inputRef.current?.focus()}
        renderContent={renderContent}
      >
        {renderPropertyTypeTags({
          ids: selectedStudentIds,
          onDelete: onSelectStudentId,
          tagProps: {
            schoolId,
            userRole: SchoolUserRole.Student,
            sx: { maxWidth: 200 },
            size: 'small',
          },
        })}
        {renderPropertyTypeTags({
          ids: selectedStaffIds,
          onDelete: onSelectStaffId,
          tagProps: {
            schoolId,
            userRole: SchoolUserRole.Staff,
            sx: { maxWidth: 200 },
            size: 'small',
          },
        })}
        <SelectSearchInput ref={inputRef} autoFocus value={query} onChangeText={setQuery} />
      </ExpandedSelect>
    );
  };
