import { IconButton } from '@mui/material';
import { ConductEntriesCreateRequest, useExportConductForGroup } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, DownloadIcon, Spin } from '@schooly/style';
import { IntlError } from '@schooly/utils/intl-error';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { useConductForGroup } from '../../../../context/conduct/useConductForGroup';
import { downloadFile } from '../../../../utils/downloadFile';

interface GroupPreviewModalConductExportProps {
  params: ConductEntriesCreateRequest;
}

export const GroupPreviewModalConductExport: FC<GroupPreviewModalConductExportProps> = ({
  params,
}) => {
  const { showError } = useNotifications();
  const { group } = useConductForGroup();

  // is being set after successful download and reset in 3 sec afterward
  const [isSuccess, setIsSuccess] = useState(false);
  const { mutateAsync: exportConductForGroup, isLoading } = useExportConductForGroup();

  const handleExportClick = useCallback(() => {
    if (!group) return;

    exportConductForGroup(
      {
        ...params,
        groupId: group.id,
        sort: undefined,
      },
      {
        onSuccess: (data) => {
          try {
            downloadFile(data, `${group.name}.conduct.csv`);
            setIsSuccess(true);
          } catch (err) {
            showError(err as IntlError);
          }
        },
        onError: showError,
      },
    );
  }, [group, exportConductForGroup, showError, params]);

  /** resets success icon in 3 sec */
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccess(false), 3 * 1000);
    }
  }, [isSuccess]);

  return (
    <IconButton
      disabled={!group}
      sx={{ color: isLoading || isSuccess ? 'primary.main' : 'text.primary' }}
      inverse
      onClick={isLoading ? undefined : handleExportClick}
    >
      {(() => {
        if (isLoading) {
          return <Spin />;
        }

        if (isSuccess) {
          return <CheckIcon />;
        }

        return <DownloadIcon />;
      })()}
    </IconButton>
  );
};
