import { InboxMessage, Message } from '@schooly/api';
import React from 'react';

import CreateMessagePreview from '../../../components/common/MessageModal/CreateMessagePreview';
import Avatar from '../../../components/ui/Avatar';
import { AttachmentProps } from '../../../components/ui/Input/RichText/Attachments/attachment-types';
import Modal from '../../../components/ui/Modal';
import { getFormattedPublishDate } from '../../../helpers/messages';
import { getUserFullName } from '../../../helpers/users';

import './index.scss';

interface IProps extends AttachmentProps {
  message?: Message | InboxMessage;
  isOpen: boolean;
  onClose: VoidFunction;
}

const RecipientPreviewModal: React.FC<IProps> = ({
  isOpen,
  message,
  onClose,
  ...attachmentProps
}) => {
  if (!message) return null;

  const { publisher, title } = message as Message;
  const { sender } = message as InboxMessage;

  const senderUser = publisher || sender;

  return (
    <Modal
      className="RecipientPreviewModal"
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      withInnerModal
    >
      <div className="RecipientPreviewModal__person-badge">
        <Avatar size="small-plus" user={senderUser} />
        <div>
          <p className="RecipientPreviewModal__name mb-0">{getUserFullName(senderUser)}</p>
          <p className="RecipientPreviewModal__date mb-0">{getFormattedPublishDate(message)}</p>
        </div>
      </div>
      <CreateMessagePreview message={message} {...attachmentProps} />
    </Modal>
  );
};

export default RecipientPreviewModal;
