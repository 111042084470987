import React, { useEffect, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import buildClassName from '../../../utils/buildClassName';
import { renderError, renderLabel } from './helpers';
import { BaseFormInputProps, useFormInput } from './utils';

type PickedInputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'readOnly' | 'autoComplete' | 'defaultValue' | 'autoFocus'
>;

export type InputType = 'text' | 'email' | 'number';

interface IProps extends BaseFormInputProps, PickedInputProps {
  type?: InputType;
  min?: number;
  max?: number;
  maxLength?: number;
  placeholderTextId?: string;
  hideErrorOnChange?: boolean;
  saveDisabledValues?: boolean;
}

const FormInput: React.FC<IProps> = (props) => {
  const {
    id,
    type,
    className,
    disabled,
    readOnly,
    autoComplete,
    min,
    max,
    maxLength,
    defaultValue,
    placeholderTextId,
    autoFocus,
    hideErrorOnChange = false,
    saveDisabledValues,
    minNumberErrorTextId,
    maxNumberErrorTextId,
  } = props;

  const { inputRef, error, fullName, value, isTouched } = useFormInput(props);
  const {
    formState: { isSubmitted },
  } = useFormContext();

  const { formatMessage } = useIntl();

  const [internalError, setInternalError] =
    useState<FieldError | { type: string; messageTextId: string }>();
  const [hideError, setHideError] = useState(isSubmitted ? hideErrorOnChange : false);

  useEffect(() => {
    if (!value || type !== 'number') {
      setInternalError(undefined);
      return;
    }

    const number = Number(value);
    if (min !== undefined && number < min) {
      if (minNumberErrorTextId) {
        setInternalError({ type: 'custom', messageTextId: minNumberErrorTextId });
        return;
      }

      setInternalError({
        type: 'number.min',
        message: `Field must be greater than or equal to ${min}`,
      });
      return;
    }
    if (max !== undefined && number > max) {
      if (maxNumberErrorTextId) {
        setInternalError({ type: 'custom', messageTextId: maxNumberErrorTextId });
        return;
      }

      setInternalError({
        type: 'number.max',
        message: maxNumberErrorTextId || `Field must be less than or equal to ${max}`,
      });
      return;
    }

    setInternalError(undefined);
  }, [max, maxNumberErrorTextId, min, minNumberErrorTextId, type, value]);

  const renderedError = error || internalError;

  const fullClassName = buildClassName(
    'form-group',
    disabled && 'disabled',
    type === 'number' && 'number',
    saveDisabledValues && 'save-disabled-values',
    (isTouched || !!defaultValue) && 'touched',
    !!renderedError && !hideError && 'error',
    !!placeholderTextId && 'withPlaceholder',
    className,
  );

  const hideErrorOnChangeProps = hideErrorOnChange
    ? {
        onChange: () => setHideError(true),
        onBlur: () => setHideError(false),
      }
    : {};

  return (
    <label className={fullClassName} htmlFor={id}>
      <input
        id={id}
        name={fullName}
        ref={inputRef}
        className="form-control"
        style={disabled ? { display: 'none' } : undefined}
        type={type}
        // TR-3025
        //disabled={saveDisabledValues ? false : disabled}
        readOnly={readOnly}
        autoComplete={autoComplete}
        min={min}
        max={max}
        maxLength={maxLength}
        defaultValue={defaultValue}
        placeholder={placeholderTextId && formatMessage({ id: placeholderTextId })}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...hideErrorOnChangeProps}
      />
      {/* TR-3025 */}
      {disabled && <input className="form-control" type={type} disabled={disabled} value={value} />}
      {renderLabel(props)}
      {!hideError && renderError(renderedError)}
    </label>
  );
};

FormInput.defaultProps = {
  type: 'text',
};

export default FormInput;
