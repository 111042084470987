import { Box } from '@mui/material';
import { FC } from 'react';

import { SearchInput, SearchInputProps } from './SearchInput';

export const PageHeaderSearchInput: FC<SearchInputProps> = (props) => {
  return (
    <Box
      data-test-id={'page-header-search'}
      sx={(theme) => ({
        position: 'relative',
        width: '100%',
        maxWidth: 660,
        [theme.breakpoints.down('md')]: {
          maxWidth: 'unset',
        },
      })}
    >
      <SearchInput {...props} />
    </Box>
  );
};
