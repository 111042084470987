import { MenuItem } from '@mui/material';
import { ControlSelect } from '@schooly/components/form-select';
import { FC } from 'react';

export enum RepeatOnMonth {
  ByWeekOfMonth = 1,
  ByDayOfMonth = 2,
}

type RecurringMonthSelectProps = {
  dayOfWeekLabel: string;
  dayOfMonthLabel: string;
  name: string;
};

export const RecurringMonthSelect: FC<RecurringMonthSelectProps> = ({
  dayOfMonthLabel,
  dayOfWeekLabel,
  name,
}) => {
  return (
    <ControlSelect
      name={name}
      renderValue={(value) =>
        value === RepeatOnMonth.ByWeekOfMonth ? dayOfWeekLabel : dayOfMonthLabel
      }
      defaultValue={RepeatOnMonth.ByWeekOfMonth}
      fullWidth
      sx={{
        '&& .MuiSelect-outlined.MuiOutlinedInput-input.MuiSelect-select': {
          paddingTop: 1.25,
        },
      }}
    >
      <MenuItem value={RepeatOnMonth.ByWeekOfMonth}>{dayOfWeekLabel}</MenuItem>
      <MenuItem value={RepeatOnMonth.ByDayOfMonth}>{dayOfMonthLabel}</MenuItem>
    </ControlSelect>
  );
};
