import { IconButton } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { CrossIcon, Loading, ModalHeader, ModalLarge } from '@schooly/style';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import { CollapsiblePanel } from '../../../components/common/CollapsiblePanel';
import { ModalSidebarItem } from '../../../components/ui/ModalSidebar';
import { ModalMain } from '../../../components/uikit-components/Modal/Modal.styled';
import { useGroup } from '../../../context/groups/useGroup';
import { useRouter } from '../../../context/router/useRouter';
import useAppLocation from '../../../hooks/useAppLocation';
import usePrevious from '../../../hooks/usePrevious';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import { AddGroupModalSection } from '../AddGroupModal/AddGroupModal';
import { ViewGroupModalTabs } from '../utils';
import { GroupPreviewModalContent } from './GroupPreviewModalContent';
import { GroupPreviewModalInfoV2 } from './GroupPreviewModalInfoV2';
import { GroupPreviewModalSideBar } from './GroupPreviewModalSideBar';

import './GroupPreviewModal.scss';

export const GroupPreviewModal: React.FC = () => {
  const location = useAppLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const { permissions } = useAuth();
  const { closeAndClean } = useRouter();
  const query = useQueryStringParams();

  const { group, validity, fetching, error, activeTab, isExpired, periodLabel } = useGroup();

  const prevActiveTab = usePrevious(activeTab);

  useEffect(() => {
    if (!query.tab && activeTab && prevActiveTab !== activeTab) {
      navigate(
        {
          pathname: location.pathname,
          search: `tab=${activeTab}`,
        },
        { replace: true, state: location.state },
      );
    }
  }, [
    activeTab,
    location.pathname,
    location.search,
    location.state,
    navigate,
    prevActiveTab,
    query,
  ]);

  const handleChangeActiveTab = useCallback(
    (item: ModalSidebarItem<ViewGroupModalTabs>) => {
      navigate({ ...location, search: `tab=${item.id}` }, { state: location.state, replace: true });
    },
    [location, navigate],
  );

  const handleEditGroupClick = useCallback(
    (section: AddGroupModalSection) => {
      navigate({
        pathname: `/groups/${id}/edit`,
        search: `?section=${section}`,
      });
    },
    [navigate, id],
  );

  const canManageGroup = permissions.includes('group_manager');

  const canManageStudents =
    permissions.includes('group_manager') ||
    group?.can_manage_student_members == null ||
    group?.can_manage_student_members;

  const renderContent = () => {
    if (error && !fetching)
      return (
        <>
          <ModalHeader withBorderBottom={false} active>
            <IconButton onClick={closeAndClean}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <AccessDenied />
        </>
      );

    if (!group) return <Loading />;

    return (
      <>
        <GroupPreviewModalInfoV2
          group={group}
          validity={validity}
          onModalClose={closeAndClean}
          onEditClick={() => handleEditGroupClick(AddGroupModalSection.Header)}
          isExpired={isExpired}
          showEditButton={canManageGroup || canManageStudents}
          showCloneButton={canManageGroup}
          withGoBack
          active
          periodLabel={periodLabel}
        />
        <ModalMain>
          <CollapsiblePanel>
            <GroupPreviewModalSideBar handleChangeActiveTab={handleChangeActiveTab} />
          </CollapsiblePanel>

          <GroupPreviewModalContent
            handleEditGroupClick={handleEditGroupClick}
            showStaffEditButton={canManageGroup}
            showStudentsEditButton={canManageGroup || canManageStudents}
          />
        </ModalMain>
      </>
    );
  };

  return (
    <ModalLarge open onClose={closeAndClean}>
      {renderContent()}
    </ModalLarge>
  );
};
