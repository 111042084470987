import {
  ATTENDANCE_REGISTERS_FILTER_KEYS,
  FilterSection,
  GetAttendanceRegistersQueryFilters,
  useGetAttendanceCodesQuery,
  useGetFiltersQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import { GroupsIcon, Loading, SettingsIcon } from '@schooly/style';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import useUserCounts from '../../hooks/useUserCounts';
import { AttendanceContent } from './AttendanceContent';

export const AttendancePage: React.FC = () => {
  const { userCounts } = useUserCounts();

  const { permissions, schoolId = '', relationId = '' } = useAuth();

  const { data: codes, isLoading: isAttendanceCodesLoading } = useGetAttendanceCodesQuery(
    schoolId,
    { enabled: !!schoolId, refetchOnMount: 'always' },
  );

  const { data: filters, isLoading: isLoadingFilters } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Attendance },
    { enabled: !!schoolId && !!relationId },
  );

  const isAdmin = permissions.includes('school_admin');

  const { $t } = useIntl();
  const navigate = useNavigate();

  const onConfigurationClick = useCallback(() => {
    navigate('/settings/attendance');
  }, [navigate]);

  const onCreateGroupClick = useCallback(() => {
    navigate('/groups/new');
  }, [navigate]);

  const isConfigured = !!codes?.length;

  const initialFilters: GetAttendanceRegistersQueryFilters | undefined = useMemo(() => {
    if (isLoadingFilters) return;

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      return pickOnlyParamsFromFilterKeys(ATTENDANCE_REGISTERS_FILTER_KEYS, defaultFilter.filter);
    }

    return {};
  }, [filters, isLoadingFilters]);

  const renderContent = () => {
    if (isAttendanceCodesLoading || !initialFilters) {
      return <Loading />;
    }

    if (!isConfigured) {
      return (
        <NoListItemsStub
          titleText={$t({ id: 'moduleNotConfigured' })}
          type="attendance"
          subTitleText={
            isAdmin
              ? $t({ id: 'school-sections-Attendance-notConfiguredAdmin' })
              : $t({ id: 'school-sections-Attendance-notConfigured' })
          }
          buttonText={isAdmin && $t({ id: 'section-AttendanceSettings' })}
          buttonIcon={isAdmin && <SettingsIcon />}
          onButtonClick={onConfigurationClick}
        />
      );
    }

    if (!userCounts?.group) {
      return (
        <NoListItemsStub
          titleText={$t({ id: 'school-sections-Attendance-ModuleNotAvailableWithoutGroups' })}
          type="attendance"
          subTitleText={$t({ id: 'school-sections-Attendance-noGroups' })}
          buttonText={$t({ id: 'school-sections-Attendance-CreateGroup' })}
          buttonIcon={<GroupsIcon />}
          onButtonClick={onCreateGroupClick}
        />
      );
    }

    return <AttendanceContent codes={codes} initialFilters={initialFilters} />;
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};
