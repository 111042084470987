import { styled, Typography } from '@mui/material';

export interface CounterProps {
  inverse?: boolean;
  disabled?: boolean;
  button?: boolean;
}

export const Counter = styled(Typography, {
  shouldForwardProp: (prop) => !(['inverse', 'disabled', 'button'] as PropertyKey[]).includes(prop),
})<CounterProps>(({ theme, inverse, disabled, button }) => {
  return {
    flex: '0 0 auto',
    padding: theme.spacing(0.125, 0.5),
    marginLeft: theme.spacing(),
    borderRadius: parseInt(theme.spacing(1.5)),
    border: disabled ? theme.mixins.borderValue() : undefined,
    backgroundColor: disabled ? theme.palette.common.white : theme.palette.primary.main,
    color: disabled ? theme.palette.text.secondary : theme.palette.common.white,

    ...(inverse && {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    }),

    ...(button && {
      backgroundColor: theme.palette.common.greenOld,
      color: theme.palette.primary.main,

      '&&': {
        ...theme.typography.body3,
        lineHeight: theme.spacing(2.25),
        height: theme.spacing(2.25),
      },
    }),
  };
});

Counter.defaultProps = {
  variant: 'body1',
  noWrap: true,
  // @ts-ignore the `defaultProps` does not expose the `component` prop by any reason
  component: 'span',
};
