import { Autocomplete, createFilterOptions, Typography } from '@mui/material';
import { SelectOption } from '@schooly/api';
import { FormTextField } from '@schooly/components/form-text-field';
import { PlusIcon, SimpleButton, SimpleButtonProps } from '@schooly/style';
import React, { FC, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { InputEndAdornment } from '../../../../../components/uikit-components/FormTextField/InputEndAdornment';
import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import {
  addSchoolPeriodGroupOption,
  AnnualPlannerCreateForm,
  AnnualPlannerCreateSchoolPeriodForm,
} from '../../scheme';
import { useAnnualPlannerCreateRecord } from '../WithAnnualPlannerCreate';
import { AnnualPlannerCreateSchoolPeriodDate } from './AnnualPlannerCreateSchoolPeriodDate';
import { AnnualPlannerCreateSchoolPeriodName } from './AnnualPlannerCreateSchoolPeriodName';

type Option = SelectOption<Exclude<AnnualPlannerCreateSchoolPeriodForm['groupId'], undefined>>;

const defaultFilterOptions = createFilterOptions<Option>();

export const AnnualPlannerCreateSchoolPeriod: FC = () => {
  const inputRef = useRef<HTMLDivElement>(null);

  const { $t } = useIntl();
  const { schoolYear } = useAnnualPlanner();
  const { record } = useAnnualPlannerCreateRecord();
  const { watch, setValue, register, formState } = useFormContext<AnnualPlannerCreateForm>();

  const groupId = watch('schoolPeriod.groupId');

  const options = useMemo<Option[]>(
    () => [
      ...(schoolYear?.period_groups ?? []).map((group) => ({ value: group.id, label: group.name })),
      addSchoolPeriodGroupOption,
    ],
    [schoolYear?.period_groups],
  );

  const value = options.find((option) => option.value === groupId);

  return (
    <>
      <AnnualPlannerCreateSchoolPeriodDate />

      <input
        type="hidden"
        {...register('schoolPeriod.groupId', {
          required: $t({ id: 'input-ErrorRequired' }),
        })}
      />
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label ?? ''}
        filterOptions={(options, state) => {
          const result = defaultFilterOptions(options, state);

          // should always keep `addGroup` option
          if (
            result.length < 1 ||
            result[result.length - 1].value !== addSchoolPeriodGroupOption.value
          ) {
            result.push(addSchoolPeriodGroupOption);
          }

          return result;
        }}
        renderOption={({ className, ...props }, option) =>
          option.value === addSchoolPeriodGroupOption.value ? (
            <SimpleButton {...(props as SimpleButtonProps)} startIcon={<PlusIcon />} sx={{ mt: 1 }}>
              {option.label}
            </SimpleButton>
          ) : (
            <li className={className} {...props}>
              <Typography
                variant="h3"
                color="primary"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {option.label}
              </Typography>
            </li>
          )
        }
        renderInput={(params) => (
          <FormTextField
            {...params}
            ref={inputRef}
            label={$t({ id: 'annualPlanner-Popover-SchoolPeriod-SelectGroup' })}
            error={Boolean(formState.errors?.schoolPeriod?.groupId?.message)}
            helperText={formState.errors?.schoolPeriod?.groupId?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment: record ? <InputEndAdornment locked /> : params.InputProps?.endAdornment,
            }}
            required
          />
        )}
        componentsProps={{
          popper: {
            sx: {
              zIndex: (theme) => theme.zIndex.tooltip,

              '& .MuiAutocomplete-paper': {
                maxHeight:
                  document.body.clientHeight -
                  (inputRef.current?.getBoundingClientRect().bottom ?? 0),
              },
            },
          },
        }}
        value={value}
        onChange={(event, option) => {
          if (!record) {
            setValue(
              'schoolPeriod',
              {
                groupId: option.value,
                groupName: option.value === addSchoolPeriodGroupOption.value ? '' : option.label,
              },
              { shouldValidate: true },
            );
          }
        }}
        disabled={!!record}
        openOnFocus
        disableClearable
        fullWidth
      />

      <AnnualPlannerCreateSchoolPeriodName />
    </>
  );
};
