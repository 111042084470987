import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getDateFnsLocaleByLangCode } from '@schooly/utils/date';
import { FC, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

type DateFnsLocalizationProviderProps = PropsWithChildren;
export const DateFnsLocalizationProvider: FC<DateFnsLocalizationProviderProps> = ({ children }) => {
  const { locale } = useIntl();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getDateFnsLocaleByLangCode(locale)}
    >
      {children}
    </LocalizationProvider>
  );
};
