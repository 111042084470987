export const AUTH_APP_BASE_PATH = '/auth';
export const SERVER = import.meta.env.VITE_SERVER;
export const WEB_APP_URL = import.meta.env.VITE_OVERRIDE_WEB_APP_URL || window.location.origin;

export const DEBUG = import.meta.env.VITE_DEBUG;
export const IS_DEV = import.meta.env.DEV;

// Capitalized keys are expected for amplify config
export const AWS_CONFIG = {
  Auth: {
    mandatorySignIn: true,
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_COGNITO_IDENTITY_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_COGNITO_IDENTITY_USER_POOL_CLIENT_ID,
    identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL,
    cookieStorage: {
      domain: import.meta.env.VITE_ORIGIN,
      sameSite: IS_DEV ? 'lax' : 'none',
      secure: !IS_DEV,
    },
    // identityPoolRegion: import.meta.env.VITE_AWS_REGION,
  },
  Storage: {
    bucket: import.meta.env.VITE_PUBLIC_UPLOADS_S3_BUCKET,
    region: import.meta.env.VITE_AWS_REGION,
  },
};

export const {
  VITE_DATE: BUILD_DATE,
  VITE_COMMIT_SHA: COMMIT_SHA,
  VITE_LANGUAGE: LANGUAGE,
} = import.meta.env;

IS_DEV &&
  // eslint-disable-next-line no-console
  console.info({
    AWS_CONFIG,
    SERVER,
    WEB_APP_URL,
    DEBUG,
    IS_DEV,
    BUILD_DATE,
    COMMIT_SHA,
    LANGUAGE,
  });
