import { Box, Switch } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch/Switch';
import { FilterKeys } from '@schooly/api';
import { buildClassName } from '@schooly/utils/build-classname';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFilters } from '../../../../context/filters/useFilters';

export interface OnlyTutorGroupProps {
  className?: string;
}

export const OnlyTutorGroupControl: FC<OnlyTutorGroupProps> = ({ className }) => {
  const { getDraftValue, setDraftValue } = useFilters();

  const checked = getDraftValue(FilterKeys.OnlyTutorGroups).join('') === '1';

  const handleChange = useCallback<Exclude<SwitchProps['onChange'], undefined>>(
    (event, checked) => {
      setDraftValue(FilterKeys.OnlyTutorGroups, checked ? ['1'] : []);
    },
    [setDraftValue],
  );

  return (
    <Box className={buildClassName('filter-control', className)}>
      <Box className="filter-control-name">
        <FormattedMessage id="groups-OnlyTutorGroups" />
      </Box>
      <Box px={2} py={1}>
        <Switch checked={checked} onChange={handleChange} />
      </Box>
    </Box>
  );
};
