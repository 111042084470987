import { styled } from '@mui/material';
import { BasketSvg, RichLinkInner } from '@schooly/style';
import ReactQuill from 'react-quill';

export const ReactQuillStyled = styled(ReactQuill)(({ theme }) => ({
  '& .ql-container': {
    ...theme.typography.h3,
    ...theme.mixins.borderControl(),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    overflowY: 'hidden',

    "& .ql-tooltip[data-mode='link']::before": {
      content: '""',
      margin: 0,
    },

    '& .ql-tooltip': {
      alignItems: 'center',
      width: '100%',
      left: '0 !important',
      boxShadow: '0 20px 40px rgba(37, 39, 90, 0.1)',
      borderRadius: theme.shape.borderRadiusLg,
      border: 'none',
      padding: theme.spacing('15px', '46px', '13px', '16px'),

      '&:not(.ql-hidden)': {
        display: 'flex',
      },

      '& a': {
        lineHeight: 'unset',
      },

      "&.ql-editing input[type='text']": {
        ...theme.typography.h3,
        ...theme.mixins.border(),
        width: '87%',
        borderRadius: theme.shape.borderRadiusSm,
        lineHeight: '18px',
        color: theme.palette.primary.main,
      },

      '& .ql-remove::before': {
        content: `url(${BasketSvg})`,
        display: 'flex',
        height: theme.spacing(3),
        backgroundRepeat: 'no-repeat',
        color: theme.palette.text.secondary,
      },
    },
  },

  '& .ql-editor': {
    minHeight: 300,
    overflowY: 'auto',

    '& a': {
      color: `${theme.palette.primary.main} !important`,

      '&::before': {
        content: `url(${RichLinkInner})`,
        marginRight: theme.spacing(0.5),
      },
    },

    '&.ql-blank::before': {
      ...theme.typography.h3,
      fontStyle: 'normal',
      color: theme.palette.text.secondary,
    },
  },

  '&.RichText--error': {
    '& .ql-container': {
      borderColor: theme.palette.error.main,
    },
  },

  '&.RichText--readonly': {
    '& .ql-container': {
      border: 'none',

      '& .ql-editor': {
        minHeight: 'unset',
        padding: 0,
      },
    },
  },
}));

ReactQuillStyled.defaultProps = {
  className: 'RichText',
};
