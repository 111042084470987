import { InputAdornment, TextFieldProps } from '@mui/material';
import { LockIcon, TimeIcon } from '@schooly/style';
import React, { FC } from 'react';

import { TimeInputStyled } from './TimeInput.styled';

export type TimeInputProps = TextFieldProps;

export const TimeInput: FC<TimeInputProps> = (props) => {
  return (
    <TimeInputStyled
      type="time"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <TimeIcon />
          </InputAdornment>
        ),
        endAdornment: props.disabled ? (
          <InputAdornment position="start">
            <LockIcon />
          </InputAdornment>
        ) : undefined,
      }}
      InputLabelProps={{
        sx: {
          ml: 3.5,
          visibility: props.value ? 'hidden' : 'visible',
          '&.Mui-focused': {
            visibility: 'hidden',
          },
        },
      }}
      {...props}
      required={false}
    />
  );
};
