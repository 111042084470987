import { Box, MenuItem, MenuProps, PopoverOrigin, SxProps, Theme, Typography } from '@mui/material';
import { DoneIcon } from '@schooly/style';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';
import { MIN_GAP_FOR_SCREEN_EDGE_AND_POPOVER } from '../DropdownCommentsV2/DropdownComments';
import {
  DropdownClearIcon,
  DropdownMenu,
  DropdownMenuItemTop,
  DropdownMenuProps,
  DropdownOpenIcon,
} from './Dropdown.styled';

import './Dropdown.scss';

const MAX_POPOVER_HEIGHT = 250;

export interface DropdownOption<T = any> {
  value: T;
  label: string;
}

export interface DropdownProps<T = any>
  extends Omit<MenuProps, 'onChange' | 'open'>,
    DropdownMenuProps {
  className?: string;
  value?: T;
  options: DropdownOption<T>[];
  placeholder?: string;
  icon?: React.ReactNode;
  iconPlacement?: 'start' | 'end';
  iconOnHover?: boolean;
  canEdit?: boolean;
  canClean?: boolean;
  border?: boolean;
  onChange?: (option?: DropdownOption<T>) => void;
  transformOrigin?: PopoverOrigin;
  sx?: SxProps<Theme>;
  dropdownStyles?: SxProps<Theme>;
  noIcon?: boolean;
  positioningOverride?: boolean;
  open?: boolean;
  disableEscapeKeyDown?: boolean;
  disableSpaceKeyPress?: boolean;
  onFocus?: (event: React.FocusEvent) => void;
  noStopPropagationOnSelect?: boolean;
}

export const $classDropdown = `${$UIKitPrefix}Dropdown`;

export const Dropdown = <T,>({
  className,
  value,
  options,
  placeholder,
  icon,
  iconPlacement = 'start',
  iconOnHover,
  canEdit = true,
  canClean = true,
  border,
  onChange,
  sx,
  dropdownStyles,
  noIcon,
  positioningOverride,
  open,
  disableEscapeKeyDown,
  onFocus,
  noStopPropagationOnSelect,
  disableSpaceKeyPress,
  ...props
}: DropdownProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  // const [isOpen, showSelect, hideSelect] = useFlag();
  // useVirtualBackdrop(isOpen, hideSelect, menuRef);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [verticalPositioning, setVerticalPositioning] = useState<PopoverOrigin['vertical']>();

  const dropdownOpensDown = verticalPositioning === 'top';
  const dropdownOpensUp = verticalPositioning === 'bottom';

  const change = useCallback(
    (option?: DropdownOption<T>) => {
      onChange?.(option);
    },
    [onChange],
  );

  const handlePopoverOpen = useCallback<React.FocusEventHandler<HTMLDivElement>>(() => {
    if (!canEdit) {
      return;
    }

    setAnchorEl(menuRef.current);
  }, [canEdit]);

  const handleClickIcon = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleItemClick = useCallback(
    (option: DropdownOption<T>) => (event: React.MouseEvent) => {
      if (!noStopPropagationOnSelect) {
        event.stopPropagation();
      }
      change(option);
      handlePopoverClose();
    },
    [change, handlePopoverClose, noStopPropagationOnSelect],
  );

  const handleClear = useCallback(() => {
    change();
    handlePopoverClose();
  }, [change, handlePopoverClose]);

  const handleItemKeyDown = useCallback(
    (option: DropdownOption<T>) => (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
        case ' ': // <- whitespace
          if (!disableSpaceKeyPress) {
            if (!noStopPropagationOnSelect) {
              event.stopPropagation();
            }
            change(option);
            handlePopoverClose();
          }
          break;
        case 'Escape':
          handlePopoverClose();
          break;
        case 'Backspace':
          if (canClean) {
            handleClear();
          }
          break;
      }
    },
    [
      canClean,
      change,
      disableSpaceKeyPress,
      handleClear,
      handlePopoverClose,
      noStopPropagationOnSelect,
    ],
  );

  const handleItemKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case ' ': // <- whitespace
          if (disableSpaceKeyPress) {
            event.preventDefault();
          }
          break;
      }
    },
    [disableSpaceKeyPress],
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onFocus) {
        onFocus(e);
      } else {
        handlePopoverOpen(e);
      }
    },
    [handlePopoverOpen, onFocus],
  );

  const findVerticalPosition = useCallback(() => {
    if (!positioningOverride) return;
    const windowHeight = window.innerHeight;
    const anchorRect = anchorEl?.getBoundingClientRect();

    if (anchorRect) {
      const bottomSpace = windowHeight - anchorRect.bottom - MIN_GAP_FOR_SCREEN_EDGE_AND_POPOVER;
      const topSpace = anchorRect.top - MIN_GAP_FOR_SCREEN_EDGE_AND_POPOVER;

      if (MAX_POPOVER_HEIGHT < bottomSpace) {
        return 'top';
      }

      if (MAX_POPOVER_HEIGHT < topSpace) {
        return 'bottom';
      }

      return bottomSpace > topSpace ? 'top' : 'bottom';
    }
  }, [anchorEl, positioningOverride]);

  const openDropdown = Boolean(anchorEl);

  const selectedOption = useMemo(
    () => options?.find((item) => item.value === value),
    [options, value],
  );

  const dropdownIcon = useMemo(() => {
    if (noIcon) return null;
    return canClean && selectedOption ? (
      <DropdownClearIcon
        onClick={handleClear}
        onKeyDown={(event) => event.key === 'Backspace' && event.stopPropagation()}
        sx={(theme) => ({
          '&&.MuiIconButton-root': {
            bottom: dropdownOpensUp ? theme.spacing(1.75) : undefined,
            top: dropdownOpensUp ? 'unset' : undefined,
          },
        })}
      />
    ) : (
      <DropdownOpenIcon />
    );
  }, [canClean, dropdownOpensUp, handleClear, noIcon, selectedOption]);

  useEffect(() => {
    const verticalPosition = findVerticalPosition();
    if (verticalPosition) {
      setVerticalPositioning(verticalPosition);
    }
  }, [findVerticalPosition]);

  useEffect(() => {
    if (open) {
      if (!canEdit) {
        return;
      }
      setAnchorEl(menuRef.current);
    } else if (open === false) {
      handlePopoverClose();
    }
  }, [canEdit, handlePopoverClose, open]);

  return (
    <Box
      ref={menuRef}
      className={buildClassName(
        $classDropdown,
        props.size && `${$classDropdown}_${props.size}`,
        border && `${$classDropdown}_border`,
        open && `${$classDropdown}_open`,
        className,
      )}
      sx={sx}
    >
      <div
        className={buildClassName(
          `${$classDropdown}__port`,
          !selectedOption && `${$classDropdown}__port_placeholder`,
        )}
      >
        {icon && iconPlacement === 'start' && (
          <div className={buildClassName(`${$classDropdown}__icon`)} onClick={handleClickIcon}>
            {icon}
          </div>
        )}
        <input
          ref={inputRef}
          onFocus={handleFocus}
          value={selectedOption?.label ?? placeholder}
          disabled={!canEdit}
        />
        {icon && iconPlacement === 'end' && (
          <div className={buildClassName(`${$classDropdown}__icon`)} onClick={handleClickIcon}>
            {icon}
          </div>
        )}
      </div>

      {canEdit && (
        <DropdownMenu
          sx={{
            '& .MuiPaper-root': {
              borderTopLeftRadius: dropdownOpensDown ? 0 : undefined,
              borderTopRightRadius: dropdownOpensDown ? 0 : undefined,
              borderBottomLeftRadius: dropdownOpensUp ? 0 : undefined,
              borderBottomRightRadius: dropdownOpensUp ? 0 : undefined,
            },
            ...dropdownStyles,
          }}
          open={openDropdown}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          {...props}
          anchorOrigin={
            positioningOverride && verticalPositioning
              ? { vertical: verticalPositioning, horizontal: 'left' }
              : undefined
          }
          transformOrigin={
            positioningOverride && verticalPositioning
              ? { vertical: verticalPositioning, horizontal: 1 }
              : undefined
          }
          disableEscapeKeyDown={disableEscapeKeyDown}
        >
          {!dropdownOpensUp && (
            <DropdownMenuItemTop disabled className="menuItemTop">
              <Typography component="span" variant="inherit" color="inherit">
                {selectedOption?.label}
              </Typography>
            </DropdownMenuItemTop>
          )}
          {!dropdownOpensUp && dropdownIcon}
          {options.map((option, index) => {
            const isSelected = option.value === selectedOption?.value;

            return (
              <MenuItem
                autoFocus={index === 0 && !selectedOption ? true : false}
                key={option.label}
                selected={isSelected}
                onClick={handleItemClick(option)}
                onKeyDown={handleItemKeyDown(option)}
                onKeyUp={handleItemKeyUp}
                sx={{
                  gap: 1,
                  '&:first-of-type': {
                    mt: dropdownOpensUp ? 0.75 : undefined,
                  },
                }}
              >
                <Typography component="span" variant="inherit" color="inherit">
                  {option?.label}
                </Typography>
                {isSelected ? <DoneIcon /> : <Box sx={{ width: 15 }} />}
              </MenuItem>
            );
          })}
          {dropdownOpensUp && (
            <DropdownMenuItemTop
              disabled
              className="menuItemTop"
              sx={(theme) => ({
                borderTop: theme.mixins.borderControlValue(),
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottom: 'none',
                '&&&': {
                  margin: 0,
                },
              })}
            >
              <Typography component="span" variant="inherit" color="inherit">
                {selectedOption?.label}
              </Typography>
            </DropdownMenuItemTop>
          )}
          {dropdownOpensUp && dropdownIcon}
        </DropdownMenu>
      )}
    </Box>
  );
};
