import { AdultAssociation, Child, ProfileSearchResult } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useCallback, useEffect, useState } from 'react';

import { getParentChildren } from '../helpers/students';
import { getUserFullName } from '../helpers/users';
import IntlError from '../utils/intlError';
import useRequestWithProgress from './useRequestWithProgress';

export default function useChildrenEdit(
  parent: ProfileSearchResult | undefined,
  closeChildSelect: () => void,
  onEditComplete: () => void,
) {
  const { schoolId } = useAuth();
  const { showNotification } = useNotifications();

  const [childrenList, setChildrenList] = useState<AdultAssociation[]>([]);

  useEffect(() => {
    setChildrenList(parent ? getParentChildren(parent) : []);
  }, [parent]);

  const updateFamilyCallback = useCallback(async () => {
    if (!schoolId) {
      throw new IntlError('error-NoSchoolId');
    }

    if (!childrenList.length) {
      throw new IntlError('people-NoChildrenProvided');
    }

    if (!parent) {
      console.error('Inconsistent state: Parent profile not set');
      return;
    }

    closeChildSelect();

    // TODO: API action
    await Promise.resolve();

    showNotification({
      textId: 'confirmation-AddChildren',
      values: { userName: getUserFullName(parent) },
      type: 'success',
    });

    onEditComplete();
  }, [schoolId, childrenList, parent, closeChildSelect, onEditComplete, showNotification]);

  const [handleSubmit, isSubmitInProgress] = useRequestWithProgress(updateFamilyCallback);

  const handleSelectChild = useCallback((user: Child) => {
    setChildrenList((c) => [...c, user as unknown as AdultAssociation]);
  }, []);

  const handleDeleteChild = useCallback((childId: string) => {
    setChildrenList((c) => c.filter(({ user_id }) => user_id !== childId));
  }, []);

  return {
    childrenList,
    handleSelectChild,
    handleDeleteChild,
    handleSubmit,
    isSubmitInProgress,
  };
}
