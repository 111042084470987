import {
  SchoolReEnrollmentResponse,
  SchoolYear,
  StudentRegistration,
  UserType,
} from '@schooly/api';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { getRegistrationsByTimePeriods } from '../../../helpers/registrations';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { useRollover } from '../../../pages/Rollover/useRollover';
import { StudentRegistrationComponent } from './StudentRegistrationComponent';
import { getNextSchoolYear } from './utils';

interface ManageStudentRegistrationsProps {
  userType: UserType;
  registrations: StudentRegistration[];
  onEditRegistration?: (registrationId: string) => void;
  isEditable?: boolean;
  studentId: string;
  canRollover: boolean;
  refetchEnrollments: () => void;
  reEnrollmentData: SchoolReEnrollmentResponse;
}

export const ManageStudentRegistrations: React.FC<ManageStudentRegistrationsProps> = ({
  userType,
  registrations,
  onEditRegistration,
  studentId,
  canRollover,
  ...props
}) => {
  const [currentRegistrations, futureRegistrations, previousRegistrations] = useMemo(
    () => getRegistrationsByTimePeriods(registrations, userType),
    [userType, registrations],
  );
  const { schoolYears, defaultValidity } = useSchoolYears();
  const { navigateToRollover } = useRollover();
  const navigate = useNavigate();

  const prevSchoolYear = useMemo(
    () =>
      schoolYears.reduce((acc, year, i, arr) => {
        const currentYear = defaultValidity?.id === year.id;
        if (!currentYear) return acc;
        const prevYear = arr[i - 1];
        return prevYear ?? null;
      }, {} as SchoolYear | null),
    [defaultValidity?.id, schoolYears],
  );

  const handleRollover = (registration: StudentRegistration) => {
    const currentSchoolYear = schoolYears.find(({ id }) => registration.school_year.id === id);
    const nextSchoolYear = getNextSchoolYear(registration.school_year.id, schoolYears);
    if (!currentSchoolYear || !nextSchoolYear) return;

    navigateToRollover({
      student_ids: [studentId],
      year_from_id: currentSchoolYear.id,
      year_to_id: nextSchoolYear.id,
    });
  };

  return (
    <div className="ManageRegistrationsModal__list">
      {!registrations.length && (
        <p className="my-3 text-muted">
          <FormattedMessage id="enrollment-NoRegistrations" />
        </p>
      )}

      {!!futureRegistrations?.length && (
        <div className="mb-4">
          <h4 className="ManageRegistrationsModal__section-title h4 mb-2">
            <FormattedMessage id="employment-Future" />
          </h4>

          <div className="cards-wrapper">
            {futureRegistrations.map((enrollment) => {
              return (
                <StudentRegistrationComponent
                  key={enrollment.id}
                  enrollment={enrollment}
                  onClick={onEditRegistration}
                  canRollover
                  onRollover={handleRollover}
                  onShowFamily={() => navigate({ hash: 'family' })}
                  prevEnrollments={currentRegistrations ?? []}
                  {...props}
                />
              );
            })}
          </div>
        </div>
      )}

      {!!currentRegistrations?.length && (
        <div className="mb-4">
          <h4 className="ManageRegistrationsModal__section-title h4 mb-2">
            <FormattedMessage id="employment-Current" />
          </h4>

          <div className="cards-wrapper">
            {currentRegistrations.map((prevEnrollments) => {
              const hasNoFutureRegistration = !futureRegistrations?.length;
              return (
                <StudentRegistrationComponent
                  key={prevEnrollments.id}
                  enrollment={prevEnrollments}
                  onClick={onEditRegistration}
                  canRollover={hasNoFutureRegistration && canRollover}
                  onRollover={handleRollover}
                  onShowFamily={() => navigate({ hash: 'family' })}
                  prevEnrollments={previousRegistrations ?? []}
                  {...props}
                />
              );
            })}
          </div>
        </div>
      )}

      {!!previousRegistrations?.length && (
        <div className="mb-4">
          <h4 className="ManageRegistrationsModal__section-title h4 mb-2">
            <FormattedMessage id="employment-Previous" />
          </h4>

          <div className="cards-wrapper">
            {previousRegistrations.map((registration, i) => {
              const hasCurrentRegistration = currentRegistrations?.some((r) =>
                r.statuses.some((s) => !!s.school_property.category?.current),
              );
              const isPreviousYear = prevSchoolYear?.id === registration?.school_year.id;

              return (
                <StudentRegistrationComponent
                  key={registration.id}
                  enrollment={registration}
                  onClick={onEditRegistration}
                  canRollover={!hasCurrentRegistration && isPreviousYear && canRollover}
                  onRollover={handleRollover}
                  onShowFamily={() => navigate({ hash: 'family' })}
                  prevEnrollments={previousRegistrations.slice(i + 1)}
                  {...props}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
