import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import { format, isAfter, isBefore } from 'date-fns';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import { AnnualPlannerCreateForm } from '../../scheme';

export const AnnualPlannerCreateReport: FC = () => {
  const { $t } = useIntl();
  const { control } = useFormContext<AnnualPlannerCreateForm>();
  const { start, end } = useAnnualPlanner();

  return (
    <>
      <Controller
        control={control}
        name="date.0"
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          return (
            <DateSelect
              ref={field.ref}
              onSetDate={(date) => {
                field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
              }}
              date={field.value}
              requiredLabel="required"
              error={fieldState.error}
              CalendarProps={{
                shouldDisableDate: (value) => {
                  return Boolean((start && isBefore(value, start)) || (end && isAfter(value, end)));
                },
              }}
            />
          );
        }}
      />

      <ControlTextField
        name="report.name"
        control={control}
        label={$t({
          id: 'annualPlanner-Popover-Report-Name',
        })}
        rules={{
          required: true,
        }}
        fullWidth
      />
    </>
  );
};
