import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Divider } from '@mui/material';
import {
  BoldIcon,
  ItalicIcon,
  LinkIcon,
  ListOlIcon,
  ListUlIcon,
  UnderlineIcon,
} from '@schooly/style';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import buildClassName from '../../../../utils/buildClassName';
import { SimpleButton } from '../../../uikit/SimpleButton/SimpleButton';
import { AttachmentProps } from './Attachments/attachment-types';
import AttachmentButton from './Attachments/AttachmentButton';

import './index.scss';

interface IProps extends AttachmentProps {
  id: string;
  className?: string;
  title?: EmotionJSX.Element;
  withoutAttachments?: boolean;
}

/** @deprecated use @schooly/form-rich-text instead */
const Toolbar: React.FC<IProps> = ({
  id,
  className,
  title,
  withoutAttachments,
  ...attachmentProps
}) => (
  <div id={id} className={buildClassName('Toolbar', className)}>
    {title && title}
    <div className="Toolbar__section">
      <button type="button" className="Toolbar__button Toolbar__bold ql-bold">
        <BoldIcon />
      </button>
      <button type="button" className="Toolbar__button ql-italic">
        <ItalicIcon />
      </button>
      <button type="button" className="Toolbar__button ql-underline">
        <UnderlineIcon />
      </button>
    </div>
    <Divider orientation="vertical" />
    <div className="Toolbar__section">
      <button type="button" value="bullet" className="Toolbar__button ql-list">
        <ListOlIcon />
      </button>
      <button type="button" value="ordered" className="Toolbar__button Toolbar__bold ql-list">
        <ListUlIcon />
      </button>
    </div>
    <Divider orientation="vertical" />
    <div className="Toolbar__section">
      <SimpleButton
        className="Toolbar__button ql-link"
        sx={(theme) => ({
          ...theme.typography.body1,
          '.ql-snow.ql-toolbar &:hover, .ql-snow.ql-toolbar &:focus': {
            color: theme.palette.text.primary,
          },
        })}
        startIcon={<LinkIcon />}
      >
        <FormattedMessage id="link" />
      </SimpleButton>
    </div>
    <Divider orientation="vertical" />
    {!withoutAttachments && (
      <div className="Toolbar__section">
        <AttachmentButton {...attachmentProps} />
      </div>
    )}
  </div>
);

export default Toolbar;
