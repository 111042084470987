import { HeaderActions } from '@schooly/components/header-actions';
import { DATE_FORMAT } from '@schooly/constants';
import { CheckIcon, CrossIcon, PublishIcon } from '@schooly/style';
import { isNotFalse } from '@schooly/utils/predicates';
import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useMessage } from '../../../context/messages/useMessage';
import { MESSAGE_STATUSES } from './StatusLabel';

export const MessageModalPreviewActions: FC = () => {
  const {
    canPublish,
    canSubmit,
    canUnsubmit,
    isPublishInProgress,
    isSubmitInProgress,
    isUnsubmitInProgress,
    isPublished,
    isSubmitted,
    actions,
    status,
    publishDate,
  } = useMessage();

  const { $t } = useIntl();
  const isLoading = isPublishInProgress || isSubmitInProgress || isUnsubmitInProgress;

  const title = useMemo(() => {
    if (typeof status === 'undefined') {
      return '';
    }

    if (isPublished && publishDate) {
      return `${$t({ id: 'status-Published' })}${' '} ${format(publishDate, DATE_FORMAT)}`;
    }

    return $t({ id: `options-message-status-${MESSAGE_STATUSES[status]}` });
  }, [$t, isPublished, publishDate, status]);

  const selectActions = useMemo(() => {
    return [
      canSubmit && {
        titleTextId: 'messages-Submit',
        handler: async () => {
          await actions.submitMessage();
          return true;
        },
        icon: <CheckIcon />,
        pendingTitle: $t({ id: 'submitting' }),
      },
      canUnsubmit && {
        titleTextId: 'messages-Unsubmit',
        handler: async () => {
          await actions.unsubmitMessage();
          return true;
        },
        icon: <CrossIcon />,
        pendingTitle: $t({ id: 'unSubmitting' }),
      },
      canPublish && {
        titleTextId: 'messages-Publish',
        handler: async () => {
          await actions.publishMessage();
          return true;
        },
        icon: <PublishIcon className="reset-svg-currentColor" />,
        pendingTitle: $t({ id: 'publishing' }),
      },
    ].filter(isNotFalse);
  }, [$t, actions, canPublish, canSubmit, canUnsubmit]);

  const styleType = useMemo(() => {
    if (isPublished) return 'success';
    if (isSubmitted) return 'warning';
    return undefined;
  }, [isPublished, isSubmitted]);

  return (
    <HeaderActions
      title={title}
      actions={selectActions}
      styleType={styleType}
      pending={isLoading}
      disabled={isPublished || !selectActions.length}
    />
  );
};
