import { FilterKeys } from '@schooly/api';
import { PROPERTIES_TEXT_IDS } from '@schooly/constants';
import { MoreIcon, StaffIcon } from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FilterType } from '../../../../context/filters/scheme';
import { POPUP_OPEN_DELAY_MS, useFilters } from '../../../../context/filters/useFilters';
import { getUserTypeTextId } from '../../../../helpers/misc';
import { MIN_REMOTE_QUERY_LENGTH } from '../../../../hooks/usePagedApiResourceWithFilter';
import addHighlight from '../../../../utils/addHighlight';
import buildClassName from '../../../../utils/buildClassName';
import { FILTER_MODE } from '../utils';
import { HeaderSearchPeopleList } from './HeaderSearchPeopleList';
import { HeaderSearchUserRoleList } from './HeaderSearchUserRoleList';

export interface IHeaderFilterSearchPopupProps {
  isOpen?: boolean;
  closePopup?: () => void;
  setMode?: (value: FILTER_MODE) => void;
  setOpenFilter?: (key?: FilterKeys) => void;
  customSearchContent?: React.ReactNode;
}

export const HeaderFilterSearchPopup: FC<IHeaderFilterSearchPopupProps> = ({
  isOpen,
  setMode,
  setOpenFilter,
  customSearchContent,
}) => {
  const { formatMessage } = useIntl();
  const { filters, filterType, searchSuggestions, appendDraftValue, destructGroupFilterKey } =
    useFilters();

  const popupClassName = buildClassName(
    'HeaderFilter__filter-popup',
    'HeaderFilter__filter-popup_dropdown',
    'HeaderFilter__search-popup',
    'dropdown-menu',
    'shadow-sm',
    'custom-scrollbar',
    isOpen && 'show',
  );

  const suggestions = useMemo(
    () =>
      filters.draftQuery.length >= MIN_REMOTE_QUERY_LENGTH
        ? searchSuggestions(filters.draftQuery)
        : [],
    [filters.draftQuery, searchSuggestions],
  );

  const renderContent = useCallback(() => {
    if (filters.draftQuery.length < MIN_REMOTE_QUERY_LENGTH) {
      return (
        <h3 className="no-results">
          <MoreIcon className="icon_more_vert" />{' '}
          <FormattedMessage
            id="typeAtLeastCharToSearch"
            values={{ number: MIN_REMOTE_QUERY_LENGTH }}
          />
        </h3>
      );
    }

    return (
      <>
        {suggestions.length ? (
          <div className="filters-section">
            {suggestions.map((section) => {
              const { userType } = destructGroupFilterKey(section.key) ?? {};
              const textId = formatMessage({ id: PROPERTIES_TEXT_IDS[section.key] });
              const typedTextId = userType && formatMessage({ id: getUserTypeTextId(userType) });

              return (
                <div key={section.key} className="mb-3">
                  <h4 className="mb-2">{typedTextId ? `${typedTextId} ${textId}` : textId}</h4>
                  {section.options.map(({ label, value }) => (
                    <div
                      className="h3 filter-option cursor-pointer mb-1"
                      onClick={() => {
                        appendDraftValue(section.key, value);
                        setOpenFilter?.(undefined);
                        setMode?.(FILTER_MODE.FILTER);

                        setTimeout(() => {
                          setOpenFilter?.(section.key);
                        }, POPUP_OPEN_DELAY_MS);
                      }}
                      role="button"
                      tabIndex={0}
                      key={value}
                    >
                      {userType === 'staff' && <StaffIcon className="mr-1" />}{' '}
                      {addHighlight(label, filters.draftQuery)}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        ) : null}
        {filterType === FilterType.Parent && (
          <HeaderSearchPeopleList isOptionsResultEmpty={suggestions.length < 1} />
        )}

        {filterType === FilterType.UserRoles && <HeaderSearchUserRoleList />}
        {filterType === FilterType.Applications && (customSearchContent ?? null)}
      </>
    );
  }, [
    appendDraftValue,
    destructGroupFilterKey,
    filterType,
    filters.draftQuery,
    formatMessage,
    customSearchContent,
    setMode,
    setOpenFilter,
    suggestions,
  ]);

  return <div className={popupClassName}>{renderContent()}</div>;
};
