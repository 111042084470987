import { Stack, Typography } from '@mui/material';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { SubjectTagSelect, SubjectTagSelectProps } from './SubjectTagSelect';

type SubjectExpandedSelectProps = PropsWithChildren<{
  schoolId: string;
  selectedIds: string[];
  onSelectSubjectId: (v: string) => void;
  onClear: () => void;
  onClose: () => void;
}>;

export const SubjectExpandedSelect: FC<SubjectExpandedSelectProps> = ({
  schoolId,
  selectedIds,
  onSelectSubjectId,
  onClose,
  onClear,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');

  const { activeSubjects, archivedSubjects, isLoading } = useSubjects(
    { schoolId },
    { refetchOnMount: 'always' },
  );

  const renderContent = useCallback(() => {
    if (isLoading) return <SelectContentSkeleton />;

    const result = activeSubjects.filter((s) => s.name.toLowerCase().includes(query.toLowerCase()));

    const resultArchived = archivedSubjects.filter((s) =>
      s.name.toLowerCase().includes(query.toLowerCase()),
    );

    if (!result.length && !resultArchived.length) {
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );
    }

    return (
      <>
        <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
          {renderSubjectTags({
            ids: result.map((s) => s.id),
            onClick: (id) => {
              onSelectSubjectId(id);
              setQuery('');
            },
            tagProps: (id) => ({
              variant: selectedIds?.includes(id) ? 'filled' : undefined,
              schoolId,
            }),
          })}
        </Stack>

        {resultArchived.length > 0 && (
          <>
            <Typography variant="h4" mx={1} mt={1.25}>
              <FormattedMessage id="filter-Archived" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
              {renderSubjectTags({
                ids: resultArchived.map((s) => s.id),
                onClick: (id) => {
                  onSelectSubjectId(id);
                  setQuery('');
                },
                tagProps: (id) => ({
                  variant: selectedIds?.includes(id) ? 'filled' : undefined,
                  schoolId,
                }),
              })}
            </Stack>
          </>
        )}
      </>
    );
  }, [
    activeSubjects,
    archivedSubjects,
    isLoading,
    onSelectSubjectId,
    query,
    schoolId,
    selectedIds,
  ]);

  return (
    <ExpandedSelect
      onClickInputArea={() => inputRef.current?.focus()}
      hasSelectedValue={selectedIds.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={renderContent}
    >
      {renderSubjectTags({
        ids: selectedIds,
        onDelete: onSelectSubjectId,
        tagProps: {
          schoolId,
          size: 'small',
        },
      })}

      <SelectSearchInput ref={inputRef} autoFocus value={query} onChangeText={setQuery} />
    </ExpandedSelect>
  );
};

type RenderSubjectTagsParams = {
  ids: string[];
  onDelete?: (v: string) => void;
  onClick?: (v: string) => void;
  tagProps: Omit<SubjectTagSelectProps, 'id'> | ((v: string) => Omit<SubjectTagSelectProps, 'id'>);
};

export const renderSubjectTags = ({ ids, onDelete, onClick, tagProps }: RenderSubjectTagsParams) =>
  ids.map((v) => (
    <SubjectTagSelect
      key={v}
      id={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
