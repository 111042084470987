import { SxProps } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { theme } from '@schooly/style';
import {
  createContext,
  FC,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

const HOVER_CLASS_NAME = 'hover';
const SELF_HOVER_CLASS_NAME = 'selfHover';

interface DataGridHoverContextProps {
  styles: SxProps;
  onCellMouseOver: (props: OnCellMouseOverProps) => void;
  onColumnMouseOver: (props: OnColumnMouseOverProps) => void;
  onMouseLeave: () => void;
}

export const DataGridHoverContext = createContext<DataGridHoverContextProps>({
  styles: {},
  onCellMouseOver: () => {},
  onColumnMouseOver: () => {},
  onMouseLeave: () => {},
});

interface WithDataGridHoverHoverProps extends PropsWithChildren {
  hoverColor?: string;
  columnsWithXHover?: GridRenderCellParams['field'][];
}

interface OnCellMouseOverProps {
  rowId: GridRenderCellParams['id'];
  colField: GridRenderCellParams['field'];
  columns: GridColDef[];
  rows: GridValidRowModel[];
  headerCellSelector?: string;
  getCellElement: (id: GridRowId, field: string) => HTMLDivElement | null;
  getColumnIndex: (field: string) => number;
  getRowIndex: (id: GridRowId) => number;
  event: MouseEvent;
}

export interface OnColumnMouseOverProps {
  colFields: GridRenderCellParams['field'][];
  getCellElement: (id: GridRowId, field: string) => HTMLDivElement | null;
  rows: GridValidRowModel[];
  event: MouseEvent<HTMLElement>;
  cellSelector?: string;
}

export const WithDataGridHover: FC<WithDataGridHoverHoverProps> = ({
  hoverColor,
  children,
  columnsWithXHover,
}) => {
  const hoveredElementsRef = useRef<(Element | null)[] | null>(null);

  const onMouseLeave = useCallback(() => {
    if (hoveredElementsRef.current) {
      hoveredElementsRef.current.forEach((e) => {
        if (e) {
          e.classList.remove(SELF_HOVER_CLASS_NAME);
          e.classList.remove(HOVER_CLASS_NAME);
        }
      });
      hoveredElementsRef.current = null;
    }
  }, []);

  const onColumnMouseOver = useCallback(
    ({
      event,
      colFields,
      rows,
      cellSelector = '.MuiDataGrid-columnHeader',
      getCellElement,
    }: OnColumnMouseOverProps) => {
      if (hoveredElementsRef.current) {
        onMouseLeave();
      }

      const currentCell = event.currentTarget.closest(cellSelector);

      if (!currentCell) return;
      currentCell.classList.add(HOVER_CLASS_NAME);

      const cells = colFields.flatMap((field) => rows.map((row) => getCellElement(row.id, field)));
      cells.forEach((cell) => {
        cell?.classList.add(HOVER_CLASS_NAME);
      });

      hoveredElementsRef.current = [currentCell, ...cells];
    },
    [onMouseLeave],
  );

  const onCellMouseOver = useCallback(
    ({
      event,
      rowId,
      colField,
      columns,
      rows,
      headerCellSelector = '.MuiDataGrid-columnHeader',
      getCellElement,
      getColumnIndex,
      getRowIndex,
    }: OnCellMouseOverProps) => {
      //When event is triggered by popover open
      if (event.target instanceof Element && event.target.closest('.MuiPopover-root')) {
        return;
      }

      if (hoveredElementsRef.current) {
        onMouseLeave();
      }

      const currentCell = getCellElement(rowId, colField);

      if (!currentCell) return;

      const colIndex = getColumnIndex(colField);
      const rowIndex = getRowIndex(rowId);

      const cellsInRow = columns.map((column) => getCellElement(rowId, column.field));
      const prevCellsInRow = cellsInRow.slice(0, colIndex);

      if (colIndex === 0 || columnsWithXHover?.includes(colField)) {
        cellsInRow.forEach((cell) => {
          cell?.classList.add(HOVER_CLASS_NAME);
        });
        hoveredElementsRef.current = cellsInRow;
      } else {
        const cellsInCol = rows.map((row) => getCellElement(row.id, colField));
        const prevCellsInCol = cellsInCol.slice(0, rowIndex);

        const headerCells = Array.from<HTMLElement>(document.querySelectorAll(headerCellSelector));
        const currentHeaderCell = headerCells.find(
          (cell) =>
            cell.getAttribute('data-fields')?.includes(colField) ||
            cell.getAttribute('data-field') === colField,
        );

        prevCellsInRow.forEach((cell) => {
          cell?.classList.add(HOVER_CLASS_NAME);
        });
        prevCellsInCol.forEach((cell) => {
          cell?.classList.add(HOVER_CLASS_NAME);
        });
        currentHeaderCell?.classList.add(HOVER_CLASS_NAME);
        currentCell?.classList.add(SELF_HOVER_CLASS_NAME);

        hoveredElementsRef.current = [
          ...prevCellsInRow,
          ...prevCellsInCol,
          currentHeaderCell ?? null,
          currentCell,
        ];
      }
    },
    [columnsWithXHover, onMouseLeave],
  );

  useEffect(
    () => () => {
      onMouseLeave();
    },
    [onMouseLeave],
  );

  const styles = {
    [`.${HOVER_CLASS_NAME}`]: {
      backgroundColor: `${hoverColor ? hoverColor : theme.palette.background.default} !important`,
      'div, p, th, a, &.MuiTableCell-root': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    [`.${SELF_HOVER_CLASS_NAME}`]: {
      backgroundColor: `${theme.palette.common.grey7} !important`,
      'div, p, th, a, &.MuiTableCell-root': {
        color: theme.palette.primary.main,
      },
    },
  };

  const value = {
    styles,
    onCellMouseOver,
    onColumnMouseOver,
    onMouseLeave,
  };

  return <DataGridHoverContext.Provider value={value}>{children}</DataGridHoverContext.Provider>;
};

export const useDataGridHover = () => {
  return useContext(DataGridHoverContext);
};
