import { Box, Icon, IconButton, IconButtonProps, Stack, styled, Typography } from '@mui/material';
import {
  CalendarIcon,
  ChevronUpIcon,
  PlusIcon,
  SimpleButton,
  SimpleButtonProps,
} from '@schooly/style';
import { ChevronLeftIcon, ChevronRightIcon } from '@schooly/style';
import { MAX_CONSISTENT_YEAR, MIN_CONSISTENT_YEAR } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const EmptyComp = () => <></>;
export const noop = () => {};

export const emptySlots = {
  leftArrowIcon: EmptyComp,
  nextIconButton: EmptyComp,
  previousIconButton: EmptyComp,
  rightArrowIcon: EmptyComp,
  calendarHeader: EmptyComp,
  switchViewButton: EmptyComp,
  switchViewIcon: EmptyComp,
} as const;

export const views = ['day'] as const;

type ArrowButtonProps = { isLeft?: boolean; isDouble?: boolean } & IconButtonProps;
export const ArrowButton: FC<ArrowButtonProps> = ({ isDouble, isLeft, ...rest }) => {
  const Icon = isLeft ? ChevronLeftIcon : ChevronRightIcon;

  return (
    <IconButton
      sx={(theme) => ({
        '.svg-icon + .svg-icon': {
          marginLeft: theme.spacing(-1.5),
        },
        color: theme.palette.common.grey,
        '&:hover': {
          color: theme.palette.text.primary,
        },
      })}
      {...rest}
    >
      <Icon />
      {isDouble && <Icon />}
    </IconButton>
  );
};

type DatePickerStyledContainerProps = {
  minHeight?: string;
};

export const DatePickerStyledContainer = styled(Stack)<DatePickerStyledContainerProps>(
  ({ theme, minHeight = 205 }) => ({
    flexDirection: 'row',
    alignItems: 'flex-start',

    '.MuiPickersLayout-root': {
      minHeight: 'unset',
      minWidth: 'unset',

      '.MuiPickersSlideTransition-root': {
        minHeight: minHeight,
      },

      '.MuiDateCalendar-root': {
        height: 'unset',
        width: 'unset',
        minHeight: minHeight,
      },

      '.MuiDayCalendar-weekDayLabel': {
        fontWeight: 'bold',
        width: theme.spacing(3.5),
        height: theme.spacing(3.75),
        marginLeft: 0,
        marginRight: 0,
      },
    },
  }),
);

type DateCalendarHeaderProps = {
  onNext?: () => void;
  onPrev?: () => void;
  onToggle?: () => void;
  isOpened?: boolean;
  date: Date;
};
export const DateCalendarHeader: FC<DateCalendarHeaderProps> = ({
  date,
  isOpened,
  onNext,
  onPrev,
  onToggle,
}) => {
  return (
    <Stack
      px={1.5}
      pt={2}
      flexDirection="row"
      alignSelf="stretch"
      justifyContent="space-between"
      alignItems="flex-start"
      position="relative"
    >
      {onPrev && (
        <Box mr={1}>
          <ArrowButton isLeft onClick={onPrev} />
        </Box>
      )}

      <Stack
        onClick={onToggle}
        sx={(theme) => ({
          flex: 1,
          pb: 0.25,
          pl: 1,
          flexDirection: 'row',
          mb: 1,
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          gap: theme.spacing(1),
          '.MuiIcon-root, .MuiTypography-root': {
            transition: 'all .2s',
          },
          '&:hover': {
            cursor: 'pointer',

            '.MuiIcon-root': {
              color: theme.palette.common.grey2,
            },
            '.MuiTypography-root': {
              color: theme.palette.primary.main,
            },
          },
        })}
      >
        <Typography my={0.1} color="text.primary">
          {format(date, 'LLLL yyyy')}
        </Typography>
        <Icon
          sx={(theme) => ({
            fontSize: theme.spacing(1.25),
            color: theme.palette.common.grey,
            transform: `rotate(${isOpened ? 0 : 180}deg)`,
          })}
        >
          <ChevronUpIcon />
        </Icon>
      </Stack>
      {onNext && (
        <Box ml={1}>
          <ArrowButton onClick={onNext} />
        </Box>
      )}
    </Stack>
  );
};

enum YearMonthState {
  YearSelect = 'year',
  MonthSelect = 'month',
}

type DatePickerYearMonthSelectProps = {
  dateToShow: Date;
  onSetDateToShow: (v: Date) => void;
  onClose: () => void;
};

export const DatePickerYearMonthSelect: FC<DatePickerYearMonthSelectProps> = ({
  dateToShow: initialDate,
  onSetDateToShow,
  onClose,
}) => {
  const [dateToShow, setDate] = useState(initialDate);
  const [state, setState] = useState<YearMonthState>(YearMonthState.YearSelect);

  const handleSetYear = (year: number) => {
    const date = new Date(dateToShow);
    date.setFullYear(year);
    setDate(date);
    setState(YearMonthState.MonthSelect);
  };

  const handleMoveYear = (v: 'next' | 'prev') => () => {
    const date = new Date(dateToShow);
    const nextYear = dateToShow.getFullYear() + 12 * (v === 'prev' ? -1 : 1);

    date.setFullYear(Math.max(Math.min(nextYear, MAX_CONSISTENT_YEAR), MIN_CONSISTENT_YEAR + 5));
    setDate(date);
  };

  const handleSetMonth = (month: number) => {
    const date = new Date(dateToShow);
    date.setMonth(month);
    onSetDateToShow(date);
    onClose();
  };

  const selectedYear = dateToShow.getFullYear();

  const renderContent = () => {
    switch (state) {
      case YearMonthState.YearSelect:
        return (
          <>
            {[...new Array(12)].map((_, i) => (
              <YearMonthCard
                key={'y_' + selectedYear + i}
                onClick={() => handleSetYear(selectedYear + i + 1 - 5)}
              >
                <Typography textAlign="center" color="text.primary">
                  {format(new Date().setFullYear(selectedYear + i + 1 - 5), 'yyyy')}
                </Typography>
              </YearMonthCard>
            ))}
          </>
        );
      case YearMonthState.MonthSelect:
        return (
          <>
            {[...new Array(12)].map((_, i) => (
              <YearMonthCard key={'m_' + i} onClick={() => handleSetMonth(i)}>
                <Typography textAlign="center" color="text.primary">
                  {format(new Date().setMonth(i, 1), 'MMM')}
                </Typography>
              </YearMonthCard>
            ))}
          </>
        );
    }
  };

  return (
    <Stack
      sx={(theme) => ({
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        alignItems: 'center',
      })}
    >
      <DateCalendarHeader
        onNext={state === YearMonthState.YearSelect ? handleMoveYear('next') : undefined}
        onPrev={state === YearMonthState.YearSelect ? handleMoveYear('prev') : undefined}
        date={initialDate}
        onToggle={onClose}
        isOpened
      />
      <Stack justifyContent="center" alignItems="center" flex={1}>
        <Stack
          sx={(theme) => ({
            maxWidth: 200,
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: theme.spacing(1),
            mb: 2,
          })}
        >
          {renderContent()}
        </Stack>
      </Stack>
    </Stack>
  );
};

const YearMonthCard = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.spacing(0.5),
  cursor: 'pointer',
  minWidth: 60,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const TodayButton: FC<SimpleButtonProps> = ({ ...rest }) => {
  const { formatMessage } = useIntl();

  return (
    <Stack alignItems="center" p={2}>
      <SimpleButton
        inverse
        data-test-id="date-select-button-today"
        startIcon={<CalendarIcon />}
        {...rest}
      >
        <Typography>{formatMessage({ id: 'datepicker-Today' })}</Typography>
      </SimpleButton>
    </Stack>
  );
};

export const NoPeriodLabel: FC<{ hasManagePermission: boolean; yearId: string }> = ({
  hasManagePermission,
  yearId,
}) => {
  const navigate = useNavigate();

  return hasManagePermission ? (
    <Stack gap={1.25}>
      <FormattedMessage id="school-schoolPeriods-NoPeriodsWithPermissions" />
      <SimpleButton
        startIcon={<PlusIcon />}
        onClick={() => {
          navigate(`/settings/school_periods/${yearId}`);
        }}
        sx={{
          alignSelf: 'flex-start',
        }}
      >
        <Typography>
          <FormattedMessage id="school-schoolPeriods-AddSchoolPeriods" />
        </Typography>
      </SimpleButton>
    </Stack>
  ) : (
    <FormattedMessage id="school-schoolPeriods-NoPeriodsWithoutPermissions" />
  );
};
