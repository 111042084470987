import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7C11.6569 7 13 5.65685 13 4C13 2.34315 11.6569 1 10 1C8.34315 1 7 2.34315 7 4C7 5.65685 8.34315 7 10 7ZM1 17C1 12.0294 5.02944 8 10 8C14.9706 8 19 12.0294 19 17V18.8911C19 18.9512 18.9512 19 18.8911 19H14C14 16.7909 12.2091 15 10 15C7.79086 15 6 16.7909 6 19H1.10891C1.04876 19 1 18.9512 1 18.8911V17ZM10 14C11.1046 14 12 13.1046 12 12C12 10.8954 11.1046 10 10 10C8.89543 10 8 10.8954 8 12C8 13.1046 8.89543 14 10 14Z"
      fill="#B3BBCB"
    />
  </svg>
);
