import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Stack, Tooltip } from '@mui/material';
import {
  FilterKeys,
  FilterSelectOption,
  FilterValue,
  GroupOptions,
  GroupUserType,
} from '@schooly/api';
import { ArchiveIcon, StaffIcon } from '@schooly/style';
import React, { Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getUserTypeTextId } from '../../../helpers/misc';
import buildClassName from '../../../utils/buildClassName';
import getTypedObjectKeys from '../../../utils/getTypedObjectKeys';
import searchWords from '../../../utils/searchWords';
import Tag from '../../ui/Tag';

interface FilterSelectPopupContentProps {
  searchQuery: string;
  filterKey: FilterKeys;
  options?: FilterSelectOption<FilterValue>[];
  groupOptions?: GroupOptions;
  hasNoneOption?: boolean;
  popupTitleTextId?: string;
  isOptionActive?: (option: FilterSelectOption<FilterValue>, userType?: GroupUserType) => boolean;
  handleSelectOption: (option?: FilterSelectOption<FilterValue>, userType?: GroupUserType) => void;
  handleRemoveOption: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    option?: FilterSelectOption<FilterValue>,
    userType?: GroupUserType,
  ) => void;
  noneOption: FilterSelectOption<FilterValue>;
  emptyLayoutNode?: EmotionJSX.Element;
}

const FilterSelectPopupContent = ({
  searchQuery,
  filterKey,
  options = [],
  groupOptions,
  hasNoneOption,
  isOptionActive,
  popupTitleTextId,
  handleSelectOption,
  handleRemoveOption,
  noneOption,
  emptyLayoutNode,
}: FilterSelectPopupContentProps) => {
  const { $t } = useIntl();

  const getOptionText = useCallback(
    (option: FilterSelectOption<FilterValue>) =>
      option.labelTextId ? $t({ id: option.labelTextId }) : option.label,
    [$t],
  );

  const onSelectOption = useCallback(
    (userType?: GroupUserType) =>
      (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        selectOption?: FilterSelectOption<FilterValue>,
      ) => {
        const isActive = isOptionActive && selectOption && isOptionActive(selectOption, userType);

        if (isActive) {
          handleRemoveOption(e, selectOption, userType);
        } else {
          handleSelectOption(selectOption, userType);
        }
      },
    [handleSelectOption, handleRemoveOption, isOptionActive],
  );

  const displayedOptions = useMemo(() => {
    const items =
      !options || (options.length < 1 && groupOptions && Object.keys(groupOptions).length > 0)
        ? Object.values(groupOptions ?? {})[0]
        : options;

    const filteredItems = searchQuery
      ? items.filter((o) => {
          const text = getOptionText(o);
          return text && searchWords(text, searchQuery);
        })
      : items;

    return filteredItems.reduce<{ active: FilterSelectOption[]; archived: FilterSelectOption[] }>(
      (prev, o) => {
        if (o.archived) {
          prev.archived.push(o);
        } else {
          prev.active.push(o);
        }

        return prev;
      },
      {
        active: [],
        archived: [],
      },
    );
  }, [options, groupOptions, searchQuery, getOptionText]);

  const displayedGroupOptions = useMemo(() => {
    if (!groupOptions) {
      return undefined;
    }

    if (!searchQuery) {
      return groupOptions;
    }

    const finalOptions: GroupOptions = {};

    getTypedObjectKeys(groupOptions).forEach((userType) => {
      const filteredOptions = groupOptions[userType]?.filter((o) => {
        const text = getOptionText(o);
        return text && searchWords(text, searchQuery);
      });

      if (filteredOptions && !!filteredOptions.length) {
        finalOptions[userType] = filteredOptions;
      }
    });

    if (!Object.keys(finalOptions).length) {
      return undefined;
    }

    return finalOptions;
  }, [groupOptions, searchQuery, getOptionText]);

  const filteredDisplayedGroupOptions = useMemo(
    () =>
      getTypedObjectKeys(displayedGroupOptions || {}).filter(
        (userType) => displayedGroupOptions![userType]?.length,
      ),
    [displayedGroupOptions],
  );

  if (searchQuery && !displayedOptions.active.length && !displayedOptions.archived.length) {
    return (
      <div className="mb-2">
        <span className="dropdown-item form-select-option form-select-no-options-found">
          <FormattedMessage id="input-NoOptionsFound" />
        </span>
      </div>
    );
  }

  if (
    !displayedOptions.active.length &&
    !displayedOptions.archived.length &&
    emptyLayoutNode &&
    !searchQuery
  ) {
    return emptyLayoutNode;
  }

  const renderNoneOption = (className?: string, userType?: GroupUserType) => {
    const isActive = isOptionActive && isOptionActive(noneOption, userType);

    return (
      <li key={noneOption.value} className={className}>
        <Tag
          selectOption={noneOption}
          className={isActive ? 'active' : undefined}
          onClick={onSelectOption(userType)}
          isBig
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            sx={{ '& svg': { flex: '0 0 auto' } }}
          >
            {userType === 'staff' && <StaffIcon />} {getOptionText(noneOption)}
          </Stack>
        </Tag>
      </li>
    );
  };

  const renderFilterOptions = (
    filterOptions: FilterSelectOption<FilterValue>[],
    userType?: GroupUserType,
  ) =>
    filterOptions.map((option) => {
      const isActive = isOptionActive && isOptionActive(option, userType);

      return (
        <li key={option.value}>
          <Tag
            selectOption={option}
            className={isActive ? 'active' : undefined}
            onClick={onSelectOption(userType)}
            isBig
          >
            {option.archived ? (
              <Tooltip title={$t({ id: `schoolProperty-Archived-${filterKey}` })}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={0.5}
                  sx={{ '& svg': { flex: '0 0 auto' } }}
                >
                  <ArchiveIcon />
                  {userType === 'staff' && <StaffIcon />} {getOptionText(option)}
                </Stack>
              </Tooltip>
            ) : (
              <Stack direction="row" alignItems="center" gap={0.5}>
                {userType === 'staff' && <StaffIcon />}
                {getOptionText(option)}
              </Stack>
            )}
          </Tag>
        </li>
      );
    });

  return (
    <>
      {groupOptions ? (
        filteredDisplayedGroupOptions.map((userType, userTypeIndex, userTypes) => {
          const titleClassName = 'filter-select-title pr-0 pl-0';

          if (!displayedGroupOptions) return null;

          if (userTypes.length === 1) {
            return (
              <Fragment key={userType}>
                {!searchQuery && hasNoneOption && renderNoneOption(undefined, userType)}
                {popupTitleTextId && (
                  <h4 className="h4 filter-select-title">
                    <FormattedMessage id={popupTitleTextId} />
                  </h4>
                )}
                {renderFilterOptions(displayedOptions.active, userType)}

                {displayedOptions.archived && (
                  <>
                    <h4 className="h4 filter-select-title">
                      <FormattedMessage id="filter-Archived" />
                    </h4>
                    {renderFilterOptions(displayedOptions.archived, userType)}
                  </>
                )}
              </Fragment>
            );
          }

          return (
            <Fragment key={userType}>
              <h4 className={buildClassName('h4', titleClassName)}>
                <FormattedMessage id={getUserTypeTextId(userType)} />
              </h4>
              {!searchQuery &&
                hasNoneOption &&
                renderNoneOption(buildClassName(titleClassName, 'pb-1'), userType)}
              {renderFilterOptions(displayedGroupOptions[userType]!, userType)}
            </Fragment>
          );
        })
      ) : (
        <>
          {!searchQuery && hasNoneOption && renderNoneOption()}
          {popupTitleTextId && (
            <h4 className="h4 filter-select-title">
              <FormattedMessage id={popupTitleTextId} />
            </h4>
          )}
          {renderFilterOptions(displayedOptions.active)}

          {displayedOptions.archived.length > 0 && (
            <>
              <h4 className="h4 filter-select-title">
                <FormattedMessage id="filter-Archived" />
              </h4>
              {renderFilterOptions(displayedOptions.archived)}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FilterSelectPopupContent;
