import { Group, useGetAssessmentsGradesForSchoolQuery } from '@schooly/api';
import {
  AssessmentGroup,
  AssessmentMethod,
  AssessmentMethodBlank,
  AssessmentMethodComment,
  AssessmentMethodGrade,
  AssessmentMethodScore,
  AssessmentMethodType,
  AssessmentsGrade,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { AppLocation } from '../../../hooks/useAppLocation';
import { useAssessmentActions, UseAssessmentActionsReturn } from './useAssessmentActions';

export enum AssessmentsCreateModalMode {
  Settings,
  Groups,
}

export interface AssessmentCreateLocation extends AppLocation {
  state: AppLocation['state'] & {
    selectedGroups?: Group[] | AssessmentGroup[];
  };
}

export const CONTEXT_NAME = 'AssessmentCreateModal';

export const SCORE_OUT_OF_MAX = 999;

export const getDefaultAssessmentMethod = (methodType: AssessmentMethodType) => {
  switch (methodType) {
    case AssessmentMethodType.Score:
      return { method_type: methodType, score_out_of: null } as AssessmentMethodScore;
    case AssessmentMethodType.Grade:
      return {
        method_type: methodType,
        select_list_id: '',
        select_list_name: '',
      } as AssessmentMethodGrade;
    case AssessmentMethodType.Comment:
      return { method_type: methodType } as AssessmentMethodComment;
  }
};

export interface AssessmentsCreateModalContextState {
  showDisplayNameField?: boolean;
  methods?: (AssessmentMethod | AssessmentMethodBlank)[];
  mode: AssessmentsCreateModalMode;
  autoFocus?: string;
  selectLists?: AssessmentsGrade[];
  assessmentId?: string;
  relationIds: string[];
  actions: UseAssessmentActionsReturn & {
    setMode: (mode: AssessmentsCreateModalMode, autoFocus?: string) => void;
    clearFocusField: () => void;
  };
}

export const AssessmentCreateContext = createContext<AssessmentsCreateModalContextState>({
  mode: AssessmentsCreateModalMode.Settings,
  autoFocus: undefined,
  selectLists: undefined,
  relationIds: [],
  actions: {
    saving: false,
    deleting: false,
    setMode: () => undefined,
    createAssessment: async () => {},
    deleteAssessment: async () => undefined,
    updateAssessment: async () => undefined,
    handleClose: () => undefined,
    handlePreview: async () => undefined,
    clearFocusField: () => undefined,
  },
});

export interface WithAssessmentCreateProps {
  id?: string;
  onClose?: () => void;
  onPreview?: (id: string) => void;
}

export const WithAssessmentCreate: FC<PropsWithChildren<WithAssessmentCreateProps>> = ({
  id: propId,
  onClose,
  onPreview,
  children,
}) => {
  const { id: paramId } = useParams<'id'>();
  const { schoolId, permissions, relationId } = useAuth();
  const [autofocus, setAutoFocus] = useState('');
  const [modalMode, setModalMode] = useState<AssessmentsCreateModalMode>(
    AssessmentsCreateModalMode.Settings,
  );

  const assessmentId = propId ?? paramId;

  const {
    saving,
    deleting,
    handleClose,
    handlePreview,
    createAssessment,
    updateAssessment,
    deleteAssessment,
  } = useAssessmentActions({
    onClose,
    onPreview,
  });

  const { data: selectLists, isFetching: gradesFetching } = useGetAssessmentsGradesForSchoolQuery(
    { schoolId },
    {
      enabled: Boolean(schoolId),
    },
  );

  const relationIds = useMemo(() => {
    if (permissions.includes('assessment_manager')) {
      return [];
    }

    return relationId ? [relationId] : [];
  }, [permissions, relationId]);

  const setMode = useCallback((mode: AssessmentsCreateModalMode, autoFocus?: string) => {
    setModalMode(mode);
    if (autoFocus) setAutoFocus(autoFocus);
  }, []);

  const clearFocusField = () => {
    setAutoFocus('');
  };

  const values = {
    assessmentId,
    fetching: gradesFetching,
    mode: modalMode,
    autoFocus: autofocus,
    selectLists,
    relationIds,
    actions: {
      saving,
      deleting,
      setMode,
      handleClose,
      handlePreview,
      createAssessment,
      updateAssessment,
      deleteAssessment,
      clearFocusField,
    },
  };

  return (
    <AssessmentCreateContext.Provider value={values}>{children}</AssessmentCreateContext.Provider>
  );
};

export const useAssessmentCreateModal = () => {
  return useContext(AssessmentCreateContext);
};
