import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { ExpandIcon } from './ProfileAccordion.styled';

export interface ProfileAccordionProps extends PropsWithChildren {
  title: string;
  expandable?: boolean;
  expandedValue?: boolean;
}

export const ProfileAccordion: React.FC<ProfileAccordionProps> = ({
  title,
  children,
  expandable,
  expandedValue = true,
}) => {
  const [expanded, setExpanded] = React.useState(expandedValue);
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="start">
        <Typography variant="h4">{title}</Typography>
        {expandable && (
          <IconButton onClick={() => setExpanded((state) => !state)}>
            <ExpandIcon expanded={expanded} />
          </IconButton>
        )}
      </Stack>
      <Box>{expanded && children}</Box>
    </Stack>
  );
};
