import { EventsInvite } from '@schooly/constants';

import { ConsentFormShort } from '../consentForms';
import { RecurringState } from './recurring';
import { SignUp, SignUpRegular, SignUpSlots } from './signups';

export enum EventsStatuses {
  'Past',
  'Draft',
  'Published',
  'Canceled',
}

export type EventAction = 'publish' | 'cancel';
export type EventType = 'default' | 'period' | 'holiday';

export type Time = string | null;
/** T[timeFrom, fromTo] */
export type DateTime = [Time, Time];

export interface EventCriteria {
  groups?: Array<{ name: string; id: string }>;
  age_group?: string[];
  house?: string[];
  student_status?: string[];
}

export type SignUpShort = Pick<
  SignUp,
  'id' | 'end' | 'title' | 'type' | 'status' | 'consent_form'
> &
  Partial<Pick<SignUpRegular, 'places'>> &
  Partial<Pick<SignUpSlots, 'duration'>>;

export interface Event {
  id: string;
  title: string;
  description: string;
  start: string;
  end: string;
  date_times: Array<DateTime>;
  invitee_type: EventsInvite | null;
  event_status: EventsStatuses;
  can_be_edited: boolean;
  criteria: EventCriteria;
  event_type: EventType;
  sign_ups?: SignUpShort[];
  recurring_state: RecurringState | null;
  consent_form?: ConsentFormShort | null;
}
