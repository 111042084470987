import {
  Box,
  BoxProps,
  FormControl,
  FormControlProps,
  FormHelperText,
  Typography,
} from '@mui/material';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import get from 'lodash.get';
import React from 'react';
import { Controller, ControllerProps, FieldValues, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { RichText, RichTextProps } from './RichText';

export const HTML_TAGS_REGEXP = /<[^>]*(>|$)/g;

export interface ControlRichTextProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<ControllerProps<TFieldValues>, 'render' | 'defaultValue'> {
  FormControlProps?: FormControlProps;
  BoxProps?: BoxProps;
  RichTextProps?: RichTextProps;
}

export const ControlRichText = <TFieldValues extends FieldValues = FieldValues>({
  name,
  FormControlProps,
  BoxProps,
  RichTextProps,
  ...props
}: ControlRichTextProps<TFieldValues>) => {
  const { formState, watch } = useFormContext<TFieldValues>();

  const { $t } = useIntl();
  const error = get(formState.errors, name);
  const hasError = Boolean(error);
  const errorMessage = getControllerErrorText<TFieldValues>(error, props.rules, $t);
  const value = watch(name);
  const text = value ? value.replaceAll(HTML_TAGS_REGEXP, '') : '';

  return (
    <FormControl error={hasError} {...FormControlProps}>
      <Controller
        name={name}
        render={({ field }) => (
          <Box component={RichText} error={hasError} {...field} {...BoxProps} {...RichTextProps} />
        )}
        {...props}
      />
      <FormHelperText>
        <Typography variant="body2">{errorMessage}</Typography>
      </FormHelperText>

      {Boolean(props.rules?.required) && !text && (
        <Box
          position="absolute"
          sx={{
            top: 58,
            right: 40,
          }}
        >
          <Typography
            color="text.secondary"
            variant="h3"
            sx={{
              fontStyle: 'italic',
            }}
          >
            <FormattedMessage id="input-required" />
          </Typography>
        </Box>
      )}
    </FormControl>
  );
};
