import { SignUpStatuses, SignUpType } from '@schooly/api';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useFormValidation } from '@schooly/hooks/use-form-validation';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SignUpForm, useSignUp } from '../../../context/signUps/WithSignUp';

export const SignUpPlaces: FC = () => {
  const { $t } = useIntl();
  const { signUp } = useSignUp();
  const { watch, setValue } = useFormContext<SignUpForm>();
  const { mustBeInteger } = useFormValidation<SignUpForm>();

  const type = watch('type');

  useEffect(() => {
    if (type !== SignUpType.Regular) {
      setValue('places', undefined);
    }
  }, [setValue, type]);

  if (type !== SignUpType.Regular) {
    return null;
  }

  return (
    <ControlTextField
      name="places"
      label={$t({ id: 'eventSignUps-create-LimitOfPlaces' })}
      rules={{
        min: {
          value: signUp?.signed_up_count ?? 0,
          message: signUp?.signed_up_count
            ? $t({ id: 'eventSignUps-preview-Places-LessThenSigned' })
            : $t({ id: 'input-ErrorMinValue' }, { value: 0 }),
        },
        validate: mustBeInteger,
      }}
      locked={signUp?.status === SignUpStatuses.Closed}
      fullWidth
      hideLabel
    />
  );
};
