import { Box, Popper, Stack } from '@mui/material';
import {
  DateRangeSelectContent,
  DateRangeSelectContentProps,
  RangePickerHeader,
} from '@schooly/components/filters';
import { DropdownSelect } from '@schooly/components/filters';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form-lts';
import { useResizeDetector } from 'react-resize-detector';

interface SchoolPeriodRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  startDateError?: FieldError;
  endDateError?: FieldError;
  toDateColor?: string;
  onSetDate: (d: Date) => void;
  opened?: boolean;
  disabled: boolean;
  onClose: () => void;
  presetDate?: Date;
  DateRangeSelectContentProps?: Omit<
    DateRangeSelectContentProps,
    'fromDate' | 'toDate' | 'onSetDate'
  >;
}

export const SchoolPeriodRangePicker: FC<SchoolPeriodRangePickerProps> = ({
  endDate,
  startDate,
  startDateError,
  endDateError,
  onSetDate,
  opened,
  disabled,
  onClose,
  presetDate,
  DateRangeSelectContentProps,
}) => {
  const [open, setOpen] = useState(opened);
  const { ref } = useResizeDetector<HTMLDivElement>({});
  const dropdownRef = useRef<DropdownSelect>(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => dropdownRef.current?.open());
    }
  }, [open]);

  const dateColor = disabled ? 'text.primary' : undefined;
  const error = useMemo(() => startDateError || endDateError, [endDateError, startDateError]);

  const dropdownContent = useMemo(
    () => (
      <Box
        sx={{
          div: {
            '&:first-child': {
              maxHeight: 36,
            },
          },
        }}
      >
        <DropdownSelect
          ref={dropdownRef}
          disabled={disabled}
          error={error}
          onToggle={setOpen}
          onClose={() => {
            if (open) onClose();
          }}
          placeholder={''}
          withoutRightIcon
          renderContent={() => (
            <DateRangeSelectContent
              presetDate={presetDate}
              fromDate={startDate}
              toDate={endDate}
              onSetDate={onSetDate}
              {...DateRangeSelectContentProps}
            />
          )}
          layoutStyleProps={{
            minHeight: 36,
            padding: 0,
            borderRadius: '6px',
          }}
        >
          {() => {
            return (
              <Stack width={open ? 500 : undefined}>
                <RangePickerHeader
                  startDate={startDate}
                  endDate={endDate}
                  open={open}
                  onClose={!disabled ? () => setOpen(false) : undefined}
                  toDateColor={!open && endDateError?.message ? 'error.main' : dateColor}
                  fromDateColor={!open && startDateError?.message ? 'error.main' : dateColor}
                  layoutStyleProps={{
                    borderBottom: undefined,
                    pl: 1,
                    pt: 0.75,
                  }}
                />
              </Stack>
            );
          }}
        </DropdownSelect>
      </Box>
    ),
    [
      error,
      disabled,
      startDateError,
      endDateError,
      open,
      onClose,
      presetDate,
      startDate,
      endDate,
      onSetDate,
      DateRangeSelectContentProps,
      dateColor,
    ],
  );

  return (
    <Box
      ref={ref}
      height={36}
      width="100%"
      mb={error ? 2 : 0}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {ref.current &&
        (open ? (
          <Popper
            modifiers={[
              {
                name: 'flip',
                enabled: false,
              },
              {
                name: 'preventOverflow',
                enabled: false,
              },
            ]}
            open
            anchorEl={ref.current}
            sx={(theme) => ({
              zIndex: theme.zIndex.tooltip,
            })}
            disablePortal
            placement="auto"
          >
            {dropdownContent}
          </Popper>
        ) : (
          dropdownContent
        ))}
    </Box>
  );
};
