import { DEFAULT_DATE_FORMAT_FNS, DefaultSchoolYear, useGetSchoolYears } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { closestIndexTo, endOfDay, isFuture, isWithinInterval, parse, parseISO } from 'date-fns';
import { useCallback, useMemo } from 'react';

const useSchoolYears = () => {
  const { schoolId } = useAuth();
  const { data, isLoading, refetch, isFetching } = useGetSchoolYears(schoolId ?? '', {
    enabled: !!schoolId,
  });

  const items = data?.school_years;

  const getSchoolYearById = useCallback(
    (schoolYearId?: string) =>
      schoolYearId ? items?.find((item) => item.id === schoolYearId) : undefined,
    [items],
  );

  const getDefaultValidity = useCallback(
    (forwardOnly?: boolean): DefaultSchoolYear | undefined => {
      const actualYear = items?.find((year) => {
        return isWithinInterval(new Date(), {
          start: parseISO(year.start),
          end: endOfDay(parse(year.end, DEFAULT_DATE_FORMAT_FNS, new Date())),
        });
      });

      if (actualYear) {
        return { ...actualYear, isActual: true };
      }

      // otherwise, return closest year to Today

      if (forwardOnly) {
        // look among future years only

        const startDates = items?.map((year) => parseISO(year.start)) ?? [];
        const startIndex = closestIndexTo(new Date(), startDates);
        const startYear = items && startIndex != null ? items[startIndex] : undefined;

        return startYear ? { ...startYear, isActual: false } : undefined;
      }

      // look among both, past and future years

      const startDates = items?.map((year) => parseISO(year.start)) ?? [];
      const endDates = items?.map((year) => parseISO(year.end)) ?? [];

      // find the closest years in both directions, forward and backward
      const startIndex = closestIndexTo(new Date(), startDates);
      const endIndex = closestIndexTo(new Date(), endDates);

      // closest future year
      const startYear = items && startIndex != null ? items[startIndex] : undefined;
      // closest past year
      const endYear = items && endIndex != null ? items[endIndex] : undefined;

      if (!startYear && !endYear) {
        return undefined;
      }

      // return one if only one is found
      if (!startYear) {
        return { ...endYear!, isActual: false };
      } else if (!endYear) {
        return { ...startYear!, isActual: false };
      }

      // now define which one is closer
      const dates = [parseISO(startYear.start), parseISO(endYear.end)];
      const index = closestIndexTo(new Date(), dates);

      switch (index) {
        case 0:
          return { ...startYear, isActual: false };
        case 1:
          return { ...endYear, isActual: false };
        default:
          return undefined;
      }
    },
    [items],
  );

  const defaultValidity = useMemo(
    () => (items ? getDefaultValidity() : undefined),
    [getDefaultValidity, items],
  );

  const nextSchoolYear = useMemo(() => {
    const futureYears = items?.filter((y) => isFuture(parseISO(y.start)));
    const startDates = futureYears?.map((year) => parseISO(year.start)) ?? [];
    const startIndex = closestIndexTo(newDateTimezoneOffset(), startDates);
    const startYear = futureYears && startIndex != null ? futureYears[startIndex] : undefined;
    return startYear ?? undefined;
  }, [items]);

  return {
    schoolYears: items ?? [],
    isLoading: isLoading,
    isFetching: isFetching,
    fetchingSilent: isLoading,
    defaultValidity,
    nextSchoolYear,
    getSchoolYearById,
    getDefaultValidity,
    refetch,
  };
};

export default useSchoolYears;
