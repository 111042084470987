import { Box, Icon, MenuItem, MenuList } from '@mui/material';
import { CheckIcon } from '@schooly/style';
import { FC, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { ModalSidebarItem } from '../../../components/ui/ModalSidebar';
import { ModalSideBar } from '../../../components/uikit-components/Modal/Modal.styled';
import { useCheckSchoolSettings } from '../../../hooks/useCheckSchoolSettings';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import { useSchool } from '../../../hooks/useSchool';

export enum SchoolTuneMode {
  SCHOOL_YEARS = 'school_years',
  AGE_GROUPS = 'age_groups',
  STATUSES = 'statuses',
  ACCESS = 'access',
  SUBJECTS = 'subjects',
  HOUSES = 'houses',
  DEPARTMENTS = 'departments',
}

export const SchoolTuneSidebar: FC = () => {
  const navigate = useNavigate();
  const { tab } = useQueryStringParams();
  const { hasSystemRole } = useSchool();
  const { hasSubjects, hasDepartments, hasHouses, hasAgeGroups, hasSchoolYears, hasStatuses } =
    useCheckSchoolSettings();

  const sidebarItems = useMemo<
    (ModalSidebarItem<SchoolTuneMode> & { isCompleted: boolean; optional?: boolean })[]
  >(
    () => [
      {
        id: SchoolTuneMode.SCHOOL_YEARS,
        title: 'school-tabs-SchoolYears',
        isCompleted: hasSchoolYears,
      },
      {
        id: SchoolTuneMode.AGE_GROUPS,
        title: 'school-tabs-AgeGroups',
        isCompleted: hasAgeGroups,
      },
      {
        id: SchoolTuneMode.STATUSES,
        title: 'school-tabs-Statuses',
        isCompleted: hasStatuses,
      },
      {
        id: SchoolTuneMode.ACCESS,
        title: 'school-tabs-Access',
        isCompleted: !!hasSystemRole,
      },
      {
        id: SchoolTuneMode.SUBJECTS,
        title: 'school-tabs-Subjects',
        isCompleted: hasSubjects,
        optional: true,
      },
      {
        id: SchoolTuneMode.HOUSES,
        title: 'school-tabs-Houses',
        isCompleted: hasHouses,
        optional: true,
      },
      {
        id: SchoolTuneMode.DEPARTMENTS,
        title: 'school-tabs-Departments',
        isCompleted: hasDepartments,
        optional: true,
      },
    ],
    [
      hasSchoolYears,
      hasAgeGroups,
      hasStatuses,
      hasSystemRole,
      hasSubjects,
      hasHouses,
      hasDepartments,
    ],
  );

  useEffect(() => {
    if (!tab) {
      navigate(
        {
          search: `?tab=${SchoolTuneMode.SCHOOL_YEARS}`,
        },
        { replace: true },
      );
    }
  });

  return (
    <ModalSideBar>
      <MenuList>
        {sidebarItems.map((item) => (
          <Link to={{ search: `?tab=${item.id}` }} replace>
            <MenuItem selected={tab === item.id} key={item.id}>
              <Box sx={{ flex: '1 1 100%' }}>
                <FormattedMessage id={item.title} />
                {item.optional && !item.isCompleted && (
                  <>
                    {' '}
                    (<FormattedMessage id="input-optional" />)
                  </>
                )}
              </Box>
              {item.isCompleted && (
                <Icon color="success">
                  <CheckIcon />
                </Icon>
              )}
            </MenuItem>
          </Link>
        ))}
      </MenuList>
    </ModalSideBar>
  );
};
