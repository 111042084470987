export const PAGE = {
  HEADER: 'page-header',
  HEADER_TITLE: 'page-header-title',
};

export const TABLE = {
  ROW: 'table-row',
};

export const LIST = {
  ASSESSMENT: 'assessmentList',
};

export const MODAL = {
  DELETE: 'modal-delete',
  ARCHIVE: 'modal-archive',
  SAVE_PREVIEW: 'modal-save-preview',
};
