import { Stack } from '@mui/material';

export const AssessmentMarkbookEmptyCell = () => {
  return (
    <Stack
      width="100%"
      height="100%"
      sx={(theme) => ({
        backgroundImage: `repeating-linear-gradient(135deg, transparent ${theme.spacing(0, 1)}, ${
          theme.palette.common.light3
        }  ${theme.spacing(1, '9px')} )`,
      })}
    />
  );
};
