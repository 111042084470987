import { Box, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

interface SchoolPaymentFrequenciesEmptyStubProps {
  labelTextId: string;
  descriptionLabelTextId?: string;
  action: ReactNode;
  svg?: ReactNode;
}

export const SchoolPaymentFrequenciesEmptyStub: FC<SchoolPaymentFrequenciesEmptyStubProps> = ({
  labelTextId,
  descriptionLabelTextId,
  action,
  svg,
}) => {
  const { $t } = useIntl();

  return (
    <Stack alignItems="center" justifyContent="center" height="100%">
      {svg && <Box mb={5}>{svg}</Box>}

      <Typography textAlign="center" variant="h1" width={520}>
        {$t({ id: labelTextId })}
      </Typography>

      {descriptionLabelTextId && (
        <Typography color="common.grey2" textAlign="center" variant="h3" width={330} mt={1}>
          {$t({ id: descriptionLabelTextId })}
        </Typography>
      )}

      <Box mt={3}>{action}</Box>
    </Stack>
  );
};
