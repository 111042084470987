import { Group, GroupLimitations, StringOrNull } from '@schooly/api';
import { GroupLimitationsUpdate, GroupUpdate } from '@schooly/api';
import Joi from 'joi';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../../../config';
import emptyString from '../../../utils/joi/emptyString';

export interface ValiditySelectFormData {
  valid_from_school_year_id?: string;
  valid_to_school_year_id?: string;
  date_from?: string;
  date_to?: string;
}

export interface AddGroupFormData extends ValiditySelectFormData {
  name: string;
  description?: string;
  subject_id?: StringOrNull;
  limited_to_staff?: GroupLimitationsUpdate;
  limited_to_students?: GroupLimitationsUpdate;
}

export const validitySelect = Joi.object<ValiditySelectFormData>({
  valid_from_school_year_id: emptyString(),
  valid_to_school_year_id: emptyString(),
  date_from: emptyString(),
  date_to: emptyString(),
});

export function getRangePickerError(data: ValiditySelectFormData) {
  const { date_from, date_to } = data;

  if (date_from && !date_to) {
    return {
      date_to: {
        type: 'invalid',
        messageTextId: 'groups-ErrorEndDate',
      },
    };
  }

  if (
    date_from &&
    date_to &&
    moment(date_from, DEFAULT_DATE_FORMAT).isAfter(moment(date_to, DEFAULT_DATE_FORMAT))
  ) {
    return {
      date_to: {
        type: 'invalid',
        messageTextId: 'groups-ErrorDateOrder',
      },
    };
  }

  return undefined;
}

export const DEFAULT_LIMITED_TO: GroupLimitations = {
  school_property_ids: [],
  genders: [],
  nationalities: [],
  subject_ids: [],
};

export function getLimitedToDataFromGroup(
  group?: Group,
): Pick<GroupUpdate, 'limited_to_staff' | 'limited_to_students'> {
  if (!group || !group.id) {
    const { limited_to_staff, limited_to_students } = group || {};

    return {
      limited_to_staff: {
        ...DEFAULT_LIMITED_TO,
        school_property_ids: group?.limited_to_staff?.school_property_ids ?? [],
        genders: limited_to_staff?.genders ?? [],
        nationalities: limited_to_staff?.nationalities ?? [],
        subject_ids: limited_to_staff?.subject_ids ?? [],
      },
      limited_to_students: {
        ...DEFAULT_LIMITED_TO,
        school_property_ids: group?.limited_to_students?.school_property_ids ?? [],
        genders: limited_to_students?.genders ?? [],
        nationalities: limited_to_students?.nationalities ?? [],
        subject_ids: limited_to_students?.subject_ids ?? [],
      },
    };
  }

  const { limited_to_staff, limited_to_students } = group;

  return {
    limited_to_staff: {
      school_property_ids: limited_to_staff?.school_property_ids ?? [],
      genders: limited_to_staff?.genders ?? [],
      nationalities: limited_to_staff?.nationalities ?? [],
      subject_ids: limited_to_staff?.subject_ids ?? [],
    },
    limited_to_students: {
      school_property_ids: limited_to_students?.school_property_ids ?? [],
      genders: limited_to_students?.genders ?? [],
      nationalities: limited_to_students?.nationalities ?? [],
      subject_ids: limited_to_students?.subject_ids ?? [],
    },
  };
}
