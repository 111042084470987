import { Box, Icon, Stack, Typography } from '@mui/material';
import { UserWithGroupMembershipNormalized } from '@schooly/api';
import { DATE_FORMAT, Genders, getLabelIdForOption, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PersonCardBasic from '../../../../../components/common/PersonCard/PersonCardBasic';
import { AttendanceIcon } from '../../../../../components/ui/Icons';
import {
  GridCell,
  GridContainer,
  GridRowCell,
  GridRowName,
} from '../../../../../components/uikit-components/Grid/Grid';
import { Table, TableCell } from '../../../../../components/uikit-components/Table/Table';
import { useSchool } from '../../../../../hooks/useSchool';
import { GroupMemberTag } from './GroupMemberTag';

interface GroupMemberTableProps {
  members: UserWithGroupMembershipNormalized[];
  userType: 'student';
  nameColumn: string;
  withBorderBottom?: boolean;
}

export const GroupMemberTable: FC<GroupMemberTableProps> = ({
  members,
  userType,
  nameColumn,
  withBorderBottom,
}) => {
  const { schoolId = '' } = useSchool();
  const { formatMessage } = useIntl();
  const { ageGroups } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const columns: TableCell[] = useMemo(
    () => [
      {
        id: nameColumn,
        renderContent: () => (
          <GridCell py={1}>
            <FormattedMessage id={nameColumn} />
          </GridCell>
        ),
      },
      {
        id: 'groups-MembershipReason',
        renderContent: () => (
          <GridCell py={1}>
            <FormattedMessage id="groups-MembershipReason" />
          </GridCell>
        ),
      },
      {
        id: 'date-of-birthday',
        renderContent: () => (
          <GridCell py={1}>
            <FormattedMessage id="date-of-birthday" />
          </GridCell>
        ),
      },
      {
        id: 'peopleDetail-Gender',
        renderContent: () => (
          <GridCell py={1}>
            <FormattedMessage id="peopleDetail-Gender" />
          </GridCell>
        ),
      },
      {
        id: 'schoolProperty-AgeGroup',
        renderContent: () => (
          <GridCell py={1}>
            <FormattedMessage id="schoolProperty-AgeGroup" />
          </GridCell>
        ),
      },
      {
        id: 'attendance-Status',
        renderContent: () => (
          <GridCell py={1}>
            <FormattedMessage id="attendance-Status" />
          </GridCell>
        ),
      },
    ],
    [nameColumn],
  );

  const rows = useMemo(() => {
    if (!members) {
      return [];
    }
    return members.map((member) => {
      const ageGroupName = ageGroups.find((gr) => gr.id === member.age_group)?.name ?? '';
      const attendanceCodesString = member.attendance_codes?.join(',');
      const gender = Number.isInteger(member.gender)
        ? formatMessage({
            id: getLabelIdForOption({
              key: Genders[member.gender],
              optionsKey: 'gender',
            }),
          })
        : '';

      const cellArray: TableCell[] = [
        {
          id: 'date',
          renderContent: () => (
            <GridCell noVerticalPadding borderBottom={!!withBorderBottom}>
              <GridRowName sx={{ pt: 0.75, pb: 0.9, width: 240 }}>
                <Box
                  sx={{
                    '& .card-body': {
                      padding: '0 !important',
                    },
                  }}
                >
                  <PersonCardBasic
                    isUsernameClickable
                    user={member}
                    userType={userType}
                    isListItem
                  />
                </Box>
              </GridRowName>
            </GridCell>
          ),
        },

        {
          id: 'Tags',
          renderContent: () => (
            <GridCell
              sx={{
                width: 280,
              }}
              noVerticalPadding
              borderBottom={!!withBorderBottom}
            >
              {userType === 'student' && (
                <GridRowCell>
                  <GroupMemberTag membership={member.membership} />
                </GridRowCell>
              )}
            </GridCell>
          ),
        },
        {
          id: 'Dob',
          renderContent: () => (
            <GridCell
              sx={{
                width: 100,
              }}
              noVerticalPadding
              borderBottom={!!withBorderBottom}
            >
              <Typography color="text.primary">
                {format(newDateTimezoneOffset(member.date_of_birth), DATE_FORMAT)}
              </Typography>
            </GridCell>
          ),
        },
        {
          id: 'Gender',
          renderContent: () => (
            <GridCell
              sx={{
                width: 90,
              }}
              noVerticalPadding
              borderBottom={!!withBorderBottom}
            >
              <Typography color="text.primary">{gender}</Typography>
            </GridCell>
          ),
        },
        {
          id: 'Age group',
          renderContent: () => (
            <GridCell
              sx={{
                width: 90,
              }}
              noVerticalPadding
              borderBottom={!!withBorderBottom}
            >
              <Typography color="text.primary">{ageGroupName}</Typography>
            </GridCell>
          ),
        },
        {
          id: 'Attendance codes',
          renderContent: () => (
            <GridCell
              sx={{
                width: 135,
              }}
              noVerticalPadding
              borderBottom={!!withBorderBottom}
            >
              {attendanceCodesString?.length ? (
                <Stack direction="row" gap={0.5} alignItems="center">
                  <Icon sx={{ color: 'text.secondary' }}>
                    <AttendanceIcon />
                  </Icon>
                  <Typography color="text.primary">{attendanceCodesString}</Typography>
                </Stack>
              ) : (
                <Typography color="text.secondary">{'-'}</Typography>
              )}
            </GridCell>
          ),
        },
      ];

      return {
        id: member.relation_id,
        cells: cellArray,
      };
    });
  }, [ageGroups, formatMessage, members, userType, withBorderBottom]);

  return (
    <GridContainer
      sx={{
        borderRadius: 0,
      }}
    >
      <Table columns={columns} rows={rows} withDefaultHover />
    </GridContainer>
  );
};
