import {
  AssessmentForSchool,
  GetAssessmentsQueryFilters,
  GetAssessmentsQuerySort,
  SORT_DIRECTION,
  useGetAssessmentsQuery,
} from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { GridBody, SkeletonRows } from '@schooly/style';
import { FC, useEffect, useMemo } from 'react';

import { AssessmentRow, AssessmentsHeader } from './AssessmentsGrid';

interface AssessmentChartGridProps {
  schoolId: string;
  filters: Partial<GetAssessmentsQueryFilters>;
  sort?: GetAssessmentsQuerySort;
  onChangeSort: (v: GetAssessmentsQuerySort) => void;
  onSetTotalCount: (v?: number) => void;
}

const PAGE_SIZE = 30;

export const AssessmentChartGrid: FC<AssessmentChartGridProps> = ({
  sort,
  filters,
  schoolId,
  onChangeSort,
  onSetTotalCount,
}) => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, setParams } =
    useGetAssessmentsQuery(
      {
        school_id: schoolId,
        page_size: PAGE_SIZE,
        sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
        filters,
      },
      { refetchOnMount: 'always' },
    );

  useEffect(() => {
    onSetTotalCount(data?.pages[0].count);
  }, [data?.pages, onSetTotalCount]);

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage);

  const entries = useMemo(
    () =>
      data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as AssessmentForSchool[]) ??
      [],
    [data?.pages],
  );

  const total = data?.pages[0]?.count;

  useEffect(() => {
    setParams((params) => ({ ...params, filters, sort }));
  }, [sort, filters, setParams]);

  return (
    <>
      <AssessmentsHeader sort={sort} onChangeSort={onChangeSort} />
      <GridBody>
        {entries?.map((entry) => (
          <AssessmentRow
            assessment={entry}
            key={entry.id}
            onAddFilters={(draftFilter) =>
              setParams((p) => ({ ...p, filters: { ...filters, draftFilter }, sort }))
            }
          />
        ))}
        {(isLoading || isFetchingNextPage) && <SkeletonRows columnsCount={7} amount={PAGE_SIZE} />}
        {hasNextPage && !isFetchingNextPage && (
          <>
            <div ref={loaderRef} />
            <SkeletonRows
              columnsCount={7}
              amount={Math.min(
                PAGE_SIZE,
                total ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
              )}
            />
          </>
        )}
      </GridBody>
    </>
  );
};
