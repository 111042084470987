import { Skeleton, Stack } from '@mui/material';
import { FC } from 'react';

export const SelectContentSkeleton: FC = () => {
  return (
    <Stack gap={1} p={1}>
      <Skeleton variant="rectangular" height={20} width="80%" />
      <Skeleton variant="rectangular" height={20} width="70%" />
      <Skeleton variant="rectangular" height={20} width="20%" />
    </Stack>
  );
};
