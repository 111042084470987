import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { FC, useCallback } from 'react';

import { EditFamilyModal } from '../../components/common/EditFamilly/EditFamilyModal';
import { useRouter } from '../../context/router/useRouter';

export const AddParentModal: FC = () => {
  const invalidateQueries = useInvalidateListQueriesFor('parent');
  const { goBack } = useRouter();

  const handleParentAdd = useCallback(() => {
    invalidateQueries();
    goBack();
  }, [goBack, invalidateQueries]);

  return <EditFamilyModal isOpen onClose={goBack} onParentAdd={handleParentAdd} />;
};
