import { Box, Stack, Typography } from '@mui/material';
import {
  StaffRegistration,
  SyncUser,
  useAddEmploymentMutation,
  useDeleteEmploymentMutation,
  useGetEmploymentsQuery,
  useUpdateEmploymentMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import { Loading, PlusIcon, SimpleButton } from '@schooly/style';
import { getUserFullNameWithTitle } from '@schooly/utils/get-user-full-name-with-title';
import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import AccessDenied from '../../../../components/common/AccessDenied';
import {
  AddEmploymentCaseForm,
  AddEmploymentCaseModal,
} from '../../../../components/common/EmploymentCases/AddEmploymentCaseModal';
import {
  convertEmploymentCaseFormToRegistrationUpdate,
  convertStaffRegistrationToEmploymentForm,
} from '../../../../components/common/EmploymentCases/utils';
import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { GridContainer } from '../../../../components/uikit-components/Grid/Grid';
import { getRegistrationsByTimePeriods } from '../../../../helpers/registrations';
import { ProfileModalEmploymentCaseRow } from './ProfileModalEmploymentCaseRow';

type ProfileModalEmploymentCasesProps = {
  user: SyncUser;
};

export const ProfileModalEmploymentCases: FC<ProfileModalEmploymentCasesProps> = ({ user }) => {
  const { schoolId, permissions } = useAuth();
  const [employmentCaseModalOpened, openEmploymentCaseModal, closeEmploymentCaseModal] = useFlag();
  const isManager = useMemo(() => permissions.includes('employment_manager'), [permissions]);
  const { showError } = useNotifications();
  const [editingRegistration, setEditingRegistration] = useState<StaffRegistration | null>(null);
  const staffId = user.relation_id || '';
  const invalidateQueries = useInvalidateListQueriesFor('staff');
  const { data, refetch } = useGetEmploymentsQuery(
    { schoolId: schoolId || '', staffId },
    { enabled: !!schoolId && !!staffId, refetchOnMount: 'always' },
  );

  const addEmploymentMutation = useAddEmploymentMutation();
  const updateEmploymentMutation = useUpdateEmploymentMutation();
  const deleteEmploymentMutation = useDeleteEmploymentMutation();

  const [currentRegistrations, futureRegistrations, previousRegistrations] = useMemo(
    () => getRegistrationsByTimePeriods(data?.employments, 'staff'),
    [data?.employments],
  );

  const handleSubmit = useCallback(
    (data: AddEmploymentCaseForm) => {
      if (!schoolId || !staffId) return;

      const employment = convertEmploymentCaseFormToRegistrationUpdate(data);

      if (!editingRegistration)
        return addEmploymentMutation.mutate(
          {
            schoolId,
            staffId,
            employment,
          },
          {
            onSuccess: () => {
              refetch();
              invalidateQueries();
              closeEmploymentCaseModal();
            },
            onError: showError,
          },
        );

      updateEmploymentMutation.mutate(
        { employmentId: editingRegistration.id, employment },
        {
          onSuccess: () => {
            refetch();
            invalidateQueries();
            closeEmploymentCaseModal();
            setEditingRegistration(null);
          },
          onError: showError,
        },
      );
    },
    [
      addEmploymentMutation,
      closeEmploymentCaseModal,
      editingRegistration,
      invalidateQueries,
      refetch,
      schoolId,
      showError,
      staffId,
      updateEmploymentMutation,
    ],
  );

  const handleEditRegistration = useCallback(
    (registration: StaffRegistration) => {
      setEditingRegistration(registration);
      openEmploymentCaseModal();
    },
    [openEmploymentCaseModal],
  );

  const handleDeleteRegistration = useCallback(() => {
    if (!editingRegistration) return;

    deleteEmploymentMutation.mutate(editingRegistration.id, {
      onSuccess: () => {
        refetch();
        invalidateQueries();
        closeEmploymentCaseModal();
        setEditingRegistration(null);
      },
      onError: showError,
    });
  }, [
    closeEmploymentCaseModal,
    deleteEmploymentMutation,
    editingRegistration,
    invalidateQueries,
    refetch,
    showError,
  ]);

  const handleCloseModal = useCallback(() => {
    closeEmploymentCaseModal();
    setEditingRegistration(null);
  }, [closeEmploymentCaseModal]);

  if (!schoolId) return null;
  if (!permissions.includes('staff_manager')) return <AccessDenied />;

  const renderContent = () => {
    if (!data) return <Loading />;

    if (!data?.employments.length)
      return (
        <Typography my={3}>
          <FormattedMessage id={'employment-NoCases'} />
        </Typography>
      );

    return (
      <Stack gap={2}>
        {!!futureRegistrations?.length && (
          <Box>
            <Typography variant="h4">
              <FormattedMessage id="employment-Future" />
            </Typography>
            <GridContainer
              sx={{
                overflow: 'hidden',
              }}
            >
              {futureRegistrations.map((registration) => (
                <ProfileModalEmploymentCaseRow
                  key={registration.id}
                  registration={registration}
                  onEdit={handleEditRegistration}
                  canEdit={isManager}
                />
              ))}
            </GridContainer>
          </Box>
        )}

        {!!currentRegistrations?.length && (
          <Box>
            <Typography variant="h4">
              <FormattedMessage id="employment-Current" />
            </Typography>
            <GridContainer
              sx={{
                overflow: 'hidden',
              }}
            >
              {currentRegistrations.map((registration) => (
                <ProfileModalEmploymentCaseRow
                  key={registration.id}
                  registration={registration}
                  onEdit={handleEditRegistration}
                  canEdit={isManager}
                />
              ))}
            </GridContainer>
          </Box>
        )}

        {!!previousRegistrations?.length && (
          <Box>
            <Typography variant="h4">
              <FormattedMessage id="employment-Previous" />
            </Typography>
            <GridContainer
              sx={{
                overflow: 'hidden',
              }}
            >
              {previousRegistrations.map((registration) => (
                <ProfileModalEmploymentCaseRow
                  key={registration.id}
                  registration={registration}
                  onEdit={handleEditRegistration}
                  canEdit={isManager}
                />
              ))}
            </GridContainer>
          </Box>
        )}
      </Stack>
    );
  };

  return (
    <Stack height="100%" gap={2.5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id={'peopleDetail-employmentCases'} />
          </Typography>
          {!!data?.employments?.length && <Counter>{data.employments.length}</Counter>}
        </Stack>

        {isManager && (
          <SimpleButton
            startIcon={<PlusIcon />}
            onClick={openEmploymentCaseModal}
            data-test-id="add-employment-case-modal"
          >
            <FormattedMessage id={'employment-AddCase'} />
          </SimpleButton>
        )}
      </Stack>

      {renderContent()}

      {employmentCaseModalOpened && (
        <AddEmploymentCaseModal
          open
          initialValues={
            editingRegistration
              ? convertStaffRegistrationToEmploymentForm(editingRegistration)
              : undefined
          }
          schoolId={schoolId}
          isSaving={addEmploymentMutation.isLoading || updateEmploymentMutation.isLoading}
          isDeleting={deleteEmploymentMutation.isLoading}
          onSubmit={handleSubmit}
          onDelete={
            editingRegistration && !editingRegistration.initial
              ? handleDeleteRegistration
              : undefined
          }
          onClose={handleCloseModal}
          title={getUserFullNameWithTitle(user)}
        />
      )}
    </Stack>
  );
};
