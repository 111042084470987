import { Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import AccessDenied from '../../components/common/AccessDenied';
import Sidebar from '../../components/uikit-components/Sidebar/Sidebar';

const AccessDeniedPage: React.FC<PropsWithChildren> = () => (
  <Stack
    sx={{
      flex: 1,
      position: 'relative',
      zIndex: '1',
      background: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Sidebar />
    <AccessDenied />
  </Stack>
);

export default AccessDeniedPage;
