import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import get from 'lodash.get';
import { ReactNode } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form-lts';
import { FieldPath, FieldValues } from 'react-hook-form-lts/dist/types';
import { useIntl } from 'react-intl';

import { FormTextField, FormTextFieldProps } from './FormTextField';

export type ControlTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  FormTextFieldProps & {
    showEndAdornment?: boolean;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
  };

export const ControlTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control: propsControl,
  ...props
}: ControlTextFieldProps<TFieldValues, TName>) => {
  const { $t } = useIntl();
  const { control: contextControl, formState } = useFormContext<TFieldValues>();
  const control = propsControl ?? contextControl;

  return (
    <Controller
      control={control}
      render={({ field: { ref, ...restField } }) => {
        const error = get(formState.errors, restField.name);
        const hasError = props.error || Boolean(error);
        const errorMessage = getControllerErrorText<TFieldValues, TName>(error, props.rules, $t);

        return (
          <FormTextField
            {...props}
            {...props.rules}
            inputRef={ref}
            required={Boolean(props.rules?.required) || props.required}
            {...restField}
            error={hasError}
            helperText={errorMessage || props.helperText}
          />
        );
      }}
      {...props}
    />
  );
};
