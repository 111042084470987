import { IconButton, Paper, Stack } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { usePrevious } from '@schooly/hooks/use-previous';
import { DeleteIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  FieldPath,
  FieldPathValue,
  useFieldArray,
  useFormContext,
  Validate,
} from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../../../components/uikit/Dnd/dnd.styled';
import { SchoolTuneAgeGroupRowSmall } from './SchoolTuneAgeGroupRowSmall';
import {
  AgeGroupsDroppable,
  getEmptyAgeGroup,
  SchoolTuneAgeGroupsForm,
} from './SchoolTuneAgeGroupsModalContent';

interface SchoolTuneSchoolLevelProps {
  levelIndex: number;
  handleDeleteSchoolLevel: (index: number) => void;
  shouldFocusLevelInput: boolean;
  validateAgeGroup: (
    id: string,
  ) => Validate<
    FieldPathValue<SchoolTuneAgeGroupsForm, FieldPath<SchoolTuneAgeGroupsForm>>,
    SchoolTuneAgeGroupsForm
  >;
  validateSchoolLevel: (
    index: number,
  ) => Validate<
    FieldPathValue<SchoolTuneAgeGroupsForm, FieldPath<SchoolTuneAgeGroupsForm>>,
    SchoolTuneAgeGroupsForm
  >;
}

export const SchoolTuneSchoolLevel: FC<SchoolTuneSchoolLevelProps> = ({
  levelIndex,
  handleDeleteSchoolLevel,
  shouldFocusLevelInput,
  validateAgeGroup,
  validateSchoolLevel,
}) => {
  const { $t } = useIntl();

  const { control, watch, formState, trigger } = useFormContext<SchoolTuneAgeGroupsForm>();
  const hasErrors = formState.errors.schoolLevels || formState.errors.ageGroupsWithNoLevel;
  const level = watch(`schoolLevels.${levelIndex}`);
  const levelId = level.id || level.id_tmp || '';

  const ageGroups = level.ageGroups;
  const prevAgeGroups = usePrevious(ageGroups);

  const ageGroupAdded = prevAgeGroups && ageGroups && ageGroups.length - prevAgeGroups.length === 1;
  const shouldFocusAgeGroupInput = Boolean(ageGroupAdded);

  const { append: appendAgeGroups, remove: removeAgeGroups } = useFieldArray({
    control: control,
    name: `schoolLevels.${levelIndex}.ageGroups`,
  });

  const schoolLevels = watch('schoolLevels');
  const levelName = watch(`schoolLevels.${levelIndex}.name`);

  const addAgeGroupToLevel = useCallback(() => {
    appendAgeGroups({ ...getEmptyAgeGroup(), level_id: levelId });
  }, [appendAgeGroups, levelId]);

  const handleDeleteAgeGroup = useCallback(
    (index: number) => {
      removeAgeGroups(index);
      if (hasErrors) {
        trigger();
      }
    },
    [hasErrors, removeAgeGroups, trigger],
  );

  return (
    <Paper
      elevation={0}
      sx={(theme) => ({
        p: theme.spacing(2),
        bgcolor: 'background.default',
        width: '100%',
        overflow: 'hidden',
      })}
    >
      <Stack gap={2}>
        <Stack direction="row" gap={1} alignItems="flex-start">
          <ControlTextField
            canClear
            control={control}
            solid
            name={`schoolLevels.${levelIndex}.name`}
            label={$t({ id: 'school-tabs-AgeGroups-SchoolLevelName' })}
            fullWidth
            rules={{
              required: true,
              validate: validateSchoolLevel(levelIndex),
            }}
            autoFocus={
              schoolLevels && levelIndex === schoolLevels.length - 1 && shouldFocusLevelInput
            }
          />

          <IconButton
            inverse
            onClick={() => handleDeleteSchoolLevel(levelIndex)}
            sx={(theme) => ({ mt: theme.spacing(1.5) })}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>

        <Droppable droppableId={levelId} type={AgeGroupsDroppable.AGEGROUP}>
          {(provided) => (
            <DndListWrapper
              {...provided.droppableProps}
              ref={provided.innerRef}
              gap={1}
              sx={{ overflow: 'visible' }}
            >
              {ageGroups.map((ageGroup, index) => {
                const ageGroupId = ageGroup.id || ageGroup.id_tmp || '';
                return (
                  <Draggable key={ageGroupId} draggableId={ageGroupId} index={index}>
                    {(provided) => (
                      <SchoolTuneAgeGroupRowSmall
                        provided={provided}
                        levelAgeGroupsCount={level.ageGroups.length}
                        shouldFocus={
                          shouldFocusAgeGroupInput && index === level.ageGroups.length - 1
                        }
                        levelIndex={levelIndex}
                        ageGroupIndex={index}
                        validateAgeGroup={validateAgeGroup}
                        handleDeleteAgeGroup={handleDeleteAgeGroup}
                      />
                    )}
                  </Draggable>
                );
              })}

              {provided.placeholder}

              <SimpleButton
                disabled={!levelName && !level.ageGroups.length}
                startIcon={<PlusIcon />}
                onClick={addAgeGroupToLevel}
                sx={(theme) => ({
                  mt: level.ageGroups.length ? theme.spacing(1) : 0,
                  alignSelf: 'flex-start',
                  '&.MuiButton-root.Mui-disabled': { bgcolor: 'background.default' },
                })}
              >
                <FormattedMessage id="school-tabs-AgeGroups-AddAgeGroup" />
              </SimpleButton>
            </DndListWrapper>
          )}
        </Droppable>
      </Stack>
    </Paper>
  );
};
