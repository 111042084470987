import { IconButton, Stack, Typography } from '@mui/material';
import { CreateConsentFormRequest } from '@schooly/api';
import { DeleteIcon } from '@schooly/style';
import React, { FC } from 'react';
import { renderToString } from 'react-dom/server';
import { FormProvider, UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import {
  ConsentFormEditor,
  FULL_PARENT_NAME_VARIABLE,
  FULL_STUDENT_NAME_VARIABLE,
} from './ConsentFormEditor';

export type CreateConsentForm = Omit<CreateConsentFormRequest, 'reference_type' | 'reference_id'>;

type ConsentFormProps = {
  visible: boolean;
  onDelete?: () => void;
  consentForm: UseFormReturn<CreateConsentForm, any>;
};

const consentPointLabelIds = [
  'consentForms-description-consentPoint1',
  'consentForms-description-consentPoint2',
  'consentForms-description-consentPoint3',
];

export const ConsentForm: FC<ConsentFormProps> = ({ visible, onDelete, consentForm }) => {
  const { $t } = useIntl();

  const defaultValue = renderToString(
    <>
      <p style={{ whiteSpace: 'pre-line' }}>
        {$t(
          { id: 'consentForms-description-header' },
          { parentName: FULL_PARENT_NAME_VARIABLE, studentName: FULL_STUDENT_NAME_VARIABLE },
        )}
      </p>
      <ul>
        {consentPointLabelIds.map((id) => (
          <li key={id}>{$t({ id })}</li>
        ))}
      </ul>
    </>,
  );

  if (!visible) return null;

  return (
    <FormProvider {...consentForm}>
      <Stack width="100%" gap={1.25}>
        <Stack justifyContent="space-between" direction="row" alignItems="center">
          <Typography variant="h2">{$t({ id: 'consentForms-title' })}</Typography>
          {onDelete && (
            <IconButton inverse>
              <DeleteIcon onClick={onDelete} />
            </IconButton>
          )}
        </Stack>
        <ConsentFormEditor RichTextProps={{ defaultValue }} />
      </Stack>
    </FormProvider>
  );
};
