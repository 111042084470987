import { Button, IconButton, Stack, Typography } from '@mui/material';
import { FilterSelectOption, SchoolProperty } from '@schooly/api';
import {
  CheckIcon,
  CrossIcon,
  ModalConfirm,
  ModalContent,
  ModalHeader,
  ModalMain,
} from '@schooly/style';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FieldArrayWithId, FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import FormSelect2 from '../../../../components/ui/Input/FormSelect2';
import { ModalFooter } from '../../../../components/uikit-components/Modal/Modal.styled';
import { SchoolGeneralHousesForm } from './SchoolGeneralHousesModal';

export interface SchoolGeneralHousesReplaceModalProps {
  field: { name?: SchoolProperty['name']; originId?: SchoolProperty['id'] };
  fields: { index: number; field: FieldArrayWithId<SchoolGeneralHousesForm, 'houses', 'id'> }[];
  onClose?: () => void;
  onReplace?: (replaceId: SchoolProperty['id']) => void;
  onArchiveWithoutReplacing?: () => void;
}

export interface SchoolGeneralHousesReplaceForm {
  house?: SchoolProperty['id'];
}

export const SchoolGeneralHousesReplaceModal: FC<SchoolGeneralHousesReplaceModalProps> = ({
  field,
  fields,
  onClose,
  onReplace,
  onArchiveWithoutReplacing,
}) => {
  const { $t } = useIntl();

  const options = useMemo<FilterSelectOption[]>(
    () =>
      fields
        .filter((option) => option.field.originId && option.field.originId !== field.originId)
        .map((option) => ({
          value: option.field.originId ?? '',
          label: option.field.name,
          archived: option.field.archived,
        })),
    [field.originId, fields],
  );

  const form = useForm<SchoolGeneralHousesReplaceForm>({
    defaultValues: {
      house: undefined,
    },
  });

  const handleSubmit = useCallback<SubmitHandler<SchoolGeneralHousesReplaceForm>>(
    (data) => {
      if (data.house) {
        onReplace?.(data.house);
      }
    },
    [onReplace],
  );

  /* Archives without replace if no options to select available */
  useEffect(() => {
    if (!options.length) {
      onArchiveWithoutReplacing?.();
    }
  }, [onArchiveWithoutReplacing, options.length]);

  return (
    <ModalConfirm open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ModalHeader
            title={$t({ id: 'school-tabs-Houses-ArchiveReplaceTitle' }, { name: field.name })}
            withBorderBottom={false}
            multiline
            active
          >
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalMain>
            <ModalContent sx={{ pt: 0 }} active>
              <Stack direction="row" gap={2.5} alignItems="center">
                <Stack sx={(theme) => ({ height: theme.spacing(5) })} justifyContent="center">
                  <Typography variant="h2" whiteSpace="nowrap">
                    <FormattedMessage id="school-tabs-ArchiveReplaceWith" />
                  </Typography>
                </Stack>

                <FormSelect2
                  name="house"
                  labelTextId="schoolProperty-House"
                  options={options}
                  rules={{
                    validate: (value) => {
                      return value ? true : 'school-tabs-Houses-Archive-SelectRequired';
                    },
                  }}
                />
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooter active>
            <Button variant="outlined" onClick={onArchiveWithoutReplacing}>
              <FormattedMessage id="school-tabs-ArchiveWithoutReplacing" />
            </Button>
            <Button type="submit" startIcon={<CheckIcon />}>
              <FormattedMessage id="action-Replace" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalConfirm>
  );
};
