import { Icon, Menu, MenuItem, MenuProps, Stack, SxProps, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import React, { ReactNode } from 'react';

import { CheckIcon } from '../../assets/assets';
import { DropdownMenuItem } from './DropdownMenu';

export interface DropdownMenuPopoverProps<T extends DropdownMenuItem> extends MenuProps {
  menuStyles?: SxProps<Theme>;
  options: T[];
  renderMenuItem?: (i: T) => ReactNode;
  isSelected?: (i: T) => boolean;
  closeOnSelect?: boolean;
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'menuItemClick'): void;
  }['bivarianceHack'];
}

export const DropdownMenuPopover = <T extends DropdownMenuItem>({
  options,
  menuStyles,
  renderMenuItem,
  isSelected,
  closeOnSelect,
  sx,
  children,
  ...props
}: DropdownMenuPopoverProps<T>) => {
  return (
    <Menu
      sx={(theme) => ({
        '& .MuiList-root': {
          width: 180,
          p: 1,
        },

        '& .MuiMenuItem-root': {
          m: 0,
          px: 0.5,
          py: '1px',

          '&:not(:last-of-type)': {
            mb: 1,
          },

          '&:hover': {
            '& .MuiTypography-root, .svg-icon': {
              color: 'common.main2',
            },
          },
        },

        ...(typeof sx === 'function' ? sx(theme) : (sx as SystemStyleObject<Theme>)),
      })}
      {...props}
    >
      {options.map((option) => {
        const selected = Boolean(isSelected?.(option));
        return (
          <MenuItem
            onClick={(event) => {
              option.handler();
              closeOnSelect && props.onClose?.(event, 'menuItemClick');
            }}
            sx={{
              color: selected ? 'primary.main' : 'text.primary',
            }}
            key={option.title}
            data-test-id={`menu-item-${option.title}`}
          >
            {renderMenuItem ? (
              renderMenuItem(option)
            ) : (
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Stack direction="row" alignItems="center" gap={1}>
                  {option.icon && <Icon>{option.icon}</Icon>}
                  <Typography variant="h3" color="inherit">
                    {option.title}
                  </Typography>
                </Stack>
                {selected && (
                  <Icon>
                    <CheckIcon />
                  </Icon>
                )}
              </Stack>
            )}
          </MenuItem>
        );
      })}
      {children}
    </Menu>
  );
};
