export interface WeekOccurrence {
  day_of_week: number[]; // [1-7]
  day_of_month: null;
  week_of_month: null;
}

export interface MonthWeekOccurrence {
  day_of_week: [number]; // [1-7]
  day_of_month: null;
  week_of_month: number; // 1-5
}
export interface MonthDayOccurrence {
  day_of_week: null;
  day_of_month: number; // 1-31
  week_of_month: null;
}

export type Occurrence = WeekOccurrence | MonthWeekOccurrence | MonthDayOccurrence;

export enum RecurringRepeatBy {
  Week = 'week',
  Month = 'month',
}

export interface RecurringState {
  repeat_every: {
    repeat_count: number; // 1-9, e.g. 'repeat every 2nd month'
    period: RecurringRepeatBy;
    occurrence: Occurrence;
  };
  ends_on: string | null; // date
  ends_after: number | null;
  recurrence_id?: string; // uuid4, id of recurring group
  recurrence_name?: string; //name of the event that was initially used for creation
  following_count?: number; //number of events in the set after current event
}

export interface Recurrence {
  id: string;
  title: string;
}

export enum RepetitionType {
  FirstOfRecurrence = 'first_of_recurrence',
  NotRecurring = 'not_recurring',
}
