import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.36726 11.1863C0.85131 10.4802 0.853915 9.52082 1.37369 8.81756L5.81291 2.81126C6.19001 2.30103 6.78683 2 7.42129 2L12.6384 2C13.2766 2 13.8765 2.30462 14.2531 2.81993L18.6377 8.81956C19.1514 9.5224 19.1514 10.4768 18.6378 11.1796L14.2531 17.18C13.8765 17.6953 13.2766 18 12.6383 18L7.36184 18C6.72356 18 6.12361 17.6953 5.74703 17.18L1.36726 11.1863ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89546 8 8.00003 8.89543 8.00003 10C8.00003 11.1046 8.89546 12 10 12Z"
      fill="white"
    />
  </svg>
);
