import { Box, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const FilterRow: FC<PropsWithChildren<{ label: string }>> = ({ label, children }) => (
  <Stack flexDirection="row" alignItems="center" gap={2}>
    <Stack minHeight={44} sx={{ flex: '1 1 25%', justifyContent: 'center' }}>
      <Typography color="common.grey2">{label}</Typography>
    </Stack>
    <Box sx={{ flex: '1 1 75%' }}>{children}</Box>
  </Stack>
);
