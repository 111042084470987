import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="11" y="3" width="14" height="2" rx="1" transform="rotate(90 11 3)" fill="#24275B" />
    <rect x="3" y="9" width="14" height="2" rx="1" fill="#24275B" />
  </svg>
);
