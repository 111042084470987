import { Stack } from '@mui/material';
import { SchoolUserRole } from '@schooly/constants';
import { FC, PropsWithChildren, useCallback } from 'react';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { renderUserRoleTags } from './UserRoleExpandedSelect';

type UserRoleSelectMultipleProps = PropsWithChildren<{
  options: SchoolUserRole[];
  selectedRoles: SchoolUserRole[];
  onSelectUserRole: (v: SchoolUserRole) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;
export const UserRoleSelectMultiple: FC<UserRoleSelectMultipleProps> = ({
  options,
  selectedRoles,
  onSelectUserRole,
  placeholder,
  ...dropdownProps
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderUserRoleTags({
          roles: options,
          tagProps: (role) => ({
            variant: selectedRoles.includes(role) ? 'filled' : undefined,
          }),
          onClick: onSelectUserRole,
        })}
      </Stack>
    );
  }, [onSelectUserRole, options, selectedRoles]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selectedRoles.length}
      renderContent={renderContent}
    >
      {(opened) => (
        <>
          {renderUserRoleTags({
            roles: selectedRoles,
            tagProps: { size: placeholder ? 'small' : undefined },
            onDelete: opened ? onSelectUserRole : undefined,
          })}
        </>
      )}
    </DropdownSelect>
  );
};
