import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_21289_422)">
      <path d="M5 16.02V0L16.59 11.62H9.81L9.4 11.74L5 16.02Z" fill="white" />
      <path
        d="M14.08 16.69L10.48 18.22L5.80005 7.12996L9.48005 5.57996L14.08 16.69Z"
        fill="white"
      />
      <path
        d="M9.65257 8.63464L7.80847 9.40874L10.9048 16.7852L12.7489 16.0111L9.65257 8.63464Z"
        fill="black"
      />
      <path d="M6 2.41003V13.6L8.97 10.73L9.4 10.59H14.16L6 2.41003Z" fill="black" />
    </g>
    <defs>
      <filter
        id="filter0_d_21289_422"
        x="3"
        y="0"
        width="15.59"
        height="22.22"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21289_422" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_21289_422"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
