import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4C17 2.89543 16.1046 2 15 2H14V5H17V4ZM17 6H14C13.4477 6 13 5.55228 13 5V2H5C3.89543 2 3 2.89543 3 4V16C3 17.1046 3.89543 18 5 18H15C16.1046 18 17 17.1046 17 16V6Z"
      fill="#24275B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 14C9.73839 14 9.52632 13.7863 9.52632 13.5227L9.52632 8.62951L7.80863 10.3602C7.62364 10.5466 7.32372 10.5466 7.13874 10.3602C6.95375 10.1738 6.95375 9.87163 7.13874 9.68524L9.66505 7.13979C9.85004 6.9534 10.15 6.9534 10.3349 7.13979L12.8613 9.68524C13.0462 9.87162 13.0462 10.1738 12.8613 10.3602C12.6763 10.5466 12.3764 10.5466 12.1914 10.3602L10.4737 8.62951L10.4737 13.5227C10.4737 13.7863 10.2616 14 10 14Z"
      fill="white"
    />
  </svg>
);
