import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { LANGUAGE_OPTIONS, USER_OTHER_LANGUAGES_MAX_COUNT } from '@schooly/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  pickFormFieldsFromUser,
  processFormData,
  UserFormData,
} from '../../../components/common/ProfileModal/profileEditSchema';
import { FormSelect } from '../../../components/ui/Input';
import { hasFieldValue } from '../../../components/ui/Input/utils';
import { useProfile } from '../../../context/profile/useProfile';
import IntlError from '../../../utils/intlError';
import { PropertyModal } from './PropertyModal';

interface LanguageModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const getUserLanguageData = (formData: UserFormData) => {
  const { language, other_languages_spoken } = processFormData(formData);

  return {
    language: hasFieldValue(language) ? Number(language) : null,
    other_languages_spoken: other_languages_spoken ? other_languages_spoken.map(Number) : null,
  };
};

const LanguageModal = ({ isOpen, onClose }: LanguageModalProps) => {
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, actions } = useProfile();

  const form = useForm<UserFormData>({
    mode: 'onChange',
    defaultValues: pickFormFieldsFromUser(user!),
  });

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      try {
        setIsUpdating(true);

        const res = await actions.updateProfile(getUserLanguageData(data));
        if (!res) return;

        showNotification({
          textId: 'confirmation-Language',
          type: 'success',
        });
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, showNotification, showError, onClose],
  );

  const { reset, watch } = form;

  useEffect(() => {
    if (user) {
      reset(pickFormFieldsFromUser(user));
    }
  }, [user, reset]);

  const currentFormData = watch(['language', 'other_languages_spoken']);

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <FormSelect
        name="language"
        labelTextId="peopleDetail-PrimaryLanguage"
        options={LANGUAGE_OPTIONS}
        canRemove={!currentFormData.other_languages_spoken.length}
      />

      <FormSelect
        name="other_languages_spoken"
        labelTextId="peopleDetail-AdditionalLanguage"
        multiLabelTextId="peopleDetail-AdditionalLanguage-plural"
        multiple
        maxCount={USER_OTHER_LANGUAGES_MAX_COUNT}
        options={LANGUAGE_OPTIONS}
        disabled={!hasFieldValue(currentFormData.language)}
      />
    </PropertyModal>
  );
};

export default LanguageModal;
