import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { EditIcon } from '@schooly/style';
import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { useSchool } from '../../../../hooks/useSchool';

export const SchoolGeneralStatuses: FC = () => {
  const {
    hasStatuses,
    activeStudentStatuses,
    activeStaffStatuses,
    studentActivePropertiesMap,
    staffActivePropertiesMap,
    staffPropertiesFetching,
    studentPropertiesFetching,
  } = useSchool();
  const [expanded, setExpanded] = useState(false);

  const fetchingSilent = staffPropertiesFetching || studentPropertiesFetching;
  const canExpand = !fetchingSilent && hasStatuses;
  const studentCount = studentActivePropertiesMap.status?.length ?? 0;
  const staffCount = staffActivePropertiesMap.status?.length ?? 0;
  const count = studentCount + staffCount;

  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  const renderCategory = (list: { id?: string; name?: string }[], messageId: string) => {
    return (
      list.length > 0 && (
        <Stack
          direction="row"
          gap={2.5}
          alignItems="stretch"
          sx={{ borderBottom: (theme) => theme.mixins.borderValue() }}
        >
          <GridContainer
            sx={{
              borderRadius: 0,
            }}
          >
            {list.map((status) => (
              <GridRowStyled key={status.id} noBorderRadius>
                <GridRowItem>
                  <GridRowName>{status.name}</GridRowName>
                </GridRowItem>
              </GridRowStyled>
            ))}
          </GridContainer>
          <Stack
            justifyContent="center"
            sx={{
              flex: '0 0 50%',
            }}
          >
            <Typography>
              <FormattedMessage id={messageId} />
            </Typography>
          </Stack>
        </Stack>
      )
    );
  };

  return (
    <Card sx={{ minHeight: 224 }}>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center">
            <FormattedMessage id="school-tabs-Statuses" />
            {fetchingSilent ? (
              <Skeleton
                variant="circular"
                sx={(theme) => ({
                  ml: 1,
                  width: theme.spacing(2.5),
                  height: theme.spacing(2.5),
                })}
              />
            ) : (
              <Counter>{count}</Counter>
            )}
          </Stack>
        }
        action={
          <Link to="/settings/statuses">
            <IconButton inverse>
              <EditIcon />
            </IconButton>
          </Link>
        }
      />
      <CardContent>
        {fetchingSilent ? (
          <Stack>
            {new Array(3).fill(true).map((_, index) => (
              <GridRowStyled key={index} noBorder noBorderRadius>
                <GridRowItem>
                  <GridRowName>
                    <Skeleton />
                  </GridRowName>
                </GridRowItem>
              </GridRowStyled>
            ))}
          </Stack>
        ) : (
          <>
            <Stack direction="row" alignItems="center" my={2}>
              <Typography variant="h3">
                <FormattedMessage id="userType-student-plural" />
              </Typography>
              <Counter inverse>{studentCount}</Counter>
            </Stack>

            {expanded && (
              <>
                {renderCategory(
                  activeStudentStatuses.prospective,
                  'school-tabs-Statuses-Status-Prospective',
                )}
                {renderCategory(
                  activeStudentStatuses.current,
                  'school-tabs-Statuses-Status-Current',
                )}
                {renderCategory(activeStudentStatuses.former, 'school-tabs-Statuses-Status-Former')}
                {renderCategory(
                  activeStudentStatuses.unsuccessful,
                  'school-tabs-Statuses-Status-Unsuccessful',
                )}
              </>
            )}

            <Stack direction="row" alignItems="center" mt={2.5} mb={2}>
              <Typography variant="h3">
                <FormattedMessage id="userType-staff-plural" />
              </Typography>
              <Counter inverse>{staffCount}</Counter>
            </Stack>

            {!!staffCount && expanded && (
              <Box mb={0.75}>
                {renderCategory(
                  activeStaffStatuses.prospective,
                  'school-tabs-Statuses-Status-Prospective',
                )}
                {renderCategory(activeStaffStatuses.current, 'school-tabs-Statuses-Status-Current')}
                {renderCategory(activeStaffStatuses.former, 'school-tabs-Statuses-Status-Former')}
                {renderCategory(
                  activeStaffStatuses.unsuccessful,
                  'school-tabs-Statuses-Status-Unsuccessful',
                )}
              </Box>
            )}
          </>
        )}

        {canExpand && (
          <SimpleButton sx={{ mt: 1.25 }} onClick={toggleExpand}>
            {expanded ? (
              <FormattedMessage id="action-Hide" />
            ) : (
              <FormattedMessage id="action-ViewMore-number" values={{ number: count }} />
            )}
          </SimpleButton>
        )}
      </CardContent>
    </Card>
  );
};
