import { Box, Button, InputAdornment, Stack, Typography } from '@mui/material';
import { SchoolYear, SHORT_FORMATTED_DATE_FORMAT_FNS, TermlyPaymentFrequency } from '@schooly/api';
import { ControlPeriodSelect, getPeriodKey, PeriodLabel } from '@schooly/components/form-select';
import { ControlSwitch } from '@schooly/components/form-switch';
import { FormNumberSelect } from '@schooly/components/form-text-field';
import {
  CheckIcon,
  LockIcon,
  ModalContent,
  ModalFooter,
  PlusIcon,
  SimpleButton,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { addDays, format } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import {
  TermlyFrequencyForm,
  useFrequencies,
} from '../../../../context/frequencies/WithFrequencies';
import { formatPeriodStringDatesLabel, getBillingDateLabel } from '../utils';
import {
  FrequencyBillingDateControl,
  FrequencyHeader,
  FrequencySetting,
  MAX_DUE_DATE_NUMBER,
  MIN_DUE_DATE_NUMBER,
  PeriodResultRow,
  PeriodsResult,
} from './EditFrequencyCommon';

type TermlyFrequencyContentProps = {
  frequency: TermlyPaymentFrequency;
  currentSchoolYear: SchoolYear;
};

export const TermlyFrequencyContent: FC<TermlyFrequencyContentProps> = ({
  frequency,
  currentSchoolYear,
}) => {
  const { has_invoices, ...defaultValues } = frequency;
  const { updateFrequency } = useFrequencies();

  const { $t } = useIntl();

  const form = useForm<TermlyFrequencyForm>({
    defaultValues: {
      ...defaultValues,
      billing_periods:
        defaultValues.school_year_period_group_id && defaultValues.billing_periods
          ? defaultValues.billing_periods
          : [],
    },
    mode: 'onChange',
  });

  const inUse = form.watch('in_use');
  const activityCycles = form.watch('activity_cycles');
  const billingDates = form.watch('billing_periods');
  const periodGroupId = form.watch('school_year_period_group_id');
  const dueDay = form.watch('due_days_count');

  const periodsOptions = useMemo(
    () =>
      currentSchoolYear.period_groups?.map(({ id, name }) => ({
        label: name,
        key: id,
      })) ?? [],

    [currentSchoolYear.period_groups],
  );

  const periodGroup = useMemo(
    () => currentSchoolYear.period_groups?.find(({ id }) => id === periodGroupId),
    [currentSchoolYear.period_groups, periodGroupId],
  );

  const handleSubmit = useCallback(
    (d: TermlyFrequencyForm) => {
      const updatedFrequency: TermlyPaymentFrequency = {
        ...d,
        has_invoices: frequency.has_invoices,
      };

      updateFrequency(updatedFrequency);
    },
    [frequency.has_invoices, updateFrequency],
  );

  useEffect(() => {
    const selectPeriodGroupSubscription = form.watch(
      ({ school_year_period_group_id }, { name, type }) => {
        if (
          type !== 'change' ||
          name !== 'school_year_period_group_id' ||
          !school_year_period_group_id
        )
          return;
        const periodGroup = currentSchoolYear.period_groups?.find(
          (p) => p.id === school_year_period_group_id,
        );

        if (!periodGroup) return;

        periodGroup.periods.forEach(({ id }, i) => {
          form.setValue(`billing_periods.${i}`, {
            due_date: '',
            generation_date: '',
            invoice_date: '',
          });
        });
      },
    );
    return selectPeriodGroupSubscription.unsubscribe;
  }, [currentSchoolYear.period_groups, form]);

  const disabled = !inUse;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FrequencyHeader
          title={$t({ id: 'frequencies-termly' })}
          yearName={currentSchoolYear.name}
          // TODO onClose
          onClose={() => {}}
        >
          <ControlSwitch label={$t({ id: 'frequencies-InUse' })} name="in_use" />
        </FrequencyHeader>
        <ModalContent active>
          <FrequencySetting
            disabled={disabled}
            title={$t({ id: 'frequencies-ActivityPeriodMonthTitle' })}
            sx={{
              gap: 1,
            }}
          >
            <ControlPeriodSelect
              name="school_year_period_group_id"
              rules={{
                validate: (v) => {
                  if (!v.length) return $t({ id: 'frequencies-ActivityPeriodsError' });
                  return true;
                },
              }}
              singleSelect
              PeriodSelectProps={{
                options: periodsOptions,
                periodLabel: <PeriodLabel label={periodGroup?.name ?? ''} selected />,
                placeholder: $t({ id: 'frequencies-ActivityPeriodTitle' }),
                disabled: disabled,
                closeAfterClick: true,
                menuItem: (
                  <SimpleButton
                    sx={{ alignSelf: 'flex-start' }}
                    startIcon={<PlusIcon />}
                    onClick={() => {}}
                  >
                    {$t({ id: 'school-schoolPeriods-AddSchoolPeriods' })}
                  </SimpleButton>
                ),
              }}
            />
          </FrequencySetting>

          {!!periodGroup && (
            <>
              <FrequencySetting
                disabled={disabled}
                title={$t({ id: 'frequencies-InvoiceGenerationAndDueTitle' })}
                sx={{
                  pt: 4,
                }}
              >
                <Stack gap={2} direction="row" pt={2} pb={1}>
                  <Typography variant="h4" width="50%">
                    {$t({ id: 'frequencies-GenerationDates' })}
                  </Typography>
                  <Typography variant="h4">{$t({ id: 'frequencies-InvoiceDates' })}</Typography>
                </Stack>
                <Stack gap={2}>
                  {periodGroup.periods.map((p, i) => {
                    const periodNumber = i + 1;
                    return (
                      <Stack key={p.id} direction="row" gap={1}>
                        <FrequencyBillingDateControl
                          placeholder={getBillingDateLabel(
                            periodNumber,
                            $t({
                              id: 'frequencies-GenerationDate',
                            }),
                          )}
                          name={`billing_dates.${i}.generation_date`}
                        />
                        <FrequencyBillingDateControl
                          placeholder={getBillingDateLabel(
                            periodNumber,
                            $t({ id: 'frequencies-InvoiceDate' }),
                          )}
                          name={`billing_dates.${i}.invoice_date`}
                        />
                      </Stack>
                    );
                  })}
                </Stack>
              </FrequencySetting>
              <FrequencySetting
                disabled={disabled}
                subTitle={$t({ id: 'frequencies-DueDate' })}
                sx={{
                  pt: 2,
                  gap: 1,
                }}
              >
                <FormNumberSelect
                  disabled={disabled}
                  name="due_day"
                  min={MIN_DUE_DATE_NUMBER}
                  max={MAX_DUE_DATE_NUMBER}
                  placeholder={$t({ id: 'frequencies-DueOn' })}
                  rules={{
                    validate: (value) => {
                      if (!value) return $t({ id: 'input-ErrorRequired' });
                      return true;
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {disabled ? (
                          <LockIcon />
                        ) : (
                          $t({ id: 'frequencies-DueDateDescriptionTitle' })
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                {billingDates && (
                  <Box pt={3.5}>
                    <PeriodsResult>
                      {billingDates.map(({ generation_date, invoice_date }, i) => {
                        const periodId = activityCycles[i]?.school_year_period_id;
                        const period = periodGroup?.periods?.find(({ id }) => periodId === id);
                        const periodNumber = i + 1;
                        if (!period) return null;

                        const { date_from: start, date_to: end } = period;
                        return (
                          <PeriodResultRow
                            key={getPeriodKey({ start, end })}
                            invoiceDate={
                              invoice_date
                                ? formatPeriodStringDatesLabel({ start: invoice_date })
                                : '-'
                            }
                            generationDate={
                              generation_date
                                ? formatPeriodStringDatesLabel({ start: generation_date })
                                : '-'
                            }
                            activityPeriod={formatPeriodStringDatesLabel({
                              start,
                              end,
                            })}
                            periodNumber={periodNumber}
                            dueDate={
                              generation_date && dueDay
                                ? format(
                                    addDays(newDateTimezoneOffset(generation_date), dueDay),
                                    SHORT_FORMATTED_DATE_FORMAT_FNS,
                                  )
                                : '-'
                            }
                          />
                        );
                      })}
                    </PeriodsResult>
                  </Box>
                )}
              </FrequencySetting>
            </>
          )}
        </ModalContent>
        <ModalFooter
          active
          sx={(theme) => ({
            zIndex: theme.zIndex.drawer,
          })}
        >
          <Button endIcon={<CheckIcon />} type="submit">
            {$t({ id: 'action-Save' })}
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
