import { Box, Stack } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, CopyIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback, useRef, useState } from 'react';

export const SchoolApplicationsDirectLink: FC = () => {
  const timeout = useRef<number | undefined>();
  const { showNotification } = useNotifications();

  const { currentSchool } = useAuth();

  const [copied, setCopied] = useState(false);

  const link = `${window.location.origin}/parent-applications?school_id=${currentSchool?.id}`;

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(link);

    setCopied(true);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = window.setTimeout(() => {
      setCopied(false);
    }, 3000);

    showNotification({
      textId: 'school-sections-Applications-SuccessfullyCopied',
      type: 'success',
    });
  }, [link, showNotification]);

  return (
    <Stack direction="row" alignItems="center" mt={2.5}>
      <Box flex="0 0 350px" />
      <SimpleButton
        endIcon={copied ? <CheckIcon /> : <CopyIcon />}
        sx={{ textAlign: 'left', color: copied ? 'primary.main' : undefined }}
        onClick={handleClick}
        inverse
      >
        {link}
      </SimpleButton>
    </Stack>
  );
};
