import { GET_CONDUCT_TYPES_FOR_SCHOOL_QUERY, useGetConductTypesForSchoolQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';

import { queryClient } from '../../queryClient';

const fetchTypes = () => queryClient.invalidateQueries([GET_CONDUCT_TYPES_FOR_SCHOOL_QUERY]);

export const useConductCommon = () => {
  const { schoolId = '' } = useAuth();
  const {
    data: types,
    isLoading: typesFetching,
    error: typesError,
  } = useGetConductTypesForSchoolQuery(schoolId, { enabled: !!schoolId });

  return {
    types,
    typesError,
    typesFetching,
    fetchTypes,
  };
};
