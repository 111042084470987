import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.25259 0.840838C9.65038 0.393322 10.3496 0.393322 10.7474 0.840838L11.9902 2.23895C12.2306 2.50939 12.5983 2.62888 12.9517 2.55138L14.779 2.15077C15.3638 2.02253 15.9295 2.43354 15.9883 3.0294L16.1719 4.89097C16.2074 5.25106 16.4347 5.56388 16.7662 5.70894L18.4799 6.45883C19.0285 6.69887 19.2445 7.36388 18.9418 7.88049L17.9962 9.49447C17.8133 9.80667 17.8133 10.1933 17.9962 10.5055L18.9418 12.1195C19.2445 12.6361 19.0285 13.3011 18.4799 13.5412L16.7662 14.2911C16.4347 14.4361 16.2074 14.7489 16.1719 15.109L15.9883 16.9706C15.9295 17.5665 15.3638 17.9775 14.779 17.8492L12.9517 17.4486C12.5983 17.3711 12.2306 17.4906 11.9902 17.7611L10.7474 19.1592C10.3496 19.6067 9.65038 19.6067 9.25259 19.1592L8.00983 17.7611C7.76943 17.4906 7.4017 17.3711 7.04825 17.4486L5.22105 17.8492C4.63618 17.9775 4.07049 17.5665 4.01171 16.9706L3.82808 15.109C3.79257 14.7489 3.56529 14.4361 3.2338 14.2911L1.52008 13.5412C0.971545 13.3011 0.755468 12.6361 1.05816 12.1195L2.0038 10.5055C2.18673 10.1933 2.18673 9.80667 2.0038 9.49447L1.05816 7.88049C0.755469 7.36388 0.971545 6.69887 1.52008 6.45883L3.2338 5.70894C3.56529 5.56388 3.79257 5.25107 3.82808 4.89097L4.01171 3.0294C4.07049 2.43354 4.63618 2.02253 5.22105 2.15077L7.04825 2.55138C7.4017 2.62888 7.76943 2.50939 8.00983 2.23895L9.25259 0.840838Z"
      fill="#6FCF97"
    />
    <path
      d="M10.1091 14C9.40364 14 8.79636 13.8709 8.28727 13.6127C7.78545 13.3472 7.35636 12.9673 7 12.473L7.99273 11.3333C8.34909 11.8349 8.70182 12.178 9.05091 12.3624C9.4 12.5394 9.78545 12.6279 10.2073 12.6279C10.5491 12.6279 10.8364 12.5542 11.0691 12.4066C11.3091 12.2591 11.4291 12.0599 11.4291 11.8091C11.4291 11.5067 11.2764 11.2706 10.9709 11.101C10.6727 10.9239 10.2873 10.7875 9.81455 10.6916C8.99273 10.5145 8.38182 10.2305 7.98182 9.83956C7.58182 9.44859 7.38182 8.92854 7.38182 8.27939C7.38182 7.82941 7.50909 7.43476 7.76364 7.09544C8.01818 6.74873 8.35273 6.47948 8.76727 6.28769C9.18909 6.0959 9.65455 6 10.1636 6C10.84 6 11.4 6.11803 11.8436 6.35408C12.2945 6.58276 12.6509 6.91102 12.9127 7.33887L11.8982 8.34578C11.4473 7.69663 10.8727 7.37206 10.1745 7.37206C9.81091 7.37206 9.51636 7.44952 9.29091 7.60443C9.07273 7.75196 8.96364 7.95482 8.96364 8.213C8.96364 8.48594 9.09091 8.69986 9.34545 8.85477C9.60727 9.00968 9.95636 9.1314 10.3927 9.21992C11.1927 9.38958 11.8255 9.66252 12.2909 10.0387C12.7636 10.4076 13 10.9055 13 11.5325C13 12.0489 12.8764 12.4915 12.6291 12.8603C12.3891 13.2291 12.0509 13.5131 11.6145 13.7123C11.1855 13.9041 10.6836 14 10.1091 14Z"
      fill="white"
    />
  </svg>
);
