import { Backdrop, Box, ClickAwayListener, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { GroupSubject } from '@schooly/api';
import {
  ExpandedSelect,
  getSelectedItemsWithGrouping,
  renderPropertyGroupTags,
  renderSubjectTags,
  SelectContentSkeleton,
  SelectedItem,
  SelectedItemWithGrouping,
  SelectSearchInput,
  SubjectTagSelect,
} from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { DropdownIcon, LockIcon, TagSelectCounter, TagSelectProperty } from '@schooly/style';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ProductBlockedEditingLabel } from '../SchoolProductCommon';
import { IntersectsAll } from './SchoolProductCreateModalContent';

type ProductApplicableSelectMultipleProps = {
  schoolId: string;
  intersectionSubjectIds?: string[];
  intersectionAgeGroupIds?: IntersectsAll | string[];
  selectedSubjectIds: string[];
  selectedAgeGroupIds: string[];
  onClear?: () => void;
  onSelectSubjectId: (v: string) => void;
  onSelectAgeGroupIds: (v: string[]) => void;
  disabled: boolean;
};

export const ProductApplicableSelectMultiple: FC<ProductApplicableSelectMultipleProps> = ({
  schoolId,
  selectedAgeGroupIds,
  selectedSubjectIds,
  intersectionSubjectIds,
  intersectionAgeGroupIds,
  onSelectSubjectId,
  onSelectAgeGroupIds,
  onClear,
  disabled,
}) => {
  const [opened, open, close] = useFlag();
  const { $t } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');
  const [hoveredLevelId, setHoveredLevelId] = useState('');

  const {
    schoolLevels,
    schoolLevelsWithAgeGroupsMap,
    getAgeGroupsByLevelId,
    getSchoolLevelById,
    getAgeGroupById,
    activeAgeGroups,
    isLoading: isLoadingAgeGroups,
  } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const isLoading = isLoadingAgeGroups;

  const selectedItemsWithGrouping: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups =
      selectedAgeGroupIds?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [selectedAgeGroupIds, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  const renderContent = useCallback(() => {
    if (isLoading) return <SelectContentSkeleton />;

    const filteredLevels = schoolLevels.filter((p) =>
      p.name.toLowerCase().includes(query.toLowerCase()),
    );

    const filteredAgeGroups = activeAgeGroups.filter((p) =>
      p.name.toLowerCase().includes(query.toLowerCase()),
    );

    //Based on TR-7185 subjects are temporary hidden
    const filteredSubjects: GroupSubject[] = [];
    // const filteredSubjects =
    //   subjectsData?.subjects.filter(
    //     (s) => !s.archived && s.name.toLowerCase().includes(query.toLowerCase()),
    //   ) || [];

    if (!filteredLevels.length && !filteredAgeGroups.length && !filteredSubjects?.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {!!filteredLevels.length && (
          <>
            <Typography variant="h4" mt={1} ml={1}>
              <FormattedMessage id="school-properties-ageGroups-schoolLevels" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
              {filteredLevels.map((level) => {
                const ageGroupsOfLevel = getAgeGroupsByLevelId(level.id);
                return (
                  <TagSelectProperty
                    key={level.id}
                    userRole={SchoolUserRole.Student}
                    variant={
                      selectedItemsWithGrouping.some((item) => item.id === level.id)
                        ? 'filled'
                        : undefined
                    }
                    onClick={() => {
                      const ageGroupsOfLevelIds = ageGroupsOfLevel.map((g) => g.id);
                      onSelectAgeGroupIds(ageGroupsOfLevelIds);
                      setQuery('');
                    }}
                    className={hoveredLevelId === level.id ? 'hoveredTag' : undefined}
                    onMouseEnter={() => setHoveredLevelId(level.id)}
                    onMouseLeave={() => setHoveredLevelId('')}
                    label={
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        {level.name}
                        <TagSelectCounter inverse>{ageGroupsOfLevel.length}</TagSelectCounter>
                      </Stack>
                    }
                  />
                );
              })}
            </Stack>
          </>
        )}
        {!!filteredAgeGroups.length && (
          <>
            <Typography variant="h4" mt={1} ml={1}>
              <FormattedMessage id="schoolProperty-AgeGroup-plural" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
              {filteredAgeGroups.map((ageGroup) => (
                <TagSelectProperty
                  key={ageGroup.id}
                  userRole={SchoolUserRole.Student}
                  property={ageGroup}
                  variant={selectedAgeGroupIds?.includes(ageGroup.id) ? 'filled' : undefined}
                  outlined={Boolean(hoveredLevelId && ageGroup.level_id === hoveredLevelId)}
                  onClick={() => {
                    onSelectAgeGroupIds([ageGroup.id]);
                    setQuery('');
                  }}
                />
              ))}
            </Stack>
          </>
        )}
        {!!filteredSubjects.length && (
          <>
            <Typography variant="h4" mt={1} ml={1}>
              <FormattedMessage id="groups-GroupSubject-plural" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
              {filteredSubjects.map((property) => (
                <SubjectTagSelect
                  schoolId={schoolId}
                  key={property.id}
                  id={property.id}
                  variant={selectedSubjectIds?.includes(property.id) ? 'filled' : undefined}
                  onClick={() => {
                    onSelectSubjectId(property.id);
                    setQuery('');
                  }}
                />
              ))}
            </Stack>
          </>
        )}
      </>
    );
  }, [
    isLoading,
    schoolLevels,
    activeAgeGroups,
    query,
    getAgeGroupsByLevelId,
    selectedItemsWithGrouping,
    hoveredLevelId,
    onSelectAgeGroupIds,
    selectedAgeGroupIds,
    schoolId,
    selectedSubjectIds,
    onSelectSubjectId,
  ]);

  const hasValues = !!selectedSubjectIds.length || !!selectedAgeGroupIds.length;

  //Based on TR-7185 subjects are temporary hidden
  // const renderSubject = (id: string, hasNext?: boolean) => {
  //   const name = subjectsData?.subjects.find((s) => s.id === id)?.name || '';

  //   if (!name) return null;

  //   return (
  //     <>
  //       <span key={id} className={intersectionSubjectIds?.includes(id) ? 'error' : ''}>
  //         {name}
  //       </span>
  //       {hasNext && ' + '}
  //     </>
  //   );
  // };

  const renderSelectedItemsWithGrouping = (item: SelectedItemWithGrouping, hasNext?: boolean) => {
    const name = item.isGroup
      ? getSchoolLevelById(item.id)?.name || ''
      : getAgeGroupById(item.id)?.name || '';

    if (!name) return null;

    const ageGroupIds = item.isGroup
      ? (schoolLevelsWithAgeGroupsMap[item.id] || []).map((g) => g.id)
      : [item.id];

    return (
      <>
        <span
          key={item.id}
          className={ageGroupIds.some((id) => intersectionAgeGroupIds?.includes(id)) ? 'error' : ''}
        >
          {name}
        </span>
        {hasNext && ' + '}
      </>
    );
  };

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 500,
                  maxWidth: 500,
                  borderRadius: theme.spacing(1),
                  borderTopLeftRadius: 0,
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(-2.85, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <ExpandedSelect
                onClickInputArea={() => inputRef.current?.focus()}
                hasSelectedValue={hasValues}
                onClose={close}
                onClear={onClear}
                renderContent={renderContent}
                width={500}
              >
                {renderPropertyGroupTags({
                  selectedItems: selectedItemsWithGrouping,
                  onDelete: (ids) => {
                    onSelectAgeGroupIds(ids);
                    setHoveredLevelId('');
                  },
                  getProperty: (i) =>
                    i.isGroup
                      ? getSchoolLevelById(i.id)
                      : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
                  getItemsOfGroupIds: (i) =>
                    i.isGroup ? getAgeGroupsByLevelId(i.id).map(({ id }) => id) : [i.id],
                  onGroupMouseEnter: (id) => setHoveredLevelId(id),
                  onGroupMouseLeave: () => setHoveredLevelId(''),
                  tagProps: {
                    userRole: SchoolUserRole.Student,
                    schoolId,
                    size: 'small',
                    sx: { maxWidth: 200 },
                  },
                })}
                {renderSubjectTags({
                  ids: selectedSubjectIds,
                  tagProps: { schoolId, size: 'small', sx: { maxWidth: 200 } },
                  onDelete: onSelectSubjectId,
                })}
                <SelectSearchInput
                  ref={inputRef}
                  autoFocus
                  value={query || ''}
                  onChangeText={setQuery}
                  placeholder={
                    !selectedAgeGroupIds.length && !selectedSubjectIds.length
                      ? $t({ id: 'products-WhichStudentsTheProductIsApplicableTo?' })
                      : undefined
                  }
                />
              </ExpandedSelect>
            }
          >
            <Box
              flexDirection="row"
              sx={{
                cursor: disabled ? 'auto' : 'pointer',
                position: 'relative',
                zIndex: (theme) => (opened ? theme.zIndex.drawer + 2 : undefined),
              }}
              alignItems="center"
              onClick={disabled ? undefined : open}
            >
              {!opened ? (
                <Stack flexDirection="row" alignItems="center" p={1} pr={5}>
                  {!hasValues && (
                    <Typography variant="h3" color="common.grey">
                      {$t({ id: 'products-AddStudents' })}
                    </Typography>
                  )}
                  <Typography
                    variant="h3"
                    sx={(theme) => ({
                      '.error': {
                        color: theme.palette.error.main,
                      },
                      '.all-age-groups': {
                        color: theme.palette.common.grey,
                      },
                    })}
                  >
                    {selectedItemsWithGrouping.map((item, i) =>
                      renderSelectedItemsWithGrouping(
                        item,
                        i < selectedItemsWithGrouping.length - 1,
                      ),
                    )}
                    {/* Based on TR-7185 subjects are temporary hidden
                    {!!selectedSubjectIds.length && !selectedItemsWithGrouping.length && (
                      <span
                        className={
                          typeof intersectionAgeGroupIds === 'string' &&
                          intersectionAgeGroupIds === INTERSECTS_ALL
                            ? 'error'
                            : 'all-age-groups'
                        }
                      >
                        {$t({ id: 'products-AllAgeGroups' })}
                        {', '}
                      </span>
                    )}
                    {!!selectedSubjectIds.length && !!selectedItemsWithGrouping.length && ', '}
                    {selectedSubjectIds.map((id, i) =>
                      renderSubject(id, i < selectedSubjectIds.length - 1),
                    )}   */}
                  </Typography>
                  <Tooltip
                    componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
                    title={<ProductBlockedEditingLabel labelId="products-CannotEditCriteria" />}
                  >
                    <Icon
                      sx={(theme) => ({
                        cursor: 'pointer',
                        position: 'absolute',
                        right: theme.spacing(1.5),
                        color: theme.palette.common.grey,
                        '&:hover': {
                          color: disabled ? theme.palette.primary.main : theme.palette.text.primary,
                        },
                      })}
                    >
                      {disabled ? <LockIcon /> : <DropdownIcon />}
                    </Icon>
                  </Tooltip>
                </Stack>
              ) : (
                <></>
              )}
            </Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};
