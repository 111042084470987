import { Button, Icon, Stack } from '@mui/material';
import { ApplicationChild } from '@schooly/api';
import {
  ApplicationEditablePreviewContent,
  ApplicationUpdateForm,
  CreateChildForm,
  CreateParentForm,
} from '@schooly/components/applications';
import { CreateCustomField } from '@schooly/components/applications';
import { ArrowLeftIcon, ArrowRightIcon, Loading, ModalFooter, ParentIcon } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ApplicationChildForm {
  children: ApplicationChild[];
}

interface ApplicationEditablePreviewContainerProps {
  formData: ApplicationUpdateForm;
  pending: boolean;
  onChildSubmit: (data: CreateChildForm, type: string) => void;
  onParentSubmit: (data: CreateParentForm, type: string) => void;
  onGeneralCustomFieldsSubmit: (data: CreateCustomField[], type: string) => void;
  onClose: () => void;
  onGoBack: () => void;
  onSave: () => void;
  onConvert: () => void;
  canEdit: boolean;
  withSaveButton?: boolean;
  childUpdating?: Record<string, boolean>;
  parentUpdating?: Record<string, boolean>;
}

export const ApplicationEditablePreviewContainer: FC<ApplicationEditablePreviewContainerProps> = ({
  pending,
  formData,
  onChildSubmit,
  onParentSubmit,
  onGeneralCustomFieldsSubmit,
  onClose,
  onGoBack,
  onSave,
  onConvert,
  canEdit,
  withSaveButton,
  childUpdating,
  parentUpdating,
}) => {
  if (pending) {
    return <Loading />;
  }

  return (
    <ApplicationEditablePreviewContent
      formData={formData}
      onChildSubmit={onChildSubmit}
      onParentSubmit={onParentSubmit}
      onGeneralCustomFieldsSubmit={onGeneralCustomFieldsSubmit}
      canEdit={canEdit}
      headerTitle={
        <Stack direction="row" height={'100%'} gap={1.5} pl={1} alignItems="center">
          <Icon sx={{ display: 'flex', color: (theme) => theme.palette.primary.main }}>
            <ParentIcon />
          </Icon>
          <FormattedMessage id="userType-parentsGuardians" />
        </Stack>
      }
      onClose={onClose}
      withNotes
      childUpdating={childUpdating}
      parentUpdating={parentUpdating}
      footerActions={
        <ModalFooter active sx={{ justifyContent: 'space-between' }}>
          <Button
            startIcon={<ArrowLeftIcon />}
            onClick={onGoBack}
            variant="outlined"
            data-test-id="application-preview-back-button"
          >
            <FormattedMessage id="action-Back" />
          </Button>

          <Stack direction="row" gap={2}>
            {withSaveButton && (
              <Button
                variant="outlined"
                onClick={onSave}
                data-test-id="application-preview-save-button"
              >
                <FormattedMessage id="action-SaveAndClose" />
              </Button>
            )}
            <Button
              onClick={onConvert}
              endIcon={<ArrowRightIcon />}
              data-test-id="application-preview-convert-button"
            >
              <FormattedMessage id="applications-Action-Convert" />
            </Button>
          </Stack>
        </ModalFooter>
      }
    />
  );
};
