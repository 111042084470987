import { Stack, styled, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useRef, useState } from 'react';

import { HOVER_TEXT_CLASS_NAME } from './StudentProductsModalList';

export interface DiscountInputProps {
  value: number | undefined;
  onChange: (v: number | undefined) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: boolean;
}

export const DiscountInput: FC<DiscountInputProps> = ({
  error,
  value,
  onChange,
  onBlur,
  onFocus,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [lastValue, setLastValue] = useState(value);
  const escPressed = useRef(false);

  const handleFocus = useCallback(() => {
    setIsEdit(true);
    onFocus?.();
    const inputElement = inputRef.current?.querySelector('input');
    inputElement?.focus();
  }, [onFocus]);

  const handleBlur = useCallback(async () => {
    setIsEdit(false);
    onBlur?.();
  }, [onBlur]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value.replace(/[^\d.]+/g, '');

      if (newValue === '') {
        onChange(undefined);
        setLastValue(undefined);
        return;
      }
      const numValue = Math.min(Math.max(Math.abs(parseInt(newValue)), 1), 100);

      onChange(numValue);
      setLastValue(numValue);
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      let inputElement;

      switch (event.code) {
        case 'Enter':
          inputElement = inputRef.current?.querySelector('input');
          inputElement?.blur();
          break;
        case 'Escape':
          escPressed.current = true;
          onChange(lastValue);
          inputElement = inputRef.current?.querySelector('input');
          inputElement?.blur();
      }
    },
    [lastValue, onChange],
  );

  const isEmpty = value === undefined;

  const backgroundColor = useMemo(() => {
    if (isEdit) return 'background.paper';
  }, [isEdit]);

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      inputRef.current?.focus();
      handleFocus();
    },
    [handleFocus],
  );

  return (
    <Stack
      onClick={clickHandler}
      sx={{
        backgroundColor: backgroundColor,
        height: '100%',
        cursor: 'text',
      }}
    >
      <Stack
        className="inputWrapper"
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
          border: error
            ? `1px solid ${theme.palette.error.main}`
            : isEdit
            ? theme.mixins.borderControlValue()
            : 'none',
          paddingLeft: theme.spacing(1),
          margin: isEdit || error ? '-1px' : 0,
          width: isEdit || error ? 'calc(100% + 2px)' : '100%',
          height: isEdit ? 46 : 44,
          zIndex: isEdit ? 2 : undefined,
          'input[type=number]': {
            '-moz-appearance': 'textfield',
          },
          'input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          'input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        })}
      >
        <Input
          size={value ? String(value).length : 1}
          ref={inputRef}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value}
        />
        {!isEmpty && (
          <Typography
            color="common.grey"
            className={HOVER_TEXT_CLASS_NAME}
            sx={{ pointerEvents: 'none' }}
          >
            %
          </Typography>
        )}
        {isEmpty && !isEdit && (
          <Typography
            color="common.grey"
            sx={(theme) => ({
              position: 'absolute',
              left: theme.spacing(1),
              pointerEvents: 'none',
            })}
          >
            -
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const Input = styled('input')(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  color: theme.palette.text.primary,
  border: 'none',
  background: 'none',
  padding: 0,
}));
