import { ClickAwayListener } from '@mui/material';
import { FC, useCallback, useState } from 'react';

type WithOpenByClickProps = {
  showOnClick?: boolean;
  children: (
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    show?: boolean,
  ) => React.ReactNode;
};

export const WithShowOnClick: FC<WithOpenByClickProps> = ({ showOnClick, children }) => {
  const [showContent, setShowContent] = useState(false);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (showOnClick) {
        e.stopPropagation();
        e.preventDefault();
        setShowContent((s) => !s);
      }
    },
    [showOnClick],
  );
  const onClickAway = useCallback(() => {
    if (showContent) {
      setShowContent(false);
    }
  }, [showContent]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div>{children(handleClick, showOnClick ? showContent : undefined)}</div>
    </ClickAwayListener>
  );
};
