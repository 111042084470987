import { Stack, Typography } from '@mui/material';
import { FilterElementType, UserWithGroupMembershipNormalized } from '@schooly/api';
import { Genders, Nationalities } from '@schooly/constants';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { GroupCriteriaTag } from '../../../ManageGroupModal/GroupCriteriaInfo.styled';

export interface GroupMemberTagProps {
  membership: UserWithGroupMembershipNormalized['membership'];
}

export const GroupMemberTag: FC<GroupMemberTagProps> = ({ membership }) => {
  const isIndividual = membership.some((m) => m.individual);

  const userSchoolProperties = useMemo(
    () => membership?.flatMap((m) => m.school_properties ?? []),
    [membership],
  );

  const criteriaProperties = membership.reduce<Array<{ name: string; id: number }>>(
    (acc, { criteria_type, enum_criteria }) => {
      if (!enum_criteria?.length || !criteria_type) {
        return acc;
      }

      const enumValue = FilterElementType.Gender === criteria_type ? Genders : Nationalities;
      const data = enum_criteria.map((c, i) => ({ name: enumValue[c], id: i }));

      return [...acc, ...data.filter(({ name }) => !acc.some((d) => d.name === name))];
    },
    [],
  );

  return (
    <Stack direction="row" gap={1} alignItems="center" py={1}>
      {criteriaProperties.map((p, i, arr) => (
        <React.Fragment key={p.id}>
          <GroupCriteriaTag>
            <Typography>{p.name}</Typography>
          </GroupCriteriaTag>
          {i !== arr.length - 1 && ','}
        </React.Fragment>
      ))}
      {userSchoolProperties.map((p, i, arr) => (
        <React.Fragment key={p.property_id}>
          <GroupCriteriaTag>
            <Typography>{p.property_name}</Typography>
          </GroupCriteriaTag>
          {i !== arr.length - 1 && ','}
        </React.Fragment>
      ))}
      {isIndividual && (
        <>
          {!!userSchoolProperties.length && ','}
          <Typography color="text.secondary">
            <FormattedMessage id="groups-MembersIndividual" />
          </Typography>
        </>
      )}
    </Stack>
  );
};
