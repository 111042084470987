import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 6C7.25 5.58579 7.58579 5.25 8 5.25H18C18.4142 5.25 18.75 5.58579 18.75 6C18.75 6.41421 18.4142 6.75 18 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6ZM7.25 10C7.25 9.58579 7.58579 9.25 8 9.25H18C18.4142 9.25 18.75 9.58579 18.75 10C18.75 10.4142 18.4142 10.75 18 10.75H8C7.58579 10.75 7.25 10.4142 7.25 10ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H18C18.4142 13.25 18.75 13.5858 18.75 14C18.75 14.4142 18.4142 14.75 18 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z"
      fill="#24275B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12.5H4V13.5H2V12.5ZM3 13.73H4V14.23H3V13.73ZM4 15.5V14.5H2V15.5H4Z"
      fill="#24275B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4.5H2V5.5H3V7.5H4V5.5V4.5H3Z"
      fill="#24275B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8.5H4V9.5L3 10.5H4V11.5H2V10.5L3 9.5H2V8.5Z"
      fill="#24275B"
    />
  </svg>
);
