import { checkUserHealth } from '@schooly/api';
import { MaintenanceEvent, TIME_MARGIN } from '@schooly/api';
import { useCallback, useContext, useEffect } from 'react';

import usePrevious from '../../hooks/usePrevious';
import { MaintenanceContext } from './MaintenanceContext';

export const CHECK_FREQUENCY = 60 * 1000;

const useMaintenance = () => {
  const { init, enabled, timestamp, setInit, setEnabled, setTimestamp } =
    useContext(MaintenanceContext);
  const prevEnabled = usePrevious(enabled);

  const handleMaintenance = useCallback(
    (event: Event) => {
      const customEvent = event as CustomEvent<MaintenanceEvent>;

      const timestamp = customEvent.detail.timestamp ?? 0;

      setTimestamp(timestamp);

      if (timestamp) {
        setEnabled(timestamp ? timestamp < Date.now() + TIME_MARGIN : false);
      } else {
        setEnabled(!!customEvent.detail.enabled);
      }
    },
    [setEnabled, setTimestamp],
  );

  useEffect(() => {
    window.addEventListener('maintenance', handleMaintenance);

    return () => {
      window.removeEventListener('maintenance', handleMaintenance);
    };
  }, [handleMaintenance]);

  useEffect(() => {
    if (prevEnabled && !enabled) {
      window.location.reload();
    }
  }, [enabled, prevEnabled]);

  useEffect(() => {
    if (!enabled) return undefined;

    const intervalId = setInterval(checkUserHealth, CHECK_FREQUENCY);

    return () => clearInterval(intervalId);
  }, [enabled]);

  useEffect(() => {
    if (!init) {
      checkUserHealth().then(() => {
        setInit(true);
      });
    }
  }, [init, setInit]);

  return { init, enabled, timestamp };
};

export default useMaintenance;
