import { Box, Stack } from '@mui/material';
import { AvailableCriteria } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { SchoolUserType } from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading } from '@schooly/style';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getCriteriaName, getUserTypeTextId } from '../../../helpers/misc';
import { getSchoolUserId } from '../../../helpers/school';
import SearchInput from '../../ui/SearchInput';
import { NoSearchResultsFound } from '../NoSearchResultsFound/NoSearchResultsFound';
import PersonCardSelectable from '../PersonCard/PersonCardSelectable';
import UserCriteria from '../UserCriteria';

import './index.scss';

export interface UserWithCriteria extends SimpleListResult {
  criteria?: string[];
}

export interface AvailableUsersProps {
  userType: SchoolUserType;
  userList?: SimpleListResult[];
  onUserClick: (userId: string) => void;
  selectedUsers: SimpleListResult[];
  selectedCriteria?: AvailableCriteria[];
  searchPlaceholderTextId: string;
  isFetching?: boolean;
  isSearching?: boolean;
  handleShowMore?: VoidFunction;
  canShowMore?: boolean;
  filter: string;
  setFilter?: any;
}

const AvailableUsers: React.FC<AvailableUsersProps> = ({
  userType,
  userList,
  onUserClick,
  selectedUsers,
  selectedCriteria,
  searchPlaceholderTextId,
  isFetching,
  isSearching,
  handleShowMore,
  canShowMore,
  filter,
  setFilter,
}) => {
  const { formatMessage } = useIntl();

  const handleFilterChange = useCallback(
    (newFilter: string) => {
      setFilter(newFilter);
    },
    [setFilter],
  );

  const usersListWithCriteria = useMemo(() => {
    if (userList) {
      return userList.map((user) => {
        const userCriteria = selectedCriteria?.filter((c) =>
          c?.relation_ids?.includes(user.relation_id),
        );

        if (userCriteria?.length) {
          return { ...user, criteria: userCriteria.map((c) => getCriteriaName(c)) };
        }

        return user;
      });
    }

    return userList;
  }, [userList, selectedCriteria]);

  const users = userType === 'student' ? usersListWithCriteria : userList;

  const displayedUserList = useMemo(() => {
    if (!users) {
      return users;
    }

    const selectedUserIds = selectedUsers.map(getSchoolUserId);
    return users.filter((user) => !selectedUserIds.includes(getSchoolUserId(user)));
  }, [users, selectedUsers]);

  const totalCount = useMemo(
    () => (displayedUserList ? displayedUserList.length : 0),
    [displayedUserList],
  );

  const isResultEmpty = filter.length && !totalCount && !isSearching && !isFetching;

  const loaderRef = useInfiniteScroll(isFetching || !!isResultEmpty || isSearching, handleShowMore);

  const renderContent = () => {
    if (isResultEmpty) {
      return (
        <div className="AvailableUsers__empty">
          <NoSearchResultsFound />
        </div>
      );
    }

    if (isSearching || !displayedUserList || (!canShowMore && isFetching)) {
      return <Loading />;
    }

    return (
      <Box
        mr={-2}
        pr={2}
        sx={{
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          overscrollBehavior: 'contain',
        }}
      >
        {displayedUserList.map((user: UserWithCriteria) => (
          <PersonCardSelectable
            isUsernameClickable
            isUsernameLinkView
            key={user.relation_id}
            user={user}
            userType={userType}
            onClick={onUserClick}
            isListItem
          >
            {user.criteria && <UserCriteria userCriteria={user.criteria} />}
          </PersonCardSelectable>
        ))}
        {isSearching && <Loading />}
        {canShowMore && handleShowMore && (
          <div className="py-3">
            {isFetching && <Loading />}
            <div ref={loaderRef} />
          </div>
        )}
      </Box>
    );
  };

  return (
    <Stack
      mr={-2}
      pr={2}
      sx={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <SearchInput
        value={filter}
        autoFocus
        onChange={handleFilterChange}
        isValueRemovable
        placeholder={formatMessage(
          { id: searchPlaceholderTextId },
          {
            userType: formatMessage({ id: getUserTypeTextId(userType, true) }).toLowerCase(),
          },
        )}
      />

      {renderContent()}
    </Stack>
  );
};

export default AvailableUsers;
