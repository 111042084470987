import { Button, IconButton, Stack } from '@mui/material';
import { School } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, CrossIcon, Spin } from '@schooly/style';
import { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ControlTextField } from '../../../../components/uikit-components/FormTextField/ControlTextField';
import {
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalMain,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { useRouter } from '../../../../context/router/useRouter';
import { useSchool } from '../../../../hooks/useSchool';
import { SchoolSettingsLayout } from '../../SchoolSettingsLayout';

export interface SchoolGeneralNameForm {
  name: School['name'];
  code: School['code'];
}

export const SchoolGeneralNameModal: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { goBack } = useRouter();
  const { currentSchool, updateSchoolMutation } = useSchool();
  const form = useForm<SchoolGeneralNameForm>({
    defaultValues: {
      name: currentSchool?.name,
      code: currentSchool?.code,
    },
  });

  const handleClose = useCallback(() => {
    if (updateSchoolMutation.isLoading) {
      return;
    }

    goBack();
  }, [goBack, updateSchoolMutation.isLoading]);

  const handleSubmit = useCallback<SubmitHandler<SchoolGeneralNameForm>>(
    async (data) => {
      if (!currentSchool) {
        return;
      }

      updateSchoolMutation.mutate(
        {
          school_id: currentSchool.id,
          ...data,
        },
        {
          onSuccess: goBack,
          onError: showError,
        },
      );
    },
    [currentSchool, goBack, showError, updateSchoolMutation],
  );

  return (
    <SchoolSettingsLayout>
      <ModalConfirm open fullWidth>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader title={currentSchool?.name} withBorderBottom={false} active>
              <IconButton
                sx={updateSchoolMutation.isLoading ? { visibility: 'hidden' } : undefined}
                onClick={handleClose}
              >
                <CrossIcon />
              </IconButton>
            </ModalHeader>
            <ModalMain>
              <ModalContent active>
                <Stack gap={2}>
                  <ControlTextField
                    name="name"
                    control={form.control}
                    label={$t({ id: 'school-create-SchoolName' })}
                    rules={{ required: true }}
                    fullWidth
                    canClear
                  />
                  <ControlTextField
                    name="code"
                    control={form.control}
                    label={$t({ id: 'school-create-Acronym' })}
                    rules={{ required: true }}
                    fullWidth
                    canClear
                  />
                </Stack>
              </ModalContent>
            </ModalMain>
            <ModalFooter withBorderTop={false} active>
              <Button
                variant="outlined"
                disabled={updateSchoolMutation.isLoading}
                onClick={handleClose}
              >
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={updateSchoolMutation.isLoading}
                endIcon={updateSchoolMutation.isLoading ? <Spin /> : <CheckIcon />}
              >
                <FormattedMessage id="action-Save" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalConfirm>
    </SchoolSettingsLayout>
  );
};
