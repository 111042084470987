import { Comment } from '../base';
import { NumberOrNull, StringOrNull } from '../misc';
import { WithAvatar } from '../partials';
import { SchoolRelation } from '../relations';

export enum ConductConnotation {
  POSITIVE = 1,
  NEGATIVE = 2,
}

export enum ConductTypeRepresentation {
  TEXT = 1,
  NUMBER = 2,
}

export enum ConductVisibility {
  NOT_PUBLISHED = 1,
  PUBLISHED = 2,
}

export interface ConductTypeUpdate {
  name?: string;
  connotation?: ConductConnotation;
  description?: StringOrNull;
}

export interface ConductTypeCreate {
  name: string;
  type: ConductTypeRepresentation;
  connotation: ConductConnotation;
  order: number;
  description?: StringOrNull;
}

export interface ConductTypeHandle {
  conduct_type_id?: string;
  name: string;
  type: ConductTypeRepresentation;
  connotation: ConductConnotation;
  order: number;
  description?: StringOrNull;
}

export interface ConductType {
  id: string;
  type: ConductTypeRepresentation;
  name: string;
  description?: StringOrNull;
  connotation: ConductConnotation;
  order: number;
}

export interface ConductEntry {
  id: string;
  date: string;
  title: StringOrNull;
  value: NumberOrNull;
  student: SchoolRelation & WithAvatar;
  conduct_type: ConductType;
  details: StringOrNull;
  comments: Comment[];
  staff: SchoolRelation & WithAvatar;
  visibility: ConductVisibility;
}

export interface ConductEntryPatchBase {
  title?: StringOrNull;
  value?: StringOrNull;
  details?: string;
  visibility?: ConductVisibility;
}

export interface ConductEntryPatch extends ConductEntryPatchBase {
  comments?: Comment[]; // multiple comments for preview
}

export interface ConductEntryPatchRequest extends ConductEntryPatchBase {
  conduct_type_id: string;
  date: string;
}

export enum ConductCertificates {
  Gold = 'gold',
  Silver = 'silver',
  Bronze = 'bronze',
}

export const ConductCertificateLabels = {
  [ConductCertificates.Gold]: 'Gold',
  [ConductCertificates.Silver]: 'Silver',
  [ConductCertificates.Bronze]: 'Bronze',
};

export const conductCertificateOptions = [
  { value: ConductCertificates.Gold, label: ConductCertificateLabels[ConductCertificates.Gold] },
  {
    value: ConductCertificates.Silver,
    label: ConductCertificateLabels[ConductCertificates.Silver],
  },
  {
    value: ConductCertificates.Bronze,
    label: ConductCertificateLabels[ConductCertificates.Bronze],
  },
];

export const getConductCertificate = (value: string) =>
  conductCertificateOptions.find((option) => option.value === value);

export interface ConductStat {
  school_property_id: string | null;
  school_property_order: number;
  total: number | null;
}
