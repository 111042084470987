import { Box, Button, Stack, Typography } from '@mui/material';
import { COMPLETE_XERO_AUTH_MUTATION, useGetBillingCountsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Loading, PlusIcon } from '@schooly/style';
import { useIsMutating } from '@tanstack/react-query';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { allowRenderSchoolLegalEntities } from '../../../helpers/renderRoutesByPermissions';
import AccessDeniedPage from '../../AccessDenied';
import { SchoolLegalEntitiesContent } from './SchoolLegalEntitiesContent';
import { pathToNewLegalEntity } from './SchoolLegalEntitiesList';

export const SchoolLegalEntities: FC = () => {
  const { permissions, schoolId = '' } = useAuth();
  const { $t } = useIntl();

  const { data, isLoading } = useGetBillingCountsQuery(schoolId, {
    refetchOnMount: 'always',
    enabled: !!schoolId,
  });

  const isConnectingNewTenant = Boolean(
    useIsMutating({ mutationKey: [COMPLETE_XERO_AUTH_MUTATION] }),
  );

  const hasLegalEntities = Boolean(data?.legal_entities.active || data?.legal_entities.archived);

  if (!allowRenderSchoolLegalEntities(permissions)) {
    return <AccessDeniedPage />;
  }

  const renderContent = () => {
    if (isLoading || isConnectingNewTenant) return <Loading />;

    if (!hasLegalEntities) {
      return (
        <Stack alignItems="center" justifyContent="center" flex={1}>
          <Typography variant="h1" textAlign="center" mb={1} maxWidth={450}>
            {$t({ id: 'legalEntities-NoLegalEntities' })}
          </Typography>
          <Typography variant="h3" mb={3} textAlign="center">
            {$t({ id: 'legalEntities-AddLegalEntityToStart' })}
          </Typography>

          <Link to={pathToNewLegalEntity}>
            <Button startIcon={<PlusIcon />}>{$t({ id: 'legalEntities-AddLegalEntity' })}</Button>
          </Link>
        </Stack>
      );
    }

    return (
      <SchoolLegalEntitiesContent
        schoolId={schoolId}
        archivedCount={data?.legal_entities.archived ?? 0}
      />
    );
  };

  return (
    <MainLayout>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="section-LegalEntitiesAndIntegrations" />
      </Box>
      {renderContent()}
      <Outlet />
    </MainLayout>
  );
};
