import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="10px"
    height="14px"
    viewBox="0 0 10 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Shape</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="arrows_menu"
        transform="translate(1.000000, 1.000000)"
        fill="#8E90AD"
        fillRule="nonzero"
      >
        <path
          d="M6.81425015,7.75644684 C7.19898314,7.41452105 7.80101686,7.41452105 8.18574985,7.75644684 C8.57479536,8.10236249 8.60258432,8.67110178 8.26913929,9.04882934 L8.18577801,9.13241813 L4.68576993,12.2434709 C4.33311462,12.5570065 3.79778695,12.5831344 3.41471649,12.3218817 L3.31426934,12.2435058 L-0.185717664,9.13242733 C-0.604764112,8.75997021 -0.604764112,8.12891979 -0.185737859,7.75648062 C0.166908269,7.44300736 0.702198114,7.41688459 1.08527915,7.67810723 L1.18573045,7.75647403 L3.99999498,10.257 L6.81425015,7.75644684 Z M3.31427069,-0.243536982 C3.66691437,-0.556993615 4.20219413,-0.583115001 4.58527715,-0.321901141 L4.68572931,-0.243536982 L8.18583419,2.86763181 C8.60472015,3.2400798 8.60472015,3.87101874 8.18573916,4.24357267 C7.83307031,4.55698385 7.29781804,4.58310145 6.91468324,4.32189402 L6.81421491,4.24353184 L3.99999565,1.742 L1.18573786,4.24351938 C0.86515047,4.52849507 0.39361786,4.57599102 0.0227270846,4.38601761 L-0.085269306,4.32190152 L-0.185717664,4.24353733 C-0.574832223,3.89768429 -0.60262612,3.32886049 -0.269111193,2.95116501 L-0.18573245,2.86758581 L3.31427069,-0.243536982 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);
