import { SelectProps, Stack, Typography } from '@mui/material';
import {
  getSelectedItemsWithGrouping,
  renderPropertyGroupTags,
  SelectedItemWithGrouping,
} from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  SelectMultiple,
  SelectMultipleProps,
  TagSelectCounter,
  TagSelectProperty,
} from '@schooly/style';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSchool } from '../../../hooks/useSchool';

export interface SelectSchoolPropertiesValue {
  studentStatuses?: string[];
  staffStatuses?: string[];
  houses?: string[];
  ageGroups?: string[];
  campuses?: string[];
  departments?: string[];
}

export interface SelectSchoolPropertiesProps
  extends Omit<SelectMultipleProps, 'value' | 'onChange'>,
    Partial<Record<keyof SelectSchoolPropertiesValue, boolean>> {
  value?: SelectSchoolPropertiesValue;
  onChange?: (value: SelectSchoolPropertiesValue) => void;
}

export const SelectSchoolProperties: FC<SelectSchoolPropertiesProps> = ({
  value,
  onChange,
  studentStatuses,
  staffStatuses,
  houses,
  ageGroups,
  campuses,
  departments,
  ...props
}) => {
  const { schoolId = '' } = useSchool();
  const { propertiesMap: studentPropertiesMap, activePropertiesMap: studentActivePropertiesMap } =
    useSchoolProperties(
      {
        schoolId,
        userType: SchoolUserRole.Student,
      },
      { enabled: Boolean(studentStatuses || houses || campuses) },
    );
  const { propertiesMap: staffPropertiesMap, activePropertiesMap: staffActivePropertiesMap } =
    useSchoolProperties(
      {
        schoolId,
        userType: SchoolUserRole.Staff,
      },
      { enabled: Boolean(staffStatuses || departments) },
    );
  const {
    ageGroups: ageGroupsInit,
    activeAgeGroups,
    schoolLevels,
    schoolLevelsWithAgeGroupsMap,
    getAgeGroupsByLevelId,
    getAgeGroupById,
    getSchoolLevelById,
  } = useAgeGroups(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { enabled: Boolean(ageGroups) },
  );

  const [open, setOpen] = useState(props.open ?? props.defaultOpen);
  const [hoveredPropGroupId, setHoveredPropGroupId] = useState('');

  const showStudentStatuses = Boolean(
    studentStatuses && studentActivePropertiesMap?.status?.length,
  );
  const showStaffStatuses = Boolean(staffStatuses && staffActivePropertiesMap?.status?.length);
  const showHouses = Boolean(houses && studentActivePropertiesMap?.house?.length);
  const showAgeGroups = Boolean(ageGroups && activeAgeGroups?.length);
  const showCampuses = Boolean(campuses && studentActivePropertiesMap?.campus?.length);
  const showDepartments = Boolean(departments && staffActivePropertiesMap?.department?.length);

  const selectedValue = useMemo(
    () => [
      ...(value?.studentStatuses ?? []),
      ...(value?.staffStatuses ?? []),
      ...(value?.houses ?? []),
      ...(value?.ageGroups ?? []),
      ...(value?.campuses ?? []),
      ...(value?.departments ?? []),
    ],
    [
      value?.ageGroups,
      value?.campuses,
      value?.departments,
      value?.houses,
      value?.staffStatuses,
      value?.studentStatuses,
    ],
  );

  const handleClose = useCallback<Exclude<SelectProps['onClose'], undefined>>(
    (event) => {
      setOpen(false);
      props.onClose?.(event);
    },
    [props],
  );

  const handleOpen = useCallback<Exclude<SelectProps['onOpen'], undefined>>(
    (event) => {
      const isTagDelete = Boolean(
        event.target &&
          'closest' in event.target &&
          (event.target as HTMLElement).closest('.MuiChip-deleteIcon'),
      );

      if (isTagDelete) {
        return;
      }

      // const select =
      //   event.target &&
      //   'closest' in event.target &&
      //   (event.target as HTMLElement).closest('.MuiSelect-select');
      //
      // TODO: open flag behavior is unexpected in this case
      // if (select && open) {
      //   (select.parentNode?.querySelector('.MuiBackdrop-root') as HTMLDivElement)?.click();
      //   // handleClose(event);
      //   return;
      // }

      setOpen(true);
      props.onOpen?.(event);
    },
    [props],
  );

  const handleChange = useCallback<Exclude<SelectMultipleProps['onChange'], undefined>>(() => {
    // TODO: implement autofill
  }, []);

  const handlePropClick = useCallback(
    (section: keyof SelectSchoolPropertiesValue, property: { id: string }, arr: { id: string }[]) =>
      () => {
        let isDelete = false;

        const newSectionValue = (value?.[section] ?? []).filter((val) => {
          if (val === property.id) {
            isDelete = true;
            return false;
          }

          return true;
        });

        if (!isDelete) {
          newSectionValue.push(property.id);
        }

        const newValue = {
          ...value,
          // keep order of properties in the section
          [section]: arr
            .filter((property) => newSectionValue.includes(property.id))
            .map((property) => property.id),
        };

        onChange?.(newValue);
      },
    [onChange, value],
  );

  //For properties that have grouping and set multiple ids at a time
  const handlePropGroupClick = useCallback(
    (section: keyof SelectSchoolPropertiesValue, propertiesIds: string[], arr: { id: string }[]) =>
      () => {
        const newSectionValue = new Set([...(value?.[section] ?? []), ...propertiesIds]);
        if (newSectionValue.size === value?.[section]?.length) {
          newSectionValue.forEach((id) => propertiesIds.includes(id) && newSectionValue.delete(id));
        }

        const newValue = {
          ...value,
          // keep order of properties in the section
          [section]: arr
            .filter((property) => Array.from(newSectionValue).includes(property.id))
            .map((property) => property.id),
        };

        onChange?.(newValue);
      },
    [onChange, value],
  );

  const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups = ageGroupsInit
      .filter(({ id }) => value?.['ageGroups']?.includes(id))
      .map(({ id, level_id }) => ({
        id,
        groupId: level_id,
      }));

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [ageGroupsInit, schoolLevelsWithAgeGroupsMap, value]);

  const renderValue = useCallback<Exclude<SelectProps<string[]>['renderValue'], undefined>>(() => {
    const tags: React.ReactNode[][] = [
      // Statuses
      [
        ...(studentPropertiesMap?.status
          ?.filter((property) => value?.studentStatuses?.includes(property.id))
          .map((property) => (
            <TagSelectProperty
              key={`value-${property.id}`}
              userRole={SchoolUserRole.Student}
              property={property}
              size="small"
              onDelete={
                open
                  ? handlePropClick('studentStatuses', property, studentActivePropertiesMap?.status)
                  : undefined
              }
            />
          )) ?? []),
        ...(staffPropertiesMap?.status
          ?.filter((property) => value?.staffStatuses?.includes(property.id))
          .map((property) => (
            <TagSelectProperty
              key={`value-${property.id}`}
              userRole={SchoolUserRole.Staff}
              property={property}
              size="small"
              onDelete={
                open
                  ? handlePropClick('staffStatuses', property, staffActivePropertiesMap?.status)
                  : undefined
              }
            />
          )) ?? []),
      ],

      // Houses
      [
        ...(studentPropertiesMap?.house
          ?.filter((property) => value?.houses?.includes(property.id))
          .map((property) => (
            <TagSelectProperty
              key={`value-${property.id}`}
              userRole={SchoolUserRole.Student}
              property={property}
              size="small"
              onDelete={
                open
                  ? handlePropClick('houses', property, studentActivePropertiesMap?.house)
                  : undefined
              }
            />
          )) ?? []),
      ],

      // Age groups
      [
        ...renderPropertyGroupTags({
          selectedItems: selectedItemsForAgeGroups,
          getProperty: (i) =>
            i.isGroup
              ? getSchoolLevelById(i.id)
              : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
          getItemsOfGroupIds: (i) =>
            i.isGroup ? getAgeGroupsByLevelId(i.id).map(({ id }) => id) : [i.id],
          onDelete: open
            ? (ids) => {
                handlePropGroupClick('ageGroups', ids, activeAgeGroups)();
                setHoveredPropGroupId('');
              }
            : undefined,
          onGroupMouseEnter: open ? (id) => setHoveredPropGroupId(id) : undefined,
          onGroupMouseLeave: open ? () => setHoveredPropGroupId('') : undefined,
          getTooltip: (i) =>
            i.isGroup && !open
              ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                  <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                ))
              : null,
          tagProps: {
            userRole: SchoolUserRole.Student,
            size: 'small',
          },
        }),
      ],

      // Campuses
      [
        ...(studentPropertiesMap?.campus
          ?.filter((property) => value?.campuses?.includes(property.id))
          .map((property) => (
            <TagSelectProperty
              key={`value-${property.id}`}
              userRole={SchoolUserRole.Student}
              property={property}
              size="small"
              onDelete={
                open
                  ? handlePropClick('campuses', property, studentActivePropertiesMap?.campus)
                  : undefined
              }
            />
          )) ?? []),
      ],

      // Departments
      [
        ...(staffPropertiesMap?.department
          ?.filter((property) => value?.departments?.includes(property.id))
          .map((property) => (
            <TagSelectProperty
              key={`value-${property.id}`}
              userRole={SchoolUserRole.Staff}
              property={property}
              size="small"
              onDelete={
                open
                  ? handlePropClick('departments', property, staffActivePropertiesMap?.department)
                  : undefined
              }
            />
          )) ?? []),
      ],
    ].filter((arr) => arr.length); // remove empty arrays

    return (
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        gap={0.5}
        sx={(theme) => ({
          minHeight: theme.spacing(2.75),

          '.MuiInputBase-sizeSmall &': {
            minHeight: theme.spacing(2.5),
            margin: theme.spacing('1px', 0),
          },
        })}
      >
        {tags.map((arr, index) => (
          <>
            {index > 0 ? <Typography>,</Typography> : null}
            {arr.map((tag, index) => (
              <>
                {index > 0 ? <Typography>+</Typography> : null}
                {tag}
              </>
            ))}
          </>
        ))}
      </Stack>
    );
  }, [
    activeAgeGroups,
    getAgeGroupById,
    getAgeGroupsByLevelId,
    getSchoolLevelById,
    handlePropClick,
    handlePropGroupClick,
    open,
    selectedItemsForAgeGroups,
    staffActivePropertiesMap?.department,
    staffActivePropertiesMap?.status,
    staffPropertiesMap?.department,
    staffPropertiesMap?.status,
    studentActivePropertiesMap?.campus,
    studentActivePropertiesMap?.house,
    studentActivePropertiesMap?.status,
    studentPropertiesMap?.campus,
    studentPropertiesMap?.house,
    studentPropertiesMap?.status,
    value?.campuses,
    value?.departments,
    value?.houses,
    value?.staffStatuses,
    value?.studentStatuses,
  ]);

  return (
    <SelectMultiple
      value={selectedValue}
      renderValue={renderValue}
      {...props}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
    >
      <Stack gap={1}>
        {showStudentStatuses && (
          <Stack gap={0.5}>
            <Typography variant="h4">
              <FormattedMessage
                id={
                  showStaffStatuses
                    ? 'school-properties-studentStatuses'
                    : 'school-properties-statuses'
                }
              />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {studentActivePropertiesMap?.status?.map((property) => (
                <TagSelectProperty
                  key={property.id}
                  userRole={SchoolUserRole.Student}
                  property={property}
                  variant={value?.studentStatuses?.includes(property.id) ? 'filled' : undefined}
                  onClick={handlePropClick(
                    'studentStatuses',
                    property,
                    studentPropertiesMap.status,
                  )}
                />
              ))}
            </Stack>
          </Stack>
        )}

        {showStaffStatuses && (
          <Stack gap={0.5}>
            <Typography variant="h4">
              <FormattedMessage
                id={
                  showStudentStatuses
                    ? 'school-properties-staffStatuses'
                    : 'school-properties-statuses'
                }
              />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {staffActivePropertiesMap.status.map((property) => (
                <TagSelectProperty
                  key={property.id}
                  userRole={SchoolUserRole.Staff}
                  property={property}
                  variant={value?.staffStatuses?.includes(property.id) ? 'filled' : undefined}
                  onClick={handlePropClick('staffStatuses', property, staffPropertiesMap.status)}
                />
              ))}
            </Stack>
          </Stack>
        )}

        {showHouses && (
          <Stack gap={0.5}>
            <Typography variant="h4">
              <FormattedMessage id="school-properties-houses" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {studentActivePropertiesMap.house.map((property) => (
                <TagSelectProperty
                  key={property.id}
                  userRole={SchoolUserRole.Student}
                  property={property}
                  variant={value?.houses?.includes(property.id) ? 'filled' : undefined}
                  onClick={handlePropClick('houses', property, studentPropertiesMap.house)}
                />
              ))}
            </Stack>
          </Stack>
        )}

        {showAgeGroups && (
          <>
            {Boolean(schoolLevels.length) && (
              <Stack gap={0.5}>
                <Typography variant="h4">
                  <FormattedMessage id="school-properties-ageGroups-schoolLevels" />
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {schoolLevels.map((level) => {
                    const ageGroupsOfLevelIds = getAgeGroupsByLevelId(level.id).map((g) => g.id);
                    return (
                      <TagSelectProperty
                        userRole={SchoolUserRole.Student}
                        key={level.id}
                        variant={
                          selectedItemsForAgeGroups.some(
                            (item: SelectedItemWithGrouping) => item.id === level.id,
                          )
                            ? 'filled'
                            : undefined
                        }
                        onClick={handlePropGroupClick(
                          'ageGroups',
                          ageGroupsOfLevelIds,
                          ageGroupsInit,
                        )}
                        className={hoveredPropGroupId === level.id ? 'hoveredTag' : undefined}
                        onMouseEnter={() => setHoveredPropGroupId(level.id)}
                        onMouseLeave={() => setHoveredPropGroupId('')}
                        label={
                          <Stack direction="row" alignItems="center" gap={0.5}>
                            {level.name}
                            <TagSelectCounter inverse>
                              {getAgeGroupsByLevelId(level.id).length}
                            </TagSelectCounter>
                          </Stack>
                        }
                      />
                    );
                  })}
                </Stack>
              </Stack>
            )}
            <Stack gap={0.5}>
              <Typography variant="h4">
                <FormattedMessage id="school-properties-ageGroups" />
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {activeAgeGroups.map((property) => (
                  <TagSelectProperty
                    key={property.id}
                    userRole={SchoolUserRole.Student}
                    property={property}
                    variant={value?.ageGroups?.includes(property.id) ? 'filled' : undefined}
                    onClick={handlePropGroupClick('ageGroups', [property.id], ageGroupsInit)}
                    outlined={Boolean(
                      hoveredPropGroupId && property.level_id === hoveredPropGroupId,
                    )}
                  />
                ))}
              </Stack>
            </Stack>
          </>
        )}

        {showCampuses && (
          <Stack gap={0.5}>
            <Typography variant="h4">
              <FormattedMessage id="school-properties-campuses" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {studentActivePropertiesMap.campus.map((property) => (
                <TagSelectProperty
                  key={property.id}
                  userRole={SchoolUserRole.Student}
                  property={property}
                  variant={value?.campuses?.includes(property.id) ? 'filled' : undefined}
                  onClick={handlePropClick('campuses', property, studentPropertiesMap.campus)}
                />
              ))}
            </Stack>
          </Stack>
        )}

        {showDepartments && (
          <Stack gap={0.5}>
            <Typography variant="h4">
              <FormattedMessage id="school-properties-departments" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {staffActivePropertiesMap.department.map((property) => (
                <TagSelectProperty
                  key={property.id}
                  userRole={SchoolUserRole.Staff}
                  property={property}
                  variant={value?.departments?.includes(property.id) ? 'filled' : undefined}
                  onClick={handlePropClick('departments', property, staffPropertiesMap.department)}
                />
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </SelectMultiple>
  );
};
