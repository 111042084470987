import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1281 15.8707C12.9565 15.6501 12.9574 15.3503 13.1302 15.1305L14.6066 13.2535C14.732 13.0941 14.9305 13 15.1415 13L16.8766 13C17.0889 13 17.2884 13.0952 17.4137 13.2562L18.8719 15.1311C19.0427 15.3507 19.0427 15.649 18.8719 15.8686L17.4137 17.7437C17.2884 17.9048 17.0889 18 16.8766 18H15.1218C14.9095 18 14.71 17.9048 14.5847 17.7437L13.1281 15.8707ZM15.9992 16.125C16.3665 16.125 16.6643 15.8452 16.6643 15.5C16.6643 15.1548 16.3665 14.875 15.9992 14.875C15.6318 14.875 15.334 15.1548 15.334 15.5C15.334 15.8452 15.6318 16.125 15.9992 16.125Z"
      fill="#B3BBCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3H4C3.44772 3 3 3.44772 3 4V6H17V4C17 3.44772 16.5523 3 16 3H15C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3H7C7 2.44772 6.55228 2 6 2ZM3 7H17V12H14.8554C14.574 12 14.3094 12.1317 14.1422 12.3549L12.1737 14.9827C11.9432 15.2904 11.942 15.7101 12.1708 16.019L12.8974 17H4C3.44772 17 3 16.5523 3 16V7Z"
      fill="#B3BBCB"
    />
  </svg>
);
