import { IActionButton } from '@schooly/api';
import React from 'react';

import buildClassName from '../../../utils/buildClassName';
import Button from '../Button';
import ContextMenu from '../ContextMenu';

import './index.scss';

interface IProps {
  className?: string;
  actionButtons?: IActionButton[];
}

const ActionButtons: React.FC<IProps> = ({ className, actionButtons }) => {
  if (!actionButtons || !actionButtons.length) {
    return null;
  }

  return (
    <div className={buildClassName('ActionButtons', className)}>
      {actionButtons.map((button) =>
        button.contextActions ? (
          <ContextMenu
            key={button.titleTextId}
            trigger={({ toggleOpen }) => (
              <Button
                onClick={toggleOpen}
                color="secondary"
                isCustomShape
                className="rounded-circle"
                icon={button.icon}
                titleTextId={button.titleTextId}
              />
            )}
            actions={button.contextActions}
            position="right"
          />
        ) : (
          <Button
            key={button.titleTextId}
            onClick={button.onClick}
            color="secondary"
            isCustomShape
            className="rounded-circle"
            icon={button.icon}
            titleTextId={button.titleTextId}
          />
        ),
      )}
    </div>
  );
};

export default ActionButtons;
