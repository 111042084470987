import { Countries, Currencies } from '@schooly/constants';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { ApiError, PagedResponse } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { BILLING_URL } from './commonPaths';
import { XeroTenant } from './integrations';
import { InternalAccount, XeroAccount } from './invoices';
import * as api from './requests';

export type LegalEntityCommon = {
  display_name: string;
  name: string;
  tax_number?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  country: Countries;
  region?: string;
  zip_code?: string;
  currency: Currencies;
  registration_number?: string;
};

export type LegalEntityWithoutIntegration = {
  id: string;
  connected_tenant: null;
  archived: boolean;
  can_be_deleted: boolean;
  accounts: [InternalAccount];
  has_products: boolean;
} & LegalEntityCommon;

type LegalEntityWithIntegration<T, A> = {
  connected_tenant: T;
  accounts: A[];
} & Omit<LegalEntityWithoutIntegration, 'connected_tenant' | 'accounts'>;

type LegalEntityConnectedXeroTenant = Pick<XeroTenant, 'name' | 'id'>;

export type LegalEntityWithXeroIntegration = LegalEntityWithIntegration<
  LegalEntityConnectedXeroTenant,
  XeroAccount
>;

export type LegalEntity = LegalEntityWithoutIntegration | LegalEntityWithXeroIntegration;

const LEGAL_ENTITIES_URL = `${BILLING_URL}/legal-entities`;
const DEFAULT_PAGE_SIZE = 30;

type GetLegalEntityParams = {
  id: string;
  schoolId: string;
};

export const getLegalEntity = async ({
  id,
  schoolId,
}: GetLegalEntityParams): Promise<LegalEntity> => {
  return api.get(`${LEGAL_ENTITIES_URL}/${id}`, {
    params: {
      school_id: schoolId,
    },
  });
};

export const GET_LEGAL_ENTITY_QUERY = `${LEGAL_ENTITIES_URL}/GET_LEGAL_ENTITY_QUERY`;

export const useGetLegalEntityQuery = (
  params: GetLegalEntityParams,
  options?: RQUseQueryOptions<LegalEntity>,
) => {
  return useQuery<LegalEntity, ApiError>(
    [GET_LEGAL_ENTITY_QUERY, params],
    () => getLegalEntity(params),
    {
      ...options,
    },
  );
};

export type GetLegalEntitiesParams = {
  schoolId: string;
  query?: string;
  archived?: boolean;
  pageSize?: number;
  pageNumber?: number;
  relationId?: string;
};

export const getLegalEntities = async ({
  schoolId,
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  archived,
}: GetLegalEntitiesParams): Promise<PagedResponse<LegalEntity>> => {
  return api.get(`${LEGAL_ENTITIES_URL}`, {
    params: {
      school_id: schoolId,
      page_size: pageSize,
      page_number: pageNumber,
      archived,
      search_query: query || undefined,
    },
  });
};

export interface CheckLegalEntityUniqueFieldsParams {
  schoolId: string;
  name?: string;
  tax_number?: string;
}

export interface CheckLegalEntityUniqueFieldsResponse {
  name_is_unique?: boolean;
  tax_number_duplicate_name?: string;
}

const checkLegalEntityUniqueFields = ({
  schoolId,
  ...params
}: CheckLegalEntityUniqueFieldsParams): Promise<CheckLegalEntityUniqueFieldsResponse> => {
  return api.post(`${LEGAL_ENTITIES_URL}/validate-legal-entity?school_id=${schoolId}`, {
    ...params,
  });
};

export const useCheckLegalEntityUniqueFieldsMutation = (
  options?: RQUseMutationOptions<
    CheckLegalEntityUniqueFieldsResponse,
    CheckLegalEntityUniqueFieldsParams
  >,
): RQUseMutationResult<
  CheckLegalEntityUniqueFieldsResponse,
  CheckLegalEntityUniqueFieldsParams
> => {
  return useMutation(checkLegalEntityUniqueFields, {
    ...options,
  });
};

export const GET_LEGAL_ENTITIES_QUERY = `${LEGAL_ENTITIES_URL}/GET_LEGAL_ENTITIES_QUERY`;

export const useGetLegalEntitiesListQuery = (
  initialParams: GetLegalEntitiesParams,
  options?: RQUseInfiniteQueryOptions<PagedResponse<LegalEntity>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<LegalEntity>, ApiError>(
    [GET_LEGAL_ENTITIES_QUERY, params],
    ({ pageParam }) =>
      getLegalEntities({
        pageNumber: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};
export type ReplaceProductToAccount = { product_id: string; account_id: string };

export type LegalEntitySave = Omit<
  LegalEntityWithoutIntegration,
  'id' | 'archived' | 'can_be_deleted' | 'has_products' | 'accounts' | 'connected_tenant'
> & { connected_tenant_id: null | XeroTenant['id'] };

export type CreateLegalEntityParams = {
  schoolId: string;
  legalEntityParams: LegalEntitySave;
  replace_to_accounts?: Array<ReplaceProductToAccount>;
};

export const createLegalEntity = ({
  schoolId,
  legalEntityParams,
  replace_to_accounts,
}: CreateLegalEntityParams): Promise<LegalEntity> => {
  return api.post(`${LEGAL_ENTITIES_URL}?school_id=${schoolId}`, {
    ...legalEntityParams,
    replace_to_accounts,
  });
};

export const useCreateLegalEntityMutation = (
  options?: RQUseMutationOptions<LegalEntity, CreateLegalEntityParams>,
): RQUseMutationResult<LegalEntity, CreateLegalEntityParams> => {
  return useMutation(createLegalEntity, {
    ...options,
  });
};

export type UpdateLegalEntityParams = CreateLegalEntityParams & { id: LegalEntity['id'] };

const updateLegalEntity = ({
  schoolId,
  id,
  legalEntityParams,
  replace_to_accounts,
}: UpdateLegalEntityParams): Promise<LegalEntity> => {
  return api.patch(`${LEGAL_ENTITIES_URL}/${id}?school_id=${schoolId}`, {
    ...legalEntityParams,
    replace_to_accounts,
  });
};

export const useUpdateLegalEntityMutation = (
  options?: RQUseMutationOptions<LegalEntity, UpdateLegalEntityParams>,
): RQUseMutationResult<LegalEntity, UpdateLegalEntityParams> => {
  return useMutation(updateLegalEntity, {
    ...options,
  });
};

export type RemoveLegalEntityParams = {
  schoolId: string;
  id: string;
  replace_to_id?: string;
  replace_to_accounts?: Array<ReplaceProductToAccount>;
};

export type RemoveLegalEntityResponse = {
  success: string;
};

const removeLegalEntity = ({
  schoolId,
  id,
  replace_to_id,
  replace_to_accounts,
}: RemoveLegalEntityParams): Promise<RemoveLegalEntityResponse> => {
  return api.remove(`${LEGAL_ENTITIES_URL}/${id}?school_id=${schoolId}`, {
    replace_to_id,
    replace_to_accounts,
  });
};

export const useRemoveLegalEntityMutation = (
  options?: RQUseMutationOptions<RemoveLegalEntityResponse, RemoveLegalEntityParams>,
): RQUseMutationResult<RemoveLegalEntityResponse, RemoveLegalEntityParams> => {
  return useMutation(removeLegalEntity, {
    ...options,
  });
};
