import { Typography } from '@mui/material';
import {
  GetReportsQueryFilters,
  Report,
  REPORTS_QUERY_FILTER_KEYS,
  useGetReportsQuery,
} from '@schooly/api';
import { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { pickOnlyParamsFromFilterKeys } from '../utils';
import { ReportSelectRow } from './ReportSelectRow';
import { ReportTagSelect, ReportTagSelectProps } from './ReportTagSelect';

type ReportExpandedSelectProps = PropsWithChildren<{
  schoolId: string;
  selectedIds: string[];
  onSelectReportId: (v: string) => void;
  onClear: () => void;
  onClose: () => void;
  filters?: GetReportsQueryFilters;
}>;
export const ReportExpandedSelect: FC<ReportExpandedSelectProps> = ({
  schoolId,
  selectedIds,
  onSelectReportId,
  onClose,
  onClear,
  filters = {},
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage, params, setParams } =
    useGetReportsQuery(
      {
        school_id: schoolId,
        filters: pickOnlyParamsFromFilterKeys(REPORTS_QUERY_FILTER_KEYS, filters),
      },
      {
        refetchOnMount: 'always',
      },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const entries =
      data.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Report[]) ?? [];

    if (!entries.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {entries.map((report) => (
          <ReportSelectRow
            onClick={(id) => {
              onSelectReportId(id);
              handleChangeQuery('');
            }}
            key={report.id}
            report={report}
            isSelected={selectedIds.includes(report.id)}
          />
        ))}
      </>
    );
  }, [data, handleChangeQuery, onSelectReportId, selectedIds]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedIds.length > 0}
      renderContent={renderContent}
      onClose={onClose}
      onClear={onClear}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      onClickInputArea={() => inputRef.current?.focus()}
      onFetchNextPage={fetchNextPage}
    >
      {renderReportTags({
        ids: selectedIds,
        tagProps: { size: 'small' },
        onDelete: onSelectReportId,
      })}
      <SelectSearchInput
        ref={inputRef}
        autoFocus
        value={params.query || ''}
        onChangeText={handleChangeQuery}
      />
    </ExpandedSelect>
  );
};

type RenderReportTagsParams = {
  ids: string[];
  onDelete?: (v: string) => void;
  onClick?: (v: string) => void;
  tagProps?: Omit<ReportTagSelectProps, 'id'> | ((v: string) => Omit<ReportTagSelectProps, 'id'>);
};

export const renderReportTags = ({ ids, onDelete, onClick, tagProps }: RenderReportTagsParams) =>
  ids.map((v) => (
    <ReportTagSelect
      key={v}
      id={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
