import { Button, Stack } from '@mui/material';
import { MODAL } from '@schooly/constants';
import { ArchiveIcon, CheckIcon, DeleteIcon, Spin } from '@schooly/style';
import React, { PropsWithChildren, SyntheticEvent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalFooter } from './Modal.styled';

interface ModalFooterWithActionsProps extends PropsWithChildren {
  saving?: boolean;
  deleting?: boolean;
  disabled?: boolean;
  showDeleteButton?: boolean;
  onSaveClick?: (e: SyntheticEvent) => void;
  onSaveAndCloseClick?: (e: SyntheticEvent) => void;
  onSaveAndPreviewClick?: (e: SyntheticEvent) => void;
  onDeleteClick?: (e: SyntheticEvent) => void;
  isNewItem?: boolean;
  isSelection?: boolean;
  withPreview?: boolean;
  savingWithPreview?: boolean;
  active?: boolean;
  showArchiveButton?: boolean;
  archiving?: boolean;
  onArchiveClick?: (e: SyntheticEvent) => void;
}

export const ModalFooterWithActions: React.FC<ModalFooterWithActionsProps> = ({
  saving,
  deleting,
  disabled,
  onSaveClick,
  onDeleteClick,
  showDeleteButton,
  isNewItem,
  isSelection,
  withPreview,
  onSaveAndCloseClick,
  onSaveAndPreviewClick,
  savingWithPreview,
  active = false,
  children,
  showArchiveButton,
  archiving,
  onArchiveClick,
}) => {
  const hasLeftButton = showDeleteButton || showArchiveButton;
  const textForSubmitButton = useMemo(() => {
    if (isNewItem) {
      return 'action-Create';
    }
    if (isSelection) {
      return 'action-ConfirmSelection';
    }
    return 'action-Save';
  }, [isNewItem, isSelection]);

  const leftButton = useMemo(() => {
    if (!hasLeftButton) return null;

    const icon = showDeleteButton ? <DeleteIcon /> : <ArchiveIcon />;

    return (
      <Button
        startIcon={deleting ? <Spin /> : icon}
        variant="outlined"
        disabled={deleting || saving || archiving}
        onClick={showDeleteButton ? onDeleteClick : onArchiveClick}
        sx={{
          '&.MuiButton-outlined:hover': (theme) => `borderColor: ${theme.palette.error.main}`,
        }}
        data-test-id={showDeleteButton ? MODAL.DELETE : MODAL.ARCHIVE}
      >
        <FormattedMessage id={showDeleteButton ? 'action-Delete' : 'action-Archive'} />
      </Button>
    );
  }, [archiving, deleting, hasLeftButton, onArchiveClick, onDeleteClick, saving, showDeleteButton]);

  return (
    <ModalFooter
      active={active}
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer,
      })}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="end"
        gap={2}
        sx={hasLeftButton ? { width: '100%' } : null}
      >
        {leftButton}
        {withPreview && (
          <Stack direction="row" gap={1}>
            <Button
              type="submit"
              variant="outlined"
              startIcon={saving && !savingWithPreview && <Spin />}
              disabled={disabled || saving}
              onClick={onSaveAndCloseClick}
              data-test-id="modal-save-close"
            >
              <FormattedMessage id="action-SaveAndClose" />
            </Button>
            <Button
              type="submit"
              data-preview
              startIcon={saving && savingWithPreview && <Spin />}
              disabled={disabled || saving}
              onClick={onSaveAndPreviewClick}
              data-test-id={MODAL.SAVE_PREVIEW}
            >
              <FormattedMessage id="action-SaveAndPreview" />
            </Button>
          </Stack>
        )}

        {!withPreview && (
          <Button
            type="submit"
            endIcon={saving ? <Spin /> : <CheckIcon />}
            disabled={disabled || saving}
            onClick={onSaveClick}
            data-test-id="modal-submit"
          >
            <FormattedMessage id={textForSubmitButton} />
          </Button>
        )}
      </Stack>
      {children}
    </ModalFooter>
  );
};
