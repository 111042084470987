import { Checkbox, FormControlLabel, Icon, Theme, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { ProductForm } from '@schooly/api';
import { CheckboxIcon, DoneIcon, RadioOnIcon } from '@schooly/style';
import { ControllerRenderProps } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { RadioOptionCard } from '../../../../../components/uikit-components/FormCheckbox/RadioOptionCard.styled';
import {
  getProductSubscriptionExampleOptions,
  ProductSubscriptionExampleContainer,
  ProductSubscriptionExampleHeader,
  ProductSubscriptionExampleOption,
} from './ProductSubscriptionExample';

export enum ProductSubscriptionType {
  UniqueTypes = 'unique_types',
  OneTypeAssignment = 'one_type_assignment',
  ManyTypesAssignment = 'many_types_assignment',
}

type ProductSubscriptionFieldPaths = 'assignment.one_type' | 'unique_types';

interface ProductSubscriptionOptionProps<T extends ProductSubscriptionFieldPaths> {
  optionType: ProductSubscriptionType;
  labelTextId: string;
  checked: boolean;
  field: ControllerRenderProps<ProductForm, T>;
  onChange: () => void;
  disabled?: boolean;
  showExampleOptions?: boolean;
}

export const getExampleHeaderColor = (theme: Theme, checked: boolean, disabled: boolean) => {
  if (checked) return theme.palette.primary.main;
  if (disabled) return theme.palette.common.grey;
  return theme.palette.common.grey2;
};

export const ProductSubscriptionOption = <T extends ProductSubscriptionFieldPaths>({
  optionType,
  field,
  labelTextId,
  checked,
  onChange,
  disabled,
  showExampleOptions,
}: ProductSubscriptionOptionProps<T>) => {
  const { $t } = useIntl();
  const theme = useTheme();

  const getExampleHeaderColor = () => {
    if (checked) return theme.palette.primary.main;
    if (disabled) return theme.palette.common.grey;
    return theme.palette.common.grey2;
  };
  const getExampleOptionColor = (selected: boolean) => {
    if (selected && checked) return theme.palette.primary.main;
    if (disabled) return selected ? theme.palette.common.grey : theme.palette.common.grey6;
    if (selected || checked) return theme.palette.common.grey2;
    return theme.palette.common.grey;
  };
  const exampleOptions = getProductSubscriptionExampleOptions(
    ProductSubscriptionType.ManyTypesAssignment,
  );

  const renderExampleContent = () => {
    switch (optionType) {
      case ProductSubscriptionType.UniqueTypes:
        return (
          <Stack gap={1}>
            <ProductSubscriptionExampleHeader
              textId="products-SubscriptionExample-Tuition"
              color={getExampleHeaderColor()}
            />
            {showExampleOptions &&
              exampleOptions.map(({ textId, checked }) => (
                <ProductSubscriptionExampleOption
                  key={textId}
                  textId={textId}
                  color={getExampleOptionColor(checked)}
                  icon={
                    <Icon fontSize="small" sx={(theme) => ({ marginX: theme.spacing(0.25) })}>
                      <DoneIcon />
                    </Icon>
                  }
                />
              ))}
          </Stack>
        );

      case ProductSubscriptionType.OneTypeAssignment:
        return (
          <Stack gap={2}>
            <ProductSubscriptionExampleHeader
              textId="products-SubscriptionExample-Lunch"
              color={getExampleHeaderColor()}
            />

            <Stack gap={0.75}>
              {showExampleOptions &&
                exampleOptions.map(({ textId, checked }) => {
                  const color = getExampleOptionColor(checked);

                  return (
                    <ProductSubscriptionExampleOption
                      key={textId}
                      textId={textId}
                      color={color}
                      icon={
                        <Checkbox
                          sx={{ padding: 0, '.svg-icon': { height: 14 } }}
                          checked={checked}
                          checkedIcon={
                            <Icon sx={{ '.svg-icon': { 'circle:nth-child(2)': { fill: color } } }}>
                              <RadioOnIcon className="reset-svg-currentColor" />
                            </Icon>
                          }
                        />
                      }
                    />
                  );
                })}
            </Stack>
          </Stack>
        );
      case ProductSubscriptionType.ManyTypesAssignment:
        return (
          <Stack gap={2}>
            <ProductSubscriptionExampleHeader
              textId="products-SubscriptionExample-Course"
              color={getExampleHeaderColor()}
            />

            <Stack gap={0.5}>
              {exampleOptions.map(({ textId, checked }) => {
                const color = getExampleOptionColor(checked);

                return (
                  <ProductSubscriptionExampleOption
                    key={textId}
                    textId={textId}
                    color={color}
                    icon={
                      <Checkbox
                        sx={{ padding: 0, '.svg-icon': { height: 14 } }}
                        checked={checked}
                        checkedIcon={
                          <Icon sx={{ '.svg-icon circle': { fill: color } }}>
                            <CheckboxIcon className="reset-svg-currentColor" />
                          </Icon>
                        }
                      />
                    }
                  />
                );
              })}
            </Stack>
          </Stack>
        );
    }
  };

  return (
    <RadioOptionCard
      sx={{ width: '100%', gap: 1 }}
      onClick={!disabled ? onChange : undefined}
      checked={checked}
      disabled={disabled}
    >
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            sx={(theme) => ({ padding: 0, marginRight: theme.spacing(1) })}
            name={field.name}
            checked={checked}
            onChange={onChange}
            checkedIcon={<RadioOnIcon className="reset-svg-currentColor" />}
          />
        }
        label={$t({ id: labelTextId })}
      />

      <Typography mb={1}>{$t({ id: 'products-SubscriptionExample-ForExample' })}</Typography>

      <ProductSubscriptionExampleContainer>
        {renderExampleContent()}
      </ProductSubscriptionExampleContainer>
    </RadioOptionCard>
  );
};
