import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.00008 10.189V12.5124C3.00008 13.125 3.28087 13.7039 3.76202 14.0831L8.76202 18.0241C9.48817 18.5965 10.512 18.5965 11.2381 18.0241L16.2381 14.0831C16.7193 13.7039 17.0001 13.125 17.0001 12.5124V10.1889L10 15.6334L3.00008 10.189Z"
      fill="#24275B"
    />
    <path d="M10 14.3666L1.81445 7.99999L10 1.63342L18.1856 7.99999L10 14.3666Z" fill="#24275B" />
  </svg>
);
