import {
  FilterKeys,
  FilterSection,
  FilterSelectOption,
  FilterValue,
  Group,
  GroupUserType,
  SchoolUserType,
  useGetGroupSubjectsQuery,
  UserFilter,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  GENDER_OPTIONS,
  NATIONALITY_OPTIONS,
  PROPERTIES_TEXT_IDS,
  PropertiesTextIds,
  SchoolUserRole,
} from '@schooly/constants';
import { useAgeGroups, useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { GroupArrangeBySectionMap } from '../../components/common/HeaderFilter/ArrangeBySelect';
import { IControl } from '../../components/common/HeaderFilter/HeaderFilterPopup';
import { convertGroupSubjectsToSelectOption } from '../../components/common/HeaderFilter/utils';
import useSchoolYears from '../../hooks/useSchoolYears';
import { useAppDispatch } from '../../redux/hooks';
import { actions as simpleListsActions } from '../../redux/slices/simple-lists/actions';
import searchWords from '../../utils/searchWords';
import { FiltersContext, FiltersFiltersState, getDefaultDraft } from './FiltersContext';
import { AppliedGroupFilters, FilterType, GroupArrangeByOption } from './scheme';

export const SUPPORTED_FILTERS = [
  FilterKeys.ConductStatus,
  FilterKeys.ConductType,
  FilterKeys.Student,
  FilterKeys.Status,
  FilterKeys.StudentStatus,
  FilterKeys.StaffStatus,
  FilterKeys.AgeGroup,
  FilterKeys.StudentAgeGroup,
  FilterKeys.House,
  FilterKeys.StudentHouse,
  FilterKeys.StaffHouse,
  FilterKeys.Gender,
  FilterKeys.Nationality,
  FilterKeys.Subject,
  FilterKeys.OnlyTutorGroups,
  FilterKeys.Department,
  FilterKeys.AssessmentStatus,
  FilterKeys.MessagesStatus,
  FilterKeys.RecipientType,
  FilterKeys.Report,
  FilterKeys.Creator,
  FilterKeys.ReportStatus,
  FilterKeys.Group,
  FilterKeys.Staff,
  FilterKeys.InviteStatus,
];

const SUPPORTED_ARRANGE_BY: Partial<Record<FilterType, FilterKeys[]>> = {
  [FilterType.Parent]: [FilterKeys.Status, FilterKeys.Nationality, FilterKeys.Gender],
};

export const POPUP_OPEN_DELAY_MS = 300;

export const HEADER_FILTER_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * useFilters hook
 *
 * @param pathname should be passed as prop when needed to be able properly handle queryString
 * redirection. When use useAppLocation hook right here, it's impossible to define which
 * implementation should handle queryString.
 */
export const useFilters = (pathname?: string) => {
  const { formatMessage } = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { defaultValidity, isLoading: fetchingSchoolYears } = useSchoolYears();
  const { schoolId = '' } = useAuth();
  const { data } = useGetGroupSubjectsQuery({ schoolId }, { refetchOnMount: 'always' });

  const groupSubjects = data?.subjects;

  const { type, filters, saved, lists, listFilters, actions } = useContext(FiltersContext);

  const [filterType, filterSection] = useMemo(() => {
    switch (type) {
      case 'parents':
        return [FilterType.Parent, FilterSection.Parent];
      case 'user-roles':
        return [FilterType.UserRoles];
      case 'applications':
        return [FilterType.Applications];
      case 'sidebar-groups':
        return [FilterType.SidebarGroups];
      case 'sidebar-report-recipients':
        return [FilterType.SidebarReportRecipients];
      case 'sidebar-group-staff':
        return [FilterType.SidebarGroupStaff];
      case 'sidebar-group-students':
        return [FilterType.SidebarGroupStudents];
      case 'sidebar-relation-groups':
        return [FilterType.SidebarRelationGroups];
      case 'sidebar-user-role-staff':
        return [FilterType.SidebarUserRoleStaff];
      case 'sidebar-assessment-groups':
        return [FilterType.SidebarAssessmentGroups];
      default:
        return [FilterType.Default];
    }
  }, [type]);

  const propertiesUserType: SchoolUserType = useMemo(() => {
    switch (filterType) {
      case FilterType.SidebarUserRoleStaff:
        return 'staff';
      case FilterType.Parent:
      default:
        return 'student';
    }
  }, [filterType]);

  const {
    schoolProperties: studentProperties,
    selectOptions: studentSelectOptions,
    isLoading: isStudentPropertiesLoading,
  } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const {
    schoolProperties: staffProperties,
    selectOptions: staffSelectOptions,
    isLoading: isStaffPropertiesLoading,
  } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Staff,
  });

  const userProperties = propertiesUserType === 'staff' ? staffProperties : studentProperties;

  const propertiesFetched = !isStudentPropertiesLoading && !isStaffPropertiesLoading;

  const propertiesSelectOptions =
    propertiesUserType === 'staff' ? staffSelectOptions : studentSelectOptions;

  const { ageGroupSelectOptions } = useAgeGroups({
    schoolId,
    userType: propertiesUserType === 'staff' ? SchoolUserRole.Staff : SchoolUserRole.Student,
  });
  const { ageGroupSelectOptions: studentAgeGroupSelectOptions, ageGroups: studentAgeGroups } =
    useAgeGroups({
      schoolId,
      userType: SchoolUserRole.Student,
    });

  const destructGroupFilterKey = useCallback((prop: FilterKeys) => {
    const m = /^(student|staff)_(.+)$/.exec(prop);

    if (m) {
      const userType = m[1] as GroupUserType;
      const key = m[2] as FilterKeys;

      return { userType, key };
    }
  }, []);

  const options: Partial<{ [key in FilterKeys]: FilterSelectOption[] | undefined }> =
    useMemo(() => {
      switch (filterType) {
        case FilterType.SidebarGroups:
          return {
            [FilterKeys.Status]: propertiesSelectOptions.status,
            [FilterKeys.AgeGroup]: ageGroupSelectOptions,
            [FilterKeys.House]: propertiesSelectOptions.house,
            [FilterKeys.Subject]: convertGroupSubjectsToSelectOption(groupSubjects),
            [FilterKeys.OnlyTutorGroups]: [],
          };
        case FilterType.SidebarReportRecipients:
          return {
            [FilterKeys.AgeGroup]: ageGroupSelectOptions,
            [FilterKeys.House]: propertiesSelectOptions.house,
            [FilterKeys.Subject]: convertGroupSubjectsToSelectOption(groupSubjects),
            [FilterKeys.OnlyTutorGroups]: [],
            [FilterKeys.Group]: [],
          };
        case FilterType.SidebarAssessmentGroups:
          return {
            [FilterKeys.Status]: propertiesSelectOptions.status,
            [FilterKeys.AgeGroup]: ageGroupSelectOptions,
            [FilterKeys.House]: propertiesSelectOptions.house,
            [FilterKeys.Subject]: convertGroupSubjectsToSelectOption(groupSubjects),
            [FilterKeys.OnlyTutorGroups]: [],
          };
        case FilterType.SidebarGroupStudents:
          return {
            [FilterKeys.Group]: [],
          };
        case FilterType.SidebarRelationGroups:
          return {
            [FilterKeys.Subject]: convertGroupSubjectsToSelectOption(groupSubjects),
            [FilterKeys.OnlyTutorGroups]: [],
          };
        case FilterType.SidebarUserRoleStaff:
          return {
            [FilterKeys.House]: propertiesSelectOptions.house,
            [FilterKeys.Status]: propertiesSelectOptions.status,
            [FilterKeys.Subject]: convertGroupSubjectsToSelectOption(groupSubjects),
          };
        case FilterType.UserRoles:
          return {};
        case FilterType.Applications:
          return {};
        default:
          return {
            ...propertiesSelectOptions,
            [FilterKeys.AgeGroup]: ageGroupSelectOptions,
            [FilterKeys.Gender]: GENDER_OPTIONS,
            [FilterKeys.Nationality]: NATIONALITY_OPTIONS,
            [FilterKeys.Subject]: convertGroupSubjectsToSelectOption(groupSubjects),
            [FilterKeys.OnlyTutorGroups]: [],
          };
      }
    }, [filterType, propertiesSelectOptions, ageGroupSelectOptions, groupSubjects]);

  const groupOptions: Partial<{ [key in FilterKeys]: FilterSelectOption[] | undefined }> =
    useMemo(() => {
      switch (filterType) {
        case FilterType.SidebarGroups:
          return {
            [FilterKeys.StudentStatus]: studentSelectOptions.status,
            [FilterKeys.StaffStatus]: staffSelectOptions.status,
            [FilterKeys.StudentAgeGroup]: studentAgeGroupSelectOptions,
            [FilterKeys.StudentHouse]: studentSelectOptions.house,
            [FilterKeys.StaffHouse]: staffSelectOptions.house,
            [FilterKeys.Subject]: convertGroupSubjectsToSelectOption(groupSubjects),
            [FilterKeys.OnlyTutorGroups]: [],
          };
        case FilterType.SidebarAssessmentGroups:
          return {
            [FilterKeys.StudentStatus]: studentSelectOptions.status,
            [FilterKeys.StudentHouse]: studentSelectOptions.house,
            [FilterKeys.AgeGroup]: ageGroupSelectOptions,
            [FilterKeys.StaffStatus]: staffSelectOptions.status,
            [FilterKeys.StaffHouse]: staffSelectOptions.house,
            [FilterKeys.Subject]: convertGroupSubjectsToSelectOption(groupSubjects),
            [FilterKeys.OnlyTutorGroups]: [],
          };
        default:
          return {};
      }
    }, [
      filterType,
      studentSelectOptions.status,
      studentSelectOptions.house,
      staffSelectOptions.status,
      staffSelectOptions.house,
      studentAgeGroupSelectOptions,
      groupSubjects,
      ageGroupSelectOptions,
    ]);

  const sectionOptions = options;

  const arrangeByOptions: FilterSelectOption<FilterKeys>[] | undefined = useMemo(
    () =>
      SUPPORTED_ARRANGE_BY[filterType]?.map((item) => ({
        value: item,
        label: formatMessage({ id: PROPERTIES_TEXT_IDS[item] }),
      })),
    [filterType, formatMessage],
  );

  const groupArrangeByOptions = useMemo(
    () =>
      SUPPORTED_ARRANGE_BY[filterType]?.reduce(
        (prev, item) => {
          const destructedKey = destructGroupFilterKey(item);

          if (destructedKey) {
            prev[destructedKey.userType]?.push({
              value: item,
              label: formatMessage({ id: PROPERTIES_TEXT_IDS[item] }),
            });

            return prev;
          }

          prev.common?.push({
            value: item,
            label: formatMessage({ id: PROPERTIES_TEXT_IDS[item] }),
          });
          return prev;
        },
        { common: [], student: [], staff: [] } as GroupArrangeBySectionMap,
      ),
    [destructGroupFilterKey, filterType, formatMessage],
  );

  const groupArrangeBy: GroupArrangeByOption | undefined = useMemo(() => {
    const destructuredKey =
      filters.draftArrangeBy && destructGroupFilterKey(filters.draftArrangeBy);
    const section = destructuredKey?.userType ?? 'common';
    const value = filters.draftArrangeBy;

    return value
      ? {
          section,
          option: {
            value,
            label: formatMessage({ id: PROPERTIES_TEXT_IDS[value] }),
          },
        }
      : undefined;
  }, [destructGroupFilterKey, filters.draftArrangeBy, formatMessage]);

  const getDraftValue = useCallback((key: FilterKeys) => filters.draft[key], [filters.draft]);

  const setDraftValue = useCallback(
    (key: FilterKeys, value: FilterValue[]) => {
      actions.setDraftValue({ key, value });
    },
    [actions],
  );

  const setMultipleDraftValues = actions.setMultipleDraftValues;

  const appendDraftValue = useCallback(
    (key: FilterKeys, value: FilterValue | FilterValue[]) => {
      const vals = [...getDraftValue(key), ...(Array.isArray(value) ? value : [value])];
      const newValues =
        sectionOptions[key]
          ?.filter((option) => vals.includes(option.value))
          .map((option) => option.value) ?? [];

      setDraftValue(key, newValues);
    },
    [getDraftValue, sectionOptions, setDraftValue],
  );

  const setDraftArrangeBy = actions.setArrangeBy;
  const setDraftGroupBy = actions.setGroupBy;

  const listOptions = useMemo(
    () => ({
      group: Object.values(lists.groups).map(
        (group) =>
          ({ value: group.id, label: group.name, item: group } as FilterSelectOption<
            string,
            Group
          >),
      ),
    }),
    [lists.groups],
  );

  const getGroupFilterLabelByValue = useCallback(
    (key: FilterKeys, value: FilterValue) => {
      let option: (FilterSelectOption & { isStaff?: boolean }) | undefined;

      switch (key) {
        case FilterKeys.Group:
          option = listOptions.group.find((option) => String(option.value) === String(value));
          break;
        default:
          option =
            options[key]?.find((option) => String(option.value) === String(value)) ??
            groupOptions[`student_${key}` as FilterKeys]?.find(
              (option) => String(option.value) === String(value),
            );
          break;
      }

      if (option) {
        return option;
      }

      option = groupOptions[`staff_${key}` as FilterKeys]?.find((option) => option.value === value);

      if (option) {
        return { ...option, isStaff: true };
      }
    },
    [groupOptions, listOptions.group, options],
  );

  const draftFiltersArray: { key: FilterKeys; values: FilterValue[] }[] = useMemo(() => {
    if (!filters.draft) {
      return [];
    }

    return SUPPORTED_FILTERS.filter((key) => filters.draft && filters.draft[key]?.length > 0).map(
      (key) => ({
        key,
        values: filters.draft![key],
      }),
    );
  }, [filters.draft]);

  const appliedFiltersArray: { key: FilterKeys; values: FilterValue[] }[] = useMemo(() => {
    if (!filters.applied) {
      return [];
    }

    return SUPPORTED_FILTERS.filter(
      (key) => filters.applied && filters.applied[key]?.length > 0,
    ).map((key) => ({
      key,
      values: filters.applied![key],
    }));
  }, [filters.applied]);

  const getGroupFiltersArray = useCallback(
    (array: { key: FilterKeys; values: FilterValue[] }[]) => {
      return array.reduce((prev, filter) => {
        const destructedKey = destructGroupFilterKey(filter.key);

        if (destructedKey) {
          const prevItem = prev.find((item) => item.key === destructedKey.key);

          if (prevItem) {
            prevItem.values[destructedKey.userType] = filter.values;
          } else {
            prev.push({
              key: destructedKey.key,
              values: { [destructedKey.userType]: filter.values },
            });
          }

          return prev;
        }

        prev.push({ key: filter.key, values: { student: filter.values } });
        return prev;
      }, [] as AppliedGroupFilters[]);
    },
    [destructGroupFilterKey],
  );

  const appliedGroupFiltersArray = useMemo(
    () => getGroupFiltersArray(appliedFiltersArray),
    [appliedFiltersArray, getGroupFiltersArray],
  );

  const getArrangeByOption = useCallback(
    (applied?: boolean) => {
      const arrangeBy = applied ? filters.appliedArrangeBy : filters.draftArrangeBy;

      if (!arrangeBy) {
        return undefined;
      }

      const destructuredKey = destructGroupFilterKey(arrangeBy);
      const section = destructuredKey?.userType ?? 'common';

      return {
        section,
        option: arrangeByOptions?.find((item) => item.value === arrangeBy),
      };
    },
    [arrangeByOptions, destructGroupFilterKey, filters.appliedArrangeBy, filters.draftArrangeBy],
  );

  const appliedArrangeByOption = useMemo(() => {
    return getArrangeByOption(true);
  }, [getArrangeByOption]);

  const groupStudentWithStaffFilters = useMemo(() => {
    return SUPPORTED_FILTERS.filter((prop) => groupOptions[prop]).reduce((prev, prop) => {
      const destructedKey = destructGroupFilterKey(prop);

      if (destructedKey) {
        const prevItem = prev.find((item) => item.key === destructedKey.key);

        if (prevItem) {
          // add group options to existing items
          if (!prevItem.groupOptions) {
            prevItem.groupOptions = {};
          }

          prevItem.groupOptions[destructedKey.userType] = groupOptions[prop];
        } else {
          // push new control with initial group options
          prev.push({
            key: destructedKey.key,
            titleTextId: `${PROPERTIES_TEXT_IDS[prop as PropertiesTextIds]}`,
            popupTitleTextId: `${PROPERTIES_TEXT_IDS[prop as PropertiesTextIds]}-plural`,
            groupOptions: {
              [destructedKey.userType]: groupOptions[prop],
            },
          });
        }

        return prev;
      }

      prev.push({
        key: prop,
        titleTextId: `${PROPERTIES_TEXT_IDS[prop as PropertiesTextIds]}`,
        popupTitleTextId: `${PROPERTIES_TEXT_IDS[prop as PropertiesTextIds]}-plural`,
        options: groupOptions[prop],
      });

      return prev;
    }, [] as IControl[]);
  }, [destructGroupFilterKey, groupOptions]);

  const controls: IControl[] = useMemo(() => {
    switch (filterType) {
      case FilterType.SidebarGroups:
        return groupStudentWithStaffFilters;
      case FilterType.SidebarAssessmentGroups:
        return groupStudentWithStaffFilters;
    }

    // non groups
    return SUPPORTED_FILTERS.filter((prop) => options[prop]).map((prop) => ({
      key: prop,
      titleTextId: `${PROPERTIES_TEXT_IDS[prop as PropertiesTextIds]}`,
      popupTitleTextId: `${PROPERTIES_TEXT_IDS[prop as PropertiesTextIds]}-plural`,
      options: options[prop],
    }));
  }, [groupStudentWithStaffFilters, filterType, options]);

  const defaultFiltersStudents = useCallback(
    (dateOnly = false) => {
      if (!studentProperties) {
        return undefined;
      }

      const defaultProperties: Partial<UserFilter> = {};

      if (!dateOnly) {
        const ageGroups = studentAgeGroups.reduce<string[]>((acc, { id, staff_default }) => {
          return !staff_default ? acc : [...acc, id];
        }, []);
        if (ageGroups.length) {
          defaultProperties[FilterKeys.AgeGroup] = ageGroups;
        }
      }

      return {
        ...getDefaultDraft(),
        [FilterKeys.Date]: [''],
        ...defaultProperties,
      };
    },
    [studentAgeGroups, studentProperties],
  );

  const defaultRelationGroups = useCallback((dateOnly = false) => {
    return {
      ...getDefaultDraft(),
      ...(!dateOnly && { [FilterKeys.OnlyTutorGroups]: ['1'] }),
    };
  }, []);

  const defaultFiltersUserRoleStaff = useCallback(() => {
    if (!userProperties) {
      return undefined;
    }

    return {
      ...getDefaultDraft(),
    };
  }, [userProperties]);

  const defaultFiltersAssessmentGroups = useCallback((dateOnly?: boolean) => {
    return {
      ...getDefaultDraft(),
    };
  }, []);

  const defaultFilters: {
    [filterType in FilterType]: (dateOnly?: boolean) => UserFilter | undefined;
  } = useMemo(
    () => ({
      [FilterType.Default]: getDefaultDraft,
      [FilterType.Parent]: defaultFiltersStudents,
      [FilterType.UserRoles]: () => undefined,
      [FilterType.Applications]: () => undefined,
      [FilterType.SidebarGroups]: getDefaultDraft,
      [FilterType.SidebarReportRecipients]: getDefaultDraft,
      [FilterType.SidebarGroupStaff]: getDefaultDraft,
      [FilterType.SidebarGroupStudents]: getDefaultDraft,
      [FilterType.SidebarRelationGroups]: defaultRelationGroups,
      [FilterType.SidebarUserRoleStaff]: defaultFiltersUserRoleStaff,
      [FilterType.SidebarAssessmentGroups]: defaultFiltersAssessmentGroups,
    }),
    [
      defaultFiltersStudents,
      defaultRelationGroups,
      defaultFiltersUserRoleStaff,
      defaultFiltersAssessmentGroups,
    ],
  );

  const defaultArrangeBy: {
    [filterType in FilterType]: (dateOnly?: boolean) => FilterKeys | undefined;
  } = useMemo(
    () => ({
      [FilterType.Default]: () => undefined,
      [FilterType.Parent]: () => undefined,
      [FilterType.UserRoles]: () => undefined,
      [FilterType.Applications]: () => undefined,
      [FilterType.SidebarGroups]: () => undefined,
      [FilterType.SidebarReportRecipients]: () => undefined,
      [FilterType.SidebarGroupStaff]: () => undefined,
      [FilterType.SidebarGroupStudents]: () => undefined,
      [FilterType.SidebarRelationGroups]: () => undefined,
      [FilterType.SidebarUserRoleStaff]: () => undefined,
      [FilterType.SidebarAssessmentGroups]: () => undefined,
    }),
    [],
  );

  const defaultGroupBy: {
    [filterType in FilterType]: (dateOnly?: boolean) => FilterKeys | undefined;
  } = useMemo(
    () => ({
      [FilterType.Default]: () => undefined,
      [FilterType.Parent]: () => undefined,
      [FilterType.UserRoles]: () => undefined,
      [FilterType.Applications]: () => undefined,
      [FilterType.SidebarGroups]: () => undefined,
      [FilterType.SidebarReportRecipients]: () => undefined,
      [FilterType.SidebarGroupStaff]: () => undefined,
      [FilterType.SidebarGroupStudents]: () => undefined,
      [FilterType.SidebarRelationGroups]: () => undefined,
      [FilterType.SidebarUserRoleStaff]: () => undefined,
      [FilterType.SidebarAssessmentGroups]: () => undefined,
    }),
    [],
  );

  const getDefaultFiltersByType = useCallback(
    (filterType: FilterType, dateOnly?: boolean) => ({
      filters: defaultFilters[filterType](dateOnly),
      arrangeBy: defaultArrangeBy[filterType](dateOnly),
      groupBy: defaultGroupBy[filterType](dateOnly),
    }),
    [defaultArrangeBy, defaultFilters, defaultGroupBy],
  );

  const applyFilters = actions.applyFilters;

  const resetFilters = useCallback(
    (payload?: {
      filters?: FiltersFiltersState['draft'];
      arrangeBy?: FilterKeys;
      groupBy?: FilterKeys;
      query?: string;
      apply?: boolean;
      noDebounce?: boolean;
    }) => {
      const data = { noDebounce: true, ...(payload ?? getDefaultFiltersByType(filterType)) };
      applyFilters(data);
    },
    [applyFilters, filterType, getDefaultFiltersByType],
  );

  const clearFilters = useCallback(
    (apply?: boolean, dateOnly?: boolean) => {
      resetFilters({ ...getDefaultFiltersByType(filterType, dateOnly), apply });

      dispatch(simpleListsActions.staff.reset());
      dispatch(simpleListsActions.staff.setSavedSelectedOptions([]));

      dispatch(simpleListsActions.student.reset());
      dispatch(simpleListsActions.student.setSavedSelectedOptions([]));
    },
    [dispatch, filterType, getDefaultFiltersByType, resetFilters],
  );

  const isSet = useCallback(
    (applied?: boolean) => {
      if (!filters.applied) {
        return false;
      }

      const { date = [], ...other } = filters[
        applied ? 'applied' : 'draft'
      ] as FiltersFiltersState['draft'];
      const arrangeBy = filters[
        applied ? 'appliedArrangeBy' : 'draftArrangeBy'
      ] as FiltersFiltersState['draftArrangeBy'];
      const groupBy = filters[
        applied ? 'appliedGroupBy' : 'draftGroupBy'
      ] as FiltersFiltersState['draftGroupBy'];

      const defaultFilters = getDefaultFiltersByType(filterType);

      // The default dates (today) are being considered as not applied filter
      if (
        date[0] !== defaultFilters.filters?.date[0] ||
        date[1] !== defaultFilters.filters?.date[1]
      ) {
        return true;
      }

      if (arrangeBy !== defaultFilters.arrangeBy) {
        return true;
      }

      if (groupBy !== defaultFilters.groupBy) {
        return true;
      }

      // filter is applied if there is any not empty field
      return Object.values(other).some((value) => value.length > 0);
    },
    [filterType, filters, getDefaultFiltersByType],
  );

  const isDraftSet = useMemo(() => isSet(), [isSet]);
  const isFilterApplied = useMemo(() => isSet(true), [isSet]);
  const canReset = isFilterApplied;

  const isShowArrangeByControl = [FilterType.Default].includes(filterType);

  const isShowStaffControl = [FilterType.SidebarGroups].includes(filterType);

  const allowExclusion = [FilterType.SidebarGroupStudents].includes(filterType);

  useEffect(() => {
    // TR-1883: have to wait for schoolProperties and schoolYears load
    // as they are being used in the defaultFilters calculation
    if (
      !filters.applied &&
      propertiesFetched &&
      userProperties &&
      !fetchingSchoolYears &&
      defaultValidity
    ) {
      let urlParams: FiltersFiltersState['draft'] | undefined;
      let urlArrangeBy: FilterKeys | undefined;
      let urlGroupBy: FilterKeys | undefined;

      searchParams.forEach((value, key) => {
        if (getDefaultDraft()[key as FilterKeys]) {
          if (!urlParams) {
            urlParams = {} as FiltersFiltersState['draft'];
          }

          if (
            [
              FilterKeys.Gender,
              FilterKeys.Nationality,
              FilterKeys.MessagesStatus,
              FilterKeys.AssessmentStatus,
              FilterKeys.RecipientType,
              FilterKeys.ConductStatus,
            ].includes(key as FilterKeys)
          ) {
            urlParams[key as FilterKeys] = value.split(',').map(Number);
          } else {
            urlParams[key as FilterKeys] = value.split(',');
          }
        }

        if (key === 'arrange_by') {
          urlArrangeBy = value as FilterKeys;
        }

        if (key === 'group_by') {
          urlGroupBy = value as FilterKeys;
        }
      });

      const payload =
        urlParams || urlArrangeBy || urlGroupBy
          ? { filters: urlParams, arrangeBy: urlArrangeBy, groupBy: urlGroupBy, noDebounce: false }
          : {};

      resetFilters(payload);
    }
  }, [
    defaultValidity,
    fetchingSchoolYears,
    filters.applied,
    propertiesFetched,
    resetFilters,
    searchParams,
    userProperties,
  ]);

  useEffect(() => {
    if (
      !pathname ||
      !/^\/(students|staff|parents|groups|messages|assessments|reports|attendance|conduct)\/?$/.test(
        pathname,
      )
    ) {
      // if there are URL params presented it means this is not the list page,
      // this is user's modal, no need to set search params here
      return;
    }

    if (filters.applied) {
      const params = appliedFiltersArray.reduce((prev, filter) => {
        prev[filter.key] = filter.values.join(',');

        return prev;
      }, {} as Record<string, string>);

      if (filters.appliedArrangeBy) {
        params.arrange_by = filters.appliedArrangeBy;
      }

      if (filters.appliedGroupBy) {
        params.group_by = filters.appliedGroupBy;
      }

      setSearchParams(params, { replace: true });
    }
  }, [
    appliedFiltersArray,
    filters.applied,
    filters.appliedArrangeBy,
    filters.appliedGroupBy,
    pathname,
    setSearchParams,
  ]);

  // useEffect(() => {
  //   // prevent multiple `useEffect` from different components to reinitialize default filters
  //   if (attendanceDefaultSubject && !asyncFiltersInitialized) {
  //     resetFilters();
  //     actions.setAsyncFiltersInitialized(true);
  //   }
  // }, [attendanceDefaultSubject, actions, asyncFiltersInitialized, resetFilters]);

  //=== SEARCH ===//

  const searchSuggestions = useCallback(
    (query: string) => {
      return (Object.keys(sectionOptions) as FilterKeys[])
        .map((key) => ({
          key,
          options:
            sectionOptions[key]?.filter(
              (option) => option.label && searchWords(option.label, query),
            ) ?? [],
        }))
        .filter((item) => item.options.length > 0);
    },
    [sectionOptions],
  );

  return {
    filterType,
    filterSection,
    filters,
    saved,
    setExclude: actions.setExclude,
    getGroupFiltersArray,
    draftFiltersArray,
    appliedFiltersArray,
    appliedGroupFiltersArray,
    appliedArrangeByOption,
    isDraftSet,
    isFilterApplied,
    propertiesUserType,
    options,
    groupSubjects,
    arrangeByOptions,
    groupArrangeByOptions,
    groupArrangeBy,
    controls,
    canReset,
    getDraftValue,
    setDraftValue,
    setMultipleDraftValues,
    appendDraftValue,
    setDraftArrangeBy,
    setDraftGroupBy,
    applyFilters,
    resetFilters,
    clearFilters,
    getGroupFilterLabelByValue,
    getDefaultFiltersByType,
    destructGroupFilterKey,

    // search
    searchSuggestions,

    // common
    userProperties,
    propertiesFetching: isStaffPropertiesLoading || isStaffPropertiesLoading,
    propertiesFetched,
    defaultValidity,
    fetchingSchoolYears,

    // lists
    lists,
    listOptions,
    listFilters,
    setGroupsList: actions.setGroupsList,
    setGroupsListFilters: actions.setGroupsListFilters,

    // controls
    isShowArrangeByControl,
    isShowStaffControl,
    allowExclusion,
  };
};
