import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { StringOrNull } from './apiTypes/misc';
import avatarMiddleware from './avatarMiddleware';
import maintenanceMiddleware from './maintenanceMiddleware';

// TODO: Check if any endpoints need root `response` object.

let VERBOSE = false;

const debugLogger = {
  log: (...params: any[]) => {
    if (VERBOSE) {
      // eslint-disable-next-line no-console
      console.log('[API]', ...params);
    }
  },
};

let instance: AxiosInstance | null = null;

type InitApiParams = {
  verbose?: boolean;
} & AxiosRequestConfig;

export function init({ verbose = false, ...axiosParams }: InitApiParams) {
  VERBOSE = verbose;
  instance = axios.create(axiosParams);

  avatarMiddleware(instance);
  maintenanceMiddleware(instance);

  return instance;
}

export function getAxiosInstance(): AxiosInstance {
  if (!instance) throw new Error('Api must be initialized with init() function first');

  return instance;
}

function instanceOfAxiosError(error: any): error is AxiosError {
  return 'response' in error;
}

async function getAuthToken() {
  try {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();

    return `Bearer ${jwtToken}`;
  } catch (err) {
    return '';
  }
}

export async function get(endpoint: string, config?: AxiosRequestConfig) {
  try {
    debugLogger.log(`GET '${endpoint}'`, config);

    const Authorization = await getAuthToken();

    const response = await getAxiosInstance()({
      url: endpoint,
      method: 'get',
      ...config,
      headers: { Authorization },
    });

    debugLogger.log(`GET '${endpoint}' response:`, response.data);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      return Promise.resolve(undefined);
    }

    if (instanceOfAxiosError(error)) {
      return Promise.reject(error.response ? error.response.data : error.response);
    }

    return Promise.reject();
  }
}

export async function post(endpoint: string, data: any, config?: AxiosRequestConfig) {
  try {
    debugLogger.log(`POST '${endpoint}':`, data);

    const Authorization = await getAuthToken();

    const response = await getAxiosInstance()({
      url: endpoint,
      method: 'post',
      responseType: 'json',
      data,
      ...config,
      headers: { Authorization },
    });

    debugLogger.log(`POST '${endpoint}' response:`, response.data);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      return Promise.resolve(undefined);
    }

    if (instanceOfAxiosError(error)) {
      return Promise.reject(error.response ? error.response.data : error.response);
    }

    return Promise.reject();
  }
}

export async function patch(endpoint: string, data: any, config?: AxiosRequestConfig) {
  try {
    debugLogger.log(`PATCH '${endpoint}':`, data);

    const Authorization = await getAuthToken();

    const response = await getAxiosInstance()({
      url: endpoint,
      method: 'patch',
      responseType: 'json',
      data,
      ...config,
      headers: { Authorization },
    });

    debugLogger.log(`PATCH '${endpoint}' response:`, response.data);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      return Promise.resolve(undefined);
    }

    if (instanceOfAxiosError(error)) {
      return Promise.reject(error.response ? error.response.data : error.response);
    }

    return Promise.reject();
  }
}

export async function remove(endpoint: string, data?: any, config?: AxiosRequestConfig) {
  try {
    debugLogger.log(`DELETE '${endpoint}':`, data);

    const Authorization = await getAuthToken();

    const response = await getAxiosInstance()({
      url: endpoint,
      method: 'delete',
      responseType: 'json',
      data,
      ...config,
      headers: { Authorization },
    });

    debugLogger.log(`DELETE '${endpoint}' response:`, response.data);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      return Promise.resolve(undefined);
    }

    if (instanceOfAxiosError(error)) {
      return Promise.reject(error.response ? error.response.data : error.response);
    }

    return Promise.reject();
  }
}

export async function getPrivateImage(endpoint: StringOrNull, config?: AxiosRequestConfig) {
  if (!endpoint) return;

  try {
    debugLogger.log(`GET '${endpoint}'`, config);

    const Authorization = await getAuthToken();

    const response = await getAxiosInstance()({
      url: endpoint,
      method: 'get',
      ...config,
      responseType: 'blob',
      headers: { Authorization, Accept: 'image/*' },
    });

    debugLogger.log(`GET '${endpoint}' response:`, response);

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      return Promise.resolve(undefined);
    }

    if (instanceOfAxiosError(error)) {
      return Promise.reject(error.response ? error.response.data : error.response);
    }

    return Promise.reject();
  }
}
