import { Select, SelectChangeEvent, SelectProps, Stack, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { MuiSelect } from '../../theme/components/MuiSelect';
import { TagSelect } from '../TagSelect/TagSelect';

export type SelectMultipleChangeEvent<T = any> = Event & {
  target: { value: T[]; name: string };
};

export interface SelectMultipleProps<T = any>
  extends Omit<SelectProps<T>, 'multiple' | 'value' | 'defaultValue' | 'onChange' | 'renderValue'> {
  value?: T[] | '';
  defaultValue?: T[];
  onChange?: (event: SelectMultipleChangeEvent<T>, child: React.ReactNode) => void; //SelectProps<T[]>['onChange'];
  onDelete?: (value: T, index: number) => void;
  renderValue?: (value: T[]) => React.ReactNode;
  renderValueTag?: (value: T, index?: number, arr?: T[]) => React.ReactNode;
  deleteConfirmation?: (value: T) => Promise<boolean>;
}

export const SelectMultiple = <T extends any>({
  onDelete,
  renderValueTag,
  deleteConfirmation,
  ...props
}: SelectMultipleProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(props.open ?? props.defaultOpen);

  const handleClose = useCallback<Exclude<SelectProps['onClose'], undefined>>(
    (event) => {
      setOpen(false);
      props.onClose?.(event);
    },
    [props],
  );

  const handleOpen = useCallback<Exclude<SelectProps['onOpen'], undefined>>(
    (event) => {
      const isTagDelete = Boolean(
        event.target &&
          'closest' in event.target &&
          (event.target as HTMLElement).closest('.MuiChip-deleteIcon'),
      );

      if (isTagDelete) {
        return;
      }

      // const select =
      //   event.target &&
      //   'closest' in event.target &&
      //   (event.target as HTMLElement).closest('.MuiSelect-select');
      //
      // TODO: open flag behavior is unexpected in this case
      // if (select && open) {
      //   (select.parentNode?.querySelector('.MuiBackdrop-root') as HTMLDivElement)?.click();
      //   // handleClose(event);
      //   return;
      // }

      setOpen(true);
      props.onOpen?.(event);
    },
    [props],
  );

  const handleChange = useCallback<Exclude<SelectProps<T[]>['onChange'], undefined>>(
    (event, target) => {
      // On autofill we get a stringified value.
      const items =
        typeof event.target.value === 'string'
          ? `${event.target.value}`.split(',')
          : (event.target.value as string[]);

      const value = Array.isArray(props.children)
        ? props.children
            .filter((child) => items.includes(child.props.value))
            .map((child) => child.props.value)
        : items;

      props.onChange?.(
        {
          ...event,
          target: { ...event.target, value },
        } as SelectMultipleChangeEvent<T>,
        event.target as unknown as React.ReactNode,
      );
    },
    [props],
  );

  const handleDelete = useCallback(
    (value: T, index: number, arr: T[]) => async (event: React.MouseEvent) => {
      if (deleteConfirmation) {
        const isConfirmed = await deleteConfirmation(value);

        if (!isConfirmed) {
          return;
        }
      }

      handleChange(
        {
          target: { value: arr.filter((item) => item !== value) },
        } as SelectChangeEvent<T[]>,
        event.target as unknown as React.ReactNode,
      );
    },
    [deleteConfirmation, handleChange],
  );

  /* Changes popover size on input resize */
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const input = ref.current;

    const onResize = () => {
      const paper = input.querySelector<HTMLDivElement>('.MuiMenu-paper');

      if (paper) {
        paper.style.width = `${input.clientWidth}px`;
        paper.style.minWidth = `${input.clientWidth}px`;
      }
    };

    const observer = new ResizeObserver(onResize);
    observer.observe(input);

    return () => {
      observer.unobserve(input);
    };
  }, []);

  const renderValue = useCallback<Exclude<SelectProps<T[]>['renderValue'], undefined>>(
    (value) => {
      return Array.isArray(value) ? (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          gap={1}
          rowGap={0.5}
          py={0.25}
          minHeight={(theme) => theme.spacing(3)}
        >
          {value.map((item: T, index, arr) => (
            <TagSelect
              key={item as React.Key}
              label={renderValueTag ? renderValueTag(item, index, arr) : (item as React.ReactNode)}
              size="small"
              onDelete={open ? handleDelete(item, index, arr) : undefined}
            />
          ))}
        </Stack>
      ) : renderValueTag ? (
        renderValueTag(value)
      ) : (
        `${value}`
      );
    },
    [handleDelete, open, renderValueTag],
  );

  return (
    <Select
      ref={ref}
      renderValue={renderValue}
      multiple
      MenuProps={{
        ...MuiSelect?.defaultProps?.MenuProps,
        PaperProps: {
          ...MuiSelect?.defaultProps?.MenuProps?.PaperProps,
          sx:
            typeof MuiSelect?.defaultProps?.MenuProps?.PaperProps?.sx === 'function'
              ? (theme) => ({
                  ...(
                    MuiSelect?.defaultProps?.MenuProps?.PaperProps?.sx as (
                      theme: Theme,
                    ) => SystemStyleObject<Theme>
                  )(theme),
                  width: ref.current?.clientWidth, // might be changed in runtime on element resize
                })
              : {
                  ...MuiSelect?.defaultProps?.MenuProps?.PaperProps?.sx,
                  width: ref.current?.clientWidth, // might be changed in runtime on element resize
                },
        },
        disablePortal: true,
      }}
      //SelectDisplayProps={{ onClick: handleClick }}
      {...props}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
    />
  );
};
