import { CollapsePanelAction, CollapsePanelState } from '@schooly/style';
import { createContext } from 'react';

export const initialState: CollapsePanelState = {
  isOpen: true,
  isOpenByHover: false,
  buttonHover: false,
  absolutePosition: false,
  menuHover: false,
};
export interface CollapsiblePanelContextProps {
  dispatch: React.Dispatch<CollapsePanelAction>;
  state: CollapsePanelState;
}

export const CollapsiblePanelContext = createContext<CollapsiblePanelContextProps>({
  state: initialState,
  dispatch: () => {},
});

CollapsiblePanelContext.displayName = 'CollapsiblePanelContext';
