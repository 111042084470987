import { PaymentFrequencyType } from '@schooly/api';
import { Loading, ModalSmall } from '@schooly/style';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useFrequencies } from '../../../../context/frequencies/WithFrequencies';
import { FREQUENCY_SETTINGS_PATH } from '../../SchoolProducts/helpers';
import { MonthlyFrequencyContent } from './MonthlyFrequencyContent';
import { TermlyFrequencyContent } from './TermlyFrequencyContent';
import { WeeklyFrequencyContent } from './WeeklyFrequencyContent';

export const EditFrequencyModal: FC = () => {
  const { id: frequencyId } = useParams<'id'>();
  const navigate = useNavigate();

  const { frequencies, isLoading, currentSchoolYear } = useFrequencies();

  const currentFrequency = frequencies?.find(({ id }) => id === frequencyId);

  if (!currentFrequency) {
    navigate(FREQUENCY_SETTINGS_PATH);

    return null;
  }

  const renderContent = () => {
    const { type } = currentFrequency;
    if (!currentSchoolYear) return null;

    switch (type) {
      case PaymentFrequencyType.Weekly:
        return (
          <WeeklyFrequencyContent
            frequency={currentFrequency}
            currentSchoolYear={currentSchoolYear}
          />
        );
      case PaymentFrequencyType.Monthly:
        return (
          <MonthlyFrequencyContent
            frequency={currentFrequency}
            currentSchoolYear={currentSchoolYear}
          />
        );
      case PaymentFrequencyType.Annually:
        return null;
      case PaymentFrequencyType.Biannually:
        return null;
      case PaymentFrequencyType.OneOff:
        return null;
      case PaymentFrequencyType.Termly:
        return (
          <TermlyFrequencyContent
            currentSchoolYear={currentSchoolYear}
            frequency={currentFrequency}
          />
        );

      default:
        const exhaustiveCheck: never = type;
        console.error(`Unhandled frequency type: ${exhaustiveCheck}`);
    }
  };
  return <ModalSmall open>{isLoading ? <Loading /> : renderContent()}</ModalSmall>;
};
