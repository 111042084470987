import {
  AgeGroup,
  AreaOfLearning,
  Assessment,
  AssessmentToCreate,
  GroupSubject,
  ReportForAssessment,
  ReportStatuses,
  useGetPossibleAutogeneratedAssessmentsMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { usePrevious } from '@schooly/hooks/use-previous';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { useSubjects } from '@schooly/hooks/use-subjects';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm, UseFormReturn } from 'react-hook-form-lts';
import { v4 } from 'uuid';

import { RouterStateContext, RouterStateContextProps } from '../router/RouterStateContext';
import { useRouter } from '../router/useRouter';
import { useReportActions } from './useReportActions';
import { useReport } from './WithReport';

export enum CONTEXT_NAME {
  DRAFT = 'EditReportDraft',
  NOT_PUBLISHED = 'EditReportNotPublished',
}

export type ReportForm = {
  name: string;
  scheduled_publish_date: string;
  subject_ids: string[];
  age_group_ids: string[];
  with_tutor_feedback: boolean;
  areas_of_learning: AreaOfLearning[];
  assessments: Assessment[];
};

const defaultValues: ReportForm = {
  name: '',
  scheduled_publish_date: '',
  with_tutor_feedback: false,
  subject_ids: [],
  age_group_ids: [],
  areas_of_learning: [],
  assessments: [],
};

export enum DraftModalState {
  DraftForm,
  DraftMatrix,
  DraftAssessments,
}

type ReportDraftContextState = {
  autogeneratedAssessments: AssessmentToCreate[];
  modalState: DraftModalState;
  formInitialValues?: ReportForm;
  formRenderId?: string;
};

export const getDraftInitialState = (): ReportDraftContextState => ({
  autogeneratedAssessments: [],
  modalState: DraftModalState.DraftForm,
});

export type ReportDraftContextProps = {
  id?: string;
  form: UseFormReturn<ReportForm>;
  schoolId: string;
  isDeleting: boolean;
  isSaving: boolean;
  isValidating: boolean;
  isFetching: boolean;
  isAdmin: boolean;
  isPropertiesLoading: boolean;
  isSubjectsLoading: boolean;
  report?: ReportForAssessment;
  ageGroupsConfigured: boolean;
  subjectsConfigured: boolean;
  setState: (state: DraftModalState) => void;
  subjects: GroupSubject[];
  activeSubjects: GroupSubject[];
  ageGroups: AgeGroup[];
  activeAgeGroups: AgeGroup[];
  handleDelete: () => void;
  handleClose: () => void;
  handleSubmitNext: (report: ReportForm) => void;
  handleBack: () => void;
  handleOpenAssessments: () => void;
  handleSave: () => void;
  handleCreate: () => void;
} & ReportDraftContextState;

export const ReportDraftContext = createContext<ReportDraftContextProps>({
  ...getDraftInitialState(),
  id: undefined,
  schoolId: '',
  isDeleting: false,
  isSaving: false,
  isValidating: false,
  isFetching: false,
  isPropertiesLoading: false,
  isSubjectsLoading: false,
  isAdmin: false,
  report: undefined,
  form: {} as UseFormReturn<ReportForm>,
  setState: () => {},
  ageGroupsConfigured: false,
  subjectsConfigured: false,
  subjects: [],
  activeSubjects: [],
  ageGroups: [],
  activeAgeGroups: [],
  handleDelete: () => {},
  handleClose: () => {},
  handleSubmitNext: () => {},
  handleBack: () => {},
  handleOpenAssessments: () => {},
  handleSave: () => {},
  handleCreate: () => {},
});

export interface WithReportDraftContextProps {
  onClose?: () => void;
  formValuesOverrides?: Partial<ReportForm>;
}

export const WithReportDraftContext: FC<PropsWithChildren<WithReportDraftContextProps>> = ({
  onClose,
  formValuesOverrides,
  children,
}) => {
  const { goBack } = useRouter();
  const { report } = useReport();
  const { saving, deleting, createReport, updateReport, deleteReport } = useReportActions();
  const id = report?.id;
  const { schoolId = '', permissions } = useAuth();

  const close = onClose ?? goBack;

  const { getConfirmation } = useConfirmationDialog();

  const {
    activeAgeGroups,
    ageGroups,
    isLoading: isPropertiesLoading,
  } = useAgeGroups(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { refetchOnMount: 'always' },
  );

  const {
    subjects,
    activeSubjects,
    isLoading: isSubjectsLoading,
  } = useSubjects({ schoolId }, { refetchOnMount: 'always' });

  const ageGroupsConfigured = !isPropertiesLoading && !!ageGroups.length;
  const subjectsConfigured = !isSubjectsLoading && !!subjects.length;
  const { showError } = useNotifications();
  const getPossibleAutogeneratedAssessments = useGetPossibleAutogeneratedAssessmentsMutation();

  const form = useForm<ReportForm>({
    defaultValues: {
      ...((report && {
        name: report.name,
        scheduled_publish_date: report.scheduled_publish_date,
        subject_ids: report.subjects.map((s) => s.id),
        age_group_ids: report.age_groups.map((s) => s.id),
        assessments: report.assessments?.filter((a) => !a.autogenerated),
        areas_of_learning: report.areas_of_learning,
        with_tutor_feedback: report.with_tutor_feedback,
      }) ??
        defaultValues),

      ...formValuesOverrides,
    },
  });

  const {
    initialized,
    setAutogeneratedAssessments,
    setModalState,
    modalState,
    autogeneratedAssessments,
  } = useEditModalStateContext(form);

  const handleDelete = useCallback(async () => {
    await deleteReport(report, {
      onSuccess: () => {
        close();
      },
    });
  }, [close, deleteReport, report]);

  const handleClose = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({
        textId: 'school-edit-CloseUnsavedConfirmation',
      }))
    )
      return;

    close();
  }, [close, form.formState.isDirty, getConfirmation]);

  const handleSubmitNext = useCallback(
    async (report: ReportForm) => {
      if (isPropertiesLoading || isSubjectsLoading) return;

      getPossibleAutogeneratedAssessments.mutate(
        {
          schoolId,
          report: {
            ...report,
            assessment_ids: [],
            report_status: ReportStatuses.Draft,
          },
        },
        {
          onError: showError,
          onSuccess: ({ assessments }) => {
            setAutogeneratedAssessments(assessments);

            if (
              !!(report.areas_of_learning.length || report.with_tutor_feedback) &&
              !!assessments.length
            ) {
              setModalState(DraftModalState.DraftMatrix);
              return;
            }

            setModalState(DraftModalState.DraftAssessments);
          },
        },
      );
    },
    [
      getPossibleAutogeneratedAssessments,
      isPropertiesLoading,
      isSubjectsLoading,
      schoolId,
      setAutogeneratedAssessments,
      setModalState,
      showError,
    ],
  );

  const handleBack = useCallback(() => {
    const nextModalState = () => {
      switch (modalState) {
        case DraftModalState.DraftForm:
          return modalState;
        case DraftModalState.DraftMatrix:
          return DraftModalState.DraftForm;

        case DraftModalState.DraftAssessments:
          return autogeneratedAssessments?.length
            ? DraftModalState.DraftMatrix
            : DraftModalState.DraftForm;

        default:
          return modalState;
      }
    };

    setModalState(nextModalState());
  }, [autogeneratedAssessments?.length, modalState, setModalState]);

  const handleOpenAssessments = useCallback(
    () => setModalState(DraftModalState.DraftAssessments),
    [setModalState],
  );

  const handleSave = useCallback(async () => {
    const isFormValid = await form.trigger();

    if (!isFormValid) return;

    const report = form.getValues();

    const reportData = {
      ...report,
      assessment_ids: [],
      report_status: ReportStatuses.Draft,
    };

    if (!id) {
      await createReport(reportData, {
        onSuccess: () => {
          close();
        },
      });

      return;
    }

    await updateReport(id, reportData, {
      onSuccess: () => {
        close();
      },
    });
  }, [form, id, updateReport, createReport, close]);

  const handleCreate = useCallback(() => {
    const report = form.getValues();

    const reportData = {
      ...report,
      assessment_ids: report.assessments.map((a) => a.id),
      report_status: ReportStatuses.Unpublished,
    };

    if (!id) {
      createReport(reportData, {
        onSuccess: () => {
          close();
        },
      });

      return;
    }

    updateReport(id, reportData, {
      onSuccess: () => {
        close();
      },
    });
  }, [form, id, updateReport, close, createReport]);

  const isValidating = getPossibleAutogeneratedAssessments.isLoading;
  const isFetching = saving || isValidating;

  return (
    <ReportDraftContext.Provider
      value={{
        id,
        schoolId,
        isDeleting: deleting,
        isSaving: saving,
        isValidating,
        isFetching,
        isSubjectsLoading,
        isPropertiesLoading,
        report,
        form,
        ageGroupsConfigured,
        subjectsConfigured,
        autogeneratedAssessments,
        modalState: modalState,
        setState: setModalState,
        isAdmin: permissions.includes('school_admin'),
        subjects,
        activeSubjects,
        activeAgeGroups,
        ageGroups,
        handleDelete,
        handleClose,
        handleSubmitNext,
        handleBack,
        handleOpenAssessments,
        handleSave,
        handleCreate,
      }}
    >
      {initialized && children}
    </ReportDraftContext.Provider>
  );
};

export const useDraftReport = () => {
  return useContext(ReportDraftContext);
};

export enum NotPublishedModalState {
  NotPublishedInitialMatrix,
  NotPublishedForm,
  NotPublishedAssessments,
  NotPublishedFinalMatrix,
}

export type ReportNotPublishedContextProps = {
  id?: string;
  schoolId: string;
  isDeleting: boolean;
  isSaving: boolean;
  isValidating: boolean;
  isFetching: boolean;
  isAdmin: boolean;
  isPropertiesLoading: boolean;
  isSubjectsLoading: boolean;
  report?: ReportForAssessment;
  form: UseFormReturn<ReportForm>;
  ageGroupsConfigured: boolean;
  subjectsConfigured: boolean;
  autogeneratedAssessments: AssessmentToCreate[] | null;
  state: NotPublishedModalState;
  setState: Dispatch<SetStateAction<NotPublishedModalState>>;
  subjects: GroupSubject[];
  activeSubjects: GroupSubject[];
  activeAgeGroups: AgeGroup[];
  ageGroups: AgeGroup[];
  handleDelete: () => void;
  handleClose: () => void;
  handleSubmitNext: (report: ReportForm) => void;
  handleSave: () => void;
};

export const ReportNotPublishedContext = createContext<ReportNotPublishedContextProps>({
  id: undefined,
  schoolId: '',
  isDeleting: false,
  isSaving: false,
  isValidating: false,
  isFetching: false,
  isPropertiesLoading: false,
  isSubjectsLoading: false,
  isAdmin: false,
  report: undefined,
  form: {} as UseFormReturn<ReportForm>,
  setState: () => {},
  ageGroupsConfigured: false,
  subjectsConfigured: false,
  autogeneratedAssessments: null,
  state: NotPublishedModalState.NotPublishedForm,
  subjects: [],
  activeSubjects: [],
  activeAgeGroups: [],
  ageGroups: [],
  handleDelete: () => {},
  handleClose: () => {},
  handleSubmitNext: () => {},
  handleSave: () => {},
});

export interface WithReportNotPublishedContextProps {
  onClose?: () => void;
  onCloseAndClean?: () => void;
  formValuesOverrides?: Partial<ReportForm>;
}

export const WithReportNotPublishedContext: FC<
  PropsWithChildren<WithReportNotPublishedContextProps>
> = ({ onClose, onCloseAndClean, formValuesOverrides, children }) => {
  const { goBack, closeAndClean: routerCloseAndClean } = useRouter();
  const { report, id = '' } = useReport();
  const { saving, deleting, updateReport, deleteReport } = useReportActions();

  const close = onClose ?? goBack;
  const closeAndClean = onCloseAndClean ?? routerCloseAndClean;

  const { schoolId = '', permissions } = useAuth();
  const [autogeneratedAssessments, setAutogeneratedAssessments] =
    useState<AssessmentToCreate[] | null>(null);
  const [state, setState] = useState<NotPublishedModalState>(() => {
    if (report?.areas_of_learning.length || report?.with_tutor_feedback)
      return NotPublishedModalState.NotPublishedInitialMatrix;

    return NotPublishedModalState.NotPublishedForm;
  });

  const { getConfirmation } = useConfirmationDialog();
  const {
    activeAgeGroups,
    ageGroups,
    isLoading: isPropertiesLoading,
  } = useAgeGroups(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { refetchOnMount: 'always' },
  );

  const {
    subjects,
    activeSubjects,
    isLoading: isSubjectsLoading,
  } = useSubjects({ schoolId }, { refetchOnMount: 'always' });

  const ageGroupsConfigured = !isPropertiesLoading && !!ageGroups.length;
  const subjectsConfigured = !isSubjectsLoading && !!subjects.length;

  const { showError } = useNotifications();
  const getPossibleAutogeneratedAssessments = useGetPossibleAutogeneratedAssessmentsMutation();

  const form = useForm<ReportForm>({
    defaultValues: {
      ...(report
        ? {
            name: report.name,
            scheduled_publish_date: report.scheduled_publish_date,
            subject_ids: report.subjects.map((s) => s.id),
            age_group_ids: report.age_groups.map((s) => s.id),
            assessments: report.assessments?.filter((a) => !a.autogenerated),
            areas_of_learning: report.areas_of_learning,
            with_tutor_feedback: report.with_tutor_feedback,
          }
        : defaultValues),
      ...(formValuesOverrides ?? {}),
    },
  });

  useEffect(() => {
    if (state !== NotPublishedModalState.NotPublishedInitialMatrix) return;

    const report = form.getValues();

    getPossibleAutogeneratedAssessments.mutate(
      {
        schoolId,
        report: {
          ...report,
          assessment_ids: report.assessments.map((a) => a.id),
          report_status: ReportStatuses.Unpublished,
        },
      },
      {
        onError: showError,
        onSuccess: ({ assessments }) => {
          setAutogeneratedAssessments(assessments);
        },
      },
    );

    // Only request once for a particular state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSave = useCallback(async () => {
    const isFormValid = await form.trigger();
    if (!isFormValid) return;

    const report = form.getValues();

    const reportData = {
      ...report,
      assessment_ids: report.assessments.map((a) => a.id),
      report_status: ReportStatuses.Unpublished,
    };

    updateReport(id, reportData, {
      onSuccess: () => {
        close();
      },
    });
  }, [form, updateReport, id, close]);

  const handleDelete = useCallback(async () => {
    await deleteReport(report, {
      onSuccess: () => {
        closeAndClean();
      },
    });
  }, [deleteReport, report, closeAndClean]);

  const handleClose = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({
        textId: 'school-edit-CloseUnsavedConfirmation',
      }))
    )
      return;

    close();
  }, [close, form.formState.isDirty, getConfirmation]);

  const handleSubmitNext = useCallback(
    async (report: ReportForm) => {
      if (isPropertiesLoading || isSubjectsLoading) return;

      getPossibleAutogeneratedAssessments.mutate(
        {
          schoolId,
          report: {
            ...report,
            assessment_ids: report.assessments.map((a) => a.id),
            report_status: ReportStatuses.Unpublished,
          },
        },
        {
          onError: showError,
          onSuccess: ({ assessments }) => {
            if (!!assessments.length) {
              setAutogeneratedAssessments(assessments);
              setState(NotPublishedModalState.NotPublishedFinalMatrix);
              return;
            }

            handleSave();
          },
        },
      );
    },
    [
      getPossibleAutogeneratedAssessments,
      isPropertiesLoading,
      isSubjectsLoading,
      handleSave,
      schoolId,
      showError,
    ],
  );

  const isValidating = getPossibleAutogeneratedAssessments.isLoading;
  const isFetching = saving || isValidating;

  return (
    <ReportNotPublishedContext.Provider
      value={{
        id,
        schoolId,
        isDeleting: deleting,
        isSaving: saving,
        isValidating,
        isFetching,
        isSubjectsLoading,
        isPropertiesLoading,
        report,
        form,
        ageGroupsConfigured,
        subjectsConfigured,
        autogeneratedAssessments,
        state,
        setState,
        isAdmin: permissions.includes('school_admin'),
        subjects,
        activeSubjects,
        activeAgeGroups,
        ageGroups,
        handleDelete,
        handleClose,
        handleSubmitNext,
        handleSave,
      }}
    >
      {children}
    </ReportNotPublishedContext.Provider>
  );
};

export const useNotPublishedReport = () => {
  return useContext(ReportNotPublishedContext);
};

const useEditModalStateContext = (form: UseFormReturn<ReportForm>) => {
  const [initialized, setInitialized] = useFlag(false);
  const formRenderId = useRef<string>(v4());
  const {
    state: contextState,
    setContextState,
    setContextName,
  } = useContext(RouterStateContext) as RouterStateContextProps<ReportDraftContextState>;
  const previousFormInitialValues = usePrevious(contextState?.formInitialValues);

  const modalState = contextState?.modalState || DraftModalState.DraftForm;
  const setModalState = useCallback(
    (modalState: ReportDraftContextState['modalState']) => {
      setContextState({ modalState });
    },
    [setContextState],
  );

  const autogeneratedAssessments = contextState?.autogeneratedAssessments || [];
  const setAutogeneratedAssessments = useCallback(
    (autogeneratedAssessments: AssessmentToCreate[]) => {
      setContextState({ autogeneratedAssessments });
    },
    [setContextState],
  );

  useEffect(() => {
    if (!initialized) {
      setInitialized();
      setContextName(CONTEXT_NAME.DRAFT);

      return;
    }
  }, [setContextName, initialized, setInitialized, setContextState, form]);

  useEffect(() => {
    if (
      !contextState?.formInitialValues ||
      !!previousFormInitialValues ||
      contextState?.formRenderId === formRenderId.current
    )
      return;

    form.reset(contextState.formInitialValues);
  }, [
    contextState?.formInitialValues,
    contextState?.formRenderId,
    form,
    previousFormInitialValues,
  ]);

  useEffect(() => {
    const subscription = form.watch(() => {
      setContextState({
        formInitialValues: form.getValues(),
        formRenderId: formRenderId.current,
      });
    });

    return subscription.unsubscribe;
  }, [form, setContextState]);

  return {
    initialized,
    modalState,
    setModalState,
    autogeneratedAssessments,
    setAutogeneratedAssessments,
  };
};
