import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M18 2.17070571C19.1651924 2.58254212 20 3.69378117 20 5c0 1.65685425-1.3431458 3-3 3s-3-1.34314575-3-3c0-1.30621883.8348076-2.41745788 2-2.82929429V1c0-.55228475.4477153-1 1-1s1 .44771525 1 1v1.17070571zm-14 2C5.16519237 4.58254212 6 5.69378117 6 7c0 1.65685425-1.34314575 3-3 3S0 8.65685425 0 7c0-1.30621883.83480763-2.41745788 2-2.82929429V1c0-.55228475.44771525-1 1-1s1 .44771525 1 1v3.17070571zm7 12.65858859V18c0 .5522847-.4477153 1-1 1-.55228475 0-1-.4477153-1-1v-1.1707057C7.83480763 16.4174579 7 15.3062188 7 14c0-1.6568542 1.34314575-3 3-3 1.6568542 0 3 1.3431458 3 3 0 1.3062188-.8348076 2.4174579-2 2.8292943zM10 15c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1c-.55228475 0-1 .4477153-1 1s.44771525 1 1 1zm-8-3c0-.5522847.44771525-1 1-1s1 .4477153 1 1v6c0 .5522847-.44771525 1-1 1s-1-.4477153-1-1v-6zm9-4c0 .55228475-.4477153 1-1 1-.55228475 0-1-.44771525-1-1V1c0-.55228475.44771525-1 1-1 .5522847 0 1 .44771525 1 1v7zm7 10c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-7c0-.5522847.4477153-1 1-1s1 .4477153 1 1v7zM3 8c.55228475 0 1-.44771525 1-1s-.44771525-1-1-1-1 .44771525-1 1 .44771525 1 1 1zm14-2c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1-1 .44771525-1 1 .4477153 1 1 1z"
        id="filter"
      />
    </defs>
    <use xlinkHref="#filter" transform="translate(2 3)" />
  </svg>
);
