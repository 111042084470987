import { getTypedObjectKeys } from './getTypedObjectKeys';

export function removeObjectEmptyArrayValues<T extends {}>(obj: T): Partial<T> {
  const newObj: Partial<T> = { ...obj };

  for (const key of getTypedObjectKeys(newObj)) {
    const value = newObj[key];
    if (Array.isArray(value) && !value.length) delete newObj[key];
  }

  return newObj;
}
