import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="white" />
    <circle r="7" transform="matrix(1 0 0 -1 15 13)" fill="#24275B" />
    <circle r="4" transform="matrix(1 0 0 -1 15 20)" fill="#24275B" />
    <rect x="8" y="13" width="14" height="9" fill="#24275B" />
    <rect width="14" height="1" transform="matrix(1 0 0 -1 8 22)" fill="white" />
  </svg>
);
