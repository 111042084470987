import { CustomFieldDataType, useGetCustomFieldsValuesForRelationQuery } from '@schooly/api';
import { FC, useMemo } from 'react';

import { useProfile } from '../../../../context/profile/useProfile';
import { CustomFieldBooleanProperty } from './CustomFieldBooleanProperty';
import { CustomFieldDateProperty } from './CustomFieldDateProperty';
import { CustomFieldNumberProperty } from './CustomFieldNumberProperty';
import { CustomFieldPropertyProps } from './CustomFieldPropertyProps';
import { CustomFieldSelectProperty } from './CustomFieldSelectProperty';
import { CustomFieldStringProperty } from './CustomFieldStringProperty';

export const CustomFieldProperty: FC<CustomFieldPropertyProps> = (props) => {
  const { schoolMembership } = useProfile();
  const relationId = schoolMembership?.relation_id || '';
  const { data: customFieldValues } = useGetCustomFieldsValuesForRelationQuery(relationId, {
    enabled: !!relationId,
  });

  const value = useMemo(
    () => customFieldValues?.find((value) => value.custom_field_id === props.id),
    [customFieldValues, props.id],
  );

  switch (props.data_type) {
    case CustomFieldDataType.NUMBER:
      return <CustomFieldNumberProperty value={value} {...props} hideSuggestedChanges />;
    case CustomFieldDataType.STRING:
      return <CustomFieldStringProperty value={value} {...props} hideSuggestedChanges />;
    case CustomFieldDataType.BOOLEAN:
      return <CustomFieldBooleanProperty value={value} {...props} hideSuggestedChanges />;
    case CustomFieldDataType.DATE:
      return <CustomFieldDateProperty value={value} {...props} hideSuggestedChanges />;
    case CustomFieldDataType.SELECT:
      return <CustomFieldSelectProperty value={value} {...props} hideSuggestedChanges />;
    default:
      return null;
  }
};
