import { Box, styled } from '@mui/material';

import { GridRowCell, GridRowStyled } from '../Grid/Grid';

export const ConductGridRow = styled(GridRowStyled)(({ theme }) => ({
  '.icon-Comments, .icon-ConductVisibility': {
    color: theme.palette.text.primary,
  },
  '&:hover': {
    color: theme.palette.primary.main,
    '.icon-Expand, .icon-Comments, .icon-ConductVisibility': {
      color: theme.palette.primary.main,
    },
    '.PersonCard__info .MuiTypography-root': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

ConductGridRow.defaultProps = {
  className: 'GridRowItem',
};

export const ListViewRowActions = styled(Box)(({ theme }) => {
  const size = theme.spacing(2);

  return {
    position: 'relative',
    margin: theme.spacing(0, 1, 0, 2),
    flex: `0 0 ${size}`,
    fontSize: size,
    minWidth: size,

    '& .MuiIconButton-root': {
      position: 'absolute',
      transform: 'translateY(-50%)',
      display: 'none',

      '.GridRowItem:hover &': {
        display: 'unset',
      },
    },
  };
});

export const GridRowName = styled(GridRowCell)(() => ({
  minWidth: 0,
  flex: '1 1 100%',
}));

export const GridRowConnotation = styled(GridRowCell)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  minWidth: 160,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const GridRowValue = styled(GridRowCell)(() => ({
  minWidth: 140,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const GridRowPublished = styled(GridRowCell)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(3),
}));

export const GridRowExpand = styled(GridRowCell)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),

  minWidth: theme.spacing(5),
}));

export const DetailsExpandable = styled(Box)<{ isExpanded: boolean }>(({ isExpanded, theme }) => ({
  height: isExpanded ? 'auto' : 0,
  overflow: 'hidden',
  transition: 'all .2s ease',
  padding: isExpanded ? theme.spacing(1.5, 4, 2.5, 9) : theme.spacing(0, 4, 0, 9),
}));
