import { InboxMessage, Message } from '@schooly/api';
import { ClipIcon } from '@schooly/style';
import React, { useCallback, useState } from 'react';

import Avatar from '../../../components/ui/Avatar';
import { getFormattedPublishDate } from '../../../helpers/messages';
import { getUserFullName } from '../../../helpers/users';
import buildClassName from '../../../utils/buildClassName';

import './index.scss';

interface IProps {
  message: Message | InboxMessage;
  onClick: VoidFunction;
}

const InboxItemCard: React.FC<IProps> = ({ message, onClick }) => {
  const { title, recipient_read, sender } = message as InboxMessage;

  const [isRead, setIsRead] = useState(recipient_read);

  const handleClick = useCallback(() => {
    setIsRead(true);
    onClick();
  }, [onClick]);

  return (
    <div role="button" tabIndex={0} onClick={handleClick} className="InboxModal__card">
      {sender && (
        <Avatar
          className="InboxModal__card__avatar"
          user={sender}
          userType="staff"
          size="small-plus"
        />
      )}
      <div className={buildClassName('InboxModal__card__content', !isRead && 'unread')}>
        <div className="InboxModal__card__name">
          <p className="mb-0">{sender && getUserFullName(sender)}</p>
          {message.attachments && <ClipIcon />}
        </div>
        <p className="InboxModal__card__title mb-0">{title}</p>
        <p className="InboxModal__card__date mb-0">{getFormattedPublishDate(message)}</p>
      </div>
    </div>
  );
};

export default InboxItemCard;
