import {
  DEFAULT_DATE_FORMAT_FNS,
  useGetAttendanceEntriesForPeriodQuery,
  useGetAttendanceRegistersQuery,
  useGetAttendanceStatsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { format, isAfter } from 'date-fns';
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import useQueryStringParams from '../../hooks/useQueryStringParams';
import useSchoolYears from '../../hooks/useSchoolYears';
import {
  GroupAttendanceStatisticsContext,
  GroupAttendanceStatisticsContextProps,
  GroupAttendanceStatisticsMode,
} from './GroupAttendanceStatisticsContext';

const PAGE_SIZE = 40;

export const WithGroupAttendanceStatistics: FC<PropsWithChildren> = ({ children }) => {
  const { id = '' } = useParams<'id'>();
  const navigate = useNavigate();
  const { schoolYears, defaultValidity } = useSchoolYears();
  const {
    dateFrom = defaultValidity?.start || '',
    dateTo = defaultValidity?.end || '',
    query = '',
    mode = GroupAttendanceStatisticsMode.STUDENTS,
  } = useQueryStringParams() as Record<string, string> & { mode: GroupAttendanceStatisticsMode };

  const { schoolId = '' } = useAuth();

  const validity = useMemo(
    () =>
      dateFrom && dateTo
        ? {
            date: [dateFrom, dateTo],
          }
        : undefined,
    [dateFrom, dateTo],
  );

  // don't use useSchoolYears().fetching as defined validity required in the states
  const schoolYearsFetching = !validity;

  const {
    data: students,
    isLoading: isFetchingStudents,
    isFetchingNextPage: isFetchingNextStudents,
    hasNextPage: canShowMoreStudents,
    fetchNextPage: handleShowMoreStudents,
    setParams: setStudentsParams,
  } = useGetAttendanceEntriesForPeriodQuery(
    {
      pageSize: PAGE_SIZE,
      groupId: id,
      date: [dateFrom, dateTo],
      query,
    },
    {
      refetchOnMount: 'always',
      enabled: mode === GroupAttendanceStatisticsMode.STUDENTS && !!(id && dateFrom && dateTo),
    },
  );

  const {
    data: registers,
    isLoading: isFetchingRegisters,
    isFetchingNextPage: isFetchingNextRegisters,
    hasNextPage: canShowMoreRegisters,
    fetchNextPage: handleShowMoreRegisters,
    setParams: setRegistersParams,
  } = useGetAttendanceRegistersQuery(
    {
      schoolId,
      query,
      pageSize: PAGE_SIZE,
      groupIds: [id],
      filters: { date: [dateFrom, dateTo] },
    },
    {
      refetchOnMount: 'always',
      enabled:
        mode === GroupAttendanceStatisticsMode.REGISTERS &&
        !!(schoolId && id && dateFrom && dateTo),
    },
  );

  const setParams = useCallback(
    (value?: {
      dateFrom?: string;
      dateTo?: string;
      query?: string;
      mode?: GroupAttendanceStatisticsMode;
    }) => {
      const params = [];

      const { dateFrom, dateTo, query, mode } = value || {};

      if (dateFrom) {
        params.push(`dateFrom=${dateFrom}`);
      }

      if (dateTo) {
        params.push(`dateTo=${dateTo}`);
      }

      if (query) {
        params.push(`query=${query}`);
      }

      if (mode && mode !== GroupAttendanceStatisticsMode.STUDENTS) {
        params.push(`mode=${mode}`);
      }

      const search = params.length ? `?${params.join('&')}` : '';

      const date = dateFrom && dateTo ? ([dateFrom, dateTo] as [string, string]) : undefined;

      setRegistersParams((p) => ({ ...p, query, filters: { ...p.filters, date } }));
      setStudentsParams((p) => ({ ...p, query, date: date || p.date }));

      navigate({ search });
    },
    [navigate, setRegistersParams, setStudentsParams],
  );

  const setValidity = useCallback(
    (value?: { date: [string, string]; schoolYear?: { id: string } }) => {
      setParams({
        dateFrom: value?.date[0],
        dateTo: value?.date[1],
        query,
        mode,
      });
    },
    [mode, query, setParams],
  );

  const setQuery = useCallback(
    (query?: string) => {
      setParams({ dateFrom, dateTo, query, mode });
    },
    [dateFrom, dateTo, mode, setParams],
  );

  const setMode = useCallback(
    (mode: GroupAttendanceStatisticsMode) => {
      setParams({ dateFrom, dateTo, query, mode });
    },
    [dateFrom, dateTo, query, setParams],
  );

  const dateToForRequest =
    dateTo && isAfter(new Date(dateTo), new Date())
      ? format(new Date(), DEFAULT_DATE_FORMAT_FNS)
      : dateTo;

  const { data: statistics, isLoading: statisticsLoading } = useGetAttendanceStatsQuery(
    {
      schoolId,
      groupId: id,
      dateFrom,
      dateTo: dateToForRequest,
    },
    { enabled: !!(schoolId && id && dateFrom && dateTo), refetchOnMount: 'always' },
  );

  const canShowMore =
    mode === GroupAttendanceStatisticsMode.REGISTERS
      ? !!canShowMoreRegisters
      : !!canShowMoreStudents;
  const isFetching =
    mode === GroupAttendanceStatisticsMode.REGISTERS ? isFetchingRegisters : isFetchingStudents;
  const isFetchingNextPage =
    mode === GroupAttendanceStatisticsMode.REGISTERS
      ? isFetchingNextRegisters
      : isFetchingNextStudents;
  const handleShowMore =
    mode === GroupAttendanceStatisticsMode.REGISTERS
      ? handleShowMoreRegisters
      : handleShowMoreStudents;

  const [showByPresentAbsent, setShowByPresentAbsent] = useState(false);
  const [showByPercent, setShowByPercent] = useState(false);

  const value: GroupAttendanceStatisticsContextProps = {
    schoolYears,
    query,
    validity,
    schoolYearsFetching,
    canShowMore,
    isFetching,
    isFetchingNextPage,
    mode,
    students,
    registers,
    statistics,
    statisticsLoading,
    showByPresentAbsent,
    showByPercent,

    actions: {
      setQuery,
      setValidity,
      setMode,
      setShowByPresentAbsent,
      handleShowMore,
      setShowByPercent,
    },
  };

  return (
    <GroupAttendanceStatisticsContext.Provider value={value}>
      {children}
    </GroupAttendanceStatisticsContext.Provider>
  );
};
