import { getTypedObjectKeys } from './getTypedObjectKeys';
/**
 * @deprecated Use removeObjectUndefinedNullValues() instead
 *
 * Warning: this function also removes all false, zero, empty string values
 * Some enum statuses with 0 value also would be removed!
 */
export function removeObjectFalsyValues(obj: object) {
  const newObj = {};
  getTypedObjectKeys(obj).forEach((prop) => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
}
