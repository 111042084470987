import { Button, IconButton, Stack, Typography } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { usePrevious } from '@schooly/hooks/use-previous';
import { CheckIcon, CrossIcon, DeleteIcon, DragIcon, PlusIcon, Spin } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../../../components/uikit/Dnd/dnd.styled';
import {
  ModalContent,
  ModalFooter,
  ModalMain,
  ModalSmall,
} from '../../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../../components/uikit-components/Modal/ModalHeader';

export interface SchoolTuneSubjectsForm {
  subjects?: { id?: string; name: string }[];
}

const getEmptySubject = () => ({
  id: undefined,
  name: '',
});

export type SchoolTuneSubjectsModalContentProps = {
  title: string;
  isSaving: boolean;
  onSubmit: (v: SchoolTuneSubjectsForm) => void;
  onClose: () => void;
} & SchoolTuneSubjectsForm;

export const SchoolTuneSubjectsModalContent: FC<SchoolTuneSubjectsModalContentProps> = ({
  title,
  isSaving,
  onClose,
  onSubmit,
  subjects,
}) => {
  const { $t } = useIntl();
  const form = useForm<SchoolTuneSubjectsForm>({
    defaultValues: {
      subjects: subjects?.length ? subjects : [getEmptySubject()],
    },
  });
  const { fields, append, remove, replace, move } = useFieldArray({
    control: form.control,
    name: 'subjects',
  });

  const prevFields = usePrevious(fields);

  const firstField = form.getValues('subjects.0');

  const shouldFocusLastItem = Boolean(
    fields.length === 1
      ? !firstField.name
      : prevFields && fields && fields.length - prevFields.length === 1,
  );

  const addSubject = useCallback(() => {
    append(getEmptySubject());
  }, [append]);

  const deleteSubject = useCallback(
    (index: number) => () => {
      if (fields.length > 1) {
        remove(index);
      } else {
        replace([getEmptySubject()]);
      }
    },
    [fields.length, remove, replace],
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader title={title} active>
            {!isSaving && (
              <IconButton onClick={onClose}>
                <CrossIcon />
              </IconButton>
            )}
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              <Stack gap={2.5} sx={{ height: '100%' }}>
                <Typography variant="h2">
                  <FormattedMessage id="school-tabs-Subjects" />
                </Typography>

                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="SubjectDroppable">
                    {(provided) => (
                      <DndListWrapper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        alignItems="flex-start"
                        gap={2}
                      >
                        {fields?.map((field, index) => (
                          <Draggable key={field.id} draggableId={field.id} index={index}>
                            {(provided) => (
                              <Stack
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                direction="row"
                                alignItems="center"
                                gap={1}
                                sx={{
                                  width: '100%',
                                  '& .MuiOutlinedInput-root': { bgcolor: 'background.paper' },
                                }}
                                style={provided.draggableProps.style}
                              >
                                {fields.length > 1 && (
                                  <IconButton inverse {...provided.dragHandleProps}>
                                    <DragIcon />
                                  </IconButton>
                                )}
                                <ControlTextField
                                  name={`subjects.${index}.name`}
                                  control={form.control}
                                  rules={{ required: true }}
                                  label={$t({ id: 'school-tabs-Subjects-SubjectName' })}
                                  autoFocus={shouldFocusLastItem && index === fields.length - 1}
                                  fullWidth
                                  canClear
                                />
                                {fields.length > 1 && (
                                  <IconButton inverse onClick={deleteSubject(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </Stack>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}

                        <Button variant="text" startIcon={<PlusIcon />} onClick={addSubject}>
                          <FormattedMessage id="school-tabs-Subjects-AddSubject" />
                        </Button>
                      </DndListWrapper>
                    )}
                  </Droppable>
                </DragDropContext>
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooter sx={{ justifyContent: 'space-between' }} active>
            <Button variant="outlined" disabled={isSaving} onClick={onClose}>
              <FormattedMessage id="action-Cancel" />
            </Button>
            <Button type="submit" disabled={isSaving} endIcon={isSaving ? <Spin /> : <CheckIcon />}>
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
