import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6H17V16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16V4C3 2.89543 3.89543 2 5 2H13V5C13 5.55228 13.4477 6 14 6ZM9.55859 6.80773V5.73828H10.2959V6.81182C10.5785 6.84873 10.8291 6.92871 11.0479 7.05176C11.3636 7.22754 11.6045 7.48633 11.7705 7.82812C11.9398 8.16667 12.0244 8.58171 12.0244 9.07324H11.1162C11.1162 8.63704 11.0137 8.27734 10.8086 7.99414C10.6035 7.71094 10.3057 7.56934 9.91504 7.56934C9.64486 7.56934 9.42025 7.61816 9.24121 7.71582C9.06543 7.81022 8.93522 7.94369 8.85059 8.11621C8.76595 8.28548 8.72363 8.48405 8.72363 8.71191C8.72363 8.92025 8.76595 9.10254 8.85059 9.25879C8.93522 9.41504 9.07845 9.55664 9.28027 9.68359C9.48535 9.80729 9.7653 9.92936 10.1201 10.0498C10.54 10.1833 10.8965 10.3411 11.1895 10.5234C11.4824 10.7025 11.7054 10.9238 11.8584 11.1875C12.0114 11.4512 12.0879 11.7751 12.0879 12.1592C12.0879 12.5791 11.9935 12.9339 11.8047 13.2236C11.6159 13.5101 11.3506 13.7282 11.0088 13.8779C10.7708 13.9832 10.5022 14.0515 10.2031 14.0827V15.0156H9.47559V14.0844C9.31166 14.0664 9.1489 14.035 8.9873 13.9902C8.71712 13.9154 8.47298 13.7933 8.25488 13.624C8.03678 13.4548 7.86263 13.2301 7.73242 12.9502C7.60221 12.667 7.53711 12.3187 7.53711 11.9053H8.4502C8.4502 12.182 8.48926 12.4131 8.56738 12.5986C8.64876 12.7809 8.75619 12.9258 8.88965 13.0332C9.02311 13.1374 9.1696 13.2122 9.3291 13.2578C9.48861 13.3001 9.64811 13.3213 9.80762 13.3213C10.0973 13.3213 10.3431 13.2741 10.5449 13.1797C10.75 13.0853 10.9062 12.9518 11.0137 12.7793C11.1211 12.6068 11.1748 12.4033 11.1748 12.1689C11.1748 11.9736 11.1309 11.7979 11.043 11.6416C10.9551 11.4854 10.8102 11.3421 10.6084 11.2119C10.4098 11.0785 10.1413 10.9564 9.80273 10.8457C9.39258 10.7188 9.03776 10.5641 8.73828 10.3818C8.44206 10.1995 8.21256 9.97494 8.0498 9.70801C7.8903 9.43783 7.81055 9.11068 7.81055 8.72656C7.81055 8.32617 7.89681 7.98112 8.06934 7.69141C8.24186 7.40169 8.486 7.17871 8.80176 7.02246C9.0261 6.91145 9.27838 6.83987 9.55859 6.80773Z"
      fill="#24275B"
    />
    <path d="M15 2C16.1046 2 17 2.89543 17 4V5H14V2H15Z" fill="#24275B" />
  </svg>
);
