import { Box, Button, InputAdornment, Stack, Typography } from '@mui/material';
import {
  DATE_FORMAT_FULL_MONTH_FNS,
  DEFAULT_DATE_FORMAT_FNS,
  MonthlyPaymentFrequency,
  PaymentFrequencyType,
  SchoolYear,
} from '@schooly/api';
import {
  ControlPeriodSelect,
  getDatesFromPeriodKey,
  getPeriodKey,
  PeriodLabel,
} from '@schooly/components/form-select';
import { ControlSwitch } from '@schooly/components/form-switch';
import { FormNumberSelect } from '@schooly/components/form-text-field';
import { CheckIcon, LockIcon, ModalContent, ModalFooter } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { eachMonthOfInterval, endOfMonth, format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import {
  MonthlyFrequencyForm,
  useFrequencies,
} from '../../../../context/frequencies/WithFrequencies';
import { formatPeriodStringDatesLabel, mergePeriods } from '../utils';
import {
  FrequencyHeader,
  FrequencySetting,
  MAX_DUE_DATE_NUMBER,
  MAX_MONTH_GENERATION_DATE_NUMBER,
  MIN_DUE_DATE_NUMBER,
  PeriodResult,
} from './EditFrequencyCommon';

type MonthlyFrequencyContentProps = {
  frequency: MonthlyPaymentFrequency;
  currentSchoolYear: SchoolYear;
};

export const MonthlyFrequencyContent: FC<MonthlyFrequencyContentProps> = ({
  frequency,
  currentSchoolYear,
}) => {
  const { has_invoices, activity_cycles, ...defaultValues } = frequency;
  const { updateFrequency } = useFrequencies();

  const { $t } = useIntl();

  const formActivityPeriods = activity_cycles.reduce<MonthlyFrequencyForm['activity_cycles']>(
    (acc, next) => {
      return [...acc, getPeriodKey(next)];
    },
    [],
  );

  const form = useForm<MonthlyFrequencyForm>({
    defaultValues: { ...defaultValues, activity_cycles: formActivityPeriods },
    mode: 'onChange',
  });

  const dueDay = form.watch('due_days_count');
  const generationDay = form.watch('billing_periods_spec.generation_date.offset_days');
  const activityPeriods = form.watch('activity_cycles');
  const inUse = form.watch('in_use');
  const invoiceDay = form.watch('billing_periods_spec.invoice_date.offset_days');

  const yearMonthOptions = useMemo(() => {
    const days = eachMonthOfInterval({
      start: newDateTimezoneOffset(currentSchoolYear.start),
      end: newDateTimezoneOffset(currentSchoolYear.end),
    }).map((start, i) => {
      const end = endOfMonth(start);
      return {
        label: format(start, DATE_FORMAT_FULL_MONTH_FNS),
        key: getPeriodKey({
          start: format(start, DEFAULT_DATE_FORMAT_FNS),
          end: format(end, DEFAULT_DATE_FORMAT_FNS),
        }),
      };
    });

    return days;
  }, [currentSchoolYear.end, currentSchoolYear.start]);

  const handleSubmit = useCallback(
    (d: MonthlyFrequencyForm) => {
      const { activity_cycles, ...rest } = d;

      const updatedFrequency: MonthlyPaymentFrequency = {
        ...rest,
        activity_cycles: activity_cycles.map(getDatesFromPeriodKey),
        has_invoices,
      };

      updateFrequency(updatedFrequency);
    },
    [has_invoices, updateFrequency],
  );

  const activityPeriodLabel = useMemo(() => {
    const selectedPeriodCount = activityPeriods.length;

    if (!selectedPeriodCount) return '';

    const allPeriodsSelected = selectedPeriodCount === yearMonthOptions.length;

    const label = `${selectedPeriodCount} ${$t({
      id: selectedPeriodCount > 1 ? 'frequencies-Month-plural' : 'month',
    })} `;

    const dateLabel = allPeriodsSelected
      ? formatPeriodStringDatesLabel({
          start: currentSchoolYear.start,
          end: currentSchoolYear.end,
          type: 'month',
        })
      : `${mergePeriods(activityPeriods.map(getDatesFromPeriodKey), {
          mergeSameMonth: true,
        }).map(
          ({ start, end }) => ` ${formatPeriodStringDatesLabel({ start, end, type: 'month' })}`,
        )}`;

    return <PeriodLabel label={label} dateLabel={dateLabel} selected />;
  }, [$t, activityPeriods, currentSchoolYear, yearMonthOptions.length]);

  const getGenerationDate = generationDay
    ? `${$t({ id: 'frequencies-PeriodResultInvoiceGeneratedDate' }, { generationDay })} ${$t({
        id: 'frequencies-OfPreviousMonth',
      })}`
    : undefined;

  const invoiceDate = `${$t(
    { id: 'frequencies-PeriodResultMonthInvoiceDate' },
    { invoiceDay },
  )} ${$t({ id: 'frequencies-OfCurrentMonth' })}`;

  const disabled = !inUse;

  const showResult = !disabled && !!getGenerationDate && !!dueDay;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FrequencyHeader
          title={$t({ id: 'frequencies-monthly' })}
          yearName={currentSchoolYear.name}
          // TODO onClose
          onClose={() => {}}
        >
          <ControlSwitch label={$t({ id: 'frequencies-InUse' })} name="in_use" />
        </FrequencyHeader>
        <ModalContent active>
          <FrequencySetting
            disabled={disabled}
            title={$t({ id: 'frequencies-ActivityPeriodMonthTitle' })}
            sx={{
              gap: 1,
            }}
          >
            <ControlPeriodSelect
              name="activity_periods"
              rules={{
                validate: (v) => {
                  if (!v.length) return $t({ id: 'frequencies-ActivityPeriodsError' });
                  return true;
                },
              }}
              withSort
              PeriodSelectProps={{
                options: yearMonthOptions,
                periodLabel: activityPeriodLabel,
                placeholder: $t({ id: 'frequencies-ActivityPeriodTitle' }),
                disabled: disabled,
              }}
            />
          </FrequencySetting>

          <FrequencySetting
            disabled={disabled}
            title={$t({ id: 'frequencies-InvoiceGenerationAndDueTitle' })}
            subTitle={$t({ id: 'frequencies-GenerationDate' })}
            sx={{
              pt: 4,
              gap: 1,
            }}
          >
            <Stack
              direction="row"
              gap={2.5}
              sx={{
                '.MuiTextField-root': {
                  width: 326,
                },
              }}
            >
              <FormNumberSelect
                disabled={disabled}
                name="generation_date.day"
                min={MIN_DUE_DATE_NUMBER}
                max={MAX_MONTH_GENERATION_DATE_NUMBER}
                placeholder={$t({ id: 'frequencies-GenerationDate' })}
                rules={{
                  validate: (value) => {
                    if (!value) return $t({ id: 'input-ErrorRequired' });
                    return true;
                  },
                }}
              />
              <Typography
                pt={1.5}
                noWrap
                color={disabled ? 'common.grey2' : undefined}
                variant="h3"
              >
                {$t({ id: 'frequencies-OfPreviousMonth' })}
              </Typography>
            </Stack>
          </FrequencySetting>

          <FrequencySetting
            disabled={disabled}
            subTitle={$t({ id: 'frequencies-DueDate' })}
            sx={{
              pt: 2,
              gap: 1,
            }}
          >
            <FormNumberSelect
              disabled={disabled}
              name="due_day"
              min={MIN_DUE_DATE_NUMBER}
              max={MAX_DUE_DATE_NUMBER}
              rules={{
                validate: (value) => {
                  if (!value) return $t({ id: 'input-ErrorRequired' });
                  return true;
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {disabled ? <LockIcon /> : $t({ id: 'frequencies-DueDateDescriptionTitle' })}
                  </InputAdornment>
                ),
              }}
            />
          </FrequencySetting>

          {showResult && (
            <Box pt={4}>
              <PeriodResult
                type={PaymentFrequencyType.Weekly}
                dueDayCount={dueDay}
                generationDate={getGenerationDate}
                invoiceDate={invoiceDate}
              />
            </Box>
          )}
        </ModalContent>
        <ModalFooter
          active
          sx={(theme) => ({
            zIndex: theme.zIndex.drawer,
          })}
        >
          <Button endIcon={<CheckIcon />} type="submit">
            {$t({ id: 'action-Save' })}
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
