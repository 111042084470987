import { Box } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { FilterIcon } from '@schooly/style';
import React, { FC, RefAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { FilterType } from '../../../context/filters/scheme';
import { POPUP_OPEN_DELAY_MS, useFilters } from '../../../context/filters/useFilters';
import useDropdown from '../../../hooks/useDropdown';
import usePrevious from '../../../hooks/usePrevious';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { actions as simpleListsActions } from '../../../redux/slices/simple-lists/actions';
import buildClassName from '../../../utils/buildClassName';
import { HeaderFilterBottom } from './HeaderFilterBottom';
import { HeaderFilterPopup } from './HeaderFilterPopup';
import { HeaderFilterSearchPopup } from './HeaderSearch/HeaderFilterSearchPopup';
import HeaderSearch from './HeaderSearch/HeaderSearch';
import { convertStaffSimpleListResultsToSelectOption, FILTER_MODE } from './utils';

import './HeaderFilter.scss';

export interface IHeaderFilterProps {
  searchPlaceholder?: string;
  isLoading?: boolean;
  modalTitleTextId?: string;
  modal?: boolean;
  bottomInline?: boolean;
  bottomInlineMax?: number;
  bottomBig?: boolean;
  isModalOpen?: boolean;
  bottomSpace?: number;
  forceZIndex?: boolean;
  customSearchRenderer?: (closeWithDelay: () => void) => React.ReactNode;
  withoutPopupMouseEvents?: boolean;
}

/**
@deprecated We no longer use this component in new implementations
*/
const HeaderFilter: FC<IHeaderFilterProps & RefAttributes<HTMLDivElement>> = React.forwardRef<
  HTMLDivElement,
  IHeaderFilterProps
>(
  (
    {
      searchPlaceholder,
      isLoading,
      modalTitleTextId,
      modal,
      bottomInline,
      bottomInlineMax,
      bottomBig,
      isModalOpen,
      bottomSpace = 0,
      forceZIndex,
      customSearchRenderer,
      withoutPopupMouseEvents,
    },
    searchButtonRef,
  ) => {
    const { formatMessage } = useIntl();
    const openTimestampRef = useRef(+new Date());
    const dispatch = useAppDispatch();
    const {
      filters,
      filterType,
      isFilterApplied,
      resetFilters,
      getDefaultFiltersByType,

      propertiesFetching,
      userProperties,
      fetchingSchoolYears,
      defaultValidity,
    } = useFilters();

    const {
      simpleLists: {
        staff: { results: simpleStaffResults },
      },
    } = useAppSelector((state) => state);
    const { currentStaff } = useAuth();

    const wrapperRef = useRef<HTMLDivElement>(null);
    const [openFilter, setOpenFilter] = useState<FilterKeys>();
    const [mode, setMode] = useState(FILTER_MODE.FILTER);

    const prevFilterType = usePrevious(filterType);

    const onOpen = useCallback(() => {
      resetFilters({
        filters: filters.applied,
        arrangeBy: filters.appliedArrangeBy,
        groupBy: filters.appliedGroupBy,
        apply: false,
      });
    }, [filters.applied, filters.appliedArrangeBy, filters.appliedGroupBy, resetFilters]);

    const { isOpen, isOpenOnce, openPopup, closePopup, Popup } = useDropdown(
      wrapperRef,
      undefined,
      forceZIndex,
      { onOpen },
    );

    const hasFilters = useMemo(
      () =>
        [
          FilterType.Parent,
          FilterType.SidebarGroups,
          FilterType.SidebarReportRecipients,
          FilterType.SidebarGroupStudents,
          FilterType.SidebarRelationGroups,
          FilterType.SidebarUserRoleStaff,
          FilterType.SidebarAssessmentGroups,
        ].includes(filterType),
      [filterType],
    );

    const hasSearch = useMemo(
      () =>
        [FilterType.UserRoles].includes(filterType) ||
        [FilterType.Applications].includes(filterType),
      [filterType],
    );

    const handleClosePopup = useCallback(() => {
      const currTimestamp = +new Date();
      const diff = currTimestamp - openTimestampRef.current;
      setOpenFilter(undefined);

      // Closing popup before open animation end breaks layout and causes open/close logic crash
      if (diff > POPUP_OPEN_DELAY_MS) {
        closePopup();
      }
    }, [closePopup]);

    const handleCloseAndResetSavedStaffs = useCallback(() => {
      // remove draft staffs from SavedSelectedOptions
      const appliedValues = filters.applied?.staff?.length
        ? filters.applied?.staff
        : filters.applied?.creator;

      const allStaffOptions = convertStaffSimpleListResultsToSelectOption(
        simpleStaffResults,
        currentStaff,
      );

      const filteredOptions = allStaffOptions.filter((o) =>
        appliedValues?.includes(o.item.relation_id),
      );
      dispatch(simpleListsActions.staff.setSavedSelectedOptions(filteredOptions));
      handleClosePopup();
    }, [
      currentStaff,
      dispatch,
      filters.applied?.creator,
      filters.applied?.staff,
      handleClosePopup,
      simpleStaffResults,
    ]);

    const togglePopup = useCallback(
      (m: FILTER_MODE) => () => {
        setMode(m);

        if (isOpen && mode === m) {
          handleCloseAndResetSavedStaffs();
        } else {
          openPopup();
        }
      },
      [handleCloseAndResetSavedStaffs, isOpen, mode, openPopup],
    );

    // The closeWithDelay hack is needed for cases where we apply newQuery and newQuery !== draftQuery in filters.
    // Without this hack, the popup window will open again by useEffect, because the draftQuery will be updated
    const closeWithDelay = useCallback(() => {
      setTimeout(closePopup, POPUP_OPEN_DELAY_MS);
    }, [closePopup]);

    const customSearchContent = customSearchRenderer?.(closeWithDelay);

    const handleTagClick = useCallback(
      (key?: FilterKeys) => {
        openPopup();
        setOpenFilter(undefined);
        setMode(FILTER_MODE.FILTER);

        setTimeout(() => {
          setOpenFilter(key);
        }, POPUP_OPEN_DELAY_MS);
      },
      [openPopup],
    );

    const wrapperClassName = buildClassName(
      'search-wrapper',
      isOpen && !modal && 'open',
      hasFilters && 'withFilters',
    );

    useEffect(() => {
      // The filter type might be changed in the same component (for instance on People/Group pages
      // on section change). Need carefully reset filters to default state on filter type change.
      if (
        prevFilterType !== filterType &&
        !propertiesFetching &&
        userProperties &&
        !fetchingSchoolYears &&
        defaultValidity
      ) {
        resetFilters({ ...getDefaultFiltersByType(filterType), query: '' });
        dispatch(simpleListsActions.staff.reset());
      }
    }, [
      defaultValidity,
      dispatch,
      fetchingSchoolYears,
      filterType,
      getDefaultFiltersByType,
      prevFilterType,
      propertiesFetching,
      resetFilters,
      userProperties,
    ]);

    useEffect(() => {
      if (filters.draftQuery) {
        setMode(FILTER_MODE.SEARCH);
        openPopup();
      } else {
        setMode(FILTER_MODE.FILTER);
        closePopup();
      }
    }, [closePopup, filters.draftQuery, openPopup]);

    const onPopupMouseEvent = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (withoutPopupMouseEvents) {
          e.stopPropagation();
        }
      },
      [withoutPopupMouseEvents],
    );

    return (
      <div className={buildClassName('HeaderFilter', modal && 'HeaderFilter_modal')}>
        <div className="HeaderFilter__top">
          <div className={wrapperClassName} ref={wrapperRef}>
            {!modal && isOpen && (
              <div
                role="button"
                tabIndex={0}
                className="HeaderFilter__popup-backdrop"
                onClick={handleCloseAndResetSavedStaffs}
              />
            )}
            <HeaderSearch
              placeholder={searchPlaceholder}
              isLoading={isLoading}
              closePopup={handleClosePopup}
            />
            {hasFilters && (
              <Box onMouseOver={onPopupMouseEvent} onMouseLeave={onPopupMouseEvent}>
                <div
                  role="button"
                  tabIndex={0}
                  className={buildClassName('filter-toggle', isFilterApplied && 'applied')}
                  onClick={togglePopup(FILTER_MODE.FILTER)}
                  aria-label={formatMessage({ id: 'filter-FilterControlLabel' })}
                  title={formatMessage({ id: 'filter-FilterControlLabel' })}
                  ref={searchButtonRef}
                >
                  <FilterIcon />
                </div>

                {/* Popups */}

                <Popup>
                  <HeaderFilterPopup
                    isOpen={mode === FILTER_MODE.FILTER && isOpen}
                    isOpenOnce={mode === FILTER_MODE.FILTER && isOpenOnce}
                    closePopup={handleClosePopup}
                    openFilter={openFilter}
                    modal={modal}
                    modalTitleTextId={modalTitleTextId}
                    isModalOpen={isModalOpen}
                  />
                </Popup>
                <Popup>
                  <HeaderFilterSearchPopup
                    isOpen={!modal && mode === FILTER_MODE.SEARCH && isOpen}
                    closePopup={handleClosePopup}
                    setMode={setMode}
                    setOpenFilter={setOpenFilter}
                  />
                </Popup>
              </Box>
            )}

            {!hasFilters && hasSearch ? (
              <Popup>
                <HeaderFilterSearchPopup
                  isOpen={!modal && mode === FILTER_MODE.SEARCH && isOpen}
                  closePopup={handleClosePopup}
                  setMode={setMode}
                  setOpenFilter={setOpenFilter}
                  customSearchContent={customSearchContent}
                />
              </Popup>
            ) : null}
          </div>
        </div>
        <Box minHeight={bottomSpace}>
          {hasFilters && (
            <HeaderFilterBottom
              onTagClick={handleTagClick}
              inline={bottomInline}
              inlineMax={bottomInlineMax}
              isBig={bottomBig}
              isLoading={isLoading}
            />
          )}
        </Box>
      </div>
    );
  },
);

export default HeaderFilter;
