import { ChipProps } from '@mui/material';
import { useGetReportQuery } from '@schooly/api';
import { Spin, TagSelect } from '@schooly/style';
import { FC } from 'react';

export type ReportTagSelectProps = {
  id: string;
} & Omit<ChipProps, 'label'>;

export const ReportTagSelect: FC<ReportTagSelectProps> = ({ id, ...props }) => {
  const { data } = useGetReportQuery(id);

  return <TagSelect label={data?.name || <Spin />} {...props} />;
};
