import { Button, FormControlLabel, IconButton, Stack, Switch, TextField } from '@mui/material';
import {
  GET_SCHOOL_PROPERTIES_QUERY,
  SchoolProperty,
  updateHouses,
  UpdateHousesProps,
} from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { usePrevious } from '@schooly/hooks/use-previous';
import {
  CheckIcon,
  CrossIcon,
  DragIcon,
  PlusIcon,
  RollBackIcon,
  SimpleButton,
  Spin,
} from '@schooly/style';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { SubmitHandler } from 'react-hook-form';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../../components/uikit/Dnd/dnd.styled';
import { ControlTextField } from '../../../../components/uikit-components/FormTextField/ControlTextField';
import {
  ModalContent,
  ModalFooter,
  ModalMain,
  ModalSmall,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { SchoolPropertyType } from '../../../../constants/school';
import { useRouter } from '../../../../context/router/useRouter';
import { useSchool } from '../../../../hooks/useSchool';
import { queryClient } from '../../../../queryClient';
import { SchoolSettingsLayout } from '../../SchoolSettingsLayout';
import { SchoolGeneralRowAction } from '../SchoolGeneralRowAction';
import { SchoolGeneralHousesReplaceModal } from './SchoolGeneralHousesReplaceModal';

export interface SchoolGeneralHousesForm {
  houses?: (SchoolProperty & {
    originId?: SchoolProperty['id'];
    replaceId?: SchoolProperty['id'];
  })[];
}

const getEmptyHouse = () => ({
  id: '',
  originId: '',
  replaceId: '',
  name: '',
  type: SchoolPropertyType.House,
  archived: false,
  category: null,
  conduct_default: false,
  group_default: false,
  order: 0,
  staff_default: false,
  student_default: false,
});

export const SchoolGeneralHousesModal: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { goBack } = useRouter();
  const { getConfirmation } = useConfirmationDialog();
  const { studentPropertiesMap, schoolId } = useSchool();
  const [archivingFieldIndex, setArchivingFieldIndex] = useState<number>();
  const [showArchived, setShowArchived] = useState(false);
  const autoSwitchedArchivedOn = useRef(false);

  const form = useForm<SchoolGeneralHousesForm>({
    defaultValues: {
      houses: studentPropertiesMap.house?.length
        ? [...studentPropertiesMap.house.map((h) => ({ ...h, originId: h.id, replaceId: '' }))]
        : [getEmptyHouse()],
    },
  });
  const { fields, append, remove, replace, move, update } = useFieldArray({
    control: form.control,
    name: 'houses',
  });
  const [saving, setSaving] = useState(false);

  // (TR-4611): Have to spread active & archived fields to two different lists and
  // render/manage them separately.
  const [activeFields, archivedFields] = useMemo(
    () =>
      fields.reduce<Array<Array<{ index: number; field: typeof fields[number] }>>>(
        (prev, field, index) => {
          prev[field.archived ? 1 : 0].push({ index, field });
          return prev;
        },
        [[], []],
      ),
    [fields],
  );

  const prevActiveFields = usePrevious(activeFields);

  const firstActiveField = form.getValues(`houses.${activeFields[0]?.index}`);

  const shouldFocusLastItem = Boolean(
    activeFields.length === 1
      ? !firstActiveField.name
      : prevActiveFields && activeFields && activeFields.length - prevActiveFields.length === 1,
  );

  const handleClose = useCallback(async () => {
    if (saving) {
      return;
    }

    if (
      form.formState.isDirty &&
      !(await getConfirmation({ textId: 'school-edit-CloseUnsavedConfirmation' }))
    ) {
      return;
    }

    goBack();
  }, [goBack, saving, form.formState.isDirty, getConfirmation]);

  const handleCloseReplaceModal = useCallback(() => {
    setArchivingFieldIndex(undefined);
  }, []);

  const addHouse = useCallback(() => {
    append(getEmptyHouse());
  }, [append]);

  const archiveHouse = useCallback(
    (index: number, replaceId: SchoolProperty['id'] = '') => {
      const field = form.getValues(`houses.${index}`);

      update(index, { ...field, archived: true, replaceId });

      // TR-4611: should switch on `Show archived` first time if no archived fields were before
      if (!archivedFields.length && !autoSwitchedArchivedOn.current) {
        setShowArchived(true);
        autoSwitchedArchivedOn.current = true;
      }
    },
    [archivedFields.length, form, update],
  );

  const handleDeleteHouse = useCallback(
    (index: number) => {
      if (fields.length > 1) {
        remove(index);
      } else {
        replace([getEmptyHouse()]);
      }
    },
    [fields.length, remove, replace],
  );

  const handleArchiveHouse = useCallback((index: number) => {
    setArchivingFieldIndex(index);
  }, []);

  const handleReplaceHouse = useCallback(
    (index: number) => (replaceId: SchoolProperty['id']) => {
      archiveHouse(index, replaceId);
      setArchivingFieldIndex(undefined);
    },
    [archiveHouse],
  );

  const handleArchiveHouseWithoutReplacing = useCallback(
    (index: number) => () => {
      archiveHouse(index);
      setArchivingFieldIndex(undefined);
    },
    [archiveHouse],
  );

  const handleRestoreHouse = useCallback(
    (index: number) => async () => {
      const field = form.getValues(`houses.${index}`);
      // can not just use `update` as need to move to the end
      remove(index);
      append({ ...field, archived: false });
    },
    [append, form, remove],
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const handleSubmit = useCallback<SubmitHandler<SchoolGeneralHousesForm>>(
    async (data) => {
      if (!schoolId || !data.houses) {
        return;
      }

      setSaving(true);

      try {
        await updateHouses({
          schoolId,
          houses: data.houses.map((house, index) => {
            const result: UpdateHousesProps['houses'][number] = {
              property_id: house.id || undefined,
              replace_to_id: house.replaceId || undefined,
              name: house.name ?? '',
              order: index + 1,
              archive: house.archived,
            };

            return result;
          }),
        });

        // (TR-4611): Each property is single instance, but for both user types, so have to request both
        // Student and Staff properties after save. If request only for Students, the Staff one
        // will be not actual in a storage.
        await queryClient.invalidateQueries([GET_SCHOOL_PROPERTIES_QUERY]);

        goBack();
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }

      setSaving(false);
    },
    [goBack, schoolId, showError],
  );

  return (
    <SchoolSettingsLayout>
      <ModalSmall open onClose={handleClose}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader title={$t({ id: 'school-tabs-Houses' })} active>
              {archivedFields.length > 0 && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={showArchived}
                      onChange={(event, value) => setShowArchived(value)}
                    />
                  }
                  label={$t({ id: 'action-ShowArchived' })}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              )}
              {!saving && (
                <IconButton onClick={handleClose}>
                  <CrossIcon />
                </IconButton>
              )}
            </ModalHeader>
            <ModalMain>
              <ModalContent flat active>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="HousesDroppable">
                    {(provided) => (
                      <DndListWrapper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        alignItems="flex-start"
                        gap={2}
                        p={2.5}
                      >
                        {activeFields?.map(({ field, index }, arrayIndex) => (
                          <Draggable key={field.id} draggableId={field.id} index={index}>
                            {(provided) => (
                              <Stack
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                direction="row"
                                alignItems="center"
                                gap={1}
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                style={provided.draggableProps.style}
                              >
                                {activeFields.length > 1 && (
                                  <IconButton inverse {...provided.dragHandleProps}>
                                    <DragIcon />
                                  </IconButton>
                                )}
                                <ControlTextField
                                  name={`houses.${index}.name`}
                                  control={form.control}
                                  rules={{
                                    required: true,
                                    validate: (value, formValues) => {
                                      // Check for name uniqueness
                                      for (let i = 0; i < (formValues.houses?.length ?? 0); i++) {
                                        if (i === index) {
                                          continue;
                                        }

                                        const field = formValues.houses![i];

                                        if (
                                          field.name?.trim().toLowerCase() ===
                                          `${value ?? ''}`.trim().toLowerCase()
                                        ) {
                                          return $t({
                                            id: field.archived
                                              ? 'school-tabs-Houses-ArchivedExists'
                                              : 'school-tabs-Houses-ActiveExists',
                                          });
                                        }
                                      }

                                      return true;
                                    },
                                  }}
                                  label={$t({ id: 'school-tabs-Houses-HouseName' })}
                                  autoFocus={
                                    shouldFocusLastItem && arrayIndex === activeFields.length - 1
                                  }
                                  fullWidth
                                  canClear
                                />

                                {activeFields.length ? (
                                  <SchoolGeneralRowAction
                                    id={field.originId}
                                    index={index}
                                    isLocked={
                                      field.conduct_default ||
                                      field.group_default ||
                                      field.student_default ||
                                      field.staff_default
                                    }
                                    lockMessage={$t({
                                      id: 'school-tabs-Houses-Tooltip-DefaultFilter',
                                    })}
                                    onArchive={handleArchiveHouse}
                                    onDelete={handleDeleteHouse}
                                  />
                                ) : null}
                              </Stack>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}

                        <SimpleButton startIcon={<PlusIcon />} onClick={addHouse}>
                          <FormattedMessage id="school-tabs-Houses-AddHouse" />
                        </SimpleButton>

                        {showArchived &&
                          archivedFields.map(({ field, index }) => (
                            <Stack
                              key={field.id}
                              direction="row"
                              alignItems="center"
                              gap={1}
                              pl={3.5}
                              width="100%"
                            >
                              <TextField
                                value={field.name}
                                fullWidth
                                disabled
                                label={$t({ id: 'school-tabs-Houses-HouseName' })}
                              />

                              <IconButton onClick={handleRestoreHouse(index)}>
                                <RollBackIcon />
                              </IconButton>
                            </Stack>
                          ))}
                      </DndListWrapper>
                    )}
                  </Droppable>
                </DragDropContext>
              </ModalContent>
            </ModalMain>
            <ModalFooter sx={{ justifyContent: 'space-between' }} active>
              <Button variant="outlined" disabled={saving} onClick={handleClose}>
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button type="submit" disabled={saving} endIcon={saving ? <Spin /> : <CheckIcon />}>
                <FormattedMessage id="action-Save" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalSmall>

      {archivingFieldIndex != null && (
        <SchoolGeneralHousesReplaceModal
          field={fields[archivingFieldIndex]}
          fields={activeFields}
          onClose={handleCloseReplaceModal}
          onReplace={handleReplaceHouse(archivingFieldIndex)}
          onArchiveWithoutReplacing={handleArchiveHouseWithoutReplacing(archivingFieldIndex)}
        />
      )}
    </SchoolSettingsLayout>
  );
};
