import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  pickFormFieldsFromUser,
  UserFormData,
} from '../../../components/common/ProfileModal/profileEditSchema';
import { FormInput } from '../../../components/ui/Input';
import { useProfile } from '../../../context/profile/useProfile';
import IntlError from '../../../utils/intlError';
import { PropertyModal } from '../properties/PropertyModal';

interface PreferNameModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const PreferNameModal = ({ onClose, isOpen }: PreferNameModalProps) => {
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, actions } = useProfile();

  const form = useForm<UserFormData>({
    mode: 'all',
    defaultValues: {
      known_as: user?.known_as,
    },
  });

  const handleSubmit = useCallback(
    async ({ known_as }: UserFormData) => {
      setIsUpdating(true);
      try {
        const res = await actions.updateProfile({ known_as });
        if (!res) return;

        showNotification({
          textId: 'confirmation-PreferName',
          type: 'success',
        });
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, showNotification, showError, onClose],
  );

  const {
    reset,
    formState: { isValid },
  } = form;

  useEffect(() => {
    if (user) {
      reset(pickFormFieldsFromUser(user));
    }
  }, [user, reset]);

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
      submitDisabled={!isValid}
    >
      <FormInput name="known_as" type="text" labelTextId="peopleDetail-PreferredName" />
    </PropertyModal>
  );
};

export default PreferNameModal;
