import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4.5C8 5.32843 7.32843 6 6.5 6C5.67157 6 5 5.32843 5 4.5C5 3.67157 5.67157 3 6.5 3C7.32843 3 8 3.67157 8 4.5Z"
      fill="#B3BBCB"
    />
    <path
      d="M8 10C8 10.8284 7.32843 11.5 6.5 11.5C5.67157 11.5 5 10.8284 5 10C5 9.17157 5.67157 8.5 6.5 8.5C7.32843 8.5 8 9.17157 8 10Z"
      fill="#B3BBCB"
    />
    <path
      d="M8 15.5C8 16.3284 7.32843 17 6.5 17C5.67157 17 5 16.3284 5 15.5C5 14.6716 5.67157 14 6.5 14C7.32843 14 8 14.6716 8 15.5Z"
      fill="#B3BBCB"
    />
    <path
      d="M15 4.5C15 5.32843 14.3284 6 13.5 6C12.6716 6 12 5.32843 12 4.5C12 3.67157 12.6716 3 13.5 3C14.3284 3 15 3.67157 15 4.5Z"
      fill="#B3BBCB"
    />
    <path
      d="M15 10C15 10.8284 14.3284 11.5 13.5 11.5C12.6716 11.5 12 10.8284 12 10C12 9.17157 12.6716 8.5 13.5 8.5C14.3284 8.5 15 9.17157 15 10Z"
      fill="#B3BBCB"
    />
    <path
      d="M15 15.5C15 16.3284 14.3284 17 13.5 17C12.6716 17 12 16.3284 12 15.5C12 14.6716 12.6716 14 13.5 14C14.3284 14 15 14.6716 15 15.5Z"
      fill="#B3BBCB"
    />
  </svg>
);
