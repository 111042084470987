import { Autocomplete, Box, Icon, InputAdornment, Stack, Tooltip } from '@mui/material';
import { AssessmentMethod, AssessmentMethodBlank, AssessmentMethodType } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { ArchiveIcon } from '@schooly/style';
import { FC, useRef } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { renderError } from '../../../../components/ui/Input/helpers';
import { FormTextField } from '../../../../components/uikit-components/FormTextField/FormTextField';
import { useAssessmentsGradesForSchool } from '../../../../hooks/useAssessmentsGradesForSchool';
import { AssessmentForm } from '../AssessmentCreateContent';
import { DeleteMethodButton } from './AssessmentsCreateModalSettings.styled';
import { AssessmentsCreateModalSettingsMethodType } from './AssessmentsCreateModalSettingsMethodType';

export interface AssessmentsGradeControlProps {
  index: number;
  autoFocus?: boolean;
  onMethodChange?: (methodType?: AssessmentMethodType) => void;
  onDelete?: () => void;
  onValidate: (method: AssessmentMethod | AssessmentMethodBlank) => string | boolean;
}

export const AssessmentsGradeControl: FC<AssessmentsGradeControlProps> = ({
  index,
  autoFocus,
  onMethodChange,
  onDelete,
  onValidate,
}) => {
  const { formatMessage, $t } = useIntl();
  const { formState, control, watch } = useFormContext<AssessmentForm>();
  const { getConfirmation } = useConfirmationDialog();
  const autocompleteInputRef = useRef<HTMLInputElement>();
  const name = `methods.${index}` as const;
  const method = watch(name);
  const { grades: selectLists, activeGrades: activeSelectLists } = useAssessmentsGradesForSchool();
  const loading = !selectLists;

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: onValidate,
      }}
      render={({ field }) => {
        const error = get(formState.errors, field.name);
        const selectedList = selectLists?.find((list) => list.id === field.value.select_list_id);

        return (
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <Box sx={{ flex: '0 0 49%' }}>
              <AssessmentsCreateModalSettingsMethodType
                methodType={field.value.method_type ?? undefined}
                onChange={onMethodChange}
              />
            </Box>
            <Box width={'100%'}>
              <Autocomplete
                options={activeSelectLists ?? []}
                getOptionLabel={(option) => option.name}
                loading={loading}
                renderInput={(params) => (
                  <FormTextField
                    {...params}
                    error={Boolean(error)}
                    helperText={renderError(error)}
                    label={formatMessage({ id: 'assessments-GradePlaceholder' })}
                    autoFocus={autoFocus}
                    dropdown
                    required
                    startAdornment={
                      selectedList?.archived && (
                        <InputAdornment position="start" sx={{ '&&': { mt: 0 } }}>
                          <Tooltip title={$t({ id: 'schoolProperty-Archived-assessmentGrade' })}>
                            <Icon sx={{ color: 'primary.main', cursor: 'auto' }}>
                              <ArchiveIcon />
                            </Icon>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }
                    inputRef={autocompleteInputRef}
                  />
                )}
                value={selectedList}
                onChange={async (_, value) => {
                  if (
                    selectedList?.archived &&
                    !(await getConfirmation({
                      textId: 'deselect-subject-archived',
                      textValues: { name: selectedList.name },
                    }))
                  ) {
                    autocompleteInputRef.current?.blur();
                    return;
                  }
                  autocompleteInputRef.current?.blur();
                  field.onChange({
                    ...method,
                    select_list_id: value.id,
                    select_list_name: value.name,
                  });
                }}
                openOnFocus
                disableClearable
                fullWidth
              />
            </Box>
            <Box>
              <DeleteMethodButton onClick={onDelete} />
            </Box>
          </Stack>
        );
      }}
    />
  );
};
