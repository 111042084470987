import { Box, Stack, Typography } from '@mui/material';
import {
  CalendarChartData,
  DEFAULT_DATE_FORMAT_FNS,
  DEFAULT_FORMATTED_DATE_FORMAT_FNS,
  DEFAULT_YEAR_MONTH_FORMAT_FNS,
  getTypedObjectKeys,
  UserFilter,
} from '@schooly/api';
import { CalendarChart, CalendarChartEmpty, ChartTooltip } from '@schooly/components/charts';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { addMonths, differenceInMonths, endOfMonth, format, getMonth, getYear } from 'date-fns';
import { TooltipComponentFormatterCallbackParams } from 'echarts';
import { maxBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { FormattedMessage } from 'react-intl';

export const CALENDAR_CHART_MONTH_LIMIT = 12;

export type CalendarChartComponentData = Record<
  string,
  Array<{ date: string; value: number; month: number }>
>;

export type CalendarChartsCustomGrid<T> = {
  filters: T;
  year: string;
  index: number | null;
  count?: number;
};

interface CalendarChartContentProps<T> {
  filters: T;
  onOpenCustomGrid: (data: CalendarChartsCustomGrid<T> | null) => void;
  chartCustomGrid: CalendarChartsCustomGrid<T> | null;
  chartIcon: React.ReactNode;
  chartOpen: boolean;
  data?: CalendarChartData;
  pending: boolean;
  overYearSelected?: boolean;
  withoutItemCount?: boolean;
}

export const CalendarChartContent = <
  T extends Omit<Partial<UserFilter>, 'date'> & { date?: string[] },
>({
  filters,
  onOpenCustomGrid,
  chartCustomGrid,
  chartIcon,
  chartOpen,
  data,
  pending,
  overYearSelected,
  withoutItemCount,
}: CalendarChartContentProps<T>) => {
  const calendarChartData = useMemo(() => {
    return data?.rows.length
      ? data.rows.reduce<CalendarChartComponentData>((acc, next) => {
          const year = getYear(new Date(next.value));
          const month = getMonth(new Date(next.value));
          const prev = acc[year];

          return {
            ...acc,
            [year]: [
              ...(prev ? prev : []),
              { date: next.value, value: next.total, month: month + 1 },
            ],
          };
        }, {})
      : undefined;
  }, [data?.rows]);

  const renderTooltip = useCallback(
    (params: TooltipComponentFormatterCallbackParams): string => {
      if (Array.isArray(params)) {
        throw new Error('The Array type is not accepted in renderTooltip');
      }

      if (!params.seriesName || !Number.isInteger(params.dataIndex)) {
        return '';
      }

      const data = calendarChartData?.[params.seriesName][params.dataIndex!];

      if (!data) {
        return '';
      }

      const date = format(newDateTimezoneOffset(data.date), DEFAULT_FORMATTED_DATE_FORMAT_FNS);

      return renderToString(
        <ChartTooltip
          title={date}
          value={data.value ?? 0}
          backgroundColor={typeof params.color === 'string' ? params.color : undefined}
        />,
      );
    },
    [calendarChartData],
  );

  const onDateSelect = useCallback(
    (data: (Omit<CalendarChartsCustomGrid<T>, 'filters'> & { date: string[] }) | null) => {
      if (!data) {
        onOpenCustomGrid(null);
      } else {
        const { date, index, year } = data;
        onOpenCustomGrid?.({ filters: { ...filters, date }, year, index });
      }
    },
    [filters, onOpenCustomGrid],
  );

  const yearKeys: string[] = useMemo(
    () => (calendarChartData ? getTypedObjectKeys(calendarChartData) : []),
    [calendarChartData],
  );

  const dateRange = useMemo(() => {
    const [dateFrom, dateTo] = filters.date ?? [];

    if (!dateFrom || !dateTo) {
      return [];
    }
    // For now, if the user selects 1 month, we show 1 month, if the user selects a year or less, we show 12 months from dateFrom
    const monthSelected = differenceInMonths(new Date(dateTo), new Date(dateFrom)) < 1;
    if (monthSelected) {
      const yearKey = yearKeys[0] ?? '';
      const firstMonth = calendarChartData ? calendarChartData[yearKey][0].date : '';
      const month = firstMonth
        ? format(newDateTimezoneOffset(firstMonth), DEFAULT_YEAR_MONTH_FORMAT_FNS)
        : '';
      return [month];
    }
    const endRange = format(
      endOfMonth(addMonths(newDateTimezoneOffset(dateFrom), 11)),
      DEFAULT_DATE_FORMAT_FNS,
    );

    return [dateFrom, endRange];
  }, [calendarChartData, filters.date, yearKeys]);

  const maxCount = useMemo(() => {
    return yearKeys.reduce((acc, key) => {
      const maxValue = maxBy(calendarChartData?.[key] ?? [], 'value')?.value ?? 0;
      return maxValue > acc ? maxValue : acc;
    }, 0);
  }, [calendarChartData, yearKeys]);

  const selectedData = useMemo(
    () =>
      chartCustomGrid
        ? {
            date: chartCustomGrid.filters.date ?? [],
            year: chartCustomGrid.year,
            index: chartCustomGrid.index,
          }
        : null,
    [chartCustomGrid],
  );

  const mainContent = useMemo(() => {
    if (!calendarChartData || pending || overYearSelected) {
      return (
        <CalendarChartEmpty pending={pending} opacity={0.3}>
          {((!pending && !calendarChartData?.length) || overYearSelected) && (
            <Stack height="100%" alignItems="center" justifyContent="center" pb={6.5}>
              <Box maxWidth={overYearSelected ? 500 : 300}>
                <Typography
                  variant="h1"
                  textAlign="center"
                  sx={{
                    fontStyle: 'normal',
                  }}
                >
                  {overYearSelected ? (
                    <FormattedMessage id="chartOverYearSelected" />
                  ) : (
                    <FormattedMessage id="chartNoData" />
                  )}
                </Typography>
              </Box>
            </Stack>
          )}
        </CalendarChartEmpty>
      );
    }

    return (
      <CalendarChart
        renderTooltip={renderTooltip}
        chartData={calendarChartData}
        maxCount={maxCount}
        onDateSelect={onDateSelect}
        dateFormat={DEFAULT_DATE_FORMAT_FNS}
        selectedData={selectedData}
        loading={pending}
        dateRange={dateRange}
        withoutItemCount={withoutItemCount}
      />
    );
  }, [
    calendarChartData,
    dateRange,
    maxCount,
    onDateSelect,
    overYearSelected,
    pending,
    renderTooltip,
    selectedData,
    withoutItemCount,
  ]);

  if (!chartOpen) {
    return null;
  }

  return (
    <Stack>
      <Stack direction="row" justifyContent="flex-end" gap={2} pr={1}>
        {chartIcon}
      </Stack>
      {mainContent}
    </Stack>
  );
};
