import { Box, Divider } from '@mui/material';
import { FilterKeys, FilterValue, GroupOptions, SelectOption } from '@schooly/api';
import { buildClassName } from '@schooly/utils/build-classname';
import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FilterType } from '../../../context/filters/scheme';
import { useFilters } from '../../../context/filters/useFilters';
import useScrollLock from '../../../hooks/useScrollLock';
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import { Toggle, ToggleOption } from '../../uikit/Toggle/Toggle';
import Hint from '../Hint';
import ArrangeByControl from './ArrangeByControl';
import FilterControl from './FilterControl';
import { GroupControl } from './GroupControl/GroupControl';
import { OnlyTutorGroupControl } from './OnlyTutorGroupControl/OnlyTutorGroupControl';
import { StaffControl } from './StaffControl/StaffControl';
import { SubjectControl } from './SubjectControl/SubjectControl';

export interface HeaderFilterPopupProps {
  isOpen?: boolean;
  isOpenOnce?: boolean;
  openFilter?: FilterKeys;
  closePopup?: () => void;
  modal?: boolean;
  modalTitleTextId?: string;
  isModalOpen?: boolean;
}

export interface IControl {
  key: FilterKeys;
  titleTextId: string;
  popupTitleTextId: string;
  options?: SelectOption<FilterValue>[];
  groupOptions?: GroupOptions;
}

export const HeaderFilterPopup: FC<HeaderFilterPopupProps> = ({
  isOpen,
  isOpenOnce,
  openFilter,
  closePopup,
  modal,
  modalTitleTextId = 'filter-Filters',
  isModalOpen,
}) => {
  const { formatMessage } = useIntl();
  const [error] = useState({});
  const {
    filters,
    filterType,
    applyFilters,
    clearFilters,
    isDraftSet,
    isShowArrangeByControl,
    isShowStaffControl,
    allowExclusion,
    controls,
    setExclude,
    resetFilters,
  } = useFilters();

  const disableApplyButton = !!Object.keys(error).length;

  useScrollLock(isModalOpen || Boolean(modal && isOpen));

  const popupClassName = buildClassName(
    'HeaderFilter__filter-popup',
    ...(modal
      ? ['HeaderFilter__filter-popup_modal']
      : [
          'HeaderFilter__filter-popup_dropdown',
          'dropdown-menu',
          'shadow-sm',
          'custom-scrollbar',
          isOpen && 'show',
          isOpenOnce && 'open-once',
        ]),
  );

  const popupFooterClassName = buildClassName(
    'HeaderFilter__popup-footer',
    'd-flex flex-column',
    isShowArrangeByControl && 'HeaderFilter__popup-footer_space-small',
  );

  const handleApply = useCallback(() => {
    applyFilters();
    closePopup?.();
  }, [applyFilters, closePopup]);

  const handleClear = useCallback(() => {
    clearFilters(false);
  }, [clearFilters]);

  const handleClosePopup = useCallback(() => {
    resetFilters({ filters: filters.applied });
    closePopup?.();
  }, [closePopup, filters.applied, resetFilters]);

  const renderContent = useCallback(() => {
    return (
      <>
        <div className="filters-section">
          {!modal && (
            <h4 className="mb-2 d-flex align-items-center">
              <FormattedMessage id="filter-Filters" />
              {filterType === FilterType.Parent && (
                <Hint className="ml-1">
                  <FormattedMessage id="peopleDetail-ParentFilterTooltip" />
                </Hint>
              )}
            </h4>
          )}

          {controls.map((control) => {
            switch (control.key) {
              case FilterKeys.Staff:
              case FilterKeys.Creator:
                return (
                  <StaffControl
                    control={control}
                    key={control.key}
                    shouldOpen={[FilterKeys.Staff, FilterKeys.Creator].includes(openFilter!)}
                    isOpen={isOpen}
                  />
                );
              case FilterKeys.Group:
                return (
                  <GroupControl
                    control={control}
                    key={control.key}
                    shouldOpen={openFilter === FilterKeys.Group}
                    isOpen={isOpen}
                  />
                );
              case FilterKeys.Subject:
                return (
                  <SubjectControl
                    key={control.key}
                    filterKey={control.key}
                    titleTextId={control.titleTextId}
                    popupTitleTextId={control.popupTitleTextId}
                    options={control.options}
                    groupOptions={control.groupOptions}
                    shouldOpen={openFilter === control.key}
                    isFocusable={isOpen}
                    isHeaderFilterPopupOpen={isOpen}
                  />
                );
              case FilterKeys.OnlyTutorGroups:
                return <OnlyTutorGroupControl key={control.key} />;
              default:
                return (
                  <FilterControl
                    key={control.key}
                    filterKey={control.key}
                    titleTextId={control.titleTextId}
                    popupTitleTextId={control.popupTitleTextId}
                    options={control.options}
                    groupOptions={control.groupOptions}
                    shouldOpen={openFilter === control.key}
                    isFocusable={isOpen}
                    isHeaderFilterPopupOpen={isOpen}
                  />
                );
            }
          })}
        </div>

        {isShowArrangeByControl && !isShowStaffControl && (
          <Box mx={2.5}>
            <Divider />
          </Box>
        )}

        <footer className={popupFooterClassName}>
          {isShowArrangeByControl && <ArrangeByControl isFocusable={isOpen} />}

          <div className="d-flex justify-content-end filter-actions">
            {isDraftSet && (
              <Button color="secondary" size="small" onClick={handleClear}>
                <FormattedMessage id="filter-Clear" />
              </Button>
            )}
            <Button
              isDisabled={disableApplyButton}
              color="primary"
              size="small"
              onClick={handleApply}
            >
              <FormattedMessage id="filter-Apply" />
            </Button>
          </div>
        </footer>
      </>
    );
  }, [
    controls,
    disableApplyButton,
    filterType,
    handleApply,
    handleClear,
    isDraftSet,
    isOpen,
    isShowArrangeByControl,
    isShowStaffControl,
    modal,
    openFilter,
    popupFooterClassName,
  ]);

  const toggleOptions: [ToggleOption<'include' | 'exclude'>, ToggleOption<'include' | 'exclude'>] =
    [
      { value: 'include', label: formatMessage({ id: 'filter-include' }) },
      { value: 'exclude', label: formatMessage({ id: 'filter-exclude' }) },
    ];
  const toggleValue = filters.draftExclude ? 'exclude' : 'include';

  const handleToggle = useCallback(
    (option: ToggleOption<'include' | 'exclude'>) => {
      setExclude(option.value === 'exclude');
    },
    [setExclude],
  );

  return modal ? (
    <Modal
      className="HeaderFilter__filter-modal"
      titleTextId={modalTitleTextId}
      isOpen={!!isOpen}
      onClose={handleClosePopup}
      additionalHeaderButton={
        allowExclusion ? (
          <div className="HeaderFilter__exclude-toggle">
            <Toggle options={toggleOptions} value={toggleValue} onChange={handleToggle} />
          </div>
        ) : undefined
      }
    >
      <div className={popupClassName}>{renderContent()}</div>
    </Modal>
  ) : (
    <div className={popupClassName}>{renderContent()}</div>
  );
};
