import { createContext } from 'react';

export interface MaintenanceContextProps {
  init?: boolean;
  enabled?: boolean;
  timestamp?: number;
  setInit: (value: boolean) => void;
  setEnabled: (value: boolean) => void;
  setTimestamp: (value: number) => void;
}

export const MaintenanceContext = createContext<MaintenanceContextProps>({
  setInit: () => {},
  setEnabled: () => {},
  setTimestamp: () => {},
});
MaintenanceContext.displayName = 'MaintenanceContext';
