import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  GET_SCHOOL_PROPERTIES_QUERY,
  SchoolProperty,
  useCreateDepartmentsMutation,
} from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { usePrevious } from '@schooly/hooks/use-previous';
import {
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  DragIcon,
  PlusIcon,
  SimpleButton,
  Spin,
} from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { SubmitHandler } from 'react-hook-form';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { DndListWrapper } from '../../../../../components/uikit/Dnd/dnd.styled';
import { ControlTextField } from '../../../../../components/uikit-components/FormTextField/ControlTextField';
import {
  ModalContent,
  ModalFooter,
  ModalMain,
  ModalSmall,
} from '../../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../../components/uikit-components/Modal/ModalHeader';
import { useSchool } from '../../../../../hooks/useSchool';
import { queryClient } from '../../../../../queryClient';
import { SchoolSettingsLayout } from '../../../SchoolSettingsLayout';

interface SchoolTuneDepartmentsContentProps {
  departments: SchoolProperty[];
}

export interface SchoolTuneDepartmentForm {
  departments?: { id?: string; name?: string }[];
}

const getEmptyDepartment = () => ({
  id: undefined,
  name: undefined,
});

export const SchoolTuneDepartmentsContent: FC<SchoolTuneDepartmentsContentProps> = ({
  departments,
}) => {
  const navigate = useNavigate();
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { schoolId = '', currentSchool } = useSchool();
  const createDepartments = useCreateDepartmentsMutation();
  const saving = createDepartments.isLoading;

  const form = useForm<SchoolTuneDepartmentForm>({
    defaultValues: {
      departments: departments?.length ? [...departments] : [getEmptyDepartment()],
    },
  });
  const { fields, append, remove, replace, move } = useFieldArray({
    control: form.control,
    name: 'departments',
  });

  const prevFields = usePrevious(fields);

  const firstField = form.getValues('departments.0');

  const shouldFocusLastItem = Boolean(
    fields.length === 1
      ? !firstField.name
      : prevFields && fields && fields.length - prevFields.length === 1,
  );

  const handleClose = useCallback(() => {
    if (saving) {
      return;
    }

    navigate({ pathname: '/settings/tune', search: '?tab=departments' });
  }, [navigate, saving]);

  const addDepartment = useCallback(() => {
    append(getEmptyDepartment());
  }, [append]);

  const deleteDepartment = useCallback(
    (index: number) => () => {
      if (fields.length > 1) {
        remove(index);
      } else {
        replace([getEmptyDepartment()]);
      }
    },
    [fields.length, remove, replace],
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const handleSubmit = useCallback<SubmitHandler<SchoolTuneDepartmentForm>>(
    async (data) => {
      if (!schoolId || !data.departments) {
        return;
      }

      try {
        await createDepartments.mutateAsync({
          schoolId,
          departments: data.departments.map((department, index) => ({
            name: department.name ?? '',
            order: index + 1,
          })),
        });

        await queryClient.invalidateQueries([GET_SCHOOL_PROPERTIES_QUERY]);

        navigate({ pathname: '/settings/tune', search: '?tab=departments' });
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }
    },
    [createDepartments, navigate, schoolId, showError],
  );

  return (
    <SchoolSettingsLayout>
      <ModalSmall open>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader title={currentSchool?.name} active>
              {!saving && (
                <IconButton onClick={handleClose}>
                  <CrossIcon />
                </IconButton>
              )}
            </ModalHeader>
            <ModalMain>
              <ModalContent active>
                <Stack gap={2.5} sx={{ height: '100%' }}>
                  <Typography variant="h2">
                    <FormattedMessage id="school-tabs-Departments" />
                  </Typography>

                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="DepartmentsDroppable">
                      {(provided) => (
                        <DndListWrapper
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          alignItems="flex-start"
                          gap={2}
                        >
                          {fields?.map((field, index) => (
                            <Draggable key={field.id} draggableId={field.id} index={index}>
                              {(provided) => (
                                <Stack
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                  sx={{
                                    width: '100%',
                                    '& .MuiOutlinedInput-root': { bgcolor: 'background.paper' },
                                  }}
                                  style={provided.draggableProps.style}
                                >
                                  {fields.length > 1 && (
                                    <IconButton inverse {...provided.dragHandleProps}>
                                      <DragIcon />
                                    </IconButton>
                                  )}
                                  <ControlTextField
                                    name={`departments.${index}.name`}
                                    control={form.control}
                                    rules={{ required: true }}
                                    label={$t({ id: 'school-tabs-Departments-DepartmentName' })}
                                    autoFocus={shouldFocusLastItem && index === fields.length - 1}
                                    fullWidth
                                    canClear
                                  />
                                  {fields.length > 1 && (
                                    <IconButton inverse onClick={deleteDepartment(index)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Stack>
                              )}
                            </Draggable>
                          ))}

                          {provided.placeholder}

                          <SimpleButton startIcon={<PlusIcon />} onClick={addDepartment}>
                            <FormattedMessage id="school-tabs-Departments-AddDepartment" />
                          </SimpleButton>
                        </DndListWrapper>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Stack>
              </ModalContent>
            </ModalMain>
            <ModalFooter sx={{ justifyContent: 'space-between' }} active>
              <Button variant="outlined" disabled={saving} onClick={handleClose}>
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button type="submit" disabled={saving} endIcon={saving ? <Spin /> : <CheckIcon />}>
                <FormattedMessage id="action-Save" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalSmall>
    </SchoolSettingsLayout>
  );
};
