import { To } from 'history';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { actions, StackItem } from '../redux/slices/routerSlice';

export const useRouter = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { stack } = useAppSelector((state) => state.router);

  const lastStackItem = useMemo(() => {
    return stack.length > 0 ? stack[stack.length - 1] : undefined;
  }, [stack]);

  const push = useCallback(
    (payload: StackItem) => {
      dispatch(actions.push(payload));
    },
    [dispatch],
  );

  const pop = useCallback(() => {
    dispatch(actions.pop());
  }, [dispatch]);

  const clean = useCallback(() => {
    dispatch(actions.clean());
  }, [dispatch]);

  const replaceLast = useCallback(
    (payload: StackItem) => {
      dispatch(actions.replaceLast(payload));
    },
    [dispatch],
  );

  /**
   * Navigate back through the stack.
   * @param defaultPath fallback path to navigate to, when stack is empty
   */
  const goBack = useCallback(
    (defaultPath: To) => {
      if (stack.length > 1) {
        pop();
        navigate(stack[stack.length - 2].location);
      } else {
        navigate(defaultPath);
      }
    },
    [navigate, pop, stack],
  );

  return { stack, lastStackItem, push, pop, clean, replaceLast, goBack };
};
