import { FilterKeys, FilterValue, GroupUserType, SelectOption, UserFilter } from '@schooly/api';
import { MAX_PAGE_SIZE } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { StaffIcon } from '@schooly/style';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFilters } from '../../../../context/filters/useFilters';
import usePrevious from '../../../../hooks/usePrevious';
import { useShowMore } from '../../../../hooks/useShowMore';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { actions } from '../../../../redux/slices/simple-lists/actions';
import { SimpleListsState } from '../../../../redux/slices/simple-lists/reducer';
import searchWords from '../../../../utils/searchWords';
import Button from '../../../ui/Button';
import Tag from '../../../ui/Tag';
import Preloader from '../../../uikit/Preloader/Preloader';
import { SelectPeopleRow } from '../../../uikit-components/SelectPeopleRow/SelectPeopleRow';
import { SEARCH_DEBOUNCE_WAIT } from '../HeaderSearch/HeaderSearchPeopleList';

import './FilterPeopleSelectPopupContent.scss';

export interface FilterPeopleSelectPopupContentProps {
  searchQuery: string;
  options?: SelectOption<FilterValue>[];
  hasNoneOption?: boolean;
  popupTitleTextId?: string;
  noneOption: SelectOption<FilterValue>;
  showTitle?: boolean;
  isOpen?: boolean;
  filterKey: FilterKeys;
  isOptionActive?: (option: SelectOption<FilterValue>, userType?: GroupUserType) => boolean;
  handleSelectOption: (option?: SelectOption<FilterValue>, userType?: GroupUserType) => void;
  handleRemoveOption: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    option?: SelectOption<FilterValue>,
    userType?: GroupUserType,
  ) => void;
  resetSearchQuery: VoidFunction;
  query?: string;
  isHeaderFilterPopupOpen?: boolean;
}

export const FilterPeopleSelectPopupContent: React.FC<FilterPeopleSelectPopupContentProps> = ({
  filterKey,
  searchQuery,
  options = [],
  hasNoneOption,
  isOptionActive,
  handleSelectOption,
  handleRemoveOption,
  resetSearchQuery,
  noneOption,
  query,
  isOpen,
  isHeaderFilterPopupOpen,
}) => {
  const { formatMessage } = useIntl();
  const { filters, setDraftValue } = useFilters();
  const dispatch = useAppDispatch();
  const [isNewListFetched, setNewListFetched] = useState(false);
  const { currentStaff, schoolId } = useAuth();
  const { simpleLists } = useAppSelector((state) => state);

  const type = filterKey === FilterKeys.Creator ? 'staff' : (filterKey as keyof SimpleListsState);

  const { results, fetching, error, canShowMore, current_page, total_pages } = simpleLists[type];

  useEffect(() => {
    if (!isOpen) return;
    setNewListFetched(false);
    return () => {
      setTimeout(() => {
        setNewListFetched(false);
      }, 400);
    };
  }, [isOpen]);

  const prevDate = usePrevious(filters.draft.date);

  useEffect(() => {
    const draftDate = filters?.draft?.date?.join('');
    const prevDraftDate = prevDate?.join('');

    if (draftDate === prevDraftDate) return;
    if (!isHeaderFilterPopupOpen) return;

    fetchList(MAX_PAGE_SIZE, undefined, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.draft.date]);

  const fetchList = useCallback(
    (pageSize?: number, pageNumber?: number, query?: string) => {
      if (!schoolId) return;

      dispatch(
        actions[type].get({
          schoolId,
          pageNumber,
          pageSize,
          filters: {
            ...filters.applied,
            date: filters.draft.date,
          } as UserFilter,
          query,
          type,
        }),
      );

      setNewListFetched(true);
    },
    [dispatch, filters.applied, filters.draft.date, schoolId, type],
  );

  const requestFetchList = useMemo(
    () =>
      debounce(
        (pageSize?: number, pageNumber?: number, query?: string) =>
          fetchList(pageSize, pageNumber, query),
        SEARCH_DEBOUNCE_WAIT,
        {
          leading: false,
          trailing: true,
        },
      ),
    [fetchList],
  );

  const { showMoreLoader } = useShowMore({
    getResource: requestFetchList as any,
    fetching,
    canShowMore: canShowMore && !query?.length,
    current_page,
    total_pages,
  });

  useEffect(() => {
    if (!isOpen) return;
    setNewListFetched(false);
    requestFetchList(undefined, undefined, query);
  }, [query, isOpen, requestFetchList]);

  useEffect(() => {
    if (!isHeaderFilterPopupOpen) return;

    const draftDate = filters?.draft?.date?.join('');
    const prevDraftDate = prevDate?.join('');

    if (!draftDate) return;
    if (draftDate === prevDraftDate) return;

    // remove draft results which are not avaliable because of date changes
    let currentFilterKey = filterKey;

    if (type === 'staff') {
      currentFilterKey = filters?.draft.staff?.length ? FilterKeys.Staff : FilterKeys.Creator;

      if (!filters?.draft.staff?.length && !filters?.draft?.creator?.length) {
        return;
      }
    } else if (!filters?.draft[type]?.length) {
      return;
    }

    const draftValues = filters.draft[currentFilterKey];

    if (
      !results ||
      !draftValues.length ||
      (draftValues.length === 1 && draftValues[0] === currentStaff?.relation_id)
    )
      return;

    const ids: FilterValue[] = results?.map((s) => s.relation_id);

    if (draftValues?.some((s) => !ids.includes(s))) {
      const filteredResults = results
        ?.filter((r) => draftValues.includes(r.relation_id))
        .map((r) => r.relation_id);

      // TODO: why it's array here?
      setDraftValue([currentFilterKey] as unknown as FilterKeys, filteredResults);
    }
  }, [
    currentStaff?.relation_id,
    filterKey,
    filters.draft,
    isHeaderFilterPopupOpen,
    prevDate,
    results,
    setDraftValue,
    type,
  ]);

  const handleRefresh = useCallback(() => {
    resetSearchQuery();
    fetchList();
  }, [fetchList, resetSearchQuery]);

  const getOptionText = useCallback(
    (option: SelectOption<FilterValue>) =>
      option.labelTextId ? formatMessage({ id: option.labelTextId }) : option.label,
    [formatMessage],
  );

  const onSelectOption = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, selectOption?: SelectOption<FilterValue>) => {
      const isActive = isOptionActive && selectOption && isOptionActive(selectOption);

      if (isActive) {
        handleRemoveOption(e, selectOption);
      } else {
        handleSelectOption(selectOption);
      }
    },
    [handleSelectOption, handleRemoveOption, isOptionActive],
  );

  const displayedOptions = useMemo(() => {
    if (!searchQuery) {
      return options;
    }

    return options.filter((o) => {
      const text = getOptionText(o);
      return text && searchWords(text, searchQuery);
    });
  }, [getOptionText, options, searchQuery]);

  const showPreloader = useMemo(() => {
    const emptyList =
      !displayedOptions.length ||
      (displayedOptions.length === 1 && displayedOptions[0]?.value === currentStaff?.relation_id);

    return (fetching && emptyList) || !isNewListFetched;
  }, [currentStaff?.relation_id, displayedOptions, fetching, isNewListFetched]);

  if (searchQuery && !displayedOptions.length && !fetching) {
    return (
      <div>
        <span className="form-select-option dropdown-item form-select-no-options-found">
          <FormattedMessage id="input-NoOptionsFound" />
        </span>
      </div>
    );
  }

  const renderNoneOption = (className?: string, userType?: GroupUserType) => {
    const isActive = isOptionActive && isOptionActive(noneOption, userType);

    return (
      <li key={noneOption.value} className={className}>
        <Tag
          selectOption={noneOption}
          className={isActive ? 'active' : undefined}
          onClick={onSelectOption}
          isBig
        >
          {userType === 'staff' && <StaffIcon className="icon-option mr-1" />}{' '}
          {getOptionText(noneOption)}
        </Tag>
      </li>
    );
  };

  const renderFilterOptions = (filterOptions: SelectOption<FilterValue>[]) =>
    filterOptions.map((option) => {
      const isActive = isOptionActive && option && isOptionActive(option);

      return (
        <SelectPeopleRow
          isMe={type === 'staff' && currentStaff?.relation_id === option.value}
          isSelected={isActive}
          key={option.value}
          user={option.item}
          selectOption={option}
          onClick={onSelectOption}
        />
      );
    });

  if (error) {
    return (
      <div className="FilterPeopleSelectPopupContent__error">
        <p className="FilterPeopleSelectPopupContent__error__text">{error.reason}</p>
        <Button onClick={handleRefresh} size="x-small">
          <FormattedMessage id="action-Refresh" />
        </Button>
      </div>
    );
  }

  return (
    <>
      {!searchQuery && hasNoneOption && renderNoneOption()}
      {showPreloader ? <Preloader /> : renderFilterOptions(displayedOptions)}
      {showMoreLoader}
    </>
  );
};
