import { Box, Stack, styled } from '@mui/material';

import { ModalSideBar } from '../../../components/uikit-components/Modal/Modal.styled';

export const ReportsPreviewContentStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'stretch',
  flex: '1 1 auto',
  overflow: 'hidden',
}));

export const ReportsPreviewSideBar = styled(ModalSideBar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,

  '& .HeaderFilter': {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
}));

interface ReportsPreviewSideBarTabProps {
  active?: boolean;
}

export const ReportsPreviewSideBarTab = styled(Box, {
  shouldForwardProp: (prop) => !(['active'] as PropertyKey[]).includes(prop),
})<ReportsPreviewSideBarTabProps>(({ theme, active }) => ({
  cursor: 'pointer',
  padding: theme.spacing(0.5, 1),
  width: '100%',
  minWidth: 100,
  backgroundColor: active ? theme.palette.background.default : 'transparent',

  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const ReportsPreviewStudentList = styled(Box)(({ theme }) => ({
  '& .PersonCard__info__username': {
    maxWidth: 140,
  },
}));
