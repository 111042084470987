import { Button, ButtonProps, Icon, Stack, Typography } from '@mui/material';
import { FC } from 'react';

type ButtonWithDescriptionProps = {
  title: string;
  description: string;
  oneLine: boolean;
} & ButtonProps;

export const ButtonWithDescription: FC<ButtonWithDescriptionProps> = ({
  title,
  description,
  startIcon,
  oneLine,
  ...props
}) => {
  return (
    <Button
      variant="outlined"
      {...props}
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        minHeight: oneLine ? 90 : 112,
        pt: 1.5,
        ...props.sx,
      }}
    >
      <Stack gap={1} color="common.main2">
        <Stack direction="row" alignItems="center" gap={1}>
          <Icon fontSize="medium">{startIcon}</Icon>
          <Typography
            variant="h2"
            sx={(theme) => ({
              '&&': {
                color: theme.palette.common.main2,
              },
            })}
          >
            {title}
          </Typography>
        </Stack>
        <Typography textAlign="start" color="text.primary" variant="h3">
          {description}
        </Typography>
      </Stack>
    </Button>
  );
};
