import { Box, Tooltip } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

interface AddPeopleToGroupProps extends PropsWithChildren {
  text: React.ReactNode;
  offset?: number[];
  width?: string;
  arrow?: boolean;
  disableHoverListener?: boolean;
  open?: boolean;
  sx?: SystemStyleObject;
}

export const ManageGroupModalTooltip: FC<AddPeopleToGroupProps> = ({
  text,
  children,
  offset = [0, 0],
  arrow,
  width = '200px',
  disableHoverListener,
  open,
  sx,
}) => {
  return (
    <Tooltip
      open={open ?? undefined}
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            boxShadow: `0px 20px 40px rgba(37, 39, 90, 0.1)`,
            backgroundColor: theme.palette.background.paper,
            '& .MuiTooltip-arrow': {
              color: theme.palette.background.paper,
            },
            width,
            ...(sx ?? {}),
          }),
        },
      }}
      disableHoverListener={!!disableHoverListener}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
        ],
      }}
      arrow={arrow}
      title={<Box>{text}</Box>}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};
