import { Stack, Typography } from '@mui/material';
import { UserRolePermissionsDateRange } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import React, { FC } from 'react';

import PersonCardBasic from '../../../components/common/PersonCard/PersonCardBasic';
import {
  Grid,
  GridBody,
  GridCell,
  GridContainer,
  GridRow,
} from '../../../components/uikit-components/Grid/Grid';
import formatDate from '../../../utils/formatDate';

interface UserRoleStaffGridProps {
  staff?: SimpleListResult[] & UserRolePermissionsDateRange;
}

export const UserRoleStaffGrid: FC<UserRoleStaffGridProps> = ({ staff }) => {
  return (
    <Stack gap={1} height="100%">
      <GridContainer sx={{ height: '82%', overflow: 'scroll' }}>
        <Grid sx={{ borderCollapse: 'collapse' }}>
          <GridBody>
            {staff?.map((user: SimpleListResult) => (
              <GridRow>
                <GridCell noVerticalPadding>
                  <PersonCardBasic
                    isListItem
                    isUsernameClickable
                    user={user}
                    userType="staff"
                    key={user.relation_id}
                  />
                </GridCell>
                <GridCell>
                  <Typography variant="body1">
                    From {formatDate((user as UserRolePermissionsDateRange).start)}
                  </Typography>
                </GridCell>
              </GridRow>
            ))}
          </GridBody>
        </Grid>
      </GridContainer>
    </Stack>
  );
};
