import { Box, BoxProps } from '@mui/material';
import React, { FC } from 'react';

const animationParams = {
  fill: 'freeze',
  dur: '4s',
  repeatCount: 'indefinite',
  calcMode: 'linear',
};

export const LoadingIcon: FC<BoxProps<'svg'>> = (props) => {
  return (
    <Box
      component="svg"
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
        <path
          d="M27.1789985,8.67458767 C25.4508473,6.94466337 22.9742135,6.07970123 19.7490972,6.07970123 C14.9114227,6.07970123 10.7631509,8.27484819 10.7631509,12.6654073 C10.7631509,17.0559664 15.0965563,18.6274668 19.485552,19.6764421 C23.2884133,20.5853305 27.3044639,21.4072989 28.4280045,25.0189787 C29.9775272,30 25.6795814,33.6300684 20,33.9679421 C16.2136124,34.1931912 12.9549047,32.9882299 10.2238768,30.3530581"
          stroke="#24275b"
          strokeWidth="6"
          strokeDasharray="69 69"
          strokeDashoffset="0"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="  69;  69; 0.0; 0.0;   -69; -69"
            keyTimes=" 0; 0.1; 0.2; 0.7; 0.988;   1"
            {...animationParams}
          />
        </path>

        <circle cx="0" cy="0" r="0" fill="#68DF83">
          <animate
            attributeName="r"
            values="  1; 3.1; 3.10;  1"
            keyTimes="0; 0.1; 0.75; 1"
            {...animationParams}
          />
          <animateMotion
            path="M18.5,1.5 C21.1281961,1.83333333 24.0211956,4.22486256 27.1789985,8.67458767 C25.4508473,6.94466337 22.9742135,6.07970123 19.7490972,6.07970123 C14.9114227,6.07970123 10.7631509,8.27484819 10.7631509,12.6654073 C10.7631509,17.0559664 15.0965563,18.6274668 19.485552,19.6764421 C23.2884133,20.5853305 27.3044639,21.4072989 28.4280045,25.0189787 C29.9775272,30 25.6795814,33.6300684 20,33.9679421 C16.2136124,34.1931912 12.9549047,32.9882299 10.2238768,30.3530581 C5.61934307,26.1914682 4.71138414,22.5737821 7.5,19.5 C11.6829238,14.8893268 18.5767014,16.8882002 20.5,17.3192592 C22.4232986,17.7503182 32.4241141,20.1783095 35.5,23.9473537 C37.5505906,26.4600498 37.2172573,29.6442652 34.5,33.5 C33.195961,35.3906523 31.5292943,36.7239856 29.5,37.5 C26.4560586,38.6640216 10.2238768,39.521225 5.5,33.5 C0.776123191,27.478775 2,20 3,17 C4,14 6.23684905,7.5 8.5,4.5 C10.7631509,1.5 14.5577058,1 18.5,1.5 C21.1281961,1.83333333 24.0211956,4.22486256 Z"
            keyPoints="0; 0.0555; 0.4; 0.645; 0.645; 1"
            keyTimes=" 0; 0.1000; 0.2; 0.330; 0.850; 1"
            {...animationParams}
          />
        </circle>

        <path
          d="M17.7084508,22.3404346 L31.6582469,25.6673917 C31.4971054,24.38528 31.0121703,23.1239525 30.2034415,21.883409 C28.9903483,20.0225938 27.5592749,19.2999857 26.4407253,18.7348471 C23.5371589,17.2678425 19.27505,16.7448701 16.8067515,15.7755918 C15.1612192,15.1294062 14.1806166,14.3708756 13.8649438,13.5 L7.83131062,13.7788476 C8.1071135,15.8861125 9.06021755,17.6441413 10.6906228,19.0529338 C12.321028,20.4617263 14.660304,21.5575599 17.7084508,22.3404346 Z"
          fill="#24275B"
          opacity="0"
        >
          <animate
            attributeName="opacity"
            values="  0; 0.0; 1.00; 1.00; 0.00; 0.00"
            keyTimes="0; 0.2; 0.21; 0.45; 0.65; 1.00"
            {...animationParams}
          />
        </path>

        <circle cx="0" cy="0" r="3" fill="#24275B">
          <animate
            attributeName="r"
            values="  0; 0.0; 3.0; 3.000; 0"
            keyTimes="0; 0.2; 0.7; 0.985; 1"
            {...animationParams}
          />
          <animateMotion
            path="M27.1789985,8.67458767 C25.4508473,6.94466337 22.9742135,6.07970123 19.7490972,6.07970123 C14.9114227,6.07970123 10.7631509,8.27484819 10.7631509,12.6654073 C10.7631509,17.0559664 15.0965563,18.6274668 19.485552,19.6764421 C23.2884133,20.5853305 27.3044639,21.4072989 28.4280045,25.0189787 C29.9775272,30 25.6795814,33.6300684 20,33.9679421 C16.2136124,34.1931912 12.9549047,32.9882299 10.2238768,30.3530581"
            keyPoints="0; 0.000; 1"
            keyTimes=" 0; 0.712; 1"
            {...animationParams}
          />
        </circle>
      </g>
    </Box>
  );
};
