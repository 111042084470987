import { Icon, Stack, Typography } from '@mui/material';
import { SCHOOL_TYPE_OPTIONS, SchoolType } from '@schooly/constants';
import { CheckIcon, EditIcon } from '@schooly/style';
import { FC, PropsWithChildren } from 'react';
import { UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SchoolCreateForm, SchoolCreateState } from './useSchoolCreate';

type SchoolCreateTitleProps = {
  onSetState: (v: SchoolCreateState) => void;
  state: SchoolCreateState;
  form: UseFormReturn<SchoolCreateForm>;
};
export const SchoolCreateTitle: FC<SchoolCreateTitleProps> = ({ state, form, onSetState }) => {
  const { $t } = useIntl();

  const name = form.watch('name');
  const type = form.watch('type');
  const educationSystem = form.watch('educationSystem');

  const hasEduTypeStep = type === SchoolType.Regular;

  return (
    <Stack>
      <Stack
        sx={(theme) => ({
          height: theme.spacing(5),
          gap: 5,
          transition: 'all .3s',
          transform: `translateY(-${theme.spacing(
            10 * (Math.min(state, hasEduTypeStep ? 3 : 2) - 1),
          )})`,
        })}
      >
        <Title
          order={SchoolCreateState.Name}
          isVisible={state >= SchoolCreateState.NameFromConfigure}
          isCompleted={state > SchoolCreateState.Name}
          onSetState={onSetState}
        >
          {state === SchoolCreateState.Name ? $t({ id: 'school-create-WhatIsSchoolName' }) : name}
        </Title>
        <Title
          order={SchoolCreateState.Type}
          isVisible={state >= SchoolCreateState.Type}
          isCompleted={state > SchoolCreateState.Type}
          onSetState={onSetState}
        >
          {state === SchoolCreateState.Type
            ? $t({ id: 'school-create-WhatType' })
            : $t({ id: SCHOOL_TYPE_OPTIONS.find((o) => o.value === type)?.labelTextId || '' })}
        </Title>
        {hasEduTypeStep && (
          <Title
            order={SchoolCreateState.System}
            isVisible={state >= SchoolCreateState.System}
            isCompleted={state > SchoolCreateState.System}
            onSetState={onSetState}
          >
            {state === SchoolCreateState.System
              ? $t({ id: 'school-create-WhatEducationSystem' })
              : educationSystem}
          </Title>
        )}
      </Stack>
    </Stack>
  );
};

type TitleProps = PropsWithChildren<{
  order: SchoolCreateState;
  isCompleted?: boolean;
  isVisible: boolean;
  onSetState: (v: SchoolCreateState) => void;
}>;
const Title: FC<TitleProps> = ({ children, order, isCompleted, isVisible, onSetState }) => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={2.5}
      sx={(theme) => ({
        pointerEvents: isVisible ? 'auto' : 'none',
        height: theme.spacing(5),
        transition: 'all .5s',
        opacity: isVisible ? 1 : 0,
      })}
    >
      <Stack
        sx={(theme) => ({
          width: theme.spacing(3.75),
          height: theme.spacing(3.75),
          lineHeight: theme.spacing(3.75),
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'white',
          borderRadius: '50%',
          border: `1px solid ${
            isCompleted ? theme.palette.success.main : theme.palette.common.grey
          }`,
          color: isCompleted ? theme.palette.success.main : theme.palette.primary.main,
        })}
      >
        {isCompleted ? <CheckIcon /> : order}
      </Stack>
      <Typography
        variant="h1"
        onClick={isCompleted ? () => onSetState(order) : undefined}
        sx={
          isCompleted ? { cursor: 'pointer', '&:not(:hover) .svg-icon': { opacity: 0 } } : undefined
        }
      >
        {children}
        {isCompleted && (
          <Icon sx={{ ml: 1 }}>
            <EditIcon />
          </Icon>
        )}
      </Typography>
    </Stack>
  );
};
