import { Button, IconButton, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import { usePrevious } from '@schooly/hooks/use-previous';
import { CheckIcon, CrossIcon, DeleteIcon, PlusIcon, Spin } from '@schooly/style';
import { format } from 'date-fns';
import React, { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ControlTextField } from '../../../../../components/uikit-components/FormTextField/ControlTextField';
import {
  ModalContent,
  ModalFooter,
  ModalMain,
  ModalSmall,
} from '../../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../../components/uikit-components/Modal/ModalHeader';
import { FormSchoolYear } from '../../../SchoolCreate/useSchoolCreate';

export interface SchoolTuneSchoolYearsForm {
  schoolYears?: FormSchoolYear[];
}

const getEmptySchoolYear = () => ({
  name: '',
  start: '',
  end: '',
});

type SchoolTuneSchoolYearsModalContentProps = {
  title: string;
  isSaving: boolean;
  onSubmit: (v: SchoolTuneSchoolYearsForm) => void;
  onClose: () => void;
} & SchoolTuneSchoolYearsForm;

export const SchoolTuneSchoolYearsModalContent: FC<SchoolTuneSchoolYearsModalContentProps> = ({
  onClose,
  onSubmit,
  isSaving,
  title,
  schoolYears,
}) => {
  const { $t } = useIntl();
  const form = useForm<SchoolTuneSchoolYearsForm>({
    defaultValues: {
      schoolYears: schoolYears?.length
        ? [...schoolYears.map(({ id, name, start, end }) => ({ id, name, start, end }))]
        : [getEmptySchoolYear()],
    },
  });
  const { fields, append, remove, replace } = useFieldArray({
    control: form.control,
    name: 'schoolYears',
  });

  const prevFields = usePrevious(fields);

  const firstField = form.getValues('schoolYears.0');

  const shouldFocusLastItem = Boolean(
    fields.length === 1
      ? !firstField.name
      : prevFields && fields && fields.length - prevFields.length === 1,
  );

  const addSchoolYear = useCallback(() => {
    append(getEmptySchoolYear());
  }, [append]);

  const deleteSchoolYear = useCallback(
    (index: number) => () => {
      if (fields.length > 1) {
        remove(index);
      } else {
        replace([getEmptySchoolYear()]);
      }
    },
    [fields.length, remove, replace],
  );

  const handleSubmit = useCallback<SubmitHandler<SchoolTuneSchoolYearsForm>>(
    async (data) => {
      if (!data.schoolYears?.length) {
        return;
      }

      onSubmit(data);
    },
    [onSubmit],
  );

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ModalHeader title={title} active>
            {!isSaving && (
              <IconButton onClick={onClose}>
                <CrossIcon />
              </IconButton>
            )}
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              <Stack gap={2.5} sx={{ height: '100%' }}>
                <Typography variant="h2">
                  <FormattedMessage id="school-tabs-SchoolYears" />
                </Typography>

                <Stack alignItems="flex-start" gap={2} sx={{ height: '100%' }}>
                  {fields?.map((schoolYearsField, index) => (
                    <Stack key={schoolYearsField.id} direction="row" gap={1} sx={{ width: '100%' }}>
                      <ControlTextField
                        name={`schoolYears.${index}.name`}
                        control={form.control}
                        rules={{ required: true }}
                        label={$t({ id: 'school-tabs-SchoolYears-SchoolYearName' })}
                        autoFocus={shouldFocusLastItem && index === fields.length - 1}
                        fullWidth
                        canClear
                      />
                      <Stack width="100%">
                        <Controller
                          control={form.control}
                          name={`schoolYears.${index}.start`}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => {
                            const endDate = schoolYearsField.end;
                            return (
                              <DateSelect
                                ref={field.ref}
                                onSetDate={(date) => {
                                  field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                                  if (endDate) {
                                    form.setValue(`schoolYears.${index}.end`, '');
                                  }
                                }}
                                date={field.value}
                                placeholder={$t({ id: 'school-tabs-SchoolYears-StartDate' })}
                                requiredLabel="required"
                                error={fieldState.error}
                                onClear={() => field.onChange('')}
                                hideTodayButton
                              />
                            );
                          }}
                        />
                      </Stack>
                      {fields.length > 1 && (
                        <IconButton inverse onClick={deleteSchoolYear(index)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Stack>
                  ))}
                  <Button variant="text" startIcon={<PlusIcon />} onClick={addSchoolYear}>
                    <FormattedMessage id="school-tabs-SchoolYears-AddSchoolYear" />
                  </Button>
                </Stack>
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooter sx={{ justifyContent: 'space-between' }} active>
            <Button variant="outlined" disabled={isSaving} onClick={onClose}>
              <FormattedMessage id="action-Cancel" />
            </Button>
            <Button type="submit" disabled={isSaving} endIcon={isSaving ? <Spin /> : <CheckIcon />}>
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
