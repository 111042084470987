import { Box, Typography, TypographyProps } from '@mui/material';
import DOMPurify from 'dompurify';
import React, { FC } from 'react';

type ShowHtmlProps = {
  html: string;
} & TypographyProps;

export const ShowHtml: FC<ShowHtmlProps> = ({ html, ...props }) => {
  return (
    <Box
      sx={{
        '*': {
          mb: 0,
        },
      }}
    >
      <Typography
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(html),
        }}
        {...props}
      />
    </Box>
  );
};
