import { SignUpStatuses, SignUpType } from '@schooly/api';

export const SignUpsStatusOptions = [
  SignUpStatuses.Draft,
  SignUpStatuses.Closed,
  SignUpStatuses.Open,
];

export const getSignUpsStatusLabel = (v: SignUpStatuses) => {
  switch (v) {
    case SignUpStatuses.Draft:
      return 'eventSignUps-status-Draft';
    case SignUpStatuses.Closed:
      return 'eventSignUps-status-Closed';
    case SignUpStatuses.Open:
      return 'eventSignUps-status-Open';

    default:
      throw new Error(v);
  }
};

export const SignUpsTypeOptions = [SignUpType.Regular, SignUpType.Slots];

export const getSignUpsTypeLabel = (v: SignUpType) => {
  switch (v) {
    case SignUpType.Regular:
      return 'eventSignUps-type-Regular';
    case SignUpType.Slots:
      return 'eventSignUps-type-Slots';

    default:
      throw new Error(v);
  }
};
