import { Grid, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

export const PASSWORD_CHECKS = [
  ['input-PasswordMinLength', /^.{8,}$/],
  ['input-PasswordNumber', /[\d]+/],
  ['input-PasswordUpperAndLowercase', /^(?=.*[a-z])(?=.*[A-Z]).+$/],
  ['input-PasswordSpecial', /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]+/],
] as const;

export const passwordValidCharactersCheck = {
  regex: /^[a-zA-Z\d^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]+$/,
  message: 'input-ErrorPasswordValidCharacters',
} as const;

type SignUpModalPasswordRequirementsProps = {
  password: string;
  error?: boolean;
};

export const checkPasswordRequirements = (s: string) => {
  return PASSWORD_CHECKS.every(([_, v]) => v.test(s));
};

export const checkPasswordCharacters = (s: string) => {
  return passwordValidCharactersCheck.regex.test(s);
};

export const SignUpModalPasswordRequirements: FC<SignUpModalPasswordRequirementsProps> = ({
  password,
  error,
}) => {
  const getColor = useCallback(
    (requirement: RegExp) => {
      let color = 'common.grey';
      if (requirement.test(password)) {
        color = 'success.main';
      } else if (error) {
        color = 'error.main';
      }
      return color;
    },
    [error, password],
  );

  return (
    <Grid container columnSpacing={2} px={1.5}>
      {PASSWORD_CHECKS.map(([labelId, r]) => (
        <Grid key={labelId} xs={6} item>
          <Typography sx={{ fontSize: 10 }} variant="body2" color={getColor(r)}>
            <FormattedMessage id={labelId} />
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
