import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Typography,
} from '@mui/material';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

export type ControlCheckboxProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> &
  Omit<FormControlLabelProps, 'control'>;

export const ControlCheckbox = <TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  label,
  rules,
  ...rest
}: ControlCheckboxProps<TFieldValues>) => {
  const { $t } = useIntl();

  const error = control?.getFieldState(name).error;

  const hasError = Boolean(error);
  const errorMessage = getControllerErrorText<TFieldValues>(error, rules, $t);

  return (
    <FormControl error={hasError}>
      <FormControlLabel
        control={
          <Controller
            name={name}
            rules={rules}
            control={control}
            render={({ field: props }) => (
              <Checkbox
                {...props}
                disabled={rest.disabled}
                checked={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            )}
          />
        }
        label={label}
        {...rest}
      />
      {errorMessage && (
        <FormHelperText>
          <Typography variant="body2" ml={1.75}>
            {errorMessage}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};
