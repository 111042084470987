import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { DragIcon, Loading, PlusIcon } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import { GridContainer } from '../../../../components/uikit-components/Grid/Grid';
import { useAttendanceCodes } from '../../../../hooks/useAttendanceCodes';
import { AttendanceCodeRow } from './AttendanceCodeRow';

export const SchoolAttendanceCodes: FC = () => {
  const { attendanceCodes, isLoading: isFetching } = useAttendanceCodes();

  const navigate = useNavigate();

  const onAddNewCodeClick = useCallback(() => {
    navigate('/settings/attendance/new');
  }, [navigate]);

  const onEditClick = useCallback(
    (codeId: string) => {
      navigate(`/settings/attendance/${codeId}`);
    },
    [navigate],
  );

  const onChangeOrderClick = useCallback(() => {
    navigate(`/settings/attendance/change-order`);
  }, [navigate]);

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center">
            <FormattedMessage
              id="attendance-Codes"
              values={{
                codePlural: attendanceCodes.length > 1 ? 'codes' : 'code',
              }}
            />
            {!!attendanceCodes.length && <Counter>{attendanceCodes.length}</Counter>}
          </Stack>
        }
        action={
          <Stack direction="row" gap={5}>
            {attendanceCodes.length > 1 && (
              <SimpleButton startIcon={<DragIcon />} onClick={onChangeOrderClick}>
                <FormattedMessage id={'changeOrder'} />
              </SimpleButton>
            )}
            <SimpleButton startIcon={<PlusIcon />} onClick={onAddNewCodeClick}>
              <FormattedMessage id={'attendance-AddCodes'} />
            </SimpleButton>
          </Stack>
        }
      />
      <CardContent sx={{ p: !attendanceCodes.length ? 0 : 'undefined' }}>
        <GridContainer
          sx={{
            borderRadius: 0,
          }}
        >
          {isFetching && <Loading />}

          {attendanceCodes.map((code) => (
            <AttendanceCodeRow editable code={code} onEditClick={() => onEditClick(code.id)} />
          ))}
        </GridContainer>
      </CardContent>
    </Card>
  );
};
