import { MAX_PAGE_SIZE } from '@schooly/api';
import { ApiError, FilterValue, GroupUserType, SelectOption, UserType } from '@schooly/api';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFilters } from '../../../../context/filters/useFilters';
import usePrevious from '../../../../hooks/usePrevious';
import { ShowMoreResourceFunction, useShowMore } from '../../../../hooks/useShowMore';
import searchWords from '../../../../utils/searchWords';
import Button from '../../../ui/Button';
import Tag from '../../../ui/Tag';
import Preloader from '../../../uikit/Preloader/Preloader';
import { SEARCH_DEBOUNCE_WAIT } from '../HeaderSearch/HeaderSearchPeopleList';

import './FilterMainListSelectPopupContent.scss';

export interface FilterMainListSelectPopupContentMainProps {
  searchQuery: string;
  options?: SelectOption<FilterValue>[];
  hasNoneOption?: boolean;
  popupTitleTextId?: string;
  noneOption: SelectOption<FilterValue>;
  showTitle?: boolean;
  isOpen?: boolean;
  type?: UserType;
  isOptionActive?: (option: SelectOption<FilterValue>, userType?: GroupUserType) => boolean;
  handleSelectOption: (option?: SelectOption<FilterValue>, userType?: GroupUserType) => void;
  handleRemoveOption: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    option?: SelectOption<FilterValue>,
    userType?: GroupUserType,
  ) => void;
  resetSearchQuery?: VoidFunction;
  query?: string;
  isHeaderFilterPopupOpen?: boolean;
}

export interface FilterMainListSelectPopupContentProps<T>
  extends FilterMainListSelectPopupContentMainProps {
  renderFilterOption?: (args: {
    option: SelectOption<FilterValue>;
    isActive: boolean;
    onSelectOption: (
      e: React.MouseEvent<HTMLElement, MouseEvent>,
      selectOption?: SelectOption<FilterValue>,
    ) => void;
  }) => React.ReactNode;
  fetchList: ShowMoreResourceFunction<T>;
  fetching?: boolean;
  canShowMore?: boolean;
  current_page?: number;
  total_pages?: number;
  error?: ApiError | null;
}

export const FilterMainListSelectPopupContent: React.FC<
  FilterMainListSelectPopupContentProps<any>
> = ({
  searchQuery,
  options = [],
  hasNoneOption,
  isOptionActive,
  handleSelectOption,
  handleRemoveOption,
  resetSearchQuery,
  noneOption,
  query,
  isHeaderFilterPopupOpen,
  isOpen,

  renderFilterOption,
  fetchList,
  fetching,
  canShowMore,
  current_page,
  total_pages,
  error,
}) => {
  const { formatMessage } = useIntl();
  const { filters } = useFilters();
  const [isNewListFetched, setNewListFetched] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    return () => {
      setTimeout(() => {
        setNewListFetched(false);
      }, 400);
    };
  }, [isOpen]);

  const handleFetchList = useCallback(
    (pageSize?: number, pageNumber?: number, query?: string) => {
      if (fetchList(pageSize, pageNumber, query)) {
        setNewListFetched(true);
      }
    },
    [fetchList],
  );

  const prevDate = usePrevious(filters.draft.date);

  useEffect(() => {
    const draftDate = filters.draft.date.join('');
    const prevDraftDate = prevDate?.join('');

    if (draftDate === prevDraftDate) return;
    if (!isHeaderFilterPopupOpen) return;

    handleFetchList(MAX_PAGE_SIZE, undefined, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetchList, filters.draft.date, prevDate]);

  const requestFetchList = useMemo(
    () =>
      debounce(
        (pageSize?: number, pageNumber?: number, query?: string) =>
          handleFetchList(pageSize, pageNumber, query),
        SEARCH_DEBOUNCE_WAIT,
        {
          leading: false,
          trailing: true,
        },
      ),
    [handleFetchList],
  );

  const { showMoreLoader } = useShowMore({
    getResource: requestFetchList as any,
    fetching,
    canShowMore,
    current_page,
    total_pages,
  });

  useEffect(() => {
    if (!isOpen) return;

    setNewListFetched(false);
    requestFetchList(undefined, undefined, query);
  }, [query, isOpen, requestFetchList]);

  const handleRefresh = useCallback(() => {
    resetSearchQuery?.();
    handleFetchList();
  }, [handleFetchList, resetSearchQuery]);

  const getOptionText = useCallback(
    (option: SelectOption<FilterValue>) =>
      option.labelTextId ? formatMessage({ id: option.labelTextId }) : option.label,
    [formatMessage],
  );

  const onSelectOption = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, selectOption?: SelectOption<FilterValue>) => {
      const isActive = isOptionActive && selectOption && isOptionActive(selectOption);

      if (isActive) {
        handleRemoveOption(e, selectOption);
      } else {
        handleSelectOption(selectOption);
      }
    },
    [handleSelectOption, handleRemoveOption, isOptionActive],
  );

  const displayedOptions = useMemo(() => {
    if (!searchQuery) {
      return options;
    }

    return options.filter((o) => {
      const text = getOptionText(o);
      return text && searchWords(text, searchQuery);
    });
  }, [getOptionText, options, searchQuery]);

  if (isNewListFetched && !displayedOptions.length && !fetching) {
    return (
      <div>
        <span className="form-select-option dropdown-item form-select-no-search-results">
          <FormattedMessage id={searchQuery ? 'emptySearchResults' : 'assessments-EmptyEntries'} />
        </span>
      </div>
    );
  }

  const renderNoneOption = (className?: string, userType?: GroupUserType) => {
    const isActive = isOptionActive && isOptionActive(noneOption, userType);

    return (
      <li key={noneOption.value} className={className}>
        <Tag
          selectOption={noneOption}
          className={isActive ? 'active' : undefined}
          onClick={onSelectOption}
          isBig
        >
          {getOptionText(noneOption)}
        </Tag>
      </li>
    );
  };

  const renderDefaultFilterOption: FilterMainListSelectPopupContentProps<any>['renderFilterOption'] =
    ({ option, isActive, onSelectOption }) => (
      <li key={option.value}>
        <Tag
          selectOption={option}
          className={isActive ? 'active' : undefined}
          onClick={onSelectOption}
          isBig
        >
          {getOptionText(option)}
        </Tag>
      </li>
    );

  const renderFilterOptions = (filterOptions: SelectOption<FilterValue>[]) =>
    filterOptions.map((option) => {
      const isActive = Boolean(isOptionActive && option && isOptionActive(option));
      return renderFilterOption
        ? renderFilterOption({ option, isActive, onSelectOption })
        : renderDefaultFilterOption({ option, isActive, onSelectOption });
    });

  if (error) {
    return (
      <div className="FilterMainListSelectPopupContent__error">
        <p className="FilterMainListSelectPopupContent__error__text">{error.reason}</p>
        <Button onClick={handleRefresh} size="x-small">
          <FormattedMessage id="action-Refresh" />
        </Button>
      </div>
    );
  }

  return (
    <>
      {!searchQuery && hasNoneOption && renderNoneOption()}
      {(fetching && !displayedOptions.length) || !isNewListFetched ? (
        <Preloader />
      ) : (
        renderFilterOptions(displayedOptions)
      )}
      {showMoreLoader}
    </>
  );
};
