import { PreferredNameIcon } from '@schooly/style';
import React from 'react';

import { useProfile } from '../../../context/profile/useProfile';
import ProfileProperty from '../properties/ProfileProperty';
import PreferNameModal from './PreferNameModal';

interface PreferNamePropertyProps {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}
const PreferNameProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
}: PreferNamePropertyProps) => {
  const { user, canEditProfile, hasEditingPermission } = useProfile();

  if (!user) {
    return null;
  }

  return (
    <ProfileProperty
      value={user.known_as}
      canEdit={canEditProfile && hasEditingPermission}
      params={{ size, iconColor, color }}
      editValueTextId="peopleDetail-AddPreferredName"
      valueTextId="peopleDetail-PreferredName"
      fieldName="known_as"
      renderIcon={() => <PreferredNameIcon />}
      modalController={({ isOpen, hide }) => <PreferNameModal isOpen={isOpen} onClose={hide} />}
    />
  );
};

export default PreferNameProperty;
