import React from 'react';

import LandingLayout from '../../components/ui/LandingLayout';

const MobileInboxNotificationsLayout: React.FC = () => (
  <LandingLayout
    titleId="root-TitleParentOnly"
    imageName="parent-only"
    subtitleId="root-SubtitleParentOnly"
    hasDownloadButtons
    hideSidebar
  />
);

export default MobileInboxNotificationsLayout;
