import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3C5.89543 3 5 3.89543 5 5L3 5C1.89543 5 1 5.89543 1 7V15C1 16.1046 1.89543 17 3 17H7H13H17C18.1046 17 19 16.1046 19 15V7C19 5.89543 18.1046 5 17 5H15C15 3.89543 14.1046 3 13 3H7ZM10 7C7.79086 7 6 8.79086 6 11C6 13.2091 7.79086 15 10 15C12.2091 15 14 13.2091 14 11C14 8.79086 12.2091 7 10 7ZM10 8C8.34315 8 7 9.34315 7 11C7 12.6569 8.34315 14 10 14C11.6569 14 13 12.6569 13 11C13 9.34315 11.6569 8 10 8ZM16.5 7C16.7761 7 17 7.22386 17 7.5C17 7.77614 16.7761 8 16.5 8C16.2239 8 16 7.77614 16 7.5C16 7.22386 16.2239 7 16.5 7Z"
      fill="#24275B"
    />
  </svg>
);
