import { Box, Icon, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { BaseUser, SchoolProperty, StudentRegistration } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { SchoolUserRole } from '@schooly/constants';
import { LockIcon, TagSelect } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DisabledRegistrationStatus, RegistrationForm } from './StudentRegistrationForm';
import { CanceledEnrollmentParams } from './useReEnrollmentActions';

type UseReEnrollmentStatusesProps = {
  enrollmentFormStatuses: RegistrationForm['statuses'];
  canceledEnrollmentParams?: CanceledEnrollmentParams;
  statuses: SchoolProperty[];
  enrollment?: StudentRegistration;
};

export const useReEnrollmentStatuses = ({
  enrollmentFormStatuses,
  canceledEnrollmentParams,
  statuses,
  enrollment,
}: UseReEnrollmentStatusesProps): DisabledRegistrationStatus[] => {
  const { $t } = useIntl();
  return useMemo(() => {
    if (!enrollment) return [];

    const tag = (
      <ReEnrollmentTag>{$t({ id: 'students-ReEnrollment-NotReEnrolled' })}</ReEnrollmentTag>
    );

    if (canceledEnrollmentParams) {
      const { id, statusId, user } = canceledEnrollmentParams;
      const statusName = statuses.find((s) => s.id === statusId)?.name?.toLowerCase() ?? '';

      const lockIconLabel = $t({ id: 'students-ReEnrollment-CannotEditResponse' });

      return [
        {
          formId: id,
          disabledFields: ['school_property_id'],
          tag,
          endAdornment: (
            <StatusEndAdornment
              title={$t(
                { id: 'students-ReEnrollment-MarkedAs' },
                {
                  statusName,
                  userName: getUserFullName(user),
                },
              )}
              user={user}
            />
          ),
          required: true,
          lockIcon: (
            <Tooltip title={lockIconLabel}>
              <IconButton inverse>
                <LockIcon />
              </IconButton>
            </Tooltip>
          ),
        },
      ];
    }

    if (enrollment.type !== 're_enrollment' || !enrollment.enrollment_status) return [];

    const { type, created_by } = enrollment.enrollment_status;

    return enrollmentFormStatuses.reduce<DisabledRegistrationStatus[]>(
      (acc, { school_property_id, formId }) => {
        if (!formId) return acc;
        const isReadOnly = enrollment.statuses.some((s) => s.id === formId && !!s.read_only);

        if (!isReadOnly) return acc;

        const isCurrentOrFinalStatus =
          type === 'accepted'
            ? statuses.find((s) => s.id === school_property_id)?.category?.current
            : statuses.find((s) => s.id === school_property_id)?.category?.final;

        const createdByParent = created_by.role === SchoolUserRole.Parent;

        const showEndAdornment = isCurrentOrFinalStatus && !createdByParent;

        const lockIconLabel =
          type === 'rejected' && createdByParent
            ? $t({ id: 'students-ReEnrollment-CannotEditParentResponse' })
            : $t({ id: 'students-ReEnrollment-CannotEditCreatedResponse' });

        const allStatusFields: DisabledRegistrationStatus['disabledFields'] = [
          'school_property_id',
          'applies_from',
        ];

        const disabledFieldsAfterReject: DisabledRegistrationStatus['disabledFields'] =
          createdByParent ? allStatusFields : ['school_property_id'];

        return [
          ...acc,
          {
            formId,
            disabledFields: type === 'rejected' ? disabledFieldsAfterReject : allStatusFields,
            endAdornment: showEndAdornment ? (
              <StatusEndAdornment
                title={$t(
                  {
                    id: 'students-ReEnrollment-ConvertedBy',
                  },
                  { userName: getUserFullName(created_by) },
                )}
                user={created_by}
              />
            ) : (
              <Box position="absolute" right={2} top={12}>
                <Icon inverse>
                  <LockIcon />
                </Icon>
              </Box>
            ),
            lockIcon: (
              <Tooltip title={lockIconLabel}>
                <IconButton inverse>
                  <LockIcon />
                </IconButton>
              </Tooltip>
            ),
            tag: type === 'rejected' ? tag : undefined,
          },
        ];
      },
      [],
    );
  }, [$t, canceledEnrollmentParams, enrollment, enrollmentFormStatuses, statuses]);
};

type StatusEndAdornmentProps = {
  title: string;
  user: BaseUser;
};

const StatusEndAdornment: FC<StatusEndAdornmentProps> = ({ title, user }) => (
  <Tooltip
    title={
      <Stack gap={1}>
        <Typography>{title}</Typography>
      </Stack>
    }
    componentsProps={{
      tooltip: {
        sx: (theme) => ({
          padding: theme.spacing(1.25),
        }),
      },
    }}
  >
    <Box position="absolute" right={7} top={7.25}>
      <AvatarAuth user={user} />
    </Box>
  </Tooltip>
);

export const ReEnrollmentTag: FC<PropsWithChildren> = ({ children }) => (
  <TagSelect
    sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
    label={
      <Typography variant="body1" color={(theme) => `${theme.palette.common.grey2} !important`}>
        {children}
      </Typography>
    }
  />
);
