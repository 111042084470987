import { SchoolUserRole } from '@schooly/constants';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { useState } from 'react';

import {
  CreateMessageResponse,
  GetMessagesAggregatedDataRequest,
  MessagesAggregatedData,
} from './apiTypes/endpoints/messages';
import {
  IListMessagesForSchool,
  InboxMessage,
  IncomingAttachmentFile,
  Individual,
  ListMessagesForGroup,
  ListMessagesForRelation,
  Message,
  MessageAction,
  MessageCriteria,
  MessageForGroup,
  MessageStatus,
  MessageUpdate,
  Recipient,
} from './apiTypes/messages';
import { ApiError, PagedResponse } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { FilterKeys } from './apiTypes/users';
import * as api from './requests';
import { getFormattedParams } from './utils/getFormattedParams';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';
import { removeObjectFalsyValues } from './utils/removeObjectFalsyValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

const DEFAULT_PAGE_SIZE = 50;

const MESSAGES_URL = '/messages/';

const MESSAGES_AGGREGATED_DATA_URL = '/messages/aggregate';

export function getMessages({
  filters,
  school_id,
  page_size = DEFAULT_PAGE_SIZE,
  page_number,
  query,
}: IListMessagesForSchool): Promise<PagedResponse<Message>> {
  const dateFrom = filters?.date?.[0];
  const dateTo = filters?.date?.[1] || filters?.date?.[0];

  const params = removeObjectFalsyValues({
    page_number,
    page_size,
    date_from: dateFrom,
    date_to: dateTo,
    search_query: query,
    creator: filters?.creator?.join(','),
  });

  const formattedParams = getFormattedParams(params, filters, [FilterKeys.Date]);

  return api.get(`${MESSAGES_URL}for-school/${school_id}`, { params: formattedParams });
}

export const GET_MESSAGES_QUERY = `${MESSAGES_URL}GET_MESSAGES_QUERY`;

export const MESSAGES_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.RecipientType,
  FilterKeys.MessagesStatus,
  FilterKeys.Creator,
  FilterKeys.MessagesMonth,
  FilterKeys.MessagesReadPercent,
] as const;

export type GetMessagesQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.RecipientType]?: SchoolUserRole[];
  [FilterKeys.MessagesStatus]?: MessageStatus[];
  [FilterKeys.Creator]?: string[];
  [FilterKeys.MessagesMonth]?: string[];
  [FilterKeys.MessagesReadPercent]?: string[];
};

export type MessageArrangeBy =
  | FilterKeys.MessagesMonth
  | FilterKeys.Creator
  | FilterKeys.MessagesReadPercent;

export const MESSAGE_ARRANGE_BY_FILTER_KEYS = [
  FilterKeys.MessagesMonth,
  FilterKeys.Creator,
  FilterKeys.MessagesReadPercent,
] as const;

export const useGetMessagesQuery = (
  initialParams: Omit<IListMessagesForSchool, 'filters'> & {
    filters: GetMessagesQueryFilters;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<Message>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<Message>, ApiError>(
    [GET_MESSAGES_QUERY, { ...params, filters: removeObjectEmptyArrayValues(params.filters) }],
    ({ pageParam }) =>
      getMessages({
        page_number: pageParam,
        ...params,
        filters: removeObjectEmptyArrayValues(params.filters) as IListMessagesForSchool['filters'],
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export function getMessagesByUserType(userType: SchoolUserRole): Promise<InboxMessage[]> {
  return api.get(`${MESSAGES_URL}user?user_type=${userType}`);
}

type GetMessageResponse = {
  message: Message;
  message_criteria: MessageCriteria[];
  message_individuals: Individual[];
  message_recipients: Recipient[];
};

export async function getMessage(
  messageId: string,
  config?: AxiosRequestConfig,
): Promise<GetMessageResponse> {
  return api.get(`${MESSAGES_URL}${messageId}`, config);
}

export const CREATE_MESSAGE_MUTATION = `${MESSAGES_URL}CREATE_MESSAGE_MUTATION`;

export function createMessage(data: MessageUpdate): Promise<CreateMessageResponse> {
  return api.post(`${MESSAGES_URL}create`, data);
}

export const useCreateMessageMutation = (
  options?: RQUseMutationOptions<CreateMessageResponse, MessageUpdate>,
): RQUseMutationResult<CreateMessageResponse, MessageUpdate> => {
  return useMutation([CREATE_MESSAGE_MUTATION], (params: MessageUpdate) => createMessage(params), {
    ...options,
  });
};

export function messageAction(
  messageId: string,
  action = MessageAction.Publish,
): Promise<{ success: string }> {
  return api.post(`${MESSAGES_URL}${messageId}/action`, { action });
}

type MessageUpdateRequest = {
  messageId: string;
  data: Omit<MessageUpdate, 'school_id'>;
};
type MessageUpdateResponse = { success: string };

export const UPDATE_MESSAGE_MUTATION = `${MESSAGES_URL}UPDATE_MESSAGE_MUTATION`;

export function updateMessage({
  messageId,
  data,
}: MessageUpdateRequest): Promise<MessageUpdateResponse> {
  return api.patch(`${MESSAGES_URL}${messageId}`, data);
}

export const useUpdateMessageMutation = (
  options?: RQUseMutationOptions<MessageUpdateResponse, MessageUpdateRequest>,
): RQUseMutationResult<MessageUpdateResponse, MessageUpdateRequest> => {
  return useMutation(
    [UPDATE_MESSAGE_MUTATION],
    (params: MessageUpdateRequest) => updateMessage(params),
    {
      ...options,
    },
  );
};

const REMOVE_MESSAGE_MUTATION = `${MESSAGES_URL}REMOVE_MESSAGE_MUTATION`;

type MessageRemoveResponse = {
  success: string;
};

export function deleteMessage(messageId?: string): Promise<MessageRemoveResponse> {
  return api.remove(`${MESSAGES_URL}${messageId}/`, {});
}

export const useRemoveMessage = (
  options?: RQUseMutationOptions<MessageRemoveResponse, string>,
): RQUseMutationResult<MessageRemoveResponse, string> => {
  return useMutation([REMOVE_MESSAGE_MUTATION], (params: string) => deleteMessage(params), {
    ...options,
  });
};

export function markAsRead(messageId?: string, recipientId?: string): Promise<{ success: string }> {
  const params = {
    recipient_id: recipientId,
    recipient_read: true,
  };

  return api.patch(`${MESSAGES_URL}${messageId}/read`, params);
}

export function getAttachment(
  attachmentId: string,
  messageId: string,
): Promise<IncomingAttachmentFile> {
  const params = {
    message_id: messageId,
  };

  return api.get(`${MESSAGES_URL}file/${attachmentId}`, { params });
}

export function getMessagesForGroup({
  groupId,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  searchQuery: search_query,
}: ListMessagesForGroup): Promise<PagedResponse<MessageForGroup>> {
  return api.get(`${MESSAGES_URL}for-group/${groupId}`, {
    params: { page_size: pageSize, page_number: pageNumber, search_query },
  });
}

export const GET_MESSAGES_FOR_GROUP_QUERY = `${MESSAGES_URL}/GET_MESSAGES_FOR_GROUP_QUERY`;

export const useGetMessagesForGroupQuery = (
  params: ListMessagesForGroup,
  options?: RQUseInfiniteQueryOptions<PagedResponse<MessageForGroup>>,
) => {
  return useInfiniteQuery<PagedResponse<MessageForGroup>, ApiError>(
    [GET_MESSAGES_FOR_GROUP_QUERY, params.groupId, params],
    () => getMessagesForGroup(params),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );
};

export function getMessagesForRelation({
  relationId,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  token,
}: ListMessagesForRelation): Promise<PagedResponse<MessageForGroup>> {
  return api.get(`${MESSAGES_URL}for-relation/${relationId}`, {
    params: { page_size: pageSize, page_number: pageNumber },
    cancelToken: token,
  });
}

export const GET_MESSAGES_FOR_RELATION_QUERY = `${MESSAGES_URL}/GET_MESSAGES_FOR_RELATION_QUERY`;

export const useGetMessagesForRelationQuery = (
  params: ListMessagesForRelation,
  options?: RQUseInfiniteQueryOptions<PagedResponse<MessageForGroup>>,
) => {
  return useInfiniteQuery<PagedResponse<MessageForGroup>, ApiError>(
    [GET_MESSAGES_FOR_RELATION_QUERY, params.relationId, params],
    () => getMessagesForRelation(params),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );
};

export function getMessagesAggregatedData({
  schoolId,
  filters,
  arrangeBy,
  searchQuery,
}: GetMessagesAggregatedDataRequest): Promise<MessagesAggregatedData> {
  const params = removeObjectUndefinedNullValues({
    date_from: filters?.date?.[0],
    date_to: filters?.date?.[1] || filters?.date?.[0],
    creator: filters?.creator?.join(','),
    arrange_by: arrangeBy,
    recipient_type: filters.recipient_type?.join(','),
    message_status: filters.message_status?.join(','),
    search_query: searchQuery,
  });
  return api.get(`${MESSAGES_URL}for-school/${schoolId}/aggregate`, { params });
}

export const GET_MESSAGES_AGGREGATED_DATA_QUERY = `${MESSAGES_AGGREGATED_DATA_URL}/GET_MESSAGES_AGGREGATED_DATA_QUERY`;

export const useGetMessagesAggregatedData = (
  params: GetMessagesAggregatedDataRequest,
  options?: RQUseQueryOptions<MessagesAggregatedData>,
) => {
  return useQuery<MessagesAggregatedData, ApiError>(
    [GET_MESSAGES_AGGREGATED_DATA_QUERY, params],
    () => getMessagesAggregatedData(params),
    options,
  );
};
