import { Box, Stack, Typography } from '@mui/material';
import { FilterButtonDropdown, renderSubjectTags } from '@schooly/components/filters';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import searchWords from '../../../utils/searchWords';

type ProfileModalAssessmentsSubjectFilterProps = {
  schoolId: string;
  subjectIds: string[];
  onSetSubjectIds: (v: string[]) => void;
};

export const ProfileModalAssessmentsSubjectFilter: FC<ProfileModalAssessmentsSubjectFilterProps> =
  ({ schoolId, subjectIds: initialSubjectIds, onSetSubjectIds }) => {
    const [query, setQuery] = useState('');
    const [subjectIds, setSubjectIds] = useState(initialSubjectIds);
    const { $t } = useIntl();

    const handleToggleSubjectIds = useCallback((id: string) => {
      setSubjectIds((ids) => {
        const updatedSubjectIds = ids.includes(id) ? ids.filter((i) => i !== id) : [...ids, id];
        return updatedSubjectIds;
      });
    }, []);

    const { activeSubjects, archivedSubjects, isLoading } = useSubjects(
      { schoolId },
      { refetchOnMount: 'always' },
    );

    const { active, archived } = useMemo(() => {
      if (!query) return { active: activeSubjects, archived: archivedSubjects };

      return {
        active: activeSubjects.filter(({ name }) => searchWords(name, query)),
        archived: archivedSubjects.filter(({ name }) => searchWords(name, query)),
      };
    }, [activeSubjects, archivedSubjects, query]);

    const isEmpty = !active.length && !archived.length;

    return (
      <FilterButtonDropdown
        hasSelectedValue={!!subjectIds.length}
        query={query}
        isFetchingNextPage={isLoading}
        onChangeQuery={setQuery}
        onClose={() => onSetSubjectIds(subjectIds)}
        renderContent={() => (
          <Stack gap={2} px={1} py={2}>
            {isEmpty && <Typography p={1}>{$t({ id: 'input-NoOptionsFound' })}</Typography>}
            {!!active.length && (
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    marginBottom: '4px !important',
                  }}
                >
                  {$t({ id: 'section-Subjects' })}
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {renderSubjectTags({
                    ids: active.map((s) => s.id),
                    tagProps: (id) => ({
                      schoolId,
                      variant: subjectIds?.includes(id) ? 'filled' : undefined,
                    }),
                    onClick: handleToggleSubjectIds,
                  })}
                </Stack>
              </Box>
            )}

            {archived.length > 0 && (
              <Box>
                <Typography variant="h4">{$t({ id: 'filter-Archived' })}</Typography>
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {renderSubjectTags({
                    ids: archived.map((s) => s.id),
                    tagProps: (id) => ({
                      schoolId,
                      variant: subjectIds?.includes(id) ? 'filled' : undefined,
                    }),
                    onClick: handleToggleSubjectIds,
                  })}
                </Stack>
              </Box>
            )}
          </Stack>
        )}
      >
        {renderSubjectTags({
          ids: subjectIds,
          tagProps: { schoolId, size: 'small', sx: { maxWidth: 200 } },
          onDelete: handleToggleSubjectIds,
        })}
      </FilterButtonDropdown>
    );
  };
