import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalLarge,
  ModalSmall,
  Spin,
} from '@schooly/style';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { DraftModalState, useDraftReport } from '../../../context/report/WithReportEditContext';
import { AssessmentsStateContent } from './ReportCreateModalAssessments';
import { FormStateContent } from './ReportCreateModalForm';
import { ReportCreateModalHeader } from './ReportCreateModalHeader';
import { MatrixStateContent } from './ReportCreateModalMatrix';

export const ReportCreateModalDraftContent: FC = () => {
  const { $t } = useIntl();

  const {
    id,
    schoolId,
    modalState: state,
    isDeleting,
    isSaving,
    isValidating,
    isFetching,
    autogeneratedAssessments,
    isAdmin,
    subjects,
    subjectsConfigured,
    activeSubjects,
    ageGroups,
    activeAgeGroups,
    ageGroupsConfigured,
    isPropertiesLoading,
    isSubjectsLoading,
    handleDelete,
    handleBack,
    handleClose,
    handleCreate,
    handleOpenAssessments,
    handleSave,
    handleSubmitNext,
    form,
  } = useDraftReport();
  const additionalAssessments = form.watch('assessments');

  const renderFooter = () => {
    switch (state) {
      case DraftModalState.DraftForm:
        return (
          <ModalFooter
            withBorderTop
            active
            sx={{
              justifyContent: 'space-between',
            }}
          >
            {id && (
              <Button
                variant="outlined"
                startIcon={isDeleting ? <Spin /> : <DeleteIcon />}
                disabled={isDeleting}
                onClick={handleDelete}
                data-test-id="modal-delete"
              >
                {$t({ id: 'action-Delete' })}
              </Button>
            )}
            <Typography variant="body1">
              {$t({ id: 'reports-AdditionalAssessmentsNextStep' })}
            </Typography>
            <Stack flexDirection="row" gap={1.25}>
              <Button
                variant="outlined"
                startIcon={isSaving ? <Spin /> : undefined}
                disabled={isSaving}
                onClick={handleSave}
                data-test-id="modal-submit"
              >
                {$t({ id: 'action-Save' })}
              </Button>
              <Button
                type="submit"
                disabled={isValidating}
                endIcon={isValidating ? <Spin /> : <ArrowRightIcon />}
                data-test-id="modal-next-button"
              >
                {$t({ id: 'action-Next' })}
              </Button>
            </Stack>
          </ModalFooter>
        );

      case DraftModalState.DraftMatrix:
        return (
          <ModalFooter
            withBorderTop
            active
            sx={{
              position: 'relative',
              justifyContent: 'space-between',
            }}
          >
            <Button onClick={handleBack} variant="outlined" startIcon={<ArrowLeftIcon />}>
              {$t({ id: 'action-Back' })}
            </Button>

            <Button onClick={handleOpenAssessments} endIcon={<ArrowRightIcon />}>
              {$t({ id: 'action-Next' })}
            </Button>
          </ModalFooter>
        );

      case DraftModalState.DraftAssessments:
        return (
          <ModalFooter
            withBorderTop
            sx={{
              position: 'relative',
              justifyContent: 'space-between',
            }}
          >
            <Button onClick={handleBack} variant="outlined" startIcon={<ArrowLeftIcon />}>
              {$t({ id: 'action-Back' })}
            </Button>

            {!!autogeneratedAssessments.length || !!additionalAssessments.length ? (
              <Button
                onClick={handleCreate}
                disabled={isFetching}
                startIcon={isFetching ? <Spin /> : <CheckIcon />}
                data-test-id="modal-submit"
              >
                {$t({ id: 'action-Create' })}
              </Button>
            ) : (
              <Button
                variant="outlined"
                startIcon={isFetching ? <Spin /> : undefined}
                disabled={isFetching}
                onClick={handleSave}
                data-test-id="modal-submit"
              >
                {$t({ id: 'action-Save' })}
              </Button>
            )}
          </ModalFooter>
        );

      default:
        return null;
    }
  };

  const renderContent = () => {
    if (isPropertiesLoading || isSubjectsLoading) {
      return (
        <ModalSmall open onClose={handleClose}>
          <ModalHeader active>
            <IconButton onClick={handleClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalContent active>
            <Loading />
          </ModalContent>
        </ModalSmall>
      );
    }

    switch (state) {
      case DraftModalState.DraftForm:
        return (
          <ModalSmall
            open
            onClose={handleClose}
            sx={isFetching ? { pointerEvents: 'none' } : undefined}
          >
            <FormStateContent
              isAdmin={isAdmin}
              schoolId={schoolId}
              hasActiveSubjects={!!activeSubjects.length}
              hasActiveAgeGroups={!!activeAgeGroups.length}
              ageGroupsConfigured={ageGroupsConfigured}
              subjectsConfigured={subjectsConfigured}
              form={form}
              onClose={handleClose}
              onNext={handleSubmitNext}
              renderFooter={renderFooter}
            />
          </ModalSmall>
        );
      case DraftModalState.DraftMatrix:
        return (
          <ModalLarge open onClose={handleClose}>
            <ReportCreateModalHeader onClose={handleClose} form={form} withBorderBottom active />
            <MatrixStateContent
              assessments={autogeneratedAssessments}
              subjects={subjects}
              ageGroups={ageGroups}
              form={form}
            />
            {renderFooter()}
          </ModalLarge>
        );
      case DraftModalState.DraftAssessments:
        return (
          <ModalSmall
            open
            onClose={handleClose}
            sx={isFetching ? { pointerEvents: 'none' } : undefined}
          >
            <ReportCreateModalHeader onClose={handleClose} form={form} withBorderBottom />
            <AssessmentsStateContent
              subjects={subjects}
              ageGroups={ageGroups}
              autogeneratedAssessments={autogeneratedAssessments}
              form={form}
            />
            {renderFooter()}
          </ModalSmall>
        );
    }
  };

  return <FormProvider {...form}>{renderContent()}</FormProvider>;
};
