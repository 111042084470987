import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Assessment, getAssessments, PagedResponse } from '@schooly/api';

import { IColumnSort } from '../../../components/ui/ListView';
import { FiltersFiltersState } from '../../../context/filters/FiltersContext';
import { IApiSlice } from '../../scheme';

export interface AssessmentsListState extends IApiSlice<PagedResponse<Assessment>> {}

export const initialState: AssessmentsListState = {
  data: undefined,
  fetching: false,
  error: undefined,
};

export const extraActions = {
  get: createAsyncThunk(
    'assessments/list/get',
    (payload: {
      filters?: FiltersFiltersState['applied'];
      schoolId: string;
      sort?: IColumnSort<keyof Assessment>[];
      query?: string;
    }) =>
      getAssessments({
        filters: payload.filters,
        school_id: payload.schoolId,
        sort: payload.sort,
        query: payload.query,
      }),
  ),
};

const slice = createSlice({
  name: 'assessments/preview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
        state.error = undefined;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        state.data = action.payload;
        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
