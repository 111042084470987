import {
  ANNUAL_PLAN_FILTER_KEYS,
  AnnualPlan,
  AnnualPlanRecordTypes,
  FilterKeys,
  GetAnnualPlanRequestFilters,
  removeObjectEmptyArrays,
  SchoolYear,
  useGetAnnualPlanQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  useFiltersStateFromSearchParams,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useSchoolYears from '../../hooks/useSchoolYears';

export interface AnnualPlannerContextProps {
  hasSchoolAdminPermissions: boolean;
  hasEventsPermissions: boolean;
  hasAssessmentsPermissions: boolean;
  schoolYear?: SchoolYear;
  start?: Date;
  end?: Date;
  filters: GetAnnualPlanRequestFilters;
  handleSetFilters: (filters: GetAnnualPlanRequestFilters) => void;
  recordTypesMap: Partial<Record<AnnualPlanRecordTypes, boolean>>;
  toggleRecordType: (type: AnnualPlanRecordTypes) => () => void;
  data?: AnnualPlan;
  isLoading: boolean;
  isFetching: boolean;
}

export const AnnualPlannerContext = createContext<AnnualPlannerContextProps>({
  hasSchoolAdminPermissions: false,
  hasEventsPermissions: false,
  hasAssessmentsPermissions: false,
  schoolYear: undefined,
  start: undefined,
  end: undefined,
  filters: { school_year: [] },
  handleSetFilters: () => {},
  recordTypesMap: {},
  toggleRecordType: () => () => {},
  data: undefined,
  isLoading: false,
  isFetching: false,
});

export const WithAnnualPlanner: FC<PropsWithChildren> = ({ children }) => {
  const { defaultValidity, getSchoolYearById } = useSchoolYears();

  const { schoolId = '', permissions } = useAuth();

  const hasSchoolAdminPermissions = permissions.includes('school_admin');
  const hasEventsPermissions = permissions.includes('event_viewer');
  const hasAssessmentsPermissions = permissions.includes('assessment_viewer');

  // define filters by default
  const defaultFilters = useMemo<GetAnnualPlanRequestFilters>(
    () => ({
      [FilterKeys.SchoolYear]: [defaultValidity?.id ?? ''],
      [FilterKeys.RecordType]: [
        AnnualPlanRecordTypes.SCHOOL_PERIOD,
        ...(hasEventsPermissions
          ? [AnnualPlanRecordTypes.EVENT, AnnualPlanRecordTypes.HOLIDAY]
          : []),
        ...(hasAssessmentsPermissions
          ? [AnnualPlanRecordTypes.ASSESSMENT, AnnualPlanRecordTypes.REPORT]
          : []),
      ],
    }),
    [defaultValidity?.id, hasAssessmentsPermissions, hasEventsPermissions],
  );

  // get filters from the URL
  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: ANNUAL_PLAN_FILTER_KEYS,
    defaultFilters,
  }) as GetAnnualPlanRequestFilters;

  // additionally check permissions, as the users could manually set irrelevant record types
  // or the ones they have no access for
  const initialFilters = useMemo(
    () =>
      initialFiltersState[FilterKeys.RecordType]
        ? {
            ...initialFiltersState,
            [FilterKeys.RecordType]: initialFiltersState[FilterKeys.RecordType]?.filter(
              (recordType) => {
                switch (recordType) {
                  case AnnualPlanRecordTypes.EVENT:
                  case AnnualPlanRecordTypes.HOLIDAY:
                    return hasEventsPermissions;
                  case AnnualPlanRecordTypes.ASSESSMENT:
                  case AnnualPlanRecordTypes.REPORT:
                    return hasAssessmentsPermissions;
                  case AnnualPlanRecordTypes.SCHOOL_PERIOD:
                    return true;
                  default:
                    return false;
                }
              },
            ),
          }
        : initialFiltersState,
    [hasAssessmentsPermissions, hasEventsPermissions, initialFiltersState],
  );

  const [filters, setFilters] = useState<AnnualPlannerContextProps['filters']>(initialFilters);

  const {
    data: requestData,
    isFetching,
    isLoading,
  } = useGetAnnualPlanQuery(schoolId, filters[FilterKeys.SchoolYear][0], {
    refetchOnMount: 'always',
  });

  /**
   * Filters records by type.
   * Can not rely on useGetAnnualPlanQuery.select option as it's being called on each render and the
   * calculations are pretty heavy
   */
  const data = useMemo(() => {
    return requestData
      ? {
          ...requestData,
          records: requestData.records.filter((record) =>
            (filters[FilterKeys.RecordType] ?? []).includes(record.type),
          ),
        }
      : undefined;
  }, [filters, requestData]);

  const schoolYear = getSchoolYearById(filters[FilterKeys.SchoolYear][0]);

  const start = schoolYear?.start ? newDateTimezoneOffset(schoolYear.start) : undefined;
  const end = schoolYear?.end ? newDateTimezoneOffset(schoolYear.end) : undefined;

  useSyncFiltersStateWithSearchParams({
    pathname: '/planner',
    filters,
  });

  const handleSetFilters = useCallback<AnnualPlannerContextProps['handleSetFilters']>((filters) => {
    setFilters(() => removeObjectEmptyArrays(filters) as GetAnnualPlanRequestFilters);
  }, []);

  useEffect(() => {
    if (!schoolYear && defaultValidity) {
      handleSetFilters({ ...filters, [FilterKeys.SchoolYear]: [defaultValidity.id] });
    }
  }, [defaultValidity, filters, handleSetFilters, schoolYear]);

  /* Record types state */
  const recordTypesMap = useMemo(
    () =>
      filters[FilterKeys.RecordType]?.reduce<AnnualPlannerContextProps['recordTypesMap']>(
        (prev, type) => ({ ...prev, [type]: true }),
        {},
      ) ?? {},
    [filters],
  );

  const toggleRecordType = useCallback<AnnualPlannerContextProps['toggleRecordType']>(
    (type) => () => {
      handleSetFilters({
        ...filters,
        [FilterKeys.RecordType]:
          (recordTypesMap[type]
            ? filters[FilterKeys.RecordType]?.filter((item) => item !== type)
            : [...(filters[FilterKeys.RecordType] ?? []), type]) ?? [],
      });
    },
    [handleSetFilters, filters, recordTypesMap],
  );

  const value = useMemo(
    () => ({
      hasSchoolAdminPermissions,
      hasEventsPermissions,
      hasAssessmentsPermissions,
      schoolYear,
      start,
      end,
      filters,
      handleSetFilters,
      recordTypesMap,
      toggleRecordType,
      data,
      isLoading,
      isFetching,
    }),
    [
      data,
      end,
      filters,
      handleSetFilters,
      hasAssessmentsPermissions,
      hasEventsPermissions,
      hasSchoolAdminPermissions,
      isFetching,
      isLoading,
      recordTypesMap,
      schoolYear,
      start,
      toggleRecordType,
    ],
  );

  return <AnnualPlannerContext.Provider value={value}>{children}</AnnualPlannerContext.Provider>;
};

export const useAnnualPlanner = () => {
  return useContext(AnnualPlannerContext);
};
