import { Button, IconButton } from '@mui/material';
import { ApiError, SYNC_USER_QUERY, verifyEmailForUsername } from '@schooly/api';
import { createUnverifiedEmailForUsername, updateSchoolMembership } from '@schooly/api';
import { VerifyEmailModalContent } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { VALID_EMAIL_REGEXP } from '@schooly/constants';
import { CheckIcon, CloseIcon, ModalContent, ModalFooter, ModalHeader, Spin } from '@schooly/style';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { useProfile } from '../../../context/profile/useProfile';
import { isSchoolUserType } from '../../../helpers/misc';
import { getUserFullName } from '../../../helpers/users';
import { queryClient } from '../../../queryClient';
import IntlError from '../../../utils/intlError';
import { PropertyModalContainer } from './PropertyModal';

interface EmailModalProps {
  onClose: () => void;
  isOpen: boolean;
  currentUserEmail: string;
}

export type EmailForm = {
  email: string;
};

const defaultValues: EmailForm = {
  email: '',
};

enum State {
  SetEmail,
  Verify,
}

const EmailModal = ({ isOpen, currentUserEmail, onClose: onCloseModal }: EmailModalProps) => {
  const { $t } = useIntl();
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<null | ApiError>(null);
  const [state, setState] = useState<State>(State.SetEmail);
  const { user, userType = 'profile', schoolMembership, actions } = useProfile();
  const invalidateQueries = useInvalidateListQueriesFor(userType);

  const form = useForm<EmailForm>({
    defaultValues,
  });

  const { watch, reset } = form;
  const email = watch('email');

  const onClose = useCallback(() => {
    onCloseModal();
    setState(State.SetEmail);
    reset();
  }, [onCloseModal, reset]);

  const handleSubmit = useCallback<SubmitHandler<EmailForm>>(
    async ({ email }) => {
      setIsUpdating(true);

      if (userType && isSchoolUserType(userType) && schoolMembership) {
        try {
          await updateSchoolMembership({
            relationId: schoolMembership.relation_id,
            userType,
            update: {
              email: !!email ? email : null,
            },
          });

          invalidateQueries();
          actions.invalidateProfileCache();
          showNotification({
            textId: 'confirmation-Email',
            type: 'success',
          });

          onClose();
          return;
        } catch (err) {
          showError(err as ApiError | IntlError);
          return;
        } finally {
          setIsUpdating(false);
        }
      }

      if (userType === 'profile') {
        try {
          setIsUpdating(true);

          await createUnverifiedEmailForUsername(email);

          setState(State.Verify);
        } catch (err) {
          setIsUpdating(false);
          showError(err as ApiError | IntlError);
          return;
        } finally {
          setIsUpdating(false);
        }
        return;
      }

      try {
        setIsUpdating(true);
        const res = await actions.updateProfile({ email });
        if (res) {
          onClose();
          actions.invalidateProfileCache();
          return;
        }
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
      }
    },
    [actions, invalidateQueries, onClose, schoolMembership, showError, showNotification, userType],
  );

  useEffect(() => {
    reset({ email: currentUserEmail });
  }, [currentUserEmail, reset]);

  const onSubmitCode = useCallback(
    async (code: string) => {
      if (!email) {
        return;
      }

      setError(null);
      setIsUpdating(true);

      try {
        const isEmailVerified = await verifyEmailForUsername(email, code);
        if (!isEmailVerified) return;

        showNotification({
          textId: 'confirmation-Email',
          type: 'success',
        });
        actions.invalidateProfileCache();
        queryClient.invalidateQueries([SYNC_USER_QUERY]);
        onClose();
      } catch (err) {
        setError(err as ApiError);
      } finally {
        setIsUpdating(false);
      }
    },
    [email, actions, onClose, showNotification],
  );

  const onResetCodeError = useCallback(() => {
    setError(null);
  }, []);

  const onResend = useCallback(async () => {
    try {
      await createUnverifiedEmailForUsername(email);
    } catch (error) {
      showNotification({
        message: (error as ApiError).reason,
        type: 'error',
      });
    }
  }, [email, showNotification]);

  const isChanged = currentUserEmail !== email;

  if (!isOpen) return null;

  const renderContent = () => {
    switch (state) {
      case State.SetEmail:
        return (
          <>
            <ModalHeader withBorderBottom={false} title={user && getUserFullName(user)} active>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </ModalHeader>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <ModalContent active>
                  <ControlTextField
                    type="email"
                    name="email"
                    control={form.control}
                    rules={{
                      required: true,
                      pattern: {
                        value: VALID_EMAIL_REGEXP,
                        message: $t({
                          id: 'input-ErrorInvalidEmail',
                        }),
                      },
                    }}
                    label={$t({ id: 'peopleDetail-EmailAddressPersonal' })}
                    fullWidth
                  />
                </ModalContent>
                <ModalFooter active withBorderTop={false}>
                  <Button variant="outlined" onClick={onClose} disabled={isUpdating}>
                    <FormattedMessage id="action-Cancel" />
                  </Button>

                  <Button
                    endIcon={isUpdating ? <Spin /> : <CheckIcon />}
                    type="submit"
                    disabled={!isChanged || isUpdating}
                  >
                    <FormattedMessage id="action-Save" />
                  </Button>
                </ModalFooter>
              </form>
            </FormProvider>
          </>
        );
      case State.Verify:
        return (
          <VerifyEmailModalContent
            error={error?.reason || error?.message}
            isLoading={isUpdating}
            onResend={onResend}
            onSubmit={onSubmitCode}
            onResetError={onResetCodeError}
            email={email}
            submitButtonIcon={<CheckIcon />}
            submitButtonTextId="action-Save"
            onBack={() => setState(State.SetEmail)}
          />
        );

      default:
        return null;
    }
  };

  return (
    <PropertyModalContainer
      onClose={!isUpdating ? onClose : undefined}
      open
      className="PropertyModal"
    >
      {renderContent()}
    </PropertyModalContainer>
  );
};

export default EmailModal;
