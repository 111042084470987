import { Message } from '@schooly/api';
import React from 'react';

import { GridContainer } from '../Grid/Grid';
import {
  ListViewMessagesRow,
  ListViewMessagesRowVariant,
} from '../ListViewMessagesRow/ListViewMessagesRow';

import './ListViewMessages.scss';

export interface ListViewMessagesProps {
  messages: Message[];
  variant?: ListViewMessagesRowVariant;
  groupId?: string;
}

export const ListViewMessages: React.FC<ListViewMessagesProps> = ({
  messages,
  variant,
  groupId,
}) => {
  return (
    <GridContainer>
      {messages.map((message) => (
        <ListViewMessagesRow
          key={message.id}
          message={message}
          variant={variant}
          groupId={groupId}
        />
      ))}
    </GridContainer>
  );
};
