import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ProfileSearchResult, useGetProfilesQuery } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import {
  CrossIcon,
  EmptyFolderSvg,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSmall,
  PlusIcon,
} from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GridContainer } from '../../../components/uikit-components/Grid/Grid';
import { AddStaffModalAdultRow } from './AddStaffModalAdultRow';
import { AddStaffSelectAdultModalSearchInput } from './AddStaffSelectAdultModalSearchInput';

type AddStaffSelectAdultModalProps = {
  open: boolean;
  onAdultClick: (a: ProfileSearchResult) => void;
  onCreateAdult: () => void;
  onClose: () => void;
  schoolId: string;
};

export const AddStaffSelectAdultModal: FC<AddStaffSelectAdultModalProps> = ({
  open,
  schoolId,
  onCreateAdult,
  onAdultClick,
  onClose,
}) => {
  const { $t } = useIntl();

  const {
    data,
    setParams,
    isLoading,
    isRefetching,
    params,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetProfilesQuery(
    {
      schoolId,
      type: 'adult',
      query: '',
      excludeSchoolUserTypes: [SchoolUserRole.Staff, SchoolUserRole.Parent],
      min: true,
    },
    {
      refetchOnMount: 'always',
    },
  );

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const entries = useMemo(() => {
    return (
      data?.pages.reduce<ProfileSearchResult[]>((prev, curr) => [...prev, ...curr.results], []) ??
      []
    );
  }, [data]);

  const toggleIncludeParents = useCallback(() => {
    setParams((params) => ({
      ...params,
      excludeSchoolUserTypes: params.excludeSchoolUserTypes?.includes(SchoolUserRole.Parent)
        ? params.excludeSchoolUserTypes.filter((p) => p !== SchoolUserRole.Parent)
        : [...(params.excludeSchoolUserTypes || []), SchoolUserRole.Parent],
    }));
  }, [setParams]);

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((params) => ({ ...params, query }));
    },
    [setParams],
  );

  const areParentsIncluded = !params.excludeSchoolUserTypes?.includes(SchoolUserRole.Parent);

  return (
    <ModalSmall open={open} onClose={onClose}>
      <ModalHeader active title={$t({ id: 'staff-NewStaff' })}>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalContent active>
        <Stack gap={2.5} height="100%">
          <Stack gap={1}>
            <AddStaffSelectAdultModalSearchInput
              value={params.query || ''}
              onChangeText={handleChangeQuery}
            />
            <FormControlLabel
              sx={{ ml: 0.5 }}
              control={<Checkbox onChange={toggleIncludeParents} checked={areParentsIncluded} />}
              label={
                <>
                  <Typography variant="h3">
                    <FormattedMessage id="people-ShowParents" />
                  </Typography>
                </>
              }
            />
          </Stack>
          {isLoading || isRefetching ? (
            <Loading />
          ) : !entries.length ? (
            <Stack flex={1} justifyContent="center" alignItems="center">
              <EmptyFolderSvg />
              <Typography my={3} variant="h3" color="common.grey2">
                <FormattedMessage
                  id={params.query ? 'people-NoUserMatches' : 'people-NoUsersExist'}
                  values={{
                    userTypePlural: `${$t({
                      id: 'peopleDetail-StaffMember-plural',
                    }).toLowerCase()}${
                      areParentsIncluded
                        ? `, ${$t({
                            id: 'userType-parent-plural',
                          }).toLowerCase()}`
                        : ''
                    }`,
                  }}
                />
              </Typography>
            </Stack>
          ) : (
            <>
              <GridContainer>
                {entries.map((adult, i) => (
                  <AddStaffModalAdultRow
                    key={adult.user_id + i}
                    adult={adult}
                    onAdultClick={onAdultClick}
                  />
                ))}
                {hasNextPage && (
                  <Box py={3}>
                    <Loading />
                    <div ref={loaderRef} />
                  </Box>
                )}
              </GridContainer>
            </>
          )}
        </Stack>
      </ModalContent>
      <ModalFooter sx={{ justifyContent: 'flex-start' }}>
        <Button onClick={onCreateAdult} startIcon={<PlusIcon />}>
          <FormattedMessage id="migration-CreateNewAdult" />
        </Button>
      </ModalFooter>
    </ModalSmall>
  );
};
