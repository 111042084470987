import { Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  DefaultSchoolYear,
  EVENTS_FILTER_KEYS,
  EventsStatuses,
  FilterKeys,
  FilterSection,
  GetEventsQueryFilters,
  RepetitionType,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AgeGroupExpandedSelect,
  DateRangeDropdown,
  eventFilterAdapter,
  EventRecurrenceExpandedSelect,
  EventRecurrenceTagSelect,
  EventsParticipationOptions,
  EventsStatusOptions,
  ExpandedSelectFilter,
  FilterDropdown,
  filterExistingFilterOptions,
  FiltersContainer,
  getEventsStatusLabel,
  getParticipantLabel,
  getSelectedItemsWithGrouping,
  GroupExpandedSelect,
  GroupTagSelect,
  MoreButton,
  MoreButtonOption,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  PropertyTypeExpandedSelect,
  PropertyTypeTagSelect,
  renderPropertyGroupTags,
  SelectedItem,
  SelectedItemWithGrouping,
  toggleMultipleValueArrayProperty,
} from '@schooly/components/filters';
import { EventsInvite, SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { TagSelect } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useSchool } from '../../hooks/useSchool';

type EventsFiltersProps = {
  schoolId: string;
  filters: GetEventsQueryFilters;
  defaultFilters: GetEventsQueryFilters;
  onSetFilters: (v: GetEventsQueryFilters) => void;
  notActualInitialDate?: boolean;
  defaultSchoolYear?: DefaultSchoolYear;
  defaultUserFilters: GetEventsQueryFilters;
};

export const EventsFilters: FC<EventsFiltersProps> = ({
  defaultFilters,
  onSetFilters,
  filters: actualFilters,
  schoolId,
  notActualInitialDate,
  defaultSchoolYear,
  defaultUserFilters,
}) => {
  const { $t } = useIntl();
  const {
    getAgeGroupsByLevelId,
    getAgeGroupById,
    schoolLevelsWithAgeGroupsMap,
    getSchoolLevelById,
  } = useAgeGroups({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });
  const [dateChanged, setDateChanged] = useState(false);
  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetEventsQueryFilters>(actualFilters);
  const { currentStaff } = useAuth();
  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    setDraftFilters((filters) => ({ ...filters, [v]: filters[v] !== undefined ? undefined : [] }));
  }, []);
  const { hasHouses } = useSchool();

  const { permissions } = useAuth();
  const canViewGroups = permissions.includes('group_viewer');

  useEffect(() => {
    setDraftFilters(actualFilters);
  }, [actualFilters]);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  const handleApply = useMemo(() => {
    if (
      !EVENTS_FILTER_KEYS.some((key) => {
        const draftFiltersForKey = [...(draftFilters[key] || [])];
        const actualFiltersForKey = [...(actualFilters[key] || [])];

        return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
      })
    )
      return undefined;

    return () => {
      onSetFilters(draftFilters);
    };
  }, [onSetFilters, actualFilters, draftFilters]);

  const dateFilter = useMemo(() => ({ [FilterKeys.Date]: draftFilters.date }), [draftFilters.date]);

  const recurringFilter = useMemo(
    () => ({
      [FilterKeys.Date]: draftFilters.date,
      [FilterKeys.RepetitionType]: [RepetitionType.FirstOfRecurrence],
    }),
    [draftFilters.date],
  );

  const {
    onSetDate,
    onSelectGroup,
    onClearGroup,
    onSelectHouseId,
    onClearAgeGroup,
    onClearHouse,
    onSelectAgeGroup,
  } = useMemo(() => {
    const updateFilter = (key: FilterKeys.House | FilterKeys.Group) => (id: string) => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: filters[key]?.includes(id)
          ? filters[key]?.filter((ct) => ct !== id)
          : [...(filters[key] || []), id],
      }));
    };
    const onSelectAgeGroup = (v: string[]) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(filters[FilterKeys.AgeGroup], v),
      }));
    };
    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    return {
      onSetDate: (v: [Date, Date]) =>
        setDraftFilters((filters) => {
          const updatedDates = [
            format(v[0], DEFAULT_DATE_FORMAT_FNS),
            format(v[1], DEFAULT_DATE_FORMAT_FNS),
          ];

          if (updatedDates.join('') === filters.date?.join('')) return filters;

          setDateChanged(true);
          return {
            ...filters,
            [FilterKeys.Date]: updatedDates,
          };
        }),
      onSelectHouseId: updateFilter(FilterKeys.House),
      onClearHouse: clearFilter(FilterKeys.House),
      onSelectAgeGroup,
      onClearAgeGroup: clearFilter(FilterKeys.AgeGroup),
      onSelectGroup: updateFilter(FilterKeys.Group),
      onClearGroup: clearFilter(FilterKeys.Group),
    };
  }, []);

  const { onSelectStatus, onClearStatus } = useMemo(() => {
    return {
      onSelectStatus: (id: EventsStatuses) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.EventStatus]: filters[FilterKeys.EventStatus]?.includes(id)
            ? filters[FilterKeys.EventStatus]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.EventStatus] || []), id],
        })),
      onClearStatus: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.EventStatus]: [],
        })),
    };
  }, []);

  const { onSelectParticipation, onClearParticipation } = useMemo(() => {
    return {
      onSelectParticipation: (id: EventsInvite) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.InviteType]: filters[FilterKeys.InviteType]?.includes(id)
            ? filters[FilterKeys.InviteType]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.InviteType] || []), id],
        })),
      onClearParticipation: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.EventStatus]: [],
        })),
    };
  }, []);

  const { onSelectRecurrence, onClearRecurrence } = useMemo(() => {
    return {
      onSelectRecurrence: (id: string) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.RecurrenceId]: [id],
        })),
      onClearRecurrence: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.RecurrenceId]: [],
        })),
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    setDraftFilters(defaultFilters);
  }, [defaultFilters]);

  const handleResetToDefault = useCallback(() => {
    setDraftFilters(defaultUserFilters);
  }, [defaultUserFilters]);

  const filtersDate = draftFilters.date;
  const filtersGroup = canViewGroups ? draftFilters.group : undefined;
  const filtersAgeGroup = draftFilters.age_group;
  const filtersHouse = draftFilters.house;
  const filtersStatus = draftFilters.event_status;
  const filtersParticipation = draftFilters.invite_type;
  const filtersRecurring = draftFilters.recurrence_id;

  const houseLabel = `${$t({ id: 'schoolProperty-House' })}`;
  const groupLabel = $t({ id: 'events-group' });
  const statusLabel = $t({ id: 'events-status' });
  const participationLabel = $t({ id: 'event-participation' });
  const ageGroupLabel = $t({ id: 'schoolProperty-AgeGroup' });
  const dateLabel = $t({ id: 'schoolProperty-DateAndPeriod' });
  const recurrenceLabel = $t({ id: 'events-recurring' });

  const filterOptions: MoreButtonOption<keyof Omit<GetEventsQueryFilters, 'has_signups'>>[] = [
    { value: FilterKeys.Date, label: dateLabel, required: true },
    { value: FilterKeys.EventStatus, label: statusLabel },
    { value: FilterKeys.InviteType, label: participationLabel },
    { value: FilterKeys.AgeGroup, label: ageGroupLabel },
    { value: FilterKeys.House, label: houseLabel },
    { value: FilterKeys.Group, label: groupLabel, visible: canViewGroups },
    { value: FilterKeys.RecurrenceId, label: recurrenceLabel },
  ];

  const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups =
      filtersAgeGroup?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [filtersAgeGroup, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        onSaveFilter={handleSaveFilter}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        schoolId={schoolId}
        section={FilterSection.Events}
        filters={draftFilters}
        defaultSchoolYear={defaultSchoolYear}
        accessMap={{
          group: canViewGroups,
        }}
        onSetFilters={(v) => {
          onSetFilters(pickOnlyParamsFromFilterKeys(EVENTS_FILTER_KEYS, v));
        }}
        filterAdapter={eventFilterAdapter}
      />
      {filtersDate && (
        <DateRangeDropdown
          schoolId={schoolId}
          date={filtersDate}
          onSetDate={onSetDate}
          openLabel={dateLabel}
          defaultSchoolYear={defaultSchoolYear}
          dateChanged={dateChanged}
          notActualInitialDate={notActualInitialDate}
        />
      )}
      {filtersParticipation && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.InviteType)}
          label={participationLabel}
          tags={(open) =>
            filtersParticipation.map((status) => (
              <TagSelect
                key={status}
                sx={{ maxWidth: 200 }}
                onClick={open}
                label={$t({ id: getParticipantLabel(status) })}
              />
            ))
          }
        >
          {(onClose) => (
            <ExpandedSelectFilter
              selected={filtersParticipation}
              onSelect={onSelectParticipation}
              onClose={onClose}
              onClear={onClearParticipation}
              options={EventsParticipationOptions}
              getLabel={(s) => $t({ id: getParticipantLabel(s) })}
            />
          )}
        </FilterDropdown>
      )}
      {filtersStatus && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.EventStatus)}
          label={statusLabel}
          tags={(open) =>
            filtersStatus.map((status) => (
              <TagSelect
                key={status}
                sx={{ maxWidth: 200 }}
                onClick={open}
                label={$t({ id: getEventsStatusLabel(status) })}
              />
            ))
          }
        >
          {(onClose) => (
            <ExpandedSelectFilter
              selected={filtersStatus}
              onSelect={onSelectStatus}
              onClose={onClose}
              onClear={onClearStatus}
              options={EventsStatusOptions}
              getLabel={(s) => $t({ id: getEventsStatusLabel(s) })}
            />
          )}
        </FilterDropdown>
      )}
      {filtersAgeGroup && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.AgeGroup)}
          label={ageGroupLabel}
          tags={(open) =>
            renderPropertyGroupTags({
              selectedItems: selectedItemsForAgeGroups,
              onClick: open,
              getProperty: (i) =>
                i.isGroup
                  ? getSchoolLevelById(i.id)
                  : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
              getTooltip: (i) =>
                i.isGroup
                  ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                      <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                    ))
                  : null,
              tagProps: {
                userRole: SchoolUserRole.Student,
                sx: { maxWidth: 200 },
              },
            })
          }
        >
          {(onClose) => (
            <AgeGroupExpandedSelect
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersAgeGroup}
              onSelect={onSelectAgeGroup}
              onClose={onClose}
              onClear={onClearAgeGroup}
            />
          )}
        </FilterDropdown>
      )}
      {filtersHouse && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.House)}
          label={houseLabel}
          tags={(open) =>
            filtersHouse.map((id) => (
              <PropertyTypeTagSelect
                userRole={SchoolUserRole.Student}
                sx={{ maxWidth: 200 }}
                schoolId={schoolId}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <PropertyTypeExpandedSelect
              propertyType={SchoolPropertyType.House}
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersHouse}
              onSelectId={onSelectHouseId}
              onClose={onClose}
              onClear={onClearHouse}
            />
          )}
        </FilterDropdown>
      )}
      {filtersGroup && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Group)}
          label={groupLabel}
          width={500}
          tags={(open) =>
            filtersGroup.map((id) => (
              <GroupTagSelect key={id} sx={{ maxWidth: 200 }} id={id} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <GroupExpandedSelect
              selectedIds={filtersGroup}
              schoolId={schoolId}
              onSelectGroup={onSelectGroup}
              onClose={onClose}
              onClear={onClearGroup}
              filters={dateFilter}
            />
          )}
        </FilterDropdown>
      )}
      {filtersRecurring && (
        <FilterDropdown
          width={500}
          onClear={() => toggleFiltersVisible(FilterKeys.RecurrenceId)}
          label={recurrenceLabel}
          tags={(open) =>
            filtersRecurring.map((id) => (
              <EventRecurrenceTagSelect
                key={id}
                sx={{ maxWidth: 200 }}
                id={id}
                onClick={open}
                schoolId={schoolId}
                onClear={() => onClearRecurrence()}
              />
            ))
          }
        >
          {(onClose) => (
            <EventRecurrenceExpandedSelect
              selectedId={filtersRecurring[0]}
              schoolId={schoolId}
              onSelectRecurrence={onSelectRecurrence}
              onClose={onClose}
              onClear={onClearRecurrence}
              filters={recurringFilter}
            />
          )}
        </FilterDropdown>
      )}

      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterExistingFilterOptions({ filterOptions, hasHouses })}
        selectedOptions={EVENTS_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        onToggleOption={toggleFiltersVisible}
      />
    </FiltersContainer>
  );
};
