import { InboxMessage } from '@schooly/api';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

import SearchInput from '../../../components/ui/SearchInput';
import buildClassName from '../../../utils/buildClassName';
import EmptyInbox from './EmptyInbox';
import { getFilteredMessages } from './get-filtered-messages';
import InboxMessagesList from './InboxMessagesList';

import './index.scss';

interface IProps {
  messages?: InboxMessage[];
  onCardClick: (message: InboxMessage) => void;
}

enum InboxContentType {
  Messages,
  // todo: uncomment one day and add translation
  // Notifications,
}

const INBOX_CONTENT_TYPE = $enum(InboxContentType);

const InboxModalContent: React.FC<IProps> = ({ messages, onCardClick }) => {
  const [contentType, setContentType] = useState(InboxContentType.Messages);
  const [displayedMessages, setDisplayedMessages] = useState<InboxMessage[] | undefined>(messages);
  const [filter, setFilter] = useState<string>('');

  const handleChange = useCallback(
    (value: string) => {
      setFilter(value);

      const filteredMessages = getFilteredMessages(value, messages);

      if (!value.length) {
        setDisplayedMessages(messages);
        return;
      }

      setDisplayedMessages(filteredMessages);
    },
    [messages],
  );

  const { formatMessage } = useIntl();

  const searchPlaceholder = useMemo(() => {
    const availableCount = Number(messages?.length);

    if (!availableCount) {
      return undefined;
    }

    return formatMessage(
      { id: 'people-SearchAmongTypeCount' },
      {
        userTypePlural: formatMessage({
          id: availableCount > 1 ? 'messages-Messages' : 'messages-Message',
        }).toLowerCase(),
        count: availableCount,
      },
    );
  }, [formatMessage, messages]);

  const messagesList = displayedMessages?.length ? (
    <InboxMessagesList onCardClick={onCardClick} messages={displayedMessages} />
  ) : (
    <EmptyInbox />
  );

  const notificationsList = <EmptyInbox />;

  return (
    <>
      <header className="InboxModal__header">
        {INBOX_CONTENT_TYPE.getKeys().map((key) => (
          <button
            key={key}
            onClick={() => setContentType(InboxContentType[key])}
            className={buildClassName(
              'InboxModal__content-type-button reset-button-styles',
              InboxContentType[key] === contentType && 'active',
            )}
            type="button"
          >
            {formatMessage({ id: `messages-InboxContentType-${key}` })}
          </button>
        ))}
        <SearchInput
          className="InboxModal__search-input"
          onChange={handleChange}
          value={filter}
          placeholder={searchPlaceholder}
        />
      </header>
      {contentType === InboxContentType.Messages ? messagesList : notificationsList}
    </>
  );
};

export default InboxModalContent;
