import { Stack, Typography } from '@mui/material';
import { ConductConnotation, useGetConductTypesForSchoolQuery } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { renderConductTypeTags } from './ConductTypeExpandedSelect';

type ConductTypeSelectProps = {
  schoolId: string;
  selectedValue: string[];
  onSelectId: (v: string) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const ConductTypeSelectMultiple: FC<ConductTypeSelectProps> = ({
  schoolId,
  selectedValue,
  onSelectId,
  placeholder,
  ...dropdownProps
}) => {
  const { data } = useGetConductTypesForSchoolQuery(schoolId, { refetchOnMount: 'always' });

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const positive = data.filter((ct) => ct.connotation === ConductConnotation.POSITIVE);
    const negative = data.filter((ct) => ct.connotation === ConductConnotation.NEGATIVE);

    if (!data.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {!!positive.length && (
          <>
            <Typography variant="h4" mt={1} ml={1}>
              <FormattedMessage id="conduct-Type-positive" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
              {positive.map((option) => (
                <TagSelect
                  key={option.id}
                  label={option.name}
                  variant={selectedValue?.includes(option.id) ? 'filled' : undefined}
                  onClick={() => onSelectId(option.id)}
                />
              ))}
            </Stack>
          </>
        )}
        {!!negative.length && (
          <>
            <Typography variant="h4" mt={1} ml={1}>
              <FormattedMessage id="conduct-Type-negative" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
              {negative.map((option) => (
                <TagSelect
                  key={option.id}
                  label={option.name}
                  variant={selectedValue?.includes(option.id) ? 'filled' : undefined}
                  onClick={() => onSelectId(option.id)}
                />
              ))}
            </Stack>
          </>
        )}
      </>
    );
  }, [data, onSelectId, selectedValue]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selectedValue.length}
      renderContent={renderContent}
    >
      {(opened) => (
        <>
          {renderConductTypeTags({
            ids: selectedValue,
            tagProps: { size: placeholder ? 'small' : undefined },
            onDelete: opened ? onSelectId : undefined,
          })}
        </>
      )}
    </DropdownSelect>
  );
};
