import {
  ParentSchoolRelation,
  SchoolRelation,
  SchoolUserType,
  StaffSchoolRelation,
  StudentSchoolRelation,
  SuggestedChange,
  SyncUser,
  UploadFile,
  UserType,
  WithRole,
} from '@schooly/api';
import { createContext } from 'react';

import { UserContexts } from '../../constants/userContexts';
import { ProfileModalMode } from './helpers';

export const CONTEXT_NAME = 'ProfileContext';

export enum ProfileModalType {
  DeleteAccount = 'deleteAccount',
  CloseAccount = 'closeAccount',
  PasswordChange = 'passwordChange',
}

export interface ProfileSchoolRelation extends SchoolRelation, WithRole {
  userType: SchoolUserType;
}

export interface ProfileContextAction {
  titleTextId: string;
  handler: () => void;
}

export type ProfileContextState = {
  user?: SyncUser;
};

export type ProfileContextProps = {
  mode: ProfileModalMode;
  userContext: UserContexts;
  userType: UserType;
  allUserTypes?: UserType[];
  schoolMembership?: StaffSchoolRelation | StudentSchoolRelation | ParentSchoolRelation;
  schoolRelations?: ProfileSchoolRelation[];
  contextActions: ProfileContextAction[];
  suggestedChanges: SuggestedChange[];
  isFetching: boolean;
  isMembershipFetched: boolean;
  isUpdating: boolean;
  isSchoolUser: boolean;
  isSchoolContext: boolean;
  isIndividualContext: boolean;
  showLoader: boolean;
  hasAccess: boolean;
  hasEditingPermission: boolean;
  hasViewerPermission: boolean;
  canEditProfile: boolean;
  canEditProfilePicture: boolean;
  isChildOfCurrentUser: boolean;
  openedModal: ProfileModalType | null;

  actions: {
    handleClose: () => void;
    handleUserDelete: () => void;
    setMode: (mode: ProfileModalMode) => void;
    updateProfile: (update: Partial<SyncUser>) => Promise<SyncUser | undefined>;
    updateAvatar: (avatar: UploadFile) => void;
    closeModal: () => void;
    invalidateProfileCache: () => void;
    handleSchoolRoleChange: (relationData: ProfileSchoolRelation) => void;
  };
} & ProfileContextState;

export const ProfileContext = createContext<ProfileContextProps>({
  userContext: UserContexts.School,
  userType: 'student',
  allUserTypes: undefined,
  mode: ProfileModalMode.About,
  contextActions: [],
  suggestedChanges: [],
  isFetching: false,
  isMembershipFetched: false,
  isUpdating: false,
  isSchoolUser: false,
  isSchoolContext: false,
  isIndividualContext: false,
  showLoader: false,
  hasAccess: false,
  hasEditingPermission: false,
  hasViewerPermission: false,
  canEditProfile: false,
  canEditProfilePicture: false,
  isChildOfCurrentUser: false,
  openedModal: null,

  actions: {
    handleClose: () => {},
    handleUserDelete: () => {},
    setMode: () => {},
    invalidateProfileCache: () => {},
    updateProfile: async () => undefined,
    updateAvatar: () => {},
    closeModal: () => {},
    handleSchoolRoleChange: () => {},
  },
});

ProfileContext.displayName = CONTEXT_NAME;
