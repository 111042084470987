import { UseAutocompleteProps } from '@mui/base/useAutocomplete/useAutocomplete';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FilterKeys, GroupSubject, GroupType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import {
  SelectOptionsArchivedEmptyStub,
  SelectOptionsArchivedIcon,
} from '@schooly/components/filters';
import { usePrevious } from '@schooly/hooks/use-previous';
import { ArchiveIcon } from '@schooly/style';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormCheckbox } from '../../../../components/uikit-components/FormCheckbox/FormCheckbox';
import { FormTextField } from '../../../../components/uikit-components/FormTextField/FormTextField';
import { InputEndAdornment } from '../../../../components/uikit-components/FormTextField/InputEndAdornment';
import { useAddGroupModal } from '../useAddGroupModal';

export interface SubjectSelectProps {
  isEditingLocked?: boolean;
}

export interface SubjectSelectForm {
  isTutorGroup?: boolean;
}

type MuiAutocompleteValue =
  | {
      label: string;
      value: string;
    }
  | null
  | undefined;

const subjectToOptionValue = (subject?: GroupSubject) =>
  subject?.id ? { label: subject.name, value: subject.id } : undefined;

export const SubjectSelect: FC<SubjectSelectProps> = ({ isEditingLocked }) => {
  const { $t } = useIntl();
  const { subject, group_type, groupSubjects, focusedField, actions } = useAddGroupModal({});
  const { permissions } = useAuth();
  const isAdmin = permissions?.includes('school_admin');
  const { getConfirmation } = useConfirmationDialog();

  // TODO: refactor entire AddGroupModal to use react-hook-forms
  const form = useForm<SubjectSelectForm>({
    defaultValues: { isTutorGroup: group_type === GroupType.TutorGroup },
  });

  const isTutorGroup = form.watch('isTutorGroup');
  const prevIsTutorGroup = usePrevious(isTutorGroup);

  const groupSubjectsOptions = useMemo(
    () => groupSubjects.map(subjectToOptionValue),
    [groupSubjects],
  );

  const hasOptions = Boolean(groupSubjectsOptions.length);
  const noOptionsNoneSelected = !hasOptions && !subject?.id;

  const isSubjectOptionEqualToValue = useCallback<
    Exclude<
      UseAutocompleteProps<
        { label: string; value: string } | undefined,
        undefined,
        undefined,
        undefined
      >['isOptionEqualToValue'],
      undefined
    >
  >((option, value) => {
    return option?.value === value?.value;
  }, []);

  const autocompleteInputRef = useRef<HTMLInputElement>();
  const blurAutocompleteInputOnClear = useCallback<
    Exclude<
      UseAutocompleteProps<
        { label: string; value: string } | undefined,
        undefined,
        undefined,
        undefined
      >['onInputChange'],
      undefined
    >
  >(
    (event, value, reason) => {
      if (reason === 'clear') {
        setTimeout(() => autocompleteInputRef.current?.blur());
      }

      if (value) {
        form.setValue('isTutorGroup', false);
      }
    },
    [form],
  );

  const handleChange = useCallback(
    async (
      event: React.SyntheticEvent,
      option: MuiAutocompleteValue,
      reason: AutocompleteChangeReason,
    ) => {
      if (reason === 'clear' && subject?.archived) {
        const confirmed = await getConfirmation({
          textId: `deselect-subject-archived`,
          textValues: { name: subject?.name },
        });
        if (!confirmed) {
          autocompleteInputRef.current?.blur();
          return;
        }
      }
      return actions.setContextState({
        group_type: GroupType.Subject,
        subject: {
          id: option?.value!,
          name: option?.label!,
        },
      });
    },
    [actions, getConfirmation, subject?.archived, subject?.name],
  );

  const endAdornment = useMemo(() => {
    if (isTutorGroup || isEditingLocked) {
      return <InputEndAdornment locked />;
    }
    if (noOptionsNoneSelected) {
      return (
        <InputEndAdornment
          customIcon={<SelectOptionsArchivedIcon isAdmin={isAdmin} type={FilterKeys.Subject} />}
        />
      );
    }
  }, [isAdmin, isEditingLocked, isTutorGroup, noOptionsNoneSelected]);

  useEffect(() => {
    if (prevIsTutorGroup !== isTutorGroup) {
      actions.setContextState({
        group_type: isTutorGroup ? GroupType.TutorGroup : GroupType.Subject,
        subject: isTutorGroup ? undefined : subject,
      });
    }
  }, [actions, isTutorGroup, prevIsTutorGroup, subject]);

  return (
    <FormProvider {...form}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Box
          sx={{
            '& .MuiFormControlLabel-root': {
              whiteSpace: 'nowrap',
              backgroundColor: subject?.id ? 'background.default' : undefined,
            },
          }}
        >
          <FormCheckbox
            name="isTutorGroup"
            labelTextId="groups-TutorGroup"
            testId="groups-modal-tutor-group-checkbox"
          />
        </Box>

        {groupSubjects.length && (
          <>
            <Typography variant="h3" color="text.secondary">
              <FormattedMessage id="or" />
            </Typography>

            <Autocomplete
              key={subject?.id}
              options={groupSubjectsOptions}
              value={subjectToOptionValue(subject)}
              isOptionEqualToValue={isSubjectOptionEqualToValue}
              openOnFocus
              blurOnSelect
              disableClearable={!subject?.id}
              disabled={isEditingLocked || noOptionsNoneSelected || isTutorGroup}
              onChange={handleChange}
              onInputChange={blurAutocompleteInputOnClear}
              fullWidth
              sx={{
                ' .MuiFormLabel-root.Mui-disabled': {
                  color: 'text.secondary',
                },
              }}
              PaperComponent={
                !hasOptions
                  ? (props) => (
                      <Paper elevation={0} {...props} sx={{ px: 1, py: 1 }}>
                        <SelectOptionsArchivedEmptyStub
                          isAdmin={isAdmin}
                          type={FilterKeys.Subject}
                        />
                      </Paper>
                    )
                  : undefined
              }
              renderInput={(params) => (
                <FormTextField
                  {...params}
                  sx={{
                    '.MuiOutlinedInput-root:hover .MuiInputAdornment-root.MuiInputAdornment-positionEnd':
                      {
                        color: 'text.secondary',
                      },
                    '.Mui-disabled .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline':
                      { borderColor: 'common.light3' },
                    ...(isTutorGroup && {
                      '&&& .MuiOutlinedInput-root': { pr: 0 },

                      '& .MuiOutlinedInput-input, & .MuiInputAdornment-root': {
                        zIndex: 1,
                      },

                      '& .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: 'background.default',
                      },
                    }),
                  }}
                  label={$t({ id: 'groups-GroupSubject' })}
                  autoFocus={focusedField === 'subject'}
                  fullWidth
                  locked={isTutorGroup || isEditingLocked}
                  startAdornment={
                    subject?.archived && (
                      <InputAdornment position="start" sx={{ '&&': { mt: 0 } }}>
                        <Tooltip title={$t({ id: 'schoolProperty-Archived-subject' })}>
                          <IconButton inverse>
                            <ArchiveIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }
                  endAdornment={endAdornment}
                  showEndAdornment={
                    !subject?.id || isTutorGroup || isEditingLocked || noOptionsNoneSelected
                  }
                  dropdown
                  inputRef={autocompleteInputRef}
                  data-test-id="groups-modal-subject-dropdown"
                />
              )}
            />
          </>
        )}
      </Stack>
    </FormProvider>
  );
};
