import React from 'react';

import TextLayout from '../../components/ui/TextLayout';

import './index.scss';

const CookiesPolicy: React.FC = () => (
  <TextLayout>
    <section className="policy-text">
      <h1 className="mt-4 mb-5">Cookies policy</h1>

      <p>Last update: 9 December 2021</p>
      <h3>What is a cookie?</h3>
      <p>
        The provider, through their own account or that of a third contracted party for the benefit
        of measurement services, can use cookies when the user browses the website. The cookies are
        files which are sent to the browser through the web server, with the aim to document the
        users activities during their browsing time. The cookies used by the website are uniquely
        associated with an anonymous user and their computer, and are not related to the
        user&rsquo;s personal data.
      </p>
      <h3>Purpose of cookies</h3>
      <p>
        Using cookies, it is possible for the server hosting the website to recognize the browser
        being used in order to facilitate navigation; allowing, for example, users who have
        registered previously to access areas, services, promotions or competitions reserved
        exclusively for them without having to register each time. They are also used to measure the
        audience and traffic parameters, monitor progress and the number of entries.
      </p>
      <p>
        The user has the possibility to configure their browser in order to be shown when cookies
        are being used and to prevent their installation on their computer. Please consult the
        browser&rsquo;s instructions and user manuals to find out more information.
      </p>
      <p>
        In order to use the website, the user does not have to allow the installation of cookies
        sent by the website, or third parties which act on their behalf. Nevertheless, it is
        necessary for the user to log-in to every service which requires prior registration or
        log-in details.
      </p>
      <h3>Types of cookies</h3>
      <p>
        The cookies used on this website are all temporary, with the sole purpose of making future
        transmission more efficient. In no case will cookies be used in order to collect personal
        data.
      </p>
      <h3>The cookies used are</h3>
      <ul>
        <li>
          Analytical cookies (Google Analytics): These allow monitoring and analysis of the
          user&rsquo;s behaviour in order to make improvements based on his or her usage of the
          website and services. As stated by Google, Google Analytics is a free web analytics tool
          provided by Google that primarily allows website owners to know how users interact with
          their website. It also enables cookies in the domain of the website being viewed and uses
          a set of cookies called &rdquo; __utma &rdquo; and &rdquo; __utmz &rdquo; to collect
          anonymous information and report on website trends without identifying individual users.
          More information on Google Analytics
          at:https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage-
        </li>
        <li>
          Technical cookies: They allow the user to browse through the website and use its various
          options or services.
        </li>
        <li>
          Plug-in cookies: these cookies are used to share social content. Social networks store and
          access this type of cookies in the user&rsquo;s terminal equipment, allowing the social
          network to identify its members as they interact with the plug-ins.
        </li>
        <li>
          Multimedia player cookies: They store technical data, necessary to play video or audio
          content, maintaining an adequate connection speed, image quality and buffering parameters.
        </li>
      </ul>
      <h3>Opting out of cookies</h3>
      <p>
        Users can disable cookies on their terminal by configuring their browser, but this will
        entail a less satisfactory browsing experience that may even be defective. For more
        information on disabling cookies visit:
      </p>
      <p>
        <a
          href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
          target="_blank"
          rel="noreferrer"
        >
          <u>Mozilla</u>
        </a>
      </p>
      <p>
        <a
          href="https://support.google.com/chrome/answer/95647?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          <u>Chrome</u>
        </a>
      </p>
      <p>
        <a
          href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11-win-7"
          target="_blank"
          rel="noreferrer"
        >
          <u>Internet Explorer</u>
        </a>
      </p>
      <p>
        <a
          href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
          target="_blank"
          rel="noreferrer"
        >
          <u>Microsoft Edge</u>
        </a>
      </p>
      <p>
        <a href="https://support.apple.com/en-gb/HT201265" target="_blank" rel="noreferrer">
          <u>iOS Safari</u>
        </a>
      </p>
      <p>
        <a
          href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
          target="_blank"
          rel="noreferrer"
        >
          <u>MacOS Safari</u>
        </a>
      </p>
      <p>
        <a
          href="https://help.opera.com/en/latest/web-preferences/#cookies"
          target="_blank"
          rel="noreferrer"
        >
          <u>Opera</u>
        </a>
      </p>
      <h3>IP addresses</h3>
      <p>
        The website servers will automatically be able to detect the IP Address and domain name used
        by the user. An IP Address is a number which is automatically assigned to a computer when it
        connects to the Internet. All of this information is collected in a file about server
        activity that allows subsequent processing of data; with the aim to collect statistical
        measurements only, which show the number of printed pages, the number of visits to web
        services, the reasons for the visit, the point of access, etc.
      </p>
      <h3>Security</h3>
      <p>
        The website uses information security technologies which are accepted throughout the
        industry, such as FireWalls, methods to control access and cryptic mechanisms. All of these
        have the objective of preventing unauthorised access to the data. In order to carry out
        these purposes, the user/ client accepts that the provider collects data for purposes of
        authentication for access control.
      </p>
    </section>
  </TextLayout>
);

export default CookiesPolicy;
