import { Stack } from '@mui/material';
import { GridApiPro } from '@mui/x-data-grid-pro';
import { ChevronLeftIcon, ChevronRightIcon } from '@schooly/style';
import React, {
  FC,
  HTMLAttributes,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface ScrollArrowsProps {
  cellSelector?: string;
  containerRef?: RefObject<HTMLElement>;
  ArrowComponent?: React.FunctionComponent;
  onArrowClick?: typeof handleArrowClick;
  shouldShowRightArrow?: () => boolean;
  shouldShowLeftArrow?: () => boolean;
  apiRef?: React.MutableRefObject<GridApiPro>;
}

/**
 * Scrolls one assessment item left/right
 */
const handleArrowClick = (
  direction: 'left' | 'right',
  containerRef?: RefObject<HTMLElement>,
  cellSelector?: string,
) => {
  return () => {
    if (!containerRef?.current || !cellSelector) {
      return;
    }

    // get all header cells
    const cells = Array.from<HTMLElement>(containerRef.current.querySelectorAll(cellSelector));

    const scrollLeft = containerRef.current.scrollLeft ?? 0;
    const offsetLeft = containerRef.current.offsetLeft ?? 0;

    // first cell is sticky, should be considered as an offset rather than a plain cell
    const stickyCell = cells.shift();
    const offset = stickyCell?.offsetWidth ?? 0;
    const anchorOffset = offset + scrollLeft;

    let scrollTo: HTMLElement | undefined;

    // define an item we'll be scrolling to, depending on the direction
    if (direction === 'right') {
      scrollTo = cells.find((cell) => {
        return cell.offsetLeft > Math.ceil(anchorOffset + offsetLeft);
      });
    } else {
      if (cells.length > 0) {
        for (let i = cells.length - 1; i >= 0; i--) {
          const cell = cells[i];
          if (cell.offsetLeft < Math.floor(anchorOffset)) {
            scrollTo = cell;
            break;
          }
        }
      }
    }

    // do scroll
    if (scrollTo) {
      containerRef.current.scrollTo({
        left: scrollTo.offsetLeft - offset - offsetLeft,
        behavior: 'smooth',
      });
    }
  };
};

const Arrow: FC<HTMLAttributes<HTMLElement>> = ({ children, ...props }) => (
  <Stack
    justifyContent="center"
    sx={(theme) => ({
      position: 'absolute',
      height: 80,
      fontSize: 20,
      zIndex: 4,
      cursor: 'pointer',
      color: (theme) => theme.palette.text.secondary,
      background: (theme) => theme.palette.background.paper,

      '&:hover': {
        color: (theme) => theme.palette.common.grey3,
      },

      '&:active': {
        color: (theme) => theme.palette.common.grey2,
      },

      '&.arrowRight': {
        right: 26,
        background: 'linear-gradient(90deg, transparent 0%, white 55%, white 100%)',
        [theme.breakpoints.down('md')]: {
          right: 0,
          background: 'linear-gradient(90deg, transparent 0%, white 30%, white 100%)',
          height: 75,
          pl: theme.spacing(1.5),
          pr: theme.spacing(2.5),
        },
      },
      '&.arrowLeft': {
        left: 280,
        [theme.breakpoints.down('lg')]: {
          left: 310,
        },
      },
    })}
    {...props}
  >
    {children}
  </Stack>
);

export const ScrollArrows: FC<ScrollArrowsProps> = ({
  containerRef,
  cellSelector,
  ArrowComponent = Arrow,
  onArrowClick = handleArrowClick,
  apiRef,
  shouldShowLeftArrow,
  shouldShowRightArrow,
}) => {
  const onClickLeft = useMemo(
    () => onArrowClick('left', containerRef, cellSelector),
    [containerRef, cellSelector, onArrowClick],
  );

  const onClickRight = useMemo(
    () => onArrowClick('right', containerRef, cellSelector),
    [containerRef, cellSelector, onArrowClick],
  );

  const containerElement = containerRef?.current;
  const [initialized, setInitialized] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  const onScroll = useCallback(() => {
    const leftArrowVisible = shouldShowLeftArrow
      ? shouldShowLeftArrow()
      : (containerElement?.scrollLeft ?? 0) > 0;

    const rightArrowVisible = shouldShowRightArrow
      ? shouldShowRightArrow()
      : (containerElement?.scrollWidth ?? 0) - (containerElement?.offsetWidth ?? 0) >
        Math.ceil((containerElement?.scrollLeft ?? 0) * 1.01);

    setShowLeftArrow(leftArrowVisible);
    setShowRightArrow(rightArrowVisible);
  }, [
    containerElement?.offsetWidth,
    containerElement?.scrollLeft,
    containerElement?.scrollWidth,
    shouldShowLeftArrow,
    shouldShowRightArrow,
  ]);

  useEffect(() => {
    containerElement?.addEventListener('scroll', onScroll);

    onScroll();
    setInitialized(true);

    return () => containerElement?.removeEventListener('scroll', onScroll);
  }, [apiRef, containerElement, onScroll]);

  useEffect(() => {
    if (!apiRef) return;

    const unsubscribeScrollPositionChange = apiRef.current.subscribeEvent(
      'scrollPositionChange',
      onScroll,
    );
    return () => unsubscribeScrollPositionChange();
  }, [apiRef, containerElement, onScroll]);

  return initialized ? (
    <>
      {showLeftArrow && (
        <ArrowComponent onClick={onClickLeft} className="arrowLeft">
          <ChevronLeftIcon />
        </ArrowComponent>
      )}
      {showRightArrow && (
        <ArrowComponent onClick={onClickRight} className="arrowRight">
          <ChevronRightIcon />
        </ArrowComponent>
      )}
    </>
  ) : null;
};
