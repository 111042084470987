import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.5L1.5 1.5L1.5 14.5L14.5 14.5V10C14.5 9.58579 14.8358 9.25 15.25 9.25C15.6642 9.25 16 9.58579 16 10L16 14.75C16 15.4404 15.4404 16 14.75 16L1.25 16C0.559645 16 0 15.4404 0 14.75L0 1.25C0 0.559645 0.559644 0 1.25 0L6 0C6.41421 0 6.75 0.335787 6.75 0.75C6.75 1.16421 6.41421 1.5 6 1.5ZM7.71967 7.21967C7.42678 7.51256 7.42678 7.98744 7.71967 8.28033C8.01256 8.57322 8.48744 8.57322 8.78033 8.28033L14.5 2.56066L14.5 5.75C14.5 6.16421 14.8358 6.5 15.25 6.5C15.6642 6.5 16 6.16421 16 5.75V0.750001C16 0.335787 15.6642 0 15.25 0L10.25 0C9.83579 0 9.5 0.335787 9.5 0.75C9.5 1.16421 9.83579 1.5 10.25 1.5L13.4393 1.5L7.71967 7.21967Z"
      fill="#B3BBCB"
    />
  </svg>
);
