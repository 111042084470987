import { ChipProps } from '@mui/material';
import { FilterKeys, Recurrence, useGetAssessmentsQuery } from '@schooly/api';
import { FC } from 'react';

import { RecurrenceTagSelect } from './RecurrenceTagSelect';

export type AssessmentRecurrenceTagSelectProps = {
  id: string;
  schoolId: string;
  onClear: () => void;
} & Omit<ChipProps, 'label'>;

export const AssessmentRecurrenceTagSelect: FC<AssessmentRecurrenceTagSelectProps> = ({
  id,
  schoolId,
  onClear,
  ...props
}) => {
  const { data } = useGetAssessmentsQuery({
    school_id: schoolId,
    query: '',
    filters: { [FilterKeys.RecurrenceId]: [id] },
  });

  const firstAssessment = data?.pages?.[0]?.results?.[0];

  const recurrenceId = firstAssessment?.recurring_state?.recurrence_id ?? null;
  const recurrenceTitle = firstAssessment?.recurring_state?.recurrence_name ?? null;

  const recurrence: Recurrence | null =
    recurrenceId && recurrenceTitle
      ? {
          id: recurrenceId,
          title: recurrenceTitle,
        }
      : null;

  return (
    <RecurrenceTagSelect
      onClear={onClear}
      schoolId={schoolId}
      recurrence={recurrence}
      id={id}
      {...props}
    />
  );
};
