import { Box, Button, IconButton } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT,
  GET_SCHOOL_YEARS_QUERY,
  SchoolYear,
  useUpdateSchoolYearsMutation,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { Notification, useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import { usePrevious } from '@schooly/hooks/use-previous';
import {
  CheckIcon,
  CloseIcon,
  CrossIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  ModalSmall,
  PlusIcon,
  RollBackIcon,
} from '@schooly/style';
import { Spin } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { useQueryClient } from '@tanstack/react-query';
import { addDays, compareAsc, isAfter, isBefore, isWithinInterval, parseISO } from 'date-fns';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormProvider, get, useFieldArray, useForm, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { useRouter } from '../../../../context/router/useRouter';
import { useSchool } from '../../../../hooks/useSchool';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import { SchoolYearFormRow } from './SchoolYearFormRow';
import { getEmptySchoolYear, getSchoolYearEndDate, getSchoolYearFromGap } from './utils';

export type FormSchoolYear = Partial<SchoolYear & { originId: string }>;

export interface SchoolGeneralSchoolYearsForm {
  schoolYears?: FormSchoolYear[];
}

export const SchoolGeneralSchoolYearsModal: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { goBack } = useRouter();
  const { getConfirmation } = useConfirmationDialog();
  const { schoolId } = useSchool();
  const queryClient = useQueryClient();
  const { schoolYears, fetchingSilent, isFetching, refetch } = useSchoolYears();
  const form = useForm<SchoolGeneralSchoolYearsForm>({
    mode: 'onChange',
    defaultValues: {
      schoolYears: schoolYears.length
        ? [...schoolYears.map((y) => ({ ...y, originId: y.id }))]
        : [getEmptySchoolYear()],
    },
  });

  const updateSchoolYears = useUpdateSchoolYearsMutation();

  const schoolYearsValue = form.watch('schoolYears');
  const [refreshButtonShowed, showRefreshButton] = useFlag();

  const { fields, append, remove, replace, insert } = useFieldArray({
    control: form.control,
    name: 'schoolYears',
  });

  const [closedGapMessages, setClosedGapMessages] = useState<Record<string, boolean>>({});
  const [closeNoActiveYearMessages, setCloseNoActiveYearMessages] = useState(false);

  const prevFields = usePrevious(fields);

  const firstField = form.getValues('schoolYears.0');

  const shouldFocusLastItem = Boolean(
    fields.length === 1
      ? !firstField.name
      : prevFields && fields && fields.length - prevFields.length === 1,
  );

  const hasActualYear = Boolean(
    schoolYearsValue?.length &&
      schoolYearsValue
        .filter((field) => field.start)
        .some((field) => {
          const start = parseISO(field.start!);
          const end = getSchoolYearEndDate(start);

          return isWithinInterval(new Date(), { start, end });
        }),
  );

  const closestActualYearIndex = [...(schoolYearsValue ?? [])]
    .sort((a, b) => (a.start && b.start ? compareAsc(parseISO(a.start), parseISO(b.start)) : 0))
    .findIndex((year) => year.start && isAfter(parseISO(year.start), new Date()));

  const closestActualYearPastIndexTmp = [...(schoolYearsValue ?? [])]
    .sort((a, b) => (a.start && b.start ? compareAsc(parseISO(a.start), parseISO(b.start)) : 0))
    .findIndex(
      (year) => year.start && isBefore(getSchoolYearEndDate(parseISO(year.start)), new Date()),
    );

  const closestActualYearPastIndex =
    closestActualYearPastIndexTmp === -1
      ? -1
      : schoolYearsValue!.length - closestActualYearPastIndexTmp - 1;

  const handleClose = useCallback(async () => {
    if (updateSchoolYears.isLoading) {
      return;
    }

    if (
      form.formState.isDirty &&
      !(await getConfirmation({ textId: 'school-edit-CloseUnsavedConfirmation' }))
    ) {
      return;
    }

    goBack();
  }, [goBack, updateSchoolYears.isLoading, form.formState.isDirty, getConfirmation]);

  const addSchoolYear = useCallback(() => {
    append(getEmptySchoolYear());
  }, [append]);

  const deleteSchoolYear = useCallback(
    (index: number) => {
      if (fields.length > 1) {
        remove(index);
      } else {
        replace([getEmptySchoolYear()]);
      }
    },
    [fields.length, remove, replace],
  );

  const handleDelete = (index: number) => async () => {
    const year = form.getValues(`schoolYears.${index}`);

    if (year.name) {
      const hasRelatedRegistrations = !!year.in_use_info?.related_registrations;
      const isConfirmed = await getConfirmation({
        textId: hasRelatedRegistrations
          ? 'school-tabs-SchoolYears-DeleteConfirmation'
          : 'school-tabs-SchoolYears-SaveConfirmation',
        textValues: { name: year.name ?? '' },
        confirmTextId: 'yes',
        cancelTextId: 'no',
      });

      if (isConfirmed) {
        deleteSchoolYear(index);
      }
    } else {
      deleteSchoolYear(index);
    }
  };

  const handleSubmit = useCallback<SubmitHandler<SchoolGeneralSchoolYearsForm>>(
    async (data) => {
      if (!schoolId || !data.schoolYears) {
        return;
      }

      updateSchoolYears.mutate(
        {
          schoolId,
          schoolYears: data.schoolYears.map((year) => ({
            name: year.name,
            start: year.start,
            school_year_id: year.id || undefined, // BE doesn't accept empty string here
            end: moment(year.start).add(1, 'year').subtract(1, 'day').format(DEFAULT_DATE_FORMAT),
          })),
        },
        {
          onError: showError,
          onSuccess: () => {
            queryClient.invalidateQueries([GET_SCHOOL_YEARS_QUERY]);

            goBack();
          },
        },
      );
    },
    [goBack, queryClient, schoolId, showError, updateSchoolYears],
  );
  const handleRefresh = useCallback(async () => {
    refetch();
  }, [refetch]);

  // /* resets form state once actual schoolYears received */
  useEffect(() => {
    form.reset({
      schoolYears: schoolYears.length
        ? [...schoolYears.map((y) => ({ ...y, originId: y.id }))]
        : [getEmptySchoolYear()],
    });
  }, [form, schoolYears]);

  const renderNoActualYearMessage = () => {
    return (
      !hasActualYear &&
      !closeNoActiveYearMessages && (
        <Box sx={{ gridColumn: '1 / 5' }}>
          <Notification
            sx={{ px: 1.5 }}
            typographyProps={{ textAlign: 'left' }}
            notification={{
              textId: 'school-tabs-SchoolYears-NoActiveYear-Message',
              actions: [
                {
                  textId: 'school-tabs-SchoolYears-AddSchoolYear',
                  handler: addSchoolYear,
                },
                {
                  icon: <CloseIcon />,
                  handler: () => {
                    setCloseNoActiveYearMessages(true);
                  },
                },
              ],
            }}
          />
        </Box>
      )
    );
  };

  useEffect(() => {
    const clearNameValidationSubscription = form.watch(({ schoolYears }, { name: path }) => {
      if (!schoolYears || !path) return;

      const schoolYearName = form.getValues(path) as string | undefined;

      if (!schoolYearName) return;

      for (const [idx, field] of schoolYears.entries()) {
        const hasError = get(form.formState.errors, `schoolYears.${idx}.name`);

        if (!hasError || !field?.name) continue;

        const hasUniqueName = !schoolYears.some(
          (s, index) => index !== idx && !!s?.name && s.name === field.name,
        );

        if (hasUniqueName) {
          form.clearErrors(`schoolYears.${idx}.name`);
        }
      }
    });

    return clearNameValidationSubscription.unsubscribe;
  }, [$t, form]);

  return (
    <ModalSmall open onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ModalHeader title={$t({ id: 'school-tabs-SchoolYears' })} active>
            {refreshButtonShowed && (
              <IconButton onClick={handleRefresh}>
                {isFetching || fetchingSilent ? <Spin /> : <RollBackIcon />}
              </IconButton>
            )}

            <IconButton disabled={updateSchoolYears.isLoading} onClick={handleClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              {fetchingSilent ? (
                <Loading />
              ) : (
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gridTemplateColumns: `repeat(3, 1fr) ${theme.spacing(2.5)}`,
                    gridAutoRows: 'max-content',
                    rowGap: theme.spacing(2),
                    columnGap: theme.spacing(1),
                    height: '100%',
                  })}
                  gap={2}
                >
                  {closestActualYearIndex === -1 &&
                    closestActualYearPastIndex === -1 &&
                    renderNoActualYearMessage()}

                  {fields.map((field, index) => {
                    const startDateFormatted = form.getValues(`schoolYears.${index}.start`);
                    const startDate = startDateFormatted
                      ? newDateTimezoneOffset(startDateFormatted)
                      : undefined;

                    const checkFields = schoolYearsValue?.filter(
                      (year) => year.start && year.start !== startDateFormatted,
                    );

                    const prevField = schoolYearsValue?.[index - 1];

                    const isGap = Boolean(
                      index > 0 &&
                        checkFields?.length &&
                        startDate &&
                        !closedGapMessages[`${field.id}-${startDateFormatted}`] &&
                        !checkFields.some((field) => {
                          const prevStartDateFormatted = field.start!;
                          const prevStartDate = parseISO(prevStartDateFormatted);
                          const prevEndDate = getSchoolYearEndDate(prevStartDate);

                          return isWithinInterval(startDate, {
                            start: prevStartDate,
                            end: addDays(prevEndDate, 1),
                          });
                        }),
                    );

                    return (
                      <React.Fragment key={field.id}>
                        {closestActualYearIndex === index && renderNoActualYearMessage()}

                        {isGap && prevField?.start && (
                          <SchoolYearFormGap
                            index={index}
                            onInsertYear={insert}
                            onClose={() =>
                              setClosedGapMessages((value) => ({
                                ...value,
                                [`${field.id}-${startDateFormatted}`]: true,
                              }))
                            }
                          />
                        )}

                        <SchoolYearFormRow
                          yearField={field}
                          shouldFocusLastItem={shouldFocusLastItem}
                          fieldIndex={index}
                          onDelete={handleDelete(index)}
                          onShowRefreshButton={showRefreshButton}
                        />

                        {closestActualYearIndex === -1 &&
                          closestActualYearPastIndex === index &&
                          renderNoActualYearMessage()}
                      </React.Fragment>
                    );
                  })}

                  <Button variant="text" startIcon={<PlusIcon />} onClick={addSchoolYear}>
                    <FormattedMessage id="school-tabs-SchoolYears-AddSchoolYear" />
                  </Button>
                </Box>
              )}
            </ModalContent>
          </ModalMain>
          <ModalFooter sx={{ justifyContent: 'space-between' }} active>
            <Button variant="outlined" disabled={updateSchoolYears.isLoading} onClick={handleClose}>
              <FormattedMessage id="action-Cancel" />
            </Button>
            <Button
              type="submit"
              disabled={fetchingSilent || updateSchoolYears.isLoading}
              endIcon={updateSchoolYears.isLoading ? <Spin /> : <CheckIcon />}
            >
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalSmall>
  );
};

type SchoolYearFormGapProps = {
  index: number;
  onClose: () => void;
  onInsertYear: (idx: number, year: FormSchoolYear) => void;
};

const SchoolYearFormGap: FC<SchoolYearFormGapProps> = ({ index, onClose, onInsertYear }) => {
  const { watch } = useFormContext<SchoolGeneralSchoolYearsForm>();

  const schoolYearsValue = watch('schoolYears');

  const prevField = schoolYearsValue?.[index - 1];
  const nextField = schoolYearsValue?.[index];

  const schoolYear =
    !!prevField?.end && !!nextField?.start
      ? getSchoolYearFromGap(
          newDateTimezoneOffset(prevField.end),
          newDateTimezoneOffset(nextField.start),
        )
      : null;

  const defaultAction = {
    icon: <CloseIcon />,
    handler: onClose,
  };

  return (
    <Box sx={{ gridColumn: '1 / 5' }}>
      <Notification
        sx={{ px: 1.5 }}
        typographyProps={{ textAlign: 'left' }}
        notification={{
          textId: 'school-tabs-SchoolYears-Gap-Message',
          actions: schoolYear
            ? [
                {
                  textId: 'school-tabs-SchoolYears-AddSchoolYear',
                  handler: () => {
                    onInsertYear(index, schoolYear);
                  },
                },
                defaultAction,
              ]
            : [defaultAction],
        }}
      />
    </Box>
  );
};
