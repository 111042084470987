import {
  AgeGroup,
  GET_SCHOOL_PROPERTIES_QUERY,
  SchoolLevel,
  useUpdateAgeGroupsMutation,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { usePrevious } from '@schooly/hooks/use-previous';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { useCallback, useMemo, useRef, useState } from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import {
  FieldPath,
  FieldPathValue,
  SubmitHandler,
  useFieldArray,
  useForm,
  Validate,
} from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { useRouter } from '../../../../context/router/useRouter';
import { useSchool } from '../../../../hooks/useSchool';
import { queryClient } from '../../../../queryClient';

export const NO_LEVEL_DROPPABLE_ID = 'AgeGroupWithNoLevelDroppable';

export enum AgeGroupsDroppable {
  LEVEL = 'AgeGroupsDroppableLevel',
  AGEGROUP = 'AgeGroupsDroppableAgeGroup',
}
export interface AgeGroupInForm extends AgeGroup {
  id_tmp?: string;
}

interface SchoolLevelInForm extends SchoolLevel {
  ageGroups: AgeGroupInForm[];
  id_tmp?: string;
}

export interface SchoolGeneralAgeGroupsForm {
  schoolLevels: SchoolLevelInForm[];
  ageGroupsWithNoLevel: AgeGroupInForm[];
  archivedAgeGroups: AgeGroupInForm[];
}

export const getEmptyAgeGroup = (): AgeGroupInForm => ({
  id: '',
  name: '',
  archived: false,
  conduct_default: false,
  group_default: false,
  order: 0,
  staff_default: false,
  student_default: false,
  level_id: null,
  id_tmp: uuidv4(),
});

const getEmptySchoolLevel = (): SchoolLevelInForm => ({
  id: '',
  name: '',
  order: 0,
  id_tmp: uuidv4(),
  ageGroups: [],
});

export const useSchoolGeneralAgeGroupsModal = () => {
  const { schoolId } = useSchool();
  const { showError } = useNotifications();
  const { goBack } = useRouter();
  const { getConfirmation } = useConfirmationDialog();
  const { $t } = useIntl();

  const updateAgeGroups = useUpdateAgeGroupsMutation();
  const { isLoading: saving } = updateAgeGroups;

  const autoSwitchedArchivedOn = useRef(false);
  const [showArchived, setShowArchived] = useState(false);

  const {
    ageGroups: initAgeGroups,
    schoolLevels: initSchoolLevels,
    activeAgeGroups: initActiveAgeGroups,
    archivedAgeGroups: initArchivedAgeGroups,
    getAgeGroupsByLevelId,
  } = useAgeGroups({
    schoolId: schoolId!,
    userType: SchoolUserRole.Student,
  });

  const firstEmptyLevel = getEmptySchoolLevel();
  const defaultSchoolLevels = useMemo(() => {
    if (initAgeGroups?.length) {
      if (initSchoolLevels?.length) {
        return [
          ...initSchoolLevels?.map((l) => ({
            ...l,
            ageGroups: getAgeGroupsByLevelId(l.id),
          })),
        ];
      }
      return [];
    }
    return [
      {
        ...firstEmptyLevel,
        ageGroups: [{ ...getEmptyAgeGroup(), level_id: firstEmptyLevel.id_tmp }],
      },
    ];
  }, [initAgeGroups?.length, firstEmptyLevel, initSchoolLevels, getAgeGroupsByLevelId]);

  const form = useForm<SchoolGeneralAgeGroupsForm>({
    defaultValues: {
      schoolLevels: defaultSchoolLevels,
      ageGroupsWithNoLevel: initActiveAgeGroups?.filter((ageGroup) => !ageGroup.level_id),
      archivedAgeGroups: initArchivedAgeGroups,
    },
  });

  const hasErrors =
    form.formState.errors.schoolLevels || form.formState.errors.ageGroupsWithNoLevel;

  const schoolLevels = form.watch('schoolLevels');
  const ageGroupsWithNoLevel = form.watch('ageGroupsWithNoLevel');
  const archivedAgeGroups = form.watch('archivedAgeGroups');

  const getAllAgeGroups = useCallback(() => {
    const ageGroupsWithLevel: AgeGroupInForm[] = [];

    schoolLevels.forEach((level) => {
      if (level.ageGroups) {
        ageGroupsWithLevel.push(...level.ageGroups);
      }
    });

    return [...ageGroupsWithLevel, ...ageGroupsWithNoLevel, ...archivedAgeGroups];
  }, [ageGroupsWithNoLevel, archivedAgeGroups, schoolLevels]);

  const getActiveAgeGroupsCount = useCallback(
    () => getAllAgeGroups().filter((ageGroup) => !ageGroup.archived).length,
    [getAllAgeGroups],
  );

  const {
    move: moveSchoolLevel,
    remove: removeSchoolLevel,
    replace: replaceSchoolLevels,
    append: appendSchoolLevel,
  } = useFieldArray({
    control: form.control,
    name: 'schoolLevels',
  });
  const {
    append: appendAgeGroupWithNoLevel,
    replace: replaceAgeGroupsWithNoLevel,
    insert: insertAgeGroupWithNoLevel,
    move: moveAgeGroupWithNoLevel,
    remove: removeAgeGroupWithNoLevel,
  } = useFieldArray({
    control: form.control,
    name: 'ageGroupsWithNoLevel',
  });
  const { remove: removeArchivedAgeGroup } = useFieldArray({
    control: form.control,
    name: 'archivedAgeGroups',
  });

  //autofocus cases for age groups with no level
  const prevAgeGroupsWithNoLevel = usePrevious(ageGroupsWithNoLevel);
  const ageGroupAdded =
    prevAgeGroupsWithNoLevel &&
    ageGroupsWithNoLevel &&
    ageGroupsWithNoLevel.length - prevAgeGroupsWithNoLevel.length === 1;
  const shouldFocusAgeGroupInput = Boolean(ageGroupAdded);

  //autofocus cases for school levels
  const prevSchoolLevels = usePrevious(schoolLevels);
  const firstSchoolLevelField = form.getValues(`schoolLevels.${0}`);
  const schoolLevelAdded = prevSchoolLevels && schoolLevels.length - prevSchoolLevels.length === 1;
  const shouldFocusLevelInput = Boolean(
    schoolLevels.length === 1 ? !firstSchoolLevelField?.name : schoolLevelAdded,
  );

  const validateAgeGroup = useCallback<
    (
      id: string,
    ) => Validate<
      FieldPathValue<SchoolGeneralAgeGroupsForm, FieldPath<SchoolGeneralAgeGroupsForm>>,
      SchoolGeneralAgeGroupsForm
    >
  >(
    (id) => (value) => {
      // // Check for name uniqueness
      const ageGroups = getAllAgeGroups();
      for (let i = 0; i < (ageGroups?.length ?? 0); i++) {
        const ageGroup = ageGroups[i];
        const ageGroupId = ageGroup.id || ageGroup.id_tmp;
        if (ageGroupId === id) {
          continue;
        }

        if (ageGroup.name?.trim().toLowerCase() === `${value ?? ''}`.trim().toLowerCase()) {
          return $t({
            id: ageGroup.archived
              ? 'school-tabs-AgeGroups-ArchivedExists'
              : 'school-tabs-AgeGroups-ActiveExists',
          });
        }
      }

      return true;
    },
    [$t, getAllAgeGroups],
  );

  const validateSchoolLevel = useCallback<
    (
      index: number,
    ) => Validate<
      FieldPathValue<SchoolGeneralAgeGroupsForm, FieldPath<SchoolGeneralAgeGroupsForm>>,
      SchoolGeneralAgeGroupsForm
    >
  >(
    (index) => (value, formValues) => {
      // Check for name uniqueness
      for (let i = 0; i < (formValues.schoolLevels?.length ?? 0); i++) {
        if (i === index) {
          continue;
        }

        const field = formValues.schoolLevels![i];

        if (field.name?.trim().toLowerCase() === `${value ?? ''}`.trim().toLowerCase()) {
          return $t({
            id: 'school-tabs-AgeGroups-SchoolLevelExists',
          });
        }
      }

      return true;
    },
    [$t],
  );

  const handleClose = useCallback(async () => {
    if (saving) {
      return;
    }

    if (
      form.formState.isDirty &&
      !(await getConfirmation({ textId: 'school-edit-CloseUnsavedConfirmation' }))
    ) {
      return;
    }

    goBack();
  }, [goBack, saving, form.formState.isDirty, getConfirmation]);

  const addAgeGroup = useCallback(() => {
    appendAgeGroupWithNoLevel(getEmptyAgeGroup());
  }, [appendAgeGroupWithNoLevel]);

  const addSchoolLevel = useCallback(() => {
    appendSchoolLevel(getEmptySchoolLevel());
  }, [appendSchoolLevel]);

  const handleDeleteSchoolLevel = useCallback(
    (index: number) => {
      const ageGroupsWithoutLevel = form.getValues('ageGroupsWithNoLevel');
      const ageGroupsOfLevel = form.getValues(`schoolLevels.${index}.ageGroups`);
      const updatedAgeGroupsOfLevel = ageGroupsOfLevel.map((g) => ({ ...g, level_id: null }));

      replaceAgeGroupsWithNoLevel([...ageGroupsWithoutLevel, ...updatedAgeGroupsOfLevel]);
      removeSchoolLevel(index);

      if (hasErrors) {
        form.trigger();
      }
    },
    [form, hasErrors, removeSchoolLevel, replaceAgeGroupsWithNoLevel],
  );

  const handleRestoreAgeGroup = useCallback(
    (index: number) => async () => {
      const field = form.getValues(`archivedAgeGroups.${index}`);
      removeArchivedAgeGroup(index);
      appendAgeGroupWithNoLevel({ ...field, archived: false });
      if (hasErrors) {
        form.trigger();
      }
    },
    [appendAgeGroupWithNoLevel, form, hasErrors, removeArchivedAgeGroup],
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      const { destination, source, type } = result;

      // dropped outside the list
      if (!destination) {
        return;
      }
      // dropped to the same position
      if (destination.index === source.index && destination.droppableId === source.droppableId) {
        return;
      }

      //when moving level
      if (type === AgeGroupsDroppable.LEVEL) {
        moveSchoolLevel(source.index, destination.index);
      } else {
        //when moving age group
        if (
          //when moved inside age groups with no level
          source.droppableId === NO_LEVEL_DROPPABLE_ID &&
          destination.droppableId === NO_LEVEL_DROPPABLE_ID
        ) {
          moveAgeGroupWithNoLevel(source.index, destination.index);
        } else {
          const schoolLevels = form.getValues(`schoolLevels`) ?? [];
          const newLevelId =
            destination.droppableId === NO_LEVEL_DROPPABLE_ID ? null : destination.droppableId;

          const updatedSchoolLevels = [...(schoolLevels ?? [])];
          const sourceLevelIndex =
            source.droppableId !== NO_LEVEL_DROPPABLE_ID
              ? schoolLevels.findIndex((level) => {
                  const levelId = level.id || level.id_tmp;
                  return levelId === source.droppableId;
                })
              : -1;
          const destinationLevelIndex =
            destination.droppableId !== NO_LEVEL_DROPPABLE_ID
              ? schoolLevels.findIndex((level) => {
                  const levelId = level.id || level.id_tmp;
                  return levelId === destination.droppableId;
                })
              : -1;

          if (
            //when moved outside of level
            source.droppableId !== NO_LEVEL_DROPPABLE_ID &&
            destination.droppableId === NO_LEVEL_DROPPABLE_ID
          ) {
            const [removedAgeGroup] = updatedSchoolLevels[sourceLevelIndex].ageGroups.splice(
              source.index,
              1,
            );

            insertAgeGroupWithNoLevel(destination.index, {
              ...removedAgeGroup,
              level_id: newLevelId,
            });
            replaceSchoolLevels(updatedSchoolLevels);
          } else if (
            //when moved from one level to another
            source.droppableId !== NO_LEVEL_DROPPABLE_ID &&
            destination.droppableId !== NO_LEVEL_DROPPABLE_ID
          ) {
            const [removedAgeGroup] = updatedSchoolLevels[sourceLevelIndex].ageGroups.splice(
              source.index,
              1,
            );

            updatedSchoolLevels[destinationLevelIndex]?.ageGroups.splice(destination.index, 0, {
              ...removedAgeGroup,
              level_id: newLevelId,
            });

            replaceSchoolLevels(updatedSchoolLevels);
          } else {
            //when moved inside a level from age groups with no level
            const ageGroup = form.getValues(`ageGroupsWithNoLevel.${source.index}`);
            updatedSchoolLevels[destinationLevelIndex]?.ageGroups.splice(destination.index, 0, {
              ...ageGroup,
              level_id: newLevelId,
            });

            removeAgeGroupWithNoLevel(source.index);
            replaceSchoolLevels(updatedSchoolLevels);
          }
        }
      }
      if (hasErrors) {
        form.trigger();
      }
    },
    [
      hasErrors,
      form,
      replaceSchoolLevels,
      insertAgeGroupWithNoLevel,
      moveAgeGroupWithNoLevel,
      removeAgeGroupWithNoLevel,
      moveSchoolLevel,
    ],
  );

  const handleSubmit = useCallback<SubmitHandler<SchoolGeneralAgeGroupsForm>>(
    async (data) => {
      if (!schoolId) {
        return;
      }

      //levels without age groups should not be saved
      const schoolLevels = data.schoolLevels?.filter((level) => level.ageGroups.length) ?? [];

      const ageGroupsWithLevel: AgeGroupInForm[] = [];
      schoolLevels.forEach((level) => {
        if (level.ageGroups) {
          ageGroupsWithLevel.push(...level.ageGroups);
        }
      });
      const ageGroups = [
        ...ageGroupsWithLevel,
        ...data.ageGroupsWithNoLevel,
        ...data.archivedAgeGroups,
      ];

      updateAgeGroups.mutate(
        {
          schoolId,
          ageGroups: ageGroups.map((ageGroup, index) => ({
            id: ageGroup.id || undefined,
            name: ageGroup.name ?? '',
            order: index + 1,
            archived: !!ageGroup.archived,
            level_id: ageGroup.level_id || undefined,
          })),
          schoolLevels: schoolLevels.map((level, index) => ({
            id: level.id || undefined,
            id_tmp: level.id_tmp || undefined,
            name: level.name ?? '',
            order: index + 1,
          })),
        },
        {
          onSuccess: () => {
            // (TR-4609): Each property is single instance, but for both user types, so have to request both
            // Student and Staff properties after save. If request only for Students, the Staff one
            // will be not actual in a storage.
            queryClient.invalidateQueries([GET_SCHOOL_PROPERTIES_QUERY]);
            goBack();
          },
          onError: showError,
        },
      );
    },
    [goBack, schoolId, showError, updateAgeGroups],
  );

  return {
    showArchived,
    form,
    saving,
    autoSwitchedArchivedOn,
    shouldFocusAgeGroupInput,
    shouldFocusLevelInput,

    handleDragEnd,
    handleClose,
    addAgeGroup,
    handleSubmit,
    handleRestoreAgeGroup,
    addSchoolLevel,
    handleDeleteSchoolLevel,
    setShowArchived,
    validateAgeGroup,
    getAllAgeGroups,
    getActiveAgeGroupsCount,
    validateSchoolLevel,
  };
};
