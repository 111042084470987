import {
  ApiError,
  CreateEventRequest,
  CreateEventResponse,
  Event,
  EventsBulkChangeStatusRequest,
  EventsBulkChangeStatusResponse,
  EventsStatuses,
  RemoveEventRequest,
  RemoveEventResponse,
  removeObjectEmptyArrayValues,
  SignUp,
  SignUpFormRequest,
  SignUpType,
  UpdateEventRequest,
  UpdateEventResponse,
  useCreateConsentFormMutation,
  useCreateEventMutation,
  useCreateSignUpMutation,
  useEventsBulkStatusChangeMutation,
  useRemoveEventMutation,
  useUpdateEventMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import {
  RecurringConfirmOptions,
  RecurringConfirmType,
  RecurringLabel,
  useRecurringConfirmDialog,
} from '@schooly/components/recurring';
import { isDateInPast } from '@schooly/utils/date';
import { removeObjectUndefinedOrNullValues } from '@schooly/utils/remove-object-undefined-or-null-values';
import { MutateOptions } from '@tanstack/react-query';
import isEqual from 'lodash.isequal';
import { Dispatch, useState } from 'react';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { CreateConsentForm } from '../../components/common/ConsentForms/ConsentForm';
import { SignUpForm } from '../signUps/WithSignUp';
import { useEventNotifications } from './useEventNotifications';

export interface UseEventActionsReturn {
  creating: boolean;
  updating: boolean;
  publishing: boolean;
  deleting: boolean;

  currentStatus?: EventsStatuses;
  setCurrentStatus: Dispatch<React.SetStateAction<EventsStatuses | undefined>>;

  getEventStatus: (
    event?: Partial<Pick<Event, 'start' | 'end' | 'date_times' | 'event_status'>>,
  ) => {
    isStarted: boolean;
    isEnded: boolean;
    isActive: boolean;
    isPublished: boolean;
    hasStartDateError: boolean;
  };

  updateList: () => void;

  createSignUpForEvent: (
    eventId: Event['id'],
    event: Pick<Event, 'title' | 'description'>,
    signUp: SignUpForm,
  ) => Promise<{ id: SignUp['id'] } | undefined>;

  createEvent: (
    payload: Omit<CreateEventRequest, 'schoolId'> & {
      signUpPayload?: SignUpForm;
      consentFormPayload?: CreateConsentForm;
      withNotifications?: boolean;
    },
    options?: MutateOptions<CreateEventResponse, ApiError, CreateEventRequest>,
  ) => Promise<CreateEventResponse | undefined>;

  updateEvent: (
    payload: UpdateEventRequest & {
      originalEvent?: Event;
      signUpPayload?: SignUpForm;
      withNotifications?: boolean;
      consentFormPayload?: CreateConsentForm;
    },
    options?: MutateOptions<UpdateEventResponse, ApiError, UpdateEventRequest>,
  ) => Promise<UpdateEventResponse | undefined>;

  publishEvent:
    | ((
        payload: Omit<CreateEventRequest, 'schoolId'> & {
          signUpPayload?: SignUpForm;
          withNotifications?: boolean;
          consentFormPayload?: CreateConsentForm;
        },
        options?: MutateOptions<CreateEventResponse, ApiError, CreateEventRequest>,
      ) => Promise<EventsBulkChangeStatusResponse | undefined>)
    | ((
        payload: UpdateEventRequest & {
          originalEvent?: Event;
          consentFormPayload?: CreateConsentForm;
        },
        options?: MutateOptions<UpdateEventResponse, ApiError, UpdateEventRequest>,
      ) => Promise<EventsBulkChangeStatusResponse | undefined>);

  deleteEvent: (
    event?: Pick<Event, 'id' | 'title' | 'recurring_state' | 'consent_form'> & {
      withNotifications?: boolean;
    },
    options?: MutateOptions<RemoveEventResponse, ApiError, RemoveEventRequest>,
  ) => Promise<RemoveEventResponse | undefined>;

  submitAction: (
    params: Omit<EventsBulkChangeStatusRequest, 'schoolId'>,
  ) => Promise<EventsBulkChangeStatusResponse | undefined>;
}

export const useEventActions = (): UseEventActionsReturn => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { showEventNotification } = useEventNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const { getRecurringApplyTypeConfirm } = useRecurringConfirmDialog();

  const [currentStatus, setCurrentStatus] = useState<EventsStatuses>();

  const { schoolId = '' } = useAuth();

  const createEventMutation = useCreateEventMutation();
  const removeEventMutation = useRemoveEventMutation();
  const updateEventMutation = useUpdateEventMutation();
  const createSignUpMutation = useCreateSignUpMutation();
  const bulkStatusChangeMutation = useEventsBulkStatusChangeMutation();
  const createConsentFormMutation = useCreateConsentFormMutation();

  const invalidateSignUpQueries = useInvalidateListQueriesFor('signup');
  const invalidateEventQueries = useInvalidateListQueriesFor('event');

  const confirmUpdateModalOptions: RecurringConfirmOptions[] = useMemo(
    () => [
      {
        value: RecurringConfirmType.Single,
        label: $t({
          id: 'events-recurring-ThisEvent',
        }),
      },
      {
        value: RecurringConfirmType.CurrentAndFollowing,
        label: $t({
          id: 'events-recurring-ThisAndFollowingEvents',
        }),
      },
    ],
    [$t],
  );

  const confirmDeleteModalOptions: RecurringConfirmOptions[] = useMemo(
    () => [
      {
        value: RecurringConfirmType.Single,
        label: $t({
          id: 'events-recurring-ThisEvent',
        }),
      },
      {
        value: RecurringConfirmType.CurrentAndFollowing,
        label: $t({
          id: 'events-recurring-ThisAndFollowingDraftEvents',
        }),
      },
    ],
    [$t],
  );

  const updateList = useCallback(() => {
    invalidateSignUpQueries();
    invalidateEventQueries();
  }, [invalidateEventQueries, invalidateSignUpQueries]);

  const getEventStatus = useCallback<UseEventActionsReturn['getEventStatus']>((event) => {
    if (!event)
      return {
        isStarted: false,
        isEnded: false,
        isActive: false,
        isPublished: false,
        hasStartDateError: false,
      };

    const startTimeOfStartDate = event?.date_times?.[0]?.[0];
    const startTime = startTimeOfStartDate ?? null;
    const endTime = event.date_times?.[event.date_times?.length - 1]?.[1] ?? null;

    const isStarted = Boolean(event.start && isDateInPast(event.start, startTime));
    const isEnded = Boolean(event.end && isDateInPast(event.end, endTime));
    const isActive = Boolean(
      event.event_status === EventsStatuses.Published && isStarted && !isEnded,
    );
    const isPublished = event?.event_status === EventsStatuses.Published || isActive;
    const hasStartDateError = Boolean(
      event?.event_status === EventsStatuses.Draft &&
        event.start &&
        isDateInPast(event.start, startTimeOfStartDate ?? null),
    );

    return {
      isStarted,
      isEnded,
      isActive,
      isPublished,
      hasStartDateError,
    };
  }, []);

  const createSignUpForEvent = useCallback<UseEventActionsReturn['createSignUpForEvent']>(
    async (eventId, event, signUp) => {
      const params: SignUpFormRequest = {
        event_id: eventId,
        title: $t({ id: 'eventSignUps-create-TitlePreset' }, { title: event.title }),
        description: event.description,
        type: signUp.type!,
        end: signUp.end,
      };

      switch (params.type) {
        case SignUpType.Regular:
          params.places = signUp.places;
          break;
        case SignUpType.Slots:
          params.duration = signUp.duration;
          break;
      }

      try {
        return await createSignUpMutation.mutateAsync({ ...params, schoolId });
      } catch (error) {
        showError(error as ApiError);
      }
    },
    [$t, createSignUpMutation, schoolId, showError],
  );

  const createEvent = useCallback<UseEventActionsReturn['createEvent']>(
    async (
      { signUpPayload, withNotifications = true, consentFormPayload, ...payload },
      options,
    ) => {
      if (!schoolId) {
        return;
      }

      const e = removeObjectUndefinedOrNullValues(payload);

      const { groups, ...criteria } = payload.criteria;

      const params: CreateEventRequest = {
        schoolId,
        ...e,
        criteria: removeObjectEmptyArrayValues({
          ...criteria,
          group_ids: groups?.map(({ id }) => id),
        }),
      };

      try {
        const hasConsentForm = consentFormPayload && !payload.consent_form;
        const result = await createEventMutation.mutateAsync(
          params,
          hasConsentForm ? undefined : options,
        );

        if (!result?.id) return;

        const signUpResponse =
          !!signUpPayload && !payload.recurring_state
            ? await createSignUpForEvent(result.id, payload, signUpPayload)
            : null;

        if (hasConsentForm) {
          // If user created event with sign up we connect consent form to sign up
          await createConsentFormMutation.mutateAsync({
            reference_id: signUpResponse ? signUpResponse.id : result.id,
            reference_type: signUpResponse ? 'signup' : 'event',
            description: consentFormPayload.description,
          });
        }

        const eventsCount = result.event_count ?? 0;

        if (withNotifications) {
          showEventNotification({
            count: eventsCount,
            id: result.id,
            title: payload.title,
            textId: 'events-notification-Created',
            multipleTextId: 'events-notification-CreatedMultiple',
            recurrenceId: result.recurrence_id,
          });
        }

        updateList();

        return result;
      } catch (error) {
        showError(error as ApiError);
      }
    },
    [
      createConsentFormMutation,
      createEventMutation,
      createSignUpForEvent,
      schoolId,
      showError,
      showEventNotification,
      updateList,
    ],
  );

  const getEventFormState = useCallback(
    (
      form: Pick<Event, 'start' | 'end' | 'recurring_state'> & Partial<Event>,
      original?: Pick<Event, 'start' | 'end' | 'recurring_state'> & Partial<Event>,
    ) => {
      const payloadWithoutRecurringAndDates = {
        title: form.title,
        description: form.description,
        date_times: form.date_times,
        invitee_type: form.invitee_type,
        can_be_edited: form.can_be_edited,
        criteria: form.criteria,
        event_type: form.event_type,
      };

      const originalWithoutRecurringAndDates = original
        ? {
            title: original.title,
            description: original.description,
            date_times: original.date_times,
            invitee_type: original.invitee_type,
            can_be_edited: original.can_be_edited,
            criteria: original.criteria,
            event_type: original.event_type,
          }
        : {};

      const recurringStateIsChanged =
        original && !isEqual(original.recurring_state, form.recurring_state);
      const onlyDateIsChanged =
        original && original.start !== form.start && !recurringStateIsChanged;
      const initFollowingCount = original?.recurring_state?.following_count ?? 0;
      const isSingleDateSelected = form.start && form.start === form.end;
      const currentFollowingCount =
        isSingleDateSelected && form.recurring_state?.following_count
          ? form.recurring_state.following_count
          : 0;
      const followingCount = recurringStateIsChanged ? currentFollowingCount : initFollowingCount;
      const hasFollowingEvents = Boolean(followingCount);
      const followingEventsChanged = Boolean(hasFollowingEvents && initFollowingCount);
      const eventDetailsChanged = !isEqual(
        originalWithoutRecurringAndDates,
        payloadWithoutRecurringAndDates,
      );
      const showRecurringChangeModal =
        followingEventsChanged && (onlyDateIsChanged || eventDetailsChanged);
      const warningTitle =
        recurringStateIsChanged && initFollowingCount && hasFollowingEvents
          ? $t({
              id: 'events-recurring-RepetitionChangesWarning',
            })
          : undefined;

      return {
        recurringStateIsChanged,
        onlyDateIsChanged,
        initFollowingCount,
        isSingleDateSelected,
        currentFollowingCount,
        followingCount,
        hasFollowingEvents,
        followingEventsChanged,
        eventDetailsChanged,
        showRecurringChangeModal,
        warningTitle,
      };
    },
    [$t],
  );

  const updateEvent = useCallback<UseEventActionsReturn['updateEvent']>(
    async (
      { originalEvent, withNotifications = true, signUpPayload, consentFormPayload, ...payload },
      { onSuccess, ...options } = {},
    ) => {
      if (!payload.id) {
        return;
      }

      const { isActive, isPublished } = getEventStatus(originalEvent);

      const { initFollowingCount, followingCount, showRecurringChangeModal, warningTitle } =
        getEventFormState(payload, originalEvent);

      let withFollowing = payload.withFollowing;

      if (withFollowing === undefined && showRecurringChangeModal) {
        const applyType = await getRecurringApplyTypeConfirm({
          options: confirmUpdateModalOptions,
          title: $t(
            {
              id: isPublished ? 'events-recurring-Update' : 'events-recurring-Save',
            },
            {
              eventTitle: payload.title ? `"${payload.title}"` : '',
            },
          ),
          subtitle: payload.recurring_state && (
            <RecurringLabel recurringState={payload.recurring_state} />
          ),
          actionButtonTextId: isPublished ? 'action-Update' : 'action-Save',
          warningTitle,
        });

        if (!applyType) return;

        withFollowing = applyType?.type === RecurringConfirmType.CurrentAndFollowing;
      }

      const notifyParents =
        payload.notify_parents !== undefined
          ? payload.notify_parents
          : isActive
          ? await getConfirmation({
              textId: 'events-notification-NotifyAfterUpdate',
            })
          : false;

      const confirmed =
        payload.confirmed !== undefined
          ? payload.confirmed
          : !(initFollowingCount && followingCount);
      let eventsCount = 0;

      try {
        const { can_be_edited, event_status, sign_ups, consent_form, ...data } = payload as Event;
        // TODO: why do we rename the criteria.groups field in the CreateEvent modal? It produces
        //  a lot of confusion and inconvenience
        const { group, groups, ...criteria } = data.criteria as Event['criteria'] & {
          group: Event['criteria']['groups'];
        };

        const params = {
          ...data,
          invitee_type: data.invitee_type ?? undefined, // BE can not correctly handle NULL value here
          criteria: { ...criteria, group_ids: (group ?? groups ?? []).map((group) => group.id) },
          notify_parents: notifyParents,
          confirmed,
          withFollowing,
        };

        let result = await updateEventMutation.mutateAsync(params, options);

        if (!result) {
          return;
        }

        if (withFollowing) {
          eventsCount = result.event_count ?? 0;

          if (result?.will_overwrite) {
            const confirmed = await getConfirmation({
              textId: 'events-recurring-OverwriteConfirmation',
            });

            if (!confirmed) {
              return;
            }

            result = await updateEventMutation.mutateAsync(params, options);

            if (!result) return;

            eventsCount = result.event_count ?? 0;
          }
        }

        const existingSignUp = sign_ups?.[0];
        let createdSignUpId: string | undefined = undefined;

        if (signUpPayload && !existingSignUp && !payload.recurring_state) {
          const createSignUpResponse = await createSignUpForEvent(
            payload.id,
            payload,
            signUpPayload,
          );
          createdSignUpId = createSignUpResponse?.id;
        }

        if (consentFormPayload && !consent_form && !existingSignUp?.consent_form) {
          await createConsentFormMutation.mutateAsync({
            reference_id: existingSignUp?.id || createdSignUpId || payload.id,
            reference_type: existingSignUp || createdSignUpId ? 'signup' : 'event',
            description: consentFormPayload.description,
          });
        }

        onSuccess?.(result, params, undefined);
        updateList();

        if (payload.recurring_state) {
          const recurrenceId = payload?.recurring_state.recurrence_id;

          if (withNotifications) {
            showEventNotification({
              count: eventsCount,
              id: payload.id,
              title: payload.title,
              textId: isPublished ? 'events-notification-Updated' : 'events-notification-Saved',
              multipleTextId: isPublished
                ? 'events-notification-UpdatedMultiple'
                : 'events-notification-SavedMultiple',
              recurrenceId,
            });
          }
        }

        return result;
      } catch (e) {
        showError(e as ApiError);
      }
    },
    [
      $t,
      confirmUpdateModalOptions,
      createConsentFormMutation,
      createSignUpForEvent,
      getConfirmation,
      getEventFormState,
      getEventStatus,
      getRecurringApplyTypeConfirm,
      showError,
      showEventNotification,
      updateEventMutation,
      updateList,
    ],
  );

  const deleteEvent = useCallback<UseEventActionsReturn['deleteEvent']>(
    async (event, options) => {
      if (!event?.id) return;

      let withFollowing;
      const hasFollowingEvents = Boolean(event.recurring_state?.following_count);

      if (hasFollowingEvents) {
        const applyType = await getRecurringApplyTypeConfirm({
          options: confirmDeleteModalOptions,
          title: $t(
            {
              id: 'events-recurring-Delete',
            },
            {
              eventTitle: event.title ? `"${event.title}"` : '',
            },
          ),
          subtitle: event.recurring_state && (
            <RecurringLabel recurringState={event.recurring_state} />
          ),
          actionButtonTextId: 'action-Delete',
        });
        if (!applyType) return;

        withFollowing = applyType?.type === RecurringConfirmType.CurrentAndFollowing;
      }

      try {
        const res = await removeEventMutation.mutateAsync({ id: event.id, withFollowing }, options);

        if (!res) {
          return;
        }

        updateList();

        if (event?.recurring_state) {
          const recurrenceId = event.recurring_state.recurrence_id;
          const eventsCount = res?.event_count ?? 0;

          if (event.withNotifications !== false) {
            showEventNotification({
              count: eventsCount,
              id: event.id,
              title: event.title,
              textId: 'events-notification-Deleted',
              multipleTextId: 'events-notification-DeletedMultiple',
              recurrenceId,
              showActionButton: false,
            });
          }
        }

        return res;
      } catch (error) {
        showError(error as ApiError);
      }
    },
    [
      $t,
      confirmDeleteModalOptions,
      getRecurringApplyTypeConfirm,
      removeEventMutation,
      showError,
      showEventNotification,
      updateList,
    ],
  );

  const publishEvent = useCallback<UseEventActionsReturn['publishEvent']>(
    async (
      payload:
        | Parameters<UseEventActionsReturn['createEvent']>[0]
        | Parameters<UseEventActionsReturn['updateEvent']>[0],
      options?:
        | Parameters<UseEventActionsReturn['createEvent']>[1]
        | Parameters<UseEventActionsReturn['updateEvent']>[1],
    ) => {
      if (Object.values(payload.criteria).every((v) => !v.length)) {
        return;
      }

      const { recurring_state: recurringState, withNotifications = true } = payload;
      const { followingCount, initFollowingCount, warningTitle } = getEventFormState(
        payload,
        'originalEvent' in payload ? payload.originalEvent : undefined,
      );

      let withFollowing;
      const hasFollowingEvents = Boolean(payload.recurring_state?.following_count);

      if (hasFollowingEvents) {
        const applyType = await getRecurringApplyTypeConfirm({
          options: confirmUpdateModalOptions,
          title: $t(
            {
              id: 'events-recurring-Publish',
            },
            {
              eventTitle: payload.title ? `"${payload.title}"` : '',
            },
          ),
          subtitle: recurringState && <RecurringLabel recurringState={recurringState} />,
          actionButtonTextId: 'action-Publish',
          warningTitle,
        });

        if (!applyType) return;

        withFollowing = applyType.type === RecurringConfirmType.CurrentAndFollowing;
      }

      const notifyParents = await getConfirmation({
        textId: 'events-notification-NotifyAfterAction',
        textValues: {
          action: 'publishing',
        },
      });

      let confirmed = !(initFollowingCount && followingCount);
      let publishedEventCount = 0;

      let result: CreateEventResponse | UpdateEventResponse | undefined;

      if ((payload as Parameters<UseEventActionsReturn['updateEvent']>[0]).id) {
        result = await updateEvent(
          {
            ...(payload as Parameters<UseEventActionsReturn['updateEvent']>[0]),
            withFollowing,
            notify_parents: notifyParents,
          },
          options as MutateOptions<UpdateEventResponse, ApiError, UpdateEventRequest>,
        );

        if ((result as UpdateEventResponse)?.will_overwrite) {
          confirmed = await getConfirmation({
            textId: 'events-recurring-OverwriteConfirmation',
          });

          if (!confirmed) {
            return;
          }
        }
      } else {
        result = await createEvent(
          payload,
          options as MutateOptions<CreateEventResponse, ApiError, CreateEventRequest>,
        );
      }

      if (!result) {
        return;
      }

      try {
        const publishActionResult = await bulkStatusChangeMutation.mutateAsync({
          schoolId,
          action: 'publish',
          event_ids: [
            (payload as Parameters<UseEventActionsReturn['updateEvent']>[0])?.id ?? result.id,
          ],
          notify_parents: notifyParents,
          withFollowing,
        });

        if (!publishActionResult) {
          return;
        }

        publishedEventCount = publishActionResult.event_count ?? 0;

        const recurrenceId =
          payload.recurring_state?.recurrence_id ?? (result as CreateEventResponse)?.recurrence_id;

        updateList();
        setCurrentStatus(EventsStatuses.Published);

        if (withNotifications) {
          showEventNotification({
            count: publishedEventCount,
            id: result.id,
            title: payload.title,
            textId: 'events-notification-Published',
            multipleTextId: 'events-notification-PublishedMultiple',
            recurrenceId,
          });
        }

        return publishActionResult;
      } catch (e) {
        showError(e as ApiError);
      }
    },
    [
      $t,
      bulkStatusChangeMutation,
      confirmUpdateModalOptions,
      createEvent,
      getConfirmation,
      getEventFormState,
      getRecurringApplyTypeConfirm,
      schoolId,
      showError,
      showEventNotification,
      updateEvent,
      updateList,
    ],
  );

  const submitAction = useCallback<UseEventActionsReturn['submitAction']>(
    async (params) => {
      if (!schoolId) return;
      try {
        const res = await bulkStatusChangeMutation.mutateAsync({ schoolId, ...params });
        switch (params.action) {
          case 'cancel':
            setCurrentStatus(EventsStatuses.Canceled);
            break;
          case 'publish':
            setCurrentStatus(EventsStatuses.Published);
            break;

          default:
            throw new Error(params.action);
        }
        return res;
      } catch (error) {
        showError(error as ApiError);
      }
    },
    [bulkStatusChangeMutation, schoolId, showError],
  );

  const creating =
    createSignUpMutation.isLoading ||
    createEventMutation.isLoading ||
    createConsentFormMutation.isLoading;
  const updating = updateEventMutation.isLoading || createConsentFormMutation.isLoading;
  const publishing = creating || updating || bulkStatusChangeMutation.isLoading;
  const deleting = removeEventMutation.isLoading;

  return {
    creating,
    updating,
    publishing,
    deleting,

    getEventStatus,
    currentStatus,
    setCurrentStatus,

    updateList,

    createSignUpForEvent,
    createEvent,
    updateEvent,
    publishEvent,
    deleteEvent,
    submitAction,
  };
};
