import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    height="20"
    width="20"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M5 10v8c0 .5522847-.44771525 1-1 1s-1-.4477153-1-1v-7H2c-.89090485 0-1.33707164-1.07714192-.70710678-1.70710678l8.99999998-9c.3905243-.3905243 1.0236893-.3905243 1.4142136 0l9 9C21.3370716 9.92285808 20.8909049 11 20 11h-1v7c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-8c0-.4288091.2699006-.79457955.6490884-.93669807L11 2.41421356 4.35091163 9.06330193C4.73009945 9.20542045 5 9.5711909 5 10zm4.5 8c0 .5522847-.44771525 1-1 1s-1-.4477153-1-1v-5c0-.2652165.10535684-.5195704.29289322-.7071068C8.6502869 11.4354995 9.73903575 11 11 11c1.2609642 0 2.3497131.4354995 3.2071068 1.2928932C14.3946432 12.4804296 14.5 12.7347835 14.5 13v5c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-4.5452571C12.0835596 13.1492287 11.5937842 13 11 13s-1.08355957.1492287-1.5.4547429V18z"
        id="dashboard"
      />
    </defs>
    <use xlinkHref="#dashboard" transform="translate(1 2)" />
  </svg>
);
