import { Box, Icon, Stack, Tooltip } from '@mui/material';
import { SettingsIcon, SimpleButton, Spin } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isAfter, isWithinInterval } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export type RolloverProps<T> = { yearFrom: T; yearTo: T };

type RolloverButtonProps<T> = {
  onRollover: (data: RolloverProps<T>) => void;
  schoolYears: T[];
  selectedDate: string;
  defaultSchoolYear?: T & { isActual?: boolean };
  canEditYears: boolean;
  isFetching: boolean;
  onYearsEdit: () => void;
};

export const RolloverButton = <T extends { id: string; start: string; end: string }>({
  onRollover,
  schoolYears,
  selectedDate,
  defaultSchoolYear,
  canEditYears,
  onYearsEdit,
  isFetching,
}: RolloverButtonProps<T>) => {
  const { $t } = useIntl();
  const currentDate = newDateTimezoneOffset(selectedDate);

  const { rolloverStartYear, rolloverEndYear } = useMemo(() => {
    let rolloverStartYear: T | null = null;
    let rolloverEndYear: T | null = null;
    if (!defaultSchoolYear) return { rolloverStartYear, rolloverEndYear };

    for (const [idx, year] of schoolYears.entries()) {
      const startDate = newDateTimezoneOffset(year.start);
      const endDate = newDateTimezoneOffset(year.end);

      const isCurrentYear = isWithinInterval(currentDate, {
        start: startDate,
        end: endDate,
      });

      if (!isCurrentYear) continue;

      rolloverStartYear = year;
      rolloverEndYear = schoolYears[idx + 1] ?? null;
    }

    return { rolloverStartYear, rolloverEndYear };
  }, [currentDate, defaultSchoolYear, schoolYears]);

  const isCurrentYearSelected = Boolean(
    defaultSchoolYear?.id && defaultSchoolYear.id === rolloverStartYear?.id,
  );
  const isPreviousYearSelected = !!rolloverEndYear && rolloverEndYear.id === defaultSchoolYear?.id;
  const isFutureYearSelected = Boolean(
    rolloverStartYear && defaultSchoolYear
      ? isAfter(
          newDateTimezoneOffset(rolloverStartYear.start),
          newDateTimezoneOffset(defaultSchoolYear.end),
        )
      : null,
  );

  const isStartYearSelected = isCurrentYearSelected || isPreviousYearSelected;

  const editYears = useMemo(
    () => (
      <Stack gap={1}>
        <FormattedMessage id="students-AnnualRollover-NoNextSchoolYearWithPermissions" />
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={onYearsEdit}
        >
          <Icon>
            <SettingsIcon />
          </Icon>
          <FormattedMessage id="school-schoolYears-Managing" />
        </Stack>
      </Stack>
    ),
    [onYearsEdit],
  );

  const noYearSelectedText = !rolloverStartYear
    ? $t({ id: 'students-AnnualRollover-NoYearSelected' })
    : '';

  const previousYearSelectedText =
    !isStartYearSelected && !isFutureYearSelected
      ? $t({ id: 'students-AnnualRollover-PreviousSchoolYear' })
      : '';

  const manageYearsText = canEditYears
    ? editYears
    : $t({ id: 'students-AnnualRollover-NoNextSchoolYearWithoutPermissions' });

  const futureYearSelectedText = isFutureYearSelected
    ? $t({ id: 'students-AnnualRollover-FutureYearSelected' })
    : '';

  const noNextYearSelectedText = !rolloverEndYear ? manageYearsText : '';

  const onRolloverStart = useCallback(() => {
    if (!rolloverStartYear || !rolloverEndYear) return;

    onRollover({ yearFrom: rolloverStartYear, yearTo: rolloverEndYear });
  }, [onRollover, rolloverEndYear, rolloverStartYear]);

  const disabled = isFutureYearSelected || !isStartYearSelected || !rolloverEndYear;

  return (
    <Tooltip
      arrow
      title={
        noYearSelectedText ||
        futureYearSelectedText ||
        previousYearSelectedText ||
        noNextYearSelectedText
      }
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            padding: theme.spacing(1.25),
          }),
        },
      }}
    >
      <Box>
        <SimpleButton
          disabled={disabled}
          startIcon={isFetching ? <Spin /> : undefined}
          onClick={onRolloverStart}
        >
          {$t({
            id: 'students-AnnualRollover',
          })}
        </SimpleButton>
      </Box>
    </Tooltip>
  );
};
