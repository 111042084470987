import {
  DEFAULT_DATE_FORMAT_FNS,
  DefaultSchoolYear,
  FilterKeys,
  FilterSection,
  GetReportsQueryFilters,
  REPORTS_QUERY_FILTER_KEYS,
  ReportStatuses,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  DateRangeDropdown,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  ReportStatusTagSelect,
  UserTagSelect,
} from '@schooly/components/filters';
import {
  FilterDropdown,
  FiltersContainer,
  MoreButton,
  ReportStatusExpandedSelect,
  UserExpandedSelect,
} from '@schooly/components/filters';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

type ReportsFiltersProps = {
  schoolId: string;
  filters: GetReportsQueryFilters;
  defaultFilters: GetReportsQueryFilters;
  onSetFilters: (v: GetReportsQueryFilters) => void;
  notActualInitialDate?: boolean;
  defaultSchoolYear?: DefaultSchoolYear;
  defaultUserFilters: GetReportsQueryFilters;
};

export const ReportsFilters: FC<ReportsFiltersProps> = ({
  defaultFilters,
  onSetFilters,
  filters: actualFilters,
  schoolId,
  notActualInitialDate,
  defaultSchoolYear,
  defaultUserFilters,
}) => {
  const { $t } = useIntl();
  const [dateChanged, setDateChanged] = useState(false);

  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetReportsQueryFilters>(actualFilters);
  const { currentStaff } = useAuth();
  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    setDraftFilters((filters) => ({ ...filters, [v]: filters[v] !== undefined ? undefined : [] }));
  }, []);

  useEffect(() => {
    setDraftFilters(actualFilters);
  }, [actualFilters]);

  const handleApply = useMemo(() => {
    if (
      !REPORTS_QUERY_FILTER_KEYS.some((key) => {
        const draftFiltersForKey = [...(draftFilters[key] || [])];
        const actualFiltersForKey = [...(actualFilters[key] || [])];

        return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
      })
    )
      return undefined;

    return () => {
      onSetFilters(draftFilters);
    };
  }, [onSetFilters, actualFilters, draftFilters]);

  const dateFilter = useMemo(() => ({ [FilterKeys.Date]: draftFilters.date }), [draftFilters.date]);

  const { onSetDate, onSelectStaffId, onClearStaff } = useMemo(() => {
    const updateFilter = (key: FilterKeys.Staff) => (id: string) => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: filters[key]?.includes(id)
          ? filters[key]?.filter((ct) => ct !== id)
          : [...(filters[key] || []), id],
      }));
    };
    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    return {
      onSetDate: (v: [Date, Date]) =>
        setDraftFilters((filters) => {
          const updatedDates = [
            format(v[0], DEFAULT_DATE_FORMAT_FNS),
            format(v[1], DEFAULT_DATE_FORMAT_FNS),
          ];

          if (updatedDates.join('') === filters.date?.join('')) return filters;

          setDateChanged(true);
          return {
            ...filters,
            [FilterKeys.Date]: updatedDates,
          };
        }),
      onSelectStaffId: updateFilter(FilterKeys.Staff),
      onClearStaff: clearFilter(FilterKeys.Staff),
    };
  }, []);

  const { onSelectStatus, onClearStatus } = useMemo(() => {
    return {
      onSelectStatus: (id: ReportStatuses) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.ReportStatus]: filters[FilterKeys.ReportStatus]?.includes(id)
            ? filters[FilterKeys.ReportStatus]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.ReportStatus] || []), id],
        })),
      onClearStatus: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.ReportStatus]: [],
        })),
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    setDraftFilters(defaultFilters);
  }, [defaultFilters]);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleResetToDefault = useCallback(() => {
    setDraftFilters(defaultUserFilters);
  }, [defaultUserFilters]);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  const filtersDate = draftFilters.date;
  const filtersStatus = draftFilters.report_status;
  const filtersStaff = draftFilters.staff;

  const statusLabel = $t({ id: 'assessments-Status' });
  const dateLabel = $t({ id: 'schoolProperty-Period' });
  const staffLabel = $t({ id: 'schoolProperty-Staff' });

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        onSaveFilter={handleSaveFilter}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        schoolId={schoolId}
        section={FilterSection.Report}
        filters={draftFilters}
        defaultSchoolYear={defaultSchoolYear}
        onSetFilters={(v) => {
          onSetFilters(pickOnlyParamsFromFilterKeys(REPORTS_QUERY_FILTER_KEYS, v));
        }}
      />
      {filtersDate && (
        <DateRangeDropdown
          schoolId={schoolId}
          date={filtersDate}
          dateChanged={dateChanged}
          onSetDate={onSetDate}
          notActualInitialDate={notActualInitialDate}
          openLabel={dateLabel}
          defaultSchoolYear={defaultSchoolYear}
        />
      )}
      {filtersStatus && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.ReportStatus)}
          label={statusLabel}
          tags={(open) =>
            filtersStatus.map((status) => (
              <ReportStatusTagSelect
                key={status}
                sx={{ maxWidth: 200 }}
                status={status}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <ReportStatusExpandedSelect
              selectedStatuses={filtersStatus}
              onSelectStatus={onSelectStatus}
              onClose={onClose}
              onClear={onClearStatus}
            />
          )}
        </FilterDropdown>
      )}
      {filtersStaff && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Staff)}
          label={staffLabel}
          tags={(open) =>
            filtersStaff.map((id) => (
              <UserTagSelect
                userType="staff"
                currentUser={currentStaff}
                sx={{ maxWidth: 200 }}
                schoolId={schoolId}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <UserExpandedSelect
              type="staff"
              schoolId={schoolId}
              currentUser={currentStaff}
              selectedIds={filtersStaff}
              onSelectUserId={onSelectStaffId}
              onClose={onClose}
              onClear={onClearStaff}
              filters={dateFilter}
            />
          )}
        </FilterDropdown>
      )}
      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={[
          { value: FilterKeys.Date, label: dateLabel, required: true },
          { value: FilterKeys.ReportStatus, label: statusLabel },
          { value: FilterKeys.Staff, label: staffLabel },
        ]}
        selectedOptions={REPORTS_QUERY_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        onToggleOption={toggleFiltersVisible}
      />
    </FiltersContainer>
  );
};
