import { listRelations } from '@schooly/api';
import { SchoolRelation } from '@schooly/api';
import { BaseUser } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import debounce from 'lodash.debounce';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { FilterType } from '../../../../context/filters/scheme';
import { useFilters } from '../../../../context/filters/useFilters';
import { getRouteModalPathname, getUserTypeTextId } from '../../../../helpers/misc';
import useAppLocation from '../../../../hooks/useAppLocation';
import { MIN_REMOTE_QUERY_LENGTH } from '../../../../hooks/usePagedApiResourceWithFilter';
import usePrevious from '../../../../hooks/usePrevious';
import buildClassName from '../../../../utils/buildClassName';
import updateCancelToken from '../../../../utils/updateCancelToken';
import PeopleList from '../../PeopleList';
import { HeaderSearchFooter } from './HeaderSearchFooter';

export interface IHeaderSearchPeopleListProps {
  isOptionsResultEmpty?: boolean;
}

const cancelTokenSource = {};

export const MAX_SEARCH_RESULT_COUNT = 3;
export const SEARCH_DEBOUNCE_WAIT = 300;

export const HeaderSearchPeopleList: FC<IHeaderSearchPeopleListProps> = ({
  isOptionsResultEmpty,
}) => {
  const navigate = useNavigate();
  const location = useAppLocation();
  const { schoolId } = useAuth();
  const [users, setUsers] = useState<SchoolRelation[]>([]);
  const [fetching, setFetching] = useState(false);
  const { filters, filterType } = useFilters();

  const prevQuery = usePrevious(filters.draftQuery);

  const userType = useMemo(() => {
    switch (filterType) {
      case FilterType.Parent:
        return filterType;
      default:
        return 'student';
    }
  }, [filterType]);

  const request = useCallback(
    async (query: string) => {
      if (!schoolId || !userType) {
        return;
      }

      if (query.length < MIN_REMOTE_QUERY_LENGTH) {
        return;
      }

      setFetching(true);
      setUsers([]);

      // Don't specify pageSize as the network response return a list of relations instead of users.
      // So the eventual computed list of users might be shorter.
      // Will cut first MAX_SEARCH_RESULT_COUNT users afterwards.
      const response = await listRelations({
        schoolId,
        type: userType,
        query,
        filters: filters.applied,
        token: updateCancelToken(cancelTokenSource),
      });

      setUsers(response ? response.results.slice(0, MAX_SEARCH_RESULT_COUNT) : []);
      setFetching(false);
    },
    [filters.applied, schoolId, userType],
  );

  const requestDebounced = useMemo(
    () =>
      debounce(request, SEARCH_DEBOUNCE_WAIT, {
        leading: false,
        trailing: true,
      }),
    [request],
  );

  useEffect(() => {
    if (prevQuery !== filters.draftQuery) {
      // set fetching state before debounce as the debounce is being called with a delay
      setFetching(true);
      setUsers([]);

      requestDebounced(filters.draftQuery);
    }
  }, [filters.draftQuery, prevQuery, requestDebounced]);

  const handleUserSelect = useCallback(
    (user: SchoolRelation | BaseUser) => {
      navigate(getRouteModalPathname(userType, user), {
        state: { backgroundLocation: location },
      });
    },
    [userType, navigate, location],
  );

  if (!fetching && !users.length && isOptionsResultEmpty) {
    return (
      <h3 className="no-results">
        <FormattedMessage id="filter-NoResultsMatching" values={{ filter: filters.draftQuery }} />
      </h3>
    );
  }

  const userTypeText = (
    <span className="text-lowercase">
      <FormattedMessage id={getUserTypeTextId(userType, true)} />
    </span>
  );

  return (
    <>
      {(fetching || users.length || isOptionsResultEmpty) && (
        <div
          className={buildClassName(
            'filters-section',
            'filters-section__people',
            'pr-2 pl-2',
            !fetching && !users.length && 'PeopleList-empty__hidden',
          )}
        >
          <h4 className="mb-1 pr-2 pl-2">
            <FormattedMessage id="section-People" />
          </h4>
          <PeopleList
            users={users}
            isFetching={fetching}
            onUserSelect={handleUserSelect}
            userType={userType}
            highlightSearchString={filters.draftQuery}
            hideFamilyInfo
            hideHeader
            hideArrow
          />
        </div>
      )}

      {!!users.length && (
        <HeaderSearchFooter
          text={
            <FormattedMessage
              id="action-ShowAllByMatching"
              values={{ entities: userTypeText, filter: filters.draftQuery }}
            />
          }
        />
      )}
    </>
  );
};
