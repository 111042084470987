import {
  ApiError,
  DEFAULT_DATE_FORMAT_FNS,
  useGetConductEntriesQuery,
  useGetStudentMembership,
} from '@schooly/api';
import { getMiniList, MAX_PAGE_SIZE } from '@schooly/api';
import {
  useAddEntryCommentMutation,
  useCreateConductEntryMutation,
  useDeleteConductEntryMutation,
  useEditEntryCommentMutation,
  useUpdateConductEntryMutation,
} from '@schooly/api';
import { ConductEntryPatch, ConductType, ConductTypeRepresentation } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { ConductVisibility } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { useCallback, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useConductCommon } from '../../../context/conduct/useConductCommon';
import {
  RouterStateContext,
  RouterStateContextProps,
} from '../../../context/router/RouterStateContext';
import { useRouter } from '../../../context/router/useRouter';
import useAppLocation, { AppLocation } from '../../../hooks/useAppLocation';
import { FormState, useForm } from '../../../hooks/useForm';
import usePrevious from '../../../hooks/usePrevious';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import { formatDateDefault } from '../../../utils/formatDate';
import removeObjectFalsyValues from '../../../utils/removeObjectFalsyValues';

export enum ConductCreateModalMode {
  Initial,
  Students,
  Table,
}

export interface ConductCreateModalContextState extends FormState<ConductCreateModalContextState> {
  id?: string;
  date: string;
  mode?: ConductCreateModalMode;
  selectedStudents: SimpleListResult[];
  selectedConductType?: ConductType;
  conductEntryPatches: Record<string, ConductEntryPatch>;
  gridErrors: Record<string, boolean> | null;

  focusedField?: keyof ConductCreateModalContextState;
}

export const CONTEXT_NAME = 'ConductCreateModal';

export const getInitialState = (
  state?: Partial<ConductCreateModalContextState>,
): ConductCreateModalContextState => ({
  date: '',
  mode: ConductCreateModalMode.Initial,
  selectedStudents: [],
  conductEntryPatches: {},
  focusedField: 'selectedConductType',
  gridErrors: null,
  ...(state ?? {}),
});

export type ConductCreateModalContextLocation = AppLocation & {
  state: AppLocation['state'] & { initialState: Partial<ConductCreateModalContextState> };
};

export const useConductCreateModal = () => {
  const { state, setState, contextName, setContextState, setContextName, setStateHandler } =
    useContext(RouterStateContext) as RouterStateContextProps<ConductCreateModalContextState>;

  const location = useAppLocation<ConductCreateModalContextLocation>();
  const { types, typesFetching } = useConductCommon();
  const invalidateQueries = useInvalidateListQueriesFor('conduct');
  const { $t } = useIntl();
  const { id: conductId = '' } = useParams();
  const { relation_id: externalStudentRelationId } = useQueryStringParams();
  const { goBack: closeModal } = useRouter();
  const { showNotification, showError } = useNotifications();
  const { currentStaff, schoolId = '', permissions } = useAuth();
  const { getConfirmation } = useConfirmationDialog();
  const deleteConductEntryMutation = useDeleteConductEntryMutation();
  const { isLoading: deleting } = deleteConductEntryMutation;
  const { isLoading: userPropertiesFetching } = useSchoolProperties({
    userType: SchoolUserRole.Student,
    schoolId,
  });
  const updateConductEntry = useUpdateConductEntryMutation();
  const createConductEntry = useCreateConductEntryMutation();
  const addEntryComment = useAddEntryCommentMutation();
  const editEntryComment = useEditEntryCommentMutation();
  const hasManagerPermission = permissions.includes('conduct_manager');
  const singleStudent = externalStudentRelationId && !conductId;
  const isEditing = !!conductId;

  const stateExists = !!state;
  const shouldLoadStudentRelation = !conductId && !!schoolId && !!externalStudentRelationId;

  const { data: studentRelationData } = useGetStudentMembership(
    { schoolId, id: externalStudentRelationId },
    {
      onError: showError,
      refetchOnMount: 'always',
      enabled: shouldLoadStudentRelation,
    },
  );

  useEffect(() => {
    if (!studentRelationData || !stateExists) return;
    setContextState({ selectedStudents: [studentRelationData as SimpleListResult] });
  }, [setContextState, stateExists, studentRelationData]);

  const { data } = useGetConductEntriesQuery(
    {
      schoolId,
      filters: {
        conduct_entry_ids: [conductId],
      },
    },
    { enabled: !!conductId, onError: showError, refetchOnMount: 'always' },
  );

  const conductEntry = !!conductId && data?.pages[0]?.results[0];

  useEffect(() => {
    if (!conductEntry || !stateExists) return;
    setContextState({
      selectedConductType: conductEntry.conduct_type,
      date: conductEntry.date,
      selectedStudents: [conductEntry.student as SimpleListResult],
      conductEntryPatches: {
        [conductEntry.student.relation_id]: {
          title: conductEntry.title,
          value: conductEntry.value,
          details: conductEntry.details,
          comments: conductEntry.comments,
          visibility: conductEntry.visibility,
        } as unknown as ConductEntryPatch,
      },
      mode: ConductCreateModalMode.Table,
    });
  }, [conductEntry, setContextState, stateExists]);

  const handleClose = useCallback(() => {
    if (
      deleteConductEntryMutation.isLoading ||
      createConductEntry.isLoading ||
      updateConductEntry.isLoading
    )
      return;

    closeModal();
  }, [
    closeModal,
    createConductEntry.isLoading,
    deleteConductEntryMutation.isLoading,
    updateConductEntry.isLoading,
  ]);

  const form = useForm({
    state,
    setState,
    rules: {
      selectedConductType: {
        required: true,
      },

      date: {
        required: true,
      },

      selectedStudents: {
        custom: (state) => {
          if (!state.selectedStudents?.length) {
            return { id: 'conduct-AtLeastOneStudent' };
          }
        },
      },
    },
  });

  const onStudentClick = useCallback(
    (studentsList?: SimpleListResult | SimpleListResult[]) => {
      if (!studentsList) {
        return;
      }

      let newStudents;

      if (Array.isArray(studentsList)) {
        newStudents = [...state.selectedStudents, ...studentsList];
      } else {
        const student = studentsList;

        if (state.selectedStudents?.includes(student)) {
          newStudents = state.selectedStudents?.filter(
            (s) => s.relation_id !== student.relation_id,
          );
        } else {
          newStudents = [...state.selectedStudents!, student];
        }
      }

      form.set('selectedStudents', newStudents);
    },
    [form, state],
  );

  const setConductEntryPatches = useCallback(
    (conductEntryPatches: Record<string, ConductEntryPatch>) => {
      setContextState({ conductEntryPatches });
    },
    [setContextState],
  );

  const submitConduct = useCallback(async () => {
    let response;
    try {
      if (conductId) {
        const conductEntryPatch = Object.values(state.conductEntryPatches)[0];

        const updateConductEntryMutateAsync = updateConductEntry.mutateAsync({
          id: conductId,
          data: {
            conduct_type_id: state.selectedConductType?.id!,
            date: formatDateDefault(state.date)!,
            title: conductEntryPatch.title ?? null,
            value: conductEntryPatch.value ?? null,
            details: conductEntryPatch.details!,
            visibility: conductEntryPatch.visibility!,
          },
        });

        const comment = conductEntryPatch.comments?.find(
          (comment) => comment.creator_relation_id === currentStaff?.relation_id,
        );

        if (comment) {
          if (comment.id) {
            await editEntryComment.mutateAsync({
              entryId: conductId,
              commentId: comment.id,
              comment: {
                comment: comment.comment,
                creator_relation_id: comment.creator_relation_id,
              },
            });
          } else {
            await addEntryComment.mutateAsync({
              entryId: conductId,
              comment: {
                comment: comment.comment,
                creator_relation_id: comment.creator_relation_id,
              },
            });
          }
        }

        // Run update and add/edit comment mutation simultaneously
        response = await updateConductEntryMutateAsync;
      } else {
        const data = {
          date: formatDateDefault(state.date)!,
          students: state.selectedStudents
            .map((student) => {
              const studentData = state.conductEntryPatches[student.relation_id];

              return !studentData.visibility
                ? {
                    ...studentData,
                    visibility: ConductVisibility.PUBLISHED,
                  }
                : studentData;
            })
            .map(({ comments, ...patch }) =>
              removeObjectFalsyValues({
                ...patch,
                comment:
                  comments?.find(
                    (comment) => comment.creator_relation_id === currentStaff?.relation_id,
                  )?.comment ?? undefined,
              }),
            ),
        };

        response = await createConductEntry.mutateAsync({
          conductTypeId: state.selectedConductType?.id!,
          data,
        });
      }

      invalidateQueries();

      closeModal();

      showNotification({
        textId: conductId ? 'conduct-confirmation-Saved' : response.success,
        type: 'success',
      });
    } catch (error) {
      showError(error as ApiError);
    }
  }, [
    closeModal,
    editEntryComment,
    addEntryComment,
    conductId,
    createConductEntry,
    currentStaff?.relation_id,
    invalidateQueries,
    showError,
    showNotification,
    state?.conductEntryPatches,
    state?.date,
    state?.selectedConductType?.id,
    state?.selectedStudents,
    updateConductEntry,
  ]);

  const submitForm = useCallback(() => {
    setContextState({ mode: ConductCreateModalMode.Table });
  }, [setContextState]);

  const deleteConduct = useCallback(async () => {
    if (!conductId) {
      return;
    }

    const isConfirmed = await getConfirmation({
      message: $t({ id: 'conduct-ConfirmDeleteAction' }),
    });

    if (!isConfirmed) {
      return;
    }

    return deleteConductEntryMutation.mutate(conductId, {
      onSuccess: () => {
        invalidateQueries();
        closeModal();

        showNotification({
          textId: 'conduct-conformation-Deleted',
          type: 'success',
        });
      },
      onError: showError,
    });
  }, [
    $t,
    closeModal,
    conductId,
    deleteConductEntryMutation,
    getConfirmation,
    invalidateQueries,
    showError,
    showNotification,
  ]);

  const [getStudents, dateLoading] = useRequestWithProgress(async (date: string) => {
    const response = await getMiniList({
      schoolId: schoolId!,
      type: 'student',
      filters: {
        date: [date!, date!],
      },
      pageSize: MAX_PAGE_SIZE,
    });

    return response.results;
  });

  const onDateChange = useCallback(
    async (date: Date | null) => {
      const prevDate = state.date;

      const stringDate = date ? format(date, DEFAULT_DATE_FORMAT_FNS) : '';

      form.set('date', stringDate);

      if (!state.selectedStudents.length) {
        return;
      }

      const studentsToSelectedDate = await getStudents(stringDate);

      const studentsIdsToRemove: string[] = [];

      for (const existingStudent of state.selectedStudents) {
        const isExistingStudentPresentInNewList = studentsToSelectedDate!.find(
          (s) => s.relation_id === existingStudent.relation_id,
        );

        if (!isExistingStudentPresentInNewList) {
          studentsIdsToRemove.push(existingStudent.relation_id);
        }
      }

      if (studentsIdsToRemove.length > 0) {
        const isConfirmed = await getConfirmation({
          message: $t(
            { id: 'conduct-ChangingDateWarning' },
            { studentsCount: studentsIdsToRemove.length },
          ),
        });

        if (isConfirmed) {
          const filteredStudents = state.selectedStudents.filter(
            (s) => !studentsIdsToRemove.includes(s.relation_id),
          );

          setContextState({ selectedStudents: filteredStudents });
          return;
        }
        setContextState({ date: prevDate });
      }
    },
    [state?.date, state?.selectedStudents, form, getStudents, getConfirmation, $t, setContextState],
  );

  const validateGrid = useCallback(
    (studentRelationId?: string) => {
      const gridErrors: Record<string, boolean> = {};
      const ids = studentRelationId
        ? [studentRelationId]
        : state.selectedStudents.map((s) => s.relation_id);
      const selectedConductType = types?.find((type) => type.id === state.selectedConductType?.id);

      for (const relationId of ids) {
        const patch = state.conductEntryPatches[relationId] ?? {};
        const isNumber = !!patch.value && typeof parseInt(patch.value, 10) === 'number';
        if (selectedConductType?.type === ConductTypeRepresentation.NUMBER && !isNumber) {
          gridErrors[relationId] = true;
        }

        if (!patch.value && !patch.title && !patch.details) {
          gridErrors[relationId] = true;
        }
      }

      setContextState({ gridErrors });

      return gridErrors;
    },
    [setContextState, state, types],
  );

  const saveConduct = useCallback(() => {
    const errors = validateGrid();

    if (Object.values(errors).length === 0) {
      submitConduct();
    }
  }, [validateGrid, submitConduct]);

  const prevPatches = usePrevious(state?.conductEntryPatches);

  useEffect(() => {
    if (contextName !== CONTEXT_NAME) {
      setContextName(CONTEXT_NAME);
      return;
    }
    if (!stateExists) {
      // Update the context state on a next tick to avoid race condition
      // with WithRouterState.tsx -> useEffect on location change
      setTimeout(() => setState(getInitialState(location.state?.initialState)));
    }
  }, [
    stateExists,
    conductId,
    externalStudentRelationId,
    contextName,
    location.state?.initialState,
    setContextName,
    setState,
  ]);

  useEffect(() => {
    if (
      state?.mode === ConductCreateModalMode.Table &&
      state?.gridErrors !== null &&
      !isEqual(state?.conductEntryPatches, prevPatches)
    ) {
      validateGrid();
    }
  }, [state, prevPatches, validateGrid]);

  const clearFocusField = useCallback(() => {
    setContextState({ focusedField: undefined });
  }, [setContextState]);

  return {
    ...state,
    conductId,
    externalStudentRelationId,
    types,
    typesFetching,
    form,
    loading:
      typesFetching ||
      userPropertiesFetching ||
      !state ||
      (!!conductId && !conductEntry) ||
      (shouldLoadStudentRelation && !studentRelationData),
    dateLoading,
    saving:
      updateConductEntry.isLoading ||
      createConductEntry.isLoading ||
      addEntryComment.isLoading ||
      editEntryComment.isLoading,
    deleting,
    singleStudent,
    hasManagerPermission,
    isEditing,

    actions: {
      setContextState,
      closeModal: handleClose,
      setStateHandler,
      onStudentClick,
      setConductEntryPatches,
      submitForm,
      deleteConduct,
      onDateChange,
      saveConduct,
      validateGrid,
      clearFocusField,
    },
  };
};
