const SERIALIZED_ERROR_PREFIX = 'joiSerializedError:';

// Joi treats any strings having curly braces as templates, so we escape them here
function escapeCurlyBraces(str: string) {
  return str.split('{').join('&LBRACE;').split('}').join('&RBRACE;');
}

function restoreCurlyBraces(str: string) {
  return str.split('&LBRACE;').join('{').split('&RBRACE;').join('}');
}

// Joi doesn't easily integrate with `react-intl`,
// So we serialize any intl errors with this funcition and unpack it later in the input helpers
export function serializeIntlError(id: string, values?: Record<string, any>) {
  return `${SERIALIZED_ERROR_PREFIX}${escapeCurlyBraces(JSON.stringify({ id, values }))}`;
}

export function isSerializedIntlError(error: string) {
  return error.indexOf(SERIALIZED_ERROR_PREFIX) === 0;
}

export function unpackSerializedIntlError(error: string) {
  const serialized = error.slice(SERIALIZED_ERROR_PREFIX.length);
  return JSON.parse(restoreCurlyBraces(serialized));
}
