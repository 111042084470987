import React from 'react';

import buildClassName from '../../../utils/buildClassName';

import './index.scss';

interface IProps {
  userCriteria: string[];
  criteriaToShow?: number;
}

// todo: discuss with designer and update accordingly to https://www.figma.com/file/Hxa9WHqVxN1q07IKdamU8j/Schooly-3.0?node-id=434%3A42554
const UserCriteria: React.FC<IProps> = ({ userCriteria, criteriaToShow = 1 }) => {
  const fullClassName = buildClassName(
    'UserCriteria',
    userCriteria.length > criteriaToShow && 'hoverable',
  );

  return (
    <div className={fullClassName}>
      {userCriteria.slice(0, criteriaToShow).join(', ')}
      {userCriteria.length > criteriaToShow && `, +${userCriteria.length - criteriaToShow}`}

      <div className="UserCriteria__popup">
        {userCriteria.map((c) => (
          <div key={c}>{c}</div>
        ))}
      </div>
    </div>
  );
};

export default UserCriteria;
