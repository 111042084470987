import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Assessment, AssessmentsGrade, getAssessment, getAssessmentList } from '@schooly/api';

import { AppLocation } from '../../../hooks/useAppLocation';
import { IApiSlice } from '../../scheme';

export interface AssessmentsPreviewState extends IApiSlice<Assessment> {
  list?: AssessmentsGrade;
  fetchingList: boolean;
}

export const initialState: AssessmentsPreviewState = {
  data: undefined,
  list: undefined,
  fetching: false,
  fetchingList: false,
};

export interface AssessmentsPreviewContextLocation extends AppLocation {
  state: AppLocation['state'] & {
    initialState?: Assessment;
  };
}

export const extraActions = {
  get: createAsyncThunk('assessments/preview/get', (id: string) => getAssessment({ id })),
  getList: createAsyncThunk('assessments/preview/list/get', (id: string) => getAssessmentList(id)),
};

const slice = createSlice({
  name: 'assessments/preview',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Assessment>) => {
      state.data = {
        ...action.payload,
        methods:
          [...(action.payload?.methods ?? [])].sort((a, b) => a.method_type - b.method_type) ?? [],
      } as Assessment;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.data = undefined;
        state.fetching = true;
        state.error = undefined;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        state.data = {
          ...action.payload,
          methods:
            [...(action.payload?.methods ?? [])].sort((a, b) => a.method_type - b.method_type) ??
            [],
        } as Assessment;
        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });

    builder
      .addCase(extraActions.getList.pending, (state) => {
        state.list = undefined;
        state.fetchingList = true;
        state.error = undefined;
      })
      .addCase(extraActions.getList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.fetchingList = false;
      })
      .addCase(extraActions.getList.rejected, (state, action) => {
        state.fetchingList = false;
        state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
