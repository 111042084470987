import { Group, GroupType } from '@schooly/api';
import { AssessmentGroup } from '@schooly/api';
import React from 'react';
import { useIntl } from 'react-intl';

import RoundCard, { RoundCardProps } from '../../../../components/uikit-components/RoundCard';

interface AssessmentsGroupCardProps extends Omit<RoundCardProps<any>, 'item'> {
  group: Group | AssessmentGroup;
  isSelected?: boolean;
  onClick?: VoidFunction;
  generateHref?: (id?: string) => string;
}

const AssessmentsCreateModalGroupsItem: React.FC<AssessmentsGroupCardProps> = ({
  group,
  isSelected,
  onClick,
  generateHref,
  ...props
}) => {
  const { $t } = useIntl();
  const { subject, group_type, student_count } = (group as AssessmentGroup) || {};

  return (
    <RoundCard
      onClick={onClick}
      item={group}
      label={
        group_type === GroupType.TutorGroup
          ? $t({ id: 'groups-TutorGroup' })
          : subject?.archived
          ? undefined
          : subject?.name
      }
      count={student_count}
      withTooltip
      name={group?.name}
      isSelected={isSelected}
      generateHref={generateHref}
      {...props}
    />
  );
};

export default AssessmentsCreateModalGroupsItem;
