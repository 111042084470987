import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApiError,
  FilterValue,
  getMiniList,
  MiniListArguments,
  PagedResponse,
  SelectOption,
  SimpleListResult,
} from '@schooly/api';

export interface SimpleListStudentState extends PagedResponse<SimpleListResult> {
  fetching: boolean;
  error?: ApiError;
  canShowMore?: boolean;
  arg?: MiniListArguments;
  savedSelectedOptions: SelectOption<FilterValue, any>[];
}

export const initialState: SimpleListStudentState = {
  count: 0,
  results: [],
  suggested: [],
  fetching: true,
  error: undefined,
  canShowMore: false,
  savedSelectedOptions: [],
};

export const extraActions = {
  get: createAsyncThunk('simple-lists/student/get', (args: MiniListArguments) =>
    getMiniList({ ...args, type: 'student' }),
  ),
};

const slice = createSlice({
  name: 'simple-list/student',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    setSavedSelectedOptions: (state, action: PayloadAction<SelectOption<FilterValue, any>[]>) => {
      state.savedSelectedOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state, action) => ({
        ...state,
        arg: action.meta.arg,
        count: state.results.length || 0,
        fetching: true,
      }))
      .addCase(extraActions.get.fulfilled, (state, action) => {
        const results = [
          ...(action.meta.arg.pageNumber && action.meta.arg.pageNumber > 1 ? state.results : []),
          ...action.payload.results,
        ];

        return {
          ...state,
          ...action.payload,
          results,
          count: action.payload.results.length || 0,
          fetching: false,
          canShowMore: Boolean(
            results.length && action.payload.current_page !== action.payload.total_pages,
          ),
        };
      })
      .addCase(extraActions.get.rejected, (state, action) => ({
        ...state,
        fetching: false,
        error: action.payload as ApiError,
      }));
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
