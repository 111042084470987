import { Stack } from '@mui/material';
import { MessageStatus } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { renderMessageStatusTags } from './MessageStatusExpandedSelect';

type MessageStatusSelectMultipleProps = PropsWithChildren<{
  selectedStatuses: MessageStatus[];
  onSelectMessageStatus: (v: MessageStatus) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

const STATUSES = [MessageStatus.Published, MessageStatus.Draft, MessageStatus.Submitted];

export const MessageStatusSelectMultiple: FC<MessageStatusSelectMultipleProps> = ({
  selectedStatuses,
  onSelectMessageStatus,
  placeholder,
  ...dropdownProps
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderMessageStatusTags({
          statuses: STATUSES,
          tagProps: (status) => ({
            variant: selectedStatuses.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectMessageStatus,
        })}
      </Stack>
    );
  }, [onSelectMessageStatus, selectedStatuses]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selectedStatuses.length}
      renderContent={renderContent}
    >
      {(opened) => (
        <>
          {renderMessageStatusTags({
            statuses: selectedStatuses,
            tagProps: { size: placeholder ? 'small' : undefined },
            onDelete: opened ? onSelectMessageStatus : undefined,
          })}
        </>
      )}
    </DropdownSelect>
  );
};
