import { Stack } from '@mui/material';
import { FormTextField } from '@schooly/components/form-text-field';
import { FC, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { Validate } from 'react-hook-form-lts/dist/types/validator';
import { useIntl } from 'react-intl';

import { usePeriodValidation } from '../../../../School/SchoolPeriods/SchoolPeriodsUpdate/usePeriodValidation';
import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import { addSchoolPeriodGroupOption, AnnualPlannerCreateForm } from '../../scheme';
import { useAnnualPlannerCreateRecord } from '../WithAnnualPlannerCreate';

export const AnnualPlannerCreateSchoolPeriodName: FC = () => {
  const { $t } = useIntl();
  const { schoolYear } = useAnnualPlanner();
  const { record } = useAnnualPlannerCreateRecord();
  const { validatePastDate } = usePeriodValidation();
  const { watch, register, setValue } = useFormContext<AnnualPlannerCreateForm>();

  const groupId = watch('schoolPeriod.groupId');
  const groupName = watch('schoolPeriod.groupName');

  const [group, period, isStarted] = useMemo(() => {
    const group =
      groupId && groupName
        ? schoolYear?.period_groups?.find((group) => group.id === groupId)
        : undefined;

    const period = record
      ? group?.periods.find((period) => period.id === record.details.id)
      : undefined;

    const isStarted = group?.periods.some((period) => validatePastDate(period.date_from));

    return [group, period, isStarted];
  }, [groupId, groupName, record, schoolYear?.period_groups, validatePastDate]);

  const isRenamed = Boolean(
    group && group.id !== addSchoolPeriodGroupOption.value && group.name !== groupName,
  );

  /**
   * Validates a group name
   *
   * TODO: make shareable as might partially duplicate
   *  apps/web/src/pages/School/SchoolPeriods/SchoolPeriodsUpdate/PeriodGroup.tsx:validateGroupName
   */
  const validateGroupName = useCallback<
    Validate<AnnualPlannerCreateForm['schoolPeriod']['groupName'], AnnualPlannerCreateForm>
  >(
    (value) => {
      const error = Boolean(
        value &&
          schoolYear?.period_groups?.some(
            (item) => item.id !== group?.id && item.name === value.toString().trim(),
          ),
      );

      return error ? $t({ id: 'school-schoolPeriods-UniqueGroupName' }) : true;
    },
    [$t, group?.id, schoolYear?.period_groups],
  );

  if (!groupId) {
    return null;
  }

  const index = period?.name.replace(/.*\s(\d+)$/, '$1') || (group?.periods.length ?? 0) + 1;
  const name = groupName ? `${groupName} ${index}` : '';

  return (
    <Stack direction="row" gap={1}>
      {/*
         The 'schoolPeriod.groupName' might be disabled which leads to the undefined value in the
         form state. Will be using a hidden input without disabled attr as a workaround.
         See: https://github.com/react-hook-form/react-hook-form/issues/4130
      */}
      <input
        type="hidden"
        {...register('schoolPeriod.groupName', {
          validate: validateGroupName,
          required: true,
        })}
      />
      <FormTextField
        value={groupName}
        onChange={(event) => {
          setValue('schoolPeriod.groupName', event.target.value);
        }}
        key={groupId} // to keep autoFocus on group change
        label={$t({ id: 'annualPlanner-Popover-SchoolPeriod-GroupName' })}
        disabled={isStarted}
        locked={isStarted}
        lockedTooltip={{
          title: $t({ id: 'annualPlanner-Popover-SchoolPeriod-PeriodName-disabled' }),
        }}
        helperText={
          isRenamed
            ? $t({ id: 'annualPlanner-Popover-SchoolPeriod-PeriodName-totalChange' })
            : undefined
        }
        sx={{
          '& .MuiFormHelperText-root:not(.Mui-error)': {
            color: 'primary.main',
          },
        }}
        fullWidth
        hideLabel
        autoFocus
      />
      <FormTextField
        label={$t({ id: 'annualPlanner-Popover-SchoolPeriod-PeriodName' })}
        value={name}
        fullWidth
        disabled
        locked
        hideLabel
      />
    </Stack>
  );
};
