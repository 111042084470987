import { InputAdornment, Stack, Typography } from '@mui/material';
import { FormTextFieldStyled } from '@schooly/components/form-text-field';
import { FC, forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { HOVER_TEXT_CLASS_NAME } from './CreateVariantsTable';

export interface ProductPriceInputProps {
  currencySymbol: string;
  value: number | undefined;
  onChange: (v: number | undefined) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: boolean;
}

export const ProductPriceInput: FC<ProductPriceInputProps> = ({
  error,
  value,
  currencySymbol,
  onChange,
  onBlur,
  onFocus,
}) => {
  const { $t } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [lastValue, setLastValue] = useState(value);
  const escPressed = useRef(false);

  const handleFocus = useCallback(() => {
    setIsEdit(true);
    onFocus?.();
    const inputElement = inputRef.current?.querySelector('input');
    inputElement?.focus();
  }, [onFocus]);

  const handleBlur = useCallback(async () => {
    setIsEdit(false);
    onBlur?.();
  }, [onBlur]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? parseFloat(event.target.value) : undefined;

    onChange(newValue);
    setLastValue(newValue);
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === '-') event.preventDefault();

      let inputElement;

      switch (event.code) {
        case 'Enter':
          inputElement = inputRef.current?.querySelector('input');
          inputElement?.blur();
          break;
        case 'Escape':
          escPressed.current = true;
          onChange(lastValue);
          inputElement = inputRef.current?.querySelector('input');
          inputElement?.blur();
      }
    },
    [lastValue, onChange],
  );

  const isEmpty = value === undefined;

  const backgroundColor = useMemo(() => {
    if (isEdit) return 'background.paper';
  }, [isEdit]);

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      handleFocus();
    },
    [handleFocus],
  );

  return (
    <Stack
      onClick={clickHandler}
      sx={{
        backgroundColor: backgroundColor,
        height: '100%',
      }}
    >
      <Stack
        width="100%"
        direction="row"
        flex={1}
        alignItems="center"
        sx={(theme) => ({
          position: 'relative',
          border: error
            ? `1px solid ${theme.palette.error.main}`
            : isEdit
            ? theme.mixins.borderControlValue()
            : 'none',
          m: isEdit || error ? '-1px' : 0,
          width: isEdit || error ? 'calc(100% + 2px)' : '100%',
          zIndex: isEdit ? 2 : undefined,
          'input[type=number]': {
            '-moz-appearance': 'textfield',
          },
          'input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          'input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        })}
      >
        <FormTextFieldStyled
          variant="standard"
          sx={(theme) => ({
            width: '100%',

            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
              border: 'none !important',
            },
            '& .MuiInput-input': {
              ...theme.typography.h3,

              minHeight: '42px !important',
              height: '100%',
              margin: '0 !important',
            },
            '&.MuiTextField-root .MuiInputAdornment-root': {
              marginLeft: 0,
              marginRight: theme.spacing(0.5),
            },
          })}
          ref={inputRef}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={$t({ id: 'products-AddPrice' })}
          value={value}
          InputProps={{
            inputComponent: ProductPriceFormatInput as any,
            startAdornment: !isEmpty && (
              <InputAdornment position="start">
                <Typography variant="h3" color="common.grey" className={HOVER_TEXT_CLASS_NAME}>
                  {currencySymbol}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};

interface ProductPriceFormatInputProps {
  onChange: (event: { target: { name: string; value?: number } }) => void;
  name: string;
}

const PRICE_DECIMAL_SCALE = 2;
const PRICE_DECIMAL_SEPARATOR = '.';

const ProductPriceFormatInput = forwardRef<NumericFormatProps, ProductPriceFormatInputProps>(
  function NumericFormatCustom({ name, onChange, ...rest }, ref) {
    return (
      <NumericFormat
        {...rest}
        getInputRef={ref}
        decimalSeparator={PRICE_DECIMAL_SEPARATOR}
        decimalScale={PRICE_DECIMAL_SCALE}
        onValueChange={({ floatValue }) => {
          onChange({
            target: {
              name: name,
              value: floatValue,
            },
          });
        }}
        fixedDecimalScale
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);
