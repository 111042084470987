import { SingleInvoiceAssignedProduct, SingleInvoicePayer } from '@schooly/api';
import { Currencies } from '@schooly/constants';

import { getDiscountedPrice } from '../../ProfileModalPayers/StudentProductsModal/helpers';

type GetPriceParams = {
  price: number;
  quantity: number;
  discountPercent?: number;
};

export function getProductTotal({ price, quantity, discountPercent }: GetPriceParams) {
  const total = price * quantity;
  return discountPercent ? getDiscountedPrice(total, discountPercent) : total;
}

export function getProductsTotal(products: SingleInvoiceAssignedProduct[]) {
  const map: Map<Currencies, number> = new Map();

  for (const product of products) {
    const {
      price,
      billing_connection: { legal_entity_currency },
    } = product;
    const total = getProductTotal({
      price,
      quantity: Number.isInteger(product.quantity) ? product.quantity : 1,
      discountPercent: product.discount_percent,
    });

    const val = map.get(legal_entity_currency) ?? 0;

    map.set(legal_entity_currency, val + total);
  }

  return [...map].map(([currency, total]) => ({ currency, total }));
}

export function getPayerId(payer: SingleInvoicePayer) {
  return payer.type === 'company' ? payer.id : payer.relation_id;
}
