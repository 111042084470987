import { Icon, Stack, Typography } from '@mui/material';
import { PlusIcon } from '@schooly/style';
import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { IS_DEMO } from '../../../../config';

export interface CreateNewSchoolProps {
  menu?: boolean;
}

export const CreateNewSchool: FC<CreateNewSchoolProps> = ({ menu }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate('/school/new');
  }, [navigate]);

  if (IS_DEMO) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={menu ? 2.25 : 1}
      pl={menu ? 1.25 : 1}
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        color: (theme) => theme.palette.primary.main,
        '&:hover': { color: (theme) => theme.palette.text.primary },
      }}
    >
      <Icon sx={{ display: 'flex' }}>
        <PlusIcon />
      </Icon>
      <Typography variant="h3" color="inherit">
        <FormattedMessage id="school-create-Title" />
      </Typography>
    </Stack>
  );
};
