import { Stack } from '@mui/material';
import { FC, HTMLAttributes, RefObject } from 'react';

export const handleArrowClick = (
  direction: 'left' | 'right',
  containerRef?: RefObject<HTMLElement>,
  cellSelector?: string,
) => {
  return () => {
    if (!containerRef?.current || !cellSelector) {
      return;
    }

    // get all header cells
    const cells = Array.from<HTMLElement>(containerRef.current.querySelectorAll(cellSelector));

    const scrollLeft = containerRef.current.scrollLeft ?? 0;
    const offsetLeft = containerRef.current.offsetLeft ?? 0;

    // first cell is sticky, should be considered as an offset rather than a plain cell
    const offset = 140;
    const anchorOffset = offset + scrollLeft;

    let scrollTo: HTMLElement | undefined;

    // define an item we'll be scrolling to, depending on the direction
    if (direction === 'right') {
      scrollTo = cells.find((cell) => {
        return cell.offsetLeft > Math.ceil(anchorOffset - offsetLeft);
      });
    } else {
      if (cells.length > 0) {
        for (let i = cells.length - 1; i >= 0; i--) {
          const cell = cells[i];
          if (cell.offsetLeft < Math.floor(anchorOffset)) {
            scrollTo = cell;
            break;
          }
        }
      }
    }

    // do scroll
    if (scrollTo) {
      containerRef.current.scrollTo({
        left: scrollTo.offsetLeft - offset - offsetLeft,
        behavior: 'smooth',
      });
    }
  };
};

export const Arrow: FC<HTMLAttributes<HTMLElement>> = ({ children, ...props }) => (
  <Stack
    justifyContent="center"
    sx={(theme) => ({
      position: 'absolute',
      height: 49,
      fontSize: 20,
      zIndex: 4,
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      background: theme.palette.background.paper,
      px: 0.5,
      '&:hover': {
        color: theme.palette.common.grey3,
      },

      '&:active': {
        color: theme.palette.common.grey2,
      },

      '&.arrowRight': {
        right: 0,
        background: 'linear-gradient(90deg, transparent 0%, white 55%, white 100%)',
      },
      '&.arrowLeft': {
        left: 125,
      },
    })}
    {...props}
  >
    {children}
  </Stack>
);
