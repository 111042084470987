import { Stack, Typography } from '@mui/material';
import {
  Details,
  ParentSchoolRelation,
  StaffSchoolRelation,
  StudentSchoolRelation,
  StudentSchoolRelationUpdate,
  User,
  useUpdateSchoolMembershipMutation,
} from '@schooly/api';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { usePrevious } from '@schooly/hooks/use-previous';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormRadioGroup } from '../../../components/uikit-components/FormCheckbox/FormRadioGroup';
import { PropertyModal2 } from './PropertyModal2';

interface MedicalModalProps {
  onClose: () => void;
  isOpen: boolean;
  user: User;
  studentMembership?: StaffSchoolRelation | StudentSchoolRelation | ParentSchoolRelation;
  canEdit: boolean;
}

const BOOL_OPTIONS = [
  { value: 'true', labelTextId: 'yes' },
  { value: 'false', labelTextId: 'no' },
];

const castToBoolean = (value: string): boolean | null => {
  if (typeof value === 'boolean' || value === null) {
    return value;
  }

  return value === 'true';
};

const getStudentSchoolRelationData = (data: Details = {}): StudentSchoolRelationUpdate =>
  Object.entries(data).reduce((acc, [k, v]) => {
    if (!['severe', 'reviewed_by_nurse'].includes(k)) {
      return { [k]: v };
    }

    return { ...acc, [k]: castToBoolean(v) };
  }, {});

const MedicalModal: React.FC<MedicalModalProps> = ({
  onClose,
  isOpen,
  user,
  studentMembership,
  canEdit,
}) => {
  const { showNotification, showError } = useNotifications();
  const { $t } = useIntl();
  const updateSchoolMembership = useUpdateSchoolMembershipMutation();
  const { details, relation_id = '' } = studentMembership || {};

  const form = useForm<Details>({
    mode: 'all',
  });
  const { reset, setValue, watch } = form;
  const allergy = watch('allergy');
  const prevAllergy = usePrevious(allergy);
  const initReviewedByNurse =
    details?.allergy && !details?.reviewed_by_nurse ? false : details?.reviewed_by_nurse;

  useEffect(() => {
    if (!isOpen) return;

    setTimeout(() => {
      form.setValue('allergy', details?.allergy);
      form.setValue('severe', details?.severe);
      form.setValue('reviewed_by_nurse', initReviewedByNurse);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, details]);

  useEffect(() => {
    const subscription = watch(({ allergy }, { name, type }) => {
      if (type !== 'change' && name !== 'allergy') return;

      if (!prevAllergy && allergy) {
        setValue('severe', false);
        setValue('reviewed_by_nurse', false);
      } else if (!allergy) {
        setValue('severe', null);
        setValue('reviewed_by_nurse', null);
      }
    });

    return () => subscription.unsubscribe();
  }, [prevAllergy, setValue, watch]);

  const handleSubmit = useCallback(
    async (data: Details) => {
      updateSchoolMembership.mutateAsync(
        {
          relationId: relation_id,
          userType: 'student',
          update: getStudentSchoolRelationData(data),
        },
        {
          onSuccess: () => {
            showNotification({
              textId: 'confirmation-MedicalNeeds',
              type: 'success',
            });

            onClose();
          },
          onError: showError,
        },
      );
    },
    [onClose, relation_id, showNotification, updateSchoolMembership, showError],
  );

  useEffect(() => {
    reset();
  }, [studentMembership, reset]);

  return (
    <PropertyModal2
      isOpen={isOpen}
      user={user}
      isUpdating={updateSchoolMembership.isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <Stack gap={2} mb={2}>
        <ControlTextField
          name="allergy"
          control={form.control}
          label={$t({ id: 'peopleDetail-AllergyMedicalConcerns' })}
          fullWidth
          disabled={!canEdit}
          data-test-id="student-medical-needs-input"
        />

        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" pl={2} mb={1.5}>
            <FormattedMessage id="peopleDetail-FlagAsSevere" />
          </Typography>
          <FormRadioGroup options={BOOL_OPTIONS} name="severe" disabled={!canEdit || !allergy} />
        </Stack>

        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" pl={2} mb={1.5}>
            <FormattedMessage id="peopleDetail-ReviewedByNurse" />
          </Typography>
          <FormRadioGroup options={BOOL_OPTIONS} name="reviewed_by_nurse" disabled={!allergy} />
        </Stack>
      </Stack>
    </PropertyModal2>
  );
};

export default MedicalModal;
