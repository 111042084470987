import { useEffect, useState } from 'react';

import useAppLocation from './useAppLocation';

function searchStringToObject(search: string) {
  const urlSearchParams = new URLSearchParams(search);
  const entries = Array.from(urlSearchParams.entries()); // as Entries<T>;
  const result: Record<string, string> = {};

  entries.forEach(([key, value]) => {
    result[key] = value;
  });

  return result;
}

export default function useQueryStringParams<T extends string>() {
  const { search } = useAppLocation();
  const [params, setParams] = useState(searchStringToObject(search));

  useEffect(() => {
    setParams(searchStringToObject(search));
  }, [search]);

  return params as Record<T, string>;
}
