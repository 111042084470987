import { Box, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getUserTypeTextId } from '../../../../helpers/misc';
import buildClassName from '../../../../utils/buildClassName';
import { MessagesModalPreviewSendToParents } from './MessagesModalPreviewSendToParents';
import { MessagesModalPreviewSendToStaff } from './MessagesModalPreviewSendToStaff';

export type TabType = 'parent' | 'staff';

export const MessagesModalPreviewSendTo: FC = () => {
  const [tab, setTab] = useState<TabType>('parent');
  const tabs: TabType[] = ['parent', 'staff'];

  return (
    <Box>
      <Typography variant="h2" mb={2.5}>
        <FormattedMessage id="messages-SendTo" />
      </Typography>

      <div className="MessagesModalPreview">
        <div className="MessagesModalPreview__tabs">
          {tabs.map((item) => (
            <div
              key={item}
              onClick={() => setTab(item)}
              className={buildClassName('MessagesModalPreview__tab', item === tab && 'active')}
            >
              <FormattedMessage id={getUserTypeTextId(item, true)} />
            </div>
          ))}
        </div>
        <div className="MessagesModalPreview__content">
          {tab === 'parent' && <MessagesModalPreviewSendToParents />}
          {tab === 'staff' && <MessagesModalPreviewSendToStaff />}
        </div>
      </div>
    </Box>
  );
};
