import { MenuItem, Stack, TypographyProps } from '@mui/material';
import { RecurringState } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import { theme, TypographyWithOverflowHint } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SingleSelectStyled } from 'libs/components/form-select/src/lib/SingleSelect.style';
import React, { FC, useMemo, useState } from 'react';
import { get, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

import { useRecurringStateLabel } from './hooks';
import { RecurringModal, RecurringModalProps } from './RecurringModal';
import { convertRecurringFormToState, convertRecurringStateToForm } from './utils';

enum RecurringSelectOptions {
  DoesNotRepeat = 'recurring-DoesNotRepeat',
  RecurringState = 'recurring-RecurringState',
  CustomRecurrence = 'recurring-selectCustomRecurrence',
}

type RecurringSelectProps = {
  name: string;
  onCustomValidation?: (recurringState: RecurringState) => Promise<boolean>;
} & Omit<RecurringModalProps, 'onClose' | 'recurringForm' | 'onSubmit'>;

export const RecurringSelect: FC<RecurringSelectProps> = ({
  name,
  onCustomValidation,
  ...props
}) => {
  const { watch, setValue, formState } = useFormContext<Record<string, RecurringState | null>>();
  const [isRecurringModalOpened, showRecurringModal, closeRecurringModal] = useFlag();
  const [prevRecurringState, setPrevRecurringState] = useState<RecurringState>();
  const [selectedOption, setSelectedOption] = useState<RecurringSelectOptions>();
  const { ref: inputRef, width } = useResizeDetector<HTMLDivElement>();
  const error = get(formState.errors, name);
  const hasError = Boolean(error);
  const recurringState = watch(name);
  const currentRecurringState = recurringState ?? prevRecurringState;
  const { $t } = useIntl();

  const defaultValue = currentRecurringState
    ? RecurringSelectOptions.RecurringState
    : RecurringSelectOptions.DoesNotRepeat;

  const recurringForm = useMemo(
    () => (currentRecurringState ? convertRecurringStateToForm(currentRecurringState) : undefined),
    [currentRecurringState],
  );
  return (
    <Stack minHeight={44}>
      <SingleSelectStyled
        ref={inputRef}
        sx={{
          '&& .MuiSelect-outlined.MuiOutlinedInput-input.MuiSelect-select': {
            paddingTop: 1.25,
            whiteSpace: 'normal',
          },
        }}
        MenuProps={{
          ...theme.components?.MuiSelect?.defaultProps?.MenuProps,
          sx: {
            ...theme.components?.MuiSelect?.defaultProps?.MenuProps?.sx,
            '& .MuiMenu-paper': { width },
          },
        }}
        value={selectedOption ?? defaultValue}
        error={hasError}
        renderValue={(v) => {
          if (v === RecurringSelectOptions.RecurringState && currentRecurringState) {
            return (
              <RecurringItem recurringState={currentRecurringState} startDate={props.startDate} />
            );
          }
          return $t({ id: v as RecurringSelectOptions });
        }}
        onChange={(e) => {
          setSelectedOption(e.target.value as RecurringSelectOptions);
        }}
        defaultValue={defaultValue}
      >
        <MenuItem
          value={RecurringSelectOptions.DoesNotRepeat}
          onClick={() => {
            if (recurringState) {
              setPrevRecurringState(recurringState);
              setValue(name, null);
            }
          }}
        >
          <FormattedMessage id={RecurringSelectOptions.DoesNotRepeat} />
        </MenuItem>

        {currentRecurringState && (
          <MenuItem
            value={RecurringSelectOptions.RecurringState}
            onClick={() => {
              if (!recurringState && prevRecurringState) {
                setValue(name, prevRecurringState);
              }
            }}
            sx={{ whiteSpace: 'unset' }}
          >
            <RecurringItem
              sx={{ whiteSpace: 'inherit' }}
              recurringState={currentRecurringState}
              startDate={props.startDate}
            />
          </MenuItem>
        )}
        <MenuItem onClick={showRecurringModal}>
          <FormattedMessage
            id={RecurringSelectOptions.CustomRecurrence}
            values={{
              name: props.entity.name.toLowerCase(),
            }}
          />
        </MenuItem>
      </SingleSelectStyled>

      {isRecurringModalOpened && (
        <RecurringModal
          onClose={() => {
            setSelectedOption(
              recurringState
                ? RecurringSelectOptions.RecurringState
                : RecurringSelectOptions.DoesNotRepeat,
            );
            closeRecurringModal();
          }}
          {...props}
          onSubmit={async (form) => {
            const recurringState = convertRecurringFormToState({
              startDate: props.startDate,
              form,
            });

            const saveAndClose = () => {
              setPrevRecurringState(undefined);
              setValue(name, recurringState);
              setSelectedOption(RecurringSelectOptions.RecurringState);
              closeRecurringModal();
            };

            if (!onCustomValidation) {
              saveAndClose();
            } else {
              const confirmed = await onCustomValidation(recurringState);
              if (confirmed) saveAndClose();
            }
          }}
          recurringForm={recurringForm}
        />
      )}
    </Stack>
  );
};

type RecurringItemProps = {
  recurringState: RecurringState;
  startDate: Date;
} & TypographyProps;

export const RecurringItem: FC<RecurringItemProps> = ({ recurringState, startDate, ...props }) => {
  const label = useRecurringStateLabel({
    shortDays: true,
    ...recurringState,
  });

  return (
    <TypographyWithOverflowHint variant="h3" {...props}>
      {label}
    </TypographyWithOverflowHint>
  );
};
