import { Box, Card, IconButton, Stack, styled, Typography } from '@mui/material';

export const CalendarWeekStyled = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  border: 0,
  height: '100%',
});

CalendarWeekStyled.defaultProps = {
  className: 'CalendarWeek',
};

export const CalendarWeekGridStyled = styled(Stack)(({ theme }) => ({
  overflow: 'auto',
  flex: '1 1 100%',
  ...theme.mixins.scrollBar(),
}));

CalendarWeekGridStyled.defaultProps = {
  className: 'CalendarWeekGrid',
};

export interface CalendarWeekGridSectionProps {
  weeksCount: number;
}

export const CalendarWeekGridSection = styled(Stack)<CalendarWeekGridSectionProps>(
  ({ weeksCount }) => ({
    flexDirection: 'row',
    position: 'relative',
    // take {weeksCount * 300}% as we will render {weeksCount}x weeks and only one should fill the viewport
    // minus 44px padding of each week (for a Timeline column)
    width: `calc(${weeksCount}00% - ${weeksCount} * 44px)`,
  }),
);

CalendarWeekGridSection.defaultProps = {
  className: 'CalendarWeekGridSection',
};

export interface CalendarWeekGridColumnStyledProps {
  weeksCount: number;
}

export const CalendarWeekGridColumnStyled = styled(Stack)<CalendarWeekGridColumnStyledProps>(
  ({ theme, weeksCount }) => ({
    // will render 3x weeks
    flex: `0 0 ${100 / (weeksCount * 7)}%`,

    borderRight: theme.mixins.borderValue(),

    '&:first-of-type': {
      borderLeft: theme.mixins.borderValue(),
    },
  }),
);

CalendarWeekGridColumnStyled.defaultProps = {
  className: 'CalendarWeekGridColumn',
};

export const CalendarWeekGridColumnTimeStyled = styled(Stack)(({ theme }) => ({
  flex: `0 0 ${theme.spacing(5.5)}`,
  paddingLeft: theme.spacing(1),
  borderRight: theme.mixins.borderValue(),

  // Stick to left on scroll
  position: 'sticky',
  left: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 101,

  '& .CalendarWeekGridCell': {
    position: 'unset',

    '&:nth-of-type(even):not(:last-of-type)': {
      border: 'none',
    },
  },

  '& .MuiTypography-root': {
    transform: 'translateY(-50%)',
  },
}));

CalendarWeekGridColumnTimeStyled.defaultProps = {
  className: 'CalendarWeekGridColumnTime',
};

export const CalendarWeekGridCurrentTimeStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',

  position: 'absolute',
  top: theme.spacing(-1), // actual position is being calculated in the component
  left: 44, // a padding for a time column
  width: '100%',
  zIndex: 100,

  '& .CalendarWeekGridColumn': {
    position: 'relative',
    height: 1,
    border: 0,
    backgroundColor: theme.palette.primary.main,
  },
}));

CalendarWeekGridCurrentTimeStyled.defaultProps = {
  className: 'CalendarWeekGridCurrentTime',
};

export const CalendarWeekGridCurrentTimeToday = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: 'calc(50% - 0.5px)',
  marginTop: theme.spacing(0.5),
  marginLeft: theme.spacing(-0.5),
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
}));

CalendarWeekGridCurrentTimeToday.defaultProps = {
  className: 'CalendarWeekGridCurrentTimeToday',
};

export const CalendarWeekGridControls = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 103,
}));

CalendarWeekGridControls.defaultProps = {
  className: 'CalendarWeekGridControls',
};

export const CalendarWeekGridControlsButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
}));

CalendarWeekGridControlsButton.defaultProps = {
  className: 'CalendarWeekGridControlsButton',
};

export const CalendarWeekGridControlsButtonPrev = styled(CalendarWeekGridControlsButton)(
  ({ theme }) => ({
    left: theme.spacing(2),
  }),
);

CalendarWeekGridControlsButtonPrev.defaultProps = {
  className: `${CalendarWeekGridControlsButton.defaultProps.className} CalendarWeekGridControlsButtonPrev`,
};

export const CalendarWeekGridControlsButtonNext = styled(CalendarWeekGridControlsButton)({
  right: 0,
});

CalendarWeekGridControlsButtonNext.defaultProps = {
  className: `${CalendarWeekGridControlsButton.defaultProps.className} CalendarWeekGridControlsButtonNext`,
};

export const CALENDAR_CELL_HEIGHT = 1;

export const CalendarWeekGridCellStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '0 0 auto',
  height: theme.spacing(CALENDAR_CELL_HEIGHT),

  '&:nth-of-type(even):not(:last-of-type)': {
    borderBottom: theme.mixins.borderValue(),
  },
}));

CalendarWeekGridCellStyled.defaultProps = {
  className: 'CalendarWeekGridCell',
};

export const CalendarWeekGridHeaderCellStyled = styled(CalendarWeekGridCellStyled)(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(6.5),
  paddingTop: 3,
  borderTop: theme.mixins.borderValue(),
  borderBottom: theme.mixins.borderValue(),
  backgroundColor: theme.palette.background.paper,
}));

CalendarWeekGridHeaderCellStyled.defaultProps = {
  className: 'CalendarWeekGridHeaderCellStyled',
};

export interface CalendarWeekGridHeaderCellDateProps {
  isToday?: boolean;
}

export const CalendarWeekGridHeaderCellDate = styled(
  Typography,
)<CalendarWeekGridHeaderCellDateProps>(({ theme, isToday }) => ({
  borderRadius: '50%',
  width: 30,
  height: 30,
  lineHeight: '30px',
  textAlign: 'center',

  ...(isToday && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  }),
}));

CalendarWeekGridHeaderCellDate.defaultProps = {
  className: 'CalendarWeekGridHeaderCell-Date',
  variant: 'h2',
};

export const CalendarWeekGridHeaderCellDayOfWeek = styled(
  Typography,
)<CalendarWeekGridHeaderCellDateProps>(({ theme, isToday }) => ({
  color: isToday ? theme.palette.primary.main : theme.palette.text.secondary,
}));

CalendarWeekGridHeaderCellDate.defaultProps = {
  className: 'CalendarWeekGridHeaderCell-DayOfWeek',
  variant: 'h2',
};

export interface CalendarRecordCellProps {
  size: number;
  isPast?: boolean;
}

export const CalendarRecordCell = styled(Box)<CalendarRecordCellProps>(
  ({ theme, size, onClick }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: theme.spacing(size * CALENDAR_CELL_HEIGHT),
    cursor: onClick ? 'pointer' : undefined,

    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 2,
      bottom: 1,
      left: 2,
      borderRadius: theme.shape.borderRadiusSm,
    },

    ...(size === 1 && {
      '& .CalendarRecordCell-Content': {
        lineHeight: '7px',

        '& .MuiTypography-root': {
          lineHeight: 'inherit',
        },
      },
    }),
  }),
);

CalendarRecordCell.defaultProps = {
  className: 'CalendarRecordCell',
};

export const CalendarRecordCellContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 6,
  bottom: 0,
  left: 6,
  borderRadius: theme.shape.borderRadiusSm,
  overflow: 'hidden',
  zIndex: 1,
}));

CalendarRecordCellContent.defaultProps = {
  className: 'CalendarRecordCell-Content',
};

export const CalendarRecordCellDraftStyled = styled(CalendarRecordCell)(({ theme }) => ({
  '&:before': {
    backgroundColor: theme.palette.common.grey7,
  },

  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
}));

CalendarRecordCellDraftStyled.defaultProps = {
  className: `${CalendarRecordCell.defaultProps.className} ${CalendarRecordCell}-Draft`,
};
export const CalendarRecordCellSlotStyled = styled(CalendarRecordCell)(({ theme, isPast }) => ({
  '&:before': {
    backgroundColor: isPast ? theme.palette.common.grey7 : theme.palette.success.superLight,
  },

  '& .MuiTypography-root': {
    color: isPast ? theme.palette.text.secondary : theme.palette.success.main,
  },
}));

CalendarRecordCellSlotStyled.defaultProps = {
  className: `${CalendarRecordCell.defaultProps.className} ${CalendarRecordCell}-Slot`,
};

export interface CalendarRecordCellActiveProps extends CalendarRecordCellProps {
  shadowed?: boolean;
}

export const CalendarRecordCellActiveStyled = styled(
  CalendarRecordCell,
)<CalendarRecordCellActiveProps>(({ theme, isPast, shadowed }) => ({
  zIndex: 1,

  '&:before': isPast
    ? {
        backgroundColor: shadowed ? theme.palette.common.light2 : theme.palette.common.grey,
      }
    : {
        backgroundColor: shadowed ? theme.palette.success.light : theme.palette.success.main,
      },

  '& .MuiTypography-root': {
    color: isPast && shadowed ? theme.palette.text.secondary : theme.palette.common.white,
  },
}));

CalendarRecordCellActiveStyled.defaultProps = {
  className: `${CalendarRecordCell.defaultProps.className} ${CalendarRecordCell}-Active`,
};
