import {
  AnnualPlan,
  AnnualPlanRecordAssessment,
  AnnualPlanRecordEvent,
  AnnualPlanRecordTypes,
  ApiError,
  Assessment,
  EventsStatuses,
  GET_ANNUAL_PLAN_QUERY,
  getAssessment,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SubmitHandler } from 'react-hook-form-lts';
import { useNavigate } from 'react-router-dom';

import {
  useAssessmentActions,
  UseAssessmentActionsReturn,
} from '../../../../Assessments/AssessmentsCreateModal/useAssessmentActions';
import { usePeriodValidation } from '../../../../School/SchoolPeriods/SchoolPeriodsUpdate/usePeriodValidation';
import { AnnualPlannerCreateForm } from '../../scheme';
import { AnnualPlannerCreateRecordContext } from '../WithAnnualPlannerCreate';

export interface AnnualPlannerCreateAssessmentContextProps {
  onPreview: UseAssessmentActionsReturn['handlePreview'];
  disableSaveAssessment: boolean;
  setDisableSaveAssessment: Dispatch<
    SetStateAction<AnnualPlannerCreateAssessmentContextProps['disableSaveAssessment']>
  >;
  isSavingAssessment: boolean;
  canDeleteAssessment: boolean;
  deleteAssessment: () => Promise<void>;
  isDeletingAssessment: boolean;
  submitAssessment: SubmitHandler<AnnualPlannerCreateForm & { withNotifications?: boolean }>;
  addAssessmentToQueryCache: (data: Assessment) => void;
  updateAssessmentInQueryCache: (id: Assessment['id'], data: Partial<Assessment>) => void;
  deleteAssessmentFromQueryCache: (id: Assessment['id'], withFollowing?: boolean) => void;
}

export const AnnualPlannerCreateAssessmentContext =
  createContext<AnnualPlannerCreateAssessmentContextProps>({
    onPreview: () => {},
    disableSaveAssessment: false,
    setDisableSaveAssessment: () => {},
    isSavingAssessment: false,
    canDeleteAssessment: false,
    deleteAssessment: async () => {},
    isDeletingAssessment: false,
    submitAssessment: () => {},
    addAssessmentToQueryCache: () => {},
    updateAssessmentInQueryCache: () => {},
    deleteAssessmentFromQueryCache: () => {},
  });

export const WithAnnualPlannerCreateAssessment: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { record, close } = useContext(AnnualPlannerCreateRecordContext);

  const { showError } = useNotifications();
  const { validatePastDate } = usePeriodValidation();

  const onPreview = useCallback<AnnualPlannerCreateAssessmentContextProps['onPreview']>(
    (id: string) => {
      close();
      navigate({
        pathname: `/planner/assessments/${id}`,
        // can not use neither useLocation nor useSearchParams as the .search is empty
        // there at the moment by any reason
        search: window.location.search,
      });
    },
    [close, navigate],
  );

  const assessmentActions = useAssessmentActions({
    onClose: close,
    onPreview,
  });

  const [disableSaveAssessment, setDisableSaveAssessment] = useState(false);
  const [fetchingOriginal, setFetchingOriginal] = useState(false);

  const queryClient = useQueryClient();

  const isPublished =
    (record?.details as AnnualPlanRecordEvent)?.status === EventsStatuses.Published;
  const isActive = record && isPublished && validatePastDate(record?.start);

  const canDeleteAssessment = Boolean(
    record && record.type === AnnualPlanRecordTypes.ASSESSMENT && !isPublished && !isActive,
  );

  const addAssessmentToQueryCache = useCallback<
    AnnualPlannerCreateAssessmentContextProps['addAssessmentToQueryCache']
  >(
    (data) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        return {
          ...input,
          records: [
            ...input.records,
            {
              type: AnnualPlanRecordTypes.ASSESSMENT,
              id: data.id,
              name: data.name,
              display_name: data.display_name,
              assessment_date: data.assessment_date,
              status: data.status,
              method_ids: data.methods.map((method) => method.method_id!),
              group_ids: data.groups.map((group) => group.id),
              recurring_state: data.recurring_state,
              can_be_edited: data.can_be_edited,
            },
          ],
        };
      });
    },
    [queryClient],
  );

  const updateAssessmentInQueryCache = useCallback<
    AnnualPlannerCreateAssessmentContextProps['updateAssessmentInQueryCache']
  >(
    (id, data) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        return {
          ...input,
          records: input.records.map((record) =>
            record.type === AnnualPlanRecordTypes.ASSESSMENT && record.id === id
              ? {
                  ...record,
                  ...data,
                }
              : record,
          ),
        };
      });
    },
    [queryClient],
  );

  const deleteAssessmentFromQueryCache = useCallback<
    AnnualPlannerCreateAssessmentContextProps['deleteAssessmentFromQueryCache']
  >(
    (id, withFollowing) => {
      queryClient.setQueriesData<AnnualPlan>([GET_ANNUAL_PLAN_QUERY], (input) => {
        if (!input) {
          return input;
        }

        const index = input.records.findIndex(
          (record) => record.type === AnnualPlanRecordTypes.ASSESSMENT && record.id === id,
        );

        const deletingRecord = input.records[index] as AnnualPlanRecordAssessment;

        const recordsStart = input.records.slice(0, index);
        let recordsEnd = input.records.slice(index + 1);

        if (withFollowing) {
          recordsEnd = recordsEnd.filter(
            (record) =>
              !(
                record.type === AnnualPlanRecordTypes.ASSESSMENT &&
                record.recurring_state?.recurrence_id &&
                record.recurring_state.recurrence_id ===
                  deletingRecord.recurring_state?.recurrence_id
              ),
          );
        }

        return {
          ...input,
          records: [...recordsStart, ...recordsEnd],
        };
      });
    },
    [queryClient],
  );

  const deleteAssessment = useCallback<
    AnnualPlannerCreateAssessmentContextProps['deleteAssessment']
  >(async () => {
    const details = record?.details as AnnualPlanRecordAssessment;

    if (!details.id) {
      return;
    }

    return assessmentActions.deleteAssessment({
      assessmentId: details.id,
      confirmed: false,
      name: details.name,
      recurringState: details.recurring_state,
    });
  }, [assessmentActions, record?.details]);

  const submitAssessment = useCallback<
    AnnualPlannerCreateAssessmentContextProps['submitAssessment']
  >(
    async ({ withNotifications, ...values }) => {
      if (!values.assessment?.name) {
        return;
      }

      if (values.originId) {
        try {
          setFetchingOriginal(true);

          const data = await getAssessment({ id: values.originId });

          setFetchingOriginal(false);

          if (!data) {
            return;
          }

          const useAssessmentNameAsDisplayName = data.name === data.display_name;
          const displayName = useAssessmentNameAsDisplayName
            ? values.assessment.name
            : data.display_name;

          await assessmentActions.updateAssessment({
            assessmentId: data.id,
            form: {
              ...data,
              name: values.assessment.name,
              display_name: displayName,
              useAssessmentNameAsDisplayName,
              assessment_date: values.date[0],
            },
            confirmed: false,
            showRecurringConfirmDialog: Boolean(data?.recurring_state),
            recurringStateHasChanges: false,
            recurrenceId: data.recurring_state?.recurrence_id,
            withNotifications,
            withPreview: false,
          });
        } catch (error) {
          showError(error as ApiError);
        }
      } else {
        await assessmentActions.createAssessment({
          form: {
            name: values.assessment.name,
            display_name: values.assessment.name,
            useAssessmentNameAsDisplayName: true,
            assessment_date: values.date[0],
            group_ids: [],
            groups: [],
            methods: [],
            recurring_state: null,
          },
          withPreview: false,
          withNotifications,
        });
      }
    },
    [assessmentActions, showError],
  );

  const value = useMemo<AnnualPlannerCreateAssessmentContextProps>(
    () => ({
      onPreview,
      disableSaveAssessment,
      setDisableSaveAssessment,
      isSavingAssessment: fetchingOriginal || assessmentActions.saving,
      canDeleteAssessment,
      deleteAssessment,
      isDeletingAssessment: assessmentActions.deleting,
      submitAssessment,
      addAssessmentToQueryCache,
      updateAssessmentInQueryCache,
      deleteAssessmentFromQueryCache,
    }),
    [
      onPreview,
      disableSaveAssessment,
      fetchingOriginal,
      assessmentActions.saving,
      assessmentActions.deleting,
      canDeleteAssessment,
      deleteAssessment,
      submitAssessment,
      addAssessmentToQueryCache,
      updateAssessmentInQueryCache,
      deleteAssessmentFromQueryCache,
    ],
  );

  return (
    <AnnualPlannerCreateAssessmentContext.Provider value={value}>
      {children}
    </AnnualPlannerCreateAssessmentContext.Provider>
  );
};

export const useAnnualPlannerCreateAssessment = () => {
  return useContext(AnnualPlannerCreateAssessmentContext);
};
