import { Avatar, Icon, Stack, Typography } from '@mui/material';
import { useGetSchoolQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { CameraIcon } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SchoolAvatar } from '../../../../components/uikit-components/Sidebar/SchoolSelect/SchoolAvatar';
import { AddLogoLayout, MainContentWrapper } from './SchoolGeneralLogo.styled';

export const SchoolGeneralLogo: FC = () => {
  const { schoolId = '' } = useAuth();
  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });

  return (
    <Avatar
      sx={{
        flex: '0 0 auto',
        width: 68,
        height: 68,
        border: (theme) => theme.mixins.borderControlValue(),
        bgcolor: 'background.paper',
        '&: hover': {
          '.addLogo': {
            visibility: 'visible',
          },
        },
      }}
    >
      <AddLogoLayout className="addLogo">
        <Icon sx={{ display: 'flex', color: (theme) => theme.palette.primary.main }}>
          <CameraIcon />
        </Icon>
        <Typography variant="h3">
          <FormattedMessage id={currentSchool?.image ? 'action-ChangeLogo' : 'action-AddLogo'} />
        </Typography>
      </AddLogoLayout>

      <MainContentWrapper>
        {currentSchool?.image ? (
          <SchoolAvatar lightSkeleton school={currentSchool} />
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              height: '100%',
              bgcolor: 'background.default',
            }}
          >
            <Typography variant="h1" color="common.grey3">
              {currentSchool?.code ?? ''}
            </Typography>
          </Stack>
        )}
      </MainContentWrapper>
    </Avatar>
  );
};
