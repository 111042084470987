import { Icon, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { NoteIcon, theme } from '@schooly/style';
import { FC, useState } from 'react';

interface CompanyNoteProps {
  text: string;
  sx?: Omit<SxProps, 'children'>;
}
export const CompanyNote: FC<CompanyNoteProps> = ({ text, sx }) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      sx={(theme) => ({
        height: 32,
        width: 32,
        border: theme.mixins.borderValue(),
        borderColor: open ? theme.palette.common.light2 : 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: `${theme.shape.borderRadiusSm}px`,
        backgroundColor: open ? theme.palette.background.paper : undefined,
        ...(sx ?? {}),
      })}
    >
      <Tooltip
        title={<Typography color="primary.main">{text}</Typography>}
        open={open}
        arrow={false}
        componentsProps={{
          tooltip: {
            sx: {
              border: theme.mixins.borderValue(),
              padding: theme.spacing(1.25),
              minWidth: 300,
            },
          },
        }}
        placement="bottom-end"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [8, -6],
              },
            },
          ],
        }}
      >
        <Icon>
          <NoteIcon />
        </Icon>
      </Tooltip>
    </Stack>
  );
};
