import { IconButton, Stack, Typography } from '@mui/material';
import { GetProductsQueryFilters, PRODUCTS_QUERY_FILTER_KEYS } from '@schooly/api';
import { FiltersContainer, MoreButton, MoreButtonOption } from '@schooly/components/filters';
import { CloseSmallIcon } from '@schooly/style';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type ProductFiltersProps = {
  defaultShowAllFrequencies: boolean;
  filters: GetProductsQueryFilters;
  defaultFilters: GetProductsQueryFilters;
  onSetShowAllFrequencies: Dispatch<SetStateAction<boolean>>;
  showAllFrequencies: boolean;
};

export const ProductFilters: FC<ProductFiltersProps> = ({
  defaultShowAllFrequencies,
  filters: actualFilters,
  defaultFilters,
  onSetShowAllFrequencies,
  showAllFrequencies,
}) => {
  const { $t } = useIntl();
  const moreButton = useRef<MoreButton | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetProductsQueryFilters>(actualFilters);

  const [draftShowAllFrequencies, setDraftShowAllFrequencies] = useState(defaultShowAllFrequencies);

  const handleApply = useMemo(() => {
    if (showAllFrequencies === draftShowAllFrequencies) return undefined;
    return () => {
      onSetShowAllFrequencies(draftShowAllFrequencies);
    };
  }, [draftShowAllFrequencies, onSetShowAllFrequencies, showAllFrequencies]);

  const handleResetToDefault = useCallback(() => {
    setDraftFilters(defaultFilters);
    setDraftShowAllFrequencies(defaultShowAllFrequencies);
  }, [defaultFilters, defaultShowAllFrequencies]);

  const handleClearFilters = useCallback(() => {
    setDraftFilters(defaultFilters);
    setDraftShowAllFrequencies(false);
  }, [defaultFilters]);

  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    setDraftFilters((filters) => ({ ...filters, [v]: filters[v] !== undefined ? undefined : [] }));
  }, []);

  const filterOptions: MoreButtonOption<keyof GetProductsQueryFilters>[] = [];

  return (
    <FiltersContainer onApply={handleApply}>
      {draftShowAllFrequencies && (
        <Stack direction="row" gap={1}>
          <Typography variant="h3">
            <FormattedMessage id="frequencies-ShowDisabledFrequencies" />
          </Typography>
          <IconButton
            sx={{ fontSize: (theme) => theme.spacing(2) }}
            onClick={() => setDraftShowAllFrequencies(false)}
          >
            <CloseSmallIcon />
          </IconButton>
        </Stack>
      )}

      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterOptions}
        selectedOptions={PRODUCTS_QUERY_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        onToggleOption={toggleFiltersVisible}
        toggleOptions={[
          {
            key: 'frequencies-ShowDisabledFrequencies',
            onToggle: () => setDraftShowAllFrequencies((s) => !s),
            selected: draftShowAllFrequencies,
            label: $t({ id: 'frequencies-ShowDisabledFrequencies' }),
          },
        ]}
      />
    </FiltersContainer>
  );
};
