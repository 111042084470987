import { Stack, Typography } from '@mui/material';
import { EventCriteria } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';

import { useSchool } from '../../../hooks/useSchool';
import { useEventTags } from '../EventsGrid';

export interface EventPreviewCriteriaProps {
  criteria: EventCriteria;
}

export const EventPreviewCriteria: FC<EventPreviewCriteriaProps> = ({ criteria }) => {
  const { schoolId } = useSchool();

  const tags = useEventTags({
    schoolId,
    criteria,
    showComma: true,
    divider: <Typography variant="h3">{' ,'}</Typography>,
    unionDivider: (
      <Typography variant="h3" sx={{ pr: 0.75 }}>
        +
      </Typography>
    ),

    tagRenderer: (n) => (
      <TagSelect
        sx={(theme) => ({
          ...theme.typography.h3,
          padding: theme.spacing(0.75),
          gap: 1,
          borderRadius: '300px',
          '.MuiChip-label': {
            color: theme.palette.common.grey2,
          },
          backgroundColor: theme.palette.background.paper,
        })}
        label={n.name}
        key={n.id}
      />
    ),
  });

  return (
    <Stack direction="row" gap={0.5} alignItems="center" width="100%" flexWrap="wrap">
      {tags}
    </Stack>
  );
};
