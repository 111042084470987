import { SubmitErrorHandler } from 'react-hook-form-lts';

// This helper would scroll to the first appearing error by name property from errors list
export const scrollToErrorInput: SubmitErrorHandler<any> = (errors) => {
  const pointToElements: HTMLElement[] = [];

  const tryFindAndAddElementByName = (name: string) => {
    const relatedEl = document.getElementsByName(name)[0];
    if (!relatedEl) return null;

    pointToElements.push(relatedEl);
    return relatedEl;
  };

  for (const [errorKey, errorValue] of Object.entries(errors)) {
    if (Array.isArray(errorValue)) {
      errorValue.map((e, i) => {
        if (!e) return null;
        const foundEl = tryFindAndAddElementByName(`${errorKey}.${i}.value`);
        if (!foundEl) {
          tryFindAndAddElementByName(errorKey);
        }
        return null;
      });

      continue;
    }

    tryFindAndAddElementByName(errorKey);
  }

  const sortedElements = pointToElements
    .filter((el) => !!el)
    .sort((a, b) => (a?.getBoundingClientRect().top || 0) - (b?.getBoundingClientRect().top || 0));

  sortedElements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};
