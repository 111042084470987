import { Box, Stack, Typography } from '@mui/material';
import { theme } from '@schooly/style';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import GroupStubArrowImg from '../../../assets/images/group-stub-arrow.svg';
import GroupStubArrowNarrowImg from '../../../assets/images/group-stub-arrow-narrow.svg';
import GroupStubArrowWideImg from '../../../assets/images/group-stub-arrow-wide.svg';

interface AssessmentsEmptyStubProps {
  textId?: string;
}

export const GroupTabEmptyStub: React.FC<AssessmentsEmptyStubProps> = ({ textId }) => {
  const { formatMessage } = useIntl();

  return (
    <Stack justifyContent="center" alignItems="center" position="relative">
      <Box
        component="img"
        src="/images/no-search-results.svg"
        alt={formatMessage({ id: 'groups-Assessment-NoAssessments' })}
        sx={(theme) => ({
          width: 300,
          mt: 5,
          mb: 2.5,
          [theme.breakpoints.down('lg')]: {
            width: 200,
            mt: 6,
          },
          [theme.breakpoints.down('md')]: {
            mt: 11,
          },
        })}
      />
      <Typography color="text.primary" textAlign="center">
        <FormattedMessage id="groups-NoResultsMatching" />
        <br />
        <FormattedMessage id={textId} />
      </Typography>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          left: 'calc(50% + 170px)',

          [theme.breakpoints.down('lg')]: {
            left: 'calc(50% + 150px)',
          },

          bottom: (theme) => theme.spacing(2),

          '& img': {
            width: 'auto',
            height: 'auto',
          },
        })}
      >
        <picture>
          <source
            media={`(max-width: ${theme.breakpoints.values.md}px)`}
            srcSet={GroupStubArrowNarrowImg}
          />
          <source
            media={`(max-width: ${theme.breakpoints.values.lg}px)`}
            srcSet={GroupStubArrowWideImg}
          />
          <img src={GroupStubArrowImg} alt="arrow" />
        </picture>
      </Box>
    </Stack>
  );
};
