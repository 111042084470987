/* eslint-disable no-case-declarations */
import { Stack } from '@mui/material';
import { acceptInvite } from '@schooly/api';
import { Invite } from '@schooly/api';
import { Loading, ModalConfirm } from '@schooly/style';
import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { getUserFullName } from '../../../helpers/users';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import { mapAdultChildAssociationToUser } from './helpers';
import { InviteCard } from './InviteCard';
import { InviteContent } from './InviteContent';

interface InviteModalProps {
  isOpen: boolean;
  inviteId: string;
  isLoading: boolean;
  onClose: VoidFunction;
  data?: Invite;
  error?: string;
}

const InviteModal: React.FC<InviteModalProps> = ({
  isOpen,
  data,
  inviteId,
  isLoading: externalIsLoading,
  error: externalError,
  onClose,
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState<'name' | 'children' | 'school' | 'warning'>('name');
  const { school_name, adult_child_associations: childrenItems, school_image } = data || {};

  const handleClose = useCallback(() => {
    if (step !== 'warning') {
      setStep('warning');
      return;
    }
    navigate('/');
  }, [navigate, step]);

  const acceptInviteRequest = useCallback(async () => {
    const isSucceed = await acceptInvite(inviteId);
    if (isSucceed) {
      onClose();
      window.location.href = '/';
    }
  }, [inviteId, onClose]);

  const [performRequest, isLoading, error] = useRequestWithProgress(
    acceptInviteRequest,
    undefined,
    true,
  );

  const handleConfirm = useCallback(() => {
    switch (step) {
      case 'name': {
        const newStep = childrenItems && childrenItems?.length > 0 ? 'children' : 'school';
        setStep(newStep);
        break;
      }

      case 'children':
        setStep('school');
        break;

      case 'school':
        performRequest();
        break;

      default:
        break;
    }
  }, [childrenItems, performRequest, step]);

  const title = useMemo(() => {
    if (!data) return null;

    const name = getUserFullName(data);

    switch (step) {
      case 'name':
        return <FormattedMessage id="inviteModal-ConfirmName" values={{ name }} />;

      case 'children':
        return <FormattedMessage id="inviteModal-Children" values={{ name }} />;

      case 'school':
        return <FormattedMessage id="inviteModal-AreYouSure" values={{ school: school_name }} />;

      case 'warning':
        return <FormattedMessage id="inviteModal-Restart" />;

      default:
        return null;
    }
  }, [data, school_name, step]);

  const children = useMemo(() => {
    if (step !== 'children') return null;

    return childrenItems?.map((child) => {
      const mappedChild = mapAdultChildAssociationToUser(child);
      return <InviteCard key={mappedChild.id} user={mappedChild} userType="child" />;
    });
  }, [childrenItems, step]);

  const icon = useMemo(() => {
    if (['school', 'warning'].includes(step)) return 'tick';

    return 'arrow';
  }, [step]);

  const confirmTextId = useMemo(() => {
    if (step === 'school') return 'action-Confirm';

    if (step === 'warning') return 'action-Okay';

    return 'action-Continue';
  }, [step]);

  if (isLoading || externalIsLoading) return <Loading />;

  return (
    <Modal isOpen={isOpen}>
      {error || externalError ? (
        <InviteContent
          imageSrc={school_image}
          onlyCancelButton
          onClose={() => navigate('/')}
          icon="tick"
          title={error?.reason || error?.message || externalError}
        />
      ) : (
        <InviteContent
          imageSrc={school_image}
          onClose={handleClose}
          headerTitle={step === 'warning' ? null : school_name}
          icon={icon}
          onConfirm={handleConfirm}
          confirmTextId={confirmTextId}
          title={title}
          onlyCancelButton={step === 'warning'}
        >
          {!!children?.length && (
            <Stack pt={2.5} gap={1.75}>
              {children}
            </Stack>
          )}
        </InviteContent>
      )}
    </Modal>
  );
};

export default InviteModal;

type ModalProps = PropsWithChildren<{
  isOpen: boolean;
}>;

const Modal: FC<ModalProps> = ({ isOpen, children }) => (
  <ModalConfirm
    open={isOpen}
    sx={{
      '& .MuiDialog-paperFullWidth': {
        width: 700,
        height: 'unset',
      },
      '& .MuiBackdrop-root': {
        backgroundColor: '#56668133',
      },
    }}
    fullWidth
  >
    {children}
  </ModalConfirm>
);
