import { IconButton, Stack } from '@mui/material';
import { CrossIcon, ModalHeader, ModalPanelProps } from '@schooly/style';
import React, { FC, PropsWithChildren } from 'react';

type RolloverHeaderProps = {
  title: string;
  onClose: () => void;
} & Omit<ModalPanelProps, 'title'>;

export const RolloverHeader: FC<PropsWithChildren<RolloverHeaderProps>> = ({
  title,
  onClose,
  children,
  ...props
}) => {
  return (
    <ModalHeader title={title} active {...props}>
      <Stack direction="row" gap={2.5}>
        {children}
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </Stack>
    </ModalHeader>
  );
};
