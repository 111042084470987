import { Box } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, SignUpStatuses } from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import { format } from 'date-fns';
import moment, { Moment } from 'moment';
import { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SignUpForm, useSignUp } from '../../../context/signUps/WithSignUp';

export const SignUpDateSelector: FC = () => {
  const { $t } = useIntl();
  const { signUp } = useSignUp();
  const { watch, control } = useFormContext<SignUpForm>();

  const type = watch('type');
  const event = watch('event');

  const isPastDay = useCallback(
    (day: string | Moment | Date) => {
      if (!day) {
        return true;
      }

      const date = moment(day).set({ hours: 0, minutes: 0, seconds: 0 });
      const today = moment().set({ hours: 0, minutes: 0, seconds: 0 });

      if (
        signUp?.status !== SignUpStatuses.Draft &&
        signUp?.end &&
        date.isSame(moment(signUp.end))
      ) {
        // currently set date of existing signup is a valid date anyway
        return false;
      }

      return date.isBefore(today);
    },
    [signUp?.end, signUp?.status],
  );

  const isAfterEventDay = useCallback(
    (day: string | Moment | Date) => {
      if (!day) {
        return true;
      }

      if (!event) {
        return false;
      }

      const date = moment(day).set({ hours: 0, minutes: 0, seconds: 0 });
      const start = moment(event.start).set({ hours: 0, minutes: 0, seconds: 0 });

      return date.isSameOrAfter(start);
    },
    [event],
  );

  if (!type) {
    return null;
  }

  return (
    <Box
      width="100%"
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          minWidth: 328,
        },
      })}
    >
      <Controller
        control={control}
        name="end"
        rules={{
          required: true,

          validate: (value) => {
            if (!value) return;

            if (isPastDay(value)) {
              return $t({ id: 'eventSignUps-create-Date-MustBeInFuture' });
            }

            if (isAfterEventDay(value)) {
              return $t({ id: 'eventSignUps-create-Date-MustBeBeforeEvent' });
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <DateSelect
              ref={field.ref}
              onSetDate={(date) => {
                field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
              }}
              CalendarProps={{
                shouldDisableDate: (value) => isPastDay(value) || isAfterEventDay(value),
              }}
              date={field.value}
              placeholder={$t({ id: 'eventSignUps-create-Date' })}
              error={fieldState.error}
              onClear={() => field.onChange('')}
            />
          );
        }}
      />
    </Box>
  );
};
