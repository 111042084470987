import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 18C17.9569 17.9122 17.9036 17.8294 17.8398 17.7543L10.7114 9.34749C10.4167 8.99998 9.98471 8.91312 9.62138 9.0869C9.54439 9.12372 9.47048 9.17224 9.40162 9.23247C9.36216 9.26698 9.32436 9.30533 9.28858 9.34752L2.66313 17.1611L2.65909 17.1659L2.16016 17.7543C2.09644 17.8294 2.04305 17.9122 2 18L18 18Z"
      fill="#B3BBCB"
    />
    <circle cx="10" cy="5" r="3" fill="#B3BBCB" />
  </svg>
);
