import { Box, Stack, styled } from '@mui/material';

export const GroupAttendanceStatisticsEmptyStubStyled = styled(Stack)(({ theme }) => ({
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 'unset',
  marginTop: theme.spacing(4.5),

  '& > img': {
    width: 300,
    height: 300,
    marginTop: 90,
  },

  '& .MuiButton-root': {
    marginTop: theme.spacing(2.5),
  },
}));

export const GroupPreviewAttendanceEmptyStubArrow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 'calc(50% + 170px)',
  bottom: theme.spacing(2),

  '& img': {
    width: 'auto',
    height: 'auto',
  },
}));
