import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import {
  ApplicationChild,
  ApplicationCustomField,
  ApplicationDefaultCustomFields,
  CustomField,
} from '@schooly/api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RowsContainer } from '@schooly/components/applications';
import { Genders, getLabelIdForOption } from '@schooly/constants';
import {
  Attention2Icon,
  BehaviorIcon,
  CalendarIcon,
  DateIcon,
  EditIcon,
  FemaleIcon,
  FlagIcon,
  MaleIcon,
  MedicalIcon,
  NewTabIcon,
  PlusIcon,
  PuzzleIcon,
  SimpleButton,
  theme,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { formatApplicationDate } from '@schooly/utils/application-helpers';
import { getNationalitiesLabelIds } from '@schooly/utils/get-nationalities-label-ids';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
// eslint-disable-next-line @nx/enforce-module-boundaries
import Tag from 'apps/web/src/components/ui/Tag';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { GridRowName } from 'apps/web/src/components/uikit-components/Grid/Grid';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LongNameWithVerticalTooltip } from 'apps/web/src/components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import moment from 'moment';
import { FC, PropsWithChildren, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getCustomFieldsToShow } from '../../utils';
import { PreviewFormType } from '../ApplicationEditablePreviewContent';
import { ApplicationAdditionalInformationPreview } from './ApplicationAdditionalInformationPreview';
import { ApplicationPreviewInfoRow } from './ApplicationPreviewInfoRow';
import { LanguageRow } from './LanguageRow';
import { SchoolHistoryRow } from './SchoolHistoryRow';

interface ApplicationChildrenPreviewProps extends PropsWithChildren {
  child: ApplicationChild;
  index: number;
  editable?: boolean;
  updating?: Record<string, boolean>;
  updatingIcon?: React.ReactNode;
  applicationCustomFields?: CustomField[];
  readonlyIcon?: React.ReactNode;
  withoutActionBackground?: boolean;
  isConverted?: boolean;
  onEdit?: (type: PreviewFormType, customField?: ApplicationCustomField) => void;
}

export function getCustomFieldIcon(customFieldLabel: ApplicationCustomField['label']) {
  switch (customFieldLabel) {
    case ApplicationDefaultCustomFields.MedicalNeeds: {
      return <MedicalIcon />;
    }
    case ApplicationDefaultCustomFields.SpecialNeeds: {
      return <Attention2Icon className="reset-svg-currentColor" />;
    }
    case ApplicationDefaultCustomFields.BehaviourConcerns: {
      return <BehaviorIcon />;
    }

    default:
      return <PuzzleIcon />;
  }
}

export const ApplicationChildrenPreview: FC<ApplicationChildrenPreviewProps> = ({
  child,
  index,
  editable,
  onEdit,
  children,
  updating,
  updatingIcon,
  readonlyIcon,
  withoutActionBackground,
  isConverted,
}) => {
  const { formatMessage } = useIntl();

  const yearsOld = moment(child.preferred_start_date).diff(moment(child.date_of_birth), 'years');
  const nationalities = useMemo(
    () =>
      getNationalitiesLabelIds(child.nationalities)
        .map((id) => formatMessage({ id }))
        .join(', '),
    [child.nationalities, formatMessage],
  );

  const customFieldsToShow = useMemo(
    () => getCustomFieldsToShow(child.custom_fields_values ?? []),
    [child.custom_fields_values],
  );

  return (
    <Stack pt={index === 0 ? 2 : 4.5} key={child.id}>
      <Box
        sx={{
          '& :hover': {
            '.userInfo': {
              visibility: 'visible',
            },
          },
        }}
      >
        <Stack direction="row" gap={2} alignItems="center">
          {isConverted && !!child?.relation_id ? (
            <Stack
              direction="row"
              gap={1}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  '.subtitle': {
                    color: theme.palette.common.grey2,
                  },
                  '.newTabIcon': {
                    color: theme.palette.common.grey2,
                  },
                },
              }}
              alignItems="center"
              onClick={() => {
                window.open(`/students/${child.relation_id}`, '_blank');
              }}
            >
              <Typography variant="h2" className="subtitle">
                {getUserFullName(child)}
              </Typography>
              <IconButton size="small" className="newTabIcon">
                <NewTabIcon />
              </IconButton>
            </Stack>
          ) : (
            <Typography variant="h2">{getUserFullName(child)}</Typography>
          )}

          {editable ? (
            <Stack>
              <IconButton
                className="userInfo"
                inverse
                sx={{ visibility: 'hidden' }}
                onClick={() => onEdit?.('userInfo')}
              >
                <EditIcon />
              </IconButton>
            </Stack>
          ) : (
            readonlyIcon
          )}
        </Stack>
      </Box>
      <Stack direction="row" spacing={2.5} pt={2.5}>
        <Stack flex="1 1 50%" spacing={2} sx={{ overflowX: 'hidden' }}>
          <RowsContainer containerTitle="applications-PreferredStartingDate">
            <ApplicationPreviewInfoRow
              onEdit={() => onEdit?.('startedDate')}
              editable={editable}
              editIconName="startedDate"
              updating={updating?.['startedDate']}
              updatingIcon={updatingIcon}
              readonlyIcon={readonlyIcon}
              withoutActionBackground={withoutActionBackground}
            >
              <Icon>
                <CalendarIcon />
              </Icon>
              <GridRowName
                sx={{
                  minWidth: 0,
                }}
              >
                <LongNameWithVerticalTooltip>
                  {formatApplicationDate(child.preferred_start_date)}
                </LongNameWithVerticalTooltip>
              </GridRowName>
            </ApplicationPreviewInfoRow>
          </RowsContainer>
          <RowsContainer containerTitle="applications-PersonalInformation">
            <ApplicationPreviewInfoRow
              onEdit={() => onEdit?.('dateOfBirth')}
              editable={editable}
              editIconName="dateOfBirth"
              updating={updating?.['dateOfBirth']}
              updatingIcon={updatingIcon}
              readonlyIcon={readonlyIcon}
              withoutActionBackground={withoutActionBackground}
            >
              <GridRowName>
                <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Icon sx={{ color: 'text.secondary' }}>
                    <DateIcon />
                  </Icon>
                  {formatApplicationDate(child.date_of_birth)}
                  <Tag>{`${yearsOld} y.o`}</Tag>
                </Typography>
              </GridRowName>
            </ApplicationPreviewInfoRow>

            {Number.isInteger(child.gender) && (
              <ApplicationPreviewInfoRow
                onEdit={() => onEdit?.('gender')}
                editable={editable}
                editIconName="gender"
                updating={updating?.['gender']}
                updatingIcon={updatingIcon}
                readonlyIcon={readonlyIcon}
                withoutActionBackground={withoutActionBackground}
              >
                <Icon>{child.gender === Genders.Male ? <MaleIcon /> : <FemaleIcon />}</Icon>
                <GridRowName>
                  {formatMessage({
                    id: getLabelIdForOption({
                      key: Genders[child.gender],
                      optionsKey: 'gender',
                    }),
                  })}
                </GridRowName>
              </ApplicationPreviewInfoRow>
            )}

            <ApplicationPreviewInfoRow
              onEdit={() => onEdit?.('nationalities')}
              editable={editable}
              editIconName="nationalities"
              updating={updating?.['nationalities']}
              updatingIcon={updatingIcon}
              readonlyIcon={readonlyIcon}
              withoutActionBackground={withoutActionBackground}
            >
              <Icon>
                <FlagIcon />
              </Icon>
              <GridRowName>
                <TypographyWithOverflowHint variant="h3">
                  {nationalities}
                </TypographyWithOverflowHint>
              </GridRowName>
            </ApplicationPreviewInfoRow>

            <LanguageRow
              editable={editable}
              onEdit={() => onEdit?.('language')}
              language={child.language}
              updating={updating?.['language']}
              updatingIcon={updatingIcon}
              readonlyIcon={readonlyIcon}
              withoutActionBackground={withoutActionBackground}
            />

            {child.other_languages_spoken?.map((l, i) => (
              // We don't have any id here
              <LanguageRow
                editable={editable}
                onEdit={() => onEdit?.('language')}
                language={l}
                key={i}
                updating={updating?.['language']}
                updatingIcon={updatingIcon}
                readonlyIcon={readonlyIcon}
                withoutActionBackground={withoutActionBackground}
              />
            ))}
          </RowsContainer>
        </Stack>
        <Stack flex="1 1 50%" spacing={2.5}>
          <ApplicationAdditionalInformationPreview
            customFields={customFieldsToShow}
            editable={editable}
            updating={updating}
            updatingIcon={updatingIcon}
            readonlyIcon={readonlyIcon}
            withoutActionBackground={withoutActionBackground}
            onEdit={(customField) => onEdit?.('customFields', customField)}
          />
          {(!!child.school_history?.length || !!editable) && (
            <RowsContainer containerTitle="applications-PreviousSchools">
              {!!child.school_history?.length ? (
                <Stack>
                  {child.school_history?.map((history, i) => (
                    // We don't have any id here
                    <SchoolHistoryRow
                      schoolHistory={history}
                      key={i}
                      onEdit={() => onEdit?.('schoolHistory')}
                      editable={editable}
                      editIconName="schoolHistory"
                      updating={updating?.['schoolHistory']}
                      updatingIcon={updatingIcon}
                      readonlyIcon={readonlyIcon}
                      withoutActionBackground={withoutActionBackground}
                    />
                  ))}
                </Stack>
              ) : (
                <ApplicationPreviewInfoRow
                  onEdit={() => onEdit?.('schoolHistory')}
                  editable={editable}
                  updating={updating?.['schoolHistory']}
                  updatingIcon={updatingIcon}
                  readonlyIcon={readonlyIcon}
                >
                  <SimpleButton startIcon={<PlusIcon />} onClick={() => onEdit?.('schoolHistory')}>
                    <Typography variant="h3">
                      <FormattedMessage id="applications-SchoolHistory-AddSchool" />
                    </Typography>
                  </SimpleButton>
                </ApplicationPreviewInfoRow>
              )}
            </RowsContainer>
          )}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};
