import { IconButton, Stack, Typography } from '@mui/material';
import { getUserRolesAvailableForRelation, UserRole, UserRoleList } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { InformationIcon, Loading } from '@schooly/style';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupCard } from '../../../components/common/GroupCard/GroupCard';
import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import SearchInput from '../../../components/ui/SearchInput';
import { ModalContent } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import { ManageGroupModalTooltip } from '../../Groups/ManageGroupModal/ManageGroupModalTooltip';
import { DEBOUNCE_TIME } from '../../ProfileModal/tabs/ProfileModalGroups';
import { useManageUserRoleModal } from './useManageUserRoleModal';

export interface AddUserRoleExternalSidebarProps {
  selectedRoles?: UserRole[];
  onSelect: (role: UserRole) => void;
}

export const AddUserRoleExternalSidebar: React.FC<AddUserRoleExternalSidebarProps> = ({
  selectedRoles,
  onSelect,
}) => {
  const [filter, setFilter] = useState('');
  const [requestedFilter, setRequestedFilter] = useState('');
  const [userRoles, setUserRoles] = useState<UserRoleList>();

  const { relationId } = useManageUserRoleModal();

  const { $t } = useIntl();

  const { schoolId } = useAuth();

  const getRolesList = useCallback(
    async (searchQuery: string) => {
      if (!schoolId || !relationId) {
        return;
      }

      const userRoles = await getUserRolesAvailableForRelation(
        relationId,
        searchQuery ?? undefined,
      );

      setUserRoles(userRoles);
    },
    [relationId, schoolId],
  );

  const [getUsersRoleList, isUsersRoleListLoading] = useRequestWithProgress(getRolesList);

  const debouncedFetchResults = useMemo(
    () =>
      debounce(async () => {
        await getUsersRoleList(filter);

        setRequestedFilter(filter);
      }, DEBOUNCE_TIME),
    [getUsersRoleList, filter],
  );

  useEffect(() => {
    getUsersRoleList();
  }, [getUsersRoleList]);

  const filteredList = useMemo(() => {
    if (!userRoles) {
      return userRoles;
    }

    const selectedRoleIds = selectedRoles?.map((role) => role.id) ?? [];

    const list = (Object.keys(userRoles) as Array<keyof UserRoleList>).reduce<UserRoleList>(
      (acc, key) => {
        const arr = userRoles[key]
          .filter((role) => !selectedRoleIds.includes(role.id))
          .sort((a, b) => a.name.localeCompare(b.name));

        return {
          ...acc,
          [key]: arr,
        };
      },
      {} as UserRoleList,
    );

    return list;
  }, [selectedRoles, userRoles]);

  useEffect(() => {
    if (!isUsersRoleListLoading && requestedFilter !== filter) {
      debouncedFetchResults();

      return () => {
        debouncedFetchResults.cancel();
      };
    }
  }, [debouncedFetchResults, isUsersRoleListLoading, filter, requestedFilter]);

  const userRolesCount = useMemo(
    () =>
      (filteredList?.available_to_assign.length ?? 0) +
      (filteredList?.not_allowed_to_assign.length ?? 0),
    [filteredList?.available_to_assign.length, filteredList?.not_allowed_to_assign.length],
  );

  return (
    <Stack sx={{ height: '100%' }}>
      <ModalHeader title={$t({ id: 'userRoles-UserRoles' })} active />
      <Stack pt={2} px={2}>
        <SearchInput
          value={filter}
          onChange={setFilter}
          isValueRemovable
          placeholder={$t(
            { id: 'userRoles-SearchAmongRoles' },
            {
              userRolesCount,
            },
          )}
        />
      </Stack>

      <ModalContent flat sx={{ pt: 0, px: 2 }} active>
        {isUsersRoleListLoading && <Loading />}
        {!userRolesCount && !isUsersRoleListLoading && <NoSearchResultsFound />}
        <Stack gap={1}>
          {filteredList?.available_to_assign.map((role) => (
            <GroupCard
              id={role.id}
              title={role.name}
              withBorder
              selectable
              onSelect={() => onSelect(role)}
              withoutTitleLink
            />
          ))}
        </Stack>
        {!!filteredList?.not_allowed_to_assign?.length && (
          <Stack gap={1} pt={!!filteredList.available_to_assign.length ? 2.5 : 0}>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography variant="h4" color="text.primary">
                <FormattedMessage id="userRoles-NotAllowedToAssign" />
              </Typography>
              {/*  TODO move tooltip to ui-kit */}
              <ManageGroupModalTooltip
                text={
                  <Typography color="primary.main">
                    <FormattedMessage id="userRoles-NoPermissionsToManage" />
                  </Typography>
                }
                arrow
              >
                <IconButton inverse>
                  <InformationIcon />
                </IconButton>
              </ManageGroupModalTooltip>
            </Stack>

            {filteredList?.not_allowed_to_assign.map((group) => (
              <GroupCard title={group.name} id={group.id} withBorder locked withoutTitleLink />
            ))}
          </Stack>
        )}
      </ModalContent>
    </Stack>
  );
};
