import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form-lts/dist/types/fields';
import { FieldPath, FieldPathValue } from 'react-hook-form-lts/dist/types/path';
import { Validate } from 'react-hook-form-lts/dist/types/validator';
import { useIntl } from 'react-intl';

export const useFormValidation = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>() => {
  const { $t } = useIntl();

  const mustBeInteger = useCallback<
    Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues>
  >(
    (value) => {
      if (!value) {
        return undefined;
      }

      const isNumber = /^\s*\d+\s*$/.test(value);

      if (!isNumber) {
        return $t({ id: 'input-ErrorMustBeNumber' });
      }

      return Number.isSafeInteger(parseInt(value))
        ? undefined
        : $t({ id: 'input-ErrorNumberUnsafe' });
    },
    [$t],
  );

  return { mustBeInteger };
};
