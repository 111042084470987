import { PlusIcon, TypographyWithOverflowHint } from '@schooly/style';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../../components/uikit/Button/Button';
import buildClassName from '../../utils/buildClassName';

interface AddButtonProps extends PropsWithChildren {
  onClick?: () => void;
  className?: string;
  labelText?: string;
  labelTextId?: string;
  testId?: string;
}

const AddButton: React.FC<AddButtonProps> = ({
  onClick,
  className,
  labelText,
  labelTextId,
  children,
  testId,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      type="button"
      variant="text"
      startIcon={<PlusIcon className="plus-icon" />}
      aria-label={formatMessage({ id: 'ariaLabel-Create' })}
      onClick={onClick}
      className={buildClassName('reset-button-styles', className)}
      data-test-id={testId}
    >
      <TypographyWithOverflowHint className={className}>
        {labelText ?? formatMessage({ id: labelTextId })}
      </TypographyWithOverflowHint>
      {children}
    </Button>
  );
};

export default AddButton;
