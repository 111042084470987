import { Box, Icon, Skeleton, Stack, StackProps } from '@mui/material';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { ChevronUpIcon, CrossIcon } from '@schooly/style';
import { FC, PropsWithChildren, ReactNode } from 'react';

export type ExpandedSelectProps = PropsWithChildren<{
  renderContent: (onClose: () => void) => ReactNode | ReactNode[];
  onClickInputArea?: () => void;
  onClear?: () => void;
  onClose: () => void;
  hasSelectedValue: boolean;
  onFetchNextPage?: () => Promise<any>;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  maxHeight?: number;
  width?: number | string;
  showNextPageLoading?: boolean;
}> &
  StackProps;
export const ExpandedSelect: FC<ExpandedSelectProps> = ({
  children,
  hasSelectedValue,
  hasNextPage,
  isFetchingNextPage,
  onClear,
  onClickInputArea,
  onClose,
  onFetchNextPage,
  renderContent,
  maxHeight = 300,
  width = 400,
  showNextPageLoading = true,
}) => {
  const RightIcon = hasSelectedValue && onClear ? CrossIcon : ChevronUpIcon;
  const loaderRef = useInfiniteScroll(
    onFetchNextPage ? isFetchingNextPage : true,
    onFetchNextPage,
    hasNextPage,
  );

  return (
    <Stack
      sx={{
        width,
      }}
    >
      <Stack
        className="header"
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5,
          position: 'relative',
          minHeight: theme.spacing(5),
          padding: theme.spacing(0.5, 5, 0.5, 1),
          borderBottom: `1px solid ${theme.palette.common.light3}`,
          flexWrap: 'wrap',
        })}
      >
        {onClickInputArea && (
          <Box
            position="absolute"
            left={0}
            top={0}
            right={0}
            bottom={0}
            onClick={onClickInputArea}
          />
        )}
        {children}
        <Icon
          sx={(theme) => ({
            fontSize: theme.spacing(2),
            cursor: 'pointer',
            position: 'absolute',
            right: theme.spacing(1.5),
            top: '11.5px',
            color: theme.palette.common.grey,
            '&:hover': {
              color: theme.palette.text.primary,
            },
          })}
          onClick={hasSelectedValue && onClear ? onClear : onClose}
        >
          <RightIcon />
        </Icon>
      </Stack>
      <Stack sx={{ maxHeight, overflowY: 'scroll' }}>
        {renderContent(onClose)}
        {hasNextPage && (
          <Stack gap={1} m={showNextPageLoading ? 1 : 0}>
            <div ref={loaderRef} />
            {showNextPageLoading && (
              <>
                <Skeleton variant="rectangular" height={20} width="90%" />
                <Skeleton variant="rectangular" height={20} width="80%" />
              </>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
