import {
  ParentSchoolRelation,
  PaymentFrequencyType,
  StudentSchoolRelation,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { Loading, ModalLarge } from '@schooly/style';
import { isNotEmpty } from '@schooly/utils/predicates';
import React, { FC } from 'react';

import { useProfile } from '../../../../../context/profile/useProfile';
import { useRouter } from '../../../../../context/router/useRouter';
import { useSchool } from '../../../../../hooks/useSchool';
import useSchoolYears from '../../../../../hooks/useSchoolYears';
import { SingleInvoiceContent, SingleInvoiceData } from './SingleInvoicesContent';

export const SingleInvoiceForParentModal: FC = () => {
  const { goBack } = useRouter();
  const { isLoading, oneOffFrequency, schoolMembership } = useSingleInvoices();

  const parent =
    schoolMembership?.role === SchoolUserRole.Parent
      ? (schoolMembership as unknown as ParentSchoolRelation)
      : null;

  const students = parent
    ? parent.children.map((c) => c.school_user_relation).filter(isNotEmpty)
    : null;

  const studentRelation: SingleInvoiceData | null =
    parent && !!students?.length
      ? {
          students,
          currentGuardian: { ...parent, primary: false },
        }
      : null;

  return (
    <ModalLarge open onClose={goBack}>
      {isLoading || !studentRelation || !oneOffFrequency ? (
        <Loading />
      ) : (
        <SingleInvoiceContent
          singleInvoiceData={studentRelation}
          oneOffFrequency={oneOffFrequency}
        />
      )}
    </ModalLarge>
  );
};

export const SingleInvoiceForStudentModal: FC = () => {
  const { goBack } = useRouter();

  const { isLoading, oneOffFrequency, schoolMembership } = useSingleInvoices();

  const student =
    schoolMembership?.role === SchoolUserRole.Student
      ? (schoolMembership as unknown as StudentSchoolRelation)
      : null;

  const guardians =
    student?.guardian_relations.map((g) => ({ ...g.guardian, primary: !!g.primary })) ?? [];

  if (!isLoading && !guardians.length) goBack();

  const singleInvoiceData: SingleInvoiceData | null = student
    ? {
        students: [student],
        guardians,
        currentGuardian: guardians[0],
      }
    : null;

  return (
    <ModalLarge open onClose={goBack}>
      {isLoading || !singleInvoiceData || !oneOffFrequency ? (
        <Loading />
      ) : (
        <SingleInvoiceContent
          singleInvoiceData={singleInvoiceData}
          oneOffFrequency={oneOffFrequency}
        />
      )}
    </ModalLarge>
  );
};

const useSingleInvoices = (schoolYearId?: string) => {
  const { schoolId = '' } = useSchool();
  const { defaultValidity } = useSchoolYears();

  const yearId = schoolYearId ?? defaultValidity?.id;

  const { schoolMembership, isFetching: isProfileFetching } = useProfile();

  const { data, isLoading: isPaymentFrequencyLoading } = useGetSchoolPaymentFrequencies(
    {
      school_id: schoolId,
      year_id: yearId!,
    },
    { refetchOnMount: 'always', enabled: Boolean(yearId && schoolId) },
  );

  const oneOffFrequency = data?.frequencies.find((f) => f.type === PaymentFrequencyType.OneOff);
  const isLoading = isPaymentFrequencyLoading || isProfileFetching;

  return {
    isLoading,
    oneOffFrequency,
    schoolMembership,
  };
};
