import { Icon, IconButton, Stack, Typography } from '@mui/material';
import { isSignUpRegular, isSignUpSlots, SignUpStatuses } from '@schooly/api';
import {
  CalendarIcon,
  Counter,
  DownloadIcon,
  IconDropdownMenu,
  ListViewIcon,
  ModalSearch,
  Spin,
} from '@schooly/style';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSignUp } from '../../../../context/signUps/WithSignUp';
import { SignUpPreviewRespondentsCalendar } from './SignUpPreviewRespondentsCalendar';
import { SignUpPreviewRespondentsList } from './SignUpPreviewRespondentsList';

export const SignUpPreviewRespondents: FC = () => {
  const { $t } = useIntl();
  const { signUp, query, setQuery, download, downloading } = useSignUp();

  const isSlots = isSignUpSlots(signUp);

  const [view, setView] = useState<'calendar' | 'list'>(isSlots ? 'calendar' : 'list');

  if (!signUp || signUp.status === SignUpStatuses.Draft) {
    return null;
  }

  const count = isSignUpRegular(signUp)
    ? signUp.places
      ? `${signUp.signed_up_count ?? 0}/${signUp.places}`
      : signUp.signed_up_count
    : signUp?.signed_up_count; // don't display `0` count, will be a placeholder instead

  return (
    <Stack pt={2} flex="1 1 auto" overflow="hidden">
      <Stack direction="row" alignItems="center" flex="0 0 auto">
        <Typography variant="h2">
          <FormattedMessage id="eventSignUps-preview-Respondents-Title" />
        </Typography>
        {count ? <Counter>{count}</Counter> : null}

        <Stack
          flex="1 1 auto"
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2.5}
        >
          {Boolean(signUp.signed_up_count) && (
            <ModalSearch
              value={query}
              onChange_MemoizedCallbackOnly={setQuery}
              placeholder={$t({ id: 'people-Search' })}
            />
          )}

          {isSlots && (
            <IconDropdownMenu
              closeOnSelect
              actions={[
                {
                  view: 'calendar',
                  icon: <CalendarIcon />,
                  title: $t({ id: 'eventSignUps-preview-Respondents-View-Calendar' }),
                  handler: () => setView('calendar'),
                },
                {
                  view: 'list',
                  icon: <ListViewIcon />,
                  title: $t({ id: 'eventSignUps-preview-Respondents-View-List' }),
                  handler: () => setView('list'),
                },
              ]}
              isSelected={(item) => item.view === view}
              icon={
                <IconButton
                  inverse
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {view === 'calendar' ? <CalendarIcon /> : <ListViewIcon />}
                </IconButton>
              }
            />
          )}

          {Boolean(signUp.signed_up_count) && (
            <>
              <IconButton onClick={download}>
                {downloading ? (
                  <Icon color="primary">
                    <Spin />
                  </Icon>
                ) : (
                  <DownloadIcon />
                )}
              </IconButton>
            </>
          )}
        </Stack>
      </Stack>

      {view === 'calendar' && <SignUpPreviewRespondentsCalendar />}
      {view === 'list' && <SignUpPreviewRespondentsList />}
    </Stack>
  );
};
