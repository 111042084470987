import {
  Child,
  SchoolUserType,
  StatusRegistration,
  SyncUser,
  User,
  UserSummary,
  WithAddress,
  WithName,
  WithNationality,
} from '@schooly/api';
import { Countries, Nationalities, SchoolUserRole, Titles } from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import moment from 'moment';

export function getUserFullName(user: WithName, userGivenName?: boolean) {
  if (!user) return '';

  let first_name = user.given_name;

  if (!userGivenName && user.known_as) {
    first_name = user.known_as;
  }

  if (!user.last_name) {
    return first_name ?? '';
  }

  return `${first_name} ${user.last_name}`;
}

export function getUserFullNameWithTitle(user: WithName) {
  if ((!user.title && user.title !== 0) || !Titles[user.title]) {
    return getUserFullName(user, true);
  }

  return `${Titles[user.title]}. ${getUserFullName(user, true)}`;
}

export function getUserKnownName(user: WithName) {
  return user.known_as || user.given_name;
}

export function getUserInitials(user: WithName) {
  const { given_name, last_name, known_as } = user;

  const firstName = known_as || given_name;

  const g = firstName ? firstName.trim() : '';
  const l = last_name ? last_name.trim() : '';
  return `${g.charAt(0)}${l.charAt(0)}`;
}

export function getUserPrepopulatedNames(prepopulatedName?: string): string[] {
  if (!prepopulatedName) {
    return [];
  }

  const parts = prepopulatedName.split(' ');

  if (parts.length === 1) {
    return [prepopulatedName];
  }

  const [lastName, ...givenName] = parts.reverse();

  return [givenName.reverse().join(' '), lastName || ''];
}

export function getUserAge(user: UserSummary) {
  if (!user.date_of_birth) {
    return undefined;
  }
  return moment().diff(newDateTimezoneOffset(user.date_of_birth), 'years');
}

export function getUserNationalityValues(user?: WithNationality, sorted = true) {
  if (!user) return [];

  const otherNationalities = user.other_nationalities || [];

  const nationalities =
    user.nationality !== null && user.nationality !== undefined
      ? [user.nationality, ...otherNationalities]
      : [];

  if (sorted) {
    return nationalities.sort((a, b) => a - b);
  }

  return nationalities;
}

export function getUserNationalitiesList(user: WithNationality) {
  const nationalities = getUserNationalityValues(user);

  if (!nationalities.length) return '';

  return nationalities.map((n) => Nationalities[n]).join(', ');
}

export function getUserAddress(addressValues: WithAddress) {
  const { address_line_1, address_line_2, city, region, zip_code, country } = addressValues;

  let address = '';
  if (address_line_1) {
    address += `${address_line_1},\n`;
  }
  if (address_line_2) {
    address += `${address_line_2},\n`;
  }
  if (city) {
    address += `${city}`;
  }
  if (region) {
    address += ` ${region}`;
  }
  if (zip_code) {
    if (city || region) {
      address += ',';
    }
    address += ` ${zip_code}`;
  }
  if (hasFieldValue(country)) {
    address += ` ${Countries[country!]}`;
  }

  if (!address.length) {
    return undefined;
  }

  return address;
}

export function getUserHasStudentMembership(user: User | SyncUser | Child | StatusRegistration) {
  const hasStudentRole =
    'school_relations' in user &&
    user.school_relations.some(({ role }) => role === SchoolUserRole.Student);
  const hasSchoolUserRelation =
    'school_user_relation' in user &&
    user.school_user_relation &&
    !!Object.keys(user.school_user_relation).length;

  if (hasStudentRole || hasSchoolUserRelation) {
    return true;
  }
  return 'student_relations' in user && !!user.student_relations?.length;
}

export function getUserStudentMembership(user: Child, schoolId: string) {
  return user.student_relations?.length
    ? user.student_relations.find(({ school_id }) => school_id === schoolId)
    : undefined;
}

export function getChildPrimaryContact(user: SyncUser) {
  const guardianRelation = user.guardian_relations.find(({ primary }) => !!primary);
  return guardianRelation?.guardian;
}

export function getAdultUserTypes(user?: SyncUser): SchoolUserType[] | undefined {
  const userTypes: SchoolUserType[] = [];

  if (!user) return undefined;

  if (user.adult_associations.length) {
    userTypes.push('parent');
  }

  if (user.school_relations.find((s) => s.role === SchoolUserRole.Staff)) {
    userTypes.push('staff');
  }

  return userTypes;
}

export function hasFieldValue(value: any) {
  return value?.toString() === '0' || (Array.isArray(value) ? !!value.length : !!value);
}
