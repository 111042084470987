import {
  GET_STUDENTS_INCONSISTENCY_LIST_QUERY,
  useGetSchoolReEnrollmentStatus,
  useStartRolloverMutation,
} from '@schooly/api';
import {
  instanceOfStudentRolloverParams,
  RolloverConfigParams,
  RolloverInconsistenciesContent,
  RolloverInProgressContent,
} from '@schooly/components/annual-roll-over';
import { useAuth } from '@schooly/components/authentication';
import { ConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { ModalLarge, ModalSmall } from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRouter } from '../../context/router/useRouter';
import { useSchool } from '../../hooks/useSchool';
import useSchoolYears from '../../hooks/useSchoolYears';
import AccessDenied from '../AccessDenied';
import { RolloverConfigurationModal } from './RolloverConfigurationModal';

type RolloverModalContent = 'inconsistencies' | 'configuration' | 'confirm' | 'finished';

export const RolloverModal: FC = () => {
  const navigate = useNavigate();
  const {
    state: { initialState, backgroundLocation },
  } = useLocation();
  const [rolloverConfigParams, setRolloverConfigParams] = useState<RolloverConfigParams>();
  const rolloverParams = instanceOfStudentRolloverParams(initialState) ? initialState : null;
  const [modalContent, setModalContent] = useState<RolloverModalContent>('inconsistencies');
  const { schoolId } = useSchool();
  const { schoolYears } = useSchoolYears();
  const startRolloverMutation = useStartRolloverMutation();
  const queryClient = useQueryClient();
  const { permissions } = useAuth();
  const isStudentManager = permissions.includes('student_manager');
  const isAdmin = permissions?.includes('school_admin');
  const { data } = useGetSchoolReEnrollmentStatus(schoolId ?? '');
  const invalidateQueries = useInvalidateListQueriesFor('student');
  const { goBack } = useRouter();

  const { $t } = useIntl();

  const { activePropertiesMap } = useSchoolProperties({
    schoolId: schoolId ?? '',
    userType: SchoolUserRole.Student,
  });

  const prospectiveStatusOptions = useMemo(
    () =>
      activePropertiesMap.status
        .filter((s) => !s.category?.final && !s.category?.current)
        .map((s) => ({
          value: s.id,
          label: s.name,
        })),
    [activePropertiesMap.status],
  );

  const handleRolloverConfirm = useCallback(async () => {
    if (!rolloverParams) return;
    const { total, ...configParams } = rolloverConfigParams ?? {};
    await startRolloverMutation.mutateAsync({
      ...rolloverParams,
      ...(configParams && configParams),
    });
    setModalContent('finished');
  }, [rolloverConfigParams, rolloverParams, startRolloverMutation]);

  if (!rolloverParams || !schoolId || !isStudentManager) {
    return (
      <ModalLarge open>
        <AccessDenied />
      </ModalLarge>
    );
  }

  const renderContent = () => {
    switch (modalContent) {
      case 'inconsistencies':
        return (
          <ModalLarge open>
            <RolloverInconsistenciesContent
              params={rolloverParams}
              schoolYears={schoolYears}
              schoolId={schoolId}
              onClose={goBack}
              onRowClick={(id) => window.open(`/students/${id}#registrations`, '_blank')}
              onShowFamily={(id) => window.open(`/students/${id}#family`, '_blank')}
              onRollover={() => setModalContent('configuration')}
            />
          </ModalLarge>
        );
      case 'configuration':
        const year = schoolYears.find((y) => y.id === rolloverParams.year_to_id);
        if (!year) return null;
        return (
          <RolloverConfigurationModal
            year={year}
            onClose={goBack}
            statusOptions={prospectiveStatusOptions}
            isAdmin={isAdmin}
            onRollover={(rolloverParams) => {
              setRolloverConfigParams(rolloverParams);
              setModalContent('confirm');
            }}
            rolloverParams={rolloverParams}
            schoolId={schoolId}
          />
        );
      case 'confirm': {
        const yearName = schoolYears?.find((y) => y.id === rolloverParams.year_to_id)?.name ?? '';

        const studentsCount = rolloverConfigParams?.total ?? 0;
        return (
          <ConfirmationDialog
            isOpen
            onConfirm={handleRolloverConfirm}
            onClose={() => setModalContent('configuration')}
            titleTextId={'students-AnnualRollover-Confirm'}
            textValues={{
              yearName,
              studentsCount: `${studentsCount} ${
                studentsCount === 1
                  ? $t({ id: 'students-AnnualRollover-RolloverStudent' })
                  : $t({ id: 'students-AnnualRollover-RolloverStudent-plural' })
              }`,
            }}
            confirmTextId={'action-Confirm'}
            cancelTextId={'action-Cancel'}
            isLoading={startRolloverMutation.isLoading}
          />
        );
      }
      case 'finished': {
        return (
          <ModalSmall
            sx={{
              '& .MuiDialog-paperFullWidth': {
                width: 600,
                height: 'auto',
              },
            }}
            open
          >
            <RolloverInProgressContent
              onClose={() => {
                queryClient.resetQueries([GET_STUDENTS_INCONSISTENCY_LIST_QUERY]);
                invalidateQueries();
                navigate(backgroundLocation?.pathname ? backgroundLocation.pathname : '/students', {
                  replace: true,
                });
              }}
              isReEnrollmentEnabled={Boolean(data?.enabled)}
            />
          </ModalSmall>
        );
      }

      default:
        return null;
    }
  };

  return renderContent();
};
