import { FC } from 'react';

export const IncompleteSvg: FC = () => (
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1934 128.226V236.236C17.1934 239.801 21.0688 242.049 24.1692 240.266L176.553 151.828C179.886 149.89 181.901 146.364 181.901 142.527V34.6725C181.901 30.7195 177.638 28.2392 174.189 30.2157L23.7429 116.91C19.6737 119.235 17.1934 123.537 17.1934 128.226Z"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M76.2168 210.038L90.8661 221.703C94.0052 224.183 98.3457 224.532 101.834 222.478L137.372 201.744C139.697 200.388 139.542 197.016 137.139 195.853L117.064 186.358"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M123.305 195.737V254.954C123.305 256.93 125.436 258.132 127.141 257.163L210.658 208.681C212.479 207.634 213.603 205.697 213.603 203.565V144.464C213.603 142.294 211.278 140.937 209.379 142.023L126.87 189.536C124.661 190.815 123.305 193.179 123.305 195.737Z"
      fill="#24275B"
      stroke="#24275B"
      strokeMiterlimit="10"
    />
    <path
      d="M131 233.37C131 233.37 166.595 227.055 158.583 221.052C153.401 217.149 146.564 218.898 144.285 220.512C142.005 222.126 148.959 227.081 166.602 217.943C184.245 208.804 162.371 206.954 160.275 209.069C158.179 211.185 165.133 217.908 185.215 204.161"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M144 205.553C144 205.553 169.262 201.013 170.862 197.482C172.477 194.016 161.836 197.696 162.701 194.369C163.566 191.042 184.448 189.178 186.455 184.907C188.463 180.636 176.878 185.385 181.323 180.925C185.768 176.465 201.184 174.334 204.761 169.055"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M145.627 178.801L168.5 143L189.381 153.494"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.0518 141.171V168.493C34.0518 169.384 35.0206 169.965 35.8345 169.5L92.3001 137.218C93.1527 136.714 93.6565 135.822 93.6565 134.854V107.57C93.6565 106.563 92.5714 105.943 91.7188 106.446L35.6795 138.341C34.6718 138.923 34.0518 140.008 34.0518 141.171Z"
      fill="#E7E9F4"
    />
    <path d="M256 45V224.279" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M270.766 64.668V214.881" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M241.429 64.668V214.881" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M233.329 30.9512V59.7847" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M278.827 30.9512V59.7847" stroke="#E7E9F4" strokeMiterlimit="10" />
    <path
      d="M256.078 223.756L241.429 214.882L256.078 206.007L270.766 214.882L256.078 223.756Z"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M256.078 184.963L241.429 176.088L256.078 167.252L270.766 176.088L256.078 184.963Z"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M256.078 146.17L241.429 137.334L256.078 128.459L270.766 137.334L256.078 146.17Z"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M256.078 107.415L241.429 98.5402L256.078 89.7041L270.766 98.5402L256.078 107.415Z"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M278.827 59.7852L256.078 73.5043L233.329 59.7852"
      stroke="#E7E9F4"
      strokeMiterlimit="10"
    />
    <path
      d="M256.078 44.7095L233.329 30.9516L256.078 17.2324L278.827 30.9516L256.078 44.7095Z"
      stroke="#E7E9F4"
      strokeMiterlimit="10"
    />
    <path
      d="M270.766 98.5395L256.078 73.5039L241.429 98.5395L256.078 146.169L270.766 98.5395Z"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M241.429 64.668L256.078 107.414L270.766 64.668"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M241.429 137.334L256.078 107.415L270.766 137.334L256.078 184.963L241.429 137.334Z"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M270.766 176.089L256.078 146.17L241.429 176.089L256.078 223.757L270.766 176.089Z"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M270.766 214.882L256.078 184.963L241.429 214.882"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M250.149 48.585L161.749 102.066"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M238.444 42.4229L161.71 88.851"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M187.288 86.5643V73.3877L198.992 79.5109V92.7263L187.288 86.5643Z"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path d="M233.5 60V31L255.5 44.5V73L233.5 60Z" fill="#24275B" stroke="#24275B" />
    <path
      d="M212.866 71.1021V57.8867L224.57 64.0487V77.2641L212.866 71.1021Z"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M246.118 46.4531L238.444 55.638L250.148 53.1189"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
    />
    <path
      d="M238.444 42.4229L212.866 71.1014L250.148 61.8002"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
    />
    <path
      d="M250.148 61.8008L173.414 108.19"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M212.866 57.8864L238.444 55.6387L224.57 77.2638L187.288 86.5649L212.866 57.8864Z"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M198.992 92.7267L212.867 71.1016L187.288 73.3881L161.749 102.067L198.992 92.7267Z"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M173.414 108.19L187.288 86.5645L161.71 88.851"
      stroke="#C7C8E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M161.749 102.067L161.71 88.8516L173.414 94.9748V108.19L161.749 102.067Z"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path d="M167.562 105.051V136.675" stroke="#24275B" strokeMiterlimit="10" />
    <path
      d="M167.562 134.488V137.395C167.562 137.395 170.972 135.573 170.972 139.061C170.972 141.077 170.662 142.394 169.112 143.324C167.756 144.099 165.973 143.208 165.896 142.355"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M167.562 135.185C169.445 135.185 170.972 133.658 170.972 131.775C170.972 129.891 169.445 128.364 167.562 128.364C165.678 128.364 164.151 129.891 164.151 131.775C164.151 133.658 165.678 135.185 167.562 135.185Z"
      fill="#C7C8E0"
    />
    <path
      d="M31.4551 127.451L173.297 45.7559"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M36.7266 182.522L94.7811 149.076"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M36.7266 189.42L94.7811 155.975"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M36.7266 195.504L60.4445 181.862"
      stroke="#24275B"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M41.1826 204.379L68.6597 283.322" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M55.8711 195.504L83.3482 274.486" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M77.7671 258.79L63.0791 267.665" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M73.3495 246.272L58.7002 255.109" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M68.9696 233.716L54.3203 242.591" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M64.5907 221.198L49.9414 230.034" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M60.2108 208.642L45.5615 217.516" stroke="#24275B" strokeMiterlimit="10" />
    <path d="M256 44.5V74L279 60V31L256 44.5Z" fill="#E7E9F4" />
  </svg>
);
