import { Box, Divider } from '@mui/material';
import {
  ChartBarData,
  FilterKeys,
  RolloverAggregatedDataResponse,
  RolloverAggregatedKey,
} from '@schooly/api';
import { theme } from '@schooly/style';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ChartBar } from './chartBar/ChartBar';
import { useRenderTooltip } from './chartBar/ChartTooltip';

const BAR_ITEM_LIMIT = 5;

const getPendingLayout = (
  borderColor = theme.palette.primary.main,
  dashColor = theme.palette.common.main5,
) => ({
  color: theme.palette.background.paper,
  decal: {
    color: dashColor,
    dashArrayX: [1, 0],
    dashArrayY: [2, 1.9],
    symbolSize: 0.8,
    rotation: -Math.PI / 4,
  },
  borderColor: borderColor,
  borderWidth: 1,
});

const PENDING_KEY: RolloverAggregatedKey = 'will_enrolled';

type RolloverChartProps = {
  data: RolloverAggregatedDataResponse;
  reEnrollmentEnabled: boolean;
};

const getRolloverAggregatedKeyLabel = (
  key: RolloverAggregatedKey,
  reEnrollmentEnabled: boolean,
) => {
  switch (key) {
    case 'enrolled':
      return 'students-AnnualRollover-AlreadyEnrolled';
    case 'will_enrolled':
      return reEnrollmentEnabled
        ? 'students-AnnualRollover-ReEnrollmentWillRollover'
        : 'students-AnnualRollover-WillRollover';
    case 'pending_parent_response':
      return 'status-rollover-PendingParentResponse';

    default:
      return '';
  }
};

export const RolloverCharts: FC<RolloverChartProps> = ({ data, reEnrollmentEnabled }) => {
  const { $t } = useIntl();

  const props = {
    borderColor: reEnrollmentEnabled ? theme.palette.common.orange : theme.palette.primary.main,
    lineColor: reEnrollmentEnabled ? theme.palette.common.orange5 : theme.palette.common.main5,
  };

  const renderTooltip = useRenderTooltip(true, {
    seriesName: $t({ id: getRolloverAggregatedKeyLabel(PENDING_KEY, reEnrollmentEnabled) }),
    background: `repeating-linear-gradient(-45deg, transparent 0px 1px, ${props.lineColor} 1px 2px)`,
    border: `1px solid ${props.borderColor}`,
  });

  const { chartData, legendData } = useMemo(() => {
    const series = data.result.map(({ rows, key }) => {
      const label = $t({ id: getRolloverAggregatedKeyLabel(key, reEnrollmentEnabled) });
      return {
        name: label,
        dataKey: FilterKeys.Status,
        data: rows.map(({ total }) => total),
        dataValue: label,
        label:
          key === PENDING_KEY
            ? {
                color: theme.palette.primary.main,
              }
            : undefined,
        itemStyle: {
          ...(key === PENDING_KEY && getPendingLayout(props.borderColor, props.lineColor)),
          ...(key === 'pending_parent_response' && { color: theme.palette.common.orange }),
        },
      };
    });
    const normalizedData: ChartBarData = {
      arrange_by: {
        dataKey: FilterKeys.Status,
        data:
          data.result[0]?.rows?.map(({ name, value }) => ({
            name,
            dataValue: value,
          })) ?? [],
      },
      series,
    };

    return { chartData: normalizedData, legendData: series.filter((d) => d.data.some((v) => !!v)) };
  }, [$t, data.result, props.borderColor, props.lineColor, reEnrollmentEnabled]);

  if (!chartData) return null;

  return (
    <Box>
      <Divider sx={{ mb: 4 }} />

      <ChartBar
        barPosition={chartData.arrange_by.data?.length < BAR_ITEM_LIMIT ? 'vertical' : 'horizontal'}
        renderTooltip={renderTooltip}
        chartData={chartData}
        getDataName={(n) => n.name ?? ''}
        legendData={legendData}
        minHeight={358}
        withoutBorder
        gridBottom={40}
      />
    </Box>
  );
};
