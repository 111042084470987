import { IconButton } from '@mui/material';
import { FilterIcon } from '@schooly/style';
import React, { FC } from 'react';

interface SearchInputFilterIconButtonProps {
  onClick: () => void;
  hasAppliedIndicator: boolean;
}

export const SearchInputFilterIconButton: FC<SearchInputFilterIconButtonProps> = ({
  onClick,
  hasAppliedIndicator,
}) => {
  return (
    <IconButton
      onClick={onClick}
      sx={(theme) => ({
        zIndex: 10,
        cursor: 'pointer',
        position: 'absolute',
        color: hasAppliedIndicator ? undefined : 'common.grey',
        right: theme.spacing(1.5),
        '&:hover': {
          color: 'unset',
        },

        '&:after': hasAppliedIndicator
          ? {
              content: "''",
              display: 'block',
              width: '0.4rem',
              height: '0.4rem',
              borderRadius: '50%',
              background: theme.palette.success.main,
              border: '1px solid white',
              position: 'absolute',
              right: '0',
              top: -2,
            }
          : undefined,
      })}
    >
      <FilterIcon />
    </IconButton>
  );
};
