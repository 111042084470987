import { ChipProps } from '@mui/material';
import { ConductVisibility } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

export type ConductVisibilityTagSelectProps = {
  visibility: ConductVisibility;
} & Omit<ChipProps, 'label'>;

export const ConductVisibilityTagSelect: FC<ConductVisibilityTagSelectProps> = ({
  visibility,
  ...props
}) => {
  const { $t } = useIntl();

  return (
    <TagSelect
      label={$t({
        id: visibility === ConductVisibility.PUBLISHED ? 'status-Published' : 'status-NotPublished',
      })}
      {...props}
    />
  );
};
