import { FC, HTMLAttributes } from 'react';

export const ArrowSelectSvg: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="86"
    height="60"
    viewBox="0 0 86 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.89844 59.3469L7.25408 59.6984L7.60553 59.3427L13.9335 52.9392L13.2222 52.2363L7.72591 57.7982C7.81521 45.1301 11.5313 31.1735 22.8947 20.333C34.4016 9.35586 53.8269 1.50019 85.5 1.50019L85.5 0.500192C53.673 0.500191 33.9605 8.39453 22.2045 19.6095C10.5962 30.6835 6.82065 44.9233 6.72608 57.7707L1.19783 52.3077L0.494934 53.019L6.89844 59.3469ZM7.23005 58.2687L7.24569 58.2842L7.26096 58.2687L7.23005 58.2687Z"
      fill="#C7C8E0"
    />
  </svg>
);
