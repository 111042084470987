import { SerializedError } from '@reduxjs/toolkit';
import {
  Genders,
  Languages,
  Nationalities,
  SchoolPropertyType,
  StaffStatuses,
  Titles,
} from '@schooly/constants';

import { IColumnSort, ListPeopleArguments } from './endpoints/people';
import { Group, GroupSubject } from './groups';
import { SchoolProperty } from './schools';
import { FilterKeys } from './users';

export type ValidationError = {
  [Key: string]: string[];
};

export type GroupUserType = 'staff' | 'student';
export type AdultUserType = 'staff' | 'parent';

export type FilterValue = string | number;

export type StringOrNull = string | null;
export type StringArrayOrNull = string[] | null;

export type NumberOrNull = number | null;
export type NumberArrayOrNull = number[] | null;

export type BooleanOrNull = boolean | null;
export type BooleanArrayOrNull = boolean[] | null;

export type NationalityOrNull = Nationalities | null;
export type NationalitiesArrayOrNull = Nationalities[] | null;

export type GenderOrNull = Genders | null;
export type GendersArrayOrNull = Genders[] | null;

export type TitleOrNull = Titles | null;
export type TitlesArrayOrNull = Titles[] | null;

export type LanguageOrNull = Languages | null;
export type LanguagesArrayOrNull = Languages[] | null;

export interface UploadFile {
  mime_type?: string;
  // todo: fix type
  data?: any;
}

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

export interface PagedResponse<T> {
  relation_count?: number;
  count: number;
  results: T[];
  suggested?: T[];
  current_page?: number;
  next_page?: number;
  previous_page?: number;
  total_pages?: number;
}

export interface AvailableCriteria {
  type?: FilterElementType;
  school_property?: SchoolProperty;
  subject?: GroupSubject;
  group?: Group;
  enum_index?: number;
  id?: string;
  user_ids?: string[];
  relation_ids?: string[];
  groups?: string[];
  propGroup?: { id: string | null; name?: string; count: number; propType?: SchoolPropertyType };
  propGroupItems?: AvailableCriteria[];
}

export enum FilterElementType {
  Property,
  Gender,
  Nationality,
  Subject,
  Group,
  TutorGroup = 9,
}

export type ProfileUserType = 'adult' | 'child';

export type SchoolUserType = 'staff' | 'parent' | 'student';

export type UserType = SchoolUserType | ProfileUserType | 'profile';

export interface ApiError extends SerializedError {
  reason?: string;
}

export interface CriteriaUpdate {
  school_property_ids: string[];
  genders: Genders[];
  nationalities: Nationalities[];
  groups?: string[];
  subjects?: string[];
}

export interface EmploymentHistoryEntry {
  employment_status: StaffStatuses;
  end: StringOrNull;
  id: string;
  initials: StringOrNull;
  job_title: StringOrNull;
  school_id: string;
  staff_id: string;
  start: StringOrNull;
  staff_number: string;
}

export type ListGroupFilterKeys = FilterKeys | 'recipientIds' | 'assessmentIds';

export type ListGroupFilters = Partial<Record<ListGroupFilterKeys, FilterValue[]>>;

export interface IListGroupArguments extends Omit<ListPeopleArguments, 'type' | 'sort'> {
  sort?: IColumnSort;
  filters?: ListGroupFilters;
  min?: number;
}

export interface AvailableCriteriaCount {
  type: FilterElementType;
  relation_count: number;
  school_property?: SchoolProperty;
  tutor_group?: Pick<Group, 'id' | 'name'>;
  enum_index?: number;
}

export interface SelectOption<T = FilterValue, I = any> {
  value: T;
  label?: string;
  labelTextId?: string;
  item?: I;
  checkColor?: 'POSITIVE' | 'NEGATIVE';
  disabled?: boolean;
  archived?: boolean;
  group?: string;
}

export interface FilterSelectOption<T = FilterValue, I = any> extends SelectOption<T, I> {
  archived?: boolean;
}

export type GroupOptions = { [key in GroupUserType]?: FilterSelectOption<FilterValue>[] };

export interface PhoneNumberCountry {
  id: string;
  name: string;
  flag: string;
  code: string;
}

export type PeopleListType = SchoolUserType | 'group';

export interface IContextAction {
  titleTextId: string;
  handler: VoidFunction;
}

export interface IActionButton {
  titleTextId: string;
  icon: React.ReactNode;
  onClick?: VoidFunction;
  contextActions?: IContextAction[];
}
