import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM7.8846 3.36156C8.09276 3.15341 8.43025 3.15341 8.63841 3.36157C8.84657 3.56972 8.84657 3.90722 8.63841 4.11538L6.73403 6.01976L8.64062 7.92634C8.83787 8.12359 8.83787 8.44339 8.64062 8.64064C8.44338 8.83788 8.12358 8.83788 7.92633 8.64064L6.01974 6.73405L4.11554 8.63824C3.90738 8.8464 3.56989 8.8464 3.36173 8.63824C3.15357 8.43008 3.15357 8.09259 3.36173 7.88443L5.26593 5.98023L3.35913 4.07343C3.16188 3.87619 3.16188 3.55639 3.35913 3.35914C3.55637 3.1619 3.87617 3.1619 4.07342 3.35914L5.98022 5.26594L7.8846 3.36156Z"
      fill="none"
    />
  </svg>
);
