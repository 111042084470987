import React, { FC, useCallback, useMemo } from 'react';

import { WithReport } from '../../../../../context/report/WithReport';
import { useRouter } from '../../../../../context/router/useRouter';
import {
  ReportCreateModal,
  ReportCreateModalProps,
} from '../../../../Reports/ReportCreateModal/ReportCreateModal';
import { useAnnualPlannerCreateRecord } from '../WithAnnualPlannerCreate';

export const AnnualPlannerExpandedReport: FC = () => {
  const { clean } = useRouter();
  const { record, expandedData, close } = useAnnualPlannerCreateRecord();

  const closeAndClean = useCallback(() => {
    close();
    clean();
  }, [clean, close]);

  const formValuesOverrides = useMemo<
    Exclude<ReportCreateModalProps['formValuesOverrides'], undefined>
  >(
    () => ({
      name: expandedData?.report.name,
      scheduled_publish_date: expandedData?.date[0],
    }),
    [expandedData?.date, expandedData?.report.name],
  );

  return (
    <WithReport id={record?.id}>
      <ReportCreateModal
        formValuesOverrides={formValuesOverrides}
        onClose={close}
        onCloseAndClean={closeAndClean}
      />
    </WithReport>
  );
};
