import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SchoolUserRole } from '../constants/school';
import useAppLocation from './useAppLocation';

export const useProfileSchoolContextType = () => {
  const location = useAppLocation();
  const [searchParams] = useSearchParams();

  const profileContextType = useMemo(() => {
    const type = searchParams.get('type');

    if (type == null) {
      return location.pathname.includes('adults') ? SchoolUserRole.Parent : undefined;
    }

    return Number(type) as SchoolUserRole;
  }, [location.pathname, searchParams]);

  return profileContextType;
};
