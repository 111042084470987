import { Icon, Skeleton, Stack } from '@mui/material';
import {
  FilterSection,
  GetStaffQueryFilters,
  STAFF_ARRANGE_BY_FILTER_KEYS,
  STAFF_QUERY_FILTER_KEYS,
  StaffArrangeBy,
  useGetFiltersQuery,
  useGetSchoolPropertiesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  useChartOpenFromSearchParams,
  useChartZeroesFromSearchParams,
  WithCharts,
} from '@schooly/components/charts';
import { PageHeader, pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SkeletonRows,
} from '@schooly/style';
import React, { useMemo } from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import useUserCounts from '../../hooks/useUserCounts';
import { PAGE_SIZE, SKELETON_COLS, StaffContent } from './StaffContent';
import { StaffEmptyStub } from './StaffEmptyStub';

const StaffPage: React.FC = () => {
  const { userCounts } = useUserCounts();
  const { schoolId = '', relationId = '' } = useAuth();

  const { data } = useGetSchoolPropertiesQuery(
    {
      schoolId,
      userType: SchoolUserRole.Staff,
    },
    { refetchOnMount: 'always' },
  );

  const { data: filters } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Staff },
    { enabled: !!schoolId && !!relationId },
  );

  const initialFiltersAndArrangeBy = useMemo(() => {
    const initialFilters: GetStaffQueryFilters = {};
    let initialArrangeBy: StaffArrangeBy | null = null;

    if (!filters) return;

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      for (const key of STAFF_ARRANGE_BY_FILTER_KEYS) {
        if (key === defaultFilter.filter.arrange_by) initialArrangeBy = key;
      }

      return {
        initialFilters: pickOnlyParamsFromFilterKeys(STAFF_QUERY_FILTER_KEYS, defaultFilter.filter),
        initialArrangeBy,
      };
    }

    if (!data) return;

    for (const property of data.school_properties) {
      if (!property.staff_default) continue;

      const type = property.type;

      switch (type) {
        case SchoolPropertyType.Status:
        case SchoolPropertyType.House:
        case SchoolPropertyType.Department:
          initialFilters[type] = [...(initialFilters[type] || []), property.id];
          break;
      }
    }

    return { initialFilters, initialArrangeBy };
  }, [data, filters]);

  const isChartsOpen = useChartOpenFromSearchParams();
  const showZeroValues = useChartZeroesFromSearchParams();

  const renderContent = () => {
    if (!initialFiltersAndArrangeBy || !schoolId) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <PageHeader pageTitleTextId="section-Staff">
              <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
            </PageHeader>
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_COLS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    if (!userCounts?.staff) {
      return <StaffEmptyStub />;
    }

    return (
      <WithCharts schoolId={schoolId} isOpen={isChartsOpen} showZeroValues={showZeroValues}>
        <StaffContent {...initialFiltersAndArrangeBy} />
      </WithCharts>
    );
  };
  return <MainLayout>{renderContent()}</MainLayout>;
};

export default StaffPage;
