import { Box } from '@mui/material';
import {
  BaseUserSchoolRelation,
  ReportForAssessment,
  useGetReportRecipientsQuery,
} from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { CollapsiblePanel } from '../../../components/common/CollapsiblePanel';
import HeaderFilter from '../../../components/common/HeaderFilter/HeaderFilter';
import { ModalNavigation } from '../../../components/common/ModalNavigation';
import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import PersonCardBasic from '../../../components/common/PersonCard/PersonCardBasic';
import { useCollapsiblePanel } from '../../../context/collapsiblePanel/useCollapsiblePanel';
import { getDefaultDraft } from '../../../context/filters/FiltersContext';
import { useFilters } from '../../../context/filters/useFilters';
import useAppLocation from '../../../hooks/useAppLocation';
import { useDebounce } from '../../../hooks/useDebounce';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import {
  ReportsPreviewContentStyled,
  ReportsPreviewSideBar,
  ReportsPreviewSideBarTab,
} from './ReportsPreviewContent.styled';
import { ReportsPreviewEntries } from './ReportsPreviewEntries/ReportsPreviewEntries';

type ReportsPreviewContentProps = {
  report: ReportForAssessment;
};

export const ReportsPreviewContent: FC<ReportsPreviewContentProps> = ({ report }) => {
  const { id } = useParams<'id'>();
  const { recipient_id: recipientId } = useQueryStringParams();
  const navigate = useNavigate();
  const location = useAppLocation();
  const { formatMessage } = useIntl();
  const { filters, setGroupsListFilters } = useFilters();
  const { state, dispatch } = useCollapsiblePanel();
  const searchHeaderRef = useRef<HTMLDivElement | null>(null);

  const query = useDebounce(filters.draftQuery ?? '', 300);

  const {
    data: requestData,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useGetReportRecipientsQuery(
    {
      report_id: id ?? '',
      query,
      filters: filters.applied ?? getDefaultDraft(),
    },
    { enabled: Boolean(id), refetchOnMount: 'always' },
  );

  const loaderRef = useInfiniteScroll(isFetching, fetchNextPage, hasNextPage);

  const displayedList = useMemo<BaseUserSchoolRelation[]>(() => {
    return (
      requestData?.pages.reduce<BaseUserSchoolRelation[]>(
        (prev, curr) => [...prev, ...curr.results],
        [],
      ) ?? []
    );
  }, [requestData]);

  const selectedRecipient = useMemo<BaseUserSchoolRelation | undefined>(() => {
    if (!displayedList?.length || !recipientId) {
      return undefined;
    }

    return displayedList.find((r) => r.relation_id === recipientId);
  }, [displayedList, recipientId]);

  const handleRecipientClick = useCallback(
    (relationId: string) => {
      navigate(
        { pathname: `/reports/${id}`, search: `?recipient_id=${relationId}` },
        { state: location.state },
      );
    },
    [id, location.state, navigate],
  );

  const firstStudentRelationId = displayedList[0]?.relation_id;

  useEffect(() => {
    if (!!recipientId) return;
    if (!firstStudentRelationId) return;

    handleRecipientClick(firstStudentRelationId);
  }, [firstStudentRelationId, handleRecipientClick, recipientId]);

  /*
   * Set additional filters (assessments ids of current report) for Group filter selector
   * https://treehive.atlassian.net/browse/TR-2135
   */
  useEffect(() => {
    if (report?.assessments) {
      const assessmentIds = Array.from(
        report.assessments.reduce((prev, assessment) => {
          prev.add(assessment.id);
          return prev;
        }, new Set<string>([])),
      );

      setGroupsListFilters({ assessmentIds });
    }
  }, [report.assessments, setGroupsListFilters]);

  const onFilterClick = useCallback(() => {
    if (!state.isOpen && state.menuHover) {
      dispatch({ type: 'reset' });
    }
  }, [dispatch, state.isOpen, state.menuHover]);

  useEffect(() => {
    const filterButtonRef = searchHeaderRef?.current;
    if (filterButtonRef) {
      filterButtonRef.addEventListener('click', onFilterClick);

      return () => {
        filterButtonRef.removeEventListener('click', onFilterClick);
      };
    }
  }, [onFilterClick]);

  const showEmptyStub = !isLoading && !displayedList.length;

  const renderList = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (showEmptyStub) {
      return <NoSearchResultsFound type="small" />;
    }

    return (
      <>
        {displayedList.map((recipient) => (
          <ReportsPreviewSideBarTab
            active={recipient.relation_id === recipientId}
            id={`student-${recipient.relation_id}`}
            key={recipient.relation_id}
            onClick={() => handleRecipientClick(recipient.relation_id)}
          >
            <PersonCardBasic
              user={recipient}
              userType="student"
              tooltipType="vertical"
              isListItem
            />
          </ReportsPreviewSideBarTab>
        ))}

        {hasNextPage && (
          <Box py={3}>
            {isFetchingNextPage && <Loading />}
            <div ref={loaderRef} />
          </Box>
        )}
      </>
    );
  };

  const isPanelOpen = state.isOpen;

  return (
    <>
      <ReportsPreviewContentStyled>
        <CollapsiblePanel
          width={300}
          getSxProps={() => ({
            '& .HeaderFilter__top': {
              pt: 0.5,
              px: 1,
            },
          })}
        >
          <ReportsPreviewSideBar sx={{ '& .HeaderFilter': { flexGrow: 0 } }}>
            <HeaderFilter
              searchPlaceholder={formatMessage({ id: 'people-Search' })}
              modalTitleTextId="reports-Filter-Title"
              modal
              bottomInline
              bottomInlineMax={1}
              ref={searchHeaderRef}
              withoutPopupMouseEvents
              isModalOpen
            />

            <Box
              sx={(theme) => ({
                flex: '1 1 auto',
                px: 2,
                '& .MuiBox-root': {
                  borderRadius: theme.spacing(1),
                  '& .PersonCard__info__username': {
                    maxWidth: '100%',
                  },
                },
              })}
            >
              {renderList()}
            </Box>
          </ReportsPreviewSideBar>
        </CollapsiblePanel>

        <ReportsPreviewEntries
          report={report}
          recipientId={recipientId}
          showEmptyStub={!recipientId && showEmptyStub}
        />
      </ReportsPreviewContentStyled>
      {!!selectedRecipient && !isPanelOpen && (
        <ModalNavigation
          selectedItem={selectedRecipient}
          items={displayedList}
          onClick={(r) => {
            handleRecipientClick(r.relation_id);
          }}
          getItemLabel={(r) => getUserFullName(r)}
        />
      )}
    </>
  );
};
