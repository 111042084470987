import { Box, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, isSignUpSlots, SignUpStatuses } from '@schooly/api';
import {
  BaseCalendarRecord,
  CalendarRecord,
  CalendarRecordTypes,
  CalendarWeek,
} from '@schooly/components/calendar';
import { eachDayOfInterval, parse } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSignUp } from '../../../context/signUps/WithSignUp';

export const SignUpPreviewDateAndTime: FC = () => {
  const { signUp } = useSignUp();

  const isSlots = isSignUpSlots(signUp);

  const records = useMemo<CalendarRecord[]>(() => {
    if (!signUp?.event || !isSignUpSlots(signUp) || !signUp.event.date_times.length) {
      return [];
    }

    const start = parse(signUp.event.start, DEFAULT_DATE_FORMAT_FNS, new Date());
    const end = parse(signUp.event.end, DEFAULT_DATE_FORMAT_FNS, new Date());

    return eachDayOfInterval({ start, end }).map((date, index) => ({
      date,
      type: CalendarRecordTypes.Draft,
      time: [
        signUp.event!.date_times[index] ?? signUp.event!.date_times[0],
      ] as BaseCalendarRecord['time'], // just an array of one item as Events don't support interval gaps yet
    }));
  }, [signUp]);

  if (!signUp || !isSlots || signUp.status !== SignUpStatuses.Draft) {
    return null;
  }

  return (
    <Stack pt={2} flex="1 1 auto" overflow="hidden">
      <Typography variant="h2">
        <FormattedMessage id="events-dateAndTime" />
      </Typography>

      <Box mt={2.5} flex="1 1 auto" overflow="hidden">
        <CalendarWeek
          records={records}
          startDate={signUp.event?.start}
          minDate={signUp.event?.start}
          maxDate={signUp.event?.end}
          showTimeline={false}
        />
      </Box>
    </Stack>
  );
};
