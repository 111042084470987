import React, { FC } from 'react';

import { useTableHover } from '../../../context/table/tableHover/useTableHover';
import { GridHead, GridRow } from '../Grid/Grid';
import { TableCell } from './Table';

interface TableHeaderProps {
  columns: TableCell[];
  withoutDefaultHover?: boolean;
}

export const TableHeader: FC<TableHeaderProps> = ({ columns, withoutDefaultHover }) => {
  const { columnRef, getColumnHoverProps, styles } = useTableHover();
  return (
    <GridHead borderBottom sx={styles}>
      <GridRow withoutDefaultHover={withoutDefaultHover} ref={columnRef}>
        {columns?.map((column, colIndex) => {
          const props = getColumnHoverProps({
            cellId: column.id,
            colIndex,
            rowIndex: 0,
            ...column.hoverProps,
          });

          return <React.Fragment key={column.id}>{column.renderContent(props)}</React.Fragment>;
        })}
      </GridRow>
    </GridHead>
  );
};
