import { Components, Theme } from '@mui/material';

const paddingY = 9; //px
const paddingX = 16; //px
const paddingYsm = 4; //px
const paddingXsm = 12; //px

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    disableElevation: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const paletteColor =
        ownerState.color && ['primary', 'info', 'warning', 'error'].includes(ownerState.color)
          ? (ownerState.color as 'primary' | 'info' | 'warning' | 'error')
          : 'primary';

      return {
        ...theme.typography.h3,
        padding: theme.spacing(`${paddingY}px`, `${paddingX}px`),
        border: `2px solid transparent`,
        textTransform: 'unset',

        // disable default border-color animation
        transition: theme.transitions.create(['border-color'], {
          easing: theme.transitions.easing.sharp,
          duration: 0,
        }),

        '&.MuiButton-sizeSmall': {
          ...theme.typography.body1,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.5, 1.5),
        },

        '& .MuiButton-iconSizeSmall>*:nth-of-type(1)': {
          fontSize: theme.spacing(2),
        },

        '&:hover': {
          backgroundColor:
            paletteColor === 'error'
              ? theme.palette.background.button.error.hover
              : theme.palette[paletteColor]?.light,
        },

        '&:active': {
          borderColor: theme.palette[paletteColor]?.main,
        },

        '&.Mui-disabled': {
          backgroundColor: theme.palette.common.grey,
          color: theme.palette.common.white,
        },
      };
    },
    contained: ({ theme }) => ({
      color: theme.palette.common.white,
    }),
    outlined: ({ theme }) => ({
      ...theme.mixins.border(),
      padding: theme.spacing(`${paddingY + 1}px`, `${paddingX + 1}px`),
      backgroundColor: 'transparent',

      transition: theme.transitions.create(['background-color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
      }),

      '&.MuiButton-sizeSmall': {
        padding: theme.spacing(`${paddingYsm + 1}px`, `${paddingXsm + 1}px`),
      },

      '&:hover': {
        backgroundColor: theme.palette.common.lightBg,
        borderColor: theme.palette.common.light2,
      },

      '&:active': {
        boxShadow: `0px 0px 0px 1px ${theme.palette.common.light2} inset`,
        borderColor: theme.palette.common.light2,
      },

      '&.Mui-disabled': {
        backgroundColor: theme.palette.common.white,
        borderColor: 'transparent',
        color: theme.palette.text.secondary,
      },
    }),
    text: ({ theme }) => ({
      ...theme.mixins.border(),
      //backgroundColor: theme.palette.common.white,
      borderColor: 'transparent',

      transition: theme.transitions.create(['background-color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
      }),

      '&:hover': {
        backgroundColor: theme.palette.common.lightBg,
      },

      '&:active': {
        boxShadow: `0px 0px 0px 1px ${theme.palette.common.light2} inset`,
        borderColor: theme.palette.common.light2,
      },

      '&.Mui-disabled': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
      },
    }),
  },
};
