import { Box, Tooltip } from '@mui/material';
import { UserType } from '@schooly/api';
import { SchoolRelation } from '@schooly/api';
import { BaseUser } from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { ArrowRightIcon, InformationIcon, Loading } from '@schooly/style';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { SchoolUserRole } from '../../../constants/school';
import { getRouteModalPathname, getUserTypeTextId } from '../../../helpers/misc';
import { getSchoolUserId } from '../../../helpers/school';
import { getUserFullName } from '../../../helpers/users';
import useAppLocation from '../../../hooks/useAppLocation';
import addHighlight from '../../../utils/addHighlight';
import buildClassName from '../../../utils/buildClassName';
import Avatar from '../../ui/Avatar';
import { LongNameWithTooltip } from '../../uikit-components/LongNameWithTooltip/LongNameWithTooltip';
import FamilyMembers from '../FamilyMembers';
import { NoSearchResultsFound } from '../NoSearchResultsFound/NoSearchResultsFound';

import './index.scss';

export interface PeopleListProps<U extends SchoolRelation | BaseUser> {
  users: U[];
  userType: UserType;
  studentWithParents?: boolean;
  isFetching?: boolean;
  isSearching?: boolean;
  canShowMore?: boolean;
  hideFamilyInfo?: boolean;
  hideArrow?: boolean | ((user: U) => boolean);
  hideHeader?: boolean;
  highlightedUserId?: string;
  highlightSearchString?: string;
  renderBefore?: (user: U, index: number, users: U[]) => React.ReactNode;
  renderAfter?: (user: U, index: number, users: U[]) => React.ReactNode;
  onUserSelect: (user: U) => void;
  onShowMore?: () => void;
  profileSchoolContext?: SchoolUserRole;
  disabled?: (user: U) => boolean;
  hint?: (user: U) => React.ReactNode;
}

const isUserSearchResult = (user: SchoolRelation | BaseUser): boolean =>
  'adult_associations' in user ||
  'guardian_relations' in user ||
  'guardian_of_relations' in user ||
  'parents' in user;

const PeopleList = <U extends SchoolRelation | BaseUser>({
  users,
  userType,
  studentWithParents,
  isFetching,
  isSearching,
  canShowMore,
  hideFamilyInfo,
  highlightedUserId,
  hideHeader,
  hideArrow,
  onUserSelect,
  onShowMore,
  highlightSearchString,
  profileSchoolContext,
  disabled,
  hint,
  renderBefore,
  renderAfter,
}: PeopleListProps<U>) => {
  const location = useAppLocation();

  const extraUserTypeTextId = getUserTypeTextId(
    userType === 'child' || userType === 'student' ? 'parent' : 'child',
    true,
  );

  const fetching = isSearching || (isFetching && !users.length);

  const loaderRef = useInfiniteScroll(fetching, onShowMore);

  const handleLinkClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  if (fetching) {
    return <Loading />;
  }

  if (!users.length) {
    return <NoSearchResultsFound className="PeopleList__empty-stub" />;
  }

  return (
    <>
      {!hideHeader && (
        <div className="PeopleList-header">
          <div className="row">
            <div className="col-6">
              <FormattedMessage id="people-Name" />
            </div>
            <div className="col-6">
              {!hideFamilyInfo && (users.length < 1 || isUserSearchResult(users[0])) && (
                <FormattedMessage id={extraUserTypeTextId} />
              )}
            </div>
          </div>
        </div>
      )}

      {users.map((user, index, array) => {
        const id = getSchoolUserId(user);
        const username = getUserFullName(user);
        const hintMessage = hint?.(user);

        return (
          <React.Fragment key={id}>
            {renderBefore?.(user, index, array)}

            <div
              className={buildClassName(
                'PeopleList-item',
                highlightedUserId === id && 'highlighted',
                disabled?.(user) && 'disabled',
              )}
              role="button"
              tabIndex={0}
              onClick={() => onUserSelect(user)}
            >
              <div className="row">
                <div className="PeopleList-item-info col">
                  <Avatar user={user} userType={userType} size="small" />
                  <LongNameWithTooltip>
                    <Link
                      to={{
                        pathname: getRouteModalPathname(userType, user),
                        ...(profileSchoolContext !== undefined && {
                          search: `?type=${profileSchoolContext}`,
                        }),
                      }}
                      state={{ backgroundLocation: location }}
                      className="h3 m-0 PeopleList-item-info-name"
                      onClick={handleLinkClick}
                    >
                      {highlightSearchString
                        ? addHighlight(username, highlightSearchString)
                        : username}
                    </Link>
                  </LongNameWithTooltip>
                </div>
                {!hideFamilyInfo && isUserSearchResult(user) && (
                  <div className="PeopleList-item-extra col">
                    <FamilyMembers
                      user={user}
                      userType={userType}
                      studentWithParents={studentWithParents}
                    />
                  </div>
                )}
              </div>
              {!(typeof hideArrow === 'function' ? hideArrow(user) : Boolean(hideArrow)) && (
                <ArrowRightIcon className="PeopleList-item-arrow" />
              )}
              {hintMessage ? (
                <Tooltip title={hintMessage}>
                  <Box className="PeopleList-item-hint">
                    <InformationIcon />
                  </Box>
                </Tooltip>
              ) : null}
            </div>

            {renderAfter?.(user, index, array)}
          </React.Fragment>
        );
      })}

      {canShowMore && onShowMore && (
        <div className="py-3">
          {isFetching && <Loading />}
          <div ref={loaderRef} />
        </div>
      )}
    </>
  );
};

export default PeopleList;
