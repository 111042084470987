import { Button, IconButton } from '@mui/material';
import { CheckIcon, CrossIcon, Loading, Spin } from '@schooly/style';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

import {
  ModalFooter,
  ModalLarge,
} from '../../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../../components/uikit-components/Modal/ModalHeader';
import { SchoolTuneStatusesForm, SchoolTuneStatusType } from './scheme';
import { SchoolTuneStatusesModalStaffPanel } from './SchoolTuneStatusesModalStaffPanel';
import { SchoolTuneStatusesModalStudentsPanel } from './SchoolTuneStatusesModalStudentsPanel';

type SchoolTuneStatusesModalContentProps = {
  studentStatuses: SchoolTuneStatusType;
  staffStatuses: SchoolTuneStatusType;
  isLoading: boolean;
  isSaving: boolean;
  onSubmit: (v: SchoolTuneStatusesForm) => void;
  onClose: () => void;
  title: string;
};

export const SchoolTuneStatusesModalContent: FC<SchoolTuneStatusesModalContentProps> = ({
  staffStatuses,
  studentStatuses,
  onClose,
  onSubmit,
  title,
  isSaving,
  isLoading,
}) => {
  const form = useForm<SchoolTuneStatusesForm>({
    defaultValues: {
      students: studentStatuses,
      staff: staffStatuses,
    },
  });

  return (
    <ModalLarge open onClose={onClose}>
      {isLoading ? (
        <Loading />
      ) : (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <ModalHeader title={title}>
              {!isSaving && (
                <IconButton onClick={onClose}>
                  <CrossIcon />
                </IconButton>
              )}
            </ModalHeader>

            <SchoolTuneStatusesModalStudentsPanel />
            <SchoolTuneStatusesModalStaffPanel />

            <ModalFooter sx={{ justifyContent: 'space-between' }}>
              <Button variant="outlined" disabled={isSaving} onClick={onClose}>
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={isSaving}
                endIcon={isSaving ? <Spin /> : <CheckIcon />}
              >
                <FormattedMessage id="action-Save" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      )}
    </ModalLarge>
  );
};
