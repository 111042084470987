import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 3C5 2.44772 5.44772 2 6 2C6.55228 2 7 2.44772 7 3H13C13 2.44772 13.4477 2 14 2C14.5523 2 15 2.44772 15 3H16C16.5523 3 17 3.44772 17 4V6H3V4C3 3.44772 3.44772 3 4 3H5Z"
      fill="#24275B"
    />
    <path
      d="M17 7H3V16C3 16.5523 3.44772 17 4 17H16C16.5523 17 17 16.5523 17 16V7Z"
      fill="#24275B"
    />
  </svg>
);
