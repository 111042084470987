import { Button, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AttendanceStubCheckImg from '../../../../../assets/images/attendance-stub-check.svg';
import { GroupPreviewAttendanceEmptyStubStyled } from './GroupPreviewAttendanceEmptyStub.styled';

export const GroupPreviewAttendanceEmptyStubWithButton: FC = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const handleClick = useCallback(() => {
    navigate('attendance');
  }, [navigate]);

  return (
    <GroupPreviewAttendanceEmptyStubStyled>
      <img src={AttendanceStubCheckImg} alt={formatMessage({ id: 'attendance-NoAttendanceYet' })} />
      <Typography variant="h3" color="text.primary" mt={2}>
        <FormattedMessage id="attendance-NoAttendanceYet" />
      </Typography>
      <Button onClick={handleClick}>
        <FormattedMessage id="attendance-TakeAttendance" />
      </Button>
    </GroupPreviewAttendanceEmptyStubStyled>
  );
};
