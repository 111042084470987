import { Stack, Typography } from '@mui/material';
import { Event } from '@schooly/api';
import { DATE_FORMAT } from '@schooly/constants';
import { eachDayOfInterval, format } from 'date-fns';
import React, { FC } from 'react';

const ZERO_VALUE_REGEXP = /^0+(?!$)/g;

interface DateTimeProps<T> {
  start: T;
  end?: T;
}
function DateTime<T extends string>({ start, end }: DateTimeProps<T>) {
  return !end ? (
    <Typography variant="h3">{start}</Typography>
  ) : (
    <Typography variant="h3">
      {start} - {end}
    </Typography>
  );
}

interface DateTimeContentProps {
  event: Event;
}

export const DateTimeContent: FC<DateTimeContentProps> = ({ event }) => {
  const [startTime, endTime] = event.date_times[0] ?? [];
  const singleTime = event.date_times.length === 1;
  const startDate = format(new Date(event.start), DATE_FORMAT);
  const endDate = format(new Date(event.end), DATE_FORMAT);

  if (singleTime) {
    return (
      <Stack direction="row" gap={2}>
        {event.start !== event.end && <DateTime start={startDate} end={endDate} />}
        {startTime && endTime && (
          <DateTime
            start={startTime.replaceAll(ZERO_VALUE_REGEXP, '')}
            end={endTime.replaceAll(ZERO_VALUE_REGEXP, '')}
          />
        )}
      </Stack>
    );
  }

  const days = eachDayOfInterval({
    start: new Date(event.start),
    end: new Date(event.end),
  });

  return (
    <>
      {days.map((d, i) => {
        const date = format(d, DATE_FORMAT);
        const time = event.date_times[i] ?? [];
        const [startTime, endTime] = time;

        return (
          <Stack key={date} direction="row" gap={2.5}>
            <DateTime start={date} />
            {startTime && endTime && (
              <DateTime
                start={startTime.replaceAll(ZERO_VALUE_REGEXP, '')}
                end={endTime.replaceAll(ZERO_VALUE_REGEXP, '')}
              />
            )}
          </Stack>
        );
      })}
    </>
  );
};
