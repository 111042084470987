import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="#B3BBCB"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.98777 2.58787C1.10493 2.47071 1.29488 2.47071 1.41203 2.58787L3.9999 5.17574L6.58777 2.58787C6.70493 2.47071 6.89488 2.47071 7.01203 2.58787C7.12919 2.70503 7.12919 2.89497 7.01203 3.01213L4.21203 5.81213C4.09488 5.92929 3.90493 5.92929 3.78777 5.81213L0.98777 3.01213C0.870613 2.89497 0.870613 2.70503 0.98777 2.58787Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
