import { Box, Divider, Icon, MenuItem, Stack, StackProps, Typography } from '@mui/material';
import { DropdownSelect, DropdownSelectProps } from '@schooly/components/filters';
import { CheckIcon, TypographyWithOverflowHint } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isSameMonth } from 'date-fns';
import React, { FC, ReactNode, useCallback, useRef } from 'react';

export type PeriodKey = string;
export type Period = { start: string; end: string };

const PERIOD_KEY_SEPARATOR = '_';

export function getPeriodKey({ start, end }: Period): PeriodKey {
  return [start, end].join(PERIOD_KEY_SEPARATOR);
}
export function getDatesFromPeriodKey(periodKey: PeriodKey) {
  const [start, end] = periodKey.split(PERIOD_KEY_SEPARATOR);

  return { start, end };
}

export type PeriodOption = {
  key: PeriodKey;
  label: string;
  dateLabel?: string;
};

type PeriodLabelProps = {
  label: string;
  dateLabel?: string;
  selected?: boolean;
} & StackProps;

export const PeriodLabel: FC<PeriodLabelProps> = ({
  label,
  dateLabel,
  selected = false,
  ...props
}) => {
  return (
    <Stack direction="row" gap={2} alignItems="center" {...props}>
      <Typography variant="h3" color={selected ? 'primary.main' : 'common.grey2'}>
        {label}
      </Typography>
      {dateLabel && (
        <Stack direction="row" alignItems="center" gap={0.5} minWidth={0}>
          <Box
            sx={{
              height: '3px',
              width: '3px',
              borderRadius: '50%',
              backgroundColor: 'common.grey',
            }}
          />
          <TypographyWithOverflowHint noWrap>
            <Typography
              component="span"
              variant="h3"
              sx={{ color: (theme) => `${theme.palette.common.grey} !important` }}
            >
              {dateLabel}
            </Typography>
          </TypographyWithOverflowHint>
        </Stack>
      )}
    </Stack>
  );
};

export type PeriodSelectProps = {
  selectedPeriods?: PeriodKey[];
  options: PeriodOption[];
  onSelect: (v: PeriodOption) => void;
  periodLabel: ReactNode;
  showMonthSeparator?: boolean;
  menuItem?: ReactNode;
  closeAfterClick?: boolean;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const PeriodSelect: FC<PeriodSelectProps> = ({
  selectedPeriods,
  options,
  onSelect,
  periodLabel,
  showMonthSeparator = false,
  menuItem,
  closeAfterClick,
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const hasValues = selectedPeriods ? !!Object.keys(selectedPeriods).length : false;

  const renderContent = useCallback(() => {
    return (
      <Stack py={1} px={1} gap={0.5}>
        {options.map((o, i, arr) => {
          const nextOption = arr[i + 1];
          const { start } = getDatesFromPeriodKey(o.key);
          const startDate = newDateTimezoneOffset(start);
          const nextPeriodStartDate = nextOption
            ? newDateTimezoneOffset(getDatesFromPeriodKey(nextOption.key).start)
            : null;

          const hasSameMonth =
            showMonthSeparator && nextPeriodStartDate
              ? isSameMonth(startDate, nextPeriodStartDate)
              : true;
          const isSelected = selectedPeriods?.includes(o.key);

          return (
            <>
              <MenuItem
                key={o.key}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect(o);
                  !!closeAfterClick && dropdown.current?.close();
                }}
                sx={(theme) => ({
                  m: 0,
                  py: theme.spacing(1.25),
                  px: 0.25,
                  height: 24,
                  borderRadius: theme.spacing(0.5),
                  '.MuiTypography-root': {
                    color: (theme) => (isSelected ? theme.palette.primary.main : undefined),
                  },
                  display: 'flex',
                  justifyContent: 'space-between',
                  '&:hover': {
                    '.MuiTypography-root': {
                      color: (theme) => theme.palette.primary.main,
                    },
                  },
                })}
              >
                <PeriodLabel label={o.label} dateLabel={o.dateLabel} />

                {isSelected && (
                  <Icon
                    sx={{
                      color: 'primary.main',
                    }}
                  >
                    <CheckIcon />
                  </Icon>
                )}
              </MenuItem>
              {!hasSameMonth && <Divider sx={{ my: '0 !important' }} />}
            </>
          );
        })}
        {menuItem}
      </Stack>
    );
  }, [closeAfterClick, menuItem, onSelect, options, selectedPeriods, showMonthSeparator]);

  return (
    <DropdownSelect
      ref={dropdown}
      sx={{
        height: 46,
      }}
      hasValues={hasValues}
      renderContent={renderContent}
      borderOnHover={false}
      disabled={options.length === 1}
      requiredLabel={!hasValues ? 'required' : undefined}
      renderRightIcon={options.length === 1 ? () => <></> : undefined}
      {...dropdownProps}
    >
      {(opened) => {
        return (
          <Typography
            sx={{
              zIndex: (theme) => theme.zIndex.drawer,
              cursor: 'pointer',
            }}
            onClick={opened ? dropdown.current?.close : dropdown.current?.open}
            color="text.primary"
            noWrap
          >
            {periodLabel}
          </Typography>
        );
      }}
    </DropdownSelect>
  );
};
