import { IconButton, Stack, Typography } from '@mui/material';
import { CrossIcon, EyeIcon, InformationIcon, Loading, ModalSmall } from '@schooly/style';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import {
  ModalContent,
  ModalPanel,
  ModalTitle,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { ModalPeopleExtensionPanel } from '../../../components/uikit-components/Modal/ModalPeopleExtensionPanel';
import { useFilters } from '../../../context/filters/useFilters';
import { getUserFullName } from '../../../helpers/users';
import usePrevious from '../../../hooks/usePrevious';
import { DEBOUNCE_TIME } from '../../ProfileModal/tabs/ProfileModalGroups';
import { ManageGroupModalMode, useManageRelationGroup } from './context/useManageRelationGroup';
import { ManageCriteriaGroups } from './ManageCriteriaGroups';
import { ManageGroupModalRightSidebar } from './ManageGroupModalRightSidebar';
import { ManageGroupModalTooltip } from './ManageGroupModalTooltip';
import { ManageIndividualStudentGroups } from './ManageIndividualStudentGroups';

const ManageGroupModal: React.FC = () => {
  const {
    actions,
    currentGroups,
    relatedGroups,
    isFetching,
    mode,
    user,
    schoolYear,
    isRelatedGroupsFetching,
    userType,
    isSaving,
  } = useManageRelationGroup();
  const { filters, applyFilters } = useFilters();

  const isStudent = userType === 'student';
  const titleId = isStudent ? 'groups-as-individual' : undefined;

  const prevQuery = usePrevious(filters.appliedQuery);
  const prevSubjects = usePrevious(filters.applied?.subject);
  const prevOnlyTutorGroups = usePrevious(filters.applied?.only_tutor_groups);

  const filterParams = useMemo(
    () =>
      filters.applied?.subject.length || filters.applied?.only_tutor_groups.length
        ? {
            searchQuery: filters.draftQuery,
            subjects: filters.applied?.subject,
            onlyTutorGroups: filters.applied?.only_tutor_groups,
          }
        : {
            searchQuery: filters.draftQuery,
          },
    [filters.applied?.only_tutor_groups, filters.applied?.subject, filters.draftQuery],
  );

  const debouncedFetchResults = useMemo(
    () =>
      debounce(() => {
        applyFilters({
          query: filters.draftQuery,
        });

        actions.filterRelatedGroups(filterParams);
      }, DEBOUNCE_TIME),
    [actions, applyFilters, filterParams, filters.draftQuery],
  );

  useEffect(() => {
    if (isRelatedGroupsFetching) {
      return;
    }

    if (
      (prevSubjects && !isEqual(prevSubjects, filters.applied?.subject)) ||
      (prevOnlyTutorGroups && !isEqual(prevOnlyTutorGroups, filters.applied?.only_tutor_groups))
    ) {
      actions.filterRelatedGroups(filterParams);
    }
  }, [
    actions,
    filterParams,
    filters.applied?.only_tutor_groups,
    filters.applied?.subject,
    isRelatedGroupsFetching,
    prevOnlyTutorGroups,
    prevSubjects,
  ]);

  useEffect(() => {
    if (isRelatedGroupsFetching) return;

    if (filters.draftQuery !== prevQuery) {
      debouncedFetchResults();
      return () => {
        debouncedFetchResults.cancel();
      };
    }
  }, [debouncedFetchResults, filters.draftQuery, isRelatedGroupsFetching, prevQuery]);

  const { individualGroups, criteriaGroups } = useMemo(() => {
    return {
      individualGroups: currentGroups?.filter(
        (g) =>
          g.memberships.some((m) => !!m.is_individual) ||
          // Groups added from sidebar
          !g.memberships.length,
      ),
      criteriaGroups: currentGroups?.filter((g) => g.memberships.some((m) => !!m.criteria)) || [],
    };
  }, [currentGroups]);

  const filteredRelatedGroups = useMemo(() => {
    return (relatedGroups || []).filter((g) => !individualGroups?.some((gr) => gr.id === g.id));
  }, [relatedGroups, individualGroups]);

  const totalGroupsCount = useMemo(
    () => individualGroups?.length + criteriaGroups?.length || 0,
    [criteriaGroups?.length, individualGroups?.length],
  );

  const filterApplied = useMemo(
    () => !!filters.applied && !Object.values(filters.applied).every((f) => !f.length),
    [filters.applied],
  );

  return (
    <ModalSmall open>
      {isFetching || !user ? (
        <Stack flexDirection="row" height="100%" alignItems="center" justifyContent="center">
          <Loading />
        </Stack>
      ) : (
        <>
          <ModalHeader title={<ModalTitle>{getUserFullName(user)}</ModalTitle>}>
            <IconButton onClick={actions.closeModal}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalPanel
            active={mode === ManageGroupModalMode.Individual}
            withBorderBottom={isStudent}
          >
            <Stack direction="row" alignItems="center">
              <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="h2">
                  <FormattedMessage id="groups-Group-plural" />
                </Typography>
                <Typography variant="h2" color="text.secondary">
                  {schoolYear?.name ?? ''}
                </Typography>
              </Stack>

              {!!totalGroupsCount && <Counter>{Number(totalGroupsCount)}</Counter>}
            </Stack>
          </ModalPanel>
          <ModalPeopleExtensionPanel
            addActionId="groups-AddGroups"
            editActionId="groups-EditGroups"
            titleId={titleId}
            active={mode === ManageGroupModalMode.Individual}
            count={isStudent ? individualGroups?.length : 0}
            onAddClick={() => actions.setMode(ManageGroupModalMode.Individual)}
            sidebarContent={
              <ManageGroupModalRightSidebar
                onAddGroup={actions.addGroup}
                currentYear={schoolYear?.name ?? ''}
                isFetching={isRelatedGroupsFetching}
                relatedGroups={filteredRelatedGroups}
                filterApplied={filterApplied}
              />
            }
            variant="h3"
            withoutGap
            withoutPadding={!isStudent}
          >
            <ManageIndividualStudentGroups
              fetching={isFetching}
              onRemoveGroup={actions.removeGroup}
              individualGroups={individualGroups}
              criteriaGroups={criteriaGroups}
            />
          </ModalPeopleExtensionPanel>

          {userType === 'student' && !!criteriaGroups?.length && (
            <ModalPeopleExtensionPanel
              addActionId="groups-ViewGroups"
              editActionId="groups-ViewGroups"
              titleId="based-on-criteria"
              editActionIcon={<EyeIcon />}
              active={mode === ManageGroupModalMode.ByCriteria}
              count={criteriaGroups?.length}
              onAddClick={() => actions.setMode(ManageGroupModalMode.ByCriteria)}
              sidebarContent={null}
              variant="h3"
              headerMode={mode === ManageGroupModalMode.ByCriteria}
              disabled={!criteriaGroups?.length}
              infoNode={
                <ManageGroupModalTooltip
                  text={
                    <Typography color="primary.main">
                      <FormattedMessage id="delete-criteria-group-info" />
                    </Typography>
                  }
                  arrow
                >
                  <IconButton inverse>
                    <InformationIcon />
                  </IconButton>
                </ManageGroupModalTooltip>
              }
            >
              <ModalContent active flat sx={{ pt: 0 }}>
                <ManageCriteriaGroups criteriaGroups={criteriaGroups} />
              </ModalContent>
            </ModalPeopleExtensionPanel>
          )}
          <ModalFooterWithActions
            onSaveClick={() => actions.saveGroups(individualGroups)}
            saving={isSaving}
            disabled={!individualGroups?.length}
          />
        </>
      )}
    </ModalSmall>
  );
};

export default ManageGroupModal;
