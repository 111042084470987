import { UserRoleAccessType, UserRoleCategory, UserRolePermissions } from '@schooly/api';

import searchWords from '../utils/searchWords';

export function getUserPermissionScope(
  category: UserRoleCategory,
  accessType: UserRoleAccessType,
): Array<UserRolePermissions> {
  if (accessType === 'Viewer') {
    switch (category) {
      case 'StudentsAndParents':
        return [
          UserRolePermissions.StaffBase,
          UserRolePermissions.StudentViewer,
          UserRolePermissions.ParentViewer,
          UserRolePermissions.CustomFieldViewer,
          UserRolePermissions.ProfileViewer,
        ];

      case 'Staff':
        return [
          UserRolePermissions.StaffBase,
          UserRolePermissions.StaffViewer,
          UserRolePermissions.CustomFieldViewer,
          UserRolePermissions.ProfileViewer,
        ];

      case 'Groups':
        return [UserRolePermissions.GroupViewer, UserRolePermissions.StaffBase];

      case 'Messages':
        return [UserRolePermissions.MessageViewer, UserRolePermissions.StaffBase];

      case 'AssessmentsAndReports':
        return [UserRolePermissions.AssessmentViewer, UserRolePermissions.StaffBase];

      case 'Attendance':
        return [UserRolePermissions.AttendanceViewer, UserRolePermissions.StaffBase];

      case 'Conduct':
        return [UserRolePermissions.ConductViewer, UserRolePermissions.StaffBase];

      case 'Events':
        return [UserRolePermissions.EventViewer];

      case 'UserRoles':
        return [UserRolePermissions.UserroleViewer];

      case 'CustomFields':
        return [UserRolePermissions.CustomFieldViewer];

      case 'Applications':
        return [UserRolePermissions.ApplicationViewer];

      case 'AnnualPlanner':
        return [UserRolePermissions.PlannerViewer, UserRolePermissions.StaffBase];

      case 'ProductsAndInvoices':
        return [UserRolePermissions.ProductAndInvoiceViewer, UserRolePermissions.StaffBase];

      case 'PayersAndProductsAssignment':
        return [UserRolePermissions.PayerAndProductAssignmentViewer, UserRolePermissions.StaffBase];

      default:
        return [];
    }
  } else if (accessType === 'Creator') {
    switch (category) {
      case 'Messages':
        return [UserRolePermissions.MessageCreator];

      case 'AssessmentsAndReports':
        return [UserRolePermissions.AssessmentCreator];

      case 'Attendance':
        return [UserRolePermissions.AttendanceCreator];

      case 'Events':
        return [UserRolePermissions.EventCreator];

      case 'ProductsAndInvoices':
        return [UserRolePermissions.ProductAndInvoiceCreator];

      case 'PayersAndProductsAssignment':
        return [UserRolePermissions.PayerAndProductAssignmentCreator];

      default:
        return [];
    }
  } else if (accessType === 'Manager') {
    switch (category) {
      case 'StudentsAndParents':
        return [
          UserRolePermissions.StudentManager,
          UserRolePermissions.ParentManager,
          UserRolePermissions.ProfileManager,
        ];

      case 'Registrations':
        return [UserRolePermissions.RegistrationManager];

      case 'Staff':
        return [UserRolePermissions.StaffManager, UserRolePermissions.ProfileManager];

      case 'EmploymentCases':
        return [UserRolePermissions.EmploymentManager];

      case 'Groups':
        return [UserRolePermissions.GroupManager];

      case 'Messages':
        return [UserRolePermissions.MessagePublisher];

      case 'AssessmentsAndReports':
        return [UserRolePermissions.AssessmentManager];

      case 'Attendance':
        return [UserRolePermissions.AttendanceManager];

      case 'Conduct':
        return [UserRolePermissions.ConductManager];

      case 'Events':
        return [UserRolePermissions.EventManager];

      case 'UserRoles':
        return [UserRolePermissions.UserroleManager];

      case 'CustomFields':
        return [UserRolePermissions.CustomFieldManager];

      case 'SchoolSettings':
        return [UserRolePermissions.SchoolAdmin];

      case 'Applications':
        return [UserRolePermissions.ApplicationManager];

      case 'ProductsAndInvoices':
        return [UserRolePermissions.ProductAndInvoiceManager];

      case 'PayersAndProductsAssignment':
        return [UserRolePermissions.PayerAndProductAssignmentManager];

      default:
        return [];
    }
  }

  return [];
}

export function filterUserRole(userRoleName: string, filter: string) {
  return searchWords(userRoleName, filter);
}
export function getAllPermissions(category: UserRoleCategory, accessTypes: UserRoleAccessType[]) {
  return accessTypes.flatMap((t) => getUserPermissionScope(category, t));
}
