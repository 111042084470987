import { Box, Icon, keyframes, Stack, Typography } from '@mui/material';
import { ConductConnotation, useGetCategoriesQuery } from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';
import { Counter, EditIcon, PlusIcon, SimpleButton } from '@schooly/style';
import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import {
  SchoolGeneralDepartmentsForm,
  SchoolGeneralDepartmentsModalContent,
} from '../SchoolGeneral/SchoolGeneralDepartments/SchoolGeneralDepartmentsContent';
import {
  AgeGroupCreate,
  SchoolTuneAgeGroupsForm,
  SchoolTuneAgeGroupsModalContent,
} from '../SchoolTune/tabs/age_groups/SchoolTuneAgeGroupsModalContent';
import { SchoolTuneSchoolYearsModalContent } from '../SchoolTune/tabs/school_years/SchoolTuneSchoolYearsModalContent';
import { SchoolTuneStatusesForm } from '../SchoolTune/tabs/statuses/scheme';
import { SchoolTuneStatusesModalContent } from '../SchoolTune/tabs/statuses/SchoolTuneStatusesModalContent';
import { SchoolTuneSubjectsModalContent } from '../SchoolTune/tabs/subjects/SchoolTuneSubjectsModalContent';
import { SchoolCreateAccessModal } from './SchoolCreateAccessModal';
import {
  SchoolCreateAssessmentGradesModal,
  SchoolCreateAssessmentGradesModalForm,
} from './SchoolCreateAssessmentGradesModal';
import {
  SchoolCreateAttendanceCodesModal,
  SchoolCreateAttendanceCodesModalForm,
} from './SchoolCreateAttendanceCodesModal';
import {
  SchoolCreateConductTypesModal,
  SchoolCreateConductTypesModalForm,
} from './SchoolCreateConductTypesModal';
import { FormSchoolYear, SchoolCreateForm, SchoolCreateUserRole } from './useSchoolCreate';

type SchoolCreateConfigureProps = {
  hideAgeGroups?: boolean;
  form: UseFormReturn<SchoolCreateForm, any>;
};

enum SchoolCreateConfigureModal {
  AgeGroups = 1,
  SchoolYears,
  Statuses,
  Departments,
  Subjects,
  UserRoles,
  AttendanceCodes,
  AssessmentGrades,
  ConductTypes,
}

export const SchoolCreateConfigure: FC<SchoolCreateConfigureProps> = ({ form, hideAgeGroups }) => {
  const [modal, setModal] = useState<SchoolCreateConfigureModal | null>(null);
  const { $t } = useIntl();
  const { data } = useGetCategoriesQuery();

  const name = form.watch('name');
  const schoolLevels = form.watch('schoolLevels');
  const ageGroups = form.watch('ageGroups');
  const schoolYears = form.watch('schoolYears');
  const staffStatuses = form.watch('staffStatuses');
  const studentStatuses = form.watch('studentStatuses');
  const subjects = form.watch('subjects');
  const userRoles = form.watch('userRoles');
  const departments = form.watch('departments');
  const assessmentGrades = form.watch('assessmentGrades');
  const attendanceCodes = form.watch('attendanceCodes');
  const conductTypes = form.watch('conductTypes');
  const closeModal = useCallback(() => setModal(null), []);

  const departmentsWithEmptyIds = useMemo(
    () => departments.map((d) => ({ ...d, id: '' })),
    [departments],
  );

  const {
    handleUpdateSchoolYears,
    handleUpdateAgeGroups,
    handleUpdateSubjects,
    handleUpdateAccess,
    handleUpdateStatuses,
    handleUpdateDepartments,
    handleUpdateAttendanceCodes,
    handleUpdateAssessmentGrades,
    handleUpdateConductTypes,
  } = useMemo(
    () => ({
      handleUpdateSchoolYears: (data: { schoolYears?: FormSchoolYear[] }) => {
        form.setValue(
          'schoolYears',
          [...(data.schoolYears || [])].sort(
            (a, b) => new Date(a.start).valueOf() - new Date(b.start).valueOf(),
          ),
        );
        closeModal();
      },
      handleUpdateAgeGroups: (data: SchoolTuneAgeGroupsForm) => {
        //levels without age groups should not be saved
        const schoolLevels = data.schoolLevels?.filter((level) => level.ageGroups.length) ?? [];

        const ageGroupsWithLevel: AgeGroupCreate[] = [];
        schoolLevels.forEach((level) => {
          if (level.ageGroups) {
            ageGroupsWithLevel.push(...level.ageGroups);
          }
        });
        const ageGroups = [...ageGroupsWithLevel, ...(data.ageGroupsWithNoLevel ?? [])];

        form.setValue(
          'ageGroups',
          ageGroups.map(({ name, level_id }, order) => ({
            name,
            order,
            level_id,
          })),
        );
        form.setValue(
          'schoolLevels',
          schoolLevels.map(({ name, id_tmp }, order) => ({
            name,
            order,
            id_tmp,
          })),
        );
        closeModal();
      },
      handleUpdateSubjects: (data: { subjects?: { name: string }[] }) => {
        form.setValue('subjects', data.subjects || []);
        closeModal();
      },
      handleUpdateAccess: (data: SchoolCreateUserRole[]) => {
        form.setValue('userRoles', data);
      },
      handleUpdateStatuses: (data: SchoolTuneStatusesForm) => {
        form.setValue('staffStatuses', data.staff);
        form.setValue('studentStatuses', data.students);
        closeModal();
      },
      handleUpdateDepartments: (data: SchoolGeneralDepartmentsForm) => {
        form.setValue(
          'departments',
          (data.departments || []).map(({ name }, order) => ({
            order,
            name,
            type: SchoolPropertyType.Department,
          })),
        );

        closeModal();
      },
      handleUpdateAttendanceCodes: (data: SchoolCreateAttendanceCodesModalForm) => {
        form.setValue(
          'attendanceCodes',
          (data.codes || []).map((code, order) => ({
            order,
            ...code,
          })),
        );
      },
      handleUpdateAssessmentGrades: (data: SchoolCreateAssessmentGradesModalForm) => {
        form.setValue('assessmentGrades', data.grades);
      },
      handleUpdateConductTypes: (data: SchoolCreateConductTypesModalForm) => {
        form.setValue(
          'conductTypes',
          data.types.map((t, i) => ({ ...t, order: i })),
        );
      },
    }),

    [form, closeModal],
  );

  const renderModal = () => {
    if (!modal) return null;

    const commonProps = {
      onClose: closeModal,
      isSaving: false,
      title: name,
    };

    switch (modal) {
      case SchoolCreateConfigureModal.AgeGroups:
        return (
          <SchoolTuneAgeGroupsModalContent
            {...commonProps}
            onSubmit={handleUpdateAgeGroups}
            ageGroups={ageGroups}
            schoolLevels={schoolLevels}
          />
        );

      case SchoolCreateConfigureModal.SchoolYears:
        return (
          <SchoolTuneSchoolYearsModalContent
            {...commonProps}
            onSubmit={handleUpdateSchoolYears}
            schoolYears={schoolYears}
          />
        );
      case SchoolCreateConfigureModal.Subjects:
        return (
          <SchoolTuneSubjectsModalContent
            {...commonProps}
            onSubmit={handleUpdateSubjects}
            subjects={subjects}
          />
        );
      case SchoolCreateConfigureModal.Statuses:
        return (
          <SchoolTuneStatusesModalContent
            {...commonProps}
            staffStatuses={staffStatuses}
            studentStatuses={studentStatuses}
            onSubmit={handleUpdateStatuses}
            isLoading={!data?.categories}
          />
        );
      case SchoolCreateConfigureModal.UserRoles:
        return (
          <SchoolCreateAccessModal
            {...commonProps}
            roles={userRoles}
            onSubmit={handleUpdateAccess}
          />
        );
      case SchoolCreateConfigureModal.Departments:
        return (
          <SchoolGeneralDepartmentsModalContent
            {...commonProps}
            departments={departmentsWithEmptyIds}
            onSubmit={handleUpdateDepartments}
            isLoading={false}
          />
        );
      case SchoolCreateConfigureModal.AttendanceCodes:
        return (
          <SchoolCreateAttendanceCodesModal
            {...commonProps}
            codes={attendanceCodes}
            onSubmit={handleUpdateAttendanceCodes}
          />
        );
      case SchoolCreateConfigureModal.AssessmentGrades:
        return (
          <SchoolCreateAssessmentGradesModal
            {...commonProps}
            grades={assessmentGrades}
            onSubmit={handleUpdateAssessmentGrades}
          />
        );
      case SchoolCreateConfigureModal.ConductTypes:
        return (
          <SchoolCreateConductTypesModal
            {...commonProps}
            types={conductTypes}
            onSubmit={handleUpdateConductTypes}
          />
        );

      default:
        return null;
    }
  };

  const staffStatusesArr = [
    ...staffStatuses.prospective,
    ...staffStatuses.current,
    ...staffStatuses.former,
    ...staffStatuses.unsuccessful,
  ];
  const studentStatusesArr = [
    ...studentStatuses.prospective,
    ...studentStatuses.current,
    ...studentStatuses.former,
    ...studentStatuses.unsuccessful,
  ];

  return (
    <>
      <Stack gap={2}>
        <Section
          index={0}
          title={$t({ id: 'schoolYears' })}
          count={schoolYears.length}
          onEdit={() => setModal(SchoolCreateConfigureModal.SchoolYears)}
        >
          <Typography variant="h3" color="text.primary">
            {schoolYears.map((y) => y.name).join(', ')}
          </Typography>
        </Section>
        {(form.formState.defaultValues?.ageGroups?.length !== 0 || !!ageGroups.length) && (
          <Section
            index={1}
            title={$t({ id: 'schoolProperty-AgeGroup-plural' })}
            count={ageGroups.length}
            onEdit={() => setModal(SchoolCreateConfigureModal.AgeGroups)}
          >
            {schoolLevels.length ? (
              <>
                {schoolLevels.map((l) => (
                  <Stack flexDirection="row" gap={0.5}>
                    <Typography
                      variant="h3"
                      minWidth="95px"
                      maxWidth="95px"
                      sx={{ wordBreak: 'break-word' }}
                    >
                      {l.name}:
                    </Typography>
                    <Typography variant="h3" color="text.primary">
                      {ageGroups
                        .filter((g) => g.level_id === l.id_tmp)
                        .map((g) => g.name)
                        .join(', ')}
                    </Typography>
                  </Stack>
                ))}
                <Typography variant="h3" color="text.primary">
                  {ageGroups
                    .filter((g) => !g.level_id)
                    .map((g) => g.name)
                    .join(', ')}
                </Typography>
              </>
            ) : (
              <Typography variant="h3" color="text.primary">
                {ageGroups.map((g) => g.name).join(', ')}
              </Typography>
            )}
          </Section>
        )}
        <Section
          index={2}
          title={$t({ id: 'schoolProperty-Status-plural' })}
          count={studentStatusesArr.length + staffStatusesArr.length}
          onEdit={() => setModal(SchoolCreateConfigureModal.Statuses)}
        >
          <Stack flexDirection="row">
            <Typography variant="h3" width="100px">
              {$t({ id: 'userType-student-plural' })}:
            </Typography>
            <Typography variant="h3" color="text.primary">
              {studentStatusesArr.map((y) => y.name).join(', ')}
            </Typography>
          </Stack>
          <Stack flexDirection="row">
            <Typography variant="h3" width="100px">
              {$t({ id: 'userType-staff-plural' })}:
            </Typography>
            <Typography variant="h3" color="text.primary">
              {staffStatusesArr.map((y) => y.name).join(', ')}
            </Typography>
          </Stack>
        </Section>

        <Section
          index={3}
          title={$t({ id: 'groups-GroupSubject-plural' })}
          count={subjects.length}
          onEdit={() => setModal(SchoolCreateConfigureModal.Subjects)}
        >
          <Box
            sx={(theme) => ({
              columnWidth: theme.spacing(26),
              columnGap: theme.spacing(2),
              '.letter-group + .letter-group': { mt: 2 },
            })}
          >
            {Object.entries(
              subjects
                .map((y) => y.name)
                .sort()
                .reduce<{ [k: string]: string[] }>(
                  (acc, name, i) => {
                    const letter = name[0];
                    if (acc[letter]) {
                      acc[letter].push(name);
                    } else {
                      acc[letter] = [name];
                    }

                    return acc;
                  },

                  {},
                ),
            ).map(([letter, names]) => (
              <Stack key={letter} className="letter-group">
                {names.map((name) => (
                  <Typography key={name} variant="h3" color="text.primary">
                    {name}
                  </Typography>
                ))}
              </Stack>
            ))}
          </Box>
        </Section>
        <Section
          index={4}
          title={$t({ id: 'schoolProperty-Department-plural' })}
          count={departments.length}
          onEdit={() => setModal(SchoolCreateConfigureModal.Departments)}
        >
          <Typography variant="h3" color="text.primary">
            {departments.map((y) => y.name).join(', ')}
          </Typography>
        </Section>
        <Section
          index={5}
          title={$t({ id: 'school-tabs-Access' })}
          count={userRoles.length}
          onEdit={() => setModal(SchoolCreateConfigureModal.UserRoles)}
        >
          <Typography variant="h3" color="text.primary">
            {userRoles.map((y) => y.name).join(', ')}
          </Typography>
        </Section>
        <Section
          index={6}
          title={$t({ id: 'attendance-Codes' })}
          count={attendanceCodes.length}
          emptyEditText={$t({ id: 'attendance-AddCodes' })}
          onEdit={() => setModal(SchoolCreateConfigureModal.AttendanceCodes)}
        >
          <Typography variant="h3" color="text.primary">
            {attendanceCodes.map((y) => y.name).join(', ')}
          </Typography>
        </Section>
        <Section
          index={7}
          title={$t({ id: 'assessments-Grades' })}
          count={assessmentGrades.length}
          onEdit={() => setModal(SchoolCreateConfigureModal.AssessmentGrades)}
          emptyEditText={$t({ id: 'assessments-AddGradingSystem' })}
        >
          <Typography variant="h3" color="text.primary">
            {assessmentGrades.map((y) => y.name).join(', ')}
          </Typography>
        </Section>
        <Section
          index={8}
          emptyEditText={$t({ id: 'school-section-ConductSettings-AddConductType' })}
          title={$t({ id: 'school-section-ConductSettings-ConductTypes' })}
          count={conductTypes.length}
          onEdit={() => setModal(SchoolCreateConfigureModal.ConductTypes)}
        >
          {(() => {
            const positiveTypes = conductTypes.filter(
              (c) => c.connotation === ConductConnotation.POSITIVE,
            );
            const negativeTypes = conductTypes.filter(
              (c) => c.connotation === ConductConnotation.NEGATIVE,
            );

            return (
              <>
                {!!positiveTypes.length && (
                  <Stack flexDirection="row" gap={0.5}>
                    <Typography variant="h3" minWidth="95px">
                      {$t({ id: 'conduct-Type-positive' })}:
                    </Typography>
                    <Typography variant="h3" color="text.primary">
                      {positiveTypes.map((y) => y.name).join(', ')}
                    </Typography>
                  </Stack>
                )}
                {!!negativeTypes.length && (
                  <Stack flexDirection="row" gap={0.5}>
                    <Typography variant="h3" minWidth="95px">
                      {$t({ id: 'conduct-Type-negative' })}:
                    </Typography>
                    <Typography variant="h3" color="text.primary">
                      {negativeTypes.map((y) => y.name).join(', ')}
                    </Typography>
                  </Stack>
                )}
              </>
            );
          })()}
        </Section>
      </Stack>
      {renderModal()}
    </>
  );
};

type SectionProps = PropsWithChildren<{
  emptyEditText?: string;
  index: number;
  title: string;
  count: number;
  onEdit: () => void;
}>;
export const Section: FC<SectionProps> = ({
  children,
  count,
  title,
  onEdit,
  index,
  emptyEditText,
}) => {
  return (
    <Stack
      onClick={onEdit}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.common.light2}`,
        gap: 1,
        cursor: 'pointer',
        transition: 'all .3s',
        animation: `${fadeIn} ${Math.min(0.5 + index * 0.3, 1.2)}s`,
        '&:hover': {
          backgroundColor: theme.palette.background.default,
          '.MuiTypography-h3': {
            color: theme.palette.primary.main,
          },
        },
        '&:not(:hover) .edit-icon .svg-icon': { opacity: 0 },
      })}
    >
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row" gap={1} alignItems="center">
          <Typography variant="h2">{title}</Typography>
          {!!count && (
            <Counter sx={{ minWidth: 20, textAlign: 'center', fontWeight: 600 }}>{count}</Counter>
          )}
        </Stack>
        {!count && emptyEditText ? (
          <SimpleButton startIcon={<PlusIcon />}>{emptyEditText}</SimpleButton>
        ) : (
          <Icon className="edit-icon">
            <EditIcon />
          </Icon>
        )}
      </Stack>
      {!!count && children}
    </Stack>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  30% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
