import { FilterKeys } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ShowEventNotificationProps {
  count: number;
  recurrenceId?: string;
  title: string;
  id: string;
  multipleTextId?: string;
  textId?: string;
  showActionButton?: boolean;
}

export const useEventNotifications = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotifications();

  const showEventNotification = useCallback(
    ({
      count,
      recurrenceId,
      title,
      id,
      multipleTextId,
      textId,
      showActionButton = true,
    }: ShowEventNotificationProps) => {
      if (!count) return;
      const multipleEvents = Boolean(recurrenceId && count > 1);

      showNotification({
        textId: multipleEvents ? multipleTextId : textId,
        type: 'success',
        values: {
          eventName: title,
          eventsCount: count,
        },
        actions: showActionButton
          ? [
              {
                textId: multipleEvents
                  ? 'events-recurring-ViewSet'
                  : 'events-notification-ViewEvent',
                handler: multipleEvents
                  ? () =>
                      navigate(
                        {
                          pathname: '/events',
                        },
                        { state: { [FilterKeys.RecurrenceId]: recurrenceId } },
                      )
                  : () => navigate(`/events/${id}`),
                buttonColor: 'light',
              },
            ]
          : undefined,
      });
    },
    [navigate, showNotification],
  );

  return { showEventNotification };
};
