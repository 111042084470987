import { IActionButton } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Loading } from '@schooly/style';
import React, { PropsWithChildren } from 'react';

import { useSchool } from '../../../hooks/useSchool';
import Sidebar from '../Sidebar/Sidebar';
import { MainLayoutContainer, MainLayoutContent, MainLayoutStyled } from './MainLayout.styled';
import { MainLayoutEmptyStub } from './MainLayoutEmptyStub';

interface MainLayoutProps extends PropsWithChildren {
  pageTitleTextId?: string;
  className?: string;
  actionButtons?: IActionButton[];
}

const MainLayout: React.FC<MainLayoutProps> = ({ className, children }) => {
  // We're utilizing flag instead changeLastSchool.isLoading to avoid showing outdated ui
  // before window.location.href performs
  const { currentUser, currentSchool } = useAuth();
  const { hasSystemRole, isFetching, isSchoolAdmin, isSchoolChanging } = useSchool();

  const renderContent = () => {
    if (currentUser === undefined || isFetching || isSchoolChanging) {
      return <Loading />;
    }

    if (!currentSchool || (!isSchoolAdmin && !hasSystemRole)) {
      return <MainLayoutEmptyStub />;
    }

    return children;
  };

  return (
    <MainLayoutStyled className={className}>
      <Sidebar />
      <MainLayoutContainer>
        <MainLayoutContent>{renderContent()}</MainLayoutContent>
      </MainLayoutContainer>
    </MainLayoutStyled>
  );
};

export default MainLayout;
