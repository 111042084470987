import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { DAY_OF_WEEK_OPTIONS } from './FormDayOfWeekSelect';

type FormDaySelectProps = {
  name: string;
  required: boolean;
  disabled?: boolean;
};

export const FormDaySelect: FC<FormDaySelectProps> = ({ name, required, disabled }) => {
  const { control, setValue, formState, trigger } =
    useFormContext<Record<string, number | undefined>>();

  const { $t } = useIntl();

  const error = get(formState.errors, name);
  const errorMessage = getControllerErrorText(error, undefined, $t);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      render={({ field }) => {
        return (
          <Stack gap={0.5}>
            <Stack
              direction="row"
              gap={1}
              sx={{
                '.MuiButton-outlined.Mui-disabled': {
                  borderColor: 'common.grey',
                  '.MuiTypography-root': {
                    color: 'common.grey',
                  },
                },
              }}
            >
              {DAY_OF_WEEK_OPTIONS.map(({ labelTextId, value }) => {
                const selected = field.value === value;
                const shortDescriptions = $t({ id: labelTextId }).at(0);
                return (
                  <Button
                    key={value}
                    disabled={!!disabled}
                    variant={selected ? 'contained' : 'outlined'}
                    sx={(theme) => ({
                      justifyItems: 'flex-start',
                      minWidth: 0,
                      padding: theme.spacing(1.25, 1.75),
                      width: 44,
                      height: 44,
                    })}
                    onClick={() => {
                      if (!selected) {
                        setValue(name, value);
                      }
                      if (error) trigger(name);
                    }}
                  >
                    <Typography color={selected ? 'white' : 'primary.main'} variant="h3">
                      {shortDescriptions}
                    </Typography>
                  </Button>
                );
              })}
            </Stack>
            {errorMessage && (
              <FormHelperText>
                <Typography color="error.main" variant="body2">
                  {errorMessage}
                </Typography>
              </FormHelperText>
            )}
          </Stack>
        );
      }}
    />
  );
};
