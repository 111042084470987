import React, { PropsWithChildren, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import useFlag from '../../../hooks/useFlag';
import buildClassName from '../../../utils/buildClassName';
import Popover from '../Popover';
import { getPlacementClassNames, Placement } from './utils';

import './index.scss';

export interface PopoverProps extends PropsWithChildren {
  text?: string;
  textId?: string;
  className?: string;
  noWrap?: boolean;
  placement?: Placement;
  element?: React.ReactNode;
  offsetX?: number;
  offsetY?: number;
  isUnderElement?: boolean;
  onPopoverOpen?: (value: boolean) => void;
}

const Tooltip: React.FC<PopoverProps> = ({
  children,
  text,
  textId,
  element,
  className,
  noWrap,
  placement,
  offsetX,
  offsetY = -20,
  isUnderElement,
  onPopoverOpen,
}) => {
  const { formatMessage } = useIntl();
  const placementClassName = useMemo(() => getPlacementClassNames(placement), [placement]);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [isPopoverOpen, openPopover, closePopover] = useFlag();

  const commonTooltipStyles = buildClassName(
    'Tooltip',
    className,
    placementClassName,
    noWrap ? 'nowrap' : 'default-width',
  );

  const commonTooltip = (
    <div className={commonTooltipStyles} data-text={textId ? formatMessage({ id: textId }) : text}>
      {children}
    </div>
  );

  const handleOpenPopover = useCallback(() => {
    openPopover();

    if (onPopoverOpen) {
      onPopoverOpen(true);
    }
  }, [onPopoverOpen, openPopover]);

  const handleClosePopover = useCallback(() => {
    closePopover();

    if (onPopoverOpen) {
      onPopoverOpen(false);
    }
  }, [closePopover, onPopoverOpen]);

  const popoverTooltip = (
    <div
      className={buildClassName('Tooltip--popover', className)}
      onMouseEnter={handleOpenPopover}
      onMouseLeave={handleClosePopover}
      ref={popoverRef}
    >
      {children}
      <Popover
        className={'Tooltip--popover__element'}
        open={isPopoverOpen}
        offsetY={offsetY}
        offsetX={offsetX}
        anchorRef={popoverRef}
        isUnderElement={isUnderElement}
        onMouseEnter={handleOpenPopover}
        onMouseLeave={handleClosePopover}
      >
        {element}
      </Popover>
    </div>
  );

  return element ? popoverTooltip : commonTooltip;
};

export default Tooltip;
