import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="2.25"
      y="2.25"
      width="10.5"
      height="12.5"
      stroke="#24275B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="10" y="6" width="4" height="5" fill="white" />
    <path
      d="M3.36423 2.34438C3.13193 2.21533 2.84869 2.21883 2.61965 2.3536C2.39062 2.48836 2.25 2.73426 2.25 3V14.6667C2.25 14.939 2.39767 15.19 2.63577 15.3223L8.63577 18.6556C8.86807 18.7847 9.15131 18.7812 9.38035 18.6464C9.60938 18.5116 9.75 18.2657 9.75 18V6.33333C9.75 6.06096 9.60233 5.80999 9.36423 5.67772L3.36423 2.34438Z"
      fill="#24275B"
      stroke="#24275B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8.75C9 8.33579 9.33579 8 9.75 8L17.4394 8L14.7197 5.28033C14.4268 4.98744 14.4268 4.51256 14.7197 4.21967C15.0126 3.92678 15.4875 3.92678 15.7804 4.21967L19.7804 8.21967C20.0732 8.51256 20.0732 8.98744 19.7804 9.28033L15.7804 13.2803C15.4875 13.5732 15.0126 13.5732 14.7197 13.2803C14.4268 12.9874 14.4268 12.5126 14.7197 12.2197L17.4394 9.5L9.75 9.5C9.33579 9.5 9 9.16421 9 8.75Z"
      fill="#24275B"
    />
  </svg>
);
