import emptyString from './emptyString';

/**
 * Helper schema for email adress fields.
 *
 * @param required makes the schema required
 * @param defaultToNull if set to `true`, coerces empty values to `null`
 */
export default function emailSchema(required?: boolean, defaultToNull?: boolean) {
  return emptyString(required, defaultToNull ? null : undefined).email({ tlds: { allow: false } });
}
