import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.29289 2.70708C9.68342 2.31655 10.3166 2.31655 10.7071 2.70708L14.25 6.24997L16.2929 4.20708C16.9229 3.57711 18 4.02328 18 4.91418V9H2V4.91419C2 4.02328 3.07714 3.57711 3.70711 4.20708L5.75 6.24997L9.29289 2.70708Z"
      fill="#24275B"
    />
    <path
      d="M2.04785 10C2.09065 10.074 2.14371 10.1438 2.20706 10.2071L9.29284 17.2929C9.58575 17.5858 10.0152 17.659 10.3763 17.5125C10.4528 17.4815 10.5263 17.4406 10.5947 17.3898C10.634 17.3608 10.6715 17.3284 10.7071 17.2929L17.2929 10.7071L17.2969 10.703L17.7928 10.2071C17.8562 10.1438 17.9093 10.074 17.952 10H2.04785Z"
      fill="#24275B"
    />
  </svg>
);
