import { EventsInvite } from '@schooly/constants';

import { ConsentFormShort } from '../consentForms';
import { Event, EventCriteria } from './events';

export enum SignUpStatuses {
  Draft = 1,
  Open = 2,
  Closed = 3,
}

export enum SignUpType {
  Regular = 1,
  Slots = 2,
}

export interface SignUpBaseWithEvent {
  event: Event;
}

export interface SignUpBaseWithoutEvent {
  invitee_type: EventsInvite;
  criteria: EventCriteria;
}

export interface SignUpBase extends Partial<SignUpBaseWithEvent>, Partial<SignUpBaseWithoutEvent> {
  type: SignUpType;
  id: string;
  title: string;
  description?: string;
  status: SignUpStatuses;
  end: string; // Date
  can_be_edited?: boolean;
  signed_up_count?: number;
  consent_form?: ConsentFormShort | null;
}

export interface SignUpRegular extends SignUpBase {
  type: SignUpType.Regular;
  places: number;
}

export interface SignUpSlots extends SignUpBase {
  type: SignUpType.Slots;
  duration: number; // number of minutes
}

export type SignUp = SignUpRegular | SignUpSlots;

export const isSignUpRegular = (signUp?: SignUp): signUp is SignUpRegular => {
  return signUp?.type === SignUpType.Regular;
};

export const isSignUpSlots = (signUp?: SignUp): signUp is SignUpSlots => {
  return signUp?.type === SignUpType.Slots;
};

export const isSignUpWithEvent = (signUp?: SignUp): signUp is SignUp & SignUpBaseWithEvent => {
  return Boolean(signUp?.event);
};

export const isSignUpWithoutEvent = (
  signUp?: SignUp,
): signUp is SignUp & SignUpBaseWithoutEvent => {
  return !signUp?.event;
};
