import { Icon, Stack, Typography } from '@mui/material';
import { FilterKeys, GetParentsQueryFilters, useSendBulkInvitesMutation } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { Attention2Icon, CheckboxSquareIconButton, SimpleButton, Spin } from '@schooly/style';
import { BulkActionsBottomBar, SelectedIds } from '@schooly/utils/bulk-actions';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { SIDEBAR_WIDTH } from '../../components/uikit-components/Sidebar/Sidebar.styled';

type ParentsBulkActionsProps = {
  totalCount: number;
  schoolId: string;
  selectedIds: SelectedIds;
  query?: string;
  filters: GetParentsQueryFilters;
  onClear: () => void;
};

const MAX_INVITE_AMOUNT = 650;

export const ParentsBulkActions: FC<ParentsBulkActionsProps> = ({
  totalCount,
  selectedIds,
  onClear,
  schoolId,
  query,
  filters,
}) => {
  const { $t } = useIntl();
  const hasOnlyEmplyEmailFilter = Boolean(filters[FilterKeys.OnlyEmptyEmail]);

  const { showNotification, showError } = useNotifications();

  const sendInvitesMutation = useSendBulkInvitesMutation();
  const onSendInvites = useCallback(() => {
    sendInvitesMutation.mutate(
      selectedIds === 'all'
        ? {
            schoolId,
            query,
            filters,
          }
        : {
            schoolId,
            relation_ids: Array.from(selectedIds),
          },
      {
        onSuccess: () => {
          showNotification({ textId: 'school-bulkActions-SendInvitesMessage', type: 'success' });
          onClear();
        },
        onError: showError,
      },
    );
  }, [
    filters,
    onClear,
    query,
    schoolId,
    selectedIds,
    sendInvitesMutation,
    showError,
    showNotification,
  ]);

  if (selectedIds !== 'all' && !selectedIds.size) return null;

  const amountExceeded =
    (selectedIds === 'all' && totalCount > MAX_INVITE_AMOUNT) ||
    (!!selectedIds && selectedIds !== 'all' && selectedIds.size > MAX_INVITE_AMOUNT);

  return (
    <BulkActionsBottomBar
      sx={{
        pointerEvents: sendInvitesMutation.isLoading ? 'none' : undefined,
        minWidth: 885,
      }}
      sidebarWidth={SIDEBAR_WIDTH}
    >
      <Stack flexDirection="row" alignItems="center" minWidth={240} gap={1}>
        <CheckboxSquareIconButton isActive onClick={onClear} />
        <Typography variant="h3">
          {$t(
            { id: 'countOfTypeSelected' },
            selectedIds === 'all'
              ? {
                  count: $t({ id: 'filter-All' }),
                  userTypePlural: $t({ id: 'userType-parent-plural' }),
                }
              : {
                  count: selectedIds.size,
                  userTypePlural: $t({
                    id: selectedIds.size > 1 ? 'userType-parent-plural' : 'userType-parent',
                  }),
                },
          )}
        </Typography>
      </Stack>
      <SimpleButton
        startIcon={sendInvitesMutation.isLoading ? <Spin /> : undefined}
        disabled={sendInvitesMutation.isLoading || amountExceeded || hasOnlyEmplyEmailFilter}
        onClick={onSendInvites}
      >
        {$t({
          id:
            selectedIds === 'all' || selectedIds.size > 1
              ? 'school-bulkActions-SendInvites'
              : 'school-bulkActions-SendInvite',
        })}
      </SimpleButton>
      {amountExceeded && (
        <Stack flexDirection="row" alignItems="center" gap={1} ml={5}>
          <Icon sx={(theme) => ({ '.svg-icon path': { fill: theme.palette.warning.main } })}>
            <Attention2Icon className="reset-svg-currentColor" />
          </Icon>
          <Typography>{$t({ id: 'parents-CannotInvite' })}</Typography>
        </Stack>
      )}
    </BulkActionsBottomBar>
  );
};
