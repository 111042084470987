import { Box, Stack, styled } from '@mui/material';

interface RoundCardWrapperProps {
  error?: boolean;
}

export const RoundCardWrapper = styled(Stack)<RoundCardWrapperProps>(({ theme, error }) => ({
  whiteSpace: 'nowrap',
  padding: theme.spacing(1, 1.5),
  border: theme.mixins.borderValue(),
  borderRadius: 300,
  borderColor: error ? theme.palette.error.light : theme.palette.common.light2,
  color: error ? theme.palette.error.main : theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1.25),

  ':hover': {
    cursor: 'pointer',
    background: error ? theme.palette.error.superLight : theme.palette.common.lightBg,
  },

  ':hover p': {
    color: error && theme.palette.error.main,
  },
}));

export const MinusIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
  height: 20,
  padding: theme.spacing(0.5),
  color: theme.palette.common.light3,
  borderRadius: '50%',
  flex: '0 0 auto',
  transition: 'all .2s',
}));
