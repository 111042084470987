import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { mobileDetect } from './mobileDetect';

type UsePortraitViewProps = {
  onOrientationChange?: (isPortrait: boolean) => void;
};

export const usePortraitView = ({ onOrientationChange }: UsePortraitViewProps) => {
  const [portraitView, setPortraitView] = useState(
    window.matchMedia('(orientation: portrait)').matches,
  );

  const handleOrientationChange = useCallback(
    (e: MediaQueryListEvent) => {
      setPortraitView(e.matches);
      onOrientationChange && onOrientationChange(e.matches);
    },
    [onOrientationChange],
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');

    mediaQuery.addEventListener('change', handleOrientationChange);
    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, [handleOrientationChange]);

  return useMemo(
    () => ({ isPortraitView: portraitView, handleOrientationChange }),
    [handleOrientationChange, portraitView],
  );
};

type UseMobilePortraitViewNotificationProps = {
  onNotify: (id: string, onRemoveNotification: () => void) => void;
  onClear: (id: string) => void;
};

export const usePortraitViewNotification = ({
  onNotify,
  onClear,
}: UseMobilePortraitViewNotificationProps) => {
  const [notificationId, setNotificationId] = useState<string | null>();
  const isMobile = useRef(mobileDetect.mobile());

  const handleOrientationChange = useCallback(
    (isPortraitView: boolean) => {
      if (!isMobile.current) return;

      if (!isPortraitView && notificationId) {
        onClear(notificationId);
        setNotificationId(null);
        return;
      }

      if (isPortraitView && !notificationId) {
        const id = v4();
        onNotify(id, () => setNotificationId(null));
        setNotificationId(id);
      }
    },
    [notificationId, onClear, onNotify],
  );

  const { isPortraitView } = usePortraitView({
    onOrientationChange: handleOrientationChange,
  });

  useEffect(() => {
    if (notificationId === undefined) {
      if (isPortraitView) handleOrientationChange(isPortraitView);
      else setNotificationId(null);
    }
  }, [handleOrientationChange, isPortraitView, notificationId]);
};
