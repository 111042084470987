import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';
import { Assessment, Group, SchoolYear, useExportAssessmentSingleMutation } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, DownloadIcon, SelectMultiple, SelectMultipleProps, Spin } from '@schooly/style';
import { IntlError } from '@schooly/utils/intl-error';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { downloadFile } from '../../../../utils/downloadFile';

interface AssessmentsPreviewExportProps {
  data?: Assessment;
}

export const AssessmentsPreviewExport: FC<AssessmentsPreviewExportProps> = ({ data }) => {
  const { $t } = useIntl();
  const { showError } = useNotifications();

  const ref = useRef<HTMLButtonElement>(null);

  // is being set after successful download and reset in 3 sec afterwards
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<'all' | 'selected'>('all');
  const [selectedGroupIds, setSelectedGroupIds] = useState<SchoolYear['id'][]>([]);

  const groupIds = useMemo(
    () => (filter === 'selected' ? selectedGroupIds : data?.group_ids ?? []),
    [data?.group_ids, filter, selectedGroupIds],
  );

  const mutation = useExportAssessmentSingleMutation();

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleFilterChange = useCallback<Exclude<RadioGroupProps['onChange'], undefined>>(
    (event, value) => {
      setFilter(value as 'all' | 'selected');
    },
    [],
  );

  const handleGroupsChange = useCallback<
    Exclude<SelectMultipleProps<Group['id']>['onChange'], undefined>
  >((event) => {
    setSelectedGroupIds(event.target.value);
  }, []);

  const download = useCallback(() => {
    if (!data?.id) {
      return;
    }

    mutation.mutate(
      {
        assessmentId: data.id,
        groupIds,
      },
      {
        onSuccess: (buffer) => {
          try {
            downloadFile(buffer, `${data.name}.csv`);
            setIsSuccess(true);
          } catch (err) {
            showError(err as IntlError);
          }
        },
        onError: showError,
      },
    );
  }, [data?.id, data?.name, mutation, groupIds, showError]);

  const handleExportClick = useCallback(() => {
    download();
    setOpen(false);
  }, [download]);

  /** resets success icon in 3 sec */
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccess(false), 3 * 1000);
    }
  }, [isSuccess]);

  /** resets filter settings to default ones on Popover close */
  useEffect(() => {
    if (!open) {
      setFilter('all');
      setSelectedGroupIds([]);
    }
  }, [open]);

  return (
    <>
      <IconButton
        ref={ref}
        sx={{
          '&&': { color: open || mutation.isLoading || isSuccess ? 'primary.main' : undefined },
        }}
        onClick={mutation.isLoading ? undefined : handleClick}
      >
        {(() => {
          if (mutation.isLoading) {
            return <Spin />;
          }

          if (isSuccess) {
            return <CheckIcon />;
          }

          return <DownloadIcon />;
        })()}
      </IconButton>
      <Popover
        open={open}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        PaperProps={{
          sx: { p: 2, width: 300 },
        }}
      >
        <Stack gap={2.5}>
          <Typography variant="h3">
            <FormattedMessage id="assessments-ExportAssessment" />
          </Typography>

          <FormControl>
            <RadioGroup value={filter} onChange={handleFilterChange}>
              <FormControlLabel value="all" control={<Radio />} label="All groups" />
              <FormControlLabel value="selected" control={<Radio />} label="Select groups" />
            </RadioGroup>
          </FormControl>

          {filter === 'selected' && (
            <SelectMultiple
              label={$t({ id: 'assessments-Groups' })}
              value={groupIds}
              onChange={handleGroupsChange}
              renderValueTag={(id) => data?.groups.find((group) => group.id === id)?.name}
            >
              {data?.groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </SelectMultiple>
          )}

          <Button
            variant="outlined"
            onClick={handleExportClick}
            disabled={!groupIds.length}
            fullWidth
          >
            <FormattedMessage id="exportToCSV" />
          </Button>
        </Stack>
      </Popover>
    </>
  );
};
