import debounce from 'lodash.debounce';
import { useCallback, useMemo, useState } from 'react';

const MODAL_FADEOUT_DURATION_MS = 300;

const useFlag = (initial = false, debounceTimout = MODAL_FADEOUT_DURATION_MS) => {
  const [isOpen, setIsOpen] = useState(initial);

  const setTrue = useCallback(() => {
    setIsOpen(true);
  }, []);

  const setTrueDebounced = useMemo(
    () =>
      debounce(setTrue, debounceTimout, {
        leading: false,
        trailing: true,
      }),
    [debounceTimout, setTrue],
  );

  const setFalse = useCallback(() => {
    setIsOpen(false);
  }, []);

  const setFalseDebounced = useMemo(
    () =>
      debounce(setFalse, debounceTimout, {
        leading: true,
        trailing: false,
      }),
    [debounceTimout, setFalse],
  );

  return [isOpen, setTrue, setFalse, setTrueDebounced, setFalseDebounced] as const;
};

export default useFlag;
