import {
  AnnualPlanRecordEvent,
  AnnualPlanRecordTypes,
  DEFAULT_DATE_FORMAT_FNS,
  Event,
  EventsStatuses,
} from '@schooly/api';
import { DateRangeSelect } from '@schooly/components/filters';
import { isDateInPast } from '@schooly/utils/date';
import { format, isAfter, isBefore } from 'date-fns';
import React, { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { Validate } from 'react-hook-form-lts/dist/types/validator';
import { useIntl } from 'react-intl';

import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import { AnnualPlannerCreateForm } from '../../scheme';
import { useAnnualPlannerCreateRecord } from '../WithAnnualPlannerCreate';

export interface AnnualPlannerCreateEventDateProps {
  event?: Event;
}

export const AnnualPlannerCreateEventDate: FC<AnnualPlannerCreateEventDateProps> = ({ event }) => {
  const { $t } = useIntl();
  const { start, end } = useAnnualPlanner();
  const { record } = useAnnualPlannerCreateRecord();
  const { control, getValues, setValue } = useFormContext<AnnualPlannerCreateForm>();

  const details =
    record && [AnnualPlanRecordTypes.EVENT, AnnualPlanRecordTypes.HOLIDAY].includes(record.type)
      ? (record.details as AnnualPlanRecordEvent)
      : undefined;

  const isStarted = details?.start && isDateInPast(details?.start);
  const isEnded = details?.end && isDateInPast(details?.end);

  const isActive = details?.status === EventsStatuses.Published && isStarted && !isEnded;
  const isPublished = details?.status === EventsStatuses.Published || isActive;

  /**
   * Validates a date value for Event/Holiday
   *
   * TODO: make shareable as might partially duplicate
   *  apps/web/src/pages/Events/EventCreateModal/EventCreateContent.tsx:startDateError
   */
  const validate = useCallback<
    (index: number) => Validate<AnnualPlannerCreateForm['date'], AnnualPlannerCreateForm>
  >(
    (index) => (value) => {
      const startDate = value[0];
      const endDate = value[1];

      if (index === 0) {
        if (!startDate) {
          return $t({ id: 'input-ErrorRequired' });
        }

        const error =
          (!event || event.event_status === EventsStatuses.Draft) && isDateInPast(startDate, null);

        if (error) {
          return $t({ id: 'events-action-Started' });
        }
      }

      if (index === 1) {
        if (!endDate) {
          return $t({ id: 'input-ErrorRequired' });
        }

        const error =
          (!event || event.event_status === EventsStatuses.Draft) && isDateInPast(endDate, null);

        if (error) {
          return $t({ id: 'events-action-Started' });
        }
      }
    },
    [$t, event],
  );

  return (
    <Controller
      control={control}
      name="date"
      rules={{
        validate: {
          startDate: validate(0),
          endDate: validate(1),
        },
      }}
      render={({ field, fieldState }) => {
        const generalError = fieldState.error;
        const fromDateError = generalError?.type === 'startDate';
        const toDateError = generalError?.type === 'endDate';

        return (
          <DateRangeSelect
            singleDatePossible
            date={field.value || []}
            fromDateError={fromDateError}
            toDateError={toDateError}
            error={generalError}
            DateRangeSelectContentProps={{
              disablePast: true,
              shouldDisableDay: (date) =>
                !!((start && isBefore(date, start)) || (end && isAfter(date, end))),
            }}
            onClose={() => {
              const date = getValues('date');
              const isStartInPast = date[0] && isDateInPast(date[0]);
              const singleDatePossible = !(isPublished && isStartInPast);

              if (singleDatePossible && !date[1]) {
                setValue('date', [date[0], date[0]]);
              }
            }}
            onSetDate={([startDate, endDate]) => {
              field.onChange([
                format(startDate, DEFAULT_DATE_FORMAT_FNS),
                format(endDate, DEFAULT_DATE_FORMAT_FNS),
              ]);
            }}
          />
        );
      }}
    />
  );
};
