import { Stack, Typography } from '@mui/material';
import { EditIcon, PlusIcon } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { SimpleButton } from '../../../../../components/uikit/SimpleButton/SimpleButton';
import { ModalPanel } from '../../../../../components/uikit-components/Modal/Modal.styled';
import useQueryStringParams from '../../../../../hooks/useQueryStringParams';
import {
  SchoolTuneStatusesArchivable,
  SchoolTuneStatusesForm,
  SchoolTuneStatusModalMode,
  SchoolTuneStatusType,
} from './scheme';
import { SchoolTuneStatusesModalMainPanel } from './SchoolTuneStatusesModalMainPanel';

export interface SchoolTuneStatusesModalStudentsPanelProps extends SchoolTuneStatusesArchivable {}

export const SchoolTuneStatusesModalStudentsPanel: FC<SchoolTuneStatusesModalStudentsPanelProps> =
  ({ showArchived, setShowArchived, autoSwitchedArchivedOn }) => {
    const navigate = useNavigate();
    const { type = SchoolTuneStatusModalMode.STUDENTS } = useQueryStringParams<'type'>();
    const { watch } = useFormContext<SchoolTuneStatusesForm>();

    const isActive = type === SchoolTuneStatusModalMode.STUDENTS;
    const statuses = watch('students');
    const hasStatuses = (Object.keys(statuses) as (keyof SchoolTuneStatusType)[]).every(
      (key) => statuses[key].length,
    );

    const handlePanelClick = useCallback(() => {
      navigate({ search: `?type=${SchoolTuneStatusModalMode.STUDENTS}` }, { replace: true });
    }, [navigate]);

    return (
      <>
        <ModalPanel active={isActive}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">
              <FormattedMessage id="school-tabs-Statuses-StudentsStatuses" />
            </Typography>
            {!isActive &&
              (hasStatuses ? (
                <SimpleButton startIcon={<EditIcon />} onClick={handlePanelClick}>
                  <FormattedMessage id="school-tabs-Statuses-EditStatuses" />
                </SimpleButton>
              ) : (
                <SimpleButton startIcon={<PlusIcon />} onClick={handlePanelClick}>
                  <FormattedMessage id="school-tabs-Statuses-AddStatus" />
                </SimpleButton>
              ))}
          </Stack>
        </ModalPanel>
        {isActive && (
          <SchoolTuneStatusesModalMainPanel
            type={SchoolTuneStatusModalMode.STUDENTS}
            showArchived={showArchived}
            setShowArchived={setShowArchived}
            autoSwitchedArchivedOn={autoSwitchedArchivedOn}
          />
        )}
      </>
    );
  };
