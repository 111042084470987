import { Box, BoxProps } from '@mui/material';
import { FC } from 'react';

export interface SpinProps extends BoxProps<'svg'> {
  size?: number;
}

export const Spin: FC<SpinProps> = ({ size = 1, ...props }) => {
  return (
    <Box
      component="svg"
      width={`${size}em`}
      height={`${size}em`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Box
        component="circle"
        cx="10"
        cy="10"
        r="9"
        sx={{
          stroke: 'color-mix(in srgb, currentColor, transparent 80%)',
        }}
      />
      <Box
        component="circle"
        cx="10"
        cy="10"
        r="9"
        sx={{
          stroke: 'currentColor',

          strokeDasharray: 57,
          strokeDashoffset: 57,
          transform: 'rotate(0deg)',
          transformOrigin: 'center',

          animationName: 'spinAnimate',
          animationDuration: '4s',
          animationTimingFunction: 'linear',
          animationIterationCount: 'infinite',

          '@keyframes spinAnimate': {
            from: { strokeDasharray: 57, strokeDashoffset: 57, transform: 'rotate(0deg)' },
            '50%': { strokeDasharray: 57, strokeDashoffset: 0, transform: 'rotate(360deg)' },
            to: { strokeDasharray: 57, strokeDashoffset: -57, transform: 'rotate(720deg)' },
          },
        }}
      />
    </Box>
  );
};
