import { Box, Icon, Stack, Typography } from '@mui/material';
import { Event, FilterKeys } from '@schooly/api';
import { getParticipantLabel } from '@schooly/components/filters';
import { RecurrenceInfo } from '@schooly/components/recurring';
import { DATE_FORMAT, DATE_FORMAT_SHORT, EventsInvite } from '@schooly/constants';
import { ParentIcon, StudentIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { SignUpForm } from '../../../context/signUps/WithSignUp';
import { InviteesCellContent } from '../../Events/EventsGrid';
import { useEventColor } from '../../Events/useEventColor';

export const SignUpEventDetails: FC = () => {
  const { watch } = useFormContext<SignUpForm>();
  const navigate = useNavigate();

  const event = watch('event');
  const recurringState = event?.recurring_state;

  const date = event ? formatEventDate(event) : undefined;

  const inviteeColor = useEventColor(event);

  if (!event) {
    return null;
  }

  return (
    <Stack
      sx={{
        p: 2,
        bgcolor: 'background.default',
        borderRadius: 0.5,
        overflow: 'hidden',
      }}
    >
      <Stack direction="row" alignItems="center" mb={0.5} gap={0.5}>
        <Typography variant="h2" flex="0 0 auto">
          {event.title}
        </Typography>
        {recurringState && (
          <RecurrenceInfo
            recurringState={recurringState}
            onClick={() => {
              navigate(
                {
                  pathname: '/events',
                },
                { state: { [FilterKeys.RecurrenceId]: recurringState.recurrence_id } },
              );
            }}
          />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" flex="0 0 auto" gap={2.5} mb={2}>
        <Typography color="text.primary">{date}</Typography>

        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{
            '.svg-icon, .MuiTypography-root': {
              color: inviteeColor,
            },
          }}
        >
          <Icon>
            {event.invitee_type === EventsInvite.StudentsOnly ? <StudentIcon /> : <ParentIcon />}
          </Icon>
          {event.invitee_type && (
            <Typography>
              <FormattedMessage id={getParticipantLabel(event.invitee_type)} />
            </Typography>
          )}
        </Stack>
      </Stack>

      {event && (
        <InviteesCellContent
          criteria={event.criteria}
          sx={{
            flex: '0 0 auto',
            mb: 2,

            '& .TagSelect': { bgcolor: 'background.paper' },
            '& .CheckOverflow:not(.CheckOverflow-hidden)': { ml: 1 },
          }}
        />
      )}

      <Box sx={{ flex: '1 1 auto', overflowY: 'auto', mx: -2, px: 2 }}>
        <Typography dangerouslySetInnerHTML={{ __html: event.description }} />
      </Box>
    </Stack>
  );
};

export const formatEventDate = (event: Event) => {
  const firstDate = newDateTimezoneOffset(event.start);
  const lastDate = newDateTimezoneOffset(event.end);

  if (firstDate.getTime() === lastDate.getTime()) {
    return format(firstDate, DATE_FORMAT);
  }

  if (firstDate.getMonth() === lastDate.getMonth()) {
    return `${firstDate.getDate()} - ${format(lastDate, DATE_FORMAT)}`;
  }

  return `${format(firstDate, DATE_FORMAT_SHORT)} - ${format(lastDate, DATE_FORMAT)}`;
};
