import { Box, IconButton, Menu, MenuItem, styled } from '@mui/material';
import { CrossIcon, DropdownIcon } from '@schooly/style';
import React from 'react';

export interface DropdownMenuProps {
  size?: 'small' | 'medium' | 'large';
}

export const DropdownMenu = styled(Menu)<DropdownMenuProps>(({ theme, size }) => ({
  '& .MuiPaper-root': {
    ...theme.mixins.borderControl(),
    minWidth: 120,
  },

  '& .MuiList-root': {
    padding: 0,
  },

  '& .MuiMenuItem-root': {
    ...theme.typography.h3,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75),
    margin: theme.spacing(0, 0.75, 0.75),
    minHeight: 34,
    color: theme.palette.text.primary,

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },

    '&.Mui-focusVisible, &:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      '&.Mui-selected': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },

  '& .DropdownMenuItemTop': {
    paddingTop: size === 'small' ? 3.5 : 10.5,
    paddingBottom: size === 'small' ? 3.5 : 10.5,
  },
}));

DropdownMenu.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    elevation: 0,
  },
  disableRestoreFocus: true,
};

export const DropdownMenuItemTop = styled(MenuItem)(({ theme }) => ({
  borderBottom: theme.mixins.borderControlValue(),
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,

  '&&&': {
    margin: theme.spacing(0, 0, 0.75),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },

  '&.Mui-disabled': {
    opacity: 1,
  },
}));

DropdownMenuItemTop.defaultProps = {
  disabled: true,
  className: 'DropdownMenuItemTop',
};

export const DropdownOpenIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.125),
  right: theme.spacing(1.5),
  color: theme.palette.primary.main,

  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

DropdownOpenIcon.defaultProps = {
  size: 'small',
  children: (
    <Box sx={{ transform: 'rotate(180deg)' }}>
      <DropdownIcon />
    </Box>
  ),
};

export const DropdownClearIcon = styled(DropdownOpenIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,

  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

DropdownClearIcon.defaultProps = {
  children: <CrossIcon />,
};
