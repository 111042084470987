import { AnnualPlanRecordTypes } from '@schooly/api';
import React, { useCallback } from 'react';

export const useAnnualPlannerFilter = () => {
  const onFilterItemMouseEnter = useCallback<
    (type: AnnualPlanRecordTypes) => React.MouseEventHandler
  >(
    (type) => () => {
      document.querySelectorAll(`.AnnualPlannerCalendarRecordGroup-${type}`).forEach((record) => {
        record.classList.add('AnnualPlannerCalendarRecordGroup-highlight');
      });
    },
    [],
  );

  const onFilterItemMouseLeave = useCallback<React.MouseEventHandler>(() => {
    document
      .querySelectorAll(
        '.AnnualPlannerCalendarRecordGroup-root.AnnualPlannerCalendarRecordGroup-highlight',
      )
      .forEach((record) => {
        record.classList.remove('AnnualPlannerCalendarRecordGroup-highlight');
      });
  }, []);

  const getFilterItemProps = useCallback(
    (type: AnnualPlanRecordTypes) => ({
      onMouseEnter: onFilterItemMouseEnter(type),
      onMouseLeave: onFilterItemMouseLeave,
    }),
    [onFilterItemMouseEnter, onFilterItemMouseLeave],
  );

  return { getFilterItemProps, onFilterItemMouseEnter, onFilterItemMouseLeave };
};
