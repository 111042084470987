import { Box, BoxProps, Dialog, Stack, styled, Typography } from '@mui/material';

const MEDIUM_SCREEN_SIDEBAR_WIDTH = 350;
const SMALL_SCREEN_SIDEBAR_WIDTH = 370;

export interface ModalSmallProps {
  sidebarOpen?: boolean;
}

export const ModalSmall = styled(Dialog)<ModalSmallProps>(({ theme, sidebarOpen }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& .MuiDialog-paper > form': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    height: '100%',
  },

  '& .MuiDialog-paperFullWidth': {
    width: 700,

    [theme.breakpoints.only('lg')]: {
      width: 600,
    },
    [theme.breakpoints.down('lg')]: {
      width: sidebarOpen ? `calc(100vw - ${MEDIUM_SCREEN_SIDEBAR_WIDTH}px)` : '100vw',
    },
    [theme.breakpoints.down('md')]: {
      width: sidebarOpen ? `calc(100vw - ${SMALL_SCREEN_SIDEBAR_WIDTH}px)` : undefined,
    },
  },
}));

export const ModalConfirm = styled(Dialog)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: theme.zIndex.modal + 1,

  '& .MuiDialog-paper > form': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    height: 'unset',
  },

  '& .MuiDialog-paperFullWidth': {
    width: 600,
    height: 'unset',
  },
}));

export const ModalOpaque = styled(ModalConfirm)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.common.lightBg,
  },
  '& .MuiDialog-paper': {
    boxShadow: '0px 30px 50px rgba(37, 39, 90, 0.1)',
  },
}));

ModalSmall.defaultProps = {
  fullWidth: true,
};

export const ModalLarge = styled(ModalSmall)(({ theme }) => ({
  '& .MuiDialog-paperFullWidth': {
    width: 1000,

    [theme.breakpoints.down('lg')]: {
      width: '100vw',
    },
  },
}));

export interface ModalPanelProps extends BoxProps {
  withBorderTop?: boolean;
  withBorderBottom?: boolean;
  active?: boolean;
  cursor?: boolean;
  disabled?: boolean;
  flat?: boolean;
  fullHeight?: boolean;
}

export const ModalPanel = styled(Box, {
  shouldForwardProp: (prop) =>
    !(
      [
        'withBorderTop',
        'withBorderBottom',
        'active',
        'cursor',
        'disabled',
        'flat',
        'fullHeight',
      ] as PropertyKey[]
    ).includes(prop),
})<ModalPanelProps>(
  ({ theme, withBorderTop, withBorderBottom, active, cursor, disabled, flat, fullHeight }) => ({
    flex: fullHeight ? '1 1 100%' : '0 0 auto',
    padding: flat ? 0 : theme.spacing(2.5),
    backgroundColor: active ? theme.palette.background.paper : theme.palette.background.default,
    borderTop: withBorderTop ? theme.mixins.borderValue() : undefined,
    borderBottom: withBorderBottom ? theme.mixins.borderValue() : undefined,
    overflow: 'auto',
    cursor: cursor ? 'pointer' : undefined,
    ...(disabled && {
      pointerEvents: 'none',
    }),
  }),
);

export const ModalSubpanel = styled(ModalPanel)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const ModalTitleMultiLine = styled(Typography)();

const ModalTitleDefaultProps = {
  variant: 'h1',
  className: 'ModalTitle',
} as const;

ModalTitle.defaultProps = ModalTitleDefaultProps;
ModalTitleMultiLine.defaultProps = ModalTitleDefaultProps;

export interface ModalMainProps {
  scrollable?: boolean;
}

export const ModalMain = styled(Stack)<ModalMainProps>(({ theme, scrollable }) => ({
  flex: '1 1 auto',
  flexDirection: 'row',
  alignItems: 'stretch',
  backgroundColor: theme.palette.background.default,
  overflow: scrollable ? 'auto' : 'hidden',
}));

export const MODAL_SIDEBAR_WIDTH = 200;

export const ModalSideBar = styled(ModalPanel)(({ theme }) => ({
  flex: `0 0 ${MODAL_SIDEBAR_WIDTH}px`,
  height: '100%',
  padding: theme.spacing(2, 1.25),
  borderRight: theme.mixins.borderValue(),
}));

ModalSideBar.defaultProps = {
  active: true,
};

export const ModalContent = styled(ModalPanel)(({ theme, flat }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  height: '100%',
  paddingTop: flat ? 0 : theme.spacing(2.5),
  paddingBottom: flat ? 0 : theme.spacing(2.5),
}));

export const ModalContentStickyWrapper = styled(Stack)({
  minHeight: '100%',
});

export const ModalContentStickyTitle = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(-2.5),
  margin: theme.spacing(-2.5, -2.5, 0),
  padding: theme.spacing(2.5, 2.5, 0),
  background: theme.palette.background.default,
  zIndex: theme.zIndex.appBar,
}));

export const ModalFooter = styled(ModalPanel)(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  marginTop: -1,
}));

ModalFooter.defaultProps = {
  withBorderTop: true,
};

export const ModalSearchWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),

  '& .SearchInput': {
    margin: 0,
    flexGrow: 1,

    '& input:not(:focus)': {
      border: 'none',
    },

    '& .input-group-text': {
      border: 'none',
    },
  },

  '& .SchoolYearPicker': {
    flex: '0 0 120px',
  },
}));
