import { IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { CrossIcon, DropdownIcon, LockIcon } from '@schooly/style';
import { FC, ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { InputEndAdornmentIcon } from './InputEndAdornment.styled';

export interface InputEndAdornmentProps {
  required?: boolean;
  hideLabel?: boolean;
  dropdown?: boolean;
  canClear?: boolean;
  onClear?: () => void;
  hasValue?: boolean;
  locked?: boolean;
  customIcon?: ReactNode;
}

export const InputEndAdornment: FC<InputEndAdornmentProps> = ({
  required,
  customIcon,
  hideLabel,
  dropdown,
  canClear,
  onClear,
  hasValue,
  locked,
}) => {
  const handleClear = useCallback<React.MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      onClear?.();
    },
    [onClear],
  );

  return (
    <InputAdornment position="end">
      {locked ? (
        <InputEndAdornmentIcon>
          <IconButton>
            <LockIcon />
          </IconButton>
        </InputEndAdornmentIcon>
      ) : (
        <Stack direction="row" gap={1} alignItems="center">
          {!hideLabel && !hasValue && (
            <Typography
              variant="h3"
              className="h3-optional-required"
              fontStyle="italic"
              color="unset"
            >
              <FormattedMessage id={required ? 'input-required' : 'input-optional'} />
            </Typography>
          )}
          <InputEndAdornmentIcon>
            {canClear && hasValue && (
              <IconButton className="FormTextField-clear" onClick={handleClear}>
                <CrossIcon />
              </IconButton>
            )}
            {dropdown && !canClear && (
              <IconButton className="FormTextField-dropdown">
                <DropdownIcon />
              </IconButton>
            )}
            {customIcon}
          </InputEndAdornmentIcon>
        </Stack>
      )}
    </InputAdornment>
  );
};
