import { Box, Icon, IconButton, Typography } from '@mui/material';
import {
  Application,
  ApplicationChild,
  ApplicationCustomField,
  CustomField,
  CustomFieldApplicableTo,
} from '@schooly/api';
import { ControlTextField, InputEndAdornment } from '@schooly/components/form-text-field';
import { CrossIcon, DoneIcon, ModalContent, ModalHeader, ModalMain, Spin } from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { getCustomFieldsToShow } from '../utils';
import { ApplicationAdditionalInformationPreview } from './ApplicationPreviewCommon/ApplicationAdditionalInformationPreview';
import { ApplicationChildrenPreview } from './ApplicationPreviewCommon/ApplicationChildrenPreview';
import { ApplicationParentPreview } from './ApplicationPreviewCommon/ApplicationParentPreview';

export type ApplicationFormChild = ApplicationChild & { originId: string };

export interface ApplicationChildForm {
  children: ApplicationFormChild[];
}

interface ApplicationPreviewContentProps {
  application: Application;
  updating: Record<string, boolean>;
  showSuccessIcon: Record<string, boolean>;
  headerTitle: React.ReactNode;
  onClose: () => void;
  onSubmit: (data: ApplicationChildForm) => void;
  footerActions: React.ReactNode;
  headerContent?: React.ReactNode;
  withNotes?: boolean;
  onNoteChange?: (child: ApplicationFormChild) => void;
  canEditNotes?: boolean;
  applicationCustomFields: CustomField[];
  withoutActionBackground?: boolean;
}

export const ApplicationPreviewContent: FC<ApplicationPreviewContentProps> = ({
  application,
  updating,
  headerTitle,
  onClose,
  onSubmit,
  footerActions,
  headerContent,
  withNotes,
  onNoteChange,
  canEditNotes,
  applicationCustomFields,
  withoutActionBackground,
  showSuccessIcon,
}) => {
  const { $t } = useIntl();
  const form = useForm<ApplicationChildForm>({
    defaultValues: {
      children: application.children.map((child) => ({ ...child, originId: child.id })),
    },
  });

  const renderIcon = useCallback(
    (childId: string) => {
      if (updating[childId] || showSuccessIcon[childId]) {
        return (
          <Icon sx={{ color: 'text.secondary' }}>
            {updating[childId] && <Spin />}
            {showSuccessIcon[childId] && <DoneIcon />}
          </Icon>
        );
      }
      return;
    },
    [showSuccessIcon, updating],
  );

  const applicationChildrenPreviewData = useMemo(() => {
    return (
      application.children.map((c) => {
        const customFields =
          applicationCustomFields?.reduce<ApplicationCustomField[]>(
            (acc, { label, data_type, id, applicable_to }) => {
              const existedField = c.custom_fields_values?.find((f) => id === f.custom_field_id);

              if (application.status === 'rejected') {
                if (existedField) {
                  return [...acc, { ...existedField, data_type }];
                }

                if (applicable_to.includes(CustomFieldApplicableTo.DefaultApplicationValues)) {
                  return [
                    ...acc,
                    {
                      custom_field_id: id,
                      label,
                      data_type,
                      value: '',
                      value_id: id,
                    },
                  ];
                }

                return acc;
              }

              return existedField
                ? [...acc, { ...existedField, data_type }]
                : [
                    ...acc,
                    {
                      custom_field_id: id,
                      label,
                      data_type,
                      value: '',
                      value_id: id,
                    },
                  ];
            },
            [],
          ) ?? [];

        return {
          ...c,
          custom_fields_values: customFields,
        };
      }) ?? []
    );
  }, [application, applicationCustomFields]);

  const customFieldsToShow = useMemo(
    () => getCustomFieldsToShow(application.general_info_custom_fields_values),
    [application],
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalHeader title={headerTitle}>
          {headerContent}
          <IconButton onClick={onClose} data-test-id="application-close-button">
            <CrossIcon />
          </IconButton>
        </ModalHeader>

        <ModalMain sx={{ paddingTop: 0 }}>
          <ModalContent sx={{ paddingTop: 0, overflowX: 'hidden' }}>
            {!!applicationChildrenPreviewData.length &&
              applicationChildrenPreviewData.map((child, index) => {
                return (
                  <ApplicationChildrenPreview
                    withoutActionBackground={withoutActionBackground}
                    key={child.id}
                    child={child}
                    index={index}
                    isConverted={!!(application.status === 'converted')}
                  >
                    {withNotes && (
                      <Box
                        pt={2}
                        sx={{
                          '& .MuiFormControl-root': { bgcolor: 'background.paper' },
                          '& .FormTextField-clear': { visibility: 'hidden' },
                          ':hover': { '& .FormTextField-clear': { visibility: 'visible' } },
                        }}
                        onBlur={() => {
                          const child = form.getValues(`children.${index}`);
                          onNoteChange?.(child);
                        }}
                      >
                        <ControlTextField
                          name={`children.${index}.notes`}
                          control={form.control}
                          label={$t({ id: 'applications-AdmissionNotes' })}
                          fullWidth
                          disabled={updating[child.id] || !canEditNotes}
                          InputProps={{
                            endAdornment: (
                              <InputEndAdornment
                                canClear={
                                  canEditNotes && !updating[child.id] && !showSuccessIcon[child.id]
                                }
                                hasValue={!!form.getValues(`children.${index}.notes`)}
                                onClear={() => {
                                  form.setValue(`children.${index}.notes`, '');
                                  const child = form.getValues(`children.${index}`);
                                  onNoteChange?.(child);
                                }}
                                customIcon={renderIcon(child.id)}
                              />
                            ),
                          }}
                          data-test-id="application-note-block"
                        />
                      </Box>
                    )}
                  </ApplicationChildrenPreview>
                );
              })}
            {!!application.adults.length && (
              <ApplicationParentPreview
                withoutActionBackground={withoutActionBackground}
                parents={application.adults}
              />
            )}

            {!!customFieldsToShow.length && (
              <>
                <Typography variant="h2" sx={{ mt: 3, mb: 2.5 }}>
                  <FormattedMessage id="userType-general-information" />
                </Typography>

                <ApplicationAdditionalInformationPreview
                  withTitle={false}
                  customFields={customFieldsToShow}
                  maxWidth={800}
                />
              </>
            )}
          </ModalContent>
        </ModalMain>
        {footerActions}
      </form>
    </FormProvider>
  );
};
