import { checkUserHealth } from '@schooly/api';
import { TIME_MARGIN } from '@schooly/api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useMaintenance from '../../../context/maintenance/useMaintenance';

import './index.scss';

const START_COUNTDOWN = 1000 * 60 * 10;

const MaintenanceModal: React.FC = () => {
  const [isOpen, setIsPopupOpen] = useState(false);
  const [time, setTime] = useState<string | null>(null);
  const [isForceChecked, setIsForceChecked] = useState(false);
  const { enabled, timestamp } = useMaintenance();

  useEffect(() => {
    if (!timestamp || enabled) return undefined;

    const intervalId = setInterval(() => {
      const diff = moment(timestamp).diff(moment());

      let minutes: string | number = moment.duration(diff).get('minutes');
      let seconds: string | number = moment.duration(diff).get('seconds');

      if (minutes < 10) {
        minutes = `0${minutes < 0 ? 0 : minutes}`;
      }

      if (seconds < 10) {
        seconds = `0${seconds < 0 ? 0 : seconds}`;
      }

      const formattedTime = `${minutes}:${seconds}`;
      setTime(formattedTime);
      setIsPopupOpen(diff <= START_COUNTDOWN);

      // Manually check the maintenance status if the timer gets 00:00,
      if (!isForceChecked && diff <= TIME_MARGIN) {
        setIsForceChecked(true);
        checkUserHealth();
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isForceChecked, enabled, timestamp]);

  if (enabled) return null;

  return isOpen && time ? (
    <div className="MaintenanceModal">
      <h1 className="MaintenanceModal__title">
        <FormattedMessage id="maintenance-WeWillStart" />
      </h1>
      <p className="MaintenanceModal__text">
        <FormattedMessage id="maintenance-PleaseMakeSure" values={{ time }} />
      </p>
    </div>
  ) : null;
};

export default MaintenanceModal;
