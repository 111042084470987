import { Box, IconButton, MenuItem, MenuList, Stack } from '@mui/material';
import {
  Assessment,
  AssessmentGroupsQueryFilters,
  FilterKeys,
  Group,
  useGetAssessmentGroupsQuery,
} from '@schooly/api';
import { FilterButton, SearchInput } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { CrossIcon, FilterIcon, Loading } from '@schooly/style';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { LongNameWithVerticalTooltip } from '../../../components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { ModalSideBar } from '../../../components/uikit-components/Modal/Modal.styled';
import useAppLocation from '../../../hooks/useAppLocation';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import { InlineFiltersTags } from '../../Attendance/AttendanceRegisterCreateModal/InlineFiltersTags';
import { AssessmentGroupFiltersPopup } from './AssessmentGroupFiltersPopup';

interface AssessmentsPreviewModalSideBarProps {
  assessment?: Assessment;
}

const initialFiltersState: AssessmentGroupsQueryFilters = {
  [FilterKeys.StaffHouse]: [],
  [FilterKeys.StudentHouse]: [],
  [FilterKeys.AgeGroup]: [],
  [FilterKeys.StaffStatus]: [],
  [FilterKeys.StudentStatus]: [],
  [FilterKeys.Subject]: [],
  [FilterKeys.OnlyTutorGroups]: [],
};

export const AssessmentsPreviewModalSideBar: FC<AssessmentsPreviewModalSideBarProps> = ({
  assessment,
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useAppLocation();
  const { group_id } = useQueryStringParams<'group_id'>();

  const [filtersModalVisible, openFilterModal, closeFiltersModal] = useFlag(false);

  const {
    data,
    hasNextPage,
    params,
    setParams,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useGetAssessmentGroupsQuery(
    {
      assessment_id: assessment?.id ?? '',
      filters: initialFiltersState,
    },
    { enabled: Boolean(group_id) },
  );

  const loaderRef = useInfiniteScroll(isFetching, fetchNextPage, hasNextPage);

  const groups = useMemo<Group[]>(() => {
    return data?.pages.reduce<Group[]>((prev, curr) => [...prev, ...curr.results], []) ?? [];
  }, [data]);

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: AssessmentGroupsQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const handleResetFilters = useCallback(() => {
    setParams((p) => ({ ...p, filters: {} }));
  }, [setParams]);

  const hasFiltersApplied = Object.keys(initialFiltersState).some(
    (key) => !!params.filters[key as keyof typeof initialFiltersState]?.length,
  );

  const showEmptyStub = !isLoading && !groups.length;

  const renderGroupsList = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (showEmptyStub) {
      return <NoSearchResultsFound type="small" />;
    }

    return (
      <>
        {groups.map((group) => (
          <Link key={group.id} to={{ search: `?group_id=${group.id}` }} state={location.state}>
            <MenuItem selected={group.id === group_id}>
              <LongNameWithVerticalTooltip>{group.name}</LongNameWithVerticalTooltip>
            </MenuItem>
          </Link>
        ))}

        {hasNextPage && (
          <Box py={3}>
            {isFetchingNextPage && <Loading />}
            <div ref={loaderRef} />
          </Box>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!group_id && assessment?.groups.length) {
      navigate({ search: `?group_id=${assessment?.groups[0].id}` }, { state: location.state });
    }
  }, [assessment?.groups, group_id, location.state, navigate]);

  return (
    <ModalSideBar
      sx={{ paddingTop: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
    >
      <Box px={1} pt={0.75}>
        <Stack gap={1}>
          <Stack justifyContent="center" position="relative">
            <SearchInput
              placeholder={formatMessage({ id: 'people-Search' })}
              onChangeText={handleSetFiltersQuery}
              value={params.query || ''}
              canClear={false}
              InputProps={{
                endAdornment: (
                  <Stack pl={0.75} gap={0.5} pr={1} flexDirection="row">
                    {!!params.query && (
                      <IconButton
                        sx={(theme) => ({ '&:hover': { color: theme.palette.text.primary } })}
                        inverse
                        onClick={() => handleSetFiltersQuery('')}
                      >
                        <CrossIcon />
                      </IconButton>
                    )}
                    <FilterButton onClick={openFilterModal} hasFiltersApplied={hasFiltersApplied}>
                      <FilterIcon />
                    </FilterButton>
                  </Stack>
                ),
              }}
            />
          </Stack>
          <InlineFiltersTags
            onResetFilters={handleResetFilters}
            onOpenFilters={openFilterModal}
            filters={params.filters}
          />
        </Stack>
        {filtersModalVisible && (
          <AssessmentGroupFiltersPopup
            onClose={closeFiltersModal}
            defaultFilters={initialFiltersState}
            onSetFilters={handleSetFilters}
            filters={params.filters}
          />
        )}
      </Box>

      <MenuList sx={{ height: '100%', overflow: 'scroll' }}>{renderGroupsList()}</MenuList>
    </ModalSideBar>
  );
};
