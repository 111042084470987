import { exportAttendanceRegister } from '@schooly/api';
import { AttendanceRegisterForSchool } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { DownloadIcon, Spin } from '@schooly/style';
import React, { FC, useCallback, useState } from 'react';

import { AttendanceExportButton, AttendanceExportButtonProps } from './AttendanceExport.styled';

export interface AttendanceExportProps extends AttendanceExportButtonProps {
  register: AttendanceRegisterForSchool;
}

export const AttendanceExport: FC<AttendanceExportProps> = ({ register, ...props }) => {
  const { showError } = useNotifications();
  const [fetching, setFetching] = useState(false);

  const handleClick = useCallback<React.MouseEventHandler>(
    async (event) => {
      event.stopPropagation();

      if (fetching) {
        return;
      }

      setFetching(true);

      try {
        const data = await exportAttendanceRegister(register.id);

        let filename = `${register.name ?? ''}.${register.register_date}.pdf`;
        filename = decodeURI(filename);

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        showError(error as ApiError);
      }

      setFetching(false);
    },
    [fetching, register.id, register.name, register.register_date, showError],
  );

  return (
    <AttendanceExportButton onClick={handleClick} {...props}>
      {fetching ? <Spin /> : <DownloadIcon />}
    </AttendanceExportButton>
  );
};
