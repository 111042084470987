import { Icon, Stack, Typography } from '@mui/material';
import { GetGroupsQueryFilters, useGetAreasOfLearningQuery } from '@schooly/api';
import {
  DropdownSelect,
  DropdownSelectProps,
  SelectContentSkeleton,
  SelectSearchInput,
} from '@schooly/components/filters';
import { DoneIcon } from '@schooly/style';
import { FC, useCallback, useRef, useState } from 'react';

type GroupSelectProps = {
  schoolId: string;
  value: string;
  onChange: (v: string) => void;
  filters?: GetGroupsQueryFilters;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const ReportAreaSuggestedInput: FC<GroupSelectProps> = ({
  schoolId,
  value,
  onChange,
  placeholder,
  filters = {},
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [queryEnabled, setQueryEnabled] = useState(false);

  const { data } = useGetAreasOfLearningQuery(schoolId, {
    enabled: queryEnabled,
    refetchOnMount: 'always',
  });

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const entries = data.filter((a) => {
      const name = a.name.toLowerCase();
      const v = value.toLowerCase();

      return name.includes(v.trim());
    });

    if (!entries.length) return null;

    return (
      <>
        {entries.map((area) => {
          const isSelected = area.name.trim() === value.trim();

          return (
            <Stack
              key={area.id}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => {
                onChange(area.name);
                dropdown.current?.close();
              }}
              px={1}
              gap={2}
              py={0.5}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                },
              })}
            >
              <Typography variant="h3" color="common.grey2">
                {area.name}
              </Typography>
              <Icon
                sx={{
                  visibility: isSelected ? 'visible' : 'hidden',
                }}
              >
                <DoneIcon />
              </Icon>
            </Stack>
          );
        })}
      </>
    );
  }, [data, onChange, value]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={placeholder}
      hasValues={value.length > 0}
      renderContent={renderContent}
      onOpen={() => inputRef.current?.focus()}
      onClickInputArea={() => inputRef.current?.focus()}
      withoutRightIcon
    >
      {(opened) => (
        <SelectSearchInput
          sx={(theme) => ({ ...theme.typography.h3, width: value ? '100%' : undefined })}
          debounced={false}
          ref={inputRef}
          autoFocus={opened}
          value={value}
          onChangeText={onChange}
        />
      )}
    </DropdownSelect>
  );
};
