import { AxiosError, AxiosInstance } from 'axios';

export default function avatarMiddleware(instance: AxiosInstance) {
  return instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.config.url && /\/avatar\?/.test(error.config.url)) {
        return Promise.resolve();
      }

      return Promise.reject(error);
    },
  );
}
