import { Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  GetReportsQueryFilters,
  GetReportsQuerySort,
  Report,
  REPORTS_QUERY_FILTER_KEYS,
  useGetReportsQuery,
} from '@schooly/api';
import { FilterKeys, SORT_DIRECTION } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  checkHasFiltersApplied,
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { GridBody, MainPageGrid, PlusIcon, SkeletonGridLoader, SkeletonRows } from '@schooly/style';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import useAppLocation from '../../hooks/useAppLocation';
import useSchoolYears from '../../hooks/useSchoolYears';
import AccessDenied from '../AccessDenied';
import { ReportsFilters } from './ReportsFilters';
import { ReportRow, ReportsHeader } from './ReportsGrid';

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 4;

type ReportsContentProps = {
  initialFilters?: GetReportsQueryFilters;
};

export const ReportsContent: FC<ReportsContentProps> = ({ initialFilters }) => {
  const location = useAppLocation();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { schoolId, permissions } = useAuth();

  const { defaultValidity } = useSchoolYears();

  const defaultFilters: GetReportsQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [
        defaultValidity?.start || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        defaultValidity?.end || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [defaultValidity],
  );

  const { lastAppliedFilter } = useLastAppliedFiltersState({
    type: StoredFilterSections.Reports,
    filterKeys: REPORTS_QUERY_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: REPORTS_QUERY_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const {
    data,
    isLoading,
    params,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    setParams,
    error,
  } = useGetReportsQuery(
    {
      school_id: schoolId || '',
      query: '',
      page_size: PAGE_SIZE,
      sort: { columnTextId: 'report_date', direction: SORT_DIRECTION.ASC },
      filters: lastAppliedFilter ?? initialFiltersState,
    },
    { refetchOnMount: 'always' },
  );

  const filtersDateString = params.filters.date?.join('');
  const notActualInitialDate =
    !defaultValidity?.isActual &&
    filtersDateString === defaultFilters?.date?.join('') &&
    filtersDateString !== initialFilters?.date?.join('');

  useSyncFiltersStateWithSearchParams({
    pathname: '/reports',
    filters: params.filters,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Reports,
    filters: params.filters,
    schoolId: schoolId || '',
  });

  const handleChangeSort = useCallback(
    (sort: GetReportsQuerySort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetReportsQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const entries = useMemo(
    () => data?.pages.reduce<Report[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  const hasFiltersApplied =
    !!params.query?.length || checkHasFiltersApplied(REPORTS_QUERY_FILTER_KEYS, params.filters);

  const handleAddButtonClick = useCallback(() => {
    navigate('/reports/new', {
      state: { backgroundLocation: location },
    });
  }, [location, navigate]);

  const total = data?.pages[0]?.count;

  if (!schoolId) return null;

  if (error) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonTextId="reports-CreateReport"
          pageTitleTextId="section-Reports"
          showActionButton={permissions.includes('assessment_manager')}
          buttonIcon={<PlusIcon />}
          onButtonClick={handleAddButtonClick}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={formatMessage({ id: 'reports-Search' })}
          />
        </PageHeader>
        <ReportsFilters
          notActualInitialDate={notActualInitialDate}
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          defaultSchoolYear={defaultValidity}
          defaultUserFilters={defaultUserFilters}
        />
      </Stack>
      <MainPageGrid mt={2}>
        <ReportsHeader sort={params.sort} onChangeSort={handleChangeSort} />
        <GridBody>
          {entries?.map((entry) => (
            <ReportRow report={entry} key={entry.id} />
          ))}
          {isLoading && <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />}
          <SkeletonGridLoader
            isFetching={isLoading || isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            columnsCount={SKELETON_COLS}
            amount={Math.min(
              PAGE_SIZE,
              total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
            )}
          />
        </GridBody>
      </MainPageGrid>

      {!isLoading &&
        !entries.length &&
        (hasFiltersApplied ? (
          <MainGridNoResultsStub textId="reports-NoMatches" />
        ) : (
          <NoListItemsStub
            titleText={<FormattedMessage id="reports-YouDoNotHaveReportsYet" />}
            buttonText={<FormattedMessage id="reports-CreateReport" />}
            onButtonClick={handleAddButtonClick}
            type="report"
          />
        ))}
    </>
  );
};
