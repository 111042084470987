import { IconButton } from '@mui/material';
import { useGetStudentMembership, useMergeStudentsMutation } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { CrossIcon, Loading, ModalHeaderStyled, ModalLarge } from '@schooly/style';
import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import { useProfile } from '../../../context/profile/useProfile';
import { useRouter } from '../../../context/router/useRouter';
import { CompareDuplicatesContent } from './CompareDuplicatesContent';

export const CompareDuplicatesModal: FC = () => {
  const { schoolId, permissions } = useAuth();
  const { user, canEditProfile, userType, showLoader } = useProfile();
  const { duplicateId = '' } = useParams();
  const { goBack } = useRouter();

  const mergeStudents = useMergeStudentsMutation();
  const { isLoading: isMerging } = mergeStudents;

  const [isMarkingNotDuplicates, setIsMarkingNotDuplicates] = useState(false);

  const areDuplicates = user?.duplicates?.some(({ relation_id }) => relation_id === duplicateId);

  const { data: duplicateUser, isLoading } = useGetStudentMembership(
    { id: duplicateId, schoolId: schoolId || '' },
    { enabled: !!schoolId && !!duplicateId },
  );

  const { relationToBeDeleted, relationToBePreserved } = useMemo(() => {
    let relationToBeDeleted;
    let relationToBePreserved;

    if (!canEditProfile && duplicateUser?.can_be_edited) {
      relationToBeDeleted = duplicateUser;
      relationToBePreserved = user;
    } else {
      relationToBeDeleted = user;
      relationToBePreserved = duplicateUser;
    }

    return { relationToBeDeleted, relationToBePreserved };
  }, [canEditProfile, duplicateUser, user]);

  const canManageDuplicates = useMemo(() => {
    switch (userType) {
      case 'student':
        return (
          permissions.includes('student_manager') || permissions.includes('registration_manager')
        );
      default:
        return false;
    }
  }, [permissions, userType]);

  const renderContent = useCallback(() => {
    if (isLoading || showLoader) {
      return <Loading />;
    }

    if (!canManageDuplicates || !areDuplicates || !relationToBeDeleted || !relationToBePreserved) {
      return (
        <>
          <ModalHeaderStyled withBorderBottom={false} active sx={{ justifyContent: 'flex-end' }}>
            <IconButton inverse onClick={goBack}>
              <CrossIcon />
            </IconButton>
          </ModalHeaderStyled>
          <AccessDenied />
        </>
      );
    }

    return (
      <CompareDuplicatesContent
        relationToBeDeleted={relationToBeDeleted}
        relationToBePreserved={relationToBePreserved}
        isMarkingNotDuplicates={isMarkingNotDuplicates}
        setIsMarkingNotDuplicates={setIsMarkingNotDuplicates}
      />
    );
  }, [
    areDuplicates,
    canManageDuplicates,
    goBack,
    isLoading,
    isMarkingNotDuplicates,
    relationToBeDeleted,
    relationToBePreserved,
    showLoader,
  ]);

  return (
    <ModalLarge open onClose={!isMarkingNotDuplicates && !isMerging ? goBack : undefined}>
      {renderContent()}
    </ModalLarge>
  );
};
