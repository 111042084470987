import { useGetConsentFormQuery } from '@schooly/api';
import { Loading, ModalSmall } from '@schooly/style';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import AccessDenied from '../../components/common/AccessDenied';
import { ConsentFormEditContent } from './ConsentFormEditContent';
import { useConsentFormBackground } from './hooks';

export const ConsentFormEditModal: FC = () => {
  const { id } = useParams<'id'>();
  const { data, isLoading, error } = useGetConsentFormQuery(id ?? '', { enabled: !!id });

  useConsentFormBackground(data?.reference);

  if (error) {
    return (
      <ModalSmall open>
        <AccessDenied />
      </ModalSmall>
    );
  }
  return (
    <ModalSmall open>
      {isLoading || !data ? <Loading /> : <ConsentFormEditContent consentForm={data} />}
    </ModalSmall>
  );
};
