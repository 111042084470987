import { Stack, Typography } from '@mui/material';
import { AssessmentGroup, Group } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { EditIcon, PlusIcon } from '@schooly/style';
import React, { FC, useCallback, useRef } from 'react';
import { get, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

import { renderError } from '../../../../components/ui/Input/helpers';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import { ModalMain, ModalPanel } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalRightSidebar } from '../../../../components/uikit-components/Modal/ModalRightSidebar';
import { AssessmentForm } from '../AssessmentCreateContent';
import { AssessmentsCreateModalMode, useAssessmentCreateModal } from '../useAssessmentCreateModal';
import { AssessmentsCreateModalGroupsMain } from './AssessmentsCreateModalGroupsMain';
import { AssessmentsCreateModalGroupsRightSidebar } from './AssessmentsCreateModalGroupsRightSidebar';

export const AssessmentsCreateModalGroups: FC = () => {
  const panelRef = useRef<HTMLDivElement>(null);
  const { schoolId } = useAuth();

  const { mode, actions, relationIds } = useAssessmentCreateModal();
  const { watch, setValue, formState } = useFormContext<AssessmentForm>();
  const groupError = get(formState.errors, 'groups');
  const selectedGroups = watch('groups');
  const date = watch('assessment_date');
  const recurringEndDate = watch('recurringEndDate');

  const isActive = mode === AssessmentsCreateModalMode.Groups;

  const handleClick = useCallback(() => {
    actions.setMode(AssessmentsCreateModalMode.Groups);
  }, [actions]);

  const handleCardClick = useCallback(
    (group: Group | AssessmentGroup) => {
      if (selectedGroups?.includes(group)) {
        const currentGroups = selectedGroups.filter((g) => g.id !== group.id) ?? [];
        setValue('groups', currentGroups);
      } else {
        setValue('groups', [...selectedGroups, group]);
      }
    },
    [selectedGroups, setValue],
  );

  const handleSetGroups = useCallback(
    (groups: Group[]) => {
      setValue('groups', groups);
    },
    [setValue],
  );
  return (
    <>
      <ModalPanel ref={panelRef} active={isActive} withBorderBottom={!isActive}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="assessments-Groups" />
          </Typography>
          {groupError && (
            <Typography variant="body2" color="error">
              {renderError(groupError)}
            </Typography>
          )}
          {!isActive &&
            (selectedGroups?.length ? (
              <SimpleButton startIcon={<EditIcon />} onClick={handleClick}>
                <FormattedMessage id="asssessments-EditGroups" />
              </SimpleButton>
            ) : (
              <SimpleButton startIcon={<PlusIcon />} onClick={handleClick}>
                <FormattedMessage id="asssessments-AddGroups" />
              </SimpleButton>
            ))}
        </Stack>
      </ModalPanel>
      {isActive && (
        <ModalRightSidebar open={isActive} anchorEl={panelRef.current}>
          {schoolId && (
            <AssessmentsCreateModalGroupsRightSidebar
              onCardClick={handleCardClick}
              onSetGroups={handleSetGroups}
              selectedGroups={selectedGroups}
              schoolId={schoolId}
              relationIds={relationIds}
              startDate={date ?? undefined}
              endDate={recurringEndDate}
            />
          )}
        </ModalRightSidebar>
      )}
      <ModalMain>
        {isActive && (
          <AssessmentsCreateModalGroupsMain
            onCardClick={handleCardClick}
            selectedGroups={selectedGroups}
          />
        )}
      </ModalMain>
    </>
  );
};
