import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupAttendanceStatisticsMode } from '../../../context/groups/GroupAttendanceStatisticsContext';
import { useGroupAttendanceStatistics } from '../../../context/groups/useGroupAttendanceStatistics';

export const GroupAttendanceStatisticsModalToggleMode: FC = () => {
  const { $t } = useIntl();
  const { mode, actions } = useGroupAttendanceStatistics();

  const handleChange = useCallback<Exclude<SwitchBaseProps['onChange'], undefined>>(
    (event, checked) => {
      actions.setMode(
        checked ? GroupAttendanceStatisticsMode.REGISTERS : GroupAttendanceStatisticsMode.STUDENTS,
      );
    },
    [actions],
  );

  const handleToggle = useCallback(() => {
    actions.setMode(
      mode === GroupAttendanceStatisticsMode.REGISTERS
        ? GroupAttendanceStatisticsMode.STUDENTS
        : GroupAttendanceStatisticsMode.REGISTERS,
    );
  }, [actions, mode]);

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography
        color={mode === GroupAttendanceStatisticsMode.REGISTERS ? 'text.secondary' : 'text.primary'}
        sx={{ cursor: 'pointer' }}
        onClick={handleToggle}
      >
        <FormattedMessage id="attendance-ByStudent" />
      </Typography>

      <FormControlLabel
        control={
          <Switch
            checked={mode === GroupAttendanceStatisticsMode.REGISTERS}
            onChange={handleChange}
          />
        }
        label={<Typography>{$t({ id: 'attendance-ByRegister' })}</Typography>}
        sx={{
          m: 0,
          color:
            mode === GroupAttendanceStatisticsMode.REGISTERS ? 'text.primary' : 'text.secondary',
        }}
      />
    </Stack>
  );
};
