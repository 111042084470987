import { PhoneNumberCountry } from '@schooly/api';
import { DeleteIcon } from '@schooly/style';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import Button from '../../Button';
import FormPhoneNumberInput from './FormPhoneNumberInput';

interface FormPhoneNumberProps {
  name: string;
  maxNumbers: number;
}

interface IPhoneValue {
  country: PhoneNumberCountry | undefined;
  phoneNumber: string;
}

interface RemoveItemData {
  removeIndex: number;
  count: number;
}

const isRemoveButtonDisabled = ({ country, phoneNumber = '' }: Partial<IPhoneValue>) =>
  !(phoneNumber && country);

const FormPhoneNumbers = ({ name, maxNumbers }: FormPhoneNumberProps) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray<IPhoneValue>({ name, control });
  const telephones = useWatch<IPhoneValue[]>({ control, name });

  useEffect(() => {
    const updateTelephones = () => {
      if (!telephones) {
        return;
      }

      const { removeIndex, count } = telephones.reduce<RemoveItemData>(
        (acc, tel, index) => {
          if (!tel.country && !tel.phoneNumber) {
            acc.removeIndex = index;
            acc.count += 1;
          }

          return acc;
        },
        { removeIndex: -1, count: 0 },
      );

      if (count > 1 && removeIndex !== -1) {
        remove(removeIndex);
      }
    };

    updateTelephones();
  }, [telephones, remove]);

  useEffect(() => {
    const updateTelephones = () => {
      if (!telephones) {
        return;
      }

      if (!telephones.length) {
        append({
          country: undefined,
          phoneNumber: '',
        });
      }

      if (fields.length >= maxNumbers || telephones.length >= maxNumbers) {
        return;
      }

      let canAddNewField = false;

      if (fields.length !== telephones.length) {
        canAddNewField = fields.every(({ country, phoneNumber }) => country && phoneNumber);
      } else {
        canAddNewField = telephones.every(({ country, phoneNumber }) => country && phoneNumber);
      }

      if (canAddNewField) {
        append(
          {
            country: undefined,
            phoneNumber: '',
          },
          false,
        );
      }
    };

    updateTelephones();
  }, [maxNumbers, telephones, fields, append]);

  return (
    <>
      {fields.map(({ id, country, phoneNumber }, index) => (
        <FormPhoneNumberInput
          id={id!}
          key={id}
          name={`${name}[${index}]`}
          labelTextId={index === 0 ? 'peopleDetail-PhoneNumber' : 'profile-AdditionalPhoneNumber'}
          button={
            index !== 0 && (
              <Button
                type="button"
                color="secondary-action"
                icon={<DeleteIcon />}
                className="remove-button"
                titleTextId="profile-RemovePhoneNumber"
                shouldBlurOnClick
                isCustomShape
                isDisabled={isRemoveButtonDisabled(
                  fields.length !== telephones?.length
                    ? { phoneNumber, country }
                    : telephones[index],
                )}
                onClick={() => remove(index)}
              />
            )
          }
        />
      ))}
    </>
  );
};

export default FormPhoneNumbers;
