import { ChipProps } from '@mui/material';
import { AssessmentMethodType } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

export type AssessmentMethodTypeTagSelectProps = {
  methodType: AssessmentMethodType;
} & Omit<ChipProps, 'label'>;

export const AssessmentMethodTypeTagSelect: FC<AssessmentMethodTypeTagSelectProps> = ({
  methodType,
  ...props
}) => {
  const { $t } = useIntl();

  return (
    <TagSelect
      label={$t({
        id: `assessments-Type-${methodType}`,
      })}
      {...props}
    />
  );
};
