import { Box, Paper, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSchool } from '../../../../../hooks/useSchool';
import { SchoolTuneStatusModalMode, SchoolTuneStatusType } from './scheme';

export interface SchoolTuneStatusesDisplayCategoryProps {
  type: SchoolTuneStatusModalMode;
  category: keyof SchoolTuneStatusType;
  title: React.ReactNode;
}

export const SchoolTuneStatusesDisplayCategory: FC<SchoolTuneStatusesDisplayCategoryProps> = ({
  type,
  category,
  title,
}) => {
  const { studentStatuses, staffStatuses } = useSchool();

  const statuses = (type === SchoolTuneStatusModalMode.STAFF ? staffStatuses : studentStatuses)[
    category
  ];

  return (
    <Stack gap={1} height="100%">
      <Typography variant="h4">{title}</Typography>
      <Paper elevation={0} sx={{ flex: '1 1 100%', bgcolor: 'background.paper' }}>
        {statuses.length ? (
          <Stack>
            {statuses.map((status) => (
              <Box
                key={status.id}
                p={1}
                sx={{ borderBottom: (theme) => theme.mixins.borderValue() }}
              >
                <Typography variant="h3">{status.name}</Typography>
              </Box>
            ))}
          </Stack>
        ) : (
          <Box py={1} px={2.5}>
            <Typography variant="h3" color="text.secondary">
              <FormattedMessage id="school-tabs-Statuses-NoStatus" />
            </Typography>
          </Box>
        )}
      </Paper>
    </Stack>
  );
};
