import { Box, Icon, Stack, StackProps, SxProps, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { DATE_FORMAT } from '@schooly/constants';
import { ArrowRightIcon, CalendarIcon, ChevronUpIcon } from '@schooly/style';
import { format, isSameDay } from 'date-fns';
import { FC, forwardRef, PropsWithChildren, useImperativeHandle, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

type RangePickerHeaderProps = {
  startDate: Date | null;
  endDate: Date | null;
  onClose?: () => void;
  open?: boolean;
  fromDateColor?: string;
  toDateColor?: string;
  onClick?: () => void;
  layoutStyleProps?: SxProps<Theme>;
  requiredLabel?: string;
} & Omit<StackProps, 'children'> &
  PropsWithChildren;

export interface RangePickerHeaderRef {
  onHoverDateRange: (v?: Date[]) => void;
}

export const RangePickerHeader = forwardRef<RangePickerHeaderRef, RangePickerHeaderProps>(
  (
    {
      endDate,
      startDate,
      onClose,
      open,
      toDateColor,
      fromDateColor,
      onClick,
      layoutStyleProps,
      requiredLabel,
      children,
    },
    ref,
  ) => {
    const { $t } = useIntl();
    const [hoveredDateRange, setHoveredDateRange] = useState<Date[]>();

    const [hoveredStartDate, hoveredEndDate] = hoveredDateRange ?? [];

    useImperativeHandle(
      ref,
      () => ({
        onHoverDateRange: setHoveredDateRange,
      }),
      [],
    );

    const isStartDateNotSelected = useMemo(
      () => open && !startDate && !endDate,
      [open, startDate, endDate],
    );
    const isEndDateNotSelected = useMemo(
      () => Boolean(open && startDate && !endDate),
      [open, startDate, endDate],
    );

    const isSingleDate = startDate && endDate && isSameDay(startDate, endDate);

    const endDateColor = useMemo(() => {
      if (toDateColor) return toDateColor;
      return endDate && !hoveredStartDate ? 'primary.main' : 'common.grey';
    }, [endDate, hoveredStartDate, toDateColor]);

    const startDateColor = useMemo(() => {
      if (fromDateColor) return fromDateColor;
      return startDate && !hoveredEndDate ? 'primary.main' : 'common.grey';
    }, [fromDateColor, startDate, hoveredEndDate]);

    const required = requiredLabel && !startDate && !endDate && !open && (
      <Typography
        color="common.grey"
        className="required-label"
        variant="h3"
        sx={(theme) => ({
          pointerEvents: 'none',
          fontStyle: 'italic',
          position: 'absolute',
          right: parseInt(theme.spacing(5)),
          top: parseInt(theme.spacing(1.25)),
        })}
      >
        {$t({ id: requiredLabel })}
      </Typography>
    );

    return (
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          gap: 1,
          padding: theme.spacing(1.25, 5, 1, 1.5),
          flex: 1,
          alignItems: 'center',
          borderBottom: `1px solid ${theme.palette.common.light3}`,
          ...((typeof layoutStyleProps === 'function'
            ? layoutStyleProps(theme)
            : layoutStyleProps) as SystemStyleObject),
        })}
        onClick={onClick}
      >
        <Icon sx={{ color: 'common.grey' }}>
          <CalendarIcon />
        </Icon>
        <Date
          isSelected={isStartDateNotSelected}
          date={startDate}
          dateColor={startDateColor}
          dateLabel={$t({ id: 'school-tabs-SchoolYears-StartDate' })}
        />
        {!isSingleDate && (
          <>
            <Icon sx={{ color: 'common.grey' }}>
              <ArrowRightIcon />
            </Icon>
            <Date
              isSelected={isEndDateNotSelected}
              date={endDate}
              dateColor={endDateColor}
              dateLabel={$t({ id: 'school-tabs-SchoolYears-EndDate' })}
            />
          </>
        )}
        {required}

        {children}
        {onClose && (
          <Icon
            className="dropdown-icon"
            sx={(theme) => ({
              fontSize: theme.spacing(2),
              cursor: 'pointer',
              position: 'absolute',
              right: theme.spacing(1.5),
              top: theme.spacing(1.25),
              color: theme.palette.common.grey,
              '&:hover': {
                color: theme.palette.text.primary,
              },
              transform: !open ? 'rotate(180deg)' : undefined,
            })}
            onClick={onClose}
          >
            <ChevronUpIcon />
          </Icon>
        )}
      </Stack>
    );
  },
);

interface DateProps {
  date: Date | null;
  dateColor?: string;
  isSelected?: boolean;
  dateLabel: string;
}

export const Date: FC<DateProps> = ({ date, isSelected, dateColor, dateLabel }) => {
  const currentDateColor = useMemo(() => {
    if (dateColor) return dateColor;
    return date ? 'primary.main' : 'common.grey';
  }, [dateColor, date]);

  return (
    <Stack>
      <Typography variant="h3" color={currentDateColor} sx={{ width: 100 }}>
        {date ? format(date, DATE_FORMAT) : dateLabel}
      </Typography>
      {isSelected && (
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: 40,
            height: `1px`,
            width: 100,
            backgroundColor: theme.palette.primary.main,
          })}
        />
      )}
    </Stack>
  );
};
