import { IconButton, Typography } from '@mui/material';
import { AttendanceCode } from '@schooly/api';
import { ATTENDANCE_CODE_SCHOOl_PRESENCE_OPTIONS } from '@schooly/constants';
import { CrossIcon, DragIcon } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../../components/uikit/Dnd/dnd.styled';
import { LongTitleVerticalTooltip } from '../../../../components/uikit/LongTitleVerticalTooltip/LongTitleVerticalTooltip';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { LongStringWithTooltip } from '../../../../components/uikit-components/LongStringWithTooltip/LongStringWithTooltip';
import {
  ModalContent,
  ModalLarge,
  ModalMain,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { useAttendanceCodes } from '../../../../hooks/useAttendanceCodes';
import { normalizeInSchoolValue } from './AttendanceCodeRow';

export interface AttendanceCodeOrderForm {
  attendanceCodes: AttendanceCode[];
}

export const AttendanceCodeModal: FC = () => {
  const { attendanceCodes, changeAttendanceCodeOrder, isLoading, onClose } = useAttendanceCodes();

  const { $t } = useIntl();

  const form = useForm<AttendanceCodeOrderForm>({
    defaultValues: { attendanceCodes },
  });

  const { fields, move } = useFieldArray({
    control: form.control,
    name: 'attendanceCodes',
  });

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const onChangeOrder = useCallback<SubmitHandler<AttendanceCodeOrderForm>>(
    async ({ attendanceCodes }) => {
      const codes = attendanceCodes.map((c, i) => ({ id: c.id, order: i + 1 }));

      await changeAttendanceCodeOrder(codes);
    },
    [changeAttendanceCodeOrder],
  );

  return (
    <ModalLarge open>
      <ModalHeader title={$t({ id: 'attendance-Codes' })} active>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalMain>
        <ModalContent active>
          <GridContainer sx={{ height: '100%' }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="AttendanceCodesDroppable">
                {(provided) => (
                  <DndListWrapper {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((code, i) => (
                      <Draggable key={code.id} draggableId={code.id} index={i}>
                        {(provided) => {
                          const schoolPresentLabel = ATTENDANCE_CODE_SCHOOl_PRESENCE_OPTIONS.find(
                            ({ value }) => normalizeInSchoolValue(code.in_school) === value,
                          )?.labelTextId;

                          return (
                            <GridRowStyled
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={provided.draggableProps.style}
                              noBorderRadius
                              key={code.id}
                              sx={{ height: 44 }}
                            >
                              <GridRowItem noVerticalPadding sx={{ pt: 1, overflow: 'hidden' }}>
                                {fields.length > 1 && (
                                  <IconButton {...provided.dragHandleProps} inverse>
                                    <DragIcon />
                                  </IconButton>
                                )}
                                <GridRowName sx={{ flex: '1 1 30%' }}>
                                  <LongStringWithTooltip>{code.name}</LongStringWithTooltip>
                                </GridRowName>
                                <GridRowName sx={{ flex: '1 1 20%' }} variant="body1">
                                  {schoolPresentLabel && (
                                    <FormattedMessage id={schoolPresentLabel} />
                                  )}
                                </GridRowName>
                                <GridRowName sx={{ flex: '1 1 10%' }} variant="body1">
                                  {code.code}
                                </GridRowName>
                                <GridRowName sx={{ flex: '1 1 40%' }} variant="body1">
                                  <LongTitleVerticalTooltip
                                    renderTooltipContent={(longName) => (
                                      <Typography variant="body1" color="text.primary">
                                        {longName}
                                      </Typography>
                                    )}
                                  >
                                    {code.description}
                                  </LongTitleVerticalTooltip>
                                </GridRowName>
                              </GridRowItem>
                            </GridRowStyled>
                          );
                        }}
                      </Draggable>
                    ))}
                  </DndListWrapper>
                )}
              </Droppable>
            </DragDropContext>
          </GridContainer>
        </ModalContent>
      </ModalMain>

      <ModalFooterWithActions
        saving={isLoading}
        onSaveClick={form.handleSubmit(onChangeOrder)}
        active
      />
    </ModalLarge>
  );
};
