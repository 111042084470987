import { Icon, Stack, Switch, Typography } from '@mui/material';
import { AttendanceCode, NotificationAttendanceSettings } from '@schooly/api';
import { AttendanceNotificationSelect, DropdownSelect } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { CheckIcon, Spin } from '@schooly/style';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

export const DELAY_BEFORE_CHANGE_STATUS = 3000;

interface SchoolNotificationsAttendanceContentProps {
  onSubmit: (enabled: boolean) => Promise<NotificationAttendanceSettings | undefined>;
  onSwitch: (enabled: boolean) => Promise<NotificationAttendanceSettings | undefined>;
  attendanceCodes: Array<AttendanceCode>;
  notificationsEnabled: boolean;
  onSelectCode: (val: AttendanceCode) => void;
  updating?: boolean;
  setChangesCompleted: (prop: boolean) => void;
  changesCompleted?: boolean;
  selectedCodes: Array<AttendanceCode>;
}

export const SchoolNotificationsAttendanceContent: FC<SchoolNotificationsAttendanceContentProps> =
  ({
    onSubmit,
    onSwitch,
    attendanceCodes,
    notificationsEnabled,
    onSelectCode,
    selectedCodes,
    updating,
    setChangesCompleted,
    changesCompleted,
  }) => {
    const navigate = useNavigate();
    const [attendanceNotificationsEnabled, setAttendanceNotificationsEnabled] =
      useState(notificationsEnabled);

    const [emptyPropsWarning, showEmptyPropsWarning, hideEmptyPropsWarning] = useFlag();
    const dropdown = useRef<DropdownSelect | null>(null);
    const { $t } = useIntl();

    const statusIcon = useMemo(() => {
      if (updating) {
        return <Spin />;
      }

      return changesCompleted ? <CheckIcon /> : null;
    }, [changesCompleted, updating]);

    const onChangesCompleted = useCallback(() => {
      setChangesCompleted(true);

      setTimeout(() => {
        setChangesCompleted(false);
      }, DELAY_BEFORE_CHANGE_STATUS);
    }, [setChangesCompleted]);

    const onInputClose = useCallback(async () => {
      if (!selectedCodes.length) showEmptyPropsWarning();
      else if (emptyPropsWarning) hideEmptyPropsWarning();

      const response = await onSubmit(!!attendanceNotificationsEnabled);

      if (response) {
        onChangesCompleted();
      }
    }, [
      attendanceNotificationsEnabled,
      emptyPropsWarning,
      hideEmptyPropsWarning,
      onChangesCompleted,
      onSubmit,
      selectedCodes.length,
      showEmptyPropsWarning,
    ]);

    const onToggleSwitch = useCallback(
      async (enabled: boolean) => {
        hideEmptyPropsWarning();
        setAttendanceNotificationsEnabled(enabled);

        const response = await onSwitch(enabled);
        if (!response) {
          setAttendanceNotificationsEnabled(notificationsEnabled);
        }
        dropdown.current?.open();
      },
      [hideEmptyPropsWarning, notificationsEnabled, onSwitch],
    );

    return (
      <Stack
        width="100%"
        alignItems="center"
        justifyContent="center"
        gap={1.5}
        sx={{ pb: attendanceNotificationsEnabled ? 1 : 0 }}
      >
        <Stack direction="row" alignItems="center" gap={1} minHeight={28}>
          <Switch
            checked={attendanceNotificationsEnabled}
            onChange={(e) => {
              onToggleSwitch(e.target.checked);
            }}
            sx={{
              ' .MuiSwitch-thumb': {
                backgroundColor: attendanceNotificationsEnabled ? 'primary.main' : 'text.secondary',
              },
            }}
          />
          <Typography
            variant="h3"
            color={!attendanceNotificationsEnabled ? 'text.secondary' : undefined}
          >
            <FormattedMessage id="school-sections-Notifications-EnableNotifications" />
          </Typography>
        </Stack>
        {attendanceNotificationsEnabled && (
          <Stack ml={3}>
            <Stack direction="row" gap={1}>
              <AttendanceNotificationSelect
                error={
                  emptyPropsWarning
                    ? { type: 'validate', message: $t({ id: 'input-ErrorRequired' }) }
                    : undefined
                }
                borderOnHover
                getLabel={(s) => s.name}
                ref={dropdown}
                onSelect={onSelectCode}
                selected={selectedCodes}
                options={attendanceCodes}
                onEmptyLayoutClick={() => navigate('/settings/attendance')}
                placeholder={
                  selectedCodes.length ? '' : $t({ id: 'school-sections-Notifications-SetCodes' })
                }
                onClose={onInputClose}
              />

              <Icon color="primary" sx={{ marginTop: 1 }}>
                {statusIcon}
              </Icon>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  };
