import { CreateChildContent, CreateChildForm } from '@schooly/components/applications';
import { Loading, ModalSmall } from '@schooly/style';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useApplication } from '../../../context/applications/useApplication';
import { useRouter } from '../../../context/router/useRouter';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { useApplicationForm } from '../../../pages/Applications/useApplicationForm';

export const UpdateChildModal = () => {
  const [fetching, setFetching] = useState(false);
  const { childId } = useParams<'childId'>();
  const { children, addChild, removeChild: removeChildAction } = useApplicationForm('update');
  const { applicationId } = useApplication();

  const navigate = useNavigate();
  const [currentChild, setCurrentChild] = useState<CreateChildForm>();
  const { goBack } = useRouter();

  const { applicationChildCustomFields, fetching: loadingCustomFields } = useCustomFields({
    refetchOnMount: 'always',
  });

  const onSubmit = useCallback(
    (data: CreateChildForm) => {
      addChild(data);
      navigate(`/applications/${applicationId}/update`);
    },
    [addChild, navigate, applicationId],
  );

  const onRemoveChild = useCallback(
    (childId: string) => {
      removeChildAction(childId);
      navigate(`/applications/${applicationId}/update`);
    },
    [applicationId, navigate, removeChildAction],
  );

  const init = useCallback(async () => {
    if (childId) {
      const child = children?.find((c) => c.id === childId);
      setCurrentChild(child);
    }

    setFetching(false);
  }, [children, childId]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <ModalSmall open>
      {fetching || loadingCustomFields ? (
        <Loading />
      ) : (
        <CreateChildContent
          onClose={goBack}
          onSubmit={onSubmit}
          formCustomFields={currentChild?.custom_fields ?? applicationChildCustomFields}
          child={currentChild}
          onRemoveChild={onRemoveChild}
        />
      )}
    </ModalSmall>
  );
};
