import { ControlRadioGroup, ControlRadioGroupProps } from '@schooly/components/form-radio-group';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export interface ControlBooleanProps<T = boolean>
  extends Omit<ControlRadioGroupProps<T>, 'options'> {
  trueLabel?: string;
  trueValue?: T;
  falseLabel?: string;
  falseValue?: T;
}

export const ControlBoolean = <T extends any = boolean>({
  trueLabel,
  trueValue = true as T,
  falseLabel,
  falseValue = false as T,
  rules,
  ...props
}: ControlBooleanProps<T>) => {
  const { $t } = useIntl();

  const options = useMemo(
    () => [
      {
        value: trueValue,
        label: trueLabel ?? $t({ id: 'yes' }),
      },
      {
        value: falseValue,
        label: falseLabel ?? $t({ id: 'no' }),
      },
    ],
    [$t, falseLabel, falseValue, trueLabel, trueValue],
  );

  return (
    <ControlRadioGroup
      options={options}
      rules={{
        ...rules,
        required: false,
        validate: (value?: boolean) => {
          if (rules?.required && value !== trueValue && value !== falseValue) {
            return $t({ id: 'input-ErrorRequired' });
          }
        },
      }}
      {...props}
    />
  );
};
