import { Components, Icon, Theme } from '@mui/material';
import { ArrowDownIcon } from '@schooly/style';

export const MuiTableSortLabel: Components<Theme>['MuiTableSortLabel'] = {
  defaultProps: {
    IconComponent: (props) => (
      <Icon {...props}>
        <ArrowDownIcon />
      </Icon>
    ),
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiIcon-root': {
        fontSize: theme.spacing(2.5),
      },

      '&.Mui-active .MuiTableSortLabel-icon': {
        color: theme.palette.text.primary,
      },
    }),
  },
};
