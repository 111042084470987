import { Box, Button } from '@mui/material';
import { DeleteIcon, ModalFooter, Spin } from '@schooly/style';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { AssessmentForm } from './AssessmentCreateContent';
import { useAssessmentCreateModal } from './useAssessmentCreateModal';
import { useWithAssessmentFormValidation } from './useAssessmentFormValidation';

type AssessmentCreateFooterProps = {
  withPreview: boolean;
};

export const AssessmentCreateFooter: FC<AssessmentCreateFooterProps> = ({ withPreview }) => {
  const { $t } = useIntl();
  const { groupsChecking } = useWithAssessmentFormValidation();
  const { actions, assessmentId } = useAssessmentCreateModal();
  const { watch, formState } = useFormContext<AssessmentForm>();

  const initName = formState.defaultValues?.name;
  const initFollowingCount = formState.defaultValues?.recurring_state?.following_count ?? 0;

  const recurringState = watch('recurring_state');
  const followingCount = recurringState?.following_count ?? 0;

  const addingFollowingAssessments = Boolean(!initFollowingCount && followingCount);
  const totalAssessments = followingCount + 1;

  return (
    <ModalFooter>
      {assessmentId && (
        <Box sx={{ flex: '1 1 auto' }}>
          <Button
            variant="outlined"
            startIcon={actions.deleting ? <Spin /> : <DeleteIcon />}
            disabled={actions.saving || actions.deleting || groupsChecking}
            onClick={() =>
              actions.deleteAssessment({
                assessmentId,
                confirmed: false,
                name: initName,
                recurringState,
              })
            }
          >
            <FormattedMessage id="action-Delete" />
          </Button>
        </Box>
      )}

      {!recurringState ? (
        <>
          <Button
            type="submit"
            variant="outlined"
            startIcon={actions.saving && !withPreview && <Spin />}
            disabled={actions.saving || actions.deleting || groupsChecking}
          >
            <FormattedMessage id="action-SaveAndClose" />
          </Button>
          <Button
            type="submit"
            data-preview
            startIcon={actions.saving && withPreview && <Spin />}
            disabled={actions.saving || actions.deleting || groupsChecking}
          >
            <FormattedMessage id="action-SaveAndPreview" />
          </Button>
        </>
      ) : (
        <Button
          type="submit"
          startIcon={actions.saving && !withPreview && <Spin />}
          disabled={actions.saving || actions.deleting || groupsChecking}
        >
          <FormattedMessage
            id={
              addingFollowingAssessments
                ? $t(
                    {
                      id: 'assessments-recurring-SaveAssessmentsAction',
                    },
                    {
                      assessmentsCount: totalAssessments,
                    },
                  )
                : 'action-Save'
            }
          />
        </Button>
      )}
    </ModalFooter>
  );
};
