import { Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback } from 'react';

import { ColumnRecordSlot } from '../../scheme';
import { CalendarRecordCellContent, CalendarRecordCellSlotStyled } from '../CalendarWeek.styled';

export interface CalendarRecordCellSlotProps extends ColumnRecordSlot {
  hasActive?: boolean;
}

export const CalendarRecordCellSlot: FC<CalendarRecordCellSlotProps> = ({
  start,
  end,
  isPast,
  size,
  record,
  hasActive,
}) => {
  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      record.onClick?.(event, record);
    },
    [record],
  );

  const renderContent = () => (
    <CalendarRecordCellSlotStyled
      size={size}
      isPast={isPast}
      onClick={record.onClick && handleClick}
    >
      {!hasActive && (
        <CalendarRecordCellContent>
          <Typography variant="caption">
            {format(start, 'HH:mm')} - {format(end, 'HH:mm')}
          </Typography>
        </CalendarRecordCellContent>
      )}
    </CalendarRecordCellSlotStyled>
  );

  return record.tooltip ? (
    <Tooltip {...record.tooltip}>{renderContent()}</Tooltip>
  ) : (
    renderContent()
  );
};
