import { useGetConsentFormQuery } from '@schooly/api';
import { Loading, ModalLarge } from '@schooly/style';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import AccessDenied from '../../components/common/AccessDenied';
import { ConsentFormPreviewContent } from './ConsentFormPreviewContent';
import { useConsentFormBackground } from './hooks';

export const ConsentFormPreviewModal: FC = () => {
  const { id } = useParams<'id'>();
  const { data, isFetching, error } = useGetConsentFormQuery(id ?? '', {
    enabled: !!id,
    refetchOnMount: 'always',
  });

  useConsentFormBackground(data?.reference);

  if (error) {
    return (
      <ModalLarge open>
        <AccessDenied />
      </ModalLarge>
    );
  }

  return (
    <ModalLarge open>
      {isFetching || !data ? <Loading /> : <ConsentFormPreviewContent consentForm={data} />}
    </ModalLarge>
  );
};
