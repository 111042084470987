import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { PaymentFrequency, ProductForm, ProductFormType } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import {
  CrossIcon,
  DeleteIcon,
  Grid,
  GridBody,
  GridCell,
  GridHead,
  GridRow,
  LockIcon,
  PlusIcon,
  PRICE_SUBTEXT_CLASS_NAME,
  SimpleButton,
} from '@schooly/style';
import React, { FC, Fragment, PropsWithChildren, useCallback } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { useTableHover } from '../../../../context/table/tableHover/useTableHover';
import {
  HOVER_CLASS_NAME,
  SELF_HOVER_CLASS_NAME,
  WithTableHover,
} from '../../../../context/table/tableHover/WithTableHover';
import {
  FrequenciesTooltip,
  NotInUseFrequencyLabel,
  ProductBlockedEditingLabel,
} from '../SchoolProductCommon';
import { ProductPriceInput } from './ProductPriceInput';
import { IntersectionIds } from './SchoolProductCreateModalContent';
import {
  ApplicableToForm,
  FrequenciesPriceForm,
  HalfDayForm,
  VariantValidationError,
} from './SchoolProductCreateModalVariants';

export const HOVER_TEXT_CLASS_NAME = 'hoverText';
const HOVER_ICON_CLASS_NAME = 'hoverIcon';

export type PriceValue = number | undefined;

type CreateVariantsTableProps = {
  addedFrequencies: PaymentFrequency[];
  typeIdx: number;
  schoolId: string;
  intersectionIds: IntersectionIds;
  currencySymbol: string;
  canViewFrequency: boolean;
  onToggleFrequency: (f: PaymentFrequency) => void;
  availableFrequencies: PaymentFrequency[];
  onRemoveVariant: (vIdx: number) => void;
  canEdit: boolean;
  variants: ProductFormType['variants'];
  sx?: SxProps<Theme>;
};

export type BaseCellType = 'Half day' | 'Applicable to';

export type Cell =
  | {
      type: BaseCellType;
    }
  | PaymentFrequency;

export type EditableCell = {
  type: BaseCellType | 'Frequencies';
};

export const variantBaseCells: BaseCellType[] = ['Half day', 'Applicable to'];

export const CreateVariantsTable: FC<CreateVariantsTableProps> = ({
  canViewFrequency,
  onToggleFrequency,
  availableFrequencies,
  sx,
  ...props
}) => {
  const theme = useTheme();
  const headerCells: Cell[] = [
    ...variantBaseCells.map((c) => ({ type: c })),
    ...props.addedFrequencies,
  ];

  const hoverStyles = {
    [`.${HOVER_CLASS_NAME}`]: {
      backgroundColor: `${theme.palette.background.default} !important`,
      [`.${HOVER_TEXT_CLASS_NAME}, .${PRICE_SUBTEXT_CLASS_NAME}`]: {
        color: theme.palette.text.primary,
      },

      [`.${HOVER_ICON_CLASS_NAME}`]: {
        color: theme.palette.primary.main,
      },
    },
    [`.${SELF_HOVER_CLASS_NAME}`]: {
      backgroundColor: `${theme.palette.common.grey7} !important`,
      [`.${HOVER_TEXT_CLASS_NAME}, .${PRICE_SUBTEXT_CLASS_NAME}`]: {
        color: theme.palette.text.primary,
      },
    },
  };

  const renderAddFrequency = () => {
    if (!availableFrequencies.length) return null;
    if (props.canEdit) {
      return (
        <AddFrequency
          availableFrequencies={availableFrequencies}
          onToggleFrequency={onToggleFrequency}
        />
      );
    }

    return (
      <Stack>
        <Tooltip
          componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
          title={<ProductBlockedEditingLabel labelId="products-CannotAddRemoveFrequency" />}
        >
          <IconButton inverse>
            <LockIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <WithTableHover hoverStyles={hoverStyles}>
      <Grid
        sx={{
          '.MuiTableCell-root:not(:last-child)': {
            borderRight: (theme) => theme.mixins.borderValue(),
          },
          ...sx,
        }}
      >
        <VariantsTableHeader
          cells={headerCells}
          canRemoveFrequency={props.addedFrequencies.length > 1}
          canEdit={props.canEdit}
          canViewFrequency={canViewFrequency}
          onToggleFrequency={onToggleFrequency}
        >
          <GridCell noVerticalPadding width={44}>
            {renderAddFrequency()}
          </GridCell>
        </VariantsTableHeader>
        <EditVariantsTableBody {...props} />
      </Grid>
    </WithTableHover>
  );
};

type EditVariantsTableBodyProps = {
  variants: ProductFormType['variants'];
  addedFrequencies: PaymentFrequency[];
  intersectionIds: IntersectionIds;
  currencySymbol: string;
  schoolId: string;
  typeIdx: number;
  onRemoveVariant: (vIdx: number) => void;
  canEdit: boolean;
};

const EditVariantsTableBody: FC<EditVariantsTableBodyProps> = ({
  variants,
  onRemoveVariant,
  ...props
}) => {
  const { gridRef, styles, getRowHoverProps } = useTableHover();

  const cells: EditableCell[] = [
    ...variantBaseCells.map((c) => ({ type: c })),
    { type: 'Frequencies' },
  ];

  const renderDeleteButton = (variantIdx: number) => {
    if (variants.length < 2) return null;
    if (props.canEdit) {
      return (
        <Stack>
          <IconButton
            className={HOVER_ICON_CLASS_NAME}
            inverse
            onClick={() => onRemoveVariant(variantIdx)}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      );
    }

    return (
      <Stack>
        <Tooltip
          componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
          title={<ProductBlockedEditingLabel labelId="products-CannotAddRemoveVariant" />}
        >
          <IconButton inverse>
            <LockIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <GridBody ref={gridRef} sx={{ ...styles }}>
      {variants.map((v, variantIdx) => {
        return (
          <EditVariantRow key={v.id} variantCells={cells} variantIdx={variantIdx} {...props}>
            <GridCell
              noPadding
              {...getRowHoverProps({
                cellId: 'action',
                colIndex: cells.length + props.addedFrequencies.length,
                rowIndex: variantIdx,
                onlyXHover: true,
              })}
            >
              {renderDeleteButton(variantIdx)}
            </GridCell>
          </EditVariantRow>
        );
      })}
    </GridBody>
  );
};

type EditVariantRowProps = {
  variantCells: EditableCell[];
  typeIdx: number;
  variantIdx: number;
  schoolId: string;
  intersectionIds: IntersectionIds;
  addedFrequencies: PaymentFrequency[];
  currencySymbol: string;
  canEdit: boolean;
};

const EditVariantRow: FC<PropsWithChildren<EditVariantRowProps>> = ({
  variantCells,
  typeIdx,
  variantIdx,
  schoolId,
  intersectionIds,
  addedFrequencies,
  children,
  currencySymbol,
  canEdit,
}) => {
  const { getRowHoverProps } = useTableHover();
  const { showError } = useNotifications();

  const { watch, formState, trigger } = useFormContext<ProductForm>();
  const variantPath = `types.${typeIdx}.variants.${variantIdx}` as const;
  const variant = watch(variantPath);

  const initPrices =
    formState.defaultValues?.types?.[typeIdx]?.variants?.[variantIdx]?.prices ?? [];
  const onPriceChange = useCallback(
    (prevPrice: PriceValue, newPrice: PriceValue) => {
      if (canEdit) return;
      trigger(`${variantPath}.prices`);
      if (prevPrice && !newPrice) {
        showError({ message: 'products-CannotDeletePrice' });
      }
    },
    [canEdit, showError, trigger, variantPath],
  );

  const renderCell = (c: EditableCell, colIndex: number) => {
    const props = getRowHoverProps({
      cellId: c.type,
      colIndex,
      rowIndex: variantIdx,
      onlyXHover: c.type === 'Applicable to',
    });
    switch (c.type) {
      case 'Half day':
        const emptyCell = !canEdit && !variant.half_day;
        return (
          <Tooltip
            componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
            title={
              emptyCell ? (
                <ProductBlockedEditingLabel labelId="products-CannotEditHalfDay" />
              ) : undefined
            }
          >
            <GridCell noPadding borderRight empty={emptyCell} {...props}>
              {!emptyCell && (
                <HalfDayForm variantIdx={variantIdx} typeIdx={typeIdx} canEdit={canEdit} />
              )}
            </GridCell>
          </Tooltip>
        );
      case 'Applicable to':
        return (
          <GridCell borderRight noPadding sx={{ position: 'relative' }} {...props}>
            <ApplicableToForm
              disabled={!canEdit}
              schoolId={schoolId}
              typeIdx={typeIdx}
              variantIdx={variantIdx}
              intersectionIds={intersectionIds}
              sx={{
                border: 'none',
                '.header': {
                  minHeight: 44,
                },
              }}
              renderError={() => (
                <Box
                  sx={(theme) => ({
                    pointerEvents: 'none',
                    position: 'absolute',
                    minHeight: 43,
                    left: -1,
                    top: 0,
                    right: -1,
                    bottom: 0,
                    border: `1px solid ${theme.palette.error.main}`,
                  })}
                />
              )}
            />
          </GridCell>
        );

      case 'Frequencies':
        return (
          <FrequenciesPriceForm
            currencySymbol={currencySymbol}
            frequencies={addedFrequencies}
            typeIndex={typeIdx}
            variantIdx={variantIdx}
            canRemove={canEdit}
            onChange={onPriceChange}
          >
            {(field, errorMessage) =>
              addedFrequencies.map((f, i, arr) => {
                const initPrice = initPrices.find((p) => p?.frequency_id === f.id);
                const relatedPrice = field.value.find((p) => p.frequency_id === f.id);

                const emptyCell = !canEdit && !initPrice?.price;

                const freqColIdx = variantCells.length - 1 + i;
                const frequencyHoverProps = getRowHoverProps({
                  cellId: f.type,
                  colIndex: freqColIdx,
                  rowIndex: variantIdx,
                });

                const hasEmptyPricesError =
                  errorMessage === VariantValidationError.NoPricesProvided;
                const hasPriceRemovedError =
                  !emptyCell &&
                  !relatedPrice &&
                  errorMessage === VariantValidationError.PriceRemoved;

                return (
                  <Tooltip
                    componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
                    title={
                      emptyCell ? (
                        <ProductBlockedEditingLabel labelId="products-CannotAddRemoveFrequency" />
                      ) : undefined
                    }
                  >
                    <GridCell
                      empty={emptyCell}
                      key={f.id}
                      height={40}
                      borderRight={!hasEmptyPricesError}
                      {...frequencyHoverProps}
                      sx={(theme) => {
                        const errorBorder = `1px solid ${theme.palette.error.main} !important`;
                        return {
                          overflow: 'hidden',
                          ...(hasEmptyPricesError && {
                            '&.MuiTableCell-root': {
                              borderTop: errorBorder,
                              borderBottom: errorBorder,
                              ...(i === 0 && { borderLeft: errorBorder }),
                              ...(i === arr.length - 1 && { borderRight: errorBorder }),
                            },
                          }),
                          ...(hasPriceRemovedError && {
                            '&.MuiTableCell-root': {
                              border: errorBorder,
                            },
                          }),
                        };
                      }}
                      noPadding
                    >
                      {!emptyCell && (
                        <ProductPriceInput
                          currencySymbol={currencySymbol}
                          value={relatedPrice?.price}
                          onChange={(price) => {
                            field.onChange([
                              ...field.value.filter((p) => p.frequency_id !== f.id),
                              ...(price ? [{ frequency_id: f.id, price }] : []),
                            ]);
                            onPriceChange(relatedPrice?.price, price);
                          }}
                        />
                      )}
                    </GridCell>
                  </Tooltip>
                );
              })
            }
          </FrequenciesPriceForm>
        );

      default:
        const type: never = c.type;

        console.error(`Unexpected type ${type}`);
    }
  };

  return (
    <GridRow withoutDefaultHover>
      {variantCells.map((c, idx) => (
        <Fragment key={c.type}>{renderCell(c, idx)}</Fragment>
      ))}
      {children}
    </GridRow>
  );
};

type OntoggleFrequency = (f: PaymentFrequency) => void;

type VariantsTableHeaderProps = {
  cells: Cell[];
  canRemoveFrequency: boolean;
  canViewFrequency: boolean;
  onToggleFrequency?: OntoggleFrequency;
  canEdit?: boolean;
};

export const VariantsTableHeader: FC<PropsWithChildren<VariantsTableHeaderProps>> = ({
  cells,
  canRemoveFrequency,
  canViewFrequency,
  onToggleFrequency,
  canEdit,
  children,
}) => {
  const { $t } = useIntl();
  const { columnRef, getColumnHoverProps, styles } = useTableHover();

  const renderRemoveButton = (cell: PaymentFrequency) => {
    if (!canRemoveFrequency) return null;

    if (!cell.in_use || canEdit === false) {
      return (
        <FrequenciesTooltip
          title={
            !cell.in_use ? (
              <NotInUseFrequencyLabel frequency={cell} canView={canViewFrequency} />
            ) : (
              <ProductBlockedEditingLabel labelId="products-CannotAddRemoveFrequency" />
            )
          }
        >
          <IconButton inverse size="small">
            <LockIcon />
          </IconButton>
        </FrequenciesTooltip>
      );
    }

    return (
      <IconButton
        inverse
        size="small"
        className="removeIcon"
        sx={{
          visibility: 'hidden',
        }}
        onClick={() => onToggleFrequency?.(cell)}
      >
        <CrossIcon />
      </IconButton>
    );
  };

  const renderCell = (cell: Cell, idx: number) => {
    const props = getColumnHoverProps({
      cellId: cell.type,
      colIndex: idx,
      rowIndex: 0,
    });

    switch (cell.type) {
      case 'Half day':
        return (
          <GridCell width={60} {...props}>
            <Typography noWrap className={HOVER_TEXT_CLASS_NAME}>
              {$t({ id: 'students-HalfDay' })}
            </Typography>
          </GridCell>
        );

      case 'Applicable to':
        return (
          <GridCell {...props}>
            <Typography noWrap className={HOVER_TEXT_CLASS_NAME}>
              {$t({ id: 'products-TheProductIsApplicableTo' })}
            </Typography>
          </GridCell>
        );

      default:
        return (
          <GridCell
            sx={{
              ':hover .removeIcon': {
                visibility: 'visible',
                padding: 0,
              },
              width: 120,
            }}
            {...props}
          >
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography noWrap className={HOVER_TEXT_CLASS_NAME}>
                {$t({ id: `frequencies-${cell.type}` })}
              </Typography>
              <Stack>{renderRemoveButton(cell)}</Stack>
            </Stack>
          </GridCell>
        );
    }
  };

  return (
    <GridHead
      borderBottom
      sx={{
        '& .MuiTableCell-root': {
          height: '34px !important',
        },
        '&& .MuiTableCell-head': {
          py: 0,
        },
        ...styles,
      }}
    >
      <GridRow ref={columnRef} withoutDefaultHover>
        {cells.map((c, i) => (
          <Fragment key={c.type}>{renderCell(c, i)}</Fragment>
        ))}
        {children}
      </GridRow>
    </GridHead>
  );
};

type AddFrequencyProps = {
  availableFrequencies: PaymentFrequency[];
  onToggleFrequency: (f: PaymentFrequency) => void;
};

const AddFrequency: FC<AddFrequencyProps> = ({ availableFrequencies, onToggleFrequency }) => {
  const { $t } = useIntl();

  return (
    <Tooltip
      title={
        <Stack gap={1} alignItems="flex-start" width={200}>
          {availableFrequencies.map((fr) => (
            <SimpleButton
              key={fr.id}
              startIcon={<PlusIcon />}
              onClick={() => onToggleFrequency(fr)}
            >
              {$t(
                { id: 'products-AddFrequency' },
                { frequency: $t({ id: `frequencies-${fr.type}` }) },
              )}
            </SimpleButton>
          ))}
        </Stack>
      }
      disableFocusListener
      disableTouchListener
    >
      <IconButton sx={{ pl: 0.25 }} inverse>
        <PlusIcon />
      </IconButton>
    </Tooltip>
  );
};
