import { useGetStudentsWithProductsListQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Loading, ModalSmall } from '@schooly/style';

import AccessDenied from '../../../components/common/AccessDenied';
import { useCompany } from '../../../context/companies/WithCompany';
import { useRouter } from '../../../context/router/useRouter';
import { AddStudentsToCompanyModalContent } from './AddStudentsToCompanyModalContent';

export const AddStudentToCompanyModal = () => {
  const { goBack } = useRouter();
  const { schoolId = '' } = useAuth();

  const { company, isFetching: isFetchingCompany, canEditCompanyStudents } = useCompany();

  const { isLoading: isLoadingStudents } = useGetStudentsWithProductsListQuery(
    {
      schoolId,
      query: '',
    },
    { enabled: !!schoolId },
  );

  if (isLoadingStudents || isFetchingCompany) {
    return (
      <ModalSmall open onClose={goBack}>
        <Loading />
      </ModalSmall>
    );
  }

  if (!company || !canEditCompanyStudents) {
    return (
      <ModalSmall open onClose={goBack}>
        <AccessDenied />
      </ModalSmall>
    );
  }
  return <AddStudentsToCompanyModalContent onClose={goBack} company={company} />;
};
