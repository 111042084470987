import { Components, Theme } from '@mui/material';

export const MuiIcon: Components<Theme>['MuiIcon'] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      let fontSize: string;

      switch (ownerState.fontSize) {
        case 'large':
          fontSize = theme.spacing(5); // TODO: adjust size when will be specified in design
          break;
        case 'small':
          fontSize = theme.spacing(2); // TODO: adjust size when will be specified in design
          break;
        default:
          fontSize = theme.spacing(2.5);
          break;
      }

      return {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize,
      };
    },
  },
};
