import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { AssessmentForGroup, SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { ReportsIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { AssessmentStatusTag } from '../../../components/uikit-components/AssessmentStatusTag/AssessmentStatusTag';
import useAppLocation from '../../../hooks/useAppLocation';

interface AssessmentInfoProps {
  assessment: AssessmentForGroup;
}

export const AssessmentInfo: FC<AssessmentInfoProps> = ({ assessment }) => {
  const location = useAppLocation();

  return (
    <Stack gap={0.25} sx={{ p: 1 }}>
      <Typography variant="body1" color="text.secondary">
        {format(newDateTimezoneOffset(assessment.assessment_date), SHORT_FORMATTED_DATE_FORMAT_FNS)}
      </Typography>

      <Tooltip title={assessment.name}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography
            variant="body1"
            color="text.primary"
            component={Link}
            to={{ pathname: `/assessments/${assessment.id}` }}
            state={
              location.state?.backgroundLocation ? location.state : { backgroundLocation: location }
            }
            style={{ textDecoration: 'underline' }}
            sx={{
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              '-webkit-line-clamp': '2',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {assessment.name}
          </Typography>{' '}
          {assessment.reports?.length ? (
            <div style={{ lineHeight: 'normal', fontSize: 14 }}>
              <ReportsIcon />
            </div>
          ) : null}
        </Stack>
      </Tooltip>

      <Box>
        <AssessmentStatusTag assessment={assessment} flat statusOnly />
      </Box>
    </Stack>
  );
};
