import { useInviteToAcceptQuery } from '@schooly/api';
import { useUserContext } from '@schooly/components/authentication';
import { REDIRECT_SEARCH_PARAM, SIGNUP_ON_UNAUTHORIZED_SEARCH_PARAM } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { Loading } from '@schooly/style';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import InviteModal from '../../components/common/InviteModal';
import LandingLayout from '../../components/ui/LandingLayout';
import { AUTH_APP_URL } from '../../config';
import { hasStaffUserType } from '../../helpers/misc';
import useQueryStringParams from '../../hooks/useQueryStringParams';
import { useSchool } from '../../hooks/useSchool';

const RootPage: React.FC = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const { invite_id: inviteId } = useQueryStringParams();
  const [isOpen, , closeInviteModal] = useFlag(true);
  const { isCompleted, isFetching, schoolId } = useSchool();

  const isStaffSchoolPresented = useMemo(() => hasStaffUserType(user || undefined), [user]);

  const {
    data: inviteData,
    isLoading: isLoadingInvite,
    error: inviteError,
  } = useInviteToAcceptQuery(inviteId!, {
    retry: false,
    enabled: !!inviteId,
  });

  useEffect(() => {
    if (user === null) {
      const { searchParams } = window.INIT_URL;
      const shouldRedirectToSignUp = searchParams.has(SIGNUP_ON_UNAUTHORIZED_SEARCH_PARAM);
      if (shouldRedirectToSignUp) searchParams.delete(SIGNUP_ON_UNAUTHORIZED_SEARCH_PARAM);

      const url = new URL(`${AUTH_APP_URL}${shouldRedirectToSignUp ? '/signup' : ''}`);
      url.searchParams.append(REDIRECT_SEARCH_PARAM, encodeURIComponent(window.INIT_URL.href));

      window.location.replace(url);
      return;
    }

    if (isFetching || inviteId) {
      return;
    }

    if (!isCompleted) {
      navigate('/settings');
      return;
    }

    if (isStaffSchoolPresented) {
      navigate('/planner');
    }
  }, [user, inviteId, isCompleted, isFetching, isStaffSchoolPresented, navigate]);

  if (!user) return <Loading />;

  if (inviteId) {
    return (
      <InviteModal
        inviteId={inviteId}
        isLoading={isLoadingInvite}
        data={inviteData}
        error={inviteError?.message || inviteError?.reason}
        isOpen={isOpen}
        onClose={closeInviteModal}
      />
    );
  }

  if (!isStaffSchoolPresented && user?.school_relations.length) {
    return (
      <LandingLayout
        titleId="root-TitleParentOnly"
        imageName="parent-only"
        subtitleId="root-SubtitleParentOnly"
        hasDownloadButtons
      />
    );
  }

  if (!!schoolId && isFetching) return <Loading />;

  return (
    <LandingLayout
      titleId="root-TitleNoSchools"
      imageName="no-schools"
      subtitleId="root-SubtitleNoSchools"
    />
  );
};

export default RootPage;
