import { Icon, Stack, Typography } from '@mui/material';
import { Attention2Icon } from '@schooly/style';
import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

interface DuplicatesCardProps extends PropsWithChildren {
  message: string;
}

export const DuplicatesCard: FC<DuplicatesCardProps> = ({ children, message }) => {
  return (
    <Stack
      sx={(theme) => ({
        borderRadius: '8px',
        backgroundColor: theme.palette.common.orange5,
        border: `1px solid ${theme.palette.warning.main}`,
        gap: 1,
        p: 2,
        mb: 0.5,
      })}
    >
      <Stack direction="row" gap={1.75} alignItems="center">
        <Icon
          sx={{
            color: 'warning.main',
            '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
          }}
        >
          <Attention2Icon />
        </Icon>
        <Typography
          variant="h3"
          pr={4}
          sx={{
            color: 'warning.main',
          }}
        >
          <FormattedMessage id={message} />
        </Typography>
      </Stack>

      {children}
    </Stack>
  );
};
