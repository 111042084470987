import { Components, Theme } from '@mui/material';

export const MuiSkeleton: Components<Theme>['MuiSkeleton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.common.light2,

      '&.MuiSkeleton-rectangular': {
        borderRadius: theme.shape.borderRadius,
      },
    }),
  },
};
