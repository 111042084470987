import {
  ApiError,
  GET_SCHOOL_YEARS_QUERY,
  SchoolYear,
  UpdateSchoolYearPeriodsRequest,
  useGetSchoolYears,
  useUpdateSchoolYearPeriodsMutation,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getYear, isBefore, startOfToday } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSchool } from '../../../../hooks/useSchool';
import { queryClient } from '../../../../queryClient';
import { addSchoolPeriodGroupOption } from '../../../AnnualPlanner/AnnualPlannerCalendar/scheme';
import { SchoolPeriodGroupsForm } from './SchoolPeriodUpdateContent';

function removeEmptyIds<T extends { id?: string }>(data: T): T | Omit<T, 'id'> {
  const { id, ...rest } = data;

  return id ? data : rest;
}

export const useSchoolPeriodsUpdate = (propSchoolYear?: SchoolYear) => {
  const { id } = useParams<'id'>();
  const { schoolId } = useSchool();
  const updateSchoolYearPeriods = useUpdateSchoolYearPeriodsMutation();
  const { showError } = useNotifications();

  const { data, isLoading } = useGetSchoolYears(schoolId ?? '', {
    enabled: Boolean(!propSchoolYear && schoolId),
  });

  const currentSchoolYear = useMemo(() => {
    const year = propSchoolYear ?? data?.school_years?.find((y) => y.id === id);

    if (!year) return null;

    const currentYear = newDateTimezoneOffset().getFullYear();

    const isCurrent =
      getYear(new Date(year.start)) === currentYear || getYear(new Date(year.end)) === currentYear;

    return { ...year, isCurrent };
  }, [data?.school_years, id, propSchoolYear]);

  const canEdit = useMemo(() => {
    if (!currentSchoolYear) return false;

    const today = startOfToday();
    const isDateInPast = isBefore(new Date(currentSchoolYear.end), today);

    return !isDateInPast;
  }, [currentSchoolYear]);

  const updatePeriodGroups = useCallback(
    async (data: SchoolPeriodGroupsForm) => {
      if (!currentSchoolYear || !schoolId) return;

      const periodGroups = data.period_groups.reduce<
        UpdateSchoolYearPeriodsRequest['period_groups']
      >((acc, data) => {
        if (!data.name || !data.periods.length) return acc;

        const { originId, frequency, ...rest } = data;

        const groupPeriod = removeEmptyIds({
          ...rest,
          id: originId && originId !== addSchoolPeriodGroupOption.value ? originId : undefined,
          periods: data.periods.map(({ originId, isStarted, ...rest }) =>
            removeEmptyIds({
              ...rest,
              id: originId ? originId : undefined,
            }),
          ),
        });

        return [...acc, groupPeriod];
      }, []);

      try {
        const res = await updateSchoolYearPeriods.mutateAsync({
          schoolId,
          schoolYearId: currentSchoolYear.id,
          period_groups: periodGroups,
        });

        // invalidate queries
        queryClient.invalidateQueries([GET_SCHOOL_YEARS_QUERY]);

        return res;
      } catch (error) {
        showError(error as ApiError);
      }
    },
    [currentSchoolYear, schoolId, showError, updateSchoolYearPeriods],
  );

  return useMemo(
    () => ({
      schoolYears: data?.school_years,
      isLoading: !propSchoolYear && isLoading,
      canEdit,
      currentSchoolYear,
      updatePeriods: updatePeriodGroups,
      isUpdating: updateSchoolYearPeriods.isLoading,
    }),
    [
      data?.school_years,
      propSchoolYear,
      isLoading,
      canEdit,
      currentSchoolYear,
      updatePeriodGroups,
      updateSchoolYearPeriods.isLoading,
    ],
  );
};
