import { Box, Icon, IconButton, Stack } from '@mui/material';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { ControlTextField } from '@schooly/components/form-text-field';
import { ArrowAngleIcon, DragIcon, MinusIcon } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import {
  FieldPath,
  FieldPathValue,
  useFieldArray,
  useFormContext,
  Validate,
} from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SchoolGeneralRowAction } from '../SchoolGeneralRowAction';
import {
  AgeGroupInForm,
  getEmptyAgeGroup,
  SchoolGeneralAgeGroupsForm,
} from './useSchoolGeneralAgeGroupsModal';

interface SchoolGeneralAgeGroupRowSmallProps {
  provided: DraggableProvided;
  levelAgeGroupsCount: number;
  shouldFocus: boolean;
  levelIndex: number;
  ageGroupIndex: number;
  schoolLevelsCount: number;
  validateAgeGroup: (
    id: string,
  ) => Validate<
    FieldPathValue<SchoolGeneralAgeGroupsForm, FieldPath<SchoolGeneralAgeGroupsForm>>,
    SchoolGeneralAgeGroupsForm
  >;
  autoSwitchedArchivedOn: React.MutableRefObject<boolean>;
  setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;
  getAllAgeGroups: () => AgeGroupInForm[];
}

export const SchoolGeneralAgeGroupRowSmall: FC<SchoolGeneralAgeGroupRowSmallProps> = ({
  provided,
  levelAgeGroupsCount,
  shouldFocus,
  levelIndex,
  ageGroupIndex,
  validateAgeGroup,
  autoSwitchedArchivedOn,
  setShowArchived,
  getAllAgeGroups,
}) => {
  const { $t } = useIntl();
  const { getConfirmation } = useConfirmationDialog();

  const { control, watch, formState, trigger } = useFormContext<SchoolGeneralAgeGroupsForm>();
  const hasErrors = formState.errors.schoolLevels || formState.errors.ageGroupsWithNoLevel;

  const archivedAgeGroups = watch('archivedAgeGroups');
  const ageGroup = watch(`schoolLevels.${levelIndex}.ageGroups.${ageGroupIndex}`);

  const { remove: removeAgeGroups, replace: replaceAgeGroups } = useFieldArray({
    control: control,
    name: `schoolLevels.${levelIndex}.ageGroups`,
  });
  const { append: appendArchivedAgeGroup } = useFieldArray({
    control: control,
    name: `archivedAgeGroups`,
  });

  const handleArchiveAgeGroup = useCallback(
    async (index: number) => {
      const isConfirmed = await getConfirmation({
        textId: 'school-tabs-AgeGroups-ArchiveConfirmation',
        textValues: { name: ageGroup?.name ?? '' },
        confirmTextId: 'yes',
        cancelTextId: 'no',
      });

      if (isConfirmed) {
        removeAgeGroups(index);
        appendArchivedAgeGroup({ ...ageGroup, archived: true, level_id: null });

        // TR-4613: should switch on `Show archived` first time if no archived fields were before
        if (!archivedAgeGroups.length && !autoSwitchedArchivedOn.current) {
          setShowArchived(true);
          autoSwitchedArchivedOn.current = true;
        }
        if (hasErrors) {
          trigger();
        }
      }
    },
    [
      ageGroup,
      appendArchivedAgeGroup,
      archivedAgeGroups.length,
      autoSwitchedArchivedOn,
      getConfirmation,
      hasErrors,
      removeAgeGroups,
      setShowArchived,
      trigger,
    ],
  );

  const handleDeleteAgeGroup = useCallback(
    (index: number) => {
      if (getAllAgeGroups().length > 1) {
        removeAgeGroups(index);
        if (hasErrors) {
          trigger();
        }
      } else {
        replaceAgeGroups([getEmptyAgeGroup()]);
      }
    },
    [getAllAgeGroups, hasErrors, removeAgeGroups, replaceAgeGroups, trigger],
  );

  const isLocked =
    ageGroup &&
    (ageGroup.conduct_default ||
      ageGroup.group_default ||
      ageGroup.student_default ||
      ageGroup.staff_default);
  const hideDeleteButton = !isLocked && !ageGroup?.id && levelAgeGroupsCount < 2;

  return (
    <Stack
      direction="row"
      gap={1}
      width="100%"
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={provided.draggableProps.style}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          width: '100%',
          '.dragIconButton': {
            visibility: 'hidden',
          },
          ':hover, :active': {
            '.arrowIcon': {
              visibility: 'hidden',
            },
            '.dragIconButton': {
              visibility: 'visible',
            },
          },
        }}
      >
        <Box
          sx={(theme) => ({
            mt: theme.spacing(1),
            position: 'relative',
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
          })}
        >
          <IconButton
            className="dragIconButton"
            inverse
            sx={{ position: 'absolute' }}
            {...provided.dragHandleProps}
          >
            <DragIcon />
          </IconButton>
          <Icon
            className="arrowIcon"
            inverse
            sx={(theme) => ({
              color: theme.palette.common.grey,
              position: 'absolute',
            })}
          >
            <ArrowAngleIcon />
          </Icon>
        </Box>

        <ControlTextField
          canClear
          control={control}
          solid
          hideLabel
          rules={{
            required: true,
            validate: validateAgeGroup(ageGroup?.id || ageGroup?.id_tmp || ''),
          }}
          name={`schoolLevels.${levelIndex}.ageGroups.${ageGroupIndex}.name`}
          placeholder={$t({ id: 'school-tabs-AgeGroups-AgeGroupName' })}
          sx={(theme) => ({
            '&& .MuiInputBase-input': {
              padding: theme.spacing(1, 1.5),
            },
            '&&& .MuiInputAdornment-root': {
              mt: 0,
            },
            'input::placeholder': {
              color: 'text.secondary',
              opacity: 1,
            },
          })}
          fullWidth
          autoFocus={shouldFocus}
        />
      </Stack>
      <Box sx={{ visibility: hideDeleteButton ? 'hidden' : 'visible' }} mt={1}>
        <SchoolGeneralRowAction
          id={ageGroup?.id}
          index={ageGroupIndex}
          isLocked={isLocked}
          lockMessage={$t({
            id: 'school-tabs-AgeGroups-Tooltip-DefaultFilter',
          })}
          onArchive={handleArchiveAgeGroup}
          onDelete={handleDeleteAgeGroup}
          deleteIcon={
            <Icon fontSize="small" sx={{ mx: 0.25 }}>
              <MinusIcon />
            </Icon>
          }
        />
      </Box>
    </Stack>
  );
};
