import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { ArchiveIcon, MinusIcon, PlusIcon, TypographyWithOverflowHint } from '@schooly/style';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { noop } from '../../../helpers/misc';
import useAppLocation from '../../../hooks/useAppLocation';
import { Counter } from '../../uikit/Counter/Counter.styled';
import { MinusIconWrapper, RoundCardWrapper } from './RoundCard.styled';

export interface RoundCardProps<T> extends PropsWithChildren {
  item: T;
  isSelected?: boolean;
  name?: string;
  archived?: boolean;
  archivedTooltipId?: string;
  label?: string;
  count?: string | number;
  withTooltip?: boolean;
  className?: string;
  onClick?: (item?: any) => void;
  generateHref?: (id?: string) => string | null;
  prefix?: string;
  error?: boolean;
  testId?: string;
}

const nameWidth = {
  archivedNoLabelNoCounter: 65,
  archivedNoLabel: 55,
  archived: 30,
  noLabelCounter: 65,
  noLabel: 75,
  default: 50,
};

export default function RoundCard<T extends { id?: string }>({
  item,
  isSelected,
  name,
  archived,
  archivedTooltipId,
  label,
  count,
  withTooltip,
  onClick,
  generateHref = noop,
  prefix,
  error,
  children,
  testId,
}: RoundCardProps<T>): JSX.Element | null {
  const location = useAppLocation();
  const { $t } = useIntl();

  const handleClick = useCallback(() => {
    onClick?.(item);
  }, [item, onClick]);

  const withLabel = !!label;
  const withCounter = typeof count === 'number';

  const link = generateHref(item.id);

  const cardNameMaxWidth = useMemo(() => {
    if (archived && !withLabel && !withCounter) {
      return nameWidth.archivedNoLabelNoCounter;
    }

    if (archived && !withLabel) {
      return nameWidth.archivedNoLabel;
    }

    if (archived) {
      return nameWidth.archived;
    }

    if (!withLabel && !withCounter) {
      return nameWidth.noLabelCounter;
    }

    if (!withLabel) {
      return nameWidth.noLabel;
    }

    return nameWidth.default;
  }, [archived, withLabel, withCounter]);

  const cardNameContent = useMemo(() => {
    if (withTooltip && link) {
      return (
        <Box
          sx={(theme) => ({
            flex: `1 1 ${cardNameMaxWidth}%`,
            maxWidth: `${cardNameMaxWidth}%`,
            padding: theme.spacing(0.5, 0.5, 0.5, 1),
          })}
        >
          <TypographyWithOverflowHint
            variant="h3"
            key={name}
            sx={(theme) => ({
              '.cardNameLink': {
                color: theme.palette.common.grey2,
              },
              ':hover': {
                textDecoration: 'underline',

                '.cardNameLink': {
                  color: theme.palette.common.grey2,
                },
              },
            })}
          >
            <Link
              to={`${link}`}
              className="cardNameLink"
              state={{
                prevPathname: `${location.pathname}${location.hash ?? ''}`,
                backgroundLocation: location,
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {name}
            </Link>
          </TypographyWithOverflowHint>
        </Box>
      );
    }

    if (withTooltip) {
      return (
        <Box
          sx={(theme) => ({
            flex: `1 1 ${cardNameMaxWidth}%`,
            maxWidth: `${cardNameMaxWidth}%`,
            padding: theme.spacing(0.5, 0.5, 0.5, 1),
          })}
        >
          <TypographyWithOverflowHint variant="h3" key={name} color="common.grey2">
            {name}
          </TypographyWithOverflowHint>
        </Box>
      );
    }

    return (
      <TypographyWithOverflowHint variant="h3" key={name} color="common.grey2">
        {name}
      </TypographyWithOverflowHint>
    );
  }, [link, location, cardNameMaxWidth, name, withTooltip]);

  if (!name) {
    return null;
  }

  const rightIconContent = (
    <>
      {onClick ? (
        isSelected ? (
          <MinusIconWrapper className="rightIcon">
            <MinusIcon />
          </MinusIconWrapper>
        ) : (
          <IconButton
            size="small"
            sx={(theme) => ({
              p: 0.5,
              color: theme.palette.common.light3,
              borderRadius: '50%',
              flex: '0 0 auto',
            })}
            className="rightIcon"
          >
            <PlusIcon />
          </IconButton>
        )
      ) : null}
    </>
  );

  const content = withTooltip ? (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      sx={{ maxWidth: prefix || archived ? '40%' : '47%', ml: 'auto' }}
      onClick={handleClick}
    >
      {withLabel && (
        <TypographyWithOverflowHint
          variant="body1"
          color="text.primary"
          key={label}
          sx={(theme) => ({
            border: 1,
            borderColor: theme.palette.common.light2,
            borderRadius: 0.5,
            padding: theme.spacing(0, 0.5),
            mr: 0.5,
          })}
        >
          {label}
        </TypographyWithOverflowHint>
      )}

      {withCounter && (
        <Counter disabled sx={{ mr: 0.5 }}>
          {Number(count)}
        </Counter>
      )}

      {rightIconContent}
    </Stack>
  ) : (
    <>
      {withLabel && <TypographyWithOverflowHint key={label}>{label}</TypographyWithOverflowHint>}

      {withCounter && (
        <Counter disabled sx={{ mr: 0.5 }}>
          {Number(count)}
        </Counter>
      )}

      {rightIconContent}
    </>
  );

  const renderContent = () => (
    <RoundCardWrapper
      error={!!error}
      sx={(theme) => ({
        ':hover .rightIcon': {
          color: theme.palette.common.light2,
          background: theme.palette.primary.main,
        },
      })}
      onClick={handleClick}
      data-test-id={testId}
    >
      {(archived || prefix) && (
        <Stack direction="row" alignItems="center" sx={{ flex: '0 1 10%' }}>
          {archived && (
            <IconButton size="small" sx={{ mr: 1 }}>
              <ArchiveIcon />
            </IconButton>
          )}

          {prefix && (
            <TypographyWithOverflowHint
              variant="h3"
              key={prefix}
              sx={(theme) => ({
                fontSize: '12px',
                color: theme.palette.common.light3,
                maxWidth: cardNameMaxWidth,
              })}
            >
              {prefix}
            </TypographyWithOverflowHint>
          )}
        </Stack>
      )}

      {cardNameContent}

      {children}

      {content}
    </RoundCardWrapper>
  );

  return archivedTooltipId ? (
    <Tooltip title={$t({ id: archivedTooltipId })}>{renderContent()}</Tooltip>
  ) : (
    renderContent()
  );
}
