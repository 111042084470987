import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M10.3786797 10.2573593V3.60050506c0-.55228475.4477152-1 1-1 .5522847 0 1 .44771525 1 1v7.07106784c0 .2652165-.1053569.5195704-.2928933.7071068l-3.94974743 3.9497474v3.4142136l2.24264073 2.2426407v-4.6568543c0-.2652165.1053568-.5195704.2928932-.7071068.6796738-.6796738 1.6406524-1 2.8284271-1 1.1877747 0 2.1487533.3203262 2.8284271 1 .1875364.1875364.2928932.4418903.2928932.7071068v4.6568543l2.2426407-2.2426407v-3.4142136l-3.9497474-3.9497474c-.1875364-.1875364-.2928933-.4418903-.2928933-.7071068V3.60050506c0-.55228475.4477153-1 1-1 .5522848 0 1 .44771525 1 1v6.65685424l3.9497475 3.9497475c.1875364.1875364.2928932.4418903.2928932.7071068v4.2426406c0 .2652165-.1053568.5195705-.2928932.7071068l-4.2426407 4.2426407c-.6299648.6299649-1.7071068.1837981-1.7071068-.7071068v-6.5711161c-.2672022-.1336318-.6348193-.2070585-1.1213203-.2070585-.486501 0-.8541181.0734267-1.1213203.2070585v6.5711161c0 .8909049-1.077142 1.3370717-1.7071068.7071068L6.42893219 19.863961c-.18753638-.1875363-.29289322-.4418903-.29289322-.7071068v-4.2426406c0-.2652165.10535684-.5195704.29289322-.7071068l3.94974751-3.9497475z"
        id="configure"
      />
    </defs>
    <use transform="rotate(-135 12.29289322 13.41521556)" xlinkHref="#configure" />
  </svg>
);
