import { Box, Icon, Stack } from '@mui/material';
import { DATE_FORMAT_RANGE_SHORT_FNS, Group, GroupType } from '@schooly/api';
import { DoneIcon, TagSubject, TypographyWithOverflowHint } from '@schooly/style';
import { formatGroupValidity } from '@schooly/utils/format-group-validity';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type GroupSelectRowProps = {
  isSelected: boolean;
  onClick: (v: string) => void;
  group: Group;
};

export const GroupSelectRow: FC<GroupSelectRowProps> = ({ isSelected, group, onClick }) => {
  return (
    <Stack
      onClick={() => onClick(group.id)}
      flexDirection="row"
      alignItems="center"
      key={group.id}
      px={1}
      gap={1}
      py={0.5}
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <Box width="40%">
        <TypographyWithOverflowHint
          color={!isSelected ? 'common.grey2' : undefined}
          variant="h3"
          noWrap
        >
          {group.name}
        </TypographyWithOverflowHint>
      </Box>
      <Box width="32%">
        <TypographyWithOverflowHint noWrap color="common.grey">
          {formatGroupValidity(group, DATE_FORMAT_RANGE_SHORT_FNS)}
        </TypographyWithOverflowHint>
      </Box>
      <Box
        width={90}
        display="inline-flex"
        sx={{
          svg: {
            minWidth: '12px',
          },
          '.MuiStack-root': {
            minWidth: 0,
          },
        }}
      >
        {(group.subject || group.group_type === GroupType.TutorGroup) && (
          <TagSubject
            renderLabel={() => (
              <TypographyWithOverflowHint>
                {group.subject ? group.subject.name : <FormattedMessage id="groups-TutorGroup" />}
              </TypographyWithOverflowHint>
            )}
            archived={group.subject?.archived}
          />
        )}
      </Box>

      <Icon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <DoneIcon />
      </Icon>
    </Stack>
  );
};
