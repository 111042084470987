import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3034 2.33656C11.5739 2.11763 10.8007 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C13.9056 18 17.1575 15.2013 17.8596 11.5H8.23071L12.3034 2.33656ZM17.9846 10.5C17.9948 10.3346 18 10.1679 18 10C18 6.73615 16.0455 3.92881 13.243 2.6846L9.76947 10.5H17.9846Z"
      fill="#B3BBCB"
    />
  </svg>
);
