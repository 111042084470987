import { Box, Stack, Typography } from '@mui/material';
import { EmptyFolderSvg } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export const ConductNoStudentsStub: FC = () => {
  return (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <Box sx={{ mb: 2, '& svg': { width: 300, height: 300 } }}>
        <EmptyFolderSvg />
      </Box>

      <Typography variant="h1" textAlign="center" mb={1} whiteSpace="pre-wrap">
        <FormattedMessage id="conduct-NoStudents-title" />
      </Typography>

      <Typography textAlign="center" whiteSpace="pre-wrap">
        <FormattedMessage id="conduct-NoStudents-subtitle" />
      </Typography>
    </Stack>
  );
};
