import {
  BooleanOrNull,
  Guardian,
  SchoolRelation,
  User,
  UserType,
  WithTelephone,
} from '@schooly/api';
import { SchoolInviteStatus, SchoolUserRole } from '@schooly/constants';
import { StarIcon } from '@schooly/style';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { getRouteModalPathname } from '../../../helpers/misc';
import renderFullUserType from '../../../helpers/renderFullUserType';
import { getUserFullName } from '../../../helpers/users';
import useAppLocation from '../../../hooks/useAppLocation';
import buildClassName from '../../../utils/buildClassName';
import Avatar from '../../ui/Avatar';
import Tooltip from '../../ui/Tooltip';

import './ProfileCard.scss';

interface ProfileCardProps {
  user: User | SchoolRelation;
  userType: UserType;
  primary?: BooleanOrNull;
  showInviteStatus?: boolean;
  inviteStatus?: Guardian['invite_status'];
  profileSchoolContext?: SchoolUserRole;
  onClick?: (e: React.SyntheticEvent) => void;
  onUserNameClick?: () => void;
}

const ProfileCard = ({
  user,
  userType,
  primary,
  inviteStatus,
  showInviteStatus,
  profileSchoolContext,
  onClick,
  onUserNameClick,
}: ProfileCardProps) => {
  const location = useAppLocation();

  const cardClassName = buildClassName(
    'person d-flex align-items-center',
    userType === 'parent' && 'parent',
    primary ? 'primary' : undefined,
  );

  const inviteStatusClassName = useMemo(() => {
    if (!showInviteStatus) return '';

    return (
      {
        [SchoolInviteStatus.Invited]: 'invited',
        [SchoolInviteStatus.Active]: 'active',
      }[inviteStatus!] || 'not-invited'
    );
  }, [inviteStatus, showInviteStatus]);

  const cardLabel = ['student', 'child'].includes(userType)
    ? renderFullUserType(user as User, userType)
    : null;

  const clickableProps =
    onClick && !primary
      ? {
          onClick,
          role: 'button',
          tabIndex: 0,
        }
      : {};

  return (
    <div
      className={cardClassName}
      // eslint-disable-next- react/jsx-props-no-spreading
      {...clickableProps}
    >
      <div className="d-flex align-items-center flex-1">
        <Avatar user={user} userType={userType} />
        <Link
          to={{
            pathname: getRouteModalPathname(userType, user),
            ...(profileSchoolContext !== undefined && {
              search: `?type=${profileSchoolContext}`,
            }),
          }}
          state={
            location.state?.backgroundLocation ? location.state : { backgroundLocation: location }
          }
          className="h3 person-username ml-2 mb-0 text-primary text-underline"
          onClick={onUserNameClick}
        >
          {getUserFullName(user)}
        </Link>
      </div>
      <div className="d-flex align-items-center flex-1">
        {primary && (
          <span className="label-icon">
            <StarIcon />
          </span>
        )}
        <span className="label-content">
          {userType !== 'student' && userType !== 'child' && (user as WithTelephone).telephone ? (
            <span className="ml-1">{(user as WithTelephone).telephone}</span>
          ) : (
            cardLabel
          )}
        </span>
      </div>
      {showInviteStatus && inviteStatus !== SchoolInviteStatus.Active && (
        <Tooltip
          noWrap
          textId={
            inviteStatus === SchoolInviteStatus.Invited
              ? 'inviteStatus-Invited'
              : 'inviteStatus-NotInvited'
          }
        >
          <span className={buildClassName('invite-indicator', `${inviteStatusClassName}`)} />
        </Tooltip>
      )}
    </div>
  );
};

export default ProfileCard;
