import { FilterKeys, useChangeLastSchoolMutation } from '@schooly/api';
import {
  ARRANGE_BY_SEARCH_PARAM,
  clearLastAppliedFilter,
  StoredFilterSections,
} from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { FC, useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useSchool } from '../../hooks/useSchool';
import { clearReactQueryStorageCache } from '../../queryClient';

export const RolloverResultKeys = { ...FilterKeys, SchoolId: 'school_id' };

type RolloverResultProps = {
  path: string;
};

export const RolloverResult: FC<RolloverResultProps> = ({ path }) => {
  const { schoolId } = useSchool();
  const [searchParams] = useSearchParams();
  const changeLastSchool = useChangeLastSchoolMutation();
  const { showError } = useNotifications();
  const navigate = useNavigate();

  const changeSchool = useCallback(
    async (schoolId: string, queryString: string) => {
      await changeLastSchool.mutateAsync(schoolId, {
        onError: showError,
        onSuccess: () => {
          clearReactQueryStorageCache();
          window.location.href = queryString;
        },
      });
    },
    [changeLastSchool, showError],
  );

  useEffect(() => {
    if (changeLastSchool.isLoading) return;
    const values = Object.values(RolloverResultKeys);

    if (!values.length) {
      navigate(path);
      return;
    }

    let currentSchoolId = schoolId ?? '';
    const filtersFromSearchParams: Partial<Record<string, any>> = {};
    const arrangeByParam = searchParams.get(ARRANGE_BY_SEARCH_PARAM);

    for (const key of values) {
      const param = searchParams.get(key);
      if (!param) continue;
      if (RolloverResultKeys.SchoolId === key) {
        currentSchoolId = param;
        continue;
      }

      filtersFromSearchParams[key] = param.split(',');
    }

    const query = `${path}?${Object.entries(filtersFromSearchParams)
      .map(([k, v]) => `${k}=${encodeURIComponent(JSON.stringify(v))}`)
      .concat(arrangeByParam ? `${ARRANGE_BY_SEARCH_PARAM}=${arrangeByParam}` : '')
      .filter(Boolean)
      .join('&')}`;

    clearLastAppliedFilter(StoredFilterSections.Students);

    if (currentSchoolId === schoolId) {
      navigate(query);
    } else {
      changeSchool(currentSchoolId, query);
    }
  }, [changeLastSchool.isLoading, changeSchool, navigate, path, schoolId, searchParams]);

  return null;
};
