import { Box, Stack, Typography } from '@mui/material';
import {
  AssessmentForRelation,
  AssessmentsGrade,
  DATE_FORMAT_FULL_MONTH_FNS,
  IColumnSort,
  SchoolYear,
  SORT_DIRECTION,
  useGetAssessmentsForRelationQuery,
  useGetAssessmentsGradesForSchoolQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading, ModalSearch } from '@schooly/style';
import { DropdownYears } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { ListViewAssessments } from '../../../components/uikit-components/ListViewAssessments/ListViewAssessments';
import { MONTH_NONE } from '../../../constants/misc';
import { useProfile } from '../../../context/profile/useProfile';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { ProfileModalAssessmentsExport } from './ProfileModalAssessmentsExport';
import { ProfileModalAssessmentsSubjectFilter } from './ProfileModalAssessmentsSubjectFilter';

export const SEARCH_DEBOUNCE_WAIT = 200;

const defaultSort: IColumnSort<keyof AssessmentForRelation>[] = [
  { columnTextId: 'assessment_date', direction: SORT_DIRECTION.DESC },
  { columnTextId: 'subject_order', direction: SORT_DIRECTION.ASC },
  { columnTextId: 'group_name', direction: SORT_DIRECTION.ASC },
  { columnTextId: 'assessment_name', direction: SORT_DIRECTION.ASC },
];

export const ProfileModalAssessments: FC = () => {
  const { schoolMembership } = useProfile();
  const { $t } = useIntl();

  const { schoolId = '' } = useAuth();
  const { defaultValidity, schoolYears } = useSchoolYears();

  const relationId = schoolMembership?.relation_id || '';

  const { data: grades, isLoading: gradesLoading } = useGetAssessmentsGradesForSchoolQuery(
    { schoolId },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const {
    data,
    isLoading: assessmentsLoading,
    hasNextPage,
    params,
    setParams,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetAssessmentsForRelationQuery(
    {
      sort: defaultSort,
      dateFrom: defaultValidity?.start,
      dateTo: defaultValidity?.end,
      relationId,
      subjectIds: [],
      query: '',
    },
    { refetchOnMount: 'always', enabled: !!relationId },
  );

  const isLoading = gradesLoading || assessmentsLoading;

  const handleSetSubjectIds = useCallback(
    (subjectIds: string[]) => {
      setParams((params) => ({
        ...params,
        subjectIds,
      }));
    },
    [setParams],
  );

  const entriesByMonth = useMemo(() => {
    return (
      data?.pages
        .reduce<AssessmentForRelation[]>((prev, curr) => {
          prev.push(...curr.results);
          return prev;
        }, [])
        ?.reduce<Record<string, AssessmentForRelation[]>>((prev, entry) => {
          const month = format(
            newDateTimezoneOffset(entry.assessment_date),
            DATE_FORMAT_FULL_MONTH_FNS,
          ).toUpperCase();

          if (!prev[month]) {
            prev[month] = [];
          }

          prev[month].push(entry);

          return prev;
        }, {}) ?? {}
    );
  }, [data?.pages]);

  const lists = useMemo(() => {
    return grades?.reduce((prev, list) => {
      if (list) {
        prev[list.id] = list;
      }

      return prev;
    }, {} as Record<string, AssessmentsGrade>);
  }, [grades]);

  const handleSchoolYearChange = useCallback(
    (year: SchoolYear) => {
      setParams((p) => {
        return { ...p, dateFrom: year.start, dateTo: year.end };
      });
    },
    [setParams],
  );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const selectedSchoolYear = useMemo(
    () => schoolYears.find((y) => y.start === params.dateFrom && y.end === params.dateTo),
    [params.dateFrom, params.dateTo, schoolYears],
  );

  const totalCount = data?.pages[0].count;

  const loaderRef = useInfiniteScroll(
    isLoading || isFetchingNextPage || !hasNextPage,
    fetchNextPage,
  );

  return (
    <div className="section section-wrapper">
      <Stack direction="row" justifyContent="space-between" gap={2.5} mb={2.5}>
        <Typography variant="h2">
          <FormattedMessage id="section-Assessments" />
        </Typography>
        <Stack direction="row" alignItems="center" gap={2.5} mt={-0.75}>
          <Box>
            <ModalSearch
              value={params.query || ''}
              onChange_MemoizedCallbackOnly={handleChangeQuery}
              placeholder={$t({ id: 'people-Search' })}
              withDebounce
            />
          </Box>
          {!!schoolId && (
            <ProfileModalAssessmentsSubjectFilter
              subjectIds={params.subjectIds || []}
              onSetSubjectIds={handleSetSubjectIds}
              schoolId={schoolId}
            />
          )}
          <DropdownYears
            years={schoolYears}
            defaultYear={defaultValidity}
            currentYear={selectedSchoolYear}
            onYearChange={handleSchoolYearChange}
          />

          <ProfileModalAssessmentsExport />
        </Stack>
      </Stack>

      {isLoading ? (
        <Loading />
      ) : (
        <Stack
          gap={2.5}
          height="100%"
          sx={(theme) => ({
            overflowY: 'scroll',
            [theme.breakpoints.down('md')]: {
              marginRight: -2.5,
            },
          })}
        >
          {totalCount === 0 && <NoSearchResultsFound type="small" />}
          {Object.entries(entriesByMonth).map(([month, assessments]) => (
            <Box key={month}>
              {month !== MONTH_NONE && <Typography variant="h4">{month}</Typography>}
              <ListViewAssessments
                type="for-relation"
                assessments={assessments}
                lists={lists}
                id={schoolMembership?.relation_id}
              />
            </Box>
          ))}

          {hasNextPage && (
            <Box py={3}>
              <div ref={loaderRef} />
              <Loading />
            </Box>
          )}
        </Stack>
      )}
    </div>
  );
};
