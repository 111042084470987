import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.87891 5.41016H9.3125C9.99219 5.41016 10.498 5.43945 10.8301 5.49805C11.166 5.55273 11.4648 5.66992 11.7266 5.84961C11.9922 6.0293 12.2129 6.26953 12.3887 6.57031C12.5645 6.86719 12.6523 7.20117 12.6523 7.57227C12.6523 7.97461 12.543 8.34375 12.3242 8.67969C12.1094 9.01562 11.8164 9.26758 11.4453 9.43555C11.9688 9.58789 12.3711 9.84766 12.6523 10.2148C12.9336 10.582 13.0742 11.0137 13.0742 11.5098C13.0742 11.9004 12.9824 12.2812 12.7988 12.6523C12.6191 13.0195 12.3711 13.3145 12.0547 13.5371C11.7422 13.7559 11.3555 13.8906 10.8945 13.9414C10.6055 13.9727 9.9082 13.9922 8.80273 14H5.87891V5.41016ZM7.61328 6.83984V8.82617H8.75C9.42578 8.82617 9.8457 8.81641 10.0098 8.79688C10.3066 8.76172 10.5391 8.66016 10.707 8.49219C10.8789 8.32031 10.9648 8.0957 10.9648 7.81836C10.9648 7.55273 10.8906 7.33789 10.7422 7.17383C10.5977 7.00586 10.3809 6.9043 10.0918 6.86914C9.91992 6.84961 9.42578 6.83984 8.60938 6.83984H7.61328ZM7.61328 10.2559V12.5527H9.21875C9.84375 12.5527 10.2402 12.5352 10.4082 12.5C10.666 12.4531 10.875 12.3398 11.0352 12.1602C11.1992 11.9766 11.2812 11.7324 11.2812 11.4277C11.2812 11.1699 11.2188 10.9512 11.0938 10.7715C10.9688 10.5918 10.7871 10.4609 10.5488 10.3789C10.3145 10.2969 9.80273 10.2559 9.01367 10.2559H7.61328Z"
      fill="#24275B"
    />
  </svg>
);
