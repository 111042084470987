import { Button, Stack, Typography } from '@mui/material';
import { ModalConfirm, ModalContent, ModalFooter } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export interface RemoveEmploymentCaseConfirmDialogProps {
  onClose: () => void;
  onConfirm: () => void;
}
export const RemoveEmploymentCaseConfirmDialog: FC<RemoveEmploymentCaseConfirmDialogProps> = ({
  onClose,
  onConfirm,
}) => {
  return (
    <ModalConfirm open onClose={onClose}>
      <ModalContent active>
        <Typography variant="h1">
          <FormattedMessage id="enrollment-DeleteRegistrationInfo" />
        </Typography>
      </ModalContent>
      <ModalFooter
        withBorderTop={false}
        active
        sx={{ justifyContent: 'space-between', gap: 0.5, width: '100%', flex: '1 1 100%' }}
      >
        <Stack gap={2} direction="row" flexGrow={1}>
          <Button fullWidth variant="outlined" onClick={onClose}>
            <FormattedMessage id="no" />
          </Button>
          <Button
            fullWidth
            color="primary"
            onClick={() => {
              onClose();
              onConfirm();
            }}
          >
            <FormattedMessage id="yes" />
          </Button>
        </Stack>
      </ModalFooter>
    </ModalConfirm>
  );
};
