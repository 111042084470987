import { Button, Stack, Typography } from '@mui/material';
import { PlusIcon } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export const GroupsEmptyStub: FC = () => {
  return (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <Typography variant="h1" textAlign="center" mb={1} maxWidth={417}>
        <FormattedMessage id="groups-Empty-title" />
      </Typography>

      <Typography variant="h3" mb={3}>
        <FormattedMessage id="groups-Empty-subtitle" />
      </Typography>

      <Link to="/groups/new">
        <Button startIcon={<PlusIcon />}>
          <FormattedMessage id="groups-NewGroup" />
        </Button>
      </Link>
    </Stack>
  );
};
