import { Switch } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import usePrevious from '../../../hooks/usePrevious';
import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';

import './Toggle.scss';

export interface ToggleOption<T> {
  value: T;
  label: React.ReactNode;
}

export interface ToggleProps<T> {
  options: [ToggleOption<T>, ToggleOption<T>];
  value?: T;
  onChange?: (value: ToggleOption<T>) => void;
}

const $class = `${$UIKitPrefix}Toggle`;

export const Toggle = <T,>({ value, options, onChange, ...props }: ToggleProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [switched, setSwitched] = useState(options[1].value === value);
  const prevSwitched = usePrevious(switched);

  useEffect(() => {
    if (value !== undefined) {
      setSwitched(options[1].value === value);
    }
  }, [options, value]);

  useEffect(() => {
    if (prevSwitched !== switched) {
      const newValue = switched ? options[1] : options[0];
      onChange?.(newValue);
    }
  }, [onChange, options, prevSwitched, switched]);

  const handleOptionClick = useCallback(() => {
    if (ref.current) {
      ref.current.querySelector<HTMLButtonElement>('.ant-switch')?.click();
    }
  }, []);

  return (
    <div ref={ref} className={$class}>
      <div
        className={buildClassName(`${$class}__option`, !switched && `${$class}__option_active`)}
        onClick={handleOptionClick}
      >
        {options[0].label}
      </div>
      <div className={`${$class}__switch`}>
        <Switch checked={switched} onChange={(_, v) => setSwitched(v)} {...props} />
      </div>
      <div
        className={buildClassName(`${$class}__option`, switched && `${$class}__option_active`)}
        onClick={handleOptionClick}
      >
        {options[1].label}
      </div>
    </div>
  );
};
