import { Typography } from '@mui/material';
import { PAGE } from '@schooly/constants';
import React, { FC, RefAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFilters } from '../../../context/filters/useFilters';
import { useAppSelector } from '../../../redux/hooks';
import buildClassName from '../../../utils/buildClassName';
import HeaderFilter from '../../common/HeaderFilter/HeaderFilter';
import { Counter } from '../../uikit/Counter/Counter.styled';
import Button from '../Button';

import './index.scss';

interface IHeaderProps {
  pageTitleTextId?: string;
  pageTitle?: string;
  pageTitleCounter?: number;
  hasSearch?: boolean;
  searchPlaceholder?: string;
  buttonTextId?: string;
  buttonIcon?: React.ReactNode;
  buttonCounter?: number;
  onButtonClick?: () => void;
  showActionButton?: boolean;
  testId?: string;
}

export interface IHeaderPropsWithoutSearch extends IHeaderProps {
  hasSearch?: false;
}

export interface IHeaderPropsWithSearch extends IHeaderProps {
  hasSearch: true;
}

const Header: FC<any & RefAttributes<HTMLDivElement>> = React.forwardRef<
  HTMLDivElement,
  IHeaderPropsWithoutSearch | IHeaderPropsWithSearch
>(
  (
    {
      pageTitleTextId,
      pageTitle,
      pageTitleCounter,
      hasSearch,
      searchPlaceholder,
      buttonTextId,
      buttonIcon,
      buttonCounter,
      showActionButton,
      onButtonClick,
      testId,
    },
    ref,
  ) => {
    const { filterType } = useFilters();
    const { pending } = useAppSelector((state) => state.requestsState);

    let bottomInlineMax: number | undefined;

    switch (filterType) {
      default:
        bottomInlineMax = 5;
        break;
    }

    return (
      <div className="Header">
        <Typography
          variant="h1"
          className="Header__page-title"
          pr={5}
          sx={{ display: 'flex', alignItems: 'center' }}
          data-test-id={PAGE.HEADER_TITLE}
        >
          {pageTitle || <FormattedMessage id={pageTitleTextId} />}
          {typeof pageTitleCounter !== 'undefined' && <Counter>{pageTitleCounter}</Counter>}
        </Typography>
        {hasSearch && (
          <HeaderFilter
            searchPlaceholder={searchPlaceholder}
            isLoading={pending}
            bottomInline={!!bottomInlineMax}
            bottomInlineMax={bottomInlineMax}
            bottomBig
            bottomSpace={46}
            ref={ref}
          />
        )}

        {buttonTextId && showActionButton && (
          <div
            className={buildClassName(
              'Header__action-button',
              typeof buttonCounter === 'number' && 'Header__action-button--with-counter',
            )}
          >
            <Button
              icon={buttonIcon}
              counter={buttonCounter}
              onClick={onButtonClick}
              testId={testId}
            >
              <FormattedMessage id={buttonTextId} />
            </Button>
          </div>
        )}
      </div>
    );
  },
);

export default Header;
