import { Box, Icon, Stack, Typography } from '@mui/material';
import { Report, ReportStatuses } from '@schooly/api';
import { DATE_FORMAT } from '@schooly/constants';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type ReportSelectRowProps = {
  isSelected: boolean;
  onClick: (v: string) => void;
  report: Report;
};

export const ReportSelectRow: FC<ReportSelectRowProps> = ({ isSelected, report, onClick }) => {
  const isPublished = report.report_status === ReportStatuses.Published;
  const date = report.actual_publish_date || report.scheduled_publish_date;
  return (
    <Stack
      onClick={() => onClick(report.id)}
      flexDirection="row"
      alignItems="center"
      px={1}
      gap={2}
      py={0.5}
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <Box width="45%">
        <TypographyWithOverflowHint
          color={!isSelected ? 'common.grey2' : undefined}
          variant="h3"
          noWrap
        >
          {report.name}
        </TypographyWithOverflowHint>
      </Box>
      <Box whiteSpace="nowrap" textAlign="right" width="45%">
        <Typography color={isPublished ? 'success.main' : undefined}>
          {isPublished && (
            <>
              <FormattedMessage id="reports-Published" />{' '}
            </>
          )}
          {date && format(newDateTimezoneOffset(date), DATE_FORMAT)}
        </Typography>
      </Box>

      <Icon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <DoneIcon />
      </Icon>
    </Stack>
  );
};
