import { Icon, Stack } from '@mui/material';
import { Recurrence } from '@schooly/api';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { FC } from 'react';

type RecurrenceSelectRowProps = {
  isSelected: boolean;
  onClick: (v: string) => void;
  recurrence: Recurrence;
};

export const RecurrenceSelectRow: FC<RecurrenceSelectRowProps> = ({
  isSelected,
  recurrence,
  onClick,
}) => {
  return (
    <Stack
      onClick={() => onClick(recurrence.id)}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      maxWidth="100%"
      key={recurrence.id}
      px={1}
      gap={2}
      py={0.5}
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <TypographyWithOverflowHint
        color={!isSelected ? 'common.grey2' : undefined}
        variant="h3"
        noWrap
      >
        {recurrence.title}
      </TypographyWithOverflowHint>

      <Icon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <DoneIcon />
      </Icon>
    </Stack>
  );
};
