import { Link } from '@mui/material';
import { Spin, TickIcon } from '@schooly/style';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface VerifyEmailModalResendLinkProps {
  onResend: () => Promise<void>;
}

const DELAY_BEFORE_CHANGE_STATUS = 3000;

export const VerifyEmailModalResendLink: React.FC<VerifyEmailModalResendLinkProps> = ({
  onResend,
}) => {
  const [resendStatus, setResendStatus] = useState<'not-send' | 'sending' | 'sent'>('not-send');

  const handleResend = useCallback(async () => {
    setResendStatus('sending');
    try {
      await onResend();
      setResendStatus('sent');
      setTimeout(() => setResendStatus('not-send'), DELAY_BEFORE_CHANGE_STATUS);
    } catch {
      setResendStatus('not-send');
    }
  }, [onResend]);

  return (
    <Link type="button" onClick={handleResend}>
      {resendStatus === 'not-send' && <FormattedMessage id="verify-ResendCode" />}
      {resendStatus === 'sending' && (
        <>
          <Spin /> <FormattedMessage id="verify-ResendingCode" />
        </>
      )}
      {resendStatus === 'sent' && (
        <>
          <TickIcon className="VerifyEmail__icon" />
          <FormattedMessage id="verify-CodeSent" />
        </>
      )}
    </Link>
  );
};
