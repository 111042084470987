import { Components, Theme } from '@mui/material';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const labelActive = {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        transform: `translate(${theme.spacing(1.5)}, ${theme.spacing(0.5)})`,
      };

      return {
        '& .MuiInputAdornment-root': {
          marginTop: ownerState.label ? theme.spacing(-1.5) : undefined,
          color: theme.palette.text.secondary,
          fontSize: theme.spacing(2.5),

          transition: theme.transitions.create(['color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
          }),

          '&.MuiInputAdornment-positionStart': {
            marginLeft: theme.spacing(1.5),
          },

          '&.MuiInputAdornment-positionEnd': {
            marginRight: theme.spacing(1.5),
            marginTop: ownerState.label ? theme.spacing(-2) : undefined,
          },
        },

        '& .MuiInputLabel-root': {
          ...theme.typography.h3,
          color: theme.palette.text.secondary,
          lineHeight: undefined,
          transform: `translate(${theme.spacing(1.5)}, ${theme.spacing(1.5)})`,

          transition: theme.transitions.create(['color', 'font-size', 'transform'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shortest,
          }),

          '&.Mui-focused, &.MuiFormLabel-filled': labelActive,
        },

        ...(ownerState.value ? { '&& .MuiInputLabel-root': labelActive } : {}),

        '& .MuiInput-root': {
          ...theme.typography.h1,

          '&:before': {
            borderColor: 'transparent',
          },

          '& .MuiInput-input': {
            height: theme.spacing(4),
            margin: theme.spacing(0.5, 0),
            padding: 0,

            '&::placeholder': {
              fontWeight: theme.typography.fontWeightRegular,
            },
          },
        },

        '& .MuiFormHelperText-root': {
          ...theme.typography.body2,

          ...(ownerState.variant === 'standard'
            ? {
                marginTop: theme.spacing(0.5),
              }
            : {
                marginTop: theme.spacing(0.25),
                marginLeft: -3,
                marginRight: -3,
                padding: theme.spacing(0, 2),
              }),
        },

        '& .MuiOutlinedInput-root, .MuiAutocomplete-root & .MuiOutlinedInput-root': {
          ...theme.typography.h3,

          margin: -3,
          padding: 0,
          border: '3px solid transparent',
          borderRadius: theme.shape.borderRadius,

          ...(ownerState.label
            ? {
                paddingTop: theme.spacing(2),
              }
            : {}),

          transition: theme.transitions.create(['border-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shortest,
          }),

          '& .Mui-disabled': {
            color: theme.palette.text.primary,
            '-webkit-text-fill-color': theme.palette.text.primary,
          },

          '&.MuiInputBase-multiline': {
            alignItems: 'flex-start',

            '& .MuiInputAdornment-root': {
              marginTop: ownerState.label ? theme.spacing(0.75) : undefined,
            },
          },

          '&.MuiInputBase-fullWidth': {
            width: `calc(100% + 6px)`,
            display: 'flex',
          },

          '&.Mui-focused, &:hover': {
            borderColor: theme.palette.common.lightBg,

            '& .MuiInputAdornment-root': {
              color: theme.palette.text.primary,
            },

            '& .MuiOutlinedInput-notchedOutline': {
              ...theme.mixins.borderControl(),
              borderRadius: theme.shape.borderRadius,
            },
          },

          '& .MuiOutlinedInput-input': {
            padding: ownerState.label ? theme.spacing(0.5, 1.5) : theme.spacing(1.5),
            height: theme.spacing(2.5),

            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },

            '&.MuiInputBase-inputAdornedEnd': {
              paddingRight: 0,
            },
          },
        },

        '& .MuiOutlinedInput-notchedOutline': {
          top: 0,

          ...theme.mixins.borderControl(),
          borderRadius: theme.shape.borderRadius,

          '& legend': {
            position: 'absolute',
            top: 0,
            left: 0,
          },
        },

        '& .Mui-disabled': {
          backgroundColor: 'unset',

          '&:hover': {
            borderColor: 'transparent !important',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.common.grey7} !important`,
          },
        },
      };
    },
  },
};
