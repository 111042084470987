import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  MenuItem,
  Paper,
  Stack,
} from '@mui/material';
import { exportGroupStudentList } from '@schooly/api';
import { ExportGroupStudentListRequest } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { DELAY_BEFORE_HIDE_ICON_DONE } from '@schooly/constants';
import { Spin } from '@schooly/style';
import moment from 'moment';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Menu, MenuItemArrow } from '../../../../components/common/ContextMenu/ContextMenu.styled';
import { DoneIcon, DownloadIcon } from '../../../../components/ui/Icons';
import { DEFAULT_DATE_FORMAT } from '../../../../config';
import { downloadFile } from '../../../../utils/downloadFile';

export type ExportDataOptions = 'studentDetails' | 'primaryContactDetails' | 'medicalInfo';

export interface GroupStudentListExportProps {
  groupName: string;
  groupId?: string;
}

export interface ExportFormatOption {
  id: ExportGroupStudentListRequest['exportType'];
  mimeType: ExportGroupStudentListRequest['mimeType'];
  extension: string;
  titleTextId: string;
}

export const exportFormatOptions: ExportFormatOption[] = [
  {
    id: 1,
    mimeType: 'application/pdf',
    extension: '.pdf',
    titleTextId: 'exportToPDF',
  },
  {
    id: 0,
    mimeType: 'text/csv',
    extension: '.csv',
    titleTextId: 'exportToCSV',
  },
];

const initialState = {
  studentDetails: 0,
  primaryContactDetails: 0,
  medicalInfo: 0,
};

export const GroupStudentListExport: FC<GroupStudentListExportProps> = ({ groupName, groupId }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [fetching, setFetching] = useState(false);
  const [showDoneIcon, setShowDoneIcon] = useState(false);
  const [exportParams, setExportParams] = useState(initialState);

  const open = Boolean(anchorEl);
  const { showError } = useNotifications();

  const dataOptions: { titleTextId: string; name: ExportDataOptions }[] = [
    { titleTextId: 'groups-StudentsDetails', name: 'studentDetails' },
    { titleTextId: 'groups-PrimaryContactDetails', name: 'primaryContactDetails' },
    { titleTextId: 'groups-MedicalInfo', name: 'medicalInfo' },
  ];

  const noDataOptionsSelected = useMemo(
    () => !Object.values(exportParams).some((el) => el > 0),
    [exportParams],
  );

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setExportParams(initialState);
  }, []);

  const handleChange = useCallback((optionName: ExportDataOptions) => {
    setExportParams((prev) => ({
      ...prev,
      [optionName]: prev[optionName] ? 0 : 1,
    }));
  }, []);

  const handleDownload = useCallback(
    (fileFormat: ExportFormatOption) => async () => {
      if (!groupId) {
        return;
      }

      handleClose();
      setFetching(true);
      try {
        const data = await exportGroupStudentList({
          mimeType: fileFormat.mimeType,
          exportType: fileFormat.id,
          groupId,
          ...exportParams,
        });
        const filename = `${groupName ?? ''}.${moment().format(DEFAULT_DATE_FORMAT)}${
          fileFormat.extension
        }`;
        downloadFile(data, filename, fileFormat.mimeType);
        setShowDoneIcon(true);
        setTimeout(() => setShowDoneIcon(false), DELAY_BEFORE_HIDE_ICON_DONE);
      } catch (error) {
        showError(error as ApiError);
      }
      setFetching(false);
    },
    [exportParams, groupId, groupName, handleClose, showError],
  );

  const icon = useMemo(() => {
    if (showDoneIcon) {
      return (
        <Icon
          sx={{
            display: 'flex',
            color: 'primary.main',
          }}
        >
          <DoneIcon />
        </Icon>
      );
    }
    if (fetching) {
      return (
        <Stack justifyContent="center">
          <Icon
            sx={{
              display: 'flex',
              color: 'primary.main',
            }}
          >
            <Spin />
          </Icon>
        </Stack>
      );
    }
    return (
      <IconButton
        inverse
        onClick={handleOpen}
        sx={{
          '&.MuiIconButton-root': { color: open ? 'primary.main' : undefined },
          color: (theme) => theme.palette.common.grey2,
        }}
      >
        <DownloadIcon />
      </IconButton>
    );
  }, [fetching, open, showDoneIcon]);

  return (
    <>
      {icon}
      <MenuItemArrow anchorEl={anchorEl} open={open}>
        <Paper />
      </MenuItemArrow>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiList-root': {
            padding: (theme) => theme.spacing(1),
          },
        }}
      >
        {dataOptions.map((dataOption) => (
          <MenuItem sx={{ margin: 0, padding: 0 }} key={dataOption.titleTextId}>
            <FormControlLabel
              sx={{ width: '100%', margin: 0 }}
              label={<FormattedMessage id={dataOption.titleTextId} />}
              control={
                <Checkbox
                  sx={{ '&.Mui-checked + .MuiTypography-root': { color: 'primary.main' } }}
                  onChange={() => handleChange(dataOption.name)}
                />
              }
            />
          </MenuItem>
        ))}
        <MenuItem sx={{ padding: 0, margin: 0 }}>
          <Stack
            direction="row"
            gap={1}
            p={1}
            sx={{
              '& .MuiButton-root.Mui-disabled': {
                borderColor: (theme) => theme.palette.common.light2,
              },
            }}
          >
            {exportFormatOptions.map((exportFormatOption) => (
              <Button
                key={exportFormatOption.titleTextId}
                onClick={handleDownload(exportFormatOption)}
                variant="outlined"
                disabled={noDataOptionsSelected}
              >
                <FormattedMessage id={exportFormatOption.titleTextId} />
              </Button>
            ))}
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};
