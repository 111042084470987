import { Stack, styled } from '@mui/material';

export const ToolbarStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  border: 'none !important',
  padding: '0 !important',
  justifyContent: 'flex-start',
  marginBottom: theme.spacing(0.5),
  paddingBottom: theme.spacing(1),
  alignItems: 'center',

  '&.Toolbar--readonly': {
    display: 'none',
  },

  '& .MuiDivider-root': {
    marginBottom: theme.spacing(0.5),
  },

  '&.ql-snow.ql-toolbar button': {
    display: 'inline-flex',
    width: 'auto',
    padding: 0,

    svg: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },

    '.ql-link': {
      svg: {
        height: '16px !important',
      },
    },

    '&:hover, &:active, &:focus, &.ql-active': {
      svg: {
        color: theme.palette.text.primary,

        '& .ql-stroke': {
          stroke: `${theme.palette.text.primary} !important`,
        },
      },
    },
  },

  '& .ql-snow.ql-toolbar .button .ql-stroke': {
    color: theme.palette.text.primary,
  },

  '&:after': {
    position: 'absolute',
  },

  '& button.attachment-button': {
    input: {
      display: 'none',
    },

    svg: {
      width: 110,
    },
  },
}));

ToolbarStyled.defaultProps = {
  className: 'Toolbar',
};
