import { CheckIcon } from '@schooly/style';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import buildClassName from '../../../../utils/buildClassName';

import './index.scss';

interface IProps {
  id?: string;
  checked: boolean;
  isDisabled?: boolean;
  className?: string;
  label?: string;
  labelTextId?: string;
  mode?: 'default' | 'tick';
  onClick: () => void;
}

const Checkbox: React.FC<IProps> = ({
  id,
  checked,
  isDisabled,
  className,
  label,
  labelTextId,
  mode = 'default',
  onClick,
}) => {
  const fullClassName = buildClassName(
    'Checkbox',
    `Checkbox--mode-${mode}`,
    checked && 'Checkbox--checked',
    className,
  );

  return (
    <label className={fullClassName} htmlFor={id}>
      <input id={id} type="checkbox" checked={checked} disabled={isDisabled} onChange={onClick} />
      <span className="Checkbox__circle">{mode === 'tick' && <CheckIcon />}</span>
      <span className="Checkbox__label">
        {labelTextId ? <FormattedMessage id={labelTextId} /> : label}
      </span>
    </label>
  );
};

export default Checkbox;
