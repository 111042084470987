import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useRouter } from '../context/router/useRouter';
import useAppLocation from '../hooks/useAppLocation';
import usePrevious from '../hooks/usePrevious';

export interface RouteModalsProps {
  withBackground?: boolean;
}

export const RouteModals: FC<RouteModalsProps> = ({ withBackground }) => {
  const location = useAppLocation();
  const prevLocation = usePrevious(location);
  const { lastStackItem, firstStackItem, push, clean, replaceLast, getBackground, setBackground } =
    useRouter();

  useEffect(() => {
    const path = getBackground().path;

    if (prevLocation?.key === location.key) {
      return;
    }

    if (lastStackItem?.location.key === location.key) {
      // location has not been changed
      return;
    }

    if (/^[\\/][^\\/]+[\\/]?$/.test(location?.pathname)) {
      // new path is a base path
      clean();
      return;
    }

    if (/^\/(settings)/.test(location?.pathname ?? '') && path !== 'settings') {
      setBackground('settings');
    }

    if (/^\/(students)/.test(location?.pathname ?? '') && path !== 'students') {
      setBackground('students');
    }

    if (
      /^\/children/.test(lastStackItem?.location.pathname ?? '') &&
      /^\/students/.test(location?.pathname ?? '')
    ) {
      // children -> students redirect
      // TODO: is [child -> other student] transition possible?
      replaceLast(location);
      return;
    }

    if (
      /^\/students/.test(lastStackItem?.location.pathname ?? '') &&
      /^\/children/.test(location?.pathname ?? '')
    ) {
      // students -> children redirect
      // TODO: is [student -> other child] transition possible?
      // > students -> children transition means new profile is going to be open,
      // > so no need to replace in this case
      // replaceLast(location);
      // return;
    }

    if (
      /^\/adults/.test(lastStackItem?.location.pathname ?? '') &&
      /^\/(parents|staff)/.test(location?.pathname ?? '')
    ) {
      // adults -> parents|staff redirect
      // TODO: is [adult -> other parent|staff] transition possible?

      if (
        /^\/(staff)/.test(location?.pathname ?? '') &&
        firstStackItem?.location.key === lastStackItem?.location.key
      ) {
        setBackground('staff');
      }

      replaceLast(location);
      return;
    }

    if (
      /^\/staff/.test(lastStackItem?.location.pathname ?? '') &&
      /^\/(adult|parents)/.test(location?.pathname ?? '')
    ) {
      // staff -> adult|parents redirect
      // TODO: is [staff -> other adult|parent] transition possible?
      // > staff -> adult transition means new profile is going to be open,
      // > so no need to replace in this case
      // replaceLast(location);
      // return;
    }

    if (lastStackItem?.location.pathname === location.pathname) {
      // the same route with different search/hash
      replaceLast(location);
      return;
    }

    // The `/:id` -> `/:id/edit` logic is obsolete. Closing of edit modal should lead back
    // to the preview
    //
    // if (
    //   lastStackItem?.location.pathname.replace(/\/edit(\/)?$/, '') ===
    //   location.pathname.replace(/\/edit(\/)?$/, '')
    // ) {
    //   // edit/preview modes of the same essence
    //   replaceLast(location);
    //   return;
    // }

    // The `/new` -> `/:id` logic is obsolete as there are edge cases.
    // For example, user can go from CreateGroup modal to one of existing groups from students
    // criteria.
    // Now we fully rely on state.replace flag only instead.
    //
    // if (lastStackItem?.location.pathname?.endsWith('/new') && lastStackItemBasePath === basePath) {
    //   // previous item was a 'Create' modal and the new one with the same base path means,
    //   // it has been a 'Create' which modal was saved or closed, what should be considered
    //   // as a single stack item.
    //   // Other transitions out of `.../new` should be considered as a plain stack items.
    //   // TODO: keep create/modify modal data in the Redux to be able reconcile it after go back
    //   replaceLast(location);
    //   return;
    // }

    if (location.state?.replace) {
      // replace item if specified implicitly
      // TODO: think about remaking other place to use this mechanism
      replaceLast(location);
    } else {
      // otherwise add new item to the stack
      push(location);
    }
  }, [
    clean,
    firstStackItem?.location.key,
    lastStackItem?.location.key,
    lastStackItem?.location.pathname,
    lastStackItem?.location.state,
    location,
    getBackground,
    prevLocation,
    push,
    replaceLast,
    setBackground,
  ]);

  return (
    <>
      {withBackground && getBackground().element}
      <Outlet />
    </>
  );
};
