import { Avatar, Stack, TableRow, Tooltip, Typography } from '@mui/material';
import { GetGroupsQuerySort, Group, GroupType, SORT_DIRECTION, User } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { SchoolPeriodLabel } from '@schooly/components/filters';
import { DATE_FORMAT } from '@schooly/constants';
import {
  ArchiveIcon,
  GridCell,
  GridHead,
  SortableCell,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';

type GroupsHeaderProps = {
  sort?: GetGroupsQuerySort;
  onChangeSort: (v: GetGroupsQuerySort) => void;
  rightIcon?: ReactNode;
};

export const GroupsHeader: FC<GroupsHeaderProps> = ({ onChangeSort, sort, rightIcon }) => {
  const { $t } = useIntl();

  const handleSort = (columnTextId: GetGroupsQuerySort['columnTextId']) => () => {
    onChangeSort({
      columnTextId,
      direction:
        columnTextId === sort?.columnTextId
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  return (
    <GridHead borderBottom>
      <SortableCell
        label={$t({ id: 'groups-GroupName' })}
        sort={sort}
        columnTextId="name"
        onChangeSort={handleSort}
        pl={2}
        width="65%"
      />
      <SortableCell
        label={$t({ id: 'userType-student-plural' })}
        sort={sort}
        columnTextId="students"
        onChangeSort={handleSort}
        width="180px"
        sx={(theme) => ({
          [theme.breakpoints.only('md')]: {
            width: 140,
          },
        })}
      />
      <SortableCell
        label={$t({ id: 'userType-staff-plural' })}
        sort={sort}
        columnTextId="staff"
        onChangeSort={handleSort}
        width="180px"
        sx={(theme) => ({
          [theme.breakpoints.only('md')]: {
            width: 140,
          },
        })}
      />
      <SortableCell
        label={$t({ id: 'groups-GroupSubject' })}
        sort={sort}
        columnTextId="subject"
        onChangeSort={handleSort}
        width="35%"
      />
      <SortableCell
        label={$t({ id: 'groups-Validity' })}
        sort={sort}
        columnTextId="validity"
        onChangeSort={handleSort}
        width="180px"
        sx={(theme) => ({
          [theme.breakpoints.only('md')]: {
            width: 140,
          },
        })}
      />
      <GridCell right pr={1} width="40px">
        {rightIcon}
      </GridCell>
    </GridHead>
  );
};

type GroupRowProps = {
  group: Group;
  periodLabel?: string;
};

export const GroupRow: FC<GroupRowProps> = ({ group, periodLabel }) => {
  const location = useAppLocation();
  const { $t } = useIntl();

  const dateFrom = group.validity.date_from;
  const dateTo = group.validity.date_to;

  const validity =
    dateFrom && dateTo
      ? `${format(newDateTimezoneOffset(dateFrom), DATE_FORMAT)} - ${format(
          newDateTimezoneOffset(dateTo),
          DATE_FORMAT,
        )}`
      : '—';

  return (
    <TableRow
      component={Link}
      to={`/groups/${group.id}`}
      state={{ initialState: group, backgroundLocation: location }}
      sx={(theme) => ({
        '&:hover td': {
          backgroundColor: theme.palette.background.default,
        },
      })}
      data-test-id="group-table-row"
    >
      <GridCell pl={2}>
        <TypographyWithOverflowHint variant="h3" color="text.primary" noWrap>
          {group.name}
        </TypographyWithOverflowHint>
      </GridCell>
      <GridCell>
        <StudentsCellContent group={group} />
      </GridCell>
      <GridCell>
        <StaffCellContent group={group} />
      </GridCell>
      <GridCell>
        {(() => {
          switch (group?.group_type) {
            case GroupType.TutorGroup:
              return (
                <Typography color="text.primary">
                  <FormattedMessage id="groups-TutorGroup" />
                </Typography>
              );
            case GroupType.Subject:
            default:
              return group.subject?.archived ? (
                <Tooltip title={$t({ id: 'schoolProperty-Archived-subject' })}>
                  <Typography color="text.primary">
                    <ArchiveIcon /> {group.subject?.name ?? '—'}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography color="text.primary">{group.subject?.name ?? '—'}</Typography>
              );
          }
        })()}
      </GridCell>
      <GridCell>
        {periodLabel ? (
          <SchoolPeriodLabel label={periodLabel} dateRange={validity} />
        ) : (
          <Typography color="text.primary">{validity}</Typography>
        )}
      </GridCell>
      <GridCell />
    </TableRow>
  );
};

const AVATARS_AMOUNT = 3;

type CellContentProps = {
  group: Group;
};
const StudentsCellContent: FC<CellContentProps> = ({ group }) => {
  const users = group.students_preview.slice(0, AVATARS_AMOUNT);
  const restAmount = Math.max(0, group.student_count - AVATARS_AMOUNT);

  return (
    <Stack flexDirection="row" gap={1} alignItems="center">
      <Typography color="text.primary">{group.student_count || '—'}</Typography>
      <Avatars users={users} restAmount={restAmount} />
    </Stack>
  );
};
const StaffCellContent: FC<CellContentProps> = ({ group }) => {
  const users = group.staff_preview.slice(0, AVATARS_AMOUNT);
  const restAmount = Math.max(0, group.staff_count - AVATARS_AMOUNT);

  return (
    <Stack flexDirection="row" gap={1} alignItems="center">
      <Typography color="text.primary">{group.staff_count || '—'}</Typography>
      <Avatars users={users} restAmount={restAmount} />
    </Stack>
  );
};

type AvatarsProps = {
  users: Pick<User, 'profile_picture' | 'given_name' | 'last_name' | 'user_id'>[];
  restAmount: number;
};
export const Avatars: FC<AvatarsProps> = ({ users, restAmount }) => {
  return (
    <Stack
      sx={(theme) => ({
        my: theme.spacing(-1),
        flexDirection: 'row',
        '.MuiAvatar-root + .MuiAvatar-root': {
          marginLeft: theme.spacing(-1),
        },
      })}
    >
      {users.map((u) => {
        return <AvatarAuth key={u.user_id} user={u} />;
      })}
      {!!restAmount && <Avatar sx={{ fontSize: 10 }}>+{restAmount}</Avatar>}
    </Stack>
  );
};
