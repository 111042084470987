import { Box, Icon, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { MenuItem } from './ContextMenuItem.styled';

export interface ContextMenuItemProps {
  titleTextId: string;
  onMenuClick?: () => void;
  icon?: ReactNode;
  testId?: string;
}

export const ContextMenuItem: FC<ContextMenuItemProps> = ({
  titleTextId,
  onMenuClick,
  icon,
  testId,
}) => {
  return (
    <MenuItem onClick={onMenuClick} data-test-id={testId}>
      <Stack direction="row" alignItems="center">
        {icon && <Icon>{icon}</Icon>}
        <Box pl={1}>
          <Typography variant="h3">
            <FormattedMessage id={titleTextId} />
          </Typography>
        </Box>
      </Stack>
    </MenuItem>
  );
};
