import React from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { WithFilters } from '../../context/filters/WithFilters';
import { WithUserRolesForSchool } from '../../context/userRoles/WithUserRolesForSchool';
import UserRoles from './UserRoles';

import './index.scss';

const UserRolesPage: React.FC = () => (
  <MainLayout>
    <WithUserRolesForSchool>
      <WithFilters type="user-roles">
        <UserRoles />
      </WithFilters>
    </WithUserRolesForSchool>
  </MainLayout>
);

export default UserRolesPage;
