import { FilterKeys, FilterSelectOption, FilterValue, GroupOptions, UserType } from '@schooly/api';
import { buildClassName } from '@schooly/utils/build-classname';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFilters } from '../../../context/filters/useFilters';
import FilterSelect from './FilterSelect';

export interface IFilterControlProps {
  filterKey: FilterKeys;
  titleTextId: string;
  popupTitleTextId: string;
  options?: FilterSelectOption<FilterValue>[];
  groupOptions?: GroupOptions;
  hasNoneOption?: boolean;
  shouldOpen?: boolean;
  isFocusable?: boolean;
  placeholderTextId?: string;
  className?: string;
  isHeaderFilterPopupOpen?: boolean;
}

const FilterControl: React.FC<IFilterControlProps> = ({
  filterKey,
  titleTextId,
  popupTitleTextId,
  options = [],
  groupOptions,
  hasNoneOption,
  shouldOpen,
  isFocusable,
  isHeaderFilterPopupOpen,
  className,
}) => {
  const { getDraftValue, setDraftValue, setMultipleDraftValues } = useFilters();

  const value = useMemo(() => getDraftValue(filterKey), [filterKey, getDraftValue]);

  const groupValue = useMemo(() => {
    const student = getDraftValue(`student_${filterKey}` as FilterKeys);
    const staff = getDraftValue(`staff_${filterKey}` as FilterKeys);

    return groupOptions ? { student, staff } : undefined;
  }, [filterKey, getDraftValue, groupOptions]);

  const onChange = useCallback(
    (val: FilterValue[]) => {
      setDraftValue(filterKey, val);
    },
    [filterKey, setDraftValue],
  );

  const onChangeGroupOption = useCallback(
    (userType: UserType, val: FilterValue[]) => {
      const key = `${userType}_${filterKey}` as FilterKeys;
      setDraftValue(key, val);
    },
    [filterKey, setDraftValue],
  );

  const onMultipleChangeGroupOption = useCallback(
    (payload: { userType: UserType; value: FilterValue[] }[]) => {
      setMultipleDraftValues(
        payload.map(({ userType, value }) => ({
          key: `${userType}_${filterKey}` as FilterKeys,
          value,
        })),
      );
    },
    [filterKey, setMultipleDraftValues],
  );

  return (
    <div className={buildClassName('filter-control', className)}>
      <div className="filter-control-name">
        <FormattedMessage id={titleTextId} />
      </div>
      <FilterSelect
        value={value}
        filterKey={filterKey}
        onChange={onChange}
        onChangeGroupOption={onChangeGroupOption}
        onMultipleChangeGroupOption={onMultipleChangeGroupOption}
        groupValue={groupValue}
        options={options}
        groupOptions={groupOptions}
        popupTitleTextId={popupTitleTextId}
        hasNoneOption={hasNoneOption}
        shouldOpen={shouldOpen}
        isFocusable={isFocusable}
        isHeaderFilterPopupOpen={isHeaderFilterPopupOpen}
      />
    </div>
  );
};

export default FilterControl;
