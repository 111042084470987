import { IconButton, styled } from '@mui/material';

export const FilterButton = styled(IconButton)<{ hasFiltersApplied: boolean }>(
  ({ theme, hasFiltersApplied }) => ({
    cursor: 'pointer',
    color: hasFiltersApplied ? undefined : 'common.grey',
    '&:hover': {
      color: 'unset',
    },

    '&:after': hasFiltersApplied
      ? {
          content: "''",
          display: 'block',
          width: '0.4rem',
          height: '0.4rem',
          borderRadius: '50%',
          background: theme.palette.success.main,
          border: '1px solid white',
          position: 'absolute',
          right: '0',
          top: -2,
        }
      : undefined,
  }),
);
