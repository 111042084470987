import { Link } from '@mui/material';
import { MobileLinks } from '@schooly/constants';
import { AppstoreIcon, PlaystoreIcon } from '@schooly/style';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import buildClassName from '../../../utils/buildClassName';
import Sidebar from '../../uikit-components/Sidebar/Sidebar';

import './index.scss';

interface IProps extends PropsWithChildren {
  imageName?: string;
  titleId?: string;
  subtitleId?: string;
  hasDownloadButtons?: boolean;
  hideSidebar?: boolean;
  className?: string;
}

const LandingLayout: React.FC<IProps> = ({
  titleId,
  imageName,
  subtitleId,
  hasDownloadButtons,
  hideSidebar,
  children,
  className,
}) => (
  <main className={buildClassName('LandingLayout', 'center-contents', className)}>
    {!hideSidebar && <Sidebar className="LandingLayout__sidebar" hideNavMenu />}
    {imageName && (
      <picture>
        <source srcSet={`/images/${imageName}.png, /images/${imageName}@2x.png 2x`} />
        <img className="LandingLayout__image" src={`/images/${imageName}.png`} alt={titleId} />
      </picture>
    )}
    {titleId && (
      <h1 className="LandingLayout__title">
        <FormattedMessage id={titleId} />
      </h1>
    )}
    {subtitleId && (
      <p className="LandingLayout__subtitle">
        <FormattedMessage id={subtitleId} />
      </p>
    )}
    {hasDownloadButtons && (
      <div className="LandingLayout__buttons-wrapper">
        <Link href={MobileLinks.iosApp}>
          <button className="LandingLayout__button" type="button">
            <AppstoreIcon className="LandingLayout__icon" />
          </button>
        </Link>

        <Link href={MobileLinks.androidApp}>
          <button className="LandingLayout__button" type="button">
            <PlaystoreIcon
              className={buildClassName('LandingLayout__icon', 'reset-svg-currentColor')}
            />
          </button>
        </Link>
      </div>
    )}
    {children}
  </main>
);

export default LandingLayout;
