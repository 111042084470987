import { Box, IconButton, Stack, Typography } from '@mui/material';
import { AvailableCriteria, BaseUser, UserFilter } from '@schooly/api';
import { getMiniList } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { FilterKeys, GroupLimitationsUpdate, SchoolUserType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading, RollBackIcon } from '@schooly/style';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DEFAULT_DATE_FORMAT } from '../../../config';
import { useFilters } from '../../../context/filters/useFilters';
import { getCriteriaName, getUserTypeTextId } from '../../../helpers/misc';
import { getSchoolUserId } from '../../../helpers/school';
import { useLimitedTo } from '../../../hooks/useLimitedTo';
import usePagedApiResourceWithFilter, {
  GetResourceFunction,
} from '../../../hooks/usePagedApiResourceWithFilter';
import { ValidityRangeRequest } from '../../../pages/Groups/AddGroupModal/useAddGroupModal';
import { UserRoleLimitationsUpdate } from '../../../pages/UserRoles/CreateUserRoleModal/Context/useUserRoleModal';
import getTypedObjectKeys from '../../../utils/getTypedObjectKeys';
import updateCancelToken from '../../../utils/updateCancelToken';
import HeaderFilter from '../HeaderFilter/HeaderFilter';
import { NoSearchResultsFound } from '../NoSearchResultsFound/NoSearchResultsFound';
import PersonCardSelectable from '../PersonCard/PersonCardSelectable';
import { ProfileAccordion } from '../ProfileAccordion/ProfileAccordion';
import UserCriteria from '../UserCriteria';

import './AvailableUsersWithFilter.scss';

export interface UserWithCriteria extends BaseUser {
  criteria?: string[];
}

export interface AvailableUsersWithFilterProps {
  userType: SchoolUserType;
  onUserClick: (userId: string, user?: SimpleListResult) => void;
  selectedCriteria?: AvailableCriteria[];
  selectedUsers: SimpleListResult[];
  searchPlaceholderTextId: string;
  filterPlaceholderTextId: string;
  // TODO generic type
  limitedTo: GroupLimitationsUpdate | UserRoleLimitationsUpdate;
  validityRange?: ValidityRangeRequest;
  singleDate?: string;
  onLoad?(users?: SimpleListResult[]): void;
  deleteHistoryUsers?: SimpleListResult[];
  onRemovedUserClick?: (userId: string) => void;
  isModalOpen: boolean;
}

const cancelTokenSource = {};

export const AvailableUsersWithFilter: React.FC<AvailableUsersWithFilterProps> = ({
  userType,
  onUserClick,
  selectedCriteria,
  selectedUsers,
  searchPlaceholderTextId,
  filterPlaceholderTextId,
  limitedTo,
  validityRange,
  singleDate,
  onLoad,
  deleteHistoryUsers,
  onRemovedUserClick,
  isModalOpen,
}) => {
  const { formatMessage, $t } = useIntl();
  const { filters, setDraftValue } = useFilters();
  const { schoolId } = useAuth();

  const { filters: limitedToFilters } = useLimitedTo(userType, limitedTo);

  const appliedValidityRange = useMemo(() => {
    let appliedValidityRange;

    if (singleDate) {
      appliedValidityRange = [singleDate, singleDate];
    }

    if (validityRange) {
      appliedValidityRange =
        moment(validityRange?.[0]) < moment()
          ? [moment().format(DEFAULT_DATE_FORMAT), ...validityRange.slice(1)]
          : validityRange;
    }

    return appliedValidityRange;
  }, [singleDate, validityRange]);

  /*
   * Should set actual validity range to the filters context, so the groups request
   * will be able to apply corresponding filter
   */
  useEffect(() => {
    if (!appliedValidityRange) return;

    if (JSON.stringify(filters.draft[FilterKeys.Date]) !== JSON.stringify(appliedValidityRange)) {
      setDraftValue(FilterKeys.Date, appliedValidityRange ?? []);
    }
  }, [appliedValidityRange, filters.draft, setDraftValue]);

  const getUsersList = useCallback<GetResourceFunction<SimpleListResult>>(
    ({ pageSize, pageNumber, query }) => {
      // filters.applied === undefined means it has not been initially set yet.
      // Later it will be set to an object with some filters which will emit additional update
      // and redundant request as a result .
      if (!schoolId || !filters.applied) {
        return undefined;
      }
      const notEmptyLimitedToFilters = getTypedObjectKeys(limitedToFilters).reduce<
        Partial<UserFilter>
      >((acc, key) => (limitedToFilters[key] ? { ...acc, [key]: limitedToFilters[key] } : acc), {});

      return getMiniList({
        schoolId,
        type: userType,
        pageSize,
        pageNumber,
        query,
        filters: {
          ...filters.applied,
          ...notEmptyLimitedToFilters,
          [FilterKeys.Date]: appliedValidityRange,
        },
        exclude: filters.appliedExclude,
        token: updateCancelToken(cancelTokenSource),
      });
    },
    [
      appliedValidityRange,
      filters.applied,
      filters.appliedExclude,
      limitedToFilters,
      schoolId,
      userType,
    ],
  );

  const { displayedList, canShowMore, isFetching, isSearching, handleShowMore } =
    usePagedApiResourceWithFilter<SimpleListResult>({
      getResource: getUsersList,
      filter: filters.draftQuery,
    });

  const criteriaMap = useMemo(() => {
    return (
      selectedCriteria?.reduce<Record<string, string[]>>((prev, criteria) => {
        criteria.relation_ids?.forEach((userId) => {
          if (!prev[userId]) {
            prev[userId] = [];
          }

          prev[userId].push(getCriteriaName(criteria));
        });

        return prev;
      }, {}) ?? {}
    );
  }, [selectedCriteria]);

  const filteredList = useMemo(() => {
    if (!displayedList?.results) {
      return displayedList?.results;
    }

    const selectedUserIds = selectedUsers.map(getSchoolUserId);

    return displayedList?.results.filter(
      (user) =>
        !selectedUserIds.includes(getSchoolUserId(user)) &&
        !deleteHistoryUsers?.some((u) => u.relation_id === user.relation_id),
    );
  }, [displayedList?.results, deleteHistoryUsers, selectedUsers]);

  onLoad?.(filteredList);

  const isResultEmpty =
    !deleteHistoryUsers?.length && !filteredList?.length && !isSearching && !isFetching;

  const loaderRef = useInfiniteScroll(isFetching || isSearching, handleShowMore);

  const isFirstFetching = !canShowMore && isFetching;

  const renderContent = () => {
    if (isSearching || !filteredList || isFirstFetching) {
      return <Loading />;
    }

    if (isResultEmpty) {
      return <NoSearchResultsFound />;
    }

    return (
      <Box
        mr={-2}
        pr={2}
        sx={{
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          overscrollBehavior: 'contain',
        }}
      >
        {!!deleteHistoryUsers?.length && (
          <Stack pb={2} pt={1}>
            <ProfileAccordion title={$t({ id: 'groups-deleteHistory' })} expandable expandedValue>
              {deleteHistoryUsers?.map((user) => (
                <PersonCardSelectable
                  isUsernameClickable
                  isUsernameLinkView
                  key={user.relation_id}
                  user={user}
                  userType={userType}
                  onClick={(userId) => {
                    onUserClick(userId, user);
                    onRemovedUserClick?.(userId);
                  }}
                  isListItem
                  renderCustomIcon={
                    <IconButton inverse>
                      <RollBackIcon />
                    </IconButton>
                  }
                />
              ))}
            </ProfileAccordion>
          </Stack>
        )}
        {!!filteredList?.length && !!deleteHistoryUsers?.length && (
          <Typography variant="h4">
            <FormattedMessage id="membership-AvailableToAdd" />
          </Typography>
        )}
        {filteredList?.map((user) => (
          <PersonCardSelectable
            isUsernameClickable
            isUsernameLinkView
            key={user.relation_id}
            user={user}
            userType={userType}
            onClick={(userId) => onUserClick(userId, user)}
            isListItem
          >
            {criteriaMap[user.relation_id] && (
              <UserCriteria userCriteria={criteriaMap[user.relation_id]} />
            )}
          </PersonCardSelectable>
        ))}

        {canShowMore && (
          <div className="py-3">
            {isFetching && <Loading />}
            <div ref={loaderRef} />
          </div>
        )}
      </Box>
    );
  };

  const userTypeTranslation = formatMessage({
    id: getUserTypeTextId(userType, true),
  }).toLowerCase();

  return (
    <Stack
      mr={-2}
      pr={2}
      sx={{
        height: '100%',
        overflow: 'hidden',
        '& .HeaderFilter': {
          flex: '0 0 auto',
          marginBottom: '12px',
        },
      }}
    >
      <HeaderFilter
        searchPlaceholder={formatMessage(
          { id: searchPlaceholderTextId },
          {
            userType: userTypeTranslation,
          },
        )}
        modalTitleTextId={formatMessage(
          { id: filterPlaceholderTextId },
          {
            userType: userTypeTranslation,
          },
        )}
        modal
        bottomInline
        isModalOpen={isModalOpen}
      />
      {renderContent()}
    </Stack>
  );
};
