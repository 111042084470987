import { IncomingAttachment, OutcomingAttachment } from '@schooly/api';
import { useEffect, useMemo, useState } from 'react';

import { AttachmentFile } from '../components/ui/Input/RichText/Attachments/attachment-types';

const regex = /^data:.+;base64,/;

export function removeDataPrefix(base64String: string) {
  return base64String.replace(regex, '');
}

export default function useConvertFileToBuffer(files?: AttachmentFile[] | IncomingAttachment[]) {
  const [loadingItems, setLoadingItems] = useState<(string | null | OutcomingAttachment)[]>([]);
  const [convertibleFiles, setConvertibleFiles] = useState<AttachmentFile[]>([]);

  useEffect(() => {
    if (!files || !files.length) return;

    const currentFiles = (files as AttachmentFile[]).filter((f) => !!f.file);
    setConvertibleFiles(currentFiles);

    if (!currentFiles.length) return;

    setLoadingItems([]);

    currentFiles?.forEach((f) => {
      const reader = new FileReader();

      reader.onload = () => {
        setLoadingItems(
          (prev) => [...prev, { data: reader.result, name: f.file.name }] as OutcomingAttachment[],
        );
      };

      reader.readAsDataURL(f.file);
    });
  }, [files]);

  const items = useMemo(() => {
    if (
      !convertibleFiles ||
      !convertibleFiles.length ||
      loadingItems.length < convertibleFiles.length
    ) {
      return [];
    }

    return (loadingItems as OutcomingAttachment[]).map((i) => ({
      ...i,
      data: removeDataPrefix(i.data),
    }));
  }, [convertibleFiles, loadingItems]);

  return items;
}
