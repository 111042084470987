import { Comment } from '@schooly/api';

import { DropdownCommentsItem } from '../components/uikit-components/DropdownComments/DropdownComments';

export function commentToDropdownCommentItem(comment: Comment): DropdownCommentsItem {
  return {
    id: comment.id,
    comment: comment.comment,
    relation_id: comment.creator_relation_id,
    title: comment.creator_title,
    last_name: comment.creator_last_name,
    given_name: comment.creator_given_name,
    known_as: comment.creator_known_as,
  };
}
