import { Stack } from '@mui/material';
import { MessageStatus } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import { MessageStatusTagSelect, MessageStatusTagSelectProps } from './MessageStatusTagSelect';

type MessageStatusExpandedSelectProps = PropsWithChildren<{
  selectedStatuses: MessageStatus[];
  onSelectMessageStatus: (v: MessageStatus) => void;
  onClear: () => void;
  onClose: () => void;
}>;

const STATUSES = [MessageStatus.Published, MessageStatus.Draft, MessageStatus.Submitted];

export const MessageStatusExpandedSelect: FC<MessageStatusExpandedSelectProps> = ({
  selectedStatuses,
  onSelectMessageStatus,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderMessageStatusTags({
          statuses: STATUSES,
          tagProps: (status) => ({
            size: 'small',
            variant: selectedStatuses.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectMessageStatus,
        })}
      </Stack>
    );
  }, [onSelectMessageStatus, selectedStatuses]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedStatuses.length > 0}
      renderContent={renderContent}
      onClose={onClose}
      onClear={onClear}
    >
      {renderMessageStatusTags({
        statuses: selectedStatuses,
        tagProps: { size: 'small' },
        onDelete: onSelectMessageStatus,
      })}
    </ExpandedSelect>
  );
};

type RenderMessageStatusTagsParams = {
  statuses: MessageStatus[];
  onDelete?: (v: MessageStatus) => void;
  onClick?: (v: MessageStatus) => void;
  tagProps?:
    | Omit<MessageStatusTagSelectProps, 'status'>
    | ((v: MessageStatus) => Omit<MessageStatusTagSelectProps, 'status'>);
};

export const renderMessageStatusTags = ({
  statuses,
  onDelete,
  onClick,
  tagProps,
}: RenderMessageStatusTagsParams) =>
  statuses.map((v) => (
    <MessageStatusTagSelect
      key={v}
      status={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
