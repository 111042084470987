import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IProfileModalState {
  hasBeenUpdated: boolean;
}

const initialState: IProfileModalState = {
  hasBeenUpdated: false,
};

const slice = createSlice({
  name: 'profileModal',
  initialState,
  reducers: {
    setHasBeenUpdated: (state, action: PayloadAction<boolean>) => {
      state.hasBeenUpdated = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice;
