import { GroupValidity } from '@schooly/api';
import { SchoolYear } from '@schooly/api';
import moment from 'moment';
import { useCallback } from 'react';

export function useGroupDates({
  schoolYears,
  dateFormat,
}: {
  schoolYears: SchoolYear[];
  dateFormat: string;
}) {
  const getValidityDates = useCallback(
    (validity: GroupValidity) => {
      let from = '';
      let to = '';

      if (validity.date_from && validity.date_to) {
        from = moment(validity.date_from).format(dateFormat);
        to = moment(validity.date_to).format(dateFormat);
      } else if (validity.from_school_year && validity.to_school_year) {
        const startSchoolYear = schoolYears.find(
          (year) => year.id === validity.from_school_year?.id,
        );

        const endSchoolYear = schoolYears.find((year) => year.id === validity.to_school_year?.id);

        if (startSchoolYear && endSchoolYear) {
          from = moment(startSchoolYear?.start).format(dateFormat);
          to = moment(endSchoolYear?.end).format(dateFormat);
        }
      }

      return { from, to };
    },
    [dateFormat, schoolYears],
  );

  return { getValidityDates };
}
