import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import { DATE_FORMAT_RANGE_MOMENT } from '@schooly/constants';
import { CalendarIcon, EditIcon } from '@schooly/style';
import moment from 'moment';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../../components/uikit/Counter/Counter.styled';
import {
  GridContainer,
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../../components/uikit-components/Grid/Grid';
import useSchoolYears from '../../../../../hooks/useSchoolYears';
import { SchoolTuneSchoolYearsEmptyStub } from './SchoolTuneSchoolYearsEmptyStub';

export const SchoolTuneSchoolYears: FC = () => {
  const { schoolYears } = useSchoolYears();

  if (!schoolYears.length) {
    return <SchoolTuneSchoolYearsEmptyStub />;
  }

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="school-tabs-SchoolYears" />
          </Typography>
          {!!schoolYears.length && <Counter>{schoolYears.length}</Counter>}
        </Stack>

        <Link to="school_years">
          <IconButton inverse>
            <EditIcon />
          </IconButton>
        </Link>
      </Stack>

      <Box sx={{ overflow: 'auto' }}>
        <GridContainer>
          {schoolYears.map((year) => (
            <GridRowStyled key={year.id}>
              <GridRowItem>
                <GridRowName sx={{ flex: '1 1 50%' }}>{year.name}</GridRowName>
                <GridRowCell sx={{ flex: '1 1 50%' }}>
                  <Typography
                    variant="body2"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                  >
                    <Icon sx={{ color: 'text.secondary' }}>
                      <CalendarIcon />
                    </Icon>{' '}
                    {moment(year.start).format(DATE_FORMAT_RANGE_MOMENT)} -{' '}
                    {moment(year.end).format(DATE_FORMAT_RANGE_MOMENT)}
                  </Typography>
                </GridRowCell>
              </GridRowItem>
            </GridRowStyled>
          ))}
        </GridContainer>
      </Box>
    </Stack>
  );
};
