import { Event } from '@schooly/api';
import { ControlTextField } from '@schooly/components/form-text-field';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { AnnualPlannerCreateForm } from '../../scheme';
import { AnnualPlannerCreateEventDate } from './AnnualPlannerCreateEventDate';

export interface AnnualPlannerCreateEventProps {
  holiday?: boolean;
  event?: Event;
}

export const AnnualPlannerCreateEvent: FC<AnnualPlannerCreateEventProps> = ({ holiday, event }) => {
  const { $t } = useIntl();
  const { control } = useFormContext<AnnualPlannerCreateForm>();

  return (
    <>
      <AnnualPlannerCreateEventDate event={event} />

      <ControlTextField
        name="event.name"
        control={control}
        label={$t({
          id: holiday ? 'annualPlanner-Popover-Holiday-Name' : 'annualPlanner-Popover-Event-Name',
        })}
        rules={{
          required: true,
        }}
        fullWidth
        hideLabel
      />
    </>
  );
};
