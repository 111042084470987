import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.11842 8.0633L5.59076 7.59096C5.88366 7.29806 6.35853 7.29806 6.65142 7.59096C6.94432 7.88385 6.94432 8.35872 6.65142 8.65162L4.12109 11.1819L1.59076 8.65162C1.29787 8.35872 1.29787 7.88385 1.59076 7.59096C1.88366 7.29806 2.35853 7.29806 2.65142 7.59096L3.44506 8.38459C3.72917 7.22763 4.32324 6.13062 5.22683 5.22703C7.86287 2.59099 12.1367 2.59099 14.7728 5.22703C17.4088 7.86307 17.4088 12.1369 14.7728 14.773C12.1367 17.409 7.86287 17.409 5.22683 14.773C4.93394 14.4801 4.93394 14.0052 5.22683 13.7123C5.51972 13.4194 5.9946 13.4194 6.28749 13.7123C8.33774 15.7626 11.6619 15.7626 13.7121 13.7123C15.7624 11.6621 15.7624 8.33794 13.7121 6.28769C11.6619 4.23744 8.33774 4.23744 6.28749 6.28769C5.76416 6.81102 5.37453 7.4168 5.11842 8.0633ZM9.99976 6.25C10.414 6.25 10.7498 6.58579 10.7498 7V9.59861L13.4158 11.376C13.7604 11.6057 13.8536 12.0714 13.6238 12.416C13.394 12.7607 12.9284 12.8538 12.5837 12.624L9.24976 10.4014V7C9.24976 6.58579 9.58554 6.25 9.99976 6.25Z"
      fill="#5D6688"
    />
  </svg>
);
