import { Box } from '@mui/material';
import { buildClassName } from '@schooly/utils/build-classname';
import { FC, PropsWithChildren, useCallback, useLayoutEffect, useRef, useState } from 'react';

const MAX_CONTAINER_WIDTH = 200;

type CheckOverflowProps = PropsWithChildren<{
  index: number;
  onHideTag: (index: number, hidden: boolean) => void;
  maxWidth?: number;
}>;
export const CheckOverflow: FC<CheckOverflowProps> = ({
  index,
  children,
  onHideTag,
  maxWidth = MAX_CONTAINER_WIDTH,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hidden, setHidden] = useState(false);

  const onHide = useCallback(
    (v: boolean) => {
      setHidden(v);
      onHideTag(index, v);
    },
    [index, onHideTag],
  );

  useLayoutEffect(() => {
    const elRef = ref.current;
    const wrapRef = elRef?.parentElement;
    const containerRef = wrapRef?.parentElement;

    if (!elRef || !containerRef || !wrapRef) {
      return;
    }

    const calculateVisibility = () => {
      const elWidth = elRef.clientWidth;
      const elRelativeX =
        wrapRef.getBoundingClientRect().x - containerRef.getBoundingClientRect().x;

      onHide(elRelativeX + elWidth > maxWidth);
    };

    const resizeObserver = new ResizeObserver(calculateVisibility);

    resizeObserver.observe(elRef);

    return () => {
      resizeObserver.disconnect();
      onHide(false);
    };
  }, [ref, onHide, maxWidth]);

  return (
    <Box
      position="relative"
      ml={hidden ? 0 : 0.5}
      className={buildClassName('CheckOverflow', hidden && 'CheckOverflow-hidden')}
    >
      <Box ref={ref} visibility="hidden" position="absolute">
        {children}
      </Box>
      {!hidden && children}
    </Box>
  );
};
