import { Loading, ModalSmall } from '@schooly/style';
import { FC } from 'react';

import { useEvent } from '../../../context/events/WithEvent';
import { EventCreateContent, EventCreateContentProps } from './EventCreateContent';

export interface EventCreateModalProps extends EventCreateContentProps {}

export const EventCreateModal: FC<EventCreateModalProps> = (props) => {
  const { fetching } = useEvent();

  return <ModalSmall open>{fetching ? <Loading /> : <EventCreateContent {...props} />}</ModalSmall>;
};
