import { Box, Button, Stack, Typography } from '@mui/material';
import React, { forwardRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownSelect } from '../DropdownSelect';
import { DropdownSelectFilterProps } from '../DropdownSelectFilter';
import { renderTags } from '../ExpandedSelectFilter';

function AttendanceNotificationSelectComponent<T>(
  {
    selected,
    onSelect,
    placeholder,
    getLabel,
    options,
    onEmptyLayoutClick,
    ...dropdownProps
  }: DropdownSelectFilterProps<T> & { onEmptyLayoutClick: () => void },
  ref: React.ForwardedRef<DropdownSelect>,
) {
  const renderContent = useCallback(() => {
    return (
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={(theme) => ({
          margin: theme.spacing(1.25, 1.25, 3.5),
          gap: 1,
        })}
      >
        {!options.length ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            flex={1}
            sx={(theme) => ({
              margin: theme.spacing(2, 0),
              pb: theme.spacing(1),
            })}
          >
            <Stack gap={2} sx={{ maxWidth: 300, textAlign: 'center' }}>
              <Typography variant="h3">
                <FormattedMessage id="school-sections-Notifications-NoAttendanceStatuses" />
              </Typography>
              <Box>
                <Button color="primary" size="small" onClick={onEmptyLayoutClick}>
                  <FormattedMessage id="school-sections-Notifications-SetUpAttendanceCodes" />
                </Button>
              </Box>
            </Stack>
          </Stack>
        ) : (
          <>
            {renderTags({
              items: options,
              tagProps: (item) => ({
                variant: selected.includes(item) ? 'filled' : undefined,
              }),
              onClick: onSelect,
              getLabel,
            })}
          </>
        )}
      </Stack>
    );
  }, [getLabel, onEmptyLayoutClick, onSelect, options, selected]);

  return (
    <Stack
      width="506px"
      sx={(theme) => ({
        '& .emptyLabel': {
          ...theme.typography.body1,
        },
      })}
    >
      <DropdownSelect
        ref={ref}
        {...dropdownProps}
        placeholder={placeholder}
        hasValues={!!selected.length}
        renderContent={renderContent}
        layoutStyleProps={{
          minHeight: '35px',
          '.right-icon': {
            top: 8,
          },
        }}
      >
        {(opened) => (
          <>
            {renderTags({
              items: selected,
              tagProps: { size: 'small' },
              onDelete: opened ? onSelect : undefined,
              getLabel,
            })}
          </>
        )}
      </DropdownSelect>
    </Stack>
  );
}

export const AttendanceNotificationSelect = forwardRef(AttendanceNotificationSelectComponent);
