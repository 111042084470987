import { Stack, styled, Typography } from '@mui/material';

export const AssessmentsReportsName = styled(Stack)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  a: {
    color: 'inherit',
  },
});

export interface AssessmentsReportsNameTypographyProps {
  underline?: boolean;
}

export const AssessmentsReportsNameTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'underline',
})<AssessmentsReportsNameTypographyProps>(({ underline }) => ({
  color: 'inherit',

  '&:hover': {
    textDecoration: underline ? 'underline' : undefined,
  },
}));

AssessmentsReportsNameTypography.defaultProps = {
  component: 'span',
  variant: 'h3',
} as typeof AssessmentsReportsNameTypography['defaultProps'];
