import {
  AnnualPlanRecordTypes,
  DEFAULT_DATE_FORMAT_FNS,
  ReportStatuses,
  SchoolYear,
} from '@schooly/api';
import { AssessmentStatuses } from '@schooly/constants';
import {
  addHours,
  differenceInMonths,
  endOfMonth,
  format,
  getDate,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWeekend,
  parse,
  startOfToday,
} from 'date-fns';

import {
  ANNUAL_PLANNER_DAY_CELL_WIDTH,
  AnnualPlannerCalendarWithDates,
  AnnualPlannerCreateForm,
  AnnualPlannerRecordMeta,
} from './scheme';
import { UseAnnualPlannerCellItem } from './useAnnualPlannerCell';

export const getAnnualPlannerCell = ({
  month,
  day,
  start,
  end,
}: {
  month: Date;
  day: number;
  start: Date;
  end: Date;
}) => {
  const lastDate = endOfMonth(month);
  const date = new Date(month);
  let hasDate = day <= lastDate.getDate();

  date.setDate(Math.min(day, lastDate.getDate()));

  hasDate = hasDate && !isBefore(date, start) && !isAfter(date, end);

  const isFirstDate = isSameDay(date, start);
  const isLastMonth = isSameMonth(date, end);
  const isWeekEnd = isWeekend(date);
  const isSunday = date.getDay() === 0;

  return { date, hasDate, isFirstDate, isLastMonth, isWeekEnd, isSunday };
};

export interface GetCellPositionProps extends AnnualPlannerCalendarWithDates {
  date: Date;
}

export const getCellPosition = (props: GetCellPositionProps) => {
  const start =
    typeof props.start === 'string'
      ? parse(props.start, DEFAULT_DATE_FORMAT_FNS, new Date())
      : props.start;

  const end =
    typeof props.end === 'string'
      ? parse(props.end, DEFAULT_DATE_FORMAT_FNS, new Date())
      : props.end;

  const months = differenceInMonths(end, start) + 1;

  const topDateNum = getDate(props.date) - 1;
  const bottomDateNum = topDateNum + 1;
  const monthNum = differenceInMonths(addHours(props.date, 1), start);

  const topValue = (topDateNum * 100) / 31;
  const top = topValue === 0 ? '1px' : `calc(${topValue}% + 1px)`;

  const rightValue = ((monthNum + 1) * 100) / months;
  const right = `${100 - rightValue}%`;

  const bottomValue = (bottomDateNum * 100) / 31;
  const bottom = `${100 - bottomValue}%`;

  const leftValue = (monthNum * 100) / months;
  const left =
    leftValue === 0
      ? `${ANNUAL_PLANNER_DAY_CELL_WIDTH + 1}px`
      : `calc(${leftValue}% + ${ANNUAL_PLANNER_DAY_CELL_WIDTH + 1}px)`;

  const widthValue = rightValue - leftValue;
  const width = `calc(${widthValue}% - ${ANNUAL_PLANNER_DAY_CELL_WIDTH + 1}px)`;

  return {
    top,
    topValue,
    right,
    rightValue,
    bottom,
    bottomValue,
    left,
    leftValue,
    width,
    widthValue,
    topDateNum,
    bottomDateNum,
  };
};

export const getCellStyleParams = (
  date: Date,
  group: UseAnnualPlannerCellItem,
  index: number,
  groups: UseAnnualPlannerCellItem[],
) => {
  const padding: string[] = groups.map((group, i) =>
    group.size === 'wide' ||
    (isSameDay(group.start, date) && (i === groups.length - 1 || groups[i + 1].size === 'wide'))
      ? '1fr'
      : `${i > 0 ? (group.narrowIndex - groups[i - 1].narrowIndex) * 3 : 3}px`,
  );
  const gridColumnStart = index + 1;

  const titleColumnStart =
    group.size === 'narrow' ? padding.findIndex((p) => p === '1fr') + 1 : gridColumnStart;

  return { padding, gridColumnStart, titleColumnStart };
};

export const getRecordLink = (record: AnnualPlannerRecordMeta) => {
  switch (record.type) {
    case AnnualPlanRecordTypes.EVENT:
    case AnnualPlanRecordTypes.HOLIDAY:
      return `/events/${record.id}`;
    case AnnualPlanRecordTypes.ASSESSMENT:
      return `/assessments/${record.id}`;
    case AnnualPlanRecordTypes.REPORT:
      return `/reports/${record.id}`;
  }
};

export const getRecordLockHint = (record: AnnualPlannerRecordMeta) => {
  switch (record.type) {
    case AnnualPlanRecordTypes.SCHOOL_PERIOD:
      return isBefore(record.start, startOfToday())
        ? 'annualPlanner-Records-SchoolPeriods-LockHint'
        : undefined;
    case AnnualPlanRecordTypes.HOLIDAY:
      return record.details.can_be_edited === false || isBefore(record.start, startOfToday())
        ? 'annualPlanner-Records-Holidays-LockHint'
        : undefined;
    case AnnualPlanRecordTypes.EVENT:
      return record.details.can_be_edited === false || isBefore(record.start, startOfToday())
        ? 'annualPlanner-Records-Events-LockHint'
        : undefined;
    case AnnualPlanRecordTypes.ASSESSMENT:
      return record.details.status === AssessmentStatuses.Published
        ? 'annualPlanner-Records-Assessments-LockHint'
        : undefined;
    case AnnualPlanRecordTypes.REPORT:
      return record.details.report_status === ReportStatuses.Published
        ? 'annualPlanner-Records-Reports-LockHint'
        : undefined;
  }
};

export const getRecordFormattedDate = (record: AnnualPlannerRecordMeta) => {
  const dates: Date[] = [record.start];

  if (!isSameDay(record.end, record.start)) {
    dates.push(record.end);
  }

  return dates.map((date) => format(date, 'd MMM yyyy')).join(' - ');
};

export const getRecordTitleByType = (type: AnnualPlanRecordTypes) => {
  switch (type) {
    case AnnualPlanRecordTypes.SCHOOL_PERIOD:
      return 'annualPlanner-Records-SchoolPeriods-Type';
    case AnnualPlanRecordTypes.EVENT:
      return 'annualPlanner-Records-Events-Type';
    case AnnualPlanRecordTypes.HOLIDAY:
      return 'annualPlanner-Records-Holidays-Type';
    case AnnualPlanRecordTypes.ASSESSMENT:
      return 'annualPlanner-Records-Assessments-Type';
    case AnnualPlanRecordTypes.REPORT:
      return 'annualPlanner-Records-Reports-Type';
  }
};

export const getRecordFormData = (
  record: AnnualPlannerRecordMeta,
  schoolYear: SchoolYear,
): Partial<AnnualPlannerCreateForm> => {
  const originId = record.details.id;
  const type = record.type;
  const date: AnnualPlannerCreateForm['date'] = [
    record.start ? format(record.start, DEFAULT_DATE_FORMAT_FNS) : '',
    record.end ? format(record.end, DEFAULT_DATE_FORMAT_FNS) : '',
  ];

  switch (record.type) {
    case AnnualPlanRecordTypes.SCHOOL_PERIOD: {
      const group = schoolYear.period_groups?.find(
        (group) => group.id === record.details.period_group_id,
      );

      return {
        originId,
        type,
        date,
        schoolPeriod: {
          groupId: record.details.period_group_id,
          groupName: group?.name,
        },
      };
    }
    case AnnualPlanRecordTypes.HOLIDAY:
    case AnnualPlanRecordTypes.EVENT:
      return {
        originId,
        type,
        date,
        event: {
          name: record.details.title,
          isHoliday: type === AnnualPlanRecordTypes.HOLIDAY,
        },
      };
    case AnnualPlanRecordTypes.ASSESSMENT:
      return {
        originId,
        type,
        date,
        assessment: {
          name: record.details.name,
        },
      };
    case AnnualPlanRecordTypes.REPORT:
      return {
        originId,
        type,
        date,
        report: {
          name: record.details.name,
        },
      };
    default:
      return {
        originId,
        type,
        date,
      };
  }
};

export const getRecordIncompleteFields = (record: AnnualPlannerRecordMeta) => {
  const fields: string[] = [];

  switch (record.type) {
    case AnnualPlanRecordTypes.EVENT:
      if (!record.details.description) {
        fields.push('description');
      }

      if (!record.details.invitee_type) {
        fields.push('invitee_type');
      }

      break;

    case AnnualPlanRecordTypes.ASSESSMENT:
      if (record.details.status === AssessmentStatuses.Published) {
        // The published assessment can not be incomplete. But the assessment might occur published
        // without some mandatory fields (like built-in assessment or so), in this case have to
        // ignore incomplete fields.
        // TODO: double-check if this is do a valid case
        break;
      }

      if (!record.details.method_ids?.length) {
        fields.push('methods');
      }

      if (!record.details.group_ids?.length) {
        fields.push('groups');
      }

      break;

    case AnnualPlanRecordTypes.REPORT:
      if (!record.details.assessment_ids?.length) {
        fields.push('assessments');
      }

      break;
  }

  return fields;
};
