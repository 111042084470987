import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8853_486387)">
      <path
        d="M6.00005 16.9282C3.61214 15.5495 2.79126 12.486 4.16992 10.0981L7.66992 4.03588C7.85425 3.71662 8.26192 3.60792 8.58055 3.79188C8.89928 3.9759 9.00898 4.3833 8.82466 4.70257L5.32466 10.7647C4.31398 12.5153 4.91566 14.7625 6.66674 15.7734C8.41782 16.7844 10.6648 16.1819 11.6754 14.4314L15.3421 8.08048C15.9854 6.96623 15.6023 5.53651 14.4881 4.8932C13.3737 4.24983 11.944 4.63292 11.3007 5.74717L7.96734 11.5207C7.6917 11.9982 7.85584 12.6111 8.33336 12.8868C8.81088 13.1625 9.42375 12.9982 9.69939 12.5207L12.8661 7.03588C13.0504 6.71662 13.4581 6.60792 13.7767 6.79188C14.0954 6.9759 14.2051 7.3833 14.0208 7.70257L10.8541 13.1874C10.2108 14.3017 8.78098 14.6847 7.66674 14.0414C6.55249 13.3981 6.16929 11.9683 6.8126 10.8541L10.146 5.08048C11.1566 3.33004 13.4036 2.72742 15.1548 3.73846C16.9059 4.74945 17.5075 6.99672 16.4969 8.74717L12.8302 15.0981C11.4515 17.486 8.38796 18.3068 6.00005 16.9282Z"
        fill="#8E90AD"
      />
    </g>
    <defs>
      <clipPath id="clip0_8853_486387">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);
