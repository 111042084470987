import {
  ApiError,
  AvailableCriteria,
  getEligibleRelations,
  GetEligibleRelationsProps,
  GroupLimitations,
  GroupUserType,
  SimpleListResult,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { convertSchoolUserRoleToNumericType } from '../../../../helpers/misc';

export interface LimitedToDeletedUsers {
  deletedMemberIds: string[];
  deletedCriteriaMemberIds: string[];
}

export interface LimitedToRequestParams {
  query?: GetEligibleRelationsProps;
  type?: GroupUserType;
  memberIds?: string[];
  criteriaMemberIds?: string[];
}

interface UseLimitedToProps {
  schoolId: string;
  userType: GroupUserType;
  dateRange: [string, string];
  limitedTo: GroupLimitations;
  currentUsers?: SimpleListResult[];
  currentCriteria?: AvailableCriteria[];
}

export const useLimitedTo = ({
  userType,
  schoolId,
  dateRange,
  limitedTo,
  currentUsers,
  currentCriteria,
}: UseLimitedToProps) => {
  const [draftLimitedTo, setDraftLimitedTo] = useState<GroupLimitations>(limitedTo);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const hasLimitedToSelection = Boolean(
    draftLimitedTo.genders?.length ||
      draftLimitedTo.nationalities?.length ||
      draftLimitedTo.school_property_ids.length ||
      draftLimitedTo.subject_ids?.length,
  );

  const { isFetching, validate } = useLimitedToValidation({
    schoolId,
    userType,
  });

  const onLimitedToUpdate = useCallback(async () => {
    if (isEqual(draftLimitedTo, limitedTo)) return;

    if (currentUsers?.length || currentCriteria?.length) {
      const criteriaMemberIds = currentCriteria?.flatMap((c) => c.relation_ids ?? []) ?? [];
      const memberIds = currentUsers?.map(({ relation_id }) => relation_id) ?? [];
      const relationsId = [...memberIds, ...criteriaMemberIds];

      if (!relationsId.length) {
        return;
      }

      if (!hasLimitedToSelection) {
        return {
          deletedMemberIds: memberIds ?? [],
          deletedCriteriaMemberIds: criteriaMemberIds,
        };
      }

      const data = await validate({
        memberIds,
        criteriaMemberIds,
        dateRange,
        limitedTo: draftLimitedTo,
      });

      return data;
    }
  }, [
    currentCriteria,
    currentUsers,
    dateRange,
    draftLimitedTo,
    hasLimitedToSelection,
    limitedTo,
    validate,
  ]);

  return useMemo(
    () => ({
      draftLimitedTo,
      setDraftLimitedTo,
      isChecking: isFetching,
      hasLimitedToSelection,
      onLimitedToUpdate,
      onDropdownToggle: setDropdownOpen,
      dropdownOpen,
      validate,
    }),
    [draftLimitedTo, dropdownOpen, hasLimitedToSelection, isFetching, onLimitedToUpdate, validate],
  );
};

interface UseLimitedValidation {
  schoolId: string;
  userType: GroupUserType;
}

export const useLimitedToValidation = ({ schoolId, userType }: UseLimitedValidation) => {
  const [fetching, setFetching] = useState(false);
  const { showError } = useNotifications();

  const { mapPropertyIdsByType } = useSchoolProperties({
    schoolId,
    userType: convertSchoolUserRoleToNumericType(userType),
  });

  const validate = useCallback(
    async ({
      memberIds,
      criteriaMemberIds,
      dateRange,
      limitedTo,
    }: {
      memberIds: string[];
      criteriaMemberIds: string[];
      dateRange: string[];
      limitedTo: GroupLimitations;
    }) => {
      const [date_from, date_to] = dateRange;

      const relationIds = [...memberIds, ...criteriaMemberIds];
      const query: GetEligibleRelationsProps = {
        schoolId: schoolId,
        user_role: convertSchoolUserRoleToNumericType(userType),
        relation_ids: relationIds,
        date_from,
        date_to,
        ...mapPropertyIdsByType<string>(
          limitedTo.school_property_ids,
          (schoolProperty) => schoolProperty.id,
        ),
        nationality: limitedTo.nationalities,
        gender: limitedTo.genders,
      };

      setFetching(true);
      try {
        const { relation_ids } = await getEligibleRelations(query);
        const resMap =
          relation_ids.reduce<Record<string, boolean>>((acc, relationId) => {
            acc[relationId] = true;
            return acc;
          }, {}) ?? {};

        const deletedMemberIds = memberIds?.filter((id) => !resMap[id]) ?? [];
        const deletedCriteriaMemberIds = criteriaMemberIds?.filter((id) => !resMap[id]) ?? [];
        if (!deletedMemberIds.length && !deletedCriteriaMemberIds.length) {
          return;
        }

        return { deletedMemberIds, deletedCriteriaMemberIds };
      } catch (error) {
        showError(error as ApiError);
      } finally {
        setFetching(false);
      }
    },
    [mapPropertyIdsByType, schoolId, showError, userType],
  );

  return useMemo(() => ({ isFetching: fetching, validate }), [fetching, validate]);
};
