import {
  AvailableCriteria,
  GroupLimitations,
  GroupUserType,
  SimpleListResult,
  useGetGroupAvailableCriteria,
} from '@schooly/api';
import { FC, useMemo } from 'react';

import ExternalSidebar from './ExternalSidebar';
import { AddGroupTab } from './useAddGroupModal';

interface GroupSidebarContentProps {
  schoolId: string;
  activeTab: AddGroupTab;
  deleteHistoryUsers: SimpleListResult[];
  userType: GroupUserType;
  groupUsers: SimpleListResult[];
  validityRange: [string, string];
  groupId?: string;
  groupCriteria: AvailableCriteria[];
  limitedTo: GroupLimitations;
  onRemovedUserClick: (userId: string) => void;
  onCriteriaClick: (criteria: AvailableCriteria) => void;
  onAddUsers: (users: SimpleListResult[]) => void;
  onUserClick: (userId: string, user?: SimpleListResult) => void;
  titleId: string;
}

export const GroupSidebarContent: FC<GroupSidebarContentProps> = ({
  schoolId,
  deleteHistoryUsers,
  userType,
  validityRange,
  groupUsers,
  groupCriteria,
  limitedTo,
  groupId,
  onRemovedUserClick,
  onCriteriaClick,
  onAddUsers,
  onUserClick,
  titleId,
  activeTab,
}) => {
  const { data: criteria, isFetching: criteriaFetching } = useGetGroupAvailableCriteria(
    {
      schoolId: schoolId ?? '',
      fromDate: validityRange[0],
      toDate: validityRange[1],
      schoolPropertyIds: limitedTo?.school_property_ids,
      genders: limitedTo?.genders,
      nationalities: limitedTo?.nationalities,
    },
    {
      refetchOnMount: 'always',
      enabled: activeTab === 'criteria' && !!validityRange,
    },
  );

  const availableCriteria = useMemo(() => {
    if (!groupId) return criteria?.available_criteria;

    return criteria?.available_criteria.filter((c) => c.group?.id !== groupId);
  }, [criteria?.available_criteria, groupId]);

  return (
    <ExternalSidebar
      userType={userType}
      titleId={titleId}
      availableCriteria={availableCriteria}
      isFetching={criteriaFetching}
      groupCriteria={groupCriteria}
      groupUsers={groupUsers}
      onUserClick={onUserClick}
      onAddUsers={onAddUsers}
      onCriteriaClick={onCriteriaClick}
      editedGroupId={groupId}
      limitedTo={limitedTo}
      validityRange={validityRange}
      deleteHistoryUsers={deleteHistoryUsers}
      onRemovedUserClick={onRemovedUserClick}
    />
  );
};
