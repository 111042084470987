import { Icon, IconButton } from '@mui/material';
import {
  AvailableCriteria,
  FilterElementType,
  Group,
  GroupType,
  IListGroupArguments,
  SchoolUserType,
  useGetGroupSubjectsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  PROPERTIES_TEXT_IDS,
  PropertiesTextIds,
  SchoolPropertyType,
  SchoolUserRole,
} from '@schooly/constants';
import { useAgeGroups, useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  ArrowheadDownIcon,
  ChevronUpSmallIcon,
  Counter,
  Loading,
  PlusIcon,
  SidebarExpandableCard,
  theme,
} from '@schooly/style';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

import Nationalities from '../../../constants/nationalities';
import { Genders } from '../../../constants/people';
import { getCriteriaItemKey, getCriteriaName } from '../../../helpers/misc';
import usePrevious from '../../../hooks/usePrevious';
import { getGroupedCriteria } from '../../../pages/Messages/helpers';
import buildClassName from '../../../utils/buildClassName';
import getTypedObjectKeys from '../../../utils/getTypedObjectKeys';
import searchWords from '../../../utils/searchWords';
import SearchInput from '../../ui/SearchInput';
import RoundCard, { RoundCardProps } from '../../uikit-components/RoundCard';
import { NoSearchResultsFound } from '../NoSearchResultsFound/NoSearchResultsFound';

import './index.scss';

interface AvailableCriteriaListProps {
  userType: SchoolUserType;
  selectedCriteria?: AvailableCriteria[];
  singleDate?: string;
  onCriteriaClick?: (criteria: AvailableCriteria) => void;
  onGroupCriteriaAddClick: (criteria: AvailableCriteria[]) => void;
  fetchGroups: (params: IListGroupArguments) => Promise<Group[]>;
}

const GENDERS = $enum(Genders);
const NATIONALITIES = $enum(Nationalities);

const MAX_PAGE_SIZE = 999999;

const AvailableCriteriaList: React.FC<AvailableCriteriaListProps> = ({
  userType,
  selectedCriteria,
  singleDate,
  onCriteriaClick,
  onGroupCriteriaAddClick,
  fetchGroups,
}) => {
  const { $t } = useIntl();
  const { schoolId = '' } = useAuth();
  const { data } = useGetGroupSubjectsQuery({ schoolId }, { refetchOnMount: 'always' });
  const schoolGroupSubjects = data?.subjects;
  const { activePropertiesMap } = useSchoolProperties({
    schoolId,
    userType: userType === 'staff' ? SchoolUserRole.Staff : SchoolUserRole.Student,
    showReEnrollmentProperties: true,
  });
  const { activeAgeGroups, getSchoolLevelById, getAgeGroupsByLevelId } = useAgeGroups({
    schoolId,
    userType: userType === 'staff' ? SchoolUserRole.Staff : SchoolUserRole.Student,
  });
  const prevSingleDate = usePrevious(singleDate);

  const [filter, setFilter] = useState('');
  const [groups, setGroups] = useState<Group[]>();
  const [selectedCategory, setSelectedCategory] = useState<PropertiesTextIds>();

  const handleFilterChange = useCallback((newFilter: string) => {
    setFilter(newFilter);
  }, []);

  const handleCategoryClick = useCallback(
    (category: PropertiesTextIds) => {
      setSelectedCategory(category === selectedCategory ? undefined : category);
    },
    [selectedCategory],
  );

  const getCriteriaIsSelected = useCallback(
    (item: AvailableCriteria) => {
      return selectedCriteria?.some((c) => {
        if (c.school_property) {
          return c.school_property.id === item.school_property?.id;
        }

        if (c.subject) {
          return c.subject.id === item.subject?.id;
        }

        if (c.group) {
          return c.group.id === item.group?.id;
        }

        return c.enum_index === item.enum_index && c.type === item.type;
      });
    },
    [selectedCriteria],
  );

  const criteriaByCategory = useMemo(() => {
    const result: Partial<Record<PropertiesTextIds, RoundCardProps<AvailableCriteria>[]>> = {};

    getTypedObjectKeys(PROPERTIES_TEXT_IDS).forEach((key) => {
      switch (key) {
        case 'status':
          result[key] = activePropertiesMap[SchoolPropertyType.Status].map((i) => ({
            item: {
              type: FilterElementType.Property,
              school_property: i,
            },
          }));
          break;
        case 'house':
          result[key] = activePropertiesMap[SchoolPropertyType.House].map((i) => ({
            item: {
              type: FilterElementType.Property,
              school_property: i,
            },
          }));
          break;
        case 'age_group':
          result[key] = activeAgeGroups.map((item) => ({
            item: {
              type: FilterElementType.Property,
              school_property: { ...item, type: SchoolPropertyType.AgeGroup },
              propGroup: {
                id: item.level_id,
                name: item.level_id ? getSchoolLevelById(item.level_id)?.name : undefined,
                count: getAgeGroupsByLevelId(item.level_id ?? '').length ?? 0,
                propType: SchoolPropertyType.AgeGroup,
              },
            },
          }));
          break;
        case 'department':
          if (userType !== 'staff') break;
          result[key] = activePropertiesMap[SchoolPropertyType.Department].map((i) => ({
            item: {
              type: FilterElementType.Property,
              school_property: i,
            },
          }));
          break;
        case 'gender':
          result[key] = GENDERS.map((enum_index) => ({
            item: {
              type: FilterElementType.Gender,
              enum_index,
            },
          }));
          break;
        case 'nationality':
          result[key] = NATIONALITIES.map((enum_index) => ({
            item: {
              type: FilterElementType.Nationality,
              enum_index,
            },
          }));
          break;
        case 'subject':
          result[key] = schoolGroupSubjects
            ?.slice()
            .filter((item) => !item.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((i) => ({
              item: {
                type: FilterElementType.Subject,
                subject: i,
              },
            }));
          break;
        case 'group':
          result[key] = groups?.map((group) => ({
            item: {
              type: FilterElementType.Group,
              group,
            },
            label:
              group.group_type === GroupType.TutorGroup
                ? $t({ id: 'groups-TutorGroup' })
                : group.subject?.name,
            withTooltip: true,
            generateHref: () => `/groups/${group.id}`,
          }));
          break;
        default:
          break;
      }
    });

    return result;
  }, [
    activePropertiesMap,
    activeAgeGroups,
    userType,
    schoolGroupSubjects,
    groups,
    getSchoolLevelById,
    getAgeGroupsByLevelId,
    $t,
  ]);

  const displayedCriteriaByCategory = useMemo(() => {
    const result: Partial<Record<PropertiesTextIds, RoundCardProps<AvailableCriteria>[]>> = {};

    getTypedObjectKeys(PROPERTIES_TEXT_IDS).forEach((key) => {
      result[key] = criteriaByCategory[key]?.filter(({ item }) => !getCriteriaIsSelected(item));

      if (filter) {
        result[key] = result[key]?.filter(({ item }) => {
          switch (item.type) {
            case FilterElementType.Property:
              return (
                searchWords(item.school_property!.name, filter) ||
                (item.propGroup?.name && searchWords(item.propGroup.name, filter))
              );
            case FilterElementType.Subject:
              return searchWords(item.subject!.name, filter);
            case FilterElementType.Group:
              return searchWords(item.group!.name, filter);
            case FilterElementType.Gender:
              return searchWords(Genders[item.enum_index!], filter);
            case FilterElementType.Nationality:
              return searchWords(Nationalities[item.enum_index!], filter);
            default:
              return true;
          }
        });
      }

      if (key === 'age_group') {
        result[key] = getGroupedCriteria(filter, result[key]);
      }
    });

    if (filter && Object.values(result).every((o) => !o || !o.length)) {
      return {};
    }

    return result;
  }, [criteriaByCategory, filter, getCriteriaIsSelected]);

  useEffect(() => {
    (async () => {
      if (!schoolId || prevSingleDate === singleDate) {
        return;
      }
      setGroups(undefined);

      const groups = await fetchGroups({
        schoolId,
        singleDate,
        pageSize: MAX_PAGE_SIZE,
        min: 1,
      });

      setGroups(groups);
    })();
  }, [fetchGroups, prevSingleDate, schoolId, singleDate]);

  useEffect(() => {
    setFilter('');
    setSelectedCategory(undefined);
  }, [userType]);

  function renderCriteriaCategory(category: PropertiesTextIds) {
    if (!displayedCriteriaByCategory || !displayedCriteriaByCategory[category]?.length) {
      return undefined;
    }

    return (
      <React.Fragment key={category}>
        <div
          className={buildClassName(
            'AvailableCriteria__category',
            (category === selectedCategory || !!filter) && 'selected',
          )}
        >
          <div
            className="AvailableCriteria__category-header"
            onClick={() => handleCategoryClick(category)}
            role="button"
            tabIndex={0}
          >
            <FormattedMessage id={PROPERTIES_TEXT_IDS[category]} />

            <ArrowheadDownIcon />
          </div>

          <div className="AvailableCriteria__category-content">
            {displayedCriteriaByCategory[category]?.map(({ item, ...other }) => {
              if (
                item.propGroup?.id &&
                (item.propGroupItems?.length || (item.propGroupItems !== undefined && filter))
              ) {
                const allRelatedCriteria = item.propGroup?.propType
                  ? criteriaByCategory[item.propGroup?.propType]?.filter(
                      (c) =>
                        c.item.propGroup?.id === item.propGroup?.id &&
                        !getCriteriaIsSelected(c.item),
                    )
                  : [];

                return (
                  <PropGroupCard
                    key={item.propGroup.id}
                    onAddGroup={() =>
                      onGroupCriteriaAddClick((allRelatedCriteria ?? []).map(({ item }) => item))
                    }
                    propGroup={{ id: item.propGroup.id, name: item.propGroup?.name ?? '' }}
                    count={item.propGroup?.count ?? 0}
                    isExpanded={Boolean(filter && item.propGroupItems.length)}
                    canExpand={Boolean(item.propGroupItems.length)}
                  >
                    {item.propGroupItems.map((i) => (
                      <RoundCard
                        key={getCriteriaItemKey(i)}
                        name={getCriteriaName(i)}
                        onClick={onCriteriaClick}
                        item={i}
                        {...other}
                      />
                    ))}
                  </PropGroupCard>
                );
              }

              return (
                <RoundCard
                  key={getCriteriaItemKey(item)}
                  name={getCriteriaName(item)}
                  item={item}
                  onClick={onCriteriaClick}
                  {...other}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className="AvailableCriteria">
      {displayedCriteriaByCategory && groups ? (
        <>
          <SearchInput
            value={filter}
            onChange={handleFilterChange}
            isValueRemovable
            placeholderTextId="messages-CriteriaSearch"
          />

          {filter && !Object.keys(displayedCriteriaByCategory).length ? (
            <div className="AvailableCriteria__empty">
              <NoSearchResultsFound />
            </div>
          ) : (
            <div className="AvailableCriteria__list">
              {!!displayedCriteriaByCategory &&
                getTypedObjectKeys(displayedCriteriaByCategory).map(renderCriteriaCategory)}
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AvailableCriteriaList;

interface PropGroupCardProps extends PropsWithChildren {
  propGroup: { id: string; name: string };
  count: number;
  onAddGroup?: () => void;
  isExpanded: boolean;
  canExpand: boolean;
}

const PropGroupCard: FC<PropGroupCardProps> = ({
  children,
  propGroup,
  count,
  onAddGroup,
  isExpanded,
  canExpand,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <SidebarExpandableCard
      item={propGroup}
      onCardClick={canExpand ? () => setExpanded((e) => !e) : undefined}
      expanded={expanded}
      style={{
        py: theme.spacing(1),
        paddingBottom: expanded ? 0 : undefined,
        '&:hover:not(:has(.UIKit-RoundCard:hover))': {
          backgroundColor: 'background.default',
          color: 'primary.main',
          '& .MuiTypography-root': {
            color: 'primary.main',
            borderColor: 'primary.main',
          },
          '& .plusIcon': {
            backgroundColor: 'primary.main',
            color: 'background.paper',
            transition: 'all 0.2s',
          },
          '& .UIKit-RoundCard': {
            backgroundColor: 'background.paper',
          },
          '.MuiIcon-root': {
            path: {
              stroke: theme.palette.primary.main,
            },
          },
        },
      }}
      endIcon={
        <IconButton
          size="small"
          className="plusIcon"
          sx={{
            p: 0.5,
            color: 'text.secondary',
            borderRadius: '50%',
          }}
          onClick={onAddGroup}
        >
          <PlusIcon />
        </IconButton>
      }
      startIcon={
        <>
          <Counter
            inverse
            sx={(theme) => ({
              minWidth: 20,
              height: 20,
              textAlign: 'center',
              fontWeight: theme.typography.fontWeightMedium,
              mx: 0.5,
              padding: theme.spacing(0, 0.5),
              background: 'transparent',
              color: 'text.primary',
              borderColor: 'text.primary',
            })}
          >
            {count}
          </Counter>
          {canExpand && (
            <Icon
              sx={(theme) => ({
                alignSelf: 'center',
                fontSize: theme.spacing(1),
                transform: !expanded ? 'rotate(180deg)' : undefined,
                path: {
                  stroke: theme.palette.text.primary,
                },
              })}
            >
              <ChevronUpSmallIcon />
            </Icon>
          )}
        </>
      }
    >
      {expanded && children}
    </SidebarExpandableCard>
  );
};
