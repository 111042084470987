import { combineReducers } from '@reduxjs/toolkit';

import simpleStaffSlice, { SimpleListStaffState } from './simpleStaffSlice';
import simpleStudentSlice, { SimpleListStudentState } from './simpleStudentSlice';

export interface SimpleListsState {
  staff: SimpleListStaffState;
  student: SimpleListStudentState;
}

const reducer = combineReducers<SimpleListsState>({
  staff: simpleStaffSlice.reducer,
  student: simpleStudentSlice.reducer,
});

export default reducer;
