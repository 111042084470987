import { styled } from '@mui/material';

import { GridRowDate } from '../../components/uikit-components/Grid/Grid';

export const GridRowDateToday = styled(GridRowDate)(({ theme }) => ({
  position: 'relative',
  '&:before': {
    position: 'absolute',
    width: theme.spacing(),
    height: theme.spacing(),
    borderRadius: '50%',
    background: theme.palette.success.main,
    margin: 0,
    transform: `translate(${theme.spacing(-2)}, 50%)`,
    content: '""',
  },
}));
