import {
  AdultAssociation,
  AnySchoolRelation,
  GET_USER_QUERY,
  SyncUser,
  UploadFile,
  useGetParentMembership,
  useGetStaffMembership,
  useGetStudentMembership,
  useGetUserQuery,
  useInvalidateSchoolMembershipQuery,
  useUpdateSchoolMembershipMutation,
  useUpdateSchoolUserMutation,
} from '@schooly/api';
import { ApiError, ParentSchoolRelation, SchoolUserType, UserType } from '@schooly/api';
import { useAuth, useUserContext } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { getIsEditableForProfilePicture } from '../../components/common/ProfileModal/utils';
import { MODAL_FADEOUT_DURATION_MS } from '../../components/ui/Modal';
import { SchoolUserRole } from '../../constants/school';
import { UserContexts } from '../../constants/userContexts';
import { getRouteModalPathname, isProfileUserType, isSchoolUserType } from '../../helpers/misc';
import useAppLocation from '../../hooks/useAppLocation';
import { clearReactQueryStorageCache, queryClient } from '../../queryClient';
import { useAppDispatch } from '../../redux/hooks';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import IntlError from '../../utils/intlError';
import { RouterStateContext, RouterStateContextProps } from '../router/RouterStateContext';
import { useRouter } from '../router/useRouter';
import { getDefaultMode, ProfileModalMode } from './helpers';
import {
  CONTEXT_NAME,
  ProfileContext,
  ProfileContextProps,
  ProfileContextState,
  ProfileModalType,
  ProfileSchoolRelation,
} from './ProfileContext';

export interface WithProfileProps extends PropsWithChildren {
  id?: string;
  userType: UserType;
  context?: UserContexts;
  onClose?: () => void;
}

export function mapRelationToUser(relation?: AnySchoolRelation): SyncUser {
  if ((relation as ParentSchoolRelation).children) {
    const children =
      ((relation as ParentSchoolRelation)?.children as unknown as AdultAssociation[]) ?? [];

    return { ...relation, adult_associations: children } as SyncUser;
  }

  return relation as unknown as SyncUser;
}

export const WithProfile: FC<WithProfileProps> = ({
  id: propId,
  userType,
  context,
  onClose,
  children,
}) => {
  const dispatch = useAppDispatch();
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  const location = useAppLocation();
  const { closeAndClean } = useRouter();
  const [searchParams] = useSearchParams();
  const { showError, showNotification } = useNotifications();

  const [openedModal, setOpenedModal] = useState<ProfileModalType | null>(null);
  const profileId = propId || paramId || '';

  const { logout } = useUserContext();
  const { setState, setContextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<ProfileContextState>;
  const updateUser = useUpdateSchoolUserMutation();

  const invalidateQueries = useInvalidateListQueriesFor(userType);
  const invalidateSchoolMembershipQuery = useInvalidateSchoolMembershipQuery();
  const shouldQueryUser = isProfileUserType(userType) || context === UserContexts.Individual;
  const updateSchoolMembership = useUpdateSchoolMembershipMutation();
  const {
    data,
    isLoading: isLoadingUser,
    error: getUserError,
  } = useGetUserQuery(
    { userId: profileId },
    { enabled: shouldQueryUser, refetchOnMount: 'always' },
  );

  const { schoolId = '', currentUser, permissions } = useAuth();
  const {
    data: schoolMembership,
    isLoading: isLoadingMembership,
    error: getMembershipError,
  } = useGetMembership(
    userType,
    {
      schoolId,
      id: profileId,
    },
    { enabled: !shouldQueryUser },
  );

  const isMembershipFetched = !!schoolMembership;

  const user = useMemo(() => {
    if (isLoadingMembership) return undefined;
    if (!schoolMembership) return data;

    return mapRelationToUser(schoolMembership);
  }, [data, isLoadingMembership, schoolMembership]);

  // sync user with context state
  useEffect(() => {
    if (!user) return;

    setState({
      user,
    });
  }, [user, setState]);

  const schoolRelations = useMemo(() => {
    if (!schoolMembership) return;

    if ('school_relations' in schoolMembership && schoolMembership.school_relations.length > 1) {
      const schoolRelations: ProfileSchoolRelation[] = schoolMembership.school_relations.map(
        (rel) => ({
          ...rel,
          userType: SchoolUserRole[rel.role].toLowerCase() as SchoolUserType,
        }),
      );
      return schoolRelations;
    }
  }, [schoolMembership]);

  const mode = getDefaultMode(location);

  const suggestedChanges =
    (schoolMembership as unknown as AnySchoolRelation)?.suggested_changes ?? [];

  const userContext = useMemo<UserContexts>(() => {
    if (searchParams.get('context')) {
      return Number(searchParams.get('context'));
    }

    if (context != null) {
      return context;
    }

    return UserContexts.School;
  }, [context, searchParams]);

  const isSchoolContext = userContext === UserContexts.School;
  const isIndividualContext = userContext === UserContexts.Individual;

  // Can be userId or relationId
  const actualId = schoolMembership?.relation_id ?? profileId;
  const isSchoolUser = Boolean(userType && isSchoolUserType(userType));
  const isFetching = isSchoolUser ? isLoadingMembership : isLoadingUser;
  const showLoader = Boolean(isFetching);

  const isChildOfCurrentUser = useMemo(() => {
    const isChildOfUser = Boolean(
      user?.child_associations?.find((a) => a.user_id === currentUser?.user_id),
    );

    return userType === 'child' && isChildOfUser && userContext === UserContexts.Individual;
  }, [currentUser?.user_id, user?.child_associations, userType, userContext]);

  const canEditProfile = Boolean(user?.can_be_edited);

  const hasEditingPermission = useMemo(() => {
    if (isChildOfCurrentUser) {
      return canEditProfile;
    }

    switch (userType) {
      case 'student':
        return permissions.includes('student_manager');
      case 'parent':
        return permissions.includes('parent_manager');
      case 'staff':
        return permissions.includes('staff_manager');
      case 'child':
      case 'adult':
        return permissions.includes('profile_manager');
      case 'profile':
        return true;
      default:
        return false;
    }
  }, [isChildOfCurrentUser, userType, canEditProfile, permissions]);

  const hasViewerPermission = useMemo(() => {
    if (isChildOfCurrentUser) {
      return true;
    }

    switch (userType) {
      case 'student':
        return permissions.includes('student_viewer');
      case 'parent':
        return permissions.includes('parent_viewer');
      case 'staff':
        return permissions.includes('staff_viewer');
      case 'child':
      case 'adult':
        return permissions.includes('profile_viewer');
      case 'profile':
        return true;
      default:
        return false;
    }
  }, [isChildOfCurrentUser, userType, permissions]);

  const canEditProfilePicture = useMemo(() => {
    if (!userType) {
      return false;
    }

    if (userType === 'profile') {
      return canEditProfile;
    }

    return getIsEditableForProfilePicture(
      userType,
      hasEditingPermission,
      canEditProfile,
      userContext,
    );
  }, [canEditProfile, hasEditingPermission, userType, userContext]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      closeAndClean();
    }
  }, [closeAndClean, onClose]);

  const handleLogOut = useCallback(() => {
    handleClose();

    setTimeout(async () => {
      logout();
      clearReactQueryStorageCache();
      navigate('/');
    }, MODAL_FADEOUT_DURATION_MS);
  }, [handleClose, logout, navigate]);

  const handleUserDelete = useCallback(() => {
    dispatch(modalActions.remove());
  }, [dispatch]);

  const contextActions = useMemo<ProfileContextProps['contextActions']>(() => {
    if (userType === 'profile') {
      return [
        {
          titleTextId: 'login-ChangePassword',
          handler: () => setOpenedModal(ProfileModalType.PasswordChange),
        },
        {
          titleTextId: 'login-CloseAccount',
          handler: () => setOpenedModal(ProfileModalType.CloseAccount),
        },
        {
          titleTextId: 'login-Logout',
          handler: handleLogOut,
        },
      ];
    }

    if (canEditProfile && hasEditingPermission) {
      return [
        {
          titleTextId: schoolMembership ? 'people-DeregisterPerson' : 'people-DeleteUser',
          handler: () => setOpenedModal(ProfileModalType.DeleteAccount),
        },
      ];
    }

    return [];
  }, [canEditProfile, handleLogOut, hasEditingPermission, schoolMembership, userType]);

  const updateProfile = useCallback(
    async (update: Partial<SyncUser>) => {
      if (!userType || !schoolId) {
        return;
      }

      let res: SyncUser | undefined;

      try {
        res = await updateUser.mutateAsync({
          userId: user?.user_id || '',
          schoolId: userContext === UserContexts.School ? schoolId : undefined,
          update,
        });
      } catch (err) {
        showError(err as ApiError | IntlError);
      }

      if (isSchoolUserType(userType)) {
        invalidateSchoolMembershipQuery({ id: actualId, userType });
      }
      invalidateQueries();
      return res;
    },
    [
      userType,
      schoolId,
      invalidateQueries,
      invalidateSchoolMembershipQuery,
      userContext,
      updateUser,
      actualId,
      user?.user_id,
      showError,
    ],
  );

  const updateAvatar = useCallback(
    async (avatar: UploadFile) => {
      switch (userType) {
        case 'student':
        case 'staff':
        case 'parent': {
          if (!schoolMembership) return;

          try {
            const res = await updateSchoolMembership.mutateAsync({
              relationId: schoolMembership.relation_id,
              userType,
              update: { avatar },
            });

            if (!res) return;

            let textId = 'confirmation-StudentAvatarEdit';
            if (userType === 'staff') {
              textId = 'confirmation-StaffAvatarEdit';
            }
            if (userType === 'parent') {
              textId = 'confirmation-ParentAvatarEdit';
            }
            invalidateQueries();
            showNotification({
              textId,
              type: 'success',
            });
          } catch (e) {
            showError(e as ApiError | IntlError);
          }
          break;
        }
        case 'profile':
        case 'child': {
          const res = await updateProfile({ avatar });
          if (!res) return;
          showNotification({
            textId: 'confirmation-ProfileAvatarEdit',
            type: 'success',
          });
          break;
        }
        default:
          break;
      }
    },
    [
      invalidateQueries,
      schoolMembership,
      showError,
      showNotification,
      updateProfile,
      updateSchoolMembership,
      userType,
    ],
  );

  const setMode = useCallback(
    (mode: ProfileModalMode) => {
      navigate({ hash: mode === ProfileModalMode.About ? '' : `#${mode}` });
    },
    [navigate],
  );

  useEffect(() => {
    setContextName(CONTEXT_NAME);
  }, [setContextName, setState]);

  const handleSchoolRoleChange = useCallback(
    async ({ userType: newUserType, ...relationData }: ProfileSchoolRelation) => {
      if (newUserType === userType) {
        return;
      }

      navigate(getRouteModalPathname(newUserType, relationData), { state: { replace: true } });
    },
    [navigate, userType],
  );

  const closeModal = useCallback(() => setOpenedModal(null), []);
  const invalidateProfileCache = useCallback(() => {
    queryClient.invalidateQueries([GET_USER_QUERY, user?.user_id]);
    if (isSchoolUserType(userType)) {
      invalidateSchoolMembershipQuery({ id: actualId, userType });
    }
  }, [actualId, invalidateSchoolMembershipQuery, user?.user_id, userType]);

  const value = {
    user,
    userType,
    schoolMembership,
    schoolRelations,
    userContext,
    mode,
    contextActions,
    suggestedChanges,
    isFetching,
    isMembershipFetched,
    isUpdating: updateUser.isLoading,
    isSchoolUser,
    isSchoolContext,
    isIndividualContext,
    showLoader,
    hasAccess: !getMembershipError && !getUserError,
    hasEditingPermission,
    hasViewerPermission,
    canEditProfile,
    canEditProfilePicture,
    isChildOfCurrentUser,
    openedModal,

    actions: {
      handleClose,
      handleLogOut,
      handleUserDelete,
      setMode,
      updateProfile,
      updateAvatar,
      closeModal,
      invalidateProfileCache,
      handleSchoolRoleChange,
    },
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

const useGetMembership = (
  userType: UserType,
  params: { id: string; schoolId: string },
  { enabled }: { enabled: boolean },
) => {
  const { id, schoolId } = params;
  const studentMembership = useGetStudentMembership(params, {
    refetchOnMount: 'always',
    enabled: enabled && userType === 'student' && !!schoolId && !!id,
  });
  const staffMembership = useGetStaffMembership(params, {
    refetchOnMount: 'always',
    enabled: enabled && userType === 'staff' && !!schoolId && !!id,
  });
  const parentMembership = useGetParentMembership(params, {
    refetchOnMount: 'always',
    enabled: enabled && userType === 'parent' && !!schoolId && !!id,
  });

  switch (userType) {
    case 'staff':
      return staffMembership;
    case 'student':
      return studentMembership;
    case 'parent':
      return parentMembership;
    default:
      return {
        data: undefined,
        isLoading: false,
        isFetching: false,
        error: undefined,
      };
  }
};
