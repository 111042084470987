import React, { useEffect } from 'react';

// This effect implements closing menus by clicking outside of them
// without adding extra elements to the DOM
export default function useVirtualBackdrop(
  isOpen: boolean,
  onClose: () => void,
  menuRef: React.RefObject<HTMLElement>,
  wrapperRef?: React.RefObject<HTMLElement>,
) {
  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      const menu = menuRef.current;
      const wrapper = wrapperRef?.current;
      const target = e.target as HTMLElement | null;
      if (!menu || !target) return;

      const clickedInsideWrapper = wrapper?.contains(target);
      const clickedInsideMenu = menu.contains(target);
      if (clickedInsideWrapper || clickedInsideMenu) return;

      e.preventDefault();
      e.stopPropagation();
      onClose();
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleMouseDown);
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('click', handleMouseDown);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, menuRef, onClose, wrapperRef]);
}
