import { SearchIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export interface IHeaderSearchFooterProps {
  text: JSX.Element | string;
}

export const HeaderSearchFooter: FC<IHeaderSearchFooterProps> = ({ text }) => (
  <div className="filters-footer bg-white pt-2 pb-2 pr-3 pl-3">
    <div className="icon-wrapper">
      <SearchIcon className="icon" />
    </div>
    <h3 className="hint mb-0">{text}</h3>
    <h3 className="hint-action mb-0 text-right">
      <FormattedMessage id="action-PressKey" values={{ key: 'ENTER' }} />
    </h3>
  </div>
);
