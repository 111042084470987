import { alpha, Box, Stack, styled, Tooltip, Typography } from '@mui/material';
import { AnnualPlanRecordTypes } from '@schooly/api';
import * as React from 'react';

import {
  ANNUAL_PLANNER_CALENDAR_COLS,
  ANNUAL_PLANNER_CELL_HEIGHT,
  ANNUAL_PLANNER_DAY_CELL_WIDTH,
} from './scheme';

export const AnnualPlannerCalendarGrid = styled(Box)({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: `${ANNUAL_PLANNER_DAY_CELL_WIDTH}px repeat(${ANNUAL_PLANNER_CALENDAR_COLS}, 1fr)`,
  gridTemplateRows: 'repeat(32, 1fr)',
  height: '100%',
  minHeight: 32 * ANNUAL_PLANNER_CELL_HEIGHT,
  userSelect: 'none',
});

AnnualPlannerCalendarGrid.defaultProps = {
  className: 'AnnualPlannerCalendar-grid',
};

export const AnnualPlannerCalendarCell = styled(Box)({
  height: '100%',
  lineHeight: 1,
});

AnnualPlannerCalendarCell.defaultProps = {
  className: 'AnnualPlannerCalendar-cell',
};

export const AnnualPlannerCalendarRecords = styled(Stack)({
  position: 'absolute',
  top: `${100 / 32}%`,
  right: 0,
  left: ANNUAL_PLANNER_DAY_CELL_WIDTH,
  bottom: 0,
  pointerEvents: 'none',
});

AnnualPlannerCalendarRecords.defaultProps = {
  className: 'AnnualPlannerCalendarRecords-root',
};

export const AnnualPlannerCalendarRecordsHorizontal = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  marginLeft: ANNUAL_PLANNER_DAY_CELL_WIDTH + 1,
  marginRight: 1,
  gap: ANNUAL_PLANNER_DAY_CELL_WIDTH,
}));

AnnualPlannerCalendarRecordsHorizontal.defaultProps = {
  className: 'AnnualPlannerCalendarRecords-horizontal',
};

export interface AnnualPlannerCalendarRecordCellProps {
  type?: AnnualPlanRecordTypes;
  incomplete?: boolean;
}

export const AnnualPlannerCalendarRecordCell = styled(Box)<AnnualPlannerCalendarRecordCellProps>(
  ({ theme, type, incomplete }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    borderRadius: 2,
    width: '100%',
    minHeight: ANNUAL_PLANNER_CELL_HEIGHT - 1,
    padding: theme.spacing('3px', 0.25, '3px', 0.5),
    overflow: 'hidden',
    fontSize: 10,
    lineHeight: `11px`,
    mixBlendMode: 'multiply',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '&::before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: theme.spacing(0.25),
      content: '""',

      ...(() => {
        switch (type) {
          case AnnualPlanRecordTypes.EVENT:
            return {
              backgroundColor: theme.palette.common.moss1,
            };
          case AnnualPlanRecordTypes.HOLIDAY:
            return {
              backgroundColor: theme.palette.common.green1,
            };
          case AnnualPlanRecordTypes.ASSESSMENT:
            return {
              backgroundColor: theme.palette.common.dijon1,
            };
          case AnnualPlanRecordTypes.REPORT:
            return {
              backgroundColor: theme.palette.common.wine1,
            };
          case AnnualPlanRecordTypes.SCHOOL_PERIOD:
          default:
            return {
              backgroundColor: theme.palette.primary.main,
            };
        }
      })(),
    },

    ...(() => {
      switch (type) {
        case AnnualPlanRecordTypes.EVENT:
          return {
            color: theme.palette.common.moss1,
            borderTop: incomplete ? `1px solid ${theme.palette.common.moss1}` : undefined,
            borderRight: incomplete ? `1px solid ${theme.palette.common.moss1}` : undefined,
            borderBottom: incomplete ? `1px solid ${theme.palette.common.moss1}` : undefined,
            backgroundColor: incomplete ? undefined : alpha(theme.palette.common.moss4, 0.5),
          };
        case AnnualPlanRecordTypes.HOLIDAY:
          return {
            color: theme.palette.common.green1,
            borderTop: incomplete ? `1px solid ${theme.palette.common.green1}` : undefined,
            borderRight: incomplete ? `1px solid ${theme.palette.common.green1}` : undefined,
            borderBottom: incomplete ? `1px solid ${theme.palette.common.green1}` : undefined,
            backgroundColor: incomplete ? undefined : alpha(theme.palette.common.green4, 0.5),
          };
        case AnnualPlanRecordTypes.ASSESSMENT:
          return {
            color: theme.palette.common.dijon1,
            borderTop: incomplete ? `1px solid ${theme.palette.common.dijon1}` : undefined,
            borderRight: incomplete ? `1px solid ${theme.palette.common.dijon1}` : undefined,
            borderBottom: incomplete ? `1px solid ${theme.palette.common.dijon1}` : undefined,
            backgroundColor: incomplete ? undefined : alpha(theme.palette.common.dijon4, 0.5),
          };
        case AnnualPlanRecordTypes.REPORT:
          return {
            color: theme.palette.common.wine1,
            borderTop: incomplete ? `1px solid ${theme.palette.common.wine1}` : undefined,
            borderRight: incomplete ? `1px solid ${theme.palette.common.wine1}` : undefined,
            borderBottom: incomplete ? `1px solid ${theme.palette.common.wine1}` : undefined,
            backgroundColor: incomplete ? undefined : alpha(theme.palette.common.wine4, 0.5),
          };
        case AnnualPlanRecordTypes.SCHOOL_PERIOD:
        default:
          return {
            color: theme.palette.primary.main,
            borderTop: incomplete ? `1px solid ${theme.palette.primary.main}` : undefined,
            borderRight: incomplete ? `1px solid ${theme.palette.primary.main}` : undefined,
            borderBottom: incomplete ? `1px solid ${theme.palette.primary.main}` : undefined,
            backgroundColor: incomplete ? undefined : alpha(theme.palette.common.main4, 0.3),
          };
      }
    })(),
  }),
);

AnnualPlannerCalendarRecordCell.defaultProps = {
  className: 'AnnualPlannerCalendarRecord-cell',
};

export const AnnualPlannerCalendarRecordTitle = styled(Stack)<AnnualPlannerCalendarRecordCellProps>(
  ({ theme, type }) => ({
    alignItems: 'center',
    position: 'relative',
    marginLeft: theme.spacing(0.25),
    padding: theme.spacing(0, 0.25),
    borderTopRightRadius: theme.spacing(0.25),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: 1,

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      mixBlendMode: 'multiply',
    },

    ...(() => {
      switch (type) {
        case AnnualPlanRecordTypes.EVENT:
          return {
            color: theme.palette.common.moss1,
          };
        case AnnualPlanRecordTypes.HOLIDAY:
          return {
            color: theme.palette.common.green1,
          };
        case AnnualPlanRecordTypes.ASSESSMENT:
          return {
            color: theme.palette.common.dijon1,
          };
        case AnnualPlanRecordTypes.REPORT:
          return {
            color: theme.palette.common.wine1,
          };
        case AnnualPlanRecordTypes.SCHOOL_PERIOD:
        default:
          return {
            color: theme.palette.primary.main,
          };
      }
    })(),
  }),
);

AnnualPlannerCalendarRecordTitle.defaultProps = {
  className: 'AnnualPlannerCalendarRecord-title',
  direction: 'row',
};

export const AnnualPlannerCalendarRecordEnd = styled(AnnualPlannerCalendarRecordTitle)(
  ({ theme }) => ({
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: theme.spacing(0.25),
  }),
);

AnnualPlannerCalendarRecordEnd.defaultProps = {
  ...AnnualPlannerCalendarRecordTitle.defaultProps,
  className: `${AnnualPlannerCalendarRecordTitle.defaultProps.className} AnnualPlannerCalendarRecord-end`,
};

export const AnnualPlannerCalendarRecordTitleTypography = styled(Typography)<{
  component?: React.ElementType;
}>(({ theme }) => ({
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: 1,
  zIndex: 1,
}));

AnnualPlannerCalendarRecordTitleTypography.defaultProps = {
  className: 'AnnualPlannerCalendarRecord-titleTypography',
  component: Box,
  variant: 'caption',
  color: 'inherit',
};

export const AnnualPlannerCalendarRecordGroup = styled(Box)<AnnualPlannerCalendarRecordCellProps>(
  ({ theme, type }) => ({
    '&:hover, &.AnnualPlannerCalendarRecordGroup-highlight, &.AnnualPlannerCalendarRecordGroup-active':
      {
        '& .AnnualPlannerCalendarRecord-cell': {
          ...(() => {
            switch (type) {
              case AnnualPlanRecordTypes.EVENT:
                return {
                  backgroundColor: alpha(theme.palette.common.moss3, 0.5),
                };
              case AnnualPlanRecordTypes.HOLIDAY:
                return {
                  backgroundColor: alpha(theme.palette.common.green3, 0.5),
                };
              case AnnualPlanRecordTypes.ASSESSMENT:
                return {
                  backgroundColor: alpha(theme.palette.common.dijon3, 0.5),
                };
              case AnnualPlanRecordTypes.REPORT:
                return {
                  backgroundColor: alpha(theme.palette.common.wine3, 0.5),
                };
              case AnnualPlanRecordTypes.SCHOOL_PERIOD:
              default:
                return {
                  backgroundColor: alpha(theme.palette.common.main3, 0.3),
                };
            }
          })(),
        },
      },

    '&.AnnualPlannerCalendarRecordGroup-active': {
      '& .AnnualPlannerCalendarRecord-title': {
        color: theme.palette.common.white,
        zIndex: 9999,

        '&::before': {
          ...(() => {
            switch (type) {
              case AnnualPlanRecordTypes.EVENT:
                return {
                  backgroundColor: theme.palette.common.moss1,
                };
              case AnnualPlanRecordTypes.HOLIDAY:
                return {
                  backgroundColor: theme.palette.common.green1,
                };
              case AnnualPlanRecordTypes.ASSESSMENT:
                return {
                  backgroundColor: theme.palette.common.dijon1,
                };
              case AnnualPlanRecordTypes.REPORT:
                return {
                  backgroundColor: theme.palette.common.wine1,
                };
              case AnnualPlanRecordTypes.SCHOOL_PERIOD:
              default:
                return {
                  backgroundColor: theme.palette.common.main1,
                };
            }
          })(),
        },
      },
    },
  }),
);

AnnualPlannerCalendarRecordGroup.defaultProps = {
  className: 'AnnualPlannerCalendarRecordGroup-root',
};

export const AnnualPlannerCalendarPopoverType = styled(Stack)<AnnualPlannerCalendarRecordCellProps>(
  ({ theme, type, incomplete }) => ({
    alignSelf: 'flex-start',

    ...(() => {
      switch (type) {
        case AnnualPlanRecordTypes.EVENT:
          return {
            color: theme.palette.common.moss1,
          };
        case AnnualPlanRecordTypes.HOLIDAY:
          return {
            color: theme.palette.common.green1,
          };
        case AnnualPlanRecordTypes.ASSESSMENT:
          return {
            color: theme.palette.common.dijon1,
          };
        case AnnualPlanRecordTypes.REPORT:
          return {
            color: theme.palette.common.wine1,
          };
        case AnnualPlanRecordTypes.SCHOOL_PERIOD:
        default:
          return {
            color: theme.palette.primary.main,
          };
      }
    })(),

    '&:before': {
      content: '""',
      display: 'block',
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: '50%',

      ...(incomplete
        ? {
            border: `1px solid currentColor`,
          }
        : {
            background: 'currentColor',
          }),
    },
  }),
);

AnnualPlannerCalendarPopoverType.defaultProps = {
  direction: 'row',
  alignItems: 'center',
  gap: 1,
};

export const AnnualPlannerCalendarDateTooltip = styled(Tooltip)();

AnnualPlannerCalendarDateTooltip.defaultProps = {
  className: 'AnnualPlannerCalendarDateTooltip-root',
  arrow: false,
  disableInteractive: true,
  PopperProps: {
    sx: {
      '& .MuiTooltip-tooltip': {
        px: 0.5,
        py: 0.25,
        borderRadius: 0.5,
        boxShadow: ['0px 2px 10px 2px #24275B1A', '0px 2px 4px 1px #24275B1A'],
      },
    },
  },
};
