import { SchoolYear } from '@schooly/api';
import { Preset } from '@schooly/components/recurring';
import { DATE_FORMAT } from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, isAfter } from 'date-fns';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

type UseSchoolYearPresetsProps = {
  years: SchoolYear[];
  startDate: Date;
};

export const useSchoolYearPresets = ({ startDate, years }: UseSchoolYearPresetsProps) => {
  const { $t } = useIntl();
  return useMemo(
    () =>
      years.reduce<Array<Preset>>((acc, { end, period_groups, id }) => {
        if (isAfter(startDate, newDateTimezoneOffset(end))) return acc;

        const year = {
          label: $t({ id: 'school-schoolPeriods-EndOfYear' }),
          id: id,
          tagLabel: format(newDateTimezoneOffset(end), DATE_FORMAT),
          value: end,
        };

        const endOfPeriods =
          period_groups?.flatMap((g) => {
            const actualPeriods = g.periods.filter(
              (p) => !isAfter(startDate, newDateTimezoneOffset(p.date_to)),
            );
            return actualPeriods.map((p) => ({
              label: $t(
                { id: 'school-schoolPeriods-EndOfPeriod' },
                {
                  period: p.name,
                },
              ),
              id: p.id,
              value: p.date_to,
            }));
          }) ?? [];

        return [...acc, year, ...endOfPeriods];
      }, []) ?? [],
    [$t, startDate, years],
  );
};
