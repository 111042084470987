import { Box } from '@mui/material';
import { Event, GET_EVENT_QUERY, SignUp } from '@schooly/api';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback } from 'react';

import {
  SignUpPreviewActions,
  SignUpPreviewActionsProps,
} from '../../SignUps/SignUpPreviewModal/SignUpPreviewActions';

export interface EventPreviewSignUpsStatusProps {
  event: Event;
  signUp: SignUp;
}

export const EventPreviewSignUpsStatus: FC<EventPreviewSignUpsStatusProps> = ({
  event,
  signUp,
}) => {
  const queryClient = useQueryClient();

  const handleChangeStatus = useCallback<
    Exclude<SignUpPreviewActionsProps['onChangeStatus'], undefined>
  >(
    (status) => {
      // looking for a data if the event in a cache
      const queryKey = [GET_EVENT_QUERY, { eventId: event?.id }];
      const eventData = queryClient.getQueryData(queryKey) as Event;

      // no need to do anything if no data in the cache
      if (!eventData) {
        return;
      }

      // override sign up's new status in the cache
      queryClient.setQueryData(queryKey, {
        ...eventData,
        sign_ups: eventData.sign_ups?.map((item) =>
          item.id === signUp.id ? { ...item, status } : item,
        ),
      });
    },
    [event?.id, queryClient, signUp],
  );

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      sx={{
        '& .HeaderActions': {
          p: 0,
          border: 0,
          backgroundColor: 'unset',
        },
      }}
    >
      <SignUpPreviewActions onChangeStatus={handleChangeStatus} />
    </Box>
  );
};
