import { Icon, Stack, Typography } from '@mui/material';
import { NoteIcon, theme } from '@schooly/style';
import { FC, useState } from 'react';

import { ManageGroupModalTooltip } from '../../Groups/ManageGroupModal/ManageGroupModalTooltip';

interface ApplicationNoteProps {
  text: string;
}
export const ApplicationNote: FC<ApplicationNoteProps> = ({ text }) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      sx={(theme) => ({
        height: 32,
        width: 32,
        border: theme.mixins.borderValue(),
        borderColor: open ? theme.palette.common.light2 : 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: `${theme.shape.borderRadiusSm}px`,
        backgroundColor: open ? theme.palette.background.paper : undefined,
      })}
    >
      <ManageGroupModalTooltip
        text={<Typography color="primary.main">{text}</Typography>}
        open={open}
        offset={[-84, -9]}
        sx={{
          border: theme.mixins.borderValue(),
          padding: theme.spacing(1),
          width: 300,
        }}
      >
        <Icon
          sx={(theme) => ({
            color: open ? theme.palette.primary.main : theme.palette.common.grey,
          })}
        >
          <NoteIcon />
        </Icon>
      </ManageGroupModalTooltip>
    </Stack>
  );
};
