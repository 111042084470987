import {
  Assessment,
  AssessmentMethodType,
  useGetAssessmentListQuery,
  useGetAssessmentsForGroupEntriesQuery,
  useGetGroupDetailsQuery,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';

type UseAssessmentForGroupProps = {
  assessment: Assessment;
  groupId: string;
};

export const useAssessmentEntriesForGroup = ({
  assessment,
  groupId,
}: UseAssessmentForGroupProps) => {
  const { showError } = useNotifications();

  const {
    data: groupResponse,
    isLoading: isGroupLoading,
    isFetching: isGroupFetching,
  } = useGetGroupDetailsQuery(
    {
      id: groupId,
      date: assessment.assessment_date,
    },
    { onError: showError, refetchOnMount: 'always' },
  );

  const group = groupResponse ? groupResponse.group : undefined;

  const { data: entries, isLoading: isEntriesLoading } = useGetAssessmentsForGroupEntriesQuery(
    {
      assessment_ids: [assessment.id],
      group_id: groupId,
    },
    { enabled: !isGroupLoading && !isGroupFetching, onError: showError },
  );

  const assessmentForGroup = entries ? entries?.assessments?.[0] : undefined;

  const assessmentMethodId =
    assessment?.methods.find((method) => method.method_type === AssessmentMethodType.Grade)
      ?.select_list_id ?? '';

  const { data: assessmentGrade } = useGetAssessmentListQuery(assessmentMethodId, {
    enabled: !!assessmentMethodId,
  });

  return {
    group,
    isGroupLoading,
    isEntriesLoading,
    assessmentForGroup,
    assessmentGrade,
  };
};
