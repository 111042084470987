import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { WithMessage, WithMessageProps } from './WithMessage';

export const WithMessageWrapper: FC<WithMessageProps> = (props) => {
  const { id } = useParams<'id'>();

  return <WithMessage key={id} {...props} />;
};
