import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import { SYNC_USER_QUERY } from '@schooly/api';
import { CrossIcon, MoreIcon } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { AvatarEditable } from '../../../components/common/AvatarEditable';
import { UserRoleSelect } from '../../../components/common/UserTypeSelect/UserRoleSelect';
import ContextMenu from '../../../components/ui/ContextMenu';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import { useLastRefreshTimeContext } from '../../../context/LastRefreshTimeContext';
import { useProfile } from '../../../context/profile/useProfile';
import { useRouter } from '../../../context/router/useRouter';
import { getUserTypeTextId, isProfileUserType } from '../../../helpers/misc';
import renderUserTypeIcon from '../../../helpers/renderUserTypeIcon';
import { getUserInitials } from '../../../helpers/users';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import { queryClient } from '../../../queryClient';
import { useApplicationFormParentUpdate } from '../../Applications/useApplicationFormParentUpdate';
import NameProperty from './NameProperty';
import ProfileHeaderButtons from './ProfileHeaderButtons';

export const ProfileModalHeader: FC = () => {
  const { updateLastRefreshTime } = useLastRefreshTimeContext();
  const { stack } = useRouter();
  const {
    user,
    userType,
    contextActions,
    canEditProfile,
    canEditProfilePicture,
    isSchoolContext,
    isFetching,
    hasAccess,
    actions,
    schoolRelations,
  } = useProfile();

  const isContextMenuAvailable = (() => {
    if (!userType) {
      return false;
    }

    if (isProfileUserType(userType)) {
      return canEditProfile;
    }

    return true;
  })();

  useApplicationFormParentUpdate(userType === 'parent' && user ? user : undefined);

  const [handleAvatarChange, isLoading] = useRequestWithProgress(actions.updateAvatar);

  const withGoBack = stack.length > 1;

  const onUploadComplete = useCallback(() => {
    if (userType === 'profile') {
      queryClient.invalidateQueries([SYNC_USER_QUERY]);
    } else {
      updateLastRefreshTime();
    }
  }, [userType, updateLastRefreshTime]);

  const userIcon = userType ? renderUserTypeIcon(userType) : null;

  if (!user || !userType) {
    return null;
  }

  return (
    <ModalHeaderV2
      active
      title={
        <Box
          sx={{
            '.profile-property': {
              p: 0,
              width: 'unset',
              '.property-value': { mr: 1 },
            },
          }}
        >
          <NameProperty size="md" color="primary" />
        </Box>
      }
      avatar={
        <AvatarEditable
          key={user.profile_picture}
          currentAvatarUrl={user.profile_picture}
          className="mb-0"
          isLoading={isLoading}
          onChange={handleAvatarChange}
          initials={getUserInitials(user)}
          user={user}
          onUploadComplete={onUploadComplete}
          canEdit={canEditProfilePicture}
        />
      }
      withGoBack={withGoBack}
      subHeader={
        <Stack direction="row" gap={3}>
          {userType !== 'profile' && !schoolRelations && isSchoolContext && (
            <Stack direction="row" gap={0.25} alignItems="center">
              {userIcon && <Icon fontSize="small">{userIcon}</Icon>}
              <Typography color="text.primary">
                <FormattedMessage id={getUserTypeTextId(userType)} />
              </Typography>
            </Stack>
          )}
          {schoolRelations && (
            <UserRoleSelect
              selected={userType}
              onSelect={actions.handleSchoolRoleChange}
              list={schoolRelations}
            />
          )}
        </Stack>
      }
    >
      {isSchoolContext && <ProfileHeaderButtons userType={userType} />}
      {!isFetching && isContextMenuAvailable && hasAccess ? (
        <ContextMenu
          trigger={({ toggleOpen }) => (
            <IconButton onClick={toggleOpen}>
              <MoreIcon />
            </IconButton>
          )}
          actions={contextActions}
          position="bottom left"
        />
      ) : null}

      <IconButton onClick={actions.handleClose}>
        <CrossIcon />
      </IconButton>
    </ModalHeaderV2>
  );
};
