import { ApiError } from '@schooly/api';
import { CreateChildForm, CreateParentForm } from '@schooly/components/applications';
import { CreateCustomField } from '@schooly/components/applications';
import { useNotifications } from '@schooly/components/notifications';
import { ModalLarge } from '@schooly/style';
import { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useApplication } from '../../../context/applications/useApplication';
import { useRouter } from '../../../context/router/useRouter';
import { useApplicationForm } from '../useApplicationForm';
import { ApplicationEditablePreviewContainer } from './ApplicationEditablePreviewContainer';

export const ApplicationUpdatePreviewModal: FC = () => {
  const {
    addChild,
    addParents,
    addGeneralCustomFields,
    parents,
    children,
    generalCustomFields,
    resetForm,
    prepareFormData,
  } = useApplicationForm('update');

  const { applicationId, updateApplication, fetching, canEdit, application } = useApplication();

  const { showNotification, showError } = useNotifications();
  const { $t } = useIntl();

  const { goBack } = useRouter();
  const navigate = useNavigate();

  const handleConvert = useCallback(async () => {
    try {
      await updateApplication({ children, parents, generalCustomFields });

      navigate(`/applications/${applicationId}/convert`, {
        replace: true,
      });

      resetForm();
    } catch (error) {
      showError(error as ApiError);
    }
  }, [
    children,
    navigate,
    parents,
    generalCustomFields,
    applicationId,
    showError,
    resetForm,
    updateApplication,
  ]);

  const handleSave = useCallback(async () => {
    await updateApplication({ children, parents, generalCustomFields });

    navigate(`/applications`);

    if (applicationId) {
      navigate(`/applications`);
      showNotification({
        message: $t({ id: 'applications-SuccessfulUpdate' }),
        type: 'success',
        actions: [
          {
            textId: 'applications-Action-View',
            handler: () =>
              navigate(`/applications/${applicationId}`, {
                replace: true,
              }),
            buttonColor: 'light',
          },
        ],
      });
    }
    resetForm();
  }, [
    $t,
    applicationId,
    children,
    navigate,
    parents,
    generalCustomFields,
    resetForm,
    showNotification,
    updateApplication,
  ]);

  const onChildSubmit = useCallback(
    (data: CreateChildForm) => {
      addChild(data);
    },
    [addChild],
  );

  const onParentSubmit = useCallback(
    (data: CreateParentForm) => {
      addParents([data]);
    },
    [addParents],
  );

  const onGeneralCustomFieldsSubmit = useCallback(
    (data: CreateCustomField[]) => {
      addGeneralCustomFields(data);
    },
    [addGeneralCustomFields],
  );

  useEffect(() => {
    if (!application || fetching) {
      return;
    }
    if (!children.length && !parents.length) {
      prepareFormData(application);
    }
  }, [application, children, fetching, parents, prepareFormData]);

  return (
    <ModalLarge open>
      <ApplicationEditablePreviewContainer
        onChildSubmit={onChildSubmit}
        onParentSubmit={onParentSubmit}
        onGeneralCustomFieldsSubmit={onGeneralCustomFieldsSubmit}
        onClose={goBack}
        onSave={handleSave}
        onConvert={handleConvert}
        pending={fetching}
        formData={{ children, parents, generalCustomFields }}
        onGoBack={goBack}
        canEdit={canEdit}
        withSaveButton
      />
    </ModalLarge>
  );
};
