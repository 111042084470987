import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { ChartIcon } from '@schooly/style';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChartBarEmpty } from './ChartBarEmpty';
import { SelectArrangeBy } from './SelectArrangeBy';

type ChartsContainerProps = PropsWithChildren<{
  isEmpty: boolean;
  isLoading: boolean;
  zeroValuesToggler?: ReactNode;
  arrangeByDropdown?: ReactNode;
  arrangeBy?: FilterKeys | null;
  onToggleChartsOpened: () => void;
  onOpenArrangeByDropdown?: () => void;
}>;
export const ChartsContainer: FC<ChartsContainerProps> = ({
  children,
  zeroValuesToggler,
  arrangeByDropdown,
  arrangeBy,
  isLoading,
  isEmpty,
  onToggleChartsOpened,
  onOpenArrangeByDropdown,
}) => {
  const renderContent = () => {
    if (isLoading) return <ChartBarEmpty pending />;

    if (!arrangeBy)
      return (
        <ChartBarEmpty>
          <SelectArrangeBy onSelect={onOpenArrangeByDropdown} />
        </ChartBarEmpty>
      );

    if (isEmpty)
      return (
        <ChartBarEmpty>
          <Stack height="100%" alignItems="center" justifyContent="center" gap={2.5}>
            <Box maxWidth={300}>
              <Typography
                variant="h1"
                textAlign="center"
                sx={{
                  fontStyle: 'normal',
                }}
              >
                <FormattedMessage id="chartNoData" />
              </Typography>
            </Box>
          </Stack>
        </ChartBarEmpty>
      );

    return children;
  };

  return (
    <Stack>
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          height: theme.spacing(3.5),
          gap: 2.5,
          justifyContent: 'flex-end',
          alignItems: 'center',
          pr: 1,
        })}
      >
        {zeroValuesToggler}
        {arrangeByDropdown}

        <IconButton onClick={onToggleChartsOpened}>
          <ChartIcon />
        </IconButton>
      </Stack>

      {renderContent()}

      {!!arrangeBy && <Divider sx={{ mt: 2 }} />}
    </Stack>
  );
};
