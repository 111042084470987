import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReports } from '@schooly/api';
import { ApiError, PagedResponse } from '@schooly/api';
import { ListReportsForSchool, Report } from '@schooly/api';

export interface ReportsListState extends PagedResponse<Report> {
  fetching: boolean;
  error?: ApiError;
  canShowMore?: boolean;
}

export const initialState: ReportsListState = {
  count: 0,
  results: [],
  fetching: false,
  error: undefined,
  canShowMore: false,
};

export const extraActions = {
  get: createAsyncThunk('reports/list/get', (payload: ListReportsForSchool) => getReports(payload)),
};

const slice = createSlice({
  name: 'reports/list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => ({
        ...state,
        fetching: true,
        count: state?.results?.length ?? 0,
        error: undefined,
      }))
      .addCase(extraActions.get.fulfilled, (state, action) => {
        const results = [
          ...(action.meta.arg.page_number && action.meta.arg.page_number > 1
            ? state?.results ?? []
            : []),
          ...action.payload.results,
        ];

        return {
          ...state,
          ...action.payload,
          results: action.payload.results,
          count: action.payload.results.length ?? 0,
          fetching: false,
          canShowMore: Boolean(
            results.length && action.payload.current_page !== action.payload.total_pages,
          ),
        };
      })
      .addCase(extraActions.get.rejected, (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
      }));
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
