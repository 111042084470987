import { InboxMessage, Message } from '@schooly/api';
import React from 'react';

import { RichText } from '../../ui/Input';
import { AttachmentProps } from '../../ui/Input/RichText/Attachments/attachment-types';

import './index.scss';

interface IProps extends AttachmentProps {
  titleTextId?: string;
  title?: string;
  message: Message | InboxMessage;
}

const CreateMessagePreview: React.FC<IProps> = ({
  titleTextId,
  title,
  message,
  ...attachmentProps
}) => (
  <>
    <div>
      {(titleTextId || title) && <h2 className="CreateMessage__message__title h2">{title}</h2>}
    </div>
    <RichText value={message.body} readOnly {...attachmentProps} />
  </>
);

export default CreateMessagePreview;
