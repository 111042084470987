import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.016 2.27693C16.7182 2.10097 16.3709 2 16 2H4C2.89543 2 2 2.89543 2 4V16C2 16.3709 2.10097 16.7182 2.27693 17.016L17.016 2.27693ZM2.98405 17.7231C3.2818 17.899 3.62911 18 4 18H16C17.1046 18 18 17.1046 18 16V4C18 3.62911 17.899 3.2818 17.7231 2.98405L2.98405 17.7231Z"
      fill="#24275B"
    />
  </svg>
);
