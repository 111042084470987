import { CreateChildContent, CreateChildForm } from '@schooly/components/applications';
import { Loading, ModalSmall } from '@schooly/style';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useApplication } from '../../../context/applications/useApplication';
import { useRouter } from '../../../context/router/useRouter';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { useApplicationForm } from '../../../pages/Applications/useApplicationForm';

export const CreateChildModal = () => {
  const { childId } = useParams<'childId'>();
  const { fetching: loadingApplication } = useApplication();
  const { children, addChild, removeChild: removeChildAction } = useApplicationForm('create');
  const { applicationChildCustomFields, fetching: loadingCustomFields } = useCustomFields({
    refetchOnMount: 'always',
  });

  const navigate = useNavigate();
  const { goBack } = useRouter();

  const onSubmit = useCallback(
    (data: CreateChildForm) => {
      addChild(data);
      navigate('/applications/new');
    },
    [navigate, addChild],
  );

  const onRemoveChild = useCallback(
    (childId: string) => {
      removeChildAction(childId);
      navigate('/applications/new');
    },
    [navigate, removeChildAction],
  );

  const currentChild = useMemo(() => {
    if (childId) {
      return children?.find((c) => c.id === childId);
    }
  }, [children, childId]);

  return (
    <ModalSmall open>
      {loadingApplication || loadingCustomFields ? (
        <Loading />
      ) : (
        <CreateChildContent
          onClose={goBack}
          onSubmit={onSubmit}
          formCustomFields={applicationChildCustomFields}
          child={currentChild}
          onRemoveChild={onRemoveChild}
        />
      )}
    </ModalSmall>
  );
};
