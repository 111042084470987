import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalAccessDeniedStyled } from './ModalAccessDenied.styled';

export const ModalAccessDenied: FC = () => {
  return (
    <ModalAccessDeniedStyled>
      <picture>
        <source srcSet="/images/access-denied.png, /images/access-denied@2x.png 2x" />
        <img src="/images/access-denied.png" alt="Access denied" />
      </picture>
      <Typography variant="h1" mb={1.5}>
        <FormattedMessage id="accessDenied" />
      </Typography>
      <Typography variant="h3" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage id="pleaseContact" />
      </Typography>
    </ModalAccessDeniedStyled>
  );
};
