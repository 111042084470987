import { EventsInvite } from '@schooly/constants';
import { CancelToken } from 'axios';

import { ConsentPerson } from '../../consentForms';
import { GetEventsQueryFilters } from '../../events';
import { Event } from '../events';
import { NumberOrNull } from '../misc';
import { SignUp, SignUpStatuses, SignUpType } from '../signups';
import { FilterKeys } from '../users';
import { IColumnSort, SimpleListResult } from './people';

export const SIGN_UPS_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.SignUpStatus,
  FilterKeys.SignUpType,
  FilterKeys.InviteType,
] as const;

export type GetSignUpsListQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.SignUpStatus]?: SignUpStatuses[];
  [FilterKeys.SignUpType]?: SignUpType[];
  [FilterKeys.InviteType]?: EventsInvite[];
};

export type GetSignUpsListSort = IColumnSort<keyof Pick<SignUp, 'end'>>;

export interface GetSignUpsListRequest {
  schoolId: string;
  query?: string;
  filters?: GetSignUpsListQueryFilters;
  sort?: GetSignUpsListSort[];
  pageSize?: number;
  pageNumber?: number;
  token?: CancelToken;
}

export interface SignUpFormRequest {
  title: string;
  description?: string;
  type: SignUpType;
  end?: string;
  duration?: number;
  places?: NumberOrNull;
  invitee_type?: EventsInvite;
  event_id?: Event['id'];
  criteria?: Omit<Event['criteria'], 'groups'> & {
    group?: Event['criteria']['groups'];
  };
  notify_parents?: boolean;
}

export interface CreateSignUpRequest extends SignUpFormRequest {
  schoolId: string;
}

export interface UpdateSignUpRequest extends SignUpFormRequest {
  id: SignUp['id'];
  notify_parents?: boolean;
}

export interface SignUpActionRequest {
  schoolId: string;
  sign_up_id: string;
  action: 'open' | 'draft' | 'close';
  query?: string;
  filters?: Partial<GetEventsQueryFilters>;
  notify_parents?: boolean;
}
export interface SignUpActionResponse {
  success: string;
}

export interface GetSignUpRespondentsRequest {
  id: string;
  query?: string;
  pageSize?: number;
  pageNumber?: number;
  token?: CancelToken;
}

export interface SignUpRespondent {
  student: SimpleListResult;
  parent: SimpleListResult;
  time_slot?: { date: string; start: string };
  consent_signed_by: ConsentPerson;
}
