import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3C3.44772 3 3 3.44772 3 4V12C3 12.5523 3.44772 13 4 13H5H8H13V8V4C13 3.44772 12.5523 3 12 3H4ZM14 8V14H13H8V17C8 17.5523 8.44772 18 9 18H17C17.5523 18 18 17.5523 18 17V13V9C18 8.44772 17.5523 8 17 8H14Z"
      fill="#B3BBCB"
    />
    <path
      d="M4.7793 10.5H4.0957L5.99609 5.52344H6.57715L8.48096 10.5H7.80078L7.32568 9.19775H5.25098L4.7793 10.5ZM5.4458 8.66113H7.13086L6.28662 6.33691L5.4458 8.66113ZM11.3024 7.96387C11.3024 7.74284 11.2363 7.56738 11.1042 7.4375C10.9743 7.30534 10.7818 7.23926 10.5265 7.23926C10.2896 7.23926 10.0982 7.2985 9.95232 7.41699C9.80649 7.53548 9.73357 7.6722 9.73357 7.82715H9.09441C9.09441 7.64941 9.1548 7.47738 9.27557 7.31104C9.39633 7.14242 9.56609 7.00456 9.78484 6.89746C10.0059 6.78809 10.2656 6.7334 10.5641 6.7334C10.9675 6.7334 11.2979 6.83594 11.5554 7.04102C11.8128 7.24609 11.9416 7.55599 11.9416 7.9707V9.69336C11.9416 9.81641 11.9518 9.94743 11.9723 10.0864C11.9951 10.2254 12.0282 10.3451 12.0715 10.4453V10.5H11.405C11.3845 10.4544 11.3662 10.3975 11.3503 10.3291C11.3366 10.2607 11.3252 10.189 11.3161 10.1138C11.1976 10.2391 11.0495 10.3462 10.8718 10.4351C10.6963 10.5239 10.4946 10.5684 10.2668 10.5684C10.0161 10.5684 9.79624 10.5205 9.60711 10.4248C9.42026 10.3268 9.27443 10.1947 9.16961 10.0283C9.06707 9.86198 9.0158 9.67741 9.0158 9.47461C9.0158 9.07585 9.16619 8.77393 9.46697 8.56885C9.76775 8.36149 10.1722 8.25781 10.6804 8.25781H11.3024V7.96387ZM10.3591 10.0215C10.5869 10.0215 10.784 9.96452 10.9504 9.85059C11.1167 9.73438 11.2341 9.60563 11.3024 9.46436V8.70215H10.7829C10.4229 8.70215 10.1449 8.75911 9.94891 8.87305C9.75294 8.98698 9.65496 9.15788 9.65496 9.38574C9.65496 9.56348 9.71421 9.71387 9.8327 9.83691C9.95346 9.95996 10.1289 10.0215 10.3591 10.0215Z"
      fill="white"
    />
  </svg>
);
