import { Icon, Stack, Typography } from '@mui/material';
import { CheckIcon, CrossIcon } from '@schooly/style';
import { FC, PropsWithChildren } from 'react';

interface SchoolPageIncompleteOptionProps extends PropsWithChildren {
  isActive?: boolean;
}

export const SchoolPageIncompleteOption: FC<SchoolPageIncompleteOptionProps> = ({
  children,
  isActive,
}) => (
  <Stack direction="row" alignItems="center" gap={1}>
    <Icon fontSize="medium" sx={{ display: 'flex', color: isActive ? '#27ae60' : 'text.primary' }}>
      {isActive ? <CheckIcon /> : <CrossIcon />}
    </Icon>
    <Typography variant="h3" color={isActive ? '#2aa65c' : 'text.primary'}>
      {children}
    </Typography>
  </Stack>
);
