const LEGAL_ENTITY_PARAMS_KEY = 'legalEntity';

type StoredLegalEntityParams = {
  path: string;
};

export const setStoredLegalEntity = (data: StoredLegalEntityParams) => {
  sessionStorage.setItem(LEGAL_ENTITY_PARAMS_KEY, JSON.stringify(data));
};

export const getStoredLegalEntity = () => {
  const params = sessionStorage.getItem(LEGAL_ENTITY_PARAMS_KEY);
  if (!params) return null;
  const parsedParams: StoredLegalEntityParams = JSON.parse(params);
  return parsedParams;
};

export const removeStoredLegalEntity = () => {
  sessionStorage.removeItem(LEGAL_ENTITY_PARAMS_KEY);
};
