import { Box, Button, InputAdornment, Stack, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, CopyIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

interface SchoolApplicationsEmbedFormForm {
  width: number;
  height: number;
}

export const SchoolApplicationsEmbedForm: FC = () => {
  const timeout = useRef<number | undefined>();

  const { $t } = useIntl();
  const { showNotification } = useNotifications();

  const { currentSchool } = useAuth();

  const form = useForm<SchoolApplicationsEmbedFormForm>();

  const width = form.watch('width');
  const height = form.watch('height');

  const [frame, setFrame] = useState('');
  const [copied, setCopied] = useState(false);

  const handleSubmit = useCallback(
    ({ width, height }: SchoolApplicationsEmbedFormForm) => {
      setFrame(
        `<iframe src="${window.location.origin}/parent-applications?school_id=${currentSchool?.id}" name="Schooly Application" scrolling="auto" width="${width}" height="${height}"></iframe>`,
      );
    },
    [currentSchool?.id],
  );

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(frame);

    setCopied(true);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = window.setTimeout(() => {
      setCopied(false);
    }, 3000);

    showNotification({
      textId: 'school-sections-Applications-SuccessfullyCopied',
      type: 'success',
    });
  }, [frame, showNotification]);

  /** resets the frame on params change */
  useEffect(() => {
    setFrame('');
  }, [width, height]);

  return (
    <Stack direction="row" alignItems="center" mt={2.5}>
      <Box flex="0 0 350px" />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Stack>
            <Stack direction="row" alignItems="flex-start">
              <ControlTextField
                name="width"
                control={form.control}
                rules={{ required: true, min: 600 }}
                sx={{ width: 120 }}
                placeholder={$t({ id: 'width' })}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">px</InputAdornment>,
                }}
              />
              <Stack justifyContent="center" height={44}>
                <Typography color="text.secondary" mx={1}>
                  x
                </Typography>
              </Stack>
              <ControlTextField
                name="height"
                control={form.control}
                rules={{ required: true, min: 700 }}
                sx={{ width: 120 }}
                placeholder={$t({ id: 'height' })}
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">px</InputAdornment>,
                }}
              />

              <Button sx={{ ml: 2 }} type="submit">
                <FormattedMessage id="action-Generate" />
              </Button>
            </Stack>

            {frame && (
              <SimpleButton
                endIcon={copied ? <CheckIcon /> : <CopyIcon />}
                sx={{ textAlign: 'left', mt: 2.5, color: copied ? 'primary.main' : undefined }}
                onClick={handleCopyClick}
                inverse
              >
                {frame}
              </SimpleButton>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  );
};
