import { ProfileSearchResult, UserType } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getUserTypeTextId } from '../../../helpers/misc';
import { getUserFullName } from '../../../helpers/users';
import useChildrenEdit from '../../../hooks/useChildrenEdit';
import useFlag from '../../../hooks/useFlag';
import Modal from '../../ui/Modal';
import ChildrenExternalSidebar from '../ChildrenExternalSidebar';
import ChildrenDisplay from '../CreatePersonForm/ChildrenDisplay';

import './index.scss';

interface EditChildrenModalProps {
  isOpen: boolean;
  userType: UserType;
  editedUser?: ProfileSearchResult;
  onClose: () => void;
  onStudentAdd: () => void;
}

const EditChildrenModal: React.FC<EditChildrenModalProps> = ({
  isOpen,
  userType,
  editedUser,
  onClose,
  onStudentAdd,
}) => {
  const { getConfirmation } = useConfirmationDialog();
  const invalidateStudentQueries = useInvalidateListQueriesFor('student');
  const invalidateParentQueries = useInvalidateListQueriesFor('parent');
  const [selectedParentProfile, setSelectedParentProfile] =
    useState<ProfileSearchResult | undefined>(editedUser);
  const [isSelectChildOpen, openChildSelect, closeChildSelect] = useFlag();

  const handleEditComplete = useCallback(() => {
    invalidateStudentQueries();
    invalidateParentQueries();
    onStudentAdd();
    onClose();
  }, [invalidateParentQueries, invalidateStudentQueries, onClose, onStudentAdd]);

  const { childrenList, handleSelectChild, handleDeleteChild, handleSubmit, isSubmitInProgress } =
    useChildrenEdit(selectedParentProfile, closeChildSelect, handleEditComplete);

  useEffect(() => {
    setSelectedParentProfile(editedUser);
  }, [editedUser]);

  const selectedChildIds = childrenList.map(({ user_id }) => user_id);

  useEffect(() => {
    if (isOpen && selectedParentProfile) {
      // openChildSelect();
    } else {
      closeChildSelect();
    }
  }, [openChildSelect, closeChildSelect, isOpen, selectedParentProfile]);

  const handleClose = useCallback(async () => {
    const isConfirmed = await getConfirmation({
      textId: 'people-CreateFamilyWarning',
    });

    if (!isConfirmed) {
      return;
    }

    onClose();
  }, [onClose, getConfirmation]);

  const [modalTitle, modalTitleTextId] = useMemo(() => {
    if (selectedParentProfile) {
      return [getUserFullName(selectedParentProfile)];
    }

    return [undefined, 'parents-SelectStudent'];
  }, [selectedParentProfile]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={modalTitle}
      titleTextId={modalTitleTextId}
      className="AddParentsModal"
      externalSidebarContent={
        isSelectChildOpen && (
          <ChildrenExternalSidebar
            userType={userType}
            adultProfile={selectedParentProfile}
            selectedUserIds={selectedChildIds}
            onUserSelect={handleSelectChild}
          />
        )
      }
      externalSidebarTitleTextId={getUserTypeTextId('child', true)}
      onExternalSidebarClose={closeChildSelect}
    >
      <ChildrenDisplay
        childrenList={childrenList}
        isChildSelectOpen={isSelectChildOpen}
        isLoading={isSubmitInProgress}
        onChildDelete={handleDeleteChild}
        onSubmit={handleSubmit}
        onOpenChildSelect={openChildSelect}
      />
    </Modal>
  );
};

export default EditChildrenModal;
