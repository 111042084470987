import { Components, Theme } from '@mui/material';

import hebrew from '../../css/fonts/heebo/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2';
import latin from '../../css/fonts/heebo/NGS6v5_NC0k9P9H2TbFhsqMA.woff2';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: {
    body: `
        /* hebrew */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 100;
          src: url(${hebrew}) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* latin */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 100;
          src: url(${latin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* hebrew */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 200;
          src: url(${hebrew}) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* latin */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 200;
          src: url(${latin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* hebrew */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 300;
          src: url(${hebrew}) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* latin */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 300;
          src: url(${latin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* hebrew */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 400;
          src: url(${hebrew}) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* latin */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 400;
          src: url(${latin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* hebrew */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 500;
          src: url(${hebrew}) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* latin */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 500;
          src: url(${latin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* hebrew */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 600;
          src: url(${hebrew}) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* latin */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 600;
          src: url(${latin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* hebrew */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 700;
          src: url(${hebrew}) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* latin */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 700;
          src: url(${latin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* hebrew */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 800;
          src: url(${hebrew}) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* latin */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 800;
          src: url(${latin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* hebrew */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 900;
          src: url(${hebrew}) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* latin */
        @font-face {
          font-family: 'Heebo';
          font-style: normal;
          font-weight: 900;
          src: url(${latin}) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `,

    '.MuiPopover-paper.MuiPaper-elevation': {
      boxShadow: '0px 10px 40px 0px #25275A26',
    },
  },
};
