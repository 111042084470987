import { CHECK_SYSTEM_ROLE } from '@schooly/api';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserRolesForSchool } from '../../../../../context/userRoles/useUserRolesForSchool';
import { CreateUserRoleModal } from '../../../../UserRoles/CreateUserRoleModal/CreateUserRoleModal';
import { UserRolePreviewModal } from '../../../../UserRoles/UserRolesPreviewModal/UserRolePreviewModal';
import { SchoolSettingsLayout } from '../../../SchoolSettingsLayout';

interface SchoolTuneUserRoleModalProps {
  type?: 'assign' | 'preview';
}

export const SchoolTuneUserRoleModal: FC<SchoolTuneUserRoleModalProps> = ({ type }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { loadUserRoles } = useUserRolesForSchool();

  const routePath = '/settings/tune/user_roles';

  const onModalClose = useCallback(() => {
    loadUserRoles();
    queryClient.invalidateQueries([CHECK_SYSTEM_ROLE]);

    navigate({ pathname: '/settings/tune', search: '?tab=access' });
  }, [loadUserRoles, navigate, queryClient]);

  const content = useMemo(() => {
    if (type === 'assign') {
      return <CreateUserRoleModal onClose={onModalClose} assign />;
    }

    if (type === 'preview') {
      return <UserRolePreviewModal onClose={onModalClose} routePath={routePath} />;
    }

    return <CreateUserRoleModal routePath={routePath} onClose={onModalClose} />;
  }, [onModalClose, type]);

  return <SchoolSettingsLayout>{content}</SchoolSettingsLayout>;
};
