import { Button, Dialog, IconButton, Switch, Typography } from '@mui/material';
import { AssessmentGroupsQueryFilters, FilterKeys, GetGroupsQueryFilters } from '@schooly/api';
import {
  AgeGroupSelectMultiple,
  FilterRow,
  StudentStaffPropertyTypeSelectMultiple,
  SubjectSelectMultiple,
  toggleMultipleValueArrayProperty,
} from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { CrossIcon, ModalContent, ModalFooter, ModalHeader } from '@schooly/style';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useSchool } from '../../../hooks/useSchool';

type AssessmentGroupFiltersPopupProps = {
  filters: AssessmentGroupsQueryFilters;
  defaultFilters: AssessmentGroupsQueryFilters;
  onClose: () => void;
  onSetFilters: (v: AssessmentGroupsQueryFilters) => void;
};

export const AssessmentGroupFiltersPopup: FC<AssessmentGroupFiltersPopupProps> = ({
  onClose,
  onSetFilters,
  filters: actualFilters,
  defaultFilters,
}) => {
  const { formatMessage } = useIntl();
  const { schoolId } = useSchool();
  const [filters, setFilters] = useState<GetGroupsQueryFilters>(actualFilters);

  const handleApply = useCallback(() => {
    onSetFilters(filters);
    onClose();
  }, [onSetFilters, filters, onClose]);

  const handleClear = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const {
    onClearSubject,
    onSelectSubjectId,
    onSelectOnlyTutorGroups,
    onClearOnlyTutorGroups,
    onClearAgeGroup,
    onClearHouse,
    onSelectAgeGroup,
    onSelectStaffHouseId,
    onSelectStudentHouseId,
    onClearStatus,
    onSelectStaffStatusId,
    onSelectStudentStatusId,
  } = useMemo(() => {
    const updateFilter = (key: keyof typeof actualFilters) => (id: string) => {
      setFilters((filters) => ({
        ...filters,
        [key]: filters[key]?.includes(id)
          ? filters[key]?.filter((ct) => ct !== id)
          : [...(filters[key] || []), id],
      }));
    };
    const onSelectAgeGroup = (v: string[]) => {
      setFilters((filters) => ({
        ...filters,
        [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(filters[FilterKeys.AgeGroup], v),
      }));
    };
    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    return {
      onSelectSubjectId: updateFilter(FilterKeys.Subject),
      onClearSubject: clearFilter(FilterKeys.Subject),
      onClearOnlyTutorGroups: () =>
        setFilters((filters) => ({
          ...filters,
          [FilterKeys.OnlyTutorGroups]: undefined,
        })),
      onSelectOnlyTutorGroups: () =>
        setFilters((filters) => ({
          ...filters,
          [FilterKeys.OnlyTutorGroups]: ['1'],
          [FilterKeys.Subject]: undefined,
        })),
      onSelectAgeGroup,
      onClearAgeGroup: clearFilter(FilterKeys.AgeGroup),
      onSelectStaffHouseId: updateFilter(FilterKeys.StaffHouse),
      onSelectStudentStatusId: updateFilter(FilterKeys.StudentStatus),
      onSelectStaffStatusId: updateFilter(FilterKeys.StaffStatus),
      onSelectStudentHouseId: updateFilter(FilterKeys.StudentHouse),
      onClearStatus: () => {
        clearFilter(FilterKeys.StaffStatus)();
        clearFilter(FilterKeys.StudentStatus)();
      },
      onClearHouse: () => {
        clearFilter(FilterKeys.StaffHouse)();
        clearFilter(FilterKeys.StudentHouse)();
      },
    };
  }, []);

  if (!schoolId) return null;
  return (
    <Dialog
      fullWidth
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndex.modal + 1,

        '& .MuiDialog-paperFullWidth': {
          width: 600,
          height: 'unset',
        },
      })}
      open
      onClose={onClose}
    >
      <ModalHeader active title={formatMessage({ id: 'groups-Filter-Title' })}>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalContent active>
        <FilterRow label={formatMessage({ id: 'schoolProperty-Status' })}>
          <StudentStaffPropertyTypeSelectMultiple
            borderOnHover
            schoolId={schoolId}
            onClear={onClearStatus}
            propertyType={SchoolPropertyType.Status}
            selectedStaffIds={filters.staff_status || []}
            selectedStudentIds={filters.student_status || []}
            onSelectStaffId={onSelectStaffStatusId}
            onSelectStudentId={onSelectStudentStatusId}
          />
        </FilterRow>
        <FilterRow label={formatMessage({ id: 'schoolProperty-AgeGroup' })}>
          <AgeGroupSelectMultiple
            borderOnHover
            allowArchived
            schoolId={schoolId}
            onClear={onClearAgeGroup}
            optionsTitle={formatMessage({ id: 'schoolProperty-AgeGroup-plural' })}
            userRole={SchoolUserRole.Student}
            selectedIds={filters.age_group || []}
            onSelect={onSelectAgeGroup}
          />
        </FilterRow>
        <FilterRow label={formatMessage({ id: 'schoolProperty-House' })}>
          <StudentStaffPropertyTypeSelectMultiple
            borderOnHover
            schoolId={schoolId}
            onClear={onClearHouse}
            propertyType={SchoolPropertyType.House}
            selectedStaffIds={filters.staff_house || []}
            selectedStudentIds={filters.student_house || []}
            onSelectStaffId={onSelectStaffHouseId}
            onSelectStudentId={onSelectStudentHouseId}
          />
        </FilterRow>

        <FilterRow label={formatMessage({ id: 'groups-GroupSubject' })}>
          {!filters.only_tutor_groups?.length ? (
            <SubjectSelectMultiple
              borderOnHover
              schoolId={schoolId}
              onClear={onClearSubject}
              selectedIds={filters.subject || []}
              allowArchived
              onSelectSubjectId={onSelectSubjectId}
            />
          ) : (
            <Typography ml={1.25} color="common.grey">
              {formatMessage({ id: 'groups-GroupSubject-disabledFilter-Hint' })}
            </Typography>
          )}
        </FilterRow>
        <FilterRow label={formatMessage({ id: 'groups-OnlyTutorGroups' })}>
          <Switch
            sx={{ ml: 1.25 }}
            checked={!!filters.only_tutor_groups?.length}
            onChange={
              !!filters.only_tutor_groups?.length ? onClearOnlyTutorGroups : onSelectOnlyTutorGroups
            }
          />
        </FilterRow>
      </ModalContent>
      <ModalFooter active>
        <Button variant="outlined" onClick={handleClear}>
          {formatMessage({ id: 'filter-Clear' })}
        </Button>
        <Button onClick={handleApply}>{formatMessage({ id: 'filter-Apply' })}</Button>
      </ModalFooter>
    </Dialog>
  );
};
