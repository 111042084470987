import { IconButton } from '@mui/material';
import { CommentFilledIcon, CommentIcon, theme } from '@schooly/style';
import { FC, useMemo } from 'react';

export interface DropdownCommentIconProps {
  disabled: boolean;
  hasComments: boolean;
  error: boolean;
}

export const DropdownCommentIcon: FC<DropdownCommentIconProps> = ({
  disabled,
  hasComments,
  error,
}) => {
  const styles = useMemo(() => {
    if (hasComments) {
      return {
        color: error ? theme.palette.error.main : theme.palette.primary.main,
        ':hover': {
          color: error ? theme.palette.error.main : theme.palette.primary.main,
        },
      };
    }
    return {
      color: error ? theme.palette.error.main : theme.palette.text.primary,
      '.GridRow:hover &, .GridRowItem:hover &, .MuiDataGrid-row.Mui-hovered &': {
        '&:not(.Mui-disabled)': {
          color: error ? theme.palette.error.main : theme.palette.primary.main,
        },
      },
    };
  }, [error, hasComments]);

  return (
    <IconButton
      className="icon-Comments"
      disabled={disabled}
      sx={(theme) => ({
        ...styles,
        '&.Mui-disabled': {
          color: error ? theme.palette.error.main : theme.palette.text.secondary,
        },
      })}
    >
      {hasComments ? <CommentFilledIcon /> : <CommentIcon />}
    </IconButton>
  );
};
