import { useGetEventQuery } from '@schooly/api';
import { Loading, ModalLarge } from '@schooly/style';
import { FC } from 'react';

import { useEvent } from '../../../context/events/WithEvent';
import { EventPreviewContent } from './EventPreviewContent';

interface EventPreviewModalProps {}

export const EventPreviewModal: FC<EventPreviewModalProps> = () => {
  const { id, event, fetching } = useEvent();

  useGetEventQuery(
    {
      eventId: id ?? '',
    },
    {
      enabled: !!id,
      refetchOnMount: 'always',
    },
  );

  return (
    <ModalLarge open>
      {fetching || !event ? <Loading /> : <EventPreviewContent event={event} />}
    </ModalLarge>
  );
};
