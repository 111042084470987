import { AxiosInstance } from 'axios';

import { actions as requestsStateActions } from '../redux/slices/requestsStateSlice';
import { IAppDispatch } from '../redux/store';
let numberOfRequestsPending = 0;

// TODO: What is that for?

export default function setupPendingMiddleware(instance: AxiosInstance, dispatch: IAppDispatch) {
  function fulfilRequest() {
    numberOfRequestsPending--;

    if (numberOfRequestsPending === 0) {
      dispatch(requestsStateActions.setPending(false));
    }
  }

  instance.interceptors.request.use(
    (config) => {
      numberOfRequestsPending++;

      dispatch(requestsStateActions.setPending(true));

      return config;
    },
    (error) => {
      fulfilRequest();

      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => {
      fulfilRequest();

      return response;
    },
    (error) => {
      fulfilRequest();

      return Promise.reject(error);
    },
  );
}
