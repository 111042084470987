import { FormControl, FormHelperText, Typography } from '@mui/material';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import get from 'lodash.get';
import React from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form-lts';
import { FieldValues } from 'react-hook-form-lts/dist/types';
import { useIntl } from 'react-intl';

import { TimeInput, TimeInputProps } from './TimeInput';

export type ControlTimeInputProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> &
  TimeInputProps;

export const ControlTimeInput = <TFieldValues extends FieldValues = FieldValues>({
  control: propsControl,
  ...props
}: ControlTimeInputProps<TFieldValues>) => {
  const { $t } = useIntl();
  const { control: contextControl, formState } = useFormContext<TFieldValues>();
  const control = propsControl ?? contextControl;

  return (
    <Controller
      control={control}
      render={({ field }) => {
        const error = get(formState.errors, field.name);
        const hasError = props.error || Boolean(error);
        const errorMessage = getControllerErrorText<TFieldValues>(error, props.rules, $t);

        return (
          <FormControl error={hasError} fullWidth={props.fullWidth}>
            <TimeInput
              {...props}
              {...props.rules}
              error={hasError}
              inputRef={field.ref}
              required={Boolean(props.rules?.required) || props.required}
              {...field}
            />

            <FormHelperText>
              <Typography variant="body2">{errorMessage}</Typography>
            </FormHelperText>
          </FormControl>
        );
      }}
      {...props}
    />
  );
};
