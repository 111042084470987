import { PagedResponse } from '@schooly/api';
import {
  AttendanceEntryForPeriod,
  AttendanceRegisterForSchool,
  AttendanceStatistics,
} from '@schooly/api';
import { SchoolYear } from '@schooly/api';
import { InfiniteData } from '@tanstack/react-query';
import { createContext } from 'react';

export enum GroupAttendanceStatisticsMode {
  STUDENTS = 'students',
  REGISTERS = 'registers',
}

export interface GroupAttendanceStatisticsContextProps {
  schoolYears: SchoolYear[];
  query: string;
  validity?: { date: string[] };
  schoolYearsFetching: boolean;
  canShowMore: boolean;
  isFetchingNextPage: boolean;
  isFetching: boolean;
  students?: InfiniteData<PagedResponse<AttendanceEntryForPeriod>>;
  registers?: InfiniteData<PagedResponse<AttendanceRegisterForSchool>>;
  mode: GroupAttendanceStatisticsMode;
  statistics?: AttendanceStatistics;
  statisticsLoading: boolean;
  showByPresentAbsent: boolean;
  showByPercent: boolean;

  actions: {
    setQuery: (filter: string) => void;
    setValidity: (validity: { date: [string, string] }) => void;
    setMode: (mode: GroupAttendanceStatisticsMode) => void;
    setShowByPresentAbsent: (value: boolean) => void;
    handleShowMore: () => void;
    setShowByPercent: (value: boolean) => void;
  };
}

export const GroupAttendanceStatisticsContext =
  createContext<GroupAttendanceStatisticsContextProps>({
    schoolYears: [],
    query: '',
    validity: { date: [] },
    schoolYearsFetching: true,
    canShowMore: false,
    isFetchingNextPage: false,
    isFetching: false,
    students: undefined,
    registers: undefined,
    mode: GroupAttendanceStatisticsMode.STUDENTS,
    statistics: undefined,
    statisticsLoading: false,
    showByPresentAbsent: false,
    showByPercent: false,

    actions: {
      setQuery: () => {},
      setValidity: () => {},
      setMode: () => {},
      setShowByPresentAbsent: () => {},
      handleShowMore: () => {},
      setShowByPercent: () => {},
    },
  });

GroupAttendanceStatisticsContext.displayName = 'GroupAttendanceStatisticsContext';
