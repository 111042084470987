import { Skeleton, Stack } from '@mui/material';
import { PageHeader } from '@schooly/components/filters';
import { GridBody, GridHead, MainPageGrid, RowSkeleton, SkeletonRows } from '@schooly/style';
import { FC } from 'react';

import { PAGE_SIZE, SKELETON_COLUMNS } from './SchoolProductsContent';

export const SchoolProductsSkeleton: FC = () => {
  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <PageHeader pageTitleTextId="products-Title">
          <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
        </PageHeader>
      </Stack>
      <MainPageGrid>
        <GridHead borderBottom>
          <RowSkeleton columnsCount={SKELETON_COLUMNS} />
        </GridHead>
        <GridBody>
          <SkeletonRows columnsCount={SKELETON_COLUMNS} amount={PAGE_SIZE} />
        </GridBody>
      </MainPageGrid>
    </Stack>
  );
};
