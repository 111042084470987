import { Select, styled } from '@mui/material';

export interface SingleSelectStyledProps {
  isTop?: boolean;
}

export const SingleSelectStyled = styled(Select)<SingleSelectStyledProps>(({ theme, isTop }) => ({
  '&& .MuiOutlinedInput-notchedOutline': {
    ...(isTop && {
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    }),
  },
}));
