import { Button, Stack } from '@mui/material';
import {
  FilterKeys,
  GetMessagesQueryFilters,
  Message,
  MessageArrangeBy,
  useGetMessagesAggregatedData,
  useGetMessagesQuery,
} from '@schooly/api';
import {
  ArrangedByCollapsableSection,
  ArrangedByCollapsableSectionSkeleton,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { EyeIcon, GridBody, MainPageGrid, SkeletonRows } from '@schooly/style';
import { format } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import { MessagesHeader } from './MessagesGrid';
import { MessageRow } from './MessagesGrid';

type MessagesArrangedByListProps = {
  schoolId: string;
  arrangeByKey: MessageArrangeBy;
  filters: GetMessagesQueryFilters;
  query?: string;
  onSetTotalCount: (v?: number) => void;
};
export const MessagesArrangedByList: FC<MessagesArrangedByListProps> = ({
  schoolId,
  arrangeByKey,
  filters,
  query,
  onSetTotalCount,
}) => {
  const { data, isFetching } = useGetMessagesAggregatedData(
    {
      schoolId: schoolId,
      filters,
      arrangeBy: arrangeByKey ?? null,
      searchQuery: query,
    },
    { refetchOnMount: true, enabled: !!filters.date },
  );

  const { getRowName } = useGetArrangeByLabel();

  useEffect(() => {
    onSetTotalCount(data?.rows.reduce((acc, r) => acc + r.total, 0));
  }, [onSetTotalCount, data?.rows]);

  const sections = useMemo(() => {
    if (!data) return [];
    if (data.rows.every(({ total }) => total === 0)) return [];

    return data.rows.map((d) => ({
      ...d,
      name: getRowName(d.name, arrangeByKey),
    }));
  }, [arrangeByKey, data, getRowName]);

  if (isFetching) return <ArrangedByCollapsableSectionSkeleton />;

  if (!sections.length) return <MainGridNoResultsStub textId="messages-NoResults-title" />;

  return (
    <>
      {sections.map((section) => {
        if (!section.total) return null;
        return (
          <ArrangedByCollapsableSection
            count={section.total}
            title={section.name}
            key={section.value}
          >
            <ArrangedByMessagesGrid
              count={section.total}
              filters={filters}
              arrangeByKey={arrangeByKey}
              arrangeByValue={section.value}
              schoolId={schoolId}
              query={query}
            />
          </ArrangedByCollapsableSection>
        );
      })}
    </>
  );
};

type ArrangedByMessagesGridProps = {
  count: number;
  filters: GetMessagesQueryFilters;
  arrangeByKey?: MessageArrangeBy;
  arrangeByValue?: string | number;
  schoolId: string;
  query?: string;
};

const PAGE_SIZE = 30;

export const ArrangedByMessagesGrid: FC<ArrangedByMessagesGridProps> = ({
  count,
  filters: initialFilters,
  schoolId,
  arrangeByKey,
  arrangeByValue,
  query,
}) => {
  const filters = useMemo(
    () =>
      arrangeByKey
        ? {
            ...initialFilters,
            [arrangeByKey]: [arrangeByValue === undefined ? 'none' : arrangeByValue],
          }
        : initialFilters,
    [initialFilters, arrangeByKey, arrangeByValue],
  );

  const { data, isLoading, setParams, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetMessagesQuery(
      {
        school_id: schoolId,
        page_size: PAGE_SIZE,
        filters,
        query,
      },
      { refetchOnMount: 'always' },
    );

  useEffect(() => {
    setParams((params) => ({ ...params, filters }));
  }, [filters, setParams]);

  const entries = useMemo(
    () => data?.pages.reduce<Message[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  const mainContent = useMemo(() => {
    if (!entries.length && !isLoading) {
      return (
        <NoListItemsStub
          titleText={<FormattedMessage id="filter-NoResults" />}
          subTitleText={<FormattedMessage id="groups-NoMatches" />}
          type="group"
        />
      );
    }

    return (
      <>
        <MessagesHeader />

        <GridBody>
          {entries?.map((entry) => (
            <MessageRow message={entry} key={entry.id} />
          ))}

          {isLoading && <SkeletonRows columnsCount={6} amount={PAGE_SIZE} />}
          {(isLoading || isFetchingNextPage) && (
            <SkeletonRows
              columnsCount={6}
              amount={Math.min(PAGE_SIZE, count - (entries?.length || 0))}
            />
          )}
        </GridBody>
      </>
    );
  }, [count, entries, isFetchingNextPage, isLoading]);

  return (
    <MainPageGrid
      bottomElement={
        hasNextPage && !isFetchingNextPage ? (
          <Stack mt={1} alignItems="center">
            <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
              <FormattedMessage id="action-ShowMoreButton" />
            </Button>
          </Stack>
        ) : undefined
      }
    >
      {mainContent}
    </MainPageGrid>
  );
};

export const useGetArrangeByLabel = () => {
  const { $t } = useIntl();

  const getRowName = useCallback(
    (name: string, arrangeByKey: FilterKeys) => {
      switch (arrangeByKey) {
        case FilterKeys.MessagesReadPercent:
          const [percentFrom, percentTo] = name.split('-');
          if (Number(percentFrom) === 0) {
            return $t(
              {
                id: 'messages-LessThanReadLabel',
              },
              { percentTo },
            );
          } else if (Number(percentTo) === 100) {
            return $t(
              {
                id: 'messages-MoreThanReadLabel',
              },
              { percentFrom },
            );
          }

          return $t(
            {
              id: 'messages-ReadPercentLabel',
            },
            { percentFrom, percentTo },
          );

        case FilterKeys.MessagesMonth:
          const date = new Date(name);
          return format(date, "MMMM'' yy");

        default:
          return name;
      }
    },
    [$t],
  );

  return useMemo(() => ({ getRowName }), [getRowName]);
};
