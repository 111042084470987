import { Button, DialogActions, DialogContent } from '@mui/material';
import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalConfirmation } from '../../../../components/uikit-components/Modal/ModalConfirmation.styled';
import { useMessage } from '../../../../context/messages/useMessage';

export const MessagesCreateModalDeleteConfirmation: FC = () => {
  const { deletingConfirmation, actions } = useMessage();

  const handleConfirm = useCallback(() => {
    actions.deleteMessage();
  }, [actions]);

  return (
    <ModalConfirmation open={deletingConfirmation} onClose={actions.cancelDeletingConfirmation}>
      <DialogContent>
        <FormattedMessage id="messages-DeleteTitle" />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={actions.cancelDeletingConfirmation}>
          <FormattedMessage id="action-Cancel" />
        </Button>
        <Button onClick={handleConfirm}>
          <FormattedMessage id="action-Confirm" />
        </Button>
      </DialogActions>
    </ModalConfirmation>
  );
};
