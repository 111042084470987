import { debounce } from '@mui/material';
import {
  ApiError,
  AssessmentAction,
  AssessmentDeleteRequest,
  AssessmentDeleteResponse,
  CREATE_ASSESSMENT_MUTATION,
  CREATE_EVENT_MUTATION,
  CREATE_REPORT_MUTATION,
  DELETE_ASSESSMENT_MUTATION,
  DELETE_EVENT_MUTATION,
  DELETE_REPORT_MUTATION,
  EVENTS_BULK_STATUS_CHANGE_MUTATION,
  EventsBulkChangeStatusRequest,
  EventsBulkChangeStatusResponse,
  EventsStatuses,
  MutationState,
  PERFORM_ASSESSMENT_ACTION_MUTATION,
  PERFORM_REPORT_ACTION_MUTATION,
  PerformAssessmentActionRequest,
  PerformAssessmentActionResponse,
  PerformReportActionRequest,
  PerformReportActionResponse,
  RemoveEventRequest,
  RemoveEventResponse,
  ReportAction,
  ReportStatuses,
  UPDATE_ASSESSMENT_MUTATION,
  UPDATE_EVENT_MUTATION,
  UPDATE_REPORT_MUTATION,
  UPDATE_SCHOOL_YEAR_PERIODS_MUTATION,
} from '@schooly/api';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { AssessmentStatuses } from '@schooly/constants';
import { useQueryClient } from '@tanstack/react-query';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';

import { useAnnualPlannerCreateAssessment } from './forms/WithAnnualPlannerCreateAssessment';
import { useAnnualPlannerCreateEvent } from './forms/WithAnnualPlannerCreateEvent';
import { useAnnualPlannerCreateReport } from './forms/WithAnnualPlannerCreateReport';

const DEBOUNCE_TIMEOUT = 300;

export const WithAnnualPlannerCreateObserver: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const invalidateQueries = useInvalidateListQueriesFor('annualPlanner');

  const {
    updateBulkEventsInQueryCache,
    deleteEventFromQueryCache,
    deleteBulkEventsFromQueryCache,
  } = useAnnualPlannerCreateEvent();
  const { updateAssessmentInQueryCache, deleteAssessmentFromQueryCache } =
    useAnnualPlannerCreateAssessment();
  const { updateReportInQueryCache } = useAnnualPlannerCreateReport();

  const onChangeSchoolYearPeriods = useMemo(
    () =>
      debounce(() => {
        // TODO: use QueryCache update instead of re-fetching the entire planner data
        invalidateQueries();
      }, DEBOUNCE_TIMEOUT),
    [invalidateQueries],
  );

  const onEventCreate = useMemo(
    () =>
      debounce(() => {
        // TODO: use `addEventToQueryCache` when BE is ready
        invalidateQueries();
      }, DEBOUNCE_TIMEOUT),
    [invalidateQueries],
  );

  const onEventUpdate = useMemo(
    () =>
      debounce(() => {
        // TODO: use `updateEventInQueryCache` when BE is ready
        invalidateQueries();
      }, DEBOUNCE_TIMEOUT),
    [invalidateQueries],
  );

  const onEventDelete = useMemo(
    () =>
      debounce(
        ({ variables }: MutationState<RemoveEventResponse, ApiError, RemoveEventRequest>) => {
          if (variables?.id) {
            deleteEventFromQueryCache(variables.id, variables.withFollowing);
          }
        },
        DEBOUNCE_TIMEOUT,
      ),
    [deleteEventFromQueryCache],
  );

  const onEventsBulkStatusChange = useMemo(
    () =>
      debounce(
        ({
          variables,
        }: MutationState<
          EventsBulkChangeStatusResponse,
          ApiError,
          EventsBulkChangeStatusRequest
        >) => {
          if (!variables?.event_ids?.length || !variables?.action) {
            return;
          }

          if (variables.action === 'publish') {
            updateBulkEventsInQueryCache(variables.event_ids, {
              status: EventsStatuses.Published,
            });
          } else {
            deleteBulkEventsFromQueryCache(variables.event_ids);
          }
        },
        DEBOUNCE_TIMEOUT,
      ),
    [deleteBulkEventsFromQueryCache, updateBulkEventsInQueryCache],
  );

  const onAssessmentCreate = useMemo(
    () =>
      debounce(() => {
        // TODO: use `addAssessmentToQueryCache` when BE is ready
        invalidateQueries();
      }, DEBOUNCE_TIMEOUT),
    [invalidateQueries],
  );

  const onAssessmentUpdate = useMemo(
    () =>
      debounce(() => {
        // TODO: use `updateAssessmentInQueryCache` when BE is ready
        invalidateQueries();
      }, DEBOUNCE_TIMEOUT),
    [invalidateQueries],
  );

  const onAssessmentDelete = useMemo(
    () =>
      debounce(
        ({
          data,
          variables,
        }: MutationState<AssessmentDeleteResponse, ApiError, AssessmentDeleteRequest>) => {
          if (variables?.assessmentId && data?.success && !data?.entries_to_delete) {
            deleteAssessmentFromQueryCache(variables.assessmentId, variables.withFollowing);
          }
        },
        DEBOUNCE_TIMEOUT,
      ),
    [deleteAssessmentFromQueryCache],
  );

  const onAssessmentAction = useMemo(
    () =>
      debounce(
        ({
          variables,
        }: MutationState<
          PerformAssessmentActionResponse,
          ApiError,
          PerformAssessmentActionRequest
        >) => {
          if (variables?.id && variables?.action)
            updateAssessmentInQueryCache(variables.id, {
              status:
                variables.action === AssessmentAction.Publish
                  ? AssessmentStatuses.Published
                  : AssessmentStatuses['Not published'],
            });
        },
        DEBOUNCE_TIMEOUT,
      ),
    [updateAssessmentInQueryCache],
  );

  const onReportCreate = useMemo(
    () =>
      debounce(() => {
        invalidateQueries();
      }, DEBOUNCE_TIMEOUT),
    [invalidateQueries],
  );

  const onReportUpdate = useMemo(
    () =>
      debounce(() => {
        invalidateQueries();
      }, DEBOUNCE_TIMEOUT),
    [invalidateQueries],
  );

  const onReportDelete = useMemo(
    () =>
      debounce(() => {
        invalidateQueries();
      }, DEBOUNCE_TIMEOUT),
    [invalidateQueries],
  );

  const onReportAction = useMemo(
    () =>
      debounce(
        ({
          variables,
        }: MutationState<PerformReportActionResponse, ApiError, PerformReportActionRequest>) => {
          if (variables?.id && variables?.status) {
            updateReportInQueryCache(variables.id, {
              report_status:
                variables.status === ReportAction.Publish
                  ? ReportStatuses.Published
                  : ReportStatuses.Unpublished,
            });
          }
        },
        DEBOUNCE_TIMEOUT,
      ),
    [updateReportInQueryCache],
  );

  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe((mutation) => {
      if (mutation.type !== 'updated' || mutation.mutation?.state.status !== 'success') {
        return;
      }

      switch (mutation.mutation.options.mutationKey?.[0]) {
        case UPDATE_SCHOOL_YEAR_PERIODS_MUTATION:
          onChangeSchoolYearPeriods();
          break;

        case CREATE_EVENT_MUTATION:
          onEventCreate();
          break;
        case UPDATE_EVENT_MUTATION:
          onEventUpdate();
          break;
        case DELETE_EVENT_MUTATION:
          onEventDelete(mutation.mutation.state);
          break;
        case EVENTS_BULK_STATUS_CHANGE_MUTATION:
          onEventsBulkStatusChange(mutation.mutation.state);
          break;

        case CREATE_ASSESSMENT_MUTATION:
          onAssessmentCreate();
          break;
        case UPDATE_ASSESSMENT_MUTATION:
          onAssessmentUpdate();
          break;
        case DELETE_ASSESSMENT_MUTATION:
          onAssessmentDelete(mutation.mutation.state);
          break;
        case PERFORM_ASSESSMENT_ACTION_MUTATION:
          onAssessmentAction(mutation.mutation.state);
          break;

        case CREATE_REPORT_MUTATION:
          onReportCreate();
          break;
        case UPDATE_REPORT_MUTATION:
          onReportUpdate();
          break;
        case DELETE_REPORT_MUTATION:
          onReportDelete();
          break;
        case PERFORM_REPORT_ACTION_MUTATION:
          onReportAction(mutation.mutation.state);
          break;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [
    onAssessmentAction,
    onAssessmentCreate,
    onAssessmentDelete,
    onAssessmentUpdate,
    onChangeSchoolYearPeriods,
    onEventCreate,
    onEventDelete,
    onEventUpdate,
    onEventsBulkStatusChange,
    onReportAction,
    onReportCreate,
    onReportDelete,
    onReportUpdate,
    queryClient,
  ]);

  return <>{children}</>;
};
