import { IconButton, IconButtonProps, Typography } from '@mui/material';
import { PlusIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalEmpty } from './ModalEmpty';

export interface ModalEmptyMainAreaProps {
  label: string;
  onClick?: IconButtonProps['onClick'];
  labelNode?: React.ReactNode;
}

export const ModalEmptyMainArea: FC<ModalEmptyMainAreaProps> = ({ label, onClick, labelNode }) => {
  return (
    <ModalEmpty>
      <Typography align="center" variant="h3">
        <FormattedMessage id="modal-EmptyLabel-1" />
        <IconButton onClick={onClick}>
          <PlusIcon />
        </IconButton>
        {labelNode ? labelNode : <FormattedMessage id="modal-EmptyLabel-2" values={{ label }} />}
      </Typography>
    </ModalEmpty>
  );
};
