import { Components, Theme } from '@mui/material';

export const MuiLink: Components<Theme>['MuiLink'] = {
  styleOverrides: {
    root: () => {
      return {
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      };
    },
  },
};
