import { HeaderActions } from '@schooly/components/header-actions';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolInviteStatus } from '@schooly/constants';
import { CrossIcon, MailIcon } from '@schooly/style';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IS_DEMO } from '../../../config';
import useRequest from '../../../hooks/useRequest';

export const DELAY_BEFORE_CHANGE_STATUS = 2000;

interface InviteButtonProps {
  canInvite: boolean;
  currentStatus: SchoolInviteStatus | null;
  onSendInviteClick: () => Promise<any>;
  onCancelInviteClick: () => Promise<any>;
  tooltipTitle?: string;
}

const InviteButton: React.FC<InviteButtonProps> = ({
  canInvite,
  currentStatus,
  onSendInviteClick,
  onCancelInviteClick,
  tooltipTitle,
}) => {
  const { showError } = useNotifications();
  const { isLoading, error, requestWrapper } = useRequest();
  const { $t } = useIntl();
  const isInvited = currentStatus === SchoolInviteStatus.Invited;

  useEffect(() => {
    if (!error) return;

    showError(error);
  }, [error, showError]);

  const actions = useMemo(() => {
    const inviteAction = {
      titleTextId: isInvited ? 'resend-invite' : 'send-invite',
      handler: requestWrapper(onSendInviteClick),
      icon: <MailIcon />,
      pendingTitle: $t({ id: 'sending-invite' }),
    };

    const cancelAction = {
      titleTextId: 'cancel-invite',
      handler: requestWrapper(onCancelInviteClick),
      icon: <CrossIcon />,
      pendingTitle: $t({ id: 'canceling-invite' }),
    };

    if (!canInvite && isInvited) return [cancelAction];

    return isInvited ? [inviteAction, cancelAction] : [inviteAction];
  }, [$t, canInvite, isInvited, onCancelInviteClick, onSendInviteClick, requestWrapper]);

  const status = useMemo(() => {
    switch (currentStatus) {
      case SchoolInviteStatus.Invited:
        return {
          title: $t({ id: 'inviteStatus-Invited' }),
          styleType: 'warning' as const,
        };
      case SchoolInviteStatus.Active:
        return { title: '' };

      default:
        return {
          title: $t({ id: 'inviteStatus-NotInvited' }),
          styleType: 'error' as const,
        };
    }
  }, [$t, currentStatus]);

  if (IS_DEMO) return null;

  return (
    <HeaderActions
      title={status.title}
      actions={actions}
      pending={isLoading}
      styleType={status.styleType}
      disabled={!canInvite && !isInvited}
      tooltipTitle={tooltipTitle}
    />
  );
};

export default InviteButton;
