import axios, { CancelToken, CancelTokenSource } from 'axios';

// If used cancel request then return type must be set undefined

/* eslint-disable no-param-reassign */
export default function updateCancelToken(data: { source?: CancelTokenSource }): CancelToken {
  if (data.source) {
    data.source.cancel('Operation canceled due to new request.');
  }

  data.source = axios.CancelToken.source();

  return data.source.token;
}
