import {
  Box,
  ClickAwayListener,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { SchoolReEnrollmentResponse, StudentRegistration } from '@schooly/api';
import {
  InviteNotAcceptedWarning,
  isReEnrollmentPendingRegistration,
  isReEnrollmentPendingStatus,
  SameAgeGroupWarning,
} from '@schooly/components/annual-roll-over';
import { useAuth } from '@schooly/components/authentication';
import { useFlag } from '@schooly/hooks/use-flag';
import { ArrowUpIcon, DeleteIcon, LogoutIcon, MoreIcon, Spin, StudentIcon } from '@schooly/style';
import React, { FC, PropsWithChildren, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useProfile } from '../../../context/profile/useProfile';
import { getDisplayStatus } from '../../../helpers/registrations';
import RegistrationCard, { RegistrationProps } from '../RegistrationCard';
import { StatusStringForRegistration } from '../RegistrationCard/helpers';
import { useReEnrollmentActions } from '../StudentRegistration/useReEnrollmentActions';
import { ReEnrollmentTag } from '../StudentRegistration/useReEnrollmentStatuses';

export interface StudentRegistrationProps extends Omit<RegistrationProps, 'registration'> {
  enrollment: StudentRegistration;
  canRollover: boolean;
  onRollover: (reg: StudentRegistration) => void;
  onShowFamily: () => void;
  prevEnrollments: StudentRegistration[];
  refetchEnrollments: () => void;
  reEnrollmentData: SchoolReEnrollmentResponse;
}

export const StudentRegistrationComponent: FC<StudentRegistrationProps> = ({
  enrollment,
  onClick,
  canRollover,
  onRollover,
  onShowFamily,
  prevEnrollments,
  refetchEnrollments,
  reEnrollmentData,
}) => {
  const [opened, open, close] = useFlag();
  const { currentUser } = useAuth();

  const {
    handleAcceptReEnrollment,
    handleRejectReEnrollment,
    handleDeleteEnrollment,
    isReEnrollmentUpdating,
  } = useReEnrollmentActions({
    fetchEnrollments: refetchEnrollments,
    reEnrollmentData,
  });
  const contextActions = useRef([
    {
      labelTextId: 'school-annualRollover-AcceptReEnrollment',
      onClick: handleAcceptReEnrollment,
      icon: <StudentIcon />,
    },
    {
      labelTextId: 'school-annualRollover-RejectReEnrollment',
      onClick: handleRejectReEnrollment,
      icon: <LogoutIcon className="reset-svg-currentColor" />,
    },
  ]);
  const { $t } = useIntl();
  const { schoolMembership } = useProfile();

  const isInviteAccepted =
    schoolMembership &&
    'parents_invite_accepted' in schoolMembership &&
    !!schoolMembership.parents_invite_accepted;

  const isPendingRegistration = isReEnrollmentPendingRegistration(enrollment);

  const displayStatus = useMemo(
    () => (enrollment.statuses.length ? getDisplayStatus(enrollment.statuses) : null),
    [enrollment.statuses],
  );

  const isReEnrollmentStatus = displayStatus?.school_property.source?.type === 're_enrollment';

  const isPendingStatus = Boolean(
    displayStatus && isReEnrollmentPendingStatus(displayStatus.school_property),
  );

  const isExpiredStatus = Boolean(
    isReEnrollmentStatus && displayStatus?.school_property.category?.final,
  );

  const isLeftByReject =
    enrollment?.type === 're_enrollment' && enrollment.enrollment_status?.type === 'rejected';

  const isLoading = isReEnrollmentUpdating;
  const showReEnrollmentActions = isPendingRegistration && !isLoading;

  return (
    <RegistrationCardWrapper
      isPendingRegistration={isPendingRegistration}
      isMenuActive={opened}
      isPendingStatus={isPendingStatus}
      isExpiredStatus={isExpiredStatus}
    >
      <RegistrationCard
        key={enrollment.id}
        registration={enrollment}
        onClick={!isPendingRegistration ? onClick : undefined}
        actionButton={
          enrollment?.same_age_group && (
            <SameAgeGroupWarningComponent
              canRollover={canRollover}
              onRollover={() => onRollover(enrollment)}
            />
          )
        }
        canEdit={!isPendingRegistration}
        renderStatus={() => (
          <StatusStringForRegistration
            registration={enrollment}
            statusInfo={
              <Stack direction="row">
                {!isInviteAccepted && isPendingStatus && (
                  <InviteNotAcceptedWarning onShowFamily={onShowFamily} />
                )}
                {isLeftByReject ? (
                  <ReEnrollmentTag>
                    {$t({ id: 'students-ReEnrollment-NotReEnrolled' })}
                  </ReEnrollmentTag>
                ) : null}
              </Stack>
            }
          />
        )}
      >
        {isLoading && (
          <Icon>
            <Spin />
          </Icon>
        )}
        {showReEnrollmentActions && (
          <>
            {isPendingStatus ? (
              <ClickAwayListener
                onClickAway={() => {
                  if (opened) close();
                }}
              >
                <div>
                  <Tooltip
                    open={opened}
                    disableTouchListener
                    componentsProps={{
                      tooltip: {
                        sx: (theme) => ({
                          padding: theme.spacing(1),
                          maxWidth: 215,
                        }),
                      },
                    }}
                    title={
                      <>
                        {contextActions.current.map((a) => (
                          <MenuItem
                            key={a.labelTextId}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!currentUser) return;
                              a.onClick({ enrollment, prevEnrollments, user: currentUser });
                              close();
                            }}
                            sx={(theme) => ({
                              m: 0,
                              py: theme.spacing(0.5),
                              px: theme.spacing(1.25),
                              borderRadius: theme.spacing(0.5),
                            })}
                          >
                            <Stack direction="row" gap={0.75}>
                              <Icon color="primary">{a.icon}</Icon>
                              <Typography variant="h3">{$t({ id: a.labelTextId })}</Typography>
                            </Stack>
                          </MenuItem>
                        ))}
                      </>
                    }
                  >
                    <IconButton
                      className="menuIcon"
                      sx={{
                        visibility: 'hidden',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        open();
                      }}
                    >
                      <MoreIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            ) : (
              <IconButton
                className="menuIcon"
                inverse
                sx={{
                  visibility: 'hidden',
                }}
                onClick={() => handleDeleteEnrollment(enrollment.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        )}
      </RegistrationCard>
    </RegistrationCardWrapper>
  );
};

type RegistrationCardWrapperProps = {
  isMenuActive: boolean;
  isPendingRegistration: boolean;
  isPendingStatus: boolean;
  isExpiredStatus: boolean;
};

const RegistrationCardWrapper = styled(Box)<RegistrationCardWrapperProps>(
  ({ theme, isPendingRegistration, isMenuActive, isPendingStatus, isExpiredStatus }) => {
    const pendingColor = isPendingStatus ? theme.palette.common.orange : undefined;
    const expiredColor = isExpiredStatus ? theme.palette.error.main : undefined;
    const pendingBg = isPendingStatus ? theme.palette.common.orange5 : undefined;
    const expiredBg = isExpiredStatus ? theme.palette.error.superLight : undefined;

    const textColor = pendingColor || expiredColor;
    const bgColor = pendingBg || expiredBg;
    return {
      '.Card': {
        ...(isPendingRegistration && {
          border: textColor ? `1px solid ${textColor} !important` : undefined,
          borderRadius: '8px !important',
          backgroundColor: isMenuActive ? bgColor : 'inherit',
        }),

        '.menuIcon': {
          visibility: isMenuActive ? 'visible' : 'hidden',
        },

        '.RegistrationCard__status .text-body': {
          color: textColor ? `${textColor} !important` : undefined,
        },
        ':hover': {
          '.menuIcon': {
            visibility: 'visible',
          },

          backgroundColor: isPendingRegistration ? bgColor : theme.palette.common.lightBg,

          '.MuiTypography-root': {
            color: theme.palette.primary.main,
          },
        },
      },
    };
  },
);

type SameAgeGroupWarningProps = {
  canRollover?: boolean;
  onRollover?: () => void;
};

const SameAgeGroupWarningComponent: FC<PropsWithChildren<SameAgeGroupWarningProps>> = ({
  canRollover,
  onRollover,
}) => {
  const { $t } = useIntl();
  return (
    <Box
      sx={{
        '.MuiIcon-root': {
          paddingTop: 0.5,
        },
      }}
    >
      <SameAgeGroupWarning
        tooltipLabel={
          <Stack gap={1}>
            <Typography>
              {$t(
                { id: 'students-sameAgeGroupWarning' },
                {
                  ageGroup: $t({ id: 'students-sameAgeGroup' }).toLowerCase(),
                },
              )}{' '}
              {$t({ id: 'students-DuringRollover' }).toLowerCase()}
            </Typography>
            {canRollover && (
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                  cursor: 'pointer',
                  pointerEvents: 'auto',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onRollover?.();
                }}
              >
                <Icon>
                  <ArrowUpIcon />
                </Icon>
                <Typography>
                  <FormattedMessage id="students-RolloverStart" />
                </Typography>
              </Stack>
            )}
          </Stack>
        }
      />
    </Box>
  );
};
