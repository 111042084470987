import { InputAdornment } from '@mui/material';
import { FormTextField, FormTextFieldProps } from '@schooly/components/form-text-field';
import { SearchIcon, Spin } from '@schooly/style';
import debounce from 'lodash.debounce';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

export type SearchInputProps = {
  value: string;
  onChangeText: (v: string) => void;
  debounced?: boolean;
  isLoading?: boolean;
} & FormTextFieldProps;

export const SearchInput: FC<SearchInputProps> = ({
  onChangeText,
  isLoading,
  value: initialValue,
  onChange,
  canClear = true,
  hideLabel = true,
  fullWidth = true,
  debounced = true,
  ...rest
}) => {
  const prevOnChangeValue = useRef(initialValue);
  const [value, setValue] = useState(initialValue);

  const onChangeDebounce = useMemo(
    () =>
      debounce(
        (v: string) => {
          if (prevOnChangeValue.current === v) return;

          prevOnChangeValue.current = v;
          onChangeText(v);
        },
        debounced ? 500 : 0,
      ),
    [onChangeText, debounced],
  );

  useEffect(() => {
    prevOnChangeValue.current = initialValue;
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onChangeDebounce(value);
  }, [value, onChangeDebounce]);

  return (
    <FormTextField
      fullWidth={fullWidth}
      canClear={canClear}
      startAdornment={
        <InputAdornment position="start">{isLoading ? <Spin /> : <SearchIcon />}</InputAdornment>
      }
      value={value}
      onChange={(e) => setValue(e.target.value)}
      hideLabel={hideLabel}
      {...rest}
    />
  );
};
