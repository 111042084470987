import { FilterKeys, FilterValue } from '@schooly/api';

export function getIsOnlyDateFilterApplied(
  appliedFiltersArray: {
    key: FilterKeys;
    values: FilterValue[];
  }[],
): boolean {
  return appliedFiltersArray.length === 1 && appliedFiltersArray[0].key === 'date';
}
