import { TooltipProps } from '@mui/material';

export const CALENDAR_SLOT_SIZE = 15; // minutes

export enum CalendarRecordTypes {
  Draft,
  Slot,
  Active,
}

export interface BaseCalendarRecord {
  type: CalendarRecordTypes;
  date: Date | string;
  time: [Date | string, Date | string][]; // [ [10:00, 14:00], [15:00, 19:00], ... ]
  tooltip?: TooltipProps;
}

export interface CalendarRecordDraft extends BaseCalendarRecord {
  type: CalendarRecordTypes.Draft;
  onClick?: (event: React.MouseEvent<HTMLDivElement>, record: CalendarRecordDraft) => void;
}

export interface CalendarRecordSlot extends BaseCalendarRecord {
  type: CalendarRecordTypes.Slot;
  onClick?: (event: React.MouseEvent<HTMLDivElement>, record: CalendarRecordSlot) => void;
}

export interface CalendarRecordActive extends BaseCalendarRecord {
  type: CalendarRecordTypes.Active;
  shadowed?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>, record: CalendarRecordActive) => void;
}

export type CalendarRecord = CalendarRecordDraft | CalendarRecordSlot | CalendarRecordActive;

export interface ColumnRecordBasic {
  start: Date;
  end: Date;
  isPast: boolean;
  size: number;
  record: BaseCalendarRecord;
}

export interface ColumnRecordDraft extends ColumnRecordBasic {
  record: CalendarRecordDraft;
}

export interface ColumnRecordSlot extends ColumnRecordBasic {
  record: CalendarRecordSlot;
}

export interface ColumnRecordActive extends ColumnRecordBasic {
  record: CalendarRecordActive;
}

export type ColumnRecord = ColumnRecordDraft | ColumnRecordSlot | ColumnRecordActive;
