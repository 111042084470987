import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import {
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { SelectedCriteriaList } from '../../../../components/uikit-components/SelectedCriteriaList/SelectedCriteriaList.styled';
import { useMessage } from '../../../../context/messages/useMessage';
import { getDisplayedCriteria } from '../../helpers';

export const MessagesModalPreviewSendToStaff: FC = () => {
  const { staff, staffCriteria, staffByIndividualStudent } = useMessage();

  return (
    <>
      {Boolean(staffCriteria.length || staff.length) && (
        <SelectedCriteriaList mb={2.5}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            <FormattedMessage id="messages-Staff" />
          </Typography>
          {
            /* Staff criteria */
            getDisplayedCriteria({ selectedCriteria: staffCriteria })
          }
          {/* Individual staff */}
          {Boolean(staff.length) && (
            <Box>
              {staff.map((item) => (
                <GridRowStyled key={item.relation_id}>
                  <GridRowItem noVerticalPadding>
                    <GridRowName>
                      <PersonCardBasic
                        user={item}
                        userType="staff"
                        isListItem
                        isUsernameClickable
                      />
                    </GridRowName>
                  </GridRowItem>
                </GridRowStyled>
              ))}
            </Box>
          )}
        </SelectedCriteriaList>
      )}

      {/* Staff by individual students */}
      {Boolean(staffByIndividualStudent.length) && (
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            <FormattedMessage id="messages-StaffOfStudents" />
          </Typography>
          <Box>
            {staffByIndividualStudent.map((item) => (
              <GridRowStyled key={item.relation_id}>
                <GridRowItem noVerticalPadding>
                  <GridRowName>
                    <PersonCardBasic user={item} userType="staff" isListItem isUsernameClickable />
                  </GridRowName>
                </GridRowItem>
              </GridRowStyled>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
