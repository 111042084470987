import { Box, Button, InputAdornment, Stack, Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  PaymentFrequencyType,
  SchoolYear,
  WeeklyPaymentFrequency,
} from '@schooly/api';
import {
  ControlPeriodSelect,
  getDatesFromPeriodKey,
  getPeriodKey,
  PeriodLabel,
} from '@schooly/components/form-select';
import { ControlSwitch } from '@schooly/components/form-switch';
import {
  DAY_OF_WEEK_OPTIONS,
  FormDaySelect,
  FormNumberSelect,
} from '@schooly/components/form-text-field';
import { CheckIcon, LockIcon, ModalContent, ModalFooter } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { eachWeekOfInterval, endOfWeek, format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import {
  useFrequencies,
  WeeklyPaymentFrequencyForm,
} from '../../../../context/frequencies/WithFrequencies';
import { formatPeriodDatesLabel, formatPeriodStringDatesLabel, mergePeriods } from '../utils';
import {
  FrequencyHeader,
  FrequencySetting,
  MAX_DUE_DATE_NUMBER,
  MIN_DUE_DATE_NUMBER,
  PeriodResult,
} from './EditFrequencyCommon';

type WeeklyFrequencyContentProps = {
  frequency: WeeklyPaymentFrequency;
  currentSchoolYear: SchoolYear;
};

export const WeeklyFrequencyContent: FC<WeeklyFrequencyContentProps> = ({
  frequency,
  currentSchoolYear,
}) => {
  const { has_invoices, activity_cycles, ...defaultValues } = frequency;
  const { updateFrequency } = useFrequencies();

  const { $t } = useIntl();

  const formActivityPeriods = activity_cycles.reduce<WeeklyPaymentFrequencyForm['activity_cycles']>(
    (acc, next) => {
      return [...acc, getPeriodKey(next)];
    },
    [],
  );

  const form = useForm<WeeklyPaymentFrequencyForm>({
    defaultValues: { ...defaultValues, activity_cycles: formActivityPeriods },
    mode: 'onChange',
  });

  const dueDay = form.watch('due_days_count');
  const generationDay = form.watch('billing_periods_spec.generation_date.offset_days');
  const activityPeriods = form.watch('activity_cycles');
  const inUse = form.watch('in_use');

  const yearWeekOptions = useMemo(() => {
    const days = eachWeekOfInterval({
      start: newDateTimezoneOffset(currentSchoolYear.start),
      end: newDateTimezoneOffset(currentSchoolYear.end),
    }).map((start, i) => {
      const end = endOfWeek(start);
      return {
        label: `${i + 1} ${$t({ id: 'week' }).toLowerCase()}`,
        dateLabel: formatPeriodDatesLabel({ start, end, type: 'week' }),
        key: getPeriodKey({
          start: format(start, DEFAULT_DATE_FORMAT_FNS),
          end: format(end, DEFAULT_DATE_FORMAT_FNS),
        }),
      };
    });

    return days;
  }, [$t, currentSchoolYear.end, currentSchoolYear.start]);

  const handleSubmit = useCallback(
    (d: WeeklyPaymentFrequencyForm) => {
      const { activity_cycles, ...rest } = d;

      const updatedFrequency: WeeklyPaymentFrequency = {
        ...rest,
        activity_cycles: activity_cycles.map(getDatesFromPeriodKey),
        has_invoices,
      };

      updateFrequency(updatedFrequency);
    },
    [has_invoices, updateFrequency],
  );

  const activityPeriodLabel = useMemo(() => {
    const selectedPeriodCount = activityPeriods.length;

    if (!selectedPeriodCount) return '';

    const allPeriodsSelected = selectedPeriodCount === yearWeekOptions.length;

    const label = `${selectedPeriodCount} ${$t({
      id: selectedPeriodCount > 1 ? 'frequencies-Week-plural' : 'week',
    })} `;

    const dateLabel = allPeriodsSelected
      ? formatPeriodStringDatesLabel({
          start: currentSchoolYear.start,
          end: currentSchoolYear.end,
          type: 'week',
        })
      : `${mergePeriods(activityPeriods.map(getDatesFromPeriodKey)).map(
          ({ start, end }) => ` ${formatPeriodStringDatesLabel({ start, end, type: 'week' })}`,
        )}`;

    return <PeriodLabel label={label} dateLabel={dateLabel} selected />;
  }, [$t, activityPeriods, currentSchoolYear, yearWeekOptions.length]);

  const selectedDayLabelId = DAY_OF_WEEK_OPTIONS.find(
    (d) => d.value === generationDay,
  )?.labelTextId;

  const getGenerationDate = selectedDayLabelId
    ? `${$t({ id: selectedDayLabelId })} ${$t({ id: 'frequencies-OfPreviousWeek' })}`
    : undefined;

  const invoiceDate = `${$t({ id: 'week-Monday' })}  ${$t({ id: 'frequencies-OfCurrentWeek' })}`;
  const disabled = !inUse;

  const showResult = !disabled && !!getGenerationDate && !!dueDay;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FrequencyHeader
          title={$t({ id: 'frequencies-weekly' })}
          yearName={currentSchoolYear.name}
          // TODO onClose
          onClose={() => {}}
        >
          <ControlSwitch label={$t({ id: 'frequencies-InUse' })} name="in_use" />
        </FrequencyHeader>
        <ModalContent active>
          <FrequencySetting
            disabled={disabled}
            title={$t({ id: 'frequencies-ActivityPeriodWeekTitle' })}
            sx={{
              gap: 1,
            }}
          >
            <ControlPeriodSelect
              name="activity_periods"
              rules={{
                validate: (v) => {
                  if (!v.length) return $t({ id: 'frequencies-ActivityPeriodsError' });
                  return true;
                },
              }}
              withSort
              PeriodSelectProps={{
                options: yearWeekOptions,
                periodLabel: activityPeriodLabel,
                placeholder: $t({ id: 'frequencies-ActivityPeriodTitle' }),
                showMonthSeparator: true,
                disabled: disabled,
              }}
            />
          </FrequencySetting>

          <FrequencySetting
            disabled={disabled}
            title={$t({ id: 'frequencies-InvoiceGenerationAndDueTitle' })}
            subTitle={$t({ id: 'frequencies-GenerationDate' })}
            sx={{
              pt: 4,
              gap: 1,
            }}
          >
            <Stack direction="row" alignItems="center" gap={2.5}>
              <FormDaySelect name="generation_date.offset_days" required disabled={disabled} />
              <Typography color={disabled ? 'common.grey2' : undefined} variant="h3">
                {$t({ id: 'frequencies-OfPreviousWeek' })}
              </Typography>
            </Stack>
          </FrequencySetting>

          <FrequencySetting
            disabled={disabled}
            subTitle={$t({ id: 'frequencies-DueDate' })}
            sx={{
              pt: 2,
              gap: 1,
            }}
          >
            <FormNumberSelect
              disabled={disabled}
              name="due_days_count"
              min={MIN_DUE_DATE_NUMBER}
              max={MAX_DUE_DATE_NUMBER}
              placeholder={$t({ id: 'frequencies-DueOn' })}
              rules={{
                validate: (value) => {
                  if (!value) return $t({ id: 'input-ErrorRequired' });
                  return true;
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {disabled ? <LockIcon /> : $t({ id: 'frequencies-DueDateDescriptionTitle' })}
                  </InputAdornment>
                ),
              }}
            />
          </FrequencySetting>

          {showResult && (
            <Box pt={4}>
              <PeriodResult
                type={PaymentFrequencyType.Weekly}
                dueDayCount={dueDay}
                generationDate={getGenerationDate}
                invoiceDate={invoiceDate}
              />
            </Box>
          )}
        </ModalContent>
        <ModalFooter
          active
          sx={(theme) => ({
            zIndex: theme.zIndex.drawer,
          })}
        >
          <Button endIcon={<CheckIcon />} type="submit">
            {$t({ id: 'action-Save' })}
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
