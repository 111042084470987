import { FC } from 'react';

export const EmptyFolderSvg: FC = () => (
  <svg
    width="254"
    height="250"
    viewBox="0 0 254 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_24221_37565)">
      <path
        d="M88.752 136.207C88.752 137.027 89.2522 137.764 90.0141 138.066L253.503 202.961V84.8779C253.503 84.0582 253.003 83.3215 252.242 83.0191L149.647 42.2837C149.177 42.0972 148.796 41.7399 148.579 41.2836L138.837 20.7957C138.62 20.3395 138.239 19.9822 137.769 19.7957L91.4902 1.4145C90.1771 0.892967 88.752 1.8604 88.752 3.27325V136.207Z"
        fill="#24275B"
      />
      <path
        d="M70.7217 182.736L235.426 248.132V96.8798L70.7217 31.4836V182.736Z"
        fill="white"
        stroke="#E7E9F4"
        strokeMiterlimit="10"
      />
      <path
        d="M70.7217 149.567C70.7217 150.387 71.2218 151.123 71.9836 151.426L235.426 216.321V98.2377C235.426 97.4179 234.926 96.6811 234.164 96.3787L131.617 55.6898C131.147 55.5034 130.765 55.1461 130.548 54.6896L120.807 34.2019C120.59 33.7457 120.208 33.3885 119.739 33.202L73.4599 14.8207C72.1469 14.2992 70.7217 15.2666 70.7217 16.6795V149.567Z"
        fill="white"
        stroke="#E7E9F4"
        strokeMiterlimit="10"
      />
      <path
        d="M70.3784 183.603C70.6023 183.921 70.914 184.166 71.2753 184.31L235.426 249.486L195.348 192.591L165.702 150.524C165.478 150.206 165.167 149.961 164.805 149.817L6.89412 87.1186C5.04636 86.385 3.37604 88.5042 4.5211 90.1294L70.3784 183.603Z"
        fill="#E7E9F4"
      />
      <path
        d="M109.772 166.48C109.772 166.48 85.6224 139.714 84.7816 149.664C84.2211 156.11 89.3126 160.968 91.8818 162.042C94.4509 163.117 94.9647 154.615 77.915 144.525C60.8653 134.436 70.8616 153.914 73.7577 154.568C76.6538 155.222 78.6624 145.787 56.4277 136.071"
        stroke="#24275B"
        strokeMiterlimit="10"
      />
      <path
        d="M141.209 194.367C141.209 194.367 124.019 175.402 120.189 175.916C116.405 176.383 125.141 183.436 121.871 184.464C118.601 185.492 105.989 168.816 101.318 169.376C96.6465 169.937 106.783 177.224 100.664 175.822C94.5445 174.421 84.595 162.509 78.2422 162.276"
        stroke="#24275B"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_24221_37565">
        <rect width="253.737" height="250" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
