import { Box, Stack, TableHeadProps, TableRow, Theme, Typography } from '@mui/material';
import { GetParentsQuerySort, ParentSearchResult, SORT_DIRECTION } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { CombinedRow } from '@schooly/components/filters';
import {
  CheckboxSquareIconButton,
  GridCell,
  GridHead,
  SortableCell,
  TypographyWithOverflowHint,
  UpdateIcon,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { FC, MouseEventHandler, ReactNode, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';

type ParentsHeaderProps = {
  sort?: GetParentsQuerySort;
  onChangeSort: (v: GetParentsQuerySort) => void;
  isSelectedAll?: boolean;
  onSelectAll?: () => void;
  rightIcon?: ReactNode;
} & TableHeadProps;

export const ParentsHeader: FC<ParentsHeaderProps> = ({
  isSelectedAll,
  onSelectAll,
  rightIcon,
  sort,
  onChangeSort,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const handleSort = (columnTextId: GetParentsQuerySort['columnTextId']) => () => {
    onChangeSort({
      columnTextId,
      direction:
        columnTextId === sort?.columnTextId
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width="50px" center>
        <CheckboxSquareIconButton
          isActive={isSelectedAll}
          disabled={!onSelectAll}
          onClick={onSelectAll}
        />
      </GridCell>
      <SortableCell
        label={formatMessage({ id: 'people-Name' })}
        sort={sort}
        columnTextId="last_name"
        onChangeSort={handleSort}
      />
      <SortableCell
        label={formatMessage({ id: 'userType-student-plural' })}
        sort={sort}
        columnTextId="students"
        onChangeSort={handleSort}
        width="300px"
        sx={(theme) => ({
          [theme.breakpoints.only('md')]: {
            width: 200,
          },
        })}
      />
      <GridCell width="150px">{formatMessage({ id: 'people-Telephone' })}</GridCell>
      <GridCell width="200px">{formatMessage({ id: 'people-Email' })}</GridCell>
    </GridHead>
  );
};

type ParentRowProps = {
  isSelected?: boolean;
  onSelect?: (v: string) => void;
  combinedParentsRow: CombinedRow<ParentSearchResult>;
};

export const ParentRow: FC<ParentRowProps> = ({ combinedParentsRow, isSelected, onSelect }) => {
  const { item, additionalRows } = combinedParentsRow;
  const location = useAppLocation();
  const name = getUserFullName(item);
  const ref = useRef<HTMLAnchorElement | null>(null);
  const handleSelect: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      onSelect?.(combinedParentsRow.id);

      const node = ref.current;
      if (!node) return;

      const scrollIfRowHidden = () => {
        const { y, height } = node.getBoundingClientRect();
        const yOffset = window.innerHeight - y;
        const isHiddenByBulkActionBar = !isSelected && yOffset <= height * 1.5;
        isHiddenByBulkActionBar && window.scrollBy({ behavior: 'smooth', top: height });
      };

      setTimeout(scrollIfRowHidden, 300);
    },
    [combinedParentsRow.id, onSelect, isSelected],
  );

  const hasSuggestedChanges = !!item.suggested_changes;

  return (
    <TableRow
      ref={ref}
      component={Link}
      to={`/parents/${combinedParentsRow.id}`}
      state={{ backgroundLocation: location }}
      sx={(theme) => ({
        '.avatar, .checkbox': {
          transition: 'opacity .2s',
        },
        '.checkbox': {
          position: 'absolute',
        },
        ...(isSelected
          ? {
              '.avatar': {
                opacity: 0,
              },
            }
          : {
              '&:not(:hover) .checkbox': {
                opacity: 0,
              },
              '&:hover': {
                '.avatar': {
                  opacity: 0,
                },
                '.checkbox': {
                  opacity: 1,
                },
              },
            }),
        '&:hover td': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <GridCell>
        <Stack
          sx={(theme) => ({
            justifyContent: 'center',
            alignItems: 'center',
            margin: theme.spacing(-0.5, 0.5),
          })}
          onClick={handleSelect}
        >
          <AvatarAuth
            className="avatar"
            sx={
              hasSuggestedChanges
                ? (theme: Theme) => ({
                    fontSize: theme.spacing(1.25),
                    width: theme.spacing(2.25),
                    height: theme.spacing(2.25),
                  })
                : undefined
            }
            user={item}
          />
          {hasSuggestedChanges && (
            <Box
              className="avatar"
              sx={(theme) => ({
                position: 'absolute',
                '.svg-icon': {
                  width: theme.spacing(4),
                  height: theme.spacing(4),
                },
              })}
            >
              <UpdateIcon className="reset-svg-currentColor" />
            </Box>
          )}
          <CheckboxSquareIconButton isActive={isSelected} className="checkbox" />
        </Stack>
      </GridCell>
      <GridCell>
        <TypographyWithOverflowHint variant="h3" color="common.grey2" noWrap>
          {name}
        </TypographyWithOverflowHint>
      </GridCell>
      <GridCell>
        <Typography
          noWrap
          sx={{
            a: {
              color: 'common.grey2',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          }}
        >
          {[item.student, ...additionalRows.map((r) => r.item.student)].map((s, i) => (
            <>
              <Link key={s.relation_id + i} to={`/students/${s.relation_id}`}>
                {getUserFullName(s)}
              </Link>
              {i !== additionalRows.length ? ', ' : ''}
            </>
          ))}
        </Typography>
      </GridCell>
      <GridCell>
        <Typography
          sx={{
            a: {
              color: 'common.grey2',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          }}
        >
          {item.telephone ? <Link to={`tel:${item.telephone}`}>{item.telephone}</Link> : '—'}
        </Typography>
      </GridCell>
      <GridCell>
        <TypographyWithOverflowHint
          sx={{
            a: {
              color: 'common.grey2',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          }}
          noWrap
        >
          <Link to={`mailto:${item.email}`}>{item.email}</Link>
        </TypographyWithOverflowHint>
      </GridCell>
    </TableRow>
  );
};
