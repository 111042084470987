import { Box, Skeleton, Stack } from '@mui/material';
import { DefaultSchoolYear } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isBefore } from 'date-fns';
import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { DropdownSelect, DropdownSelectProps } from '../../DropdownSelect';
import { NoPeriodLabel } from '../DatePicker/DatePickerCommonComponents';
import { DateRangeSelectContent } from '../DatePicker/DateRangeSelectContent';
import { RangePickerHeader } from '../DatePicker/RangePickerHeader';
import { SchoolPeriodSelectList } from './SchoolPeriodSelectList';
import { SchoolPeriodsList } from './SchoolPeriodsList';
import { Period, useSchoolPeriods } from './useSchoolPeriods';
export interface SchoolPeriodsRangeSelectComponent {
  updateDates: (dates: string[]) => void;
}
type SchoolPeriodsRangeSelectProps = {
  schoolId: string;
  onSetDate: (v: [Date, Date]) => void;
  date: string[];
  defaultSchoolYear?: DefaultSchoolYear;
  hasManagePermission: boolean;
  showActualPeriods?: boolean;
  fromDateError?: boolean;
  toDateError?: boolean;
  shouldOpen?: boolean;
  renderHint?: ReactNode;
  isLoading?: boolean;
  renderCustomHeader?: (v: { fromDate: Date | null; toDate: Date | null }) => ReactNode;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const SchoolPeriodsRangeSelect = forwardRef<
  SchoolPeriodsRangeSelectComponent,
  SchoolPeriodsRangeSelectProps
>(
  (
    {
      schoolId,
      date,
      onSetDate,
      defaultSchoolYear,
      hasManagePermission,
      showActualPeriods,
      fromDateError,
      toDateError,
      shouldOpen,
      renderHint,
      isLoading,
      renderCustomHeader,
      ...dropdownProps
    },
    ref,
  ) => {
    const [periodListOpen, setPeriodListOpen] = useState(false);
    const dropdown = useRef<DropdownSelect | null>(null);

    const defaultFromDate =
      defaultSchoolYear && !date[0] ? newDateTimezoneOffset(defaultSchoolYear?.start) : null;
    const defaultToDate =
      defaultSchoolYear && !date[1] ? newDateTimezoneOffset(defaultSchoolYear.end) : null;
    const [dates, setDates] = useState({
      fromDate: date[0] ? newDateTimezoneOffset(date[0]) : defaultFromDate,
      toDate: date[1] ? newDateTimezoneOffset(date[1]) : defaultToDate,
    });
    const prevFromDate = useRef(dates.fromDate);
    const prevToDate = useRef(dates.toDate);
    const { fromDate, toDate } = dates;
    const handleSetDates = useCallback((fromDate: Date, toDate: Date) => {
      setDates({ fromDate, toDate });
      dropdown.current?.close();
    }, []);
    useImperativeHandle(
      ref,
      () => ({
        updateDates: (dates) => {
          setDates({
            fromDate: newDateTimezoneOffset(dates[0]),
            toDate: newDateTimezoneOffset(dates[1]),
          });
        },
      }),
      [],
    );
    const handleSetDate = useCallback((date: Date) => {
      setDates((dates) => {
        const { fromDate, toDate } = dates;
        if (fromDate && toDate) {
          return {
            fromDate: date,
            toDate: null,
          };
        }
        if (!fromDate) {
          return { ...dates, fromDate };
        }
        if (isBefore(date, fromDate)) {
          return {
            toDate: dates.fromDate,
            fromDate: date,
          };
        }
        return { ...dates, toDate: date };
      });
    }, []);
    useEffect(() => {
      if (!fromDate || !toDate) return;
      if (
        prevFromDate.current?.getTime() === fromDate.getTime() &&
        prevToDate.current?.getTime() === toDate.getTime()
      )
        return;
      prevFromDate.current = fromDate;
      prevToDate.current = toDate;
      onSetDate([fromDate, toDate]);
    }, [fromDate, toDate, onSetDate, date]);
    useEffect(() => {
      if (defaultFromDate && defaultToDate) {
        onSetDate([defaultFromDate, defaultToDate]);
      }
    }, [defaultFromDate, defaultToDate, onSetDate]);
    useEffect(() => {
      if (shouldOpen) {
        dropdown.current?.open();
      }
    }, [defaultFromDate, defaultToDate, onSetDate, shouldOpen]);
    const handleClose = useCallback(() => {
      if (!fromDate) return;
      if (
        prevFromDate.current?.getTime() === fromDate.getTime() &&
        prevToDate.current?.getTime() === toDate?.getTime()
      ) {
        return;
      }
      onSetDate([fromDate, toDate ?? fromDate]);
      if (!fromDate || !toDate) {
        setDates({ ...dates, toDate: date[1] ? newDateTimezoneOffset(date[1]) : null });
      }
    }, [fromDate, toDate, onSetDate, dates, date]);
    const {
      changePeriod,
      currentPeriod,
      label,
      currentSchoolYear,
      schoolYears,
      isCurrentYear,
      isThisMonth,
      isThisWeek,
      isToday,
    } = useSchoolPeriods(schoolId, date, defaultSchoolYear);
    const handleSetPeriod = useCallback(
      (data: Period) => {
        changePeriod(data);
        handleSetDates(
          newDateTimezoneOffset(data.period.date_from),
          newDateTimezoneOffset(data.period.date_to),
        );
      },
      [changePeriod, handleSetDates],
    );
    const togglePeriodList = useCallback(() => {
      setPeriodListOpen((s) => !s);
    }, []);

    const renderHeaderContent = useCallback(
      (opened: boolean) => {
        if (isLoading) return <Skeleton />;

        if (renderCustomHeader) return renderCustomHeader({ fromDate, toDate });

        const header = (
          <RangePickerHeader
            startDate={fromDate}
            endDate={toDate}
            requiredLabel={dropdownProps.requiredLabel}
            fromDateColor={fromDateError ? 'error.main' : undefined}
            toDateColor={toDateError ? 'error.main' : undefined}
            layoutStyleProps={{
              borderBottom: 'none',
              padding: 0,
            }}
          >
            {renderHint}
          </RangePickerHeader>
        );

        return opened ? (
          header
        ) : (
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            {header}

            {label && (
              <Box>
                <TagSelect size="small" label={label} />
              </Box>
            )}
          </Stack>
        );
      },
      [
        renderCustomHeader,
        dropdownProps.requiredLabel,
        fromDate,
        fromDateError,
        isLoading,
        label,
        renderHint,
        toDate,
        toDateError,
      ],
    );

    return (
      <DropdownSelect
        ref={dropdown}
        hasValues
        onClose={handleClose}
        maxExpandedContainerWidth={500}
        expandedContainerStyleProps={{
          maxHeight: 'unset',
        }}
        renderContent={() => {
          return periodListOpen ? (
            <SchoolPeriodsList
              schoolYears={schoolYears ?? []}
              onSetPeriod={handleSetPeriod}
              defaultSchoolYear={currentSchoolYear}
              onGoBack={togglePeriodList}
              currentPeriod={currentPeriod}
              showActualPeriods={showActualPeriods}
              withoutPeriodLabel={(yearId) => (
                <NoPeriodLabel hasManagePermission={hasManagePermission} yearId={yearId} />
              )}
            />
          ) : (
            <Stack justifyContent="center">
              <DateRangeSelectContent
                fromDate={fromDate}
                toDate={toDate}
                onSetDate={handleSetDate}
              />
              <Stack mx={2}>
                <SchoolPeriodSelectList
                  defaultSchoolYear={currentSchoolYear}
                  onSetDate={(start, end) =>
                    handleSetDates(newDateTimezoneOffset(start), newDateTimezoneOffset(end))
                  }
                  onShowListClick={togglePeriodList}
                  isThisWeek={isThisWeek}
                  isThisMonth={isThisMonth}
                  isCurrentYear={isCurrentYear}
                  isToday={isToday}
                />
              </Stack>
            </Stack>
          );
        }}
        {...dropdownProps}
      >
        {renderHeaderContent}
      </DropdownSelect>
    );
  },
);
