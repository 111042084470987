import {
  ApiError,
  CustomField,
  CustomFieldValue,
  useSetCustomFieldValueForRelationMutation,
} from '@schooly/api';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import React, { FC, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form-lts';

import { useProfile } from '../../../../context/profile/useProfile';
import IntlError from '../../../../utils/intlError';
import { PropertyModal2 } from '../PropertyModal2';

export interface CustomFieldStringModalProps {
  onClose: () => void;
  isOpen: boolean;
  field: CustomField;
  value?: CustomFieldValue;
}

interface CustomFieldFormStringData {
  value?: string;
}

export const CustomFieldStringModal: FC<CustomFieldStringModalProps> = ({
  isOpen,
  onClose,
  field,
  value,
}) => {
  const { showError } = useNotifications();

  const { user, schoolMembership } = useProfile();
  const setCustomFieldValueForRelation = useSetCustomFieldValueForRelationMutation();

  const stringValue = `${value?.value ?? ''}`;

  const form = useForm<CustomFieldFormStringData>({
    mode: 'onChange',
    defaultValues: {
      value: stringValue,
    },
  });

  const {
    reset,
    formState: { isValid },
  } = form;

  const handleSubmit: SubmitHandler<CustomFieldFormStringData> = useCallback(
    async ({ value: newValue }) => {
      if (!schoolMembership) {
        return;
      }

      try {
        const res = await setCustomFieldValueForRelation.mutateAsync({
          relationId: schoolMembership.relation_id,
          field,
          value: !newValue || (newValue as unknown as boolean) === true ? null : newValue,
        });

        if (!res) return;
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        onClose();
      }
    },
    [field, onClose, schoolMembership, setCustomFieldValueForRelation, showError],
  );

  useEffect(() => {
    reset({ value: stringValue });
  }, [reset, stringValue, isOpen]);

  return (
    <PropertyModal2
      isOpen={isOpen}
      user={user}
      submitDisabled={!isValid}
      isUpdating={setCustomFieldValueForRelation.isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <ControlTextField
        name="value"
        type="text"
        fullWidth
        rules={{ required: field.required }}
        label={field.label}
      />
    </PropertyModal2>
  );
};
