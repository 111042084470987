import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46967 6.46967C2.76256 6.17678 3.23744 6.17678 3.53033 6.46967L10 12.9393L16.4697 6.46967C16.7626 6.17678 17.2374 6.17678 17.5303 6.46967C17.8232 6.76256 17.8232 7.23744 17.5303 7.53033L10.5303 14.5303C10.2374 14.8232 9.76256 14.8232 9.46967 14.5303L2.46967 7.53033C2.17678 7.23744 2.17678 6.76256 2.46967 6.46967Z"
      fill="currentColor"
    />
  </svg>
);
