import React from 'react';

import LandingLayout from '../../components/ui/LandingLayout';

import './index.scss';

const MaintenancePage: React.FC = () => (
  <LandingLayout hideSidebar className="Maintenance" titleId="maintenance-ApplicationInProgress" />
);

export default MaintenancePage;
