import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { EditIcon, PlusIcon } from '@schooly/style';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { useSchool } from '../../../../hooks/useSchool';

const DISPLAY_ROWS = 2;

export const SchoolGeneralAgeGroups: FC = () => {
  const { schoolId = '' } = useSchool();
  const [expanded, setExpanded] = useState(false);

  const { activeAgeGroups, isFetching } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const count = activeAgeGroups.length ?? 0;
  const hasRows = count > 0;
  const canExpand = !isFetching && count > DISPLAY_ROWS;

  const rows = useMemo(
    () => (activeAgeGroups || []).slice(0, !canExpand || expanded ? undefined : DISPLAY_ROWS),
    [activeAgeGroups, canExpand, expanded],
  );

  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  return (
    <Card component={Stack} sx={{ minHeight: 224 }}>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center">
            <FormattedMessage id="school-tabs-AgeGroups" />
            {isFetching ? (
              <Skeleton
                variant="circular"
                sx={(theme) => ({
                  ml: 1,
                  width: theme.spacing(2.5),
                  height: theme.spacing(2.5),
                })}
              />
            ) : hasRows ? (
              <Counter>{count}</Counter>
            ) : null}
          </Stack>
        }
        action={
          hasRows ? (
            <Link to="/settings/age_groups">
              <IconButton inverse>
                <EditIcon />
              </IconButton>
            </Link>
          ) : undefined
        }
      />
      <CardContent
        component={Stack}
        sx={{ flex: '1', justifyContent: 'space-between', alignItems: 'flex-start' }}
      >
        <GridContainer
          sx={{
            borderRadius: 0,
            borderBottom: (theme) =>
              !isFetching && hasRows ? theme.mixins.borderValue() : undefined,
          }}
        >
          {isFetching ? (
            <Stack>
              {new Array(3).fill(true).map((_, index) => (
                <GridRowStyled key={index} noBorder noBorderRadius>
                  <GridRowItem>
                    <GridRowName>
                      <Skeleton />
                    </GridRowName>
                  </GridRowItem>
                </GridRowStyled>
              ))}
            </Stack>
          ) : hasRows ? (
            rows.map((ageGroup) => (
              <GridRowStyled key={ageGroup.id} noBorderRadius>
                <GridRowItem>
                  <GridRowName>{ageGroup.name}</GridRowName>
                </GridRowItem>
              </GridRowStyled>
            ))
          ) : (
            <Typography variant="h3">
              <FormattedMessage id="school-tabs-AgeGroups-NoActive" />
            </Typography>
          )}
        </GridContainer>

        {canExpand && (
          <SimpleButton sx={{ mt: 2.5 }} onClick={toggleExpand}>
            {expanded ? (
              <FormattedMessage id="action-Hide" />
            ) : (
              <FormattedMessage
                id="action-ViewMore-number"
                values={{ number: activeAgeGroups.length - rows.length }}
              />
            )}
          </SimpleButton>
        )}

        {!hasRows && (
          <Link to="/settings/age_groups">
            <SimpleButton sx={{ mt: 2.5 }} startIcon={<PlusIcon />} onClick={toggleExpand}>
              <FormattedMessage id="school-tabs-AgeGroups-AddAgeGroup" />
            </SimpleButton>
          </Link>
        )}
      </CardContent>
    </Card>
  );
};
