import { Box, Stack, Typography } from '@mui/material';
import { ApplicationChildShort, ApplicationShort, Parent } from '@schooly/api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getUserFullName } from 'apps/web/src/helpers/users';
import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeaderSearchFooter } from '../../../components/common/HeaderFilter/HeaderSearch/HeaderSearchFooter';
import { useFilters } from '../../../context/filters/useFilters';
import { searchByName } from '../../../helpers/applications';
import addHighlight from '../../../utils/addHighlight';

interface ApplicationsSearchContentProps {
  applicationList: ApplicationShort[];
  onClose: () => void;
}

export const ApplicationsSearchContent: FC<ApplicationsSearchContentProps> = ({
  applicationList,
  onClose,
}) => {
  const { filters, applyFilters } = useFilters();

  const filteredApplications = useMemo(() => {
    if (!applicationList || !filters.draftQuery) return [];

    const filteredList = applicationList.reduce<
      Array<{ id: string; users: Array<ApplicationChildShort | Parent> }>
    >((acc, { adults, children, id }) => {
      const users = [...children, ...adults].filter((p) =>
        searchByName({ ...p, searchQuery: filters.draftQuery }),
      );

      return Boolean(users.length)
        ? [
            ...acc,
            {
              id,
              users,
            },
          ]
        : acc;
    }, []);

    return filteredList ?? null;
  }, [filters.draftQuery, applicationList]);

  const onNameClick = useCallback(
    (query: string) => {
      applyFilters({
        query,
        apply: true,
        noDebounce: true,
      });
      onClose();
    },
    [applyFilters, onClose],
  );

  if (!filteredApplications.length) {
    return (
      <Box py={1.5} px={2}>
        <Typography variant="h3" color="text.primary">
          <FormattedMessage id="noResultsMatching" values={{ filter: filters.draftQuery }} />
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box py={1.5} px={1.5}>
        <Stack gap={1}>
          {filteredApplications.map(({ users }) => {
            return users.map((user) => {
              const userName = getUserFullName(user);

              return (
                <Stack direction="row">
                  <Typography
                    onClick={() => onNameClick(userName)}
                    variant="h3"
                    sx={{
                      ':hover': {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      },
                    }}
                  >
                    {addHighlight(userName, filters.draftQuery)}
                  </Typography>
                </Stack>
              );
            });
          })}
        </Stack>
      </Box>
      <Box
        overflow="hidden"
        sx={(theme) => ({
          borderRadius: theme.spacing(0, 0, theme.shape.borderRadius, theme.shape.borderRadius),
        })}
      >
        {/* CopyPaste from legacy component */}
        <HeaderSearchFooter
          text={
            <FormattedMessage
              id="action-ShowAllByMatching"
              values={{
                entities: 'applications',
                filter: filters.draftQuery,
              }}
            />
          }
        />
      </Box>
    </>
  );
};
