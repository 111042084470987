import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4H13V6H7V4ZM6 6V4C6 3.44772 6.44772 3 7 3H13C13.5523 3 14 3.44772 14 4V6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17V7C2 6.44772 2.44772 6 3 6H6Z"
      fill="#B3BBCB"
    />
  </svg>
);
