import { Box, Skeleton, Stack, styled, Typography, useTheme } from '@mui/material';
import {
  GetPayableFeesStatisticsParams,
  PayableFeesStatisticsItem,
  PayableFeeStatus,
  useGetPayableFeesStatisticsQuery,
} from '@schooly/api';
import { Currencies } from '@schooly/constants';
import { DropdownCurrencies, HashIcon, PercentIcon } from '@schooly/style';
import _ from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { PieChart } from 'react-minimal-pie-chart';

import {
  PercentButton,
  PercentIconButton,
} from '../../../pages/Attendance/AttendanceStatistics/AttendanceStats.styled';
import { getChartStatisticsByCurrencyMap, payableFeeStatusColor } from './helpers';
import { PayableFeeFilter } from './PayableFees';

type ChartStatistics = {
  label: PayableFeeStatus;
  value: number;
  color: string;
  opacity: number;
  title: string;
};

type PayableFeesChartProps = {
  onCloseChart: () => void;
  columns: number;
  onSetFilter: (f?: PayableFeeFilter) => void;
  selectedStatus?: PayableFeeStatus;
} & GetPayableFeesStatisticsParams;

export const PayableFeesChart: FC<PayableFeesChartProps> = ({
  onCloseChart,
  columns = 3,
  onSetFilter,
  selectedStatus,
  ...props
}) => {
  const { $t } = useIntl();
  const theme = useTheme();

  const [showPercents, setShowPercents] = useState(false);
  const [currency, setCurrency] = useState<Currencies>();

  const { isLoading, data } = useGetPayableFeesStatisticsQuery(props, { refetchOnMount: 'always' });

  const statisticsByCurrencyMap = useMemo(
    () => getChartStatisticsByCurrencyMap(data?.statistics),
    [data?.statistics],
  );

  const currencies = useMemo(
    () => data?.statistics.map((s) => s.currency) ?? [],
    [data?.statistics],
  );

  const total = currency ? statisticsByCurrencyMap?.[currency]?.total ?? 0 : 0;

  useEffect(() => {
    if (!isLoading && currencies.length) {
      setCurrency(currencies[0]);
    }
  }, [currencies, isLoading]);

  const pieChartData = useMemo(() => {
    if (!currency || !statisticsByCurrencyMap) return [];

    return statisticsByCurrencyMap?.[currency]?.items.reduce<ChartStatistics[]>(
      (acc, { label, value }: PayableFeesStatisticsItem) => {
        if (!value) return acc;
        return [
          ...acc,
          {
            title: $t({ id: `payableFees-status-${label}` }),
            label,
            value: showPercents ? _.round((value / total) * 100, 0) : value,
            color: payableFeeStatusColor[label],
            opacity: selectedStatus && label !== selectedStatus ? 0.3 : 1,
          },
        ];
      },
      [],
    );
  }, [$t, currency, selectedStatus, showPercents, statisticsByCurrencyMap, total]);

  const showCurrencySelect = currency && currencies.length > 1;
  const shouldShowSkeleton = isLoading;

  if (!isLoading && !total) return null;

  return (
    <PayableFeesCharLayout>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          py: 2.5,
          pl: 2.5,
          gap: (theme) => theme.spacing(4.5),
        }}
      >
        <Box sx={{ height: (theme) => theme.spacing(16) }}>
          <PieChart
            data={pieChartData || []}
            lineWidth={38}
            background={theme.palette.background.default}
            segmentsStyle={(i) => {
              const item = pieChartData?.[i];
              if (!item || !selectedStatus) return {};
              if (selectedStatus === item.label) return;
              return {
                opacity: 0.3,
              };
            }}
            label={(props) => {
              return (
                // Label accept only svg tags
                <text {...props}>
                  <tspan>{total.toLocaleString() ?? ''}</tspan>
                  <tspan
                    {...props}
                    dy={props.dy + 10}
                    style={{
                      paddingTop: '2px',
                      fontSize: '8px',
                      fill: theme.palette.common.grey2,
                    }}
                  >
                    {currency}
                  </tspan>
                </text>
              );
            }}
            labelStyle={{
              fontSize: theme.typography.h3.fontSize,
              fill: theme.palette.primary.main,
            }}
            labelPosition={0}
          />
        </Box>
        <Stack flexWrap="wrap" height="122px" gap={0.5} columnGap={8.5}>
          {pieChartData?.map(({ title, color, value, label, opacity }) => (
            <Stack
              key={title}
              p={1}
              direction="row"
              alignItems="center"
              sx={{ opacity, cursor: 'pointer' }}
              onClick={() => onSetFilter({ status: label, currency })}
            >
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: color,

                  whiteSpace: 'nowrap',
                }}
              />
              <Stack direction="row" pl={1.5} gap={0.75} alignItems="center">
                <Typography variant="h3" color="text.primary">
                  {title}
                </Typography>
                <Typography variant="h3">
                  {showPercents ? `${value}%` : value.toLocaleString()}
                </Typography>
              </Stack>
            </Stack>
          ))}
          {shouldShowSkeleton &&
            [...new Array(5)].map((_, i) => (
              <Stack key={i} p={1} width={100 / columns + '%'}>
                <Skeleton width="100px" />
              </Stack>
            ))}
        </Stack>
      </Stack>

      <Stack alignSelf="baseline" p={2.5} direction="row" gap={1} justifyContent="center">
        {showCurrencySelect && (
          <DropdownCurrencies
            actions={currencies.map((c) => ({
              title: c,
              handler: () => {
                setCurrency(c);
                onSetFilter();
              },
            }))}
            selectedCurrency={currency}
          />
        )}

        <PercentButton variant="outlined" onClick={() => setShowPercents((val) => !val)}>
          <PercentIconButton>{showPercents ? <HashIcon /> : <PercentIcon />}</PercentIconButton>
        </PercentButton>
      </Stack>
    </PayableFeesCharLayout>
  );
};

export const PayableFeesCharLayout = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
}));
