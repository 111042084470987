export const DATE_FORMAT = 'dd MMM yyyy';
export const DATE_FORMAT_SHORT = 'dd MMM';
export const DATE_FORMAT_MOMENT = 'DD MMM YYYY';

export const DATE_FORMAT_SHORT_MOMENT = 'DD MMM';

export const DATE_FORMAT_RANGE_MOMENT = 'D MMM yyyy';
export const DATE_TODAY_FORMAT = '[Today]';

export const TIME_FORMAT = 'HH:mm';
