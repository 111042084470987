import { useCallback, useContext } from 'react';

import { RecurringConfirmForm } from './RecurringConfirmModal';
import {
  RecurringConfirmDialogConfig,
  RecurringConfirmDialogContext,
} from './WithRecurringConfirmDialog';

export const useRecurringConfirmDialog = () => {
  const { openDialog } = useContext(RecurringConfirmDialogContext);

  const getRecurringApplyTypeConfirm = useCallback(
    ({
      ...options
    }: Omit<RecurringConfirmDialogConfig, 'callback'>): Promise<RecurringConfirmForm | null> =>
      new Promise((res) => {
        openDialog({ callback: res, ...options });
      }),
    [openDialog],
  );

  return { getRecurringApplyTypeConfirm };
};
