import { WithName } from '@schooly/api';

export function getUserFullName(user: WithName, userGivenName?: boolean) {
  if (!user) return '';

  let first_name = user.given_name;

  if (!userGivenName && user.known_as) {
    first_name = user.known_as;
  }

  if (!user.last_name) {
    return first_name ?? '';
  }

  return `${first_name} ${user.last_name}`;
}

export const getInitials = (name: string) =>
  name
    .split(' ')
    .map((w) => w[0])
    .slice(0, 2)
    .join('');
