import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ReportStatuses } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { DATE_FORMAT_MOMENT as DATE_FORMAT } from '@schooly/constants';
import { CrossIcon, EditIcon, IncompleteSvg, Loading } from '@schooly/style';
import moment from 'moment';
import React, { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import { ModalLarge } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import { WithFilters } from '../../../context/filters/WithFilters';
import { useReport } from '../../../context/report/WithReport';
import { useRouter } from '../../../context/router/useRouter';
import { allowRenderReports } from '../../../helpers/renderRoutesByPermissions';
import useAppLocation from '../../../hooks/useAppLocation';
import usePrevious from '../../../hooks/usePrevious';
import { useAppDispatch } from '../../../redux/hooks';
import { actions, ReportsPreviewLocation } from '../../../redux/slices/reports/previewSlice';
import { ReportsPreviewActions } from './ReportsPreviewActions';
import { ReportsPreviewContent } from './ReportsPreviewContent';

import './ReportsPreviewModal.scss';

export const ReportsPreviewModal: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useAppLocation<ReportsPreviewLocation>();
  const { permissions } = useAuth();
  const { closeAndClean } = useRouter();
  const { id } = useParams<'id'>();

  const { report, isFetching, onChangeStatus, isChangingStatus, canEdit } = useReport();

  const prevFetching = usePrevious(isFetching);
  const { $t } = useIntl();
  const userHasAccess = allowRenderReports(permissions);

  const showEditButton = canEdit;

  const handleEditButtonClick = useCallback(() => {
    navigate(`/reports/${report?.id}/edit`, {
      state: { ...location.state, replace: report?.report_status === ReportStatuses.Draft },
    });
  }, [location.state, navigate, report?.id, report?.report_status]);

  useEffect(() => {
    if (report?.id === id) {
      return;
    }

    if (location.state?.initialState) {
      dispatch(actions.setData(location.state.initialState));
    } else if (id) {
      dispatch(actions.get({ id }));
    }
  }, [dispatch, id, location.state?.initialState, report?.id]);

  useEffect(() => {
    if (prevFetching && !isFetching && !report) {
      navigate('/reports');
    }
  }, [dispatch, isFetching, navigate, prevFetching, report]);

  const date = moment(report?.scheduled_publish_date).format(DATE_FORMAT);

  const renderContent = () => {
    if (!report)
      return (
        <Box position="absolute" width="100%" height="100%">
          <Loading />
        </Box>
      );

    return (
      <>
        <ModalHeaderV2
          subHeader={date && <Typography color="text.primary">{date}</Typography>}
          title={report.name}
          withGoBack
          active
        >
          <ReportsPreviewActions
            isLoading={isChangingStatus}
            onClick={onChangeStatus}
            report={report}
          />
          {showEditButton && (
            <IconButton onClick={handleEditButtonClick} data-test-id="report-modal-edit-button">
              <EditIcon />
            </IconButton>
          )}

          <IconButton onClick={closeAndClean} data-test-id="report-modal-close-button">
            <CrossIcon />
          </IconButton>
        </ModalHeaderV2>
        <WithFilters type="sidebar-report-recipients">
          {report.report_status === ReportStatuses.Draft ? (
            <Stack
              sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                gap: theme.spacing(2.5),
              })}
            >
              <IncompleteSvg />
              <Typography
                variant="h3"
                color="text.primary"
                sx={{ textAlign: 'center', maxWidth: 300 }}
              >
                {$t({ id: 'reports-NotConfigured' })}
              </Typography>
              {showEditButton && (
                <Button
                  startIcon={<EditIcon />}
                  onClick={() => navigate('edit', { state: { replace: true } })}
                >
                  {$t({ id: 'action-ContinueSetup' })}
                </Button>
              )}
            </Stack>
          ) : (
            <ReportsPreviewContent report={report} />
          )}
        </WithFilters>
      </>
    );
  };

  return (
    <ModalLarge open onClose={closeAndClean}>
      {userHasAccess ? renderContent() : <AccessDenied />}
    </ModalLarge>
  );
};
