import { IconButton, Stack, Typography } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { DeleteIcon, DragIcon } from '@schooly/style';
import { FC } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SchoolCustomFieldForm } from './SchoolCustomFieldsModalContent';

export interface SelectRowProps {
  rowIdx: number;
  maxBodyLength?: number;
  provided: DraggableProvided;
  onRemoveOption: (id: string) => void;
}

export const SchoolCustomFieldsSelectRow: FC<SelectRowProps> = ({
  rowIdx,
  maxBodyLength,
  provided,
  onRemoveOption,
}) => {
  const { formatMessage } = useIntl();
  const { control, watch } = useFormContext<SchoolCustomFieldForm>();

  const option = watch(`select_type_options.${rowIdx}`);
  const optionList = watch('select_type_options');

  const bodyLength = option.label.length;
  const maxLengthError = !!bodyLength && !!maxBodyLength && bodyLength > maxBodyLength;

  return (
    <>
      <Stack
        ref={provided.innerRef}
        {...provided.draggableProps}
        direction="row"
        alignItems="baseline"
        gap={1}
        style={provided.draggableProps.style}
      >
        <IconButton inverse {...provided.dragHandleProps}>
          <DragIcon />
        </IconButton>

        <Stack sx={{ flex: '1 1 90%', position: 'relative', mb: 3 }}>
          <ControlTextField
            label={formatMessage({ id: 'option' })}
            name={`select_type_options.${rowIdx}.label`}
            required
            error={maxLengthError}
            control={control}
            rules={{
              validate: (value) => {
                if (!value) {
                  return optionList?.length === 1
                    ? formatMessage({
                        id: 'school-sections-CustomFields-Message-OneOptionRequired',
                      })
                    : formatMessage({
                        id: 'input-ErrorRequired',
                      });
                }
                if (maxLengthError) {
                  return formatMessage({
                    id: 'school-sections-CustomFields-Message-CharacterLimit',
                  });
                }

                return true;
              },
            }}
            sx={() => ({
              '.MuiFormHelperText-root': {
                position: 'absolute',
                left: '0',
                bottom: '-20px',
              },
            })}
          />

          {bodyLength !== undefined && maxBodyLength !== undefined && (
            <Typography
              variant="caption"
              textAlign="right"
              color={maxLengthError ? 'error.main' : 'common.grey'}
              sx={{ position: 'absolute', right: '0', bottom: '-20px' }}
            >
              {bodyLength}
              {!!maxBodyLength && ` / ${maxBodyLength}`}
            </Typography>
          )}
        </Stack>

        {Number(optionList?.length) > 1 && (
          <IconButton
            sx={{
              display: 'flex',
              visibility: 'visible',
              alignSelf: 'start',
              pt: 1.8,
            }}
            size="small"
            inverse
            onClick={() => onRemoveOption(option.id)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    </>
  );
};
