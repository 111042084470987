import { Button, IconButton, Typography } from '@mui/material';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, CrossIcon, Loading, ModalLarge, Spin } from '@schooly/style';
import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

import { ModalContent, ModalFooter } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalAccessDenied } from '../../../components/uikit-components/Modal/ModalAccessDenied';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { useRouter } from '../../../context/router/useRouter';
import usePrevious from '../../../hooks/usePrevious';
import IntlError from '../../../utils/intlError';
import { TakeAttendanceModalContent } from './TakeAttendanceModalContent/TakeAttendanceModalContent';
import { useTakeAttendanceModal } from './useTakeAttendanceModal';

export const TakeAttendanceModal: FC = () => {
  const { goBack } = useRouter();
  const { showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const {
    form,
    errors,
    saving,
    isModified,
    actions,
    canEditAttendance,
    canTakeAttendance,
    groupDetails,
    attendanceFetching,
  } = useTakeAttendanceModal(true); // Initialize RouterStateContext

  const prevError = usePrevious(errors?.entries);

  const handleClose = useCallback(async () => {
    if (
      !isModified ||
      (await getConfirmation({
        textId: 'attendance-TakeAttendanceCloseConfirmation',
      }))
    ) {
      goBack();
    }
  }, [goBack, isModified, getConfirmation]);

  /* Display form error snackbar */
  useEffect(() => {
    if (!prevError && errors?.entries) {
      showError(new IntlError((errors.entries as FormattedMessageProps).id!));
    }
  }, [errors?.entries, prevError, showError]);

  if (attendanceFetching) {
    return (
      <ModalLarge open onClose={handleClose}>
        <ModalContent active>
          <Loading />
        </ModalContent>
      </ModalLarge>
    );
  }

  if (!canTakeAttendance && !canEditAttendance) {
    return (
      <ModalLarge open onClose={handleClose}>
        <ModalHeader active>
          <IconButton onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent active>
          <ModalAccessDenied />
        </ModalContent>
      </ModalLarge>
    );
  }

  return (
    <ModalLarge open onClose={handleClose}>
      <ModalHeader
        active
        title={
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={(theme) => ({
                position: 'absolute',
                top: theme.spacing(1),
                left: theme.spacing(2),

                [theme.breakpoints.down('lg')]: {
                  left: theme.spacing(2.5),
                },
              })}
            >
              <FormattedMessage id="attendance-Title" />
            </Typography>
            {groupDetails?.name}
          </>
        }
        sx={{
          '& .ModalHeaderBackLink': {
            top: (theme) => theme.spacing(2.5),
          },
        }}
      >
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalContent
        active
        sx={(theme) => ({
          pb: 0,
          [theme.breakpoints.down('lg')]: {
            p: theme.spacing(1, 4, 0),
          },
        })}
      >
        <TakeAttendanceModalContent />
      </ModalContent>
      <ModalFooter sx={{ m: 0 }} active>
        <form onSubmit={form.onSubmit(actions.saveAttendance)}>
          <Button
            startIcon={saving ? <Spin /> : <CheckIcon />}
            disabled={saving || !isModified}
            type="submit"
          >
            <FormattedMessage id="action-Save" />
          </Button>
        </form>
      </ModalFooter>
    </ModalLarge>
  );
};
