import { FilterValue } from '@schooly/api';
import React from 'react';

import { useAppSelector } from '../../../redux/hooks';
import Tag from '../../ui/Tag';

export interface FilterReportTagsProps {
  values?: Partial<{
    staff: FilterValue[];
    student: FilterValue[];
  }>;
  onClick?: VoidFunction;
}

export const FilterReportTags: React.FC<FilterReportTagsProps> = ({ values, onClick }) => {
  const { results } = useAppSelector((state) => state.reports.list);

  if (!results || !results.length || !values?.student || !values.student.length) return null;

  const tags = results?.filter((r) => values?.student?.includes(r.id));

  return (
    <>
      {tags.map((t, i) => (
        <>
          {i > 0 && <span className="plus-separator">+</span>}
          <Tag isBig onClick={onClick}>
            {t.name}
          </Tag>
        </>
      ))}
    </>
  );
};
