import { ChipProps } from '@mui/material';
import { AssessmentStatuses } from '@schooly/constants';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

export type AssessmentStatusTagSelectProps = {
  status: AssessmentStatuses;
} & Omit<ChipProps, 'label'>;

export const AssessmentStatusTagSelect: FC<AssessmentStatusTagSelectProps> = ({
  status,
  ...props
}) => {
  const { $t } = useIntl();

  return (
    <TagSelect
      label={$t({
        id: status === AssessmentStatuses.Published ? 'status-Published' : 'status-NotPublished',
      })}
      {...props}
    />
  );
};
