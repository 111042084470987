import { Stack } from '@mui/material';
import { SchoolUserRole } from '@schooly/constants';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import { UserRoleTagSelect, UserRoleTagSelectProps } from './UserRoleTagSelect';

type UserRoleExpandedSelectProps = PropsWithChildren<{
  options: SchoolUserRole[];
  selectedRoles: SchoolUserRole[];
  onSelectUserRole: (v: SchoolUserRole) => void;
  onClear: () => void;
  onClose: () => void;
}>;
export const UserRoleExpandedSelect: FC<UserRoleExpandedSelectProps> = ({
  options,
  selectedRoles,
  onSelectUserRole,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderUserRoleTags({
          roles: options,
          tagProps: (role) => ({
            variant: selectedRoles.includes(role) ? 'filled' : undefined,
          }),
          onClick: onSelectUserRole,
        })}
      </Stack>
    );
  }, [onSelectUserRole, options, selectedRoles]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedRoles.length > 0}
      renderContent={renderContent}
      onClose={onClose}
      onClear={onClear}
    >
      {renderUserRoleTags({
        roles: selectedRoles,
        tagProps: { size: 'small' },
        onDelete: onSelectUserRole,
      })}
    </ExpandedSelect>
  );
};

type RenderUserRoleTagsParams = {
  roles: SchoolUserRole[];
  onDelete?: (v: SchoolUserRole) => void;
  onClick?: (v: SchoolUserRole) => void;
  tagProps?:
    | Omit<UserRoleTagSelectProps, 'role'>
    | ((v: SchoolUserRole) => Omit<UserRoleTagSelectProps, 'role'>);
};

export const renderUserRoleTags = ({
  roles,
  onDelete,
  onClick,
  tagProps,
}: RenderUserRoleTagsParams) =>
  roles.map((v) => (
    <UserRoleTagSelect
      key={v}
      role={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
