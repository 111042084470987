import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useGetUserRolesForRelationQuery, UserRole } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { EditIcon, Loading } from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { ProfileAccordion } from '../../../components/common/ProfileAccordion/ProfileAccordion';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import {
  GridContainer,
  GridRowCell,
  GridRowName,
  GridRowStyled,
} from '../../../components/uikit-components/Grid/Grid';
import { LongNameWithVerticalTooltip } from '../../../components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { ModalEmpty } from '../../../components/uikit-components/Modal/ModalEmpty';
import { useProfile } from '../../../context/profile/useProfile';

export const ProfileModalUserRoles: FC = () => {
  const navigate = useNavigate();
  const { schoolMembership } = useProfile();
  const { $t } = useIntl();

  const { data } = useGetUserRolesForRelationQuery(schoolMembership?.relation_id || '', {
    enabled: !!schoolMembership,
    refetchOnMount: 'always',
  });

  const { activeRoles, inactiveRoles } = useMemo(
    () =>
      [
        ...(data ? data.available_to_assign : []),
        ...(data ? data.not_allowed_to_assign : []),
      ].reduce(
        (acc, r) =>
          r.is_current
            ? { ...acc, activeRoles: [...acc.activeRoles, r] }
            : { ...acc, inactiveRoles: [...acc.inactiveRoles, r] },
        { activeRoles: [] as UserRole[], inactiveRoles: [] as UserRole[] },
      ),
    [data],
  );

  const total = activeRoles.length + inactiveRoles.length;

  const { permissions } = useAuth();

  const canEdit = permissions.includes('staff_manager');

  const handleManageRolesClick = useCallback(() => {
    navigate('user-roles');
  }, [navigate]);

  const renderRole = useCallback((role: UserRole) => {
    return (
      <GridRowStyled key={role.id} py={0.5}>
        <GridRowCell>
          <GridRowName variant="h3">
            <LongNameWithVerticalTooltip>{role.name}</LongNameWithVerticalTooltip>
          </GridRowName>
        </GridRowCell>
      </GridRowStyled>
    );
  }, []);

  const renderContent = useCallback(() => {
    if (!data) return <Loading />;
    if (!!data && !total) {
      return (
        <Stack pt={5}>
          <ModalEmpty width="300">
            <Typography variant="h3" color="text.primary">
              <FormattedMessage
                id="profile-MemberNotAssigned"
                values={{
                  //TODO use userType
                  memberType: 'Staff',
                }}
              />
            </Typography>
            {canEdit && (
              <Button
                onClick={() => {
                  navigate('user-roles');
                }}
              >
                <FormattedMessage id="userRoles-AssignTitle" />
              </Button>
            )}
          </ModalEmpty>
        </Stack>
      );
    }

    return (
      <Stack gap={2.5}>
        {!!activeRoles.length && (
          <ProfileAccordion title={$t({ id: 'groups-active' })}>
            <GridContainer>{activeRoles.map(renderRole)}</GridContainer>
          </ProfileAccordion>
        )}
        {!!inactiveRoles.length && (
          <ProfileAccordion
            title={$t({ id: 'groups-not-active' })}
            expandable
            expandedValue={false}
          >
            <GridContainer>{inactiveRoles.map(renderRole)}</GridContainer>
          </ProfileAccordion>
        )}
      </Stack>
    );
  }, [data, total, activeRoles, $t, renderRole, inactiveRoles, canEdit, navigate]);

  return (
    <Stack gap={2.5} height="100%">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="profile-UserRoles" />
          </Typography>
          {!!total && <Counter>{total}</Counter>}
        </Stack>

        {!!total && canEdit && (
          <IconButton onClick={handleManageRolesClick} inverse>
            <EditIcon />
          </IconButton>
        )}
      </Stack>

      {renderContent()}
    </Stack>
  );
};
