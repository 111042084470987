import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 9.5C3.75 9.91422 4.08579 10.25 4.5 10.25C4.91421 10.25 5.25 9.91422 5.25 9.5C5.25 7.76644 6.65533 6.36111 8.38889 6.36111H13.7621L12.021 8.20771C11.7368 8.50909 11.7508 8.98376 12.0522 9.26792C12.3535 9.55207 12.8282 9.53811 13.1124 9.23673L16.0457 6.12562C16.3181 5.8367 16.3181 5.38552 16.0457 5.0966L13.1124 1.98549C12.8282 1.68411 12.3535 1.67015 12.0522 1.95431C11.7508 2.23847 11.7368 2.71313 12.021 3.01451L13.7621 4.86111H8.38889C5.8269 4.86111 3.75 6.93802 3.75 9.5ZM16.25 10.5C16.25 10.0858 15.9142 9.75 15.5 9.75C15.0858 9.75 14.75 10.0858 14.75 10.5C14.75 12.2336 13.3447 13.6389 11.6111 13.6389L6.23794 13.6389L7.97903 11.7923C8.26318 11.4909 8.24922 11.0162 7.94784 10.7321C7.64647 10.4479 7.1718 10.4619 6.88764 10.7633L3.95431 13.8744C3.6819 14.1633 3.6819 14.6145 3.95431 14.9034L6.88764 18.0145C7.1718 18.3159 7.64647 18.3298 7.94784 18.0457C8.24922 17.7615 8.26318 17.2869 7.97903 16.9855L6.23794 15.1389H11.6111C14.1731 15.1389 16.25 13.062 16.25 10.5Z"
      fill="#24275B"
    />
  </svg>
);
