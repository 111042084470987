import { Box, Stack, TableCellProps, Typography } from '@mui/material';
import {
  AttendanceEntryForGroup,
  SimpleListResult,
  useGetAttendanceCodesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { CheckIcon } from '@schooly/style';
import React, { FC, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import PersonCardBasic from '../../../../../components/common/PersonCard/PersonCardBasic';
import { DropdownComments } from '../../../../../components/uikit-components/DropdownCommentsV2/DropdownComments';
import { CommentTableCell } from '../../../../../components/uikit-components/DropdownCommentsV2/DropdownCommentsWrappers/CommentTableCell';
import { GridCell, GridContainer } from '../../../../../components/uikit-components/Grid/Grid';
import { Table, TableCell } from '../../../../../components/uikit-components/Table/Table';

type GroupPreviewModalAttendanceEntriesProps = {
  entries: Record<string, AttendanceEntryForGroup>;
  students: SimpleListResult[];
};

export const GroupPreviewModalAttendanceEntries: FC<GroupPreviewModalAttendanceEntriesProps> = ({
  students,
  entries,
}) => {
  const { schoolId = '' } = useAuth();
  const { data: codes } = useGetAttendanceCodesQuery(schoolId);
  const containerRef = useRef<HTMLDivElement>(null);

  const attendanceCodes = useMemo(() => codes ?? [], [codes]);

  const columns: TableCell[] = useMemo(
    () => [
      {
        id: 'attendance-empty-cell',
        renderContent: (props) => <GridCell variant="head" sticky borderRight {...props} />,
        hoverProps: {
          disableHover: true,
        },
      },
      ...attendanceCodes.map((code) => ({
        id: code.id,
        renderContent: (props: TableCellProps) => (
          <GridCell
            key={`th-${code.code}`}
            variant="head"
            sx={{
              width: `${100 / attendanceCodes.length}%`,

              '&&': {
                color: 'text.primary',
                textAlign: 'center',
              },
            }}
            {...props}
          >
            {code.name}
          </GridCell>
        ),
      })),
      {
        id: 'attendance-NoStatus',
        renderContent: (props) => (
          <GridCell
            variant="head"
            sx={{
              '&&': {
                color: 'text.primary',
                textAlign: 'center',
              },
            }}
            {...props}
          >
            <FormattedMessage id="attendance-NoStatus" />
          </GridCell>
        ),
      },
      {
        id: 'comments',
        renderContent: (props) => (
          <GridCell
            variant="head"
            borderLeft
            sx={(theme) => ({
              width: theme.spacing(6),
            })}
            {...props}
          >
            <Box sx={(theme) => ({ width: theme.spacing(4) })} />
          </GridCell>
        ),
        hoverProps: {
          disableHover: true,
        },
      },
    ],
    [attendanceCodes],
  );

  const rows = useMemo(() => {
    if (!students.length) {
      return [];
    }

    return students.map((student) => {
      const noStatus = !entries?.[student.relation_id]?.attendance_code_id;

      const cellArray: TableCell[] = [
        {
          id: 'student',
          renderContent: (props) => (
            <GridCell sticky borderRight noVerticalPadding {...props}>
              <Box sx={{ width: 195 }}>
                <PersonCardBasic user={student} userType="student" isListItem isUsernameClickable />
              </Box>
            </GridCell>
          ),
        },
        ...attendanceCodes.map((code) => {
          return {
            id: code.id,
            renderContent: (props: TableCellProps) => (
              <GridCell
                key={`td-${student.relation_id}-${code.code}`}
                sx={{
                  textAlign: 'center',
                }}
                noVerticalPadding
                {...props}
              >
                {entries?.[student.relation_id]?.attendance_code_id === code.id ? (
                  <Typography variant="h2" component="span">
                    <CheckIcon />
                  </Typography>
                ) : null}
              </GridCell>
            ),
          };
        }),
        {
          id: 'attendance-NoStatus',
          renderContent: (props) => (
            <GridCell
              noVerticalPadding
              sx={{
                textAlign: 'center',
              }}
              key={`td-${student.relation_id}`}
              {...props}
            >
              {!!noStatus && (
                <Typography variant="h2" component="span">
                  <CheckIcon />
                </Typography>
              )}
            </GridCell>
          ),
        },
        {
          id: 'attendance-comments',
          renderContent: (props) => (
            <CommentTableCell borderLeft noVerticalPadding {...props}>
              {(onToggle, cellRef) =>
                entries?.[student.relation_id]?.text && (
                  <DropdownComments
                    comments={[
                      {
                        comment: entries[student.relation_id].text!,
                        relation_id: entries[student.relation_id].creator_relation_id!,
                        title: entries[student.relation_id].creator_title,
                        given_name: entries[student.relation_id].creator_given_name,
                        last_name: entries[student.relation_id].creator_last_name!,
                        known_as: entries[student.relation_id].creator_known_as,
                      },
                    ]}
                    canAdd={false}
                    canEditOwn={false}
                    canEditOther={false}
                    showAllComments
                    getParentRef={() => cellRef}
                    onToggle={onToggle}
                    tableView
                    ref={containerRef}
                  />
                )
              }
            </CommentTableCell>
          ),
          hoverProps: {
            onlyXHover: true,
          },
        },
      ];

      return { id: student.relation_id, cells: cellArray };
    });
  }, [attendanceCodes, entries, students]);

  return (
    <Stack
      sx={(theme) => ({
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
          overflowX: 'scroll',
          paddingRight: 2.5,
          '@media (orientation: portrait)': {
            marginRight: -2.5,
            paddingRight: 0,
          },
        },
      })}
    >
      <GridContainer
        ref={containerRef}
        sx={(theme) => ({
          overflow: 'auto',
          [theme.breakpoints.down('md')]: {
            py: 0.5,
          },
        })}
      >
        <Table rows={rows} columns={columns} />
      </GridContainer>
    </Stack>
  );
};
