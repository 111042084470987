import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import { EditIcon, Loading, LockIcon, PlusIcon } from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { Counter } from '../../../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../../../components/uikit/SimpleButton/SimpleButton';
import {
  GridContainer,
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../../components/uikit-components/Grid/Grid';
import { useUserRolesForSchool } from '../../../../../context/userRoles/useUserRolesForSchool';

export const SchoolTuneAccess: FC = () => {
  const { userRoles, isLoading } = useUserRolesForSchool();
  const navigate = useNavigate();

  const roles = useMemo(() => {
    if (!userRoles) return [];
    return [...userRoles.available_to_assign, ...userRoles.not_allowed_to_assign];
  }, [userRoles]);

  const onEditClick = useCallback(
    (roleId: string) => {
      navigate(`user_roles/${roleId}/edit`);
    },
    [navigate],
  );

  const onAddRoleClick = useCallback(() => {
    navigate('user_roles/create');
  }, [navigate]);

  const renderContent = () => (
    <GridContainer>
      {roles?.map((role) => {
        return (
          <GridRowStyled
            key={role.id}
            sx={{
              '& :hover': {
                '.editRoleIcon': {
                  visibility: 'visible',
                },
              },
            }}
          >
            <GridRowItem>
              {role.editable ? (
                <>
                  <Link to={`user_roles/${role.id}`}>
                    <GridRowName>{role.name}</GridRowName>
                  </Link>

                  <IconButton
                    className="editRoleIcon"
                    sx={{
                      visibility: 'hidden',
                    }}
                    inverse
                    onClick={() => onEditClick(role.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              ) : (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Link to={`user_roles/${role.id}`}>
                    <GridRowName>{role.name}</GridRowName>
                  </Link>

                  <GridRowCell>
                    <Icon sx={{ color: 'text.secondary' }}>
                      <LockIcon />
                    </Icon>
                  </GridRowCell>
                </Stack>
              )}
            </GridRowItem>
          </GridRowStyled>
        );
      })}
    </GridContainer>
  );

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="school-tabs-Access" />
          </Typography>
          {!!roles?.length && <Counter>{roles?.length}</Counter>}
        </Stack>

        <SimpleButton startIcon={<PlusIcon />} onClick={onAddRoleClick}>
          <FormattedMessage id={'school-tabs-Access-AddUserRole'} />
        </SimpleButton>
      </Stack>

      <Box sx={{ overflow: 'auto' }}>{isLoading ? <Loading /> : renderContent()}</Box>
    </Stack>
  );
};
