import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useCallback, useState } from 'react';

import IntlError from '../utils/intlError';
import useFlag from './useFlag';

export default function useRequestWithProgress<T>(
  callback: (...args: any) => void | Promise<void> | Promise<T>,
  errorCallback?: VoidFunction,
  hideErrorToast?: boolean,
  defaultLoadingValue?: boolean,
) {
  const [isRequestInProgress, startRequestProgress, stopRequestProgress] =
    useFlag(defaultLoadingValue);
  const [error, setError] = useState<null | ApiError>();

  const { showError } = useNotifications();

  const performRequest = useCallback(
    async (...args: any) => {
      startRequestProgress();
      try {
        const data = await callback(...args);
        return data;
      } catch (err) {
        setError(err as ApiError | IntlError);
        if (errorCallback) {
          errorCallback();
        }

        if (hideErrorToast) return undefined;

        showError(err as ApiError | IntlError);
      } finally {
        stopRequestProgress();
      }
      return undefined;
    },
    [callback, errorCallback, showError, hideErrorToast, startRequestProgress, stopRequestProgress],
  );

  return [performRequest, isRequestInProgress, error] as const;
}
