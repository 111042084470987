import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.53033 10.4697C9.82322 10.7626 9.82322 11.2374 9.53033 11.5303L3.81066 17.25H7C7.41421 17.25 7.75 17.5858 7.75 18C7.75 18.4142 7.41421 18.75 7 18.75H2C1.58579 18.75 1.25 18.4142 1.25 18V13C1.25 12.5858 1.58579 12.25 2 12.25C2.41421 12.25 2.75 12.5858 2.75 13V16.1893L8.46967 10.4697C8.76256 10.1768 9.23744 10.1768 9.53033 10.4697Z"
      fill="#B3BBCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4697 9.53033C10.1768 9.23744 10.1768 8.76256 10.4697 8.46967L16.1893 2.75L13 2.75C12.5858 2.75 12.25 2.41421 12.25 2C12.25 1.58579 12.5858 1.25 13 1.25L18 1.25C18.4142 1.25 18.75 1.58579 18.75 2L18.75 7C18.75 7.41421 18.4142 7.75 18 7.75C17.5858 7.75 17.25 7.41421 17.25 7L17.25 3.81066L11.5303 9.53033C11.2374 9.82322 10.7626 9.82322 10.4697 9.53033Z"
      fill="#B3BBCB"
    />
  </svg>
);
