import { FilterKeys, FilterValue, PagedResponse, Report, UserFilter } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, { FC, useCallback, useEffect } from 'react';

import { useFilters } from '../../../../context/filters/useFilters';
import { ShowMoreResourceFunction } from '../../../../hooks/useShowMore';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { actions } from '../../../../redux/slices/reports/listSlice';
import { SelectReportRow } from '../../../uikit-components/SelectReportRow/SelectReportRow';
import {
  FilterMainListSelectPopupContent,
  FilterMainListSelectPopupContentMainProps,
  FilterMainListSelectPopupContentProps,
} from './FilterMainListSelectPopupContent';

export const FilterReportSelectPopupContent: FC<FilterMainListSelectPopupContentMainProps> = (
  props,
) => {
  const { filters, setDraftValue } = useFilters();
  const dispatch = useAppDispatch();

  const { schoolId } = useAuth();
  const { results, fetching, error, canShowMore, current_page, total_pages } = useAppSelector(
    (state) => state.reports.list,
  );

  const fetchList = useCallback<ShowMoreResourceFunction<Report>>(
    async (pageSize?: number, pageNumber?: number, query?: string) => {
      if (!schoolId) return;

      const res = await dispatch(
        actions.get({
          page_size: pageSize,
          page_number: pageNumber,
          school_id: schoolId,
          filters: { date: filters.draft.date } as UserFilter,
          query,
        }),
      );

      return res.payload as PagedResponse<Report>;
    },
    [dispatch, filters.draft.date, schoolId],
  );

  const renderFilterOption = useCallback<
    Exclude<FilterMainListSelectPopupContentProps<any>['renderFilterOption'], undefined>
  >(
    ({ option, isActive, onSelectOption }) => (
      <SelectReportRow
        isSelected={isActive}
        key={option.value}
        report={option.item}
        selectOption={option}
        onClick={onSelectOption}
      />
    ),
    [],
  );

  useEffect(() => {
    // remove draft results which are not available because of date changes
    if (!results || !filters.draft?.report?.length) return;
    const ids: FilterValue[] = results?.map((item) => item.id);

    if (filters.draft?.student?.some((item) => !ids.includes(item))) {
      const filtered = results
        ?.filter((item) => filters.draft?.student?.includes(item.id))
        .map((r) => r.id);

      setDraftValue([FilterKeys.Student] as unknown as FilterKeys, filtered);
    }
  }, [filters.draft?.report, filters.draft?.student, results, setDraftValue]);

  return (
    <FilterMainListSelectPopupContent
      {...props}
      renderFilterOption={renderFilterOption}
      fetchList={fetchList}
      fetching={fetching}
      error={error}
      canShowMore={canShowMore}
      current_page={current_page}
      total_pages={total_pages}
    />
  );
};
