import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { useSchool } from '../../../../hooks/useSchool';
import { LegalEntityCreateContent } from './LegalEntityCreateContent';

export const LegalEntityCreateModal: FC = () => {
  const { schoolId } = useSchool();
  const navigate = useNavigate();
  const close = useCallback(() => navigate('/settings/legal_entities'), [navigate]);

  if (!schoolId) return null;

  return <LegalEntityCreateContent schoolId={schoolId} onClose={close} />;
};
