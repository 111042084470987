import { useAuth } from '@schooly/components/authentication';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import {
  allowRenderAssessments,
  allowRenderAttendance,
  allowRenderCompanies,
  allowRenderConduct,
  allowRenderGroups,
  allowRenderMessages,
  allowRenderParents,
  allowRenderReports,
  allowRenderSchoolSettings,
  allowRenderStaff,
  allowRenderStudents,
  allowRenderUserRoles,
} from '../../helpers/renderRoutesByPermissions';
import AccessDeniedPage from '../AccessDenied';

/*

  This page is rendered once there are no permissions assigned to the user
  allowing to refresh the page and be redirected to one of the pages if a new permission assigned

*/

export const CheckAccessPage: FC = () => {
  const { permissions } = useAuth();

  if (allowRenderStudents(permissions)) return <Navigate to="/students" />;
  if (allowRenderSchoolSettings(permissions)) return <Navigate to="/settings" />;
  if (allowRenderUserRoles(permissions)) return <Navigate to="/user-roles" />;
  if (allowRenderStaff(permissions)) return <Navigate to="/staff" />;
  if (allowRenderReports(permissions)) return <Navigate to="/reports" />;
  if (allowRenderParents(permissions)) return <Navigate to="/parents" />;
  if (allowRenderMessages(permissions)) return <Navigate to="/messages" />;
  if (allowRenderGroups(permissions)) return <Navigate to="/groups" />;
  if (allowRenderConduct(permissions)) return <Navigate to="/conduct" />;
  if (allowRenderAttendance(permissions)) return <Navigate to="/attendance" />;
  if (allowRenderAssessments(permissions)) return <Navigate to="/assessments" />;
  if (allowRenderCompanies(permissions)) return <Navigate to="/companies" />;

  return <AccessDeniedPage />;
};
