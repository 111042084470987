import { Icon } from '@mui/material';
import { AgeGroup, SchoolProperty } from '@schooly/api';
import { changeDefaultValuesForProperties } from '@schooly/api';
import {
  ChangeDefaultValuesForPropertiesProps,
  ChangeDefaultValuesForPropertiesTypes,
} from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { usePrevious } from '@schooly/hooks/use-previous';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { CheckIcon, FormControlHoverBorder, Spin } from '@schooly/style';
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../components/uikit-components/Grid/Grid';
import {
  SelectSchoolProperties,
  SelectSchoolPropertiesValue,
} from '../../../components/uikit-components/SelectSchoolProperties/SelectSchoolProperties';
import { useSchool } from '../../../hooks/useSchool';

export interface SchoolFiltersRowProps
  extends PropsWithChildren<Partial<Record<keyof SelectSchoolPropertiesValue, boolean>>> {
  title: React.ReactNode;
  changeTypes: (keyof ChangeDefaultValuesForPropertiesTypes)[];
}

export const SchoolFiltersRow: FC<SchoolFiltersRowProps> = ({
  title,
  changeTypes,
  children,
  ...SelectProps
}) => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const { schoolId = '' } = useSchool();

  const {
    propertiesMap: studentPropertiesMap,
    activePropertiesMap: studentActivePropertiesMap,
    refetch: studentPropertiesRefetch,
    isLoading: isStudentPropertiesLoading,
  } = useSchoolProperties(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    {
      enabled: Boolean(
        SelectProps.studentStatuses ||
          SelectProps.ageGroups ||
          SelectProps.houses ||
          SelectProps.campuses,
      ),
    },
  );

  const { ageGroups: studentAgeGroups, activeAgeGroups: studentActiveAgeGroups } = useAgeGroups(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    {
      enabled: Boolean(SelectProps.ageGroups),
    },
  );

  const {
    propertiesMap: staffPropertiesMap,
    activePropertiesMap: staffActivePropertiesMap,
    refetch: staffPropertiesRefetch,
    isLoading: isStaffPropertiesLoading,
  } = useSchoolProperties(
    {
      schoolId,
      userType: SchoolUserRole.Staff,
    },
    { enabled: Boolean(SelectProps.staffStatuses || SelectProps.departments) },
  );

  const prevIsStudentPropertiesLoading = usePrevious(isStudentPropertiesLoading);
  const prevIsStaffPropertiesLoading = usePrevious(isStaffPropertiesLoading);

  /* Retrieves actual set properties and converts to a value format */
  const getValueFromProperties = useCallback(() => {
    const map: SelectSchoolPropertiesValue = {};

    if (SelectProps.studentStatuses) {
      map.studentStatuses = studentPropertiesMap.status
        ?.filter((prop) => changeTypes.every((type) => prop[type]))
        .map((prop) => prop.id);
    }

    if (SelectProps.staffStatuses) {
      map.staffStatuses = staffPropertiesMap.status
        ?.filter((prop) => changeTypes.every((type) => prop[type]))
        .map((prop) => prop.id);
    }

    if (SelectProps.houses) {
      map.houses = studentPropertiesMap.house
        ?.filter((prop) => changeTypes.every((type) => prop[type]))
        .map((prop) => prop.id);
    }

    if (SelectProps.ageGroups) {
      map.ageGroups = studentAgeGroups
        ?.filter((prop) => changeTypes.every((type) => prop[type]))
        .map((prop) => prop.id);
    }

    if (SelectProps.campuses) {
      map.campuses = studentPropertiesMap.campus
        ?.filter((prop) => changeTypes.every((type) => prop[type]))
        .map((prop) => prop.id);
    }

    if (SelectProps.departments) {
      map.departments = studentPropertiesMap.department
        ?.filter((prop) => changeTypes.every((type) => prop[type]))
        .map((prop) => prop.id);
    }

    return map;
  }, [
    SelectProps.ageGroups,
    SelectProps.campuses,
    SelectProps.departments,
    SelectProps.houses,
    SelectProps.staffStatuses,
    SelectProps.studentStatuses,
    changeTypes,
    staffPropertiesMap.status,
    studentAgeGroups,
    studentPropertiesMap.campus,
    studentPropertiesMap.department,
    studentPropertiesMap.house,
    studentPropertiesMap.status,
  ]);

  const [value, setValue] = useState<SelectSchoolPropertiesValue>(getValueFromProperties());
  const [success, setSuccess] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (
      (prevIsStudentPropertiesLoading && !isStudentPropertiesLoading) ||
      (prevIsStaffPropertiesLoading && !isStaffPropertiesLoading)
    ) {
      setValue(getValueFromProperties());
    }
  }, [
    getValueFromProperties,
    isStaffPropertiesLoading,
    isStudentPropertiesLoading,
    prevIsStaffPropertiesLoading,
    prevIsStudentPropertiesLoading,
  ]);

  const handleClose = useCallback(async () => {
    if (!schoolId) {
      return;
    }

    const oldValue = getValueFromProperties();

    // Don't save if value hasn't been changed
    if (JSON.stringify(oldValue) === JSON.stringify(value)) {
      return;
    }

    // Check for archived properties
    let hasArchived = false;

    const getPropertiesArray = (
      key: keyof SelectSchoolPropertiesValue,
    ): SchoolProperty[] | AgeGroup[] => {
      switch (key) {
        case 'staffStatuses':
          return staffActivePropertiesMap.status;
        case 'studentStatuses':
          return studentActivePropertiesMap.status;
        case 'ageGroups':
          return studentActiveAgeGroups;
        case 'houses':
          return studentActivePropertiesMap.house;
        case 'campuses':
          return studentActivePropertiesMap.campus;
        case 'departments':
          return staffActivePropertiesMap.department;
        default:
          return [];
      }
    };

    const cleanValue = (
      Object.keys(value) as (keyof SelectSchoolPropertiesValue)[]
    ).reduce<SelectSchoolPropertiesValue>((prev, key) => {
      const ids =
        value[key]?.filter((id) =>
          getPropertiesArray(key).some((property) => property.id === id),
        ) ?? [];

      if (ids.length < (value[key]?.length ?? 0)) {
        hasArchived = true;
      }

      prev[key] = ids;

      return prev;
    }, {});

    if (hasArchived) {
      const isConfirmed = await getConfirmation({
        textId: 'school-sections-Filters-RemoveArchivedProperties-Confirmation',
      });

      if (!isConfirmed) {
        setValue(oldValue);
        return;
      } else {
        setValue(cleanValue);
      }
    }

    setSuccess(false);
    setFetching(true);

    try {
      // switch off previous properties first
      const oldProps = (
        Object.keys(oldValue ?? {}) as (keyof SelectSchoolPropertiesValue)[]
      ).reduce<Record<string, boolean>>(
        (prev, section) => ({
          ...prev,
          ...oldValue?.[section]?.reduce<Record<string, boolean>>(
            (prev, prop) => ({ ...prev, [prop]: false }),
            {},
          ),
        }),
        {},
      );

      // then, switch on new actual properties
      const newProps = (
        Object.keys(cleanValue ?? {}) as (keyof SelectSchoolPropertiesValue)[]
      ).reduce<Record<string, boolean>>(
        (prev, section) => ({
          ...prev,
          ...cleanValue?.[section]?.reduce<Record<string, boolean>>(
            (prev, prop) => ({ ...prev, [prop]: true }),
            {},
          ),
        }),
        oldProps,
      );

      // convert to API expected format
      const properties = Object.keys(newProps).reduce<
        ChangeDefaultValuesForPropertiesProps['properties']
      >(
        (prev, id) => [
          ...prev,
          {
            property_id: id,
            ...changeTypes.reduce<ChangeDefaultValuesForPropertiesTypes>(
              (prev, type) => ({ ...prev, [type]: newProps[id] }),
              {},
            ),
          },
        ],
        [],
      );

      // save properties
      await changeDefaultValuesForProperties({ schoolId, properties });

      // refresh staff properties
      if (SelectProps.staffStatuses || SelectProps.departments) {
        await staffPropertiesRefetch();
      }

      // refresh student properties
      if (
        SelectProps.studentStatuses ||
        SelectProps.houses ||
        SelectProps.ageGroups ||
        SelectProps.campuses
      ) {
        await studentPropertiesRefetch();
      }

      setSuccess(true);
    } catch (err) {
      showError(err as ApiError);
    }

    setFetching(false);
  }, [
    SelectProps.ageGroups,
    SelectProps.campuses,
    SelectProps.departments,
    SelectProps.houses,
    SelectProps.staffStatuses,
    SelectProps.studentStatuses,
    changeTypes,
    getConfirmation,
    getValueFromProperties,
    schoolId,
    showError,
    staffActivePropertiesMap.department,
    staffActivePropertiesMap.status,
    staffPropertiesRefetch,
    studentActiveAgeGroups,
    studentActivePropertiesMap.campus,
    studentActivePropertiesMap.house,
    studentActivePropertiesMap.status,
    studentPropertiesRefetch,
    value,
  ]);

  /* Hides success check mark in 3 sec */
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 3 * 1000);
    }
  }, [success]);

  return (
    <GridRowStyled noBorderRadius>
      <GridRowItem noVerticalPadding>
        <GridRowName sx={{ flex: '1 1 30%' }}>{title}</GridRowName>

        <GridRowCell
          sx={{
            flex: '1 1 70%',
            py: '5px',
          }}
        >
          <FormControlHoverBorder size="small">
            <SelectSchoolProperties
              label={$t({ id: 'school-sections-Filters-SelectFilters' })}
              value={value}
              onChange={setValue}
              onClose={handleClose}
              sx={{
                '&& .MuiSelect-outlined.MuiOutlinedInput-input.MuiSelect-select': {
                  minWidth: 500,
                  minHeight: 34,
                },
              }}
              {...SelectProps}
            />
          </FormControlHoverBorder>
        </GridRowCell>
        <GridRowCell
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            ml: 1,
            flex: (theme) => `1 0 ${theme.spacing(2.5)}`,
          }}
        >
          {fetching && (
            <Icon>
              <Spin />
            </Icon>
          )}
          {success && (
            <Icon>
              <CheckIcon />
            </Icon>
          )}
        </GridRowCell>
      </GridRowItem>
    </GridRowStyled>
  );
};
