export enum Genders {
  Male,
  Female,
}

export enum Relations {
  Father,
  Mother,
  Guardian,
  StepFather,
  StepMother,
  Grandparent,
  OtherRelative,
  PersonalAssistant,
  PersonalTutor,
  Driver,
  DomesticHelper,
  Sibling,
  HalfSibling,
  StepSibling,
  Other,
}

export enum RelationsToChild {
  Parent,
  Father,
  Mother,
  Guardian,
}

export enum Titles {
  Mr,
  Mrs,
  Miss,
  Ms,
  Dr,
  Prof,
}

export enum PersonTypes {
  Staff,
  Student,
  Parent,
}

export enum StaffStatuses {
  Applicant,
  Employed,
  Left,
}

export enum StudentStatuses {
  Applicant,
  Enrolled,
  Left,
}

export enum SchoolInviteStatus {
  Invited,
  Active,
}
