import { Button, IconButton } from '@mui/material';
import { LegalEntity } from '@schooly/api';
import {
  CrossIcon,
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spin,
} from '@schooly/style';
import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

export type LegalEntityForm = {
  legalEntity: LegalEntity;
};

type LegalEntityRemoveConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  legalEntityName: string;
  canDelete: boolean;
  isRemoving: boolean;
  content?: ReactNode;
};

export const LegalEntityRemoveConfirmationModal: FC<LegalEntityRemoveConfirmationModalProps> = ({
  legalEntityName,
  canDelete,
  onCancel,
  onConfirm,
  isRemoving,
  content,
}) => {
  const { $t } = useIntl();

  return (
    <ModalConfirm open fullWidth onClose={!isRemoving ? onCancel : undefined}>
      <ModalHeader
        active
        title={$t(
          { id: canDelete ? 'legalEntities-DeleteConfirm' : 'legalEntities-ArchiveConfirm' },
          { legalEntityName },
        )}
        multiline
        sx={{ borderBottom: 'none', pb: content ? 0 : undefined }}
      >
        <IconButton sx={{ visibility: isRemoving ? 'hidden' : 'visible' }} onClick={onCancel}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      {content && (
        <ModalContent active sx={(theme) => ({ pt: theme.spacing(2) })}>
          {content}
        </ModalContent>
      )}
      <ModalFooter
        active
        withBorderTop={false}
        sx={(theme) => ({
          gap: 1,
          '& .MuiButton-root': { flex: '1 1 50%' },
          paddingTop: 0,
          paddingBottom: theme.spacing(2.5),
        })}
      >
        <Button onClick={onCancel} variant="outlined" disabled={isRemoving}>
          {$t({ id: 'action-Cancel' })}
        </Button>
        <Button
          onClick={onConfirm}
          disabled={isRemoving}
          endIcon={isRemoving ? <Spin /> : undefined}
        >
          {$t({ id: 'action-Confirm' })}
        </Button>
      </ModalFooter>
    </ModalConfirm>
  );
};
