import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 10.5C3.75 10.0858 4.08579 9.75 4.5 9.75L13.6893 9.75L10.9697 7.03033C10.6768 6.73744 10.6768 6.26256 10.9697 5.96967C11.2626 5.67678 11.7374 5.67678 12.0303 5.96967L16.0303 9.96967C16.3232 10.2626 16.3232 10.7374 16.0303 11.0303L12.0303 15.0303C11.7374 15.3232 11.2626 15.3232 10.9697 15.0303C10.6768 14.7374 10.6768 14.2626 10.9697 13.9697L13.6893 11.25L4.5 11.25C4.08579 11.25 3.75 10.9142 3.75 10.5Z"
      fill="#5D6688"
    />
  </svg>
);
