import { Box, FormControl, Stack, styled, Typography } from '@mui/material';

interface FormSchoolLogoStyledProps {
  width?: number;
}

export const FormSchoolLogoStyled = styled(FormControl)<FormSchoolLogoStyledProps>(
  ({ theme, width = 170 }) => ({
    width,

    '& .MuiFormHelperText-root': {
      ...theme.typography.body2,

      margin: theme.spacing(0.25, 0, 0),
      padding: theme.spacing(0, 2),
      textAlign: 'center',
    },
  }),
);

export interface FormSchoolLogoFieldProps extends FormSchoolLogoStyledProps {
  error?: boolean;
}

export const FormSchoolLogoField = styled(Stack)<FormSchoolLogoFieldProps>(
  ({ theme, error, width = 170 }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2.5),
    position: 'relative',
    width: width + 6,
    height: width + 6,
    margin: -3,
    borderRadius: '50%',
    overflow: 'hidden',
    border: `3px solid transparent`,
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
    cursor: 'pointer',

    transition: theme.transitions.create(['color', 'border-color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),

    '&:before': {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      border: theme.mixins.borderControlValue(),
      borderColor: error ? theme.palette.error.main : undefined,
      borderRadius: '50%',
      content: '""',

      transition: theme.transitions.create('border-color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },

    '&:hover': {
      borderColor: theme.palette.background.default,
      color: theme.palette.text.primary,

      '&:before': {
        borderColor: theme.palette.common.light3,
      },
    },
  }),
);

export interface FormSchoolLogoPreviewProps {
  preview: string;
}

export const FormSchoolLogoPreview = styled(Box)<FormSchoolLogoPreviewProps>(
  ({ theme, preview }) => ({
    width: '100%',
    height: '100%',
    background: `center / contain no-repeat url(${preview}), ${theme.palette.background.paper}`,
  }),
);

export const FormSchoolLogoParam = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(),
}));

FormSchoolLogoParam.defaultProps = {
  color: 'inherit',
  variant: 'body2',
};
