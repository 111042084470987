import { UserRoleList } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { createContext } from 'react';

export interface UserRolesForSchoolContextProps {
  error?: ApiError;
  userRoles?: UserRoleList;
  isLoading: boolean;
  loadUserRoles: () => void;
}

export const UserRolesForSchoolContext = createContext<UserRolesForSchoolContextProps>({
  error: undefined,
  userRoles: undefined,
  isLoading: false,
  loadUserRoles: () => {},
});

UserRolesForSchoolContext.displayName = 'UserRolesForSchoolContext';
