import { useTheme } from '@mui/material';
import React, { FC } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Data } from 'react-minimal-pie-chart/types/commonTypes';

interface AttendanceStatsPieChartProps<T> {
  total: string | number;
  data: T;
  labelCountText?: string;
}

export const AttendanceStatsPieChart: FC<AttendanceStatsPieChartProps<Data>> = ({
  data,
  total,
  labelCountText,
}) => {
  const theme = useTheme();

  return (
    <PieChart
      data={data ?? []}
      lineWidth={35}
      label={(props) => (
        // Label accept only svg tags
        <text {...props}>
          <tspan>{total}</tspan>
          <tspan
            {...props}
            dy={props.dy + 10}
            style={{
              fontSize: theme.typography.body3.fontSize,
              fill: theme.palette.common.grey2,
            }}
          >
            {labelCountText ?? ''}
          </tspan>
        </text>
      )}
      labelStyle={{
        fontSize: theme.typography.h3.fontSize,
        fill: theme.palette.primary.main,
      }}
      labelPosition={0}
    />
  );
};
