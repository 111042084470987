import { Typography } from '@mui/material';
import { Recurrence } from '@schooly/api';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../../DropdownSelect';
import { SelectContentSkeleton } from '../../SelectContentSkeleton';
import { SelectSearchInput } from '../../SelectSearchInput';
import { RecurrenceSelectRow } from '../RecurrenceSelectRow';

type RecurrenceSelectProps = {
  schoolId: string;
  selectedId?: string;
  onSelectRecurrence: (v: string) => void;
  recurrences: Recurrence[];
  isLoading: boolean;
  hasNextPage?: boolean;
  handleChangeQuery: (q: string) => void;
  fetchNextPage: () => Promise<any>;
  query?: string;
  renderLabel: (title: string) => ReactNode;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const RecurrenceSelect = ({
  label,
  selectedId,
  onSelectRecurrence,
  placeholder,
  schoolId,
  recurrences,
  isLoading,
  hasNextPage,
  handleChangeQuery,
  fetchNextPage,
  query,
  renderLabel,
  ...dropdownProps
}: RecurrenceSelectProps) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedOptionTitle, setSelectedOptionTitle] = useState('');

  const hasOptions = Boolean(recurrences.length);

  const renderContent = useCallback(() => {
    if (!hasOptions) {
      if (isLoading || hasNextPage) {
        return <SelectContentSkeleton />;
      }
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );
    }

    return (
      <>
        {recurrences.map((recurrence) => (
          <RecurrenceSelectRow
            onClick={(id) => {
              dropdown.current?.close();
              onSelectRecurrence(id);
              handleChangeQuery('');
              setSelectedOptionTitle(recurrence.title);
            }}
            key={recurrence.id}
            recurrence={recurrence}
            isSelected={selectedId === recurrence.id}
          />
        ))}
      </>
    );
  }, [
    handleChangeQuery,
    hasNextPage,
    hasOptions,
    isLoading,
    onSelectRecurrence,
    recurrences,
    selectedId,
  ]);

  const renderInputContent = useCallback(
    (opened: boolean) => {
      if (!selectedId) {
        return opened ? (
          <SelectSearchInput
            ref={inputRef}
            autoFocus
            value={query || ''}
            onChangeText={handleChangeQuery}
            disabled={Boolean(selectedId)}
          />
        ) : null;
      }
      return renderLabel(selectedOptionTitle);
    },
    [handleChangeQuery, query, renderLabel, selectedId, selectedOptionTitle],
  );

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      placeholder={placeholder}
      hasValues={Boolean(selectedId)}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isLoading}
      onFetchNextPage={fetchNextPage}
      showNextPageLoading={hasOptions}
    >
      {renderInputContent}
    </DropdownSelect>
  );
};
