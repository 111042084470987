import { TextFieldProps } from '@mui/material';
import get from 'lodash.get';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form-lts';
import { FieldValues } from 'react-hook-form-lts/dist/types';
import { useIntl } from 'react-intl';

import { getControllerErrorText } from '../../ui/Input/utils';
import { ModalHeaderInput } from './ModalHeader.styled';

export type ControlModalHeaderInputProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> &
  TextFieldProps;
export const ControlModalHeaderInput = <TFieldValues extends FieldValues = FieldValues>({
  control: propsControl,
  ...props
}: ControlModalHeaderInputProps<TFieldValues>) => {
  const { $t } = useIntl();
  const { control: contextControl, formState } = useFormContext<TFieldValues>();
  const control = propsControl ?? contextControl;

  return (
    <Controller
      control={control}
      render={({ field }) => {
        const error = get(formState.errors, field.name);
        const hasError = Boolean(error);
        const errorMessage = getControllerErrorText(error, $t);

        return (
          <ModalHeaderInput
            {...props}
            {...props.rules}
            // Required property is defined through rules to show helperText
            required={undefined}
            inputRef={field.ref}
            {...field}
            error={hasError}
            helperText={errorMessage}
          />
        );
      }}
      {...props}
    />
  );
};
