import { SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export const AdornmentLabel: FC<{ label?: 'optional' | 'required'; sx?: SxProps }> = ({
  label,
  sx,
}) => {
  if (!label) return null;

  return (
    <Typography
      variant="h3"
      fontStyle="italic"
      color="common.grey"
      sx={{
        right: 41,
        top: 11,
        position: 'absolute',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <FormattedMessage id={label === 'required' ? 'input-required' : 'input-optional'} />
    </Typography>
  );
};
