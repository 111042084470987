import { EventsInvite } from '@schooly/constants';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import {
  CreateEventRequest,
  CreateEventResponse,
  EventsBulkChangeStatusRequest,
  EventsBulkChangeStatusResponse,
  GetEventRequest,
  GetEventsRequest,
  RemoveEventRequest,
  RemoveEventResponse,
  UpdateEventRequest,
  UpdateEventResponse,
} from './apiTypes/endpoints/events';
import { IColumnSort } from './apiTypes/endpoints/people';
import { Event, EventsStatuses } from './apiTypes/events';
import { ApiError, PagedResponse } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { RepetitionType } from './apiTypes/recurring';
import { FilterKeys, UserFilter } from './apiTypes/users';
import * as api from './requests';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

const DEFAULT_PAGE_SIZE = 50;
const EVENTS_URL = '/events';

export const EVENTS_FILTER_KEYS = [
  FilterKeys.InviteType,
  FilterKeys.EventStatus,
  FilterKeys.Date,
  FilterKeys.AgeGroup,
  FilterKeys.House,
  FilterKeys.Group,
  FilterKeys.RecurrenceId,
  FilterKeys.RepetitionType,
] as const;

export type GetEventsQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.InviteType]?: EventsInvite[];
  [FilterKeys.Group]?: string[];
  [FilterKeys.EventStatus]?: EventsStatuses[];
  [FilterKeys.EventType]?: Array<'default' | 'period'>;
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.House]?: string[];
  has_signups?: boolean;
  [FilterKeys.RecurrenceId]?: string[];
  [FilterKeys.RepetitionType]?: RepetitionType[];
};

export function getEvent({ eventId }: GetEventRequest): Promise<Event> {
  return api.get(`${EVENTS_URL}/${eventId}`);
}

export const GET_EVENT_QUERY = `${EVENTS_URL}/GET_EVENT`;

export const useGetEventQuery = (params: GetEventRequest, options?: RQUseQueryOptions<Event>) => {
  return useQuery<Event, ApiError>([GET_EVENT_QUERY, params], () => getEvent(params), {
    ...options,
  });
};

export function getEventsBaseQueryParams<T extends GetEventsQueryFilters | UserFilter>(
  filters: Partial<T>,
) {
  const isPastStatus = filters?.event_status?.some((v) => +v === EventsStatuses.Past);
  const eventsStatus = filters?.event_status?.filter((v) => v !== EventsStatuses.Past) ?? undefined;
  const eventsStatusArr = eventsStatus?.length ? eventsStatus : undefined;

  return removeObjectUndefinedNullValues({
    date_from: filters?.date?.[0],
    date_to: filters?.date?.[1],
    invitee_type: filters?.invite_type?.join(','),
    event_status: eventsStatusArr?.join(','),
    event_type: filters.event_type?.join(','),
    has_signups: (filters as GetEventsQueryFilters)?.has_signups,
    past_only: isPastStatus ? true : undefined,
    age_group: filters?.age_group?.join(','),
    house: filters?.house?.join(','),
    group: filters.group?.join(','),
    recurrence_id: filters.recurrence_id?.[0],
    repetition_type: filters.repetition_type?.join(','),
  });
}

export function getEvents({
  schoolId,
  query,
  filters,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  token,
}: GetEventsRequest): Promise<PagedResponse<Event>> {
  const baseQueryParams = filters ? getEventsBaseQueryParams(filters) : {};

  return api.get(`${EVENTS_URL}/for-school/${schoolId}`, {
    params: {
      ...baseQueryParams,
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
    },
    cancelToken: token,
  });
}

export const GET_EVENTS_QUERY = `${EVENTS_URL}/GET_EVENTS`;

export type GetEventsQuerySort = IColumnSort<keyof Pick<Event, 'id' | 'title' | 'start' | 'end'>>;
export const useGetEventsQuery = (
  initialParams: Omit<GetEventsRequest, 'filters' | 'sort'> & {
    filters: GetEventsQueryFilters;
    sort?: GetEventsQuerySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<Event>>,
) => {
  const [params, setParams] = useState(initialParams);

  const filters = removeObjectEmptyArrayValues(params.filters);

  const query = useInfiniteQuery<PagedResponse<Event>, ApiError>(
    [GET_EVENTS_QUERY, { ...params, filters }],
    ({ pageParam }) =>
      getEvents({
        pageNumber: pageParam,
        ...params,
        filters,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const CREATE_EVENT_MUTATION = `${EVENTS_URL}CREATE_EVENT_MUTATION`;

export function createEvent({
  schoolId,
  ...params
}: CreateEventRequest): Promise<CreateEventResponse> {
  return api.post(`${EVENTS_URL}/for-school/${schoolId}`, { ...params });
}

export const useCreateEventMutation = (
  options?: RQUseMutationOptions<CreateEventResponse, CreateEventRequest>,
): RQUseMutationResult<CreateEventResponse, CreateEventRequest> => {
  return useMutation([CREATE_EVENT_MUTATION], (params: CreateEventRequest) => createEvent(params), {
    ...options,
  });
};

export const UPDATE_EVENT_MUTATION = `${EVENTS_URL}UPDATE_EVENT_MUTATION`;

export function updateEvent({
  id,
  confirmed,
  withFollowing,
  ...params
}: UpdateEventRequest): Promise<UpdateEventResponse> {
  const withFollowingParam = withFollowing !== undefined ? `&with_following=${withFollowing}` : '';
  return api.patch(`${EVENTS_URL}/${id}?confirmed=${confirmed}${withFollowingParam}`, {
    ...params,
  });
}

export const useUpdateEventMutation = (
  options?: RQUseMutationOptions<UpdateEventResponse, UpdateEventRequest>,
): RQUseMutationResult<UpdateEventResponse, UpdateEventRequest> => {
  return useMutation([UPDATE_EVENT_MUTATION], (params: UpdateEventRequest) => updateEvent(params), {
    ...options,
  });
};

export const DELETE_EVENT_MUTATION = `${EVENTS_URL}DELETE_EVENT_MUTATION`;

export function removeEvent({
  id,
  withFollowing,
}: RemoveEventRequest): Promise<RemoveEventResponse> {
  const withFollowingParam = withFollowing !== undefined ? `?with_following=${withFollowing}` : '';
  return api.remove(`${EVENTS_URL}/${id}${withFollowingParam}`);
}

export const useRemoveEventMutation = (
  options?: RQUseMutationOptions<RemoveEventResponse, RemoveEventRequest>,
): RQUseMutationResult<RemoveEventResponse, RemoveEventRequest> => {
  return useMutation([DELETE_EVENT_MUTATION], (params: RemoveEventRequest) => removeEvent(params), {
    ...options,
  });
};

export const EVENTS_BULK_STATUS_CHANGE_MUTATION = `${EVENTS_URL}EVENTS_BULK_STATUS_CHANGE_MUTATION`;

export function eventsBulkStatusChange({
  schoolId,
  filters,
  query,
  withFollowing,
  ...rest
}: EventsBulkChangeStatusRequest): Promise<EventsBulkChangeStatusResponse> {
  const baseParams = filters ? getEventsBaseQueryParams(filters) : {};
  const withFollowingParam = withFollowing !== undefined ? `?with_following=${withFollowing}` : '';

  return api.post(`${EVENTS_URL}/for-school/${schoolId}/action${withFollowingParam}`, {
    ...baseParams,
    ...rest,
    search_query: query,
  });
}

export const useEventsBulkStatusChangeMutation = (
  options?: RQUseMutationOptions<EventsBulkChangeStatusResponse, EventsBulkChangeStatusRequest>,
): RQUseMutationResult<EventsBulkChangeStatusResponse, EventsBulkChangeStatusRequest> => {
  return useMutation(
    [EVENTS_BULK_STATUS_CHANGE_MUTATION],
    (params: EventsBulkChangeStatusRequest) => eventsBulkStatusChange(params),
    {
      ...options,
    },
  );
};
