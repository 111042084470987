import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="15" cy="15" r="15" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.15002 11.55C9.15002 11.2186 9.3515 10.95 9.60002 10.95L20.4 10.95C20.6486 10.95 20.85 11.2186 20.85 11.55C20.85 11.8813 20.6486 12.15 20.4 12.15L9.60002 12.15C9.3515 12.15 9.15002 11.8813 9.15002 11.55ZM9.15002 15.15C9.15002 14.8186 9.3515 14.55 9.60002 14.55L20.4 14.55C20.6486 14.55 20.85 14.8186 20.85 15.15C20.85 15.4813 20.6486 15.75 20.4 15.75L9.60002 15.75C9.3515 15.75 9.15002 15.4813 9.15002 15.15ZM9.60002 18.15C9.3515 18.15 9.15002 18.4186 9.15002 18.75C9.15002 19.0813 9.3515 19.35 9.60002 19.35H20.4C20.6486 19.35 20.85 19.0813 20.85 18.75C20.85 18.4186 20.6486 18.15 20.4 18.15H9.60002Z"
        fill="#5D6688"
      />
    </svg>
  );
};
