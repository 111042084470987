import {
  alpha,
  Box,
  Divider,
  IconButton,
  IconButtonProps,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ConductTypeRepresentation,
  GET_CONDUCT_STATS_QUERY_FILTER_KEYS,
  GetConductStatsQueryFilters,
  useGetConductStatsQuery,
  useGetConductTypesForSchoolQuery,
} from '@schooly/api';
import { ConductTypeDropdown } from '@schooly/components/filters';
import { PROPERTIES_TEXT_IDS, SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { ChartIcon } from '@schooly/style';
import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { PieChart } from 'react-minimal-pie-chart';

interface ConductChartProps {
  onCloseChart: () => void;
  hideChartButton?: boolean;
  chartHeight?: number;
  schoolId: string;
  columns?: number;
  schoolPropertyType: SchoolPropertyType;
  conductTypeOptionIds?: string[];
  filters?: GetConductStatsQueryFilters;
}

export const ConductChart: FC<ConductChartProps> = ({
  schoolId,
  onCloseChart,
  chartHeight = 80,
  schoolPropertyType,
  conductTypeOptionIds,
  filters,
  columns = 3,
  hideChartButton = false,
}) => {
  const { $t } = useIntl();
  const [selectedOptionId, setSelectedOptionId] = useState(conductTypeOptionIds?.[0]);

  const { data, isLoading } = useGetConductTypesForSchoolQuery(schoolId);
  const availableConductTypes = useMemo(() => {
    if (!data) return;

    return data.filter(
      (type) =>
        type.type === ConductTypeRepresentation.NUMBER &&
        (conductTypeOptionIds && conductTypeOptionIds.length
          ? conductTypeOptionIds.includes(type.id)
          : true),
    );
  }, [conductTypeOptionIds, data]);

  useEffect(() => {
    if (
      !availableConductTypes ||
      availableConductTypes.map((c) => c.id).includes(selectedOptionId || '')
    )
      return;

    const newSelectedOptionId = availableConductTypes[0]?.id;

    if (!newSelectedOptionId) {
      onCloseChart();
      return;
    }

    setSelectedOptionId(newSelectedOptionId);
  }, [availableConductTypes, selectedOptionId, onCloseChart]);

  const { schoolProperties, isLoading: isLoadingSchoolProperties } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
    showReEnrollmentProperties: true,
  });

  const { data: chartData, isLoading: isLoadingChartsData } = useGetConductStatsQuery(
    {
      schoolId,
      school_property_type: schoolPropertyType,
      conduct_type_id: selectedOptionId!,
      filters,
    },
    { enabled: !!selectedOptionId, refetchOnMount: 'always' },
  );

  const theme = useTheme();
  const total = useMemo(
    () => chartData?.reduce((acc, stat) => acc + (stat.total ?? 0), 0),
    [chartData],
  );

  const shouldShowSkeleton =
    !selectedOptionId || isLoading || isLoadingSchoolProperties || isLoadingChartsData;

  const pieChartData = chartData
    ?.filter((stat) => {
      if (!stat.school_property_id || !filters || !(schoolPropertyType in filters)) return true;

      const propertyTypeFilters =
        filters[schoolPropertyType as unknown as typeof GET_CONDUCT_STATS_QUERY_FILTER_KEYS[0]];

      if (!propertyTypeFilters || !propertyTypeFilters.length) return true;

      return propertyTypeFilters.includes(stat.school_property_id);
    })
    .map((stat, i) => {
      const value = stat.total ?? 0;
      const title =
        schoolProperties.find((property) => property.id === stat.school_property_id)?.name ||
        `${$t({ id: 'no' })} ${$t({ id: PROPERTIES_TEXT_IDS[schoolPropertyType] })}`;

      return {
        title,
        value,
        color: stat.school_property_id
          ? alpha(theme.palette.primary.main, (chartData.length - i) / chartData.length)
          : theme.palette.error.main,
      };
    });

  const showTypeSelect = availableConductTypes && availableConductTypes.length > 1;

  return (
    <Stack>
      {(showTypeSelect || !hideChartButton) && (
        <>
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              alignItems: 'center',
              height: theme.spacing(3.5),
              gap: 2,
              mb: 1,
              justifyContent: 'flex-end',
              pr: 1,
            })}
          >
            {showTypeSelect && (
              <ConductTypeDropdown
                onSelectOption={(v) => setSelectedOptionId(v.id)}
                options={availableConductTypes}
                selectedOption={availableConductTypes.find((c) => c.id === selectedOptionId)}
                label={$t({ id: 'conduct-SelectScore' })}
              />
            )}

            {!hideChartButton && (
              <IconButton onClick={onCloseChart}>
                <ChartIcon />
              </IconButton>
            )}
          </Stack>
          <Divider sx={{ mb: 2 }} />
        </>
      )}
      <Stack direction="row" alignItems="center" gap={6}>
        <Box sx={{ height: chartHeight }}>
          <PieChart
            data={pieChartData || []}
            lineWidth={35}
            background={theme.palette.background.default}
            label={() => total || ''}
            labelStyle={{
              fontSize: '15px',
              fill: theme.palette.primary.main,
            }}
            labelPosition={0}
          />
        </Box>

        <Stack direction="row" flexWrap="wrap" flex={1} alignItems="flex-start">
          {pieChartData?.map(({ title, color, value }) => (
            <Stack key={title} p={1} width={100 / columns + '%'}>
              <Stack direction="row" alignItems="center" gap={1.5}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: color,
                    whiteSpace: 'nowrap',
                  }}
                />
                <Typography variant="h3">
                  {title}&nbsp;{value}
                </Typography>
              </Stack>
            </Stack>
          ))}
          {shouldShowSkeleton &&
            [...new Array(5)].map((_, i) => (
              <Stack key={i} p={1} width={100 / columns + '%'}>
                <Skeleton width="100px" />
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

type ConductChartButtonProps = {
  schoolId: string;
  conductTypeOptionIds?: string[];
} & IconButtonProps;

export const ConductChartIconButton: FC<ConductChartButtonProps> = ({
  conductTypeOptionIds,
  schoolId,
  disabled,
  ...iconButtonProps
}) => {
  const { $t } = useIntl();
  const { data, isLoading } = useGetConductTypesForSchoolQuery(schoolId);
  const availableConductTypes = useMemo(() => {
    if (!data) return;

    return data.filter(
      (type) =>
        type.type === ConductTypeRepresentation.NUMBER &&
        (conductTypeOptionIds && conductTypeOptionIds.length
          ? conductTypeOptionIds.includes(type.id)
          : true),
    );
  }, [conductTypeOptionIds, data]);

  const showNoChartsAvailableTooltip = !isLoading && !availableConductTypes?.length;

  const button = (
    <IconButton
      disabled={isLoading || showNoChartsAvailableTooltip || disabled}
      {...iconButtonProps}
    />
  );

  if (showNoChartsAvailableTooltip)
    return (
      <Tooltip title={$t({ id: 'conduct-ChartsNotAvailable' })}>
        <Stack justifyContent="center">{button}</Stack>
      </Tooltip>
    );

  return button;
};
