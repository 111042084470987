import {
  ApplicationCustomField,
  CustomField,
  CustomFieldApplicableTo,
  CustomFieldDataType,
  CustomFieldPlaceOfUse,
  RQUseQueryOptions,
  SORT_DIRECTION,
  useGetCustomFieldsForSchoolQuery,
} from '@schooly/api';
import { CreateCustomField } from '@schooly/components/applications';
import { useAuth } from '@schooly/components/authentication';
import { CustomFieldBooleanValues, SchoolUserRole } from '@schooly/constants';
import { useMemo } from 'react';

export const useCustomFields = (options?: Omit<RQUseQueryOptions<CustomField[]>, 'retry'>) => {
  const { schoolId = '', permissions } = useAuth();
  const isAssessmentViewer = permissions.includes('custom_field_viewer');

  const {
    data: customFields,
    refetch,
    isLoading,
  } = useGetCustomFieldsForSchoolQuery(
    { schoolId, sort: [{ columnTextId: 'applicable_to', direction: SORT_DIRECTION.ASC }] },
    {
      enabled: Boolean(schoolId && isAssessmentViewer),
      retry: isAssessmentViewer,
      ...options,
    },
  );

  const { archivedCustomFields, activeCustomFields } = useMemo(() => {
    return {
      archivedCustomFields: customFields?.filter((field) => field.archived),
      activeCustomFields: customFields?.filter((field) => !field.archived),
    };
  }, [customFields]);

  const commonCustomFields = useMemo<CreateCustomField[]>(() => {
    // manually sort custom fields by `applicable_to`, as the `applicable_to` might
    // include also other types
    const getCustomFieldApplicableTo = (customField: CustomField) =>
      customField.applicable_to.includes(CustomFieldApplicableTo.DefaultApplicationValues)
        ? CustomFieldApplicableTo.DefaultApplicationValues
        : CustomFieldApplicableTo.CustomApplicationValues;

    return (
      activeCustomFields
        ?.sort((a, b) => getCustomFieldApplicableTo(a) - getCustomFieldApplicableTo(b))
        .map((c) => ({
          ...c,
          checked: CustomFieldBooleanValues.No,
          value: '',
        })) ?? []
    );
  }, [activeCustomFields]);

  const defaultCustomFields = useMemo(() => {
    return commonCustomFields?.filter((field) =>
      field.applicable_to.includes(CustomFieldApplicableTo.DefaultApplicationValues),
    );
  }, [commonCustomFields]);

  const [applicationCustomFields, profileCustomFields] = useMemo(() => {
    return [CustomFieldPlaceOfUse.Application, CustomFieldPlaceOfUse.Profile].reduce<
      CreateCustomField[][]
    >((acc, place) => {
      const filteredFields = commonCustomFields?.filter((c) => c.place_of_use?.[0] === place);
      return [...acc, filteredFields];
    }, []);
  }, [commonCustomFields]);

  const [applicationAdultCustomFields, applicationGeneralCustomFields] = useMemo(() => {
    return [CustomFieldApplicableTo.Parent, CustomFieldApplicableTo.GeneralInformation].reduce<
      CreateCustomField[][]
    >((acc, to) => {
      const filteredFields = applicationCustomFields?.filter((c) =>
        c.applicable_to.includes(to as CustomFieldApplicableTo),
      );

      return [...acc, filteredFields];
    }, []);
  }, [applicationCustomFields]);

  const applicationChildCustomFields = useMemo(() => {
    const studentCustomFields = applicationCustomFields.filter((field) =>
      field.applicable_to.includes(CustomFieldApplicableTo.Student),
    );
    return [...defaultCustomFields, ...studentCustomFields];
  }, [applicationCustomFields, defaultCustomFields]);

  const [profileParentCustomFields, profileStaffCustomFields] = useMemo(() => {
    return [CustomFieldApplicableTo.Parent, CustomFieldApplicableTo.Staff].reduce<
      CreateCustomField[][]
    >((acc, to) => {
      const filteredFields = profileCustomFields?.filter((c) =>
        c.applicable_to.includes(to as CustomFieldApplicableTo),
      );

      return [...acc, filteredFields];
    }, []);
  }, [profileCustomFields]);

  const customFieldsByUserRole = useMemo(
    () =>
      activeCustomFields?.reduce<Partial<Record<CustomFieldApplicableTo, CustomField[]>>>(
        (prev, field) => {
          field.applicable_to.forEach((userRole) => {
            const role = userRole as unknown as SchoolUserRole;

            if (!prev[role]) {
              prev[role] = [];
            }

            prev[userRole]!.push(field);
          });

          return prev;
        },
        {},
      ),
    [activeCustomFields],
  );

  const getCustomFieldsToShow = (fields: ApplicationCustomField[]) => {
    return fields.filter(
      (field) => !!field.value.length || field.data_type === CustomFieldDataType.BOOLEAN,
    );
  };

  return {
    customFields,
    customFieldsByUserRole,
    fetching: isLoading,
    archivedCustomFields,
    activeCustomFields,
    getCustomFields: refetch,
    applicationCustomFields,
    applicationAdultCustomFields,
    applicationChildCustomFields,
    applicationGeneralCustomFields,
    profileCustomFields,
    profileParentCustomFields,
    profileStaffCustomFields,
    commonCustomFields,
    defaultCustomFields,
    getCustomFieldsToShow,
  };
};
