import Decimal from 'decimal.js';

export const getDiscountedPrice = (price: number, discountPercent: number) => {
  const decimalPrice = new Decimal(price);
  const decimalDiscountPercent = new Decimal(discountPercent);

  const discountAmount = decimalPrice.mul(decimalDiscountPercent).div(100);
  const discountedPrice = decimalPrice.minus(discountAmount);

  return discountedPrice.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber();
};
