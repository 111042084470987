import { MenuItem as MuiMenuItem, styled } from '@mui/material';

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: 0,
  width: '180px',
  margin: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.common.lightBg,
    borderRadius: theme.shape.borderRadiusSm,
  },
}));
