import { UserType } from '@schooly/api';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FilterType } from '../../../../context/filters/scheme';
import { useFilters } from '../../../../context/filters/useFilters';
import { getUserTypeTextId } from '../../../../helpers/misc';
import { MIN_REMOTE_QUERY_LENGTH } from '../../../../hooks/usePagedApiResourceWithFilter';
import SearchInput from '../../../ui/SearchInput';

export interface IHeaderSearchProps {
  placeholder?: string;
  isLoading?: boolean;
  closePopup?: () => void;
}

const HeaderSearch: FC<IHeaderSearchProps> = ({ placeholder, isLoading, closePopup }) => {
  const { formatMessage } = useIntl();
  const { filters, filterType, applyFilters } = useFilters();

  const innerPlaceholder = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    switch (filterType) {
      case FilterType.SidebarGroups:
        return formatMessage({ id: 'groups-SearchGroups' });
      case FilterType.Parent:
        return formatMessage(
          { id: 'people-SearchAmongType' },
          {
            userTypePlural: formatMessage({
              id: getUserTypeTextId(filterType as UserType, true),
            }).toLowerCase(),
          },
        );
      default:
        return formatMessage({ id: 'people-Search' });
    }
  }, [filterType, formatMessage, placeholder]);

  const handleChange = useCallback(
    (query: string) => {
      // TR-1718: clear & apply search query once it empty.
      // If it's not empty, has to be applied by hitting Enter
      applyFilters({ query, apply: !query, noDebounce: true });
    },
    [applyFilters],
  );

  const handleEnter = useCallback(() => {
    applyFilters({
      query: filters.draftQuery.length >= MIN_REMOTE_QUERY_LENGTH ? filters.draftQuery : '',
      noDebounce: true,
    });
    closePopup?.();
  }, [applyFilters, closePopup, filters.draftQuery]);

  const handleResetValue = useCallback(() => {
    handleChange('');
  }, [handleChange]);

  return (
    <SearchInput
      value={filters.draftQuery}
      onChange={handleChange}
      isLoading={isLoading}
      placeholder={innerPlaceholder}
      onEnter={handleEnter}
      onResetValue={handleResetValue}
      isValueRemovable
      testId="aux-modal-search-input"
    />
  );
};

export default HeaderSearch;
