import { Box, Button, IconButton, Select, Stack } from '@mui/material';
import {
  ConsentForm,
  GET_CONSENT_FORM_QUERY,
  useRemoveConsentFormMutation,
  useUpdateConsentFormMutation,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import {
  CrossIcon,
  DeleteIcon,
  LockIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  Spin,
} from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../components/common/AccessDenied';
import { ConsentFormEditor } from '../../components/common/ConsentForms/ConsentFormEditor';
import { useRouter } from '../../context/router/useRouter';
import { EventPreviewSignUps } from '../Events/EventPreviewModal/EventPreviewSignUps';
import { useReferenceInfo } from './hooks';
import { ReferencePreview } from './ReferencePreview/ReferencePreview';

type ConsentEditForm = {
  description: string;
};
type ConsentFormEditContentProps = {
  consentForm: ConsentForm;
};

export const ConsentFormEditContent: FC<ConsentFormEditContentProps> = ({ consentForm }) => {
  const { $t } = useIntl();
  const { reference } = consentForm;
  const { name, canRemoveConsentForm, path, canEditConsentForm } = useReferenceInfo(
    consentForm.reference,
  );
  const updateConsentForm = useUpdateConsentFormMutation();
  const removeConsentForm = useRemoveConsentFormMutation();
  const navigate = useNavigate();
  const { getConfirmation } = useConfirmationDialog();
  const { showError, showNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { closeAndClean, clean } = useRouter();

  const form = useForm<ConsentEditForm>({
    defaultValues: { description: consentForm.description },
  });

  const handleSubmit = useCallback(
    async (form: ConsentEditForm) => {
      await updateConsentForm.mutateAsync(
        {
          id: consentForm.id,
          description: form.description,
          reference_id: consentForm.reference.data.id,
          reference_type: consentForm.reference.type,
        },
        {
          onSuccess: () => {
            showNotification({
              textId: 'consentForms-successfulUpdate',
              type: 'success',
            });
            queryClient.invalidateQueries([GET_CONSENT_FORM_QUERY]);
            navigate(`/consent-form/${consentForm.id}`, { state: { replace: true } });
          },
          onError: showError,
        },
      );
    },
    [
      consentForm.id,
      consentForm.reference.data.id,
      consentForm.reference.type,
      navigate,
      queryClient,
      showError,
      showNotification,
      updateConsentForm,
    ],
  );
  const handleDeleteConsentForm = useCallback(async () => {
    const isConfirmed = await getConfirmation({
      textId: 'consentForms-removeFormWarning',
      sx: {
        '.MuiDialog-paperFullWidth': {
          width: 600,
        },
      },
    });

    if (!isConfirmed) return;

    await removeConsentForm.mutateAsync(consentForm.id, {
      onSuccess: () => {
        navigate(path);
        clean();
      },
      onError: showError,
    });
  }, [clean, consentForm.id, getConfirmation, navigate, path, removeConsentForm, showError]);

  const isLoading = removeConsentForm.isLoading || updateConsentForm.isLoading;

  if (!canRemoveConsentForm && !canEditConsentForm) return <AccessDenied />;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <ModalHeader title={$t({ id: 'consentForms-title' })} active>
          <IconButton onClick={closeAndClean}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>

        <ModalMain>
          <ModalContent active>
            <Stack gap={1}>
              <Stack flex={1}>
                <Select
                  disabled
                  value={name}
                  label={$t({ id: 'consentForms-linkTo' })}
                  IconComponent={() => (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        right: 11,
                        pointerEvents: 'none',
                      }}
                      inverse
                    >
                      <LockIcon />
                    </IconButton>
                  )}
                />
              </Stack>
              <ReferencePreview reference={reference}>
                {reference.type === 'event' && (
                  <Box
                    sx={{
                      '& > :first-child': {
                        paddingBottom: 0,
                      },
                    }}
                  >
                    <EventPreviewSignUps
                      titleWidth="180px"
                      event={reference.data}
                      showHeaderTitle={false}
                    />
                  </Box>
                )}
              </ReferencePreview>
            </Stack>
            <Stack width="100%" pt={4}>
              <ConsentFormEditor RichTextProps={{ disabled: !canEditConsentForm }} />
            </Stack>
          </ModalContent>
        </ModalMain>
        <ModalFooter
          active
          sx={{ justifyContent: canRemoveConsentForm ? 'space-between' : 'flex-end' }}
        >
          {canRemoveConsentForm && (
            <Button
              variant="outlined"
              startIcon={removeConsentForm.isLoading ? <Spin /> : <DeleteIcon />}
              disabled={isLoading}
              onClick={handleDeleteConsentForm}
            >
              {$t({ id: 'action-Delete' })}
            </Button>
          )}
          <Button
            disabled={isLoading || !canEditConsentForm}
            startIcon={updateConsentForm.isLoading ? <Spin /> : undefined}
            type="submit"
          >
            {$t({ id: 'action-Save' })}
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
