import { Box, styled } from '@mui/material';

import { GridRowCell, GridRowStyled } from '../../../../components/uikit-components/Grid/Grid';

export const EmploymentCaseGridRow = styled(GridRowStyled)(({ theme }) => ({
  '.status': {
    color: theme.palette.common.grey,
  },
  '.icon-Comments, .icon-ConductVisibility': {
    color: theme.palette.text.primary,
  },
  '&:hover': {
    color: theme.palette.primary.main,
    '.icon-Expand, .icon-Comments, .icon-ConductVisibility, .status': {
      color: theme.palette.primary.main,
    },
  },
}));

EmploymentCaseGridRow.defaultProps = {
  className: 'GridRowItem',
};

export const ListViewRowActions = styled(Box)(({ theme }) => {
  const size = theme.spacing(2);

  return {
    position: 'relative',
    margin: theme.spacing(0, 1, 0, 2),
    flex: `0 0 ${size}`,
    fontSize: size,
    minWidth: size,

    '& .MuiIconButton-root': {
      position: 'absolute',
      transform: 'translateY(-50%)',
      display: 'none',

      '.GridRowItem:hover &': {
        display: 'unset',
      },
    },
  };
});

export const GridRowTitle = styled(GridRowCell)(() => ({
  minWidth: 0,
  flex: '1 1 100%',
}));

export const GridRowExpand = styled(GridRowCell)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
  minWidth: theme.spacing(4),
}));

export const DetailsExpandable = styled(Box)<{ isExpanded: boolean }>(({ isExpanded, theme }) => ({
  height: isExpanded ? 'auto' : 0,
  overflow: 'hidden',
  transition: 'all .2s ease',
  padding: isExpanded ? theme.spacing(0.5, 4, 1.5, 1) : theme.spacing(0, 4, 0, 1),
}));
