import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6H17V16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16V4C3 2.89543 3.89543 2 5 2H13V5C13 5.55228 13.4477 6 14 6ZM15 2C16.1046 2 17 2.89543 17 4V5H14V2H15ZM11.9758 7.70994C12.1785 7.50724 12.5071 7.50724 12.7098 7.70994C12.9125 7.91264 12.9125 8.24129 12.7098 8.44399L10.8554 10.2985L12.712 12.1551C12.904 12.3471 12.904 12.6586 12.712 12.8506C12.5199 13.0427 12.2085 13.0427 12.0164 12.8506L10.1598 10.994L8.3055 12.8483C8.10279 13.051 7.77415 13.051 7.57144 12.8483C7.36874 12.6456 7.36874 12.317 7.57144 12.1143L9.42573 10.26L7.56891 8.40315C7.37683 8.21107 7.37683 7.89966 7.56891 7.70758C7.76099 7.5155 8.0724 7.5155 8.26448 7.70758L10.1213 9.5644L11.9758 7.70994Z"
      fill="#F2994A"
    />
  </svg>
);
