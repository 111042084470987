import { getTypedObjectKeys } from './getTypedObjectKeys';

export function removeObjectEmptyArrays(obj: object) {
  const newObj = {};
  getTypedObjectKeys(obj).forEach((prop) => {
    if (!Array.isArray(obj[prop]) || (obj[prop] as []).length) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
}
