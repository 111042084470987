import { Icon, Skeleton, Stack } from '@mui/material';
import {
  FilterKeys,
  FilterSection,
  GetGroupsQueryFilters,
  GroupArrangeBy,
  GROUPS_ARRANGE_BY_FILTER_KEYS,
  GROUPS_QUERY_FILTER_KEYS,
  useGetFiltersQuery,
  useGetSchoolPropertiesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useChartOpenFromSearchParams, WithCharts } from '@schooly/components/charts';
import { PageHeader, pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SkeletonRows,
} from '@schooly/style';
import React, { useMemo } from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import useUserCounts from '../../hooks/useUserCounts';
import GroupsContent, { PAGE_SIZE, SKELETON_COLS } from './GroupsContent';
import { GroupsEmptyStub } from './GroupsEmptyStub';

const GroupsPage: React.FC = () => {
  const { userCounts } = useUserCounts();
  const { schoolId = '', relationId = '' } = useAuth();

  const { data: studentPropertiesData } = useGetSchoolPropertiesQuery(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { refetchOnMount: 'always' },
  );

  const { ageGroups } = useAgeGroups(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { refetchOnMount: 'always' },
  );

  const { data: staffPropertiesData } = useGetSchoolPropertiesQuery(
    {
      schoolId,
      userType: SchoolUserRole.Staff,
    },
    { refetchOnMount: 'always' },
  );

  const { data: filters, isLoading } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Group },
    { enabled: !!schoolId && !!relationId },
  );

  const initialFiltersAndArrangeBy = useMemo(() => {
    const initialFilters: GetGroupsQueryFilters = {};
    let initialArrangeBy: GroupArrangeBy | null = null;

    if (isLoading) return;

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      for (const key of GROUPS_ARRANGE_BY_FILTER_KEYS) {
        if (key === defaultFilter.filter.arrange_by) initialArrangeBy = key;
      }

      return {
        initialFilters: pickOnlyParamsFromFilterKeys(
          GROUPS_QUERY_FILTER_KEYS,
          defaultFilter.filter,
        ),
        initialArrangeBy,
      };
    }

    initialFilters[FilterKeys.Staff] = relationId ? [relationId] : [];

    if (!studentPropertiesData || !staffPropertiesData) return;

    for (const property of studentPropertiesData.school_properties) {
      if (!property.group_default) continue;

      const type = property.type;

      switch (type) {
        case SchoolPropertyType.Status:
          initialFilters[FilterKeys.StudentStatus] = [
            ...(initialFilters[FilterKeys.StudentStatus] || []),
            property.id,
          ];
          break;
        case SchoolPropertyType.House:
          initialFilters[FilterKeys.StudentHouse] = [
            ...(initialFilters[FilterKeys.StudentHouse] || []),
            property.id,
          ];
          break;
      }
    }

    for (const ageGroup of ageGroups) {
      if (!ageGroup.group_default) continue;

      initialFilters[SchoolPropertyType.AgeGroup] = [
        ...(initialFilters[SchoolPropertyType.AgeGroup] || []),
        ageGroup.id,
      ];
    }

    for (const property of staffPropertiesData.school_properties) {
      if (!property.group_default) continue;

      const type = property.type;

      switch (type) {
        case SchoolPropertyType.Status:
          initialFilters[FilterKeys.StaffStatus] = [
            ...(initialFilters[FilterKeys.StaffStatus] || []),
            property.id,
          ];
          break;
        case SchoolPropertyType.House:
          initialFilters[FilterKeys.StaffHouse] = [
            ...(initialFilters[FilterKeys.StaffHouse] || []),
            property.id,
          ];
          break;
      }
    }

    return { initialFilters, initialArrangeBy };
  }, [ageGroups, filters, isLoading, relationId, staffPropertiesData, studentPropertiesData]);

  const isChartsOpen = useChartOpenFromSearchParams();

  const renderContent = () => {
    if (!initialFiltersAndArrangeBy) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <PageHeader pageTitleTextId="section-Groups">
              <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
            </PageHeader>
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_COLS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    if (!userCounts?.group) {
      return <GroupsEmptyStub />;
    }

    return (
      <WithCharts schoolId={schoolId ?? ''} isOpen={isChartsOpen}>
        <GroupsContent {...initialFiltersAndArrangeBy} />
      </WithCharts>
    );
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};

export default GroupsPage;
