import { Box, Checkbox, IconButton, Stack, TableCellProps, Typography } from '@mui/material';
import { GroupType, useGetAttendanceCodesQuery } from '@schooly/api';
import { AttendanceCode, AttendanceEntryForRelation } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { DATE_FORMAT_SHORT_MOMENT, MD_BREAKPOINT_TABLE_MIN_WIDTH } from '@schooly/constants';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import {
  ChartIcon,
  CheckIcon,
  GridCell,
  GridContainer,
  GridRowDate,
  GridRowItem,
  Loading,
  stickyCellStyle,
  stickyHeaderCellStyle,
  TagSubject,
} from '@schooly/style';
import { DropdownYears } from '@schooly/style';
import moment from 'moment';
import React, { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { TagGroup } from '../../../components/uikit/TagGroup/TagGroup';
import { DropdownComments } from '../../../components/uikit-components/DropdownCommentsV2/DropdownComments';
import { CommentTableCell } from '../../../components/uikit-components/DropdownCommentsV2/DropdownCommentsWrappers/CommentTableCell';
import { ListViewAssessmentsTags } from '../../../components/uikit-components/ListViewAssessmentsRow/ListViewAssessmentsRow.styled';
import { Table, TableCell } from '../../../components/uikit-components/Table/Table';
import { AttendanceForRelationContext } from '../../../context/attendance/AttendanceForRelationContext';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { AttendanceStats } from '../../Attendance/AttendanceStatistics/AttendanceStats';
import { AttendanceStatsPieChart } from '../../Attendance/AttendanceStatistics/AttendanceStatsPieChart';
import { ProfileModalAttendanceGridItem } from './ProfileModalAttendance.styled';

export const ProfileModalAttendance: FC = () => {
  const { schoolId = '' } = useAuth();
  const { data: codes } = useGetAttendanceCodesQuery(schoolId);

  const {
    entries,
    fetching,
    isFetchingNextPage,
    schoolYear,
    setSchoolYear,
    pendingStatusEntries,
    pendingCommentEntries,
    setEntryStatus,
    setEntryComment,
    canShowMore,
    showMore,
    counts,
    generateEntryKey,
  } = useContext(AttendanceForRelationContext);
  const loaderRef = useInfiniteScroll(fetching, showMore);
  const isInitialFetching = fetching && !entries.length;
  const containerRef = useRef<HTMLDivElement>(null);
  const { schoolYears, defaultValidity } = useSchoolYears();

  const [showStats, setShowStats] = useState(false);

  const { $t } = useIntl();

  const renderStatusCell = useCallback(
    (entry: AttendanceEntryForRelation, code: AttendanceCode, entryKey: string) => {
      const groupId = entry.group_id;
      let content = null;

      if (entry.editable) {
        const checked = pendingStatusEntries[entryKey]
          ? pendingStatusEntries[entryKey] === code.id // use pendingEntry if set (while updating)
          : entry.attendance_code_id === code.id; // otherwise check actual entry code

        content = (
          <Checkbox
            checked={checked}
            onChange={(event, checked) => {
              // don't allow to uncheck the status
              if (checked && groupId && entry.register_id) {
                setEntryStatus(groupId, code.id, entry.register_id, entryKey);
              }
            }}
            disabled={pendingStatusEntries[entryKey] != null}
          />
        );
      } else if (entry.attendance_code_id && entry.attendance_code_id === code.id) {
        content = (
          <Typography variant="h2" component="span">
            <CheckIcon />
          </Typography>
        );
      }

      return content;
    },
    [pendingStatusEntries, setEntryStatus],
  );

  const renderCommentCell = useCallback(
    (entry: AttendanceEntryForRelation, disabled: Boolean, tableCellProps: TableCellProps) => {
      let content = (
        <CommentTableCell
          borderLeft
          noVerticalPadding
          sx={(theme) => ({
            right: 0,
            ...stickyCellStyle,
            [theme.breakpoints.down('md')]: {
              position: 'relative',
            },
          })}
          {...tableCellProps}
        >
          {() => (
            <DropdownComments
              comments={[]}
              disabled
              canAdd={false}
              canEditOwn
              canEditOther
              tableView
              ref={containerRef}
            />
          )}
        </CommentTableCell>
      );

      if (entry.editable) {
        const comments = pendingCommentEntries[entry.attendance_entry_id]
          ? pendingCommentEntries[entry.attendance_entry_id]!.text
            ? [
                {
                  comment: pendingCommentEntries[entry.attendance_entry_id]!.text!,
                  relation_id: entry.creator_relation_id!, // No relation_id for currentUser
                  title: pendingCommentEntries[entry.attendance_entry_id]!.creator_title,
                  given_name: pendingCommentEntries[entry.attendance_entry_id]!.creator_given_name,
                  last_name: pendingCommentEntries[entry.attendance_entry_id]!.creator_last_name!,
                  known_as: pendingCommentEntries[entry.attendance_entry_id]!.creator_known_as,
                },
              ]
            : []
          : entry.text
          ? [
              {
                comment: entry.text!,
                relation_id: entry.creator_relation_id!,
                title: entry.creator_title,
                given_name: entry.creator_given_name,
                last_name: entry.creator_last_name!,
                known_as: entry.creator_known_as,
              },
            ]
          : [];

        const canAdd = pendingCommentEntries[entry.attendance_entry_id]
          ? !pendingCommentEntries[entry.attendance_entry_id]!.text
          : !entry.text;

        const handleEdit = (value: string) => {
          const notEmptyComment = comments[0] ?? '' !== value;
          if (notEmptyComment && entry.group_id && entry.register_id && entry.attendance_code_id) {
            setEntryComment({
              groupId: entry.group_id,
              registerId: entry.register_id,
              codeId: entry.attendance_code_id,
              comment: value,
            });
          }
        };

        content = (
          <CommentTableCell
            borderLeft
            noVerticalPadding
            sx={(theme) => ({
              right: 0,
              ...stickyCellStyle,
              [theme.breakpoints.down('md')]: {
                position: 'relative',
              },
            })}
            {...tableCellProps}
          >
            {(onToggle, cellRef) => (
              <DropdownComments
                comments={comments}
                onAdd={handleEdit}
                onEdit={handleEdit}
                disabled={!!pendingCommentEntries[entry.attendance_entry_id] || !!disabled}
                canAdd={canAdd} // if no comment yet
                canEditOwn
                canEditOther
                getParentRef={() => cellRef}
                onToggle={onToggle}
                tableView
                ref={containerRef}
              />
            )}
          </CommentTableCell>
        );
      } else if (entry.text) {
        content = (
          <CommentTableCell
            borderLeft
            noVerticalPadding
            sx={(theme) => ({
              right: 0,
              ...stickyCellStyle,
              [theme.breakpoints.down('md')]: {
                position: 'relative',
              },
            })}
            {...tableCellProps}
          >
            {(onToggle, cellRef) => (
              <DropdownComments
                comments={[
                  {
                    comment: entry.text!,
                    relation_id: entry.creator_relation_id!,
                    title: entry.creator_title,
                    given_name: entry.creator_given_name,
                    last_name: entry.creator_last_name!,
                    known_as: entry.creator_known_as,
                  },
                ]}
                canAdd={false}
                canEditOwn={false}
                canEditOther={false}
                getParentRef={() => cellRef}
                onToggle={onToggle}
                tableView
                ref={containerRef}
              />
            )}
          </CommentTableCell>
        );
      }

      return content;
    },
    [pendingCommentEntries, setEntryComment],
  );

  const attendanceCodes = useMemo(() => codes ?? [], [codes]);

  const rows = useMemo(
    () =>
      entries?.map((entry, i) => {
        const withoutStatus = !entry.attendance_code_id;
        const entryKey = generateEntryKey(entry, i);

        const cellArray: TableCell[] = [
          {
            id: 'students',
            renderContent: (props) => (
              <GridCell borderRight noPadding sticky {...props}>
                <ProfileModalAttendanceGridItem>
                  <GridRowItem>
                    <GridRowDate>
                      {moment(entry.register_date).format(DATE_FORMAT_SHORT_MOMENT).toUpperCase()}
                    </GridRowDate>
                    <ListViewAssessmentsTags
                      sx={(theme) => ({
                        '& .UIKit-TagGroup': {
                          [theme.breakpoints.down('lg')]: {
                            maxWidth: 100,
                          },
                        },
                      })}
                    >
                      {entry.group_name && <TagGroup>{entry.group_name}</TagGroup>}
                      {entry.group_type === GroupType.TutorGroup || entry.subject_name ? (
                        <TagSubject archived={entry.subject_archive}>
                          {entry.group_type === GroupType.TutorGroup ? (
                            <FormattedMessage id="groups-TutorGroup" />
                          ) : (
                            entry.subject_name
                          )}
                        </TagSubject>
                      ) : null}
                    </ListViewAssessmentsTags>
                  </GridRowItem>
                </ProfileModalAttendanceGridItem>
              </GridCell>
            ),
          },

          ...attendanceCodes.map((code) => ({
            renderContent: (props: TableCellProps) => (
              <GridCell
                key={`td-${entryKey}-${code.code}`}
                sx={{
                  width: !!attendanceCodes.length ? `${100 / attendanceCodes.length}%` : undefined,
                  textAlign: 'center',
                }}
                noVerticalPadding
                {...props}
              >
                {renderStatusCell(entry, code, entryKey)}
              </GridCell>
            ),
            id: code.id,
          })),
          {
            id: 'attendance-NoStatus',
            renderContent: (props) => (
              <GridCell center key={`td-${entry.register_id}`} noVerticalPadding {...props}>
                {entry.editable ? (
                  <Checkbox
                    checked={withoutStatus && !pendingStatusEntries[entryKey]}
                    disabled={!withoutStatus}
                  />
                ) : (
                  <Typography variant="h2" component="span">
                    {withoutStatus && <CheckIcon />}
                  </Typography>
                )}
              </GridCell>
            ),
          },
          {
            id: 'attendance-Comment',
            renderContent: (props) => renderCommentCell(entry, withoutStatus, props),
            hoverProps: {
              onlyXHover: true,
            },
          },
        ];

        return { id: entryKey, cells: cellArray };
      }) ?? [],
    [
      attendanceCodes,
      entries,
      generateEntryKey,
      pendingStatusEntries,
      renderCommentCell,
      renderStatusCell,
    ],
  );

  const columns: TableCell[] = useMemo(
    () => [
      {
        id: 'Header',
        renderContent: (props) => (
          <GridCell variant="head" sticky borderRight {...props}>
            <ProfileModalAttendanceGridItem />
          </GridCell>
        ),
        hoverProps: {
          disableHover: true,
        },
      },

      ...attendanceCodes.map((code) => ({
        id: code.id,
        renderContent: (props: TableCellProps) => (
          <GridCell
            key={`th-${code.code}`}
            variant="head"
            {...props}
            sx={{
              '&&': {
                color: 'text.primary',
                textAlign: 'center',
              },
            }}
          >
            {code.name}
          </GridCell>
        ),
      })),
      {
        id: 'attendance-NoStatus',
        renderContent: (props) => (
          <GridCell
            variant="head"
            sx={{
              '&&': {
                color: 'text.primary',
                textAlign: 'center',
              },
            }}
            {...props}
          >
            <FormattedMessage id="attendance-NoStatus" />
          </GridCell>
        ),
      },
      {
        id: 'attendance-Comment',
        renderContent: (props) => (
          <GridCell
            variant="head"
            borderLeft
            sx={(theme) => ({
              right: 0,
              ...stickyHeaderCellStyle,
              [theme.breakpoints.down('md')]: {
                '@media (orientation: portrait)': {
                  position: 'relative',
                },
              },
              width: theme.spacing(6),
              [theme.breakpoints.down('lg')]: {
                minWidth: theme.spacing(7.5),
              },
            })}
            {...props}
          >
            <Box sx={(theme) => ({ width: theme.spacing(4) })} />
          </GridCell>
        ),
        hoverProps: {
          disableHover: true,
        },
      },
    ],
    [attendanceCodes],
  );

  const isFirstPageFetching = fetching && !isFetchingNextPage;

  return (
    <Stack sx={{ height: '100%' }} ref={containerRef}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2" className="title">
          <FormattedMessage id="section-Attendance" />
        </Typography>
        <Stack direction="row" gap={2} alignItems="baseline">
          <DropdownYears
            years={schoolYears}
            defaultYear={defaultValidity}
            currentYear={schoolYear}
            onYearChange={setSchoolYear}
          />

          {!!entries.length && (
            <IconButton
              onClick={() => setShowStats((cur) => !cur)}
              sx={{
                color: showStats ? 'primary' : 'text.secondary',
              }}
            >
              <ChartIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>

      <Stack
        sx={(theme) => ({
          flexGrow: 1,

          [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            marginRight: -2.5,
            paddingRight: 2.5,
          },
        })}
      >
        {showStats && !!entries.length && (
          <Stack
            sx={(theme) => ({
              overflowY: 'scroll',
              [theme.breakpoints.down('lg')]: {
                pb: 2.5,
              },
              [theme.breakpoints.down('md')]: {
                marginRight: -2.5,
              },
            })}
          >
            <Stack
              mb={1}
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              })}
            >
              <AttendanceStats
                loading={fetching}
                statistics={counts}
                renderChart={(data, total) => (
                  <Stack
                    sx={{
                      height: (theme) => theme.spacing(16),
                      width: (theme) => theme.spacing(16),
                    }}
                  >
                    <AttendanceStatsPieChart
                      data={data}
                      total={total}
                      labelCountText={$t({ id: 'schoolDays' }).toLowerCase()}
                    />
                  </Stack>
                )}
              />
            </Stack>
          </Stack>
        )}

        {isFirstPageFetching && <Loading />}

        {!isInitialFetching && !Boolean(entries.length) && (
          <Stack sx={{ height: '100%' }}>
            <NoSearchResultsFound type="small" />
          </Stack>
        )}

        <Stack
          sx={(theme) => ({
            overflowY: 'scroll',
            [theme.breakpoints.down('md')]: {
              marginRight: -2.5,
            },
          })}
        >
          {Boolean(entries.length) && !isFirstPageFetching && (
            <GridContainer
              sx={(theme) => ({
                flex: '0 0 auto',
                overflow: 'visible',
                [theme.breakpoints.down('md')]: {
                  minWidth: MD_BREAKPOINT_TABLE_MIN_WIDTH,
                  paddingRight: 2.5,
                  py: 0.5,
                },
              })}
            >
              <Table
                rows={rows}
                columns={columns}
                isEmpty={!entries.length}
                renderEmptyContent={
                  <Stack sx={{ height: '100%' }}>
                    <NoSearchResultsFound type="small" />
                  </Stack>
                }
              />
              {canShowMore && (
                <Box py={3}>
                  {isFetchingNextPage && <Loading />}
                  <div ref={loaderRef} />
                </Box>
              )}
            </GridContainer>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
