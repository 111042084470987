import {
  ApiError,
  AvailableCriteria,
  DEFAULT_DATE_FORMAT_FNS,
  getAttachment,
  getMessage,
  IListGroupArguments,
  listGroups,
  MessageAction,
  messageAction,
  MessageStatus,
  MessageUpdate,
  OutcomingAttachment,
  useCreateConsentFormMutation,
  useCreateMessageMutation,
  useGetGroupSubjectsQuery,
  useGetSchoolPropertiesQuery,
  useRemoveMessage,
  useUpdateMessageMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { NotificationActions, useNotifications } from '@schooly/components/notifications';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm as useReactHookForm } from 'react-hook-form-lts';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateConsentForm } from '../../components/common/ConsentForms/ConsentForm';
import { AttachmentFile } from '../../components/ui/Input/RichText/Attachments/attachment-types';
import {
  getAttachmentName,
  getBase64Href,
} from '../../components/ui/Input/RichText/Attachments/utils';
import { SchoolUserRole } from '../../constants/school';
import { getMessagePublishDate } from '../../helpers/messages';
import { buildCriteriaUpdate, getCriteriaArchived, getCriteriaName } from '../../helpers/misc';
import useAppLocation from '../../hooks/useAppLocation';
import useConvertFileToBuffer from '../../hooks/useConvertFileToBuffer';
import useFlag from '../../hooks/useFlag';
import { useForm } from '../../hooks/useForm';
import {
  convertIndividualToUserSearchResult,
  getParentsFromIndividuals,
  getParentsOfStudentsFromIndividuals,
  getStaffAvailableCriteria,
  getStaffFromIndividuals,
  getStaffOfStudentsFromIndividuals,
  getStudentsAvailableCriteria,
} from '../../pages/Messages/helpers';
import { useAppDispatch } from '../../redux/hooks';
import { actions as ReduxActions } from '../../redux/slices/messagesSlice';
import { convertApiTimestamp } from '../../utils/convertApiTimestamp';
import IntlError from '../../utils/intlError';
import { RouterStateContext, RouterStateContextProps } from '../router/RouterStateContext';
import { useRouter } from '../router/useRouter';
import {
  CONTEXT_NAME,
  getInitialState,
  MessageContext,
  MessageContextProps,
  MessageContextState,
  MessageCreateLocation,
  MessageForm,
} from './MessageContext';

export interface WithMessageProps extends PropsWithChildren {}

export const MESSAGE_MAX_CHARS_LENGTH = 5000;

export const WithMessage: FC<WithMessageProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useAppLocation() as MessageCreateLocation;
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  const { goBack, closeAndClean } = useRouter();
  const { showError, showNotification } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const invalidateQueries = useInvalidateListQueriesFor('message');
  const createMessage = useCreateMessageMutation();
  const updateMessage = useUpdateMessageMutation();
  const removeMessage = useRemoveMessage();
  const createConsentForm = useCreateConsentFormMutation();

  const messageId = id === 'new' ? undefined : id;

  const abortController = useRef(new AbortController());

  useEffect(() => {
    const controller = abortController.current;

    return () => {
      controller.abort();
    };
  }, []);

  const { currentUser, permissions, schoolId = '' } = useAuth();
  const { data } = useGetGroupSubjectsQuery({ schoolId }, { refetchOnMount: 'always' });

  const groupSubjects = data?.subjects;

  const { data: studentProperties } = useGetSchoolPropertiesQuery({
    userType: SchoolUserRole.Student,
    schoolId,
  });
  const { data: staffProperties } = useGetSchoolPropertiesQuery({
    userType: SchoolUserRole.Staff,
    schoolId,
  });

  const properties = useMemo(() => {
    return [
      ...(studentProperties?.school_properties ?? []),
      ...(staffProperties?.school_properties ?? []),
    ];
  }, [staffProperties?.school_properties, studentProperties?.school_properties]);

  const { ageGroups: staffAgeGroups, schoolLevels: staffSchoolLevels } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Staff,
  });
  const { ageGroups: studentAgeGroups, schoolLevels: studentSchoolLevels } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const [fetchingAttachments, setFetchingAttachments] = useState<
    MessageContextProps['fetchingAttachments']
  >({});
  const [isPublishInProgress, setIsPublishInProgress] = useState(false);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [isUnsubmitInProgress, setIsUnsubmitInProgress] = useState(false);

  const { state, setState, setContextState, setContextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<MessageContextState>;

  const [fetching, setFetching] = useState(false);
  const [notActualGroups, setNotActualGroups] = useState<AvailableCriteria[]>([]);
  const [removeGroupsConfirmShown, setRemoveGroupsConfirmShown] = useState(false);

  const convertedFiles = useConvertFileToBuffer(state?.attachments);
  const [isRemoveGroupDialogOpen, showRemoveGroupDialog, hideRemoveGroupDialog] = useFlag();
  const [consentFormShowed, showConsentForm, hideConsentForm] = useFlag();

  const hasCreatorPermission = permissions.includes('message_creator');
  const hasPublisherPermission = permissions.includes('message_publisher');
  const hasViewerPermission = permissions.includes('message_viewer');
  const isPublished = state?.status === MessageStatus.Published;
  const isDraft = state?.status === MessageStatus.Draft;
  const isSubmitted = state?.status === MessageStatus.Submitted;
  const isCreator = state?.creator?.user_id === currentUser?.user_id;

  // These fields are extracted to hook form due to performance issues to prevent rerenders on every body/title change
  const messageForm = useReactHookForm<MessageForm>({
    defaultValues: {
      title: state?.title || '',
      body: state?.body || '',
    },
    mode: 'onChange',
  });

  const consentForm = useReactHookForm<CreateConsentForm>({
    defaultValues: {
      description: state?.linkedConsentForm ? state.linkedConsentForm.description : '',
    },
    mode: 'onChange',
  });

  // Update message form values with ones from context state
  useEffect(() => {
    const { title, body } = messageForm.getValues();

    !title && !!state?.title && messageForm.setValue('title', state.title);
    !body && !!state?.body && messageForm.setValue('body', state.body);
  }, [state?.title, state?.body, messageForm]);

  // Sync message form values with context state
  useEffect(() => {
    const setValueDebounced = debounce((key: 'title' | 'body', value: string) => {
      setContextState({ [key]: value });
    }, 500);

    const subscription = messageForm.watch(({ title = '', body = '' }, { name }) => {
      switch (name) {
        case 'body':
          return setValueDebounced('body', body);
        case 'title':
          return setValueDebounced('title', title);
      }
    });
    return subscription.unsubscribe;
  }, [messageForm, setContextState]);

  const [error, setError] = useState<ApiError | null>(null);

  const canEdit = useMemo(() => {
    const creatorPermissions = !isPublished && isCreator && hasCreatorPermission;
    const publisherPermissions = !isPublished && hasPublisherPermission;

    return messageId ? creatorPermissions || publisherPermissions : hasViewerPermission;
  }, [
    isPublished,
    isCreator,
    hasCreatorPermission,
    hasPublisherPermission,
    messageId,
    hasViewerPermission,
  ]);

  const bodyLength = messageForm.watch('body')?.length || 0;

  const isBodyLengthOverLimit = bodyLength > MESSAGE_MAX_CHARS_LENGTH;

  const canPublish = useMemo(
    () => Boolean(messageId && !isPublished && hasPublisherPermission),
    [hasPublisherPermission, isPublished, messageId],
  );

  const canSubmit = useMemo(() => {
    if (isBodyLengthOverLimit) return false;

    const creatorPermissions = isDraft && isCreator && hasCreatorPermission;
    const publisherPermissions = isDraft && hasPublisherPermission;

    return creatorPermissions || publisherPermissions;
  }, [hasCreatorPermission, hasPublisherPermission, isBodyLengthOverLimit, isCreator, isDraft]);

  const canUnsubmit = useMemo(() => {
    const creatorPermissions = isSubmitted && isCreator && hasCreatorPermission;
    const publisherPermissions = isSubmitted && hasPublisherPermission;

    return creatorPermissions || publisherPermissions;
  }, [hasCreatorPermission, hasPublisherPermission, isCreator, isSubmitted]);

  const hasParents = Boolean(
    state?.parents?.length ||
      state?.parentsCriteria?.length ||
      state?.parentsByIndividualStudent?.length,
  );
  const hasStaff = Boolean(
    state?.staff?.length || state?.staffCriteria?.length || state?.staffByIndividualStudent?.length,
  );
  const hasRecipients = hasParents || hasStaff;

  const form = useForm({
    core: true,
    state,
    setState,
    rules: {},
  });

  const canSave =
    !!form.isValid && !!messageForm.formState.isValid && bodyLength <= MESSAGE_MAX_CHARS_LENGTH;

  const request = useCallback(async () => {
    const initialState = getInitialState();

    if (messageId) {
      // request message details and use as initial state
      setFetching(true);

      try {
        const response = await getMessage(messageId, { signal: abortController.current.signal });

        if (response) {
          const criteriaDateTimestamp = convertApiTimestamp(response.message.criteria_date);
          if (response.message.consent_form) {
            consentForm.reset({ description: response.message.consent_form.description });
          }

          messageForm.reset({ body: response.message.body, title: response.message.title });

          setState({
            ...initialState,
            attachments: response.message.attachments || [],
            status: response.message.status,
            creator: response.message.creator,
            publisher: response.message.publisher,
            linkedConsentForm: response.message.consent_form,
            criteriaDate: criteriaDateTimestamp
              ? format(criteriaDateTimestamp, DEFAULT_DATE_FORMAT_FNS)
              : undefined,

            publishDate: getMessagePublishDate(response.message),

            parents:
              getParentsFromIndividuals(response.message_individuals)?.map(
                convertIndividualToUserSearchResult,
              ) ?? [],

            parentsCriteria:
              getStudentsAvailableCriteria({
                criteria: response.message_criteria,
                properties,
                subjects: groupSubjects,
                ageGroups: studentAgeGroups,
                schoolLevels: studentSchoolLevels,
              }) ?? [],

            parentsByIndividualStudent:
              getParentsOfStudentsFromIndividuals(response.message_individuals)?.map(
                convertIndividualToUserSearchResult,
              ) ?? [],

            staff:
              getStaffFromIndividuals(response.message_individuals)?.map(
                convertIndividualToUserSearchResult,
              ) ?? [],

            staffCriteria:
              getStaffAvailableCriteria({
                criteria: response.message_criteria,
                properties,
                subjects: groupSubjects,
                ageGroups: staffAgeGroups,
                schoolLevels: staffSchoolLevels,
              }) ?? [],

            staffByIndividualStudent:
              getStaffOfStudentsFromIndividuals(response.message_individuals)?.map(
                convertIndividualToUserSearchResult,
              ) ?? [],

            actualParents:
              response.message_recipients.filter(
                (recipient) => recipient.school_user_relation_role === SchoolUserRole.Parent,
              ) ?? [],

            actualStaff:
              response.message_recipients.filter(
                (recipient) => recipient.school_user_relation_role === SchoolUserRole.Staff,
              ) ?? [],
          });
        }
      } catch (e) {
        setError(e as ApiError);
        showError(e as ApiError);
      }

      setFetching(false);
    }
  }, [
    messageId,
    messageForm,
    setState,
    properties,
    groupSubjects,
    studentAgeGroups,
    studentSchoolLevels,
    staffAgeGroups,
    staffSchoolLevels,
    consentForm,
    showError,
  ]);

  const criteriaDateObj = useMemo(
    () => (state?.criteriaDate ? newDateTimezoneOffset(state.criteriaDate) : undefined),
    [state?.criteriaDate],
  );

  const setCriteriaDate = useCallback<MessageContextProps['actions']['setCriteriaDate']>(
    (criteriaDate) => {
      if (removeGroupsConfirmShown) {
        setRemoveGroupsConfirmShown(false);
      }
      form.set('prevCriteriaDate', state?.criteriaDate);
      form.set(
        'criteriaDate',
        criteriaDate ? format(criteriaDate, DEFAULT_DATE_FORMAT_FNS) : undefined,
      );
    },
    [form, state?.criteriaDate, removeGroupsConfirmShown],
  );

  const setAttachments = useCallback<MessageContextProps['actions']['setAttachments']>(
    (attachments) => {
      form.set('attachments', attachments ?? []);
    },
    [form],
  );

  const setParentsSelectBy = useCallback<MessageContextProps['actions']['setParentsSelectBy']>(
    (parentsSelectBy) => {
      setContextState({ parentsSelectBy });
    },
    [setContextState],
  );

  const setStaffSelectBy = useCallback<MessageContextProps['actions']['setStaffSelectBy']>(
    (staffSelectBy) => {
      setContextState({ staffSelectBy });
    },
    [setContextState],
  );

  const setFetchingAttachmentId = useCallback(
    (attachmentId: string) => {
      setFetchingAttachments({ ...fetchingAttachments, [attachmentId]: true });
    },
    [fetchingAttachments],
  );

  const removeLoadingAttachmentId = useCallback(
    (attachmentId: string) => {
      setFetchingAttachments({ ...fetchingAttachments, [attachmentId]: false });
    },
    [fetchingAttachments],
  );

  const fetchAttachment = useCallback<MessageContextProps['actions']['fetchAttachment']>(
    async (attachmentId) => {
      if (!messageId || !attachmentId) return;

      setFetchingAttachmentId(attachmentId);

      try {
        const data = await getAttachment(attachmentId, messageId);
        triggerBase64Download(getBase64Href(data), getAttachmentName(data));
      } catch (err) {
        showError(err as ApiError);
      } finally {
        removeLoadingAttachmentId(attachmentId);
      }
    },
    [messageId, removeLoadingAttachmentId, setFetchingAttachmentId, showError],
  );

  const setMode = useCallback<MessageContextProps['actions']['setMode']>(
    (mode, autoFocus) => {
      setState({ ...state, mode, autoFocus });
    },
    [setState, state],
  );

  const handlePreview = useCallback<MessageContextProps['actions']['handlePreview']>(
    (id) => {
      navigate(`/messages/${id}`, { state: { replace: true } });
    },
    [navigate],
  );

  const saveMessage = useCallback<MessageContextProps['actions']['saveMessage']>(
    async (savingAction) => {
      if (!canSave) {
        return;
      }

      if (consentFormShowed) {
        const isValid = await consentForm.trigger();
        if (!isValid) return;
      }

      if (!schoolId) {
        const error = new IntlError('error-NoSchoolId');

        showError(error);
        throw error;
      }

      const data: MessageUpdate = {
        title: messageForm.getValues('title'),
        body: messageForm.getValues('body'),
        attachments: convertedFiles as OutcomingAttachment[],
        school_id: schoolId,
        parents_ids: state.parents.map(({ relation_id }) => relation_id),
        parents_of_students_ids: state.parentsByIndividualStudent?.map(
          ({ relation_id }) => relation_id,
        ),
        parents_of_students_criteria: buildCriteriaUpdate(state.parentsCriteria),
        staff_ids: state.staff.map(({ relation_id }) => relation_id),
        staff_of_students_ids: state.staffByIndividualStudent.map(({ relation_id }) => relation_id),
        staff_criteria: buildCriteriaUpdate(state.staffCriteria),
        criteria_date: state.criteriaDate || null,
      };

      const modifiedData = Object.fromEntries(
        Object.entries(data).filter(([k]) => k !== 'school_id'),
      ) as Omit<MessageUpdate, 'school_id'>;

      modifiedData.attachment_ids_to_save = (state.attachments as AttachmentFile[])
        ?.filter((f) => !f.file)
        .map((f) => f.id);

      setState({ ...state, saving: true, savingAction });

      const shouldRequestAttachments = !!data.attachments.length;
      const consentFormPayload = consentFormShowed ? consentForm.getValues() : undefined;

      const onSuccess = async (id: string, responseText: string) => {
        const createdConsentForm = consentFormPayload
          ? await createConsentForm.mutateAsync(
              {
                reference_id: id,
                reference_type: 'message',
                description: consentFormPayload.description,
              },
              { onError: showError },
            )
          : null;

        if (consentFormShowed) hideConsentForm();

        showNotification({ message: responseText, type: 'success' });

        setState({
          ...state,
          saving: shouldRequestAttachments ? true : false,
          savingAction: undefined,
          ...(createdConsentForm && { linkedConsentForm: createdConsentForm }),
        });

        if (shouldRequestAttachments) {
          await request();
        }

        invalidateQueries();

        if (savingAction === 'close') goBack();
        else handlePreview(id);
      };

      try {
        if (messageId) {
          await updateMessage.mutateAsync(
            { messageId, data: modifiedData },
            {
              onSuccess: async ({ success }) => {
                await onSuccess(messageId, success);
              },
            },
          );
        } else {
          await createMessage.mutateAsync(data, {
            onSuccess: async ({ id, success }) => {
              await onSuccess(id, success);
            },
          });
        }
      } catch (err) {
        showError(err as ApiError);
        setState({
          ...state,
          saving: false,
          savingAction: undefined,
        });
      }
    },
    [
      canSave,
      consentFormShowed,
      schoolId,
      state,
      messageForm,
      convertedFiles,
      setState,
      consentForm,
      showError,
      createConsentForm,
      hideConsentForm,
      showNotification,
      invalidateQueries,
      goBack,
      handlePreview,
      request,
      messageId,
      updateMessage,
      createMessage,
    ],
  );

  const deleteMessage = useCallback<MessageContextProps['actions']['deleteMessage']>(async () => {
    if (!messageId) {
      return;
    }
    setState({ ...state, deleting: true, deletingConfirmation: false });
    try {
      const response = await removeMessage.mutateAsync(messageId);

      showNotification({ message: response.success, type: 'success' });
      setState({ ...state, deleting: false });

      invalidateQueries();

      closeAndClean();

      return response;
    } catch (err) {
      showError(err as ApiError);
      setState({ ...state, deleting: false });
    }
  }, [
    messageId,
    setState,
    state,
    removeMessage,
    showNotification,
    invalidateQueries,
    closeAndClean,
    showError,
  ]);

  const setDeletingConfirmation = useCallback<
    MessageContextProps['actions']['setDeletingConfirmation']
  >(
    (deletingConfirmation) => {
      setState({ ...state, deletingConfirmation });
    },
    [setState, state],
  );

  const cancelDeletingConfirmation = useCallback<
    MessageContextProps['actions']['cancelDeletingConfirmation']
  >(() => {
    setDeletingConfirmation(false);
  }, [setDeletingConfirmation]);

  const removeParent = useCallback<MessageContextProps['actions']['removeParent']>(
    (student) => {
      form.set(
        'parents',
        state?.parents.filter((user) => user.relation_id !== student.relation_id),
      );
    },
    [form, state?.parents],
  );

  const sendAction = useCallback(
    async (actionType: MessageAction) => {
      if (!messageId) {
        return;
      }

      dispatch(ReduxActions.resetHighlightedMessageId());

      switch (actionType) {
        case MessageAction.Publish:
          setIsPublishInProgress(true);
          break;
        case MessageAction.Submit:
          setIsSubmitInProgress(true);
          break;
        case MessageAction.Unsubmit:
          setIsUnsubmitInProgress(true);
          break;
        default:
          break;
      }

      const notificationActions: NotificationActions | undefined =
        actionType === MessageAction.Submit
          ? [
              {
                textId: 'messages-ViewMessage',
                handler: () => navigate(`/messages/${messageId}`),
                buttonColor: 'light',
              },
              {
                textId: 'messages-Unsubmit',
                handler: async () => {
                  try {
                    await messageAction(messageId, MessageAction.Unsubmit);

                    navigate(`/messages/${messageId}`);
                  } catch (error) {
                    showNotification({
                      message: (error as ApiError).reason,
                      type: 'error',
                    });
                  }
                },
                isOutline: true,
                buttonColor: 'white-text',
              },
            ]
          : undefined;

      try {
        const response = await messageAction(messageId, actionType);

        showNotification({
          message: response.success,
          type: 'success',
          actions: notificationActions,
        });

        if (actionType === MessageAction.Unsubmit) {
          request();
          invalidateQueries();
          return;
        }

        if (actionType === MessageAction.Submit) {
          dispatch(ReduxActions.setHighlightedMessageId(messageId));
        }

        request();
        invalidateQueries();
      } catch (err) {
        showError(err as ApiError | IntlError);
      } finally {
        setIsPublishInProgress(false);
        setIsSubmitInProgress(false);
        setIsUnsubmitInProgress(false);
      }
    },
    [dispatch, messageId, navigate, invalidateQueries, request, showError, showNotification],
  );

  const publishMessage = useCallback(() => {
    return sendAction(MessageAction.Publish);
  }, [sendAction]);

  const submitMessage = useCallback(() => {
    return sendAction(MessageAction.Submit);
  }, [sendAction]);

  const unsubmitMessage = useCallback(() => {
    return sendAction(MessageAction.Unsubmit);
  }, [sendAction]);

  const confirmRemoveArchivedCriteria = useCallback(
    async (criteria: AvailableCriteria) => {
      const isArchived = getCriteriaArchived(criteria);

      if (isArchived) {
        const name = getCriteriaName(criteria);

        const isConfirmed = await getConfirmation({
          textId: 'deselect-criteria-archived',
          textValues: { name },
        });

        if (!isConfirmed) {
          return false;
        }
      }

      return true;
    },
    [getConfirmation],
  );

  const addParent = useCallback<MessageContextProps['actions']['addParent']>(
    (student) => {
      if (!state?.parents?.find((user) => user.relation_id === student.relation_id)) {
        form.set('parents', [...state?.parents, student]);
      } else {
        removeParent(student);
      }
    },
    [form, removeParent, state?.parents],
  );

  const addParentsCriteria = useCallback<MessageContextProps['actions']['addParentsCriteria']>(
    (criteria) => {
      form.set('parentsCriteria', [...state?.parentsCriteria, criteria]);
    },
    [form, state?.parentsCriteria],
  );

  const addParentsCriteriaMultiple = useCallback<
    MessageContextProps['actions']['addParentsCriteriaMultiple']
  >(
    (criteria) => {
      form.set('parentsCriteria', [...state?.parentsCriteria, ...criteria]);
    },
    [form, state?.parentsCriteria],
  );

  const removeParentsCriteria = useCallback<
    MessageContextProps['actions']['removeParentsCriteria']
  >(
    async (criteria) => {
      const isConfirmed = await confirmRemoveArchivedCriteria(criteria);

      if (!isConfirmed) {
        return;
      }

      const parentsCriteria = state?.parentsCriteria.filter((c) => {
        if (c.school_property) {
          return c.school_property.id !== criteria.school_property?.id;
        }

        if (c.subject) {
          return c.subject.id !== criteria.subject?.id;
        }

        if (c.group) {
          return c.group.id !== criteria.group?.id;
        }

        return !(c.enum_index === criteria.enum_index && c.type === criteria.type);
      });

      form.set('parentsCriteria', parentsCriteria);
    },
    [confirmRemoveArchivedCriteria, form, state?.parentsCriteria],
  );

  const removeParentsCriteriaMultiple = useCallback<
    MessageContextProps['actions']['removeParentsCriteriaMultiple']
  >(
    (criteria) => {
      const parentsCriteria = state?.parentsCriteria.filter((c) => {
        if (c.school_property) {
          return !criteria.some(
            ({ school_property }) => school_property?.id === c.school_property?.id,
          );
        }

        if (c.subject) {
          return !criteria.some(({ subject }) => subject?.id === c.subject?.id);
        }

        if (c.group) {
          return !criteria.some(({ group }) => group?.id === c.group?.id);
        }

        return !criteria.some(
          ({ enum_index, type }) => enum_index === c.enum_index && type === c.type,
        );
      });

      form.set('parentsCriteria', parentsCriteria);
    },
    [form, state?.parentsCriteria],
  );

  const removeNotActualCriteriaGroups = useCallback(
    (toDeleteCriteriaGroups: AvailableCriteria[]) => {
      form.set(
        'parentsCriteria',
        state.parentsCriteria.filter(
          (c) =>
            !toDeleteCriteriaGroups.some(({ group }) => c.group?.id && c.group.id === group?.id),
        ),
      );

      form.set(
        'staffCriteria',
        state.staffCriteria.filter(
          (c) =>
            !toDeleteCriteriaGroups.some(({ group }) => c.group?.id && c.group.id === group?.id),
        ),
      );
    },
    [form, state?.parentsCriteria, state?.staffCriteria],
  );

  const removeParentByIndividualStudent = useCallback<
    MessageContextProps['actions']['removeParentByIndividualStudent']
  >(
    (student) => {
      form.set(
        'parentsByIndividualStudent',
        state?.parentsByIndividualStudent.filter(
          (user) => user.relation_id !== student.relation_id,
        ),
      );
    },
    [form, state?.parentsByIndividualStudent],
  );

  const addParentByIndividualStudent = useCallback<
    MessageContextProps['actions']['addParentByIndividualStudent']
  >(
    (student) => {
      if (
        !state?.parentsByIndividualStudent?.find((user) => user.relation_id === student.relation_id)
      ) {
        form.set('parentsByIndividualStudent', [...state?.parentsByIndividualStudent, student]);
      } else {
        removeParentByIndividualStudent(student);
      }
    },
    [form, removeParentByIndividualStudent, state?.parentsByIndividualStudent],
  );

  const removeStaff = useCallback<MessageContextProps['actions']['removeStaff']>(
    (student) => {
      form.set(
        'staff',
        state?.staff.filter((user) => user.relation_id !== student.relation_id),
      );
    },
    [form, state?.staff],
  );

  const addStaff = useCallback<MessageContextProps['actions']['addStaff']>(
    (student) => {
      if (!state?.staff?.find((user) => user.relation_id === student.relation_id)) {
        form.set('staff', [...state?.staff, student]);
      } else {
        removeStaff(student);
      }
    },
    [form, removeStaff, state?.staff],
  );

  const addStaffCriteria = useCallback<MessageContextProps['actions']['addStaffCriteria']>(
    (criteria) => {
      form.set('staffCriteria', [...state?.staffCriteria, criteria]);
    },
    [form, state?.staffCriteria],
  );

  const addStaffCriteriaMultiple = useCallback<
    MessageContextProps['actions']['addStaffCriteriaMultiple']
  >(
    (criteria) => {
      form.set('staffCriteria', [...state?.staffCriteria, ...criteria]);
    },
    [form, state?.staffCriteria],
  );

  const removeStaffCriteria = useCallback<MessageContextProps['actions']['removeStaffCriteria']>(
    async (criteria) => {
      const isConfirmed = await confirmRemoveArchivedCriteria(criteria);

      if (!isConfirmed) {
        return;
      }

      const staffCriteria = state?.staffCriteria.filter((c) => {
        if (c.school_property) {
          return c.school_property.id !== criteria.school_property?.id;
        }

        if (c.subject) {
          return c.subject.id !== criteria.subject?.id;
        }

        if (c.group) {
          return c.group.id !== criteria.group?.id;
        }

        return !(c.enum_index === criteria.enum_index && c.type === criteria.type);
      });

      form.set('staffCriteria', staffCriteria);
    },
    [confirmRemoveArchivedCriteria, form, state?.staffCriteria],
  );

  const removeStaffCriteriaMultiple = useCallback<
    MessageContextProps['actions']['removeStaffCriteriaMultiple']
  >(
    async (criteria) => {
      const staffCriteria = state?.staffCriteria.filter((c) => {
        if (c.school_property) {
          return !criteria.some(
            ({ school_property }) => school_property?.id === c.school_property?.id,
          );
        }

        if (c.subject) {
          return !criteria.some(({ subject }) => subject?.id === c.subject?.id);
        }

        if (c.group) {
          return !criteria.some(({ group }) => group?.id === c.group?.id);
        }

        return !criteria.some(
          ({ enum_index, type }) => enum_index === c.enum_index && type === c.type,
        );
      });

      form.set('staffCriteria', staffCriteria);
    },
    [form, state?.staffCriteria],
  );

  const removeStaffByIndividualStudent = useCallback<
    MessageContextProps['actions']['removeStaffByIndividualStudent']
  >(
    (student) => {
      form.set(
        'staffByIndividualStudent',
        state?.staffByIndividualStudent.filter((user) => user.relation_id !== student.relation_id),
      );
    },
    [form, state?.staffByIndividualStudent],
  );

  const addStaffByIndividualStudent = useCallback<
    MessageContextProps['actions']['addStaffByIndividualStudent']
  >(
    (student) => {
      if (
        !state?.staffByIndividualStudent?.find((user) => user.relation_id === student.relation_id)
      ) {
        form.set('staffByIndividualStudent', [...state?.staffByIndividualStudent, student]);
      } else {
        removeStaffByIndividualStudent(student);
      }
    },
    [form, removeStaffByIndividualStudent, state?.staffByIndividualStudent],
  );

  const propsLoaded = !!studentProperties && !!groupSubjects && !!staffProperties;

  /* Set initial state */
  useEffect(() => {
    if (!propsLoaded) {
      return;
    }
    // set initial state on Modal
    setContextName(CONTEXT_NAME);

    if (messageId) {
      request();
    }
  }, [messageId, propsLoaded, request, setContextName]);

  useEffect(() => {
    if (!state) {
      // no messageId means a new one is being created,
      // set default initial state in this case
      setTimeout(() => {
        const state = { ...getInitialState(), ...location.state };
        messageForm.reset({ body: state.body, title: state.title });
        setState(state);
      });
    }
  }, [location.state, messageForm, setState, state]);

  const fetchGroups = useCallback(
    async (params: IListGroupArguments) => {
      const { results: groups } = await listGroups(params);
      setNotActualGroups([]);

      const currentCriteria = [...state.parentsCriteria, ...state.staffCriteria].reduce<
        AvailableCriteria[]
      >(
        (acc, next) =>
          next.group?.id
            ? [...acc.filter(({ group }) => next.group?.id && group?.id !== next.group.id), next]
            : acc,
        [],
      );

      const notActualGroups = currentCriteria.filter(
        ({ group }) => !groups.some((g) => g.id === group?.id),
      );

      setNotActualGroups(notActualGroups);

      const dateIsChanged =
        !!notActualGroups.length && !!state.prevCriteriaDate && !removeGroupsConfirmShown;

      const dialogNotShown = !!notActualGroups.length && !removeGroupsConfirmShown;

      if (dialogNotShown || dateIsChanged) {
        setRemoveGroupsConfirmShown(true);
        showRemoveGroupDialog();
      }

      return groups;
    },
    [
      removeGroupsConfirmShown,
      showRemoveGroupDialog,
      state?.parentsCriteria,
      state?.prevCriteriaDate,
      state?.staffCriteria,
    ],
  );

  const handleConfirmRemoveGroupDialog = useCallback(() => {
    removeNotActualCriteriaGroups(notActualGroups);
    setNotActualGroups([]);
    hideRemoveGroupDialog();
  }, [hideRemoveGroupDialog, notActualGroups, removeNotActualCriteriaGroups]);

  const handleCloseRemoveGroupDialog = useCallback(() => {
    hideRemoveGroupDialog();
  }, [hideRemoveGroupDialog]);

  const handleHideConsentForm = useCallback(() => {
    consentForm.reset();
    hideConsentForm();
  }, [consentForm, hideConsentForm]);

  const value = {
    ...state,
    title: messageForm.watch('title'),
    body: messageForm.watch('body'),
    state,
    form,
    fetching: !state || fetching || !studentProperties || !groupSubjects,
    errors: state?.touched ? state?.errors : {},
    fetchingAttachments,
    messageId,
    criteriaDateObj,
    hasCreatorPermission,
    hasPublisherPermission,
    hasViewerPermission,
    canSave,
    canEdit,
    canPublish,
    canSubmit,
    canUnsubmit,
    hasParents,
    hasStaff,
    hasRecipients,
    isPublished,
    isDraft,
    isSubmitted,
    isCreator,
    isPublishInProgress,
    isSubmitInProgress,
    isUnsubmitInProgress,
    isRemoveGroupDialogOpen,
    isBodyLengthOverLimit,
    notActualGroups,
    error,
    messageForm,
    consentForm,
    publishDate:
      // When we get the state from the router, it will be a string as in the BE response
      typeof state?.publishDate === 'string' ? new Date(state.publishDate) : state?.publishDate,
    consentFormShowed,
    actions: {
      setMode,
      setCriteriaDate,
      setAttachments,
      setParentsSelectBy,
      setStaffSelectBy,
      fetchAttachment,
      handleClose: goBack,
      handlePreview,
      saveMessage,
      deleteMessage,
      setDeletingConfirmation,
      cancelDeletingConfirmation,
      publishMessage,
      submitMessage,
      unsubmitMessage,
      addParent,
      removeParent,
      addParentsCriteria,
      addParentsCriteriaMultiple,
      removeParentsCriteria,
      removeParentsCriteriaMultiple,
      addParentByIndividualStudent,
      removeParentByIndividualStudent,
      addStaff,
      removeStaff,
      addStaffCriteria,
      addStaffCriteriaMultiple,
      removeStaffCriteria,
      removeStaffCriteriaMultiple,
      addStaffByIndividualStudent,
      removeStaffByIndividualStudent,
      fetchGroups,
      handleCloseRemoveGroupDialog,
      handleConfirmRemoveGroupDialog,
      showConsentForm,
      hideConsentForm: handleHideConsentForm,
    },
  };

  return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>;
};
