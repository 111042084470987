import {
  Backdrop,
  Button,
  ClickAwayListener,
  Icon,
  IconButton,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { DATE_FORMAT } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { CalendarTodayIcon, CrossCircleIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, isToday } from 'date-fns';
import { FC, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

import { TodayButton } from './DatePicker/DatePickerCommonComponents';
import { DateSelectContent, DateSelectContentRef } from './DatePicker/DateSelectContent';

type ButtonDateSelectProps = {
  date: string;
  onSetDate: (v: Date) => void;
  onSetToday?: () => void;
  getDayStyleProps?: (d: Date) => Omit<SxProps, 'children'>;
  canClear?: boolean;
  onClear?: () => void;
};

export const ButtonDateSelect: FC<ButtonDateSelectProps> = ({
  date: dateString,
  onSetDate,
  onSetToday,
  getDayStyleProps,
  canClear,
  onClear,
}) => {
  const { $t } = useIntl();
  const [opened, open, close] = useFlag(false);
  const date = newDateTimezoneOffset(dateString);
  const isTodayDate = isToday(date);
  const value = format(date, DATE_FORMAT);
  const dateContainer = useRef<DateSelectContentRef | null>(null);

  const label = (
    <Typography
      variant="h3"
      display="flex"
      color={opened ? 'background.paper' : 'text.primary'}
      gap={1}
      onClick={open}
    >
      <Icon>
        <CalendarTodayIcon />
      </Icon>
      {isTodayDate ? $t({ id: 'datepicker-Today' }) : value}
    </Typography>
  );

  const handleSetDate = useCallback(
    (d: Date) => {
      onSetDate(d);
      close();
    },
    [close, onSetDate],
  );

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            onClose={close}
            open={opened}
            placement="bottom-end"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  borderRadius: theme.spacing(1),
                  width: 260,
                  maxWidth: 260,
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0.5, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <DateSelectContent
                ref={dateContainer}
                date={date}
                onSetDate={handleSetDate}
                getDayStyleProps={getDayStyleProps}
              >
                <TodayButton
                  onClick={() => {
                    const now = new Date();
                    dateContainer.current?.setVisibleMonthDate(now);
                    onSetToday ? onSetToday() : onSetDate(now);
                    close();
                  }}
                />
              </DateSelectContent>
            }
          >
            <Stack
              sx={{
                zIndex: (theme) => (opened ? theme.zIndex.drawer + 2 : undefined),
                cursor: 'pointer',
                '&:hover': {
                  '.MuiTypography-root': {
                    color: (theme) => (!opened ? theme.palette.primary.main : 'inherit'),
                  },
                },
              }}
              height={28}
              direction="row"
              alignItems="center"
              position="relative"
            >
              {opened ? (
                <Button
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    close();
                  }}
                  sx={(theme) => ({
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                    },
                    '&.MuiButton-root': {
                      padding: theme.spacing(0.25, 1, 0.25, 0.75),
                    },
                  })}
                  endIcon={
                    canClear ? (
                      <IconButton
                        sx={(theme) => ({
                          color: theme.palette.background.paper,
                          width: theme.spacing(1.5),
                        })}
                        onClick={onClear}
                      >
                        <CrossCircleIcon />
                      </IconButton>
                    ) : undefined
                  }
                >
                  {label}
                </Button>
              ) : (
                label
              )}
            </Stack>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};
