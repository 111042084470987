import {
  Application,
  ApplicationChild,
  ApplicationCustomField,
  ApplicationRelation,
  CustomField,
  Parent,
} from '@schooly/api';
import { CreateChildForm, CreateParentForm } from '@schooly/components/applications';
import { CreateCustomField } from '@schooly/components/applications';
import { countriesWithAreaCodes, CustomFieldBooleanValues } from '@schooly/constants';
import { useCallback, useMemo } from 'react';

import { useCustomFields } from '../../hooks/useCustomFields';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  actions as applicationActions,
  ApplicationAction,
} from '../../redux/slices/applicationSlice';

export const convertChildToForm = (
  c: ApplicationChild,
  customFields: CustomField[],
): CreateChildForm => {
  const language = [c.language, ...(c.other_languages_spoken ?? [])].map(
    ([language, speaking, writing]) => ({ language, speaking, writing }),
  );

  const custom_fields =
    customFields?.reduce<CreateChildForm['custom_fields']>((acc, value) => {
      const field = c.custom_fields_values?.find((f) => value.id === f.custom_field_id);

      return field
        ? [
            ...acc,
            {
              ...value,
              value: Array.isArray(field.value) ? field.value[0] : field.value,
              checked: CustomFieldBooleanValues.Yes,
              is_severe: field.is_severe,
            },
          ]
        : [...acc, { ...value, value: '', checked: CustomFieldBooleanValues.No, is_severe: false }];
    }, []) ?? [];

  return {
    ...c,
    school_history: c.school_history ?? [],
    language,
    custom_fields,
  };
};

export const convertParentToForm = (
  p: Parent,
  customFields: CustomField[],
  relations?: ApplicationRelation[],
): CreateParentForm => {
  const relationType = relations?.find((r) => p.id === r.adult_rid)?.relationship_type;
  const relationship_type = Number.isInteger(relationType) ? relationType : undefined;

  const custom_fields =
    customFields?.reduce<CreateParentForm['custom_fields']>((acc, value) => {
      const field = p.custom_fields?.find((f) => value.id === f.custom_field_id);

      return field
        ? [
            ...acc,
            {
              ...value,
              value: Array.isArray(field.value) ? field.value[0] : field.value,
              checked: CustomFieldBooleanValues.Yes,
              is_severe: field.is_severe,
            },
          ]
        : [...acc, { ...value, value: '', checked: CustomFieldBooleanValues.No, is_severe: false }];
    }, []) ?? [];

  if (!p.telephone) {
    return { ...p, relationship_type, custom_fields };
  }
  const code =
    p.telephone && countriesWithAreaCodes.find(({ code }) => p.telephone?.includes(code))?.code;

  if (code) {
    return {
      ...p,
      phoneCode: code,
      telephone: p.telephone.replace(code, ''),
      relationship_type,
      custom_fields,
    };
  }

  return { ...p, phoneCode: '', relationship_type, custom_fields };
};

export const convertGeneralCustomFieldsToForm = (
  generalCustomFields: ApplicationCustomField[],
  customFields: CustomField[],
): CreateCustomField[] => {
  return (
    customFields.reduce<CreateCustomField[]>((acc, value) => {
      const field = generalCustomFields?.find((f) => value.id === f.custom_field_id);

      return field
        ? [
            ...acc,
            {
              ...value,
              value: Array.isArray(field.value) ? field.value[0] : field.value,
              checked: CustomFieldBooleanValues.Yes,
            },
          ]
        : [...acc, { ...value, value: '', checked: CustomFieldBooleanValues.No, is_severe: false }];
    }, []) ?? []
  );
};

export interface ApplicationForm {
  children: CreateChildForm[];
  parents: CreateParentForm[];
  generalCustomFields: CreateCustomField[];
  addChild: (child: CreateChildForm) => void;
  addParents: (parents: CreateParentForm[]) => void;
  addGeneralCustomFields: (customFields: CreateCustomField[]) => void;
  removeParent: (parenId: string) => void;
  removeChild: (childId: string) => void;
  resetForm: () => void;
  prepareFormData: (application: Application) => void;
  prepareCreateFormData: (customFields: CustomField[]) => void;
}

export const useApplicationForm = (actionType: ApplicationAction): ApplicationForm => {
  const dispatch = useAppDispatch();

  const { parents, children, generalCustomFields } = useAppSelector(
    (state) => state.applications[actionType],
  );

  const {
    applicationChildCustomFields,
    applicationGeneralCustomFields,
    applicationAdultCustomFields,
  } = useCustomFields({ refetchOnMount: 'always' });

  const addChild = useCallback<ApplicationForm['addChild']>(
    (child) => {
      dispatch(applicationActions.add({ actionType, type: 'children', entities: [child] }));
    },
    [actionType, dispatch],
  );

  const removeChild = useCallback(
    (childId: string) => {
      dispatch(applicationActions.remove({ actionType, type: 'children', entityId: childId }));
    },
    [actionType, dispatch],
  );

  const addParents = useCallback<ApplicationForm['addParents']>(
    (parents) => {
      dispatch(applicationActions.add({ actionType, type: 'parents', entities: parents }));
    },
    [actionType, dispatch],
  );

  const removeParent = useCallback(
    (parentId: string) => {
      dispatch(applicationActions.remove({ actionType, type: 'parents', entityId: parentId }));
    },
    [actionType, dispatch],
  );

  const addGeneralCustomFields = useCallback<ApplicationForm['addGeneralCustomFields']>(
    (generalCustomFields) => {
      dispatch(
        applicationActions.add({
          actionType,
          type: 'generalCustomFields',
          entities: generalCustomFields,
        }),
      );
    },
    [actionType, dispatch],
  );

  const prepareFormData = useCallback(
    (application: Application) => {
      const children = application.children.map((c) =>
        convertChildToForm(c, applicationChildCustomFields ?? []),
      );

      const parents = application.adults.map((p) =>
        convertParentToForm(p, applicationAdultCustomFields ?? [], application.relations),
      );

      const generalCustomFields = convertGeneralCustomFieldsToForm(
        application.general_info_custom_fields_values,
        applicationGeneralCustomFields ?? [],
      );

      addParents(parents);
      children.forEach(addChild);
      addGeneralCustomFields(generalCustomFields);
    },
    [
      applicationChildCustomFields,
      applicationGeneralCustomFields,
      applicationAdultCustomFields,
      addChild,
      addParents,
      addGeneralCustomFields,
    ],
  );

  const prepareCreateFormData = useCallback(
    (customFields: CustomField[]) => {
      const generalCustomFields = convertGeneralCustomFieldsToForm([], customFields);

      addGeneralCustomFields(generalCustomFields);
    },
    [addGeneralCustomFields],
  );

  const resetForm = useCallback(() => {
    dispatch(applicationActions.reset({ actionType }));
  }, [actionType, dispatch]);

  return useMemo(
    () => ({
      children,
      parents,
      generalCustomFields,
      addChild,
      removeChild,
      addParents,
      removeParent,
      resetForm,
      prepareFormData,
      prepareCreateFormData,
      addGeneralCustomFields,
    }),
    [
      children,
      parents,
      generalCustomFields,
      addChild,
      addParents,
      removeChild,
      removeParent,
      resetForm,
      prepareFormData,
      addGeneralCustomFields,
      prepareCreateFormData,
    ],
  );
};
