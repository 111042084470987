export type HandlerName =
  | 'onEnter'
  | 'onBackspace'
  | 'onDelete'
  | 'onEsc'
  | 'onUp'
  | 'onDown'
  | 'onTab';
export type KeyboardCallback = (e: KeyboardEvent) => void;
export type Handler = {
  callback: KeyboardCallback;
  force?: boolean;
};
export type CaptureOptions = Partial<Record<HandlerName, KeyboardCallback>>;

const keyToHandlerName: Record<string, HandlerName> = {
  Enter: 'onEnter',
  Backspace: 'onBackspace',
  Delete: 'onDelete',
  Esc: 'onEsc',
  Escape: 'onEsc',
  ArrowUp: 'onUp',
  ArrowDown: 'onDown',
  Tab: 'onTab',
};

const handlers: Record<HandlerName, Handler[]> = {
  onEnter: [],
  onDelete: [],
  onBackspace: [],
  onEsc: [],
  onUp: [],
  onDown: [],
  onTab: [],
};

function hasActiveHandlers() {
  return Object.values(handlers).some((keyHandlers) => Boolean(keyHandlers.length));
}

function handleKeyDown(e: KeyboardEvent) {
  const handlerName = keyToHandlerName[e.key];
  if (!handlerName) {
    return;
  }

  const { length } = handlers[handlerName];
  if (!length) {
    return;
  }
  e.stopPropagation();

  const handler = handlers[handlerName][length - 1];

  handler?.callback!(e);
}

function releaseKeyboardListener(options: CaptureOptions) {
  (Object.keys(options) as Array<HandlerName>).forEach((handlerName) => {
    const callback = options[handlerName];
    const currentEventHandlers = handlers[handlerName];
    if (currentEventHandlers) {
      const index = currentEventHandlers.findIndex((cb) => cb.callback === callback);
      if (index !== -1) {
        currentEventHandlers.splice(index, 1);
      }
    }
  });

  if (!hasActiveHandlers()) {
    document.removeEventListener('keydown', handleKeyDown, false);
  }
}

export default function captureKeyboardListeners(options: CaptureOptions, force = false) {
  if (!hasActiveHandlers()) {
    document.addEventListener('keydown', handleKeyDown, true);
  }

  (Object.keys(options) as Array<HandlerName>).forEach((handlerName) => {
    const callback = options[handlerName];

    if (!callback) {
      return;
    }

    const handler: Handler = { callback, force };
    const currentEventHandlers = handlers[handlerName];

    if (currentEventHandlers) {
      const forcedHandlerIndex = currentEventHandlers.findIndex((h: Handler) => h.force);

      // Ensure new event handlers are coming after forced handlers
      if (forcedHandlerIndex > -1) {
        handlers[handlerName].splice(forcedHandlerIndex - 1, 0, handler);
      } else {
        currentEventHandlers.push(handler);
      }
    }
  });

  return () => {
    releaseKeyboardListener(options);
  };
}
