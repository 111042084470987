import { Avatar, Typography, TypographyProps } from '@mui/material';
import { School } from '@schooly/api';
import React, { FC } from 'react';

import { SchoolAvatar } from './SchoolAvatar';
import { SchoolSelectItemStyled } from './SchoolSelect.styled';

export interface SchoolSelectItemProps {
  school: School;
  subitem?: boolean;
  color?: TypographyProps['color'];
  onClick?: React.MouseEventHandler;
}

export const SchoolSelectItem: FC<SchoolSelectItemProps> = ({
  school,
  subitem,
  color,
  onClick,
}) => {
  return (
    <SchoolSelectItemStyled onClick={onClick} subitem={subitem}>
      {school?.image ? (
        <Avatar sx={{ bgcolor: 'background.paper' }}>
          <SchoolAvatar school={school} />
        </Avatar>
      ) : (
        <Avatar
          sx={{ bgcolor: 'background.default', border: (theme) => theme.mixins.borderValue() }}
        >
          <Typography color="common.grey3">{school?.code}</Typography>
        </Avatar>
      )}
      <Typography
        variant={subitem ? 'h3' : 'h2'}
        color={color ?? 'inherit'}
        sx={{
          whiteSpace: 'normal',
          alignSelf: 'center',
        }}
      >
        {subitem ? school?.name : school?.code}
      </Typography>
    </SchoolSelectItemStyled>
  );
};
