import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3C16.5523 3 17 3.44772 17 4L17 15H3V4C3 3.44772 3.44772 3 4 3H16ZM3 16H17V17L3 17V16ZM10.4693 5.77774C10.3089 5.34094 9.69111 5.34094 9.53066 5.77774L8.76393 7.86511L6.50443 7.90868C6.02354 7.91796 5.8318 8.53447 6.22261 8.81485L8 10.09L7.34092 12.1516C7.19732 12.6007 7.69832 12.9808 8.09216 12.7214L10 11.4651L11.9078 12.7214C12.3017 12.9808 12.8027 12.6007 12.6591 12.1516L12 10.09L13.7774 8.81485C14.1682 8.53447 13.9765 7.91796 13.4956 7.90868L11.2361 7.86511L10.4693 5.77774Z"
      fill="#24275B"
    />
  </svg>
);
