import {
  FilterKeys,
  GroupLimitationsUpdate,
  SchoolUserType,
  useGetSchoolPropertiesQuery,
  UserFilter,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useMemo } from 'react';

import { convertSchoolUserRoleToNumericType } from '../helpers/misc';
import { UserRoleLimitationsUpdate } from '../pages/UserRoles/CreateUserRoleModal/Context/useUserRoleModal';

export const useLimitedTo = (
  userType: SchoolUserType,
  // TODO generic type
  limitedTo: GroupLimitationsUpdate | UserRoleLimitationsUpdate,
) => {
  const { schoolId = '' } = useAuth();
  const { school_property_ids, genders, nationalities, subject_ids } = limitedTo;

  const { data } = useGetSchoolPropertiesQuery(
    {
      schoolId: schoolId,
      userType: convertSchoolUserRoleToNumericType(userType),
      showReEnrollmentProperties: true,
    },
    { enabled: !!schoolId },
  );

  const filters: Partial<UserFilter> = useMemo(() => {
    const initialFilters: Partial<UserFilter> = {
      [FilterKeys.Gender]: genders,
      [FilterKeys.Nationality]: nationalities,
      [FilterKeys.Subject]: subject_ids,
    };

    const ageGroupIds = school_property_ids.filter((id) =>
      data?.age_groups.some((a) => a.id === id),
    );
    if (ageGroupIds.length) {
      initialFilters[FilterKeys.AgeGroup] = ageGroupIds;
    }

    return school_property_ids.reduce<Partial<UserFilter>>((prev, id) => {
      const property = data?.school_properties.find((p) => p.id === id);

      if (!property) {
        return prev;
      }

      const filterKey = property.type as unknown as FilterKeys;

      if (!prev[filterKey]) {
        prev[filterKey] = [];
      }

      prev[filterKey]?.push(property.id);

      return prev;
    }, initialFilters);
  }, [
    data?.age_groups,
    data?.school_properties,
    genders,
    nationalities,
    school_property_ids,
    subject_ids,
  ]);

  return { filters };
};
