import { Box, Typography } from '@mui/material';
import { FilterSelectOption, GroupLimitations, GroupUserType, SchoolProperty } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { DropdownSelect, toggleMultipleValueArrayProperty } from '@schooly/components/filters';
import { SelectSearchInput } from '@schooly/components/filters';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { ExtendedFieldError } from '../../../../components/ui/Input/utils';
import { convertSchoolPropertyPluralToSingular } from '../../../../helpers/school';
import searchWords from '../../../../utils/searchWords';
import { LimitedToContent } from './LimitedToContent';
import { LimitedToHeader } from './LimitedToHeader';
import { getLimitedToCategoryKey } from './utils';

export const DEFAULT_LIMITED_TO_SECTION_TEXT_IDS = {
  statuses: 'schoolProperty-Status',
  departments: 'schoolProperty-Department',
  school_levels: 'schoolProperty-AgeGroup-SchoolLevel',
  age_groups: 'schoolProperty-AgeGroup',
  subjects: 'groups-GroupSubject',
  campuses: 'schoolProperty-Campus',
  houses: 'schoolProperty-House',
  genders: 'peopleDetail-Gender',
  nationalities: 'peopleDetail-Nationality',
};

export type LimitedToCategory = keyof typeof DEFAULT_LIMITED_TO_SECTION_TEXT_IDS;
export type BaseLimitedToProps = Record<string, (string | number)[] | undefined>;

interface LimitedToSelectProps {
  hasValues: boolean;
  schoolProperties: SchoolProperty[];
  limitedTo: GroupLimitations;
  onChange: (val: GroupLimitations) => void;
  onOpen?: () => void;
  onClose?: () => void;
  userType: GroupUserType;
  isLoading: boolean;
  onToggle?: (v: boolean) => void;
  categories: LimitedToCategory[];
  options: Partial<Record<LimitedToCategory, FilterSelectOption[]>>;
  selectedOptions: Partial<Record<LimitedToCategory, FilterSelectOption[]>>;
  errors?: Partial<Record<LimitedToCategory, string>>;
  helperText?: string;
}

export interface LimitedToDropdownProps {
  open: () => void;
  close: () => void;
}

export const LimitedToSelect = forwardRef<LimitedToDropdownProps, LimitedToSelectProps>(
  (
    {
      hasValues,
      options,
      limitedTo,
      onChange,
      onOpen,
      onClose,
      userType,
      isLoading,
      onToggle,
      categories,
      selectedOptions,
      errors,
      helperText,
    },
    ref,
  ) => {
    const dropdownRef = useRef<DropdownSelect>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [hoveredGroupTagId, setHoveredGroupTagId] = useState('');
    const { $t } = useIntl();
    const { getConfirmation } = useConfirmationDialog();

    useImperativeHandle(
      ref,
      () => ({
        open: () => dropdownRef.current?.open(),
        close: () => dropdownRef.current?.close(),
      }),
      [],
    );

    useEffect(() => {
      if (!hasValues) dropdownRef.current?.open();
    }, [hasValues]);

    const displayedOptionsByCategory = useMemo(() => {
      const opt: Partial<Record<LimitedToCategory, FilterSelectOption[]>> = {};

      if (searchQuery) {
        categories.forEach((key) => {
          opt[key] = options[key]?.filter(
            (o) => !o.archived && o.label && searchWords(o.label, searchQuery),
          );
        });
      } else {
        categories.forEach((key) => {
          opt[key] = options[key]?.filter((o) => !o.archived);
        });
      }

      if (Object.values(opt).every((o) => !o || !o.length)) {
        return null;
      }

      return opt;
    }, [categories, options, searchQuery]);

    const handleSelectOption = useCallback(
      async (option: FilterSelectOption, category: LimitedToCategory) => {
        if (!categories.includes(category)) {
          return;
        }

        const key = getLimitedToCategoryKey(category);
        let processedValue: string | number = option.value;
        if (category === 'genders' || category === 'nationalities') {
          processedValue = Number(option.value);
        }

        const shouldRemove = limitedTo[key]?.includes(processedValue);

        const checkArchived = () => {
          if (shouldRemove && option.archived) {
            return getConfirmation({
              textId: `deselect-property-archived-${convertSchoolPropertyPluralToSingular(
                category,
              )}`,
              textValues: { name: option.label },
            });
          }

          return Promise.resolve(true);
        };

        const isConfirmed = await checkArchived();

        if (isConfirmed) {
          setSearchQuery('');
          onChange({
            ...limitedTo,
            [key]: shouldRemove
              ? limitedTo[key]?.filter((a: any) => a !== processedValue)
              : [...(limitedTo[key] ?? []), processedValue],
          });
        }

        searchRef.current?.focus();
      },
      [getConfirmation, categories, limitedTo, onChange],
    );

    const handleSelectMultipleOptions = useCallback(
      async (options: FilterSelectOption[], category: LimitedToCategory) => {
        if (!categories.includes(category)) {
          return;
        }

        const key = getLimitedToCategoryKey(category);

        setSearchQuery('');
        onChange({
          ...limitedTo,
          [key]: toggleMultipleValueArrayProperty(
            limitedTo[key],
            options.map(({ value }) => value),
          ),
        });

        searchRef.current?.focus();
      },
      [categories, limitedTo, onChange],
    );

    const error: ExtendedFieldError | undefined = useMemo(() => {
      const errorMessages = !!errors && Object.values(errors).join(', ');

      if (!errorMessages) return undefined;
      return {
        type: 'validate',
        message: errorMessages,
      };
    }, [errors]);

    const handleClose = useCallback(() => {
      setSearchQuery('');

      onClose?.();
    }, [onClose]);

    return (
      <>
        <DropdownSelect
          ref={dropdownRef}
          isLoading={isLoading}
          onClose={handleClose}
          onOpen={onOpen}
          onToggle={onToggle}
          error={error}
          placeholder={$t({ id: 'groups-LimitedTo' })}
          hasValues={hasValues || !!searchQuery}
          onClickInputArea={() => searchRef.current?.focus()}
          renderContent={() => (
            <LimitedToContent
              isEmpty={!!searchQuery && !displayedOptionsByCategory}
              onSelect={handleSelectOption}
              displayedOptionsByCategory={displayedOptionsByCategory}
              limitedTo={limitedTo}
              errors={errors}
              onSelectMultiple={handleSelectMultipleOptions}
              options={options}
              hoveredGroupTagId={hoveredGroupTagId}
              setHoveredGroupTagId={setHoveredGroupTagId}
            />
          )}
          layoutStyleProps={{
            minHeight: 42,
          }}
        >
          {(open) => (
            <LimitedToHeader
              selectedOptions={selectedOptions}
              isOpen={open}
              onRemove={handleSelectOption}
              userType={userType}
              hasLimitedTo={hasValues || !!searchQuery}
              isLoading={isLoading}
              onRemoveMultiple={handleSelectMultipleOptions}
              options={options}
              setHoveredGroupTagId={setHoveredGroupTagId}
            >
              {open && (
                <Box
                  sx={{
                    position: !hasValues && !searchQuery ? 'absolute' : 'inherit',
                  }}
                >
                  <SelectSearchInput
                    ref={searchRef}
                    autoFocus
                    value={searchQuery}
                    onChangeText={setSearchQuery}
                  />
                </Box>
              )}
            </LimitedToHeader>
          )}
        </DropdownSelect>

        {helperText && (
          <Box pl={1.5}>
            <Typography variant="caption" color="primary.main">
              {helperText}
            </Typography>
          </Box>
        )}
      </>
    );
  },
);
