import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.06536 2H15.9346C16.7705 2 17.2375 2.96451 16.7191 3.62018L11.6 10.0952V15.3512C11.6 15.6143 11.4963 15.8669 11.3113 16.0541L10.1113 17.2683C9.48304 17.9041 8.4 17.4592 8.4 16.5654V10.0952L3.2809 3.62018C2.76253 2.96451 3.22953 2 4.06536 2Z"
      fill="#24275B"
    />
  </svg>
);
