import { Chip, styled } from '@mui/material';

interface TagUserProps {
  selected: boolean;
}

export const TagUser = styled(Chip)<TagUserProps>(({ theme, selected }) => ({
  '&&.MuiChip-root': {
    ...theme.typography.h3,
    height: 44,
    minWidth: 120,
    justifyContent: 'flex-start',
    borderRadius: 100,
    background: selected ? theme.palette.primary.main : theme.palette.background.paper,
    color: selected ? theme.palette.background.paper : theme.palette.common.grey2,
    border: theme.mixins.borderControlValue(),
    borderColor: (() => {
      if (selected) {
        return theme.palette.primary.main;
      }

      return theme.palette.common.light2;
    })(),
  },
  '.MuiChip-label': {
    padding: theme.spacing(1),
  },

  '& .MuiChip-avatar': {
    width: 30,
    height: 30,
    marginRight: 0,
    color: theme.palette.common.grey3,
    fontSize: theme.typography.h3.fontSize,
  },
}));
