import { IconButton, Stack, Typography } from '@mui/material';
import { ConductConnotation, ConductTypeCreate, ConductTypeRepresentation } from '@schooly/api';
import {
  Counter,
  CrossIcon,
  DragIcon,
  EditIcon,
  GridRowCell,
  GridRowItem,
  GridRowStyled,
  ModalContent,
  ModalLarge,
  PlusIcon,
  SimpleButton,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { ConductConnotationIndicator } from '../../Conduct/ConductGrid';
import {
  ConductTypeForm,
  SchoolConductTypeModalContent,
} from '../SchoolConduct/SchoolConductTypeModalContent';
import { ConductTypesDroppable } from '../SchoolConduct/useSchoolConductChangeOrderModal';

type SchoolCreateConductTypesModalProps = {
  title?: string;
  types: ConductTypeCreate[];
  onClose: () => void;
  onSubmit: (form: SchoolCreateConductTypesModalForm) => void;
};

export type SchoolCreateConductTypesModalForm = {
  types: Omit<ConductTypeCreate, 'order'>[];
};

export const SchoolCreateConductTypesModal: FC<SchoolCreateConductTypesModalProps> = ({
  title,
  types: initialTypes,
  onClose,
  onSubmit,
}) => {
  const { $t } = useIntl();
  const form = useForm<SchoolCreateConductTypesModalForm>({
    defaultValues: {
      types: initialTypes.sort((a, b) => a.connotation - b.connotation),
    },
  });
  const [editingTypeIndex, setEditingTypeIndex] = useState<number | null>(
    initialTypes.length ? null : -1,
  );

  const {
    fields: types,
    append,
    insert,
    remove,
    move,
    update,
  } = useFieldArray({
    control: form.control,
    name: 'types',
  });

  useEffect(() => {
    onSubmit({ types });
  }, [types, onSubmit]);

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const { firstNegativeIndex, positiveTypes, negativeTypes } = useMemo(() => {
    const firstNegativeIndex = types.findIndex(
      (t) => t.connotation === ConductConnotation.NEGATIVE,
    );

    return {
      firstNegativeIndex,
      positiveTypes: types.slice(0, firstNegativeIndex !== -1 ? firstNegativeIndex : undefined),
      negativeTypes: firstNegativeIndex !== -1 ? types.slice(firstNegativeIndex) : [],
    };
  }, [types]);

  const editTypeWithActions = useMemo(() => {
    if (editingTypeIndex === null) return;

    const onClose = () => setEditingTypeIndex(null);
    const firstNegativeIndexOrZero = firstNegativeIndex !== -1 ? firstNegativeIndex : 0;

    if (editingTypeIndex === -1) {
      return {
        onSubmit: (v: ConductTypeForm) => {
          v.connotation === ConductConnotation.POSITIVE && firstNegativeIndex !== -1
            ? insert(firstNegativeIndex, v)
            : append(v);
          onClose();
        },
        onClose,
        isNewItem: true,
      };
    }

    const relatedCode = types[editingTypeIndex];

    if (!relatedCode) return;

    return {
      type: relatedCode,
      isNewItem: false,
      onSubmit: (v: ConductTypeForm) => {
        update(editingTypeIndex, v);
        if (relatedCode.connotation !== v.connotation) {
          move(
            editingTypeIndex,
            v.connotation === ConductConnotation.POSITIVE
              ? firstNegativeIndexOrZero
              : types.length - 1,
          );
        }
        onClose();
      },
      onDelete: () => {
        remove(editingTypeIndex);
        onClose();
      },
      onClose,
    };
  }, [editingTypeIndex, types, insert, firstNegativeIndex, append, update, move, remove]);

  useEffect(() => {
    if (!!editTypeWithActions || types.length) return;
    onClose();
  }, [editTypeWithActions, onClose, types.length]);

  return (
    <>
      <ModalLarge open={!editTypeWithActions} onClose={onClose}>
        <ModalHeader active title={title}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Typography variant="h2">
                {$t({ id: 'school-section-ConductSettings-ConductTypes' })}
              </Typography>
              <Counter sx={{ minWidth: 20, textAlign: 'center', fontWeight: 600 }}>
                {types.length}
              </Counter>
            </Stack>
            <SimpleButton startIcon={<PlusIcon />} onClick={() => setEditingTypeIndex(-1)}>
              {$t({ id: 'school-section-ConductSettings-AddConductType' })}
            </SimpleButton>
          </Stack>
          <DragDropContext onDragEnd={handleDragEnd}>
            {!!positiveTypes.length && (
              <Droppable
                droppableId={ConductTypesDroppable.POSITIVE}
                type={ConductTypesDroppable.POSITIVE}
              >
                {(provided) => (
                  <Stack mt={3} {...provided.droppableProps} ref={provided.innerRef}>
                    {positiveTypes?.map((code, i) => (
                      <DraggableConductTypeRow
                        key={code.id}
                        type={code}
                        onEdit={() => setEditingTypeIndex(i)}
                        index={i}
                        draggable={positiveTypes.length > 1}
                      />
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            )}
            <Droppable
              droppableId={ConductTypesDroppable.NEGATIVE}
              type={ConductTypesDroppable.NEGATIVE}
            >
              {(provided) => (
                <Stack mt={3} {...provided.droppableProps} ref={provided.innerRef}>
                  {negativeTypes?.map((code, i) => (
                    <DraggableConductTypeRow
                      key={code.id}
                      type={code}
                      onEdit={() => setEditingTypeIndex(i + positiveTypes.length)}
                      index={i + positiveTypes.length}
                      draggable={negativeTypes.length > 1}
                    />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </ModalContent>
      </ModalLarge>
      {!!editTypeWithActions && (
        <SchoolConductTypeModalContent
          {...editTypeWithActions}
          isSaving={false}
          isDeleting={false}
        />
      )}
    </>
  );
};

type DraggableConductTypeRowProps = {
  index: number;
  draggable?: boolean;
  type: FieldArrayWithId<SchoolCreateConductTypesModalForm, 'types', 'id'>;
  onEdit: () => void;
};

const ConductTypeMessageId: { [K in ConductTypeRepresentation]: string } = {
  [ConductTypeRepresentation.TEXT]: 'conduct-type-TEXT',
  [ConductTypeRepresentation.NUMBER]: 'conduct-type-NUMBER',
};

const DraggableConductTypeRow: FC<DraggableConductTypeRowProps> = ({
  type,
  index,
  onEdit,
  draggable,
}) => {
  const { $t } = useIntl();

  return (
    <Draggable draggableId={type.id} index={index}>
      {(provided) => (
        <GridRowStyled
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
          sx={{
            '& :hover': {
              '.editRoleIcon': {
                visibility: 'visible',
              },
            },
          }}
        >
          <GridRowItem gap={2}>
            {draggable && (
              <IconButton {...provided.dragHandleProps} inverse>
                <DragIcon />
              </IconButton>
            )}

            <ConductConnotationIndicator connotation={type.connotation} />

            <GridRowCell width={300}>
              <TypographyWithOverflowHint fontSize={15}>{type.name}</TypographyWithOverflowHint>
            </GridRowCell>

            <GridRowCell flex={1}>
              <Typography>{$t({ id: ConductTypeMessageId[type.type] })}</Typography>
            </GridRowCell>
            <IconButton
              className="editRoleIcon"
              sx={{
                visibility: 'hidden',
              }}
              inverse
              onClick={onEdit}
            >
              <EditIcon />
            </IconButton>
          </GridRowItem>
        </GridRowStyled>
      )}
    </Draggable>
  );
};
