import { AnnualPlanRecordTypes, SchoolYearPeriodGroup } from '@schooly/api';
import { FC, useMemo } from 'react';

import {
  SchoolPeriodsUpdateModal,
  SchoolPeriodsUpdateModalProps,
} from '../../../../School/SchoolPeriods/SchoolPeriodsUpdate/SchoolPeriodsUpdateModal';
import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import { addSchoolPeriodGroupOption } from '../../scheme';
import { useAnnualPlannerCreateRecord } from '../WithAnnualPlannerCreate';

export const AnnualPlannerExpandedSchoolPeriod: FC = () => {
  const { schoolYear } = useAnnualPlanner();
  const { record, expandedData, close } = useAnnualPlannerCreateRecord();

  const editedSchoolYear = useMemo<SchoolPeriodsUpdateModalProps['schoolYear']>(() => {
    if (!schoolYear || !expandedData) {
      return;
    }

    if (record && record.type === AnnualPlanRecordTypes.SCHOOL_PERIOD) {
      return {
        ...schoolYear,
        period_groups: schoolYear.period_groups?.map((group) =>
          group.id === expandedData.schoolPeriod.groupId
            ? {
                ...group,
                name: expandedData.schoolPeriod.groupName ?? '',
                periods: group.periods.map((period, index) =>
                  period.id === record.details.id
                    ? {
                        ...period,
                        name: `${expandedData.schoolPeriod.groupName} ${index + 1}`,
                        date_from: expandedData.date[0],
                        date_to: expandedData.date[1],
                        isStarted: false,
                      }
                    : {
                        ...period,
                        name: `${expandedData.schoolPeriod.groupName} ${index + 1}`,
                        isStarted: false,
                      },
                ),
              }
            : group,
        ),
      };
    } else {
      const getGroup = (group?: Partial<SchoolYearPeriodGroup>) =>
        ({
          id: expandedData.schoolPeriod.groupId,
          name: expandedData.schoolPeriod.groupName ?? '',
          should_publish_events: false,
          periods: [
            ...(group?.periods ?? []),
            {
              name: `${expandedData.schoolPeriod.groupName} ${(group?.periods?.length ?? 0) + 1}`,
              date_from: expandedData.date[0],
              date_to: expandedData.date[1],
              isStarted: false,
            },
          ],
        } as SchoolYearPeriodGroup);

      return {
        ...schoolYear,
        period_groups:
          expandedData.schoolPeriod.groupId === addSchoolPeriodGroupOption.value
            ? [...(schoolYear.period_groups ?? []), getGroup()]
            : (schoolYear.period_groups ?? []).map((group) =>
                group.id === expandedData.schoolPeriod.groupId ? getGroup(group) : group,
              ),
      };
    }
  }, [expandedData, record, schoolYear]);

  if (!editedSchoolYear) {
    return null;
  }

  return (
    <SchoolPeriodsUpdateModal
      schoolYear={editedSchoolYear}
      autoFocusGroup={expandedData?.schoolPeriod.groupId}
      onClose={close}
    />
  );
};
