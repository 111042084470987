import { IconButton, Stack } from '@mui/material';
import { SearchInput, SearchInputProps } from '@schooly/components/filters';
import { FilterIcon } from '@schooly/style';
import { FC } from 'react';

type GroupPreviewModalConductSearchInputProps = {
  onFiltersPress: () => void;
  hasFilters: boolean;
} & SearchInputProps;

export const GroupPreviewModalConductSearchInput: FC<GroupPreviewModalConductSearchInputProps> = ({
  onFiltersPress,
  hasFilters,
  ...rest
}) => {
  return (
    <Stack justifyContent="center" position="relative">
      <SearchInput {...rest} canClear={false} />
      <IconButton
        onClick={onFiltersPress}
        sx={(theme) => ({
          zIndex: 10,
          cursor: 'pointer',
          position: 'absolute',
          color: hasFilters ? undefined : 'common.grey',
          right: theme.spacing(1.5),
          '&:hover': {
            color: 'unset',
          },

          '&:after': hasFilters
            ? {
                content: "''",
                display: 'block',
                width: '0.4rem',
                height: '0.4rem',
                borderRadius: '50%',
                background: theme.palette.success.main,
                border: '1px solid white',
                position: 'absolute',
                right: '0',
                top: -2,
              }
            : undefined,
        })}
      >
        <FilterIcon />
      </IconButton>
    </Stack>
  );
};
