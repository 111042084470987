import { Box, Stack } from '@mui/material';
import { FC } from 'react';

export const SchoolTuneStatusesModalArrow: FC = () => {
  return (
    <Stack direction="row" alignItems="center" height="100%">
      <Box
        sx={(theme) => ({
          position: 'relative',
          flex: '1 1 100%',
          height: 1.5,
          backgroundColor: theme.palette.text.secondary,
          borderRadius: 2,

          '&:before': {
            position: 'absolute',
            right: 0,
            top: 0.5,
            display: 'block',
            height: 1.5,
            width: 7,
            transform: 'rotate(45deg)',
            transformOrigin: 'center right',
            backgroundColor: theme.palette.text.secondary,
            borderRadius: 2,
            content: '""',
          },

          '&:after': {
            position: 'absolute',
            right: 0,
            bottom: 0.5,
            display: 'block',
            height: 1.5,
            width: 7,
            transform: 'rotate(-45deg)',
            transformOrigin: 'center right',
            backgroundColor: theme.palette.text.secondary,
            borderRadius: 2,
            content: '""',
          },
        })}
      />
    </Stack>
  );
};
