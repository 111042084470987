import { FilterValue, GroupSubject, Report, SelectOption } from '@schooly/api';
import { ConductType } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { SchoolRelation } from '@schooly/api';

import { getUserFullName } from '../../../helpers/users';

export enum FILTER_MODE {
  FILTER = 'FILTER',
  SEARCH = 'SEARCH',
  FAVORITE = 'FAVORITE',
}

export const convertGroupSubjectsToSelectOption = (
  groupSubjects?: GroupSubject[],
): SelectOption<FilterValue>[] => {
  if (!groupSubjects) return [];

  return groupSubjects.map(({ id, name, archived }) => ({
    value: id,
    label: name,
    archived,
  }));
};

export const convertSimpleListResultsToSelectOption = (
  results: SchoolRelation[],
): SelectOption<FilterValue>[] => {
  if (!results) return [];

  return results.map((res) => ({
    value: res.relation_id,
    label: getUserFullName(res),
    item: res,
  }));
};

export const convertStaffSimpleListResultsToSelectOption = (
  results: SimpleListResult[],
  currentStaff?: SchoolRelation,
): SelectOption<FilterValue>[] => {
  const defaultOption = currentStaff
    ? ({
        value: currentStaff?.relation_id,
        item: currentStaff,
        label: getUserFullName(currentStaff),
        labelTextId: 'filter-Me',
      } as SelectOption<FilterValue, SimpleListResult>)
    : null;

  if (!results && defaultOption) return [defaultOption];
  if (!results && !defaultOption) return [];

  const options = convertSimpleListResultsToSelectOption(results).filter(
    (r) => r.item.relation_id !== currentStaff?.relation_id,
  );

  return currentStaff?.relation_id && defaultOption ? [defaultOption, ...options] : options;
};

export const convertReportsToSelectOption = (
  reports?: Report[],
): SelectOption<FilterValue, Report>[] => {
  if (!reports || !reports.length) return [] as SelectOption<FilterValue, Report>[];

  return reports.map((r) => ({
    value: r.id,
    label: r.name,
    item: r,
  }));
};

export const convertConductTypeToSelectOption = (
  types?: ConductType[],
): SelectOption<FilterValue, ConductType>[] => {
  if (!types) return [];

  return types.map((item) => ({ value: item.id, label: item.name, item }));
};

export const getDefaultStaffFilter = (currentStaff?: SchoolRelation): FilterValue[] =>
  [currentStaff?.relation_id] as FilterValue[];
