import {
  AgeGroup,
  FilterSelectOption,
  GroupSubject,
  SchoolLevel,
  SchoolProperty,
} from '@schooly/api';
import { GENDER_OPTIONS, NATIONALITY_OPTIONS, SchoolPropertyType } from '@schooly/constants';

import { getOptionsForPropertyType } from '../../../../helpers/school';
import { LimitedToCategory } from './LimitedToSelect';

interface GetLimitedToOptionsProps {
  categories: LimitedToCategory[];
  schoolProperties: SchoolProperty[];
  subjects: GroupSubject[];
  ageGroups: AgeGroup[];
  schoolLevels: SchoolLevel[];
}

export type LimitedToOption = FilterSelectOption & {
  isPropGroup?: boolean;
  propGroupId?: string | null;
};

export function getLimitedToCategoryKey(category: LimitedToCategory) {
  let key = '';
  if (
    category === 'statuses' ||
    category === 'age_groups' ||
    category === 'school_levels' ||
    category === 'departments' ||
    category === 'campuses' ||
    category === 'houses'
  ) {
    key = 'school_property_ids';
  } else if (category === 'subjects') {
    key = 'subject_ids';
  } else key = category;

  return key;
}

export function getLimitedToRelatedCategory(category: LimitedToCategory): LimitedToCategory {
  switch (category) {
    case 'school_levels':
      return 'age_groups';
    case 'age_groups':
      return 'school_levels';
    default:
      return category;
  }
}

export function getLimitedToOptions({
  categories,
  schoolProperties,
  subjects,
  ageGroups,
  schoolLevels,
}: GetLimitedToOptionsProps) {
  const options: Partial<Record<LimitedToCategory, LimitedToOption[]>> = {};
  categories.forEach((key) => {
    switch (key) {
      case 'statuses':
        options[key] = getOptionsForPropertyType(schoolProperties, SchoolPropertyType.Status);
        break;
      case 'campuses':
        options[key] = getOptionsForPropertyType(schoolProperties, SchoolPropertyType.Campus);
        break;
      case 'houses':
        options[key] = getOptionsForPropertyType(schoolProperties, SchoolPropertyType.House);
        break;
      case 'departments':
        options[key] = getOptionsForPropertyType(schoolProperties, SchoolPropertyType.Department);
        break;
      case 'genders':
        options[key] = GENDER_OPTIONS;
        break;
      case 'nationalities':
        options[key] = NATIONALITY_OPTIONS;
        break;
      case 'subjects':
        options[key] =
          subjects?.map(({ id, name, archived }) => ({
            value: id,
            label: name,
            archived,
          })) ?? [];
        break;
      case 'age_groups':
        options[key] =
          ageGroups?.map(({ id, name, archived, level_id }) => ({
            value: id,
            label: name,
            archived,
            propGroupId: level_id,
            type: SchoolPropertyType.AgeGroup,
          })) ?? [];
        break;
      case 'school_levels':
        options[key] =
          schoolLevels?.map(({ id, name }) => ({
            value: id,
            label: name,
            isPropGroup: true,
          })) ?? [];
        break;
      default:
        break;
    }
  });

  return options;
}

export const getLimitedToGroupWithOptionsMap = (
  category: LimitedToCategory,
  options: Partial<Record<LimitedToCategory, LimitedToOption[]>>,
) => {
  const groupCategory = getLimitedToRelatedCategory(category);
  const groups = options[groupCategory] ?? [];

  return groups.reduce((map: Record<string, { id: string }[]>, g) => {
    map[g.value] = (options[category] ?? [])
      .filter((o) => o.propGroupId === g.value)
      .map((i) => ({ id: i.value.toString() }));
    return map;
  }, {});
};
