import { Stack } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

export const ChildInfoFormContainer: FC = () => {
  const { control } = useFormContext();
  const { $t } = useIntl();
  return (
    <Stack gap={2}>
      <ControlTextField
        name="given_name"
        control={control}
        rules={{ required: true }}
        label={$t({ id: 'peopleDetail-GivenNames' })}
        canClear
        fullWidth
      />
      <ControlTextField
        name="last_name"
        control={control}
        rules={{ required: true }}
        label={$t({ id: 'peopleDetail-FamilyName' })}
        canClear
        fullWidth
      />
      <ControlTextField
        name="known_as"
        control={control}
        label={$t({ id: 'peopleDetail-PreferredName' })}
        canClear
        fullWidth
      />
    </Stack>
  );
};
