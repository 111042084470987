import { Stack, styled } from '@mui/material';

export const EditFamilyCardWrapper = styled(Stack)(({ theme }) => ({
  '&:hover': {
    '.primaryContact': {
      visibility: 'visible',
    },
    '.FormTagSelect': {
      backgroundColor: theme.palette.background.paper,
    },
  },

  '&:not(:last-child)': {
    borderBottom: theme.mixins.borderValue(),
  },

  '.PersonCard': {
    width: '100%',
    '&:hover': {
      '.FormTagSelect .MuiTypography-root, .PersonCard__info__username .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  '.PersonCard__info': {
    width: '30%',
    overflow: 'hidden',
    mr: 0.5,
  },
}));
