import { IconButton, Stack } from '@mui/material';
import { AttendanceCode } from '@schooly/api';
import {
  ATTENDANCE_CODE_SCHOOl_PRESENCE_OPTIONS,
  AttendanceCodeSchoolPresence,
} from '@schooly/constants';
import { CrossIcon } from '@schooly/style';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { FormCheckbox } from '../../../../components/uikit-components/FormCheckbox/FormCheckbox';
import { FormRadioGroup } from '../../../../components/uikit-components/FormCheckbox/FormRadioGroup';
import { ControlTextField } from '../../../../components/uikit-components/FormTextField/ControlTextField';
import { ControlModalHeaderInput } from '../../../../components/uikit-components/Modal/ControlModalHeaderInput';
import {
  ModalContent,
  ModalMain,
  ModalSmall,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { AttendanceCodeForm } from '../../../../hooks/useAttendanceCodes';

type AddAttendanceCodeModalContentProps = {
  code: Partial<AttendanceCode>;
  isEditing: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  onDelete?: () => void;
  onClose: () => void;
  onSubmit: (v: AttendanceCodeForm) => void;
};

export const AddAttendanceCodeModalContent: FC<AddAttendanceCodeModalContentProps> = ({
  code,
  isDeleting,
  isEditing,
  isLoading,
  onDelete,
  onSubmit,
  onClose,
}) => {
  const { $t } = useIntl();

  const form = useForm<AttendanceCodeForm>({
    defaultValues: {
      ...code,
      in_school: code.in_school
        ? AttendanceCodeSchoolPresence.InSchool
        : AttendanceCodeSchoolPresence.OutOfSchool,
    },
  });

  const presenceStatus = form.watch('in_school');

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader
            active
            title={
              <ControlModalHeaderInput
                control={form.control}
                name="name"
                rules={{ required: true }}
                placeholder={$t({ id: 'attendance-CodeName' })}
                autoFocus
              />
            }
          >
            {(!isLoading || !isDeleting) && (
              <IconButton onClick={onClose}>
                <CrossIcon />
              </IconButton>
            )}
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              <Stack gap={2} sx={{ height: '100%' }}>
                <Stack direction="row" gap={2}>
                  <FormRadioGroup
                    options={ATTENDANCE_CODE_SCHOOl_PRESENCE_OPTIONS}
                    name="in_school"
                    rules={{ required: true }}
                  />

                  {presenceStatus === AttendanceCodeSchoolPresence.OutOfSchool && (
                    <FormCheckbox
                      withBorder={false}
                      name="is_present"
                      labelTextId="attendance-CountAsPresent"
                    />
                  )}
                </Stack>

                <ControlTextField
                  name="code"
                  control={form.control}
                  label={$t({ id: 'attendance-Code' })}
                  fullWidth
                  canClear
                />
                <ControlTextField
                  name="description"
                  control={form.control}
                  label={$t({ id: 'attendance-CodeDescription' })}
                  fullWidth
                  multiline
                  rows={2}
                  canClear
                />
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooterWithActions
            isNewItem={!isEditing}
            saving={isLoading}
            showDeleteButton={isEditing}
            deleting={isDeleting}
            disabled={isDeleting || isLoading}
            onDeleteClick={onDelete}
            active
          />
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
