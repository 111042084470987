import { Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { SchoolProperty } from '@schooly/api';
import { ArchiveIcon } from '@schooly/style';
import { FC, PropsWithChildren } from 'react';

export type RenderSchoolPropertyProps = {
  additionalLabel?: string;
  property?: Pick<SchoolProperty, 'archived' | 'name'>;
  defaultValue?: React.ReactNode;
  tooltipTitle: string;
} & Omit<TypographyProps, 'children' | 'property'>;

export const RenderSchoolProperty: FC<PropsWithChildren<RenderSchoolPropertyProps>> = ({
  property,
  tooltipTitle,
  defaultValue,
  children,
  additionalLabel,
  ...props
}) => {
  const label = `${property?.name ?? defaultValue}${additionalLabel || ''}`;

  return (
    <Stack direction="row" gap={0.75} alignItems="center">
      {property?.archived ? (
        <Tooltip title={tooltipTitle}>
          <Typography color="text.primary">
            <ArchiveIcon /> {label}
          </Typography>
        </Tooltip>
      ) : (
        <Typography component="span" color="text.primary" {...props}>
          {label}
        </Typography>
      )}
      {children}
    </Stack>
  );
};
