import { Box, IconButton, Tooltip } from '@mui/material';
import { UserType } from '@schooly/api';
import { InformationIcon } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import PersonCardBasic, { PersonCardBasicProps } from '../PersonCard/PersonCardBasic';

interface InviteCardProps {
  user: PersonCardBasicProps['user'] & { id?: string };
  userType: UserType;
}

export const InviteCard: FC<InviteCardProps> = ({ user, userType }) => {
  const { $t } = useIntl();
  return (
    <Box
      sx={(theme) => ({
        '.PersonCard .card-body': {
          padding: theme.spacing(1, 2, 0.75, 2),
        },
        '.PersonCard__info': {
          pl: 1,
        },
        '.Card': {
          mb: 0,
        },
      })}
    >
      <PersonCardBasic user={user} userType={userType} showDateOfBirth>
        <Tooltip
          title={$t({ id: 'inviteModal-ChildDetails' })}
          componentsProps={{
            tooltip: {
              sx: () => ({
                width: 200,
                padding: 1.25,
              }),
            },
          }}
        >
          <IconButton inverse>
            <InformationIcon />
          </IconButton>
        </Tooltip>
      </PersonCardBasic>
    </Box>
  );
};
