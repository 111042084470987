import { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      maxWidth: 200,
      backgroundColor: 'white',
      padding: theme.spacing(1.5, 2),
      textAlign: 'left',
      ...theme.typography.body1,
      color: theme.palette.primary.main,
      boxShadow: '0px 20px 40px rgba(37, 39, 90, 0.1)',
    }),
    arrow: {
      color: 'white',
    },
  },
};
