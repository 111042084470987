import { Stack, Typography } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';
import { SettingsIcon, SimpleButton } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

export type SelectOptionsArchivedEmptyStubProps = {
  isAdmin: boolean;
  type:
    | SchoolPropertyType.Department
    | SchoolPropertyType.House
    | FilterKeys.Subject
    | SchoolPropertyType.Status;
};

export const SelectOptionsArchivedEmptyStub: FC<SelectOptionsArchivedEmptyStubProps> = ({
  isAdmin,
  type,
}) => {
  const { $t } = useIntl();
  const navigate = useNavigate();

  return (
    <Stack gap={1} alignItems="flex-start" py={1} height={76}>
      {isAdmin ? (
        <>
          <Typography color="primary.main">
            <FormattedMessage
              id={`peopleDetail-EmploymentCaseModal-NoActivePropertiesForSchoolAdmin-${type}`}
            />
          </Typography>
          <SimpleButton startIcon={<SettingsIcon />} onClick={() => navigate('/settings')}>
            <Typography>
              {$t({
                id: 'peopleDetail-EmploymentCaseModal-SchoolSettings',
              })}
            </Typography>
          </SimpleButton>
        </>
      ) : (
        <Typography color="primary.main">
          <FormattedMessage id={`peopleDetail-EmploymentCaseModal-NoActiveProperties-${type}`} />
        </Typography>
      )}
    </Stack>
  );
};
