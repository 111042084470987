import { ApiError } from '@schooly/api';
import { createContext, useContext } from 'react';

import { NotificationProps } from './Notification';

export interface NotificationsContextParams {
  notifications: NotificationProps[];
  showNotification: (notification: NotificationProps) => void;
  showError: (error: ApiError) => void;
  dismissNotification: (id?: NotificationProps['id']) => void;
  clearNotifications: VoidFunction;
}

export const NotificationsContext = createContext<NotificationsContextParams>({
  notifications: [],

  showNotification: () => {},
  showError: () => {},
  dismissNotification: () => {},
  clearNotifications: () => {},
});

export const useNotifications = () => useContext(NotificationsContext);
