import { CrossIcon, SearchIcon, Spin } from '@schooly/style';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import buildClassName from '../../../utils/buildClassName';

import './index.scss';

export interface IProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  placeholderTextId?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isValueRemovable?: boolean;
  onEnter?: () => void;
  onResetValue?: () => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  className?: string;
  testId?: string;
}

const SearchInput: React.FC<IProps> = ({
  placeholder,
  placeholderTextId,
  isDisabled,
  isLoading,
  value,
  onEnter,
  onChange,
  isValueRemovable,
  onResetValue,
  autoFocus,
  onBlur,
  className,
  testId,
}) => {
  const { formatMessage } = useIntl();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && onEnter) {
        onEnter();
      }
    },
    [onEnter],
  );

  const handleValueRemove = useCallback(() => {
    if (onResetValue) onResetValue();
    onChange('');
  }, [onChange, onResetValue]);

  const fullClassName = buildClassName(
    'SearchInput input-group',
    value && isValueRemovable && 'has-remove-control',
    className,
  );

  return (
    <div className={fullClassName}>
      <div className="input-group-prepend SearchInput__icon">
        <div className="input-group-text">{isLoading ? <Spin /> : <SearchIcon />}</div>
      </div>
      {value && isValueRemovable && (
        <div className="SearchInput__remove" role="button" tabIndex={0} onClick={handleValueRemove}>
          <CrossIcon />
        </div>
      )}
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        onBlur={onBlur}
        onKeyPress={handleKeyPress}
        className="form-control SearchInput__input"
        value={value}
        onChange={handleChange}
        type="text"
        placeholder={placeholder || formatMessage({ id: placeholderTextId })}
        disabled={isDisabled}
        data-test-id={testId}
      />
    </div>
  );
};

SearchInput.defaultProps = {
  placeholderTextId: 'searchPlaceholder',
};

export default SearchInput;
