import { Icon, Skeleton, Stack } from '@mui/material';
import {
  FilterSection,
  GetStudentsQueryFilters,
  STUDENTS_ARRANGE_BY_FILTER_KEYS,
  STUDENTS_QUERY_FILTER_KEYS,
  StudentsArrangeBy,
  useGetFiltersQuery,
  useGetSchoolPropertiesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  useChartOpenFromSearchParams,
  useChartZeroesFromSearchParams,
  WithCharts,
} from '@schooly/components/charts';
import { PageHeader, pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SkeletonRows,
} from '@schooly/style';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import useUserCounts from '../../hooks/useUserCounts';
import StudentsContent, { PAGE_SIZE, SKELETON_COLS } from './StudentsContent';
import { StudentsEmptyStub } from './StudentsEmptyStub';

const StudentsPage: React.FC = () => {
  const location = useLocation();
  const { userCounts } = useUserCounts();
  const { schoolId = '', relationId = '' } = useAuth();

  //we have to prevent rendering content in case we are currently on RolloverResult
  const isOnRolloverResult = location.pathname === '/students/rollover/result';

  const { data } = useGetSchoolPropertiesQuery(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { refetchOnMount: 'always' },
  );

  const { ageGroups } = useAgeGroups(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { refetchOnMount: 'always' },
  );

  const { data: filters, isLoading } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Student },
    { enabled: !!schoolId && !!relationId },
  );

  const isChartsOpen = useChartOpenFromSearchParams();
  const showZeroValues = useChartZeroesFromSearchParams();

  const initialFiltersAndArrangeBy = useMemo(() => {
    const initialFilters: GetStudentsQueryFilters = {};
    let initialArrangeBy: StudentsArrangeBy | null = null;

    if (isLoading) return;

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      for (const key of STUDENTS_ARRANGE_BY_FILTER_KEYS) {
        if (key === defaultFilter.filter.arrange_by) initialArrangeBy = key;
      }

      return {
        initialFilters: pickOnlyParamsFromFilterKeys(
          STUDENTS_QUERY_FILTER_KEYS,
          defaultFilter.filter,
        ),
        initialArrangeBy,
      };
    }

    if (!data) return;
    for (const property of data.school_properties) {
      if (!property.student_default) continue;

      const type = property.type;

      switch (type) {
        case SchoolPropertyType.Status:
        case SchoolPropertyType.House:
          initialFilters[type] = [...(initialFilters[type] || []), property.id];
          break;
      }
    }

    for (const ageGroup of ageGroups) {
      if (!ageGroup.student_default) continue;

      initialFilters[SchoolPropertyType.AgeGroup] = [
        ...(initialFilters[SchoolPropertyType.AgeGroup] || []),
        ageGroup.id,
      ];
    }

    return { initialFilters, initialArrangeBy };
  }, [ageGroups, data, filters, isLoading]);

  const renderContent = () => {
    if (!initialFiltersAndArrangeBy || !schoolId || isOnRolloverResult) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <PageHeader pageTitleTextId="section-Students">
              <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
            </PageHeader>
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_COLS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    if (!userCounts?.student) {
      return <StudentsEmptyStub />;
    }

    return (
      <WithCharts schoolId={schoolId} isOpen={isChartsOpen} showZeroValues={showZeroValues}>
        <StudentsContent {...initialFiltersAndArrangeBy} />
      </WithCharts>
    );
  };
  return <MainLayout>{renderContent()}</MainLayout>;
};

export default StudentsPage;
