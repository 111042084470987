import { Box, ButtonProps } from '@mui/material';
import { FC } from 'react';

import { SimpleButtonStyled, SimpleButtonStyledProps } from './SimpleButton.styled';

export type SimpleButtonProps = Omit<
  ButtonProps & { target?: string } & SimpleButtonStyledProps,
  'variant'
>;

export const SimpleButton: FC<SimpleButtonProps> = ({ children, ...props }) => {
  return <SimpleButtonStyled {...props}>{children && <Box>{children}</Box>}</SimpleButtonStyled>;
};
