import { ReportStatuses } from '@schooly/api';
import { Loading, ModalSmall } from '@schooly/style';
import { FC } from 'react';

import AccessDenied from '../../../components/common/AccessDenied';
import { useReport } from '../../../context/report/WithReport';
import {
  ReportForm,
  WithReportDraftContext,
  WithReportNotPublishedContext,
  WithReportNotPublishedContextProps,
} from '../../../context/report/WithReportEditContext';
import { useRouter } from '../../../context/router/useRouter';
import { ReportCreateModalDraftContent } from './ReportCreateModalDraftContent';
import { ReportCreateModalNotPublishedContent } from './ReportCreateModalNotPublishedContent';

export interface ReportCreateModalProps
  extends Pick<WithReportNotPublishedContextProps, 'onClose' | 'onCloseAndClean'> {
  formValuesOverrides?: Partial<ReportForm>;
}

export const ReportCreateModal: FC<ReportCreateModalProps> = ({
  onClose,
  onCloseAndClean,
  formValuesOverrides,
}) => {
  const { goBack } = useRouter();
  const { id } = useReport();

  const close = onClose ?? goBack;

  const { report, canCreate, canEdit, isFetching } = useReport();

  if (isFetching) {
    return (
      <ModalSmall open onClose={close}>
        <Loading />
      </ModalSmall>
    );
  }

  if ((!!id && !canEdit) || !canCreate) {
    return (
      <ModalSmall open onClose={close}>
        <AccessDenied />
      </ModalSmall>
    );
  }

  if (report && report.report_status === ReportStatuses.Unpublished)
    return (
      <WithReportNotPublishedContext
        onClose={close}
        onCloseAndClean={onCloseAndClean}
        formValuesOverrides={formValuesOverrides}
      >
        <ReportCreateModalNotPublishedContent />
      </WithReportNotPublishedContext>
    );

  return (
    <WithReportDraftContext onClose={close} formValuesOverrides={formValuesOverrides}>
      <ReportCreateModalDraftContent />
    </WithReportDraftContext>
  );
};
