import { Box, Stack, SxProps, Typography } from '@mui/material';
import { DATE_FORMAT } from '@schooly/constants';
import { TypographyWithOverflowHint } from '@schooly/style';
import { format } from 'date-fns';
import { FC, ReactNode } from 'react';

import { ActiveRegistrations } from './StudentRegistrationForm';

interface RegistrationConflictRowProps {
  registration: ActiveRegistrations;
  endIcon?: ReactNode;
  sxProps?: Omit<SxProps, 'children'>;
  isYearInvalid: boolean;
  isHouseInvalid: boolean;
  isAgeGroupInvalid: boolean;
  isStatusInvalid: boolean;
}

export const RegistrationConflictRow: FC<RegistrationConflictRowProps> = ({
  registration,
  endIcon,
  isYearInvalid,
  isHouseInvalid,
  isAgeGroupInvalid,
  isStatusInvalid,
  sxProps,
}) => {
  const dateFrom = format(new Date(registration.applies_from), DATE_FORMAT);

  const yearColor = isYearInvalid ? 'error.main' : 'text.primary';
  const houseColor = isHouseInvalid ? 'error.main' : 'text.primary';
  const ageGroupColor = isAgeGroupInvalid ? 'error.main' : 'text.primary';

  return (
    <Box
      sx={(theme) => ({
        borderRadius: '8px',
        border: `1px solid transparent`,
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          borderColor: 'error.main',
          '.status': {
            color: 'primary.main',
          },
          '.date': {
            color: 'text.primary',
          },
        },
        ...(sxProps ?? {}),
      })}
    >
      <Stack
        direction="row"
        gap={1.75}
        alignItems="center"
        justifyContent="space-between"
        mb={0.25}
        sx={{
          py: 1.25,
          px: 1,
        }}
      >
        <Stack direction="row" alignItems="center" gap={2} width="100%">
          <Typography variant="h3" color={yearColor}>
            {registration.year.name}
          </Typography>
          <Typography
            variant="h3"
            color={ageGroupColor}
            sx={{
              flex: '0 0 19%',
            }}
          >
            {registration.ageGroup?.name}
          </Typography>
          <Typography
            variant="h3"
            color={houseColor}
            sx={{
              flex: '0 0 19%',
            }}
          >
            {registration.house?.name}
          </Typography>

          <Stack direction="row" gap={1} alignItems="center" overflow="hidden">
            <TypographyWithOverflowHint
              noWrap
              variant="h3"
              {...(!isStatusInvalid && {
                className: 'status',
              })}
              color={isStatusInvalid ? 'error.main' : 'text.primary'}
            >
              {registration.school_property.name}
            </TypographyWithOverflowHint>
            <TypographyWithOverflowHint
              variant="h3"
              noWrap
              color={isStatusInvalid ? 'error.main' : 'text.secondary'}
              {...(!isStatusInvalid && {
                className: 'date',
              })}
            >
              {registration.applies_to
                ? `${dateFrom} - ${format(new Date(registration.applies_to), DATE_FORMAT)}`
                : dateFrom}
            </TypographyWithOverflowHint>
          </Stack>
        </Stack>

        {endIcon}
      </Stack>
    </Box>
  );
};
