import { Button, IconButton, Typography, useTheme } from '@mui/material';
import { Currencies } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { FC } from 'react';

import { ArrowDownV2Icon } from '../../assets/assets';
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu/DropdownMenu';

type DropdownCurrenciesProps = {
  actions: DropdownMenuItem[];
  selectedCurrency: Currencies;
};

export const DropdownCurrencies: FC<DropdownCurrenciesProps> = ({ selectedCurrency, actions }) => {
  const theme = useTheme();
  const [opened, open, close] = useFlag();

  return (
    <DropdownMenu
      actions={actions}
      closeOnSelect
      noArrow
      isSelected={(item) => item.title === selectedCurrency}
      menuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        open: opened,
        onClose: close,
      }}
      menuStyles={{
        marginTop: theme.spacing(0.25),
        '& .MuiPaper-root': {
          border: `1px solid ${theme.palette.common.light3}`,
          '& .MuiList-root': {
            width: 140,
          },
          '& .MuiMenuItem-root': {
            borderRadius: theme.spacing(0.5),
            '&:not(:last-of-type)': {
              marginBottom: theme.spacing(0.5),
            },
          },
        },
      }}
      icon={
        <Button
          size="small"
          onClick={open}
          variant={opened ? 'contained' : 'outlined'}
          sx={(theme) => ({
            height: 30,
            '&.MuiButton-root': {
              paddingX: theme.spacing(1),
            },
            '& .MuiTypography-root': {
              color: opened ? theme.palette.background.paper : undefined,
            },
          })}
          endIcon={
            <IconButton
              sx={(theme) => ({
                width: theme.spacing(1),
                transform: opened ? 'rotate(180deg)' : undefined,
                path: {
                  stroke: opened ? theme.palette.background.paper : theme.palette.common.grey,
                },
              })}
            >
              <ArrowDownV2Icon />
            </IconButton>
          }
        >
          <Typography variant="h3">{selectedCurrency}</Typography>
        </Button>
      }
    />
  );
};
