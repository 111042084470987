import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 4C6.75 3.58579 6.41421 3.25 6 3.25C5.58579 3.25 5.25 3.58579 5.25 4L5.25 11.5C5.25 11.9142 5.58579 12.25 6 12.25L13.6894 12.25L10.9697 14.9697C10.6768 15.2626 10.6768 15.7374 10.9697 16.0303C11.2626 16.3232 11.7375 16.3232 12.0304 16.0303L16.0304 12.0303C16.3232 11.7374 16.3232 11.2626 16.0304 10.9697L12.0304 6.96967C11.7375 6.67678 11.2626 6.67678 10.9697 6.96967C10.6768 7.26256 10.6768 7.73744 10.9697 8.03033L13.6894 10.75L6.75 10.75L6.75 4Z"
      fill="#5D6688"
    />
  </svg>
);
