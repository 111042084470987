import { Box, Icon, Stack, styled, TypographyProps } from '@mui/material';
import {
  AgeGroup,
  EXPIRED_RE_ENROLLMENT,
  PENDING_PARENT_RESPONSE,
  SchoolProperty,
  SchoolYear,
  StudentInconsistency,
  StudentInconsistencyType,
} from '@schooly/api';
import { DATE_FORMAT } from '@schooly/constants';
import {
  GridRowItem,
  GridRowName,
  GridRowStyled,
  NewTabIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
// eslint-disable-next-line @nx/enforce-module-boundaries
import PersonCardBasic from 'apps/web/src/components/common/PersonCard/PersonCardBasic';
import { format } from 'date-fns';
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { InviteNotAcceptedWarning } from './InviteNotAcceptedWarning';
import { SameAgeGroupWarning } from './SameAgeGroupWarning';

type RolloverInconsistenciesRowProps = {
  data: StudentInconsistency;
  year?: SchoolYear;
  houses: SchoolProperty[];
  ageGroups: AgeGroup[];
  onRowClick: (id: string) => void;
  onShowFamily: (id: string) => void;
  inconsistencyType: StudentInconsistencyType;
};

export const RolloverInconsistenciesRow: FC<RolloverInconsistenciesRowProps> = ({
  data,
  year,
  houses,
  ageGroups,
  onRowClick,
  inconsistencyType,
  onShowFamily,
}) => {
  const { ageGroupName, houseName } = useMemo(
    () => ({
      houseName: houses.find((h) => h.id === data.house_id)?.name ?? undefined,
      ageGroupName: ageGroups.find((h) => h.id === data.age_group_id)?.name ?? undefined,
    }),
    [ageGroups, data.age_group_id, data.house_id, houses],
  );

  const { $t } = useIntl();

  const isPendingParentResponse = data.status.name === PENDING_PARENT_RESPONSE;
  const isExpiredReEnrollment = data.status.name === EXPIRED_RE_ENROLLMENT;
  const pendingParentResponseLabel = isPendingParentResponse
    ? $t({ id: 'status-rollover-PendingParentResponse' })
    : null;

  const expiredReEnrollmentLabel = isExpiredReEnrollment
    ? $t({ id: 'status-rollover-ExpiredReEnrollment' })
    : null;

  return (
    <RolloverListItem noBorderRadius onClick={() => onRowClick(data.student_id)}>
      <GridRowItem
        sx={{
          py: 0,
          px: 0,
        }}
      >
        <GridRowName
          sx={{
            flex: '0 0 28%',
            minWidth: 0,
            '& .Card--list-item': {
              pl: 0.5,
            },
          }}
        >
          <PersonCardBasic user={data} userType="student" isListItem />
        </GridRowName>
        <GridRowName
          sx={{
            flex: '0 0 20%',
          }}
        >
          <Stack direction="row" gap={1}>
            <RowItem label={year?.name} />
            <RowItem label={ageGroupName} />
          </Stack>
        </GridRowName>
        <GridRowName
          sx={{
            flex: '0 0 13%',
          }}
        >
          <RowItem label={houseName} />
        </GridRowName>
        <GridRowName>
          <Stack direction="row" gap={0.5} alignItems="center" justifyContent="space-between">
            <RowItem
              width={220}
              label={pendingParentResponseLabel || expiredReEnrollmentLabel || data.status.name}
              color={(theme) =>
                isPendingParentResponse ? `${theme.palette.common.orange} !important` : undefined
              }
              display="flex"
            >
              {isPendingParentResponse && !data.parents_invite_accepted && (
                <Box pl={0.5}>
                  <InviteNotAcceptedWarning onShowFamily={() => onShowFamily(data.student_id)} />
                </Box>
              )}
            </RowItem>

            {data.status.applies_from && (
              <RowItem
                className="statusDate"
                label={format(newDateTimezoneOffset(data.status.applies_from), DATE_FORMAT)}
              />
            )}
            {inconsistencyType === 'same_age_group' && data.status.same_age_group && (
              <Stack direction="row">
                <SameAgeGroupWarning
                  tooltipLabel={$t({ id: 'students-AnnualRollover-SameAgeGroup' })}
                />
              </Stack>
            )}

            <Icon
              className="newTab"
              fontSize="small"
              sx={{
                visibility: 'hidden',
                mr: 1,
              }}
            >
              <NewTabIcon />
            </Icon>
          </Stack>
        </GridRowName>
      </GridRowItem>
    </RolloverListItem>
  );
};

type RowItemProps = { label?: ReactNode } & TypographyProps;

const RowItem: FC<PropsWithChildren<RowItemProps>> = ({ label, children, ...props }) => (
  <TypographyWithOverflowHint variant="h3" {...(!label && { className: 'emptyCell' })} {...props}>
    {label ?? '-'}
    {children}
  </TypographyWithOverflowHint>
);

const RolloverListItem = styled(GridRowStyled)(({ theme }) => ({
  borderRadius: 'none',
  cursor: 'pointer',
  '&& .statusDate, && .emptyCell': {
    color: theme.palette.common.grey,
  },
  '.card-body': {
    gap: 2,
  },
  ':hover': {
    '.newTab': {
      visibility: 'visible',
    },
    '&& .statusDate': {
      color: theme.palette.common.grey2,
    },
  },
}));
