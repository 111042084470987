import { IconButton } from '@mui/material';
import { CrossIcon, ModalHeader, ModalSmall } from '@schooly/style';
import { FC, PropsWithChildren } from 'react';

import { ModalAccessDenied } from '../../components/uikit-components/Modal/ModalAccessDenied';
import { useApplication } from '../../context/applications/useApplication';
import { useRouter } from '../../context/router/useRouter';

interface ApplicationAccessLayerProps extends PropsWithChildren {
  checkEditPermissions?: boolean;
}

export const ApplicationAccessLayer: FC<ApplicationAccessLayerProps> = ({
  children,
  checkEditPermissions,
}) => {
  const { canView, isApplicationManager } = useApplication();

  const { goBack } = useRouter();

  if (!canView || (checkEditPermissions && !isApplicationManager)) {
    return (
      <ModalSmall open>
        <ModalHeader active>
          <IconButton onClick={goBack}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalAccessDenied />
      </ModalSmall>
    );
  }

  return <>{children}</>;
};
