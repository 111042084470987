import { styled } from '@mui/material';

import { Counter, CounterProps } from '../Counter';

export const TagSelectCounter = styled(Counter)<CounterProps>(({ theme }) => {
  return {
    minWidth: 20,
    height: 20,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: 0,
    marginRight: theme.spacing(-0.75),
    padding: theme.spacing(0, 0.5),
    background: 'transparent',

    '.MuiChip-filled &': {
      border: `1px solid ${theme.palette.common.white}`,
      color: theme.palette.common.white,
    },
  };
});
