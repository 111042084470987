import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { usePrevious } from '@schooly/hooks/use-previous';
import { CheckIcon, CrossIcon, DragIcon, PlusIcon, Spin } from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import {
  FieldPath,
  FieldPathValue,
  FormProvider,
  useFieldArray,
  useForm,
  Validate,
} from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { DndListWrapper } from '../../../../../components/uikit/Dnd/dnd.styled';
import {
  ModalContent,
  ModalFooter,
  ModalMain,
  ModalSmall,
} from '../../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../../components/uikit-components/Modal/ModalHeader';
import { SchoolTuneAgeGroupRow } from './SchoolTuneAgeGroupRow';
import { SchoolTuneSchoolLevel } from './SchoolTuneSchoolLevel';

export const NO_LEVEL_DROPPABLE_ID = 'AgeGroupWithNoLevelDroppable';

export enum AgeGroupsDroppable {
  LEVEL = 'AgeGroupsDroppableLevel',
  AGEGROUP = 'AgeGroupsDroppableAgeGroup',
}

export interface AgeGroupCreate {
  id?: string;
  id_tmp?: string;
  name: string;
  level_id: string | null;
}

export interface SchoolLevelCreate {
  id?: string;
  id_tmp?: string;
  name: string;
  ageGroups: AgeGroupCreate[];
}

export interface SchoolTuneAgeGroupsForm {
  schoolLevels: SchoolLevelCreate[];
  ageGroupsWithNoLevel: AgeGroupCreate[];
}

export const getEmptyAgeGroup = () => ({
  id: undefined,
  id_tmp: uuidv4(),
  name: '',
  level_id: null,
});

const getEmptySchoolLevel = () => ({
  id: undefined,
  id_tmp: uuidv4(),
  name: '',
  ageGroups: [],
});

type SchoolTuneAgeGroupsModalContentProps = {
  title: string;
  isSaving: boolean;
  onSubmit: (v: SchoolTuneAgeGroupsForm) => void;
  onClose: () => void;
  ageGroups: AgeGroupCreate[];
  schoolLevels: Omit<SchoolLevelCreate, 'ageGroups'>[];
};

export const SchoolTuneAgeGroupsModalContent: FC<SchoolTuneAgeGroupsModalContentProps> = ({
  onClose,
  onSubmit,
  title,
  isSaving,
  ageGroups: initAgeGroups,
  schoolLevels: initSchoolLevels,
}) => {
  const { $t } = useIntl();

  const firstEmptyLevel = getEmptySchoolLevel();
  const defaultSchoolLevels = useMemo(() => {
    if (initAgeGroups?.length) {
      if (initSchoolLevels.length) {
        return [
          ...initSchoolLevels.map((l) => ({
            ...l,
            ageGroups: initAgeGroups
              .filter((g) => g.level_id === l.id || g.level_id === l.id_tmp)
              .map((g) => ({ ...g, id_tmp: g.id ? undefined : uuidv4() })),
          })),
        ];
      }
      return [];
    }
    return [
      {
        ...firstEmptyLevel,
        ageGroups: [{ ...getEmptyAgeGroup(), level_id: firstEmptyLevel.id_tmp }],
      },
    ];
  }, [firstEmptyLevel, initAgeGroups, initSchoolLevels]);

  const form = useForm<SchoolTuneAgeGroupsForm>({
    defaultValues: {
      schoolLevels: defaultSchoolLevels,
      ageGroupsWithNoLevel: initAgeGroups
        ?.filter((ageGroup) => !ageGroup.level_id)
        .map((g) => ({ ...g, id_tmp: g.id ? undefined : uuidv4() })),
    },
  });

  const hasErrors =
    form.formState.errors.schoolLevels || form.formState.errors.ageGroupsWithNoLevel;

  const {
    append: appendAgeGroupWithNoLevel,
    remove: removeAgeGroupWithNoLevel,
    replace: replaceAgeGroupsWithNoLevel,
    move: moveAgeGroupWithNoLevel,
    insert: insertAgeGroupWithNoLevel,
  } = useFieldArray({
    control: form.control,
    name: 'ageGroupsWithNoLevel',
  });
  const {
    move: moveSchoolLevel,
    replace: replaceSchoolLevels,
    append: appendSchoolLevel,
    remove: removeSchoolLevel,
  } = useFieldArray({
    control: form.control,
    name: 'schoolLevels',
  });

  const schoolLevels = form.watch('schoolLevels');
  const ageGroupsWithNoLevel = form.watch('ageGroupsWithNoLevel');

  const getAllAgeGroups = useCallback(() => {
    const ageGroupsWithLevel: AgeGroupCreate[] = [];

    schoolLevels.forEach((level) => {
      if (level.ageGroups) {
        ageGroupsWithLevel.push(...level.ageGroups);
      }
    });

    return [...ageGroupsWithLevel, ...ageGroupsWithNoLevel];
  }, [ageGroupsWithNoLevel, schoolLevels]);

  //autofocus cases for age groups with no level
  const prevAgeGroupsWithNoLevel = usePrevious(ageGroupsWithNoLevel);
  const ageGroupAdded =
    prevAgeGroupsWithNoLevel &&
    ageGroupsWithNoLevel &&
    ageGroupsWithNoLevel.length - prevAgeGroupsWithNoLevel.length === 1;
  const shouldFocusAgeGroupInput = Boolean(ageGroupAdded);

  //autofocus cases for school levels
  const prevSchoolLevels = usePrevious(schoolLevels);
  const firstSchoolLevelField = form.getValues(`schoolLevels.${0}`);
  const schoolLevelAdded = prevSchoolLevels && schoolLevels.length - prevSchoolLevels.length === 1;
  const shouldFocusLevelInput = Boolean(
    schoolLevels.length === 1 ? !firstSchoolLevelField?.name : schoolLevelAdded,
  );

  const addSchoolLevel = useCallback(() => {
    appendSchoolLevel(getEmptySchoolLevel());
  }, [appendSchoolLevel]);

  const deleteSchoolLevel = useCallback(
    (index: number) => {
      const ageGroupsWithoutLevel = form.getValues('ageGroupsWithNoLevel');
      const ageGroupsOfLevel = form.getValues(`schoolLevels.${index}.ageGroups`);
      const updatedAgeGroupsOfLevel = ageGroupsOfLevel.map((g) => ({ ...g, level_id: null }));

      replaceAgeGroupsWithNoLevel([...ageGroupsWithoutLevel, ...updatedAgeGroupsOfLevel]);
      removeSchoolLevel(index);

      if (hasErrors) {
        form.trigger();
      }
    },
    [form, hasErrors, removeSchoolLevel, replaceAgeGroupsWithNoLevel],
  );

  const addAgeGroupWithNoLevel = useCallback(() => {
    appendAgeGroupWithNoLevel(getEmptyAgeGroup());
  }, [appendAgeGroupWithNoLevel]);

  const deleteAgeGroupWithNoLevel = useCallback(
    (index: number) => {
      removeAgeGroupWithNoLevel(index);
      if (hasErrors) {
        form.trigger();
      }
    },
    [form, hasErrors, removeAgeGroupWithNoLevel],
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      const { destination, source, type } = result;

      // dropped outside the list
      if (!destination) {
        return;
      }
      // dropped to the same position
      if (destination.index === source.index && destination.droppableId === source.droppableId) {
        return;
      }

      //when moving level
      if (type === AgeGroupsDroppable.LEVEL) {
        moveSchoolLevel(source.index, destination.index);
      } else {
        //when moving age group
        if (
          //when moved inside age groups with no level
          source.droppableId === NO_LEVEL_DROPPABLE_ID &&
          destination.droppableId === NO_LEVEL_DROPPABLE_ID
        ) {
          moveAgeGroupWithNoLevel(source.index, destination.index);
        } else {
          const schoolLevels = form.getValues(`schoolLevels`) ?? [];
          const newLevelId =
            destination.droppableId === NO_LEVEL_DROPPABLE_ID ? null : destination.droppableId;

          const updatedSchoolLevels = [...(schoolLevels ?? [])];
          const sourceLevelIndex =
            source.droppableId !== NO_LEVEL_DROPPABLE_ID
              ? schoolLevels.findIndex((level) => {
                  const levelId = level.id || level.id_tmp;
                  return levelId === source.droppableId;
                })
              : -1;
          const destinationLevelIndex =
            destination.droppableId !== NO_LEVEL_DROPPABLE_ID
              ? schoolLevels.findIndex((level) => {
                  const levelId = level.id || level.id_tmp;
                  return levelId === destination.droppableId;
                })
              : -1;

          if (
            //when moved outside of level
            source.droppableId !== NO_LEVEL_DROPPABLE_ID &&
            destination.droppableId === NO_LEVEL_DROPPABLE_ID
          ) {
            const [removedAgeGroup] = updatedSchoolLevels[sourceLevelIndex].ageGroups.splice(
              source.index,
              1,
            );

            insertAgeGroupWithNoLevel(destination.index, {
              ...removedAgeGroup,
              level_id: newLevelId,
            });
            replaceSchoolLevels(updatedSchoolLevels);
          } else if (
            //when moved from one level to another
            source.droppableId !== NO_LEVEL_DROPPABLE_ID &&
            destination.droppableId !== NO_LEVEL_DROPPABLE_ID
          ) {
            const [removedAgeGroup] = updatedSchoolLevels[sourceLevelIndex].ageGroups.splice(
              source.index,
              1,
            );

            updatedSchoolLevels[destinationLevelIndex]?.ageGroups.splice(destination.index, 0, {
              ...removedAgeGroup,
              level_id: newLevelId,
            });

            replaceSchoolLevels(updatedSchoolLevels);
          } else {
            //when moved inside a level from age groups with no level
            const ageGroup = form.getValues(`ageGroupsWithNoLevel.${source.index}`);
            updatedSchoolLevels[destinationLevelIndex]?.ageGroups.splice(destination.index, 0, {
              ...ageGroup,
              level_id: newLevelId,
            });

            removeAgeGroupWithNoLevel(source.index);
            replaceSchoolLevels(updatedSchoolLevels);
          }
        }
      }
      if (hasErrors) {
        form.trigger();
      }
    },
    [
      hasErrors,
      form,
      replaceSchoolLevels,
      insertAgeGroupWithNoLevel,
      moveAgeGroupWithNoLevel,
      removeAgeGroupWithNoLevel,
      moveSchoolLevel,
    ],
  );

  const validateAgeGroup = useCallback<
    (
      id: string,
    ) => Validate<
      FieldPathValue<SchoolTuneAgeGroupsForm, FieldPath<SchoolTuneAgeGroupsForm>>,
      SchoolTuneAgeGroupsForm
    >
  >(
    (id) => (value) => {
      // Check for name uniqueness
      const ageGroups = getAllAgeGroups();
      for (let i = 0; i < (ageGroups?.length ?? 0); i++) {
        const ageGroup = ageGroups[i];
        const ageGroupId = ageGroup.id || ageGroup.id_tmp;
        if (ageGroupId === id) {
          continue;
        }

        if (ageGroup.name?.trim().toLowerCase() === `${value ?? ''}`.trim().toLowerCase()) {
          return $t({
            id: 'school-tabs-AgeGroups-ActiveExists',
          });
        }
      }

      return true;
    },
    [$t, getAllAgeGroups],
  );

  const validateSchoolLevel = useCallback<
    (
      index: number,
    ) => Validate<
      FieldPathValue<SchoolTuneAgeGroupsForm, FieldPath<SchoolTuneAgeGroupsForm>>,
      SchoolTuneAgeGroupsForm
    >
  >(
    (index) => (value, formValues) => {
      // Check for name uniqueness
      for (let i = 0; i < (formValues.schoolLevels?.length ?? 0); i++) {
        if (i === index) {
          continue;
        }

        const field = formValues.schoolLevels![i];

        if (field.name?.trim().toLowerCase() === `${value ?? ''}`.trim().toLowerCase()) {
          return $t({
            id: 'school-tabs-AgeGroups-SchoolLevelExists',
          });
        }
      }

      return true;
    },
    [$t],
  );

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader title={title} active>
            {!isSaving && (
              <IconButton onClick={onClose}>
                <CrossIcon />
              </IconButton>
            )}
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              <Stack gap={2.5} sx={{ height: '100%' }}>
                <Typography variant="h2">
                  <FormattedMessage id="school-tabs-AgeGroups" />
                </Typography>

                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId={AgeGroupsDroppable.LEVEL} type={AgeGroupsDroppable.LEVEL}>
                    {(provided) => (
                      <DndListWrapper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        gap={3}
                        sx={{ overflow: 'visible', height: 'unset' }}
                      >
                        {schoolLevels.map((level, index) => {
                          const levelId = level.id || level.id_tmp || '';
                          return (
                            <Draggable
                              key={levelId}
                              draggableId={levelId}
                              index={index}
                              isDragDisabled={schoolLevels.length < 2}
                            >
                              {(provided) => (
                                <Stack
                                  direction="row"
                                  alignItems="flex-start"
                                  gap={1}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={provided.draggableProps.style}
                                >
                                  {schoolLevels.length > 1 && (
                                    <IconButton
                                      inverse
                                      {...provided.dragHandleProps}
                                      sx={(theme) => ({ mt: theme.spacing(3.5) })}
                                    >
                                      <DragIcon />
                                    </IconButton>
                                  )}
                                  <SchoolTuneSchoolLevel
                                    levelIndex={index}
                                    handleDeleteSchoolLevel={deleteSchoolLevel}
                                    shouldFocusLevelInput={shouldFocusLevelInput}
                                    validateAgeGroup={validateAgeGroup}
                                    validateSchoolLevel={validateSchoolLevel}
                                  />
                                </Stack>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        <Button
                          variant="outlined"
                          startIcon={<PlusIcon />}
                          onClick={addSchoolLevel}
                          sx={{ alignSelf: 'flex-start' }}
                        >
                          <FormattedMessage id="school-tabs-AgeGroups-AddSchoolLevel" />
                        </Button>
                      </DndListWrapper>
                    )}
                  </Droppable>

                  <Droppable droppableId={NO_LEVEL_DROPPABLE_ID} type={AgeGroupsDroppable.AGEGROUP}>
                    {(provided) => (
                      <DndListWrapper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        alignItems="flex-start"
                        gap={2}
                        pb={2.5}
                        sx={{ overflow: 'visible', height: 'unset' }}
                      >
                        {ageGroupsWithNoLevel.map((ageGroup, index) => {
                          const ageGroupId = ageGroup.id || ageGroup.id_tmp || '';
                          return (
                            <Draggable key={ageGroupId} draggableId={ageGroupId} index={index}>
                              {(provided) => (
                                <SchoolTuneAgeGroupRow
                                  ageGroupIndex={index}
                                  getAllAgeGroups={getAllAgeGroups}
                                  provided={provided}
                                  shouldFocus={shouldFocusAgeGroupInput}
                                  handleDeleteAgeGroup={deleteAgeGroupWithNoLevel}
                                  validateAgeGroup={validateAgeGroup}
                                />
                              )}
                            </Draggable>
                          );
                        })}

                        {provided.placeholder}

                        {Boolean(ageGroupsWithNoLevel.length) && (
                          <Box pt={1}>
                            <Button
                              variant="outlined"
                              startIcon={<PlusIcon />}
                              onClick={addAgeGroupWithNoLevel}
                            >
                              <FormattedMessage id="school-tabs-AgeGroups-AddAgeGroup" />
                            </Button>
                          </Box>
                        )}
                      </DndListWrapper>
                    )}
                  </Droppable>
                </DragDropContext>
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooter sx={{ justifyContent: 'space-between' }} active>
            <Button variant="outlined" disabled={isSaving} onClick={onClose}>
              <FormattedMessage id="action-Cancel" />
            </Button>
            <Button type="submit" disabled={isSaving} endIcon={isSaving ? <Spin /> : <CheckIcon />}>
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
