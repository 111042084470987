import { Button, Dialog, Icon, IconButton, Stack, Typography } from '@mui/material';
import { ControlRadioGroup } from '@schooly/components/form-radio-group';
import { Attention2Icon, CrossIcon, ModalContent, ModalFooter, ModalHeader } from '@schooly/style';
import React, { FC, ReactNode, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

export enum RecurringConfirmType {
  Single = 1,
  CurrentAndFollowing = 2,
}
export type RecurringConfirmOptions = {
  value: RecurringConfirmType;
  label: string;
  disabled?: boolean;
};

export type RecurringConfirmForm = {
  type: RecurringConfirmType;
};

export type RecurringConfirmModalProps = {
  onSubmit: (d: RecurringConfirmForm) => void;
  options: RecurringConfirmOptions[];
  warningTitle?: string;
  title: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  actionButtonTextId?: string;
};

export const RecurringConfirmModal: FC<RecurringConfirmModalProps> = ({
  onSubmit,
  options,
  warningTitle,
  title,
  onClose,
  isOpen,
  actionButtonTextId,
}) => {
  const form = useForm<RecurringConfirmForm>({
    defaultValues: {
      type: RecurringConfirmType.Single,
    },
  });

  const { reset } = form;

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleSubmit = useCallback(
    (data: RecurringConfirmForm) => {
      onSubmit(data);
      reset();
    },
    [onSubmit, reset],
  );

  return (
    <Dialog
      fullWidth
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndex.snackbar,
        '& .MuiDialog-paperFullWidth': {
          width: 600,
          height: 'unset',
        },
      })}
      open={isOpen}
      onClose={handleClose}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ModalHeader sx={{ py: 1.5 }} active title={title}>
            <IconButton onClick={handleClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>

          <ModalContent
            active
            flat
            sx={{
              px: 1,
              py: 1.75,
            }}
          >
            {warningTitle && (
              <Stack
                sx={(theme) => ({
                  flexDirection: 'row',
                  gap: 2,
                  border: `1px solid ${theme.palette.common.orange}`,
                  borderRadius: '6px',
                  bgcolor: theme.palette.common.orange5,
                  color: theme.palette.common.orange,
                  px: 2.5,
                  py: 1.25,
                  mx: 1.5,
                  mb: 1,
                  '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
                })}
              >
                <Icon>
                  <Attention2Icon />
                </Icon>

                <Typography variant="h3" color="inherit">
                  {warningTitle}
                </Typography>
              </Stack>
            )}

            <ControlRadioGroup
              options={options}
              name="type"
              withBorder={false}
              containerProps={{
                sx: () => ({
                  flexDirection: 'column',
                  gap: 0.5,
                  alignItems: 'flex-start',
                }),
              }}
            />
          </ModalContent>
          <ModalFooter
            active
            sx={{
              pr: 2.25,
              py: 1.25,
            }}
          >
            <Stack direction="row" gap={1.25}>
              <Button variant="outlined" onClick={handleClose}>
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button type="submit">
                <FormattedMessage id={actionButtonTextId ?? 'action-Confirm'} />
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </FormProvider>
    </Dialog>
  );
};
