import React from 'react';

import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';
import { LoaderShimmerLine } from '../LoaderShimmerLine/LoaderShimmerLine';

import './Preloader.scss';

const $class = `${$UIKitPrefix}Preloader`;

export interface PreloaderProps {
  className?: string;
}

const Preloader: React.FC<PreloaderProps> = ({ className }) => {
  return (
    <div className={buildClassName($class, className)}>
      <LoaderShimmerLine className={`${$class}__row`} />
      <LoaderShimmerLine className={`${$class}__row`} />
      <LoaderShimmerLine className={`${$class}__row`} />
    </div>
  );
};

export default Preloader;
