import { useCompleteXeroAuth } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLegalEntity } from '../../../../context/legalEntity/WithLegalEntity';
import { getStoredLegalEntity } from '../helpers';

//TR-6076 Temporary redirect until we can update redirect_uri in xero
export const XeroAuthRedirect: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();

  navigate(`/settings/legal_entities/integration/xero-auth?${searchParamsString}`, {
    state: { replace: true },
  });
  return null;
};

export const LegalEntityXeroAuth: FC = () => {
  const { formatMessage } = useIntl();
  const { schoolId = '' } = useAuth();
  const [searchParams] = useSearchParams();
  const sendXeroAuthData = useCompleteXeroAuth();
  const navigate = useNavigate();
  const { showNotification } = useNotifications();

  const { changeSelectedTenant } = useLegalEntity();

  useEffect(() => {
    const redirectBack = () => {
      const legalEntityParams = getStoredLegalEntity();
      navigate(legalEntityParams ? legalEntityParams.path : '/settings/legal_entities/new', {
        state: { replace: true },
      });
    };

    sendXeroAuthData.mutate(
      {
        schoolId,
        state: searchParams.get('state') || '',
        code: searchParams.get('code') || '',
        session_state: searchParams.get('session_state') || '',
      },
      {
        onError: () => {
          showNotification({
            message: formatMessage({ id: 'legalEntity-XeroAuthError' }),
            type: 'error',
          });
          redirectBack();
        },
        onSuccess: ({ connected_tenant }) => {
          if (!connected_tenant) {
            showNotification({
              message: formatMessage({ id: 'legalEntity-XeroTenantNotSelectedError' }),
              type: 'error',
            });
            redirectBack();
            return;
          }

          changeSelectedTenant(connected_tenant);
          redirectBack();
        },
      },
    );
    // Don't need any deps here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
