import { GroupType, GroupWithMembers, UserRolePermission } from '@schooly/api';

import { ModalSidebarItem } from '../../components/ui/ModalSidebar';

export enum ViewGroupModalTabs {
  Attendance = 'attendance',
  Members = 'members',
  Messages = 'messages',
  Assessments = 'assessments',
  Conduct = 'conduct',
}

export const getTabs = (
  permissions?: UserRolePermission[],
  group?: GroupWithMembers,
): ModalSidebarItem<ViewGroupModalTabs>[] => {
  if (!group) {
    return [];
  }

  const allowedAttendance =
    permissions?.includes('attendance_viewer') && group.group_type === GroupType.TutorGroup;
  const allowedAssessments = permissions?.includes('assessment_viewer');
  const allowedMessages = permissions?.includes('message_viewer');
  const allowedConduct = permissions?.includes('conduct_viewer');

  return [
    { id: ViewGroupModalTabs.Members, title: 'groups-Members' },
    { id: ViewGroupModalTabs.Attendance, title: 'groups-Attendance', disabled: !allowedAttendance },
    {
      id: ViewGroupModalTabs.Assessments,
      title: 'groups-Assessments',
      disabled: !allowedAssessments,
    },
    { id: ViewGroupModalTabs.Messages, title: 'groups-Messages', disabled: !allowedMessages },
    { id: ViewGroupModalTabs.Conduct, title: 'groups-Conduct', disabled: !allowedConduct },
  ].filter((tab) => !tab.disabled);
};
