import { Box } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SchoolTuneStatusModalMode } from './scheme';
import { SchoolTuneStatusesDisplayCategory } from './SchoolTuneStatusesDisplayCategory';
import { SchoolTuneStatusesModalArrow } from './SchoolTuneStatusesModalArrow';

export interface SchoolTuneStatusesDisplayProps {
  type: SchoolTuneStatusModalMode;
}

export const SchoolTuneStatusesDisplay: FC<SchoolTuneStatusesDisplayProps> = ({ type }) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 29px 1fr 29px 1fr"
      gridTemplateRows="repeat(2, 1fr)"
      height="100%"
      gap={1}
      rowGap={2}
    >
      <Box gridColumn="span 1" gridRow="span 2">
        <SchoolTuneStatusesDisplayCategory
          type={type}
          category="prospective"
          title={<FormattedMessage id="school-tabs-Statuses-Status-Prospective" />}
        />
      </Box>
      <Box gridColumn="span 1">
        <SchoolTuneStatusesModalArrow />
      </Box>
      <Box gridColumn="span 1">
        <SchoolTuneStatusesDisplayCategory
          type={type}
          category="current"
          title={<FormattedMessage id="school-tabs-Statuses-Status-Current" />}
        />
      </Box>
      <Box gridColumn="span 1">
        <SchoolTuneStatusesModalArrow />
      </Box>
      <Box gridColumn="span 1">
        <SchoolTuneStatusesDisplayCategory
          type={type}
          category="former"
          title={<FormattedMessage id="school-tabs-Statuses-Status-Former" />}
        />
      </Box>
      <Box gridColumn="span 3">
        <SchoolTuneStatusesModalArrow />
      </Box>
      <Box gridColumn="span 1">
        <SchoolTuneStatusesDisplayCategory
          type={type}
          category="unsuccessful"
          title={<FormattedMessage id="school-tabs-Statuses-Status-Unsuccessful" />}
        />
      </Box>
    </Box>
  );
};
