import { Loading } from '@schooly/style';
import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useFilters } from '../../../../context/filters/useFilters';
import { useUserRolesForSchool } from '../../../../context/userRoles/useUserRolesForSchool';
import { getRouteModalPathname } from '../../../../helpers/misc';
import { filterUserRole } from '../../../../helpers/userRoles';
import useAppLocation from '../../../../hooks/useAppLocation';
import addHighlight from '../../../../utils/addHighlight';
import { HeaderSearchFooter } from './HeaderSearchFooter';

export const HeaderSearchUserRoleList: FC = () => {
  const location = useAppLocation();
  const navigate = useNavigate();
  const { filters } = useFilters();
  const { userRoles, isLoading } = useUserRolesForSchool();

  const filteredRoles = useMemo(() => {
    if (!userRoles) return [];
    return [...userRoles.available_to_assign, ...userRoles.not_allowed_to_assign].filter((role) =>
      filterUserRole(role.name, filters.draftQuery),
    );
  }, [filters.draftQuery, userRoles]);

  const handleUserRoleClick = (id: string) => {
    navigate(getRouteModalPathname('userroles', { id }), {
      state: { backgroundLocation: location },
    });
  };

  const entityText = (
    <span className="text-lowercase">
      <FormattedMessage id="section-UserRoles" />
    </span>
  );

  if (!isLoading && !filteredRoles.length) {
    return (
      <h3 className="no-results">
        <FormattedMessage id="noResultsMatching" values={{ filter: filters.draftQuery }} />
      </h3>
    );
  }

  return (
    <>
      {/* TODO Create component-wrapper with mui styles and wrap all HeaderSearch...List components  */}
      <div className="filters-section filters-section__general">
        <h4 className="mb-1 pr-3">
          <FormattedMessage id="section-UserRoles" />
        </h4>
        {isLoading ? (
          <div className="pt-2 mb-4">
            <Loading />
          </div>
        ) : (
          <div className="List">
            {filteredRoles.map(({ id, name }) => (
              <div
                className="List-item"
                onClick={() => handleUserRoleClick(id)}
                role="button"
                tabIndex={0}
                key={id}
              >
                <div className="row">
                  <div className="List-item-name col h3 mb-0">
                    {addHighlight(name, filters.draftQuery)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <HeaderSearchFooter
        text={
          <FormattedMessage
            id="action-ShowAllByMatching"
            values={{
              entities: entityText,
              filter: filters.draftQuery,
            }}
          />
        }
      />
    </>
  );
};
