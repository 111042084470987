import { Box, Button, Icon, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ApiError, LegalEntity, PagedResponse, useGetLegalEntitiesListQuery } from '@schooly/api';
import {
  ArchiveIcon,
  EditIcon,
  EmptyFolderSvg,
  EyeIcon,
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  Loading,
  PlusIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserAddress } from '@schooly/utils/user-helpers';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

export const pathToNewLegalEntity = '/settings/legal_entities/new';

type SchoolLegalEntitiesListProps = {
  schoolId: string;
  archived: boolean;
};

export const SchoolLegalEntitiesList: FC<PropsWithChildren<SchoolLegalEntitiesListProps>> = ({
  archived,
  schoolId,
  children,
}) => {
  const { data, ...props } = useGetLegalEntitiesListQuery(
    {
      schoolId,
      archived,
    },
    { enabled: !!schoolId, refetchOnMount: 'always' },
  );
  const entries = useMemo(
    () => data?.pages.reduce<LegalEntity[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  return (
    <Stack>
      {children}
      <LegalEntityEntriesContent entries={entries} {...props} archived={archived} />
    </Stack>
  );
};

type LegalEntityEntriesContentProps = {
  entries: LegalEntity[];
  archived?: boolean;
} & Omit<UseInfiniteQueryResult<PagedResponse<LegalEntity>, ApiError>, 'data'>;

export const LegalEntityEntriesContent: FC<LegalEntityEntriesContentProps> = ({
  entries,
  archived,
  isLoading,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
}) => {
  const { formatMessage } = useIntl();

  if (!isLoading && !entries.length) {
    return (
      <Stack alignItems="center" justifyContent="center" flex={1} pt={11} gap={3}>
        <EmptyFolderSvg />

        <Typography variant="h3" maxWidth={660} textAlign="center" pt={3}>
          {formatMessage({ id: 'legalEntities-NoActiveTitle' })}
        </Typography>

        {!archived && (
          <Link to={pathToNewLegalEntity}>
            <Button startIcon={<PlusIcon />}>
              {formatMessage({ id: 'legalEntities-AddLegalEntity' })}
            </Button>
          </Link>
        )}
      </Stack>
    );
  }

  return (
    <Stack>
      {isLoading && !entries.length && <Loading sx={{ my: 8 }} />}
      {entries.map((entity) => (
        <LegalEntityRow key={entity.id} entity={entity} />
      ))}

      {hasNextPage && (
        <Stack mt={2} alignItems="center">
          {isFetchingNextPage ? (
            <Loading sx={{ mt: 1 }} />
          ) : (
            <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
              {formatMessage({ id: 'action-ShowMoreButton' })}
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};

interface LegalEntityRowProps {
  entity: LegalEntity;
}

export const LegalEntityRow: FC<LegalEntityRowProps> = ({ entity }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <GridRowStyled noBorderRadius>
      <GridRowItem
        gap={2}
        sx={{
          ' .actionIcon': {
            visibility: 'hidden',
          },
          ':hover .actionIcon': {
            visibility: 'visible',
          },
        }}
      >
        <GridRowName minWidth={0} sx={{ flex: '1 1 27%' }}>
          <TypographyWithOverflowHint variant="h3">
            {entity.display_name}
          </TypographyWithOverflowHint>
        </GridRowName>

        <GridRowName minWidth={0} sx={{ flex: '1 1 15%' }}>
          <TypographyWithOverflowHint>{entity.name}</TypographyWithOverflowHint>
        </GridRowName>

        <GridRowName minWidth={0} sx={{ flex: '1 1 48%' }}>
          <TypographyWithOverflowHint>{getUserAddress(entity) ?? '-'}</TypographyWithOverflowHint>
        </GridRowName>

        <GridRowName sx={{ flex: '1 1 10%' }}>
          <Typography>{entity.currency ?? '-'}</Typography>
        </GridRowName>

        <GridRowCell>
          <Box className="actionIcon">
            {entity.archived ? (
              <Tooltip
                title={
                  <Typography>
                    {formatMessage({ id: 'legalEntities-ArchivedTitle' }, { name: entity.name })}
                  </Typography>
                }
              >
                <Icon>
                  <ArchiveIcon />
                </Icon>
              </Tooltip>
            ) : (
              <IconButton
                onClick={() => {
                  navigate(`/settings/legal_entities/${entity.id}`);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </GridRowCell>
      </GridRowItem>
    </GridRowStyled>
  );
};
