import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.34912 8.29688H8.66309C8.72021 7.45898 9.28516 6.93213 10.1611 6.93213C11.0308 6.93213 11.5957 7.45898 11.5957 8.17627C11.5957 8.83008 11.3228 9.19824 10.5547 9.66162C9.68506 10.1821 9.29785 10.7534 9.32959 11.6484L9.33594 12.1562H10.6499V11.769C10.6499 11.1152 10.8848 10.7788 11.7036 10.2964C12.5415 9.79492 13.0366 9.10303 13.0366 8.11914C13.0366 6.76074 11.9067 5.76416 10.231 5.76416C8.39014 5.76416 7.40625 6.8623 7.34912 8.29688ZM10.0024 15.0952C10.4785 15.0952 10.8403 14.7397 10.8403 14.2764C10.8403 13.813 10.4785 13.4575 10.0024 13.4575C9.53906 13.4575 9.1709 13.813 9.1709 14.2764C9.1709 14.7397 9.53906 15.0952 10.0024 15.0952Z"
      fill="#B3BBCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      fill="#B3BBCB"
    />
  </svg>
);
