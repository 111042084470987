import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00012 14.9152L3.29303 9.20808C2.90251 8.81756 2.90251 8.1844 3.29303 7.79387C3.68356 7.40335 4.31672 7.40335 4.70724 7.79387L9.00015 12.0868L15.7944 5.29288C16.1849 4.90236 16.8181 4.90238 17.2086 5.29291C17.5991 5.68345 17.5991 6.31661 17.2086 6.70713L9.00012 14.9152Z"
      fill="#24275B"
    />
  </svg>
);
