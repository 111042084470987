import { Stack } from '@mui/material';
import { TagSelect, TagSelectProps } from '@schooly/style';
import { PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from './ExpandedSelect';

interface ExpandedSelectFilterProps<T> extends PropsWithChildren {
  selected: T[];
  onSelect: (v: T) => void;
  onClear: () => void;
  onClose: () => void;
  options: T[];
  getLabel: (v: T) => string;
}

export function ExpandedSelectFilter<T>({
  selected,
  onSelect,
  onClose,
  onClear,
  options,
  getLabel,
}: ExpandedSelectFilterProps<T>) {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderTags({
          items: options,
          tagProps: (item) => {
            return {
              variant: selected.includes(item) ? 'filled' : undefined,
              label: getLabel(item),
            };
          },
          onClick: onSelect,
          getLabel,
        })}
      </Stack>
    );
  }, [getLabel, onSelect, options, selected]);

  return (
    <ExpandedSelect
      hasSelectedValue={selected.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={renderContent}
    >
      {renderTags({
        tagProps: { size: 'small' },
        items: selected,
        onDelete: onSelect,
        getLabel,
      })}
    </ExpandedSelect>
  );
}

type RenderTagsParams<T> = {
  items: T[];
  onDelete?: (v: T) => void;
  onClick?: (v: T) => void;
  getLabel: (v: T) => string;
  tagProps?: TagSelectProps | ((v: T) => TagSelectProps);
};

export function renderTags<T>({
  items,
  onDelete,
  onClick,
  tagProps,
  getLabel,
}: RenderTagsParams<T>) {
  return items.map((v) => (
    <TagSelect
      key={v as string}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      label={getLabel(v)}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
}
