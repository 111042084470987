import { Box } from '@mui/material';
import { getTypedObjectKeys } from '@schooly/api';
import { theme } from '@schooly/style';
import { format, getMonth, lastDayOfMonth } from 'date-fns';
import type {
  ECElementEvent,
  EChartsOption,
  ElementEvent,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import ReactECharts from 'echarts-for-react';
import * as _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { MONTH_NAMES } from './CalendarChartEmpty';

interface CalendarChartData {
  value: [string, string];
  itemStyle: {
    opacity: number;
  };
}

const COLOR_PALETTE = [
  '#E6E9F2',
  '#CFD4E5',
  '#B8C0D9',
  '#A3AACC',
  '#8F97BF',
  '#7D86B2',
  '#6C71A6',
  '#5C6199',
  '#404580',
  '#292966',
];

interface CalendarSelectMonthData {
  month: number;
  year: string;
}
export interface CalendarChartProps {
  chartData: Record<string, Array<{ date: string; value: number; month: number }>>;
  renderTooltip?: (params: TooltipComponentFormatterCallbackParams) => string;
  maxCount?: number;
  dateFormat: string;
  onDateSelect?: (data: { date: string[]; year: string; index: number | null } | null) => void;
  selectedData: { date: string[]; year: string; index: number | null } | null;
  loading: boolean;
  dateRange: string[];
  withoutItemCount?: boolean;
}

export const CalendarChart: FC<CalendarChartProps> = ({
  chartData,
  renderTooltip,
  maxCount,
  onDateSelect,
  dateFormat,
  selectedData,
  loading,
  dateRange,
  withoutItemCount,
}) => {
  const echartRef = useRef<echarts.ECharts | null>(null);
  const [width, setWidth] = useState(0);

  const { ref } = useResizeDetector<HTMLDivElement>({
    onResize: () => {
      setWidth(ref.current?.clientWidth ?? 0);
    },
  });

  const yearKeys = useMemo(() => getTypedObjectKeys(chartData), [chartData]);

  const dataMaxCount = maxCount ? maxCount : 100;

  const monthCounts = useMemo(
    () =>
      Object.values(chartData)
        .flat()
        .reduce<Record<string, number>>((acc, { month, value }) => {
          const data = acc[month];
          return data ? { ...acc, [month]: data + value } : { ...acc, [month]: value };
        }, {}),
    [chartData],
  );

  const options: EChartsOption = useMemo(
    () => ({
      animation: false,
      tooltip: [
        {
          triggerEvent: true,
          trigger: 'item',
          enterable: true,
          showContent: true,
          confine: true,
          axisPointer: {
            type: 'shadow',
          },
          textStyle: {
            ...(theme.typography.caption as any),
            color: theme.palette.primary.main,
          },
          padding: [2, 4, 2, 4],
          borderWidth: 0,
          formatter: renderTooltip,
        },
      ],
      visualMap: {
        triggerEvent: true,
        min: 0,
        max: dataMaxCount,
        width,
        type: 'piecewise',
        orient: 'horizontal',
        left: 'right',
        calculable: true,
        show: false,
        inRange: {
          color: COLOR_PALETTE,
        },
      },

      calendar: {
        triggerEvent: true,
        left: 32,
        width: '97%',
        top: 15,
        cellSize: [12, 40],
        range: dateRange,
        splitLine: {
          lineStyle: {
            color: theme.palette.common.grey,
          },
        },
        itemStyle: {
          borderColor: theme.palette.common.light2,
        },
        yearLabel: {
          show: false,
        },
        dayLabel: {
          color: theme.palette.common.grey2,
        },
        monthLabel: {
          color: theme.palette.common.grey2,
          position: 'end',
          padding: 10,
          formatter: (a) => {
            const count = monthCounts?.[a.M];
            const monthName = a.nameMap.toUpperCase();

            return count && !withoutItemCount
              ? [`${monthName}`, `{count|${count}}`].join('  ')
              : `{title|${monthName}}`;
          },
          rich: {
            count: {
              borderColor: theme.palette.common.light2,
              borderWidth: 1,
              borderRadius: 15,
              padding: [2, 4, 2, 4],
              color: theme.palette.common.grey2,
            },

            title: {
              padding: 2,
            },
          },
        },
      },
      grid: {
        containLabel: true,
        top: 0,
        left: 0,
        height: '100%',
      },
      series: yearKeys.map((key) => ({
        type: 'heatmap',
        coordinateSystem: 'calendar',
        calendarIndex: 0,
        data: chartData[key]?.map(({ date, value }) => ({
          value: [date, value],
          itemStyle: {
            opacity: 1,
          },
          label: {
            show: true,
            fontSize: theme.typography.body1.fontSize,
            fontFamily: theme.typography.body1.fontFamily,
            formatter: (a) => {
              const currentChart = chartData[a.seriesName!];
              const year = currentChart ? currentChart[a.dataIndex!] : null;
              return year ? year.value.toString() : '';
            },
          },
        })),
        name: key,
      })),
    }),
    [
      chartData,
      dataMaxCount,
      dateRange,
      monthCounts,
      renderTooltip,
      width,
      withoutItemCount,
      yearKeys,
    ],
  );

  const onChartReady = useCallback(
    (ref: echarts.ECharts) => {
      echartRef.current = ref;
      echartRef.current.setOption(options);
    },
    [options],
  );

  const isolateByIndex = useCallback(
    ({ seriesName, dataIndex }: { dataIndex: number; seriesName: string }) => {
      const options = echartRef.current?.getOption();
      const series = options?.series as EChartsOption['series'];

      if (!Array.isArray(series)) {
        return;
      }

      const newSeries = series.map((opt) => {
        const data = opt.data as CalendarChartData[];

        return {
          ...opt,
          data: data.map((d, index) => {
            const selectedCell = dataIndex === index && seriesName === opt.name;
            return {
              ...d,
              itemStyle: {
                ...d.itemStyle,
                borderColor: selectedCell ? theme.palette.primary.main : undefined,
                opacity: selectedCell ? 1 : 0.1,
              },
            };
          }),
        };
      });

      echartRef.current?.setOption({
        series: newSeries,
      });
    },
    [],
  );

  const clearIsolation = useCallback(() => {
    const options = echartRef.current?.getOption();

    const series = options?.series as EChartsOption['series'];
    if (!Array.isArray(series)) {
      return;
    }

    const newSeries = series.map((opt) => {
      const chartData = opt.data as CalendarChartData[];

      return {
        ...opt,
        data: chartData.map((d) => ({
          ...d,
          itemStyle: {
            ...d.itemStyle,
            opacity: 1,
            borderColor: undefined,
          },
        })),
      };
    });
    echartRef.current?.setOption({
      series: newSeries,
    });
  }, []);

  const onDayCellClick = useCallback(
    (e: ECElementEvent) => {
      if (!e.seriesName || !Number.isInteger(e.seriesIndex) || !Number.isInteger(e.seriesIndex)) {
        return;
      }

      const [date] = e.value as [string, string];
      if (!date) {
        return;
      }

      if (selectedData && _.isEqual(selectedData.date, [date, date])) {
        clearIsolation();
        onDateSelect?.(null);
      } else {
        isolateByIndex({
          dataIndex: e.dataIndex,
          seriesName: e.seriesName,
        });
        onDateSelect?.({ date: [date, date], index: e.dataIndex, year: e.seriesName });
      }
    },
    [clearIsolation, isolateByIndex, onDateSelect, selectedData],
  );

  const getMonthData = useCallback(
    (event: ElementEvent) => {
      const emptyData = { month: null, year: null };
      if (!event.target) {
        return emptyData;
      }

      const style = 'style' in event.target ? (event.target.style as { text: string }) : null;
      if (!style || !style.text) {
        return emptyData;
      }

      // Month count
      if (Number.isInteger(+style.text)) {
        return emptyData;
      }

      const monthName = style.text.trim();

      if (!MONTH_NAMES.some((m) => m.toLocaleLowerCase() === monthName.toLocaleLowerCase())) {
        return emptyData;
      }
      const monthNumber = getMonth(new Date(`${monthName} 1`)) + 1;

      const selectedYear = yearKeys.reduce(
        (acc, year) => (chartData[year]?.some(({ month }) => month === monthNumber) ? year : acc),
        '',
      );

      if (!selectedYear) {
        return emptyData;
      }

      return { month: monthNumber ?? null, year: selectedYear ?? null };
    },
    [chartData, yearKeys],
  );

  const isolateOtherMonths = useCallback((data: CalendarSelectMonthData) => {
    const options = echartRef.current?.getOption();

    const series = options?.series as EChartsOption['series'];
    if (!Array.isArray(series)) {
      return;
    }
    const newSeries = series.map((opt) => {
      const chartData = opt.data as CalendarChartData[];

      return {
        ...opt,
        data: chartData.map((d) => {
          const [date] = d.value;
          const month = new Date(date).getMonth() + 1;

          return {
            ...d,
            itemStyle: {
              ...d.itemStyle,
              opacity: month === data.month ? 1 : 0.1,
            },
          };
        }),
      };
    });

    echartRef.current?.setOption({
      series: newSeries,
    });
  }, []);

  const onChartClick = useCallback(
    (params: ElementEvent) => {
      const { month, year } = getMonthData(params);

      if (month && year) {
        const fullDate = new Date(`${year}-${month}`);
        const firstDateOfMonth = format(fullDate, dateFormat);
        const lastDateOfMonth = format(lastDayOfMonth(fullDate), dateFormat);

        if (selectedData && _.isEqual(selectedData.date, [firstDateOfMonth, lastDateOfMonth])) {
          clearIsolation();
          onDateSelect?.(null);
        } else {
          onDateSelect?.({ date: [firstDateOfMonth, lastDateOfMonth], index: null, year });
          isolateOtherMonths({ month, year });
        }
      }
    },
    [clearIsolation, dateFormat, getMonthData, isolateOtherMonths, onDateSelect, selectedData],
  );

  const onChartMouseMove = useCallback(
    (params: ElementEvent) => {
      const { month, year } = getMonthData(params);
      if (!!month && !!year) {
        isolateOtherMonths({ month, year });
      }
    },
    [getMonthData, isolateOtherMonths],
  );

  const onChartMouseOut = useCallback(
    (params: ElementEvent) => {
      const { month, year } = getMonthData(params);
      if (!!month && !!year) {
        clearIsolation();
      }

      if (!selectedData) {
        return;
      }

      // Month selected
      if (!Number.isInteger(selectedData.index) && year) {
        const [date] = selectedData.date;

        if (!date) {
          return;
        }
        const month = new Date(date).getMonth();
        isolateOtherMonths({ month: month + 1, year: selectedData.year });
        return;
      }

      if (Number.isInteger(selectedData.index)) {
        isolateByIndex({ dataIndex: selectedData.index!, seriesName: selectedData.year });
      }
    },
    [clearIsolation, getMonthData, isolateByIndex, isolateOtherMonths, selectedData],
  );

  useEffect(() => {
    if (echartRef.current) {
      // We can't handle xAxis month click in calendar by standard echarts methods
      echartRef.current.getZr().on('click', onChartClick);
      echartRef.current.getZr().on('mousemove', onChartMouseMove);
      echartRef.current.getZr().on('mouseout', onChartMouseOut);
      echartRef.current.on('click', onDayCellClick);

      return () => {
        echartRef?.current?.getZr()?.off?.('click', onChartClick);
        echartRef?.current?.getZr()?.off?.('mousemove', onChartMouseMove);
        echartRef?.current?.getZr()?.off?.('mouseout', onChartMouseOut);
        echartRef?.current?.off?.('click', onDayCellClick);
      };
    }
  }, [onChartClick, onChartMouseMove, onChartMouseOut, onDayCellClick]);

  useEffect(() => {
    if (echartRef.current && options) {
      echartRef.current.setOption(options);
    }
  }, [options]);

  return (
    <Box position="relative" ref={ref}>
      <ReactECharts
        option={{}}
        style={{
          height: 370,
          opacity: loading ? 0.3 : 1,
        }}
        onChartReady={onChartReady}
      />
    </Box>
  );
};
