import { Stack, styled } from '@mui/material';

export const CardWrapper = styled(Stack)(({ theme }) => ({
  whiteSpace: 'nowrap',
  width: `calc(100% / 4 - ${theme.spacing(2)} * (4 - 1) / 4)`,
  minWidth: theme.spacing(31),
  border: theme.mixins.borderValue(),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  '&:hover': {
    background: theme.palette.common.lightBg,
    p: {
      color: theme.palette.primary.main,
    },
    '.MuiTypography-body1': {
      color: theme.palette.common.grey2,
    },
  },
}));
