import { Box, SxProps } from '@mui/material';
import {
  AssessmentEntryComment,
  AssessmentForGroup,
  AssessmentMethodComment,
  GET_ASSESSMENTS_FOR_RELATION_QUERY,
  useUpsertDeleteAssessmentEntryMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  DEFAULT_NOTIFICATION_DURATION_MS,
  useNotifications,
} from '@schooly/components/notifications';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, RefObject, useCallback, useEffect, useMemo, useState } from 'react';

import usePrevious from '../../../../hooks/usePrevious';
import { useWithRef } from '../../../../hooks/useWithRef';
import { UpdateAssessmentEntryProps } from '../../../../pages/Assessments/AssessmentMarkbookModal/AssessmentMarkbookGrid';
import { DropdownCommentsItem } from '../../DropdownComments/DropdownComments';
import { DropdownComments } from '../../DropdownCommentsV2/DropdownComments';

export interface CommentControllerProps {
  groupId: string;
  relationId: string;
  entries?: AssessmentEntryComment[];
  assessmentForGroup: AssessmentForGroup;
  method: AssessmentMethodComment;
  open?: boolean;
  plainCommentsListOnly?: boolean;
  iconOnly?: boolean;
  showAllComments?: boolean;
  onSetLocalEntries?: (entries?: AssessmentEntryComment[]) => void;
  onSuccess?: () => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
  menuProps?: SxProps;
  maxRows?: number;
  getParentRef?: () => RefObject<HTMLDivElement | HTMLSpanElement>;
  onToggle?: (val: boolean) => void;
  popoverMargin?: number;
  withoutBorderPosition?: boolean;
  tableView?: boolean;
  autoFocusOnEdit?: boolean;
  disableEscapeKeyDown?: boolean;
  saveOnEnterPress?: boolean;
  updateAssessmentEntry?: (p: UpdateAssessmentEntryProps) => void;
}

export const CommentController: FC<CommentControllerProps> = ({
  groupId,
  relationId,
  entries,
  assessmentForGroup,
  method,
  open,
  plainCommentsListOnly,
  iconOnly,
  showAllComments,
  onSetLocalEntries,
  onSuccess,
  onClick,
  onFocus,
  menuProps,
  maxRows,
  getParentRef,
  onToggle,
  popoverMargin,
  tableView,
  autoFocusOnEdit,
  disableEscapeKeyDown,
  saveOnEnterPress,
  updateAssessmentEntry,
}) => {
  const queryClient = useQueryClient();
  const { showError } = useNotifications();
  const { currentStaff, permissions } = useAuth();
  const canAdd = Boolean(method.method_id && assessmentForGroup.enterable_and_my_group);
  const canEditOwn = Boolean(
    method.method_id &&
      (permissions.includes('assessment_manager') || permissions.includes('assessment_creator')),
  );
  const canEditOther = Boolean(method.method_id && permissions.includes('assessment_manager'));
  const [localEntries, setLocalEntries] = useState(entries?.filter((entry) => !!entry.comment));
  const [error, setError] = useState<any>();

  const upsertDeleteAssessmentEntry = useUpsertDeleteAssessmentEntryMutation();
  const methodId = method.method_id;
  const { containerRef } = useWithRef();

  const prevEntries = usePrevious(entries);

  useEffect(() => {
    if (JSON.stringify(prevEntries) !== JSON.stringify(entries)) {
      setLocalEntries(entries?.filter((entry) => !!entry.comment));
    }
  }, [entries, prevEntries]);

  const handleSave = useCallback(
    async (comment: string, relation_id?: string) => {
      let shouldUpdate = false;

      if (relation_id) {
        // should update comment only if it's different from previous one
        const prevComment =
          localEntries?.find((entry) => entry.creator_relation_id === relation_id)?.comment ?? '';

        shouldUpdate = prevComment !== comment;
      } else {
        // should add new comment if it's not empty
        shouldUpdate = !!comment;
      }

      if (!shouldUpdate) {
        return;
      }

      const prevEntries = [...(localEntries ?? [])];
      setError(undefined);

      let newEntries: AssessmentEntryComment[] | undefined;

      if (comment) {
        // add/modify
        newEntries = relation_id
          ? localEntries?.map((entry) =>
              entry.creator_relation_id === relation_id ? { ...entry, comment } : entry,
            )
          : [
              ...(localEntries ?? []),
              {
                comment,
                method_id: methodId,
                method_type: method.method_type,
                creator_relation_id: currentStaff!.relation_id!,
                creator_title: currentStaff?.title,
                creator_given_name: currentStaff!.given_name,
                creator_last_name: currentStaff!.last_name,
                creator_known_as: currentStaff?.known_as,
              },
            ];
      } else {
        // delete
        newEntries = localEntries?.filter((entry) => entry.creator_relation_id !== relation_id);
      }

      setLocalEntries(newEntries);
      onSetLocalEntries?.(newEntries);
      if (!methodId) return;

      upsertDeleteAssessmentEntry.mutate(
        {
          method_id: methodId,
          recipient_relation_id: relationId,
          data: {
            group_id: groupId,
            comment: comment || null, // should set `null` to delete existing comment,
            creator_relation_id: relation_id ?? currentStaff?.relation_id,
          },
        },
        {
          onSuccess: (res) => {
            queryClient.invalidateQueries([GET_ASSESSMENTS_FOR_RELATION_QUERY, relationId]);

            updateAssessmentEntry?.({
              entry: res.assessment_entry,
              assessmentId: assessmentForGroup.id,
              methodId,
              studentId: relationId,
              comments: newEntries,
            });

            onSuccess?.();
          },
          onError: (err) => {
            console.error(err);
            setError(err);
            showError(err);

            setTimeout(() => {
              setError(undefined);
              setLocalEntries(prevEntries);
              onSetLocalEntries?.(prevEntries);
            }, DEFAULT_NOTIFICATION_DURATION_MS);
          },
        },
      );
    },
    [
      assessmentForGroup.id,
      currentStaff,
      groupId,
      localEntries,
      method.method_type,
      methodId,
      onSetLocalEntries,
      onSuccess,
      queryClient,
      relationId,
      showError,
      updateAssessmentEntry,
      upsertDeleteAssessmentEntry,
    ],
  );

  const comments: DropdownCommentsItem[] = useMemo(
    () =>
      localEntries?.map(
        ({
          comment,
          creator_relation_id,
          creator_given_name,
          creator_last_name,
          creator_known_as,
          creator_title,
        }) => ({
          comment,
          relation_id: creator_relation_id,
          given_name: creator_given_name,
          last_name: creator_last_name,
          known_as: creator_known_as,
          title: creator_title,
        }),
      ) ?? [],
    [localEntries],
  );

  return plainCommentsListOnly ? (
    <DropdownComments
      comments={comments}
      canAdd={canAdd}
      canEditOwn={canEditOwn}
      canEditOther={canEditOther}
      open={open}
      onAdd={handleSave}
      onEdit={handleSave}
      onClick={onClick}
      onToggle={onToggle}
      onFocus={onFocus}
      plainCommentsListOnly
      showAllComments={showAllComments}
      menuProps={menuProps}
      maxRows={maxRows}
      getParentRef={getParentRef}
      popoverMargin={popoverMargin}
      ref={containerRef}
    />
  ) : (
    <Box height="100%">
      {method.method_id && (
        <DropdownComments
          comments={comments}
          canAdd={canAdd}
          canEditOwn={canEditOwn}
          canEditOther={canEditOther}
          open={open}
          onAdd={handleSave}
          onEdit={handleSave}
          onClick={onClick}
          onFocus={onFocus}
          iconOnly={iconOnly}
          showAllComments={showAllComments}
          menuProps={menuProps}
          maxRows={maxRows}
          getParentRef={getParentRef}
          onToggle={onToggle}
          popoverMargin={popoverMargin}
          tableView={tableView}
          ref={containerRef}
          error={error}
          autoFocusOnEdit={autoFocusOnEdit}
          disableEscapeKeyDown={disableEscapeKeyDown}
          saveOnEnterPress={saveOnEnterPress}
        />
      )}
    </Box>
  );
};
