import { Icon } from '@mui/material';
import { CustomField } from '@schooly/api';
import {
  EditIcon,
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  RollBackIcon,
  SimpleButton,
} from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LongStringWithTooltip } from '../../../components/uikit-components/LongStringWithTooltip/LongStringWithTooltip';
import { APPLICABLE_TO_OPTIONS, TYPE_OPTIONS } from './constants';

interface SchoolCustomFieldsRowProps {
  field: CustomField;
  onRowClick?: () => void;
}

export const SchoolCustomFieldsRow: FC<SchoolCustomFieldsRowProps> = ({ field, onRowClick }) => {
  const { $t } = useIntl();

  const dataType = TYPE_OPTIONS.find((option) => option.value === field.data_type);

  return (
    <GridRowStyled
      noBorderRadius
      sx={{
        cursor: 'pointer',
        '&:hover': {
          '.editIconCell': {
            visibility: 'visible',
          },
        },
      }}
      onClick={onRowClick}
    >
      <GridRowItem sx={{ cursor: 'pointer' }}>
        <GridRowName sx={{ flex: '1 1 20%' }}>
          <LongStringWithTooltip>{field.label}</LongStringWithTooltip>
        </GridRowName>

        <GridRowName sx={{ flex: '1 1 10%' }} variant="body1">
          {field.applicable_to
            .map((role) => {
              const labelTextId = APPLICABLE_TO_OPTIONS.find(
                (option) => option.value === role,
              )?.labelTextId;

              return labelTextId
                ? $t({
                    id: labelTextId,
                  })
                : '';
            })
            .join(', ')}
        </GridRowName>

        <GridRowName sx={{ flex: '1 1 5%' }} variant="body1">
          <FormattedMessage id={field.required ? 'input-Required' : 'input-Optional'} />
        </GridRowName>

        <GridRowName sx={{ flex: '1 1 10%' }} variant="body1">
          {dataType && <FormattedMessage id={dataType.labelTextId} />}
        </GridRowName>

        <GridRowCell
          sx={{
            flex: '0 0 auto',
            visibility: field.archived ? 'visible' : 'hidden',
          }}
          className="editIconCell"
          align="right"
        >
          {field.archived ? (
            <SimpleButton startIcon={<RollBackIcon />}>{$t({ id: 'action-Restore' })}</SimpleButton>
          ) : (
            <Icon color="primary" sx={{ display: 'inline' }}>
              <EditIcon />
            </Icon>
          )}
        </GridRowCell>
      </GridRowItem>
    </GridRowStyled>
  );
};
