import { AssessmentStatuses } from '@schooly/constants';
import { CancelToken } from 'axios';

import { BaseComment } from './base';
import { IColumnSort } from './endpoints/people';
import { GroupBase, GroupType } from './groups';
import { StringOrNull } from './misc';
import { RecurringState } from './recurring';
import { ReportForAssessment } from './reports';
import { UserFilter } from './users';

export enum AssessmentMethodType {
  Score,
  Grade,
  Comment,
}

export enum AssessmentAction {
  Publish = 'publish',
  Unpublish = 'unpublish',
}

export interface AssessmentGroup extends GroupBase {}

export interface AssessmentMethodBase {
  method_id?: string;
  score_out_of?: number | null;
  select_list_id?: string | null;
  method_type: AssessmentMethodType | null;
}

export interface AssessmentMethodScore extends AssessmentMethodBase {
  method_type: AssessmentMethodType.Score;
  score_out_of: number | null;
  entries_max_score?: number;
}

export interface AssessmentMethodGrade extends AssessmentMethodBase {
  method_type: AssessmentMethodType.Grade;
  select_list_id: string;
  select_list_name: string;
}

export interface AssessmentMethodComment extends AssessmentMethodBase {
  method_type: AssessmentMethodType.Comment;
}

export interface AssessmentMethodBlank extends AssessmentMethodBase {
  method_type: null;
}

export type AssessmentMethod =
  | AssessmentMethodScore
  | AssessmentMethodGrade
  | AssessmentMethodComment;

export interface AssessmentBase {
  id: string;
  name: string;
  display_name?: string;
  assessment_status: AssessmentStatuses;
  assessment_date: string;
  status: AssessmentStatuses;
  publish_date: string | null;
  autogenerated: boolean;
  generated_from_report_id?: string;
  groups: AssessmentGroup[];
  methods: AssessmentMethod[];
  recurring_state: RecurringState | null;
}

export interface Assessment extends AssessmentBase {
  can_be_edited: boolean;
  group_ids: string[];
  reports?: ReportForAssessment[];
}

export interface AssessmentEntryScore {
  method_id?: string;
  method_type: AssessmentMethodType;
  score: number;
}

export interface AssessmentEntryGrade {
  method_id: string;
  method_type: AssessmentMethodType;
  select_list_option_id: string;
}

export interface AssessmentEntryComment extends BaseComment {
  method_id?: string;
  method_type: AssessmentMethodType;
}

export interface AssessmentEntriesForGroup {
  group_id: string;
  assessments: AssessmentForGroup[];
}

export type AssessmentEntry = AssessmentEntryScore | AssessmentEntryGrade | AssessmentEntryComment;

export interface AssessmentRecipientRelation {
  recipient_relation_id: string;
  entries: AssessmentEntry[];
}

export interface Assessment extends AssessmentBase {
  can_be_edited: boolean;
  group_ids: string[];
  reports?: ReportForAssessment[];
}

export interface AssessmentForSchool extends AssessmentBase {
  reports?: ReportForAssessment[];
}

export interface AssessmentForGroup extends AssessmentBase {
  enterable_and_my_group: boolean;
  relations: AssessmentRecipientRelation[];
  reports?: ReportForAssessment[];
}

export interface AssessmentEntriesForGroup {
  group_id: string;
  assessments: AssessmentForGroup[];
}

export interface AssessmentForRelation extends AssessmentBase {
  assessment_name?: StringOrNull;
  group_id: string;
  group_name: string;
  group_type?: GroupType;
  subject_name?: StringOrNull;
  subject_order?: number;
  subject_active?: boolean;
  subject_archive?: boolean;
  enterable_and_my_group: boolean;
  methods: (AssessmentMethod & { entries: AssessmentEntry[] })[];
  relations: AssessmentRecipientRelation[];
}

export interface AssessmentForReport {
  id: string;
  name: string;
  assessment_status: AssessmentStatuses;
  autogenerated: boolean;
  generated_from_report_id?: string;
  recurring_state?: RecurringState | null;
}

export interface AssessmentsGradeOption {
  id: string;
  name: string;
  order: number;
  active: boolean;
}

export interface AssessmentsGrade {
  id: string;
  name: string;
  description: string;
  active: boolean;
  archived: boolean;
  options: AssessmentsGradeOption[];
}

export interface ListAssessmentsForSchool {
  filters?: Partial<UserFilter>;
  page_number?: number;
  page_size?: number;
  school_id: string;
  sort?: IColumnSort<keyof Assessment>[];
  query?: string;
}

export interface ListAssessmentsForGroup {
  groupId: string;
  pageSize?: number;
  pageNumber?: number;
  dateFrom?: string;
  dateTo?: string;
  query?: string;
  sort?: IColumnSort<keyof AssessmentForGroup>[];
  token?: CancelToken;
}

export interface ListAssessmentsForRelation {
  relationId: string;
  reportId?: string;
  pageSize?: number;
  pageNumber?: number;
  dateFrom?: string;
  dateTo?: string;
  subjectIds?: string[];
  query?: string;
  sort?: IColumnSort<keyof AssessmentForRelation>[];
  token?: CancelToken;
}

export interface IUpsertDeleteAssessmentEntryBase {
  group_id: string;
}

export interface IUpsertDeleteAssessmentEntryScore extends IUpsertDeleteAssessmentEntryBase {
  score: number | null;
}

export interface IUpsertDeleteAssessmentEntryGrade extends IUpsertDeleteAssessmentEntryBase {
  select_list_option_id?: StringOrNull;
}

export interface IUpsertDeleteAssessmentEntryComment extends IUpsertDeleteAssessmentEntryBase {
  comment: StringOrNull;
  creator_relation_id: string;
}

export type IUpsertDeleteAssessmentEntry =
  | IUpsertDeleteAssessmentEntryScore
  | IUpsertDeleteAssessmentEntryGrade
  | IUpsertDeleteAssessmentEntryComment;
