import { FilterKeys, FilterValue, GroupUserType, SelectOption } from '@schooly/api';

export enum FilterType {
  Default = 'default',
  Parent = 'parent',
  UserRoles = 'user-roles',
  Applications = 'applications',
  SidebarGroups = 'sidebar-groups',
  SidebarReportRecipients = 'sidebar-report-recipients',
  SidebarGroupStaff = 'sidebar-group-staff',
  SidebarGroupStudents = 'sidebar-group-students',
  SidebarRelationGroups = 'sidebar-relation-groups',
  SidebarUserRoleStaff = 'sidebar-user-role-staff',
  SidebarAssessmentGroups = 'sidebar-assessment-groups',
}

export interface AppliedGroupFilters {
  key: FilterKeys;
  values: Partial<{ [key in GroupUserType]: FilterValue[] }>;
}

export type GroupArrangeBySection = GroupUserType | 'common';

export type GroupArrangeByOption = {
  section: GroupArrangeBySection;
  option: SelectOption<FilterKeys>;
};
