import type { Location } from 'history';
import { useLocation } from 'react-router-dom';

export interface RouterStateReconcile {
  contextName?: string;
  state?: string;
}

export interface AppLocation extends Location {
  state: {
    backgroundLocation?: Location;
    prevPathname?: string;
    replace?: boolean;
    reconcile?: RouterStateReconcile;
  };
}

const useAppLocation = <T extends AppLocation = AppLocation>() => {
  return useLocation() as T;
};

export default useAppLocation;
