import { ChipProps } from '@mui/material';
import { useGetGroupDetailsQuery } from '@schooly/api';
import { Spin, TagSelect } from '@schooly/style';
import { FC } from 'react';

export type GroupTagSelectProps = {
  id: string;
} & Omit<ChipProps, 'label'>;

export const GroupTagSelect: FC<GroupTagSelectProps> = ({ id, ...props }) => {
  const { data } = useGetGroupDetailsQuery({ id });

  return <TagSelect label={data?.group ? data.group.name : <Spin />} {...props} />;
};
