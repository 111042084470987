import { Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  isSignUpSlots,
  MAX_PAGE_SIZE,
  SignUpStatuses,
  useGetSignUpRespondentsQuery,
} from '@schooly/api';
import {
  BaseCalendarRecord,
  CalendarRecord,
  CalendarRecordActive,
  CalendarRecordTypes,
  CalendarWeek,
} from '@schooly/components/calendar';
import { Loading } from '@schooly/style';
import { addMinutes, eachDayOfInterval, endOfDay, isWithinInterval, parse } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { useSignUp } from '../../../../context/signUps/WithSignUp';
import { SignUpPreviewRespondentsRecordPopup } from './SignUpPreviewRespondentsRecordPopup';

export const SignUpPreviewRespondentsCalendar: FC = () => {
  const { signUp, debouncedQuery } = useSignUp();
  const [selectedRecord, setSelectedRecord] = useState<CalendarRecordActive>();

  const { data, isInitialLoading } = useGetSignUpRespondentsQuery(
    { id: signUp?.id ?? '', pageSize: MAX_PAGE_SIZE, query: '' },
    { enabled: Boolean(signUp && signUp.signed_up_count) },
  );

  const { data: searchData, setParams } = useGetSignUpRespondentsQuery(
    { id: signUp?.id ?? '', pageSize: MAX_PAGE_SIZE, query: debouncedQuery },
    { enabled: Boolean(signUp && signUp.signed_up_count) },
  );

  useEffect(() => {
    setParams((p) => ({ ...p, query: debouncedQuery }));
  }, [debouncedQuery, setParams]);

  const searchDataMap = useMemo(
    () =>
      searchData?.pages[0].results.reduce<Record<string, boolean>>(
        (prev, curr) => ({
          ...prev,
          [`${curr.parent.relation_id}-${curr.student.relation_id}`]: true,
        }),
        {},
      ) ?? {},
    [searchData?.pages],
  );

  const showTimeline = useMemo(
    () =>
      signUp?.event && signUp.status === SignUpStatuses.Open
        ? isWithinInterval(new Date(), {
            start: parse(signUp.event.start, DEFAULT_DATE_FORMAT_FNS, new Date()),
            end: endOfDay(parse(signUp.event.end, DEFAULT_DATE_FORMAT_FNS, new Date())),
          })
        : false,
    [signUp?.event, signUp?.status],
  );

  const records = useMemo<CalendarRecord[]>(() => {
    if (!signUp?.event || !isSignUpSlots(signUp) || !signUp.event.date_times.length) {
      return [];
    }

    const start = parse(signUp.event.start, DEFAULT_DATE_FORMAT_FNS, new Date());
    const end = parse(signUp.event.end, DEFAULT_DATE_FORMAT_FNS, new Date());

    const slots = eachDayOfInterval({ start, end }).map((date, index) => ({
      date,
      type: CalendarRecordTypes.Slot,
      time: [
        signUp.event!.date_times[index] ?? signUp.event!.date_times[0],
      ] as BaseCalendarRecord['time'], // just an array of one item as Events don't support interval gaps yet
    }));

    const active =
      data?.pages[0].results.reduce<CalendarRecordActive[]>((prev, respondent) => {
        if (!respondent.time_slot) {
          return prev;
        }

        if (!respondent.time_slot.date || !respondent.time_slot.start) {
          return prev;
        }

        const start = parse(
          `${respondent.time_slot.date} ${respondent.time_slot.start}`,
          `${DEFAULT_DATE_FORMAT_FNS} HH:mm`,
          new Date(),
        );
        const end = addMinutes(start, signUp.duration);

        const item: CalendarRecordActive = {
          type: CalendarRecordTypes.Active,
          date: respondent.time_slot.date,
          time: [[start, end]],
        };

        prev.push({
          ...item,
          shadowed:
            !searchDataMap[`${respondent.parent.relation_id}-${respondent.student.relation_id}`],
          tooltip:
            selectedRecord &&
            JSON.stringify(item) ===
              JSON.stringify({
                type: selectedRecord.type,
                date: selectedRecord.date,
                time: selectedRecord.time,
              })
              ? {
                  title: (
                    <SignUpPreviewRespondentsRecordPopup
                      respondent={respondent}
                      onClose={() => setSelectedRecord(undefined)}
                    />
                  ),
                  PopperProps: {
                    sx: { '& .MuiTooltip-tooltip': { maxWidth: 'unset', p: 0 } },
                  },
                  open: true,
                }
              : undefined,
          onClick: (event, record) => {
            setSelectedRecord(record);
          },
        } as CalendarRecordActive);

        return prev;
      }, []) ?? [];

    return [...slots, ...active];
  }, [data?.pages, searchDataMap, selectedRecord, signUp]);

  if (!signUp?.event || !isSignUpSlots(signUp)) {
    return null;
  }

  return (
    <Stack flex="1 1 auto" overflow="hidden" mt={2.5}>
      {isInitialLoading ? (
        <Loading />
      ) : (
        <CalendarWeek
          records={records}
          startDate={signUp.event.start}
          minDate={signUp.event.start}
          maxDate={signUp.event.end}
          showTimeline={showTimeline}
        />
      )}
    </Stack>
  );
};
