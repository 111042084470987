import { SchoolYear } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PRODUCTS_YEAR_ID_PARAM } from '@schooly/constants';
import { Loading, ModalLarge } from '@schooly/style';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../../../components/common/AccessDenied';
import { useProduct } from '../../../../context/product/WithProduct';
import { ViewState } from '../SchoolProductCreateModal/SchoolProductCreateModalContent';
import { SchoolProductModalContent } from './SchoolProductModalContent';

export const SchoolProductModal: FC = () => {
  const { schoolId = '' } = useAuth();
  const navigate = useNavigate();

  const { id, product, isFetching, canView, canEdit } = useProduct();
  const close = useCallback(() => navigate('/settings/products'), [navigate]);
  const onEdit = useCallback(() => navigate(`/settings/products/${id}/edit`), [navigate, id]);
  const onEditVariants = useCallback(
    (yearId?: SchoolYear['id']) =>
      navigate(
        `/settings/products/${id}/edit?${ViewState.Variants}&${PRODUCTS_YEAR_ID_PARAM}=${yearId}`,
      ),
    [navigate, id],
  );

  if (isFetching) {
    return (
      <ModalLarge open onClose={close}>
        <Loading />
      </ModalLarge>
    );
  }

  if (!product || !canView) {
    return (
      <ModalLarge open onClose={close}>
        <AccessDenied />
      </ModalLarge>
    );
  }

  return (
    <SchoolProductModalContent
      schoolId={schoolId}
      product={product}
      onClose={close}
      onEdit={canEdit ? onEdit : undefined}
      onEditVariants={canEdit ? onEditVariants : undefined}
    />
  );
};
