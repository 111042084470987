import { Stack, styled } from '@mui/material';

export const EditableRow = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),

  '&:not(:hover) .MuiIconButton-root': {
    display: 'none',
  },
}));
