import { Box, Tooltip } from '@mui/material';
import { FC, PropsWithChildren, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import theme from '../../../theme/theme';

interface LongTitleVerticalTooltipProps extends PropsWithChildren {
  renderTooltipContent?: (longName: React.ReactNode) => React.ReactNode;
  getOffset?: (height: number) => number[];
  withoutArrow?: boolean;
}

export const LongTitleVerticalTooltip: FC<LongTitleVerticalTooltipProps> = ({
  children,
  renderTooltipContent,
  getOffset,
  withoutArrow,
}) => {
  const [truncated, setTruncated] = useState(false);
  const [rect, setRect] = useState<DOMRect>();

  const { ref } = useResizeDetector<HTMLDivElement>({
    onResize: () => {
      const isTruncated = !!ref?.current && ref.current.scrollWidth > ref.current.clientWidth;
      setTruncated(isTruncated);

      const rect = ref.current?.getBoundingClientRect();
      setRect(rect);
    },
  });

  const width = rect?.width ?? 0;
  const height = rect?.height ?? 0;

  return (
    <Tooltip
      TransitionProps={{ timeout: 450 }}
      arrow={!withoutArrow}
      componentsProps={{
        tooltip: {
          sx: () => ({
            backgroundColor: theme.palette.background.paper,
            // 16 padding in tooltip
            maxWidth: `${width + 16}px`,
            boxShadow:
              '0px 2px 4px 1px rgba(36, 39, 91, 0.1), 0px 2px 10px 2px rgba(36, 39, 91, 0.1)',
            borderRadius: `${theme.shape.borderRadiusSm}px`,
            margin: '0 !important',
          }),
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // 4 padding in tooltip
              offset: getOffset ? getOffset(height) : [0, -(height + 4)],
            },
          },
        ],
      }}
      title={renderTooltipContent?.(children) ?? <>{children}</>}
      disableHoverListener={!truncated}
    >
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        ref={ref}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
