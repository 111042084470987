import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4C3 3.44772 3.44772 3 4 3H12C12.5523 3 13 3.44772 13 4V8V13H8H5H4C3.44772 13 3 12.5523 3 12V4ZM8 14V17C8 17.5523 8.44772 18 9 18H17C17.5523 18 18 17.5523 18 17V13V9C18 8.44772 17.5523 8 17 8H14V14H13H8Z"
      fill="#B3BBCB"
    />
    <path
      d="M7.32568 9.19775H5.25098L4.7793 10.5H4.0957L5.99609 5.52344H6.43359H6.57715L8.48096 10.5H7.80078L7.32568 9.19775ZM7.13086 8.66113L6.28662 6.33691L5.4458 8.66113H7.13086Z"
      fill="white"
    />
    <path
      d="M11.9416 9.69336C11.9416 9.82324 11.953 9.95768 11.9758 10.0967C11.9985 10.2334 12.0304 10.3496 12.0715 10.4453V10.5H11.405C11.3662 10.4111 11.3366 10.2824 11.3161 10.1138C11.1885 10.2505 11.0347 10.361 10.8547 10.4453C10.6769 10.5273 10.481 10.5684 10.2668 10.5684C10.0207 10.5684 9.80193 10.5205 9.61053 10.4248C9.4214 10.3268 9.27443 10.1947 9.16961 10.0283C9.06707 9.86198 9.0158 9.67741 9.0158 9.47461C9.0158 9.21257 9.08302 8.9904 9.21746 8.80811C9.35418 8.62581 9.54673 8.48909 9.7951 8.39795C10.0457 8.30452 10.3408 8.25781 10.6804 8.25781H11.3024V7.96387C11.3024 7.73828 11.2352 7.56169 11.1008 7.43408C10.9686 7.3042 10.7772 7.23926 10.5265 7.23926C10.3739 7.23926 10.2372 7.2666 10.1164 7.32129C9.99562 7.3737 9.90105 7.44548 9.8327 7.53662C9.76661 7.62549 9.73357 7.72233 9.73357 7.82715H9.09441C9.09441 7.64714 9.15594 7.47282 9.27898 7.3042C9.40203 7.13558 9.57521 6.99886 9.79852 6.89404C10.0218 6.78695 10.277 6.7334 10.5641 6.7334C10.8376 6.7334 11.0768 6.78011 11.2819 6.87354C11.4893 6.96468 11.6511 7.10368 11.7673 7.29053C11.8835 7.4751 11.9416 7.70182 11.9416 7.9707V9.69336ZM10.3591 10.0215C10.5049 10.0215 10.6405 9.99642 10.7658 9.94629C10.8934 9.89388 11.0028 9.82552 11.0939 9.74121C11.1851 9.6569 11.2546 9.56576 11.3024 9.46777V8.70215H10.7829C10.4183 8.70215 10.1392 8.76025 9.94549 8.87646C9.7518 8.9904 9.65496 9.16016 9.65496 9.38574C9.65496 9.50651 9.6823 9.61475 9.73699 9.71045C9.79168 9.80615 9.87143 9.88249 9.97625 9.93945C10.0811 9.99414 10.2087 10.0215 10.3591 10.0215Z"
      fill="white"
    />
  </svg>
);
