import { DEFAULT_DATE_FORMAT_FNS, SchoolYearInUse } from '@schooly/api';
import { addDays, addYears, format, isBefore, subDays } from 'date-fns';

export const getSchoolYearInUseLabelId = (key: keyof SchoolYearInUse) => {
  switch (key) {
    case 'groups':
      return 'school-tabs-SchoolYears-InUse-Groups';
    case 'has_started':
      return 'school-tabs-SchoolYears-InUse-HasStarted';
    case 'periods':
      return 'school-sections-SchoolPeriods';
    case 'registrations_with_current_status':
      return 'school-tabs-SchoolYears-InUse-CurrentRegistrations';

    default:
      return '';
  }
};

export function incrementDay(date: Date) {
  return addDays(date, 1);
}
export function decrementDay(date: Date) {
  return subDays(date, 1);
}

export function getInUseCount(
  key: keyof Omit<SchoolYearInUse, 'has_started'>,
  yearInUser: SchoolYearInUse,
): number {
  return key === 'registrations_with_current_status' ? yearInUser[key].count : yearInUser[key];
}

export function getSchoolYearEndDate(startDate: Date) {
  return subDays(addYears(startDate, 1), 1);
}

// Full object needed (empty strings required instead of undefined) to prevent `append` bug happen
// https://github.com/react-hook-form/react-hook-form/issues/7165
export function getEmptySchoolYear() {
  return {
    id: '',
    originId: '',
    name: '',
    start: '',
    end: '',
    can_be_deleted: true,
  };
}

export function getSchoolYearFromGap(prevYearEndDate: Date, nextYearStartDate: Date) {
  const yearStartDate = incrementDay(prevYearEndDate);

  const yearEndDate = getSchoolYearEndDate(yearStartDate);

  return isBefore(yearEndDate, nextYearStartDate)
    ? {
        ...getEmptySchoolYear(),
        start: format(yearStartDate, DEFAULT_DATE_FORMAT_FNS),
        end: format(yearEndDate, DEFAULT_DATE_FORMAT_FNS),
      }
    : null;
}
