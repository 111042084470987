import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppLocation } from '../../hooks/useAppLocation';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices/routerSlice';
import { RouterContext } from './RouterContext';
import { RouterStateContext } from './RouterStateContext';

export const useRouter = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { stack } = useAppSelector((state) => state.router);
  const context = useContext(RouterContext);
  const { contextName, state } = useContext(RouterStateContext);

  const lastStackItem = stack.length > 0 ? stack[stack.length - 1] : undefined;
  const firstStackItem = stack.length > 0 ? stack[0] : undefined;

  const push = useCallback(
    (location: AppLocation) => {
      dispatch(
        actions.push({
          location,
          state: {
            contextName,
            state: JSON.stringify(state) as unknown as { originalData?: unknown },
          },
        }),
      );
    },
    [dispatch, contextName, state],
  );

  const pop = useCallback(() => {
    dispatch(actions.pop());
  }, [dispatch]);

  const clean = useCallback(() => {
    dispatch(actions.clean());
  }, [dispatch]);

  const replaceLast = useCallback(
    (location: AppLocation) => {
      dispatch(
        actions.replaceLast({
          location,
          state: {
            contextName,
            state: JSON.stringify(state) as unknown as { originalData?: unknown },
          },
        }),
      );
    },
    [contextName, dispatch, state],
  );

  /**
   * Navigates back through the stack.
   */
  const goBack = useCallback(() => {
    if (stack.length > 1) {
      const item = stack[stack.length - 2];
      const { state } = item.location;
      navigate(item.location, {
        state: {
          ...state,
          replace: true,
          reconcile: item.state,
        },
      });
    } else {
      navigate(context.getBackground().path);
    }
  }, [stack, navigate, context]);

  /**
   * Navigates to the first background page in the stack and cleans the stack
   */
  const closeAndClean = useCallback(() => {
    if (stack.length > 1) {
      navigate(context.getBackground(stack[0].location).path);
    } else {
      navigate(context.getBackground().path);
    }

    clean();
  }, [stack, clean, navigate, context]);

  return {
    stack,
    lastStackItem,
    firstStackItem,
    push,
    pop,
    clean,
    replaceLast,
    goBack,
    closeAndClean,
    ...context,
  };
};
