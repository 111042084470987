import { Button, IconButton, Stack } from '@mui/material';
import { School } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { COUNTRY_OPTIONS } from '@schooly/constants';
import { CheckIcon, CrossIcon, Spin } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import FormSelect2 from '../../../../components/ui/Input/FormSelect2';
import { ControlTextField } from '../../../../components/uikit-components/FormTextField/ControlTextField';
import {
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalMain,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { useRouter } from '../../../../context/router/useRouter';
import { useSchool } from '../../../../hooks/useSchool';
import getTypedObjectKeys from '../../../../utils/getTypedObjectKeys';
import { SchoolSettingsLayout } from '../../SchoolSettingsLayout';

export interface SchoolGeneralAddressForm {
  address_line_1: School['address_line_1'];
  address_line_2: School['address_line_2'];
  city: School['city'];
  region: School['region'];
  zip_code: School['zip_code'];
  country: School['country'];
}

export const SchoolGeneralAddressModal: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { goBack } = useRouter();
  const { currentSchool, updateSchoolMutation } = useSchool();
  const form = useForm<SchoolGeneralAddressForm>({
    defaultValues: {
      address_line_1: currentSchool?.address_line_1,
      address_line_2: currentSchool?.address_line_2,
      city: currentSchool?.city,
      region: currentSchool?.region,
      zip_code: currentSchool?.zip_code,
      country: currentSchool?.country,
    },
  });
  // const updateSchool = useUpdateSchoolMutation();
  const handleClose = useCallback(() => {
    if (updateSchoolMutation.isLoading) {
      return;
    }

    goBack();
  }, [goBack, updateSchoolMutation.isLoading]);

  const handleSubmit = useCallback<SubmitHandler<SchoolGeneralAddressForm>>(
    async (data) => {
      if (!currentSchool) {
        return;
      }

      const params = getTypedObjectKeys(data).reduce<Partial<Omit<School, 'id'>>>(
        (acc, key) => ({ ...acc, [key]: data[key] ?? null }),
        {},
      );

      updateSchoolMutation.mutate(
        {
          school_id: currentSchool.id,
          ...params,
        },
        {
          onError: showError,
          onSuccess: goBack,
        },
      );
    },
    [currentSchool, goBack, showError, updateSchoolMutation],
  );

  return (
    <SchoolSettingsLayout>
      <ModalConfirm open fullWidth onClose={handleClose}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader title={currentSchool?.name} active>
              <IconButton
                sx={updateSchoolMutation.isLoading ? { visibility: 'hidden' } : undefined}
                onClick={handleClose}
              >
                <CrossIcon />
              </IconButton>
            </ModalHeader>
            <ModalMain>
              <ModalContent active>
                <Stack gap={2}>
                  <ControlTextField
                    name="address_line_1"
                    control={form.control}
                    label={$t({ id: 'school-create-AddressLine1' })}
                    rules={{ required: true }}
                    fullWidth
                    canClear
                  />
                  <ControlTextField
                    name="address_line_2"
                    control={form.control}
                    label={$t({ id: 'school-create-AddressLine2' })}
                    fullWidth
                    canClear
                  />
                  <Stack flexDirection="row" gap={2}>
                    <ControlTextField
                      name="city"
                      control={form.control}
                      label={$t({ id: 'school-create-City' })}
                      rules={{ required: true }}
                      fullWidth
                      canClear
                    />
                    <ControlTextField
                      name="region"
                      control={form.control}
                      label={$t({ id: 'school-create-Region' })}
                      fullWidth
                      canClear
                    />
                  </Stack>
                  <Stack
                    flexDirection="row"
                    gap={2}
                    sx={(theme) => ({
                      '.form-select-search': {
                        paddingLeft: theme.spacing(1.75),
                      },
                      '.form-select-value-wrapper': {
                        left: theme.spacing(1.5),
                      },
                      '.form-select-arrow, .form-select-remove': {
                        right: theme.spacing(1.5),
                      },
                      '.form-group__label': {
                        left: theme.spacing(1.5),
                      },
                      '.form-select-value-text': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    })}
                  >
                    <FormSelect2
                      id="school-edit-country"
                      name="country"
                      labelTextId="school-create-Country"
                      options={COUNTRY_OPTIONS}
                      rules={{ required: true }}
                    />
                    <ControlTextField
                      name="zip_code"
                      control={form.control}
                      label={$t({ id: 'school-create-ZipCode' })}
                      rules={{ required: true }}
                      fullWidth
                      canClear
                    />
                  </Stack>
                </Stack>
              </ModalContent>
            </ModalMain>
            <ModalFooter withBorderTop={false} active>
              <Button
                variant="outlined"
                disabled={updateSchoolMutation.isLoading}
                onClick={handleClose}
              >
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={updateSchoolMutation.isLoading}
                endIcon={updateSchoolMutation.isLoading ? <Spin /> : <CheckIcon />}
              >
                <FormattedMessage id="action-Save" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalConfirm>
    </SchoolSettingsLayout>
  );
};
