import { DATE_FORMAT_SHORT_FNS, GroupType } from '@schooly/api';
import {
  AssessmentEntryComment,
  AssessmentForRelation,
  AssessmentMethodComment,
  AssessmentMethodGrade,
  AssessmentMethodScore,
  AssessmentMethodType,
  AssessmentsGrade,
} from '@schooly/api';
import { GridCell, TagSubject } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import AssessmentStatuses from '../../../constants/assessmentStatuses';
import useAppLocation from '../../../hooks/useAppLocation';
import usePrevious from '../../../hooks/usePrevious';
import { TagGroup } from '../../uikit/TagGroup/TagGroup';
import { AssessmentEntryMethod } from '../AssessmentEntryMethods/AssessmentEntryMethod';
import { CommentController } from '../AssessmentEntryMethods/methods/CommentController';
import { CommentRowCell } from '../DropdownCommentsV2/DropdownCommentsWrappers/CommentRowCell';
import { CommentTableCell } from '../DropdownCommentsV2/DropdownCommentsWrappers/CommentTableCell';
import { GridRowDate, GridRowItem, GridRowStyled } from '../Grid/Grid';
import {
  ListViewAssessmentsNameForRelation,
  ListViewAssessmentsStatus,
  ListViewAssessmentsStatusPublished,
  ListViewAssessmentsTags,
  ListViewExtraRow,
} from './ListViewAssessmentsRow.styled';

const METHOD_CELL_WIDTH = 112;

export interface ListViewAssessmentsRowForRelationProps {
  assessment: AssessmentForRelation;
  lists?: Record<string, AssessmentsGrade>;
  relationId: string;
  showAllComments?: boolean;
  wideComments?: boolean;
  isCommentsOpen?: boolean;
  onOpenComments?: (id: string) => void;
  onCloseComments?: (id: string) => void;
  addAssessmentWithComment?: (id: string) => void;
}

export const ListViewAssessmentsRowForRelation: FC<ListViewAssessmentsRowForRelationProps> = ({
  assessment,
  lists,
  relationId,
  showAllComments,
  wideComments,
  isCommentsOpen,
  onOpenComments,
  onCloseComments,
  addAssessmentWithComment,
}) => {
  const location = useAppLocation();

  const prevShowAllComments = usePrevious(showAllComments);

  // Transform AssessmentForRelation to a AssessmentForGroup with entries
  // to be able to reuse AssessmentEntryMethods component
  const assessmentForGroup = useMemo(() => {
    return {
      ...assessment,
      // There all 3 methods should be presented
      methods: [
        {
          ...assessment.methods.find((method) => method.method_type === AssessmentMethodType.Score),
          method_type: AssessmentMethodType.Score,
        } as AssessmentMethodScore,
        {
          ...assessment.methods.find((method) => method.method_type === AssessmentMethodType.Grade),
          method_type: AssessmentMethodType.Grade,
        } as AssessmentMethodGrade,
        {
          ...assessment.methods.find(
            (method) => method.method_type === AssessmentMethodType.Comment,
          ),
          method_type: AssessmentMethodType.Comment,
        } as AssessmentMethodComment,
      ],
      groupRelations: assessment.relations,
      relations: [
        {
          recipient_relation_id: relationId,
          entries: assessment.methods.map((method) => ({
            ...method,
            ...method.entries[0],
          })),
        },
      ],
    };
  }, [assessment, relationId]);

  const [commentMethod, commentEntries] = useMemo(() => {
    const relation = assessmentForGroup?.relations.find(
      (item) => item.recipient_relation_id === relationId,
    );

    const commentMethod = assessmentForGroup.methods.find(
      (item) => item.method_type === AssessmentMethodType.Comment,
    ) as AssessmentMethodComment | undefined;

    const commentEntries = ((commentMethod &&
      relation?.entries?.filter(
        (item) =>
          commentMethod.method_id === item.method_id &&
          item.method_type === AssessmentMethodType.Comment,
      )) ??
      []) as AssessmentEntryComment[];

    return [commentMethod, commentEntries];
  }, [assessmentForGroup.methods, assessmentForGroup?.relations, relationId]);

  const [localEntries, setLocalEntries] = useState<AssessmentEntryComment[] | undefined>(
    commentEntries?.filter((entry) => !!entry.comment),
  );

  const hasComments = Boolean(localEntries?.length);
  const canAdd = Boolean(commentMethod?.method_id && assessmentForGroup.enterable_and_my_group);
  const canOpen = canAdd || hasComments;

  const onCommentsIconClick = useMemo(() => {
    if (!canOpen) return;
    if (isCommentsOpen) {
      return () => onCloseComments?.(assessment.id);
    }
    return () => onOpenComments?.(assessment.id);
  }, [assessment.id, canOpen, isCommentsOpen, onCloseComments, onOpenComments]);

  const wideCommentControllerProps = {
    entries: localEntries,
    iconOnly: true,
    open: isCommentsOpen,
    onClick: onCommentsIconClick,
    onFocus: () => {},
    onBlur: () => {},
  };

  useEffect(() => {
    if (!prevShowAllComments && showAllComments && hasComments) {
      onOpenComments?.(assessment.id);
    }

    if (prevShowAllComments && !showAllComments) {
      onCloseComments?.(assessment.id);
    }
  }, [
    assessment.id,
    hasComments,
    onCloseComments,
    onOpenComments,
    prevShowAllComments,
    showAllComments,
  ]);

  useEffect(() => {
    if (hasComments) {
      addAssessmentWithComment?.(assessment.id);
    }
  }, [addAssessmentWithComment, assessment.id, hasComments]);

  return (
    <GridRowStyled>
      <GridRowItem
        sx={(theme) => ({
          height: theme.spacing(5.5),
          pr: 0,
        })}
        noVerticalPadding
      >
        <GridRowDate>
          {format(newDateTimezoneOffset(assessment.assessment_date), DATE_FORMAT_SHORT_FNS)}
        </GridRowDate>
        <ListViewAssessmentsTags>
          {assessment.group_name && <TagGroup>{assessment.group_name}</TagGroup>}
          {assessment.group_type === GroupType.TutorGroup || assessment.subject_name ? (
            <TagSubject archived={assessment.subject_archive}>
              {assessment.group_type === GroupType.TutorGroup ? (
                <FormattedMessage id="groups-TutorGroup" />
              ) : (
                assessment.subject_name
              )}
            </TagSubject>
          ) : null}
        </ListViewAssessmentsTags>
        <ListViewAssessmentsStatus>
          {assessment.status === AssessmentStatuses.Published && (
            <ListViewAssessmentsStatusPublished />
          )}
        </ListViewAssessmentsStatus>
        <ListViewAssessmentsNameForRelation>
          <Link
            to={`/assessments/${assessment.id}${
              assessment.group_id ? `?group_id=${assessment.group_id}` : ''
            }`}
            state={{
              prevPathname: `${location.pathname}${location.hash ?? ''}`,
            }}
          >
            {assessment.name}
          </Link>
        </ListViewAssessmentsNameForRelation>
        {assessmentForGroup.methods
          // TODO: why it was added?
          //.filter((m) => !!m.method_id)
          .map((method) => {
            const isEmpty = !method.method_id;
            const isMethodComment = method.method_type === AssessmentMethodType.Comment;
            const canComment = assessmentForGroup.groupRelations.some(
              (relation) => relation.recipient_relation_id === relationId,
            );

            if (isMethodComment && !canComment) {
              return null;
            }

            if (isMethodComment) {
              return (
                <CommentTableCell
                  key={method.method_id}
                  borderLeft
                  borderTopRightRadius
                  borderBottomRightRadius
                  empty={isEmpty}
                  sx={(theme) => ({
                    minWidth: METHOD_CELL_WIDTH,
                    height: '100%',
                    '.GridRow:hover &, .GridRowItem:hover &': {
                      backgroundColor: theme.palette.background.default,
                    },
                  })}
                >
                  {(onToggle, cellRef) => (
                    <AssessmentEntryMethod
                      method={method}
                      assessmentForGroup={assessmentForGroup}
                      lists={lists}
                      relationId={relationId}
                      groupId={assessment.group_id}
                      commentControllerProps={
                        wideComments
                          ? wideCommentControllerProps
                          : {
                              onToggle,
                              getParentRef: () => cellRef,
                              tableView: true,
                            }
                      }
                    />
                  )}
                </CommentTableCell>
              );
            }

            return (
              <GridCell
                key={method.method_id}
                borderLeft
                noPadding
                empty={isEmpty}
                sx={(theme) => ({
                  minWidth: METHOD_CELL_WIDTH,
                  height: '100%',
                  '.GridRow:hover &, .GridRowItem:hover &': {
                    backgroundColor: theme.palette.background.default,
                  },
                })}
              >
                <AssessmentEntryMethod
                  method={method}
                  assessmentForGroup={assessmentForGroup}
                  lists={lists}
                  relationId={relationId}
                  groupId={assessment.group_id}
                  commentControllerProps={wideComments ? wideCommentControllerProps : undefined}
                />
              </GridCell>
            );
          })}
      </GridRowItem>
      {wideComments && commentMethod && isCommentsOpen && (
        <ListViewExtraRow>
          <GridRowDate />
          <CommentRowCell rowStyleProps={{ width: '100%', border: 'none' }}>
            {(onToggle, cellRef) => (
              <CommentController
                relationId={relationId}
                groupId={assessment.group_id}
                entries={localEntries}
                // https://treehive.atlassian.net/browse/TR-2119:
                //
                // Sync comments update emits further CommentController remount
                // what might loos some simultaneous pointer events
                //
                // Postpone the process to the macrotasks stack to yield synchronous pointer events first
                onSetLocalEntries={(entries) => setTimeout(() => setLocalEntries(entries), 300)}
                method={commentMethod}
                assessmentForGroup={assessmentForGroup}
                showAllComments={showAllComments}
                plainCommentsListOnly
                popoverMargin={2}
                onToggle={onToggle}
                getParentRef={() => cellRef}
                withoutBorderPosition
              />
            )}
          </CommentRowCell>
        </ListViewExtraRow>
      )}
    </GridRowStyled>
  );
};
