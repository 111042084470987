import { styled } from '@mui/material';

import {
  ModalContent,
  ModalPanel,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { $UIKitPrefix } from '../../../../styles/variables';

export const MessagesCreateModalRecipientsRightSidebarContent = styled(ModalContent)(
  ({ theme }) => ({
    flex: '1 1 100%',
    overflow: 'hidden',

    [theme.breakpoints.down('lg')]: {
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(4),
    },

    [`& .${$UIKitPrefix}Dropdown`]: {
      flex: '0 0 auto',
      padding: 0,
      margin: theme.spacing(-1, 0, 1),

      '&__port': {
        width: 220,
      },
    },
  }),
);

export const MessageCreateModalRecipientTypePanel = styled(ModalPanel)(({ theme }) => ({
  margin: theme.spacing(0, -2.5),

  [theme.breakpoints.down('lg')]: {
    p: theme.spacing(2.5),
    margin: 0,
  },
}));
