import { UserType } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { MinusIcon, PlusIcon, TickIcon } from '@schooly/style';
import React, { PropsWithChildren } from 'react';

import { getSchoolUserId } from '../../../helpers/school';
import buildClassName from '../../../utils/buildClassName';
import PersonCardBasic, { PersonCardBasicProps } from './PersonCardBasic';

interface PersonCardSelectableProps extends PropsWithChildren {
  user: PersonCardBasicProps['user'];
  userType?: UserType;
  isStaticSelected?: boolean;
  isSingularSelected?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  isUsernameClickable?: boolean;
  isUsernameLinkView?: boolean;
  className?: string;
  onClick?: (userId: string) => void;
  profileSchoolContext?: SchoolUserRole;
  marked?: boolean;
  isListItem?: boolean;
  renderCustomIcon?: React.ReactNode;
}

const PersonCardSelectable: React.FC<PersonCardSelectableProps> = ({
  user,
  userType,
  isStaticSelected,
  isSingularSelected,
  isSelected,
  isDisabled,
  isUsernameClickable,
  isUsernameLinkView,
  className,
  onClick,
  marked,
  isListItem,
  profileSchoolContext,
  children,
  renderCustomIcon,
}) => {
  function handleClick() {
    const id = getSchoolUserId(user);

    if (id && onClick) {
      onClick(id);
    }
  }

  function renderIcon() {
    if (renderCustomIcon) {
      return renderCustomIcon;
    }

    if (isStaticSelected) {
      return <MinusIcon className="PersonCardSelectable__icon" />;
    }

    if (isSingularSelected) {
      return <TickIcon className="PersonCardSelectable__icon" />;
    }
    if (isSingularSelected === false) {
      return null;
    }

    return isSelected ? (
      <TickIcon className="PersonCardSelectable__icon" />
    ) : (
      <PlusIcon className="PersonCardSelectable__icon" />
    );
  }

  const fullClassName = buildClassName(
    'PersonCardSelectable',
    (isSelected || isSingularSelected) && 'PersonCardSelectable--selected',
    marked && 'PersonCardSelectable--marked',
    isDisabled && 'PersonCardSelectable--disabled',
    className,
  );

  return (
    <PersonCardBasic
      user={user}
      userType={userType}
      onClick={!isDisabled ? handleClick : undefined}
      className={fullClassName}
      isUsernameClickable={isUsernameClickable}
      isUsernameLinkView={isUsernameLinkView}
      isListItem={isListItem}
      profileSchoolContext={profileSchoolContext}
      tooltipType="vertical"
    >
      {children}
      {renderIcon()}
    </PersonCardBasic>
  );
};

export default PersonCardSelectable;
