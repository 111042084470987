import { Box, Icon, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { DELAY_BEFORE_HIDE_ICON_DONE } from '@schooly/constants';
import { CopyIcon, DoneIcon, theme } from '@schooly/style';
import { FC, PropsWithChildren, useState } from 'react';

interface CompanyContactsTooltipProps extends PropsWithChildren {
  items: { text: string }[];
}
export const CompanyContactsTooltip: FC<CompanyContactsTooltipProps> = ({ items, children }) => {
  return (
    <Tooltip
      disableFocusListener
      title={items.map((item) => (
        <CompanyContactsTooltipMenuItem item={item} />
      ))}
      componentsProps={{
        tooltip: {
          sx: {
            padding: theme.spacing(1),
            minWidth: 250,
          },
        },
      }}
    >
      <Box sx={{ display: 'inline' }}>{children}</Box>
    </Tooltip>
  );
};

interface CompanyContactsTooltipMenuItemProps {
  item: { text: string };
}

export const CompanyContactsTooltipMenuItem: FC<CompanyContactsTooltipMenuItemProps> = ({
  item,
}) => {
  const [showDoneIcon, setShowDoneIcon] = useState(false);

  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setShowDoneIcon(true);
    setTimeout(() => setShowDoneIcon(false), DELAY_BEFORE_HIDE_ICON_DONE);
  };

  const icon = showDoneIcon ? <DoneIcon /> : <CopyIcon />;

  return (
    <MenuItem
      key={item.text}
      onClick={(e) => {
        e.stopPropagation();
        onCopy(item.text);
      }}
      sx={(theme) => ({
        borderRadius: `${theme.shape.borderRadiusSm}px`,
        padding: theme.spacing(0.25, 0.5),
        '&:last-of-type': {
          margin: 0,
        },
        ' .MuiIcon-root': { visibility: showDoneIcon ? 'visible' : 'hidden' },
        '&:hover': { ' .MuiIcon-root': { visibility: 'visible' } },
      })}
    >
      <Stack direction="row" justifyContent="space-between" overflow="hidden" width="100%">
        <Typography variant="h3" overflow="hidden" textOverflow="ellipsis">
          {item.text}
        </Typography>
        <Icon>{icon}</Icon>
      </Stack>
    </MenuItem>
  );
};
