import { UserType } from '@schooly/api';

export function getFormStepTextIds(userType: UserType) {
  return [
    'peopleDetail-PersonalInformation',
    'peopleDetail-ContactInformation',
    ...(userType === 'child' ? ['peopleDetail-ParentInformation'] : []),
  ];
}

const TEMP_USER_ID_PREFIX = 'TEMP_USER_';

export function generateTempUserId() {
  return `${TEMP_USER_ID_PREFIX}${Date.now().valueOf()}`;
}

export function isTempUserId(id?: string) {
  if (!id) return false;

  return id.startsWith(TEMP_USER_ID_PREFIX);
}

export const replaceEmptyStringValuesWithNull = (data?: object) => {
  if (!data) return {};

  return Object.entries(data).reduce((acc, [k, v]) => {
    if (v === '') {
      return { ...acc, [k]: null };
    }

    return { ...acc, [k]: v };
  }, {});
};

export function getIsValuesFilled(values: object) {
  const filledValue = Object.values(values).find((v) => {
    if (Array.isArray(v)) return v.length;

    return !!v;
  });

  return !!filledValue;
}
