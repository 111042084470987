import { Stack } from '@mui/material';
import { AssessmentStatuses } from '@schooly/constants';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import {
  AssessmentStatusTagSelect,
  AssessmentStatusTagSelectProps,
} from './AssessmentStatusTagSelect';

type AssessmentStatusExpandedSelectProps = PropsWithChildren<{
  selectedStatuses: AssessmentStatuses[];
  onSelectStatus: (v: AssessmentStatuses) => void;
  onClear: () => void;
  onClose: () => void;
}>;

const OPTIONS = [AssessmentStatuses.Published, AssessmentStatuses['Not published']];

export const AssessmentStatusExpandedSelect: FC<AssessmentStatusExpandedSelectProps> = ({
  selectedStatuses,
  onSelectStatus,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderAssessmentStatusTags({
          statuses: OPTIONS,
          tagProps: (status) => ({
            variant: selectedStatuses.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectStatus,
        })}
      </Stack>
    );
  }, [onSelectStatus, selectedStatuses]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedStatuses.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={renderContent}
    >
      {renderAssessmentStatusTags({
        tagProps: { size: 'small' },
        statuses: selectedStatuses,
        onDelete: onSelectStatus,
      })}
    </ExpandedSelect>
  );
};

type RenderAssessmentStatusTagsParams = {
  statuses: AssessmentStatuses[];
  onDelete?: (v: AssessmentStatuses) => void;
  onClick?: (v: AssessmentStatuses) => void;
  tagProps?:
    | Omit<AssessmentStatusTagSelectProps, 'status'>
    | ((v: AssessmentStatuses) => Omit<AssessmentStatusTagSelectProps, 'status'>);
};

export const renderAssessmentStatusTags = ({
  statuses,
  onDelete,
  onClick,
  tagProps,
}: RenderAssessmentStatusTagsParams) =>
  statuses.map((v) => (
    <AssessmentStatusTagSelect
      key={v}
      status={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
