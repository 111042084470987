import { IncomingAttachment, IncomingAttachmentFile } from '@schooly/api';

import IntlError from '../../../../../utils/intlError';
import { AttachmentFile } from './attachment-types';

const XLSX_MIME = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const DOCX_MIME = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

const OFFICE_DOCUMENT_TYPES = [
  XLSX_MIME,
  DOCX_MIME,
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/rtf',
  'text/rtf',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/octet-stream',
];

export const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/svg',
  'audio/*',
  'application/pdf',
  'text/csv',
  'application/csv',
  'application/json',
  'text/plain',
  '.epub',
  '.ppt ',
  '.pptx',
  ...OFFICE_DOCUMENT_TYPES,
];

export const MAX_ATTACHMENTS_SIZE = 5 * 1024 * 1024; // 5 Mb

export const formatSize = (size: number) => {
  const sizeInMb = size / 1024 / 1024;

  return sizeInMb.toFixed(1);
};

export const formatType = (type: string, name: string) => {
  if (OFFICE_DOCUMENT_TYPES.includes(type)) {
    return name.split('.')[1];
  }

  return type.split('/')[1];
};

export const getTotalFilesSize = (files?: AttachmentFile[] | IncomingAttachment[]) => {
  if (!files) return null;

  return (files as AttachmentFile[]).reduce(
    (acc: number, cur: AttachmentFile | IncomingAttachment) => {
      const addSize = (cur as AttachmentFile)?.file
        ? (cur as AttachmentFile)?.file?.size
        : (cur as IncomingAttachment).size;

      return acc + addSize;
    },
    0,
  );
};

export const getMaxSizeError = (files?: AttachmentFile[] | IncomingAttachment[]) => {
  const totalSize = getTotalFilesSize(files);
  const sizeInMb = Number(totalSize) / 1024 / 1024;

  return new IntlError('error-MaxSizeOfFiles', { size: sizeInMb.toFixed(1) });
};

export const getIsMaxAttachmensSizeExceed = (files?: AttachmentFile[] | IncomingAttachment[]) => {
  const totalSize = getTotalFilesSize(files);
  return Number(totalSize) > MAX_ATTACHMENTS_SIZE;
};

const getAttachmentTypeFromName = (data: IncomingAttachmentFile) =>
  data.name.includes('.') ? data.name.split('.')[1] : null;

export const getAttachmentName = (data: IncomingAttachmentFile) =>
  data.name.includes('.') ? data.name.split('.')[0] : data.name;

export const getBase64Href = (data: IncomingAttachmentFile) => {
  if (OFFICE_DOCUMENT_TYPES.includes(data.type)) {
    const typeFromName = getAttachmentTypeFromName(data);

    switch (typeFromName) {
      case 'xls':
      case 'xlsx':
        return `data:${XLSX_MIME};base64,${data.data}`;
      case 'doc':
      case 'docx':
        return `data:${DOCX_MIME};base64,${data.data}`;
    }
  }

  return `data:${data.type};base64,${data.data}`;
};
