import { ApiError, DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { format, isAfter } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import {
  pickFormFieldsFromUser,
  UserFormData,
} from '../../../components/common/ProfileModal/profileEditSchema';
import { useProfile } from '../../../context/profile/useProfile';
import IntlError from '../../../utils/intlError';
import { PropertyModal2 } from './PropertyModal2';

import './DateModal.scss';

interface DateModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const DateModal = ({ onClose, isOpen }: DateModalProps) => {
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);
  const { $t } = useIntl();
  const { user, actions } = useProfile();

  const form = useForm<UserFormData>({
    mode: 'all',
    defaultValues: {
      date_of_birth: user?.date_of_birth,
    },
  });

  const {
    reset,
    formState: { isValid },
  } = form;

  const handleSubmit = useCallback(
    async ({ date_of_birth }: UserFormData) => {
      setIsUpdating(true);

      try {
        const res = await actions.updateProfile({
          date_of_birth: date_of_birth || null,
        });

        if (!res) return;

        showNotification({
          textId: 'confirmation-DateOfBirth',
          type: 'success',
        });
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, showNotification, showError, onClose],
  );

  useEffect(() => {
    if (user) {
      reset(pickFormFieldsFromUser(user));
    }
  }, [user, reset]);

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  return (
    <PropertyModal2
      isOpen={isOpen}
      user={user}
      isUpdating={isUpdating}
      submitDisabled={!isValid}
      onClose={handleClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <Controller
        control={form.control}
        name="date_of_birth"
        rules={{
          required: true,
          validate: (date_of_birth) => {
            if (!date_of_birth) return;
            if (isAfter(new Date(date_of_birth), Date.now())) {
              return $t({ id: 'error-FutureDateOfBirth' });
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <DateSelect
              ref={field.ref}
              onSetDate={(date) => {
                field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
              }}
              date={field.value || ''}
              placeholder={$t({ id: 'peopleDetail-DateOfBirth' })}
              error={fieldState.error}
              requiredLabel="required"
              popperZIndex={(theme) => theme.zIndex.tooltip}
              hideTodayButton
            />
          );
        }}
      />
    </PropertyModal2>
  );
};

export default DateModal;
