import { TableCellProps, Typography } from '@mui/material';
import { AttendanceCode, AttendanceRegisterGroupDetail } from '@schooly/api';
import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  GridCell,
  GridCellContent,
  GridContainer,
  GridHeaderCellContent,
  GridRowName,
} from '../../../components/uikit-components/Grid/Grid';
import { LongNameWithVerticalTooltip } from '../../../components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { Table, TableCell } from '../../../components/uikit-components/Table/Table';
import { useAttendanceRegister } from '../../../context/attendance/useAttendanceRegister';
import { useAttendanceStatistics } from '../../../hooks/useAttendanceStatistics';
import {
  getAbsentTotal,
  getAttendanceDetailByCode,
  getNoStatusTotal,
  getPresentTotal,
  getRegisterTotal,
} from '../attendance-utils';

interface AttendanceRegisterPreviewGridProps {
  codes?: AttendanceCode[];
  groupDetails?: AttendanceRegisterGroupDetail[];
  date?: string;
}

export const AttendanceRegisterPreviewGrid: FC<AttendanceRegisterPreviewGridProps> = ({
  codes,
  groupDetails,
  date,
}) => {
  const navigate = useNavigate();
  const { showByPercent, showByPresentAbsent } = useAttendanceRegister();
  const { renderCount } = useAttendanceStatistics(1, showByPercent);

  const rowClickHandler = useCallback(
    (groupDetail: AttendanceRegisterGroupDetail) => () =>
      navigate(`/groups/${groupDetail.id}?tab=attendance&attendanceDate=${date}`),
    [date, navigate],
  );

  const attendanceCodes = useMemo(() => codes ?? [], [codes]);

  const columns: TableCell[] = useMemo(
    () => [
      {
        id: 'attendance-empty-cell',
        renderContent: (props) => <GridCell sticky py={1} />,
      },
      ...(!showByPresentAbsent
        ? attendanceCodes.map((code) => ({
            id: code.id,
            renderContent: (props: TableCellProps) => (
              <GridCell key={code.code} py={1} {...props}>
                <GridHeaderCellContent size="normal">{code.name}</GridHeaderCellContent>
              </GridCell>
            ),
          }))
        : [
            {
              id: 'attendance-Present',
              renderContent: (props: TableCellProps) => (
                <GridCell
                  pr={0.75}
                  py={1}
                  sx={{
                    right: 40,
                    height: 54,
                  }}
                  {...props}
                  sticky
                >
                  <GridHeaderCellContent size="normal">
                    <FormattedMessage id="attendance-Present" />
                  </GridHeaderCellContent>
                </GridCell>
              ),
            },
            {
              id: 'attendance-Absent',
              renderContent: (props: TableCellProps) => (
                <GridCell
                  pr={0.75}
                  py={1}
                  sx={{
                    right: 40,
                  }}
                  {...props}
                  sticky
                >
                  <GridHeaderCellContent size="normal">
                    <FormattedMessage id="attendance-Absent" />
                  </GridHeaderCellContent>
                </GridCell>
              ),
            },
          ]),
      {
        id: 'attendance-NoStatus',
        renderContent: (props) => (
          <GridCell py={1} borderLeft {...props}>
            <GridHeaderCellContent size="normal">
              <FormattedMessage id="attendance-NoStatus" />
            </GridHeaderCellContent>
          </GridCell>
        ),
      },
      {
        id: 'attendance-Total',
        renderContent: (props) => (
          <GridCell sticky py={1} {...props}>
            <GridHeaderCellContent size="normal">
              <FormattedMessage id="attendance-Total" />
            </GridHeaderCellContent>
          </GridCell>
        ),
      },
    ],
    [attendanceCodes, showByPresentAbsent],
  );

  const rows = useMemo(() => {
    if (!groupDetails?.length) {
      return [];
    }

    return groupDetails.map((groupDetail) => {
      const noStatusCount = getNoStatusTotal(groupDetail.attendance_details);
      const inSchoolCount = getPresentTotal(attendanceCodes ?? [], groupDetail.attendance_details);

      const outOfSchoolCount = getAbsentTotal(
        attendanceCodes ?? [],
        groupDetail.attendance_details,
      );

      const cellArray: TableCell[] = [
        {
          id: 'groupName',
          renderContent: (props) => (
            <GridCell
              sticky
              noVerticalPadding
              py={1}
              width={180}
              {...props}
              onClick={rowClickHandler(groupDetail)}
              sx={{ cursor: 'pointer' }}
            >
              <GridRowName variant="h3" sx={{ py: 1, width: 180, overflow: 'hidden' }}>
                <LongNameWithVerticalTooltip>{groupDetail.name}</LongNameWithVerticalTooltip>
              </GridRowName>
            </GridCell>
          ),
        },
        ...(!showByPresentAbsent
          ? attendanceCodes.map((code) => {
              const count = getAttendanceDetailByCode(code, groupDetail.attendance_details)?.count;

              return {
                id: code.id,
                renderContent: (props: TableCellProps) => (
                  <GridCell width="10%" py={1} {...props}>
                    <GridCellContent
                      size="normal"
                      variant="body1"
                      color={count ? undefined : 'text.secondary'}
                    >
                      {count ?? '-'}
                    </GridCellContent>
                  </GridCell>
                ),
              };
            })
          : [
              {
                id: 'attendance-Present',
                renderContent: (props: TableCellProps) => (
                  <GridCell py={1} noHorizontalPadding {...props}>
                    {renderCount(inSchoolCount, getRegisterTotal(groupDetail.attendance_details))}
                  </GridCell>
                ),
              },
              {
                id: 'attendance-Absent',
                renderContent: (props: TableCellProps) => (
                  <GridCell py={1} noHorizontalPadding {...props}>
                    {renderCount(
                      outOfSchoolCount,
                      getRegisterTotal(groupDetail.attendance_details),
                    )}
                  </GridCell>
                ),
              },
            ]),
        {
          id: 'attendance-NoStatus',
          renderContent: (props) => (
            <GridCell py={1} borderLeft width="10%" {...props}>
              <GridCellContent size="normal" variant="body1" width="10%">
                {!!noStatusCount ? (
                  noStatusCount
                ) : (
                  <Typography color="text.secondary">-</Typography>
                )}
              </GridCellContent>
            </GridCell>
          ),
        },
        {
          id: 'attendance-Total',
          renderContent: (props) => (
            <GridCell
              py={1}
              sx={{
                right: 0,
              }}
              width="10%"
              sticky
              {...props}
            >
              <GridCellContent size="normal" variant="body1" width="10%">
                {getRegisterTotal(groupDetail.attendance_details)}
              </GridCellContent>
            </GridCell>
          ),
        },
      ];

      return { id: groupDetail.id, cells: cellArray };
    });
  }, [attendanceCodes, groupDetails, rowClickHandler, showByPresentAbsent, renderCount]);

  return (
    <GridContainer>
      <Table rows={rows} columns={columns} />
    </GridContainer>
  );
};
