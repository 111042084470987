import React, { PropsWithChildren } from 'react';
import { FormProvider, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export type FormWrapperProps<T extends FieldValues = FieldValues> = PropsWithChildren<{
  form: UseFormMethods<T>;
  onSubmit: SubmitHandler<T>;
}>;

class FormWrapper<T extends FieldValues = FieldValues> extends React.PureComponent<
  FormWrapperProps<T>
> {
  public render() {
    const { form, onSubmit, children } = this.props;

    return (
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit<T>(onSubmit)}>{children}</form>
      </FormProvider>
    );
  }
}

export default FormWrapper;
