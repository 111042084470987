import {
  BaseUser,
  BaseUserSearchResult,
  Child,
  editFamily,
  getStudentMembership,
  isStudentWithParents,
  NewFamilyMember,
  ProfileSearchResult,
  SchoolRelation,
  StudentSchoolRelation,
  updatePrimaryContactId,
  UserType,
  WithAssociations,
  WithGuardian,
} from '@schooly/api';

import { isTempUserId } from '../components/common/CreatePersonForm/utils';
import { getUserStudentMembership } from './users';

export const getStudentParents = (
  student: BaseUser & Partial<WithAssociations & WithGuardian>,
  userType?: UserType,
) => {
  if (isStudentWithParents(student)) {
    return student.parents ?? [];
  }

  const { guardian_relations, child_associations } = student;

  if (!guardian_relations && !child_associations) {
    return [];
  }

  if (userType === 'child') {
    return child_associations ?? [];
  }

  return guardian_relations?.map((r) => r.guardian) ?? [];
};

export const getParentChildren = (parent: ProfileSearchResult) => {
  const { adult_associations } = parent;
  return adult_associations;
};

export const getStudentPrimaryContactId = (
  student: BaseUser & Partial<WithAssociations & WithGuardian>,
  userType?: UserType,
  canSetDefaultPrimary = true,
) => {
  if (userType === 'child') return undefined;

  const { guardian_relations, child_associations } = student;
  if (!guardian_relations && !child_associations) return undefined;

  const primaryContact = guardian_relations?.find((r) => r.primary)?.guardian;

  if (!primaryContact && canSetDefaultPrimary) {
    return guardian_relations && guardian_relations[0]?.guardian?.user_id;
  }

  return primaryContact?.user_id;
};

export const getStudentDefaultPrimaryContactId = (student: BaseUser & Partial<WithGuardian>) => {
  const primaryContact = student.guardian_relations?.find((a) => a.primary)?.guardian;
  return primaryContact?.user_id;
};

export const getStudentGuardianPrimaryContactId = (student: BaseUser & Partial<WithGuardian>) => {
  const primaryContact = student.guardian_relations?.find((r) => r.primary)?.guardian;
  return primaryContact?.user_id;
};

export const updateFamily = async (
  child: Child | StudentSchoolRelation,
  parents: (NewFamilyMember | BaseUserSearchResult)[],
  primaryContactId: string,
  schoolId: string,
  schoolMembership?: SchoolRelation,
  userType?: UserType,
) => {
  let studentMembership = schoolMembership || getUserStudentMembership(child as Child, schoolId);

  const currentPrimaryGuardianContactId = getStudentGuardianPrimaryContactId(child);

  const processedAdults = parents.map((p) => {
    if (isTempUserId(p.user_id)) {
      const tempUser = { ...p };
      delete tempUser.user_id;

      return tempUser as NewFamilyMember;
    }

    return {
      user_id: p.user_id,
    } as NewFamilyMember;
  });

  const { adults } = await editFamily(schoolId, child.user_id, processedAdults);

  if (
    primaryContactId &&
    currentPrimaryGuardianContactId !== primaryContactId &&
    userType === 'student'
  ) {
    let finalPrimaryContactId = primaryContactId;
    if (isTempUserId(primaryContactId)) {
      const createdAdultIndex = parents.findIndex((p) => p.user_id === primaryContactId);
      finalPrimaryContactId = adults[createdAdultIndex];
    }

    if (!studentMembership) {
      try {
        studentMembership = await getStudentMembership(schoolId, child.user_id);
      } catch (e) {
        return;
      }
    }

    await updatePrimaryContactId(studentMembership.relation_id, finalPrimaryContactId);
  }
};
