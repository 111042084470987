import { SelectOption } from '@schooly/api';
import { WithAvatar, WithName } from '@schooly/api';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { NON_BREAKING_SPACE } from '../../../constants/typography';
import { getUserFullName, getUserInitials } from '../../../helpers/users';
import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';

import './TagAvatar.scss';

export interface TagAvatarProps {
  user?: WithName & WithAvatar;
  isMe?: boolean;
  className?: string;
  selectOption?: SelectOption;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, selectOption?: SelectOption) => void;
}

const $class = `${$UIKitPrefix}TagAvatar`;

export const TagAvatar: React.FC<TagAvatarProps> = ({
  user,
  className,
  isMe,
  selectOption,
  onClick,
}) => {
  const { profile_picture } = user || {};

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      if (onClick) {
        onClick(e, selectOption);
      }
    },
    [onClick, selectOption],
  );

  if (!user) return null;

  const name = getUserFullName(user);

  const initials = getUserInitials(user);
  return (
    <button
      onClick={handleClick}
      className={buildClassName($class, isMe && `${$class}_me`, 'reset-button-styles', className)}
    >
      {profile_picture ? (
        <img className={`${$class}__avatar`} src={profile_picture} alt={name} />
      ) : (
        <span className={`${$class}__initials`}>{initials}</span>
      )}
      <p className={`${$class}__name`}>
        {NON_BREAKING_SPACE}
        {isMe ? <FormattedMessage id="filter-Me" /> : name}
        {NON_BREAKING_SPACE}
        {NON_BREAKING_SPACE}
      </p>
    </button>
  );
};
