import {
  PaymentFrequency,
  PaymentFrequencyType,
  Product,
  ProductFormType,
  ProductSaveVariant,
  ProductType,
  SchoolYear,
} from '@schooly/api';
import { Currencies, CURRENCY_SYMBOLS, PRODUCTS_YEAR_ID_PARAM } from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useSchoolYears from '../../../hooks/useSchoolYears';
import {
  IntersectionIds,
  INTERSECTS_ALL,
} from './SchoolProductCreateModal/SchoolProductCreateModalContent';

export const FREQUENCY_SETTINGS_PATH = '/settings/frequencies';

export const DEFAULT_FREQUENCIES = [
  PaymentFrequencyType.Weekly,
  PaymentFrequencyType.Monthly,
  PaymentFrequencyType.Termly,
  PaymentFrequencyType.Biannually,
  PaymentFrequencyType.Annually,
  PaymentFrequencyType.OneOff,
];

export const getTypesByYears = <
  T extends Pick<ProductFormType, 'active_from' | 'active_to' | 'year_id'>,
>(
  types: T[],
  years: SchoolYear[],
) => {
  const yearIntervals = years.map((year) => ({
    yearId: year.id,
    start: newDateTimezoneOffset(year.start).getTime(),
    end: newDateTimezoneOffset(year.end).getTime(),
  }));

  return types.reduce((typesByYears: { [year: string]: T[] }, type) => {
    if (type.active_from && type.active_to) {
      yearIntervals.forEach((interval) => {
        const typeStartDate = newDateTimezoneOffset(type.active_from).getTime();
        const typeEndDate = newDateTimezoneOffset(type.active_to).getTime();

        if (typeStartDate <= interval.end && typeEndDate >= interval.start) {
          const yearKey = interval.yearId;
          if (!typesByYears[yearKey]) {
            typesByYears[yearKey] = [];
          }
          typesByYears[yearKey].push(type);
        }
      });
    } else if (type.year_id) {
      const yearKey = type.year_id;
      if (!typesByYears[yearKey]) {
        typesByYears[yearKey] = [];
      }
      typesByYears[yearKey].push(type);
    }
    return typesByYears;
  }, {});
};

export const useSchoolYearsInProduct = (canShowYearInSelect: (year?: SchoolYear) => boolean) => {
  const { defaultValidity, schoolYears } = useSchoolYears();
  const [params] = useSearchParams();

  const yearsForSelect = useMemo(
    () => schoolYears.filter(canShowYearInSelect),
    [canShowYearInSelect, schoolYears],
  );
  const defaultYear = canShowYearInSelect(defaultValidity) ? defaultValidity : undefined;
  //Based on TR-6622 current year should be pre-selected
  const defaultYearInSelect = defaultYear ?? yearsForSelect[0];

  const paramsYearId = params.get(PRODUCTS_YEAR_ID_PARAM);
  const paramsSchoolYear = useMemo(
    () => yearsForSelect.find((y) => y.id === paramsYearId),
    [paramsYearId, yearsForSelect],
  );
  const initSelectedYear = paramsSchoolYear ?? defaultYearInSelect;

  const [selectedYear, setSelectedYear] = useState<SchoolYear | undefined>(initSelectedYear);

  useEffect(() => {
    if (paramsYearId && selectedYear?.id && paramsYearId !== selectedYear.id) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(PRODUCTS_YEAR_ID_PARAM, selectedYear.id);
      const newSearchParamsString = searchParams.toString();
      const newPath = `${window.location.pathname}?${newSearchParamsString}`;

      window.history.replaceState(null, '', newPath);
    }
  }, [paramsYearId, selectedYear?.id]);

  return { selectedYear, setSelectedYear, defaultYear: defaultYearInSelect, yearsForSelect };
};

export const getVariantPrice = (v: Product['types'][0]['variants'][0], freqId?: string) => {
  if (!freqId) return null;

  const p = v.prices.find((v) => v.frequency_id === freqId);
  if (!p) return null;

  return p.price;
};

export function getSortedFrequencies(frequencies: PaymentFrequency[]) {
  return [...frequencies].sort(
    (a, b) => DEFAULT_FREQUENCIES.indexOf(a.type) - DEFAULT_FREQUENCIES.indexOf(b.type),
  );
}

type UseProductFrequencyProps = {
  product: Product;
  frequencies: PaymentFrequency[];
};

export type ProductTypeNotActiveMap = Record<ProductType['id'], boolean>;

export const useProductFrequency = ({ product, frequencies }: UseProductFrequencyProps) => {
  return useMemo(() => {
    const productTypeNotActiveMap = product.types.reduce<ProductTypeNotActiveMap>(
      (acc, { id, variants }) => ({
        ...acc,
        [id]: variants.every((v) =>
          v.prices.every((p) => !frequencies.find((f) => f.id === p.frequency_id)?.in_use),
        ),
      }),
      {},
    );

    return {
      productTypeNotActiveMap,
      allTypesNotActive: Object.values(productTypeNotActiveMap).every(Boolean),
    };
  }, [frequencies, product.types]);
};

type HasIntersectionErrorProps = {
  variant: ProductSaveVariant;
  intersectionIds?: IntersectionIds;
};

export function getIntersections({
  variant: { age_groups, half_day, subjects },
  intersectionIds,
}: HasIntersectionErrorProps) {
  const intersectionsForDay = intersectionIds?.[half_day ? 'halfDayIds' : 'fullDayIds'];

  //TODO Based on TR-5885 user can only create types for one year
  //Once this is changed validation for types in different years should be discussed and updated
  const ageGroupsIntersections =
    !age_groups.length &&
    intersectionsForDay?.some(({ ageGroupId }) => ageGroupId !== INTERSECTS_ALL)
      ? INTERSECTS_ALL
      : age_groups.filter((agId) =>
          intersectionsForDay?.map(({ ageGroupId }) => ageGroupId).includes(agId),
        );
  const subjectIntersections = subjects.filter((agId) =>
    intersectionsForDay?.map(({ subjectId }) => subjectId).includes(agId),
  );

  const hasIntersectionError = !!(subjectIntersections.length || ageGroupsIntersections.length);

  return { subjectIntersections, hasIntersectionError, ageGroupsIntersections };
}

export const getCurrencySymbol = (currency?: Currencies) => {
  //list of currency symbols is used cause the task was to use symbols from a certain source
  return currency ? CURRENCY_SYMBOLS[currency] : '';
};
