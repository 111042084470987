import { Box, Stack, styled } from '@mui/material';

export const LogoLink = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  position: 'fixed',
  left: theme.spacing(5),
  top: theme.spacing(5),
}));

export const LanguageSelectWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  right: theme.spacing(5),
  top: theme.spacing(5),
}));

export const SchoolCreateLayout = styled(Stack)(({ theme }) => ({
  position: 'relative',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SchoolCreateContentForm = styled('form')(({ theme }) => ({
  maxWidth: 960,
  maxHeight: '100vh',
  margin: theme.spacing(-5, 0, -5, 0),
  padding: theme.spacing(0, 0, 5, 0),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  '.MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: `${theme.spacing(1.6)} !important`,
    borderColor: `${theme.palette.background.default} !important`,
  },
}));
