import { getPrivateImage } from '@schooly/api';
import { useCallback, useEffect, useState } from 'react';

export const usePrivateImage = (url?: string | null) => {
  const [key, setKey] = useState(0);
  const [image, setImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<string | undefined>();

  const fetchImage = useCallback(async () => {
    if (!url) return;

    const imageBlob = await getPrivateImage(url);
    const result = URL.createObjectURL(imageBlob as Blob);
    setKey((prev) => prev + 1);
    setImage(url);
    setResponse(result);
    setIsLoading(false);
  }, [url]);

  useEffect(() => {
    setIsLoading(true);
    fetchImage();
  }, [fetchImage]);

  return { key, image, response, isLoading };
};
