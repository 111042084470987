export default class IntlError extends Error {
  public textId: string;

  public values: Record<string, any> | undefined;

  constructor(textId: string, values?: Record<string, any>) {
    super(textId);
    this.textId = textId;
    this.values = values;
  }
}
