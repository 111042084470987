import { Box, Collapse, IconButton, Stack, SxProps } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { createPortal } from 'react-dom';

import { SidebarClosedIcon, SidebarOpenedIcon } from '../../assets/assets';

export interface CollapsePanelState {
  isOpen: boolean;
  isOpenByHover: boolean;
  buttonHover: boolean;
  absolutePosition: boolean;
  menuHover: boolean;
}

export type CollapsePanelActions =
  | 'buttonClick'
  | 'buttonHover'
  | 'panelHover'
  | 'resetStyles'
  | 'reset';

export interface CollapsePanelAction {
  type: CollapsePanelActions;
  value?: boolean;
}

export interface CollapsePanelProps extends PropsWithChildren {
  width?: number;
  state: CollapsePanelState;
  handleAction: (params: CollapsePanelAction) => void;
  getSxProps?: (state: CollapsePanelState) => SxProps;
}

export const CollapsePanel: FC<CollapsePanelProps> = ({
  children,
  width = 200,
  getSxProps,
  state,
  handleAction,
}) => {
  const [coords, setCoords] = useState<DOMRect | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const onResize = useCallback(() => {
    const coords = sidebarRef.current?.getBoundingClientRect() ?? null;
    setCoords(coords);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    if (sidebarRef.current) onResize();
  }, [onResize, sidebarRef]);

  const buttonCoords = useMemo(
    () =>
      coords
        ? {
            top: coords.y + 20,
            left: state.isOpen ? coords.x + coords.width - 14 : coords.x - 14,
          }
        : {},
    [coords, state.isOpen],
  );

  const panelOpen = useMemo(
    () => state.isOpenByHover || state.isOpen || state.menuHover,
    [state.isOpen, state.isOpenByHover, state.menuHover],
  );

  const panelButton = useMemo(() => {
    if (state.isOpen || (!state.isOpen && state.buttonHover)) {
      return <SidebarOpenedIcon className="reset-svg-currentColor" />;
    }

    return <SidebarClosedIcon className="reset-svg-currentColor" />;
  }, [state.buttonHover, state.isOpen]);

  const onButtonClick = useCallback(() => {
    handleAction({ type: 'buttonClick' });
    setTimeout(() => {
      handleAction({ type: 'buttonHover', value: false });
      handleAction({ type: 'panelHover', value: false });
    }, 100);
  }, [handleAction]);

  const onButtonMouseOver = useCallback(() => {
    handleAction({ type: 'buttonHover', value: true });
  }, [handleAction]);

  const onButtonMouseLeave = useCallback(() => {
    handleAction({ type: 'buttonHover', value: false });
  }, [handleAction]);

  const onPanelMouseOver = useCallback(() => {
    handleAction({ type: 'panelHover', value: true });
  }, [handleAction]);

  const onPanelMouseLeave = useCallback(() => {
    handleAction({ type: 'panelHover', value: false });
  }, [handleAction]);

  return (
    <Stack ref={sidebarRef} position="relative">
      <Stack
        direction="row"
        position={state.absolutePosition ? 'absolute' : 'initial'}
        sx={{
          zIndex: (theme) => theme.zIndex.modal,
          height: '100%',
          '.MuiCollapse-horizontal': {
            minWidth: width,
          },
        }}
        onMouseOver={onPanelMouseOver}
        onMouseLeave={onPanelMouseLeave}
      >
        <Collapse
          onExited={() => {
            handleAction({ type: 'resetStyles' });
          }}
          in={panelOpen}
          orientation="horizontal"
          sx={{
            height: '100%',
            display: 'flex',
            flexGrow: 1,
            '& > :first-child': {
              minWidth: width,
              height: '100%',
            },
            ...(getSxProps?.(state) ?? {}),
          }}
        >
          {children}
        </Collapse>
      </Stack>
      {coords &&
        createPortal(
          <Box
            sx={(theme) => ({
              ...buttonCoords,
              position: 'fixed',
              zIndex: theme.zIndex.modal,
            })}
          >
            <IconButton
              sx={(theme) => ({
                '&:hover svg': {
                  path: {
                    fill: theme.palette.primary.main,
                  },
                },
                '.svg-icon': {
                  filter: 'drop-shadow(0px 1px 4px #25275A20)',
                },
                fontSize: theme.spacing(3.5),
                transform: !state.isOpen && state.buttonHover ? 'rotate(180deg)' : 0,
              })}
              onClick={onButtonClick}
              onMouseOver={onButtonMouseOver}
              onMouseLeave={onButtonMouseLeave}
            >
              {panelButton}
            </IconButton>
          </Box>,
          document.body,
        )}
    </Stack>
  );
};
