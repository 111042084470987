import React from 'react';

interface HighlightedTextProps {
  highlightString: string | string[];
  text: string;
}

const HighlightedText = ({ text, highlightString }: HighlightedTextProps) => {
  if (!text) return null;

  const isHighlight = Array.isArray(highlightString)
    ? new RegExp(`(${highlightString.join('|')})`, 'gi').test(text)
    : text.toLowerCase() === highlightString.toLowerCase();

  return isHighlight ? <span className="highlight">{text}</span> : <>{text}</>;
};

export default function addHighlight(text?: string, search?: string | string[]) {
  if (!text) return null;
  if (!search) return text;

  const regex = Array.isArray(search) ? search.join('|') : search;
  const parts = text.split(new RegExp(`(${regex})`, 'gi'));

  return (
    <>
      {parts.map((part, index) => (
        <HighlightedText
          text={part}
          highlightString={search}
          key={part + index} // eslint-disable-line react/no-array-index-key
        />
      ))}
    </>
  );
}
