import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { MainLayoutEmptyStubStyled } from './MainLayout.styled';

export const MainLayoutEmptyStub: FC = () => {
  const { $t } = useIntl();

  return (
    <MainLayoutEmptyStubStyled>
      <Stack gap={2.5} alignItems="center">
        <picture>
          <source srcSet="/images/no-schools.png, /images/no-schools@2x.png 2x" />
          <img src="/images/no-schools.png" alt={$t({ id: 'mainLayout-empty-title' })} />
        </picture>
        <Stack gap={2} alignItems="center">
          <Typography variant="h1" sx={{ textAlign: 'center', whiteSpace: 'pre' }}>
            <FormattedMessage id="mainLayout-empty-title" />
          </Typography>

          <Typography
            variant="h3"
            color="text.primary"
            sx={{ textAlign: 'center', whiteSpace: 'pre' }}
          >
            <FormattedMessage id="mainLayout-empty-subtitle" />
          </Typography>
        </Stack>
      </Stack>
    </MainLayoutEmptyStubStyled>
  );
};
