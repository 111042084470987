import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.729 14.9152L9.34958 13.5357L10.7282 12.1626L10.7291 12.1634L11.1579 11.7346L11.2967 11.5963L11.2965 11.596L17.3392 5.55362C17.7191 5.17368 18.3351 5.1737 18.7151 5.55365C19.095 5.9336 19.095 6.54961 18.715 6.92954L10.729 14.9152ZM1.28496 9.36275L6.83739 14.9152L14.8234 6.92956C15.2034 6.54962 15.2034 5.93362 14.8234 5.55367C14.4435 5.17372 13.8275 5.1737 13.4475 5.55363L6.83743 12.1634L2.66085 7.98686C2.28091 7.60691 1.6649 7.60691 1.28496 7.98685C0.905014 8.3668 0.905014 8.9828 1.28496 9.36275Z"
      fill="#B3BBCB"
    />
  </svg>
);
