import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08383 13.9166C8.51434 16.2403 10.5517 18 13 18C15.7614 18 18 15.7614 18 13C18 10.5517 16.2403 8.51434 13.9166 8.08383C13.4506 11.0819 11.0819 13.4506 8.08383 13.9166ZM1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 7.34095 12.9716 7.67526 12.9169 8.00068C12.4952 10.5126 10.5126 12.4952 8.00068 12.9169C7.99648 12.9176 7.99228 12.9183 7.98807 12.919C7.66663 12.9723 7.33655 13 7 13C3.68629 13 1 10.3137 1 7Z"
      fill="#B3BBCB"
    />
    <path d="M10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7" stroke="white" />
    <path d="M14 16C14 15.4477 13.5523 15 13 15C12.4477 15 12 15.4477 12 16" stroke="white" />
  </svg>
);
