import { IconButton, styled } from '@mui/material';

export interface AttendanceExportButtonProps {
  primary?: boolean;
}

export const AttendanceExportButton = styled(IconButton)<AttendanceExportButtonProps>(
  ({ theme, primary }) => ({
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadiusSm,
    padding: 5, // TODO: review the Figma

    ...(primary && {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    }),

    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.light,
    },
  }),
);
