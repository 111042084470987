import { IconButton, Tooltip } from '@mui/material';
import { useExportAttendanceForGroupMutation } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, DownloadIcon, Spin } from '@schooly/style';
import { IntlError } from '@schooly/utils/intl-error';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { GroupAttendanceStatisticsMode } from '../../../context/groups/GroupAttendanceStatisticsContext';
import { useGroup } from '../../../context/groups/useGroup';
import { useGroupAttendanceStatistics } from '../../../context/groups/useGroupAttendanceStatistics';
import { downloadFile } from '../../../utils/downloadFile';

export const GroupAttendanceStatisticsModalExport: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { group } = useGroup();
  const { mode, validity } = useGroupAttendanceStatistics();

  // is being set after successful download and reset in 3 sec afterwards
  const [isSuccess, setIsSuccess] = useState(false);

  const mutation = useExportAttendanceForGroupMutation();

  const handleClick = useCallback(() => {
    if (!group?.id || !validity?.date) {
      return;
    }

    mutation.mutate(
      { groupId: group.id, date: validity?.date },
      {
        onSuccess: (data) => {
          try {
            downloadFile(data, `${group.name ?? ''}.attendance.csv`);
            setIsSuccess(true);
          } catch (err) {
            showError(err as IntlError);
          }
        },
        onError: showError,
      },
    );
  }, [group?.id, group?.name, mutation, showError, validity?.date]);

  /** resets success icon in 3 sec */
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccess(false), 3 * 1000);
    }
  }, [isSuccess]);

  if (mode !== GroupAttendanceStatisticsMode.STUDENTS) {
    // Export by registers is not supported on BE side yet
    return null;
  }

  return (
    <Tooltip title={$t({ id: 'attendance-ExportToCSV' })}>
      <IconButton
        sx={{ '&&': { color: mutation.isLoading || isSuccess ? 'primary.main' : undefined } }}
        onClick={mutation.isLoading ? undefined : handleClick}
      >
        {(() => {
          if (mutation.isLoading) {
            return <Spin />;
          }

          if (isSuccess) {
            return <CheckIcon />;
          }

          return <DownloadIcon />;
        })()}
      </IconButton>
    </Tooltip>
  );
};
