import { IconButton, Stack, Typography } from '@mui/material';
import { CrossIcon, EditIcon, Loading, ModalHeader, ModalLarge } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import AccessDenied from '../../../components/common/AccessDenied';
import { CollapsiblePanel } from '../../../components/common/CollapsiblePanel';
import { ModalContent, ModalMain } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import { useMessage } from '../../../context/messages/useMessage';
import useAppLocation from '../../../hooks/useAppLocation';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import { MessageModalPreviewActions } from './MessageModalPreviewActions';
import { MessageModalPreviewMessage } from './MessageModalPreviewMessage';
import { MessageModalPreviewMode, MessagePreviewModalSideBar } from './MessagePreviewModalSideBar';
import { MessagesModalPreviewConsentForm } from './MessagesModalPreviewConsentForm';
import { MessagesModalPreviewRecipients } from './MessagesModalPreviewRecipients/MessagesModalPreviewRecipients';
import { MessagesModalPreviewSendTo } from './MessagesModalPreviewSendTo/MessagesModalPreviewSendTo';

export const MessagesPreviewModal: React.FC = () => {
  const {
    messageId,
    hasViewerPermission,
    title,
    fetching,
    canEdit,
    actions,
    creator,
    publisher,
    error,
  } = useMessage();

  const navigate = useNavigate();
  const location = useAppLocation();
  const { tab } = useQueryStringParams();

  const handleEditButtonClick = useCallback(() => {
    navigate(`/messages/${messageId}/edit`);
  }, [navigate, messageId]);

  useEffect(() => {
    if (!tab) {
      navigate(
        {
          search: `?tab=${MessageModalPreviewMode.MESSAGE}`,
        },
        { replace: true, state: location.state },
      );
    }
  });

  const showEditButton = !fetching && canEdit;

  const renderContent = () => {
    if (fetching) {
      return <Loading />;
    }

    if (!hasViewerPermission || error) {
      return (
        <>
          <ModalHeader withBorderBottom={false} active>
            <IconButton onClick={actions.handleClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <AccessDenied />
        </>
      );
    }

    return (
      <>
        <ModalHeaderV2
          withGoBack
          active
          title={title}
          subHeader={
            <Stack direction="row" gap={5}>
              {creator && (
                <Stack direction="row" gap={0.5}>
                  <Typography>
                    <FormattedMessage id="messages-From" />
                  </Typography>
                  <Typography>{getUserFullName(creator)}</Typography>
                </Stack>
              )}
              {publisher && (
                <Stack direction="row" gap={0.5}>
                  <Typography>
                    <FormattedMessage id="messages-Publisher" />
                  </Typography>
                  <Typography>{getUserFullName(publisher)}</Typography>
                </Stack>
              )}
            </Stack>
          }
        >
          <MessageModalPreviewActions />
          {showEditButton && (
            <IconButton onClick={handleEditButtonClick}>
              <EditIcon />
            </IconButton>
          )}

          <IconButton onClick={actions.handleClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeaderV2>

        <ModalMain>
          <CollapsiblePanel>
            <MessagePreviewModalSideBar />
          </CollapsiblePanel>

          <ModalContent
            sx={(theme) => ({
              pt: theme.spacing(2.5),
            })}
          >
            {tab === MessageModalPreviewMode.MESSAGE && <MessageModalPreviewMessage />}
            {tab === MessageModalPreviewMode.SEND_TO && <MessagesModalPreviewSendTo />}
            {tab === MessageModalPreviewMode.RECIPIENTS && <MessagesModalPreviewRecipients />}
            {tab === MessageModalPreviewMode.CONSENT_FORM && <MessagesModalPreviewConsentForm />}
          </ModalContent>
        </ModalMain>
      </>
    );
  };

  return (
    <ModalLarge open onClose={actions.handleClose}>
      {renderContent()}
    </ModalLarge>
  );
};
