import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C3.89543 2 3 2.89543 3 4V16C3 17.1046 3.89543 18 5 18H15C16.1046 18 17 17.1046 17 16V4C17 2.89543 16.1046 2 15 2H10V9C10 9.20223 9.87818 9.38455 9.69134 9.46194C9.5045 9.53933 9.28945 9.49655 9.14645 9.35355L7.5 7.70711L5.85355 9.35355C5.71055 9.49655 5.4955 9.53933 5.30866 9.46194C5.12182 9.38455 5 9.20223 5 9V2ZM6 2V7.79289L7.14645 6.64645C7.34171 6.45118 7.65829 6.45118 7.85355 6.64645L9 7.79289V2H6Z"
      fill="#B3BBCB"
    />
  </svg>
);
