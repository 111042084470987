import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 10C1 5.02944 5.02944 1 10 1C10 2.12878 10.6234 3.11196 11.5447 3.62401C11.1998 4.16622 11 4.80978 11 5.5C11 7.433 12.567 9 14.5 9C15.0631 9 15.5951 8.86702 16.0664 8.63076C16.3525 9.96752 17.529 10.9741 18.9451 10.9995C18.4479 15.4997 14.6327 19 10 19C5.02944 19 1 14.9706 1 10ZM5.25 7.5C4.83579 7.5 4.5 7.16421 4.5 6.75C4.5 6.33579 4.83579 6 5.25 6C5.66421 6 6 6.33579 6 6.75C6 7.16421 5.66421 7.5 5.25 7.5ZM7 14C7 14.5523 7.44772 15 8 15C8.55228 15 9 14.5523 9 14C9 13.4477 8.55228 13 8 13C7.44772 13 7 13.4477 7 14ZM12.75 12.5C12.3358 12.5 12 12.1642 12 11.75C12 11.3358 12.3358 11 12.75 11C13.1642 11 13.5 11.3358 13.5 11.75C13.5 12.1642 13.1642 12.5 12.75 12.5Z"
      fill="white"
    />
  </svg>
);
