import { Button, Stack, Typography } from '@mui/material';
import { ArrowRightIcon } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import SchoolIncompleteImg from '../../assets/images/school_incomplete.svg';
import { useSchool } from '../../hooks/useSchool';
import { SchoolPageIncompleteOption } from './SchoolPageIncompleteOption';

export const SchoolPageIncompleted: FC = () => {
  const { hasSchoolYears, hasStatuses, hasSystemRole } = useSchool();
  const { $t } = useIntl();

  return (
    <Stack alignItems="center" mt={5}>
      <img src={SchoolIncompleteImg} alt={$t({ id: 'school-incomplete-Title' })} />
      <Typography variant="h1" mt={5}>
        <FormattedMessage id="school-incomplete-Title" />
      </Typography>
      <Typography
        variant="h3"
        color="text.primary"
        mt={2.5}
        sx={{ whiteSpace: 'pre', textAlign: 'center' }}
      >
        <FormattedMessage id="school-incomplete-Subtitle" />
      </Typography>
      <Stack direction="row" alignItems="center" gap={3} mt={2.5}>
        <SchoolPageIncompleteOption isActive={hasSchoolYears}>
          <FormattedMessage id="school-tabs-SchoolYears" />
        </SchoolPageIncompleteOption>
        <SchoolPageIncompleteOption isActive={hasStatuses}>
          <FormattedMessage id="school-tabs-Statuses" />
        </SchoolPageIncompleteOption>
        <SchoolPageIncompleteOption isActive={Boolean(hasSystemRole)}>
          <FormattedMessage id="school-tabs-Access" />
        </SchoolPageIncompleteOption>
      </Stack>

      <Link to="/settings/tune">
        <Button endIcon={<ArrowRightIcon />} sx={{ mt: 4 }}>
          <FormattedMessage id="action-Configure" />
        </Button>
      </Link>
    </Stack>
  );
};
