import { Tooltip, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type SchoolPeriodLabelProps = {
  label: string;
  dateRange: ReactNode;
};

export const SchoolPeriodLabel: FC<SchoolPeriodLabelProps> = ({ dateRange, label }) => {
  return (
    <Tooltip title={dateRange}>
      <Typography
        color="text.primary"
        sx={{
          width: 'fit-content',
          cursor: 'default',
          '&:hover': {
            color: 'primary.main',
          },
        }}
      >
        {label}
      </Typography>
    </Tooltip>
  );
};
