import { useTheme } from '@mui/material';
import { Event } from '@schooly/api';
import { EventsInvite } from '@schooly/constants';
import { useMemo } from 'react';

export const useEventColor = (event?: Pick<Event, 'invitee_type'>) => {
  const theme = useTheme();

  return useMemo(() => {
    switch (event?.invitee_type) {
      case EventsInvite.ParentsMustAttend:
        return theme.palette.primary.main;
      case EventsInvite.ParentsAreWelcome:
        return theme.palette.common.green1;
      default:
        return theme.palette.common.grey;
    }
  }, [
    event?.invitee_type,
    theme.palette.common.green1,
    theme.palette.common.grey,
    theme.palette.primary.main,
  ]);
};
