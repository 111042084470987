import { StudentSchoolRelation, SyncUser } from '@schooly/api';
import {
  DATE_FORMAT,
  Genders,
  Languages,
  USER_NATIONALITIES_MAX_COUNT,
  USER_OTHER_LANGUAGES_MAX_COUNT,
} from '@schooly/constants';
import {
  DateIcon,
  FemaleIcon,
  FlagIcon,
  Language2Icon,
  Language3Icon,
  MaleIcon,
  TagSelect,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isNotEmpty } from '@schooly/utils/predicates';
import { getUserNationalitiesList, getUserNationalityValues } from '@schooly/utils/user-helpers';
import { differenceInYears, format } from 'date-fns';

export const getStudentProfilePropertiesArray = (user: SyncUser | StudentSchoolRelation) => {
  const dateOfBirth = format(newDateTimezoneOffset(user.date_of_birth), DATE_FORMAT);
  const yearsOld = differenceInYears(new Date(), new Date(user.date_of_birth ?? ''));
  const gender = Number.isInteger(user.gender) ? Genders[user.gender!] : null;
  const nationalities = getUserNationalitiesList(user);
  const language = Number.isInteger(user.language) ? Languages[user.language!] : '';
  const otherLanguages =
    Array.isArray(user.other_languages_spoken) &&
    user.other_languages_spoken.map((item: Languages) => Languages[item]).join(', ');

  return [
    {
      icon: <DateIcon />,
      property: dateOfBirth,
      tag: (
        <TagSelect label={`${yearsOld} y.o`} sx={{ '&.MuiChip-root': { color: 'text.primary' } }} />
      ),
    },
    {
      icon: user.gender === Genders.Female ? <FemaleIcon /> : <MaleIcon />,
      property: gender,
    },
    {
      icon: <FlagIcon />,
      property: nationalities,
    },
    {
      icon: <Language3Icon className="reset-svg-currentColor" />,
      property: language,
    },
    {
      icon: <Language2Icon className="reset-svg-currentColor" />,
      property: otherLanguages,
    },
  ];
};

export const getStudentRelationAfterMergePreview = (
  relationToBeDeleted: SyncUser | StudentSchoolRelation,
  relationToBePreserved: SyncUser | StudentSchoolRelation,
) => {
  const relationAfterMergePreview = { ...relationToBePreserved };

  //known as
  if (!isNotEmpty(relationToBePreserved.known_as) && isNotEmpty(relationToBeDeleted.known_as)) {
    relationAfterMergePreview.known_as = relationToBeDeleted.known_as;
  }

  //gender
  if (!isNotEmpty(relationToBePreserved.gender) && isNotEmpty(relationToBeDeleted.gender)) {
    relationAfterMergePreview.gender = relationToBeDeleted.gender;
  }

  //languages
  const primaryLanguages = [];
  if (isNotEmpty(relationToBePreserved.language)) {
    primaryLanguages.push(relationToBePreserved.language);
  }
  if (isNotEmpty(relationToBeDeleted.language)) {
    primaryLanguages.push(relationToBeDeleted.language);
  }
  const allLanguages = new Set([
    ...primaryLanguages,
    ...(relationToBePreserved.other_languages_spoken ?? []),
    ...(relationToBeDeleted.other_languages_spoken ?? []),
  ]);
  const uniqueLanguages = Array.from(allLanguages);
  const primaryLanguageToBePreserved = uniqueLanguages[0];
  const otherLanguagesToBePreserved = uniqueLanguages.slice(1, 1 + USER_OTHER_LANGUAGES_MAX_COUNT);

  relationAfterMergePreview.language = primaryLanguageToBePreserved;
  relationAfterMergePreview.other_languages_spoken = otherLanguagesToBePreserved;

  //nationalities
  const nationalitiesToBePreserved = getUserNationalityValues(relationToBePreserved, false);
  const nationalitiesToBeDeleted = getUserNationalityValues(relationToBeDeleted, false);

  const allNationalities = new Set([...nationalitiesToBePreserved, ...nationalitiesToBeDeleted]);
  const uniqueNationalities = Array.from(allNationalities);
  const primaryNationalityToBePreserved = uniqueNationalities[0];
  const otherNationalitiesToBePreserved = uniqueNationalities.slice(
    1,
    1 + USER_NATIONALITIES_MAX_COUNT,
  );

  relationAfterMergePreview.nationality = primaryNationalityToBePreserved;
  relationAfterMergePreview.other_nationalities = otherNationalitiesToBePreserved;

  return relationAfterMergePreview;
};
