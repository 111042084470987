import { IContextAction } from '@schooly/api';
import React, { useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useVirtualBackdrop from '../../../hooks/useVirtualBackdrop';
import buildClassName from '../../../utils/buildClassName';

import './index.scss';

interface IProps {
  actions: IContextAction[];
  trigger: React.FC<{
    toggleOpen: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    isOpen: boolean;
  }>;
  className?: string;
  // TODO: Add more position options, make arrow position more dynamic
  position?: 'right' | 'bottom' | 'bottom left';
}

const ContextMenu: React.FC<IProps> = ({ actions, trigger, className, position = 'bottom' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  function closeMenu() {
    setIsOpen(false);
  }

  useVirtualBackdrop(isOpen, closeMenu, dropdownRef);

  function toggleOpen(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.stopPropagation();
    setIsOpen((o) => !o);
  }

  const containerClassName = buildClassName('ContextMenu', className);

  const dropdownClassName = buildClassName(
    'ContextMenu__dropdown',
    'dropdown-menu',
    'shadow-lg',
    isOpen && 'show',
  );

  const dropdownStyle: React.CSSProperties = useMemo(() => {
    switch (position) {
      case 'right':
        return {
          left: 'calc(100% + 0.5rem)',
          top: 0,
        };
      case 'bottom left':
        return {
          top: 'calc(100% + 0.5rem)',
          transform: 'translateX(calc(32px - 100%))',
        };
      case 'bottom':
      default:
        return {
          top: 'calc(100% + 0.5rem)',
          left: '50%',
          transform: 'translateX(-50%)',
        };
    }
  }, [position]);

  const arrowClassName = buildClassName('ContextMenu__arrow', position);

  if (!actions.length) return null;

  return (
    <div className={containerClassName} data-test-id="modal-contextmenu">
      {trigger({ toggleOpen, isOpen })}
      <div ref={dropdownRef} className={dropdownClassName} style={dropdownStyle}>
        <div className={arrowClassName} />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <ul className="list-unstyled mb-0" onClick={toggleOpen}>
          {actions.map((action) => (
            <li key={action.titleTextId}>
              <button
                type="button"
                onClick={action.handler}
                className="dropdown-item"
                data-test-id="modal-contextmenu-item"
              >
                <FormattedMessage id={action.titleTextId} />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ContextMenu;
