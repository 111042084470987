import { getLabelIdForOption } from '@schooly/constants';
import { Language2Icon, LanguageIcon } from '@schooly/style';
import React from 'react';
import { useIntl } from 'react-intl';

import Languages from '../../../constants/languages';
import { useProfile } from '../../../context/profile/useProfile';
import LanguageModal from './LanguageModal';
import ProfileProperty from './ProfileProperty';

interface LanguagePropertyParams {
  size?: 'lg' | 'md' | 'sm';
  value?: Languages | Languages[] | null;
  color?: string;
  iconColor?: string;
  multiple?: boolean;
}

const LanguageProperty = ({
  value: initialValue,
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
  multiple,
}: LanguagePropertyParams) => {
  const { canEditProfile, hasEditingPermission } = useProfile();
  const { formatMessage } = useIntl();

  const editValueTextId = multiple
    ? 'peopleDetail-AddAdditionalLanguage'
    : 'peopleDetail-AddPrimaryLanguage';

  const valueTextId = multiple
    ? 'peopleDetail-AdditionalLanguage-plural'
    : 'peopleDetail-PrimaryLanguage';

  return (
    <ProfileProperty
      value={initialValue}
      canEdit={canEditProfile && hasEditingPermission}
      params={{ size, iconColor, color }}
      renderIcon={() =>
        multiple ? <Language2Icon /> : <LanguageIcon className="reset-svg-currentColor" />
      }
      renderValue={(value) =>
        Array.isArray(value)
          ? value
              .map((item: Languages) =>
                formatMessage({
                  id: getLabelIdForOption({
                    key: Languages[item],
                    optionsKey: 'language',
                  }),
                }),
              )
              .join(', ')
          : formatMessage({
              id: getLabelIdForOption({
                key: Languages[value],
                optionsKey: 'language',
              }),
            })
      }
      editValueTextId={editValueTextId}
      valueTextId={valueTextId}
      fieldName={multiple ? 'other_languages_spoken' : 'language'}
      modalController={({ isOpen, hide }) => <LanguageModal isOpen={isOpen} onClose={hide} />}
    />
  );
};

export default LanguageProperty;
