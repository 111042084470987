import { theme } from '@schooly/style';
import { TooltipComponentFormatterCallbackParams } from 'echarts';
import { CSSProperties, useCallback } from 'react';
import { FC } from 'react';
import { renderToString } from 'react-dom/server';

interface ChartTooltipProps extends CSSProperties {
  backgroundColor?: string;
  title: string;
  value: number;
}

export const ChartTooltip: FC<ChartTooltipProps> = ({ title, value, ...props }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 110,
        alignItems: 'center',
        gap: theme.spacing(0.75),
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(0.75),
        }}
      >
        <div
          style={{
            height: theme.spacing(1),
            width: theme.spacing(1),
            borderRadius: '50%',
            ...props,
          }}
        />
        {title}
      </div>
      {value}
    </div>
  );
};

// To provide additional styles in the tooltip
type TooltipOptions = {
  seriesName: string;
} & CSSProperties;

export const useRenderTooltip = (showSeriesName?: boolean, options?: TooltipOptions) => {
  return useCallback(
    (params: TooltipComponentFormatterCallbackParams): string => {
      if (Array.isArray(params)) {
        throw new Error('Array is not accepted in renderTooltip');
      }

      if (!params.value) {
        return '';
      }

      const { seriesName: optionSeriesName, ...restOptions } = options ?? {};

      const props = !!optionSeriesName && optionSeriesName === params.seriesName ? restOptions : {};

      const seriesName = params.seriesName ?? '';

      return renderToString(
        <ChartTooltip
          title={showSeriesName ? seriesName : params.name}
          value={typeof params.value === 'number' ? params.value : 0}
          backgroundColor={typeof params.color === 'string' ? params.color : undefined}
          {...props}
        />,
      );
    },
    [options, showSeriesName],
  );
};
