import { Icon, Stack, StackProps } from '@mui/material';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';

type DropdownSelectRowProps = {
  isSelected?: boolean;
} & StackProps;
export const DropdownSelectRow = ({
  isSelected,
  children,
  ...stackProps
}: DropdownSelectRowProps) => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      px={1}
      gap={2}
      py={0.5}
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
      {...stackProps}
    >
      <TypographyWithOverflowHint variant="h3" color={!isSelected ? 'common.grey2' : undefined}>
        {children}
      </TypographyWithOverflowHint>
      <Icon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <DoneIcon />
      </Icon>
    </Stack>
  );
};
