import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { FilterSelectOption } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { SchoolPropertyType } from '@schooly/constants';
import { ArchiveIcon, CheckIcon, CrossIcon, DropdownIcon, LockIcon } from '@schooly/style';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import useDropdown from '../../../hooks/useDropdown';
import useKeyboardListNavigation from '../../../hooks/useKeyboardListNavigation';
import useVirtualBackdrop from '../../../hooks/useVirtualBackdrop';
import buildClassName from '../../../utils/buildClassName';
import captureKeyboardListeners from '../../../utils/captureKeyboardListeners';
import searchWords from '../../../utils/searchWords';
import Preloader from '../../uikit/Preloader/Preloader';
import Tag from '../Tag';
import { renderError, renderLabel } from './helpers';
import {
  BaseFormInputProps,
  getIsFieldTouched,
  getSelectedOptions,
  hasFieldValue,
  useFormInput,
} from './utils';

import './FormSelect.scss';

type PickedInputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'autoComplete' | 'multiple' | 'defaultValue'
>;

interface IProps extends BaseFormInputProps, PickedInputProps {
  propertyType?: SchoolPropertyType;
  options: FilterSelectOption[];
  value?: any;
  maxCount?: number;
  canRemove?: boolean;
  hideErrorOnChange?: boolean;
  hideArrow?: boolean;
  disabledValue?: string;
  hiddenOptions?: FilterSelectOption[];
  isLoading?: boolean;
  locked?: boolean;
  endIcon?: JSX.Element;
  allArchivedEmptyStub?: JSX.Element;
}

const FOCUS_SUPRESS_DURATION = 300;
/**
 * @deprecated Use FormSelect2 instead
 *
 * This component is not utilizing react-hook-form standard
 * reduce using it as much as possible to fully migrate to utilizing react-hook-form
 */
const FormSelect: React.FC<IProps> = (props) => {
  const {
    id,
    className,
    propertyType,
    options,
    disabled: disabledProp,
    multiple,
    autoComplete,
    defaultValue = props.multiple ? [] : '',
    required,
    maxCount,
    hideArrow,
    canRemove,
    hideErrorOnChange,
    disabledValue,
    hiddenOptions,
    isLoading,
    locked,
    endIcon,
    allArchivedEmptyStub,
  } = props;

  const { formatMessage } = useIntl();
  const { getConfirmation } = useConfirmationDialog();
  const [bottomOffset, setBottomOffset] = useState(0);

  const disabled = locked || disabledProp;

  const { control, error, fullName, value } = useFormInput(props);
  const {
    formState: { isSubmitted },
  } = useFormContext();
  const isTouched = getIsFieldTouched(value, defaultValue);
  const selectedOption = getSelectedOptions(
    options,
    // Map values to a react-hook-form standards to use the same util
    Array.isArray(value)
      ? value.map((v) => ('value' in v ? (v as FilterSelectOption).value : v))
      : value,
    defaultValue,
  );

  const wrapperRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { isOpen, openPopup, closePopup, Popup, recalculatePopupPosition } = useDropdown(
    wrapperRef,
    multiple ? searchInputRef : inputRef,
    true,
  );

  // This helps with unwanted input focusing when clearing selected option
  const shouldSupressFocus = useRef<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideError, setHideError] = useState(isSubmitted ? hideErrorOnChange : false);

  const handleOpenPopup = useCallback(
    (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent> | React.FocusEvent<HTMLElement>) => {
      openPopup(e);
      setHideError(true);
    },
    [openPopup],
  );

  const handleBlur = useCallback(
    (onFormBlur: VoidFunction) => () => {
      onFormBlur();
      setHideError(false);
    },
    [],
  );

  function handleSearchQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
    const searchValue = e.currentTarget.value;

    setSearchQuery(searchValue);

    if (searchInputRef.current) {
      searchInputRef.current.style.width = searchValue?.length
        ? `${searchValue.length * 0.5}rem`
        : '0.5rem';
    }
  }

  function handleSearchFocus(e: React.FocusEvent<HTMLElement>) {
    if (shouldSupressFocus.current) {
      inputRef.current?.blur();
      searchInputRef.current?.blur();

      return;
    }

    searchInputRef.current?.focus();
    inputRef.current?.focus();
    handleOpenPopup(e);
  }

  const menuRef = useRef<HTMLUListElement>(null);
  const keyDownHandler = useKeyboardListNavigation(menuRef, isOpen, [searchQuery, value]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      shouldSupressFocus.current = true;
      keyDownHandler(e);

      setTimeout(() => {
        shouldSupressFocus.current = false;
      }, 0);
    },
    [keyDownHandler],
  );

  useVirtualBackdrop(isOpen, closePopup, multiple ? menuRef : wrapperRef, wrapperRef);

  useEffect(() => {
    setSearchQuery('');
  }, [isOpen]);

  useEffect(() => {
    recalculatePopupPosition();
  }, [recalculatePopupPosition, value, isOpen]);

  // On TAB click unfocus from portal popup back to select, then simmulte TAB click again
  const handleTabClick = useCallback(() => {
    inputRef.current?.focus();
    closePopup();
    document.dispatchEvent(new KeyboardEvent('keypress', { key: 'Tab' }));
  }, [closePopup]);

  useEffect(
    () => (isOpen ? captureKeyboardListeners({ onTab: handleTabClick }) : undefined),
    [isOpen, handleTabClick],
  );

  const getOptionText = useCallback(
    (option: FilterSelectOption) =>
      option.labelTextId ? formatMessage({ id: option.labelTextId }) : option.label,
    [formatMessage],
  );

  const isOptionActive = useCallback(
    (option: FilterSelectOption) => {
      if (multiple) {
        return !!(selectedOption as FilterSelectOption[])?.find(
          (item) => item.value.toString() === option.value.toString(),
        );
      }

      return selectedOption && option.value === (selectedOption as FilterSelectOption).value;
    },
    [multiple, selectedOption],
  );

  const getOptionClassName = useCallback(
    (option: FilterSelectOption) =>
      buildClassName('dropdown-item form-select-option', isOptionActive(option) && 'active'),
    [isOptionActive],
  );

  const displayedOptions = useMemo(() => {
    const hiddenValues = hiddenOptions?.map((o) => o.value);
    const filteredOptions = options?.filter((o) => !o.archived && !hiddenValues?.includes(o.value));

    if (!searchQuery) {
      return filteredOptions;
    }

    return filteredOptions?.filter((o) => {
      const text = getOptionText(o);
      return text && searchWords(text, searchQuery);
    });
  }, [options, searchQuery, getOptionText, hiddenOptions]);

  const handleValueRemove = useCallback(
    async (
      onChange: (value: string | string[]) => void,
      e: React.MouseEvent<HTMLElement, MouseEvent>,
      d?: FilterSelectOption,
    ) => {
      if (canRemove === false) return;

      if (!multiple) {
        if ((selectedOption as FilterSelectOption)?.archived) {
          const isConfirmed = await getConfirmation({
            textId: `deselect-property-archived-${propertyType}`,
            textValues: { name: (selectedOption as FilterSelectOption).label },
          });

          if (isConfirmed) {
            onChange('');
          }
        } else {
          onChange('');
        }
      } else if (d?.archived) {
        const isConfirmed = await getConfirmation({
          textId: `deselect-property-archived-${propertyType}`,
          textValues: { name: d.label },
        });

        if (isConfirmed) {
          onChange(
            value?.filter(
              (item: FilterSelectOption) => item.value.toString() !== d.value.toString(),
            ),
          );
        }
      } else if (d) {
        onChange(
          value?.filter((item: FilterSelectOption) => item.value.toString() !== d.value.toString()),
        );
      } else {
        onChange([]);
      }
      setSearchQuery('');
    },
    [canRemove, multiple, selectedOption, getConfirmation, propertyType, value],
  );

  const handleDropdownIconClick = useCallback(
    (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
      if (!isOpen) {
        handleOpenPopup(e);

        return;
      }

      shouldSupressFocus.current = true;

      closePopup(e);

      setTimeout(() => {
        shouldSupressFocus.current = false;
      }, FOCUS_SUPRESS_DURATION);
    },
    [isOpen, handleOpenPopup, closePopup],
  );

  const handleSelectOption = useCallback(
    async (
      onChange: (newValue: any) => void,
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      clickedOption?: FilterSelectOption,
    ) => {
      shouldSupressFocus.current = true;
      e.stopPropagation();

      const option = e.currentTarget as HTMLButtonElement;

      const curOption = options?.find(
        (item: FilterSelectOption) => item.value.toString() === option.dataset.value?.toString(),
      );

      if (multiple) {
        if (
          value?.find(
            (item: FilterSelectOption) => item.value.toString() === clickedOption?.value.toString(),
          )
        ) {
          onChange(
            value?.filter(
              (item: FilterSelectOption) =>
                item.value.toString() !== clickedOption?.value.toString(),
            ),
          );
        } else if (!maxCount || value.length < maxCount) {
          onChange([...value, { value: curOption?.value }]);
        }
      } else {
        if ((selectedOption as FilterSelectOption)?.archived && propertyType) {
          const isConfirmed = await getConfirmation({
            textId: `deselect-property-archived-${propertyType}`,
            textValues: { name: (selectedOption as FilterSelectOption).label },
          });

          if (isConfirmed) {
            onChange(curOption?.value);
          }
        } else {
          onChange(curOption?.value);
        }
      }

      setSearchQuery('');

      if (!multiple) {
        closePopup();
      }

      setTimeout(() => {
        shouldSupressFocus.current = false;

        if (multiple) {
          searchInputRef.current?.focus();
        }
      }, FOCUS_SUPRESS_DURATION);
    },
    [options, multiple, value, maxCount, selectedOption, propertyType, getConfirmation, closePopup],
  );

  useEffect(() => {
    // this logic needed for the https://treehive.atlassian.net/browse/TR-1771
    if (!isOpen) return;

    setBottomOffset(0);

    setTimeout(() => {
      const list = menuRef.current;
      const listRect = list?.getBoundingClientRect();
      const listPosition = Number(listRect?.height) + Number(listRect?.top);

      if (listPosition > window.innerHeight) {
        const offset = listPosition - window.innerHeight;
        setBottomOffset(offset);
      }
    }, 300);
  }, [isOpen]);

  const fullClassName = buildClassName(
    'form-group form-select',
    disabled && 'disabled',
    isOpen && 'open',
    (isTouched || isOpen) && 'touched',
    error && !hideError && !isLoading && 'error',
    multiple && 'form-select-multiple',
    !hasFieldValue(value) && 'no-value',
    disabledValue && 'disabled-value',
    className,
  );

  const popupClassName = buildClassName(
    'form-select-popup',
    'list-unstyled',
    'dropdown-menu',
    'shadow-sm',
    isOpen && 'show',
  );

  const valueCanBeRemoved = isOpen && hasFieldValue(value) && !required && canRemove !== false;

  if (isLoading) {
    return (
      <Controller
        control={control}
        name={fullName}
        defaultValue={defaultValue}
        render={() => (
          <>
            <label
              className={fullClassName}
              ref={wrapperRef}
              onFocus={handleSearchFocus}
              onKeyDown={handleKeyDown}
            >
              {endIcon && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 13,
                    right: 16,
                  }}
                >
                  {endIcon}
                </Box>
              )}
              {!valueCanBeRemoved && !hideArrow && !endIcon && (
                <div
                  className="form-select-arrow"
                  role="button"
                  tabIndex={-1}
                  onClick={handleDropdownIconClick}
                >
                  {locked ? <LockIcon /> : <DropdownIcon />}
                </div>
              )}
              <input className="form-control form-select-search" disabled={disabled} />
              <Popup>
                <ul className={buildClassName(popupClassName, 'hide-scrollbar')}>
                  <Preloader />
                </ul>
              </Popup>
              {renderLabel(
                props,
                false,
                multiple && (selectedOption as FilterSelectOption[]).length > 1,
              )}
            </label>
          </>
        )}
      />
    );
  }

  return (
    <Controller
      control={control}
      name={fullName}
      defaultValue={defaultValue}
      render={({ onChange, onBlur }) => (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <label
            className={fullClassName}
            htmlFor={id}
            ref={wrapperRef}
            onFocus={handleSearchFocus}
            onKeyDown={handleKeyDown}
          >
            {multiple ? (
              <>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <div className="form-control" role="button" tabIndex={disabled ? -1 : 0} />
              </>
            ) : (
              <input
                id={id}
                className="form-control form-select-search"
                ref={inputRef}
                disabled={disabled}
                autoComplete={autoComplete}
                value={disabled ? disabledValue : searchQuery}
                onChange={handleSearchQueryChange}
                onBlur={handleBlur(onBlur)}
              />
            )}

            {multiple && (
              <div className="form-select-value-wrapper">
                <div className="form-select-value-wrapper-inner">
                  {(selectedOption as FilterSelectOption[]).map((item) => (
                    <Tag
                      key={item.value}
                      selectOption={item}
                      className="form-select-value"
                      onRemove={isOpen ? (e, o) => handleValueRemove(onChange, e, o) : undefined}
                    >
                      {item.archived ? (
                        <Stack direction="row" alignItems="center" gap={0.75}>
                          <ArchiveIcon />
                          <Typography>{getOptionText(item)}</Typography>
                        </Stack>
                      ) : (
                        <Typography>{getOptionText(item)}</Typography>
                      )}
                    </Tag>
                  ))}

                  <input
                    id={id}
                    className="form-select-search-input"
                    tabIndex={-1}
                    ref={searchInputRef}
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    onBlur={handleBlur(onBlur)}
                  />
                </div>
              </div>
            )}

            {!multiple && selectedOption && !searchQuery && (
              <div className="form-select-value-wrapper">
                <div className="form-select-value-wrapper-inner">
                  <div className="form-select-value">
                    {(() => {
                      const text = getOptionText(selectedOption as FilterSelectOption);

                      if ((selectedOption as FilterSelectOption).archived) {
                        const label = (
                          <Stack direction="row" alignItems="center" gap={0.75}>
                            <ArchiveIcon />
                            <span>{text}</span>
                          </Stack>
                        );

                        return propertyType ? (
                          <Tooltip
                            title={formatMessage({ id: `schoolProperty-Archived-${propertyType}` })}
                          >
                            {label}
                          </Tooltip>
                        ) : (
                          label
                        );
                      }

                      return text;
                    })()}
                  </div>
                </div>
              </div>
            )}

            {endIcon && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 13,
                  right: 16,
                }}
              >
                {endIcon}
              </Box>
            )}

            {valueCanBeRemoved && !endIcon && (
              <div
                className="form-select-remove"
                role="button"
                tabIndex={-1}
                onClick={(e) => handleValueRemove(onChange, e)}
              >
                <CrossIcon />
              </div>
            )}

            {!valueCanBeRemoved && !hideArrow && !endIcon && (
              <div
                className="form-select-arrow"
                role="button"
                tabIndex={-1}
                onClick={handleDropdownIconClick}
              >
                {locked ? <LockIcon /> : <DropdownIcon />}
              </div>
            )}

            <Popup>
              <ul
                ref={menuRef}
                className={popupClassName}
                style={{ paddingBottom: `${bottomOffset}px` }}
              >
                {!searchQuery && !!allArchivedEmptyStub && allArchivedEmptyStub}
                {!!searchQuery && !displayedOptions?.length && (
                  <li>
                    <span className="dropdown-item form-select-option form-select-no-options-found">
                      <FormattedMessage id="input-NoOptionsFound" />
                    </span>
                  </li>
                )}
                {displayedOptions?.map((option) => (
                  <li key={option.value}>
                    <button
                      type="button"
                      tabIndex={-1}
                      data-value={option.value}
                      className={getOptionClassName(option)}
                      onClick={(e) => handleSelectOption(onChange, e, option)}
                    >
                      {getOptionText(option)}
                      {isOptionActive(option) && <CheckIcon />}
                    </button>
                  </li>
                ))}
              </ul>
            </Popup>

            {renderLabel(
              { ...props, disabled },
              false,
              multiple && (selectedOption as FilterSelectOption[]).length > 1,
            )}
            {!hideError && renderError(error)}
          </label>
        </>
      )}
    />
  );
};

export default FormSelect;
