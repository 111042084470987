import { Typography } from '@mui/material';
import { BaseUser, User, UserType } from '@schooly/api';
import { IContextAction } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { DATE_FORMAT, SchoolUserRole } from '@schooly/constants';
import { StarIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { getRouteModalPathname } from '../../../helpers/misc';
import { getSchoolUserId } from '../../../helpers/school';
import { getUserFullName } from '../../../helpers/users';
import useAppLocation from '../../../hooks/useAppLocation';
import buildClassName from '../../../utils/buildClassName';
import Card from '../../ui/Card';
import { LongNameWithTooltip } from '../../uikit-components/LongNameWithTooltip/LongNameWithTooltip';
import { LongNameWithVerticalTooltip } from '../../uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { UserName, UserNameWrapper } from './PersonCardBasic.styled';

import './index.scss';

export interface PersonCardBasicProps extends PropsWithChildren {
  user: Omit<BaseUser, 'user_id' | 'email'> & {
    id?: string;
    user_id?: string;
    relation_id?: string;
  };
  userType?: UserType;
  hasFavoriteMark?: boolean;
  contextActions?: IContextAction[];
  className?: string;
  isSmall?: boolean;
  isListItem?: boolean;
  short?: boolean;
  showDateOfBirth?: boolean;
  onClick?: (userId: string) => void;
  profileSchoolContext?: SchoolUserRole;
  isUsernameClickable?: boolean;
  isUsernameLinkView?: boolean;
  tooltipType?: 'horizontal' | 'vertical';
  noAvatar?: boolean;
}

const PersonCardBasic: React.FC<PersonCardBasicProps> = ({
  user,
  userType,
  hasFavoriteMark,
  contextActions,
  className,
  showDateOfBirth,
  isSmall,
  isListItem,
  short,
  onClick,
  isUsernameClickable,
  isUsernameLinkView,
  profileSchoolContext,
  children,
  tooltipType = 'horizontal',
  noAvatar,
}) => {
  const location = useAppLocation();

  const handleUserNameClick = useCallback((event: React.MouseEvent) => {
    event?.stopPropagation();
  }, []);

  const UserNameElement: any = useMemo(
    () => (isUsernameClickable ? Link : 'div'),
    [isUsernameClickable],
  );

  const userNameElementProps = useMemo(() => {
    if (isUsernameClickable) {
      return {
        onClick: handleUserNameClick,
        to: {
          pathname: getRouteModalPathname(userType!, user),
          ...(profileSchoolContext !== undefined && { search: `?type=${profileSchoolContext}` }),
        },
        state: { backgroundLocation: location },
      };
    }

    return {};
  }, [handleUserNameClick, isUsernameClickable, location, profileSchoolContext, user, userType]);

  const dateOfBirth = (user as User)?.date_of_birth;

  const userNameIsUnderlinedLink = !!isUsernameLinkView && !!isUsernameClickable;

  return (
    <Card
      className={buildClassName('PersonCard', className)}
      contextActions={contextActions}
      onClick={
        onClick
          ? () => {
              const id = getSchoolUserId(user);

              if (id) {
                onClick(id);
              }
            }
          : undefined
      }
      isListItem={isListItem}
      testId="person-card"
      css={{ width: '100%' }}
    >
      {!noAvatar && <AvatarAuth user={user} />}
      {hasFavoriteMark && (
        <>
          <StarIcon className="Avatar__favorite-mark__backplane" />
          <StarIcon className="Avatar__favorite-mark" />
        </>
      )}

      {short ? (
        <div className="PersonCard__short">{getUserFullName(user)}</div>
      ) : (
        <UserNameWrapper
          hasEllipsis={userNameIsUnderlinedLink}
          isSmall={isSmall}
          tooltipType={tooltipType}
        >
          <UserNameElement className="PersonCard__info__username" {...userNameElementProps}>
            <UserName
              userNameIsUnderlinedLink={userNameIsUnderlinedLink}
              isUsernameClickable={isUsernameClickable}
              component={isUsernameLinkView ? 'span' : 'p'}
              variant={isSmall ? 'body1' : 'h3'}
            >
              {tooltipType === 'vertical' && (
                <LongNameWithVerticalTooltip
                  css={{
                    position: 'absolute',
                    width: '100%',
                    '&:hover .card-body': {
                      backgroundColor: 'transparent',
                    },
                    '&.UIKit-LongNameWithVerticalTooltip_tooltip': {
                      '&:hover .UIKit-LongNameWithVerticalTooltip__info': {
                        backgroundColor: 'white !important',
                      },
                    },
                  }}
                >
                  {getUserFullName(user)}
                </LongNameWithVerticalTooltip>
              )}
              {tooltipType === 'horizontal' && (
                <LongNameWithTooltip
                  css={{
                    position: 'absolute',
                    width: '100%',
                    '&:hover .card-body': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {getUserFullName(user)}
                </LongNameWithTooltip>
              )}
              {showDateOfBirth && dateOfBirth && (
                <Typography color="common.grey3">
                  {format(newDateTimezoneOffset(dateOfBirth), DATE_FORMAT)}
                </Typography>
              )}
            </UserName>
          </UserNameElement>
        </UserNameWrapper>
      )}

      {children}
    </Card>
  );
};

export default PersonCardBasic;
