import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ControlTextFieldProps } from './ControlTextField';
import { FormTextField } from './FormTextField';

type FormNumberSelectProps = {
  required?: boolean;
  name: string;
  min: number;
  max: number;
} & ControlTextFieldProps;

export const FormNumberSelect: FC<FormNumberSelectProps> = ({
  required,
  name,
  min,
  max,
  ...props
}) => {
  const { $t } = useIntl();

  const { control } = useFormContext<Record<string, number>>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const hasError = Boolean(error);
        const errorMessage = getControllerErrorText(error, props.rules, $t);
        return (
          <FormTextField
            inputRef={field.ref}
            {...props.rules}
            {...props}
            hiddenLabel
            showEndAdornment={false}
            required={required}
            type="number"
            error={hasError}
            sx={(theme) => ({
              '.MuiOutlinedInput-root .MuiOutlinedInput-input': {
                paddingRight: 0.5,
              },
              '.MuiFormHelperText-root:not(.Mui-error)': {
                ...theme.typography.caption,
                color: theme.palette.primary.main,
              },
              'p .Mui-error': {
                ...theme.typography.caption,
              },
              input: {
                '&::placeholder': {
                  opacity: 1,
                  color: theme.palette.common.grey,
                },
              },

              ...(Boolean(props.error) && {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.error.main,
                },
              }),
            })}
            InputProps={{
              ...props.InputProps,
              inputProps: {
                min,
                max,
              },
            }}
            {...field}
            onChange={(e) => {
              let value = parseInt(e.target.value, 10);
              if (value > max) value = max;

              field.onChange(value);
            }}
            onBlur={() => {
              if (isNaN(field.value) || field.value < min) field.onChange(min);
            }}
            helperText={errorMessage ?? props.helperText}
          />
        );
      }}
      {...props}
    />
  );
};
