import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import './index.scss';

const EmptyInbox: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="InboxModal__empty-wrapper">
      <picture>
        <source srcSet="/images/inbox-stub.png, /images/inbox-stub@2x.png 2x" />
        <img
          className="InboxModal__image-empty"
          src="/images/inbox-stub.png"
          alt={formatMessage({ id: 'messages-NoMessages' })}
        />
      </picture>
      <p className="GroupEmptyStub__text">
        <FormattedMessage id="messages-NoMessages" />
      </p>
    </div>
  );
};

export default EmptyInbox;
