import { Box, ClickAwayListener, Divider, Icon, Stack, Tooltip } from '@mui/material';
import { DEFAULT_FORMATTED_DATE_FORMAT_FNS, SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { LockIcon, Spin } from '@schooly/style';
import { addMonths, format, getDate, isSameDay, setDate } from 'date-fns';
import React, { FC, Fragment, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  AnnualPlannerCalendarDateTooltip,
  AnnualPlannerCalendarRecordCell,
  AnnualPlannerCalendarRecordEnd,
  AnnualPlannerCalendarRecordGroup,
  AnnualPlannerCalendarRecordTitle,
  AnnualPlannerCalendarRecordTitleTypography,
} from '../AnnualPlannerCalendar.styled';
import { useAnnualPlannerCreateRecord } from '../AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import {
  ANNUAL_PLANNER_CELL_HEIGHT,
  AnnualPlannerRecordMeta,
  AnnualPlannerRecordMetaWithRecurring,
  canBeRecurring,
} from '../scheme';
import {
  useAnnualPlannerCell,
  UseAnnualPlannerCellItem,
  UseAnnualPlannerCellProps,
} from '../useAnnualPlannerCell';
import { getCellStyleParams, getRecordIncompleteFields, getRecordLockHint } from '../utils';
import { useAnnualPlannerDnD } from '../WithAnnualPlannerDnD';
import { AnnualPlannerRecordsLayoutCellGroup } from './AnnualPlannerRecordsLayoutCellGroup';
import { AnnualPlannerRecordsLayoutRecordDetails } from './AnnualPlannerRecordsLayoutRecordDetails';

export interface AnnualPlannerRecordsLayoutCellProps extends UseAnnualPlannerCellProps {}

export const AnnualPlannerRecordsLayoutCell: FC<AnnualPlannerRecordsLayoutCellProps> = (props) => {
  const { $t } = useIntl();
  const { hasStartItems, groups } = useAnnualPlannerCell(props);
  const { isOpen: isEditOpen, record } = useAnnualPlannerCreateRecord();
  const { movingItem } = useAnnualPlannerDnD();

  const anchorTitles = useRef<Record<UseAnnualPlannerCellItem['id'], HTMLElement | null>>();
  const [anchorGroupId, setAnchorGroupId] = useState<string>();

  const handleClick = useCallback(
    (id: string) => () => {
      setAnchorGroupId(id);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorGroupId(undefined);
  }, []);

  const onGroupMouseEnter = useCallback<
    (record: AnnualPlannerRecordMetaWithRecurring) => React.MouseEventHandler
  >(
    (record) => () => {
      document
        .querySelectorAll(
          `.AnnualPlannerCalendarRecordGroup-${record.type}[data-recurrence-id="${record.details.recurring_state?.recurrence_id}"]`,
        )
        .forEach((record) => {
          record.classList.add('AnnualPlannerCalendarRecordGroup-highlight');
        });
    },
    [],
  );

  const onGroupMouseLeave = useCallback<React.MouseEventHandler>(() => {
    document
      .querySelectorAll(
        '.AnnualPlannerCalendarRecordGroup-root.AnnualPlannerCalendarRecordGroup-highlight',
      )
      .forEach((record) => {
        record.classList.remove('AnnualPlannerCalendarRecordGroup-highlight');
      });
  }, []);

  // Should display counts instead of title if there are 3+ groups on the date
  // https://schooly.atlassian.net/browse/TR-5364 - Design review - p.11
  const forceDisplayCounts =
    groups.filter((group) => isSameDay(group.start, props.date)).length > 2;

  if (!hasStartItems) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        {groups.map((group, index) => {
          if (!isSameDay(group.start, props.date)) {
            return null;
          }

          const isMoving = movingItem?.id === group.id;

          const isOpen = anchorGroupId === group.id;
          const isEdit =
            isEditOpen && record && group.records.some((item) => item.id === record.id);
          const isOpenOrEdit = isOpen || isEdit;

          let records: AnnualPlannerRecordMeta[] = [];

          const firstRecord = group.records[0];

          const isIncomplete =
            group.records.length === 1 && getRecordIncompleteFields(firstRecord).length > 0;

          const isRecurring = Boolean(
            group.records.length === 1 &&
              canBeRecurring(firstRecord) &&
              firstRecord.details.recurring_state?.recurrence_id,
          );

          let count = 0;

          switch (group.size) {
            case 'wide':
              count = group.records.length;
              records = group.records;
              break;
            case 'narrow':
              // for a most top narrow group need to calculate all records of all narrow groups
              records = groups
                .filter((group) => group.size === 'narrow' && isSameDay(group.start, props.date))
                .reduce<AnnualPlannerRecordMeta[]>((prev, group) => {
                  prev.push(...group.records);
                  return prev;
                }, []);
              count = records.length;
              break;
          }

          const lockHint =
            !forceDisplayCounts && count === 1 && getRecordLockHint(group.records[0]);

          return (
            <AnnualPlannerRecordsLayoutCellGroup
              group={group}
              key={group.id}
              draggable={group.records.length === 1 && !lockHint}
              type={group.type}
              incomplete={isIncomplete}
              className={[
                AnnualPlannerCalendarRecordGroup.defaultProps?.className,
                `AnnualPlannerCalendarRecordGroup-${group.type}`,
                (isOpenOrEdit || isMoving) && 'AnnualPlannerCalendarRecordGroup-active',
              ]
                .filter(Boolean)
                .join(' ')}
              data-recurrence-id={
                isRecurring
                  ? (firstRecord as AnnualPlannerRecordMetaWithRecurring).details.recurring_state
                      ?.recurrence_id
                  : undefined
              }
              onClick={handleClick(group.id)}
              onMouseEnter={
                isRecurring
                  ? onGroupMouseEnter(firstRecord as AnnualPlannerRecordMetaWithRecurring)
                  : undefined
              }
              onMouseLeave={isRecurring ? onGroupMouseLeave : undefined}
            >
              {group.position.map((position, positionIndex) => {
                const { padding, gridColumnStart, titleColumnStart } = getCellStyleParams(
                  props.date,
                  group,
                  index,
                  groups,
                );

                // Calculate records count in the group, which will be displayed in a title
                // = 0 - don't display title at all (the narrow records behind others)
                // = 1 - display a record title as is
                // > 1 - display records number

                // for a most top narrow group need to calculate all records of all narrow groups
                if (
                  group.size === 'narrow' &&
                  positionIndex > 0
                  // Generally, should display a count only on the last narrow item in a stack
                  // but in this case there won't be an anchor element for a tooltip/popover,
                  // so currently will render the title for all narrow items in the stack
                  // TODO: maybe further might be improved, in case if the record background will
                  //   become transparent again
                  // && (index === groups.length - 1 || groups[index + 1].size === 'wide')
                ) {
                  count = 0;
                }

                const shouldDisplayCounts = forceDisplayCounts || count > 1;
                const title = shouldDisplayCounts ? count : count > 0 ? group.records[0].title : '';

                const daysCount = position.bottomDateNum - position.topDateNum;
                const dayHeight = daysCount > 1 ? `calc(${100 / daysCount}% - 1px)` : '100%';

                const lastPositionIndex = group.position.length - 1;
                const hasEndLabel =
                  positionIndex === lastPositionIndex && (lastPositionIndex > 0 || daysCount > 1);

                return (
                  <Box
                    sx={(theme) => ({
                      position: 'absolute',
                      top: position.top,
                      left: position.left,
                      right: position.right,
                      bottom: position.bottom,

                      display: 'grid',
                      gridTemplateColumns: padding.join(' '),

                      // the wrapper should not block pointer events for the elements behind it
                      // (hover on such elements should work)
                      pointerEvents: 'none',

                      zIndex: isOpenOrEdit ? theme.zIndex.modal : undefined,
                    })}
                  >
                    <AnnualPlannerCalendarRecordCell
                      type={group.type}
                      incomplete={isIncomplete}
                      sx={{
                        // as the wrapper disables its pointer events, need to enabled them on the child
                        // implicitly
                        pointerEvents: 'auto',

                        p: 0,
                        gridRowStart: 1,
                        gridColumnStart,
                        // single-day records should not overlap each other
                        gridColumnEnd:
                          positionIndex === 0 && daysCount === 1 ? gridColumnStart + 1 : undefined,
                      }}
                    >
                      {/*
                       * The following elements are needed just to display a date tooltip when
                       * hover over the record
                       */}
                      {new Array(daysCount).fill(true).map((item, index) => (
                        <AnnualPlannerCalendarDateTooltip
                          key={`day-${index}`}
                          title={format(
                            setDate(
                              addMonths(group.start, positionIndex),
                              position.topDateNum + index + 1,
                            ),
                            DEFAULT_FORMATTED_DATE_FORMAT_FNS,
                          )}
                        >
                          <Box
                            sx={{
                              height: dayHeight,
                            }}
                          />
                        </AnnualPlannerCalendarDateTooltip>
                      ))}

                      {hasEndLabel && isEdit && (
                        <AnnualPlannerCalendarRecordEnd
                          type={group.type}
                          sx={{
                            position: 'absolute',
                            height: dayHeight,
                            right: 0,
                            bottom: 0,
                            left: 0,
                          }}
                        >
                          <AnnualPlannerCalendarRecordTitleTypography ml={0.25}>
                            {format(group.end, SHORT_FORMATTED_DATE_FORMAT_FNS)}
                          </AnnualPlannerCalendarRecordTitleTypography>
                        </AnnualPlannerCalendarRecordEnd>
                      )}
                    </AnnualPlannerCalendarRecordCell>

                    {title && (
                      <AnnualPlannerCalendarRecordTitle
                        ref={(el) => {
                          if (!anchorTitles.current) {
                            anchorTitles.current = {};
                          }

                          anchorTitles.current![group.id] = el;
                        }}
                        type={group.type}
                        sx={{
                          gridRowStart: 1,
                          gridColumn: `${titleColumnStart} / span 1`,
                          height: dayHeight,
                          justifyContent: shouldDisplayCounts ? 'center' : 'flex-start',
                          pointerEvents: 'none',
                          borderBottomRightRadius: daysCount === 1 ? 2 : undefined,
                        }}
                      >
                        {lockHint ? (
                          <Tooltip
                            title={$t({ id: lockHint })}
                            PopperProps={{
                              sx: {
                                '& .MuiTooltip-tooltip': {
                                  px: 0.5,
                                  py: 0.25,
                                  maxWidth: 'unset',
                                  whiteSpace: 'nowrap',
                                },
                              },
                            }}
                          >
                            <Icon
                              sx={(theme) => ({
                                mr: theme.spacing(0.25),
                                fontSize: theme.spacing(1.5),
                                pointerEvents: 'all',
                                zIndex: 1,
                              })}
                            >
                              <LockIcon />
                            </Icon>
                          </Tooltip>
                        ) : (
                          <Box sx={{ pl: 0.25 }} />
                        )}

                        <Tooltip
                          PopperProps={{
                            sx: {
                              '& .MuiTooltip-tooltip': {
                                p: 0,
                                maxWidth: 'unset',
                                width: 330,
                              },
                            },
                          }}
                          title={
                            <Stack
                              sx={{
                                p: 1,
                                maxHeight: `${
                                  Math.max(getDate(props.date), 31 - getDate(props.date)) *
                                  ANNUAL_PLANNER_CELL_HEIGHT
                                }px`,
                                overflow: 'auto',
                              }}
                            >
                              {records.map((record, index) => (
                                <Fragment key={record.id}>
                                  {index > 0 && (
                                    <Box p={1}>
                                      <Divider />
                                    </Box>
                                  )}

                                  <AnnualPlannerRecordsLayoutRecordDetails
                                    record={record}
                                    anchorEl={anchorTitles.current?.[group.id]}
                                    onClose={handleClose}
                                  />
                                </Fragment>
                              ))}
                            </Stack>
                          }
                          open={isOpen}
                          onClose={handleClose}
                          placement="bottom"
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                        >
                          <AnnualPlannerCalendarRecordTitleTypography>
                            {isEdit ? format(props.date, SHORT_FORMATTED_DATE_FORMAT_FNS) : title}
                          </AnnualPlannerCalendarRecordTitleTypography>
                        </Tooltip>

                        {isMoving && (
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            sx={{ flex: '1 1 auto', ml: 0.25, zIndex: 1 }}
                          >
                            <Spin />
                          </Stack>
                        )}
                      </AnnualPlannerCalendarRecordTitle>
                    )}
                  </Box>
                );
              })}
            </AnnualPlannerRecordsLayoutCellGroup>
          );
        })}
      </Box>
    </ClickAwayListener>
  );
};
