import { Box, IconButton, Stack, Typography } from '@mui/material';
import { EditIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../../components/uikit/Counter/Counter.styled';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../../components/uikit-components/Grid/Grid';
import { useSchool } from '../../../../../hooks/useSchool';
import { SchoolTuneHousesEmptyStub } from './SchoolTuneHousesEmptyStub';

export const SchoolTuneHouses: FC = () => {
  const { studentPropertiesMap } = useSchool();

  if (!studentPropertiesMap.house?.length) {
    return <SchoolTuneHousesEmptyStub />;
  }

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="school-tabs-Houses" />
          </Typography>
          {!!studentPropertiesMap.house?.length && (
            <Counter>{studentPropertiesMap.house?.length}</Counter>
          )}
        </Stack>

        <Link to="houses">
          <IconButton inverse>
            <EditIcon />
          </IconButton>
        </Link>
      </Stack>

      <Box sx={{ overflow: 'auto' }}>
        <GridContainer>
          {studentPropertiesMap.house?.map((house) => (
            <GridRowStyled key={house.id}>
              <GridRowItem>
                <GridRowName>{house.name}</GridRowName>
              </GridRowItem>
            </GridRowStyled>
          ))}
        </GridContainer>
      </Box>
    </Stack>
  );
};
