import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import get from 'lodash.get';
import React from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { getDatesFromPeriodKey, PeriodKey, PeriodSelect, PeriodSelectProps } from './PeriodSelect';

export type ControlPeriodSelectProps<TFieldValues extends Record<string, PeriodKey[]>> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> & {
  withSort?: boolean;
  singleSelect?: boolean;
  PeriodSelectProps: Omit<PeriodSelectProps, 'onSelect' | 'selectedPeriods'>;
};

export const ControlPeriodSelect = <TFieldValues extends Record<string, PeriodKey[]>>({
  withSort,
  singleSelect,
  PeriodSelectProps,
  control: propsControl,
  ...props
}: ControlPeriodSelectProps<TFieldValues>) => {
  const { control, formState } = useFormContext<TFieldValues>();
  const { $t } = useIntl();

  return (
    <Controller
      control={control}
      render={({ field }) => {
        const error = get(formState.errors, field.name);
        const hasError = Boolean(error);
        const errorMessage = getControllerErrorText<TFieldValues>(error, props.rules, $t);

        return (
          <PeriodSelect
            {...field}
            onSelect={({ key }) => {
              if (singleSelect) {
                field.onChange(key);
                return;
              }

              if (field.value.includes(key)) {
                field.onChange(field.value.filter((k) => k !== key));

                return;
              }
              const values = [...field.value, key];

              field.onChange(
                withSort
                  ? values.sort(
                      (a, b) =>
                        newDateTimezoneOffset(getDatesFromPeriodKey(a).start).getTime() -
                        newDateTimezoneOffset(getDatesFromPeriodKey(b).start).getTime(),
                    )
                  : values,
              );
            }}
            selectedPeriods={field.value}
            error={
              hasError
                ? {
                    type: 'validate',
                    message: errorMessage,
                  }
                : undefined
            }
            {...PeriodSelectProps}
          />
        );
      }}
      {...props}
      {...props.rules}
    />
  );
};
