import { Icon, Stack, Typography } from '@mui/material';
import { CheckIcon } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export const SchoolCustomFieldsDefaultStaff: FC = () => {
  return (
    <Stack direction="row" alignItems="flex-start" gap={3}>
      <Stack gap={1} flex="0 0 260px">
        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-Title" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-GivenNames" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-FamilyName" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-DateOfBirth" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-Gender" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-Nationality" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-Language-plural" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-StaffNumber" />
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={1} flex="0 0 260px">
        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-PhoneNumber" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-EmailAddress" />
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Icon sx={{ color: 'primary.main' }}>
            <CheckIcon />
          </Icon>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-PostalAddress" />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
