import { Box, Stack, styled } from '@mui/material';

export const ListViewGroupRowLayout = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  ':first-child': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  ':last-child': {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  ':not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  ':hover': {
    backgroundColor: theme.palette.common.lightBg,
  },
}));

export const GroupRowLayout = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  minWidth: 125,
  borderRadius: theme.shape.borderRadius,
  height: theme.spacing(0.5),
  overflow: 'hidden',
  cursor: 'pointer',
  background: theme.palette.common.light2,
}));
