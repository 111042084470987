import { ChipProps } from '@mui/material';
import { useGetConductTypeQuery } from '@schooly/api';
import { Spin, TagSelect } from '@schooly/style';
import { FC } from 'react';

export type ConductTypeTagSelectProps = {
  id: string;
} & Omit<ChipProps, 'label'>;

export const ConductTypeTagSelect: FC<ConductTypeTagSelectProps> = ({ id, ...props }) => {
  const { data } = useGetConductTypeQuery(id);

  return <TagSelect label={data ? data.name : <Spin />} {...props} />;
};
