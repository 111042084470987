import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 20 20"
  >
    <defs>
      <path
        id="warning-a"
        d="M14.4211899,0 L20,5.57881008 L20,14.4211899 L14.4211899,20 L5.57881008,20 L0,14.4211899 L0,5.57881008 L5.57881008,0 L14.4211899,0 Z M13.7839383,1.53846154 L6.21606171,1.53846154 L1.53846154,6.21606171 L1.53846154,13.7839383 L6.21606171,18.4615385 L13.7839383,18.4615385 L18.4615385,13.7839383 L18.4615385,6.21606171 L13.7839383,1.53846154 Z M9.23076923,4.87179487 L10.7692308,4.87179487 L10.7692308,11.025641 L9.23076923,11.025641 L9.23076923,4.87179487 Z M9.23076923,13.0769231 L10.7692308,13.0769231 L10.7692308,15.1282051 L9.23076923,15.1282051 L9.23076923,13.0769231 Z"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="warning-b">
        <use xlinkHref="#warning-a" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#warning-a" />
      <g mask="url(#warning-b)">
        <rect width="20" height="20" />
      </g>
    </g>
  </svg>
);
