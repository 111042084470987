import { PopoverOrigin, Stack } from '@mui/material';
import { CSSProperties, FC, PropsWithChildren, useMemo } from 'react';

import theme from '../../../../theme/theme';
import {
  DROPDOWN_COMMENTS_POPOVER_HEIGHT,
  DROPDOWN_COMMENTS_POPOVER_HEIGHT_SMALL,
  SCREEN_HEIGHT_SMALL,
} from '../DropdownComments';

interface CommentLayoutProps extends PropsWithChildren {
  placement?: PopoverOrigin;
  tableView?: boolean;
}

export const CommentLayout: FC<CommentLayoutProps> = ({ placement, tableView, children }) => {
  const maxHeight =
    window.innerHeight < SCREEN_HEIGHT_SMALL
      ? DROPDOWN_COMMENTS_POPOVER_HEIGHT_SMALL
      : DROPDOWN_COMMENTS_POPOVER_HEIGHT;

  const placementBorderRadius = useMemo(() => {
    if (!tableView) {
      return theme.spacing(1);
    }
    if (placement?.vertical === 'bottom') {
      return placement.horizontal === 'left'
        ? theme.spacing(0, 1, 1, 1)
        : theme.spacing(1, 0, 1, 1);
    }

    if (placement?.vertical === 'top') {
      return placement.horizontal === 'left'
        ? theme.spacing(1, 1, 1, 0)
        : theme.spacing(1, 1, 0, 1);
    }
  }, [placement?.horizontal, placement?.vertical, tableView]);

  const styles: CSSProperties =
    placement?.vertical === 'top'
      ? {
          resize: 'none',
          bottom: 0,
        }
      : {};

  return (
    <Stack
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={styles}
      sx={(theme) => ({
        background: theme.palette.background.paper,
        borderRadius: placementBorderRadius,
        border: theme.mixins.borderControlValue(),
        width: 400,
        maxHeight: maxHeight,
        overflowY: 'scroll',
        overflowX: 'hidden',
        position: 'absolute',
      })}
    >
      {children}
    </Stack>
  );
};
