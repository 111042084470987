import { Skeleton, SkeletonProps, Stack, StackProps } from '@mui/material';
import { FC } from 'react';

type SkeletonRowsComponentProps = {
  rowCount: number;
  variant?: SkeletonProps['variant'];
  height?: number;
} & StackProps;

export const SkeletonRowsComponent: FC<SkeletonRowsComponentProps> = ({
  rowCount,
  variant = 'rounded',
  height = 48,
  ...props
}) => {
  if (!rowCount) return null;

  return (
    <Stack gap={1.25} {...props}>
      {[...new Array(rowCount)].map((_, i) => (
        <Skeleton variant={variant} sx={{ borderRadius: 24 }} height={height} key={i} />
      ))}
    </Stack>
  );
};
