import { IconButton } from '@mui/material';
import { EyeCloseIcon, EyeIcon } from '@schooly/style';
import { useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form-lts/dist/types';

import { ControlTextField, ControlTextFieldProps } from './ControlTextField';

export const ControlPasswordField = <TFieldValues extends FieldValues = FieldValues>({
  hasValue,
  ...props
}: ControlTextFieldProps<TFieldValues>) => {
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

  const toggleVisible = useCallback(() => setPasswordVisible((t) => !t), []);

  return (
    <ControlTextField
      {...props}
      hasValue={hasValue}
      type={isPasswordVisible ? 'text' : 'password'}
      customIcon={
        <IconButton className="FormTextField-clear" onClick={toggleVisible}>
          {isPasswordVisible ? <EyeCloseIcon /> : <EyeIcon />}
        </IconButton>
      }
    />
  );
};
