import { FormHelperText, Icon, Stack, Typography } from '@mui/material';
import { ImageFormatIcon, ImageSizeIcon } from '@schooly/style';
import { FC, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  FormSchoolLogoField,
  FormSchoolLogoParam,
  FormSchoolLogoPreview,
  FormSchoolLogoStyled,
} from './FormSchoolLogoField';

export interface FormSchoolLogoProps {
  onChange?: (file?: File) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  width?: number;
  content?: React.ReactNode;
}

const FILE_MAX_SIZE = 200 * 1024;
const FILE_MAX_SIZE_TITLE = '200 kb';

export const FormSchoolLogo: FC<FormSchoolLogoProps> = ({
  error: errorProp,
  helperText: heplerTextProp,
  onChange,
  width,
  content,
}) => {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxSize: FILE_MAX_SIZE,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
  });

  const [file, preview] = useMemo(() => {
    const file = acceptedFiles[0];

    if (!file) {
      return [undefined, undefined];
    }

    return [file, URL.createObjectURL(file)];
  }, [acceptedFiles]);

  const errorMessage = useMemo(() => {
    const error = fileRejections[0]?.errors[0];

    if (error?.code === 'file-too-large') {
      return `File is larger than ${FILE_MAX_SIZE_TITLE}`;
    }
    return error?.message;
  }, [fileRejections]);

  const error = errorProp || Boolean(errorMessage);
  const isError = Boolean(error);
  const helperText = heplerTextProp ?? errorMessage;

  useEffect(() => {
    onChange?.(file);
  }, [file, onChange]);

  return (
    <FormSchoolLogoStyled width={width}>
      <FormSchoolLogoField width={width} {...getRootProps()} error={isError}>
        {preview ? (
          <FormSchoolLogoPreview preview={preview} />
        ) : (
          <>
            <input {...getInputProps()} />
            {content ? (
              content
            ) : (
              <>
                <Stack alignItems="center" justifyContent="center">
                  <Typography color="inherit" variant="h3">
                    Drag and drop
                  </Typography>
                  <Typography color="inherit">
                    or{' '}
                    <Typography component="span" color="primary">
                      browse
                    </Typography>{' '}
                    a school logo
                  </Typography>
                </Stack>

                <Stack alignItems="center" justifyContent="center" gap={1}>
                  <FormSchoolLogoParam>
                    <Icon fontSize="small">
                      <ImageSizeIcon />
                    </Icon>
                    <span>max size 200 Kb</span>
                  </FormSchoolLogoParam>
                  <FormSchoolLogoParam>
                    <Icon fontSize="small">
                      <ImageFormatIcon />
                    </Icon>
                    <span>PNG, JPEG</span>
                  </FormSchoolLogoParam>
                </Stack>
              </>
            )}
          </>
        )}
      </FormSchoolLogoField>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormSchoolLogoStyled>
  );
};
