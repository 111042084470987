import { FC, HTMLAttributes } from 'react';

export const ApplicationLargeSvg: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M201.5 11.7378L201.5 211.417L66.5 265.262L66.5 65.6028L201.5 11.7378Z"
      fill="white"
      stroke="#C7C8E0"
      stroke-miterlimit="10"
    />
    <path d="M81 76.4768L81 278L218 223.543L218 22L81 76.4768Z" fill="#24275B" />
    <path
      d="M233.5 34.7358L233.5 234.416L97.5 288.264L97.5 88.6037L233.5 34.7358Z"
      fill="white"
      stroke="#C7C8E0"
      stroke-miterlimit="10"
    />
    <path d="M112 166.411L221 123" stroke="#E7E9F4" stroke-miterlimit="10" />
    <path d="M112 191.411L221 148" stroke="#E7E9F4" stroke-miterlimit="10" />
    <path d="M112 216.411L221 173" stroke="#E7E9F4" stroke-miterlimit="10" />
    <path d="M112 241.411L221 198" stroke="#E7E9F4" stroke-miterlimit="10" />
    <path
      d="M187.243 126.198C199.996 112.357 201.793 93.2707 191.257 83.5681C180.722 73.8656 161.843 77.2207 149.091 91.0619C136.338 104.903 134.541 123.989 145.076 133.692C155.612 143.394 174.49 140.039 187.243 126.198Z"
      fill="#E7E9F4"
    />
    <path
      d="M158.456 121.978C162.086 117.548 162.623 111.985 159.655 109.553C156.687 107.121 151.338 108.741 147.708 113.172C144.078 117.602 143.541 123.165 146.509 125.597C149.477 128.029 154.826 126.409 158.456 121.978Z"
      fill="#24275B"
    />
    <path
      d="M160.149 109.112C163.779 104.681 164.315 99.1185 161.347 96.6866C158.379 94.2546 153.03 95.8745 149.4 100.305C145.77 104.735 145.234 110.298 148.202 112.73C151.17 115.162 156.518 113.542 160.149 109.112Z"
      fill="#24275B"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M179.25 120.514C179.25 124.652 176.644 129.403 172.609 133.286C171.331 134.001 170.054 134.614 168.726 135.125C159.326 138.905 151.101 137.168 146.555 131.498C147.832 125.01 154.626 117.704 162.8 114.435C171.842 110.859 179.25 113.566 179.25 120.514Z"
      fill="#C7C8E0"
    />
    <path
      d="M165.815 104.013L159.582 123.63C158.509 127.001 160.042 129.658 162.954 129.556C165.917 129.453 169.135 126.644 170.208 123.272L176.441 103.655L165.815 104.013Z"
      fill="white"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M179.327 99.8694C180.9 97.949 181.132 95.5368 179.844 94.4818C178.557 93.4268 176.237 94.1283 174.664 96.0488C173.09 97.9693 172.858 100.381 174.146 101.436C175.433 102.491 177.753 101.79 179.327 99.8694Z"
      fill="white"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M180.731 104.677C180.731 109.888 176.542 115.763 171.332 117.806C168.164 119.084 165.355 118.624 163.669 116.887L163.618 116.836L163.107 116.172L157.487 109.326L168.726 92.7743L177.053 91.446L180.169 101.561C180.527 102.481 180.731 103.502 180.731 104.677Z"
      fill="white"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M158.969 108.865C158.458 109.07 157.947 109.223 157.436 109.376C156.466 108.15 155.904 106.413 155.904 104.37C155.904 98.3415 160.808 91.496 166.836 89.0438C171.74 87.1026 175.878 88.6351 177.258 92.5177C176.338 93.1307 175.368 93.6926 174.346 94.1013"
      fill="#24275B"
    />
    <path
      d="M158.969 108.865C158.458 109.07 157.947 109.223 157.436 109.376C156.466 108.15 155.904 106.413 155.904 104.37C155.904 98.3415 160.808 91.496 166.836 89.0438C171.74 87.1026 175.878 88.6351 177.258 92.5177C176.338 93.1307 175.368 93.6926 174.346 94.1013"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M170.028 111C170.028 110.922 170 110.844 170 110.767C170 109.729 170.889 108.562 172 108.147C173.111 107.732 174 108.225 174 109.262C174 109.651 173.861 110.066 173.667 110.429"
      fill="white"
    />
    <path
      d="M170.028 111C170.028 110.922 170 110.844 170 110.767C170 109.729 170.889 108.562 172 108.147C173.111 107.732 174 108.225 174 109.262C174 109.651 173.861 110.066 173.667 110.429"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M161.575 112.749C159.531 113.567 157.896 112.596 157.896 110.553C157.896 108.509 159.531 106.21 161.575 105.393"
      fill="white"
    />
    <path
      d="M161.575 112.749C159.531 113.567 157.896 112.596 157.896 110.553C157.896 108.509 159.531 106.21 161.575 105.393"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M173.835 97.0139C175.521 95.2259 177.82 94.7662 178.944 95.9922L173.835 97.0139Z"
      fill="white"
    />
    <path
      d="M173.835 97.0139C175.521 95.2259 177.82 94.7662 178.944 95.9922"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M168.176 103.599C168.176 103.599 168.177 103.6 168.178 103.603C168.176 103.601 168.176 103.599 168.176 103.599ZM168.105 103.638C168.142 103.626 168.167 103.622 168.183 103.622C168.185 103.638 168.187 103.663 168.183 103.701C168.171 103.811 168.112 103.967 167.985 104.122C167.859 104.276 167.718 104.364 167.612 104.398C167.576 104.409 167.55 104.413 167.534 104.413C167.532 104.398 167.53 104.372 167.534 104.334C167.546 104.224 167.605 104.068 167.732 103.914C167.858 103.759 168 103.671 168.105 103.638ZM167.511 104.411C167.511 104.411 167.512 104.411 167.514 104.412C167.512 104.412 167.511 104.411 167.511 104.411ZM167.541 104.436C167.541 104.436 167.54 104.435 167.539 104.433C167.541 104.435 167.541 104.436 167.541 104.436ZM168.203 103.623C168.205 103.623 168.206 103.624 168.206 103.624C168.206 103.624 168.205 103.624 168.203 103.623Z"
      fill="black"
      stroke="#24275B"
    />
    <path
      d="M176.519 98.2289C176.519 98.2288 176.52 98.2298 176.521 98.2322C176.519 98.2302 176.519 98.229 176.519 98.2289ZM176.448 98.2672C176.484 98.2555 176.51 98.2519 176.526 98.2514C176.528 98.2673 176.53 98.2927 176.525 98.3311C176.513 98.4408 176.455 98.5968 176.328 98.7512C176.202 98.9057 176.06 98.9937 175.955 99.0272C175.918 99.0389 175.893 99.0425 175.877 99.0431C175.874 99.0272 175.873 99.0017 175.877 98.9633C175.889 98.8536 175.948 98.6977 176.074 98.5432C176.201 98.3887 176.342 98.3007 176.448 98.2672ZM175.854 99.0407C175.854 99.0406 175.855 99.0408 175.857 99.0419C175.855 99.0413 175.854 99.0408 175.854 99.0407ZM175.884 99.0655C175.884 99.0656 175.883 99.0646 175.882 99.0623C175.883 99.0643 175.884 99.0654 175.884 99.0655ZM176.545 98.2526C176.548 98.2531 176.549 98.2536 176.549 98.2537C176.549 98.2539 176.548 98.2536 176.545 98.2526Z"
      fill="black"
      stroke="#24275B"
    />
    <path d="M173.273 105.751L176.185 103.197L172.507 100.131" fill="white" />
    <path
      d="M173.273 105.751L176.185 103.197L172.507 100.131"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M164.691 103.502L178.229 91.7527C178.229 91.5994 178.229 91.4462 178.229 91.2418C178.229 86.5419 174.448 84.2942 169.748 86.1843C165.048 88.0745 161.268 93.3875 161.268 98.0874C161.319 100.846 162.647 102.787 164.691 103.502Z"
      fill="#24275B"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M174.397 95.3294C174.397 95.1761 174.397 95.0228 174.397 94.8185C174.397 90.1186 170.616 87.8708 165.917 89.761"
      stroke="white"
      stroke-miterlimit="10"
    />
    <path
      d="M165.508 105.035C165.508 102.991 167.143 100.693 169.186 99.8752L165.508 105.035Z"
      fill="white"
    />
    <path
      d="M165.508 105.035C165.508 102.991 167.143 100.693 169.186 99.8752"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M135 176.488C135 176.488 159.223 149.653 160.066 159.628C160.628 166.091 155.521 170.962 152.945 172.039C150.368 173.116 149.852 164.593 166.954 154.477C184.055 144.361 174.028 163.89 171.123 164.546C168.219 165.201 166.204 155.741 188.506 146"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M138 203.174C138 203.174 155.242 184.16 159.084 184.675C162.879 185.144 154.117 192.215 157.397 193.246C160.677 194.276 173.327 177.557 178.012 178.119C182.698 178.681 172.531 185.986 178.668 184.582C184.806 183.177 194.786 171.234 201.158 171"
      stroke="#24275B"
      stroke-miterlimit="10"
    />
    <path
      d="M258.524 249.194C269.499 230.06 266.161 207.487 251.07 198.775C235.978 190.063 214.847 198.511 203.872 217.644C192.897 236.777 196.234 259.351 211.326 268.063C226.418 276.775 247.549 268.327 258.524 249.194Z"
      fill="#24275B"
    />
    <path
      d="M250 241.142L216 226.268"
      stroke="white"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path d="M220.5 256L246.5 212" stroke="white" stroke-miterlimit="10" stroke-linecap="round" />
  </svg>
);
