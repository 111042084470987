import { IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useGetGroupDetailsQuery } from '@schooly/api';
import {
  Counter,
  EditIcon,
  HistoryLogIcon,
  Loading,
  ModalSearch,
  SimpleButton,
} from '@schooly/style';
import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  ModalContentStickyTitle,
  ModalContentStickyWrapper,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { useGroup } from '../../../../context/groups/useGroup';
import { filterUser } from '../../../../helpers/users';
import { AddGroupModalSection } from '../../AddGroupModal/AddGroupModal';
import GroupEmptyStub from '../../GroupEmptyStub';
import { GroupMembersCardList } from './GroupMemberCardList';
import { GroupPreviewMembersList } from './GroupPreviewMembersList/GroupPreviewMembersList';
import { ListViewSwitch, ViewType } from './GroupPreviewMembersList/ListViewSwitch';
import { GroupStudentListExport } from './GroupStudentListExport';

export enum GroupMemberTab {
  Students,
  Staff,
}
export interface GroupPreviewModalMembersProps {
  showStaffEditButton?: boolean;
  showStudentsEditButton?: boolean;
  onGroupEdit?: (section: AddGroupModalSection) => void;
}

export const GroupPreviewModalMembers: FC<GroupPreviewModalMembersProps> = ({
  showStaffEditButton,
  showStudentsEditButton,
  onGroupEdit,
}) => {
  const { formatMessage } = useIntl();
  const [filter, setFilter] = useState('');
  const { isExpired, group: todayGroupData, groupId = '' } = useGroup();
  const [activeTab, setActiveTab] = useState<GroupMemberTab>(GroupMemberTab.Students);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const validity = todayGroupData?.validity;

  const dateFrom = validity?.date_from ?? validity?.from_school_year?.start;
  const dateTo = validity?.date_to ?? validity?.to_school_year?.end;

  const { data, isLoading } = useGetGroupDetailsQuery(
    { id: groupId, date_from: dateFrom, date_to: dateTo },
    { enabled: Boolean(groupId && dateFrom && dateTo), refetchOnMount: 'always' },
  );

  const group = data?.group || todayGroupData;

  const [viewType, setViewType] = useState<ViewType>('listView');
  const navigate = useNavigate();

  const { $t } = useIntl();

  const [activeStaff, notActiveStaff] = useMemo(() => {
    if (!group) {
      return [];
    }

    const members = group.staff;

    const activeStaff = members.filter((member) => member.is_actual);
    const notActiveStaff = members.filter((member) => !member.is_actual);

    if (!members || !filter) {
      return [activeStaff, notActiveStaff];
    }

    return [
      activeStaff.filter((member) => filterUser(member.user, filter)),
      notActiveStaff.filter((member) => filterUser(member.user, filter)),
    ];
  }, [filter, group]);

  const [activeStudents, notActiveStudents] = useMemo(() => {
    if (!group) {
      return [];
    }

    const members = group.students;

    const activeStudents = members.filter((member) => member.is_actual);
    const notActiveStudents = members.filter((member) => !member.is_actual);

    if (!members || !filter) {
      return [activeStudents, notActiveStudents];
    }

    return [
      activeStudents.filter((member) => filterUser(member.user, filter)),
      notActiveStudents.filter((member) => filterUser(member.user, filter)),
    ];
  }, [filter, group]);

  const { activeStaffCount, activeStudentsCount, visibleMembersCount } = useMemo(() => {
    const activeStudentsCount = activeStudents?.length ?? 0;
    const activeStaffCount = activeStaff?.length ?? 0;

    return {
      activeStaffCount,
      activeStudentsCount,
      visibleMembersCount:
        activeStudentsCount +
        (notActiveStudents?.length ?? 0) +
        (activeStaffCount + (notActiveStaff?.length ?? 0)),
    };
  }, [
    activeStaff?.length,
    activeStudents?.length,
    notActiveStaff?.length,
    notActiveStudents?.length,
  ]);

  const noMembersInGroup = !group?.students.length && !group?.staff.length;
  const isStudentTabActive = activeTab === GroupMemberTab.Students;

  const actionsContent = useMemo(() => {
    const editIcon =
      onGroupEdit && !isExpired ? (
        <IconButton
          sx={{
            color: (theme) => theme.palette.common.grey2,
          }}
          inverse
          onClick={() =>
            onGroupEdit(
              isStudentTabActive ? AddGroupModalSection.Students : AddGroupModalSection.Staff,
            )
          }
        >
          <EditIcon />
        </IconButton>
      ) : null;
    if (activeTab === GroupMemberTab.Students) {
      return (
        <Stack direction="row" gap={2.25} alignItems="center">
          {group && <GroupStudentListExport groupName={group.name} groupId={group.id} />}
          <ListViewSwitch viewType={viewType} onListViewChange={setViewType} />
          <SimpleButton
            inverse
            onClick={() => navigate('members/students')}
            startIcon={<HistoryLogIcon />}
          >
            <FormattedMessage id="viewHistory" />
          </SimpleButton>
          {showStudentsEditButton && editIcon}
        </Stack>
      );
    }

    return showStaffEditButton && editIcon;
  }, [
    onGroupEdit,
    isExpired,
    activeTab,
    showStaffEditButton,
    isStudentTabActive,
    group,
    viewType,
    showStudentsEditButton,
    navigate,
  ]);

  const mainContent = useMemo(() => {
    const userType = isStudentTabActive ? 'student' : 'staff';
    const activeMembers = isStudentTabActive ? activeStudents : activeStaff;
    const notActiveMembers = isStudentTabActive ? notActiveStudents : notActiveStaff;

    if (viewType === 'gridView' && isStudentTabActive) {
      return (
        <Stack pt={1} gap={2}>
          <GroupMembersCardList
            cardsInRow={3}
            userType={userType}
            groupMembers={activeMembers}
            title={$t({ id: 'groups-active' }).toUpperCase()}
          />
          <GroupMembersCardList
            cardsInRow={3}
            userType={userType}
            groupMembers={notActiveMembers}
            title={$t({ id: 'groups-not-active' }).toUpperCase()}
          />
        </Stack>
      );
    }

    const currentView = isStudentTabActive ? viewType : 'listView';

    return (
      <Stack pt={1} gap={viewType === 'compressedView' ? 1.25 : 2.5}>
        <GroupPreviewMembersList
          userType={userType}
          groupMembers={activeMembers}
          headerTitle={$t({ id: 'groups-active' }).toUpperCase()}
          tableView={currentView}
        />
        <GroupPreviewMembersList
          userType={userType}
          groupMembers={notActiveMembers}
          headerTitle={$t({ id: 'groups-not-active' }).toUpperCase()}
          tableView={currentView}
        />
      </Stack>
    );
  }, [
    $t,
    activeStaff,
    activeStudents,
    isStudentTabActive,
    notActiveStaff,
    notActiveStudents,
    viewType,
  ]);

  return (
    <ModalContentStickyWrapper>
      <ModalContentStickyTitle
        sx={{
          paddingTop: 0.5,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1.5}>
          <Stack direction="row" alignItems="center">
            <Tabs
              sx={(theme) => ({
                height: 46,
                minHeight: 50,
                marginRight: 0,
                '& .MuiTab-root': {
                  mr: 2.5,
                },
                '.MuiTabs-indicator': {
                  left: 0,
                  maxWidth: activeTab === GroupMemberTab.Students ? '80px' : '45px',
                },
                '.MuiTab-root:not(.Mui-selected)': {
                  '& :first-child': {
                    color: theme.palette.common.grey2,
                  },
                  '& .counter': {
                    backgroundColor: theme.palette.common.grey2,
                  },
                },
              })}
              value={activeTab}
              onChange={handleTabChange}
            >
              <Tab
                label={
                  <Typography variant="h2">
                    <FormattedMessage id="userType-student-plural" />
                  </Typography>
                }
                icon={
                  !isExpired ? (
                    <Counter
                      className="counter"
                      sx={{ padding: (theme) => theme.spacing(0.125, 0.75) }}
                    >
                      {activeStudentsCount}
                    </Counter>
                  ) : undefined
                }
                iconPosition={'end'}
              />

              <Tab
                label={
                  <Typography variant="h2">
                    <FormattedMessage id="userType-staff" />
                  </Typography>
                }
                icon={
                  !isExpired ? (
                    <Counter
                      className="counter"
                      sx={{ padding: (theme) => theme.spacing(0.125, 0.75) }}
                    >
                      {activeStaffCount}
                    </Counter>
                  ) : undefined
                }
                iconPosition={'end'}
              />
            </Tabs>
          </Stack>

          <Stack direction="row" gap={2.5} alignItems="center" pt={1.5}>
            <ModalSearch
              value={filter}
              onChange_MemoizedCallbackOnly={setFilter}
              placeholder={formatMessage({ id: 'people-Search' })}
              withDebounce
            />

            {actionsContent}
          </Stack>
        </Stack>
      </ModalContentStickyTitle>
      {isLoading ? (
        <Stack justifyContent="center" flexGrow={1}>
          <Loading />
        </Stack>
      ) : (
        <>
          {!visibleMembersCount && (
            <Stack justifyContent="center" sx={{ flex: '1 1 100%' }}>
              {!isLoading && noMembersInGroup && <GroupEmptyStub />}
              {!isLoading && !noMembersInGroup && !visibleMembersCount && (
                <Stack alignItems="center">
                  <Typography>
                    <FormattedMessage id="groups-NoGroupMembers" />
                  </Typography>
                </Stack>
              )}
            </Stack>
          )}
          <Stack>{mainContent}</Stack>
        </>
      )}
    </ModalContentStickyWrapper>
  );
};
