import {
  SchoolProperty,
  StudentInconsistencyType,
  StudentRegistration,
  StudentRolloverRequestParams,
} from '@schooly/api';

export function instanceOfStudentRolloverParams(data: any): data is StudentRolloverRequestParams {
  if (!data) return false;

  if ('year_from_id' in data && 'year_to_id' in data && 'student_ids' in data) {
    const { year_from_id, year_to_id, student_ids } = data;
    return Boolean(
      typeof year_from_id === 'string' &&
        typeof year_to_id === 'string' &&
        (Array.isArray(student_ids) || student_ids === 'all'),
    );
  }
  return false;
}

export function getInconsistencyTypeLabelId(type: StudentInconsistencyType) {
  switch (type) {
    case 'rollover_in_process':
      return 'students-AnnualRollover-ForYearInProgress';
    case 'have_registration':
      return 'students-AnnualRollover-HaveRegistration';
    case 'left_or_leaving':
      return 'students-AnnualRollover-LeftOrLeaving';
    case 'not_enrolled':
      return 'students-AnnualRollover-NotEnrolled';
    case 'no_current_age_group':
      return 'students-AnnualRollover-NoAgeGroup';
    case 'same_age_group':
      return 'students-AnnualRollover-StayInSameAgeGroup';
    case 'no_next_age_group':
      return 'students-AnnualRollover-NoNextAgeGroup';

    default:
      return '';
  }
}

export function isReEnrollmentPendingStatus(status: Pick<SchoolProperty, 'category' | 'source'>) {
  if (!status.source || !status.category) return false;
  return (
    status.source.type === 're_enrollment' && !status.category.current && !status.category.final
  );
}
export function isReEnrollmentPendingRegistration(enrollment: StudentRegistration) {
  return enrollment.type === 're_enrollment' && !enrollment.enrollment_status;
}
