import { Typography, useTheme } from '@mui/material';
import { differenceInMinutes, format, hoursToMinutes } from 'date-fns';
import { FC, useEffect, useRef, useState } from 'react';

import { CALENDAR_SLOT_SIZE } from '../scheme';
import {
  CALENDAR_CELL_HEIGHT,
  CalendarWeekGridCellStyled,
  CalendarWeekGridColumnTimeStyled,
} from './CalendarWeek.styled';
import { CalendarWeekCurrentTime, CalendarWeekCurrentTimeProps } from './CalendarWeekCurrentTime';

export interface CalendarWeekColumnTimeProps
  extends Pick<CalendarWeekCurrentTimeProps, 'days' | 'weeksCount'> {
  showTimeline?: boolean;
}

export const CalendarWeekColumnTime: FC<CalendarWeekColumnTimeProps> = ({
  days,
  weeksCount,
  showTimeline,
}) => {
  const refCurrentTime = useRef<HTMLElement>(null);
  const theme = useTheme();
  const [currentTime, setCurrentTime] = useState(new Date());

  const currentTimePosition = showTimeline
    ? theme.spacing(
        (CALENDAR_CELL_HEIGHT / CALENDAR_SLOT_SIZE) *
          (hoursToMinutes(currentTime.getHours()) + currentTime.getMinutes()),
      )
    : 0;

  /** Updates the currentTime */
  useEffect(() => {
    if (!showTimeline) {
      return;
    }

    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [showTimeline]);

  /** Scrolls main grid to the current time  */
  useEffect(() => {
    if (!refCurrentTime.current) {
      return;
    }

    refCurrentTime.current.closest('.CalendarWeekGrid')!.scrollTop =
      refCurrentTime.current.offsetTop - 10;
  }, []);

  return (
    <>
      <CalendarWeekGridColumnTimeStyled>
        {new Array(24).fill(true).map((_, index) => {
          const date = new Date();
          date.setHours(index, 0, 0, 0);

          const diff = differenceInMinutes(date, currentTime);
          const isCurrentTime = diff > -30 && diff < 30;

          return (
            <CalendarWeekGridCellStyled
              key={index}
              sx={{
                height: (theme) => theme.spacing((60 / CALENDAR_SLOT_SIZE) * CALENDAR_CELL_HEIGHT),
              }}
            >
              {index > 0 && !isCurrentTime ? (
                <Typography color="text.secondary">{format(date, 'HH:00')}</Typography>
              ) : null}
              {isCurrentTime && !showTimeline && (
                <Typography ref={refCurrentTime} color="text.secondary">
                  {format(currentTime, 'HH:mm')}
                </Typography>
              )}
              {isCurrentTime && showTimeline && (
                <Typography
                  ref={refCurrentTime}
                  color="primary.main"
                  position="absolute"
                  top={currentTimePosition}
                >
                  {format(currentTime, 'HH:mm')}
                </Typography>
              )}
            </CalendarWeekGridCellStyled>
          );
        })}
      </CalendarWeekGridColumnTimeStyled>

      {showTimeline && (
        <CalendarWeekCurrentTime
          days={days}
          weeksCount={weeksCount}
          position={currentTimePosition}
        />
      )}
    </>
  );
};
