import { Card, Icon, Stack, SxProps, Typography } from '@mui/material';
import { ArrowDownLongIcon, ArrowRightLongIcon } from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

const RE_ENROLLMENT_STEPS = [
  {
    step: 1,
    textId: 'school-annualRollover-Step1Label',
  },
  {
    step: 2,
    textId: 'school-annualRollover-Step2Label',
  },
  { step: 3, textId: 'school-annualRollover-Step3Label' },
];

const FINAL_STEP = RE_ENROLLMENT_STEPS.length + 1;

type ReEnrollmentInfoProps = {
  statusName: string;
};

export const ReEnrollmentInfo: FC<ReEnrollmentInfoProps> = ({ statusName }) => {
  const { $t } = useIntl();
  return (
    <Stack pt={4} direction="row" gap={1}>
      {RE_ENROLLMENT_STEPS.map((s, i) => {
        return (
          <Stack
            key={s.textId}
            alignSelf="flex-start"
            direction="row"
            alignItems="center"
            gap={1}
            flex="1 1 0px"
          >
            <ReEnrollmentInfoCard stepNumber={s.step} text={$t({ id: s.textId })} />

            {i !== RE_ENROLLMENT_STEPS.length - 1 && (
              <Icon fontSize="small" inverse>
                <ArrowRightLongIcon className="reset-svg-currentColor" />
              </Icon>
            )}
          </Stack>
        );
      })}
      <Stack alignSelf="center" gap={0.25}>
        <Icon fontSize="small">
          <ArrowRightLongIcon className="reset-svg-currentColor" />
        </Icon>

        <Icon
          sx={{
            flex: '0 0 100%',
            '.svg-icon': {
              height: '142px',
            },
          }}
        >
          <ArrowDownLongIcon className="reset-svg-currentColor" />
        </Icon>
      </Stack>
      <Stack gap={1} flex="1 1 0px">
        <ReEnrollmentInfoCard
          stepNumber={FINAL_STEP}
          text={$t({ id: 'school-annualRollover-ConfirmLabel' })}
          sxProps={{
            backgroundColor: 'success.superLight',
            '.mainText': {
              color: 'common.green1',
            },
          }}
        />
        <ReEnrollmentInfoCard
          stepNumber={FINAL_STEP}
          text={$t({ id: 'school-annualRollover-RejectLabel' }, { statusName })}
          sxProps={{
            backgroundColor: 'error.superLight',
            '.mainText': {
              color: 'error.main',
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

type ReEnrollmentInfoCardProps = {
  stepNumber: number;
  text: string;
  sxProps?: Omit<SxProps, 'children'>;
};
const ReEnrollmentInfoCard: FC<ReEnrollmentInfoCardProps> = ({ stepNumber, text, sxProps }) => {
  return (
    <Card
      sx={(theme) => ({
        minHeight: 144,
        px: theme.spacing(1.5),
        pt: theme.spacing(1.5),
        backgroundColor: theme.palette.common.lightBg,
        border: 'none',
        ...sxProps,
      })}
    >
      <Stack gap={0.5}>
        <Typography variant="h4">Step {stepNumber}</Typography>
        <Typography className="mainText" variant="h3">
          {text}
        </Typography>
      </Stack>
    </Card>
  );
};
