import { Icon } from '@mui/material';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { ArchiveIcon, Spin, TagSelectProperty, TagSelectPropertyProps } from '@schooly/style';
import React, { FC, useMemo } from 'react';

export type SubjectTagSelectProps = {
  schoolId: string;
  id: string;
} & TagSelectPropertyProps;

export const SubjectTagSelect: FC<SubjectTagSelectProps> = ({ id, schoolId, ...props }) => {
  const { subjects } = useSubjects({ schoolId });

  const subject = useMemo(() => subjects.find((p) => p.id === id), [id, subjects]);

  return (
    <TagSelectProperty
      avatar={
        subject?.archived ? (
          <Icon>
            <ArchiveIcon />
          </Icon>
        ) : undefined
      }
      label={subject?.name || <Spin />}
      {...props}
    />
  );
};
