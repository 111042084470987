import { Stack } from '@mui/material';
import { Event, SignUp, SignUpShort, SignUpStatuses, SignUpType } from '@schooly/api';
import { DATE_FORMAT_SHORT } from '@schooly/constants';
import { TypographyWithOverflowHint } from '@schooly/style';
import { getMinutesDurationLabel, newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { SignUpTypeLabels } from '../../../context/signUps/WithSignUp';
import { EventInvitee, InviteesCriteria } from '../../ConsentForms/ReferencePreview/EventPreview';

type SignUpPreviewProps = {
  signUp: SignUp;
};

export const SignUpPreview: FC<SignUpPreviewProps> = ({ signUp }) => {
  const inviteeType = signUp.invitee_type ?? signUp.event?.invitee_type;
  const criteria = signUp.event ? signUp.event.criteria : signUp.criteria;

  return (
    <Stack gap={2}>
      <SignUpShortPreview signUp={signUp}>
        {inviteeType && <EventInvitee inviteeType={inviteeType} />}
      </SignUpShortPreview>
      {criteria && <InviteesCriteria criteria={criteria} />}
    </Stack>
  );
};

type SignUpShortPreviewProps = {
  signUp: SignUpShort;
};

export const SignUpShortPreview: FC<PropsWithChildren<SignUpShortPreviewProps>> = ({
  signUp,
  children,
}) => {
  const { $t } = useIntl();

  const { closeDateLabel, durationLabel, closeLabel, placesLabel } = useSignUpBaseInfo(signUp);

  return (
    <>
      <Stack direction="row" alignItems="center" flex="0 0 auto" gap={2.5}>
        <Stack direction="row" alignItems="center" gap={3}>
          <TypographyWithOverflowHint noWrap>
            {$t({ id: closeLabel }, { date: closeDateLabel })}
          </TypographyWithOverflowHint>
          {children}
          <TypographyWithOverflowHint noWrap>
            {$t({ id: SignUpTypeLabels[signUp.type] })}
          </TypographyWithOverflowHint>
          <TypographyWithOverflowHint noWrap>
            {durationLabel || placesLabel}
          </TypographyWithOverflowHint>
        </Stack>
      </Stack>
    </>
  );
};

export function useSignUpBaseInfo(signUp: NonNullable<Event['sign_ups']>[0]) {
  const { $t } = useIntl();

  const isRegular = signUp.type === SignUpType.Regular;

  const durationLabel =
    isRegular && signUp.duration ? getMinutesDurationLabel(signUp.duration) : null;
  const placesLabel =
    isRegular && signUp.places
      ? $t({ id: 'eventSignUps-preview-Places' }, { value: signUp.places })
      : null;

  const closeLabel =
    signUp.status === SignUpStatuses.Closed
      ? 'events-header-SignUp-ClosedOnDate'
      : 'events-header-SignUp-ClosesOnDate';
  const closeDateLabel = format(newDateTimezoneOffset(signUp.end), DATE_FORMAT_SHORT);

  return { durationLabel, closeDateLabel, closeLabel, placesLabel, isRegular };
}
