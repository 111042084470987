import { Button, Stack, Typography } from '@mui/material';
import { PlusIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ModalEmpty } from '../../../../../components/uikit-components/Modal/ModalEmpty';

export const SchoolTuneStatusesEmptyStub: FC = () => {
  return (
    <Stack sx={{ height: '100%' }}>
      <Typography variant="h2" mb={2.5}>
        <FormattedMessage id="school-tabs-Statuses" />
      </Typography>

      <ModalEmpty width="300px">
        <Typography variant="h3" color={(theme) => theme.palette.common.grey2}>
          <FormattedMessage id="school-tabs-Statuses-EmptyMessage" />
        </Typography>
        <Link to="statuses">
          <Button startIcon={<PlusIcon />}>
            <FormattedMessage id="school-tabs-Statuses-AddStatus" />
          </Button>
        </Link>
      </ModalEmpty>
    </Stack>
  );
};
