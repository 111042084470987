import { ChipProps } from '@mui/material';
import { ReportStatuses } from '@schooly/api';
import { REPORT_STATUS_TEXT_ID } from '@schooly/constants';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

export type ReportStatusTagSelectProps = {
  status: ReportStatuses;
} & Omit<ChipProps, 'label'>;

export const ReportStatusTagSelect: FC<ReportStatusTagSelectProps> = ({ status, ...props }) => {
  const { $t } = useIntl();

  return (
    <TagSelect
      label={$t({
        id: REPORT_STATUS_TEXT_ID[status],
      })}
      {...props}
    />
  );
};
