import { Icon, Stack } from '@mui/material';
import { Assessment, AssessmentBase } from '@schooly/api';
import { ModalContent, RecurringIcon } from '@schooly/style';
import { FC, useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ModalEmptyMainArea } from '../../../components/uikit-components/Modal/ModalEmptyMainArea';
import { ModalPeopleExtensionPanel } from '../../../components/uikit-components/Modal/ModalPeopleExtensionPanel';
import RoundCard from '../../../components/uikit-components/RoundCard';
import { ReportForm } from '../../../context/report/WithReportEditContext';
import formatDate from '../../../utils/formatDate';
import { SelectAssessmentExternalSidebar } from './SelectAssessmentExternalSidebar';

type ReportCreateModalAdditionalAssessmentsProps = {
  active: boolean;
  errorId?: string;
  onAddClick?: () => void;
  form: UseFormReturn<ReportForm>;
};

export const ReportCreateModalAdditionalAssessments: FC<ReportCreateModalAdditionalAssessmentsProps> =
  ({ form, errorId, onAddClick, active }) => {
    const { $t } = useIntl();

    const additionalAssessments = form.watch('assessments');

    const onAssessmentSelect = useCallback(
      (v: Assessment) => {
        form.setValue(
          'assessments',
          additionalAssessments.map((a) => a.id).includes(v.id)
            ? additionalAssessments.filter((a) => a.id !== v.id)
            : [...additionalAssessments, v],
        );
      },
      [additionalAssessments, form],
    );

    const assessmentsFilter = useCallback((v: AssessmentBase) => {
      if (v.autogenerated) return false;

      return true;
    }, []);

    return (
      <>
        <ModalPeopleExtensionPanel
          titleId="reports-AdditionalAssessments"
          addActionId="reports-AddAssessments"
          editActionId="reports-EditAssessments"
          count={additionalAssessments.length}
          onAddClick={onAddClick}
          errorId={errorId}
          active={active}
          sidebarContent={
            <SelectAssessmentExternalSidebar
              assessmentListFilter={assessmentsFilter}
              selectedAssessments={additionalAssessments}
              onCardClick={onAssessmentSelect}
            />
          }
          testId="report-add-assessment-button"
        >
          <ModalContent active flat sx={{ pt: 0 }}>
            {!additionalAssessments?.length ? (
              <ModalEmptyMainArea label={$t({ id: 'reports-Assessments' }).toLowerCase()} />
            ) : (
              <Stack sx={{ overflowY: 'auto', px: 2.5 }}>
                {additionalAssessments?.map((assessment) => (
                  <RoundCard
                    key={assessment.id}
                    withTooltip
                    onClick={onAssessmentSelect}
                    name={assessment.name}
                    item={assessment}
                    generateHref={(id) => `/assessments/${id}`}
                    prefix={formatDate(
                      (assessment as AssessmentBase).assessment_date,
                    )?.toUpperCase()}
                    isSelected
                  >
                    {assessment.recurring_state ? (
                      <Icon
                        sx={(theme) => ({
                          svg: {
                            backgroundColor: 'transparent !important',
                            color: `${theme.palette.common.grey2} !important`,
                          },
                        })}
                      >
                        <RecurringIcon />
                      </Icon>
                    ) : null}
                  </RoundCard>
                ))}
              </Stack>
            )}
          </ModalContent>
        </ModalPeopleExtensionPanel>
      </>
    );
  };
