import { SelectOption } from '@schooly/api';
import { CrossSmallIcon } from '@schooly/style';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import buildClassName from '../../../utils/buildClassName';

import './index.scss';

interface IProps extends PropsWithChildren {
  selectOption?: SelectOption;
  className?: string;
  isBig?: boolean;
  isDisableStopPropagationOfClick?: boolean;
  removeTitleTextId?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, selectOption?: SelectOption) => void;
  onRemove?: (e: React.MouseEvent<HTMLElement, MouseEvent>, selectOption?: SelectOption) => void;
}

const Tag: React.FC<IProps> = ({
  selectOption,
  className,
  isBig,
  isDisableStopPropagationOfClick = false,
  removeTitleTextId = 'input-RemoveOption',
  onClick,
  onRemove,
  children,
}) => {
  const { formatMessage } = useIntl();

  const fullClassName = buildClassName(
    'Tag',
    onClick && 'Tag--clickable',
    onRemove && 'Tag--removable',
    isBig && 'Tag--big',
    className,
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (!isDisableStopPropagationOfClick) {
        e.stopPropagation();
      }
      if (onClick) {
        onClick(e, selectOption);
      }
    },
    [isDisableStopPropagationOfClick, onClick, selectOption],
  );

  const handleRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (onRemove) {
        onRemove(e, selectOption);
      }
    },
    [onRemove, selectOption],
  );

  const removeTitle = useMemo(
    () => formatMessage({ id: removeTitleTextId }),
    [formatMessage, removeTitleTextId],
  );

  return (
    <button type="button" tabIndex={-1} className={fullClassName} onClick={handleClick}>
      {children}
      {!!onRemove && (
        <span
          role="button"
          tabIndex={-1}
          onClick={handleRemove}
          className="Tag__remove"
          title={removeTitle}
          aria-label={removeTitle}
        >
          <CrossSmallIcon />
        </span>
      )}
    </button>
  );
};

export default Tag;
