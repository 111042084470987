import { Components, Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      padding: 0,
      minWidth: 0,
      '&:not(:last-child)': {
        marginRight: theme.spacing(2.5),
      },
      ...theme.typography.h3,
      color: theme.palette.common.grey,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    }),
  },
};
