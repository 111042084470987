import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.67895 14.6143L2.02222 17.271L2.72932 17.9782L17.9785 2.72896L17.2714 2.02185L14.7088 4.58443C11.964 2.08543 7.71331 2.1596 5.05791 4.80695L0.660179 9.19135C0.268222 9.58212 0.268222 10.2169 0.660178 10.6077L4.67895 14.6143ZM6.81212 12.4811L7.52563 11.7676C7.16355 11.2719 6.94985 10.6609 6.94985 10C6.94985 8.34317 8.29299 7.00003 9.94985 7.00003C10.6107 7.00003 11.2217 7.21373 11.7174 7.57581L12.431 6.8623C11.7491 6.32239 10.8871 6.00003 9.94985 6.00003C7.74071 6.00003 5.94985 7.79089 5.94985 10C5.94985 10.9373 6.27221 11.7993 6.81212 12.4811ZM14.9424 14.9921C12.5645 17.3627 8.90745 17.67 6.19799 15.9139L8.41638 13.6955C8.88863 13.8917 9.40659 14 9.94985 14C12.159 14 13.9498 12.2092 13.9498 10C13.9498 9.45677 13.8415 8.93881 13.6454 8.46656L16.1255 5.98646L19.3401 9.19136C19.7321 9.58212 19.7321 10.2169 19.3401 10.6077L14.9424 14.9921ZM9.94985 13C9.69275 13 9.44321 12.9677 9.20507 12.9069L12.8567 9.25525C12.9175 9.49339 12.9498 9.74293 12.9498 10C12.9498 11.6569 11.6067 13 9.94985 13Z"
      fill="#B3BBCB"
    />
  </svg>
);
