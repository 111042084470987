import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 4C3 3.44771 3.44772 3 4 3H8.53333C9.08562 3 9.53333 3.44772 9.53333 4V8.53333C9.53333 9.08562 9.08562 9.53333 8.53333 9.53333H4C3.44771 9.53333 3 9.08562 3 8.53333V4Z"
      fill="#24275B"
    />
    <path
      d="M10.4667 4C10.4667 3.44771 10.9144 3 11.4667 3H16C16.5523 3 17 3.44772 17 4V8.53333C17 9.08562 16.5523 9.53333 16 9.53333H11.4667C10.9144 9.53333 10.4667 9.08562 10.4667 8.53333V4Z"
      fill="#24275B"
    />
    <path
      d="M10.4667 11.4667C10.4667 10.9144 10.9144 10.4667 11.4667 10.4667H16C16.5523 10.4667 17 10.9144 17 11.4667V16C17 16.5523 16.5523 17 16 17H11.4667C10.9144 17 10.4667 16.5523 10.4667 16V11.4667Z"
      fill="#24275B"
    />
    <path
      d="M3 11.4667C3 10.9144 3.44772 10.4667 4 10.4667H8.53333C9.08562 10.4667 9.53333 10.9144 9.53333 11.4667V16C9.53333 16.5523 9.08562 17 8.53333 17H4C3.44771 17 3 16.5523 3 16V11.4667Z"
      fill="#24275B"
    />
  </svg>
);
