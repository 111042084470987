import { format, isToday } from 'date-fns';
import { FC } from 'react';

import {
  CalendarWeekGridHeaderCellDate,
  CalendarWeekGridHeaderCellDayOfWeek,
  CalendarWeekGridHeaderCellStyled,
} from './CalendarWeek.styled';

export interface CalendarWeekGridHeaderCellProps {
  date: Date;
}

export const CalendarWeekHeaderCell: FC<CalendarWeekGridHeaderCellProps> = ({ date }) => {
  const isDateToday = isToday(date);

  return (
    <CalendarWeekGridHeaderCellStyled>
      <CalendarWeekGridHeaderCellDate variant="h2" isToday={isDateToday}>
        {format(date, 'd')}
      </CalendarWeekGridHeaderCellDate>
      <CalendarWeekGridHeaderCellDayOfWeek isToday={isDateToday}>
        {format(date, 'iii').toUpperCase()}
      </CalendarWeekGridHeaderCellDayOfWeek>
    </CalendarWeekGridHeaderCellStyled>
  );
};
