import { Chip, styled } from '@mui/material';

interface SelectChipProps {
  selected: boolean;
  error?: boolean;
}

export const SelectChip = styled(Chip)<SelectChipProps>(({ theme, selected, error }) => ({
  '&&.MuiChip-root': {
    background: selected ? theme.palette.primary.main : undefined,
    color: selected ? theme.palette.background.default : theme.palette.common.grey,
    borderColor: (() => {
      if (error) {
        return theme.palette.error.main;
      }

      if (selected) {
        return theme.palette.primary.main;
      }

      return theme.palette.common.light3;
    })(),
    padding: theme.spacing(0),
    '.MuiChip-label': {
      padding: theme.spacing(0, 1),
    },
  },

  height: theme.spacing(2.75),
}));
