import { Product, ProductTriggerType, SchoolYear } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { ModalContent, ModalLarge } from '@schooly/style';
import { FC } from 'react';

import { EmptyTypes } from '../EmptyTypes';
import { SchoolProductModalHeader } from '../SchoolProductModalHeader';
import { SchoolProductModalVariants } from './SchoolProductModalVariants';

type SchoolProductModalContentProps = {
  product: Product;
  schoolId: string;
  onEdit?: () => void;
  onEditVariants?: (yearId?: SchoolYear['id']) => void;
  onClose: () => void;
};

export const SchoolProductModalContent: FC<SchoolProductModalContentProps> = ({
  product,
  schoolId,
  onClose,
  onEditVariants,
}) => {
  const { name, obligatory, triggers, type } = product;
  const { propertiesMap } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Student });

  const registrationTrigger = triggers.find(
    (t) => t.trigger_type === ProductTriggerType.RegistrationUpdate,
  );
  const statusName = registrationTrigger?.extra_data?.status
    ? propertiesMap.status.find((s) => s.id === registrationTrigger?.extra_data?.status)?.name
    : undefined;

  return (
    <ModalLarge open onClose={onClose}>
      <SchoolProductModalHeader
        name={name}
        obligatory={obligatory}
        statusName={statusName}
        onClose={onClose}
        triggerTextId={
          registrationTrigger ? 'products-Trigger-Status' : 'products-Trigger-SingleInvoice'
        }
        type={type}
      />
      <ModalContent display="flex" flexDirection="column">
        {product.types.length ? (
          <SchoolProductModalVariants
            product={product}
            schoolId={schoolId}
            onEdit={onEditVariants}
          />
        ) : (
          <EmptyTypes onAdd={onEditVariants} productName={product.name} />
        )}
      </ModalContent>
    </ModalLarge>
  );
};
