import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99982 1.52637L16.9004 5.39067C18.2485 6.14561 17.7126 8.19943 16.1675 8.19943H11.3271C11.4374 7.99056 11.4999 7.75252 11.4999 7.49988C11.4999 6.67145 10.8283 5.99988 9.99988 5.99988C9.17145 5.99988 8.49988 6.67145 8.49988 7.49988C8.49988 7.75252 8.56234 7.99056 8.67265 8.19943H3.83218C2.28709 8.19943 1.75117 6.14561 3.09928 5.39067L9.99982 1.52637ZM8.67267 8.19946H4V11.9995H1.5C1.22386 11.9995 1 12.2234 1 12.4995V17.4999C1 17.776 1.22386 17.9999 1.5 17.9999H7.5V14.4994C7.5 13.671 8.17157 12.9994 9 12.9994H11C11.8284 12.9994 12.5 13.671 12.5 14.4994V17.9999H18.5C18.7761 17.9999 19 17.776 19 17.4999V12.4995C19 12.2234 18.7761 11.9995 18.5 11.9995H16V8.19946H11.3271C11.0757 8.67547 10.5757 8.99988 9.99988 8.99988C9.4241 8.99988 8.9241 8.67547 8.67267 8.19946ZM8.5 17.9999H11.5V14.4994C11.5 14.2232 11.2761 13.9994 11 13.9994H9C8.72386 13.9994 8.5 14.2232 8.5 14.4994V17.9999Z"
      fill="#24275B"
    />
  </svg>
);
