import { Stack } from '@mui/material';
import { RelationGroup } from '@schooly/api';
import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import SearchInput from '../../../components/ui/SearchInput';
import { ModalContent } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalEmptyMainArea } from '../../../components/uikit-components/Modal/ModalEmptyMainArea';
import { filterGroups } from '../../../helpers/misc';
import { IndividualStudentGroup } from './IndividualStudentGroup';

interface ManageIndividualStudentGroupsProps {
  individualGroups: RelationGroup[];
  criteriaGroups: RelationGroup[];
  onRemoveGroup: (group: RelationGroup) => void;
  fetching: boolean;
}

export const ManageIndividualStudentGroups: FC<ManageIndividualStudentGroupsProps> = ({
  individualGroups,
  onRemoveGroup,
  fetching,
  criteriaGroups,
}) => {
  const [searchString, setSearchString] = useState('');

  const { formatMessage } = useIntl();

  const filteredIndividualGroups = useMemo(
    () =>
      searchString
        ? individualGroups.filter((group) => filterGroups(group, searchString))
        : individualGroups,
    [individualGroups, searchString],
  );

  if (!individualGroups?.length && !fetching) {
    return (
      <ModalEmptyMainArea label={formatMessage({ id: 'groups-Group-plural' }).toLowerCase()} />
    );
  }
  return (
    <ModalContent withBorderBottom active sx={{ pt: 0 }}>
      <SearchInput
        value={searchString}
        onChange={setSearchString}
        isValueRemovable
        placeholder={formatMessage(
          { id: 'groups-SearchAmongCount' },
          {
            GroupPlural: `${individualGroups.length} ${formatMessage({
              id: 'assessments-Groups',
            }).toLowerCase()}`,
          },
        )}
      />

      <Stack gap={1}>
        {filteredIndividualGroups?.map((group) => (
          <IndividualStudentGroup
            key={group.id}
            group={group}
            onRemoveGroup={() => onRemoveGroup(group)}
            criteriaGroups={criteriaGroups}
          />
        ))}
      </Stack>
    </ModalContent>
  );
};
