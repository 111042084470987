import { ApiError, ConductType, ConductTypeHandle, handleConductTypes } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useCallback, useEffect, useState } from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form-lts';

import { useConductCommon } from '../../../context/conduct/useConductCommon';
import { useRouter } from '../../../context/router/useRouter';
import { separateTypesByConnotation } from './separateTypesByConnotation';

export interface SchoolConductChangeOrderForm {
  positiveConductTypes: ConductType[];
  negativeConductTypes: ConductType[];
}

export enum ConductTypesDroppable {
  POSITIVE = 'ConductTypesDroppablePositive',
  NEGATIVE = 'ConductTypesDroppableNegative',
}

export const useSchoolConductChangeOrderModal = () => {
  const { schoolId } = useAuth();
  const { goBack } = useRouter();
  const [isSaving, setSaving] = useState(false);

  const { showError } = useNotifications();
  const { types = [], typesFetching, fetchTypes } = useConductCommon();
  const closeModal = goBack;

  const form = useForm<SchoolConductChangeOrderForm>({
    defaultValues: separateTypesByConnotation(types),
  });

  const { fields: positiveFields, move: movePositive } = useFieldArray({
    control: form.control,
    name: 'positiveConductTypes',
  });

  const { fields: negativeFields, move: moveNegative } = useFieldArray({
    control: form.control,
    name: 'negativeConductTypes',
  });

  useEffect(() => {
    if (typesFetching) return;

    form.reset({
      ...separateTypesByConnotation(types),
    });
  }, [typesFetching, types, form]);

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.destination) {
        return;
      }

      switch (result.type) {
        case ConductTypesDroppable.POSITIVE:
          movePositive(result.source.index, result.destination.index);
          break;
        case ConductTypesDroppable.NEGATIVE:
          moveNegative(result.source.index, result.destination.index);
          break;
        default:
          break;
      }
    },
    [movePositive, moveNegative],
  );

  const onSaveOrder = form.handleSubmit(
    useCallback<SubmitHandler<SchoolConductChangeOrderForm>>(
      async ({ positiveConductTypes, negativeConductTypes }) => {
        if (!schoolId) return;

        setSaving(true);

        const conductTypes: ConductTypeHandle[] = [
          ...positiveConductTypes,
          ...negativeConductTypes,
        ].map(({ id, ...rest }, i) => ({
          conduct_type_id: id,
          ...rest,
          description: undefined,
          order: i,
        }));

        try {
          await handleConductTypes({ schoolId, conductTypes });
          fetchTypes();
          closeModal();
        } catch (err) {
          console.error(err);
          showError(err as ApiError);
        }

        setSaving(false);
      },
      [closeModal, fetchTypes, schoolId, showError],
    ),
  );

  return {
    positiveFields,
    negativeFields,
    isLoading: typesFetching,
    isSaving,

    handleDragEnd,
    onSaveOrder,
    closeModal,
  };
};
