import {
  GET_SCHOOL_PROPERTIES_QUERY,
  UpdateDepartmentsRequest,
  useUpdateDepartmentsMutation,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form-lts';

import { useRouter } from '../../../../context/router/useRouter';
import { useSchool } from '../../../../hooks/useSchool';
import { SchoolSettingsLayout } from '../../SchoolSettingsLayout';
import {
  SchoolGeneralDepartmentsForm,
  SchoolGeneralDepartmentsModalContent,
} from './SchoolGeneralDepartmentsContent';

export const SchoolGeneralDepartmentsModal: FC = () => {
  const { schoolId = '' } = useSchool();

  const { propertiesMap, isLoading } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Staff,
  });

  const queryClient = useQueryClient();
  const { showError } = useNotifications();
  const { goBack } = useRouter();
  const updateDepartments = useUpdateDepartmentsMutation();

  const handleSubmit = useCallback<SubmitHandler<SchoolGeneralDepartmentsForm>>(
    async (data) => {
      if (!schoolId || !data.departments) {
        return;
      }

      updateDepartments.mutate(
        {
          schoolId,
          departments: data.departments.map((department, index) => {
            const result: UpdateDepartmentsRequest['departments'][number] = {
              property_id: department.id || undefined,
              replace_to_id: department.replaceId || undefined,
              name: department.name ?? '',
              order: index + 1,
              archive: department.archived,
            };

            return result;
          }),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([GET_SCHOOL_PROPERTIES_QUERY]);
            goBack();
          },
          onError: showError,
        },
      );
    },
    [goBack, queryClient, schoolId, showError, updateDepartments],
  );

  return (
    <SchoolSettingsLayout>
      <SchoolGeneralDepartmentsModalContent
        onSubmit={handleSubmit}
        onClose={goBack}
        isSaving={updateDepartments.isLoading}
        isLoading={isLoading}
        departments={propertiesMap.department}
      />
    </SchoolSettingsLayout>
  );
};
