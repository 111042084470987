import { WithName } from '@schooly/api';
import { Titles } from '@schooly/constants';
import { getUserFullName } from '@schooly/utils/get-user-full-name';

export function getUserFullNameWithTitle(user: WithName) {
  if ((!user.title && user.title !== 0) || !Titles[user.title]) {
    return getUserFullName(user, true);
  }

  return `${Titles[user.title]}. ${getUserFullName(user, true)}`;
}
