import { Box, Stack, Typography } from '@mui/material';
import {
  DATE_FORMAT_FULL_MONTH_FNS,
  MessageForGroup,
  useGetMessagesForRelationQuery,
} from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading } from '@schooly/style';
import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { retrieveApiDate } from '../../../../src/helpers/misc';
import EmptyStub from '../../../components/common/ProfileModal/EmptyStub';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { ListViewMessages } from '../../../components/uikit-components/ListViewMessages/ListViewMessages';
import { ListViewMessagesRowVariant } from '../../../components/uikit-components/ListViewMessagesRow/ListViewMessagesRow';
import { MONTH_NONE } from '../../../constants/misc';
import { ProfileModalMode } from '../../../context/profile/helpers';
import { useProfile } from '../../../context/profile/useProfile';
import buildClassName from '../../../utils/buildClassName';

export const ProfileModalMessages: FC = () => {
  const { schoolMembership } = useProfile();
  const { data, fetchNextPage, isLoading, hasNextPage, isFetchingNextPage } =
    useGetMessagesForRelationQuery(
      {
        relationId: schoolMembership?.relation_id || '',
      },
      { enabled: !!schoolMembership?.relation_id },
    );

  const entries = useMemo(
    () =>
      data?.pages.reduce<MessageForGroup[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  const messagesByMonth = useMemo(() => {
    return entries.reduce<Record<string, MessageForGroup[]>>((prev, message) => {
      const date = message.publish_date && retrieveApiDate(message.publish_date);
      const month = date ? format(date, DATE_FORMAT_FULL_MONTH_FNS).toUpperCase() : MONTH_NONE;

      if (!prev[month]) {
        prev[month] = [];
      }

      prev[month].push(message);

      return prev;
    }, {});
  }, [entries]);

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);
  const count = data?.pages[0].count;
  const isEmpty = !isLoading && !count;

  if (isEmpty) {
    return <EmptyStub mode={ProfileModalMode.Messages} />;
  }

  return (
    <Box flex="1" className={buildClassName('section', isEmpty && 'ProfileEmptyStub')}>
      <Stack direction="row" alignItems="center" mb={2.5}>
        <Typography variant="h2">
          <FormattedMessage id="profile-Messages" />
        </Typography>
        {!!count && <Counter>{count}</Counter>}
      </Stack>

      {!data ? (
        <Loading />
      ) : (
        <Stack gap={2.5}>
          {Object.keys(messagesByMonth).map((month) => (
            <Box key={month}>
              {month !== MONTH_NONE && <Typography variant="h4">{month}</Typography>}

              <ListViewMessages
                messages={messagesByMonth[month]}
                variant={ListViewMessagesRowVariant.Profile}
              />
            </Box>
          ))}

          {hasNextPage && (
            <Box py={3}>
              <Loading />
              <div ref={loaderRef} />
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};
