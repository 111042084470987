import { Paper, PopoverProps, SxProps } from '@mui/material';
import { IContextAction } from '@schooly/api';
import { FC, MouseEvent, useState } from 'react';

import { Menu, MenuItemArrow } from './ContextMenu.styled';
import { ContextMenuItem } from './ContextMenuItem';

export interface ContextMenuProps extends Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'> {
  actions: IContextAction[];
  arrowOffset?: number[];
  children: (onClick: (event: MouseEvent<HTMLElement>) => void) => React.ReactNode;
  styles?: SxProps;
}

export const ContextMenu: FC<ContextMenuProps> = ({
  actions,
  arrowOffset,
  children,
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  transformOrigin = { vertical: 'top', horizontal: 'center' },
  styles,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onMenuClick = (handler: () => void) => {
    handler();
    handleClose();
  };

  return (
    <>
      {children(handleClick)}

      <MenuItemArrow
        anchorEl={anchorEl}
        open={open}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: arrowOffset,
            },
          },
        ]}
      >
        <Paper />
      </MenuItemArrow>

      <Menu
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={styles}
      >
        {actions.map(({ titleTextId, handler }) => (
          <ContextMenuItem
            testId={`${titleTextId}-button`}
            onMenuClick={() => onMenuClick(handler)}
            key={titleTextId}
            titleTextId={titleTextId}
          />
        ))}
      </Menu>
    </>
  );
};
