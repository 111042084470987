import {
  ChartBarData,
  GetGroupsQueryFilters,
  GroupArrangeBy,
  GROUPS_QUERY_FILTER_KEYS,
  useGetGroupsAggregatedData,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { FC, useCallback, useEffect, useMemo } from 'react';

import { BarSelectProps, ChartBar, ChartBarProps } from './chartBar/ChartBar';
import { ChartsContainer } from './chartBar/ChartsContainer';
import { useRenderTooltip } from './chartBar/ChartTooltip';
import { useGetLabel } from './chartBar/useGetLabel';
import { useCharts } from './context/ChartsContext';
import { ChartsCustomGrid } from './StudentsStaffCharts';

const BAR_ITEM_LIMIT = 5;
interface ChartsProps extends Omit<ChartBarProps, 'chartData' | 'getDataName'> {
  onOpenArrangeByDropdown?: () => void;
  onOpenCustomGrid: (v: ChartsCustomGrid<GetGroupsQueryFilters> | null) => void;
  arrangeBy: GroupArrangeBy | null;
  filters: GetGroupsQueryFilters;
  query?: string;
}

export const GroupCharts: FC<ChartsProps> = ({
  filters,
  onOpenArrangeByDropdown,
  onOpenCustomGrid,
  arrangeBy,
  query,
}) => {
  const {
    isChartsOpened,
    schoolId,
    chartSelectedElement,
    setChartSelectedElement,
    onToggleChartsOpened,
  } = useCharts();
  const { showError } = useNotifications();
  const renderTooltip = useRenderTooltip();
  const { schoolProperties, isLoading: isLoadingSchoolProperties } = useSchoolProperties({
    userType: SchoolUserRole.Student,
    schoolId,
    showReEnrollmentProperties: true,
  });

  const { data: groupAggregatedData, isFetching } = useGetGroupsAggregatedData(
    {
      schoolId,
      filters,
      arrangeBy,
      query,
    },
    { onError: showError, refetchOnMount: 'always', enabled: !!isChartsOpened && !!arrangeBy },
  );

  const getDataName = useGetLabel({
    arrangeBy,
    schoolProperties,
  });

  const chartData = useMemo(() => {
    if (!groupAggregatedData?.rows.length) return undefined;

    const normalizedData: ChartBarData = {
      arrange_by: {
        dataKey: groupAggregatedData.key,
        data: [],
      },
      series: [
        {
          dataValue: null,
          dataKey: null,
          name: null,
          data: [],
        },
      ],
    };

    groupAggregatedData.rows.forEach(({ total, value, name }) => {
      if (!total) {
        return;
      }

      normalizedData.arrange_by.data.push({
        dataValue: value,
        name,
      });

      normalizedData.series[0]?.data.push(total);
    });

    return normalizedData;
  }, [groupAggregatedData]);

  useEffect(() => {
    setChartSelectedElement(undefined);
    onOpenCustomGrid(null);
  }, [arrangeBy, filters, setChartSelectedElement, onOpenCustomGrid]);

  const onBarSelect = useCallback(
    ({ data, bar }: BarSelectProps) => {
      const getFiltersFromBarData = (data: BarSelectProps['data']): GetGroupsQueryFilters => {
        const filters: GetGroupsQueryFilters = {};
        if (!data) return filters;

        for (const filterKey of GROUPS_QUERY_FILTER_KEYS) {
          const filterKeyData = data.find((d) => d.dataKey === filterKey);

          if (!filterKeyData) continue;

          filters[filterKey] = [
            filterKeyData.dataValue === null ? 'none' : String(filterKeyData.dataValue),
          ];
        }

        return filters;
      };

      setChartSelectedElement((old) => {
        const isElementSelected =
          old?.dataIndex === bar.dataIndex && old?.seriesIndex === bar.seriesIndex;
        const newSelectedBar = isElementSelected ? undefined : bar;

        if (newSelectedBar && data) {
          const barFilters = getFiltersFromBarData(data);
          const { name: title, value } = newSelectedBar;

          onOpenCustomGrid({
            title,
            count: typeof value === 'number' ? value : 0,
            filters: { ...filters, ...barFilters },
          });
        } else {
          onOpenCustomGrid(null);
        }

        return newSelectedBar;
      });
    },
    [filters, onOpenCustomGrid, setChartSelectedElement],
  );

  if (!isChartsOpened) return null;

  return (
    <ChartsContainer
      isEmpty={!chartData?.series.some((s) => !!s.data.reduce((acc, v) => acc + v, 0))}
      isLoading={isFetching || isLoadingSchoolProperties}
      onToggleChartsOpened={onToggleChartsOpened}
      arrangeBy={arrangeBy}
      onOpenArrangeByDropdown={onOpenArrangeByDropdown}
    >
      {chartData && (
        <ChartBar
          barPosition={
            chartData.arrange_by.data.length < BAR_ITEM_LIMIT ? 'vertical' : 'horizontal'
          }
          renderTooltip={renderTooltip}
          chartData={chartData}
          onBarSelect={onBarSelect}
          getDataName={getDataName}
          selectedBar={chartSelectedElement}
        />
      )}
    </ChartsContainer>
  );
};
