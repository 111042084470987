import { Loading, ModalSmall } from '@schooly/style';
import { FC } from 'react';

import { useSignUp } from '../../../context/signUps/WithSignUp';
import { SignUpCreateContent } from './SignUpCreateContent';

export const SignUpCreateModal: FC = () => {
  const { fetching } = useSignUp();

  return <ModalSmall open>{fetching ? <Loading /> : <SignUpCreateContent />}</ModalSmall>;
};
