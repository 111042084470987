import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  ApiError,
  StudentSchoolRelation,
  SyncUser,
  useMarkStudentsNotDuplicates,
  useMergeStudentsMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { USER_HAS_ACTIVE_CRITERIA_MEMBERSHIP } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  BigArrowSvg,
  CrossIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  Spin,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { FormRadioGroup } from '../../../components/uikit-components/FormCheckbox/FormRadioGroup';
import { useProfile } from '../../../context/profile/useProfile';
import { useRouter } from '../../../context/router/useRouter';
import { getRouteModalPathname } from '../../../helpers/misc';
import { CompareDuplicatesConfirmationModal } from './CompareDuplicatesConfirmationModal';
import { CompareDuplicatesUserCard } from './CompareDuplicatesUserCard';
import { MergeDuplicatesErrorModal } from './MergeDuplicatesErrorModal';
import { MergeMembershipErrorModal } from './MergeMembershipErrorModal';
import { getStudentRelationAfterMergePreview } from './utils';

export enum DuplicatesStatus {
  NotDuplicates,
  Duplicates,
}

interface CompareDuplicatesContentProps {
  relationToBeDeleted: SyncUser | StudentSchoolRelation;
  relationToBePreserved: SyncUser | StudentSchoolRelation;
  isMarkingNotDuplicates: boolean;
  setIsMarkingNotDuplicates: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CompareForm {
  confirmation: DuplicatesStatus;
}

export const CompareDuplicatesContent: FC<CompareDuplicatesContentProps> = ({
  relationToBeDeleted,
  relationToBePreserved,
  isMarkingNotDuplicates,
  setIsMarkingNotDuplicates,
}) => {
  const { schoolId } = useAuth();
  const { $t } = useIntl();
  const { showError, showNotification } = useNotifications();
  const navigate = useNavigate();
  const { userType } = useProfile();
  const { clean, goBack } = useRouter();

  const mergeStudents = useMergeStudentsMutation();
  const markStudentsNotDuplicates = useMarkStudentsNotDuplicates();
  const invalidateStudentQueries = useInvalidateListQueriesFor('student');

  const { isLoading: isMerging } = mergeStudents;
  const isLoading = isMerging || isMarkingNotDuplicates;

  const [isConfirmationDialogOpen, showConfirmationDialog, hideConfirmationDialog] = useFlag();
  const [isMergeFailModalOpen, showMergeFailModal] = useFlag();
  const [isMembershipErrorModalOpen, showMembershipErrorModal] = useFlag();

  const form = useForm<CompareForm>();
  const confirmation = form.watch('confirmation');
  const duplicatesConfirmed = confirmation === DuplicatesStatus.Duplicates;

  const confirmationOptions = [
    {
      value: DuplicatesStatus.Duplicates,
      labelTextId: 'profile-Duplicates-ConfirmationDuplicates',
    },
    {
      value: DuplicatesStatus.NotDuplicates,
      labelTextId: 'profile-Duplicates-ConfirmationNotDuplicates',
    },
  ];

  const onMarkNotDuplicatesClick = useCallback(
    async (data: CompareForm) => {
      if (data.confirmation !== DuplicatesStatus.NotDuplicates) {
        showError({
          reason: $t({
            id: 'profile-Duplicates-ConfirmationError',
          }),
        });
        return;
      }
      showConfirmationDialog();
    },
    [$t, showConfirmationDialog, showError],
  );

  const onMergeClick = useCallback(
    (data: CompareForm) => {
      if (data.confirmation !== DuplicatesStatus.Duplicates) {
        showError({
          reason: $t({
            id: 'profile-Duplicates-ConfirmationError',
          }),
        });
        return;
      }
      showConfirmationDialog();
    },
    [$t, showConfirmationDialog, showError],
  );

  const handleMerge = useCallback(async () => {
    if (
      !schoolId ||
      !relationToBePreserved.relation_id ||
      !relationToBeDeleted.relation_id ||
      !userType
    )
      return;

    try {
      await mergeStudents.mutateAsync({
        schoolId,
        params: {
          relation_id_to_be_preserved: relationToBePreserved.relation_id,
          relation_id_to_be_deleted: relationToBeDeleted.relation_id,
        },
      });
      invalidateStudentQueries();
      clean();
      navigate(getRouteModalPathname(userType, relationToBePreserved));
      showNotification({
        textId: 'profile-Duplicates-MergeSuccess',
        type: 'success',
      });
    } catch (error) {
      hideConfirmationDialog();
      if ((error as ApiError).reason === USER_HAS_ACTIVE_CRITERIA_MEMBERSHIP) {
        showMembershipErrorModal();
      } else {
        showMergeFailModal();
      }
    }
  }, [
    clean,
    hideConfirmationDialog,
    invalidateStudentQueries,
    mergeStudents,
    navigate,
    relationToBeDeleted.relation_id,
    relationToBePreserved,
    schoolId,
    showMembershipErrorModal,
    showMergeFailModal,
    showNotification,
    userType,
  ]);

  const handleMarkNotDuplicates = useCallback(async () => {
    if (
      !schoolId ||
      !relationToBePreserved.relation_id ||
      !relationToBeDeleted.relation_id ||
      !userType
    )
      return;

    setIsMarkingNotDuplicates(true);

    try {
      await markStudentsNotDuplicates.mutateAsync({
        schoolId,
        params: {
          relation_a_id: relationToBeDeleted.relation_id,
          relation_b_id: relationToBePreserved.relation_id,
        },
      });

      goBack();
      showNotification({
        textId: 'profile-Duplicates-MarkAsNonDuplicatesSuccess',
        type: 'success',
      });
    } catch (error) {
      hideConfirmationDialog();
      showError(error as ApiError);
    }
    setIsMarkingNotDuplicates(false);
  }, [
    goBack,
    hideConfirmationDialog,
    markStudentsNotDuplicates,
    relationToBeDeleted.relation_id,
    relationToBePreserved.relation_id,
    schoolId,
    setIsMarkingNotDuplicates,
    showError,
    showNotification,
    userType,
  ]);

  return (
    <>
      <FormProvider {...form}>
        <form>
          <ModalHeader active title={$t({ id: 'profile-Duplicates-MergeDuplicates' })}>
            <IconButton onClick={goBack} disabled={isLoading}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              <Stack direction="row" mb={5}>
                <Stack
                  flex={1}
                  gap={2.5}
                  sx={{
                    overflow: 'hidden',
                  }}
                >
                  <Typography variant="h2">
                    {$t({ id: 'profile-Duplicates-ProfileToMerge' })}
                  </Typography>
                  <CompareDuplicatesUserCard user={relationToBeDeleted} />
                </Stack>
                <Stack
                  m={3.75}
                  sx={{ '& svg': { width: 100, height: 100, transform: 'rotate(-90deg)' } }}
                  justifyContent="center"
                >
                  <BigArrowSvg />
                </Stack>
                <Stack
                  flex={1}
                  gap={2.5}
                  sx={{
                    overflow: 'hidden',
                  }}
                >
                  <Typography variant="h2">
                    {$t({ id: 'profile-Duplicates-ProfileToRemain' })}
                  </Typography>
                  <CompareDuplicatesUserCard user={relationToBePreserved} isRelationToBePreserved />
                </Stack>
              </Stack>
              <FormRadioGroup
                disabled={isLoading}
                containerProps={{ gap: 2 }}
                options={confirmationOptions}
                name="confirmation"
                rules={{
                  validate: (value) =>
                    !Number.isInteger(value) ? $t({ id: 'input-ErrorConfirmAction' }) : true,
                }}
                withBorder={false}
                listView
              />
            </ModalContent>
          </ModalMain>
          <ModalFooter active>
            <Button
              disabled={isLoading}
              onClick={form.handleSubmit(onMarkNotDuplicatesClick)}
              startIcon={isMarkingNotDuplicates && <Spin />}
            >
              {$t({ id: 'action-MarkNotDuplicates' })}
            </Button>
            <Button
              onClick={form.handleSubmit(onMergeClick)}
              disabled={isLoading}
              startIcon={isMerging && <Spin />}
            >
              {$t({ id: 'action-Merge' })}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>

      <CompareDuplicatesConfirmationModal
        onClose={hideConfirmationDialog}
        confirmation={confirmation}
        open={isConfirmationDialogOpen}
        onConfirm={duplicatesConfirmed ? handleMerge : handleMarkNotDuplicates}
        onCancel={goBack}
        user={
          duplicatesConfirmed
            ? getStudentRelationAfterMergePreview(relationToBeDeleted, relationToBePreserved)
            : undefined
        }
        isLoading={isLoading}
      />
      <MergeDuplicatesErrorModal open={isMergeFailModalOpen} onClose={goBack} />
      <MergeMembershipErrorModal
        open={isMembershipErrorModalOpen}
        onClose={goBack}
        studentName={getUserFullName(relationToBeDeleted)}
        studentId={relationToBeDeleted.relation_id}
      />
    </>
  );
};
