import { MenuItem, MenuList } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalSidebarItem } from '../../../components/ui/ModalSidebar';
import { ModalSideBar } from '../../../components/uikit-components/Modal/Modal.styled';
import { useGroup } from '../../../context/groups/useGroup';
import { ViewGroupModalTabs } from '../utils';

export interface ViewGroupModalSideBarProps {
  handleChangeActiveTab: (tab: ModalSidebarItem<ViewGroupModalTabs>) => void;
}

export const GroupPreviewModalSideBar: React.FC<ViewGroupModalSideBarProps> = ({
  handleChangeActiveTab,
}) => {
  const { tabs, activeTab } = useGroup();

  return (
    <ModalSideBar
      sx={{
        paddingTop: (theme) => theme.spacing(1),
      }}
    >
      <MenuList>
        {tabs.map((tab) => (
          <MenuItem
            key={tab.id}
            onClick={() => handleChangeActiveTab(tab)}
            selected={tab.id === activeTab}
          >
            <FormattedMessage id={tab.title} />
          </MenuItem>
        ))}
      </MenuList>
    </ModalSideBar>
  );
};
