import { Box, Icon, Stack, styled, Typography } from '@mui/material';
import { InvoiceIcon } from '@schooly/style';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { ProductSubscriptionType } from './ProductSubscriptionOption';

interface ProductSubscriptionExampleHeaderProps {
  textId: string;
  color: string;
}

export const ProductSubscriptionExampleHeader: FC<ProductSubscriptionExampleHeaderProps> = ({
  textId,
  color,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stack direction="row" gap={0.75} color={color}>
      <Icon>
        <InvoiceIcon />
      </Icon>
      <Typography variant="h3" color="inherit">
        {formatMessage({ id: textId })}
      </Typography>
    </Stack>
  );
};

interface ProductSubscriptionExampleOptionProps {
  textId: string;
  icon: ReactNode;
  color: string;
}

export const ProductSubscriptionExampleOption: FC<ProductSubscriptionExampleOptionProps> = ({
  textId,
  icon,
  color,
}) => {
  const { $t } = useIntl();

  return (
    <Stack direction="row" gap={0.75} color={color}>
      {icon}
      <Typography sx={{ '&&&.MuiTypography-root': { color: color } }}>
        {$t({ id: textId })}
      </Typography>
    </Stack>
  );
};

export const getProductSubscriptionExampleOptions = (type: ProductSubscriptionType) => {
  switch (type) {
    case ProductSubscriptionType.UniqueTypes:
      return [
        {
          textId: 'products-SubscriptionExample-Primary',
          checked: true,
        },
      ];

    case ProductSubscriptionType.OneTypeAssignment:
      return [
        {
          textId: 'products-SubscriptionExample-Standard',
          checked: false,
        },
        {
          textId: 'products-SubscriptionExample-Vegan',
          checked: true,
        },
        {
          textId: 'products-SubscriptionExample-GlutenFree',
          checked: false,
        },
      ];

    case ProductSubscriptionType.ManyTypesAssignment:
      return [
        {
          textId: 'products-SubscriptionExample-Math',
          checked: false,
        },
        {
          textId: 'products-SubscriptionExample-Literature',
          checked: true,
        },
        {
          textId: 'products-SubscriptionExample-Geography',
          checked: true,
        },
      ];

    default:
      return [];
  }
};

export const ProductSubscriptionExampleContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5),
  ...theme.mixins.dropdownShadow(),
}));
