import { AssessmentBase } from '@schooly/api';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import AssessmentStatuses from '../../../constants/assessmentStatuses';
import { TagStatusPublish } from '../TagStatusPublish/TagStatusPublish';

export interface AssessmentStatusTagProps {
  assessment: AssessmentBase;
  flat?: boolean;
  statusOnly?: boolean;
}

export const AssessmentStatusTag: FC<AssessmentStatusTagProps> = ({
  assessment,
  flat,
  statusOnly,
}) => {
  const { formatMessage } = useIntl();

  const isAssessmentPublished = assessment.assessment_status === AssessmentStatuses.Published;

  const label = useMemo(
    () =>
      isAssessmentPublished
        ? formatMessage({ id: 'status-Published' })
        : formatMessage({ id: 'status-NotPublished' }),
    [formatMessage, isAssessmentPublished],
  );

  const publishDate = moment(assessment.publish_date);

  return (
    <TagStatusPublish
      label={label!}
      published={assessment.assessment_status === AssessmentStatuses.Published}
      publishDate={!statusOnly ? publishDate : undefined}
      flat={flat}
    />
  );
};
