import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

interface DefaultContext {
  lastRefreshTime: number;
  updateLastRefreshTime: () => void;
}

const defaultContext: DefaultContext = {
  lastRefreshTime: Date.now(),
  updateLastRefreshTime: () => {},
};

const LastRefreshTimeContext = createContext(defaultContext);

export const useLastRefreshTimeContext = () => useContext(LastRefreshTimeContext);

export const LastRefreshTimeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [lastRefreshTime, setLastRefreshTime] = useState<number>(Date.now());

  const updateLastRefreshTime = useCallback(() => {
    setLastRefreshTime(Date.now());
  }, []);

  return (
    <LastRefreshTimeContext.Provider value={{ lastRefreshTime, updateLastRefreshTime }}>
      {children}
    </LastRefreshTimeContext.Provider>
  );
};
