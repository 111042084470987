import { Divider, Stack, SxProps, Theme } from '@mui/material';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import {
  addMonths,
  isBefore,
  isSameDay,
  isWithinInterval,
  startOfToday,
  subMonths,
} from 'date-fns';
import { forwardRef, PropsWithChildren, useImperativeHandle, useState } from 'react';

import { useDatePickerVisibleMonth } from '../useDatePickerVisibleMonth';
import { DateFnsLocalizationProvider } from './DateFnsLocalizationProvider';
import {
  ArrowButton,
  DateCalendarHeader,
  DatePickerYearMonthSelect,
} from './DatePickerCommonComponents';
import { DateSelectCalendar, DateSelectCalendarProps } from './DateSelectCalendar';

export type DateRangeSelectContentProps = PropsWithChildren<{
  fromDate: Date | null;
  toDate: Date | null;
  onSetDate: (date: Date) => void;
  presetDate?: Date;
  disablePast?: boolean;
  shouldDisableDay?: (v: Date) => boolean;
  LeftCalendarProps?: Partial<DateSelectCalendarProps>;
  RightCalendarProps?: Partial<DateSelectCalendarProps>;
}>;

export type DateRangeSelectContentRef = {
  setVisibleMonthDate: (v: Date) => void;
};

export const DateRangeSelectContent = forwardRef<
  DateRangeSelectContentRef,
  DateRangeSelectContentProps
>(
  (
    {
      fromDate,
      toDate,
      disablePast,
      onSetDate,
      shouldDisableDay,
      presetDate,
      LeftCalendarProps,
      RightCalendarProps,
      children,
    },
    ref,
  ) => {
    const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
    const [yearMonthSelectOpened, openYearAndMonthSelectFor] =
      useState<null | 'currentMonth' | 'nextMonth'>(null);

    const { visibleMonthDate, showNextMonth, showPrevMonth, setVisibleMonthDate } =
      useDatePickerVisibleMonth(fromDate || presetDate || newDateTimezoneOffset());

    useImperativeHandle(
      ref,
      () => ({
        setVisibleMonthDate,
      }),
      [setVisibleMonthDate],
    );

    const getDayStyleProps: (d: Date) => SxProps<Theme> = (dayDate) => (theme) => {
      const isPastDisabled = disablePast && isBefore(dayDate, startOfToday());
      const isDisabled = isPastDisabled || shouldDisableDay?.(dayDate);

      const isFromDate = fromDate && isSameDay(dayDate, fromDate);
      const isToDate = toDate && isSameDay(dayDate, toDate);

      const isSelected = isFromDate || isToDate;

      const isHighlighted =
        !isSelected &&
        !toDate &&
        fromDate &&
        hoveredDate &&
        isWithinInterval(
          dayDate,
          isBefore(hoveredDate, fromDate)
            ? { start: hoveredDate, end: fromDate }
            : { start: fromDate, end: hoveredDate },
        );

      const isWithinSelectedRange =
        !isSelected &&
        toDate &&
        fromDate &&
        isWithinInterval(dayDate, {
          start: fromDate,
          end: isBefore(toDate, fromDate) ? fromDate : toDate,
        });

      return {
        cursor: 'pointer',
        pointerEvents: isDisabled ? 'none' : undefined,

        ...(isDisabled
          ? {
              '.MuiTypography-root': {
                color: theme.palette.common.grey,
              },
            }
          : {}),

        ...(isHighlighted || isWithinSelectedRange
          ? {
              backgroundColor: isHighlighted
                ? theme.palette.background.default
                : theme.palette.common.grey7,
              borderRadius: 0,
            }
          : undefined),
      };
    };

    const currentMonth = visibleMonthDate;
    const nextMonth = addMonths(visibleMonthDate, 1);

    return (
      <DateFnsLocalizationProvider>
        <Stack position="relative" minWidth={500}>
          <Stack flexDirection="row" flex={1} gap={2}>
            <Stack mt={2} ml={1}>
              <ArrowButton isLeft onClick={showPrevMonth} />
            </Stack>
            <Stack flex={1} alignItems="flex-end">
              <Stack>
                <DateCalendarHeader
                  date={visibleMonthDate}
                  onToggle={() => openYearAndMonthSelectFor((v) => (v ? null : 'currentMonth'))}
                />
                <DateSelectCalendar
                  onHoverDay={setHoveredDate}
                  fromDate={fromDate}
                  toDate={toDate}
                  onClickDay={onSetDate}
                  getDayStyleProps={getDayStyleProps}
                  visibleMonth={currentMonth}
                  {...LeftCalendarProps}
                />
              </Stack>
            </Stack>
            <Stack flex={1} alignItems="flex-start">
              <Stack>
                <DateCalendarHeader
                  date={nextMonth}
                  onToggle={() => openYearAndMonthSelectFor((v) => (v ? null : 'nextMonth'))}
                />
                <DateSelectCalendar
                  onHoverDay={setHoveredDate}
                  fromDate={fromDate}
                  toDate={toDate}
                  onClickDay={onSetDate}
                  getDayStyleProps={getDayStyleProps}
                  visibleMonth={nextMonth}
                  {...RightCalendarProps}
                />
              </Stack>
            </Stack>
            <Stack mt={2} mr={1}>
              <ArrowButton onClick={showNextMonth} />
            </Stack>
          </Stack>
          {!!children && <Divider sx={{ alignSelf: 'stretch', mx: 2 }} />}
          {children}
          {yearMonthSelectOpened && (
            <DatePickerYearMonthSelect
              dateToShow={yearMonthSelectOpened === 'currentMonth' ? currentMonth : nextMonth}
              onSetDateToShow={(date) =>
                setVisibleMonthDate(
                  yearMonthSelectOpened === 'currentMonth' ? date : subMonths(date, 1),
                )
              }
              onClose={() => openYearAndMonthSelectFor(null)}
            />
          )}
        </Stack>
      </DateFnsLocalizationProvider>
    );
  },
);
