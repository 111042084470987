import { MenuList, Stack, styled } from '@mui/material';

export interface SidebarStyledProps {
  isUserMenuOpen?: boolean;
}

export const SIDEBAR_WIDTH = 200;

export const SidebarStyled = styled(Stack)<SidebarStyledProps>(({ theme, isUserMenuOpen }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  width: SIDEBAR_WIDTH,
  height: '100%',
  background: theme.palette.background.default,
  borderRight: theme.mixins.borderValue(),
  padding: theme.spacing(2.5, 1.5, 1.5),
  overflow: isUserMenuOpen ? 'visible' : 'auto',
}));

export const SidebarMenuList = styled(MenuList)(({ theme }) => ({
  '& .MuiMenuItem-root': {
    ...theme.typography.h3,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.primary,
    textWrap: 'wrap',

    '&:hover, &.Mui-selected': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.light2,
    },

    '& .MuiListItemIcon-root': {
      marginRight: theme.spacing(),
      width: theme.spacing(2.5),
      minWidth: theme.spacing(2.5),
      fontSize: theme.spacing(2.5),
      color: 'inherit',
    },
  },
}));
