import {
  DEFAULT_DATE_FORMAT,
  GET_SCHOOL_YEARS_QUERY,
  useCreateSchoolYearsMutation,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSchool } from '../../../../../hooks/useSchool';
import useSchoolYears from '../../../../../hooks/useSchoolYears';
import { SchoolSettingsLayout } from '../../../SchoolSettingsLayout';
import {
  SchoolTuneSchoolYearsForm,
  SchoolTuneSchoolYearsModalContent,
} from './SchoolTuneSchoolYearsModalContent';

export const SchoolTuneSchoolYearsModal: FC = () => {
  const navigate = useNavigate();
  const { showError } = useNotifications();
  const { schoolId, currentSchool } = useSchool();
  const queryClient = useQueryClient();
  const { schoolYears } = useSchoolYears();

  const createSchoolYears = useCreateSchoolYearsMutation();

  const handleClose = useCallback(() => {
    if (createSchoolYears.isLoading) return;
    navigate({ pathname: '/settings/tune', search: '?tab=school_years' });
  }, [navigate, createSchoolYears.isLoading]);

  const handleSubmit = useCallback(
    async (data: SchoolTuneSchoolYearsForm) => {
      if (!schoolId || !data.schoolYears) {
        return;
      }

      createSchoolYears.mutate(
        {
          schoolId,
          schoolYears: data.schoolYears.map((year) => ({
            ...year,
            end: moment(year.start).add(1, 'year').subtract(1, 'day').format(DEFAULT_DATE_FORMAT),
          })),
        },
        {
          onError: showError,
          onSuccess: () => {
            queryClient.invalidateQueries([GET_SCHOOL_YEARS_QUERY]);

            navigate({ pathname: '/settings/tune', search: '?tab=school_years' });
          },
        },
      );
    },
    [createSchoolYears, navigate, queryClient, schoolId, showError],
  );

  return (
    <SchoolSettingsLayout>
      <SchoolTuneSchoolYearsModalContent
        isSaving={createSchoolYears.isLoading}
        title={currentSchool?.name || ''}
        onClose={handleClose}
        onSubmit={handleSubmit}
        schoolYears={schoolYears}
      />
    </SchoolSettingsLayout>
  );
};
