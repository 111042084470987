import { IconButton } from '@mui/material';
import { ConductType, ConductTypeRepresentation } from '@schooly/api';
import { DragIcon, EditIcon } from '@schooly/style';
import { FC } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

import {
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../components/uikit-components/Grid/Grid';
import { ConductTypeOption } from '../../Conduct/ConductCreateModal/ConductTypeOption';

interface SchoolConductTypeRowProps {
  draggableProps?: DraggableProvided;
  draggable?: boolean;
  conductType: Pick<ConductType, 'name' | 'connotation' | 'type'>;
  onEdit?: () => any;
}

const ConductTypeMessageId: { [K in ConductTypeRepresentation]: string } = {
  [ConductTypeRepresentation.TEXT]: 'conduct-type-TEXT',
  [ConductTypeRepresentation.NUMBER]: 'conduct-type-NUMBER',
};

export const SchoolConductTypeRow: FC<SchoolConductTypeRowProps> = ({
  conductType,
  onEdit,
  draggable,
  draggableProps,
}) => {
  const messageId = ConductTypeMessageId[conductType.type];
  return (
    <GridRowStyled
      ref={draggableProps?.innerRef}
      {...draggableProps?.draggableProps}
      style={draggableProps?.draggableProps.style}
      noBorderRadius
      onClick={onEdit}
      sx={{
        cursor: !!onEdit ? 'pointer' : undefined,
        '&:hover': {
          '& .editIcon': {
            visibility: 'visible',
          },
        },
      }}
    >
      <GridRowItem>
        {draggable && (
          <GridRowName sx={{ flex: '1 1 20px', mr: 2 }}>
            <IconButton {...draggableProps?.dragHandleProps} inverse>
              <DragIcon />
            </IconButton>
          </GridRowName>
        )}
        <GridRowName sx={{ flex: '1 1 35%' }}>
          <ConductTypeOption {...conductType} />
        </GridRowName>
        <GridRowName sx={{ flex: '1 1 65%' }}>
          {!!messageId && <FormattedMessage id={messageId} />}
        </GridRowName>
        {onEdit && (
          <GridRowName sx={{ flex: '1 1 0%', ml: 2 }}>
            <IconButton className="editIcon" sx={{ visibility: 'hidden' }}>
              <EditIcon />
            </IconButton>
          </GridRowName>
        )}
      </GridRowItem>
    </GridRowStyled>
  );
};
