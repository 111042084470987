import { Icon, IconButton, Link as MuiLink, Stack, Tooltip, Typography } from '@mui/material';
import { AnnualPlanRecordTypes, EventsStatuses, ReportStatuses } from '@schooly/api';
import { getEventsStatusLabel } from '@schooly/components/filters';
import { HeaderActions, HeaderActionsProps } from '@schooly/components/header-actions';
import { AssessmentStatuses } from '@schooly/constants';
import { EditIcon, LockIcon, RecurringIcon } from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AnnualPlannerCalendarPopoverType } from '../AnnualPlannerCalendar.styled';
import { useAnnualPlannerCreateRecord } from '../AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import { AnnualPlannerRecordMeta, canBeRecurring } from '../scheme';
import {
  getRecordFormattedDate,
  getRecordIncompleteFields,
  getRecordLink,
  getRecordLockHint,
  getRecordTitleByType,
} from '../utils';

export interface AnnualPlannerRecordsLayoutRecordDetailsProps {
  record: AnnualPlannerRecordMeta;
  anchorEl?: HTMLElement | null;
  onClose?: () => void;
}

export const AnnualPlannerRecordsLayoutRecordDetails: FC<AnnualPlannerRecordsLayoutRecordDetailsProps> =
  ({ record, anchorEl, onClose }) => {
    const { $t } = useIntl();
    const navigate = useNavigate();
    const { open: openCreatePopover } = useAnnualPlannerCreateRecord();

    const link = getRecordLink(record);
    const lockHint = getRecordLockHint(record);

    const incompleteFields = useMemo(() => getRecordIncompleteFields(record), [record]);
    const isIncomplete = incompleteFields.length > 0;
    const isRecurring = Boolean(
      canBeRecurring(record) && record.details.recurring_state?.recurrence_id,
    );

    const getPopoverStatus = useCallback(
      (record: AnnualPlannerRecordMeta) => {
        switch (record.type) {
          case AnnualPlanRecordTypes.EVENT:
          case AnnualPlanRecordTypes.HOLIDAY: {
            let styleType: HeaderActionsProps['styleType'];
            const title =
              getRecordIncompleteFields(record).length > 0
                ? $t({ id: 'status-Draft' })
                : $t({ id: getEventsStatusLabel(record.details.status) });

            switch (record.details.status) {
              case EventsStatuses.Canceled:
                styleType = 'error';
                break;
              case EventsStatuses.Published:
                styleType = 'success';
                break;
            }

            return (
              <HeaderActions
                title={title}
                styleType={styleType}
                actions={[]}
                pending={false}
                disabled
              />
            );
          }
          case AnnualPlanRecordTypes.ASSESSMENT: {
            let styleType: HeaderActionsProps['styleType'];
            let title = '';

            switch (record.details.status) {
              case AssessmentStatuses.Published:
                styleType = 'success';
                title = $t({ id: 'status-Published' });
                break;
              default:
                title =
                  getRecordIncompleteFields(record).length > 0
                    ? $t({ id: 'status-Draft' })
                    : $t({ id: 'status-NotPublished' });
                break;
            }

            return (
              <HeaderActions
                title={title}
                styleType={styleType}
                actions={[]}
                pending={false}
                disabled
              />
            );
          }
          case AnnualPlanRecordTypes.REPORT: {
            let styleType: HeaderActionsProps['styleType'];
            let title =
              getRecordIncompleteFields(record).length > 0
                ? $t({ id: 'status-Draft' })
                : $t({ id: 'status-NotPublished' });

            switch (record.details.report_status) {
              case ReportStatuses.Draft:
                styleType = undefined;
                title = $t({ id: 'status-Draft' });
                break;
              case ReportStatuses.Published:
                styleType = 'success';
                title = $t({ id: 'status-Published' });
                break;
            }

            return (
              <HeaderActions
                title={title}
                styleType={styleType}
                actions={[]}
                pending={false}
                disabled
              />
            );
          }
          default:
            return null;
        }
      },
      [$t],
    );

    return (
      <Stack
        sx={(theme) => ({
          p: 1,
          gap: 0.5,

          '&:hover': {
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.spacing(0.5),
          },
        })}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          sx={{
            '& .HeaderActions': {
              height: 'unset',
            },
          }}
        >
          <Typography color="text.primary">{getRecordFormattedDate(record)}</Typography>
          {getPopoverStatus(record)}
        </Stack>

        <Stack direction="row" alignItems="center" gap={0.5}>
          {lockHint && (
            <Tooltip
              title={$t({ id: lockHint })}
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    px: 0.5,
                    py: 0.25,
                    maxWidth: 'unset',
                    whiteSpace: 'nowrap',
                  },
                },
              }}
            >
              <Icon
                sx={(theme) => ({
                  fontSize: theme.spacing(2),
                })}
              >
                <LockIcon />
              </Icon>
            </Tooltip>
          )}

          <Stack flex="1 1 100%" direction="row" gap={0.5}>
            {link ? (
              <Typography
                component={MuiLink}
                href={link}
                variant="h3"
                sx={{
                  alignSelf: 'start',
                  textAlign: 'left',
                  overflowWrap: 'anywhere',
                }}
                onClick={(event) => {
                  // should close a tooltip first, then navigate
                  event.preventDefault();
                  event.stopPropagation();

                  onClose?.();
                  navigate(link);
                }}
              >
                {record.title}
              </Typography>
            ) : (
              <Typography variant="h3">{record.title}</Typography>
            )}

            {isRecurring && (
              <Icon sx={{ color: 'text.primary' }}>
                <RecurringIcon />
              </Icon>
            )}
          </Stack>

          {!lockHint && (
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                if (anchorEl) {
                  onClose?.();
                  openCreatePopover(anchorEl, [record.start, record.end], record);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </Stack>

        {(() => {
          const popoverType = (
            <AnnualPlannerCalendarPopoverType type={record.type} incomplete={isIncomplete}>
              <span>
                <FormattedMessage id={getRecordTitleByType(record.type)} />
              </span>
            </AnnualPlannerCalendarPopoverType>
          );

          return isIncomplete ? (
            <Tooltip
              title={$t(
                { id: 'annualPlanner-Records-Details-Incomplete-tooltip' },
                {
                  type: record.type,
                },
              )}
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    px: 0.5,
                    py: 0.25,
                    maxWidth: 'unset',
                    whiteSpace: 'nowrap',
                  },
                },
              }}
            >
              {popoverType}
            </Tooltip>
          ) : (
            popoverType
          );
        })()}
      </Stack>
    );
  };
