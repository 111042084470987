import { INVITE_ID_SEARCH_PARAM, REDIRECT_SEARCH_PARAM } from '@schooly/constants';
import { Auth } from 'aws-amplify';
import { createContext, useContext } from 'react';
import { FC, PropsWithChildren, useCallback, useRef } from 'react';

export interface AuthRedirectContextParams {
  inviteId: string | null;
  redirectIfAuthenticated: () => Promise<void>;
}

export const AuthRedirectContext = createContext<AuthRedirectContextParams>({
  inviteId: null,
  redirectIfAuthenticated: async () => {},
});

export const useAuthRedirectContext = () => useContext(AuthRedirectContext);

export type WithAuthRedirectProps = PropsWithChildren<{
  redirectToUrl: string;
}>;

export const WithAuthRedirect: FC<WithAuthRedirectProps> = ({ children, redirectToUrl }) => {
  const initialUrl = useRef(new URL(window.location.href)).current;
  const inviteId = initialUrl.searchParams.get(INVITE_ID_SEARCH_PARAM);

  const redirectIfAuthenticated = useCallback(async () => {
    try {
      await Auth.currentSession();
      const redirectUrl = initialUrl.searchParams.get(REDIRECT_SEARCH_PARAM);

      if (redirectUrl) {
        window.location.assign(decodeURIComponent(redirectUrl));
        return;
      }

      if (inviteId) {
        const resultUrl = new URL(redirectToUrl);
        resultUrl.searchParams.append(INVITE_ID_SEARCH_PARAM, inviteId);
        window.location.assign(resultUrl.toString());
        return;
      }

      window.location.assign(redirectToUrl);
    } catch {}
  }, [initialUrl, inviteId, redirectToUrl]);

  return (
    <AuthRedirectContext.Provider value={{ redirectIfAuthenticated, inviteId }}>
      {children}
    </AuthRedirectContext.Provider>
  );
};
