import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { AssessmentBase } from './apiTypes/assessments';
import { GroupType } from './apiTypes/groups';
import { ApiError, PagedResponse, SORT_DIRECTION } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { BaseUserSchoolRelation } from './apiTypes/relations';
import {
  ListReportRecipients,
  ListReportsForRelation,
  ListReportsForSchool,
  Report,
  ReportAction,
  ReportForAssessment,
  ReportStatuses,
} from './apiTypes/reports';
import { FilterKeys, UserFilter } from './apiTypes/users';
import * as api from './requests';
import { getFormattedParams } from './utils/getFormattedParams';
import { getSortParams } from './utils/getSortParam';
import { removeObjectFalsyValues } from './utils/removeObjectFalsyValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

const REPORTS_URL = '/reports/';
const EXPORT_REPORT_URL = '/export/reports';
const DEFAULT_PAGE_SIZE = 50;

export function getReports({
  filters,
  page_number,
  page_size = DEFAULT_PAGE_SIZE,
  school_id,
  sort = [
    { columnTextId: 'report_date', direction: SORT_DIRECTION.DESC },
    { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
  ],
  query,
}: ListReportsForSchool): Promise<PagedResponse<Report>> {
  const dateFrom = filters?.date?.[0];
  const dateTo = filters?.date?.[1] || filters?.date?.[0];

  const params = removeObjectFalsyValues({
    page_number,
    page_size,
    report_date_from: dateFrom,
    report_date_to: dateTo,
    search_query: query,
    sort_by: getSortParams(sort),
  });

  const formattedParams = getFormattedParams(params, filters, [
    FilterKeys.Date,
    FilterKeys.Report,
    FilterKeys.AssessmentStatus,
  ]);

  return api.get(`${REPORTS_URL}for-school/${school_id}`, {
    params: formattedParams,
  });
}

export const GET_REPORTS_QUERY = `${REPORTS_URL}GET_REPORTS_QUERY`;

export const REPORTS_QUERY_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.Staff,
  FilterKeys.ReportStatus,
] as const;

export type GetReportsQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.Staff]?: string[];
  [FilterKeys.ReportStatus]?: ReportStatuses[];
};

export type GetReportsQuerySort = {
  columnTextId: 'name' | 'report_date' | 'report_status';
  direction: SORT_DIRECTION;
};

export const useGetReportsQuery = (
  initialParams: Omit<ListReportsForSchool, 'filters' | 'sort'> & {
    sort?: GetReportsQuerySort;
    filters: GetReportsQueryFilters;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<Report>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<Report>, ApiError>(
    [GET_REPORTS_QUERY, params],
    ({ pageParam }) =>
      getReports({
        page_number: pageParam,
        ...params,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, params, setParams };
};

export function getReport(reportId: string): Promise<ReportForAssessment | undefined> {
  return api.get(`${REPORTS_URL}${reportId}`);
}

export const GET_REPORT_QUERY = `${REPORTS_URL}GET_REPORT_QUERY`;

export const useGetReportQuery = (
  id: string,
  options?: RQUseQueryOptions<ReportForAssessment | undefined>,
) => {
  return useQuery<ReportForAssessment | undefined, ApiError>(
    [GET_REPORT_QUERY, id],
    () => getReport(id),
    options,
  );
};

export type GetAreasOfLearningResponse = { name: string; id: string }[];

export function getAreasOfLearning(schoolId: string): Promise<GetAreasOfLearningResponse> {
  return api.get(`${REPORTS_URL}for-school/${schoolId}/areas-of-learning`);
}

export const GET_AREAS_OF_LEARNING_QUERY = `${REPORTS_URL}GET_AREAS_OF_LEARNING`;

export const useGetAreasOfLearningQuery = (
  schoolId: string,
  options?: RQUseQueryOptions<GetAreasOfLearningResponse>,
) => {
  return useQuery<GetAreasOfLearningResponse, ApiError>(
    [GET_AREAS_OF_LEARNING_QUERY, schoolId],
    () => getAreasOfLearning(schoolId),
    options,
  );
};

export type AssessmentToCreate = Pick<AssessmentBase, 'name' | 'assessment_date' | 'methods'> & {
  age_groups: { id: string; name: string; subjects?: { id: string; name: string }[] }[];
  subjects: { id: string; name: string }[];
  is_tutor: boolean;
  deletedEntriesCount?: number;
};

export type GetPossibleAutogeneratedAssessmentsResponse = {
  assessments: AssessmentToCreate[];
};
export type GetPossibleAutogeneratedAssessmentsParams = {
  schoolId: string;
  report: ReportSave;
};

const prepareReportData = ({
  name,
  areas_of_learning,
  subject_ids,
  age_group_ids,
  with_tutor_feedback,
  scheduled_publish_date,
  report_status,
}: ReportSave) => ({
  name,
  scheduled_publish_date,
  subjects: subject_ids,
  age_groups: age_group_ids,
  with_tutor_feedback,
  report_status,
  areas_of_learning: areas_of_learning.map((a) => ({
    ...a,
    id: a.assessment_id ? a.id : undefined,
    methods: a.methods.map((m) => ({
      method_type: m.method_type,
      score_out_of: m.score_out_of || null,
      select_list_id: m.select_list_id || null,
    })),
  })),
});

export function getPossibleAutogeneratedAssessments({
  report,
  schoolId,
}: GetPossibleAutogeneratedAssessmentsParams): Promise<GetPossibleAutogeneratedAssessmentsResponse> {
  const data = prepareReportData(report);

  return api.post(`${REPORTS_URL}for-school/${schoolId}/possible-assessments`, data);
}

export const useGetPossibleAutogeneratedAssessmentsMutation = (
  options?: RQUseMutationOptions<
    GetPossibleAutogeneratedAssessmentsResponse,
    GetPossibleAutogeneratedAssessmentsParams
  >,
): RQUseMutationResult<
  GetPossibleAutogeneratedAssessmentsResponse,
  GetPossibleAutogeneratedAssessmentsParams
> => {
  return useMutation(getPossibleAutogeneratedAssessments, options);
};

export const GET_POSSIBLE_ASSESSMENTS_QUERY = `${REPORTS_URL}GET_POSSIBLE_ASSESSMENTS_QUERY`;

export const useGetPossibleAssessmentsQuery = (
  initialParams: GetPossibleAutogeneratedAssessmentsParams,
  options?: RQUseQueryOptions<GetPossibleAutogeneratedAssessmentsResponse>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useQuery<GetPossibleAutogeneratedAssessmentsResponse, ApiError>(
    [GET_POSSIBLE_ASSESSMENTS_QUERY, params],
    () => getPossibleAutogeneratedAssessments(params),
    options,
  );

  return { ...query, params, setParams };
};

export type ReportSave = Pick<
  ReportForAssessment,
  'name' | 'areas_of_learning' | 'scheduled_publish_date' | 'with_tutor_feedback' | 'report_status'
> & {
  subject_ids: string[];
  age_group_ids: string[];
  assessment_ids: string[];
};

export type CreateReportResponse = { success?: string; report: ReportForAssessment };

type SaveDraftReportParams = {
  schoolId: string;
  report: ReportSave;
};

export function createReport({
  schoolId,
  report,
}: SaveDraftReportParams): Promise<CreateReportResponse> {
  const data = {
    ...prepareReportData(report),
    assessment_ids: report.assessment_ids?.length ? report.assessment_ids : undefined,
  };

  return api.post(`${REPORTS_URL}for-school/${schoolId}`, data);
}

export type CreateReportParams = {
  schoolId: string;
  report: ReportSave;
};

export const CREATE_REPORT_MUTATION = `${REPORTS_URL}CREATE_REPORT_MUTATION`;

export const useCreateReportMutation = (
  options?: RQUseMutationOptions<CreateReportResponse, CreateReportParams>,
): RQUseMutationResult<CreateReportResponse, CreateReportParams> => {
  return useMutation([CREATE_REPORT_MUTATION], createReport, options);
};

export const UPDATE_REPORT_MUTATION = `${REPORTS_URL}UPDATE_REPORT_MUTATION`;

export const useUpdateReportMutation = (
  options?: RQUseMutationOptions<UpdateReportResponse, UpdateReportParams>,
): RQUseMutationResult<UpdateReportResponse, UpdateReportParams> => {
  return useMutation([UPDATE_REPORT_MUTATION], updateReport, options);
};

export type UpdateReportParams = {
  reportId: string;
  report: ReportSave;
  no_save?: boolean;
  confirmed?: boolean;
};

export type UpdateReportResponse = { success: string; report: ReportForAssessment };

export function updateReport({
  reportId,
  report,
  confirmed = true,
  no_save,
}: UpdateReportParams): Promise<UpdateReportResponse> {
  const data = {
    ...prepareReportData(report),
    assessment_ids: report.assessment_ids?.length ? report.assessment_ids : undefined,
    no_save,
    confirmed,
  };

  return api.patch(`${REPORTS_URL}${reportId}`, data);
}

export const VALIDATE_REPORT_MUTATION = `${REPORTS_URL}VALIDATE_REPORT_MUTATION`;

export type AssessmentsToRemove = {
  name: string;
  id: string;
  entries: number;
  groups: [
    {
      name: string;
      id: string;
    },
  ];
};

export type ValidateReportError = {
  assessments_to_remove: AssessmentsToRemove[];
};

export const useValidateReportMutation = (
  options?: RQUseMutationOptions<
    UpdateReportResponse,
    UpdateReportParams,
    any,
    ValidateReportError | ApiError
  >,
): RQUseMutationResult<
  UpdateReportResponse,
  UpdateReportParams,
  any,
  ValidateReportError | ApiError
> => {
  return useMutation(
    [VALIDATE_REPORT_MUTATION],
    (params) => updateReport({ ...params, no_save: true, confirmed: false }),
    options,
  );
};

export const DELETE_REPORT_MUTATION = `${REPORTS_URL}DELETE_REPORT_MUTATION`;

export function deleteReport(reportId: string): Promise<{ success: string }> {
  return api.remove(`${REPORTS_URL}${reportId}`);
}

export const useDeleteReportMutation = (
  options?: RQUseMutationOptions<{ success: string }, string>,
): RQUseMutationResult<{ success: string }, string> => {
  return useMutation([DELETE_REPORT_MUTATION], deleteReport, options);
};

export type PerformReportActionRequest = {
  id: string;
  status: ReportAction;
};

export type PerformReportActionResponse = { success: string };

export const PERFORM_REPORT_ACTION_MUTATION = `${REPORTS_URL}PERFORM_REPORT_ACTION_MUTATION`;

export function performReportAction(
  id: string,
  action: ReportAction,
): Promise<PerformReportActionResponse> {
  return api.post(`${REPORTS_URL}${id}/action`, { action });
}

export const usePerformReportActionMutation = (
  options?: RQUseMutationOptions<PerformReportActionResponse, PerformReportActionRequest>,
): RQUseMutationResult<PerformReportActionResponse, PerformReportActionRequest> => {
  return useMutation(
    [PERFORM_REPORT_ACTION_MUTATION],
    (params) => performReportAction(params.id, params.status),
    options,
  );
};

export const GET_REPORT_RECIPIENTS_QUERY = `${REPORTS_URL}GET_REPORT_RECIPIENTS_QUERY`;

export function getReportRecipients({
  report_id,
  filters: { only_tutor_groups, ...filters } = {} as UserFilter,
  pageNumber = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  query,
  sort = [
    { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    { columnTextId: 'given_name', direction: SORT_DIRECTION.ASC },
  ],
  token,
}: ListReportRecipients): Promise<PagedResponse<BaseUserSchoolRelation>> {
  const onlyTutorGroups = only_tutor_groups?.join('') === '1';

  const params = removeObjectUndefinedNullValues({
    page_number: pageNumber,
    page_size: pageSize,

    report_date: filters?.date?.[0],
    age_group: filters?.age_group?.join(','),
    house: filters?.house?.join(','),
    subject: onlyTutorGroups ? undefined : filters?.subject?.join(','),
    group_type: onlyTutorGroups ? GroupType.TutorGroup : undefined,

    search_query: query,
    sort_by: getSortParams(sort),
  });

  const formattedParams = getFormattedParams(params, filters);

  return api.get(`${REPORTS_URL}${report_id}/recipients`, {
    params: formattedParams,
    cancelToken: token,
  });
}

export const useGetReportRecipientsQuery = (
  { filters, ...params }: ListReportRecipients,
  options?: RQUseInfiniteQueryOptions<PagedResponse<BaseUserSchoolRelation>>,
) => {
  return useInfiniteQuery<PagedResponse<BaseUserSchoolRelation>, ApiError>(
    [GET_REPORT_RECIPIENTS_QUERY, params, JSON.stringify(filters)],
    ({ pageParam }) => getReportRecipients({ pageNumber: pageParam, filters, ...params }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );
};

function getReportsForRelation({
  relationId,
  dateFrom,
  dateTo,
  query,
  sort = [
    { columnTextId: 'report_date', direction: SORT_DIRECTION.DESC },
    { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
  ],
  token,
}: ListReportsForRelation): Promise<Report[]> {
  const params = removeObjectFalsyValues({
    report_date_from: dateFrom,
    report_date_to: dateTo,
    search_query: query,
    sort_by: getSortParams(sort),
  });

  return api.get(`${REPORTS_URL}for-relation/${relationId}`, {
    params,
    cancelToken: token,
  });
}

export const GET_REPORTS_FOR_RELATION_QUERY = `${REPORTS_URL}GET_REPORTS_FOR_RELATION_QUERY`;

export const useGetReportsForRelationQuery = (
  params: ListReportsForRelation,
  options?: RQUseQueryOptions<Report[]>,
) => {
  return useQuery<Report[], ApiError>(
    [GET_REPORTS_FOR_RELATION_QUERY, params.relationId, params],
    () => getReportsForRelation(params),
    options,
  );
};

export interface ExportReportRequest {
  userId?: string;
  schoolId?: string;
  reportId: string;
}

export function exportReport({
  userId,
  schoolId,
  reportId,
}: ExportReportRequest): Promise<ArrayBuffer> | undefined {
  if (!userId || !schoolId || !reportId) return;

  const params = { school_id: schoolId, report_id: reportId };
  return api.get(`${EXPORT_REPORT_URL}/for-profile/${userId}`, {
    params,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });
}
