import { SchoolYearPeriod } from '@schooly/api';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { areIntervalsOverlapping, isAfter, startOfToday } from 'date-fns';
import { useCallback, useMemo } from 'react';

export const usePeriodValidation = () => {
  const validatePastDate = useCallback((date: Date | string) => {
    const currentDate = typeof date === 'string' ? newDateTimezoneOffset(date) : date;
    const today = startOfToday();

    return isAfter(today, currentDate);
  }, []);

  const validateDatePeriods = useCallback(
    (left: { start: string; end: string }, right: { start: string; end: string }) => {
      return areIntervalsOverlapping(
        { start: newDateTimezoneOffset(left.start), end: newDateTimezoneOffset(left.end) },
        {
          start: newDateTimezoneOffset(right.start),
          end: newDateTimezoneOffset(right.end),
        },
        { inclusive: true },
      );
    },
    [],
  );

  const validatePeriodsOverlap = useCallback(
    (startDate: string, endDate: string, periods: SchoolYearPeriod[]) => {
      return periods.some(({ date_from, date_to }) => {
        if (!date_from && !date_to) return false;
        return validateDatePeriods(
          { start: startDate, end: endDate },
          { start: date_from, end: date_to },
        );
      });
    },
    [validateDatePeriods],
  );

  return useMemo(
    () => ({ validatePeriodsOverlap, validateDatePeriods, validatePastDate }),
    [validateDatePeriods, validatePastDate, validatePeriodsOverlap],
  );
};
