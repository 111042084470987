import { Button, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon, Spin } from '@schooly/style';
import { FC, ReactNode, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AuthenticationModalContent, AuthenticationModalFooter } from './AuthenticationModal';
import { VerifyEmailModalResendLink } from './VerifyEmailModalResendLink';
import { VerifyEmailModalSegmentedInput } from './VerifyEmailModalSegmentedInput';

export interface VerifyEmailModalContentProps {
  error?: string;
  email: string;
  isLoading: boolean;
  onResetError: () => void;
  onResend: () => Promise<void>;
  onSubmit: (code: string) => void;
  onBack?: () => void;
  submitButtonIcon?: ReactNode;
  submitButtonTextId?: string;
}

export const VerifyEmailModalContent: FC<VerifyEmailModalContentProps> = ({
  error,
  isLoading,
  onResend,
  onSubmit,
  onResetError,
  onBack,
  email,
  submitButtonIcon,
  submitButtonTextId = 'verify-Continue',
}) => {
  const [code, setCode] = useState('');

  const handleResend = useCallback(() => {
    setCode('');
    return onResend();
  }, [onResend]);

  const handleCodeChange = useCallback(
    (v: string) => {
      if (error) onResetError();
      setCode(v);
    },
    [onResetError, error],
  );

  return (
    <>
      <AuthenticationModalContent>
        <Stack gap={2}>
          <Typography variant="h1">
            <FormattedMessage id="verify-VerifyEmail" />
          </Typography>
          <div>
            <Typography variant="h3">
              <FormattedMessage id="verify-WeSentACode" values={{ email }} />
            </Typography>
            <Typography variant="h3">
              <FormattedMessage id="verify-PleaseEnter" />
            </Typography>
          </div>
          <VerifyEmailModalSegmentedInput error={error} value={code} onChange={handleCodeChange} />
          <div>
            <Typography variant="h3" color="common.grey">
              <FormattedMessage id="verify-CantFind" />
            </Typography>
            <Typography variant="h3" color="common.grey">
              <FormattedMessage id="verify-PleaseCheck" />{' '}
              <VerifyEmailModalResendLink onResend={handleResend} />
            </Typography>
          </div>
        </Stack>
      </AuthenticationModalContent>
      <AuthenticationModalFooter sx={{ justifyContent: !!onBack ? 'space-between' : undefined }}>
        {!!onBack && (
          <Button variant="outlined" startIcon={<ArrowLeftIcon />} onClick={onBack}>
            <FormattedMessage id="action-Back" />
          </Button>
        )}
        <Button
          endIcon={isLoading ? <Spin /> : submitButtonIcon || <ArrowRightIcon />}
          disabled={isLoading || code.length < 6}
          onClick={() => onSubmit(code)}
        >
          <FormattedMessage id={submitButtonTextId} />
        </Button>
      </AuthenticationModalFooter>
    </>
  );
};
