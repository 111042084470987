import { Box, Link, Stack, styled } from '@mui/material';

export const ReportsPreviewEntriesStyled = styled(Stack)(({ theme }) => ({
  flex: '1 1 auto',
  backgroundColor: theme.palette.background.default,
  height: '100%',
  width: '100%',
  padding: theme.spacing(0, 2, 0, 2),
  overflowX: 'hidden',
}));

export const ReportsPreviewEntriesHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2.5, 1, 2.5, 0.5),
  backgroundColor: theme.palette.background.default,
  position: 'sticky',
  top: 0,
  zIndex: 1,

  '& h2': {
    margin: 0,
  },
}));

export const ReportsPreviewEntriesContent = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
}));

export const ReportsPreviewEntriesBottom = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: '0 0 auto',
  padding: theme.spacing(1.5, 0),
  backgroundColor: theme.palette.background.default,
  position: 'sticky',
  bottom: 0,

  '& button': {
    flex: '0 0 auto',
  },
}));

export const ReportsPreviewEntriesShowAllComments = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  flexShrink: 0,
  marginLeft: theme.spacing(2.5),

  ':hover': { color: theme.palette.primary.main, textDecoration: 'none' },
}));
