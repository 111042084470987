import debounce from 'lodash.debounce';
import React, {
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import usePortal from 'react-useportal';

import buildClassName from '../../../utils/buildClassName';

import './index.scss';

const NULL_EVENT = { currentTarget: { contains: () => false } };
const OPEN_POPOVER_DELAY = 100;

export enum PopoverSize {
  Small = 'small',
  Medium = 'medium',
}
interface PopoverProps extends PropsWithChildren {
  anchorRef: React.RefObject<HTMLElement>;
  className?: string;
  open: boolean;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  offsetX?: number;
  offsetY?: number;
  isUnderElement?: boolean;
  forsedZIndex?: number;
  size?: PopoverSize;
}

const Popover: React.FC<PopoverProps> = ({
  anchorRef,
  className,
  open,
  children,
  onMouseEnter,
  onMouseLeave,
  forsedZIndex,
  offsetX = 0,
  offsetY = 10,
  size,
  isUnderElement = true,
}) => {
  const clientRect = useRef<DOMRect | undefined>();

  clientRect.current = anchorRef.current?.getBoundingClientRect();

  const getTop = useCallback(
    (el: DOMRect) => {
      const height = isUnderElement ? el.height : 0;

      return `${el.top + height + offsetY + window.scrollY}px`;
    },
    [isUnderElement, offsetY],
  );

  const getCssStyles = useCallback(
    (el: DOMRect) => {
      const left = el.left + el.height / 2 + offsetX;
      return `
    position: absolute;
    z-index: ${forsedZIndex || 1500};
    top: ${getTop(el)};
    left: ${left}px;
  `;
    },
    [forsedZIndex, getTop, offsetX],
  );

  const { isOpen, openPortal, closePortal, Portal } = usePortal({
    closeOnOutsideClick: false,
    onOpen: ({ portal }) => {
      requestAnimationFrame(() => {
        if (!clientRect.current) {
          return;
        }

        // eslint-disable-next-line no-param-reassign
        portal.current.style.cssText = getCssStyles(clientRect.current);
      });
    },
  });

  // Refs are needed here because `openPortal` and `closePortal` update on every render
  const openPortalRef = useRef(openPortal);
  const closePortalRef = useRef(closePortal);

  useEffect(() => {
    openPortalRef.current = openPortal;
    closePortalRef.current = closePortal;
  }, [openPortal, closePortal]);

  const openPopup = useCallback((e?: any) => openPortalRef.current(e || NULL_EVENT), []);

  const closePopup = useCallback((e?: any) => closePortalRef.current(e || NULL_EVENT), []);

  const debouncedPopup = useMemo(
    () =>
      debounce((o: boolean | undefined) => {
        if (o) {
          openPopup();
        } else {
          closePopup();
        }
      }, OPEN_POPOVER_DELAY),
    [openPopup, closePopup],
  );

  useEffect(() => {
    debouncedPopup(open);
  }, [open, debouncedPopup]);

  const popoverClassName = buildClassName(
    'Popover',
    isOpen && 'open',
    size === 'small' && 'Popover--small',
    className,
  );

  return (
    <Portal>
      <div className={popoverClassName} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
      </div>
    </Portal>
  );
};

export default Popover;
