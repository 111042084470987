import React, { PropsWithChildren } from 'react';

import logo from '../../../assets/images/schooly-logo.svg';

import './index.scss';

const MainLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <main className="TextLayout">
    <header className="TextLayout__header">
      <div className="TextLayout__header-content">
        <img alt="Schooly logo" className="TextLayout__logo" src={logo} />
      </div>
    </header>

    <div className="TextLayout__content custom-scrollbar">{children}</div>
  </main>
);

export default MainLayout;
