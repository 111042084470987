import { Chip, styled } from '@mui/material';

export const RejectedChip = styled(Chip)(({ theme }) => ({
  color: `${theme.palette.background.default} !important`,
  background: theme.palette.common.grey,
  px: 1,
  py: 0.5,
  mr: 0.5,
}));

export const ConvertedChip = styled(Chip)(({ theme }) => ({
  color: `${theme.palette.background.default} !important`,
  background: '#6FCF97',
  px: 1,
  py: 0.5,
  mr: 0.5,
}));
