import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63841 3.36171C8.43025 3.15355 8.09276 3.15355 7.8846 3.36171L5.98022 5.26609L4.07342 3.35929C3.87617 3.16204 3.55637 3.16204 3.35913 3.35929C3.16188 3.55653 3.16188 3.87633 3.35913 4.07358L5.26593 5.98038L3.36173 7.88457C3.15357 8.09273 3.15357 8.43023 3.36173 8.63839C3.56989 8.84655 3.90738 8.84655 4.11554 8.63839L6.01974 6.73419L7.92633 8.64078C8.12358 8.83802 8.44338 8.83802 8.64062 8.64078C8.83787 8.44353 8.83787 8.12373 8.64062 7.92649L6.73403 6.0199L8.63841 4.11552C8.84657 3.90736 8.84657 3.56987 8.63841 3.36171Z"
      fill="#B3BBCB"
    />
  </svg>
);
