import { Stack, Typography } from '@mui/material';
import { EditIcon, PlusIcon } from '@schooly/style';
import React, { FC, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import {
  ModalMain,
  ModalSubpanel,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalRightSidebar } from '../../../../components/uikit-components/Modal/ModalRightSidebar';
import { MessagesCreateModalMode } from '../../../../context/messages/MessageContext';
import { useMessage } from '../../../../context/messages/useMessage';
import { MessagesCreateModalRecipientsStaffMain } from './MessagesCreateModalRecipientsStaffMain';
import { MessagesCreateModalRecipientsStaffRightSidebar } from './MessagesCreateModalRecipientsStaffRightSidebar';
import { MessageCreateModalRecipientTypePanel } from './ModalCreateModalRecipients.styled';

export const MessagesCreateModalRecipientsStaff: FC = () => {
  const panelRef = useRef<HTMLDivElement>(null);
  const { mode, hasStaff, actions } = useMessage();

  const isSubpanel = mode === MessagesCreateModalMode.Recipients;
  const isActive = mode === MessagesCreateModalMode.RecipientsStaff;

  const handleClick = useCallback(() => {
    actions.setMode(MessagesCreateModalMode.RecipientsStaff);
  }, [actions]);

  const renderContent = () => {
    return (
      <>
        <Stack sx={{ height: '100%' }}>
          <Stack ref={panelRef} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">
              <FormattedMessage id="messages-Staff" />
            </Typography>
            {/*{errors?.recipients && (*/}
            {/*  <Typography variant="body2" color="error">*/}
            {/*    {renderError(errors?.recipients)}*/}
            {/*  </Typography>*/}
            {/*)}*/}
            {!isActive &&
              (hasStaff ? (
                <SimpleButton startIcon={<EditIcon />} onClick={handleClick}>
                  <FormattedMessage id="messages-EditStaff" />
                </SimpleButton>
              ) : (
                <SimpleButton startIcon={<PlusIcon />} onClick={handleClick}>
                  <FormattedMessage id="messages-AddStaff" />
                </SimpleButton>
              ))}
          </Stack>
          <ModalMain>{isActive && <MessagesCreateModalRecipientsStaffMain />}</ModalMain>
        </Stack>

        {isActive && (
          <ModalRightSidebar open={isActive} anchorEl={panelRef.current} arrowOffset={[0, 20]}>
            <MessagesCreateModalRecipientsStaffRightSidebar />
          </ModalRightSidebar>
        )}
      </>
    );
  };

  return isSubpanel ? (
    <ModalSubpanel
      sx={(theme) => ({
        [theme.breakpoints.down('lg')]: {
          px: theme.spacing(4),
        },
      })}
      fullHeight={isActive}
      active
      withBorderTop
    >
      {renderContent()}
    </ModalSubpanel>
  ) : (
    <MessageCreateModalRecipientTypePanel
      sx={(theme) => ({
        [theme.breakpoints.down('lg')]: {
          px: theme.spacing(4),
        },
      })}
      fullHeight={isActive}
      active={isActive}
      withBorderTop
    >
      {renderContent()}
    </MessageCreateModalRecipientTypePanel>
  );
};
