import { IconButton, Stack, Typography } from '@mui/material';
import { AssessmentsGradeUpdate } from '@schooly/api';
import {
  Counter,
  CrossIcon,
  EditIcon,
  GridRowCell,
  GridRowItem,
  GridRowStyled,
  ModalContent,
  ModalLarge,
  PlusIcon,
  SimpleButton,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { SchoolAssessmentsGradeModalContent } from '../SchoolAssessmentGrades/SchoolAssessmentsGradeModalContent';

type SchoolCreateAssessmentGradesModalProps = {
  title?: string;
  grades: AssessmentsGradeUpdate[];
  onClose: () => void;
  onSubmit: (form: SchoolCreateAssessmentGradesModalForm) => void;
};

export type SchoolCreateAssessmentGradesModalForm = {
  grades: AssessmentsGradeUpdate[];
};

export const SchoolCreateAssessmentGradesModal: FC<SchoolCreateAssessmentGradesModalProps> = ({
  title,
  grades: initialGrades,
  onClose,
  onSubmit,
}) => {
  const { $t } = useIntl();
  const form = useForm<SchoolCreateAssessmentGradesModalForm>({
    defaultValues: {
      grades: initialGrades,
    },
  });
  const [editingGradeIndex, setEditingGradeIndex] = useState<number | null>(
    initialGrades.length ? null : -1,
  );

  const {
    fields: grades,
    append,
    remove,
    update,
  } = useFieldArray({
    control: form.control,
    name: 'grades',
  });

  useEffect(() => {
    onSubmit({ grades });
  }, [grades, onSubmit]);

  const editGradeWithActions = useMemo(() => {
    if (editingGradeIndex === null) return;

    const onClose = () => setEditingGradeIndex(null);

    if (editingGradeIndex === -1) {
      return {
        onSubmit: (v: AssessmentsGradeUpdate) => {
          append(v);
          onClose();
        },
        onClose,
        isNewItem: true,
      };
    }

    return {
      grade: grades[editingGradeIndex],
      onSubmit: (v: AssessmentsGradeUpdate) => {
        update(editingGradeIndex, v);
        onClose();
      },
      onDelete: () => {
        remove(editingGradeIndex);
        onClose();
      },
      onClose,
      isNewItem: false,
    };
  }, [append, grades, editingGradeIndex, remove, update]);

  useEffect(() => {
    if (!!editGradeWithActions || grades.length) return;
    onClose();
  }, [editGradeWithActions, onClose, grades.length]);

  return (
    <>
      <ModalLarge open={!editGradeWithActions} onClose={onClose}>
        <ModalHeader active title={title}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Typography variant="h2">{$t({ id: 'assessments-Grades' })}</Typography>
              <Counter sx={{ minWidth: 20, textAlign: 'center', fontWeight: 600 }}>
                {grades.length}
              </Counter>
            </Stack>
            <SimpleButton startIcon={<PlusIcon />} onClick={() => setEditingGradeIndex(-1)}>
              {$t({ id: 'assessments-AddGradingSystem' })}
            </SimpleButton>
          </Stack>

          <Stack mt={3}>
            {grades?.map((grade, i) => (
              <GridRowStyled
                key={grade.id}
                sx={{
                  '& :hover': {
                    '.editRoleIcon': {
                      visibility: 'visible',
                    },
                  },
                }}
              >
                <GridRowItem gap={2}>
                  <GridRowCell flex={1}>
                    <TypographyWithOverflowHint fontSize={15}>
                      {grade.name}
                    </TypographyWithOverflowHint>
                  </GridRowCell>
                  <GridRowCell width={250}>
                    <Typography>{grade.options.map((o) => o.name).join(', ')}</Typography>
                  </GridRowCell>
                  <GridRowCell width={400}>
                    <Typography>{grade.description}</Typography>
                  </GridRowCell>
                  <IconButton
                    className="editRoleIcon"
                    sx={{
                      visibility: 'hidden',
                    }}
                    inverse
                    onClick={() => setEditingGradeIndex(i)}
                  >
                    <EditIcon />
                  </IconButton>
                </GridRowItem>
              </GridRowStyled>
            ))}
          </Stack>
        </ModalContent>
      </ModalLarge>
      {!!editGradeWithActions && (
        <SchoolAssessmentsGradeModalContent {...editGradeWithActions} isSaving={false} />
      )}
    </>
  );
};
