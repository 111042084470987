import {
  Box,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  CheckIcon,
  CopyIcon,
  DynamicVariableIcon,
  MenuItemArrow,
  TypographyWithOverflowHint,
} from '@schooly/style';
import React from 'react';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

type DynamicVariableOptions = {
  titleId: string;
  value: string;
};

type DynamicVariablesProps = {
  options: DynamicVariableOptions[];
};

const COPY_DELAY = 1000;

export const DynamicVariables: FC<DynamicVariablesProps> = ({ options }) => {
  const [copied, setCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const { $t } = useIntl();

  return (
    <div>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Box>
          <DynamicVariableIcon />
        </Box>
      </IconButton>

      <MenuItemArrow anchorEl={anchorEl} open={open}>
        <Paper />
      </MenuItemArrow>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            transform: 'translateY(12px)',
          },
        }}
        sx={{
          '& .MuiList-root': {
            minWidth: 400,
            px: 0,
            py: 1.25,
          },
        }}
      >
        <Typography color="primary.main" p={1.25} pb={1.75}>
          {$t({ id: 'consentForms-dynamicVariables' })}
        </Typography>
        {options.map(({ titleId, value }, i, arr) => (
          <React.Fragment key={value}>
            <MenuItem
              onClick={async () => {
                setCopied(true);
                await navigator.clipboard.writeText(value);
                setAnchorEl(null);
                setTimeout(() => {
                  setCopied(false);
                }, COPY_DELAY);
              }}
              sx={{
                margin: 0,
                py: 1,
                width: '100%',
                borderRadius: 0,
                ':hover': {
                  '.copyIcon': {
                    visibility: 'visible',
                  },
                  '.titleText': {
                    color: 'common.grey2',
                  },
                },
              }}
            >
              <Stack direction="row" justifyContent="space-between" gap={3} width="100%">
                <TypographyWithOverflowHint variant="h3" width={160}>
                  {value}
                </TypographyWithOverflowHint>
                <TypographyWithOverflowHint
                  className="titleText"
                  color="common.grey"
                  variant="h3"
                  width={146}
                >
                  {$t({ id: titleId })}
                </TypographyWithOverflowHint>

                <Icon
                  className="copyIcon"
                  sx={{
                    alignSelf: 'flex-end',
                    visibility: 'hidden',
                  }}
                >
                  {copied ? <CheckIcon /> : <CopyIcon />}
                </Icon>
              </Stack>
            </MenuItem>
            {Boolean(arr.length - 1 !== i) && <Divider sx={{ ml: 1.25, my: '0 !important' }} />}
          </React.Fragment>
        ))}
      </Menu>
    </div>
  );
};
