import { StringOrNull } from '@schooly/api';
import { CreateParentForm } from '@schooly/components/applications';
import { useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import { Loading } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useApplication } from 'apps/web/src/context/applications/useApplication';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { WithFilters } from 'apps/web/src/context/filters/WithFilters';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getRouteModalPathname } from 'apps/web/src/helpers/misc';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ModalLarge } from '../../../components/uikit-components/Modal/Modal.styled';
import { ApplicationRouteState } from '../../../context/applications/ApplicationsContext';
import { useApplicationForm } from '../useApplicationForm';
import { AddParentModal } from './AddParentModal/AddParentModal';
import { ApplicationCreateModalContent } from './ApplicationCreateModalContent';

export const ApplicationUpdateModal: React.FC = () => {
  const { application, fetching, updateApplication, applicationId } = useApplication();

  const {
    children,
    parents,
    generalCustomFields,
    addParents,
    removeParent,
    removeChild,
    resetForm,
    prepareFormData,
  } = useApplicationForm('update');

  const navigate = useNavigate();
  const [isAddParentModalOpen, showAddParentModal, hideAddParentModal] = useFlag();

  const { showNotification } = useNotifications();
  const { $t } = useIntl();

  const onChildClick = useCallback(
    (id?: string) => {
      if (id) {
        navigate(`child/${id}`);
      } else {
        navigate('child');
      }
    },
    [navigate],
  );

  const onParentEdit = useCallback(
    ({ profileId, id }: { profileId: StringOrNull; id: string }) => {
      if (profileId) {
        const state: ApplicationRouteState = {
          type: 'application',
          actionType: 'update',
          parents,
        };
        navigate(getRouteModalPathname('adult', { id: profileId }), {
          state,
        });
      } else {
        navigate(`parent/${id}`);
      }
    },
    [navigate, parents],
  );

  const addedParentIds = useMemo(() => {
    return parents.reduce<string[]>((acc, p) => (p.profile_id ? [...acc, p.profile_id] : acc), []);
  }, [parents]);

  const setExistedParents = useCallback(
    (selectedParents: CreateParentForm[]) => {
      const removedParents = addedParentIds.reduce<string[]>((acc, profileId) => {
        const toRemoveId = !selectedParents.some((parent) => profileId === parent.profile_id);
        if (!toRemoveId) return acc;
        const parentId = parents.find((p) => p.profile_id === profileId)?.id;
        return parentId ? [...acc, parentId] : acc;
      }, []);

      if (!!removedParents.length) {
        removedParents.forEach(removeParent);
      }

      addParents(selectedParents);
    },
    [addParents, addedParentIds, parents, removeParent],
  );

  const onContinue = useCallback(() => {
    navigate('general');
  }, [navigate]);

  const onSubmit = useCallback(async () => {
    await updateApplication({ children, parents, generalCustomFields });
    navigate('/applications');

    if (applicationId) {
      navigate('/applications');
      showNotification({
        message: $t({ id: 'applications-SuccessfulUpdate' }),
        type: 'success',
        actions: [
          {
            textId: 'applications-Action-View',
            handler: () =>
              navigate(`/applications/${applicationId}`, {
                replace: true,
              }),
            buttonColor: 'light',
          },
        ],
      });
    }
    resetForm();
  }, [
    $t,
    applicationId,
    children,
    navigate,
    parents,
    resetForm,
    showNotification,
    updateApplication,
    generalCustomFields,
  ]);

  useEffect(() => {
    if (!application || fetching) {
      return;
    }

    if (!children.length && !parents.length) {
      prepareFormData(application);
    }
  }, [application, children, fetching, parents, prepareFormData]);

  const onOpenPreview = useCallback(() => {
    navigate('preview');
  }, [navigate]);

  return (
    <>
      <ModalLarge open>
        {fetching || !children || !parents ? (
          <Loading />
        ) : (
          <ApplicationCreateModalContent
            children={children}
            parents={parents}
            generalCustomFields={generalCustomFields}
            onChildClick={onChildClick}
            onChildRemove={removeChild}
            onSubmit={onSubmit}
            onOpenPreview={onOpenPreview}
            onContinue={onContinue}
            resetApplication={resetForm}
            onParentRemove={removeParent}
            parentActions={[
              { titleTextId: 'applications-AddExisting', handler: showAddParentModal },
              {
                titleTextId: 'applications-CreateNew',
                handler: () => navigate('parent'),
              },
            ]}
            onParentEdit={onParentEdit}
            updateParents={addParents}
          />
        )}
        {isAddParentModalOpen && (
          <WithFilters type="parents">
            <AddParentModal
              onClose={hideAddParentModal}
              addedApplicationParents={parents}
              setApplicationParents={setExistedParents}
            />
          </WithFilters>
        )}
      </ModalLarge>
    </>
  );
};
