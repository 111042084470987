import { CollapsePanel, CollapsePanelProps } from '@schooly/style';

import { useCollapsiblePanel } from '../../context/collapsiblePanel/useCollapsiblePanel';

export const CollapsiblePanel = ({
  children,
  ...props
}: Omit<CollapsePanelProps, 'state' | 'handleAction'>) => {
  const { state, dispatch } = useCollapsiblePanel();

  return (
    <CollapsePanel handleAction={dispatch} state={state} {...props}>
      {children}
    </CollapsePanel>
  );
};
