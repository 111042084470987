import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.1998 3.2002H5.5998V4.4002H5.1998C3.43249 4.4002 1.9998 5.83288 1.9998 7.6002C1.9998 9.36751 3.43249 10.8002 5.1998 10.8002H5.5998V12.0002H5.1998C2.76975 12.0002 0.799805 10.0302 0.799805 7.6002C0.799805 5.17014 2.76975 3.2002 5.1998 3.2002ZM13.9998 7.6002C13.9998 9.36751 12.5671 10.8002 10.7998 10.8002H10.3998V12.0002H10.7998C13.2299 12.0002 15.1998 10.0302 15.1998 7.6002C15.1998 5.17014 13.2299 3.2002 10.7998 3.2002H10.3998V4.4002H10.7998C12.5671 4.4002 13.9998 5.83288 13.9998 7.6002ZM4.7998 7.6002C4.7998 7.26882 5.06843 7.0002 5.3998 7.0002H10.5998C10.9312 7.0002 11.1998 7.26882 11.1998 7.6002C11.1998 7.93157 10.9312 8.2002 10.5998 8.2002H5.3998C5.06843 8.2002 4.7998 7.93157 4.7998 7.6002Z"
      fill="#24275B"
    />
  </svg>
);
