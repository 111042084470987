import React, { PropsWithChildren } from 'react';

import { useDivOverflow } from '../../../hooks/useDivOverflow';
import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';
import Card from '../../ui/Card';

import './LongNameWithVerticalTooltip.scss';

const $class = `${$UIKitPrefix}LongNameWithVerticalTooltip`;

interface LongNameWithVerticalTooltipProps extends PropsWithChildren {
  className?: string;
}

export const LongNameWithVerticalTooltip: React.FC<LongNameWithVerticalTooltipProps> = ({
  children,
  className,
}) => {
  const { ref, isTooltip } = useDivOverflow(false);

  return (
    <Card
      ref={ref}
      className={buildClassName(
        $class,
        isTooltip && `${$class}_tooltip`,
        `${$class}_tooltip__vertical`,
        className,
      )}
    >
      <div className={`${$class}__info`}>
        <div className={`${$class}__info__content`}>
          <p className={buildClassName(`${$class}__info__title`)}>{children}</p>
        </div>
      </div>
    </Card>
  );
};
