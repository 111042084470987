import { FC } from 'react';

import { GroupAttendanceStatisticsMode } from '../../../context/groups/GroupAttendanceStatisticsContext';
import { useGroupAttendanceStatistics } from '../../../context/groups/useGroupAttendanceStatistics';
import { GroupAttendanceStatisticsModalListRegisters } from './GroupAttendanceStatisticsModalListRegisters';
import { GroupAttendanceStatisticsModalListStudents } from './GroupAttendanceStatisticsModalListStudents';

export const GroupAttendanceStatisticsModalList: FC = () => {
  const { mode } = useGroupAttendanceStatistics();

  return mode === GroupAttendanceStatisticsMode.REGISTERS ? (
    <GroupAttendanceStatisticsModalListRegisters />
  ) : (
    <GroupAttendanceStatisticsModalListStudents />
  );
};
