import { Button, Stack } from '@mui/material';
import {
  FilterElementType,
  FilterKeys,
  GetStudentsQueryFilters,
  GetStudentsQuerySort,
  StudentsArrangeBy,
  StudentSearchResult,
  useGetStudentsQuery,
  useGroupAvailableCriteriaCountQuery,
} from '@schooly/api';
import {
  ArrangedByCollapsableSection,
  ArrangedByCollapsableSectionSkeleton,
  getArrangeBySectionsFromAvailableCriteria,
  getCombinedRowsFromSearchResults,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { PROPERTIES_TEXT_IDS, SchoolUserRole } from '@schooly/constants';
import { EyeIcon, GridBody, MainPageGrid, SkeletonRows } from '@schooly/style';
import { SelectedIds, useSelectSections } from '@schooly/utils/bulk-actions';
import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StudentsSectionsBulkActions } from './StudentsBulkActions';
import { StudentRow, StudentsHeader } from './StudentsGrid';

type StudentsArrangedByListProps = {
  schoolHasHouses: boolean;
  schoolId: string;
  arrangeBy: StudentsArrangeBy;
  filters: GetStudentsQueryFilters;
  showZeroCounts?: boolean;
  sort?: GetStudentsQuerySort;
  query?: string;
  onChangeSort: (v: GetStudentsQuerySort) => void;
  onSetTotalCount: (v?: number) => void;
};
export const StudentsArrangedByList: FC<StudentsArrangedByListProps> = ({
  schoolId,
  schoolHasHouses,
  arrangeBy,
  filters,
  showZeroCounts = true,
  sort,
  query,
  onChangeSort,
  onSetTotalCount,
}) => {
  const { $t } = useIntl();
  const { toggleSelectSection, clearSelectedSections, selectedSections, toggleSelectIdInSection } =
    useSelectSections<StudentsArrangeBy>();
  const { data, isLoading } = useGroupAvailableCriteriaCountQuery(
    {
      schoolId,
      relationRole: SchoolUserRole.Student,
      fromDate: filters.date?.[0]!,
      schoolPropertyIds: [
        ...(filters.age_group || []),
        ...(filters.status || []),
        ...(filters.house || []),
      ],
      showNoneCounts: true,
      nationalities: filters.nationality || [],
      genders: filters.gender || [],
      type: arrangeBy === FilterKeys.TutorGroup ? FilterElementType.TutorGroup : undefined,
      searchQuery: query,
    },
    { refetchOnMount: true, enabled: !!filters.date?.[0] },
  );

  useEffect(() => {
    onSetTotalCount(data?.total_count);
  }, [onSetTotalCount, data?.total_count, arrangeBy]);

  useEffect(clearSelectedSections, [filters, sort, arrangeBy, clearSelectedSections]);

  const sections = useMemo(() => {
    if (!data) return [];

    const arrangeBySections = getArrangeBySectionsFromAvailableCriteria({
      criteria: data.available_criteria,
      arrangeByKey: arrangeBy,
      allowZeroCounts: showZeroCounts,
    });

    const allSectionsDiff =
      data.total_count - arrangeBySections.reduce<number>((acc, s) => acc + s.count, 0);

    if (arrangeBy !== FilterKeys.TutorGroup || allSectionsDiff <= 0) {
      return arrangeBySections;
    }

    return [
      ...arrangeBySections,
      // "No tutor group" section
      {
        title: '',
        isNoneSection: true,
        arrangeByKey: arrangeBy,
        arrangeByValue: undefined,
        count: allSectionsDiff,
      },
    ];
  }, [data, arrangeBy, showZeroCounts]);

  if (isLoading) return <ArrangedByCollapsableSectionSkeleton />;

  if (!sections.length) return <MainGridNoResultsStub textId="students-NoResults-title" />;

  return (
    <>
      {sections.map((section) => {
        const selectedSection = selectedSections.get(section.title);

        return (
          <ArrangedByCollapsableSection
            key={section.arrangeByKey + section.arrangeByValue}
            isExpanded={sections.length === 1}
            count={section.count}
            title={
              section.isNoneSection
                ? `${$t({ id: 'without' })}  ${$t({
                    id: PROPERTIES_TEXT_IDS[section.arrangeByKey],
                  })}`
                : section.title
            }
          >
            <ArrangedByStudentsGrid
              schoolHasHouses={schoolHasHouses}
              count={section.count}
              sort={sort}
              filters={filters}
              arrangeByKey={section.arrangeByKey}
              arrangeByValue={!section.isNoneSection ? section.arrangeByValue : undefined}
              schoolId={schoolId}
              onChangeSort={onChangeSort}
              query={query}
              selectedIds={selectedSection?.selectedIds}
              isSelectedAll={!!(selectedSection?.selectedIds === 'all')}
              onToggleSelectAll={() => toggleSelectSection(section)}
              onToggleSelectId={(id) => toggleSelectIdInSection(section, id)}
            />
          </ArrangedByCollapsableSection>
        );
      })}
      <StudentsSectionsBulkActions
        filters={filters}
        schoolId={schoolId}
        onClear={clearSelectedSections}
        selectedSections={selectedSections}
      />
    </>
  );
};

type ArrangedByStudentsGridProps = {
  count: number;
  filters: GetStudentsQueryFilters;
  arrangeByKey?: StudentsArrangeBy;
  arrangeByValue?: string | number;
  schoolId: string;
  sort?: GetStudentsQuerySort;
  isSelectedAll: boolean;
  selectedIds?: SelectedIds;
  query?: string;
  schoolHasHouses: boolean;
  onChangeSort: (v: GetStudentsQuerySort) => void;
  onToggleSelectId: (v: string) => void;
  onToggleSelectAll: () => void;
};

const PAGE_SIZE = 30;
const HEADER_OFFSET = 50;

export const ArrangedByStudentsGrid: FC<ArrangedByStudentsGridProps> = ({
  count,
  sort,
  filters: initialFilters,
  schoolId,
  arrangeByKey,
  arrangeByValue,
  query,
  onChangeSort,
  schoolHasHouses,
  selectedIds,
  isSelectedAll,
  onToggleSelectId,
  onToggleSelectAll,
}) => {
  const filters = useMemo(
    () =>
      arrangeByKey
        ? {
            ...initialFilters,
            [arrangeByKey]: [arrangeByValue === undefined ? 'none' : arrangeByValue],
          }
        : initialFilters,
    [initialFilters, arrangeByKey, arrangeByValue],
  );
  const { data, isLoading, params, setParams, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetStudentsQuery(
      {
        schoolId,
        pageSize: PAGE_SIZE,
        sort,
        filters,
        query,
      },
      { refetchOnMount: 'always' },
    );

  useEffect(() => {
    setParams((params) => ({ ...params, filters, sort, query }));
  }, [sort, filters, query, setParams]);

  const entries = useMemo(
    () =>
      getCombinedRowsFromSearchResults(
        data?.pages.reduce(
          (prev, curr) => [...prev, ...curr.results],
          [] as StudentSearchResult[],
        ) ?? [],
      ),
    [data?.pages],
  );

  return (
    <MainPageGrid
      bottomElement={
        hasNextPage && !isFetchingNextPage ? (
          <Stack mt={1} alignItems="center">
            <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
              <FormattedMessage id="action-ShowMoreButton" />
            </Button>
          </Stack>
        ) : undefined
      }
    >
      <StudentsHeader
        sort={params.sort}
        schoolHasHouses={schoolHasHouses}
        onChangeSort={onChangeSort}
        sx={{
          position: 'sticky',
          bgcolor: 'white',
          zIndex: 3,
          top: HEADER_OFFSET,
        }}
        onSelectAll={!!entries.length ? onToggleSelectAll : undefined}
        isSelectedAll={selectedIds === 'all' || isSelectedAll}
      />
      <GridBody>
        {entries?.map((entry) => (
          <StudentRow
            schoolId={schoolId}
            schoolHasHouses={schoolHasHouses}
            combinedStudentRow={entry}
            key={entry.id}
            onSelect={onToggleSelectId}
            isSelected={
              isSelectedAll ||
              (selectedIds ? selectedIds === 'all' || selectedIds.has(entry.id) : false)
            }
          />
        ))}
        {(isLoading || isFetchingNextPage) && (
          <SkeletonRows
            columnsCount={8}
            amount={Math.min(PAGE_SIZE, count - (entries?.length || 0))}
          />
        )}
      </GridBody>
    </MainPageGrid>
  );
};
