import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import {
  CreateSignUpRequest,
  GetSignUpRespondentsRequest,
  GetSignUpsListQueryFilters,
  GetSignUpsListRequest,
  GetSignUpsListSort,
  SignUpActionRequest,
  SignUpActionResponse,
  SignUpRespondent,
  UpdateSignUpRequest,
} from './apiTypes/endpoints/signups';
import { ApiError, PagedResponse } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { SignUp } from './apiTypes/signups';
import { UserFilter } from './apiTypes/users';
import { getEventsBaseQueryParams } from './events';
import * as api from './requests';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

const DEFAULT_PAGE_SIZE = 50;
const SIGN_UPS_URL = '/events/sign-ups';

export const GET_SIGN_UP_QUERY = `${SIGN_UPS_URL}/GET_SIGN_UP_QUERY`;

export const getSignUp = (id: SignUp['id']): Promise<SignUp> => {
  return api.get(`${SIGN_UPS_URL}/${id}`);
};

export const useGetSignUpQuery = (id: SignUp['id'], options?: RQUseQueryOptions<SignUp>) => {
  return useQuery<SignUp, ApiError>([GET_SIGN_UP_QUERY, id], () => getSignUp(id), {
    ...options,
  });
};

export const getSignUpsBaseQueryParams = (filters: Partial<UserFilter>) => {
  return removeObjectUndefinedNullValues({
    date_from: filters?.date?.[0],
    date_to: filters?.date?.[1],
    sign_up_status: filters?.sign_up_status?.join(','),
    sign_up_type: filters?.sign_up_type?.join(','),
    invitee_type: filters?.invite_type?.join(','),
  });
};

export const GET_SIGN_UPS_LIST_QUERY = `${SIGN_UPS_URL}/GET_SIGN_UPS_LIST_QUERY`;

export const getSignUpsList = ({
  schoolId,
  query,
  filters,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  token,
}: GetSignUpsListRequest): Promise<PagedResponse<SignUp>> => {
  const baseQueryParams = filters ? getSignUpsBaseQueryParams(filters) : {};

  return api.get(`${SIGN_UPS_URL}/for-school/${schoolId}`, {
    params: {
      ...baseQueryParams,
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
    },
    cancelToken: token,
  });
};

export const useGetSignUpsListQuery = (
  initialParams: Omit<GetSignUpsListRequest, 'filters' | 'sort'> & {
    filters: GetSignUpsListQueryFilters;
    sort?: GetSignUpsListSort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<SignUp>>,
) => {
  const [params, setParams] = useState(initialParams);

  const filters = removeObjectEmptyArrayValues(params.filters ?? {});

  const query = useInfiniteQuery<PagedResponse<SignUp>, ApiError>(
    [GET_SIGN_UPS_LIST_QUERY, { ...params, filters }],
    ({ pageParam }) =>
      getSignUpsList({
        pageNumber: pageParam,
        ...params,
        filters,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const createSignUp = ({
  schoolId,
  ...params
}: CreateSignUpRequest): Promise<{ success: string; id: string }> => {
  return api.post(`${SIGN_UPS_URL}/for-school/${schoolId}`, { ...params });
};

export const useCreateSignUpMutation = (
  options?: RQUseMutationOptions<{ success: string; id: string }, CreateSignUpRequest>,
): RQUseMutationResult<{ success: string; id: string }, CreateSignUpRequest> => {
  return useMutation((params: CreateSignUpRequest) => createSignUp(params), {
    ...options,
  });
};

export const updateSignUp = ({
  id,
  ...params
}: UpdateSignUpRequest): Promise<{ success: string; id: string }> => {
  return api.patch(`${SIGN_UPS_URL}/${id}`, { ...params });
};

export const useUpdateSignUpMutation = (
  options?: RQUseMutationOptions<{ success: string; id: string }, UpdateSignUpRequest>,
): RQUseMutationResult<{ success: string; id: string }, UpdateSignUpRequest> => {
  return useMutation((params: UpdateSignUpRequest) => updateSignUp(params), {
    ...options,
  });
};

export const removeSignUp = (id: SignUp['id']): Promise<{ success: string; id: string }> => {
  return api.remove(`${SIGN_UPS_URL}/${id}`);
};

export const useRemoveSignUpMutation = (
  options?: RQUseMutationOptions<{ success: string; id: string }, SignUp['id']>,
): RQUseMutationResult<{ success: string; id: string }, SignUp['id']> => {
  return useMutation((params: SignUp['id']) => removeSignUp(params), {
    ...options,
  });
};

export function signUpAction({
  schoolId,
  filters,
  query,
  ...rest
}: SignUpActionRequest): Promise<SignUpActionResponse> {
  const baseParams = filters ? getEventsBaseQueryParams(filters) : {};

  return api.post(`${SIGN_UPS_URL}/for-school/${schoolId}/action`, {
    ...baseParams,
    ...rest,
    search_query: query,
  });
}

export const useSignActionMutation = (
  options?: RQUseMutationOptions<SignUpActionResponse, SignUpActionRequest>,
): RQUseMutationResult<SignUpActionResponse, SignUpActionRequest> => {
  return useMutation((params: SignUpActionRequest) => signUpAction(params), {
    ...options,
  });
};

export const GET_SIGN_UP_RESPONDENTS_QUERY = `${SIGN_UPS_URL}/GET_SIGN_UP_RESPONDENTS_QUERY`;

export const getSignUpRespondents = ({
  id,
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  token,
}: GetSignUpRespondentsRequest): Promise<PagedResponse<SignUpRespondent>> => {
  return api.get(`${SIGN_UPS_URL}/${id}/respondents`, {
    params: {
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
    },
    cancelToken: token,
  });
};

export const useGetSignUpRespondentsQuery = (
  initialParams: GetSignUpRespondentsRequest,
  options?: RQUseInfiniteQueryOptions<PagedResponse<SignUpRespondent>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<SignUpRespondent>, ApiError>(
    [GET_SIGN_UP_RESPONDENTS_QUERY, params],
    ({ pageParam }) =>
      getSignUpRespondents({
        pageNumber: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const downloadSignUp = (signUpId: SignUp['id']): Promise<ArrayBuffer | undefined> => {
  return api.get(`export/sign-ups/respondents/${signUpId}`);
};

export const useDownloadSignUpMutation = (
  options?: RQUseMutationOptions<ArrayBuffer | undefined, SignUp['id']>,
): RQUseMutationResult<ArrayBuffer | undefined, SignUp['id']> => {
  return useMutation((params) => downloadSignUp(params), {
    ...options,
  });
};
