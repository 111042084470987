import { Button } from '@mui/material';
import { ModalContent, ModalFooter, ModalMain } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RolloverHeader } from './RolloverHeader';
import { RolloverInProcessNotification } from './RolloverNotification';

type RolloverInProgressContentProps = {
  onClose: () => void;
  isReEnrollmentEnabled: boolean;
};

export const RolloverInProgressContent: FC<RolloverInProgressContentProps> = ({
  onClose,
  isReEnrollmentEnabled,
}) => {
  const { $t } = useIntl();
  return (
    <>
      <RolloverHeader
        title={$t({ id: 'students-AnnualRollover-InProgress' })}
        onClose={onClose}
        sx={{
          border: 'none',
        }}
      />
      <ModalMain>
        <ModalContent
          active
          sx={{
            paddingTop: 0,
          }}
        >
          <RolloverInProcessNotification isReEnrollmentEnabled={isReEnrollmentEnabled} />
        </ModalContent>
      </ModalMain>

      <ModalFooter active>
        <Button onClick={onClose}>
          <FormattedMessage id="students-AnnualRollover-Success" />
        </Button>
      </ModalFooter>
    </>
  );
};
