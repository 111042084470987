import { Icon, Skeleton, Stack } from '@mui/material';
import {
  FilterSection,
  GetParentsQueryFilters,
  PARENT_QUERY_FILTER_KEYS,
  useGetFiltersQuery,
  useGetSchoolPropertiesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PageHeader, pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SkeletonRows,
} from '@schooly/style';
import React, { FC, useMemo } from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import useUserCounts from '../../hooks/useUserCounts';
import ParentsContent, { PAGE_SIZE, SKELETON_COLS } from './ParentsContent';
import { ParentsEmptyStub } from './ParentsEmptyStub';

const ParentsPage: FC = () => {
  const { userCounts } = useUserCounts();
  const { schoolId = '', relationId = '' } = useAuth();

  const { data } = useGetSchoolPropertiesQuery(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { refetchOnMount: 'always' },
  );

  const { ageGroups } = useAgeGroups(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { refetchOnMount: 'always' },
  );

  const { data: filters, isLoading } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Parent },
    { enabled: !!schoolId && !!relationId },
  );

  const initialFilters: GetParentsQueryFilters | undefined = useMemo(() => {
    const initialFilters: GetParentsQueryFilters = {};
    if (isLoading) return;

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      return pickOnlyParamsFromFilterKeys(PARENT_QUERY_FILTER_KEYS, defaultFilter.filter);
    }

    if (!data) return;
    for (const property of data.school_properties) {
      if (!property.student_default) continue;

      const type = property.type;

      switch (type) {
        case SchoolPropertyType.Status:
        case SchoolPropertyType.House:
          initialFilters[type] = [...(initialFilters[type] || []), property.id];
          break;
      }
    }

    for (const ageGroup of ageGroups) {
      if (!ageGroup.student_default) continue;

      initialFilters[SchoolPropertyType.AgeGroup] = [
        ...(initialFilters[SchoolPropertyType.AgeGroup] || []),
        ageGroup.id,
      ];
    }

    return initialFilters;
  }, [ageGroups, data, filters, isLoading]);

  const renderContent = () => {
    if (!initialFilters) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <PageHeader pageTitleTextId="section-Parents">
              <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
            </PageHeader>
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_COLS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    if (!userCounts?.parent) {
      return <ParentsEmptyStub />;
    }

    return <ParentsContent initialFilters={initialFilters} />;
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};

export default ParentsPage;
