import {
  GetGroupSubjectsProps,
  GetGroupSubjectsResponse,
  GroupSubject,
  RQUseQueryOptions,
  useGetGroupSubjectsQuery,
} from '@schooly/api';
import { useMemo } from 'react';

export const useSubjects = (
  params: GetGroupSubjectsProps,
  options?: RQUseQueryOptions<GetGroupSubjectsResponse>,
) => {
  const response = useGetGroupSubjectsQuery(params, options);

  const [subjects, activeSubjects, archivedSubjects] = useMemo(() => {
    const subjects = response.data?.subjects ?? [];

    return [
      subjects,
      ...subjects.reduce<[GroupSubject[], GroupSubject[]]>(
        (prev, subject) => {
          prev[subject.archived ? 1 : 0].push(subject);
          return prev;
        },
        [[], []],
      ),
    ];
  }, [response.data?.subjects]);

  return { ...response, subjects, activeSubjects, archivedSubjects };
};
