import { Box, Skeleton } from '@mui/material';
import { School } from '@schooly/api';
import React from 'react';

import { usePrivateImage } from '../../../../hooks/usePrivateImage';

export interface SchoolAvatarProps {
  school: School;
  lightSkeleton?: boolean;
}

export const SchoolAvatar: React.FC<SchoolAvatarProps> = ({ school, lightSkeleton }) => {
  const { key, isLoading, image } = usePrivateImage(school?.image);

  return isLoading ? (
    <Skeleton
      variant="circular"
      sx={{ background: (theme) => (lightSkeleton ? theme.palette.common.lightBg : undefined) }}
    />
  ) : (
    <Box
      key={key}
      className="avatar"
      sx={{
        width: '100%',
        height: '100%',
        background: `center / contain no-repeat url(${image}) #fff`,
      }}
    />
  );
};
