import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { Spin, TagSelectProperty, TagSelectPropertyProps } from '@schooly/style';
import { FC } from 'react';

export type PropertyTypeTagSelectProps = {
  userRole: SchoolUserRole;
  schoolId: string;
  id: string;
} & Omit<TagSelectPropertyProps, 'label'>;

export const PropertyTypeTagSelect: FC<PropertyTypeTagSelectProps> = ({
  id,
  schoolId,

  userRole,
  ...props
}) => {
  const { getPropertyById } = useSchoolProperties({
    schoolId,
    userType: userRole,
  });

  return (
    <TagSelectProperty
      userRole={userRole}
      property={getPropertyById(id)}
      defaultValue={<Spin />}
      {...props}
    />
  );
};
