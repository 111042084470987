import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7071 0.707109C10.3166 0.316584 9.68342 0.316584 9.29289 0.707108L6.20711 3.7929C5.81658 4.18342 5.81658 4.81658 6.20711 5.20711L9.29289 8.2929C9.68342 8.68342 10.3166 8.68342 10.7071 8.2929L13.7929 5.20711C14.1834 4.81658 14.1834 4.18342 13.7929 3.7929L10.7071 0.707109Z"
      fill="#24275B"
    />
    <path
      d="M10 10.4072L5.79648 6.20363L1.86833 10.1318C1.47781 10.5223 1.47781 11.1555 1.86833 11.546L9.29295 18.9706C9.68348 19.3611 10.3166 19.3611 10.7072 18.9706L18.1318 11.546C18.5223 11.1555 18.5223 10.5223 18.1318 10.1318L14.2036 6.20362L10 10.4072Z"
      fill="#24275B"
    />
  </svg>
);
