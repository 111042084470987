import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { Company } from './apiTypes/companies';
import {
  CheckCompanyUniqueParams,
  CheckCompanyUniqueResponse,
  CreateCompanyParams,
  DeleteCompanyParams,
  DeleteCompanyResponse,
  GetCompaniesParams,
  GetCompaniesSort,
  UpdateCompanyParams,
  UpdateCompanyResponse,
} from './apiTypes/endpoints/companies';
import { ApiError, PagedResponse, SORT_DIRECTION } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import * as api from './requests';
import { getSortParams } from './utils/getSortParam';

const DEFAULT_PAGE_SIZE = 30;
const COMPANIES_URL = '/invoices/companies';

export const getCompany = async (id: string): Promise<Company> => {
  return api.get(`${COMPANIES_URL}/${id}`);
};

export const GET_COMPANY_QUERY = `${COMPANIES_URL}/GET_COMPANY_QUERY`;

export const useGetCompanyQuery = (id: string, options?: RQUseQueryOptions<Company>) => {
  return useQuery<Company, ApiError>([GET_COMPANY_QUERY, id], () => getCompany(id), {
    ...options,
  });
};

export const getCompanies = async ({
  schoolId,
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  sort = [{ columnTextId: 'student_count', direction: SORT_DIRECTION.DESC }],
}: GetCompaniesParams): Promise<PagedResponse<Company>> => {
  return api.get(`${COMPANIES_URL}/for-school/${schoolId}`, {
    params: {
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: getSortParams(sort),
    },
  });
};

export const GET_COMPANIES_QUERY = `${COMPANIES_URL}/GET_COMPANIES_QUERY`;

export const useGetCompaniesListQuery = (
  initialParams: Omit<GetCompaniesParams, 'sort'> & {
    sort?: GetCompaniesSort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<Company>>,
) => {
  const [params, setParams] = useState(initialParams);
  const queryClient = useQueryClient();

  const query = useInfiniteQuery<PagedResponse<Company>, ApiError>(
    [GET_COMPANIES_QUERY, params],
    ({ pageParam }) =>
      getCompanies({
        pageNumber: pageParam,
        ...params,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      onSuccess: (data) => {
        for (const company of data.pages.map((p) => p.results).flat()) {
          queryClient.setQueryData([GET_COMPANY_QUERY, company.id], company);
        }
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const createCompany = ({ schoolId, ...params }: CreateCompanyParams): Promise<Company> => {
  return api.post(`${COMPANIES_URL}/for-school/${schoolId}`, params.company);
};

export const useCreateCompanyMutation = (
  options?: RQUseMutationOptions<Company, CreateCompanyParams>,
): RQUseMutationResult<Company, CreateCompanyParams> => {
  return useMutation(createCompany, options);
};

export const updateCompany = ({
  id,
  ...params
}: UpdateCompanyParams): Promise<UpdateCompanyResponse> => {
  return api.patch(`${COMPANIES_URL}/${id}`, params.company);
};

export const useUpdateCompanyMutation = (
  options?: RQUseMutationOptions<UpdateCompanyResponse, UpdateCompanyParams>,
): RQUseMutationResult<UpdateCompanyResponse, UpdateCompanyParams> => {
  return useMutation(updateCompany, options);
};

export const deleteCompany = ({ id }: DeleteCompanyParams): Promise<DeleteCompanyResponse> => {
  return api.remove(`${COMPANIES_URL}/${id}`);
};

export const useDeleteCompanyMutation = (
  options?: RQUseMutationOptions<DeleteCompanyResponse, DeleteCompanyParams>,
): RQUseMutationResult<DeleteCompanyResponse, DeleteCompanyParams> => {
  return useMutation(deleteCompany, options);
};

const checkCompanyUnique = (
  params: CheckCompanyUniqueParams,
): Promise<CheckCompanyUniqueResponse> => {
  return api.post(`${COMPANIES_URL}/for-school/${params.schoolId}/validate-company`, {
    name: params.name,
    tax_number: params.tax_number,
  });
};

export const useCheckCompanyUniqueMutation = (
  options?: RQUseMutationOptions<CheckCompanyUniqueResponse, CheckCompanyUniqueParams>,
): RQUseMutationResult<CheckCompanyUniqueResponse, CheckCompanyUniqueParams> => {
  return useMutation(checkCompanyUnique, {
    ...options,
  });
};
