import { CrossIcon } from '@schooly/style';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import buildClassName from '../../../../utils/buildClassName';
import { renderError, renderLabel } from '../helpers';
import CountryCodeSelect from '../PhoneNumberInput/CountryCodeSelect';
import { useFormInput } from '../utils';

interface PhoneNumber {
  id: string;
  name: string;
  labelTextId: string;
  className?: string;
  disabled?: boolean;
  button?: React.ReactNode;
}

const FormPhoneNumberInput = ({
  id,
  name,
  labelTextId,
  className,
  disabled,
  button,
}: PhoneNumber) => {
  const {
    value: valueCountry,
    error: errorCountry,
    control,
  } = useFormInput({ name: `${name}.country` });

  const { value, isTouched, error, inputRef } = useFormInput({ name: `${name}.phoneNumber` });

  const { setValue } = useFormContext();

  const handleClearPhoneNumber = useCallback(() => {
    setValue(`${name}.phoneNumber`, '');
  }, [setValue, name]);

  const additionalFullClassName = buildClassName(
    'form-group form-group_remove',
    disabled && 'disabled',
    isTouched && 'touched',
    error && 'error',
    className,
  );

  return (
    <div className="OtherPhoneNumber__row">
      <div className="PhoneNumberInput">
        <Controller
          control={control}
          name={`${name}.country`}
          defaultValue={valueCountry || null}
          render={({ name: nameCountry, onChange, value: controllerValue }) => (
            <CountryCodeSelect
              name={`${nameCountry}.country`}
              labelTextId="AreaCode"
              country={controllerValue}
              disabled={disabled}
              onChange={(country) => onChange(country)}
              error={errorCountry}
              noRequiredLabel
            />
          )}
        />
        <label className={additionalFullClassName} htmlFor={id}>
          <input
            id={id}
            name={`${name}.phoneNumber`}
            className="form-control"
            type="text"
            inputMode="tel"
            ref={inputRef}
            disabled={disabled}
            defaultValue={value}
          />
          <span
            className="form-group__icon cursor-pointer"
            onClick={handleClearPhoneNumber}
            role="button"
            tabIndex={0}
          >
            <CrossIcon />
          </span>
          {renderLabel({
            id,
            disabled,
            className,
            labelTextId,
          })}
          {renderError(error)}
        </label>
      </div>
      {button}
    </div>
  );
};

export default FormPhoneNumberInput;
