import { Stack } from '@mui/material';
import { GroupType, RelationGroup } from '@schooly/api';
import { DATE_FORMAT_MOMENT as DATE_FORMAT } from '@schooly/constants';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { GroupCard } from '../../../components/common/GroupCard/GroupCard';
import SearchInput from '../../../components/ui/SearchInput';
import { ModalContent } from '../../../components/uikit-components/Modal/Modal.styled';
import { filterGroups } from '../../../helpers/misc';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { CriteriaGroupInfo } from './GroupCriteriaInfo';
import { useGroupDates } from './useGroupDates';

type ManageCriteriaGroupsProps = {
  criteriaGroups: RelationGroup[];
};

export const ManageCriteriaGroups: FC<ManageCriteriaGroupsProps> = ({ criteriaGroups }) => {
  const [searchString, setSearchString] = useState('');
  const { formatMessage } = useIntl();

  const { schoolYears } = useSchoolYears();
  const { getValidityDates } = useGroupDates({ schoolYears, dateFormat: DATE_FORMAT });

  const filteredCriteriaGroups = useMemo(
    () =>
      searchString
        ? criteriaGroups.filter((group) => filterGroups(group, searchString))
        : criteriaGroups,
    [criteriaGroups, searchString],
  );

  const getGroupValidityTitle = useCallback(
    (group: RelationGroup) => {
      const { from, to } = getValidityDates(group.validity);
      return `${from}${' '}-${' '}${to}`;
    },
    [getValidityDates],
  );

  return (
    <ModalContent active sx={{ pt: 0 }}>
      <SearchInput
        value={searchString}
        onChange={setSearchString}
        isValueRemovable
        placeholder={formatMessage(
          { id: 'groups-SearchAmongCount' },
          {
            GroupPlural: `${criteriaGroups.length} ${formatMessage({
              id: 'assessments-Groups',
            }).toLowerCase()}`,
          },
        )}
      />

      <Stack gap={1}>
        {filteredCriteriaGroups?.map((group) => (
          <GroupCard
            key={group.id}
            title={group.name}
            date={getGroupValidityTitle(group)}
            subjectName={
              group.group_type === GroupType.TutorGroup
                ? formatMessage({ id: 'groups-TutorGroup' })
                : group.subject?.name
            }
            subjectArchived={group.subject?.archived}
            rowItemNode={<CriteriaGroupInfo membership={group.memberships} />}
            id={group.id}
          />
        ))}
      </Stack>
    </ModalContent>
  );
};
