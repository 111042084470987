import { ModalLarge } from '@schooly/style';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApplicationPreviewContainer } from './ApplicationPreviewContainer';

export const ApplicationPreviewModal: FC = () => {
  const navigate = useNavigate();

  return (
    <ModalLarge open>
      <ApplicationPreviewContainer
        onEditClick={() => {
          navigate('update');
        }}
      />
    </ModalLarge>
  );
};
