import { useSetCustomFieldValueForRelationMutation } from '@schooly/api';
import { ApiError, CustomField, CustomFieldValue } from '@schooly/api';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import React, { FC, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form-lts';

import { useProfile } from '../../../../context/profile/useProfile';
import IntlError from '../../../../utils/intlError';
import { PropertyModal2 } from '../PropertyModal2';

export interface CustomFieldStringModalProps {
  onClose: () => void;
  isOpen: boolean;
  field: CustomField;
  value?: CustomFieldValue;
}

interface CustomFieldFormNumberData {
  value?: number;
}

export const CustomFieldNumberModal: FC<CustomFieldStringModalProps> = ({
  isOpen,
  onClose,
  field,
  value,
}) => {
  const { showError } = useNotifications();

  const { user, schoolMembership } = useProfile();
  const setCustomFieldValueForRelation = useSetCustomFieldValueForRelationMutation();

  const numberValue = value?.value as number;

  const form = useForm<CustomFieldFormNumberData>({
    mode: 'onChange',
    defaultValues: {
      value: numberValue,
    },
  });

  const {
    reset,
    formState: { isValid },
  } = form;

  const handleSubmit: SubmitHandler<CustomFieldFormNumberData> = useCallback(
    async ({ value: newValue }) => {
      if (!schoolMembership) {
        return;
      }

      try {
        const res = await setCustomFieldValueForRelation.mutateAsync({
          relationId: schoolMembership.relation_id,
          field,
          value: newValue == null || (newValue as unknown as boolean) === true ? null : newValue,
        });

        if (!res) return;
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        onClose();
      }
    },
    [field, onClose, schoolMembership, setCustomFieldValueForRelation, showError],
  );

  useEffect(() => {
    reset({ value: numberValue });
  }, [reset, numberValue, isOpen]);

  return (
    <PropertyModal2
      isOpen={isOpen}
      user={user}
      submitDisabled={!isValid}
      isUpdating={setCustomFieldValueForRelation.isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <ControlTextField
        name="value"
        type="number"
        fullWidth
        label={field.label}
        rules={{ required: field.required }}
      />
    </PropertyModal2>
  );
};
