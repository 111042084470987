export function setCookie(name: string, value = '', days?: number): void {
  let expires = '';

  if (days) {
    const date = new Date();

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/`;
}

export function getCookie(name: string): string | null {
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => cookie.substring(0, name.length + 1) === `${name}=`)
      .map((cookie) => decodeURIComponent(cookie.substring(name.length + 1)))[0] || null
  );
}
