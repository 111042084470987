import { IconButton, styled } from '@mui/material';
import { DeleteIcon } from '@schooly/style';
import * as React from 'react';

// TODO: may be need to make common for other modals
export const DeleteMethodButton = styled(IconButton)(({ theme }) => ({
  paddingRight: theme.spacing(1.5),
  marginTop: theme.spacing(1.5),
  color: theme.palette.text.secondary,

  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

DeleteMethodButton.defaultProps = {
  children: <DeleteIcon />,
};
