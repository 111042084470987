import { Icon, Stack, Typography } from '@mui/material';
import { CheckIcon, Spin } from '@schooly/style';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

type SchoolCreateSavingProps = {
  handleSchoolCreated?: () => void;
};

const ITEMS = [
  'schoolYears',
  'schoolProperty-AgeGroup-plural',
  'schoolProperty-Status-plural',
  'school-tabs-Access',
  'groups-GroupSubject-plural',
  'schoolProperty-Department-plural',
];

const DELAY = 1500;

export const SchoolCreateSaving: FC<SchoolCreateSavingProps> = ({ handleSchoolCreated }) => {
  const { $t } = useIntl();
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveItemIndex((index) => {
        if (index === ITEMS.length) return index;

        return index + 1;
      });
    }, DELAY);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (activeItemIndex < ITEMS.length || !handleSchoolCreated) return;
    handleSchoolCreated();
  }, [activeItemIndex, handleSchoolCreated]);

  return (
    <Stack flex={1} justifyContent="center" alignItems="center">
      <Stack flexDirection="row" gap={2.5} mt={(ITEMS.length / 2) * 10}>
        <Typography variant="h1" sx={(theme) => ({ lineHeight: theme.spacing(9) })}>
          {$t({ id: 'school-create-WeAreConfiguring' })}
        </Typography>
        <Stack
          sx={(theme) => ({
            gap: 1.25,
            transition: 'all .3s',
            transform: `translateY(-${theme.spacing(
              Math.min(10.5 * activeItemIndex, 10.5 * (ITEMS.length - 1)),
            )})`,
          })}
        >
          {ITEMS.map((labelTextId, i) => (
            <LoadingItem
              status={
                i < activeItemIndex ? 'completed' : i === activeItemIndex ? 'active' : 'inactive'
              }
              labelTextId={labelTextId}
              key={labelTextId}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

type LoadingItemProps = {
  status: 'inactive' | 'active' | 'completed';
  labelTextId: string;
};
export const LoadingItem: FC<LoadingItemProps> = ({ status, labelTextId }) => {
  const { $t } = useIntl();
  return (
    <Stack flexDirection="row">
      <Stack
        sx={(theme) => ({
          backgroundColor: status === 'inactive' ? undefined : 'white',
          border:
            status === 'inactive'
              ? undefined
              : `1px solid ${
                  status === 'completed' ? theme.palette.success.main : theme.palette.common.grey
                }`,
          flexDirection: 'row',
          gap: 1,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: theme.spacing(1),
          padding: theme.spacing(0, 2.5),
          transition: 'all .3s',
          '.svg-icon': {
            color: theme.palette.success.main,
            transform: 'scale(1.35)',
          },
        })}
      >
        <Typography
          variant="h1"
          sx={(theme) => ({
            transition: 'all .3s',
            lineHeight: theme.spacing(9),
            color:
              status === 'completed'
                ? 'success.main'
                : status === 'active'
                ? undefined
                : theme.palette.common.grey,
          })}
        >
          {$t({ id: labelTextId })}
        </Typography>
        {status === 'completed' ? (
          <Icon>
            <CheckIcon />
          </Icon>
        ) : (
          status === 'active' && <Spin size={1.75} />
        )}
      </Stack>
    </Stack>
  );
};
