import { useMutation, useQuery } from '@tanstack/react-query';

import { changeNotificationAttendanceSettingsProps } from './apiTypes/endpoints/notificationSettings';
import { ApiError } from './apiTypes/misc';
import { NotificationSettings } from './apiTypes/notificationSettings';
import { RQUseMutationOptions, RQUseMutationResult, RQUseQueryOptions } from './apiTypes/query';
import * as api from './requests';

const SCHOOL_URL = '/school/';

function getNotificationAttendanceSettings({
  schoolId,
}: {
  schoolId: string;
}): Promise<NotificationSettings> {
  return api.get(`${SCHOOL_URL}${schoolId}/settings/notifications`);
}

export const GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY = `${SCHOOL_URL}/GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY`;

export const useGetNotificationAttendanceSettings = (
  params: {
    schoolId: string;
  },
  options?: RQUseQueryOptions<NotificationSettings>,
) => {
  return useQuery<NotificationSettings, ApiError>(
    [GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY, params],
    () => getNotificationAttendanceSettings(params),
    {
      ...options,
    },
  );
};

function changeNotificationAttendanceSettings({
  schoolId,
  enabled,
  notifications,
}: changeNotificationAttendanceSettingsProps): Promise<NotificationSettings> {
  const params = {
    attendance: {
      notifications,
      enabled,
    },
  };
  return api.patch(`${SCHOOL_URL}${schoolId}/settings/notifications`, params);
}

export const useChangeNotificationAttendanceSettingsMutation = (
  options?: RQUseMutationOptions<NotificationSettings, changeNotificationAttendanceSettingsProps>,
): RQUseMutationResult<NotificationSettings, changeNotificationAttendanceSettingsProps> => {
  return useMutation(
    (params: changeNotificationAttendanceSettingsProps) =>
      changeNotificationAttendanceSettings(params),
    options,
  );
};
