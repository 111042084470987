import { Box, Button, Typography } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import buildClassName from '../../utils/buildClassName';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const { $t } = useIntl();

  useLayoutEffect(() => {
    // hide if modal open
    setTimeout(() => {
      const modalRoot = document.querySelector('#modal-root');
      if (modalRoot) {
        setVisible(modalRoot.children.length <= 0);
      }
    }, 0);
  }, []);

  function handleClick() {
    navigate('/');
  }

  return (
    <main className={buildClassName('center-contents', 'hidden', visible && 'visible')}>
      <Box mb={4}>
        <picture>
          <source srcSet="/images/no-schools.png, /images/no-schools@2x.png 2x" />
          <img src="/images/no-schools.png" alt={$t({ id: 'notfound-message' })} />
        </picture>
      </Box>
      <Typography variant="h2" mb={6}>
        {$t({ id: 'notfound-message' })}
      </Typography>

      <Button onClick={handleClick}>{$t({ id: 'returnToHome' })}</Button>
    </main>
  );
};

export default NotFoundPage;
