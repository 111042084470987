import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7818 2.75014L12.4006 4.14476L15.4427 7.21636L16.8239 5.82174C17.504 5.13504 17.504 4.02167 16.8239 3.33496L16.2447 2.75014C15.5646 2.06343 14.462 2.06343 13.7818 2.75014Z"
      fill="#24275B"
    />
    <path
      d="M11.5 5L3.86032 12.7343L3.88168 12.7559L3.84721 12.7907L3.04522 15.3357C2.82042 16.0491 3.46998 16.7304 4.19328 16.5399L6.85429 15.839L6.89471 15.7981L6.9055 15.8091L14.5452 8.07475L11.5 5Z"
      fill="#24275B"
    />
  </svg>
);
