import { useNotifications } from '@schooly/components/notifications';
import { useEffect } from 'react';

import { useAppSelector } from '../redux/hooks';
import { IApiSlice } from '../redux/scheme';
import { IRootState } from '../redux/store';
import usePrevious from './usePrevious';

export const useApiSlice = <T extends IApiSlice>(selector: (state: IRootState) => T) => {
  const sliceData = useAppSelector(selector);
  const { showError } = useNotifications();
  const prevError = usePrevious(sliceData.error);

  useEffect(() => {
    if (sliceData.error && prevError !== sliceData.error) {
      showError(sliceData.error);
    }
  }, [prevError, showError, sliceData.error]);

  return sliceData;
};
