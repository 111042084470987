import { Typography } from '@mui/material';
import { Assessment } from '@schooly/api';
import { Loading } from '@schooly/style';
import React, { FC, useRef } from 'react';

import { ModalContent } from '../../../components/uikit-components/Modal/Modal.styled';
import { WithRef } from '../../../hooks/useWithRef';
import { AssessmentsPreviewEntriesTable } from './AssessmentsPreviewEntries/AssessmentsPreviewEntriesTable';

interface AssessmentsPreviewModalContentProps {
  isFetching: boolean;
  assessment: Assessment;
  groupId: string;
}
export const AssessmentsPreviewModalContent: FC<AssessmentsPreviewModalContentProps> = ({
  isFetching,
  assessment,
  groupId,
}) => {
  const groupName = assessment?.groups.find((item) => item.id === groupId)?.name ?? '';
  const containerRef = useRef<HTMLDivElement>(null);

  if (isFetching || !groupId) {
    return <Loading />;
  }

  return (
    <ModalContent ref={containerRef} sx={{ pb: 0 }}>
      <WithRef containerRef={containerRef}>
        <Typography variant="h2" mb={2.5}>
          {groupName}
        </Typography>
        <AssessmentsPreviewEntriesTable assessment={assessment} groupId={groupId} />
      </WithRef>
    </ModalContent>
  );
};
