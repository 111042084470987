import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { differenceInMinutes, isPast, parse } from 'date-fns';
import { FC, useMemo } from 'react';

import {
  CALENDAR_SLOT_SIZE,
  CalendarRecord,
  CalendarRecordTypes,
  ColumnRecordActive,
  ColumnRecordDraft,
  ColumnRecordSlot,
} from '../scheme';
import { CalendarWeekGridCellStyled, CalendarWeekGridColumnStyled } from './CalendarWeek.styled';
import { CalendarRecordCellActive } from './records/CalendarRecordCellActive';
import { CalendarRecordCellDraft } from './records/CalendarRecordCellDraft';
import { CalendarRecordCellSlot } from './records/CalendarRecordCellSlot';

export interface CalendarWeekColumnProps {
  weeksCount: number;
  records?: CalendarRecord[];
}

export const CalendarWeekColumn: FC<CalendarWeekColumnProps> = ({ weeksCount, records }) => {
  const [drafts, slots, active] = useMemo(() => {
    const drafts: Record<number, ColumnRecordDraft[]> = {}; // { startIndex: [ ColumnRecordSlot, ColumnRecordSlot ] } as might be several slots starting on the same date
    const slots: Record<number, ColumnRecordSlot[]> = {};
    const active: Record<number, ColumnRecordActive[]> = {};

    records?.forEach((record) => {
      const date =
        typeof record.date === 'string'
          ? parse(record.date, DEFAULT_DATE_FORMAT_FNS, new Date())
          : new Date(record.date);

      record.time.forEach((time) => {
        const start =
          typeof time[0] === 'string' ? parse(time[0], 'HH:mm', new Date(date)) : time[0];
        const end = typeof time[1] === 'string' ? parse(time[1], 'HH:mm', new Date(date)) : time[1];
        const startIndex = Math.floor(
          differenceInMinutes(start, new Date(start).setHours(0, 0, 0, 0)) / CALENDAR_SLOT_SIZE,
        );
        const endIndex = Math.floor(
          differenceInMinutes(end, new Date(end).setHours(0, 0, 0, 0)) / CALENDAR_SLOT_SIZE,
        );

        let target: typeof drafts | typeof slots | typeof active;

        switch (record.type) {
          case CalendarRecordTypes.Draft:
            target = drafts;
            break;
          case CalendarRecordTypes.Slot:
            target = slots;
            break;
          case CalendarRecordTypes.Active:
            target = active;
            break;
        }

        if (!target[startIndex]) {
          target[startIndex] = [];
        }

        (target[startIndex] as ColumnRecordDraft[]).push({
          start,
          end,
          size: endIndex - startIndex,
          isPast: isPast(end),
          record,
        } as ColumnRecordDraft);
      });
    });

    return [drafts, slots, active];
  }, [records]);

  return (
    <CalendarWeekGridColumnStyled weeksCount={weeksCount}>
      {new Array((60 / CALENDAR_SLOT_SIZE) * 24).fill(true).map((_, index) => (
        <CalendarWeekGridCellStyled key={index}>
          {drafts[index]?.map((item, slotIndex) => (
            <CalendarRecordCellDraft key={`draft-${index}-${slotIndex}`} {...item} />
          ))}
          {slots[index]?.map((item, slotIndex) => (
            <CalendarRecordCellSlot
              key={`slot-${index}-${slotIndex}`}
              hasActive={Boolean(active[index]?.length)}
              {...item}
            />
          ))}
          {active[index]?.map((item, slotIndex) => (
            <CalendarRecordCellActive key={`active-${index}-${slotIndex}`} {...item} />
          ))}
        </CalendarWeekGridCellStyled>
      ))}
    </CalendarWeekGridColumnStyled>
  );
};
