import { ChipProps } from '@mui/material';
import { Genders, getLabelIdForOption } from '@schooly/constants';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

export type GenderTagSelectProps = {
  gender: Genders;
} & Omit<ChipProps, 'label'>;

const OPTIONS = $enum(Genders).map((value, key) => ({ value, key }));

export const GenderTagSelect: FC<GenderTagSelectProps> = ({ gender, ...props }) => {
  const { formatMessage } = useIntl();

  const key = OPTIONS.find((o) => o.value === gender)?.key;
  const label = key
    ? formatMessage({ id: getLabelIdForOption({ optionsKey: 'gender', key }) })
    : undefined;

  return <TagSelect label={label} {...props} />;
};
