import { Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { useFlag } from '@schooly/hooks/use-flag';
import React, { ReactNode, useCallback, useState } from 'react';

import {
  RecurringConfirmForm,
  RecurringConfirmModal,
  RecurringConfirmOptions,
} from './RecurringConfirmModal';

export interface RecurringConfirmDialogConfig extends Omit<DialogProps, 'open'> {
  callback: (value: RecurringConfirmForm | null) => void;
  options: RecurringConfirmOptions[];
  warningTitle?: string;
  actionButtonTextId?: string;
  title: string;
  subtitle?: ReactNode;
}

interface RecurringConfirmDialogContextProps {
  openDialog: (config: RecurringConfirmDialogConfig) => void;
}

const defaultContext: RecurringConfirmDialogContextProps = {
  openDialog: () => {},
};

export const RecurringConfirmDialogContext = React.createContext(defaultContext);

RecurringConfirmDialogContext.displayName = 'RecurringConfirmDialogContext';

export const WithRecurringConfirmDialog = ({ children }: { children: React.ReactNode }) => {
  const [isDialogOpen, showDialog, hideDialog] = useFlag();
  const [dialogConfig, setDialogConfig] = useState<RecurringConfirmDialogConfig>();

  const openDialog = useCallback(
    ({
      callback,
      options,
      title,
      subtitle,
      warningTitle,
      actionButtonTextId,
    }: RecurringConfirmDialogConfig) => {
      showDialog();
      setDialogConfig({
        callback,
        title,
        subtitle,
        options,
        warningTitle,
        actionButtonTextId,
      });
    },
    [showDialog],
  );

  const resetDialog = () => {
    hideDialog();
    setDialogConfig(undefined);
  };

  const onConfirm = (d: RecurringConfirmForm) => {
    resetDialog();
    dialogConfig?.callback(d);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig?.callback(null);
  };

  const title = (
    <>
      <Typography variant="h2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {dialogConfig?.title}
      </Typography>
      {dialogConfig?.subtitle}
    </>
  );

  return (
    <RecurringConfirmDialogContext.Provider value={{ openDialog }}>
      {children}
      <RecurringConfirmModal
        isOpen={isDialogOpen}
        onClose={onDismiss}
        onSubmit={onConfirm}
        warningTitle={dialogConfig?.warningTitle}
        options={dialogConfig?.options ?? []}
        title={title}
        actionButtonTextId={dialogConfig?.actionButtonTextId}
      />
    </RecurringConfirmDialogContext.Provider>
  );
};
