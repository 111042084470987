import { Stack } from '@mui/material';
import { SimpleListResult } from '@schooly/api';
import React from 'react';
import { useIntl } from 'react-intl';

import { ModalContent } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { WithFilters } from '../../../../context/filters/WithFilters';
import { UserRoleLimitationsUpdate } from '../Context/useUserRoleModal';
import { AddStaffFilterSection } from './AddStaffFilterSection';

export interface AddStaffExternalSidebarProps {
  staff: SimpleListResult[];
  onStaffClick: (staff: SimpleListResult) => void;
  date?: string;
  limitedTo: UserRoleLimitationsUpdate;
}

export const AddStaffExternalSidebar: React.FC<AddStaffExternalSidebarProps> = ({
  staff,
  onStaffClick,
  date,
  limitedTo,
}) => {
  const { $t } = useIntl();

  return (
    <Stack sx={{ height: '100%' }}>
      <ModalHeader title={$t({ id: 'userType-staff-plural' })} active />
      <ModalContent active>
        <WithFilters type="sidebar-user-role-staff">
          <AddStaffFilterSection
            limitedTo={limitedTo}
            onStaffClick={onStaffClick}
            date={date}
            staff={staff}
          />
        </WithFilters>
      </ModalContent>
    </Stack>
  );
};
