import { Components, Theme } from '@mui/material';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.h3,
      padding: theme.spacing(1, 1.25),
      marginBottom: theme.spacing(0.75),
      color: theme.palette.text.secondary,
      borderRadius: theme.shape.borderRadius,

      transition: theme.transitions.create(['color', 'background-color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),

      '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,

        '&:hover': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.common.light2,
        },
      },

      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },

      '&:active': {
        color: theme.palette.text.primary,
      },

      '& a': {
        maxWidth: '100%',
        color: 'inherit',
      },

      '& .UIKit-LongNameWithVerticalTooltip__info': {
        padding: 0,
        border: 0,
      },
    }),
  },
};
