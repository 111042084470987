import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2C16.1046 2 17 2.89543 17 4V5H14V2H15ZM14 6H17V14.0045C16.959 14.0015 16.9178 14 16.8766 14H15.1415C14.6382 14 14.1443 14.2238 13.8206 14.6353L12.3443 16.5122C12.0044 16.9444 11.916 17.5023 12.0799 18H5C3.89543 18 3 17.1046 3 16V4C3 2.89543 3.89543 2 5 2H13V5C13 5.55228 13.4477 6 14 6ZM7 12H13V13H7V12ZM13 9H7V10H13V9Z"
      fill="#24275B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1281 17.8707C12.9565 17.6501 12.9574 17.3503 13.1302 17.1305L14.6066 15.2535C14.732 15.0941 14.9305 15 15.1415 15L16.8766 15C17.0889 15 17.2884 15.0952 17.4137 15.2562L18.8719 17.1311C19.0427 17.3507 19.0427 17.649 18.8719 17.8686L17.4137 19.7437C17.2884 19.9048 17.0889 20 16.8766 20H15.1218C14.9095 20 14.71 19.9048 14.5847 19.7437L13.1281 17.8707ZM15.9992 18.125C16.3665 18.125 16.6643 17.8452 16.6643 17.5C16.6643 17.1548 16.3665 16.875 15.9992 16.875C15.6318 16.875 15.334 17.1548 15.334 17.5C15.334 17.8452 15.6318 18.125 15.9992 18.125Z"
      fill="#24275B"
    />
  </svg>
);
