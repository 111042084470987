import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.9999 2.39961C3.9999 1.95778 4.35807 1.59961 4.7999 1.59961C5.24173 1.59961 5.5999 1.95778 5.5999 2.39961H10.3999C10.3999 1.95778 10.7581 1.59961 11.1999 1.59961C11.6417 1.59961 11.9999 1.95778 11.9999 2.39961H12.7999C13.2417 2.39961 13.5999 2.75778 13.5999 3.19961V4.79961H2.3999V3.19961C2.3999 2.75778 2.75807 2.39961 3.1999 2.39961H3.9999ZM13.5999 5.59961H2.3999V12.7996C2.3999 13.2414 2.75807 13.5996 3.1999 13.5996H9.22801C8.95573 13.1289 8.7999 12.5825 8.7999 11.9996C8.7999 10.2323 10.2326 8.79961 11.9999 8.79961C12.5828 8.79961 13.1292 8.95544 13.5999 9.22771V5.59961ZM14.3999 11.9996C14.3999 10.6741 13.3254 9.59961 11.9999 9.59961C10.6744 9.59961 9.5999 10.6741 9.5999 11.9996C9.5999 13.3251 10.6744 14.3996 11.9999 14.3996C13.3254 14.3996 14.3999 13.3251 14.3999 11.9996ZM11.4999 12.2996V10.4996H12.4999V11.7996H13.1999V12.7996H11.9999H11.4999V12.2996Z"
      fill="#24275B"
    />
  </svg>
);
