import {
  ApiError,
  DisconnectXeroAuthParams,
  LegalEntity,
  RemoveLegalEntityResponse,
  ReplaceProductToAccount,
  useCreateLegalEntityMutation,
  useDisconnectXeroAuthTokenMutation,
  useGetLegalEntityQuery,
  useRemoveLegalEntityMutation,
  useUpdateLegalEntityMutation,
  XeroTenant,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { removeObjectUndefinedOrNullValues } from '@schooly/utils/remove-object-undefined-or-null-values';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

export type LegalEntityForm = Omit<LegalEntity, 'can_be_deleted' | 'archived' | 'has_products'> & {
  replace_to_accounts?: Array<ReplaceProductToAccount>;
};

type RemoveProps = {
  replace_to_accounts?: Array<ReplaceProductToAccount>;
  replace_to_id?: LegalEntity['id'];
};

export type LegalEntityModalType =
  | 'legal-entity-form'
  | 'start-integration'
  | 'replace-accounts'
  | 'complete-integration';

export type LegalEntityRemoveModalType =
  | 'remove-start'
  | 'products-list'
  | 'remove-confirm'
  | 'replace-accounts'
  | 'replace-legal-entity';

export type LegalEntityContextProps = {
  modalType: LegalEntityModalType;
  selectedTenant?: XeroTenant;
  id: string;
  isLoading: boolean;
  isSaving: boolean;
  isRemoving: boolean;
  canCreate: boolean;
  canEdit: boolean;
  canView: boolean;
  changeSelectedTenant: (tenant?: XeroTenant) => void;
  changeModalType: (modalType: LegalEntityModalType) => void;
  create: (payload: LegalEntityForm) => Promise<void | LegalEntity>;
  remove: (payload: RemoveProps) => Promise<void | RemoveLegalEntityResponse>;
  update: (payload: LegalEntityForm) => Promise<void | LegalEntity>;
  disconnectXeroTenant: (payload: DisconnectXeroAuthParams) => Promise<void>;
  legalEntityToReplace?: LegalEntity;
  setLegalEntityToReplace: (entity?: LegalEntity) => void;
  removeModalType: LegalEntityRemoveModalType;
  changeRemoveModalType: (modalType: LegalEntityRemoveModalType) => void;
};

export const LegalEntityContext = createContext<LegalEntityContextProps>({
  modalType: 'legal-entity-form',
  id: '',
  isLoading: false,
  isSaving: false,
  canCreate: false,
  canEdit: false,
  canView: false,
  isRemoving: false,
  changeSelectedTenant: () => {},
  changeModalType: () => {},
  create: async () => {},
  remove: async () => {},
  update: async () => {},
  disconnectXeroTenant: async () => {},
  legalEntityToReplace: undefined,
  setLegalEntityToReplace: () => {},
  removeModalType: 'remove-start',
  changeRemoveModalType: () => {},
});

export const WithLegalEntity: FC<
  PropsWithChildren<{ id?: string; initialModalType?: LegalEntityModalType }>
> = ({ id: propId, initialModalType, children }) => {
  const { id: paramId = '' } = useParams<'id'>();
  const id = propId ?? paramId;
  const [selectedTenant, setSelectedTenant] = useState<XeroTenant>();
  const [modalType, setModalType] = useState<LegalEntityModalType>(
    !id && !selectedTenant ? 'start-integration' : 'legal-entity-form',
  );

  const [removeModalType, setRemoveModalType] =
    useState<LegalEntityRemoveModalType>('remove-start');
  const [legalEntityToReplace, setLegalEntityToReplace] = useState<LegalEntity>();
  const { showError, showNotification } = useNotifications();
  const { schoolId, permissions } = useAuth();

  const canView = permissions.includes('product_and_invoice_viewer');
  const canCreate = permissions.includes('product_and_invoice_creator');
  const canEdit = permissions.includes('product_and_invoice_creator');

  const createLegalEntity = useCreateLegalEntityMutation();
  const updateLegalEntity = useUpdateLegalEntityMutation();
  const removeLegalEntity = useRemoveLegalEntityMutation();
  const disconnectXeroAuthToken = useDisconnectXeroAuthTokenMutation();

  const invalidateQueries = useInvalidateListQueriesFor('legalEntity');

  const { data, isLoading } = useGetLegalEntityQuery(
    { id, schoolId: schoolId! },
    {
      refetchOnMount: 'always',
      enabled: !!id && !!schoolId,
    },
  );

  const remove = useCallback<LegalEntityContextProps['remove']>(
    async (props) => {
      if (!data || !schoolId) return;

      try {
        const result = await removeLegalEntity.mutateAsync({
          schoolId,
          id,
          ...props,
        });

        invalidateQueries();

        showNotification({
          textId: data.can_be_deleted
            ? 'legalEntities-DeleteSuccess'
            : 'legalEntities-ArchiveSuccess',
          values: { legalEntityName: `"${data?.display_name}"` },
          type: 'success',
        });
        return result;
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [data, id, invalidateQueries, removeLegalEntity, schoolId, showError, showNotification],
  );

  const create = useCallback<LegalEntityContextProps['create']>(
    async ({ replace_to_accounts, connected_tenant, accounts, ...rest }) => {
      if (!schoolId) return;

      const legalEntityParams = {
        ...rest,
        connected_tenant_id: connected_tenant?.id ?? null,
      };

      try {
        const res = await createLegalEntity.mutateAsync({
          schoolId,
          replace_to_accounts,
          legalEntityParams: removeObjectUndefinedOrNullValues(legalEntityParams),
        });

        invalidateQueries();

        showNotification({
          textId: 'legalEntities-CreateSuccess',
          values: { legalEntityName: `"${rest?.display_name}"` },
          type: 'success',
        });
        return res;
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [createLegalEntity, invalidateQueries, schoolId, showError, showNotification],
  );

  const update = useCallback<LegalEntityContextProps['update']>(
    async ({ id, replace_to_accounts, accounts, connected_tenant, ...rest }) => {
      if (!id || !schoolId) return;

      const legalEntityParams = {
        ...rest,
        connected_tenant_id: connected_tenant?.id ?? null,
      };

      try {
        const res = await updateLegalEntity.mutateAsync({
          schoolId,
          id,
          replace_to_accounts,
          legalEntityParams: removeObjectUndefinedOrNullValues(legalEntityParams),
        });

        invalidateQueries();

        showNotification({
          textId: 'legalEntities-UpdateSuccess',
          values: { legalEntityName: `"${rest?.display_name}"` },
          type: 'success',
        });

        return res;
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [schoolId, updateLegalEntity, showNotification, invalidateQueries, showError],
  );

  const disconnectXeroTenant = useCallback(
    async (payload: DisconnectXeroAuthParams) => {
      if (!id) return;

      const tenantName = data?.connected_tenant?.name ?? selectedTenant?.name;

      try {
        await disconnectXeroAuthToken.mutateAsync(payload);

        showNotification({
          textId: 'legalEntity-DisconnectSuccess',
          values: {
            legalEntityName: `"${data?.display_name}"`,
            tenantName: `"${tenantName}"`,
          },
          type: 'success',
        });

        // TODO TR-6076 invalidateQueries
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [
      id,
      data?.connected_tenant?.name,
      data?.display_name,
      selectedTenant?.name,
      disconnectXeroAuthToken,
      showNotification,
      showError,
    ],
  );

  return (
    <LegalEntityContext.Provider
      value={{
        id,
        selectedTenant,
        changeSelectedTenant: setSelectedTenant,
        isLoading,
        modalType,
        changeModalType: setModalType,
        disconnectXeroTenant,
        create,
        update,
        remove,
        canCreate,
        canEdit,
        canView,
        isRemoving: removeLegalEntity.isLoading,
        isSaving: createLegalEntity.isLoading || updateLegalEntity.isLoading,
        legalEntityToReplace,
        setLegalEntityToReplace,
        removeModalType,
        changeRemoveModalType: setRemoveModalType,
      }}
    >
      {children}
    </LegalEntityContext.Provider>
  );
};

export const useLegalEntity = () => {
  return useContext(LegalEntityContext);
};
