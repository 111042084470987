import { UserType } from '@schooly/api';
import { User } from '@schooly/api';
import Joi from 'joi';

import Languages from '../../../constants/languages';
import Nationalities from '../../../constants/nationalities';
import { getUserNationalityValues } from '../../../helpers/users';
import {
  convertFieldArrayToFlatArray,
  convertFlatArrayToFieldArray,
  FieldArrayItem,
} from '../../../utils/flatFieldArrayConverter';
import emailSchema from '../../../utils/joi/emailSchema';
import emptyNumber from '../../../utils/joi/emptyNumber';
import emptyString from '../../../utils/joi/emptyString';
import fieldArray from '../../../utils/joi/fieldArray';
import { hasFieldValue } from '../../ui/Input/utils';

export type UserFormData = Pick<
  User,
  | 'given_name'
  | 'last_name'
  | 'known_as'
  | 'title'
  | 'date_of_birth'
  | 'gender'
  | 'language'
  | 'email'
> &
  Partial<
    Pick<User, 'address_line_1' | 'address_line_2' | 'city' | 'country' | 'region' | 'zip_code'>
  > & {
    nationalities: FieldArrayItem<Nationalities>[];
    other_languages_spoken: FieldArrayItem<Languages>[];
    telephones: FieldArrayItem<string>[];
  };

// TODO: Typing is not strong enough here
export function getSchema(userType: UserType) {
  return Joi.object<UserFormData>({
    given_name: emptyString(true),
    last_name: emptyString(true),
    known_as: emptyString(false, ''),
    title: emptyNumber(false, null),
    date_of_birth: emptyString(false, null),
    gender: emptyNumber(false, null),
    nationalities: fieldArray(emptyNumber()),
    language: emptyNumber(false, null),
    other_languages_spoken: fieldArray(emptyNumber()),
    telephones: fieldArray(emptyString(false, null)),
    email: emailSchema(false, true),
    ...(userType !== 'child' && {
      address_line_1: emptyString(false, null),
      address_line_2: emptyString(false, null),
      city: emptyString(false, null),
      country: emptyNumber(false, null),
      region: emptyString(false, null),
      zip_code: emptyString(false, null),
    }),
  }).unknown();
}

export function pickFormFieldsFromUser(user: User): UserFormData {
  return {
    ...user,
    nationalities: convertFlatArrayToFieldArray(getUserNationalityValues(user)),
    other_languages_spoken: convertFlatArrayToFieldArray(user.other_languages_spoken),
    telephones: convertFlatArrayToFieldArray(
      user.telephone ? [user.telephone, ...(user.other_telephones || [])] : [],
    ),
  };
}

export function processFormData(data: UserFormData): Partial<User> {
  const { telephones, nationalities, language, ...other } = data;

  return {
    ...other,
    language: hasFieldValue(language) ? language : null,
    telephone: telephones && telephones.length ? convertFieldArrayToFlatArray(telephones)[0] : null,
    other_telephones:
      telephones && telephones.length
        ? convertFieldArrayToFlatArray(telephones.slice(1)).filter(Boolean)
        : null,
    nationality:
      nationalities && nationalities.length ? convertFieldArrayToFlatArray(nationalities)[0] : null,
    other_nationalities:
      nationalities && nationalities.length > 1
        ? convertFieldArrayToFlatArray(nationalities.slice(1))
        : null,
    other_languages_spoken: convertFieldArrayToFlatArray(data.other_languages_spoken),
  };
}
