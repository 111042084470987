import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  ArrowRightIcon,
  CheckIcon,
  Counter,
  CrossIcon,
  DeleteIcon,
  EditIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalLarge,
  ModalPanel,
  ModalSmall,
  SimpleButton,
  Spin,
} from '@schooly/style';
import { FC, useState } from 'react';
import { FormProvider } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import {
  NotPublishedModalState,
  useNotPublishedReport,
} from '../../../context/report/WithReportEditContext';
import { ReportCreateModalAdditionalAssessments } from './ReportCreateModalAdditionalAssessments';
import { FormStateContent, InitiallyFocusedInput } from './ReportCreateModalForm';
import { ReportCreateModalHeader } from './ReportCreateModalHeader';
import { MatrixStateContent } from './ReportCreateModalMatrix';

export const ReportCreateModalNotPublishedContent: FC = () => {
  const { $t } = useIntl();
  const [formFocusedInput, setFormFocusedInput] = useState<InitiallyFocusedInput | undefined>();
  const {
    report,
    schoolId,
    state,
    setState,
    isDeleting,
    isValidating,
    isFetching,
    autogeneratedAssessments,
    isAdmin,
    subjects,
    subjectsConfigured,
    activeSubjects,
    ageGroups,
    activeAgeGroups,
    ageGroupsConfigured,
    isPropertiesLoading,
    isSubjectsLoading,
    handleDelete,
    handleClose,
    handleSave,
    handleSubmitNext,
    form,
  } = useNotPublishedReport();
  const additionalAssessments = form.watch('assessments');

  const renderFooter = (disabled = false) => {
    const deleteButton = (
      <Button
        variant="outlined"
        startIcon={isDeleting ? <Spin /> : <DeleteIcon />}
        disabled={isDeleting || disabled}
        onClick={handleDelete}
        data-test-id="report-delete-button"
      >
        {$t({ id: 'action-Delete' })}
      </Button>
    );

    const footerProps = {
      withBorderTop: true,
      active: true,
      sx: {
        position: 'relative',
        justifyContent: 'space-between',
      },
    } as const;

    switch (state) {
      case NotPublishedModalState.NotPublishedAssessments:
        return (
          <ModalFooter {...footerProps}>
            {deleteButton}

            <Button
              onClick={() => setState(NotPublishedModalState.NotPublishedForm)}
              endIcon={<ArrowRightIcon />}
              disabled={disabled}
            >
              {$t({ id: 'action-Next' })}
            </Button>
          </ModalFooter>
        );
      case NotPublishedModalState.NotPublishedForm:
        return (
          <ModalFooter {...footerProps}>
            {deleteButton}

            {report?.areas_of_learning.length || report?.with_tutor_feedback ? (
              <Button
                type="submit"
                disabled={isValidating || disabled}
                endIcon={isValidating ? <Spin /> : <ArrowRightIcon />}
              >
                {$t({ id: 'action-Next' })}
              </Button>
            ) : (
              <Button
                disabled={isFetching || disabled}
                onClick={handleSave}
                endIcon={isFetching ? <Spin /> : <CheckIcon />}
              >
                {$t({ id: 'action-Save' })}
              </Button>
            )}
          </ModalFooter>
        );
      case NotPublishedModalState.NotPublishedFinalMatrix:
        return (
          <ModalFooter {...footerProps}>
            {deleteButton}

            <Button
              disabled={isFetching}
              onClick={handleSave}
              endIcon={isFetching ? <Spin /> : <CheckIcon />}
            >
              {$t({ id: 'action-Save' })}
            </Button>
          </ModalFooter>
        );
      case NotPublishedModalState.NotPublishedInitialMatrix:
        return (
          <ModalFooter {...footerProps}>
            {deleteButton}

            <Button onClick={handleClose} endIcon={<CheckIcon />}>
              {$t({ id: 'action-Save' })}
            </Button>
          </ModalFooter>
        );

      default:
        return null;
    }
  };

  const renderContent = () => {
    const headerProps = {
      onEdit: (focusedInput?: InitiallyFocusedInput) => {
        setFormFocusedInput(focusedInput);
        setState(NotPublishedModalState.NotPublishedForm);
      },
      onClose: handleClose,
      form,
      withBorderBottom: true,
    };

    switch (state) {
      case NotPublishedModalState.NotPublishedAssessments:
        return (
          <ModalSmall
            open
            onClose={handleClose}
            sx={isFetching ? { pointerEvents: 'none' } : undefined}
          >
            <ReportCreateModalHeader {...headerProps} />
            <Stack height="100%">
              <ReportCreateModalAdditionalAssessments active form={form} />
            </Stack>
            {renderFooter()}
          </ModalSmall>
        );
      case NotPublishedModalState.NotPublishedInitialMatrix:
      case NotPublishedModalState.NotPublishedFinalMatrix:
        if (isPropertiesLoading || isSubjectsLoading || !autogeneratedAssessments) {
          return (
            <ModalLarge open onClose={handleClose}>
              <ModalHeader active>
                <IconButton onClick={handleClose}>
                  <CrossIcon />
                </IconButton>
              </ModalHeader>
              <ModalContent active>
                <Loading />
              </ModalContent>
            </ModalLarge>
          );
        }

        return (
          <ModalLarge open onClose={handleClose}>
            <ReportCreateModalHeader
              {...headerProps}
              onEditAssessments={() => setState(NotPublishedModalState.NotPublishedAssessments)}
              active
            />
            <MatrixStateContent
              report={report}
              assessments={autogeneratedAssessments}
              subjects={subjects}
              ageGroups={ageGroups}
              form={form}
            />
            {renderFooter()}
          </ModalLarge>
        );

      case NotPublishedModalState.NotPublishedForm:
        if (isPropertiesLoading || isSubjectsLoading) {
          return (
            <ModalSmall open onClose={handleClose}>
              <ModalHeader active>
                <IconButton onClick={handleClose}>
                  <CrossIcon />
                </IconButton>
              </ModalHeader>
              <ModalContent active>
                <Loading />
              </ModalContent>
            </ModalSmall>
          );
        }

        return (
          <ModalSmall
            open
            onClose={handleClose}
            sx={isFetching ? { pointerEvents: 'none' } : undefined}
          >
            <FormStateContent
              focusedInput={formFocusedInput}
              isAdmin={isAdmin}
              schoolId={schoolId}
              hasActiveSubjects={!!activeSubjects.length}
              hasActiveAgeGroups={!!activeAgeGroups.length}
              ageGroupsConfigured={ageGroupsConfigured}
              subjectsConfigured={subjectsConfigured}
              form={form}
              onClose={handleClose}
              onNext={handleSubmitNext}
              renderFooter={renderFooter}
              report={report}
              onClearFocusedInput={() => setFormFocusedInput(undefined)}
            >
              <ModalPanel withBorderTop>
                <Stack flexDirection="row" justifyContent="space-between">
                  <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="h2">
                      {$t({
                        id: 'reports-AdditionalAssessments',
                      })}
                    </Typography>
                    <Counter sx={{ minWidth: 20, minHeight: 20, textAlign: 'center' }}>
                      {additionalAssessments.length}
                    </Counter>
                  </Stack>
                  <SimpleButton
                    startIcon={<EditIcon />}
                    onClick={() => setState(NotPublishedModalState.NotPublishedAssessments)}
                  >
                    {$t({
                      id: 'reports-EditAssessments',
                    })}
                  </SimpleButton>
                </Stack>
              </ModalPanel>
            </FormStateContent>
          </ModalSmall>
        );

      default:
        return null;
    }
  };

  return <FormProvider {...form}>{renderContent()}</FormProvider>;
};
