import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9925 4.80691C12.2606 2.08325 7.84002 2.08324 5.10808 4.8069L0.710349 9.19131C0.318393 9.58208 0.318393 10.2169 0.710349 10.6077L5.10808 14.9921C7.84002 17.7157 12.2606 17.7157 14.9925 14.9921L19.3903 10.6077C19.7822 10.2169 19.7822 9.58208 19.3903 9.19131L14.9925 4.80691ZM14 9.99998C14 12.2091 12.2092 14 10 14C7.79088 14 6.00002 12.2091 6.00002 9.99998C6.00002 7.79084 7.79088 5.99998 10 5.99998C12.2092 5.99998 14 7.79084 14 9.99998ZM13 9.99998C13 11.6568 11.6569 13 10 13C8.34316 13 7.00002 11.6568 7.00002 9.99998C7.00002 8.34313 8.34316 6.99998 10 6.99998C11.6569 6.99998 13 8.34313 13 9.99998Z"
      fill="#B3BBCB"
    />
  </svg>
);
