import { Box, MenuProps, Stack, Typography } from '@mui/material';
import { buildClassName } from '@schooly/utils/build-classname';
import React, { ReactNode, useState } from 'react';

import { ArrowDownV2Icon } from '../../assets/assets';
import { DropdownMenuPopover, DropdownMenuPopoverProps } from './DropdownMenuPopover';

export interface DropdownMenuItem {
  icon?: ReactNode;
  title: string;
  handler: () => void;
}

export interface DropdownMenuProps<T extends DropdownMenuItem>
  extends Pick<
    DropdownMenuPopoverProps<T>,
    'menuStyles' | 'isSelected' | 'renderMenuItem' | 'closeOnSelect'
  > {
  title?: string;
  icon?: ReactNode;
  actions: T[];
  noArrow?: boolean;
  menuProps?: Partial<MenuProps>;
  menuStyles?: DropdownMenuPopoverProps<T>['sx'];
  onClose?: () => void;
  testId?: string;
}

export const DropdownMenu = <T extends DropdownMenuItem>({
  actions,
  title,
  icon,
  renderMenuItem,
  isSelected,
  noArrow,
  menuProps,
  menuStyles,
  closeOnSelect,
  onClose,
  testId,
}: DropdownMenuProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  return (
    <>
      <Stack
        onClick={(e) => setAnchorEl(e.currentTarget)}
        direction="row"
        alignItems="center"
        gap={!noArrow ? 0.5 : undefined}
        className={buildClassName('DropdownMenu', open && 'DropdownMenu-open')}
        sx={(theme) => ({
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
          justifyContent: 'center',
          '&:hover': {
            color: theme.palette.primary.main,
            '.arrowDropdown': {
              path: {
                stroke: theme.palette.primary.main,
              },
            },
            '.svg-icon': {
              color: theme.palette.primary.main,
            },
          },
          color: open ? theme.palette.primary.main : undefined,
          '.svg-icon': {
            color: open ? theme.palette.primary.main : undefined,
          },
          '.arrowDropdown': {
            path: {
              stroke: open ? theme.palette.primary.main : undefined,
            },
            width: 8,
            transform: Boolean(open) ? 'rotate(180deg)' : undefined,
          },
        })}
        data-test-id={testId}
      >
        {icon}
        {title && <Typography>{title}</Typography>}

        {!noArrow && (
          <Box pl={0.5}>
            <ArrowDownV2Icon className="arrowDropdown" />
          </Box>
        )}
      </Stack>

      <DropdownMenuPopover
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ vertical: 20, horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        closeOnSelect={closeOnSelect}
        autoFocus={false}
        sx={menuStyles}
        renderMenuItem={renderMenuItem}
        isSelected={isSelected}
        options={actions}
        {...menuProps}
      />
    </>
  );
};
