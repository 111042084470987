import { getPrivateImage } from '@schooly/api';
import { StringOrNull } from '@schooly/api';
import { useCallback, useEffect, useState } from 'react';

import useRequestWithProgress from './useRequestWithProgress';

export const usePrivateImage = (url?: StringOrNull) => {
  const [key, setKey] = useState(0);
  const [image, setImage] = useState<StringOrNull>(null);

  const fetchImage = useCallback(async () => {
    if (!url) return;

    await getPrivateImage(url);
    setKey((prev) => prev + 1);
    setImage(url);
  }, [url]);

  const [fetchPrivateImage, isLoading] = useRequestWithProgress(fetchImage);

  useEffect(() => {
    fetchPrivateImage();
  }, [fetchPrivateImage]);

  return { key, isLoading, image };
};
