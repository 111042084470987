import { IconButton, Stack } from '@mui/material';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { ControlTextField } from '@schooly/components/form-text-field';
import { DragIcon } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import {
  FieldPath,
  FieldPathValue,
  useFieldArray,
  useFormContext,
  Validate,
} from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SchoolGeneralRowAction } from '../SchoolGeneralRowAction';
import {
  AgeGroupInForm,
  getEmptyAgeGroup,
  SchoolGeneralAgeGroupsForm,
} from './useSchoolGeneralAgeGroupsModal';

interface SchoolGeneralAgeGroupRowProps {
  provided: DraggableProvided;
  shouldFocus: boolean;
  ageGroupIndex: number;
  validateAgeGroup: (
    id: string,
  ) => Validate<
    FieldPathValue<SchoolGeneralAgeGroupsForm, FieldPath<SchoolGeneralAgeGroupsForm>>,
    SchoolGeneralAgeGroupsForm
  >;
  getAllAgeGroups: () => AgeGroupInForm[];
  activeAgeGroupsTotalCount: number;
  autoSwitchedArchivedOn: React.MutableRefObject<boolean>;
  setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SchoolGeneralAgeGroupRow: FC<SchoolGeneralAgeGroupRowProps> = ({
  provided,
  shouldFocus,
  ageGroupIndex,
  validateAgeGroup,
  getAllAgeGroups,
  activeAgeGroupsTotalCount,
  autoSwitchedArchivedOn,
  setShowArchived,
}) => {
  const { $t } = useIntl();
  const { getConfirmation } = useConfirmationDialog();

  const { control, watch, formState, trigger } = useFormContext<SchoolGeneralAgeGroupsForm>();
  const hasErrors = formState.errors.schoolLevels || formState.errors.ageGroupsWithNoLevel;

  const ageGroupsWithNoLevel = watch('ageGroupsWithNoLevel');
  const ageGroup = ageGroupsWithNoLevel[ageGroupIndex];
  const archivedAgeGroups = watch('archivedAgeGroups');

  const { remove: removeAgeGroups, replace: replaceAgeGroups } = useFieldArray({
    control: control,
    name: `ageGroupsWithNoLevel`,
  });
  const { append: appendArchivedAgeGroup } = useFieldArray({
    control: control,
    name: `archivedAgeGroups`,
  });

  const handleArchiveAgeGroup = useCallback(
    async (index: number) => {
      const isConfirmed = await getConfirmation({
        textId: 'school-tabs-AgeGroups-ArchiveConfirmation',
        textValues: { name: ageGroup?.name ?? '' },
        confirmTextId: 'yes',
        cancelTextId: 'no',
      });

      if (isConfirmed) {
        removeAgeGroups(index);
        appendArchivedAgeGroup({ ...ageGroup, archived: true, level_id: null });

        // TR-4613: should switch on `Show archived` first time if no archived fields were before
        if (!archivedAgeGroups.length && !autoSwitchedArchivedOn.current) {
          setShowArchived(true);
          autoSwitchedArchivedOn.current = true;
        }
        if (hasErrors) {
          trigger();
        }
      }
    },
    [
      ageGroup,
      appendArchivedAgeGroup,
      archivedAgeGroups.length,
      autoSwitchedArchivedOn,
      getConfirmation,
      hasErrors,
      removeAgeGroups,
      setShowArchived,
      trigger,
    ],
  );

  const handleDeleteAgeGroup = useCallback(
    (index: number) => {
      if (getAllAgeGroups().length > 1) {
        removeAgeGroups(index);
        if (hasErrors) {
          trigger();
        }
      } else {
        replaceAgeGroups([getEmptyAgeGroup()]);
      }
    },
    [getAllAgeGroups, hasErrors, removeAgeGroups, replaceAgeGroups, trigger],
  );

  return (
    <Stack
      ref={provided.innerRef}
      {...provided.draggableProps}
      direction="row"
      alignItems="flex-start"
      gap={1}
      sx={(theme) => ({
        width: '100%',
        '& > .MuiButtonBase-root': { mt: theme.spacing(1.5) },
      })}
      style={provided.draggableProps.style}
    >
      <IconButton inverse {...provided.dragHandleProps}>
        <DragIcon />
      </IconButton>

      <ControlTextField
        solid
        name={`ageGroupsWithNoLevel.${ageGroupIndex}.name`}
        control={control}
        rules={{
          required: true,
          validate: validateAgeGroup(ageGroup?.id || ageGroup?.id_tmp || ''),
        }}
        label={$t({ id: 'school-tabs-AgeGroups-AgeGroupName' })}
        autoFocus={
          shouldFocus && ageGroupIndex === ageGroupsWithNoLevel.length - 1 && !ageGroup?.name
        }
        fullWidth
        canClear
      />

      {activeAgeGroupsTotalCount > 1 ? (
        <SchoolGeneralRowAction
          id={ageGroup?.id}
          index={ageGroupIndex}
          isLocked={
            ageGroup &&
            (ageGroup.conduct_default ||
              ageGroup.group_default ||
              ageGroup.student_default ||
              ageGroup.staff_default)
          }
          lockMessage={$t({
            id: 'school-tabs-AgeGroups-Tooltip-DefaultFilter',
          })}
          onArchive={handleArchiveAgeGroup}
          onDelete={handleDeleteAgeGroup}
        />
      ) : null}
    </Stack>
  );
};
