import { Button, FormControlLabel, IconButton, Stack, Switch, TextField } from '@mui/material';
import { SchoolProperty } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { usePrevious } from '@schooly/hooks/use-previous';
import {
  CheckIcon,
  CrossIcon,
  DragIcon,
  Loading,
  ModalFooter,
  ModalHeader,
  ModalMain,
  ModalSmall,
  PlusIcon,
  RollBackIcon,
  SimpleButton,
  Spin,
} from '@schooly/style';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../../components/uikit/Dnd/dnd.styled';
import { ControlTextField } from '../../../../components/uikit-components/FormTextField/ControlTextField';
import { ModalContent } from '../../../../components/uikit-components/Modal/Modal.styled';
import { SchoolPropertyType } from '../../../../constants/school';
import { SchoolGeneralRowAction } from '../SchoolGeneralRowAction';
import { SchoolGeneralDepartmentsReplaceModal } from './SchoolGeneralDepartmentsReplaceModal';

interface SchoolGeneralDepartmentsModalContentProps {
  departments: SchoolProperty[];
  onSubmit: (v: SchoolGeneralDepartmentsForm) => void;
  onClose: () => void;
  isSaving: boolean;
  isLoading: boolean;
}

export interface SchoolGeneralDepartmentsForm {
  departments?: (SchoolProperty & {
    originId?: SchoolProperty['id'];
    replaceId?: SchoolProperty['id'];
  })[];
}

const getEmptyDepartment = () => ({
  id: '',
  originId: '',
  replaceId: '',
  name: '',
  type: SchoolPropertyType.Department,
  archived: false,
  category: null,
  conduct_default: false,
  group_default: false,
  order: 0,
  staff_default: false,
  student_default: false,
});

export const SchoolGeneralDepartmentsModalContent: FC<SchoolGeneralDepartmentsModalContentProps> =
  ({ departments, isSaving, isLoading, onSubmit, onClose }) => {
    const { $t } = useIntl();
    const { getConfirmation } = useConfirmationDialog();
    const [archivingFieldIndex, setArchivingFieldIndex] = useState<number>();
    const [showArchived, setShowArchived] = useState(false);
    const autoSwitchedArchivedOn = useRef(false);

    const form = useForm<SchoolGeneralDepartmentsForm>({
      defaultValues: {
        departments: departments?.length
          ? [...departments.map((h) => ({ ...h, originId: h.id, replaceId: '' }))]
          : [getEmptyDepartment()],
      },
    });
    const { fields, append, remove, replace, move, update } = useFieldArray({
      control: form.control,
      name: 'departments',
    });

    // Have to spread active & archived fields to two different lists and
    // render/manage them separately.
    const [activeFields, archivedFields] = useMemo(
      () =>
        fields.reduce<Array<Array<{ index: number; field: typeof fields[number] }>>>(
          (prev, field, index) => {
            prev[field.archived ? 1 : 0].push({ index, field });
            return prev;
          },
          [[], []],
        ),
      [fields],
    );

    const prevActiveFields = usePrevious(activeFields);

    const firstActiveField = form.getValues(`departments.${activeFields[0]?.index}`);

    const shouldFocusLastItem = Boolean(
      activeFields.length === 1
        ? !firstActiveField.name
        : prevActiveFields && activeFields && activeFields.length - prevActiveFields.length === 1,
    );

    const addDepartment = useCallback(() => {
      append(getEmptyDepartment());
    }, [append]);

    const archiveDepartment = useCallback(
      (index: number, replaceId: SchoolProperty['id'] = '') => {
        const field = form.getValues(`departments.${index}`);

        update(index, { ...field, archived: true, replaceId });

        // Should switch on `Show archived` first time if no archived fields were before
        if (!archivedFields.length && !autoSwitchedArchivedOn.current) {
          setShowArchived(true);
          autoSwitchedArchivedOn.current = true;
        }
      },
      [archivedFields.length, form, update],
    );

    const handleDeleteDepartment = useCallback(
      (index: number) => {
        if (fields.length > 1) {
          remove(index);
        } else {
          replace([getEmptyDepartment()]);
        }
      },
      [fields.length, remove, replace],
    );

    const handleArchiveDepartment = useCallback((index: number) => {
      setArchivingFieldIndex(index);
    }, []);

    const handleReplaceDepartment = useCallback(
      (index: number) => (replaceId: SchoolProperty['id']) => {
        archiveDepartment(index, replaceId);
        setArchivingFieldIndex(undefined);
      },
      [archiveDepartment],
    );

    const handleArchiveDepartmentWithoutReplacing = useCallback(
      (index: number) => () => {
        archiveDepartment(index);
        setArchivingFieldIndex(undefined);
      },
      [archiveDepartment],
    );

    const handleRestoreDepartment = useCallback(
      (index: number) => async () => {
        const field = form.getValues(`departments.${index}`);
        // can not just use `update` as need to move to the end
        remove(index);
        append({ ...field, archived: false });
      },
      [append, form, remove],
    );

    const handleDragEnd = useCallback<OnDragEndResponder>(
      (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        move(result.source.index, result.destination.index);
      },
      [move],
    );

    const handleCloseReplaceModal = useCallback(() => {
      setArchivingFieldIndex(undefined);
    }, []);

    const handleClose = useCallback(async () => {
      if (isSaving) {
        return;
      }

      if (
        form.formState.isDirty &&
        !(await getConfirmation({ textId: 'school-edit-CloseUnsavedConfirmation' }))
      ) {
        return;
      }

      onClose();
    }, [isSaving, form.formState.isDirty, getConfirmation, onClose]);

    if (isLoading) {
      return (
        <ModalSmall open onClose={handleClose}>
          <Loading />
        </ModalSmall>
      );
    }

    return (
      <ModalSmall open onClose={handleClose}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <ModalHeader title={$t({ id: 'school-tabs-Departments' })} active>
              {archivedFields.length > 0 && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={showArchived}
                      onChange={(event, value) => setShowArchived(value)}
                    />
                  }
                  label={$t({ id: 'action-ShowArchived' })}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              )}
              {!isSaving && (
                <IconButton onClick={handleClose}>
                  <CrossIcon />
                </IconButton>
              )}
            </ModalHeader>
            <ModalMain>
              <ModalContent flat active>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="DeparmentsDroppable">
                    {(provided) => (
                      <DndListWrapper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        alignItems="flex-start"
                        gap={2}
                        p={2.5}
                      >
                        {activeFields?.map(({ field, index }, arrayIndex) => (
                          <Draggable key={field.id} draggableId={field.id} index={index}>
                            {(provided) => (
                              <Stack
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                direction="row"
                                alignItems="center"
                                gap={1}
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                style={provided.draggableProps.style}
                              >
                                {activeFields.length > 1 && (
                                  <IconButton inverse {...provided.dragHandleProps}>
                                    <DragIcon />
                                  </IconButton>
                                )}
                                <ControlTextField
                                  name={`departments.${index}.name`}
                                  control={form.control}
                                  rules={{
                                    required: true,
                                    validate: (value, formValues) => {
                                      // Check for name uniqueness
                                      for (
                                        let i = 0;
                                        i < (formValues.departments?.length ?? 0);
                                        i++
                                      ) {
                                        if (i === index) {
                                          continue;
                                        }

                                        const field = formValues.departments![i];

                                        if (
                                          field.name?.trim().toLowerCase() ===
                                          `${value ?? ''}`.trim().toLowerCase()
                                        ) {
                                          return $t({
                                            id: field.archived
                                              ? 'school-tabs-Departments-ArchivedExists'
                                              : 'school-tabs-Departments-ActiveExists',
                                          });
                                        }
                                      }

                                      return true;
                                    },
                                  }}
                                  label={$t({ id: 'school-tabs-Departments-DepartmentName' })}
                                  autoFocus={
                                    shouldFocusLastItem && arrayIndex === activeFields.length - 1
                                  }
                                  fullWidth
                                  canClear
                                />

                                {activeFields.length ? (
                                  <SchoolGeneralRowAction
                                    id={field.originId}
                                    index={index}
                                    isLocked={
                                      field.conduct_default ||
                                      field.group_default ||
                                      field.student_default ||
                                      field.staff_default
                                    }
                                    lockMessage={$t({
                                      id: 'school-tabs-Departments-Tooltip-DefaultFilter',
                                    })}
                                    onArchive={handleArchiveDepartment}
                                    onDelete={handleDeleteDepartment}
                                  />
                                ) : null}
                              </Stack>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}

                        <SimpleButton startIcon={<PlusIcon />} onClick={addDepartment}>
                          <FormattedMessage id="school-tabs-Departments-AddDepartment" />
                        </SimpleButton>

                        {showArchived &&
                          archivedFields.map(({ field, index }) => (
                            <Stack
                              key={field.id}
                              direction="row"
                              alignItems="center"
                              gap={1}
                              pl={3.5}
                              width="100%"
                            >
                              <TextField
                                value={field.name}
                                fullWidth
                                disabled
                                label={$t({ id: 'school-tabs-Departments-DepartmentName' })}
                              />

                              <IconButton onClick={handleRestoreDepartment(index)}>
                                <RollBackIcon />
                              </IconButton>
                            </Stack>
                          ))}
                      </DndListWrapper>
                    )}
                  </Droppable>
                </DragDropContext>
              </ModalContent>
            </ModalMain>
            <ModalFooter sx={{ justifyContent: 'space-between' }} active>
              <Button variant="outlined" disabled={isSaving} onClick={handleClose}>
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={isSaving}
                endIcon={isSaving ? <Spin /> : <CheckIcon />}
              >
                <FormattedMessage id="action-Save" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
        {archivingFieldIndex != null && (
          <SchoolGeneralDepartmentsReplaceModal
            field={fields[archivingFieldIndex]}
            fields={activeFields}
            onClose={handleCloseReplaceModal}
            onReplace={handleReplaceDepartment(archivingFieldIndex)}
            onArchiveWithoutReplacing={handleArchiveDepartmentWithoutReplacing(archivingFieldIndex)}
          />
        )}
      </ModalSmall>
    );
  };
