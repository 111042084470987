import { isToday } from 'date-fns';
import { FC } from 'react';

import {
  CalendarWeekGridCellStyled,
  CalendarWeekGridColumnStyled,
  CalendarWeekGridCurrentTimeStyled,
  CalendarWeekGridCurrentTimeToday,
} from './CalendarWeek.styled';

export interface CalendarWeekCurrentTimeProps {
  days: { date: Date }[];
  weeksCount: number;
  position?: number | string;
}

export const CalendarWeekCurrentTime: FC<CalendarWeekCurrentTimeProps> = ({
  days,
  position,
  weeksCount,
}) => {
  return (
    <CalendarWeekGridCurrentTimeStyled sx={{ top: position }}>
      {days.map(({ date }, index) => (
        <CalendarWeekGridColumnStyled key={index} weeksCount={weeksCount}>
          <CalendarWeekGridCellStyled>
            {isToday(date) && <CalendarWeekGridCurrentTimeToday />}
          </CalendarWeekGridCellStyled>
        </CalendarWeekGridColumnStyled>
      ))}
    </CalendarWeekGridCurrentTimeStyled>
  );
};
