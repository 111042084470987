import { FilterValue, SelectOption } from '@schooly/api';
import { Report, ReportStatuses } from '@schooly/api';
import { DATE_FORMAT_RANGE_MOMENT } from '@schooly/constants';
import { DoneIcon } from '@schooly/style';
import moment from 'moment';
import React, { useCallback } from 'react';

import { noop } from '../../../helpers/misc';
import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';
import { StatusCell } from '../../uikit/StatusCell/StatusCell';

import './SelectReportRow.scss';

export interface SelectReportRowProps {
  report?: Report;
  isSelected?: boolean;
  onClick: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    selectOption?: SelectOption<FilterValue>,
  ) => void;
  selectOption?: SelectOption;
}

const $class = `${$UIKitPrefix}SelectReportRow`;

export const SelectReportRow: React.FC<SelectReportRowProps> = ({
  report,
  isSelected,
  selectOption,
  onClick = noop,
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      if (onClick) {
        onClick(e, selectOption);
      }
    },
    [onClick, selectOption],
  );

  if (!report) return null;

  const { name, report_status, scheduled_publish_date, actual_publish_date } = report;

  return (
    <button
      onClick={handleClick}
      className={buildClassName($class, 'reset-button-styles', isSelected && `${$class}_selected`)}
    >
      <p className={`${$class}__name`}>{name}</p>
      {actual_publish_date ? (
        <StatusCell
          isPublished={report_status === ReportStatuses.Published}
          date={actual_publish_date}
        />
      ) : (
        <p className="mb-0">{moment(scheduled_publish_date).format(DATE_FORMAT_RANGE_MOMENT)}</p>
      )}
      {isSelected && <DoneIcon className={`${$class}__done-icon`} />}
    </button>
  );
};
