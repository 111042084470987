import { MenuItem } from '@mui/material';
import { SignUpStatuses, SignUpType } from '@schooly/api';
import { ControlSelect } from '@schooly/components/form-select';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { SignUpForm, SignUpTypeLabels, useSignUp } from '../../../context/signUps/WithSignUp';

export interface SignUpTypeSelectorProps {
  onNoEventDateTimes?: () => void;
}

export const SignUpTypeSelector: FC<SignUpTypeSelectorProps> = ({ onNoEventDateTimes }) => {
  const { $t } = useIntl();
  const { signUp } = useSignUp();
  const { watch, setValue } = useFormContext<SignUpForm>();

  const event = watch('event');
  const withEvent = watch('withEvent');
  const type = watch('type');

  useEffect(() => {
    setValue('type', withEvent ? undefined : SignUpType.Regular);
    setValue('invitee_type', undefined);
  }, [setValue, withEvent]);

  useEffect(() => {
    if (
      onNoEventDateTimes &&
      type === SignUpType.Slots &&
      !event?.date_times.some((slot) => slot[0] && slot[1])
    ) {
      onNoEventDateTimes();
    }
  }, [event?.date_times, onNoEventDateTimes, type]);

  if (withEvent && !event) {
    return null;
  }

  return (
    <ControlSelect
      name="type"
      label={$t({ id: 'eventSignUps-create-Type' })}
      renderValue={(value) => <FormattedMessage id={SignUpTypeLabels[value as SignUpType]} />}
      rules={{ required: true }}
      locked={!withEvent || (signUp && signUp.status !== SignUpStatuses.Draft)}
      lockHint={
        !signUp || signUp.status === SignUpStatuses.Draft
          ? $t({ id: 'eventSignUps-create-Type-hint' })
          : undefined
      }
      fullWidth
    >
      <MenuItem value={SignUpType.Regular}>
        <FormattedMessage id={SignUpTypeLabels[SignUpType.Regular]} />
      </MenuItem>
      <MenuItem value={SignUpType.Slots}>
        <FormattedMessage id={SignUpTypeLabels[SignUpType.Slots]} />
      </MenuItem>
    </ControlSelect>
  );
};
