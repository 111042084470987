import { styled, TextField } from '@mui/material';

export const TimeInputStyled = styled(TextField)(({ theme, value, error }) => ({
  width: 120,
  ' .MuiInputBase-root': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '.MuiInputAdornment-root': {
      paddingTop: theme.spacing(1),
    },
  },
  '& ::-webkit-calendar-picker-indicator': {
    display: 'none',
  },
  '& .MuiInputBase-input:not(:focus)': {
    color: value ? undefined : 'transparent',
  },
  ' .MuiOutlinedInput-root': {
    '&:hover, &.Mui-focused': {
      '.MuiInputAdornment-root': {
        color: theme.palette.text.secondary,
      },
    },
  },
  ' .MuiFormLabel-root-MuiInputLabel-root.Mui-error': {
    color: theme.palette.text.secondary,
  },
  '.MuiOutlinedInput-root:hover,.MuiOutlinedInput-root.Mui-focused': {
    borderColor: 'transparent',
    ' .MuiOutlinedInput-notchedOutline': {
      borderColor: error ? theme.palette.error.main : undefined,
      outline: error
        ? `2px solid ${theme.palette.error.superLight}`
        : `2px solid ${theme.palette.background.default}`,
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    outline: error ? `2px solid ${theme.palette.error.superLight}` : undefined,
  },

  '& .MuiFormLabel-root:hover + .MuiInputBase-root': {
    ' .MuiOutlinedInput-notchedOutline': {
      outline: error
        ? `2px solid ${theme.palette.error.superLight}`
        : `2px solid ${theme.palette.background.default}`,
    },
  },

  '.Mui-disabled .svg-icon': {
    zIndex: 1,
  },
  '.MuiInputBase-input.Mui-disabled': {
    visibility: value ? 'visible' : 'hidden',
  },
  '.Mui-disabled .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.common.light3,
  },
  '& .MuiFormLabel-root:hover + .MuiInputBase-root.Mui-disabled': {
    ' .MuiOutlinedInput-notchedOutline': {
      outline: 'none',
    },
  },
  '.MuiOutlinedInput-root:hover,.MuiOutlinedInput-root.Mui-disabled': {
    ' .MuiOutlinedInput-notchedOutline': {
      outline: 'none',
    },
  },
  ' .MuiFormLabel-root-MuiInputLabel-root.Mui-disabled': {
    color: theme.palette.text.secondary,
  },
}));
