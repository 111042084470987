import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../../constants';
import { FilterKeys, UserFilter } from '../apiTypes/users';
import { getTypedObjectKeys } from './getTypedObjectKeys';

export const getFormattedParams = (
  initialParams: Record<string, any>,
  filters?: Partial<UserFilter>,
  excludeFilters?: FilterKeys[],
) => {
  const params: Record<string, any> = { ...initialParams };

  /**
   * empty string value ('') considered as 'Today', other variants should be valid as is
   * https://treehive.atlassian.net/browse/TR-2100
   */
  const prepareDate = (date?: string) =>
    date === '' ? moment().format(DEFAULT_DATE_FORMAT) : date;

  if (filters) {
    getTypedObjectKeys(filters).forEach((key) => {
      if (excludeFilters?.includes(key)) return;

      const filter = filters[key];
      if (filter?.length) {
        switch (key) {
          case FilterKeys.Status:
            params['status_ids'] = Array.isArray(filter) ? (filter as string[]).join(',') : filter;
            break;
          case FilterKeys.Date:
            params[key] = Array.isArray(filter)
              ? (filter as string[]).map(prepareDate).join(',')
              : prepareDate(filter);
            break;
          case FilterKeys.OnlyEmptyEmail:
            params[key] = filter[0] === 1 ? 1 : 0;
            break;
          default:
            params[key] = Array.isArray(filter) ? (filter as string[]).join(',') : filter;
            break;
        }
      }
    });
  }

  const filteredParams = Object.entries(params).filter(([, v]) => v !== '');

  return Object.fromEntries(filteredParams);
};
