import { ChipProps, Stack, Tooltip } from '@mui/material';
import { DATE_FORMAT } from '@schooly/constants';
import { Attention2Icon, TagSelect, WithShowOnClick } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { mobileDetect } from '@schooly/utils/mobile';
import { format, isSameDay } from 'date-fns';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { DateTagSelect } from './DateTagSelect';

type DateSelectProps = {
  dateFormat?: string;
  date: string[];
  showNoCurrentSchoolYearWarning?: boolean;
} & Omit<ChipProps, 'label'>;

export const DateRangeTagSelect: FC<DateSelectProps> = ({
  date,
  dateFormat = DATE_FORMAT,
  showNoCurrentSchoolYearWarning,
  ...props
}) => {
  const { $t } = useIntl();

  const fromDate = date[0] ? new Date(date[0]) : undefined;
  const toDate = date[1] ? new Date(date[1]) : undefined;
  const isMobile = mobileDetect.mobile();

  if (!fromDate || !toDate) return null;

  if (isSameDay(fromDate, toDate) && date[0]) return <DateTagSelect date={date[0]} {...props} />;

  return (
    <TagSelect
      label={
        <Stack direction="row" alignItems="center" gap={1}>
          <span>{date.map((d) => format(newDateTimezoneOffset(d), dateFormat)).join(' — ')}</span>
          {showNoCurrentSchoolYearWarning && (
            <WithShowOnClick showOnClick={!!isMobile}>
              {(onClick, show) => (
                <Tooltip
                  onClick={onClick}
                  open={show}
                  title={$t({ id: 'filter-NoCurrentSchoolYear-Hint' })}
                >
                  <Stack
                    sx={{
                      color: 'warning.main',
                      fontSize: (theme) => theme.spacing(2.5),

                      '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
                    }}
                  >
                    <Attention2Icon />
                  </Stack>
                </Tooltip>
              )}
            </WithShowOnClick>
          )}
        </Stack>
      }
      {...props}
    />
  );
};
