import { IconButton, Stack, styled } from '@mui/material';
import {
  ApiError,
  AssessmentMethod,
  AssessmentMethodType,
  ReportStatuses,
  useValidateReportMutation,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import {
  AssessmentGradeSelect,
  AssessmentMethodTypeSelectMultiple,
} from '@schooly/components/filters';
import { FormTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { DeleteIcon, PlusIcon, SimpleButton, Spin } from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC, useCallback, useEffect } from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ReportForm } from '../../../context/report/WithReportEditContext';
import { ReportAreaSuggestedInput } from './ReportAreaSuggestedInput';

type ReportCreateModalAreasOfLearningProps = {
  isAdmin: boolean;
  schoolId: string;
  form: UseFormReturn<ReportForm>;
  reportId?: string;
};
export const ReportCreateModalAreasOfLearning: FC<ReportCreateModalAreasOfLearningProps> = ({
  isAdmin,
  form,
  schoolId,
  reportId,
}) => {
  const { $t } = useIntl();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'areas_of_learning',
  });

  const handleAddArea = useCallback(() => {
    append({ name: '', methods: [] });
  }, [append]);

  useEffect(() => {
    if (fields.length) return;
    handleAddArea();
  }, [fields, handleAddArea]);

  return (
    <Stack gap={4}>
      {fields.map((f, i) => {
        const props = {
          isAdmin: isAdmin,
          schoolId: schoolId,
          key: f.id,
          index: i,
          form: form,
          onDelete: fields.length > 1 ? remove : undefined,
        };
        return f.assessment_id && reportId ? (
          <AreaOfLearningWithDeleteConfirmation
            {...props}
            assessmentId={f.assessment_id}
            reportId={reportId}
          />
        ) : (
          <AreaOfLearning {...props} />
        );
      })}
      <SimpleButton
        sx={{ alignSelf: 'flex-start' }}
        startIcon={<PlusIcon />}
        onClick={handleAddArea}
      >
        {$t({ id: 'reports-AddArea' })}
      </SimpleButton>
    </Stack>
  );
};
type AreaOfLearningWithDeleteConfirmationProps = Omit<
  AreaOfLearningProps,
  'isLoading' | 'disabled'
> & {
  assessmentId: string;
  reportId: string;
};

const AreaOfLearningWithDeleteConfirmation: FC<AreaOfLearningWithDeleteConfirmationProps> = ({
  assessmentId,
  reportId,
  onDelete,
  ...props
}) => {
  const { showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const validateReport = useValidateReportMutation();

  const handleDelete = useCallback(
    async (idx: number) => {
      const report = props.form.getValues();

      validateReport.mutate(
        {
          reportId,
          report: {
            ...report,
            areas_of_learning: report.areas_of_learning.filter(
              (aol) => !!aol.assessment_id && aol.assessment_id !== assessmentId,
            ),
            assessment_ids: report.assessments.map((a) => a.id),
            report_status: ReportStatuses.Unpublished,
          },
        },
        {
          onSuccess: () => onDelete?.(idx),
          onError: async (err) => {
            if ('assessments_to_remove' in err) {
              const assessment = err.assessments_to_remove.find(({ id }) => id === assessmentId);

              if (!assessment || !assessment.entries) {
                onDelete?.(idx);
                return;
              }

              const isConfirmed = await getConfirmation({
                textId: 'reports-DeleteAssessment',
                textValues: {
                  assessmentName: assessment.name,
                  entriesCount: assessment.entries,
                },
              });
              if (isConfirmed) {
                onDelete?.(idx);
              }
              return;
            }

            showError(err as ApiError);
          },
        },
      );
    },
    [assessmentId, getConfirmation, onDelete, props.form, reportId, showError, validateReport],
  );

  return (
    <AreaOfLearning
      {...props}
      disabled
      onDelete={onDelete ? handleDelete : undefined}
      isLoading={validateReport.isLoading}
    />
  );
};

type AreaOfLearningProps = {
  form: UseFormReturn<ReportForm>;
  index: number;
  schoolId: string;
  isAdmin?: boolean;
  onDelete?: (index: number) => void;
  disabled?: boolean;
  isLoading?: boolean;
};
const AreaOfLearning: FC<AreaOfLearningProps> = ({
  form,
  index,
  onDelete,
  schoolId,
  isAdmin,
  disabled,
  isLoading,
}) => {
  const { $t } = useIntl();
  const path = `areas_of_learning.${index}` as const;

  const areas = form.watch('areas_of_learning');
  const name = form.watch(`${path}.name`);
  const methods = form.watch(`${path}.methods`);
  const methodTypes = methods.map((m) => m.method_type);

  const gradeMethodIndex = methods.findIndex((m) => m.method_type === AssessmentMethodType.Grade);
  const scoreMethodIndex = methods.findIndex((m) => m.method_type === AssessmentMethodType.Score);

  return (
    <Stack gap={1} position="relative" pr={onDelete ? 4 : 0}>
      <Stack flexDirection="row" gap={1} flex={1}>
        <Stack flex={1}>
          <Controller
            control={form.control}
            name={`${path}.name`}
            rules={{
              required: true,
              validate: {
                unique: (v) => {
                  const notUnique = areas.some((m, i) => m.name === v && i < index);
                  if (notUnique) return $t({ id: 'reports-AreaDuplicateError' });
                },
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <ReportAreaSuggestedInput
                  disabled={disabled}
                  schoolId={schoolId}
                  placeholder={$t({ id: 'reports-AreasOfLearning' })}
                  requiredLabel="required"
                  error={fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                  onClear={() => field.onChange('')}
                />
              );
            }}
          />
        </Stack>
        {!!name.length && (
          <Stack flex={1}>
            <Controller
              name={`${path}.methods`}
              control={form.control}
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                const error = Array.isArray(fieldState.error) ? undefined : fieldState.error;

                return (
                  <AssessmentMethodTypeSelectMultiple
                    selectedTypes={methodTypes}
                    disabled={disabled}
                    placeholder={$t({ id: 'reports-AssessmentMethod' })}
                    requiredLabel="required"
                    error={error}
                    onSelectType={(type) => {
                      field.onChange(
                        methodTypes.includes(type)
                          ? methods.filter((m) => m.method_type !== type)
                          : [...methods, generateEmptyAssessmentMethod(type)],
                      );
                    }}
                  />
                );
              }}
            />
          </Stack>
        )}
        {onDelete && (
          <IconButton
            sx={(theme) => ({ position: 'absolute', right: 0.5, top: theme.spacing(1.25) })}
            inverse
            onClick={() => onDelete(index)}
          >
            {isLoading ? <Spin /> : <DeleteIcon />}
          </IconButton>
        )}
      </Stack>
      {!!name.length && (
        <Stack flexDirection="row" gap={1} flex={1}>
          {gradeMethodIndex !== -1 && (
            <Stack flex={1}>
              <Controller
                control={form.control}
                name={`${path}.methods.${gradeMethodIndex}.select_list_id`}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <AssessmentGradeSelect
                      disabled={disabled}
                      isAdmin={isAdmin}
                      schoolId={schoolId}
                      selectedGradeId={field.value || undefined}
                      onSelectGradeId={field.onChange}
                      placeholder={$t({ id: 'assessments-GradePlaceholder' })}
                      error={fieldState.error}
                      requiredLabel="required"
                      {...field}
                    />
                  );
                }}
              />
            </Stack>
          )}
          {scoreMethodIndex !== -1 && (
            <NumberInputContainer>
              <Controller
                control={form.control}
                name={`${path}.methods.${scoreMethodIndex}.score_out_of`}
                rules={{
                  min: 1,
                  max: 999,
                }}
                render={({ field, fieldState }) => {
                  const errorMessage = getControllerErrorText(
                    fieldState.error,
                    { min: 1, max: 999 },
                    $t,
                  );
                  return (
                    <FormTextField
                      type="number"
                      inputProps={{
                        maxLength: 3,
                      }}
                      disabled={disabled}
                      label={$t({ id: 'assessments-ScorePlaceholder' })}
                      {...field}
                      helperText={errorMessage}
                      error={!!fieldState.error}
                    />
                  );
                }}
              />
            </NumberInputContainer>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const NumberInputContainer = styled(Stack)(() => ({
  flex: 1,
  'input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  'input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  'input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
}));

const generateEmptyAssessmentMethod = (v: AssessmentMethodType): AssessmentMethod => {
  switch (v) {
    case AssessmentMethodType.Comment:
      return {
        method_type: AssessmentMethodType.Comment,
      };
    case AssessmentMethodType.Grade:
      return {
        method_type: AssessmentMethodType.Grade,
        select_list_id: '',
        select_list_name: '',
      };
    case AssessmentMethodType.Score:
      return {
        method_type: AssessmentMethodType.Score,
        score_out_of: null,
      };
  }
};
