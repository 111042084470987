import { CUSTOM_FIELDS_BOOLEAN_OPTIONS } from '@schooly/constants';
import { SuggestedChangeDataSet } from '@schooly/constants';
import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LongStringWithTooltip } from '../../../../components/uikit-components/LongStringWithTooltip/LongStringWithTooltip';
import { useProfile } from '../../../../context/profile/useProfile';
import ProfileProperty from '../ProfileProperty';
import { CustomFieldBooleanModal } from './CustomFieldBooleanModal';
import { CustomFieldPropertyProps } from './CustomFieldPropertyProps';

export const CustomFieldBooleanProperty: FC<CustomFieldPropertyProps> = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
  value,
  hideSuggestedChanges,
  onClick,
  onClose,
  ...field
}) => {
  const { hasEditingPermission } = useProfile();
  const { $t } = useIntl();

  const booleanName = useMemo(() => {
    let numberValue: 1 | 0 | undefined = undefined;

    if (typeof value?.value === 'boolean') {
      numberValue = value?.value === true ? 1 : 0;
    }

    const textId = CUSTOM_FIELDS_BOOLEAN_OPTIONS.find(
      ({ value }) => value === numberValue,
    )?.labelTextId;

    return textId ? $t({ id: textId }) : textId;
  }, [$t, value]);

  const handleClose = (fn: () => void) => {
    fn();
    onClose?.();
  };

  return (
    <ProfileProperty
      value={booleanName}
      canEdit={hasEditingPermission}
      params={{ size, iconColor, color }}
      renderLabel={() => <LongStringWithTooltip>{field.label}</LongStringWithTooltip>}
      valueText={field.label}
      editValueText={field.label}
      dataSet={SuggestedChangeDataSet.Telephones}
      hideSuggestedChanges={hideSuggestedChanges}
      modalController={({ isOpen, hide }) => (
        <CustomFieldBooleanModal
          isOpen={isOpen}
          onClose={() => handleClose(hide)}
          field={field}
          value={value}
        />
      )}
      onClick={onClick}
    />
  );
};
