import {
  AssessmentEntriesForGroup,
  AssessmentMethodType,
  AssessmentsGrade,
  SORT_DIRECTION,
  useGetAssessmentsForGroupEntriesQuery,
  useGetAssessmentsForGroupQuery,
  useGetAssessmentsGradesForSchoolQuery,
  useGetGroupDetailsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useSelectIdsContext } from '@schooly/utils/bulk-actions';
import { useMemo } from 'react';

import { useGroup } from '../context/groups/useGroup';

type UseAssessmentsMarkbookProps = {
  onUpdateEntries?: (e: AssessmentEntriesForGroup) => void;
};

export const useAssessmentsMarkbook = ({ onUpdateEntries }: UseAssessmentsMarkbookProps) => {
  const { groupId = '', group: { validity } = {}, fetching: isCurrentGroupFetching } = useGroup();

  const dateFrom = validity?.date_from ?? validity?.from_school_year?.start;
  const dateTo = validity?.date_to ?? validity?.to_school_year?.end;

  const {
    data,
    isFetching: isGroupFetching,
    error: errorGroup,
  } = useGetGroupDetailsQuery(
    { id: groupId ?? '', date_from: dateFrom, date_to: dateTo },
    { enabled: Boolean(groupId && dateFrom && dateTo && !isCurrentGroupFetching) },
  );

  const group = data?.group;

  const { selectedIds } = useSelectIdsContext();
  const { schoolId = '' } = useAuth();

  const {
    data: assessments = [],
    isLoading: isAssessmentsLoading,
    error: errorAssessments,
  } = useGetAssessmentsForGroupQuery(
    {
      groupId,
      sort: [
        { columnTextId: 'assessment_date', direction: SORT_DIRECTION.ASC },
        { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
      ],
    },
    { enabled: !!groupId, refetchOnMount: 'always' },
  );

  const {
    data: entries,
    isLoading: isEntriesLoading,
    error: errorEntries,
    refetch,
  } = useGetAssessmentsForGroupEntriesQuery(
    {
      assessment_ids: assessments.map((a) => a.id),
      group_id: groupId,
    },
    {
      enabled: !!groupId && !!assessments?.length,
      refetchOnMount: 'always',
      onSuccess: (r) => (r ? onUpdateEntries?.(r) : undefined),
    },
  );

  const assessmentListIds = useMemo(
    () =>
      assessments.reduce<string[]>((acc, assessment) => {
        const listId = assessment.methods.find(
          (method) => method.method_type === AssessmentMethodType.Grade,
        )?.select_list_id;

        return !listId || acc.includes(listId) ? acc : [...acc, listId];
      }, []),
    [assessments],
  );

  const fetching = isGroupFetching || isAssessmentsLoading || isEntriesLoading;

  const {
    data: grades,
    isInitialLoading: isGradesLoading,
    refetch: refetchGrades,
  } = useGetAssessmentsGradesForSchoolQuery(
    { schoolId, listIds: assessmentListIds },
    {
      enabled: Boolean(schoolId && !fetching && assessmentListIds.length),
    },
  );

  const error = errorGroup || errorAssessments || errorEntries;

  const students = group?.students;

  const lists = useMemo(
    () =>
      grades?.reduce<Record<string, AssessmentsGrade>>(
        (acc, list) => ({ ...acc, [list.id]: list }),
        {},
      ),
    [grades],
  );

  const filteredAssessments = useMemo(() => {
    if (!assessments) return assessments;

    if (selectedIds === 'all' || selectedIds.size === 0) return assessments;

    return assessments.filter((a) => selectedIds.has(a.id));
  }, [selectedIds, assessments]);

  return {
    // Group details
    group,
    isGroupFetching,
    errorGroup,
    dateFrom,
    dateTo,
    selectedIds,
    // Assessments for group
    assessments: filteredAssessments,
    isAssessmentsLoading,
    errorAssessments,

    // Select lists
    lists,
    fetchingLists: isGradesLoading,
    requestSelectLists: refetchGrades,

    // Assessment entries for group
    entries,
    isEntriesLoading,
    errorEntries,

    // other
    students,
    fetching,
    error,
    request: refetch,
  };
};
