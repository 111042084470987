import { Box, Icon, IconButton, Stack } from '@mui/material';
import { exportReport } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { Report, ReportStatuses } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { DATE_FORMAT_SHORT_MOMENT } from '@schooly/constants';
import { DownloadIcon, Spin } from '@schooly/style';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { useProfile } from '../../../context/profile/useProfile';
import useAppLocation from '../../../hooks/useAppLocation';
import { downloadFile } from '../../../utils/downloadFile';
import { StatusCell } from '../../uikit/StatusCell/StatusCell';
import { GridRowDate, GridRowItem, GridRowName, GridRowStyled } from '../Grid/Grid';

export interface ListViewReportsRowProps {
  report: Report;
  relationId: string;
}

export const ListViewReportsRow: React.FC<ListViewReportsRowProps> = ({ report, relationId }) => {
  const location = useAppLocation();
  const isPublished = report.report_status === ReportStatuses.Published;
  const { showError } = useNotifications();
  const [fetching, setFetching] = useState(false);
  const { schoolId } = useAuth();
  const { user } = useProfile();

  const handleDownload = useCallback<React.MouseEventHandler>(
    async (e) => {
      e.preventDefault();

      if (fetching) {
        return;
      }
      setFetching(true);
      try {
        const data = await exportReport({ userId: user?.user_id, schoolId, reportId: report.id });
        if (data) {
          const filename = `${report.name}.${report.actual_publish_date}.pdf`;
          downloadFile(data, filename);
        }
      } catch (error) {
        showError(error as ApiError);
      }
      setFetching(false);
    },
    [
      fetching,
      report.actual_publish_date,
      report.id,
      report.name,
      schoolId,
      showError,
      user?.user_id,
    ],
  );

  return (
    <GridRowStyled
      sx={{
        '&:hover': {
          '& .downloadButton': {
            visibility: isPublished ? 'visible' : 'hidden',
          },
        },
      }}
    >
      <Link
        to={`/reports/${report.id}?recipient_id=${relationId}`}
        state={{
          prevPathname: `${location.pathname}${location.hash ?? ''}`,
        }}
      >
        <GridRowItem>
          <GridRowDate>
            {moment(report.scheduled_publish_date).format(DATE_FORMAT_SHORT_MOMENT).toUpperCase()}
          </GridRowDate>
          <GridRowName>{report.name}</GridRowName>
          <Stack direction="row" gap={2.5}>
            <Box sx={{ minWidth: 126 }} color="text.secondary">
              <StatusCell isPublished={isPublished} date={report.actual_publish_date} />
            </Box>
            <Stack alignItems="center" minWidth={28} minHeight={20}>
              {fetching ? (
                <Icon
                  fontSize="small"
                  sx={{
                    display: 'flex',
                    color: 'primary.main',
                  }}
                >
                  <Spin />
                </Icon>
              ) : (
                <IconButton
                  className="downloadButton"
                  sx={(theme) => ({
                    visibility: 'hidden',

                    [theme.breakpoints.down('lg')]: {
                      visibility: isPublished ? 'visible' : 'hidden',
                      color: theme.palette.text.secondary,
                    },
                  })}
                  onClick={handleDownload}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </GridRowItem>
      </Link>
    </GridRowStyled>
  );
};
