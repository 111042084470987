import { UserType } from '@schooly/api';
import { ParentIcon, StaffIcon, StudentIcon } from '@schooly/style';

export default function renderUserTypeIcon(userType: UserType, className?: string) {
  switch (userType) {
    case 'parent':
      return <ParentIcon className={className} />;
    case 'staff':
      return <StaffIcon className={className} />;
    case 'student':
      return <StudentIcon className={className} />;
    default:
      return null;
  }
}
