import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { Counter } from '@schooly/style';
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

interface HeaderProps {
  pageTitleTextId?: string;
  pageTitle?: React.ReactNode;
  pageTitleCounter?: number;
  buttonText?: string;
  buttonTextId?: string;
  buttonIcon?: React.ReactNode;
  buttonCounter?: number;
  buttonDisabled?: boolean;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  showActionButton?: boolean;
  actionsContent?: React.ReactNode;
  tooltipTitle?: React.ReactNode;
}

export const PageHeader: React.FC<PropsWithChildren<HeaderProps>> = ({
  pageTitleTextId,
  pageTitle,
  pageTitleCounter,
  buttonText,
  buttonTextId,
  buttonIcon,
  buttonCounter,
  buttonDisabled,
  showActionButton,
  onButtonClick,
  actionsContent,
  children,
  tooltipTitle = '',
}) => {
  const button = (
    <Button
      onClick={onButtonClick}
      startIcon={buttonIcon}
      disabled={buttonDisabled}
      data-test-id={'page-header-action'}
      sx={{
        minWidth: 215,
      }}
    >
      {buttonText || <FormattedMessage id={buttonTextId} />}
      {typeof buttonCounter === 'number' && (
        <Counter
          sx={(theme) => ({
            display: 'inline-block',
            lineHeight: theme.spacing(1.75),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.success.main,
            fontSize: theme.spacing(1.25),
          })}
        >
          {buttonCounter}
        </Counter>
      )}
    </Button>
  );
  return (
    <Stack
      data-test-id={'page-header'}
      sx={(theme) => ({
        justifyContent: 'space-between',
        flexDirection: 'row',
        columnGap: 3,
        [theme.breakpoints.down('md')]: {
          rowGap: 1.5,
          flexWrap: 'wrap',
        },
      })}
    >
      <Typography
        data-test-id={'page-header-title'}
        variant="subtitle1"
        sx={(theme) => ({
          minWidth: 220,
          [theme.breakpoints.down('md')]: {
            minWidth: 'unset',
          },
        })}
      >
        {pageTitle || <FormattedMessage id={pageTitleTextId} />}
        {typeof pageTitleCounter !== 'undefined' && (
          <Counter sx={{ verticalAlign: 'middle' }} data-test-id={'page-header-title-counter'}>
            {pageTitleCounter}
          </Counter>
        )}
      </Typography>
      <Stack
        sx={(theme) => ({
          flex: 1,
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            flexBasis: '100%',
            order: 3,
          },
        })}
      >
        {children}
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {actionsContent}
        {showActionButton && (
          <>
            {tooltipTitle ? (
              <Tooltip
                title={tooltipTitle}
                arrow={false}
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      padding: theme.spacing(1.25),
                      width: 200,
                    }),
                  },
                }}
              >
                <span>{button}</span>
              </Tooltip>
            ) : (
              button
            )}
          </>
        )}
      </Box>
    </Stack>
  );
};
