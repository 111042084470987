import { Button, Stack, styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

type FiltersContainerProps = PropsWithChildren<{
  onApply?: () => void;
}>;
export const FiltersContainer: FC<FiltersContainerProps> = ({ children, onApply }) => {
  const { $t } = useIntl();

  return (
    <FiltersContainerStyled data-test-id={'filters'}>
      {children}
      {onApply && (
        <Button size="small" onClick={onApply} data-test-id={'filters-apply'}>
          {$t({ id: 'filter-Apply' })}
        </Button>
      )}
    </FiltersContainerStyled>
  );
};

export const FiltersContainerStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  flex: 1,
  columnGap: theme.spacing(2),
  rowGap: theme.spacing(1),
  lineHeight: theme.spacing(3.75),
  alignItems: 'center',
  flexWrap: 'wrap',
}));
