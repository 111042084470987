import {
  StudentRolloverRequestParams,
  useGetRolloverAggregatedData,
  useGetSchoolReEnrollmentStatus,
} from '@schooly/api';
import {
  RolloverConfigurationContent,
  RolloverConfigurationContentProps,
} from '@schooly/components/annual-roll-over';
import { Loading, ModalLarge } from '@schooly/style';
import React, { FC } from 'react';

type RolloverConfigurationModalProps = {
  rolloverParams: StudentRolloverRequestParams;
  schoolId: string;
} & Omit<RolloverConfigurationContentProps, 'data' | 'reEnrollmentEnabled'>;

export const RolloverConfigurationModal: FC<RolloverConfigurationModalProps> = ({
  schoolId,
  rolloverParams,
  ...props
}) => {
  const { data, isFetching } = useGetRolloverAggregatedData(rolloverParams, {
    refetchOnMount: 'always',
    enabled: !!rolloverParams,
  });

  const { data: reEnrollmentData, isLoading: isReEnrollmentDataLoading } =
    useGetSchoolReEnrollmentStatus(schoolId ?? '', {
      enabled: !!schoolId,
      refetchOnMount: 'always',
    });

  return (
    <ModalLarge
      open
      sx={{
        '& .MuiDialog-paperFullWidth': {
          height: !isFetching && !data?.total ? 'auto' : 'inherit',
        },
      }}
    >
      {isFetching || !data || isReEnrollmentDataLoading || !reEnrollmentData ? (
        <Loading />
      ) : (
        <RolloverConfigurationContent
          reEnrollmentEnabled={reEnrollmentData.enabled}
          data={data}
          {...props}
        />
      )}
    </ModalLarge>
  );
};
