import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import {
  Application,
  ApplicationChild,
  DEFAULT_DATE_FORMAT,
  DuplicatesForUser,
  SchoolProperty,
  SchoolYear,
  useGetEnrollments,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { SelectOptionsArchivedIcon } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { usePrevious } from '@schooly/hooks/use-previous';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  CalendarIcon,
  CheckboxIcon,
  CheckIcon,
  CrossIcon,
  RollBackIcon,
  Spin,
} from '@schooly/style';
import { formatApplicationDate } from '@schooly/utils/application-helpers';
import { isNotEmpty } from '@schooly/utils/predicates';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DuplicatesCard } from '../../../components/common/PersonDuplicates/DuplicatesCard';
import { DuplicatesRow } from '../../../components/common/PersonDuplicates/DuplicatesRow';
import { RegistrationConflicts } from '../../../components/common/StudentRegistration/RegistrationConflicts';
import { useRegistrationValidation } from '../../../components/common/StudentRegistration/useRegistrationValidation';
import { validateStatusDateOrder } from '../../../components/common/StudentRegistration/utils';
import FormSelect2 from '../../../components/ui/Input/FormSelect2';
import { FormCheckboxStyled } from '../../../components/uikit-components/FormCheckbox/FormCheckbox.styled';
import {
  ModalContent,
  ModalFooter,
  ModalMain,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { ModalPeopleExtensionPanel } from '../../../components/uikit-components/Modal/ModalPeopleExtensionPanel';
import { ApplyStudentsForm } from '../../../context/applications/ApplicationsContext';
import { validateStatuses } from '../../../helpers/registrations';
import { getPropertiesByType } from '../../../helpers/school';
import { getUserFullName } from '../../../helpers/users';
import { useSchool } from '../../../hooks/useSchool';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { ApplicationAddStudentStatus } from './ApplicationAddStudentStatus';

export type StudentFormDefaultYears = Record<ApplicationChild['id'], SchoolYear>;
interface ApplicationAddStudentFormProps {
  handleSubmit: SubmitHandler<ApplyStudentsForm>;
  application: Application;
  onClose: () => void;
  duplicateRecords?: DuplicatesForUser[];
  defaultYears: StudentFormDefaultYears;
}
export const ApplicationAddStudentForm: FC<ApplicationAddStudentFormProps> = ({
  handleSubmit,
  application,
  onClose,
  duplicateRecords,
  defaultYears,
}) => {
  const { $t } = useIntl();
  const { permissions } = useAuth();
  const isAdmin = permissions?.includes('school_admin');
  const { schoolId = '', hasHouses, activeStudentStatuses } = useSchool();
  const { schoolYears } = useSchoolYears();
  const { schoolProperties: schoolStudentProperties } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const [sectionOpen, setSectionOpen] = useState<string>('');
  const currentChildIndex = application.children.findIndex((child) => child.id === sectionOpen);

  const [showReload, setShowReloadTrue, setShowReloadFalse] = useFlag();

  const duplicatesForCurrentChild = useMemo(() => {
    return duplicateRecords?.find((record) => record.id === sectionOpen)?.duplicates ?? [];
  }, [duplicateRecords, sectionOpen]);

  const { activePropertiesMap } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const { activeAgeGroups } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const { registrationConflicts, validateRegistrations } = useRegistrationValidation({
    schoolStatuses: activePropertiesMap['status'],
  });

  const [houseOptions, ageGroupOptions, schoolYearOptions] = useMemo(() => {
    return [
      !!activePropertiesMap?.house
        ? getPropertiesByType(activePropertiesMap.house, SchoolPropertyType.House)?.map((p) => ({
            value: p.id,
            label: p.name,
          }))
        : [],
      !!activeAgeGroups
        ? activeAgeGroups.map((g) => ({
            value: g.id,
            label: g.name,
          }))
        : [],
      (!!schoolYears &&
        schoolYears.map(({ id, name }) => ({
          value: id,
          label: name,
        }))) ??
        [],
    ];
  }, [activeAgeGroups, activePropertiesMap.house, schoolYears]);

  const hasHouseOptions = Boolean(houseOptions.length);

  const form = useForm<ApplyStudentsForm>({
    shouldUnregister: false,
    defaultValues: {
      children:
        application.children.map(({ id, given_name, last_name, preferred_start_date }) => {
          const defaultYear = defaultYears[id];
          return {
            childId: id,
            relationId: null,
            given_name,
            last_name,
            preferred_start_date,
            school_year_id: defaultYear ? defaultYear.id : undefined,
            house_property_id: undefined,
            age_group_property_id: undefined,
            statuses: [
              {
                school_property_id: '',
                applies_from: moment().format(DEFAULT_DATE_FORMAT),
                applies_to: '',
              },
            ],
          };
        }) ?? [],
    },
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: `children`,
  });

  const onSubmit = useCallback<SubmitHandler<ApplyStudentsForm>>(
    (data) => {
      const error = form
        .getValues()
        .children.some(
          (f) =>
            !f.school_year_id || !f.statuses.some((s) => s.applies_from && s.school_property_id),
        );

      if (error) return;

      handleSubmit(data);
    },
    [form, handleSubmit],
  );
  const currentStudentRelationId = form.watch(`children.${currentChildIndex}.relationId`);
  const selectedYearId = form.watch(`children.${currentChildIndex}.school_year_id`);
  const selectedAgeGroupId = form.watch(`children.${currentChildIndex}.age_group_property_id`);
  const selectedHouseId = form.watch(`children.${currentChildIndex}.house_property_id`);
  const selectedStatuses = form.watch(`children.${currentChildIndex}.statuses`);
  const selectedSchoolYear = schoolYears.find(({ id }) => id === selectedYearId);

  const {
    data,
    refetch,
    isLoading,
    isFetching: isRegistrationFetching,
  } = useGetEnrollments(
    { schoolId: schoolId || '', studentId: currentStudentRelationId || '' },
    { enabled: !!schoolId && !!currentStudentRelationId },
  );
  const registrations = useMemo(() => data?.enrollments ?? [], [data?.enrollments]);
  const prevRegistrations = usePrevious(registrations);
  const isLoadingRegistrations = isLoading && !!currentStudentRelationId;

  const handleReload = useCallback(async () => {
    const { data } = await refetch();

    const formValues = form.getValues();

    const conflicts = validateRegistrations({
      form: formValues.children[currentChildIndex],
      registrations: data?.enrollments || [],
      yearEndDate: selectedSchoolYear?.end,
    });

    if (!conflicts.length) setShowReloadFalse();
  }, [
    currentChildIndex,
    form,
    refetch,
    selectedSchoolYear?.end,
    setShowReloadFalse,
    validateRegistrations,
  ]);

  useEffect(() => {
    if (!sectionOpen && application.children.length) {
      setSectionOpen(application.children[0].id);
    }
  }, [application.children, sectionOpen]);

  useEffect(() => {
    if (form.formState.isSubmitted && sectionOpen) {
      form.trigger('children');
    }
  }, [form, form?.formState.isSubmitted, sectionOpen]);

  //checks registration conflicts on form change
  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (!name) return;

      const schoolYear = schoolYears.find(
        ({ id }) => id === value.children?.[currentChildIndex]?.school_year_id,
      );
      if (!schoolYear) return;

      const nonEmptyStatuses = value.children?.[currentChildIndex]?.statuses?.length
        ? value.children?.[currentChildIndex]?.statuses?.filter(isNotEmpty)
        : null;
      if (!nonEmptyStatuses?.length) return;

      const currentName = name.split('.').at(2);

      if (currentName === 'statuses' || currentName === 'school_year_id') {
        const yearErrors = validateStatusDateOrder({
          selectedStatuses: nonEmptyStatuses,
          schoolYear,
          studentStatues: activeStudentStatuses,
        });

        yearErrors.forEach((d, i) => {
          const path = `children.${currentChildIndex}.statuses.${i}.applies_from` as const;

          if (d?.applies_from) {
            form.setError(path, {
              type: 'validate',
              message: $t({ id: d.applies_from.id }, d.applies_from.values),
            });
          } else {
            form.clearErrors(path);
          }
        });
      }

      validateRegistrations({
        form: { ...value?.children?.[currentChildIndex], statuses: nonEmptyStatuses },
        registrations,
        yearEndDate: schoolYear.end,
      });
    });

    return () => subscription.unsubscribe();
  }, [
    $t,
    activePropertiesMap,
    activeStudentStatuses,
    currentChildIndex,
    form,
    registrations,
    schoolYears,
    validateRegistrations,
  ]);

  //checks registration conflicts on selected duplicate student change
  useEffect(() => {
    if (!selectedSchoolYear || isEqual(prevRegistrations, registrations)) return;

    const nonEmptyStatuses = selectedStatuses?.length ? selectedStatuses.filter(isNotEmpty) : null;
    if (!nonEmptyStatuses?.length) return;

    const formValues = form.getValues();

    validateRegistrations({
      form: { ...formValues?.children?.[currentChildIndex], statuses: nonEmptyStatuses },
      registrations,
      yearEndDate: selectedSchoolYear?.end,
    });
  }, [
    currentChildIndex,
    form,
    prevRegistrations,
    registrations,
    selectedSchoolYear,
    selectedStatuses,
    validateRegistrations,
  ]);

  const renderExistingRecordSwitch = useCallback(
    (checked: boolean, onToggle: () => void) => {
      return (
        <Stack justifyContent="center" ml={1}>
          <FormControlLabel
            sx={(theme) => ({
              m: 0,
              '.MuiFormControlLabel-label': { ...theme.typography.h3, color: 'unset' },
            })}
            control={
              checked && isLoadingRegistrations ? (
                <Spin height={20} width={30} mr={1} />
              ) : (
                <Switch
                  checked={checked}
                  onChange={onToggle}
                  sx={{
                    ' .MuiSwitch-track, .Mui-checked+.MuiSwitch-track': {
                      opacity: 1,
                      backgroundColor: 'background.paper',
                    },
                    '&:hover': {
                      color: 'primary.main',
                      ' .MuiSwitch-track, .Mui-checked+.MuiSwitch-track': {
                        opacity: 1,
                      },
                    },
                  }}
                />
              )
            }
            label={$t({ id: 'action-UseExistingRecord' })}
          />
        </Stack>
      );
    },
    [$t, isLoadingRegistrations],
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalHeader title={$t({ id: 'applications-RegistrationInformation' })}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>

        {fields.map((c, index) => {
          const field = form.getValues(`children.${index}`);
          const isOpenSection = sectionOpen === c.childId;
          const hasChanges = Boolean(
            form.formState.dirtyFields.children && form.formState.dirtyFields.children[index],
          );

          const selectedSchoolYear = schoolYears?.find(({ id }) => id === field.school_year_id);

          const statusErrors = validateStatuses(
            field.statuses ?? [],
            SchoolUserRole.Student,
            schoolStudentProperties.filter((p) => 'type' in p) as SchoolProperty[],
            selectedSchoolYear,
          );
          const error =
            form.formState.isSubmitted &&
            !isOpenSection &&
            (!field.school_year_id ||
              !field.statuses.some((s) => s.applies_from && s.school_property_id));

          return (
            <ModalPeopleExtensionPanel
              key={c.id}
              titleId={getUserFullName(c)}
              addActionId="applications-AddInformation"
              editActionId="applications-EditInformation"
              active={isOpenSection}
              onAddClick={() => {
                setSectionOpen(c.childId);
              }}
              forceShowEditButton={hasChanges}
              sidebarContent={null}
              headerMode
              errorId={error ? $t({ id: 'applications-FormError' }) : undefined}
              activePanelStyleProps={{
                paddingTop: 1,
                paddingBottom: 1.5,
              }}
              activePanelDetails={
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={(theme) => ({
                    background: theme.palette.common.lightBg,
                    borderRadius: `${theme.shape.borderRadiusSm}px`,
                    py: 1.5,
                    px: 1,
                  })}
                >
                  <Icon>
                    <CalendarIcon />
                  </Icon>
                  <Typography variant="h3" color="text.primary">
                    <FormattedMessage id="applications-PreferredStartingDate" />
                  </Typography>
                  <Typography variant="h3">
                    {formatApplicationDate(c.preferred_start_date)}
                  </Typography>
                </Stack>
              }
            >
              <ModalContent
                withBorderBottom
                active
                flat
                sx={{ pt: 0, px: 2.5, ' .form-control:disabled': { borderColor: 'common.light3' } }}
              >
                <Stack gap={2} mb={1}>
                  {Boolean(duplicatesForCurrentChild.length) && (
                    <DuplicatesCard
                      message={
                        duplicatesForCurrentChild.length < 2
                          ? 'applications-StudentDuplicate'
                          : 'applications-StudentDuplicate-plural'
                      }
                    >
                      {duplicatesForCurrentChild.map((duplicate) => {
                        const isSelected = duplicate.relation_id === currentStudentRelationId;
                        const onToggle = () => {
                          if (isSelected) {
                            form.setValue(`children.${index}.relationId`, null);
                          } else {
                            form.setValue(`children.${index}.relationId`, duplicate.relation_id);
                          }
                        };
                        return (
                          <DuplicatesRow
                            duplicate={duplicate}
                            endAction={renderExistingRecordSwitch(isSelected, onToggle)}
                            userType={'student'}
                            sxProps={{
                              border: `1px solid transparent`,
                              '&:hover': {
                                borderColor: `warning.main`,
                                ' .MuiTypography-root, .MuiIconButton-root': {
                                  color: 'primary.main',
                                },
                              },
                            }}
                          />
                        );
                      })}
                    </DuplicatesCard>
                  )}

                  <FormSelect2
                    name={`children.${index}.school_year_id`}
                    labelTextId="students-SchoolYear"
                    options={schoolYearOptions}
                    rules={{ required: true }}
                  />
                  {hasHouses && (
                    <FormSelect2
                      name={`children.${index}.house_property_id`}
                      labelTextId="schoolProperty-House"
                      options={houseOptions}
                      disabled={!hasHouseOptions}
                      endIcon={
                        !hasHouseOptions ? (
                          <SelectOptionsArchivedIcon
                            isAdmin={isAdmin}
                            type={SchoolPropertyType.House}
                          />
                        ) : undefined
                      }
                    />
                  )}
                  <Stack flexDirection="row" gap={1}>
                    {!!ageGroupOptions.length && (
                      <FormSelect2
                        name={`children.${index}.age_group_property_id`}
                        labelTextId="schoolProperty-AgeGroup"
                        options={ageGroupOptions}
                      />
                    )}
                    {selectedAgeGroupId && (
                      <Controller
                        name={`children.${index}.half_day`}
                        control={form.control}
                        render={({ field }) => (
                          <FormCheckboxStyled
                            sx={{ width: 140, whiteSpace: 'nowrap' }}
                            withBorder
                            control={
                              <Checkbox
                                name={field.name}
                                checked={field.value}
                                onChange={field.onChange}
                                checkedIcon={<CheckboxIcon className="reset-svg-currentColor" />}
                              />
                            }
                            label={$t({ id: 'students-HalfDay' })}
                          />
                        )}
                      />
                    )}
                  </Stack>

                  <ApplicationAddStudentStatus key={index} errors={statusErrors} index={index} />
                </Stack>
                {!!registrationConflicts?.length && (
                  <RegistrationConflicts
                    selectedAgeGroupId={selectedAgeGroupId}
                    selectedHouseId={selectedHouseId}
                    onRegistrationClick={setShowReloadTrue}
                    registrationConflicts={registrationConflicts}
                    getRegistrationLink={(id) =>
                      `/students/${currentStudentRelationId}/registration?id=${id}`
                    }
                    endIcon={
                      showReload && (
                        <IconButton disabled={isRegistrationFetching} onClick={handleReload}>
                          <RollBackIcon />
                        </IconButton>
                      )
                    }
                  />
                )}
              </ModalContent>
            </ModalPeopleExtensionPanel>
          );
        })}
        <ModalMain />

        {Boolean(fields.length) && (
          <ModalFooter active>
            <Button
              type="submit"
              endIcon={<CheckIcon />}
              disabled={Boolean(registrationConflicts?.length || isLoadingRegistrations)}
              data-test-id="modal-submit"
            >
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        )}
      </form>
    </FormProvider>
  );
};
