import { debounce } from '@mui/material';
import {
  ApiError,
  ConsentFormShort,
  DEFAULT_DATE_FORMAT_FNS,
  GET_SIGN_UP_QUERY,
  isSignUpRegular,
  isSignUpSlots,
  isSignUpWithEvent,
  removeObjectEmptyArrays,
  removeObjectEmptyArrayValues,
  SignUp,
  SignUpActionRequest,
  SignUpFormRequest,
  SignUpStatuses,
  SignUpType,
  useCreateConsentFormMutation,
  useCreateSignUpMutation,
  useDownloadSignUpMutation,
  useGetSignUpQuery,
  useRemoveConsentFormMutation,
  useRemoveSignUpMutation,
  useSignActionMutation,
  useUpdateConsentFormMutation,
  useUpdateSignUpMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { removeObjectUndefinedOrNullValues } from '@schooly/utils/remove-object-undefined-or-null-values';
import { useQueryClient } from '@tanstack/react-query';
import { addDays, isBefore, parse } from 'date-fns';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateConsentForm } from '../../components/common/ConsentForms/ConsentForm';
import { downloadFile } from '../../utils/downloadFile';

export const SignUpTypeLabels = {
  [SignUpType.Regular]: 'eventSignUps-type-Regular',
  [SignUpType.Slots]: 'eventSignUps-type-Slots',
};

export interface SignUpForm extends Omit<SignUpFormRequest, 'type' | 'title'> {
  event?: SignUp['event'];
  withEvent: boolean;
  type?: SignUpFormRequest['type'] | null;
  title?: SignUpFormRequest['title'];
  consent_form?: SignUp['consent_form'];
}

export interface SignUpContextProps {
  signUp?: SignUp;
  query: string;
  debouncedQuery: string;
  canEdit: boolean;
  canView: boolean;
  canCreate: boolean;
  canDelete: boolean;
  isStarted: boolean;
  isEnded: boolean;
  isActive: boolean;
  fetching: boolean;
  removing: boolean;
  updating: boolean;
  publishing: boolean;
  creating: boolean;
  downloading: boolean;

  setQuery: Dispatch<SetStateAction<SignUpContextProps['query']>>;
  create: (
    e: SignUpForm & { consentFormPayload?: CreateConsentForm },
  ) => Promise<{ id: string } | undefined>;
  remove: (id: string) => Promise<void>;
  update: (
    e: SignUpForm & { notify_parents: boolean; consentFormPayload?: CreateConsentForm },
  ) => Promise<{ id: string } | undefined>;
  updateAndPublish: (
    e: SignUpForm & { notify_parents: boolean; consentFormPayload?: CreateConsentForm },
  ) => Promise<{ id: string } | undefined>;
  updateList: () => void;
  submitAction: (params: Omit<SignUpActionRequest, 'schoolId'>) => Promise<void>;
  clone: () => void;
  download: () => void;
}

export const SignUpContext = createContext<SignUpContextProps>({
  signUp: undefined,
  query: '',
  debouncedQuery: '',
  canEdit: false,
  canView: false,
  canCreate: false,
  canDelete: false,
  isStarted: false,
  isEnded: false,
  isActive: false,
  fetching: false,
  removing: false,
  updating: false,
  publishing: false,
  creating: false,
  downloading: false,

  setQuery: () => {},
  create: async () => undefined,
  remove: async () => {},
  update: async () => undefined,
  updateAndPublish: async () => undefined,
  updateList: () => undefined,
  submitAction: async () => undefined,
  clone: () => {},
  download: () => {},
});

export interface WithSignUpProps extends PropsWithChildren {
  signUp?: SignUp;
}

export const SEARCH_DEBOUNCE_TIME = 300;

export const WithSignUp: FC<WithSignUpProps> = ({ signUp: propSignUp, children }) => {
  const { id: paramsId } = useParams<'id'>();
  const navigate = useNavigate();
  const { showError } = useNotifications();
  const queryClient = useQueryClient();
  const { schoolId, permissions } = useAuth();
  const [publishing, setPublishing] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  const setDebouncedQueryRef = useMemo<SignUpContextProps['setQuery']>(
    () => debounce(setDebouncedQuery, SEARCH_DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    setDebouncedQueryRef(query);
  }, [query, setDebouncedQueryRef]);

  const invalidateSignUpQueries = useInvalidateListQueriesFor('signup');
  const invalidateEventQueries = useInvalidateListQueriesFor('event');
  const createSignUp = useCreateSignUpMutation();
  const updateSignUp = useUpdateSignUpMutation();
  const removeSignUp = useRemoveSignUpMutation();
  const downloadSignUp = useDownloadSignUpMutation();
  const bulkStatusChangeMutation = useSignActionMutation();
  const createConsentForm = useCreateConsentFormMutation();
  const updateConsentForm = useUpdateConsentFormMutation();
  const removeConsentForm = useRemoveConsentFormMutation();

  const updateList = useCallback(() => {
    invalidateSignUpQueries();
    invalidateEventQueries();
  }, [invalidateEventQueries, invalidateSignUpQueries]);

  const { data, isFetching: fetching } = useGetSignUpQuery(paramsId ?? '', {
    enabled: Boolean(!propSignUp && paramsId),
    refetchOnMount: 'always',
  });
  const signUp = propSignUp ?? data;
  const id = signUp?.id ?? paramsId;

  const canEdit = Boolean(signUp?.can_be_edited) && signUp?.status !== SignUpStatuses.Closed;
  const canView = permissions.includes('event_viewer');
  const canCreate = permissions.includes('event_creator') || permissions.includes('event_manager');
  const canDelete = Boolean(signUp?.id && signUp?.status === SignUpStatuses.Draft);

  const [isStarted, isEnded] = useMemo(() => {
    if (!signUp) {
      return [false, false];
    }

    return [
      signUp.status === SignUpStatuses.Open,
      isBefore(addDays(parse(signUp.end, DEFAULT_DATE_FORMAT_FNS, new Date()), 1), new Date()),
    ];
  }, [signUp]);

  const isActive = useMemo(() => isStarted && !isEnded, [isEnded, isStarted]);

  const getFormRequestParams = useCallback<
    (signUp: SignUpForm & { notify_parents?: boolean }) => SignUpFormRequest | undefined
  >(({ consent_form, ...signUp }) => {
    const signUpForm = removeObjectUndefinedOrNullValues(signUp);

    if (!signUpForm.type || !signUpForm.title || !signUpForm.end) {
      return;
    }

    const params: SignUpFormRequest = {
      title: signUpForm.title!,
      type: signUpForm.type!,
      end: signUpForm.end,
    };

    if (signUpForm.notify_parents) {
      params.notify_parents = true;
    }

    if (signUpForm.withEvent) {
      if (!signUpForm.event) {
        return;
      }

      params.event_id = signUpForm.event.id;
    } else {
      if (!signUpForm.description || !signUpForm.invitee_type || !signUpForm.criteria) {
        return;
      }

      const { group, ...criteria } = signUp.criteria ?? {};

      params.description = signUpForm.description;
      params.invitee_type = signUpForm.invitee_type;
      params.criteria = criteria
        ? removeObjectEmptyArrayValues({
            ...criteria,
            group_ids: group?.map(({ id }) => id),
          })
        : undefined;
    }

    switch (signUpForm.type) {
      case SignUpType.Regular:
        params.places = signUpForm.places || null;
        break;
      case SignUpType.Slots:
        params.duration = signUpForm.duration;
        break;
    }

    return params;
  }, []);

  const remove = useCallback<SignUpContextProps['remove']>(
    async (id) => {
      try {
        await removeSignUp.mutateAsync(id);
      } catch (error) {
        showError(error as ApiError);
      }
    },
    [removeSignUp, showError],
  );

  const updateSignUpConsentForm = useCallback(
    async ({
      signUpId,
      consentFormPayload,
      signUpForm,
      eventConsentForm,
    }: {
      signUpId: string;
      signUpForm: SignUpForm;
      consentFormPayload?: CreateConsentForm;
      eventConsentForm?: ConsentFormShort;
    }) => {
      const consentForm = consentFormPayload ?? signUpForm.consent_form;

      if (consentForm && eventConsentForm) {
        await removeConsentForm.mutateAsync(eventConsentForm.id);
      }

      if (!consentForm && eventConsentForm) {
        await updateConsentForm.mutateAsync({
          id: eventConsentForm.id,
          reference_id: signUpId,
          reference_type: 'signup',
          description: eventConsentForm.description,
        });
      }

      if (consentFormPayload) {
        await createConsentForm.mutateAsync({
          reference_id: signUpId,
          reference_type: 'signup',
          description: consentFormPayload.description,
        });
      }
    },
    [createConsentForm, removeConsentForm, updateConsentForm],
  );

  const create = useCallback<SignUpContextProps['create']>(
    async ({ consentFormPayload, ...signUpForm }) => {
      if (!schoolId) return;

      const params = getFormRequestParams(signUpForm);

      if (!params) {
        return;
      }

      try {
        const res = await createSignUp.mutateAsync({ ...params, schoolId });

        if (!res) return;

        await updateSignUpConsentForm({
          signUpId: res.id,
          consentFormPayload,
          signUpForm,
          eventConsentForm: signUpForm?.event?.consent_form ?? undefined,
        });

        return res;
      } catch (error) {
        showError(error as ApiError);
      }
    },
    [createSignUp, updateSignUpConsentForm, getFormRequestParams, schoolId, showError],
  );

  const update = useCallback<SignUpContextProps['update']>(
    async ({ consentFormPayload, ...signUpForm }) => {
      if (!schoolId || !id) return;

      const params = getFormRequestParams(signUpForm);

      if (!params) {
        return;
      }

      try {
        const res = await updateSignUp.mutateAsync({ ...params, id });

        await updateSignUpConsentForm({
          signUpId: id,
          consentFormPayload,
          signUpForm,
          eventConsentForm: signUpForm.event?.consent_form ?? undefined,
        });

        return res;
      } catch (error) {
        showError(error as ApiError);
      }
    },
    [updateSignUpConsentForm, getFormRequestParams, id, schoolId, showError, updateSignUp],
  );

  const submitAction = useCallback<SignUpContextProps['submitAction']>(
    async (params) => {
      if (!schoolId) return;

      setPublishing(true);

      try {
        await bulkStatusChangeMutation.mutateAsync({ schoolId, ...params });
      } catch (error) {
        showError(error as ApiError);
      }

      setPublishing(false);
    },
    [bulkStatusChangeMutation, schoolId, showError],
  );

  const updateAndPublish = useCallback<SignUpContextProps['updateAndPublish']>(
    async ({ notify_parents, consentFormPayload, ...signUpForm }) => {
      if (!schoolId) return;

      const params = getFormRequestParams(signUpForm);

      if (!params) {
        return;
      }

      setPublishing(true);

      try {
        const res = id
          ? await updateSignUp.mutateAsync({ ...params, id })
          : await createSignUp.mutateAsync({ ...params, schoolId });

        if (!res) return;

        const signUpId = id ? id : res.id;

        if (signUpId && consentFormPayload && !signUp?.consent_form) {
          await createConsentForm.mutateAsync({
            reference_id: signUpId,
            reference_type: 'signup',
            description: consentFormPayload.description,
          });
        }

        await submitAction({
          action: 'open',
          sign_up_id: signUpId,
          notify_parents: Boolean(notify_parents),
        });

        if (id) {
          queryClient.invalidateQueries([GET_SIGN_UP_QUERY, id]);
        }

        return { id: signUpId };
      } catch (error) {
        showError(error as ApiError);
      } finally {
        setPublishing(false);
      }
    },
    [
      createConsentForm,
      createSignUp,
      getFormRequestParams,
      id,
      queryClient,
      schoolId,
      showError,
      signUp,
      submitAction,
      updateSignUp,
    ],
  );

  const clone = useCallback(() => {
    if (!signUp) {
      return;
    }

    navigate(
      {
        pathname: `/signups/new`,
      },
      {
        state: {
          replace: true,
          initialState: {
            title: '',
            event: isSignUpWithEvent(signUp) ? signUp.event : undefined,
            description: signUp.description,
            type: signUp.type,
            end: '',
            places: isSignUpRegular(signUp) ? signUp.places : undefined,
            duration: isSignUpSlots(signUp) ? signUp.duration : undefined,
            invitee_type: signUp.invitee_type,
            criteria: signUp.criteria ? removeObjectEmptyArrays(signUp.criteria) : undefined,
          },
        },
      },
    );
  }, [navigate, signUp]);

  const download = useCallback<SignUpContextProps['download']>(async () => {
    if (!signUp?.id || downloadSignUp.isLoading) {
      return;
    }

    try {
      const data = await downloadSignUp.mutateAsync(signUp.id);
      if (data) {
        const filename = `${signUp.title || 'sign_up'}.csv`;
        downloadFile(data, filename);
      }
    } catch (error) {
      showError(error as ApiError);
    }
  }, [downloadSignUp, showError, signUp?.id, signUp?.title]);

  const isUpdating = updateSignUp.isLoading || createConsentForm.isLoading;
  const isCreating = createSignUp.isLoading || createConsentForm.isLoading;
  const isRemoving = removeSignUp.isLoading || removeConsentForm.isLoading;

  const value = useMemo<SignUpContextProps>(
    () => ({
      signUp,
      query,
      debouncedQuery,
      canEdit,
      canView,
      canCreate,
      canDelete,
      isStarted,
      isEnded,
      isActive,
      fetching,
      removing: isRemoving,
      updating: isUpdating && !publishing,
      creating: isCreating && !publishing,
      downloading: downloadSignUp.isLoading,
      publishing,

      setQuery,
      create,
      remove,
      update,
      updateAndPublish,
      updateList,
      submitAction,
      clone,
      download,
    }),
    [
      canCreate,
      canDelete,
      canEdit,
      canView,
      clone,
      create,
      debouncedQuery,
      download,
      downloadSignUp.isLoading,
      fetching,
      isActive,
      isCreating,
      isEnded,
      isRemoving,
      isStarted,
      isUpdating,
      publishing,
      query,
      remove,
      signUp,
      submitAction,
      update,
      updateAndPublish,
      updateList,
    ],
  );

  return <SignUpContext.Provider value={value}>{children}</SignUpContext.Provider>;
};

export const useSignUp = () => {
  return useContext(SignUpContext);
};
