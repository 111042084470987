import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import { EmptyFolderSvg, MarkIcon } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export interface MainGridNoResultsStubProps {
  showFiltersInfo?: boolean;
  textId: string;
  typography?: TypographyProps;
}

export const MainGridNoResultsStub: FC<MainGridNoResultsStubProps> = ({
  textId,
  typography,
  showFiltersInfo = true,
}) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      data-test-id={'page-data-no-results'}
    >
      <Box sx={{ mb: 2, '& svg': { width: 300, height: 300 } }}>
        <EmptyFolderSvg />
      </Box>

      <Typography variant="h1" textAlign="center" mb={3} whiteSpace="pre-wrap" {...typography}>
        <FormattedMessage id={textId} />
      </Typography>

      <Typography variant="h3" mb={1}>
        <FormattedMessage id="stubs-MainGrid-NoResults-subtitle" />
      </Typography>

      {showFiltersInfo && (
        <Typography variant="h3">
          <FormattedMessage id="stubs-MainGrid-NoResults-cta-1" />{' '}
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={(theme) => ({
              width: 30,
              height: 30,
              fontSize: theme.spacing(2.5),
              display: 'inline-flex',
              verticalAlign: 'middle',
              border: theme.mixins.borderValue(),
              borderRadius: `${theme.shape.borderRadiusSm}px`,
              background: theme.palette.background.default,
            })}
          >
            <MarkIcon />
          </Stack>{' '}
          <FormattedMessage id="stubs-MainGrid-NoResults-cta-2" />
        </Typography>
      )}
    </Stack>
  );
};
