import React, { PropsWithChildren } from 'react';

import buildClassName from '../../../utils/buildClassName';

import './index.scss';

interface IProps extends PropsWithChildren {
  className?: string;
  contentClassName?: string;
  contentRef?: React.RefObject<HTMLDivElement>;
  footerClassName?: string;
  footerContent?: JSX.Element;
}

const FormWithFooter: React.FC<IProps> = ({
  className,
  contentClassName,
  contentRef,
  footerContent,
  footerClassName,
  children,
}) => (
  <div className={buildClassName('FormWithFooter', className)}>
    <div ref={contentRef} className={buildClassName('FormWithFooter__content', contentClassName)}>
      {children}
    </div>
    <div className={buildClassName('FormWithFooter__footer', footerClassName)}>{footerContent}</div>
  </div>
);

export default FormWithFooter;
