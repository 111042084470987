import { Box, Typography } from '@mui/material';
import { SimpleListResult } from '@schooly/api';
import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PersonCardSelectable from '../../../../components/common/PersonCard/PersonCardSelectable';
import { ModalContent } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalEmptyMainArea } from '../../../../components/uikit-components/Modal/ModalEmptyMainArea';
import { SelectedCriteriaList } from '../../../../components/uikit-components/SelectedCriteriaList/SelectedCriteriaList.styled';
import { useMessage } from '../../../../context/messages/useMessage';
import { getDisplayedCriteria } from '../../helpers';

export const MessagesCreateModalRecipientsParentsMain: FC = () => {
  const { formatMessage } = useIntl();
  const {
    parents,
    parentsCriteria,
    parentsByIndividualStudent,
    hasParents,
    notActualGroups,
    actions,
  } = useMessage();

  const handleStudentClick = useCallback(
    (relationId: string) => {
      const user = parentsByIndividualStudent?.find(
        ({ relation_id }) => relation_id === relationId,
      );

      if (!user) {
        return;
      }

      actions.removeParentByIndividualStudent(user);
    },
    [actions, parentsByIndividualStudent],
  );

  const handleParentClick = useCallback(
    (relationId: string) => {
      const user = parents?.find(({ relation_id }) => relation_id === relationId);

      if (!user) {
        return;
      }

      actions.removeParent(user);
    },
    [actions, parents],
  );

  const render = () => {
    if (!hasParents) {
      return <ModalEmptyMainArea label={formatMessage({ id: 'messages-Parents' }).toLowerCase()} />;
    }

    return (
      <>
        {Boolean(parentsCriteria?.length || parentsByIndividualStudent.length) && (
          <SelectedCriteriaList mt={2.5}>
            <Typography variant="h4">
              <FormattedMessage id="messages-ParentsOfStudents" />
            </Typography>
            <Box mt={1}>
              {getDisplayedCriteria({
                selectedCriteria: parentsCriteria,
                handleCriteriaClick: actions.removeParentsCriteria,
                handleGroupCriteriaClick: actions.removeParentsCriteriaMultiple,
                criteriaWithErrors: notActualGroups,
              })}
              {parentsByIndividualStudent?.map((user: SimpleListResult) => (
                <PersonCardSelectable
                  key={user.relation_id}
                  user={user}
                  userType="student"
                  onClick={handleStudentClick}
                  isStaticSelected
                  isListItem
                />
              ))}
            </Box>
          </SelectedCriteriaList>
        )}

        {Boolean(parents?.length) && (
          <Box mt={2.5}>
            <Typography variant="h4">
              <FormattedMessage id="messages-Parents" />
            </Typography>

            {parents.map((user: SimpleListResult) => (
              <PersonCardSelectable
                key={user.relation_id}
                user={user}
                userType="parent"
                isStaticSelected
                onClick={handleParentClick}
                isListItem
              />
            ))}
          </Box>
        )}
      </>
    );
  };

  return (
    <ModalContent sx={{ p: 0 }} active>
      {render()}
    </ModalContent>
  );
};
