import { Box, Stack, TextField } from '@mui/material';
import { AssessmentMethodType } from '@schooly/api';
import * as React from 'react';
import { FC } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { renderError } from '../../../../components/ui/Input/helpers';
import { AssessmentForm } from '../AssessmentCreateContent';
import { SCORE_OUT_OF_MAX } from '../useAssessmentCreateModal';
import { DeleteMethodButton } from './AssessmentsCreateModalSettings.styled';
import { AssessmentsCreateModalSettingsMethodType } from './AssessmentsCreateModalSettingsMethodType';

export interface AssessmentsScoreControlProps {
  autoFocus?: boolean;
  onMethodChange?: (methodType?: AssessmentMethodType) => void;
  onDelete?: () => void;
  index: number;
}

export const AssessmentsScoreControl: FC<AssessmentsScoreControlProps> = ({
  autoFocus,
  onMethodChange,
  onDelete,
  index,
}) => {
  const { formatMessage } = useIntl();
  const name = `methods.${index}` as const;
  const { control, formState, watch } = useFormContext<AssessmentForm>();
  const method = watch(name);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const error = get(formState.errors, field.name);
        return (
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <Box sx={{ flex: '0 0 49%' }}>
              <AssessmentsCreateModalSettingsMethodType
                methodType={field.value.method_type ?? undefined}
                onChange={onMethodChange}
              />
            </Box>
            <Box width="100%">
              <TextField
                label={formatMessage({ id: 'assessments-ScorePlaceholder' })}
                inputProps={{
                  min: 0,
                  max: SCORE_OUT_OF_MAX,
                  value: field.value.score_out_of ?? '',
                }}
                value={field.value.score_out_of}
                onChange={(e) => {
                  const scoreOutOf = e.target.value;

                  let score_out_of;

                  if (scoreOutOf == null || scoreOutOf === '') {
                    score_out_of = null;
                  } else if (isNaN(parseInt(scoreOutOf))) {
                    // NaN should not update value
                    return;
                  } else {
                    score_out_of = parseInt(scoreOutOf);

                    if (score_out_of < 0) {
                      score_out_of = 0;
                    } else if (score_out_of > SCORE_OUT_OF_MAX) {
                      score_out_of = SCORE_OUT_OF_MAX;
                    }
                  }
                  field.onChange({ ...method, score_out_of });
                }}
                error={Boolean(error)}
                helperText={renderError(error)}
                autoFocus={autoFocus}
                fullWidth
              />
            </Box>
            <Box>
              <DeleteMethodButton onClick={onDelete} />
            </Box>
          </Stack>
        );
      }}
    />
  );
};
