import { Box, Button } from '@mui/material';
import { DeleteIcon, Spin } from '@schooly/style';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalFooter } from '../../../components/uikit-components/Modal/Modal.styled';
import { MessagesCreateModalMode } from '../../../context/messages/MessageContext';
import { useMessage } from '../../../context/messages/useMessage';

export const MessagesCreateModalFooter = () => {
  const { messageId, saving, savingAction, deleting, actions, mode, canSave } = useMessage();

  const handleDelete = useCallback(() => {
    actions.setDeletingConfirmation(true);
  }, [actions]);

  const sidebarOpen =
    mode === MessagesCreateModalMode.RecipientsParents ||
    mode === MessagesCreateModalMode.RecipientsStaff;

  return (
    <ModalFooter
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          flexDirection: messageId && sidebarOpen ? 'column-reverse' : undefined,
          gap: messageId && sidebarOpen ? theme.spacing(1) : undefined,
          padding: theme.spacing(2, 4),
          '& .MuiButtonBase-root, .MuiBox-root': {
            width: messageId && sidebarOpen ? '100%' : undefined,
          },
        },
      })}
    >
      {messageId && (
        <Box sx={{ flex: '1 1 auto' }}>
          <Button
            variant="outlined"
            startIcon={deleting ? <Spin /> : <DeleteIcon />}
            disabled={saving || deleting}
            onClick={handleDelete}
          >
            <FormattedMessage id="action-Delete" />
          </Button>
        </Box>
      )}
      <Button
        type="submit"
        variant="outlined"
        startIcon={saving && savingAction === 'close' && <Spin />}
        disabled={saving || deleting || !canSave}
      >
        <FormattedMessage id="action-SaveAndClose" />
      </Button>
      <Button
        type="submit"
        data-preview
        startIcon={saving && savingAction === 'preview' && <Spin />}
        disabled={saving || deleting || !canSave}
      >
        <FormattedMessage id="action-SaveAndPreview" />
      </Button>
    </ModalFooter>
  );
};
