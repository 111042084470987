import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7071 3.29289C17.0976 3.68342 17.0976 4.31658 16.7071 4.70711L4.70711 16.7071C4.31658 17.0976 3.68342 17.0976 3.29289 16.7071C2.90237 16.3166 2.90237 15.6834 3.29289 15.2929L15.2929 3.29289C15.6834 2.90237 16.3166 2.90237 16.7071 3.29289Z"
      fill="#24275B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071Z"
      fill="#24275B"
    />
  </svg>
);
