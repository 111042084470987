import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { RichText } from '@schooly/components/form-rich-text';
import {
  ButtonWithDescription,
  EditIcon,
  PlusIcon,
  PreviewModalCard,
  ShowHtml,
} from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConsentForm } from '../../../../components/common/ConsentForms/ConsentForm';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import { ModalPanel } from '../../../../components/uikit-components/Modal/Modal.styled';
import { MessagesCreateModalMode } from '../../../../context/messages/MessageContext';
import { useMessage } from '../../../../context/messages/useMessage';
import { MESSAGE_MAX_CHARS_LENGTH } from '../../../../context/messages/WithMessage';

export const MessagesCreateModalMessage: FC = () => {
  const {
    mode,
    messageForm,
    attachments,
    actions,
    consentForm,
    messageId,
    isPublished,
    consentFormShowed,
    linkedConsentForm,
  } = useMessage();

  const { $t } = useIntl();
  const body = messageForm.watch('body');
  const isActive = mode === MessagesCreateModalMode.Message;
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const sidebarOpen =
    mode === MessagesCreateModalMode.RecipientsParents ||
    mode === MessagesCreateModalMode.RecipientsStaff;

  const mediumScreenWidth = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'));

  const canCreateConsentForm = (!messageId || !isPublished) && !linkedConsentForm;

  const titleNode = useMemo(() => {
    if (mediumScreenWidth && isActive) {
      return (
        <Box mt={2} mb={2}>
          <Typography variant="h2">
            <FormattedMessage id="messages-MessageTitle" />
          </Typography>
        </Box>
      );
    }
  }, [mediumScreenWidth, isActive]);

  const handleClick = useCallback(() => {
    actions.setMode(MessagesCreateModalMode.Message);
  }, [actions]);

  return (
    <ModalPanel
      active={isActive}
      fullHeight={isActive}
      ref={scrollContainer}
      withBorderBottom
      sx={(theme) => ({
        [theme.breakpoints.down('lg')]: {
          p: theme.spacing(2, 4),
          pb: isActive ? theme.spacing(5) : undefined,
        },
        [theme.breakpoints.only('md')]: {
          pt: isActive ? 0 : undefined,
        },
        [theme.breakpoints.down('md')]: {
          p: isActive ? theme.spacing(3, 4, 2) : undefined,
          pb: sidebarOpen ? theme.spacing(7.5) : undefined,
        },
      })}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h2"
          sx={(theme) => ({
            [theme.breakpoints.only('md')]: {
              display: isActive ? 'none' : undefined,
            },
          })}
        >
          <FormattedMessage id="messages-MessageTitle" />
        </Typography>

        {messageForm.formState.errors.body && (
          <Typography variant="body2" color="error">
            {getControllerErrorText(messageForm.formState.errors.body, undefined, $t)}
          </Typography>
        )}
        {!isActive &&
          (body ? (
            <SimpleButton startIcon={<EditIcon />} onClick={handleClick}>
              <FormattedMessage id="messages-EditMessage" />
            </SimpleButton>
          ) : (
            <SimpleButton startIcon={<PlusIcon />} onClick={handleClick}>
              <FormattedMessage id="messages-CreateMessage" />
            </SimpleButton>
          ))}
      </Stack>

      {isActive && (
        <Stack
          gap={3}
          pt={2.5}
          sx={{
            '& .ql-editor': { minHeight: '120px !important' },
          }}
        >
          <Controller
            name="body"
            control={messageForm.control}
            render={({ field }) => {
              return (
                <RichText
                  value={field.value}
                  onChange={field.onChange}
                  files={attachments}
                  setFiles={actions.setAttachments}
                  title={titleNode}
                  maxBodyLength={MESSAGE_MAX_CHARS_LENGTH}
                  scrollingContainer={scrollContainer.current}
                />
              );
            }}
          />

          {canCreateConsentForm && consentForm && (
            <ConsentForm
              visible={consentFormShowed}
              onDelete={actions.hideConsentForm}
              consentForm={consentForm}
            />
          )}
          {canCreateConsentForm && !consentFormShowed && (
            <ButtonWithDescription
              onClick={actions.showConsentForm}
              startIcon={<PlusIcon />}
              title={$t({ id: 'consentForms-title' })}
              description={$t({ id: 'consentForms-titleDescription' })}
              oneLine
            />
          )}

          {linkedConsentForm && (
            <PreviewModalCard
              path={`/consent-form/${linkedConsentForm.id}`}
              title={$t({ id: 'consentForms-title' })}
            >
              <ShowHtml
                html={linkedConsentForm.description}
                sx={{
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                }}
              />
            </PreviewModalCard>
          )}
        </Stack>
      )}
    </ModalPanel>
  );
};
