import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 2C16.1046 2 17 2.89543 17 4V5H14V2H15ZM14 6H17V16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16V4C3 2.89543 3.89543 2 5 2H13V5C13 5.55228 13.4477 6 14 6ZM13.8438 8.8196C14.0521 8.63211 14.0521 8.32812 13.8438 8.14062C13.6355 7.95313 13.2978 7.95313 13.0895 8.14062L9.2 11.642L6.91046 9.58096C6.70218 9.39347 6.36449 9.39347 6.15621 9.58096C5.94793 9.76846 5.94793 10.0724 6.15621 10.2599L9.2 13L13.8438 8.8196Z"
      fill="#F2994A"
    />
  </svg>
);
