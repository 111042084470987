import { MenuItem } from '@mui/material';
import { RecurringRepeatBy } from '@schooly/api';
import { ControlSelect } from '@schooly/components/form-select';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

type RepeatBySelectProps = {
  required?: boolean;
  name: string;
};

export const RepeatBySelect: FC<RepeatBySelectProps> = ({ required, name }) => {
  const { watch } = useFormContext<Record<string, RecurringRepeatBy>>();
  const field = watch(name);

  return (
    <ControlSelect
      name={name}
      renderValue={(value) => <FormattedMessage id={value as RecurringRepeatBy} />}
      defaultValue={RecurringRepeatBy.Week}
      rules={{ required }}
      fullWidth
      sx={{
        '&& .MuiSelect-outlined.MuiOutlinedInput-input.MuiSelect-select': {
          paddingTop: 1.25,
        },
      }}
    >
      <MenuItem selected={field === RecurringRepeatBy.Week} value={RecurringRepeatBy.Week}>
        <FormattedMessage id={RecurringRepeatBy.Week} />
      </MenuItem>
      <MenuItem selected={field === RecurringRepeatBy.Month} value={RecurringRepeatBy.Month}>
        <FormattedMessage id={RecurringRepeatBy.Month} />
      </MenuItem>
    </ControlSelect>
  );
};
