import { Box, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

interface RowsContainerProps extends PropsWithChildren {
  containerTitle?: string;
  color?: string;
  stretch?: boolean;
}

export const RowsContainer: FC<RowsContainerProps> = ({
  containerTitle,
  color,
  children,
  stretch,
}) => {
  return (
    <Stack flex={stretch ? '1 1 100%' : ''} gap={1}>
      {containerTitle && (
        <Typography color={color ?? undefined} variant="h4">
          <FormattedMessage id={containerTitle} />
        </Typography>
      )}
      <Box>{children}</Box>
    </Stack>
  );
};
