import { FunctionInterpolation } from '@emotion/react';
import { Box, Button, styled, Theme } from '@mui/material';
import { animated } from '@react-spring/web';

import { ButtonColor, NotificationProps } from './Notification';

export const NOTIFICATION_HEIGHT = 44;
export const NOTIFICATION_GAP = 20;

export const NotificationsContainer = styled(Box)(() => ({
  position: 'fixed',
  zIndex: 9999,
  display: 'flex',
  left: 0,
  right: 0,
  bottom: '3.5rem',
  flexDirection: 'column',
  alignItems: 'center',
  pointerEvents: 'none',
}));

type NotificationType = NotificationProps['type'];

export const notificationStyle: FunctionInterpolation<{ theme: Theme; type: NotificationType }> = ({
  theme,
  type,
}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'auto',
  padding: '5px 20px',
  minHeight: '2rem',
  textAlign: 'center',
  fontSize: '0.7rem',
  backgroundColor: type === 'error' ? theme.palette.error.main : theme.palette.primary.main,
  pointerEvents: 'all',
  borderRadius: 10,
  lineHeight: NOTIFICATION_HEIGHT,
  maxWidth: '90vw',
  letterSpacing: '0.001rem',

  '.svg-icon': {
    color: 'white',
    '--icon-size': '1rem',
    marginRight: '0.5rem',
  },

  a: {
    color: 'white',
    textDecoration: 'underline',
  },
});

export const NotificationContainer = styled(Box)<{ type: NotificationType }>(notificationStyle);

export const NotificationToastContainer = styled(animated.div)<{ type: NotificationType }>(
  notificationStyle,
);

export const NotificationButton = styled(Button)<{ buttonColor?: ButtonColor }>(
  ({ theme, buttonColor }) => {
    const isOutlined = buttonColor === 'white-text';

    return {
      ...theme.typography.body1,
      backgroundColor: isOutlined ? 'transparent' : 'white',
      color: isOutlined ? 'white' : theme.palette.primary.main,
      border: '1px solid white',
      borderRadius: theme.shape.borderRadiusSm,
      padding: theme.spacing(0.75, 1.5),
      whiteSpace: 'nowrap',
      transition: 'all .2s ease',

      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: isOutlined ? 'white' : theme.palette.text.disabled,
        borderColor: isOutlined ? 'white' : theme.palette.text.disabled,
      },
    };
  },
);
