import { Checkbox } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AttendanceCode } from '@schooly/api';
import { GridCell } from '@schooly/style';
import React, { FC } from 'react';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

import { useTakeAttendanceModal } from '../useTakeAttendanceModal';

interface TakeAttendanceGridCheckboxCellProps {
  props: GridRenderCellParams;
  onCheckboxChange: () => void;
  code: AttendanceCode;
}

export const TakeAttendanceGridCheckboxCell: FC<TakeAttendanceGridCheckboxCellProps> = ({
  props,
  onCheckboxChange,
  code,
}) => {
  const { entries, errors, saving } = useTakeAttendanceModal();

  return (
    <GridCell
      key={`td-${props.row.id}-${code.code}`}
      sx={(theme) => ({
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&&': {
          backgroundColor: (
            (errors?.entries as FormattedMessageProps)?.values as {
              emptyStudents?: Record<string, boolean>;
            }
          )?.emptyStudents?.[props.row.id]
            ? `${theme.palette.error.main}19` // + alpha: 0.1
            : 'inherit',
        },
      })}
      noPadding
    >
      <Checkbox
        checked={entries?.[props.row.id]?.attendance_code_id === code.id}
        onChange={(event, checked) => {
          // don't allow to uncheck the status
          if (checked) onCheckboxChange();
        }}
        disabled={saving}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') onCheckboxChange();
        }}
      />
    </GridCell>
  );
};
