import { IconButton, Typography } from '@mui/material';
import { GroupType } from '@schooly/api';
import { RelationGroup } from '@schooly/api';
import { DATE_FORMAT_MOMENT as DATE_FORMAT } from '@schooly/constants';
import { AttentionIcon } from '@schooly/style';
import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupCard } from '../../../components/common/GroupCard/GroupCard';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { ManageGroupModalTooltip } from './ManageGroupModalTooltip';
import { useGroupDates } from './useGroupDates';

interface ManageIndividualStudentGroupsProps {
  group: RelationGroup;
  criteriaGroups: RelationGroup[];
  onRemoveGroup: (group: RelationGroup) => void;
}

export const IndividualStudentGroup: FC<ManageIndividualStudentGroupsProps> = ({
  group,
  criteriaGroups,
  onRemoveGroup,
}) => {
  const { $t } = useIntl();
  const { schoolYears } = useSchoolYears();
  const { getValidityDates } = useGroupDates({ schoolYears, dateFormat: DATE_FORMAT });

  const criteriaInfo = useMemo(() => {
    if (criteriaGroups.some((gr) => gr.id === group.id)) {
      return (
        <ManageGroupModalTooltip
          arrow
          text={
            <Typography color="primary.main">
              <FormattedMessage id="group-added-with-criteria" />
            </Typography>
          }
        >
          <IconButton inverse>
            <AttentionIcon />
          </IconButton>
        </ManageGroupModalTooltip>
      );
    }

    return null;
  }, [criteriaGroups, group.id]);

  const groupValidityTitle = useMemo(() => {
    const { from, to } = getValidityDates(group.validity);
    return `${from}${' '}-${' '}${to}`;
  }, [getValidityDates, group.validity]);

  return (
    <GroupCard
      title={group.name}
      date={groupValidityTitle}
      added
      subjectName={
        group.group_type === GroupType.TutorGroup
          ? $t({ id: 'groups-TutorGroup' })
          : group.subject?.name
      }
      subjectArchived={group.subject?.archived}
      onSelect={() => onRemoveGroup(group)}
      selectable
      rowItemNode={criteriaInfo}
      withBorder
      id={group.id}
    />
  );
};
