import { Box } from '@mui/material';
import React from 'react';

import { DropdownMenu, DropdownMenuItem, DropdownMenuProps } from './DropdownMenu';

export const IconDropdownMenu = <T extends DropdownMenuItem>(props: DropdownMenuProps<T>) => {
  return (
    <Box
      className="IconDropdownMenu"
      sx={(theme) => ({
        '& .DropdownMenu': {
          borderRadius: `${theme.shape.borderRadiusSm}px`,

          '& .MuiButtonBase-root': {
            padding: '5px',
          },

          '& .MuiButtonBase-root, & .svg-icon': {
            color: 'inherit',
            transition: 'none',
          },
        },

        '& .DropdownMenu-open': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.getContrastText(theme.palette.primary.main),
        },
      })}
    >
      <DropdownMenu
        noArrow
        menuProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
        {...props}
      />
    </Box>
  );
};
