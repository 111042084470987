import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70711 2.29289C10.0976 2.68342 10.0976 3.31658 9.70711 3.70711L3.41421 10L9.70711 16.2929C10.0976 16.6834 10.0976 17.3166 9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L1.29289 10.7071C0.902369 10.3166 0.902369 9.68342 1.29289 9.29289L8.29289 2.29289C8.68342 1.90237 9.31658 1.90237 9.70711 2.29289Z"
      fill="#B3BBCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2071 2.29289C19.5976 2.68342 19.5976 3.31658 19.2071 3.70711L12.9142 10L19.2071 16.2929C19.5976 16.6834 19.5976 17.3166 19.2071 17.7071C18.8166 18.0976 18.1834 18.0976 17.7929 17.7071L10.7929 10.7071C10.4024 10.3166 10.4024 9.68342 10.7929 9.29289L17.7929 2.29289C18.1834 1.90237 18.8166 1.90237 19.2071 2.29289Z"
      fill="#B3BBCB"
    />
  </svg>
);
