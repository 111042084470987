import { Stack, SxProps } from '@mui/material';
import { FC, RefObject, useRef, useState } from 'react';

import { GridRowCell } from '../../Grid/Grid';

interface CommentRowCellProps {
  children: (onToggle: (val: boolean) => void, ref: RefObject<HTMLSpanElement>) => React.ReactNode;
  rowStyleProps?: SxProps;
}
export const CommentRowCell: FC<CommentRowCellProps> = ({ children, rowStyleProps }) => {
  const [open, setOpen] = useState(false);
  const cellRef = useRef<HTMLSpanElement>(null);

  return (
    <GridRowCell
      ref={cellRef}
      sx={{
        border: (theme) => theme.mixins.borderValue(),
        borderColor: (theme) => (open ? theme.palette.common.light2 : 'transparent'),
        borderRadius: (theme) => `${theme.shape.borderRadius}px`,
        ...rowStyleProps,
      }}
    >
      <Stack
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {children((val) => setOpen(val), cellRef)}
      </Stack>
    </GridRowCell>
  );
};
