import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM9.30469 4.625L9.41406 12.7891H10.7266L10.8359 4.625H9.30469ZM10.7422 14.6797C10.5911 14.5234 10.3802 14.4453 10.1094 14.4453C9.83854 14.4453 9.6276 14.5234 9.47656 14.6797C9.33073 14.8359 9.25781 15.0339 9.25781 15.2734C9.25781 15.513 9.33073 15.7083 9.47656 15.8594C9.6276 16.0104 9.83854 16.0859 10.1094 16.0859C10.3802 16.0859 10.5911 16.0104 10.7422 15.8594C10.8984 15.7083 10.9766 15.513 10.9766 15.2734C10.9766 15.0339 10.8984 14.8359 10.7422 14.6797Z"
      fill="#EB5757"
    />
  </svg>
);
