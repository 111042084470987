import { BaseUser, BaseUserSearchResult, NewUser, UserType } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { SchoolUserRole } from '@schooly/constants';
import { DeleteFilledIcon, DeleteIcon, MinusIcon, StarIcon } from '@schooly/style';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getUserFullName } from '../../../helpers/users';
import buildClassName from '../../../utils/buildClassName';
import PersonCardBasic from '../PersonCard/PersonCardBasic';
import { isTempUserId } from './utils';

import './ListItem.scss';

interface ListItemProps {
  user: NewUser | BaseUser;
  userType: UserType;
  isPrimaryContact?: boolean;
  isProtected?: boolean;
  profileSchoolContext?: SchoolUserRole;
  onUserDelete: (userId: string) => void;
  onSelectPrimaryContact?: (userId: string) => void;
}

const ListItem: React.FC<ListItemProps> = ({
  user,
  userType,
  isPrimaryContact,
  isProtected,
  profileSchoolContext,
  onUserDelete,
  onSelectPrimaryContact,
}) => {
  const { formatMessage } = useIntl();
  const { getConfirmation } = useConfirmationDialog();
  const isCreatedUser = isTempUserId(user.user_id);

  const canDelete = !isProtected;

  async function handleRemove(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();

    if (isCreatedUser) {
      const isConfirmed = await getConfirmation({
        message: formatMessage(
          { id: 'people-RemovePersonWarning' },
          { userName: getUserFullName(user) },
        ),
      });

      if (!isConfirmed) {
        return;
      }
    }

    onUserDelete((user as BaseUserSearchResult).user_id);
  }

  function handleCardClick() {
    if (isPrimaryContact || !onSelectPrimaryContact) {
      return;
    }

    onSelectPrimaryContact((user as BaseUserSearchResult).user_id);
  }

  return (
    <PersonCardBasic
      user={user}
      profileSchoolContext={profileSchoolContext}
      userType={userType}
      onClick={handleCardClick}
      isUsernameClickable={!isCreatedUser}
      className={buildClassName(
        'PersonCardSelectable ListItem',
        !!onSelectPrimaryContact && 'with-primary-contact',
      )}
      isListItem
    >
      {onSelectPrimaryContact && (
        <div className={buildClassName('ListItem__primary-contact', isPrimaryContact && 'static')}>
          <StarIcon />
          <span>
            {isPrimaryContact ? (
              <FormattedMessage id="people-PrimaryContact" />
            ) : (
              <FormattedMessage id="people-MakePrimaryContact" />
            )}
          </span>
        </div>
      )}
      {canDelete && (
        <div role="button" tabIndex={0} className="ListItem__remove-button" onClick={handleRemove}>
          {isCreatedUser ? (
            <>
              <DeleteIcon className="PersonCardSelectable__icon PersonCardSelectable__icon_delete" />
              <DeleteFilledIcon className="PersonCardSelectable__icon PersonCardSelectable__icon_delete-filled" />
            </>
          ) : (
            <MinusIcon className="PersonCardSelectable__icon" />
          )}
        </div>
      )}
    </PersonCardBasic>
  );
};

export default ListItem;
