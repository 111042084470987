import { Loading, ModalLarge } from '@schooly/style';
import { FC } from 'react';

import { useSignUp } from '../../../context/signUps/WithSignUp';
import { SignUpPreviewContent } from './SignUpPreviewContent';

interface SignUpPreviewModalProps {}

export const SignUpPreviewModal: FC<SignUpPreviewModalProps> = () => {
  const { signUp, fetching } = useSignUp();

  return (
    <ModalLarge open>{fetching || !signUp ? <Loading /> : <SignUpPreviewContent />}</ModalLarge>
  );
};
