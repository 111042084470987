import { IconButton, Stack, Typography } from '@mui/material';
import { AttendanceCode } from '@schooly/api';
import { AttendanceCodeSchoolPresence } from '@schooly/constants';
import {
  Counter,
  CrossIcon,
  DragIcon,
  EditIcon,
  GridRowCell,
  GridRowItem,
  GridRowStyled,
  ModalContent,
  ModalLarge,
  PlusIcon,
  SimpleButton,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { useFieldArray, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { AttendanceCodeForm } from '../../../hooks/useAttendanceCodes';
import { AddAttendanceCodeModalContent } from '../SchoolAttendance/SchoolAttendanceCodes/AddAttendanceCodeModalContent';

type AttendanceCodeCreate = Omit<AttendanceCode, 'id' | 'order'>;

type SchoolCreateAttendanceCodesModalProps = {
  title?: string;
  codes: AttendanceCodeCreate[];
  onClose: () => void;
  onSubmit: (form: SchoolCreateAttendanceCodesModalForm) => void;
};

export type SchoolCreateAttendanceCodesModalForm = {
  codes: AttendanceCodeCreate[];
};

export const SchoolCreateAttendanceCodesModal: FC<SchoolCreateAttendanceCodesModalProps> = ({
  title,
  codes: initialCodes,
  onClose,
  onSubmit,
}) => {
  const { $t } = useIntl();
  const form = useForm<SchoolCreateAttendanceCodesModalForm>({
    defaultValues: {
      codes: initialCodes,
    },
  });
  const [editingCodeIndex, setEditingCodeIndex] = useState<number | null>(
    initialCodes.length ? null : -1,
  );

  const {
    fields: codes,
    append,
    remove,
    move,
    update,
  } = useFieldArray({
    control: form.control,
    name: 'codes',
  });

  useEffect(() => {
    onSubmit({ codes });
  }, [codes, onSubmit]);

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const editCodeWithActions = useMemo(() => {
    if (editingCodeIndex === null) return;

    const onClose = () => setEditingCodeIndex(null);

    if (editingCodeIndex === -1) {
      return {
        code: generateEmptyAttendanceCode(),
        onSubmit: (v: AttendanceCodeForm) => {
          append({
            ...v,
            in_school: v.in_school === AttendanceCodeSchoolPresence.InSchool ? true : false,
          });
          onClose();
        },
        onClose,
        isEditing: false,
      };
    }

    const relatedCode = codes[editingCodeIndex];

    if (!relatedCode) return;

    return {
      code: relatedCode,
      onSubmit: (v: AttendanceCodeForm) => {
        update(editingCodeIndex, {
          ...v,
          in_school: v.in_school === AttendanceCodeSchoolPresence.InSchool ? true : false,
        });
        onClose();
      },
      onDelete: () => {
        remove(editingCodeIndex);
        onClose();
      },
      onClose,
      isEditing: true,
    };
  }, [append, codes, editingCodeIndex, remove, update]);

  useEffect(() => {
    if (!!editCodeWithActions || codes.length) return;
    onClose();
  }, [editCodeWithActions, onClose, codes.length]);

  return (
    <>
      <ModalLarge open={!editCodeWithActions} onClose={onClose}>
        <ModalHeader active title={title}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Typography variant="h2">{$t({ id: 'attendance-Codes' })}</Typography>
              <Counter sx={{ minWidth: 20, textAlign: 'center', fontWeight: 600 }}>
                {codes.length}
              </Counter>
            </Stack>
            <SimpleButton startIcon={<PlusIcon />} onClick={() => setEditingCodeIndex(-1)}>
              {$t({ id: 'attendance-AddCodes' })}
            </SimpleButton>
          </Stack>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Stack mt={3} {...provided.droppableProps} ref={provided.innerRef}>
                  {codes?.map((code, i) => {
                    return (
                      <Draggable key={code.id} draggableId={code.id} index={i}>
                        {(provided) => (
                          <GridRowStyled
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={provided.draggableProps.style}
                            key={code.id}
                            sx={{
                              '& :hover': {
                                '.editRoleIcon': {
                                  visibility: 'visible',
                                },
                              },
                            }}
                          >
                            <GridRowItem gap={2}>
                              {codes.length > 1 && (
                                <IconButton {...provided.dragHandleProps} inverse>
                                  <DragIcon />
                                </IconButton>
                              )}

                              <GridRowCell flex={1}>
                                <TypographyWithOverflowHint fontSize={15}>
                                  {code.name}{' '}
                                </TypographyWithOverflowHint>
                              </GridRowCell>
                              <GridRowCell width={150}>
                                <Typography>
                                  {$t({
                                    id: code.in_school
                                      ? 'attendance-InSchool'
                                      : 'attendance-OutOfSchool',
                                  })}
                                </Typography>
                              </GridRowCell>
                              <GridRowCell width={100}>
                                <Typography>{code.code}</Typography>
                              </GridRowCell>
                              <GridRowCell width={400}>
                                <Typography>{code.description}</Typography>
                              </GridRowCell>
                              <IconButton
                                className="editRoleIcon"
                                sx={{
                                  visibility: 'hidden',
                                }}
                                inverse
                                onClick={() => setEditingCodeIndex(i)}
                              >
                                <EditIcon />
                              </IconButton>
                            </GridRowItem>
                          </GridRowStyled>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </ModalContent>
      </ModalLarge>
      {!!editCodeWithActions && (
        <AddAttendanceCodeModalContent
          {...editCodeWithActions}
          isLoading={false}
          isDeleting={false}
        />
      )}
    </>
  );
};

export const generateEmptyAttendanceCode = (): AttendanceCodeCreate => ({
  name: '',
  description: '',
  code: '',
  in_school: true,
  is_present: false,
});
