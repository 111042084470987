import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { PickersDayProps, StaticDatePicker, StaticDatePickerProps } from '@mui/x-date-pickers';
import { format, isSameDay } from 'date-fns';
import { FC, useMemo } from 'react';

import { DatePickerStyledContainer, emptySlots, views } from './DatePickerCommonComponents';

export type DateSelectCalendarProps = {
  visibleMonth: Date;
  onClickDay: (v: Date) => void;
  fromDate?: Date | null;
  toDate?: Date | null;
  onHoverDay?: (v: Date | null) => void;
  getDayStyleProps?: (d: Date) => SxProps<Theme>;
  DatePickerProps?: StaticDatePickerProps<Date>;
};

export const DateSelectCalendar: FC<DateSelectCalendarProps> = ({
  toDate,
  fromDate,
  visibleMonth,
  getDayStyleProps,
  onClickDay,
  onHoverDay,
  DatePickerProps,
}) => {
  const slots = useMemo(() => {
    return {
      ...emptySlots,
      day: (props: PickersDayProps<Date>) => {
        const dayDate = props.day;
        const isDisabled = props.disabled;
        const isFromDate = fromDate && isSameDay(dayDate, fromDate);
        const isToDate = toDate && isSameDay(dayDate, toDate);

        const isSelected = isFromDate || isToDate;
        const dayStyleProps = getDayStyleProps?.(dayDate) ?? {};

        return (
          <Stack
            onClick={() => onClickDay(dayDate)}
            key={props.key}
            sx={(theme) => ({
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
              height: theme.spacing(3.75),
              minWidth: theme.spacing(3.5),

              '.MuiTypography-root': {
                color: isSelected
                  ? theme.palette.background.paper
                  : isDisabled
                  ? theme.palette.common.grey
                  : theme.palette.text.primary,
              },

              ...(isSelected
                ? {
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    color: theme.palette.background.paper,
                    borderRadius: theme.spacing(0.5),
                  }
                : {
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: theme.palette.background.default,
                      borderRadius: theme.spacing(0.5),
                    },
                  }),

              ...(isDisabled
                ? {
                    pointerEvents: 'none',
                  }
                : {}),
              ...(props.outsideCurrentMonth
                ? {
                    visibility: 'hidden',
                  }
                : undefined),
              ...((typeof dayStyleProps === 'function'
                ? dayStyleProps(theme)
                : dayStyleProps) as SystemStyleObject),
            })}
            onMouseEnter={onHoverDay ? () => onHoverDay(dayDate) : undefined}
            onMouseLeave={onHoverDay ? () => onHoverDay(null) : undefined}
          >
            <Typography>{format(dayDate, 'd')}</Typography>
            {props.today && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 2,
                  width: '4px',
                  height: '4px',
                  borderRadius: '2px',
                  backgroundColor: isSelected ? 'white' : 'text.primary',
                }}
              />
            )}
          </Stack>
        );
      },
    };
  }, [fromDate, getDayStyleProps, onClickDay, onHoverDay, toDate]);

  return (
    <DatePickerStyledContainer>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={visibleMonth}
        views={views}
        slots={slots}
        disableHighlightToday
        {...DatePickerProps}
      />
    </DatePickerStyledContainer>
  );
};
