import { SchoolPropertyCategory } from '@schooly/api';

import { SchoolUserRole } from '../../../../../../constants/school';
import { SchoolTuneStatus, SchoolTuneStatusesForm, SchoolTuneStatusType } from '../scheme';

export const prepareStatusesToSave = <T>(
  categories: SchoolPropertyCategory[] | undefined,
  data: SchoolTuneStatusesForm,
  predicate: (
    categoryId: string,
    index: number,
  ) => (status: SchoolTuneStatus, index: number, array: SchoolTuneStatus[]) => T,
) => {
  const { studentCategories = {}, staffCategories = {} } =
    categories?.reduce<{
      studentCategories: Partial<Record<keyof SchoolTuneStatusType, string>>;
      staffCategories: Partial<Record<keyof SchoolTuneStatusType, string>>;
    }>(
      (prev, category) => {
        let key: 'staffCategories' | 'studentCategories' | undefined;

        switch (category.user_type) {
          case SchoolUserRole.Staff:
            key = 'staffCategories';
            break;
          case SchoolUserRole.Student:
            key = 'studentCategories';
            break;
        }

        if (!key) {
          return prev;
        }

        let type: keyof SchoolTuneStatusType | undefined;

        switch (category.name) {
          case 'Prospective':
            type = 'prospective';
            break;
          case 'Current':
            type = 'current';
            break;
          case 'Former':
            type = 'former';
            break;
          case 'Unsuccessful':
            type = 'unsuccessful';
            break;
        }

        if (!type) {
          return prev;
        }

        prev[key][type] = (category as any).id;

        return prev;
      },
      { studentCategories: {}, staffCategories: {} },
    ) ?? {};

  const statuses: T[] = [];

  (['students', 'staff'] as (keyof SchoolTuneStatusesForm)[]).forEach((type) => {
    let index = 0;

    for (const category of [
      'prospective',
      'current',
      'former',
      'unsuccessful',
    ] as (keyof SchoolTuneStatusType)[]) {
      const categoryId = type === 'staff' ? staffCategories[category] : studentCategories[category];

      if (!categoryId) {
        return;
      }

      statuses.push(
        // eslint-disable-next-line no-loop-func
        ...data[type][category].map(predicate(categoryId, index)),
      );

      index += data[type][category].length;
    }
  });

  return statuses;
};
