import { ButtonProps, Icon, Stack, Tooltip, TooltipProps, Typography } from '@mui/material';
import { PaymentFrequency } from '@schooly/api';
import { Attention2Icon, CheckIcon, NewTabIcon, SettingsIcon, SimpleButton } from '@schooly/style';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { FREQUENCY_SETTINGS_PATH } from './helpers';
import { HOVER_TEXT_CLASS_NAME } from './SchoolProductCreateModal/CreateVariantsTable';

type NotInUseFrequencyLabelProps = {
  frequency: PaymentFrequency;
  canView: boolean;
};

export const NotInUseFrequencyLabel: FC<NotInUseFrequencyLabelProps> = ({
  frequency: { type },
  canView,
}) => {
  const { $t } = useIntl();

  return (
    <Stack gap={2}>
      <Typography>
        {$t(
          { id: 'frequencies-SwitchedOff' },
          { frequencyName: $t({ id: `frequencies-${type}` }) },
        )}
      </Typography>
      <Typography pt={0.5}>{$t({ id: 'frequencies-SwitchedOff-Payments' })}</Typography>

      <Stack gap={1.25}>
        {$t({
          id: canView
            ? 'frequencies-SwitchedOff-Enable'
            : 'frequencies-SwitchedOff-EnableNoPermissions',
        })}{' '}
        {$t({ id: 'frequencies-SwitchedOff-InvoicesWillBeIssued' })}
        {canView && (
          <SimpleButton
            size="small"
            startIcon={<NewTabIcon />}
            sx={{
              alignSelf: 'flex-start',
            }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(FREQUENCY_SETTINGS_PATH, '_blank');
            }}
          >
            {$t({ id: 'frequencies-SetFrequencies' })}
          </SimpleButton>
        )}
      </Stack>
    </Stack>
  );
};

export const AllFrequenciesNotInUseLabel: FC<AllFrequenciesNotInUseProps> = ({ name, plural }) => {
  const { $t } = useIntl();

  return (
    <Stack gap={2}>
      <Typography>
        {$t(
          {
            id: plural
              ? 'frequencies-SwitchedOff-NotAvailableProductOrTypePluralNames'
              : 'frequencies-SwitchedOff-NotAvailableProductOrType',
          },
          { ...(plural ? { names: name } : { name }) },
        )}
      </Typography>
      <Typography pt={0.5}>{$t({ id: 'frequencies-SwitchedOff-StudentsPaying' })}</Typography>
    </Stack>
  );
};

export const FrequenciesTooltip: FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: () => ({
            width: 200,
            padding: 1.25,
          }),
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

type AllFrequenciesNotInUseProps = {
  name: string;
  plural?: boolean;
};

export const AllFrequenciesNotInUse: FC<AllFrequenciesNotInUseProps> = (props) => {
  return (
    <FrequenciesTooltip title={<AllFrequenciesNotInUseLabel {...props} />}>
      <Icon
        sx={(theme) => ({
          color: theme.palette.background.paper,
          path: {
            color: theme.palette.common.orange,
          },
        })}
      >
        <Attention2Icon />
      </Icon>
    </FrequenciesTooltip>
  );
};

type NoFrequenciesProps = {
  canView: boolean;
};

export const NoFrequencies: FC<NoFrequenciesProps> = ({ canView }) => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        alignItems: 'center',
        gap: 1,
      }}
      onClick={(e) => {
        e.stopPropagation();
        navigate(FREQUENCY_SETTINGS_PATH);
      }}
    >
      <Typography>{$t({ id: 'frequencies-SwitchedOff-CreateProduct' })}</Typography>

      {canView ? (
        <SimpleButton
          startIcon={<SettingsIcon />}
          sx={{
            alignSelf: 'flex-start',
          }}
        >
          <Typography> {$t({ id: 'frequencies-SetFrequencies' })}</Typography>
        </SimpleButton>
      ) : (
        <Typography>{$t({ id: 'frequencies-CreateFrequencyNoPermissions' })}</Typography>
      )}
    </Stack>
  );
};

type HalfDayIconComponentProps = {
  isHalfDay: boolean;
  tooltipTitle?: ReactNode;
  showEmpty?: boolean;
  halfDayIcon?: ReactNode;
} & ButtonProps;

export const HalfDayIconComponent: FC<HalfDayIconComponentProps> = ({
  isHalfDay,
  tooltipTitle,
  showEmpty = true,
  halfDayIcon = <CheckIcon />,
  ...props
}) => {
  return (
    <Tooltip title={tooltipTitle}>
      <Stack sx={{ alignItems: 'center' }}>
        <Icon color="primary" {...props}>
          {isHalfDay ? (
            halfDayIcon
          ) : (
            <Typography variant="h3" color="common.grey" className={HOVER_TEXT_CLASS_NAME}>
              {showEmpty ? '-' : ''}
            </Typography>
          )}
        </Icon>
      </Stack>
    </Tooltip>
  );
};

export const ProductTypeLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack
      direction="row"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1, 1, 0, 0),
        p: theme.spacing(1.75, 1),
        borderBottom: theme.mixins.borderControlValue(),
        borderColor: theme.palette.divider,
      })}
    >
      {children}
    </Stack>
  );
};

export const ProductBlockedEditingLabel: FC<{ labelId: string }> = ({ labelId }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography mb={2}>{formatMessage({ id: labelId })}</Typography>
      <Typography>{formatMessage({ id: 'products-WorkingOnEdit' })}</Typography>
    </>
  );
};
