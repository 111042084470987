import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getReport } from '@schooly/api';
import { Report } from '@schooly/api';

import { AppLocation } from '../../../hooks/useAppLocation';
import { IApiSlice } from '../../scheme';

export interface ReportsPreviewState extends IApiSlice<Report> {}

export const initialState: ReportsPreviewState = {
  data: undefined,
  fetching: false,
};

export interface ReportsPreviewLocation extends AppLocation {
  state: AppLocation['state'] & {
    initialState?: Report;
  };
}

export const extraActions = {
  get: createAsyncThunk('reports/preview/get', ({ id }: { id: string; silent?: boolean }) =>
    getReport(id),
  ),
};

const slice = createSlice({
  name: 'reports/preview',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Report>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state, action) => {
        if (!action.meta.arg.silent) {
          state.data = undefined;
          state.fetching = true;
        }

        state.error = undefined;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        state.data = action.payload;
        state.fetching = false;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
