import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { RichText } from '../../../components/ui/Input';
import { useMessage } from '../../../context/messages/useMessage';

export const MessageModalPreviewMessage: FC = () => {
  const { body, attachments, actions } = useMessage();

  return (
    <Box>
      <Typography variant="h2" mb={2.5}>
        <FormattedMessage id="messages-MessageTitle" />
      </Typography>
      <RichText
        value={body}
        files={attachments}
        onAttachmentCardClick={actions.fetchAttachment}
        readOnly
      />
    </Box>
  );
};
