import { StringOrNull } from '@schooly/api';
import { DATE_FORMAT_MOMENT } from '@schooly/constants';
import moment, { Moment } from 'moment';

import { DEFAULT_DATE_FORMAT } from '../config';

export default function formatDate(
  date?: StringOrNull | Moment | Date,
  format = DATE_FORMAT_MOMENT,
) {
  if (!date) {
    return undefined;
  }

  return moment(date).format(format);
}

export function formatDateDefault(date?: StringOrNull | Moment | Date) {
  return formatDate(date, DEFAULT_DATE_FORMAT);
}
