import { Box, Checkbox, IconButton, Stack } from '@mui/material';
import { CheckIcon } from '@schooly/style';
import { FC, useCallback, useMemo, useState } from 'react';

interface UserRolePermissionsCheckboxProps {
  onCheckboxChange: () => void;
  checked: boolean;
  complexChecked?: boolean;
  editable?: boolean;
}

export const UserRolePermissionsCheckbox: FC<UserRolePermissionsCheckboxProps> = ({
  onCheckboxChange,
  checked,
  complexChecked,
  editable = true,
}) => {
  const [hover, setHover] = useState(false);

  const complexCheckedIcon = useMemo(
    () =>
      hover ? (
        <Checkbox checked={checked} onChange={onCheckboxChange} />
      ) : (
        <IconButton>
          <CheckIcon />
        </IconButton>
      ),
    [checked, hover, onCheckboxChange],
  );

  const renderContent = useCallback(() => {
    if (!editable) {
      return checked || complexChecked ? (
        <IconButton sx={{ pointerEvents: editable ? 'inherit' : 'none' }}>
          <CheckIcon />
        </IconButton>
      ) : (
        <Box sx={(theme) => ({ height: theme.spacing(4.5) })} />
      );
    }

    return !!complexChecked ? (
      complexCheckedIcon
    ) : (
      <Checkbox checked={checked} onChange={onCheckboxChange} />
    );
  }, [checked, complexChecked, complexCheckedIcon, editable, onCheckboxChange]);

  return (
    <Stack
      alignItems="center"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {renderContent()}
    </Stack>
  );
};
