import { Stack, Typography } from '@mui/material';
import { Nationalities } from '@schooly/constants';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { $enum } from 'ts-enum-util';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectSearchInput } from '../SelectSearchInput';
import { NationalityTagSelect, NationalityTagSelectProps } from './NationalityTagSelect';

type NationalityExpandedSelectProps = PropsWithChildren<{
  selectedNationalities: Nationalities[];
  onSelectNationality: (v: Nationalities) => void;
  onClear: () => void;
  onClose: () => void;
}>;

const OPTIONS = $enum(Nationalities).map((value, key) => ({ value, label: key }));

export const NationalityExpandedSelect: FC<NationalityExpandedSelectProps> = ({
  selectedNationalities,
  onSelectNationality,
  onClose,
  onClear,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState('');
  const renderContent = useCallback(() => {
    const result = OPTIONS.filter(({ label }) =>
      label.toLowerCase().includes(query.toLowerCase()),
    ).map((v) => v.value);

    if (!result.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderNationalityTags({
          nationalities: result,
          onClick: (nationality) => {
            onSelectNationality(nationality);
            setQuery('');
          },
          tagProps: (nationality) => ({
            variant: selectedNationalities.includes(nationality) ? 'filled' : undefined,
          }),
        })}
      </Stack>
    );
  }, [onSelectNationality, query, selectedNationalities]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedNationalities.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
    >
      {renderNationalityTags({
        nationalities: selectedNationalities,
        onDelete: onSelectNationality,
        tagProps: { size: 'small' },
      })}

      <SelectSearchInput ref={inputRef} autoFocus value={query} onChangeText={setQuery} />
    </ExpandedSelect>
  );
};

type RenderNationalityTagsParams = {
  nationalities: Nationalities[];
  onDelete?: (v: Nationalities) => void;
  onClick?: (v: Nationalities) => void;
  tagProps?:
    | Omit<NationalityTagSelectProps, 'nationality'>
    | ((v: Nationalities) => Omit<NationalityTagSelectProps, 'nationality'>);
};

export const renderNationalityTags = ({
  nationalities,
  onDelete,
  onClick,
  tagProps,
}: RenderNationalityTagsParams) =>
  nationalities.map((v) => (
    <NationalityTagSelect
      key={v}
      nationality={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
