import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 7C10.1046 7 11 6.10457 11 5C11 4.63571 10.9026 4.29417 10.7324 4H14C14.5523 4 15 4.44772 15 5V8.26756C15.2942 8.09739 15.6357 8 16 8C17.1046 8 18 8.89543 18 10C18 11.1046 17.1046 12 16 12C15.6357 12 15.2942 11.9026 15 11.7324V15C15 15.5523 14.5523 16 14 16H4C3.44772 16 3 15.5523 3 15V5C3 4.44772 3.44772 4 4 4H7.26756C7.09739 4.29417 7 4.63571 7 5C7 6.10457 7.89543 7 9 7Z"
      fill="#B3BBCB"
    />
  </svg>
);
