import { ApiError } from '@schooly/api';
import { WithNationality } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { NATIONALITY_OPTIONS, USER_NATIONALITIES_MAX_COUNT } from '@schooly/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  pickFormFieldsFromUser,
  processFormData,
  UserFormData,
} from '../../../components/common/ProfileModal/profileEditSchema';
import { FormSelect } from '../../../components/ui/Input';
import { hasFieldValue } from '../../../components/ui/Input/utils';
import { useProfile } from '../../../context/profile/useProfile';
import IntlError from '../../../utils/intlError';
import { PropertyModal } from './PropertyModal';

interface NationalityModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const getUserNationalityFields = ({ nationality, other_nationalities }: WithNationality) => ({
  nationality: hasFieldValue(nationality) ? Number(nationality) : null,
  other_nationalities: other_nationalities ? other_nationalities.map(Number) : null,
});

const NationalityModal = ({ onClose, isOpen }: NationalityModalProps) => {
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, actions } = useProfile();

  const form = useForm<UserFormData>({
    mode: 'onChange',
    defaultValues: pickFormFieldsFromUser(user!),
  });

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      try {
        setIsUpdating(true);

        const res = await actions.updateProfile(getUserNationalityFields(processFormData(data)));
        if (!res) return;

        showNotification({
          textId: 'confirmation-Nationality',
          type: 'success',
        });
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, showNotification, showError, onClose],
  );

  const { reset } = form;

  useEffect(() => {
    if (user) {
      reset(pickFormFieldsFromUser(user));
    }
  }, [user, showNotification, onClose, reset]);

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <FormSelect
        name="nationalities"
        labelTextId="peopleDetail-Nationality"
        multiLabelTextId="peopleDetail-Nationality-plural"
        maxCount={USER_NATIONALITIES_MAX_COUNT}
        multiple
        options={NATIONALITY_OPTIONS}
      />
    </PropertyModal>
  );
};

export default NationalityModal;
