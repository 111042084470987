import { Icon, Stack } from '@mui/material';
import { useGetSchoolPaymentFrequencies } from '@schooly/api';
import { ContextMenu, DropdownIcon, SimpleButton } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

type SetBulkFrequencyButtonProps = {
  schoolId: string;
  yearId: string;
  optionIds: string[];
  onSetFrequency: (id: string) => void;
};

export const SetBulkFrequencyButton: FC<SetBulkFrequencyButtonProps> = ({
  schoolId,
  yearId,
  optionIds,
  onSetFrequency,
}) => {
  const { formatMessage } = useIntl();

  const { data } = useGetSchoolPaymentFrequencies({ school_id: schoolId, year_id: yearId });

  const actions = optionIds.map((id) => ({
    labelTextId: `frequencies-${data?.frequencies.find((f) => f.id === id)?.type}`,
    onClick: () => onSetFrequency(id),
  }));

  return (
    <ContextMenu
      actions={actions}
      PopperProps={{ disablePortal: false }}
      componentsProps={{
        tooltip: {
          sx: {
            minWidth: 215,
            p: 1,
            '&&.MuiTooltip-tooltip': {
              mt: 0,
            },
          },
        },
      }}
      renderMenuItem={(action, handleClose) => (
        <SimpleButton
          key={action.labelTextId}
          onClick={() => {
            action.onClick();
            handleClose();
          }}
          sx={(theme) => ({
            width: '100%',
            p: 0.5,
            justifyContent: 'flex-start',
            borderRadius: theme.spacing(0.5),
            ':hover': {
              backgroundColor: 'background.default',
            },
            ':active': {
              p: 0.5,
            },
          })}
        >
          {formatMessage({ id: action.labelTextId })}
        </SimpleButton>
      )}
    >
      {(open, opened) => (
        <Stack height="100%" alignItems="center" justifyContent="center">
          <SimpleButton
            onClick={open}
            endIcon={
              <Icon
                sx={{
                  rotate: opened ? '180deg' : 0,
                  color: opened ? 'primary.main' : 'common.grey',
                }}
              >
                <DropdownIcon />
              </Icon>
            }
            sx={(theme) => ({ gap: theme.spacing(0.5) })}
          >
            {formatMessage({ id: 'products-SetForAllProducts' })}
          </SimpleButton>
        </Stack>
      )}
    </ContextMenu>
  );
};
