import { IconButton, Paper, Stack } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { usePrevious } from '@schooly/hooks/use-previous';
import { DeleteIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  FieldPath,
  FieldPathValue,
  useFieldArray,
  useFormContext,
  Validate,
} from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../../components/uikit/Dnd/dnd.styled';
import { SchoolGeneralAgeGroupRowSmall } from './SchoolGeneralAgeGroupRowSmall';
import {
  AgeGroupInForm,
  AgeGroupsDroppable,
  getEmptyAgeGroup,
  SchoolGeneralAgeGroupsForm,
} from './useSchoolGeneralAgeGroupsModal';

interface SchoolGeneralAgeGroupsLevelProps {
  levelIndex: number;
  handleDeleteSchoolLevel: (index: number) => void;
  shouldFocusLevelInput: boolean;
  schoolLevelsCount: number;
  autoSwitchedArchivedOn: React.MutableRefObject<boolean>;
  setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;
  getAllAgeGroups: () => AgeGroupInForm[];
  validateAgeGroup: (
    id: string,
  ) => Validate<
    FieldPathValue<SchoolGeneralAgeGroupsForm, FieldPath<SchoolGeneralAgeGroupsForm>>,
    SchoolGeneralAgeGroupsForm
  >;
  validateSchoolLevel: (
    index: number,
  ) => Validate<
    FieldPathValue<SchoolGeneralAgeGroupsForm, FieldPath<SchoolGeneralAgeGroupsForm>>,
    SchoolGeneralAgeGroupsForm
  >;
}

export const SchoolGeneralAgeGroupsLevel: FC<SchoolGeneralAgeGroupsLevelProps> = ({
  levelIndex,
  handleDeleteSchoolLevel,
  shouldFocusLevelInput,
  schoolLevelsCount,
  validateAgeGroup,
  autoSwitchedArchivedOn,
  setShowArchived,
  getAllAgeGroups,
  validateSchoolLevel,
}) => {
  const { $t } = useIntl();

  const { control, watch } = useFormContext<SchoolGeneralAgeGroupsForm>();
  const level = watch(`schoolLevels.${levelIndex}`);
  const levelId = level.id || level.id_tmp || '';

  const ageGroups = level.ageGroups;
  const prevAgeGroups = usePrevious(ageGroups);

  const ageGroupAdded = prevAgeGroups && ageGroups && ageGroups.length - prevAgeGroups.length === 1;
  const shouldFocusAgeGroupInput = Boolean(ageGroupAdded);

  const { append } = useFieldArray({
    control: control,
    name: `schoolLevels.${levelIndex}.ageGroups`,
  });

  const schoolLevels = watch('schoolLevels');
  const levelName = watch(`schoolLevels.${levelIndex}.name`);

  const addAgeGroupToLevel = useCallback(() => {
    append({ ...getEmptyAgeGroup(), level_id: levelId });
  }, [append, levelId]);

  return (
    <Paper
      elevation={0}
      sx={(theme) => ({
        p: theme.spacing(2),
        bgcolor: 'background.default',
        width: '100%',
        overflow: 'hidden',
      })}
    >
      <Stack gap={2}>
        <Stack direction="row" gap={1} alignItems="flex-start">
          <ControlTextField
            canClear
            control={control}
            solid
            name={`schoolLevels.${levelIndex}.name`}
            label={$t({ id: 'school-tabs-AgeGroups-SchoolLevelName' })}
            fullWidth
            rules={{ required: true, validate: validateSchoolLevel(levelIndex) }}
            autoFocus={
              schoolLevels && levelIndex === schoolLevels.length - 1 && shouldFocusLevelInput
            }
          />

          <IconButton
            inverse
            onClick={() => handleDeleteSchoolLevel(levelIndex)}
            sx={(theme) => ({ mt: theme.spacing(1.5) })}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>

        <Droppable droppableId={levelId} type={AgeGroupsDroppable.AGEGROUP}>
          {(provided) => (
            <DndListWrapper
              {...provided.droppableProps}
              ref={provided.innerRef}
              gap={1}
              sx={{ overflow: 'visible' }}
            >
              {level.ageGroups.map((ageGroup, index) => (
                <Draggable
                  key={ageGroup.id || ageGroup.id_tmp}
                  draggableId={ageGroup.id || ageGroup.id_tmp || ''}
                  index={index}
                >
                  {(provided) => (
                    <SchoolGeneralAgeGroupRowSmall
                      provided={provided}
                      levelAgeGroupsCount={level.ageGroups.length}
                      shouldFocus={shouldFocusAgeGroupInput && index === level.ageGroups.length - 1}
                      levelIndex={levelIndex}
                      ageGroupIndex={index}
                      schoolLevelsCount={schoolLevelsCount}
                      validateAgeGroup={validateAgeGroup}
                      autoSwitchedArchivedOn={autoSwitchedArchivedOn}
                      setShowArchived={setShowArchived}
                      getAllAgeGroups={getAllAgeGroups}
                    />
                  )}
                </Draggable>
              ))}

              {provided.placeholder}

              <SimpleButton
                disabled={!levelName && !level.ageGroups.length}
                startIcon={<PlusIcon />}
                onClick={addAgeGroupToLevel}
                sx={(theme) => ({
                  mt: level.ageGroups.length ? theme.spacing(1) : 0,
                  alignSelf: 'flex-start',
                  '&.MuiButton-root.Mui-disabled': { bgcolor: 'background.default' },
                })}
              >
                <FormattedMessage id="school-tabs-AgeGroups-AddAgeGroup" />
              </SimpleButton>
            </DndListWrapper>
          )}
        </Droppable>
      </Stack>
    </Paper>
  );
};
