/* eslint-disable simple-import-sort/imports */
import {
  Assessment,
  AvailableCriteria,
  CriteriaUpdate,
  FilterElementType,
  Group,
  Message,
  ProfileUserType,
  RelationGroup,
  Report,
  SchoolUserType,
  SyncUser,
  UserType,
} from '@schooly/api';
import { Genders, Nationalities, SchoolPropertyType, SchoolUserRole } from '@schooly/constants';

import { convertApiTimestamp } from '../utils/convertApiTimestamp';
import searchWords from '../utils/searchWords';

export const noop = () => null;

export const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export function isSchoolUserType(type: UserType | null): type is SchoolUserType {
  return type === 'staff' || type === 'parent' || type === 'student';
}

export function isProfileUserType(type: UserType): type is ProfileUserType {
  return type === 'adult' || type === 'child';
}

export function getUserTypeWithoutHiveMembership(type: UserType): UserType {
  switch (type) {
    case 'parent':
    case 'staff':
      return 'adult';
    case 'student':
      return 'child';
    default:
      return type;
  }
}

export function getUserTypeTextId(type: UserType, plural?: boolean) {
  return `userType-${type}${plural ? '-plural' : ''}`;
}

export function getSchoolUserRoleTextId(type: SchoolUserRole, plural?: boolean) {
  switch (type) {
    case SchoolUserRole.Admin:
    case SchoolUserRole.Staff:
      return `userType-staff${plural ? '-plural' : ''}`;
    case SchoolUserRole.Student:
      return `userType-student${plural ? '-plural' : ''}`;
    case SchoolUserRole.Parent:
      return `userType-parent${plural ? '-plural' : ''}`;
    default:
      return 'none';
  }
}

export function convertSchoolUserRoleToTextType(type: SchoolUserRole): SchoolUserType {
  switch (type) {
    case SchoolUserRole.Student:
      return 'student';
    case SchoolUserRole.Parent:
      return 'parent';
    case SchoolUserRole.Admin:
    case SchoolUserRole.Staff:
    default:
      return 'staff';
  }
}

export function convertSchoolUserRoleToNumericType(type: SchoolUserType): SchoolUserRole {
  switch (type) {
    case 'student':
      return SchoolUserRole.Student;
    case 'parent':
      return SchoolUserRole.Parent;
    case 'staff':
    default:
      return SchoolUserRole.Staff;
  }
}

export function filterAssessments(assessment: Assessment, filter: string) {
  return searchWords(assessment.name, filter);
}

export function filterGroups(group: Group | RelationGroup, filter: string) {
  return searchWords(group.name, filter);
}

export function filterMessages(message: Message, filter: string) {
  return searchWords(message.title, filter);
}

export function filterReports(report: Report, filter: string) {
  return searchWords(report.name, filter);
}

export function getSchoolPropertyTypeTextId(type: SchoolPropertyType) {
  switch (type) {
    case SchoolPropertyType.AgeGroup:
      return 'schoolProperty-AgeGroup';
    case SchoolPropertyType.Campus:
      return 'schoolProperty-Campus';
    case SchoolPropertyType.House:
      return 'schoolProperty-House';
    case SchoolPropertyType.Status:
      return 'schoolProperty-Status';
    default:
      return undefined;
  }
}

export function getCriteriaTypeTextId(type?: FilterElementType | SchoolPropertyType) {
  switch (type) {
    case FilterElementType.Gender: {
      return 'peopleDetail-Gender';
    }
    case FilterElementType.Nationality: {
      return 'peopleDetail-Nationality';
    }
    case FilterElementType.Group: {
      return 'groups-Group-plural';
    }
    case FilterElementType.Property:
    default: {
      return getSchoolPropertyTypeTextId(type as SchoolPropertyType);
    }
  }
}

export function getCriteriaName(criteria: AvailableCriteria) {
  switch (criteria.type) {
    case FilterElementType.Gender: {
      return Genders[criteria.enum_index!];
    }
    case FilterElementType.Nationality: {
      return Nationalities[criteria.enum_index!];
    }
    case FilterElementType.Subject: {
      return criteria.subject?.name ?? '';
    }
    case FilterElementType.Group: {
      return criteria.group?.name ?? '';
    }
    case FilterElementType.Property:
    default: {
      return criteria.school_property?.name ?? '';
    }
  }
}

export function getCriteriaArchived(criteria: AvailableCriteria) {
  switch (criteria.type) {
    case FilterElementType.Subject:
      return Boolean(criteria.subject!.archived);
    case FilterElementType.Property:
    default:
      return Boolean(criteria.school_property?.archived);
  }
}

export function getCriteriaArchivedTooltip(criteria: AvailableCriteria) {
  const isArchived = getCriteriaArchived(criteria);

  if (!isArchived) {
    return undefined;
  }

  switch (criteria.type) {
    case FilterElementType.Subject:
      return 'schoolProperty-Archived-subject';
    case FilterElementType.Property:
      return `schoolProperty-Archived-${criteria.school_property?.type}`;
    default:
      return undefined;
  }
}

export function getCriteriaItemKey(criteria: AvailableCriteria) {
  let id;

  if (criteria.type === FilterElementType.Property) {
    id = criteria.school_property!.id;
  } else if (criteria.type === FilterElementType.Subject) {
    id = criteria.subject!.id;
  } else if (criteria.type === FilterElementType.Group) {
    id = criteria.group!.id;
  } else {
    id = criteria.enum_index!;
  }

  return `${FilterElementType[criteria.type as FilterElementType]}-${id}`;
}

export function buildCriteriaUpdate(criteria: AvailableCriteria[]): CriteriaUpdate {
  // collect criteria excluding duplicates
  const data = criteria.reduce<{
    school_property_ids: Set<string>;
    genders: Set<Genders>;
    nationalities: Set<Nationalities>;
    groups: Set<string>;
    subjects?: Set<string>;
  }>(
    (acc, item) => {
      if (item.school_property) {
        acc.school_property_ids.add(item.school_property.id);
      }

      if (item.type === FilterElementType.Gender) {
        acc.genders.add(item.enum_index as Genders);
      }

      if (item.type === FilterElementType.Nationality) {
        acc.nationalities.add(item.enum_index as Nationalities);
      }

      if (item.type === FilterElementType.Subject) {
        if (!acc.subjects) {
          acc.subjects = new Set();
        }

        acc.subjects.add(item.subject!.id);
      }

      if (item.type === FilterElementType.Group) {
        acc.groups.add(item.group!.id);
      }

      return acc;
    },
    {
      school_property_ids: new Set(),
      genders: new Set(),
      nationalities: new Set(),
      groups: new Set(),
    },
  );

  // prepare CriteriaUpdate data
  return (Object.keys(data) as (keyof typeof data)[]).reduce<CriteriaUpdate>(
    (prev, key) => {
      if (data[key]) {
        prev[key] = Array.from(data[key] as Set<string>) as any;
      }

      return prev;
    },
    {
      school_property_ids: [],
      genders: [],
      nationalities: [],
      groups: [],
    },
  );
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function fromKebebCaseToPascalCase(str?: string) {
  return str
    ?.split('-')
    .map((w) => capitalizeFirstLetter(w))
    .join('');
}

export function hasStaffUserType(user?: SyncUser) {
  return Boolean(user?.school_relations.find((s) => s.role === SchoolUserRole.Staff));
}

export function getRouteModalPathname(
  type:
    | UserType
    | 'message'
    | 'group'
    | 'assessment'
    | 'report'
    | 'attendance'
    | 'userroles'
    | 'applications',
  user: { id?: string; relation_id?: string; user_id?: string },
) {
  const { id, relation_id, user_id } = user || {};
  const relationId = relation_id ?? id;
  const userId = user_id ?? id;

  switch (type) {
    case 'parent':
    case 'student':
      return `/${type}s/${relationId}`;
    case 'staff':
      return `/${type}/${relationId}`;
    case 'adult':
      return `/${type}s/${userId}`;
    case 'child':
      return `/children/${userId}`;
    case 'message':
    case 'group':
    case 'assessment':
    case 'report':
      return `/${type}s/${relationId}`;
    case 'attendance':
    default:
      return `/${type}/${relationId}`;
  }
}

export function getRouteAddModalPathname(
  type:
    | SchoolUserType
    | 'group'
    | 'message'
    | 'assessment'
    | 'report'
    | 'attendance'
    | 'conduct'
    | 'event',
) {
  switch (type) {
    case 'parent':
    case 'student':
    case 'group':
    case 'message':
    case 'assessment':
      return `/${type}s/add-${type}`;
    case 'staff':
      return `/${type}/add-${type}`;
    default:
      return '';
  }
}

export const getRelationRoleByUserType = (userType: UserType, segment?: SchoolUserRole) => {
  switch (userType) {
    case 'student':
    case 'child':
      return SchoolUserRole.Student;
    case 'adult':
      return segment === SchoolUserRole.Parent ? SchoolUserRole.Parent : SchoolUserRole.Staff;
    case 'parent':
      return SchoolUserRole.Parent;
    case 'staff':
      return SchoolUserRole.Staff;
    default:
      return null;
  }
};

export const retrieveApiDate = (date?: number) => {
  if (date == null) return undefined;

  const timestamp = convertApiTimestamp(date);

  return new Date(Number(timestamp));
};

export const isDescendant = (parent: Node, child: Node) => {
  let node = child.parentNode;

  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
};
