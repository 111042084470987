import { IconButton, Stack, Typography } from '@mui/material';
import { Company } from '@schooly/api';
import {
  CrossIcon,
  EditIcon,
  ModalContent,
  ModalHeader,
  ModalLarge,
  ModalMain,
} from '@schooly/style';
import { FC } from 'react';

import { CollapsiblePanel } from '../../../components/common/CollapsiblePanel';
import { ModalSidebarItem } from '../../../components/ui/ModalSidebar';
import { CompanyPreviewModalTabs } from '../../../context/companies/WithCompany';
import { CompanyPreviewModalAbout } from './CompanyPreviewModalAbout';
import { CompanyPreviewModalPayableFees } from './CompanyPreviewModalPayableFees';
import { CompanyPreviewModalStudents } from './CompanyPreviewModalStudents';
import { CompanyPreviewModalSideBar } from './CompanyPreviewSideBar';

interface CompanyPreviewContentProps {
  onClose: () => void;
  onEdit?: () => void;
  activeTab?: CompanyPreviewModalTabs;
  company: Company;
  handleChangeActiveTab: (item: ModalSidebarItem<CompanyPreviewModalTabs>) => void;
}

export const CompanyPreviewModalContent: FC<CompanyPreviewContentProps> = ({
  onClose,
  onEdit,
  handleChangeActiveTab,
  activeTab,
  company,
}) => {
  const renderContent = () => {
    switch (activeTab) {
      case CompanyPreviewModalTabs.About:
        return <CompanyPreviewModalAbout company={company} />;
      case CompanyPreviewModalTabs.Students:
        return <CompanyPreviewModalStudents />;
      case CompanyPreviewModalTabs.PayableFees:
        return <CompanyPreviewModalPayableFees company={company} />;
      default:
        return null;
    }
  };

  return (
    <ModalLarge open onClose={onClose}>
      <ModalHeader
        active
        sx={{ alignItems: 'center' }}
        title={
          <Stack height="100%">
            <Typography variant="h2" color="primary.main">
              {company.name}
            </Typography>
          </Stack>
        }
      >
        <Stack flexDirection="row" gap={2.5}>
          {onEdit && (
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          )}
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </Stack>
      </ModalHeader>

      <ModalMain>
        <CollapsiblePanel>
          <CompanyPreviewModalSideBar handleChangeActiveTab={handleChangeActiveTab} />
        </CollapsiblePanel>

        <ModalContent>{renderContent()}</ModalContent>
      </ModalMain>
    </ModalLarge>
  );
};
