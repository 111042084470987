import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import {
  BoldIcon,
  ItalicIcon,
  LinkIcon,
  ListOlIcon,
  ListUlIcon,
  SimpleButton,
  UnderlineIcon,
} from '@schooly/style';
import { buildClassName } from '@schooly/utils/build-classname';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { AttachmentProps } from './Attachments/attachment-types';
import { AttachmentButton } from './Attachments/AttachmentButton';
import { ToolbarStyled } from './Toolbar.styled';

export interface ToolbarProps extends AttachmentProps {
  id: string;
  className?: string;
  title?: EmotionJSX.Element;
  withoutAttachments?: boolean;
}

export const Toolbar: React.FC<PropsWithChildren<ToolbarProps>> = ({
  id,
  className,
  title,
  withoutAttachments,
  children,
  ...attachmentProps
}) => (
  <ToolbarStyled
    id={id}
    className={buildClassName(ToolbarStyled.defaultProps?.className, className)}
  >
    {title && title}
    <Stack direction="row" pr={2} gap={2.5}>
      <IconButton className="ql-bold">
        <BoldIcon />
      </IconButton>
      <IconButton className="ql-italic">
        <ItalicIcon />
      </IconButton>
      <IconButton className="ql-underline">
        <UnderlineIcon />
      </IconButton>
    </Stack>
    <Divider orientation="vertical" flexItem />
    <Stack direction="row" px={2} gap={2.5}>
      <IconButton value="bullet" className="ql-list">
        <ListOlIcon />
      </IconButton>
      <IconButton value="ordered" className="ql-list">
        <ListUlIcon />
      </IconButton>
    </Stack>
    <Divider orientation="vertical" flexItem />
    <Box
      sx={{
        px: 2,
        '.MuiButtonBase-root': {
          gap: 0,
        },
        '.ql-link': {
          svg: {
            height: '16px !important',
          },
        },
      }}
    >
      <SimpleButton
        className="Toolbar__button ql-link"
        sx={(theme) => ({
          ...theme.typography.body1,
          '.ql-snow.ql-toolbar &:hover, .ql-snow.ql-toolbar &:focus': {
            color: theme.palette.text.primary,
          },
        })}
        startIcon={<LinkIcon />}
      >
        <FormattedMessage id="link" />
      </SimpleButton>
    </Box>
    <Divider orientation="vertical" flexItem />

    {children && (
      <Box px={2}>
        {children}
        <Divider orientation="vertical" flexItem />
      </Box>
    )}

    {!withoutAttachments && (
      <div className="Toolbar__section">
        <AttachmentButton {...attachmentProps} />
      </div>
    )}
  </ToolbarStyled>
);
