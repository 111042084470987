import { Stack, Typography } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Assessment, AssessmentMethod, AssessmentMethodType, GroupMember } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { DataGridTable } from '@schooly/components/data-grid';
import { Loading } from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import {
  AssessmentEntryMethod,
  getAssessmentParams,
} from '../../../../components/uikit-components/AssessmentEntryMethods/AssessmentEntryMethod';
import { CommentControllerProps } from '../../../../components/uikit-components/AssessmentEntryMethods/methods/CommentController';
import { GradeControllerProps } from '../../../../components/uikit-components/AssessmentEntryMethods/methods/GradeController';
import { ScoreControllerProps } from '../../../../components/uikit-components/AssessmentEntryMethods/methods/ScoreController';
import { useAssessmentEntriesForGroup } from './hooks';

const METHOD_CELL_WIDTH = 112;
const ROW_HEIGHT = 45;

interface AssessmentsPreviewEntriesTableProps {
  assessment: Assessment;
  groupId: string;
}

type CellEditChecker = (params: GridCellParams<GridValidRowModel, GroupMember>) => boolean;

interface RenderAssessmentEntryMethodProps {
  student: GroupMember;
  method: AssessmentMethod;
  scoreControllerProps?: Partial<ScoreControllerProps>;
  gradeControllerProps?: Partial<GradeControllerProps>;
  commentControllerProps?: Partial<CommentControllerProps>;
}

export const AssessmentsPreviewEntriesTable: FC<AssessmentsPreviewEntriesTableProps> = ({
  assessment,
  groupId,
}) => {
  const apiRef = useGridApiRef();
  const { formatMessage } = useIntl();
  const { permissions } = useAuth();

  const { assessmentForGroup, group, isEntriesLoading, isGroupLoading, assessmentGrade } =
    useAssessmentEntriesForGroup({
      assessment: assessment,
      groupId,
    });

  const isScoreCellEditable = useCallback(() => {
    const methodType = AssessmentMethodType.Score;
    const method = assessment?.methods.find((method) => method.method_type === methodType);
    const canEdit = Boolean(
      method?.method_id &&
        (permissions.includes('assessment_manager') || assessmentForGroup?.enterable_and_my_group),
    );
    return canEdit;
  }, [assessment.methods, permissions, assessmentForGroup]);

  const isGradeCellEditable = useCallback(() => {
    const methodType = AssessmentMethodType.Grade;
    const method = assessment?.methods.find((method) => method.method_type === methodType);
    const canEdit = Boolean(
      method?.method_id &&
        (permissions.includes('assessment_manager') || assessmentForGroup?.enterable_and_my_group),
    );
    return canEdit;
  }, [assessment.methods, permissions, assessmentForGroup]);

  const isCommentCellEditable = useCallback(
    (params: GridCellParams<GridValidRowModel, GroupMember>) => {
      const { value: student } = params;
      const methodType = AssessmentMethodType.Comment;
      const method = assessment?.methods.find((method) => method.method_type === methodType);
      const canAdd = Boolean(method?.method_id && assessmentForGroup?.enterable_and_my_group);
      const comments =
        method &&
        student?.user?.relation_id &&
        getAssessmentParams(student.user.relation_id, method, assessmentForGroup)?.entries;
      const hasComments = !!comments?.length;

      return canAdd || hasComments;
    },
    [assessment.methods, assessmentForGroup],
  );

  const cellTypeCheckers: Record<string, CellEditChecker> = useMemo(
    () => ({
      'assessment-score': isScoreCellEditable,
      'assessment-grade': isGradeCellEditable,
      'assessment-comment': isCommentCellEditable,
    }),
    [isCommentCellEditable, isScoreCellEditable, isGradeCellEditable],
  );

  const isCellEditable = useCallback(
    (params: GridCellParams) => {
      const field = params.field;
      const cellTypeChecker = cellTypeCheckers[field];
      return cellTypeChecker
        ? cellTypeChecker(params as GridCellParams<GridValidRowModel, GroupMember>)
        : false;
    },
    [cellTypeCheckers],
  );
  const rows = useMemo(() => {
    if (!group || (group && !group.students.length)) {
      return [];
    }

    return group.students.map((student) => ({
      id: student.user?.relation_id,
      'assessment-students': student,
      'assessment-score': student,
      'assessment-grade': student,
      'assessment-comment': student,
    }));
  }, [group]);

  if (isGroupLoading || isEntriesLoading) {
    return <Loading />;
  }

  if (!group || group.students.length <= 0 || !assessmentForGroup) {
    return (
      <Stack
        sx={{
          flex: 1,
          gap: 2.5,
          alignItems: 'center',
          justifyContent: 'center',
          '& img': {
            height: 150,
          },
        }}
      >
        <picture>
          <source srcSet="/images/no-schools.png, /images/no-schools@2x.png 2x" />
          <img src="/images/no-schools.png" alt={formatMessage({ id: 'mainLayout-empty-title' })} />
        </picture>
        <Typography>
          <FormattedMessage id="assessments-EmptyEntries" />
        </Typography>
      </Stack>
    );
  }

  const renderAssessmentEntryMethod = ({
    student,
    method,
    scoreControllerProps,
    gradeControllerProps,
    commentControllerProps,
  }: RenderAssessmentEntryMethodProps) => {
    return (
      <AssessmentEntryMethod
        relationId={student.user?.relation_id}
        groupId={group.id}
        method={method}
        assessmentForGroup={assessmentForGroup}
        lists={assessmentGrade ? { [assessmentGrade.id]: assessmentGrade } : undefined}
        scoreControllerProps={scoreControllerProps}
        gradeControllerProps={gradeControllerProps}
        commentControllerProps={commentControllerProps}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'assessment-students',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 250,
      cellClassName: 'studentNameCell',
      renderCell: (props: GridRenderCellParams<GridValidRowModel, GroupMember>) => {
        const { value: student } = props;
        if (!student) return null;

        return (
          <Stack
            pl={1}
            sx={{
              maxWidth: '100%',
              '& .card-body': {
                maxHeight: 44,
              },
            }}
          >
            <PersonCardBasic
              user={student.user}
              userType="student"
              isListItem
              isUsernameClickable
            />
          </Stack>
        );
      },
    },
    ...assessment.methods.map((method): GridColDef => {
      if (method.method_type === AssessmentMethodType.Score) {
        return {
          field: 'assessment-score',
          sortable: false,
          disableColumnMenu: true,
          width: METHOD_CELL_WIDTH,
          editable: true,
          cellClassName: 'scoreCell',

          renderCell: (props: GridRenderCellParams<GridValidRowModel, GroupMember>) => {
            const { value: student } = props;
            const scoreControllerProps = {
              focused: false,
            };

            return (
              <Stack
                sx={{
                  minWidth: METHOD_CELL_WIDTH,
                  '.MuiDataGrid-row:last-of-type &': {
                    height: ROW_HEIGHT,
                  },
                }}
              >
                {student && renderAssessmentEntryMethod({ student, method, scoreControllerProps })}
              </Stack>
            );
          },
          renderEditCell: (props: GridRenderCellParams<GridValidRowModel, GroupMember>) => {
            const { value: student } = props;
            if (!student) return null;
            const scoreControllerProps = {
              focused: true,
            };

            return (
              <Stack
                sx={{
                  minWidth: METHOD_CELL_WIDTH,
                  '.MuiDataGrid-row:last-of-type &': {
                    height: ROW_HEIGHT,
                  },
                }}
              >
                {renderAssessmentEntryMethod({ student, method, scoreControllerProps })}
              </Stack>
            );
          },
        };
      }

      if (method.method_type === AssessmentMethodType.Grade) {
        const canOpen = Boolean(
          method.method_id &&
            (permissions.includes('assessment_manager') ||
              assessmentForGroup.enterable_and_my_group),
        );

        return {
          field: 'assessment-grade',
          sortable: false,
          disableColumnMenu: true,
          width: METHOD_CELL_WIDTH,
          editable: canOpen ? true : false,
          cellClassName: 'gradeCell',
          renderCell: (props: GridRenderCellParams<GridValidRowModel, GroupMember>) => {
            const { value: student } = props;
            if (!student) return null;
            const gradeControllerProps = {
              open: false,
              onFocus: (e: React.FocusEvent) => e.stopPropagation,
            };

            return (
              <Stack
                sx={{
                  minWidth: METHOD_CELL_WIDTH,
                }}
              >
                {renderAssessmentEntryMethod({ student, method, gradeControllerProps })}
              </Stack>
            );
          },
          renderEditCell: (props: GridRenderCellParams<GridValidRowModel, GroupMember>) => {
            const { value: student } = props;
            if (!student) return null;
            const gradeControllerProps = {
              open: true,
              disableEscapeKeyDown: true,
              noStopPropagationOnSelect: true,
              disableSpaceKeyPress: true,
            };

            return (
              <Stack sx={{ minWidth: METHOD_CELL_WIDTH }}>
                {renderAssessmentEntryMethod({ student, method, gradeControllerProps })}
              </Stack>
            );
          },
        };
      } else {
        return {
          field: 'assessment-comment',
          sortable: false,
          disableColumnMenu: true,
          align: 'center',
          editable: true,
          cellClassName: 'commentCell',

          width: METHOD_CELL_WIDTH,
          renderCell: (props: GridRenderCellParams<GridValidRowModel, GroupMember>) => {
            const { value: student } = props;
            if (!student) return null;
            const commentControllerProps = {
              tableView: true,
              onFocus: (e: React.FocusEvent) => e.stopPropagation,
            };

            return (
              <Stack
                sx={{
                  minWidth: METHOD_CELL_WIDTH,
                  height: 44,
                }}
              >
                {renderAssessmentEntryMethod({ student, method, commentControllerProps })}
              </Stack>
            );
          },
          renderEditCell: (props: GridRenderCellParams<GridValidRowModel, GroupMember>) => {
            const { value: student } = props;
            if (!student) return null;
            const commentControllerProps = {
              tableView: true,
              open: true,
              autoFocusOnEdit: true,
              disableEscapeKeyDown: true,
              saveOnEnterPress: true,
            };

            return (
              <Stack sx={{ minWidth: METHOD_CELL_WIDTH, height: 44 }}>
                {renderAssessmentEntryMethod({ student, method, commentControllerProps })}
              </Stack>
            );
          },
        };
      }
    }),
  ];

  return (
    <Stack
      direction="row"
      sx={{
        overflow: 'hidden',
        height: '100%',
        py: 0.5,
      }}
    >
      <DataGridTable
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        disableVirtualization
        columnsToPin={['assessment-students']}
        slots={{
          columnHeaders: () => null,
        }}
        slotProps={{
          cell: {
            onKeyDown: (e) => {
              switch (e.key) {
                case 'Escape':
                  e.stopPropagation();
                  break;
              }
            },
          },
        }}
        isCellEditable={isCellEditable}
        stopEditModeOnDeleteKey={(field: GridCellParams['field']) => field === 'assessment-grade'}
        noNavigationOnFirstCol
        rowHeight={ROW_HEIGHT}
        sx={(theme) => ({
          '.MuiDataGrid-virtualScroller': {
            pb: theme.spacing(2.5),
          },
          '.MuiDataGrid-pinnedColumns': {
            overflow: 'visible',
            '& .MuiDataGrid-row': {
              '.studentNameCell': {
                borderRight: theme.mixins.borderValue(),
                outline: 'none',
              },
              '&:first-child': {
                borderTopLeftRadius: theme.shape.borderRadius,
              },
              '&:last-child': {
                borderBottomLeftRadius: theme.shape.borderRadius,
              },
            },
          },
          '.MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              '&:first-child': {
                borderTopRightRadius: theme.shape.borderRadius,
              },
              '&:last-child': {
                borderBottomRightRadius: theme.shape.borderRadius,
              },
            },
          },
          ' .MuiDataGrid-row': {
            '&:not(.MuiDataGrid-row--dynamicHeight)>.studentNameCell': {
              overflow: 'visible',
            },
            backgroundColor: theme.palette.background.paper,
            '&.Mui-hovered': {
              '.studentNameCell': {
                '.MuiTypography-root': {
                  color: theme.palette.primary.main,
                },
              },
            },
            ' .MuiDataGrid-withBorderColor': {
              '&.commentCell, &.gradeCell ,&.scoreCell': {
                '&:not(:last-child)': {
                  borderRight: theme.mixins.borderValue(),
                },
              },
              '&.MuiDataGrid-cell--editing': {
                '&.commentCell': {
                  borderRight: theme.mixins.borderValue(),
                },
                '&.gradeCell': {
                  borderLeft: 'none',
                  borderBottom: theme.mixins.borderValue(),
                },
                '&.scoreCell': {
                  paddingX: 0,
                  '&.MuiDataGrid-cell:focus-within': {
                    overflow: 'visible',
                    outlineColor: theme.palette.common.light3,
                    '& .MuiStack-root': {
                      borderColor: 'transparent',
                    },
                  },
                },
              },
            },
          },
        })}
      />
    </Stack>
  );
};
