import { Stack, Typography, useTheme } from '@mui/material';
import { PaymentFrequency, ProductFormType, ProductSaveVariant } from '@schooly/api';
import { Grid, GridBody, GridCell, GridRow, Price, PRICE_SUBTEXT_CLASS_NAME } from '@schooly/style';
import React, { FC } from 'react';

import { useTableHover } from '../../../../context/table/tableHover/useTableHover';
import {
  HOVER_CLASS_NAME,
  SELF_HOVER_CLASS_NAME,
  WithTableHover,
} from '../../../../context/table/tableHover/WithTableHover';
import { ProductApplicable } from '../ProductsApplicable';
import { HalfDayIconComponent } from '../SchoolProductCommon';
import {
  Cell,
  HOVER_TEXT_CLASS_NAME,
  variantBaseCells,
  VariantsTableHeader,
} from '../SchoolProductCreateModal/CreateVariantsTable';

type PreviewVariantsTableProps = {
  addedFrequencies: PaymentFrequency[];
  canViewFrequency: boolean;
} & Omit<VariantsTableBodyProps, 'cells'>;

export const PreviewVariantsTable: FC<PreviewVariantsTableProps> = ({
  addedFrequencies,
  canViewFrequency,
  ...props
}) => {
  const cells: Cell[] = [...variantBaseCells.map((c) => ({ type: c })), ...addedFrequencies];
  const theme = useTheme();

  const hoverStyles = {
    [`.${HOVER_CLASS_NAME}`]: {
      backgroundColor: `${theme.palette.background.default} !important`,
      [`.${HOVER_TEXT_CLASS_NAME}, .${PRICE_SUBTEXT_CLASS_NAME}`]: {
        color: theme.palette.text.primary,
      },
    },
    [`.${SELF_HOVER_CLASS_NAME}`]: {
      backgroundColor: `${theme.palette.common.grey7} !important`,
      [`.${HOVER_TEXT_CLASS_NAME}, .${PRICE_SUBTEXT_CLASS_NAME}`]: {
        color: theme.palette.text.primary,
      },
    },
  };

  return (
    <WithTableHover hoverStyles={hoverStyles}>
      <Grid
        sx={(theme) => ({
          '.MuiTableCell-root:not(:last-child)': {
            borderRight: theme.mixins.borderValue(),
          },
        })}
      >
        <VariantsTableHeader
          cells={cells}
          canRemoveFrequency={false}
          canViewFrequency={canViewFrequency}
        />
        <VariantsTableBody cells={cells} {...props} />
      </Grid>
    </WithTableHover>
  );
};

type VariantsTableBodyProps = {
  variants: ProductFormType['variants'];
  cells: Cell[];
  schoolId: string;
  currencySymbol: string;
};

const VariantsTableBody: FC<VariantsTableBodyProps> = ({ cells, variants, ...props }) => {
  const { gridRef, styles } = useTableHover();

  return (
    <GridBody ref={gridRef} sx={{ ...styles }}>
      {variants.map((v, variantIdx) => (
        <VariantRow
          key={v.id}
          variantCells={cells}
          variant={v}
          variantIdx={variantIdx}
          {...props}
        />
      ))}
    </GridBody>
  );
};

type VariantRowProps = {
  variantCells: Cell[];
  variant: ProductSaveVariant;
  onRemoveVariant?: (vIdx: number) => void;
  schoolId: string;
  currencySymbol: string;
  variantIdx: number;
};

const VariantRow: FC<VariantRowProps> = ({
  variantCells,
  schoolId,
  variant,
  currencySymbol,
  variantIdx,
}) => {
  const { getRowHoverProps } = useTableHover();

  const renderCell = (c: Cell) => {
    switch (c.type) {
      case 'Half day':
        return <HalfDayIconComponent isHalfDay={variant.half_day} />;
      case 'Applicable to':
        return (
          <ProductApplicable
            schoolId={schoolId}
            selectedAgeGroupIds={variant.age_groups}
            selectedSubjectIds={variant.subjects}
            variant="h3"
            px={1}
          />
        );

      default:
        const relatedPrice = variant.prices.find((p) => p.frequency_id === c.id);

        return (
          <Stack direction="row" gap={0.5} alignItems="center">
            {relatedPrice?.price ? (
              <Price currency={currencySymbol} price={relatedPrice.price} />
            ) : (
              <Typography variant="h3" color="common.grey" className={HOVER_TEXT_CLASS_NAME}>
                -
              </Typography>
            )}
          </Stack>
        );
    }
  };

  return (
    <GridRow withoutDefaultHover>
      {variantCells.map((c, i) => {
        const props = getRowHoverProps({
          cellId: c.type,
          colIndex: i,
          rowIndex: variantIdx,
          onlyXHover: c.type === 'Applicable to',
        });

        return (
          <GridCell key={c.type} pt={1.25} pb={1.25} {...props}>
            {renderCell(c)}
          </GridCell>
        );
      })}
    </GridRow>
  );
};
