import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4C17 2.89543 16.1046 2 15 2H14V5H17V4Z"
      fill="#F2994A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 6H14C13.4477 6 13 5.55228 13 5V2H5C3.89543 2 3 2.89543 3 4V16C3 17.1046 3.89543 18 5 18H15C16.1046 18 17 17.1046 17 16V6Z"
      fill="#F2994A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.47485C10.2761 7.47485 10.5 7.69871 10.5 7.97485V12.9749C10.5 13.251 10.2761 13.4749 10 13.4749C9.72386 13.4749 9.5 13.251 9.5 12.9749V7.97485C9.5 7.69871 9.72386 7.47485 10 7.47485Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 10.4749C13 10.751 12.7761 10.9749 12.5 10.9749L7.5 10.9749C7.22386 10.9749 7 10.751 7 10.4749C7 10.1987 7.22386 9.97485 7.5 9.97485L12.5 9.97485C12.7761 9.97485 13 10.1987 13 10.4749Z"
      fill="white"
    />
  </svg>
);
