import { Components, Theme } from '@mui/material';

const variant = 'h2';

export const MuiCardHeader: Components<Theme>['MuiCardHeader'] = {
  defaultProps: {
    titleTypographyProps: {
      variant,
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(2.5, 2.5, 0),
    }),
    action: ({ theme }) => ({
      margin: 0,
      lineHeight: theme.typography[variant].lineHeight,
    }),
  },
};
