import { Box, Typography } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AgeGroupExpandedSelect,
  filterExistingFilterOptions,
  getSelectedItemsWithGrouping,
  GroupTagSelect,
  MoreButtonOption,
  PropertyTypeExpandedSelect,
  PropertyTypeTagSelect,
  renderPropertyGroupTags,
  SelectedItem,
  SelectedItemWithGrouping,
  toggleMultipleValueArrayProperty,
} from '@schooly/components/filters';
import {
  FilterDropdown,
  FiltersContainer,
  GroupExpandedSelect,
  MoreButton,
} from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { EventForm } from '../../../context/events/WithEvent';
import { useSchool } from '../../../hooks/useSchool';

const INVITEES_CRITERIA = [
  FilterKeys.StudentStatus,
  FilterKeys.AgeGroup,
  FilterKeys.House,
  FilterKeys.Group,
] as const;

export interface InviteesCriteriaProps {
  schoolId: string;
  criteria: EventForm['criteria'];
  onCriteriaChange: (cb: (c: EventForm['criteria']) => EventForm['criteria']) => void;
  groupDates: { [FilterKeys.IntersectDate]: string[] };
  disabled: boolean;
}

export const InviteesCriteria: FC<InviteesCriteriaProps> = ({
  criteria,
  schoolId,
  onCriteriaChange,
  groupDates,
  disabled,
}) => {
  const { $t } = useIntl();
  const {
    getAgeGroupsByLevelId,
    getAgeGroupById,
    schoolLevelsWithAgeGroupsMap,
    getSchoolLevelById,
  } = useAgeGroups({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });
  const moreButton = useRef<MoreButton | null>(null);
  const { permissions } = useAuth();
  const canViewGroups = permissions.includes('group_viewer');
  const { hasHouses } = useSchool();

  const toggleFiltersVisible = useCallback(
    (key: keyof EventForm['criteria']) => {
      onCriteriaChange((criteria) => {
        const isCriteriaExists = criteria[key] !== undefined;

        if (isCriteriaExists) {
          const currentCriteria = { ...criteria };
          delete currentCriteria[key];
          return currentCriteria;
        }

        return {
          ...criteria,
          [key]: [],
        };
      });
    },
    [onCriteriaChange],
  );

  const {
    onSelectStatusId,
    onClearStatus,
    onSelectGroup,
    onClearGroup,
    onSelectHouseId,
    onClearAgeGroup,
    onClearHouse,
    onSelectAgeGroup,
  } = useMemo(() => {
    const updateCriteria = (key: FilterKeys.StudentStatus | FilterKeys.House) => (id: string) => {
      onCriteriaChange((criteria) => {
        return {
          ...criteria,
          [key]: criteria[key]?.includes(id)
            ? criteria[key]?.filter((ct) => ct !== id)
            : [...(criteria[key] || []), id],
        };
      });
    };
    const onSelectGroup = (groupId: string, name?: string) => {
      onCriteriaChange((criteria) => ({
        ...criteria,
        [FilterKeys.Group]: criteria['group']?.some(({ id }) => groupId === id)
          ? criteria['group']?.filter(({ id }) => id !== groupId)
          : [...(criteria['group'] || []), { id: groupId, name: name ?? '' }],
      }));
    };
    const onSelectAgeGroup = (v: string[]) => {
      onCriteriaChange((criteria) => ({
        ...criteria,
        [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(criteria[FilterKeys.AgeGroup], v),
      }));
    };
    const clearCriteria = (key: keyof typeof criteria) => () => {
      onCriteriaChange((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    return {
      onSelectStatusId: updateCriteria(FilterKeys.StudentStatus),
      onClearStatus: clearCriteria(FilterKeys.StudentStatus),
      onSelectHouseId: updateCriteria(FilterKeys.House),
      onClearHouse: clearCriteria(FilterKeys.House),
      onSelectAgeGroup,
      onClearAgeGroup: clearCriteria(FilterKeys.AgeGroup),
      onSelectGroup: onSelectGroup,
      onClearGroup: clearCriteria('group'),
    };
  }, [onCriteriaChange]);

  const criteriaGroup = canViewGroups ? criteria.group : undefined;
  const criteriaAgeGroup = criteria.age_group;
  const criteriaHouse = criteria.house;
  const criteriaStatus = criteria.student_status;

  const houseLabel = `${$t({ id: 'schoolProperty-House' })}`;
  const groupLabel = $t({ id: 'events-group' });
  const statusLabel = $t({ id: 'schoolProperty-Status' });
  const ageGroupLabel = $t({ id: 'schoolProperty-AgeGroup' });

  const filterOptions: MoreButtonOption<
    FilterKeys.AgeGroup | FilterKeys.House | FilterKeys.Group | FilterKeys.StudentStatus
  >[] = [
    {
      value: FilterKeys.StudentStatus,
      label: statusLabel,
      required: true,
    },
    { value: FilterKeys.AgeGroup, label: ageGroupLabel },
    { value: FilterKeys.House, label: houseLabel },
    { value: FilterKeys.Group, label: groupLabel, visible: canViewGroups },
  ];

  const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups =
      criteriaAgeGroup?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [criteriaAgeGroup, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  return (
    <FiltersContainer>
      {criteriaStatus && (
        <FilterDropdown
          selectLabel={$t({ id: 'select' })}
          label={statusLabel}
          disabled={disabled}
          tags={(open) =>
            criteriaStatus.map((id) => (
              <PropertyTypeTagSelect
                userRole={SchoolUserRole.Student}
                schoolId={schoolId}
                sx={(theme) => ({ maxWidth: 200, backgroundColor: theme.palette.background.paper })}
                key={id}
                id={id}
                onClick={!disabled ? open : undefined}
              />
            ))
          }
        >
          {(onClose) => (
            <PropertyTypeExpandedSelect
              propertyType={SchoolPropertyType.Status}
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={criteriaStatus}
              onSelectId={onSelectStatusId}
              onClose={onClose}
              onClear={onClearStatus}
            />
          )}
        </FilterDropdown>
      )}
      {criteriaAgeGroup && (
        <FilterDropdown
          selectLabel={$t({ id: 'select' })}
          onClear={() => toggleFiltersVisible(FilterKeys.AgeGroup)}
          label={ageGroupLabel}
          disabled={disabled}
          tags={(open) =>
            renderPropertyGroupTags({
              selectedItems: selectedItemsForAgeGroups,
              onClick: !disabled ? open : undefined,
              getProperty: (i) =>
                i.isGroup
                  ? getSchoolLevelById(i.id)
                  : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
              getTooltip: (i) =>
                i.isGroup
                  ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                      <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                    ))
                  : null,
              tagProps: {
                userRole: SchoolUserRole.Student,
                sx: { maxWidth: 200, backgroundColor: 'background.paper' },
              },
            })
          }
        >
          {(onClose) => (
            <AgeGroupExpandedSelect
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={criteriaAgeGroup}
              onSelect={onSelectAgeGroup}
              onClose={onClose}
              onClear={onClearAgeGroup}
            />
          )}
        </FilterDropdown>
      )}
      {criteriaHouse && (
        <FilterDropdown
          selectLabel={$t({ id: 'select' })}
          onClear={() => toggleFiltersVisible(FilterKeys.House)}
          label={houseLabel}
          disabled={disabled}
          tags={(open) =>
            criteriaHouse.map((id) => (
              <PropertyTypeTagSelect
                userRole={SchoolUserRole.Student}
                sx={(theme) => ({ maxWidth: 200, backgroundColor: theme.palette.background.paper })}
                schoolId={schoolId}
                key={id}
                id={id}
                onClick={!disabled ? open : undefined}
              />
            ))
          }
        >
          {(onClose) => (
            <PropertyTypeExpandedSelect
              propertyType={SchoolPropertyType.House}
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={criteriaHouse}
              onSelectId={onSelectHouseId}
              onClose={onClose}
              onClear={onClearHouse}
            />
          )}
        </FilterDropdown>
      )}
      {criteriaGroup && (
        <FilterDropdown
          selectLabel={$t({ id: 'select' })}
          onClear={() => toggleFiltersVisible('group')}
          label={groupLabel}
          disabled={disabled}
          width={500}
          tags={(open) =>
            criteriaGroup.map(({ id }) => (
              <GroupTagSelect
                key={id}
                sx={(theme) => ({ maxWidth: 200, backgroundColor: theme.palette.background.paper })}
                id={id}
                onClick={!disabled ? open : undefined}
              />
            ))
          }
        >
          {(onClose) => (
            <GroupExpandedSelect
              selectedIds={criteriaGroup.map(({ id }) => id)}
              schoolId={schoolId}
              onSelectGroup={onSelectGroup}
              onClose={onClose}
              onClear={onClearGroup}
              filters={groupDates}
            />
          )}
        </FilterDropdown>
      )}

      {!disabled && (
        <Box
          sx={{
            '.MuiTooltip-tooltipArrow > .MuiStack-root': {
              paddingBottom: 1.25,
            },
            '.MuiTypography-h4': {
              marginBottom: 1,
            },
          }}
        >
          <MoreButton
            ref={moreButton}
            options={filterExistingFilterOptions({ filterOptions, hasHouses })}
            selectedOptions={INVITEES_CRITERIA.filter((key) => !!criteria[key])}
            onToggleOption={toggleFiltersVisible}
            title={$t({ id: 'groups-AvailableCriteria' })}
          />
        </Box>
      )}
    </FiltersContainer>
  );
};
