import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect id="rect" x="1.5" y="1.5" width="17" height="17" rx="3.5" fill="white" stroke="#C7C8E0" />
  </svg>
);
