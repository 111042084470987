import { EmailIcon } from '@schooly/style';
import React, { FC } from 'react';

import { useProfile } from '../../../context/profile/useProfile';
import { ContactEmailModal } from './ContactEmailModal';
import ProfileProperty from './ProfileProperty';

interface EmailPropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}

export const ContactEmailProperty: FC = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
}: EmailPropertyParams) => {
  const { userType = 'profile', user, canEditProfile } = useProfile();

  const email = user?.email || '';

  if (userType !== 'profile' || !!user?.use_account_email_as_contact) return null;

  return (
    <>
      <ProfileProperty
        value={email}
        canEdit={canEditProfile}
        params={{ size, iconColor, color }}
        renderIcon={() => <EmailIcon />}
        valueTextId="peopleDetail-ContactEmailAddress"
        editValueTextId="peopleDetail-AddContactEmail"
        hideSuggestedChanges
        modalController={({ isOpen, hide }) => (
          <ContactEmailModal currentContactEmail={email} isOpen={isOpen} onClose={hide} />
        )}
      />
    </>
  );
};
