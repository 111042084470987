import Joi from 'joi';

/**
 * Helper schema for number values from native inputs,
 * which return empty string when left blank
 *
 * @param required makes the schema required
 * @param defaultValue set default value for the field when it is empty
 */
export default function emptyNumber(required?: boolean, defaultValue?: any) {
  const baseSchema = Joi.number()
    .empty([null, ''])
    .messages({ 'number.unsafe': 'input-ErrorNumberUnsafe' });

  const requiredSchema = required ? baseSchema.required() : baseSchema.allow('');

  return defaultValue !== undefined ? requiredSchema.default(defaultValue) : requiredSchema;
}
