import { Button, IconButton, MenuItem, Popover, Stack, Typography } from '@mui/material';
import { SchoolYear, useExportAssessmentForRelationMutation } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, DownloadIcon, SelectMultiple, SelectMultipleProps, Spin } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { IntlError } from '@schooly/utils/intl-error';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useProfile } from '../../../context/profile/useProfile';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { downloadFile } from '../../../utils/downloadFile';

export const ProfileModalAssessmentsExport: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { schoolMembership } = useProfile();
  const { getSchoolYearById, schoolYears } = useSchoolYears();

  const ref = useRef<HTMLButtonElement>(null);

  // is being set after successful download and reset in 3 sec afterwards
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [schoolYearIds, setSchoolYearIds] = useState<SchoolYear['id'][]>([]);

  const mutation = useExportAssessmentForRelationMutation();

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSchoolYearsChange = useCallback<
    Exclude<SelectMultipleProps<SchoolYear['id']>['onChange'], undefined>
  >((event) => {
    setSchoolYearIds(event.target.value);
  }, []);

  const download = useCallback(() => {
    if (!schoolMembership?.relation_id) {
      return;
    }

    mutation.mutate(
      {
        relationId: schoolMembership.relation_id,
        schoolYearIds: schoolYearIds.length
          ? schoolYearIds
          : schoolYears.map((schoolYear) => schoolYear.id),
      },
      {
        onSuccess: (data) => {
          try {
            downloadFile(data, `${getUserFullName(schoolMembership)}.assessment.csv`);
            setIsSuccess(true);
          } catch (err) {
            showError(err as IntlError);
          }
        },
        onError: showError,
      },
    );
  }, [mutation, schoolMembership, schoolYearIds, schoolYears, showError]);

  const handleExportClick = useCallback(() => {
    download();
    setOpen(false);
  }, [download]);

  /** resets success icon in 3 sec */
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccess(false), 3 * 1000);
    }
  }, [isSuccess]);

  return (
    <>
      <IconButton
        ref={ref}
        sx={{ color: open || mutation.isLoading || isSuccess ? 'primary.main' : 'text.primary' }}
        inverse
        onClick={mutation.isLoading ? undefined : handleClick}
      >
        {(() => {
          if (mutation.isLoading) {
            return <Spin />;
          }

          if (isSuccess) {
            return <CheckIcon />;
          }

          return <DownloadIcon />;
        })()}
      </IconButton>
      <Popover
        open={open}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        PaperProps={{
          sx: { p: 2, width: 300 },
        }}
      >
        <Stack gap={2.5}>
          <Typography variant="h3">
            <FormattedMessage id="assessments-ExportAssessments" />
          </Typography>

          <SelectMultiple
            label={$t({ id: schoolYearIds.length ? 'schoolYears' : 'allSchoolYears' })}
            value={schoolYearIds}
            onChange={handleSchoolYearsChange}
            renderValueTag={(id) => getSchoolYearById(id)?.name}
          >
            {schoolYears.map((year) => (
              <MenuItem key={year.id} value={year.id}>
                {year.name}
              </MenuItem>
            ))}
          </SelectMultiple>

          <Button variant="outlined" onClick={handleExportClick} fullWidth>
            <FormattedMessage id="exportToCSV" />
          </Button>
        </Stack>
      </Popover>
    </>
  );
};
