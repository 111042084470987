import { FilterKeys, FilterValue, SelectOption } from '@schooly/api';
import { CheckIcon, CrossIcon, DropdownIcon, StaffIcon } from '@schooly/style';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupArrangeByOption, GroupArrangeBySection } from '../../../context/filters/scheme';
import useDropdown from '../../../hooks/useDropdown';
import useHorizontalScroll from '../../../hooks/useHorizontalScroll';
import useKeyboardListNavigation from '../../../hooks/useKeyboardListNavigation';
import useVirtualBackdrop from '../../../hooks/useVirtualBackdrop';
import buildClassName from '../../../utils/buildClassName';
import captureKeyboardListeners from '../../../utils/captureKeyboardListeners';
import getTypedObjectKeys from '../../../utils/getTypedObjectKeys';
import searchWords from '../../../utils/searchWords';
import { getSelectedOptions } from '../../ui/Input/utils';
import Tag from '../../ui/Tag';

import './ArrangeBySelect.scss';

export type GroupArrangeBySectionMap = {
  [key in GroupArrangeBySection]?: SelectOption<FilterKeys>[];
};

interface IProps {
  id?: string;
  options: SelectOption<FilterKeys>[];
  groupOptions?: GroupArrangeBySectionMap;
  value?: any;
  groupValue?: GroupArrangeByOption;
  onChange: (value: FilterKeys) => void;
  onGroupChange?: (section: GroupArrangeBySection, option: SelectOption<FilterKeys>) => void;
  onReset: () => void;
  getOptionDisabledText: (option: SelectOption<FilterValue>) => string;
  getGroupSectionTitleId?: (section: GroupArrangeBySection) => string | undefined;
  isFocusable?: boolean;
}

const FOCUS_SUPRESS_DURATION = 300;

const FormSelect: React.FC<IProps> = ({
  id,
  options,
  groupOptions,
  value,
  groupValue,
  onChange,
  onGroupChange,
  onReset,
  getGroupSectionTitleId,
  getOptionDisabledText,
  isFocusable,
}) => {
  const { formatMessage } = useIntl();

  const selectedOption = useMemo(() => getSelectedOptions(options, value), [options, value]);

  const selectedGroupOption = useMemo(() => {
    if (!groupOptions || !groupValue) {
      return undefined;
    }

    let selected: GroupArrangeByOption | undefined;

    getTypedObjectKeys(groupOptions).forEach((sectionKey) => {
      const sectionOptions = groupOptions[sectionKey] || [];
      const sectionSelectedOption = getSelectedOptions(
        sectionOptions,
        groupValue?.option.value,
      ) as SelectOption<FilterKeys>;

      if (sectionSelectedOption) {
        selected = { section: groupValue.section, option: sectionSelectedOption };
      }
    });

    return selected;
  }, [groupOptions, groupValue]);

  const wrapperRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { isOpen, openPopup, closePopup, Popup } = useDropdown(wrapperRef, inputRef, true);

  // This helps with unwanted input focusing when clearing selected option
  const shouldSupressFocus = useRef<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>('');

  function handleSearchQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(e.currentTarget.value);
  }

  function handleSearchFocus(e: React.FocusEvent<HTMLInputElement>) {
    if (shouldSupressFocus.current) {
      e.currentTarget.blur();
      return;
    }

    openPopup(e);
  }

  const menuRef = useRef<HTMLUListElement>(null);
  const valueWrapperRef = useRef<HTMLDivElement>(null);
  const handleKeyDown = useKeyboardListNavigation(menuRef, isOpen);
  useHorizontalScroll(valueWrapperRef);

  useVirtualBackdrop(isOpen, closePopup, menuRef, wrapperRef);

  useEffect(() => {
    setSearchQuery('');
  }, [isOpen]);

  // On TAB click unfocus from portal popup back to select, then simmulte TAB again
  const handleTabClick = useCallback(() => {
    inputRef.current?.focus();
    closePopup();
    document.dispatchEvent(new KeyboardEvent('keypress', { key: 'Tab' }));
  }, [closePopup]);

  useEffect(
    () => (isOpen ? captureKeyboardListeners({ onTab: handleTabClick }) : undefined),
    [isOpen, handleTabClick],
  );

  const getOptionText = useCallback(
    (option: SelectOption<FilterValue>) =>
      option.labelTextId ? formatMessage({ id: option.labelTextId }) : option.label,
    [formatMessage],
  );

  const isOptionActive = useCallback(
    (option: SelectOption<FilterValue>, section?: GroupArrangeBySection) =>
      section
        ? selectedGroupOption?.section === section &&
          option.value === selectedGroupOption?.option.value
        : selectedOption && option.value === (selectedOption as SelectOption<FilterValue>).value,
    [selectedOption, selectedGroupOption],
  );

  const getOptionClassName = useCallback(
    (option: SelectOption<FilterValue>, section?: GroupArrangeBySection) =>
      buildClassName(
        'dropdown-item form-select-option',
        isOptionActive(option, section) && 'active',
        !!getOptionDisabledText(option) && 'disabled',
      ),
    [isOptionActive, getOptionDisabledText],
  );

  const displayedOptions = useMemo(() => {
    if (!searchQuery) {
      return options;
    }

    return options.filter((o) => {
      const text = getOptionText(o);
      return text && searchWords(text, searchQuery);
    });
  }, [options, searchQuery, getOptionText]);

  const displayedGroupOptions = useMemo(() => {
    if (!groupOptions) {
      return undefined;
    }

    if (!searchQuery) {
      return groupOptions;
    }

    const filteredGroupOptions = getTypedObjectKeys(groupOptions).reduce<GroupArrangeBySectionMap>(
      (acc, currentSection) => {
        const filteredOptions = (groupOptions[currentSection] || []).filter((o) => {
          const text = getOptionText(o);
          return text && searchWords(text, searchQuery);
        });

        if (filteredOptions.length) {
          acc[currentSection] = filteredOptions;
        }

        return acc;
      },
      {},
    );

    if (!Object.keys(filteredGroupOptions).length) {
      return undefined;
    }

    return filteredGroupOptions;
  }, [groupOptions, searchQuery, getOptionText]);

  const handleDropdownIconClick = useCallback(
    (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
      if (!isOpen) {
        openPopup(e);

        return;
      }

      shouldSupressFocus.current = true;

      closePopup(e);

      setTimeout(() => {
        shouldSupressFocus.current = false;
      }, FOCUS_SUPRESS_DURATION);
    },
    [isOpen, openPopup, closePopup],
  );

  const handleValueRemove = useCallback(() => {
    onReset();
  }, [onReset]);

  const handleSelectOption = useCallback(
    (option: SelectOption<FilterKeys>, section?: GroupArrangeBySection) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        shouldSupressFocus.current = true;
        e.stopPropagation();

        if (section) {
          if (onGroupChange) onGroupChange(section, option);
        } else {
          onChange(option.value);
        }

        setSearchQuery('');
        closePopup();

        setTimeout(() => {
          shouldSupressFocus.current = false;
        }, FOCUS_SUPRESS_DURATION);
      },
    [closePopup, onChange, onGroupChange],
  );

  const fullClassName = buildClassName(
    'ArrangeBySelect FilterSelect form-group form-select',
    isOpen && 'open',
    (!value || (Array.isArray(value) && !value.length)) && 'no-value',
  );

  const popupClassName = buildClassName(
    'ArrangeBySelect__popup',
    'form-select-popup',
    'list-unstyled',
    'dropdown-menu',
    'shadow-sm',
    isOpen && 'show',
  );

  const renderButton = useCallback(
    (option: SelectOption<FilterKeys>, section?: GroupArrangeBySection) => {
      const disabledText = getOptionDisabledText(option);

      return (
        <button
          type="button"
          data-value={option.value}
          className={getOptionClassName(option, section)}
          onClick={handleSelectOption(option, section)}
        >
          {section === 'staff' && <StaffIcon className="user-type-icon" />}
          <span className="form-select-option__info">{getOptionText(option)}</span>
          {disabledText && <span className="form-select-description">{disabledText}</span>}
          {isOptionActive(option, section) && <CheckIcon />}
        </button>
      );
    },
    [getOptionDisabledText, getOptionClassName, handleSelectOption, getOptionText, isOptionActive],
  );

  const placeholderText =
    !selectedGroupOption && !selectedOption ? formatMessage({ id: 'filter-NoArrange' }) : undefined;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label className={fullClassName} htmlFor={id} ref={wrapperRef} onKeyDown={handleKeyDown}>
        <input
          id={id}
          className="form-control form-select-search"
          ref={inputRef}
          value={searchQuery}
          onChange={handleSearchQueryChange}
          onFocus={handleSearchFocus}
          placeholder={placeholderText}
          tabIndex={isFocusable ? 0 : -1}
        />
        {(selectedGroupOption || selectedOption) && !searchQuery && (
          <div className="form-select-value-wrapper">
            <div ref={valueWrapperRef} className="form-select-value-wrapper-inner">
              <Tag className="form-select-value">
                {selectedGroupOption?.section === 'staff' && (
                  <StaffIcon className="user-type-icon" />
                )}
                {getOptionText(
                  (selectedGroupOption
                    ? selectedGroupOption.option
                    : selectedOption) as SelectOption<FilterValue>,
                )}
              </Tag>
            </div>
          </div>
        )}

        {isOpen && (value || groupValue) && (
          <div
            className="form-select-remove"
            role="button"
            tabIndex={-1}
            onClick={handleValueRemove}
          >
            <CrossIcon />
          </div>
        )}

        {!(isOpen && (value || groupValue)) && (
          <div
            className="form-select-arrow"
            role="button"
            tabIndex={-1}
            onClick={handleDropdownIconClick}
          >
            <DropdownIcon />
          </div>
        )}

        <Popup>
          <ul ref={menuRef} className={popupClassName}>
            {!!searchQuery && !displayedOptions.length && (
              <li>
                <span className="dropdown-item form-select-option form-select-no-options-found">
                  <FormattedMessage id="input-NoOptionsFound" />
                </span>
              </li>
            )}
            {groupOptions
              ? displayedGroupOptions &&
                getTypedObjectKeys(displayedGroupOptions).map((sectionKey) => {
                  const sectionOptions = displayedGroupOptions[sectionKey] || [];
                  const sectionTitleId =
                    getGroupSectionTitleId && getGroupSectionTitleId(sectionKey);

                  return sectionOptions.length > 0 ? (
                    <React.Fragment key={sectionKey}>
                      {sectionTitleId && (
                        <h4 className="group-section-title h4 mb-0 mt-2">
                          {formatMessage({ id: sectionTitleId })}
                        </h4>
                      )}
                      {sectionOptions.map((option) => (
                        <li key={option.value}>{renderButton(option, sectionKey)}</li>
                      ))}
                    </React.Fragment>
                  ) : null;
                })
              : displayedOptions.map((option) => (
                  <li key={option.value}>{renderButton(option)}</li>
                ))}
          </ul>
        </Popup>
      </label>
    </>
  );
};

export default FormSelect;
