import {
  createContext,
  FC,
  PropsWithChildren,
  RefObject,
  useContext,
  useEffect,
  useState,
} from 'react';

export const WithRefContext = createContext<WithRefContextProps>({
  containerRef: null,
});

interface WithRefContextProps extends PropsWithChildren {
  containerRef: RefObject<HTMLElement> | null;
}

export const WithRef: FC<WithRefContextProps> = ({ containerRef, children }) => {
  const [ref, setRef] = useState<RefObject<HTMLElement> | null>(null);

  useEffect(() => {
    if (!containerRef?.current) {
      return;
    }

    setRef(containerRef);
  }, [containerRef]);

  return (
    <WithRefContext.Provider value={{ containerRef: ref }}>{children}</WithRefContext.Provider>
  );
};
export const useWithRef = () => {
  return useContext(WithRefContext);
};
