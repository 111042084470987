/* eslint-disable import/prefer-default-export */

export const sortAlphabetically = <T extends object>(subjects?: T[], key = ''): T[] | undefined => {
  if (!subjects) return undefined;

  return subjects.sort((a: any, b: any) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};
