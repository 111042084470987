import { Button, Icon, IconButton, Stack, Typography } from '@mui/material';
import {
  ArchiveIcon,
  Attention2Icon,
  CrossIcon,
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  Spin,
} from '@schooly/style';
import { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

interface SchoolPropertyArchiveConfirmationModalProps {
  open: boolean;
  title: string;
  message: ReactElement;
  onClose: () => void;
  onArchive: () => void;
  isArchiving: boolean;
}

export const SchoolPropertyArchiveConfirmationModal: FC<SchoolPropertyArchiveConfirmationModalProps> =
  ({ open, onClose, message, title, onArchive, isArchiving }) => {
    return (
      <ModalConfirm
        open={open}
        fullWidth
        onClose={onClose}
        sx={{
          '& .MuiDialog-paperFullWidth': {
            width: 600,
          },
        }}
      >
        <ModalHeader multiline title={title} withBorderBottom={false} active>
          <IconButton disabled={isArchiving} onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalMain>
          <ModalContent active>
            <Stack direction="row" gap={1} mt={-1} mb={1.5}>
              <Icon
                sx={{
                  mt: 0.5,
                  color: 'background.paper',
                  path: {
                    color: 'primary.main',
                  },
                }}
              >
                <Attention2Icon />
              </Icon>
              <Typography variant="h2">{message}</Typography>
            </Stack>
          </ModalContent>
        </ModalMain>
        <ModalFooter withBorderTop={false} active>
          <Button
            onClick={onArchive}
            disabled={isArchiving}
            startIcon={isArchiving ? <Spin /> : <ArchiveIcon />}
          >
            <FormattedMessage id="action-Archive" />
          </Button>
        </ModalFooter>
      </ModalConfirm>
    );
  };
