import {
  ApiError,
  CreateReportParams,
  CreateReportResponse,
  GET_REPORT_QUERY,
  ReportForAssessment,
  ReportStatuses,
  UpdateReportParams,
  UpdateReportResponse,
  useCreateReportMutation,
  useDeleteReportMutation,
  useUpdateReportMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { MutateOptions, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export interface UseAssessmentActionsReturn {
  saving: boolean;
  deleting: boolean;

  createReport: (
    payload: CreateReportParams['report'] & { withNotifications?: boolean },
    options?: MutateOptions<CreateReportResponse, ApiError, CreateReportParams>,
  ) => Promise<CreateReportResponse | undefined>;

  updateReport: (
    id: UpdateReportParams['reportId'],
    payload: UpdateReportParams['report'] & { withNotifications?: boolean },
    options?: MutateOptions<UpdateReportResponse, ApiError, UpdateReportParams>,
  ) => Promise<UpdateReportResponse | undefined>;

  deleteReport: (
    report?: Pick<ReportForAssessment, 'id' | 'name' | 'report_status'> & {
      withNotifications?: boolean;
    },
    options?: MutateOptions<{ success: string }, ApiError, ReportForAssessment['id']>,
  ) => Promise<{ success: string } | undefined>;
}

export const useReportActions = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const { getConfirmation } = useConfirmationDialog();
  const { showNotification, showError } = useNotifications();
  const { schoolId } = useAuth();

  const queryClient = useQueryClient();
  const invalidateQueries = useInvalidateListQueriesFor('report');

  const createReportMutation = useCreateReportMutation();
  const updateReportMutation = useUpdateReportMutation();
  const deleteReportMutation = useDeleteReportMutation();

  const createReport = useCallback<UseAssessmentActionsReturn['createReport']>(
    async ({ withNotifications = true, ...payload }, options) => {
      if (!schoolId) {
        return;
      }

      try {
        const result = await createReportMutation.mutateAsync(
          {
            schoolId,
            report: payload,
          },
          options,
        );

        invalidateQueries();

        if (withNotifications) {
          showNotification({
            message: $t({ id: 'reports-ReportAndAssessmentsGenerated' }),
            type: 'success',
            actions: [
              {
                textId: 'reports-ViewReport',
                handler: () => navigate(`/reports/${result.report.id}`),
                buttonColor: 'light',
              },
            ],
          });
        }

        return result;
      } catch (e) {
        showError(e as ApiError);
      }
    },
    [$t, createReportMutation, invalidateQueries, navigate, schoolId, showError, showNotification],
  );

  const updateReport = useCallback<UseAssessmentActionsReturn['updateReport']>(
    async (id, { withNotifications = true, ...payload }, options) => {
      if (!id) {
        return;
      }

      try {
        const result = await updateReportMutation.mutateAsync(
          {
            reportId: id,
            report: payload,
          },
          options,
        );

        invalidateQueries();
        queryClient.invalidateQueries([GET_REPORT_QUERY, id]);

        if (withNotifications) {
          showNotification({
            message: $t({ id: 'reports-ReportAndAssessmentsGenerated' }),
            type: 'success',
            actions: [
              {
                textId: 'reports-ViewReport',
                handler: () => navigate(`/reports/${id}`),
                buttonColor: 'light',
              },
            ],
          });
        }

        return result;
      } catch (e) {
        showError(e as ApiError);
      }
    },
    [
      $t,
      invalidateQueries,
      navigate,
      queryClient,
      showError,
      showNotification,
      updateReportMutation,
    ],
  );

  const deleteReport = useCallback<UseAssessmentActionsReturn['deleteReport']>(
    async (report, options) => {
      if (!report?.id) return;

      const isUnpublished = report.report_status === ReportStatuses.Unpublished;

      if (
        !(await getConfirmation({
          textId: isUnpublished ? 'reports-DeleteConfirmation' : 'reports-DeleteConfirmationDraft',
          textValues: {
            reportName: report?.name,
          },
        }))
      )
        return;

      try {
        const result = await deleteReportMutation.mutateAsync(report.id, options);

        // TODO: why don't we display notification here?
        // if (report.withNotifications !== false) {
        //   // ... display notification
        // }

        invalidateQueries();

        return result;
      } catch (e) {
        showError(e as ApiError);
      }
    },
    [deleteReportMutation, getConfirmation, invalidateQueries, showError],
  );

  return {
    saving: createReportMutation.isLoading || updateReportMutation.isLoading,
    deleting: deleteReportMutation.isLoading,

    createReport,
    updateReport,
    deleteReport,
  };
};
