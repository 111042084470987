import { Box, Skeleton, Stack, Typography } from '@mui/material';
import {
  isSignUpSlots,
  isSignUpWithEvent,
  SignUpRespondent,
  useGetSignUpRespondentsQuery,
} from '@schooly/api';
import { DATE_FORMAT, EventsInvite, Genders } from '@schooly/constants';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { EmptySchoolSvg, GridRowCell, GridRowItem, GridRowName, randomInt } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { addMinutes, format, parse } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { $enum } from 'ts-enum-util';

import { NoSearchResultsFound } from '../../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import { GridRowStyled } from '../../../../components/uikit-components/Grid/Grid';
import { useSignUp } from '../../../../context/signUps/WithSignUp';
import { getUserFullName } from '../../../../helpers/users';
import { PAGE_SIZE } from '../../SignUpsContent';

const GENDERS = $enum(Genders).map((value, key) => ({ value, label: key }));

export const SignUpPreviewRespondentsList: FC = () => {
  const { signUp, debouncedQuery } = useSignUp();

  const { data, isLoading, isFetchingNextPage, fetchNextPage, setParams, hasNextPage } =
    useGetSignUpRespondentsQuery(
      {
        id: signUp?.id ?? '',
        query: debouncedQuery,
      },
      {
        enabled: Boolean(signUp && signUp.signed_up_count),
        refetchOnMount: 'always',
      },
    );

  const respondents = useMemo(
    () =>
      data?.pages.reduce<SignUpRespondent[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  useEffect(() => {
    setParams((p) => ({ ...p, query: debouncedQuery }));
  }, [debouncedQuery, setParams]);

  const total = data?.pages[0]?.count;

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage);

  const inviteeType = isSignUpWithEvent(signUp) ? signUp.event.invitee_type : signUp?.invitee_type;
  const withSlots = isSignUpSlots(signUp);

  const SkeletonRow = useCallback<FC>(
    () =>
      inviteeType === EventsInvite.StudentsOnly ? (
        <GridRowStyled>
          <GridRowItem noVerticalPadding>
            <GridRowName sx={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton variant="circular" width={30} height={30} sx={{ mx: 0.5, my: 1 }} />
              <Skeleton width={`${randomInt(30, 80)}%`} />
            </GridRowName>
            <GridRowCell variant="body1" flex={`0 0 ${withSlots ? 20 : 25}%`}>
              <Skeleton width={70} />
            </GridRowCell>
            <GridRowCell variant="body1" flex={`0 0 ${withSlots ? 20 : 25}%`}>
              <Skeleton width={`${randomInt(1, 2) * 12}%`} />
            </GridRowCell>

            {withSlots && (
              <GridRowCell variant="body1" flex="0 0 20%">
                <Skeleton width={160} />
              </GridRowCell>
            )}
          </GridRowItem>
        </GridRowStyled>
      ) : (
        <GridRowStyled>
          <GridRowItem noVerticalPadding>
            <GridRowName sx={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton variant="circular" width={30} height={30} sx={{ mx: 0.5, my: 1 }} />
              <Skeleton width={`${randomInt(30, 80)}%`} />
            </GridRowName>
            <GridRowCell variant="body1" flex={`0 0 ${withSlots ? 25 : 50}%`}>
              <Skeleton width={`${randomInt(30, 50)}%`} />
            </GridRowCell>

            {withSlots && (
              <GridRowCell variant="body1" flex="0 0 25%">
                <Skeleton width={160} />
              </GridRowCell>
            )}
          </GridRowItem>
        </GridRowStyled>
      ),
    [inviteeType, withSlots],
  );

  if (!signUp) {
    return null;
  }

  return signUp.signed_up_count && (isLoading || respondents.length || debouncedQuery) ? (
    <>
      <Typography variant="h4" sx={{ mt: 2.5, mb: 1 }}>
        <FormattedMessage
          id={
            inviteeType === EventsInvite.StudentsOnly
              ? 'userType-student-plural'
              : 'messages-ParentsOfStudents'
          }
        />
      </Typography>

      {!respondents.length && !isLoading ? (
        <NoSearchResultsFound />
      ) : (
        <Box>
          {respondents.map((respondent) => {
            const slot =
              withSlots && respondent.time_slot?.date && respondent.time_slot?.start ? (
                <Stack direction="row" gap={2} alignItems="center">
                  <span>
                    {format(newDateTimezoneOffset(respondent.time_slot.date), DATE_FORMAT)}
                  </span>
                  <span>
                    {respondent.time_slot.start} -{' '}
                    {format(
                      addMinutes(
                        parse(respondent.time_slot.start, 'HH:mm', new Date()),
                        signUp.duration,
                      ),
                      'HH:mm',
                    )}
                  </span>
                </Stack>
              ) : null;

            return (
              <GridRowStyled key={respondent.student.relation_id}>
                <GridRowItem noVerticalPadding>
                  <GridRowName>
                    <PersonCardBasic
                      user={respondent.student}
                      userType="student"
                      isUsernameClickable
                      isListItem
                    />
                  </GridRowName>

                  {inviteeType !== EventsInvite.StudentsOnly ? (
                    <>
                      <GridRowCell variant="body1" flex={`0 0 ${withSlots ? 25 : 50}%`}>
                        {getUserFullName(respondent.parent)}
                      </GridRowCell>

                      {withSlots && (
                        <GridRowCell variant="body1" flex="0 0 25%">
                          {slot}
                        </GridRowCell>
                      )}
                    </>
                  ) : (
                    <>
                      <GridRowCell variant="body1" flex={`0 0 ${withSlots ? 20 : 25}%`}>
                        {format(
                          newDateTimezoneOffset(respondent.student.date_of_birth),
                          DATE_FORMAT,
                        )}
                      </GridRowCell>
                      <GridRowCell variant="body1" flex={`0 0 ${withSlots ? 20 : 25}%`}>
                        {respondent.student.gender != null
                          ? GENDERS[respondent.student.gender ?? 0].label
                          : 'Female'}
                      </GridRowCell>

                      {withSlots && (
                        <GridRowCell variant="body1" flex="0 0 20%">
                          {slot}
                        </GridRowCell>
                      )}
                    </>
                  )}
                </GridRowItem>
              </GridRowStyled>
            );
          })}

          {isLoading &&
            new Array(Math.min(PAGE_SIZE, signUp.signed_up_count))
              .fill(true)
              .map((_, index) => <SkeletonRow key={index} />)}

          {hasNextPage && (
            <>
              <div ref={loaderRef} />
              {new Array(
                Math.min(
                  PAGE_SIZE,
                  total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                ),
              )
                .fill(true)
                .map((_, index) => (
                  <SkeletonRow key={index} />
                ))}
            </>
          )}
        </Box>
      )}
    </>
  ) : (
    <Stack pt={5} gap={2.5} alignItems="center" justifyContent="center">
      <EmptySchoolSvg />
      <Typography>
        <FormattedMessage id="eventSignUps-preview-Respondents-Empty" />
      </Typography>
    </Stack>
  );
};
