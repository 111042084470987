import { useIntl } from 'react-intl';

import { FormError } from './useForm';

export const useFormError = () => {
  const { formatMessage } = useIntl();

  const renderError = (error: FormError) => {
    if (!error) {
      return null;
    }

    if (typeof error === 'string') {
      return error;
    }

    return formatMessage(error);
  };

  return { renderError };
};
