import { Icon, Skeleton, Stack } from '@mui/material';
import {
  FilterKeys,
  FilterSection,
  GetReportsQueryFilters,
  REPORTS_QUERY_FILTER_KEYS,
  useGetFiltersQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PageHeader, pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SkeletonRows,
} from '@schooly/style';
import React, { useMemo } from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { PAGE_SIZE, ReportsContent, SKELETON_COLS } from './ReportsContent';

const ReportsPage: React.FC = () => {
  const { relationId = '', schoolId = '' } = useAuth();

  const { data: filters, isLoading: isLoadingFilters } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Report },
    { enabled: !!schoolId && !!relationId },
  );

  const initialFilters: GetReportsQueryFilters | undefined = useMemo(() => {
    if (isLoadingFilters) return;

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      return pickOnlyParamsFromFilterKeys(REPORTS_QUERY_FILTER_KEYS, defaultFilter.filter);
    }

    return {
      [FilterKeys.Staff]: relationId ? [relationId] : [],
    };
  }, [filters, isLoadingFilters, relationId]);

  const renderContent = () => {
    if (!initialFilters) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <PageHeader pageTitleTextId="section-Reports">
              <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
            </PageHeader>
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_COLS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    return <ReportsContent initialFilters={initialFilters} />;
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};

export default ReportsPage;
