import { FC, PropsWithChildren, useEffect } from 'react';
import { IntlConfig, IntlProvider, useIntl } from 'react-intl';

import { MESSAGES_BY_LANG_CODE } from './constants';
import { getClientLanguage, setDefaultLanguageFromGeolocation } from './utils';

export const IntlProviderWithMessages: FC<
  PropsWithChildren<
    Omit<IntlConfig, 'locale' | 'messages'> & {
      overrideLanguage?: string;
      requestUserLanguageOnAppear?: boolean;
    }
  >
> = ({ children, overrideLanguage, requestUserLanguageOnAppear = false, ...rest }) => {
  const langCode = getClientLanguage(overrideLanguage);

  const selectedMessages = MESSAGES_BY_LANG_CODE[langCode];

  return (
    <IntlProvider locale={langCode} messages={selectedMessages} {...rest}>
      {children}
      {requestUserLanguageOnAppear && <LanguageRequest />}
    </IntlProvider>
  );
};

// Must be done as child component to access intl context
const LanguageRequest: FC = () => {
  useRequestUserLanguage();
  return null;
};

export const useRequestUserLanguage = () => {
  const { locale } = useIntl();

  useEffect(() => {
    setDefaultLanguageFromGeolocation(locale);
  }, [locale]);
};
