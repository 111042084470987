import { TextFieldProps } from '@mui/material';
import { FC, forwardRef, ReactNode, useCallback, useRef } from 'react';

import { FormTextFieldStyled, FormTextFieldStyledProps } from './FormTextField.styled';
import { InputEndAdornment, InputEndAdornmentProps } from './InputEndAdornment';

export type FormTextFieldProps = TextFieldProps & FormTextFieldStyledProps & InputEndAdornmentProps;

export const FormTextField: FC<
  FormTextFieldProps & {
    showEndAdornment?: boolean;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
  }
> = forwardRef(
  (
    {
      inputRef: propInputRef,
      hideLabel,
      dropdown,
      canClear,
      onClear,
      InputProps,
      required,
      showEndAdornment = true,
      startAdornment,
      endAdornment,
      ...props
    },
    ref,
  ) => {
    const localRef = useRef<HTMLInputElement>();
    const inputRef = propInputRef && typeof propInputRef !== 'function' ? propInputRef : localRef;
    const value = props.value ?? props.inputProps?.value;

    const handleClear = useCallback(() => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.value = '';

      props.onChange?.({
        target: inputRef.current,
        nativeEvent: new Event('change'),
      } as React.ChangeEvent<HTMLInputElement>);

      onClear?.();
    }, [onClear, props, inputRef]);

    return (
      <FormTextFieldStyled
        ref={ref}
        inputRef={
          typeof propInputRef === 'function'
            ? (_ref) => {
                localRef.current = _ref;
                propInputRef(localRef.current);
              }
            : inputRef
        }
        InputProps={{
          ...InputProps,
          startAdornment: startAdornment || InputProps?.startAdornment,
          ...(showEndAdornment && {
            endAdornment: (endAdornment || InputProps?.endAdornment) ?? (
              <InputEndAdornment
                hideLabel={hideLabel ?? !!value}
                canClear={canClear && !!value}
                onClear={handleClear}
                required={required}
                dropdown={dropdown}
                hasValue={!!value}
                {...props}
              />
            ),
          }),
        }}
        {...props}
      />
    );
  },
);
