import { Box, Chip, Icon, Skeleton, Stack, TableRow, Tooltip, Typography } from '@mui/material';
import {
  DATE_FORMAT_RANGE_FNS,
  DependantStudent,
  PayableFee,
  PayerType,
  ProductBillingType,
} from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import {
  CompanyIcon,
  Grid,
  GridBody,
  GridCell,
  GridContainer,
  NewTabIcon,
  Price,
  PRICE_SUBTEXT_CLASS_NAME,
  ProfileIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { getRouteModalPathname } from '../../../helpers/misc';
import { AssignedProductPayer } from '../../../pages/ProfileModal/tabs/ProfileModalAssignedProductsList/AssignedProductPayer';
import { getCurrencySymbol } from '../../../pages/School/SchoolProducts/helpers';
import { payableFeeStatusColor } from './helpers';
import { PayableFeesRelationType } from './PayableFees';

export type PayableFeeTableProps = {
  payableFee: PayableFee;
  relationType: PayableFeesRelationType;
  isMultipleItemsView: boolean;
  schoolId: string;
};

export const PayableFeeTable: FC<PayableFeeTableProps> = ({
  payableFee,
  relationType,
  isMultipleItemsView,
  schoolId,
}) => {
  const { $t, formatMessage } = useIntl();
  const { students, payer, issue_date, due_date, total_payment, status, total_paid, currency } =
    payableFee;
  const navigate = useNavigate();
  const currencySymbol = getCurrencySymbol(currency);

  const isCompanyPayer = payer.type === PayerType.Company;
  const payerName = isCompanyPayer ? payer.data.name : getUserFullName(payer.data);
  const payerIcon = isCompanyPayer ? <CompanyIcon /> : <ProfileIcon />;
  const contactName = isCompanyPayer ? payer.data.contact_name : undefined;
  const inviteStatus = isCompanyPayer ? undefined : payer.data.invite_status;
  const payerId = isCompanyPayer ? payer.data.id : payer.data.relation_id;

  const isCompany = relationType === 'company';
  const isStudent = relationType === 'student';
  const payerPath = isCompanyPayer
    ? `/companies/${payer.data.id}`
    : `/parents/${payer.data.relation_id}`;

  const companyStudent = payableFee.students[0];
  const getStatusInfo = () => {
    const status = $t({ id: `payableFees-status-${payableFee.status}` });
    if (
      payableFee.status === 'partially_paid' &&
      total_paid &&
      //based on TR-7978 we temporary hide total paid number in student payable fees
      !isStudent
    ) {
      return `${status} ${currencySymbol} ${total_paid?.toLocaleString()} / ${total_payment.toLocaleString()}`;
    }

    return status;
  };

  const statusColor = payableFeeStatusColor[status];

  return (
    <GridContainer>
      <Grid>
        <GridBody>
          <TableRow
            sx={(theme) => ({
              height: '40px !important',
              '&:hover': {
                '.MuiTypography-root, .MuiIcon-root': {
                  color: theme.palette.primary.main,
                },
                'td.MuiTableCell-root': {
                  backgroundColor: theme.palette.background.default,
                },
                [` .${PRICE_SUBTEXT_CLASS_NAME} , .invoiceDate`]: {
                  color: 'common.grey2',
                },
                ' .MuiChip-root': {
                  bgcolor: theme.palette.background.paper,
                  color: theme.palette.primary.main,
                },
              },
            })}
          >
            <GridCell borderBottom width={170}>
              <Stack direction="row" gap={3}>
                <Box>
                  <Typography color="common.grey" className="invoiceDate">
                    {formatMessage({ id: 'Generation' })}
                  </Typography>
                  <Typography color="common.grey2" noWrap>
                    {format(newDateTimezoneOffset(issue_date), DATE_FORMAT_RANGE_FNS)}
                  </Typography>
                </Box>
                <Box>
                  <Typography color="common.grey" className="invoiceDate">
                    {formatMessage({ id: 'payableFees-DueOn' })}
                  </Typography>
                  <Typography color="common.grey2" noWrap>
                    {format(newDateTimezoneOffset(due_date), DATE_FORMAT_RANGE_FNS)}
                  </Typography>
                </Box>
              </Stack>
            </GridCell>

            {isStudent && (
              <GridCell borderBottom width={48}>
                <AssignedProductPayer
                  name={payerName}
                  icon={payerIcon}
                  telephone={payer.data.telephone}
                  email={payer.data.email}
                  id={payerId}
                  contactName={contactName}
                  inviteStatus={inviteStatus}
                  schoolId={schoolId}
                  onClick={() => navigate(payerPath)}
                  sx={{ cursor: 'pointer' }}
                />
              </GridCell>
            )}

            {isCompany && companyStudent && (
              <GridCell borderBottom width={48}>
                <StudentAvatar student={companyStudent} />
              </GridCell>
            )}

            <GridCell
              borderBottom
              width={isMultipleItemsView ? 220 : 196}
              sx={{ maxWidth: isMultipleItemsView ? 220 : 196 }}
            >
              {payableFee.invoice_link_for_issuer && (
                <Link
                  to={payableFee.invoice_link_for_issuer}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  {payableFee.invoice_number ? (
                    <TypographyWithOverflowHint
                      sx={{ color: 'common.grey2', '&:hover': { textDecoration: 'underline' } }}
                      variant="h3"
                    >
                      {payableFee.invoice_number}
                    </TypographyWithOverflowHint>
                  ) : (
                    <Icon fontSize="small" sx={{ color: 'common.grey2' }}>
                      <NewTabIcon />
                    </Icon>
                  )}
                </Link>
              )}
            </GridCell>

            <GridCell borderBottom noVerticalPadding width={isMultipleItemsView ? 200 : 180}>
              <TypographyWithOverflowHint color={statusColor}>
                <Typography display="inline" color={statusColor}>
                  {getStatusInfo()}
                </Typography>
              </TypographyWithOverflowHint>
            </GridCell>

            <GridCell borderBottom pr={0}>
              <Price currency={getCurrencySymbol(payableFee.currency)} price={total_payment} />
            </GridCell>
            <GridCell width={74} borderBottom />
          </TableRow>

          {payableFee.items.map((item, i) => {
            const isLastItemRow = i === payableFee.items.length - 1;
            const key = `${item.product_id}-${item.variant_id}`;
            const student = students.find((s) => item.student_relation_id === s.id);

            return (
              <TableRow
                key={key}
                sx={(theme) => ({
                  height: '40px !important',
                  '&:hover': {
                    '.MuiTypography-root': {
                      color: theme.palette.primary.main,
                    },
                    'td.MuiTableCell-root': {
                      backgroundColor: theme.palette.background.default,
                    },
                    [` .${PRICE_SUBTEXT_CLASS_NAME}`]: {
                      color: 'common.grey2',
                    },
                    ' .MuiChip-root': {
                      bgcolor: theme.palette.background.paper,
                      color: theme.palette.primary.main,
                    },
                  },
                })}
              >
                <GridCell
                  borderBottom={!isLastItemRow}
                  noVerticalPadding
                  colSpan={isMultipleItemsView ? 2 : 3}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    gap={1}
                    sx={{
                      maxWidth: 320,
                    }}
                  >
                    <TypographyWithOverflowHint sx={{ '&:hover': { textDecoration: 'underline' } }}>
                      <Typography
                        component={Link}
                        to={{ pathname: `/settings/products/${item.product_id}` }}
                        variant="h3"
                        color="text.primary"
                      >
                        {item.label}
                      </Typography>
                    </TypographyWithOverflowHint>
                    {item.product_type === ProductBillingType.OneOff && (
                      <Chip
                        variant="outlined"
                        label={formatMessage({ id: 'products-ProductType-OneOff' })}
                        sx={(theme) => ({
                          borderRadius: theme.spacing(0.25),
                          maxHeight: 22,
                          bgcolor: 'background.default',
                          '.MuiChip-label': { padding: theme.spacing(0.25, 0.5) },
                        })}
                      />
                    )}
                  </Stack>
                </GridCell>
                <GridCell
                  borderBottom={!isLastItemRow}
                  noVerticalPadding
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mr={2}
                    sx={{
                      width: isMultipleItemsView ? 200 : 150,
                      ...(isMultipleItemsView && { position: 'absolute', left: -10, top: 3 }),
                    }}
                    gap={2}
                  >
                    <Stack direction="row" alignItems="center" gap={2}>
                      {isMultipleItemsView && student && <StudentAvatar student={student} />}
                      <TypographyWithOverflowHint color="text.primary">
                        <Price
                          currency={getCurrencySymbol(payableFee.currency)}
                          isOldPrice={!!item.discount_percent}
                          variant="body1"
                          price={item.price_full}
                        />
                      </TypographyWithOverflowHint>
                    </Stack>

                    <Typography
                      width={40}
                      noWrap
                      color={item.discount_percent ? 'common.grey2' : 'common.grey'}
                    >
                      {item.discount_percent ? (
                        <>
                          {item.discount_percent}
                          <Typography color="common.grey" display="inline">
                            %
                          </Typography>
                        </>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Stack>
                </GridCell>

                <GridCell borderBottom={!isLastItemRow} noVerticalPadding pr={0} width={90}>
                  <Price
                    currency={getCurrencySymbol(payableFee.currency)}
                    variant="body1"
                    price={item.price}
                  />
                </GridCell>
                <GridCell />
              </TableRow>
            );
          })}
        </GridBody>
      </Grid>
    </GridContainer>
  );
};

export type PayableFeesSkeletonRowsProps = {
  rowsCount: number;
  cellsCount: number;
};

export const PayableFeesSkeletonRows: FC<PayableFeesSkeletonRowsProps> = ({
  rowsCount,
  cellsCount,
}) => {
  const skeleton = (
    <Typography>
      <Skeleton variant="text" />
    </Typography>
  );
  return (
    <>
      {[...new Array(rowsCount)].map((r) => (
        <TableRow key={r}>
          {[...new Array(cellsCount)].map((c) => (
            <GridCell noVerticalPadding key={c}>
              {skeleton}
            </GridCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

type StudentAvatarProps = {
  student: DependantStudent;
};

const StudentAvatar: FC<StudentAvatarProps> = ({ student }) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            minWidth: 200,
            padding: 1.25,
          },
        },
      }}
      title={
        <Link to={getRouteModalPathname('student', { relation_id: student.id })}>
          <Typography sx={{ '&:hover': { textDecoration: 'underline' } }}>
            {getUserFullName(student)}
          </Typography>
        </Link>
      }
      arrow
    >
      <Box
        sx={(theme) => ({
          border: '1px solid transparent',
          borderRadius: '50%',
          '&:hover': {
            borderColor: theme.palette.primary.main,
          },
        })}
      >
        <AvatarAuth user={student} />
      </Box>
    </Tooltip>
  );
};
