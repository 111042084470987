import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.04608 5.7794C4.62872 4.15804 6.17986 3 7.99993 3C9.99264 3 11.6612 4.38776 12.0919 6.24954L10.2423 5.18167C9.95535 5.01598 9.5884 5.11431 9.42271 5.40128C9.25703 5.68826 9.35535 6.05521 9.64233 6.2209L12.5817 7.91796C12.8687 8.08364 13.2356 7.98532 13.4013 7.69834L15.0984 4.75895C15.2641 4.47198 15.1657 4.10502 14.8788 3.93934C14.5918 3.77365 14.2248 3.87198 14.0592 4.15895L13.1824 5.67762C12.5251 3.43653 10.4536 1.8 7.99993 1.8C5.65769 1.8 3.6652 3.29091 2.91679 5.37359C2.80472 5.68544 2.96668 6.02908 3.27853 6.14115C3.59037 6.25321 3.93402 6.09125 4.04608 5.7794Z"
      fill="#24275B"
    />
    <path
      d="M11.9611 10.2001C11.3842 11.8321 9.82771 13 7.99994 13C6.01524 13 4.35208 11.6234 3.91319 9.7729L5.75771 10.8378C6.04469 11.0035 6.41164 10.9052 6.57733 10.6182C6.74301 10.3312 6.64469 9.96429 6.35771 9.7986L3.41832 8.10155C3.13135 7.93586 2.76439 8.03419 2.59871 8.32116L0.901652 11.2606C0.735967 11.5475 0.834292 11.9145 1.12127 12.0802C1.40824 12.2459 1.7752 12.1475 1.94088 11.8606L2.82137 10.3355C3.48287 12.5699 5.55103 14.2 7.99994 14.2C10.3521 14.2 12.3515 12.6965 13.0925 10.5999C13.2029 10.2875 13.0392 9.94472 12.7268 9.8343C12.4143 9.72387 12.0715 9.88762 11.9611 10.2001Z"
      fill="#24275B"
    />
  </svg>
);
