import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="path-1-outside-1"
      maskUnits="userSpaceOnUse"
      x="2"
      y="3"
      width="16"
      height="14"
      fill="black"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7.04023V15C3 15.5523 3.44772 16 4 16H16C16.5523 16 17 15.5523 17 15V7.04038L10.0001 12.6403L3 7.04023ZM3 5.7596V5C3 4.44772 3.44772 4 4 4H16C16.5523 4 17 4.44772 17 5V5.75976L10.0001 11.3597L3 5.7596Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7.04023V15C3 15.5523 3.44772 16 4 16H16C16.5523 16 17 15.5523 17 15V7.04038L10.0001 12.6403L3 7.04023ZM3 5.7596V5C3 4.44772 3.44772 4 4 4H16C16.5523 4 17 4.44772 17 5V5.75976L10.0001 11.3597L3 5.7596Z"
      fill="#B3BBCB"
    />
    <path
      d="M3 7.04023L3.6247 6.25936L2 4.9596V7.04023H3ZM17 7.04038H18V4.95976L16.3753 6.25951L17 7.04038ZM10.0001 12.6403L9.3754 13.4212L10.0001 13.9209L10.6248 13.4212L10.0001 12.6403ZM3 5.7596H2V6.24023L2.3753 6.54047L3 5.7596ZM17 5.75976L17.6247 6.54063L18 6.24038V5.75976H17ZM10.0001 11.3597L9.3754 12.1405L10.0001 12.6403L10.6248 12.1405L10.0001 11.3597ZM4 15V7.04023H2V15H4ZM4 15H4H2C2 16.1046 2.89543 17 4 17V15ZM16 15H4V17H16V15ZM16 15V17C17.1046 17 18 16.1046 18 15H16ZM16 7.04038V15H18V7.04038H16ZM16.3753 6.25951L9.3754 11.8594L10.6248 13.4212L17.6247 7.82125L16.3753 6.25951ZM10.6248 11.8594L3.6247 6.25936L2.3753 7.8211L9.3754 13.4212L10.6248 11.8594ZM4 5.7596V5H2V5.7596H4ZM4 5V5V3C2.89543 3 2 3.89543 2 5H4ZM4 5H16V3H4V5ZM16 5H18C18 3.89543 17.1046 3 16 3V5ZM16 5V5.75976H18V5H16ZM10.6248 12.1405L17.6247 6.54063L16.3753 4.97889L9.3754 10.5788L10.6248 12.1405ZM2.3753 6.54047L9.3754 12.1405L10.6248 10.5788L3.6247 4.97873L2.3753 6.54047Z"
      fill="white"
      mask="url(#path-1-outside-1)"
    />
  </svg>
);
