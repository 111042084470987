import { Box, Card, Icon, IconButton, Stack, Typography } from '@mui/material';
import { ControlTextField, FormTextFieldStyled } from '@schooly/components/form-text-field';
import { COUNTRY_OPTIONS, CURRENCY_OPTIONS } from '@schooly/constants';
import {
  Attention2Icon,
  CrossIcon,
  LockIcon,
  ModalContent,
  ModalHeader,
  ModalMain,
  RollBackIcon,
} from '@schooly/style';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import FormSelect2 from '../../../../components/ui/Input/FormSelect2';
import { ControlModalHeaderInput } from '../../../../components/uikit-components/Modal/ControlModalHeaderInput';
import { LegalEntityForm } from '../../../../context/legalEntity/WithLegalEntity';

type LegalEntityFormContentProps = {
  onClose: () => void;
  onConnect: () => void;
  onDisconnect: () => void;
  onReload?: () => void;
};

export const LegalEntityFormContent: FC<PropsWithChildren<LegalEntityFormContentProps>> = ({
  onClose,
  children,
  onConnect,
  onDisconnect,
  onReload,
}) => {
  const form = useFormContext<LegalEntityForm>();
  const tenant = form.watch('connected_tenant');
  const currency = form.watch('currency');
  const { $t } = useIntl();

  const integrationCurrency = tenant && currency ? currency : null;

  //TR-6076 Edit flow for LE with integration will be available in future
  const canEdit = !tenant?.id;

  return (
    <>
      <ModalHeader
        active
        title={
          <ControlModalHeaderInput
            control={form.control}
            name="display_name"
            rules={{ required: true }}
            placeholder={$t({ id: 'legalEntities-DisplayName' })}
            autoFocus
            disabled={!canEdit}
          />
        }
      >
        {/* TR-6076 Temporary refresh. Full delete/archive flow will be available in future */}
        {onReload && (
          <IconButton onClick={onReload}>
            <RollBackIcon />
          </IconButton>
        )}

        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalMain>
        <ModalContent active>
          <Stack gap={4}>
            <Stack gap={2} alignItems="flex-start">
              {/* TR-6076 Edit flow for LE with integration will be available in future */}
              {!canEdit && (
                <LegalEntityWarning
                  warning={
                    <Box>
                      <Typography variant="h3" color="inherit">
                        {$t({ id: 'legalEntities-CannotEditLegalEntity' })}
                      </Typography>
                      <Typography variant="h3" color="inherit">
                        {$t({ id: 'legalEntities-CanDelete' })}
                      </Typography>
                    </Box>
                  }
                />
              )}
              <ControlTextField
                name="name"
                control={form.control}
                label={$t({ id: 'legalEntities-LegalName' })}
                canClear={canEdit}
                fullWidth
                rules={{ required: true }}
                disabled={!canEdit}
              />
              {/* TR-6832 We need to hide the field, but when tenant disconnect logic is introduced we need to discuss the design */}
              {/* <LegalEntityIntegration onConnect={onConnect} onDisconnect={onDisconnect} /> */}
            </Stack>
            <Stack gap={1}>
              <Typography variant="h4">{$t({ id: 'legalEntities-Address' })}</Typography>
              <Stack gap={2}>
                <ControlTextField
                  name="address_line_1"
                  control={form.control}
                  label={$t({ id: 'legalEntities-AddressLine1' })}
                  canClear
                  fullWidth
                  disabled={!canEdit}
                />
                <ControlTextField
                  name="address_line_2"
                  control={form.control}
                  label={$t({ id: 'legalEntities-AddressLine2' })}
                  canClear
                  fullWidth
                  disabled={!canEdit}
                />
                <Stack direction="row" gap={1}>
                  <ControlTextField
                    name="city"
                    control={form.control}
                    label={$t({ id: 'legalEntities-City' })}
                    canClear
                    fullWidth
                    disabled={!canEdit}
                  />
                  <ControlTextField
                    name="region"
                    control={form.control}
                    label={$t({ id: 'legalEntities-StateProvince' })}
                    canClear
                    fullWidth
                    disabled={!canEdit}
                  />
                </Stack>
                <Stack direction="row" gap={1}>
                  <ControlTextField
                    name="zip_code"
                    control={form.control}
                    label={$t({ id: 'legalEntities-ZipCode' })}
                    canClear
                    fullWidth
                    disabled={!canEdit}
                  />
                  <FormSelect2
                    name="country"
                    labelTextId="legalEntities-Country"
                    options={COUNTRY_OPTIONS}
                    rules={{ required: true }}
                    disabled={!canEdit}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack gap={1}>
              <Typography variant="h4">{$t({ id: 'legalEntities-OtherInformation' })}</Typography>
              <Stack gap={2}>
                <ControlTextField
                  name="registration_number"
                  control={form.control}
                  label={$t({ id: 'legalEntities-BusinessRegistrationNumber' })}
                  canClear
                  fullWidth
                  disabled={!canEdit}
                />
                <ControlTextField
                  name="tax_number"
                  control={form.control}
                  label={$t({ id: 'legalEntities-TaxID' })}
                  canClear
                  fullWidth
                  disabled={!canEdit}
                />
                <FormSelect2
                  name="currency"
                  labelTextId={$t({ id: 'legalEntities-Currency' })}
                  options={CURRENCY_OPTIONS}
                  disabled={!!integrationCurrency || !canEdit}
                  value={integrationCurrency ? integrationCurrency : undefined}
                  endIcon={
                    integrationCurrency ? (
                      <IconButton disabled>
                        <LockIcon />
                      </IconButton>
                    ) : undefined
                  }
                  rules={{ required: true }}
                />
              </Stack>
            </Stack>
          </Stack>
        </ModalContent>
      </ModalMain>
      {children}
    </>
  );
};

type LegalEntityIntegrationProps = {
  onConnect: () => void;
  onDisconnect: () => void;
};

export const LegalEntityIntegration: FC<LegalEntityIntegrationProps> = ({
  onConnect,
  onDisconnect,
}) => {
  const { watch } = useFormContext<LegalEntityForm>();
  const { $t } = useIntl();
  const tenant = watch('connected_tenant');

  return tenant ? (
    <Box width="100%">
      <FormTextFieldStyled
        //TR-6076 Temporary hidden. Will introduce disconnect in future
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <Button onClick={onDisconnect} size="small">
        //         {$t({ id: 'legalEntities-Disconnect' })}
        //       </Button>
        //     </InputAdornment>
        //   ),
        // }}
        disabled
        fullWidth
        label={$t({ id: 'legalEntities-Integration' })}
        value={tenant.name}
      />
    </Box>
  ) : null;
  //TR-6076 Adding of integration will be introduced in future
  // <Button onClick={onConnect} startIcon={<PlusIcon />}>
  //   {$t({ id: 'legalEntities-AddIntegration' })}
  // </Button>
};

const LegalEntityWarning: FC<{ warning: ReactNode }> = ({ warning }) => {
  return (
    <Card
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 2,
        p: 2,
        borderColor: theme.palette.common.orange,
        bgcolor: theme.palette.common.orange5,
        color: theme.palette.common.orange,
        '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
      })}
    >
      <Icon>
        <Attention2Icon />
      </Icon>

      {warning}
    </Card>
  );
};
