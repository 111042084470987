import { IconButton, Stack } from '@mui/material';
import { ConductTypeCreate } from '@schooly/api';
import { ConductConnotation, ConductTypeRepresentation } from '@schooly/api';
import { CrossIcon } from '@schooly/style';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

import FormSelect2 from '../../../components/ui/Input/FormSelect2';
import { FormRadioGroup } from '../../../components/uikit-components/FormCheckbox/FormRadioGroup';
import { ControlModalHeaderInput } from '../../../components/uikit-components/Modal/ControlModalHeaderInput';
import {
  ModalContent,
  ModalMain,
  ModalSmall,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';

export const CONNOTATION_OPTIONS = $enum(ConductConnotation).map((value, key) => ({
  labelTextId: `conduct-connotation-${key}`,
  value,
  checkColor: key,
}));

export const TYPE_OPTIONS = $enum(ConductTypeRepresentation).map((value, key) => ({
  labelTextId: `conduct-type-${key}`,
  value,
}));

export type ConductTypeForm = Omit<ConductTypeCreate, 'order'>;

const defaultValues = {
  name: '',
  type: undefined,
  connotation: undefined,
};

type SchoolConductTypeModalContentProps = {
  type?: ConductTypeForm;
  isNewItem: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  editConnotationDisabled?: boolean;
  onDelete?: () => void;
  onClose: () => void;
  onSubmit: (v: ConductTypeForm) => void;
};

export const SchoolConductTypeModalContent: FC<SchoolConductTypeModalContentProps> = ({
  type,
  isDeleting,
  isSaving,
  isNewItem,
  onClose,
  onDelete,
  onSubmit,
  editConnotationDisabled,
}) => {
  const { $t } = useIntl();

  const form = useForm<ConductTypeForm>({
    defaultValues: type
      ? { name: type.name, connotation: type.connotation, type: type.type }
      : defaultValues,
  });

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader
            active
            disabled={isSaving}
            title={
              <ControlModalHeaderInput
                control={form.control}
                name="name"
                rules={{ required: true }}
                placeholder={$t({ id: 'school-section-ConductSettings-ConductTypeName' })}
                autoFocus={isNewItem}
              />
            }
          >
            {!isSaving && (
              <IconButton onClick={onClose}>
                <CrossIcon />
              </IconButton>
            )}
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              <Stack alignItems="flex-start" gap={2} sx={{ height: '100%' }}>
                <FormRadioGroup
                  options={CONNOTATION_OPTIONS}
                  name="connotation"
                  rules={{ required: true }}
                />
                <FormSelect2
                  disabled={editConnotationDisabled}
                  id="school-section-ConductSettings-ConductType"
                  name="type"
                  labelTextId="school-section-ConductSettings-ConductType"
                  options={TYPE_OPTIONS}
                  rules={{ required: true }}
                />
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooterWithActions
            saving={isSaving}
            deleting={isDeleting}
            disabled={isSaving || isDeleting}
            showDeleteButton={!!onDelete}
            onDeleteClick={onDelete}
            isNewItem={isNewItem}
          />
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
