import { Typography } from '@mui/material';
import { GetSignUpsListSort, SignUpStatuses } from '@schooly/api';
import { getSignUpsStatusLabel } from '@schooly/components/filters';
import { GridCell, GridHead } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type SignUpsHeaderProps = {
  sort?: GetSignUpsListSort;
  onChangeSort?: (v: GetSignUpsListSort) => void;
};

export const SignUpsHeader: FC<SignUpsHeaderProps> = ({ onChangeSort, sort }) => {
  const { $t } = useIntl();

  return (
    <GridHead borderBottom>
      <GridCell
        width="125px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 120,
          },
          [theme.breakpoints.only('md')]: {
            width: 100,
          },
          [theme.breakpoints.down('md')]: {
            width: 100,
          },
        })}
      >
        {$t({ id: 'eventSignUps-grid-date' })}
      </GridCell>
      <GridCell width="40%">{$t({ id: 'eventSignUps-grid-title' })}</GridCell>

      <GridCell
        width="30%"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 120,
          },
        })}
      >
        {$t({ id: 'eventSignUps-grid-linkedEvent' })}
      </GridCell>

      <GridCell
        width="140px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 120,
          },
          [theme.breakpoints.only('md')]: {
            width: 100,
          },
          [theme.breakpoints.down('md')]: {
            width: 100,
          },
        })}
      >
        {$t({ id: 'eventSignUps-SignUpType' })}
      </GridCell>

      <GridCell
        width="140px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 160,
          },
          [theme.breakpoints.only('md')]: {
            width: 140,
          },
          [theme.breakpoints.down('md')]: {
            width: 140,
          },
        })}
      >
        {$t({ id: 'eventSignUps-Participation' })}
      </GridCell>

      <GridCell
        width="140px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 100,
          },
          [theme.breakpoints.only('md')]: {
            width: 80,
          },
          [theme.breakpoints.down('md')]: {
            width: 80,
          },
        })}
      >
        {$t({ id: 'eventSignUps-SignUpStatus' })}
      </GridCell>
    </GridHead>
  );
};

export const StatusCellContent: React.FC<{ status: SignUpStatuses }> = ({ status }) => {
  return (
    <Typography
      className="status"
      sx={
        status === SignUpStatuses.Open
          ? { color: 'success.main', '.MuiTableRow-root:hover td &': { color: 'success.main' } }
          : { color: 'common.grey2' }
      }
    >
      <FormattedMessage id={getSignUpsStatusLabel(status)} />
    </Typography>
  );
};
