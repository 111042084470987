import { RefObject, useCallback, useEffect, useState } from 'react';

const KEY_CODE_DOWN = 40;
const KEY_CODE_UP = 38;

/**
 * Handles logic for dropdown menu navigation with keyboard.
 * Note that this hook is designed to work with list items that have `LI > BUTTON` structure.
 *
 * @param elementRef ref to the list element
 * @param isOpen value indicating if list is open and can be navigated
 * @param dependencies reset focused index on dependencies change
 */
export default function useKeyboardListNavigation(
  elementRef: RefObject<HTMLElement>,
  isOpen: boolean,
  dependencies: Array<any> = [],
) {
  const [focusedIndex, setFocusedIndex] = useState(-1);

  useEffect(() => {
    setFocusedIndex(-1);
  }, [isOpen, ...dependencies]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<any>) => {
      const element = elementRef.current;

      if (!element) {
        return;
      }

      if (e.keyCode !== KEY_CODE_UP && e.keyCode !== KEY_CODE_DOWN) {
        return;
      }

      e.preventDefault();

      const focusedElement =
        document.activeElement?.tagName !== 'BUTTON'
          ? document.activeElement
          : document.activeElement?.parentElement;
      const elementChildren = Array.from(element.children);
      const currentFocusedIndex = focusedElement ? elementChildren.indexOf(focusedElement) : -1;

      let newIndex = currentFocusedIndex >= 0 ? currentFocusedIndex : focusedIndex;

      if (e.keyCode === KEY_CODE_UP && newIndex > 0) {
        newIndex -= elementChildren[newIndex - 1]?.tagName === 'LI' ? 1 : 2;
      } else if (e.keyCode === KEY_CODE_DOWN && newIndex < elementChildren.length - 1) {
        newIndex += elementChildren[newIndex + 1]?.tagName === 'LI' ? 1 : 2;
      } else if (elementChildren.length === 1) {
        newIndex = 0;
      } else {
        return;
      }

      const item = elementChildren[newIndex];
      const focusableElement = item?.children[0];

      if (focusableElement) {
        setFocusedIndex(newIndex);
        (focusableElement as HTMLElement).focus();
      }
    },
    [focusedIndex, elementRef],
  );

  return handleKeyDown;
}
