import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="16"
    height="142"
    viewBox="0 0 18 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 0.5C0.723858 0.5 0.5 0.723858 0.5 1C0.5 1.27614 0.723858 1.5 1 1.5V0.5ZM8.52941 1H9.02941V0.5H8.52941V1ZM8.52941 138H8.02941V138.5H8.52941V138ZM17.3536 138.354C17.5488 138.158 17.5488 137.842 17.3536 137.646L14.1716 134.464C13.9763 134.269 13.6597 134.269 13.4645 134.464C13.2692 134.66 13.2692 134.976 13.4645 135.172L16.2929 138L13.4645 140.828C13.2692 141.024 13.2692 141.34 13.4645 141.536C13.6597 141.731 13.9763 141.731 14.1716 141.536L17.3536 138.354ZM1 1.5H8.52941V0.5H1V1.5ZM8.02941 1V138H9.02941V1H8.02941ZM8.52941 138.5H17V137.5H8.52941V138.5Z"
      fill="#B3BBCB"
    />
  </svg>
);
