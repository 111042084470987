enum Languages {
  'Abkhaz',
  'Achinese',
  'Acoli',
  'Adangme',
  'Adygei',
  'Afar',
  'Afghan',
  'Afrikaans',
  'Akan',
  'Akkadian',
  'Albanian',
  'Aleut',
  'Algonquian',
  'Aljamia',
  'Altaic',
  'Amharic',
  'Apache languages',
  'Arabic',
  'Aragonese Spanish',
  'Aramaic',
  'Arapaho',
  'Arawak',
  'Armenian',
  'Assamese',
  'Athapascan',
  'Austronesian',
  'Avaric',
  'Avestan',
  'Awadhi',
  'Aymara',
  'Azerbaijani',
  'Bable',
  'Bahasa',
  'Balinese',
  'Balochi',
  'Baltic',
  'Bambara',
  'Banda',
  'Bantu',
  'Basa',
  'Bashkir',
  'Basque',
  'Batak',
  'Beja',
  'Belarusian',
  'Bemba',
  'Bengali',
  'Berber',
  'Bhojpuri',
  'Bihari',
  'Bikol',
  'Bislama',
  'Bosnian',
  'Braj',
  'Breton',
  'Bugis',
  'Bulgarian',
  'Buriat',
  'Burmese',
  'Caddo',
  'Carib',
  'Catalan',
  'Caucasian',
  'Cebuano',
  'Celtic',
  'Central American Indian',
  'Chagatai',
  'Chamic languages',
  'Chamorro',
  'Chechen',
  'Cherokee',
  'Chewa',
  'Cheyenne',
  'Chhattisgarhi',
  'Chibcha',
  'Chinese – Mandarin',
  'Chinese – Cantonese',
  'Chinese',
  'Chinook jargon',
  'Chipewyan',
  'Chittagonian',
  'Choctaw',
  'Church Slavic',
  'Chuvash',
  'Coptic',
  'Cornish',
  'Corsican',
  'Cree',
  'Creek',
  'Creoles and Pidgins',
  'Crimean Tatar',
  'Croatian',
  'Cushitic',
  'Czech',
  'Dadi',
  'Dakota',
  'Danish',
  'Dargwa',
  'Dayak',
  'Deccan',
  'Delaware',
  'Dhundhari',
  'Dinka',
  'Divehi',
  'Dogri',
  'Dogrib',
  'Dravidian',
  'Duala',
  'Dutch',
  'Dyula',
  'Dzongkha',
  'Eastern Min',
  'Edo',
  'Efik',
  'Egyptian',
  'Ekajuk',
  'Elamite',
  'English',
  'Eskimo languages',
  'Esperanto',
  'Estonian',
  'Ethiopic',
  'Ewe',
  'Ewondo',
  'Fang',
  'Fanti',
  'Faroese',
  'Fijian',
  'Finnish',
  'Finno Ugrian',
  'Fon',
  'French',
  'Frisian',
  'Friulian',
  'Fula',
  'Ga',
  'Galician',
  'Gan Chinese',
  'Ganda',
  'Gayo',
  'Gbaya',
  'Georgian',
  'German',
  'Germanic',
  'Gilbertese',
  'Gondi',
  'Gorontalo',
  'Gothic',
  'Grebo',
  'Greek',
  'Guarani',
  'Gujarati',
  'Gwichin',
  'Haida',
  'Haitian Creole',
  'Hakka',
  'Haryanvi',
  'Hausa',
  'Hawaiian',
  'Hebrew',
  'Herero',
  'Hiligaynon',
  'Himachali',
  'Hindi',
  'Hiri Motu',
  'Hittite',
  'Hmong',
  'Hungarian',
  'Hupa',
  'Iban',
  'Icelandic',
  'Ido',
  'Igbo',
  'Ijo',
  'Ilocano',
  'Iloko',
  'Inari Sami',
  'Indic',
  'Indo European',
  'Indonesian',
  'Ingush',
  'Interlingue',
  'Inuktitut',
  'Inupiaq',
  'Iranian',
  'Irish',
  'Iroquoian',
  'Italian',
  'Japanese',
  'Javanese',
  'Jin',
  'Judeo Arabic',
  'Judeo Persian',
  'Kabardian',
  'Kabyle',
  'Kachin',
  'Kalatdlisut',
  'Kalmyk',
  'Kamba',
  'Kannada',
  'Kanuri',
  'Kara Kalpak',
  'Karen',
  'Kashmiri',
  'Kawi',
  'Kazakh',
  'Khasi',
  'Khmer',
  'Khoisan',
  'Khotanese',
  'Kikuyu',
  'Kimbundu',
  'Kinyarwanda',
  'Kirundi',
  'Komi',
  'Kongo',
  'Konkani',
  'Korean',
  'Kpelle',
  'Kru',
  'Kuanyama',
  'Kumyk',
  'Kurdish',
  'Kurukh',
  'Kusaie',
  'Kutenai',
  'Kyrgyz',
  'Ladino',
  'Lahnda',
  'Lamba',
  'Lao',
  'Latin',
  'Latvian',
  'Letzeburgesch',
  'Lezgian',
  'Limburgish',
  'Lingala',
  'Lithuanian',
  'Low German',
  'Lozi',
  'Luba Katanga',
  'Luba Lulua',
  'Luiseno',
  'Lule Sami',
  'Lunda',
  'Luo',
  'Lushai',
  'Macedonian',
  'Madurese',
  'Magahi',
  'Maithili',
  'Makasar',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manchu',
  'Mandar',
  'Mandarin',
  'Mandingo',
  'Manipuri',
  'Manobo languages',
  'Manx',
  'Maori',
  'Mapuche',
  'Marathi',
  'Mari',
  'Marshallese',
  'Marwari',
  'Masai',
  'Mayan languages',
  'Mende',
  'Micmac',
  'Minangkabau',
  'Miscellaneous languages',
  'Mohawk',
  'Moldavian',
  'Mon Khmer',
  'Mongo Nkundu',
  'Mongolian',
  'Moore',
  'Mossi',
  'Multiple languages',
  'Munda',
  'Nahuatl',
  'Nauru',
  'Navajo',
  'Ndebele',
  'Ndonga',
  'Neapolitan Italian',
  'Nepali',
  'Newari',
  'Nias',
  'Niger Kordofanian',
  'Nilo Saharan',
  'Niuean',
  'Nogai',
  'North American Indian',
  'Northern Min',
  'Northern Sami',
  'Northern Sotho',
  'Norwegian',
  'Nubian languages',
  'Nyamwezi',
  'Nyanja',
  'Nyankole',
  'Nyoro',
  'Nzima',
  'Ojibwa',
  'Old Irish',
  'Old Norse',
  'Oriya',
  'Oromo',
  'Osage',
  'Ossetic',
  'Otomian languages',
  'Ottoman Turkish',
  'Pahlavi',
  'Palauan',
  'Pali',
  'Pampanga',
  'Pangasinan',
  'Panjabi',
  'Papiamento',
  'Papuan',
  'Pashto',
  'Persian',
  'Philippine',
  'Phoenician',
  'Polish',
  'Ponape',
  'Portuguese',
  'Prakrit languages',
  'Punjabi',
  'Pushto',
  'Quechua',
  'Raeto Romance',
  'Rajasthani',
  'Rapanui',
  'Rarotongan',
  'Romance',
  'Romani',
  'Romanian',
  'Rundi',
  'Russian',
  'Salishan languages',
  'Samaritan Aramaic',
  'Sami',
  'Samoan',
  'Sandawe',
  'Sango',
  'Sanskrit',
  'Santali',
  'Saraiki',
  'Sardinian',
  'Sasak',
  'Scots',
  'Scottish Gaelic',
  'Selkup',
  'Semitic',
  'Serbian',
  'Serbo Croatian',
  'Serer',
  'Sesotho',
  'Shan',
  'Shona',
  'Sichuan Yi',
  'Sidamo',
  'Sign languages',
  'Siksika',
  'Sindhi',
  'Sinhalese',
  'Sino Tibetan',
  'Siouan',
  'Skolt Sami',
  'Slave',
  'Slavic',
  'Slovak',
  'Slovenian',
  'Sogdian',
  'Somali',
  'Songhai',
  'Soninke',
  'Sorbian languages',
  'Sotho',
  'South American Indian',
  'Southern Min',
  'Spanish',
  'Sukuma',
  'Sumerian',
  'Sundanese',
  'Swahili',
  'Swedish',
  'Swiss German',
  'Sylheti',
  'Tagalog',
  'Tahitian',
  'Tai',
  'Tajik',
  'Tamashek',
  'Tamil',
  'Tatar',
  'Telugu',
  'Temne',
  'Terena',
  'Tetum',
  'Thai',
  'Tibetan',
  'Tigre',
  'Tigrinya',
  'Tiv',
  'Tlingit',
  'Tok Pisin',
  'Tokelauan',
  'Tonga',
  'Tongan',
  'Truk',
  'Tsimshian',
  'Tsonga',
  'Tswana',
  'Tumbuka',
  'Tupi languages',
  'Turkish',
  'Turkmen',
  'Tuvaluan',
  'Tuvinian',
  'Twi',
  'Udmurt',
  'Ugaritic',
  'Uighur',
  'Ukrainian',
  'Umbundu',
  'Undetermined',
  'Urdu',
  'Uyghur',
  'Uzbek',
  'Vai',
  'Venda',
  'Vietnamese',
  'Volapuk',
  'Votic',
  'Wakashan languages',
  'Walamo',
  'Walloon',
  'Waray',
  'Washo',
  'Welsh',
  'Wolof',
  'Wu',
  'Xhosa',
  'Xiang',
  'Yakut',
  'Yao',
  'Yapese',
  'Yiddish',
  'Yoruba',
  'Yue',
  'Yupik languages',
  'Zande',
  'Zapotec',
  'Zenaga',
  'Zhuang',
  'Zulu',
  'Zuni',
}

export default Languages;
