import { Stack, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

const AccessDenied: React.FC<PropsWithChildren> = ({ children }) => (
  <Stack flex={1} alignItems="center">
    {children}
    <Stack flex={1} p={4} justifyContent="center" alignItems="center">
      <picture>
        <source srcSet="/images/access-denied.png, /images/access-denied@2x.png 2x" />
        <img className="LandingLayout__image" src="/images/access-denied.png" alt="Access denied" />
      </picture>
      <Typography variant="h1" mb={0.5} align="center">
        <FormattedMessage id="accessDenied" />
      </Typography>
      <Typography align="center">
        <FormattedMessage id="pleaseContact" />
      </Typography>
    </Stack>
  </Stack>
);

export default AccessDenied;
