import { useQuery } from '@tanstack/react-query';

import { CalendarChartData, ChartBarData } from './apiTypes/charts';
import { CalendarChartRequest, ChartBarRequest } from './apiTypes/endpoints/charts';
import { ApiError } from './apiTypes/misc';
import { RQUseQueryOptions } from './apiTypes/query';
import { getEventsBaseQueryParams } from './events';
import * as api from './requests';
import { getFormattedParams } from './utils/getFormattedParams';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';

const SCHOOL_URL = '/school';

export const GET_STUDENT_CHART_QUERY = `${SCHOOL_URL}/GET_STUDENT_CHART_QUERY`;
export const GET_STAFF_CHART_QUERY = `${SCHOOL_URL}/GET_STAFF_CHART_QUERY`;
export const GET_ASSESSMENTS_CHART_QUERY = `${SCHOOL_URL}/GET_ASSESSMENTS_CHART_QUERY`;
export const GET_EVENTS_CHART_QUERY = `${SCHOOL_URL}/GET_EVENTS_CHART_QUERY`;
export const GET_GROUP_CHART_QUERY = `${SCHOOL_URL}/GET_STAFF_GROUP_QUERY`;

export function getChart({
  schoolId,
  filters,
  arrange_by,
  breakdown_by,
  entityType,
  query,
}: ChartBarRequest): Promise<ChartBarData> {
  // TODO get path via switch-case in groups, assessments etc implementation
  const path =
    entityType === 'student'
      ? `${SCHOOL_URL}/${schoolId}/students/chart`
      : `${SCHOOL_URL}/${schoolId}/staff/chart`;

  const params = getFormattedParams(
    {
      arrange_by,
      breakdown_by,
      query,
    },
    filters,
  );

  return api.get(path, {
    params,
  });
}

export const useGetChartQuery = (
  params: ChartBarRequest,
  options?: RQUseQueryOptions<ChartBarData>,
) => {
  const getQueryKey = () => {
    switch (params.entityType) {
      case 'student':
        return GET_STUDENT_CHART_QUERY;
      case 'staff':
        return GET_STAFF_CHART_QUERY;
    }
  };
  return useQuery<ChartBarData, ApiError>([getQueryKey(), params], async () => getChart(params), {
    ...options,
  });
};

export function getAssessmentsCalendarChartData({
  schoolId,
  filters,
}: CalendarChartRequest): Promise<CalendarChartData> {
  const dateFrom = filters?.date?.[0];
  const dateTo = filters?.date?.[1] || filters?.date?.[0];
  const p = filters ? removeObjectEmptyArrayValues(filters) : {};
  const params = {
    assessment_date_from: dateFrom,
    assessment_date_to: dateTo,
    assessment_status: p?.assessment_status?.join(','),
    group_ids: p?.group?.join(','),
    subject_ids: p?.subject?.join(','),
    staff: p?.staff?.join(','),
    report: p?.report?.join(','),
    recurrence_id: p?.recurrence_id?.join(','),
  };

  return api.get(`assessment/for-school/${schoolId}/aggregate`, {
    params,
  });
}

export function getEventsCalendarChartData({
  schoolId,
  filters,
}: CalendarChartRequest): Promise<CalendarChartData> {
  const params = filters ? getEventsBaseQueryParams(filters) : {};

  return api.get(`events/for-school/${schoolId}/aggregate`, {
    params,
  });
}

export const useGetAssessmentChartQuery = (
  params: CalendarChartRequest,
  options?: RQUseQueryOptions<CalendarChartData>,
) => {
  return useQuery<CalendarChartData, ApiError>(
    [GET_ASSESSMENTS_CHART_QUERY, params],
    () => getAssessmentsCalendarChartData(params),
    {
      ...options,
    },
  );
};

export const useGetEventsChartQuery = (
  params: CalendarChartRequest,
  options?: RQUseQueryOptions<CalendarChartData>,
) => {
  return useQuery<CalendarChartData, ApiError>(
    [GET_EVENTS_CHART_QUERY, params],
    () => getEventsCalendarChartData(params),
    {
      ...options,
    },
  );
};
