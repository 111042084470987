import { FormControl, styled } from '@mui/material';

export const FormControlHoverBorder = styled(FormControl)<{ fullWidth?: boolean }>(
  ({ theme, fullWidth }) => ({
    ...(fullWidth
      ? {
          width: '100% !important',
          '& .MuiSelect-select': {
            width: '100% !important',
            minWidth: 'unset !important',
          },
        }
      : {}),

    '& .MuiOutlinedInput-root:not(:hover)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },

      '& .MuiSelect-icon': {
        opacity: 0,
      },
    },
  }),
);

export const FormControlBorder = styled(FormControl)<{ fullWidth?: boolean }>(
  ({ theme, fullWidth }) => ({
    ...(fullWidth
      ? {
          width: '100% !important',
          '& .MuiSelect-select': {
            width: '100% !important',
            minWidth: 'unset !important',
          },
        }
      : {}),

    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        outline: '0px solid transparent',
        transition: 'all .2s',
      },

      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          outline: `3px solid ${theme.palette.background.default}`,
        },
      },
    },
  }),
);
