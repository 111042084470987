import {
  AvailableCriteria,
  GET_GROUP_DETAILS_QUERY,
  GET_MEMBERS_FOR_GROUP_QUERY,
  getGroupDetails,
  Group,
  GroupLimitations,
  GroupLimitationsUpdate,
  GroupMember,
  GroupSubject,
  GroupType,
  GroupUpdate,
  GroupUserType,
  GroupWithMembers,
} from '@schooly/api';
import { checkGroupName, createGroup, deleteGroup, updateGroup } from '@schooly/api';
import { CreateGroupResponse } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups, useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  RouterStateContext,
  RouterStateContextProps,
} from '../../../context/router/RouterStateContext';
import { useRouter } from '../../../context/router/useRouter';
import { buildCriteriaUpdate, getCriteriaArchived, getCriteriaName } from '../../../helpers/misc';
import useAppLocation, { AppLocation } from '../../../hooks/useAppLocation';
import { FormState, useForm } from '../../../hooks/useForm';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import useSchoolYears from '../../../hooks/useSchoolYears';
import useUserCounts from '../../../hooks/useUserCounts';
import { formatDateDefault } from '../../../utils/formatDate';
import IntlError from '../../../utils/intlError';
import { sortAlphabetically } from '../../../utils/sortAlphabetically';
import { getCriteriaCategory } from '../../Messages/helpers';
import { LimitedToDeletedUsers } from './LimitedToSelect/useLimitedTo';
import { DEFAULT_LIMITED_TO, getLimitedToDataFromGroup } from './schema';
// import { IValiditySelectChangeArgs } from './ValiditySelect';

export type ValidityRangeRequest = [string | '', string | ''];

type DateRange<TDate> = [TDate | null, TDate | null];
type GroupMemberOrder = SimpleListResult['relation_id'][];

export enum AddGroupModalMode {
  Initial,
  Staff,
  Students,
}

export type AddGroupTab = 'users' | 'criteria';

export interface AddGroupModalLocation extends AppLocation {
  state: AppLocation['state'] & {
    initialState: Partial<AddGroupModalContextState>;
  };
}

export interface AddGroupModalContextState
  extends Omit<GroupWithMembers, 'id'>,
    FormState<AddGroupModalContextState> {
  id?: string;
  // displays if there is no actual school year and the validity has been
  // adjusted to the closest one
  dateNa?: boolean;
  validityRange: DateRange<string | undefined>;
  // Will keep separate immutable copy of validity value of existing group,
  // as the `validityRange` might be changed by user in edit mode,
  // but we need to define a `isGroupExpired` relying on original date
  savedValidityRange: DateRange<string | undefined>;
  mode?: AddGroupModalMode;

  groupSubjects: GroupSubject[];

  limitedToStudents: GroupLimitations;
  limitedToStaff: GroupLimitations;
  prevLimitedToStudents?: GroupLimitations;
  prevLimitedToStaff?: GroupLimitations;

  groupStudents: SimpleListResult[];
  groupStaff: SimpleListResult[];
  groupStaffOrder: GroupMemberOrder;
  groupStudentsOrder: GroupMemberOrder;
  groupCriteria: AvailableCriteria[];

  deleteHistoryUsers: SimpleListResult[];

  focusedField?: keyof GroupWithMembers;
  groupNameExists?: boolean;

  activeTab: AddGroupTab;

  originalData?: AddGroupModalContextState;
}

export const CONTEXT_NAME = 'AddGroupModal';

export const getInitialState = (): AddGroupModalContextState => ({
  name: '',
  description: '',
  group_type: GroupType.Subject,
  subject: undefined,
  students_preview: [],
  staff_preview: [],
  staff_count: 0,
  student_count: 0,
  validity: {},

  students: [],
  staff: [],
  criteria: [],
  has_memberships: false,

  mode: AddGroupModalMode.Initial,
  validityRange: [null, null],
  savedValidityRange: [null, null],

  groupSubjects: [],
  limitedToStudents: DEFAULT_LIMITED_TO,
  limitedToStaff: DEFAULT_LIMITED_TO,
  groupStudents: [],
  groupStaff: [],
  groupStaffOrder: [],
  groupStudentsOrder: [],
  groupCriteria: [],

  deleteHistoryUsers: [],

  focusedField: 'name',
  groupNameExists: false,

  activeTab: 'criteria',

  originalData: undefined,
});

function getValidityRange(
  group: {} & Pick<GroupWithMembers, 'validity'>,
): DateRange<string | undefined> {
  if (group.validity.date_from) {
    return [group.validity.date_from, group.validity.date_to];
  }

  if (group.validity.from_school_year?.start) {
    return [group.validity.from_school_year?.start, group.validity.to_school_year?.end];
  }

  return [null, null];
}

function isDateExpired(date?: string | null) {
  return !!date && moment().startOf('day').isAfter(moment(date).startOf('day'));
}

export interface AddGroupModalProps {
  core?: boolean;
}

export const useAddGroupModal = ({ core }: AddGroupModalProps) => {
  const { state, setState, contextName, setContextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<AddGroupModalContextState>;
  const location = useAppLocation() as AddGroupModalLocation;
  const navigate = useNavigate();
  const { goBack, closeAndClean } = useRouter();
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const { showNotification } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const { id: groupId } = useParams<'id'>();
  const { schoolId = '', permissions } = useAuth();

  const { schoolProperties: schoolStaffProperties } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Staff,
  });
  const { schoolProperties: allStudentProperties } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
  });
  const schoolStudentProperties = allStudentProperties?.filter(
    (p) => 'type' in p && p.type !== SchoolPropertyType.Department,
  );
  const { ageGroups, schoolLevels } = useAgeGroups({
    schoolId: schoolId ?? '',
    userType: SchoolUserRole.Student,
  });

  const { getDefaultValidity } = useSchoolYears();
  // TR-3540 E Groups: Empty dropdown list in Subject
  const {
    subjects,
    activeSubjects,
    isRefetching: subjectsRefetching,
    isLoading: subjectsLoading,
  } = useSubjects({ schoolId: schoolId! });
  const invalidateQueries = useInvalidateListQueriesFor('group');
  const canManageGroup = permissions.includes('group_manager');
  const groupMembers: GroupUserType[] = useMemo(() => ['staff', 'student'], []);
  const groupViewerCanOnlyManageStudents = useMemo(
    () =>
      permissions.includes('group_viewer') && !canManageGroup && state?.can_manage_student_members,
    [state, permissions, canManageGroup],
  );
  const canEditGroup = canManageGroup || groupViewerCanOnlyManageStudents;
  const isGroupExpired = !!groupId && isDateExpired(state?.savedValidityRange?.[1]);
  const [initialized, setInitialized] = useState(false);
  const { updateCounts, userCounts } = useUserCounts();

  const groupSubjects = useMemo<GroupSubject[]>(
    () => sortAlphabetically(activeSubjects, 'name') ?? [],
    [activeSubjects],
  );

  const { section: openedSection } = useQueryStringParams();

  const form = useForm({
    core,
    state,
    setState,
    rules: {
      name: {
        required: true,
        async: (state) => {
          if (state?.groupNameExists) {
            return { id: 'error-GroupAlreadyExists' };
          }
        },
      },

      validityRange: {
        custom: (state) => {
          const [startDate, endDate] = state?.validityRange || [];

          if (!startDate || !endDate) {
            return { id: 'input-ErrorRequired' };
          }

          if (startDate && isDateExpired(endDate)) {
            return { id: 'groups-ErrorPastDate', values: { endDate } };
          }
        },
      },
    },
  });

  const setContextState = useCallback(
    (newState: Partial<AddGroupModalContextState>, saveOriginalData?: boolean) => {
      setState((prevState) => {
        const data: AddGroupModalContextState = {
          ...((prevState ?? {}) as AddGroupModalContextState),
          ...newState,
        };

        if (saveOriginalData) {
          const { originalData, ...withoutOriginalData } = data;
          data.originalData = { ...withoutOriginalData };
        }

        return data;
      });
    },
    [setState],
  );

  const uniqStudentsCount = useMemo(() => {
    const uniqueRelationIds = !!state?.groupCriteria?.length
      ? state.groupCriteria.reduce<string[]>(
          (acc, item) => [...acc, ...(item.relation_ids ?? [])],
          [],
        )
      : [];

    const criteriaUniqueUsersId = !!uniqueRelationIds.length
      ? Array.from(new Set(uniqueRelationIds))
      : [];
    const groupStudentsIds = (state?.groupStudents ?? []).map((student) => student.relation_id);

    return Array.from(new Set([...criteriaUniqueUsersId, ...groupStudentsIds])).length;
  }, [state?.groupStudents, state?.groupCriteria]);

  const validityRangeRequest = useMemo(
    (): ValidityRangeRequest => [
      formatDateDefault(state?.validityRange?.[0]) || '',
      formatDateDefault(state?.validityRange?.[1]) || '',
    ],
    [state?.validityRange],
  );

  const setStateHandler = useCallback(
    (stateKey: keyof AddGroupModalContextState, value?: unknown) => {
      return (callbackValue: unknown) =>
        setContextState({ [stateKey]: value === undefined ? callbackValue : value });
    },
    [setContextState],
  );

  const onInputChangeHandler = useCallback(
    (stateKey: keyof AddGroupModalContextState) => {
      return (e: React.ChangeEvent<HTMLInputElement>) =>
        setContextState({ [stateKey]: e.target.value });
    },
    [setContextState],
  );

  const checkGroupNameExists = useCallback(
    async (currentName?: string, updatedRange?: ValidityRangeRequest) => {
      const [fromDate, toDate] = updatedRange || validityRangeRequest;

      if (currentName && schoolId && fromDate && toDate) {
        const { exists, group: existingGroup } = await checkGroupName(
          schoolId,
          currentName.trim(),
          undefined,
          undefined,
          undefined,
          fromDate,
          toDate,
        );

        setContextState({ groupNameExists: exists && existingGroup?.id !== groupId });
        return;
      }
      setContextState({ groupNameExists: false });
    },
    [groupId, schoolId, setContextState, validityRangeRequest],
  );

  const checkGroupNameExistsDebounced = useMemo(
    () => debounce(checkGroupNameExists, 500),
    [checkGroupNameExists],
  );

  const onGroupNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.set('name', e.target.value);

      checkGroupNameExistsDebounced(e.target.value);
    },
    [checkGroupNameExistsDebounced, form],
  );

  const setValidityRangeHandler = useCallback(
    (args: { date: string[] }) => {
      const validityRange: ValidityRangeRequest = [args.date[0], args.date[1]];

      form.set('validityRange', validityRange);
      // some kind of a useForm workaround for https://schooly.atlassian.net/browse/TR-6639
      // need to trigger name to reset error before checkGroupNameExists executed to clear error
      if (state?.name) {
        form.set('name', state.name);
        checkGroupNameExists(state.name, validityRange);
      }
    },
    [form, checkGroupNameExists, state?.name],
  );

  const setGroupType = useCallback(
    (group_type?: GroupType) => {
      setContextState({ group_type });
    },
    [setContextState],
  );

  const setGroup = useCallback(
    (group: GroupWithMembers, saveOriginalData?: boolean) => {
      const data: Partial<AddGroupModalContextState> = {
        // ...getInitialState(),
        ...group,
        validityRange: getValidityRange(group),
        savedValidityRange: getValidityRange(group),
      };

      setContextState(data, saveOriginalData);
    },
    [setContextState],
  );

  const setLimitedToStudents = useCallback(
    (
      limitedToStudents: AddGroupModalContextState['limitedToStudents'],
      saveOriginalData?: boolean,
    ) => setContextState({ limitedToStudents }, saveOriginalData),
    [setContextState],
  );

  const setLimitedToStaff = useCallback(
    (limitedToStaff: AddGroupModalContextState['limitedToStaff'], saveOriginalData?: boolean) =>
      setContextState({ limitedToStaff }, saveOriginalData),
    [setContextState],
  );

  const setGroupStudents = useCallback(
    (groupStudents: AddGroupModalContextState['groupStudents'], saveOriginalData?: boolean) =>
      setContextState({ groupStudents }, saveOriginalData),
    [setContextState],
  );

  const setGroupStaff = useCallback(
    (groupStaff: AddGroupModalContextState['groupStaff'], saveOriginalData?: boolean) =>
      setContextState({ groupStaff }, saveOriginalData),
    [setContextState],
  );

  const setGroupCriteria = useCallback(
    (groupCriteria: AddGroupModalContextState['groupCriteria']) =>
      setContextState({ groupCriteria }),
    [setContextState],
  );

  const setRemoveUserHistory = useCallback(
    (deleteHistoryUsers: SimpleListResult[]) => {
      setContextState({ deleteHistoryUsers });
    },
    [setContextState],
  );

  const setActiveTab = useCallback(
    (activeTab: AddGroupTab) => {
      setContextState({ activeTab });
    },
    [setContextState],
  );

  const closeModal = goBack;

  const removeGroupMembers = useCallback(
    ({
      studentMemberIds,
      staffMemberIds,
      criteriaIds,
    }: {
      studentMemberIds: string[];
      staffMemberIds: string[];
      criteriaIds: string[];
    }) => {
      const newState: Partial<
        Pick<AddGroupModalContextState, 'groupCriteria' | 'groupStaff' | 'groupStudents'>
      > = {};

      if (!!studentMemberIds.length) {
        newState.groupStudents = state.groupStudents?.filter(
          (user) => !studentMemberIds.some((rId) => user.relation_id === rId),
        );
      }

      if (!!staffMemberIds.length) {
        newState.groupStaff = state.groupStaff?.filter(
          (user) => !staffMemberIds.some((rId) => user.relation_id === rId),
        );
      }

      if (!!criteriaIds.length) {
        newState.groupCriteria = [];
      }
      // Preventing update state race
      setTimeout(() => setContextState(newState));
    },
    [setContextState, state?.groupStaff, state?.groupStudents],
  );

  const handleLimitedToChange = useCallback(
    async (data: GroupLimitationsUpdate, type: GroupUserType, members?: LimitedToDeletedUsers) => {
      if (type === 'student') {
        setLimitedToStudents(data, true);
      } else {
        setLimitedToStaff(data, true);
      }

      if (!members) return;

      const studentMemberIds = type === 'student' ? members.deletedMemberIds : [];
      const staffMemberIds = type === 'staff' ? members.deletedMemberIds : [];

      removeGroupMembers({
        studentMemberIds,
        staffMemberIds,
        criteriaIds: members.deletedCriteriaMemberIds,
      });
    },
    [removeGroupMembers, setLimitedToStudents, setLimitedToStaff],
  );

  const handleGroupUsersChange = useCallback(
    (users: SimpleListResult[]) => {
      if (state?.mode === AddGroupModalMode.Students) {
        setGroupStudents(users);
      } else {
        setGroupStaff(users);
      }
    },
    [state?.mode, setGroupStudents, setGroupStaff],
  );

  const handleUserAdd = useCallback(
    (userId: string, user?: SimpleListResult) => {
      if (!user) {
        return;
      }

      const groupMemberOrder =
        state.mode === AddGroupModalMode.Students
          ? state.groupStudentsOrder
          : state.groupStaffOrder;

      const groupUsers =
        state.mode === AddGroupModalMode.Students ? state.groupStudents : state.groupStaff;

      const positionExisted = groupMemberOrder.find((relId) => relId === userId);

      if (positionExisted) {
        const reorderedGroupMembers: SimpleListResult[] = [];

        groupMemberOrder.forEach((relId) => {
          const existedMember = groupUsers.find((u) => u.relation_id === relId);
          if (existedMember) {
            reorderedGroupMembers.push(existedMember);
          } else if (relId === userId) {
            reorderedGroupMembers.push(user);
          }
        });

        handleGroupUsersChange([
          ...reorderedGroupMembers,
          ...groupUsers.filter(
            (u) => !reorderedGroupMembers.some(({ relation_id }) => relation_id === u.relation_id),
          ),
        ]);
      } else if (!groupUsers.find(({ relation_id }) => relation_id === userId)) {
        handleGroupUsersChange([...groupUsers, user]);
      }
    },
    [
      state?.mode,
      state?.groupStudentsOrder,
      state?.groupStaffOrder,
      state?.groupStudents,
      state?.groupStaff,
      handleGroupUsersChange,
    ],
  );

  const handleUsersAdd = useCallback(
    (users: SimpleListResult[]) => {
      const groupMemberOrder =
        state.mode === AddGroupModalMode.Students
          ? state.groupStudentsOrder
          : state.groupStaffOrder;

      const groupUsers =
        state.mode === AddGroupModalMode.Students ? state.groupStudents : state.groupStaff;

      handleGroupUsersChange([
        ...groupUsers,
        ...users.filter((u) => !!u.user_id && !groupMemberOrder.includes(u.user_id)),
      ]);
    },
    [
      state?.mode,
      state?.groupStudentsOrder,
      state?.groupStaffOrder,
      state?.groupStudents,
      state?.groupStaff,
      handleGroupUsersChange,
    ],
  );

  const handleUserRemove = useCallback(
    (userId: string) => {
      const groupUsers =
        state.mode === AddGroupModalMode.Students ? state.groupStudents : state.groupStaff;
      handleGroupUsersChange(groupUsers.filter(({ relation_id }) => userId !== relation_id));
    },
    [state?.groupStaff, state?.groupStudents, state?.mode, handleGroupUsersChange],
  );

  const handleUserHistoryRemove = useCallback(
    (userId: string) => {
      const groupUsers =
        state.mode === AddGroupModalMode.Students ? state.groupStudents : state.groupStaff;

      const user = groupUsers.find(({ relation_id }) => userId === relation_id);

      if (!user) {
        return;
      }

      const deleteHistoryUsers = [...(state.deleteHistoryUsers || []), user];

      handleGroupUsersChange(groupUsers.filter(({ relation_id }) => userId !== relation_id));
      setRemoveUserHistory(deleteHistoryUsers);
    },
    [
      state?.mode,
      state?.groupStudents,
      state?.groupStaff,
      state?.deleteHistoryUsers,
      handleGroupUsersChange,
      setRemoveUserHistory,
    ],
  );

  const handleUserRecover = useCallback(
    (userId: string) => {
      const deleteHistoryUsers = state.deleteHistoryUsers?.filter(
        ({ relation_id }) => relation_id !== userId,
      );

      handleUserAdd(userId);
      setRemoveUserHistory(deleteHistoryUsers);
    },
    [state?.deleteHistoryUsers, setRemoveUserHistory, handleUserAdd],
  );

  const handleCriteriaClick = useCallback(
    async (criteria: AvailableCriteria) => {
      const existingCriteria = state.groupCriteria?.find(
        ({ school_property, group, enum_index, type }) => {
          if (school_property) {
            return school_property.id === criteria.school_property?.id;
          } else if (group) {
            return group.id === criteria.group?.id;
          } else {
            return enum_index === criteria.enum_index && type === criteria.type;
          }
        },
      );

      if (!existingCriteria) {
        setGroupCriteria([...state.groupCriteria, criteria]);
      } else {
        if (getCriteriaArchived(criteria)) {
          const isConfirmed = await getConfirmation({
            textId: `deselect-property-archived-${getCriteriaCategory(criteria)}`,
            textValues: { name: getCriteriaName(criteria) },
          });

          if (!isConfirmed) {
            return;
          }
        }

        setGroupCriteria(
          state.groupCriteria.filter(({ school_property, group, enum_index, type }) => {
            if (school_property) {
              return school_property.id !== criteria.school_property?.id;
            } else if (group) {
              return group.id !== criteria.group?.id;
            } else {
              return !(enum_index === criteria.enum_index && type === criteria.type);
            }
          }),
        );
      }
    },
    [state?.groupCriteria, setGroupCriteria, getConfirmation],
  );

  const prepareState = useCallback(
    (source?: Partial<AddGroupModalContextState>) => {
      const initialState = getInitialState();

      let dateNa = false;
      let validity = initialState.validity;

      const defaultValidity = getDefaultValidity(true);

      if (defaultValidity) {
        validity = {
          date_from: defaultValidity.start,
          date_to: defaultValidity.end,
        };

        dateNa = !source?.validity && !defaultValidity.isActual;
      }

      const stateToReturn = {
        ...initialState,
        validity,
        dateNa,
        ...(location.state?.initialState ?? state),
        ...source,
      };

      stateToReturn.validityRange = getValidityRange({ validity: stateToReturn.validity });
      stateToReturn.activeTab = state?.activeTab ?? initialState?.activeTab;

      const { limited_to_staff, limited_to_students } = getLimitedToDataFromGroup(
        stateToReturn as unknown as Group,
      );
      const { criteria, students, staff } = stateToReturn;

      if (limited_to_staff) {
        stateToReturn.limitedToStaff = limited_to_staff;
      }

      if (limited_to_students) {
        stateToReturn.limitedToStudents = {
          ...limited_to_students,
          school_property_ids: (limited_to_students.school_property_ids as string[]) || [],
        };
      }

      if (criteria) {
        stateToReturn.groupCriteria = criteria.map((c: AvailableCriteria) => ({
          ...c,
          relation_ids: students
            ?.filter((m: GroupMember) => c.id && m.criteria_ids.includes(c.id))
            .map((m: GroupMember) => m.user.relation_id),
        }));
      }

      if (students) {
        const groupStudents = students.filter((i) => i.is_individual).map((i) => i.user);

        stateToReturn.groupStudents = groupStudents;
        stateToReturn.groupStudentsOrder = groupStudents.map(({ relation_id }) => relation_id);
      }

      if (staff) {
        const groupStaff = staff.filter((i) => i.is_individual).map((i) => i.user);
        stateToReturn.groupStaff = groupStaff;
        stateToReturn.groupStaffOrder = groupStaff.map(({ relation_id }) => relation_id);
      }

      if (openedSection) {
        stateToReturn.mode = +openedSection;
      }

      return stateToReturn;
    },

    [getDefaultValidity, location.state?.initialState, openedSection, state],
  );

  useEffect(() => {
    if (!location.state?.initialState?.name) return;

    checkGroupNameExists(location.state.initialState.name);
  }, [location.state?.initialState?.name, checkGroupNameExists]);

  const performExistingGroupLoadRequest = useCallback(
    async (id?: string) => {
      if (!id || !location.pathname.includes('groups')) {
        return;
      }

      const { group } = await getGroupDetails({ id });

      // TODO:
      // const expiredDate = new Date(
      //   `${group.validity.to_school_year?.end ?? group.validity.to_date}`,
      // ).getTime();
      // const now = new Date().getTime();
      // const isExpired = expiredDate < now;
      //
      // if (isExpired) {
      //   setIsGroupExpired();
      // } else {
      //   setIsGroupNotExpired();
      // }

      const data = prepareState(group) as GroupWithMembers;

      setGroup(data, true);
    },
    [location.pathname, prepareState, setGroup],
  );

  const performRemoveRequest = useCallback(async () => {
    if (!groupId) {
      return;
    }

    const isConfirmed = await getConfirmation({
      message: formatMessage({ id: 'groups-DeleteConfirmation' }, { groupName: state?.name }),
    });

    if (!isConfirmed) {
      return;
    }

    await deleteGroup(groupId);
    invalidateQueries();
    closeAndClean();

    showNotification({
      textId: 'confirmation-GroupRemove',
      type: 'success',
    });
  }, [
    groupId,
    getConfirmation,
    formatMessage,
    state?.name,
    invalidateQueries,
    closeAndClean,
    showNotification,
  ]);

  const performUpdateRequest = useCallback(async () => {
    if (!schoolId) {
      throw new IntlError('error-NoSchoolId');
    }

    const { name, description, group_type } = state;
    const [date_from, date_to] = validityRangeRequest;

    let finalData: GroupUpdate = {
      description,
      group_type,
      limited_to_students: state.limitedToStudents,
      student_ids: state.groupStudents.map(({ relation_id }) => relation_id),
      criteria: buildCriteriaUpdate(state.groupCriteria),
    };

    if (canManageGroup) {
      if (finalData.limited_to_students?.subject_ids) {
        const { subject_ids, ...limitedToStudents } = finalData.limited_to_students;
        finalData.limited_to_students = limitedToStudents;
      }

      finalData = {
        ...finalData,
        name,
        subject_id: state.subject?.id ?? null,
        validity: {
          date_from,
          date_to,
        },
        limited_to_staff: state.limitedToStaff,
        staff_ids: state.groupStaff.map(({ relation_id }) => relation_id),
      };

      if (state.deleteHistoryUsers.length && groupId) {
        finalData = {
          ...finalData,
          relation_ids_to_remove: state.deleteHistoryUsers.map(({ relation_id }) => relation_id),
        };
      }
    }

    const isUpdatingGroup = !!groupId;

    const response = isUpdatingGroup
      ? await updateGroup(groupId, finalData)
      : await createGroup(schoolId, finalData);

    if (groupId) {
      // invalidating the queries cache for GroupPreview modal
      queryClient.invalidateQueries([GET_GROUP_DETAILS_QUERY, groupId]);
      queryClient.invalidateQueries([GET_MEMBERS_FOR_GROUP_QUERY, groupId]);
    }

    invalidateQueries();
    if (!userCounts?.group) updateCounts();

    if (!!state.deleteHistoryUsers.length) {
      setContextState({ deleteHistoryUsers: [] });
    }

    closeModal();

    showNotification({
      textId: isUpdatingGroup
        ? 'confirmation-GroupEdit'
        : (response as CreateGroupResponse).success,
      type: 'success',
      actions: isUpdatingGroup
        ? undefined
        : [
            {
              textId: 'groups-ViewGroup',
              handler: () =>
                navigate(`/groups/${(response as CreateGroupResponse).group_id}`, {
                  state: location.state,
                }),
              buttonColor: 'light',
            },
          ],
    });
  }, [
    canManageGroup,
    closeModal,
    groupId,
    invalidateQueries,
    location.state,
    navigate,
    queryClient,
    schoolId,
    setContextState,
    showNotification,
    state,
    updateCounts,
    userCounts?.group,
    validityRangeRequest,
  ]);

  const onFieldEditClick = useCallback(
    (key?: keyof GroupWithMembers) => {
      setContextState({ mode: AddGroupModalMode.Initial, focusedField: key });
    },
    [setContextState],
  );

  const [submitGroup, isSavingInProgress] = useRequestWithProgress(performUpdateRequest);
  const [removeGroup, isRemoveInProgress] = useRequestWithProgress(performRemoveRequest);
  const [loadExistingGroup, isGroupLoading] = useRequestWithProgress(
    performExistingGroupLoadRequest,
  );

  useEffect(() => {
    if (!core) {
      return;
    }

    if (!initialized) {
      setInitialized(true);
      // Update the context state on a next tick to avoid race condition
      // with WithRouterState.tsx -> useEffect on location change
      setTimeout(() => {
        if (groupId) {
          loadExistingGroup(groupId);
        } else {
          setContextState(prepareState());
        }
      });
    }
  }, [
    contextName,
    core,
    groupId,
    loadExistingGroup,
    prepareState,
    setContextName,
    setContextState,
    initialized,
    location?.state?.reconcile?.state,
  ]);

  useEffect(() => {
    if (!isEqual(state?.limitedToStaff, state?.prevLimitedToStaff)) {
      setContextState({ prevLimitedToStaff: state?.limitedToStaff });
    }

    if (!isEqual(state?.limitedToStudents, state?.prevLimitedToStudents)) {
      setContextState({ prevLimitedToStudents: state?.limitedToStudents });
    }
  }, [
    state?.limitedToStaff,
    state?.limitedToStudents,
    state?.prevLimitedToStaff,
    state?.prevLimitedToStudents,
    setContextState,
  ]);

  const contextActions = useCallback(
    (userId: string) => {
      const baseAction = [
        {
          titleTextId: 'membership-endMembership',
          handler: () => {
            handleUserRemove(userId);
            setActiveTab('users');
          },
        },
      ];

      if (!canManageGroup) {
        return baseAction;
      }

      return [
        ...baseAction,
        {
          titleTextId: 'groups-deleteHistory',
          handler: () => handleUserHistoryRemove(userId),
        },
      ];
    },
    [canManageGroup, handleUserHistoryRemove, handleUserRemove, setActiveTab],
  );

  const isIndividualMember = useCallback(
    (userId: string) => {
      if (!groupId) return false;

      const groupUsers = state.mode === AddGroupModalMode.Students ? state.students : state.staff;

      return groupUsers.some(
        ({ user: { relation_id }, is_individual }) => userId === relation_id && !!is_individual,
      );
    },
    [state?.mode, state?.staff, state?.students, groupId],
  );

  return {
    ...state,
    form,
    schoolStaffProperties,
    schoolStudentProperties,
    ageGroups,
    schoolLevels,
    uniqStudentsCount,
    validityRangeRequest,
    groupId,
    saving: isSavingInProgress,
    deleting: isRemoveInProgress,
    isGroupLoading: !initialized || isGroupLoading,
    pending: isSavingInProgress || isRemoveInProgress || isGroupLoading,
    groupViewerCanOnlyManageStudents,
    isGroupExpired,
    canEditGroup,
    groupMembers,
    groupSubjects,
    contextActions,
    isIndividualMember,
    subjects,
    subjectsLoading: subjectsRefetching || subjectsLoading,
    removeGroupMembers,

    actions: {
      setContextState,
      setStateHandler,
      onInputChangeHandler,
      setGroup,
      setLimitedToStudents,
      setLimitedToStaff,
      setGroupStudents,
      setGroupStaff,
      setGroupCriteria,
      setActiveTab,
      setValidityRangeHandler,
      setGroupType,
      handleLimitedToChange,
      handleUserAdd,
      handleUserRemove,
      handleCriteriaClick,
      submitGroup,
      removeGroup,
      closeModal,
      onFieldEditClick,
      onGroupNameChange,
      handleUserHistoryRemove,
      handleUserRecover,
      handleUsersAdd,
    },
  };
};
