import { Stack, Typography } from '@mui/material';
import { AvailableCriteria, GroupLimitationsUpdate, GroupUserType } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { PlusIcon } from '@schooly/style';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AvailableUsersWithFilter } from '../../../../components/common/AvailableUsersWithFilter/AvailableUsersWithFilter';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import { ModalPanel } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { WithFilters } from '../../../../context/filters/WithFilters';
import usePrevious from '../../../../hooks/usePrevious';
import GroupModalTabs from '../GroupModalTabs';
import { useAddGroupModal, ValidityRangeRequest } from '../useAddGroupModal';
import AvailableCriteriaList from './AvailableCriteria';

import './index.scss';

const TABS = {
  criteria: 'groups-AvailableCriteria',
  users: 'groups-AvailableUsers',
};

export interface ExternalSidebarProps {
  userType: GroupUserType;
  availableCriteria?: AvailableCriteria[];
  groupUsers: SimpleListResult[];
  groupCriteria: AvailableCriteria[];
  onCriteriaClick?: (criteria: AvailableCriteria) => void;
  onUserClick: (userId: string, user?: SimpleListResult) => void;
  onAddUsers: (users: SimpleListResult[]) => void;
  editedGroupId?: string;
  limitedTo: GroupLimitationsUpdate;
  isLimitedToOpen?: boolean;
  validityRange: ValidityRangeRequest;
  titleId: string;
  deleteHistoryUsers?: SimpleListResult[];
  onRemovedUserClick?: (userId: string) => void;
  isFetching?: boolean;
}

const ExternalSidebar: React.FC<ExternalSidebarProps> = ({
  userType,
  availableCriteria,
  groupUsers,
  groupCriteria,
  onCriteriaClick,
  onUserClick,
  onAddUsers,
  editedGroupId,
  limitedTo,
  isLimitedToOpen,
  validityRange,
  titleId,
  deleteHistoryUsers,
  onRemovedUserClick,
  isFetching,
}) => {
  const { formatMessage } = useIntl();
  const [loadedUsers, setLoadedUsers] = useState<SimpleListResult[] | undefined>([]);
  const {
    activeTab,
    actions: { setActiveTab },
  } = useAddGroupModal({});

  const prevDeleteHistoryUsers = usePrevious(deleteHistoryUsers);

  useEffect(() => {
    if (
      deleteHistoryUsers?.length &&
      prevDeleteHistoryUsers?.length !== deleteHistoryUsers.length &&
      activeTab !== 'users'
    ) {
      setActiveTab('users');
    }
  }, [deleteHistoryUsers?.length, activeTab, prevDeleteHistoryUsers?.length, setActiveTab]);

  if (!userType) {
    return null;
  }

  function renderContent() {
    if (userType === 'staff' || activeTab === 'users') {
      return (
        <>
          <WithFilters
            key={userType}
            type={userType === 'staff' ? 'sidebar-group-staff' : 'sidebar-group-students'}
          >
            <AvailableUsersWithFilter
              userType={userType}
              selectedCriteria={groupCriteria}
              selectedUsers={groupUsers}
              onUserClick={onUserClick}
              searchPlaceholderTextId="groups-AvailableUsersSearch"
              filterPlaceholderTextId="groups-FilterUserType"
              limitedTo={limitedTo}
              validityRange={validityRange}
              deleteHistoryUsers={deleteHistoryUsers}
              onRemovedUserClick={onRemovedUserClick}
              onLoad={setLoadedUsers}
              isModalOpen
            />
          </WithFilters>
        </>
      );
    }

    return (
      <AvailableCriteriaList
        userType={userType}
        criteria={availableCriteria}
        groupCriteria={groupCriteria}
        onCriteriaClick={onCriteriaClick}
        isFetching={isFetching}
      />
    );
  }

  // TODO: drop className="GroupExternalSidebar"
  return (
    <Stack sx={{ height: '100%' }} className="GroupExternalSidebar">
      <ModalHeader title={formatMessage({ id: titleId })} active />
      <ModalPanel
        active
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {userType === 'student' && (
          <GroupModalTabs tabs={TABS} activeTab={activeTab} onTabSelect={setActiveTab} />
        )}
        {renderContent()}
      </ModalPanel>
      {loadedUsers && loadedUsers?.length > 1 && userType === 'student' && (
        <ModalPanel
          active
          withBorderTop
          sx={{
            px: 2.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 63,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              borderRadius: 1,
              width: '100%',
            }}
          >
            <SimpleButton startIcon={<PlusIcon />} onClick={() => onAddUsers(loadedUsers)}>
              <Typography variant="h3">
                <FormattedMessage
                  id="groups-AddAllStudents"
                  values={{ count: loadedUsers.length }}
                />
              </Typography>
            </SimpleButton>
          </Stack>
        </ModalPanel>
      )}
    </Stack>
  );
};

export default ExternalSidebar;
