import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="#B3BBCB"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.01223 5.41213C6.89507 5.52929 6.70512 5.52929 6.58797 5.41213L4.0001 2.82426L1.41223 5.41213C1.29507 5.52929 1.10512 5.52929 0.987966 5.41213C0.870809 5.29497 0.870809 5.10502 0.987966 4.98787L3.78797 2.18787C3.90512 2.07071 4.09507 2.07071 4.21223 2.18787L7.01223 4.98787C7.12939 5.10503 7.12939 5.29497 7.01223 5.41213Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
