import {
  BaseUser,
  Child,
  NewFamilyMember,
  NewUser,
  ProfileSearchResult,
  SchoolRelation,
  SchoolUserRelationInBaseUser,
  StudentSchoolRelation,
  UserType,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { generateTempUserId } from '../components/common/CreatePersonForm/utils';
import {
  getStudentGuardianPrimaryContactId,
  getStudentParents,
  getStudentPrimaryContactId,
  updateFamily,
} from '../helpers/students';
import { getUserFullName } from '../helpers/users';
import IntlError from '../utils/intlError';
import useRequestWithProgress from './useRequestWithProgress';

function isNewUser(user: BaseUser | NewUser): user is NewUser {
  return !('user_id' in user);
}

export default function useParentsEdit(
  child: Child | NewUser | StudentSchoolRelation | undefined,
  closeAdultSelect: () => void,
  onEditComplete: () => void,
  studentMembership?: SchoolRelation,
  userType?: UserType,
) {
  const { schoolId } = useAuth();
  const { showNotification } = useNotifications();

  const [parents, setParents] = useState<(NewFamilyMember | ProfileSearchResult)[]>([]);
  const [primaryContactId, setPrimaryContactId] = useState<string | undefined>();

  const resetParentEdit = useCallback(() => {
    setParents([]);
    setPrimaryContactId(undefined);
  }, []);

  const setDefaultParents = useCallback(
    (user?: Child | SchoolUserRelationInBaseUser, userType?: UserType) => {
      if (!user) return;

      setParents(getStudentParents(user, userType) as unknown as NewFamilyMember[]);
      setPrimaryContactId(getStudentPrimaryContactId(user, userType));
    },
    [],
  );

  const refreshParentEdit = useCallback(() => {
    if (child && !isNewUser(child)) {
      setParents(getStudentParents(child, userType) as unknown as NewFamilyMember[]);
      setPrimaryContactId(getStudentPrimaryContactId(child, userType));
    } else {
      setParents([]);
      setPrimaryContactId(undefined);
    }
  }, [child, userType]);

  useEffect(() => {
    if (child && !isNewUser(child)) {
      setParents(getStudentParents(child, userType) as unknown as NewFamilyMember[]);
      setPrimaryContactId(getStudentPrimaryContactId(child, userType));
    }
  }, [child, userType]);

  const familyEmails = useMemo(() => {
    if (!child) return;

    return [
      child.email,
      ...(parents as NewFamilyMember[]).map(({ email }) => email),
    ].filter<string>(Boolean as any);
  }, [child, parents]);

  const updateFamilyCallback = useCallback(async () => {
    if (!schoolId) {
      throw new IntlError('error-NoSchoolId');
    }

    if (!parents.length) {
      throw new IntlError('people-NoParentsProvided');
    }

    if (!child || isNewUser(child)) {
      console.error('Inconsistent state: Existing student profile not set');
      return;
    }
    closeAdultSelect();

    const prevParentsLength = getStudentParents(child)?.length;
    const prevPrimaryContactId = getStudentGuardianPrimaryContactId(child);

    await updateFamily(
      child,
      parents as NewFamilyMember[],
      primaryContactId || '',
      schoolId,
      studentMembership,
      userType,
    );

    const isPrimaryContactChanged = prevPrimaryContactId !== primaryContactId;

    const userName = getUserFullName(child);

    if (parents.length !== prevParentsLength && isPrimaryContactChanged) {
      showNotification({
        textId: 'confirmation-AddParentsAndChangePrimaryContact',
        values: { userName },
        type: 'success',
      });
    } else if (isPrimaryContactChanged) {
      showNotification({
        textId: 'confirmation-ChangePrimaryContact',
        values: { userName },
        type: 'success',
      });
    } else {
      showNotification({
        textId: 'confirmation-AddParents',
        values: { userName },
        type: 'success',
      });
    }

    onEditComplete();
  }, [
    child,
    closeAdultSelect,
    onEditComplete,
    parents,
    primaryContactId,
    schoolId,
    showNotification,
    studentMembership,
    userType,
  ]);

  const [handleSubmit, isSubmitInProgress] = useRequestWithProgress(updateFamilyCallback);

  const handleSelectParent = useCallback(
    (user: ProfileSearchResult) => {
      const newParents = [...parents, user];

      if (!primaryContactId) {
        setPrimaryContactId(newParents[0].user_id);
      }

      setParents(newParents);
    },
    [parents, primaryContactId],
  );

  const handleSaveCreatedParent = useCallback(
    (newParent: NewUser) => {
      const newParents = [...parents, { ...newParent, user_id: generateTempUserId() }];

      if (!primaryContactId) {
        setPrimaryContactId(newParents[0].user_id);
      }

      setParents(newParents);
    },
    [parents, primaryContactId],
  );

  const handleDeleteParent = useCallback(
    (parentId?: string) => {
      const newParents = parents.filter((p) => p.user_id !== parentId);

      if (newParents.length) {
        setPrimaryContactId((currentId) => {
          if (!newParents.find((p) => p.user_id === currentId)) {
            return newParents[0].user_id;
          }

          return currentId;
        });
      } else {
        setPrimaryContactId(undefined);
      }

      setParents(newParents);
    },
    [parents],
  );

  return {
    parents,
    primaryContactId,
    familyEmails,
    setPrimaryContactId,
    handleSelectParent,
    handleSaveCreatedParent,
    handleDeleteParent,
    handleSubmit,
    isSubmitInProgress,
    resetParentEdit,
    refreshParentEdit,
    setDefaultParents,
  };
}
