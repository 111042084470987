export enum SchoolUserRole {
  Admin = -1,
  Staff,
  Student,
  Parent,
}

export enum SchoolPropertyType {
  Campus = 'campus',
  House = 'house',
  AgeGroup = 'age_group',
  Status = 'status',
  Department = 'department',
}
