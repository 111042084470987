import { DateIcon } from '@schooly/style';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

import { useProfile } from '../../../context/profile/useProfile';
import formatDate from '../../../utils/formatDate';
import DateModal from './DateModal';
import ProfileProperty from './ProfileProperty';

interface DatePropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}

const DateProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
}: DatePropertyParams) => {
  const { user, userType, canEditProfile, hasEditingPermission } = useProfile();

  const age = useMemo(
    () => Math.floor(moment().diff(moment(user?.date_of_birth)) / (3600 * 24 * 1000 * 365)),
    [user?.date_of_birth],
  );

  const renderAge = useCallback(
    () =>
      user?.date_of_birth && (userType === 'child' || userType === 'student') ? (
        <div className="ml-1 age-desc">
          <div>{`${age} y.o`}</div>
        </div>
      ) : null,
    [age, userType, user],
  );

  return (
    <ProfileProperty
      value={user?.date_of_birth}
      canEdit={canEditProfile && hasEditingPermission}
      params={{ size, iconColor, color }}
      renderValue={(value) => formatDate(value) || ''}
      renderIcon={() => <DateIcon />}
      renderAdditionalField={renderAge}
      editValueTextId="peopleDetail-DateOfBirth"
      valueTextId="peopleDetail-DateOfBirth"
      fieldName="date_of_birth"
      modalController={({ isOpen, hide }) => <DateModal isOpen={isOpen} onClose={hide} />}
    />
  );
};

export default DateProperty;
