import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4C3 3.44772 3.44772 3 4 3H12C12.5523 3 13 3.44772 13 4V8V13H8H5H4C3.44772 13 3 12.5523 3 12V4ZM13 14H14V8H17C17.5523 8 18 8.44772 18 9V13V17C18 17.5523 17.5523 18 17 18H9C8.44772 18 8 17.5523 8 17V14H13ZM9.16935 8.6511L9.42473 7.37775H10.5V6.71429H9.55914L9.90412 5H9.14695L8.80197 6.71429H7.81183L8.15681 5H7.39964L7.05466 6.71429H5.83602V7.37775H6.92025L6.66487 8.6511H5.5V9.31044H6.53495L6.19444 11H6.95161L7.29211 9.31044H8.27778L7.93728 11H8.69892L9.03943 9.31044H10.1595V8.6511H9.16935ZM7.6819 7.37775H8.66756L8.41219 8.6511H7.42204L7.6819 7.37775Z"
      fill="#B3BBCB"
    />
  </svg>
);
