import { Icon, Stack, Typography } from '@mui/material';
import { Event } from '@schooly/api';
import { getParticipantLabel } from '@schooly/components/filters';
import { EventsInvite } from '@schooly/constants';
import { ParentIcon, StudentIcon } from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { InviteesCellContent, InviteesCellContentProps } from '../../Events/EventsGrid';
import { useEventColor } from '../../Events/useEventColor';
import { formatEventDate } from '../../SignUps/SignUpCreateModal/SignUpEventDetails';

type EventPreviewProps = {
  event: Event;
};

export const EventPreview: FC<EventPreviewProps> = ({ event }) => {
  return (
    <>
      <Stack direction="row" alignItems="center" flex="0 0 auto" gap={2.5} mb={2}>
        <Typography color="text.primary">{formatEventDate(event)}</Typography>
        {event.invitee_type && <EventInvitee inviteeType={event.invitee_type} />}
      </Stack>
      <InviteesCriteria criteria={event.criteria} />
    </>
  );
};

type EventInviteeProps = {
  inviteeType: EventsInvite;
};

export const EventInvitee: FC<EventInviteeProps> = ({ inviteeType }) => {
  const inviteeColor = useEventColor({ invitee_type: inviteeType });
  const { $t } = useIntl();

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        '.svg-icon, .MuiTypography-root': {
          color: inviteeColor,
        },
      }}
    >
      <Icon>{inviteeType === EventsInvite.StudentsOnly ? <StudentIcon /> : <ParentIcon />}</Icon>
      <Typography>{$t({ id: getParticipantLabel(inviteeType) })}</Typography>
    </Stack>
  );
};

export const InviteesCriteria: FC<InviteesCellContentProps> = (props) => {
  return (
    <InviteesCellContent
      sx={{
        flex: '0 0 auto',
        mb: 2,
        '& .TagSelect': { bgcolor: 'background.paper' },
        '& .CheckOverflow:not(.CheckOverflow-hidden)': { ml: 1 },
      }}
      {...props}
    />
  );
};
