import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { RecurringState } from '@schooly/api';
import { EyeIcon, RecurringIcon, SimpleButton, SimpleButtonProps } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { useRecurringStateLabel } from './hooks';

type RecurrenceInfoProps = {
  recurringState: RecurringState;
} & SimpleButtonProps;

export const RecurrenceInfo: FC<RecurrenceInfoProps> = ({ recurringState, ...props }) => {
  const { $t } = useIntl();

  const label = useRecurringStateLabel(recurringState);
  return (
    <Tooltip
      title={
        <Stack
          gap={1.25}
          alignItems="flex-start"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Typography>{label}</Typography>
          <SimpleButton
            startIcon={<EyeIcon />}
            sx={(theme) => ({ ...theme.typography.body1 })}
            {...props}
          >
            {$t({ id: 'events-recurring-ViewSet' })}
          </SimpleButton>
        </Stack>
      }
      arrow
      disableFocusListener
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            padding: theme.spacing(1.25),
          }),
        },
      }}
    >
      <IconButton
        inverse
        sx={{ color: 'common.grey2' }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <RecurringIcon />
      </IconButton>
    </Tooltip>
  );
};
