import { Stack, Typography } from '@mui/material';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { TagSelectProperty } from '@schooly/style';
import { FC, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectOptionsArchivedIcon } from '../SelectOptionsArchivedIcon';
import { SelectSearchInput } from '../SelectSearchInput';
import { filterPropertiesByQuery } from '../utils';
import { renderPropertyTypeTags } from './PropertyTypeExpandedSelect';

type PropertyTypeSelectMultipleProps = {
  hideIfNoOptions?: boolean;
  propertyType: SchoolPropertyType;
  userRole: SchoolUserRole;
  schoolId: string;
  selectedIds: string[];
  onSelectId: (v: string) => void;
  optionsTitle?: string;
  isAdmin?: boolean;
  allowArchived?: boolean;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const PropertyTypeSelectMultiple: FC<PropertyTypeSelectMultipleProps> = ({
  hideIfNoOptions,
  optionsTitle,
  schoolId,
  selectedIds,
  propertyType,
  userRole,
  placeholder,
  onSelectId,
  isAdmin,
  allowArchived,
  ...dropdownProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdown = useRef<DropdownSelect | null>(null);
  const [query, setQuery] = useState('');

  const [queryEnabled, setQueryEnabled] = useState(false);

  const { getConfirmation } = useConfirmationDialog();
  const { propertiesMap, activePropertiesMap, archivedPropertiesMap, isLoading, getPropertyById } =
    useSchoolProperties({ schoolId, userType: userRole }, { enabled: queryEnabled });

  const showAllArchivedRightIcon =
    !allowArchived &&
    !selectedIds.length &&
    !activePropertiesMap[propertyType].length &&
    archivedPropertiesMap[propertyType].length > 0;

  const renderAllArchivedIcon = useCallback(
    () => <SelectOptionsArchivedIcon isAdmin={!!isAdmin} type={propertyType} />,
    [isAdmin, propertyType],
  );

  const handleSelectPropertyId = useCallback(
    async (id: string) => {
      const type = getPropertyById(id);

      if (
        !allowArchived &&
        type?.archived &&
        !(await getConfirmation({
          textId: `deselect-property-archived-${propertyType}`,
          textValues: { name: type.name },
        }))
      ) {
        return;
      }
      onSelectId(id);
    },
    [allowArchived, getConfirmation, getPropertyById, onSelectId, propertyType],
  );

  const renderContent = useCallback(() => {
    if (isLoading) return <SelectContentSkeleton />;

    const activeProperties = filterPropertiesByQuery(query, activePropertiesMap[propertyType]);
    const archivedProperties = filterPropertiesByQuery(query, archivedPropertiesMap[propertyType]);

    if (!activeProperties.length && !archivedProperties.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
          {activeProperties.map((property) => (
            <TagSelectProperty
              key={property.id}
              userRole={userRole}
              property={property}
              variant={selectedIds?.includes(property.id) ? 'filled' : undefined}
              onClick={() => {
                onSelectId(property.id);
                setQuery('');
              }}
            />
          ))}
        </Stack>
        {allowArchived && !!archivedProperties.length && (
          <>
            <Typography variant="h4" mt={1} ml={1}>
              <FormattedMessage id="filter-Archived" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
              {archivedProperties.map((property) => (
                <TagSelectProperty
                  key={property.id}
                  userRole={userRole}
                  property={property}
                  variant={selectedIds?.includes(property.id) ? 'filled' : undefined}
                  onClick={() => {
                    onSelectId(property.id);
                    setQuery('');
                  }}
                />
              ))}
            </Stack>
          </>
        )}
      </>
    );
  }, [
    activePropertiesMap,
    allowArchived,
    archivedPropertiesMap,
    isLoading,
    onSelectId,
    propertyType,
    query,
    selectedIds,
    userRole,
  ]);

  if (hideIfNoOptions && !isLoading && !propertiesMap[propertyType].length) return null;

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={placeholder}
      hasValues={selectedIds.length > 0}
      renderContent={renderContent}
      renderRightIcon={showAllArchivedRightIcon ? renderAllArchivedIcon : undefined}
      onClickInputArea={() => inputRef.current?.focus()}
    >
      {(opened) => (
        <>
          {renderPropertyTypeTags({
            ids: selectedIds,
            tagProps: {
              userRole,
              schoolId,
              size: placeholder ? 'small' : undefined,
              sx: { maxWidth: 200 },
            },
            onDelete: opened ? handleSelectPropertyId : undefined,
          })}
          {opened && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={query || ''}
              onChangeText={setQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
