import { SuggestedChange, SyncUser, User, UserRolePermission, UserType } from '@schooly/api';
import { Genders, Languages, SuggestedChangeDataSet } from '@schooly/constants';

import { hasFieldValue } from '../../../components/ui/Input/utils';
import {
  getUserAddress,
  getUserFullNameWithTitle,
  getUserNationalitiesList,
  getUserTelephonesList,
} from '../../../helpers/users';
import formatDate from '../../../utils/formatDate';

export const getSuggestedChangeTypeId = (
  dataSet?: SuggestedChangeDataSet,
  fieldName?: keyof SyncUser,
): string => {
  const dataSetMap: Record<SuggestedChangeDataSet, string> = {
    [SuggestedChangeDataSet.Address]: 'peopleDetail-Address',
    [SuggestedChangeDataSet.Name]: 'people-Name',
    [SuggestedChangeDataSet.Email]: 'people-Email',
    [SuggestedChangeDataSet.Nationality]: 'peopleDetail-Nationality-plural',
    [SuggestedChangeDataSet.Telephones]: 'peopleDetail-PhoneNumber-plural',
  };

  const userFieldMap: Partial<Record<keyof SyncUser, string>> = {
    known_as: 'peopleDetail-PreferredName',
    email: 'people-Email',
    date_of_birth: 'peopleDetail-DateOfBirth',
    gender: 'peopleDetail-Gender',
    language: 'peopleDetail-PrimaryLanguage',
    other_languages_spoken: 'peopleDetail-AdditionalLanguage-plural',
  };

  const defaultValue = '-';

  if (fieldName) {
    return userFieldMap[fieldName] || defaultValue;
  }

  if (dataSet) {
    return dataSetMap[dataSet];
  }

  return defaultValue;
};

const getChangeValueByFieldName = (
  fieldName: keyof SyncUser,
  relatedChanges: SuggestedChange[],
) => {
  const getTextFromEnum = (
    e: {
      [id: number]: string;
    },
    newValue: string,
  ) => {
    if (newValue == null) return undefined;
    return hasFieldValue(Number(newValue)) ? e[Number(newValue)] : undefined;
  };

  const map: Partial<Record<keyof SyncUser, (newValue: string | number[]) => string | undefined>> =
    {
      known_as: (newValue) => newValue as string,
      date_of_birth: (newValue) => formatDate(newValue as string),
      gender: (newValue) => getTextFromEnum(Genders, newValue as string),
      language: (newValue) => getTextFromEnum(Languages, newValue as string),
      email: (newValue) => newValue as string,
      other_languages_spoken: (newValue) => {
        try {
          return (newValue as number[]).map((lang) => Languages[lang]).join(', ');
        } catch (e) {
          return undefined;
        }
      },
    };

  const getValue = map[fieldName] || (() => undefined);

  return getValue(relatedChanges[0].new_value);
};

const getChangeValueByDataSet = (
  dataSet: SuggestedChangeDataSet,
  relatedChanges: SuggestedChange[],
  user: SyncUser,
) => {
  const map: Record<SuggestedChangeDataSet, (u: User) => string | undefined> = {
    [SuggestedChangeDataSet.Email]: (u) => u.email || undefined,
    [SuggestedChangeDataSet.Address]: (u) => getUserAddress(u),
    [SuggestedChangeDataSet.Name]: (u) => getUserFullNameWithTitle(u),
    [SuggestedChangeDataSet.Nationality]: (u) => getUserNationalitiesList(u),
    [SuggestedChangeDataSet.Telephones]: (u) => getUserTelephonesList(u),
  };

  const userCopy = user && JSON.parse(JSON.stringify(user));

  const updatedUser = relatedChanges.reduce<User>((acc, { field, new_value }) => {
    // @ts-ignore
    acc[field] = new_value;
    return acc;
  }, userCopy);

  return map[dataSet](updatedUser);
};

export const getChangeValue = (
  dataSet?: SuggestedChangeDataSet,
  fieldName?: keyof SyncUser,
  relatedChanges?: SuggestedChange[],
  user?: SyncUser,
): string => {
  const defaultValue = '';

  if ((!dataSet && !fieldName) || !relatedChanges?.length || !user) {
    return defaultValue;
  }

  let value;

  if (fieldName) {
    value = getChangeValueByFieldName(fieldName, relatedChanges);
  }

  if (dataSet) {
    value = getChangeValueByDataSet(dataSet, relatedChanges, user);
  }

  return value || defaultValue;
};

export const getUserNotificationTextId = (isCurrentUserUpdated: boolean, value: string) => {
  const action = value ? 'Updated' : 'Deleted';

  let pronoun = '';
  if (isCurrentUserUpdated) {
    pronoun = 'Their';
  }

  return `confirmation-User${action}${pronoun}Profile`;
};

export const getFieldNameBelongsToSet = (
  fieldName: keyof User,
  dataSet: SuggestedChangeDataSet,
) => {
  const map: Record<SuggestedChangeDataSet, (field: string) => boolean> = {
    [SuggestedChangeDataSet.Email]: (f) => ['email'].includes(f),
    [SuggestedChangeDataSet.Name]: (f) => ['title', 'given_name', 'last_name'].includes(f),
    [SuggestedChangeDataSet.Address]: (f) =>
      ['address_line_1', 'address_line_2', 'city', 'region', 'zip_code', 'country'].includes(f),
    [SuggestedChangeDataSet.Nationality]: (f) => ['nationality', 'other_nationalities'].includes(f),
    [SuggestedChangeDataSet.Telephones]: (f) => ['telephone', 'other_telephones'].includes(f),
  };

  return map[dataSet](fieldName);
};

export const getRelatedChanges = (
  suggestedChanges: SuggestedChange[],
  dataSet?: SuggestedChangeDataSet,
  fieldName?: keyof SyncUser,
) => {
  if (fieldName) {
    return suggestedChanges.filter(({ field }) => field === fieldName);
  }
  if (dataSet) {
    return suggestedChanges.filter(({ field }) => getFieldNameBelongsToSet(field, dataSet));
  }
  return [];
};

export const hasPermissionForAcceptChanges = (
  userType: UserType,
  permissions?: UserRolePermission[],
) => {
  switch (userType) {
    case 'student':
      return permissions?.includes('student_manager');
    case 'parent':
      return permissions?.includes('parent_manager');
    case 'staff':
      return permissions?.includes('staff_manager');
    default:
      return false;
  }
};
