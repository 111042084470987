import { IconButton, Stack, styled, Typography } from '@mui/material';

export const AttachmentCardStyled = styled(Stack, {
  shouldForwardProp: (prop) => !(['readOnly'] as PropertyKey[]).includes(prop),
})<{ readOnly?: boolean }>(({ theme, readOnly }) => ({
  position: 'relative',
  width: theme.spacing(9),
  minHeight: theme.spacing(9),
  borderRadius: theme.spacing(0.5),
  boxShadow: `0px 0px 0px 1px ${theme.palette.text.secondary}`,
  padding: theme.spacing(1),
  cursor: 'pointer',

  '&::before': {
    content: "''",
    position: 'absolute',
    top: '-1px',
    right: '-1px',
    borderBottom: `${theme.spacing(2)} solid ${theme.palette.common.white}`,
    borderRight: `${theme.spacing(2)} solid transparent`,
    // todo: remake attachment card corner
    borderBottomLeftRadius: '1px',
    boxShadow: `0px 0px 0px 1px ${theme.palette.text.secondary}`,
    borderImage: `linear-gradient(45deg, white 9.5px, ${theme.palette.text.secondary} 9.5px)`,
  },

  '&::after': {
    content: "''",
    position: 'absolute',
    top: '-2px',
    right: '-2px',
    width: '0px',
    height: '0px',
    borderTop: '19px solid #F8F9FD',
    borderLeft: '19px solid transparent',
  },

  '&:hover': {
    '&::before': {
      display: readOnly ? 'none' : 'flex',
      height: theme.spacing(3),
      border: 'none',
      boxShadow: 'none',
      borderImage: 'none',
    },

    '&::after': {
      display: readOnly ? 'none' : 'block',
      backdropFilter: 'blur(10px)',
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(0.5),
      border: 'none',
    },
  },
}));

export const AttachmentType = styled(Typography)(({ theme }) => ({
  borderRadius: theme.spacing(0.25),
  padding: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  maxHeight: theme.spacing(2),
  backgroundColor: theme.palette.success.main,
  display: 'flex',
  alignItems: 'center',
}));

export const AttachmentName = styled(Typography)(({ theme }) => ({
  wordBreak: 'break-all',
  marginBottom: theme.spacing(1),
}));

export const AttachmentSize = styled(Typography)(({ theme }) => ({
  marginTop: 'auto',
}));

export const AttachmentButtonStyled = styled(IconButton)(({ theme }) => ({
  '&&&': {
    width: 110,
  },

  '&:hover svg': {
    color: theme.palette.text.primary,
  },
  '&:focus svg': {
    color: theme.palette.text.primary,
  },

  '& svg': {
    width: theme.spacing(13),
  },
}));

export const AttachmentLoaderWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(9),
  minHeight: theme.spacing(9),
}));
