import { GridApiPro } from '@mui/x-data-grid-pro';
import { AssessmentForGroup } from '@schooly/api';
import { ScrollArrows } from '@schooly/components/data-grid';
import React, { FC, useCallback } from 'react';

interface HeaderCellPosition {
  offsetLeft: number;
  width: number;
}

export interface AssessmentMarkbookScrollArrowsProps {
  apiRef: React.MutableRefObject<GridApiPro>;
  assessments: AssessmentForGroup[];
  colWidth: number;
  containerRef: React.RefObject<HTMLDivElement>;
  pinnedColumnsWidth: number;
}

export const AssessmentMarkbookScrollArrows: FC<AssessmentMarkbookScrollArrowsProps> = ({
  apiRef,
  assessments,
  colWidth,
  containerRef,
  pinnedColumnsWidth,
}) => {
  const headerCells = assessments.reduce((acc: HeaderCellPosition[], assessment) => {
    const offsetLeft =
      acc.length > 0 ? acc[acc.length - 1].offsetLeft + acc[acc.length - 1].width : 0;
    const width = colWidth * assessment.methods.length;
    return [...acc, { offsetLeft, width }];
  }, []);

  const showLeftArrow = useCallback(() => {
    return (apiRef?.current.getScrollPosition().left ?? 0) > 0;
  }, [apiRef]);

  const showRightArrow = useCallback(() => {
    const leftPosition = apiRef.current.getScrollPosition().left ?? 0;

    const scrollWidth = headerCells.reduce((sum, cell) => sum + cell.width, 0);
    const containerWidth = containerRef.current?.offsetWidth ?? 0;
    const widthOfVisibleArea = containerWidth - pinnedColumnsWidth;
    const leftMaxPosition = scrollWidth - widthOfVisibleArea;

    return leftMaxPosition > leftPosition * 1.01;
  }, [apiRef, containerRef, headerCells, pinnedColumnsWidth]);

  const handleArrowClick = (direction: 'left' | 'right') => {
    return () => {
      const leftPosition = apiRef.current.getScrollPosition().left ?? 0;

      let scrollTo;

      // define an item we'll be scrolling to, depending on the direction
      if (direction === 'right') {
        scrollTo = headerCells.find((cell) => {
          return cell.offsetLeft > Math.ceil(leftPosition);
        });
      } else {
        if (headerCells.length > 0) {
          for (let i = headerCells.length - 1; i >= 0; i--) {
            const cell = headerCells[i];
            if (cell.offsetLeft < Math.floor(leftPosition)) {
              scrollTo = cell;
              break;
            }
          }
        }
      }

      // do scroll
      if (scrollTo) {
        apiRef.current.scroll({
          left: scrollTo.offsetLeft,
        });
      }
    };
  };

  return (
    <ScrollArrows
      onArrowClick={handleArrowClick}
      shouldShowRightArrow={showRightArrow}
      shouldShowLeftArrow={showLeftArrow}
      apiRef={apiRef}
    />
  );
};
