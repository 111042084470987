import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalContent } from '../../../../components/uikit-components/Modal/Modal.styled';
import { useProfile } from '../../../../context/profile/useProfile';
import PreferNameProperty from '../../ProfileModalHeader/PreferNameProperty';
import AddressProperty from '../../properties/AddressProperty';
import { ContactEmailProperty } from '../../properties/ContactEmailProperty';
import DateProperty from '../../properties/DateProperty';
import EmailProperty from '../../properties/EmailProperty';
import GenderProperty from '../../properties/GenderProperty';
import LanguageProperty from '../../properties/LanguageProperty';
import MedicalProperty from '../../properties/MedicalProperty';
import MembershipNumberProperty from '../../properties/MembershipNumberProperty';
import NationalityProperty from '../../properties/NationalityProperty';
import PhoneNumberProperty from '../../properties/PhoneNumberProperty';
import { ProfileEmailProperty } from '../../properties/ProfileEmailProperty';

import './ProfileModalIndividualAbout.scss';

export const ProfileModalIndividualAbout: FC = () => {
  const { user, userType, isSchoolUser, canEditProfile } = useProfile();

  const canRenderAddPhoneNumberButton =
    !user?.other_telephones || (canEditProfile && user?.other_telephones.length < 3);

  const isStudentOrChild = userType === 'student' || userType === 'child';

  return (
    <ModalContent className="profile-left-side" sx={{ pt: 4 }} active>
      <Typography variant="h2" mb={1}>
        <FormattedMessage id="profile-About" />
      </Typography>

      <Box>
        {isStudentOrChild && <PreferNameProperty />}
        <DateProperty />
        <GenderProperty />
        <NationalityProperty />
        <LanguageProperty value={user?.language} />

        {typeof user?.language === 'number' && (
          <LanguageProperty value={user.other_languages_spoken} multiple />
        )}
      </Box>

      {isSchoolUser && (
        <Box className="school-info">
          <Typography variant="h4" className="sub-title">
            <FormattedMessage id="peopleDetail-SchoolInformation" />
          </Typography>
          <MembershipNumberProperty />
        </Box>
      )}

      {userType === 'profile' && (
        <Box>
          <Typography variant="h4" className="sub-title">
            <FormattedMessage id="peopleDetail-EmailAddressPersonal" />
          </Typography>
          <ProfileEmailProperty />
        </Box>
      )}

      <Box>
        <Typography variant="h4" className="sub-title">
          {isStudentOrChild ? (
            <FormattedMessage id="peopleDetail-StudentContactInformation" />
          ) : (
            <FormattedMessage id="peopleDetail-ContactInformation" />
          )}
        </Typography>

        {userType === 'profile' ? <ContactEmailProperty /> : <EmailProperty />}
        <PhoneNumberProperty value={user?.telephone} />

        {user?.other_telephones?.map((telephone) => (
          <PhoneNumberProperty key={telephone} value={telephone} hideSuggestedChanges />
        ))}

        {user?.telephone && canRenderAddPhoneNumberButton && (
          <PhoneNumberProperty hideSuggestedChanges />
        )}

        {userType !== 'student' && userType !== 'child' && <AddressProperty />}
      </Box>

      {userType === 'student' && (
        <Box>
          <h4 className="sub-title">
            <FormattedMessage id="peopleDetail-MedicalNeeds" />
          </h4>
          <MedicalProperty />
        </Box>
      )}
    </ModalContent>
  );
};
