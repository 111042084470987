import { FilterSelectOption, SchoolProperty } from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';

export const getPropertiesByType = (
  properties: SchoolProperty[],
  type: SchoolPropertyType,
): SchoolProperty[] => properties.filter((p) => p.type === type);

export function getOptionsForPropertyType(
  properties: SchoolProperty[],
  type: SchoolPropertyType,
): FilterSelectOption<string>[] {
  return getPropertiesByType(properties, type).map((p) => ({
    value: p.id,
    label: p.name,
    archived: p.archived,
    type: p.type,
  }));
}

export const getSchoolUserId = (user: { user_id?: string; relation_id?: string; id?: string }) => {
  return 'relation_id' in user ? user.relation_id : user.user_id ?? user.id;
};

export const convertSchoolPropertyPluralToSingular = (plural: string) => {
  switch (plural) {
    case 'statuses':
      return 'status';
    case 'departments':
      return 'department';
    case 'age_groups':
      return 'age_group';
    case 'subjects':
      return 'subject';
    case 'campuses':
      return 'campus';
    case 'houses':
      return 'house';
    case 'genders':
      return 'gender';
    case 'nationalities':
      return 'nationality';
    default:
      return plural;
  }
};

export const convertSchoolPropertySingularToPlural = (singular: string) => {
  switch (singular) {
    case 'status':
      return 'statuses';
    case 'department':
      return 'departments';
    case 'age_group':
      return 'age_groups';
    case 'subject':
      return 'subjects';
    case 'campus':
      return 'campuses';
    case 'hous':
      return 'hauses';
    case 'gender':
      return 'genders';
    case 'nationality':
      return 'nationalities';
    default:
      return singular;
  }
};
