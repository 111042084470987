import {
  Box,
  Icon,
  Stack,
  Switch,
  TableHeadProps,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { PaymentFrequency, PaymentFrequencyType } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { DELAY_BEFORE_HIDE_ICON_DONE } from '@schooly/constants';
import { DoneIcon, GridCell, GridHead, Spin, TypographyWithOverflowHint } from '@schooly/style';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useFrequencyDateLabels } from './hooks';
import { canToggleFrequency, getCyclesForFrequencyCount } from './utils';

export const FrequenciesHeader: FC<TableHeadProps> = ({ ...rest }) => {
  const { $t } = useIntl();

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width={146}>{$t({ id: 'frequencies-Frequency' })}</GridCell>
      <GridCell width={100}>{$t({ id: 'frequencies-CyclesPerYear' })}</GridCell>
      <GridCell
        width={255}
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 160,
          },
        })}
      >
        {$t({ id: 'frequencies-GenerationDate' })}
      </GridCell>
      <GridCell
        width={255}
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 160,
          },
        })}
      >
        {$t({ id: 'frequencies-InvoiceDate' })}
      </GridCell>
      <GridCell
        width={170}
        sx={(theme) => ({
          [theme.breakpoints.down('xl')]: {
            width: 100,
          },
        })}
      >
        {$t({ id: 'frequencies-DueDate' })}
        <Typography variant="caption">{` (${$t({
          id: 'frequencies-AfterGenerationDate',
        }).toLowerCase()})`}</Typography>
      </GridCell>
      <GridCell width={50}>{$t({ id: 'frequencies-InUse' })}</GridCell>
    </GridHead>
  );
};

interface FrequencyRowProps {
  frequency: PaymentFrequency;
  canEdit: boolean;
  onToggleSwitch: (frequency: PaymentFrequency) => Promise<void | PaymentFrequency>;
  updating: boolean;
}

export const FrequencyRow: FC<FrequencyRowProps> = ({
  frequency,
  canEdit,
  onToggleSwitch,
  updating,
}) => {
  const { $t } = useIntl();
  const { showNotification } = useNotifications();
  const cyclesPerYear = getCyclesForFrequencyCount(frequency);
  const { generationDateLabel, invoiceDateLabel, dueDateLabel } = useFrequencyDateLabels(frequency);
  const { type, in_use, has_products } = frequency;

  //TR-6100. Temporary behavior. In future switch should open frequency edit modal
  const canToggle = canEdit && !updating && canToggleFrequency(frequency);

  const [showLoader, setShowLoader] = useState(false);
  const [showDoneIcon, setShowDoneIcon] = useState(false);

  const handleToggleSwitch = useCallback(
    async (inUse: boolean) => {
      setShowLoader(true);

      const res = await onToggleSwitch({ ...frequency, in_use: inUse });

      if (res) {
        setShowDoneIcon(true);
        showNotification({
          textId: inUse ? 'frequencies-TurnOnSuccess' : 'frequencies-TurnOffSuccess',
          type: 'success',
        });
        setTimeout(() => setShowDoneIcon(false), DELAY_BEFORE_HIDE_ICON_DONE);
      }
      setShowLoader(false);
    },
    [frequency, onToggleSwitch, showNotification],
  );

  const tooltip = useMemo(() => {
    if (type === PaymentFrequencyType.OneOff) {
      return $t({ id: 'frequencies-CannotTurnOff-OneOff' });
    }
    if (has_products) {
      return (
        <Stack gap={2}>
          <Typography>{$t({ id: 'frequencies-CannotTurnOff' })}</Typography>
          <Typography>{$t({ id: 'frequencies-RemoveFrequencyFromProducts' })}</Typography>
        </Stack>
      );
    }
  }, [$t, has_products, type]);

  const inUseCellContent = useMemo(() => {
    if (showLoader && updating) {
      return (
        <Icon>
          <Spin />
        </Icon>
      );
    }

    if (showDoneIcon) {
      return (
        <Icon>
          <DoneIcon />
        </Icon>
      );
    }

    return (
      <Tooltip title={tooltip}>
        <Box>
          <Switch
            checked={in_use}
            onChange={(e) => handleToggleSwitch(e.target.checked)}
            disabled={!canToggle}
          />
        </Box>
      </Tooltip>
    );
  }, [canToggle, handleToggleSwitch, in_use, showDoneIcon, showLoader, tooltip, updating]);

  return (
    <TableRow
      sx={{
        '.editIcon': {
          visibility: 'hidden',
        },
        '&:hover': {
          ' td.MuiTableCell-root': {
            backgroundColor: 'background.default',
            '.MuiTypography-root': {
              color: in_use ? 'primary.main' : 'text.primary',
            },
            '.editIcon': {
              visibility: 'visible',
            },
          },
        },
      }}
    >
      <GridCell>
        <TypographyWithOverflowHint variant="h3" color={in_use ? 'text.primary' : 'text.secondary'}>
          {$t({ id: `frequencies-${type}` })}
        </TypographyWithOverflowHint>
      </GridCell>

      <GridCell>
        {in_use && (
          <Typography variant="h3" color="text.primary">
            {cyclesPerYear || '-'}
          </Typography>
        )}
      </GridCell>

      <GridCell>
        {in_use && (
          <TypographyWithOverflowHint>
            <Typography display="inline" variant="h3" color="text.primary">
              {generationDateLabel}
            </Typography>
          </TypographyWithOverflowHint>
        )}
      </GridCell>

      <GridCell>
        {in_use && (
          <TypographyWithOverflowHint>
            <Typography display="inline" variant="h3" color="text.primary">
              {invoiceDateLabel}
            </Typography>
          </TypographyWithOverflowHint>
        )}
      </GridCell>

      <GridCell>
        {in_use && (
          <TypographyWithOverflowHint variant="h3" color="text.primary">
            {dueDateLabel}
          </TypographyWithOverflowHint>
        )}
      </GridCell>

      <GridCell>
        <Stack alignItems="center">{inUseCellContent}</Stack>
      </GridCell>
    </TableRow>
  );
};
