import { Report } from '@schooly/api';
import { FC } from 'react';

import { $UIKitPrefix } from '../../../styles/variables';
import { ListViewReportsRow } from '../ListViewReportRow/ListViewReportsRow';

import './ListViewReports.scss';

const $class = `${$UIKitPrefix}ListViewReports`;

export interface ListViewReportsProps {
  reports: Report[];
  id: string;
}

export const ListViewReports: FC<ListViewReportsProps> = ({ reports, id }) => {
  return (
    <div className={$class}>
      {reports.map((report) => (
        <ListViewReportsRow key={report.id} relationId={id} report={report} />
      ))}
    </div>
  );
};
