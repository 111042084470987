import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AddGroupModalSection } from '../../pages/Groups/AddGroupModal/AddGroupModal';
import { AddGroupFormData } from '../../pages/Groups/AddGroupModal/schema';

export interface IGroupStateState {
  section?: AddGroupModalSection;
  formData?: Partial<AddGroupFormData>;
}

const initialState: IGroupStateState = {
  section: undefined,
  formData: undefined,
};

const slice = createSlice({
  name: 'groupState',
  initialState,
  reducers: {
    setSection: (state, action: PayloadAction<AddGroupModalSection | undefined>) => {
      state.section = action.payload;
    },
    setFormData: (state, action: PayloadAction<AddGroupFormData | undefined>) => {
      return { ...state, formData: { ...state.formData, ...action.payload } };
    },
    resetFormData: (state, action: PayloadAction<AddGroupFormData | undefined>) => {
      return { ...state, formData: undefined };
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice;
