import { Box, BoxProps, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ChevronLeftIcon, Counter, randomInt } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';

type ArrangedByCollapsableSectionProps = PropsWithChildren<{
  isExpanded?: boolean;
  title: string;
  count: number;
  canExpand?: boolean;
}> &
  BoxProps;

export const ArrangedByCollapsableSection: FC<ArrangedByCollapsableSectionProps> = ({
  title,
  count,
  children,
  isExpanded: isInitiallyExpanded,
  canExpand: isInitiallyCanExpand,
  ...props
}) => {
  const canExpand = Boolean(count);

  const [expanded, setExpanded] = useState(canExpand ? isInitiallyExpanded : false);

  const isExpanded = canExpand ? expanded : false;

  const toggleExpanded = useCallback(() => {
    if (isInitiallyCanExpand) return;
    setExpanded((e) => !e);
  }, [isInitiallyCanExpand]);

  return (
    <Stack
      position="relative"
      sx={{
        ':hover': {
          '.expandIcon': {
            path: {
              color: 'primary.main',
            },
          },
        },
      }}
    >
      <Box
        onClick={toggleExpanded}
        pt={2}
        position={isExpanded ? 'sticky' : undefined}
        top={isExpanded ? 0 : undefined}
        bgcolor="white"
        width="100%"
        alignItems="center"
        display="flex"
        zIndex={4}
        pb={1}
        mb={0}
        pr={5}
        sx={{
          cursor: 'pointer',
        }}
        {...props}
      >
        <Typography
          variant="h2"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title} <Counter>{count}</Counter>
        </Typography>

        {Boolean(count && !isInitiallyCanExpand) && (
          <Box position="absolute" right={1}>
            <IconButton
              className="expandIcon"
              size="medium"
              disabled={!isExpanded}
              sx={{ transform: isExpanded ? 'rotate(90deg)' : 'rotate(-90deg)' }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      {isExpanded && children}
      <Divider sx={{ mt: 1, borderColor: isExpanded ? 'transparent' : undefined }} />
    </Stack>
  );
};

export const SectionSkeleton: FC = () => {
  const width = useRef(randomInt(20, 50)).current;

  return (
    <Stack position="relative">
      <Stack pt={2} bgcolor="white" pb={1} pr={5}>
        <Typography variant="h2">
          <Skeleton variant="text" width={`${width}%`} />
        </Typography>

        <Box position="absolute" right={1}>
          <IconButton size="small" disabled sx={{ transform: 'rotate(-90deg)' }}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1 }} />
    </Stack>
  );
};

export const ArrangedByCollapsableSectionSkeleton: FC = () => (
  <>
    {[...new Array(8)].map((_, i) => (
      <SectionSkeleton key={i} />
    ))}
  </>
);
