import { ChipProps } from '@mui/material';
import { FilterKeys, useGetEventsQuery } from '@schooly/api';
import { FC } from 'react';

import { RecurrenceLabelSelect } from './RecurrenceSelectLabel';

export type RecurrenceLabelSelectProps = {
  id: string;
  schoolId: string;
  title: string;
} & Omit<ChipProps, 'label'>;

export const EventRecurrenceLabelSelect: FC<RecurrenceLabelSelectProps> = ({
  id,
  schoolId,
  title,
}) => {
  const { data } = useGetEventsQuery(
    {
      schoolId,
      query: '',
      filters: { [FilterKeys.RecurrenceId]: [id] },
    },
    { enabled: Boolean(!title && id) },
  );

  const firstEvent = data?.pages?.[0]?.results?.[0];
  const selectedOptionTitle = title || firstEvent?.recurring_state?.recurrence_name;

  return <RecurrenceLabelSelect title={selectedOptionTitle} />;
};
