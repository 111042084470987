import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { EditIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../../components/uikit/Counter/Counter.styled';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../../components/uikit-components/Grid/Grid';
import { SchoolTuneAgeGroupsEmptyStub } from './SchoolTuneAgeGroupsEmptyStub';

export const SchoolTuneAgeGroups: FC = () => {
  const { schoolId = '' } = useAuth();

  const { ageGroups } = useAgeGroups(
    {
      schoolId: schoolId,
      userType: SchoolUserRole.Student,
    },
    { enabled: !!schoolId },
  );

  if (!ageGroups?.length) {
    return <SchoolTuneAgeGroupsEmptyStub />;
  }

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="school-tabs-AgeGroups" />
          </Typography>
          {!!ageGroups.length && <Counter>{ageGroups.length}</Counter>}
        </Stack>

        <Link to="age_groups">
          <IconButton inverse>
            <EditIcon />
          </IconButton>
        </Link>
      </Stack>

      <Box sx={{ overflow: 'auto' }}>
        <GridContainer>
          {ageGroups.map((ageGroup) => (
            <GridRowStyled key={ageGroup.id}>
              <GridRowItem>
                <GridRowName>{ageGroup.name}</GridRowName>
              </GridRowItem>
            </GridRowStyled>
          ))}
        </GridContainer>
      </Box>
    </Stack>
  );
};
