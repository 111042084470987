import { Icon, IconButton, Stack, Switch, Typography } from '@mui/material';
import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import { EditIcon, PersonalMailIcon, Spin } from '@schooly/style';
import React, { ChangeEvent, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useProfile } from '../../../context/profile/useProfile';
import EmailModal from './EmailModal';

export const ProfileEmailProperty: FC = () => {
  const [editModalOpened, openEditModal, closeEditModal] = useFlag();
  const {
    userType = 'profile',
    user,
    isUpdating,
    actions: { updateProfile },
  } = useProfile();

  const { $t } = useIntl();
  const { showError } = useNotifications();

  const onToggleUseAsContactEmail = useCallback(
    async (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      try {
        await updateProfile({ use_account_email_as_contact: checked });
      } catch (e) {
        showError(e as ApiError);
      }
    },
    [showError, updateProfile],
  );
  if (userType !== 'profile') return null;

  const email = user?.account_email || '';

  return (
    <>
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          padding: theme.spacing(1.5, 1),
          backgroundColor: 'transparent',
          transition: 'all .3s',
          '.mail-icon': {
            color: theme.palette.common.grey,
          },
          '&:hover': {
            '.mail-icon': {
              color: theme.palette.primary.main,
            },
            '.edit-icon': {
              opacity: 1,
            },
            backgroundColor: theme.palette.background.default,
          },
        })}
      >
        <Icon className="mail-icon">
          <PersonalMailIcon />
        </Icon>
        <Typography flex={1} variant="h3" color="text.primary">
          {email}
        </Typography>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography color="common.grey">
            {$t({ id: 'peopleDetail-AlsoUseAsContactEmail' })}
          </Typography>
          <Switch
            checked={!!user?.use_account_email_as_contact}
            onChange={onToggleUseAsContactEmail}
            disabled={isUpdating}
          />
        </Stack>
        <IconButton
          inverse={isUpdating}
          disabled={isUpdating}
          className="edit-icon"
          sx={{ opacity: 0, width: 20 }}
          onClick={openEditModal}
        >
          {isUpdating ? <Spin /> : <EditIcon />}
        </IconButton>
      </Stack>
      <EmailModal currentUserEmail={email} isOpen={editModalOpened} onClose={closeEditModal} />
    </>
  );
};
