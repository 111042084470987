import { Box, Button, Stack } from '@mui/material';
import React, { ReactNode, useMemo } from 'react';

import { ArrowLeftIcon, ArrowRightIcon } from '../ui/Icons';

type ModalNavigationProps<T> = {
  selectedItem: T;
  items: Array<T>;
  onClick: (i: T) => void;
  getItemLabel: (i: T) => ReactNode;
};

export const ModalNavigation = <T,>({
  items,
  selectedItem,
  onClick,
  getItemLabel,
}: ModalNavigationProps<T>) => {
  const { leftItem, rightItem } = useMemo(() => {
    const idx = items.indexOf(selectedItem);
    if (idx < 0) return { leftItem: null, rightItem: null };

    const leftItem = items[idx - 1] ?? null;
    const rightItem = items[idx + 1] ?? null;

    return { leftItem, rightItem };
  }, [items, selectedItem]);

  if (items.length <= 1) return null;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={(theme) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1.25, 2.5),
      })}
    >
      <Box>
        {leftItem && (
          <Button
            variant="outlined"
            startIcon={<ArrowLeftIcon />}
            onClick={() => onClick(leftItem)}
          >
            {getItemLabel(leftItem)}
          </Button>
        )}
      </Box>
      <Box>
        {rightItem && (
          <Button
            variant="outlined"
            endIcon={<ArrowRightIcon />}
            onClick={() => onClick(rightItem)}
          >
            {getItemLabel(rightItem)}
          </Button>
        )}
      </Box>
    </Stack>
  );
};
