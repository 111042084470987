import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="0.8s"
        from="0"
        to="360"
        repeatCount="indefinite"
      />
      <g clip-path="url(#clip0_14030_30702)">
        <circle
          cx="10.0228"
          cy="10.0227"
          r="9"
          transform="rotate(-165 10.0228 10.0227)"
          stroke="#F4F4F9"
        />
        <path
          d="M15.3199 17.299C16.9281 16.1297 18.1612 14.4224 18.7159 12.3521C20.0024 7.55089 17.1532 2.61586 12.352 1.32938C7.55078 0.0429047 2.61575 2.89215 1.32927 7.69334C0.774544 9.76361 0.988779 11.8588 1.7969 13.6755"
          stroke={props.color ?? '#24275B'}
        />
      </g>
      <defs>
        <clipPath id="clip0_14030_30702">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
