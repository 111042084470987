import { ApiError } from '@schooly/api';
import { useCallback, useState } from 'react';

import IntlError from '../utils/intlError';

export default function useRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | ApiError>();

  const requestWrapper = useCallback(
    <T>(request: () => Promise<T>) =>
      async () => {
        setError(null);
        setIsLoading(true);

        let response;

        try {
          setIsLoading(true);
          response = await request();
        } catch (err) {
          setError(err as ApiError | IntlError);
        } finally {
          setIsLoading(false);
        }
        return response;
      },
    [],
  );

  return { isLoading, error, requestWrapper };
}
