const RE_NOT_LETTER = /[^\w]+/;

export default function searchWords(haystack: string, needle: string) {
  if (!haystack || !needle) {
    return false;
  }

  const haystackWords = haystack.toLowerCase().split(RE_NOT_LETTER);
  const needleWords = needle.toLowerCase().split(RE_NOT_LETTER).filter(Boolean);

  // TODO: Support non-latin characters
  if (!needleWords.length) {
    return false;
  }

  return needleWords.every((needleWord) =>
    haystackWords.some((haystackWord) => haystackWord.indexOf(needleWord) >= 0),
  );
}
