import { Box, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, Event, SignUp, SignUpStatuses } from '@schooly/api';
import { DATE_FORMAT, DATE_FORMAT_SHORT } from '@schooly/constants';
import {
  GridRowCell,
  GridRowDate,
  GridRowItem,
  GridRowStyled,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getMinutesDurationLabel } from '@schooly/utils/date';
import { format, getYear, parse } from 'date-fns';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { SignUpTypeLabels, WithSignUp } from '../../../context/signUps/WithSignUp';
import { EventPreviewSignUpsStatus } from './EventPreviewSignUpsStatus';

export interface EventPreviewSignUpsProps {
  event: Event;
  titleWidth?: string;
  showHeaderTitle?: boolean;
}

export const EventPreviewSignUps: FC<EventPreviewSignUpsProps> = ({
  event,
  titleWidth = '240px',
  showHeaderTitle = true,
}) => {
  if (!event.sign_ups?.length) {
    return null;
  }

  return (
    <Stack gap={2} pb={1}>
      {showHeaderTitle && (
        <Typography variant="h2">
          <FormattedMessage id="events-header-SignUp-Title" />
        </Typography>
      )}

      <Box>
        {event.sign_ups?.map((signUp) => {
          const date = parse(signUp.end, DEFAULT_DATE_FORMAT_FNS, new Date());
          const formattedDate = format(
            date,
            getYear(date) === getYear(new Date()) ? DATE_FORMAT_SHORT : DATE_FORMAT,
          );

          return (
            <WithSignUp key={signUp.id} signUp={{ ...signUp, event } as SignUp}>
              <GridRowStyled>
                <Link to={`/signups/${signUp.id}`}>
                  <GridRowItem sx={{ gap: 2, py: 1, px: 2 }}>
                    <GridRowDate flexBasis={120}>
                      <FormattedMessage
                        id={
                          signUp.status === SignUpStatuses.Closed
                            ? 'events-header-SignUp-ClosedOnDate'
                            : 'events-header-SignUp-ClosesOnDate'
                        }
                        values={{
                          date: formattedDate,
                        }}
                      />
                    </GridRowDate>
                    <GridRowCell
                      flex={`0 0 ${titleWidth}`}
                      component={TypographyWithOverflowHint}
                      noWrap
                    >
                      {signUp.title}
                    </GridRowCell>
                    <GridRowCell flex="0 0 80px" variant="body1">
                      <FormattedMessage id={SignUpTypeLabels[signUp.type]} />
                    </GridRowCell>
                    <GridRowCell flex="1 1 100%" variant="body1">
                      {signUp.duration ? getMinutesDurationLabel(signUp.duration) : signUp.places}
                    </GridRowCell>
                    <GridRowCell>
                      <EventPreviewSignUpsStatus event={event} signUp={signUp as SignUp} />
                    </GridRowCell>
                  </GridRowItem>
                </Link>
              </GridRowStyled>
            </WithSignUp>
          );
        })}
      </Box>
    </Stack>
  );
};
