import { Components, Theme } from '@mui/material';

export const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: 30,
      height: 20,
      padding: 0,

      '.MuiFormControlLabel-labelPlacementTop &': {
        marginTop: theme.spacing(),
      },

      '.MuiFormControlLabel-labelPlacementEnd &': {
        marginRight: theme.spacing(),
      },

      '.MuiFormControlLabel-labelPlacementBottom &': {
        marginBottom: theme.spacing(),
      },

      '.MuiFormControlLabel-labelPlacementStart &': {
        marginLeft: theme.spacing(),
      },
    }),
    track: ({ theme }) => ({
      backgroundColor: 'unset',
      border: theme.mixins.borderControlValue(),
      borderRadius: 10,
    }),
    thumb: ({ theme }) => ({
      width: 10,
      height: 10,
      boxShadow: 'unset',
      backgroundColor: theme.palette.text.primary,
      borderRadius: '50%',
    }),
    switchBase: () => ({
      padding: 5,

      '&.Mui-checked': {
        transform: 'translateX(10px)',

        '& + .MuiSwitch-track': {
          backgroundColor: 'unset',
        },
      },
    }),
  },
};
