import { Box, Stack, StackProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FC } from 'react';

type BulkActionsBottomBarProps = StackProps & { sidebarWidth: number };
export const BulkActionsBottomBar: FC<BulkActionsBottomBarProps> = ({
  sx,
  sidebarWidth,
  children,
  ...stackProps
}) => {
  return (
    <>
      <Box sx={(theme) => ({ height: theme.spacing(7.5) })} />
      <Stack
        sx={(theme) => ({
          position: 'fixed',
          background: theme.palette.background.paper,
          bottom: 0,
          left: sidebarWidth,
          right: 0,
          boxShadow: '0px -10px 20px 0px rgba(37, 39, 90, 0.05)',
          ...((typeof sx === 'function' ? sx(theme) : sx) as SystemStyleObject),
        })}
        {...stackProps}
      >
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            alignItems: 'center',
            margin: theme.spacing(0, 'auto'),
            maxWidth: 1440,
            width: '100%',
            height: theme.spacing(7.5),
            padding: theme.spacing(0, 3, 0, 4.75),
          })}
        >
          {children}
        </Stack>
      </Stack>
    </>
  );
};
