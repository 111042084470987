import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.75C7.10051 3.75 4.75 6.10051 4.75 9C4.75 10.1302 5.10742 11.1768 5.71506 12.0335H3.96767C3.5084 11.1214 3.25 10.0908 3.25 9C3.25 5.27208 6.27208 2.25 10 2.25C13.7279 2.25 16.75 5.27208 16.75 9C16.75 12.5692 13.9799 15.4913 10.4723 15.7337C10.4721 15.7367 10.4718 15.7396 10.4716 15.7426L12.0183 17.2959C12.3105 17.5894 12.3095 18.0643 12.016 18.3566C11.7225 18.6488 11.2476 18.6478 10.9553 18.3543L7.96179 15.3478C7.66953 15.0543 7.67055 14.5794 7.96407 14.2872L10.9705 11.2936C11.2641 11.0014 11.7389 11.0024 12.0312 11.2959C12.3235 11.5894 12.3224 12.0643 12.0289 12.3566L10.1371 14.2402L10.1372 14.2404C10.1384 14.243 10.1396 14.2456 10.1408 14.2481C12.9752 14.1735 15.25 11.8524 15.25 9C15.25 6.10051 12.8995 3.75 10 3.75Z"
      fill="#B3BBCB"
    />
  </svg>
);
