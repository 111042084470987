import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 3H16C16.5523 3 17 3.44772 17 4V15C17 15.5523 16.5523 16 16 16H13.5C12.9477 16 12.5 15.5523 12.5 15V7.5H5C4.44772 7.5 4 7.05228 4 6.5V4C4 3.44772 4.44772 3 5 3Z"
      fill="#24275B"
    />
    <path d="M7.78502 8.5L3 13.285V17H6.99924L11.5 12.4993V8.50005L7.78502 8.5Z" fill="#24275B" />
  </svg>
);
