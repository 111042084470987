import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#B3BBCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.527 10.8304C18.9085 10.4697 19 10.1887 19 10C19 9.81128 18.9085 9.53032 18.527 9.16957C18.1435 8.80683 17.5348 8.43409 16.6997 8.10005C15.035 7.43419 12.6668 7 10 7C7.33323 7 4.96497 7.43419 3.30032 8.10005C2.46523 8.43409 1.85653 8.80683 1.47296 9.16957C1.0915 9.53032 1 9.81128 1 10C1 10.1887 1.0915 10.4697 1.47296 10.8304C1.85653 11.1932 2.46523 11.5659 3.30032 11.9C4.96497 12.5658 7.33323 13 10 13C12.6668 13 15.035 12.5658 16.6997 11.9C17.5348 11.5659 18.1435 11.1932 18.527 10.8304ZM10 14C15.5228 14 20 12.2091 20 10C20 7.79086 15.5228 6 10 6C4.47715 6 0 7.79086 0 10C0 12.2091 4.47715 14 10 14Z"
      fill="#B3BBCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8304 1.47296C10.4697 1.0915 10.1887 1 10 1C9.81128 1 9.53032 1.0915 9.16957 1.47296C8.80683 1.85653 8.43409 2.46523 8.10005 3.30032C7.43419 4.96497 7 7.33323 7 10C7 12.6668 7.43419 15.035 8.10005 16.6997C8.43409 17.5348 8.80683 18.1435 9.16957 18.527C9.53032 18.9085 9.81128 19 10 19C10.1887 19 10.4697 18.9085 10.8304 18.527C11.1932 18.1435 11.5659 17.5348 11.9 16.6997C12.5658 15.035 13 12.6668 13 10C13 7.33323 12.5658 4.96497 11.8999 3.30032C11.5659 2.46523 11.1932 1.85653 10.8304 1.47296ZM14 10C14 4.47715 12.2091 -2.7141e-07 10 -1.74846e-07C7.79086 -7.8281e-08 6 4.47715 6 10C6 15.5228 7.79086 20 10 20C12.2091 20 14 15.5228 14 10Z"
      fill="#B3BBCB"
    />
  </svg>
);
