import { Button, IconButton, Stack, Typography } from '@mui/material';
import { IContextAction, StringOrNull } from '@schooly/api';
import {
  CreateChildForm,
  CreateParentForm,
  PersonActionsSelect,
  RemovePersonConfirmDialog,
} from '@schooly/components/applications';
import { CreateCustomField } from '@schooly/components/applications';
import {
  ArrowRightIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  MinusIcon,
  ModalFooter,
  Spin,
  StarIcon,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { CrossIcon } from '../../../components/ui/Icons';
import { ModalMain, ModalPanel } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalConfirmationDialog } from '../../../components/uikit-components/Modal/ModalConfirmationDialog';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import useFlag from '../../../hooks/useFlag';
import { AddFamilyMemberButton } from './AddFamilyMemberButton';
import { AddParentContextMenu } from './AddParentContextMenu';
import { ApplicationCreatePersonCard } from './ApplicationCreatePersonCard';

interface ApplicationCreateContentProps {
  children: CreateChildForm[];
  parents: CreateParentForm[];
  generalCustomFields: CreateCustomField[];
  onChildClick: (id?: string) => void;
  onParentRemove: (id: string) => void;
  onChildRemove: (id: string) => void;
  onParentEdit: ({ profileId, id }: { profileId: StringOrNull; id: string }) => void;
  saving?: boolean;
  parentActions: IContextAction[];
  onSubmit?: () => void;
  resetApplication: () => void;
  onOpenPreview?: () => void;
  updateParents: (parents: CreateParentForm[]) => void;
  onContinue?: () => void;
}

export const ApplicationCreateModalContent: FC<ApplicationCreateContentProps> = ({
  children,
  parents,
  generalCustomFields,
  onChildClick,
  saving,
  parentActions,
  onParentEdit,
  onParentRemove,
  onChildRemove,
  onSubmit,
  onOpenPreview,
  resetApplication,
  updateParents,
  onContinue,
}) => {
  const { $t } = useIntl();

  const [personToRemove, setPersonToRemove] =
    useState<{ form: CreateChildForm | CreateParentForm; type: 'Child' | 'Parent' }>();
  const [isConfirmModalOpen, showConfirmModal, hideConfirmModal] = useFlag();
  const [isRemoveDialogOpen, showRemoveDialog, hideRemoveDialog] = useFlag();
  const [openMenuId, setOpenMenuId] = useState('');
  const [primaryContactId, setPrimaryContactId] = useState(
    parents.find((p) => Boolean(p.primary))?.id || '',
  );

  const navigate = useNavigate();

  const isFamilyMemberAdded = useMemo(
    () => !!parents.length || !!children.length,
    [children.length, parents.length],
  );

  const isFamilyAdded = useMemo(
    () => !!parents.length && !!children.length,
    [children.length, parents.length],
  );

  const setFirstPrimaryContact = useCallback(
    (parentsList: CreateParentForm[]) => {
      const firstParent = parentsList[0];

      if (firstParent) {
        setPrimaryContactId(firstParent.id);
        updateParents([{ ...firstParent, primary: true }]);
      } else {
        setPrimaryContactId('');
      }
    },
    [updateParents],
  );

  const updatePrimaryContact = useCallback(
    (parent: CreateParentForm) => {
      setPrimaryContactId(parent.id);
      const prevPrimaryContact = parents.find((parent) => parent.id === primaryContactId);

      if (prevPrimaryContact) {
        updateParents([
          { ...prevPrimaryContact, primary: false },
          { ...parent, primary: true },
        ]);
      } else {
        updateParents([{ ...parent, primary: true }]);
      }
    },
    [parents, primaryContactId, updateParents],
  );

  const onCloseAndReset = useCallback(() => {
    resetApplication();
    navigate('/applications');
  }, [navigate, resetApplication]);

  const getActions = useCallback(
    (parent: CreateParentForm) => {
      const isPrimaryContact = parent.id === primaryContactId;

      const editOrViewAction = {
        title: parent.profile_id
          ? $t({ id: 'applications-ViewProfile' })
          : $t({ id: 'applications-EditInformation' }),
        handler: () => {
          onParentEdit({
            profileId: parent.profile_id,
            id: parent.id,
          });
        },
        icon: parent.profile_id ? <EyeIcon /> : <EditIcon />,
      };
      const markPrimaryAction = {
        title: $t({ id: 'applications-Actions-MarkPrimary' }),
        handler: () => {
          updatePrimaryContact(parent);
        },
        icon: <StarIcon />,
      };
      const removeOrDeleteAction = {
        title: parent.profile_id
          ? $t({ id: 'applications-Actions-Remove' })
          : $t({ id: 'applications-Actions-Delete' }),
        handler: () => {
          setPersonToRemove({ type: 'Parent', form: parent });
          showRemoveDialog();
        },
        icon: parent.profile_id ? <MinusIcon /> : <DeleteIcon />,
      };

      return [
        editOrViewAction,
        ...(isPrimaryContact ? [] : [markPrimaryAction]),
        removeOrDeleteAction,
      ];
    },
    [$t, onParentEdit, primaryContactId, showRemoveDialog, updatePrimaryContact],
  );

  useEffect(() => {
    if (!primaryContactId && parents.length) {
      setFirstPrimaryContact(parents);
    }
  }, [parents, primaryContactId, setFirstPrimaryContact]);

  return (
    <>
      <ModalHeader active title={$t({ id: 'applications-Title' })}>
        <IconButton onClick={isFamilyMemberAdded ? showConfirmModal : onCloseAndReset}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalMain>
        <ModalPanel active sx={{ width: '50%' }}>
          <Stack gap={2.5}>
            <Typography variant="h4" color="primary.main">
              <FormattedMessage id="userType-parentsGuardians" />
            </Typography>
            {!parents.length && (
              <AddParentContextMenu arrowOffset={[-5, 0]} actions={parentActions}>
                {(handleClick) => (
                  <AddFamilyMemberButton
                    onClick={handleClick}
                    textId="applications-AddPrimaryParentOrGuardian"
                    testId="add-family-member-button"
                  />
                )}
              </AddParentContextMenu>
            )}
            <Stack gap={2.5} sx={(theme) => ({ pr: theme.spacing(2.5) })}>
              {parents.map((parent) => {
                const menuOpen = openMenuId === parent.id;
                return (
                  <ApplicationCreatePersonCard
                    key={parent.id}
                    user={parent}
                    isPrimaryContact={primaryContactId === parent.id}
                    sx={(theme) => ({
                      background: menuOpen ? theme.palette.background.default : undefined,
                      '& .UIKit-LongNameWithVerticalTooltip__info': {
                        color: menuOpen ? theme.palette.primary.main : undefined,
                      },
                      '& .MuiTypography-body1, .MuiIcon-root': {
                        color: menuOpen ? theme.palette.text.primary : undefined,
                      },
                      '& .MuiIconButton-root': {
                        visibility: menuOpen ? 'visible' : undefined,
                      },
                    })}
                  >
                    <PersonActionsSelect
                      onClose={() => setOpenMenuId('')}
                      onOpen={() => setOpenMenuId(parent.id)}
                      actions={getActions(parent)}
                    />
                  </ApplicationCreatePersonCard>
                );
              })}
            </Stack>
            {!!parents.length && (
              <AddParentContextMenu arrowOffset={[-15, 0]} actions={parentActions}>
                {(handleClick) => (
                  <AddFamilyMemberButton
                    onClick={handleClick}
                    textId="applications-AddAdditionalParentOrGuardian"
                    testId="add-family-member-button"
                  />
                )}
              </AddParentContextMenu>
            )}
          </Stack>
        </ModalPanel>
        <ModalPanel active sx={{ width: '50%', pl: 0 }}>
          <Stack gap={2.5}>
            <Typography variant="h4" color="primary.main">
              <FormattedMessage id="userType-child-plural" />
            </Typography>
            {!children.length && (
              <AddFamilyMemberButton
                textId="applications-AddChild"
                onClick={() => onChildClick()}
                testId="add-child-button"
              />
            )}
            <Stack gap={2.5} sx={(theme) => ({ pr: theme.spacing(5) })}>
              {children.map((child) => {
                return (
                  <ApplicationCreatePersonCard user={child} key={child.id}>
                    <Stack direction="row" gap={1.5} alignItems="center">
                      <IconButton
                        inverse
                        onClick={() => {
                          onChildClick(child.id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        inverse
                        onClick={() => {
                          setPersonToRemove({ type: 'Child', form: child });
                          showRemoveDialog();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </ApplicationCreatePersonCard>
                );
              })}
            </Stack>
            {!!children.length && (
              <AddFamilyMemberButton
                textId="applications-AddChild"
                onClick={() => onChildClick()}
                testId="add-child-button"
              />
            )}
          </Stack>
        </ModalPanel>
      </ModalMain>
      {isFamilyAdded && (
        <ModalFooter active>
          <Stack direction="row" gap={1}>
            {generalCustomFields.length ? (
              <Button
                endIcon={<ArrowRightIcon />}
                disabled={saving}
                onClick={onContinue}
                data-test-id="save-application-button"
              >
                <FormattedMessage id="action-Continue" />
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  startIcon={saving && <Spin />}
                  disabled={saving}
                  onClick={onOpenPreview}
                  data-test-id="preview-application-button"
                >
                  <FormattedMessage id="action-Preview" />
                </Button>

                <Button
                  startIcon={saving && <Spin />}
                  disabled={saving}
                  onClick={onSubmit}
                  data-test-id="save-application-button"
                >
                  <FormattedMessage id="action-Save" />
                </Button>
              </>
            )}
          </Stack>
        </ModalFooter>
      )}

      <ModalConfirmationDialog
        onCancel={hideConfirmModal}
        onConfirm={onCloseAndReset}
        open={isConfirmModalOpen}
        confirmLabelId="yes"
        cancelLabelId="no"
        content={<FormattedMessage id="applications-CloseWithoutSavingWarning" />}
      />
      <RemovePersonConfirmDialog
        isOpen={isRemoveDialogOpen}
        onClose={() => {
          setPersonToRemove(undefined);
          hideRemoveDialog();
        }}
        onConfirm={() => {
          if (!personToRemove) {
            return;
          }
          if (personToRemove.type === 'Parent') {
            const parentId = personToRemove.form.id;
            onParentRemove(parentId);
            if (parentId === primaryContactId) {
              const updatedParents = parents.filter((p) => p.id !== parentId);
              setFirstPrimaryContact(updatedParents);
            }
          } else {
            onChildRemove(personToRemove.form.id);
          }
          setPersonToRemove(undefined);
          hideRemoveDialog();
        }}
        personName={personToRemove ? getUserFullName(personToRemove.form) : ''}
      />
    </>
  );
};
