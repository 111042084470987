import { Group, listGroups } from '@schooly/api';
import { FilterKeys, FilterValue, GroupUserType, SelectOption, UserType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFilters } from '../../../../context/filters/useFilters';
import usePagedApiResourceWithFilter, {
  GetResourceFunction,
} from '../../../../hooks/usePagedApiResourceWithFilter';
// import searchWords from '../../../../utils/searchWords';
import Tag from '../../../ui/Tag';
import Preloader from '../../../uikit/Preloader/Preloader';
import { SelectGroupRow } from '../../../uikit-components/SelectGroupRow/SelectGroupRow';

import './FilterGroupSelectPopupContent.scss';

export interface FilterGroupSelectPopupContentProps {
  searchQuery: string;
  options?: SelectOption<FilterValue>[];
  hasNoneOption?: boolean;
  popupTitleTextId?: string;
  noneOption: SelectOption<FilterValue>;
  showTitle?: boolean;
  isOpen?: boolean;
  type?: UserType;
  isOptionActive?: (option: SelectOption<FilterValue>, userType?: GroupUserType) => boolean;
  handleSelectOption: (option?: SelectOption<FilterValue>, userType?: GroupUserType) => void;
  handleRemoveOption: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    option?: SelectOption<FilterValue>,
    userType?: GroupUserType,
  ) => void;
  resetSearchQuery: VoidFunction;
  query?: string;
  isHeaderFilterPopupOpen?: boolean;
}

const $class = 'FilterGroupSelectPopupContent';

export const FilterGroupSelectPopupContent: FC<FilterGroupSelectPopupContentProps> = ({
  searchQuery,
  options = [],
  hasNoneOption,
  isOptionActive,
  handleSelectOption,
  handleRemoveOption,
  resetSearchQuery,
  noneOption,
  query,
  isOpen,
}) => {
  const { formatMessage } = useIntl();
  const { filters, lists, listFilters, setGroupsList } = useFilters();
  const { schoolId } = useAuth();

  const getGroupsList = useCallback<GetResourceFunction<Group>>(
    ({ pageNumber, query }) => {
      if (!schoolId || !isOpen) {
        return undefined;
      }

      return listGroups({
        schoolId,
        pageSize: 99999,
        pageNumber,
        query,
        // Consider additional Group filters set externally.
        //
        // For example, Report preview modal might set assessment ids of the report
        // https://treehive.atlassian.net/browse/TR-2135
        filters: {
          ...listFilters.groups,
          ...filters.applied,
          [FilterKeys.IntersectDate]: filters.draft.date,
          [FilterKeys.Staff]: filters.draft.staff,
        },
      });
    },
    [
      filters.applied,
      filters.draft.date,
      filters.draft.staff,
      isOpen,
      listFilters.groups,
      schoolId,
    ],
  );

  const { displayedList, canShowMore, isFetching, isSearching, handleShowMore } =
    usePagedApiResourceWithFilter<Group>({
      getResource: getGroupsList,
      filter: query,
    });

  useEffect(() => {
    if ((displayedList?.results.length && !searchQuery) || !lists.groups.length) {
      setGroupsList(displayedList?.results ?? []);
    }
  }, [displayedList, lists.groups.length, searchQuery, setGroupsList]);

  const loaderRef = useInfiniteScroll(isFetching, handleShowMore);

  const getOptionText = useCallback(
    (option: SelectOption<FilterValue>) =>
      option.labelTextId ? formatMessage({ id: option.labelTextId }) : option.label,
    [formatMessage],
  );

  const onSelectOption = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, selectOption?: SelectOption<FilterValue>) => {
      const isActive = isOptionActive && selectOption && isOptionActive(selectOption);

      if (isActive) {
        handleRemoveOption(e, selectOption);
      } else {
        handleSelectOption(selectOption);
      }
    },
    [handleSelectOption, handleRemoveOption, isOptionActive],
  );

  const renderNoneOption = (className?: string, userType?: GroupUserType) => {
    const isActive = isOptionActive && isOptionActive(noneOption, userType);

    return (
      <li key={noneOption.value} className={className}>
        <Tag
          selectOption={noneOption}
          className={isActive ? 'active' : undefined}
          onClick={onSelectOption}
          isBig
        >
          {getOptionText(noneOption)}
        </Tag>
      </li>
    );
  };

  const renderContent = () => {
    if (isSearching) {
      return <Preloader />;
    }

    if (!displayedList?.results && isFetching) {
      return <Preloader />;
    }

    return (
      <div className={`${$class}__list`}>
        {displayedList?.results.map((group) => {
          const option = { value: group.id, label: group.name, item: group };

          const isSelected = isOptionActive && group && isOptionActive(option);

          return (
            <SelectGroupRow
              key={group.id}
              option={option}
              isSelected={isSelected}
              onClick={onSelectOption}
            />
          );
        })}
        {canShowMore && (
          <>
            {isFetching && <Preloader />}
            <div ref={loaderRef} />
          </>
        )}
      </div>
    );
  };

  if (!displayedList?.results.length && !isFetching && !isSearching) {
    return (
      <div>
        <span className="form-select-option dropdown-item form-select-no-options-found">
          <FormattedMessage id="input-NoOptionsFound" />
        </span>
      </div>
    );
  }

  return (
    <div className={$class}>
      {!query && hasNoneOption && renderNoneOption()}
      {renderContent()}
    </div>
  );
};
