import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5C14 7.20914 12.2091 9 10 9C7.79086 9 6 7.20914 6 5C6 2.79086 7.79086 1 10 1C12.2091 1 14 2.79086 14 5ZM16.5905 12.8709C14.9473 11.1048 12.6027 10 10 10C5.02944 10 1 14.0294 1 19H9.74298C9.67669 18.2852 9.91719 17.5473 10.4645 17C9.48816 16.0237 9.48816 14.4408 10.4645 13.4645C11.4408 12.4882 13.0237 12.4882 14 13.4645C14.7006 12.7639 15.7135 12.5661 16.5905 12.8709ZM16.1213 15.5858C16.3166 15.3906 16.3166 15.074 16.1213 14.8787C15.9261 14.6834 15.6095 14.6834 15.4142 14.8787L14 16.2929L12.5858 14.8787C12.3905 14.6834 12.0739 14.6834 11.8787 14.8787C11.6834 15.074 11.6834 15.3905 11.8787 15.5858L13.2929 17L11.8787 18.4142C11.6834 18.6095 11.6834 18.9261 11.8787 19.1213C12.0739 19.3166 12.3905 19.3166 12.5858 19.1213L14 17.7071L15.4142 19.1213C15.6095 19.3166 15.9261 19.3166 16.1213 19.1213C16.3166 18.9261 16.3166 18.6095 16.1213 18.4142L14.7071 17L16.1213 15.5858ZM19.6633 19.2C19.8493 19.2 20 19.0493 20 18.8633C20 18.6774 19.8493 18.5267 19.6633 18.5267H18.1176L18.9294 17.631C19.1137 17.4319 19.2755 17.241 19.4147 17.0582C19.5539 16.8754 19.6618 16.6936 19.7382 16.5128C19.8167 16.33 19.8559 16.1421 19.8559 15.9492C19.8559 15.5632 19.7363 15.2586 19.4971 15.0352C19.2598 14.8117 18.9196 14.7 18.4765 14.7C18.1706 14.7 17.9069 14.766 17.6853 14.898C17.4637 15.028 17.2941 15.2027 17.1765 15.4221C17.1212 15.5242 17.0789 15.6315 17.0496 15.7439C16.9933 15.96 17.182 16.1442 17.4053 16.1442C17.6277 16.1442 17.7943 15.9553 17.8824 15.7511C17.9333 15.6354 18.0069 15.544 18.1029 15.4769C18.201 15.4099 18.3216 15.3764 18.4647 15.3764C18.651 15.3764 18.7941 15.4363 18.8941 15.5561C18.9941 15.676 19.0441 15.8344 19.0441 16.0314C19.0441 16.1228 19.0265 16.2183 18.9912 16.3178C18.9559 16.4173 18.8961 16.528 18.8118 16.6499C18.7294 16.7718 18.6167 16.915 18.4735 17.0795L17.192 18.5055C17.1233 18.5819 17.0853 18.681 17.0853 18.7837C17.0853 19.0136 17.2717 19.2 17.5016 19.2H19.6633Z"
      fill="#F2994A"
    />
  </svg>
);
