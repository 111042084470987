import { Stack } from '@mui/material';
import { EventsStatuses, GET_SIGN_UP_QUERY, SignUpStatuses, SignUpType } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { getSignUpsStatusLabel } from '@schooly/components/filters';
import { HeaderActions } from '@schooly/components/header-actions';
import { EventsIcon, PublishIcon, SimpleButton, UnpublishIcon } from '@schooly/style';
import { isNotFalse } from '@schooly/utils/predicates';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useEvent } from '../../../context/events/WithEvent';
import { useSignUp } from '../../../context/signUps/WithSignUp';

export interface SignUpPreviewActionsProps {
  canGoToEvent?: boolean;
  onChangeStatus?: (status: SignUpStatuses) => void;
}

export const SignUpPreviewActions: FC<SignUpPreviewActionsProps> = ({
  canGoToEvent,
  onChangeStatus,
}) => {
  const { $t } = useIntl();
  const queryClient = useQueryClient();
  const { getConfirmation } = useConfirmationDialog();
  const {
    actions: { publishing: eventPublishing },
  } = useEvent();
  const { signUp, canEdit, publishing: signUpPublishing, submitAction, updateList } = useSignUp();

  const publishing = eventPublishing || signUpPublishing;
  const isForPublish = signUp?.status === SignUpStatuses.Draft;

  const eventHasNoTimeSlotsForSignUp =
    isForPublish && signUp?.type === SignUpType.Slots && !signUp?.event?.date_times.length;

  const title = useMemo(() => {
    if (!signUp?.status) {
      return '';
    }

    return $t({ id: getSignUpsStatusLabel(signUp.status) });
  }, [$t, signUp?.status]);

  const selectActions = useMemo(() => {
    return [
      isForPublish &&
        canEdit && {
          titleTextId: 'action-Open',
          pendingTitle: $t({
            id: 'publishing',
          }),
          icon: <PublishIcon className="reset-svg-currentColor" />,
          handler: async () => {
            const isConfirmed = await getConfirmation({
              textId: 'eventSignUps-notification-NotifyAfterAction',
              textValues: {
                action: 'publishing',
              },
            });

            await submitAction({
              action: 'open',
              sign_up_id: signUp.id,
              notify_parents: isConfirmed,
            });

            if (queryClient.getQueryData([GET_SIGN_UP_QUERY, signUp.id])) {
              queryClient.setQueryData([GET_SIGN_UP_QUERY, signUp.id], {
                ...signUp,
                status: SignUpStatuses.Open,
              });
            }

            updateList();

            onChangeStatus?.(SignUpStatuses.Open);
            return true;
          },
        },
      signUp?.status === SignUpStatuses.Open &&
        canEdit && {
          titleTextId: 'action-Cancel',
          icon: <UnpublishIcon />,
          pendingTitle: $t({
            id: 'cancelling',
          }),
          handler: async () => {
            const isConfirmed = await getConfirmation({
              textId: 'eventSignUps-notification-NotifyAfterAction',
              textValues: {
                action: 'cancelling',
              },
            });

            await submitAction({
              action: 'close',
              sign_up_id: signUp.id,
              notify_parents: isConfirmed,
            });

            if (queryClient.getQueryData([GET_SIGN_UP_QUERY, signUp.id])) {
              queryClient.setQueryData([GET_SIGN_UP_QUERY, signUp.id], {
                ...signUp,
                status: SignUpStatuses.Closed,
              });
            }

            updateList();

            onChangeStatus?.(SignUpStatuses.Closed);
            return true;
          },
        },
    ].filter(isNotFalse);
  }, [
    $t,
    canEdit,
    getConfirmation,
    isForPublish,
    onChangeStatus,
    queryClient,
    signUp,
    submitAction,
    updateList,
  ]);

  const styleType = useMemo(() => {
    switch (signUp?.status) {
      case SignUpStatuses.Open:
        return 'success';
      default:
        return undefined;
    }
  }, [signUp?.status]);

  const tooltip = useMemo(() => {
    if (!canEdit) {
      return;
    }

    if (!signUp?.event?.id) {
      return;
    }

    if (
      signUp?.event?.event_status === EventsStatuses.Published &&
      signUp.status === SignUpStatuses.Draft
    ) {
      return;
    }

    if (signUp?.status !== SignUpStatuses.Draft) {
      return;
    }

    if (eventHasNoTimeSlotsForSignUp) {
      return (
        <Stack gap={1.25} alignItems="flex-start">
          <FormattedMessage id="events-SignUp-Duration-NoTimeSlots" />

          {canGoToEvent && (
            <Link to={`/events/${signUp.event.id}`}>
              <SimpleButton
                startIcon={<EventsIcon />}
                sx={(theme) => ({ ...theme.typography.body1 })}
              >
                <FormattedMessage id="eventSignUps-preview-Actions-GoToEvent" />
              </SimpleButton>
            </Link>
          )}
        </Stack>
      );
    }

    return (
      <Stack gap={1.25} alignItems="flex-start">
        <FormattedMessage id="eventSignUps-preview-Actions-Tooltip" />

        {canGoToEvent && (
          <Link to={`/events/${signUp.event.id}`}>
            <SimpleButton
              startIcon={<EventsIcon />}
              sx={(theme) => ({ ...theme.typography.body1 })}
            >
              <FormattedMessage id="eventSignUps-preview-Actions-GoToEvent" />
            </SimpleButton>
          </Link>
        )}
      </Stack>
    );
  }, [
    canEdit,
    canGoToEvent,
    eventHasNoTimeSlotsForSignUp,
    signUp?.event?.event_status,
    signUp?.event?.id,
    signUp?.status,
  ]);

  return (
    <HeaderActions
      title={title}
      actions={selectActions}
      styleType={styleType}
      pending={publishing}
      disabled={Boolean(
        !canEdit ||
          signUp?.status === SignUpStatuses.Closed ||
          (isForPublish &&
            signUp?.event?.id &&
            signUp?.event?.event_status !== EventsStatuses.Published) ||
          eventHasNoTimeSlotsForSignUp,
      )}
      tooltipTitle={tooltip}
    />
  );
};
