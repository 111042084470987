import {
  Box,
  Icon,
  IconButton,
  keyframes,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useGetSchoolQuery } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ContextMenu,
  DeleteIcon,
  EditIcon,
  EmailIcon,
  LocationIcon,
  MoreIcon,
  PhoneIcon,
  SimpleButton,
  VerifiedIcon,
  WorldIcon,
} from '@schooly/style';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { getUserAddress } from '../../helpers/users';
import { useSchool } from '../../hooks/useSchool';
import { SchoolGeneralListedToggle } from './SchoolGeneral/SchoolGeneralListedToggle';
import { SchoolGeneralLogo } from './SchoolGeneral/SchoolGeneralLogo/SchoolGeneralLogo';
import { SchoolGeneralVerify } from './SchoolGeneral/SchoolGeneralVerify';
import { EditableRow } from './SchoolPage.styled';

export const SchoolPageHeader: FC = () => {
  const { schoolId = '', isCompleted } = useSchool();
  const { $t } = useIntl();
  const [detailsVisible, showDetails, hideDetails] = useFlag(false);
  const navigate = useNavigate();

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    refetchOnMount: 'always',
    enabled: !!schoolId,
  });

  const address = currentSchool ? getUserAddress(currentSchool) : undefined;
  const routePath = !!isCompleted ? '/settings' : '/settings/tune';

  const verified = !!currentSchool?.verification;

  const schoolCode = (
    <Link to={`${routePath}/name`}>
      <EditableRow>
        <Typography variant="h2" color="text.primary">
          {currentSchool?.code}
        </Typography>
        <IconButton>
          <EditIcon />
        </IconButton>
      </EditableRow>
    </Link>
  );

  const renderSchoolInfo = () => {
    if (!currentSchool)
      return (
        <Typography variant="h2">
          <Skeleton width={90} />
        </Typography>
      );

    if (!verified || detailsVisible) return schoolCode;

    return <SimpleButton onClick={showDetails}>{$t({ id: 'school-ViewDetails' })}</SimpleButton>;
  };

  return (
    <>
      <Stack direction="row" gap={2.5}>
        <Link to={`/settings/${isCompleted ? 'info' : 'tune/info'}`}>
          <SchoolGeneralLogo />
        </Link>

        <Stack flex="1 1 auto" alignItems="flex-start">
          {currentSchool ? (
            <Link to={`${routePath}/name`}>
              <EditableRow>
                <Typography variant="h1">{currentSchool.name}</Typography>
                {verified && (
                  <Icon
                    sx={(theme) => ({
                      position: 'relative',
                      top: theme.spacing(-1),
                      left: theme.spacing(-0.5),
                      transform: 'scale(0.8)',
                    })}
                  >
                    <VerifiedIcon className="reset-svg-currentColor" />
                  </Icon>
                )}
                <IconButton>
                  <EditIcon />
                </IconButton>
              </EditableRow>
            </Link>
          ) : (
            <Typography variant="h1">
              <Skeleton width={180} />
            </Typography>
          )}
          {renderSchoolInfo()}
        </Stack>
        {isCompleted && (
          <Box>{verified ? <SchoolGeneralListedToggle /> : <SchoolGeneralVerify />}</Box>
        )}
        <Box pr={1} pt={1}>
          <ContextMenu
            actions={[
              {
                labelTextId: 'school-DeleteSchool',
                icon: <DeleteIcon />,
                onClick: () => navigate('/settings/delete'),
              },
            ]}
          >
            {(openMenu) => (
              <IconButton inverse onClick={openMenu}>
                <MoreIcon />
              </IconButton>
            )}
          </ContextMenu>
        </Box>
      </Stack>
      {detailsVisible && (
        <DetailsContainer>
          <Link to={`${routePath}/info`}>
            <InlineEditableRow>
              <Typography variant="h3" color="text.primary">
                {currentSchool?.description}&nbsp;
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Typography>
            </InlineEditableRow>
          </Link>
          <Stack flexDirection="row" gap={4}>
            <EditButton icon={<LocationIcon />} linkTo={`${routePath}/address`}>
              {address}
            </EditButton>
            <EditButton icon={<WorldIcon />} noWrap linkTo={`${routePath}/info`}>
              {currentSchool?.website}
            </EditButton>
            <EditButton icon={<PhoneIcon />} noWrap linkTo={`${routePath}/info`}>
              {currentSchool?.telephone}
            </EditButton>
            <EditButton icon={<EmailIcon />} noWrap linkTo={`${routePath}/info`}>
              {currentSchool?.contact_email}
            </EditButton>
          </Stack>

          <SimpleButton onClick={hideDetails}>{$t({ id: 'school-HideDetails' })}</SimpleButton>
        </DetailsContainer>
      )}
    </>
  );
};

type EditButtonProps = PropsWithChildren<{
  linkTo: string;
  icon: ReactNode;
  noWrap?: boolean;
}>;
export const EditButton: FC<EditButtonProps> = ({ linkTo, icon, children, noWrap }) => {
  return (
    <Link to={linkTo}>
      <InlineEditableRow
        sx={(theme) => ({
          '.edit-button-icon, .edit-button-text': {
            transition: 'color .2s',
          },

          '&:not(:hover)': {
            '.edit-button-text': {
              color: theme.palette.text.primary,
            },
            '.edit-button-icon': {
              color: theme.palette.text.secondary,
            },
          },
        })}
      >
        <Icon className="edit-button-icon" sx={{ display: 'flex', mt: 0.25 }}>
          {icon}
        </Icon>
        <Typography
          variant="h3"
          className="edit-button-text"
          whiteSpace={noWrap ? 'nowrap' : undefined}
        >
          {children}
          &nbsp;
          <IconButton>
            <EditIcon />
          </IconButton>
        </Typography>
      </InlineEditableRow>
    </Link>
  );
};

const InlineEditableRow = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),

  '&:not(:hover) .MuiIconButton-root': {
    opacity: 0,
  },
}));

const fadeIn = keyframes`
  from {
    max-height: 0vh;
  }
  to {
    max-height: 100vh;
  }
`;

const DetailsContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(11),
  alignItems: 'flex-start',
  animation: `${fadeIn} 0.75s ease`,
}));
