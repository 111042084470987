import { Box, Icon, Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { ConsentFormPublished, ConsentFormShort } from '@schooly/api';
import { ShowHtml, SubmitIcon, TypographyWithOverflowHint, UnpublishIcon } from '@schooly/style';
import React, { FC, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getConsentFormItemStatusColor } from './helpers';

type ConsentFormPreviewProps = {
  label: string;
  consentFormShort: ConsentFormShort;
};

export const ConsentFormPreview: FC<ConsentFormPreviewProps> = ({ label, consentFormShort }) => {
  return (
    <Link to={`/consent-form/${consentFormShort.id}`}>
      <Stack gap={2} pb={1}>
        <Typography variant="h2">{label}</Typography>
        <Stack
          sx={{
            backgroundColor: 'background.paper',
            px: 2,
            py: 1.5,
            borderRadius: 1,
            color: 'common.grey2',
            ':hover': {
              backgroundColor: 'common.lightBg',
              color: 'primary.main',
            },
          }}
        >
          {consentFormShort.type === 'published' && (
            <Stack direction="row" gap={8} pb={2}>
              <ConsentFormStatusPreview status="agreed" count={consentFormShort.statuses.agreed} />
              <ConsentFormStatusPreview
                status="declined"
                count={consentFormShort.statuses.declined}
              />
            </Stack>
          )}
          <ShowHtml html={consentFormShort.description} />
        </Stack>
      </Stack>
    </Link>
  );
};

type ConsentFormStatusPreviewProps = {
  status: keyof Omit<NonNullable<ConsentFormPublished['statuses']>, 'no_status'>;
  count: number;
};

const ConsentFormStatusPreview: FC<ConsentFormStatusPreviewProps> = ({ status, count }) => {
  const { $t } = useIntl();

  const color = getConsentFormItemStatusColor(status);

  return (
    <Stack direction="row" gap={1}>
      <Icon sx={{ color }}>{status === 'agreed' ? <SubmitIcon /> : <UnpublishIcon />}</Icon>

      <Typography variant="h3" sx={{ color }}>
        {$t({ id: `consentForms-${status}` })}
        {': '}
        {count}
      </Typography>
    </Stack>
  );
};

type ConsentFormSignedInfoProps = {
  label?: string;
  path: string;
  userName: string;
  textWidth?: number;
};

export const ConsentFormSignedInfo: FC<ConsentFormSignedInfoProps> = ({
  label,
  path,
  userName,
  textWidth = 300,
}) => {
  return (
    <TypographyWithOverflowHint maxWidth={textWidth}>
      <Box color="text.primary" display="inline">
        {label && <Typography display="inline">{label} </Typography>}

        <Typography
          color="text.primary"
          component={Link}
          to={path}
          maxWidth={0}
          minWidth={0}
          onClick={(e) => e.stopPropagation()}
          sx={{ '&:hover': { textDecoration: 'underline' } }}
        >
          {userName}
        </Typography>
      </Box>
    </TypographyWithOverflowHint>
  );
};

type ConsentItemsSkeletonRowsProps = {
  rowCount: number;
  widths: string[];
  withAvatar?: boolean;
} & StackProps;

export const ConsentItemsSkeletonRows: FC<ConsentItemsSkeletonRowsProps> = ({
  rowCount,
  widths,
  withAvatar,
  ...props
}) => {
  const rowWidths = useRef(widths).current;

  return (
    <Stack gap={2} pl={0.5} pb={1.25} width="100%">
      {[...new Array(rowCount)].map((_, i) => {
        return (
          <Stack key={i} width="100%" direction="row" gap={2} {...props}>
            {rowWidths.map((width, i) => (
              <Box width={width} height={30} key={i}>
                <Skeleton variant={withAvatar && i === 0 ? 'circular' : undefined} />
              </Box>
            ))}
          </Stack>
        );
      })}
    </Stack>
  );
};
