import { PhoneNumberCountry, SelectOption } from '@schooly/api';
import { CheckIcon, CrossIcon, DropdownIcon } from '@schooly/style';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import COUNTRIES from '../../../../constants/countriesWithAreaCodes.json';
import useDropdown from '../../../../hooks/useDropdown';
import useHorizontalScroll from '../../../../hooks/useHorizontalScroll';
import useKeyboardListNavigation from '../../../../hooks/useKeyboardListNavigation';
import useVirtualBackdrop from '../../../../hooks/useVirtualBackdrop';
import buildClassName from '../../../../utils/buildClassName';
import searchWords from '../../../../utils/searchWords';
import { renderError, renderLabel } from '../helpers';
import { BaseFormInputProps } from '../utils';

import '../FormSelect.scss';

interface IProps extends BaseFormInputProps {
  country?: PhoneNumberCountry;
  onChange: (country?: PhoneNumberCountry) => void;
  error?: FieldError | undefined;
}

const COUNTRY_OPTIONS: SelectOption[] = COUNTRIES.map(({ name, code, id }) => ({
  label: `${name} (${code})`,
  value: id,
}));

const CountryCodeSelect: React.FC<IProps> = (props) => {
  const { id, country, className, disabled, onChange, name, error } = props;
  const { formatMessage } = useIntl();
  const isTouched = !!country;

  const wrapperRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { isOpen, openPopup, closePopup, Popup } = useDropdown(wrapperRef, inputRef, true);

  const shouldSupressFocus = useRef<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>('');

  function handleSearchQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(e.currentTarget.value);
  }

  function handleSearchFocus(e: React.FocusEvent<HTMLInputElement>) {
    if (shouldSupressFocus.current) {
      e.currentTarget.blur();
      return;
    }
    openPopup(e);
  }

  const menuRef = useRef<HTMLUListElement>(null);
  const valueWrapperRef = useRef<HTMLDivElement>(null);
  const handleKeyDown = useKeyboardListNavigation(menuRef, isOpen);
  useHorizontalScroll(valueWrapperRef);

  useVirtualBackdrop(isOpen, closePopup, menuRef, wrapperRef);

  useEffect(() => {
    setSearchQuery('');
  }, [isOpen]);

  const isOptionActive = useCallback(
    (option: SelectOption) => country && option.value === country.id,
    [country],
  );

  const getOptionClassName = useCallback(
    (option: SelectOption) =>
      buildClassName('dropdown-item form-select-option', isOptionActive(option) && 'active'),
    [isOptionActive],
  );

  const getOptionText = useCallback(
    (option: SelectOption) =>
      option.labelTextId ? formatMessage({ id: option.labelTextId }) : option.label,
    [formatMessage],
  );

  const displayedOptions = useMemo(() => {
    if (!searchQuery) {
      return COUNTRY_OPTIONS;
    }

    return COUNTRY_OPTIONS.filter((o) => {
      const text = getOptionText(o);
      return text && searchWords(text, searchQuery);
    });
  }, [searchQuery, getOptionText]);

  const handleValueRemove = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onChange(undefined);
      setSearchQuery('');

      setTimeout(() => closePopup(e));
    },
    [closePopup, onChange],
  );

  const handleDropdownIconClick = useCallback(
    (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
      if (!isOpen) {
        openPopup(e);
        return;
      }

      closePopup(e);
    },
    [isOpen, openPopup, closePopup],
  );

  const handleSelectOption = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      const option = e.currentTarget as HTMLButtonElement;

      const selectedOption = COUNTRIES.find((c) => c.id === option.dataset.value);
      onChange(selectedOption);

      setSearchQuery('');

      closePopup();
    },
    [closePopup, onChange],
  );

  const popupClassName = buildClassName(
    'form-select-popup CountryCodeSelectPopup',
    'list-unstyled',
    'dropdown-menu',
    'shadow-sm',
    isOpen && 'show',
  );

  const fullClassName = buildClassName(
    'form-group form-select CountryCodeSelect',
    disabled && 'disabled',
    isOpen && 'open',
    (isTouched || isOpen) && 'touched',
    !country && 'no-value',
    error && 'error',
    className,
  );

  // TODO: Refactor and restructure
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label className={fullClassName} htmlFor={id} ref={wrapperRef} onKeyDown={handleKeyDown}>
        <input
          id={id}
          className="form-control form-select-search"
          ref={inputRef}
          disabled={disabled}
          value={searchQuery}
          onChange={handleSearchQueryChange}
          onFocus={handleSearchFocus}
          name={name}
        />
        {renderError(error)}
        {country && !searchQuery && (
          <div className="form-select-value-wrapper">
            <div ref={valueWrapperRef} className="form-select-value-wrapper-inner">
              <span className="form-select-value">{country.code}</span>
            </div>
          </div>
        )}

        {isOpen && !!country && (
          <div
            className="form-select-remove"
            role="button"
            tabIndex={-1}
            onClick={(e) => handleValueRemove(e)}
          >
            <CrossIcon />
          </div>
        )}

        {!(isOpen && !!country) && (
          <div
            className="form-select-arrow"
            role="button"
            tabIndex={-1}
            onClick={handleDropdownIconClick}
          >
            <DropdownIcon />
          </div>
        )}

        <Popup>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <ul ref={menuRef} className={popupClassName}>
            {!!searchQuery && !displayedOptions.length && (
              <li>
                <span className="dropdown-item form-select-option form-select-no-options-found">
                  <FormattedMessage id="input-NoOptionsFound" />
                </span>
              </li>
            )}
            {displayedOptions.map((option) => (
              <li key={option.value}>
                <button
                  type="button"
                  data-value={option.value}
                  className={getOptionClassName(option)}
                  onClick={(e) => handleSelectOption(e)}
                >
                  {getOptionText(option)}
                  {isOptionActive(option) && <CheckIcon />}
                </button>
              </li>
            ))}
          </ul>
        </Popup>

        {renderLabel(props, false)}
      </label>
    </>
  );
};

export default CountryCodeSelect;
