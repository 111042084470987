import { Stack, styled } from '@mui/material';

export const ModalAccessDeniedStyled = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',

  '& picture': {
    width: 'auto',
    marginBottom: theme.spacing(5),

    '& img': {
      maxWidth: 330,
    },
  },
}));
