import { styled, TextField } from '@mui/material';

export interface FormTextFieldStyledProps {
  solid?: boolean;
}

export const FormTextFieldStyled = styled(TextField)<FormTextFieldStyledProps>(
  ({ theme, solid }) => ({
    '& .FormTextField-clear': {
      // display: 'none',
    },

    '.h3-optional-required': {
      color: theme.palette.text.secondary,
    },

    '&:hover': {
      '& .FormTextField-clear': {
        display: 'inline-flex',

        '& ~ .FormTextField-dropdown': {
          display: 'none',
        },
      },

      '& .FormTextField-dropdown': {
        color: theme.palette.text.primary,
      },
    },

    '& .Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.common.grey} !important`,
      },
    },

    '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot [aria-expanded="true"]': {
      '& ~ .MuiInputAdornment-root': {
        '& .FormTextField-clear': {
          display: 'inline-flex',

          '& ~ .FormTextField-dropdown': {
            display: 'none',
          },
        },

        '& .FormTextField-dropdown': {
          transform: 'rotate(180deg)',
          color: theme.palette.text.primary,
        },
      },

      '& ~ .MuiOutlinedInput-notchedOutline': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },

    '&.MuiTextField-root .MuiInputLabel-root': {
      transform: `translate(${theme.spacing(1.5)}, ${theme.spacing(1.5)})`,

      '&.Mui-focused, &.MuiFormLabel-filled': {
        transform: `translate(${theme.spacing(1.5)}, ${theme.spacing(0.5)})`,
      },
    },

    '&.MuiTextField-root &.MuiInputBase-root:not(.MuiInputBase-multiline) .MuiInputAdornment-root.MuiInputAdornment-positionEnd':
      {
        marginTop: theme.spacing(-2),
      },

    ...(solid && {
      '& .MuiOutlinedInput-input': {
        zIndex: 1,
      },

      '& .MuiInputAdornment-root': {
        zIndex: 1,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        backgroundColor: theme.palette.background.paper,
      },
    }),
  }),
);
