import { Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { noop } from '../../../../../helpers/misc';
import IntlError from '../../../../../utils/intlError';
import { AttachmentFile, AttachmentProps } from './attachment-types';
import { AttachmentCard } from './AttachmentCard';
import { getIsMaxAttachmensSizeExceed, getMaxSizeError } from './utils';

interface AttachmentsProps extends AttachmentProps {
  error?: IntlError;
  readOnly?: boolean;
}

/** @deprecated use @schooly/form-rich-text instead */
const Attachments: React.FC<AttachmentsProps> = ({
  files,
  error,
  readOnly,
  setError = noop,
  setFiles = noop,
  onAttachmentCardClick,
}) => {
  useEffect(() => {
    if (getIsMaxAttachmensSizeExceed(files)) {
      setError(getMaxSizeError(files));
    } else {
      setError(undefined);
    }
  }, [files, setError]);

  const handleAttachmentCardClick = useCallback(
    (id?: string) => {
      if (onAttachmentCardClick) {
        onAttachmentCardClick(id);
        return;
      }

      const filteredFiles = (files as AttachmentFile[])?.filter((f) => f.id !== id);
      setFiles(filteredFiles);
    },
    [files, onAttachmentCardClick, setFiles],
  );

  return (
    <>
      {files?.length ? (
        <>
          {error && (
            <Typography color="error.main">
              <FormattedMessage id={error.message} values={error.values} />
            </Typography>
          )}
          <Stack direction="row" flexWrap="wrap" gap={2.5} mt={2.5} p="1px">
            {Array.from(files as AttachmentFile[]).map((item) => (
              <AttachmentCard
                readOnly={readOnly}
                key={item.id}
                file={item.file || item}
                onClick={() => handleAttachmentCardClick(item.id)}
              />
            ))}
          </Stack>
        </>
      ) : null}
    </>
  );
};

export default Attachments;
