import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { ConsentFormItemStatus } from '@schooly/api';
import { DownloadIcon, Spin } from '@schooly/style';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { getConsentFormItemStatusLabelId } from '../../components/common/ConsentForms/helpers';
import { MenuItemArrow } from '../../components/common/ContextMenu/ContextMenu.styled';

type ConsentFormItemsDownloadProps = {
  isDownloading: boolean;
  onDownload: (val: ConsentFormItemStatus[]) => void;
};
const options: ConsentFormItemStatus[] = ['agreed', 'declined', 'no_status'];

export const ConsentFormItemsDownload: FC<ConsentFormItemsDownloadProps> = ({
  isDownloading,
  onDownload,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [exportParams, setExportParams] = useState<ConsentFormItemStatus[]>([]);

  const open = Boolean(anchorEl);

  const { $t } = useIntl();

  return (
    <div>
      <Button
        size="small"
        sx={{
          position: 'relative',
          height: 30,
          width: 30,
          '&': {
            minWidth: 0,
          },
        }}
        variant={open ? 'contained' : 'text'}
        onClick={(e) => !isDownloading && setAnchorEl(e.currentTarget)}
      >
        <Icon fontSize="medium" sx={{ color: !open ? 'common.grey2' : 'inherit' }}>
          {isDownloading ? <Spin /> : <DownloadIcon />}
        </Icon>
      </Button>

      <MenuItemArrow anchorEl={anchorEl} open={open}>
        <Paper />
      </MenuItemArrow>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            transform: 'translateY(12px)',
          },
        }}
        sx={{
          '& .MuiList-root': {
            padding: (theme) => theme.spacing(1, 2.5),
          },
          '& .MuiButton-root.Mui-disabled': {
            borderColor: 'common.light2',
            '.MuiTypography-root': {
              color: 'inherit',
            },
          },
        }}
      >
        {options.map((status) => (
          <MenuItem key={status} sx={{ margin: 0, padding: 0 }}>
            <FormControlLabel
              sx={{ width: '100%', margin: 0 }}
              label={$t({ id: getConsentFormItemStatusLabelId(status) })}
              control={
                <Checkbox
                  sx={{ '&.Mui-checked + .MuiTypography-root': { color: 'primary.main' }, pl: 0 }}
                  onChange={() =>
                    setExportParams((v) =>
                      v.includes(status) ? v.filter((s) => s !== status) : [...v, status],
                    )
                  }
                />
              }
            />
          </MenuItem>
        ))}
        <Button
          variant="outlined"
          sx={{
            minWidth: 260,
            mt: 1.25,
            mb: 1,
          }}
          onClick={() => {
            onDownload(exportParams);
            setAnchorEl(null);
            setExportParams([]);
          }}
          disabled={!exportParams.length}
        >
          <Typography variant="h3">{$t({ id: 'consentForms-PDFExport' })}</Typography>
        </Button>
      </Menu>
    </div>
  );
};
