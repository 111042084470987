import { Popper, styled } from '@mui/material';

export const ModalRightSidebarStyled = styled(Popper)(({ theme, anchorEl }) => {
  const modal = typeof anchorEl === 'function' ? anchorEl() : anchorEl;

  const modalHeight = modal?.getBoundingClientRect().height ?? 0;

  return {
    zIndex: theme.zIndex.modal,

    '& > .MuiPaper-root': {
      position: 'relative',
      width: 340,
      height: modalHeight || `calc(100vh - ${theme.spacing(5)})`,
      marginLeft: theme.spacing(),
      overflow: 'hidden',

      [theme.breakpoints.only('lg')]: {
        width: 310,
      },
      [theme.breakpoints.down('lg')]: {
        width: 350,
        marginLeft: 0,
        borderRadius: 0,
        boxShadow: 'none',
        borderLeft: theme.mixins.borderValue(),
      },
      [theme.breakpoints.down('md')]: {
        width: 370,
      },
    },
  };
});

ModalRightSidebarStyled.defaultProps = {
  placement: 'right-start',
};

export const ModalRightSidebarArrow = styled(Popper)(({ theme, placement }) => ({
  zIndex: theme.zIndex.modal + 1,
  width: theme.spacing(),

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },

  '& > .MuiPaper-root': {
    position: 'absolute',
    width: theme.spacing(2),
    height: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: 2, // non-standard shape
    transform: 'rotate(-45deg)',
    boxShadow: 'none',

    ...(placement === 'top' && {
      marginTop: theme.spacing(-2.25),
      marginLeft: theme.spacing(-0.5),
      transformOrigin: 'center',
    }),

    ...(placement === 'right' && {
      marginLeft: theme.spacing(0.25),
      transformOrigin: 'top left',
    }),

    ...(placement === 'bottom' && {
      marginTop: theme.spacing(0.25),
      marginLeft: theme.spacing(-0.5),
      transformOrigin: 'center',
    }),

    ...(placement === 'left' && {
      marginLeft: theme.spacing(-2.25),
      transformOrigin: 'top left',
    }),
  },
}));

ModalRightSidebarArrow.defaultProps = {
  placement: 'right',
};
