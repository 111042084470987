import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdultUserType } from '@schooly/api';

import { ParentsSelectBy, StaffSelectBy } from '../../components/common/MessageModal/utils';

export enum AddMessageState {
  Recipients,
  Message,
}
export interface IMessagesState {
  isInboxModalOpen: boolean;
  highlightedMessageId: string;
  loadingAttachmentIds: string[];
  userType?: AdultUserType;
  selectBy?: ParentsSelectBy | StaffSelectBy;
  modalState?: AddMessageState;
}

const initialState: IMessagesState = {
  isInboxModalOpen: false,
  highlightedMessageId: '',
  loadingAttachmentIds: [],
  userType: undefined,
  selectBy: undefined,
  modalState: undefined,
};

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    toggleInboxModal: (state, action: PayloadAction<boolean>) => {
      state.isInboxModalOpen = action.payload;
    },
    setHighlightedMessageId: (state, action: PayloadAction<string>) => {
      state.highlightedMessageId = action.payload;
    },
    resetHighlightedMessageId: (state) => {
      state.highlightedMessageId = '';
    },
    setLoadingAttachmentId: (state, action: PayloadAction<string>) => {
      state.loadingAttachmentIds = [...state.loadingAttachmentIds, action.payload];
    },
    removeLoadingAttachmentId: (state, action: PayloadAction<string>) => {
      state.loadingAttachmentIds = state.loadingAttachmentIds.filter((id) => id !== action.payload);
    },
    setUserType: (state, action: PayloadAction<AdultUserType | undefined>) => {
      state.userType = action.payload;
    },
    setSelectBy: (state, action: PayloadAction<ParentsSelectBy | StaffSelectBy | undefined>) => {
      state.selectBy = action.payload;
    },
    setModalState: (state, action: PayloadAction<AddMessageState | undefined>) => {
      state.modalState = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice;
