import {
  ApiError,
  CompanySave,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from '@schooly/api';
import { Company } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { Countries } from '@schooly/constants';
import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ModalSidebarItem } from '../../components/ui/ModalSidebar';
import useQueryStringParams from '../../hooks/useQueryStringParams';
import { useRouter } from '../router/useRouter';

export enum CompanyPreviewModalTabs {
  About = 'about',
  Students = 'students',
  PayableFees = 'payableFees',
}

export interface CompanyForm extends Omit<Company, 'id' | 'country' | 'student_count'> {
  country: Countries | null;
  phoneCode: string;
}

export type CompanyContextProps = {
  company?: Company;
  id: string;
  isLoading: boolean;
  isFetching: boolean;
  isSaving: boolean;
  isRemoving: boolean;
  canCreateAndEdit: boolean;
  canEditCompanyStudents: boolean;
  canView: boolean;
  create: (c: CompanySave) => Promise<void>;
  remove: (id: string) => Promise<void>;
  update: (c: CompanySave) => Promise<void>;
  tabs: ModalSidebarItem<CompanyPreviewModalTabs>[];
  activeTab?: CompanyPreviewModalTabs;
};

export const CompanyContext = createContext<CompanyContextProps>({
  company: undefined,
  id: '',
  isLoading: false,
  isFetching: false,
  isSaving: false,
  isRemoving: false,
  canCreateAndEdit: false,
  canEditCompanyStudents: false,
  canView: false,
  create: async () => {},
  remove: async () => {},
  update: async () => {},
  tabs: [],
  activeTab: undefined,
});

export const WithCompany: FC<PropsWithChildren<{ id?: string }>> = ({ id: propId, children }) => {
  const { id: paramId = '' } = useParams<'id'>();
  const navigate = useNavigate();
  const query = useQueryStringParams();
  const id = propId ?? paramId;
  const { goBack } = useRouter();

  const { showError, showNotification } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const invalidateQueries = useInvalidateListQueriesFor('company');

  const { schoolId, permissions } = useAuth();
  const canView = permissions.includes('payer_and_product_assignment_viewer');
  const canCreateAndEdit =
    permissions.includes('payer_and_product_assignment_creator') ||
    permissions.includes('payer_and_product_assignment_manager');
  const canEditCompanyStudents = permissions.includes('payer_and_product_assignment_manager');

  const createCompany = useCreateCompanyMutation();
  const updateCompany = useUpdateCompanyMutation();
  const removeCompany = useDeleteCompanyMutation();

  const { data, isFetching, isLoading } = useGetCompanyQuery(id, {
    enabled: !!id,
    refetchOnMount: 'always',
    onError: showError,
  });

  const remove = useCallback(
    async (id: string) => {
      if (!data) return;

      try {
        if (
          !(await getConfirmation({
            textId: 'companies-AreYouSureYouWantToDelete',
            textValues: {
              companyName: data.name,
            },
          }))
        )
          return;
        await removeCompany.mutateAsync({ id });
        showNotification({
          type: 'success',
          textId: 'companies-CompanyHasBeenDeleted',
          values: { companyName: data.name },
        });
        invalidateQueries();
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [data, getConfirmation, invalidateQueries, removeCompany, showError, showNotification],
  );

  const create = useCallback(
    async (company: CompanySave) => {
      if (!schoolId || !!id) return;

      try {
        const res = await createCompany.mutateAsync({
          schoolId,
          company,
        });
        if (canEditCompanyStudents && res.id) {
          navigate(`/companies/${res.id}/add-student`, { state: { replace: true } });
        } else {
          goBack();
        }
        showNotification({
          type: 'success',
          textId: 'companies-CompanyHasBeenCreated',
          values: { companyName: company.name },
        });
        invalidateQueries();
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [
      canEditCompanyStudents,
      createCompany,
      goBack,
      id,
      invalidateQueries,
      navigate,
      schoolId,
      showError,
      showNotification,
    ],
  );

  const update = useCallback(
    async (company: CompanySave) => {
      if (!id) return;

      try {
        await updateCompany.mutateAsync({ id, company });
        showNotification({
          type: 'success',
          textId: 'companies-CompanyHasBeenUpdated',
          values: { companyName: company.name },
        });
        invalidateQueries();
        goBack();
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [goBack, id, invalidateQueries, showError, showNotification, updateCompany],
  );

  const tabs = [
    { id: CompanyPreviewModalTabs.About, title: 'companies-About' },
    {
      id: CompanyPreviewModalTabs.Students,
      title: 'companies-StudentsAndProducts',
    },
    {
      id: CompanyPreviewModalTabs.PayableFees,
      title: 'profile-PayableFees',
    },
  ];

  const queryTab =
    query?.tab &&
    Object.values(CompanyPreviewModalTabs).includes(query?.tab as CompanyPreviewModalTabs)
      ? (query?.tab as CompanyPreviewModalTabs)
      : undefined;

  const activeTab = queryTab ?? tabs[0]?.id;

  return (
    <CompanyContext.Provider
      value={{
        id,
        company: data,
        isFetching,
        isLoading,
        create,
        update,
        remove,
        canCreateAndEdit,
        canEditCompanyStudents,
        canView,
        isRemoving: removeCompany.isLoading,
        isSaving: createCompany.isLoading || updateCompany.isLoading,
        tabs,
        activeTab,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => {
  return useContext(CompanyContext);
};
