import { Icon, Tooltip } from '@mui/material';
import { Attention2Icon } from '@schooly/style';
import { FC, ReactNode } from 'react';

type SameAgeGroupWarningProps = {
  tooltipLabel: ReactNode;
};

export const SameAgeGroupWarning: FC<SameAgeGroupWarningProps> = ({ tooltipLabel }) => {
  return (
    <Tooltip
      title={tooltipLabel}
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            padding: theme.spacing(1.25),
          }),
        },
      }}
    >
      <Icon
        sx={(theme) => ({
          display: 'flex',
          '&&': {
            color: 'warning.main',
            fontSize: theme.spacing(2.5),
            '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
          },
        })}
      >
        <Attention2Icon />
      </Icon>
    </Tooltip>
  );
};
