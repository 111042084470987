import { Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, DEFAULT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import {
  addMonths,
  eachDayOfInterval,
  format,
  isBefore,
  isSameDay,
  isToday,
  isValid,
  isWithinInterval,
  max,
  min,
  parse,
} from 'date-fns';
import { FC, useCallback, useEffect, useRef } from 'react';

import {
  AnnualPlannerCalendarCell,
  AnnualPlannerCalendarDateTooltip,
  AnnualPlannerCalendarGrid,
} from '../AnnualPlannerCalendar.styled';
import { useAnnualPlannerCreateRecord } from '../AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import { ANNUAL_PLANNER_DAY_CELL_WIDTH, AnnualPlannerCalendarWithDates } from '../scheme';
import { useAnnualPlannerGrid } from '../useAnnualPlannerGrid';
import { getAnnualPlannerCell } from '../utils';
import { AnnualPlannerMainGridLayoutSelectedCell } from './AnnualPlannerMainGridLayoutSelectedCell';

export interface AnnualPlannerMainGridLayoutProps extends AnnualPlannerCalendarWithDates {}

export const AnnualPlannerMainGridLayout: FC<AnnualPlannerMainGridLayoutProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { start, end, cols, months, days } = useAnnualPlannerGrid(props);
  const {
    isOpen,
    date: selectedDate,
    type: selectedType,
    record,
    open,
    openParamsRef,
    setOpenParams,
  } = useAnnualPlannerCreateRecord();

  const resetHighlighters = useCallback(() => {
    ref.current
      ?.querySelectorAll<HTMLDivElement>('.AnnualPlannerCalendar-MainGrid-highlight')
      .forEach((highlighter) => {
        highlighter.classList.remove(
          'AnnualPlannerCalendar-MainGrid-highlight-noTitle',
          'AnnualPlannerCalendar-MainGrid-highlight-start',
          'AnnualPlannerCalendar-MainGrid-highlight-end',
        );
        highlighter.style.display = 'none';
      });
  }, []);

  useEffect(() => {
    const onMouseOver = (event: MouseEvent) => {
      if (!ref.current || !openParamsRef.current?.[1]?.[0]) {
        return;
      }

      // the grid cell might be behind other element
      const cell = document
        .elementsFromPoint(event.x, event.y)
        .find((element) => element.classList.contains('AnnualPlannerCalendar-MainGrid-cell'));

      if (!cell) {
        return;
      }

      const date = parse(cell.getAttribute('data-date') ?? '', DEFAULT_DATE_FORMAT_FNS, new Date());

      if (!isValid(date)) {
        return;
      }

      const start = min([openParamsRef.current[1][0], date]);
      const end = max([openParamsRef.current[1][0], date]);

      resetHighlighters();

      eachDayOfInterval({ start, end }).forEach((date, index, arr) => {
        const highlighter = ref.current?.querySelector<HTMLDivElement>(
          `.AnnualPlannerCalendar-MainGrid-cell[data-date="${format(
            date,
            DEFAULT_DATE_FORMAT_FNS,
          )}"] .AnnualPlannerCalendar-MainGrid-highlight`,
        );

        if (highlighter) {
          if (index > 0 && index < arr.length - 1) {
            // excluding first and last cells which should stay with their title
            highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-noTitle');
          }

          if (index === 0) {
            highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-start');
          }

          if (index === arr.length - 1) {
            highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-end');
          }

          highlighter.style.display = 'block';
        }
      });
    };

    const onMouseUp = (event: MouseEvent) => {
      if (!openParamsRef.current) {
        return;
      }

      // the grid cell might be behind other element
      const cell = document
        .elementsFromPoint(event.x, event.y)
        .find((element) => element.classList.contains('AnnualPlannerCalendar-MainGrid-cell'));

      if (!cell || !openParamsRef.current[1]) {
        setOpenParams(undefined);
        resetHighlighters();
        return;
      }

      const date = parse(cell.getAttribute('data-date') ?? '', DEFAULT_DATE_FORMAT_FNS, new Date());

      if (!isValid(date)) {
        setOpenParams(undefined);
        resetHighlighters();
        return;
      }

      if (isBefore(date, openParamsRef.current[1][0])) {
        // should open a popover under the earliest date, instead of from what
        // the selection has started
        open(cell as HTMLDivElement, [date, openParamsRef.current[1][0]]);
      } else {
        open(openParamsRef.current[0], [openParamsRef.current[1][0], date]);
      }

      setOpenParams(undefined);
      resetHighlighters();
    };

    document.addEventListener('mouseover', onMouseOver);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseover', onMouseOver);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [open, openParamsRef, resetHighlighters, setOpenParams]);

  return (
    <AnnualPlannerCalendarGrid
      ref={ref}
      className={[
        AnnualPlannerCalendarGrid.defaultProps?.className ?? '',
        'AnnualPlannerCalendar-MainGrid-root',
      ].join(' ')}
      sx={(theme) => ({
        borderTop: theme.mixins.borderValue(),
        borderLeft: theme.mixins.borderValue(),
        gridTemplateColumns: `${ANNUAL_PLANNER_DAY_CELL_WIDTH}px repeat(${cols}, 1fr)`,
      })}
    >
      <AnnualPlannerCalendarCell
        sx={(theme) => ({
          borderRight: theme.mixins.borderValue(),
          borderBottom: theme.mixins.borderValue(),
        })}
      >
        {/* top-left empty cell */}
      </AnnualPlannerCalendarCell>

      {months.map((month) => {
        return (
          <AnnualPlannerCalendarCell
            key={`header-col-${month.getMonth()}-${month.getFullYear()}`}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
            })}
          >
            {format(month, 'MMM')}
          </AnnualPlannerCalendarCell>
        );
      })}

      {days.map((day) => (
        <>
          <AnnualPlannerCalendarCell
            key={`col-date-${day}`}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
            })}
          >
            <Typography variant="caption">{day}</Typography>
          </AnnualPlannerCalendarCell>

          {months.map((month, index) => {
            const { date, hasDate, isFirstDate, isWeekEnd, isSunday } = getAnnualPlannerCell({
              month,
              day,
              start,
              end,
            });

            // there might be no neighbour date to the right,
            // in this case don't need to render a right border
            const hasNeighbourDate =
              index < months.length - 1
                ? getAnnualPlannerCell({ month: addMonths(month, 1), day, start, end })?.hasDate
                : undefined;

            const content = (
              <Stack flexDirection="row" height="100%">
                {hasDate && (
                  <>
                    <Typography
                      component={Stack}
                      alignItems="center"
                      justifyContent="center"
                      variant="caption"
                      color="text.secondary"
                      sx={(theme) => ({
                        flex: '0 0 auto',
                        width: ANNUAL_PLANNER_DAY_CELL_WIDTH,
                        height: '100%',
                        borderRight: theme.mixins.borderValue(),
                        cursor: 'default',
                        background: isToday(date) ? theme.palette.primary.main : undefined,
                        color: isToday(date) ? theme.palette.primary.contrastText : undefined,
                      })}
                    >
                      {format(date, 'EEEEE')}
                    </Typography>

                    {!isOpen && <AnnualPlannerMainGridLayoutSelectedCell date={date} highlight />}

                    {isOpen &&
                      !record &&
                      selectedDate?.[0] &&
                      isWithinInterval(date, {
                        start: selectedDate[0],
                        end: selectedDate[1] || selectedDate[0],
                      }) && (
                        <AnnualPlannerMainGridLayoutSelectedCell
                          date={date}
                          type={selectedType}
                          start={isSameDay(date, selectedDate[0])}
                          end={!selectedDate[1] || isSameDay(date, selectedDate[1])}
                        />
                      )}
                  </>
                )}
              </Stack>
            );

            return (
              <AnnualPlannerCalendarCell
                key={format(date, DEFAULT_FORMATTED_DATE_FORMAT_FNS)}
                data-date={format(date, DEFAULT_DATE_FORMAT_FNS)}
                className={[
                  AnnualPlannerCalendarCell.defaultProps?.className ?? '',
                  'AnnualPlannerCalendar-MainGrid-cell',
                  hasDate
                    ? 'AnnualPlannerCalendar-MainGrid-withDate'
                    : 'AnnualPlannerCalendar-MainGrid-empty',
                ].join(' ')}
                sx={(theme) => ({
                  borderTop:
                    isFirstDate && start.getDate() > 1 ? theme.mixins.borderValue() : undefined,
                  borderRight: hasDate || hasNeighbourDate ? theme.mixins.borderValue() : undefined,

                  ...(hasDate && {
                    borderBottom: theme.mixins.borderValue(),
                    borderBottomColor: isSunday ? theme.palette.common.light3 : undefined,
                    backgroundColor: isWeekEnd ? theme.palette.background.default : undefined,
                  }),
                })}
                onMouseDown={(event) => {
                  if (!openParamsRef) {
                    return;
                  }

                  const cell = (event.target as HTMLElement).closest<HTMLDivElement>(
                    '.AnnualPlannerCalendar-MainGrid-cell',
                  );

                  setOpenParams([cell, [date, date]]);

                  resetHighlighters();

                  const highlighter = cell?.querySelector<HTMLDivElement>(
                    '.AnnualPlannerCalendar-MainGrid-highlight',
                  );

                  if (highlighter) {
                    highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-start');
                    highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-end');
                    highlighter.style.display = 'block';
                  }
                }}
              >
                {hasDate && !isOpen ? (
                  <AnnualPlannerCalendarDateTooltip
                    title={format(date, DEFAULT_FORMATTED_DATE_FORMAT_FNS)}
                  >
                    {content}
                  </AnnualPlannerCalendarDateTooltip>
                ) : (
                  content
                )}
              </AnnualPlannerCalendarCell>
            );
          })}
        </>
      ))}
    </AnnualPlannerCalendarGrid>
  );
};
