import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Event, EventsStatuses, FilterKeys } from '@schooly/api';
import { RichText } from '@schooly/components/form-rich-text';
import { RecurrenceInfo } from '@schooly/components/recurring';
import {
  CrossIcon,
  EditIcon,
  IncompleteSvg,
  LockIcon,
  ModalContent,
  ModalMain,
  ReadMore,
} from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ConsentFormPreview } from '../../../components/common/ConsentForms/ConsentFormPreview';
import { ModalAccessDenied } from '../../../components/uikit-components/Modal/ModalAccessDenied';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import { useEvent } from '../../../context/events/WithEvent';
import { useRouter } from '../../../context/router/useRouter';
import { getEventDate } from '../EventsGrid';
import { DateTimeContent } from './DateTimeContent';
import { EventPreviewActions } from './EventPreviewActions';
import { EventPreviewCriteria } from './EventPreviewCriteria';
import { EventPreviewInvitees } from './EventPreviewInvitees';
import { EventPreviewSignUps } from './EventPreviewSignUps';

interface EventPreviewContentProps {
  event: Event;
}

export const EventPreviewContent: FC<EventPreviewContentProps> = ({ event }) => {
  const { closeAndClean } = useRouter();
  const { canEdit, canView } = useEvent();
  const { $t } = useIntl();
  const navigate = useNavigate();

  const isIncomplete = event.event_type === 'default' && !event.description;

  const handleEditButtonClick = useCallback(() => {
    navigate(`/events/${event.id}/edit`);
  }, [event.id, navigate]);

  const singleTime = event.date_times.length === 1;
  const recurringState = event.recurring_state;

  const consentForm = event.consent_form ?? event.sign_ups?.[0]?.consent_form;

  const tooltipTitle = useMemo(() => {
    if (event.event_type === 'period') return 'events-SystemEventDescription';
    return event.event_status === EventsStatuses.Canceled
      ? 'events-readonly-Canceled'
      : 'events-readonly-Passed';
  }, [event.event_status, event.event_type]);

  if (!canView) {
    return <ModalAccessDenied />;
  }

  return (
    <>
      <ModalHeaderV2
        withGoBack
        active
        title={
          <Stack direction="row" gap={0.5}>
            {event.title}
            {recurringState && (
              <RecurrenceInfo
                recurringState={recurringState}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    {
                      pathname: '/events',
                    },
                    { state: { [FilterKeys.RecurrenceId]: recurringState.recurrence_id } },
                  );
                }}
              />
            )}
          </Stack>
        }
        subHeader={
          <Stack direction="row" gap={5}>
            <Typography>{getEventDate(event)}</Typography>
          </Stack>
        }
      >
        <EventPreviewActions event={event} isIncomplete={isIncomplete} />

        {canEdit && (
          <IconButton onClick={handleEditButtonClick}>
            <EditIcon />
          </IconButton>
        )}

        {(event.event_status === EventsStatuses.Past ||
          event.event_status === EventsStatuses.Canceled ||
          event.event_type === 'period') && (
          <Tooltip
            title={$t({
              id: tooltipTitle,
            })}
          >
            <IconButton inverse>
              <LockIcon />
            </IconButton>
          </Tooltip>
        )}

        <IconButton onClick={closeAndClean}>
          <CrossIcon />
        </IconButton>
      </ModalHeaderV2>
      <ModalMain>
        {isIncomplete ? (
          <Stack alignItems="center" justifyContent="center" gap={2.5} width="100%">
            <IncompleteSvg />
            <Typography
              variant="h3"
              color="text.primary"
              sx={{ textAlign: 'center', maxWidth: 300 }}
            >
              {$t({ id: 'events-NotConfigured' })}
            </Typography>
            {canEdit && (
              <Button
                startIcon={<EditIcon />}
                onClick={() => navigate('edit', { state: { replace: true } })}
              >
                {$t({ id: 'action-ContinueSetup' })}
              </Button>
            )}
          </Stack>
        ) : (
          <ModalContent
            sx={(theme) => ({
              pt: theme.spacing(2.5),
              [theme.breakpoints.down('lg')]: {
                p: theme.spacing(4, 3, 3),
              },
              height: '100%',
              flex: 1,
            })}
          >
            <Stack gap={2}>
              {event.invitee_type && <EventPreviewInvitees invitee_type={event.invitee_type} />}
              <EventPreviewCriteria criteria={event.criteria} />

              {!!event.date_times.length && (
                <Stack pt={2}>
                  <Typography variant="h2">
                    {$t({ id: singleTime ? 'events-Time' : 'events-dateAndTime' })}
                  </Typography>

                  <Stack gap={2} pt={2}>
                    <DateTimeContent event={event} />
                  </Stack>
                </Stack>
              )}
              {event.event_type === 'default' && (
                <Stack pt={2}>
                  <Typography variant="h2">{$t({ id: 'event-descriptionPlaceholder' })}</Typography>
                  <Box pt={2}>
                    <ReadMore text={event.description ?? ''} rows={3}>
                      <RichText value={event.description ?? ''} readOnly />
                    </ReadMore>
                  </Box>
                </Stack>
              )}

              <Stack pt={2} gap={2.75}>
                <EventPreviewSignUps event={event} />
                {consentForm && (
                  <ConsentFormPreview
                    consentFormShort={consentForm}
                    label={$t({ id: 'consentForms-title' })}
                  />
                )}
              </Stack>
            </Stack>
          </ModalContent>
        )}
      </ModalMain>
    </>
  );
};
