import { Avatar, Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { usePrivateImage } from '@schooly/hooks/use-private-image';
import { CrossIcon, Loading, ModalFooter, ModalHeader, ModalPanel } from '@schooly/style';
import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { IModalButton } from '../../ui/Modal';
import { getButtonIcon } from './helpers';

export type InviteContentIconType = 'tick' | 'arrow';

interface InviteContentProps {
  title?: React.ReactNode;
  headerTitle?: string | null;
  confirmTextId?: string;
  cancelTextId?: string;
  onlyCancelButton?: boolean;
  icon?: InviteContentIconType;
  imageSrc?: string;
  onClose: VoidFunction;
  onConfirm?: VoidFunction;
}

export const InviteContent: FC<PropsWithChildren<InviteContentProps>> = ({
  children,
  title,
  headerTitle,
  confirmTextId = 'action-Okay',
  cancelTextId = 'action-Cancel',
  onlyCancelButton,
  icon,
  imageSrc,
  onClose,
  onConfirm,
}) => {
  const { image, isLoading } = usePrivateImage(imageSrc);

  const footerButtons: IModalButton[] = onlyCancelButton
    ? [
        {
          textId: confirmTextId,
          color: 'primary',
          onClick: onClose,
          ...getButtonIcon(icon),
        },
      ]
    : [
        {
          textId: cancelTextId,
          color: 'secondary',
          onClick: onClose,
        },
        {
          textId: confirmTextId,
          color: 'primary',
          onClick: onConfirm,
          ...getButtonIcon(icon),
        },
      ];

  return (
    <>
      {headerTitle && (
        <ModalHeader
          active
          sx={{
            py: 1.5,
          }}
          withBorderBottom={false}
          title={
            <Stack direction="row" alignItems="center" gap={1.25}>
              <Box
                sx={(theme) => ({
                  width: theme.spacing(5),
                  height: theme.spacing(5),
                })}
              >
                {isLoading ? (
                  <Skeleton variant="circular" />
                ) : (
                  <Avatar
                    src={image ?? ''}
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
              </Box>

              <Typography variant="h2">{headerTitle}</Typography>
            </Stack>
          }
        >
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
      )}
      <ModalPanel active>
        {title && <Typography variant="h1">{title}</Typography>}
        {children}
      </ModalPanel>
      <ModalFooter
        active
        withBorderTop={false}
        sx={{
          pt: 1.5,
          pb: 1.25,
          gap: 1.5,
          pr: 2.5,
        }}
      >
        {footerButtons.map((button, i) => (
          <Button
            key={button.textId}
            startIcon={button.icon}
            endIcon={button.isLoading ? <Loading /> : button.rightIcon}
            onClick={button.onClick}
            disabled={button.isDisabled}
            variant={footerButtons.length > 1 && i === 0 ? 'outlined' : undefined}
            testId={button.testId}
          >
            <FormattedMessage id={button.textId} />
          </Button>
        ))}
      </ModalFooter>
    </>
  );
};
