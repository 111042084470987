import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { useSchool } from '../../hooks/useSchool';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices/userRolesSlice';
import { UserRolesForSchoolContext } from './UserRolesForSchoolContext';

export const WithUserRolesForSchool: FC<PropsWithChildren> = ({ children }) => {
  const { schoolId } = useSchool();
  const dispatch = useAppDispatch();

  const { roles, isLoading, error } = useAppSelector((state) => state.userRoles.userRoles);

  const [init, setInit] = useState(false);

  const loadUserRoles = useCallback(() => {
    if (!schoolId) {
      return;
    }
    setInit(true);
    dispatch(actions.getUserRoles({ schoolId }));
  }, [schoolId, dispatch]);

  useEffect(() => {
    if (init) {
      return;
    }
    loadUserRoles();
  }, [init, loadUserRoles]);

  const value = {
    error,
    loadUserRoles,
    userRoles: roles,
    isLoading: isLoading || !init,
  };

  return (
    <UserRolesForSchoolContext.Provider value={value}>
      {children}
    </UserRolesForSchoolContext.Provider>
  );
};
