import { NewAdult } from '@schooly/api';
import { convertCustomFieldsToApplicationCreateCustomFields } from '@schooly/utils/application-helpers';

import { CreateAdultForm } from './CreateAdultModal';

export const converCreateAdultFormToNewAdult = (v: CreateAdultForm): NewAdult => {
  const {
    email,
    last_name,
    given_name,
    gender,
    other_languages_spoken,
    language,
    date_of_birth,
    preferred_name,
    title,
    country,
    telephone,
    address_line_1,
    address_line_2,
    city,
    region,
    zip_code,
    custom_fields,
  } = v;

  const [nationality, ...other_nationalities] = v.nationalities;

  const customFields = convertCustomFieldsToApplicationCreateCustomFields(custom_fields);

  return {
    known_as: preferred_name,
    date_of_birth: date_of_birth || undefined,
    email: email || undefined,
    last_name,
    given_name,
    gender,
    nationality,
    other_nationalities,
    language,
    other_languages_spoken,
    title,
    telephone,
    address_line_1,
    address_line_2,
    city,
    region,
    country,
    zip_code,
    custom_fields_values: customFields,
  };
};
