import { styled } from '@mui/material';

import { GridCell } from '../../../components/uikit-components/Grid/Grid';

interface PermissionGridCellProps {
  disabled?: boolean;
}

export const PermissionGridCell = styled(GridCell)<PermissionGridCellProps>(({ theme }) => ({
  '&:not(:last-child)': {
    borderRight: theme.mixins.borderValue(),
  },
}));
