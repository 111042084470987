import { Typography } from '@mui/material';
import { Recurrence } from '@schooly/api';
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandedSelect } from '../../ExpandedSelect';
import { SelectContentSkeleton } from '../../SelectContentSkeleton';
import { SelectSearchInput } from '../../SelectSearchInput';
import { RecurrenceSelectRow } from '../RecurrenceSelectRow';

type RecurrenceExpandedSelectProps = {
  schoolId: string;
  selectedId: string;
  onSelectRecurrence: (v: string) => void;
  onClear: () => void;
  onClose: () => void;
  recurrences: Recurrence[];
  isLoading: boolean;
  hasNextPage?: boolean;
  handleChangeQuery: (q: string) => void;
  fetchNextPage: () => Promise<any>;
  query?: string;
  renderLabel: (title: string) => ReactNode;
};
export const RecurrenceExpandedSelect: FC<RecurrenceExpandedSelectProps> = ({
  selectedId,
  onSelectRecurrence,
  onClose,
  onClear,
  recurrences,
  isLoading,
  hasNextPage,
  handleChangeQuery,
  fetchNextPage,
  query,
  renderLabel,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedOptionTitle, setSelectedOptionTitle] = useState('');

  const hasOptions = Boolean(recurrences.length);

  const renderContent = useCallback(() => {
    if (!hasOptions) {
      if (isLoading || hasNextPage) {
        return <SelectContentSkeleton />;
      }
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );
    }

    return (
      <>
        {recurrences.map((recurrence) => (
          <RecurrenceSelectRow
            onClick={(id) => {
              onClose();
              onSelectRecurrence(id);
              handleChangeQuery('');
              setSelectedOptionTitle(recurrence.title);
            }}
            key={recurrence.id}
            recurrence={recurrence}
            isSelected={selectedId === recurrence.id}
          />
        ))}
      </>
    );
  }, [
    handleChangeQuery,
    hasNextPage,
    hasOptions,
    isLoading,
    onClose,
    onSelectRecurrence,
    recurrences,
    selectedId,
  ]);

  const inputContent = useMemo(() => {
    if (!selectedId) {
      return (
        <SelectSearchInput
          ref={inputRef}
          autoFocus
          value={query || ''}
          onChangeText={handleChangeQuery}
          disabled={Boolean(selectedId)}
        />
      );
    }

    return renderLabel(selectedOptionTitle);
  }, [handleChangeQuery, query, renderLabel, selectedId, selectedOptionTitle]);

  return (
    <ExpandedSelect
      hasSelectedValue={Boolean(selectedId)}
      renderContent={renderContent}
      onClose={onClose}
      onClear={onClear}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading}
      hasNextPage={hasNextPage}
      onFetchNextPage={fetchNextPage}
      width={500}
      showNextPageLoading={hasOptions}
    >
      {inputContent}
    </ExpandedSelect>
  );
};
