import React from 'react';

import { useAppSelector } from '../../../redux/hooks';
import {
  FilterSchoolRelationTags,
  FilterSchoolRelationTagsBaseProps,
} from './FilterSchoolRelationTags';

import './FilterSchoolRelationTags.scss';

export const FilterStaffTags: React.FC<FilterSchoolRelationTagsBaseProps> = (props) => {
  const { results, savedSelectedOptions = [] } = useAppSelector((state) => state.simpleLists.staff);

  return (
    <FilterSchoolRelationTags
      {...props}
      results={results}
      savedSelectedOptions={savedSelectedOptions}
    />
  );
};
