import { IconButton, Skeleton, Stack, styled, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { DATE_FORMAT, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import {
  CrossIcon,
  EditIcon,
  ModalHeader,
  ModalHeaderProps,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ReportForm } from '../../../context/report/WithReportEditContext';
import { InitiallyFocusedInput } from './ReportCreateModalForm';

type FormHeaderProps = {
  form: UseFormReturn<ReportForm>;
  onClose: () => void;
  onEdit?: (v: InitiallyFocusedInput | undefined) => void;
  onEditAssessments?: () => void;
} & ModalHeaderProps;
export const ReportCreateModalHeader: FC<FormHeaderProps> = ({
  form,
  onClose,
  onEdit,
  onEditAssessments,
  ...rest
}) => {
  const { schoolId = '' } = useAuth();
  const { $t } = useIntl();

  const name = form.watch('name');
  const ageGroupIds = form.watch('age_group_ids');
  const subjectIds = form.watch('subject_ids');
  const areas = form.watch('areas_of_learning');
  const scheduledPublishDate = form.watch('scheduled_publish_date');
  const additionalAssessments = form.watch('assessments');

  const { ageGroups, isLoading } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const renderInfo = () => {
    if (isLoading)
      return (
        <>
          {[...new Array(4)].map((_, i) => (
            <Skeleton key={i} width={100} variant="text" />
          ))}
        </>
      );

    return (
      <>
        <InlineEditableRow onClick={() => onEdit?.('scheduled_publish_date')}>
          <TypographyWithOverflowHint>
            {format(newDateTimezoneOffset(scheduledPublishDate), DATE_FORMAT)}
          </TypographyWithOverflowHint>
          {onEdit && (
            <IconButton>
              <EditIcon />
            </IconButton>
          )}
        </InlineEditableRow>
        <TypographyWithOverflowHint color="text.primary" mr={onEdit ? 3 : 0}>
          {ageGroups
            .filter((a) => ageGroupIds.includes(a.id))
            .map((a) => a.name)
            .join(' + ')}
        </TypographyWithOverflowHint>
        {!!subjectIds.length && (
          <InlineEditableRow onClick={() => onEdit?.('subject_ids')}>
            <TypographyWithOverflowHint>
              {subjectIds.length} {$t({ id: 'section-Subjects' })}
            </TypographyWithOverflowHint>
            {onEdit && (
              <IconButton>
                <EditIcon />
              </IconButton>
            )}
          </InlineEditableRow>
        )}
        {!!areas.length && (
          <InlineEditableRow onClick={() => onEdit?.(undefined)}>
            <TypographyWithOverflowHint>
              {areas.length} {$t({ id: 'reports-AreasOfLearning' })}
            </TypographyWithOverflowHint>
            {onEdit && (
              <IconButton>
                <EditIcon />
              </IconButton>
            )}
          </InlineEditableRow>
        )}
        {onEditAssessments && !!additionalAssessments.length && (
          <InlineEditableRow onClick={onEditAssessments}>
            <TypographyWithOverflowHint>
              {additionalAssessments.length} {$t({ id: 'reports-AdditionalAssessments' })}
            </TypographyWithOverflowHint>
            {onEdit && (
              <IconButton>
                <EditIcon />
              </IconButton>
            )}
          </InlineEditableRow>
        )}
      </>
    );
  };

  return (
    <ModalHeader
      sx={(theme) => ({ paddingBottom: theme.spacing(1.5) })}
      title={
        <Stack mt={-0.5}>
          <InlineEditableRow onClick={() => onEdit?.('name')}>
            <Typography variant="h2" color="primary.main">
              {name}
            </Typography>
            {onEdit && (
              <IconButton>
                <EditIcon />
              </IconButton>
            )}
          </InlineEditableRow>
          <Stack flexDirection="row" gap={onEdit ? 2 : 5}>
            {renderInfo()}
          </Stack>
        </Stack>
      }
      {...rest}
    >
      <IconButton onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </ModalHeader>
  );
};

const InlineEditableRow = styled(Stack)(({ theme, onClick }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
  cursor: onClick ? 'pointer' : undefined,
  '.MuiTypography-body1': {
    color: theme.palette.text.primary,
  },
  '&:not(:hover) .MuiIconButton-root': {
    opacity: 0,
  },
  '&:hover .MuiTypography-body1': {
    color: onClick ? theme.palette.primary.main : theme.palette.text.primary,
  },
}));
