import { Box, Button, Icon, IconButton, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { SchoolPeriodsRangeSelect, SearchInput } from '@schooly/components/filters';
import { DATE_FORMAT } from '@schooly/constants';
import { CalendarIcon, ChartIcon } from '@schooly/style';
import { format } from 'date-fns';
import React, { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ModalPanel } from '../../../components/uikit-components/Modal/Modal.styled';
import { GroupAttendanceStatisticsMode } from '../../../context/groups/GroupAttendanceStatisticsContext';
import { useGroupAttendanceStatistics } from '../../../context/groups/useGroupAttendanceStatistics';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { AttendanceStats } from '../../Attendance/AttendanceStatistics/AttendanceStats';
import { AttendanceStatWithBorderWrapper } from '../../Attendance/AttendanceStatistics/AttendanceStats.styled';
import { AttendanceStatsPieChart } from '../../Attendance/AttendanceStatistics/AttendanceStatsPieChart';
import { GroupAttendanceStatisticsModalToggleShowByPresentAbsent } from './GroupAttendanceStatisticsModalToggleShowByPresentAbsent';

export const GroupAttendanceStatisticsModalTopPanel: FC = () => {
  const { $t } = useIntl();
  const form = useForm();
  const {
    query,
    validity,
    mode,
    actions,
    statistics,
    statisticsLoading,
    showByPresentAbsent,
    showByPercent,
  } = useGroupAttendanceStatistics();

  const [showStats, setShowStats] = useState(false);
  const { schoolId, permissions } = useAuth();
  const isAdmin = permissions.includes('school_admin');
  const { defaultValidity } = useSchoolYears();

  const hasStatistics = !!statistics?.statistic?.length;
  const hasStatisticsContent = hasStatistics || statisticsLoading;

  const handleChangeDate = useCallback(
    (dates: Date[]) => {
      const [start, end] = dates;
      if (!start && !end) return;
      actions.setValidity({
        date: [format(start, DEFAULT_DATE_FORMAT_FNS), format(end, DEFAULT_DATE_FORMAT_FNS)],
      });
    },
    [actions],
  );

  return (
    <>
      <ModalPanel sx={{ py: 1, overflow: 'unset' }} withBorderBottom active>
        <Stack direction="row" gap={1} width="100%">
          <Box sx={{ flex: '1 1 auto', '& .SearchInput': { margin: 0 } }}>
            <SearchInput
              value={query}
              onChangeText={actions.setQuery}
              placeholder={$t(
                { id: 'groups-AvailableUsersSearch' },
                {
                  userType:
                    mode === GroupAttendanceStatisticsMode.REGISTERS ? 'registers' : 'students',
                },
              )}
            />
          </Box>

          <Box
            sx={{
              flex: '0 1 auto',
            }}
          >
            <GroupAttendanceStatisticsModalToggleShowByPresentAbsent />
          </Box>

          <Box sx={{ flex: '1 0 150px' }}>
            <FormProvider {...form}>
              <SchoolPeriodsRangeSelect
                schoolId={schoolId ?? ''}
                date={validity?.date ?? []}
                onSetDate={handleChangeDate}
                hasManagePermission={isAdmin}
                defaultSchoolYear={defaultValidity}
                renderCustomHeader={({ fromDate, toDate }) => (
                  <Stack flexDirection="row" gap={1}>
                    <Icon sx={(theme) => ({ color: theme.palette.common.grey })}>
                      <CalendarIcon />
                    </Icon>
                    <Typography noWrap variant="h3">
                      {fromDate && format(fromDate, DATE_FORMAT)}
                      {!!fromDate && !!toDate && ` – `}
                      {toDate && format(toDate, DATE_FORMAT)}
                    </Typography>
                  </Stack>
                )}
              />
            </FormProvider>
          </Box>

          <Button
            variant="outlined"
            disabled={!hasStatistics}
            sx={(theme) => ({
              borderColor: theme.palette.common.light3,
              minWidth: 0,
              width: theme.spacing(5.5),
            })}
            onClick={() => {
              setShowStats((cur) => !cur);
            }}
          >
            <IconButton
              sx={{
                color: showStats && hasStatistics ? 'primary' : 'text.secondary',
                pointerEvents: 'none',
              }}
            >
              <ChartIcon />
            </IconButton>
          </Button>
        </Stack>
      </ModalPanel>

      {showStats && hasStatisticsContent && (
        <AttendanceStatWithBorderWrapper sx={{ minHeight: 168 }}>
          <AttendanceStats
            showByPresentAbsent={showByPresentAbsent}
            showPercents={showByPercent}
            statistics={statistics?.statistic ?? []}
            loading={statisticsLoading}
            rowsPerColumnCount={3}
            precisionForPercent={1}
            totalCount={statistics?.register_count}
            renderChart={(data, total) => (
              <Box sx={{ height: 126 }}>
                <AttendanceStatsPieChart
                  data={data}
                  total={total}
                  labelCountText={$t({ id: 'schoolDays' }).toLowerCase()}
                />
              </Box>
            )}
          />
        </AttendanceStatWithBorderWrapper>
      )}
    </>
  );
};
