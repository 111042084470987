import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1813_16690)">
      <path
        d="M4.79945 13.5426C2.88912 12.4397 2.23242 9.98887 3.33535 8.07854L6.13535 3.2288C6.28281 2.97339 6.60895 2.88643 6.86385 3.0336C7.11884 3.18082 7.2066 3.50674 7.05914 3.76215L4.25914 8.61189C3.4506 10.0123 3.93194 11.8101 5.3328 12.6189C6.73367 13.4276 8.53122 12.9456 9.33977 11.5452L12.2731 6.46448C12.7878 5.57309 12.4813 4.42931 11.5899 3.91466C10.6984 3.39996 9.55463 3.70644 9.03998 4.59783L6.37328 9.21669C6.15278 9.59862 6.28409 10.089 6.6661 10.3095C7.04812 10.5301 7.53842 10.3986 7.75892 10.0167L10.2923 5.6288C10.4397 5.37339 10.7659 5.28643 11.0208 5.4336C11.2758 5.58082 11.3635 5.90674 11.2161 6.16215L8.68271 10.55C8.16806 11.4414 7.0242 11.7479 6.1328 11.2332C5.24141 10.7186 4.93485 9.57474 5.44949 8.68334L8.11619 4.06448C8.92469 2.66413 10.7223 2.18203 12.1232 2.99087C13.5241 3.79966 14.0054 5.59748 13.1969 6.99783L10.2636 12.0785C9.16063 13.9889 6.70978 14.6456 4.79945 13.5426Z"
        fill="#24275B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1813_16690">
        <rect width="12.8" height="12.8" fill="white" transform="translate(1.59961 1.6001)" />
      </clipPath>
    </defs>
  </svg>
);
