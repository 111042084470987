import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 15C3 14.4477 3.44772 14 4 14H16C16.5523 14 17 14.4477 17 15V16C17 16.5523 16.5523 17 16 17H4C3.44772 17 3 16.5523 3 16V15Z"
      fill="#24275B"
    />
    <path
      d="M3 9.5C3 8.94772 3.44772 8.5 4 8.5H16C16.5523 8.5 17 8.94772 17 9.5V10.5C17 11.0523 16.5523 11.5 16 11.5H4C3.44772 11.5 3 11.0523 3 10.5V9.5Z"
      fill="#24275B"
    />
    <path
      d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V5C17 5.55228 16.5523 6 16 6H4C3.44772 6 3 5.55228 3 5V4Z"
      fill="#24275B"
    />
  </svg>
);
