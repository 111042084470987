import { PopoverOrigin, Stack, Theme, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';

import { DropdownCommentsItem } from '../../DropdownComments/DropdownComments';
import { Comment } from './Comment';
import { CommentLayout } from './CommentLayout';

export interface CommentsProps {
  comments?: DropdownCommentsItem[];
  maxRows?: number;
  showInput?: boolean;
  canEditOwn?: boolean;
  canEditOther?: boolean;
  showAllComments?: boolean;
  onBlur?: (text: string, relationId?: string) => void;
  placement?: PopoverOrigin;
  tableView?: boolean;
  plainCommentsListOnly?: boolean;
  autoFocusOnEdit?: boolean;
  saveOnEnterPress?: boolean;
}

export const Comments: FC<CommentsProps> = ({
  comments,
  maxRows,
  showInput,
  canEditOwn,
  canEditOther,
  onBlur,
  placement,
  tableView,
  plainCommentsListOnly,
  autoFocusOnEdit,
  saveOnEnterPress,
}) => {
  const belowXlScreenWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));

  if (plainCommentsListOnly) {
    return (
      <>
        {comments?.map((comment) => (
          <Stack
            sx={(theme) => ({
              '&:not(:last-child)': {
                borderBottom: theme.mixins.borderValue(),
              },
            })}
          >
            <Comment
              canEditOwn={canEditOwn}
              canEditOther={canEditOther}
              comment={comment}
              key={comment.id}
              maxRows={maxRows}
              onBlur={onBlur}
              plainCommentOnly
            />
          </Stack>
        ))}

        {showInput && <Comment plainCommentOnly onBlur={onBlur} maxRows={maxRows} autoFocus />}
      </>
    );
  }

  return (
    <CommentLayout placement={placement} tableView={tableView}>
      {comments?.map((comment) => (
        <Stack
          sx={(theme) => ({
            '&:not(:last-child)': {
              borderBottom: theme.mixins.borderValue(),
            },
          })}
        >
          <Comment
            canEditOwn={canEditOwn}
            canEditOther={canEditOther}
            comment={comment}
            key={comment.id}
            maxRows={maxRows}
            onBlur={onBlur}
            autoFocus={autoFocusOnEdit}
            saveOnEnterPress={saveOnEnterPress}
          />
        </Stack>
      ))}

      {showInput && (
        <Comment
          onBlur={onBlur}
          maxRows={maxRows}
          autoFocus={belowXlScreenWidth ? false : true}
          saveOnEnterPress={saveOnEnterPress}
        />
      )}
    </CommentLayout>
  );
};
