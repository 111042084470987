import { GroupValidity } from '@schooly/api';
import { DATE_FORMAT_RANGE_MOMENT } from '@schooly/constants';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../config';

export default function renderGroupValidity<T extends { validity: GroupValidity }>(
  group: T,
  customDateFormat?: string,
) {
  const { from_school_year, to_school_year, date_from, date_to } = group.validity || {};

  const dateFormat = customDateFormat || DATE_FORMAT_RANGE_MOMENT;

  if (date_from && date_to) {
    const fromDate = moment(date_from, DEFAULT_DATE_FORMAT);
    const toDate = moment(date_to, DEFAULT_DATE_FORMAT);

    return fromDate.isSame(toDate)
      ? fromDate.format(dateFormat)
      : `${fromDate.format(dateFormat)} – ${toDate.format(dateFormat)}`;
  }

  if (from_school_year) {
    return !to_school_year || from_school_year.id === to_school_year.id
      ? from_school_year.name
      : `${from_school_year.name}–${to_school_year.name}`;
  }

  return '';
}
