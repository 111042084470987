import { BaseUser, WithGender, WithTelephone } from '@schooly/api';
import { CreateParentForm } from '@schooly/components/applications';
import { usePrevious } from '@schooly/hooks/use-previous';
import isEqual from 'lodash.isequal';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ApplicationRouteState } from '../../context/applications/ApplicationsContext';
import { useAppDispatch } from '../../redux/hooks';
import { actions as applicationActions } from '../../redux/slices/applicationSlice';

export const useApplicationFormParentUpdate = (parent?: BaseUser & WithTelephone & WithGender) => {
  const prevUser = usePrevious(parent);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const applicationRouteState: ApplicationRouteState | undefined = useMemo(
    () =>
      location.state && 'type' in location.state && location.state.type === 'application'
        ? location.state
        : undefined,
    [location.state],
  );

  useEffect(() => {
    if (!applicationRouteState || !parent || !prevUser || isEqual(parent, prevUser)) {
      return;
    }

    const currentParent = applicationRouteState.parents.find(
      ({ profile_id }) => profile_id === parent.user_id,
    );

    if (!currentParent) return;

    const { given_name, title, last_name, telephone, gender } = parent;

    const updatedParent: CreateParentForm = {
      ...currentParent,
      given_name: given_name ?? undefined,
      title: title ?? undefined,
      last_name: last_name ?? undefined,
      telephone: telephone ?? undefined,
      gender: gender ?? undefined,
    };

    dispatch(
      applicationActions.update({
        actionType: applicationRouteState.actionType,
        type: 'parents',
        entity: updatedParent,
      }),
    );
  }, [applicationRouteState, dispatch, prevUser, parent]);
};
