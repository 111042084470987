import { Stack, styled } from '@mui/material';

export const CenteredFormStack = styled(Stack)(({ theme }) => ({
  height: '100%',
  gap: theme.spacing(2.5),
  marginTop: theme.spacing(5),

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(15),
  },
}));
