import { EventsStatuses, FilterKeys, SavedFilter } from '@schooly/api';
import { EventsInvite } from '@schooly/constants';
import { isBefore, setHours, setMinutes, startOfToday } from 'date-fns';

export const EventsParticipationOptions = [
  EventsInvite.ParentsAreWelcome,
  EventsInvite.ParentsMustAttend,
  EventsInvite.StudentsOnly,
];

export const EventsStatusOptions = [
  EventsStatuses.Draft,
  EventsStatuses.Canceled,
  EventsStatuses.Past,
  EventsStatuses.Published,
];

export const getParticipantLabel = (v: EventsInvite) => {
  switch (v) {
    case EventsInvite.ParentsAreWelcome:
      return 'events-ParentsAreWelcome';
    case EventsInvite.ParentsMustAttend:
      return 'events-ParentsMustAttend';
    case EventsInvite.StudentsOnly:
      return 'events-StudentsOnly';

    default:
      throw new Error(v);
  }
};

// This adapter resolves the problem of Past status event logic between BE and FE, BE lacks Past status in ENUM.
// incoming - BE filter data
// normalized -  normalized data for FE filters
export function eventFilterAdapter(filter: SavedFilter['filter']) {
  const isPastStatus = filter?.event_status?.some((v) => v === EventsStatuses.Past);
  const eventsStatus = filter?.event_status?.filter((v) => v !== EventsStatuses.Past) ?? undefined;

  return {
    incoming: {
      ...filter,
      event_status: eventsStatus?.length ? eventsStatus : undefined,
      past_only: isPastStatus ?? undefined,
    },
    normalized: {
      ...filter,
      [FilterKeys.EventStatus]: filter.past_only
        ? [...(filter[FilterKeys.EventStatus] ?? []), EventsStatuses.Past]
        : filter[FilterKeys.EventStatus] ?? [],
    },
  };
}

export const isTimeInPast = (time: string) => {
  const [hours = 0, minutes = 0] = time.split(':').map(Number);
  const now = new Date();
  const today = startOfToday();
  const dateWithTime = setMinutes(setHours(today, hours), minutes);
  return isBefore(dateWithTime, now);
};

export const getEventsStatusLabel = (v: EventsStatuses) => {
  switch (v) {
    case EventsStatuses.Past:
      return 'events-status-Past';
    case EventsStatuses.Canceled:
      return 'events-status-Canceled';
    case EventsStatuses.Draft:
      return 'events-status-Draft';
    case EventsStatuses.Published:
      return 'events-status-Published';

    default:
      throw new Error(v);
  }
};
