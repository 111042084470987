import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00012 14.9152L3.29303 9.20807C2.90251 8.81755 2.90251 8.18438 3.29303 7.79386C3.68356 7.40333 4.31672 7.40333 4.70724 7.79386L9.00015 12.0868L15.7944 5.29286C16.1849 4.90235 16.8181 4.90236 17.2086 5.2929C17.5991 5.68343 17.5991 6.3166 17.2086 6.70711L9.00012 14.9152Z"
      fill="white"
    />
  </svg>
);
