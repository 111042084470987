import { Icon, Stack } from '@mui/material';
import { User } from '@schooly/api';
import { CheckIcon, ImageFormatIcon, ImageSizeIcon } from '@schooly/style';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getUserFullName } from '../../../helpers/users';
import Button from '../../ui/Button';
import { AttachmentFile } from '../../ui/Input/RichText/Attachments/attachment-types';
import Modal from '../../ui/Modal';

import './index.scss';

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

interface AvatarEditModalProps {
  onClose: () => void;
  handleChange: () => void;
  isOpen: boolean;
  isUploading: boolean;
  user?: User;
  file?: AttachmentFile;
  setFile: (file?: AttachmentFile) => void;
}

const AvatarEditModal = ({
  onClose,
  isOpen,
  user,
  file,
  setFile,
  handleChange,
  isUploading,
}: AvatarEditModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const { formatMessage } = useIntl();

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onDragLeave = () => {
    setIsDragActive(false);
  };

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';

    setIsDragActive(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(false);

    if (e.dataTransfer) {
      const { files } = e.dataTransfer;

      if (files && files[0]) {
        const firstFile = files[0];

        setFile({
          id: `${firstFile.name}${Math.floor(Math.random() * 100)}`,
          file: firstFile,
        });
      }
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget) {
      const { files } = e.currentTarget;

      if (files && files[0]) {
        const firstFile = files[0];
        setFile({
          id: `${firstFile.name}${Math.floor(Math.random() * 100)}`,
          file: firstFile,
        });
      }
    }
  };

  useEffect(() => {
    if (file?.file && imageRef.current) {
      imageRef.current.src = window.URL.createObjectURL(file?.file);
    }
  }, [file?.file]);

  useEffect(() => {
    if (!isOpen) {
      setFile(undefined);
    }
  }, [isOpen, setFile]);

  if (!user) return null;

  return (
    <Modal onClose={onClose} isOpen={isOpen} className="PropertyModal AvatarEditModal">
      <div className="header">
        <h1>{getUserFullName(user)}</h1>
      </div>
      <div className="body">
        <div className="row mb-3">
          <div className="col-md-12">
            <div
              role="button"
              tabIndex={0}
              className={`drag ${isDragActive ? 'active' : ''}`}
              onClick={handleClick}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              onDrop={handleDrop}
            >
              {file ? (
                <img ref={imageRef} alt="prev" className="prev-image" />
              ) : (
                <div className="desc-content">
                  <h3>
                    <FormattedMessage id="peopleDetail-DragAndDropImage" />
                  </h3>
                  <span className="sm-desc">
                    {formatMessage(
                      { id: 'peopleDetail-BrowseFile' },
                      {
                        browse: (
                          <span key="browse" className="text-primary">
                            <FormattedMessage id="peopleDetail-Browse" />
                          </span>
                        ),
                      },
                    )}
                  </span>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      color: 'common.grey',
                      mt: 2.75,
                      gap: 2,
                    }}
                  >
                    <Stack direction="row" gap={1} whiteSpace="nowrap" alignItems="center">
                      <Icon fontSize="small">
                        <ImageSizeIcon />
                      </Icon>
                      <FormattedMessage id="peopleDetail-MaxSize" />
                    </Stack>
                    <Stack direction="row" gap={1} whiteSpace="nowrap" alignItems="center">
                      <Icon fontSize="small">
                        <ImageFormatIcon />
                      </Icon>
                      <FormattedMessage id="peopleDetail-ImageTypes" />
                    </Stack>
                  </Stack>
                </div>
              )}
            </div>
            <input
              ref={inputRef}
              type="file"
              accept={ALLOWED_FILE_TYPES.join(',')}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mb-2">
          <Button type="button" color="secondary" onClick={onClose}>
            <FormattedMessage id="action-Cancel" />
          </Button>

          <Button
            type="submit"
            color="primary"
            rightIcon={<CheckIcon />}
            className="ml-3"
            onClick={handleChange}
            isLoading={isUploading}
          >
            <FormattedMessage id="action-Save" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AvatarEditModal;
