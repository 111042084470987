import { ChipProps } from '@mui/material';
import { MessageStatus } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { $enum } from 'ts-enum-util';

export type MessageStatusTagSelectProps = {
  status: MessageStatus;
} & Omit<ChipProps, 'label'>;

const OPTIONS = $enum(MessageStatus).map((value, key) => ({ value, label: key }));

export const MessageStatusTagSelect: FC<MessageStatusTagSelectProps> = ({ status, ...props }) => {
  const label = OPTIONS.find((o) => o.value === status)?.label;

  return <TagSelect label={label} {...props} />;
};
