import { Typography } from '@mui/material';
import {
  GetMiniListQueryFilters,
  MINI_LIST_QUERY_FILTER_KEYS,
  SchoolRelation,
  SchoolUserType,
  SimpleListResult,
  useGetMiniListQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { pickOnlyParamsFromFilterKeys } from '../utils';
import { UserSelectRow } from './UserSelectRow';
import { UserTagSelect, UserTagSelectProps } from './UserTagSelect';

type UserExpandedSelectProps = PropsWithChildren<{
  type: SchoolUserType;
  schoolId: string;
  currentUser?: SchoolRelation;
  selectedIds: string[];
  onSelectUserId: (v: string) => void;
  onClear: () => void;
  onClose: () => void;
  filters?: GetMiniListQueryFilters;
}>;

export const UserExpandedSelect: FC<UserExpandedSelectProps> = ({
  type,
  schoolId,
  selectedIds,
  currentUser,
  onSelectUserId,
  onClose,
  onClear,
  filters = {},
}) => {
  const { formatMessage } = useIntl();
  const { permissions } = useAuth();

  const inputRef = useRef<HTMLInputElement>(null);
  const canRequestData = permissions.includes(`${type}_viewer`);

  const { data, isLoading, hasNextPage, params, setParams, isFetchingNextPage, fetchNextPage } =
    useGetMiniListQuery(
      {
        schoolId,
        type,
        query: '',
        filters: pickOnlyParamsFromFilterKeys(MINI_LIST_QUERY_FILTER_KEYS, filters),
      },
      { refetchOnMount: true, enabled: canRequestData },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(() => {
    const currentUserRow = currentUser ? (
      <UserSelectRow
        overrideName={formatMessage({ id: 'filter-Me' })}
        user={currentUser}
        isSelected={selectedIds.includes(currentUser.relation_id)}
        onClick={() => {
          onSelectUserId(currentUser.relation_id);
          handleChangeQuery('');
        }}
      />
    ) : undefined;

    if (!canRequestData) {
      return (
        <>
          {currentUserRow}
          <Typography p={1}>
            <FormattedMessage id="error-NoPermissionsToRequest" />
          </Typography>
        </>
      );
    }

    if (!data) return <SelectContentSkeleton />;

    const entries = (
      data.pages.reduce<SimpleListResult[]>((prev, curr) => [...prev, ...curr.results], []) ?? []
    ).filter((u) => u.user_id !== currentUser?.relation_id);

    if (!entries.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {currentUserRow}
        {entries.map((user) => {
          if (!user.user_id) return null;
          return (
            <UserSelectRow
              onClick={() => {
                onSelectUserId(user.relation_id);
                handleChangeQuery('');
              }}
              key={user.user_id}
              user={user}
              isSelected={selectedIds.includes(user.user_id)}
            />
          );
        })}
      </>
    );
  }, [
    currentUser,
    formatMessage,
    selectedIds,
    canRequestData,
    data,
    onSelectUserId,
    handleChangeQuery,
  ]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedIds.length > 0}
      renderContent={renderContent}
      onClose={onClose}
      onClear={onClear}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={canRequestData && hasNextPage}
      onFetchNextPage={fetchNextPage}
    >
      {renderUserTags({
        ids: selectedIds,
        onDelete: onSelectUserId,
        tagProps: {
          currentUser,
          schoolId,
          userType: type,
          sx: { maxWidth: 200 },
          size: 'small',
        },
      })}

      <SelectSearchInput
        ref={inputRef}
        autoFocus
        value={params.query || ''}
        onChangeText={handleChangeQuery}
      />
    </ExpandedSelect>
  );
};

type RenderUserTagsParams = {
  ids: string[];
  onDelete?: (v: string) => void;
  onClick?: (v: string) => void;
  tagProps: Omit<UserTagSelectProps, 'id'> | ((v: string) => Omit<UserTagSelectProps, 'id'>);
};

export const renderUserTags = ({ ids, onDelete, onClick, tagProps }: RenderUserTagsParams) =>
  ids.map((v) => (
    <UserTagSelect
      key={v}
      id={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
