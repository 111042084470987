import { Button, IconButton } from '@mui/material';
import { ApiError, createContactEmail, GET_USER_QUERY, verifyContactEmail } from '@schooly/api';
import { VerifyEmailModalContent } from '@schooly/components/authentication';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { VALID_EMAIL_REGEXP } from '@schooly/constants';
import { CheckIcon, CloseIcon, ModalContent, ModalFooter, ModalHeader, Spin } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { useProfile } from '../../../context/profile/useProfile';
import IntlError from '../../../utils/intlError';
import { PropertyModalContainer } from './PropertyModal';

interface EmailModalProps {
  onClose: () => void;
  isOpen: boolean;
  currentContactEmail: string;
}

export type EmailForm = {
  email: string;
};

const defaultValues: EmailForm = {
  email: '',
};

enum State {
  SetEmail,
  Verify,
}

export const ContactEmailModal = ({
  isOpen,
  currentContactEmail,
  onClose: onCloseModal,
}: EmailModalProps) => {
  const { $t } = useIntl();
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<null | ApiError>(null);
  const [state, setState] = useState<State>(State.SetEmail);
  const queryClient = useQueryClient();
  const { user } = useProfile();

  const form = useForm<EmailForm>({
    defaultValues,
  });

  const { watch, reset } = form;
  const email = watch('email');

  const onClose = useCallback(() => {
    onCloseModal();
    setState(State.SetEmail);
    reset();
  }, [onCloseModal, reset]);

  const handleSubmit = useCallback<SubmitHandler<EmailForm>>(
    async ({ email }) => {
      setIsUpdating(true);

      try {
        await createContactEmail(email);
        setState(State.Verify);
      } catch (err) {
        showError(err as ApiError | IntlError);
      }
      setIsUpdating(false);
    },
    [showError],
  );

  useEffect(() => {
    reset({ email: currentContactEmail });
  }, [currentContactEmail, reset]);

  const onSubmitCode = useCallback(
    async (code: string) => {
      if (!email) {
        return;
      }

      setError(null);
      setIsUpdating(true);

      try {
        const isEmailVerified = await verifyContactEmail(email, code);
        if (!isEmailVerified) return;

        queryClient.invalidateQueries([GET_USER_QUERY, user?.user_id]);
        showNotification({
          textId: 'confirmation-Email',
          type: 'success',
        });
        onClose();
      } catch (err) {
        setError(err as ApiError);
      } finally {
        setIsUpdating(false);
      }
    },
    [email, user?.user_id, onClose, showNotification, queryClient],
  );

  const onResetCodeError = useCallback(() => {
    setError(null);
  }, []);

  const onResend = useCallback(async () => {
    try {
      await createContactEmail(email);
    } catch (error) {
      showNotification({
        message: (error as ApiError).reason,
        type: 'error',
      });
    }
  }, [email, showNotification]);

  const isChanged = currentContactEmail !== email;

  if (!isOpen) return null;

  const renderContent = () => {
    switch (state) {
      case State.SetEmail:
        return (
          <>
            <ModalHeader title={user && getUserFullName(user)} withBorderBottom={false} active>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </ModalHeader>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <ModalContent active>
                  <ControlTextField
                    type="email"
                    name="email"
                    control={form.control}
                    rules={{
                      required: true,
                      pattern: {
                        value: VALID_EMAIL_REGEXP,
                        message: $t({
                          id: 'input-ErrorInvalidEmail',
                        }),
                      },
                    }}
                    label={$t({ id: 'peopleDetail-EmailAddressPersonal' })}
                    fullWidth
                  />
                </ModalContent>
                <ModalFooter active withBorderTop={false}>
                  <Button variant="outlined" onClick={onClose} disabled={isUpdating}>
                    <FormattedMessage id="action-Cancel" />
                  </Button>

                  <Button
                    endIcon={isUpdating ? <Spin /> : <CheckIcon />}
                    type="submit"
                    disabled={!isChanged || isUpdating}
                  >
                    <FormattedMessage id="action-Save" />
                  </Button>
                </ModalFooter>
              </form>
            </FormProvider>
          </>
        );
      case State.Verify:
        return (
          <VerifyEmailModalContent
            error={error?.reason || error?.message}
            isLoading={isUpdating}
            onResend={onResend}
            onSubmit={onSubmitCode}
            onResetError={onResetCodeError}
            email={email}
            submitButtonIcon={<CheckIcon />}
            submitButtonTextId="action-Save"
            onBack={() => setState(State.SetEmail)}
          />
        );

      default:
        return null;
    }
  };

  return (
    <PropertyModalContainer
      onClose={!isUpdating ? onClose : undefined}
      open
      className="PropertyModal"
    >
      {renderContent()}
    </PropertyModalContainer>
  );
};
