import { Box, Card, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl/lib';

import SchoolIncompleteImg from '../../assets/images/school_incomplete.svg';
import { SchoolPageAddPropertyButton } from './SchoolPageAddPropertyButton';

export const SchoolPageAddProperty: FC = () => {
  const { $t } = useIntl();

  return (
    <Card sx={{ height: 224, border: 'none', bgcolor: 'background.default', p: 2.5 }}>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Stack flex="0 0 250px" gap={2}>
          <Typography variant="h2" whiteSpace="pre-wrap">
            <FormattedMessage id="school-AddMoreProperties" />
          </Typography>
          <SchoolPageAddPropertyButton />
        </Stack>

        <Box flex="0 1 auto">
          <Box
            component="img"
            width="100%"
            maxHeight={178}
            src={SchoolIncompleteImg}
            alt={$t({ id: 'school-AddMoreProperties' })}
          />
        </Box>
      </Stack>
    </Card>
  );
};
