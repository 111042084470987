import { Skeleton, Stack } from '@mui/material';
import { Group, MAX_PAGE_SIZE, SORT_DIRECTION, useGetGroupsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import RoundCard from '../RoundCard';
import { ModalContent } from './Modal.styled';
import { ModalEmptyMainArea } from './ModalEmptyMainArea';

interface ModalGroupsListProps {
  date?: string;
  selectedGroupIds?: string[];
  onRemoveGroupId: (id: string) => void;
}

export const ModalGroupsList: FC<ModalGroupsListProps> = ({
  date,
  selectedGroupIds,
  onRemoveGroupId,
}) => {
  const { schoolId = '' } = useAuth();
  const { data, setParams } = useGetGroupsQuery(
    {
      schoolId,
      pageSize: MAX_PAGE_SIZE,
      singleDate: date,
      sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
      filters: {},
    },
    { refetchOnMount: 'always', enabled: !!(date && schoolId) },
  );

  useEffect(() => {
    if (!date) return;

    setParams((p) => ({ ...p, singleDate: date }));
  }, [date, setParams]);

  const groups = useMemo(
    () =>
      data?.pages.reduce<Group[]>(
        (prev, curr) => [...prev, ...curr.results.filter((g) => selectedGroupIds?.includes(g.id))],
        [],
      ) ?? [],
    [data?.pages, selectedGroupIds],
  );

  const onCardClick = useCallback(
    (g: Group) => {
      onRemoveGroupId(g.id);
    },
    [onRemoveGroupId],
  );

  const { $t } = useIntl();

  return (
    <ModalContent sx={{ pt: 0 }} active>
      {selectedGroupIds?.length ? (
        <Stack gap={1}>
          {!groups.length && (
            <>
              {[...new Array(Math.min(6, selectedGroupIds?.length))].map((_, i) => (
                <Skeleton variant="rounded" height={48} key={i} sx={{ borderRadius: 24 }} />
              ))}
            </>
          )}
          {groups?.map((group) => (
            <RoundCard
              item={group}
              name={group?.name}
              label={group.subject?.archived ? undefined : group.subject?.name}
              count={group.student_count}
              onClick={onCardClick}
              isSelected
              withTooltip
              key={group.id}
            />
          ))}
        </Stack>
      ) : (
        <ModalEmptyMainArea label={$t({ id: 'assessments-Group' }).toLowerCase()} />
      )}
    </ModalContent>
  );
};
