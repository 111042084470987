import { Button, Stack } from '@mui/material';
import {
  FilterKeys,
  GetGroupsQueryFilters,
  GetGroupsQuerySort,
  Group,
  GroupArrangeBy,
  GroupsAggregatedRow,
  useGetGroupsAggregatedData,
  useGetGroupsQuery,
} from '@schooly/api';
import {
  ArrangedByCollapsableSection,
  ArrangedByCollapsableSectionSkeleton,
  PeriodDates,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { PROPERTIES_TEXT_IDS } from '@schooly/constants';
import { EyeIcon, GridBody, MainPageGrid, SkeletonRows } from '@schooly/style';
import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import { GroupRow, GroupsHeader } from './GroupsGrid';

type GroupsArrangedByListProps = {
  schoolId: string;
  arrangeByKey: GroupArrangeBy;
  filters: GetGroupsQueryFilters;
  sort?: GetGroupsQuerySort;
  query?: string;
  onChangeSort: (v: GetGroupsQuerySort) => void;
  onSetTotalCount: (v?: number) => void;
  getPeriodLabel?: (p: PeriodDates) => string | undefined;
};
export const GroupsArrangedByList: FC<GroupsArrangedByListProps> = ({
  schoolId,
  arrangeByKey,
  filters,
  sort,
  query,
  onChangeSort,
  onSetTotalCount,
  getPeriodLabel,
}) => {
  const { $t } = useIntl();
  const { data, isFetching } = useGetGroupsAggregatedData(
    {
      schoolId: schoolId,
      filters,
      arrangeBy: arrangeByKey ?? null,
      query,
    },
    { refetchOnMount: true, enabled: !!filters.date },
  );

  useEffect(() => {
    onSetTotalCount(data?.rows.reduce((acc, r) => acc + r.total, 0));
  }, [onSetTotalCount, data?.rows]);

  const sections =
    data?.rows.reduce<Array<GroupsAggregatedRow & { isNoneSection: boolean }>>(
      (acc, r) =>
        r.total > 0
          ? [
              ...acc,
              {
                ...r,
                name: r.name
                  ? r.name
                  : `${$t({ id: 'without' })}  ${$t({ id: PROPERTIES_TEXT_IDS[arrangeByKey] })}`,
                isNoneSection: !r.name,
              },
            ]
          : acc,
      [],
    ) ?? [];

  if (isFetching) return <ArrangedByCollapsableSectionSkeleton />;

  if (!sections.length) return <MainGridNoResultsStub textId="groups-NoResults-title" />;

  return (
    <>
      {sections.map((section) => (
        <ArrangedByCollapsableSection
          count={section.total}
          title={
            section.value === FilterKeys.TutorGroup ? $t({ id: 'groups-TutorGroup' }) : section.name
          }
          key={section.value}
        >
          <ArrangedByGroupsGrid
            count={section.total}
            sort={sort}
            filters={filters}
            arrangeByKey={arrangeByKey}
            arrangeByValue={!section.isNoneSection ? section.value : undefined}
            schoolId={schoolId}
            query={query}
            onChangeSort={onChangeSort}
            getPeriodLabel={getPeriodLabel}
          />
        </ArrangedByCollapsableSection>
      ))}
    </>
  );
};

type ArrangedByGroupsGridProps = {
  count: number;
  filters: GetGroupsQueryFilters;
  arrangeByKey?: GroupArrangeBy;
  arrangeByValue?: string | number;
  schoolId: string;
  sort?: GetGroupsQuerySort;
  query?: string;
  onChangeSort: (v: GetGroupsQuerySort) => void;
  getPeriodLabel?: (p: PeriodDates) => string | undefined;
};

const PAGE_SIZE = 30;

export const ArrangedByGroupsGrid: FC<ArrangedByGroupsGridProps> = ({
  count,
  sort,
  filters: initialFilters,
  schoolId,
  onChangeSort,
  arrangeByKey,
  arrangeByValue,
  query,
  getPeriodLabel,
}) => {
  const filters = useMemo(
    () =>
      arrangeByKey
        ? {
            ...initialFilters,
            [arrangeByKey]: [arrangeByValue === undefined ? 'none' : arrangeByValue],
          }
        : initialFilters,
    [initialFilters, arrangeByKey, arrangeByValue],
  );

  const { data, isLoading, params, setParams, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetGroupsQuery(
      {
        schoolId,
        pageSize: PAGE_SIZE,
        sort,
        filters,
        query,
      },
      { refetchOnMount: 'always' },
    );

  useEffect(() => {
    setParams((params) => ({ ...params, filters, sort }));
  }, [sort, filters, setParams]);

  const entries = useMemo(
    () => data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Group[]) ?? [],
    [data?.pages],
  );

  const mainContent = useMemo(() => {
    if (!entries.length && !isLoading) {
      return (
        <NoListItemsStub
          titleText={<FormattedMessage id="filter-NoResults" />}
          subTitleText={<FormattedMessage id="groups-NoMatches" />}
          type="group"
        />
      );
    }

    return (
      <>
        <GroupsHeader sort={params.sort} onChangeSort={onChangeSort} />

        <GridBody>
          {entries?.map((entry) => {
            const { date_to, date_from } = entry.validity;
            const periodLabel =
              !!date_to && !!date_from
                ? getPeriodLabel?.({
                    startDate: date_from,
                    endDate: date_to,
                  })
                : undefined;
            return <GroupRow group={entry} key={entry.id} periodLabel={periodLabel} />;
          })}

          {isLoading && <SkeletonRows columnsCount={6} amount={PAGE_SIZE} />}
          {(isLoading || isFetchingNextPage) && (
            <SkeletonRows
              columnsCount={6}
              amount={Math.min(PAGE_SIZE, count - (entries?.length || 0))}
            />
          )}
        </GridBody>
      </>
    );
  }, [count, entries, getPeriodLabel, isFetchingNextPage, isLoading, onChangeSort, params.sort]);

  return (
    <MainPageGrid
      bottomElement={
        hasNextPage && !isFetchingNextPage ? (
          <Stack mt={1} alignItems="center">
            <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
              <FormattedMessage id="action-ShowMoreButton" />
            </Button>
          </Stack>
        ) : undefined
      }
    >
      {mainContent}
    </MainPageGrid>
  );
};
