import { styled } from '@mui/material';

import { GridCell } from '../../../../components/uikit-components/Grid/Grid';

export const TakeAttendanceHeaderCell = styled(GridCell)(({ theme }) => ({
  '&&': {
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
}));

TakeAttendanceHeaderCell.defaultProps = {
  variant: 'head',
};

//TODO: Do we still need this?
export const TakeAttendanceStudentHeaderCell = styled(TakeAttendanceHeaderCell)(({ theme }) => ({
  width: 244,
  maxWidth: 244,
  whiteSpace: 'nowrap',

  '&&': {
    textAlign: 'left',
  },

  '& .MuiFormControlLabel-root': {
    marginRight: 0,
  },

  '& .UIKit-Dropdown': {
    height: theme.spacing(4.75),
  },
}));

TakeAttendanceStudentHeaderCell.defaultProps = {
  noPadding: true,
  sticky: true,
};
