import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99998 18L2.32 12.24C2.11856 12.0889 2 11.8518 2 11.6V2.99999C2 2.58797 2.47038 2.35278 2.8 2.59999L10 7.99999C10 7.99999 12.5973 4.16803 14.1868 1.46497C14.4003 1.10198 15 1.25547 15 1.67657V10.4999L9.99998 18Z"
      fill="#24275B"
    />
    <path
      d="M16 3.49999V10.9999L10.6153 17.5385L17.68 12.24C17.8814 12.0889 18 11.8518 18 11.6V2.99999C18 2.58797 17.5296 2.35278 17.2 2.59999L16 3.49999Z"
      fill="#24275B"
    />
  </svg>
);
