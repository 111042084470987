import { styled, SxProps, Theme, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import { forwardRef, InputHTMLAttributes, useEffect, useMemo, useState } from 'react';

type SelectSearchInputProps = {
  value: string;
  onChangeText: (v: string) => void;
  debounced?: boolean;
  sx?: SxProps<Theme>;
} & InputHTMLAttributes<HTMLInputElement>;

export const SelectSearchInput = forwardRef<HTMLInputElement, SelectSearchInputProps>(
  (
    { onChangeText, value: initialValue, onChange, debounced = true, placeholder, ...rest },
    ref,
  ) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const onChangeDebounce = useMemo(
      () => debounce((v: string) => onChangeText(v), debounced ? 500 : 0),
      [onChangeText, debounced],
    );

    useEffect(() => {
      onChangeDebounce(value);
    }, [value, onChangeDebounce]);

    return (
      <>
        <Input
          ref={ref}
          size={value.length || 1}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          {...rest}
        />
        {/* Due to this input is autoresizeable we cannot just use placeholder in input  */}
        {!value && !!placeholder && (
          <Typography
            variant="h3"
            color="common.grey"
            sx={{
              pointerEvents: 'none',
              ml: -2,
            }}
          >
            {placeholder}
          </Typography>
        )}
      </>
    );
  },
);

const Input = styled('input')(({ theme }) => ({
  border: 'none',
  background: 'none',
  padding: 0,
}));
