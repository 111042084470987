import { StaffRegistration, StaffRegistrationUpdate } from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';

import { AddEmploymentCaseForm } from './AddEmploymentCaseModal';

export const convertEmploymentCaseFormToRegistrationUpdate = (
  data: AddEmploymentCaseForm,
): StaffRegistrationUpdate => {
  const statuses: StaffRegistrationUpdate['statuses'] = [];

  for (const { applies_from, school_property_id } of data.statuses) {
    if (!applies_from || !school_property_id) continue;

    statuses.push({ applies_from, school_property_id });
  }

  return {
    job_title: data.job_title,
    statuses,
    subject_ids: data.subject_ids.map((s) => s.originId),
    school_property_ids: [
      ...(data.house_id ? [data.house_id] : []),
      ...data.age_group_ids.map((g) => g.originId),
      ...(data.department_id ? [data.department_id] : []),
    ],
  };
};

export const convertStaffRegistrationToEmploymentForm = (
  registration: StaffRegistration,
): AddEmploymentCaseForm => {
  return {
    job_title: registration.job_title || '',
    age_group_ids: registration.school_properties
      .filter(({ type }) => type === SchoolPropertyType.AgeGroup)
      .map((v) => ({ originId: v.id })),
    house_id: registration.school_properties.find(({ type }) => type === SchoolPropertyType.House)
      ?.id,
    department_id: registration.school_properties.find(
      ({ type }) => type === SchoolPropertyType.Department,
    )?.id,
    subject_ids: registration.subjects?.map((s) => ({ originId: s.id })) || [],
    statuses: registration.statuses.map((s) => ({
      school_property_id: s.school_property.id,
      applies_from: s.applies_from,
    })),
  };
};
