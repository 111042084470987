import { IconButton } from '@mui/material';
import { CrossIcon, Loading, ModalHeaderStyled } from '@schooly/style';
import React, { FC } from 'react';

import AccessDenied from '../../../../components/common/AccessDenied';
import { ModalSmall } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeaderGoBack } from '../../../../components/uikit-components/Modal/ModalHeaderGoBack';
import { useProfile } from '../../../../context/profile/useProfile';
import { ProfileModalHeader } from '../../ProfileModalHeader/ProfileModalHeader';
import { ProfileModalProps } from '../../ProfileModalProps';
import { ProfileModalIndividualAbout } from './ProfileModalIndividualAbout';

export const ProfileModalIndividualContext: FC<ProfileModalProps> = (props) => {
  const { showLoader, hasAccess, actions } = useProfile();

  const renderContent = () => {
    if (showLoader) {
      return <Loading />;
    }

    if (!hasAccess) {
      return (
        <>
          <ModalHeaderStyled withBorderBottom={false} active>
            <IconButton onClick={actions.handleClose}>
              <CrossIcon />
            </IconButton>
            <ModalHeaderGoBack />
          </ModalHeaderStyled>
          <AccessDenied />
        </>
      );
    }

    return (
      <>
        <ProfileModalHeader />
        <ProfileModalIndividualAbout />
      </>
    );
  };

  // TODO: get rid of deprecated CSS
  return (
    <ModalSmall
      PaperProps={{ className: 'ProfileModal' }}
      onClose={actions.handleClose}
      open
      {...props}
    >
      {renderContent()}
      {props.children}
    </ModalSmall>
  );
};
