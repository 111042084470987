import packageInfo from '@schooly/package-info';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';

import { COMMIT_SHA } from './config';

const CACHE_BUSTER_STORAGE_KEY = 'schooly-app@cacheIdentifier';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const generateAndStoreBusterCacheId = () => {
  const key = String(new Date().getTime());
  localStorage.setItem(CACHE_BUSTER_STORAGE_KEY, key);

  return key;
};

export const clearReactQueryStorageCache = () => {
  generateAndStoreBusterCacheId();
};

const getCacheBusterKey = () => {
  const storedKey =
    localStorage.getItem(CACHE_BUSTER_STORAGE_KEY) || generateAndStoreBusterCacheId();

  return `${packageInfo.version}_${COMMIT_SHA}_${storedKey}`;
};

// React query persist config
export const persistOptions = {
  persister: createSyncStoragePersister({
    storage: window.localStorage,
  }),
  buster: getCacheBusterKey(),
};
