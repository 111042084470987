import { Chip } from '@mui/material';
import { DATE_FORMAT_MOMENT as DATE_FORMAT } from '@schooly/constants';
import moment, { Moment } from 'moment';
import React from 'react';

export interface TagStatusPublishProps {
  label?: string;
  published?: boolean;
  publishDate?: Date | Moment;
  flat?: boolean;
}

export const TagStatusPublish: React.FC<TagStatusPublishProps> = ({
  label,
  published,
  publishDate,
  flat,
}) => {
  let statusLabel = label;

  if (published && publishDate) {
    const date = moment(publishDate).format(DATE_FORMAT);

    statusLabel += ` ${date}`;
  }

  return (
    <Chip
      flat={flat}
      color={published ? 'success' : 'default'}
      variant="outlined"
      label={statusLabel}
    />
  );
};
