import { useCallback, useEffect, useRef } from 'react';

export const useInfiniteScroll = (
  isLoading?: boolean,
  callback?: VoidFunction,
  //hasNewPage is needed as useEffect dependency in case
  //if useEffect is not triggered after there was no next page
  hasNextPage?: boolean,
  threshold = 0.5,
) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  const handleObserver = useCallback<IntersectionObserverCallback>(
    (entries) => {
      if (!callback || isLoading) return;
      const target = entries[0];

      if (target?.isIntersecting) {
        callback();
      }
    },
    [callback, isLoading],
  );

  useEffect(() => {
    if (!loaderRef?.current) return;

    const option = {
      root: null,
      rootMargin: '0px',
      threshold,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(loaderRef.current);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderRef?.current, handleObserver, isLoading, hasNextPage]);

  return loaderRef;
};
