export const USER_NATIONALITIES_MAX_COUNT = 5;

export enum Nationalities {
  'Afghani',
  'Alandic',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Anguillan',
  'Antarctic',
  'Antiguan',
  'Argentine',
  'Armenian',
  'Arubian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahameese',
  'Bahrainian',
  'Bangladeshi',
  'Barbadian',
  'Barthelemois',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bermudan',
  'Bhutanese',
  'Bolivian',
  'Bosnian',
  'Bouvet Island',
  'Brazilian',
  'British',
  'British Indian Ocean Territory',
  'Bruneian',
  'Bulgarian',
  'Burkinabe',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Caribbean Netherlands',
  'Caymanian',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Chinese - Hong Kong',
  'Chinese - Macau',
  'Chinese - Taiwan',
  'Christmas Islander',
  'Cocossian',
  'Colombian',
  'Comoran',
  'Congolese',
  'Cook Islander',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Curacaoan',
  'Cypriot',
  'Czech',
  'Danish',
  'Djiboutian',
  'Dominican',
  'Dutch',
  'Ecuadorean',
  'Egyptian',
  'Emirati',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Falkland Islander',
  'Faroese',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'French Guianese',
  'French Polynesian',
  'French Southern Territories',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Gibralterian',
  'Greek',
  'Greenlander',
  'Grenadian',
  'Guadeloupean',
  'Guamanian',
  'Guatemalan',
  'Guernsey',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Heard and McDonald Islands',
  'Honduran',
  'Hungarian',
  'Icelander',
  'I-Kiribati',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jersey',
  'Jordanian',
  'Kazakhstani',
  'Kenyan',
  'Kittian',
  'Kosovan',
  'Kuwaiti',
  'Kyrgyzstani',
  'Laotian',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtensteiner',
  'Lithunian',
  'Luxembourger',
  'Macedonian',
  'Mahoran',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivan',
  'Malian',
  'Maltese',
  'Manx',
  'Marshallese',
  'Martinican',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monacan',
  'Mongolian',
  'Montenegrin',
  'Montserratian',
  'Moroccan',
  'Mosotho',
  'Motswana',
  'Mozambican',
  'Myanmarese',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Caledonian',
  'New Zealander',
  'Ni Vanuatu',
  'Nicaraguan',
  'Nigerian',
  'Nigerien',
  'Niuean',
  'Norfolk Islander',
  'North Korean',
  'Northern Mariana Islander',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Palestinian',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Pitcairn Islander',
  'Polish',
  'Portuguese',
  'Puerto Rican',
  'Qatari',
  'Reunion',
  'Romanian',
  'Russian',
  'Rwandan',
  'Saint Helenian',
  'Saint Lucian',
  'Saint Martin',
  'Saint Pierrais',
  'Saint Vincentian',
  'Salvadorean',
  'Samoan',
  'Sanmarinese',
  'Sao Tomean',
  'Saudi Arabian',
  'Senegalese',
  'Serbian',
  'Seychellois',
  'Sierra Leonean',
  'Singaporean',
  'Sint Maarten',
  'Slovakian',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Georgia and the South Sandwich Islands',
  'South Korean',
  'Spanish',
  'Sri Lankan',
  'Sudanese',
  'Surinamer',
  'Svalbard and Jan Mayen Islands',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Tajikistani',
  'Tanzanian',
  'Thai',
  'Timorese',
  'Togolese',
  'Tokelauan',
  'Tongan',
  'Trinidadian',
  'Tunisian',
  'Turkish',
  'Turkmen',
  'Turks and Caicos Islander',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'United States Minor Outlying Islands',
  'Uruguayan',
  'Uzbekistani',
  'Vanuatu',
  'Vatican',
  'Venezuelan',
  'Vietnamese',
  'Virgin Islander',
  'Wallisian',
  'Western Saharan',
  'Yemeni',
  'Zambian',
  'Zimbabwean',
}
