import { Button, Stack, Typography } from '@mui/material';
import { Company, GetCompaniesSort, SORT_DIRECTION, useGetCompaniesListQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PageHeader, PageHeaderSearchInput } from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { GridBody, MainPageGrid, PlusIcon, SkeletonGridLoader, SkeletonRows } from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { CompaniesHeader, CompanyRow } from './CompaniesGrid';

export const PAGE_SIZE = 30;
export const SKELETON_COLUMNS = 8;

export const CompaniesContent: FC = () => {
  const { schoolId = '', permissions } = useAuth();
  const canCreate =
    permissions.includes('payer_and_product_assignment_creator') ||
    permissions.includes('payer_and_product_assignment_manager');
  const { $t } = useIntl();
  const navigate = useNavigate();

  const { data, params, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading, setParams } =
    useGetCompaniesListQuery(
      {
        schoolId,
        query: '',
        sort: { columnTextId: 'student_count', direction: SORT_DIRECTION.DESC },
      },
      { refetchOnMount: 'always', enabled: !!schoolId },
    );

  const handleChangeSort = useCallback(
    (sort: GetCompaniesSort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleAddCompanyClick = useCallback(() => {
    navigate('/companies/new');
  }, [navigate]);

  const entries = useMemo(
    () => data?.pages.reduce<Company[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  const total = data?.pages[0].count;

  if (data && !params.query && !total)
    return (
      <>
        <PageHeader pageTitleTextId="companies-Title" />
        <Stack alignItems="center" justifyContent="center" flex={1}>
          <Typography variant="h1" textAlign="center" mb={1} maxWidth={417}>
            {$t({ id: 'companies-NoCompanies' })}
          </Typography>

          {canCreate && (
            <>
              <Typography variant="h3" mb={3}>
                {$t({ id: 'companies-CreateCompany' })}
              </Typography>

              <Link to="/companies/new">
                <Button startIcon={<PlusIcon />}>{$t({ id: 'companies-AddCompany' })}</Button>
              </Link>
            </>
          )}
        </Stack>
      </>
    );

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonTextId="companies-NewCompany"
          pageTitleTextId="companies-Title"
          showActionButton={canCreate}
          buttonIcon={<PlusIcon />}
          onButtonClick={handleAddCompanyClick}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={$t({ id: 'companies-SearchAmongCompanies' })}
          />
        </PageHeader>
      </Stack>

      <>
        <MainPageGrid mt={2.5}>
          <CompaniesHeader sort={params.sort} onChangeSort={handleChangeSort} />
          <GridBody>
            {entries?.map((entry) => (
              <CompanyRow company={entry} key={entry.id} />
            ))}
            {isLoading && <SkeletonRows columnsCount={SKELETON_COLUMNS} amount={PAGE_SIZE} />}
            <SkeletonGridLoader
              isFetching={isLoading || isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              columnsCount={SKELETON_COLUMNS}
              amount={Math.min(
                PAGE_SIZE,
                total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
              )}
            />
          </GridBody>
        </MainPageGrid>
        {!isLoading && !entries.length && (
          <MainGridNoResultsStub textId="companies-noCompaniesMatching" showFiltersInfo={false} />
        )}
      </>
    </>
  );
};
