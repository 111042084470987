import { isStudentWithParents } from '@schooly/api';
import { WithAssociations, WithGuardian } from '@schooly/api';
import { SchoolRelation } from '@schooly/api';
import { BaseUser } from '@schooly/api';
import { UserType } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { getRouteModalPathname } from '../../../helpers/misc';
import { getSchoolUserId } from '../../../helpers/school';
import { getUserFullName } from '../../../helpers/users';
import useAppLocation from '../../../hooks/useAppLocation';

interface FamilyMembersProps {
  user: (SchoolRelation | BaseUser) & Partial<WithAssociations> & Partial<WithGuardian>;
  userType: UserType;
  studentWithParents?: boolean;
}

export const MAX_EXTRA_INFO_WIDTH = 32; // symbols

const FamilyMembers: React.FC<FamilyMembersProps> = ({ user, userType, studentWithParents }) => {
  const location = useAppLocation();

  const memberType = useMemo(() => {
    switch (userType) {
      case 'child':
      case 'student':
        return studentWithParents ? 'parent' : 'adult';
      case 'parent':
        return 'student';
      default:
        return 'child';
    }
  }, [studentWithParents, userType]);

  const familyMembers = useMemo(() => {
    if (!user) {
      return [];
    }

    if (studentWithParents && isStudentWithParents(user)) {
      return user.parents ?? [];
    }

    switch (userType) {
      case 'child':
        return user.child_associations ?? [];
      case 'student':
        return user.guardian_relations?.map((m) => m?.guardian) ?? [];
      case 'parent':
        return user.guardian_of_relations?.map((m) => m?.child) ?? [];
      default:
        return user.adult_associations ?? [];
    }
  }, [user, studentWithParents, userType]);

  const handleMemberClick = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  if (!familyMembers) {
    return null;
  }

  let totalNamesLength = 0;
  let dotsInserted = false;

  return (
    <>
      {familyMembers.map((member) => {
        let name = getUserFullName(member);
        totalNamesLength += name.length;

        if (totalNamesLength > MAX_EXTRA_INFO_WIDTH) {
          const delta = Math.abs(MAX_EXTRA_INFO_WIDTH - totalNamesLength);
          if (delta >= name.length) {
            if (dotsInserted) {
              return null;
            }

            dotsInserted = true;
            return (
              <span key={getSchoolUserId(member)} className="PeopleList-item-extra-name">
                ...
              </span>
            );
          }
          name = `${name.slice(0, -delta)}...`;
          dotsInserted = true;
        }

        return (
          <Link
            key={getSchoolUserId(member)}
            to={{
              pathname: getRouteModalPathname(memberType, member),
              search: `?type=${SchoolUserRole.Parent}`,
            }}
            state={{ backgroundLocation: location }}
            className="PeopleList-item-extra-name active"
            onClick={handleMemberClick}
          >
            {name}
          </Link>
        );
      })}
    </>
  );
};

export default FamilyMembers;
