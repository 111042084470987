import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2C16.1046 2 17 2.89543 17 4V5H14V2H15ZM14 6H17V16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16V4C3 2.89543 3.89543 2 5 2H13V5C13 5.55228 13.4477 6 14 6ZM14.1778 9L13.3689 10.4396L12.5601 9H11.7646L12.9365 10.9835L11.7379 13H12.5361L13.3689 11.533L14.1992 13H15L13.8041 10.9835L14.9733 9H14.1778ZM8.70663 13H7.9832L9.44609 9H9.60893H9.90525H10.0708L11.539 13H10.8155L10.5074 12.0852H9.0141L8.70663 13ZM9.75987 9.86619L10.3149 11.5137H9.20615L9.75987 9.86619ZM6.20128 13H6.88468V9.57143H8.09664V9H6.88468H6.20128H5V9.57143H6.20128V13Z"
      fill="#24275B"
    />
  </svg>
);
