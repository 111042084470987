import { StudentRolloverRequestParams } from '@schooly/api';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';

export const useRollover = () => {
  const location = useAppLocation();
  const navigate = useNavigate();

  const navigateToRollover = useCallback(
    (params: StudentRolloverRequestParams) => {
      navigate('/students/rollover', {
        state: { backgroundLocation: location, initialState: params },
      });
    },
    [location, navigate],
  );

  return { navigateToRollover };
};
