import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.36133 5.41016H8.0957V10.0625C8.0957 10.8008 8.11719 11.2793 8.16016 11.498C8.23438 11.8496 8.41016 12.1328 8.6875 12.3477C8.96875 12.5586 9.35156 12.6641 9.83594 12.6641C10.3281 12.6641 10.6992 12.5645 10.9492 12.3652C11.1992 12.1621 11.3496 11.9141 11.4004 11.6211C11.4512 11.3281 11.4766 10.8418 11.4766 10.1621V5.41016H13.2109V9.92188C13.2109 10.9531 13.1641 11.6816 13.0703 12.1074C12.9766 12.5332 12.8027 12.8926 12.5488 13.1855C12.2988 13.4785 11.9629 13.7129 11.541 13.8887C11.1191 14.0605 10.5684 14.1465 9.88867 14.1465C9.06836 14.1465 8.44531 14.0527 8.01953 13.8652C7.59766 13.6738 7.26367 13.4277 7.01758 13.127C6.77148 12.8223 6.60938 12.5039 6.53125 12.1719C6.41797 11.6797 6.36133 10.9531 6.36133 9.99219V5.41016Z"
      fill="#24275B"
    />
    <path d="M5.5 15.8984H14.166V17.1582H5.5V15.8984Z" fill="#24275B" />
  </svg>
);
