import { Typography } from '@mui/material';
import {
  GetReportsQueryFilters,
  Report,
  REPORTS_QUERY_FILTER_KEYS,
  useGetReportsQuery,
} from '@schooly/api';
import { FC, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { pickOnlyParamsFromFilterKeys } from '../utils';
import { renderReportTags } from './ReportExpandedSelect';
import { ReportSelectRow } from './ReportSelectRow';

type ReportSelectMultipleProps = {
  schoolId: string;
  selectedIds: string[];
  onSelectReportId: (v: string) => void;
  filters?: GetReportsQueryFilters;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const ReportSelectMultiple: FC<ReportSelectMultipleProps> = ({
  schoolId,
  selectedIds,
  onSelectReportId,
  placeholder,
  filters = {},
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [queryEnabled, setQueryEnabled] = useState(false);

  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage, params, setParams } =
    useGetReportsQuery(
      {
        school_id: schoolId,
        filters: pickOnlyParamsFromFilterKeys(REPORTS_QUERY_FILTER_KEYS, filters),
      },
      { enabled: queryEnabled },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const entries =
      data.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Report[]) ?? [];

    if (!entries.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {entries.map((report) => (
          <ReportSelectRow
            onClick={(id) => {
              onSelectReportId(id);
              handleChangeQuery('');
            }}
            key={report.id}
            report={report}
            isSelected={selectedIds.includes(report.id)}
          />
        ))}
      </>
    );
  }, [data, handleChangeQuery, onSelectReportId, selectedIds]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={placeholder}
      hasValues={selectedIds.length > 0}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      onFetchNextPage={fetchNextPage}
    >
      {(opened) => (
        <>
          {renderReportTags({
            ids: selectedIds,
            tagProps: { size: placeholder ? 'small' : undefined, sx: { maxWidth: 200 } },
            onDelete: opened ? onSelectReportId : undefined,
          })}
          {opened && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={params.query || ''}
              onChangeText={handleChangeQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
