import { DEFAULT_DATE_FORMAT_FNS, useGetActualMembersForGroupQuery } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { format } from 'date-fns';
import { useState } from 'react';

import { useGroup } from '../groups/useGroup';

export const useMembersForGroup = () => {
  const { groupId, isExpired, group, fetching } = useGroup();
  const [date, setDate] = useState<string | undefined>(format(new Date(), DEFAULT_DATE_FORMAT_FNS));

  const {
    data: usersWithMembership,
    isLoading: fetchingUsersWithMembership,
    error: errorUsersWithMembership,
  } = useGetActualMembersForGroupQuery(
    {
      id: groupId ?? '',
      date,
      user_role: SchoolUserRole.Student,
    },
    {
      refetchOnMount: 'always',
      enabled: Boolean(groupId && date),
    },
  );

  const value = {
    date,
    groupId,
    group,
    error: errorUsersWithMembership,
    fetching: fetching || fetchingUsersWithMembership,
    usersWithMembership,
    isExpired,
    setDate,
  };

  return value;
};
