import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3C8.44772 3 8 3.44772 8 4V8H4C3.44772 8 3 8.44772 3 9V11C3 11.5523 3.44772 12 4 12H8V16C8 16.5523 8.44772 17 9 17H11C11.5523 17 12 16.5523 12 16V12H16C16.5523 12 17 11.5523 17 11V9C17 8.44772 16.5523 8 16 8H12V4C12 3.44772 11.5523 3 11 3H9Z"
      fill="#B3BBCB"
    />
  </svg>
);
