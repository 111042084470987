import { AnnualPlanRecordTypes } from '@schooly/api';
import { FC } from 'react';

import { AnnualPlannerExpandedAssessment } from './expanded/AnnualPlannerExpandedAssessment';
import { AnnualPlannerExpandedEvent } from './expanded/AnnualPlannerExpandedEvent';
import { AnnualPlannerExpandedReport } from './expanded/AnnualPlannerExpandedReport';
import { AnnualPlannerExpandedSchoolPeriod } from './expanded/AnnualPlannerExpandedSchoolPeriod';
import { useAnnualPlannerCreateRecord } from './WithAnnualPlannerCreate';

export const AnnualPlannerCreateExpanded: FC = () => {
  const { isOpen, expandedData } = useAnnualPlannerCreateRecord();

  if (!isOpen && !expandedData) {
    return null;
  }

  switch (expandedData?.type) {
    case AnnualPlanRecordTypes.SCHOOL_PERIOD:
      return <AnnualPlannerExpandedSchoolPeriod />;

    case AnnualPlanRecordTypes.HOLIDAY:
    case AnnualPlanRecordTypes.EVENT:
      return <AnnualPlannerExpandedEvent />;

    case AnnualPlanRecordTypes.ASSESSMENT:
      return <AnnualPlannerExpandedAssessment />;

    case AnnualPlanRecordTypes.REPORT:
      return <AnnualPlannerExpandedReport />;

    default:
      return null;
  }
};
