import { BaseUser, Child, listProfiles, NewUser, UserType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading, PlusIcon } from '@schooly/style';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getUserTypeTextId } from '../../../helpers/misc';
import usePagedApiResourceWithFilter, {
  GetResourceFunction,
} from '../../../hooks/usePagedApiResourceWithFilter';
import Button from '../../ui/Button';
import SearchInput from '../../ui/SearchInput';
import PersonCardSelectable from '../PersonCard/PersonCardSelectable';
import FilteredUsersList from './FilteredUsersList';

import './index.scss';

interface ChildrenExternalSidebarProps {
  userType: UserType;
  adultProfile?: NewUser | BaseUser;
  selectedUserIds: string[];
  onUserSelect: (user: Child) => void;
  onUserCreate?: () => void;
}

const ChildrenExternalSidebar: React.FC<ChildrenExternalSidebarProps> = ({
  userType,
  adultProfile,
  selectedUserIds,
  onUserSelect,
  onUserCreate,
}) => {
  const { formatMessage } = useIntl();
  const { schoolId } = useAuth();
  const userTypePlural = formatMessage({ id: getUserTypeTextId('child', true) }).toLowerCase();
  const [filter, setFilter] = useState<string>('');

  const getUserList = useCallback<GetResourceFunction<Child>>(
    ({ pageSize, pageNumber, query }) => {
      if (!schoolId) {
        return undefined;
      }

      return listProfiles({ schoolId, type: 'child', pageSize, pageNumber, query });
    },
    [schoolId],
  );

  const {
    displayedList: displayedUserList,
    canShowMore,
    totalCount,
    isSearching,
    isFetching,
    handleShowMore,
  } = usePagedApiResourceWithFilter<Child>({
    getResource: getUserList,
    filter,
    onFilterUpdate: setFilter,
  });

  const loaderRef = useInfiniteScroll(isFetching, handleShowMore);

  const content = useMemo(() => {
    if (!adultProfile) {
      return null;
    }

    if (!filter && !totalCount) {
      return (
        <p className="text-center text-muted">
          <FormattedMessage
            id="people-NoUsersExist"
            values={{
              userTypePlural,
            }}
          />
        </p>
      );
    }
    if (!totalCount) {
      return (
        <p className="text-center text-muted">
          <FormattedMessage id={isSearching ? 'people-Searching' : 'people-NoUsersFound'} />
        </p>
      );
    }

    const filteredUsers = displayedUserList!.results.filter(
      (u) => !selectedUserIds.includes(u.user_id),
    );

    if (filter) {
      return (
        <FilteredUsersList
          filteredUsers={filteredUsers}
          canShowMore={canShowMore}
          userType={userType}
          isFetching={isFetching}
          onShowMore={handleShowMore}
          onUserSelect={onUserSelect}
        />
      );
    }

    const suggestedUsers = filteredUsers.filter(
      (u) => u.last_name.toLowerCase() === adultProfile.last_name.toLowerCase(),
    );
    const suggestedUserIds = suggestedUsers.map(({ user_id }) => user_id);
    const otherUsers = filteredUsers.filter((u) => !suggestedUserIds.includes(u.user_id));

    return (
      <>
        {!!suggestedUsers.length && (
          <div className="mb-5">
            <h4 className="text-muted">
              <FormattedMessage id="people-MaybeLooking" />
            </h4>
            {suggestedUsers.map((user) => (
              <PersonCardSelectable
                key={user.user_id}
                user={user}
                userType={userType === 'parent' ? 'student' : 'child'}
                onClick={() => onUserSelect(user)}
                isListItem
              />
            ))}
          </div>
        )}
        {!!otherUsers.length && (
          <>
            {!!suggestedUsers.length && (
              <h4 className="text-muted">
                <FormattedMessage
                  id="people-OtherType"
                  values={{
                    userTypePlural,
                  }}
                />
              </h4>
            )}
            {otherUsers.map((user) => (
              <PersonCardSelectable
                key={user.user_id}
                user={user}
                userType={userType === 'parent' ? 'student' : 'child'}
                onClick={() => onUserSelect(user)}
                isListItem
              />
            ))}
            {canShowMore && (
              <div className="py-3">
                {isFetching && <Loading />}
                <div ref={loaderRef} />
              </div>
            )}
          </>
        )}
      </>
    );
  }, [
    adultProfile,
    canShowMore,
    displayedUserList,
    filter,
    handleShowMore,
    isFetching,
    isSearching,
    loaderRef,
    onUserSelect,
    selectedUserIds,
    totalCount,
    userType,
    userTypePlural,
  ]);

  if (!displayedUserList || !adultProfile) {
    return <Loading />;
  }

  return (
    <div className="ChildrenExternalSidebar">
      <div className="ChildrenExternalSidebar__main">
        <SearchInput
          value={filter}
          onChange={setFilter}
          isValueRemovable
          isLoading={isSearching}
          placeholder={formatMessage(
            { id: 'people-SearchAmongTypeCount' },
            {
              count: totalCount,
              userTypePlural,
            },
          )}
        />

        <div className="ChildrenExternalSidebar__main__content">{content}</div>
      </div>
      {onUserCreate && (
        <div className="ChildrenExternalSidebar__footer">
          <Button color="primary" icon={<PlusIcon />} onClick={onUserCreate}>
            <FormattedMessage id="migration-CreateNewChild" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ChildrenExternalSidebar;
