import { Stack, Typography } from '@mui/material';
import { IntlError } from '@schooly/utils/intl-error';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { AttachmentFile, AttachmentProps } from './attachment-types';
import { AttachmentCard } from './AttachmentCard';
import { getIsMaxAttachmensSizeExceed, getMaxSizeError } from './utils';

export interface AttachmentsProps extends AttachmentProps {
  error?: IntlError;
  readOnly?: boolean;
}

export const Attachments: React.FC<AttachmentsProps> = ({
  files,
  error,
  readOnly,
  setError = () => {},
  setFiles = () => {},
  onAttachmentCardClick,
}) => {
  useEffect(() => {
    if (getIsMaxAttachmensSizeExceed(files)) {
      setError(getMaxSizeError(files));
    } else {
      setError(undefined);
    }
  }, [files, setError]);

  const handleAttachmentCardClick = useCallback(
    (id?: string) => {
      if (onAttachmentCardClick) {
        onAttachmentCardClick(id);
        return;
      }

      const filteredFiles = (files as AttachmentFile[])?.filter((f) => f.id !== id);
      setFiles(filteredFiles);
    },
    [files, onAttachmentCardClick, setFiles],
  );
  if (!files?.length) return null;

  return (
    <Stack overflow="auto" pb={1.5}>
      {error && (
        <Typography color="error.main">
          <FormattedMessage id={error.message} values={error.values} />
        </Typography>
      )}
      <Stack direction="row" gap={2.5} pt={2} p="1px">
        {Array.from(files as AttachmentFile[]).map((item) => (
          <AttachmentCard
            readOnly={readOnly}
            key={item.id}
            file={item.file || item}
            onClick={() => handleAttachmentCardClick(item.id)}
          />
        ))}
      </Stack>
    </Stack>
  );
};
