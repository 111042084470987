import React from 'react';
import { FormattedMessage } from 'react-intl';

import buildClassName from '../../../utils/buildClassName';
import getTypedObjectKeys from '../../../utils/getTypedObjectKeys';
import { AddGroupTab } from './useAddGroupModal';

import '../../../components/ui/Tabs/index.scss';

export interface GroupModalTabsProps {
  activeTab?: AddGroupTab;
  tabs: Record<AddGroupTab, string>;
  onTabSelect: (tabId: AddGroupTab) => void;
}

const GroupModalTabs: React.FC<GroupModalTabsProps> = ({ activeTab, onTabSelect, tabs }) => (
  <div className="Tabs">
    <nav className="TabNav mb-0">
      <ul className="TabNav__list list-inline">
        {getTypedObjectKeys(tabs).map((tabId) => (
          <TabLabel
            key={tabId}
            id={tabId}
            label={tabs[tabId]}
            isActive={tabId === activeTab}
            onTabSelect={onTabSelect}
          />
        ))}
      </ul>
    </nav>
  </div>
);

export interface TabLabelProps {
  id: AddGroupTab;
  label: string;
  isActive: boolean;
  onTabSelect: (tabId: AddGroupTab) => void;
}

const TabLabel: React.FC<TabLabelProps> = ({ id, label, isActive, onTabSelect }) => (
  <li className="TabLabel">
    <button
      type="button"
      className={buildClassName('TabLabel__button btn', isActive && 'TabLabel__button--active')}
      onClick={() => onTabSelect(id)}
    >
      <FormattedMessage id={label} />
    </button>
  </li>
);

export default GroupModalTabs;
