import { Icon, Menu, Stack, Typography } from '@mui/material';
import { ReportForAssessment } from '@schooly/api';
import { ReportsIcon } from '@schooly/style';
import React, { FC, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import { isDescendant } from '../../../helpers/misc';
import {
  AssessmentsReportsName,
  AssessmentsReportsNameTypography,
} from './AssessmentsReportsList.styled';

export interface AssessmentsReportsListProps {
  reports?: ReportForAssessment[];
  withLabel?: boolean;
}

export const AssessmentsReportsList: FC<AssessmentsReportsListProps> = ({ reports, withLabel }) => {
  const reportsFieldRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback((event: React.MouseEvent) => {
    const relatedTarget = event.relatedTarget as HTMLElement;

    if (
      relatedTarget &&
      reportsFieldRef.current &&
      !isDescendant(reportsFieldRef.current, relatedTarget)
    ) {
      setAnchorEl(null);
    }
  }, []);

  const open = Boolean(anchorEl);
  const reportsCount = reports?.length ?? 0;

  return reports?.length ? (
    <>
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Icon fontSize="small">
          <ReportsIcon />
        </Icon>

        <AssessmentsReportsName
          direction="row"
          ref={reportsFieldRef}
          onMouseEnter={handlePopoverOpen}
        >
          <Link to={`/reports/${reports[0].id}`}>
            <Typography>{reports[0].name}</Typography>
          </Link>
          {reportsCount > 1 ? <Typography> +{reportsCount - 1}</Typography> : null}
        </AssessmentsReportsName>
      </Stack>

      {reports.length > 1 && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          componentsProps={{
            backdrop: {
              onMouseEnter: handlePopoverClose,
            },
          }}
          disableRestoreFocus
          sx={{ '& .MuiList-root': { p: 1 } }}
        >
          <Stack>
            {reports.map((report) => (
              <Link key={report.id} to={`/reports/${report.id}`}>
                <AssessmentsReportsNameTypography underline>
                  {report.name}
                </AssessmentsReportsNameTypography>
              </Link>
            ))}
          </Stack>
        </Menu>
      )}
    </>
  ) : null;
};
