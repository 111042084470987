import { Message } from '@schooly/api';
import { DoubleCheckIcon } from '@schooly/style';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';
import Hint from '../../common/Hint';
import { PopoverSize } from '../../ui/Popover';
import { ListViewMessagesRowVariant } from '../ListViewMessagesRow/ListViewMessagesRow';

import './MessageReadIndicator.scss';

const $class = `${$UIKitPrefix}MessageReadIndicator`;

export interface ReadIndicatorProps {
  message: Message;
  variant?: ListViewMessagesRowVariant;
}

export const MessageReadIndicator: React.FC<ReadIndicatorProps> = ({ message, variant }) => {
  const { recipient_read } = message;

  const indicator = useMemo(() => {
    switch (variant) {
      case ListViewMessagesRowVariant.Profile: {
        return recipient_read ? (
          <Hint
            icon={
              <DoubleCheckIcon
                className={buildClassName(
                  `${$class}__double-check-icon`,
                  `${$class}__double-check-icon--read`,
                )}
              />
            }
            size={PopoverSize.Small}
          >
            <FormattedMessage id="messages-HasBeenRead" />
          </Hint>
        ) : (
          <DoubleCheckIcon className={buildClassName(`${$class}__double-check-icon`)} />
        );
      }
      case ListViewMessagesRowVariant.Group: {
        // const allRecipientsCount = groupMembersRead.length;
        // const readRecipients = groupMembersRead.filter((m) => m.hasBeenRead) || [];
        // const readRecipientsCount = readRecipients.length;

        // const label = (
        //   <div
        //     className={buildClassName(
        //       `${$class}__group-label`,
        //       readRecipientsCount > 0 && `${$class}__group-label--read`,
        //     )}
        //   >
        //     <span className={`${$class}__group-label__text`}>
        //       <FormattedMessage id="messages-Read" />
        //     </span>
        //     {NON_BREAKING_SPACE}
        //     {readRecipientsCount}/{allRecipientsCount}
        //   </div>
        // );

        // const labelWithTooltip = (
        //   <TooltipList
        //     offsetY={0}
        //     offsetX={15}
        //     className={`${$class}__tooltip`}
        //     array={readRecipients.length ? readRecipients.map((r: any) => r.name) : []}
        //   >
        //     {label}
        //   </TooltipList>
        // );

        // return readRecipientsCount > 0 ? labelWithTooltip : label;
        // todo: return when BE will be ready
        return null;
      }
      default:
        return null;
    }
  }, [recipient_read, variant]);

  return indicator;
};
