import { InputAdornment } from '@mui/material';
import { FormTextField, FormTextFieldProps } from '@schooly/components/form-text-field';
import { SearchIcon } from '@schooly/style';
import debounce from 'lodash.debounce';
import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

type AddStaffSelectAdultModalSearchInputProps = {
  value: string;
  onChangeText: (v: string) => void;
} & FormTextFieldProps;

export const AddStaffSelectAdultModalSearchInput: FC<AddStaffSelectAdultModalSearchInputProps> = ({
  onChangeText,
  value: initialValue,
  onChange,
  ...rest
}) => {
  const { $t } = useIntl();
  const [value, setValue] = useState(initialValue);

  const onChangeDebounce = useMemo(
    () => debounce((v: string) => onChangeText(v), 1000),
    [onChangeText],
  );

  useEffect(() => {
    onChangeDebounce(value);
  }, [value, onChangeDebounce]);

  return (
    <FormTextField
      canClear
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      placeholder={$t(
        { id: 'people-SearchAmongType' },
        { userTypePlural: $t({ id: 'userType-adult-plural' }).toLowerCase() },
      )}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      hideLabel
      {...rest}
    />
  );
};
