import { Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface SelectArrangeByProps {
  onSelect?: () => void;
}

export const SelectArrangeBy: FC<SelectArrangeByProps> = ({ onSelect }) => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" gap={2.5}>
      <Box maxWidth={330}>
        <Typography
          variant="h1"
          textAlign="center"
          sx={{
            fontStyle: 'normal',
          }}
        >
          <FormattedMessage id="selectArrangeByFromChart" />
        </Typography>
      </Box>

      <Box>
        <Button onClick={onSelect}>
          <FormattedMessage id="selectArrangeBy" />
        </Button>
      </Box>
    </Stack>
  );
};
