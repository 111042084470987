import { ProfileSearchResult } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading } from '@schooly/style';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import PersonCardSelectable from '../PersonCard/PersonCardSelectable';

import './index.scss';

interface FilteredUsersListProps {
  filteredUsers?: ProfileSearchResult[];
  canShowMore: boolean;
  isFetching: boolean;
  profileSchoolContext?: SchoolUserRole;
  onShowMore?: VoidFunction;
  onUserSelect: (user: ProfileSearchResult) => void;
}

const FilteredUsersList: React.FC<FilteredUsersListProps> = ({
  filteredUsers,
  canShowMore,
  isFetching,
  profileSchoolContext,
  onShowMore,
  onUserSelect,
}) => {
  const loaderRef = useInfiniteScroll(isFetching, onShowMore);

  return (
    <>
      <h4 className="text-muted">
        <FormattedMessage id="SearchResults" />
      </h4>
      {filteredUsers?.map((user) => (
        <PersonCardSelectable
          key={user.user_id}
          user={user}
          userType="adult"
          isUsernameClickable
          onClick={() => onUserSelect(user)}
          isListItem
          profileSchoolContext={profileSchoolContext}
        />
      ))}
      {canShowMore && onShowMore && (
        <div className="py-3">
          {isFetching && <Loading />}
          <div ref={loaderRef} />
        </div>
      )}
    </>
  );
};

export default FilteredUsersList;
