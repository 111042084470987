import { Components, Stack, Theme } from '@mui/material';
import { DropdownIcon } from '@schooly/style';

import { Tag } from '../../components/uikit/Tag/Tag';

export const SELECT_MIN_WIDTH = 500;

export const getActiveLegendStyle = (theme: Theme) => {
  return {
    ...theme.typography.caption,
    top: theme.spacing(0.5),
  };
};

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    IconComponent: DropdownIcon,
    renderValue: (value) => {
      if (Array.isArray(value)) {
        return (
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            minHeight={(theme) => theme.spacing(3)}
          >
            {value.map((item) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Stack>
        );
      }

      return `${value}`;
    },
    MenuProps: {
      elevation: 0,
      PaperProps: {
        sx: (theme) => ({
          // marginLeft: '0.5px',
          borderLeft: theme.mixins.borderControlValue(),
          borderRight: theme.mixins.borderControlValue(),
          borderBottom: theme.mixins.borderControlValue(),
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          maxHeight: 300,

          '& .MuiMenu-list': {
            padding: theme.spacing(1),
          },

          '& .MuiMenuItem-root': {
            padding: theme.spacing(0.25, 3.5, 0.25, 0.5),
            marginBottom: theme.spacing(0.5),
            borderRadius: `${theme.shape.borderRadiusSm}px`,
            color: theme.palette.text.primary,

            '&:last-child': {
              marginBottom: 0,
            },

            '&.Mui-focusVisible, &.Mui-selected, &:hover': {
              background: theme.palette.background.default,
              color: theme.palette.primary.main,
            },

            '&.Mui-selected': {
              background: `${
                theme.palette.background.default
              } url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04Ljk5OTk5IDE0LjkxNTJMMy4yOTI5MSA5LjIwODA3QzIuOTAyMzggOC44MTc1NSAyLjkwMjM4IDguMTg0MzggMy4yOTI5MSA3Ljc5Mzg2QzMuNjgzNDMgNy40MDMzMyA0LjMxNjYgNy40MDMzMyA0LjcwNzEyIDcuNzkzODZMOS4wMDAwMyAxMi4wODY4TDE1Ljc5NDMgNS4yOTI4NkMxNi4xODQ4IDQuOTAyMzUgMTYuODE4IDQuOTAyMzYgMTcuMjA4NSA1LjI5MjlDMTcuNTk5IDUuNjgzNDMgMTcuNTk5IDYuMzE2NiAxNy4yMDg0IDYuNzA3MTFMOC45OTk5OSAxNC45MTUyWiIgZmlsbD0iIzI0Mjc1QiIvPgo8L3N2Zz4K) no-repeat calc(100% - ${theme.spacing(
                0.5,
              )}) 50%`,
            },
          },
        }),
      },
    },
  },
  styleOverrides: {
    outlined: ({ theme, ownerState }) => {
      const hasValue = Boolean(
        Array.isArray(ownerState?.value) ? ownerState?.value.length : ownerState?.value != null,
      );

      return {
        zIndex: ownerState?.open ? theme.zIndex.tooltip : undefined,
        backgroundColor: ownerState?.open ? theme.palette.background.paper : undefined,

        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },

        // To override FormControlHoverBorder effect when select is open
        '&& ~ .MuiOutlinedInput-notchedOutline': {
          borderColor: ownerState?.open ? theme.palette.common.light3 : undefined,
        },

        '& ~ .MuiOutlinedInput-notchedOutline': {
          ...theme.mixins.borderControl(),
          top: 0,
          padding: theme.spacing(0, 1.5),

          ...(ownerState?.open
            ? {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                zIndex: theme.zIndex.tooltip,
              }
            : {}),

          '& legend': {
            ...theme.typography.h3,
            position: 'absolute',
            top: parseInt(theme.spacing(1.25)) - 1, // consider border-width
            margin: 0,
            height: 'unset',
            maxWidth: 'unset',
            color: theme.palette.text.secondary,

            transition: theme.transitions.create('all', {
              duration: theme.transitions.duration.shortest,
              easing: theme.transitions.easing.sharp,
            }),

            ...(hasValue ? getActiveLegendStyle(theme) : {}),

            // TODO: should consider .Mui-focused only in case of autocomplete, most likely
            // '.Mui-focused &': getActiveLegendStyle(theme),

            '& span': {
              padding: 0,
              opacity: 1,
            },

            '.MuiInputBase-sizeSmall &': {
              display: hasValue ? 'none' : undefined, // don't display label for small size if value is set
              top: parseInt(theme.spacing(0.75)) - 1, // consider border-width
            },
          },
        },
      };
    },

    select: ({ theme, ownerState }) => {
      return {
        ...theme.typography.h3,
        minWidth: SELECT_MIN_WIDTH,
        minHeight: theme.spacing(3),
        padding: theme.spacing(2, 5, 0.5, 1.5),
        boxSizing: 'border-box',

        [theme.breakpoints.down('md')]: {
          minWidth: 'unset',
        },

        // To override FormControlHoverBorder effect when select is open
        '&&': {
          opacity: ownerState?.open ? 1 : undefined,
        },

        '&.MuiOutlinedInput-input': {
          paddingRight: theme.spacing(5),
        },

        '.MuiInputBase-sizeSmall &': {
          padding: theme.spacing(0.75, 5, 0.75, 1.5),
        },
      };
    },

    icon: ({ theme, ownerState }) => {
      return {
        right: theme.spacing(1.5),
        fontSize: theme.spacing(2.5),
        color: theme.palette.text.secondary,
        zIndex: ownerState?.open ? theme.zIndex.tooltip : undefined,
      };
    },
  },
};
