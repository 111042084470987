import { AnnualPlanRecordTypes, Event, useGetEventQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { WithRecurringConfirmDialog } from '@schooly/components/recurring';
import { EventsInvite, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import React, { FC, useMemo } from 'react';

import { WithEvent } from '../../../../../context/events/WithEvent';
import { defaultValues } from '../../../../Events/EventCreateModal/EventCreateContent';
import { EventCreateModal } from '../../../../Events/EventCreateModal/EventCreateModal';
import { useAnnualPlannerCreateRecord } from '../WithAnnualPlannerCreate';

export const AnnualPlannerExpandedEvent: FC = () => {
  const { schoolId = '' } = useAuth();
  const { activePropertiesMap } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const { record, expandedData, close } = useAnnualPlannerCreateRecord();

  const { data } = useGetEventQuery(
    {
      eventId: record?.details.id ?? '',
    },
    {
      enabled: Boolean(record?.details.id),
      refetchOnMount: 'always',
    },
  );

  const editedEvent = useMemo<Event | undefined>(() => {
    if (!data) {
      return;
    }

    return {
      ...data,
      title: expandedData?.event.name ?? '',
      start: expandedData?.date[0] ?? '',
      end: expandedData?.date[1] ?? expandedData?.date[0] ?? '',
    };
  }, [data, expandedData?.date, expandedData?.event.name]);

  const formValues = useMemo(
    () => ({
      ...defaultValues,
      ...(expandedData?.type === AnnualPlanRecordTypes.HOLIDAY && {
        event_type: 'holiday' as const,
        invitee_type: EventsInvite.StudentsOnly,
        criteria: {
          student_status: activePropertiesMap.status
            .filter((status) => status.category?.current)
            .map((status) => status.id),
        },
      }),
      title: expandedData?.event.name ?? '',
      start: expandedData?.date[0] ?? '',
      end: expandedData?.date[1] ?? expandedData?.date[0] ?? '',
    }),
    [activePropertiesMap.status, expandedData?.date, expandedData?.event.name, expandedData?.type],
  );

  return (
    <WithEvent id={record?.details.id} event={editedEvent}>
      <WithRecurringConfirmDialog>
        <EventCreateModal onClose={close} defaultValues={formValues} />
      </WithRecurringConfirmDialog>
    </WithEvent>
  );
};
