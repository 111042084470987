import { AnnualPlanRecord, AnnualPlanRecordTypes, DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import {
  compareAsc,
  compareDesc,
  eachDayOfInterval,
  format,
  getDaysInMonth,
  parse,
} from 'date-fns';
import { FC, useCallback, useMemo } from 'react';

import useSchoolYears from '../../../../hooks/useSchoolYears';
import { AnnualPlannerCalendarRecords } from '../AnnualPlannerCalendar.styled';
import { AnnualPlannerCalendarWithDates, AnnualPlannerRecordMeta } from '../scheme';
import { useAnnualPlannerGrid } from '../useAnnualPlannerGrid';
import { AnnualPlannerRecordsLayoutCell } from './AnnualPlannerRecordsLayoutCell';

export interface AnnualPlannerRecordsLayoutProps extends AnnualPlannerCalendarWithDates {
  records?: AnnualPlanRecord[];
}

export const AnnualPlannerRecordsLayout: FC<AnnualPlannerRecordsLayoutProps> = ({
  records = [],
  ...props
}) => {
  const { getSchoolYearById } = useSchoolYears();
  const { months } = useAnnualPlannerGrid(props);

  /** Retrieves record's minimal metadata in a common format for the calendar grid */
  const getAnnualPlannerRecordMeta = useCallback(
    (record: AnnualPlanRecord): AnnualPlannerRecordMeta | undefined => {
      switch (record.type) {
        case AnnualPlanRecordTypes.SCHOOL_PERIOD: {
          const period = getSchoolYearById(record.year_id)
            ?.period_groups?.find((group) => group.id === record.period_group_id)
            ?.periods.find((period) => period.id === record.id);

          if (!period) {
            return;
          }

          return {
            id: period.id,
            type: record.type,
            title: period.name,
            start: parse(period.date_from, DEFAULT_DATE_FORMAT_FNS, new Date()),
            end: parse(period.date_to, DEFAULT_DATE_FORMAT_FNS, new Date()),
            details: record,
          };
        }
        case AnnualPlanRecordTypes.EVENT:
        case AnnualPlanRecordTypes.HOLIDAY:
          return {
            id: record.id,
            type: record.type,
            title: record.title,
            start: parse(record.start, DEFAULT_DATE_FORMAT_FNS, new Date()),
            end: parse(record.end, DEFAULT_DATE_FORMAT_FNS, new Date()),
            details: record,
          } as AnnualPlannerRecordMeta;
        case AnnualPlanRecordTypes.ASSESSMENT:
          return {
            id: record.id,
            type: record.type,
            title: record.name,
            start: parse(record.assessment_date, DEFAULT_DATE_FORMAT_FNS, new Date()),
            end: parse(record.assessment_date, DEFAULT_DATE_FORMAT_FNS, new Date()),
            details: record,
          };
        case AnnualPlanRecordTypes.REPORT: {
          if (!record.scheduled_publish_date) {
            return undefined;
          }

          return {
            id: record.id,
            type: record.type,
            title: record.name,
            start: parse(record.scheduled_publish_date, DEFAULT_DATE_FORMAT_FNS, new Date()),
            end: parse(record.scheduled_publish_date, DEFAULT_DATE_FORMAT_FNS, new Date()),
            details: record,
          };
        }
      }
    },
    [getSchoolYearById],
  );

  const data = useMemo(() => {
    const getRecordWeight = (record: AnnualPlanRecord) => {
      switch (record.type) {
        case AnnualPlanRecordTypes.SCHOOL_PERIOD:
          return 0;
        case AnnualPlanRecordTypes.EVENT:
          return 1;
        case AnnualPlanRecordTypes.HOLIDAY:
          return 2;
        case AnnualPlanRecordTypes.ASSESSMENT:
          return 3;
        case AnnualPlanRecordTypes.REPORT:
          return 4;
        default:
          return 5;
      }
    };

    return (
      records
        // the records should always be in the same order
        .sort((a, b) => getRecordWeight(a) - getRecordWeight(b))
        .sort((a, b) => {
          const metaA = getAnnualPlannerRecordMeta(a);
          const metaB = getAnnualPlannerRecordMeta(b);

          if (!metaA) {
            return -1;
          }

          if (!metaB) {
            return 1;
          }

          const n = compareAsc(metaA.start, metaB.start);

          if (n !== 0) {
            return n;
          }

          return compareDesc(metaA.end, metaB.end);
        })
        .reduce<Record<string, AnnualPlannerRecordMeta[]>>((prev, record) => {
          const meta = getAnnualPlannerRecordMeta(record);

          if (!meta) {
            return prev;
          }

          eachDayOfInterval(meta).forEach((date) => {
            const dateString = format(date, DEFAULT_DATE_FORMAT_FNS);

            if (!prev[dateString]) {
              prev[dateString] = [];
            }

            prev[dateString].push(meta);
          });

          return prev;
        }, {})
    );
  }, [getAnnualPlannerRecordMeta, records]);

  return (
    <AnnualPlannerCalendarRecords>
      {months.map((month) =>
        new Array(getDaysInMonth(month)).fill(true).map((_, index) => {
          const day = index + 1;
          const date = new Date(month);
          date.setDate(day);

          return (
            <AnnualPlannerRecordsLayoutCell
              key={`cell-${month.getFullYear()}-${month.getMonth()}-${day}`}
              start={props.start}
              end={props.end}
              date={date}
              records={data}
            />
          );
        }),
      )}
    </AnnualPlannerCalendarRecords>
  );
};
