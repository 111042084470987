import {
  ApiError,
  Assessment,
  AssessmentUpdate,
  FilterKeys,
  GET_ASSESSMENTS_QUERY,
  RecurringState,
  useCreateAssessmentMutation,
  useDeleteAssessmentMutation,
  useUpdateAssessmentMutation,
} from '@schooly/api';
import { AssessmentMethod } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import {
  RecurringConfirmOptions,
  RecurringConfirmType,
  RecurringLabel,
  useRecurringConfirmDialog,
} from '@schooly/components/recurring';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AssessmentForm } from './AssessmentCreateContent';

export interface UseAssessmentActionsProps {
  onClose?: () => void;
  onPreview?: (id: string) => void;
}

export interface UseAssessmentActionsReturn {
  saving: boolean;
  deleting: boolean;

  handleClose: () => void;
  handlePreview: (id: string) => void;

  createAssessment: (params: {
    form: AssessmentForm;
    withPreview: boolean;
    withNotifications?: boolean;
  }) => Promise<void>;
  updateAssessment: (params: {
    assessmentId: Assessment['id'];
    form: AssessmentForm;
    confirmed: boolean;
    showRecurringConfirmDialog?: boolean;
    recurringStateHasChanges?: boolean;
    withPreview: boolean;
    withNotifications?: boolean;
    recurrenceId?: string;
  }) => Promise<void>;
  deleteAssessment: (params: {
    assessmentId: Assessment['id'];
    confirmed?: boolean;
    name?: string;
    recurringState?: RecurringState | null;
    withNotifications?: boolean;
  }) => Promise<void>;
}

export const useAssessmentActions = ({
  onClose,
  onPreview,
}: UseAssessmentActionsProps = {}): UseAssessmentActionsReturn => {
  const { $t } = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { showNotification, showError } = useNotifications();
  const { schoolId } = useAuth();

  const { getConfirmation } = useConfirmationDialog();
  const { getRecurringApplyTypeConfirm } = useRecurringConfirmDialog();
  const recurringApplyTypeModalOptions = useRef<RecurringConfirmOptions[]>([
    {
      value: RecurringConfirmType.Single,
      label: $t({
        id: 'assessments-recurring-ThisAssessment',
      }),
    },
    {
      value: RecurringConfirmType.CurrentAndFollowing,
      label: $t({
        id: 'assessments-recurring-ThisAndFollowingAssessments',
      }),
    },
  ]);

  const updateAssessmentMutation = useUpdateAssessmentMutation();
  const createAssessmentMutation = useCreateAssessmentMutation();
  const deleteAssessmentMutation = useDeleteAssessmentMutation();

  const invalidateQueries = useInvalidateListQueriesFor('assessment');

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      navigate('/assessments');
    }
  }, [navigate, onClose]);

  const handlePreview = useCallback(
    (id: string) => {
      if (onPreview) {
        onPreview(id);
      } else {
        navigate(`/assessments/${id}`, { state: { replace: true } });
      }
    },
    [navigate, onPreview],
  );

  const showAssessmentNotification = useCallback(
    ({
      name,
      count,
      textId,
      recurrenceId,
    }: {
      name: string;
      count: number;
      textId: string;
      recurrenceId?: string;
    }) => {
      showNotification({
        textId,
        values: {
          assessmentsCount: count,
          assessmentName: name,
        },
        type: 'success',
        actions: recurrenceId
          ? [
              {
                textId: 'events-recurring-ViewSet',
                handler: () =>
                  navigate(
                    {
                      pathname: '/assessments',
                    },
                    {
                      state: {
                        [FilterKeys.RecurrenceId]: recurrenceId,
                      },
                    },
                  ),
                buttonColor: 'light',
              },
            ]
          : undefined,
      });
    },
    [navigate, showNotification],
  );

  const createAssessment = useCallback<UseAssessmentActionsReturn['createAssessment']>(
    async ({ form, withPreview, withNotifications = true }) => {
      if (!schoolId) {
        return;
      }

      const assessment = convertAssessmentFormToSave(form);

      await createAssessmentMutation.mutateAsync(
        { schoolId, assessment },
        {
          onSuccess: (response) => {
            if (withNotifications) {
              showAssessmentNotification({
                textId:
                  response.assessment_count > 1
                    ? 'assessments-notification-SavedMultiple'
                    : 'assessments-notification-Saved',

                count: response.assessment_count,
                name: assessment.name,
                recurrenceId: response.recurrence_id,
              });
            }

            if (withPreview) {
              handlePreview(response.id);
            } else {
              handleClose();
            }

            invalidateQueries();
          },
          onError: showError,
        },
      );
    },
    [
      createAssessmentMutation,
      handleClose,
      handlePreview,
      invalidateQueries,
      schoolId,
      showAssessmentNotification,
      showError,
    ],
  );

  /**
   * @param confirmed makes a Backend to force update assessment, and it's related entries.
   *
   * If there are any student entries in the assessment or in case of recurring assessments
   * if there is going to be an overwrite of individual changes in the following assessments,
   * the Backend won't update it as is.
   * It will return a `entries_to_delete` number which ways how many entries will be deleted
   * after assessment groups change.
   * It will return `overwrite: true` in case if following assessments in recurring set have individual changes.
   * The `?confirmed=true` argument force deletes affected entries and updates the assessment.
   */
  const updateAssessment = useCallback<UseAssessmentActionsReturn['updateAssessment']>(
    async ({
      assessmentId,
      form,
      confirmed = false,
      showRecurringConfirmDialog,
      recurringStateHasChanges,
      withPreview,
      withNotifications = true,
      recurrenceId,
    }) => {
      if (!schoolId || !assessmentId) {
        return;
      }

      const assessment = convertAssessmentFormToSave(form);

      try {
        let withFollowing;
        if (showRecurringConfirmDialog) {
          const applyType = await getRecurringApplyTypeConfirm({
            options: recurringApplyTypeModalOptions.current,
            title: $t(
              {
                id: 'assessments-recurring-Save',
              },
              {
                assessmentName: assessment.name,
              },
            ),
            actionButtonTextId: 'action-Save',
            subtitle: assessment.recurring_state && (
              <RecurringLabel recurringState={assessment.recurring_state} />
            ),
            warningTitle: recurringStateHasChanges
              ? $t({
                  id: 'assessments-recurring-RepetitionChangesWarning',
                })
              : undefined,
          });
          if (!applyType) return;
          withFollowing = applyType?.type === RecurringConfirmType.CurrentAndFollowing;
        }

        let response = await updateAssessmentMutation.mutateAsync({
          assessmentId,
          assessment,
          confirmed,
          withFollowing,
        });

        const { will_overwrite, entries_to_delete } = response;

        const overwriteConfirmed = will_overwrite
          ? await getConfirmation({
              textId: 'assessments-recurring-OverwriteConfirmation',
            })
          : null;

        if (will_overwrite && !overwriteConfirmed) {
          return;
        }

        const deletingConfirmed = entries_to_delete
          ? await getConfirmation({
              textId: response.success,
            })
          : null;

        if (entries_to_delete && !deletingConfirmed) {
          return;
        }

        if (will_overwrite || entries_to_delete) {
          response = await updateAssessmentMutation.mutateAsync({
            assessmentId,
            assessment,
            confirmed: true,
            withFollowing,
          });
        }

        if (withNotifications) {
          showAssessmentNotification({
            textId:
              response.assessment_count > 1
                ? 'assessments-notification-SavedMultiple'
                : 'assessments-notification-Saved',

            count: response.assessment_count,
            name: assessment.name,
            recurrenceId: response.assessment_count > 1 ? recurrenceId : undefined,
          });
        }

        if (withPreview) {
          handlePreview(assessmentId);
        } else {
          handleClose();
        }

        invalidateQueries();
      } catch (err) {
        showError(err as ApiError);
      }
    },
    [
      schoolId,
      updateAssessmentMutation,
      getConfirmation,
      showAssessmentNotification,
      invalidateQueries,
      getRecurringApplyTypeConfirm,
      $t,
      handlePreview,
      handleClose,
      showError,
    ],
  );

  /**
   * @param confirmed makes a Backend to force delete assessment, and it's related entries.
   *
   * If there are any student entries in the assessment, the Backend won't delete it as is.
   * It will return a `entries_to_delete` number which ways how many entries will be deleted
   * with the assessment.
   * The `?confirmed=true` argument force deletes the entries and the assessment.
   */
  const deleteAssessment = useCallback<UseAssessmentActionsReturn['deleteAssessment']>(
    async ({ assessmentId, confirmed = false, name, recurringState, withNotifications = true }) => {
      if (!assessmentId) {
        return;
      }

      try {
        let withFollowing;

        if (recurringState?.following_count) {
          const applyType = await getRecurringApplyTypeConfirm({
            options: recurringApplyTypeModalOptions.current,
            title: $t(
              {
                id: 'assessments-recurring-Delete',
              },
              {
                assessmentName: name,
              },
            ),
            actionButtonTextId: 'action-Delete',
            subtitle: recurringState && <RecurringLabel recurringState={recurringState} />,
          });
          if (!applyType) return;
          withFollowing = applyType?.type === RecurringConfirmType.CurrentAndFollowing;
        }

        let response = await deleteAssessmentMutation.mutateAsync({
          assessmentId,
          confirmed,
          withFollowing,
        });

        if (response.entries_to_delete) {
          const deletingConfirmed = await getConfirmation({
            textId: response.success,
          });
          if (!deletingConfirmed) {
            return;
          }

          response = await deleteAssessmentMutation.mutateAsync({
            assessmentId,
            confirmed: deletingConfirmed,
            withFollowing,
          });
        }

        if (withNotifications) {
          showAssessmentNotification({
            textId:
              response.assessment_count > 1
                ? 'assessments-notification-DeletedMultiple'
                : 'assessments-notification-Deleted',

            count: response.assessment_count,
            name: name ?? '',
          });
        }

        queryClient.invalidateQueries([GET_ASSESSMENTS_QUERY]);

        handleClose();
        invalidateQueries();
      } catch (err) {
        showError(err as ApiError);
      }
    },
    [
      deleteAssessmentMutation,
      showAssessmentNotification,
      queryClient,
      handleClose,
      invalidateQueries,
      getRecurringApplyTypeConfirm,
      $t,
      getConfirmation,
      showError,
    ],
  );

  return {
    saving: updateAssessmentMutation.isLoading || createAssessmentMutation.isLoading,
    deleting: deleteAssessmentMutation.isLoading,
    handleClose,
    handlePreview,
    createAssessment,
    updateAssessment,
    deleteAssessment,
  };
};

function convertAssessmentFormToSave(form: AssessmentForm): AssessmentUpdate {
  return {
    name: form.name,
    assessment_date: form.assessment_date,
    group_ids: form.groups.map((group) => group.id),
    methods: form.methods
      ?.filter((method) => method.method_type != null)
      .map(({ select_list_name, entries_max_score, ...method }: any) => ({
        ...method,
        score_out_of: method.score_out_of == null ? null : method.score_out_of,
        select_list_id: method.select_list_id ?? null,
      })) as AssessmentMethod[],
    display_name: form.useAssessmentNameAsDisplayName ? form?.name : form.display_name,
    recurring_state: form.recurring_state,
  };
}
