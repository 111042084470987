import { Icon, Stack, Tooltip, Typography } from '@mui/material';
import { Attention2Icon, ParentIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type InviteNotAcceptedWarningProps = {
  onShowFamily: () => void;
};

export const InviteNotAcceptedWarning: FC<InviteNotAcceptedWarningProps> = ({ onShowFamily }) => {
  const { $t } = useIntl();
  return (
    <Tooltip
      title={
        <Stack gap={1}>
          <Typography>{$t({ id: 'students-ParentInviteNotAccepted' })}</Typography>

          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer',
              pointerEvents: 'auto',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onShowFamily();
            }}
          >
            <Icon>
              <ParentIcon />
            </Icon>
            <Typography>
              <FormattedMessage id="students-ShowFamily" />
            </Typography>
          </Stack>
        </Stack>
      }
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            padding: theme.spacing(1.25),
          }),
        },
      }}
    >
      <Icon
        sx={(theme) => ({
          display: 'inline-flex',
          '.svg-icon': {
            path: { fill: theme.palette.error.main },
          },
        })}
      >
        <Attention2Icon className="reset-svg-currentColor" />
      </Icon>
    </Tooltip>
  );
};
