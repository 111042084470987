import { ChipProps, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';

export type RecurrenceLabelSelectProps = {
  title?: string;
} & Omit<ChipProps, 'label'>;

export const RecurrenceLabelSelect: FC<RecurrenceLabelSelectProps> = ({ title }) => {
  return title ? (
    <Typography
      variant="h3"
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
    >
      {title}
    </Typography>
  ) : (
    <Skeleton variant="rectangular" height={20} width="80%" />
  );
};
