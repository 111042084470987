import { Box, Stack, Typography } from '@mui/material';
import { listStudentsByParents, StudentWithParents } from '@schooly/api';
import { SchoolRelation } from '@schooly/api';
import { SORT_DIRECTION } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLastRefreshTimeContext } from '../../../context/LastRefreshTimeContext';
import { getUserTypeTextId } from '../../../helpers/misc';
import usePagedApiResourceWithFilter, {
  GetResourceFunction,
} from '../../../hooks/usePagedApiResourceWithFilter';
import SearchInput from '../../ui/SearchInput';
import PeopleList from '../PeopleList';

interface AddParentsHomeProps {
  onUserSelect: (user: SchoolRelation) => void;
}

const AddParentsHome: React.FC<AddParentsHomeProps> = ({ onUserSelect }) => {
  const { schoolId } = useAuth();
  const { formatMessage } = useIntl();
  const [filter, setFilter] = useState<string>('');
  const { lastRefreshTime } = useLastRefreshTimeContext();

  const getUserList = useCallback<GetResourceFunction<StudentWithParents>>(
    ({ pageSize, pageNumber, query }) => {
      if (!schoolId) {
        return undefined;
      }

      return listStudentsByParents({
        schoolId,
        pageSize,
        pageNumber,
        query,
        sort: [
          { columnTextId: 'edit_access', direction: SORT_DIRECTION.DESC },
          { columnTextId: 'students', direction: SORT_DIRECTION.ASC },
        ],
      });
    },
    [schoolId],
  );

  const {
    displayedList: displayedUserList,
    canShowMore,
    isFetching,
    isSearching,
    handleShowMore,
  } = usePagedApiResourceWithFilter<StudentWithParents>({
    getResource: getUserList,
    filter,
    onFilterUpdate: setFilter,
    lastRefreshTime,
  });

  const availableCount = displayedUserList ? displayedUserList.count : 0;

  const searchPlaceholder = useMemo(() => {
    if (!availableCount) {
      return undefined;
    }

    return formatMessage(
      { id: 'people-SearchAmongTypeCount' },
      {
        userTypePlural: formatMessage({
          id: getUserTypeTextId('student', availableCount > 1),
        }).toLowerCase(),
        count: availableCount,
      },
    );
  }, [availableCount, formatMessage]);

  const isUserDisabled = useCallback((user: StudentWithParents) => !user.edit_access, []);

  const renderHint = useCallback(
    (user: StudentWithParents) =>
      user.edit_access ? null : <FormattedMessage id="parents-NoEditAccessHint" />,
    [],
  );

  const renderBefore = useCallback(
    (user: StudentWithParents, index: number, users: StudentWithParents[]) => {
      if (user.edit_access) {
        return null;
      }

      // render only before the first non-editable row
      if (index === 0 || users[index - 1].edit_access) {
        return (
          <Box className="PeopleList-item disabled" sx={{ p: 0 }}>
            <Typography color="text.secondary" sx={{ pt: 2, pb: 1 }}>
              <FormattedMessage id="parents-NoAccess" />
            </Typography>
          </Box>
        );
      }

      return null;
    },
    [],
  );

  return (
    <Stack height="100%">
      <SearchInput
        value={filter}
        onChange={setFilter}
        isValueRemovable
        placeholder={searchPlaceholder}
      />
      <Stack
        sx={(theme) => ({
          flex: 1,
          overflowY: 'scroll',
          mx: theme.spacing(-2),
          px: theme.spacing(2),
        })}
      >
        <PeopleList
          users={displayedUserList ? displayedUserList.results : []}
          userType="student"
          studentWithParents
          isFetching={isFetching}
          isSearching={isSearching}
          canShowMore={canShowMore}
          onShowMore={handleShowMore}
          onUserSelect={onUserSelect}
          hideArrow={isUserDisabled}
          disabled={isUserDisabled}
          hint={renderHint}
          renderBefore={renderBefore}
        />
      </Stack>
    </Stack>
  );
};

export default AddParentsHome;
