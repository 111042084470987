import { Box, Stack, styled } from '@mui/material';

export const LimitedToEmptyStubStyled = styled(Stack)(({ theme }) => ({
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 'unset',
  marginTop: theme.spacing(3),

  '& > img': {
    width: 200,
    height: 200,
  },

  '& > .MuiTypography-root': {
    whiteSpace: 'pre',
  },
}));

export const LimitedToEmptyStubArrow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 'calc(50% + 148px)',
  bottom: theme.spacing(2.75),

  '& img': {
    width: 'auto',
    height: 'auto',
  },
}));
