import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { PageHeader } from '@schooly/components/filters';
import { PlusIcon } from '@schooly/style';
import { FC, MouseEventHandler, PropsWithChildren, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import eventIcon from './event.svg';
import holidayIcon from './holiday.svg';
import signupIcon from './signup.svg';

export interface EventsHeaderProps {
  value: 'events' | 'signUps';
  loading?: boolean;
}

export const EventsAndSignUpsPageHeader: FC<PropsWithChildren<EventsHeaderProps>> = ({
  value,
  loading,
  children,
}) => {
  const { $t } = useIntl();

  const { permissions } = useAuth();
  const canCreate = permissions.includes('event_manager') || permissions.includes('event_creator');

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <PageHeader
      buttonTextId="eventAndSignUp-new"
      pageTitle={
        <Tabs
          value={value}
          sx={{
            minHeight: 0,
            '& .MuiTab-root': {
              minHeight: 0,
              paddingBottom: 0.25,

              '& .MuiTypography-root': {
                color: 'text.secondary',
              },

              '&.Mui-selected .MuiTypography-root': { color: 'primary.main' },
            },
          }}
        >
          <Tab
            value="events"
            label={
              <Link to="/events">
                <Typography variant="h1">
                  <FormattedMessage id="section-Events" />
                </Typography>
              </Link>
            }
          />
          <Tab
            value="signUps"
            label={
              <Link to="/signups">
                <Typography variant="h1">
                  <FormattedMessage id="section-SignUps" />
                </Typography>
              </Link>
            }
          />
        </Tabs>
      }
      showActionButton={!loading && canCreate}
      buttonIcon={<PlusIcon />}
      onButtonClick={handleButtonClick}
    >
      {loading ? <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} /> : children}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: 0.5,
          },
        }}
        MenuListProps={{
          sx: {
            p: 1,

            '& .MuiMenuItem-root': {
              mb: 2,

              '&:last-of-type': {
                mb: 0,
              },

              '& .MuiListItemIcon-root': {
                mr: 2,
              },

              '& .MuiListItemText-root': {
                '& .MuiListItemText-secondary': {
                  mt: 0.5,
                  width: 278,
                  whiteSpace: 'pre-wrap',
                  color: 'text.primary',
                },
              },

              '&:hover .MuiListItemText-root .MuiListItemText-secondary': {
                color: 'primary.main',
              },
            },
          },
        }}
      >
        <Link to="/events/new">
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <img src={eventIcon} alt="Event" />
            </ListItemIcon>
            <ListItemText
              primary={$t({ id: 'events-header-Event-Title' })}
              primaryTypographyProps={{ variant: 'h3' }}
              secondary={$t({ id: 'events-header-Event-Description' })}
              secondaryTypographyProps={{ variant: 'body1' }}
            />
          </MenuItem>
        </Link>

        <Link to="/events/new?type=holiday">
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <img src={holidayIcon} alt="Holiday" />
            </ListItemIcon>
            <ListItemText
              primary={$t({ id: 'events-header-Holiday-Title' })}
              primaryTypographyProps={{ variant: 'h3' }}
              secondary={$t({ id: 'events-header-Holiday-Description' })}
              secondaryTypographyProps={{ variant: 'body1' }}
            />
          </MenuItem>
        </Link>

        <Link to="/signups/new">
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <img src={signupIcon} alt="Sign up" />
            </ListItemIcon>
            <ListItemText
              primary={$t({ id: 'events-header-SignUp-Title' })}
              primaryTypographyProps={{ variant: 'h3' }}
              secondary={$t({ id: 'events-header-SignUp-Description' })}
              secondaryTypographyProps={{ variant: 'body1' }}
            />
          </MenuItem>
        </Link>
      </Menu>
    </PageHeader>
  );
};
