import {
  ApiError,
  CHECK_SYSTEM_ROLE,
  checkUserRoleName,
  createUserRole,
  deleteUserRole,
  GET_ELIGIBLE_RELATIONS_QUERY,
  GetEligibleRelationsProps,
  getUserRole,
  SchoolUserType,
  SimpleListResult,
  updateUserRole,
  useGetEligibleRelationsQuery,
  UserRoleAccessType,
  UserRoleCategory,
  UserRolePermissions,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import {
  SchoolUserRole,
  USER_ROLE_CATEGORY_OPTIONS,
  USER_ROLE_PERMISSION_OPTIONS,
  USER_ROLE_TYPE_OPTIONS,
} from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { UPDATE_MAIN_LIST } from '../../../../constants/events';
import {
  RouterStateContext,
  RouterStateContextProps,
} from '../../../../context/router/RouterStateContext';
import { useRouter } from '../../../../context/router/useRouter';
import { convertSchoolUserRoleToNumericType, getUserTypeTextId } from '../../../../helpers/misc';
import { getAllPermissions, getUserPermissionScope } from '../../../../helpers/userRoles';
import useAppLocation from '../../../../hooks/useAppLocation';
import { FormState, useForm } from '../../../../hooks/useForm';
import useRequestWithProgress from '../../../../hooks/useRequestWithProgress';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import { queryClient } from '../../../../queryClient';
import { getLimitedToData } from '../utils';

export enum CreateUserRoleModalSection {
  Settings = 'settings',
  Permissions = 'permissions',
  Staff = 'staff',
  Configuration = 'configuration',
}

export type SelectedPermissions = Partial<Record<UserRoleCategory, UserRoleAccessType>>;
export type PermissionsToAssign = typeof USER_ROLE_PERMISSION_OPTIONS;

const USER_ROLE_CREATE_MODAL = 'UserRoleCreateModal';

export interface UserRoleModalContextState extends FormState<UserRoleModalContextState> {
  roleExists: boolean;
  roleName: string;
  defaultRoleName?: string;
  description?: string;
  mode?: CreateUserRoleModalSection;
  autoFocus?: string;
  permissions?: SelectedPermissions;
  selectedStuff: SimpleListResult[];
  selectedPermissionsToAssign: PermissionsToAssign;
  modalActive: boolean;
  hasAccess?: boolean;
  roleIsEditable: boolean;
  hasPermissionToAssign?: boolean;
  limitedToStaff: UserRoleLimitationsUpdate;
  prevLimitedToStaff: UserRoleLimitationsUpdate;
  isLimitedToOpen: boolean;
  originalData?: UserRoleModalContextState;
}
export interface UserRoleLimitationsUpdate extends Record<string, (string | number)[] | undefined> {
  school_property_ids: string[];
}

export const DEFAULT_LIMITED_TO: UserRoleLimitationsUpdate = {
  school_property_ids: [],
};

export const ROLE_USER_TYPE = 'staff';

export type RoleUserType = typeof ROLE_USER_TYPE;

export const getInitialState = (): UserRoleModalContextState => ({
  roleExists: false,
  roleName: '',
  defaultRoleName: undefined,
  description: '',
  limitedToStaff: DEFAULT_LIMITED_TO,
  prevLimitedToStaff: DEFAULT_LIMITED_TO,
  mode: CreateUserRoleModalSection.Settings,
  autoFocus: 'name',
  permissions: USER_ROLE_CATEGORY_OPTIONS.reduce(
    (acc, { category }) => ({ ...acc, [category]: 'NoAccess' }),
    {} as SelectedPermissions,
  ),
  selectedStuff: [],
  selectedPermissionsToAssign: [],
  modalActive: true,
  hasAccess: true,
  roleIsEditable: true,
  isLimitedToOpen: false,
});

export interface UserRoleModalProps {
  core?: boolean;
  assign?: boolean;
  onClose?: () => void;
  routePath?: string;
}

export const useUserRoleModal = ({
  core,
  assign,
  onClose,
  routePath = '/userroles',
}: UserRoleModalProps) => {
  const { state, setState, setContextState, setContextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<UserRoleModalContextState>;

  const { goBack } = useRouter();
  const { id: roleId } = useParams();
  const [searchParams] = useSearchParams();
  const section = searchParams.get('section') as CreateUserRoleModalSection;
  const isEditing = !!roleId;
  const { permissions: currentUserPermissions, schoolId = '' } = useAuth();
  const isUserRoleManager = currentUserPermissions.includes('userrole_manager');
  const isUserRoleViewer = currentUserPermissions.includes('userrole_viewer');

  const { schoolProperties: schoolStaffProperties } = useSchoolProperties(
    { schoolId, userType: SchoolUserRole.Staff },
    { refetchOnMount: 'always' },
  );

  const { mapPropertyIdsByType: mapStaffPropertyIdsByType } = useSchoolProperties({
    userType: SchoolUserRole.Staff,
    schoolId,
  });
  const { $t } = useIntl();
  const [init, setInit] = useState(false);

  const { getConfirmation } = useConfirmationDialog();

  const { showError, showNotification } = useNotifications();
  const isAssigning = !!assign;
  const canManageRole = isUserRoleManager && !!state?.roleIsEditable;
  const navigate = useNavigate();
  const location = useAppLocation();
  const { defaultValidity } = useSchoolYears();

  const {
    getCurrentPermissions,
    isPermissionChecked,
    isPermissionsEmpty,
    getPreselectedPermissions,
  } = usePermissionHelpers(state?.permissions || undefined);

  const getMode = useCallback(() => {
    if (isAssigning) {
      return CreateUserRoleModalSection.Staff;
    }

    if (section) {
      return section;
    }

    return CreateUserRoleModalSection.Settings;
  }, [isAssigning, section]);

  const request = async () => {
    if (!roleId) {
      const { limited_to } = getLimitedToData({});

      setContextState({
        hasAccess: isUserRoleManager,
        limitedToStaff: limited_to,
      });

      return;
    }

    try {
      const { user_role: role } = await getUserRole(roleId);
      const hasPermissionToAssign = role.permission_to_assign?.some((p) =>
        currentUserPermissions.includes(p as any),
      );

      if (isUserRoleViewer && !hasPermissionToAssign) {
        setContextState({
          hasAccess: false,
        });
      }

      const { limited_to } = getLimitedToData({ role });

      let permissions: SelectedPermissions = {};

      USER_ROLE_CATEGORY_OPTIONS.forEach(({ category }) => {
        USER_ROLE_TYPE_OPTIONS.forEach(({ type }) => {
          const permissionScope = getUserPermissionScope(category, type);

          if (
            !!permissionScope.length &&
            permissionScope.every((scopePermission) =>
              role.permissions?.some((p) => p === scopePermission),
            )
          ) {
            permissions = { ...permissions, [category]: type };
          }
        });

        if (!permissions[category]) {
          permissions = { ...permissions, [category]: 'NoAccess' };
        }
      });

      const selectedPermissionsToAssign: PermissionsToAssign =
        USER_ROLE_PERMISSION_OPTIONS.filter((data) => {
          const option = role.permission_to_assign?.some((value) => value === data.value);

          return option ? data : false;
        }) ?? [];

      setContextState({
        roleName: role.name,
        description: role.description ?? '',
        selectedStuff: role.staff,
        selectedPermissionsToAssign,
        permissions,
        defaultRoleName: role.name,
        hasAccess: isAssigning || role.editable,
        roleIsEditable: role.editable,
        hasPermissionToAssign,
        mode: getMode(),
        limitedToStaff: limited_to,
      });
    } catch (e) {
      setContextState({
        hasAccess: false,
      });
      showError(e as ApiError);
    }
  };
  const [loadUserRole, isUserRoleLoading] = useRequestWithProgress(request);

  const checkRoleNameExists = useCallback(
    async (currentName?: string) => {
      if (isEditing && currentName && currentName.trim() === state.defaultRoleName) {
        setContextState({ roleExists: false });
      }

      if (currentName && schoolId) {
        const { exists } = await checkUserRoleName(schoolId, currentName.trim());
        setContextState({ roleExists: exists });
      }

      setContextState({ roleExists: false });
    },
    [isEditing, schoolId, setContextState, state?.defaultRoleName],
  );

  const checkRoleNameExistsDebounced = useMemo(
    () => debounce(checkRoleNameExists, 500),
    [checkRoleNameExists],
  );

  const form = useForm({
    core,
    state,
    setState,
    rules: {
      roleName: {
        required: true,
        custom: (state) => {
          if (!state.roleName) {
            return { id: 'userRoles-EmptyRoleName' };
          }

          if (state?.roleExists) {
            return { id: 'userRoles-UserRoleExists' };
          }
        },
      },
      permissions: {
        required: true,
        custom: (state) => {
          if (
            !state.permissions ||
            (Object.keys(state.permissions) as Array<UserRoleCategory>).every(
              (p) => state.permissions?.[p] === 'NoAccess',
            )
          ) {
            return { id: 'userRoles-AtLeastOnePermission' };
          }
        },
      },
    },
  });

  const onGroupNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.set('roleName', e.target.value);

      checkRoleNameExistsDebounced(e.target.value);
    },
    [checkRoleNameExistsDebounced, form],
  );
  const onDescriptionChange = useCallback(
    (description: string) => {
      setState({ ...state, description });
    },
    [setState, state],
  );

  const closeModal = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      goBack();
    }
    setState({ ...getInitialState(), modalActive: false });
  }, [goBack, onClose, setState]);

  const setMode = useCallback(
    (mode: CreateUserRoleModalSection, autoFocus?: string) => {
      setState({ ...state, mode, autoFocus });
    },
    [setState, state],
  );

  useEffect(() => {
    if (!core || !schoolStaffProperties || isUserRoleLoading) {
      return;
    }

    if (!init || !state) {
      setInit(true);

      setContextName(USER_ROLE_CREATE_MODAL);
      setContextState({
        ...getInitialState(),
      });
      loadUserRole();
    }
  }, [
    schoolStaffProperties,
    init,
    isUserRoleLoading,
    core,
    setContextName,
    setContextState,
    loadUserRole,
    getMode,
    state,
  ]);

  const onStaffClick = useCallback(
    (staff: SimpleListResult) => {
      if (state.selectedStuff?.some(({ relation_id }) => relation_id === staff.relation_id)) {
        setState({
          ...state,
          selectedStuff: state.selectedStuff?.filter(
            ({ relation_id }) => relation_id !== staff.relation_id,
          ),
        });
      } else {
        setState({
          ...state,
          selectedStuff: [...(state.selectedStuff || []), staff],
        });
      }
    },
    [setState, state],
  );

  const setPermissionsToAssign = useCallback(
    (permissionToAssign: PermissionsToAssign) => {
      setState({
        ...state,
        selectedPermissionsToAssign: permissionToAssign,
      });
    },
    [setState, state],
  );

  const setSelectedPermissions = useCallback(
    (category: UserRoleCategory, type: UserRoleAccessType) => {
      const preselectedPermissions = getPreselectedPermissions(category, type);

      if (!state.permissions) {
        form.set('permissions', {
          ...preselectedPermissions,
          permissions: { [category]: type },
        });

        return;
      }

      const permissions = (
        Object.keys(state.permissions) as Array<UserRoleCategory>
      ).reduce<SelectedPermissions>((acc, key) => {
        if (category !== key && state.permissions) {
          acc[key] = state.permissions[key];
        }

        return acc;
      }, {});

      form.set('permissions', { ...permissions, ...preselectedPermissions, [category]: type });
    },
    [form, getPreselectedPermissions, state],
  );

  const editRole = async () => {
    if (!roleId || !state.permissions || !form.isValid) {
      return;
    }

    const relationIds = state.selectedStuff.map(({ relation_id }) => relation_id);

    const params = isAssigning
      ? {
          id: roleId,
          relation_ids: relationIds,
          school_property_ids: state.limitedToStaff.school_property_ids,
        }
      : {
          id: roleId,
          name: state.roleName,
          description: state.description ?? null,
          permissions: getCurrentPermissions(),
          permission_to_assign: state.selectedPermissionsToAssign.map(({ value }) => value),
          relation_ids: relationIds,
          school_property_ids: state.limitedToStaff.school_property_ids,
        };

    try {
      await updateUserRole(params);
      document.dispatchEvent(new Event(UPDATE_MAIN_LIST));
      closeModal();

      showNotification({
        textId: 'confirmation-UserRoleUpdate',
        type: 'success',
      });
    } catch (e) {
      showError(e as ApiError);
    }
  };

  const createRole = async () => {
    if (!schoolId || !state.roleName || !state.permissions || !form.isValid) {
      return;
    }
    try {
      const params = {
        school_id: schoolId,
        name: state.roleName ?? '',
        description: state.description ?? '',
        permissions: getCurrentPermissions(),
        permission_to_assign: state.selectedPermissionsToAssign.map(({ value }) => value),
        relation_ids: state.selectedStuff.map(({ relation_id }) => relation_id),
        school_property_ids: state.limitedToStaff.school_property_ids,
      };
      const response = await createUserRole(params);
      showNotification({
        textId: 'confirmation-UserRoleCreate',
        values: { userRoleName: state.roleName },
        type: 'success',
        actions: [
          {
            textId: 'userRoles-ViewUserRole',
            handler: () =>
              navigate(`${routePath}/${response.id}`, {
                state: location.state,
              }),
            buttonColor: 'light',
          },
        ],
      });
      document.dispatchEvent(new Event(UPDATE_MAIN_LIST));

      setState(getInitialState());
      closeModal();
    } catch (e) {
      showError(e as ApiError);
    }
  };

  const removeRole = async () => {
    if (!roleId || !schoolId) {
      return;
    }

    try {
      await deleteUserRole(roleId);
      queryClient.invalidateQueries([CHECK_SYSTEM_ROLE]);
      closeModal();
    } catch (e) {
      showError(e as ApiError);
    }
  };

  const [submitRole, isSaving] = useRequestWithProgress(roleId ? editRole : createRole);
  const [deleteRole, isDeleting] = useRequestWithProgress(removeRole);

  const onLimitedToChange = useCallback(
    (limitedToStaff: UserRoleLimitationsUpdate) => {
      const hasLimitedTo = limitedToStaff.school_property_ids.length > 0;

      setContextState({
        limitedToStaff,
        prevLimitedToStaff: state.limitedToStaff,
        selectedStuff: hasLimitedTo ? state.selectedStuff : [],
      });
    },
    [setContextState, state?.limitedToStaff, state?.selectedStuff],
  );

  const [checkLimitedToParams, setCheckLimitedToParams] = useState<{
    query?: GetEligibleRelationsProps;
    type?: RoleUserType;
    roleUsers?: SimpleListResult[];
  }>({});

  const { isFetching: checkingLimitedTo } = useGetEligibleRelationsQuery(
    checkLimitedToParams.query!,
    {
      enabled: checkLimitedToParams.query != null,
      onSuccess: async (data) => {
        const { type, roleUsers } = checkLimitedToParams;

        if (!type || !roleUsers) {
          return;
        }

        const resMap = data.relation_ids.reduce<Record<string, boolean>>((prev, relationId) => {
          prev[relationId] = true;
          return prev;
        }, {});

        const deletedUsers = roleUsers.filter((user) => !resMap[user.relation_id]);

        if (deletedUsers.length) {
          const userTypePlural = $t({
            id: getUserTypeTextId(type, deletedUsers.length > 1),
          }).toLowerCase();

          const isConfirmed = await getConfirmation({
            textId: 'userRoles-LimitedToWarning',
            textValues: {
              number: deletedUsers.length,
              userType: userTypePlural,
            },
          });

          if (isConfirmed) {
            setContextState(
              { selectedStuff: roleUsers.filter((user) => resMap[user.relation_id]) },
              true,
            );
          } else {
            onLimitedToChange(state.prevLimitedToStaff);
          }
        }
      },
    },
  );

  const checkLimitedTo = useCallback(
    async (roleUsers: SimpleListResult[], type: RoleUserType) => {
      if (!schoolId || !defaultValidity) {
        return;
      }

      const query: GetEligibleRelationsProps = {
        schoolId: schoolId,
        date_from: defaultValidity.start,
        date_to: defaultValidity.end,
        user_role: convertSchoolUserRoleToNumericType(type as SchoolUserType),
        relation_ids: roleUsers.map(({ relation_id }) => relation_id),
        ...mapStaffPropertyIdsByType<string>(
          state.limitedToStaff.school_property_ids,
          (schoolProperty) => schoolProperty.id,
        ),
      };

      await queryClient.invalidateQueries([GET_ELIGIBLE_RELATIONS_QUERY]);
      setCheckLimitedToParams({ query, type, roleUsers });
    },
    [schoolId, defaultValidity, mapStaffPropertyIdsByType, state?.limitedToStaff],
  );

  const onLimitedToOpen = useCallback(() => {
    setContextState({ isLimitedToOpen: true });
  }, [setContextState]);

  const onLimitedToClose = useCallback(() => {
    setContextState({ isLimitedToOpen: false });

    if (!state.selectedStuff.length) {
      return;
    }

    if (isEqual(state.selectedStuff, state.originalData?.selectedStuff)) {
      return;
    }

    checkLimitedTo(state.selectedStuff, ROLE_USER_TYPE);
  }, [checkLimitedTo, setContextState, state?.originalData?.selectedStuff, state?.selectedStuff]);

  return {
    ...state,
    roleId,
    form,
    isUserRoleManager,
    isUserRoleViewer,
    isPermissionChecked,
    isSaving,
    isDeleting,
    isEditing,
    isFetching: isUserRoleLoading || !state,
    canManageRole,
    isPermissionsEmpty,
    schoolStaffProperties,
    checkingLimitedTo,
    actions: {
      onGroupNameChange,
      onDescriptionChange,
      setMode,
      closeModal,
      setSelectedPermissions,
      onStaffClick,
      setPermissionsToAssign,
      submitRole,
      deleteRole,
      onLimitedToChange,
      onLimitedToOpen,
      onLimitedToClose,
    },
  };
};

export const usePermissionHelpers = (selectedPermissions?: SelectedPermissions) => {
  const isPermissionsEmpty = useMemo(() => {
    if (!selectedPermissions) {
      return false;
    }

    return (
      !Object.keys(selectedPermissions).length ||
      (Object.keys(selectedPermissions) as Array<UserRoleCategory>).every(
        (p) => selectedPermissions?.[p] === 'NoAccess',
      )
    );
  }, [selectedPermissions]);

  const getCurrentPermissions = useCallback(() => {
    if (!selectedPermissions) {
      return [];
    }
    const _permissions = (Object.keys(selectedPermissions) as Array<UserRoleCategory>).reduce<
      Array<string>
    >((acc, category) => {
      const roleType = selectedPermissions?.[category];
      if (!roleType) {
        return acc;
      }

      const accessTypeArr = USER_ROLE_TYPE_OPTIONS.map((o) => o.type).filter(
        (t) => t !== 'NoAccess',
      );

      const index = accessTypeArr.findIndex((type) => type === roleType);

      if (index === -1) {
        return acc;
      }

      const permissionScope = getAllPermissions(
        category as UserRoleCategory,
        accessTypeArr.slice(0, index + 1),
      ).map((p) => p.toLowerCase());

      return [...acc, ...permissionScope];
    }, []);

    return Array.from(new Set(_permissions)) as UserRolePermissions[];
  }, [selectedPermissions]);

  const isPermissionChecked = useCallback(
    (type: UserRoleAccessType, category: UserRoleCategory, currentIndex: number) => {
      if (!selectedPermissions) {
        return { checked: false, complexChecked: false };
      }

      if (type === 'NoAccess') {
        return { checked: selectedPermissions?.[category] === type, complexChecked: false };
      }

      const slicedArr = USER_ROLE_TYPE_OPTIONS.slice(
        currentIndex,
        USER_ROLE_TYPE_OPTIONS.length + 1,
      );

      const checked = slicedArr.some(({ type }) => selectedPermissions?.[category] === type);

      const complexChecked = USER_ROLE_TYPE_OPTIONS.slice(
        currentIndex + 1,
        USER_ROLE_TYPE_OPTIONS.length + 1,
      ).some(({ type }) => selectedPermissions?.[category] === type);

      return { complexChecked, checked: complexChecked ? false : checked };
    },
    [selectedPermissions],
  );

  const getPreselectedPermissions = useCallback(
    (category: UserRoleCategory, type: UserRoleAccessType) => {
      const preselectedPermissions: SelectedPermissions = {};

      const hasReadPermission = (category: UserRoleCategory) => {
        return USER_ROLE_TYPE_OPTIONS.some(
          ({ type }) => type !== 'NoAccess' && selectedPermissions?.[category] === type,
        );
      };

      const hasNoAccess = (category: UserRoleCategory) => {
        return selectedPermissions?.[category] === 'NoAccess';
      };

      switch (category) {
        case 'Registrations':
          if (type !== 'Manager') {
            return;
          }

          if (hasNoAccess('StudentsAndParents')) {
            preselectedPermissions['StudentsAndParents'] = 'Viewer';
          }
          break;

        case 'Applications':
          if (type === 'NoAccess') {
            return;
          }

          (['StudentsAndParents', 'CustomFields'] as UserRoleCategory[]).forEach((c) => {
            if (type === 'Manager' && c === 'CustomFields') {
              preselectedPermissions[c] = 'Viewer';
              return;
            } else if (type === 'Manager' && c === 'StudentsAndParents') {
              preselectedPermissions[c] = 'Manager';
            }

            if (type === 'Viewer' && hasNoAccess(c)) {
              preselectedPermissions[c] = 'Viewer';
            }
          });
          break;

        case 'StudentsAndParents':
          if (type === 'NoAccess') {
            (['Registrations', 'Messages', 'Conduct', 'Attendance'] as UserRoleCategory[]).forEach(
              (c) => {
                if (hasReadPermission(c)) {
                  preselectedPermissions[c] = 'NoAccess';
                }
              },
            );
          } else if (hasNoAccess('CustomFields')) {
            preselectedPermissions['CustomFields'] = 'Viewer';
          }

          break;

        case 'EmploymentCases':
          if (type !== 'Manager') {
            return;
          }
          if (hasNoAccess('Staff')) {
            preselectedPermissions['Staff'] = 'Viewer';
          }
          break;

        case 'Staff':
          if (type !== 'NoAccess') {
            return;
          }

          (['EmploymentCases', 'Messages'] as UserRoleCategory[]).forEach((c) => {
            if (hasReadPermission(c)) {
              preselectedPermissions[c] = 'NoAccess';
            }
          });

          break;

        case 'Messages':
          if (type !== 'Creator' && type !== 'Manager') {
            return;
          }

          (['StudentsAndParents', 'Staff', 'Groups'] as UserRoleCategory[]).forEach((c) => {
            if (hasNoAccess(c)) {
              preselectedPermissions[c] = 'Viewer';
            }
          });

          break;

        case 'AssessmentsAndReports':
          if (type === 'NoAccess') {
            return;
          }
          if (hasNoAccess('Groups')) {
            preselectedPermissions['Groups'] = 'Viewer';
          }

          break;

        case 'Attendance':
          if (type === 'NoAccess') {
            return;
          }

          (['StudentsAndParents', 'Groups'] as UserRoleCategory[]).forEach((c) => {
            if (hasNoAccess(c)) {
              preselectedPermissions[c] = 'Viewer';
            }
          });

          break;
        case 'Conduct':
          if (type !== 'Manager') {
            return;
          }

          if (hasNoAccess('StudentsAndParents')) {
            preselectedPermissions['StudentsAndParents'] = 'Viewer';
          }

          break;
        case 'Groups':
          if (type !== 'NoAccess') {
            return;
          }

          (['AssessmentsAndReports', 'Attendance', 'Messages'] as UserRoleCategory[]).forEach(
            (c) => {
              if (hasReadPermission(c)) {
                preselectedPermissions[c] = 'NoAccess';
              }
            },
          );

          break;

        default:
          break;
      }
      return preselectedPermissions;
    },
    [selectedPermissions],
  );

  return {
    isPermissionsEmpty,
    getCurrentPermissions,
    isPermissionChecked,
    getPreselectedPermissions,
  };
};
