import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="15" cy="15" r="15" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8242 10.3757C15.0586 10.61 15.0586 10.9899 14.8242 11.2242L11.0485 15L14.8242 18.7757C15.0586 19.01 15.0586 19.3899 14.8242 19.6242C14.5899 19.8585 14.21 19.8585 13.9757 19.6242L9.77571 15.4242C9.5414 15.1899 9.5414 14.81 9.77571 14.5757L13.9757 10.3757C14.21 10.1414 14.5899 10.1414 14.8242 10.3757Z"
      fill="#5D6688"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5242 10.3757C20.7586 10.61 20.7586 10.9899 20.5242 11.2242L16.7485 15L20.5242 18.7757C20.7586 19.01 20.7586 19.3899 20.5242 19.6242C20.2899 19.8585 19.91 19.8585 19.6757 19.6242L15.4757 15.4242C15.2414 15.1899 15.2414 14.81 15.4757 14.5757L19.6757 10.3757C19.91 10.1414 20.2899 10.1414 20.5242 10.3757Z"
      fill="#5D6688"
    />
  </svg>
);
