import {
  Backdrop,
  Box,
  ClickAwayListener,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { PROPERTIES_TEXT_IDS } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { ChevronDownSmallIcon } from '@schooly/style';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useIntl } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { FiltersDropdownButton } from '../FiltersDropdownButton';
import { ArrangeBySelectRow } from './ArrangeBySelectRow';

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

type ArrangeByDropdownProps<T> = {
  label?: string;
  options: readonly T[];
  selectedOption?: T;
  onSelectOption: (v: T) => void;
  onClear: () => void;
  onRemove: () => void;
  getOptionLabelId?: (o: T) => string;
} & Partial<TooltipProps>;

export type ArrangeByDropdown = {
  open: () => void;
};

const ArrangeByDropdownComponent = <T extends FilterKeys>(
  {
    label,
    options,
    selectedOption,
    onSelectOption,
    onClear,
    onRemove,
    getOptionLabelId,
    ...tooltipProps
  }: ArrangeByDropdownProps<T>,
  ref: React.ForwardedRef<ArrangeByDropdown>,
) => {
  const { $t } = useIntl();
  const [opened, open, close] = useFlag();

  const handleRemove = useCallback(() => {
    close();
    onRemove();
  }, [close, onRemove]);

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    [open],
  );

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 250,
                  maxWidth: 250,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0.5, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <ExpandedSelect
                width={250}
                hasSelectedValue={!!selectedOption}
                renderContent={() => (
                  <>
                    {options.map((option) => (
                      <ArrangeBySelectRow
                        arrangeBy={option}
                        isSelected={selectedOption === option}
                        getOptionLabelId={getOptionLabelId}
                        onClick={() => {
                          onSelectOption(option);
                          close();
                        }}
                      />
                    ))}
                  </>
                )}
                onClose={close}
                onClear={onClear}
              >
                {selectedOption && (
                  <Typography variant="h3">
                    {$t({
                      id: getOptionLabelId
                        ? getOptionLabelId(selectedOption)
                        : PROPERTIES_TEXT_IDS[selectedOption],
                    })}
                  </Typography>
                )}
              </ExpandedSelect>
            }
            {...tooltipProps}
          >
            <Box
              flexDirection="row"
              sx={{
                cursor: 'pointer',
                position: 'relative',
                zIndex: (theme) => (opened ? theme.zIndex.drawer + 2 : undefined),
              }}
              alignItems="center"
              onClick={open}
            >
              {!opened ? (
                <Stack
                  flexDirection="row"
                  gap={0.5}
                  onClick={open}
                  sx={{ cursor: 'pointer' }}
                  alignItems="center"
                >
                  <Typography variant="h3" color="common.grey3">
                    {label || $t({ id: 'filter-ArrangeBy' })}
                    {selectedOption ? ':' : ''}
                  </Typography>
                  {selectedOption && (
                    <Typography variant="h3" onClick={open}>
                      {$t({
                        id: getOptionLabelId
                          ? getOptionLabelId(selectedOption)
                          : PROPERTIES_TEXT_IDS[selectedOption],
                      })}
                    </Typography>
                  )}
                  <ChevronDownSmallIcon color="black" />
                </Stack>
              ) : (
                <FiltersDropdownButton
                  onClick={(e) => {
                    e.stopPropagation();
                    close();
                  }}
                  onClear={handleRemove}
                >
                  {label || $t({ id: 'filter-ArrangeBy' })}
                </FiltersDropdownButton>
              )}
            </Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ArrangeByDropdown = forwardRef(ArrangeByDropdownComponent);
