import { Box, Stack } from '@mui/material';
import { AssessmentMethodType } from '@schooly/api';
import * as React from 'react';
import { FC } from 'react';

import { DeleteMethodButton } from './AssessmentsCreateModalSettings.styled';
import { AssessmentsCreateModalSettingsMethodType } from './AssessmentsCreateModalSettingsMethodType';

export interface AssessmentsCreateModalSettingsDefaultProps {
  autoFocus?: boolean;
  onMethodChange?: (methodType?: AssessmentMethodType) => void;
  onDelete?: () => void;
}

export const AssessmentsCreateModalSettingsDefault: FC<AssessmentsCreateModalSettingsDefaultProps> =
  ({ autoFocus, onMethodChange, onDelete }) => {
    return (
      <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
        <AssessmentsCreateModalSettingsMethodType onChange={onMethodChange} autoFocus={autoFocus} />
        <Box>
          <DeleteMethodButton onClick={onDelete} />
        </Box>
      </Stack>
    );
  };
