import { Button, IconButton, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect, scrollToErrorInput } from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import {
  GENDER_OPTIONS,
  LANGUAGE_AREAS_OPTIONS,
  LANGUAGE_OPTIONS,
  NATIONALITY_OPTIONS,
  USER_NATIONALITIES_MAX_COUNT,
} from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
// eslint-disable-next-line @nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FormRadioGroup } from 'apps/web/src/components/uikit-components/FormCheckbox/FormRadioGroup';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { CreateChildForm } from './CreateChildContent.type';
import { CustomFieldsFormContainer } from './CustomFieldsFormContainer';
import { LanguageFormContainer } from './LanguageFormContainer';
import { PreviousSchoolFormContainer } from './PreviousSchoolFormContainer';
import { RemovePersonConfirmDialog } from './RemovePersonConfirmDialog';
import { RowsContainer } from './RowsContainer';

interface CreateChildContentProps {
  onClose: () => void;
  formCustomFields: CreateChildForm['custom_fields'];
  child?: CreateChildForm;
  onRemoveChild: (childId: CreateChildForm['id']) => void;
  onSubmit: (childForm: CreateChildForm) => void;
}

export const CreateChildContent: FC<CreateChildContentProps> = ({
  formCustomFields,
  onSubmit,
  onClose,
  child,
  onRemoveChild,
}) => {
  const [isConfirmationDialogOpen, showConfirmationDialog, hideConfirmationDialog] = useFlag();

  const form = useForm<CreateChildForm>({
    defaultValues: child ?? {
      id: uuidv4(),
      language: [{ language: undefined, speaking: undefined, writing: undefined }],
      custom_fields: formCustomFields,
      date_of_birth: '',
      given_name: '',
      last_name: '',
      known_as: '',
      preferred_start_date: '',
      nationalities: [],
    },
    shouldFocusError: false,
  });

  const nationalities = form.watch('nationalities');
  const givenName = form.watch('given_name');
  const lastName = form.watch('last_name');

  const { $t } = useIntl();

  const childName = useMemo(() => {
    if (!givenName && !lastName) {
      return $t({
        id: 'applications-Child-AddChildInfo',
      });
    }

    return getUserFullName({ last_name: lastName ?? '', given_name: givenName });
  }, [$t, givenName, lastName]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, scrollToErrorInput)}>
        <ModalHeader title={childName} active>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>

        <ModalMain data-test-id="modal-main">
          <ModalContent active>
            <Controller
              control={form.control}
              name="preferred_start_date"
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <DateSelect
                    name={field.name}
                    ref={field.ref}
                    onSetDate={(date) => {
                      field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                    }}
                    date={field.value}
                    placeholder={$t({ id: 'applications-PreferredStartingDate' })}
                    requiredLabel="required"
                    error={fieldState.error}
                    onClear={() => field.onChange('')}
                    testId="date-input"
                  />
                );
              }}
            />

            <Stack gap={2} pt={4}>
              <Typography variant="h4" color="text.secondary">
                <FormattedMessage id="applications-PersonalInformation" />
              </Typography>

              <Stack direction="row" gap={2}>
                <ControlTextField
                  name="given_name"
                  control={form.control}
                  rules={{ required: true }}
                  label={$t({ id: 'peopleDetail-GivenNames' })}
                  canClear
                  fullWidth
                  data-test-id="child-given-name-block"
                />

                <ControlTextField
                  name="last_name"
                  control={form.control}
                  rules={{ required: true }}
                  label={$t({ id: 'peopleDetail-FamilyName' })}
                  canClear
                  fullWidth
                  data-test-id="child-last-name-block"
                />
              </Stack>

              <Stack direction="row" gap={2} sx={{ width: '100%' }}>
                <ControlTextField
                  name="known_as"
                  control={form.control}
                  label={$t({ id: 'peopleDetail-PreferredName' })}
                  canClear
                  fullWidth
                />

                <Stack width="100%">
                  <Controller
                    control={form.control}
                    name="date_of_birth"
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                      return (
                        <DateSelect
                          ref={field.ref}
                          name={field.name}
                          onSetDate={(date) => {
                            field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                          }}
                          date={field.value}
                          placeholder={$t({ id: 'peopleDetail-DateOfBirth' })}
                          requiredLabel="required"
                          error={fieldState.error}
                          onClear={() => {
                            field.onChange('');
                          }}
                          testId="child-date-of-birth-input"
                          hideTodayButton
                        />
                      );
                    }}
                  />
                </Stack>
              </Stack>

              <Stack gap={1.5} sx={{ width: '100%' }}>
                <FormRadioGroup
                  rules={{ required: true }}
                  options={[...GENDER_OPTIONS].reverse()}
                  name="gender"
                />

                <FormSelect2
                  name="nationalities"
                  labelTextId={
                    nationalities.length <= 1
                      ? 'peopleDetail-Nationality'
                      : 'peopleDetail-Nationality-plural'
                  }
                  options={NATIONALITY_OPTIONS}
                  rules={{ required: true }}
                  multiple
                  maxCount={USER_NATIONALITIES_MAX_COUNT}
                />
              </Stack>

              <Stack gap={2}>
                <LanguageFormContainer
                  options={LANGUAGE_OPTIONS}
                  levelOptions={LANGUAGE_AREAS_OPTIONS}
                />
              </Stack>

              {!!formCustomFields.length && (
                <Stack pt={1}>
                  <RowsContainer
                    color="text.secondary"
                    containerTitle="applications-AdditionalInformation"
                  >
                    <CustomFieldsFormContainer />
                  </RowsContainer>
                </Stack>
              )}

              <Stack pt={2}>
                <RowsContainer color="text.secondary" containerTitle="applications-PreviousSchools">
                  <PreviousSchoolFormContainer />
                </RowsContainer>
              </Stack>
            </Stack>
          </ModalContent>
        </ModalMain>

        <ModalFooter active sx={{ justifyContent: child ? 'space-between' : 'flex-end' }}>
          {child && (
            <Button variant="outlined" startIcon={<DeleteIcon />} onClick={showConfirmationDialog}>
              <FormattedMessage id="action-Delete" />
            </Button>
          )}
          <Button type="submit" endIcon={<CheckIcon />} data-test-id="save-child-button">
            <FormattedMessage id="action-Save" />
          </Button>
        </ModalFooter>

        <RemovePersonConfirmDialog
          isOpen={isConfirmationDialogOpen}
          onClose={hideConfirmationDialog}
          onConfirm={() => child && onRemoveChild(child.id)}
          personName={child ? getUserFullName(child) : ''}
        />
      </form>
    </FormProvider>
  );
};
