import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import React, { FC } from 'react';

type LoadMoreProps = {
  isLoading: boolean;
  onFetchNextPage: () => void;
};
export const LoadMore: FC<LoadMoreProps> = ({ isLoading, onFetchNextPage }) => {
  const loaderRef = useInfiniteScroll(isLoading, onFetchNextPage);
  return <div ref={loaderRef} />;
};
