import { Icon, Stack, Typography } from '@mui/material';
import { LegalEntity, useGetLegalEntitiesListQuery } from '@schooly/api';
import {
  DropdownSelect,
  DropdownSelectProps,
  SelectContentSkeleton,
  SelectSearchInput,
} from '@schooly/components/filters';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

type LegalEntitySelectProps = PropsWithChildren<{
  schoolId: string;
  selectedEntity?: LegalEntity;
  isAdmin?: boolean;
  onSelect: (v: LegalEntity) => void;
  legalEntityId: string;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const LegalEntitySelect: FC<LegalEntitySelectProps> = ({
  schoolId,
  selectedEntity,
  onSelect,
  isAdmin,
  placeholder,
  legalEntityId,
  ...dropdownProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<DropdownSelect | null>(null);

  const { data, params, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading, setParams } =
    useGetLegalEntitiesListQuery(
      {
        schoolId,
        query: '',
        archived: false,
      },
      { refetchOnMount: 'always', enabled: !!schoolId },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const entities =
      data.pages
        .reduce((prev, curr) => [...prev, ...curr.results], [] as LegalEntity[])
        .filter(({ id }) => id !== legalEntityId) ?? [];

    if (!entities.length) {
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );
    }

    return (
      <>
        {entities.map((entity) => {
          const isSelected = selectedEntity?.id === entity.id;

          return (
            <Stack
              key={entity.id}
              onClick={() => {
                onSelect(entity);
                handleChangeQuery('');
                dropdownRef.current?.close();
              }}
              flexDirection="row"
              alignItems="center"
              px={1}
              gap={2}
              py={0.5}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                },
              })}
            >
              <TypographyWithOverflowHint
                flex={1}
                color={!isSelected ? 'common.grey2' : undefined}
                variant="h3"
                noWrap
              >
                {entity.name}
              </TypographyWithOverflowHint>

              <Icon
                sx={{
                  visibility: isSelected ? 'visible' : 'hidden',
                }}
              >
                <DoneIcon />
              </Icon>
            </Stack>
          );
        })}
      </>
    );
  }, [data, handleChangeQuery, legalEntityId, onSelect, selectedEntity?.id]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdownRef}
      placeholder={placeholder}
      hasValues={!!selectedEntity}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      onFetchNextPage={fetchNextPage}
    >
      {(opened) => (
        <>
          {selectedEntity && (
            <TypographyWithOverflowHint variant="h3">
              {selectedEntity.name}
            </TypographyWithOverflowHint>
          )}
          {opened && !selectedEntity && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={params.query || ''}
              onChangeText={handleChangeQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
