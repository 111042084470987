import { Button, IconButton } from '@mui/material';
import {
  CrossIcon,
  EyeIcon,
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SimpleButton,
} from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

type LegalEntityStartRemoveModalProps = {
  legalEntityName: string;
  canDelete?: boolean;
  onViewList?: () => void;
  onReplace: () => void;
  onRemove: () => void;
  onClose: () => void;
};

export const LegalEntityStartRemoveModal: FC<LegalEntityStartRemoveModalProps> = ({
  legalEntityName,
  canDelete,
  onViewList,
  onReplace,
  onRemove,
  onClose,
}) => {
  const { $t } = useIntl();

  return (
    <ModalConfirm open fullWidth onClose={onClose}>
      <ModalHeader
        active
        title={$t(
          { id: canDelete ? 'legalEntities-DeleteTitle' : 'legalEntities-ArchiveTitle' },
          { legalEntityName },
        )}
        multiline
        sx={(theme) => ({ borderBottom: 'none', pb: 0, gap: theme.spacing(2.5) })}
      >
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>

      <ModalContent active sx={(theme) => ({ py: theme.spacing(2) })}>
        {onViewList && (
          <SimpleButton
            startIcon={<EyeIcon />}
            onClick={onViewList}
            sx={{ alignSelf: 'flex-start' }}
          >
            {$t({ id: 'legalEntities-ShowListOfProductsAndTypes' })}
          </SimpleButton>
        )}
      </ModalContent>
      <ModalFooter
        active
        withBorderTop={false}
        sx={{ gap: 1, '& .MuiButton-root': { flex: '1 1 50%' } }}
      >
        <Button
          variant="outlined"
          //TR-6076 Archiving without replacing will be available after product archiving is done
          disabled={!canDelete}
          onClick={onRemove}
        >
          {$t({ id: canDelete ? 'action-DeleteWithoutReplacing' : 'action-Archive' })}
        </Button>
        <Button onClick={onReplace}>{$t({ id: 'action-Replace' })}</Button>
      </ModalFooter>
    </ModalConfirm>
  );
};
