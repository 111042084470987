export function downloadFile(
  data: ArrayBuffer,
  filename: string,
  type: Blob['type'] = 'application/pdf',
) {
  const decodedFilename = decodeURI(filename);
  const url = window.URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', decodedFilename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}
