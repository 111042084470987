import { getMiniList } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { StatusUserRelation } from '@schooly/api';
import { SchoolUserType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, { FC, useCallback, useState } from 'react';

import AccessDenied from '../../../../components/common/AccessDenied';
import AvailableUsers from '../../../../components/common/AvailableUsers';
import { SEARCH_PLACEHOLDER_TEXT_IDS } from '../../../../components/common/MessageModal/utils';
import { useLastRefreshTimeContext } from '../../../../context/LastRefreshTimeContext';
import { getSchoolUserId } from '../../../../helpers/school';
import usePagedApiResourceWithFilter, {
  GetResourceFunction,
} from '../../../../hooks/usePagedApiResourceWithFilter';
import getIsAccessDeniedError from '../../../../utils/getIsAccessDeniedError';

export interface MessagesCreateModalRecipientsRightSidebarIndividualProps {
  userType: SchoolUserType;
  selectedUsers: SimpleListResult[];
  onAdd?: (student: SimpleListResult) => void;
}

export const MessagesCreateModalRecipientsRightSidebarIndividual: FC<MessagesCreateModalRecipientsRightSidebarIndividualProps> =
  ({ userType, selectedUsers, onAdd }) => {
    const { schoolId } = useAuth();
    const { lastRefreshTime } = useLastRefreshTimeContext();

    const [filter, setFilter] = useState<string>('');

    const getUserList = useCallback<GetResourceFunction<StatusUserRelation | SimpleListResult>>(
      ({ pageSize, pageNumber, query }) => {
        if (!schoolId) {
          return undefined;
        }

        const params = {
          schoolId,
          type: userType,
          pageSize,
          pageNumber,
          query,
        };

        return getMiniList(params);
      },
      [schoolId, userType],
    );

    const { displayedList, isFetching, isSearching, canShowMore, handleShowMore, error } =
      usePagedApiResourceWithFilter<StatusUserRelation | SimpleListResult>({
        getResource: getUserList,
        filter,
        onFilterUpdate: setFilter,
        lastRefreshTime,
      });

    const handleUserClick = useCallback(
      (userId: string) => {
        const user = displayedList?.results?.find((user) => getSchoolUserId(user) === userId);

        if (!user) {
          return;
        }

        onAdd?.(user as SimpleListResult);
      },
      [displayedList?.results, onAdd],
    );

    if (getIsAccessDeniedError(error)) {
      return <AccessDenied />;
    }

    return (
      <AvailableUsers
        isFetching={isFetching}
        isSearching={isSearching}
        canShowMore={canShowMore}
        handleShowMore={handleShowMore}
        userType={userType}
        onUserClick={handleUserClick}
        userList={displayedList?.results as SimpleListResult[]}
        selectedUsers={selectedUsers}
        searchPlaceholderTextId={SEARCH_PLACEHOLDER_TEXT_IDS[userType]}
        filter={filter}
        setFilter={setFilter}
      />
    );
  };
