import { Icon, Stack, Typography } from '@mui/material';
import { ProfileSearchResult } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { SchoolUserRole } from '@schooly/constants';
import { ArrowRightIcon, GridRowItem, GridRowStyled } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

type AddStaffModalAdultRowProps = {
  onAdultClick: (adult: ProfileSearchResult) => void;
  adult: ProfileSearchResult;
};

export const AddStaffModalAdultRow: FC<AddStaffModalAdultRowProps> = ({ adult, onAdultClick }) => {
  const name = getUserFullName(adult);

  const handleLinkClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);

  return (
    <GridRowStyled>
      <GridRowItem
        onClick={() => onAdultClick(adult)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            '.arrowIcon': {
              opacity: 1,
            },
          },
        }}
      >
        <Stack
          sx={{
            flex: '1 1 100%',
          }}
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          position="relative"
        >
          <Stack flexDirection="row" alignItems="center" gap={1} pr={5}>
            <AvatarAuth alt={name} user={adult} sx={{ width: 30, height: 30 }} />
            <Link
              onClick={handleLinkClick}
              to={{
                pathname: `/adults/${adult.user_id}`,
                search: `?type=${SchoolUserRole.Staff}`,
              }}
            >
              <Typography variant="h3" sx={{ '&:hover': { textDecoration: 'underline' } }}>
                {name}
              </Typography>
            </Link>
          </Stack>
          <Icon
            className="arrowIcon"
            sx={{ position: 'absolute', right: 1, opacity: 0, transition: 'opacity .2s' }}
          >
            <ArrowRightIcon />
          </Icon>
        </Stack>
      </GridRowItem>
    </GridRowStyled>
  );
};
