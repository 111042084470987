import { Icon, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, EventCriteria, FilterKeys } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { EVENT_INVITE_OPTIONS, EventsInvite } from '@schooly/constants';
import { AttentionIcon, LockIcon } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form-lts';
import { FieldPathValue } from 'react-hook-form-lts/dist/types/path';
import { RegisterOptions, Validate } from 'react-hook-form-lts/dist/types/validator';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormRadioGroup } from '../../../components/uikit-components/FormCheckbox/FormRadioGroup';
import { EventForm } from '../../../context/events/WithEvent';
import { InviteesCriteria, InviteesCriteriaProps } from './InviteesCriteria';

interface InviteesFormProps {
  startDate?: string;
  endDate?: string;
  isActive?: boolean;
  isActiveWarning?: string;
  isHoliday?: boolean;
  inviteeType?: EventsInvite;
  inviteeDescription?: string;
  isPublishing?: boolean;
  error?: boolean;
  criteriaValidate?: Validate<
    FieldPathValue<{ criteria: EventCriteria }, 'criteria'>,
    { criteria: EventCriteria }
  >;
  inviteeTypeRules?: Omit<
    RegisterOptions<FieldValues, string>,
    'setValueAs' | 'disabled' | 'valueAsNumber' | 'valueAsDate'
  >;
}

export const InviteesForm: FC<InviteesFormProps> = ({
  criteriaValidate,
  isActive,
  isActiveWarning,
  isHoliday,
  inviteeType,
  inviteeDescription,
  isPublishing,
  inviteeTypeRules,
  ...props
}) => {
  const { $t } = useIntl();
  const defaultCriteria: EventForm['criteria'] = useMemo(
    () => ({
      student_status: [],
    }),
    [],
  );

  const { schoolId } = useAuth();
  const { setValue, watch, register, formState } = useFormContext<EventForm>();
  const formCriteria = watch('criteria');
  const startDate = props.startDate ?? watch('start');
  const endDate = props.endDate ?? watch('end');
  const criteria = formCriteria ?? defaultCriteria;
  const error = formState.errors.criteria?.message;

  const handleCriteriaChange = useCallback<InviteesCriteriaProps['onCriteriaChange']>(
    (cb) => {
      const newCriteria = cb(criteria);
      setValue('criteria', newCriteria);
    },
    [criteria, setValue],
  );

  const groupDates = useMemo(
    () => ({
      [FilterKeys.IntersectDate]: [
        format(startDate ? new Date(startDate) : new Date(), DEFAULT_DATE_FORMAT_FNS),
        format(endDate ? new Date(endDate) : new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [endDate, startDate],
  );

  const options = useMemo(
    () =>
      (isActive || isHoliday) && inviteeType
        ? EVENT_INVITE_OPTIONS.filter(({ value }) => value === inviteeType)
        : EVENT_INVITE_OPTIONS,
    [inviteeType, isActive, isHoliday],
  );

  if (!startDate) return null;

  return (
    <Stack
      gap={0.5}
      sx={{
        opacity: isActive ? 0.5 : 1,
        ' .MuiFormControlLabel-root, .MuiButtonBase-root, .MuiBox-root': {
          cursor: isActive ? 'auto' : undefined,
        },
      }}
    >
      <input
        type="hidden"
        ref={
          register(`criteria`, {
            required: $t({ id: 'input-ErrorRequired' }),
            validate: criteriaValidate,
          }).ref
        }
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">
          <FormattedMessage id="events-grid-invitees" />
        </Typography>
        {isActive && (
          <Stack direction="row" gap={1} justifyItems="center">
            <Typography variant="h3">{isActiveWarning}</Typography>
            <Icon>
              <LockIcon />
            </Icon>
          </Stack>
        )}
      </Stack>

      <Stack
        sx={{
          '.MuiCheckbox-root, .MuiFormControlLabel-root': {
            pl: 0,
          },
        }}
        onClick={(e) => isActive && e.preventDefault()}
      >
        <FormRadioGroup
          options={options}
          name="invitee_type"
          withBorder={false}
          rules={
            inviteeTypeRules ?? {
              required: false,
              validate: (value) => {
                return isPublishing && !value ? $t({ id: 'input-ErrorRequired' }) : undefined;
              },
            }
          }
          containerProps={{ gap: 0.5 }}
        />
      </Stack>

      <Stack
        gap={1.5}
        sx={(theme) => ({
          backgroundColor: error ? theme.palette.background.paper : theme.palette.common.lightBg,
          padding: 2.25,
          borderRadius: 0.5,
          border: '1px solid transparent',
          borderColor: error ? theme.palette.error.main : undefined,
        })}
      >
        <Stack>
          <InviteesCriteria
            criteria={criteria}
            schoolId={schoolId || ''}
            onCriteriaChange={handleCriteriaChange}
            groupDates={groupDates}
            disabled={!!isActive}
          />
        </Stack>
        <Stack direction="row" gap={1}>
          <Icon>
            <AttentionIcon />
          </Icon>
          <Typography variant="h3" color="common.grey2">
            {inviteeDescription}
          </Typography>
        </Stack>
      </Stack>
      {error && (
        <Typography color="error.main">
          <FormattedMessage id="events-InviteesCriteriaError" />
        </Typography>
      )}
    </Stack>
  );
};
