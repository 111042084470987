import { SyncUser } from '@schooly/api';
import { SuggestedChangeDataSet } from '@schooly/constants';
import { EditIcon } from '@schooly/style';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { hasFieldValue } from '../../../components/ui/Input/utils';
import useFlag from '../../../hooks/useFlag';
import { AcceptChangePopover } from '../AcceptChangePopover';
import AddButton from '../AddButton';

export interface PropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}

type Falsy = false | null | '' | undefined;
type ExcludeFalsy<T> = Exclude<T, Falsy>;

interface ProfilePropertyProps<T extends React.ReactNode | boolean> {
  value: T;
  canEdit?: boolean;
  renderValue?: (value: ExcludeFalsy<T>) => React.ReactNode;
  params: PropertyParams;
  editValueTextId?: string;
  valueTextId?: string;
  editValueText?: string;
  valueText?: string;
  fieldName?: keyof SyncUser;
  dataSet?: SuggestedChangeDataSet;
  hideSuggestedChanges?: boolean;
  renderIcon?: (value: T) => React.ReactNode;
  renderLabel?: (value: T) => React.ReactNode;
  renderAdditionalField?: () => React.ReactNode;
  modalController?: (data: { isOpen: boolean; show: () => void; hide: () => void }) => JSX.Element;
  onClick?: () => void;
}

const buildClassNameProperty = ({
  type,
  size,
  color,
  iconColor,
  canEdit,
}: PropertyParams & { type?: 'add' | 'static'; canEdit?: boolean }) =>
  `profile-property ${type || ''} size--${size} text--${color} icon--${iconColor} ${
    canEdit ? 'can-edit' : ''
  }`;

//TODO: Remake with MUI

export default function ProfileProperty<T extends React.ReactNode>({
  value,
  canEdit,
  renderValue,
  renderIcon,
  renderLabel,
  params: { size, color, iconColor },
  editValueTextId,
  valueTextId,
  editValueText,
  valueText,
  fieldName,
  dataSet,
  renderAdditionalField,
  modalController,
  hideSuggestedChanges,
  onClick,
}: ProfilePropertyProps<T>) {
  const [isDialogOpen, showDialog, hideDialog] = useFlag();
  const { formatMessage } = useIntl();

  const handleAddButtonClick = useCallback(() => {
    showDialog();
    onClick?.();
  }, [showDialog, onClick]);

  if (canEdit) {
    return (
      <>
        {hasFieldValue(value) ? (
          <button
            type="button"
            aria-label={formatMessage({ id: 'ariaLabel-Edit' })}
            onClick={showDialog}
            className={buildClassNameProperty({
              color,
              iconColor,
              size,
              canEdit,
            })}
            style={{ overflow: 'visible' }}
          >
            {renderIcon && (
              <div className="property-icon">{renderIcon(value as ExcludeFalsy<T>)}</div>
            )}
            {renderLabel && (
              <div className="property-label">{renderLabel(value as ExcludeFalsy<T>)}</div>
            )}
            <div className="property-value">
              {renderValue ? renderValue(value as ExcludeFalsy<T>) : value}
            </div>
            {renderAdditionalField && renderAdditionalField()}
            <AcceptChangePopover
              className="ml-1"
              fieldName={fieldName}
              dataSet={dataSet}
              canShow={!hideSuggestedChanges}
            />
            <EditIcon className="edit-icon" />
          </button>
        ) : (
          <AddButton
            className={buildClassNameProperty({
              type: 'add',
              color: 'primary',
              iconColor,
              size,
              canEdit,
            })}
            onClick={handleAddButtonClick}
            labelText={editValueText}
            labelTextId={editValueTextId}
          >
            <AcceptChangePopover
              className="ml-1"
              fieldName={fieldName}
              dataSet={dataSet}
              canShow={!hideSuggestedChanges}
            />
          </AddButton>
        )}
        {modalController &&
          modalController({
            isOpen: isDialogOpen,
            show: showDialog,
            hide: hideDialog,
          })}
      </>
    );
  }

  let finalValue: React.ReactNode = valueText ?? formatMessage({ id: valueTextId });
  let finalColor = 'light-gray';

  if (hasFieldValue(value)) {
    finalValue = renderValue ? renderValue(value as ExcludeFalsy<T>) : String(value);
    finalColor = color || '';
  }

  return (
    <>
      <div
        className={buildClassNameProperty({
          type: 'static',
          color: finalColor,
          iconColor,
          size,
          canEdit,
        })}
      >
        {renderIcon && <div className="property-icon">{renderIcon(value)}</div>}
        {renderLabel && hasFieldValue(value) && (
          <div className="property-label">{renderLabel(value as ExcludeFalsy<T>)}</div>
        )}
        <div className="property-value">{finalValue}</div>
        {renderAdditionalField && renderAdditionalField()}
        <AcceptChangePopover
          className="ml-1"
          fieldName={fieldName}
          dataSet={dataSet}
          canShow={!hideSuggestedChanges}
        />
      </div>
      {modalController &&
        modalController({
          isOpen: isDialogOpen,
          show: showDialog,
          hide: hideDialog,
        })}
    </>
  );
}
