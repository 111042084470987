import { Box, IconButton, Stack, Typography } from '@mui/material';
import { SchoolYear, useGetGroupsForRelationQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Loading, ModalSearch } from '@schooly/style';
import { EditIcon } from '@schooly/style';
import { DropdownYears } from '@schooly/style';
import moment from 'moment';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import { ProfileAccordion } from '../../../components/common/ProfileAccordion/ProfileAccordion';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { ListViewGroupRow } from '../../../components/uikit-components/ListViewGroupRow/ListViewGroupRow';
import { ModalEmptyGroupArea } from '../../../components/uikit-components/Modal/ModalEmptyGroupArea';
import { DEFAULT_DATE_FORMAT } from '../../../config';
import { useProfile } from '../../../context/profile/useProfile';
import { getRouteModalPathname } from '../../../helpers/misc';
import { getUserFullName } from '../../../helpers/users';
import useAppLocation from '../../../hooks/useAppLocation';
import useSchoolYears from '../../../hooks/useSchoolYears';
import getIsAccessDeniedError from '../../../utils/getIsAccessDeniedError';

export const DEBOUNCE_TIME = 300;

enum GroupAccordionTitle {
  Active = 'active',
  NotActive = 'not active',
}

export const ProfileModalGroups: FC = () => {
  const [filter, setFilter] = useState('');

  const { permissions } = useAuth();
  const { schoolMembership, user, userType } = useProfile();
  const { formatMessage } = useIntl();
  const { defaultValidity, schoolYears } = useSchoolYears();
  const isGroupManager = permissions.includes('group_manager');
  const hasPermission = permissions.includes('group_viewer');
  const relationId = schoolMembership?.relation_id || '';
  const location = useAppLocation();
  const navigate = useNavigate();

  const [schoolYear, setGroupsSchoolYear] = useState<SchoolYear | undefined>(defaultValidity);

  const { data, error } = useGetGroupsForRelationQuery(
    {
      search_query: filter,
      relationId,
      date_from: schoolYear?.start,
      date_to: schoolYear?.end,
    },
    { enabled: !!relationId, refetchOnMount: 'always' },
  );

  const { activeGroups, notActiveGroups } = useMemo(() => {
    const activeGroups = data?.groups?.filter((group) => {
      const currentDateTime = moment(moment().format(DEFAULT_DATE_FORMAT)).valueOf();

      return group.memberships.some(({ end, start }) => {
        const memberStartDateTime = moment(start).valueOf();
        const memberEndDateTime = moment(end).valueOf();

        return memberEndDateTime >= currentDateTime && memberStartDateTime <= currentDateTime;
      });
    });

    return {
      activeGroups: activeGroups || [],
      notActiveGroups:
        data?.groups?.filter((gr) => !activeGroups?.some((group) => group.id === gr.id)) || [],
    };
  }, [data]);

  const showEditButton = useMemo(() => {
    if (!schoolYear?.end || !isGroupManager) {
      return false;
    }
    const currentDateTime = moment(moment().format(DEFAULT_DATE_FORMAT)).valueOf();

    const schoolYearEndDateTime = moment(schoolYear.end).valueOf();

    return schoolYearEndDateTime >= currentDateTime;
  }, [isGroupManager, schoolYear?.end]);

  const handleManageGroupClick = useCallback(() => {
    if (!user || !userType) return;
    const path = getRouteModalPathname(userType, user);

    navigate(`${path}/groups?schoolYearId=${schoolYear?.id}`, {
      state: { backgroundLocation: location },
    });
  }, [location, navigate, schoolYear?.id, user, userType]);

  if (getIsAccessDeniedError(error) || !hasPermission || !user) {
    return <AccessDenied />;
  }

  const userFullName = getUserFullName(user);

  const renderContent = () => {
    if (!data) return <Loading className="pt-2 pb-2" />;
    if (!activeGroups.length && !notActiveGroups.length)
      return (
        <Box pt="17px">
          <ModalEmptyGroupArea
            label={userFullName}
            onClick={showEditButton ? handleManageGroupClick : undefined}
          />
        </Box>
      );

    return (
      <Stack gap={2.5}>
        {!!activeGroups.length && schoolYear && (
          <ProfileAccordion title={GroupAccordionTitle.Active}>
            {activeGroups.map((group) => (
              <ListViewGroupRow active schoolYear={schoolYear} key={group.id} group={group} />
            ))}
          </ProfileAccordion>
        )}
        {!!notActiveGroups.length && schoolYear && (
          <ProfileAccordion
            title={GroupAccordionTitle.NotActive}
            expandable={!!activeGroups.length}
            expandedValue={!activeGroups.length}
          >
            {notActiveGroups.map((group) => (
              <ListViewGroupRow schoolYear={schoolYear} key={group.id} group={group} />
            ))}
          </ProfileAccordion>
        )}
      </Stack>
    );
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5} mt={-0.75}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="profile-Groups" />
          </Typography>
          {!!(data && data.count) && <Counter>{Number(data.count)}</Counter>}
        </Stack>
        <Stack direction="row" alignItems="center" gap={2.5}>
          <ModalSearch
            value={filter}
            onChange_MemoizedCallbackOnly={setFilter}
            placeholder={formatMessage({ id: 'people-Search' })}
          />
          <DropdownYears
            years={schoolYears}
            defaultYear={defaultValidity}
            currentYear={schoolYear}
            onYearChange={setGroupsSchoolYear}
          />
          {showEditButton && (
            <IconButton onClick={handleManageGroupClick} inverse>
              <EditIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>

      {renderContent()}
    </>
  );

  return null;
};
