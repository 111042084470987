import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1C13.866 1 17 4.13401 17 8V16H3V8C3 4.13401 6.13401 1 10 1ZM6.53513 17C7.22675 18.1956 8.51944 19 10 19C11.4806 19 12.7733 18.1956 13.4649 17H6.53513Z"
      fill="#24275B"
    />
  </svg>
);
