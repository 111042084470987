import { Box, Icon, Stack, TextareaAutosize, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

import { getUserFullName } from '../../../../helpers/users';
import theme from '../../../../theme/theme';
import { EditIcon } from '../../../ui/Icons';
import Tag from '../../../ui/Tag';
import { DropdownCommentsItem } from '../DropdownComments';

export interface CommentProps {
  comment?: DropdownCommentsItem;
  canEditOwn?: boolean;
  canEditOther?: boolean;
  maxRows?: number;
  onBlur?: (text: string, relationId?: string) => void;
  autoFocus?: boolean;
  plainCommentOnly?: boolean;
  saveOnEnterPress?: boolean;
}

const COMMENT_TEXTARIA_WIDTH = 380;

export const Comment: React.FC<CommentProps> = ({
  comment,
  canEditOwn,
  canEditOther,
  maxRows,
  onBlur,
  autoFocus,
  plainCommentOnly,
  saveOnEnterPress,
}) => {
  const { $t } = useIntl();
  const { currentStaff } = useAuth();
  const [commentText, setCommentText] = useState(comment?.comment ?? '');
  const [editing, setEditing] = useState(false);

  const { ref } = useResizeDetector<HTMLDivElement>();

  const checkCanEdit = useCallback(
    (comment: DropdownCommentsItem) =>
      (canEditOwn && comment.relation_id === currentStaff?.relation_id) ||
      (canEditOther && comment.relation_id !== currentStaff?.relation_id),
    [canEditOther, canEditOwn, currentStaff?.relation_id],
  );

  const canEdit = comment?.relation_id ? checkCanEdit(comment) : true;
  const readonly = !canEdit;

  const renderTag = useMemo(() => {
    const withTag = (node: React.ReactNode) => (
      <Tag>
        <Typography>{node}</Typography>
      </Tag>
    );

    if (comment) {
      return withTag(getUserFullName(comment));
    }

    return currentStaff ? withTag(getUserFullName(currentStaff)) : null;
  }, [comment, currentStaff]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      switch (event.key) {
        case 'Enter':
          if (event.ctrlKey) {
            event.stopPropagation();
            const textarea = event.target as HTMLTextAreaElement;
            const { selectionStart, selectionEnd, value } = textarea;

            const textBeforeCaret = value.substring(0, selectionStart);
            const textAfterCaret = value.substring(selectionEnd);

            const newCaretPosition = selectionStart + 1;

            const newValue = `${textBeforeCaret}\n${textAfterCaret}`;
            textarea.value = newValue;
            setCommentText(newValue);

            textarea.selectionStart = newCaretPosition;
            textarea.selectionEnd = newCaretPosition;
          } else if (event.shiftKey) {
            event.stopPropagation();
          } else if (saveOnEnterPress) {
            setEditing(false);
            onBlur?.(commentText, comment?.relation_id);
            event.preventDefault();
          }
          break;
        case 'Escape':
          setCommentText(comment?.comment ?? '');
          break;
      }
    },
    [comment?.comment, comment?.relation_id, commentText, onBlur, saveOnEnterPress],
  );

  return (
    <Stack
      sx={{
        padding: theme.spacing(1),
        gap: 0.5,
        ':hover': {
          '& .editIcon': { visibility: !editing && comment ? 'visible' : undefined },
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={0.5}
        alignItems="flex-end"
        position="relative"
        sx={{
          'textarea::placeholder': {
            color: (theme) => theme.palette.common.grey,
          },
        }}
      >
        <TextareaAutosize
          onBlur={() => {
            setEditing(false);
            onBlur?.(commentText, comment?.relation_id);
          }}
          onKeyDown={handleKeyDown}
          maxRows={maxRows}
          onFocus={(event) => {
            if (!readonly) {
              setEditing(true);
            }
            const length = event.target.value.length;
            event.target.scrollTop = event.target.scrollHeight;
            setTimeout(() => {
              event.target.setSelectionRange(length, length);
            });
          }}
          onChange={(event) => setCommentText(event.target.value)}
          autoFocus={autoFocus}
          defaultValue={commentText}
          placeholder={
            comment ? $t({ id: 'comment-EditComment' }) : $t({ id: 'comment-AddComment' })
          }
          readOnly={readonly}
          style={{
            flex: '1 1 90%',
            border: 'none',
            overflow: 'auto',
            outline: 'none',
            boxShadow: 'none',
            resize: 'none',
            padding: theme.spacing(0),
            maxWidth: !plainCommentOnly ? COMMENT_TEXTARIA_WIDTH : undefined,
            backgroundColor: plainCommentOnly ? 'inherit' : undefined,
            ...theme.typography.body1,
            color:
              readonly || (plainCommentOnly && !editing) ? theme.palette.common.grey2 : undefined,
          }}
        />
        {/* // Ghost textArea to calculate real text width */}
        <Typography
          ref={ref}
          component="div"
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            position: 'absolute',
            whiteSpace: 'nowrap',
            visibility: 'hidden',
          }}
        >
          {commentText}
        </Typography>
        {plainCommentOnly && canEdit && (
          <Icon sx={{ visibility: 'hidden', color: 'text.primary' }} className="editIcon">
            <EditIcon />
          </Icon>
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>{renderTag}</Box>
      </Stack>
    </Stack>
  );
};
