import { getMessagesByUserType, markAsRead } from '@schooly/api';
import { InboxMessage, IncomingAttachment } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useFetchAttachment } from '@schooly/components/form-rich-text';
import { Loading } from '@schooly/style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AttachmentFile } from '../../../components/ui/Input/RichText/Attachments/attachment-types';
import Modal from '../../../components/ui/Modal';
import { SchoolUserRole } from '../../../constants/school';
import { hasStaffUserType } from '../../../helpers/misc';
import useFlag from '../../../hooks/useFlag';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import buildClassName from '../../../utils/buildClassName';
import RecipientPreviewModal from '../RecipientPreviewModal';
import InboxModalContent from './InboxModalContent';

import './index.scss';

interface IProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const InboxModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const [messages, setMessages] = useState<InboxMessage[]>();
  const [currentMessage, setCurrentMessage] = useState<InboxMessage | undefined>();
  const [files, setFiles] = useState<AttachmentFile[] | IncomingAttachment[] | undefined>([]);
  const [isRecipientsPreviewModalOpen, showRecipientsPreviewModal, hideRecipientsPreviewModal] =
    useFlag();

  const { currentUser } = useAuth();

  const userType = useMemo(
    () => (hasStaffUserType(currentUser) ? SchoolUserRole.Staff : SchoolUserRole.Parent),
    [currentUser],
  );

  const fetchAttachment = useFetchAttachment(currentMessage);

  const fetchMessages = useCallback(async () => {
    const messagesData = await getMessagesByUserType(userType);
    setMessages(messagesData);
  }, [userType]);

  const [getMessagesRequest, isLoading] = useRequestWithProgress(fetchMessages);

  useEffect(() => {
    if (!isOpen) return;

    getMessagesRequest();
  }, [getMessagesRequest, isOpen]);

  const handleCardClick = useCallback(
    async (message?: InboxMessage) => {
      showRecipientsPreviewModal();
      setCurrentMessage(message);
      setFiles(message?.attachments || undefined);

      if (!message?.recipient_read) {
        await markAsRead(message?.id, message?.recipient_id);
        await getMessagesRequest();
      }
    },
    [setCurrentMessage, getMessagesRequest, showRecipientsPreviewModal],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleTextId="messages-InboxTitle"
        className={buildClassName('InboxModal', !messages?.length && 'InboxModal--empty')}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <InboxModalContent onCardClick={handleCardClick} messages={messages} />
        )}
      </Modal>
      <RecipientPreviewModal
        isOpen={isRecipientsPreviewModalOpen}
        onClose={hideRecipientsPreviewModal}
        message={currentMessage}
        files={files}
        setFiles={setFiles}
        onAttachmentCardClick={fetchAttachment}
      />
    </>
  );
};
export default InboxModal;
