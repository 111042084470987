import { Button, IconButton } from '@mui/material';
import {
  CheckIcon,
  CrossIcon,
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
} from '@schooly/style';
import { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

export interface EditDialogProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  name: string;
}
export const EditDialog: FC<EditDialogProps> = ({ isOpen, onClose, onConfirm, children, name }) => {
  return (
    <ModalConfirm open={isOpen} fullWidth>
      <ModalHeader title={name} withBorderBottom={false} active>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalMain>
        <ModalContent sx={{ pt: 0 }} active>
          {children}
        </ModalContent>
      </ModalMain>
      <ModalFooter active sx={{ gap: 1 }}>
        <Button variant="outlined" onClick={onClose}>
          <FormattedMessage id="action-Cancel" />
        </Button>
        <Button endIcon={<CheckIcon />} onClick={onConfirm}>
          <FormattedMessage id="action-Save" />
        </Button>
      </ModalFooter>
    </ModalConfirm>
  );
};
