import { styled } from '@mui/material';
import { ModalSmall } from '@schooly/style';

export const AuthenticationNotificationModal = styled(ModalSmall)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.common.light2,
  },
  '& .MuiDialog-paper': {
    width: '100%',

    boxShadow: '0px 30px 50px rgba(37, 39, 90, 0.1)',
    backgroundColor: 'white',

    [theme.breakpoints.up('md')]: {
      minWidth: 700,
    },
  },
}));
