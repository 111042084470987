import { ChangeEvent, createContext, Dispatch, SetStateAction } from 'react';

export interface RouterState<T = unknown> {
  contextName?: string;
  state: T & { originalData?: T };
}

export interface RouterStateContextProps<T = unknown> extends RouterState<T> {
  setState: Dispatch<SetStateAction<(T & { originalData?: T }) | undefined>>;
  setContextName: (contextName?: string) => void;
  setContextState: (newState?: Partial<T>, saveOriginalData?: boolean) => void;
  onInputChangeHandler: (stateKey: keyof T) => (e: ChangeEvent<HTMLInputElement>) => void;
  setStateHandler: (stateKey: keyof T, value?: unknown) => (callbackValue: unknown) => void;
}

export const RouterStateContext = createContext<RouterStateContextProps>({
  contextName: undefined,
  state: {},
  setState: () => {},
  setContextName: () => {},
  setContextState: () => {},
  onInputChangeHandler: () => () => {},
  setStateHandler: () => () => {},
});

RouterStateContext.displayName = 'RouterStateContext';
