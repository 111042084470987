import { Typography } from '@mui/material';
import {
  ApiError,
  DEFAULT_FORMATTED_DATE_FORMAT_FNS,
  DeleteProductResponse,
  Product,
  ProductBillingType,
  ProductForm,
  ProductSave,
  ProductTriggerType,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductQuery,
  useUpdateProductMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

export type ProductContextProps = {
  product?: Product;
  id: string;
  isLoading: boolean;
  isFetching: boolean;
  isSaving: boolean;
  isRemoving: boolean;
  canCreate: boolean;
  canEdit: boolean;
  canView: boolean;
  canDelete: boolean;
  create: (e: ProductForm) => Promise<void>;
  remove: (id: string) => Promise<void | DeleteProductResponse>;
  update: (e: ProductForm) => Promise<void>;
};

export const ProductContext = createContext<ProductContextProps>({
  product: undefined,
  id: '',
  isLoading: false,
  isFetching: false,
  isSaving: false,
  canCreate: false,
  canEdit: false,
  canView: false,
  canDelete: false,
  isRemoving: false,
  create: async () => {},
  remove: async () => {},
  update: async () => {},
});

export const WithProduct: FC<PropsWithChildren<{ id?: string }>> = ({ id: propId, children }) => {
  const { id: paramId = '' } = useParams<'id'>();
  const id = propId ?? paramId;

  const { schoolId = '', permissions } = useAuth();
  const { formatMessage } = useIntl();
  const { showError, showNotification } = useNotifications();
  const { propertiesMap } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Student });
  const { getConfirmation } = useConfirmationDialog();
  const invalidateQueries = useInvalidateListQueriesFor('product');
  const navigate = useNavigate();

  const canView = permissions.includes('product_and_invoice_viewer');
  const canCreate = permissions.includes('product_and_invoice_creator');
  const canEdit = permissions.includes('product_and_invoice_creator');
  //Based on TR-6916 deletion is currently not possible
  const canDelete = false;

  const createProduct = useCreateProductMutation();
  const updateProduct = useUpdateProductMutation();
  const removeProduct = useDeleteProductMutation();

  const { data, isFetching, isLoading } = useGetProductQuery(id, {
    enabled: !!id,
    refetchOnMount: 'always',
    onError: showError,
  });

  const convertProductFormToProductSave = (product: ProductForm): ProductSave => {
    return {
      ...product,
      types: product.types.map(({ billing_connection, ...t }) => ({
        ...t,
        billing_connection: {
          account_id: billing_connection.account_id,
        },
      })),
    };
  };

  const remove = useCallback(
    async (id: string) => {
      if (!data) return;

      try {
        const confirmed = await getConfirmation({
          textId: 'products-AreYouSureYouWantToDelete',
          textValues: {
            name: data.name,
          },
        });

        if (!confirmed) return;

        const result = await removeProduct.mutateAsync({ id });
        invalidateQueries();

        return result;
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [data, getConfirmation, invalidateQueries, removeProduct, showError],
  );

  const create = useCallback(
    async (product: ProductForm) => {
      if (!schoolId || !!id) return;

      const registrationTrigger = product.triggers.find(
        (t) => t.trigger_type === ProductTriggerType.RegistrationUpdate,
      );

      let withActiveRegistrations;
      if (product.type === ProductBillingType.OneOff && registrationTrigger) {
        const statusName = registrationTrigger?.extra_data?.status
          ? propertiesMap.status.find((s) => s.id === registrationTrigger?.extra_data?.status)?.name
          : undefined;

        withActiveRegistrations = await getConfirmation({
          textId: 'products-OneOff-Confirmation-Title',
          textValues: { status: `"${statusName}"` },
          confirmTextId: 'products-YesCreateInvoices',
          cancelTextId: 'products-NoApplyToFutureStudents',
          content: (
            <Typography mt={0.5} variant="h3">
              {formatMessage(
                { id: 'products-OneOff-Confirmation-Description' },
                {
                  status: `"${statusName}"`,
                  today: format(newDateTimezoneOffset(), DEFAULT_FORMATTED_DATE_FORMAT_FNS),
                },
              )}
            </Typography>
          ),
        });
      }

      try {
        const res = await createProduct.mutateAsync({
          schoolId,
          product: convertProductFormToProductSave(product),
          withActiveRegistrations,
        });
        showNotification({
          type: 'success',
          textId: withActiveRegistrations
            ? 'products-ProductHasBeenCreatedAndInvoicesGenerated'
            : 'products-ProductHasBeenCreated',
          values: { name: `"${product.name}"` },
          actions: [
            {
              textId: 'products-notification-ViewProduct',
              handler: () => navigate(`/settings/products/${res.id}`),
              buttonColor: 'light',
            },
          ],
        });
        invalidateQueries();
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [
      createProduct,
      formatMessage,
      getConfirmation,
      id,
      invalidateQueries,
      navigate,
      propertiesMap.status,
      schoolId,
      showError,
      showNotification,
    ],
  );

  const update = useCallback(
    async (product: ProductForm) => {
      if (!id) return;

      try {
        await updateProduct.mutateAsync({ id, product: convertProductFormToProductSave(product) });
        showNotification({
          type: 'success',
          textId: 'products-ProductHasBeenSaved',
          values: { name: `"${product.name}"` },
        });
        invalidateQueries();
      } catch (e) {
        showError(e as ApiError);
        throw e;
      }
    },
    [updateProduct, invalidateQueries, id, showError, showNotification],
  );

  return (
    <ProductContext.Provider
      value={{
        id,
        product: data,
        isFetching,
        isLoading,
        create,
        update,
        remove,
        canCreate,
        canEdit,
        canView,
        canDelete,
        isRemoving: removeProduct.isLoading,
        isSaving: createProduct.isLoading || updateProduct.isLoading,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => {
  return useContext(ProductContext);
};
