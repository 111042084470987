import { Stack, styled } from '@mui/material';

export const ModalEmptyStyled = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2.5),

  '& picture': {
    maxWidth: 200,
    maxHeight: 200,

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },

  '& .MuiIconButton-root': {
    margin: theme.spacing(0, 1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
