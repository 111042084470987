import React, { FC } from 'react';

import { WithAnnualPlannerCreate } from './AnnualPlannerCalendar/AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import { WithAnnualPlannerDnD } from './AnnualPlannerCalendar/WithAnnualPlannerDnD';
import { AnnualPlannerPageContent } from './AnnualPlannerPageContent';
import { WithAnnualPlanner } from './WithAnnualPlanner';

export const AnnualPlannerPage: FC = () => {
  return (
    <WithAnnualPlannerDnD>
      <WithAnnualPlanner>
        <WithAnnualPlannerCreate>
          <AnnualPlannerPageContent />
        </WithAnnualPlannerCreate>
      </WithAnnualPlanner>
    </WithAnnualPlannerDnD>
  );
};
