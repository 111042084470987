import 'react-quill/dist/quill.snow.css';

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactQuill from 'react-quill';

import { NON_BREAKING_SPACE } from '../../../../constants/typography';
import { noop } from '../../../../helpers/misc';
import buildClassName from '../../../../utils/buildClassName';
import IntlError from '../../../../utils/intlError';
import Attachments from './Attachments';
import { AttachmentProps } from './Attachments/attachment-types';
import Toolbar from './Toolbar';

import './index.scss';

const quill = (ReactQuill as any).Quill;

const quillIcons = quill.import('ui/icons');

quillIcons.bold = null;
quillIcons.italic = null;
quillIcons.underline = null;
quillIcons.link = null;
quillIcons.list = null;

const Link = quill.import('formats/link');
Link.sanitize = (url: string) => {
  if (url.includes('http')) return url;

  return `https://${url}`;
};

interface IProps extends AttachmentProps {
  value?: string;
  defaultValue?: string;
  readOnly?: boolean;
  toolbarId?: string;
  className?: string;
  autoFocus?: boolean;
  useManualEnterKeyUp?: boolean;
  onChange?: (val: string) => void;
  title?: EmotionJSX.Element;
  withoutAttachments?: boolean;
  placeholder?: string;
}

/** @deprecated use @schooly/form-rich-text instead */
const RichText: React.FC<IProps> = ({
  className,
  value = '',
  defaultValue = '',
  readOnly,
  files,
  autoFocus,
  useManualEnterKeyUp,
  setFiles = noop,
  onChange = noop,
  onAttachmentCardClick,
  toolbarId = 'toolbar',
  title,
  withoutAttachments,
  placeholder,
}) => {
  const { formatMessage } = useIntl();
  const [error, setError] = useState<IntlError | undefined>();
  const ref = useRef<ReactQuill>(null);

  useEffect(() => {
    if (!ref.current || !autoFocus) return;

    const editor = ref?.current.getEditor();
    editor.setSelection(defaultValue.length, defaultValue.length);

    ref.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  useEffect(() => {
    const input = document.querySelector('input[data-link]') as HTMLInputElement;
    input.dataset.link = 'https://www.site.com';
    input.placeholder = 'https://www.site.com';
  }, []);

  const editorValue = useMemo(() => {
    if (defaultValue && !value.length) {
      return defaultValue;
    }

    return value;
  }, [defaultValue, value]);

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent) => {
      if (!useManualEnterKeyUp || !ref?.current || e.code !== 'Enter') return;

      const editor = ref?.current.getEditor();
      const index = Number(editor.getSelection()?.index);

      editor.insertText(index, `${NON_BREAKING_SPACE}\n`);
      editor.setSelection(index, 0);
    },
    [useManualEnterKeyUp],
  );

  useLayoutEffect(() => {
    const ref = document.querySelector('.ql-tooltip') as HTMLDivElement;
    const isVisible = ref.getBoundingClientRect();

    if (!ref || !isVisible) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => ref.scrollIntoView());
    resizeObserver.observe(ref);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const optionalProps = readOnly || !useManualEnterKeyUp ? { value: editorValue } : {};

  return (
    <div className="RichTextContainer">
      <Toolbar
        files={files}
        setFiles={setFiles}
        setError={setError}
        id={toolbarId}
        className={readOnly ? 'Toolbar--readonly' : ''}
        title={title}
        withoutAttachments={withoutAttachments}
      />
      <ReactQuill
        ref={ref}
        readOnly={readOnly}
        className={buildClassName('RichText', readOnly && 'RichText--readonly', className)}
        onChange={onChange}
        placeholder={placeholder ?? formatMessage({ id: 'messages-MessageText' })}
        defaultValue={defaultValue}
        onKeyUp={handleKeyUp}
        modules={{
          toolbar: {
            container: `#${toolbarId}`,
          },
        }}
        {...optionalProps}
      />
      <Attachments
        error={error}
        readOnly={readOnly}
        files={files}
        setFiles={setFiles}
        setError={setError}
        onAttachmentCardClick={onAttachmentCardClick}
      />
    </div>
  );
};

export default RichText;
