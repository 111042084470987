import { FC } from 'react';

import { CloseAccountModal } from '../../components/common/CloseAccountModal/CloseAccountModal';
import DeleteUserModal from '../../components/common/DeleteUserModal';
import PasswordChangeModal from '../../components/common/PasswordChangeModal';
import { UserContexts } from '../../constants/userContexts';
import { ProfileModalType } from '../../context/profile/ProfileContext';
import { useProfile } from '../../context/profile/useProfile';
import { ProfileModalIndividualContext } from './contexts/ProfileModalIndividualContext/ProfileModalIndividualContext';
import { ProfileModalSchoolContext } from './contexts/ProfileModalSchoolContext/ProfileModalSchoolContext';
import { ProfileModalProps } from './ProfileModalProps';

export const ProfileModal: FC<ProfileModalProps> = (props) => {
  const { user, userType, userContext, schoolMembership, openedModal, actions } = useProfile();

  const renderModal = () => {
    if (!openedModal) return;

    switch (openedModal) {
      case ProfileModalType.CloseAccount:
        return <CloseAccountModal isOpen onClose={actions.closeModal} />;
      case ProfileModalType.PasswordChange:
        return <PasswordChangeModal isOpen onClose={actions.closeModal} />;
      case ProfileModalType.DeleteAccount:
        if (!user || !userType) return null;

        return (
          <DeleteUserModal
            isOpen
            onClose={actions.closeModal}
            user={user}
            schoolMembership={schoolMembership}
            onUserDelete={actions.handleUserDelete}
            userType={userType}
            closeProfileModal={actions.handleClose}
          />
        );
    }
  };

  switch (userContext) {
    case UserContexts.School:
      return (
        <>
          <ProfileModalSchoolContext {...props} />
          {renderModal()}
        </>
      );
    case UserContexts.Individual:
    default:
      return (
        <ProfileModalIndividualContext {...props}>
          {/* TR-3350 FE Password input does not work in close account modal */}
          {renderModal()}
        </ProfileModalIndividualContext>
      );
  }
};
