import { Icon, TableHeadProps, TableRow, Typography } from '@mui/material';
import { Message, MessageStatus } from '@schooly/api';
import { DATE_FORMAT } from '@schooly/constants';
import {
  AttachmentIcon,
  DoubleCheckIcon,
  GridCell,
  GridHead,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { format } from 'date-fns';
import { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';

export const MessagesHeader: FC<TableHeadProps & { rightIcon?: ReactNode }> = ({
  rightIcon,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  return (
    <GridHead borderBottom {...rest}>
      <GridCell>{formatMessage({ id: 'messages-Subject' })}</GridCell>
      <GridCell width="40px" />
      <GridCell width="140px">{formatMessage({ id: 'messages-From' })}</GridCell>
      <GridCell width="140px">{formatMessage({ id: 'messages-Publisher' })}</GridCell>
      <GridCell width="100px">{formatMessage({ id: 'messages-Status' })}</GridCell>
      <GridCell width="100px">{formatMessage({ id: 'messages-PublishDate' })}</GridCell>
      <GridCell width="140px">{formatMessage({ id: 'messages-ActualRecipients' })}</GridCell>
      <GridCell width="100px">{formatMessage({ id: 'messages-ReadStatistics' })}</GridCell>
      <GridCell right pr={1} width="40px">
        {rightIcon}
      </GridCell>
    </GridHead>
  );
};

type MessageRowProps = {
  message: Message;
};

export const MessageRow: FC<MessageRowProps> = ({ message }) => {
  const { formatMessage } = useIntl();
  const location = useAppLocation();

  return (
    <TableRow
      component={Link}
      to={`/messages/${message.id}`}
      state={{ backgroundLocation: location }}
      sx={(theme) => ({
        '&:hover td': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <GridCell>
        <TypographyWithOverflowHint variant="h3" color="common.grey2" noWrap>
          {message.title}
        </TypographyWithOverflowHint>
      </GridCell>
      <GridCell>
        {!!message.attachments?.length && (
          <Icon sx={{ color: 'common.grey' }}>
            <AttachmentIcon />
          </Icon>
        )}
      </GridCell>
      <GridCell>
        {message.creator ? (
          <TypographyWithOverflowHint color="common.grey2" noWrap>
            {getUserFullName(message.creator)}
          </TypographyWithOverflowHint>
        ) : (
          <Typography color="common.grey2">—</Typography>
        )}
      </GridCell>
      <GridCell>
        {message.publisher ? (
          <TypographyWithOverflowHint color="common.grey2" noWrap>
            {getUserFullName(message.publisher)}
          </TypographyWithOverflowHint>
        ) : (
          <Typography color="common.grey2">—</Typography>
        )}
      </GridCell>
      <GridCell>
        <Typography color="common.grey2">
          {formatMessage({ id: `options-message-status-${MessageStatus[message.status]}` })}
        </Typography>
      </GridCell>
      <GridCell>
        <Typography color="common.grey2">
          {message.publish_date ? format(new Date(message.publish_date * 1000), DATE_FORMAT) : '—'}
        </Typography>
      </GridCell>
      <GridCell>
        <RecipientsCell message={message} />
      </GridCell>
      <GridCell>
        <StatisticsCell message={message} />
      </GridCell>
      <GridCell />
    </TableRow>
  );
};

type RecipientsCellProps = { message: Message };
export const RecipientsCell: FC<RecipientsCellProps> = ({ message }) => {
  const { status, parent_count, staff_count } = message;

  const renderContent = () => {
    if (status !== MessageStatus.Published) {
      return '—';
    }

    if (!staff_count && !parent_count) {
      return '—';
    }

    return status === MessageStatus.Published ? (
      <>
        {staff_count ? (
          <>
            <FormattedMessage id="userType-staff-plural" />
            :&nbsp;{staff_count}
          </>
        ) : null}{' '}
        {parent_count ? (
          <>
            <FormattedMessage id="userType-parent-plural" />
            :&nbsp;{parent_count}
          </>
        ) : null}
      </>
    ) : null;
  };
  return <Typography color="common.grey2">{renderContent()}</Typography>;
};

type StatisticsCellProps = { message: Message };
export const StatisticsCell: FC<StatisticsCellProps> = ({ message }) => {
  const { status, percentage_read } = message;

  const renderContent = () => {
    if (status !== MessageStatus.Published) {
      return '—';
    }

    if (!percentage_read) {
      return '—';
    }

    return (
      <Typography
        color="inherit"
        sx={{
          '.svg-icon': {
            color: 'success.main',
            fontSize: '18px',
            mr: 0.25,
          },
        }}
      >
        <DoubleCheckIcon />
        {percentage_read.toLocaleString(
          undefined, // leave undefined to use the visitor's browser locale
          { minimumFractionDigits: 0, maximumFractionDigits: 0 },
        )}
        %
      </Typography>
    );
  };
  return <Typography color="common.grey2">{renderContent()}</Typography>;
};
