import { Box, Stack } from '@mui/material';
import { styled } from '@mui/system';

import { GridContainer } from '../../../../../components/uikit-components/Grid/Grid';

export const GroupPreviewMembersListStyled = styled(GridContainer)(({ theme }) => ({
  minHeight: theme.spacing(10),
  '& + &': {
    marginTop: theme.spacing(6.5),
  },
}));

export const GroupPreviewMembersListTitleWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2.5),
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.common.lightBg,

  '&::before': {
    content: "''",
    display: 'block',
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '110%',
    top: '-110%',
    left: 0,
    backgroundColor: theme.palette.common.lightBg,
  },
}));

export interface GroupPreviewMembersListCardsWrapperProps {
  disabled?: boolean;
}

export const GroupPreviewMembersListCardsWrapper = styled(
  Box,
)<GroupPreviewMembersListCardsWrapperProps>(({ theme, disabled }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  opacity: disabled ? 0.6 : 1,
  '&:last-child': {
    borderBottom: 0,
  },
}));
