import { Box, Skeleton, Stack, TableRow, Typography } from '@mui/material';
import { AgeGroup, AssessmentMethodType, AssessmentToCreate, GroupSubject } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  EyeIcon,
  Grid,
  GridBody,
  GridCell,
  ModalPanel,
  SimpleButton,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ReportForm } from '../../../context/report/WithReportEditContext';
import { useAssessmentsGradesForSchool } from '../../../hooks/useAssessmentsGradesForSchool';
import { Arrow, NoAssessmentsSvg } from './NoAssessmentsSvg';
import { ReportCreateModalAdditionalAssessments } from './ReportCreateModalAdditionalAssessments';

type AssessmentsStateContentProps = {
  autogeneratedAssessments: AssessmentToCreate[];
  ageGroups: AgeGroup[];
  subjects: GroupSubject[];
  form: UseFormReturn<ReportForm>;
};

export const AssessmentsStateContent: FC<AssessmentsStateContentProps> = ({
  form,
  subjects,
  ageGroups,
  autogeneratedAssessments,
}) => {
  const { $t } = useIntl();

  const [isAdditionalAssessmentsOpened, openAdditionalAssessments, closeAdditionalAssessments] =
    useFlag(false);

  const { grades } = useAssessmentsGradesForSchool();
  const additionalAssessments = form.watch('assessments');

  const renderGeneratedAssessments = useCallback(() => {
    if (!autogeneratedAssessments.length)
      return (
        <Stack
          height="100%"
          position="relative"
          justifyContent="center"
          alignItems="center"
          gap={3}
        >
          <NoAssessmentsSvg />
          <Typography variant="h3">{$t({ id: 'reports-EditConfiguration' })}</Typography>
          <Box position="absolute" right={0} bottom={30}>
            <Arrow />
          </Box>
        </Stack>
      );
    return (
      <Grid stickyHeader fixedLayout>
        <GridBody>
          {autogeneratedAssessments.map((assessment) => {
            const isTutor = assessment.is_tutor;

            return (
              <AssessmentToCreateRow
                key={assessment.name}
                name={!isTutor ? assessment.name : $t({ id: 'reports-TutorsFeedback' })}
                subjectsLabel={
                  isTutor
                    ? $t({ id: 'groups-TutorGroup' })
                    : subjects
                        .filter((s) => assessment.subjects.map((s) => s.id).includes(s.id))
                        .map((s) => s.name)
                        .join(' + ')
                }
                ageGroupsLabel={
                  assessment.age_groups.length
                    ? ageGroups
                        .filter((ageGroup) =>
                          assessment.age_groups.map((g) => g.id).includes(ageGroup.id),
                        )
                        .map((g) => g.name)
                        .join(' + ')
                    : $t({ id: 'reports-AllStudents' })
                }
                methodsLabel={
                  isTutor
                    ? $t({ id: 'assessments-Type-2' })
                    : assessment.methods
                        .map((m) => {
                          if (m.method_type === AssessmentMethodType.Comment)
                            return $t({ id: 'assessments-Type-2' });
                          if (m.method_type === AssessmentMethodType.Grade)
                            return grades.find((g) => g.id === m.select_list_id)?.name;
                          if (m.method_type === AssessmentMethodType.Score)
                            return m.score_out_of
                              ? `0/${m.score_out_of}`
                              : $t({ id: 'assessments-Type-0' });

                          return 'Other';
                        })
                        .join(' + ')
                }
              />
            );
          })}
        </GridBody>
      </Grid>
    );
  }, [$t, ageGroups, autogeneratedAssessments, grades, subjects]);

  return (
    <Stack height="100%">
      <ModalPanel
        fullHeight={!isAdditionalAssessmentsOpened}
        withBorderBottom
        active={!isAdditionalAssessmentsOpened}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          mb={!isAdditionalAssessmentsOpened ? 3 : 0}
        >
          <Typography variant="h2">
            {$t({
              id: autogeneratedAssessments.length
                ? 'reports-WouldGenerateAssessments'
                : 'reports-NoGenerated',
            })}
          </Typography>
          {!!isAdditionalAssessmentsOpened && !!autogeneratedAssessments.length && (
            <SimpleButton onClick={closeAdditionalAssessments} startIcon={<EyeIcon />}>
              {$t({ id: 'reports-ViewAssessment' })}
            </SimpleButton>
          )}
        </Stack>
        {!isAdditionalAssessmentsOpened && renderGeneratedAssessments()}
      </ModalPanel>
      <ReportCreateModalAdditionalAssessments
        onAddClick={openAdditionalAssessments}
        active={isAdditionalAssessmentsOpened}
        errorId={
          !autogeneratedAssessments.length &&
          !additionalAssessments.length &&
          form.formState.errors.assessments
            ? 'reports-AssessmentRequiredError'
            : undefined
        }
        form={form}
      />
    </Stack>
  );
};

type AssessmentToCreateRowProps = {
  name: string;
  subjectsLabel?: string;
  methodsLabel?: string;
  ageGroupsLabel?: string;
};

const AssessmentToCreateRow: FC<AssessmentToCreateRowProps> = ({
  name,
  methodsLabel,
  ageGroupsLabel,
  subjectsLabel,
}) => {
  return (
    <TableRow
      sx={(theme) => ({
        '&:hover td': {
          backgroundColor: theme.palette.background.default,
        },
        '&:not(:hover) td': {
          color: theme.palette.common.grey2,
          h3: {
            color: theme.palette.common.grey2,
          },
        },
      })}
    >
      <GridCell height={44} noVerticalPadding pl={2}>
        <TypographyWithOverflowHint variant="h3" noWrap>
          {name}
        </TypographyWithOverflowHint>
      </GridCell>
      <GridCell height={44} noVerticalPadding>
        <TypographyWithOverflowHint noWrap>
          {ageGroupsLabel || <Skeleton width={50} />}
        </TypographyWithOverflowHint>
      </GridCell>
      <GridCell height={44} noVerticalPadding width="100px">
        <TypographyWithOverflowHint noWrap>{subjectsLabel}</TypographyWithOverflowHint>
      </GridCell>
      <GridCell height={44} noVerticalPadding>
        <TypographyWithOverflowHint noWrap>{methodsLabel}</TypographyWithOverflowHint>
      </GridCell>
    </TableRow>
  );
};
