import { IconButton } from '@mui/material';
import { SchoolUserType, UserType } from '@schooly/api';
import { DropdownMenu } from '@schooly/style';
import React from 'react';
import { useIntl } from 'react-intl';

import { getUserTypeTextId } from '../../../helpers/misc';
import renderUserTypeIcon from '../../../helpers/renderUserTypeIcon';

interface SelectUserRoleProps<T> {
  selected: UserType;
  list: T[];
  onSelect: (userType: T) => void;
}

export const UserRoleSelect = <T extends { userType: SchoolUserType }>({
  selected,
  list,
  onSelect,
}: SelectUserRoleProps<T>) => {
  const { $t } = useIntl();
  const actions = list
    .map((i) => ({
      title: $t({ id: getUserTypeTextId(i.userType) }),
      handler: () => onSelect(i),
      icon: renderUserTypeIcon(i.userType),
    }))
    .sort((a) => (a.title === $t({ id: getUserTypeTextId(selected) }) ? -1 : 0));

  return (
    <DropdownMenu
      title={$t({ id: getUserTypeTextId(selected) })}
      icon={
        <IconButton
          inverse
          sx={{
            color: (theme) => theme.palette.common.grey2,
          }}
          size="small"
        >
          {renderUserTypeIcon(selected)}
        </IconButton>
      }
      actions={actions}
      isSelected={({ title }) => title === $t({ id: getUserTypeTextId(selected) })}
    />
  );
};
