import { Stack, Typography } from '@mui/material';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { TagSelectCounter, TagSelectProperty } from '@schooly/style';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectOptionsArchivedIcon } from '../SelectOptionsArchivedIcon';
import { SelectSearchInput } from '../SelectSearchInput';
import { getSelectedItemsWithGrouping, SelectedItem, SelectedItemWithGrouping } from '../utils';
import { renderPropertyGroupTags } from './AgeGroupExpandedSelect';

type AgeGroupSelectMultipleProps = {
  hideIfNoOptions?: boolean;
  userRole: SchoolUserRole;
  schoolId: string;
  selectedIds: string[];
  onSelect: (v: string[]) => void;
  optionsTitle?: string;
  isAdmin?: boolean;
  allowArchived?: boolean;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const AgeGroupSelectMultiple: FC<AgeGroupSelectMultipleProps> = ({
  hideIfNoOptions,
  optionsTitle,
  schoolId,
  selectedIds,
  userRole,
  placeholder,
  isAdmin,
  allowArchived,
  onSelect,
  ...dropdownProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdown = useRef<DropdownSelect | null>(null);
  const [query, setQuery] = useState('');
  const { getConfirmation } = useConfirmationDialog();

  const [queryEnabled, setQueryEnabled] = useState(false);
  const [hoveredLevelId, setHoveredLevelId] = useState('');

  const {
    ageGroups,
    schoolLevels: schoolLevelsInit,
    activeAgeGroups: activeAgeGroupsInit,
    archivedAgeGroups: archivedAgeGroupsInit,
    schoolLevelsWithAgeGroupsMap,
    getAgeGroupsByLevelId,
    getAgeGroupById,
    getSchoolLevelById,
    isLoading,
  } = useAgeGroups(
    {
      schoolId: schoolId,
      userType: userRole,
    },
    { enabled: queryEnabled },
  );

  const selectedItems: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups =
      selectedIds?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [selectedIds, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  const showAllArchivedRightIcon =
    !allowArchived &&
    !selectedIds.length &&
    !activeAgeGroupsInit?.length &&
    archivedAgeGroupsInit?.length;

  const renderAllArchivedIcon = useCallback(
    () => <SelectOptionsArchivedIcon isAdmin={!!isAdmin} type={SchoolPropertyType.AgeGroup} />,
    [isAdmin],
  );

  const handleSelect = useCallback(
    async (ids: string[]) => {
      if (ids.length === 1) {
        const ageGroup = getAgeGroupById(ids[0] ?? '');
        if (
          !allowArchived &&
          ageGroup?.archived &&
          !(await getConfirmation({
            textId: 'deselect-property-archived-age_group',
            textValues: { name: ageGroup.name },
          }))
        ) {
          return;
        }
      }
      onSelect(ids);
    },
    [allowArchived, getAgeGroupById, getConfirmation, onSelect],
  );

  const renderContent = useCallback(() => {
    if (isLoading) return <SelectContentSkeleton />;

    const schoolLevels = schoolLevelsInit?.filter((p) =>
      p.name.toLowerCase().includes(query.toLowerCase()),
    );

    const activeAgeGroups = activeAgeGroupsInit?.filter((p) =>
      p.name.toLowerCase().includes(query.toLowerCase()),
    );

    const archivedAgeGroups = archivedAgeGroupsInit?.filter((p) =>
      p.name.toLowerCase().includes(query.toLowerCase()),
    );

    if (!schoolLevels?.length && !activeAgeGroups?.length && !archivedAgeGroups?.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        <Stack mx={1} my={2} gap={2}>
          {Boolean(schoolLevels.length) && (
            <Stack gap={0.5}>
              <Typography variant="h4">
                <FormattedMessage id="school-properties-ageGroups-schoolLevels" />
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {schoolLevels.map((level) => (
                  <TagSelectProperty
                    userRole={userRole}
                    key={level.id}
                    variant={
                      selectedItems.some((item) => item.id === level.id) ? 'filled' : undefined
                    }
                    onClick={() => {
                      const ageGroupsOfLevelIds = getAgeGroupsByLevelId(level.id).map((g) => g.id);
                      onSelect(ageGroupsOfLevelIds);
                      setQuery('');
                    }}
                    className={hoveredLevelId === level.id ? 'hoveredTag' : undefined}
                    onMouseEnter={() => setHoveredLevelId(level.id)}
                    onMouseLeave={() => setHoveredLevelId('')}
                    label={
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        {level.name}
                        <TagSelectCounter inverse>
                          {getAgeGroupsByLevelId(level.id).length}
                        </TagSelectCounter>
                      </Stack>
                    }
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {Boolean(activeAgeGroups?.length) && (
            <Stack gap={0.5}>
              <Typography variant="h4">
                <FormattedMessage id="school-properties-ageGroups" />
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {activeAgeGroups?.map((ageGroup) => (
                  <TagSelectProperty
                    key={ageGroup.id}
                    userRole={userRole}
                    property={{ ...ageGroup, type: SchoolPropertyType.AgeGroup }}
                    variant={selectedIds?.includes(ageGroup.id) ? 'filled' : undefined}
                    onClick={() => {
                      onSelect([ageGroup.id]);
                      setQuery('');
                    }}
                    outlined={Boolean(hoveredLevelId && ageGroup.level_id === hoveredLevelId)}
                  />
                ))}
              </Stack>
            </Stack>
          )}

          {Boolean(allowArchived && archivedAgeGroups?.length) && (
            <Stack gap={0.5}>
              <Typography variant="h4">
                <FormattedMessage id="filter-Archived" />
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {archivedAgeGroups?.map((ageGroup) => (
                  <TagSelectProperty
                    key={ageGroup.id}
                    userRole={userRole}
                    property={{ ...ageGroup, type: SchoolPropertyType.AgeGroup }}
                    variant={selectedIds?.includes(ageGroup.id) ? 'filled' : undefined}
                    onClick={() => {
                      onSelect([ageGroup.id]);
                      setQuery('');
                    }}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </>
    );
  }, [
    isLoading,
    schoolLevelsInit,
    activeAgeGroupsInit,
    archivedAgeGroupsInit,
    allowArchived,
    query,
    selectedItems,
    getAgeGroupsByLevelId,
    onSelect,
    hoveredLevelId,
    userRole,
    selectedIds,
  ]);

  if (hideIfNoOptions && !isLoading && !ageGroups.length) return null;

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={placeholder}
      hasValues={selectedIds.length > 0}
      renderContent={renderContent}
      renderRightIcon={showAllArchivedRightIcon ? renderAllArchivedIcon : undefined}
      onClickInputArea={() => inputRef.current?.focus()}
    >
      {(opened) => (
        <>
          {renderPropertyGroupTags({
            selectedItems,
            onDelete: opened
              ? (ids) => {
                  handleSelect(ids);
                  setHoveredLevelId('');
                }
              : undefined,

            getProperty: (i) =>
              i.isGroup
                ? getSchoolLevelById(i.id)
                : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
            getItemsOfGroupIds: (i) =>
              i.isGroup ? getAgeGroupsByLevelId(i.id).map(({ id }) => id) : [i.id],
            onGroupMouseEnter: (id) => setHoveredLevelId(id),
            onGroupMouseLeave: () => setHoveredLevelId(''),
            tagProps: {
              userRole,
              size: placeholder ? 'small' : undefined,
              sx: { maxWidth: 200 },
            },
          })}
          {opened && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={query || ''}
              onChangeText={setQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
