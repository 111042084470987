import { Stack } from '@mui/material';
import { InviteStatus } from '@schooly/constants';
import { FC, PropsWithChildren, useCallback } from 'react';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { renderInviteStatusTags } from './InviteStatusExpandedSelect';

type InviteStatusSelectMultipleProps = PropsWithChildren<{
  selectedStatuses: InviteStatus[];
  onSelectStatus: (v: InviteStatus) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

const OPTIONS = [InviteStatus.Invited, InviteStatus.NotInvited, InviteStatus.InviteAccepted];

export const InviteStatusSelectMultiple: FC<InviteStatusSelectMultipleProps> = ({
  selectedStatuses,
  onSelectStatus,
  placeholder,
  ...dropdownProps
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderInviteStatusTags({
          statuses: OPTIONS,
          tagProps: (status) => ({
            variant: selectedStatuses.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectStatus,
        })}
      </Stack>
    );
  }, [onSelectStatus, selectedStatuses]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selectedStatuses.length}
      renderContent={renderContent}
    >
      {(opened) => (
        <>
          {renderInviteStatusTags({
            statuses: selectedStatuses,
            tagProps: { size: placeholder ? 'small' : undefined },
            onDelete: opened ? onSelectStatus : undefined,
          })}
        </>
      )}
    </DropdownSelect>
  );
};
