import { Stack } from '@mui/material';
import { Genders } from '@schooly/constants';
import { FC, PropsWithChildren, useCallback } from 'react';
import { $enum } from 'ts-enum-util';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { renderGenderTags } from './GenderExpandedSelect';

type GenderSelectMultipleProps = PropsWithChildren<{
  selectedGenders: Genders[];
  onSelectGender: (v: Genders) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

const OPTIONS = $enum(Genders).map((v) => v);

export const GenderSelectMultiple: FC<GenderSelectMultipleProps> = ({
  selectedGenders,
  onSelectGender,
  placeholder,
  ...dropdownProps
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderGenderTags({
          genders: OPTIONS,
          tagProps: (status) => ({
            variant: selectedGenders.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectGender,
        })}
      </Stack>
    );
  }, [onSelectGender, selectedGenders]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selectedGenders.length}
      renderContent={renderContent}
    >
      {(opened) => (
        <>
          {renderGenderTags({
            genders: selectedGenders,
            tagProps: { size: placeholder ? 'small' : undefined },
            onDelete: opened ? onSelectGender : undefined,
          })}
        </>
      )}
    </DropdownSelect>
  );
};
