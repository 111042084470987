import { Box, Stack, styled } from '@mui/material';

import { SIDEBAR_WIDTH } from '../Sidebar/Sidebar.styled';

export const MainLayoutStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  paddingLeft: SIDEBAR_WIDTH,
  background: theme.palette.background.paper,
  zIndex: 1,

  [theme.breakpoints.down('md')]: {
    overflowX: 'auto',
  },
}));

MainLayoutStyled.defaultProps = {
  component: 'main',
};

export const MainLayoutContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  minHeight: '100%',
  backgroundColor: theme.palette.background.paper,
}));

export const MainLayoutContent = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 1440,
  padding: theme.spacing(2.5, 3),
  margin: theme.spacing(0, 'auto'),
  background: theme.palette.background.paper,
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const MainLayoutEmptyStubStyled = styled(Stack)(({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',

  '& img': {
    height: 300,
  },
}));
