import { useCallback, useState } from 'react';

export type SelectedIds = Set<string> | 'all';

export type UseSelectIds = {
  selectedIds: SelectedIds;
  toggleSelectAll: () => void;
  toggleSelectId: (id: string) => void;
  clearSelectedIds: () => void;
  toggleSelectedIds: (ids: string[]) => void;
};

export const useSelectIds = (): UseSelectIds => {
  const [selectedIds, setSelectedIds] = useState<SelectedIds>(new Set());

  const toggleSelectAll = useCallback(() => {
    setSelectedIds((s) => (s === 'all' ? new Set() : 'all'));
  }, []);

  const clearSelectedIds = useCallback(() => {
    setSelectedIds(new Set());
  }, []);

  const toggleSelectId = useCallback((id: string) => {
    setSelectedIds((s) => {
      if (s === 'all') return new Set([id]);
      const set = new Set(s);
      set.has(id) ? set.delete(id) : set.add(id);
      return set;
    });
  }, []);

  const toggleSelectedIds = useCallback((ids: string[]) => {
    setSelectedIds((s) => {
      if (s === 'all') return new Set(ids);
      const set = new Set(s);
      ids.forEach((id) => (set.has(id) ? set.delete(id) : set.add(id)));
      return set;
    });
  }, []);

  return {
    selectedIds,
    toggleSelectAll,
    toggleSelectId,
    clearSelectedIds,
    toggleSelectedIds,
  };
};

export type SelectedSection<T> = {
  title: string;
  count: number;
  selectedIds: SelectedIds;
  arrangeByKey: T;
  arrangeByValue?: string | number;
  isNoneSection?: boolean;
};

export type SelectedSections<T> = Map<string, SelectedSection<T>>;

export const useSelectSections = <T>() => {
  const [selectedSections, setSelectedSections] = useState<SelectedSections<T>>(new Map());

  const clearSelectedSections = useCallback(() => {
    setSelectedSections(new Map());
  }, []);

  const toggleSelectSection = useCallback((section: Omit<SelectedSection<T>, 'selectedIds'>) => {
    setSelectedSections((s) => {
      const map = new Map(s);
      const existingSection = map.get(section.title);

      map.set(section.title, {
        ...section,
        selectedIds: existingSection?.selectedIds === 'all' ? new Set() : 'all',
      });

      return map;
    });
  }, []);

  const toggleSelectIdInSection = useCallback(
    (section: Omit<SelectedSection<T>, 'selectedIds'>, id: string) => {
      setSelectedSections((s) => {
        const map = new Map(s);
        const existingSection = map.get(section.title);

        if (!existingSection || existingSection.selectedIds === 'all') {
          map.set(section.title, { ...section, selectedIds: new Set([id]) });
          return map;
        }

        const updatedSelectIds = new Set(existingSection.selectedIds);
        updatedSelectIds.has(id) ? updatedSelectIds.delete(id) : updatedSelectIds.add(id);

        map.set(section.title, { ...section, selectedIds: updatedSelectIds });
        return map;
      });
    },
    [],
  );

  return {
    selectedSections,
    toggleSelectIdInSection,
    toggleSelectSection,
    clearSelectedSections,
  };
};
