import annualPlanner from './annualPlanner.json';
import applications from './applications.json';
import assessments from './assessments.json';
import attendance from './attendance.json';
import companies from './companies.json';
import conduct from './conduct.json';
import consentForms from './consentForms.json';
import criteria from './criteria.json';
import events from './events.json';
import eventSignUps from './eventSignUps.json';
import frequencies from './frequencies.json';
import groups from './groups.json';
import invites from './invites.json';
import legalEntities from './legalEnities.json';
import login from './login.json';
import memberships from './memberships.json';
import messages from './messages.json';
import migration from './migration.json';
import parents from './parents.json';
import payableFees from './payableFees.json';
import products from './products.json';
import profile from './profile.json';
import relationship from './relationship.json';
import reports from './reports.json';
import root from './root.json';
import school from './school.json';
import sections from './sections.json';
import signUp from './signUp.json';
import singleInvoices from './singleInvoices.json';
import staff from './staff.json';
import students from './students.json';
import userRoles from './userRoles.json';
import verify from './verify.json';

const allSections = {
  ...sections,
  ...annualPlanner,
  ...groups,
  ...memberships,
  ...login,
  ...messages,
  ...login,
  ...migration,
  ...students,
  ...parents,
  ...staff,
  ...profile,
  ...userRoles,
  ...signUp,
  ...invites,
  ...verify,
  ...invites,
  ...messages,
  ...assessments,
  ...reports,
  ...root,
  ...school,
  ...attendance,
  ...conduct,
  ...products,
  ...criteria,
  ...applications,
  ...relationship,
  ...events,
  ...eventSignUps,
  ...companies,
  ...payableFees,
  ...consentForms,
  ...legalEntities,
  ...frequencies,
  ...singleInvoices,
};

export default allSections;
