import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#24275B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1999 13.9321L4.63423 9.36646C4.32181 9.05404 4.32181 8.5475 4.63423 8.23508C4.94665 7.92267 5.45318 7.92267 5.7656 8.23509L9.19993 11.6694L14.6353 6.23429C14.9477 5.92188 15.4543 5.92189 15.7667 6.23432C16.0791 6.54674 16.0791 7.05328 15.7666 7.36569L9.1999 13.9321Z"
      fill="white"
    />
  </svg>
);
