import { RefObject, useEffect } from 'react';

export default function useHorizontalScroll(
  containerRef: RefObject<HTMLElement>,
  disabled?: boolean,
) {
  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return undefined;
    }

    function handleScroll(e: WheelEvent) {
      // Ignore horizontal scroll and let it work natively (e.g. on touchpad)
      if (!e.deltaX) {
        container!.scrollLeft += e.deltaY / 4;
      }
    }

    container.addEventListener('wheel', handleScroll, { passive: true });

    return () => {
      container.removeEventListener('wheel', handleScroll);
    };
  }, [containerRef, disabled]);
}
