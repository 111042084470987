import { MenuItem, MenuList } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalSidebarItem } from '../../../components/ui/ModalSidebar';
import { ModalSideBar } from '../../../components/uikit-components/Modal/Modal.styled';
import { CompanyPreviewModalTabs, useCompany } from '../../../context/companies/WithCompany';

export interface CompanyPreviewModalSideBarProps {
  handleChangeActiveTab: (tab: ModalSidebarItem<CompanyPreviewModalTabs>) => void;
}

export const CompanyPreviewModalSideBar: React.FC<CompanyPreviewModalSideBarProps> = ({
  handleChangeActiveTab,
}) => {
  const { tabs, activeTab } = useCompany();

  return (
    <ModalSideBar
      sx={{
        paddingTop: (theme) => theme.spacing(0.75),
      }}
    >
      <MenuList>
        {tabs.map((tab) => (
          <MenuItem
            key={tab.id}
            onClick={() => handleChangeActiveTab(tab)}
            selected={tab.id === activeTab}
            sx={(theme) => ({ marginBottom: theme.spacing(0.5) })}
          >
            <FormattedMessage id={tab.title} />
          </MenuItem>
        ))}
      </MenuList>
    </ModalSideBar>
  );
};
