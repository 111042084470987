import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25 10.5C16.25 10.9142 15.9142 11.25 15.5 11.25L6.31066 11.25L9.03033 13.9697C9.32322 14.2626 9.32322 14.7374 9.03033 15.0303C8.73744 15.3232 8.26256 15.3232 7.96967 15.0303L3.96967 11.0303C3.67678 10.7374 3.67678 10.2626 3.96967 9.96967L7.96967 5.96967C8.26256 5.67678 8.73744 5.67678 9.03033 5.96967C9.32322 6.26256 9.32322 6.73744 9.03033 7.03033L6.31066 9.75L15.5 9.75C15.9142 9.75 16.25 10.0858 16.25 10.5Z"
      fill="#5D6688"
    />
  </svg>
);
