import { Box, Stack, Tooltip } from '@mui/material';
import { Attention2Icon, CheckIcon, SimpleButton } from '@schooly/style';
import { addYears, isAfter, isBefore, subYears } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

type FarDateWarningProps = {
  distanceInYears?: number;
  date: string | number;
  checkFarDate?: boolean;
};
export const FarDateWarning: FC<FarDateWarningProps> = ({
  checkFarDate = true,
  date,
  distanceInYears = 3,
}) => {
  const { $t } = useIntl();
  const [ignoreFarDate, setIgnoreFarDate] = useState(false);

  const handleIgnoreFarDate = useCallback(() => {
    setIgnoreFarDate(true);
  }, []);

  const isFarDate = useMemo(() => {
    if (!checkFarDate || ignoreFarDate) {
      return false;
    }

    const val = new Date(date);
    const now = new Date();

    return (
      isAfter(val, addYears(now, distanceInYears)) || isBefore(val, subYears(now, distanceInYears))
    );
  }, [checkFarDate, date, distanceInYears, ignoreFarDate]);

  if (!isFarDate || !checkFarDate || !date) return null;

  return (
    <Tooltip
      title={
        <Box>
          {$t({ id: 'datepicker-FarDate-Alert' })}
          <SimpleButton
            startIcon={<CheckIcon />}
            sx={(theme) => ({ ...theme.typography.body1, mt: 1.25 })}
            onClick={handleIgnoreFarDate}
          >
            {$t({ id: 'datepicker-FarDate-Ignore' })}
          </SimpleButton>
        </Box>
      }
      sx={{ whiteSpace: 'pre-wrap' }}
    >
      <Stack
        sx={(theme) => ({
          '&&': {
            top: theme.spacing(1.5),
            color: 'warning.main',
            fontSize: theme.spacing(2.5),

            '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
          },
        })}
      >
        <Attention2Icon />
      </Stack>
    </Tooltip>
  );
};
