import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3C3.44772 3 3 3.44772 3 4V12C3 12.5523 3.44772 13 4 13H5H8H9H12C12.5523 13 13 12.5523 13 12V9V8V4C13 3.44772 12.5523 3 12 3H4ZM14 8V9H17V17H9V14H8V17C8 17.5523 8.44772 18 9 18H17C17.5523 18 18 17.5523 18 17V9C18 8.44772 17.5523 8 17 8H14Z"
      fill="#24275B"
    />
  </svg>
);
