import { useGetAttendanceRegisterQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, { FC, PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  AttendanceRegisterContext,
  AttendanceRegisterContextProps,
} from './AttendanceRegisterContext';

/**
 * Context wrapper for GroupPreview and TakeAttendance modals
 *
 * @param children
 * @constructor
 */
export const WithAttendanceRegister: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams();
  const { permissions } = useAuth();

  const canEdit = permissions.includes('attendance_manager');

  const [showByPresentAbsent, setShowByPresentAbsent] = useState(false);
  const [showByPercent, setShowByPercent] = useState(false);

  const {
    data: register,
    isLoading,
    refetch,
    error,
  } = useGetAttendanceRegisterQuery(id ?? '', {
    enabled: Boolean(id),
    refetchOnMount: 'always',
  });

  const value: AttendanceRegisterContextProps = {
    error,
    register,
    request: refetch,
    fetching: isLoading,
    canEdit,
    showByPresentAbsent,
    showByPercent,
    setShowByPresentAbsent,
    setShowByPercent,
  };

  return (
    <AttendanceRegisterContext.Provider value={value}>
      {children}
    </AttendanceRegisterContext.Provider>
  );
};
