import { FilterValue, GroupOptions, GroupUserType, SelectOption } from '@schooly/api';
import { ConductConnotation, ConductType } from '@schooly/api';
import { buildClassName } from '@schooly/utils/build-classname';
import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

import searchWords from '../../../../utils/searchWords';
import Tag from '../../../ui/Tag';

interface FilterConductTypeSelectPopupContentProps {
  searchQuery: string;
  options?: SelectOption<FilterValue>[];
  groupOptions?: GroupOptions;
  hasNoneOption?: boolean;
  popupTitleTextId?: string;
  isOptionActive?: (option: SelectOption<FilterValue>, userType?: GroupUserType) => boolean;
  handleSelectOption: (option?: SelectOption<FilterValue>, userType?: GroupUserType) => void;
  handleRemoveOption: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    option?: SelectOption<FilterValue>,
    userType?: GroupUserType,
  ) => void;
  noneOption: SelectOption<FilterValue>;
}

const FilterConductTypeSelectPopupContent: FC<FilterConductTypeSelectPopupContentProps> = ({
  searchQuery,
  options = [],
  groupOptions,
  hasNoneOption,
  isOptionActive,
  popupTitleTextId,
  handleSelectOption,
  handleRemoveOption,
  noneOption,
}) => {
  const { formatMessage } = useIntl();

  const getOptionText = useCallback(
    (option: SelectOption<FilterValue>) =>
      option.labelTextId ? formatMessage({ id: option.labelTextId }) : option.label,
    [formatMessage],
  );

  const onSelectOption = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, selectOption?: SelectOption<FilterValue>) => {
      const isActive = isOptionActive && selectOption && isOptionActive(selectOption);

      if (isActive) {
        handleRemoveOption(e, selectOption);
      } else {
        handleSelectOption(selectOption);
      }
    },
    [handleSelectOption, handleRemoveOption, isOptionActive],
  );

  const [displayedOptions, groupedOptions] = useMemo(() => {
    const items =
      !options || (options.length < 1 && groupOptions && Object.keys(groupOptions).length > 0)
        ? Object.values(groupOptions ?? {})[0]
        : options;

    const displayedOptions = searchQuery
      ? items.filter((o) => {
          const text = getOptionText(o);
          return text && searchWords(text, searchQuery);
        })
      : items;

    const groupedOptions = displayedOptions.reduce<
      Partial<Record<ConductConnotation, SelectOption[]>>
    >((prev, option) => {
      const item = option.item as ConductType;

      if (!prev[item.connotation]) {
        prev[item.connotation] = [];
      }

      prev[item.connotation]!.push(option);

      return prev;
    }, {});

    return [displayedOptions, groupedOptions];
  }, [options, groupOptions, searchQuery, getOptionText]);

  if (searchQuery && !displayedOptions.length) {
    return (
      <div className="mb-2">
        <span className="dropdown-item form-select-option form-select-no-options-found">
          <FormattedMessage id="input-NoOptionsFound" />
        </span>
      </div>
    );
  }

  const renderNoneOption = (className?: string) => {
    const isActive = isOptionActive && isOptionActive(noneOption);

    return (
      <li key={noneOption.value} className={className}>
        <Tag
          selectOption={noneOption}
          className={isActive ? 'active' : undefined}
          onClick={onSelectOption}
          isBig
        >
          {getOptionText(noneOption)}
        </Tag>
      </li>
    );
  };

  const renderFilterOptions = (filterOptions: SelectOption<FilterValue>[]) =>
    filterOptions.map((option) => {
      const isActive = isOptionActive && isOptionActive(option);

      return (
        <li key={option.value}>
          <Tag
            selectOption={option}
            className={isActive ? 'active' : undefined}
            onClick={onSelectOption}
            isBig
          >
            {getOptionText(option)}
          </Tag>
        </li>
      );
    });

  return (
    <>
      {(Object.keys(groupedOptions) as unknown as ConductConnotation[]).map((connotation) => {
        const titleClassName = 'filter-select-title pr-0 pl-0';
        const options = groupedOptions[connotation];

        if (!options?.length) {
          return null;
        }

        const connotationKey = $enum(ConductConnotation)
          .getKeyOrDefault(Number(connotation), '')
          .toLowerCase();

        if (Object.keys(groupedOptions).length === 1) {
          return (
            <Fragment key={`connotation-${connotationKey}`}>
              {!searchQuery && hasNoneOption && renderNoneOption(undefined)}
              <h4 className="h4 filter-select-title">
                <FormattedMessage id={`conduct-Type-${connotationKey}`} />
              </h4>
              {renderFilterOptions(options)}
            </Fragment>
          );
        }

        return (
          <Fragment key={`connotation-${connotationKey}`}>
            <h4 className={buildClassName('h4', titleClassName)}>
              <FormattedMessage id={`conduct-Type-${connotationKey}`} />
            </h4>
            {!searchQuery &&
              hasNoneOption &&
              renderNoneOption(buildClassName(titleClassName, 'pb-1'))}
            {renderFilterOptions(options)}
          </Fragment>
        );
      })}
    </>
  );
};

export default FilterConductTypeSelectPopupContent;
