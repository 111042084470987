import { UserType } from '@schooly/api';
import { WithAvatar, WithName } from '@schooly/api';
import { ProfileIcon, StarIcon } from '@schooly/style';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getUserFullName, getUserInitials } from '../../../helpers/users';
import { usePrivateImage } from '../../../hooks/usePrivateImage';
import buildClassName from '../../../utils/buildClassName';

import './index.scss';

export interface AvatarProps {
  user: WithName & WithAvatar;
  userType?: UserType;
  pictureUrlOverride?: string;
  size?: 'small' | 'small-plus' | 'medium' | 'large';
  hasFavoriteMark?: boolean;
  className?: string;
  showAriaLabel?: boolean;
  isProfile?: boolean;
  onClick?: VoidFunction;
}

const Avatar: React.FC<AvatarProps> = ({
  user,
  pictureUrlOverride,
  size,
  hasFavoriteMark,
  isProfile,
  className,
  showAriaLabel,
  onClick,
}) => {
  const { formatMessage } = useIntl();

  const displayedPicture = useMemo(() => {
    if (pictureUrlOverride !== undefined) {
      return pictureUrlOverride;
    }

    return user.profile_picture;
  }, [pictureUrlOverride, user]);

  const { key } = usePrivateImage(user.profile_picture);

  const initials = getUserInitials(user);

  const fullClassName = buildClassName(
    'Avatar',
    `Avatar--${size}`,
    !onClick && 'cursor-pointer',
    !displayedPicture && 'Avatar--no-picture',
    className,
  );

  const picture = useMemo(() => {
    if (displayedPicture) {
      return (
        <img
          src={displayedPicture}
          alt={formatMessage({ id: 'ariaLabel-Avatar' })}
          className="img-fluid Avatar__img"
        />
      );
    }

    return isProfile ? <ProfileIcon /> : <span className="Avatar__initials">{initials}</span>;
  }, [displayedPicture, formatMessage, initials, isProfile]);

  const content = (
    <>
      <div key={key} className="Avatar__inner">
        {picture}
      </div>
      {hasFavoriteMark && (
        <>
          <StarIcon className="Avatar__favorite-mark__backplane" />
          <StarIcon className="Avatar__favorite-mark" />
        </>
      )}
    </>
  );

  const ariaLabel = showAriaLabel ? getUserFullName(user) : undefined;

  if (onClick) {
    return (
      <div
        role="button"
        tabIndex={0}
        className={fullClassName}
        onClick={onClick}
        aria-label={ariaLabel}
        title={ariaLabel}
      >
        {content}
      </div>
    );
  }

  return (
    <div className={fullClassName} aria-label={ariaLabel} title={ariaLabel}>
      {content}
    </div>
  );
};

Avatar.defaultProps = {
  size: 'small',
};

export default Avatar;
