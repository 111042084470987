import { Stack, Typography } from '@mui/material';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { TagSelectCounter, TagSelectProperty, TagSelectPropertyProps } from '@schooly/style';
import { FC, PropsWithChildren, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { getSelectedItemsWithGrouping, SelectedItem, SelectedItemWithGrouping } from '../utils';
import { DottedDivider } from './DottedDivider.styled';
import { RenderPropertyTypeTagsParams } from './PropertyTypeExpandedSelect';

type AgeGroupExpandedSelectProps = PropsWithChildren<{
  userRole: SchoolUserRole;
  schoolId: string;
  selectedIds: string[];
  onSelect: (v: string[]) => void;
  onClear: () => void;
  onClose: () => void;
}>;
export const AgeGroupExpandedSelect: FC<AgeGroupExpandedSelectProps> = ({
  userRole,
  schoolId,
  selectedIds,
  onSelect,
  onClose,
  onClear,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    schoolLevels: schoolLevelsInit,
    activeAgeGroups: activeAgeGroupsInit,
    archivedAgeGroups: archivedAgeGroupsInit,
    schoolLevelsWithAgeGroupsMap,
    isLoading,
    getAgeGroupsByLevelId,
    getAgeGroupById,
    getSchoolLevelById,
  } = useAgeGroups({
    schoolId: schoolId,
    userType: userRole,
  });

  const selectedItems: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups: SelectedItem[] = [];

    selectedIds?.forEach((id) => {
      const ageGroup = getAgeGroupById(id);
      if (ageGroup?.id) {
        selectedAgeGroups.push({
          id: ageGroup.id,
          groupId: ageGroup.level_id,
        });
      }
    });

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [selectedIds, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  const [query, setQuery] = useState('');
  const [hoveredLevelId, setHoveredLevelId] = useState('');

  const schoolLevels = schoolLevelsInit?.filter((p) =>
    p.name.toLowerCase().includes(query.toLowerCase()),
  );
  const activeAgeGroups = activeAgeGroupsInit?.filter((p) =>
    p.name.toLowerCase().includes(query.toLowerCase()),
  );
  const archivedAgeGroups = archivedAgeGroupsInit?.filter((p) =>
    p.name.toLowerCase().includes(query.toLowerCase()),
  );

  const renderContent = useCallback(() => {
    if (isLoading) return <SelectContentSkeleton />;

    return (
      <>
        <Stack mx={1} my={2} gap={2}>
          {Boolean(schoolLevels.length) && (
            <Stack gap={0.5}>
              <Typography variant="h4">
                <FormattedMessage id="school-properties-ageGroups-schoolLevels" />
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {schoolLevels.map((level) => (
                  <TagSelectProperty
                    userRole={userRole}
                    key={level.id}
                    variant={
                      selectedItems.some((item) => item.id === level.id) ? 'filled' : undefined
                    }
                    onClick={() => {
                      const ageGroupsOfLevelIds = getAgeGroupsByLevelId(level.id).map((g) => g.id);
                      onSelect(ageGroupsOfLevelIds);
                      setQuery('');
                    }}
                    className={hoveredLevelId === level.id ? 'hoveredTag' : undefined}
                    onMouseEnter={() => setHoveredLevelId(level.id)}
                    onMouseLeave={() => setHoveredLevelId('')}
                    label={
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        {level.name}
                        <TagSelectCounter inverse>
                          {getAgeGroupsByLevelId(level.id).length}
                        </TagSelectCounter>
                      </Stack>
                    }
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {Boolean(activeAgeGroups?.length) && (
            <Stack gap={0.5}>
              <Typography variant="h4">
                <FormattedMessage id="school-properties-ageGroups" />
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {activeAgeGroups?.map((ageGroup) => (
                  <TagSelectProperty
                    key={ageGroup.id}
                    userRole={userRole}
                    property={{ ...ageGroup, type: SchoolPropertyType.AgeGroup }}
                    variant={selectedIds?.includes(ageGroup.id) ? 'filled' : undefined}
                    onClick={() => {
                      onSelect([ageGroup.id]);
                      setQuery('');
                    }}
                    outlined={Boolean(hoveredLevelId && ageGroup.level_id === hoveredLevelId)}
                  />
                ))}
              </Stack>
            </Stack>
          )}

          {Boolean(archivedAgeGroups?.length) && (
            <Stack gap={2}>
              <DottedDivider />
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {archivedAgeGroups?.map((ageGroup) => (
                  <TagSelectProperty
                    key={ageGroup.id}
                    userRole={userRole}
                    property={{ ...ageGroup, type: SchoolPropertyType.AgeGroup }}
                    variant={selectedIds?.includes(ageGroup.id) ? 'filled' : undefined}
                    onClick={() => {
                      onSelect([ageGroup.id]);
                      setQuery('');
                    }}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </>
    );
  }, [
    isLoading,
    schoolLevels,
    activeAgeGroups,
    archivedAgeGroups,
    selectedItems,
    getAgeGroupsByLevelId,
    userRole,
    selectedIds,
    hoveredLevelId,
    onSelect,
  ]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedIds.length > 0}
      onClose={onClose}
      onClear={onClear}
      onClickInputArea={() => inputRef.current?.focus()}
      renderContent={renderContent}
    >
      {renderPropertyGroupTags({
        selectedItems,
        onDelete: (ids) => {
          onSelect(ids);
          setHoveredLevelId('');
        },
        getProperty: (i) =>
          i.isGroup
            ? getSchoolLevelById(i.id)
            : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
        getItemsOfGroupIds: (i) =>
          i.isGroup ? getAgeGroupsByLevelId(i.id).map(({ id }) => id) : [i.id],
        onGroupMouseEnter: (id) => setHoveredLevelId(id),
        onGroupMouseLeave: () => setHoveredLevelId(''),
        tagProps: {
          userRole,
          size: 'small',
        },
      })}
      <SelectSearchInput ref={inputRef} autoFocus value={query} onChangeText={setQuery} />
    </ExpandedSelect>
  );
};

type RenderPropertyGroupTagsProps = {
  selectedItems: SelectedItemWithGrouping[];
  getProperty: (i: SelectedItemWithGrouping) => TagSelectPropertyProps['property'];
  onDelete?: (v: string[]) => void;
  onClick?: (v: string) => void;
  onGroupMouseEnter?: (v: string) => void;
  onGroupMouseLeave?: (v: string) => void;
  getItemsOfGroupIds?: (i: SelectedItemWithGrouping) => string[];
  getTooltip?: (i: SelectedItemWithGrouping) => ReactNode;
  tagProps: Omit<RenderPropertyTypeTagsParams['tagProps'], 'schoolId'>;
};

export const renderPropertyGroupTags = ({
  selectedItems,
  tagProps,
  getProperty,
  getItemsOfGroupIds,
  getTooltip,
  onClick,
  onDelete,
  onGroupMouseEnter,
  onGroupMouseLeave,
}: RenderPropertyGroupTagsProps) =>
  selectedItems.map((item) => {
    const property = getProperty(item);
    const itemsOfGroupIds = getItemsOfGroupIds ? getItemsOfGroupIds(item) : [];
    const tooltip = getTooltip ? getTooltip(item) : null;

    return (
      <TagSelectProperty
        key={item.id}
        property={property}
        onClick={onClick ? () => onClick(item.id) : undefined}
        onDelete={onDelete ? () => onDelete(itemsOfGroupIds) : undefined}
        onMouseEnter={
          item.isGroup && onGroupMouseEnter ? () => onGroupMouseEnter(item.id) : undefined
        }
        onMouseLeave={item.isGroup && onGroupMouseLeave ? onGroupMouseLeave : undefined}
        tooltip={tooltip}
        {...(typeof tagProps === 'function' ? tagProps(item.id) : tagProps)}
      />
    );
  });
