import { Stack, styled } from '@mui/material';

export const RadioOptionCard = styled(Stack)<{ checked: boolean; disabled?: boolean }>(
  ({ theme, checked, disabled }) => ({
    cursor: disabled ? 'auto' : 'pointer',
    padding: theme.spacing(1.5),
    border: theme.mixins.borderValue(),
    borderColor: checked ? theme.palette.common.light3 : theme.palette.common.grey7,
    borderRadius: theme.spacing(1),
    color: checked ? theme.palette.common.grey2 : theme.palette.common.grey,

    '& .MuiFormControlLabel-root': {
      margin: 0,
      alignItems: 'flex-start',
    },

    ...(disabled
      ? {
          '&.MuiStack-root': {
            color: checked ? theme.palette.common.grey2 : theme.palette.common.grey6,
          },
          '& .Mui-disabled.MuiFormControlLabel-root': {
            '.MuiFormControlLabel-label': {
              color: checked ? theme.palette.primary.main : theme.palette.common.grey6,
            },

            ' .svg-icon circle': {
              fill: 'none',
              '&:first-of-type': {
                stroke: checked ? theme.palette.common.light3 : theme.palette.common.main6,
              },
              '&:nth-child(2)': {
                fill: theme.palette.primary.main,
              },
            },
          },
        }
      : {
          '&:hover': {
            backgroundColor: theme.palette.background.default,
            '.MuiTypography-root': {
              color: checked ? theme.palette.primary.main : theme.palette.common.grey2,
            },
            '.MuiFormControlLabel-root': {
              color: checked ? theme.palette.primary.main : theme.palette.common.grey2,
              ' .svg-icon circle:first-of-type': {
                '&:first-of-type': {
                  stroke: theme.palette.primary.main,
                },
                '&:nth-child(2)': {
                  fill: theme.palette.primary.light,
                },
              },
            },
          },
        }),
  }),
);
