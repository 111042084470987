import { Group, GroupType } from '@schooly/api';
import { SelectOption } from '@schooly/api';
import { DoneIcon, TagSubject } from '@schooly/style';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { NON_BREAKING_SPACE } from '../../../constants/typography';
import { noop } from '../../../helpers/misc';
import renderGroupValidity from '../../../helpers/renderGroupValidity';
import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';

import './SelectGroupRow.scss';

export interface SelectGroupRowProps {
  isSelected?: boolean;
  option?: SelectOption<string, Group>;
  onClick: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    selectOption?: SelectOption<string, Group>,
  ) => void;
}

const $class = `${$UIKitPrefix}SelectGroupRow`;

export const SelectGroupRow: React.FC<SelectGroupRowProps> = ({
  isSelected,
  option,
  onClick = noop,
}) => {
  const { $t } = useIntl();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      if (onClick) {
        onClick(e, option);
      }
    },
    [onClick, option],
  );

  if (!option) return null;

  return (
    <button
      onClick={handleClick}
      className={buildClassName($class, 'reset-button-styles', isSelected && `${$class}_selected`)}
    >
      <p className={`${$class}__name`}>{option.item?.name}</p>
      {option.item?.validity && (
        <p className={`${$class}__validity`}>{renderGroupValidity(option.item, 'D MMM YY')}</p>
      )}
      {option.item?.group_type === GroupType.TutorGroup || option.item?.subject?.name ? (
        <div className={`${$class}__subject`}>
          <TagSubject archived={option.item.subject?.archived}>
            {option.item.group_type === GroupType.TutorGroup
              ? $t({ id: 'groups-TutorGroup' })
              : option.item.subject?.name}
          </TagSubject>
        </div>
      ) : (
        <div className={`${$class}__stub`}>{NON_BREAKING_SPACE}</div>
      )}
      <div className={`${$class}__done`}>
        {isSelected && <DoneIcon className={`${$class}__done-icon`} />}
      </div>
    </button>
  );
};
