import { FormControlLabel, styled } from '@mui/material';

export interface FormCheckboxStyledProps {
  error?: any;
  withBorder?: boolean;
  withBackground?: boolean;
}

export const FormCheckboxStyled = styled(FormControlLabel)<FormCheckboxStyledProps>(
  ({ theme, error, withBorder, withBackground }) => ({
    borderRadius: theme.shape.borderRadius,
    border: !!withBorder ? theme.mixins.borderControlValue() : 'undefined',
    borderColor: error ? theme.palette.error.main : undefined,
    ...(withBackground ? { backgroundColor: theme.palette.background.paper } : { undefined }),
    margin: 0,
    padding: theme.spacing(0.25, 2, 0.25, 0.5),
    cursor: 'pointer',

    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.secondary,
    },

    '& .Mui-checked ~ .MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
    },

    '&.Mui-disabled': {
      borderColor: !!withBorder ? theme.palette.common.light2 : undefined,
      '& .Mui-checked': {
        color: theme.palette.text.disabled,
        '& ~ .MuiFormControlLabel-label': {
          color: theme.palette.text.disabled,
        },
        '& .svg-icon circle': {
          stroke: theme.palette.common.light2,
          fill: 'none',
        },
        '& .svg-icon circle:nth-child(2)': {
          stroke: 'none',
          fill: theme.palette.common.light2,
        },
      },
    },
  }),
);
