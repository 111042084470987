import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, GroupType } from '@schooly/api';
import { GroupMembership, RelationGroup } from '@schooly/api';
import { SchoolYear } from '@schooly/api';
import { DATE_FORMAT } from '@schooly/constants';
import { TagSubject, TypographyWithOverflowHint } from '@schooly/style';
import { addDays, format, isBefore, isSameDay, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../../hooks/useAppLocation';
import { LongNameWithTooltip } from '../LongNameWithTooltip/LongNameWithTooltip';
import { GroupRowLayout, ListViewGroupRowLayout } from './ListViewGroupRow.styled';

export interface ListViewGroupRowProps {
  group: RelationGroup;
  schoolYear: SchoolYear;
  active?: boolean;
}

interface ProgressBarEmptyValue {
  type: 'empty';
  width: number;
  endIndex: number;
}

type ProgressBarData =
  | (Omit<ProgressBarEmptyValue, 'type'> & { type: 'filled'; fromDate: string })
  | ProgressBarEmptyValue;

export const ListViewGroupRow: React.FC<ListViewGroupRowProps> = ({
  group,
  schoolYear,
  active,
}) => {
  const { name, subject, group_type, id } = group;

  const location = useAppLocation();
  const { formatMessage } = useIntl();

  const schoolYearDays = React.useMemo(() => {
    const startYearDay = parseISO(schoolYear.start);
    const endYearDay = parseISO(schoolYear.end);

    const schoolYearDays: string[] = [];

    for (
      let m = startYearDay;
      isSameDay(m, endYearDay) || isBefore(m, endYearDay);
      m = addDays(m, 1)
    ) {
      schoolYearDays.push(format(m, DEFAULT_DATE_FORMAT_FNS));
    }

    return schoolYearDays;
  }, [schoolYear.end, schoolYear.start]);

  const progressBarData = useMemo(() => {
    const data: ProgressBarData[] = [];
    const schoolYearLastIndex = schoolYearDays.length - 1;
    const unitWidth = 100 / schoolYearLastIndex;

    const getDateNumber = (date: string) => parseISO(date).valueOf();

    const sortedMemberships = [...group.memberships]
      .sort((a, b) => getDateNumber(a.start) - getDateNumber(b.start))
      .reduce<GroupMembership[]>((acc, next) => {
        const sameDates = acc.some(
          (group) =>
            getDateNumber(group.start) === getDateNumber(next.start) &&
            getDateNumber(group.end) === getDateNumber(next.end),
        );
        return sameDates ? acc : [...acc, next];
      }, []);

    sortedMemberships.forEach((m) => {
      const startIndex = schoolYearDays.indexOf(m.start);
      const endIndex = schoolYearDays.indexOf(m.end);

      // startIndex(endIndex) can be negative because startDate(or endDate) of membership can be earlier(or later) than school year started
      const currentEndIndex = endIndex === -1 ? schoolYearLastIndex : endIndex;
      const currentStartIndex = startIndex === -1 ? 0 : startIndex;

      const currentWidth = (currentEndIndex - currentStartIndex) * unitWidth;
      const prev = data[data.length - 1] || null;

      const emptyWidth = prev
        ? (currentStartIndex - prev.endIndex) * unitWidth
        : currentStartIndex * unitWidth;

      const isOverFilled =
        prev?.type === 'filled' && (prev.endIndex >= endIndex || prev.endIndex >= startIndex);

      if (emptyWidth && !isOverFilled) {
        data.push({
          type: 'empty',
          width: emptyWidth,
          endIndex: currentStartIndex,
        });
      }

      data.push({
        type: 'filled',
        width: currentWidth,
        endIndex: currentEndIndex,
        fromDate: m.start,
      });
    });

    return data;
  }, [group.memberships, schoolYearDays]);

  return (
    <ListViewGroupRowLayout>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2.5}
        paddingX={0.75}
        paddingY={1}
      >
        {name ? (
          <Box sx={{ position: 'relative', width: '100%', height: '28px' }}>
            <LongNameWithTooltip
              css={{
                position: 'absolute',
                width: '100%',
                ':hover': { textDecoration: 'underline' },
              }}
            >
              <Link
                to={`/groups/${id}`}
                state={{
                  prevPathname: `${location.pathname}${location.hash ?? ''}`,
                }}
                style={{ color: 'inherit' }}
              >
                {name}
              </Link>
            </LongNameWithTooltip>
          </Box>
        ) : null}

        <Stack direction="row" alignItems="center" gap={2.5}>
          {group_type === GroupType.TutorGroup || subject ? (
            <Stack width="150px" gap={0.75} alignItems="baseline">
              <TagSubject archived={subject?.archived}>
                <Stack maxWidth="150px">
                  <TypographyWithOverflowHint>
                    {group_type === GroupType.TutorGroup ? (
                      <FormattedMessage id="groups-TutorGroup" />
                    ) : (
                      subject?.name
                    )}
                  </TypographyWithOverflowHint>
                </Stack>
              </TagSubject>
            </Stack>
          ) : null}

          <GroupRowLayout>
            {progressBarData.map((data) =>
              data.type === 'filled' ? (
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: 'white',
                        boxShadow: '0px 20px 40px rgba(37, 39, 90, 0.1)',
                        backgroundColor: 'white',
                        '& .MuiTooltip-arrow': {
                          color: 'white',
                        },
                        borderRadius: (theme) => theme.shape.borderRadius,
                      },
                    },
                  }}
                  arrow
                  title={
                    <Typography color="common.grey2" variant="body1">{`${formatMessage({
                      id: 'messages-From',
                    })} ${format(parseISO(data.fromDate), DATE_FORMAT)}`}</Typography>
                  }
                >
                  <Box
                    sx={(theme) => ({
                      width: `${data.width}%`,
                      background: active ? theme.palette.primary.main : theme.palette.common.grey,
                      height: '100%',
                    })}
                  />
                </Tooltip>
              ) : (
                <Box
                  sx={{
                    width: `${data.width}%`,
                    height: '100%',
                  }}
                />
              ),
            )}
          </GroupRowLayout>
        </Stack>
      </Stack>
    </ListViewGroupRowLayout>
  );
};
