import { Stack } from '@mui/material';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import React, { FC, useMemo } from 'react';

import { useCheckSchoolSettings } from '../../../hooks/useCheckSchoolSettings';
import { useSchool } from '../../../hooks/useSchool';
import { SchoolPageAddProperty } from '../SchoolPageAddProperty';
import { SchoolGeneralAgeGroups } from './SchoolGeneralAgeGroups/SchoolGeneralAgeGroups';
import { SchoolGeneralDepartments } from './SchoolGeneralDepartments/SchoolGeneralDeparments';
import { SchoolGeneralHouses } from './SchoolGeneralHouses/SchoolGeneralHouses';
import { SchoolGeneralSchoolYears } from './SchoolGeneralSchoolYears/SchoolGeneralSchoolYears';
import { SchoolGeneralStatuses } from './SchoolGeneralStatuses/SchoolGeneralStatuses';
import { SchoolGeneralSubjects } from './SchoolGeneralSubjects/SchoolGeneralSubjects';

/**
 * Displays General tab. No MainLayout here as this is a part of SchoolPageCompleted component,
 * which already exposes MainLayout by its own.
 */
export const SchoolGeneral: FC = () => {
  const { schoolId = '' } = useSchool();

  const { hasSubjects, hasDepartments, hasHouses } = useCheckSchoolSettings();

  const { activePropertiesMap, isLoading } = useSchoolProperties(
    {
      schoolId,
      userType: SchoolUserRole.Staff,
    },
    { enabled: !!schoolId },
  );

  const optionalCards = useMemo(() => {
    const cardComponents = [];

    if (hasSubjects) {
      cardComponents.push(<SchoolGeneralSubjects key={'subjects'} />);
    }

    if (hasHouses) {
      cardComponents.push(<SchoolGeneralHouses key={'houses'} />);
    }

    if (hasDepartments) {
      cardComponents.push(
        <SchoolGeneralDepartments
          isLoading={isLoading}
          departments={activePropertiesMap.department}
          key={'departments'}
        />,
      );
    }

    if (cardComponents.length < 3) {
      cardComponents.push(<SchoolPageAddProperty key={'addProperty'} />);
    }

    return cardComponents;
  }, [hasSubjects, hasHouses, hasDepartments, isLoading, activePropertiesMap.department]);

  return (
    <Stack direction="row" spacing={2}>
      <Stack flex="1 1 50%" spacing={2}>
        <SchoolGeneralSchoolYears />
        <SchoolGeneralStatuses />
        {optionalCards.filter((_, index) => index % 2 !== 0)}
      </Stack>
      <Stack flex="1 1 50%" spacing={2}>
        <SchoolGeneralAgeGroups />
        {optionalCards.filter((_, index) => index % 2 === 0)}
      </Stack>
    </Stack>
  );
};
