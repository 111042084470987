import { GET_SCHOOL_PROPERTIES_QUERY, useGetCategoriesQuery } from '@schooly/api';
import { createStatuses } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useSchool } from '../../../../../hooks/useSchool';
import { SchoolSettingsLayout } from '../../../SchoolSettingsLayout';
import { prepareStatusesToSave } from './hooks/useSchoolTuneStatuses';
import { SchoolTuneStatusesForm } from './scheme';
import { SchoolTuneStatusesModalContent } from './SchoolTuneStatusesModalContent';

export const SchoolTuneStatusesModal: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showError } = useNotifications();
  const { schoolId, currentSchool, studentStatuses, staffStatuses } = useSchool();
  const [saving, setSaving] = useState(false);

  const { data } = useGetCategoriesQuery();
  const categories = data?.categories;

  const handleClose = useCallback(() => {
    if (saving) {
      return;
    }

    navigate({ pathname: '/settings/tune', search: '?tab=statuses' });
  }, [navigate, saving]);

  const handleSubmit = useCallback<SubmitHandler<SchoolTuneStatusesForm>>(
    async (data) => {
      if (!schoolId) {
        return;
      }

      setSaving(true);

      const statuses = prepareStatusesToSave(
        categories,
        data,
        (categoryId, index) => (status, i) => ({
          name: status.name ?? '',
          category_id: categoryId,
          order: index + i,
        }),
      );

      try {
        await createStatuses(schoolId, statuses);

        queryClient.invalidateQueries([GET_SCHOOL_PROPERTIES_QUERY]);

        navigate({ pathname: '/settings/tune', search: '?tab=statuses' });
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }

      setSaving(false);
    },
    [categories, navigate, queryClient, schoolId, showError],
  );

  return (
    <SchoolSettingsLayout>
      <SchoolTuneStatusesModalContent
        title={currentSchool?.name || ''}
        isLoading={!categories}
        isSaving={saving}
        onSubmit={handleSubmit}
        onClose={handleClose}
        studentStatuses={studentStatuses}
        staffStatuses={staffStatuses}
      />
    </SchoolSettingsLayout>
  );
};
