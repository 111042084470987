import { Collapse, SxProps, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { PropsWithChildren, ReactNode } from 'react';

type SidebarExpandableCardProps<T> = {
  item: T;
  expanded: boolean;
  itemsCount?: number;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  style?: Omit<SxProps, 'children'>;
  onCardClick?: () => void;
} & PropsWithChildren;

export const SidebarExpandableCard = <T extends { name: string; id: string }>({
  item,
  expanded,
  itemsCount,
  children,
  startIcon,
  endIcon,
  style,
  onCardClick,
}: SidebarExpandableCardProps<T>) => (
  <Stack
    sx={(theme) => ({
      border: `1px solid ${theme.palette.common.light2}`,
      p: theme.spacing(1.5),
      borderRadius: expanded && !!children ? '8px' : '300px',
      mb: 1.25,
      pb: expanded && itemsCount ? 0 : theme.spacing(1.5),
      cursor: 'pointer',
      ...(style ?? {}),
    })}
    onClick={onCardClick}
  >
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Stack flexDirection="row" gap={0.5}>
        <Typography variant="h3" color="text.primary">
          {item.name}
        </Typography>
        {startIcon}
      </Stack>
      {endIcon}
    </Stack>
    <Collapse orientation="vertical" in={expanded}>
      {children && (
        <Stack mt={1} onClick={(e) => e.stopPropagation()}>
          {children}
        </Stack>
      )}
    </Collapse>
  </Stack>
);
