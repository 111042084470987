import { Button, IconButton, Stack, styled } from '@mui/material';

export const AttendanceStatsLayout = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  overflow: 'auto',
}));

// https://www.figma.com/file/rN8wIkDdHQ5TasMRwh9al7/Schooly-%23-1-approved?node-id=11824%3A164205&t=agcR3funXMxsUKFD-0
export const PercentButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadiusSm,
  '&:active': { padding: theme.spacing(0.5) },
}));

export const PercentIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': { color: theme.palette.primary.main },
}));

export const AttendanceStatWithBorderWrapper = styled(Stack)(({ theme }) => ({
  border: theme.mixins.borderControlValue(),
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1, 2.5),
}));
