import { ApplicationChild, Parent } from '@schooly/api';
import { CreateChildForm, CreateParentForm } from '@schooly/components/applications';
import { propsAreNotEmpty } from '@schooly/utils/predicates';
import { removeObjectUndefinedOrNullValues } from '@schooly/utils/remove-object-undefined-or-null-values';

import { convertCustomFieldsToApplicationCustomFields } from './convertCustomFieldToApplicationCustomField';

export const convertChildFormToApplicationChild = (child: CreateChildForm): ApplicationChild => {
  const { school_history, language, custom_fields, known_as, ...rest } = child;

  const customFields = convertCustomFieldsToApplicationCustomFields(custom_fields);

  const languages = language.reduce<ApplicationChild['language'][]>(
    (acc, l) => (propsAreNotEmpty(l) ? [...acc, [l.language, l.speaking, l.writing]] : acc),
    [],
  );

  const [firstLanguage, ...otherLanguages] = languages;

  const schoolHistory = school_history.filter(propsAreNotEmpty);

  return removeObjectUndefinedOrNullValues({
    ...rest,
    school_history: schoolHistory.length ? schoolHistory : undefined,
    language: firstLanguage,
    known_as: known_as ? known_as : undefined,
    other_languages_spoken: otherLanguages.length ? otherLanguages : undefined,
    custom_fields_values: customFields,
  });
};

export const convertParentFormToApplicationParent = (parent: CreateParentForm): Parent => {
  const { custom_fields, ...rest } = parent;

  const customFields = convertCustomFieldsToApplicationCustomFields(custom_fields);

  return removeObjectUndefinedOrNullValues({
    ...rest,
    custom_fields: customFields,
  });
};
