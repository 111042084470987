import { ControlTextField } from '@schooly/components/form-text-field';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { AnnualPlannerCreateForm } from '../../scheme';
import { AnnualPlannerCreateAssessmentDate } from './AnnualPlannerCreateAssessmentDate';

export const AnnualPlannerCreateAssessment: FC = () => {
  const { $t } = useIntl();
  const { control } = useFormContext<AnnualPlannerCreateForm>();

  return (
    <>
      <AnnualPlannerCreateAssessmentDate />

      <ControlTextField
        name="assessment.name"
        control={control}
        label={$t({
          id: 'annualPlanner-Popover-Assessment-Name',
        })}
        rules={{
          required: true,
        }}
        fullWidth
      />
    </>
  );
};
