import { combineReducers } from '@reduxjs/toolkit';

import listSlice, { ReportsListState } from './listSlice';
import previewSlice, { ReportsPreviewState } from './previewSlice';

export interface ReportsState {
  list: ReportsListState;
  preview: ReportsPreviewState;
}

const reducer = combineReducers<ReportsState>({
  list: listSlice.reducer,
  preview: previewSlice.reducer,
});

export default reducer;
