import { AdultChildAssociation } from '@schooly/api';
import { ArrowRightIcon, TickIcon } from '@schooly/style';
import React from 'react';

import { InviteContentIconType } from './InviteContent';

export function getButtonIcon(icon?: InviteContentIconType) {
  switch (icon) {
    case 'tick':
      return { icon: <TickIcon /> };
    case 'arrow':
      return { rightIcon: <ArrowRightIcon /> };
    default:
      return {};
  }
}

export function mapAdultChildAssociationToUser(association: AdultChildAssociation): {
  id?: string;
  given_name?: string;
  last_name: string;
  date_of_birth?: string;
} {
  const { child_given_name, child_last_name, child_date_of_birth, id } = association;

  return {
    given_name: child_given_name,
    last_name: child_last_name,
    date_of_birth: child_date_of_birth,
    id,
    // profile_picture,
  };
}
