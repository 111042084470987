import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';
import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

/** Have to keep OverridableComponent interface as an original Typography component */
export const TypographyWithOverflowHint: OverridableComponent<TypographyTypeMap> = (
  props: TypographyProps,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [hasPopover, setHasPopover] = useState(false);

  useLayoutEffect(() => {
    if (!ref.current || !ref.current.parentElement) {
      return;
    }

    const onParentElementResize = () => {
      if (ref.current?.clientWidth && ref.current.clientWidth < ref.current?.scrollWidth) {
        setHasPopover(true);
      } else if (hasPopover) {
        setHasPopover(false);
      }
    };

    const observer = new ResizeObserver(onParentElementResize);
    observer.observe(ref.current.parentElement);

    return () => {
      observer.disconnect();
    };
  }, [hasPopover]);

  const content = (
    <Typography ref={ref} noWrap {...props}>
      <div ref={anchorRef} />
      {props.children}
    </Typography>
  );

  if (!hasPopover) return content;

  return (
    <Tooltip
      title={props.children}
      placement="bottom-start"
      disableInteractive
      arrow={false}
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            zIndex: 2000,
            maxWidth: 400,
            margin: `${theme.spacing(-3, 0, 0)} !important`,
            padding: theme.spacing(0.5, 1),
          }),
        },
      }}
    >
      {content}
    </Tooltip>
  );
};
