import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateChildForm, CreateParentForm } from '@schooly/components/applications';
import { CreateCustomField } from '@schooly/components/applications';

type EntityType = 'children' | 'parents' | 'generalCustomFields';
export type ApplicationAction = 'create' | 'update';
export type ICreateApplicationFormState = Record<
  ApplicationAction,
  {
    parents: CreateParentForm[];
    children: CreateChildForm[];
    generalCustomFields: CreateCustomField[];
  }
>;

export const initialState: ICreateApplicationFormState = {
  create: {
    parents: [],
    children: [],
    generalCustomFields: [],
  },
  update: {
    parents: [],
    children: [],
    generalCustomFields: [],
  },
};

// const isObjectOfCustomFields = (
//   obj: CreateChildForm | CreateParentForm | CreateCustomField,
// ): obj is CreateCustomField => {
//   if ((obj as CreateCustomField)?.custom_field_id) {
//     return true;
//   }

//   return false;
// };

// const getId = (entity: CreateParentForm | CreateChildForm | CreateCustomField) =>
//   isObjectOfCustomFields(entity) ? entity.custom_field_id : entity.id;

const slice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    add: (
      state,
      action: PayloadAction<{
        actionType: ApplicationAction;
        type: EntityType;
        entities: CreateParentForm[] | CreateChildForm[] | CreateCustomField[];
      }>,
    ) => {
      const { entities, type, actionType } = action.payload;
      const currentEntities = state[actionType][type];

      const data = [...currentEntities, ...entities].reduce<
        Record<string, CreateChildForm | CreateParentForm | CreateCustomField>
      >(
        (acc, next) => ({
          ...acc,
          [next.id]: next,
        }),
        {},
      );

      return { ...state, [actionType]: { ...state[actionType], [type]: Object.values(data) } };
    },
    update: (
      state,
      action: PayloadAction<{
        actionType: ApplicationAction;
        type: EntityType;
        entity: CreateParentForm | CreateChildForm | CreateCustomField;
      }>,
    ) => {
      const { entity, type, actionType } = action.payload;
      const currentEntities = state[actionType][type];

      const entities = currentEntities.map((e) => (e.id === entity.id ? entity : e), {});

      return { ...state, [actionType]: { ...state[actionType], [type]: entities } };
    },
    remove: (
      state,
      action: PayloadAction<{ actionType: ApplicationAction; type: EntityType; entityId: string }>,
    ) => {
      const { actionType, entityId, type } = action.payload;
      const currentEntities = state[actionType][type];
      const filteredElements = [...currentEntities].filter((entity) => entity.id !== entityId);

      return { ...state, [actionType]: { ...state[actionType], [type]: filteredElements } };
    },
    reset: (state, action: PayloadAction<{ actionType: ApplicationAction }>) => {
      const { actionType } = action.payload;
      return {
        ...state,
        [actionType]: initialState[actionType],
      };
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice;
