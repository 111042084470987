import { Box, Icon, IconButton, Stack } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { ArrowAngleIcon, DeleteIcon, DragIcon } from '@schooly/style';
import React, { FC } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FieldPath, FieldPathValue, useFormContext, Validate } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SchoolTuneAgeGroupsForm } from './SchoolTuneAgeGroupsModalContent';

interface SchoolTuneAgeGroupRowSmallProps {
  provided: DraggableProvided;
  levelAgeGroupsCount: number;
  shouldFocus: boolean;
  levelIndex: number;
  ageGroupIndex: number;
  validateAgeGroup: (
    id: string,
  ) => Validate<
    FieldPathValue<SchoolTuneAgeGroupsForm, FieldPath<SchoolTuneAgeGroupsForm>>,
    SchoolTuneAgeGroupsForm
  >;
  handleDeleteAgeGroup: (index: number) => void;
}

export const SchoolTuneAgeGroupRowSmall: FC<SchoolTuneAgeGroupRowSmallProps> = ({
  provided,
  levelAgeGroupsCount,
  shouldFocus,
  levelIndex,
  ageGroupIndex,
  validateAgeGroup,
  handleDeleteAgeGroup,
}) => {
  const { $t } = useIntl();
  const { control, watch } = useFormContext<SchoolTuneAgeGroupsForm>();

  const ageGroup = watch(`schoolLevels.${levelIndex}.ageGroups.${ageGroupIndex}`);

  return (
    <Stack
      direction="row"
      gap={1}
      width="100%"
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={provided.draggableProps.style}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          width: '100%',
          '.dragIconButton': {
            visibility: 'hidden',
          },
          ':hover, :active': {
            '.arrowIcon': {
              visibility: 'hidden',
            },
            '.dragIconButton': {
              visibility: 'visible',
            },
          },
        }}
      >
        <Box
          sx={(theme) => ({
            mt: theme.spacing(1),
            position: 'relative',
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
          })}
        >
          <IconButton
            className="dragIconButton"
            inverse
            sx={{ position: 'absolute' }}
            {...provided.dragHandleProps}
          >
            <DragIcon />
          </IconButton>
          <Icon
            className="arrowIcon"
            inverse
            sx={(theme) => ({
              color: theme.palette.common.grey,
              position: 'absolute',
            })}
          >
            <ArrowAngleIcon />
          </Icon>
        </Box>

        <ControlTextField
          canClear
          control={control}
          solid
          hideLabel
          rules={{
            required: true,
            validate: validateAgeGroup(ageGroup.id || ageGroup.id_tmp || ''),
          }}
          name={`schoolLevels.${levelIndex}.ageGroups.${ageGroupIndex}.name`}
          placeholder={$t({ id: 'school-tabs-AgeGroups-AgeGroupName' })}
          sx={(theme) => ({
            '&& .MuiInputBase-input': {
              padding: theme.spacing(1, 1.5),
            },
            '&&& .MuiInputAdornment-root': {
              mt: 0,
            },
            'input::placeholder': {
              color: 'text.secondary',
              opacity: 1,
            },
          })}
          fullWidth
          autoFocus={shouldFocus}
        />
      </Stack>
      <Box sx={{ visibility: levelAgeGroupsCount > 1 ? 'visible' : 'hidden' }} mt={1}>
        <IconButton inverse onClick={() => handleDeleteAgeGroup(ageGroupIndex)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};
