import { Box, Stack } from '@mui/material';
import { AssessmentMethodComment, AssessmentMethodType } from '@schooly/api';
import * as React from 'react';
import { FC } from 'react';

import { DeleteMethodButton } from './AssessmentsCreateModalSettings.styled';
import { AssessmentsCreateModalSettingsMethodType } from './AssessmentsCreateModalSettingsMethodType';

export interface AssessmentsCreateModalSettingsCommentProps {
  method: AssessmentMethodComment;
  autoFocus?: boolean;
  onMethodChange?: (methodType?: AssessmentMethodType) => void;
  onDelete?: () => void;
}

export const AssessmentsCreateModalSettingsComment: FC<AssessmentsCreateModalSettingsCommentProps> =
  ({ method, autoFocus, onMethodChange, onDelete }) => {
    return (
      <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
        <AssessmentsCreateModalSettingsMethodType
          methodType={method.method_type}
          autoFocus={autoFocus}
          onChange={onMethodChange}
        />
        <Box>
          <DeleteMethodButton onClick={onDelete} />
        </Box>
      </Stack>
    );
  };
