import { Stack, styled } from '@mui/material';

export const AddLogoLayout = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  position: 'absolute',
  visibility: 'hidden',
  pointerEvents: 'none',
  zIndex: 1,
  gap: theme.spacing(1),
}));

export const MainContentWrapper = styled(Stack)({
  flex: '1 1 100%',
  height: '100%',
  cursor: 'pointer',
  '&: hover': {
    opacity: 0.3,
  },
});
