import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { CrossIcon, Loading } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import AccessDenied from '../../../components/common/AccessDenied';
import { ModalLarge } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { useGroup } from '../../../context/groups/useGroup';
import { useGroupAttendanceStatistics } from '../../../context/groups/useGroupAttendanceStatistics';
import { useRouter } from '../../../context/router/useRouter';
import { ellipsis } from '../GroupPreviewModal/GroupPreviewModalInfo';
import { GroupAttendanceStatisticsModalExport } from './GroupAttendanceStatisticsModalExport';
import { GroupAttendanceStatisticsModalList } from './GroupAttendanceStatisticsModalList';
import { GroupAttendanceStatisticsModalTopPanel } from './GroupAttendanceStatisticsModalTopPanel';

export const GroupAttendanceStatisticsModal: FC = () => {
  const { group, fetching } = useGroup();
  const { schoolYearsFetching } = useGroupAttendanceStatistics();
  const { goBack: closeModal } = useRouter();
  const { permissions } = useAuth();

  const renderContent = () => {
    if (fetching || schoolYearsFetching) {
      return <Loading />;
    }

    if (!permissions.includes('attendance_viewer')) {
      return (
        <Box>
          <AccessDenied />
        </Box>
      );
    }

    return (
      <>
        <ModalHeader
          active
          withBorderBottom={false}
          sx={{ pb: 0 }}
          title={
            <Stack>
              <Typography
                color="text.secondary"
                sx={{ position: 'absolute', top: (theme) => theme.spacing() }}
              >
                <FormattedMessage id="groups-Attendance" />
              </Typography>

              <Typography variant="h1">{ellipsis(group?.name)}</Typography>
            </Stack>
          }
        >
          <GroupAttendanceStatisticsModalExport />
          <IconButton onClick={closeModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>

        <GroupAttendanceStatisticsModalTopPanel />
        <GroupAttendanceStatisticsModalList />
      </>
    );
  };

  return (
    <ModalLarge
      sx={{
        '.MuiPaper-root': {
          overflowY: 'visible',
        },
      }}
      open
      onClose={closeModal}
    >
      {renderContent()}
    </ModalLarge>
  );
};
