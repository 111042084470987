import { Stack } from '@mui/material';
import { SearchInput } from '@schooly/components/filters';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { WithDataGridHover } from '../../../../context/table/dataGridHover/WithDataGridHover';
import { getUserTypeTextId } from '../../../../helpers/misc';
import { useTakeAttendanceModal } from '../useTakeAttendanceModal';
import {
  COMMENTS_COL_FIELD_KEY,
  TakeAttendanceModalContentGrid,
} from './TakeAttendanceModalContentGrid';

export const TakeAttendanceModalContent: FC = () => {
  const { formatMessage } = useIntl();
  const { query, actions } = useTakeAttendanceModal();

  return (
    <Stack sx={{ height: '100%' }}>
      <SearchInput
        value={query}
        onChangeText={actions.setQuery}
        placeholder={formatMessage(
          { id: 'people-SearchAmongType' },
          {
            userTypePlural: formatMessage({
              id: getUserTypeTextId('student', true),
            }).toLowerCase(),
          },
        )}
      />

      <WithDataGridHover columnsWithXHover={[COMMENTS_COL_FIELD_KEY]}>
        <TakeAttendanceModalContentGrid />
      </WithDataGridHover>
    </Stack>
  );
};
