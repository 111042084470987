import { buildClassName } from '@schooly/utils/build-classname';
import React from 'react';

import { ReactComponent as AgeGroupSvg } from './icons/age_group.svg';
import { ReactComponent as AppstoreIconSvg } from './icons/appstore.svg';
import { ReactComponent as ArchiveIconSvg } from './icons/archive.svg';
import { ReactComponent as ArrowAngleIconSvg } from './icons/arrow_angle.svg';
import { ReactComponent as ArrowDownIconSvg } from './icons/arrow_down.svg';
import { ReactComponent as ArrowDownV2IconSvg } from './icons/arrow_down_v2.svg';
import { ReactComponent as DropdownIconSvg } from './icons/arrow_dropdown.svg';
import { ReactComponent as ArrowLeftIconSvg } from './icons/arrow_left.svg';
import { ReactComponent as ArrowRightIconSvg } from './icons/arrow_right.svg';
import { ReactComponent as ArrowSchoolSelectSvg } from './icons/arrow_school_select.svg';
import { ReactComponent as ArrowDownLongSvg } from './icons/arrow-down-long.svg';
import { ReactComponent as ArrowLinkIconSvg } from './icons/arrow-link';
import { ReactComponent as ArrowRightLongSvg } from './icons/arrow-right-long.svg';
import { ReactComponent as ArrowheadDownIconSvg } from './icons/arrowhead_down.svg';
import { ReactComponent as ArrowsIconSvg } from './icons/arrows.svg';
import { ReactComponent as ArrowsMenuIconSvg } from './icons/arrows_menu.svg';
import { ReactComponent as AssessmentsIconSvg } from './icons/assessments.svg';
import { ReactComponent as AttachmentIconSvg } from './icons/attachment.svg';
import { ReactComponent as AttendanceIconSvg } from './icons/attendance.svg';
import { ReactComponent as AttentionIconSvg } from './icons/attention.svg';
import { ReactComponent as Attention2IconSvg } from './icons/attention2.svg';
import { ReactComponent as BankIconSvg } from './icons/bank.svg';
import { ReactComponent as BehaviorIconSvg } from './icons/behavior.svg';
import { ReactComponent as BellIconSvg } from './icons/bell.svg';
import { ReactComponent as DateIconSvg } from './icons/birthday.svg';
import { ReactComponent as BoldIconSvg } from './icons/bold.svg';
import { ReactComponent as CalendarIconSvg } from './icons/calendar.svg';
import { ReactComponent as CalendarSettingsIconSvg } from './icons/calendar-settings.svg';
import { ReactComponent as CalendarTodayIconSvg } from './icons/calendar-today.svg';
import { ReactComponent as CameraIconSvg } from './icons/camera.svg';
import { ReactComponent as CancelIconSvg } from './icons/cancel_icon.svg';
import { ReactComponent as ChartIconSvg } from './icons/chart.svg';
import { ReactComponent as CheckIconSvg } from './icons/check.svg';
import { ReactComponent as CheckboxIconSvg } from './icons/checkbox.svg';
import { ReactComponent as CheckboxSquareIconSvg } from './icons/checkbox-square.svg';
import { ReactComponent as CheckboxSquareFilledIconSvg } from './icons/checkbox-square-filled.svg';
import { ReactComponent as ChevronDownSmallSvg } from './icons/chevron_down_small.svg';
import { ReactComponent as ChevronUpSmallSvg } from './icons/chevron_up_small.svg';
import { ReactComponent as ClipIconSvg } from './icons/clip.svg';
import { ReactComponent as CommentIconSvg } from './icons/comment.svg';
import { ReactComponent as CommentFilledIconSvg } from './icons/comment-filled.svg';
import { ReactComponent as CompanyIconSvg } from './icons/company.svg';
import { ReactComponent as ConductIconSvg } from './icons/conduct.svg';
import { ReactComponent as ConfigIconSvg } from './icons/config.svg';
import { ReactComponent as ConvertedIconSvg } from './icons/converted.svg';
import { ReactComponent as CookiesIconSvg } from './icons/cookies.svg';
import { ReactComponent as CopyIconSvg } from './icons/copy.svg';
import { ReactComponent as CrossIconSvg } from './icons/cross.svg';
import { ReactComponent as CrossCircleIconSvg } from './icons/cross_circle.svg';
import { ReactComponent as CrossSmallIconSvg } from './icons/cross_small.svg';
import { ReactComponent as CurriculumSvg } from './icons/curriculum.svg';
import { ReactComponent as CursorIconSvg } from './icons/cursor.svg';
import { ReactComponent as CursorCloseHandIconSvg } from './icons/cursor_close_hand';
import { ReactComponent as DeleteIconSvg } from './icons/delete.svg';
import { ReactComponent as DeleteFilledIconSvg } from './icons/delete-filled.svg';
import { ReactComponent as DocumentIconSvg } from './icons/document.svg';
import { ReactComponent as DoneIconSvg } from './icons/done.svg';
import { ReactComponent as DoubleCheckIconSvg } from './icons/double-check.svg';
import { ReactComponent as DownloadIconSvg } from './icons/download.svg';
import { ReactComponent as DragIconSvg } from './icons/drag.svg';
import { ReactComponent as DuplicateProfileSvg } from './icons/duplicate-profile.svg';
import { ReactComponent as DynamicVariablesSvg } from './icons/dynamic_variables.svg';
import { ReactComponent as EditIconSvg } from './icons/edit.svg';
import { ReactComponent as EventsIconSvg } from './icons/events.svg';
import { ReactComponent as EventsSmallSvg } from './icons/events_small.svg';
import { ReactComponent as ExpandIconSvg } from './icons/expand.svg';
import { ReactComponent as EyeIconSvg } from './icons/eye.svg';
import { ReactComponent as EyeCloseIconSvg } from './icons/eye-close.svg';
import { ReactComponent as FemaleIconSvg } from './icons/female.svg';
import { ReactComponent as FilterIconSvg } from './icons/filter.svg';
import { ReactComponent as FlagIconSvg } from './icons/flag.svg';
import { ReactComponent as FullDayIconSvg } from './icons/full-day.svg';
import { ReactComponent as GridViewIconSvg } from './icons/grid_view.svg';
import { ReactComponent as GroupsIconSvg } from './icons/group.svg';
import { ReactComponent as GroupsConfigurationIconSvg } from './icons/groups_configuration.svg';
import { ReactComponent as HalfDayIconSvg } from './icons/half-day.svg';
import { ReactComponent as HashIconSvg } from './icons/hash.svg';
import { ReactComponent as HolidayIconSvg } from './icons/holiday.svg';
import { ReactComponent as HomeIconSvg } from './icons/home.svg';
import { ReactComponent as ImageFormatSvg } from './icons/image_format.svg';
import { ReactComponent as ImageSizeSvg } from './icons/image_size.svg';
import { ReactComponent as InformationIconSvg } from './icons/information.svg';
import { ReactComponent as InvoiceIconSvg } from './icons/invoice.svg';
import { ReactComponent as ItalicIconSvg } from './icons/italic.svg';
import { ReactComponent as Language2IconSvg } from './icons/language 2.svg';
import { ReactComponent as LanguageIconSvg } from './icons/language.svg';
import { ReactComponent as Language3IconSvg } from './icons/language3.svg';
import { ReactComponent as LinkIconSvg } from './icons/link.svg';
import { ReactComponent as ListViewIconSvg } from './icons/list_view.svg';
import { ReactComponent as ListOlIconSvg } from './icons/list-ol.svg';
import { ReactComponent as ListUlIconSvg } from './icons/list-ul.svg';
import { ReactComponent as LoaderIconSvg } from './icons/loader.svg';
import { ReactComponent as LocationIconSvg } from './icons/location.svg';
import { ReactComponent as LockIconSvg } from './icons/lock.svg';
import { ReactComponent as LogIconSvg } from './icons/log.svg';
import { ReactComponent as LogoutSvg } from './icons/logout.svg';
import { ReactComponent as EmailIconSvg, ReactComponent as MailIconSvg } from './icons/mail.svg';
import { ReactComponent as MaleIconSvg } from './icons/Male.svg';
import { ReactComponent as MarkIconSvg } from './icons/mark.svg';
import { ReactComponent as MedicalIconSvg } from './icons/medical.svg';
import { ReactComponent as MigrationIconSvg } from './icons/migration.svg';
import { ReactComponent as MinusIconSvg } from './icons/minus.svg';
import { ReactComponent as MoreIconSvg } from './icons/more.svg';
import { ReactComponent as NewDocumentIconSvg } from './icons/new_document.svg';
import { ReactComponent as NewTabIconSvg } from './icons/new_tab.svg';
import { ReactComponent as NoteIconSvg } from './icons/note.svg';
import { ReactComponent as NotificationIconSvg } from './icons/notification.svg';
import { ReactComponent as OptionsIconSvg } from './icons/options.svg';
import { ReactComponent as ParentIconSvg } from './icons/parent.svg';
import { ReactComponent as PeopleIconSvg } from './icons/people.svg';
import { ReactComponent as PercentIconSvg } from './icons/percent.svg';
import { ReactComponent as PersonalMailIconSvg } from './icons/personal-mail.svg';
import { ReactComponent as PhoneIconSvg } from './icons/phone.svg';
import { ReactComponent as PlannerIconSvg } from './icons/planner.svg';
import { ReactComponent as PlaystoreIconSvg } from './icons/playstore.svg';
import { ReactComponent as PlusIconSvg } from './icons/plus.svg';
import { ReactComponent as PreferredNameSvg } from './icons/preferred_name.svg';
import { ReactComponent as PresentAbsentIconSvg } from './icons/present_absent.svg';
import { ReactComponent as ProfessionIconSvg } from './icons/profession.svg';
import { ReactComponent as ProfileIconSvg } from './icons/profile.svg';
import { ReactComponent as PublishIconSvg } from './icons/publish.svg';
import { ReactComponent as PuzzleIconSvg } from './icons/puzzle.svg';
import { ReactComponent as RadioOffIconSvg } from './icons/radio-off.svg';
import { ReactComponent as RadioOnIconSvg } from './icons/radio-on.svg';
import { ReactComponent as RadioOnIconNegativeSvg } from './icons/radio-on-negative.svg';
import { ReactComponent as RadioOnIconPositiveSvg } from './icons/radio-on-positive.svg';
import { ReactComponent as RecurringIconSvg } from './icons/recurring.svg';
import { ReactComponent as ReportGeneratedIconSvg } from './icons/report_generated.svg';
import { ReactComponent as ReportsIconSvg } from './icons/reports.svg';
import { ReactComponent as RocketIconSvg } from './icons/rocket.svg';
import { ReactComponent as RollBackIconSvg } from './icons/roll-back.svg';
import { ReactComponent as RollUpIconSvg } from './icons/roll-up.svg';
import { ReactComponent as RotateIconSvg } from './icons/rotate.svg';
import { ReactComponent as SchoolIconSvg } from './icons/school.svg';
import { ReactComponent as SearchIconSvg } from './icons/search.svg';
import { ReactComponent as SettingsIconSvg } from './icons/settings.svg';
import { ReactComponent as SidebarClosedIconSvg } from './icons/sidebar-closed.svg';
import { ReactComponent as SidebarOpenedIconSvg } from './icons/sidebar-opened.svg';
import { ReactComponent as SignUpIconSvg } from './icons/signup.svg';
import { ReactComponent as SmallListViewIconSvg } from './icons/small-list-view.svg';
import { ReactComponent as StaffIconSvg } from './icons/staff.svg';
import { ReactComponent as StarIconSvg } from './icons/star.svg';
import { ReactComponent as StatIconSvg } from './icons/stat.svg';
import { ReactComponent as StudentIconSvg } from './icons/student.svg';
import { ReactComponent as SubmitIconSvg } from './icons/submit.svg';
import { ReactComponent as SyncIconSvg } from './icons/sync.svg';
import { ReactComponent as TaxIconSvg } from './icons/tax.svg';
import { ReactComponent as TickIconSvg } from './icons/tick.svg';
import { ReactComponent as TimeIconSvg } from './icons/time.svg';
import { ReactComponent as TwoArrowsIconSvg } from './icons/two_arrows.svg';
import { ReactComponent as UnderlineIconSvg } from './icons/underline.svg';
import { ReactComponent as UnpublishIconSvg } from './icons/unpublish.svg';
import { ReactComponent as ChevronUpIconSvg } from './icons/up_arrow.svg';
import { ReactComponent as UpdateIconSvg } from './icons/update.svg';
import { ReactComponent as UserRoleIconSvg } from './icons/user_role.svg';
import { ReactComponent as VerifiedIconSvg } from './icons/verified.svg';
import { ReactComponent as WarningIconSvg } from './icons/warning.svg';
import { ReactComponent as WorldIconSvg } from './icons/world.svg';

function makeIconComponent(
  IconComponent: React.FC<React.ComponentProps<'svg'>>,
  displayName?: string,
  extraClassName?: string,
) {
  const Component: React.FC<React.ComponentProps<'svg'>> = ({ className, ...rest }) => (
    <IconComponent className={buildClassName('svg-icon', className, extraClassName)} {...rest} />
  );
  Component.displayName = displayName;
  return Component;
}

export { default as SchoolyLogoSvg } from './images/schooly-logo.svg';

export const ChevronUpIcon = makeIconComponent(ChevronUpIconSvg, 'ChevronUpIcon');
export const ReportGeneratedIcon = makeIconComponent(ReportGeneratedIconSvg, 'ReportGeneratedIcon');
export const CalendarIcon = makeIconComponent(CalendarIconSvg, 'CalendarIcon');
export const CalendarTodayIcon = makeIconComponent(CalendarTodayIconSvg, 'CalendarTodayIcon');
export const CalendarSettingsIcon = makeIconComponent(
  CalendarSettingsIconSvg,
  'CalendarSettingsIcon',
);
export const ConfigIcon = makeIconComponent(ConfigIconSvg, 'ConfigIcon');
export const CrossIcon = makeIconComponent(CrossIconSvg, 'CrossIcon');
export const CrossSmallIcon = makeIconComponent(CrossSmallIconSvg, 'CrossSmallIcon');
export const CloseIcon = CrossIcon;
export const CloseSmallIcon = CrossSmallIcon;
export const EditIcon = makeIconComponent(EditIconSvg, 'EditIcon');
export const MoreIcon = makeIconComponent(MoreIconSvg, 'MoreIcon');
export const GroupsIcon = makeIconComponent(GroupsIconSvg, 'GroupsIcon');
export const GroupsConfigurationIcon = makeIconComponent(
  GroupsConfigurationIconSvg,
  'GroupsConfigurationIcon',
);
export const HomeIcon = makeIconComponent(HomeIconSvg, 'HomeIcon');
export const OptionsIcon = makeIconComponent(OptionsIconSvg, 'OptionsIcon');
export const VerifiedIcon = makeIconComponent(VerifiedIconSvg, 'VerifiedIcon');
export const ParentIcon = makeIconComponent(ParentIconSvg, 'ParentIcon');
export const PeopleIcon = makeIconComponent(PeopleIconSvg, 'PeopleIcon');
export const PlusIcon = makeIconComponent(PlusIconSvg, 'PlusIcon');
export const StaffIcon = makeIconComponent(StaffIconSvg, 'StaffIcon');
export const StarIcon = makeIconComponent(StarIconSvg, 'StarIcon');
export const StudentIcon = makeIconComponent(StudentIconSvg, 'StudentIcon');
export const SyncIcon = makeIconComponent(SyncIconSvg, 'SyncIcon');
export const TickIcon = makeIconComponent(TickIconSvg, 'TickIcon');
export const WarningIcon = makeIconComponent(WarningIconSvg, 'WarningIcon');
export const LogoutIcon = makeIconComponent(LogoutSvg, 'LogoutIcon');
export const ChevronRightIcon = makeIconComponent(
  DropdownIconSvg,
  'DropdownIcon',
  'ChevronRightIcon',
);
export const ChevronLeftIcon = makeIconComponent(
  DropdownIconSvg,
  'DropdownIcon',
  'ChevronLeftIcon',
);
export const ArrowsIcon = makeIconComponent(ArrowsIconSvg, 'ArrowsIcon');
export const ArrowsMenuIcon = makeIconComponent(ArrowsMenuIconSvg, 'ArrowsMenuIcon');
export const ArrowSchoolSelectIcon = makeIconComponent(
  ArrowSchoolSelectSvg,
  'ArrowSchoolSelectIcon',
);
export const ArrowDownIcon = makeIconComponent(ArrowDownIconSvg, 'ArrowDownIcon');
export const PersonalMailIcon = makeIconComponent(PersonalMailIconSvg, 'PersonalMailIcon');
export const ArrowDownV2Icon = makeIconComponent(ArrowDownV2IconSvg, 'ArrowDownV2Icon');
export const ArrowUpIcon = makeIconComponent(ArrowDownIconSvg, 'ArrowUpIcon', 'ArrowUpIcon');
export const ArrowRightIcon = makeIconComponent(ArrowRightIconSvg, 'ArrowRightIcon');
export const ArrowLeftIcon = makeIconComponent(ArrowLeftIconSvg, 'ArrowLeftIcon');
export const ArrowheadDownIcon = makeIconComponent(ArrowheadDownIconSvg, 'ArrowheadDownIconSvg');
export const CheckIcon = makeIconComponent(CheckIconSvg, 'CheckIcon');
export const CheckboxIcon = makeIconComponent(CheckboxIconSvg, 'CheckboxIcon');
export const CheckboxSquareIcon = makeIconComponent(CheckboxSquareIconSvg, 'CheckboxSquareIcon');
export const CheckboxSquareFilledIcon = makeIconComponent(
  CheckboxSquareFilledIconSvg,
  'CheckboxSquareFilledIcon',
);
export const CommentIcon = makeIconComponent(CommentIconSvg, 'CommentIcon');
export const CommentFilledIcon = makeIconComponent(CommentFilledIconSvg, 'CommentFilledIcon');
export const CookiesIcon = makeIconComponent(CookiesIconSvg, 'CookiesIcon');
export const DoneIcon = makeIconComponent(DoneIconSvg, 'DoneIcon');
export const DeleteIcon = makeIconComponent(DeleteIconSvg, 'DeleteIcon');
export const DeleteFilledIcon = makeIconComponent(DeleteFilledIconSvg, 'DeleteFilledIcon');
export const DocumentIcon = makeIconComponent(DocumentIconSvg, 'DocumentIcon');
export const DropdownIcon = makeIconComponent(DropdownIconSvg, 'DropdownIcon');
export const FilterIcon = makeIconComponent(FilterIconSvg, 'FilterIcon');
export const GridViewIcon = makeIconComponent(GridViewIconSvg, 'GridViewIcon');
export const LockIcon = makeIconComponent(LockIconSvg, 'LockIcon');
export const ImageFormatIcon = makeIconComponent(ImageFormatSvg, 'ImageFormatIcon');
export const ImageSizeIcon = makeIconComponent(ImageSizeSvg, 'ImageSizeIcon');
export const ListViewIcon = makeIconComponent(ListViewIconSvg, 'ListViewIcon');
export const MailIcon = makeIconComponent(MailIconSvg, 'MailIcon');
export const MigrationIcon = makeIconComponent(MigrationIconSvg, 'MigrationIcon');
export const MinusIcon = makeIconComponent(MinusIconSvg, 'MinusIcon');
export const SearchIcon = makeIconComponent(SearchIconSvg, 'SearchIcon');
export const UserRoleIcon = makeIconComponent(UserRoleIconSvg, 'UserRoleIcon');
export const DateIcon = makeIconComponent(DateIconSvg, 'DateIcon');
export const FemaleIcon = makeIconComponent(FemaleIconSvg, 'FemaleIcon');
export const LanguageIcon = makeIconComponent(LanguageIconSvg, 'LanguageIcon');
export const Language2Icon = makeIconComponent(Language2IconSvg, 'Language2Icon');
export const Language3Icon = makeIconComponent(Language3IconSvg, 'language3Icon');
export const PhoneIcon = makeIconComponent(PhoneIconSvg, 'PhoneIcon');
export const EmailIcon = makeIconComponent(EmailIconSvg, 'EmailIcon');
export const FlagIcon = makeIconComponent(FlagIconSvg, 'FlagIcon');
export const MaleIcon = makeIconComponent(MaleIconSvg, 'MaleIcon');
export const CameraIcon = makeIconComponent(CameraIconSvg, 'CameraIcon');
export const MedicalIcon = makeIconComponent(MedicalIconSvg, 'MedicalIcon');
export const InformationIcon = makeIconComponent(InformationIconSvg, 'InformationIcon');
export const LocationIcon = makeIconComponent(LocationIconSvg, 'LocationIcon');
export const EyeIcon = makeIconComponent(EyeIconSvg, 'EyeIcon');
export const EyeCloseIcon = makeIconComponent(EyeCloseIconSvg, 'EyeCloseIcon');
export const AppstoreIcon = makeIconComponent(AppstoreIconSvg, 'AppstoreIcon');
export const PlaystoreIcon = makeIconComponent(PlaystoreIconSvg, 'PlaystoreIcon');
export const BellIcon = makeIconComponent(BellIconSvg, 'BellIcon');
export const ProfileIcon = makeIconComponent(ProfileIconSvg, 'ProfileIcon');
export const RadioOffIcon = makeIconComponent(RadioOffIconSvg, 'RadioOffIcon');
export const RadioOnIcon = makeIconComponent(RadioOnIconSvg, 'RadioOnIcon');
export const RollUpIcon = makeIconComponent(RollUpIconSvg, 'RollUpIcon');
export const ExpandIcon = makeIconComponent(ExpandIconSvg, 'ExpandIcon');
export const BoldIcon = makeIconComponent(BoldIconSvg, 'BoldIcon');
export const ItalicIcon = makeIconComponent(ItalicIconSvg, 'ItalicIcon');
export const UnderlineIcon = makeIconComponent(UnderlineIconSvg, 'UnderlineIcon');
export const ListOlIcon = makeIconComponent(ListOlIconSvg, 'ListOlIcon');
export const ListUlIcon = makeIconComponent(ListUlIconSvg, 'ListUlIcon');
export const LinkIcon = makeIconComponent(LinkIconSvg, 'LinkIcon');
export const AssessmentsIcon = makeIconComponent(AssessmentsIconSvg, 'AssessmentsIcon');
export const ReportsIcon = makeIconComponent(ReportsIconSvg, 'ReportsIcon');
export const RocketIcon = makeIconComponent(RocketIconSvg, 'RocketIcon');
export const AttachmentIcon = makeIconComponent(AttachmentIconSvg, 'AttachmentIcon');
export const ClipIcon = makeIconComponent(ClipIconSvg, 'ClipIcon');
export const DoubleCheckIcon = makeIconComponent(DoubleCheckIconSvg, 'DoubleCheckIcon');
export const DownloadIcon = makeIconComponent(DownloadIconSvg, 'DownloadIcon');
export const DragIcon = makeIconComponent(DragIconSvg, 'DragIcon');
export const AttentionIcon = makeIconComponent(AttentionIconSvg, 'AttentionIcon');
export const AttendanceIcon = makeIconComponent(AttendanceIconSvg, 'AttendanceIcon');
export const ConductIcon = makeIconComponent(ConductIconSvg, 'ConductIcon');
export const ChartIcon = makeIconComponent(ChartIconSvg, 'ChartIcon');
export const RollBackIcon = makeIconComponent(RollBackIconSvg, 'RollBackIcon');
export const CopyIcon = makeIconComponent(CopyIconSvg, 'CopyIcon');
export const SettingsIcon = makeIconComponent(SettingsIconSvg, 'SettingsIcon');
export const PercentIcon = makeIconComponent(PercentIconSvg, 'PercentIcon');
export const HashIcon = makeIconComponent(HashIconSvg, 'HashIcon');
export const RadioOnIconNegative = makeIconComponent(RadioOnIconNegativeSvg, 'RadioOnIconNegative');
export const RadioOnIconPositive = makeIconComponent(RadioOnIconPositiveSvg, 'RadioOnIconPositive');
export const NoteIcon = makeIconComponent(NoteIconSvg, 'NoteIcon');
export const CancelIcon = makeIconComponent(CancelIconSvg, 'CancelIcon');
export const BehaviorIcon = makeIconComponent(BehaviorIconSvg, 'BehaviorIcon');
export const PuzzleIcon = makeIconComponent(PuzzleIconSvg, 'PuzzleIcon');
export const NewTabIcon = makeIconComponent(NewTabIconSvg, 'NewTabIcon');
export const Attention2Icon = makeIconComponent(Attention2IconSvg, 'Attention2Icon');
export const ProfessionIcon = makeIconComponent(ProfessionIconSvg, 'ProfessionIcon');
export const NotificationIcon = makeIconComponent(NotificationIconSvg, 'NotificationIcon');
export const ArchiveIcon = makeIconComponent(ArchiveIconSvg, 'ArchiveIcon');
export const ChevronDownSmallIcon = makeIconComponent(ChevronDownSmallSvg, 'ChevronDownSmallIcon');
export const ChevronUpSmallIcon = makeIconComponent(ChevronUpSmallSvg, 'ChevronUpSmallIcon');
export const CrossCircleIcon = makeIconComponent(CrossCircleIconSvg, 'CrossCircleIcon');
export const SidebarOpenedIcon = makeIconComponent(SidebarOpenedIconSvg, 'SidebarOpenedIcon');
export const SidebarClosedIcon = makeIconComponent(SidebarClosedIconSvg, 'SidebarClosedIcon');
export const SignUpIcon = makeIconComponent(SignUpIconSvg, 'SignUpIcon');
export const HistoryLogIcon = makeIconComponent(LogIconSvg, 'HistoryLogIcon');
export const LoaderIcon = makeIconComponent(LoaderIconSvg, 'LoaderIcon');
export const UnpublishIcon = makeIconComponent(UnpublishIconSvg, 'UnpublishIcon');
export const PublishIcon = makeIconComponent(PublishIconSvg, 'PublishIcon');
export const SmallListViewIcon = makeIconComponent(SmallListViewIconSvg, 'SmallListViewIcon');
export const MarkIcon = makeIconComponent(MarkIconSvg, 'MarkIcon');
export const PreferredNameIcon = makeIconComponent(PreferredNameSvg, 'PreferredNameIcon');
export const UpdateIcon = makeIconComponent(UpdateIconSvg, 'UpdateIcon');
export const EventsIcon = makeIconComponent(EventsIconSvg, 'EventsIcon');
export const TimeIcon = makeIconComponent(TimeIconSvg, 'TimeIcon');
export const ArrowAngleIcon = makeIconComponent(ArrowAngleIconSvg, 'ArrowAngleIcon');
export const WorldIcon = makeIconComponent(WorldIconSvg, 'WorldIcon');
export const ArrowLinkIcon = makeIconComponent(ArrowLinkIconSvg, 'ArrowLinkIcon');
export const DuplicateProfileIcon = makeIconComponent(DuplicateProfileSvg, 'DuplicateProfileIcon');
export const HolidayIcon = makeIconComponent(HolidayIconSvg, 'HolidayIcon');
export const PlannerIcon = makeIconComponent(PlannerIconSvg, 'PlannerIcon');
export const TwoArrowsIcon = makeIconComponent(TwoArrowsIconSvg, 'TwoArrowsIcon');
export const RecurringIcon = makeIconComponent(RecurringIconSvg, 'RecurringIcon');
export const RotateIcon = makeIconComponent(RotateIconSvg, 'RotateIcon');
export const ArrowRightLongIcon = makeIconComponent(ArrowRightLongSvg, 'ArrowRightLongIcon');
export const ArrowDownLongIcon = makeIconComponent(ArrowDownLongSvg, 'ArrowDownLongIcon');
export { ApplicationLargeSvg } from './images/application-large.svg';
export { ArrowSelectSvg } from './images/arrow-select.svg';
export const CurriculumIcon = makeIconComponent(CurriculumSvg, 'CurriculumIcon');
export const AgeGroupIcon = makeIconComponent(AgeGroupSvg, 'AgeGroupIcon');
export const SchoolIcon = makeIconComponent(SchoolIconSvg, 'SchoolIcon');
export const InvoiceIcon = makeIconComponent(InvoiceIconSvg, 'InvoiceIcon');
export const FullDayIcon = makeIconComponent(FullDayIconSvg, 'FullDayIcon');
export const HalfDayIcon = makeIconComponent(HalfDayIconSvg, 'HalfDayIcon');
export const CompanyIcon = makeIconComponent(CompanyIconSvg, 'CompanyIcon');
export const BankIcon = makeIconComponent(BankIconSvg, 'BankIcon');
export const TaxIcon = makeIconComponent(TaxIconSvg, 'TaxIcon');
export const SubmitIcon = makeIconComponent(SubmitIconSvg, 'SubmitIcon');
export const DynamicVariableIcon = makeIconComponent(DynamicVariablesSvg, 'DynamicVariableIcon');
export const EventsSmallIcon = makeIconComponent(EventsSmallSvg, 'EventsSmallIcon');
export const CursorIcon = makeIconComponent(CursorIconSvg, 'CursorIcon');
export const CursorCloseHandIcon = makeIconComponent(CursorCloseHandIconSvg, 'CursorCloseHandIcon');

export { BasketSvg } from './images/BasketSvg.svg';
export { BigArrowSvg } from './images/BigArrow.svg';
export { EmptyFolderSvg } from './images/EmptyFolder.svg';
export { EmptyListSvg } from './images/EmptyList.svg';
export { EmptySchoolSvg } from './images/EmptySchool.svg';
export { ErrorSvg } from './images/Error.svg';
export { IncompleteSvg } from './images/Incomplete.svg';
export { MailBoxSvg } from './images/mail-box.svg';
export { RichLinkInner } from './images/RichLinkInner.svg';
export { SuccessLargeSvg } from './images/success-large.svg';
export const ConvertedIcon = makeIconComponent(ConvertedIconSvg, 'ConvertedIcon');
export const PresentAbsentIcon = makeIconComponent(PresentAbsentIconSvg, 'PresentAbsentIcon');
export const StatIcon = makeIconComponent(StatIconSvg, 'StatIcon');
export const NewDocumentIcon = makeIconComponent(NewDocumentIconSvg, 'NewDocumentIcon');
