import { GET_SCHOOL_PROPERTIES_QUERY, useUpdateAgeGroupsMutation } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { Loading, ModalSmall } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSchool } from '../../../../../hooks/useSchool';
import { queryClient } from '../../../../../queryClient';
import { SchoolSettingsLayout } from '../../../SchoolSettingsLayout';
import {
  AgeGroupCreate,
  SchoolTuneAgeGroupsForm,
  SchoolTuneAgeGroupsModalContent,
} from './SchoolTuneAgeGroupsModalContent';

export const SchoolTuneAgeGroupsModal: FC = () => {
  const navigate = useNavigate();
  const { showError } = useNotifications();
  const { schoolId, currentSchool } = useSchool();
  const { ageGroups, schoolLevels, isLoading } = useAgeGroups({
    schoolId: schoolId!,
    userType: SchoolUserRole.Student,
  });

  const updateAgeGroups = useUpdateAgeGroupsMutation();
  const { isLoading: saving } = updateAgeGroups;

  const handleClose = useCallback(() => {
    if (saving) {
      return;
    }

    navigate({ pathname: '/settings/tune', search: '?tab=age_groups' });
  }, [navigate, saving]);

  const handleSubmit = useCallback(
    async (data: SchoolTuneAgeGroupsForm) => {
      if (!schoolId) {
        return;
      }

      //levels without age groups should not be saved
      const schoolLevels = data.schoolLevels?.filter((level) => level.ageGroups.length) ?? [];

      const ageGroupsWithLevel: AgeGroupCreate[] = [];
      schoolLevels.forEach((level) => {
        if (level.ageGroups) {
          ageGroupsWithLevel.push(...level.ageGroups);
        }
      });
      const ageGroups = [...ageGroupsWithLevel, ...data.ageGroupsWithNoLevel];

      updateAgeGroups.mutate(
        {
          schoolId,
          ageGroups: ageGroups.map((ageGroup, index) => ({
            id: ageGroup.id || undefined,
            name: ageGroup.name ?? '',
            order: index + 1,
            level_id: ageGroup.level_id || undefined,
            archived: false, //BE does not accept undefined
          })),
          schoolLevels: schoolLevels.map((level, index) => ({
            id: level.id || undefined,
            id_tmp: level.id_tmp || undefined,
            name: level.name ?? '',
            order: index + 1,
          })),
          canDelete: true,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([GET_SCHOOL_PROPERTIES_QUERY]);
            navigate({ pathname: '/settings/tune', search: '?tab=age_groups' });
          },
          onError: showError,
        },
      );
    },
    [navigate, schoolId, showError, updateAgeGroups],
  );

  return (
    <SchoolSettingsLayout>
      {isLoading ? (
        <ModalSmall open onClose={handleClose}>
          <Loading />
        </ModalSmall>
      ) : (
        <SchoolTuneAgeGroupsModalContent
          isSaving={saving}
          ageGroups={ageGroups}
          schoolLevels={schoolLevels}
          title={currentSchool?.name || ''}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </SchoolSettingsLayout>
  );
};
