import { Card, Icon, IconButton, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { ProductSave, ProductTriggerType } from '@schooly/api';
import { PropertyTypeSelect, SelectContentSkeleton } from '@schooly/components/filters';
import { FormTextField } from '@schooly/components/form-text-field';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import {
  ChartIcon,
  CursorIcon,
  DropdownYears,
  InformationIcon,
  PlusIcon,
  SearchIcon,
  SimpleButton,
} from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

const SINGLE_INVOICE_TRIGGER_STEPS_TEXT_IDS = [...new Array(3)].map(
  (_, i) => `products-CreateASingleInvoice-Step-${i + 1}`,
);
const SINGLE_INVOICE_TRIGGER_EXAMPLE_YEAR = { name: '2023/24', id: '2023/24' };

type ProductTriggerSectionProps = {
  schoolId: string;
  canEdit: boolean;
};

export const ProductTriggerSection: FC<ProductTriggerSectionProps> = ({ schoolId, canEdit }) => {
  const { formatMessage } = useIntl();
  const { control, watch } = useFormContext<ProductSave>();

  const triggers = watch('triggers');

  //only show single invoice trigger if there is no other trigger
  const triggerIndex = triggers.reduce(
    (acc, t, i) => (acc >= 0 && t.trigger_type === ProductTriggerType.SingleInvoice ? acc : i),
    -1,
  );

  const trigger = watch(`triggers.${triggerIndex}`);

  const renderContent = () => {
    switch (trigger.trigger_type) {
      case ProductTriggerType.RegistrationUpdate:
        return (
          <Stack flexDirection="row" gap={1}>
            <Stack flex={1}>
              <FormTextField
                customIcon={
                  <Tooltip title={formatMessage({ id: 'products-AtTheMoment' })}>
                    <IconButton>
                      <InformationIcon />
                    </IconButton>
                  </Tooltip>
                }
                disabled
                label={formatMessage({ id: 'products-Trigger' })}
                value={formatMessage({ id: 'products-Trigger-Registration' })}
              />
            </Stack>
            <Stack flex={1}>
              <Controller
                name={`triggers.${triggerIndex}.extra_data.status`}
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <PropertyTypeSelect
                    disabled={!canEdit}
                    schoolId={schoolId}
                    userRole={SchoolUserRole.Student}
                    propertyType={SchoolPropertyType.Status}
                    value={field.value}
                    hasError={!!fieldState.error}
                    errorMessage={getControllerErrorText(
                      fieldState.error,
                      undefined,
                      formatMessage,
                    )}
                    onChange={field.onChange}
                    label={formatMessage({ id: 'schoolProperty-Status' })}
                    renderEmptyStub={() => <SelectContentSkeleton />}
                  />
                )}
              />
            </Stack>
          </Stack>
        );
      case ProductTriggerType.SingleInvoice:
        return (
          <Card
            sx={(theme) => ({
              padding: theme.spacing(1.5),
              borderColor: theme.palette.common.light3,
            })}
          >
            <Typography variant="h3">
              {formatMessage({ id: 'products-Trigger-SingleInvoice' })}
            </Typography>

            <List sx={(theme) => ({ listStyleType: 'decimal', padding: theme.spacing(1, 2, 1.5) })}>
              {SINGLE_INVOICE_TRIGGER_STEPS_TEXT_IDS.map((labelTextId) => (
                <ListItem sx={{ display: 'list-item', padding: 0 }}>
                  <Typography>{formatMessage({ id: labelTextId })}</Typography>
                </ListItem>
              ))}
            </List>

            <Stack
              sx={(theme) => ({
                bgcolor: 'background.default',
                borderRadius: theme.spacing(1),
                padding: theme.spacing(2.5),
              })}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2">{formatMessage({ id: 'profile-PayableFees' })}</Typography>
                <Stack direction="row" gap={2.5} alignItems="center">
                  <Icon>
                    <SearchIcon />
                  </Icon>
                  <DropdownYears
                    currentYear={SINGLE_INVOICE_TRIGGER_EXAMPLE_YEAR}
                    onYearChange={() => {}}
                    years={[]}
                    disabled
                  />
                  <Icon>
                    <ChartIcon />
                  </Icon>
                  <SimpleButton
                    startIcon={<PlusIcon />}
                    disabled
                    sx={{ '&.Mui-disabled': { bgcolor: 'transparent', color: 'primary.main' } }}
                  >
                    {formatMessage({ id: 'profile-AddSingleInvoice' })}
                    <Icon sx={{ position: 'absolute', left: '65%', top: '50%' }}>
                      <CursorIcon />
                    </Icon>
                  </SimpleButton>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        );
    }
  };

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2">{formatMessage({ id: 'products-WhatWillTrigger' })}</Typography>
      </Stack>

      {renderContent()}
    </Stack>
  );
};
