export type FieldArrayItem<T = any> = { value: T };

/**
 * `react-hook-form` fieldArray can only use objects as fields.
 * `value` is default property name used by `FieldArray` component.
 */
export function convertFlatArrayToFieldArray<T>(arr?: T[] | null) {
  if (!arr) {
    return [] as FieldArrayItem<T>[];
  }

  return arr.map((value) => ({ value })) as FieldArrayItem<T>[];
}

/**
 * This transforms default `react-hook-form` fieldArray values from `FieldArray`
 * back to a flat array of values
 */
export function convertFieldArrayToFlatArray<T>(arr?: FieldArrayItem<T>[] | null) {
  if (!arr) {
    return [] as Array<T>;
  }

  return arr.map((obj) => obj.value).filter((v) => v !== undefined) as Array<T>;
}
