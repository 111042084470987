import { PagedResponse } from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading } from '@schooly/style';
import { useCallback } from 'react';

export type ShowMoreResourceFunction<T> = (
  pageSize?: number,
  pageNumber?: number,
  query?: string,
) => Promise<PagedResponse<T> | undefined> | undefined;

type GenericResourceItem = Record<string, any> & {
  id: string;
};

export function useShowMore<T extends GenericResourceItem>({
  getResource,
  fetching,
  canShowMore,
  current_page,
  total_pages,
}: {
  getResource: ShowMoreResourceFunction<T>;
  fetching?: boolean;
  canShowMore?: boolean;
  current_page?: number;
  total_pages?: number;
}) {
  const onShowMore = useCallback(() => {
    const pageNumber =
      canShowMore && current_page! < total_pages! ? current_page! + 1 : current_page;

    getResource(undefined, pageNumber);
  }, [canShowMore, current_page, getResource, total_pages]);

  const loaderRef = useInfiniteScroll(fetching, onShowMore);

  const loader = (
    <>
      {canShowMore ? (
        <div className="py-3">
          {fetching && <Loading />}
          <div ref={loaderRef} />
        </div>
      ) : null}
    </>
  );

  return { showMoreLoader: loader };
}
