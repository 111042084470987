import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { ConsentFormPreview } from '../../../components/common/ConsentForms/ConsentFormPreview';
import { useMessage } from '../../../context/messages/useMessage';

export const MessagesModalPreviewConsentForm: FC = () => {
  const { linkedConsentForm } = useMessage();
  const { formatMessage } = useIntl();

  if (!linkedConsentForm) return null;
  return (
    <Box mb={2.5}>
      <ConsentFormPreview
        consentFormShort={linkedConsentForm}
        label={formatMessage({ id: 'consentForms-title' })}
      />
    </Box>
  );
};
