import { FC } from 'react';

import { ModalPanelProps, ModalTitle, ModalTitleMultiLine } from './Modal.styled';
import { ModalHeaderActions, ModalHeaderStyled } from './ModalHeader.styled';

export interface ModalHeaderProps extends Omit<ModalPanelProps, 'title'> {
  title?: React.ReactNode;
  multiline?: boolean;
  center?: boolean;
}

export const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  center,
  children,
  multiline,
  ...props
}) => {
  const TitleComponent = multiline ? ModalTitleMultiLine : ModalTitle;

  return (
    <ModalHeaderStyled {...props}>
      <TitleComponent
        title={typeof title === 'string' ? title : undefined}
        textAlign={center ? 'center' : undefined}
      >
        {title}
      </TitleComponent>
      {children && <ModalHeaderActions>{children}</ModalHeaderActions>}
    </ModalHeaderStyled>
  );
};
