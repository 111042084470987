import { IconButton, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { NewTabIcon } from '../assets/assets';

type PreviewModalCardProps = {
  title: string;
  path: string;
};

export const PreviewModalCard: FC<PropsWithChildren<PreviewModalCardProps>> = ({
  title,
  path,
  children,
}) => {
  return (
    <Stack
      sx={{
        p: 2,
        bgcolor: 'background.default',
        borderRadius: 0.5,
        overflow: 'hidden',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" mb={0.5} gap={0.5}>
          <Typography variant="h2" flex="0 0 auto">
            {title}
          </Typography>
        </Stack>
        <Link to={path} target="_blank">
          <IconButton size="small" inverse>
            <NewTabIcon />
          </IconButton>
        </Link>
      </Stack>
      {children}
    </Stack>
  );
};
