import { Stack } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import { MIN_CONSISTENT_YEAR } from '@schooly/utils/date';
import { FC } from 'react';

import { DateFnsLocalizationProvider } from './DateFnsLocalizationProvider';

export const DateSelectInput: FC<{ value?: Date | null; onSetDate: (v: Date) => void }> = ({
  value,
  onSetDate,
}) => {
  return (
    <DateFnsLocalizationProvider>
      <Stack
        sx={{
          '.MuiTextField-root': {
            margin: 0,

            '.MuiInputBase-root, .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important',
              outline: '0px !important',
              background: 'none !important',
              margin: '0px !important',
              padding: `0px !important`,
              lineHeight: 'unset',
            },
            '.MuiOutlinedInput-input, .MuiOutlinedInput-root, .MuiTextField-root': {
              padding: '0px !important',
              margin: '0px !important',
              border: '0px !important',
              height: 'unset',
            },
          },
        }}
      >
        <DateField
          margin="dense"
          value={value || undefined}
          onChange={(v) => {
            v &&
              !isNaN(v as unknown as number) &&
              v.getFullYear() >= MIN_CONSISTENT_YEAR &&
              onSetDate(v);
          }}
          shouldRespectLeadingZeros
          autoFocus
          format="dd/MM/yyyy"
        />
      </Stack>
    </DateFnsLocalizationProvider>
  );
};
