import { Typography } from '@mui/material';
import { HashIcon, PercentIcon } from '@schooly/style';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PercentIconButton } from '../pages/Attendance/AttendanceStatistics/AttendanceStats.styled';

export const useAttendanceStatistics = (precisionForPercent = 1, customShowPercents?: boolean) => {
  const [showPercents, setShowPercents] = useState(customShowPercents ?? false);

  useEffect(() => {
    setShowPercents(customShowPercents ?? false);
  }, [customShowPercents]);

  const getTotalCount = useCallback(
    (totalCount?: number) => {
      if (showPercents) {
        return totalCount ? '100%' : '0%';
      }
      return totalCount ?? 0;
    },
    [showPercents],
  );

  const renderCount = useCallback(
    (count?: number, totalCount?: number) => {
      const empty = <Typography color="text.secondary">-</Typography>;
      if (!count) {
        return empty;
      }

      if (!showPercents) {
        return <Typography color="text.primary">{count}</Typography>;
      }

      if (totalCount) {
        return (
          <Typography color="text.primary">
            {`${_.round((count / totalCount) * 100, precisionForPercent)}%`}
          </Typography>
        );
      }
      return empty;
    },
    [showPercents, precisionForPercent],
  );

  const percentIconButton = useMemo(
    () => (
      <PercentIconButton onClick={() => setShowPercents((val) => !val)}>
        {showPercents ? <HashIcon /> : <PercentIcon />}
      </PercentIconButton>
    ),
    [showPercents],
  );

  return {
    setShowPercents,
    showPercents,
    renderCount,
    getTotalCount,
    percentIconButton,
  };
};
