import { RecurringState } from '@schooly/api';
import { DAY_OF_WEEK_OPTIONS } from '@schooly/components/form-text-field';
import { DATE_FORMAT } from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, getWeekOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { RecurringEndsSection } from './RecurringEndDate';
import { RecurringForm } from './RecurringModal';
import {
  getRecurringDaysString,
  isDayOfMothOccurrence,
  isMonthWeekOccurrence,
  isWeekOccurrence,
} from './utils';

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export function toOrdinal(n: number) {
  const enOrdinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' });

  const rule = enOrdinalRules.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
}

type UseRecurringFormLabelProps = {
  startDate: Date;
  shortDays?: boolean;
  title?: string;
} & RecurringForm;

export const useRecurringFormLabel = ({
  startDate,
  repeat_count: repeatCount,
  repeat_on_day_of_week: weekDays,
  shortDays,
  ends,
}: UseRecurringFormLabelProps) => {
  const { $t } = useIntl();
  const month = getWeekOfMonth(startDate);
  const repeatCountStr = repeatCount > 1 ? toOrdinal(repeatCount) : '';
  const monthStr = toOrdinal(month);
  const dayOfMonth = toOrdinal(startDate.getDate());
  const endsSection = ends?.section;
  const endsByDate = ends?.byDate;
  const endsByOccurrence = ends?.byOccurrence;

  const monthRepeatPeriod =
    repeatCount > 1 ? $t({ id: 'recurring-repeatEvery' }) : $t({ id: 'recurring-Monthly' });
  const weekRepeatPeriod =
    repeatCount > 1 ? $t({ id: 'recurring-repeatEvery' }) : $t({ id: 'recurring-Weekly' });

  const { recurringDays, recurringDay } = useMemo(() => {
    const dayOfMonthWeek = $t({ id: `week-${format(startDate, 'EEEE')}` });

    const daysStr = getRecurringDaysString(weekDays, (str) => $t({ id: str }), shortDays);

    return {
      recurringDays: daysStr,
      recurringDay: shortDays ? dayOfMonthWeek.slice(0, 3) : dayOfMonthWeek,
    };
  }, [$t, shortDays, startDate, weekDays]);

  const endsLabel = useMemo(() => {
    if (endsSection === RecurringEndsSection.ByDate && endsByDate) {
      return $t(
        {
          id: 'recurring-untilDate',
        },
        { date: format(newDateTimezoneOffset(endsByDate), DATE_FORMAT) },
      );
    }

    if (endsSection === RecurringEndsSection.ByOccurrence && endsByOccurrence) {
      return $t(
        {
          id: 'recurring-untilTimes',
        },
        { count: endsByOccurrence },
      );
    }

    return '';
  }, [$t, endsByDate, endsByOccurrence, endsSection]);

  const dayOfMonthLabel = $t({ id: 'recurring-byDayOfMonthTitle' }, { dayOfMonth });

  const repeatEveryLabel = $t(
    { id: 'recurring-byDayOfMonthOfEvery' },
    { repeatCount: repeatCountStr },
  );

  const dayOfWeekLabel = $t(
    { id: 'recurring-byDaysOfWeekTitle' },
    {
      daysOfWeek: recurringDay,
      repeatCount: monthStr,
    },
  );
  const daysOfWeekLabel = $t(
    { id: 'recurring-byDaysOfWeekTitle' },
    {
      daysOfWeek: recurringDays,
      repeatCount: repeatCountStr,
    },
  );

  const repeatByDayLabel = repeatCount > 1 ? ` ${repeatEveryLabel}` : '';

  return {
    dayOfWeekMenuLabel: `${dayOfWeekLabel} ${repeatEveryLabel}`,
    dayOfMonthMenuLabel: `${$t({
      id: 'recurring-StartOn',
    })} ${dayOfMonthLabel} ${repeatEveryLabel}`,
    daysOfWeekLabel: `${weekRepeatPeriod} ${
      repeatCount > 1 ? daysOfWeekLabel : recurringDays
    }${endsLabel}`,
    dayOfWeekLabel: `${monthRepeatPeriod} ${dayOfWeekLabel}${repeatByDayLabel}${endsLabel}`,
    dayOfMonthLabel: `${monthRepeatPeriod} ${dayOfMonthLabel}${repeatByDayLabel}${endsLabel}`,
  };
};

type UseRecurringStateLabelProps = {
  shortDays?: boolean;
} & RecurringState;

export const useRecurringStateLabel = ({
  ends_after,
  ends_on,
  repeat_every,
  shortDays,
}: UseRecurringStateLabelProps) => {
  const { repeat_count, period, occurrence } = repeat_every;
  const { $t } = useIntl();
  const repeatCountStr = repeat_count > 1 ? toOrdinal(repeat_count) : '';
  const endsByDate = ends_on;
  const endsByOccurrence = ends_after;

  const label = useMemo(() => {
    if (!occurrence) return '';
    const isByWeekOccurrence = isWeekOccurrence(occurrence);
    const currentPeriod =
      period === 'week' ? $t({ id: 'recurring-Weekly' }) : $t({ id: 'recurring-Monthly' });

    const repeatPeriod = repeat_count > 1 ? $t({ id: 'recurring-repeatEvery' }) : currentPeriod;

    if (period === 'week' && isByWeekOccurrence) {
      const daysStr = getRecurringDaysString(
        occurrence.day_of_week,
        (str) => $t({ id: str }),
        shortDays,
      );

      const daysLabel =
        repeat_count > 1
          ? $t(
              { id: 'recurring-byDaysOfWeekTitle' },
              {
                daysOfWeek: daysStr,
                repeatCount: repeatCountStr,
              },
            )
          : daysStr;

      return `${repeatPeriod} ${daysLabel}`;
    }

    const isByDayOfMoth = isDayOfMothOccurrence(occurrence);

    const repeatEveryLabel =
      repeat_count > 1
        ? ` ${$t({ id: 'recurring-byDayOfMonthOfEvery' }, { repeatCount: repeatCountStr })}`
        : '';

    if (isByDayOfMoth) {
      const dayOfMonthStr = toOrdinal(occurrence.day_of_month);

      const dayLabel = $t({ id: 'recurring-byDayOfMonthTitle' }, { dayOfMonth: dayOfMonthStr });

      return `${repeatPeriod} ${dayLabel}${repeatEveryLabel}`;
    }

    const isByWeekOfMoth = isMonthWeekOccurrence(occurrence);
    const weekDay = occurrence.day_of_week[0];

    if (isByWeekOfMoth && Number.isInteger(weekDay)) {
      const dayOfMonthWeekStr = DAY_OF_WEEK_OPTIONS.find(
        ({ value }) => weekDay === value,
      )?.labelTextId;

      if (!dayOfMonthWeekStr) return '';

      const recurringDay = shortDays
        ? $t({ id: dayOfMonthWeekStr }).slice(0, 3)
        : $t({ id: dayOfMonthWeekStr });
      const monthStr = toOrdinal(occurrence.week_of_month);

      const label = $t(
        { id: 'recurring-byDaysOfWeekTitle' },
        {
          daysOfWeek: recurringDay,
          repeatCount: monthStr,
        },
      );

      return `${repeatPeriod} ${label}${repeatEveryLabel}`;
    }

    return '';
  }, [$t, occurrence, period, repeatCountStr, repeat_count, shortDays]);

  const endsLabel = useMemo(() => {
    if (endsByDate) {
      return $t(
        {
          id: 'recurring-untilDate',
        },
        { date: format(newDateTimezoneOffset(endsByDate), DATE_FORMAT) },
      );
    }

    if (endsByOccurrence) {
      return $t(
        {
          id: 'recurring-untilTimes',
        },
        { count: endsByOccurrence },
      );
    }

    return '';
  }, [$t, endsByDate, endsByOccurrence]);

  return `${label}${endsLabel}`;
};
