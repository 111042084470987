import { Button, Stack, Typography } from '@mui/material';
import { SchoolUserType } from '@schooly/api';
import { Counter, PlusIcon } from '@schooly/style';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getRouteAddModalPathname } from '../../../helpers/misc';

export interface NoPeopleStubProps {
  titleText: React.ReactNode | string;
  subTitleText?: React.ReactNode | string;
  buttonText?: React.ReactNode | string;
  buttonCounter?: number;
  type?: SchoolUserType | 'group' | 'assessment' | 'report' | 'attendance' | 'conduct' | 'event';
  onButtonClick?: VoidFunction;
  buttonIcon?: React.ReactNode;
}

export const NoListItemsStub = ({
  titleText,
  subTitleText,
  buttonText,
  buttonCounter,
  type,
  onButtonClick,
  buttonIcon,
}: NoPeopleStubProps) => {
  const location = useLocation();

  const button = buttonText ? (
    <Button
      startIcon={buttonIcon ?? <PlusIcon />}
      endIcon={buttonCounter != null ? <Counter button>{buttonCounter}</Counter> : undefined}
      sx={{ mt: 3 }}
      onClick={onButtonClick}
    >
      {buttonText}
    </Button>
  ) : null;

  return (
    <Stack justifyContent="center" alignItems="center" flex={1} p={5}>
      <Typography variant="h1" textAlign="center" maxWidth={240}>
        {titleText}
      </Typography>
      {subTitleText && (
        <Typography color="text.primary" textAlign="center" mt={1}>
          {subTitleText}
        </Typography>
      )}
      {onButtonClick
        ? button
        : !!type && (
            <Link
              to={{ pathname: getRouteAddModalPathname(type) }}
              state={{ backgroundLocation: location }}
            >
              {button}
            </Link>
          )}
    </Stack>
  );
};
