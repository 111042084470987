import { Button, FormControlLabel, IconButton, Switch } from '@mui/material';
import { GET_SCHOOL_PROPERTIES_QUERY, useGetCategoriesQuery } from '@schooly/api';
import { updateStatuses } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, CrossIcon, Loading, Spin } from '@schooly/style';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ModalFooter,
  ModalLarge,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { useRouter } from '../../../../context/router/useRouter';
import { useSchool } from '../../../../hooks/useSchool';
import { queryClient } from '../../../../queryClient';
import { SchoolSettingsLayout } from '../../SchoolSettingsLayout';
import { prepareStatusesToSave } from '../../SchoolTune/tabs/statuses/hooks/useSchoolTuneStatuses';
import {
  SchoolTuneStatusesForm,
  SchoolTuneStatusType,
} from '../../SchoolTune/tabs/statuses/scheme';
import { SchoolTuneStatusesModalStaffPanel } from '../../SchoolTune/tabs/statuses/SchoolTuneStatusesModalStaffPanel';
import { SchoolTuneStatusesModalStudentsPanel } from '../../SchoolTune/tabs/statuses/SchoolTuneStatusesModalStudentsPanel';

export const SchoolGeneralStatusesModal: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { goBack } = useRouter();

  const { schoolId, currentSchool, studentStatuses, staffStatuses } = useSchool();
  const { getConfirmation } = useConfirmationDialog();
  const { data } = useGetCategoriesQuery();
  const [saving, setSaving] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const autoSwitchedArchivedOn = useRef(false);

  const categories = data?.categories;

  const form = useForm<SchoolTuneStatusesForm>({
    defaultValues: {
      students: studentStatuses,
      staff: staffStatuses,
    },
  });

  const students = form.watch('students');
  const staff = form.watch('staff');

  const hasArchived = useMemo(() => {
    if (form.formState) {
      // A reaction of useMemo to form state change to get actual form value
    }

    return Boolean(
      (Object.keys(students) as (keyof SchoolTuneStatusType)[]).some((category) =>
        students[category]?.some((field) => field.archived),
      ) ||
        (Object.keys(staff) as (keyof SchoolTuneStatusType)[]).some((category) =>
          students[category]?.some((field) => field.archived),
        ),
    );
  }, [form.formState, staff, students]);

  const handleClose = useCallback(async () => {
    if (saving) {
      return;
    }

    if (
      form.formState.isDirty &&
      !(await getConfirmation({ textId: 'school-edit-CloseUnsavedConfirmation' }))
    ) {
      return;
    }

    goBack();
  }, [goBack, saving, getConfirmation, form.formState.isDirty]);

  const handleSubmit = useCallback<SubmitHandler<SchoolTuneStatusesForm>>(
    async (data) => {
      if (!schoolId) {
        return;
      }

      setSaving(true);

      const statuses = prepareStatusesToSave(
        categories,
        data,
        (categoryId, index) => (status, i) => ({
          property_id: status.id || undefined,
          replace_to_id: status.replaceId || undefined,
          name: status.name ?? '',
          category_id: categoryId,
          order: index + i,
          archive: status.archived,
        }),
      );

      try {
        await updateStatuses(schoolId, statuses);

        // (TR-4607): Each property is single instance, but for both user types, so have to request both
        // Student and Staff properties after save. If request only for Students, the Staff one
        // will be not actual in a storage.
        await queryClient.resetQueries([GET_SCHOOL_PROPERTIES_QUERY]);

        goBack();
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }

      setSaving(false);
    },
    [categories, goBack, schoolId, showError],
  );

  return (
    <SchoolSettingsLayout>
      <ModalLarge open onClose={handleClose}>
        {!categories ? (
          <Loading />
        ) : (
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <ModalHeader title={currentSchool?.name} active>
                {hasArchived && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showArchived}
                        onChange={(event, value) => setShowArchived(value)}
                      />
                    }
                    label={$t({ id: 'action-ShowArchived' })}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        whiteSpace: 'nowrap',
                      },
                    }}
                  />
                )}
                {!saving && (
                  <IconButton onClick={handleClose}>
                    <CrossIcon />
                  </IconButton>
                )}
              </ModalHeader>

              <SchoolTuneStatusesModalStudentsPanel
                showArchived={showArchived}
                setShowArchived={setShowArchived}
                autoSwitchedArchivedOn={autoSwitchedArchivedOn}
              />
              <SchoolTuneStatusesModalStaffPanel
                showArchived={showArchived}
                setShowArchived={setShowArchived}
                autoSwitchedArchivedOn={autoSwitchedArchivedOn}
              />

              <ModalFooter sx={{ justifyContent: 'space-between' }}>
                <Button variant="outlined" disabled={saving} onClick={handleClose}>
                  <FormattedMessage id="action-Cancel" />
                </Button>
                <Button type="submit" disabled={saving} endIcon={saving ? <Spin /> : <CheckIcon />}>
                  <FormattedMessage id="action-Save" />
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        )}
      </ModalLarge>
    </SchoolSettingsLayout>
  );
};
