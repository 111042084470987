import { Box } from '@mui/material';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { useSchool } from '../../../hooks/useSchool';
import AccessDeniedPage from '../../AccessDenied';
import { SchoolCustomFieldsContent } from './SchoolCustomFieldsContent';

export const SchoolCustomFields: FC = () => {
  const { isSchoolAdmin } = useSchool();

  if (!isSchoolAdmin) {
    return <AccessDeniedPage />;
  }

  return (
    <MainLayout>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="school-sections-CustomFields" />
      </Box>
      <SchoolCustomFieldsContent />
      <Outlet />
    </MainLayout>
  );
};
