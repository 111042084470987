import { buildClassName } from '@schooly/utils/build-classname';
import { FC, PropsWithChildren } from 'react';

import { $UIKitPrefix } from '../../css/variables';

import './style.scss';

export interface TagProps extends PropsWithChildren {
  big?: boolean;
  className?: string;
}

export const $class = `${$UIKitPrefix}Tag`;

export const Tag: FC<TagProps> = ({ big, children, className }) => {
  return (
    <div className={buildClassName($class, big && `${$class}_big`, className)}>{children}</div>
  );
};
