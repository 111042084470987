import { AdultAssociation } from '@schooly/api';
import { CheckIcon, PlusIcon } from '@schooly/style';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../ui/Button';
import FormWithFooter from '../../ui/FormWithFooter';
import ListItem from './ListItem';
import { isTempUserId } from './utils';

import './ChildrenDisplay.scss';

interface ChildrenDisplayProps {
  childrenList: AdultAssociation[];
  isLoading?: boolean;
  isChildSelectOpen?: boolean;
  onChildDelete: (userId: string) => void;
  onSubmit: () => void;
  onOpenChildSelect: () => void;
}

const ChildrenDisplay: React.FC<ChildrenDisplayProps> = ({
  childrenList,
  isLoading,
  isChildSelectOpen,
  onChildDelete,
  onSubmit,
  onOpenChildSelect,
}) => {
  function handleRemoveChildren(userId: string) {
    onChildDelete(userId);
  }

  function renderChildren() {
    if (!childrenList.length) {
      return (
        <div className="p-3">
          <p className="text-center text-muted w-50 mx-auto">
            <FormattedMessage id="people-SelectOrCreateChildren" />
          </p>
        </div>
      );
    }

    const createdChildren = childrenList.filter(({ user_id }) => isTempUserId(user_id));
    const selectedChildren = childrenList.filter(({ user_id }) => !isTempUserId(user_id));

    return (
      <div>
        {createdChildren.map((user) => (
          <ListItem
            userType="student"
            key={user.user_id}
            user={user}
            onUserDelete={handleRemoveChildren}
            isProtected
          />
        ))}
        {selectedChildren.map((user) => (
          <ListItem
            userType="student"
            key={user.user_id}
            user={user}
            onUserDelete={handleRemoveChildren}
            isProtected
          />
        ))}
      </div>
    );
  }

  return (
    <FormWithFooter
      className="ChildrenDisplay"
      footerContent={
        <></> || (
          <Button
            color="primary"
            rightIcon={<CheckIcon />}
            onClick={onSubmit}
            isLoading={isLoading}
            isDisabled={!childrenList.length}
            className="ml-auto"
          >
            <FormattedMessage id="action-Save" />
          </Button>
        )
      }
    >
      <h2 className="mt-3">Children</h2>

      {renderChildren()}

      {!isChildSelectOpen && !isLoading && (
        <div className="d-flex justify-content-end mt-3">
          <Button color="table-action" icon={<PlusIcon />} onClick={onOpenChildSelect} isDisabled>
            <FormattedMessage id="people-AddChild" />
          </Button>
        </div>
      )}
    </FormWithFooter>
  );
};

export default ChildrenDisplay;
