import { GroupsIcon } from '@schooly/style';
import { FC, PropsWithChildren } from 'react';

import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';

import './TagGroup.scss';

export interface TagGroupProps extends PropsWithChildren {
  className?: string;
}

const $class = `${$UIKitPrefix}TagGroup`;

export const TagGroup: FC<TagGroupProps> = ({ children, className }) => {
  return (
    <div className={buildClassName($class, className)}>
      <GroupsIcon /> <div className={`${$class}__text`}>{children}</div>
    </div>
  );
};
