import { Box, Card, Icon, Stack, Typography } from '@mui/material';
import {
  FilterKeys,
  GET_SCHOOL_PROPERTIES_QUERY,
  GET_SCHOOL_RE_ENROLLMENT_STATUS,
  UpdateSchoolReEnrollmentRequestParams,
  useGetSchoolReEnrollmentStatus,
  useUpdateSchoolReEnrollmentStatusMutation,
} from '@schooly/api';
import { clearLastAppliedFilter, StoredFilterSections } from '@schooly/components/filters';
import { SchoolUserRole } from '@schooly/constants';
import { Spin } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { useSchool } from '../../../hooks/useSchool';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { queryClient } from '../../../queryClient';
import AccessDeniedPage from '../../AccessDenied';
import {
  SchoolReEnrollmentContent,
  ViewStudentProps,
} from './SchoolReEnrollment/SchoolReEnrollmentContent';

export const SchoolAnnualRollover: FC = () => {
  const { schoolId, isSchoolAdmin } = useSchool();
  const navigate = useNavigate();
  const { schoolYears } = useSchoolYears();

  const { data, isLoading } = useGetSchoolReEnrollmentStatus(schoolId ?? '', {
    enabled: Boolean(schoolId && isSchoolAdmin),
    refetchOnMount: 'always',
  });

  const updateSchoolReEnrollmentStatus = useUpdateSchoolReEnrollmentStatusMutation();
  const isUpdating = updateSchoolReEnrollmentStatus.isLoading;
  const handleReEnrollmentSubmit = useCallback(
    async (v: UpdateSchoolReEnrollmentRequestParams) => {
      if (!schoolId) return;
      const result = await updateSchoolReEnrollmentStatus.mutateAsync(v);

      queryClient.setQueryData([GET_SCHOOL_RE_ENROLLMENT_STATUS, schoolId], result);
      queryClient.removeQueries([
        GET_SCHOOL_PROPERTIES_QUERY,
        {
          schoolId,
          userType: SchoolUserRole.Student,
          showReEnrollmentProperties: true,
        },
      ]);
    },
    [schoolId, updateSchoolReEnrollmentStatus],
  );
  const handleViewStudents = useCallback(
    ({ statusId, yearId }: ViewStudentProps) => {
      const year = schoolYears.find((y) => y.id === yearId);
      if (!year) return;

      clearLastAppliedFilter(StoredFilterSections.Students);
      navigate(
        `/students?${`${FilterKeys.Date}=${JSON.stringify([year.start])}&${
          FilterKeys.Status
        }=${JSON.stringify([statusId])}`}`,
      );
    },
    [navigate, schoolYears],
  );

  if (!isSchoolAdmin || !schoolId) {
    return <AccessDeniedPage />;
  }

  return (
    <MainLayout>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="section-AnnualRollover" />
      </Box>

      <Card>
        <Box
          height={20}
          pt={2.5}
          pl={2.5}
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              height: 50,
            },
          })}
        >
          <Typography variant="h2">
            <FormattedMessage id="section-AnnualRolloverReEnrollment" />
          </Typography>
        </Box>
        <Box pt={0.5} pb={2.5}>
          {isLoading || !data ? (
            <Stack justifyContent="center" alignItems="center" width="100%">
              <Icon fontSize="medium">
                <Spin />
              </Icon>
            </Stack>
          ) : (
            <SchoolReEnrollmentContent
              data={data}
              schoolId={schoolId}
              onSubmit={handleReEnrollmentSubmit}
              isUpdating={isUpdating}
              onViewStudents={handleViewStudents}
            />
          )}
        </Box>
      </Card>

      <Outlet />
    </MainLayout>
  );
};
