import { Stack } from '@mui/material';
import { Genders } from '@schooly/constants';
import { FC, PropsWithChildren, useCallback } from 'react';
import { $enum } from 'ts-enum-util';

import { ExpandedSelect } from '../ExpandedSelect';
import { GenderTagSelect, GenderTagSelectProps } from './GenderTagSelect';

type GenderExpandedSelectProps = PropsWithChildren<{
  selectedGenders: Genders[];
  onSelectGender: (v: Genders) => void;
  onClear: () => void;
  onClose: () => void;
}>;

const OPTIONS = $enum(Genders).map((v) => v);

export const GenderExpandedSelect: FC<GenderExpandedSelectProps> = ({
  selectedGenders,
  onSelectGender,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderGenderTags({
          genders: OPTIONS,
          tagProps: (status) => ({
            variant: selectedGenders.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectGender,
        })}
      </Stack>
    );
  }, [onSelectGender, selectedGenders]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedGenders.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={renderContent}
    >
      {renderGenderTags({
        tagProps: { size: 'small' },
        genders: selectedGenders,
        onDelete: onSelectGender,
      })}
    </ExpandedSelect>
  );
};

type RenderGenderTagsParams = {
  genders: Genders[];
  onDelete?: (v: Genders) => void;
  onClick?: (v: Genders) => void;
  tagProps?:
    | Omit<GenderTagSelectProps, 'gender'>
    | ((v: Genders) => Omit<GenderTagSelectProps, 'gender'>);
};

export const renderGenderTags = ({
  genders,
  onDelete,
  onClick,
  tagProps,
}: RenderGenderTagsParams) =>
  genders.map((v) => (
    <GenderTagSelect
      key={v}
      gender={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
