import { useGetCompanyQuery } from '@schooly/api';
import { Loading, ModalLarge } from '@schooly/style';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';

import AccessDenied from '../../../components/common/AccessDenied';
import { ModalSidebarItem } from '../../../components/ui/ModalSidebar';
import { CompanyPreviewModalTabs, useCompany } from '../../../context/companies/WithCompany';
import { useRouter } from '../../../context/router/useRouter';
import useAppLocation from '../../../hooks/useAppLocation';
import { CompanyPreviewModalContent } from './CompanyPreviewModalContent';

export const CompanyPreviewModal: FC = () => {
  const navigate = useNavigate();
  const location = useAppLocation();
  const { goBack } = useRouter();

  const { id, company, isLoading, canCreateAndEdit: canEdit, canView, activeTab } = useCompany();
  const onEdit = useCallback(() => navigate(`/companies/${id}/edit`), [navigate, id]);

  useGetCompanyQuery(id, {
    enabled: !!id,
    refetchOnMount: 'always',
  });

  const handleChangeActiveTab = useCallback(
    (item: ModalSidebarItem<CompanyPreviewModalTabs>) => {
      navigate({ ...location, search: `tab=${item.id}` }, { state: location.state, replace: true });
    },
    [location, navigate],
  );

  if (isLoading) {
    return (
      <ModalLarge open onClose={goBack}>
        <Loading />
      </ModalLarge>
    );
  }

  if (!company || !canView) {
    return (
      <ModalLarge open onClose={goBack}>
        <AccessDenied />
      </ModalLarge>
    );
  }

  return (
    <CompanyPreviewModalContent
      activeTab={activeTab}
      company={company}
      handleChangeActiveTab={handleChangeActiveTab}
      onClose={goBack}
      onEdit={canEdit ? onEdit : undefined}
    />
  );
};
