import { PlusIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { DndListWrapper } from '../../../../src/components/uikit/Dnd/dnd.styled';
import { SELECT_LABEL_MAX_BODY_LENGTH } from './constants';
import { SchoolCustomFieldForm } from './SchoolCustomFieldsModalContent';
import { SchoolCustomFieldsSelectRow } from './SchoolCustomFieldsSelectRow';

export const SchoolCustomFieldsSelect: FC = () => {
  const { control, watch } = useFormContext<SchoolCustomFieldForm>();
  const selectTypeOptions = watch('select_type_options');

  const { append, remove, move } = useFieldArray({
    control: control,
    name: 'select_type_options',
  });

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const handleAddSelectOption = useCallback(() => {
    append({
      id: uuidv4(),
      order: selectTypeOptions?.length ?? 0,
      label: '',
    });
  }, [selectTypeOptions, append]);

  const handleRemoveSelectOption = useCallback(
    (id: string) => {
      const optionIdx = selectTypeOptions?.findIndex((option) => option.id === id);
      remove(optionIdx);
    },
    [selectTypeOptions, remove],
  );
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="CustomFieldsDroppable">
          {(provided) => (
            <DndListWrapper {...provided.droppableProps} ref={provided.innerRef} gap={1}>
              {selectTypeOptions?.map((option, index) => (
                <Draggable key={option.id} draggableId={option.id} index={index}>
                  {(provided) => (
                    <SchoolCustomFieldsSelectRow
                      provided={provided}
                      key={option.id}
                      rowIdx={index}
                      maxBodyLength={SELECT_LABEL_MAX_BODY_LENGTH}
                      onRemoveOption={handleRemoveSelectOption}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <SimpleButton
                disabled={false}
                sx={{
                  mt: !!selectTypeOptions?.length ? 2 : 0,
                  alignSelf: 'flex-start',
                  '&.Mui-disabled': {
                    backgroundColor: (theme) => theme.palette.background.default,
                  },
                }}
                onClick={handleAddSelectOption}
                startIcon={<PlusIcon />}
              >
                <FormattedMessage id="action-AddOption" />
              </SimpleButton>
            </DndListWrapper>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
