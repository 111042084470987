import { IColumnSort } from './people';

export enum CustomFieldDataType {
  NUMBER,
  STRING,
  DATE,
  BOOLEAN,
  ARR_STRING,
  ARR_NUMBER,
  MEDICAL,
  SELECT,
}

export enum CustomFieldApplicableTo {
  Admin = -1,
  Staff,
  Student,
  Parent,
  DefaultApplicationValues,
  CustomApplicationValues,
  GeneralInformation,
}

export enum CustomFieldPlaceOfUse {
  Application = 'application',
  Profile = 'profile',
  // Event,
  // Assessment,
}

export interface CustomFieldSelectOption {
  order: number;
  label: string;
}

export interface CustomField {
  id: string;
  data_type: CustomFieldDataType;
  applicable_to: CustomFieldApplicableTo[];
  place_of_use: string;
  label: string;
  required: boolean;
  searchable: boolean;
  is_severe?: boolean;
  select_type_options?: CustomFieldSelectOption[];
  archived?: boolean;
}

export interface CustomFieldResponse extends Omit<CustomField, 'place_of_use'> {
  place_of_use: CustomFieldPlaceOfUse[];
  archived: boolean;
}

export type CustomFieldUpdate = Omit<CustomField, 'id'> & { id?: string };

export interface CustomFieldBaseValue {
  custom_field_id: string;
  label: string;
  value: number | string | boolean | string[] | number[];
  value_id: string;
}

export interface CustomFieldNumberValue extends CustomFieldBaseValue {
  value: number;
}

export interface CustomFieldStringValue extends CustomFieldBaseValue {
  value: string;
}

export interface CustomFieldBooleanValue extends CustomFieldBaseValue {
  value: boolean;
}

export interface CustomFieldStringArrayValue extends CustomFieldBaseValue {
  value: string[];
}

export interface CustomFieldNumberArrayValue extends CustomFieldBaseValue {
  value: number[];
}

export type CustomFieldValue =
  | CustomFieldNumberValue
  | CustomFieldStringValue
  | CustomFieldBooleanValue
  | CustomFieldStringArrayValue
  | CustomFieldNumberArrayValue;

export interface GetCustomFieldsForSchoolFilter {
  label?: string[];
  applicable_to?: CustomFieldApplicableTo[];
  required?: boolean[];
  searchable?: boolean[];
  data_type?: CustomFieldDataType[];
  custom_fields_ids?: string[];
  place_of_use?: CustomFieldPlaceOfUse[];
}

export interface GetCustomFieldsForSchoolProps {
  schoolId: string;
  filter?: GetCustomFieldsForSchoolFilter;
  sort?: IColumnSort<keyof GetCustomFieldsForSchoolFilter>[];
}

export interface CreateCustomFieldForSchoolProps {
  schoolId: string;
  data: Omit<CustomField, 'id'>;
}

export interface CreateCustomFieldForSchoolRequest
  extends Omit<CustomField, 'id' | 'applicable_to' | 'place_of_use'> {
  applicable_to: string;
  place_of_use: string;
}

export interface CreateCustomFieldForSchoolResponse {
  custom_field_id: string;
  success: string;
}

export interface UpdateCustomFieldForSchoolProps {
  id: string;
  data: Omit<CustomField, 'id'>;
}

export interface UpdateCustomFieldForSchoolResponse {
  success: string;
}

export interface DeleteCustomFieldForSchoolProps {
  id: string;
  confirmed?: boolean;
}

export interface DeleteCustomFieldForSchoolResponse {
  success: string;
  custom_values_to_delete?: number;
}

export interface ArchiveCustomFieldRequest {
  id: string;
  archived: boolean;
}
