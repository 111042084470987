import { SimpleListResult } from '@schooly/api';
import React, { useEffect } from 'react';

import { AvailableUsersWithFilter } from '../../../../components/common/AvailableUsersWithFilter/AvailableUsersWithFilter';
import { useFilters } from '../../../../context/filters/useFilters';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import { UserRoleLimitationsUpdate } from '../Context/useUserRoleModal';

export interface AddStaffFilterSectionProps {
  staff: SimpleListResult[];
  onStaffClick: (staff: SimpleListResult) => void;
  date?: string;
  limitedTo: UserRoleLimitationsUpdate;
}

export const AddStaffFilterSection: React.FC<AddStaffFilterSectionProps> = ({
  staff,
  onStaffClick,
  date,
  limitedTo,
}) => {
  const { filters, applyFilters } = useFilters();
  const { defaultValidity } = useSchoolYears();

  useEffect(() => {
    // TR-3595 FE: School Tune. Access tab
    // 1. Empty staff-mini list when school years not added
    // When school years are not set, we need to manually apply filters
    if (!filters.applied && !defaultValidity) {
      applyFilters();
    }
  }, [defaultValidity, filters.applied, applyFilters]);

  return (
    <AvailableUsersWithFilter
      userType="staff"
      selectedUsers={staff}
      onUserClick={(_, staff) => {
        if (staff) {
          onStaffClick(staff);
        }
      }}
      searchPlaceholderTextId="groups-AvailableUsersSearch"
      filterPlaceholderTextId="groups-FilterUserType"
      limitedTo={limitedTo}
      singleDate={date}
      isModalOpen
    />
  );
};
