import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiError } from './apiTypes/misc';
import { RQUseMutationOptions, RQUseMutationResult, RQUseQueryOptions } from './apiTypes/query';
import {
  UserRole,
  UserRoleCreateRequest,
  UserRolePopulated,
  UserRoleUpdateRequest,
} from './apiTypes/userRoles';
import { UserRoleList } from './apiTypes/userRoles';
import * as api from './requests';

const USER_ROLES_URL = '/user-roles';

// BE not supports search_query yet
export function getUserRolesList(schoolId: string): Promise<UserRoleList> {
  return api.get(`${USER_ROLES_URL}/for-school/${schoolId}`);
}

export async function checkSystemRole(schoolId: string): Promise<boolean> {
  const response: { custom_roles_exist: boolean } = await api.get(
    `${USER_ROLES_URL}/${schoolId}/check-system-role`,
  );

  return response.custom_roles_exist;
}

export const CHECK_SYSTEM_ROLE = `${USER_ROLES_URL}CHECK_SYSTEM_ROLE`;

export const useCheckSystemRoleQuery = (schoolId: string, options?: RQUseQueryOptions<boolean>) => {
  return useQuery<boolean, ApiError>(
    [CHECK_SYSTEM_ROLE, schoolId],
    () => checkSystemRole(schoolId),
    options,
  );
};

export function checkUserRoleName(school_id: string, name: string): Promise<{ exists: boolean }> {
  const params = {
    name,
    school_id,
  };

  return api.get(`${USER_ROLES_URL}/check-user-role-name`, { params });
}

export function getUserRole(roleId: string): Promise<{ user_role: UserRolePopulated }> {
  return api.get(`${USER_ROLES_URL}/${roleId}`);
}
export function createUserRole({
  school_id,
  ...params
}: UserRoleCreateRequest): Promise<{ success: boolean; id: UserRole['id'] }> {
  return api.post(`${USER_ROLES_URL}/for-school/${school_id}`, params);
}

export function updateUserRole({
  id,
  ...params
}: UserRoleUpdateRequest): Promise<{ success: boolean }> {
  return api.patch(`${USER_ROLES_URL}/${id}`, params);
}

export function deleteUserRole(id: UserRoleUpdateRequest['id']): Promise<{ success: boolean }> {
  return api.remove(`${USER_ROLES_URL}/${id}`);
}

export function getUserRolesAvailableForRelation(
  relation_id: string,
  search_query?: string,
): Promise<UserRoleList> {
  return api.get(`${USER_ROLES_URL}/available-for-relation/${relation_id}`, {
    params: {
      search_query,
    },
  });
}

export function getUserRolesForRelation(relation_id: string): Promise<UserRoleList> {
  return api.get(`${USER_ROLES_URL}/for-relation/${relation_id}`);
}

export const GET_USER_ROLES = `${USER_ROLES_URL}GET_USER_ROLES`;

export const useGetUserRolesForRelationQuery = (
  relation_id: string,
  options?: RQUseQueryOptions<UserRoleList>,
) => {
  return useQuery<UserRoleList, ApiError>(
    [GET_USER_ROLES, relation_id],
    () => getUserRolesForRelation(relation_id),
    options,
  );
};

type UpdateUserRolesForRelationParams = {
  relation_id: string;
  userRoleIds: string[];
};

function updateUserRolesForRelation({
  relation_id,
  userRoleIds,
}: UpdateUserRolesForRelationParams): Promise<UserRoleList> {
  const params = {
    user_role_ids: userRoleIds,
  };
  return api.patch(`${USER_ROLES_URL}/for-relation/${relation_id}`, params);
}

export const useUpdateUserRolesForRelationMutation = (
  options?: RQUseMutationOptions<UserRoleList, UpdateUserRolesForRelationParams>,
): RQUseMutationResult<UserRoleList, UpdateUserRolesForRelationParams> => {
  const queryClient = useQueryClient();

  return useMutation(updateUserRolesForRelation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([GET_USER_ROLES, variables.relation_id]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
