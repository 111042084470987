import { DATE_FORMAT_MOMENT as DATE_FORMAT } from '@schooly/constants';
import { SuggestedChangeDataSet } from '@schooly/constants';
import moment from 'moment';
import React, { FC } from 'react';

import { LongStringWithTooltip } from '../../../../components/uikit-components/LongStringWithTooltip/LongStringWithTooltip';
import { useProfile } from '../../../../context/profile/useProfile';
import ProfileProperty from '../ProfileProperty';
import { CustomFieldDateModal } from './CustomFieldDateModal';
import { CustomFieldPropertyProps } from './CustomFieldPropertyProps';

export const CustomFieldDateProperty: FC<CustomFieldPropertyProps> = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
  value,
  hideSuggestedChanges,
  onClick,
  onClose,
  ...field
}) => {
  const { hasEditingPermission } = useProfile();

  const handleClose = (fn: () => void) => {
    fn();
    onClose?.();
  };

  return (
    <ProfileProperty
      value={value?.value}
      canEdit={hasEditingPermission}
      params={{ size, iconColor, color }}
      renderLabel={() => <LongStringWithTooltip>{field.label}</LongStringWithTooltip>}
      renderValue={(value) => moment(`${value}`).format(DATE_FORMAT)}
      valueText={field.label}
      editValueText={field.label}
      dataSet={SuggestedChangeDataSet.Telephones}
      hideSuggestedChanges={hideSuggestedChanges}
      modalController={({ isOpen, hide }) => (
        <CustomFieldDateModal
          isOpen={isOpen}
          onClose={() => handleClose(hide)}
          field={field}
          value={value}
        />
      )}
      onClick={onClick}
    />
  );
};
