import { ApiError } from '@schooly/api';
import { AttendanceRegister } from '@schooly/api';
import { createContext } from 'react';

export interface AttendanceRegisterContextProps {
  register?: AttendanceRegister;
  fetching: boolean;
  canEdit: boolean;
  error?: ApiError | null;
  request: () => void;
  showByPresentAbsent?: boolean;
  showByPercent?: boolean;
  setShowByPresentAbsent: (showByPresentAbsent: boolean) => void;
  setShowByPercent: (showByPercent: boolean) => void;
}

export const AttendanceRegisterContext = createContext<AttendanceRegisterContextProps>({
  register: undefined,
  fetching: false,
  canEdit: false,
  error: undefined,
  showByPresentAbsent: false,
  showByPercent: false,
  request: () => {},
  setShowByPresentAbsent: () => {},
  setShowByPercent: () => {},
});

AttendanceRegisterContext.displayName = 'AttendanceRegisterContext';
