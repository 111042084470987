import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import { StaffRegistration } from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';
import { DropdownIcon, EditIcon } from '@schooly/style';
import moment from 'moment';
import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { GridRowItem } from '../../../../components/uikit-components/Grid/Grid';
import { LongStringWithTooltip } from '../../../../components/uikit-components/LongStringWithTooltip/LongStringWithTooltip';
import { getDisplayStatus } from '../../../../helpers/registrations';
import {
  DetailsExpandable,
  EmploymentCaseGridRow,
  GridRowExpand,
  GridRowTitle,
  ListViewRowActions,
} from './ProfileModalEmploymentCaseRow.styled';
import { ProfileModalEmploymentCaseRowDetailsRow } from './ProfileModalEmploymentCaseRowDetailsRow';

export interface ProfileModalEmploymentCaseRowProps {
  registration: StaffRegistration;
  onEdit: (v: StaffRegistration) => void;
  canEdit: boolean;
}

export const ProfileModalEmploymentCaseRow: FC<ProfileModalEmploymentCaseRowProps> = ({
  registration,
  onEdit,
  canEdit,
}) => {
  const { $t } = useIntl();
  const [isExpanded, setExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  const handleEditClick = useCallback(() => {
    onEdit(registration);
  }, [onEdit, registration]);

  const renderStatus = () => {
    if (!registration.statuses.length) {
      return $t({ id: 'employment-NoStatus' });
    }

    const status = getDisplayStatus(registration.statuses);

    return `${status.school_property.name} ${$t({
      id: 'schoolProperty-From',
    }).toLowerCase()} ${moment(status.applies_from).format('D MMM YY')}`;
  };

  const subjects = registration.subjects?.filter(({ archived }) => !archived) ?? [];

  const ageGroups = registration.school_properties.filter(
    ({ type, archived }) => !archived && type === SchoolPropertyType.AgeGroup,
  );

  const houses = registration.school_properties.filter(
    ({ type, archived }) => !archived && type === SchoolPropertyType.House,
  );

  const department = registration.school_properties.find(
    ({ type, archived }) => !archived && type === SchoolPropertyType.Department,
  );

  const isExpandable = !!ageGroups.length || !!houses.length || !!registration.subjects?.length;

  return (
    <EmploymentCaseGridRow>
      <GridRowItem>
        <GridRowTitle>
          <Box mx={-0.75}>
            <LongStringWithTooltip>
              {registration.job_title || $t({ id: 'employment-NoJobTitle' })}
            </LongStringWithTooltip>
          </Box>
        </GridRowTitle>
        <GridRowTitle>
          <Typography>{department?.name}</Typography>
        </GridRowTitle>
        <GridRowTitle>
          <Typography className="status">{renderStatus()}</Typography>
        </GridRowTitle>
        <GridRowExpand>
          <Icon
            className="icon-Expand"
            sx={{
              cursor: 'pointer',
              visibility: isExpandable ? 'visible' : 'hidden',
              transform: isExpanded ? 'rotate(180deg)' : undefined,
              color: 'common.grey',
            }}
            onClick={toggleExpand}
          >
            <DropdownIcon />
          </Icon>
        </GridRowExpand>
        <ListViewRowActions>
          {canEdit && (
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          )}
        </ListViewRowActions>
      </GridRowItem>
      {isExpandable && (
        <DetailsExpandable isExpanded={isExpanded}>
          <Stack gap={1}>
            {!!ageGroups.length && (
              <ProfileModalEmploymentCaseRowDetailsRow
                title={$t({ id: 'schoolProperty-AgeGroup' })}
                values={ageGroups}
              />
            )}
            {!!subjects.length && (
              <ProfileModalEmploymentCaseRowDetailsRow
                title={$t({ id: 'peopleDetail-EmploymentCaseModal-Subject' })}
                values={subjects}
              />
            )}
            {!!houses.length && (
              <ProfileModalEmploymentCaseRowDetailsRow
                title={$t({ id: 'schoolProperty-House' })}
                values={houses}
              />
            )}
          </Stack>
        </DetailsExpandable>
      )}
    </EmploymentCaseGridRow>
  );
};
