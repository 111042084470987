import { IconButton } from '@mui/material';
import { SchoolYear, useExportConductForRelation } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, DownloadIcon, Spin } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { IntlError } from '@schooly/utils/intl-error';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { useProfile } from '../../../context/profile/useProfile';
import { downloadFile } from '../../../utils/downloadFile';

type ProfileModalConductExportProps = {
  schoolYear?: SchoolYear;
};

export const ProfileModalConductExport: FC<ProfileModalConductExportProps> = ({ schoolYear }) => {
  const { showError } = useNotifications();
  const { schoolMembership } = useProfile();

  // is being set after successful download and reset in 3 sec afterwards
  const [isSuccess, setIsSuccess] = useState(false);
  const mutation = useExportConductForRelation();

  const handleExportClick = useCallback(() => {
    if (!schoolMembership?.relation_id || !schoolYear) {
      return;
    }

    mutation.mutate(
      { relationId: schoolMembership.relation_id, date: [schoolYear.start, schoolYear.end] },
      {
        onSuccess: (data) => {
          try {
            downloadFile(data, `${getUserFullName(schoolMembership)}.conduct.csv`);
            setIsSuccess(true);
          } catch (err) {
            showError(err as IntlError);
          }
        },
        onError: showError,
      },
    );
  }, [mutation, schoolMembership, schoolYear, showError]);

  /** resets success icon in 3 sec */
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccess(false), 3 * 1000);
    }
  }, [isSuccess]);

  return (
    <IconButton
      disabled={!schoolYear}
      sx={{ color: mutation.isLoading || isSuccess ? 'primary.main' : 'text.primary' }}
      inverse
      onClick={mutation.isLoading ? undefined : handleExportClick}
    >
      {(() => {
        if (mutation.isLoading) {
          return <Spin />;
        }

        if (isSuccess) {
          return <CheckIcon />;
        }

        return <DownloadIcon />;
      })()}
    </IconButton>
  );
};
