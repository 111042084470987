import { FC } from 'react';

import { WithFilters } from '../../../context/filters/WithFilters';
import ManageGroupModal from './ManageGroupModal';

export const ManageRelationGroups: FC = () => {
  return (
    <WithFilters type="sidebar-relation-groups">
      <ManageGroupModal />
    </WithFilters>
  );
};
