import { Stack } from '@mui/material';
import { ConductType } from '@schooly/api';
import React, { FC } from 'react';

import { ConductConnotationIndicator } from '../ConductGrid';

export const ConductTypeOption: FC<Pick<ConductType, 'connotation' | 'name'>> = ({
  name,
  connotation,
}) => (
  <Stack direction="row" alignItems="center" gap={1} width="100%">
    <ConductConnotationIndicator connotation={connotation} />
    {name}
  </Stack>
);
