import { Backdrop, Box, ClickAwayListener, Stack, Tooltip, TooltipProps } from '@mui/material';
import { SchoolYear } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useFlag } from '@schooly/hooks/use-flag';
import { ChevronDownSmallIcon, Spin, TagSelect } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isBefore } from 'date-fns';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { FiltersDropdownButton } from '../FiltersDropdownButton';
import { NoPeriodLabel } from './DatePicker/DatePickerCommonComponents';
import { DateRangeSelectContent } from './DatePicker/DateRangeSelectContent';
import { RangePickerHeader } from './DatePicker/RangePickerHeader';
import { DateRangeTagSelect } from './DateRangeTagSelect';
import { SchoolPeriodSelectList } from './SchoolPeriods/SchoolPeriodSelectList';
import { SchoolPeriodsList } from './SchoolPeriods/SchoolPeriodsList';
import { Period, useSchoolPeriods } from './SchoolPeriods/useSchoolPeriods';

type DateRangeDropdownProps = {
  schoolId: string;
  openLabel: ReactNode;
  date: string[];
  renderDate?: (v: string[], onClick: () => void, label: ReactNode) => ReactNode;
  onSetDate: (v: [Date, Date]) => void;
  currentPeriod?: Period;
  defaultSchoolYear?: SchoolYear;
  dateChanged?: boolean;
  notActualInitialDate?: boolean;
} & Partial<Omit<TooltipProps, 'children'>>;

export const DateRangeDropdown: FC<DateRangeDropdownProps> = ({
  date,
  openLabel,
  renderDate,
  onSetDate,
  schoolId,
  defaultSchoolYear,
  dateChanged,
  notActualInitialDate,
  ...tooltipProps
}) => {
  const {
    label,
    schoolYears,
    currentSchoolYear,
    changePeriod,
    isThisMonth,
    isThisWeek,
    isCurrentYear,
    currentPeriod,
    isToday,
    isLoading,
  } = useSchoolPeriods(schoolId, date ?? [], defaultSchoolYear);
  const { permissions } = useAuth();
  const hasManagePermission = permissions.includes('school_admin');
  const [listOpen, setListOpen] = useState(false);

  const [opened, open, close] = useFlag();

  const propsFromDateString = date[0];
  const propsToDateString = date[1];

  const propsFromDate = useMemo(
    () => (propsFromDateString ? newDateTimezoneOffset(propsFromDateString) : null),
    [propsFromDateString],
  );
  const propsToDate = useMemo(
    () => (propsToDateString ? newDateTimezoneOffset(propsToDateString) : null),
    [propsToDateString],
  );

  const [dates, setDates] = useState({
    fromDate: propsFromDate,
    toDate: propsToDate,
  });

  const { fromDate, toDate } = dates;

  const handleSetDates = useCallback(
    (fromDate: Date, toDate: Date) => {
      onSetDate([fromDate, isBefore(toDate, fromDate) ? fromDate : toDate]);
      close();
    },
    [close, onSetDate],
  );

  const toggleList = useCallback(() => {
    setListOpen((l) => !l);
  }, []);

  const handleSetDate = useCallback((date: Date) => {
    setDates((dates) => {
      const { fromDate, toDate } = dates;

      if (fromDate && toDate) {
        return {
          fromDate: date,
          toDate: null,
        };
      }

      if (!fromDate) {
        return { ...dates, fromDate };
      }

      if (isBefore(date, fromDate)) {
        return {
          toDate: dates.fromDate,
          fromDate: date,
        };
      }

      return { ...dates, toDate: date };
    });
  }, []);

  useEffect(() => {
    setDates({ fromDate: propsFromDate, toDate: propsToDate });
  }, [propsFromDate, propsToDate]);

  const handleClose = useCallback(() => {
    if (!opened) return;

    const resultFromDate = fromDate || propsFromDate;
    const resultToDate = toDate || propsToDate;

    if (!resultFromDate || !resultToDate) return;

    onSetDate([
      resultFromDate,
      isBefore(resultToDate, resultFromDate) ? resultFromDate : resultToDate,
    ]);

    close();
  }, [close, fromDate, onSetDate, opened, propsFromDate, propsToDate, toDate]);

  const tagContent = useMemo(() => {
    if (isLoading) return <TagSelect label={<Spin />} />;
    if (opened) return <FiltersDropdownButton>{openLabel}</FiltersDropdownButton>;
    const currentLabel = label ? (
      <TagSelect label={label} />
    ) : (
      <DateRangeTagSelect date={date} onClick={open} />
    );

    return (
      <Stack
        flexDirection="row"
        gap={0.5}
        alignItems="center"
        sx={{
          '&:hover': {
            '.MuiChip-root': {
              backgroundColor: (theme) => theme.palette.common.light2,
            },
          },
        }}
      >
        {' '}
        {!dateChanged && notActualInitialDate ? (
          <DateRangeTagSelect onClick={open} date={date} showNoCurrentSchoolYearWarning />
        ) : (
          currentLabel
        )}
        <ChevronDownSmallIcon color="black" />
      </Stack>
    );
  }, [date, dateChanged, isLoading, label, notActualInitialDate, open, openLabel, opened]);

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={handleClose}>
        <div data-test-id={'filter-date'}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 500,
                  maxWidth: 500,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0.5, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <>
                <RangePickerHeader
                  open={opened}
                  startDate={fromDate}
                  endDate={toDate}
                  onClose={handleClose}
                />
                {listOpen ? (
                  <SchoolPeriodsList
                    schoolYears={schoolYears ?? []}
                    onSetPeriod={(data) => {
                      changePeriod(data);
                      handleSetDates(
                        newDateTimezoneOffset(data.period.date_from),
                        newDateTimezoneOffset(data.period.date_to),
                      );
                    }}
                    defaultSchoolYear={currentSchoolYear}
                    onGoBack={toggleList}
                    currentPeriod={currentPeriod}
                    withoutPeriodLabel={(yearId) => (
                      <NoPeriodLabel hasManagePermission={hasManagePermission} yearId={yearId} />
                    )}
                  />
                ) : (
                  <>
                    <DateRangeSelectContent
                      fromDate={fromDate}
                      toDate={toDate}
                      onSetDate={handleSetDate}
                    />
                    <Stack mx={2}>
                      <SchoolPeriodSelectList
                        defaultSchoolYear={defaultSchoolYear}
                        onSetDate={(start, end) =>
                          handleSetDates(newDateTimezoneOffset(start), newDateTimezoneOffset(end))
                        }
                        onShowListClick={toggleList}
                        isThisWeek={isThisWeek}
                        isThisMonth={isThisMonth}
                        isCurrentYear={isCurrentYear}
                        isToday={isToday}
                      />
                    </Stack>
                  </>
                )}
              </>
            }
            {...tooltipProps}
          >
            <Box flexDirection="row" sx={{ cursor: 'pointer' }} alignItems="center" onClick={open}>
              {tagContent}
            </Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};
