import {
  Autocomplete,
  Box,
  debounce,
  FormControlLabel,
  Icon,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  Event,
  EventsStatuses,
  FilterKeys,
  SignUpStatuses,
  SORT_DIRECTION,
  useGetEventsQuery,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { LoadMore } from '@schooly/components/filters';
import { FormTextField } from '@schooly/components/form-text-field';
import { useFlag } from '@schooly/hooks/use-flag';
import { usePrevious } from '@schooly/hooks/use-previous';
import { LockIcon, RecurringIcon } from '@schooly/style';
import { addDays, format } from 'date-fns';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SignUpForm, useSignUp } from '../../../context/signUps/WithSignUp';
import { useFormError } from '../../../hooks/useFormError';
import { useSchool } from '../../../hooks/useSchool';
import { ReferencePreview } from '../../ConsentForms/ReferencePreview/ReferencePreview';
import { EventPreviewSignUps } from '../../Events/EventPreviewModal/EventPreviewSignUps';
import { getEventDate } from '../../Events/EventsGrid';

const PAGE_SIZE = 13;

type SignUpEventSelectorProps = {
  hasConsentForm: boolean;
};

export const SignUpEventSelector: FC<SignUpEventSelectorProps> = ({ hasConsentForm }) => {
  const { $t } = useIntl();
  const { schoolId = '' } = useSchool();
  const { renderError } = useFormError();
  const ref = useRef<HTMLInputElement>();
  const [opened, open, close] = useFlag();

  const { signUp } = useSignUp();
  const { watch, formState, setValue, register } = useFormContext<SignUpForm>();

  const event_ = watch('event');
  const withEvent = watch('withEvent');
  const title = watch('title');
  const { getConfirmation } = useConfirmationDialog();

  /*
   * Autocomplete expects a non-mutable value, otherwise it will consider the value as new one,
   * what leads to the component reset and cleaning up its input filed
   */
  const event = useMemo(() => event_ ?? ({} as Event), [event_]);
  const prevEvent = usePrevious(event_);

  const showEventSelector = !signUp?.id || signUp?.status === SignUpStatuses.Draft;

  useEffect(() => {
    if (event_ && prevEvent?.title !== event_.title) {
      setValue('title', $t({ id: 'eventSignUps-create-TitlePreset' }, { title: event_.title }));
    }

    if (
      !event_ &&
      prevEvent &&
      title === $t({ id: 'eventSignUps-create-TitlePreset' }, { title: prevEvent.title })
    ) {
      setValue('title', undefined);
    }
  }, [$t, event_, prevEvent, setValue, title]);

  const { data, isLoading, setParams, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetEventsQuery(
      {
        schoolId,
        pageSize: PAGE_SIZE,
        filters: {
          [FilterKeys.Date]: [format(addDays(new Date(), 1), DEFAULT_DATE_FORMAT_FNS)],
          [FilterKeys.EventStatus]: [EventsStatuses.Draft, EventsStatuses.Published],
          [FilterKeys.EventType]: ['default'],
          has_signups: false,
        },
        sort: { columnTextId: 'start', direction: SORT_DIRECTION.ASC },
      },
      { enabled: Boolean(schoolId), refetchOnMount: 'always' },
    );

  const setParamsDebounced = useMemo(
    () =>
      debounce((query: string) => {
        setParams((params) => ({ ...params, query }));
      }, 300),
    [setParams],
  );

  const options = useMemo(
    () => data?.pages.reduce<Event[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  return (
    <Stack gap={1} flex={`0 ${withEvent ? '1' : '0'} auto`} position="relative">
      <input
        type="hidden"
        {...register('event_id', {
          required: withEvent ? $t({ id: 'input-ErrorRequired' }) : undefined,
        })}
      />
      {showEventSelector && (
        <>
          <Autocomplete
            ref={ref}
            open={opened}
            onOpen={open}
            onClose={close}
            disabled={(signUp && signUp.status !== SignUpStatuses.Draft) || !withEvent}
            options={options ?? []}
            getOptionLabel={(option?: Event) => option?.title ?? ''}
            renderOption={(props, option, state) => {
              if (option === options[options.length - 1] && hasNextPage) {
                return (
                  <Stack gap={1} px={0.5}>
                    <LoadMore isLoading={!!isFetchingNextPage} onFetchNextPage={fetchNextPage} />

                    {isFetchingNextPage && (
                      <>
                        <Skeleton variant="rectangular" height={14} width="100%" />
                        <Skeleton variant="rectangular" height={14} width="100%" />
                      </>
                    )}
                  </Stack>
                );
              }
              return (
                <li {...props}>
                  <Stack direction="row" gap={1} width="100%">
                    <Typography
                      variant="h3"
                      color="text.primary"
                      flex="0 0 130px"
                      sx={{ 'li:hover &': { color: 'primary.main' } }}
                    >
                      {getEventDate(option)}
                    </Typography>
                    <Typography
                      variant="h3"
                      color="primary.main"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {option.title}
                    </Typography>

                    {option.recurring_state && (
                      <Icon inverse>
                        <RecurringIcon />
                      </Icon>
                    )}
                  </Stack>
                </li>
              );
            }}
            loading={isLoading}
            loadingText={
              <Stack gap={0.5} px={0.5}>
                {new Array(PAGE_SIZE).fill(true).map((item, index) => (
                  <Stack key={index} height={22} justifyContent="center">
                    <Skeleton />
                  </Stack>
                ))}
              </Stack>
            }
            renderInput={(params) => (
              <FormTextField
                {...params}
                error={Boolean(formState.errors.event_id)}
                helperText={renderError(formState.errors.event_id?.message as string)}
                label={$t({ id: 'eventSignUps-create-LinkToEvent' })}
                required={withEvent}
                sx={
                  !withEvent
                    ? {
                        '& .MuiOutlinedInput-notchedOutline': {
                          bgcolor: 'background.default',
                        },
                      }
                    : undefined
                }
              />
            )}
            popupIcon={null}
            value={event ?? ({} as Event)}
            onChange={async (event, value) => {
              if (hasConsentForm && value.consent_form) {
                const isConfirmed = await getConfirmation({
                  textId: 'consentForms-removeFromEvent',
                  textValues: { eventName: value?.title ?? '' },
                  sx: {
                    '.MuiDialog-paperFullWidth': {
                      width: 600,
                    },
                  },
                });

                if (!isConfirmed) return;
                if (opened) close();
              }
              setValue('event', value);
              setValue('event_id', value.id);
              setValue('withEvent', true);
            }}
            onInputChange={(event, query) => setParamsDebounced(query)}
            openOnFocus
            disableClearable
            fullWidth
          />
          <Box
            sx={{
              position: 'absolute',
              right: 12,
              top: 10,
              zIndex: 1,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={!withEvent}
                  onChange={async (event, checked) => {
                    if (checked) {
                      if (signUp?.consent_form && event_) {
                        const isConfirmed = await getConfirmation({
                          textId: 'consentForms-unlinkFromEvent',
                          textValues: { eventName: `"${event_.title}"` ?? '' },
                          sx: {
                            '.MuiDialog-paperFullWidth': {
                              width: 600,
                            },
                          },
                        });

                        if (!isConfirmed) return;
                      }
                      setValue('withEvent', false);
                      setValue('event', undefined);
                      setValue('event_id', undefined);
                      return;
                    }

                    setValue('withEvent', true);
                  }}
                />
              }
              sx={{
                m: 0,
                color: 'text.secondary',

                '&& .MuiFormControlLabel-label': {
                  color: 'text.secondary',
                },

                '&:hover .MuiFormControlLabel-label': {
                  color: 'primary.main',
                },
              }}
              label={
                <Stack direction="row" alignItems="center" gap={1}>
                  {!withEvent && <Box component={LockIcon} sx={{ fontSize: 20 }} />}
                  <span>{$t({ id: 'eventSignUps-create-UseWithoutEvent' })}</span>
                </Stack>
              }
              labelPlacement="start"
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </Box>
        </>
      )}

      {event_ && (
        <ReferencePreview
          reference={{
            type: 'event',
            data: event_,
          }}
        >
          <Box
            sx={{
              '& > :first-child': {
                paddingBottom: 0,
              },
            }}
          >
            <EventPreviewSignUps titleWidth="180px" event={event_} showHeaderTitle={false} />
          </Box>
        </ReferencePreview>
      )}
    </Stack>
  );
};
