import { Box, IconButton, Stack, Typography } from '@mui/material';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { EditIcon, Loading } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../../components/uikit/Counter/Counter.styled';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../../components/uikit-components/Grid/Grid';
import { useSchool } from '../../../../../hooks/useSchool';
import { SchoolTuneDepartmentsEmptyStub } from './SchoolTuneDepartmentsEmptyStub';

export const SchoolTuneDepartments: FC = () => {
  const { schoolId = '' } = useSchool();

  const { propertiesMap, isLoading } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Staff,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!propertiesMap.department?.length && !isLoading) {
    return <SchoolTuneDepartmentsEmptyStub />;
  }

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="school-tabs-Departments" />
          </Typography>
          {!!propertiesMap.department?.length && (
            <Counter>{propertiesMap.department?.length}</Counter>
          )}
        </Stack>

        <Link to="departments">
          <IconButton inverse>
            <EditIcon />
          </IconButton>
        </Link>
      </Stack>

      <Box sx={{ overflow: 'auto' }}>
        <GridContainer>
          {propertiesMap.department.map((department) => (
            <GridRowStyled key={department.id}>
              <GridRowItem>
                <GridRowName>{department.name}</GridRowName>
              </GridRowItem>
            </GridRowStyled>
          ))}
        </GridContainer>
      </Box>
    </Stack>
  );
};
