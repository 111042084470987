import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useGetSchoolYears } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { RecurringSelect } from '@schooly/components/recurring';
import { CrossIcon, EditIcon, ModalHeader, ModalHeaderTitle } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { renderError } from '../../../components/ui/Input/helpers';
import { ControlModalHeaderInput } from '../../../components/uikit-components/Modal/ControlModalHeaderInput';
import { useSchoolYearPresets } from '../../../hooks/useSchoolYearPresets';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { AssessmentForm, getAssessmentFormErrorTextId } from './AssessmentCreateContent';
import { AssessmentsCreateModalGroups } from './AssessmentCreateModalGroups/AssessmentsCreateModalGroups';
import { AssessmentsCreateModalSettings } from './AssessmentCreateModalSettings/AssessmentsCreateModalSettings';
import { AssessmentsCreateModalMode, useAssessmentCreateModal } from './useAssessmentCreateModal';
import { useWithAssessmentFormValidation } from './useAssessmentFormValidation';

type AssessmentFormContentProps = {};

export const AssessmentFormContent: FC<AssessmentFormContentProps> = (props) => {
  const { schoolId } = useAuth();
  const { $t } = useIntl();

  const { control, watch, formState, trigger } = useFormContext<AssessmentForm>();

  const { autoFocus, mode, actions } = useAssessmentCreateModal();
  const { defaultValidity } = useSchoolYears();
  const validityEndDate = defaultValidity ? newDateTimezoneOffset(defaultValidity.end) : null;
  const errors = formState.errors;
  const name = watch('name');
  const assessmentDate = watch('assessment_date');

  const { data } = useGetSchoolYears(schoolId ?? '', {
    refetchOnMount: 'always',
    enabled: !!schoolId,
  });

  const schoolPeriodPresets = useSchoolYearPresets({
    startDate: newDateTimezoneOffset(assessmentDate),
    years: data?.school_years ?? [],
  });

  const handleHeaderFocus = useCallback(() => {
    if (mode === AssessmentsCreateModalMode.Settings) {
      return;
    }

    actions.setMode(AssessmentsCreateModalMode.Settings, 'name');
    trigger('name');
  }, [actions, mode, trigger]);

  const { onValidateRecurringEndDate, groupsChecking } = useWithAssessmentFormValidation();

  return (
    <>
      <ModalHeader
        active={mode === AssessmentsCreateModalMode.Settings}
        title={
          mode === AssessmentsCreateModalMode.Settings ? (
            <ControlModalHeaderInput
              control={control}
              name="name"
              rules={{ required: true }}
              placeholder={$t({ id: 'assessments-AssessmentName' })}
              autoFocus={!name || autoFocus === 'name'}
              onFocus={handleHeaderFocus}
              helperText={renderError(errors?.name)}
            />
          ) : (
            <Stack direction="row" gap={1} alignItems="center">
              <Box>
                <ModalHeaderTitle
                  endIcon={<EditIcon />}
                  iconsOnHover={!!name}
                  onClick={handleHeaderFocus}
                >
                  {name}
                </ModalHeaderTitle>
              </Box>
              {formState.isSubmitted && !name && (
                <Typography variant="body2" color="error">
                  {$t({ id: getAssessmentFormErrorTextId('name') })}
                </Typography>
              )}
            </Stack>
          )
        }
      >
        <IconButton onClick={actions.handleClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <AssessmentsCreateModalSettings
        recurringSelect={
          assessmentDate ? (
            <RecurringSelect
              entity={{
                name: $t({ id: 'groups-Assessment' }),
                namePlural: $t({ id: 'groups-Assessments' }),
              }}
              title={name}
              presets={schoolPeriodPresets}
              startDate={newDateTimezoneOffset(assessmentDate)}
              endDate={validityEndDate}
              name="recurring_state"
              onCustomValidation={onValidateRecurringEndDate}
              loading={groupsChecking}
            />
          ) : null
        }
      />
      <AssessmentsCreateModalGroups />
    </>
  );
};
