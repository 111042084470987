import { Box, Stack, Typography } from '@mui/material';
import { DATE_FORMAT } from '@schooly/constants';
import { EditIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { SimpleButton } from '../../../components/uikit/SimpleButton/SimpleButton';
import { ConductTypeOption } from './ConductTypeOption';
import { ConductCreateModalContextState } from './useConductCreateModal';

interface ConductCreateModalPreviewProps extends ConductCreateModalContextState {
  onClick(key: keyof ConductCreateModalContextState): void;
}

export const ConductCreateModalPreview: FC<ConductCreateModalPreviewProps> = ({
  date,
  selectedConductType,
  onClick,
}) => {
  return (
    <Stack direction="row" gap={12}>
      <Stack>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage id="conduct-ConductType" />
        </Typography>

        <Box>
          <SimpleButton
            endIcon={<EditIcon />}
            iconsOnHover={!!selectedConductType}
            onClick={() => onClick('selectedConductType')}
          >
            {selectedConductType && <ConductTypeOption {...selectedConductType} />}
          </SimpleButton>
        </Box>
      </Stack>

      <Stack>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage id="conduct-Date-modal" />
        </Typography>

        <Box>
          <SimpleButton
            endIcon={<EditIcon />}
            iconsOnHover={!!date}
            onClick={() => onClick('date')}
          >
            {date && format(newDateTimezoneOffset(date), DATE_FORMAT)}
          </SimpleButton>
        </Box>
      </Stack>
    </Stack>
  );
};
