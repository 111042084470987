import { Company } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, { FC } from 'react';

import AccessDenied from '../../../components/common/AccessDenied';
import { PayableFees } from '../../../components/common/PayableFees/PayableFees';

type CompanyPreviewModalPayableFeesProps = {
  company: Company;
};

export const CompanyPreviewModalPayableFees: FC<CompanyPreviewModalPayableFeesProps> = ({
  company,
}) => {
  const { permissions } = useAuth();
  const canView = permissions.includes('product_and_invoice_viewer');

  if (!canView) {
    return <AccessDenied />;
  }

  return <PayableFees id={company.id} relationType="company" />;
};
