import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3H4C3.44772 3 3 3.44772 3 4V6H17V4C17 3.44772 16.5523 3 16 3H15C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3H7C7 2.44772 6.55228 2 6 2ZM3 7H17V16C17 16.5523 16.5523 17 16 17H4C3.44772 17 3 16.5523 3 16V7ZM13.3536 9.85355C13.5488 9.65829 13.5488 9.34171 13.3536 9.14645C13.1583 8.95118 12.8417 8.95118 12.6464 9.14645L9 12.7929L6.85355 10.6464C6.65829 10.4512 6.34171 10.4512 6.14645 10.6464C5.95118 10.8417 5.95118 11.1583 6.14645 11.3536L9 14.2071L13.3536 9.85355Z"
      fill="#8E90AD"
    />
  </svg>
);
