import { Stack, Tooltip } from '@mui/material';
import { StaffRegistration, StudentRegistration } from '@schooly/api';
import { getSourcePropertyName } from '@schooly/components/filters';
import { ArchiveIcon } from '@schooly/style';
import moment from 'moment';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DEFAULT_DATE_FORMAT } from '../../../config';
import { getDisplayStatus, isStudentRegistration } from '../../../helpers/registrations';
import formatDate from '../../../utils/formatDate';

const APPLIES_DATE_FORMAT = 'DD MMM YY';

export interface StatusStringForRegistrationProps {
  registration: StudentRegistration | StaffRegistration;
  statusInfo?: ReactNode;
}

export const StatusStringForRegistration: FC<StatusStringForRegistrationProps> = ({
  registration,
  statusInfo,
}) => {
  const { $t } = useIntl();
  const { statuses } = registration;
  const school_year = isStudentRegistration(registration) ? registration.school_year : undefined;

  const now = Date.now();

  if (!statuses.length) {
    return <FormattedMessage id={school_year ? 'enrollment-NoStatus' : 'employment-NoStatus'} />;
  }

  const displayStatus = getDisplayStatus(statuses);

  const statusName = displayStatus.school_property.source
    ? $t({ id: getSourcePropertyName(displayStatus.school_property) })
    : displayStatus.school_property.name;

  const name = (
    <Stack direction="row" alignItems="center" gap={0.5}>
      {displayStatus.school_property.archived && <ArchiveIcon />}

      <span className="text-body">{statusName}</span>
      {statusInfo}
      {moment(displayStatus.applies_from, DEFAULT_DATE_FORMAT).isAfter(now) && (
        <span>{formatDate(displayStatus.applies_from, APPLIES_DATE_FORMAT)}</span>
      )}
    </Stack>
  );

  return displayStatus.school_property.archived ? (
    <Tooltip title={$t({ id: `schoolProperty-Archived-${displayStatus.school_property.type}` })}>
      {name}
    </Tooltip>
  ) : (
    name
  );
};
