import {
  ApiError,
  AssessmentGroup,
  AssessmentMethod,
  AssessmentMethodBlank,
  AssessmentMethodType,
  DEFAULT_DATE_FORMAT_FNS,
  Group,
  listGroups,
  MAX_PAGE_SIZE,
  RecurringState,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { checkRecurrenceStartDateError, getRecurringDates } from '@schooly/components/recurring';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import {
  AssessmentForm,
  AssessmentFormValidate,
  getAssessmentFormErrorTextId,
} from './AssessmentCreateContent';

type CheckGroupsDateProps = {
  date: Date;
  selectedGroups: Group[] | AssessmentGroup[];
  confirmTextId: string;
};

interface WithAssessmentFormValidationContextProps extends React.PropsWithChildren {
  onValidateDate: AssessmentFormValidate;
  onValidateRecurringEndDate: (recurringState: RecurringState) => Promise<boolean>;
  onValidateMethod: (method: AssessmentMethod | AssessmentMethodBlank) => boolean | string;
  groupsChecking: boolean;
  recurringOverlaps: boolean;
}

export const WithAssessmentFormValidationContext =
  createContext<WithAssessmentFormValidationContextProps>({
    onValidateDate: async () => false,
    onValidateMethod: () => false,
    groupsChecking: false,
    recurringOverlaps: false,
    onValidateRecurringEndDate: async () => false,
  });

export const WithAssessmentFormValidation: FC<
  PropsWithChildren<{ schoolId: string; relationIds: string[] }>
> = ({ children, schoolId, relationIds }) => {
  const [groupsChecking, setGroupsChecking] = useState(false);
  const { getConfirmation } = useConfirmationDialog();
  const { showError } = useNotifications();
  const { $t } = useIntl();

  const {
    watch,
    setValue,
    formState: { errors },
    trigger,
    clearErrors,
  } = useFormContext<AssessmentForm>();

  const assessmentDate = watch('assessment_date');
  const recurringState = watch('recurring_state');
  const selectedGroups = watch('groups');
  const displayNameError = errors['display_name'];
  const assessmentDateError = errors['assessment_date'];

  const checkGroupsDate = useCallback(
    async ({ selectedGroups, date, confirmTextId }: CheckGroupsDateProps) => {
      const formattedDate = format(date, DEFAULT_DATE_FORMAT_FNS);
      setGroupsChecking(true);

      try {
        const res = await listGroups({
          schoolId,
          pageSize: MAX_PAGE_SIZE,
          filters: { single_date: [formattedDate] },
          relationIds,
        });

        const availableGroupIds: string[] | undefined = res?.results.map(({ id }) => id) ?? [];

        const someGroupUnavailable = selectedGroups
          .map((g) => g.id)
          .some((g) => !availableGroupIds?.includes(g));

        if (someGroupUnavailable) {
          const confirmed = await getConfirmation({
            textId: confirmTextId,
          });

          if (confirmed) {
            setValue(
              'groups',
              selectedGroups.filter((g) => availableGroupIds.includes(g.id)) || [],
            );
          }

          return confirmed;
        }

        return true;
      } catch (error) {
        showError(error as ApiError);
        return false;
      } finally {
        setGroupsChecking(false);
      }
    },
    [getConfirmation, relationIds, schoolId, setValue, showError],
  );

  const recurringOverlaps = useMemo(
    () =>
      Boolean(
        recurringState &&
          assessmentDate &&
          checkRecurrenceStartDateError(recurringState, assessmentDate),
      ),
    [assessmentDate, recurringState],
  );

  useEffect(() => {
    const subscription = watch(
      (
        {
          useAssessmentNameAsDisplayName,
          name,
          assessment_date,
          recurring_state,
          recurringEndDate,
        },
        { name: fieldName },
      ) => {
        switch (fieldName) {
          case 'name':
            if (useAssessmentNameAsDisplayName) setValue('display_name', name);
            if ((displayNameError && name) || (!displayNameError && !name)) trigger('display_name');
            break;

          case 'recurring_state':
            if (!assessment_date) return;

            const recurringRemoved = recurringState && !recurring_state;
            if (recurringRemoved && recurringEndDate) setValue('recurringEndDate', undefined);

            if ((recurringRemoved && assessmentDateError) || recurring_state) {
              trigger('assessment_date');
            }
        }
      },
    );

    return subscription.unsubscribe;
  }, [
    assessmentDateError,
    clearErrors,
    displayNameError,
    recurringOverlaps,
    recurringState,
    setValue,
    trigger,
    watch,
  ]);

  const onValidateDate = useCallback<AssessmentFormValidate>(
    async (value, { recurring_state, groups }) => {
      if (groups.length && assessmentDate && assessmentDate !== value) {
        const confirmed = await checkGroupsDate({
          date: new Date(value),
          selectedGroups: groups,
          confirmTextId: 'assessments-ChangingAssessmentDate',
        });

        if (!confirmed) {
          setValue('assessment_date', assessmentDate);
          return true;
        }
      }
      if (!recurring_state || !value) return true;

      const recurringStateError = checkRecurrenceStartDateError(recurring_state, value);

      if (recurringStateError) return $t({ id: getAssessmentFormErrorTextId('recurring_state') });
      return true;
    },
    [$t, assessmentDate, checkGroupsDate, setValue],
  );

  const onValidateRecurringEndDate = useCallback(
    async (recurringState: RecurringState) => {
      if (!assessmentDate || !selectedGroups.length) return true;

      const recurringDates = getRecurringDates(
        newDateTimezoneOffset(assessmentDate),
        recurringState,
      );

      const recurringEndDate = recurringDates.at(-1);
      if (!recurringEndDate) return true;

      const confirmed = await checkGroupsDate({
        date: recurringEndDate,
        selectedGroups,
        confirmTextId: 'assessments-recurring-EndDateWarning',
      });

      if (confirmed) {
        setValue('recurringEndDate', format(recurringEndDate, DEFAULT_DATE_FORMAT_FNS));
      }

      return confirmed;
    },
    [assessmentDate, checkGroupsDate, selectedGroups, setValue],
  );

  const onValidateMethod = useCallback(
    (method: AssessmentMethod | AssessmentMethodBlank) => {
      if (method.method_type === AssessmentMethodType.Grade && !method.select_list_id)
        return $t({ id: getAssessmentFormErrorTextId('select_list_id') });

      return true;
    },
    [$t],
  );
  return (
    <WithAssessmentFormValidationContext.Provider
      value={{
        onValidateDate,
        onValidateMethod,
        groupsChecking,
        recurringOverlaps,
        onValidateRecurringEndDate,
      }}
    >
      {children}
    </WithAssessmentFormValidationContext.Provider>
  );
};

export const useWithAssessmentFormValidation = () => {
  return useContext(WithAssessmentFormValidationContext);
};
