import {
  FilterSelectOption,
  getTypedObjectKeys,
  SchoolProperty,
  SimpleListResult,
} from '@schooly/api';
import { UserType } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { SchoolPropertyType } from '../../../../constants/school';
import { getUserTypeTextId } from '../../../../helpers/misc';
import { getOptionsForPropertyType } from '../../../../helpers/school';
import {
  LimitedToDropdownProps,
  LimitedToSelect,
} from '../../../Groups/AddGroupModal/LimitedToSelect/LimitedToSelect';
import { LimitedToCategory } from '../../../Groups/AddGroupModal/LimitedToSelect/LimitedToSelect';
import { getLimitedToCategoryKey } from '../../../Groups/AddGroupModal/LimitedToSelect/utils';
import {
  DEFAULT_LIMITED_TO,
  RoleUserType,
  UserRoleLimitationsUpdate,
} from '../Context/useUserRoleModal';
interface LimitedToSectionProps {
  type: RoleUserType;
  limitedTo: UserRoleLimitationsUpdate;
  hasLimitedTo?: boolean;
  checkingLimitedTo?: boolean;
  onLimitedToChange: (data: UserRoleLimitationsUpdate, type: UserType, isEffect?: boolean) => void;
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  schoolProperties: SchoolProperty[];
  selectedStuff: SimpleListResult[];
  requiredCategories?: LimitedToCategory[];
}

export const DEFAULT_LIMITED_TO_SECTION_TEXT_IDS = {
  statuses: 'schoolProperty-Status-plural',
};

export const LimitedToSection: React.FC<LimitedToSectionProps> = ({
  type,
  limitedTo,
  hasLimitedTo,
  checkingLimitedTo,
  onLimitedToChange,
  onOpen,
  onClose,
  schoolProperties,
  selectedStuff,
  requiredCategories,
}) => {
  const { $t } = useIntl();

  const isFieldTouched = useRef<Record<RoleUserType, boolean>>({
    staff: false,
  });

  const ref = useRef<LimitedToDropdownProps | null>(null);
  const { getConfirmation } = useConfirmationDialog();

  const handleLimitedToChange = useCallback(
    async (data: UserRoleLimitationsUpdate) => {
      isFieldTouched.current[type] = true;

      const hasStatus = data.school_property_ids.some((id) =>
        schoolProperties.find(
          (property) => property.id === id && property.type === SchoolPropertyType.Status,
        ),
      );

      // If no any status selected, the entire Limited To will be dropped
      // Will display a Confirmation dialog before that
      // (https://schooly.atlassian.net/browse/TR-4502)
      if (hasStatus || !selectedStuff?.length) {
        onLimitedToChange(data, type);
        return;
      }

      const isConfirmed = await getConfirmation({
        onClick: (e) => {
          e.stopPropagation();
        },
        textId: 'userRoles-LimitedToWarning',
        textValues: {
          number: $t({
            id: getUserTypeTextId(type, true),
          }).toLowerCase(),
        },
      });

      if (isConfirmed) {
        onLimitedToChange(DEFAULT_LIMITED_TO, type);
      }
    },
    [type, selectedStuff?.length, getConfirmation, $t, schoolProperties, onLimitedToChange],
  );

  const limitedCategories = useMemo(
    () =>
      (limitedTo.school_property_ids.length > 0
        ? Object.keys(DEFAULT_LIMITED_TO_SECTION_TEXT_IDS)
        : requiredCategories) as LimitedToCategory[],
    [limitedTo.school_property_ids.length, requiredCategories],
  );

  const limitedToOptions = useMemo(() => {
    const options: Partial<Record<LimitedToCategory, FilterSelectOption[]>> = {
      statuses: getOptionsForPropertyType(schoolProperties, SchoolPropertyType.Status),
    };

    return options;
  }, [schoolProperties]);

  const selectedLimitedToOptions = useMemo(
    () =>
      getTypedObjectKeys(limitedToOptions).reduce<
        Partial<Record<LimitedToCategory, FilterSelectOption[]>>
      >((acc, cat) => {
        const key = getLimitedToCategoryKey(cat);

        const values =
          limitedToOptions[cat]?.filter((o) => limitedTo[key]?.includes(o.value)) || [];

        return values.length ? { ...acc, [cat]: values } : acc;
      }, {}),
    [limitedTo, limitedToOptions],
  );

  return (
    limitedTo && (
      <div className="AddGroupModal__limited-to-section">
        <LimitedToSelect
          hasValues={!!hasLimitedTo}
          limitedTo={limitedTo}
          schoolProperties={schoolProperties}
          onChange={handleLimitedToChange}
          userType={type}
          ref={ref}
          isLoading={!!checkingLimitedTo}
          categories={limitedCategories}
          options={limitedToOptions}
          selectedOptions={selectedLimitedToOptions}
          onOpen={onOpen}
          onClose={onClose}
        />
      </div>
    )
  );
};
