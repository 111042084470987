import { Box } from '@mui/material';
import type { EChartsOption } from 'echarts';
import { graphic } from 'echarts';
import ReactECharts from 'echarts-for-react';
import React from 'react';
import { FC, PropsWithChildren } from 'react';

interface ChartBarEmptyProps extends PropsWithChildren {
  pending?: boolean;
}

const EMPTY_CHART_SERIES_DATA = [5.5, 6.5, 7, 5, 8.5, 6.5, 6, 9, 8, 7.5, 6, 6.5, 8.5, 4];

export const ChartBarEmpty: FC<ChartBarEmptyProps> = ({ children, pending }) => {
  const options: EChartsOption = {
    grid: {
      height: 320,
      width: '100%',
      left: 0,
      top: '7px',
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: 'transparent',
      },
    },
    xAxis: {
      type: 'category',
      data: [],
      axisLabel: {
        interval: 0,
        rotate: 0,
      },

      axisTick: {
        inside: false,
        alignWithLabel: true,
      },
      splitLine: {
        show: false,
      },
      splitArea: {
        show: false,
      },
    },
    emphasis: {
      scale: true,
      scaleSize: 10,
    },

    series: [
      {
        data: EMPTY_CHART_SERIES_DATA,
        type: 'bar',
        color: pending
          ? new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: '#F4F4F9',
              },

              {
                offset: 0,
                color: '#E7E9F4',
              },
            ])
          : '#F4F4F9',
        barGap: '20px',
      },
    ],
    legend: {
      orient: 'horizontal',
      top: 'bottom',
      icon: 'circle',
    },
  };

  return (
    <Box position="relative">
      <Box
        position="absolute"
        sx={(theme) => ({
          height: '100%',
          width: '100%',
          zIndex: theme.zIndex.drawer,
        })}
      >
        {children}
      </Box>
      <ReactECharts
        style={{
          width: '100%',
          height: 400,
        }}
        option={options}
      />
    </Box>
  );
};
