import { styled } from '@mui/material';

import { GridRowStyled } from '../../../components/uikit-components/Grid/Grid';

export const ProfileModalAttendanceGridItem = styled(GridRowStyled)(({ theme }) => ({
  width: 250,
  backgroundColor: 'transparent',

  '& .UIKit-TagGroup': {
    // TODO: should be the same in all places?
    ...theme.typography.body1,

    '& svg': {
      width: '1em',
      height: '1em',
      flexBasis: 'auto',
    },
  },

  '& .UIKit-TagSubject': {
    // TODO: should be the same in all places?
    ...theme.typography.body1,
    minWidth: 78,
  },
}));
