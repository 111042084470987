import { Stack } from '@mui/material';
import {
  AssessmentGroup,
  FilterKeys,
  GetGroupsQueryFilters,
  Group,
  GROUPS_QUERY_FILTER_KEYS,
  useGetGroupsQuery,
} from '@schooly/api';
import { pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { usePrevious } from '@schooly/hooks/use-previous';
import { SkeletonRowsComponent } from '@schooly/style';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import React, { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import HeaderFilter from '../../../../components/common/HeaderFilter/HeaderFilter';
import { NoSearchResultsFound } from '../../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { ModalPanel } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { useFilters } from '../../../../context/filters/useFilters';
import AssessmentsCreateModalGroupsItem from './AssessmentsCreateModalGroupsItem';

const PAGE_SIZE = 30;

type AssessmentsCreateModalGroupsRightSidebarProps = {
  selectedGroups: AssessmentGroup[];
  onCardClick: (group: Group) => void;
  onSetGroups: (groups: Group[]) => void;
  relationIds: string[];
  schoolId: string;
  startDate?: string;
  endDate?: string;
};

export const AssessmentsCreateModalGroupsRightSidebar: FC<AssessmentsCreateModalGroupsRightSidebarProps> =
  ({ selectedGroups, onCardClick, relationIds, startDate, schoolId, endDate }) => {
    const { $t } = useIntl();

    // TODO remake to new filters
    const { filters: legacyFilters } = useFilters();

    const filters: GetGroupsQueryFilters | undefined = useMemo(() => {
      const appliedFilter = legacyFilters.applied
        ? Object.entries(legacyFilters.applied).reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key === FilterKeys.StudentAgeGroup ? FilterKeys.AgeGroup : key]: val,
            }),
            {},
          )
        : {};
      return pickOnlyParamsFromFilterKeys(GROUPS_QUERY_FILTER_KEYS, {
        ...appliedFilter,
        single_date: startDate && !endDate ? [startDate] : undefined,
        date: !!startDate && !!endDate ? [startDate, endDate] : undefined,
      });
    }, [endDate, legacyFilters.applied, startDate]);

    const prevQuery = usePrevious(legacyFilters.draftQuery);

    const {
      data,
      hasNextPage,
      isFetchingNextPage,
      params,
      setParams,
      fetchNextPage,
      isLoading,
      isFetching,
    } = useGetGroupsQuery(
      {
        schoolId: schoolId ?? '',
        pageSize: PAGE_SIZE,
        filters,
        relationIds,
        query: legacyFilters.draftQuery,
      },
      {
        enabled: Boolean(schoolId),
      },
    );

    const total = data?.pages[0]?.count;

    const setQueryParamDebounced = useMemo(
      () =>
        debounce((query: string) => {
          setParams((params) => ({ ...params, query }));
        }, 300),
      [setParams],
    );

    useEffect(() => {
      if (isFetching) return;

      if (legacyFilters.draftQuery !== prevQuery) {
        setQueryParamDebounced(legacyFilters.draftQuery);
      }
    }, [isFetching, legacyFilters.draftQuery, prevQuery, setQueryParamDebounced]);

    useEffect(() => {
      if (isFetching || isEqual(params.filters, filters)) return;
      setParams((params) => ({ ...params, filters }));
    }, [filters, isFetching, params.filters, setParams]);

    const loaderRef = useInfiniteScroll(
      isLoading || isFetchingNextPage,
      fetchNextPage,
      hasNextPage,
    );

    const groups = useMemo(() => {
      return data?.pages
        .reduce<Group[]>((prev, curr) => [...prev, ...curr.results], [])
        ?.reduce((acc: Group[], cur: Group) => {
          const intersec = (selectedGroups as Group[])?.filter((s) => s.id === cur.id);

          if (intersec?.find((i) => i.id === cur.id)) {
            return acc;
          }

          return [...acc, cur];
        }, []);
    }, [data?.pages, selectedGroups]);

    const renderList = () => {
      return (
        <>
          {!isLoading && !groups?.length && <NoSearchResultsFound />}

          {groups?.length && (
            <div className="AssessmentsExternalSidebar__groups">
              {groups?.map((g) => {
                return (
                  <AssessmentsCreateModalGroupsItem
                    key={g.id}
                    onClick={() => onCardClick(g)}
                    group={g}
                    generateHref={(id) => `/groups/${id}`}
                  />
                );
              })}
            </div>
          )}

          {isLoading && <SkeletonRowsComponent rowCount={PAGE_SIZE} />}

          {hasNextPage && (
            <>
              <div ref={loaderRef} />
              <SkeletonRowsComponent
                rowCount={Math.min(
                  PAGE_SIZE,
                  total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                )}
              />
            </>
          )}
        </>
      );
    };

    return (
      <Stack sx={{ height: '100%' }}>
        <ModalHeader title={$t({ id: 'assessments-Groups' })} active />
        <ModalPanel active>
          <HeaderFilter
            searchPlaceholder={$t(
              { id: 'assessments-SearchAmongCount' },
              {
                assessmentsPlural: $t({ id: 'assessments-Groups' }).toLowerCase(),
              },
            )}
            modalTitleTextId="groups-Filter-Title"
            modal
            bottomInline
            isModalOpen
          />
        </ModalPanel>
        <ModalPanel sx={{ pt: 0, flex: '1 1 auto', overflowY: 'auto' }} active>
          {renderList()}
        </ModalPanel>
      </Stack>
    );
  };
