import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect, FarDateWarning, PropertyTypeSelect } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { DeleteIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { format, isBefore } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { AddEmploymentCaseForm, AddEmploymentCaseStatus } from './AddEmploymentCaseModal';

export type AddEmploymentCaseModalStatusesProps = {
  form: UseFormReturn<AddEmploymentCaseForm>;
  schoolId: string;
};

export const AddEmploymentCaseModalStatuses: FC<AddEmploymentCaseModalStatusesProps> = ({
  schoolId,
  form,
}) => {
  const { $t } = useIntl();
  const [dateChangedIndexes, setDateChangedIndexes] = useState<number[]>([]);

  const {
    fields: statusesFields,
    update: updateStatus,
    append: appendStatus,
    remove: removeStatus,
  } = useFieldArray({
    control: form.control,
    name: 'statuses',
  });

  const handleAddStatus = useCallback(() => {
    appendStatus({});
  }, [appendStatus]);

  const handleDateChange = (newStatus: AddEmploymentCaseStatus, newStatusIdx: number) => {
    const newStatusFields = statusesFields.map((f, idx) => (newStatusIdx === idx ? newStatus : f));

    newStatusFields.forEach((currentField, idx) => {
      if (!currentField.applies_from) return;

      const isCurrentFieldOverlaps = newStatusFields
        .slice(0, idx)
        .some(
          (prevField) =>
            prevField.applies_from &&
            currentField.applies_from &&
            isBefore(new Date(currentField.applies_from), new Date(prevField.applies_from)),
        );

      if (isCurrentFieldOverlaps && idx !== 0) {
        form.setError(`statuses.${idx}.applies_from`, {
          type: 'validate',
          message: 'error-StatusDateOrder',
        });

        return;
      }

      form.clearErrors(`statuses.${idx}.applies_from`);
    });

    updateStatus(newStatusIdx, newStatus);
  };

  return (
    <Stack gap={2}>
      <Typography variant="h4">
        <FormattedMessage id="schoolProperty-Status-plural" />
      </Typography>
      {statusesFields.map((statusField, i) => {
        const statusError = form.formState.errors['statuses']?.[i]?.school_property_id;
        const showFarDateWarning = dateChangedIndexes.includes(i);

        return (
          <Stack key={statusField.id} flexDirection="row" gap={1.25}>
            <Stack flex={1}>
              <PropertyTypeSelect
                adornmentLabel="required"
                hasError={!!statusError}
                value={statusField.school_property_id}
                userRole={SchoolUserRole.Staff}
                propertyType={SchoolPropertyType.Status}
                label={$t({ id: 'schoolProperty-Status' })}
                schoolId={schoolId}
                errorMessage={statusError ? $t({ id: statusError.message }) : undefined}
                onChange={(e) => {
                  form.clearErrors(`statuses.${i}.school_property_id`);
                  updateStatus(i, {
                    ...statusField,
                    school_property_id: e.target.value as string,
                  });
                }}
                fullWidth
              />
            </Stack>
            <Stack flex={1}>
              <Controller
                control={form.control}
                name={`statuses.${i}.applies_from`}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  const { value, ref } = field;

                  return (
                    <DateSelect
                      ref={ref}
                      onSetDate={(date) => {
                        handleDateChange(
                          { ...statusField, applies_from: format(date, DEFAULT_DATE_FORMAT_FNS) },
                          i,
                        );
                        setDateChangedIndexes((prev) => [...prev, i].sort());
                      }}
                      date={value}
                      placeholder={$t({ id: 'schoolProperty-Status-AppliesForm' })}
                      requiredLabel="required"
                      error={fieldState.error}
                      renderRightIcon={
                        showFarDateWarning && value
                          ? () => <FarDateWarning date={value} />
                          : undefined
                      }
                    />
                  );
                }}
              />
            </Stack>
            {statusesFields.length > 1 && (
              <IconButton
                sx={(theme) => ({
                  alignSelf: 'flex-start',
                  mt: 1.25,
                  color: theme.palette.common.grey,
                  '&:hover': { color: theme.palette.text.primary },
                })}
                onClick={() => {
                  removeStatus(i);
                  setDateChangedIndexes((prev) =>
                    prev.reduce<number[]>((acc, idx) => {
                      if (idx === i) return acc;
                      return [...acc, idx > i ? idx - 1 : idx];
                    }, []),
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        );
      })}
      {statusesFields.length < 5 && (
        <Box>
          <SimpleButton startIcon={<PlusIcon />} onClick={handleAddStatus}>
            {$t({ id: 'schoolProperty-AddStatus' })}
          </SimpleButton>
        </Box>
      )}
    </Stack>
  );
};
