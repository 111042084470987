import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1C2 0.447715 2.44772 0 3 0C3.55228 0 4 0.447715 4 1H10C10 0.447715 10.4477 0 11 0C11.5523 0 12 0.447715 12 1H13C13.5523 1 14 1.44772 14 2V4H0V2C0 1.44772 0.447716 1 1 1H2ZM14 5H0V14C0 14.5523 0.447716 15 1 15H8.53513C8.19479 14.4117 8 13.7286 8 13C8 10.7909 9.79086 9 12 9C12.7286 9 13.4117 9.19479 14 9.53513V5ZM15 13C15 11.3431 13.6569 10 12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16C13.6569 16 15 14.6569 15 13ZM11.5 13.375V11.125H12.5V12.875H13.5V13.875H12H11.5V13.375Z"
      fill="#24275B"
    />
  </svg>
);
