import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.631 13.561L16.8485 13.8386C17.1007 14.2867 17.0381 14.8778 16.6612 15.2564L15.6819 16.1885C14.7702 16.9037 13.5849 17.1654 12.4586 16.8965L12.4443 16.8929C11.2766 16.5803 10.1649 16.0826 9.15152 15.4174C8.2232 14.8238 7.36181 14.1295 6.58321 13.3464C5.8671 12.6353 5.22291 11.8539 4.66041 11.0136C4.03771 10.0977 3.54374 9.09853 3.19345 8.04405L3.19192 8.0394C2.7305 6.61597 3.11945 5.06616 4.16129 4.03995L4.88178 3.31947C5.3054 2.89455 5.97377 2.89345 6.39869 3.31656L6.39961 3.31747L6.40122 3.31909L8.68219 5.60004C9.13598 6.05242 9.13777 6.81222 8.68512 7.2668L8.68411 7.26781L7.34063 8.61126C7.17917 8.77097 7.16898 8.99663 7.29181 9.16465C7.75679 9.8028 8.27177 10.4038 8.83207 10.9622L8.83374 10.9638C9.45823 11.591 10.1371 12.1617 10.8638 12.6698C11.0815 12.8217 11.3809 12.7909 11.5594 12.6156L12.8539 11.3008C13.2775 10.8753 13.9462 10.874 14.3713 11.2973L14.3724 11.2984L14.3742 11.3002L16.631 13.561Z"
      fill="#B3BBCB"
    />
  </svg>
);
