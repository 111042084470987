import { styled, SxProps } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

export interface DataGridTableStyledProps {
  hoverStyles?: SxProps;
}

export const DataGridTableStyled = styled(DataGrid)<DataGridTableStyledProps>(
  ({ theme, hoverStyles }) => ({
    '&.MuiDataGrid-root': {
      border: 'none',
      '.MuiDataGrid-columnHeader': { p: 0 },
    },

    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '.MuiDataGrid-row.Mui-hovered': {
      backgroundColor: 'transparent',
    },

    '.MuiDataGrid-columnHeadersInner': {
      ...hoverStyles,
    },

    '.MuiDataGrid-virtualScrollerRenderZone, .MuiDataGrid-pinnedColumns': {
      ...hoverStyles,
    },

    '.MuiDataGrid-cell': {
      padding: 0,
    },
    '.MuiDataGrid-columnHeader': { padding: 0 },

    '.MuiDataGrid-columnHeaderTitleContainerContent': {
      width: '100%',
    },

    '.MuiDataGrid-cell.MuiDataGrid-cell--editing': {
      boxShadow: 'none',
    },

    '.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
      outlineColor: theme.palette.primary.main,
    },

    '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },

    '.MuiDataGrid-row': {
      ' .MuiDataGrid-cell.MuiDataGrid-withBorderColor': {
        borderBottom: 'none',
      },
      ':not(:last-child)': {
        ' .MuiDataGrid-cell.MuiDataGrid-withBorderColor': {
          borderBottom: theme.mixins.borderValue(),
        },
      },
    },

    '.MuiDataGrid-withBorderColor': {
      '&.MuiDataGrid-columnHeaders': {
        borderBottom: theme.mixins.borderValue(),
      },
    },
    '.MuiDataGrid-pinnedColumns': {
      boxShadow: 'none',
      overflow: 'visible',
    },
    '.MuiDataGrid-pinnedColumnHeaders': {
      height: '100%',
      justifyContent: 'center',
      boxShadow: 'none',
    },
    '.MuiStack-root': {
      width: '100%',
    },
  }),
);
