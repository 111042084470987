import { AnnualPlanRecordTypes, useGetAssessmentQuery } from '@schooly/api';
import React, { FC, useMemo } from 'react';

import { AssessmentForCreateForm } from '../../../../Assessments/AssessmentsCreateModal/AssessmentCreateContent';
import { AssessmentsCreateModal } from '../../../../Assessments/AssessmentsCreateModal/AssessmentsCreateModal';
import { AssessmentsCreateModalMode } from '../../../../Assessments/AssessmentsCreateModal/useAssessmentCreateModal';
import { useAnnualPlannerCreateAssessment } from '../forms/WithAnnualPlannerCreateAssessment';
import { useAnnualPlannerCreateRecord } from '../WithAnnualPlannerCreate';

export const AnnualPlannerExpandedAssessment: FC = () => {
  const { record, expandedData, close } = useAnnualPlannerCreateRecord();
  const { onPreview } = useAnnualPlannerCreateAssessment();

  const { data, isFetching } = useGetAssessmentQuery(
    { id: record?.details.id ?? '' },
    {
      enabled: Boolean(record?.details.id && record.type === AnnualPlanRecordTypes.ASSESSMENT),
      refetchOnMount: 'always',
    },
  );

  const assessment = useMemo<AssessmentForCreateForm>(() => {
    if (data) {
      const useAssessmentNameAsDisplayName = data.name === data.display_name;

      return {
        ...data,
        name: expandedData?.assessment.name ?? '',
        assessment_date: expandedData?.date[0] ?? '',
        useAssessmentNameAsDisplayName,

        ...(useAssessmentNameAsDisplayName && {
          display_name: expandedData?.assessment.name ?? '',
        }),

        ...(expandedData?.assessment.groups && {
          mode: AssessmentsCreateModalMode.Groups,
          selectedGroups: expandedData.assessment.groups,
        }),
      };
    } else {
      return {
        groups: [],
        methods: [],
        recurring_state: null,
        autogenerated: false,

        name: expandedData?.assessment.name ?? '',
        display_name: expandedData?.assessment.name ?? '',
        useAssessmentNameAsDisplayName: true,
        assessment_date: expandedData?.date[0] ?? '',
      };
    }
  }, [data, expandedData?.assessment.groups, expandedData?.assessment.name, expandedData?.date]);

  return (
    <AssessmentsCreateModal
      id={record?.details.id}
      assessment={assessment}
      isFetching={isFetching}
      onClose={close}
      onPreview={onPreview}
    />
  );
};
