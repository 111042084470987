import { EyeCloseIcon, EyeIcon } from '@schooly/style';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import buildClassName from '../../../utils/buildClassName';
import Hint from '../../common/Hint';
import { InputType } from './FormInput';
import { PasswordInputType } from './PasswordInput';
import { BaseFormInputProps, getInputErrorText } from './utils';

export function renderLabel(
  props: Omit<BaseFormInputProps, 'name'> & {
    type?: InputType | PasswordInputType;
    inputType?: PasswordInputType;
  },
  renderAsLabel?: boolean,
  renderAsMultiLabel?: boolean,
  onEyeClick?: () => void,
) {
  const {
    labelText,
    labelTextId,
    required,
    noRequiredLabel,
    id,
    hint,
    type,
    inputType,
    multiLabelTextId,
    disabled,
  } = props;

  if (!labelText && !labelTextId) {
    return null;
  }

  function getRequiredLabel() {
    const hintIcon = hint ? <Hint className="hint-icon">{hint}</Hint> : null;

    if (noRequiredLabel) {
      return hintIcon;
    }

    const fullClassName = buildClassName(
      'form-group__required-label',
      type !== 'password' && !hintIcon && 'form-group__required-label--without-icon',
    );

    return (
      <span className={fullClassName}>
        {!disabled && (
          <span className="required_label">
            <FormattedMessage id={required ? 'input-required' : 'input-optional'} />
          </span>
        )}
        {type === 'password' && (
          <>
            <button type="button" className="btn eye-button" onClick={onEyeClick}>
              {inputType === 'password' ? <EyeIcon /> : <EyeCloseIcon />}
            </button>
          </>
        )}
        {hintIcon}
      </span>
    );
  }

  if (renderAsLabel) {
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={id} className="form-group__label">
          {labelText ?? <FormattedMessage id={labelTextId} />}
        </label>
        {getRequiredLabel()}
      </>
    );
  }

  return (
    <>
      <span className="form-group__label">
        {labelText ?? (
          <FormattedMessage
            id={renderAsMultiLabel ? multiLabelTextId ?? labelTextId : labelTextId}
          />
        )}
      </span>
      {getRequiredLabel()}
    </>
  );
}

export function renderPlainError(error?: React.ReactNode) {
  if (!error) {
    return null;
  }

  return <div className="form-group__error text-small text-danger">{error}</div>;
}

export function renderError(error?: FieldError) {
  if (!error) {
    return null;
  }

  return renderPlainError(<FormattedMessage {...getInputErrorText(error)} />);
}
