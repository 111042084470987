import { Typography, TypographyProps } from '@mui/material';
import { RecurringState } from '@schooly/api';
import { FC } from 'react';

import { useRecurringStateLabel } from './hooks';

type RecurringLabelProps = {
  recurringState: RecurringState;
  shortDays?: boolean;
} & TypographyProps;

export const RecurringLabel: FC<RecurringLabelProps> = ({
  recurringState,
  shortDays = false,
  ...props
}) => {
  const label = useRecurringStateLabel({
    shortDays,
    ...recurringState,
  });

  return (
    <Typography color="text.primary" {...props} sx={{ whiteSpace: 'normal' }}>
      {label}
    </Typography>
  );
};
