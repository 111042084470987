import { debounce, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { usePrevious } from '@schooly/hooks/use-previous';
import { ChevronLeftIcon, ChevronRightIcon } from '@schooly/style';
import {
  add,
  eachDayOfInterval,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameWeek,
  parse,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CalendarRecord } from '../scheme';
import {
  CalendarWeekGridColumnStyled,
  CalendarWeekGridColumnTimeStyled,
  CalendarWeekGridControls,
  CalendarWeekGridControlsButtonNext,
  CalendarWeekGridControlsButtonPrev,
  CalendarWeekGridSection,
  CalendarWeekGridStyled,
  CalendarWeekStyled,
} from './CalendarWeek.styled';
import { CalendarWeekColumn } from './CalendarWeekColumn';
import { CalendarWeekColumnTime } from './CalendarWeekColumnTime';
import { CalendarWeekHeaderCell } from './CalendarWeekHeaderCell';

export interface CalendarWeekProps {
  startDate?: Date | string;
  minDate?: Date | string;
  maxDate?: Date | string;
  records?: CalendarRecord[];
  showTimeline?: boolean;
}

const getWeekFromDate = (date: Date | string) =>
  startOfWeek(typeof date === 'string' ? parse(date, DEFAULT_DATE_FORMAT_FNS, new Date()) : date, {
    weekStartsOn: 1,
  });

const getClosestMonth = (date: Date | string) => {
  const start = getWeekFromDate(date);
  const end = add(start, { weeks: 1 });

  if (start.getMonth() !== end.getMonth()) {
    const days = eachDayOfInterval({ start, end });
    const lastDay = days[0];

    for (let i = 1; i < days.length; i++) {
      if (lastDay.getMonth() !== days[i].getMonth()) {
        return startOfMonth(i < 3 ? end : start);
      }
    }
  }

  return startOfMonth(start);
};

export const CalendarWeek: FC<CalendarWeekProps> = ({
  startDate,
  minDate,
  maxDate,
  records,
  showTimeline = true,
}) => {
  const refGrid = useRef<HTMLDivElement>(null);
  const refTimeline = useRef<HTMLDivElement>(null);
  const refPrevWeek = useRef<HTMLDivElement>(null);
  const refCurrWeek = useRef<HTMLDivElement>(null);
  const refNextWeek = useRef<HTMLDivElement>(null);
  const [currDate, setCurrDate] = useState<Date>(getWeekFromDate(startDate ?? new Date()));
  const [currMonth, setCurrMonth] = useState<Date>(getClosestMonth(currDate));

  /** updates current month date */
  useEffect(() => {
    setCurrMonth(getClosestMonth(currDate));
  }, [currDate]);

  const prevCurrDate = usePrevious(currDate);

  const [weeks, days, weeksCount] = useMemo(() => {
    const minWeek = minDate ? getWeekFromDate(minDate) : undefined;
    const maxWeek = maxDate ? getWeekFromDate(maxDate) : undefined;

    const currWeek = currDate;

    const prevWeek = sub(currWeek, { weeks: 1 });
    const nextWeek = add(currWeek, { weeks: 1 });

    const weeks = [
      !minWeek || isBefore(minWeek, prevWeek) || isSameWeek(minWeek, prevWeek)
        ? prevWeek
        : undefined,

      currWeek,

      !maxWeek || isAfter(maxWeek, nextWeek) || isSameWeek(maxWeek, nextWeek)
        ? nextWeek
        : undefined,
    ];

    const days = weeks
      .reduce<Date[]>((prev, week) => {
        if (!week) {
          return prev;
        }

        const weekDays = new Array(7).fill(true).map((_, index) => add(week, { days: index }));
        return [...prev, ...weekDays];
      }, [])
      .map((date) => ({
        date,
        records:
          records?.filter((record) =>
            isSameDay(
              typeof record.date === 'string'
                ? parse(record.date, DEFAULT_DATE_FORMAT_FNS, new Date())
                : record.date,
              date,
            ),
          ) ?? [],
      }));

    return [weeks, days, weeks.filter(Boolean).length];
  }, [currDate, maxDate, minDate, records]);

  const prevWeeks = usePrevious(weeks);

  /** Initially scrolls the grid to the current week (the middle one) */
  useEffect(() => {
    if (refGrid.current && refTimeline.current && refCurrWeek.current) {
      refGrid.current.scroll({
        left: refCurrWeek.current.offsetLeft - refTimeline.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  }, []);

  /**
   * Observes currDate change and adjusts scroll offset, as after currDate change
   * a new week is added, but the scroll position is still on an edge which causes.
   * Need to scroll the grid left or right for 7 days to keep the viewport in the same position.
   */
  useEffect(() => {
    if (!refGrid.current || !refCurrWeek.current) {
      return;
    }

    if (
      prevCurrDate &&
      prevCurrDate !== currDate &&
      weeks.filter(Boolean).length === 3 &&
      prevWeeks?.[0]
    ) {
      refGrid.current!.scrollLeft += isBefore(currDate, prevCurrDate)
        ? 7 * refCurrWeek.current!.offsetWidth + 2
        : -7 * refCurrWeek.current!.offsetWidth - 2;
    }
  }, [currDate, prevCurrDate, prevWeeks, weeks]);

  /**
   * Listens to a scroll event, defines current visible week and adds a new one to the
   * left or the right, if needed
   */
  useEffect(() => {
    if (!refGrid.current) {
      return;
    }

    const grid = refGrid.current;

    const onScroll = debounce((event: Event) => {
      if (!refGrid.current || !refTimeline.current) {
        return;
      }

      /*
       * Find current week.
       * The current week, is a week which is visible more than a half in a viewport.
       *
       * [--offsetLeft--][-mon-][-tue-][-wed-][-thu-][-fri-][-sut-][-sun-][>== next week ===]
       * ----------- visible --------------------^
       */
      const index = [refPrevWeek.current, refCurrWeek.current, refNextWeek.current].findIndex(
        (week) =>
          week &&
          week.offsetLeft + week.offsetWidth * 3.5 >=
            refGrid.current!.scrollLeft + refTimeline.current!.offsetWidth,
      );

      if (index === -1) {
        return;
      }

      if (index === 1) {
        // current week, no need to change the date
        return;
      }

      // add or subtract a week from current date
      setCurrDate((currDate) => add(currDate, { weeks: index === 0 ? -1 : 1 }));
    }, 300);

    grid.addEventListener('scroll', onScroll);

    return () => {
      grid.removeEventListener('scroll', onScroll);
    };
  }, []);

  const scrollToPrevWeek = useCallback(() => {
    if (refGrid.current && refTimeline.current && refPrevWeek.current) {
      refGrid.current.scroll({
        left: refPrevWeek.current.offsetLeft - refTimeline.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  }, []);

  const scrollToNextWeek = useCallback(() => {
    if (refGrid.current && refTimeline.current && refNextWeek.current) {
      refGrid.current.scroll({
        left: refNextWeek.current.offsetLeft - refTimeline.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <CalendarWeekStyled>
      <Typography variant="h3" p={1} textAlign="center">
        {format(currMonth, 'MMMM yyyy')}
      </Typography>
      <CalendarWeekGridStyled ref={refGrid}>
        <CalendarWeekGridControls>
          {weeks[0] && (
            <CalendarWeekGridControlsButtonPrev inverse onClick={scrollToPrevWeek}>
              <ChevronLeftIcon />
            </CalendarWeekGridControlsButtonPrev>
          )}
          {weeks[2] && (
            <CalendarWeekGridControlsButtonNext inverse onClick={scrollToNextWeek}>
              <ChevronRightIcon />
            </CalendarWeekGridControlsButtonNext>
          )}
        </CalendarWeekGridControls>

        <CalendarWeekGridSection
          weeksCount={weeksCount}
          sx={{
            // Stick to top on scroll
            position: 'sticky',
            top: 0,
            bgcolor: 'background.paper',
            zIndex: 102,
          }}
        >
          {/* To keep the same space to left as the Timeline below */}
          <CalendarWeekGridColumnTimeStyled ref={refTimeline} />

          {days.map((day, index) => (
            <CalendarWeekGridColumnStyled
              key={index}
              weeksCount={weeksCount}
              ref={
                weeks[0] && isSameDay(day.date, weeks[0])
                  ? refPrevWeek
                  : weeks[1] && isSameDay(day.date, weeks[1])
                  ? refCurrWeek
                  : weeks[2] && isSameDay(day.date, weeks[2])
                  ? refNextWeek
                  : undefined
              }
            >
              <CalendarWeekHeaderCell date={day.date} />
            </CalendarWeekGridColumnStyled>
          ))}
        </CalendarWeekGridSection>
        <CalendarWeekGridSection weeksCount={weeksCount}>
          {/* Timeline column */}
          <CalendarWeekColumnTime days={days} weeksCount={weeksCount} showTimeline={showTimeline} />

          {days.map(({ records }, index) => (
            <CalendarWeekColumn key={index} weeksCount={weeksCount} records={records} />
          ))}
        </CalendarWeekGridSection>
      </CalendarWeekGridStyled>
    </CalendarWeekStyled>
  );
};
