import { SchoolProperty, StudentRegistration } from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isNotEmpty } from '@schooly/utils/predicates';
import { areIntervalsOverlapping } from 'date-fns';
import { useCallback, useState } from 'react';

import { ActiveRegistrations, RegistrationForm } from './StudentRegistrationForm';

export const useRegistrationValidation = ({
  schoolStatuses,
}: {
  schoolStatuses: SchoolProperty[];
}) => {
  const [registrationConflicts, setRegistrationConflicts] = useState<ActiveRegistrations[]>();

  const validateRegistrations = useCallback(
    (params: Omit<ValidateExistedRegistrationsParams, 'schoolStatuses'>) => {
      const { registrations, yearEndDate } = params;
      const nonEmptyStatuses = schoolStatuses?.length ? schoolStatuses.filter(isNotEmpty) : null;

      if (!yearEndDate || !nonEmptyStatuses?.length || !registrations?.length) {
        setRegistrationConflicts([]);
        return [];
      }

      const conflicts = validateExistingRegistrations({ ...params, schoolStatuses });

      setRegistrationConflicts(conflicts);
      return conflicts;
    },
    [schoolStatuses],
  );

  return {
    validateRegistrations,
    registrationConflicts,
  };
};

type ValidateExistedRegistrationsParams = {
  form: RegistrationForm;
  registrations: StudentRegistration[];
  schoolStatuses: SchoolProperty[];
  yearEndDate?: string;
};

export const validateExistingRegistrations = ({
  form,
  registrations: studentRegistrations,
  schoolStatuses,
  yearEndDate,
}: ValidateExistedRegistrationsParams) => {
  const conflicts: ActiveRegistrations[] = [];
  const activeRegistrations = getActiveRegistrations(studentRegistrations);

  form.statuses.forEach((status, i, statusesArr) => {
    if (!status.school_property_id || !status.applies_from) return;

    const schoolStatus = schoolStatuses.find((p) => p.id === status.school_property_id);
    if (!schoolStatus) return;

    const statusStartDate = status.applies_from;

    const closeStatus = statusesArr
      .slice(i + 1)
      .find(
        (s) =>
          !!s.school_property_id &&
          schoolStatuses.some((p) => p.id === s.school_property_id && p.category?.final),
      );

    const statusEndDate = closeStatus?.applies_from || yearEndDate;
    if (!statusEndDate) return;

    for (const reg of activeRegistrations) {
      // Already have this conflict
      if (conflicts.some((c) => c.id === reg.id)) continue;

      // Check for category is final that are not validated
      if (reg.school_property.category?.final) continue;

      // Check for props are different
      if (reg.year.id !== form.school_year_id) continue;

      // Category is different
      if (schoolStatus.category?.id !== reg.school_property.category?.id) continue;

      // Check for house and age group are different for prospective status category
      if (!schoolStatus.category?.current) {
        if (form.house_property_id && form.house_property_id !== reg.house?.id) continue;
        if (form.age_group_property_id && form.age_group_property_id !== reg.ageGroup?.id) continue;
      }

      const isStatusDateOverlaps = areIntervalsOverlapping(
        {
          start: newDateTimezoneOffset(statusStartDate),
          end: newDateTimezoneOffset(statusEndDate),
        },
        {
          start: newDateTimezoneOffset(reg.applies_from),
          end: newDateTimezoneOffset(reg.applies_to),
        },
      );

      if (!reg.applies_to || isStatusDateOverlaps) conflicts.push(reg);
    }
  });
  return conflicts;
};

const getActiveRegistrations = (registrations: StudentRegistration[]) => {
  if (!registrations.length) return [];

  const activeRegistrations: ActiveRegistrations[] = [];

  for (const registration of registrations) {
    for (const status of registration.statuses) {
      // Do not treat former categories as active
      if (status.school_property.category?.final) continue;

      activeRegistrations.push({
        ...status,
        registrationId: registration.id,
        year: registration.school_year,
        ageGroup: registration.school_properties.find(
          (p) => p.type === SchoolPropertyType.AgeGroup,
        ),
        house: registration.school_properties.find((p) => p.type === SchoolPropertyType.House),
      });
    }
  }

  return activeRegistrations;
};
