import { Card, CardContent, CardHeader } from '@mui/material';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { GridContainer } from '../../../components/uikit-components/Grid/Grid';
import { SchoolFiltersRow } from './SchoolFiltersRow';

export const SchoolFiltersContent: FC = () => {
  const { $t } = useIntl();

  return (
    <Card>
      <CardHeader title={$t({ id: 'school-sections-Filters-DefaultFilters' })} />

      <CardContent>
        <GridContainer
          sx={{
            borderRadius: 0,
          }}
        >
          <SchoolFiltersRow
            changeTypes={['student_default']}
            title={$t({ id: 'school-sections-Filters-StudentsAndParents' })}
            studentStatuses
            houses
            ageGroups
          />

          <SchoolFiltersRow
            changeTypes={['staff_default']}
            title={$t({ id: 'school-sections-Filters-Staff' })}
            staffStatuses
            houses
            departments
          />

          <SchoolFiltersRow
            changeTypes={['group_default']}
            title={$t({ id: 'school-sections-Filters-Groups' })}
            studentStatuses
            staffStatuses
            houses
            ageGroups
          />

          <SchoolFiltersRow
            changeTypes={['conduct_default']}
            title={$t({ id: 'school-sections-Filters-Conduct' })}
            houses
            ageGroups
          />
        </GridContainer>
      </CardContent>
    </Card>
  );
};
