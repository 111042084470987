import { Stack, Typography } from '@mui/material';
import { FilterKeys, useGetGroupSubjectsQuery } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectOptionsArchivedEmptyStub } from '../SelectOptionsArchivedEmptyStub';
import { SelectOptionsArchivedIcon } from '../SelectOptionsArchivedIcon';
import { SelectSearchInput } from '../SelectSearchInput';
import { renderSubjectTags } from './SubjectExpandedSelect';

type SubjectSelectMultipleProps = {
  schoolId: string;
  selectedIds: string[];
  onSelectSubjectId: (v: string) => void;
  optionsTitle?: string;
  isAdmin?: boolean;
  allowArchived?: boolean;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const SubjectSelectMultiple: FC<SubjectSelectMultipleProps> = ({
  schoolId,
  selectedIds,
  onSelectSubjectId,
  allowArchived,
  placeholder,
  optionsTitle,
  onClear,
  isAdmin,
  ...dropdownProps
}) => {
  const { getConfirmation } = useConfirmationDialog();
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdown = useRef<DropdownSelect | null>(null);
  const [query, setQuery] = useState('');
  const [queryEnabled, setQueryEnabled] = useState(false);

  const { subjects, activeSubjects, archivedSubjects, isLoading } = useSubjects(
    { schoolId },
    { refetchOnMount: 'always' },
  );

  const { data } = useGetGroupSubjectsQuery(
    { schoolId, showArchived: allowArchived },
    { enabled: queryEnabled },
  );

  const showAllArchivedRightIcon =
    !allowArchived && !selectedIds.length && !activeSubjects.length && archivedSubjects.length > 0;
  const showAllArchivedEmptyStub = !allowArchived && !!selectedIds.length && !activeSubjects.length;
  const showConfirmationBeforeClear =
    onClear &&
    !allowArchived &&
    archivedSubjects.find((subject) => selectedIds.includes(subject.id));

  const handleSelectSubjectId = useCallback(
    async (id: string) => {
      const subject = subjects.find((prop) => prop.id === id);

      if (
        !allowArchived &&
        subject?.archived &&
        !(await getConfirmation({
          textId: 'deselect-subject-archived',
          textValues: { name: subject.name },
        }))
      ) {
        return;
      }
      onSelectSubjectId(id);
    },
    [allowArchived, getConfirmation, onSelectSubjectId, subjects],
  );

  const onClearArchivedSubjects = useCallback(async () => {
    if (onClear) {
      const isConfirmed = await getConfirmation({
        textId: 'deselect-subjects-archived',
      });
      if (isConfirmed) {
        onClear();
      }
    }
  }, [getConfirmation, onClear]);

  const renderAllArchivedIcon = useCallback(
    () => <SelectOptionsArchivedIcon isAdmin={!!isAdmin} type={FilterKeys.Subject} />,
    [isAdmin],
  );

  const allArchivedEmptyStub = useMemo(() => {
    return <SelectOptionsArchivedEmptyStub isAdmin={!!isAdmin} type={FilterKeys.Subject} />;
  }, [isAdmin]);

  const renderContent = useCallback(() => {
    if (isLoading) return <SelectContentSkeleton />;

    const result = (data?.subjects || []).filter((s) =>
      s.name.toLowerCase().includes(query.toLowerCase()),
    );

    if (!result.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <Stack gap={1} m={1}>
        {optionsTitle && <Typography variant="h4">{optionsTitle}</Typography>}
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {showAllArchivedEmptyStub && allArchivedEmptyStub}
          {renderSubjectTags({
            ids: activeSubjects.map((s) => s.id),
            tagProps: (id) => ({
              schoolId,
              variant: selectedIds?.includes(id) ? 'filled' : undefined,
            }),
            onClick: onSelectSubjectId,
          })}
        </Stack>

        {allowArchived && archivedSubjects.length > 0 && (
          <>
            <Typography variant="h4" mt={1} ml={1}>
              <FormattedMessage id="filter-Archived" />
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {renderSubjectTags({
                ids: archivedSubjects.map((s) => s.id),
                tagProps: (id) => ({
                  schoolId,
                  variant: selectedIds?.includes(id) ? 'filled' : undefined,
                }),
                onClick: onSelectSubjectId,
              })}
            </Stack>
          </>
        )}
      </Stack>
    );
  }, [
    isLoading,
    data?.subjects,
    optionsTitle,
    showAllArchivedEmptyStub,
    allArchivedEmptyStub,
    activeSubjects,
    onSelectSubjectId,
    allowArchived,
    archivedSubjects,
    query,
    schoolId,
    selectedIds,
  ]);

  return (
    <DropdownSelect
      {...dropdownProps}
      renderRightIcon={showAllArchivedRightIcon ? renderAllArchivedIcon : undefined}
      onClear={showConfirmationBeforeClear ? onClearArchivedSubjects : onClear}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={placeholder}
      hasValues={selectedIds.length > 0}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
    >
      {(opened) => (
        <>
          {renderSubjectTags({
            ids: selectedIds,
            tagProps: { schoolId, size: placeholder ? 'small' : undefined, sx: { maxWidth: 200 } },
            onDelete: opened ? handleSelectSubjectId : undefined,
          })}
          {opened && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={query || ''}
              onChangeText={setQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
