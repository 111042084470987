import { InboxMessage, Message } from '@schooly/api';
import { format, isThisYear, isToday } from 'date-fns';

import { retrieveApiDate } from './misc';

export function formatMessageDate(date?: Date) {
  if (!date) return null;

  if (isToday(date)) {
    return format(date, 'H:mm');
  }

  if (isThisYear(date)) {
    return format(date, 'd MMM');
  }

  return format(date, 'd MMM yy');
}

export function getMessagePublishDate(message?: Message | InboxMessage) {
  if (!message || !message.publish_date) return undefined;

  return retrieveApiDate(message.publish_date);
}

export function getFormattedPublishDate(message?: Message | InboxMessage) {
  const publishDate = getMessagePublishDate(message);
  return formatMessageDate(publishDate);
}
