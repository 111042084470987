import { ApplicationShort, Child } from '@schooly/api';

import searchWords from '../utils/searchWords';
import { getUserFullName } from './users';

export function searchByName({
  searchQuery,
  ...userData
}: Pick<Child, 'given_name' | 'last_name'> & { searchQuery: string }) {
  return searchWords(getUserFullName(userData), searchQuery);
}

export function filterApplications(applications: ApplicationShort[], searchQuery: string) {
  return applications?.filter((apl) => {
    return (
      apl.adults.some((p) => searchByName({ ...p, searchQuery })) ||
      apl.children.some((c) => searchByName({ ...c, searchQuery }))
    );
  });
}
