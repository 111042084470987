import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.2473 19.0086C30.8542 25.851 24.8029 31 17.5491 31C10.65 31 4.8388 26.3424 3.08832 20M32.2492 13.0006C30.8596 6.15359 24.8062 1 17.5491 1C10.6483 1 4.83594 5.65993 3.08703 12.0047M28.8368 21.0044L32.0786 19.0218C32.1728 18.9641 32.2959 18.9938 32.3535 19.088L34.3362 22.3298M6.49939 10.0041L3.2576 11.9868C3.16337 12.0444 3.04026 12.0147 2.98263 11.9205L1 8.67869"
      stroke="#F2994A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
