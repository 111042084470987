import { useMutation, useQuery } from '@tanstack/react-query';

import { ApiError } from './apiTypes/misc';
import { RQUseMutationOptions, RQUseMutationResult, RQUseQueryOptions } from './apiTypes/query';
import {
  RegistrationStatusUpdate,
  StaffRegistration,
  StaffRegistrationUpdate,
  StudentRegistration,
  StudentRegistrationUpdate,
} from './apiTypes/registrations';
import * as api from './requests';

const ENROLLMENTS_URL = '/registration/enrollments';
const EMPLOYMENTS_URL = '/registration/employments';

export const GET_ENROLLMENTS_QUERY = `${ENROLLMENTS_URL}/GET_ENROLLMENTS_QUERY`;

export type GetEnrollmentsResponse = {
  enrollments: StudentRegistration[];
  rollover_in_process?: boolean;
};

export function getEnrollments(
  schoolId: string,
  studentId: string,
): Promise<GetEnrollmentsResponse> {
  return api.get(`${ENROLLMENTS_URL}?school_id=${schoolId}&student_id=${studentId}`);
}

export const useGetEnrollments = (
  params: { schoolId: string; studentId: string },
  options?: RQUseQueryOptions<GetEnrollmentsResponse>,
) => {
  return useQuery<GetEnrollmentsResponse, ApiError>(
    [GET_ENROLLMENTS_QUERY, params],
    () => getEnrollments(params.schoolId, params.studentId),
    {
      ...options,
    },
  );
};

export function addEnrollment(
  schoolId: string,
  studentId: string,
  enrollment: StudentRegistrationUpdate,
): Promise<{ enrollment: StudentRegistration }> {
  return api.post(ENROLLMENTS_URL, {
    school_id: schoolId,
    student_id: studentId,
    enrollment,
  });
}

interface AddEnrollmentMutationParams {
  schoolId: string;
  studentId: string;
  enrollment: StudentRegistrationUpdate;
}

export const useAddEnrollmentMutation = (
  options?: RQUseMutationOptions<any, AddEnrollmentMutationParams>,
): RQUseMutationResult<any, AddEnrollmentMutationParams> => {
  return useMutation(
    (params: AddEnrollmentMutationParams) =>
      addEnrollment(params.schoolId, params.studentId, params.enrollment),
    options,
  );
};

export function updateEnrollment(
  enrollmentId: string,
  enrollment: StudentRegistrationUpdate,
): Promise<{ enrollment: StudentRegistration }> {
  return api.patch(`${ENROLLMENTS_URL}/${enrollmentId}`, { enrollment });
}

interface UpdateEnrollmentMutationParams {
  enrollmentId: string;
  enrollment: StudentRegistrationUpdate;
}

export const useUpdateEnrollmentMutation = (
  options?: RQUseMutationOptions<any, UpdateEnrollmentMutationParams>,
): RQUseMutationResult<any, UpdateEnrollmentMutationParams> => {
  return useMutation(
    (params: UpdateEnrollmentMutationParams) =>
      updateEnrollment(params.enrollmentId, params.enrollment),
    options,
  );
};

export function deleteEnrollment(enrollmentId: string): Promise<{}> {
  return api.remove(`${ENROLLMENTS_URL}/${enrollmentId}`);
}

export const useDeleteEnrollmentMutation = (
  options?: RQUseMutationOptions<any, string>,
): RQUseMutationResult<any, string> => {
  return useMutation(deleteEnrollment, options);
};

export interface ReEnrollmentClosedRegistration
  extends Omit<StudentRegistrationUpdate, 'statuses'> {
  id: string;
  statuses: Array<RegistrationStatusUpdate & { read_only?: boolean }>;
}

type UpdateReEnrollmentParams<P> = {
  reEnrollmentId: string;
} & P;

type UpdateReEnrollmentRequestParams = UpdateReEnrollmentParams<
  { type: 'accepted' } | { type: 'rejected'; enrollment: ReEnrollmentClosedRegistration }
>;

export function updateReEnrollment({
  reEnrollmentId,
  ...params
}: UpdateReEnrollmentRequestParams): Promise<{ success: string }> {
  return api.patch(`${ENROLLMENTS_URL}/re-enrollment/${reEnrollmentId}`, params);
}

export const useUpdateReEnrollment = (
  options?: RQUseMutationOptions<{ success: string }, UpdateReEnrollmentRequestParams>,
): RQUseMutationResult<{ success: string }, UpdateReEnrollmentRequestParams> => {
  return useMutation(
    (params: UpdateReEnrollmentRequestParams) => updateReEnrollment(params),
    options,
  );
};

export function getEmployments(
  schoolId: string,
  staffId: string,
): Promise<{ employments: StaffRegistration[] }> {
  return api.get(`${EMPLOYMENTS_URL}?school_id=${schoolId}&staff_id=${staffId}`);
}

export const GET_EMPLOYMENTS_QUERY = `${EMPLOYMENTS_URL}/GET_EMPLOYMENTS_QUERY`;

export const useGetEmploymentsQuery = (
  params: { schoolId: string; staffId: string },
  options?: RQUseQueryOptions<{ employments: StaffRegistration[] }>,
) => {
  return useQuery<{ employments: StaffRegistration[] }, ApiError>(
    [GET_EMPLOYMENTS_QUERY, params],
    () => getEmployments(params.schoolId, params.staffId),
    {
      ...options,
    },
  );
};

export function addEmployment(
  schoolId: string,
  staffId: string,
  employment: StaffRegistrationUpdate,
): Promise<{ employment: StaffRegistration }> {
  return api.post(EMPLOYMENTS_URL, {
    school_id: schoolId,
    staff_id: staffId,
    employment,
  });
}

type AddEmploymentMutationParams = {
  schoolId: string;
  staffId: string;
  employment: StaffRegistrationUpdate;
};

export const useAddEmploymentMutation = (
  options?: RQUseMutationOptions<any, AddEmploymentMutationParams>,
): RQUseMutationResult<any, AddEmploymentMutationParams> => {
  return useMutation(
    (params: AddEmploymentMutationParams) =>
      addEmployment(params.schoolId, params.staffId, params.employment),
    options,
  );
};

export function updateEmployment(
  employmentId: string,
  employment: StaffRegistrationUpdate,
): Promise<{ employment: StaffRegistration }> {
  return api.patch(`${EMPLOYMENTS_URL}/${employmentId}`, { employment });
}

type UpdateEmploymentMutationParams = {
  employmentId: string;
  employment: StaffRegistrationUpdate;
};

export const useUpdateEmploymentMutation = (
  options?: RQUseMutationOptions<any, UpdateEmploymentMutationParams>,
): RQUseMutationResult<any, UpdateEmploymentMutationParams> => {
  return useMutation(
    (params: UpdateEmploymentMutationParams) =>
      updateEmployment(params.employmentId, params.employment),
    options,
  );
};

export function deleteEmployment(employmentId: string): Promise<{}> {
  return api.remove(`${EMPLOYMENTS_URL}/${employmentId}`);
}

export const useDeleteEmploymentMutation = (
  options?: RQUseMutationOptions<any, string>,
): RQUseMutationResult<any, string> => {
  return useMutation(deleteEmployment, options);
};
