// TODO move types from apiTypes_deprecated, fix imports
import { Genders, Nationalities } from '@schooly/constants';

import { GroupForRelationRequest, UserGroupsRequest } from '../groups';
import { SimpleListResult } from './endpoints/people';
import { AvailableCriteria, CriteriaUpdate, StringOrNull } from './misc';
import { BaseUserSearchResult } from './school_users';
import { SchoolYear } from './schools';

export interface GroupMembership {
  criteria: AvailableCriteria | null;
  is_individual: boolean;
  start: string;
  end: string;
  id: string;
}

export interface GroupValidityUpdate {
  from_school_year_id?: string;
  to_school_year_id?: string;
  date_from?: string;
  date_to?: string;
}

export interface RelationGroup extends GroupBase {
  validity: GroupValidityUpdate;
  memberships: GroupMembership[];
}

export interface AvailableRelationGroupRequest extends GroupForRelationRequest {
  subject_ids?: string;
  group_type?: GroupType;
}

export interface UpdateGroupForRelationRequest extends Pick<UserGroupsRequest, 'relationId'> {
  date_from: string;
  date_to: string;
  group_ids: string[];
}

export enum GroupType {
  Subject = 1,
  TutorGroup = 2,
}

export interface GroupBase {
  id: string;
  name: string;
  subject?: GroupSubject;
  group_type?: GroupType;
  student_count: number;
  user_count?: number;
}

export interface Group extends GroupBase {
  id: string;
  name: string;
  description?: string;
  validity: GroupValidity;
  limited_to_students?: GroupLimitations;
  limited_to_staff?: GroupLimitations;

  students_preview: BaseUserSearchResult[];
  staff_preview: BaseUserSearchResult[];
  staff_count: number;
  student_count: number;

  // DEPRECATED
  area?: number;
  category?: number;
  guardian_count?: number;
  type?: number;
  user_count?: number;
}

export interface GroupWithMembers extends Group {
  students: GroupMember[];
  staff: GroupMember[];
  criteria: AvailableCriteria[];
  has_memberships: boolean;
  can_manage_student_members?: boolean;
}

export interface GroupSubject {
  id: string;
  name: string;
  attendance_default?: boolean;
  order_for_assessments?: number;
  archived?: boolean;
}

export interface GroupValidity {
  from_school_year?: SchoolYear;
  to_school_year?: SchoolYear;
  date_from?: string;
  date_to?: string;
}

export interface GroupWithMembers extends Group {
  students: GroupMember[];
  staff: GroupMember[];
  criteria: AvailableCriteria[];
  has_memberships: boolean;
  can_manage_student_members?: boolean;
}

export interface GroupBase {
  id: string;
  name: string;
  subject?: GroupSubject;
  student_count: number;
  user_count?: number;
}
export interface GroupLimitations extends Record<string, (string | number)[] | undefined> {
  school_property_ids: string[];
  genders?: Genders[];
  nationalities?: Nationalities[];
  subject_ids?: GroupSubject['id'][];
}

export interface Group extends GroupBase {
  id: string;
  name: string;
  description?: string;
  validity: GroupValidity;
  limited_to_students?: GroupLimitations;
  limited_to_staff?: GroupLimitations;

  students_preview: BaseUserSearchResult[];
  staff_preview: BaseUserSearchResult[];
  staff_count: number;
  student_count: number;

  // DEPRECATED
  area?: number;
  category?: number;
  guardian_count?: number;
  type?: number;
  user_count?: number;
}

export interface AbsenceRequest {
  comment: string;
  start: string;
  end: string;
}

export interface GroupMember {
  user: SimpleListResult;
  is_actual: boolean;
  is_individual: boolean;
  criteria_ids: string[];
  absence_request: AbsenceRequest;
}

export interface GroupCategory {
  description: StringOrNull;
  groups: Group[];
  id: string;
  name: string;
}

export interface GroupUpdate {
  name?: string;
  description?: string;
  group_type?: GroupType;
  subject_id?: StringOrNull;
  validity?: GroupValidityUpdate;
  limited_to_students?: Omit<GroupLimitationsUpdate, 'subject_ids'>;
  limited_to_staff?: GroupLimitationsUpdate;
  staff_ids?: string[];
  student_ids?: string[];
  criteria?: CriteriaUpdate;
  relation_ids_to_remove?: string[];
}

export interface GroupLimitationsUpdate extends Record<string, (string | number)[] | undefined> {
  school_property_ids: string[];
  genders?: Genders[];
  nationalities?: Nationalities[];
  subject_ids?: GroupSubject['id'][];
}
