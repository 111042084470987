import 'react-quill/dist/quill.snow.css';

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Stack, Typography } from '@mui/material';
import { NON_BREAKING_SPACE } from '@schooly/constants';
import { buildClassName } from '@schooly/utils/build-classname';
import { IntlError } from '@schooly/utils/intl-error';
import { DeltaStatic, Sources } from 'quill';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import ReactQuill, { ReactQuillProps, UnprivilegedEditor } from 'react-quill';

import { AttachmentProps } from './Attachments/attachment-types';
import { Attachments } from './Attachments/Attachments';
import { ReactQuillStyled } from './RichText.styled';
import { Toolbar } from './Toolbar';

const quill = (ReactQuill as any).Quill;

const quillIcons = quill.import('ui/icons');

quillIcons.bold = null;
quillIcons.italic = null;
quillIcons.underline = null;
quillIcons.link = null;
quillIcons.list = null;

const Link = quill.import('formats/link');
Link.sanitize = (url: string) => {
  if (url.includes('http')) return url;

  return `https://${url}`;
};

export interface RichTextProps extends AttachmentProps {
  value?: string;
  defaultValue?: string;
  maxBodyLength?: number;
  readOnly?: boolean;
  toolbarId?: string;
  className?: string;
  autoFocus?: boolean;
  useManualEnterKeyUp?: boolean;
  onChange?: (val: string) => void;
  // This property is used to get real text extracted from html length from Quill
  onChangeLength?: (val: number) => void;
  title?: EmotionJSX.Element;
  withoutAttachments?: boolean;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  toolbarItems?: ReactNode;
  // Provide this to avoid scroll to top on paste
  scrollingContainer?: ReactQuillProps['scrollingContainer'] | null;
}

export const RichText: React.FC<RichTextProps> = ({
  className,
  value = '',
  defaultValue = '',
  readOnly,
  files,
  autoFocus,
  useManualEnterKeyUp,
  setFiles = () => {},
  onChange = () => {},
  onChangeLength,
  onAttachmentCardClick,
  toolbarId = 'toolbar',
  title,
  withoutAttachments,
  placeholder,
  error,
  maxBodyLength,
  disabled,
  toolbarItems,
  scrollingContainer,
}) => {
  const { $t } = useIntl();
  const [attachementsError, setAttachementsError] = useState<IntlError | undefined>();
  const ref = useRef<ReactQuill>(null);

  useEffect(() => {
    if (!ref.current || !autoFocus) return;

    const editor = ref?.current.getEditor();

    editor.setSelection(defaultValue.length, defaultValue.length);

    ref.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  useEffect(() => {
    const input = document.querySelector('input[data-link]') as HTMLInputElement;
    input.dataset.link = 'https://www.site.com';
    input.placeholder = 'https://www.site.com';
  }, []);

  const handleChange = useCallback(
    (value: string, _: DeltaStatic, __: Sources, editor: UnprivilegedEditor) => {
      const length = editor.getLength() - 1;
      onChange?.(value);
      onChangeLength?.(length);
    },
    [onChange, onChangeLength],
  );

  useEffect(() => {
    const editor = ref?.current?.getEditor();

    if (disabled) {
      editor?.disable();
    }
  }, [disabled]);

  const editorValue = defaultValue && !value.length ? defaultValue : value;

  const handleKeyUp = useCallback(
    (e: React.KeyboardEvent) => {
      if (!useManualEnterKeyUp || !ref?.current || e.code !== 'Enter') return;

      const editor = ref?.current.getEditor();
      const index = Number(editor.getSelection()?.index);

      editor.insertText(index, `${NON_BREAKING_SPACE}\n`);
      editor.setSelection(index, 0);
    },
    [useManualEnterKeyUp],
  );

  useLayoutEffect(() => {
    const tooltipRef = document.querySelector('.ql-tooltip') as HTMLDivElement;
    const isVisible = tooltipRef.getBoundingClientRect();

    if (!tooltipRef || !isVisible) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => tooltipRef.scrollIntoView());
    resizeObserver.observe(tooltipRef);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleQuillLayout = useCallback(() => {
    onChangeLength?.((ref.current?.getEditor().getLength() ?? 1) - 1);
  }, [onChangeLength]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${toolbarId}`,
      },
    }),
    [toolbarId],
  );

  const bodyLength = editorValue.length;
  const maxLengthError = maxBodyLength !== undefined && bodyLength > maxBodyLength;

  const optionalProps = readOnly || !useManualEnterKeyUp ? { value: editorValue } : {};

  return (
    <Stack
      className="RichTextContainer"
      sx={{
        gap: 2.5,
        overflow: 'hidden',
        ...(disabled && {
          '.Toolbar': {
            pointerEvents: 'none',
            '*': {
              color: 'common.grey',
            },
          },
        }),
        '.ql-editor[contenteditable=false]': {
          backgroundColor: 'common.lightBg',
          color: 'common.grey2',
        },
      }}
    >
      <Toolbar
        files={files}
        setFiles={setFiles}
        setError={setAttachementsError}
        id={toolbarId}
        className={readOnly ? 'Toolbar--readonly' : ''}
        title={title}
        withoutAttachments={withoutAttachments}
      >
        {toolbarItems ?? null}
      </Toolbar>
      <OnLayoutQuill onLayout={handleQuillLayout}>
        <ReactQuillStyled
          ref={ref}
          readOnly={readOnly}
          scrollingContainer={scrollingContainer || undefined}
          className={buildClassName(
            ReactQuillStyled.defaultProps?.className,
            readOnly && 'RichText--readonly',
            (error || maxLengthError) && 'RichText--error',
            className,
          )}
          onChange={handleChange}
          placeholder={placeholder ?? $t({ id: 'messages-MessageText' })}
          defaultValue={defaultValue}
          onKeyUp={handleKeyUp}
          modules={modules}
          {...optionalProps}
        />
      </OnLayoutQuill>
      {maxBodyLength !== undefined && (
        <Typography
          variant="caption"
          textAlign="right"
          pr={1.5}
          py={0.25}
          color={maxLengthError ? 'error.main' : 'common.grey'}
        >
          {bodyLength}
          {!!maxBodyLength && ` / ${maxBodyLength}`}
        </Typography>
      )}
      <Attachments
        error={attachementsError}
        readOnly={readOnly}
        files={files}
        setFiles={setFiles}
        setError={setAttachementsError}
        onAttachmentCardClick={onAttachmentCardClick}
      />
    </Stack>
  );
};

const OnLayoutQuill: FC<PropsWithChildren<{ onLayout: () => void }>> = ({ onLayout, children }) => {
  useEffect(() => {
    onLayout();
  }, [onLayout]);

  return <>{children}</>;
};
