import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 9C2.04305 9.08784 2.09644 9.17057 2.16016 9.24572L9.28863 17.6525C9.58331 18 10.0153 18.0869 10.3786 17.9131C10.4556 17.8763 10.5295 17.8278 10.5984 17.7675C10.6378 17.733 10.6756 17.6947 10.7114 17.6525L17.3369 9.8389L17.3409 9.83411L17.8398 9.24572C17.9036 9.17057 17.9569 9.08784 18 9L2 9Z"
      fill="#B3BBCB"
    />
    <circle cx="10" cy="5" r="3" fill="#B3BBCB" />
  </svg>
);
