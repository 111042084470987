import {
  ApiError,
  AttendanceEntriesForRelationResponse,
  AttendanceEntryForRelation,
  GET_ATTENDANCE_ENTRIES_FOR_DATE_QUERY,
  GET_ATTENDANCE_ENTRIES_FOR_RELATION_QUERY,
  updateAttendanceEntry,
  useGetAttendanceEntriesForRelationQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import useSchoolYears from '../../hooks/useSchoolYears';
import {
  AttendanceForRelationContext,
  AttendanceForRelationContextProps,
} from './AttendanceForRelationContext';

/**
 * Context wrapper for GroupPreview and TakeAttendance modals
 *
 * @param children
 * @constructor
 */
export const WithAttendanceForRelation: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams<'id'>();
  // Attention! Don't destruct `queryClient` like `const { setQueryData } = useQueryClient()`.
  // `QueryClient` is a class, so after destruction it will lose its context.
  // https://github.com/TanStack/query/pull/1586#issuecomment-810909822
  const queryClient = useQueryClient();
  const { showError } = useNotifications();

  const { defaultValidity, isLoading: schoolYearsFetching } = useSchoolYears();

  const { currentStaff, permissions } = useAuth();

  const [schoolYear, setSchoolYear] = useState(defaultValidity);
  const [pendingStatusEntries, setPendingStatusEntries] = useState<
    AttendanceForRelationContextProps['pendingStatusEntries']
  >({});
  const [pendingCommentEntries, setPendingCommentEntries] = useState<
    AttendanceForRelationContextProps['pendingCommentEntries']
  >({});

  const canEdit =
    permissions.includes('attendance_manager') || permissions.includes('attendance_creator');

  const isAttendanceViewer = permissions.includes('attendance_viewer');

  /**
   * The request params here are being used not only the direct request itself, but in
   * the `queryClient.setQueryData` as well. This is why they are extracted to a separate object.
   */
  const requestParams = useMemo(
    () => ({
      relationId: id!,
      dateFrom: schoolYear?.start!,
      dateTo: schoolYear?.end!,
    }),
    [id, schoolYear?.end, schoolYear?.start],
  );

  /** Retrieves attendance entries for group */
  const {
    data,
    error,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
    isRefetching,
  } = useGetAttendanceEntriesForRelationQuery(requestParams, {
    enabled: Boolean(id && schoolYear && isAttendanceViewer),
    refetchOnMount: 'always',
  });

  const counts = useMemo<AttendanceEntriesForRelationResponse['counts']>(
    () => data?.pages[data.pages.length - 1].counts ?? [],
    [data?.pages],
  );

  const entries = useMemo<AttendanceEntryForRelation[]>(
    () =>
      data?.pages.reduce<AttendanceEntryForRelation[]>((prev, curr) => {
        prev.push(...curr.entries.results);
        return prev;
      }, []) ?? [],
    [data?.pages],
  );

  // When attendance_code_id:null we need to generate unique keys, to find entry or show pending status
  const generateEntryKey = (entry: AttendanceEntryForRelation, index: number) =>
    `${entry.group_id}:${entry.register_id}: ${index}`;

  const canShowMore = Boolean(hasNextPage);

  const showMore = useCallback(() => {
    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, isFetchingNextPage]);

  const setEntryStatus = useCallback<AttendanceForRelationContextProps['setEntryStatus']>(
    async (groupId, codeId, registerId, key) => {
      if (!id || !schoolYear) {
        return;
      }

      setPendingStatusEntries((pendingEntries) => ({ ...pendingEntries, [key]: codeId }));

      try {
        const response = await updateAttendanceEntry({
          registerId,
          relationId: id,
          data: { attendance_code_id: codeId, group_id: groupId },
        });

        if (response.success) {
          const refetchPageIndex = data?.pages.findIndex((page) =>
            page.entries.results.some(
              (entry) => entry.register_id === registerId && entry.group_id === groupId,
            ),
          );

          // Manually update entry in the results list
          queryClient.setQueryData(
            [GET_ATTENDANCE_ENTRIES_FOR_RELATION_QUERY, requestParams],
            (data?: InfiniteData<AttendanceEntriesForRelationResponse>) => {
              return data
                ? {
                    ...data,
                    pages: data.pages.map((page) => ({
                      ...page,
                      entries: {
                        ...page.entries,
                        results: page.entries.results.map((entry) => {
                          const isUpdated =
                            entry.register_id === registerId && entry.group_id === groupId;
                          return isUpdated ? { ...entry, attendance_code_id: codeId } : entry;
                        }),
                      },
                    })),
                  }
                : undefined;
            },
          );

          queryClient.invalidateQueries([GET_ATTENDANCE_ENTRIES_FOR_DATE_QUERY], {
            refetchType: 'all',
          });

          if (refetchPageIndex !== -1) {
            await refetch({
              refetchPage: (lastPage, index) => index === refetchPageIndex,
            });
          }
        }
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }

      setPendingStatusEntries((pendingEntries) => ({ ...pendingEntries, [key]: undefined }));
    },
    [data?.pages, id, queryClient, refetch, requestParams, schoolYear, showError],
  );

  const setEntryComment = useCallback<AttendanceForRelationContextProps['setEntryComment']>(
    async ({ codeId, groupId, registerId, comment }) => {
      if (!id) {
        return;
      }

      const text = comment || null;

      const tmpEntry = {
        text,
        creator_relation_id: currentStaff?.relation_id,
        creator_title: currentStaff?.title,
        creator_given_name: currentStaff?.given_name,
        creator_last_name: currentStaff?.last_name,
        creator_known_as: currentStaff?.known_as,
      };

      const key = `${groupId}:${registerId}`;

      setPendingCommentEntries((pendingEntries) => ({
        ...pendingEntries,
        [key]: tmpEntry,
      }));

      try {
        const response = await updateAttendanceEntry({
          registerId,
          relationId: id,
          data: { attendance_code_id: codeId, group_id: groupId, comment },
        });

        if (response.success) {
          // Manually update entry in the results list
          queryClient.setQueryData(
            [GET_ATTENDANCE_ENTRIES_FOR_RELATION_QUERY, requestParams],
            (data?: InfiniteData<AttendanceEntriesForRelationResponse>) => ({
              ...data!,
              pages: data!.pages.map((page) => ({
                ...page,
                entries: {
                  ...page.entries,
                  results: page.entries.results.map((entry) =>
                    entry.group_id === groupId && entry.register_id === registerId
                      ? {
                          ...entry,
                          ...tmpEntry,
                        }
                      : entry,
                  ),
                },
              })),
            }),
          );
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        showError(err as ApiError);
      }

      setPendingCommentEntries((pendingEntries) => ({ ...pendingEntries, [key]: undefined }));
    },
    [
      currentStaff?.given_name,
      currentStaff?.known_as,
      currentStaff?.last_name,
      currentStaff?.relation_id,
      currentStaff?.title,
      id,
      queryClient,
      requestParams,
      showError,
    ],
  );

  /* Set default school year when a list of years is loaded */
  useEffect(() => {
    if (!schoolYear && defaultValidity) {
      setSchoolYear(defaultValidity);
    }
  }, [defaultValidity, schoolYear]);

  const value = {
    relationId: id,
    schoolYear,
    setSchoolYear,
    counts,
    entries,
    fetching: (isFetching && !isRefetching) || schoolYearsFetching,
    isFetchingNextPage,
    error,
    canEdit,
    pendingStatusEntries,
    pendingCommentEntries,
    setEntryStatus,
    setEntryComment,
    generateEntryKey,
    canShowMore,
    showMore,
  };

  return (
    <AttendanceForRelationContext.Provider value={value}>
      {children}
    </AttendanceForRelationContext.Provider>
  );
};
