import { FC } from 'react';

import { ModalContent, ModalMain } from '../../../components/uikit-components/Modal/Modal.styled';
import { WithUserRolesForSchool } from '../../../context/userRoles/WithUserRolesForSchool';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import { SchoolTuneMode, SchoolTuneSidebar } from './SchoolTuneSidebar';
import { SchoolTuneAccess } from './tabs/access/SchoolTuneAccess';
import { SchoolTuneAgeGroups } from './tabs/age_groups/SchoolTuneAgeGroups';
import { SchoolTuneDepartments } from './tabs/departments/SchoolTuneDepartments';
import { SchoolTuneHouses } from './tabs/houses/SchoolTuneHouses';
import { SchoolTuneSchoolYears } from './tabs/school_years/SchoolTuneSchoolYears';
import { SchoolTuneStatuses } from './tabs/statuses/SchoolTuneStatuses';
import { SchoolTuneSubjects } from './tabs/subjects/SchoolTuneSubjects';

export const SchoolTuneContent: FC = () => {
  const { tab } = useQueryStringParams();

  return (
    <ModalMain>
      <SchoolTuneSidebar />
      <ModalContent sx={{ pt: 4 }}>
        {tab === SchoolTuneMode.SCHOOL_YEARS && <SchoolTuneSchoolYears />}
        {tab === SchoolTuneMode.AGE_GROUPS && <SchoolTuneAgeGroups />}
        {tab === SchoolTuneMode.STATUSES && <SchoolTuneStatuses />}
        {tab === SchoolTuneMode.ACCESS && (
          <WithUserRolesForSchool>
            <SchoolTuneAccess />
          </WithUserRolesForSchool>
        )}
        {tab === SchoolTuneMode.SUBJECTS && <SchoolTuneSubjects />}
        {tab === SchoolTuneMode.HOUSES && <SchoolTuneHouses />}
        {tab === SchoolTuneMode.DEPARTMENTS && <SchoolTuneDepartments />}
      </ModalContent>
    </ModalMain>
  );
};
