import { ApiError, GroupWithMembers } from '@schooly/api';
import { createContext } from 'react';

import { ModalSidebarItem } from '../../components/ui/ModalSidebar';
import { AppLocation } from '../../hooks/useAppLocation';
import { ViewGroupModalTabs } from '../../pages/Groups/utils';

export interface GroupContextState {
  groupId?: string;
  group?: GroupWithMembers;
  validity: string;
  isExpired?: boolean;
  fetching?: boolean;
  error?: ApiError | null;
  tabs: ModalSidebarItem<ViewGroupModalTabs>[];
  activeTab?: ViewGroupModalTabs;
  periodLabel?: string;
}

export const CONTEXT_NAME = 'GroupContext';

export const getInitialState = (): GroupContextState => ({
  groupId: undefined,
  group: undefined,
  validity: '',
  fetching: undefined,
  error: undefined,
  tabs: [],
  activeTab: undefined,
  periodLabel: undefined,
});

export const GroupContext = createContext<GroupContextState>(getInitialState());

GroupContext.displayName = CONTEXT_NAME;

export interface GroupContextLocation extends AppLocation {
  state: AppLocation['state'] & {
    initialState?: GroupWithMembers;
  };
}
