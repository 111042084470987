import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import usePrevious from './usePrevious';

export const useDivOverflow = (useInnerRef?: boolean, additionalDepts = [] as any[]) => {
  const { width, ref } = useResizeDetector<HTMLDivElement>();
  const { width: innerWidth, ref: innerRef } = useResizeDetector<HTMLDivElement>();
  const [isTooltip, setIsTooltip] = useState(false);
  const prevWidth = usePrevious(width);

  useEffect(() => {
    // without inner ref
    if (useInnerRef || !ref.current) return;
    if (width === prevWidth) return;

    setIsTooltip(ref.current.offsetWidth < ref.current.scrollWidth);
  }, [
    prevWidth,
    ref,
    useInnerRef,
    width,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...additionalDepts,
  ]);

  useEffect(() => {
    // with inner ref
    if (!useInnerRef || !ref.current) return;
    if (!width || !innerWidth) return;

    setIsTooltip(Number(innerWidth) > Number(width));
  }, [
    innerWidth,
    ref,
    useInnerRef,
    width,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...additionalDepts,
  ]);

  return { ref, innerRef, isTooltip };
};
