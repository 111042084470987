import {
  FilterKeys,
  FilterValue,
  Group,
  Report,
  UserFilter,
  UserFilterListItem,
} from '@schooly/api';
import { createContext } from 'react';

export interface FiltersFiltersState {
  draft: UserFilter;
  draftQuery: string;
  draftArrangeBy?: FilterKeys;
  draftGroupBy?: FilterKeys;
  draftExclude: boolean;
  applied?: UserFilter;
  appliedArrangeBy?: FilterKeys;
  appliedGroupBy?: FilterKeys;
  appliedQuery?: string;
  appliedExclude: boolean;
}

export interface ApplyActionPayload {
  filters?: FiltersFiltersState['draft'];
  arrangeBy?: FilterKeys;
  groupBy?: FilterKeys;
  apply?: boolean;
  query?: string;
  reports?: Report[];
}

export type ListGroupFilterKeys = FilterKeys | 'recipientIds' | 'assessmentIds';

export type ListGroupFilters = Partial<Record<ListGroupFilterKeys, FilterValue[]>>;

export type FilterEntry = { key: FilterKeys; value: FilterValue[] };

export interface FiltersActions {
  setDraftValue: (payload: FilterEntry) => void;
  setMultipleDraftValues: (payload: FilterEntry[]) => void;
  applyFilters: (payload?: ApplyActionPayload & { noDebounce?: boolean }) => void;
  setFilters: (draft: FiltersFiltersState['draft']) => void;
  setArrangeBy: (draftArrangeBy?: FilterKeys) => void;
  setGroupBy: (draftGroupBy?: FilterKeys) => void;
  resetFilters: () => void;
  setExclude: (value: boolean) => void;
  setGroupsList: (items: Group[]) => void;
  setGroupsListFilters: (filters: ListGroupFilters) => void;
  setAsyncFiltersInitialized: (initialized: boolean) => void;
}

export interface FiltersSavedState {
  filters: UserFilterListItem[];
  fetching: boolean;
}

export interface FiltersContextProps {
  type?:
    | 'parents'
    | 'user-roles'
    | 'applications'
    | 'sidebar-groups'
    | 'sidebar-report-recipients'
    | 'sidebar-group-staff'
    | 'sidebar-group-students'
    | 'sidebar-relation-groups'
    | 'sidebar-user-role-staff'
    | 'sidebar-assessment-groups';

  withFavorite?: boolean;
  filters: FiltersFiltersState;
  saved: FiltersSavedState;
  lists: {
    groups: Group[];
  };
  listFilters: {
    groups: ListGroupFilters;
  };
  actions: FiltersActions;
  asyncFiltersInitialized?: boolean;
}

export const getDefaultDraft = () =>
  Object.values(FilterKeys).reduce(
    (prev, key) => ({
      ...prev,
      [key]: [],
    }),
    {} as FiltersFiltersState['draft'],
  );

export interface InitialStateProps {
  type?: FiltersContextProps['type'];
  withFavorite?: FiltersContextProps['withFavorite'];
}

export const getInitialState = ({
  type,
  withFavorite,
}: InitialStateProps = {}): FiltersContextProps => ({
  type,
  withFavorite,
  filters: {
    draft: getDefaultDraft(),
    draftQuery: '',
    draftArrangeBy: undefined,
    draftGroupBy: undefined,
    draftExclude: false,
    applied: undefined,
    appliedQuery: '',
    appliedArrangeBy: undefined,
    appliedGroupBy: undefined,
    appliedExclude: false,
  },
  saved: {
    filters: [],
    fetching: false,
  },
  lists: {
    groups: [],
  },
  listFilters: {
    groups: {},
  },
  actions: {
    setDraftValue: () => {},
    setMultipleDraftValues: () => {},
    applyFilters: () => {},
    setFilters: () => {},
    setArrangeBy: () => {},
    setGroupBy: () => {},
    resetFilters: () => {},
    setExclude: () => {},
    setGroupsList: () => {},
    setGroupsListFilters: () => {},
    setAsyncFiltersInitialized: () => {},
  },
});

export const FiltersContext = createContext(getInitialState());
FiltersContext.displayName = 'FiltersContext';
