import { SxProps, Theme } from '@mui/material';
import { GridCellEditStopReasons } from '@mui/x-data-grid';
import { UncapitalizeObjectKeys } from '@mui/x-data-grid/internals';
import {
  GridCellEditStartReasons,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
  GridEventListener,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useDataGridHover } from 'apps/web/src/context/table/dataGridHover/WithDataGridHover';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { DataGridTableStyled } from './DataGridTable.styled';

interface DataGridTableProps {
  columns: GridColDef[];
  rows: GridValidRowModel[];
  apiRef: React.MutableRefObject<GridApiPro>;
  rowHeight?: number;
  slots?: UncapitalizeObjectKeys<Partial<GridSlotsComponent>>;
  slotProps?: GridSlotsComponentsProps;
  noNavigationOnFirstCol?: boolean;
  isCellEditable?: (params: GridCellParams) => boolean;
  sx?: SxProps<Theme>;
  stopEditModeOnDeleteKey?: (field: GridCellParams['field']) => boolean;
  columnGroupingModel?: GridColumnGroupingModel;
  columnsToPin?: GridCellParams['field'][];
  headerCellSelector?: string;
  columnHeaderHeight?: number;
  withDataGridHover?: boolean;
  disableVirtualization?: boolean;
}

const COLUMN_BUFFER = 5;

export const DataGridTable: FC<DataGridTableProps> = ({
  rows,
  columns,
  apiRef,
  rowHeight = 47,
  slots,
  noNavigationOnFirstCol,
  slotProps,
  isCellEditable,
  stopEditModeOnDeleteKey,
  sx,
  columnGroupingModel,
  columnsToPin,
  headerCellSelector,
  columnHeaderHeight,
  withDataGridHover,
  disableVirtualization,
}) => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const { styles, onCellMouseOver, onMouseLeave } = useDataGridHover();

  // Click changes cell mode in cell is editable
  const handleCellClick: GridEventListener<'cellClick'> = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Check if click was triggered by Enter key press
    if (event.detail < 1) {
      return;
    }

    setCellModesModel((prevModel) => {
      const currentMode =
        prevModel[params.id] && prevModel[params.id][params.field]
          ? prevModel[params.id][params.field].mode
          : GridCellModes.View;

      const newMode = currentMode === GridCellModes.View ? GridCellModes.Edit : GridCellModes.View;

      const newModel = {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: newMode },
        },
      };

      return newModel;
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'ArrowLeft':
        case 'ArrowRight':
          const focusedCell = apiRef.current.state.focus.cell;
          const focusedHeaderCell = apiRef.current.state.focus.columnHeader;
          const firstRowId = apiRef.current.getRowIdFromRowIndex(0);
          const secondColumnField = apiRef.current.getAllColumns()[1].field;

          //Prevents keyboard navigation on first column
          if (
            noNavigationOnFirstCol &&
            focusedCell?.field === apiRef.current.state.columns.orderedFields[0]
          ) {
            const currentRowId = focusedCell.id;
            apiRef.current.setCellFocus(currentRowId, secondColumnField);
          }
          if (!focusedCell) {
            //Prevents keyboard navigation on header
            if (focusedHeaderCell) {
              const currentColumnField = focusedHeaderCell.field;
              apiRef.current.setCellFocus(firstRowId, currentColumnField);
              //Starts keyboard navigation if no cell is focused
            } else {
              apiRef.current.setCellFocus(firstRowId, secondColumnField);
            }
          }
          break;
        //Changes mode from edit to view
        case 'Backspace':
          if (apiRef.current && apiRef.current.state.focus.cell) {
            const focusedCell = apiRef.current.state.focus.cell;
            const { id, field } = focusedCell;
            if (!stopEditModeOnDeleteKey?.(field)) return;

            setCellModesModel((prevModel) => {
              const currentMode = prevModel[id]?.[field]?.mode || GridCellModes.View;

              if (currentMode === GridCellModes.Edit) {
                return {
                  ...prevModel,
                  [id]: {
                    ...prevModel[id],
                    [field]: { mode: GridCellModes.View },
                  },
                };
              }

              return prevModel;
            });
          }
          break;
      }
    },
    [apiRef, noNavigationOnFirstCol, stopEditModeOnDeleteKey],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (!withDataGridHover) return;

    const unsubscribeCellMouseOver = apiRef.current.subscribeEvent(
      'cellMouseOver',
      (props: GridCellParams, event) =>
        onCellMouseOver({
          event,
          colField: props.field,
          columns,
          rows,
          rowId: props.id,
          headerCellSelector,
          getRowIndex: apiRef.current.getRowIndexRelativeToVisibleRows,
          ...apiRef.current,
        }),
    );

    return () => unsubscribeCellMouseOver();
  }, [apiRef, columns, headerCellSelector, onCellMouseOver, rows, withDataGridHover]);

  useEffect(() => {
    if (!withDataGridHover) return;
    const unsubscribeRowLeave = apiRef.current.subscribeEvent('rowMouseLeave', onMouseLeave);
    return () => unsubscribeRowLeave();
  }, [apiRef, columns, onCellMouseOver, onMouseLeave, rows, withDataGridHover]);

  return (
    <DataGridTableStyled
      initialState={{ pinnedColumns: { left: columnsToPin } }}
      experimentalFeatures={{ columnGrouping: !!columnGroupingModel }}
      disableVirtualization={disableVirtualization}
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      slots={slots}
      slotProps={slotProps}
      disableRowSelectionOnClick
      hideFooter
      columnBuffer={COLUMN_BUFFER}
      columnGroupingModel={columnGroupingModel}
      columnHeaderHeight={columnHeaderHeight}
      rowHeight={rowHeight}
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      hoverStyles={styles}
      isCellEditable={isCellEditable}
      onCellEditStart={(params, event) => {
        onMouseLeave();
        if (
          params.reason === GridCellEditStartReasons.deleteKeyDown ||
          params.reason === GridCellEditStartReasons.printableKeyDown
        ) {
          event.defaultMuiPrevented = true;
        }
      }}
      onCellEditStop={(params) => {
        if (params.reason !== GridCellEditStopReasons.enterKeyDown) return;

        const currentRowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(params.id);
        //Needs a delay due to virtualization
        setTimeout(() => {
          apiRef.current.scrollToIndexes({ rowIndex: currentRowIndex + 1 });
        }, 100);
      }}
      sx={sx}
    />
  );
};
