import { Components, Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      padding: 0,
      fontSize: theme.spacing(2.5),
      color: ownerState.inverse ? theme.palette.text.secondary : theme.palette.primary.main,
      transition: theme.transitions.create(['color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),

      '&:hover': {
        color: ownerState.inverse ? theme.palette.primary.main : theme.palette.text.secondary,
      },
    }),
    sizeSmall: ({ theme }) => ({
      fontSize: theme.spacing(2),
    }),
  },
};
