import { ConsentFormItemStatus } from '@schooly/api';

export function getConsentFormItemStatusLabelId(status: ConsentFormItemStatus) {
  return `consentForms-${status === 'no_status' ? 'noStatus' : status}`;
}
export function getConsentFormItemStatusColor(status: ConsentFormItemStatus) {
  switch (status) {
    case 'agreed':
      return 'success.main';
    case 'declined':
      return 'error.main';
    default:
      return 'common.grey';
  }
}
