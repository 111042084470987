import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import buildClassName from '../../../utils/buildClassName';
import { renderError, renderLabel } from './helpers';
import { BaseFormInputProps, useFormInput } from './utils';

import './FormInputWithGenerator.scss';

interface IProps extends BaseFormInputProps {
  type?: 'text' | 'email' | 'password';
  generateName: string;
  generateLabelTextId: string;
  manualLabelTextId: string;
  generatedValueTextId: string;
}

const FormInputWithGenerator: React.FC<IProps> = (props) => {
  const {
    id,
    type,
    className,
    disabled,
    generateName,
    generateLabelTextId,
    manualLabelTextId,
    generatedValueTextId,
  } = props;

  const { inputRef, value, error, fullName, isTouched } = useFormInput(props);

  const isInitialRender = useRef(true);
  const { watch, setValue } = useFormContext();
  const { formatMessage } = useIntl();

  const generatedValue = formatMessage({ id: generatedValueTextId });

  // TODO: This is a strange bug with `react-hook-form` that is not reproducible in a sandbox
  // `watch` value doesn't get updated correctly when `defaultValue` is provided
  // See https://github.com/react-hook-form/react-hook-form/issues/2472
  const isGenerated = Boolean(watch(generateName));

  useEffect(() => {
    if (!isGenerated && value !== generatedValue) {
      return;
    }

    setValue(fullName, isGenerated ? generatedValue : '', {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [fullName, generatedValue, isGenerated, setValue, value]);

  useEffect(() => {
    if (!isInitialRender.current && !isGenerated) {
      requestAnimationFrame(() => {
        const input = document.querySelector(`[name="${fullName}"]`);
        if (input) {
          (input as HTMLInputElement).focus();
        }
      });
    }
    isInitialRender.current = false;
  }, [fullName, isGenerated]);

  const fullClassName = buildClassName(
    'form-group',
    'FormInputWithGenerator',
    disabled && 'disabled',
    error && 'error',
    (isTouched || isGenerated) && 'touched',
    className,
  );

  return (
    <label className={fullClassName} htmlFor={id}>
      <input
        id={id}
        name={fullName}
        ref={inputRef}
        className="form-control"
        type={type}
        disabled={disabled}
        readOnly={isGenerated}
        defaultValue={isGenerated ? generatedValue : undefined}
      />
      {renderLabel(props)}
      {renderError(error)}
      <label className="FormInputWithGenerator__btn" htmlFor={generateName}>
        <input id={generateName} name={generateName} ref={inputRef} type="checkbox" />
        <FormattedMessage id={isGenerated ? manualLabelTextId : generateLabelTextId} />
      </label>
    </label>
  );
};

FormInputWithGenerator.defaultProps = {
  type: 'text',
};

export default FormInputWithGenerator;
