import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 17C16.1046 17 17 16.1046 17 15L17 14L14 14L14 17L15 17ZM13 17L13 14C13 13.4477 13.4477 13 14 13L17 13L17 5C17 3.89543 16.1046 3 15 3L5 3C3.89543 3 3 3.89543 3 5L3 15C3 16.1046 3.89543 17 5 17L13 17Z"
      fill="#B3BBCB"
    />
    <circle cx="10" cy="3" r="2.5" fill="#B3BBCB" stroke="white" />
  </svg>
);
