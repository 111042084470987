import React from 'react';

import TextLayout from '../../components/ui/TextLayout';

const TermsOfService: React.FC = () => (
  <TextLayout>
    <section className="policy-text">
      <h1 className="mt-4 mb-5">Terms of service</h1>

      <p>Last update: 9 December 2021</p>
      <ol>
        <li className="parent-list">
          <strong>Information about us, these Terms and how to contact us</strong>
          <ol>
            <li>
              <strong>Who we are</strong>. We are Schooly Solutions Ltd, a company registered in
              England and Wales. Our company registration number is 12443158 and our registered
              office is at 85 Great Portland Street, First Floor, London, England, W1W 7LT.&nbsp;
            </li>
            <li>
              <strong>These Terms. </strong>These are the Terms that apply to your use of the
              Schooly service (<strong>Service</strong>) via the Schooly app (&ldquo;
              <strong>App</strong>
              &rdquo;) or browser.Some of the Terms will only apply in certain situations, or to
              certain types of user; we will make it clear in the Terms where this applies. Where we
              refer in these Terms to the &ldquo;<strong>Contract</strong>&rdquo;, we mean the legal
              relationship between you and us relating to the Service as described in these
              Terms.&nbsp;
            </li>
            <li>
              <strong>Users of the Service. </strong>The Service allows individuals (&ldquo;
              <strong>Users</strong>&rdquo;) to communicate and exchange information with
              institutions (&ldquo;<strong>Institutions</strong>&rdquo;) with which they are
              connected. &ldquo;You&rdquo; and &ldquo;Your&rdquo; refer to a User or Institution of
              our Service.
            </li>
            <li>
              <strong>How to contact us</strong>. You can contact us by emailing us at{' '}
              <a href="mailto: info@schooly-app.com" target="_blank" rel="noreferrer">
                <u>info@schooly-app.com</u>
              </a>
              .
            </li>
            <li>
              <strong>How we may contact you</strong>. If we have to contact you we will do so by
              communicating with you via the Service or by emailing to you at the email address you
              provided to us when you registered.
            </li>
            <li>
              <strong>Privacy policy</strong>. Our{' '}
              <a
                href={import.meta.env.PUBLIC_URL + '/privacy-policy'}
                target="_blank"
                rel="noreferrer"
              >
                <u>Privacy Policy</u>
              </a>{' '}
              sets out how we collect and use personal data.&nbsp;
            </li>
            <li>
              <strong>Cookies policy</strong>.&nbsp; Our{' '}
              <a
                href={import.meta.env.PUBLIC_URL + '/cookies-policy'}
                target="_blank"
                rel="noreferrer"
              >
                <u>Cookies Policy</u>
              </a>{' '}
              sets out how we use cookies.
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>Your Account</strong>
          <ol>
            <li>
              <strong>Account required</strong>. You must create an account (
              <strong>Account</strong>) in order to use the Service (even if you have been invited
              by an Institution to use the Service). Each User creates its own Account. An Account
              can be an &ldquo;Individual Account&rdquo; or an &ldquo;Institution Account&rdquo;, as
              identified when the Account is opened. In order for an Institution to create an
              Account, firstly a relevant User should create their own Account, and then they can
              create an Account on behalf of the relevant Institution. Where an Individual creates
              or manages an Account on behalf of an Institution, we will be entitled to assume that
              they have the relevant rights to do so, and they should let us know immediately if
              they do not, or no longer, have such rights.
            </li>
            <li>
              <strong>For Institutions only</strong>. Your use of the Service will be on an
              automatically-renewing basis (each period being a &ldquo;
              <strong>Subscription Period</strong>&rdquo;) and subject to payment of the applicable
              Fee as described in Section 10.&nbsp;
            </li>
            <li>
              <strong>18 years old minimum</strong>. You must be at least 18 years old to open an
              Account.
            </li>
            <li>
              <strong>Using an Account to share information concerning someone else. </strong>Users
              and Institutions can use the Service to share information about individuals under
              their care or for which they are responsible; for example, individual Users can use
              the Service to share information about their children (where they have parental
              responsibility to do so). Where an Individual or Institution uses an Account to share
              information concerning someone else, we will be entitled to assume that they have the
              relevant rights to do so, and they should let us know immediately if they do not, or
              no longer, have such rights.
            </li>
            <li>
              <strong>Refusal of an Account</strong>. We may refuse to accept an Account being
              opened for any reason in our sole discretion.&nbsp;
            </li>
            <li>
              <strong>Your country</strong>. When you register your Account, we will ask you to
              confirm your country of residence. Please let us know if that changes.
            </li>
            <li>
              <strong>Changes to your Account. </strong>Please check your Account details regularly.
              The Service allows you to make necessary changes to your details, but let us know as
              soon as possible of any changes which we need to make.
            </li>
            <li>
              <strong>Keep your Account details private. </strong>Your Account registration details
              (username and password) are confidential to you; you should keep them secure, and you
              should not let anyone else have or use them. Please let us know immediately if you are
              aware that such details are lost, stolen or otherwise compromised in any way. You are
              responsible for all usage made of your Account by anyone to which you provide your
              Account registration details. We have the right to disable any Account registration
              details, whether chosen by you or allocated by us, at any time, if in our reasonable
              opinion you have failed to comply with any of the provisions of these Terms.
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>Our rights to make changes</strong>
          <ol>
            <li>
              <strong>Updates</strong>. The App and the Service are in ongoing development and we
              will update them from time to time.&nbsp;
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>Providing the Service</strong>
          <ol>
            <li>
              <strong>Starting the Service</strong>. Your right to use the Service will start:
            </li>
            <ol>
              <li>
                <strong>If you are an User</strong>: when your Account is opened; and
              </li>
              <li>
                <strong>If you are an Institution</strong>: when your Account is opened and you have
                paid the relevant Fee (if any) (see Section 10).&nbsp;
              </li>
            </ol>
            <li>
              <strong>We are not responsible for delays outside our control</strong>. If our supply
              of the Service is delayed by an event outside our control then we will contact you as
              soon as possible to let you know and we will take steps to minimise the effect of the
              delay.&nbsp;
            </li>
            <li>
              <strong>Service is provided &ldquo;as is&rdquo;.</strong> We do not guarantee that the
              Service, or any content on it, will always be available or be uninterrupted. We may
              suspend or withdraw or restrict the availability of all or any part of the Service for
              business and operational reasons. We will try to give you reasonable notice of any
              suspension or withdrawal.
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>Your use of the Service</strong>
          <ol>
            <li>
              We are the owner or the licensee of all intellectual property rights in our Service
              and the App, and in the material contained within them.&nbsp; Those works are
              protected by copyright laws and treaties around the world. All such rights are
              reserved.
            </li>
            <li>
              Although we make reasonable efforts to update the information available within the
              Service, we make no representations, warranties or guarantees, whether express or
              implied, that the content on our site is accurate, complete or up to date.
            </li>
            <li>
              <strong>
                User-generated and Institution-generated content is not approved by us.{' '}
              </strong>
              The Service allows Users and Institutions to generate their own content and share it
              with each other. This information and these materials have not been verified or
              approved by us, and the views expressed do not represent our views or values.
            </li>
            <li>
              <strong>You are responsible for your use of the Service.</strong> You will be liable
              to us and indemnify us for any breach of these Terms in relation to your use of the
              Service. This means you will be responsible for any loss or damage we suffer as a
              result of your breach.
            </li>
            <li>
              <strong>Your content.</strong> When you upload content to our Service, or communicate
              it through our Service, you grant us a worldwide, non-exclusive, royalty-free,
              transferable licence to: (i) use, reproduce, distribute, prepare derivative works of,
              display, and perform that content in connection with the Service; and (ii) to
              communicate it to the relevant User(s) and/or Institution(s) within the Service and
              licence them to view the content within, and to download it from, the Service.
            </li>
            <li>
              <strong>We can disclose your identity and other information.</strong> We have the
              right to disclose your identity to any third party who is claiming that any content
              posted or uploaded by you to our Service constitutes a violation of their intellectual
              property rights, or of their right to privacy. We can also disclose such information
              to law enforcement authorities as we reasonably feel is necessary or as required by
              law.
            </li>
            <li>
              <strong>Our right to remove your content</strong>. We have the right to remove any
              content you upload to or communicate via our Service if, in our opinion, your post
              does not comply with the content standards set out in this section 5.
            </li>
            <li>
              <strong>Lawful use only.</strong> You may use our Service only for lawful purposes.
              You may not use our Service:
            </li>
            <ol>
              <li>
                in any way that breaches any applicable local, national or international law or
                regulation, or which is otherwise harmful or offensive (in our opinion);
              </li>
              <li>
                in any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose
                or effect;
              </li>
              <li>for the purpose of harming or attempting to harm minors in any way;</li>
              <li>to bully, insult, intimidate or humiliate any person;</li>
              <li>
                to transmit, or procure the sending of, any unsolicited or unauthorised advertising
                or promotional material or any other form of similar solicitation; or
              </li>
              <li>
                to knowingly transmit any data, send or upload any material that contains viruses,
                Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other
                harmful programs or similar computer code designed to adversely affect the operation
                of any computer software or hardware.
              </li>
            </ol>
            <li>
              <strong>No automatic moderation</strong>. We are under no obligation to oversee,
              monitor or moderate the data shared via our Service, and we expressly exclude our
              liability for any loss or damage arising from the use of our Service by a User or
              Institution in contravention of these Terms.&nbsp;
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>Your rights to end the Contract</strong>
          <ol>
            <li>
              <strong>For Institutions. </strong>You can end your Contract with us and cancel your
              Account at any time. Such a cancellation will come into effect at the end of the
              then-current Subscription Period. Please note that we do not offer refunds of any Fees
              paid.
            </li>
            <li>
              <strong>For Individuals. </strong>You can always end your Contract with us and cancel
              your Account at any time. Please use the functionality within your Account on the App
              to inform us of such cancellation. Following cancellation, we will delete your Account
              details and the information held in your Account. Please contact any relevant
              Institutions if you wish them to delete your information as well.
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>Our rights to end the Contract</strong>
          <ol>
            <li>
              <strong>We may end the Contract if you break it</strong>. We may end the Contract and
              cancel your Account at any time if you breach any of these Terms. We will email you if
              we do this.&nbsp;
            </li>
            <li>
              <strong>For Individuals</strong>. We may end the Contract and cancel your Account any
              time in our sole discretion. We will email you if we do this.
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>What happens when the Contract finishes</strong>
          <ol>
            <li>
              <strong>No Account access.</strong> When the Contract finishes, your Account will no
              longer be accessible. If you need access to any content held within your Account, you
              should access it before the end of your Contract.
            </li>
            <li>
              <strong>Deletion of your information.</strong> We will not have any obligation to
              retain your information after the closure of your Account. Please see our Privacy
              Policy for details of our data retention policy.
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>If there is a problem&nbsp;</strong>
          <ol>
            <li>
              <strong>How to tell us about problems</strong>. If you have any questions or
              complaints about the Service, please contact us. You can email us at
              info@schooly-app.com.
            </li>
            <li>
              <strong>Summary of your legal rights</strong>. We are under a legal duty to supply the
              Service in conformity with these Terms.
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>
            Fees and Annual Account Renewal &ndash; This Section 10 is Applicable to Institutions
            Only
          </strong>
          <ol>
            <li>
              <strong>Fees Applicable to Institutions. </strong>We do not charge Individuals for
              their use of the Service, but we may charge Institutions for their use of the Service
              (&ldquo;
              <strong>Fees</strong>&rdquo;).
            </li>
            <li>
              <strong>Where to find the Fee due</strong>. The Fee charged to each Institution, if
              any, will be set out in the relevant Account registration process. We take all
              reasonable care to ensure that the Fee advised to you is correct. However It is always
              possible that, despite our best efforts, the Fee is incorrectly shown. If the actual
              Fee is higher than the Fee stated to you in your Account registration process, we will
              contact you for your instructions before we open your Account.&nbsp; We may increase
              the Fees due for future Subscription Periods; we will always provide at least 1
              month&rsquo;s notice within the Service of any future Fee increase.
            </li>
            <li>
              <strong>When you must pay and how you must pay</strong>. Fees are due to be paid in
              advance for each Subscription Period. We will take payment as part of the Account
              registration process for Institutions.
            </li>
            <li>
              <strong>Automatic renewals</strong>. Your Account runs for the initial Subscription
              Period which you selected in the Service. Unless we hear otherwise from you
              beforehand, at the end of your relevant initial Subscription Period we will
              automatically extend your Account for a further Subscription Period of the same
              length, and charge the relevant Fee to the payment method you used when opening your
              Account. This process will continue for successive renewed Subscription Periods until
              your Account is terminated.&nbsp; Please note that Fees are not refundable once the
              initial or a successive Subscription Period (as relevant) have started.&nbsp;&nbsp;
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>Our responsibility for loss or damage suffered by you</strong>
          <ol>
            <li>
              <strong>
                We are responsible to you for foreseeable loss and damage caused by us
              </strong>
              . If we fail to comply with these Terms, we are responsible for loss or damage you
              suffer that is a foreseeable result of such failure or our failing to use reasonable
              care and skill, but we are not responsible for any loss or damage that is not
              foreseeable.&nbsp;
            </li>
            <li>
              <strong>
                We do not exclude or limit in any way our liability to you where it would be
                unlawful to do so
              </strong>
              . This includes liability for death or personal injury caused by our negligence or the
              negligence of our employees, agents or subcontractors; for fraud or fraudulent
              misrepresentation; for breach of your legal rights in relation to the products.
            </li>
          </ol>
        </li>

        <li className="parent-list">
          <strong>Other important terms</strong>
          <ol>
            <li>
              <strong>We may transfer this agreement to someone else</strong>. We may transfer our
              rights and obligations under these Terms to another organisation.&nbsp;
            </li>
            <li>
              <strong>You need our consent to transfer your rights to someone else</strong>. You may
              only transfer your rights or your obligations under these Terms to another person if
              we agree to this in writing.&nbsp;
            </li>
            <li>
              <strong>Nobody else has any rights under these Terms</strong>. This Contract is
              between you and us. No other person shall have any rights to enforce any of the Terms.
            </li>
            <li>
              <strong>
                If a court finds part of this Contract illegal, the rest will continue in force
              </strong>
              . Each of the paragraphs of these Terms operates separately. If any court or relevant
              authority decides that any of them are unlawful, the remaining paragraphs will remain
              in full force and effect.
            </li>
            <li>
              <strong>Even if we delay in enforcing, we can still enforce it later</strong>. If we
              do not insist immediately that you do anything you are required to do under these
              Terms, or if we delay in taking steps against you in respect of your breaking these
              Terms, that will not mean that you do not have to do those things and it will not
              prevent us taking steps against you at a later date. For example, if you are an
              Institution and miss a payment and we do not chase you but we do not close your
              Account, we can still require you to make the payment at a later date.
            </li>
            <li>
              <strong>Which laws apply and where you may bring legal proceedings</strong>.&nbsp;
            </li>
            <ol>
              <li>
                <strong>For Institutions</strong>: These Terms are governed by English law and you
                can bring legal proceedings in respect of the App and the Service in the English
                courts. If you live in Scotland you can bring legal proceedings in respect of the
                App and the Service in either the Scottish or the English courts. If you live in
                Northern Ireland you can bring legal proceedings in respect of the App and the
                Service in either the Northern Irish or the English courts.
              </li>
              <li>
                <strong>For Individuals</strong>: These Terms are governed by the law of the country
                which you selected as your residence when you opened your Account.&nbsp;
              </li>
            </ol>
          </ol>
        </li>
      </ol>
    </section>
  </TextLayout>
);

export default TermsOfService;
