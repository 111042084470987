import { Box, styled } from '@mui/material';

export const InputEndAdornmentIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),

  '& .MuiIconButton-root': {
    color: theme.palette.text.secondary,

    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));
