import { Assessment, Event } from '@schooly/api';
import { Recurrence } from '@schooly/api';

export const getRecurrences = (recurringItems: (Event | Assessment)[]): Recurrence[] => {
  const recurrences = recurringItems.reduce((acc: Recurrence[], item) => {
    const recurrenceId = item.recurring_state?.recurrence_id;
    const recurrenceName = item.recurring_state?.recurrence_name;

    if (recurrenceId && recurrenceName) {
      acc.push({
        id: recurrenceId,
        title: recurrenceName,
      });
    }

    return acc;
  }, []);

  return recurrences;
};
