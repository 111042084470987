import { Components, createTheme, PaletteColorOptions, Theme, TypeText } from '@mui/material';
import React, { CSSProperties } from 'react';

import components from './components/components';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h3_bold: true;
    h3_medium: true;
    h3_italic: true;
    body3: true;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    fontWeightSemiBold: React.CSSProperties['fontWeight'];
    fontWeightExtraBold: React.CSSProperties['fontWeight'];
    h3_bold: React.CSSProperties;
    h3_medium: React.CSSProperties;
    h3_italic: React.CSSProperties;
    body3: React.CSSProperties;
    headerCell: React.CSSProperties;
  }

  interface TypographyOptions {
    fontWeightSemiBold?: React.CSSProperties['fontWeight'];
    fontWeightExtraBold?: React.CSSProperties['fontWeight'];
    h3_bold?: React.CSSProperties;
    h3_medium?: React.CSSProperties;
    h3_italic?: React.CSSProperties;
    body3?: React.CSSProperties;
    headerCell?: React.CSSProperties;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    lightBg: string;
    light2: string;
    light3: string;
    highlightedBg: string;

    main1: string;
    main2: string;
    main3: string;
    main4: string;
    main5: string;
    main6: string;

    grey: string;
    grey2: string;
    grey3: string;
    grey6: string;
    grey7: string;

    orange: string;
    orange4: string;
    orange5: string;

    greenOld: string;
    green1: string;
    green2: string;
    green3: string;
    green4: string;
    green5: string;

    moss1: string;
    moss2: string;
    moss3: string;
    moss4: string;
    moss5: string;

    dijon1: string;
    dijon2: string;
    dijon3: string;
    dijon4: string;
    dijon5: string;

    wine1: string;
    wine2: string;
    wine3: string;
    wine4: string;
    wine5: string;

    coral: string;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    shape: {
      borderRadiusSm: number | string;
      borderRadius: number | string;
      borderRadiusLg: number | string;
    };
  }

  interface ThemeOptions {
    shape: {
      borderRadiusSm?: number | string;
      borderRadius?: number | string;
      borderRadiusLg?: number | string;
    };
  }

  interface PaletteColor {
    superLight?: string;
  }

  interface SimplePaletteColorOptions {
    superLight?: string;
  }

  interface TypeBackground {
    button: {
      error: {
        hover: string;
      };
    };
  }

  interface Mixins {
    borderValue: (width?: string) => string;
    border: (width?: string) => {
      border: string;
    };
    borderControlValue: (width?: string) => string;
    borderControl: (width?: string) => {
      border: string;
    };
    dropdownShadow: () => CSSProperties;
    dropdownShadowTop: () => CSSProperties;
    scrollBar: () => CSSProperties;
  }
}

const colorsCommon: Partial<Theme['palette']['common']> = {
  lightBg: '#f8f9fe',
  light2: '#e7e9f4',
  light3: '#c7c8e0',
  highlightedBg: '#f2f3fd',

  main1: '#24275b',
  main2: '#3d417a',
  main3: '#8e90ad',
  main4: '#c7c8e0',
  main5: '#e7e9f4',
  main6: '#f4f4f9',

  grey: '#b3bbcb',
  grey2: '#5d6688',
  grey3: '#8e90ad',
  grey6: '#DCE1EB',
  grey7: '#eceef6',

  orange: '#F2994A',
  orange4: '#FBDEBE',
  orange5: '#FFF9F2',

  greenOld: '#68df83',
  green1: '#03473f',
  green2: '#4b7b75',
  green3: '#a6cdc8',
  green4: '#d1e8e1',
  green5: '#e9f1ef',

  moss1: '#3c8900',
  moss2: '#6fae3d',
  moss3: '#90ce5f',
  moss4: '#cdf2b2',
  moss5: '#e8ffd7',

  dijon1: '#c15d00',
  dijon2: '#d78235',
  dijon3: '#f0ac5b',
  dijon4: '#fedb96',
  dijon5: '#fff6df',

  wine1: '#880f07',
  wine2: '#a6463f',
  wine3: '#dd8f89',
  wine4: '#fcd2cf',
  wine5: '#feedec',

  coral: '#F87951',
};

const colorsPrimary: PaletteColorOptions = {
  main: '#24275b',
  light: '#3d417a',
};

const colorsText: Partial<TypeText> = {
  primary: colorsCommon.grey2,
  secondary: colorsCommon.grey,
  disabled: colorsCommon.light2,
};

export const theme = createTheme({
  palette: {
    primary: colorsPrimary,
    text: colorsText,
    background: {
      default: colorsCommon.lightBg,
      paper: '#fff',
      button: {
        error: {
          hover: '#ff6b6b',
        },
      },
    },
    error: {
      main: '#eb5757',
      light: '#fce6e6',
      superLight: '#fef7f7',
    },
    success: {
      main: '#6fcf97',
      light: '#b7e7cb',
      superLight: '#f1faf5',
    },
    warning: {
      main: '#f2994a',
      light: 'rgba(242, 153, 74, 0.15)',
      superLight: 'rgba(242, 153, 74, 0.05)',
    },

    divider: colorsCommon.light2,

    common: colorsCommon,
  },
  typography: {
    fontFamily: 'Heebo, Helvetica, sans-serif',
    fontSize: 12,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    htmlFontSize: 12,
    h1: {
      fontSize: 28,
      fontWeight: 400,
      lineHeight: '40px',
      color: colorsPrimary.main,
    },
    h2: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '28px',
      color: colorsPrimary.main,
    },
    h3: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '22px',
      color: colorsPrimary.main,
    },
    h3_bold: {
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '22px',
      letterSpacing: '0.01em',
      color: colorsPrimary.main,
    },
    h3_medium: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.01em',
    },
    h3_italic: {
      fontFamily: 'Helvetica', // no italic for Heebo
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '20px',
      color: colorsPrimary.main,
    },
    h4: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.02em',
      color: colorsCommon.grey3,
    },
    body1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.01em',
    },
    body2: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.01em',
    },
    body3: {
      fontSize: 10,
      fontWeight: 700,
      lineHeight: '14px',
      letterSpacing: '0.01em',
    },
    caption: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.01em',
    },
    headerCell: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.01em',
      color: colorsCommon.grey,
    },
  },
  mixins: {
    borderValue: (width?: string) => `${width ?? '1px'} solid ${theme.palette.divider}`,
    border: (width?: string) => ({
      border: theme.mixins.borderValue(width),
    }),
    borderControlValue: (width?: string) =>
      `${width ?? '1px'} solid ${theme.palette.common.light3}`,
    borderControl: (width?: string) => ({
      border: theme.mixins.borderControlValue(width),
    }),
    dropdownShadow: () => ({ boxShadow: '0px 10px 20px 0px #25275A0D' }),
    dropdownShadowTop: () => ({ boxShadow: '0px -10px 20px 0px #25275A0D' }),
    scrollBar: () => ({
      /* Hide scrollbar for IE, Edge and Firefox */
      '-ms-overflow-style': '-ms-autohiding-scrollbar', // IE and Edge
      scrollbarWidth: 'thin', // Firefox

      /* Hide scrollbar for Chrome, Safari and Opera */
      '&::-webkit-scrollbar': {
        width: theme.spacing(0.5),
      },

      '::-webkit-scrollbar-thumb': {
        borderRadius: theme.spacing(0.25),
        background: theme.palette.text.secondary,
      },

      '&::-webkit-scrollbar:horizontal': {
        height: theme.spacing(0.5),
      },
    }),
  },
  shape: {
    borderRadiusSm: 4,
    borderRadius: 8,
    borderRadiusLg: 16,
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  },

  components: components as Components,
});
