import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4C17 2.89543 16.1046 2 15 2H14V5H17V4ZM17 6H14C13.4477 6 13 5.55228 13 5V2H5C3.89543 2 3 2.89543 3 4V16C3 17.1046 3.89543 18 5 18H15C16.1046 18 17 17.1046 17 16V6ZM13 12H7V13H13V12ZM7 9H13V10H7V9Z"
      fill="#24275B"
    />
  </svg>
);
