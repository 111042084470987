import { ChipProps } from '@mui/material';
import {
  SchoolRelation,
  SchoolUserType,
  useGetParentMembership,
  useGetStaffMembership,
  useGetStudentMembership,
} from '@schooly/api';
import { AvatarAuth, AvatarAuthProps } from '@schooly/components/avatar-auth';
import { Spin, TagSelect } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { FC } from 'react';
import { useIntl } from 'react-intl';

export type UserTagSelectProps = {
  id: string;
  currentUser?: SchoolRelation;
  userType: SchoolUserType;
  schoolId: string;
} & Omit<ChipProps, 'label'>;

export const UserTagSelect: FC<UserTagSelectProps> = ({ userType, currentUser, ...props }) => {
  const { $t } = useIntl();

  if (currentUser && props.id === currentUser.relation_id) {
    return (
      <TagSelect
        size={props.size}
        avatar={props.size !== 'small' ? <UserAvatar user={currentUser} /> : undefined}
        label={$t({ id: 'filter-Me' })}
        {...props}
      />
    );
  }

  switch (userType) {
    case 'parent':
      return <ParentTagSelect {...props} />;
    case 'student':
      return <StudentTagSelect {...props} />;
    case 'staff':
      return <StaffTagSelect {...props} />;
    default:
      return null;
  }
};

const ParentTagSelect: FC<Omit<UserTagSelectProps, 'userType'>> = ({
  id,
  schoolId,
  size,
  ...props
}) => {
  const { data } = useGetParentMembership({ id, schoolId });

  return (
    <TagSelect
      size={size}
      avatar={data && size !== 'small' ? <UserAvatar user={data} /> : undefined}
      label={data ? getUserFullName(data) : <Spin />}
      {...props}
    />
  );
};

const StaffTagSelect: FC<Omit<UserTagSelectProps, 'userType'>> = ({
  id,
  schoolId,
  size,
  currentUser,
  ...props
}) => {
  const { data } = useGetStaffMembership({ id, schoolId });

  return (
    <TagSelect
      size={size}
      avatar={data && size !== 'small' ? <UserAvatar user={data} /> : undefined}
      label={data ? getUserFullName(data) : <Spin />}
      {...props}
    />
  );
};

const StudentTagSelect: FC<Omit<UserTagSelectProps, 'userType'>> = ({
  id,
  schoolId,
  size,
  ...props
}) => {
  const { data } = useGetStudentMembership({ id, schoolId });

  return (
    <TagSelect
      size={size}
      avatar={data && size !== 'small' ? <UserAvatar user={data} /> : undefined}
      label={data ? getUserFullName(data) : <Spin />}
      {...props}
    />
  );
};

export const UserAvatar: FC<AvatarAuthProps> = ({ user }) => {
  return (
    <AvatarAuth
      user={user}
      sx={(theme) => ({
        margin: '-1px',
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: theme.spacing(1.5),
      })}
    />
  );
};
