import { Box, IconButton, Stack, Typography } from '@mui/material';
import { SchoolProperty } from '@schooly/api';
import { CrossIcon, EditIcon, EyeIcon, Loading, ModalSmall } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import AccessDenied from '../../../components/common/AccessDenied';
import PersonCardSelectable from '../../../components/common/PersonCard/PersonCardSelectable';
import { SimpleButton } from '../../../components/uikit/SimpleButton/SimpleButton';
import { FormTextField } from '../../../components/uikit-components/FormTextField/FormTextField';
import {
  ModalContent,
  ModalMain,
  ModalPanel,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalConfirmationDialog } from '../../../components/uikit-components/Modal/ModalConfirmationDialog';
import { ModalEmptyMainArea } from '../../../components/uikit-components/Modal/ModalEmptyMainArea';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import {
  ModalHeaderInput,
  ModalHeaderTitle,
} from '../../../components/uikit-components/Modal/ModalHeader.styled';
import { ModalPeopleExtensionPanel } from '../../../components/uikit-components/Modal/ModalPeopleExtensionPanel';
import useFlag from '../../../hooks/useFlag';
import { useFormError } from '../../../hooks/useFormError';
import { LimitedToEmptyStub } from '../../Groups/AddGroupModal/LimitedToEmptyStub';
import { ellipsis } from '../../Groups/GroupPreviewModal/GroupPreviewModalInfo';
import {
  CreateUserRoleModalSection,
  UserRoleModalProps,
  useUserRoleModal,
} from './Context/useUserRoleModal';
import { LimitedToSection } from './LimitedTo/LimitedToSection';
import { UserRolePermissionsToAssign } from './PermissionsToAssign/PermissionsToAssign';
import { AddStaffExternalSidebar } from './UserRoleModalStaff/AddStaffExternalSidebar';
import { UserRolePermissions } from './UserRolePermissions/UserRolePermissions';

export const CreateUserRoleModal: FC<UserRoleModalProps> = (props) => {
  const { $t } = useIntl();

  const { renderError } = useFormError();

  const {
    form,
    isUserRoleManager,
    isUserRoleViewer,
    isFetching,
    isPermissionChecked,
    actions,
    mode,
    hasAccess,
    canManageRole,
    isPermissionsEmpty,
    checkingLimitedTo,
    ...state
  } = useUserRoleModal({ core: true, ...props });

  const [isConfirmationDialogOpen, showConfirmationDialog, hideConfirmationDialog] = useFlag();

  const hasLimitedTo = state.limitedToStaff?.school_property_ids.length > 0;

  const isSettingsMode = mode === CreateUserRoleModalSection.Settings;

  const submitDisabled = state.isSaving || state.isDeleting || !form.isValid;

  const onDeleteConfirm = useCallback(() => {
    hideConfirmationDialog();
    actions.deleteRole();
  }, [actions, hideConfirmationDialog]);

  const editIcon = <IconButton>{canManageRole ? <EditIcon /> : <EyeIcon />}</IconButton>;

  if (!hasAccess && !isFetching) {
    return (
      <ModalSmall open>
        <ModalHeader active>
          <IconButton onClick={actions.closeModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <AccessDenied />
      </ModalSmall>
    );
  }

  return (
    <ModalSmall open>
      {isFetching ? (
        <Loading />
      ) : (
        <form onSubmit={form.onSubmit(() => actions.submitRole())}>
          <ModalHeader
            active={isSettingsMode}
            multiline
            title={
              isSettingsMode ? (
                <ModalHeaderInput
                  placeholder={$t({ id: 'userRoles-UserRoleName' })}
                  value={state.roleName}
                  autoFocus={state.autoFocus === 'name'}
                  onChange={actions.onGroupNameChange}
                  error={Boolean(state.errors?.roleName)}
                  helperText={renderError(state.errors?.roleName)}
                />
              ) : (
                <ModalPanel disabled={!canManageRole} sx={{ padding: 0, overflow: 'hidden' }}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Box>
                      <ModalHeaderTitle
                        endIcon={<EditIcon />}
                        iconsOnHover={!!state.roleName}
                        onClick={() => actions.setMode(CreateUserRoleModalSection.Settings, 'name')}
                      >
                        {ellipsis(state.roleName ?? '', 35)}
                      </ModalHeaderTitle>
                    </Box>

                    {Boolean(state.errors?.roleName) && (
                      <Typography variant="body2" color="error">
                        {renderError(state.errors?.roleName)}
                      </Typography>
                    )}
                  </Stack>
                </ModalPanel>
              )
            }
          >
            <IconButton onClick={actions.closeModal}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalPanel
            withBorderBottom
            active={isSettingsMode}
            flat={isSettingsMode}
            disabled={!canManageRole}
          >
            {isSettingsMode ? (
              <Stack px={2.5} py={2.5}>
                <FormTextField
                  label={$t({ id: 'groups-GroupDescription' })}
                  multiline
                  fullWidth
                  minRows={2}
                  onChange={(e) => actions.onDescriptionChange(e.target.value)}
                  value={state.description}
                  autoFocus={state.autoFocus === 'description'}
                />
              </Stack>
            ) : (
              <Stack sx={{ flex: '1 1 30%' }}>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="groups-GroupDescription" />
                </Typography>
                <Box>
                  <SimpleButton
                    endIcon={<EditIcon />}
                    iconsOnHover={!!state.description}
                    onClick={(e) =>
                      actions.setMode(CreateUserRoleModalSection.Settings, 'description')
                    }
                  >
                    <Typography color="text.primary" variant="h3">
                      {ellipsis(state.description ?? '', 35)}
                    </Typography>
                  </SimpleButton>
                </Box>
              </Stack>
            )}
          </ModalPanel>

          {/* Permissions */}
          <ModalPeopleExtensionPanel
            titleId="userRoles-Permissions"
            addActionId={canManageRole ? 'userRoles-SetPermissions' : 'userRoles-ViewPermissions'}
            editActionId="groups-EditStaff"
            count={0}
            active={mode === CreateUserRoleModalSection.Permissions}
            onAddClick={() => actions.setMode(CreateUserRoleModalSection.Permissions)}
            sidebarContent={<></>}
            headerMode={true}
            editActionIcon={editIcon}
            errorId={(state.errors?.permissions as MessageDescriptor)?.id}
          >
            <ModalContent withBorderBottom active flat sx={{ pt: 0, px: 2.5 }}>
              <UserRolePermissions
                editable={canManageRole}
                isPermissionChecked={isPermissionChecked}
                onSelectPermission={actions.setSelectedPermissions}
              />
            </ModalContent>
          </ModalPeopleExtensionPanel>

          {/* Staff */}
          <ModalPeopleExtensionPanel
            titleId="section-Staff"
            addActionId="groups-AddStaff"
            editActionId="groups-EditStaff"
            count={state.selectedStuff?.length}
            active={mode === CreateUserRoleModalSection.Staff}
            onAddClick={() => actions.setMode(CreateUserRoleModalSection.Staff)}
            sidebarContent={
              hasLimitedTo ? (
                <AddStaffExternalSidebar
                  onStaffClick={actions.onStaffClick}
                  staff={state.selectedStuff ?? []}
                  limitedTo={state.limitedToStaff}
                />
              ) : undefined
            }
          >
            <ModalContent withBorderBottom active flat sx={{ pt: 0 }}>
              <Stack sx={{ overflowY: 'auto', px: 2.5 }}>
                {state.schoolStaffProperties?.length && (
                  <LimitedToSection
                    type="staff"
                    limitedTo={state.limitedToStaff}
                    hasLimitedTo={hasLimitedTo}
                    checkingLimitedTo={checkingLimitedTo}
                    schoolProperties={
                      (state.schoolStaffProperties as unknown as SchoolProperty[]) || []
                    }
                    selectedStuff={state.selectedStuff}
                    onLimitedToChange={actions.onLimitedToChange}
                    onOpen={actions.onLimitedToOpen}
                    onClose={actions.onLimitedToClose}
                    requiredCategories={['statuses']}
                  />
                )}

                {(() => {
                  if (!hasLimitedTo) {
                    return state.isLimitedToOpen ? null : <LimitedToEmptyStub userType="staff" />;
                  }

                  if (!state.selectedStuff?.length) {
                    return (
                      <ModalEmptyMainArea
                        label={$t({
                          id: 'userType-staff',
                        }).toLowerCase()}
                      />
                    );
                  }

                  return state.selectedStuff?.map((staff) => (
                    <PersonCardSelectable
                      key={staff.relation_id}
                      isStaticSelected
                      user={staff}
                      userType="staff"
                      onClick={() => actions.onStaffClick(staff)}
                      isListItem
                    />
                  ));
                })()}
              </Stack>
            </ModalContent>
          </ModalPeopleExtensionPanel>

          {/* Configuration */}
          <ModalPeopleExtensionPanel
            titleId="userRoles-Configuration"
            addActionId={
              canManageRole ? 'userRoles-SetConfiguration' : 'userRoles-ViewConfiguration'
            }
            editActionId="groups-EditStaff"
            count={0}
            active={mode === CreateUserRoleModalSection.Configuration}
            onAddClick={() => actions.setMode(CreateUserRoleModalSection.Configuration)}
            sidebarContent={<></>}
            headerMode={true}
            editActionIcon={editIcon}
          >
            {state.selectedPermissionsToAssign && (
              <ModalContent withBorderBottom active flat sx={{ paddingTop: 0 }}>
                {!!state.selectedPermissionsToAssign && (
                  <UserRolePermissionsToAssign
                    permissionsToAssign={state.selectedPermissionsToAssign}
                  />
                )}
              </ModalContent>
            )}
          </ModalPeopleExtensionPanel>

          <ModalMain />
          <ModalFooterWithActions
            saving={state.isSaving}
            deleting={state.isDeleting}
            showDeleteButton={canManageRole && state.isEditing}
            onDeleteClick={showConfirmationDialog}
            isNewItem={!state.isEditing}
            disabled={submitDisabled}
          />
        </form>
      )}

      <ModalConfirmationDialog
        onCancel={hideConfirmationDialog}
        onConfirm={onDeleteConfirm}
        open={isConfirmationDialogOpen}
        content={
          <FormattedMessage id="userRoles-deleteUserRole" values={{ userRole: state.roleName }} />
        }
      />
    </ModalSmall>
  );
};
