import {
  Assessment,
  ASSESSMENTS_FILTER_KEYS,
  GetAssessmentsQueryFilters,
  useGetAssessmentsQuery,
} from '@schooly/api';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';

import { pickOnlyParamsFromFilterKeys } from '../../utils';
import { AssessmentRecurrenceLabelSelect } from '../RecurrenceLabelSelect/AssessmentRecurrenceSelectLabel';
import { getRecurrences } from '../utils';
import { RecurrenceExpandedSelect } from './RecurrenceExpandedSelect';

type AssessmentRecurrenceExpandedSelectProps = PropsWithChildren<{
  schoolId: string;
  selectedId: string;
  onSelectRecurrence: (v: string) => void;
  onClear: () => void;
  onClose: () => void;
  filters?: GetAssessmentsQueryFilters;
}>;
export const AssessmentRecurrenceExpandedSelect: FC<AssessmentRecurrenceExpandedSelectProps> = ({
  schoolId,
  filters = {},
  selectedId,
  ...selectProps
}) => {
  const { data, hasNextPage, params, setParams, isLoading, isFetchingNextPage, fetchNextPage } =
    useGetAssessmentsQuery(
      {
        school_id: schoolId,
        query: '',
        filters: pickOnlyParamsFromFilterKeys(ASSESSMENTS_FILTER_KEYS, filters),
      },
      {
        refetchOnMount: 'always',
      },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const assessments = useMemo(
    () => data?.pages.reduce<Assessment[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );
  const recurrences = getRecurrences(assessments);

  const renderLabel = useCallback(
    (title: string) => {
      return <AssessmentRecurrenceLabelSelect title={title} id={selectedId} schoolId={schoolId} />;
    },
    [schoolId, selectedId],
  );

  return (
    <RecurrenceExpandedSelect
      {...selectProps}
      schoolId={schoolId}
      recurrences={recurrences}
      isLoading={isLoading || isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      handleChangeQuery={handleChangeQuery}
      hasNextPage={hasNextPage}
      query={params.query}
      renderLabel={renderLabel}
      selectedId={selectedId}
    />
  );
};
