import {
  AssessmentsGradeUpdate,
  GET_ASSESSMENT_GRADES_FOR_SCHOOL_QUERY,
  useArchiveAssessmentsGradeMutation,
  useCreateAssessmentsGradeMutation,
  useGetAssessmentGradeQuery,
  useUpdateAssessmentsGradeMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import { Loading } from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ModalSmall } from '../../../components/uikit-components/Modal/Modal.styled';
import { useRouter } from '../../../context/router/useRouter';
import { SchoolPropertyArchiveConfirmationModal } from '../SchoolPropertyArchiveConfirmationModal';
import { SchoolAssessmentsGradeModalContent } from './SchoolAssessmentsGradeModalContent';

export const SchoolAssessmentsGradeModal: FC = () => {
  const { $t } = useIntl();

  const { schoolId = '' } = useAuth();
  const { id: gradeId = '' } = useParams<'id'>();
  const { showNotification, showError } = useNotifications();
  const { goBack } = useRouter();
  const queryClient = useQueryClient();
  const [
    isArchiveConfirmationModalOpen,
    showArchiveConfirmationModal,
    hideArchiveConfirmationModal,
  ] = useFlag();
  const archiveAssessmentsGrade = useArchiveAssessmentsGradeMutation();
  const updateAssessmentsGrade = useUpdateAssessmentsGradeMutation();
  const createAssessmentsGrade = useCreateAssessmentsGradeMutation();

  const closeModal = goBack;

  const { data: grade, isFetching } = useGetAssessmentGradeQuery(gradeId, {
    enabled: !!gradeId,
    refetchOnMount: 'always',
  });

  const handleSave = useCallback(
    async (grade: AssessmentsGradeUpdate) => {
      if (!schoolId) return;
      /*

      We need to keep grades order value consistent to update description
      for assigned grades. Some previously created grades options order
      starts with 0, some with 1

      */
      const gradeOptionOrderStartsFrom = Math.min(...grade.options.map((o) => o.order || 0));

      const onSuccess = () => {
        showNotification({
          textId: gradeId ? 'assessments-GradeUpdated' : 'assessments-GradeSaved',
          values: { gradeName: grade.name },
          type: 'success',
        });
        queryClient.invalidateQueries([GET_ASSESSMENT_GRADES_FOR_SCHOOL_QUERY]);
        goBack();
      };

      if (gradeId) {
        updateAssessmentsGrade.mutate(
          {
            listId: gradeId,
            grade,
            gradeOptionOrderStartsFrom,
          },
          {
            onSuccess,
            onError: showError,
          },
        );

        return;
      }

      createAssessmentsGrade.mutate(
        { schoolId, grade },
        {
          onSuccess,
          onError: showError,
        },
      );
    },
    [
      createAssessmentsGrade,
      goBack,
      gradeId,
      queryClient,
      schoolId,
      showError,
      showNotification,
      updateAssessmentsGrade,
    ],
  );

  const handleArchive = useCallback(async () => {
    if (!grade?.name) return;

    archiveAssessmentsGrade.mutate(gradeId, {
      onError: showError,
      onSuccess: () => {
        showNotification({
          textId: 'assessments-GradeArchived',
          values: { gradeName: grade.name },
          type: 'success',
        });

        queryClient.invalidateQueries([GET_ASSESSMENT_GRADES_FOR_SCHOOL_QUERY]);
        closeModal();
      },
    });
  }, [
    archiveAssessmentsGrade,
    closeModal,
    grade?.name,
    gradeId,
    queryClient,
    showError,
    showNotification,
  ]);

  if (isFetching) {
    return (
      <ModalSmall open onClose={closeModal}>
        <Loading />
      </ModalSmall>
    );
  }

  return (
    <>
      <SchoolAssessmentsGradeModalContent
        grade={grade}
        isNewItem={!gradeId}
        isArchiving={archiveAssessmentsGrade.isLoading}
        onClose={closeModal}
        isSaving={createAssessmentsGrade.isLoading || updateAssessmentsGrade.isLoading}
        onArchiveClick={gradeId ? showArchiveConfirmationModal : undefined}
        onSubmit={handleSave}
      />

      {!!grade && (
        <SchoolPropertyArchiveConfirmationModal
          open={isArchiveConfirmationModalOpen}
          title={$t({ id: 'assessments-GradeArchiveConfirmAction' }, { gradeName: grade.name })}
          message={<FormattedMessage id="assessments-GradeArchiveConfirmActionInformation" />}
          onClose={hideArchiveConfirmationModal}
          onArchive={handleArchive}
          isArchiving={archiveAssessmentsGrade.isLoading}
        />
      )}
    </>
  );
};
