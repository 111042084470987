import {
  Box,
  FormControlLabel,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useGetAttendanceCodesQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { DATE_FORMAT } from '@schooly/constants';
import { ChartIcon, CrossIcon, EditIcon, Loading, ModalHeader } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import { CollapsiblePanel } from '../../../components/common/CollapsiblePanel';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import {
  ModalLarge,
  ModalMain,
  ModalSideBar,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import { useAttendanceRegister } from '../../../context/attendance/useAttendanceRegister';
import { useRouter } from '../../../context/router/useRouter';
import { AttendanceStats } from '../AttendanceStatistics/AttendanceStats';
import { AttendanceStatsPieChart } from '../AttendanceStatistics/AttendanceStatsPieChart';
import { AttendanceRegisterPreviewGrid } from './AttendanceRegisterPreviewGrid';

interface AttendanceRegisterPreviewModalProps {}

export const AttendanceRegisterPreviewModal: FC<AttendanceRegisterPreviewModalProps> = () => {
  const { $t } = useIntl();
  const { schoolId = '' } = useAuth();
  const { data: codes } = useGetAttendanceCodesQuery(schoolId);
  const navigate = useNavigate();
  const { closeAndClean: closeModal } = useRouter();
  const { register, fetching, canEdit, error, showByPresentAbsent, setShowByPresentAbsent } =
    useAttendanceRegister();

  const [showStats, setShowStats] = useState(false);

  const statistics = useMemo(
    () => register?.group_details.flatMap((d) => d.attendance_details) ?? [],
    [register?.group_details],
  );

  const renderContent = () => {
    if (fetching) return <Loading />;

    if (error)
      return (
        <>
          <ModalHeader withBorderBottom={false} active>
            <IconButton onClick={closeModal}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <AccessDenied />
        </>
      );

    return (
      <>
        <ModalHeaderV2
          subHeader={
            <Typography color="text.primary">
              {format(newDateTimezoneOffset(register?.register_date), DATE_FORMAT)}
            </Typography>
          }
          withGoBack
          active
          title={register?.name}
        >
          {canEdit && (
            <IconButton onClick={() => navigate(`/attendance/${register?.id}/edit`)}>
              <EditIcon />
            </IconButton>
          )}

          <IconButton onClick={closeModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeaderV2>
        <ModalMain>
          <CollapsiblePanel>
            <ModalSideBar
              sx={{
                paddingTop: (theme) => theme.spacing(1),
              }}
            >
              <MenuList>
                <MenuItem selected>
                  <FormattedMessage id="section-Groups" />
                </MenuItem>
              </MenuList>
            </ModalSideBar>
          </CollapsiblePanel>

          <Stack p={3} width="100%" gap={1} overflow="hidden">
            <Stack direction="row" alignItems="center" gap={4.5} pb={1.5}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h2">
                  <FormattedMessage id="section-Groups" />
                </Typography>
                <Box>
                  <Counter>{register?.group_details.length}</Counter>
                </Box>
              </Stack>

              <Stack direction="row" alignItems="center" gap={2} ml={'auto'}>
                {!!statistics.length && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showByPresentAbsent}
                        onChange={() => {
                          setShowByPresentAbsent(!showByPresentAbsent);
                        }}
                      />
                    }
                    label={
                      <Typography variant="h3">
                        <FormattedMessage id="filter-ShowByPresentAbsent" />
                      </Typography>
                    }
                    sx={{
                      m: 0,
                      // TODO we need to update the styles from the design to switch the component in the main theme
                      opacity: showStats ? 1 : 0.7,
                    }}
                  />
                )}

                {!!statistics.length && (
                  <IconButton
                    onClick={() => setShowStats((cur) => !cur)}
                    sx={{
                      color: showStats ? 'primary' : 'text.secondary',
                    }}
                  >
                    <ChartIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>

            {showStats && !!statistics.length && (
              <Stack>
                <AttendanceStats
                  statistics={statistics}
                  showPercents={false}
                  showByPresentAbsent={showByPresentAbsent}
                  renderChart={(data, total) => (
                    <Box sx={{ height: (theme) => theme.spacing(16) }}>
                      <AttendanceStatsPieChart
                        data={data}
                        total={total}
                        labelCountText={$t({ id: 'userType-student-plural' }).toLowerCase()}
                      />
                    </Box>
                  )}
                />
              </Stack>
            )}

            <AttendanceRegisterPreviewGrid
              codes={codes}
              groupDetails={register?.group_details}
              date={register?.register_date}
            />
          </Stack>
        </ModalMain>
      </>
    );
  };

  return (
    <ModalLarge open onClose={closeModal}>
      {renderContent()}
    </ModalLarge>
  );
};
