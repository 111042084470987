import { Icon, Skeleton, Stack } from '@mui/material';
import {
  FilterKeys,
  FilterSection,
  MESSAGE_ARRANGE_BY_FILTER_KEYS,
  MessageArrangeBy,
  MESSAGES_FILTER_KEYS,
  useGetFiltersQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  useChartOpenFromSearchParams,
  useChartZeroesFromSearchParams,
  WithCharts,
} from '@schooly/components/charts';
import { PageHeader, pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SkeletonRows,
} from '@schooly/style';
import React, { useMemo } from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import MessagesContent, { PAGE_SIZE, SKELETON_ROWS } from './MessagesContent';

const MessagesPage: React.FC = () => {
  const { schoolId = '', relationId = '' } = useAuth();
  const isChartsOpen = useChartOpenFromSearchParams();

  const { data: filters, isLoading: isLoadingFilters } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Message },
    { enabled: !!schoolId && !!relationId },
  );

  const showZeroValues = useChartZeroesFromSearchParams();

  const initialFiltersAndArrangeBy = useMemo(() => {
    let initialArrangeBy: MessageArrangeBy | null = null;

    if (isLoadingFilters) return;

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      for (const key of MESSAGE_ARRANGE_BY_FILTER_KEYS) {
        if (key === defaultFilter.filter.arrange_by) initialArrangeBy = key;
      }

      return {
        initialFilters: pickOnlyParamsFromFilterKeys(MESSAGES_FILTER_KEYS, defaultFilter.filter),
        initialArrangeBy,
      };
    }

    return {
      initialFilters: { [FilterKeys.Creator]: relationId ? [relationId] : [] },
      initialArrangeBy,
    };
  }, [filters, isLoadingFilters, relationId]);

  const renderContent = () => {
    if (!initialFiltersAndArrangeBy) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <PageHeader pageTitleTextId="section-Messages">
              <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
            </PageHeader>
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_ROWS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_ROWS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    return (
      <WithCharts schoolId={schoolId ?? ''} isOpen={isChartsOpen} showZeroValues={showZeroValues}>
        <MessagesContent {...initialFiltersAndArrangeBy} />
      </WithCharts>
    );
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};

export default MessagesPage;
