import { Icon, Stack, SxProps, Theme, Typography } from '@mui/material';
import { CreateChildForm, CreateParentForm } from '@schooly/components/applications';
import { StarIcon } from '@schooly/style';
import { getUserFullNameWithTitle } from '@schooly/utils/get-user-full-name-with-title';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { LongNameWithVerticalTooltip } from '../../../components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { PersonCardContainer } from './ApplicationCreateModal.styled';

type PersonCardUser = CreateChildForm | CreateParentForm;
interface ApplicationCreatePersonCardProps extends PropsWithChildren {
  user: PersonCardUser;
  isPrimaryContact?: boolean;
  sx?: SxProps<Theme>;
}

export const ApplicationCreatePersonCard: React.FC<ApplicationCreatePersonCardProps> = ({
  user,
  isPrimaryContact,
  children,
  sx,
}) => (
  <PersonCardContainer
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    minHeight={80}
    sx={sx}
  >
    <Stack maxWidth="85%" justifyContent="center">
      <LongNameWithVerticalTooltip>{getUserFullNameWithTitle(user)}</LongNameWithVerticalTooltip>
      {isPrimaryContact && (
        <Stack direction="row" pl={1}>
          <Icon
            fontSize="small"
            sx={{
              mr: 0.5,
              display: 'flex',
            }}
          >
            <StarIcon />
          </Icon>
          <Typography color="text.secondary">
            <FormattedMessage id="applications-PrimaryContact" />
          </Typography>
        </Stack>
      )}
    </Stack>
    {children}
  </PersonCardContainer>
);
