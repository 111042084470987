import { Icon, Stack, Typography } from '@mui/material';
import { DATE_FORMAT } from '@schooly/constants';
import { ArrowRightIcon, CalendarIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, isBefore, isSameDay, isToday } from 'date-fns';
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import {
  DateRangeSelectContent,
  DateRangeSelectContentProps,
  DateRangeSelectContentRef,
} from './DatePicker/DateRangeSelectContent';

type DateRangeSelectProps = {
  date: string[];
  onSetDate: (v: [Date, Date]) => void;
  singleDatePossible?: boolean;
  renderFooter?: (onClick: () => void, isFromDateToday: boolean) => ReactNode;
  fromDateError?: boolean;
  toDateError?: boolean;
  isFromDateDisabled?: boolean;
  DateRangeSelectContentProps?: Omit<
    DateRangeSelectContentProps,
    'fromDate' | 'toDate' | 'onSetDate'
  >;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const DateRangeSelect: FC<DateRangeSelectProps> = ({
  date,
  onSetDate,
  placeholder,
  onClear,
  singleDatePossible,
  renderFooter,
  toDateError,
  fromDateError,
  isFromDateDisabled,
  DateRangeSelectContentProps,
  ...dropdownProps
}) => {
  const { $t } = useIntl();
  const dateRangeSelect = useRef<DateRangeSelectContentRef>(null);
  const dropdown = useRef<DropdownSelect | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(
    date[0] ? newDateTimezoneOffset(date[0]) : null,
  );
  const prevFromDate = useRef(fromDate);
  const [toDate, setToDate] = useState<Date | null>(
    date[1] ? newDateTimezoneOffset(date[1]) : null,
  );
  const prevToDate = useRef(toDate);

  const isFromDateToday = fromDate ? isToday(fromDate) : false;

  const singleDateSelected = Boolean(
    singleDatePossible && fromDate && toDate && isSameDay(fromDate, toDate),
  );

  const toDateInInput = useMemo(() => {
    if (singleDateSelected) {
      return '';
    }
    if (toDate) {
      return format(toDate, DATE_FORMAT);
    } else {
      return $t({ id: 'datepicker-EndDate' });
    }
  }, [$t, singleDateSelected, toDate]);

  useEffect(() => {
    const fromDate = date[0] ? newDateTimezoneOffset(date[0]) : null;
    const toDate = date[1] ? newDateTimezoneOffset(date[1]) : null;
    if (!isFromDateDisabled) {
      setFromDate(fromDate);
      prevFromDate.current = fromDate;
    }
    setToDate(toDate);
    prevToDate.current = toDate;
  }, [date, isFromDateDisabled]);

  const handleSetDate = useCallback(
    (date: Date) => {
      if (fromDate && toDate) {
        if (!isFromDateDisabled) {
          setToDate(null);
          setFromDate(date);
        } else {
          setToDate(date);
        }
        return;
      }

      if (!fromDate) {
        return setFromDate(date);
      }

      if (isBefore(date, fromDate)) {
        setToDate(fromDate);
        if (!isFromDateDisabled) {
          return setFromDate(date);
        } else {
          return;
        }
      }

      setToDate(date);
    },
    [fromDate, isFromDateDisabled, toDate],
  );

  const handleClear = useMemo(
    () =>
      onClear
        ? () => {
            onClear?.();

            setFromDate(null);
            setToDate(null);
            prevFromDate.current = null;
            prevToDate.current = null;
          }
        : undefined,
    [onClear],
  );

  const handleClose = useCallback(() => {
    if (singleDatePossible && fromDate) {
      if (
        prevFromDate.current?.getTime() === fromDate.getTime() &&
        prevToDate.current?.getTime() === toDate?.getTime()
      ) {
        return;
      }
      onSetDate([fromDate, toDate ?? fromDate]);
    }

    if (!fromDate || (!toDate && !singleDatePossible)) {
      if (!isFromDateDisabled) {
        setFromDate(date[0] ? newDateTimezoneOffset(date[0]) : null);
      }
      setToDate(date[1] ? newDateTimezoneOffset(date[1]) : null);
    }
  }, [singleDatePossible, fromDate, toDate, onSetDate, isFromDateDisabled, date]);

  const onTodayButtonClick = useCallback(() => {
    if (!isFromDateDisabled) {
      setFromDate(new Date());
      setToDate(null);
      dateRangeSelect.current?.setVisibleMonthDate(new Date());
    } else {
      setToDate(new Date());
      dateRangeSelect.current?.setVisibleMonthDate(new Date());
    }
  }, [isFromDateDisabled]);

  useEffect(() => {
    if (singleDatePossible) return;

    if (!fromDate || !toDate) return;

    if (
      prevFromDate.current?.getTime() === fromDate.getTime() &&
      prevToDate.current?.getTime() === toDate.getTime()
    )
      return;

    prevFromDate.current = fromDate;
    prevToDate.current = toDate;

    onSetDate([fromDate, toDate]);
  }, [fromDate, toDate, onSetDate, date, singleDatePossible]);

  const renderContent = () => {
    return (
      <Stack justifyContent="center">
        <DateRangeSelectContent
          ref={dateRangeSelect}
          fromDate={fromDate}
          toDate={toDate}
          onSetDate={handleSetDate}
          {...DateRangeSelectContentProps}
        />
        <Stack mx={2}>{renderFooter?.(onTodayButtonClick, isFromDateToday)}</Stack>
      </Stack>
    );
  };

  const hasValues = !!fromDate || !!toDate;

  const fromDateColor = () => {
    if (isFromDateDisabled || !fromDate) return 'common.grey';
    if (fromDateError) return 'error.main';
    return 'primary.main';
  };

  const toDateColor = () => {
    if (!toDate) return 'common.grey';
    if (toDateError) return 'error.main';
    return 'primary.main';
  };

  return (
    <DropdownSelect
      ref={dropdown}
      {...dropdownProps}
      onClose={handleClose}
      onClear={handleClear}
      hasValues={hasValues}
      placeholder={(hasValues && placeholder) || ''}
      startIcon={
        <Icon sx={{ color: 'common.grey' }}>
          <CalendarIcon />
        </Icon>
      }
      maxExpandedContainerWidth={500}
      expandedContainerStyleProps={{
        maxHeight: 'unset',
      }}
      renderContent={renderContent}
    >
      {() => (
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography variant="h3" color={fromDateColor()} sx={{ width: 100 }}>
            {fromDate ? format(fromDate, DATE_FORMAT) : $t({ id: 'datepicker-StartDate' })}
          </Typography>
          {!singleDateSelected && (
            <Icon sx={{ color: 'common.grey' }}>
              <ArrowRightIcon />
            </Icon>
          )}
          <Typography variant="h3" color={toDateColor()} sx={{ width: 100 }}>
            {toDateInInput}
          </Typography>
        </Stack>
      )}
    </DropdownSelect>
  );
};
