import { Button, Stack, Typography } from '@mui/material';
import { IncompleteSvg, PlusIcon } from '@schooly/style';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

type EmptyTypesProps = {
  onAdd?: () => void;
  svg?: ReactNode;
  messageId?: string;
  productName?: string;
};
export const EmptyTypes: FC<EmptyTypesProps> = ({ onAdd, svg, messageId, productName }) => {
  const { $t } = useIntl();

  const textId = !!onAdd ? 'products-PleaseAddTypes' : 'products-NoTypes';

  return (
    <Stack flex={1} justifyContent="center" alignItems="center">
      {svg ?? <IncompleteSvg />}

      <Typography variant="h3" color="text.primary" mt={2.5} mb={2}>
        {$t({ id: messageId ?? textId }, { productName })}
      </Typography>
      {!!onAdd && (
        <Button startIcon={<PlusIcon />} onClick={onAdd}>
          {$t({ id: 'products-AddType' })}
        </Button>
      )}
    </Stack>
  );
};
