import { Box, Stack, Typography } from '@mui/material';
import { SimpleListResult } from '@schooly/api';
import { CheckIcon } from '@schooly/style';
import { FormattedMessage } from 'react-intl';

import PersonCardSelectable from '../../../../components/common/PersonCard/PersonCardSelectable';
import Button from '../../../../components/ui/Button';
import {
  ModalConfirm,
  ModalContent,
  ModalFooter,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeaderStyled } from '../../../../components/uikit-components/Modal/ModalHeader.styled';

export interface DeleteHistoryDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  deleteHistoryStaff?: SimpleListResult[];
  deleteHistoryStudents?: SimpleListResult[];
  loading?: boolean;
}

export const DeleteHistoryDialog: React.FC<DeleteHistoryDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  deleteHistoryStaff,
  deleteHistoryStudents,
  loading,
}) => {
  return (
    <ModalConfirm open={isOpen}>
      <ModalHeaderStyled active>
        <Box pt={2} pl={5} pr={8}>
          <Typography variant="h1">
            <FormattedMessage id="groups-removeHistoryWarning" />
          </Typography>
        </Box>
      </ModalHeaderStyled>

      <ModalContent active>
        <Stack pl={5}>
          <Box>
            {!!deleteHistoryStaff?.length && (
              <Typography variant="h4">
                <FormattedMessage id="section-Staff" />
              </Typography>
            )}
            {deleteHistoryStaff?.map((user) => (
              <PersonCardSelectable
                isSingularSelected={false}
                key={user.relation_id}
                user={user}
                isListItem
              />
            ))}
          </Box>

          <Box pt={!!deleteHistoryStaff?.length ? 2 : 0}>
            {!!deleteHistoryStudents?.length && (
              <Typography variant="h4">
                <FormattedMessage id="section-Students" />
              </Typography>
            )}
            {deleteHistoryStudents?.map((user) => (
              <PersonCardSelectable
                isSingularSelected={false}
                key={user.relation_id}
                user={user}
                isListItem
              />
            ))}
          </Box>
        </Stack>
      </ModalContent>
      <ModalFooter active>
        <Button type="button" color="secondary" onClick={onClose}>
          <FormattedMessage id="action-Cancel" />
        </Button>

        <Button
          color="primary"
          rightIcon={<CheckIcon />}
          type="submit"
          isLoading={loading}
          onClick={onConfirm}
        >
          <FormattedMessage id="action-Confirm" />
        </Button>
      </ModalFooter>
    </ModalConfirm>
  );
};
