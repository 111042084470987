import {
  ProfileUserType,
  RegistrationStatus,
  SchoolProperty,
  SchoolUserType,
  SchoolYear,
  StudentRegistration,
  StudentRegistrationUpdate,
  StudentSchoolRelationUpdate,
  UserType,
} from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';
import joi from 'joi';

import { UserContexts } from '../../../constants/userContexts';
import { isSchoolUserType } from '../../../helpers/misc';
import emptyString from '../../../utils/joi/emptyString';
import { UserFormData } from './profileEditSchema';

export const getUserNumberKey = (userType: UserType) => `${userType}_number`;

export const getUserNumberSchema = (userType: UserType) =>
  joi.object<StudentSchoolRelationUpdate>({
    should_generate_number: joi.boolean(),
    [getUserNumberKey(userType)]: emptyString(true, null),
  });

export const getUserDateOfBirthSchema = () =>
  joi.object<UserFormData>({
    date_of_birth: emptyString(false, null),
  });

export const getIsEditableForSchoolUserRelationFields = (
  userType: SchoolUserType | ProfileUserType,
  hasEditingPermission: boolean,
  canEditProfile: boolean,
) => {
  if (isSchoolUserType(userType)) {
    return hasEditingPermission;
  }

  return hasEditingPermission && canEditProfile;
};

export const getIsEditableForProfilePicture = (
  userType: SchoolUserType | ProfileUserType,
  hasEditingPermission: boolean,
  canEditProfile: boolean,
  userContext: UserContexts,
) => {
  if (userContext === UserContexts.Individual) return canEditProfile;

  if (isSchoolUserType(userType)) {
    return hasEditingPermission;
  }

  return false;
};

export function getNextSchoolYear(currentYearId: string, schoolYears: SchoolYear[]) {
  const currentYear = schoolYears.find((y) => y.id === currentYearId);
  if (!currentYear) return;
  const idx = schoolYears.indexOf(currentYear);
  return schoolYears[idx + 1];
}
export function isOpenedRegistration(statuses: Pick<RegistrationStatus, 'school_property'>[]) {
  return statuses.some((s, i, arr) => {
    const isCurrent = !!s.school_property.category?.current;
    return isCurrent && !arr.slice(i + 1).some((st) => st.school_property.category?.final);
  });
}

export type ValidateReEnrollmentChangeProps = {
  registration: StudentRegistration;
  updatedRegistration: StudentRegistrationUpdate;
  statuses: SchoolProperty[];
  ageGroupId?: string;
  sameAgeGroup?: boolean;
};

export function validateReEnrollmentChange({
  registration,
  updatedRegistration,
  statuses,
  ageGroupId,
  sameAgeGroup,
}: ValidateReEnrollmentChangeProps) {
  const isSchoolYearChanged = updatedRegistration.school_year_id !== registration.school_year.id;

  const updatedStatuses = updatedRegistration.statuses.reduce<
    Pick<RegistrationStatus, 'school_property'>[]
  >((acc, next) => {
    const status = statuses.find((st) => st.id === next.school_property_id);

    return status ? [...acc, { school_property: status }] : acc;
  }, []);

  const ageGroup = registration.school_properties.find(
    (p) => p.type === SchoolPropertyType.AgeGroup,
  );

  const isCurrentStatusRemoved = !updatedStatuses.some((s) => s.school_property.category?.current);

  const isRegistrationClosed = !isOpenedRegistration(updatedStatuses);
  const isAgeGroupRemoved = ageGroup && !ageGroupId;
  const isAgeGroupChanged = ageGroup && ageGroupId && ageGroupId !== ageGroup.id;
  const isSameAgeGroupChanged = registration.same_age_group !== sameAgeGroup;

  return (
    isSchoolYearChanged ||
    isAgeGroupRemoved ||
    isAgeGroupChanged ||
    isSameAgeGroupChanged ||
    isCurrentStatusRemoved ||
    isRegistrationClosed
  );
}
