import { Button, IconButton, Stack, Typography } from '@mui/material';
import { SchoolProperty, SelectOption } from '@schooly/api';
import {
  CheckIcon,
  CrossIcon,
  ModalConfirm,
  ModalContent,
  ModalHeader,
  ModalMain,
} from '@schooly/style';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import FormSelect2 from '../../../../components/ui/Input/FormSelect2';
import { ModalFooter } from '../../../../components/uikit-components/Modal/Modal.styled';
import { SchoolTuneStatus } from '../../SchoolTune/tabs/statuses/scheme';

export interface SchoolGeneralStatusesReplaceModalProps {
  group: string;
  field: { name?: SchoolProperty['name']; originId?: SchoolProperty['id'] };
  fields: SchoolTuneStatus[];
  onClose?: () => void;
  onReplace?: (replaceId: SchoolProperty['id']) => void;
  onArchiveWithoutReplacing?: () => void;
}

export interface SchoolGeneralStatusesReplaceForm {
  status?: SchoolProperty['id'];
}

export const SchoolGeneralStatusesReplaceModal: FC<SchoolGeneralStatusesReplaceModalProps> = ({
  group,
  field,
  fields,
  onClose,
  onReplace,
  onArchiveWithoutReplacing,
}) => {
  const { $t } = useIntl();

  const form = useForm<SchoolGeneralStatusesReplaceForm>({
    defaultValues: {
      status: undefined,
    },
  });

  const options = useMemo(
    () =>
      fields
        .filter((item) => !item.archived && item.originId && item.originId !== field.originId)
        .map<SelectOption>((item) => ({
          value: item.originId!,
          label: item.name,
          group,
        })),
    [field.originId, fields, group],
  );

  const handleSubmit = useCallback<SubmitHandler<SchoolGeneralStatusesReplaceForm>>(
    (data) => {
      if (data.status) {
        onReplace?.(data.status);
      }
    },
    [onReplace],
  );

  /* Archives the status without replace if no options to select available */
  useEffect(() => {
    if (!options.length) {
      onArchiveWithoutReplacing?.();
    }
  }, [onArchiveWithoutReplacing, options.length]);

  return options.length ? (
    <ModalConfirm open onClose={onClose}>
      <FormProvider {...form}>
        <form
          onSubmit={(event) => {
            event.stopPropagation();
            form.handleSubmit(handleSubmit)(event);
          }}
        >
          <ModalHeader
            title={$t({ id: 'school-tabs-Statuses-ArchiveReplaceTitle' }, { name: field.name })}
            withBorderBottom={false}
            multiline
            active
          >
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalMain>
            <ModalContent sx={{ pt: 0 }} active>
              <Stack direction="row" gap={2.5} alignItems="center">
                <Stack sx={(theme) => ({ height: theme.spacing(5) })} justifyContent="center">
                  <Typography variant="h2" whiteSpace="nowrap">
                    <FormattedMessage id="school-tabs-ArchiveReplaceWith" />
                  </Typography>
                </Stack>

                <FormSelect2
                  name="status"
                  labelTextId="schoolProperty-Status"
                  options={options}
                  rules={{
                    validate: (value) => {
                      return value ? true : 'school-tabs-Statuses-Archive-SelectRequired';
                    },
                  }}
                />
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooter active>
            <Button variant="outlined" onClick={onArchiveWithoutReplacing}>
              <FormattedMessage id="school-tabs-ArchiveWithoutReplacing" />
            </Button>
            <Button type="submit" startIcon={<CheckIcon />}>
              <FormattedMessage id="action-Replace" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalConfirm>
  ) : null;
};
