import { Stack, styled, Typography } from '@mui/material';

interface GroupCardLayoutProps {
  withBorder?: boolean;
}

export const GroupCardLayout = styled(Stack)<GroupCardLayoutProps>(({ theme, withBorder }) => ({
  border: withBorder ? theme.mixins.borderValue() : undefined,
  borderRadius: withBorder ? 300 : 0,
  width: '100%',
  height: '44px',
  flexDirection: 'row',
  alignItems: 'center',
  background: theme.palette.background.paper,
  justifyContent: 'space-between',
  overflow: 'hidden',
}));

export const Title = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
});
