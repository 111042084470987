import { Box, Components, Theme } from '@mui/material';

export const MuiRadio: Components<Theme>['MuiRadio'] = {
  defaultProps: {
    icon: <Box className="MuiRadio-icon" />,
    checkedIcon: <Box className="MuiRadio-icon MuiRadio-checkedIcon" />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(0.5),
      border: theme.mixins.borderValue(),
      backgroundColor: theme.palette.background.paper,

      '& ~ .MuiFormControlLabel-label': {
        ...theme.typography.h3,
        color: theme.palette.text.secondary,
      },

      '&.Mui-checked': {
        border: theme.mixins.borderControlValue(),
      },

      '&:not(.Mui-disabled)': {
        '&:hover, .MuiFormControlLabel-root:hover &': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.background.paper,
        },
      },

      '& .MuiRadio-icon': {
        width: theme.spacing(1.25),
        height: theme.spacing(1.25),
        borderRadius: '50%',
        backgroundColor: theme.palette.background.paper,
      },

      '& .MuiRadio-checkedIcon': {
        backgroundColor: theme.palette.primary.main,
      },

      '&.Mui-disabled': {
        borderColor: theme.palette.text.disabled,

        '&.Mui-checked': {
          backgroundColor: theme.palette.text.disabled,
        },

        '& .MuiRadio-checkedIcon': {
          backgroundColor: theme.palette.background.paper,
        },
      },

      '.Mui-error &': {
        borderColor: theme.palette.error.main,
        outline: `2px solid ${theme.palette.error.light}`,

        '& .MuiRadio-checkedIcon': {
          backgroundColor: theme.palette.error.main,
        },
      },
    }),
  },
};
