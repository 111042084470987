import { FC } from 'react';

export const ErrorSvg: FC = () => (
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M115.155 215.477L239 265V176.351C239 175.535 238.504 174.8 237.746 174.496L160.522 143.445L153.005 127.635C152.788 127.178 152.406 126.821 151.937 126.635L117.894 113.113C116.58 112.591 115.155 113.559 115.155 114.971V215.477Z"
      fill="#24275B"
    />
    <path
      d="M100.771 249.706L225 299.019V185L101 136V193L100.771 249.706Z"
      fill="white"
      stroke="#E7E9F4"
      stroke-miterlimit="10"
    />
    <path
      d="M101 226L225 275V186.35C225 185.534 224.504 184.8 223.748 184.495L146.89 153.519L139.374 137.711C139.156 137.254 138.773 136.895 138.302 136.71L103.734 123.078C102.421 122.56 101 123.528 101 124.939V226Z"
      fill="white"
      stroke="#E7E9F4"
      stroke-miterlimit="10"
    />
    <path
      d="M47.7701 140.824C54.466 135.338 53.2574 122.79 45.0708 112.799C36.8842 102.807 24.8197 99.1556 18.1238 104.642C11.428 110.129 12.6365 122.676 20.8231 132.668C29.0097 142.659 41.0743 146.311 47.7701 140.824Z"
      fill="#E7E9F4"
    />
    <path
      d="M272.267 132.88C284.25 118.256 286.019 99.8908 276.218 91.86C266.418 83.8293 248.759 89.1743 236.776 103.799C224.794 118.423 223.025 136.788 232.826 144.819C242.626 152.85 260.285 147.505 272.267 132.88Z"
      fill="#E7E9F4"
    />
    <path
      d="M64.4346 1C129.5 8.2959 148.018 76.3786 115.402 103.853C90.1822 125.093 71.4086 103.571 67.6398 91.1021C61.0884 69.2987 100.608 51.8278 121.46 118.33C142.488 185.466 86.8713 182.226 82.1163 158.309C77.2203 133.652 134.534 132.591 137 212.796"
      stroke="#24275B"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M193.5 54.7955C225.488 69.1705 215.891 137.492 177.71 135.132C157.633 133.899 154.076 117.978 159.112 111.99C165.276 104.628 190.249 106.953 175.878 155.174C165.03 191.56 140.057 177.787 140.973 166.375C141.924 154.258 173.941 152.849 147.735 215.089"
      stroke="#24275B"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26 114.296L38 132.296"
      stroke="#24275B"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M38 118.296L26 127.296"
      stroke="#24275B"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M264 107.296L247 132.296"
      stroke="#24275B"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M247 113.296L264 126.296"
      stroke="#24275B"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M101.524 250.595L225.5 299.5L195.533 257.006L173.263 225.404C173.039 225.087 172.728 224.841 172.366 224.698L54.9293 178.081C53.0816 177.347 51.4115 179.466 52.5564 181.092L101.524 250.595Z"
      fill="#E7E9F4"
    />
  </svg>
);
