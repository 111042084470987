import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { Loading } from '@schooly/style';
import React, { FC } from 'react';

import { ModalSmall } from '../../../../../components/uikit-components/Modal/Modal.styled';
import { useSchool } from '../../../../../hooks/useSchool';
import { SchoolSettingsLayout } from '../../../SchoolSettingsLayout';
import { SchoolTuneDepartmentsContent } from './SchoolTuneDepartmentsContent';

export const SchoolTuneDepartmentsModal: FC = () => {
  const { schoolId = '' } = useSchool();

  const { propertiesMap, isLoading } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Staff,
  });

  return (
    <SchoolSettingsLayout>
      <ModalSmall open>
        {isLoading ? (
          <Loading />
        ) : (
          <SchoolTuneDepartmentsContent departments={propertiesMap.department} />
        )}
      </ModalSmall>
    </SchoolSettingsLayout>
  );
};
