import { Card, Stack, Typography } from '@mui/material';
import { DropdownIcon } from '@schooly/style';
import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Header from '../../../components/ui/Header';
import { Dropdown, DropdownOption } from '../../../components/uikit-components/Dropdown/Dropdown';
import { DropdownMenu } from '../../../components/uikit-components/Dropdown/Dropdown.styled';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { SchoolApplicationsDirectLink } from './SchoolApplicationsDirectLink';
import { SchoolApplicationsEmbedForm } from './SchoolApplicationsEmbedForm';

export const SchoolApplications: FC = () => {
  const { $t } = useIntl();

  const [option, setOption] = useState<DropdownOption<'direct' | 'embed'> | undefined>();

  const options = useMemo<DropdownOption<'direct' | 'embed'>[]>(
    () => [
      { value: 'direct', label: $t({ id: 'school-sections-Applications-DirectLink' }) },
      { value: 'embed', label: $t({ id: 'school-sections-Applications-EmbedForm' }) },
    ],
    [$t],
  );

  return (
    <MainLayout>
      <Header pageTitleTextId="school-sections-Applications" />

      <Card sx={{ p: 2.5 }}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2" flex="0 0 350px">
            <FormattedMessage id="school-sections-Applications-Links" />
          </Typography>

          <Stack direction="row" whiteSpace="nowrap" minWidth={350} ml={-1.5}>
            <Dropdown
              value={option?.value}
              placeholder={$t({ id: 'school-sections-Applications-ChooseFormat' })}
              options={options}
              onChange={setOption}
              canClean={false}
              size="small"
              border
              icon={<DropdownIcon />}
              iconPlacement="end"
              sx={{ width: 500 }}
              PaperProps={{
                ...DropdownMenu.defaultProps?.PaperProps,
                sx: { width: 500 },
              }}
            />
          </Stack>
        </Stack>

        {option?.value === 'direct' && <SchoolApplicationsDirectLink />}
        {option?.value === 'embed' && <SchoolApplicationsEmbedForm />}
      </Card>
    </MainLayout>
  );
};
