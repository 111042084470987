import {
  RegistrationStatus,
  RegistrationStatusUpdate,
  SchoolProperty,
  SchoolYear,
  StaffRegistration,
  StudentRegistration,
  UserType,
} from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../config';
import formatDate from '../utils/formatDate';

export function getActualRegistrationStatus(registration: StudentRegistration | StaffRegistration) {
  const now = Date.now();

  const relevantDateStatuses = registration.statuses.filter(
    ({ applies_from, applies_to }) =>
      moment(applies_from).isSameOrBefore(now) && moment(applies_to).isSameOrAfter(now),
  );

  if (!relevantDateStatuses) {
    return undefined;
  }
  if (relevantDateStatuses.length === 1) {
    return relevantDateStatuses[0];
  }

  // If multiple statuses are found, return the latest one
  return relevantDateStatuses[relevantDateStatuses.length - 1];
}

export function getActualRegistrations(
  registrations: (StudentRegistration | StaffRegistration)[] | undefined,
) {
  if (!registrations) {
    return [];
  }

  const validRegistrations = registrations.filter((registration) => {
    const actualStatus = getActualRegistrationStatus(registration);

    return actualStatus && actualStatus.school_property.category;
  });

  const applyingOrCurrentRegistrations = validRegistrations.filter((registration) => {
    const actualStatus = getActualRegistrationStatus(registration)!;

    const category = actualStatus.school_property.category!;

    return (
      (category.current === false && category.final === false) ||
      category.current === true ||
      category.final === false
    );
  });

  if (applyingOrCurrentRegistrations.length) {
    return applyingOrCurrentRegistrations;
  }

  // If user has no applying or current registrations, check for final registrations
  const finalRegistrations = validRegistrations.filter((registration) => {
    const actualStatus = getActualRegistrationStatus(registration)!;

    const category = actualStatus.school_property.category!;
    return category.current === false && category.final === true;
  });

  if (finalRegistrations.length) {
    return finalRegistrations;
  }

  // If user has no final registrations, check for the remaining invalid/no status registrations
  return registrations.filter((registration) => {
    const actualStatus = getActualRegistrationStatus(registration);

    return !actualStatus || !actualStatus.school_property.category;
  });
}

export function getSortedStatuses(statuses: RegistrationStatus[]): RegistrationStatus[] {
  return statuses.slice(0).sort((statusA, statusB) => {
    const statusAMoment = moment(statusA.applies_from, DEFAULT_DATE_FORMAT);
    const statusBMoment = moment(statusB.applies_from, DEFAULT_DATE_FORMAT);

    // If two statuses have the same date, respect the initial array order
    if (statusAMoment.isSame(statusBMoment)) {
      const statusAIndex = statuses.findIndex(({ id }) => id === statusA.id);
      const statusBIndex = statuses.findIndex(({ id }) => id === statusB.id);

      return statusAIndex - statusBIndex;
    }

    return statusAMoment.diff(statusBMoment, 'days');
  });
}

export function getDisplayStatus(
  statuses: RegistrationStatus[],
  filterDate?: string,
): RegistrationStatus {
  let sortedStatuses = getSortedStatuses(statuses);

  if (filterDate) {
    sortedStatuses = sortedStatuses.filter(({ applies_from }) =>
      moment(applies_from, DEFAULT_DATE_FORMAT).isSameOrBefore(
        moment(filterDate, DEFAULT_DATE_FORMAT),
      ),
    );
  }

  return sortedStatuses[sortedStatuses.length - 1];
}

export function getSortedStudentRegistrations(registrations: StudentRegistration[] | undefined) {
  if (!registrations) {
    return [];
  }

  return registrations.slice(0).sort((registrationA, registrationB) => {
    const schoolYearAMoment = moment(registrationA.school_year.start, DEFAULT_DATE_FORMAT);
    const schoolYearBMoment = moment(registrationB.school_year.start, DEFAULT_DATE_FORMAT);

    const statusAMoment = moment(
      getDisplayStatus(registrationA.statuses)?.applies_from,
      DEFAULT_DATE_FORMAT,
    );
    const statusBMoment = moment(
      getDisplayStatus(registrationB.statuses)?.applies_from,
      DEFAULT_DATE_FORMAT,
    );

    if (schoolYearAMoment.isAfter(schoolYearBMoment)) {
      return -1;
    }

    if (schoolYearAMoment.isBefore(schoolYearBMoment)) {
      return 1;
    }

    if (statusAMoment.isAfter(statusBMoment)) {
      return -1;
    }

    if (statusAMoment.isBefore(statusBMoment)) {
      return 1;
    }

    return 0;
  });
}
export type RegistrationTuple<R> = [R[] | undefined, R[] | undefined, R[] | undefined] | [];

export function getRegistrationsByTimePeriods<R extends StudentRegistration | StaffRegistration>(
  registrations: R[] | undefined,
  userType: UserType,
): RegistrationTuple<R> {
  if (!registrations) {
    return [];
  }

  const now = Date.now();

  if (userType === 'student') {
    const current = (registrations as StudentRegistration[]).filter(
      ({ school_year }) =>
        moment(school_year?.start, DEFAULT_DATE_FORMAT).isSameOrBefore(now) &&
        moment(school_year?.end, DEFAULT_DATE_FORMAT).isSameOrAfter(now),
    );

    const future = (registrations as StudentRegistration[]).filter(
      ({ id, school_year }) =>
        moment(school_year?.start, DEFAULT_DATE_FORMAT).isAfter(now) &&
        !current.map((c) => c.id).includes(id),
    );

    const previous = (registrations as StudentRegistration[]).filter(
      ({ id, school_year }) =>
        moment(school_year?.end, DEFAULT_DATE_FORMAT).isBefore(now) &&
        !current.map((c) => c.id).includes(id),
    );

    return [
      getSortedStudentRegistrations(current),
      getSortedStudentRegistrations(future),
      getSortedStudentRegistrations(previous),
    ] as RegistrationTuple<R>;
  }

  const current = (registrations as StaffRegistration[]).filter(({ statuses }) => {
    const currentStatus = statuses.find(
      ({ school_property }) =>
        school_property.category?.current && !school_property.category?.final,
    );
    const finalStatus = statuses.find(({ school_property }) => school_property.category?.final);

    return (
      currentStatus &&
      moment(currentStatus.applies_from, DEFAULT_DATE_FORMAT).isBefore(now) &&
      (!finalStatus || moment(finalStatus.applies_from, DEFAULT_DATE_FORMAT).isAfter(now))
    );
  });

  const future = (registrations as StaffRegistration[]).filter(({ id, statuses }) => {
    if (current.map((c) => c.id).includes(id)) {
      return false;
    }

    const currentStatus = statuses.find(
      ({ school_property }) =>
        school_property.category?.current && !school_property.category?.final,
    );
    const applyingStatus = statuses.find(
      ({ school_property }) =>
        !school_property.category?.current && !school_property.category?.final,
    );

    const finalStatus = statuses.find(({ school_property }) => school_property.category?.final);

    if (currentStatus) {
      return moment(currentStatus.applies_from, DEFAULT_DATE_FORMAT).isAfter(now);
    }

    if (finalStatus) {
      return moment(finalStatus.applies_from, DEFAULT_DATE_FORMAT).isAfter(now);
    }

    return !!applyingStatus;
  });

  const previous = (registrations as StaffRegistration[]).filter(({ id, statuses }) => {
    const finalStatus = statuses.find(({ school_property }) => school_property.category?.final);

    return (
      (!finalStatus || moment(finalStatus.applies_from, DEFAULT_DATE_FORMAT).isBefore(now)) &&
      !current.map((c) => c.id).includes(id) &&
      !future.map((c) => c.id).includes(id)
    );
  });

  return [current, future, previous] as RegistrationTuple<R>;
}

export function validateStatuses(
  statuses: RegistrationStatusUpdate[],
  userType: SchoolUserRole,
  schoolProperties?: SchoolProperty[],
  selectedSchoolYear?: SchoolYear,
) {
  const errors: Record<string, any>[] = [];

  if (!statuses) return errors;

  statuses.forEach(({ school_property_id, applies_from }, index) => {
    const statusProperty = schoolProperties?.find(({ id }) => id === school_property_id);

    const appliesFromDateMoment = moment(applies_from, DEFAULT_DATE_FORMAT);

    if (selectedSchoolYear && userType === SchoolUserRole.Student) {
      if (appliesFromDateMoment.isAfter(moment(selectedSchoolYear.end, DEFAULT_DATE_FORMAT))) {
        errors[index] = {
          ...errors[index],
          applies_from: {
            messageTextId: 'error-StatusDateBoundsEnd',
            messageValues: {
              date: formatDate(selectedSchoolYear.end),
            },
          },
        };
        return;
      }

      if (
        statusProperty?.category?.current &&
        appliesFromDateMoment.isBefore(moment(selectedSchoolYear.start, DEFAULT_DATE_FORMAT))
      ) {
        errors[index] = {
          ...errors[index],
          applies_from: {
            messageTextId: 'error-StatusDateBoundsStart',
            messageValues: {
              date: formatDate(selectedSchoolYear.start),
            },
          },
        };
        return;
      }
    }

    if (index === 0) {
      return;
    }

    const previousDate = statuses[index - 1].applies_from;
    if (appliesFromDateMoment.isBefore(previousDate)) {
      errors[index] = {
        ...errors[index],
        applies_from: {
          messageTextId: 'error-StatusDateOrder',
        },
      };
    }
  });

  return errors;
}

export function isStudentRegistration(
  registration?: StudentRegistration | StaffRegistration,
): registration is StudentRegistration {
  return !!registration && 'school_year' in registration;
}
