import { Typography } from '@mui/material';
import { DATE_FORMAT_MOMENT as DATE_FORMAT } from '@schooly/constants';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';

import { SPACE } from '../../../constants/typography';

export interface StatusCellProps {
  isPublished?: boolean;
  date?: string;
}

export const StatusCell: React.FC<StatusCellProps> = ({ isPublished, date }) => {
  const { formatMessage } = useIntl();

  const currentStatus = isPublished ? (
    <>
      {formatMessage({ id: 'status-Published' })}
      {SPACE}
      {moment(date).format(DATE_FORMAT)}
    </>
  ) : (
    <> {formatMessage({ id: 'status-NotPublished' })}</>
  );

  return <Typography color={isPublished ? 'success.main' : 'inherit'}>{currentStatus}</Typography>;
};
