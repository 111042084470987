import { Child, UserType } from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading } from '@schooly/style';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import PersonCardSelectable from '../PersonCard/PersonCardSelectable';

import './index.scss';

interface FilteredUsersListProps {
  filteredUsers?: Child[];
  canShowMore: boolean;
  isFetching: boolean;
  userType: UserType;
  onShowMore?: VoidFunction;
  onUserSelect: (user: Child) => void;
}

const FilteredUsersList: React.FC<FilteredUsersListProps> = ({
  filteredUsers,
  canShowMore,
  userType,
  isFetching,
  onShowMore,
  onUserSelect,
}) => {
  const loaderRef = useInfiniteScroll(isFetching, onShowMore);
  return (
    <>
      <h4 className="text-muted">
        <FormattedMessage id="SearchResults" />
      </h4>
      {filteredUsers?.map((user) => (
        <PersonCardSelectable
          key={user.user_id}
          user={user}
          userType={userType === 'parent' ? 'student' : 'child'}
          onClick={() => onUserSelect(user)}
          isListItem
        />
      ))}
      {canShowMore && onShowMore && (
        <div className="py-3">
          {isFetching && <Loading />}
          <div ref={loaderRef} />
        </div>
      )}
    </>
  );
};

export default FilteredUsersList;
