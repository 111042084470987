import {
  ApplicationCreateCustomField,
  ApplicationCustomField,
  CustomFieldApplicableTo,
  CustomFieldDataType,
} from '@schooly/api';
import { CreateCustomField } from '@schooly/components/applications';

export const convertDataTypeToDefaultValue = (dataType: CustomFieldDataType) => {
  switch (dataType) {
    case CustomFieldDataType.STRING:
      return '';
    case CustomFieldDataType.NUMBER:
      return null;
    case CustomFieldDataType.DATE:
      return null;
    case CustomFieldDataType.SELECT:
      return [''];
    default:
      return '';
  }
};

export const convertCustomFieldsToApplicationCreateCustomFields = (
  custom_fields: CreateCustomField[],
): ApplicationCreateCustomField[] => {
  return custom_fields
    .filter(({ value, applicable_to, checked }) =>
      applicable_to.includes(CustomFieldApplicableTo.DefaultApplicationValues)
        ? checked && Boolean(value)
        : true,
    )
    ?.map(({ value, is_severe, id, data_type }) => {
      const isSelect = data_type === CustomFieldDataType.SELECT;
      const isEmpty = value === '' || value === undefined || value === null;

      if (isEmpty) {
        return {
          custom_field_id: id,
          custom_field_value: convertDataTypeToDefaultValue(data_type),
          custom_field_value_severe: is_severe,
        };
      }

      return {
        custom_field_id: id,
        custom_field_value: isSelect ? [value] : value,
        custom_field_value_severe: is_severe,
      };
    });
};

export const convertCustomFieldsToApplicationCustomFields = (
  custom_fields: CreateCustomField[],
): ApplicationCustomField[] => {
  return custom_fields?.map(({ id, ...rest }) => ({
    ...rest,
    custom_field_id: id,
    value_id: id,
  }));
};
