import { MenuItem, Stack } from '@mui/material';
import { AnnualPlanRecordTypes, DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateRangeSelect } from '@schooly/components/filters';
import { ControlSelect } from '@schooly/components/form-select';
import { isDateInPast } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { AnnualPlannerCreateForm } from '../scheme';
import { AnnualPlannerCreateAssessment } from './forms/AnnualPlannerCreateAssessment';
import { AnnualPlannerCreateEvent } from './forms/AnnualPlannerCreateEvent';
import { AnnualPlannerCreateReport } from './forms/AnnualPlannerCreateReport';
import { AnnualPlannerCreateSchoolPeriod } from './forms/AnnualPlannerCreateSchoolPeriod';
import { useAnnualPlannerCreateRecord } from './WithAnnualPlannerCreate';

export const AnnualPlannerCreatePopoverContent: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const { $t } = useIntl();
  const { typeOptions, record } = useAnnualPlannerCreateRecord();
  const { control, watch } = useFormContext<AnnualPlannerCreateForm>();

  const type = watch('type');
  const date = watch('date.0');

  const isDatePast = Boolean(date && isDateInPast(date));

  /* TODO (https://schooly.atlassian.net/browse/TR-5786): Disabled as might lead to unpredictable UI bugs in edge cases
      like top-direction opener or so */
  /* Can not use MuiSelect.defaultOpen as the select is within animated Popover */
  // useEffect(() => {
  //   if (!type) {
  //     setTimeout(
  //       () =>
  //         ref.current?.querySelector<HTMLInputElement>('.MuiSelect-select')?.dispatchEvent(
  //           new MouseEvent('mousedown', {
  //             button: 0,
  //             bubbles: true,
  //             cancelable: true,
  //             view: window,
  //           }),
  //         ),
  //       300,
  //     );
  //   }
  // }, [type]);

  return (
    <Stack ref={ref} gap={2}>
      <ControlSelect
        name="type"
        label={$t({ id: 'annualPlanner-Popover-Type' })}
        control={control}
        rules={{ required: true }}
        renderValue={(v) => typeOptions.find((c) => c.value === v)?.label}
        locked={!!record}
        fullWidth
      >
        {
          /* TR-5815: for a new record, should not display Event and Holiday types in case of a past date */
          (!record && isDatePast
            ? typeOptions.filter(
                (option) =>
                  ![AnnualPlanRecordTypes.EVENT, AnnualPlanRecordTypes.HOLIDAY].includes(
                    option.value,
                  ),
              )
            : typeOptions
          ).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        }
      </ControlSelect>

      {!type && (
        <Controller
          control={control}
          name="date"
          render={({ field, fieldState }) => {
            const generalError = fieldState.error;
            const fromDateError = generalError?.type === 'startDate';
            const toDateError = generalError?.type === 'endDate';

            return (
              <DateRangeSelect
                singleDatePossible
                date={field.value || []}
                fromDateError={fromDateError}
                toDateError={toDateError}
                error={generalError}
                onSetDate={([startDate, endDate]) => {
                  field.onChange([
                    format(startDate, DEFAULT_DATE_FORMAT_FNS),
                    format(endDate, DEFAULT_DATE_FORMAT_FNS),
                  ]);
                }}
              />
            );
          }}
        />
      )}

      {type === AnnualPlanRecordTypes.SCHOOL_PERIOD && <AnnualPlannerCreateSchoolPeriod />}
      {type === AnnualPlanRecordTypes.HOLIDAY && <AnnualPlannerCreateEvent holiday />}
      {type === AnnualPlanRecordTypes.EVENT && <AnnualPlannerCreateEvent />}
      {type === AnnualPlanRecordTypes.ASSESSMENT && <AnnualPlannerCreateAssessment />}
      {type === AnnualPlanRecordTypes.REPORT && <AnnualPlannerCreateReport />}
    </Stack>
  );
};
