import {
  ConsentForm,
  ConsentFormReference,
  DEFAULT_DATE_FORMAT_FNS,
  EventsStatuses,
  MessageStatus,
  SignUpStatuses,
} from '@schooly/api';
import { EventsSmallIcon, MailIcon, SignUpIcon } from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { format } from 'date-fns';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useRouter } from '../../context/router/useRouter';
import { retrieveApiDate } from '../../helpers/misc';

type ReferenceInfo = {
  date: string | number;
  path: string;
  icon: ReactNode;
  name: string;
  title: string;
  canEditConsentForm: boolean;
  description: string;
  canRemoveConsentForm: boolean;
};

export const useReferenceInfo = (reference: ConsentFormReference): ReferenceInfo => {
  const { type, data } = reference;
  const { $t } = useIntl();
  switch (type) {
    case 'event': {
      const { date_times, end, event_status, title, start, id, description, can_be_edited } = data;
      const endTime = date_times[date_times.length - 1]?.[1];
      const dateInPast = isDateInPast(end, endTime);

      const isCanceledOrPast = dateInPast || event_status === EventsStatuses.Canceled;
      const isDraftOrPublished =
        event_status === EventsStatuses.Draft || event_status === EventsStatuses.Published;

      return {
        date: start,
        path: `/events/${id}`,
        icon: <EventsSmallIcon />,
        name: $t({ id: 'event-type' }),
        title: title,
        description: description,
        canEditConsentForm:
          Boolean(can_be_edited) && event_status !== EventsStatuses.Published && !isCanceledOrPast,
        canRemoveConsentForm: isDraftOrPublished && !isCanceledOrPast,
      };
    }
    case 'message': {
      const { created_at, status, body, title, id } = data;
      const date = retrieveApiDate(created_at);
      const isDraftOrSubmitted =
        status === MessageStatus.Draft || status === MessageStatus.Submitted;

      return {
        date: format(date ?? new Date(), DEFAULT_DATE_FORMAT_FNS),
        path: `/messages/${id}`,
        icon: <MailIcon />,
        name: $t({ id: 'messages-MessageTitle' }),
        title: title,
        description: body,
        canEditConsentForm: status !== MessageStatus.Published,
        canRemoveConsentForm: isDraftOrSubmitted,
      };
    }

    default: {
      const { status, id, title, end, description, can_be_edited, event } = data;
      const isDraftOrOpened = status === SignUpStatuses.Draft || status === SignUpStatuses.Open;

      return {
        date: end,
        path: `/signups/${id}`,
        icon: <SignUpIcon />,
        name: $t({ id: 'events-header-SignUp-Title' }),
        title: title,
        description: description ?? event?.description ?? '',
        canEditConsentForm: Boolean(can_be_edited) && status === SignUpStatuses.Draft,
        canRemoveConsentForm: isDraftOrOpened,
      };
    }
  }
};

export const useConsentFormBackground = (reference?: ConsentForm['reference']) => {
  const { setBackground, getBackground, clean } = useRouter();
  const background = getBackground();

  if (background.element) return;

  const path = (() => {
    if (reference?.type === 'event') return 'events';
    if (reference?.type === 'signup') return 'signups';
    if (reference?.type === 'message') return 'messages';
  })();

  if (path) {
    clean();
    setBackground(path);
  }
};
