import { Components, Theme } from '@mui/material';

import { CheckboxIcon, RadioOffIcon } from '../../assets/assets';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    icon: <RadioOffIcon className="reset-svg-currentColor" />,
    checkedIcon: <CheckboxIcon className="reset-svg-currentColor" />,
    disableRipple: true,
    color: 'default',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      fontSize: theme.spacing(2.5),

      '&:hover': {
        backgroundColor: 'transparent',
        '.svg-icon': {
          color: theme.palette.text.primary,
        },
      },

      '.svg-icon': {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
      },

      '& ~ .MuiFormControlLabel-label': {
        ...theme.typography.h3,
        color: undefined,
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,

        '& .svg-icon circle': ownerState.checked
          ? {
              fill: theme.palette.text.disabled,
            }
          : {
              stroke: theme.palette.text.disabled,
            },
      },

      // '.MuiFormControlLabel-labelPlacementStart & ~ .MuiFormControlLabel-label': {
      //   marginRight: theme.spacing(),
      // },
      //
      // '.MuiFormControlLabel-labelPlacementEnd & ~ .MuiFormControlLabel-label': {
      //   marginLeft: theme.spacing(),
      // },
      //
      // '.MuiFormControlLabel-labelPlacementTop & ~ .MuiFormControlLabel-label': {
      //   marginBottom: theme.spacing(),
      // },
      //
      // '.MuiFormControlLabel-labelPlacementBottom & ~ .MuiFormControlLabel-label': {
      //   marginTop: theme.spacing(),
      // },
    }),
  },
};
