import { Stack, Typography } from '@mui/material';
import { Nationalities } from '@schooly/constants';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { $enum } from 'ts-enum-util';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectSearchInput } from '../SelectSearchInput';
import { renderNationalityTags } from './NationalityExpandedSelect';

type NationalitySelectMultipleProps = PropsWithChildren<{
  selectedNationalities: Nationalities[];
  onSelectNationality: (v: Nationalities) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

const OPTIONS = $enum(Nationalities).map((value, key) => ({ value, label: key }));

export const NationalitySelectMultiple: FC<NationalitySelectMultipleProps> = ({
  selectedNationalities,
  onSelectNationality,
  placeholder,
  ...dropdownProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState('');
  const renderContent = useCallback(() => {
    const result = OPTIONS.filter(({ label }) =>
      label.toLowerCase().includes(query.toLowerCase()),
    ).map((v) => v.value);

    if (!result.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderNationalityTags({
          nationalities: result,
          onClick: (nationality) => {
            onSelectNationality(nationality);
            setQuery('');
          },
          tagProps: (nationality) => ({
            variant: selectedNationalities.includes(nationality) ? 'filled' : undefined,
          }),
        })}
      </Stack>
    );
  }, [onSelectNationality, query, selectedNationalities]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selectedNationalities.length}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
    >
      {(opened) => (
        <>
          {renderNationalityTags({
            nationalities: selectedNationalities,
            tagProps: { size: placeholder ? 'small' : undefined, sx: { maxWidth: 200 } },
            onDelete: opened ? onSelectNationality : undefined,
          })}
          {opened && (
            <SelectSearchInput ref={inputRef} autoFocus value={query} onChangeText={setQuery} />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
