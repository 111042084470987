import {
  ApiError,
  AvailableCriteria,
  ConsentFormShort,
  Group,
  IListGroupArguments,
  IncomingAttachment,
  MessageInteractor,
  MessageStatus,
  Recipient,
  SimpleListResult,
} from '@schooly/api';
import { createContext } from 'react';
import { UseFormReturn as UseReactHookFormReturn } from 'react-hook-form-lts';

import { CreateConsentForm } from '../../components/common/ConsentForms/ConsentForm';
import { AttachmentFile } from '../../components/ui/Input/RichText/Attachments/attachment-types';
import { AppLocation } from '../../hooks/useAppLocation';
import { FormState, UseFormReturn } from '../../hooks/useForm';

export enum MessagesCreateModalMode {
  Title,
  Recipients,
  RecipientsParents,
  RecipientsStaff,
  Message,
}

export enum ParentsSelectBy {
  StudentCriteria = 'SelectByStudentCriteria',
  IndividualStudent = 'SelectByIndividualStudent',
  IndividualParent = 'SelectIndividualParent',
}

export enum StaffSelectBy {
  StaffCriteria = 'SelectByStaffCriteria',
  IndividualStaff = 'SelectIndividualStaff',
  IndividualStudent = 'SelectByIndividualStudent',
}

export type MessageForm = {
  title: string;
  body: string;
};

export interface MessageContextState extends FormState<MessageContextState> {
  title: string;
  body: string;
  mode: MessagesCreateModalMode;
  autoFocus?: string;
  attachments: AttachmentFile[] | IncomingAttachment[];
  saving: boolean;
  savingAction?: 'close' | 'preview';
  deleting: boolean;
  deletingConfirmation: boolean;
  status?: MessageStatus;
  creator?: MessageInteractor;
  publisher?: MessageInteractor;
  criteriaDate?: string;
  prevCriteriaDate?: string;
  publishDate?: Date;
  parents: SimpleListResult[];
  parentsCriteria: AvailableCriteria[];
  parentsByIndividualStudent: SimpleListResult[];
  parentsSelectBy: ParentsSelectBy;
  staff: SimpleListResult[];
  staffCriteria: AvailableCriteria[];
  staffByIndividualStudent: SimpleListResult[];
  staffSelectBy: StaffSelectBy;
  actualParents: Recipient[];
  actualStaff: Recipient[];
  linkedConsentForm?: ConsentFormShort;
  consentForm?: UseReactHookFormReturn<CreateConsentForm, any>;
  messageForm: UseReactHookFormReturn<MessageForm, any>;
  consentFormShowed: boolean;
}

export interface MessageCreateLocation extends AppLocation {
  state: AppLocation['state'] & Partial<MessageContextState>;
}

export interface MessageContextProps extends MessageContextState {
  messageId?: string;
  fetching: boolean;
  fetchingAttachments: Record<string, boolean>;
  form: UseFormReturn<MessageContextState>;
  criteriaDateObj?: Date;
  hasCreatorPermission: boolean;
  hasPublisherPermission: boolean;
  hasViewerPermission: boolean;
  canSave: boolean;
  canEdit: boolean;
  canPublish: boolean;
  canSubmit: boolean;
  canUnsubmit: boolean;
  hasParents: boolean;
  hasStaff: boolean;
  hasRecipients: boolean;
  isPublished: boolean;
  isDraft: boolean;
  isSubmitted: boolean;
  isCreator: boolean;
  isPublishInProgress: boolean;
  isSubmitInProgress: boolean;
  isUnsubmitInProgress: boolean;
  isRemoveGroupDialogOpen: boolean;
  notActualGroups: AvailableCriteria[];
  error: ApiError | null;

  actions: {
    setMode: (mode: MessagesCreateModalMode, autoFocus?: string) => void;
    setCriteriaDate: (criteriaDate?: Date | null) => void;
    setAttachments: (attachments?: MessageContextState['attachments']) => void;
    setParentsSelectBy: (selectBy: MessageContextState['parentsSelectBy']) => void;
    setStaffSelectBy: (selectBy: MessageContextState['staffSelectBy']) => void;
    fetchAttachment: (attachmentId?: string) => void;
    handleClose: () => void;
    handlePreview: (id: string) => void;
    saveMessage: (savingAction?: MessageContextState['savingAction']) => void;
    deleteMessage: () => void;
    setDeletingConfirmation: (deletingConfirmation: boolean) => void;
    cancelDeletingConfirmation: () => void;
    publishMessage: () => Promise<void>;
    submitMessage: () => Promise<void>;
    unsubmitMessage: () => Promise<void>;
    addParent: (student: SimpleListResult) => void;
    removeParent: (student: SimpleListResult) => void;
    addParentsCriteria: (criteria: AvailableCriteria) => void;
    addParentsCriteriaMultiple: (criteria: AvailableCriteria[]) => void;
    removeParentsCriteria: (criteria: AvailableCriteria) => void;
    removeParentsCriteriaMultiple: (criteria: AvailableCriteria[]) => void;
    addParentByIndividualStudent: (student: SimpleListResult) => void;
    removeParentByIndividualStudent: (student: SimpleListResult) => void;
    addStaff: (student: SimpleListResult) => void;
    removeStaff: (student: SimpleListResult) => void;
    addStaffCriteria: (criteria: AvailableCriteria) => void;
    addStaffCriteriaMultiple: (criteria: AvailableCriteria[]) => void;
    removeStaffCriteria: (criteria: AvailableCriteria) => void;
    removeStaffCriteriaMultiple: (criteria: AvailableCriteria[]) => void;
    addStaffByIndividualStudent: (student: SimpleListResult) => void;
    removeStaffByIndividualStudent: (student: SimpleListResult) => void;
    fetchGroups: (params: IListGroupArguments) => Promise<Group[]>;
    handleConfirmRemoveGroupDialog: () => void;
    handleCloseRemoveGroupDialog: () => void;
    showConsentForm: () => void;
    hideConsentForm: () => void;
  };
}

export const CONTEXT_NAME = 'MessageContext';

export const getInitialState = (): MessageContextState => ({
  mode: MessagesCreateModalMode.Title,
  title: '',
  body: '',
  autoFocus: undefined,
  attachments: [],
  saving: false,
  savingAction: undefined,
  deleting: false,
  deletingConfirmation: false,
  status: undefined,
  creator: undefined,
  publisher: undefined,
  criteriaDate: undefined,
  prevCriteriaDate: undefined,
  parents: [],
  parentsCriteria: [],
  parentsByIndividualStudent: [],
  parentsSelectBy: ParentsSelectBy.StudentCriteria,
  staff: [],
  staffCriteria: [],
  staffByIndividualStudent: [],
  staffSelectBy: StaffSelectBy.StaffCriteria,
  actualParents: [],
  actualStaff: [],
  consentFormShowed: false,
  messageForm: {} as any as UseReactHookFormReturn<MessageForm, any>,
});

export const MessageContext = createContext<MessageContextProps>({
  ...getInitialState(),
  form: {} as UseFormReturn<MessageContextState>,
  messageId: undefined,
  fetching: false,
  fetchingAttachments: {},
  criteriaDateObj: undefined,
  hasCreatorPermission: false,
  hasPublisherPermission: false,
  hasViewerPermission: false,
  canEdit: false,
  canPublish: false,
  canSubmit: false,
  canUnsubmit: false,
  canSave: false,
  hasParents: false,
  hasStaff: false,
  hasRecipients: false,
  isPublished: false,
  isDraft: false,
  isSubmitted: false,
  isCreator: false,
  isPublishInProgress: false,
  isSubmitInProgress: false,
  isUnsubmitInProgress: false,
  isRemoveGroupDialogOpen: false,
  notActualGroups: [],
  error: null,
  actions: {
    setMode: () => {},
    setCriteriaDate: () => {},
    setAttachments: () => {},
    setParentsSelectBy: () => {},
    setStaffSelectBy: () => {},
    fetchAttachment: () => {},
    handleClose: () => {},
    handlePreview: () => {},
    saveMessage: () => {},
    deleteMessage: () => {},
    setDeletingConfirmation: () => {},
    cancelDeletingConfirmation: () => {},
    publishMessage: async () => {},
    submitMessage: async () => {},
    unsubmitMessage: async () => {},
    addParent: () => {},
    removeParent: () => {},
    addParentsCriteria: () => {},
    addParentsCriteriaMultiple: () => {},
    removeParentsCriteria: () => {},
    removeParentsCriteriaMultiple: () => {},
    addParentByIndividualStudent: () => {},
    removeParentByIndividualStudent: () => {},
    addStaff: () => {},
    removeStaff: () => {},
    addStaffCriteria: () => {},
    addStaffCriteriaMultiple: () => {},
    removeStaffCriteria: () => {},
    removeStaffCriteriaMultiple: () => {},
    addStaffByIndividualStudent: () => {},
    removeStaffByIndividualStudent: () => {},
    fetchGroups: async () => [],
    handleConfirmRemoveGroupDialog: () => {},
    handleCloseRemoveGroupDialog: () => {},
    showConsentForm: () => {},
    hideConsentForm: () => {},
  },
});

MessageContext.displayName = CONTEXT_NAME;
