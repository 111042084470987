import { Box, IconButton, Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, PaymentFrequencyType } from '@schooly/api';
import { DateSelect, DropdownSelectProps } from '@schooly/components/filters';
import { toOrdinal } from '@schooly/components/recurring';
import { CrossIcon, ModalHeader } from '@schooly/style';
import { format } from 'date-fns';
import { FC, PropsWithChildren } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

export const MIN_DUE_DATE_NUMBER = 1;
export const MAX_DUE_DATE_NUMBER = 9999999999;
export const MAX_MONTH_GENERATION_DATE_NUMBER = 28;

type FrequencyHeaderProps = {
  title: string;
  onClose: () => void;
  yearName: string;
};

export const FrequencyHeader: FC<PropsWithChildren<FrequencyHeaderProps>> = ({
  title,
  yearName,
  onClose,
  children,
}) => {
  return (
    <ModalHeader
      active
      title={
        <Stack>
          <Typography variant="h2">{title}</Typography>
          <Typography color="common.grey2">{yearName}</Typography>
        </Stack>
      }
      sx={{
        pt: 2,
        pb: 1,
      }}
    >
      <Stack direction="row" gap={1} pt={1}>
        {children}
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </Stack>
    </ModalHeader>
  );
};

type PeriodResultProps = {
  type: PaymentFrequencyType;
  generationDate: string;
  invoiceDate: string;
  dueDayCount: number;
};

export const PeriodResult: FC<PeriodResultProps> = ({
  generationDate,
  invoiceDate,
  dueDayCount,
}) => {
  const { $t } = useIntl();
  return (
    <FrequencyResultLayout>
      <Typography variant="h3" color="common.grey2">
        {$t({ id: 'frequencies-Result' })}
      </Typography>
      <Typography variant="h3">
        {$t({ id: 'frequencies-PeriodResultInvoiceGenerated' }, { generationDate })}.
      </Typography>
      <Typography variant="h3">
        {$t({ id: 'frequencies-PeriodResultInvoiceDate' }, { invoiceDate })}.
      </Typography>
      <Typography variant="h3">
        {$t(
          { id: 'frequencies-PeriodResultPaymentDeadline' },
          {
            dueDayTitle: `${dueDayCount} ${$t({
              id: dueDayCount > 1 ? 'days' : ' day',
            }).toLowerCase()}`,
          },
        )}
        .
      </Typography>
    </FrequencyResultLayout>
  );
};

export const PeriodsResult: FC<PropsWithChildren> = ({ children }) => {
  return (
    <FrequencyResultLayout>
      <Stack
        gap={0.75}
        sx={{
          '.MuiStack-root:not(:last-child)': {
            borderBottom: (theme) => theme.mixins.borderValue(),
          },
        }}
      >
        <PeriodResultHeader />
        {children}
      </Stack>
    </FrequencyResultLayout>
  );
};

export const PeriodResultHeader = () => {
  const { $t } = useIntl();
  return (
    <Stack direction="row" alignItems="center" gap={3} pb={0.2}>
      <PeriodResultCell variant="h3" color="common.grey2" flex="1 1 100px">
        {$t({ id: 'frequencies-Result' })}
      </PeriodResultCell>
      <PeriodResultCell>{$t({ id: 'frequencies-GenerationDate' })}</PeriodResultCell>
      <PeriodResultCell>{$t({ id: 'frequencies-InvoiceDate' })}</PeriodResultCell>
      <PeriodResultCell>{$t({ id: 'frequencies-DueDate' })}</PeriodResultCell>
    </Stack>
  );
};

type PeriodResultRowProps = {
  generationDate: string;
  invoiceDate: string;
  periodNumber: number;
  activityPeriod: string;
  dueDate: string;
};

export const PeriodResultRow: FC<PeriodResultRowProps> = ({
  activityPeriod,
  periodNumber,
  generationDate,
  invoiceDate,
  dueDate,
}) => {
  const { $t } = useIntl();
  return (
    <Stack direction="row" alignItems="center" gap={3} pb={0.2}>
      <PeriodResultCell color="common.grey2" flex="1 1 100px">
        <Box>
          {toOrdinal(periodNumber)} {$t({ id: 'frequencies-ActivityPeriod' })}
        </Box>
        <Typography color="primary.main">{activityPeriod}</Typography>
      </PeriodResultCell>
      <PeriodResultCell variant="h3">{generationDate}</PeriodResultCell>
      <PeriodResultCell variant="h3">{invoiceDate}</PeriodResultCell>
      <PeriodResultCell variant="h3">{dueDate}</PeriodResultCell>
    </Stack>
  );
};

const PeriodResultCell: FC<PropsWithChildren<TypographyProps>> = ({ children, ...props }) => (
  <Typography flex={1} {...props}>
    {children}
  </Typography>
);

export const FrequencyResultLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.palette.common.lightBg,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
      })}
    >
      {children}
    </Stack>
  );
};

type FrequencySettingProps = {
  title?: string;
  subTitle?: string;
  disabled: boolean;
} & StackProps;

export const FrequencySetting: FC<PropsWithChildren<FrequencySettingProps>> = ({
  disabled,
  title,
  subTitle,
  children,
  ...props
}) => {
  return (
    <Stack {...props}>
      {title && (
        <Typography color={disabled ? 'common.grey' : undefined} variant="h2">
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography pt={title ? 1 : undefined} variant="h4">
          {subTitle}
        </Typography>
      )}
      {children}
    </Stack>
  );
};

type FrequencyDateControlProps = {
  name: string;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const FrequencyBillingDateControl: FC<FrequencyDateControlProps> = ({ name, ...props }) => {
  const { formState, control } = useFormContext();

  const error = get(formState.errors, name);

  return (
    <Stack flex={1}>
      <Controller
        name={name}
        control={control}
        rules={{
          required: true,
        }}
        render={({ field }) => {
          return (
            <DateSelect
              error={error ? error : undefined}
              {...props}
              {...field}
              date={field.value}
              onSetDate={(d) => {
                field.onChange(format(d, DEFAULT_DATE_FORMAT_FNS));
              }}
            />
          );
        }}
      />
    </Stack>
  );
};
