import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 6.47385V8.50723L14.9913 8.50583C15.8231 8.50101 16.5 9.17398 16.5 10.0058V11.6568C16.5 11.6695 16.4998 11.682 16.4995 11.6946C16.4998 11.7072 16.5 11.7199 16.5 11.7326V15.994C16.5 16.8259 15.823 17.499 14.9911 17.494L10.0659 17.4648L5.00869 17.4942C4.17688 17.499 3.5 16.826 3.5 15.9942V11.7326C3.5 11.7199 3.50016 11.7072 3.50047 11.6946C3.50016 11.682 3.5 11.6695 3.5 11.6568V10.0059C3.5 9.17404 4.177 8.50103 5.00889 8.50596L5.25 8.50739L5.25 6.47385C5.25 5.92015 5.51204 5.39911 5.95655 5.06896L8.36029 3.28363C9.33385 2.56053 10.6661 2.56053 11.6397 3.28362L14.0435 5.06896C14.488 5.39911 14.75 5.92015 14.75 6.47385ZM13.25 6.47385V8.51592L9.9341 8.53514L6.75 8.51628L6.75 6.47385C6.75 6.39475 6.78743 6.32031 6.85093 6.27315L9.25468 4.48781C9.6972 4.15913 10.3028 4.15913 10.7453 4.48781L13.1491 6.27315C13.2126 6.32031 13.25 6.39475 13.25 6.47385ZM10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14Z"
    />
  </svg>
);
