import { Box, Divider, IconButton, Stack } from '@mui/material';
import { CrossIcon, Loading } from '@schooly/style';
import { FC } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';

import {
  ModalContent,
  ModalLarge,
  ModalMain,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { SchoolConductTypeRow } from './SchoolConductTypeRow';
import {
  ConductTypesDroppable,
  useSchoolConductChangeOrderModal,
} from './useSchoolConductChangeOrderModal';

export const SchoolConductChangeOrderModal: FC = () => {
  const { $t } = useIntl();

  const {
    positiveFields,
    negativeFields,
    isLoading,
    handleDragEnd,
    isSaving,
    onSaveOrder,
    closeModal,
  } = useSchoolConductChangeOrderModal();

  const renderContent = () => {
    const hasBothTypes = !!positiveFields.length && !!negativeFields.length;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        <ModalHeader title={$t({ id: 'school-section-ConductSettings-ConductTypes' })} active>
          <IconButton onClick={closeModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalMain>
          <ModalContent active>
            <Stack alignItems="flex-start" gap={2} sx={{ height: '100%' }}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                  droppableId={ConductTypesDroppable.POSITIVE}
                  type={ConductTypesDroppable.POSITIVE}
                >
                  {(provided) => (
                    <Box
                      {...provided.droppableProps}
                      sx={{
                        width: '100%',
                      }}
                      ref={provided.innerRef}
                    >
                      {positiveFields.map((code, i) => (
                        <Draggable key={code.id} draggableId={code.id} index={i}>
                          {(provided) => {
                            return (
                              <SchoolConductTypeRow
                                conductType={code}
                                draggableProps={provided}
                                draggable={positiveFields.length > 1}
                              />
                            );
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      {hasBothTypes && <div />}
                    </Box>
                  )}
                </Droppable>

                <Droppable
                  droppableId={ConductTypesDroppable.NEGATIVE}
                  type={ConductTypesDroppable.NEGATIVE}
                >
                  {(provided) => (
                    <Box
                      {...provided.droppableProps}
                      sx={{ width: '100%' }}
                      ref={provided.innerRef}
                    >
                      {hasBothTypes && <Divider />}
                      {negativeFields.map((code, i) => (
                        <Draggable key={code.id} draggableId={code.id} index={i}>
                          {(provided) => {
                            return (
                              <SchoolConductTypeRow
                                conductType={code}
                                draggableProps={provided}
                                draggable={negativeFields.length > 1}
                              />
                            );
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Stack>
          </ModalContent>
        </ModalMain>
        <ModalFooterWithActions saving={isSaving} onSaveClick={onSaveOrder} />
      </>
    );
  };

  return (
    <ModalLarge open onClose={closeModal}>
      {renderContent()}
    </ModalLarge>
  );
};
