import { TableCellProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FC, RefObject, useRef, useState } from 'react';

import { GridCell, GridCellProps } from '../../Grid/Grid';

interface CommentTableCellProps extends Omit<TableCellProps, 'children'>, GridCellProps {
  children: (onOpen: (open: boolean) => void, ref: RefObject<HTMLDivElement>) => React.ReactNode;
  borderTopRightRadius?: boolean;
  borderBottomRightRadius?: boolean;
}
export const CommentTableCell: FC<CommentTableCellProps> = ({
  children,
  borderTopRightRadius,
  borderBottomRightRadius,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);

  const { sx } = props;

  return (
    <GridCell
      ref={cellRef}
      {...props}
      sx={(theme) => ({
        ...((typeof sx === 'function' ? sx(theme) : sx) as SystemStyleObject),
        '&.MuiTableCell-root': {
          borderLeft: props.borderLeft || open ? theme.mixins.borderValue() : 'none',
          borderRight: props.borderRight || open ? theme.mixins.borderValue() : 'none',
          padding: '0 !important',
          borderTopRightRadius: borderTopRightRadius ? theme.shape.borderRadius : 0,
          borderBottomRightRadius: borderBottomRightRadius ? theme.shape.borderRadius : 0,
          borderRadius: open ? 0 : undefined,
        },
      })}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children((open) => setOpen(open), cellRef)}
    </GridCell>
  );
};
