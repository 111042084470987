import { Avatar, Box, BoxProps, Icon, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useSendInviteMutation } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { DELAY_BEFORE_HIDE_ICON_DONE, SchoolInviteStatus } from '@schooly/constants';
import { CopyIcon, DoneIcon, MailIcon, SimpleButton, Spin } from '@schooly/style';
import { FC, ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

type AssignedProductPayerProps = {
  name?: string;
  telephone?: string;
  icon: ReactNode;
  email?: string;
  contactName?: string;
  inviteStatus?: SchoolInviteStatus | null;
  id: string;
  schoolId: string;
  onClick?: () => void;
} & BoxProps;

export const AssignedProductPayer: FC<AssignedProductPayerProps> = ({
  name,
  telephone,
  icon,
  email,
  contactName,
  inviteStatus,
  id,
  schoolId,
  onClick,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const sendInvite = useSendInviteMutation();
  const hasContactDetails = email || telephone || contactName;

  const { showError } = useNotifications();
  const { isLoading } = sendInvite;

  const userInvited = inviteStatus === SchoolInviteStatus.Invited;
  const inviteSent = sendInvite.isSuccess;

  const sendInviteHandler = useCallback(() => {
    sendInvite.mutate(
      { schoolId, relationId: id },
      {
        onError: showError,
      },
    );
  }, [id, schoolId, sendInvite, showError]);

  const inviteButtonTextId = () => {
    if (isLoading) return 'sending-invite';
    if (inviteSent) return 'inviteStatus-Invited';
    if (userInvited) return 'resend-invite';
    return 'send-invite';
  };

  const inviteButtonIcon = () => {
    if (isLoading) return <Spin />;
    if (inviteSent) return <DoneIcon />;
    return <MailIcon />;
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} {...props}>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              width: 200,
              padding: 1.25,
            },
          },
        }}
        title={
          name && (
            <Stack gap={1}>
              <Typography color="text.primary" textAlign="center">
                {name}
              </Typography>
              {hasContactDetails && (
                <Stack gap={0.25}>
                  {contactName && (
                    <AssignedProductPayerContactRow
                      contact={contactName}
                      contactLabelId="companies-ContactPersonName"
                    />
                  )}
                  {telephone && (
                    <AssignedProductPayerContactRow
                      contact={telephone}
                      contactLabelId="peopleDetail-PhoneNumber"
                    />
                  )}
                  {email && (
                    <AssignedProductPayerContactRow
                      contact={email}
                      contactLabelId="peopleDetail-EmailAddress"
                    />
                  )}
                </Stack>
              )}
              {inviteStatus !== undefined && inviteStatus !== SchoolInviteStatus.Active && (
                <Stack alignItems="flex-start" gap={1}>
                  <Typography>
                    {formatMessage(
                      {
                        id: userInvited
                          ? 'profile-HasNotAcceptTheInvitation'
                          : 'profile-NotInvitedToSchooly',
                      },
                      { name },
                    )}
                  </Typography>
                  {email && (
                    <SimpleButton
                      size="small"
                      sx={{ '&.Mui-disabled': { color: 'primary.main' } }}
                      disabled={isLoading || inviteSent}
                      startIcon={inviteButtonIcon()}
                      onClick={sendInviteHandler}
                    >
                      {formatMessage({ id: inviteButtonTextId() })}
                    </SimpleButton>
                  )}
                </Stack>
              )}
            </Stack>
          )
        }
        arrow
      >
        <Box onClick={onClick}>
          <Avatar>
            <Icon fontSize="small" sx={{ color: 'primary.main' }}>
              {icon}
            </Icon>
          </Avatar>
        </Box>
      </Tooltip>
    </Box>
  );
};

type AssignedProductPayerContactRowProps = {
  contact: string;
  contactLabelId: string;
};

const AssignedProductPayerContactRow: FC<AssignedProductPayerContactRowProps> = ({
  contact,
  contactLabelId,
}) => {
  const { showNotification } = useNotifications();
  const { formatMessage } = useIntl();
  const [showDoneIcon, setShowDoneIcon] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(contact);

    setShowDoneIcon(true);

    setTimeout(() => setShowDoneIcon(false), DELAY_BEFORE_HIDE_ICON_DONE);

    showNotification({
      textId: 'profile-SuccessfullyCopied',
      type: 'success',
      values: { contactDetail: formatMessage({ id: contactLabelId }) },
    });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      p={0.25}
      pl={0.5}
      sx={(theme) => ({
        borderRadius: theme.spacing(0.5),
        '.MuiIconButton-root': { visibility: 'hidden' },
        ':hover': { bgcolor: 'common.lightBg', '.MuiIconButton-root': { visibility: 'visible' } },
      })}
    >
      <Typography variant="h3" noWrap sx={{ textOverflow: 'ellipsis', maxWidth: '100%' }}>
        {contact}
      </Typography>
      {showDoneIcon ? (
        <Icon sx={{ color: 'primary.main' }}>
          <DoneIcon />
        </Icon>
      ) : (
        <IconButton onClick={onCopy}>
          <CopyIcon />
        </IconButton>
      )}
    </Stack>
  );
};
