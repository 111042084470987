import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4C17 2.89543 16.1046 2 15 2H14V5H17V4ZM17 6H14C13.4477 6 13 5.55228 13 5V2H5C3.89543 2 3 2.89543 3 4V16C3 17.1046 3.89543 18 5 18H15C16.1046 18 17 17.1046 17 16V6ZM12.1213 8.58579C12.3166 8.39052 12.3166 8.07394 12.1213 7.87868C11.9261 7.68342 11.6095 7.68342 11.4142 7.87868L10 9.29289L8.58579 7.87868C8.39052 7.68342 8.07394 7.68342 7.87868 7.87868C7.68342 8.07394 7.68342 8.39052 7.87868 8.58579L9.29289 10L7.87868 11.4142C7.68342 11.6095 7.68342 11.9261 7.87868 12.1213C8.07394 12.3166 8.39052 12.3166 8.58579 12.1213L10 10.7071L11.4142 12.1213C11.6095 12.3166 11.9261 12.3166 12.1213 12.1213C12.3166 11.9261 12.3166 11.6095 12.1213 11.4142L10.7071 10L12.1213 8.58579Z"
      fill="#24275B"
    />
  </svg>
);
