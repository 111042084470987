import { Divider } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { SchoolUserRole } from '@schooly/constants';
import React, { useMemo, useState } from 'react';

import { allowRenderSchoolProducts } from '../../../helpers/renderRoutesByPermissions';
import { useSchool } from '../../../hooks/useSchool';
import NavMenu from '../../ui/NavMenu';
import UserMenu from '../../ui/UserMenu';
import { SchoolMenu } from './SchoolMenu';
import { SchoolSelect } from './SchoolSelect/SchoolSelect';
import { SidebarStyled } from './Sidebar.styled';

interface SidebarProps {
  hideNavMenu?: boolean;
  className?: string;
}

const Sidebar: React.FC<SidebarProps> = ({ hideNavMenu, className }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const {
    isCompleted,
    isSchoolPath,
    isSchoolAdmin,
    currentSchool,
    isSchoolChanging,
    onSelectSchool,
  } = useSchool();
  const { permissions, currentUser } = useAuth();
  const showSchoolMenu = isSchoolPath && (isSchoolAdmin || allowRenderSchoolProducts(permissions));

  const schools = useMemo(
    () => currentUser?.school_relations.filter((s) => s.role === SchoolUserRole.Staff) || [],
    [currentUser],
  );

  return (
    <SidebarStyled isUserMenuOpen={isUserMenuOpen} className={className}>
      <SchoolSelect
        currentSchool={currentSchool}
        schools={schools}
        onChangeSchool={onSelectSchool}
        isLoading={isSchoolChanging}
      />

      <Divider sx={{ mt: 2, mb: 2.5 }} />

      {(() => {
        if (hideNavMenu || isSchoolChanging || !currentSchool) {
          return null;
        }

        if (!isCompleted) {
          return null;
        }

        return showSchoolMenu ? <SchoolMenu /> : <NavMenu />;
      })()}

      <UserMenu onToggle={setIsUserMenuOpen} />
    </SidebarStyled>
  );
};

export default Sidebar;
