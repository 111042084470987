import { FilterSelectOption } from '@schooly/api';
import { FilterValue, SelectOption } from '@schooly/api';
import get from 'lodash.get';
import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import {
  Control,
  FieldErrorsImpl,
  Merge,
  useFormContext as useFormContextLts,
  UseFormRegisterReturn,
} from 'react-hook-form-lts';
import { FieldValues } from 'react-hook-form-lts/dist/types/fields';
import { IntlShape } from 'react-intl';

import {
  isSerializedIntlError,
  unpackSerializedIntlError,
} from '../../../utils/joi/serializeIntlError';

type BaseInputProps = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'disabled' | 'required'>;

export interface BaseFormInputProps extends BaseInputProps {
  id?: string;
  name: string;
  fieldArrayIndex?: number;
  propertyName?: string;
  className?: string;
  labelText?: string;
  labelTextId?: string;
  placeholderText?: string;
  placeholderTextId?: string;
  multiLabelTextId?: string;
  noRequiredLabel?: boolean;
  hint?: React.ReactNode;
  minNumberErrorTextId?: string;
  maxNumberErrorTextId?: string;
}

interface IntlTextProps {
  id?: string;
  defaultMessage?: string;
  values?: Record<string, string>;
}

const NUMBER_MIN_MAX_NEEDLE_STRING = 'equal to ';

export type ExtendedFieldError = FieldError & {
  messageTextId?: string;
  messageValues?: Record<string, string>;
};

// eslint-disable-next-line import/prefer-default-export
export function getInputErrorText(error: ExtendedFieldError): IntlTextProps {
  if (error.message && isSerializedIntlError(error.message)) {
    return unpackSerializedIntlError(error.message);
  }

  if (error.messageTextId) {
    return {
      id: error.messageTextId,
      values: error.messageValues,
    };
  }

  switch (error.type) {
    case 'required':
    case 'any.required':
    case 'any.only':
    case 'array.sparse':
    case 'string.empty':
    case 'array.includesRequiredUnknowns':
      return {
        id: 'input-ErrorRequired',
      };
    case 'string.email':
      return {
        id: 'input-ErrorInvalidEmail',
      };
    case 'string.min':
    case 'number.min': {
      const value = error.message
        ? error.message.substring(
            error.message.indexOf(NUMBER_MIN_MAX_NEEDLE_STRING) +
              NUMBER_MIN_MAX_NEEDLE_STRING.length,
          )
        : '%VALUE_MISSING%';

      return {
        id: 'input-ErrorMinValue',
        values: { value },
      };
    }
    case 'number.max': {
      const value = error.message
        ? error.message.substring(
            error.message.indexOf(NUMBER_MIN_MAX_NEEDLE_STRING) +
              NUMBER_MIN_MAX_NEEDLE_STRING.length,
          )
        : '%VALUE_MISSING%';

      return {
        id: 'input-ErrorMaxValue',
        values: { value },
      };
    }
    case 'pattern':
    case 'string.pattern.base':
      return {
        id: 'input-ErrorPatternMatch',
      };
    default:
      console.warn('Untranslated form error:', error);
      if (error.message) {
        return {
          id: error.message,
          defaultMessage: error.message,
        };
      }

      console.warn('Unhandled form error:', error);
      return {
        id: error.type || 'untranslated-error',
        defaultMessage: error.message || 'Please check this field',
      };
  }
}

export const getControllerErrorText = <TFieldValues extends FieldValues>(
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<TFieldValues>>,
  $t?: IntlShape['$t'],
): string | undefined => {
  if (!error) {
    return;
  }

  if (error.message) {
    return error.message as string;
  }

  if ($t) {
    // TODO: implement other types (see obsolete `getInputErrorText`)
    switch (error.type) {
      case 'required':
        return $t({
          id: 'input-ErrorRequired',
        });
      case 'email':
        return $t({
          id: 'input-ErrorInvalidEmail',
        });
    }
  }
};

export function getFullFieldName(name: string, fieldArrayIndex?: number, propertyName?: string) {
  let fullName = name;
  if (fieldArrayIndex !== undefined) {
    fullName += `[${fieldArrayIndex}]`;

    if (propertyName) {
      fullName += `.${propertyName}`;
    }
  }

  return fullName;
}

function getInputError(
  errors: DeepMap<Record<string, any>, FieldError>,
  name: string,
  fieldArrayIndex?: number,
  propertyName?: string,
): FieldError | undefined {
  const fullName = getFullFieldName(name, fieldArrayIndex, propertyName);

  if (get(errors, fullName)) {
    return get(errors, fullName);
  }

  if (!get(errors, name)) {
    return undefined;
  }

  if (fieldArrayIndex === undefined || !Array.isArray(errors[name])) {
    return get(errors, name);
  }

  if (!propertyName) {
    return get(errors, name)[fieldArrayIndex];
  }

  return get(errors, name)[fieldArrayIndex] && get(errors, name)[fieldArrayIndex][propertyName];
}

export function getIsFieldTouched(value: any, defaultValue?: any) {
  return (
    !!value ||
    value === 0 ||
    value === false ||
    !!defaultValue ||
    defaultValue === 0 ||
    defaultValue === false
  );
}

export function useFormInput(props: BaseFormInputProps) {
  const { name, fieldArrayIndex, propertyName } = props;

  const { register, control, errors, watch } = useFormContext() || {};

  if (!register) {
    throw new Error('Form Input component can only be used inside FormProvider');
  }

  const fullName = getFullFieldName(name, fieldArrayIndex, propertyName);
  const error = getInputError(errors, name, fieldArrayIndex, propertyName);
  const value = watch(fullName);
  const isTouched = getIsFieldTouched(value);

  return {
    inputRef: register(),
    control,
    fullName,
    error,
    value,
    isTouched,
  };
}

export interface FormInput2Context {
  inputRef: UseFormRegisterReturn<string>;
  control: Control<Record<string, any>>;
  fullName: string;
  error: FieldError | undefined;
  value: any;
  isTouched: boolean;
}

/** Port of obsolete useFormInput to react-hook-forms@7+ */
export function useFormInput2(props: BaseFormInputProps): FormInput2Context {
  const { name, fieldArrayIndex, propertyName } = props;

  const { register, control, watch, formState } = useFormContextLts() || {};

  if (!register) {
    throw new Error('Form Input component can only be used inside FormProvider');
  }

  const fullName = getFullFieldName(name, fieldArrayIndex, propertyName);
  const error = getInputError(formState.errors, name, fieldArrayIndex, propertyName);
  const value = watch(fullName);
  const isTouched = getIsFieldTouched(value);

  return {
    inputRef: register(name),
    control,
    fullName,
    error,
    value,
    isTouched,
  };
}

export function getSelectedOptions<T extends FilterValue = FilterValue>(
  options: SelectOption<T>[],
  value: string | number | T[],
  defaultValue?: string | number | readonly string[],
): FilterSelectOption[] | FilterSelectOption | undefined {
  return Array.isArray(value)
    ? options?.filter((option) =>
        value?.some((item) => option.value.toString() === item.toString()),
      )
    : options?.find((option) => {
        const finalValue = value !== undefined && value !== null ? value : defaultValue || '';
        return option.value.toString() === finalValue.toString();
      });
}

export function hasFieldValue(value: any) {
  return value?.toString() === '0' || (Array.isArray(value) ? !!value.length : !!value);
}
