import { Dialog, styled } from '@mui/material';

export const ModalConfirmation = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius,
  },

  '& .MuiDialogContent-root': {
    ...theme.typography.h1,
    width: 500,
    padding: theme.spacing(2.5),
  },

  '& .MuiDialogActions-root': {
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    padding: theme.spacing(0, 2.5, 2.5),

    '& .MuiButton-root': {
      flex: '1 1 50%',
      margin: 0,
    },
  },
}));
