import { FC } from 'react';

export const RichLinkInner: FC = () => (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.20078 0.199951H5.60078V1.39995H5.20078C3.43347 1.39995 2.00078 2.83264 2.00078 4.59995C2.00078 6.36726 3.43347 7.79995 5.20078 7.79995H5.60078V8.99995H5.20078C2.77073 8.99995 0.800781 7.03 0.800781 4.59995C0.800781 2.1699 2.77073 0.199951 5.20078 0.199951ZM14.0008 4.59995C14.0008 6.36726 12.5681 7.79995 10.8008 7.79995H10.4008V8.99995H10.8008C13.2308 8.99995 15.2008 7.03 15.2008 4.59995C15.2008 2.1699 13.2308 0.199951 10.8008 0.199951H10.4008V1.39995H10.8008C12.5681 1.39995 14.0008 2.83264 14.0008 4.59995ZM4.80078 4.59995C4.80078 4.26858 5.06941 3.99995 5.40078 3.99995H10.6008C10.9322 3.99995 11.2008 4.26858 11.2008 4.59995C11.2008 4.93132 10.9322 5.19995 10.6008 5.19995H5.40078C5.06941 5.19995 4.80078 4.93132 4.80078 4.59995Z"
      fill="#24275B"
    />
  </svg>
);
