import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Stack, Tooltip } from '@mui/material';
import {
  FilterKeys,
  FilterSelectOption,
  FilterValue,
  GroupOptions,
  GroupUserType,
  UserType,
} from '@schooly/api';
import { ArchiveIcon, CrossIcon, DropdownIcon, StaffIcon } from '@schooly/style';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useFilters } from '../../../context/filters/useFilters';
import { getSchoolUserId } from '../../../helpers/school';
import useDropdown from '../../../hooks/useDropdown';
import useKeyboardListNavigation from '../../../hooks/useKeyboardListNavigation';
import { MIN_REMOTE_QUERY_LENGTH } from '../../../hooks/usePagedApiResourceWithFilter';
import useVirtualBackdrop from '../../../hooks/useVirtualBackdrop';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { actions } from '../../../redux/slices/simple-lists/actions';
import { SimpleListsState } from '../../../redux/slices/simple-lists/reducer';
import buildClassName from '../../../utils/buildClassName';
import captureKeyboardListeners from '../../../utils/captureKeyboardListeners';
import getTypedObjectKeys from '../../../utils/getTypedObjectKeys';
import Tag from '../../ui/Tag';
import FilterConductTypeSelectPopupContent from './ConductTypeControl/FilterConductTypeSelectPopupContent';
import FilterSelectPopupContent from './FilterSelectPopupContent';
import { FilterGroupSelectPopupContent } from './GroupControl/FilterGroupSelectPopupContent';
import { FilterReportSelectPopupContent } from './MainListSelectPopupContent/FilterReportSelectPopupContent';
import { FilterPeopleSelectPopupContent } from './StaffControl/FilterPeopleSelectPopupContent';

import '../../ui/Input/FormSelect.scss';
import './FilterSelect.scss';

export interface FilterSelectProps {
  id?: string;
  options?: FilterSelectOption<FilterValue>[];
  groupOptions?: GroupOptions;
  value: FilterValue | FilterValue[];
  groupValue?: { [key in UserType]?: FilterValue[] };
  onChange?: (value: FilterValue[]) => void;
  onChangeGroupOption?: (userType: UserType, values: FilterValue[]) => void;
  onMultipleChangeGroupOption?: (payload: { userType: UserType; value: FilterValue[] }[]) => void;
  popupTitleTextId: string;
  maxCount?: number;
  hasNoneOption?: boolean;
  shouldOpen?: boolean;
  isFocusable?: boolean;
  //TODO: Change FilterKeys to the one from lib/
  filterKey: FilterKeys;
  isHeaderFilterPopupOpen?: boolean;
  customPlaceholderTextId?: string;
  onClose?: () => void;
  emptyLayoutNode?: EmotionJSX.Element;
}

const FOCUS_SUPRESS_DURATION = 300;
export const NONE_OPTION: FilterSelectOption<FilterValue> = {
  value: 'none',
  labelTextId: 'filter-None',
};

const FilterSelect: React.FC<FilterSelectProps> = ({
  id,
  options = [],
  groupOptions,
  value,
  groupValue,
  onChange,
  onChangeGroupOption,
  onMultipleChangeGroupOption,
  maxCount,
  popupTitleTextId,
  hasNoneOption,
  shouldOpen,
  isFocusable,
  isHeaderFilterPopupOpen,
  filterKey,
  customPlaceholderTextId,
  onClose,
  emptyLayoutNode,
}) => {
  const { $t } = useIntl();
  const { filters } = useFilters();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [initialDefaultValue, setInitialDefaultValue] =
    useState<FilterSelectOption<FilterValue, any>[]>();

  const type = filterKey === FilterKeys.Creator ? 'staff' : filterKey;

  const { savedSelectedOptions = [] } = useAppSelector(
    (state) => state.simpleLists[type as keyof SimpleListsState] ?? {},
  );

  const canExclude = [FilterKeys.Group].includes(filterKey);

  const dispatch = useAppDispatch();

  const placeholderTextId = useMemo(() => {
    if (customPlaceholderTextId) {
      return customPlaceholderTextId;
    }
    switch (filterKey) {
      case FilterKeys.Staff:
      case FilterKeys.Creator:
        return 'filter-PleaseSelect';
      default:
        return filters.draftExclude && canExclude ? 'filter-NotIn' : 'filter-All';
    }
  }, [customPlaceholderTextId, filterKey, filters.draftExclude, canExclude]);

  // This helps with unwanted input focusing
  const shouldSupressFocus = useRef<boolean>(false);
  const shouldSupressBackdrop = useRef<boolean>(false);
  const withSmallPaddings = [
    FilterKeys.Staff,
    FilterKeys.Creator,
    FilterKeys.Student,
    FilterKeys.Report,
  ].includes(filterKey);

  const selectedOptions = useMemo(() => {
    const selected = options.filter((o) =>
      Array.isArray(value) ? value.some((item) => o.value === item) : value === o.value,
    );

    if (hasNoneOption && Array.isArray(value) && value.some((item) => item === NONE_OPTION.value)) {
      return [NONE_OPTION, ...selected];
    }

    if (![FilterKeys.Staff, FilterKeys.Creator, FilterKeys.Student].includes(filterKey))
      return selected;

    return savedSelectedOptions;
  }, [filterKey, hasNoneOption, options, savedSelectedOptions, value]);

  const selectedGroupOptions = useMemo(() => {
    if (!groupOptions || !groupValue) {
      return undefined;
    }

    const selected: GroupOptions = {};

    getTypedObjectKeys(groupOptions).forEach((userType) => {
      const filteredOptions = (groupOptions[userType] || []).filter((o) =>
        groupValue[userType]?.some((item) => o.value === item),
      );

      if (filteredOptions.length) {
        selected[userType] = filteredOptions;
      }
    });

    if (hasNoneOption) {
      getTypedObjectKeys(groupOptions).forEach((userType) => {
        if (groupValue[userType]?.some((item) => item === NONE_OPTION.value)) {
          selected[userType] = [NONE_OPTION, ...(selected[userType] || [])];
        }
      });
    }

    if (!Object.keys(selected).length) {
      return undefined;
    }

    return selected;
  }, [hasNoneOption, groupOptions, groupValue]);

  const wrapperRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  const { isOpen, openPopup, closePopup, Popup, isOpenOnce, recalculatePopupPosition } =
    useDropdown(wrapperRef, inputRef, true);

  useEffect(() => {
    // set default value for staff select
    if (!isHeaderFilterPopupOpen || !options.length || initialDefaultValue || !onChange) return;
    if (![FilterKeys.Staff, FilterKeys.Creator, FilterKeys.Student].includes(filterKey)) return;

    const selected = options.filter((o) =>
      Array.isArray(value) ? value.some((item) => o.value === item) : value === o.value,
    );

    setInitialDefaultValue(selected);
    dispatch(actions[type as keyof SimpleListsState].setSavedSelectedOptions(selected));
  }, [
    dispatch,
    filterKey,
    isHeaderFilterPopupOpen,
    initialDefaultValue,
    onChange,
    options,
    value,
    type,
  ]);

  const closePopupFromBackdrop = useCallback(() => {
    if (shouldSupressBackdrop.current) {
      return;
    }

    closePopup();
    onClose?.();
  }, [closePopup, onClose]);

  const keyDownHandler = useKeyboardListNavigation(menuRef, isOpen, [
    searchQuery,
    selectedOptions,
    selectedGroupOptions,
  ]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      shouldSupressFocus.current = true;
      keyDownHandler(e);

      setTimeout(() => {
        shouldSupressFocus.current = false;
      }, 0);
    },
    [keyDownHandler],
  );

  useVirtualBackdrop(isOpen, closePopupFromBackdrop, menuRef, wrapperRef);

  useEffect(() => {
    if (shouldOpen && wrapperRef.current) {
      wrapperRef.current.click();
    }
  }, [shouldOpen]);

  // On TAB click unfocus from portal popup back to select, then simmulte TAB again
  const handleTabClick = useCallback(() => {
    inputRef.current?.focus();
    closePopup();
    onClose?.();
    document.dispatchEvent(new KeyboardEvent('keypress', { key: 'Tab' }));
  }, [closePopup, onClose]);

  useEffect(
    () => (isOpen ? captureKeyboardListeners({ onTab: handleTabClick }) : undefined),
    [isOpen, handleTabClick],
  );

  function handleSearchQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
    const searchValue = e.currentTarget.value;

    setSearchQuery(searchValue);

    if (inputRef.current) {
      inputRef.current.style.width = searchValue?.length
        ? `${searchValue.length * 0.5}rem`
        : '0.5rem';
    }
  }

  function handleSearchFocus(e: React.FocusEvent<HTMLElement>) {
    if (shouldSupressFocus.current) {
      inputRef.current?.blur();

      return;
    }

    inputRef.current?.focus();

    if (!maxCount || `${value}`.length < maxCount) {
      openPopup(e);
    }
  }

  useEffect(() => {
    setSearchQuery('');
  }, [isOpen]);

  useEffect(() => {
    recalculatePopupPosition();
  }, [recalculatePopupPosition, selectedOptions, selectedGroupOptions, isOpen]);

  const getOptionText = useCallback(
    (option: FilterSelectOption<FilterValue>, isStaff?: boolean) => {
      const label = option.labelTextId ? $t({ id: option.labelTextId }) : option.label;

      return option.archived ? (
        <Tooltip title={$t({ id: `schoolProperty-Archived-${filterKey}` })}>
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            sx={{ '& svg': { flex: '0 0 auto' } }}
          >
            <ArchiveIcon />
            {isStaff && <StaffIcon />}
            <span>{label}</span>
          </Stack>
        </Tooltip>
      ) : (
        <Stack direction="row" alignItems="center" gap={0.5} sx={{ '& svg': { flex: '0 0 auto' } }}>
          {isStaff && <StaffIcon />}
          <span>{label}</span>
        </Stack>
      );
    },
    [filterKey, $t],
  );

  const isOptionActive = useCallback(
    (option: FilterSelectOption<FilterValue>, userType?: GroupUserType) => {
      let finalOptions = selectedOptions;

      if (userType) {
        finalOptions = selectedGroupOptions ? selectedGroupOptions[userType] || [] : [];
      }

      return finalOptions?.some((item) => item.value === option.value);
    },
    [selectedOptions, selectedGroupOptions],
  );

  const handleValueRemove = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      option?: FilterSelectOption<FilterValue>,
      userType?: UserType,
    ) => {
      shouldSupressFocus.current = true;
      shouldSupressBackdrop.current = true;

      const filterOption = (item: FilterValue) => item !== option?.value;

      if (userType) {
        if (onChangeGroupOption) {
          if (option && groupValue) {
            onChangeGroupOption(userType, (groupValue[userType] || []).filter(filterOption));
          } else {
            onChangeGroupOption(userType, []);
          }
        }
      } else if (onChange) {
        if (Array.isArray(value)) {
          switch (filterKey) {
            case FilterKeys.Staff:
            case FilterKeys.Creator:
            case FilterKeys.Student: {
              const newSavedOptions = selectedOptions?.filter(
                (o) => getSchoolUserId(o?.item) !== getSchoolUserId(option?.item),
              );
              const newSavedValues = newSavedOptions.map((o) => o.value);

              dispatch(
                actions[type as keyof SimpleListsState].setSavedSelectedOptions(newSavedOptions),
              );
              onChange(newSavedValues as FilterValue[]);
              break;
            }
            default:
              onChange(option ? value.filter(filterOption) : []);
              break;
          }
        } else {
          onChange(['']);
        }
      }

      setSearchQuery('');

      setTimeout(() => {
        shouldSupressFocus.current = false;
        shouldSupressBackdrop.current = false;
      }, FOCUS_SUPRESS_DURATION);
    },
    [dispatch, filterKey, groupValue, onChange, onChangeGroupOption, selectedOptions, type, value],
  );

  const handleValueReset = useCallback(() => {
    shouldSupressFocus.current = true;
    shouldSupressBackdrop.current = true;

    if (onMultipleChangeGroupOption && selectedGroupOptions) {
      onMultipleChangeGroupOption(
        getTypedObjectKeys(selectedGroupOptions).map((userType) => ({
          userType,
          value: [],
        })),
      );
    } else if (onChange) {
      onChange([]);
    }

    setSearchQuery('');

    if (
      filterKey === FilterKeys.Staff ||
      filterKey === FilterKeys.Creator ||
      filterKey === FilterKeys.Student
    ) {
      dispatch(actions[type as keyof SimpleListsState].setSavedSelectedOptions([]));
    }

    setTimeout(() => {
      shouldSupressFocus.current = false;
      shouldSupressBackdrop.current = false;
    }, FOCUS_SUPRESS_DURATION);
  }, [onMultipleChangeGroupOption, selectedGroupOptions, onChange, filterKey, dispatch, type]);

  const handleDropdownIconClick = useCallback(
    (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
      if (!isOpen) {
        openPopup(e);

        return;
      }

      shouldSupressFocus.current = true;

      closePopup(e);
      onClose?.();

      setTimeout(() => {
        shouldSupressFocus.current = false;
      }, FOCUS_SUPRESS_DURATION);
    },
    [isOpen, closePopup, onClose, openPopup],
  );

  const handleSelectOption = useCallback(
    (selectOption?: FilterSelectOption<FilterValue>, userType?: GroupUserType) => {
      shouldSupressFocus.current = true;
      shouldSupressBackdrop.current = true;

      if (userType && groupValue) {
        const values = groupValue[userType] || [];
        if (!maxCount || values.length < maxCount) {
          if (onChangeGroupOption) {
            // keep values order
            const newValues = [...values, selectOption!.value];
            onChangeGroupOption(
              userType,
              groupOptions?.[userType]
                ?.filter((option) => newValues.includes(option.value))
                .map((option) => option.value) ?? [],
            );
          }
        }
      } else if (!maxCount || `${value}`.length < maxCount) {
        if (onChange) {
          if (Array.isArray(value)) {
            // keep values order
            const newValues = [...value, selectOption!.value];
            const filteredOptions = options?.filter((option) => newValues.includes(option.value));

            switch (filterKey) {
              case FilterKeys.Staff:
              case FilterKeys.Creator:
              case FilterKeys.Student: {
                const newSavedOptions = [...savedSelectedOptions, selectOption!];
                const newSavedValues = newSavedOptions.map((o) => o.value);

                dispatch(
                  actions[type as keyof SimpleListsState].setSavedSelectedOptions(newSavedOptions),
                );
                onChange(newSavedValues as FilterValue[]);
                break;
              }
              default: {
                onChange(filteredOptions.map((option) => option.value));
                break;
              }
            }
          } else {
            onChange([selectOption!.value]);
          }
        }
      }

      setSearchQuery('');

      setTimeout(() => {
        shouldSupressFocus.current = false;
        shouldSupressBackdrop.current = false;

        inputRef.current?.focus();
      }, FOCUS_SUPRESS_DURATION);
    },
    [
      dispatch,
      filterKey,
      groupOptions,
      groupValue,
      maxCount,
      onChange,
      onChangeGroupOption,
      options,
      savedSelectedOptions,
      type,
      value,
    ],
  );

  const fullClassName = buildClassName(
    'FilterSelect',
    'form-group form-select',
    isOpen && 'open',
    !(!!selectedOptions?.length || selectedGroupOptions) && 'no-value',
    'form-select-multiple',
    filters.draftExclude && canExclude && 'not-filter',
  );

  const popupClassName = buildClassName(
    'FilterSelect__popup',
    'form-select-popup',
    'list-unstyled',
    'dropdown-menu',
    'shadow-sm',
    isOpen && 'show',
    isOpenOnce && 'open-once',
    withSmallPaddings && 'FilterSelect__popup--sm-p',
  );

  const showPlaceholder = useMemo(() => {
    const shouldShow = !isOpen && (groupOptions ? !selectedGroupOptions : !selectedOptions?.length);
    return filters.draftExclude && canExclude ? true : shouldShow;
  }, [
    canExclude,
    filters.draftExclude,
    groupOptions,
    isOpen,
    selectedGroupOptions,
    selectedOptions?.length,
  ]);

  const valueCanBeRemoved = isOpen && (!!selectedOptions?.length || selectedGroupOptions);

  const popupContent = useMemo(() => {
    switch (filterKey) {
      case FilterKeys.Staff:
      case FilterKeys.Creator:
      case FilterKeys.Student:
        return (
          <FilterPeopleSelectPopupContent
            filterKey={filterKey}
            isOpen={isOpen}
            searchQuery={searchQuery}
            noneOption={NONE_OPTION}
            options={options}
            handleSelectOption={handleSelectOption}
            handleRemoveOption={handleValueRemove}
            hasNoneOption={hasNoneOption}
            isOptionActive={isOptionActive}
            popupTitleTextId={popupTitleTextId}
            resetSearchQuery={() => setSearchQuery('')}
            query={searchQuery.length < MIN_REMOTE_QUERY_LENGTH ? undefined : searchQuery}
            isHeaderFilterPopupOpen={isHeaderFilterPopupOpen}
          />
        );
      case FilterKeys.Group:
        return (
          <FilterGroupSelectPopupContent
            isOpen={isOpen}
            searchQuery={searchQuery}
            noneOption={NONE_OPTION}
            options={options}
            handleSelectOption={handleSelectOption}
            handleRemoveOption={handleValueRemove}
            hasNoneOption={hasNoneOption}
            isOptionActive={isOptionActive}
            popupTitleTextId={popupTitleTextId}
            resetSearchQuery={() => setSearchQuery('')}
            query={searchQuery.length < MIN_REMOTE_QUERY_LENGTH ? undefined : searchQuery}
            isHeaderFilterPopupOpen={isHeaderFilterPopupOpen}
          />
        );
      case FilterKeys.Report:
        return (
          <FilterReportSelectPopupContent
            isOpen={isOpen}
            isHeaderFilterPopupOpen={isHeaderFilterPopupOpen}
            searchQuery={searchQuery}
            noneOption={NONE_OPTION}
            options={options}
            handleSelectOption={handleSelectOption}
            handleRemoveOption={handleValueRemove}
            hasNoneOption={hasNoneOption}
            isOptionActive={isOptionActive}
            popupTitleTextId={popupTitleTextId}
            resetSearchQuery={() => setSearchQuery('')}
            query={searchQuery.length < MIN_REMOTE_QUERY_LENGTH ? undefined : searchQuery}
          />
        );
      case FilterKeys.ConductType:
        return (
          <FilterConductTypeSelectPopupContent
            searchQuery={searchQuery}
            noneOption={NONE_OPTION}
            options={options}
            handleSelectOption={handleSelectOption}
            handleRemoveOption={handleValueRemove}
            hasNoneOption={hasNoneOption}
            isOptionActive={isOptionActive}
            popupTitleTextId={popupTitleTextId}
          />
        );
      default:
        return (
          <FilterSelectPopupContent
            searchQuery={searchQuery}
            noneOption={NONE_OPTION}
            filterKey={filterKey}
            options={options}
            groupOptions={groupOptions}
            handleSelectOption={handleSelectOption}
            handleRemoveOption={handleValueRemove}
            hasNoneOption={hasNoneOption}
            isOptionActive={isOptionActive}
            popupTitleTextId={popupTitleTextId}
            emptyLayoutNode={emptyLayoutNode}
          />
        );
    }
  }, [
    filterKey,
    groupOptions,
    handleSelectOption,
    handleValueRemove,
    hasNoneOption,
    isOpen,
    isOptionActive,
    options,
    popupTitleTextId,
    searchQuery,
    isHeaderFilterPopupOpen,
    emptyLayoutNode,
  ]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label
        className={fullClassName}
        htmlFor={id}
        ref={wrapperRef}
        onFocus={handleSearchFocus}
        onKeyDown={handleKeyDown}
      >
        <div className="form-control" role="button" tabIndex={isFocusable ? 0 : -1}>
          {showPlaceholder && (
            <span className="form-control-placeholder">{$t({ id: placeholderTextId })}</span>
          )}
        </div>

        {!groupOptions && (
          <div className="form-select-value-wrapper">
            <div className="form-select-value-wrapper-inner">
              {selectedOptions &&
                (selectedOptions as FilterSelectOption<FilterValue>[]).map((item) => (
                  <Tag
                    isDisableStopPropagationOfClick
                    key={item.value}
                    selectOption={item}
                    onRemove={isOpen ? handleValueRemove : undefined}
                    className="form-select-value"
                  >
                    {getOptionText(item)}
                  </Tag>
                ))}

              <input
                className="form-select-search-input"
                tabIndex={-1}
                ref={inputRef}
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </div>
          </div>
        )}

        {groupOptions && (
          <div className="form-select-value-wrapper">
            <div className="form-select-value-wrapper-inner">
              {selectedGroupOptions &&
                getTypedObjectKeys(selectedGroupOptions)
                  .sort((a, b) => b.localeCompare(a))
                  .map((userType) =>
                    (selectedGroupOptions[userType] || []).map((item) => (
                      <Tag
                        isDisableStopPropagationOfClick
                        key={item.value}
                        selectOption={item}
                        onRemove={
                          isOpen
                            ? (e, selectOption) => handleValueRemove(e, selectOption, userType)
                            : undefined
                        }
                        className="form-select-value"
                      >
                        {getOptionText(item, userType === 'staff')}
                      </Tag>
                    )),
                  )}

              <input
                className="form-select-search-input"
                tabIndex={-1}
                ref={inputRef}
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </div>
          </div>
        )}

        {valueCanBeRemoved && (
          <div
            className="form-select-remove"
            role="button"
            tabIndex={-1}
            onClick={handleValueReset}
          >
            <CrossIcon />
          </div>
        )}

        {!valueCanBeRemoved && (
          <div
            className="form-select-arrow"
            role="button"
            tabIndex={-1}
            onClick={handleDropdownIconClick}
          >
            <DropdownIcon />
          </div>
        )}

        <Popup>
          <ul ref={menuRef} className={popupClassName}>
            {popupContent}
          </ul>
        </Popup>
      </label>
    </>
  );
};

export default FilterSelect;
