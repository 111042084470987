import { Box, Stack } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { DateSelect, SearchInput } from '@schooly/components/filters';
import { Loading } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { NoSearchResultsFound } from '../../../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { useMembersForGroup } from '../../../../../context/members/useMembersForGroup';
import { getUserFullName } from '../../../../../helpers/users';
import searchWords from '../../../../../utils/searchWords';
import { GroupMembersPreviewModal } from './GroupMembersPreviewModal';
import { GroupMemberTable } from './GroupMemberTable';

export interface GroupMembersPreviewProps {
  userType: 'student';
}

export const GroupMembersPreview: FC<GroupMembersPreviewProps> = ({ userType }) => {
  const { group, date, setDate, usersWithMembership, fetching, isExpired } = useMembersForGroup();

  const { permissions } = useAuth();
  const isGroupManager = permissions.includes('group_manager');

  const [query, setQuery] = useState('');

  const navigate = useNavigate();

  const { $t } = useIntl();

  const onEditGroup = useCallback(() => {
    if (!group?.id) {
      return;
    }
    navigate(`/groups/${group.id}/edit`);
  }, [group?.id, navigate]);

  const onClose = useCallback(() => {
    if (!group?.id) {
      return;
    }
    navigate(`/groups/${group?.id}`, {
      state: { replace: true },
    });
  }, [group?.id, navigate]);

  const onDateChange = (value: Date | null) => {
    if (!value) {
      return;
    }

    setDate(format(value, DEFAULT_DATE_FORMAT_FNS));
  };

  const [actualUsers, notActualUsers] = useMemo(() => {
    if (!usersWithMembership) {
      return [];
    }

    const { actual, notActual } = usersWithMembership;

    if (!query.length) return [actual, notActual];

    return [
      actual.filter((u) => searchWords(getUserFullName(u), query)),
      notActual.filter((u) => searchWords(getUserFullName(u), query)),
    ];
  }, [query, usersWithMembership]);

  const renderContent = () => {
    if (fetching || !usersWithMembership) return <Loading />;

    if (!actualUsers?.length && !notActualUsers?.length) return <NoSearchResultsFound />;

    return (
      <>
        {!!actualUsers?.length && (
          <GroupMemberTable
            members={actualUsers}
            userType={userType}
            nameColumn={$t({ id: 'groups-active' }).toUpperCase()}
            withBorderBottom
          />
        )}
        {!!notActualUsers?.length && (
          <Stack pt={actualUsers?.length ? 2 : 0}>
            <GroupMemberTable
              members={notActualUsers}
              userType={userType}
              nameColumn={$t({ id: 'groups-not-active' }).toUpperCase()}
            />
          </Stack>
        )}
      </>
    );
  };

  return (
    <GroupMembersPreviewModal
      onClose={onClose}
      onEdit={!isExpired && isGroupManager ? onEditGroup : undefined}
      groupName={group?.name || ''}
      groupId={group?.id}
      userType={userType}
      topPanelContent={
        <Stack direction="row" gap={1} width="100%" alignItems="center">
          <Box sx={{ flex: '1 1 100%', '& .SearchInput': { margin: 0.25 } }}>
            <SearchInput
              value={query}
              onChangeText={setQuery}
              placeholder={$t(
                { id: 'groups-AvailableUsersSearch' },
                {
                  userType: $t({ id: 'userType-student-plural' }).toLowerCase(),
                },
              )}
            />
          </Box>
          <Stack minWidth={260}>
            <DateSelect
              date={date}
              onSetDate={onDateChange}
              placeholder={$t({ id: 'datepicker-Today' })}
              CalendarProps={{
                disableFuture: true,
              }}
            />
          </Stack>
        </Stack>
      }
    >
      {renderContent()}
    </GroupMembersPreviewModal>
  );
};
