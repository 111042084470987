import { Assessment } from '../assessments';
import { Event } from '../events';
import { ReportForAssessment } from '../reports';
import { SchoolYear, SchoolYearPeriod, SchoolYearPeriodGroup } from '../schools';
import { FilterKeys } from '../users';

export const ANNUAL_PLAN_FILTER_KEYS = [FilterKeys.SchoolYear, FilterKeys.RecordType] as const;

export enum AnnualPlanRecordTypes {
  SCHOOL_PERIOD = 'period',
  HOLIDAY = 'holiday',
  EVENT = 'event',
  ASSESSMENT = 'assessment',
  REPORT = 'assessment_report',
}

export type GetAnnualPlanRequestFilters = {
  [FilterKeys.SchoolYear]: string[];
  [FilterKeys.RecordType]?: AnnualPlanRecordTypes[];
};

export interface GetAnnualPlanRequest {
  schoolId: string;
  filters: GetAnnualPlanRequestFilters;
}

export interface AnnualPlanRecordBase {
  type: AnnualPlanRecordTypes;
}

export interface AnnualPlanRecordSchoolPeriod extends AnnualPlanRecordBase {
  type: AnnualPlanRecordTypes.SCHOOL_PERIOD;
  id: SchoolYearPeriod['id'];

  period_group_id: SchoolYearPeriodGroup['id'];

  year_id: SchoolYear['id'];
}

export interface AnnualPlanRecordHoliday
  extends AnnualPlanRecordBase,
    Pick<Event, 'id' | 'title' | 'start' | 'end' | 'can_be_edited'> {
  type: AnnualPlanRecordTypes.HOLIDAY;
  status: Event['event_status'];
}

export interface AnnualPlanRecordEvent
  extends AnnualPlanRecordBase,
    Pick<
      Event,
      | 'id'
      | 'title'
      | 'description'
      | 'invitee_type'
      | 'start'
      | 'end'
      | 'recurring_state'
      | 'can_be_edited'
    > {
  type: AnnualPlanRecordTypes.EVENT;
  status: Event['event_status'];
}

export interface AnnualPlanRecordAssessment
  extends AnnualPlanRecordBase,
    Pick<
      Assessment,
      | 'id'
      | 'name'
      | 'display_name'
      | 'assessment_date'
      | 'status'
      | 'recurring_state'
      | 'can_be_edited'
      | 'group_ids'
    > {
  type: AnnualPlanRecordTypes.ASSESSMENT;
  method_ids: string[];
}

export interface AnnualPlanRecordReport
  extends AnnualPlanRecordBase,
    Pick<ReportForAssessment, 'id' | 'name' | 'scheduled_publish_date' | 'report_status'> {
  type: AnnualPlanRecordTypes.REPORT;
  assessment_ids: string[];
}

export type AnnualPlanRecord =
  | AnnualPlanRecordSchoolPeriod
  | AnnualPlanRecordHoliday
  | AnnualPlanRecordEvent
  | AnnualPlanRecordAssessment
  | AnnualPlanRecordReport;

export interface AnnualPlan {
  school_days: number;
  school_year_id: SchoolYear['id'];
  records: AnnualPlanRecord[];
}
