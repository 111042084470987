import { addMinutes } from 'date-fns';

export const MIN_CONSISTENT_YEAR = 1900;
export const MIN_OFFSET_YEAR = 1930;
export const MAX_CONSISTENT_YEAR = 9999;

const stdTimezoneOffset = () => {
  const fullYear = new Date().getFullYear();

  const jan = new Date(fullYear, 0, 1);
  const jul = new Date(fullYear, 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

const timezoneOffset = stdTimezoneOffset();

export const newDateTimezoneOffset = (initialDateString?: string | null) => {
  if (!initialDateString) return new Date();

  const dateFromString = new Date(initialDateString);
  if (dateFromString.getFullYear() < MIN_OFFSET_YEAR) return dateFromString;

  return addMinutes(dateFromString, timezoneOffset);
};
