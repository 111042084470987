import { Spin } from '@schooly/style';
import React, { FC } from 'react';

import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';

import './Button.scss';

// TODO: [WIP] extend the button styles based on UI kit

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: 'outlined' | 'contained' | 'text';
  size?: 'small';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loading?: boolean;
}

const $class = `${$UIKitPrefix}Button`;

export const Button: FC<ButtonProps> = ({
  className,
  variant = 'contained',
  size,
  startIcon,
  endIcon,
  children,
  disabled,
  loading,
  ...props
}) => {
  const hasStartIcon = startIcon || (!endIcon && loading);
  const hasEndIcon = endIcon || (!hasStartIcon && loading);

  return (
    <button
      className={buildClassName(
        $class,
        variant && `${$class}_${variant}`,
        size && `${$class}_${size}`,
        className,
      )}
      disabled={disabled || loading}
      {...props}
    >
      <div className={`${$class}__content`}>
        {hasStartIcon && (
          <div className={`${$class}__startIcon`}>{loading ? <Spin /> : startIcon}</div>
        )}
        <div className={`${$class}__label`}>{children}</div>
        {hasEndIcon && (
          <div className={`${$class}__endIcon`}>
            {!hasStartIcon && loading ? <Spin /> : endIcon}
          </div>
        )}
      </div>
    </button>
  );
};
