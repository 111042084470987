import { Box, styled, Typography } from '@mui/material';
import React, { ChangeEvent, useCallback, useRef } from 'react';

export const CODE_LENGTH = 6;
const CODE_SEGMENTS = new Array(CODE_LENGTH).fill(0);

interface VerifyEmailModalSegmentedInputProps {
  value: string;
  onChange: (v: string) => void;
  error?: string;
}

const inputId = 'segmented-input';

export const VerifyEmailModalSegmentedInput: React.FC<VerifyEmailModalSegmentedInputProps> = ({
  onChange,
  error,
  value,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const values = value.split('');
  const selectedIndex = values.length < CODE_LENGTH ? values.length : CODE_LENGTH - 1;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue } = event.currentTarget;
      const newValue = `${value}${inputValue}`.slice(0, CODE_LENGTH);
      onChange(newValue);
    },
    [onChange, value],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Backspace') return;
      const newValue = value.slice(0, value.length - 1);

      onChange(newValue);
    },
    [onChange, value],
  );

  return (
    <SegmentedContainer>
      <Box>
        <SegmentedLabel htmlFor={inputId}>
          <Box display="flex">
            {CODE_SEGMENTS.map((_, index) => (
              <SegmentedBox error={error} key={index}>
                {values[index]}
              </SegmentedBox>
            ))}
          </Box>
          <SegmentedInput
            ref={ref}
            value=""
            id={inputId}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            style={{
              left:
                values.length <= selectedIndex
                  ? `calc(2.5rem * ${selectedIndex})`
                  : `calc(2.5rem * ${selectedIndex} + 0.5rem)`,
            }}
          />
        </SegmentedLabel>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </Box>
    </SegmentedContainer>
  );
};

const SegmentedContainer = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  flex: 1,
}));

const SegmentedLabel = styled('label')(() => ({
  position: 'relative',
  width: 'max-content',
}));

const SegmentedInput = styled('input')(() => ({
  position: 'absolute',
  border: 'none',
  fontSize: '1.2rem',
  lineHeight: '2rem',
  textAlign: 'center',
  backgroundColor: 'transparent',
  width: '2rem',
  top: 0,
  bottom: 0,
}));

const SegmentedBox = styled(Box)<Pick<VerifyEmailModalSegmentedInputProps, 'error'>>(
  ({ theme, error }) => ({
    pointerEvents: 'none',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '3rem',
    fontSize: '1.2rem',
    lineHeight: '2rem',
    borderRadius: '0.5rem',
    marginRight: '0.5rem',
    color: theme.palette.primary.main,
    border: `1px solid ${!!error ? theme.palette.error.main : theme.palette.common.light3}`,
    boxShadow: `0 0 0 3px ${!!error ? theme.palette.error.light : theme.palette.grey[200]}`,

    '&:last-child': {
      marginRight: 0,
    },
  }),
);
