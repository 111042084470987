import { CreateParentContent, CreateParentForm } from '@schooly/components/applications';
import { Loading, ModalSmall } from '@schooly/style';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useApplication } from '../../../context/applications/useApplication';
import { useRouter } from '../../../context/router/useRouter';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { useApplicationForm } from '../useApplicationForm';

export const CreateParentModal = () => {
  const navigate = useNavigate();
  const { goBack } = useRouter();

  const { parents, addParents, removeParent } = useApplicationForm('create');

  const { parentId } = useParams<'parentId'>();
  const { fetching } = useApplication();
  const { applicationAdultCustomFields, fetching: loadingCustomFields } = useCustomFields({
    refetchOnMount: 'always',
  });

  const currentParent = useMemo(() => {
    if (parentId) {
      return parents?.find((p) => p.id === parentId);
    }
  }, [parentId, parents]);

  const onSubmit = useCallback(
    (data: CreateParentForm) => {
      addParents([data]);
      navigate('/applications/new');
    },
    [addParents, navigate],
  );

  const onRemoveParent = useCallback(
    (parentId: string) => {
      removeParent(parentId);
      navigate('/applications/new');
    },
    [navigate, removeParent],
  );

  return (
    <ModalSmall open>
      {fetching || loadingCustomFields ? (
        <Loading />
      ) : (
        <CreateParentContent
          onClose={goBack}
          onSubmit={onSubmit}
          onRemoveParent={onRemoveParent}
          parent={currentParent}
          formCustomFields={applicationAdultCustomFields}
        />
      )}
    </ModalSmall>
  );
};
