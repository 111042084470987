import { useNotifications } from '@schooly/components/notifications';
import { AttachmentIcon } from '@schooly/style';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { noop } from '../../../../../helpers/misc';
import { SimpleButton } from '../../../../uikit/SimpleButton/SimpleButton';
import { AttachmentFile, AttachmentProps } from './attachment-types';
import { AttachmentButtonStyled } from './Attachments.styled';
import { ALLOWED_FILE_TYPES } from './utils';

const AttachmentButton: React.FC<AttachmentProps> = ({
  files = [],
  setFiles = noop,
  setError = noop,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { showError } = useNotifications();
  const { formatMessage } = useIntl();

  const handleClick = () => {
    if (!inputRef.current) return;

    inputRef.current.click();
  };

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget) return;
      setError(undefined);

      const { files: uploadedFiles } = e.currentTarget;
      if (!uploadedFiles?.length) return;

      let items = Array.from(uploadedFiles).map((it) => ({
        file: it,
        id: `${it.name}${Math.floor(Math.random() * 100)}`,
      }));

      const wrongFormatFiles = items.filter((item) => !ALLOWED_FILE_TYPES.includes(item.file.type));

      if (wrongFormatFiles.length) {
        wrongFormatFiles.forEach((f) => {
          showError({
            reason: formatMessage({ id: 'error-InvalidAttachmentFormat' }, { file: f.file.name }),
          });
        });

        items = items.filter((item) => ALLOWED_FILE_TYPES.includes(item.file.type));
      }

      const allFiles = [...files, ...items];

      setFiles(allFiles as AttachmentFile[]);
    },
    [files, formatMessage, setError, setFiles, showError],
  );

  return (
    <AttachmentButtonStyled onClick={handleClick}>
      <SimpleButton
        className="Toolbar__button"
        sx={(theme) => ({
          ...theme.typography.body1,
          '.ql-snow.ql-toolbar &:hover, .ql-snow.ql-toolbar &:focus': {
            color: theme.palette.text.primary,
          },
        })}
        startIcon={<AttachmentIcon />}
      >
        <FormattedMessage id="messages-AddAttachment" />
      </SimpleButton>
      <input
        ref={inputRef}
        accept={ALLOWED_FILE_TYPES.join(',')}
        multiple
        type="file"
        onChange={onChange}
        value=""
        hidden
      />
    </AttachmentButtonStyled>
  );
};

export default AttachmentButton;
