import { useGetGroupSubjectsQuery, useGetSchoolYears } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';

import { useSchool } from './useSchool';

export const useCheckSchoolSettings = () => {
  const { schoolId = '' } = useSchool();

  const enabled = !!schoolId;

  const { propertiesMap, propertiesMap: studentPropertiesMap } = useSchoolProperties(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { enabled },
  );
  const { propertiesMap: staffPropertiesMap } = useSchoolProperties(
    {
      schoolId,
      userType: SchoolUserRole.Staff,
    },
    { enabled },
  );
  const { data: subjectsData } = useGetGroupSubjectsQuery(
    {
      schoolId,
    },
    { enabled },
  );

  const { data: yearsData } = useGetSchoolYears(schoolId ?? '', {
    enabled: !!schoolId,
  });

  const hasSchoolYears = Boolean(yearsData?.school_years.length);
  const hasStatuses =
    Boolean(studentPropertiesMap.status?.length) && Boolean(staffPropertiesMap.status?.length);
  const hasAgeGroups = Boolean(propertiesMap.age_group.length);
  const hasSubjects = Boolean(subjectsData?.subjects.length);
  const hasHouses = Boolean(studentPropertiesMap.house?.length);
  const hasDepartments = Boolean(propertiesMap.department.length);

  return {
    hasSchoolYears,
    hasStatuses,
    hasAgeGroups,
    hasSubjects,
    hasHouses,
    hasDepartments,
  };
};
