import { Box, IconButton, Stack, Typography } from '@mui/material';
import { UserRoleAccessType, UserRoleCategory } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import {
  USER_ROLE_CATEGORY_OPTIONS,
  USER_ROLE_PERMISSION_OPTIONS,
  USER_ROLE_TYPE_OPTIONS,
} from '@schooly/constants';
import { CrossIcon, EditIcon } from '@schooly/style';
import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import FormSelect2 from '../../../components/ui/Input/FormSelect2';
import { SimpleButton } from '../../../components/uikit/SimpleButton/SimpleButton';
import { FormTextField } from '../../../components/uikit-components/FormTextField/FormTextField';
import { ControlModalHeaderInput } from '../../../components/uikit-components/Modal/ControlModalHeaderInput';
import {
  ModalContent,
  ModalMain,
  ModalPanel,
  ModalSmall,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { ModalHeaderTitle } from '../../../components/uikit-components/Modal/ModalHeader.styled';
import { ModalPeopleExtensionPanel } from '../../../components/uikit-components/Modal/ModalPeopleExtensionPanel';
import { getUserPermissionScope } from '../../../helpers/userRoles';
import { ellipsis } from '../../Groups/GroupPreviewModal/GroupPreviewModalInfo';
import {
  SelectedPermissions,
  usePermissionHelpers,
} from '../../UserRoles/CreateUserRoleModal/Context/useUserRoleModal';
import { UserRolePermissions } from '../../UserRoles/CreateUserRoleModal/UserRolePermissions/UserRolePermissions';
import { SchoolCreateUserRole } from './useSchoolCreate';

enum ViewState {
  Settings = 'settings',
  Permissions = 'permissions',
  Configuration = 'configuration',
}

enum Focused {
  Name = 'name',
  Description = 'description',
}

export type SchoolCreateUserRoleSubmit = Omit<SchoolCreateUserRole, 'permission_to_assign'> & {
  permission_to_assign: string[];
};

type SchoolCreateUserRoleModalProps = {
  otherRoles: SchoolCreateUserRole[];
  role: SchoolCreateUserRole;
  onClose: () => void;
  onDelete?: () => void;
  onSubmit: (role: SchoolCreateUserRoleSubmit) => void;
  readonly?: boolean;
};

type SchoolCreateUserRoleForm = {
  name: string;
  description: string;
  permission_to_assign: string[];
  permissions: SelectedPermissions;
};

const convertRoleToSelectedPermissions = (role: SchoolCreateUserRole) => {
  let permissions: SelectedPermissions = {};

  USER_ROLE_CATEGORY_OPTIONS.forEach(({ category }) => {
    USER_ROLE_TYPE_OPTIONS.forEach(({ type }) => {
      const permissionScope = getUserPermissionScope(category, type);

      if (
        !!permissionScope.length &&
        permissionScope.every((scopePermission) =>
          role.permissions?.some((p) => p === scopePermission),
        )
      ) {
        permissions = { ...permissions, [category]: type };
      }
    });

    if (!permissions[category]) {
      permissions = { ...permissions, [category]: 'NoAccess' };
    }
  });

  const permission_to_assign =
    USER_ROLE_PERMISSION_OPTIONS.filter((data) =>
      role.permission_to_assign?.some(({ value }) => value === data.value),
    ) ?? [];

  return { permissions, permission_to_assign: permission_to_assign.map((p) => p.value) };
};

export const SchoolCreateUserRoleModal: FC<SchoolCreateUserRoleModalProps> = ({
  role,
  onClose,
  onSubmit,
  otherRoles,
  onDelete,
  readonly,
}) => {
  const { $t } = useIntl();
  const [[mode, focused], setMode] = useState<[ViewState, Focused | undefined]>([
    ViewState.Settings,
    role.name ? undefined : Focused.Name,
  ]);
  const { getConfirmation } = useConfirmationDialog();

  const form = useForm<SchoolCreateUserRoleForm>({
    defaultValues: {
      name: role.name,
      description: role.description || '',
      ...convertRoleToSelectedPermissions(role),
    },
  });

  const name = form.watch('name');
  const description = form.watch('description');
  const selectedPermissions = form.watch('permissions');

  const { isPermissionChecked, getPreselectedPermissions, getCurrentPermissions } =
    usePermissionHelpers(selectedPermissions);

  const submitDisabled = !form.formState.isValid;
  const isSettingsMode = mode === ViewState.Settings;

  const handleSubmit = useCallback(
    (data: SchoolCreateUserRoleForm) => {
      const { name, permissions, permission_to_assign } = data;

      if (!name) {
        form.setError('name', { type: 'required' });
      }

      if (otherRoles.some((r) => r.name === name.trim())) {
        form.setError('name', {
          type: 'validate',
          message: $t({ id: 'userRoles-UserRoleExists' }),
        });
      }

      const permissionsSet = (Object.keys(permissions) as Array<UserRoleCategory>).some(
        (p) => permissions?.[p] !== 'NoAccess',
      );

      if (!permissionsSet) {
        form.setError('permissions', {
          type: 'validate',
          message: $t({ id: 'userRoles-AtLeastOnePermission' }),
        });
      }

      if (!name || !permissionsSet) {
        return;
      }

      onSubmit({ name, description, permission_to_assign, permissions: getCurrentPermissions() });
    },
    [$t, description, form, getCurrentPermissions, onSubmit, otherRoles],
  );
  const handleDelete = useCallback(async () => {
    if (
      !(await getConfirmation({
        textId: 'userRoles-deleteUserRole',
        textValues: { userRole: name },
      }))
    ) {
      return;
    }

    onDelete?.();
  }, [getConfirmation, onDelete, name]);

  const handleSelectPermission = useCallback(
    (category: UserRoleCategory, type: UserRoleAccessType) => {
      const preselectedPermissions = getPreselectedPermissions(category, type);
      const permissions = (Object.keys(selectedPermissions) as Array<UserRoleCategory>).reduce<
        SchoolCreateUserRoleForm['permissions']
      >((acc, key) => {
        if (category !== key && selectedPermissions) {
          acc[key] = selectedPermissions[key];
        }

        return acc;
      }, {});

      form.setValue('permissions', { ...permissions, ...preselectedPermissions, [category]: type });
    },
    [form, getPreselectedPermissions, selectedPermissions],
  );

  const editIcon = (
    <IconButton>
      <EditIcon />
    </IconButton>
  );

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ModalHeader
            active={isSettingsMode}
            multiline
            title={
              isSettingsMode ? (
                <ControlModalHeaderInput
                  control={form.control}
                  name="name"
                  rules={{ required: true }}
                  placeholder={$t({ id: 'userRoles-UserRoleName' })}
                  autoFocus={focused === Focused.Name}
                />
              ) : (
                <ModalPanel sx={{ padding: 0, overflow: 'hidden' }}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Box>
                      <ModalHeaderTitle
                        endIcon={<EditIcon />}
                        iconsOnHover={!!name}
                        onClick={() => setMode([ViewState.Settings, Focused.Name])}
                      >
                        {ellipsis(name ?? '', 35)}
                      </ModalHeaderTitle>
                    </Box>
                  </Stack>
                  {Boolean(form.formState.errors.name) && (
                    <Typography variant="body2" color="error">
                      {form.formState.errors.name?.message}
                    </Typography>
                  )}
                </ModalPanel>
              )
            }
          >
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalPanel withBorderBottom active={isSettingsMode} flat={isSettingsMode}>
            {isSettingsMode ? (
              <Stack mx={2.5} my={2.5}>
                <FormTextField
                  value={description}
                  label={$t({ id: 'groups-GroupDescription' })}
                  multiline
                  fullWidth
                  onChange={(e) => form.setValue('description', e.target.value)}
                  minRows={2}
                  autoFocus={focused === Focused.Description}
                />
              </Stack>
            ) : (
              <Stack sx={{ flex: '1 1 30%' }}>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="groups-GroupDescription" />
                </Typography>
                <Box>
                  <SimpleButton
                    endIcon={<EditIcon />}
                    iconsOnHover={!!description}
                    onClick={() => setMode([ViewState.Settings, Focused.Description])}
                  >
                    <Typography color="text.primary" variant="h3">
                      {ellipsis(description ?? '', 35)}
                    </Typography>
                  </SimpleButton>
                </Box>
              </Stack>
            )}
          </ModalPanel>

          {/* Permissions */}
          <ModalPeopleExtensionPanel
            titleId="userRoles-Permissions"
            addActionId="userRoles-SetPermissions"
            editActionId="groups-EditStaff"
            count={0}
            active={mode === ViewState.Permissions}
            onAddClick={() => setMode([ViewState.Permissions, undefined])}
            sidebarContent={<></>}
            headerMode={true}
            editActionIcon={editIcon}
            errorText={form.formState.errors.permissions?.message}
          >
            <ModalContent withBorderBottom active flat sx={{ pt: 0, px: 2.5 }}>
              <UserRolePermissions
                isPermissionChecked={isPermissionChecked}
                onSelectPermission={handleSelectPermission}
              />
            </ModalContent>
          </ModalPeopleExtensionPanel>

          {/* Configuration */}
          <ModalPeopleExtensionPanel
            titleId="userRoles-Configuration"
            addActionId="userRoles-SetConfiguration"
            editActionId="groups-EditStaff"
            count={0}
            active={mode === ViewState.Configuration}
            onAddClick={() => setMode([ViewState.Configuration, undefined])}
            sidebarContent={<></>}
            headerMode={true}
            editActionIcon={editIcon}
          >
            <ModalContent withBorderBottom active flat sx={{ pt: 0, px: 2.5 }}>
              <Stack gap={1}>
                <Typography variant="h3">
                  <FormattedMessage id="userRoles-StaffAssignee" />
                </Typography>
                <FormSelect2
                  name="permission_to_assign"
                  labelTextId="userRoles-Permissions"
                  multiLabelTextId="userRoles-Permissions"
                  multiple
                  options={USER_ROLE_PERMISSION_OPTIONS}
                  disabled={readonly}
                />
              </Stack>
            </ModalContent>
          </ModalPeopleExtensionPanel>

          <ModalMain />
          <ModalFooterWithActions
            showDeleteButton={!!onDelete}
            onDeleteClick={handleDelete}
            isNewItem={!role}
            disabled={submitDisabled}
          />
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
