import { Box } from '@mui/material';
import React, { FC } from 'react';

import { SchoolGeneral } from './SchoolGeneral/SchoolGeneral';

export const SchoolPageCompleted: FC = () => {
  return (
    <Box mt={2.5}>
      <SchoolGeneral />
    </Box>
  );
};
