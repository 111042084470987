import { Stack, Typography } from '@mui/material';
import { DateSelect } from '@schooly/components/filters';
import { EditIcon, PlusIcon } from '@schooly/style';
import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import { ModalPanel } from '../../../../components/uikit-components/Modal/Modal.styled';
import { MessagesCreateModalMode } from '../../../../context/messages/MessageContext';
import { useMessage } from '../../../../context/messages/useMessage';
import { MessagesDeleteGroupModal } from '../confirmations/MessagesDeleteGroupModal';
import { MessagesCreateModalRecipientsParents } from './MessagesCreateModalRecipientsParents';
import { MessagesCreateModalRecipientsStaff } from './MessagesCreateModalRecipientsStaff';

export const MessagesCreateModalRecipients: FC = () => {
  const { formatMessage } = useIntl();
  const {
    mode,
    hasRecipients,
    criteriaDate,
    isRemoveGroupDialogOpen,
    prevCriteriaDate,
    notActualGroups,
    actions,
  } = useMessage();

  const isActive = [
    MessagesCreateModalMode.Recipients,
    MessagesCreateModalMode.RecipientsParents,
    MessagesCreateModalMode.RecipientsStaff,
  ].includes(mode);

  const fullHeight = [
    MessagesCreateModalMode.RecipientsParents,
    MessagesCreateModalMode.RecipientsStaff,
  ].includes(mode);

  const handleClick = useCallback(() => {
    actions.setMode(MessagesCreateModalMode.Recipients);
  }, [actions]);

  const sidebarOpen =
    mode === MessagesCreateModalMode.RecipientsParents ||
    mode === MessagesCreateModalMode.RecipientsStaff;

  return (
    <ModalPanel
      active={mode === MessagesCreateModalMode.Recipients}
      fullHeight={fullHeight}
      withBorderBottom
      sx={(theme) => ({
        pb: isActive ? 0 : undefined,

        [theme.breakpoints.down('lg')]: {
          p: isActive ? 0 : theme.spacing(2, 4),
        },
      })}
    >
      <Stack sx={{ height: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              p: isActive ? theme.spacing(1, 4) : 0,
            },
            [theme.breakpoints.down('md')]: {
              flexWrap: 'wrap',
              gap: theme.spacing(1),
              py: sidebarOpen ? theme.spacing(2) : undefined,
            },
          })}
        >
          <Typography variant="h2">
            <FormattedMessage id="messages-Recipients" />
          </Typography>
          {/*{errors?.recipients && (*/}
          {/*  <Typography variant="body2" color="error">*/}
          {/*    {renderError(errors?.recipients)}*/}
          {/*  </Typography>*/}
          {/*)}*/}
          {isActive ? (
            <Stack minWidth={260}>
              <DateSelect
                date={criteriaDate}
                onSetDate={actions.setCriteriaDate}
                placeholder={formatMessage({
                  id: criteriaDate ? 'messages-AsOf' : 'messages-PublishDate',
                })}
                onClear={() => actions.setCriteriaDate(null)}
              />
            </Stack>
          ) : hasRecipients ? (
            <SimpleButton startIcon={<EditIcon />} onClick={handleClick}>
              <FormattedMessage id="messages-EditRecipients" />
            </SimpleButton>
          ) : (
            <SimpleButton startIcon={<PlusIcon />} onClick={handleClick}>
              <FormattedMessage id="messages-AddRecipients" />
            </SimpleButton>
          )}
        </Stack>
        {isActive && (
          <>
            <MessagesCreateModalRecipientsParents />
            <MessagesCreateModalRecipientsStaff />
            <MessagesDeleteGroupModal
              isOpen={isRemoveGroupDialogOpen}
              confirmTextId={
                prevCriteriaDate
                  ? 'messages-ChangingMessageDateWithInvalidGroups'
                  : 'messages-InvalidGroups'
              }
              invalidGroups={notActualGroups}
              onClose={actions.handleCloseRemoveGroupDialog}
              onConfirm={actions.handleConfirmRemoveGroupDialog}
            />
          </>
        )}
      </Stack>
    </ModalPanel>
  );
};
