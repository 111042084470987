import { styled } from '@mui/material';
import { DropdownIcon } from '@schooly/style';

interface ExpandIconProps {
  expanded?: boolean;
}

export const ExpandIcon = styled(DropdownIcon)<ExpandIconProps>(({ theme, expanded }) => ({
  transform: !!expanded ? 'rotate(180deg)' : 'none',
  transition: theme.transitions.create(['all'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.short,
  }),
  color: !!expanded ? theme.palette.primary.main : theme.palette.common.grey,
}));
