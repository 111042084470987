import { IconButton, styled } from '@mui/material';

export const CircleIconButton = styled(IconButton)(({ theme }) => ({
  ':hover': {
    color: theme.palette.background.paper,
    background: theme.palette.primary.main,
  },
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: theme.palette.common.grey,
}));
