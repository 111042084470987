import { getLabelIdForOption } from '@schooly/constants';
import { FemaleIcon, MaleIcon } from '@schooly/style';
import React from 'react';
import { useIntl } from 'react-intl';

import { Genders } from '../../../constants/people';
import { useProfile } from '../../../context/profile/useProfile';
import GenderModal from './GenderModal';
import ProfileProperty from './ProfileProperty';

interface GenderPropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}

const GenderProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
}: GenderPropertyParams) => {
  const { user, canEditProfile, hasEditingPermission } = useProfile();
  const { formatMessage } = useIntl();

  return (
    <ProfileProperty
      value={user?.gender}
      canEdit={canEditProfile && hasEditingPermission}
      params={{ size, iconColor, color }}
      renderValue={(value) =>
        formatMessage({
          id: getLabelIdForOption({
            key: Genders[value],
            optionsKey: 'gender',
          }),
        })
      }
      renderIcon={(value) => (value === Genders.Male ? <MaleIcon /> : <FemaleIcon />)}
      valueTextId="peopleDetail-Gender"
      editValueTextId="peopleDetail-AddGender"
      fieldName="gender"
      modalController={({ isOpen, hide }) => <GenderModal isOpen={isOpen} onClose={hide} />}
    />
  );
};

export default GenderProperty;
