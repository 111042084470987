import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="5" width="12" height="13" rx="1" fill="currentColor" />
    <path d="M3.5 7.5H16.5" stroke="#24275b" />
    <rect x="2" y="4" width="16" height="3" rx="1" fill="currentColor" />
    <rect x="8" y="2" width="4" height="4" rx="2" fill="currentColor" />
  </svg>
);
