import { SchoolProperty } from '@schooly/api';
import React from 'react';

export enum SchoolTuneStatusModalMode {
  STUDENTS = 'students',
  STAFF = 'staff',
}

export interface SchoolTuneStatus extends Partial<Omit<SchoolProperty, 'category'>> {
  originId?: string;
  replaceId?: string;
  category?: Partial<SchoolProperty['category']>;
}

export interface SchoolTuneStatusType {
  prospective: SchoolTuneStatus[];
  current: SchoolTuneStatus[];
  former: SchoolTuneStatus[];
  unsuccessful: SchoolTuneStatus[];
}

export interface SchoolTuneStatusesForm {
  students: SchoolTuneStatusType;
  staff: SchoolTuneStatusType;
}

export interface SchoolTuneStatusesArchivable {
  showArchived?: boolean;
  setShowArchived?: React.Dispatch<React.SetStateAction<boolean>>;
  autoSwitchedArchivedOn?: React.MutableRefObject<boolean>;
}
