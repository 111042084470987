import { ArrowRightIcon } from '@schooly/style';
import React, { useCallback, useMemo, useState } from 'react';

import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';
import Modal from '../../ui/Modal';
import { CloseAccountForm } from './CloseAccountForm';
import { CloseAccountInfo } from './CloseAccountInfo';

import './CloseAccountModal.scss';

export interface CloseAccountModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const $class = `${$UIKitPrefix}CloseAccountModal`;

enum CloseAccountModalMode {
  Info,
  ResetPassword,
}

export const CloseAccountModal: React.FC<CloseAccountModalProps> = ({ isOpen, onClose }) => {
  const [mode, setMode] = useState(CloseAccountModalMode.Info);

  const titleTextId = useMemo(() => {
    switch (mode) {
      case CloseAccountModalMode.Info:
        return 'login-YouAreAboutToRemove';
      case CloseAccountModalMode.ResetPassword:
        return 'login-PleaseEnterPassword';
      default:
        break;
    }
  }, [mode]);

  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      setMode(CloseAccountModalMode.Info);
    }, 300);
  }, [onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className={buildClassName($class, mode === CloseAccountModalMode.Info && `${$class}_info`)}
      titleTextId={titleTextId}
      footerButtons={
        mode === CloseAccountModalMode.Info
          ? [
              {
                textId: 'action-Continue',
                color: 'primary',
                rightIcon: <ArrowRightIcon />,
                onClick: () => setMode(CloseAccountModalMode.ResetPassword),
              },
            ]
          : undefined
      }
    >
      {mode === CloseAccountModalMode.Info && <CloseAccountInfo />}
      {mode === CloseAccountModalMode.ResetPassword && <CloseAccountForm onClose={handleClose} />}
    </Modal>
  );
};
