import { Icon, IconButton, Stack, Typography } from '@mui/material';
import { Company } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { DELAY_BEFORE_HIDE_ICON_DONE } from '@schooly/constants';
import {
  BankIcon,
  CopyIcon,
  DoneIcon,
  EmailIcon,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  LocationIcon,
  NoteIcon,
  PhoneIcon,
  ProfileIcon,
  TaxIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserAddress } from '@schooly/utils/user-helpers';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

interface CompanyPreviewModalAboutProps {
  company: Company;
}

enum CompanyDetails {
  TaxNumber = 'TaxNumber',
  BankAccount = 'BankAccount',
  Address = 'Address',
  ContactName = 'ContactPersonName',
  PhoneNumber = 'PhoneNumber',
  Email = 'EmailAddress',
}

export const CompanyPreviewModalAbout: React.FC<CompanyPreviewModalAboutProps> = ({ company }) => {
  const { $t } = useIntl();
  const { tax_number, bank_account, contact_name, telephone, email, note } = company;
  const address = getUserAddress(company) ?? '';

  return (
    <Stack gap={2.5}>
      <Typography variant="h2">{$t({ id: 'companies-About' })}</Typography>

      <Stack gap={2}>
        <Stack>
          <CompanyPreviewInfoRow
            icon={<TaxIcon />}
            text={tax_number}
            companyDetailType={CompanyDetails.TaxNumber}
          />
          <CompanyPreviewInfoRow
            icon={<BankIcon />}
            text={bank_account}
            companyDetailType={CompanyDetails.BankAccount}
          />
        </Stack>

        <Stack>
          <Typography mb={1} variant="h4">
            {$t({ id: 'companies-Address' })}
          </Typography>
          <CompanyPreviewInfoRow
            icon={<LocationIcon />}
            text={address}
            companyDetailType={CompanyDetails.Address}
          />
        </Stack>

        <Stack>
          <Typography mb={1} variant="h4">
            {$t({ id: 'companies-ContactInformation' })}
          </Typography>
          <CompanyPreviewInfoRow
            icon={<ProfileIcon />}
            text={contact_name}
            companyDetailType={CompanyDetails.ContactName}
          />
          <CompanyPreviewInfoRow
            icon={<PhoneIcon />}
            text={telephone}
            companyDetailType={CompanyDetails.PhoneNumber}
          />
          <CompanyPreviewInfoRow
            icon={<EmailIcon />}
            text={email}
            companyDetailType={CompanyDetails.Email}
          />
        </Stack>
      </Stack>

      {note && (
        <Stack>
          <CompanyPreviewInfoRow icon={<NoteIcon />} text={note} canCopy={false} />
        </Stack>
      )}
    </Stack>
  );
};

interface ApplicationPreviewRowProps {
  text: string;
  icon: ReactNode;
  canCopy?: boolean;
  companyDetailType?: CompanyDetails;
}

const CompanyPreviewInfoRow: FC<ApplicationPreviewRowProps> = ({
  text,
  icon,
  canCopy = true,
  companyDetailType,
}) => {
  const [showDoneIcon, setShowDoneIcon] = useState(false);
  const { showNotification } = useNotifications();
  const { $t } = useIntl();

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(text);

    setShowDoneIcon(true);

    setTimeout(() => setShowDoneIcon(false), DELAY_BEFORE_HIDE_ICON_DONE);

    showNotification({
      textId: 'companies-SuccessfullyCopied',
      type: 'success',
      values: {
        companyDetail: $t({ id: `companies-${companyDetailType}` }),
      },
    });
  }, [$t, companyDetailType, showNotification, text]);

  return (
    <GridRowStyled
      height={44}
      sx={{
        '.MuiButtonBase-root': { visibility: 'hidden' },
        '&:hover': {
          '.MuiButtonBase-root': { visibility: 'visible' },
          '.MuiIcon-root': { color: 'primary.main' },
        },
      }}
    >
      <GridRowItem gap={1}>
        <Icon sx={{ color: 'common.grey' }}>{icon}</Icon>
        <GridRowName minWidth={0}>
          <TypographyWithOverflowHint variant="h3">{text}</TypographyWithOverflowHint>
        </GridRowName>
        {canCopy && (
          <>
            {showDoneIcon ? (
              <Icon>
                <DoneIcon />
              </Icon>
            ) : (
              <IconButton onClick={onCopy}>
                <CopyIcon />
              </IconButton>
            )}
          </>
        )}
      </GridRowItem>
    </GridRowStyled>
  );
};
