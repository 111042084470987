import { Grid, Stack } from '@mui/material';
import { AvailableCriteria } from '@schooly/api';
import { GroupUserType } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { UserWithCriteria } from '../../../../components/common/AvailableUsers';
import PersonCardSelectable from '../../../../components/common/PersonCard/PersonCardSelectable';
import UserCriteria from '../../../../components/common/UserCriteria';
import { ModalEmptyMainArea } from '../../../../components/uikit-components/Modal/ModalEmptyMainArea';
import RoundCard from '../../../../components/uikit-components/RoundCard';
import {
  getCriteriaArchived,
  getCriteriaArchivedTooltip,
  getCriteriaName,
} from '../../../../helpers/misc';
import { getCriteriaItemKey } from '../ExternalSidebar/AvailableCriteria';

export interface GroupUsersListProps {
  groupCriteria?: AvailableCriteria[];
  groupUsers?: SimpleListResult[];
  onCriteriaClick?: (criteria: AvailableCriteria) => void;
  onUserClick?: (userId: string) => void;
  userType: GroupUserType;
  renderCustomIcon?: (userId: string) => React.ReactNode;
  emptyStub?: React.ReactNode;
  isEmpty: boolean;
}

export const GroupUsersList: FC<GroupUsersListProps> = ({
  groupCriteria,
  groupUsers,
  onCriteriaClick,
  onUserClick,
  userType,
  renderCustomIcon,
  emptyStub,
  isEmpty,
}) => {
  const { $t } = useIntl();

  const groupUsersWithCriteria = useMemo(() => {
    return groupUsers?.map((user) => {
      const userCriteria = groupCriteria?.filter((c) =>
        c?.relation_ids?.includes(user.relation_id),
      );

      if (userCriteria?.length) {
        return { ...user, criteria: userCriteria.map((c) => getCriteriaName(c)) };
      }

      return user;
    });
  }, [groupUsers, groupCriteria]);

  const users = userType === 'student' ? groupUsersWithCriteria : groupUsers;

  const userListContent = useMemo(() => {
    if (emptyStub) return emptyStub;
    if (isEmpty) {
      return (
        <ModalEmptyMainArea
          label={$t({
            id: userType === 'student' ? 'userType-student-plural' : 'userType-staff',
          }).toLowerCase()}
        />
      );
    }

    return (
      <Stack>
        <Grid container spacing={1}>
          {groupCriteria?.map((item) => (
            <Grid item xs={6} key={getCriteriaItemKey(item)}>
              <RoundCard
                key={item.id}
                item={item}
                count={Number(item?.relation_ids?.length)}
                isSelected
                withTooltip
                onClick={onCriteriaClick}
                name={getCriteriaName(item)}
                archived={getCriteriaArchived(item)}
                archivedTooltipId={getCriteriaArchivedTooltip(item)}
              />
            </Grid>
          ))}
        </Grid>

        {users?.map((user: UserWithCriteria) => (
          <PersonCardSelectable
            key={user.relation_id}
            user={user}
            userType={userType}
            isStaticSelected
            onClick={onUserClick}
            isListItem
            renderCustomIcon={renderCustomIcon?.(user.relation_id)}
          >
            {user.criteria && <UserCriteria userCriteria={user.criteria} criteriaToShow={2} />}
          </PersonCardSelectable>
        ))}
      </Stack>
    );
  }, [
    $t,
    emptyStub,
    groupCriteria,
    isEmpty,
    onCriteriaClick,
    onUserClick,
    renderCustomIcon,
    userType,
    users,
  ]);

  return <Stack sx={{ mt: 2, mx: 0.25, overflow: 'scroll' }}>{userListContent}</Stack>;
};
