import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { useSchool } from '../../../hooks/useSchool';
import AccessDeniedPage from '../../AccessDenied';
import { SchoolFiltersContent } from './SchoolFiltersContent';

export const SchoolFilters: FC = () => {
  const { isSchoolAdmin } = useSchool();

  if (!isSchoolAdmin) {
    return <AccessDeniedPage />;
  }

  return (
    <MainLayout>
      <Header pageTitleTextId="school-sections-Filters" />
      <SchoolFiltersContent />
      <Outlet />
    </MainLayout>
  );
};
