import { Components, Theme } from '@mui/material';

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      // bootstrap/_reboot.css adds margin-bottom to labels
      marginTop: 0,
      marginBottom: 0,
      color: theme.palette.common.grey,

      '&:hover': {
        color: theme.palette.primary.light,
      },

      '&.MuiFormControlLabel-labelPlacementEnd .MuiRadio-root': {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1),
      },

      ['.MuiFormGroup-root &.MuiFormControlLabel-labelPlacementEnd:not(:last-of-type):has(.MuiRadio-root),' +
      '.MuiFormGroup-root &.MuiFormControlLabel-labelPlacementStart:not(:last-of-type):has(.MuiRadio-root)']:
        {
          marginBottom: theme.spacing(2),
        },

      '&:not(:hover) .Mui-checked + .MuiTypography-root:not(.Mui-disabled)': {
        color: theme.palette.primary.main,
      },
    }),
  },
};
