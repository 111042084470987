import { IconButton, Stack, Typography } from '@mui/material';
import { EditIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../../components/uikit/Counter/Counter.styled';
import { useSchool } from '../../../../../hooks/useSchool';
import { SchoolTuneStatusModalMode } from './scheme';
import { SchoolTuneStatusesDisplay } from './SchoolTuneStatusesDisplay';
import { SchoolTuneStatusesEmptyStub } from './SchoolTuneStatusesEmptyStub';

export const SchoolTuneStatuses: FC = () => {
  const { hasStatuses, studentPropertiesMap, staffPropertiesMap } = useSchool();

  if (!hasStatuses) {
    return <SchoolTuneStatusesEmptyStub />;
  }

  return (
    <Stack gap={4} sx={{ height: '100%' }}>
      <Stack gap={2} flex="1 1 50%">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Typography variant="h2">
              <FormattedMessage id="school-tabs-Statuses-StudentsStatuses" />
            </Typography>
            {!!studentPropertiesMap.status?.length && (
              <Counter>{studentPropertiesMap.status?.length}</Counter>
            )}
          </Stack>

          <Link
            to={{ pathname: 'statuses', search: `?type=${SchoolTuneStatusModalMode.STUDENTS}` }}
          >
            <IconButton inverse>
              <EditIcon />
            </IconButton>
          </Link>
        </Stack>

        <SchoolTuneStatusesDisplay type={SchoolTuneStatusModalMode.STUDENTS} />
      </Stack>

      <Stack gap={2} flex="1 1 50%">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Typography variant="h2">
              <FormattedMessage id="school-tabs-Statuses-StaffStatuses" />
            </Typography>
            {!!staffPropertiesMap.status?.length && (
              <Counter>{staffPropertiesMap.status?.length}</Counter>
            )}
          </Stack>

          <Link to={{ pathname: 'statuses', search: `?type=${SchoolTuneStatusModalMode.STAFF}` }}>
            <IconButton inverse>
              <EditIcon />
            </IconButton>
          </Link>
        </Stack>

        <SchoolTuneStatusesDisplay type={SchoolTuneStatusModalMode.STAFF} />
      </Stack>
    </Stack>
  );
};
