import { Box, IconButton, Stack, Typography } from '@mui/material';
import { UserRole } from '@schooly/api';
import { IContextAction, UserType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { LockIcon, MoreIcon } from '@schooly/style';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { ContextMenu } from '../../components/common/ContextMenu/ContextMenu';
import { LongStringWithTooltip } from '../../components/uikit-components/LongStringWithTooltip/LongStringWithTooltip';
import { getUserTypeTextId } from '../../helpers/misc';
import useAppLocation from '../../hooks/useAppLocation';
import { CardWrapper } from './UserRoleCard.styled';

interface IProps {
  role: UserRole;
  userType: UserType;
  disabled?: boolean;
}

const UserRoleCard: React.FC<IProps> = ({ role, userType, disabled }) => {
  const navigate = useNavigate();
  const location = useAppLocation();
  const { formatMessage } = useIntl();
  const { permissions } = useAuth();

  const handleAssignClick = useCallback(() => {
    navigate(`/userroles/${role.id}/assign`, { state: { backgroundLocation: location } });
  }, [navigate, location, role]);

  const handleModifyClick = useCallback(() => {
    navigate(`/userroles/${role.id}/edit`, { state: { backgroundLocation: location } });
  }, [navigate, location, role]);

  const isRoleManager = permissions.includes('userrole_manager');
  const isRoleViewer = permissions.includes('userrole_viewer') && !isRoleManager;

  const menuAvailable = isRoleManager || (isRoleViewer && role.editable);

  const contextActions: IContextAction[] = useMemo(() => {
    if (!isRoleManager && !isRoleViewer) {
      return [];
    }

    const baseAction = [
      {
        titleTextId: 'action-Assign',
        handler: handleAssignClick,
      },
    ];

    return isRoleManager && role.editable
      ? [
          {
            titleTextId: 'action-Modify',
            handler: handleModifyClick,
          },
          ...baseAction,
        ]
      : baseAction;
  }, [handleAssignClick, handleModifyClick, isRoleManager, isRoleViewer, role.editable]);

  return (
    <CardWrapper>
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack minWidth={0} width="100%">
          <Link to={`/userroles/${role.id}`}>
            <Stack flexDirection="row" alignItems="center">
              <Box minWidth={0}>
                <Typography variant="h3" color="text.primary">
                  <LongStringWithTooltip>{role.name}</LongStringWithTooltip>
                </Typography>
              </Box>
              {!role.editable && (
                <IconButton size="small" disabled>
                  <LockIcon />
                </IconButton>
              )}
            </Stack>

            <Box pl={1}>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.common.grey3,
                })}
              >
                {role.member_count ? (
                  <FormattedMessage
                    id="userRoles-CountOfTypeAssigned"
                    values={{
                      count: role.member_count,
                      userTypePlural: formatMessage({
                        id: getUserTypeTextId(userType, role.member_count > 1),
                      }),
                    }}
                  />
                ) : (
                  <FormattedMessage id="noUsersYet" defaultMessage="No users yet." />
                )}
              </Typography>
            </Box>
          </Link>
        </Stack>
        {menuAvailable && !disabled && (
          <ContextMenu key={role.id} actions={contextActions}>
            {(handleClick) => (
              <IconButton inverse onClick={handleClick}>
                <MoreIcon />
              </IconButton>
            )}
          </ContextMenu>
        )}
      </Stack>
    </CardWrapper>
  );
};

export default UserRoleCard;
