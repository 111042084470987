import { Box, StackProps } from '@mui/material';
import { AnnualPlanRecordTypes, SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { format } from 'date-fns';
import { FC } from 'react';

import {
  AnnualPlannerCalendarRecordCell,
  AnnualPlannerCalendarRecordEnd,
  AnnualPlannerCalendarRecordGroup,
  AnnualPlannerCalendarRecordTitle,
  AnnualPlannerCalendarRecordTitleTypography,
} from '../AnnualPlannerCalendar.styled';

export interface AnnualPlannerMainGridLayoutSelectedCellProps extends StackProps {
  date: Date;
  type?: AnnualPlanRecordTypes;
  start?: boolean;
  end?: boolean;
  highlight?: boolean;
}

export const AnnualPlannerMainGridLayoutSelectedCell: FC<AnnualPlannerMainGridLayoutSelectedCellProps> =
  ({ date, type, start, end, highlight, ...props }) => {
    const TitleComponents =
      end && !start ? AnnualPlannerCalendarRecordEnd : AnnualPlannerCalendarRecordTitle;

    return (
      <Box
        className={highlight ? 'AnnualPlannerCalendar-MainGrid-highlight' : undefined}
        {...props}
        sx={(theme) => ({
          position: 'relative',
          flex: '1 1 100%',
          height: 'calc(100% + 1px)',

          '&.AnnualPlannerCalendar-MainGrid-highlight': {
            display: 'none',
          },

          '&.AnnualPlannerCalendar-MainGrid-highlight-start .AnnualPlannerCalendarRecord-cell': {
            borderTopLeftRadius: theme.spacing(0.25),
            borderTopRightRadius: theme.spacing(0.25),
          },

          '&.AnnualPlannerCalendar-MainGrid-highlight-end .AnnualPlannerCalendarRecord-cell': {
            borderBottomLeftRadius: theme.spacing(0.25),
            borderBottomRightRadius: theme.spacing(0.25),
          },

          '&.AnnualPlannerCalendar-MainGrid-highlight-noTitle .AnnualPlannerCalendarRecord-title': {
            display: 'none',
          },
        })}
      >
        <AnnualPlannerCalendarRecordGroup
          sx={(theme) => ({
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: theme.zIndex.modal,
          })}
          type={type}
          className={[
            AnnualPlannerCalendarRecordGroup.defaultProps?.className,
            `AnnualPlannerCalendarRecordGroup-${type}`,
            'AnnualPlannerCalendarRecordGroup-active',
          ]
            .filter(Boolean)
            .join(' ')}
        >
          <AnnualPlannerCalendarRecordCell
            type={type}
            {...props}
            sx={{
              borderTopLeftRadius: highlight || !start ? 0 : undefined,
              borderTopRightRadius: highlight || !start ? 0 : undefined,
              borderBottomLeftRadius: highlight || !end ? 0 : undefined,
              borderBottomRightRadius: highlight || !end ? 0 : undefined,
            }}
          />
          {(highlight || start || end) && (
            <TitleComponents
              type={type}
              sx={{
                height: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <AnnualPlannerCalendarRecordTitleTypography sx={{ ml: 0.25 }}>
                {format(date, SHORT_FORMATTED_DATE_FORMAT_FNS)}
              </AnnualPlannerCalendarRecordTitleTypography>
            </TitleComponents>
          )}
        </AnnualPlannerCalendarRecordGroup>
      </Box>
    );
  };
