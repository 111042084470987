import {
  changeAttendanceCodesOrder,
  createAttendanceCode,
  editAttendanceCode,
  removeAttendanceCode,
  useGetAttendanceCodesQuery,
} from '@schooly/api';
import { AttendanceCode } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { AttendanceCodeSchoolPresence } from '@schooly/constants';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useRouter } from '../context/router/useRouter';
import useRequestWithProgress from './useRequestWithProgress';

export type AttendanceCodeForm = Omit<AttendanceCode, 'in_school'> & {
  in_school: AttendanceCodeSchoolPresence;
};

export const useAttendanceCodes = () => {
  const { id: codeId } = useParams();
  // const dispatch = useAppDispatch();
  const { goBack } = useRouter();
  const invalidateAttendanceQueries = useInvalidateListQueriesFor('attendance');

  const { schoolId = '' } = useAuth();
  const { data: attendanceCodes, isLoading } = useGetAttendanceCodesQuery(schoolId, {
    refetchOnMount: 'always',
  });

  const { getConfirmation } = useConfirmationDialog();

  const { showError, showNotification } = useNotifications();
  const { $t } = useIntl();

  const isEditing = !!codeId;

  const goToSettingsPage = useCallback(() => {
    goBack();
  }, [goBack]);

  const saveCode = useCallback(
    async (currentCode: AttendanceCodeForm) => {
      if (!schoolId) {
        return;
      }

      const successNotification = () =>
        showNotification({
          textId: codeId ? 'confirmation-SuccessfulEdited' : 'confirmation-SuccessfulSaved',
          values: { name: currentCode.name },
          type: 'success',
        });

      const { id, ...code } = currentCode;

      const inSchoolParams = {
        in_school: !!code.in_school ? true : false,
        is_present: !code.in_school ? !!code.is_present : undefined,
      };

      if (!codeId) {
        const codeData: Omit<AttendanceCode, 'id'> = {
          ...code,
          ...inSchoolParams,
          order: (attendanceCodes?.length ?? 0) + 1,
        };

        try {
          await createAttendanceCode(schoolId, [codeData]);
          invalidateAttendanceQueries();

          successNotification();
          goToSettingsPage();
        } catch (err) {
          console.error(err);
          showError(err as ApiError);
        }
      } else {
        try {
          await editAttendanceCode({ ...currentCode, ...inSchoolParams });
          invalidateAttendanceQueries();

          successNotification();
          goToSettingsPage();
        } catch (err) {
          console.error(err);
          showError(err as ApiError);
        }
      }
    },
    [
      attendanceCodes?.length,
      codeId,
      goToSettingsPage,
      schoolId,
      showError,
      showNotification,
      invalidateAttendanceQueries,
    ],
  );

  const changeCodeOrder = useCallback(
    async (codes: Pick<AttendanceCode, 'id' | 'order'>[]) => {
      if (!schoolId) {
        return;
      }

      try {
        await changeAttendanceCodesOrder({ schoolId, order: codes });
        invalidateAttendanceQueries();

        goToSettingsPage();
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }
    },
    [goToSettingsPage, schoolId, showError, invalidateAttendanceQueries],
  );

  const deleteCode = useCallback(
    async (code: AttendanceCodeForm) => {
      if (!schoolId || !codeId) {
        return;
      }

      try {
        const confirmed = await getConfirmation({
          cancelTextId: 'no',
          confirmTextId: 'yes',
          message: $t({ id: 'attendance-CodeRemoveConfirmAction' }, { codeName: code.name }),
        });

        if (!confirmed) {
          return;
        }

        // TR-3744 FE: Do not allow deleting attendance codes for now
        const res = await removeAttendanceCode({ codeId, confirmed: false });

        if (res?.code_to_delete) {
          showError({
            reason: $t({ id: 'attendance-CodeRemoveError' }),
          });
          return;
        }

        invalidateAttendanceQueries();
        goToSettingsPage();
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }
    },
    [
      $t,
      codeId,
      getConfirmation,
      goToSettingsPage,
      schoolId,
      showError,
      invalidateAttendanceQueries,
    ],
  );

  const [deleteAttendanceCode, isDeletingInProgress] = useRequestWithProgress(deleteCode);
  const [changeAttendanceCodeOrder, isChangeOrderInProcess] =
    useRequestWithProgress(changeCodeOrder);
  const [saveAttendanceCode, isSavingInProcess] = useRequestWithProgress(saveCode);

  return {
    isEditing,
    isLoading: isLoading || isChangeOrderInProcess || isSavingInProcess,
    isDeleting: isDeletingInProgress,
    codeId,
    attendanceCodes: attendanceCodes ?? [],
    onClose: goToSettingsPage,
    saveAttendanceCode,
    changeAttendanceCodeOrder,
    deleteAttendanceCode,
  };
};
