import { Box, IconButton } from '@mui/material';
import { DoubleCheckIcon } from '@schooly/style';
import { FC } from 'react';

import Tooltip from '../../components/ui/Tooltip';

interface ConductVisibilityButtonProps {
  onClick?: () => void;
  tooltipTitle?: React.ReactNode;
  selected?: boolean;
  width?: number;
  nowrap?: boolean;
}
export const ConductVisibilityButton: FC<ConductVisibilityButtonProps> = ({
  onClick,
  tooltipTitle,
  selected,
  width,
  nowrap,
}) => {
  return (
    <Tooltip
      offsetY={10}
      element={
        <Box width={width ? `${width}px` : '100%'} whiteSpace={nowrap ? 'nowrap' : 'normal'}>
          {tooltipTitle}
        </Box>
      }
    >
      <IconButton
        inverse={selected}
        className="icon-ConductVisibility"
        sx={{
          color: selected ? 'primary.main' : 'text.secondary',
        }}
        onClick={onClick}
      >
        <DoubleCheckIcon />
      </IconButton>
    </Tooltip>
  );
};
