import { AutocompleteValue } from '@mui/base/useAutocomplete';
import { Autocomplete } from '@mui/material';
import { AssessmentMethodType } from '@schooly/api';
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ExtendedFieldError } from '../../../../components/ui/Input/utils';
import { FormTextField } from '../../../../components/uikit-components/FormTextField/FormTextField';
import { AssessmentForm } from '../AssessmentCreateContent';

export interface AssessmentCreateModalSettingsMethodTypeProps {
  methodType?: AssessmentMethodType;
  autoFocus?: boolean;
  error?: ExtendedFieldError;
  onChange?: (methodType?: AssessmentMethodType) => void;
}

export const AssessmentsCreateModalSettingsMethodType: FC<AssessmentCreateModalSettingsMethodTypeProps> =
  ({ methodType, autoFocus, error, onChange }) => {
    const { formatMessage } = useIntl();
    const { watch } = useFormContext<AssessmentForm>();

    const methods = watch('methods');

    const assessmentMethodTypeOptions = useMemo(
      () =>
        [
          {
            methodType: AssessmentMethodType.Score,
            label: formatMessage({ id: `assessments-Type-${AssessmentMethodType.Score}` }),
          },
          {
            methodType: AssessmentMethodType.Grade,
            label: formatMessage({ id: `assessments-Type-${AssessmentMethodType.Grade}` }),
          },
          {
            methodType: AssessmentMethodType.Comment,
            label: formatMessage({ id: `assessments-Type-${AssessmentMethodType.Comment}` }),
          },
        ].filter(
          (option) =>
            option.methodType === methodType ||
            !methods?.some((method) => method.method_type === option.methodType),
        ),
      [formatMessage, methodType, methods],
    );

    const isRequired = !methods?.filter((method) => method.method_type != null).length;

    const value =
      methodType != null
        ? assessmentMethodTypeOptions.find((option) => option.methodType === methodType)
        : undefined;

    const handleChange = useCallback(
      (
        event?: React.SyntheticEvent,
        option?: AutocompleteValue<typeof value, undefined, undefined, undefined>,
      ) => {
        onChange?.(option?.methodType);
      },
      [onChange],
    );

    return (
      <Autocomplete
        options={assessmentMethodTypeOptions}
        renderInput={(params) => (
          <FormTextField
            error={Boolean(error)}
            helperText={error?.message}
            {...params}
            label={formatMessage({ id: 'assessments-AssessmentType' })}
            autoFocus={autoFocus}
            required={isRequired}
            dropdown
            sx={(theme) => ({
              '.MuiFormLabel-root': {
                color: error ? theme.palette.error.main : undefined,
              },
            })}
          />
        )}
        value={value}
        onChange={handleChange}
        openOnFocus
        blurOnSelect
        disableClearable
        fullWidth
      />
    );
  };
