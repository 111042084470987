import { FC } from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { CompaniesContent } from './CompaniesContent';

export const CompaniesPage: FC = () => {
  return (
    <MainLayout>
      <CompaniesContent />
    </MainLayout>
  );
};
