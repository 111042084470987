export const NoAssessmentsSvg = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6439_202363)">
        <path
          d="M74.501 107.019C74.501 107.839 75.0012 108.575 75.7631 108.878L184.335 151.974V73.7044C184.335 72.8848 183.835 72.1481 183.073 71.8456L115.341 44.9524C114.872 44.7659 114.49 44.4087 114.273 43.9524L108.004 30.767C107.787 30.3108 107.405 29.9535 106.936 29.767L77.2392 17.9721C75.9262 17.4506 74.501 18.418 74.501 19.8309V107.019Z"
          fill="#24275B"
        />
        <path
          d="M62.4805 138.49L172.284 182.088V81.253L62.4805 37.6556V138.49Z"
          fill="white"
          stroke="#E7E9F4"
          strokeMiterlimit="10"
        />
        <path
          d="M62.4805 115.925C62.4805 116.745 62.9806 117.482 63.7424 117.784L172.284 160.881V82.6112C172.284 81.7914 171.783 81.0546 171.021 80.7522L103.321 53.8899C102.851 53.7035 102.469 53.3462 102.252 52.8897L95.9831 39.7046C95.7662 39.2484 95.3847 38.8911 94.9152 38.7047L65.2187 26.9097C63.9057 26.3882 62.4805 27.3556 62.4805 28.7685V115.925Z"
          fill="white"
          stroke="#E7E9F4"
          strokeMiterlimit="10"
        />
        <path
          d="M62.1379 138.906C62.3618 139.224 62.6735 139.47 63.0348 139.613L172.284 182.991L145.565 145.061L125.916 117.178C125.692 116.861 125.38 116.615 125.019 116.472L22.0093 75.5716C20.1616 74.838 18.4913 76.9572 19.6363 78.5824L62.1379 138.906Z"
          fill="#E7E9F4"
        />
        <path
          d="M88.5142 127.653C88.5142 127.653 72.4143 109.809 71.8538 116.442C71.4801 120.74 74.8744 123.979 76.5872 124.695C78.2999 125.411 78.6425 119.743 67.276 113.017C55.9096 106.29 62.5737 119.276 64.5045 119.712C66.4352 120.148 67.7743 113.858 52.9512 107.38"
          stroke="#24275B"
          strokeMiterlimit="10"
        />
        <path
          d="M109.472 146.244C109.472 146.244 98.0123 133.601 95.4588 133.944C92.9363 134.255 98.7597 138.957 96.5798 139.642C94.4 140.328 85.9919 129.21 82.8778 129.584C79.7637 129.958 86.5213 134.816 82.4418 133.881C78.3624 132.947 71.7293 125.006 67.4941 124.85"
          stroke="#24275B"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_6439_202363">
          <rect
            width="169.158"
            height="166.667"
            fill="white"
            transform="translate(15.333 16.6666)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Arrow = () => {
  return (
    <svg
      width="176"
      height="186"
      viewBox="0 0 176 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.938 158.534L123.487 158.749L123.271 158.298L119.386 150.177L120.288 149.746L123.694 156.866C132.014 139.944 140.386 109.768 136.322 81.2859C134.266 66.8756 129.03 52.9177 119.012 41.3226C108.996 29.7304 94.1738 20.4664 72.8866 15.4869L73.1144 14.5132C94.5769 19.5337 109.596 28.8947 119.768 40.6688C129.938 52.4399 135.235 66.5897 137.312 81.1446C141.391 109.73 133.06 139.951 124.717 157.052L131.627 153.746L132.059 154.649L123.938 158.534Z"
        fill="#C7C8E0"
      />
    </svg>
  );
};
