import { Stack, Typography } from '@mui/material';
import { Event } from '@schooly/api';
import { isTimeInPast } from '@schooly/components/filters';
import { ControlTimeInput } from '@schooly/components/form-time-input';
import { DATE_FORMAT } from '@schooly/constants';
import { isTimesRangeValid } from '@schooly/utils/date';
import { format, isBefore, isToday, startOfToday } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FieldPath, FieldPathValue } from 'react-hook-form-lts/dist/types/path';
import { Validate } from 'react-hook-form-lts/dist/types/validator';
import { useIntl } from 'react-intl';

export interface TimeRangeInputsProps {
  index: number;
  date?: Date;
  disabled?: boolean;
  showDate?: boolean;
}

export const TimeRangeInputs = ({ index, date, disabled, showDate }: TimeRangeInputsProps) => {
  const { $t } = useIntl();
  const { control, watch, clearErrors } = useFormContext<Event>();
  const { update } = useFieldArray<Event>({
    name: 'date_times',
    control,
  });

  const [validationTimeFromError, setValidationTimeFromError] = useState<FieldError | null>(null);
  const [validationTimeToError, setValidationTimeToError] = useState<FieldError | null>(null);

  const inputRefTimeFrom: `date_times.${number}.0` = `date_times.${index}.0`;
  const inputRefTimeTo: `date_times.${number}.1` = `date_times.${index}.1`;

  const timeFrom = watch(inputRefTimeFrom);
  const timeTo = watch(inputRefTimeTo);

  useEffect(() => {
    // should update the entire item as the fields itself don't mutate the item array,
    // which leads to missing reaction in other places
    update(index, [timeFrom, timeTo]);
    clearErrors(
      [timeFrom && inputRefTimeFrom, timeTo && inputRefTimeTo].filter(
        Boolean,
      ) as FieldPath<Event>[],
    );
  }, [clearErrors, index, inputRefTimeFrom, inputRefTimeTo, timeFrom, timeTo, update]);

  const today = startOfToday();
  const isDateInPast = date && isBefore(date, today);
  const isDateToday = date && isToday(date);

  const pastTimeErrorMessage = $t({ id: 'input-ErrorPastTime' });
  const timeRangeErrorMessage = $t({ id: 'input-ErrorTimeRange' });

  // Sets time validation errors in case dates range is changed
  useEffect(() => {
    if (!validationTimeFromError && !validationTimeToError) {
      if (!disabled && isDateToday) {
        if (timeFrom && isTimeInPast(timeFrom)) {
          setValidationTimeFromError({
            type: 'pastTimeError',
            message: pastTimeErrorMessage,
          });
        }
        if (timeTo && isTimeInPast(timeTo)) {
          setValidationTimeToError({
            type: 'pastTimeError',
            message: pastTimeErrorMessage,
          });
        }
      } else if (timeFrom && timeTo && !isTimesRangeValid(timeFrom, timeTo)) {
        setValidationTimeToError({
          type: 'timeRangeError',
          message: timeRangeErrorMessage,
        });
      }
    }
  }, [
    $t,
    isDateToday,
    pastTimeErrorMessage,
    timeRangeErrorMessage,
    validationTimeFromError,
    validationTimeToError,
    date,
    disabled,
    isDateInPast,
    timeFrom,
    timeTo,
  ]);

  //Sets empty time value for disabled inputs
  useEffect(() => {
    if (!timeFrom && !timeTo && isDateInPast) {
      update(index, [null, null]);
    }
  }, [index, isDateInPast, timeFrom, timeTo, update]);

  const validate = useCallback<Validate<FieldPathValue<Event, FieldPath<Event>>, Event>>(
    (value, formValues) => {
      if (!disabled && isDateToday && isTimeInPast(value as string)) {
        return pastTimeErrorMessage;
      } else {
        const timeFrom = formValues.date_times[index][0];
        const timeTo = formValues.date_times[index][1];

        if (timeFrom && timeTo && !isTimesRangeValid(timeFrom, timeTo)) {
          return timeRangeErrorMessage;
        }
      }
    },
    [disabled, index, isDateToday, pastTimeErrorMessage, timeRangeErrorMessage],
  );

  return (
    <Stack direction="row" alignItems="flex-start" gap={1}>
      {date && showDate && (
        <Typography variant="h3" color="primary.main" pt={1.5} width={90}>
          {format(date, DATE_FORMAT)}
        </Typography>
      )}
      <ControlTimeInput
        control={control}
        name={inputRefTimeFrom}
        label={$t({ id: 'events-time-Start' })}
        disabled={isDateInPast || disabled}
        rules={{ required: true, validate }}
      />

      <Typography variant="h3" color="primary.main" pt={1.5}>
        {$t({ id: 'events-time-To' })}
      </Typography>
      <ControlTimeInput
        name={inputRefTimeTo}
        label={$t({ id: 'events-time-End' })}
        disabled={isDateInPast || disabled}
        rules={{ required: true, validate }}
      />
    </Stack>
  );
};
