import {
  MonthDayOccurrence,
  MonthWeekOccurrence,
  Occurrence,
  RecurringRepeatBy,
  RecurringState,
  WeekOccurrence,
} from '@schooly/api';
import { DAY_OF_WEEK_OPTIONS, WeekDays } from '@schooly/components/form-text-field';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import {
  add,
  addDays,
  addMonths,
  addWeeks,
  getISODay,
  getWeekOfMonth,
  isAfter,
  isBefore,
  isSameMonth,
  setDay,
  setDefaultOptions,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

import { RecurringEndsSection } from './RecurringEndDate';
import { RecurringForm } from './RecurringModal';
import { RepeatOnMonth } from './RecurringMonthSelect';

setDefaultOptions({ weekStartsOn: 1 });

type FindNextDateProps = {
  date: Date;
  monthCount: number;
  weekNumber: number;
  dayOfWeek: number;
  dayOfMonth?: number;
};

const findNextDate = ({ date, ...props }: FindNextDateProps): Date => {
  // Incrementing months and getting the start day of the month
  const startOfNewMonthDate = addMonths(startOfMonth(date), props.monthCount);
  const dateWithIncrementedDaysOrWeeks = props.dayOfMonth
    ? addDays(startOfNewMonthDate, props.dayOfMonth - 1)
    : setDay(addWeeks(startOfNewMonthDate, props.weekNumber - 1), props.dayOfWeek);

  const isOutOfMonthRange = !isSameMonth(startOfNewMonthDate, dateWithIncrementedDaysOrWeeks);

  // If we don't have a day or week number in a month, we increment the month counter.
  return isOutOfMonthRange
    ? findNextDate({ date: startOfNewMonthDate, ...props })
    : dateWithIncrementedDaysOrWeeks;
};

type GetFollowingDatesByMonth = {
  startDate: Date;
  repeatCount: number;
  ends: Date | number;
  dayOfMonth?: number;
};

export function getFollowingDatesByMonth({
  ends,
  repeatCount,
  startDate,
  dayOfMonth,
}: GetFollowingDatesByMonth) {
  const props = {
    monthCount: repeatCount,
    dayOfWeek: startDate.getDay(),
    weekNumber: getWeekOfMonth(startDate),
    dayOfMonth,
    startDate,
  };

  return typeof ends === 'number'
    ? getMonthDatesByOccurrence({ numberOfOccurrence: ends, ...props })
    : getMonthDatesByEndDate({ endDate: ends, ...props });
}

type GetMonthDatesProps = {
  monthCount: number;
  dayOfWeek: number;
  weekNumber: number;
  dayOfMonth?: number;
  startDate: Date;
};

export function getMonthDatesByOccurrence({
  numberOfOccurrence,
  dayOfWeek,
  monthCount,
  dayOfMonth,
  weekNumber,
  startDate,
}: GetMonthDatesProps & { numberOfOccurrence: number }) {
  const occurrence = [...Array(numberOfOccurrence).keys()];

  const recurringDates: Date[] = [];

  for (const occurrenceCount of occurrence) {
    if (occurrenceCount === 0) {
      recurringDates.push(startDate);
      continue;
    }

    const lastDate = recurringDates[recurringDates.length - 1];

    recurringDates.push(
      findNextDate({ date: lastDate, monthCount, dayOfWeek, dayOfMonth, weekNumber }),
    );
  }

  return recurringDates;
}

function getMonthDatesByEndDate({
  endDate,
  startDate,
  ...props
}: GetMonthDatesProps & { endDate: Date }) {
  const getDates = ({ date, dates }: { date: Date; dates: Date[] }): Date[] => {
    const recurringDates: Date[] = dates.length ? dates : [date];
    const lastDate = recurringDates[recurringDates.length - 1];
    const nextDate = findNextDate({ date: lastDate, ...props });

    if (!isAfter(nextDate, endDate)) {
      recurringDates.push(nextDate);
      return getDates({ date: nextDate, dates: recurringDates });
    }

    return recurringDates;
  };

  return getDates({ date: startDate, dates: [] });
}

type GetFollowingDatesByWeekProps = {
  startDate: Date;
  repeatCount: number;
  ends: Date | number;
  weekDays: WeekDays[];
};

export function getFollowingDatesByWeek({ ends, ...props }: GetFollowingDatesByWeekProps) {
  return typeof ends === 'number'
    ? getWeekDatesByOccurrence({ numberOfOccurrence: ends, ...props })
    : getWeekDatesByEndDate({ endDate: ends, ...props });
}

function getWeekDatesByOccurrence({
  numberOfOccurrence,
  repeatCount,
  startDate,
  weekDays,
}: Omit<GetFollowingDatesByWeekProps, 'ends'> & { numberOfOccurrence: number }) {
  const dates = [];
  let occurrence = numberOfOccurrence;
  let startDayOfWeek = startOfWeek(startDate);

  while (occurrence > 0) {
    for (const dayNumber of weekDays) {
      const day = addDays(startDayOfWeek, dayNumber - 1);
      if (isBefore(day, startDate)) continue;

      occurrence--;
      dates.push(day);

      if (occurrence === 0) {
        break;
      }
    }
    startDayOfWeek = add(startDayOfWeek, { weeks: repeatCount });
  }
  return dates;
}

export function getWeekDatesByEndDate({
  endDate,
  repeatCount,
  startDate,
  weekDays,
}: Omit<GetFollowingDatesByWeekProps, 'ends'> & { endDate: Date }) {
  const getFollowingDates = (week: Date, dates: Date[]): Date[] => {
    let founded = false;

    for (const dayNumber of weekDays) {
      const day = addDays(week, dayNumber - 1);
      if (isBefore(day, startDate)) continue;

      if (isAfter(day, endDate)) {
        founded = true;
        break;
      }
      dates.push(day);
    }

    return !founded
      ? getFollowingDates(startOfWeek(add(week, { weeks: repeatCount })), dates)
      : dates;
  };

  return getFollowingDates(startOfWeek(startDate), []);
}

type ConvertRecurringFormToStateProps = {
  form: RecurringForm;
  startDate: Date;
};

export function convertRecurringFormToState({
  form,
  startDate,
}: ConvertRecurringFormToStateProps): RecurringState {
  const monthOccurrence =
    form.repeat_on_month === RepeatOnMonth.ByWeekOfMonth
      ? {
          day_of_week: [getISODay(startDate)] as [number],
          day_of_month: null,
          week_of_month: getWeekOfMonth(startDate),
        }
      : {
          day_of_week: null,
          day_of_month: startDate.getDate(),
          week_of_month: null,
        };
  const weekOccurrence: WeekOccurrence = {
    day_of_month: null,
    day_of_week: form.repeat_on_day_of_week,
    week_of_month: null,
  };

  const getFollowingCount = () => {
    if (form.period === 'month') {
      return getFollowingDatesByMonth({
        ends: form.ends?.byOccurrence
          ? form.ends.byOccurrence
          : newDateTimezoneOffset(form.ends?.byDate ?? null),
        repeatCount: form.repeat_count,
        startDate: startDate,
        dayOfMonth:
          form.repeat_on_month === RepeatOnMonth.ByDayOfMonth ? startDate.getDate() : undefined,
      }).length;
    }

    return getFollowingDatesByWeek({
      repeatCount: form.repeat_count,
      startDate: startDate,
      ends: form.ends?.byOccurrence
        ? form.ends.byOccurrence
        : newDateTimezoneOffset(form.ends?.byDate ?? null),
      weekDays: form.repeat_on_day_of_week ?? [],
    }).length;
  };

  return {
    repeat_every: {
      repeat_count: form.repeat_count,
      period: form.period,
      occurrence: form.period === RecurringRepeatBy.Week ? weekOccurrence : monthOccurrence,
    },
    ends_on:
      form.ends?.section === RecurringEndsSection.ByDate && form.ends?.byDate
        ? form.ends.byDate
        : null,
    ends_after:
      form.ends?.section === RecurringEndsSection.ByOccurrence && form.ends?.byOccurrence
        ? form.ends?.byOccurrence
        : null,

    following_count: getFollowingCount() - 1,
  };
}

export function convertRecurringStateToForm(state: RecurringState): RecurringForm {
  const {
    ends_after,
    ends_on,
    repeat_every: { occurrence, period, repeat_count },
  } = state;
  const getOccurrence = () => {
    if (period === 'week' && !!occurrence.day_of_week?.length) {
      return {
        repeat_on_day_of_week: occurrence.day_of_week,
        repeat_on_month: RepeatOnMonth.ByWeekOfMonth,
      };
    }

    if (period === 'month') {
      return {
        repeat_on_month:
          'day_of_month' in occurrence && occurrence.day_of_month
            ? RepeatOnMonth.ByDayOfMonth
            : RepeatOnMonth.ByWeekOfMonth,
        repeat_on_day_of_week: occurrence.day_of_week ?? [],
      };
    }

    throw new Error('Unexpected recurring state data');
  };
  return {
    period: period,
    repeat_count: repeat_count,
    ends: {
      section: ends_on ? RecurringEndsSection.ByDate : RecurringEndsSection.ByOccurrence,
      byDate: ends_on ?? undefined,
      byOccurrence: ends_after ?? undefined,
    },
    ...getOccurrence(),
  };
}

export function getRecurringDaysString(
  weekDays: WeekOccurrence['day_of_week'],
  translate: (d: string) => string,
  shortDays?: boolean,
) {
  const daysOfWeek = DAY_OF_WEEK_OPTIONS.filter(({ value }) => weekDays.includes(value)).map(
    ({ labelTextId }) => (shortDays ? translate(labelTextId).slice(0, 3) : translate(labelTextId)),
  );

  const days =
    daysOfWeek.length > 1
      ? `${daysOfWeek.slice(0, daysOfWeek.length - 1).join(', ')} ${translate(
          'and',
        )} ${daysOfWeek.at(-1)}`
      : `${daysOfWeek.at(0)}${shortDays ? '' : 's'}`;

  return days;
}

export function isWeekOccurrence(o: Occurrence): o is WeekOccurrence {
  return Boolean(o.day_of_week && !o.day_of_month && !o.week_of_month);
}
export function isDayOfMothOccurrence(o: Occurrence): o is MonthDayOccurrence {
  return Boolean(o.day_of_month && !o.week_of_month && !o.day_of_week);
}
export function isMonthWeekOccurrence(o: Occurrence): o is MonthWeekOccurrence {
  return Boolean(o.day_of_week && o.week_of_month && !o.day_of_month);
}

export function checkRecurrenceStartDateError(recurringState: RecurringState, startDate: string) {
  const recurrenceStartDate = newDateTimezoneOffset(startDate);
  const {
    ends_on,
    ends_after,
    repeat_every: { occurrence, repeat_count },
  } = recurringState;

  if (ends_on) {
    return isBefore(newDateTimezoneOffset(recurringState.ends_on), recurrenceStartDate);
  }

  if (!ends_after) return true;

  const isByDayOfMoth = isDayOfMothOccurrence(occurrence);

  const recurrenceEndDates = getFollowingDatesByMonth({
    ends: ends_after,
    repeatCount: repeat_count,
    startDate: recurrenceStartDate,
    dayOfMonth: isByDayOfMoth ? recurrenceStartDate.getDate() : undefined,
  });

  const endDate = recurrenceEndDates.at(-1);

  return endDate ? isBefore(endDate, recurrenceStartDate) : true;
}

export function getRecurringDates(startDate: Date, recurringState: RecurringState) {
  const {
    ends_on,
    ends_after,
    repeat_every: { occurrence, repeat_count, period },
  } = recurringState;

  const isByDayOfMoth = isDayOfMothOccurrence(occurrence);

  const dates =
    period === 'week'
      ? getFollowingDatesByWeek({
          ends: ends_after ? ends_after : newDateTimezoneOffset(ends_on),
          weekDays: occurrence.day_of_week ?? [],
          repeatCount: repeat_count,
          startDate: startDate,
        })
      : getFollowingDatesByMonth({
          ends: ends_after ? ends_after : newDateTimezoneOffset(ends_on),
          repeatCount: repeat_count,
          startDate: startDate,
          dayOfMonth: isByDayOfMoth ? startDate.getDate() : undefined,
        });

  return dates;
}
