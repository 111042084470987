import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateRangeSelect } from '@schooly/components/filters';
import { format, isAfter, isBefore } from 'date-fns';
import { useCallback } from 'react';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { Validate } from 'react-hook-form-lts/dist/types/validator';
import { useIntl } from 'react-intl';

import { usePeriodValidation } from '../../../../School/SchoolPeriods/SchoolPeriodsUpdate/usePeriodValidation';
import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import { AnnualPlannerCreateForm } from '../../scheme';
import { useAnnualPlannerCreateRecord } from '../WithAnnualPlannerCreate';

export const AnnualPlannerCreateSchoolPeriodDate: FC = () => {
  const { $t } = useIntl();
  const { schoolYear, start, end } = useAnnualPlanner();
  const { record } = useAnnualPlannerCreateRecord();
  const { control, watch } = useFormContext<AnnualPlannerCreateForm>();
  const { validateDatePeriods, validatePastDate, validatePeriodsOverlap } = usePeriodValidation();

  const groupId = watch('schoolPeriod.groupId');

  const group = groupId
    ? schoolYear?.period_groups?.find((group) => group.id === groupId)
    : undefined;

  /**
   * Validates a date value for school periods
   *
   * TODO: make shareable as might partially duplicate
   *  apps/web/src/pages/School/SchoolPeriods/SchoolPeriodsUpdate/PeriodRow.tsx:validateDate
   */
  const validate = useCallback<
    (index: number) => Validate<AnnualPlannerCreateForm['date'], AnnualPlannerCreateForm>
  >(
    (index) => (value) => {
      const startDate = value[0];
      const endDate = value[1];

      if (startDate && !endDate) {
        return index === 0 ? undefined : $t({ id: 'school-schoolPeriods-ErrorDates' });
      }

      // Checks after submit
      if (!startDate || !endDate || !schoolYear) return;

      const isStartDateInPast = validatePastDate(startDate);

      if (isStartDateInPast) {
        return index === 0 ? $t({ id: 'school-schoolPeriods-ErrorPeriodInPast' }) : undefined;
      }

      const isPeriodOverlapsTheYear = validateDatePeriods(
        { start: startDate, end: endDate },
        {
          start: schoolYear.start,
          end: schoolYear.end,
        },
      );

      if (!isPeriodOverlapsTheYear) {
        return $t({ id: 'school-schoolPeriods-ErrorPeriodOverlapsTheYear' });
      }

      const periods =
        (record
          ? // exclude current period if this is an existing one
            group?.periods.filter((period) => period.id !== record.details.id)
          : group?.periods) ?? [];

      const isPeriodOverlaps = validatePeriodsOverlap(startDate, endDate, periods);

      if (isPeriodOverlaps) {
        return $t({ id: 'school-schoolPeriods-ErrorPeriodOverlapsPrevPeriod' });
      }
    },
    [
      $t,
      group?.periods,
      record,
      schoolYear,
      validateDatePeriods,
      validatePastDate,
      validatePeriodsOverlap,
    ],
  );

  return (
    <Controller
      control={control}
      name="date"
      rules={{
        validate: {
          startDate: validate(0),
          endDate: validate(1),
        },
      }}
      render={({ field, fieldState }) => {
        const generalError = fieldState.error;
        const fromDateError = generalError?.type === 'startDate';
        const toDateError = generalError?.type === 'endDate';

        return (
          <DateRangeSelect
            singleDatePossible
            date={field.value || []}
            fromDateError={fromDateError}
            toDateError={toDateError}
            error={generalError}
            DateRangeSelectContentProps={{
              disablePast: true,
              shouldDisableDay: (date) =>
                !!((start && isBefore(date, start)) || (end && isAfter(date, end))),
            }}
            onSetDate={([startDate, endDate]) => {
              field.onChange([
                format(startDate, DEFAULT_DATE_FORMAT_FNS),
                format(endDate, DEFAULT_DATE_FORMAT_FNS),
              ]);
            }}
          />
        );
      }}
    />
  );
};
