import { IconButton, Stack } from '@mui/material';
import { GroupTagSelect, PropertyTypeTagSelect } from '@schooly/components/filters';
import { SchoolUserRole } from '@schooly/constants';
import { CrossIcon, TagSelect } from '@schooly/style';
import React, { FC, ReactNode, useMemo } from 'react';

import { useSchool } from '../../../hooks/useSchool';
import { AddStudentsFilters } from './AddStudentsFiltersPopup';

type InlineFiltersTagsProps = {
  onOpenFilters: () => void;
  onResetFilters: () => void;
  maxAmount?: number;
  filters: AddStudentsFilters;
};

export const InlineFiltersTags: FC<InlineFiltersTagsProps> = ({
  filters,
  onOpenFilters,
  onResetFilters,
  maxAmount = 2,
}) => {
  const { schoolId } = useSchool();

  const tags = useMemo(() => {
    const tagComponents: ReactNode[] = [];

    if (!schoolId) return tagComponents;

    const commonTagsProps = {
      size: 'small',
      onClick: onOpenFilters,
      sx: { maxWidth: 115 },
    } as const;

    for (const id of [
      ...(filters.status || []),
      ...(filters.age_group || []),
      ...(filters.house || []),
    ]) {
      tagComponents.push(
        <PropertyTypeTagSelect
          key={id}
          id={id}
          schoolId={schoolId}
          userRole={SchoolUserRole.Student}
          {...commonTagsProps}
        />,
      );
    }

    for (const id of filters.group || []) {
      tagComponents.push(<GroupTagSelect key={id} id={id} {...commonTagsProps} />);
    }

    if (tagComponents.length > maxAmount) {
      return [
        ...tagComponents.slice(0, maxAmount),
        <TagSelect label={`+${tagComponents.length - maxAmount}`} {...commonTagsProps} />,
      ];
    }

    return tagComponents;
  }, [
    filters.age_group,
    filters.group,
    filters.house,
    filters.status,
    onOpenFilters,
    schoolId,
    maxAmount,
  ]);

  if (!tags.length) return null;

  return (
    <Stack flexDirection="row" flexWrap="wrap" gap={1}>
      {tags}{' '}
      <IconButton
        onClick={onResetFilters}
        sx={(theme) => ({
          color: theme.palette.common.grey,
          '&:hover': { color: theme.palette.text.primary },
        })}
      >
        <CrossIcon />
      </IconButton>
    </Stack>
  );
};
