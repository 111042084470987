import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ConsentFormReference, FilterKeys } from '@schooly/api';
import { RecurrenceInfo } from '@schooly/components/recurring';
import { NewTabIcon, ShowHtml } from '@schooly/style';
import React, { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { SignUpPreview } from '../../Events/EventCreateModal/SignUpPreview';
import { useReferenceInfo } from '../hooks';
import { EventPreview } from './EventPreview';
import { MessagePreview } from './MessagePreview';

type ReferencePreviewProps = {
  reference: ConsentFormReference;
};

export const ReferencePreview: FC<PropsWithChildren<ReferencePreviewProps>> = ({
  reference,
  children,
}) => {
  const navigate = useNavigate();
  const { description, path } = useReferenceInfo(reference);
  const { type, data } = reference;

  return (
    <Stack
      sx={{
        p: 2,
        bgcolor: 'background.default',
        borderRadius: 0.5,
        overflow: 'hidden',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" mb={0.5} gap={0.5}>
          <Typography variant="h2" flex="0 0 auto">
            {data.title}
          </Typography>
          {type === 'event' && data.recurring_state && (
            <RecurrenceInfo
              recurringState={data.recurring_state}
              onClick={() => {
                navigate(
                  {
                    pathname: '/events',
                  },
                  {
                    state: {
                      [FilterKeys.RecurrenceId]: data.recurring_state?.recurrence_id,
                    },
                  },
                );
              }}
            />
          )}
        </Stack>
        <Link to={path} target="_blank">
          <IconButton size="small" inverse>
            <NewTabIcon />
          </IconButton>
        </Link>
      </Stack>
      {type === 'event' && <EventPreview event={data} />}
      {type === 'message' && <MessagePreview message={data} />}
      {type === 'signup' && <SignUpPreview signUp={data} />}

      <Box
        sx={{
          flex: '1 1 auto',
          overflowY: 'auto',
          maxHeight: 52,
          overflow: 'hidden',
          '& p': {
            mb: 1.75,
          },
        }}
      >
        <ShowHtml html={description} />
      </Box>
      {children}
    </Stack>
  );
};
