import {
  GetPayableFeesStatisticsResponse,
  PayableFeesStatisticsItem,
  PayableFeeStatus,
  WithName,
} from '@schooly/api';
import { Currencies } from '@schooly/constants';
import { theme } from '@schooly/style';

export const payableFeeStatusColor: Record<PayableFeeStatus, string> = {
  paid: theme.palette.success.main,
  unpaid: theme.palette.common.orange,
  partially_paid: theme.palette.common.coral,
  overdue: theme.palette.error.main,
  upcoming: theme.palette.primary.main,
  voided: theme.palette.common.grey,
  cancelled: theme.palette.common.grey,
};

export function getStudentName<T extends WithName>(student: T) {
  return student.known_as || student.given_name || '';
}

export type PayableFeesStatisticsMapItem = {
  items: PayableFeesStatisticsItem[];
  total: number;
};

type PayableFeesStatisticsMap = Partial<Record<Currencies, PayableFeesStatisticsMapItem>>;

export const getChartStatisticsByCurrencyMap = (
  statistics?: GetPayableFeesStatisticsResponse['statistics'],
) => {
  return statistics?.reduce<PayableFeesStatisticsMap>((acc, { currency, items, total }) => {
    acc[currency] = { items, total };
    return acc;
  }, {});
};
