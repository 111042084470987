import { Components, Theme } from '@mui/material';

export const MuiAvatar: Components<Theme>['MuiAvatar'] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      return {
        width: 30,
        height: 30,
        ...theme.typography.h3,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.common.grey3,
        border: `1px solid ${theme.palette.common.light2}`,
      };
    },
  },
};
