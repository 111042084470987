import { Box, Icon, Stack, Switch, Typography } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { DATE_FORMAT } from '@schooly/constants';
import { EditIcon, LockIcon, RecurringIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { renderPlainError } from '../../../../components/ui/Input/helpers';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import { ModalPanel } from '../../../../components/uikit-components/Modal/Modal.styled';
import { AssessmentForm, getAssessmentFormErrorTextId } from '../AssessmentCreateContent';
import { AssessmentsDateSelect } from '../AssessmentsDateSelect';
import { AssessmentsCreateModalMode, useAssessmentCreateModal } from '../useAssessmentCreateModal';
import { useWithAssessmentFormValidation } from '../useAssessmentFormValidation';
import { AssessmentsCreateModalSettingsMethods } from './AssessmentsCreateModalSettingsMethods';

type AssessmentsCreateModalSettingsProps = {
  recurringSelect: React.ReactNode;
};

export const AssessmentsCreateModalSettings: FC<AssessmentsCreateModalSettingsProps> = ({
  recurringSelect,
}) => {
  const { $t } = useIntl();
  const { mode, autoFocus, actions } = useAssessmentCreateModal();
  const { control, watch, setValue, formState, trigger, register } =
    useFormContext<AssessmentForm>();
  const { isSubmitted } = formState;
  const errors = formState.errors;
  const { onValidateDate, groupsChecking, recurringOverlaps } = useWithAssessmentFormValidation();

  const useAssessmentNameAsDisplayName = watch('useAssessmentNameAsDisplayName');
  const methods = watch('methods');
  const name = watch('name');
  const displayName = watch('display_name');
  const assessmentDate = watch('assessment_date');
  const recurringState = watch('recurring_state');
  const displayNameError = isSubmitted && !useAssessmentNameAsDisplayName && !displayName;
  const assessmentDateError = errors.assessment_date;
  const previewDateError = isSubmitted && (!assessmentDate || recurringOverlaps);
  const previewMethodsError = isSubmitted && !methods.length;

  const isDateFieldFocused = autoFocus === 'assessmentDate';

  const isActive = mode === AssessmentsCreateModalMode.Settings;

  const handleClick = useCallback(
    (field: string) => () => {
      actions.setMode(AssessmentsCreateModalMode.Settings, field);
      trigger();
    },
    [actions, trigger],
  );

  const handleSwitchClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;

      setValue('useAssessmentNameAsDisplayName', checked);
      if (checked) {
        setValue('display_name', name);
      }
      trigger('display_name');
    },
    [name, setValue, trigger],
  );

  const handleOpenDatePicker = useCallback(() => {
    if (isDateFieldFocused) {
      actions.clearFocusField();
    }
  }, [actions, isDateFieldFocused]);

  return (
    <ModalPanel active={isActive} withBorderBottom>
      {isActive ? (
        <Stack alignItems="flex-start" gap={2}>
          <Stack position="relative" alignSelf="stretch">
            <ControlTextField
              name="display_name"
              control={control}
              label={$t({ id: 'assessments-DisplayName' })}
              autoFocus={autoFocus === 'displayName'}
              disabled={useAssessmentNameAsDisplayName}
              showEndAdornment={false}
              rules={{ required: true }}
              error={Boolean(displayNameError)}
              helperText={
                Boolean(displayNameError) &&
                renderPlainError(
                  <FormattedMessage id={getAssessmentFormErrorTextId('display_name')} />,
                )
              }
              InputProps={{ sx: (theme) => ({ paddingRight: `${theme.spacing(28)} !important` }) }}
              fullWidth
            />
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                alignItems: 'center',
                position: 'absolute',
                right: theme.spacing(1.5),
                top: theme.spacing(1.5),
                gap: 1,
              })}
            >
              {useAssessmentNameAsDisplayName && (
                <Icon color="disabled">
                  <LockIcon />
                </Icon>
              )}
              <Typography color={!useAssessmentNameAsDisplayName ? 'common.grey' : 'primary.main'}>
                {$t({ id: 'assessments-DisplayNameAsAssessmentName' })}
              </Typography>
              <Switch checked={useAssessmentNameAsDisplayName} onChange={handleSwitchClick} />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            gap={recurringSelect ? 2 : 0}
            width={'100%'}
            mb={previewDateError || assessmentDateError ? 2 : 0}
            sx={{
              '& .form-group__error': {
                pt: 0,
                pl: 0,
              },
            }}
          >
            <Box width="100%">
              <AssessmentsDateSelect
                loading={groupsChecking}
                onValidate={onValidateDate}
                forceOpened={isDateFieldFocused}
                onOpen={handleOpenDatePicker}
              />
            </Box>

            {recurringSelect && (
              <Stack width="100%" overflow="hidden">
                {recurringSelect}
              </Stack>
            )}
          </Stack>

          <AssessmentsCreateModalSettingsMethods opened={autoFocus === 'methodType'} />
        </Stack>
      ) : (
        <Stack direction="row" gap={2} sx={{ minHeight: (theme) => theme.spacing(4.5) }}>
          {!useAssessmentNameAsDisplayName && (
            <Stack sx={{ flex: '0 0 32%' }} alignItems="flex-start">
              <input type="hidden" {...register('display_name', { required: true })} />
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage id="assessments-DisplayName" />
              </Typography>
              <SimpleButton
                endIcon={<EditIcon />}
                iconsOnHover={!!displayName}
                onClick={handleClick('displayName')}
                sx={{
                  wordBreak: 'break-all',
                  color: 'common.grey2',
                  textAlign: 'start',
                }}
              >
                {displayName}
              </SimpleButton>

              {displayNameError && (
                <Typography variant="body2" color="error">
                  {$t({
                    id: getAssessmentFormErrorTextId('display_name'),
                  })}
                </Typography>
              )}
            </Stack>
          )}

          <Stack sx={{ flex: '0 0 32%' }} alignItems="flex-start">
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="assessments-AssessmentDate" />
            </Typography>

            <Stack direction="row" gap={1} alignItems="center">
              <SimpleButton
                endIcon={<EditIcon />}
                iconsOnHover={!!assessmentDate}
                onClick={handleClick('assessmentDate')}
                sx={{
                  color: 'common.grey2',
                }}
              >
                <Stack direction="row" gap={0.5}>
                  {assessmentDate && format(newDateTimezoneOffset(assessmentDate), DATE_FORMAT)}
                  {recurringState && (
                    <Icon inverse sx={{ color: 'common.grey2' }}>
                      <RecurringIcon />
                    </Icon>
                  )}
                </Stack>
              </SimpleButton>
            </Stack>

            {previewDateError && (
              <Typography variant="body2" color="error">
                {$t({
                  id: getAssessmentFormErrorTextId(
                    recurringOverlaps ? 'recurring_state' : 'assessment_date',
                  ),
                })}
              </Typography>
            )}
          </Stack>

          <Stack sx={{ flex: '0 0 32%' }}>
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="assessments-AssessmentType" />
            </Typography>
            <Stack direction="row" gap={1} alignItems="center">
              <SimpleButton
                endIcon={<EditIcon />}
                iconsOnHover={!!methods?.length}
                onClick={handleClick('methodType')}
                sx={{
                  color: 'common.grey2',
                }}
              >
                {methods
                  ?.filter((method) => method.method_type != null)
                  .map((method) => $t({ id: `assessments-Type-${method.method_type}` }))
                  .join('+')}
              </SimpleButton>
              {previewMethodsError && (
                <Typography variant="body2" color="error">
                  {$t({ id: getAssessmentFormErrorTextId('methods') })}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </ModalPanel>
  );
};
