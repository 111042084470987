import React from 'react';
import { FormattedMessage } from 'react-intl';

import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';

import './NoSearchResultsFound.scss';

const $class = `${$UIKitPrefix}NoSearchResultsFound`;

interface NoSearchResultsFoundProps {
  type?: 'regular' | 'small';
  className?: string;
}

export const NoSearchResultsFound: React.FC<NoSearchResultsFoundProps> = ({
  className,
  type = 'regular',
}) => {
  return (
    <div className={buildClassName($class, className, type === 'small' && `${$class}__small`)}>
      <picture>
        <source srcSet="/images/no-search-results.png, /images/no-search-results@2x.png 2x" />
        <img
          className="LandingLayout__image"
          src="/images/no-search-results.png"
          alt="No results matching your search found"
        />
      </picture>
      <p className="AccessDenied__subtitle">
        <FormattedMessage id="noSearchResults" />
      </p>
    </div>
  );
};
