import { Components, Theme } from '@mui/material';

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      // bootstrap/_reboot.css adds margin-bottom to labels
      marginTop: 0,
      marginBottom: 0,
    },
  },
};
