import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { GroupMembership } from '@schooly/api';
import { ArchiveIcon } from '@schooly/style';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { getCriteriaName } from '../../../helpers/misc';
import { GroupCriteriaTag } from './GroupCriteriaInfo.styled';
import { ManageGroupModalTooltip } from './ManageGroupModalTooltip';

interface CriteriaGroupInfoProps {
  membership: GroupMembership[];
}

const SHOW_TAGS_LIMIT = 2;

export const CriteriaGroupInfo: FC<CriteriaGroupInfoProps> = ({ membership }) => {
  const { $t } = useIntl();

  const criteriaNames = useMemo(
    () =>
      membership.reduce<{ name: string; element: React.ReactNode }[]>((acc, { criteria }) => {
        if (!criteria) return acc;

        const name = getCriteriaName(criteria);

        return !acc.some((item) => item.name === name)
          ? [
              ...acc,
              {
                name,
                element: criteria.school_property?.archived ? (
                  <Tooltip
                    title={$t({ id: `schoolProperty-Archived-${criteria.school_property.type}` })}
                  >
                    <Box>
                      {criteria.school_property.archived && (
                        <Typography
                          component="span"
                          sx={{ '& .svg-icon': { width: 'auto', height: '1em' } }}
                        >
                          <ArchiveIcon />
                        </Typography>
                      )}{' '}
                      {name}
                    </Box>
                  </Tooltip>
                ) : (
                  name
                ),
              },
            ]
          : acc;
      }, []),
    [$t, membership],
  );

  const [tooltipWidth, setTooltipWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setTooltipWidth(ref.current.clientWidth);
    }
  }, []);

  const renderComma = useCallback(
    (rule: boolean) => (
      <>
        {' '}
        <Typography variant="h3" display="inline">
          {rule && ','}
        </Typography>{' '}
      </>
    ),
    [],
  );

  const renderTag = useCallback(
    (criteria: { element: React.ReactNode }) => (
      <GroupCriteriaTag>
        <Typography noWrap color="primary.main">
          {criteria.element}
        </Typography>
      </GroupCriteriaTag>
    ),
    [],
  );

  const renderTagNames = useMemo(() => {
    const showRule = criteriaNames.length <= SHOW_TAGS_LIMIT;
    const criteriaNamesArr = showRule ? criteriaNames : criteriaNames.slice(0, SHOW_TAGS_LIMIT);
    const restCount = criteriaNames.length - SHOW_TAGS_LIMIT;

    if (showRule) {
      return criteriaNamesArr.map((criteria, i) => (
        <Fragment key={criteria.name}>
          {renderTag(criteria)}
          {renderComma(criteriaNames.length >= SHOW_TAGS_LIMIT && i % SHOW_TAGS_LIMIT === 0)}
        </Fragment>
      ));
    }

    return (
      <ManageGroupModalTooltip
        offset={[-8, -44]}
        width={`${tooltipWidth}px`}
        text={
          <Box>
            {criteriaNames.map((criteria, i) => (
              <Fragment key={criteria.name}>
                {renderTag(criteria)}
                {renderComma(i !== criteriaNames.length - 1)}
              </Fragment>
            ))}
          </Box>
        }
      >
        <Stack flexDirection="row" whiteSpace="nowrap" gap={0.5} alignItems="baseline">
          {criteriaNamesArr.map((criteria, i) => (
            <Fragment key={criteria.name}>
              {renderTag(criteria)}
              {renderComma(i % SHOW_TAGS_LIMIT === 0)}
            </Fragment>
          ))}{' '}
          + {restCount}
        </Stack>
      </ManageGroupModalTooltip>
    );
  }, [criteriaNames, renderComma, renderTag, tooltipWidth]);

  return <Box ref={ref}>{renderTagNames}</Box>;
};
