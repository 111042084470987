import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import buildClassName from '../../../../utils/buildClassName';
import { renderError, renderLabel } from '../helpers';
import { BaseFormInputProps, useFormInput } from '../utils';

type PickedInputProps = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'autoComplete'>;
export type PasswordInputType = 'password' | 'text';

const PasswordInput: React.FC<BaseFormInputProps & PickedInputProps> = (props) => {
  const { id, className, disabled, autoComplete } = props;

  const { inputRef, error, fullName, isTouched } = useFormInput(props);
  const [inputType, setInputType] = useState<PasswordInputType>('password');
  const {
    formState: { isSubmitted },
  } = useFormContext();

  const hideError = !isSubmitted;

  const handleEyeClick = useCallback(() => {
    setInputType((prev) => (prev === 'text' ? 'password' : 'text'));
  }, []);

  const fullClassName = buildClassName(
    'form-group',
    disabled && 'disabled',
    !!error && !hideError && 'error',
    isTouched && 'touched',
    inputType !== 'password' && 'active-eye',
    className,
  );

  return (
    <label className={fullClassName} htmlFor={id}>
      <input
        id={id}
        name={fullName}
        ref={inputRef}
        className="form-control"
        type={inputType}
        disabled={disabled}
        autoComplete={autoComplete}
      />
      {renderLabel({ ...props, type: 'password', inputType }, false, false, handleEyeClick)}
      {!hideError && renderError(error)}
    </label>
  );
};

export default PasswordInput;
