import { Button, IconButton, Tooltip } from '@mui/material';
import {
  Child,
  editFamily,
  getStudentMembership,
  NewAdult,
  NewFamilyMember,
  ProfileSearchResult,
  SchoolRelation,
  StringOrNull,
  StudentSchoolRelation,
  updatePrimaryContactId,
  UserType,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { RelationsToChild } from '@schooly/constants';
import {
  ArrowLeftIcon,
  CheckIcon,
  CrossIcon,
  InformationIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSmall,
  Spin,
} from '@schooly/style';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { getStudentGuardianPrimaryContactId, getStudentParents } from '../../../helpers/students';
import { getUserFullName } from '../../../helpers/users';
import { useCustomFields } from '../../../hooks/useCustomFields';
import useParentsEdit from '../../../hooks/useParentsEdit';
import { ModalPeopleExtensionPanel } from '../../uikit-components/Modal/ModalPeopleExtensionPanel';
import AddParentsHome from '../AddParentsModal/Home';
import AdultsExternalSidebar from '../AdultsExternalSidebar';
import { CreateAdultForm, CreateAdultModal } from '../CreateAdultModal/CreateAdultModal';
import { converCreateAdultFormToNewAdult } from '../CreateAdultModal/utils';
import { generateTempUserId, isTempUserId } from '../CreatePersonForm/utils';
import { EditFamilyContent } from './EditFamilyContent';

import '../AddParentsModal/index.scss';

interface EditFamilyModalProps {
  isOpen: boolean;
  editedStudent?: Child;
  studentMembership?: SchoolRelation;
  userType?: UserType;
  onClose: () => void;
  onParentAdd: () => void;
}

enum AddParentState {
  AddChild,
  AddParents,
  CreateAdult,
}

export interface EditFamilyForm {
  primary: StringOrNull;
  parent: NewAdult | StudentSchoolRelation | ProfileSearchResult;
  relation_type?: RelationsToChild;
}

export const EditFamilyModal: React.FC<EditFamilyModalProps> = ({
  isOpen,
  studentMembership,
  editedStudent,
  userType,
  onClose,
  onParentAdd,
}) => {
  const { schoolId } = useAuth();
  const { getConfirmation } = useConfirmationDialog();
  const [selectedStudent, setSelectedStudent] = useState<Child | StudentSchoolRelation>();
  const [fetching, setFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const { showNotification } = useNotifications();
  const { $t } = useIntl();
  const [modalState, setModalState] = useState<AddParentState>(
    editedStudent ? AddParentState.AddParents : AddParentState.AddChild,
  );
  const { profileParentCustomFields, fetching: loadingCustomFields } = useCustomFields({
    refetchOnMount: 'always',
  });

  const isAddParentModalOpen = modalState === AddParentState.AddParents;

  const currentUser = editedStudent ?? selectedStudent;

  const guardianRelations =
    !!currentUser && 'guardian_relations' in currentUser ? currentUser.guardian_relations : [];

  const { parents } = useParentsEdit(
    currentUser,
    () => {},
    () => {},
    studentMembership,
    userType,
  );

  const form = useForm<{
    parentsForm: EditFamilyForm[];
  }>({
    values: {
      parentsForm:
        parents.map((p) => {
          const guardian = guardianRelations.find(
            (g) =>
              'relation_id' in g.guardian &&
              'relation_id' in p &&
              g.guardian.relation_id === p.relation_id,
          );

          return {
            parent: p,
            primary: guardian?.primary ? guardian.guardian.user_id : null,
            relation_type:
              !!guardian && typeof guardian.relation_type === 'number'
                ? guardian.relation_type
                : undefined,
          };
        }) ?? [],
    },
  });

  const parentsForm = form.watch('parentsForm');

  const resetState = useCallback(() => {
    setSelectedStudent(undefined);
    setModalState(AddParentState.AddParents);
    form.reset();
  }, [form]);

  const updateFamily = useCallback<SubmitHandler<{ parentsForm: EditFamilyForm[] }>>(
    async ({ parentsForm }) => {
      if (!schoolId || !form.getValues() || !currentUser) {
        return;
      }

      setSaving(true);

      try {
        const prevParentsLength = getStudentParents(currentUser)?.length;
        const prevPrimaryContactId = getStudentGuardianPrimaryContactId(currentUser);
        const primaryAdultIndex = parentsForm.findIndex((f) => Boolean(f.primary));
        const family: NewFamilyMember[] = [];

        parentsForm.forEach((form) => {
          const isNewParent = isTempUserId(form.parent.user_id);
          if (isNewParent) {
            const { user_id, ...rest } = form.parent;
            // TODO figure out with the NewFamilyMember type
            family.push({
              ...rest,
              relation_type: form.relation_type,
            } as unknown as NewFamilyMember);
          } else if (form.parent.user_id) {
            // TODO figure out with the NewFamilyMember type
            family.push({
              user_id: form.parent.user_id,
              relation_type: form.relation_type,
            } as unknown as NewFamilyMember);
          }
        });

        const { adults } = await editFamily(schoolId, currentUser.user_id, family);

        const primaryId = adults[primaryAdultIndex];

        const isPrimaryContactChanged = primaryId && primaryId !== prevPrimaryContactId;
        const relationId =
          studentMembership?.relation_id ?? (currentUser as StudentSchoolRelation)?.relation_id;

        if (primaryId && isPrimaryContactChanged && relationId) {
          await updatePrimaryContactId(relationId, primaryId);
        }

        const userName = getUserFullName(currentUser);
        onParentAdd();
        resetState();
        onClose();

        if (parents.length !== prevParentsLength && isPrimaryContactChanged) {
          showNotification({
            textId: 'confirmation-AddParentsAndChangePrimaryContact',
            values: { userName },
            type: 'success',
          });
        } else if (isPrimaryContactChanged) {
          showNotification({
            textId: 'confirmation-ChangePrimaryContact',
            values: { userName },
            type: 'success',
          });
        } else {
          showNotification({
            textId: 'confirmation-AddParents',
            values: { userName },
            type: 'success',
          });
        }
      } catch (error) {
      } finally {
        setSaving(false);
      }
    },
    [
      currentUser,
      form,
      onClose,
      onParentAdd,
      parents.length,
      resetState,
      schoolId,
      showNotification,
      studentMembership,
    ],
  );

  const { update, remove, fields, append } = useFieldArray({
    control: form.control,
    name: 'parentsForm',
  });

  const selectedParentIds = useMemo(() => parentsForm.map((p) => p.parent.user_id), [parentsForm]);
  const withoutPrimaryContact = parentsForm.every((p) => !p.primary);

  const handleClose = useCallback(async () => {
    if (form.formState.isDirty) {
      if (
        modalState === AddParentState.CreateAdult ||
        (modalState === AddParentState.AddParents && selectedStudent)
      ) {
        const isConfirmed = await getConfirmation({
          textId: selectedStudent ? 'people-CreateFamilyWarning' : 'people-CreatePersonWarning',
        });

        if (!isConfirmed) {
          return;
        }

        if (modalState === AddParentState.CreateAdult) {
          resetState();
          return;
        }
      }

      resetState();
      onClose();
    } else {
      resetState();
      onClose();
    }
  }, [form.formState.isDirty, getConfirmation, modalState, onClose, resetState, selectedStudent]);

  const onGoBack = useCallback(async () => {
    if (form.formState.isDirty) {
      const isConfirmed = await getConfirmation({
        textId: 'people-CreateFamilyWarning',
      });

      if (!isConfirmed) {
        return;
      }

      setModalState(AddParentState.AddChild);
    } else {
      setModalState(AddParentState.AddChild);
    }
  }, [form.formState.isDirty, getConfirmation]);

  const handleUserSelect = useCallback(
    async (user: SchoolRelation) => {
      if (!schoolId) {
        return;
      }
      setFetching(true);
      setModalState(AddParentState.AddParents);
      const student = await getStudentMembership(schoolId, user.relation_id);
      setSelectedStudent(student);
      setFetching(false);
    },
    [schoolId],
  );

  const handleCreateParent = useCallback(() => {
    setModalState(AddParentState.CreateAdult);
  }, []);

  const handleSaveParent = useCallback(
    (profileData: NewAdult | ProfileSearchResult) => {
      if (!profileData || !schoolId) {
        return;
      }

      const userId =
        'user_id' in profileData && profileData.user_id
          ? profileData.user_id
          : generateTempUserId();

      const parent: EditFamilyForm = {
        parent: { ...profileData, user_id: userId },
        primary: (withoutPrimaryContact && userId) || null,
        relation_type: undefined,
      };

      append(parent);
      setModalState(AddParentState.AddParents);
    },
    [append, schoolId, withoutPrimaryContact],
  );

  const handleParentDelete = useCallback(
    (index: number, isPrimary: boolean) => {
      if (parentsForm.length <= 1 || !isPrimary) {
        remove(index);
        return;
      }

      const firstParentData = index === 0 ? parentsForm[1] : parentsForm[0];

      if (!firstParentData || !firstParentData.parent.user_id) {
        return;
      }

      if (index === 0) {
        update(1, { ...firstParentData, primary: firstParentData.parent.user_id });
      } else {
        update(0, { ...firstParentData, primary: firstParentData.parent.user_id });
      }

      remove(index);
    },
    [update, remove, parentsForm],
  );

  const handleCreateAdult = useCallback(
    (a: CreateAdultForm) => {
      handleSaveParent(converCreateAdultFormToNewAdult(a));
      setModalState(AddParentState.AddParents);
    },
    [handleSaveParent],
  );

  const handleCloseCreateAdultModal = useCallback(() => {
    setModalState(AddParentState.AddParents);
  }, []);

  const handlePrimaryContactChange = useCallback(
    (index: number) => {
      parentsForm.forEach((p, idx) => {
        if (p.parent.user_id) {
          update(idx, { ...p, primary: idx === index ? p.parent.user_id : null });
        }
      });
    },
    [update, parentsForm],
  );

  function renderContent() {
    switch (modalState) {
      case AddParentState.AddParents:
        return saving ? (
          <Loading />
        ) : (
          <EditFamilyContent
            user={currentUser}
            parentForm={fields}
            isLoading={fetching}
            onPrimaryContactIdChange={handlePrimaryContactChange}
            onParentDelete={handleParentDelete}
          />
        );
      default:
        return <AddParentsHome onUserSelect={handleUserSelect} />;
    }
  }

  const title = useMemo(() => {
    if (isAddParentModalOpen && currentUser) {
      return getUserFullName(currentUser);
    }
    if (modalState === AddParentState.CreateAdult) {
      return $t({ id: 'migration-CreateNewAdult' });
    }

    return $t({ id: 'parents-SelectStudent' });
  }, [$t, currentUser, isAddParentModalOpen, modalState]);

  return (
    <>
      <ModalSmall open={isOpen}>
        {loadingCustomFields ? (
          <Loading />
        ) : (
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(updateFamily)}>
              <ModalHeader active title={title}>
                <IconButton onClick={handleClose}>
                  <CrossIcon />
                </IconButton>
              </ModalHeader>
              <ModalPeopleExtensionPanel
                titleId={isAddParentModalOpen ? 'userType-parentsGuardians' : ''}
                addActionId="groups-AddStudents"
                editActionId="groups-EditStudents"
                count={0}
                active
                testId="groups-modal-edit-students"
                headerMode={!isAddParentModalOpen || fetching || saving}
                infoNode={
                  isAddParentModalOpen &&
                  !fetching &&
                  !(currentUser as StudentSchoolRelation)?.can_be_edited ? (
                    <Tooltip
                      title={<FormattedMessage id="parents-NoEditAccessHint" />}
                      arrow
                      disableInteractive
                    >
                      <IconButton inverse>
                        <InformationIcon />
                      </IconButton>
                    </Tooltip>
                  ) : undefined
                }
                sidebarContent={
                  (currentUser as StudentSchoolRelation)?.can_be_edited ? (
                    <AdultsExternalSidebar
                      childProfile={currentUser}
                      selectedUserIds={selectedParentIds}
                      onUserSelect={handleSaveParent}
                      onUserCreate={handleCreateParent}
                    />
                  ) : undefined
                }
              >
                <ModalContent sx={{ pt: 0 }} active>
                  {renderContent()}
                </ModalContent>
              </ModalPeopleExtensionPanel>

              {isAddParentModalOpen && (
                <ModalFooter
                  active
                  sx={{
                    justifyContent:
                      selectedStudent && isAddParentModalOpen ? 'space-between' : 'flex-end',
                  }}
                >
                  {selectedStudent && isAddParentModalOpen && (
                    <Button
                      startIcon={<ArrowLeftIcon />}
                      variant="outlined"
                      disabled={fetching || saving}
                      onClick={onGoBack}
                    >
                      <FormattedMessage id="action-Back" />
                    </Button>
                  )}

                  <Button
                    disabled={fetching || saving}
                    endIcon={saving ? <Spin /> : <CheckIcon />}
                    type="submit"
                  >
                    <FormattedMessage id="action-Save" />
                  </Button>
                </ModalFooter>
              )}
            </form>
          </FormProvider>
        )}
      </ModalSmall>
      {modalState === AddParentState.CreateAdult && (
        <CreateAdultModal
          open
          onClose={handleCloseCreateAdultModal}
          isSaving={false}
          onCreateAdult={handleCreateAdult}
          formCustomFields={profileParentCustomFields}
        />
      )}
    </>
  );
};
