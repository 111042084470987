import { Stack, Typography } from '@mui/material';
import { Group, GroupType } from '@schooly/api';
import { Loading } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupCard } from '../../../components/common/GroupCard/GroupCard';
import HeaderFilter from '../../../components/common/HeaderFilter/HeaderFilter';
import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { ModalPanel } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';

interface ManageGroupModalRightSidebarProps {
  onAddGroup: (group: Group) => void;
  currentYear: string;
  relatedGroups: Group[];
  isFetching: boolean;
  filterApplied?: boolean;
}

export const ManageGroupModalRightSidebar: FC<ManageGroupModalRightSidebarProps> = ({
  onAddGroup,
  currentYear,
  relatedGroups,
  isFetching,
  filterApplied,
}) => {
  const { $t } = useIntl();

  const renderList = () => {
    if (isFetching) {
      return (
        <div className="pt-3">
          <Loading />
        </div>
      );
    }

    return relatedGroups?.length ? (
      <Stack gap={1}>
        {relatedGroups.map((group) => {
          return (
            <GroupCard
              key={group.id}
              title={group.name}
              subjectName={
                group.group_type === GroupType.TutorGroup
                  ? $t({ id: 'groups-TutorGroup' })
                  : group.subject?.name
              }
              subjectArchived={group.subject?.archived}
              onSelect={() => onAddGroup(group)}
              selectable
              withBorder
              id={group.id}
            />
          );
        })}
      </Stack>
    ) : (
      <NoSearchResultsFound type="small" />
    );
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <ModalHeader
        title={
          <Stack flexDirection="row" gap={1}>
            <Typography variant="h1">
              <FormattedMessage id="profile-Groups" />
            </Typography>
            <Typography variant="h1" color="common.grey">
              {currentYear}
            </Typography>
          </Stack>
        }
        active
      />
      <ModalPanel sx={{ pt: 1, pb: 0 }} active>
        <HeaderFilter
          searchPlaceholder={$t(
            { id: 'groups-SearchAmongCount' },
            {
              GroupPlural: `${relatedGroups?.length || 0} ${$t({
                id: 'assessments-Groups',
              }).toLowerCase()}`,
            },
          )}
          modalTitleTextId="groups-Filter-Title"
          modal
          bottomInline
          isModalOpen
        />
      </ModalPanel>
      <ModalPanel sx={{ pt: filterApplied ? 0 : 2.5, flex: '1 1 auto', overflowY: 'auto' }} active>
        {renderList()}
      </ModalPanel>
    </Stack>
  );
};
