import {
  Box,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  tableBodyClasses,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';
import React, { FC, PropsWithChildren } from 'react';

const stickyCellStyle = { position: 'sticky', left: 0, zIndex: 2 };

// set z-index incremented for `<th>` to keep both header and first column sticky
const stickyHeaderCellStyle = { ...stickyCellStyle, zIndex: 3 };

export interface GridProps {
  fixedLayout?: boolean;
}

export const Grid = styled(Table, {
  shouldForwardProp: (prop) => prop !== 'fixedLayout',
})<GridProps>(({ fixedLayout }) => ({
  borderCollapse: 'separate',
  tableLayout: fixedLayout ? 'fixed' : undefined,
}));

export const GridBody = styled(TableBody)(({ theme }) => ({
  [`&.${tableBodyClasses.root}`]: {
    [`& .${tableRowClasses.root}:not(:last-child)`]: {
      [`& .${tableCellClasses.root}`]: {
        borderBottom: theme.mixins.borderValue(),
      },
    },
  },
}));

export const GridFooter = styled(TableFooter)();

export interface GridBorderProps {
  borderLeft?: boolean;
  borderRight?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
}

export const GridHead = styled(TableHead, {
  shouldForwardProp: (prop) => !(['borderBottom'] as PropertyKey[]).includes(prop),
})<Pick<GridBorderProps, 'borderBottom'>>(({ theme, borderBottom }) => ({
  [`& .${tableCellClasses.root}.${tableCellClasses.root}`]: {
    borderBottom: borderBottom ? theme.mixins.borderValue() : 'none',
    borderBottomColor: theme.palette.common.light2,
  },
}));

export const GridPagination = styled(TablePagination)();
export const GridSortLabel = styled(TableSortLabel)();

export interface GridRowProps {
  error?: boolean;
  withoutDefaultHover?: boolean;
}

export interface GridCellProps extends GridBorderProps {
  empty?: boolean;
  sticky?: boolean;
  clean?: boolean;
  hover?: boolean;
  noPadding?: boolean;
  noVerticalPadding?: boolean;
  noHorizontalPadding?: boolean;
  sortable?: boolean;
  px?: number;
  py?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  p?: number;
  center?: boolean;
}

export interface GridCellContentProps {
  size?: 'normal' | 'small';
}

export const GridWrapper = styled(Paper)({
  backgroundColor: 'unset',
});

GridWrapper.defaultProps = {
  elevation: 0,
};

export const GridContainer: FC<PropsWithChildren<TableContainerProps>> = React.forwardRef(
  ({ children, ...props }, ref) => (
    <TableContainer component={GridWrapper} ref={ref} {...props}>
      {children}
    </TableContainer>
  ),
);

export const GridRow = styled(TableRow, {
  shouldForwardProp: (prop) => !(['error', 'withoutDefaultHover'] as PropertyKey[]).includes(prop),
})<GridRowProps>(({ theme, error, withoutDefaultHover }) => ({
  '&&:hover td': {
    backgroundColor: (() => {
      if (error) {
        return theme.palette.error.light;
      }

      if (withoutDefaultHover) {
        return undefined;
      }

      return theme.palette.background.default;
    })(),
  },

  '&& td': {
    backgroundColor: error ? theme.palette.error.superLight : 'auto',
  },
}));

GridRow.defaultProps = {
  className: 'GridRow',
};

export const GridCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    !(
      [
        'empty',
        'sticky',
        'clean',
        'hover',
        'borderLeft',
        'borderRight',
        'borderTop',
        'borderBottom',
        'noPadding',
        'noVerticalPadding',
        'noHorizontalPadding',
        'sortable',
        'px',
        'py',
        'pl',
        'pr',
        'pt',
        'pb',
        'p',
        'center',
      ] as PropertyKey[]
    ).includes(prop),
})<GridCellProps>(
  ({
    theme,
    empty,
    sticky,
    clean,
    hover,
    borderLeft,
    borderRight,
    borderTop,
    borderBottom,
    noPadding,
    noVerticalPadding,
    noHorizontalPadding,
    sortable,
    sortDirection,
    px,
    py,
    pl,
    pr,
    pt,
    pb,
    p,
    center,
  }) => {
    return {
      [`&.${tableCellClasses.root}`]: {
        ...(sticky && stickyCellStyle),
        ...theme.typography.h3,
        background: hover ? theme.palette.background.default : theme.palette.background.paper,
        lineHeight: 0,
        backgroundImage: empty
          ? `repeating-linear-gradient(135deg, transparent ${theme.spacing(0, 1)}, ${
              theme.palette.common.light3
            }  ${theme.spacing(1, '9px')} )`
          : undefined,
        padding: noPadding
          ? 0
          : theme.spacing(
              noVerticalPadding ? 0 : pt ?? py ?? p ?? 2,
              noHorizontalPadding ? 0 : pr ?? px ?? p ?? 1,
              noVerticalPadding ? 0 : pb ?? py ?? p ?? 2,
              noHorizontalPadding ? 0 : pl ?? px ?? p ?? 1,
            ),
        borderLeft: borderLeft ? theme.mixins.borderValue() : 'none',
        borderRight: borderRight ? theme.mixins.borderValue() : 'none',
        borderTop: borderTop ? theme.mixins.borderValue() : 'none',
        borderBottom: borderBottom ? theme.mixins.borderValue() : 'none',

        ...(center && { textAlign: 'center' }),
      },

      [`&.${tableCellClasses.head}`]: {
        ...(sticky && stickyHeaderCellStyle),
        ...theme.typography.headerCell,
        borderTopColor: theme.palette.common.light3,
        borderBottomColor: theme.palette.common.light3,
        lineHeight: 1,
        padding: noPadding
          ? 0
          : theme.spacing(
              noVerticalPadding ? 0 : pt ?? py ?? p ?? 2,
              noHorizontalPadding ? 0 : pr ?? px ?? p ?? 1,
              noVerticalPadding ? 0 : pb ?? py ?? p ?? 2,
              noHorizontalPadding ? 0 : pl ?? px ?? p ?? 1,
            ),

        ...(sortable && {
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.text.primary,
          },
        }),
      },

      [`&.${tableCellClasses.root}:not(:last-child)`]: {
        // borderRight: clean ? 'none' : theme.mixins.borderValue(),
      },

      [`&.${tableCellClasses.stickyHeader}`]: {
        background: theme.palette.background.paper,
      },
    };
  },
);

// GridCell.defaultProps = {
//   size: 'small',
//   padding: 'none',
// };

export interface GridRowStyledProps {
  noBorder?: boolean;
  noBorderRadius?: boolean;
}

export const GridRowStyled = styled(Box, {
  shouldForwardProp: (prop) => !(['noBorder', 'noBorderRadius'] as PropertyKey[]).includes(prop),
})<GridRowStyledProps>(({ theme, noBorder, noBorderRadius }) => ({
  backgroundColor: theme.palette.background.paper,

  '&:first-child': noBorderRadius
    ? undefined
    : {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },

  '&:last-child': noBorderRadius
    ? undefined
    : {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },

  '&:not(:last-child)': {
    borderBottom: noBorder ? undefined : theme.mixins.borderValue(),
  },

  '&:hover': {
    backgroundColor: theme.palette.background.default,

    GridRowName: {
      color: theme.palette.text.primary,
    },
  },

  '& svg': {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    flex: theme.spacing(0, 0, 2.5),
  },
}));

export interface GridRowItemProps {
  noPadding?: boolean;
  noVerticalPadding?: boolean;
  noHorizontalPadding?: boolean;
}

export const GridRowItem = styled(Stack, {
  shouldForwardProp: (prop) =>
    !(['noPadding', 'noVerticalPadding', 'noHorizontalPadding'] as PropertyKey[]).includes(prop),
})<GridRowItemProps>(({ theme, noPadding, noVerticalPadding, noHorizontalPadding }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: noPadding
    ? theme.spacing(0)
    : theme.spacing(noVerticalPadding ? 0 : 1, noHorizontalPadding ? 0 : 1),
}));

GridRowItem.defaultProps = {
  className: 'GridRowItem',
};

export const GridCellContent = styled(Typography, {
  shouldForwardProp: (prop) => !(['size'] as PropertyKey[]).includes(prop),
})<GridCellContentProps>(({ size }) => ({
  ...(size === 'small' && { lineHeight: 0, display: 'inline' }),
}));

export const GridRowDate = styled(GridCellContent)(({ theme }) => ({
  flex: '0 0 60px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',

  '.GridRow:hover &, .GridRowItem:hover &': {
    color: theme.palette.primary.main,
  },
}));

GridRowDate.defaultProps = {
  component: 'div',
  variant: 'h4',
  color: 'text.secondary',
} as typeof GridRowDate['defaultProps'];

export const GridRowCell = styled(GridCellContent)(({ theme }) => ({
  '.GridRow:hover &, .GridRowItem:hover &': {
    color: theme.palette.primary.main,
  },
}));

GridRowCell.defaultProps = {
  component: 'div',
  variant: 'h3',
  color: 'text.primary',
} as typeof GridRowCell['defaultProps'];

export const GridRowName = styled(GridRowCell)(({ theme }) => ({
  flex: '1 1 100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',

  '& .MuiTypography-root': {
    color: theme.palette.text.primary,

    '.GridRow:hover &, .GridRowItem:hover &': {
      color: theme.palette.primary.main,
    },
  },
}));

export const GridHeaderCellContent = styled(GridCellContent)(() => ({}));

GridHeaderCellContent.defaultProps = {
  variant: 'body1',
  color: 'text.primary',
};
