import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33911 2.68732C7.55787 2.06234 8.44174 2.06234 8.6605 2.68732L9.77974 5.88486L13.175 5.95357C13.8398 5.96702 14.1131 6.81273 13.5819 7.21265L11.1593 9.03651C10.9915 9.16288 10.9205 9.3807 10.9818 9.58167L11.864 12.4775C12.0573 13.112 11.3421 13.6344 10.7965 13.2574L7.9998 11.3249L5.20316 13.2574C4.65753 13.6344 3.94231 13.112 4.13559 12.4775L5.1198 9.24696L2.41775 7.21265C1.88655 6.81273 2.15983 5.96702 2.82461 5.95357L6.21987 5.88486L7.33911 2.68732Z"
      fill="#5D6688"
    />
  </svg>
);
