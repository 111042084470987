import { IconButton, Stack } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { DeleteIcon, DragIcon, theme } from '@schooly/style';
import React, { FC } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FieldPath, FieldPathValue, useFormContext, Validate } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { AgeGroupCreate, SchoolTuneAgeGroupsForm } from './SchoolTuneAgeGroupsModalContent';

interface SchoolTuneAgeGroupRowProps {
  provided: DraggableProvided;
  shouldFocus: boolean;
  ageGroupIndex: number;
  validateAgeGroup: (
    id: string,
  ) => Validate<
    FieldPathValue<SchoolTuneAgeGroupsForm, FieldPath<SchoolTuneAgeGroupsForm>>,
    SchoolTuneAgeGroupsForm
  >;
  getAllAgeGroups: () => AgeGroupCreate[];
  handleDeleteAgeGroup: (index: number) => void;
}

export const SchoolTuneAgeGroupRow: FC<SchoolTuneAgeGroupRowProps> = ({
  provided,
  shouldFocus,
  ageGroupIndex,
  validateAgeGroup,
  getAllAgeGroups,
  handleDeleteAgeGroup,
}) => {
  const { $t } = useIntl();

  const { control, watch } = useFormContext<SchoolTuneAgeGroupsForm>();

  const ageGroupsWithNoLevel = watch('ageGroupsWithNoLevel');
  const ageGroup = ageGroupsWithNoLevel[ageGroupIndex];

  return (
    <Stack
      ref={provided.innerRef}
      {...provided.draggableProps}
      direction="row"
      alignItems="flex-start"
      gap={1}
      sx={{
        width: '100%',
        '& > .MuiButtonBase-root': { mt: theme.spacing(1.5) },
      }}
      style={provided.draggableProps.style}
    >
      <IconButton inverse {...provided.dragHandleProps}>
        <DragIcon />
      </IconButton>

      <ControlTextField
        solid
        name={`ageGroupsWithNoLevel.${ageGroupIndex}.name`}
        control={control}
        rules={{
          required: true,
          validate: validateAgeGroup(ageGroup.id || ageGroup.id_tmp || ''),
        }}
        label={$t({ id: 'school-tabs-AgeGroups-AgeGroupName' })}
        autoFocus={
          shouldFocus && ageGroupIndex === ageGroupsWithNoLevel.length - 1 && !ageGroup.name
        }
        fullWidth
        canClear
      />
      {getAllAgeGroups().length > 1 && (
        <IconButton inverse onClick={() => handleDeleteAgeGroup(ageGroupIndex)}>
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
};
