import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { WithProfile, WithProfileProps } from './WithProfile';

export const WithProfileWrapper: FC<WithProfileProps> = (props) => {
  const { id } = useParams<'id'>();

  return <WithProfile key={id} {...props} />;
};
