import { Tooltip } from '@mui/material';
import { FilterKeys, FilterValue } from '@schooly/api';
import { DATE_FORMAT_MOMENT as DATE_FORMAT } from '@schooly/constants';
import { ArchiveIcon, CalendarIcon, Spin, StaffIcon } from '@schooly/style';
import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AppliedGroupFilters } from '../../../context/filters/scheme';
import { useFilters } from '../../../context/filters/useFilters';
import Tag from '../../ui/Tag';
import { FilterReportTags } from '../../uikit-components/FilterReportTags/FilterReportTags';
import { FilterStaffTags } from '../../uikit-components/FilterSchoolRelationTags/FilterStaffTags';
import { FilterStudentTags } from '../../uikit-components/FilterSchoolRelationTags/FilterStudentTags';

export interface FilterFullTagProps {
  filters: AppliedGroupFilters[];
  className?: string;
  onTagClick?: (key?: FilterKeys) => void;
}

export const FilterFullTags: FC<FilterFullTagProps> = ({ filters, className, onTagClick }) => {
  const { $t } = useIntl();

  const {
    filters: { appliedExclude },
    getGroupFilterLabelByValue,
    appliedArrangeByOption,
  } = useFilters();

  const handleTagClick = useCallback((key?: FilterKeys) => () => onTagClick?.(key), [onTagClick]);

  const renderTag = useCallback(
    ({ key, values }: AppliedGroupFilters, data: AppliedGroupFilters[]) => {
      const prepareTag = (value: FilterValue, addPlus?: boolean, isStaff?: boolean) => {
        const option = getGroupFilterLabelByValue(key, value);

        const label = (
          <>
            {isStaff && <StaffIcon className="user-type-icon mr-1" />}{' '}
            {option?.labelTextId ? (
              <FormattedMessage id={option?.labelTextId} />
            ) : (
              option?.label ?? <Spin />
            )}
          </>
        );

        return (
          <React.Fragment key={`${key}-${isStaff ? 'staff' : 'student'}-${value}`}>
            {addPlus && <span className="plus-separator">+</span>}
            <Tag isBig onClick={handleTagClick(key)}>
              {option?.archived ? (
                <Tooltip title={$t({ id: `schoolProperty-Archived-${key}` })}>
                  <span>
                    <ArchiveIcon className="mr-1" />
                    {label}
                  </span>
                </Tooltip>
              ) : (
                label
              )}
            </Tag>
          </React.Fragment>
        );
      };

      switch (key) {
        case FilterKeys.Report:
          return <FilterReportTags onClick={handleTagClick(key)} values={values} />;
        case FilterKeys.Date:
          return (
            <Tag className="Tag__date" isBig onClick={handleTagClick(key)}>
              <CalendarIcon className="user-type-icon mr-1" />
              {!values.student?.[0] ? (
                <FormattedMessage id="datepicker-Today" />
              ) : (
                moment(values.student?.[0]).format(DATE_FORMAT)
              )}
            </Tag>
          );
        case FilterKeys.Staff:
        case FilterKeys.Creator:
          return <FilterStaffTags onClick={handleTagClick(key)} values={values} />;
        case FilterKeys.Student:
          return [<FilterStudentTags onClick={handleTagClick(key)} values={values} />];
        case FilterKeys.Subject: {
          const isOnlyTutorGroup = data.some((filter) => filter.key === FilterKeys.OnlyTutorGroups);
          return isOnlyTutorGroup
            ? []
            : values.student?.map((value, valueIndex) => prepareTag(value, valueIndex > 0)) ?? [];
        }
        case FilterKeys.OnlyTutorGroups:
          return [
            <Tag isBig onClick={handleTagClick(key)}>
              <FormattedMessage id="groups-OnlyTutorGroups" />
            </Tag>,
          ];
        default: {
          const tags = [
            ...(values.student?.map((value, valueIndex) => prepareTag(value, valueIndex > 0)) ??
              []),
          ];

          values.staff?.forEach((value) => {
            tags.push(prepareTag(value, tags.length > 0, true));
          });

          return tags;
        }
      }
    },
    [$t, getGroupFilterLabelByValue, handleTagClick],
  );

  return (
    <>
      {(filters.length || appliedArrangeByOption?.option) && appliedExclude && (
        <div className="HeaderFilter__bottom-not-in">
          <FormattedMessage id="filter-NotIn" />
        </div>
      )}

      {filters.map((filter, filterIndex, data) => (
        <React.Fragment key={filter.key}>
          {filterIndex > 0 && <span className="comma-separator">,</span>}
          {renderTag(filter, data)}
        </React.Fragment>
      ))}

      {appliedArrangeByOption?.option && (
        <div className="arrange-by-tag">
          <span className="mr-1">
            <FormattedMessage id="filter-ArrangeBy" />:
          </span>
          {appliedArrangeByOption.section === 'staff' && (
            <StaffIcon className="user-type-icon mr-1" />
          )}
          <span>{appliedArrangeByOption.option.label}</span>
        </div>
      )}
    </>
  );
};
