import { combineReducers } from '@reduxjs/toolkit';

import listSlice, { AssessmentsListState } from './listSlice';
import previewSlice, { AssessmentsPreviewState } from './previewSlice';

export interface AssessmentsState {
  list: AssessmentsListState;
  preview: AssessmentsPreviewState;
}

const reducer = combineReducers<AssessmentsState>({
  list: listSlice.reducer,
  preview: previewSlice.reducer,
});

export default reducer;
