import {
  Backdrop,
  Box,
  Button,
  ClickAwayListener,
  Icon,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFlag } from '@schooly/hooks/use-flag';
import { MouseEvent, useCallback, useMemo } from 'react';

import { CalendarIcon, CheckIcon, CrossCircleIcon } from '../../assets/assets';

export interface DropdownYearsProps<T> {
  onYearChange: (v: T) => void;
  currentYear?: T;
  years: T[];
  defaultYear?: T;
  disabled?: boolean;
  color?: string;
}

export const DropdownYears = <T extends { name: string; id: string }>({
  onYearChange,
  currentYear,
  defaultYear,
  years,
  disabled,
  color,
}: DropdownYearsProps<T>) => {
  const [opened, open, close] = useFlag();

  const handleChange = (year: T) => {
    onYearChange(year);
    close();
  };

  const handleClear = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.stopPropagation();
      if (!defaultYear) return;
      onYearChange(defaultYear);
      close();
    },
    [close, defaultYear, onYearChange],
  );

  const currentColor = color ?? 'text.primary';

  const label = useMemo(
    () => (
      <Typography
        variant="h3"
        display="flex"
        color={opened ? 'background.paper' : currentColor}
        gap={1}
        onClick={!disabled ? open : undefined}
      >
        <Icon>
          <CalendarIcon />
        </Icon>
        {currentYear?.name}
      </Typography>
    ),
    [currentColor, currentYear?.name, disabled, open, opened],
  );

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 121,
                  maxWidth: 121,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0.25, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 0.5,
                  flexWrap: 'wrap',
                  mt: 1,
                  mb: 0.75,
                  px: 1,
                }}
              >
                {years.map((year) => {
                  const isSelected = currentYear?.id === year.id;
                  return (
                    <MenuItem
                      key={year.id}
                      onClick={() => handleChange(year)}
                      sx={(theme) => ({
                        m: 0,
                        py: theme.spacing(0.25),
                        pl: 0.25,
                        pr: 0.5,
                        height: 24,
                        borderRadius: theme.spacing(0.5),
                        width: '100%',
                        '&:hover': {
                          '.MuiTypography-root': {
                            color: (theme) => theme.palette.primary.main,
                          },
                        },
                      })}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Box ml={0.25}>
                          <Typography
                            variant="h3"
                            color={isSelected ? 'primary.main' : currentColor}
                          >
                            {year.name}
                          </Typography>
                        </Box>
                        {isSelected && (
                          <Icon
                            sx={{
                              color: 'primary.main',
                            }}
                          >
                            <CheckIcon />
                          </Icon>
                        )}
                      </Stack>
                    </MenuItem>
                  );
                })}
              </Stack>
            }
          >
            <Stack
              sx={{
                zIndex: (theme) => (opened ? theme.zIndex.drawer + 2 : undefined),
                cursor: !disabled ? 'pointer' : undefined,
                '&:hover': {
                  '.MuiTypography-root': {
                    color: (theme) =>
                      !opened && !disabled ? theme.palette.primary.main : 'inherit',
                  },
                },
              }}
              height={28}
              direction="row"
              alignItems="center"
              position="relative"
            >
              {opened ? (
                <Button
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    close();
                  }}
                  sx={(theme) => ({
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                    },
                    '&.MuiButton-root': {
                      padding: theme.spacing(0.25, 1, 0.25, 0.75),
                    },
                  })}
                  endIcon={
                    <IconButton
                      sx={(theme) => ({
                        color: theme.palette.background.paper,
                        width: theme.spacing(1.5),
                      })}
                      onClick={handleClear}
                    >
                      <CrossCircleIcon />
                    </IconButton>
                  }
                >
                  {label}
                </Button>
              ) : (
                label
              )}
            </Stack>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};
