import { SxProps, TableCellProps } from '@mui/material';
import { createContext } from 'react';

export interface HoverShowProps {
  onlyXHover?: boolean;
  disableHover?: boolean;
  multiRowHover?: boolean;
}

export interface HoverProps extends HoverShowProps {
  cellId: string;
  colIndex: number;
  rowIndex: number;
}

export type TableRowHoverProps = TableCellProps & Partial<Record<'data-hover-id', string>>;

export interface TableHoverContextProps {
  gridRef: React.MutableRefObject<HTMLTableSectionElement | null> | null;
  columnRef: React.MutableRefObject<HTMLTableRowElement | null> | null;
  getRowHoverProps: (props: HoverProps) => TableRowHoverProps;

  getColumnHoverProps: (props: HoverProps) => TableRowHoverProps;

  styles: SxProps;
}

export const TableHoverContext = createContext<TableHoverContextProps>({
  gridRef: null,
  columnRef: null,
  getRowHoverProps: () => ({}),
  getColumnHoverProps: () => ({}),
  styles: {},
});
