import {
  Event,
  GetEventsQueryFilters,
  GetEventsQuerySort,
  SORT_DIRECTION,
  useGetEventsQuery,
} from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { SkeletonRows } from '@schooly/style';
import { SelectedIds } from '@schooly/utils/bulk-actions';
import { FC, useEffect, useMemo } from 'react';

import { normalizeEvent } from '../../context/events/WithEvent';
import { EventRow, EventsHeader } from './EventsGrid';

interface EventsChartGridProps {
  schoolId: string;
  filters: Partial<GetEventsQueryFilters>;
  sort?: GetEventsQuerySort;
  onChangeSort?: (v: GetEventsQuerySort) => void;
  onSetTotalCount: (v?: number) => void;
  onToggleSelectId: (v: string) => void;
  onToggleSelectAll: () => void;
  isSelectedAll: boolean;
  selectedIds?: SelectedIds;
}

const PAGE_SIZE = 30;

export const EventsChartGrid: FC<EventsChartGridProps> = ({
  sort,
  filters,
  schoolId,
  onChangeSort,
  onSetTotalCount,
  onToggleSelectId,
  onToggleSelectAll,
  isSelectedAll,
  selectedIds,
}) => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, setParams } =
    useGetEventsQuery(
      {
        schoolId,
        pageSize: PAGE_SIZE,
        sort: { columnTextId: 'title', direction: SORT_DIRECTION.ASC },
        filters,
      },
      { refetchOnMount: 'always' },
    );

  useEffect(() => {
    onSetTotalCount(data?.pages[0].count);
  }, [data?.pages, onSetTotalCount]);

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage);

  const events = useMemo(
    () =>
      data?.pages.reduce<Event[]>(
        (prev, curr) => [...prev, ...curr.results.map((e) => normalizeEvent(e))],
        [],
      ) ?? [],
    [data?.pages],
  );

  const total = data?.pages[0]?.count;

  useEffect(() => {
    setParams((params) => ({ ...params, filters, sort }));
  }, [sort, filters, setParams]);

  return (
    <>
      <EventsHeader sort={sort} onChangeSort={onChangeSort} onSelectAll={onToggleSelectAll} />

      {events?.map((event) => (
        <EventRow
          event={event}
          key={event.id}
          onSelect={onToggleSelectId}
          isSelected={
            isSelectedAll ||
            (selectedIds ? selectedIds === 'all' || selectedIds.has(event.id) : false)
          }
          onAddFilters={(draftFilter) =>
            setParams((p) => ({ ...p, filters: { ...filters, draftFilter }, sort }))
          }
        />
      ))}
      {(isLoading || isFetchingNextPage) && <SkeletonRows columnsCount={7} amount={PAGE_SIZE} />}
      {hasNextPage && !isFetchingNextPage && (
        <>
          <div ref={loaderRef} />
          <SkeletonRows
            columnsCount={7}
            amount={Math.min(PAGE_SIZE, total ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE)}
          />
        </>
      )}
    </>
  );
};
