import { Box, Button, IconButton, Stack } from '@mui/material';
import { CustomFieldsFormContainer } from '@schooly/components/applications';
import { CreateCustomField } from '@schooly/components/applications';
import { scrollToErrorInput } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ArrowLeftIcon,
  CrossIcon,
  ModalFooter,
  ModalHeader,
  ModalMain,
  ModalPanel,
  Spin,
} from '@schooly/style';
import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { ModalConfirmationDialog } from '../../../components/uikit-components/Modal/ModalConfirmationDialog';

interface ApplicationCreateGeneralInfoModalContentProps {
  customFields: CreateCustomField[];
  saving?: boolean;
  onGoBack?: (customFields: CreateCustomField[]) => void;
  onSubmit: (customFields: CreateCustomField[]) => void;
  resetApplication: () => void;
  onOpenPreview: (customFields: CreateCustomField[]) => void;
}

export const ApplicationCreateGeneralInfoModalContent: FC<ApplicationCreateGeneralInfoModalContentProps> =
  ({ customFields, saving, onSubmit, onOpenPreview, resetApplication, onGoBack }) => {
    const { $t } = useIntl();
    const navigate = useNavigate();

    const [isConfirmModalOpen, showConfirmModal, hideConfirmModal] = useFlag();

    const form = useForm({
      defaultValues: {
        custom_fields: customFields,
      },
      shouldFocusError: false,
    });

    const formCustomFields = form.watch('custom_fields');

    const onCloseAndReset = useCallback(() => {
      resetApplication();
      navigate('/applications');
    }, [navigate, resetApplication]);

    return (
      <FormProvider {...form}>
        <ModalHeader active title={$t({ id: 'applications-Title' })}>
          <IconButton onClick={showConfirmModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>

        <ModalMain>
          <ModalPanel active sx={{ width: '100%' }}>
            <Stack gap={2}>
              <Stack direction="row" gap={1.25}>
                <Box flex={1.5}>
                  <CustomFieldsFormContainer />
                </Box>
              </Stack>
            </Stack>
          </ModalPanel>
        </ModalMain>

        <ModalFooter active>
          <Stack flex={1} direction="row" justifyContent="space-between">
            <Button
              startIcon={<ArrowLeftIcon />}
              onClick={() => onGoBack?.(formCustomFields)}
              variant="outlined"
              disabled={saving}
              data-test-id="application-preview-back-button"
            >
              <FormattedMessage id="action-Back" />
            </Button>

            <Stack direction="row" gap={1}>
              <Button
                variant="outlined"
                startIcon={saving && <Spin />}
                disabled={saving}
                onClick={form.handleSubmit(
                  (v) => onOpenPreview(v.custom_fields),
                  scrollToErrorInput,
                )}
                data-test-id="preview-application-button"
              >
                <FormattedMessage id="action-Preview" />
              </Button>

              <Button
                startIcon={saving && <Spin />}
                disabled={saving}
                onClick={form.handleSubmit((v) => onSubmit(v.custom_fields), scrollToErrorInput)}
                data-test-id="save-application-button"
              >
                <FormattedMessage id="action-Save" />
              </Button>
            </Stack>
          </Stack>
        </ModalFooter>

        <ModalConfirmationDialog
          onCancel={hideConfirmModal}
          onConfirm={onCloseAndReset}
          open={isConfirmModalOpen}
          confirmLabelId="yes"
          cancelLabelId="no"
          content={<FormattedMessage id="applications-CloseWithoutSavingWarning" />}
        />
      </FormProvider>
    );
  };
