import { ControlRichText } from '@schooly/components/form-rich-text';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SignUpForm } from '../../../context/signUps/WithSignUp';

export const SignUpDescription: FC = () => {
  const { $t } = useIntl();
  const { watch } = useFormContext<SignUpForm>();

  const withEvent = watch('withEvent');

  if (withEvent) {
    return null;
  }

  return (
    <ControlRichText
      name="description"
      rules={{ required: true, minLength: 50 }}
      BoxProps={{
        sx: { overflow: 'hidden', '& .ql-editor': { minHeight: 120 } },
      }}
      RichTextProps={{
        placeholder: $t({ id: 'eventSignUps-create-Description' }),
        withoutAttachments: true,
      }}
      FormControlProps={{ sx: { flex: '0 1 auto' } }}
    />
  );
};
