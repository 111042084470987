import { Box, MenuItem, styled, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { ArrowLeftIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { IS_DEMO } from '../../../config';
import {
  allowRenderSchoolFrequencies,
  allowRenderSchoolLegalEntities,
  allowRenderSchoolProducts,
  allowRenderSchoolSettings,
} from '../../../helpers/renderRoutesByPermissions';
import { SimpleButton } from '../../uikit/SimpleButton/SimpleButton';
import { SidebarMenuList } from './Sidebar.styled';

export const SchoolMenu: FC = () => {
  return (
    <>
      <Box sx={{ px: 1.25, py: 1, mt: 0.5, mb: 5, width: 'auto' }}>
        <Link to="/">
          <SimpleButton
            startIcon={<ArrowLeftIcon />}
            sx={{ color: 'text.primary', '&:hover': { color: 'primary.main' } }}
          >
            <FormattedMessage id="action-Back" />
          </SimpleButton>
        </Link>
      </Box>

      <SidebarMenuList>
        <SchoolMenuItems />
      </SidebarMenuList>
    </>
  );
};

const SchoolMenuItems: FC = () => {
  const { pathname } = useLocation();
  const { permissions } = useAuth();

  const showSchoolSettings = allowRenderSchoolSettings(permissions);
  const showSchoolProducts = allowRenderSchoolProducts(permissions);
  const showSchoolLegalEntities = allowRenderSchoolLegalEntities(permissions);
  const showSchoolFrequencies = allowRenderSchoolFrequencies(permissions);
  const showInvoicingSection =
    showSchoolProducts || showSchoolLegalEntities || showSchoolFrequencies;

  return (
    <>
      {showSchoolSettings && (
        <>
          <Link to="/settings" state={{ replace: true }}>
            <MenuItem selected={pathname === '/settings'}>
              <FormattedMessage id="school-sections-General" />
            </MenuItem>
          </Link>

          <SectionTitle variant="h4">
            <FormattedMessage id="section-Enrolment" />
          </SectionTitle>

          {!IS_DEMO && (
            <Link to="/settings/applications" state={{ replace: true }}>
              <MenuItem selected={pathname.startsWith('/settings/applications')}>
                <FormattedMessage id="school-sections-Applications" />
              </MenuItem>
            </Link>
          )}

          <Link to="/settings/annual_rollover" state={{ replace: true }}>
            <MenuItem selected={pathname.startsWith('/settings/annual_rollover')}>
              <FormattedMessage id="section-AnnualRollover" />
            </MenuItem>
          </Link>

          <SectionTitle variant="h4">
            <FormattedMessage id="section-Activity" />
          </SectionTitle>

          <Link to="/settings/assessments" state={{ replace: true }}>
            <MenuItem selected={pathname.startsWith('/settings/assessments')}>
              <FormattedMessage id="school-sections-Assessments" />
            </MenuItem>
          </Link>
          <Link to="/settings/attendance" state={{ replace: true }}>
            <MenuItem selected={pathname.startsWith('/settings/attendance')}>
              <FormattedMessage id="school-sections-Attendance" />
            </MenuItem>
          </Link>
          <Link to="/settings/conduct" state={{ replace: true }}>
            <MenuItem selected={pathname.startsWith('/settings/conduct')}>
              <FormattedMessage id="school-sections-Conduct" />
            </MenuItem>
          </Link>
          <Link to="/settings/school_periods" state={{ replace: true }}>
            <MenuItem selected={pathname.startsWith('/settings/school_periods')}>
              <FormattedMessage id="school-sections-SchoolPeriods" />
            </MenuItem>
          </Link>
        </>
      )}

      {showInvoicingSection && (
        <>
          <SectionTitle variant="h4">
            <FormattedMessage id="section-Invoicing" />
          </SectionTitle>

          {showSchoolLegalEntities && (
            <Link to="/settings/legal_entities" state={{ replace: true }}>
              <MenuItem selected={pathname.startsWith('/settings/legal_entities')}>
                <FormattedMessage id="section-LegalEntitiesAndIntegrations" />
              </MenuItem>
            </Link>
          )}
          {showSchoolFrequencies && (
            <Link to="/settings/frequencies" state={{ replace: true }}>
              <MenuItem selected={pathname.startsWith('/settings/frequencies')}>
                <FormattedMessage id="section-FrequenciesAndDates" />
              </MenuItem>
            </Link>
          )}
          {showSchoolProducts && (
            <Link to="/settings/products" state={{ replace: true }}>
              <MenuItem selected={pathname.startsWith('/settings/products')}>
                <FormattedMessage id="products-Title" />
              </MenuItem>
            </Link>
          )}
        </>
      )}

      {showSchoolSettings && (
        <>
          <SectionTitle variant="h4">
            <FormattedMessage id="section-Others" />
          </SectionTitle>

          <Link to="/settings/filters" state={{ replace: true }}>
            <MenuItem selected={pathname.startsWith('/settings/filters')}>
              <FormattedMessage id="school-sections-Filters" />
            </MenuItem>
          </Link>
          <Link to="/settings/custom_fields" state={{ replace: true }}>
            <MenuItem selected={pathname.startsWith('/settings/custom_fields')}>
              <FormattedMessage id="school-sections-CustomFields" />
            </MenuItem>
          </Link>
          <Link to="/settings/notifications" state={{ replace: true }}>
            <MenuItem selected={pathname.startsWith('/settings/notifications')}>
              <FormattedMessage id="school-sections-Notifications" />
            </MenuItem>
          </Link>
          <Link to="/settings/data_import" state={{ replace: true }}>
            <MenuItem selected={pathname.startsWith('/settings/data_import')}>
              <FormattedMessage id="school-sections-DataImport" />
            </MenuItem>
          </Link>
        </>
      )}
    </>
  );
};

const SectionTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0.5, 1),
  '&:first-child': {
    marginTop: theme.spacing(0),
  },
}));
