import { Paper, PopperProps } from '@mui/material';
import { FC, PropsWithChildren, useEffect } from 'react';

import { ModalRightSidebarArrow, ModalRightSidebarStyled } from './ModalRightSidebar.styled';

export interface ModalRightSidebarProps extends Omit<PropsWithChildren<PopperProps>, 'anchorEl'> {
  anchorEl?: HTMLElement | null;
  offset?: number[];
  arrowOffset?: number[];
}

export const ModalRightSidebar: FC<ModalRightSidebarProps> = ({
  anchorEl,
  offset = [0, 0],
  arrowOffset = [0, 0],
  children,
  ...props
}) => {
  const portal = anchorEl;
  const modal = anchorEl?.closest<HTMLDivElement>('.MuiDialog-container > .MuiPaper-root') ?? null;

  useEffect(() => {
    props.open && modal?.classList.add('withRightSidebarOpen');

    return () => modal?.classList.remove('withRightSidebarOpen');
  }, [modal?.classList, props.open]);

  return (
    <>
      {/**
       * Arrow Popper. Is bind to the portal DOMRect
       * and draws triangle arrow behind the main Popper
       */}
      <ModalRightSidebarArrow
        anchorEl={portal}
        open={props.open}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: arrowOffset,
            },
          },
        ]}
      >
        <Paper />
      </ModalRightSidebarArrow>

      {/** Main Popper */}
      <ModalRightSidebarStyled
        anchorEl={modal}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset,
            },
          },
        ]}
        {...props}
      >
        <Paper>{children}</Paper>
      </ModalRightSidebarStyled>
    </>
  );
};
