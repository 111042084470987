import { IconButton, Stack } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect, PropertyTypeSelect } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { DeleteIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { format } from 'date-fns';
import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import {
  AddRegistrationStatus,
  DisabledRegistrationStatus,
  RegistrationForm,
} from './StudentRegistrationForm';

export interface RegistrationStatusComponent {
  validateYear: ({ start, end }: { start: string; end: string }) => void;
  validateDateOrder: (statuses: AddRegistrationStatus[]) => void;
}
interface RegistrationStatusProps {
  containerRef?: React.RefObject<HTMLElement>;
  schoolId: string;
  disabledStatuses: DisabledRegistrationStatus[];
}

export const RegistrationStatus: FC<RegistrationStatusProps> = ({
  containerRef,
  schoolId,
  disabledStatuses,
}) => {
  const { control, watch, formState } = useFormContext<RegistrationForm>();
  const { $t } = useIntl();

  const statuses = watch('statuses');

  const {
    fields: statusFields,
    append: appendStatus,
    remove: removeStatus,
  } = useFieldArray({
    control,
    name: 'statuses',
  });

  const addField = useCallback(() => {
    appendStatus({
      formId: uuidv4(),
      school_property_id: '',
      applies_from: '',
    });

    if (containerRef) {
      requestAnimationFrame(() => {
        const container = containerRef.current;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
        }
      });
    }
  }, [appendStatus, containerRef]);

  useEffect(() => {
    if (!statusFields.length) {
      addField();
    }
  }, [statusFields.length, addField]);

  return (
    <Stack gap={2}>
      {statuses?.map((statusField, index) => {
        const statusError = formState.errors['statuses']?.[index]?.school_property_id;
        const dateError = formState.errors['statuses']?.[index]?.applies_from;
        const disabledStatus = disabledStatuses.find((s) => s.formId === statusField.formId);
        const required = index === 0 || disabledStatus?.required;
        const requiredLabel = required ? 'required' : 'optional';

        return (
          <Stack
            key={statusField.formId}
            direction="row"
            gap={1.25}
            sx={{
              '& .MuiSelect-select': {
                maxHeight: 44,
              },
            }}
          >
            <Stack
              flex={1}
              position="relative"
              sx={{
                '& .MuiFormControl-root': {
                  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              <Controller
                name={`statuses.${index}.school_property_id`}
                control={control}
                rules={{
                  required,
                }}
                render={({ field }) => {
                  const hasError = Boolean(statusError);
                  const errorMessage = getControllerErrorText(statusError, undefined, $t);
                  return (
                    <PropertyTypeSelect
                      adornmentLabel={requiredLabel}
                      userRole={SchoolUserRole.Student}
                      label={$t({ id: 'schoolProperty-Status' })}
                      propertyType={SchoolPropertyType.Status}
                      schoolId={schoolId}
                      errorMessage={errorMessage}
                      hasError={hasError}
                      fullWidth
                      {...field}
                      disabled={disabledStatus?.disabledFields.includes('school_property_id')}
                      optionalLabel={disabledStatus?.tag}
                      renderEndIcon={disabledStatus ? () => disabledStatus.endAdornment : undefined}
                    />
                  );
                }}
              />
            </Stack>

            <Stack flex={1}>
              <Controller
                name={`statuses.${index}.applies_from`}
                control={control}
                rules={{
                  required,
                }}
                render={({ field }) => {
                  const errorMessage = dateError
                    ? getControllerErrorText(dateError, undefined, $t)
                    : '';

                  const disabled = disabledStatus?.disabledFields.includes('applies_from');

                  return (
                    <DateSelect
                      onSetDate={(date) => {
                        field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                      }}
                      date={field.value}
                      opened={(!field.value && !!disabledStatus) || undefined}
                      hasValues={Boolean(field.value)}
                      placeholder={$t({ id: 'schoolProperty-Status-AppliesForm' })}
                      requiredLabel={requiredLabel}
                      disabled={disabled}
                      renderRightIcon={disabled ? () => <></> : undefined}
                      error={
                        errorMessage
                          ? {
                              message: errorMessage,
                              type: 'validate',
                            }
                          : undefined
                      }
                    />
                  );
                }}
              />
            </Stack>
            {(statusFields.length > 1 || disabledStatus?.lockIcon) && (
              <IconButton
                inverse
                onClick={() => !disabledStatus?.lockIcon && removeStatus(index)}
                sx={(theme) => ({
                  alignSelf: 'flex-start',
                  mt: 1.25,
                  color: theme.palette.common.grey,
                  '&:hover': { color: theme.palette.text.primary },
                })}
              >
                {disabledStatus?.lockIcon ? disabledStatus?.lockIcon : <DeleteIcon />}
              </IconButton>
            )}
          </Stack>
        );
      })}

      <SimpleButton
        sx={{
          alignSelf: 'flex-start',
          '&.Mui-disabled': {
            backgroundColor: (theme) => theme.palette.background.default,
          },
        }}
        onClick={addField}
        startIcon={<PlusIcon />}
      >
        <FormattedMessage id="schoolProperty-AddStatus" />
      </SimpleButton>
    </Stack>
  );
};
