import { Stack, Tooltip, Typography } from '@mui/material';
import { SchoolProperty, StaffRegistration, StudentRegistration } from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';
import { ArchiveIcon, EditIcon } from '@schooly/style';
import React, { PropsWithChildren, ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { isStudentRegistration } from '../../../helpers/registrations';
import buildClassName from '../../../utils/buildClassName';
import Card from '../../ui/Card';
import { StatusStringForRegistration } from './helpers';

import './index.scss';

export interface RegistrationProps {
  registration: StudentRegistration | StaffRegistration;
  onClick?: (registrationId: string) => void;
  actionButton?: ReactNode;
  canEdit?: boolean;
  renderStatus?: () => ReactNode;
}

const RegistrationCard: React.FC<PropsWithChildren<RegistrationProps>> = ({
  registration,
  onClick,
  actionButton,
  canEdit = true,
  children,
  renderStatus,
}) => {
  const { $t } = useIntl();

  const handleClick = useCallback(() => {
    if (!onClick) return;

    onClick(registration.id);
  }, [onClick, registration]);

  const ageGroup = registration.school_properties.find(
    ({ type }) => type === SchoolPropertyType.AgeGroup,
  );

  const house = registration.school_properties.find(
    ({ type }) => type === SchoolPropertyType.House,
  );

  const renderProperty = useCallback(
    (property?: SchoolProperty) => {
      if (!property) {
        return;
      }

      if (property.archived) {
        return (
          <Tooltip title={$t({ id: `schoolProperty-Archived-${property.type}` })}>
            <Stack direction="row" alignItems="center" gap={1}>
              <ArchiveIcon />
              <span>{property.name}</span>
            </Stack>
          </Tooltip>
        );
      }

      return <span>{property.name}</span>;
    },
    [$t],
  );

  return (
    <Card
      className={buildClassName(
        'RegistrationCard row profile-property',
        onClick && 'RegistrationCard--editable',
      )}
      shouldPlaceChildrenDirectly
      onClick={onClick ? handleClick : undefined}
      isListItem
    >
      <div className="card-body d-flex justify-content-center">
        <div className="RegistrationCard__name">
          <Typography variant="h3">
            <Stack direction="row" alignItems="center">
              {isStudentRegistration(registration) ? (
                <>
                  <span className="mr-2">{registration.school_year.name}</span>
                  {renderProperty(ageGroup) ?? $t({ id: 'enrollment-NoAgeGroup' })}
                  {registration.half_day && <>&nbsp;• {$t({ id: 'students-HalfDay' })}</>}
                </>
              ) : (
                registration.job_title || $t({ id: 'employment-NoJobTitle' })
              )}
            </Stack>
          </Typography>
        </div>

        <div className="RegistrationCard__house d-flex align-items-center">
          <Typography color="text.primary" sx={{ m: 0 }}>
            {renderProperty(house) ?? <span>—</span>}
          </Typography>
        </div>

        <div className="RegistrationCard__status d-flex align-items-center">
          <Typography>
            {renderStatus ? (
              renderStatus()
            ) : (
              <StatusStringForRegistration registration={registration} />
            )}
          </Typography>
        </div>
        <Stack direction="row" justifyContent="flex-end" flexGrow={1} gap={2.5}>
          {actionButton}
          {canEdit && (
            <div className="RegistrationCard__icon d-flex align-items-center justify-content-end flex-grow-0">
              <EditIcon />
            </div>
          )}
          {children}
        </Stack>
      </div>
    </Card>
  );
};

export default RegistrationCard;
