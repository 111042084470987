import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import {
  useCheckLegalEntityUniqueFieldsMutation,
  useGetLegalEntityQuery,
  XeroTenant,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import {
  ArrowRightIcon,
  CheckIcon,
  DeleteIcon,
  Loading,
  ModalFooter,
  ModalSmall,
  NewTabIcon,
  SimpleButton,
  Spin,
} from '@schooly/style';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { LegalEntityForm, useLegalEntity } from '../../../../context/legalEntity/WithLegalEntity';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import { setStoredLegalEntity } from '../helpers';
import { LegalEntityRemoveModal } from '../SchoolLegalEntityRemove/LegalEntityRemoveModal';
import { LegalEntityFormContent } from './LegalEntityFormContent';
import { ReplaceProductsAccountsForm } from './ReplaceProductsAccountsContent';
import { ReplaceProductsAccountsModal } from './ReplaceProductsAccountsModal';
import { StartIntegrationModal } from './StartIntegrationModal';

type LegalEntityCreateContentProps = {
  onClose: () => void;
  schoolId: string;
};

const defaultValues: Omit<LegalEntityForm, 'id' | 'country' | 'currency' | 'accounts'> & {
  country?: LegalEntityForm['country'];
  currency?: LegalEntityForm['currency'];
} = {
  display_name: '',
  name: '',
  currency: undefined,
  country: undefined,
  address_line_1: undefined,
  address_line_2: undefined,
  city: undefined,
  region: undefined,
  registration_number: undefined,
  tax_number: undefined,
  zip_code: undefined,
  connected_tenant: null,
};

export const LegalEntityCreateContent: FC<LegalEntityCreateContentProps> = ({
  onClose,
  schoolId,
}) => {
  const {
    modalType,
    changeModalType,
    selectedTenant,
    id,
    changeSelectedTenant,
    disconnectXeroTenant,
    isSaving,
    isRemoving,
    create,
    update,
  } = useLegalEntity();

  const form = useForm<LegalEntityForm>({ defaultValues });
  const checkLegalEntityUniqueFields = useCheckLegalEntityUniqueFieldsMutation();
  const { isLoading: isValidating } = checkLegalEntityUniqueFields;
  const displayName = form.watch('display_name');
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const [editModalState, setEditModalState] = useState(true);
  const { defaultValidity, isLoading: isYearsFetching } = useSchoolYears();

  const { data, isFetching, refetch } = useGetLegalEntityQuery(
    { id, schoolId },
    {
      enabled: !!id,
      onSuccess: (legalEntity) => {
        if (selectedTenant) {
          const { id, ...rest } = selectedTenant;
          form.reset({ ...rest, connected_tenant: { id, name: rest.name } });
        } else {
          const { archived, can_be_deleted, has_products, ...formData } = legalEntity;
          form.reset(formData);
        }
      },
    },
  );

  //TR-6076 Full delete/archive flow will be available in future
  // const { data: billingCounts } = useGetBillingCountsQuery(schoolId, {
  //   enabled: !!schoolId,
  // });
  // const totalActiveLegalEntities = billingCounts?.legal_entities.active ?? 0;
  // const hasMultipleLegalEntities = totalActiveLegalEntities > 1;

  useEffect(() => {
    if (modalType === 'start-integration' && selectedTenant) {
      changeModalType('legal-entity-form');
      const { id, ...rest } = selectedTenant;
      form.reset({ ...rest, connected_tenant: { id, name: rest.name } });
    }
  }, [changeModalType, form, modalType, selectedTenant]);

  const onSave = useCallback(
    async (data: LegalEntityForm) => {
      if (id) {
        return update(data);
      }

      return create(data);
    },
    [create, id, update],
  );

  const handleReplaceProductToAccount = useCallback(
    (formData: ReplaceProductsAccountsForm) => {
      const replaceToAccounts = formData.replace_to_accounts.map(({ product, account }) => ({
        account_id: account.id,
        product_id: product.id,
      }));
      form.setValue('replace_to_accounts', replaceToAccounts);

      const formValues = form.getValues();
      onSave({ ...formValues, replace_to_accounts: replaceToAccounts });
    },
    [form, onSave],
  );

  const handleConnectExistedTenant = useCallback(
    (tenant: XeroTenant) => {
      form.reset(tenant);
      changeSelectedTenant(tenant);
      changeModalType('legal-entity-form');
    },
    [changeSelectedTenant, changeModalType, form],
  );

  const handleCancelIntegration = () => changeModalType('legal-entity-form');

  const handleConnectXero = useCallback(
    async (xeroAuthUri: string) => {
      setStoredLegalEntity({ path: `/settings/legal_entities/${id ? id : 'new'}` });

      window.location.assign(xeroAuthUri);
    },
    [id],
  );

  const handleDisconnectXero = useCallback(async () => {
    const isConfirmed = await getConfirmation({
      textId: 'legalEntities-DisconnectFromXeroConfirmation',
      textValues: {
        legalEntityName: displayName ? `"${displayName}"` : $t({ id: 'legalEntities-LegalEntity' }),
      },
    });

    if (!isConfirmed) return;

    form.setValue('connected_tenant', null);

    if (selectedTenant) {
      changeSelectedTenant();
    }
    const tenantId = data?.connected_tenant?.id ?? selectedTenant?.id;

    if (tenantId) {
      await disconnectXeroTenant({ schoolId, tenant_id: tenantId });
    }
  }, [
    $t,
    changeSelectedTenant,
    data?.connected_tenant?.id,
    disconnectXeroTenant,
    form,
    getConfirmation,
    displayName,
    schoolId,
    selectedTenant,
  ]);

  const handleCloseWithConfirm = useCallback(async () => {
    const needConfirmation = form.formState.isDirty || selectedTenant;

    if (
      needConfirmation &&
      !(await getConfirmation({
        textId: 'school-edit-CloseUnsavedConfirmation',
      }))
    )
      return;

    onClose();
  }, [form.formState.isDirty, getConfirmation, onClose, selectedTenant]);

  const tenantAccounts = selectedTenant?.accounts.length ?? 0;
  const mustReplaceProducts = data?.has_products && tenantAccounts > 1;

  const disabled = isFetching || isSaving || isRemoving || isValidating || isYearsFetching;
  //TR-6076 Edit flow for LE with integration will be available in future
  const canEdit = !data?.connected_tenant?.id;

  const nameChanged = form.formState.dirtyFields.name;
  const taxNumberChanged = form.formState.dirtyFields.tax_number;

  const handleSubmit = useCallback(
    async (data: LegalEntityForm) => {
      const shouldValidateName = nameChanged;
      const shouldValidateTaxNumber = data.tax_number && taxNumberChanged;
      const shouldValidate = shouldValidateName || shouldValidateTaxNumber;

      if (!shouldValidate) {
        const res = await onSave(data);
        if (res) onClose();
        return;
      }

      await checkLegalEntityUniqueFields.mutateAsync(
        {
          schoolId,
          name: shouldValidateName ? data.name : undefined,
          tax_number: shouldValidateTaxNumber ? data.tax_number : undefined,
        },
        {
          onSuccess: async ({ name_is_unique, tax_number_duplicate_name }) => {
            const nameIsUnique = name_is_unique !== false;

            if (!nameIsUnique) {
              form.setError('name', {
                type: 'validate',
                message: $t({ id: 'legalEntities-NameExists' }),
              });
              form.setFocus('name');
            }

            if (tax_number_duplicate_name) {
              form.setError('tax_number', {
                type: 'validate',
                message: $t(
                  { id: 'legalEntities-TaxIdExists' },
                  { name: `"${tax_number_duplicate_name}"` },
                ),
              });
              nameIsUnique && form.setFocus('tax_number');
            }

            if (!nameIsUnique || tax_number_duplicate_name) return;

            const res = await onSave(data);
            if (res) onClose();
          },
          onError: showError,
        },
      );
    },
    [
      $t,
      checkLegalEntityUniqueFields,
      form,
      nameChanged,
      onClose,
      onSave,
      schoolId,
      showError,
      taxNumberChanged,
    ],
  );

  const renderContent = () => {
    switch (modalType) {
      case 'start-integration': {
        return (
          <StartIntegrationModal
            onSubmit={handleConnectExistedTenant}
            onCancel={handleCancelIntegration}
            onConnect={handleConnectXero}
            schoolId={schoolId}
            onClose={onClose}
          />
        );
      }
      case 'complete-integration': {
        form.setValue('connected_tenant', { id: '', name: '' });
        changeModalType('legal-entity-form');
        return (
          <ModalSmall open>
            <Loading />
          </ModalSmall>
        );
      }
      case 'legal-entity-form':
        //TR-6076 Temporary refresh. Full delete/archive flow will be available in future
        const canReload = Boolean(data?.id && !data.can_be_deleted);
        return (
          <ModalSmall open onClose={handleCloseWithConfirm}>
            {id && (!data || isFetching || isYearsFetching) ? (
              <Loading />
            ) : (
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                  <LegalEntityFormContent
                    onConnect={() => changeModalType('start-integration')}
                    onDisconnect={handleDisconnectXero}
                    onClose={handleCloseWithConfirm}
                    onReload={canReload ? refetch : undefined}
                  >
                    <ModalFooter
                      active
                      sx={{
                        justifyContent: 'space-between',
                      }}
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        {id && (
                          //TR-6076 Full delete/archive flow will be available in future
                          <Tooltip
                            title={
                              <Stack gap={1.25} alignItems="flex-start">
                                <Typography>
                                  {$t({ id: 'legalEntities-CannotBeRemoved' })}
                                </Typography>
                                <Link to="/settings/products" target="_blank">
                                  <SimpleButton size="small" startIcon={<NewTabIcon />}>
                                    {$t({ id: 'legalEntities-GoToProducts' })}
                                  </SimpleButton>
                                </Link>
                              </Stack>
                            }
                            disableHoverListener={data?.can_be_deleted}
                          >
                            <Box>
                              <Button
                                variant="outlined"
                                startIcon={<DeleteIcon />}
                                onClick={() => setEditModalState(false)}
                                disabled={!data?.can_be_deleted}
                              >
                                {$t({ id: 'action-Delete' })}
                              </Button>
                            </Box>
                          </Tooltip>
                        )}
                        {selectedTenant && (
                          <Typography color="common.grey" sx={{ justifySelf: 'flex-start' }}>
                            {$t({ id: 'legalEntities-NotSavedForm' })}
                          </Typography>
                        )}
                      </Stack>

                      {mustReplaceProducts ? (
                        <Button
                          endIcon={<ArrowRightIcon />}
                          onClick={() => changeModalType('replace-accounts')}
                          disabled={disabled}
                        >
                          {$t({ id: 'action-Next' })}
                        </Button>
                      ) : (
                        <Button
                          endIcon={isSaving || isValidating ? <Spin /> : <CheckIcon />}
                          disabled={disabled || !canEdit}
                          type="submit"
                        >
                          {$t({ id: 'action-Save' })}
                        </Button>
                      )}
                    </ModalFooter>
                  </LegalEntityFormContent>
                </form>
              </FormProvider>
            )}
          </ModalSmall>
        );
      case 'replace-accounts':
        if (!selectedTenant || !id || !data) return null;
        return (
          <ReplaceProductsAccountsModal
            schoolId={schoolId}
            accounts={selectedTenant.accounts}
            legalEntityId={id}
            title={
              <>
                <Typography variant="h2">
                  {$t({ id: 'legalEntities-ReplaceProductsTitle' })}
                </Typography>
                <Typography color="text.primary">{data.name}</Typography>
              </>
            }
            onSubmit={handleReplaceProductToAccount}
            isLoading={isSaving}
            onGoBack={() => changeModalType('legal-entity-form')}
          />
        );

      default:
        return (
          <ModalSmall open>
            <Loading />
          </ModalSmall>
        );
    }
  };

  return editModalState ? (
    renderContent()
  ) : (
    <LegalEntityRemoveModal
      legalEntity={data}
      onClose={onClose}
      onGoBack={() => setEditModalState(true)}
      schoolId={schoolId}
      initialModalType={'remove-confirm'}
      yearId={defaultValidity?.id ?? ''}
      //TR-6076 Full delete/archive flow will be available in future
      // initialModalType={hasMultipleLegalEntities ? 'remove-start' : 'remove-confirm'}
    />
  );
};
