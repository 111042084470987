import { Components, Theme } from '@mui/material';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  defaultProps: {
    transitionDuration: 0, // transition between two modals produces a visual blinking
  },
  styleOverrides: {
    root: ({ theme }) => {
      const maxWidth = `calc(100vw - ${theme.spacing(5)})`;

      const fullDvh = '100dvh';
      const fullVh = '100vh';
      const maxHeightWithDvh = `calc(${fullDvh} - ${theme.spacing(5)})`;
      const maxHeightWithVh = `calc(${fullVh} - ${theme.spacing(5)})`;

      return {
        '& .MuiDialog-paper': {
          [theme.breakpoints.up('md')]: {
            borderRadius: theme.shape.borderRadius,
          },
          [theme.breakpoints.down('md')]: {
            borderRadius: 0,
            maxWidth: '100vw',
            maxHeight: '100vh',
            margin: 0,
          },
        },

        '& .MuiDialog-paperFullWidth': {
          maxWidth,
          width: maxWidth,
          margin: theme.spacing(2.5),

          height: maxHeightWithVh,
          maxHeight: maxHeightWithVh,
          '@supports (height: 100dvh)': {
            height: maxHeightWithDvh,
            maxHeight: maxHeightWithDvh,
          },

          [theme.breakpoints.down('lg')]: {
            maxWidth: '100vw',
            margin: 0,
            borderRadius: 0,

            height: fullVh,
            maxHeight: fullVh,
            '@supports (height: 100dvh)': {
              height: fullDvh,
              maxHeight: fullDvh,
            },
          },
        },
      };
    },
  },
};
