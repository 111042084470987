import { Icon, Stack, Typography } from '@mui/material';
import { Countries } from '@schooly/constants';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { $enum } from 'ts-enum-util';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectSearchInput } from '../SelectSearchInput';

type CountrySelectProps = PropsWithChildren<{
  selectedCountry?: Countries;
  onSelectCountry: (v: Countries) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

const OPTIONS = $enum(Countries).map((value, key) => ({ value, label: Countries[value] }));

export const CountrySelect: FC<CountrySelectProps> = ({
  selectedCountry,
  onSelectCountry,
  placeholder,
  ...dropdownProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState('');
  const renderContent = useCallback(() => {
    const result = OPTIONS.filter(({ label }) => label.toLowerCase().includes(query.toLowerCase()));

    if (!result.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {result.map((country) => {
          const isSelected = selectedCountry === country.value;

          return (
            <Stack
              key={country.value}
              onClick={() => {
                onSelectCountry(country.value);
                setQuery('');
              }}
              flexDirection="row"
              alignItems="center"
              px={1}
              gap={2}
              py={0.5}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                },
              })}
            >
              <TypographyWithOverflowHint
                flex={1}
                color={!isSelected ? 'common.grey2' : undefined}
                variant="h3"
                noWrap
              >
                {country.label}
              </TypographyWithOverflowHint>

              <Icon
                sx={{
                  visibility: isSelected ? 'visible' : 'hidden',
                }}
              >
                <DoneIcon />
              </Icon>
            </Stack>
          );
        })}
      </>
    );
  }, [onSelectCountry, query, selectedCountry]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={selectedCountry !== undefined}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
    >
      {(opened) => (
        <>
          {selectedCountry !== undefined && !query && (
            <Typography variant="h3">{Countries[selectedCountry]}</Typography>
          )}
          {opened && (
            <SelectSearchInput
              debounced={false}
              ref={inputRef}
              autoFocus
              value={query}
              onChangeText={setQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
