import { Box, Stack, TableCellProps, Typography } from '@mui/material';
import {
  AttendanceRegisterForSchool,
  DATE_FORMAT_SHORT_FNS,
  useGetAttendanceCodesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { HashIcon, Loading, PercentIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import moment from 'moment/moment';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import {
  GridCell,
  GridHeaderCellContent,
  GridRowDate,
  GridRowName,
} from '../../../components/uikit-components/Grid/Grid';
import { LongNameWithTooltip } from '../../../components/uikit-components/LongNameWithTooltip/LongNameWithTooltip';
import { Table, TableCell } from '../../../components/uikit-components/Table/Table';
import { useGroupAttendanceStatistics } from '../../../context/groups/useGroupAttendanceStatistics';
import { useAttendanceStatistics } from '../../../hooks/useAttendanceStatistics';
import { getAbsentTotal, getPresentTotal } from '../../Attendance/attendance-utils';
import { GridRowDateToday } from '../../Attendance/AttendanceList.styled';
import {
  PercentButton,
  PercentIconButton,
} from '../../Attendance/AttendanceStatistics/AttendanceStats.styled';
import { GroupAttendanceStatisticsEmptyStub } from './GroupAttendanceStatisticsEmptyStub/GroupAttendanceStatisticsEmptyStub';
import { GroupAttendanceStatisticsModalToggleMode } from './GroupAttendanceStatisticsModalToggleMode';

const LEFT_COL_WIDTH = 260;
const DATE_COL_WIDTH = 80;

export const GroupAttendanceStatisticsModalListRegisters: FC = () => {
  const { schoolId = '' } = useAuth();
  const { data: codes } = useGetAttendanceCodesQuery(schoolId);
  const {
    registers,
    query,
    isFetching,
    isFetchingNextPage,
    canShowMore,
    actions,
    showByPresentAbsent,
    showByPercent,
  } = useGroupAttendanceStatistics();

  const { renderCount, getTotalCount } = useAttendanceStatistics(1, showByPercent);

  const today = useMemo(moment, []);

  const loaderRef = useInfiniteScroll(isFetching || isFetchingNextPage, actions.handleShowMore);
  const isLoading = isFetching && !registers;

  const entries = useMemo(
    () =>
      registers?.pages.reduce<AttendanceRegisterForSchool[]>(
        (prev, curr) => [...prev, ...curr.results],
        [],
      ) ?? [],
    [registers?.pages],
  );

  /* Registers attendance map by codes */
  const registersAttendance = useMemo(
    () =>
      entries.reduce<
        Record<string, { codes: Record<string, number>; total: number; noStatus: number }>
      >((prev, register) => {
        prev[register.id] = { codes: {}, total: 0, noStatus: 0 };

        register.attendance_details?.forEach((item) => {
          if (item.attendance_code_id) {
            prev[register.id].codes[item.attendance_code_id] = item.count;
          } else {
            prev[register.id].noStatus += item.count;
          }
          prev[register.id].total += item.count;
        });

        return prev;
      }, {}),
    [entries],
  );

  const attendanceCodes = useMemo(() => codes ?? [], [codes]);

  const columns: TableCell[] = useMemo(
    () => [
      {
        id: 'Header',
        renderContent: (props) => (
          <GridCell
            noHorizontalPadding
            colSpan={2}
            sx={{
              maxWidth: LEFT_COL_WIDTH,
              minWidth: LEFT_COL_WIDTH,
              '&.MuiTableCell-head': { pb: 1 },
            }}
            {...props}
          >
            <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
              <GroupAttendanceStatisticsModalToggleMode />
            </GridHeaderCellContent>
          </GridCell>
        ),
        hoverProps: {
          disableHover: true,
        },
      },
      ...(!showByPresentAbsent
        ? attendanceCodes.map((code) => ({
            id: code.id,
            renderContent: (props: TableCellProps) => (
              <GridCell
                key={`th-${code.code}`}
                borderBottom
                sx={(theme) => ({
                  minWidth: theme.spacing(6.5),
                  width: `${100 / attendanceCodes.length}%`,
                  '&.MuiTableCell-head': { pb: 1 },
                })}
                {...props}
              >
                <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
                  {code.name}
                </GridHeaderCellContent>
              </GridCell>
            ),
          }))
        : [
            {
              id: 'attendance-Present',
              renderContent: (props: TableCellProps) => (
                <GridCell
                  noHorizontalPadding
                  sx={{
                    maxWidth: LEFT_COL_WIDTH,
                    minWidth: LEFT_COL_WIDTH,
                    '&.MuiTableCell-head': { pb: 1 },
                  }}
                  {...props}
                >
                  <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
                    <FormattedMessage id="attendance-Present" />
                  </GridHeaderCellContent>
                </GridCell>
              ),
            },
            {
              id: 'attendance-Absent',
              renderContent: (props: TableCellProps) => (
                <GridCell
                  noHorizontalPadding
                  sx={{
                    maxWidth: LEFT_COL_WIDTH,
                    minWidth: LEFT_COL_WIDTH,
                    '&.MuiTableCell-head': { pb: 1 },
                  }}
                  {...props}
                >
                  <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
                    <FormattedMessage id="attendance-Absent" />
                  </GridHeaderCellContent>
                </GridCell>
              ),
            },
          ]),
      {
        id: 'attendance-NoStatus',
        renderContent: (props) => (
          <GridCell
            sx={{
              minWidth: 90,
              '&.MuiTableCell-head': {
                pb: 1,
              },
            }}
            {...props}
          >
            <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
              <FormattedMessage id="attendance-NoStatus" />
            </GridHeaderCellContent>
          </GridCell>
        ),
      },
      {
        id: 'attendance-Total',
        renderContent: (props) => (
          <GridCell
            borderBottom
            sx={{ minWidth: 90, '&.MuiTableCell-head': { pb: 1, pr: 0 } }}
            {...props}
          >
            <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <FormattedMessage id="attendance-Total" />

                <PercentButton variant="outlined">
                  <PercentIconButton onClick={() => actions.setShowByPercent(!showByPercent)}>
                    {showByPercent ? <HashIcon /> : <PercentIcon />}
                  </PercentIconButton>
                </PercentButton>
              </Stack>
            </GridHeaderCellContent>
          </GridCell>
        ),
      },
    ],
    [attendanceCodes, showByPresentAbsent, showByPercent, actions],
  );

  const rows = useMemo(() => {
    return entries.map((register) => {
      const inSchoolCount = getPresentTotal(attendanceCodes ?? [], register.attendance_details);
      const outOfSchoolCount = getAbsentTotal(attendanceCodes ?? [], register.attendance_details);
      const totalCount = registersAttendance?.[register.id]?.total;

      const cellArray: TableCell[] = [
        {
          id: 'date',
          renderContent: (props) => (
            <GridCell
              noVerticalPadding
              sx={{ width: DATE_COL_WIDTH, minWidth: DATE_COL_WIDTH }}
              pl={2.5}
              {...props}
            >
              {moment(register.register_date).isSame(today, 'day') ? (
                <GridRowDateToday>
                  <FormattedMessage id="datepicker-Today" />
                </GridRowDateToday>
              ) : (
                <GridRowDate>
                  {format(newDateTimezoneOffset(register.register_date), DATE_FORMAT_SHORT_FNS)}
                </GridRowDate>
              )}
            </GridCell>
          ),
        },
        {
          id: 'name',
          renderContent: (props) => (
            <GridCell
              sx={{
                width: LEFT_COL_WIDTH - DATE_COL_WIDTH,
                minWidth: LEFT_COL_WIDTH - DATE_COL_WIDTH,
              }}
              noPadding
              {...props}
            >
              <GridRowName>
                <Box
                  sx={{
                    width: LEFT_COL_WIDTH - DATE_COL_WIDTH,
                  }}
                >
                  <LongNameWithTooltip>{register.name}</LongNameWithTooltip>
                </Box>
              </GridRowName>
            </GridCell>
          ),
          hoverProps: {
            onlyXHover: true,
          },
        },
        ...(!showByPresentAbsent
          ? attendanceCodes.map((code) => ({
              renderContent: (props: TableCellProps) => (
                <GridCell key={`td-${register.id}-${code.id}`} py={1.5} {...props}>
                  {renderCount(registersAttendance?.[register.id]?.codes[code.id], totalCount)}
                </GridCell>
              ),
              id: code.id,
            }))
          : [
              {
                id: 'attendance-Present',
                renderContent: (props: TableCellProps) => (
                  <GridCell noHorizontalPadding {...props}>
                    {renderCount(inSchoolCount, totalCount)}
                  </GridCell>
                ),
              },
              {
                id: 'attendance-Absent',
                renderContent: (props: TableCellProps) => (
                  <GridCell noHorizontalPadding {...props}>
                    {renderCount(outOfSchoolCount, totalCount)}
                  </GridCell>
                ),
              },
            ]),
        {
          id: 'attendance-NoStatus',
          renderContent: (props) => (
            <GridCell noVerticalPadding {...props}>
              {renderCount(
                registersAttendance?.[register.id]?.noStatus,
                registersAttendance?.[register.id]?.total,
              )}
            </GridCell>
          ),
        },
        {
          id: 'attendance-Total',
          renderContent: (props) => (
            <GridCell borderLeft noVerticalPadding {...props}>
              <Typography color="text.primary">
                {getTotalCount(registersAttendance?.[register.id]?.total)}
              </Typography>
            </GridCell>
          ),
        },
      ];

      return { id: register.id, cells: cellArray };
    });
  }, [
    attendanceCodes,
    getTotalCount,
    entries,
    registersAttendance,
    renderCount,
    today,
    showByPresentAbsent,
  ]);

  const total = registers?.pages[0]?.count;

  return (
    <Box
      sx={{
        overflowY: 'auto',
        mx: 2.5,
      }}
    >
      <Table
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        isEmpty={!isLoading && !total}
        renderEmptyContent={
          query ? <NoSearchResultsFound type="small" /> : <GroupAttendanceStatisticsEmptyStub />
        }
      />
      {canShowMore && (
        <>
          {isFetchingNextPage && (
            <Box py={3}>
              <Loading />
            </Box>
          )}
          <div ref={loaderRef} />
        </>
      )}
    </Box>
  );
};
