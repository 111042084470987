import { Button, FormControlLabel, IconButton, Stack, Switch, Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  useGetDuplicatesQuery,
  UserForDuplicatesCheck,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AgeGroupSelectMultiple,
  PropertyTypeSelect,
  SelectOptionsArchivedEmptyStub,
  SelectOptionsArchivedIcon,
  SubjectSelectMultiple,
} from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useSubjects } from '@schooly/hooks/use-subjects';
import {
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSmall,
  Spin,
} from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { format } from 'date-fns';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DuplicatesCard } from '../PersonDuplicates/DuplicatesCard';
import { DuplicatesRow } from '../PersonDuplicates/DuplicatesRow';
import { AddEmploymentCaseModalStatuses } from './AddEmploymentCaseModalStatuses';
import { RemoveEmploymentCaseConfirmDialog } from './RemoveEmploymentCaseConfirmDialog';
import { validateRegistrationStatuses } from './validateRegistrations';

export interface AddEmploymentCaseStatus {
  school_property_id?: string;
  applies_from?: string;
}
export type AddEmploymentCaseForm = {
  job_title: string;
  department_id?: string;
  house_id?: string;
  subject_ids: { originId: string }[];
  age_group_ids: { originId: string }[];
  statuses: AddEmploymentCaseStatus[];
};

const defaultValues: AddEmploymentCaseForm = {
  job_title: '',
  statuses: [{ applies_from: format(new Date(), DEFAULT_DATE_FORMAT_FNS) }],
  subject_ids: [],
  age_group_ids: [],
};

export type AddEmploymentCaseModalProps = {
  schoolId: string;
  title: string;
  open: boolean;
  isSaving: boolean;
  isDeleting?: boolean;
  initialValues?: AddEmploymentCaseForm;
  onClose?: () => void;
  onSubmit: (v: AddEmploymentCaseForm) => void;
  onDelete?: () => void;
  user?: UserForDuplicatesCheck;
  setSelectedDuplicateId?: Dispatch<SetStateAction<string>>;
  selectedDuplicateId?: string;
};

export const AddEmploymentCaseModal: FC<AddEmploymentCaseModalProps> = ({
  schoolId,
  isSaving,
  isDeleting,
  title,
  open,
  initialValues,
  onSubmit,
  onDelete,
  onClose,
  user,
  setSelectedDuplicateId,
  selectedDuplicateId,
}) => {
  const { $t } = useIntl();
  const { permissions } = useAuth();
  const isAdmin = permissions?.includes('school_admin');
  const { activeSubjects, subjects } = useSubjects({ schoolId }, { refetchOnMount: true });
  const { activePropertiesMap, propertiesMap } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Staff,
  });

  const hasDepartments = Boolean(propertiesMap.department.length);
  const hasSubjects = Boolean(subjects.length);

  const hasActiveDepartments = Boolean(activePropertiesMap.department.length);
  const hasActiveHouses = Boolean(activePropertiesMap.house.length);
  const hasActiveSubjects = Boolean(activeSubjects.length);

  const { data: duplicateRecords, isFetching: isFetchingDuplicates } = useGetDuplicatesQuery(
    { schoolId: schoolId || '', data: user ? [user] : [] },
    { enabled: Boolean(schoolId && user?.date_of_birth) },
  );
  const duplicates = duplicateRecords?.find((record) => record.id === user?.id)?.duplicates ?? [];

  const isLoading = Boolean(isFetchingDuplicates && user);

  const form = useForm<AddEmploymentCaseForm>({
    defaultValues: { ...defaultValues, ...initialValues },
  });
  const [removeDialogOpened, openRemoveDialog, closeRemoveDialog] = useFlag(false);

  const selectedSubjects = form.watch('subject_ids');

  const handleSubmit = useCallback<SubmitHandler<AddEmploymentCaseForm>>(
    (data) => {
      const { statuses, department_id, job_title } = data;

      const statusErrors = validateRegistrationStatuses(statuses);
      const noDepartmentSelected = !department_id && hasActiveDepartments;

      if (statusErrors.some(Boolean) || noDepartmentSelected || !job_title) {
        if (noDepartmentSelected) form.setError('department_id', { type: 'required' });
        if (!job_title) form.setError('job_title', { type: 'required' });

        statusErrors.forEach((statusError, index) => {
          statusError.school_property_id &&
            form.setError(`statuses.${index}.school_property_id`, {
              type: 'validate',
              message: statusError.school_property_id,
            });
          statusError.applies_from &&
            form.setError(`statuses.${index}.applies_from`, {
              type: 'validate',
              message: statusError.applies_from,
            });
        });

        return;
      }

      onSubmit(data);
    },
    [form, hasActiveDepartments, onSubmit],
  );

  const {
    fields: subjectFields,
    append: appendSubject,
    remove: removeSubject,
    replace: replaceSubjects,
  } = useFieldArray({
    control: form.control,
    name: 'subject_ids',
  });

  const handleSelectSubjectId = useCallback(
    (originId: string) => {
      const existingFieldIndex = subjectFields.map((f) => f.originId).indexOf(originId);

      if (existingFieldIndex !== -1) {
        removeSubject(existingFieldIndex);
        return;
      }

      appendSubject({ originId });
    },
    [appendSubject, subjectFields, removeSubject],
  );

  const clearSubjects = useCallback(() => replaceSubjects([]), [replaceSubjects]);

  const { fields: ageGroupFields, replace: replaceAgeGroups } = useFieldArray({
    control: form.control,
    name: 'age_group_ids',
  });

  const handleSelectAgeGroupId = useCallback(
    (value: string[]) => {
      const ageGroups = form.getValues('age_group_ids').map(({ originId }) => originId);

      const updatedIds = new Set([...ageGroups, ...value]);
      if (updatedIds.size === ageGroups?.length) {
        updatedIds.forEach((id) => value.includes(id) && updatedIds.delete(id));
      }

      replaceAgeGroups(Array.from(updatedIds).map((id) => ({ originId: id })));
    },
    [form, replaceAgeGroups],
  );

  const clearAgeGroups = useCallback(() => replaceAgeGroups([]), [replaceAgeGroups]);

  const renderExistingRecordSwitch = useCallback(
    (checked: boolean, onToggle: () => void) => {
      return (
        <Stack justifyContent="center" ml={1}>
          <FormControlLabel
            sx={{ m: 0 }}
            control={
              <Switch
                checked={checked}
                onChange={onToggle}
                sx={{
                  ' .MuiSwitch-track, .Mui-checked+.MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: 'background.paper',
                  },
                  '&:hover': {
                    color: 'primary.main',
                    ' .MuiSwitch-track, .Mui-checked+.MuiSwitch-track': {
                      opacity: 1,
                    },
                  },
                }}
              />
            }
            label={$t({ id: 'action-UseExistingRecord' })}
          />
        </Stack>
      );
    },
    [$t],
  );

  return (
    <ModalSmall open={open} onClose={onClose}>
      {isLoading ? (
        <Loading />
      ) : (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader active title={title}>
              <IconButton onClick={onClose}>
                <CrossIcon />
              </IconButton>
            </ModalHeader>
            <ModalContent
              active
              sx={isSaving || isDeleting ? { pointerEvents: 'none' } : undefined}
            >
              <Stack gap={3}>
                <Stack gap={2.5}>
                  <Typography variant="h2">
                    <FormattedMessage id="peopleDetail-EmploymentCaseModal-EmploymentInformation" />
                  </Typography>
                  <Stack gap={2}>
                    {!!duplicates.length && !!setSelectedDuplicateId && (
                      <DuplicatesCard
                        message={
                          duplicates.length < 2
                            ? 'peopleDetail-EmploymentCaseModal-StaffDuplicate'
                            : 'peopleDetail-EmploymentCaseModal-StaffDuplicate-plural'
                        }
                      >
                        {duplicates.map((duplicate) => {
                          const isSelected = duplicate.relation_id === selectedDuplicateId;
                          const onToggle = () => {
                            setSelectedDuplicateId(() => (isSelected ? '' : duplicate.relation_id));
                          };
                          return (
                            <DuplicatesRow
                              duplicate={duplicate}
                              endAction={renderExistingRecordSwitch(isSelected, onToggle)}
                              userType={'staff'}
                              sxProps={{
                                border: `1px solid transparent`,
                                '&:hover': {
                                  borderColor: `warning.main`,
                                  ' .MuiTypography-root, .MuiIconButton-root': {
                                    color: 'primary.main',
                                  },
                                },
                              }}
                            />
                          );
                        })}
                      </DuplicatesCard>
                    )}
                    <Typography variant="h4">
                      <FormattedMessage id="peopleDetail-EmploymentCaseModal-ProfessionalInformation" />
                    </Typography>
                    <Stack flexDirection="row" gap={1.25}>
                      <Stack
                        flex={1}
                        sx={(theme) => ({
                          '& .MuiInputBase-root .MuiOutlinedInput-root': {
                            padding: theme.spacing(0, 0, 1),
                          },
                        })}
                      >
                        <ControlTextField
                          type="text"
                          name="job_title"
                          control={form.control}
                          required
                          label={$t({ id: 'peopleDetail-JobTitle' })}
                          fullWidth
                        />
                      </Stack>
                      {hasDepartments && (
                        <Stack flex={1}>
                          <Controller
                            name="department_id"
                            control={form.control}
                            render={({ field }) => {
                              const error = form.formState.errors['department_id'];
                              const hasError = Boolean(error);
                              const errorMessage = getControllerErrorText(error, undefined, $t);

                              return (
                                <PropertyTypeSelect
                                  adornmentLabel="required"
                                  userRole={SchoolUserRole.Staff}
                                  schoolId={schoolId}
                                  propertyType={SchoolPropertyType.Department}
                                  label={$t({ id: 'schoolProperty-Department' })}
                                  hasError={hasError}
                                  errorMessage={errorMessage}
                                  fullWidth
                                  hideIfNoOptions
                                  disabled={!hasActiveDepartments && !field.value}
                                  canClear={Boolean(!hasActiveDepartments && field.value)}
                                  onClear={() => form.setValue('department_id', '')}
                                  renderEndIcon={
                                    !hasActiveDepartments && !field.value
                                      ? () => (
                                          <SelectOptionsArchivedIcon
                                            isAdmin={!!isAdmin}
                                            type={SchoolPropertyType.Department}
                                          />
                                        )
                                      : undefined
                                  }
                                  renderEmptyStub={
                                    Boolean(!hasActiveDepartments && field.value)
                                      ? () => (
                                          <SelectOptionsArchivedEmptyStub
                                            isAdmin={!!isAdmin}
                                            type={SchoolPropertyType.Department}
                                          />
                                        )
                                      : undefined
                                  }
                                  {...field}
                                />
                              );
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack gap={2}>
                  <Typography variant="h4">
                    <FormattedMessage id="peopleDetail-EmploymentCaseModal-EducationalInformation" />
                  </Typography>
                  <AgeGroupSelectMultiple
                    hideIfNoOptions
                    userRole={SchoolUserRole.Staff}
                    label={$t({ id: 'peopleDetail-EmploymentCaseModal-AgeGroups' })}
                    schoolId={schoolId}
                    onClear={clearAgeGroups}
                    onSelect={handleSelectAgeGroupId}
                    selectedIds={ageGroupFields.map((f) => f.originId)}
                    placeholder={$t({ id: 'peopleDetail-EmploymentCaseModal-AgeGroups' })}
                    requiredLabel="optional"
                  />
                  {hasSubjects && (
                    <SubjectSelectMultiple
                      label={$t({ id: 'peopleDetail-EmploymentCaseModal-Subjects' })}
                      schoolId={schoolId}
                      onClear={clearSubjects}
                      onSelectSubjectId={handleSelectSubjectId}
                      selectedIds={subjectFields.map((f) => f.originId)}
                      placeholder={$t({ id: 'peopleDetail-EmploymentCaseModal-Subjects' })}
                      requiredLabel="optional"
                      disabled={!hasActiveSubjects && !selectedSubjects.length}
                      isAdmin={isAdmin}
                    />
                  )}
                  <Controller
                    name="house_id"
                    control={form.control}
                    render={({ field }) => {
                      const error = form.formState.errors['house_id'];
                      const hasError = Boolean(error);
                      const errorMessage = getControllerErrorText(error, undefined, $t);

                      return (
                        <PropertyTypeSelect
                          hideIfNoOptions
                          userRole={SchoolUserRole.Staff}
                          schoolId={schoolId}
                          propertyType={SchoolPropertyType.House}
                          label={$t({ id: 'schoolProperty-House' })}
                          hasError={hasError}
                          errorMessage={errorMessage}
                          fullWidth
                          adornmentLabel="optional"
                          disabled={!hasActiveHouses && !field.value}
                          canClear={Boolean(!hasActiveHouses && field.value)}
                          onClear={() => form.setValue('house_id', '')}
                          renderEndIcon={
                            !hasActiveHouses && !field.value
                              ? () => (
                                  <SelectOptionsArchivedIcon
                                    isAdmin={!!isAdmin}
                                    type={SchoolPropertyType.House}
                                  />
                                )
                              : undefined
                          }
                          renderEmptyStub={
                            Boolean(!hasActiveHouses && field.value)
                              ? () => (
                                  <SelectOptionsArchivedEmptyStub
                                    isAdmin={!!isAdmin}
                                    type={SchoolPropertyType.House}
                                  />
                                )
                              : undefined
                          }
                          {...field}
                        />
                      );
                    }}
                  />
                </Stack>
                <AddEmploymentCaseModalStatuses schoolId={schoolId} form={form} />
              </Stack>
            </ModalContent>
            <ModalFooter active>
              {onDelete && (
                <Button
                  onClick={openRemoveDialog}
                  variant="outlined"
                  startIcon={isDeleting ? <Spin /> : <DeleteIcon />}
                  disabled={isSaving || isDeleting}
                >
                  <FormattedMessage id="people-Remove" />
                </Button>
              )}
              <Button
                type="submit"
                endIcon={isSaving ? <Spin /> : <CheckIcon />}
                disabled={isSaving || isDeleting}
              >
                <FormattedMessage id="action-Save" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      )}
      {removeDialogOpened && onDelete && (
        <RemoveEmploymentCaseConfirmDialog onClose={closeRemoveDialog} onConfirm={onDelete} />
      )}
    </ModalSmall>
  );
};
