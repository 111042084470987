import { SuggestedChangeDataSet } from '@schooly/constants';
import React from 'react';

import { useProfile } from '../../../context/profile/useProfile';
import { getUserFullNameWithTitle } from '../../../helpers/users';
import ProfileProperty, { PropertyParams } from '../properties/ProfileProperty';
import NameModal from './NameModal';

const NameProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
  ...params
}: PropertyParams) => {
  const { user, canEditProfile, hasEditingPermission } = useProfile();

  const value = user && getUserFullNameWithTitle(user);

  return (
    <ProfileProperty
      value={value}
      canEdit={canEditProfile && hasEditingPermission}
      params={{ size, iconColor, color, ...params }}
      valueTextId="people-Name"
      editValueTextId="people-Name"
      dataSet={SuggestedChangeDataSet.Name}
      modalController={({ isOpen, hide }) => <NameModal isOpen={isOpen} onClose={hide} />}
    />
  );
};

export default NameProperty;
