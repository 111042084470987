import React from 'react';

import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';

import './LoaderShimmerLine.scss';

const $class = `${$UIKitPrefix}LoaderShimmerLine`;

interface LoaderShimmerLineProps {
  className?: string;
}

export const LoaderShimmerLine: React.FC<LoaderShimmerLineProps> = ({ className }) => {
  return <div className={buildClassName($class, className)} />;
};
