import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { ConsentFormItemStatuses, GetConsentFormQueryFilters } from '@schooly/api';
import { DropdownSelect, SelectSearchInput } from '@schooly/components/filters';
import { CrossIcon, ModalContent, ModalFooter, ModalHeader, TagSelect } from '@schooly/style';
import { FC, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { getConsentFormItemStatusLabelId } from '../../../../components/common/ConsentForms/helpers';
import { useSchool } from '../../../../hooks/useSchool';

type ConsentFormsFilterPopupProps = {
  onClose: () => void;
  onSetFilters: (v: GetConsentFormQueryFilters) => void;
  filters: GetConsentFormQueryFilters;
};

export const CONSENT_FORM_STATUS_OPTIONS = ConsentFormItemStatuses.map((status) => {
  return {
    value: status,
    labelTextId: getConsentFormItemStatusLabelId(status),
  };
});

export const ConsentFormsFilterPopup: FC<ConsentFormsFilterPopupProps> = ({
  onClose,
  onSetFilters,
  filters: initialFilters,
}) => {
  const { $t } = useIntl();
  const { schoolId } = useSchool();
  const [filters, setFilters] = useState<GetConsentFormQueryFilters>(initialFilters);
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');

  const handleApply = useCallback(() => {
    onSetFilters(filters);
    onClose();
  }, [onSetFilters, filters, onClose]);

  const options = CONSENT_FORM_STATUS_OPTIONS?.filter((p) =>
    p.value.toLowerCase().includes(query.toLowerCase()),
  );

  if (!schoolId) return null;
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: 600,
        },
      }}
      open
      onClose={onClose}
    >
      <ModalHeader active title={$t({ id: 'consentForms-filterTitle' })}>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalContent active>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box sx={{ flex: '1 1 15%' }}>
            <Typography color="common.grey2">{$t({ id: 'schoolProperty-Status' })}</Typography>
          </Box>
          <Box sx={{ flex: '1 1 85%' }}>
            <DropdownSelect
              placeholder={!filters.status?.length ? $t({ id: 'filter-All' }) : undefined}
              hasValues={!!filters.status?.length}
              borderOnHover
              onClear={() => {
                setFilters({ status: [] });
              }}
              renderContent={() => (
                <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
                  {options.map((s) => (
                    <TagSelect
                      key={s.value}
                      label={$t({ id: s.labelTextId })}
                      variant={filters.status?.includes(s.value) ? 'filled' : undefined}
                      onClick={() => {
                        setFilters((f) => ({
                          ...f,
                          status: f.status?.includes(s.value)
                            ? f.status.filter((status) => status !== s.value)
                            : [...(f.status ?? []), s.value],
                        }));
                        setQuery('');
                      }}
                    />
                  ))}
                </Stack>
              )}
            >
              {(opened) => (
                <>
                  {filters.status?.map((s) => (
                    <TagSelect
                      onDelete={() =>
                        setFilters((f) => ({
                          ...f,
                          status: f.status?.filter((status) => status !== s),
                        }))
                      }
                      key={s}
                      label={$t({ id: getConsentFormItemStatusLabelId(s) })}
                    />
                  ))}
                  {opened && (
                    <SelectSearchInput
                      ref={inputRef}
                      autoFocus
                      value={query || ''}
                      onChangeText={setQuery}
                    />
                  )}
                </>
              )}
            </DropdownSelect>
          </Box>
        </Stack>
      </ModalContent>
      <ModalFooter active>
        <Button onClick={handleApply}>{$t({ id: 'filter-Apply' })}</Button>
      </ModalFooter>
    </Dialog>
  );
};
