import { ApiError, PagedResponse } from '@schooly/api';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import IntlError from '../utils/intlError';
import usePrevious from './usePrevious';

export interface GetResourceFunctionParams {
  pageSize?: number;
  pageNumber?: number;
  prevPageNumber?: number;
  query?: string;
  prevQuery?: string;
  originalQuery?: string;
  isInit?: boolean;
  forceUpdate?: boolean;
}

export type GetResourceFunctionResponse<T> = Promise<PagedResponse<T> | undefined> | undefined;

export type GetResourceFunction<T> = (
  params: GetResourceFunctionParams,
) => GetResourceFunctionResponse<T>;

type GenericResourceItem = Record<string, any> & {
  id?: string;
  user_id?: string;
  relation_id?: string;
};

export interface UsePagedApiResourceWithFilter<T extends GenericResourceItem> {
  displayedList?: PagedResponse<T>;
  canShowMore: boolean;
  count?: number;
  totalCount?: number;
  pageNumber: number;
  hasNextPage: boolean;
  isFetching: boolean;
  isSearching: boolean;
  error?: null | ApiError;
  handleShowMore: (lookBack?: boolean) => void;
  handleLocalItemDelete: (itemId: string) => void;
  loadResource: (forceUpdate?: boolean) => void;
}

export const MIN_REMOTE_QUERY_LENGTH = 2;

interface usePagedApiResourceWithFilterParams<T> {
  getResource: GetResourceFunction<T>;
  filter?: string;
  onFilterUpdate?: (value: string) => void;
  lastRefreshTime?: number;
  defaultLoadingValue?: boolean;
  forceUpdate?: boolean;
}

export default function usePagedApiResourceWithFilter<T extends GenericResourceItem>({
  getResource,
  filter = '',
  onFilterUpdate,
  lastRefreshTime,
  defaultLoadingValue = false,
}: usePagedApiResourceWithFilterParams<T>): UsePagedApiResourceWithFilter<T> {
  const isInit = useRef(false);
  const [displayedList, setDisplayedList] = useState<PagedResponse<T> | undefined>();
  const fullListRef = useRef<PagedResponse<T> | undefined>();
  const [filteredList, setFilteredList] = useState<PagedResponse<T> | undefined>();
  const filterRef = useRef<string>(filter);
  const [error, setError] = useState<null | ApiError>();
  const [isFetching, setIsFetching] = useState<boolean>(defaultLoadingValue);
  const [isSearching, setIsSearching] = useState(false);
  const [shouldLookBack, setShouldLookBack] = useState(false);

  const prevCurrentPage = usePrevious<number>(displayedList?.current_page || 1) ?? 1;

  const loadResource = useCallback(
    async (forceUpdate?: boolean) => {
      const prevResult = fullListRef.current;

      setError(undefined);
      setDisplayedList(undefined);
      fullListRef.current = undefined;
      onFilterUpdate?.('');
      setIsFetching(true);

      try {
        const result =
          (await getResource({
            query: filterRef.current,
            prevQuery: filterRef.current,
            originalQuery: filterRef.current,
            isInit: isInit.current,
            forceUpdate,
          })) ?? prevResult;

        if (result) {
          setDisplayedList(result);
          fullListRef.current = result;
        }
        setIsFetching(false);
      } catch (err) {
        if (err) {
          setError(err as ApiError | IntlError);
        }
        setIsFetching(false);
      }

      isInit.current = true;
    },
    [getResource, isInit, onFilterUpdate],
  );

  // Get initial data
  useEffect(() => {
    loadResource();
  }, [loadResource, lastRefreshTime]);

  const searchRemote = useCallback(
    async (originalQuery: string) => {
      const prevResult = fullListRef.current;
      const query = originalQuery.length >= MIN_REMOTE_QUERY_LENGTH ? originalQuery : '';

      try {
        // Check for min query length might prevent `getResource` request in case when both, current
        // and previous queries are less MIN_REMOTE_QUERY_LENGTH. This is an expected behavior as
        // the eventual request will be the same for all such cases (no query param will be sent).
        // In case of a need to force such requests anyway, use originalQuery param
        // in `getResource`.

        const searchResults =
          (await getResource({
            query,
            prevQuery: filterRef.current,
            originalQuery,
            isInit: isInit.current,
          })) ?? prevResult;

        // Only update with remote results if there is something found
        // and query hasn't changed during the request
        if (searchResults) {
          setDisplayedList(searchResults);
        }
      } catch (err) {
        setIsSearching(false);
      }

      filterRef.current = query; // store prev filter value
      setIsSearching(false);
    },
    [getResource],
  );

  const searchRemoteDebounced = useMemo(
    () => debounce(searchRemote, 500, { leading: false, trailing: true }),
    [searchRemote],
  );

  const search = useCallback(async () => {
    setIsSearching(true);
    setError(undefined);
    setDisplayedList(undefined);
    fullListRef.current = undefined;

    await searchRemoteDebounced(filter);
  }, [filter, searchRemoteDebounced]);

  useEffect(() => {
    // compare prev filter value with the current one
    if (filterRef.current === filter) {
      return;
    }

    // no search on fetching
    if (isFetching) {
      return;
    }

    search();
  }, [filter, isFetching, search]);

  /** Silently requests and update previous page data slice */
  const loadPrevPage = useCallback(
    async (prevPage: number) => {
      if (!displayedList) {
        return;
      }

      const { results: currentResults } = displayedList;
      const result = await getResource({
        pageNumber: prevPage,
        query: filterRef.current,
        prevQuery: filterRef.current,
        originalQuery: filterRef.current,
      });

      if (result) {
        const pageSize = result.results.length;
        const offset = pageSize * (prevPage - 1);
        const updatedResults = [...currentResults];
        updatedResults.splice(offset, pageSize, ...result.results);

        const updatedList = {
          ...displayedList,
          results: updatedResults,
        };
        setDisplayedList(updatedList);
        fullListRef.current = updatedList;

        if (prevPage > 1) {
          loadPrevPage(prevPage - 1);
        }
      }
    },
    [displayedList, getResource],
  );

  // Looking back the previous pages in reverse order to update the previously loaded data
  // after `Show More` action
  useEffect(() => {
    const currentPage = displayedList?.current_page ?? 1;

    if (shouldLookBack && currentPage > 1 && currentPage > prevCurrentPage) {
      loadPrevPage(currentPage - 1);
    }
  }, [displayedList, getResource, loadPrevPage, prevCurrentPage, shouldLookBack]);

  // `lookBack` is disabled by default as not needed now.
  // Can be enabled manually in certain places, if needed.
  const handleShowMore = useCallback<UsePagedApiResourceWithFilter<T>['handleShowMore']>(
    async (lookBack = false) => {
      setShouldLookBack(lookBack);

      if (!displayedList || isFetching) {
        return;
      }

      const { results: currentResults, current_page, next_page, total_pages } = displayedList;

      if (current_page === total_pages) {
        return;
      }

      setIsFetching(true);

      const result = await getResource({
        pageNumber: next_page,
        prevPageNumber: current_page,
        query: filterRef.current,
        prevQuery: filterRef.current,
        originalQuery: filterRef.current,
      });

      if (result) {
        const updatedList = {
          ...result,
          results: [...currentResults, ...result.results],
        };
        setDisplayedList(updatedList);
        fullListRef.current = updatedList;
      }

      setIsFetching(false);
    },
    [displayedList, getResource, isFetching],
  );

  const handleLocalItemDelete = useCallback<
    UsePagedApiResourceWithFilter<T>['handleLocalItemDelete']
  >(
    (itemId) => {
      function deleteItemFromList(
        list: PagedResponse<T> | undefined,
        setList: React.Dispatch<React.SetStateAction<PagedResponse<T> | undefined>>,
      ) {
        if (!list) {
          return;
        }

        if (
          list.results.findIndex(
            (item) => (item.relation_id ?? item.user_id ?? item.id) === itemId,
          ) >= 0
        ) {
          setList({
            ...list,
            count: list.count - 1,
            results: list.results.filter(
              (item) => (item.relation_id ?? item.user_id ?? item.id) !== itemId,
            ),
          });
        }
      }

      deleteItemFromList(displayedList, setDisplayedList);
      deleteItemFromList(filteredList, setFilteredList);
    },
    [filteredList, displayedList],
  );

  const canShowMore =
    !filter.length &&
    !!displayedList &&
    !!displayedList.results.length &&
    displayedList.current_page !== displayedList.total_pages;

  const [count, totalCount] = useMemo(() => {
    if (!displayedList) {
      return [];
    }

    return [displayedList.results.length, displayedList.count];
  }, [displayedList]);

  return {
    displayedList,
    canShowMore,
    count,
    totalCount,
    pageNumber: displayedList?.current_page ?? 1,
    hasNextPage: !!displayedList?.next_page,
    isFetching,
    isSearching,
    error,
    handleShowMore,
    handleLocalItemDelete,
    loadResource,
  };
}
