import { Box } from '@mui/material';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import React, { FC } from 'react';

import { DropdownComments } from '../../../../components/uikit-components/DropdownCommentsV2/DropdownComments';
import { useTakeAttendanceModal } from '../useTakeAttendanceModal';
import { StudentInRow } from './TakeAttendanceModalContentGrid';

interface TakeAttendanceGridCommentCellProps {
  props: GridRenderCellParams<GridValidRowModel, StudentInRow>;
  editMode?: boolean;
  handleCommentChange: (relationId: string) => (text: string) => void;
}

export const TakeAttendanceGridCommentCell: FC<TakeAttendanceGridCommentCellProps> = ({
  props,
  editMode,
  handleCommentChange,
}) => {
  const { row } = props;
  const student = row.student;
  const { entries, saving } = useTakeAttendanceModal();

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        minWidth: 40,
        height: 47,

        [theme.breakpoints.down('lg')]: {
          minWidth: 100,
        },
      })}
    >
      {student && (
        <DropdownComments
          onFocus={
            !editMode
              ? (e) => {
                  e.preventDefault();
                }
              : undefined
          }
          autoFocusOnEdit={editMode}
          comments={
            entries?.[student.relation_id]?.text
              ? [
                  {
                    comment: entries[student.relation_id].text!,
                    relation_id: entries[student.relation_id].creator_relation_id!,
                    title: entries[student.relation_id].creator_title,
                    given_name: entries[student.relation_id].creator_given_name,
                    last_name: entries[student.relation_id].creator_last_name!,
                    known_as: entries[student.relation_id].creator_known_as,
                  },
                ]
              : []
          }
          canAdd={!entries?.[student.relation_id]?.text} // if no comment yet
          onAdd={editMode ? handleCommentChange(student.relation_id) : undefined}
          onEdit={editMode ? handleCommentChange(student.relation_id) : undefined}
          disabled={saving}
          canEditOwn
          canEditOther
          tableView
          saveOnEnterPress
          disableEscapeKeyDown
          open={editMode}
        />
      )}
    </Box>
  );
};
