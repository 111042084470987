import React from 'react';

import FilterControl from '../FilterControl';
import { IControl } from '../HeaderFilterPopup';

import '../HeaderFilter.scss';
import './StaffControl.scss';

export interface StaffControlProps {
  control: IControl;
  shouldOpen: boolean;
  isOpen?: boolean;
}

export const StaffControl: React.FC<StaffControlProps> = ({ control, shouldOpen, isOpen }) => {
  return (
    <FilterControl
      className="StaffControl"
      filterKey={control.key}
      titleTextId={control.titleTextId}
      popupTitleTextId={control.popupTitleTextId}
      options={control.options}
      groupOptions={control.groupOptions}
      shouldOpen={shouldOpen}
      isFocusable={isOpen}
      isHeaderFilterPopupOpen={isOpen}
    />
  );
};
