import { IconButton, Stack } from '@mui/material';
import {
  AssessmentForSchool,
  ASSESSMENTS_FILTER_KEYS,
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetAssessmentsQueryFilters,
  GetAssessmentsQuerySort,
  SORT_DIRECTION,
  useGetAssessmentsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  checkHasFiltersApplied,
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import {
  ChartIcon,
  GridBody,
  MainPageGrid,
  PlusIcon,
  SkeletonGridLoader,
  SkeletonRows,
} from '@schooly/style';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { CalendarChartsCustomGrid } from '../../components/common/Charts/CalendarChartContent';
import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import useAppLocation from '../../hooks/useAppLocation';
import useSchoolYears from '../../hooks/useSchoolYears';
import AccessDenied from '../AccessDenied';
import { AssessmentChartGrid } from './AssessmentChartGrid';
import { AssessmentsChart } from './AssessmentsChart';
import { AssessmentsFilters } from './AssessmentsFilters';
import { AssessmentRow, AssessmentsHeader } from './AssessmentsGrid';

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 7;

type AssessmentsContentProps = {
  initialFilters?: GetAssessmentsQueryFilters;
  isChartsOpen?: boolean;
};

const AssessmentsContent: React.FC<AssessmentsContentProps> = ({
  initialFilters,
  isChartsOpen,
}) => {
  const location = useAppLocation();
  const navigate = useNavigate();
  const { schoolId, permissions } = useAuth();
  const { formatMessage } = useIntl();

  const { defaultValidity } = useSchoolYears();
  const { state } = useLocation();

  const recurringIdFromState =
    state && FilterKeys.RecurrenceId in state ? state[FilterKeys.RecurrenceId] : null;

  const defaultFilters: GetAssessmentsQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [
        defaultValidity?.start || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        defaultValidity?.end || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [defaultValidity],
  );

  const { lastAppliedFilter } = useLastAppliedFiltersState({
    type: StoredFilterSections.Assessments,
    filterKeys: ASSESSMENTS_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: ASSESSMENTS_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const [chartOpen, setChartOpen] = useState(Boolean(isChartsOpen));

  const [chartCustomGrid, setChartCustomGrid] =
    useState<CalendarChartsCustomGrid<GetAssessmentsQueryFilters> | null>(null);

  const handleSetCustomGridCount = useCallback((count?: number) => {
    setChartCustomGrid((grid) => (grid ? { ...grid, count } : grid));
  }, []);

  const chartIcon = (
    <IconButton
      onClick={() => {
        setChartOpen(!chartOpen);
        if (chartOpen) setChartCustomGrid(null);
      }}
      inverse={!chartOpen}
    >
      <ChartIcon />
    </IconButton>
  );

  const {
    data,
    isLoading,
    params,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    setParams,
    error,
  } = useGetAssessmentsQuery(
    {
      school_id: schoolId || '',
      query: '',
      page_size: PAGE_SIZE,
      sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
      filters: lastAppliedFilter ?? initialFiltersState,
    },
    { refetchOnMount: 'always', enabled: !chartCustomGrid },
  );

  useSyncFiltersStateWithSearchParams({
    pathname: '/assessments',
    filters: params.filters,
    charts: chartOpen,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Assessments,
    filters: params.filters,
    schoolId: schoolId || '',
  });

  useEffect(() => {
    if (recurringIdFromState) {
      setParams((p) => ({
        ...p,
        filters: { ...p.filters, [FilterKeys.RecurrenceId]: [recurringIdFromState] },
      }));
    }
  }, [recurringIdFromState, setParams, state]);

  const filtersDateString = params.filters.date?.join('');
  const notActualInitialDate =
    !defaultValidity?.isActual &&
    filtersDateString === defaultFilters?.date?.join('') &&
    filtersDateString !== initialFilters?.date?.join('');

  const handleChangeSort = useCallback(
    (sort: GetAssessmentsQuerySort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setChartCustomGrid(null);
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetAssessmentsQueryFilters) => {
      setChartCustomGrid(null);
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const entries = useMemo(
    () =>
      data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as AssessmentForSchool[]) ??
      [],
    [data?.pages],
  );

  const hasFiltersApplied =
    !!params.query?.length || checkHasFiltersApplied(ASSESSMENTS_FILTER_KEYS, params.filters);

  const handleAddButtonClick = useCallback(() => {
    navigate('/assessments/new', {
      state: { backgroundLocation: location },
    });
  }, [location, navigate]);

  const total = chartCustomGrid ? chartCustomGrid.count : data?.pages[0]?.count;

  if (!schoolId) return null;

  if (error) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonTextId="assessments-CreateAssessment"
          pageTitleTextId="section-Assessments"
          showActionButton={permissions.includes('assessment_creator')}
          buttonIcon={<PlusIcon />}
          onButtonClick={handleAddButtonClick}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={formatMessage({ id: 'assessments-Search' })}
          />
        </PageHeader>
        <AssessmentsFilters
          notActualInitialDate={notActualInitialDate}
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          defaultSchoolYear={defaultValidity}
          defaultUserFilters={defaultUserFilters}
        />
        <Stack sx={{ pt: chartOpen ? 3.5 : 0 }}>
          <AssessmentsChart
            chartCustomGrid={chartCustomGrid}
            onOpenCustomGrid={setChartCustomGrid}
            filters={params.filters}
            chartIcon={chartIcon}
            chartOpen={chartOpen}
          />
        </Stack>
      </Stack>
      <MainPageGrid mt={2.4}>
        {chartCustomGrid ? (
          <AssessmentChartGrid
            schoolId={schoolId ?? ''}
            filters={chartCustomGrid.filters}
            onChangeSort={handleChangeSort}
            sort={params.sort}
            onSetTotalCount={handleSetCustomGridCount}
          />
        ) : (
          <>
            <AssessmentsHeader
              chartIcon={!chartOpen ? chartIcon : null}
              sort={params.sort}
              onChangeSort={handleChangeSort}
            />
            <GridBody>
              {entries?.map((entry) => (
                <AssessmentRow
                  assessment={entry}
                  key={entry.id}
                  onAddFilters={(draftFilters) =>
                    handleSetFilters({ ...params.filters, ...draftFilters })
                  }
                />
              ))}
              {isLoading && <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />}
              <SkeletonGridLoader
                isFetching={isLoading || isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                columnsCount={SKELETON_COLS}
                amount={Math.min(
                  PAGE_SIZE,
                  total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                )}
              />
            </GridBody>
          </>
        )}
      </MainPageGrid>

      {!isLoading &&
        !entries.length &&
        (hasFiltersApplied ? (
          <MainGridNoResultsStub textId="assessments-NoMatches" />
        ) : (
          <NoListItemsStub
            titleText={<FormattedMessage id="assessments-YouDoNotHaveAssessmentsYet" />}
            buttonText={<FormattedMessage id="assessments-CreateAssessment" />}
            onButtonClick={handleAddButtonClick}
            type="assessment"
          />
        ))}
    </>
  );
};

export default AssessmentsContent;
