import { Button, IconButtonProps, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ModalEmpty } from './ModalEmpty';

export interface ModalEmptyGroupAreaProps {
  label: string;
  onClick?: IconButtonProps['onClick'];
}

export const ModalEmptyGroupArea: FC<ModalEmptyGroupAreaProps> = ({ label, onClick }) => {
  const { $t } = useIntl();
  const { permissions } = useAuth();
  const isGroupManager = permissions.includes('group_manager');

  return (
    <ModalEmpty width="300px">
      <Typography variant="h3" color={(theme) => theme.palette.common.grey2}>
        <FormattedMessage id="modal-EmptyLabel-3" values={{ label }} />
      </Typography>
      {isGroupManager && (
        <Button onClick={onClick}>
          <FormattedMessage
            id="groups-AddTo"
            values={{
              Group: $t({ id: 'groups-Group-plural' }),
            }}
          />
        </Button>
      )}
    </ModalEmpty>
  );
};
