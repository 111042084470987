import { Button, IconButton } from '@mui/material';
import { CrossIcon, ModalConfirm, ModalFooter, ModalHeader } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { useRouter } from '../../../context/router/useRouter';

interface MergeMembershipErrorModalProps {
  onClose: () => void;
  open: boolean;
  studentName: string;
  studentId?: string;
}

export const MergeMembershipErrorModal: FC<MergeMembershipErrorModalProps> = ({
  open,
  onClose,
  studentName,
  studentId,
}) => {
  const navigate = useNavigate();
  const pathname = `/students/${studentId}`;
  const { clean } = useRouter();

  return (
    <ModalConfirm open={open} onClose={onClose}>
      <ModalHeader
        sx={{ py: 2 }}
        multiline
        active
        title={
          <FormattedMessage
            id="profile-Duplicates-MergeMembershipError"
            values={{
              userName: studentName,
            }}
          />
        }
      >
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalFooter active withBorderTop={false} sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={onClose}>
          <FormattedMessage id="action-Cancel" />
        </Button>
        <Button
          onClick={() => {
            clean();
            navigate({ pathname, hash: '#groups' });
          }}
        >
          <FormattedMessage id="groups-ManageGroups" />
        </Button>
      </ModalFooter>
    </ModalConfirm>
  );
};
