import { AnnualPlanRecordTypes, DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import {
  compareAsc,
  format,
  isAfter,
  isSameDay,
  lastDayOfMonth,
  max,
  min,
  parse,
  setDate,
  subDays,
  subMonths,
} from 'date-fns';
import { useMemo } from 'react';

import { AnnualPlannerRecordMeta } from './scheme';
import { getCellPosition, GetCellPositionProps } from './utils';

export interface UseAnnualPlannerCellProps extends GetCellPositionProps {
  records?: Record<string, AnnualPlannerRecordMeta[]>;
  extraAreas?: boolean;
}

export interface UseAnnualPlannerCellItem
  extends Pick<AnnualPlannerRecordMeta, 'type' | 'start' | 'end'> {
  id: string;
  size: 'narrow' | 'wide';
  /*
   * For narrow records the narrowIndex is a numeric index in the cell, which defines its place in
   * the cells grid. It's basically not necessarily needed in the dat cell itself, but might be
   * very helpful for the records bellow, which this item might overlap
   */
  narrowIndex: number;
  position: Array<ReturnType<typeof getCellPosition>>;
  /*
   * The `start` can be within the current month only.
   * The `realStart` is a real date, which might be out of the month or event out of the calendar
   * period.
   * The same for `end` and `realEnd`.
   */
  realStart: AnnualPlannerRecordMeta['start'];
  realEnd: AnnualPlannerRecordMeta['end'];
  records: AnnualPlannerRecordMeta[];
}

export const getAnnualPlannerRecordWeight = (record: AnnualPlannerRecordMeta) => {
  switch (record.type) {
    case AnnualPlanRecordTypes.SCHOOL_PERIOD:
      return 0;
    case AnnualPlanRecordTypes.EVENT:
      return 1;
    case AnnualPlanRecordTypes.HOLIDAY:
      return 2;
    case AnnualPlanRecordTypes.ASSESSMENT:
      return 3;
    case AnnualPlanRecordTypes.REPORT:
      return 4;
    default:
      return 5;
  }
};

export const getRecordsGroupId = (records: AnnualPlannerRecordMeta[]) =>
  records.map((record) => record.id).join('-');

const GROUPS_CACHE: Record<string, UseAnnualPlannerCellItem[]> = {};

export const getAnnualPlannerCell = ({
  start: propStart,
  end: propEnd,
  date,
  records,
  extraAreas = true,
}: UseAnnualPlannerCellProps) => {
  const start =
    typeof propStart === 'string'
      ? parse(propStart, DEFAULT_DATE_FORMAT_FNS, new Date())
      : propStart;

  const end =
    typeof propEnd === 'string' ? parse(propEnd, DEFAULT_DATE_FORMAT_FNS, new Date()) : propEnd;

  const dateString = format(date, DEFAULT_DATE_FORMAT_FNS);

  const position = getCellPosition({ start, end, date });

  let hasStartItems = false;

  const data = [...(records?.[dateString] ?? [])];

  // unfortunately, have to re-sort the cell by start date once again
  data.sort((a, b) => {
    const adjustedStartA = max([a.start, setDate(a.start, 1), start]);
    const adjustedStartB = max([b.start, setDate(b.start, 1), start]);

    return compareAsc(adjustedStartA, adjustedStartB);
  });

  const groups =
    data.reduce<UseAnnualPlannerCellItem[]>((prev, record) => {
      const adjustedStart = max([record.start, setDate(record.start, 1), start]);
      const adjustedEnd = min([record.end, lastDayOfMonth(record.end), end]);
      const isStart = isSameDay(adjustedStart, date);

      if (isStart) {
        hasStartItems = true;
      }

      if (
        !prev.length ||
        prev[prev.length - 1].type !== record.type ||
        !isSameDay(prev[prev.length - 1].start, adjustedStart) ||
        !isSameDay(prev[prev.length - 1].end, adjustedEnd)
      ) {
        const size = !isStart ? 'narrow' : 'wide';

        let narrowIndex = prev.length ? prev[prev.length - 1].narrowIndex + 1 : 1;

        // look for a previously calculated date to find accurate previous positions which we
        // will rely on
        const prevDate = subDays(date, 1);
        const prevDateString = format(prevDate, DEFAULT_DATE_FORMAT_FNS);

        if (GROUPS_CACHE[prevDateString] && !isSameDay(date, start)) {
          const group = GROUPS_CACHE[prevDateString].find((group) =>
            group.records.some((item) => item.id === record.id),
          );

          if (group) {
            // if there is such record/group on the previous date, use its narrowIndex
            narrowIndex = group.narrowIndex;
          }
        }

        prev.push({
          id: record.id,
          size,
          narrowIndex,
          type: record.type,
          start: adjustedStart,
          realStart: record.start,
          end: adjustedEnd,
          realEnd: record.end,
          position: [{ ...position }],
          records: [],
        });
      }

      const lastGroup = prev[prev.length - 1];
      lastGroup.records.push(record);

      if (!isSameDay(record.start, record.end)) {
        lastGroup.size = 'narrow';
      }

      // Store only info for records, which started on the specified date.
      // Others are needed just to calculate a position.
      if (isStart) {
        lastGroup.id = getRecordsGroupId(lastGroup.records);

        let date = min([lastGroup.realEnd, end]);

        // need to recalculate the bottom position as it depends on the End date, not on the Start date
        const bottomPosition = getCellPosition({
          start,
          end,
          date: isAfter(date, lastDayOfMonth(lastGroup.start))
            ? lastDayOfMonth(lastGroup.start)
            : date,
        });

        lastGroup.position[0].bottom = bottomPosition.bottom;
        lastGroup.position[0].bottomValue = bottomPosition.bottomValue;
        lastGroup.position[0].bottomDateNum = bottomPosition.bottomDateNum;

        if (extraAreas) {
          // calculate additional areas in case if the record starts in one month, but ends in
          // another month
          while (isAfter(date, lastDayOfMonth(lastGroup.start))) {
            const { groups } = getAnnualPlannerCell({
              start,
              end,
              date,
              records,
              extraAreas: false,
            });

            const group = groups.find((group) => group.id === lastGroup.id);

            if (group) {
              // is this is a calculation of the record's end date, need to calculate a position
              // of the 1st day of month manually

              lastGroup.position.splice(1, 0, {
                ...group.position[0],
                top: '0',
                topValue: 0,
                topDateNum: 0,
              });

              if (isAfter(lastGroup.end, date)) {
                const bottomPosition = getCellPosition({
                  start,
                  end,
                  date: lastDayOfMonth(date),
                });

                lastGroup.position[1].bottom = bottomPosition.bottom;
                lastGroup.position[1].bottomValue = bottomPosition.bottomValue;
                lastGroup.position[1].bottomDateNum = bottomPosition.bottomDateNum;
              }
            }

            date = subMonths(date, 1);
          }
        }
      }

      return prev;
    }, []) ?? [];

  GROUPS_CACHE[dateString] = groups;

  return { dateString, position, hasStartItems, groups };
};

export const useAnnualPlannerCell = (props: UseAnnualPlannerCellProps) => {
  return useMemo(() => getAnnualPlannerCell(props), [props]);
};
