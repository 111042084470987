import { Box, Typography } from '@mui/material';
import { SimpleListResult } from '@schooly/api';
import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PersonCardSelectable from '../../../../components/common/PersonCard/PersonCardSelectable';
import { ModalContent } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalEmptyMainArea } from '../../../../components/uikit-components/Modal/ModalEmptyMainArea';
import { SelectedCriteriaList } from '../../../../components/uikit-components/SelectedCriteriaList/SelectedCriteriaList.styled';
import { useMessage } from '../../../../context/messages/useMessage';
import { getDisplayedCriteria } from '../../helpers';

export const MessagesCreateModalRecipientsStaffMain: FC = () => {
  const { formatMessage } = useIntl();
  const { staff, staffCriteria, staffByIndividualStudent, hasStaff, notActualGroups, actions } =
    useMessage();

  const handleStudentClick = useCallback(
    (relationId: string) => {
      const user = staffByIndividualStudent?.find(({ relation_id }) => relation_id === relationId);

      if (!user) {
        return;
      }

      actions.removeStaffByIndividualStudent(user);
    },
    [actions, staffByIndividualStudent],
  );

  const handleStaffClick = useCallback(
    (relationId: string) => {
      const user = staff?.find(({ relation_id }) => relation_id === relationId);

      if (!user) {
        return;
      }

      actions.removeStaff(user);
    },
    [actions, staff],
  );

  const render = () => {
    if (!hasStaff) {
      return <ModalEmptyMainArea label={formatMessage({ id: 'messages-Staff' }).toLowerCase()} />;
    }

    return (
      <>
        {Boolean(staffCriteria?.length || staff?.length) && (
          <SelectedCriteriaList mt={2.5}>
            <Typography variant="h4" sx={{ mb: 1 }}>
              <FormattedMessage id="messages-Staff" />
            </Typography>
            {getDisplayedCriteria({
              selectedCriteria: staffCriteria,
              handleCriteriaClick: actions.removeStaffCriteria,
              handleGroupCriteriaClick: actions.removeStaffCriteriaMultiple,
              criteriaWithErrors: notActualGroups,
            })}
            {staff?.map((user: SimpleListResult) => (
              <PersonCardSelectable
                key={user.relation_id}
                user={user}
                userType="staff"
                isStaticSelected
                onClick={handleStaffClick}
                isListItem
              />
            ))}
          </SelectedCriteriaList>
        )}

        {Boolean(staffByIndividualStudent?.length) && (
          <Box mt={2.5}>
            <Typography variant="h4">
              <FormattedMessage id="messages-StaffOfStudents" />
            </Typography>
            <Box mt={1}>
              {staffByIndividualStudent.map((user: SimpleListResult) => (
                <PersonCardSelectable
                  key={user.relation_id}
                  user={user}
                  userType="student"
                  onClick={handleStudentClick}
                  isStaticSelected
                  isListItem
                />
              ))}
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <ModalContent sx={{ p: 0 }} active>
      {render()}
    </ModalContent>
  );
};
