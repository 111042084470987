import { Box, Card, CardContent, CardHeader, Divider, Stack } from '@mui/material';
import { useGetConductTypesForSchoolQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { DragIcon, Loading, PlusIcon } from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../components/uikit/SimpleButton/SimpleButton';
import { GridContainer } from '../../../components/uikit-components/Grid/Grid';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import AccessDeniedPage from '../../AccessDenied';
import { SchoolConductTypeRow } from './SchoolConductTypeRow';
import { separateTypesByConnotation } from './separateTypesByConnotation';

export const SchoolConduct: FC = () => {
  const navigate = useNavigate();
  const { permissions, schoolId = '' } = useAuth();

  const { data: types } = useGetConductTypesForSchoolQuery(schoolId, {
    refetchOnMount: 'always',
  });

  const onAddClick = useCallback(() => {
    navigate('/settings/conduct/new');
  }, [navigate]);

  const onEditClick = (codeId: string) => {
    navigate(`/settings/conduct/${codeId}`);
  };

  const onChangeOrderClick = useCallback(() => {
    navigate(`/settings/conduct/change-order`);
  }, [navigate]);

  const { positiveConductTypes, negativeConductTypes } = useMemo(
    () => separateTypesByConnotation(types || []),
    [types],
  );

  const canManageConduct =
    permissions.includes('conduct_manager') && permissions.includes('school_admin');

  const hasConductTypes = Boolean(types?.length);

  if (!canManageConduct) {
    return <AccessDeniedPage />;
  }

  const renderContent = () => {
    if (!types) return <Loading />;

    const hasBothTypes = !!positiveConductTypes.length && !!negativeConductTypes.length;

    return (
      <Card>
        <CardHeader
          sx={{ pb: hasConductTypes ? undefined : 2.5 }}
          title={
            <Stack direction="row" alignItems="center">
              <FormattedMessage id="school-section-ConductSettings-ConductTypes" />
              {hasConductTypes && <Counter>{types.length}</Counter>}
            </Stack>
          }
          action={
            <Stack direction="row" gap={5}>
              {types.length > 1 && (
                <SimpleButton startIcon={<DragIcon />} onClick={onChangeOrderClick}>
                  <FormattedMessage id={'changeOrder'} />
                </SimpleButton>
              )}
              <SimpleButton onClick={onAddClick} startIcon={<PlusIcon />}>
                <FormattedMessage id="school-section-ConductSettings-AddConductType" />
              </SimpleButton>
            </Stack>
          }
        />
        {hasConductTypes && (
          <CardContent>
            <Stack gap={2}>
              {!!positiveConductTypes?.length && (
                <GridContainer
                  sx={{
                    borderRadius: 0,
                  }}
                >
                  {positiveConductTypes.map((conductType) => (
                    <SchoolConductTypeRow
                      key={conductType.id}
                      conductType={conductType}
                      onEdit={() => onEditClick(conductType.id)}
                    />
                  ))}
                  {hasBothTypes && <div />}
                </GridContainer>
              )}
              {!!negativeConductTypes?.length && (
                <GridContainer
                  sx={{
                    borderRadius: 0,
                  }}
                >
                  {hasBothTypes && <Divider />}
                  {negativeConductTypes.map((conductType) => (
                    <SchoolConductTypeRow
                      key={conductType.id}
                      conductType={conductType}
                      onEdit={() => onEditClick(conductType.id)}
                    />
                  ))}
                </GridContainer>
              )}
            </Stack>
          </CardContent>
        )}
      </Card>
    );
  };

  return (
    <MainLayout>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="school-section-ConductSettings" />
      </Box>
      {renderContent()}
      <Outlet />
    </MainLayout>
  );
};
