import { Box, Button, MenuItem, Stack, styled, Typography } from '@mui/material';
import {
  ApiError,
  DemoSchoolType,
  useGetDemoSchoolCredentialsMutation,
  usePostFeedbackMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { ControlSelect } from '@schooly/components/form-select';
import { ControlTextField } from '@schooly/components/form-text-field';
import { LanguageSelect } from '@schooly/components/language-select';
import { useNotifications } from '@schooly/components/notifications';
import { LANDING_PROD_URL } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { ArrowRightIcon, Loading, LogoSvg, RocketIcon, Spin, theme } from '@schooly/style';
import { Auth } from 'aws-amplify';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { useSchool } from '../../hooks/useSchool';
import { SchoolCreateSaving } from '../School/SchoolCreate/SchoolCreateSaving';
import { DemoSchoolAppLinks } from './DemoSchoolAppLinks';

interface DemoSchoolCreateForm {
  email: string;
  school_type: DemoSchoolType;
}

export const DemoSchoolCreatePage: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { currentUser } = useAuth();
  const form = useForm<DemoSchoolCreateForm>();
  const navigate = useNavigate();
  const { currentSchool } = useSchool();
  const [isCreatingSchool, startCreating, stopCreating] = useFlag();
  const [isSchoolCreated, setSchoolCreated] = useFlag();

  const hasExistingSchool = !!currentSchool;

  const sendFeedback = usePostFeedbackMutation();
  const getDemoCredentials = useGetDemoSchoolCredentialsMutation();

  const handleSubmit = useCallback<SubmitHandler<DemoSchoolCreateForm>>(
    async ({ email, school_type }) => {
      startCreating();

      try {
        sendFeedback.mutate({ email, source: 'Demo school' });
        const response = await getDemoCredentials.mutateAsync({ school_type });
        await Auth.signIn(response.email, response.password);
        setSchoolCreated();
      } catch (error) {
        // must be cancelled only in case of a error, otherwise the animation would be stopped
        // and no redirect performed
        stopCreating();
        showError(error as ApiError);
      }
    },
    [startCreating, sendFeedback, getDemoCredentials, setSchoolCreated, stopCreating, showError],
  );

  // passing this prop to animation component,
  // would be triggered after all animations completed
  const navigateCallbackOnSchoolCreated = useMemo(() => {
    if (!isSchoolCreated) return;
    return () => window.location.replace('/students/school_created');
  }, [isSchoolCreated]);

  useEffect(() => {
    if (!currentUser || !hasExistingSchool) return;
    navigate('/');
  }, [hasExistingSchool, currentUser, navigate]);

  if (hasExistingSchool) return <Loading />;

  const renderContent = () => {
    if (isCreatingSchool) {
      return <SchoolCreateSaving handleSchoolCreated={navigateCallbackOnSchoolCreated} />;
    }

    return (
      <Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing(4),

            margin: theme.spacing(0, 'auto'),
            padding: theme.spacing(0, 3),
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              '.demo-school-svg': {
                width: '100%',
              },
            },
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(0),
              '.demo-school-svg': {
                width: '200px',
                height: '190px',
              },
            },
          })}
        >
          <DemoSchoolSvg />
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Stack
                sx={(theme) => ({
                  gap: theme.spacing(4),
                  maxWidth: 650,
                })}
              >
                <Stack
                  sx={(theme) => ({
                    gap: theme.spacing(4),
                    [theme.breakpoints.down('sm')]: {
                      display: 'none',
                    },
                  })}
                >
                  <Typography variant="h1" sx={{ fontSize: 40, lineHeight: '54px' }}>
                    {$t({ id: 'school-EnterEmailToStart' })}
                  </Typography>

                  <ControlTextField
                    name="email"
                    control={form.control}
                    label={$t({
                      id: 'school-email',
                    })}
                    rules={{
                      required: true,
                    }}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root > *': {
                        zIndex: 1,
                      },

                      '& .MuiOutlinedInput-notchedOutline': {
                        bgcolor: 'background.paper',
                        zIndex: 0,
                      },
                    }}
                  />

                  <Typography variant="h2" color="text.primary" maxWidth={500}>
                    {$t({ id: 'school-ItWillHaveDemo' })}
                  </Typography>
                  <Typography variant="h2" color="text.primary" maxWidth={500}>
                    {$t({ id: 'school-HoweverTheDemoSchool' })}
                  </Typography>

                  <Stack direction="row" gap={2}>
                    <Box flex={1}>
                      <ControlSelect
                        name="school_type"
                        label={$t({ id: 'school-demo-TypeOfSchool' })}
                        defaultValue={DemoSchoolType.UK}
                        rules={{ required: true }}
                        renderValue={(value) => (
                          <FormattedMessage
                            id={`school-demo-schoolType-${DemoSchoolType[value as DemoSchoolType]}`}
                          />
                        )}
                        MenuProps={{
                          ...theme.components?.MuiSelect?.defaultProps?.MenuProps,
                          disableAutoFocusItem: true,
                        }}
                        fullWidth
                        sx={{ bgcolor: 'background.paper' }}
                      >
                        {Object.values(DemoSchoolType).map((value) => (
                          <MenuItem
                            value={value}
                            sx={{
                              '&.Mui-selected.Mui-focusVisible': {
                                bgcolor: 'background.default',
                              },
                            }}
                          >
                            <FormattedMessage id={`school-demo-schoolType-${value}`} />
                          </MenuItem>
                        ))}
                      </ControlSelect>
                    </Box>

                    <Button
                      type="submit"
                      disabled={isCreatingSchool}
                      endIcon={isCreatingSchool ? <Spin /> : <RocketIcon />}
                    >
                      {$t({ id: 'school-LetsGo' })}
                    </Button>
                  </Stack>
                </Stack>
                <Stack
                  sx={(theme) => ({
                    gap: theme.spacing(2),
                    [theme.breakpoints.up('sm')]: {
                      display: 'none',
                    },
                  })}
                >
                  <Typography
                    variant="h1"
                    sx={{ fontWeight: '500', fontSize: 30, lineHeight: '32px' }}
                  >
                    {$t({ id: 'school-demo-BetterExperience' })}
                    <br />
                    {$t({ id: 'school-demo-onABiggerScreen' })}
                  </Typography>
                  <Typography variant="h3" color="text.primary">
                    {$t({ id: 'school-demo-ThisFeatureIsTo' })}
                  </Typography>
                  <Button
                    endIcon={<ArrowRightIcon />}
                    onClick={() =>
                      navigator.share({
                        url: window.location.href,
                        title: $t({ id: 'school-StartADemoSchool' }),
                        text: $t({ id: 'school-YouAreGoingToRunADemoSchool' }),
                      })
                    }
                  >
                    {$t({ id: 'school-demo-SendALink' })}
                  </Button>
                  <Typography variant="h3" color="text.primary" mt={3}>
                    {$t({ id: 'school-demo-PSParentsHaveApp' })}
                  </Typography>
                  <DemoSchoolAppLinks />
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    );
  };

  return (
    <DemoSchoolCreateLayout>
      <DemoSchoolCreateHeader>
        <Link to={LANDING_PROD_URL}>
          <LogoSvg />
        </Link>
        <LanguageSelect />
      </DemoSchoolCreateHeader>
      {renderContent()}
    </DemoSchoolCreateLayout>
  );
};

const DemoSchoolCreateHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  position: 'fixed',
  top: theme.spacing(5),
  padding: theme.spacing(0, 5),

  [theme.breakpoints.down('md')]: {
    position: 'relative',
    top: 0,
    padding: 0,
    marginBottom: theme.spacing(1),
  },
}));

const DemoSchoolCreateLayout = styled(Stack)(({ theme }) => ({
  position: 'relative',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(5),
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
    padding: theme.spacing(3),
  },
}));

const DemoSchoolSvg = () => (
  <svg
    className="demo-school-svg"
    width="400"
    height="294"
    viewBox="0 0 400 294"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M250.369 265.191L210.833 293.341L145.913 268.039L185.553 240.891L250.369 265.191Z"
      fill="#27245C"
      stroke="#C7C8DD"
      strokeLinejoin="round"
    />
    <path
      d="M236.773 208.825L210.411 293.341L145.913 267.472L172.327 171.617L236.773 208.812V208.825Z"
      fill="#C7C8DD"
      stroke="#C7C8DD"
      strokeLinejoin="round"
    />
    <path
      d="M208.235 45.8045L207.774 45.6199V45.5804L97.4624 1.6874C88.0863 -2.04397 77.8926 4.86499 77.8926 14.9647V184.973L208.789 237.054L339.145 288.923V114.591C339.145 104.504 333 95.4462 323.624 91.7148L208.248 45.8045H208.235Z"
      fill="#F7F8FC"
      stroke="#C7C8DD"
      strokeLinejoin="round"
    />
    <path
      d="M222.417 35.5869V35.6265L222.879 35.8111H222.892L338.267 81.7214C347.644 85.4527 353.789 94.5109 353.789 104.597V133.895"
      stroke="#24275B"
      strokeLinejoin="round"
    />
    <path
      d="M208.275 60.9674L207.853 60.796V60.7565L109.846 21.7683C101.511 18.4589 92.4648 24.5899 92.4648 33.5557V176.35L208.75 222.616L324.547 268.698V122.067C324.547 113.114 319.088 105.058 310.753 101.736L208.249 60.9543L208.275 60.9674Z"
      fill="white"
      stroke="#C7C8DD"
      strokeMiterlimit="10"
    />
    <path
      d="M93.5938 228.385C112.785 206.948 114.502 177.183 97.4292 161.905C80.3564 146.626 50.9587 151.619 31.7676 173.057C12.5765 194.495 10.8593 224.26 27.9322 239.538C45.005 254.816 74.4027 249.823 93.5938 228.385Z"
      fill="#27245C"
    />
    <path
      d="M44.1207 213.532L86.5045 195.587C87.6254 195.112 88.5221 195.64 88.5221 196.747V208.851C88.5221 209.972 87.6122 211.251 86.5045 211.725L44.1207 229.67C42.9998 230.145 42.103 229.617 42.103 228.51V216.406C42.103 215.285 43.0129 214.006 44.1207 213.532Z"
      fill="white"
      stroke="#252858"
    />
    <path
      d="M50.1733 197.538L80.4512 184.722V214.296L50.1733 227.112V197.538Z"
      fill="white"
      stroke="#252858"
    />
    <path
      d="M60.2617 208.706C60.2617 207.585 61.1716 206.306 62.2794 205.832L68.3323 203.274C69.4532 202.799 70.35 203.327 70.35 204.434V218.555L60.2617 222.827V208.706Z"
      fill="white"
      stroke="#252858"
    />
    <path
      d="M79.0404 186.634L82.7988 185.039C84.8824 184.155 85.5945 181.097 83.7878 180.846L65.3125 178.328L46.8372 196.497C45.0173 198.277 45.7426 200.742 47.8262 199.859L50.8329 198.58"
      fill="white"
    />
    <path
      d="M79.0404 186.634L82.7988 185.039C84.8824 184.155 85.5945 181.097 83.7878 180.846L65.3125 178.328L46.8372 196.497C45.0173 198.277 45.7426 200.742 47.8262 199.859L50.8329 198.58"
      stroke="#252858"
    />
    <path
      d="M67.3302 191.592C67.3302 192.713 66.4203 193.992 65.3126 194.466C64.2048 194.941 63.2949 194.413 63.2949 193.306C63.2949 192.198 64.2048 190.906 65.3126 190.432C66.4203 189.957 67.3302 190.484 67.3302 191.592Z"
      fill="white"
    />
    <path
      d="M67.8637 194.498C69.597 192.36 69.8645 189.704 68.4612 188.567C67.0579 187.43 64.5152 188.242 62.7819 190.381C61.0487 192.519 60.7812 195.175 62.1845 196.312C63.5877 197.449 66.1304 196.637 67.8637 194.498Z"
      fill="white"
      stroke="#252858"
    />
    <path
      d="M256.105 159.552L255.657 159.381V159.341L150.805 117.624C141.89 114.077 132.211 120.643 132.211 130.242V178.262C132.211 186.99 137.539 194.848 145.649 198.079L256.619 242.235L361.735 284.058C370.742 287.645 380.514 281.013 380.514 271.322V224.937C380.514 215.351 374.672 206.741 365.757 203.194L256.092 159.565L256.105 159.552Z"
      fill="#F7F8FC"
    />
    <path
      d="M180.948 158.095C187.82 165.771 188.459 176.092 182.758 181.194C177.057 186.296 166.867 184.521 159.996 176.845C153.124 169.169 152.486 158.848 158.187 153.746C163.888 148.645 174.077 150.419 180.948 158.095Z"
      fill="#FFFEF8"
      stroke="#24275B"
    />
    <path
      d="M288.161 197.638C288.161 197.638 240.503 196.156 249.829 186.804C255.847 180.731 265.106 181.715 268.387 183.382C271.668 185.049 263.541 192.842 238.772 184.27C214.015 175.697 242.203 169.127 245.346 171.494C248.488 173.848 240.684 183.952 211.868 169.827"
      stroke="#252858"
      strokeMiterlimit="10"
    />
    <path
      d="M295.303 230.608C295.303 230.608 261.458 230.626 258.556 226.42C255.642 222.296 270.327 224.593 268.419 220.475C266.523 216.356 238.977 218.828 235.383 213.756C231.775 208.685 247.928 212.138 241.109 207.391C234.291 202.645 213.793 203.486 207.912 197.472"
      stroke="#252858"
      strokeMiterlimit="10"
    />
    <path
      d="M366.825 287.671C364.755 287.671 362.724 287.276 360.772 286.498L144.686 200.518C135.508 196.866 129.574 188.124 129.574 178.249V130.229C129.574 121.276 136.84 113.998 145.781 113.998C147.825 113.998 149.856 114.394 151.794 115.158L258.308 157.535V157.575L366.746 200.716C376.716 204.685 383.164 214.178 383.164 224.911V271.296C383.164 275.752 381.41 279.905 378.232 283.004C375.186 285.957 371.032 287.658 366.825 287.658V287.671Z"
      stroke="#27245C"
      strokeMiterlimit="10"
    />
    <path
      d="M106.944 46.9915L106.733 46.9124L57.7295 27.3986C53.5623 25.7373 49.0391 28.8094 49.0391 33.2923V55.7332C49.0391 59.8206 51.5315 63.486 55.3162 64.9891L107.182 85.6237L157.148 105.507C160.946 107.023 165.074 104.215 165.074 100.127V77.5413C165.074 73.0584 162.344 69.0369 158.177 67.3756L106.931 46.9784L106.944 46.9915Z"
      fill="#C7C8DD"
    />
    <path d="M61.5337 44.8164L114.415 65.8597" stroke="white" strokeMiterlimit="10" />
    <path d="M61.5337 55.3647L87.9082 65.9128" stroke="white" strokeMiterlimit="10" />
    <path
      d="M161.756 161.038L169.669 171.393L193.406 152.934"
      stroke="#252858"
      strokeLinecap="round"
    />
  </svg>
);
