import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import {
  DEFAULT_DATE_FORMAT_FNS,
  Event,
  EVENTS_FILTER_KEYS,
  FilterKeys,
  GetEventsQueryFilters,
  GetEventsQuerySort,
  SORT_DIRECTION,
  useGetEventsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  PageHeaderSearchInput,
  StoredFilterSections,
  useFiltersStateFromSearchParams,
  useInvalidateListQueriesFor,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import {
  ChartIcon,
  GridBody,
  MainPageGrid,
  SkeletonGridLoader,
  SkeletonRows,
} from '@schooly/style';
import { useSelectIds } from '@schooly/utils/bulk-actions';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { CalendarChartsCustomGrid } from '../../components/common/Charts/CalendarChartContent';
import { normalizeEvent } from '../../context/events/WithEvent';
import useSchoolYears from '../../hooks/useSchoolYears';
import AccessDenied from '../AccessDenied';
import { EventsAndSignUpsPageHeader } from '../EventsAndSignUpsPageHeader/EventsAndSignUpsPageHeader';
import { EventsBulkActions } from './EventsBulkActions';
import { EventsChart } from './EventsChart';
import { EventsChartGrid } from './EventsChartGrid';
import { EventsFilters } from './EventsFilters';
import { EventRow, EventsHeader } from './EventsGrid';

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 6;

interface EventsContentProps {
  initialFilters?: GetEventsQueryFilters;
  isChartsOpen?: boolean;
}

export const EventsContent: FC<EventsContentProps> = ({ initialFilters, isChartsOpen }) => {
  const { schoolId } = useAuth();
  const { $t } = useIntl();
  const { defaultValidity } = useSchoolYears();
  const { selectedIds, toggleSelectAll, toggleSelectId, clearSelectedIds, toggleSelectedIds } =
    useSelectIds();
  const invalidateQueries = useInvalidateListQueriesFor('event');
  const { state } = useLocation();
  const recurringIdFromState =
    state && FilterKeys.RecurrenceId in state ? state[FilterKeys.RecurrenceId] : null;

  const defaultFilters: GetEventsQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [
        defaultValidity?.start || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        defaultValidity?.end || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [defaultValidity?.end, defaultValidity?.start],
  );

  const { lastAppliedFilter } = useLastAppliedFiltersState({
    type: StoredFilterSections.Events,
    filterKeys: EVENTS_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: EVENTS_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const [chartOpen, setChartOpen] = useState(Boolean(isChartsOpen));

  const [chartCustomGrid, setChartCustomGrid] =
    useState<CalendarChartsCustomGrid<GetEventsQueryFilters> | null>(null);

  const handleSetCustomGridCount = useCallback((count?: number) => {
    setChartCustomGrid((grid) => (grid ? { ...grid, count } : grid));
  }, []);

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    params,
    error,
    setParams,
    fetchNextPage,
  } = useGetEventsQuery(
    {
      query: '',
      schoolId: schoolId || '',
      filters: lastAppliedFilter ?? initialFiltersState,
      pageSize: PAGE_SIZE,
      sort: { columnTextId: 'title', direction: SORT_DIRECTION.ASC },
    },
    { refetchOnMount: 'always', enabled: !chartCustomGrid },
  );

  const filtersDateString = params.filters.date?.join('');
  const notActualInitialDate =
    !defaultValidity?.isActual &&
    filtersDateString === defaultFilters?.date?.join('') &&
    filtersDateString !== initialFilters?.date?.join('');

  const total = chartCustomGrid ? chartCustomGrid.count : data?.pages[0]?.count;

  useSyncFiltersStateWithSearchParams({
    pathname: '/events',
    filters: params.filters,
    charts: chartOpen,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Events,
    filters: params.filters,
    schoolId: schoolId || '',
  });

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setChartCustomGrid(null);
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetEventsQueryFilters) => {
      setChartCustomGrid(null);
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const events = useMemo(
    () =>
      data?.pages.reduce<Event[]>(
        (prev, curr) => [...prev, ...curr.results.map((e) => normalizeEvent(e))],
        [],
      ) ?? [],
    [data?.pages],
  );

  const noResults = !isLoading && !events.length;
  const showCharts = chartOpen && !noResults;

  const chartIcon = (
    <IconButton
      onClick={() => {
        setChartOpen(!chartOpen);
        if (chartOpen) setChartCustomGrid(null);
      }}
      inverse={!chartOpen}
      disabled={noResults}
    >
      <ChartIcon />
    </IconButton>
  );

  const handleChangeSort = useCallback(
    (sort: GetEventsQuerySort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  const handleSelectRow = useCallback(
    (id: string) => {
      if (selectedIds === 'all') {
        toggleSelectedIds(
          events.reduce<string[]>((acc, next) => (next.id === id ? acc : [...acc, next.id]), []),
        );
      } else {
        toggleSelectId(id);
      }
    },
    [events, selectedIds, toggleSelectId, toggleSelectedIds],
  );

  const handleBulkActionsClear = useCallback(() => {
    clearSelectedIds();
    invalidateQueries();
  }, [clearSelectedIds, invalidateQueries]);

  useEffect(() => {
    if (recurringIdFromState) {
      setParams((p) => ({
        ...p,
        filters: { ...p.filters, [FilterKeys.RecurrenceId]: [recurringIdFromState] },
      }));
    }
  }, [recurringIdFromState, setParams, state]);

  if (!schoolId) return null;

  if (error) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack gap={1}>
        <EventsAndSignUpsPageHeader value="events">
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={$t(
              { id: 'people-SearchAmongType' },
              {
                userTypePlural: $t({
                  id: 'section-Events',
                }).toLowerCase(),
              },
            )}
          />
        </EventsAndSignUpsPageHeader>
        <EventsFilters
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          notActualInitialDate={notActualInitialDate}
          defaultSchoolYear={defaultValidity}
          defaultUserFilters={defaultUserFilters}
        />
        <Stack sx={{ pt: showCharts ? 3.5 : 0 }}>
          <EventsChart
            chartCustomGrid={chartCustomGrid}
            onOpenCustomGrid={setChartCustomGrid}
            filters={params.filters}
            chartIcon={chartIcon}
            chartOpen={showCharts}
          />
        </Stack>
      </Stack>
      <MainPageGrid
        mt={2.4}
        bottomElement={
          <EventsBulkActions
            filters={params.filters}
            query={params.query}
            schoolId={schoolId}
            onClear={handleBulkActionsClear}
            selectedIds={selectedIds}
          />
        }
      >
        {chartCustomGrid ? (
          <EventsChartGrid
            schoolId={schoolId}
            filters={chartCustomGrid.filters}
            onChangeSort={handleChangeSort}
            sort={params.sort}
            onSetTotalCount={handleSetCustomGridCount}
            onToggleSelectAll={toggleSelectAll}
            onToggleSelectId={handleSelectRow}
            isSelectedAll={selectedIds === 'all'}
            selectedIds={selectedIds}
          />
        ) : (
          <>
            <EventsHeader
              sort={params.sort}
              rightIcon={!chartOpen || !showCharts ? chartIcon : null}
              onSelectAll={!!total ? toggleSelectAll : undefined}
              isSelectedAll={selectedIds === 'all'}
            />
            {events?.map((event) => (
              <EventRow
                event={event}
                key={event.id}
                onSelect={handleSelectRow}
                isSelected={selectedIds === 'all' || selectedIds.has(event.id)}
                onAddFilters={(draftFilters) =>
                  handleSetFilters({ ...params.filters, ...draftFilters })
                }
              />
            ))}
            {isLoading && (
              <GridBody>
                <SkeletonRows columnsCount={7} amount={PAGE_SIZE} />
              </GridBody>
            )}

            {hasNextPage && (
              <GridBody>
                <SkeletonGridLoader
                  isFetching={isLoading || isFetchingNextPage}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  columnsCount={7}
                  amount={Math.min(
                    PAGE_SIZE,
                    total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                  )}
                />
              </GridBody>
            )}
          </>
        )}
      </MainPageGrid>
      {!isLoading && !events.length && <MainGridNoResultsStub textId="events-NoMatches" />}
    </>
  );
};
