import { Button, Stack, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { useFlag } from '@schooly/hooks/use-flag';
import { Spin } from '@schooly/style';
import { openJiraWidgetForRequest } from '@schooly/utils/open-jira-widget-for-request';
import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import SchoolIncompleteImg from '../../../assets/images/school_incomplete.svg';
import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';

export const SchoolDataImport: FC = () => {
  const { $t } = useIntl();
  const [searchParams] = useSearchParams();
  const { currentUser, currentSchool } = useAuth();
  const [isLoadingWidget, startLoading, endLoading] = useFlag(false);

  const handleClick = useCallback(async () => {
    startLoading();
    await openJiraWidgetForRequest('Data import', {
      summary: 'Data import',
      description: currentSchool?.name,
      email: currentUser?.account_email,
    });
    endLoading();
  }, [currentSchool?.name, currentUser?.account_email, endLoading, startLoading]);

  useEffect(() => {
    if (searchParams.has('submit')) {
      setTimeout(handleClick, 1000);
    }
  }, [handleClick, searchParams]);

  return (
    <MainLayout>
      <Header pageTitleTextId="school-sections-DataImport" />

      <Stack justifyContent="center" alignItems="center" flex={1}>
        <img
          width={300}
          height={300}
          src={SchoolIncompleteImg}
          alt={$t({ id: 'school-sections-DataImport' })}
        />

        <Typography variant="h1" mt={2.5} textAlign="center" whiteSpace="pre-wrap">
          <FormattedMessage id="school-sections-DataImport-message" />
        </Typography>

        <Typography
          variant="h3"
          color="text.primary"
          mt={1}
          textAlign="center"
          whiteSpace="pre-wrap"
        >
          <FormattedMessage id="school-sections-DataImport-submessage" />
        </Typography>

        <Button
          sx={{ mt: 3 }}
          onClick={handleClick}
          disabled={isLoadingWidget}
          startIcon={isLoadingWidget ? <Spin /> : undefined}
        >
          <FormattedMessage id="action-SubmitRequest" />
        </Button>
      </Stack>
    </MainLayout>
  );
};
