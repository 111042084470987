import { Box, Button, Icon, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useGetSchoolQuery } from '@schooly/api';
import { ControlSelect } from '@schooly/components/form-select';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { AREA_CODE_OPTIONS, VALID_EMAIL_REGEXP, VALID_URL_REGEXP } from '@schooly/constants';
import { CheckIcon, CrossIcon, ImageFormatIcon, ImageSizeIcon, Spin } from '@schooly/style';
import { getCountryFromPhoneNumber, getFormattedPhoneNumber } from '@schooly/utils/phone-number';
import React, { FC, useCallback, useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ControlSchoolLogo } from '../../../components/uikit-components/FormSchoolLogo/ControlSchoolLogo';
import { FormSchoolLogoParam } from '../../../components/uikit-components/FormSchoolLogo/FormSchoolLogoField';
import {
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalMain,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { SchoolAvatar } from '../../../components/uikit-components/Sidebar/SchoolSelect/SchoolAvatar';
import { useRouter } from '../../../context/router/useRouter';
import { removeDataPrefix } from '../../../hooks/useConvertFileToBuffer';
import { useSchool } from '../../../hooks/useSchool';
import { SchoolSettingsLayout } from '../SchoolSettingsLayout';

export interface SchoolInfoForm {
  logo?: File;
  description: string;
  email: string;
  website: string;
  phoneCode: string;
  telephone: string;
}

const prepareLogo = (file?: File) =>
  new Promise<{ mime_type: string; data: string } | undefined>((res) => {
    if (!file) {
      res(undefined);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      if (!reader.result) {
        res(undefined);
        return;
      }

      res({
        mime_type: file.type,
        data: removeDataPrefix(reader.result as string),
      });
    };
  });

export const SchoolGeneralInformationModal: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { goBack } = useRouter();

  const { schoolId = '', updateSchoolMutation } = useSchool();
  const { data: currentSchool } = useGetSchoolQuery(schoolId, { enabled: !!schoolId });
  const form = useForm<SchoolInfoForm>();

  useEffect(() => {
    if (!currentSchool) return;

    form.setValue('description', currentSchool.description || '');
    form.setValue('email', currentSchool.contact_email || '');
    form.setValue('website', currentSchool.website || '');

    if (currentSchool.telephone) {
      form.setValue('phoneCode', getCountryFromPhoneNumber(currentSchool.telephone)?.code || '');
      form.setValue('telephone', getFormattedPhoneNumber(currentSchool.telephone));
    }

    form.setValue('website', currentSchool.website || '');
  }, [currentSchool, form]);

  const handleClose = useCallback(() => {
    if (updateSchoolMutation.isLoading) {
      return;
    }

    goBack();
  }, [goBack, updateSchoolMutation.isLoading]);

  const handleSubmit = useCallback<SubmitHandler<SchoolInfoForm>>(
    async ({ logo, description, email, telephone, website, phoneCode }) => {
      if (!currentSchool) {
        return;
      }

      if (!logo && !currentSchool.image) {
        form.setError('logo', { type: 'required' });
        return;
      }

      const preparedLogo = await prepareLogo(logo);

      updateSchoolMutation.mutate(
        {
          school_id: currentSchool.id,
          description,
          contact_email: email,
          telephone: phoneCode + telephone,
          website,
          ...(preparedLogo ? { school_logo: preparedLogo } : {}),
        },
        {
          onSuccess: () => {
            goBack();
          },
          onError: showError,
        },
      );
    },
    [currentSchool, updateSchoolMutation, showError, form, goBack],
  );

  return (
    <SchoolSettingsLayout>
      <ModalConfirm open fullWidth onClose={handleClose}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader title={currentSchool?.name} active>
              <IconButton
                sx={updateSchoolMutation.isLoading ? { visibility: 'hidden' } : undefined}
                onClick={handleClose}
              >
                <CrossIcon />
              </IconButton>
            </ModalHeader>
            <ModalMain>
              <ModalContent active>
                <Stack gap={4}>
                  <Stack direction="row" justifyContent="center">
                    <ControlSchoolLogo
                      width={300}
                      content={
                        <>
                          {currentSchool && (
                            <Box
                              position="absolute"
                              width={300}
                              height={300}
                              left={0}
                              top={0}
                              sx={{ opacity: 0.25 }}
                            >
                              <SchoolAvatar lightSkeleton school={currentSchool} />
                            </Box>
                          )}
                          <Stack
                            alignItems="center"
                            position="relative"
                            mt={2}
                            justifyContent="center"
                          >
                            <Typography color="inherit" variant="h3">
                              <FormattedMessage id="dnd-Image" />
                            </Typography>
                            <Typography color="inherit">
                              <FormattedMessage
                                id="dnd-BrowseAFile"
                                values={{
                                  browse: (
                                    <Typography component="span" color="primary">
                                      <FormattedMessage id="dnd-Browse" />
                                    </Typography>
                                  ),
                                }}
                              />
                            </Typography>
                          </Stack>

                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            position="relative"
                            gap={1}
                          >
                            <FormSchoolLogoParam>
                              <Icon fontSize="small">
                                <ImageSizeIcon />
                              </Icon>
                              <FormattedMessage id="peopleDetail-MaxSize" />
                            </FormSchoolLogoParam>
                            <FormSchoolLogoParam>
                              <Icon fontSize="small">
                                <ImageFormatIcon />
                              </Icon>
                              <FormattedMessage id="peopleDetail-ImageTypes" />
                            </FormSchoolLogoParam>
                          </Stack>
                        </>
                      }
                      name="logo"
                      control={form.control}
                    />
                  </Stack>
                  <ControlTextField
                    name="description"
                    control={form.control}
                    label={$t({ id: 'groups-GroupDescription' })}
                    canClear
                    fullWidth
                    multiline
                    rows={4}
                    rules={{
                      required: true,
                    }}
                    sx={(theme) => ({
                      '.MuiInputAdornment-root': {
                        marginTop: `${theme.spacing(0.5)} !important`,
                      },
                    })}
                  />
                  <Stack gap={2}>
                    <ControlTextField
                      name="email"
                      control={form.control}
                      label={$t({ id: 'school-email' })}
                      canClear
                      fullWidth
                      //TODO: Refactor email validation - TR-4353
                      rules={{
                        required: true,
                        pattern: {
                          value: VALID_EMAIL_REGEXP,
                          message: $t({
                            id: 'input-ErrorInvalidEmail',
                          }),
                        },
                      }}
                    />
                    <ControlTextField
                      name="website"
                      control={form.control}
                      label="Website"
                      canClear
                      fullWidth
                      rules={{
                        required: true,
                        pattern: {
                          value: VALID_URL_REGEXP,
                          message: $t({
                            id: 'input-ErrorInvalidUrl',
                          }),
                        },
                      }}
                    />
                    <Stack direction="row" gap={1.25}>
                      <Box
                        flex={1}
                        sx={(theme) => ({
                          '.form-select-search': {
                            paddingLeft: theme.spacing(1.75),
                          },
                          '.form-select-value-wrapper': {
                            left: theme.spacing(1.5),
                          },
                          '.form-select-arrow, .form-select-remove': {
                            right: theme.spacing(1.5),
                          },
                          '.form-group__label': {
                            left: theme.spacing(1.5),
                          },
                        })}
                      >
                        <ControlSelect
                          name="phoneCode"
                          label={$t({ id: 'AreaCode' })}
                          rules={{ required: true }}
                          noRequiredLabel
                          fullWidth
                        >
                          {AREA_CODE_OPTIONS.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{ maxWidth: 158, whiteSpace: 'normal' }}
                            >
                              {$t({ id: option.labelTextId })}
                            </MenuItem>
                          ))}
                        </ControlSelect>
                      </Box>
                      <Box flex={2}>
                        <ControlTextField
                          name="telephone"
                          control={form.control}
                          label={$t({ id: 'peopleDetail-PhoneNumber' })}
                          rules={{ required: true }}
                          canClear
                          fullWidth
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </ModalContent>
            </ModalMain>
            <ModalFooter active>
              <Button
                variant="outlined"
                disabled={updateSchoolMutation.isLoading}
                onClick={handleClose}
              >
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button
                type="submit"
                disabled={updateSchoolMutation.isLoading || !currentSchool}
                endIcon={updateSchoolMutation.isLoading ? <Spin /> : <CheckIcon />}
              >
                <FormattedMessage id="action-Save" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalConfirm>
    </SchoolSettingsLayout>
  );
};
