import { Box } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

import logo from '../../assets/images/schooly-logo.svg';

export interface SchoolSettingsLayoutProps extends PropsWithChildren {
  withLogo?: boolean;
}

export const SchoolSettingsLayout: FC<SchoolSettingsLayoutProps> = ({
  withLogo = true,
  children,
}) => {
  return (
    <Box
      sx={{
        width: '100vw',
        minHeight: '100vh',
        pl: `${40 * 2 + 110}px`,
        boxSizing: 'border-box',

        ...(withLogo && {
          background: `url(${logo}) no-repeat`,
          backgroundSize: '110px 28px',
          backgroundPosition: '40px 43px',
        }),
      }}
    >
      {children}
    </Box>
  );
};
