import { Avatar, Box, Icon, Skeleton, Stack, Typography } from '@mui/material';
import {
  Company,
  SORT_DIRECTION,
  useGetCompaniesListQuery,
  useGetCompanyQuery,
} from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ArrowDownV2Icon,
  CompanyIcon,
  PlusIcon,
  SimpleButton,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { CompanySelectRow } from './CompanySelectRow';

type CompanySelectProps = {
  schoolId: string;
  selectedId?: string;
  onSelectCompanyId: (v: string) => void;
  onClear: (companyName?: string) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent' | 'onClear'>;

export const CompanySelect: FC<CompanySelectProps> = ({
  schoolId,
  selectedId,
  onSelectCompanyId,
  placeholder,
  onClear,
  ...dropdownProps
}) => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const dropdown = useRef<DropdownSelect | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [opened, open, close] = useFlag();

  const { data, params, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading, setParams } =
    useGetCompaniesListQuery(
      {
        schoolId,
        query: '',
        sort: { columnTextId: 'student_count', direction: SORT_DIRECTION.DESC },
      },
      { refetchOnMount: 'always', enabled: queryEnabled },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const entries = useMemo(
    () => data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Company[]) ?? [],
    [data?.pages],
  );

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    if (!entries.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <Box p={1}>
        {entries.map((company) => (
          <CompanySelectRow
            onClick={(id) => {
              onSelectCompanyId(id);
              handleChangeQuery('');
              dropdown.current?.close();
            }}
            key={company.id}
            company={company}
            isSelected={company.id === selectedId}
          />
        ))}
        <Stack alignItems="flex-start">
          <SimpleButton startIcon={<PlusIcon />} onClick={() => navigate('/companies/new')}>
            {$t({ id: 'profile-AddNewCompany' })}
          </SimpleButton>
        </Stack>
      </Box>
    );
  }, [$t, data, entries, handleChangeQuery, navigate, onSelectCompanyId, selectedId]);

  const renderInput = useCallback(() => {
    if (!selectedId) {
      return opened ? (
        <SelectSearchInput
          ref={inputRef}
          autoFocus
          value={params.query || ''}
          onChangeText={handleChangeQuery}
        />
      ) : (
        <SimpleButton startIcon={<PlusIcon />}>
          {$t({ id: 'profile-AddPayingCompany' })}
        </SimpleButton>
      );
    }

    return (
      <CompanyValue
        companyId={selectedId}
        avatar={
          opened ? undefined : (
            <Avatar>
              <Icon fontSize="small" sx={{ color: 'primary.main' }}>
                <CompanyIcon />
              </Icon>
            </Avatar>
          )
        }
      />
    );
  }, [$t, handleChangeQuery, opened, params.query, selectedId]);

  const renderRightIcon = useCallback(() => {
    if (!opened && !selectedId) return <></>;

    return (
      <Icon
        sx={(theme) => ({
          width: theme.spacing(1),
          transform: 'rotate(180deg)',
          path: { stroke: theme.palette.common.grey },
        })}
      >
        <ArrowDownV2Icon />
      </Icon>
    );
  }, [opened, selectedId]);

  return (
    <DropdownSelect
      {...dropdownProps}
      onClear={() => onClear(entries.find((e) => e.id === selectedId)?.name)}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={opened ? placeholder : null}
      hasValues={!!selectedId}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      onFetchNextPage={fetchNextPage}
      onOpen={open}
      onClose={close}
      renderRightIcon={opened ? undefined : renderRightIcon}
      sx={(theme) => ({
        borderColor: opened ? undefined : 'transparent',
        outlineColor: 'transparent !important',
        '&.MuiStack-root': {
          display: opened ? 'flex' : 'inline-block',
          transition: 'all .2s, border-color 0s',
        },
        ' .right-icon': {
          right: opened ? undefined : theme.spacing(2.5),
        },
      })}
    >
      {renderInput}
    </DropdownSelect>
  );
};

type CompanyValueProps = {
  companyId: string;
  avatar: ReactNode;
};

const CompanyValue: FC<CompanyValueProps> = ({ companyId, avatar }) => {
  const { data } = useGetCompanyQuery(companyId, {
    refetchOnMount: 'always',
  });

  if (!data)
    return (
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {avatar && (
          <Box minWidth={30} height={30} my={-1}>
            <Skeleton variant="circular" />
          </Box>
        )}
        <Typography width={180} variant="h3">
          <Skeleton variant="text" />
        </Typography>
      </Stack>
    );

  return (
    <Stack flexDirection="row" gap={1} alignItems="center">
      {avatar}
      <TypographyWithOverflowHint variant="h3">{data.name}</TypographyWithOverflowHint>
    </Stack>
  );
};
