import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C5 2.44772 5.44772 2 6 2C6.55228 2 7 2.44772 7 3H13C13 2.44772 13.4477 2 14 2C14.5523 2 15 2.44772 15 3H16C16.5523 3 17 3.44772 17 4V6H14H3V4C3 3.44772 3.44772 3 4 3H5ZM13 11C13 11.5523 13.4477 12 14 12C14.5523 12 15 11.5523 15 11V7H17V16C17 16.5523 16.5523 17 16 17H7L7 10C7 9.44772 6.55228 9 6 9C5.44772 9 5 9.44772 5 10L5 17H4C3.44772 17 3 16.5523 3 16V7H13V11ZM10 8C10.5523 8 11 8.44772 11 9L11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14L9 9C9 8.44772 9.44772 8 10 8ZM14 13C14.5523 13 15 13.4477 15 14V15C15 15.5523 14.5523 16 14 16C13.4477 16 13 15.5523 13 15V14C13 13.4477 13.4477 13 14 13Z"
      fill="#8E90AD"
    />
  </svg>
);
