import { Box, Stack, Typography } from '@mui/material';
import { UserRoleAccessType, UserRoleCategory } from '@schooly/api';
import { USER_ROLE_CATEGORY_OPTIONS, USER_ROLE_TYPE_OPTIONS } from '@schooly/constants';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Grid,
  GridBody,
  GridCell,
  GridCellProps,
  GridHead,
  GridRow,
} from '../../../../components/uikit-components/Grid/Grid';
import { getUserPermissionScope } from '../../../../helpers/userRoles';
import { PermissionGridCell } from '../UserRoleModalPermissions.styled';
import { UserRolePermissionsCheckbox } from './UserRolePermissionsCheckbox';

interface UserRolePermissionsProps {
  editable?: boolean;
  cellProps?: GridCellProps;
  onSelectPermission: (category: UserRoleCategory, type: UserRoleAccessType) => void;
  isPermissionChecked: (
    type: UserRoleAccessType,
    category: UserRoleCategory,
    i: number,
  ) => { checked: boolean; complexChecked: boolean };
}

const userRolePermissionsEmptyField = {
  type: '',
  labelTextId: '',
};

export const UserRolePermissions: FC<UserRolePermissionsProps> = ({
  editable = true,
  cellProps,
  isPermissionChecked,
  onSelectPermission,
}) => {
  return (
    <Grid stickyHeader sx={{ overflow: 'scroll', borderCollapse: 'collapse' }}>
      <GridHead borderBottom>
        <GridRow>
          {[userRolePermissionsEmptyField, ...USER_ROLE_TYPE_OPTIONS].map(
            ({ type, labelTextId }) => (
              <GridCell key={`th-${type}`} sticky noVerticalPadding height={44}>
                <Stack alignItems="center">
                  {labelTextId && (
                    <Typography color="text.primary">
                      <FormattedMessage id={labelTextId} />
                    </Typography>
                  )}
                </Stack>
              </GridCell>
            ),
          )}
        </GridRow>
      </GridHead>
      <GridBody>
        {USER_ROLE_CATEGORY_OPTIONS.map(({ category, labelTextId }) => {
          return (
            <GridRow key={category}>
              <GridCell
                borderBottom
                borderRight
                noVerticalPadding
                width={276}
                height={44}
                {...cellProps}
              >
                <Box>
                  <Typography variant="h3" color="text.primary">
                    <FormattedMessage id={labelTextId} />
                  </Typography>
                </Box>
              </GridCell>
              {USER_ROLE_TYPE_OPTIONS?.map(({ type }, i) => {
                const isEmpty =
                  !getUserPermissionScope(category, type).length && type !== 'NoAccess';

                const { checked, complexChecked } = isPermissionChecked(type, category, i);

                return (
                  <PermissionGridCell
                    key={`td-${category}-${type}-${i}`}
                    borderBottom
                    sx={{ minWidth: 120 }}
                    empty={isEmpty}
                    noVerticalPadding
                    {...cellProps}
                  >
                    {!isEmpty ? (
                      <UserRolePermissionsCheckbox
                        editable={editable}
                        checked={!!checked}
                        onCheckboxChange={() => onSelectPermission(category, type)}
                        complexChecked={!!complexChecked}
                      />
                    ) : null}
                  </PermissionGridCell>
                );
              })}
            </GridRow>
          );
        })}
      </GridBody>
    </Grid>
  );
};
