import { IContextAction } from '@schooly/api';
import React, { FC, MouseEvent } from 'react';

import { ContextMenu } from '../../../components/common/ContextMenu/ContextMenu';

export interface AddParentContextMenuProps {
  actions: IContextAction[];
  arrowOffset?: number[];
  children: (onClick: (event: MouseEvent<HTMLElement>) => void) => React.ReactNode;
}

export const AddParentContextMenu: FC<AddParentContextMenuProps> = ({
  actions,
  children,
  arrowOffset,
}) => {
  return (
    <ContextMenu
      styles={{
        '& .MuiList-root': {
          padding: 0,
        },
        '& .MuiMenuItem-root': {
          width: '200px',
          height: '26px',
          '&:hover': {
            '& .MuiTypography-h3': {
              color: 'primary.main',
            },
          },
          '& .MuiTypography-h3': {
            color: 'text.primary',
          },
        },
      }}
      arrowOffset={arrowOffset}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      actions={actions}
    >
      {children}
    </ContextMenu>
  );
};
