import { Stack } from '@mui/material';
import { AssessmentGroup } from '@schooly/api';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { ModalContent } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalEmptyMainArea } from '../../../../components/uikit-components/Modal/ModalEmptyMainArea';
import AssessmentsCreateModalGroupsItem from './AssessmentsCreateModalGroupsItem';

type AssessmentsCreateModalGroupsMainProps = {
  onCardClick: (group: AssessmentGroup) => void;
  selectedGroups: AssessmentGroup[];
};

export const AssessmentsCreateModalGroupsMain: FC<AssessmentsCreateModalGroupsMainProps> = ({
  onCardClick,
  selectedGroups,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ModalContent sx={{ pt: 0 }} active>
      {selectedGroups?.length ? (
        <Stack gap={1}>
          {selectedGroups?.map((g) => {
            return (
              <AssessmentsCreateModalGroupsItem
                key={g.id}
                onClick={() => onCardClick(g)}
                isSelected
                group={g}
              />
            );
          })}
        </Stack>
      ) : (
        <ModalEmptyMainArea label={formatMessage({ id: 'assessments-Group' }).toLowerCase()} />
      )}
    </ModalContent>
  );
};
