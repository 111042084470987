import { IconButton, Stack } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { CrossIcon } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useFilters } from '../../../context/filters/useFilters';
import { getIsOnlyDateFilterApplied } from '../../../context/filters/utils';
import buildClassName from '../../../utils/buildClassName';
import { FilterFullTags } from './FilterFullTags';
import { FilterInlineTags } from './FilterInlineTags';

export interface HeaderFilterBottomProps {
  onTagClick: (key?: FilterKeys) => void;
  inline?: boolean;
  inlineMax?: number;
  isBig?: boolean;
  isLoading?: boolean;
}

export const HeaderFilterBottom: FC<HeaderFilterBottomProps> = ({
  onTagClick,
  inline,
  inlineMax,
  isBig,
  isLoading,
}) => {
  const { appliedGroupFiltersArray, appliedFiltersArray, appliedArrangeByOption, clearFilters } =
    useFilters();

  const { $t } = useIntl();

  const isOnlyDateFilterApplied =
    getIsOnlyDateFilterApplied(appliedFiltersArray) && !appliedArrangeByOption;

  const handleClearClick = useCallback(() => {
    clearFilters(true, true);
  }, [clearFilters]);

  if (appliedGroupFiltersArray.length < 1) {
    return null;
  }

  return (
    <div
      className={buildClassName(
        'HeaderFilter__bottom',
        inline && 'HeaderFilter__bottom-inline',
        isLoading && 'HeaderFilter__bottom-loading',
      )}
    >
      <Stack direction="row" gap={1}>
        {inline ? (
          <FilterInlineTags
            filters={appliedGroupFiltersArray}
            max={inlineMax}
            isBig={isBig}
            onTagClick={onTagClick}
          />
        ) : (
          <FilterFullTags filters={appliedGroupFiltersArray} onTagClick={onTagClick} />
        )}

        {!isOnlyDateFilterApplied && (
          <IconButton title={$t({ id: 'filter-Clear' })} inverse onClick={handleClearClick}>
            <CrossIcon />
          </IconButton>
        )}
      </Stack>
    </div>
  );
};
