import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C5.44772 2 5 2.44772 5 3H4C3.44772 3 3 3.44772 3 4V6H17V4C17 3.44772 16.5523 3 16 3H15C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3H7C7 2.44772 6.55228 2 6 2ZM3 7H17V16C17 16.5523 16.5523 17 16 17H4C3.44772 17 3 16.5523 3 16V7ZM7 11C7.55228 11 8 10.5523 8 10C8 9.44772 7.55228 9 7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11Z"
      fill="#24275B"
    />
  </svg>
);
