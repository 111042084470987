import { addAttendanceEntries, GET_ATTENDANCE_ENTRIES_FOR_DATE_QUERY } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { AttendanceEntriesForDate, AttendanceEntryForGroup } from '@schooly/api';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import { UPDATE_MAIN_LIST } from '../../../constants/events';
import { useAttendanceForGroup } from '../../../context/attendance/useAttendanceForGroup';
import {
  RouterStateContext,
  RouterStateContextProps,
} from '../../../context/router/RouterStateContext';
import { useRouter } from '../../../context/router/useRouter';
import { FormError, FormState, useForm } from '../../../hooks/useForm';
import usePrevious from '../../../hooks/usePrevious';
import { queryClient } from '../../../queryClient';
import IntlError from '../../../utils/intlError';

export interface TakeAttendanceModalContextState
  extends FormState<TakeAttendanceModalContextState> {
  query: string;
  entries: Record<string, AttendanceEntryForGroup>;
  saving: boolean;
}

export const CONTEXT_NAME = 'TakeAttendanceModal';

export const registryToEntriesMap = (registry?: AttendanceEntriesForDate) =>
  registry?.relations.reduce<TakeAttendanceModalContextState['entries']>((prev, entry) => {
    prev[entry.relation_id] = { ...entry };
    return prev;
  }, {}) ?? {};

export const getInitialState = (
  registry?: AttendanceEntriesForDate,
): TakeAttendanceModalContextState => ({
  query: '',
  entries: registryToEntriesMap(registry),
  saving: false,
});

/**
 * @param core Should be set once in a central modal component.
 *             The core instance cares about context initialization and other singleton operations.
 */
export const useTakeAttendanceModal = (core?: boolean) => {
  const { showError, showNotification } = useNotifications();
  const { goBack } = useRouter();
  const invalidateListQueries = useInvalidateListQueriesFor('attendanceList');

  const { state, setState, contextName, setContextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<TakeAttendanceModalContextState>;

  const {
    entries: groupRegister,
    entriesMap,
    groupDetails,
    fetching: attendanceFetching,
    canTakeAttendance,
    canEditAttendance,
  } = useAttendanceForGroup({ refetchOnMount: core ? 'always' : false });

  const prevGroupRegister = usePrevious(groupRegister);

  const isModified = useMemo(
    () =>
      JSON.stringify(
        groupRegister?.relations
          .map((entry) => ({
            relation_id: entry.relation_id,
            attendance_code_id: entry.attendance_code_id,
            text: entry.text,
          }))
          .sort((a, b) => (a.relation_id > b.relation_id ? 1 : -1)) ?? [],
      ) !==
      JSON.stringify(
        state?.entries
          ? Object.values(state?.entries)
              .map((entry) => ({
                relation_id: entry.relation_id,
                attendance_code_id: entry.attendance_code_id,
                text: entry.text,
              }))
              .sort((a, b) => (a.relation_id > b.relation_id ? 1 : -1))
          : [],
      ),
    [groupRegister?.relations, state?.entries],
  );

  const form = useForm({
    core,
    state,
    setState,
    rules: {
      entries: {
        custom: (state) => {
          let result: true | FormError = true;

          // Grade with no select list
          const emptyStudents =
            groupDetails?.students?.reduce<Record<string, boolean>>((prev, student) => {
              if (!state?.entries?.[student.user.relation_id]?.attendance_code_id) {
                prev[student.user.relation_id] = true;
              }
              return prev;
            }, {}) ?? {};

          if (Object.keys(emptyStudents)?.length) {
            result = {
              id: 'attendance-NotAllStudentStatusesSet',
              values: { emptyStudents },
            } as unknown as FormError;
          }

          return result;
        },
      },
    },
  });

  const setQuery = useCallback(
    (query: string) => {
      setState((state) => ({
        ...(state as TakeAttendanceModalContextState),
        query,
      }));
    },
    [setState],
  );

  const setEntries = useCallback(
    (entries: TakeAttendanceModalContextState['entries']) => {
      setState((state) => ({ ...(state as TakeAttendanceModalContextState), entries }));
    },
    [setState],
  );

  const setEntry = useCallback(
    (relationId: string, entry?: AttendanceEntryForGroup) => {
      setState((s) => {
        const state = s as TakeAttendanceModalContextState;
        if (entry) {
          // Add entry

          return { ...state, entries: { ...state.entries, [relationId]: entry } };
        } else {
          // Remove entry
          const entries = Object.keys(state.entries).reduce<
            TakeAttendanceModalContextState['entries']
          >((prev, key) => {
            if (relationId !== key) {
              prev[key] = state.entries[key];
            }

            return prev;
          }, {});

          return { ...state, entries };
        }
      });
    },
    [setState],
  );

  const saveAttendance = useCallback(async () => {
    if (!form.isValid) {
      return;
    }

    if (!groupDetails?.id) {
      return;
    }

    if (!groupRegister?.register_id) {
      showError(new IntlError('attendance-NoRegisterToSaveEntries'));
      return;
    }

    setState((state) => ({ ...(state as TakeAttendanceModalContextState), saving: true }));

    try {
      const response = await addAttendanceEntries(groupDetails.id, {
        register_id: groupRegister.register_id,
        relations: Object.values(state.entries).map((entry) => {
          const result: AttendanceEntryForGroup = {
            relation_id: entry.relation_id,
            attendance_code_id: entry.attendance_code_id,
          };

          if (
            entriesMap?.[entry.relation_id]?.text &&
            entriesMap[entry.relation_id].text !== entry?.text
          ) {
            // change comment or delete
            result.text = entry?.text || null;
          } else if (entry.text) {
            // set new comment
            result.text = entry.text;
          }

          return result;
        }),
      });

      document.dispatchEvent(new Event(UPDATE_MAIN_LIST));

      showNotification({ message: response.success, type: 'success' });
      invalidateListQueries();
      queryClient.invalidateQueries([GET_ATTENDANCE_ENTRIES_FOR_DATE_QUERY]);
      goBack();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      showError(err as ApiError);
    }

    setState((state) => ({
      ...(state as TakeAttendanceModalContextState),
      saving: false,
    }));
  }, [
    form.isValid,
    groupDetails?.id,
    groupRegister?.register_id,
    setState,
    showError,
    state?.entries,
    showNotification,
    invalidateListQueries,
    goBack,
    entriesMap,
  ]);

  /* Set initial state */
  useEffect(() => {
    if (!core) {
      return;
    }

    // set initial state on Modal
    if (!state || contextName !== CONTEXT_NAME) {
      setContextName(CONTEXT_NAME);
      setState(getInitialState(groupRegister));
    }
  }, [contextName, core, groupRegister, setContextName, setState, state]);

  /* update entries on registry change */
  useEffect(() => {
    if (!core) {
      return;
    }

    if (JSON.stringify(prevGroupRegister) !== JSON.stringify(groupRegister)) {
      setEntries(registryToEntriesMap(groupRegister));
    }
  }, [core, groupRegister, prevGroupRegister, setEntries]);

  return {
    state,
    form,
    isModified,
    groupDetails,
    attendanceFetching,
    canEditAttendance,
    canTakeAttendance,
    saving: state?.saving,
    errors: state?.touched ? state?.errors : {},
    touched: state?.touched,
    query: state?.query,
    entries: state?.entries,
    actions: {
      setQuery,
      setEntry,
      setEntries,
      saveAttendance,
    },
  };
};
