import { Button, Icon, IconButton, Typography } from '@mui/material';
import {
  ApplicationChildForm,
  ApplicationFormChild,
  ApplicationPreviewContent,
} from '@schooly/components/applications';
import { useNotifications } from '@schooly/components/notifications';
import { DELAY_BEFORE_HIDE_ICON_DONE } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ArrowRightIcon,
  CancelIcon,
  ConvertedIcon,
  EditIcon,
  Loading,
  ModalFooter,
} from '@schooly/style';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { RejectApplicationForm } from '../../../context/applications/ApplicationsContext';
import { useApplication } from '../../../context/applications/useApplication';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { ConvertedChip, RejectedChip } from './ApplicationPreview.styled';
import { ApplicationRejectModal } from './ApplicationRejectModal';

interface ApplicationPreviewContainerProps {
  onEditClick: () => void;
}

export const ApplicationPreviewContainer: FC<ApplicationPreviewContainerProps> = ({
  onEditClick,
}) => {
  const {
    updateApplicationChildNotes,
    updating,
    rejectApplication,
    application,
    isApplicationManager,
    fetching,
    canEdit,
  } = useApplication();
  const { applicationChildCustomFields } = useCustomFields();

  const { $t } = useIntl();
  const navigate = useNavigate();
  const [isRejectDialogOpen, showRejectDialog, hideRejectDialog] = useFlag();
  const location = useLocation();

  const [showSuccessIcon, setShowSuccessIcon] = useState<Record<string, boolean>>({});

  const { showNotification } = useNotifications();

  const handleSubmit = useCallback<SubmitHandler<ApplicationChildForm>>(
    async (_, e) => {
      const event = e?.nativeEvent;
      if (event instanceof SubmitEvent) {
        const convert = Boolean(event.submitter?.hasAttribute('data-convert'));
        const reject = Boolean(event.submitter?.hasAttribute('data-reject'));

        if (convert) {
          navigate('convert', { state: location.state });
        } else if (reject) {
          showRejectDialog();
        }
      }
    },
    [navigate, location.state, showRejectDialog],
  );

  const handleReject = useCallback(
    async (data: RejectApplicationForm) => {
      if (isRejectDialogOpen) {
        hideRejectDialog();
      }

      const id = await rejectApplication(data);

      if (id) {
        navigate('/applications');
        showNotification({
          textId: 'applications-Status-RejectedDescription',
          type: 'success',
          actions: [
            {
              textId: 'applications-ViewApplication',
              handler: () => navigate(`/applications/${id}`),
              buttonColor: 'light',
            },
          ],
        });
      }
    },
    [isRejectDialogOpen, rejectApplication, navigate, showNotification, hideRejectDialog],
  );

  const handleNoteChange = useCallback(
    async (child: ApplicationFormChild) => {
      const isNoteChanged = application?.children.some(
        ({ id, notes }) => id === child.originId && notes !== child.notes,
      );

      if (!isNoteChanged) {
        return;
      }

      const { originId, ...rest } = child;

      const applicationChild = { ...rest, id: originId };

      const childId = await updateApplicationChildNotes(applicationChild);

      if (childId) {
        setShowSuccessIcon((old) => ({ ...old, [applicationChild.id]: true }));
        setTimeout(
          () => setShowSuccessIcon((old) => ({ ...old, [applicationChild.id]: false })),
          DELAY_BEFORE_HIDE_ICON_DONE,
        );
      }
    },
    [application?.children, updateApplicationChildNotes],
  );

  if (fetching || !application) {
    return <Loading />;
  }

  return (
    <>
      <ApplicationPreviewContent
        application={application}
        updating={updating}
        showSuccessIcon={showSuccessIcon}
        withNotes
        headerTitle={$t({
          id: 'applications-Application',
        })}
        onClose={() => navigate('/applications')}
        onSubmit={handleSubmit}
        canEditNotes={isApplicationManager}
        applicationCustomFields={applicationChildCustomFields}
        footerActions={
          isApplicationManager &&
          application?.status === 'open' && (
            <ModalFooter active sx={{ justifyContent: 'space-between' }}>
              <Button type="submit" data-reject variant="outlined">
                <FormattedMessage id="applications-Action-Reject" />
              </Button>

              <Button
                type="submit"
                data-convert
                endIcon={<ArrowRightIcon />}
                data-test-id="application-convert-button"
              >
                <FormattedMessage id="applications-Action-Convert" />
              </Button>
            </ModalFooter>
          )
        }
        onNoteChange={handleNoteChange}
        withoutActionBackground
        headerContent={
          <>
            {canEdit && application?.status === 'open' && (
              <IconButton onClick={onEditClick} data-test-id="application-edit-button">
                <EditIcon />
              </IconButton>
            )}
            {application?.status === 'rejected' && (
              <RejectedChip
                avatar={
                  <Icon
                    sx={(theme) => ({
                      color: `${theme.palette.background.default} !important`,
                      pt: 0.25,
                      pl: 0.5,
                    })}
                  >
                    <CancelIcon />
                  </Icon>
                }
                label={
                  <Typography variant="h3" color="inherit">
                    <FormattedMessage id="applications-Status-RejectedDescription" />
                  </Typography>
                }
              />
            )}
            {application?.status === 'converted' && (
              <ConvertedChip
                avatar={
                  <Icon
                    sx={(theme) => ({
                      color: `${theme.palette.background.default} !important`,
                      pt: 0.25,
                      pl: 0.5,
                    })}
                  >
                    <ConvertedIcon />
                  </Icon>
                }
                label={
                  <Typography variant="h3" color="inherit">
                    <FormattedMessage id="applications-Status-ConvertedDescription" />
                  </Typography>
                }
              />
            )}
          </>
        }
      />

      <ApplicationRejectModal
        open={isRejectDialogOpen}
        onClose={hideRejectDialog}
        onReject={handleReject}
      />
    </>
  );
};
