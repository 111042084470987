import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 20 20"
  >
    <defs>
      <polygon
        id="up_arrow-a"
        points="10 6.527 1.321 15.206 0 13.885 10 3.885 20 13.885 18.679 15.206"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="up_arrow-b">
        <use xlinkHref="#up_arrow-a" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#up_arrow-a" />
      <g mask="url(#up_arrow-b)">
        <rect width="20" height="20" />
      </g>
    </g>
  </svg>
);
