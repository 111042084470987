import Joi from 'joi';

/**
 * Helper schema to represent how `react-hook-form` stores `fieldArray` values
 *
 * @param itemSchema Schema of the field value (no nested objects allowed!)
 * @param propertyName If used with custom `fieldArray`, defines value property name
 */
export default function fieldArray(itemSchema: Joi.Schema, propertyName = 'value') {
  return Joi.array()
    .items(Joi.object({ [propertyName]: itemSchema }))
    .sparse();
}
