import { IconButton, Stack } from '@mui/material';
import { EditIcon, theme } from '@schooly/style';
import { FC, PropsWithChildren, useMemo } from 'react';

import { ApplicationPreviewRow } from './ApplicationPreviewRow';

interface ApplicationPreviewInfoRowProps extends PropsWithChildren {
  onEdit?: () => void;
  editable?: boolean;
  editIconName?: string;
  updating?: boolean;
  updatingIcon?: React.ReactNode;
  readonlyIcon?: React.ReactNode;
  withoutActionBackground?: boolean;
}

export const ApplicationPreviewInfoRow: FC<ApplicationPreviewInfoRowProps> = ({
  editable,
  onEdit,
  editIconName,
  children,
  updating,
  updatingIcon,
  readonlyIcon,
  withoutActionBackground,
}) => {
  const editIcon = useMemo(() => {
    if (updating) {
      return updatingIcon;
    }

    return (
      <Stack>
        <IconButton className={editIconName} inverse sx={{ visibility: 'hidden' }}>
          {!editable ? readonlyIcon : <EditIcon />}
        </IconButton>
      </Stack>
    );
  }, [editIconName, editable, readonlyIcon, updating, updatingIcon]);

  return (
    <ApplicationPreviewRow
      onClick={onEdit}
      sxProps={{
        ...(withoutActionBackground && {
          '&:hover': {
            background: theme.palette.background.paper,
            '& .MuiTypography-root': {
              color: `${theme.palette.common.grey2} !important`,
            },
          },
        }),
        ...(editable && { cursor: 'pointer' }),
        '& :hover': {
          [`.${editIconName}`]: {
            visibility: 'visible',
          },
        },
      }}
    >
      {children}
      {editIcon}
    </ApplicationPreviewRow>
  );
};
