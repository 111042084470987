import { GridContainer, Loading } from '@schooly/style';
import { FC } from 'react';

import { useSchoolPeriodsUpdate } from './SchoolPeriodsUpdate/useSchoolPeriodsUpdate';
import { SchoolYearRow } from './SchoolYearRow';

export const SchoolPeriodsContent: FC = () => {
  const { isLoading, schoolYears } = useSchoolPeriodsUpdate();

  return (
    <GridContainer
      sx={{
        borderRadius: 0,
        paddingTop: 0.75,
      }}
    >
      {isLoading || !schoolYears ? (
        <Loading />
      ) : (
        schoolYears.map((y) => <SchoolYearRow key={y.id} schoolYear={y} />)
      )}
    </GridContainer>
  );
};
