import { Menu, Stack, styled } from '@mui/material';

export const SchoolSelectStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: theme.spacing(5.5),
  width: '100%',
  cursor: 'pointer',

  '& .MuiIcon-root': {
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },

  '&:hover .MuiIcon-root': {
    color: theme.palette.text.primary,
  },
}));

export interface SchoolSelectItemStyledProps {
  subitem?: boolean;
}

export const SchoolSelectItemStyled = styled(Stack)<SchoolSelectItemStyledProps>(
  ({ theme, subitem }) => ({
    flexDirection: 'row',
    gap: theme.spacing(1),
    color: theme.palette.primary.main,
    cursor: 'pointer',
    overflow: 'hidden',

    ...(subitem && {
      gap: theme.spacing(1.5),
      paddingLeft: theme.spacing(0.75),
      color: theme.palette.text.primary,

      '& .MuiAvatar-root': {
        width: 30,
        height: 30,
      },

      '.MuiMenuItem-root:hover &, .MuiMenuItem-root.Mui-focusVisible &': {
        color: theme.palette.primary.main,
      },
    }),
  }),
);

export const SchoolSelectMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 300,
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },

  '& .MuiList-root': {
    padding: theme.spacing(0, 0, 1),
  },

  '& .MuiMenuItem-root': {
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1, 1),
    margin: 0,

    '&.Mui-focusVisible': {
      backgroundColor: 'unset',
    },
  },

  '& .MuiDivider-root': {
    margin: theme.spacing(2, 2, 1.5),
  },
}));

SchoolSelectMenu.defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};
