import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  SelectProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { LockIcon } from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import get from 'lodash.get';
import React, { FC, useCallback } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form-lts';
import { FieldValues } from 'react-hook-form-lts/dist/types';
import { FormattedMessage, useIntl } from 'react-intl';

import { SingleSelect } from './SingleSelect';

export type ControlSelectProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> &
  SelectProps & {
    locked?: boolean;
    lockHint?: React.ReactNode;
    noRequiredLabel?: boolean;
  };

export const ControlSelect = <TFieldValues extends FieldValues = FieldValues>({
  control: propsControl,
  locked,
  lockHint,
  noRequiredLabel,
  ...props
}: ControlSelectProps<TFieldValues>) => {
  const { $t } = useIntl();
  const { control: contextControl, formState } = useFormContext<TFieldValues>();
  const control = propsControl ?? contextControl;

  const LockEndAdornment = useCallback<FC>(() => {
    if (!locked) {
      return null;
    }

    if (!lockHint) {
      return (
        <IconButton
          sx={{
            position: 'absolute',
            top: 'calc(50% - 0.5em)',
            right: 11,
            zIndex: (theme) => theme.zIndex.tooltip,
          }}
          inverse
        >
          <LockIcon />
        </IconButton>
      );
    }

    return (
      <Tooltip title={lockHint}>
        <IconButton
          sx={{
            position: 'absolute',
            top: 'calc(50% - 0.5em)',
            right: 11,
            zIndex: (theme) => theme.zIndex.tooltip,
          }}
          inverse
        >
          <LockIcon />
        </IconButton>
      </Tooltip>
    );
  }, [lockHint, locked]);

  return (
    <Controller
      control={control}
      render={({ field }) => {
        const error = get(formState.errors, field.name);
        const hasError = Boolean(error);
        const errorMessage = getControllerErrorText<TFieldValues>(error, props.rules, $t);

        return (
          <FormControl error={hasError} fullWidth={props.fullWidth}>
            <SingleSelect
              key={field.value ?? 'empty'}
              IconComponent={locked ? LockEndAdornment : undefined}
              error={hasError}
              {...props}
              {...props.rules}
              inputRef={field.ref}
              required={undefined}
              {...field}
              disabled={locked}
            />

            {!noRequiredLabel && Boolean(props.rules?.required) && !field.value && (
              <Box
                position="absolute"
                sx={{
                  top: 11,
                  right: 40,
                  zIndex: (theme) => theme.zIndex.tooltip,
                }}
              >
                <Typography
                  color="text.secondary"
                  variant="h3"
                  sx={{
                    fontStyle: 'italic',
                  }}
                >
                  <FormattedMessage id="input-required" />
                </Typography>
              </Box>
            )}

            {hasError && (
              <FormHelperText>
                <Typography variant="body2">{errorMessage}</Typography>
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
      {...props}
    />
  );
};
