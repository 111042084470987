import { InboxMessage } from '@schooly/api';
import React from 'react';

import InboxItemCard from './InboxItemCard';

import './index.scss';

interface IProps {
  messages: InboxMessage[];
  onCardClick: (message: InboxMessage) => void;
}

const InboxMessagesList: React.FC<IProps> = ({ messages, onCardClick }) => (
  <>
    {messages
      .sort((m1, m2) => Number(m2.publish_date) - Number(m1.publish_date))
      .map((message) => (
        <InboxItemCard onClick={() => onCardClick(message)} key={message.id} message={message} />
      ))}
  </>
);

export default InboxMessagesList;
