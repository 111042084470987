import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import { AttendanceCode, SimpleListResult } from '@schooly/api';
import { DropdownIcon } from '@schooly/style';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Dropdown } from '../../../../components/uikit-components/Dropdown/Dropdown';
import { TakeAttendanceModalContextState, useTakeAttendanceModal } from '../useTakeAttendanceModal';

interface TakeAttendanceGridMarkBlankHeaderCellProps {
  students: SimpleListResult[];
  codes?: AttendanceCode[];
}

export const TakeAttendanceGridMarkBlankHeaderCell: FC<TakeAttendanceGridMarkBlankHeaderCellProps> =
  ({ students, codes }) => {
    const { formatMessage } = useIntl();
    const [markAsCode, setMarkAsCode] = useState<string | undefined>(codes?.[0]?.id);
    const { actions, saving, entries } = useTakeAttendanceModal();

    useEffect(() => {
      if (!markAsCode && codes?.length) {
        setMarkAsCode(codes[0].id);
      }
    }, [codes, markAsCode]);

    const allChecked = Boolean(
      students.length &&
        entries &&
        Object.values(entries).filter((entry) => entry.attendance_code_id).length ===
          students.length,
    );

    const handleCheckAll = useCallback<Exclude<SwitchBaseProps['onChange'], undefined>>(
      (event, checked) => {
        if (!checked) {
          return;
        }

        actions.setEntries(
          students.reduce<TakeAttendanceModalContextState['entries']>(
            (prev, student) => {
              // mark only empty entries
              if (!prev[student.relation_id]?.attendance_code_id) {
                prev[student.relation_id] = {
                  ...prev[student.relation_id],
                  relation_id: student.relation_id,
                  attendance_code_id: markAsCode,
                };
              }

              return prev;
            },
            { ...entries },
          ),
        );
      },
      [actions, entries, markAsCode, students],
    );

    return (
      <Stack
        direction="row"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            pl: theme.spacing(0.5),
          },
        })}
      >
        <FormControlLabel
          label={formatMessage({ id: 'attendance-MarkBlankAs' })}
          control={<Checkbox checked={allChecked} onChange={handleCheckAll} disabled={saving} />}
          sx={{ mr: 0, ml: -1 }}
        />
        <Dropdown
          value={markAsCode}
          options={codes?.map((code) => ({ value: code.id, label: code.name })) ?? []}
          onChange={(option) => setMarkAsCode(option?.value)}
          icon={<DropdownIcon />}
          canClean={false}
        />
      </Stack>
    );
  };
