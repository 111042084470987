import { DATE_FORMAT_SHORT_FNS } from '@schooly/api';
import { Message } from '@schooly/api';
import { ClipIcon } from '@schooly/style';
import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';

import { retrieveApiDate } from '../../../helpers/misc';
import { $UIKitPrefix } from '../../../styles/variables';
import PersonCardBasic from '../../common/PersonCard/PersonCardBasic';
import { MessageReadIndicator } from '../MessageReadIndicator/MessageReadIndicator';

import './ListViewMessagesRow.scss';

const $class = `${$UIKitPrefix}ListViewMessagesRow`;

export enum ListViewMessagesRowVariant {
  Profile = 'profile',
  Group = 'group',
}

export interface ListViewMessagesRowProps {
  message: Message;
  groupId?: string;
  variant?: ListViewMessagesRowVariant;
}

export const ListViewMessagesRow: React.FC<ListViewMessagesRowProps> = ({
  message,
  variant = ListViewMessagesRowVariant.Group,
  groupId,
}) => {
  const { publish_date, title, id, attachments, creator } = message;

  const publishDate = publish_date && retrieveApiDate(publish_date);

  return (
    <div className={$class}>
      <Link to={`/messages/${id}${groupId ? `?group_id=${groupId}` : ''}`}>
        <div className={`${$class}__row`}>
          <div className={`${$class}__date`}>
            {publishDate ? format(publishDate, DATE_FORMAT_SHORT_FNS).toUpperCase() : '-'}
          </div>
          {creator && (
            <div className={`${$class}__creator`}>
              <PersonCardBasic user={creator} userType="staff" isListItem short />
            </div>
          )}
          <div className={`${$class}__name`}>{title}</div>
          {attachments && attachments.length && <ClipIcon />}
          <MessageReadIndicator variant={variant} message={message} />
        </div>
      </Link>
    </div>
  );
};
