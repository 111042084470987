import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C10 18 17 13.0385 17 9.11111C17 5.18375 13.866 2 10 2C6.13401 2 3 5.18375 3 9.11111C3 13.0385 10 18 10 18ZM10 7C11.1046 7 12 7.89543 12 9C12 10.1046 11.1046 11 10 11C8.89543 11 8 10.1046 8 9C8 7.89543 8.89543 7 10 7Z"
      fill="#B3BBCB"
    />
  </svg>
);
