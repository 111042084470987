import { IconButton, Stack, Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  FilterSection,
  GetParentsQueryFilters,
  PARENT_QUERY_FILTER_KEYS,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AgeGroupExpandedSelect,
  filterExistingFilterOptions,
  GenderTagSelect,
  getSelectedItemsWithGrouping,
  InviteStatusTagSelect,
  MoreButtonOption,
  MoreButtonOptionType,
  NationalityTagSelect,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  PropertyTypeTagSelect,
  renderPropertyGroupTags,
  SelectedItem,
  SelectedItemWithGrouping,
  toggleMultipleValueArrayProperty,
} from '@schooly/components/filters';
import {
  DateDropdown,
  FilterDropdown,
  FiltersContainer,
  GenderExpandedSelect,
  InviteStatusExpandedSelect,
  MoreButton,
  NationalityExpandedSelect,
  PropertyTypeExpandedSelect,
} from '@schooly/components/filters';
import {
  Genders,
  InviteStatus,
  Nationalities,
  SchoolPropertyType,
  SchoolUserRole,
} from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { CloseSmallIcon } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSchool } from '../../hooks/useSchool';

type ParentsFiltersProps = {
  schoolId: string;
  filters: GetParentsQueryFilters;
  defaultFilters: GetParentsQueryFilters;
  onSetFilters: (v: GetParentsQueryFilters) => void;
  defaultUserFilters: GetParentsQueryFilters;
};

export const ParentsFilters: FC<ParentsFiltersProps> = ({
  defaultFilters,
  filters: actualFilters,
  schoolId,
  onSetFilters,
  defaultUserFilters,
}) => {
  const { $t } = useIntl();
  const {
    getAgeGroupsByLevelId,
    getAgeGroupById,
    schoolLevelsWithAgeGroupsMap,
    getSchoolLevelById,
  } = useAgeGroups({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });
  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetParentsQueryFilters>(actualFilters);
  const { empty_email_only, ...filtersCanBeSaved } = draftFilters;
  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    const value = v === FilterKeys.OnlyEmptyEmail ? [1] : [];
    return setDraftFilters((filters) => ({
      ...filters,
      [v]: filters[v] !== undefined ? undefined : value,
    }));
  }, []);

  const { currentStaff } = useAuth();
  const { hasHouses } = useSchool();
  useEffect(() => {
    setDraftFilters(actualFilters);
  }, [actualFilters]);

  const handleApply = useMemo(() => {
    // Operator != is used on purpose to properly compare against undefined and null
    // eslint-disable-next-line eqeqeq
    const filtersChanged = PARENT_QUERY_FILTER_KEYS.some((key) => {
      const draftFiltersForKey = [...(draftFilters[key] || [])];
      const actualFiltersForKey = [...(actualFilters[key] || [])];

      return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
    });

    if (!filtersChanged) return undefined;

    return () => onSetFilters(draftFilters);
  }, [onSetFilters, actualFilters, draftFilters]);

  const handleSetDate = useCallback((v: Date) => {
    setDraftFilters((filters) => ({
      ...filters,
      [FilterKeys.Date]: [format(v, DEFAULT_DATE_FORMAT_FNS)],
    }));
  }, []);

  const {
    onClearGender,
    onClearNationality,
    onSelectGender,
    onSelectNationality,
    onClearAgeGroup,
    onClearHouse,
    onClearStatus,
    onSelectAgeGroup,
    onSelectHouseId,
    onSelectStatusId,
    onSelectInviteStatus,
    onClearInviteStatus,
    onClearOnlyEmptyEmail,
  } = useMemo(() => {
    const updateFilter = (key: FilterKeys.Status | FilterKeys.House) => (id: string) => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: filters[key]?.includes(id)
          ? filters[key]?.filter((ct) => ct !== id)
          : [...(filters[key] || []), id],
      }));
    };
    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    const onSelectNationality = (v: Nationalities) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.Nationality]: filters[FilterKeys.Nationality]?.includes(v)
          ? filters[FilterKeys.Nationality]?.filter((ct) => ct !== v)
          : [...(filters[FilterKeys.Nationality] || []), v],
      }));
    };
    const onSelectInviteStatus = (v: InviteStatus) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.InviteStatus]: filters[FilterKeys.InviteStatus]?.includes(v)
          ? filters[FilterKeys.InviteStatus]?.filter((ct) => ct !== v)
          : [...(filters[FilterKeys.InviteStatus] || []), v],
      }));
    };
    const onSelectGender = (v: Genders) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.Gender]: filters[FilterKeys.Gender]?.includes(v)
          ? filters[FilterKeys.Gender]?.filter((ct) => ct !== v)
          : [...(filters[FilterKeys.Gender] || []), v],
      }));
    };

    const onSelectAgeGroup = (v: string[]) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(filters[FilterKeys.AgeGroup], v),
      }));
    };

    return {
      onSelectInviteStatus,
      onSelectGender,
      onSelectNationality,
      onSelectHouseId: updateFilter(FilterKeys.House),
      onClearHouse: clearFilter(FilterKeys.House),
      onSelectAgeGroup,
      onClearAgeGroup: clearFilter(FilterKeys.AgeGroup),
      onSelectStatusId: updateFilter(FilterKeys.Status),
      onClearStatus: clearFilter(FilterKeys.Status),
      onClearNationality: clearFilter(FilterKeys.Nationality),
      onClearGender: clearFilter(FilterKeys.Gender),
      onClearInviteStatus: clearFilter(FilterKeys.InviteStatus),
      onClearOnlyEmptyEmail: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.OnlyEmptyEmail]: undefined,
        })),
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    setDraftFilters(defaultFilters);
  }, [defaultFilters]);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleResetToDefault = useCallback(() => {
    setDraftFilters(defaultUserFilters);
  }, [defaultUserFilters]);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  const filtersDate = draftFilters.date?.[0];
  const filtersStatus = draftFilters.status;
  const filtersNationality = draftFilters.nationality;
  const filtersGender = draftFilters.gender;
  const filtersHouse = draftFilters.house;
  const filtersAgeGroup = draftFilters.age_group;
  const filtersInviteStatus = draftFilters.invite_status;
  const filtersOnlyEmptyEmail = draftFilters.empty_email_only;

  const dateLabel = $t({ id: 'schoolProperty-Date' });
  const statusLabel = $t({ id: 'schoolProperty-Status' });
  const ageGroupLabel = $t({ id: 'schoolProperty-AgeGroup' });
  const houseLabel = $t({ id: 'schoolProperty-House' });
  const genderLabel = $t({ id: 'peopleDetail-Gender' });
  const nationalityLabel = $t({ id: 'peopleDetail-Nationality' });
  const inviteStatusLabel = $t({ id: 'schoolProperty-InviteStatus' });
  const onlyEmptyEmailLabel = $t({ id: 'peopleDetail-NoEmail' });

  const filterOptions: MoreButtonOption<keyof GetParentsQueryFilters>[] = [
    { value: FilterKeys.Date, label: dateLabel, required: true },
    { value: FilterKeys.Status, label: statusLabel },
    { value: FilterKeys.AgeGroup, label: ageGroupLabel },
    { value: FilterKeys.House, label: houseLabel },
    { value: FilterKeys.Gender, label: genderLabel },
    { value: FilterKeys.Nationality, label: nationalityLabel },
    { value: FilterKeys.InviteStatus, label: inviteStatusLabel },
    {
      value: FilterKeys.OnlyEmptyEmail,
      label: onlyEmptyEmailLabel,
      type: MoreButtonOptionType.Switch,
    },
  ];

  const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups =
      filtersAgeGroup?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [filtersAgeGroup, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        onSaveFilter={handleSaveFilter}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        schoolId={schoolId}
        section={FilterSection.Parent}
        filters={filtersCanBeSaved}
        onSetFilters={(v) => {
          const appliedFilters = pickOnlyParamsFromFilterKeys(PARENT_QUERY_FILTER_KEYS, v);

          onSetFilters({
            ...appliedFilters,
            [FilterKeys.Date]: appliedFilters.date || defaultFilters.date,
          });
        }}
      />
      {filtersDate && (
        <DateDropdown date={filtersDate} label={dateLabel} onSetDate={handleSetDate} />
      )}
      {filtersStatus && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Status)}
          label={statusLabel}
          tags={(open) =>
            filtersStatus.map((id) => (
              <PropertyTypeTagSelect
                userRole={SchoolUserRole.Student}
                schoolId={schoolId}
                sx={{ maxWidth: 200 }}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <PropertyTypeExpandedSelect
              propertyType={SchoolPropertyType.Status}
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersStatus}
              onSelectId={onSelectStatusId}
              onClose={onClose}
              onClear={onClearStatus}
            />
          )}
        </FilterDropdown>
      )}
      {filtersAgeGroup && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.AgeGroup)}
          label={ageGroupLabel}
          tags={(open) =>
            renderPropertyGroupTags({
              selectedItems: selectedItemsForAgeGroups,
              onClick: open,
              getProperty: (i) =>
                i.isGroup
                  ? getSchoolLevelById(i.id)
                  : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
              getTooltip: (i) =>
                i.isGroup
                  ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                      <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                    ))
                  : null,
              tagProps: {
                userRole: SchoolUserRole.Student,
                sx: { maxWidth: 200 },
              },
            })
          }
        >
          {(onClose) => (
            <AgeGroupExpandedSelect
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersAgeGroup}
              onSelect={onSelectAgeGroup}
              onClose={onClose}
              onClear={onClearAgeGroup}
            />
          )}
        </FilterDropdown>
      )}
      {filtersHouse && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.House)}
          label={houseLabel}
          tags={(open) =>
            filtersHouse.map((id) => (
              <PropertyTypeTagSelect
                userRole={SchoolUserRole.Student}
                sx={{ maxWidth: 200 }}
                schoolId={schoolId}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <PropertyTypeExpandedSelect
              propertyType={SchoolPropertyType.House}
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersHouse}
              onSelectId={onSelectHouseId}
              onClose={onClose}
              onClear={onClearHouse}
            />
          )}
        </FilterDropdown>
      )}
      {filtersNationality && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Nationality)}
          label={nationalityLabel}
          tags={(open) =>
            filtersNationality.map((v) => (
              <NationalityTagSelect sx={{ maxWidth: 200 }} key={v} nationality={v} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <NationalityExpandedSelect
              selectedNationalities={filtersNationality}
              onSelectNationality={onSelectNationality}
              onClose={onClose}
              onClear={onClearNationality}
            />
          )}
        </FilterDropdown>
      )}
      {filtersGender && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Gender)}
          label={genderLabel}
          tags={(open) =>
            filtersGender.map((v) => (
              <GenderTagSelect sx={{ maxWidth: 200 }} key={v} gender={v} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <GenderExpandedSelect
              selectedGenders={filtersGender}
              onSelectGender={onSelectGender}
              onClose={onClose}
              onClear={onClearGender}
            />
          )}
        </FilterDropdown>
      )}
      {filtersInviteStatus && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.InviteStatus)}
          label={inviteStatusLabel}
          tags={(open) =>
            filtersInviteStatus.map((status) => (
              <InviteStatusTagSelect
                status={status}
                sx={{ maxWidth: 200 }}
                key={status}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <InviteStatusExpandedSelect
              selectedStatuses={filtersInviteStatus}
              onSelectStatus={onSelectInviteStatus}
              onClose={onClose}
              onClear={onClearInviteStatus}
            />
          )}
        </FilterDropdown>
      )}
      {filtersOnlyEmptyEmail && (
        <Stack direction="row" gap={1}>
          <Typography variant="h3">
            <FormattedMessage id="peopleDetail-NoEmail" />
          </Typography>
          <IconButton
            sx={{ fontSize: (theme) => theme.spacing(2) }}
            onClick={onClearOnlyEmptyEmail}
          >
            <CloseSmallIcon />
          </IconButton>
        </Stack>
      )}
      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterExistingFilterOptions({ filterOptions, hasHouses })}
        selectedOptions={PARENT_QUERY_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        onToggleOption={toggleFiltersVisible}
      />
    </FiltersContainer>
  );
};
