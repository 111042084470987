import { Box } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT,
  FilterKeys,
  GetConductStatsQueryFilters,
  GroupWithMembers,
} from '@schooly/api';
import { ConductChart } from '@schooly/components/charts';
import { SchoolPropertyType } from '@schooly/constants';
import { FC, useMemo } from 'react';

import useSchoolYears from '../../../../hooks/useSchoolYears';
import { useGroupDates } from '../../ManageGroupModal/useGroupDates';

type GroupPreviewModalConductStatsProps = {
  schoolId: string;
  isHidden?: boolean;
  group: GroupWithMembers;
  conductTypeOptionIds?: string[];
  onClose: () => void;
};

export const GroupPreviewModalConductStats: FC<GroupPreviewModalConductStatsProps> = ({
  onClose,
  conductTypeOptionIds,
  schoolId,
  isHidden,
  group,
}) => {
  const { schoolYears } = useSchoolYears();
  const { getValidityDates } = useGroupDates({ schoolYears, dateFormat: DEFAULT_DATE_FORMAT });
  const { from, to } = getValidityDates(group.validity);

  const filters: GetConductStatsQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [from, to],
      [FilterKeys.Group]: [group.id],
    }),
    [from, group.id, to],
  );

  if (isHidden) return null;

  return (
    <Box
      sx={{
        p: 2.25,
        backgroundColor: 'white',
        borderRadius: 1,
      }}
    >
      <ConductChart
        hideChartButton
        onCloseChart={onClose}
        conductTypeOptionIds={conductTypeOptionIds}
        schoolId={schoolId}
        filters={filters}
        columns={2}
        chartHeight={125}
        schoolPropertyType={SchoolPropertyType.House}
      />
    </Box>
  );
};
