import { useAuth } from '@schooly/components/authentication';
import { Loading } from '@schooly/style';
import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import {
  allowRenderSchoolLegalEntities,
  allowRenderSchoolProducts,
} from '../../helpers/renderRoutesByPermissions';
import { useSchool } from '../../hooks/useSchool';
import { SchoolPageCompleted } from './SchoolPageCompleted';
import { SchoolPageHeader } from './SchoolPageHeader';
import { SchoolPageIncompleted } from './SchoolPageIncompleted';

export const SchoolPage: FC = () => {
  const { isCompleted, isSchoolAdmin, isFetching } = useSchool();
  const { permissions } = useAuth();
  const hasSchoolProductsAccess = allowRenderSchoolProducts(permissions);
  const hasLegalEntitiesAccess = allowRenderSchoolLegalEntities(permissions);

  const redirectSettings = () => {
    if (hasLegalEntitiesAccess) {
      return <Navigate to="/settings/legal_entities" />;
    }
    if (hasSchoolProductsAccess) {
      return <Navigate to="/settings/products" />;
    }
    // TODO: Think about extracting auth/permissions routing somewhere else

    // This is last entry point that is checking users permissions
    // if the user is not allowed to see this page, navigate to the more generic page
    // that checks for any permission and navigate to the first one
    return <Navigate to="/check-access" />;
  };

  if (!isSchoolAdmin) {
    return redirectSettings();
  }

  const renderContent = () => {
    if (isFetching) return <Loading />;

    return (
      <>
        <SchoolPageHeader />
        {isCompleted ? <SchoolPageCompleted /> : <SchoolPageIncompleted />}
      </>
    );
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};
