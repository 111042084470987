import common from './common.json';
import errors from './errors.json';
import landing from './landing.json';
import misc from './misc.json';
import mobile from './mobile.json';
import notifications from './notifications.json';
import options from './options.json';
import parentApplications from './parent-applications.json';
import people from './people.json';
import sections from './sections';

const pt = {
  ...common,
  ...errors,
  ...misc,
  ...mobile,
  ...notifications,
  ...people,
  ...sections,
  ...parentApplications,
  ...landing,
  ...options,
};

export default pt;
