import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridColumnGroupHeaderParams,
  GridColumnGroupingModel,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  AssessmentEntry,
  AssessmentEntryComment,
  AssessmentMethod,
  AssessmentRecipientRelation,
  SHORT_FORMATTED_DATE_FORMAT_FNS,
} from '@schooly/api';
import {
  AssessmentEntriesForGroup,
  AssessmentForGroup,
  AssessmentMethodType,
  AssessmentsGrade,
  GroupMember,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { DataGridTable } from '@schooly/components/data-grid';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import PersonCardBasic from '../../../components/common/PersonCard/PersonCardBasic';
import {
  AssessmentEntryMethod,
  getAssessmentParams,
  hasAssessmentEntry,
} from '../../../components/uikit-components/AssessmentEntryMethods/AssessmentEntryMethod';
import { CommentControllerProps } from '../../../components/uikit-components/AssessmentEntryMethods/methods/CommentController';
import { GradeControllerProps } from '../../../components/uikit-components/AssessmentEntryMethods/methods/GradeController';
import { ScoreControllerProps } from '../../../components/uikit-components/AssessmentEntryMethods/methods/ScoreController';
import { GridContainer } from '../../../components/uikit-components/Grid/Grid';
import { useDataGridHover } from '../../../context/table/dataGridHover/WithDataGridHover';
import { AssessmentMarkbookEmptyCell } from './AssessmentMarkbookEmptyCell';
import { AssessmentMarkbookHeaderCell } from './AssessmentMarkbookHeaderCell';
import { AssessmentMarkbookScrollArrows } from './AssessmentMarkbookScrollArrows';

const COL_WIDTH = 112;
const ROW_HEIGHT = 44;
const STUDENT_COL_FIELD_KEY = 'students';
const FIELD_KEY_SEPARATOR = '_';

interface Row {
  id: string;
  student: GroupMember;
}

interface RenderAssessmentEntryMethodProps {
  student: GroupMember;
  method: AssessmentMethod;
  scoreControllerProps?: Partial<ScoreControllerProps>;
  gradeControllerProps?: Partial<GradeControllerProps>;
  commentControllerProps?: Partial<CommentControllerProps>;
  assessmentEntries?: AssessmentForGroup;
}

export interface AssessmentsMarkbookGridProps {
  groupId: string;
  assessments: AssessmentForGroup[];
  students: GroupMember[];
  lists?: Record<string, AssessmentsGrade>;
  entries?: AssessmentEntriesForGroup;
  dateFrom?: string;
  dateTo?: string;
  onSuccess?: () => void;
}

function getAssessmentEntries(assessment: AssessmentForGroup, entries?: AssessmentEntriesForGroup) {
  return entries?.assessments.find((item) => item.id === assessment.id);
}

export interface UpdateAssessmentEntryProps {
  studentId: string;
  assessmentId: string;
  methodId: string;
  entry?: AssessmentEntry;
  comments?: AssessmentEntryComment[];
}

//Gets a field key for a column for a method in assessment
const getAssessmentFieldKey = ({
  assessmentId,
  methodType,
  assessmentIndex,
  methodIndex,
}: {
  assessmentId: string;
  methodType: AssessmentMethodType;
  assessmentIndex: number;
  methodIndex: number;
}) =>
  assessmentId
    ? `${assessmentId}${FIELD_KEY_SEPARATOR}${methodType}${FIELD_KEY_SEPARATOR}${assessmentIndex}${FIELD_KEY_SEPARATOR}${methodIndex}`
    : STUDENT_COL_FIELD_KEY;

//Gets data from column field key
const getAssessmentFieldKeyValues = (key: GridCellParams['field']) => {
  const [assessmentId, methodType, assessmentIndex, methodIndex] = key.split(FIELD_KEY_SEPARATOR);

  const parsedMethodType = parseInt(methodType);
  const parsedAssessmentIndex = parseInt(assessmentIndex);
  const parsedMethodIndex = parseInt(methodIndex);

  if (
    !assessmentId ||
    isNaN(parsedMethodType) ||
    isNaN(parsedAssessmentIndex) ||
    isNaN(parsedMethodIndex)
  )
    console.error('Invalid field key format. Unable to extract values.');

  return {
    assessmentId,
    methodType: parsedMethodType,
    assessmentIndex: parsedAssessmentIndex,
    methodIndex: parsedMethodIndex,
  };
};

export type MarkbookRef = {
  updateEntries: (e: AssessmentEntriesForGroup) => void;
};

export const AssessmentMarkbookGrid = forwardRef<MarkbookRef, AssessmentsMarkbookGridProps>(
  (
    {
      groupId,
      assessments,
      students,
      lists,
      entries: assessmentEntries,
      dateFrom,
      dateTo,
      onSuccess,
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const apiRef = useGridApiRef();
    const { onColumnMouseOver, onMouseLeave } = useDataGridHover();
    const [entries, setEntries] = useState(assessmentEntries);

    useImperativeHandle(ref, () => ({
      updateEntries: setEntries,
    }));

    //Assessment entry update is required when using virtualization
    const updateAssessmentEntry = ({
      studentId,
      assessmentId,
      methodId,
      entry,
      comments,
    }: UpdateAssessmentEntryProps) => {
      setEntries((prev) => {
        if (!prev) return;

        const assessments: AssessmentForGroup[] = prev.assessments.map((assessment) => {
          if (assessment.id !== assessmentId) return assessment;

          const relations: AssessmentRecipientRelation[] = assessment.relations.map((relation) => {
            if (relation.recipient_relation_id !== studentId) return relation;

            const updatedEntries = entry ? [entry] : [];
            const newEntries = comments ?? updatedEntries;

            // If entries do not exists
            if (!relation.entries?.length) {
              return { ...relation, entries: newEntries };
            }

            // If entries exist
            const entryNotExists = !relation.entries.some((e) => e.method_id === methodId);

            if (entryNotExists) {
              return {
                ...relation,
                entries: [...relation.entries, ...newEntries],
              };
            }

            const entries = relation.entries.reduce<AssessmentEntry[]>((acc, next) => {
              const currentEntry = next.method_id === methodId;

              if (!currentEntry) return [...acc, next];

              return !entry ? acc : [...acc, ...newEntries];
            }, []);

            return {
              ...relation,
              entries,
            };
          });

          const result: AssessmentForGroup = { ...assessment, relations };

          return result;
        });

        return {
          ...prev,
          assessments,
        };
      });
    };

    const { permissions } = useAuth();

    const belowLargeScreenWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const studentNameCellWidth = belowLargeScreenWidth ? 307 : 282;

    // display arrows only in case of >3 assessments,
    // as the scroll appears only since 4th item
    const isScrollable = assessments.length > 3;

    const renderAssessmentEntryMethod = useCallback(
      ({
        student,
        method,
        scoreControllerProps,
        gradeControllerProps,
        commentControllerProps,
        assessmentEntries,
      }: RenderAssessmentEntryMethodProps) => {
        return (
          <AssessmentEntryMethod
            relationId={student.user?.relation_id}
            groupId={groupId}
            method={method}
            assessmentForGroup={assessmentEntries}
            lists={lists}
            scoreControllerProps={scoreControllerProps}
            gradeControllerProps={gradeControllerProps}
            commentControllerProps={commentControllerProps}
            onSuccess={onSuccess}
            updateAssessmentEntry={updateAssessmentEntry}
          />
        );
      },
      [groupId, lists, onSuccess],
    );

    //Checks if cell can be edited/opened
    const isCellEditable = useCallback(
      (params: GridCellParams<Row>) => {
        const { field, row } = params;

        if (field === STUDENT_COL_FIELD_KEY) return false;

        const studentId = row.student.user.relation_id;
        const { methodType, assessmentIndex, methodIndex } = getAssessmentFieldKeyValues(field);

        const assessment = assessments[assessmentIndex];
        const method = assessment?.methods[methodIndex];
        const assessmentEntries = assessment && getAssessmentEntries(assessment, entries);

        const isEmptyCell = !hasAssessmentEntry(studentId, method, assessmentEntries);

        //Check for score and grade cells
        if (
          methodType === AssessmentMethodType.Score ||
          methodType === AssessmentMethodType.Grade
        ) {
          return Boolean(
            !isEmptyCell &&
              method?.method_id &&
              (permissions.includes('assessment_manager') ||
                assessmentEntries?.enterable_and_my_group),
          );
        }

        //Check for comment cell
        const canAdd = Boolean(
          !isEmptyCell && method?.method_id && assessmentEntries?.enterable_and_my_group,
        );
        const comments = getAssessmentParams(studentId, method, assessmentEntries)?.entries;
        const hasComments = !!comments?.length;
        return canAdd || hasComments;
      },
      [assessments, entries, permissions],
    );

    //Groping of columns is used for joined header cells
    const columnGroupingModel: GridColumnGroupingModel = [
      {
        groupId: STUDENT_COL_FIELD_KEY,
        children: [{ field: STUDENT_COL_FIELD_KEY }],
        renderHeaderGroup: () => (
          <>
            {dateFrom && dateTo && (
              <Typography component="span" variant="h3" color="text.primary">
                {format(newDateTimezoneOffset(dateFrom), SHORT_FORMATTED_DATE_FORMAT_FNS)} -{' '}
                {format(newDateTimezoneOffset(dateTo), SHORT_FORMATTED_DATE_FORMAT_FNS)}
              </Typography>
            )}
          </>
        ),
      },
      ...(assessments ?? []).flatMap((assessment, assessmentIndex) => {
        return {
          groupId: assessment.id,
          headerClassName: 'assessmentInfoHeaderCell',
          renderHeaderGroup: (props: GridColumnGroupHeaderParams) => (
            <AssessmentMarkbookHeaderCell
              onMouseOver={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
                onColumnMouseOver({
                  getCellElement: apiRef.current.getCellElement,
                  colFields: props.fields,
                  cellSelector: '.assessmentInfoHeaderCell',
                  event,
                  rows,
                })
              }
              onMouseLeave={onMouseLeave}
              assessment={assessment}
            />
          ),
          children: assessment.methods.map((method, methodIndex) => {
            return {
              field: getAssessmentFieldKey({
                assessmentId: assessment.id,
                assessmentIndex,
                methodIndex,
                methodType: method.method_type,
              }),
            };
          }),
        };
      }),
    ];

    const rows: Row[] = useMemo(() => {
      if (!students.length) {
        return [];
      }

      return students.map((student) => ({
        id: student.user?.relation_id,
        student,
      }));
    }, [students]);

    const columns = useMemo(
      () => [
        {
          field: STUDENT_COL_FIELD_KEY,
          sortable: false,
          disableColumnMenu: true,
          flex: 1,
          minWidth: studentNameCellWidth,
          cellClassName: 'studentNameCell',

          renderHeader: () => null,

          renderCell: (props: GridRenderCellParams<Row>) => {
            const { row } = props;
            const student = row.student;

            return (
              <Stack
                pl={1}
                sx={{
                  maxWidth: '100%',
                  '& .card-body': {
                    maxHeight: ROW_HEIGHT,
                  },
                }}
              >
                <PersonCardBasic
                  user={student.user}
                  userType="student"
                  isListItem
                  isUsernameClickable
                />
              </Stack>
            );
          },
        },

        ...(assessments ?? []).flatMap((assessment, assessmentIndex) =>
          assessment.methods.map((method, methodIndex): GridColDef => {
            const assessmentEntries = getAssessmentEntries(assessment, entries);
            const methodType = method.method_type;

            switch (methodType) {
              case AssessmentMethodType.Score:
                return {
                  field: getAssessmentFieldKey({
                    assessmentId: assessment.id,
                    assessmentIndex,
                    methodIndex,
                    methodType,
                  }),
                  sortable: false,
                  disableColumnMenu: true,
                  width: COL_WIDTH,
                  editable: true,
                  cellClassName: 'scoreCell',

                  renderHeader: () => null,

                  renderCell: (props: GridRenderCellParams<Row>) => {
                    const { row } = props;
                    const student = row.student;

                    const isEmpty =
                      student &&
                      !hasAssessmentEntry(student.user.relation_id, method, assessmentEntries);

                    const scoreControllerProps = {
                      focused: false,
                    };

                    return isEmpty ? (
                      <AssessmentMarkbookEmptyCell />
                    ) : (
                      <Stack minWidth="100%">
                        {student &&
                          renderAssessmentEntryMethod({
                            student,
                            method,
                            scoreControllerProps,
                            assessmentEntries,
                          })}
                      </Stack>
                    );
                  },

                  renderEditCell: (props: GridRenderCellParams<Row>) => {
                    const { row } = props;
                    const student = row.student;

                    const scoreControllerProps = {
                      focused: true,
                    };

                    return (
                      <Stack minWidth="100%">
                        {renderAssessmentEntryMethod({
                          student,
                          method,
                          scoreControllerProps,
                          assessmentEntries,
                        })}
                      </Stack>
                    );
                  },
                };

              case AssessmentMethodType.Grade:
                return {
                  field: getAssessmentFieldKey({
                    assessmentId: assessment.id,
                    assessmentIndex,
                    methodIndex,
                    methodType,
                  }),
                  sortable: false,
                  disableColumnMenu: true,
                  width: COL_WIDTH,
                  editable: true,
                  cellClassName: 'gradeCell',

                  renderHeader: () => null,

                  renderCell: (props: GridRenderCellParams<Row>) => {
                    const { row } = props;
                    const student = row.student;

                    const isEmpty =
                      student &&
                      !hasAssessmentEntry(student.user.relation_id, method, assessmentEntries);

                    const gradeControllerProps = {
                      open: false,
                      onFocus: (e: React.FocusEvent) => e.stopPropagation,
                    };

                    return isEmpty ? (
                      <AssessmentMarkbookEmptyCell />
                    ) : (
                      <Stack minWidth="100%">
                        {student &&
                          renderAssessmentEntryMethod({
                            student,
                            method,
                            gradeControllerProps,
                            assessmentEntries,
                          })}
                      </Stack>
                    );
                  },

                  renderEditCell: (props: GridRenderCellParams<Row>) => {
                    const { row } = props;
                    const student = row.student;

                    const gradeControllerProps = {
                      open: true,
                      disableEscapeKeyDown: true,
                      noStopPropagationOnSelect: true,
                      disableSpaceKeyPress: true,
                      dropdownStyles: {
                        '.MuiPaper-root': {
                          ml: '-1px',
                          minWidth: COL_WIDTH,
                        },
                      },
                    };

                    return (
                      <Stack minWidth="100%">
                        {renderAssessmentEntryMethod({
                          student,
                          method,
                          gradeControllerProps,
                          assessmentEntries,
                        })}
                      </Stack>
                    );
                  },
                };

              case AssessmentMethodType.Comment:
              default:
                return {
                  field: getAssessmentFieldKey({
                    assessmentId: assessment.id,
                    assessmentIndex,
                    methodIndex,
                    methodType,
                  }),
                  sortable: false,
                  disableColumnMenu: true,
                  align: 'center',
                  editable: true,
                  width: COL_WIDTH,
                  cellClassName: 'commentCell',

                  renderHeader: () => null,

                  renderCell: (props: GridRenderCellParams<Row>) => {
                    const { row } = props;
                    const student = row.student;

                    const isEmpty =
                      student &&
                      !hasAssessmentEntry(student.user.relation_id, method, assessmentEntries);

                    const commentControllerProps = {
                      tableView: true,
                      onFocus: (e: React.FocusEvent) => e.stopPropagation,
                    };

                    return isEmpty ? (
                      <AssessmentMarkbookEmptyCell />
                    ) : (
                      <Stack minWidth="100%" height="100%">
                        {student &&
                          renderAssessmentEntryMethod({
                            student,
                            method,
                            commentControllerProps,
                            assessmentEntries,
                          })}
                      </Stack>
                    );
                  },
                  renderEditCell: (props: GridRenderCellParams<Row>) => {
                    const { row } = props;
                    const student = row.student;

                    const commentControllerProps = {
                      tableView: true,
                      open: true,
                      autoFocusOnEdit: true,
                      disableEscapeKeyDown: true,
                      saveOnEnterPress: true,
                    };

                    return (
                      <Stack minWidth={COL_WIDTH} height="100%">
                        {renderAssessmentEntryMethod({
                          student,
                          method,
                          commentControllerProps,
                          assessmentEntries,
                        })}
                      </Stack>
                    );
                  },
                };
            }
          }),
        ),
      ],
      [studentNameCellWidth, assessments, entries, renderAssessmentEntryMethod],
    );

    return (
      <GridContainer
        ref={containerRef}
        sx={(theme) => ({
          m: 3,
          mt: 0,
          width: '95%',
          borderRadius: 0,
          [theme.breakpoints.down('md')]: {
            mr: 0,
            width: '94%',
          },
        })}
      >
        {isScrollable && (
          <AssessmentMarkbookScrollArrows
            apiRef={apiRef}
            assessments={assessments}
            colWidth={COL_WIDTH}
            containerRef={containerRef}
            pinnedColumnsWidth={studentNameCellWidth}
          />
        )}
        <DataGridTable
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          columnsToPin={[STUDENT_COL_FIELD_KEY]}
          withDataGridHover
          slotProps={{
            cell: {
              onKeyDown: (e) => {
                switch (e.key) {
                  case 'Escape':
                    e.stopPropagation();
                    break;
                }
              },
            },
          }}
          isCellEditable={isCellEditable}
          stopEditModeOnDeleteKey={(field: GridCellParams['field']) =>
            getAssessmentFieldKeyValues(field).methodType === AssessmentMethodType.Grade
          }
          noNavigationOnFirstCol
          rowHeight={45}
          columnGroupingModel={columnGroupingModel}
          headerCellSelector={'.assessmentInfoHeaderCell'}
          sx={(theme) => ({
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: theme.palette.background.paper,
            },
            '.MuiDataGrid-columnHeaderTitleContainer.MuiDataGrid-withBorderColor': {
              whiteSpace: 'normal',
              border: 'none',
            },
            '.MuiDataGrid-columnHeaders': {
              overflow: 'visible',
            },
            '.assessmentInfoHeaderCell': {
              borderRight: theme.mixins.borderValue(),
              '&.MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:last-child': {
                borderRight: theme.mixins.borderValue(),
              },
              ' .MuiDataGrid-columnHeaderTitleContainerContent': {
                height: '100%',
              },
            },
            ' .MuiDataGrid-row': {
              '&:not(.MuiDataGrid-row--dynamicHeight)>.studentNameCell': {
                overflow: 'visible',
              },
              '.studentNameCell': {
                borderRight: theme.mixins.borderValue(),
                backgroundColor: theme.palette.background.paper,
                pr: theme.spacing(1),
                outline: 'none',
              },
              '&:first-of-type': {
                '& .MuiDataGrid-cell--editing.scoreCell': {
                  borderTop: theme.mixins.borderControlValue(),
                  '& .MuiStack-root': {
                    borderTop: 'none',
                  },
                },
              },
            },
            ' .MuiDataGrid-withBorderColor': {
              '&.commentCell, &.gradeCell, &.scoreCell': {
                borderRight: theme.mixins.borderValue(),
              },
            },
            '& .MuiDataGrid-cell--editing': {
              '&.gradeCell': {
                borderLeft: 'none',
                borderBottom: theme.mixins.borderValue(),
              },
              '&.scoreCell': {
                padding: 0,
                '&.MuiDataGrid-cell:focus-within': {
                  overflow: 'visible',
                  outline: 'none',
                },
              },
            },
          })}
        />
      </GridContainer>
    );
  },
);
