import { addMonths, addYears, subMonths, subYears } from 'date-fns';
import { useCallback, useState } from 'react';

export const useDatePickerVisibleMonth = (date: Date) => {
  const [visibleMonthDate, setVisibleMonthDate] = useState<Date>(date);

  const showNextMonth = useCallback(() => setVisibleMonthDate((d) => addMonths(d, 1)), []);
  const showPrevMonth = useCallback(() => setVisibleMonthDate((d) => subMonths(d, 1)), []);

  const showNextYear = useCallback(() => setVisibleMonthDate((d) => addYears(d, 1)), []);
  const showPrevYear = useCallback(() => setVisibleMonthDate((d) => subYears(d, 1)), []);

  return {
    setVisibleMonthDate,
    visibleMonthDate,
    showNextMonth,
    showNextYear,
    showPrevMonth,
    showPrevYear,
  };
};
