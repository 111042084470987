import { useAuth } from '@schooly/components/authentication';
import { Loading, ModalSmall } from '@schooly/style';

import { useProfile } from '../../../../context/profile/useProfile';
import { StudentRegistrationContent } from './RegistrationContent';

export const StudentRegistrationModal = () => {
  const { user, isFetching } = useProfile();
  const { schoolId } = useAuth();

  return (
    <ModalSmall open>
      {isFetching || !schoolId || !user ? (
        <Loading />
      ) : (
        <StudentRegistrationContent schoolId={schoolId} user={user} />
      )}
    </ModalSmall>
  );
};
