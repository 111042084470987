import { Box, Button, IconButton, Select, Stack, StackProps, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, RolloverAggregatedDataResponse, SchoolYear } from '@schooly/api';
import { RolloverCharts } from '@schooly/components/charts';
import {
  DateSelect,
  DropdownSelect,
  SelectOptionsArchivedEmptyStub,
  SelectOptionsArchivedIcon,
} from '@schooly/components/filters';
import { SchoolPropertyType } from '@schooly/constants';
import {
  Attention2Icon,
  CheckIcon,
  LockIcon,
  ModalContent,
  ModalFooter,
  ModalMain,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
// eslint-disable-next-line @nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ExtendedFieldError } from 'apps/web/src/components/ui/Input/utils';
import { format, isWithinInterval } from 'date-fns';
import React, { FC, useCallback, useRef } from 'react';
import {
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
  FormProvider,
  get,
  SubmitHandler,
  useForm,
} from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { RolloverHeader } from './RolloverHeader';

export type RolloverConfigurationForm = {
  applies_from: string;
  status_id: string;
};

type Total = { total: number };

export type RolloverConfigParams = Total | (Total & RolloverConfigurationForm);

export type RolloverConfigurationContentProps = {
  onClose: () => void;
  year: SchoolYear;
  statusOptions: Array<{ value: string; label: string }>;
  onRollover: (data: RolloverConfigParams) => void;
  isAdmin: boolean;
  data: RolloverAggregatedDataResponse;
  reEnrollmentEnabled: boolean;
};

export const RolloverConfigurationContent: FC<RolloverConfigurationContentProps> = ({
  onClose,
  year,
  statusOptions,
  onRollover,
  isAdmin,
  data,
  reEnrollmentEnabled,
}) => {
  const form = useForm<RolloverConfigurationForm>({
    defaultValues: { applies_from: year.start },
    mode: 'onChange',
  });
  const { $t } = useIntl();

  const inputRef = useRef<HTMLDivElement>(null);
  const dropdown = useRef<DropdownSelect | null>(null);
  const appliesFrom = form.watch('applies_from');

  const isStartDateChanged = appliesFrom !== year.start;
  const dateError: ExtendedFieldError = get(form.formState.errors, 'applies_from');

  const onValidateDate = useCallback(
    (value: FieldPathValue<FieldValues, FieldPath<FieldValues>>) => {
      if (!value) return true;
      const currentDate = newDateTimezoneOffset(value);
      const startDate = newDateTimezoneOffset(year.start);
      const endDate = newDateTimezoneOffset(year.end);

      const isOutOfYearRange = !isWithinInterval(currentDate, {
        start: startDate,
        end: endDate,
      });

      return isOutOfYearRange
        ? $t({ id: 'students-AnnualRollover-ConfigurationYearError' }, { yearName: year.name })
        : true;
    },
    [$t, year.end, year.name, year.start],
  );
  const handleSubmit = useCallback<SubmitHandler<RolloverConfigurationForm>>(
    (form) => {
      onRollover({ total: data?.rollover_count ?? 0, ...(isStartDateChanged && form) });
    },
    [data?.rollover_count, isStartDateChanged, onRollover],
  );

  const onValidateStatus = useCallback(
    (value: FieldPathValue<FieldValues, FieldPath<FieldValues>>) => {
      return !value ? $t({ id: 'students-AnnualRollover-ConfigurationStatusError' }) : true;
    },
    [$t],
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <RolloverHeader
          title={$t(
            { id: 'students-AnnualRollover-ConfigurationLabel' },
            {
              student:
                data.rollover_count === 1
                  ? $t({ id: 'students-AnnualRollover-RolloverStudent' }).toLowerCase()
                  : $t({ id: 'students-AnnualRollover-RolloverStudent-plural' }).toLowerCase(),
              yearLabel: year.name,
              count: data.rollover_count,
            },
          )}
          onClose={onClose}
        />
        <ModalMain>
          <ModalContent active sx={{ pb: 0 }}>
            {reEnrollmentEnabled && <ReEnrollmentWarning mb={2.5} />}
            <Stack gap={2}>
              <Stack direction="row" gap={1.5}>
                <Stack flex={1}>
                  <Select
                    ref={inputRef}
                    disabled
                    value={$t({ id: 'students-AnnualRollover-Enrolled' })}
                    label={$t({ id: 'schoolProperty-Status' })}
                    IconComponent={() => (
                      <IconButton
                        sx={{
                          position: 'absolute',
                          right: 11,
                          pointerEvents: 'none',
                        }}
                        inverse
                      >
                        <LockIcon />
                      </IconButton>
                    )}
                  />
                </Stack>
                <Stack flex={1}>
                  <Controller
                    name="applies_from"
                    control={form.control}
                    rules={{
                      required: true,
                      validate: onValidateDate,
                    }}
                    render={({ field }) => {
                      return (
                        <DateSelect
                          {...field}
                          date={field.value}
                          onSetDate={(d) => {
                            field.onChange(format(d, DEFAULT_DATE_FORMAT_FNS));
                            dropdown.current?.close();
                          }}
                          ref={dropdown}
                          placeholder={$t({ id: 'schoolProperty-Status-AppliesForm' })}
                          error={dateError}
                        />
                      );
                    }}
                  />
                </Stack>
              </Stack>
              {isStartDateChanged && !dateError && (
                <Stack gap={1}>
                  <Typography variant="h3">
                    <FormattedMessage id="students-AnnualRollover-ConfigurationStatusSelect" />
                  </Typography>
                  <Stack
                    pr={1}
                    sx={(theme) => ({
                      width: '50%',
                      '.form-select-search': {
                        maxHeight: 44,
                      },
                      '.form-group__error': {
                        pl: 1.5,
                        pr: 3.5,
                        ...theme.typography.caption,
                      },
                    })}
                  >
                    <FormSelect2
                      name="status_id"
                      labelTextId="schoolProperty-Status"
                      options={statusOptions}
                      disabled={!statusOptions.length}
                      endIcon={
                        !statusOptions.length ? (
                          <SelectOptionsArchivedIcon
                            isAdmin={isAdmin}
                            type={SchoolPropertyType.Status}
                            values={{
                              statusName: $t({ id: 'school-tabs-Statuses-Status-Prospective' }),
                            }}
                            path="/settings/statuses"
                          />
                        ) : undefined
                      }
                      rules={{
                        validate: onValidateStatus,
                      }}
                      propertyType={SchoolPropertyType.Status}
                      required
                      searchEmptyStub={
                        !statusOptions.length ? (
                          <Box p={1}>
                            <SelectOptionsArchivedEmptyStub
                              isAdmin={isAdmin}
                              type={SchoolPropertyType.Status}
                            />
                          </Box>
                        ) : undefined
                      }
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
            {!!data.total && (
              <Stack gap={2} pt={4}>
                <Typography variant="h2">
                  <FormattedMessage id="students-AnnualRollover-ConfigurationPreview" />
                </Typography>

                <RolloverCharts data={data} reEnrollmentEnabled={reEnrollmentEnabled} />
              </Stack>
            )}
          </ModalContent>
        </ModalMain>
        <ModalFooter active>
          <Button endIcon={<CheckIcon />} type="submit">
            <FormattedMessage id="students-RolloverStart" />
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};

const ReEnrollmentWarning: FC<StackProps> = (props) => {
  return (
    <Stack
      direction="row"
      sx={(theme) => ({
        border: theme.mixins.borderValue(),
        borderColor: theme.palette.common.orange,
        borderRadius: 1,
        px: theme.spacing(2.5),
        py: theme.spacing(1.25),
        backgroundColor: theme.palette.common.orange5,
        color: theme.palette.common.orange,
        alignItems: 'center',
      })}
      {...props}
    >
      <Stack
        sx={(theme) => ({
          '&&': {
            color: 'warning.main',
            fontSize: theme.spacing(2.5),
            '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
          },
        })}
      >
        <Attention2Icon />
      </Stack>
      <Box pl={2}>
        <Typography variant="h3" color="inherit">
          <FormattedMessage id="students-AnnualRollover-ParentMustConfirmReEnrollment" />
        </Typography>
        <Box pl={1}>
          <li>
            <Typography variant="h3" color="inherit" component="span">
              <FormattedMessage id="students-AnnualRollover-ParentRejectReEnrollment" />
            </Typography>
          </li>

          <li>
            <Typography variant="h3" color="inherit" component="span">
              <FormattedMessage id="students-AnnualRollover-ParentAcceptReEnrollment" />
            </Typography>
          </li>
        </Box>
      </Box>
    </Stack>
  );
};
