import {
  Assessment,
  AssessmentGroup,
  AssessmentMethod,
  AssessmentMethodBlank,
  AssessmentMethodType,
  AssessmentUpdate,
  GroupBase,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { AssessmentStatuses } from '@schooly/constants';
import isEqual from 'lodash.isequal';
import React, { FC, useCallback, useState } from 'react';
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form-lts';

import useAppLocation from '../../../hooks/useAppLocation';
import AccessDenied from '../../AccessDenied';
import { AssessmentCreateFooter } from './AssessmentCreateFooter';
import { AssessmentFormContent } from './AssessmentFormContent';
import {
  AssessmentCreateLocation,
  AssessmentsCreateModalMode,
  useAssessmentCreateModal,
} from './useAssessmentCreateModal';
import { WithAssessmentFormValidation } from './useAssessmentFormValidation';

export type AssessmentForm = Omit<AssessmentUpdate, 'methods'> & {
  methods: Array<AssessmentMethod | AssessmentMethodBlank>;
  useAssessmentNameAsDisplayName: boolean;
  groups: Array<AssessmentGroup | GroupBase>;
  recurringEndDate?: string;
};
export type AssessmentFormValidate = (
  value: FieldPathValue<FieldValues, FieldPath<FieldValues>>,
  formData: AssessmentForm,
) => Promise<boolean | string>;

export const getAssessmentFormErrorTextId = (
  field: keyof AssessmentForm | keyof AssessmentMethod,
) => {
  if (field === 'methods') return 'assessments-AtLeastOneMethod';
  if (field === 'select_list_id') return 'assessments-GradeTypeRequired';
  if (field === 'recurring_state') return 'assessments-recurring-DateOverlapError';
  return 'input-ErrorRequired';
};

export type AssessmentForCreateForm = Pick<
  Assessment,
  | 'assessment_date'
  | 'name'
  | 'display_name'
  | 'methods'
  | 'groups'
  | 'recurring_state'
  | 'autogenerated'
> & { id?: Assessment['id'] };

export interface AssessmentCreateContentProps {
  assessment?: AssessmentForCreateForm;
}

export const AssessmentCreateContent: FC<AssessmentCreateContentProps> = ({ assessment }) => {
  const location = useAppLocation() as AssessmentCreateLocation;
  const { schoolId } = useAuth();
  const [withPreview, setWithPreview] = useState(false);
  const { actions, relationIds } = useAssessmentCreateModal();

  const selectedGroupsFromLocationState = location?.state?.selectedGroups ?? [];

  const form = useForm<AssessmentForm>({
    defaultValues: assessment
      ? {
          assessment_date: assessment.assessment_date,
          display_name: assessment.display_name,
          methods: assessment.methods,
          name: assessment.name,
          useAssessmentNameAsDisplayName: assessment.name === assessment.display_name,
          groups: assessment.groups.length ? assessment.groups : selectedGroupsFromLocationState,
          recurring_state: assessment.recurring_state,
          autogenerated: assessment.autogenerated,
        }
      : {
          assessment_date: '',
          display_name: '',
          name: '',
          group_ids: selectedGroupsFromLocationState.map((group) => group.id),
          groups: selectedGroupsFromLocationState,
          methods: [],
          useAssessmentNameAsDisplayName: false,
          recurring_state: null,
          autogenerated: false,
        },
    shouldUnregister: false,
    mode: 'onChange',
  });

  const { watch } = form;

  const selectedRecurringState = watch('recurring_state');
  const selectedGroups = watch('groups');
  const autogenerated = watch('autogenerated');

  const defaultValues = form.formState.defaultValues;
  const initRecurringState = defaultValues?.recurring_state;

  const formIsDirty = form.formState.isDirty;
  const groupsAreChanged = !isEqual(defaultValues?.groups, selectedGroups);
  const recurringStateHasChanges = !isEqual(initRecurringState, selectedRecurringState);

  //Before user makes changes to recurringState following count is taken from BE, after - calculated on FE
  const initFollowingCount = initRecurringState?.following_count ?? 0;
  const followingCount = selectedRecurringState?.following_count ?? 0;

  const showRecurringConfirmDialog = Boolean(
    initFollowingCount && followingCount && (formIsDirty || groupsAreChanged),
  );

  const handleSubmit = useCallback<SubmitHandler<AssessmentForm>>(
    (formData, e) => {
      const error =
        !formData.assessment_date ||
        !formData.display_name ||
        !formData.methods.length ||
        !formData.name;

      if (error) return;

      const gradeError = formData.methods.some(
        (m) => m.method_type === AssessmentMethodType.Grade && !m.select_list_id,
      );

      if (gradeError) {
        actions.setMode(AssessmentsCreateModalMode.Settings, 'methods');
        form.trigger('methods');
        return;
      }

      setWithPreview(false);
      const event = e?.nativeEvent;
      if (event instanceof SubmitEvent) {
        const withPreview = Boolean(event.submitter?.hasAttribute('data-preview'));

        setWithPreview(withPreview);

        if (assessment?.id) {
          actions.updateAssessment({
            assessmentId: assessment.id,
            form: formData,
            confirmed: false,
            showRecurringConfirmDialog,
            recurringStateHasChanges,
            withPreview,
            recurrenceId: initRecurringState?.recurrence_id,
          });
        } else actions.createAssessment({ form: formData, withPreview });
      }
    },
    [
      actions,
      assessment?.id,
      form,
      initRecurringState?.recurrence_id,
      recurringStateHasChanges,
      showRecurringConfirmDialog,
    ],
  );

  if (
    (assessment as Assessment)?.assessment_status === AssessmentStatuses.Published ||
    autogenerated
  ) {
    return <AccessDenied />;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <WithAssessmentFormValidation schoolId={schoolId ?? ''} relationIds={relationIds}>
          <AssessmentFormContent />
          <AssessmentCreateFooter withPreview={withPreview} />
        </WithAssessmentFormValidation>
      </form>
    </FormProvider>
  );
};
