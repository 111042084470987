import { StatusRegistration } from '@schooly/api';
import { Child, SyncUser, User } from '@schooly/api';
import { UserType } from '@schooly/api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getUserTypeTextId, isSchoolUserType } from './misc';
import renderUserTypeIcon from './renderUserTypeIcon';
import { getUserHasStudentMembership } from './users';

export function renderUserType(userType: UserType) {
  return (
    <>
      {renderUserTypeIcon(userType)}
      <FormattedMessage id={getUserTypeTextId(userType)} />
    </>
  );
}

export default function renderFullUserType(
  user: User | SyncUser | Child | StatusRegistration,
  userType: UserType,
  renderProfileTypes?: boolean,
) {
  if (!userType) {
    return undefined;
  }

  if (isSchoolUserType(userType)) {
    return renderUserType(userType);
  }

  if (userType === 'child') {
    if (getUserHasStudentMembership(user)) {
      return renderUserType('student');
    }

    if (renderProfileTypes) {
      return renderUserType('child');
    }
  }

  if (userType === 'adult') {
    const hasStaffMembership = 'staff_relations' in user && user.staff_relations?.length;
    const hasParentMembership =
      'guardian_of_relations' in user && user.guardian_of_relations?.length;

    if (hasStaffMembership && hasParentMembership) {
      return (
        <>
          {renderUserType('staff')}
          <span className="ml-3" />
          {renderUserType('parent')}
        </>
      );
    }

    if (hasStaffMembership) {
      return renderUserType('staff');
    }

    if (hasParentMembership) {
      return renderUserType('parent');
    }

    if (renderProfileTypes) {
      return renderUserType('adult');
    }
  }

  return undefined;
}
