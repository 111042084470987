import { ApiError, ValidationError } from '@schooly/api';
import { IntlError } from '@schooly/utils/intl-error';
import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import { Notification, NotificationProps } from './Notification';
import { NotificationsContainer } from './Notifications.styled';
import { NotificationsContext } from './NotificationsContext';
export const DEFAULT_NOTIFICATION_DURATION_MS = 5000;

export const WithNotifications: FC<PropsWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const notificationsMethods = useMemo(() => {
    const dismissNotification = (id: NotificationProps['id'] | undefined) => {
      setNotifications((ns) =>
        ns.filter((n, index) => {
          if (typeof id === 'undefined') {
            return index !== ns.length - 1;
          }

          return id !== n.id;
        }),
      );
    };

    const showNotification = (notification: NotificationProps) => {
      if (!notification.persistent) {
        setTimeout(() => {
          dismissNotification(notification.id);
        }, DEFAULT_NOTIFICATION_DURATION_MS);
      }

      setNotifications((ns) => [...ns, { ...notification, id: notification.id || v4() }]);
    };

    const showError = (error: string | ApiError | IntlError | ValidationError) => {
      if (typeof error === 'string') {
        showNotification({
          message: error,
          type: 'error',
        });
        return;
      }

      if (error instanceof IntlError) {
        const { textId, values } = error;

        showNotification({
          textId,
          values,
          type: 'error',
        });
        return;
      }

      if (error != null && typeof error === 'object' && !error.message && !error.reason) {
        const arr = JSON.stringify(error).split(':');
        const text = arr[arr.length - 1]?.replace(/[[\]{}":]+/g, ' ');

        showNotification({
          message: text,
          textId: text,
          type: 'error',
        });
        return;
      }

      const errorText = error ? error.message || error.reason : '';
      const text = typeof errorText === 'string' ? errorText : undefined;

      showNotification({
        message: text,
        textId: text,
        type: 'error',
      });
    };

    return {
      showError,
      showNotification,
      dismissNotification,
      clearNotifications: () => setNotifications([]),
    };
  }, []);

  return (
    <NotificationsContext.Provider value={{ notifications, ...notificationsMethods }}>
      {children}
      <NotificationsContainer>
        {notifications.map((n) => (
          <Notification notification={n} key={n.id} toast />
        ))}
      </NotificationsContainer>
    </NotificationsContext.Provider>
  );
};
