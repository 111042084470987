import { GET_GROUP_SUBJECTS_QUERY, useGetGroupSubjectsQuery } from '@schooly/api';
import { createSubjects } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useSchool } from '../../../../../hooks/useSchool';
import { SchoolSettingsLayout } from '../../../SchoolSettingsLayout';
import {
  SchoolTuneSubjectsForm,
  SchoolTuneSubjectsModalContent,
} from './SchoolTuneSubjectsModalContent';

export const SchoolTuneSubjectsModal: FC = () => {
  const navigate = useNavigate();
  const { showError } = useNotifications();
  const { schoolId = '', currentSchool } = useSchool();
  const { data } = useGetGroupSubjectsQuery(
    { schoolId },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );
  const queryClient = useQueryClient();

  const [saving, setSaving] = useState(false);

  const handleClose = useCallback(() => {
    if (saving) {
      return;
    }

    navigate({ pathname: '/settings/tune', search: '?tab=subjects' });
  }, [navigate, saving]);

  const handleSubmit = useCallback<SubmitHandler<SchoolTuneSubjectsForm>>(
    async (data) => {
      if (!schoolId || !data.subjects) {
        return;
      }

      setSaving(true);

      try {
        await createSubjects(
          schoolId,
          data.subjects.map((subject, index) => ({
            name: subject.name ?? '',
            order_for_assessments: index + 1,
          })),
        );

        queryClient.invalidateQueries([GET_GROUP_SUBJECTS_QUERY]);
        navigate({ pathname: '/settings/tune', search: '?tab=subjects' });
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }

      setSaving(false);
    },
    [navigate, queryClient, schoolId, showError],
  );

  return (
    <SchoolSettingsLayout>
      <SchoolTuneSubjectsModalContent
        isSaving={saving}
        subjects={data?.subjects}
        title={currentSchool?.name || ''}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </SchoolSettingsLayout>
  );
};
