import { SuggestedChangeDataSet } from '@schooly/constants';
import { FlagIcon, TypographyWithOverflowHint } from '@schooly/style';
import { getNationalitiesLabelIds } from '@schooly/utils/get-nationalities-label-ids';
import { getUserNationalityValues } from '@schooly/utils/user-helpers';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useProfile } from '../../../context/profile/useProfile';
import NationalityModal from './NationalityModal';
import ProfileProperty from './ProfileProperty';

interface NationalityPropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}

const NationalityProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
}: NationalityPropertyParams) => {
  const { user, canEditProfile, hasEditingPermission } = useProfile();
  const { formatMessage } = useIntl();

  const nationalities = useMemo(() => {
    const nationalities = getUserNationalityValues(user!);

    if (!nationalities.length) return '';

    return getNationalitiesLabelIds(nationalities)
      .map((id) => formatMessage({ id }))
      .join(', ');
  }, [formatMessage, user]);

  return (
    <ProfileProperty
      value={nationalities}
      canEdit={canEditProfile && hasEditingPermission}
      params={{ size, iconColor, color }}
      renderIcon={() => <FlagIcon />}
      valueTextId="peopleDetail-Nationality"
      editValueTextId="peopleDetail-AddNewNationality"
      dataSet={SuggestedChangeDataSet.Nationality}
      modalController={({ isOpen, hide }) => <NationalityModal isOpen={isOpen} onClose={hide} />}
      renderValue={(v) => (
        <TypographyWithOverflowHint variant="h3" color="inherit">
          {v}
        </TypographyWithOverflowHint>
      )}
    />
  );
};

export default NationalityProperty;
