import {
  GET_EMPLOYMENTS_QUERY,
  ProfileSearchResult,
  removeUser,
  useAddEmploymentMutation,
  useAddFamilyMutation,
  useAddStaffMutation,
  UserForDuplicatesCheck,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CreateAdultForm,
  CreateAdultModal,
} from '../../../components/common/CreateAdultModal/CreateAdultModal';
import { converCreateAdultFormToNewAdult } from '../../../components/common/CreateAdultModal/utils';
import {
  AddEmploymentCaseForm,
  AddEmploymentCaseModal,
} from '../../../components/common/EmploymentCases/AddEmploymentCaseModal';
import { convertEmploymentCaseFormToRegistrationUpdate } from '../../../components/common/EmploymentCases/utils';
import { useRouter } from '../../../context/router/useRouter';
import useAppLocation from '../../../hooks/useAppLocation';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { AddStaffSelectAdultModal } from './AddStaffSelectAdultModal';

enum State {
  SelectAdult,
  CreateAdult,
}

export const AddStaffModal: FC = () => {
  const { schoolId } = useAuth();
  const { goBack } = useRouter();
  const navigate = useNavigate();
  const [state, setState] = useState<State>(State.SelectAdult);
  const [selectedAdult, setSelectedAdult] = useState<ProfileSearchResult | null>(null);
  const { showError, showNotification } = useNotifications();
  const location = useAppLocation();
  const queryClient = useQueryClient();
  const invalidateQueries = useInvalidateListQueriesFor('staff');
  const addEmploymentMutation = useAddEmploymentMutation();
  const [selectedDuplicateId, setSelectedDuplicateId] = useState('');

  const addStaffMutation = useAddStaffMutation();
  const addFamilyMutation = useAddFamilyMutation();
  const { profileStaffCustomFields } = useCustomFields({ refetchOnMount: 'always' });

  const checkDuplicates =
    selectedAdult?.user_id &&
    !selectedAdult.adult_associations.length &&
    !selectedAdult.child_associations.length;

  let userForDuplicatesCheck: UserForDuplicatesCheck | undefined;
  if (checkDuplicates) {
    userForDuplicatesCheck = {
      given_name: selectedAdult.given_name,
      known_as: selectedAdult.known_as,
      last_name: selectedAdult.last_name,
      date_of_birth: selectedAdult?.date_of_birth ?? '',
      id: selectedAdult.user_id,
      role: SchoolUserRole.Staff,
    };
  }

  const handleNewAdult = useCallback(
    async (data: CreateAdultForm) => {
      if (!schoolId) return;

      const adult = converCreateAdultFormToNewAdult(data);

      addFamilyMutation.mutate(
        {
          schoolId,
          family: {
            children: [],
            adults: [adult],
          },
        },
        {
          onSuccess: (data) => {
            invalidateQueries();
            setState(State.SelectAdult);
            setSelectedAdult({
              ...adult,
              adult_associations: [],
              child_associations: [],
              user_id: data.adults[0],
            });
          },
          onError: showError,
        },
      );
    },
    [addFamilyMutation, invalidateQueries, schoolId, showError],
  );

  const onSubmit = useCallback(
    async (data: AddEmploymentCaseForm) => {
      if (!selectedAdult || !schoolId) return;

      if (selectedDuplicateId) {
        const employment = convertEmploymentCaseFormToRegistrationUpdate(data);
        addEmploymentMutation.mutate(
          {
            schoolId,
            staffId: selectedDuplicateId,
            employment,
          },
          {
            onSuccess: () => {
              removeUser(selectedAdult.user_id, schoolId);
              showNotification({
                textId: 'confirmation-StaffEdit',
                type: 'success',
                actions: [
                  {
                    textId: 'staff-ViewStaff',
                    handler: () =>
                      navigate(`/staff/${selectedDuplicateId}`, {
                        state: location.state,
                      }),
                    buttonColor: 'light',
                  },
                ],
              });
              invalidateQueries();
              queryClient.invalidateQueries([GET_EMPLOYMENTS_QUERY]);
              goBack();
            },
            onError: showError,
          },
        );
      } else {
        addStaffMutation.mutate(
          {
            schoolId,
            staffId: selectedAdult.user_id,
            employment: convertEmploymentCaseFormToRegistrationUpdate(data),
          },
          {
            onSuccess: (data) => {
              showNotification({
                textId: 'confirmation-StaffAdd',
                values: { userName: getUserFullName(selectedAdult) },
                type: 'success',
                actions: [
                  {
                    textId: 'staff-ViewStaff',
                    handler: () =>
                      navigate(`/staff/${data.staff_school_relation.relation_id}`, {
                        state: location.state,
                      }),
                    buttonColor: 'light',
                  },
                ],
              });
              invalidateQueries();
              goBack();
            },
            onError: showError,
          },
        );
      }
    },
    [
      selectedAdult,
      schoolId,
      selectedDuplicateId,
      addEmploymentMutation,
      showError,
      showNotification,
      invalidateQueries,
      queryClient,
      goBack,
      navigate,
      location.state,
      addStaffMutation,
    ],
  );

  if (!schoolId) return null;

  if (selectedAdult) {
    return (
      <AddEmploymentCaseModal
        schoolId={schoolId}
        open
        title={getUserFullName(selectedAdult)}
        onClose={() => setSelectedAdult(null)}
        isSaving={addStaffMutation.isLoading || addEmploymentMutation.isLoading}
        onSubmit={onSubmit}
        user={userForDuplicatesCheck}
        setSelectedDuplicateId={setSelectedDuplicateId}
        selectedDuplicateId={selectedDuplicateId}
      />
    );
  }

  if (state === State.CreateAdult)
    return (
      <CreateAdultModal
        open
        onClose={() => setState(State.SelectAdult)}
        isSaving={addFamilyMutation.isLoading}
        formCustomFields={profileStaffCustomFields}
        onCreateAdult={handleNewAdult}
      />
    );

  if (state === State.SelectAdult)
    return (
      <AddStaffSelectAdultModal
        open
        onClose={goBack}
        onAdultClick={setSelectedAdult}
        onCreateAdult={() => setState(State.CreateAdult)}
        schoolId={schoolId}
      />
    );

  return null;
};
