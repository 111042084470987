import { Box, BoxProps, Icon, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelectContent, DropdownSelect, SelectSearchInput } from '@schooly/components/filters';
import { DATE_FORMAT } from '@schooly/constants';
import {
  CalendarIcon,
  CheckIcon,
  GridRowItem,
  GridRowStyled,
  TagSelect,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ExtendedFieldError } from 'apps/web/src/components/ui/Input/utils';
import { endOfMonth, endOfYear, format, isEqual } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

import { RecurringForm } from './RecurringModal';

export type Preset = {
  label: string;
  id: string;
  tagLabel?: string | undefined;
  value: string;
};

type EndDateDropdownProps = {
  presets: Preset[];
  onClick: () => void;
  error: ExtendedFieldError;
  helperText?: string;
  onValidate: (v?: string, f?: RecurringForm) => boolean | string;
  highlightedDays?: Date[];
  selected: boolean;
};

export const EndDateDropdown: FC<EndDateDropdownProps> = ({
  presets,
  onClick,
  error: propsError,
  helperText,
  onValidate,
  highlightedDays,
  selected,
}) => {
  const { $t } = useIntl();
  const { control, formState } = useFormContext<RecurringForm>();
  const customPresetName = $t({ id: 'recurring-Custom' });
  const { ref } = useResizeDetector<HTMLDivElement>();
  const name = 'ends.byDate';
  const error: ExtendedFieldError = get(formState.errors, name);

  return (
    <Box ref={ref}>
      <Box height={40} onClick={onClick}>
        <Controller
          name={name}
          control={control}
          rules={{
            validate: onValidate,
          }}
          render={({ field }) => {
            return (
              <EndDateSelect
                onSetDate={(d) => {
                  field.onChange(format(d, DEFAULT_DATE_FORMAT_FNS));
                }}
                date={field.value as string}
                presets={presets}
                placeholder={$t({ id: 'datepicker-EndDate' })}
                error={error || propsError}
                highlightedDays={highlightedDays}
                selected={selected}
                generalPresetItem={{
                  id: customPresetName,
                  value: '',
                  label: customPresetName,
                }}
              />
            );
          }}
        />
      </Box>

      {Boolean(error || helperText) && (
        <Box pt={0.5} px={1.5}>
          <Typography
            variant="caption"
            color={error ? 'error.main' : 'primary.main'}
            width={ref.current?.clientWidth ?? 0}
          >
            {error?.message || helperText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

type EndsDateSelectProps = {
  date?: string;
  onSetDate: (v: Date) => void;
  presets: Preset[];
  emptyValue?: string;
  placeholder?: string;
  generalPresetItem?: Preset;
  error?: ExtendedFieldError;
  highlightedDays?: Date[];
  selected?: boolean;
};

export const EndDateSelect: FC<EndsDateSelectProps> = ({
  date: dateString,
  onSetDate,
  presets,
  emptyValue,
  placeholder,
  generalPresetItem,
  error: propsError,
  highlightedDays,
  selected,
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const [presetListOpen, setPresetListOpen] = useState(Boolean(presets.length));
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const date = useMemo(() => (dateString ? newDateTimezoneOffset(dateString) : null), [dateString]);
  const value = date ? format(date, DATE_FORMAT) : emptyValue;
  const error = Boolean(propsError && !open);
  const presetsIsActive = presetListOpen && open;
  const showSearchInput = searchActive || !value;

  const filteredPresetList = useMemo(() => {
    if (!presets.length) return [];
    const list = generalPresetItem ? [generalPresetItem, ...presets] : presets;

    if (!query) return list;

    return list.filter(({ label }) => label.toLowerCase().includes(query.toLowerCase()));
  }, [generalPresetItem, presets, query]);

  const handlePresetClick = useCallback(
    (p: Preset) => {
      if (p.id === generalPresetItem?.id) {
        setPresetListOpen(false);
        return;
      }
      onSetDate(new Date(p.value));
      dropdown.current?.close();
    },
    [generalPresetItem, onSetDate],
  );
  const handleSetDate = useCallback(
    (d: Date) => {
      onSetDate(d);
      dropdown.current?.close();
    },
    [onSetDate],
  );
  const getDayStyleProps = useCallback(
    (d: Date) => {
      const isHighlighted = highlightedDays?.some((date) => isEqual(date, d));

      return isHighlighted
        ? {
            color: 'primary.main',
          }
        : {};
    },
    [highlightedDays],
  );

  const renderContent = useCallback(() => {
    if (presetListOpen) {
      return (
        <Stack
          m={0.5}
          sx={() => ({
            overflow: 'scroll',
            cursor: 'pointer',
          })}
          maxHeight={174}
        >
          {Boolean(filteredPresetList.length) ? (
            filteredPresetList.map((p) => {
              const isGeneralPresetItem = p.id === generalPresetItem?.id;

              const selected = Boolean(
                !isGeneralPresetItem && date && isEqual(newDateTimezoneOffset(p.value), date),
              );

              return (
                <PresetRow
                  preset={p}
                  key={p.id}
                  selected={selected}
                  onPresetClick={handlePresetClick}
                  sx={{
                    borderRadius: '6px',
                    ...(isGeneralPresetItem && {
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                    }),
                  }}
                />
              );
            })
          ) : (
            <Typography p={1}>
              <FormattedMessage id="input-NoOptionsFound" />
            </Typography>
          )}
        </Stack>
      );
    }

    return (
      <DateSelectContent date={date} onSetDate={handleSetDate} getDayStyleProps={getDayStyleProps}>
        <EndDateFooter
          onSetDate={handleSetDate}
          onPresetsClick={() => {
            setPresetListOpen(true);
          }}
          withPresets={Boolean(presets.length)}
        />
      </DateSelectContent>
    );
  }, [
    date,
    filteredPresetList,
    generalPresetItem?.id,
    getDayStyleProps,
    handlePresetClick,
    handleSetDate,
    presetListOpen,
    presets.length,
  ]);

  useEffect(() => {
    if (presetsIsActive && showSearchInput) {
      inputRef.current?.focus();
    }
  }, [presetsIsActive, showSearchInput]);

  const renderHeaderContent = useCallback(() => {
    if (presetsIsActive && showSearchInput) {
      return <SelectSearchInput ref={inputRef} value={query} onChangeText={setQuery} />;
    }

    const title = open && !value ? placeholder : value;

    return title ? (
      <Typography
        onClick={() => {
          if (presetsIsActive) setSearchActive(true);
        }}
        variant="h3"
        color={value ? 'primary.main' : 'common.grey'}
        zIndex={presetsIsActive ? 1 : undefined}
      >
        {title}
      </Typography>
    ) : null;
  }, [open, placeholder, presetsIsActive, query, showSearchInput, value]);

  return (
    <Box
      sx={{
        div: {
          borderColor: error ? 'error.main' : undefined,
        },
        '& .MuiTypography-root': {
          ...(!selected && value && { color: 'text.secondary' }),
          ...(error && value && { color: 'error.main' }),
        },
      }}
    >
      <DropdownSelect
        ref={dropdown}
        hasValues={!!value || (presetListOpen && open)}
        onClickInputArea={() => inputRef.current?.focus()}
        placeholder={placeholder}
        onClose={() => {
          if (searchActive) setSearchActive(false);
          if (query) setQuery('');
        }}
        startIcon={
          !presetListOpen ? (
            <Icon sx={{ color: 'common.grey' }}>
              <CalendarIcon />
            </Icon>
          ) : null
        }
        renderContent={renderContent}
        onToggle={setOpen}
        layoutStyleProps={{
          width: 320,
        }}
      >
        {renderHeaderContent}
      </DropdownSelect>
    </Box>
  );
};

type PresetRowProps = {
  isSelected?: boolean;
  preset: Preset;
  onPresetClick: (p: Preset) => void;
  selected?: boolean;
} & Omit<BoxProps, 'children'>;

export const PresetRow: FC<PresetRowProps> = ({
  isSelected,
  preset,
  onPresetClick,
  selected,
  ...props
}) => {
  return (
    <GridRowStyled noBorder onClick={() => onPresetClick(preset)} {...props}>
      <GridRowItem
        noPadding
        sx={{
          paddingLeft: 0.75,
          paddingY: 0.5,
          width: '100%',
          gap: 1,
          cursor: 'pointer',
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <TypographyWithOverflowHint
            color={isSelected ? 'primary.main' : 'common.grey2'}
            noWrap
            variant="h3"
          >
            {preset.label}
          </TypographyWithOverflowHint>

          {preset.tagLabel && (
            <TagSelect
              size="small"
              sx={(theme) => ({
                cursor: 'pointer',
                maxHeight: 18,
                bgcolor: isSelected ? theme.palette.background.paper : undefined,
              })}
              label={preset.tagLabel}
            />
          )}
        </Stack>
        {selected && (
          <Icon
            sx={{
              color: 'primary.main',
              mr: 1.25,
            }}
          >
            <CheckIcon />
          </Icon>
        )}
      </GridRowItem>
    </GridRowStyled>
  );
};

type EndDateFooterProps = {
  onSetDate: (d: Date) => void;
  onPresetsClick?: () => void;
  withPresets: boolean;
};

export const EndDateFooter: FC<EndDateFooterProps> = ({
  onSetDate,
  onPresetsClick,
  withPresets,
}) => {
  return (
    <Stack
      gap={1}
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={(theme) => ({
        gap: 3,
        py: 1.5,
        '.MuiTypography-root': {
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.common.main2,
          },
          color: theme.palette.common.grey2,
        },
      })}
    >
      <Typography onClick={() => onSetDate(endOfMonth(new Date()))}>
        <FormattedMessage id="datepicker-EndOfMonth" />
      </Typography>
      <Typography onClick={() => onSetDate(endOfYear(new Date()))}>
        <FormattedMessage id="datepicker-EndOfYear" />
      </Typography>
      {withPresets && (
        <Typography onClick={onPresetsClick}>
          <FormattedMessage id="datepicker-Presets" />
        </Typography>
      )}
    </Stack>
  );
};
