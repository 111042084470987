export const iosAppId = '1584817556';
const iosApp = `https://apps.apple.com/my/app/schooly/id${iosAppId}`;

export const androidAppId = 'com.schoolymobile';
const androidApp = `https://play.google.com/store/apps/details?id=${androidAppId}`;

export const MobileLinks = {
  iosApp,
  androidApp,
};
