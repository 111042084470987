import {
  getPrivateImage,
  School,
  SchoolRelation,
  SyncUser,
  UserRolePermission,
  UserRolePermissions,
  useSyncUserQuery,
  WithAvatar,
} from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { Auth } from 'aws-amplify';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { createContext, useContext } from 'react';

type UserWithPermissions = SyncUser & { permissions: UserRolePermissions[] };

type ChangePasswordParams = {
  oldPassword: string;
  newPassword: string;
};

const UNAUTHORIZED_MESSAGE = 'Unauthorized';

export const WithUser: FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>();

  const { data } = useSyncUserQuery({
    enabled: isAuthenticated,
    retry: (failureCount, error) => {
      if (error.message === UNAUTHORIZED_MESSAGE) return false;
      if (failureCount > 3) return false;

      return true;
    },
    refetchOnMount: 'always',
  });

  const { logout, changePassword } = useMemo(() => {
    return {
      logout: async () => {
        await Auth.signOut();
        setAuthenticated(false);
      },
      changePassword: async ({ oldPassword, newPassword }: ChangePasswordParams) => {
        const user = await Auth.currentAuthenticatedUser();
        return Auth.changePassword(user, oldPassword, newPassword);
      },
    };
  }, []);

  const userWithPermissions = useMemo(() => {
    if (isAuthenticated === false) return null;
    if (isAuthenticated === undefined || !data) return undefined;

    return { ...data.user, permissions: data.permissions };
  }, [data, isAuthenticated]);

  useEffect(() => {
    if (!userWithPermissions) return;

    getPrivateImage(userWithPermissions?.profile_picture || null);
    setupSessionRefreshTimeout();

    return () => destroySessionRefreshTimeout();
  }, [userWithPermissions]);

  const authenticate = useCallback(async () => {
    try {
      await Auth.currentSession();
      setAuthenticated(true);
    } catch {
      setAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  return (
    <UserContext.Provider value={{ user: userWithPermissions, logout, changePassword }}>
      {children}
    </UserContext.Provider>
  );
};

export interface UserContextParams {
  user?: UserWithPermissions | null;
  logout: () => Promise<void>;
  changePassword: (v: ChangePasswordParams) => Promise<any>;
}

export const UserContext = createContext<UserContextParams>({
  logout: async () => {},
  changePassword: async () => {},
});
export const useUserContext = () => useContext(UserContext);

let sessionRefreshTimeout: number | null = null;

function destroySessionRefreshTimeout() {
  if (!sessionRefreshTimeout) {
    return;
  }

  window.clearTimeout(sessionRefreshTimeout);
  sessionRefreshTimeout = null;
}

async function setupSessionRefreshTimeout() {
  if (sessionRefreshTimeout) {
    destroySessionRefreshTimeout();
  }

  try {
    const session = await Auth.currentSession();
    const idTokenExpire = session.getIdToken().getExpiration();
    const refreshToken = session.getRefreshToken();
    const currentTime = new Date().valueOf();

    // Refresh auth token 1 minute before current token expires
    sessionRefreshTimeout = window.setTimeout(async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      if (!currentUser) {
        return;
      }

      currentUser.refreshSession(refreshToken, (err: Error) => {
        if (err) {
          console.error('Error on refreshing session:', err);
        }

        setupSessionRefreshTimeout();
      });
    }, idTokenExpire * 1000 - currentTime - 60000);
  } catch (err) {
    // This error will only trigger when there is no information about current user
    if (err !== 'No current user') {
      throw err;
    }
  }
}

export interface AuthContextParams {
  schoolId?: string;
  relationId?: string;
  token?: string;
  permissions: UserRolePermission[];
  currentUser?: SyncUser;
  currentStaff?: SchoolRelation & WithAvatar;
  username?: string;
  currentSchool?: School;
}

const defaultState: AuthContextParams = {
  permissions: [],
};
export const AuthContext = createContext<AuthContextParams>(defaultState);
export const useAuth = () => useContext(AuthContext);

export const WithAuth: FC<PropsWithChildren> = ({ children }) => {
  const { user: syncUser } = useUserContext();

  const params: AuthContextParams = useMemo(() => {
    const state: AuthContextParams = { ...defaultState };

    if (!syncUser) return state;

    const { account_email, last_selected_school_id, school_relations, permissions } = syncUser;

    if (school_relations.length) {
      const lastSelectedSchool = last_selected_school_id
        ? school_relations.find(
            (s) => s.relation_id === last_selected_school_id && s.role === SchoolUserRole.Staff,
          )
        : school_relations.find((s) => s.role === SchoolUserRole.Staff);

      const schoolRelation =
        lastSelectedSchool ??
        school_relations.find(
          (s) => s.school.id === last_selected_school_id && s.role === SchoolUserRole.Staff,
        );

      state.schoolId = schoolRelation?.school.id;
      state.relationId = schoolRelation?.relation_id;

      if (schoolRelation) {
        const { relation_id, title, given_name, last_name, known_as, profile_picture } =
          schoolRelation;

        state.currentStaff = {
          relation_id,
          title,
          given_name,
          last_name,
          known_as,
          profile_picture,
        };

        state.currentSchool = schoolRelation.school;
      }
    }

    state.permissions = permissions;
    state.currentUser = syncUser;
    state.username = account_email;

    return state;
  }, [syncUser]);

  return <AuthContext.Provider value={params}>{children}</AuthContext.Provider>;
};
