import { Icon, Skeleton, Stack, Typography } from '@mui/material';
import { getTypedObjectKeys, GroupUserType } from '@schooly/api';
import { getSelectedItemsWithGrouping } from '@schooly/components/filters';
import { ArchiveIcon, TagSelectProperty } from '@schooly/style';
import React, { PropsWithChildren, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getUserTypeTextId } from '../../../../helpers/misc';
import { LimitedToCategory } from './LimitedToSelect';
import {
  getLimitedToGroupWithOptionsMap,
  getLimitedToRelatedCategory,
  LimitedToOption,
} from './utils';

interface LimitedToHeaderProps extends PropsWithChildren {
  selectedOptions: Partial<Record<LimitedToCategory, LimitedToOption[]>>;
  isOpen: boolean;
  onRemove: (option: LimitedToOption, category: LimitedToCategory) => void;
  onRemoveMultiple: (options: LimitedToOption[], category: LimitedToCategory) => void;
  hasLimitedTo: boolean;
  userType: GroupUserType;
  isLoading: boolean;
  options: Partial<Record<LimitedToCategory, LimitedToOption[]>>;
  setHoveredGroupTagId: React.Dispatch<React.SetStateAction<string>>;
}

export const LimitedToHeader = ({
  selectedOptions,
  isOpen,
  onRemove,
  children,
  hasLimitedTo,
  userType,
  isLoading,
  onRemoveMultiple,
  options,
  setHoveredGroupTagId,
}: LimitedToHeaderProps) => {
  const { $t } = useIntl();

  const [displayedCategories, displayedCategoriesWithGrouping] = useMemo(() => {
    const categories = getTypedObjectKeys(selectedOptions).filter(
      (category) => selectedOptions[category]?.length,
    );
    const categoriesWithGrouping = categories.filter(
      (category) => selectedOptions[category]?.[0].propGroupId !== undefined,
    );
    return [categories, categoriesWithGrouping];
  }, [selectedOptions]);

  //Updates selected options to replace tags with group tags when needed
  const selectedOptionsWithGrouping = useMemo(() => {
    if (!displayedCategoriesWithGrouping.length) return selectedOptions;

    const optionsWithGrouping = { ...selectedOptions };

    displayedCategoriesWithGrouping.forEach((category) => {
      const groupCategory = getLimitedToRelatedCategory(category);

      const selectedOptionsForGrouping =
        selectedOptions[category]?.map((o) => ({
          id: o.value.toString(),
          groupId: o.propGroupId?.toString() ?? null,
        })) ?? [];

      const itemsWithGrouping = getSelectedItemsWithGrouping(
        selectedOptionsForGrouping,
        getLimitedToGroupWithOptionsMap(category, options),
      );

      const selectedOptionsWithGrouping: LimitedToOption[] = itemsWithGrouping
        .map((item) => {
          return item.isGroup
            ? options[groupCategory]?.find((g) => item.id === g.value)
            : selectedOptions[category]?.find((i) => i.value === item.id);
        })
        .filter((item): item is LimitedToOption => item !== undefined);

      optionsWithGrouping[category] = selectedOptionsWithGrouping;
    });

    return optionsWithGrouping;
  }, [displayedCategoriesWithGrouping, options, selectedOptions]);

  const mainContent = useMemo(
    () => (
      <>
        {!hasLimitedTo && isOpen && (
          <Typography variant="h3" color="text.secondary">
            <FormattedMessage
              id="groups-LimitedToPlaceholder"
              values={{
                userType: $t({
                  id: getUserTypeTextId(userType, true),
                }).toLowerCase(),
              }}
            />
          </Typography>
        )}
        {displayedCategories.map((category, cIndex) => (
          <React.Fragment key={category}>
            {selectedOptionsWithGrouping[category]?.map((option, oIndex) => {
              const isGroupProp = option.isPropGroup;
              const onDelete = isGroupProp
                ? () =>
                    onRemoveMultiple(
                      options[category]?.filter((o) => o.propGroupId === option.value) ?? [],
                      category,
                    )
                : () => onRemove(option, category);

              return (
                <React.Fragment key={option.value}>
                  <TagSelectProperty
                    avatar={
                      option?.archived ? (
                        <Icon>
                          <ArchiveIcon />
                        </Icon>
                      ) : undefined
                    }
                    label={option.label}
                    size="small"
                    property={option}
                    onDelete={isOpen ? onDelete : undefined}
                    onMouseEnter={
                      isGroupProp ? () => setHoveredGroupTagId(option.value.toString()) : undefined
                    }
                    onMouseLeave={isGroupProp ? () => setHoveredGroupTagId('') : undefined}
                  />

                  {oIndex < selectedOptionsWithGrouping[category]!.length - 1 && <span>+</span>}
                </React.Fragment>
              );
            })}
            {cIndex < displayedCategories.length - 1 && <span>,</span>}
          </React.Fragment>
        ))}
        {children}
      </>
    ),
    [
      $t,
      children,
      displayedCategories,
      hasLimitedTo,
      isOpen,
      onRemove,
      onRemoveMultiple,
      options,
      selectedOptionsWithGrouping,
      setHoveredGroupTagId,
      userType,
    ],
  );

  return (
    <Stack
      sx={(theme) => ({
        padding: theme.spacing(0.5, 4, 0.25, 0),
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: 0.75,
        width: isLoading ? '100%' : 'inherit',
      })}
    >
      {isLoading ? <Skeleton /> : mainContent}
    </Stack>
  );
};
