import { IconButton, Stack, Typography } from '@mui/material';
import { PossibleSibling } from '@schooly/api';
import { EditIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import EditChildrenModal from '../../../components/common/EditChildrenModal';
import EmptyStub from '../../../components/common/ProfileModal/EmptyStub';
import ProfileCard from '../../../components/common/ProfileModal/ProfileCard';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { ProfileModalMode } from '../../../context/profile/helpers';
import { useProfile } from '../../../context/profile/useProfile';
import useFlag from '../../../hooks/useFlag';

export const ProfileModalChildren: FC = () => {
  const [isDialogOpen, showDialog, hideDialog] = useFlag();

  const { user, userType, actions } = useProfile();

  if (!user || !userType) {
    return <EmptyStub mode={ProfileModalMode.Family} />;
  }

  const { adult_associations } = user as unknown as { adult_associations: PossibleSibling[] };
  const showEditButton = !(userType === 'adult' || userType === 'parent');

  if (!adult_associations?.length) {
    return <EmptyStub mode={ProfileModalMode.Family} />;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="section-Family" />
          </Typography>
          {!!adult_associations.length && <Counter>{adult_associations.length}</Counter>}
        </Stack>

        {showEditButton && (
          <IconButton onClick={showDialog} inverse>
            <EditIcon />
          </IconButton>
        )}
      </Stack>

      <Typography variant="h4">
        <FormattedMessage id="userType-child-plural" />
      </Typography>

      <div className="cards-wrapper">
        {adult_associations.map((item) => (
          <ProfileCard
            key={item.user_id}
            user={
              !!item.school_user_relation && !!Object.keys(item.school_user_relation).length
                ? item.school_user_relation
                : item
            }
            userType={
              !!item.school_user_relation && !!Object.keys(item.school_user_relation).length
                ? 'student'
                : 'child'
            }
          />
        ))}
      </div>

      <EditChildrenModal
        isOpen={isDialogOpen}
        userType={userType}
        editedUser={user}
        onClose={hideDialog}
        onStudentAdd={actions.invalidateProfileCache}
      />
    </>
  );
};
