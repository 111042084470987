import { FC } from 'react';

export const BigArrowSvg: FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11V10L50 29.9615L100 10V11L50 31.0385L0 11ZM50 55L0 35V20L50 40L100 20V35L50 55ZM0 70L50 90L100 70V40L50 60L0 40V70Z"
      fill="#E7E9F4"
    />
  </svg>
);
