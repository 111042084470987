import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8306 12.7656H15.8306V7.07812H16.8306V12.7656ZM18.604 7.89062H14.0728V7.07812H18.604V7.89062Z"
      fill="#24275B"
    />
    <path
      d="M10.582 12.7656H9.52344L11.6641 7.07812H12.3359L12.2695 7.88672L10.582 12.7656ZM11.9766 7.88672L11.9023 7.07812H12.5781L14.7266 12.7656H13.668L11.9766 7.88672ZM13.6016 11.4648H10.5039V10.6523H13.6016V11.4648Z"
      fill="#24275B"
    />
    <path
      d="M8.39624 12.7656H7.39624V7.07812H8.39624V12.7656ZM10.1697 7.89062H5.63843V7.07812H10.1697V7.89062Z"
      fill="#24275B"
    />
    <path
      d="M4.56177 11.2969C4.56177 11.1432 4.52922 11.013 4.46411 10.9062C4.40161 10.7969 4.28312 10.6966 4.10864 10.6055C3.93416 10.5117 3.68286 10.4141 3.35474 10.3125C3.08651 10.2318 2.83781 10.1393 2.60864 10.0352C2.38208 9.93099 2.18416 9.8112 2.01489 9.67578C1.84562 9.53776 1.71281 9.37891 1.61646 9.19922C1.52271 9.01693 1.47583 8.80729 1.47583 8.57031C1.47583 8.26042 1.56177 7.98828 1.73364 7.75391C1.90552 7.51693 2.1438 7.33203 2.44849 7.19922C2.75317 7.06641 3.10604 7 3.50708 7C3.93416 7 4.30135 7.07812 4.60864 7.23438C4.91593 7.38802 5.15161 7.59635 5.31567 7.85938C5.48234 8.1224 5.56567 8.41536 5.56567 8.73828H4.55786C4.55786 8.55859 4.5188 8.39844 4.44067 8.25781C4.36515 8.11458 4.24927 8.00391 4.09302 7.92578C3.93677 7.84505 3.73755 7.80469 3.49536 7.80469C3.2688 7.80469 3.08 7.83854 2.92896 7.90625C2.77791 7.97396 2.66593 8.0651 2.59302 8.17969C2.5201 8.29427 2.48364 8.42448 2.48364 8.57031C2.48364 8.70833 2.52791 8.83073 2.61646 8.9375C2.705 9.04427 2.83911 9.14193 3.0188 9.23047C3.19849 9.31641 3.42244 9.39974 3.69067 9.48047C4.11255 9.60286 4.46281 9.74609 4.74146 9.91016C5.0201 10.0742 5.22713 10.2682 5.36255 10.4922C5.50057 10.7161 5.56958 10.9818 5.56958 11.2891C5.56958 11.6094 5.48625 11.8867 5.31958 12.1211C5.15291 12.3529 4.91724 12.5312 4.61255 12.6562C4.30786 12.7812 3.94979 12.8438 3.53833 12.8438C3.26489 12.8438 2.99536 12.8073 2.72974 12.7344C2.46411 12.6589 2.22323 12.5469 2.00708 12.3984C1.79354 12.2474 1.62166 12.0586 1.49146 11.832C1.36385 11.6055 1.30005 11.3385 1.30005 11.0312H2.30786C2.30786 11.2135 2.33911 11.3685 2.40161 11.4961C2.46411 11.6237 2.55135 11.7292 2.66333 11.8125C2.77531 11.8932 2.90552 11.9518 3.05396 11.9883C3.205 12.0247 3.36646 12.043 3.53833 12.043C3.76489 12.043 3.95369 12.0117 4.10474 11.9492C4.25578 11.8841 4.36906 11.7956 4.44458 11.6836C4.52271 11.5716 4.56177 11.4427 4.56177 11.2969Z"
      fill="#24275B"
    />
  </svg>
);
