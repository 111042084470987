import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 3C15 2.44772 14.5523 2 14 2H6C5.44772 2 5 2.44772 5 3V15.5858C5 16.4767 6.07714 16.9229 6.7071 16.2929L9.29291 13.7071C9.68344 13.3166 10.3166 13.3166 10.7071 13.7071L13.2929 16.2929C13.9229 16.9229 15 16.4767 15 15.5858V3Z"
      fill="#B3BBCB"
    />
  </svg>
);
