/* eslint-disable simple-import-sort/imports */
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { EditIcon } from '@schooly/style';
import { useProfile } from '../../../context/profile/useProfile';
import useFlag from '../../../hooks/useFlag';
import MembershipNumberModal from './MembershipNumberModal';
import AddButton from '../AddButton';

import renderUserTypeIcon from '../../../helpers/renderUserTypeIcon';

import buildClassName from '../../../utils/buildClassName';

interface MembershipNumberPropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}

const MembershipNumberProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
}: MembershipNumberPropertyParams) => {
  const { formatMessage } = useIntl();
  const [isDialogOpen, showDialog, hideDialog] = useFlag();

  const { userType, schoolMembership, hasEditingPermission } = useProfile();

  const addLabelTextId = useMemo(() => {
    if (userType === 'student') return 'peopleDetail-AddStudentNumber';

    if (userType === 'parent') return 'peopleDetail-AddParentNumber';

    return 'peopleDetail-AddStaffNumber';
  }, [userType]);

  if (!userType) {
    return null;
  }

  if (!schoolMembership?.number && hasEditingPermission) {
    return (
      <>
        <AddButton
          className={`profile-property can-edit add size--${size} text--primary icon--${iconColor}`}
          onClick={showDialog}
          labelTextId={addLabelTextId}
        />
        <MembershipNumberModal isOpen={isDialogOpen} onClose={hideDialog} />
      </>
    );
  }

  return (
    <>
      {schoolMembership?.number ? (
        <button
          type="button"
          aria-label={formatMessage({ id: 'ariaLabel-Edit' })}
          onClick={hasEditingPermission ? showDialog : undefined}
          className={buildClassName(
            `profile-property size--${size} text--${color} icon--${iconColor} not-editable-property`,
            hasEditingPermission && 'can-edit',
          )}
        >
          <div className="property-icon">{renderUserTypeIcon(userType)}</div>
          <div className="property-value">{schoolMembership?.number}</div>
          {hasEditingPermission && <EditIcon className="edit-icon" />}
        </button>
      ) : (
        <div
          className={buildClassName(
            `profile-property text--light-gray size--${size} text--${color} icon--${iconColor} not-editable-property`,
            hasEditingPermission && 'can-edit',
          )}
        >
          <div className="property-icon">{renderUserTypeIcon(userType)}</div>
          <div className="property-value">
            <FormattedMessage id="memberships-MembershipNumber" values={{ userType }} />
          </div>
        </div>
      )}
      <MembershipNumberModal isOpen={isDialogOpen} onClose={hideDialog} />
    </>
  );
};

export default MembershipNumberProperty;
