import { Divider, Stack } from '@mui/material';
import { useFlag } from '@schooly/hooks/use-flag';
import { forwardRef, PropsWithChildren, useImperativeHandle } from 'react';

import { useDatePickerVisibleMonth } from '../useDatePickerVisibleMonth';
import { DateFnsLocalizationProvider } from './DateFnsLocalizationProvider';
import { DateCalendarHeader, DatePickerYearMonthSelect } from './DatePickerCommonComponents';
import { DateSelectCalendar, DateSelectCalendarProps } from './DateSelectCalendar';

export type DateSelectContentProps = PropsWithChildren<{
  date: Date | null;
  onSetDate: (d: Date) => void;
  presetDate?: Date;
}> &
  Omit<DateSelectCalendarProps, 'visibleMonth' | 'toDate' | 'fromDate' | 'onClickDay'>;

export type DateSelectContentRef = {
  setVisibleMonthDate: (v: Date) => void;
};

export const DateSelectContent = forwardRef<DateSelectContentRef, DateSelectContentProps>(
  ({ date, children, onSetDate, presetDate, ...dateCalendarProps }, ref) => {
    const { visibleMonthDate, showNextMonth, showPrevMonth, setVisibleMonthDate } =
      useDatePickerVisibleMonth(presetDate || date || new Date());

    const [isYearMonthSelectOpened, openYearMonthSelect, closeYearMonthSelect] = useFlag();

    useImperativeHandle(
      ref,
      () => ({
        setVisibleMonthDate,
      }),
      [setVisibleMonthDate],
    );

    return (
      <DateFnsLocalizationProvider>
        <Stack flex={1} minWidth={240} alignItems="center" position="relative">
          <DateCalendarHeader
            onNext={showNextMonth}
            onPrev={showPrevMonth}
            date={visibleMonthDate}
            onToggle={openYearMonthSelect}
          />
          <DateSelectCalendar
            toDate={date}
            onClickDay={onSetDate}
            visibleMonth={visibleMonthDate}
            {...dateCalendarProps}
          />
          {!!children && <Divider sx={{ alignSelf: 'stretch', mx: 2 }} />}
          {children}
          {isYearMonthSelectOpened && (
            <DatePickerYearMonthSelect
              dateToShow={visibleMonthDate}
              onSetDateToShow={setVisibleMonthDate}
              onClose={closeYearMonthSelect}
            />
          )}
        </Stack>
      </DateFnsLocalizationProvider>
    );
  },
);
