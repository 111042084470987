import { Stack, styled } from '@mui/material';
// TODO move to lib
export const PersonCardContainer = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: theme.mixins.borderValue(),
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(1),

  '& .MuiIconButton-root': {
    visibility: 'hidden',
  },
  '& .MuiIcon-root': {
    color: theme.palette.text.secondary,
  },

  '& .UIKit-LongNameWithVerticalTooltip': {
    ...theme.typography.h2,
    color: theme.palette.text.primary,
    border: 'none',
  },

  '& .UIKit-LongNameWithVerticalTooltip__info': {
    padding: theme.spacing(0, 1),
    border: 'none',
  },

  ':hover': {
    background: theme.palette.background.default,

    '& .UIKit-LongNameWithVerticalTooltip__info': {
      color: theme.palette.primary.main,
    },
    '& .UIKit-LongNameWithVerticalTooltip_tooltip': {
      ':hover': {
        '& .UIKit-LongNameWithVerticalTooltip__info': {
          backgroundColor: theme.palette.common.white,
          border: theme.mixins.borderControlValue(),
        },
      },
    },
    '& .MuiTypography-body1, .MuiIcon-root': {
      color: theme.palette.text.primary,
    },
    '& .MuiIconButton-root': {
      visibility: 'visible',
    },
  },
}));
