import { IActionButton } from '@schooly/api';
import { ArrowsIcon, CloseIcon } from '@schooly/style';
import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { DEBUG } from '../../../config';
import useAppLocation, { AppLocation } from '../../../hooks/useAppLocation';
import useShowTransition from '../../../hooks/useShowTransition';
import { useAppDispatch } from '../../../redux/hooks';
import { actions } from '../../../redux/slices/modalSlice';
import buildClassName from '../../../utils/buildClassName';
import captureEscKeyListener from '../../../utils/captureEscKeyListener';
import ActionButtons from '../ActionButtons';
import Button from '../Button';

import './index.scss';

export interface IModalButton {
  textId: string;
  color: string;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onClick?: VoidFunction;
  isDisabled?: boolean;
  isLoading?: boolean;
  testId?: string;
}

interface IProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: VoidFunction;
  borderColor?: string;
  className?: string;
  title?: string | React.ReactNode;
  titleTextId?: string;
  footerButtons?: IModalButton[];
  sidebarContent?: JSX.Element;
  externalSidebarContent?: React.ReactNode;
  externalSidebarTitleTextId?: string;
  externalSidebarTitle?: React.ReactNode;
  onExternalSidebarClose?: () => void;
  actionButtons?: IActionButton[];
  isWide?: boolean;
  noCloseButton?: boolean;
  withInnerModal?: boolean;
  additionalHeaderButton?: JSX.Element;
  useInStack?: boolean;
  getBasePath?: (location: AppLocation) => string;
  shouldSkipStack?: boolean;
}

const modalRoot = document.getElementById('modal-root');
export const MODAL_FADEOUT_DURATION_MS = 300;

function addBodyClass() {
  document.body.classList.add('modal-open');
}

function removeBodyClass() {
  document.body.classList.remove('modal-open');
}

export const getDefaultBasePath = (location: AppLocation) => {
  return location.pathname;
};

// TODO: It might be better to call `onClose` only when the modal is fully closed.
// That might introduce some changes in the behavior that will need to be addressed.
const Modal: React.FC<IProps> = ({
  isOpen,
  onClose,
  className,
  title,
  titleTextId,
  footerButtons,
  sidebarContent,
  externalSidebarContent,
  externalSidebarTitleTextId,
  externalSidebarTitle,
  onExternalSidebarClose,
  actionButtons,
  isWide,
  noCloseButton,
  children,
  additionalHeaderButton,
  withInnerModal,
  shouldSkipStack = false,
  useInStack,
  getBasePath = getDefaultBasePath,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const location = useAppLocation();

  useEffect(() => (isOpen ? captureEscKeyListener(onClose) : undefined), [isOpen, onClose]);

  useEffect(() => {
    if (!useInStack) return;

    if (shouldSkipStack) return;

    dispatch(
      actions.add({
        location,
        getBasePath,
      }),
    );
  }, [dispatch, getBasePath, location, shouldSkipStack, useInStack]);

  const { canRender, isShown } = useShowTransition(
    isOpen,
    MODAL_FADEOUT_DURATION_MS,
    addBodyClass,
    withInnerModal ? () => {} : removeBodyClass,
  );

  const fullClassName = buildClassName(
    'Modal modal fade',
    !titleTextId && !title && 'no-title',
    isShown && 'show',
    isWide && 'wide',
    !!externalSidebarContent && 'with-external-sidebar',
    className,
  );

  const backdropClassName = buildClassName('modal-backdrop fade', isShown && 'show');

  const contentClassName = buildClassName(
    'modal-content',
    'rounded-lg shadow-lg',
    sidebarContent && 'modal-content--with-sidebar',
  );

  if (!modalRoot) {
    if (DEBUG) {
      console.error("'modal-root' container doesn't exist!");
    }
    return null;
  }

  const modal = canRender ? (
    <div className={fullClassName} tabIndex={-1} role="dialog" aria-hidden={!isShown}>
      <div className={backdropClassName} onClick={onClose} />
      <div className="modal-dialog">
        <div className={contentClassName}>
          {sidebarContent && (
            <div className="modal-sidebar">
              {sidebarContent}
              <ActionButtons className="modal-sidebar__actions" actionButtons={actionButtons} />
            </div>
          )}
          <div className="modal-content-inner">
            <div className="modal-header">
              {(titleTextId || title) && (
                <h5 className="modal-title">
                  {titleTextId ? <FormattedMessage id={titleTextId} /> : title}
                </h5>
              )}
              <div className="modal-header__actions">
                {additionalHeaderButton}
                {!noCloseButton && (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label={formatMessage({ id: 'ariaLabel-Close' })}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
            </div>
            <div className="modal-body">{children}</div>
            {footerButtons && (
              <div className="modal-footer">
                {footerButtons.map((button) => (
                  <Button
                    key={button.textId}
                    icon={button.icon}
                    rightIcon={button.rightIcon}
                    color={button.color}
                    onClick={button.onClick}
                    isDisabled={button.isDisabled}
                    isLoading={button.isLoading}
                    testId={button.testId}
                  >
                    <FormattedMessage id={button.textId} />
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>

        {externalSidebarContent && (
          <div className="modal-external-sidebar rounded-lg shadow-lg hide-scrollbar">
            {(externalSidebarTitleTextId || externalSidebarTitle) && (
              <header className="modal-external-sidebar__header mb-3">
                <div className="modal-external-sidebar__title h1">
                  {externalSidebarTitleTextId ? (
                    <FormattedMessage id={externalSidebarTitleTextId} />
                  ) : (
                    externalSidebarTitle
                  )}
                </div>
                <button
                  type="button"
                  className="modal-external-sidebar__close"
                  onClick={onExternalSidebarClose}
                >
                  <ArrowsIcon />
                </button>
              </header>
            )}

            {externalSidebarContent}
          </div>
        )}
      </div>
    </div>
  ) : null;

  return ReactDOM.createPortal(modal, modalRoot);
};

Modal.defaultProps = {
  borderColor: 'primary',
};

export default Modal;
