import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4H5H11C11.5523 4 12 4.44772 12 5V6V11H9H7H5V18H4V4ZM12 12H9C9 12.5523 9.44772 13 10 13H15.6743C16.3386 13 16.8183 12.3641 16.6358 11.7253L16.0785 9.77472C16.0272 9.59517 16.0272 9.40483 16.0785 9.22528L16.6358 7.27472C16.8183 6.6359 16.3386 6 15.6743 6H13V11V12H12Z"
      fill="#B3BBCB"
    />
  </svg>
);
