import { Stack } from '@mui/material';
import { DropdownIcon } from '@schooly/style';
import moment from 'moment/moment';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import AvailableCriteriaList from '../../../../components/common/MessageModal/AvailableCriteria';
import {
  Dropdown,
  DropdownOption,
} from '../../../../components/uikit-components/Dropdown/Dropdown';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { DEFAULT_DATE_FORMAT } from '../../../../config';
import { ParentsSelectBy } from '../../../../context/messages/MessageContext';
import { useMessage } from '../../../../context/messages/useMessage';
import { MessagesCreateModalRecipientsRightSidebarIndividual } from './MessagesCreateModalRecipientsRightSidebarIndividual';
import { MessagesCreateModalRecipientsRightSidebarIndividualStudent } from './MessagesCreateModalRecipientsRightSidebarIndividualStudent';
import { MessagesCreateModalRecipientsRightSidebarContent } from './ModalCreateModalRecipients.styled';

export const MessagesCreateModalRecipientsParentsRightSidebar: FC = () => {
  const { formatMessage } = useIntl();
  const {
    parentsSelectBy,
    criteriaDate,
    parents,
    parentsCriteria,
    parentsByIndividualStudent,
    actions,
  } = useMessage();

  const options = useMemo(
    () => [
      {
        value: ParentsSelectBy.StudentCriteria,
        label: formatMessage({ id: 'messages-SelectByStudentCriteria' }),
      },
      {
        value: ParentsSelectBy.IndividualStudent,
        label: formatMessage({ id: 'messages-SelectByIndividualStudent' }),
      },
      {
        value: ParentsSelectBy.IndividualParent,
        label: formatMessage({ id: 'messages-SelectIndividualParent' }),
      },
    ],
    [formatMessage],
  );

  const handleSelectByChange = useCallback(
    (option?: DropdownOption<ParentsSelectBy>) => {
      if (option) {
        actions.setParentsSelectBy(option.value);
      }
    },
    [actions],
  );

  const renderContent = () => {
    switch (parentsSelectBy) {
      case ParentsSelectBy.StudentCriteria:
        return (
          <AvailableCriteriaList
            userType="parent"
            onCriteriaClick={actions.addParentsCriteria}
            onGroupCriteriaAddClick={actions.addParentsCriteriaMultiple}
            selectedCriteria={parentsCriteria}
            singleDate={criteriaDate ?? moment().format(DEFAULT_DATE_FORMAT)}
            fetchGroups={actions.fetchGroups}
          />
        );
      case ParentsSelectBy.IndividualStudent:
        return (
          <MessagesCreateModalRecipientsRightSidebarIndividualStudent
            selectedUsers={parentsByIndividualStudent}
            onAdd={actions.addParentByIndividualStudent}
          />
        );
      case ParentsSelectBy.IndividualParent:
        return (
          <MessagesCreateModalRecipientsRightSidebarIndividual
            userType="parent"
            selectedUsers={parents}
            onAdd={actions.addParent}
          />
        );
    }

    return null;
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <ModalHeader
        title={formatMessage({ id: 'messages-Parents' })}
        active
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        })}
      />
      <MessagesCreateModalRecipientsRightSidebarContent active>
        <Stack sx={{ height: '100%' }}>
          <Dropdown
            options={options}
            value={parentsSelectBy}
            icon={<DropdownIcon />}
            iconPlacement="end"
            canClean={false}
            onChange={handleSelectByChange}
          />
          {renderContent()}
        </Stack>
      </MessagesCreateModalRecipientsRightSidebarContent>
    </Stack>
  );
};
