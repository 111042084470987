import { Stack, Typography } from '@mui/material';
import { GetEventsQueryFilters, useEventsBulkStatusChangeMutation } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { CheckboxSquareIconButton, SimpleButton, Spin } from '@schooly/style';
import { BulkActionsBottomBar, SelectedIds } from '@schooly/utils/bulk-actions';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { SIDEBAR_WIDTH } from '../../components/uikit-components/Sidebar/Sidebar.styled';

type EventsBulkActionsProps = {
  schoolId: string;
  selectedIds: SelectedIds;
  query?: string;
  filters: GetEventsQueryFilters;
  onClear: () => void;
};

export const EventsBulkActions: FC<EventsBulkActionsProps> = ({
  selectedIds,
  onClear,
  schoolId,
  query,
  filters,
}) => {
  const { $t } = useIntl();

  const { showNotification, showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const eventsStatusChangeMutation = useEventsBulkStatusChangeMutation();

  const onPublishEvents = useCallback(async () => {
    const isConfirmed = await getConfirmation({
      textId: 'events-notification-NotifyAfterAction',
      textValues: {
        action: 'publishing',
      },
    });

    eventsStatusChangeMutation.mutate(
      selectedIds === 'all'
        ? {
            schoolId,
            query,
            filters,
            notify_parents: isConfirmed,
            action: 'publish',
            withFollowing: false,
          }
        : {
            schoolId,
            event_ids: Array.from(selectedIds),
            notify_parents: isConfirmed,
            action: 'publish',
            withFollowing: false,
          },
      {
        onSuccess: (res) => {
          showNotification({
            textId: 'events-action-PublishSuccess',
            values: { eventsCount: res.event_count },
            type: 'success',
          });
          onClear();
        },
        onError: showError,
      },
    );
  }, [
    filters,
    getConfirmation,
    onClear,
    eventsStatusChangeMutation,
    query,
    schoolId,
    selectedIds,
    showError,
    showNotification,
  ]);

  if (selectedIds !== 'all' && !selectedIds.size) return null;

  return (
    <BulkActionsBottomBar
      sx={{ pointerEvents: eventsStatusChangeMutation.isLoading ? 'none' : undefined }}
      sidebarWidth={SIDEBAR_WIDTH}
    >
      <Stack flexDirection="row" alignItems="center" minWidth={240} gap={1}>
        <CheckboxSquareIconButton isActive onClick={onClear} />
        <Typography variant="h3">
          {$t(
            { id: 'countOfTypeSelected' },
            selectedIds === 'all'
              ? {
                  count: $t({ id: 'filter-All' }),
                  userTypePlural: $t({ id: 'event-type-plural' }).toLowerCase(),
                }
              : {
                  count: selectedIds.size,
                  userTypePlural: $t({
                    id: selectedIds.size > 1 ? 'event-type-plural' : 'event-type',
                  }).toLowerCase(),
                },
          )}
        </Typography>
      </Stack>
      <SimpleButton
        startIcon={eventsStatusChangeMutation.isLoading ? <Spin /> : undefined}
        disabled={eventsStatusChangeMutation.isLoading}
        onClick={onPublishEvents}
      >
        {$t({
          id: 'events-action-Publish',
        })}
      </SimpleButton>
    </BulkActionsBottomBar>
  );
};
