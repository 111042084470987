import { useGetEventsChartQuery, UserFilter } from '@schooly/api';
import { differenceInMonths } from 'date-fns';
import { useMemo } from 'react';
import React from 'react';

import {
  CALENDAR_CHART_MONTH_LIMIT,
  CalendarChartContent,
  CalendarChartsCustomGrid,
} from '../../components/common/Charts/CalendarChartContent';
import { useSchool } from '../../hooks/useSchool';

interface EventsChartProps<T> {
  filters: T;
  onOpenCustomGrid: (data: CalendarChartsCustomGrid<T> | null) => void;
  chartCustomGrid: CalendarChartsCustomGrid<T> | null;
  chartIcon: React.ReactNode;
  chartOpen: boolean;
}

export const EventsChart = <T extends Omit<Partial<UserFilter>, 'date'> & { date?: string[] }>({
  filters,
  onOpenCustomGrid,
  chartCustomGrid,
  chartIcon,
  chartOpen,
}: EventsChartProps<T>) => {
  const { schoolId } = useSchool();

  const overYearSelected = useMemo(() => {
    const [dateFrom, dateTo] = filters.date ?? [];

    if (!dateFrom || !dateTo) {
      return false;
    }

    return differenceInMonths(new Date(dateTo), new Date(dateFrom)) > CALENDAR_CHART_MONTH_LIMIT;
  }, [filters.date]);

  const { data: chartData, isFetching: pending } = useGetEventsChartQuery(
    {
      schoolId: schoolId ?? '',
      filters,
    },
    {
      enabled: !chartCustomGrid || overYearSelected,
      refetchOnMount: 'always',
    },
  );

  if (!chartData && !pending) return null;

  return (
    <CalendarChartContent
      chartCustomGrid={chartCustomGrid}
      onOpenCustomGrid={onOpenCustomGrid}
      filters={filters}
      chartIcon={chartIcon}
      chartOpen={chartOpen}
      data={chartData}
      pending={pending}
      overYearSelected={overYearSelected}
      withoutItemCount
    />
  );
};
