import React, { PropsWithChildren } from 'react';

import { useDivOverflow } from '../../../hooks/useDivOverflow';
import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';

import './LongStringWithTooltip.scss';

const $class = `${$UIKitPrefix}LongStringWithTooltip`;

export interface LongStringWithTooltipProps extends PropsWithChildren {
  className?: string;
  paragraphClassName?: string;
}

export const LongStringWithTooltip: React.FC<LongStringWithTooltipProps> = ({
  children,
  className,
  paragraphClassName,
}) => {
  const { ref, innerRef, isTooltip } = useDivOverflow(true);

  return (
    <div ref={ref} className={buildClassName($class, isTooltip && `${$class}_tooltip`, className)}>
      <div ref={innerRef} className={`${$class}__content`}>
        <p className={buildClassName('mb-0', paragraphClassName, `${$class}__name`)}>{children}</p>
      </div>
    </div>
  );
};
