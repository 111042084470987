import { IconButton } from '@mui/material';
import { DropdownMenu, DropdownMenuItem, MoreIcon } from '@schooly/style';
import React, { FC } from 'react';

interface PersonActionsSelectProps {
  onClose: () => void;
  onOpen: () => void;
  actions: DropdownMenuItem[];
}

export const PersonActionsSelect: FC<PersonActionsSelectProps> = ({ onClose, onOpen, actions }) => {
  return (
    <DropdownMenu
      noArrow
      closeOnSelect
      onClose={onClose}
      icon={
        <IconButton
          inverse
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={onOpen}
        >
          <MoreIcon />
        </IconButton>
      }
      actions={actions}
      menuStyles={{
        '.MuiPaper-root': {
          boxShadow: '0px 10px 40px 0px rgba(37, 39, 90, 0.15)',
        },
        '& .MuiList-root': {
          width: 215,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 0.25,
          '.MuiButtonBase-root': {
            borderRadius: '4px',
            color: 'primary.main',
          },
        },
        '.MuiMenuItem-root:hover': {
          '.MuiTypography-root, .svg-icon': {
            color: 'primary.main',
          },
        },
      }}
      testId="person-action-select"
    />
  );
};
