import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import AttendanceStubArrowImg from '../../../../../assets/images/attendance-stub-arrow.svg';
import AttendanceStubCheckImg from '../../../../../assets/images/attendance-stub-check.svg';
import {
  GroupPreviewAttendanceEmptyStubArrow,
  GroupPreviewAttendanceEmptyStubStyled,
} from './GroupPreviewAttendanceEmptyStub.styled';

export const GroupPreviewAttendanceEmptyStubNoButton: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <GroupPreviewAttendanceEmptyStubStyled>
      <img src={AttendanceStubCheckImg} alt={formatMessage({ id: 'attendance-NoAttendanceYet' })} />
      <Typography variant="h3" color="text.primary" mt={2.5} textAlign="center">
        <FormattedMessage id="attendance-NoRegisterForDate" />
        <br />
        <FormattedMessage id="attendance-SelectDifferentDate" />
      </Typography>
      <GroupPreviewAttendanceEmptyStubArrow>
        <img src={AttendanceStubArrowImg} alt="arrow" />
      </GroupPreviewAttendanceEmptyStubArrow>
    </GroupPreviewAttendanceEmptyStubStyled>
  );
};
