import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { LegalEntity } from '@schooly/api';
import {
  ArrowRightIcon,
  CrossIcon,
  DoneIcon,
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spin,
} from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { LegalEntitySelect } from './LegalEntitySelect';

type LegalEntityReplaceForm = {
  legalEntity: LegalEntity;
};

type LegalEntityReplaceModalProps = {
  schoolId: string;
  onReplace: (e: LegalEntity) => void;
  onClose: () => void;
  legalEntityName: string;
  canDelete: boolean;
  legalEntityId: string;
  onRemove: () => void;
  isRemoving: boolean;
};

export const LegalEntityReplaceModal: FC<LegalEntityReplaceModalProps> = ({
  schoolId,
  onReplace,
  legalEntityName,
  canDelete,
  legalEntityId,
  onClose,
  onRemove,
  isRemoving,
}) => {
  const { $t } = useIntl();

  const form = useForm<LegalEntityReplaceForm>();
  const selectedLegalEntity = form.watch('legalEntity');
  const hasMultipleAccounts = selectedLegalEntity && selectedLegalEntity.accounts.length > 1;

  const handleSubmit = useCallback(
    ({ legalEntity }: LegalEntityReplaceForm) => onReplace(legalEntity),
    [onReplace],
  );

  const submitButtonIcon = useMemo(() => {
    if (isRemoving) return <Spin />;
    if (hasMultipleAccounts || !selectedLegalEntity) return <ArrowRightIcon />;
    return <DoneIcon />;
  }, [hasMultipleAccounts, isRemoving, selectedLegalEntity]);

  return (
    <ModalConfirm
      open
      fullWidth
      sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      onClose={!isRemoving ? onClose : undefined}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ModalHeader
            active
            title={$t({ id: 'legalEntities-ReplaceLegalEntity' }, { legalEntityName })}
            multiline
            sx={{ borderBottom: 'none', pb: 0 }}
          >
            <IconButton onClick={onClose} sx={{ visibility: !isRemoving ? 'visible' : 'hidden' }}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalContent active>
            <Stack direction="row" mb={2.5} gap={2.5} height={46}>
              <Typography variant="h2" sx={{ alignSelf: 'center' }}>
                {$t({ id: 'legalEntities-ReplaceWith' })}
              </Typography>

              <Box flex={1}>
                <Controller
                  control={form.control}
                  name="legalEntity"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <LegalEntitySelect
                      selectedEntity={field.value}
                      schoolId={schoolId}
                      onSelect={field.onChange}
                      placeholder={$t({ id: 'legalEntities-LegalEntity' })}
                      onClear={() => field.onChange()}
                      legalEntityId={legalEntityId}
                      requiredLabel="required"
                      error={fieldState.error}
                    />
                  )}
                />
              </Box>
            </Stack>
          </ModalContent>
          <ModalFooter active>
            <Button
              variant="outlined"
              disabled={
                isRemoving ||
                //TR-6076 Archiving without replacing will be available after product archiving is done
                !canDelete
              }
              onClick={onRemove}
            >
              {$t({
                id: canDelete ? 'action-DeleteWithoutReplacing' : 'action-ArchiveWithoutReplacing',
              })}
            </Button>
            <Button type="submit" disabled={isRemoving} endIcon={submitButtonIcon}>
              {$t({
                id: hasMultipleAccounts || !selectedLegalEntity ? 'action-Next' : 'action-Replace',
              })}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalConfirm>
  );
};
