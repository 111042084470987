import { Box, styled } from '@mui/material';

export interface SelectedCriteriaListProps {
  white?: boolean;
}

export const SelectedCriteriaList = styled(Box)<SelectedCriteriaListProps>(({ theme }) => ({
  '& .AvailableCriteriaCard': {
    display: 'inline-flex',
    minWidth: 'unset',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,

    '& .card-body': {
      padding: theme.spacing(0.5, 2),
    },

    '& svg:not(.archive-icon)': {
      marginRight: theme.spacing(-1),
      marginLeft: theme.spacing(1),
      minWidth: theme.spacing(2.5),
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      fontSize: theme.spacing(2.5),
    },
  },

  '& .AvailableCriteriaCard__separator': {
    ...theme.typography.h3_medium,
    margin: theme.spacing(0, 0.5, 1.25),
  },
}));
