import { BaseUserSchoolRelation } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { EmailIcon } from '@schooly/style';
import React, { useMemo } from 'react';

import { useProfile } from '../../../context/profile/useProfile';
import EmailModal from './EmailModal';
import ProfileProperty from './ProfileProperty';

interface EmailPropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}

const EmailProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
}: EmailPropertyParams) => {
  const {
    userType = 'profile',
    user,
    schoolMembership = {} as BaseUserSchoolRelation,
    isSchoolUser,
    canEditProfile,
    hasEditingPermission,
  } = useProfile();
  const { username, currentUser } = useAuth();

  const email = useMemo(() => {
    const { email: userEmail } = isSchoolUser ? schoolMembership : user ?? {};

    if (userType === 'profile') {
      return currentUser?.account_email || user?.account_email || username || '';
    }

    return userEmail ?? '';
  }, [currentUser?.account_email, isSchoolUser, schoolMembership, user, userType, username]);

  const canEditEmail = useMemo(() => {
    switch (userType) {
      case 'profile':
      case 'parent':
        return canEditProfile;

      case 'staff':
      case 'student':
        return hasEditingPermission;

      default:
        return hasEditingPermission && canEditProfile;
    }
  }, [canEditProfile, hasEditingPermission, userType]);

  return (
    <>
      <ProfileProperty
        value={email}
        canEdit={canEditEmail}
        params={{ size, iconColor, color }}
        renderIcon={() => <EmailIcon />}
        valueTextId="peopleDetail-EmailAddressPersonal"
        editValueTextId="peopleDetail-AddEmailAddress"
        fieldName="email"
        modalController={({ isOpen, hide }) => (
          <EmailModal currentUserEmail={email} isOpen={isOpen} onClose={hide} />
        )}
      />
    </>
  );
};

export default EmailProperty;
