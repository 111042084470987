import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

export enum WeekDays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export const DAY_OF_WEEK_OPTIONS = $enum(WeekDays).map((value, key) => ({
  labelTextId: `week-${key}`,
  value,
}));

type FormDayOfWeekSelectProps = {
  name: string;
  required: boolean;
};

export const FormDayOfWeekSelect: FC<FormDayOfWeekSelectProps> = ({ name, required }) => {
  const { control, getValues, setValue, formState, trigger } =
    useFormContext<Record<string, number[]>>();

  const { $t } = useIntl();

  const error = get(formState.errors, name);
  const errorMessage = getControllerErrorText(error, undefined, $t);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      render={({ field }) => {
        const state = getValues(name);
        return (
          <Stack gap={0.5}>
            <Stack direction="row" gap={1}>
              {DAY_OF_WEEK_OPTIONS.map(({ labelTextId, value }) => {
                const selected = field.value.includes(value);
                const shortDescriptions = $t({ id: labelTextId }).at(0);
                return (
                  <Button
                    variant={selected ? 'contained' : 'outlined'}
                    sx={(theme) => ({
                      justifyItems: 'flex-start',
                      minWidth: 0,
                      padding: theme.spacing(1.25, 1.75),
                      width: 44,
                      height: 44,
                    })}
                    onClick={() => {
                      if (selected) {
                        setValue(
                          name,
                          state.filter((v) => v !== value),
                        );
                      } else setValue(name, state.length ? [...state, value] : [value]);
                      if (error) trigger(name);
                    }}
                  >
                    <Typography color={selected ? 'white' : 'primary.main'} variant="h3">
                      {shortDescriptions}
                    </Typography>
                  </Button>
                );
              })}
            </Stack>
            {errorMessage && (
              <FormHelperText>
                <Typography color="error.main" variant="body2">
                  {errorMessage}
                </Typography>
              </FormHelperText>
            )}
          </Stack>
        );
      }}
    />
  );
};
