enum Environments {
  PROD = 'prod',
  UAT = 'uat',
  STG = 'stg',
  DEV = 'dev',
}

export const getEnvironment = () =>
  (import.meta.env.VITE_ENV_TYPE as Environments) ?? Environments.DEV;

export const isEnvironment = (...envs: Environments[]) => envs.includes(getEnvironment());

export default Environments;
