import { Stack } from '@mui/material';
import { AssessmentForGroup } from '@schooly/api';
import { FC } from 'react';

import { AssessmentInfo } from './AssessmentInfo';

export interface AssessmentMarkbookHeaderCellProps {
  assessment: AssessmentForGroup;
  onMouseOver: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave: () => void;
}

export const AssessmentMarkbookHeaderCell: FC<AssessmentMarkbookHeaderCellProps> = ({
  assessment,
  onMouseOver,
  onMouseLeave,
}) => {
  return (
    <Stack
      justifyContent="center"
      sx={(theme) => ({
        width: '100%',
        height: '100%',
        px: theme.spacing(1),
        color: theme.palette.text.secondary,
      })}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <AssessmentInfo assessment={assessment} />
    </Stack>
  );
};
