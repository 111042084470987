import { InformationIcon } from '@schooly/style';
import React, { PropsWithChildren, useRef } from 'react';

import { FORSED_POPOVER_ZINDEX } from '../../../config';
import { noop } from '../../../helpers/misc';
import useFlag from '../../../hooks/useFlag';
import buildClassName from '../../../utils/buildClassName';
import Popover, { PopoverSize } from '../../ui/Popover';

import './index.scss';

interface HintProps extends PropsWithChildren {
  icon?: React.ReactNode;
  size?: PopoverSize;
  className?: string;
}

const Hint: React.FC<HintProps> = ({ icon, size, className, children }) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [isPopoverOpen, openPopover, closePopover] = useFlag();

  const isSmall = size === PopoverSize.Small;

  return (
    <div
      className={buildClassName('question', className)}
      onMouseEnter={openPopover}
      onMouseLeave={closePopover}
      ref={popoverRef}
    >
      <div className="popover-hint-icon">{icon || <InformationIcon />}</div>
      <Popover
        forsedZIndex={FORSED_POPOVER_ZINDEX}
        open={isPopoverOpen}
        anchorRef={popoverRef}
        onMouseEnter={openPopover}
        onMouseLeave={noop}
        size={size}
        offsetY={0}
      >
        <div style={{ width: '9.4rem' }}>
          {!isSmall && <span className="popover-arrow" />}
          <span>{children}</span>
        </div>
      </Popover>
    </div>
  );
};

export default Hint;
