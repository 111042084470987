import { Typography } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { PROPERTIES_TEXT_IDS } from '@schooly/constants';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { ArrangeBySelectRow } from './ArrangeBySelectRow';

type ArrangeBySelectProps<T> = {
  label?: string;
  options: readonly T[];
  selectedOption?: T;
  onSelectOption: (v: T) => void;
  getOptionLabelId?: (o: T) => string;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const ArrangeBySelect = <T extends FilterKeys>({
  label,
  options,
  selectedOption,
  onSelectOption,
  placeholder,
  getOptionLabelId,
  ...dropdownProps
}: ArrangeBySelectProps<T>) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const { $t } = useIntl();

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      placeholder={placeholder}
      hasValues={!!selectedOption}
      renderContent={() => (
        <>
          {options.map((option) => (
            <ArrangeBySelectRow
              arrangeBy={option}
              isSelected={selectedOption === option}
              getOptionLabelId={getOptionLabelId}
              onClick={() => {
                onSelectOption(option);
                dropdown.current?.close();
              }}
            />
          ))}
        </>
      )}
    >
      {() => (
        <>
          {selectedOption && (
            <Typography variant="h3">
              {$t({
                id: getOptionLabelId
                  ? getOptionLabelId(selectedOption)
                  : PROPERTIES_TEXT_IDS[selectedOption],
              })}
            </Typography>
          )}
        </>
      )}
    </DropdownSelect>
  );
};
