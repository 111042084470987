import { SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { Loading, ModalSmall } from '@schooly/style';
import React, { FC } from 'react';

import { useRouter } from '../../../../context/router/useRouter';
import { useSchool } from '../../../../hooks/useSchool';
import { SchoolSettingsLayout } from '../../SchoolSettingsLayout';
import { SchoolGeneralAgeGroupsModalContent } from './SchoolGeneralAgeGroupsModalContent';

export const SchoolGeneralAgeGroupsModal: FC = () => {
  const { schoolId = '' } = useSchool();
  const { goBack } = useRouter();

  const { isLoading } = useAgeGroups({
    schoolId: schoolId!,
    userType: SchoolUserRole.Student,
  });

  return (
    <SchoolSettingsLayout>
      {isLoading ? (
        <ModalSmall open onClose={goBack}>
          <Loading />
        </ModalSmall>
      ) : (
        <SchoolGeneralAgeGroupsModalContent />
      )}
    </SchoolSettingsLayout>
  );
};
