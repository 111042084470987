import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 9.15635C11.6753 9.15635 11.84 9.08802 11.964 8.96401C12.2199 8.70816 12.2199 8.29185 11.964 8.036C11.84 7.91201 11.6752 7.84379 11.5 7.84379C11.3247 7.84379 11.1598 7.91205 11.0359 8.036C10.9119 8.15994 10.8437 8.32477 10.8437 8.50001C10.8437 8.67537 10.912 8.84014 11.0359 8.96401C11.1598 9.08802 11.3246 9.15631 11.5 9.15635Z"
      fill="#24275B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5902 1.86723C17.74 1.84306 17.8927 1.89251 18.0002 2.00001C18.1076 2.10743 18.1571 2.26003 18.1329 2.41023C18.124 2.46528 17.9083 3.77771 17.2251 5.56648C16.8241 6.61576 16.344 7.61263 15.7981 8.52971C15.1277 9.65576 14.3549 10.6636 13.5012 11.5272L12.8051 15.1036C12.7871 15.1959 12.742 15.2809 12.6756 15.3474L9.44588 18.5875C9.3549 18.6789 9.23338 18.7269 9.11046 18.7269C9.03478 18.7269 8.95863 18.7087 8.88848 18.6715C8.70451 18.5738 8.60593 18.3673 8.64576 18.1627L9.35713 14.5081C8.89048 14.7459 8.40588 14.9685 7.9036 15.175C7.84541 15.199 7.78421 15.2107 7.72363 15.2107C7.60036 15.2107 7.47925 15.1625 7.38863 15.072L4.92836 12.6117C4.79326 12.4764 4.75263 12.2734 4.82523 12.0966C5.03183 11.5944 5.25441 11.1098 5.49231 10.643L1.83768 11.3546C1.80736 11.3604 1.77703 11.3632 1.74698 11.3632C1.57431 11.3632 1.41211 11.2685 1.32886 11.1118C1.23118 10.9277 1.26518 10.7014 1.41286 10.5542L4.65301 7.32448C4.71968 7.25815 4.80451 7.21301 4.89683 7.19506L8.47321 6.49881C9.33678 5.64506 10.3447 4.87238 11.4707 4.20203C12.3877 3.65606 13.3846 3.17598 14.4339 2.77513C16.2225 2.09173 17.5351 1.87616 17.5902 1.86723ZM5.21946 8.09716L3.18223 10.1278L6.09013 9.56163C6.49493 8.88628 6.93593 8.25521 7.41131 7.67043L5.21946 8.09716ZM9.87233 16.818L11.9029 14.7808L12.3296 12.5889C11.7449 13.0643 11.1139 13.5053 10.4384 13.9101L9.87233 16.818ZM11.5 7.00001C11.9006 7.00001 12.2773 7.15602 12.5607 7.43938C12.8439 7.7227 13 8.09938 13 8.49998C13 8.90063 12.844 9.27731 12.5607 9.56067C12.2773 9.84399 11.9006 10 11.5 10C11.0994 9.99994 10.7227 9.84399 10.4393 9.56067C10.156 9.27735 10 8.90065 10 8.49998C10 8.09938 10.156 7.72268 10.4393 7.43938C10.7227 7.15602 11.0994 7.00001 11.5 7.00001Z"
      fill="#24275B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.189 14.0939C4.07148 12.1083 7.97965 15.9952 5.97938 16.8842L3.33261 18.0606C2.4958 18.4325 1.64074 17.5775 2.01266 16.7406L3.189 14.0939ZM5.57324 15.9704L4.10281 14.5L2.92647 17.1468L5.57324 15.9704Z"
      fill="#24275B"
    />
  </svg>
);
