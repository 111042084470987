import { ApiError } from '@schooly/api';

import { AppLocation } from '../../hooks/useAppLocation';

export enum ProfileModalMode {
  About = 'about',
  Family = 'family',
  Registrations = 'registrations',
  Groups = 'groups',
  Messages = 'messages',
  Assessments = 'assessments',
  Reports = 'reports',
  Attendance = 'attendance',
  EmploymentCases = 'employment-cases',
  Conduct = 'conduct',
  UserRoles = 'user-roles',
  PayersAndProducts = 'payers-and-products',
  DependantsAndProducts = 'dependants-and-products',
  PayableFees = 'payable-fees',
  ConsentForms = 'consent-forms',
}

const profileUserTypeAvaliableModes = [ProfileModalMode.About, ProfileModalMode.Family];
const defaultMode = ProfileModalMode.About;

export const getDefaultMode = (location?: AppLocation): ProfileModalMode => {
  if (!location) return defaultMode;

  const { hash = '', pathname } = location;

  const hashAsMode = hash.split('#')?.[1];

  const isProfileUserTypeRoute = ['adults', 'children'].find((r) => pathname.includes(r));
  const hasProfileUserTypeHash = profileUserTypeAvaliableModes.includes(
    hashAsMode as ProfileModalMode,
  );

  if (isProfileUserTypeRoute && !hasProfileUserTypeHash) {
    return defaultMode;
  }

  return getModeFromHash(hash);
};

export const getModeFromHash = (hash: string) =>
  (hash?.replace('#', '') || defaultMode) as ProfileModalMode;

export const isHiveMembershipError = (e: ApiError) =>
  ['staff', 'parent', 'student'].some((ut) => e?.reason?.includes(`is not a ${ut}`));
