import { DEFAULT_DATE_FORMAT } from '@schooly/api';
import moment from 'moment';

export const validateRegistrationStatuses = (
  statuses: { school_property_id?: string; applies_from?: string }[],
) => {
  const errors: { school_property_id?: string; applies_from?: string }[] = [];

  if (!statuses.length) return errors;

  statuses.forEach(({ school_property_id, applies_from }, index) => {
    if (!school_property_id) {
      errors[index] = {
        ...errors[index],
        school_property_id: 'input-ErrorRequired',
      };
    }

    if (!applies_from) {
      errors[index] = {
        ...errors[index],
        applies_from: 'input-ErrorRequired',
      };
    } else if (index !== 0) {
      const previousDate = statuses[index - 1].applies_from;
      const appliesFromDateMoment = moment(applies_from, DEFAULT_DATE_FORMAT);

      if (appliesFromDateMoment.isBefore(previousDate)) {
        errors[index] = {
          ...errors[index],
          applies_from: 'error-StatusDateOrder',
        };
      }
    }
  });

  return errors;
};
