import { Box, Stack, Typography } from '@mui/material';
import { FilterKeys, useGetMiniListQuery } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { Loading, PlusIcon } from '@schooly/style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import PersonCardSelectable from '../../../components/common/PersonCard/PersonCardSelectable';
import { SimpleButton } from '../../../components/uikit/SimpleButton/SimpleButton';
import { ModalContent, ModalPanel } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { getSchoolUserId } from '../../../helpers/school';
import { useSchool } from '../../../hooks/useSchool';
import { GroupPreviewModalConductSearchInput } from '../../Groups/GroupPreviewModal/GroupPreviewModalConduct/GroupPreviewModalConductSearchInput';
import { AddStudentsFilters, AddStudentsFiltersPopup } from './AddStudentsFiltersPopup';
import { CREATE_CONDUCT_GROUP_SEARCH_PARAM as CONDUCT_FOR_GROUP_SEARCH_PARAM } from './ConductCreateModal';
import { InlineFiltersTags } from './InlineFiltersTags';

export interface AddStudentsExternalSidebarProps {
  students: SimpleListResult[];
  onStudentClick: (user?: SimpleListResult | SimpleListResult[]) => void;
  date?: string;
}

const addStudentsFilters: AddStudentsFilters = {
  [FilterKeys.Status]: [],
  [FilterKeys.AgeGroup]: [],
  [FilterKeys.House]: [],
  [FilterKeys.Group]: [],
  [FilterKeys.Date]: [],
};

const AddStudentsExternalSidebar: React.FC<AddStudentsExternalSidebarProps> = ({
  students: selectedStudents,
  onStudentClick,
  date,
}) => {
  const { $t } = useIntl();
  const { schoolId } = useSchool();
  const [filtersModalVisible, openFilterModal, closeFiltersModal] = useFlag(false);
  const [defaultFiltersInitialized, setDefaultFiltersInitialized] = useState(false);
  const { activePropertiesMap, isLoading: isLoadingProperties } = useSchoolProperties(
    {
      schoolId: schoolId || '',
      userType: SchoolUserRole.Student,
      showReEnrollmentProperties: true,
    },
    { enabled: !!schoolId, refetchOnMount: 'always' },
  );

  const initialFilters = useMemo(
    () => (date ? { ...addStudentsFilters, [FilterKeys.Date]: [date] } : addStudentsFilters),
    [date],
  );

  const defaultFilters: AddStudentsFilters | undefined = useMemo(() => {
    if (isLoadingProperties) return;

    const searchParams = new URL(window.location.href).searchParams;
    const groupId = searchParams.get(CONDUCT_FOR_GROUP_SEARCH_PARAM);

    const defaultStatuses =
      activePropertiesMap.status.filter(({ category }) => category?.current) ?? [];
    const defaultStatusesIds = defaultStatuses.map(({ id }) => id);

    return {
      ...initialFilters,
      ...(date ? { [FilterKeys.Date]: [date] } : {}),
      ...(groupId
        ? {
            [FilterKeys.Group]: [groupId],
          }
        : defaultStatusesIds.length
        ? { [FilterKeys.Status]: defaultStatusesIds }
        : {}),
    };
  }, [isLoadingProperties, activePropertiesMap.status, initialFilters, date]);

  const { data, params, setParams, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetMiniListQuery(
      {
        schoolId: schoolId || '',
        query: '',
        type: 'student',
        filters: defaultFilters || initialFilters,
      },
      { enabled: defaultFiltersInitialized, refetchOnMount: 'always' },
    );

  useEffect(() => {
    if (!defaultFilters || !!defaultFiltersInitialized) return;

    setParams((p) => ({ ...p, filters: defaultFilters }));
    setDefaultFiltersInitialized(true);
  }, [defaultFilters, defaultFiltersInitialized, setParams]);

  const filteredList = useMemo(() => {
    const selectedUserIds = selectedStudents.map(getSchoolUserId);
    const entries =
      data?.pages.reduce<SimpleListResult[]>((prev, curr) => {
        prev.push(
          ...curr.results.filter((user) => !selectedUserIds.includes(getSchoolUserId(user))),
        );
        return prev;
      }, []) ?? [];

    return entries;
  }, [data?.pages, selectedStudents]);

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const handleSetSearchText = useCallback(
    (query: string) => {
      setParams((params) => ({ ...params, query }));
    },
    [setParams],
  );
  const handleSetFilters = useCallback(
    (filters: AddStudentsFilters) => {
      setParams((params) => ({ ...params, filters: { ...params.filters, ...filters } }));
    },
    [setParams],
  );

  const handleResetFilters = useCallback(() => {
    setParams((params) => ({ ...params, filters: initialFilters }));
  }, [initialFilters, setParams]);

  const renderContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (!filteredList.length && !hasNextPage) {
      return <NoSearchResultsFound />;
    }

    return (
      <>
        {filteredList?.map((user) => (
          <PersonCardSelectable
            isUsernameClickable
            isUsernameLinkView
            key={user.relation_id}
            user={user}
            userType="student"
            onClick={() => onStudentClick(user)}
            isListItem
          ></PersonCardSelectable>
        ))}
        {hasNextPage && (
          <Box py={3}>
            <Loading />
            <div ref={loaderRef} />
          </Box>
        )}
      </>
    );
  };

  const hasFiltersApplied = Object.keys(initialFilters).some(
    (key) => !!params.filters[key as keyof typeof initialFilters]?.length,
  );

  return (
    <Stack sx={{ height: '100%' }}>
      <ModalHeader title={$t({ id: 'userType-student-plural' })} active />
      <Box px={2.5} pt={2.5}>
        <Stack gap={1}>
          <GroupPreviewModalConductSearchInput
            value={params.query || ''}
            onChangeText={handleSetSearchText}
            hasFilters={hasFiltersApplied}
            onFiltersPress={openFilterModal}
            placeholder={$t(
              { id: 'groups-AvailableUsersSearch' },
              {
                userType: $t({ id: 'userType-student-plural' }).toLowerCase(),
              },
            )}
          />
          <InlineFiltersTags
            onResetFilters={handleResetFilters}
            onOpenFilters={openFilterModal}
            filters={params.filters}
          />
        </Stack>
        {filtersModalVisible && (
          <AddStudentsFiltersPopup
            onClose={closeFiltersModal}
            defaultFilters={initialFilters}
            onSetFilters={handleSetFilters}
            filters={params.filters}
          />
        )}
      </Box>
      <ModalContent active>{renderContent()}</ModalContent>

      {!!filteredList.length && (
        <ModalPanel
          active
          withBorderTop
          sx={{
            px: 2.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 63,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              borderRadius: 1,
              width: '100%',
            }}
          >
            <SimpleButton startIcon={<PlusIcon />} onClick={() => onStudentClick(filteredList)}>
              <Typography variant="h3">
                <FormattedMessage
                  id="conduct-AddAllStudents"
                  values={{ studentsCount: filteredList.length }}
                />
              </Typography>
            </SimpleButton>
          </Stack>
        </ModalPanel>
      )}
    </Stack>
  );
};

export default AddStudentsExternalSidebar;
