import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99995 13.5L9.3535 13.1464L9.20706 13H8.99995V13.5ZM11.7464 16.2464L11.3928 16.6H11.3928L11.7464 16.2464ZM12.6 13.5V13H12.1V13.5H12.6ZM3.19995 3.69995C3.19995 3.42381 3.42381 3.19995 3.69995 3.19995V2.19995C2.87152 2.19995 2.19995 2.87153 2.19995 3.69995H3.19995ZM3.19995 12.5V3.69995H2.19995V12.5H3.19995ZM3.69995 13C3.42381 13 3.19995 12.7761 3.19995 12.5H2.19995C2.19995 13.3284 2.87152 14 3.69995 14V13ZM8.99995 13H3.69995V14H8.99995V13ZM8.6464 13.8535L11.3928 16.6L12.1 15.8928L9.3535 13.1464L8.6464 13.8535ZM11.3928 16.6C12.0228 17.2299 13.1 16.7837 13.1 15.8928H12.1H12.0999L11.3928 16.6ZM13.1 15.8928V13.5H12.1V15.8928H13.1ZM14.3 13H12.6V14H14.3V13ZM14.8 12.5C14.8 12.7761 14.5761 13 14.3 13V14C15.1284 14 15.8 13.3284 15.8 12.5H14.8ZM14.8 3.69995V12.5H15.8V3.69995H14.8ZM14.3 3.19995C14.5761 3.19995 14.8 3.42381 14.8 3.69995H15.8C15.8 2.87152 15.1284 2.19995 14.3 2.19995V3.19995ZM3.69995 3.19995H14.3V2.19995H3.69995V3.19995Z"
      fill="currentColor"
    />
    <rect x="6.29993" y="6.30005" width="5.4" height="0.900001" rx="0.45" fill="currentColor" />
    <rect x="6.29993" y="9" width="5.4" height="0.900001" rx="0.45" fill="currentColor" />
  </svg>
);
