import { isEqual } from 'lodash';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { Props as FormattedMessageProps } from 'react-intl/src/components/message';

import usePrevious from './usePrevious';

export type FormError<V extends Record<string, any> = Record<string, React.ReactNode>> =
  | FormattedMessageProps<V>
  | string
  | null
  | undefined;

export type FormBaseErrors = Record<string, FormError>;
export type FormStateErrors<T extends {}> = Partial<Record<keyof T, FormError>>;

export type FormBaseTouched = Record<string, boolean | undefined>;
export type FormStateTouched<T extends {}> = Partial<Record<keyof T, boolean | undefined>>;

export interface FormState<T extends {}> {
  errors?: FormStateErrors<T>;
  asyncErrors?: FormStateErrors<T>;
  touched?: FormStateTouched<T>;
  formTouched?: boolean;
  lastFieldEdited?: keyof T;
}

export interface FormRule<T extends FormState<T>> {
  required?: boolean | FormError;
  custom?: (state: T) => true | FormError;
  async?: (state: T) => true | FormError;
}

export type FormRules<T extends FormState<T>> = Partial<Record<keyof T | string, FormRule<T>>>;

export type FormSubmitHandler<T extends FormState<T>> = (
  event: React.FormEvent<HTMLFormElement>,
  isValid?: boolean,
  state?: T,
) => void;

export interface UseFormProps<T extends FormState<T>> {
  state: T | undefined;
  setState: Dispatch<SetStateAction<T | undefined>>;
  rules: FormRules<T>;
  core?: boolean;
}

export interface UseFormReturn<T extends FormState<T>> {
  isValid: boolean;
  validate: (props?: { field?: keyof T; force?: boolean }) => void;
  onSubmit: (
    handleSubmit: FormSubmitHandler<T>,
  ) => (event: React.FormEvent<HTMLFormElement>) => void;
  set: (field: keyof T, value: T[keyof T], lastFieldEdited?: keyof T) => void;
}

const isFormTouched = (touched?: Record<string, boolean | undefined>) =>
  Object.values(touched ?? {}).some(Boolean);

export const useForm = <T extends FormState<T>>({
  state,
  setState,
  rules,
  core,
}: UseFormProps<T>): UseFormReturn<T> => {
  const submitRef = useRef<FormSubmitHandler<T> | null>();
  const submitEventRef = useRef<React.FormEvent<HTMLFormElement> | null>();

  const prevState = usePrevious(state);

  const isValid = Object.values(state?.errors ?? {}).every(
    (errorValue) => !errorValue || !Object.keys(errorValue).length,
  );

  const isFieldTouched = useCallback(
    (field: keyof T) => state?.touched?.[field] || state?.[field] !== prevState?.[field],
    [state, prevState],
  );

  const validateField = useCallback(
    (field: keyof T) => {
      if (!state) {
        return;
      }

      let error: FormError;
      let asyncError: FormError;

      // required
      if (rules[field]?.required) {
        if (
          !state[field] ||
          (Array.isArray(state[field]) && (state[field] as unknown as []).length < 1)
        )
          error =
            rules[field]!.required === true
              ? { id: 'input-ErrorRequired' }
              : (rules[field]!.required as FormError);
      }

      if (rules[field]?.custom) {
        const result = rules[field]!.custom!(state);

        if (result !== true) {
          error = result;
        }
      }

      if (rules[field]?.async) {
        const result = rules[field]!.async!(state);

        if (result !== true) {
          asyncError = result;
        }
      }

      return {
        errors: { [field]: error },
        asyncErrors: asyncError ? { [field]: asyncError } : {},
        touched: { [field]: isFieldTouched(field) },
      };
    },
    [state, rules, isFieldTouched],
  );

  const validate = useCallback<UseFormReturn<T>['validate']>(
    ({ field = state?.lastFieldEdited, force } = {}) => {
      if (!state) {
        return;
      }

      if (!force && isEqual(prevState, state)) {
        // no changes in the state
        return;
      }

      const validated = ((Object.keys(rules) || []) as (keyof T)[]).reduce((prev, field) => {
        const fieldState = validateField(field);

        return fieldState
          ? ({
              errors: { ...prev.errors, ...fieldState.errors },
              touched: { ...prev.touched, ...fieldState.touched },
              asyncErrors: { ...prev.asyncErrors, ...fieldState.asyncErrors },
            } as FormState<T>)
          : prev;
      }, {} as FormState<T>);

      if (
        !force &&
        !field &&
        (!validated.asyncErrors || !Object.keys(validated.asyncErrors).length)
      ) {
        return;
      }

      const errors = {
        ...(!force && field && { [field]: validated.errors?.[field] }),
        ...(force && validated.errors),

        // 'async' errors are always kept in the state while it won't be cleared from the rule
        ...validated.asyncErrors,
      };

      setState({
        ...state,
        errors: {
          ...state.errors,
          ...errors,
        },
        touched: { ...state.touched, ...validated.touched },
        formTouched: state.formTouched || isFormTouched(validated.touched),
        ...(force && { lastFieldEdited: undefined }),
      });
    },
    [prevState, rules, setState, state, validateField],
  );

  const onSubmit = useCallback<UseFormReturn<T>['onSubmit']>(
    (handleSubmit) => {
      return (event) => {
        event.preventDefault();

        // temporary store a callback to call it after validation
        submitRef.current = handleSubmit;
        submitEventRef.current = event;

        validate({ force: true });
      };
    },
    [validate],
  );

  const set = useCallback<UseFormReturn<T>['set']>(
    (field, value, lastFieldEdited) => {
      setState((prevState) => ({
        ...prevState,
        ...({ [field]: value } as unknown as T),
        lastFieldEdited: lastFieldEdited ?? field,
      }));
    },
    [setState],
  );

  /* Validate form on values change */
  useEffect(() => {
    if (!core) {
      return;
    }

    validate();
  }, [core, validate]);

  /* call onSubmit callback after validation  */
  useEffect(() => {
    if (state && submitRef.current) {
      if (isValid) {
        submitRef.current(submitEventRef.current!, isValid, state);
      }

      submitRef.current = null;
      submitEventRef.current = null;
    }
  }, [isValid, state]);

  return {
    isValid,
    validate,
    onSubmit,
    set,
  };
};
