import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

const MODAL_FADEOUT_DURATION_MS = 300;

export const useFlag = (initial = false, debounceTimeout = MODAL_FADEOUT_DURATION_MS) => {
  const [isOpen, setIsOpen] = useState(initial);

  const setTrue = useCallback(() => {
    setIsOpen(true);
  }, []);

  const setTrueDebounced = useMemo(
    () =>
      debounce(setTrue, debounceTimeout, {
        leading: false,
        trailing: true,
      }),
    [debounceTimeout, setTrue],
  );

  const setFalse = useCallback(() => {
    setIsOpen(false);
  }, []);

  const setFalseDebounced = useMemo(
    () =>
      debounce(setFalse, debounceTimeout, {
        leading: true,
        trailing: false,
      }),
    [debounceTimeout, setFalse],
  );

  return [isOpen, setTrue, setFalse, setTrueDebounced, setFalseDebounced] as const;
};
