import { Stack, Tooltip, Typography } from '@mui/material';
import { Report, ReportAction, ReportStatuses } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { HeaderActions } from '@schooly/components/header-actions';
import { DATE_FORMAT } from '@schooly/constants';
import { PublishIcon, UnpublishIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

interface ReportsPreviewActionsProps {
  isLoading: boolean;
  onClick: (action: ReportAction) => Promise<void>;
  report?: Report;
}

export const ReportsPreviewActions: FC<ReportsPreviewActionsProps> = ({
  isLoading,
  onClick,
  report,
}) => {
  const { permissions } = useAuth();
  const isReportPublished = report?.report_status === ReportStatuses.Published;
  const isReportDraft = report?.report_status === ReportStatuses.Draft;
  const canPublish = Boolean(permissions?.includes('assessment_manager'));

  const { $t } = useIntl();

  const handleClick = useCallback(async () => {
    if (isReportDraft) return false;

    const action = isReportPublished ? ReportAction.Unpublish : ReportAction.Publish;
    await onClick(action);
    return true;
  }, [isReportDraft, isReportPublished, onClick]);

  const title = useMemo(() => {
    if (isReportPublished) {
      return `${$t({ id: 'status-Published' })}${' '} ${
        report?.scheduled_publish_date
          ? format(newDateTimezoneOffset(report.scheduled_publish_date), DATE_FORMAT)
          : ''
      }`;
    }

    return $t({ id: 'status-NotPublished' });
  }, [$t, report?.scheduled_publish_date, isReportPublished]);

  const actions = useMemo(() => {
    if (isReportDraft) return [];

    return [
      {
        titleTextId: $t({ id: isReportPublished ? 'reports-Unpublish' : 'reports-Publish' }),
        handler: handleClick,
        icon: isReportPublished ? (
          <UnpublishIcon />
        ) : (
          <PublishIcon className="reset-svg-currentColor" />
        ),
        pendingTitle: $t({ id: isReportPublished ? 'unPublishing' : 'publishing' }),
      },
    ];
  }, [$t, handleClick, isReportDraft, isReportPublished]);

  if (!report || !canPublish) {
    return null;
  }

  if (isReportDraft)
    return (
      <Tooltip title={$t({ id: 'reports-YouCannonPublish' })}>
        <Stack
          sx={(theme) => ({
            border: theme.mixins.borderValue(),
            borderRadius: theme.spacing(2),
            px: theme.spacing(1.25),
            height: theme.spacing(4),
            justifyContent: 'center',
          })}
        >
          <Typography>{$t({ id: 'status-Draft' })}</Typography>
        </Stack>
      </Tooltip>
    );

  return (
    <HeaderActions
      title={title}
      actions={actions}
      styleType={isReportPublished ? 'success' : isReportDraft ? undefined : 'warning'}
      pending={isLoading}
    />
  );
};
