import { useEffect, useState } from 'react';

const DEFAULT_TRANSITION_DURATION_MS = 300;

export default function useShowTransition(
  show: boolean,
  transitionDuration = DEFAULT_TRANSITION_DURATION_MS,
  beforeShow?: VoidFunction,
  afterHide?: VoidFunction,
) {
  const [canRender, setCanRender] = useState(false);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (show) {
      if (beforeShow) {
        beforeShow();
      }
      setCanRender(true);
      requestAnimationFrame(() => {
        setIsShown(true);
      });
    } else {
      setIsShown(false);
      setTimeout(() => {
        setCanRender(false);
        if (afterHide) {
          afterHide();
        }
      }, transitionDuration);
    }
  }, [show, transitionDuration, beforeShow, afterHide]);

  return {
    canRender,
    isShown,
  };
}
