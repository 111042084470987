import { Box, IconButton, Typography } from '@mui/material';
import {
  DeleteIcon,
  Grid,
  GridBody,
  GridCell,
  GridHead,
  GridRow,
  Price,
  TypographyWithOverflowHint,
} from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { getCurrencySymbol } from '../../../../../School/SchoolProducts/helpers';
import { SingleInvoiceForm } from '../SingleInvoicesContent';
import { getProductTotal } from '../utils';
import { AddProductForm, DiscountForm, ProductDropdownForm, QuantityForm } from './ProductForms';

type ProductContentProps = {
  index: number;
  schoolId: string;
};

export const ProductContent: FC<ProductContentProps> = ({ index, schoolId }) => {
  const { control, watch } = useFormContext<SingleInvoiceForm>();
  const name = `assignments.${index}.products` as const;
  const relationId = watch(`assignments.${index}.student`).relation_id;
  const { formatMessage } = useIntl();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name,
  });

  return (
    <Box>
      {!!fields.length ? (
        <Grid>
          <GridHead
            borderBottom
            sx={{
              '& .MuiTableCell-root': {
                height: '34px !important',
              },
            }}
          >
            <GridRow>
              <GridCell width={296}>
                <Typography>{formatMessage({ id: 'products-Product' })}</Typography>
              </GridCell>
              <GridCell width={136}>
                <Typography>{formatMessage({ id: 'legalEntities-LegalEntity' })}</Typography>
              </GridCell>
              <GridCell width={138}>
                <Typography>{formatMessage({ id: 'products-Account' })}</Typography>
              </GridCell>
              <GridCell width={44}>
                <Typography>{formatMessage({ id: 'singleInvoices-ProductQuantity' })}</Typography>
              </GridCell>
              <GridCell width={128}>
                <Typography>{formatMessage({ id: 'products-Price' })}</Typography>
              </GridCell>
              <GridCell width={36}>
                <Typography>{formatMessage({ id: 'singleInvoices-ProductDiscount' })}</Typography>
              </GridCell>
              <GridCell width={126}>
                <Typography>{formatMessage({ id: 'singleInvoices-ProductAmount' })}</Typography>
              </GridCell>
              <GridCell />
            </GridRow>
          </GridHead>

          <GridBody
            sx={{
              '& .MuiTableCell-root': {
                height: '44px !important',
              },
            }}
          >
            {fields.map((f, productIdx) => (
              <AssignProductRow
                key={f.id}
                productIdx={productIdx}
                assignmentIdx={index}
                schoolId={schoolId}
                onRemoveRow={() => remove(productIdx)}
              />
            ))}
          </GridBody>
        </Grid>
      ) : null}
      <Box mt={!fields.length ? 4 : 0}>
        <AddProductForm name={name} onSelect={append} schoolId={schoolId} relationId={relationId} />
      </Box>
    </Box>
  );
};

type AssignProductRowProps = {
  assignmentIdx: number;
  productIdx: number;
  schoolId: string;
  onRemoveRow: () => void;
};

export const AssignProductRow: FC<AssignProductRowProps> = ({
  assignmentIdx,
  productIdx,
  schoolId,
  onRemoveRow,
}) => {
  const { watch } = useFormContext<SingleInvoiceForm>();

  const assignedProduct = watch(`assignments.${assignmentIdx}.products.${productIdx}`);

  const handleClose = useCallback(() => {
    if (!assignedProduct) {
      onRemoveRow();
    }
  }, [onRemoveRow, assignedProduct]);

  const { billing_connection, price, quantity, discount_percent } = assignedProduct;

  return (
    <GridRow
      sx={{
        ':hover': {
          '.MuiTypography-root': {
            color: 'primary.main',
          },
          '.hoverText': {
            color: 'common.grey2',
          },
        },
      }}
    >
      <GridCell noVerticalPadding>
        <ProductDropdownForm
          schoolId={schoolId}
          assignmentIdx={assignmentIdx}
          productIdx={productIdx}
          onClose={handleClose}
        />
      </GridCell>
      <GridCell noVerticalPadding>
        <TypographyWithOverflowHint color="common.grey2" maxWidth={120}>
          {billing_connection.legal_entity_display_name}
        </TypographyWithOverflowHint>
      </GridCell>
      <GridCell noVerticalPadding>
        <TypographyWithOverflowHint color="common.grey2" maxWidth={120}>
          {billing_connection.legal_entity_account.type === 'xero'
            ? billing_connection.legal_entity_account.name
            : '-'}
        </TypographyWithOverflowHint>
      </GridCell>
      <GridCell noVerticalPadding>
        <QuantityForm productIdx={productIdx} assignmentIdx={assignmentIdx} />
      </GridCell>
      <GridCell noVerticalPadding>
        <Price
          color="common.grey2"
          variant="body1"
          price={price}
          currency={getCurrencySymbol(billing_connection.legal_entity_currency)}
        />
      </GridCell>
      <GridCell noVerticalPadding>
        <DiscountForm
          name={`assignments.${assignmentIdx}.products.${productIdx}.discount_percent`}
        />
      </GridCell>
      <GridCell noVerticalPadding>
        <Price
          color="common.grey2"
          variant="body1"
          price={getProductTotal({
            price,
            quantity: Number.isInteger(quantity) ? quantity : 1,
            discountPercent: discount_percent,
          })}
          currency={getCurrencySymbol(billing_connection.legal_entity_currency)}
        />
      </GridCell>
      <GridCell noVerticalPadding>
        <IconButton inverse onClick={onRemoveRow}>
          <DeleteIcon />
        </IconButton>
      </GridCell>
    </GridRow>
  );
};
