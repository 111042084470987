import { IconButton, Stack, TableHeadProps, TableRow } from '@mui/material';
import { StudentWithProducts } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { ArrowRightIcon, GridCell, GridHead, TypographyWithOverflowHint } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getRouteModalPathname } from '../../../helpers/misc';
import { HandleStudentSelectProps } from './AddStudentsToCompanyModalContent';

export const StudentsAddToCompanyHeader: FC<TableHeadProps> = ({ ...rest }) => {
  const { $t } = useIntl();

  return (
    <GridHead borderBottom {...rest}>
      <GridCell sx={{ '&.MuiTableCell-root.MuiTableCell-head': { px: 0 } }}>
        {$t({ id: 'people-Name' })}
      </GridCell>
      <GridCell>{$t({ id: 'companies-Company' })}</GridCell>
      <GridCell width="40px" />
    </GridHead>
  );
};

interface StudentAddToCompanyRowProps {
  student: StudentWithProducts;
  handleStudentSelect: (p: HandleStudentSelectProps) => void;
}

export const StudentAddToCompanyRow: FC<StudentAddToCompanyRowProps> = ({
  student,
  handleStudentSelect,
}) => {
  const name = getUserFullName(student);
  const company = student.company_payer;

  return (
    <TableRow
      onClick={() =>
        handleStudentSelect({
          studentId: student.relation_id,
          studentName: name,
          companyPayerId: student.company_payer?.id,
          companyPayerName: student.company_payer?.name,
        })
      }
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': {
          ' td.MuiTableCell-root': {
            backgroundColor: theme.palette.background.default,
            '.MuiTypography-root, .MuiButtonBase-root': {
              color: theme.palette.primary.main,
            },
          },
        },
      })}
    >
      <GridCell py={0.75}>
        <Stack direction="row" alignItems="center" gap={1}>
          <AvatarAuth user={student} />
          <TypographyWithOverflowHint
            variant="h3"
            color="text.primary"
            noWrap
            component={Link}
            to={getRouteModalPathname('student', student)}
            sx={{ '&:hover': { textDecoration: 'underline' } }}
          >
            {name}
          </TypographyWithOverflowHint>
        </Stack>
      </GridCell>
      <GridCell py={1.5}>
        {company && (
          <TypographyWithOverflowHint
            color="text.primary"
            noWrap
            component={Link}
            to={`/companies/${company.id}`}
            sx={{ '&:hover': { textDecoration: 'underline' } }}
          >
            {company.name}
          </TypographyWithOverflowHint>
        )}
      </GridCell>
      <GridCell py={1.5}>
        <IconButton inverse>
          <ArrowRightIcon />
        </IconButton>
      </GridCell>
    </TableRow>
  );
};
