import { Box, Button, Stack } from '@mui/material';
import { ApiError } from '@schooly/api';
import {
  checkPasswordCharacters,
  checkPasswordRequirements,
  passwordValidCharactersCheck,
  SignUpModalPasswordRequirements,
  useUserContext,
} from '@schooly/components/authentication';
import { ControlPasswordField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import { CheckIcon, Spin } from '@schooly/style';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '../../ui/Modal';

//TODO: Rewrite with MUI
import './index.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

const defaultValues: ChangePasswordFormData = {
  oldPassword: '',
  newPassword: '',
  newPasswordRepeat: '',
};

const PasswordChangeModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { changePassword } = useUserContext();
  const [isChanging, startChanging, stopChanging] = useFlag();

  const { showNotification, showError } = useNotifications();
  const { $t } = useIntl();

  const form = useForm<ChangePasswordFormData>({ defaultValues });

  const password: string = useWatch({ control: form.control, name: 'newPassword' });
  const hasRequirementsError = form.formState.errors.newPassword?.type === 'requirementsError';

  //Checks if password only contains valid characters before submit
  useEffect(() => {
    if (form.formState.errors.newPassword?.type === 'charactersError') {
      form.clearErrors('newPassword');
    }
    if (password && !checkPasswordCharacters(password)) {
      form.setError('newPassword', {
        type: 'charactersError',
        message: $t({ id: passwordValidCharactersCheck.message }),
      });
    }
  }, [$t, form, password]);

  const closeAndReset = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const updatePassword = useCallback(
    async (data: ChangePasswordFormData) => {
      const { newPassword, newPasswordRepeat } = data;

      const passwordValid = checkPasswordRequirements(password);
      const passwordConfirmed = newPassword === newPasswordRepeat;

      if (!passwordValid) {
        form.setError('newPassword', { type: 'requirementsError' });
      }

      if (!passwordConfirmed) {
        form.setError('newPasswordRepeat', {
          type: 'validate',
          message: $t({ id: 'error-PasswordMismatch' }),
        });
      }

      if (!passwordValid || !passwordConfirmed) return;

      startChanging();

      try {
        await changePassword(data);

        showNotification({
          textId: 'confirmation-PasswordUpdate',
          type: 'success',
        });
        closeAndReset();
      } catch (e) {
        showError(e as ApiError);
      }
      stopChanging();
    },
    [
      $t,
      closeAndReset,
      form,
      password,
      showError,
      showNotification,
      startChanging,
      stopChanging,
      changePassword,
    ],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndReset}
      className="PasswordChangeModal"
      titleTextId="login-ChangePassword"
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(updatePassword)}>
          <Stack gap={2}>
            <ControlPasswordField
              name="oldPassword"
              control={form.control}
              rules={{ required: true }}
              label={$t({ id: 'profile-YourCurrentPassword' })}
              fullWidth
            />
            <Stack gap={0.5}>
              <ControlPasswordField
                name="newPassword"
                control={form.control}
                rules={{
                  required: true,
                  //Checks if password only contains valid characters after submit
                  pattern: {
                    value: passwordValidCharactersCheck.regex,
                    message: $t({
                      id: passwordValidCharactersCheck.message,
                    }),
                  },
                }}
                label={$t({ id: 'profile-NewPassword' })}
                fullWidth
              />
              <SignUpModalPasswordRequirements password={password} error={hasRequirementsError} />
            </Stack>
            <ControlPasswordField
              name="newPasswordRepeat"
              control={form.control}
              rules={{ required: true }}
              label={$t({ id: 'profile-RepeatNewPassword' })}
              fullWidth
            />
          </Stack>

          <Box my={4} sx={{ textAlign: 'right' }}>
            <Button
              type="submit"
              endIcon={isChanging ? <Spin /> : <CheckIcon />}
              disabled={isChanging}
            >
              <FormattedMessage id="action-Save" />
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default PasswordChangeModal;
