import { ChipProps } from '@mui/material';
import { getLabelIdForOption, Nationalities } from '@schooly/constants';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

export type NationalityTagSelectProps = {
  nationality: Nationalities;
} & Omit<ChipProps, 'label'>;

const OPTIONS = $enum(Nationalities).map((value, key) => ({ value, key }));

export const NationalityTagSelect: FC<NationalityTagSelectProps> = ({ nationality, ...props }) => {
  const { formatMessage } = useIntl();

  const key = OPTIONS.find((o) => o.value === nationality)?.key;
  const label = key
    ? formatMessage({ id: getLabelIdForOption({ optionsKey: 'nationality', key }) })
    : undefined;

  return <TagSelect label={label} {...props} />;
};
