import {
  AnnualPlanRecord,
  AnnualPlanRecordAssessment,
  AnnualPlanRecordEvent,
  AnnualPlanRecordReport,
  AnnualPlanRecordSchoolPeriod,
  AnnualPlanRecordTypes,
  Assessment,
  AssessmentGroup,
  Event,
  Report,
  SchoolYearPeriodGroup,
} from '@schooly/api';

export const ANNUAL_PLANNER_CALENDAR_COLS = 12;
export const ANNUAL_PLANNER_CALENDAR_ROWS = 31;

export const ANNUAL_PLANNER_CELL_HEIGHT = 14;

export const ANNUAL_PLANNER_DAY_CELL_WIDTH = 20;

export const ANNUAL_PLANNER_RECORD_OVERLAP_PADDING = 3;

export interface AnnualPlannerCalendarWithDates {
  start: string | Date;
  end: string | Date;
}

export interface AnnualPlannerRecordMetaBase {
  id: string;
  title: string;
  type: AnnualPlanRecordTypes;
  start: Date;
  end: Date;
  details: AnnualPlanRecord;
}

export interface AnnualPlannerRecordMetaSchoolPeriod extends AnnualPlannerRecordMetaBase {
  type: AnnualPlanRecordTypes.SCHOOL_PERIOD;
  details: AnnualPlanRecordSchoolPeriod;
}

export interface AnnualPlannerRecordMetaEvent extends AnnualPlannerRecordMetaBase {
  type: AnnualPlanRecordTypes.EVENT | AnnualPlanRecordTypes.HOLIDAY;
  details: AnnualPlanRecordEvent;
}

export interface AnnualPlannerRecordMetaAssessment extends AnnualPlannerRecordMetaBase {
  type: AnnualPlanRecordTypes.ASSESSMENT;
  details: AnnualPlanRecordAssessment;
}

export interface AnnualPlannerRecordMetaReport extends AnnualPlannerRecordMetaBase {
  type: AnnualPlanRecordTypes.REPORT;
  details: AnnualPlanRecordReport;
}

export type AnnualPlannerRecordMeta =
  | AnnualPlannerRecordMetaSchoolPeriod
  | AnnualPlannerRecordMetaEvent
  | AnnualPlannerRecordMetaAssessment
  | AnnualPlannerRecordMetaReport;

export type AnnualPlannerRecordMetaWithRecurring =
  | AnnualPlannerRecordMetaEvent
  | AnnualPlannerRecordMetaAssessment;

export const canBeRecurring = (
  meta: AnnualPlannerRecordMeta,
): meta is AnnualPlannerRecordMetaWithRecurring => {
  switch (meta.type) {
    case AnnualPlanRecordTypes.EVENT:
    case AnnualPlanRecordTypes.HOLIDAY:
    case AnnualPlanRecordTypes.ASSESSMENT:
      return true;
    default:
      return false;
  }
};

export interface AnnualPlannerCreateSchoolPeriodForm {
  groupId?: SchoolYearPeriodGroup['id'] | 'new';
  groupName?: SchoolYearPeriodGroup['name'];
}

export interface AnnualPlannerCreateEventForm {
  name?: Event['title'];
  isHoliday?: boolean;
}

export interface AnnualPlannerCreateAssessmentForm {
  name?: Assessment['name'];
  groups?: AssessmentGroup[];
}

export interface AnnualPlannerCreateReportForm {
  name?: Report['name'];
}

export interface AnnualPlannerCreateForm {
  originId?: string;
  type?: AnnualPlanRecordTypes;

  date: [string, string];

  schoolPeriod: AnnualPlannerCreateSchoolPeriodForm;
  event: AnnualPlannerCreateEventForm;
  assessment: AnnualPlannerCreateAssessmentForm;
  report: AnnualPlannerCreateReportForm;
}

export const addSchoolPeriodGroupOption = { value: 'new', label: 'Add group' };
