import React from 'react';

import { ReactComponent as AppstoreIconSvg } from '../../../assets/images/icons/appstore.svg';
import { ReactComponent as ArrowDownIconSvg } from '../../../assets/images/icons/arrow_down.svg';
import { ReactComponent as DropdownIconSvg } from '../../../assets/images/icons/arrow_dropdown.svg';
import { ReactComponent as ArrowLeftIconSvg } from '../../../assets/images/icons/arrow_left.svg';
import { ReactComponent as ArrowRightIconSvg } from '../../../assets/images/icons/arrow_right.svg';
import { ReactComponent as ArrowSchoolSelectSvg } from '../../../assets/images/icons/arrow_school_select.svg';
import { ReactComponent as ArrowheadDownIconSvg } from '../../../assets/images/icons/arrowhead_down.svg';
import { ReactComponent as ArrowsIconSvg } from '../../../assets/images/icons/arrows.svg';
import { ReactComponent as ArrowsMenuIconSvg } from '../../../assets/images/icons/arrows_menu.svg';
import { ReactComponent as AssessmentsIconSvg } from '../../../assets/images/icons/assessments.svg';
import { ReactComponent as AttachmentIconSvg } from '../../../assets/images/icons/attachment.svg';
import { ReactComponent as AttendanceIconSvg } from '../../../assets/images/icons/attendance.svg';
import { ReactComponent as AttentionIconSvg } from '../../../assets/images/icons/attention.svg';
import { ReactComponent as BellIconSvg } from '../../../assets/images/icons/bell.svg';
import { ReactComponent as DateIconSvg } from '../../../assets/images/icons/birthday.svg';
import { ReactComponent as BoldIconSvg } from '../../../assets/images/icons/bold.svg';
import { ReactComponent as CalendarIconSvg } from '../../../assets/images/icons/calendar.svg';
import { ReactComponent as CalendarTodayIconSvg } from '../../../assets/images/icons/calendar-today.svg';
import { ReactComponent as CameraIconSvg } from '../../../assets/images/icons/camera.svg';
import { ReactComponent as ChartIconSvg } from '../../../assets/images/icons/chart.svg';
import { ReactComponent as CheckIconSvg } from '../../../assets/images/icons/check.svg';
import { ReactComponent as CheckboxIconSvg } from '../../../assets/images/icons/checkbox.svg';
import { ReactComponent as ClipIconSvg } from '../../../assets/images/icons/clip.svg';
import { ReactComponent as CommentIconSvg } from '../../../assets/images/icons/comment.svg';
import { ReactComponent as CommentFilledIconSvg } from '../../../assets/images/icons/comment-filled.svg';
import { ReactComponent as ConductIconSvg } from '../../../assets/images/icons/conduct.svg';
import { ReactComponent as CookiesIconSvg } from '../../../assets/images/icons/cookies.svg';
import { ReactComponent as CopyIconSvg } from '../../../assets/images/icons/copy.svg';
import { ReactComponent as CrossIconSvg } from '../../../assets/images/icons/cross.svg';
import { ReactComponent as CrossSmallIconSvg } from '../../../assets/images/icons/cross_small.svg';
import { ReactComponent as DeleteIconSvg } from '../../../assets/images/icons/delete.svg';
import { ReactComponent as DeleteFilledIconSvg } from '../../../assets/images/icons/delete-filled.svg';
import { ReactComponent as DocumentIconSvg } from '../../../assets/images/icons/document.svg';
import { ReactComponent as DoneIconSvg } from '../../../assets/images/icons/done.svg';
import { ReactComponent as DoubleCheckIconSvg } from '../../../assets/images/icons/double-check.svg';
import { ReactComponent as DownloadIconSvg } from '../../../assets/images/icons/download.svg';
import { ReactComponent as DragIconSvg } from '../../../assets/images/icons/drag.svg';
import { ReactComponent as EditIconSvg } from '../../../assets/images/icons/edit.svg';
import { ReactComponent as ExpandIconSvg } from '../../../assets/images/icons/expand.svg';
import { ReactComponent as EyeIconSvg } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeCloseIconSvg } from '../../../assets/images/icons/eye-close.svg';
import { ReactComponent as FemaleIconSvg } from '../../../assets/images/icons/female.svg';
import { ReactComponent as FilterIconSvg } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as FlagIconSvg } from '../../../assets/images/icons/flag.svg';
import { ReactComponent as GridViewIconSvg } from '../../../assets/images/icons/grid_view.svg';
import { ReactComponent as GroupsIconSvg } from '../../../assets/images/icons/group.svg';
import { ReactComponent as HashIconSvg } from '../../../assets/images/icons/hash.svg';
import { ReactComponent as ImageFormatSvg } from '../../../assets/images/icons/image_format.svg';
import { ReactComponent as ImageSizeSvg } from '../../../assets/images/icons/image_size.svg';
import { ReactComponent as InformationIconSvg } from '../../../assets/images/icons/information.svg';
import { ReactComponent as ItalicIconSvg } from '../../../assets/images/icons/italic.svg';
import { ReactComponent as Language2IconSvg } from '../../../assets/images/icons/language 2.svg';
import { ReactComponent as LanguageIconSvg } from '../../../assets/images/icons/language.svg';
import { ReactComponent as LinkIconSvg } from '../../../assets/images/icons/link.svg';
import { ReactComponent as ListViewIconSvg } from '../../../assets/images/icons/list_view.svg';
import { ReactComponent as ListOlIconSvg } from '../../../assets/images/icons/list-ol.svg';
import { ReactComponent as ListUlIconSvg } from '../../../assets/images/icons/list-ul.svg';
import { ReactComponent as LocationIconSvg } from '../../../assets/images/icons/location.svg';
import { ReactComponent as LockIconSvg } from '../../../assets/images/icons/lock.svg';
import {
  ReactComponent as EmailIconSvg,
  ReactComponent as MailIconSvg,
} from '../../../assets/images/icons/mail.svg';
import { ReactComponent as MaleIconSvg } from '../../../assets/images/icons/Male.svg';
import { ReactComponent as MedicalIconSvg } from '../../../assets/images/icons/medical.svg';
import { ReactComponent as MigrationIconSvg } from '../../../assets/images/icons/migration.svg';
import { ReactComponent as MinusIconSvg } from '../../../assets/images/icons/minus.svg';
import { ReactComponent as MoreIconSvg } from '../../../assets/images/icons/more.svg';
import { ReactComponent as ParentIconSvg } from '../../../assets/images/icons/parent.svg';
import { ReactComponent as PercentIconSvg } from '../../../assets/images/icons/percent.svg';
import { ReactComponent as PhoneIconSvg } from '../../../assets/images/icons/phone.svg';
import { ReactComponent as PlaystoreIconSvg } from '../../../assets/images/icons/playstore.svg';
import { ReactComponent as PlusIconSvg } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as ProfileIconSvg } from '../../../assets/images/icons/profile.svg';
import { ReactComponent as RadioOffIconSvg } from '../../../assets/images/icons/radio-off.svg';
import { ReactComponent as RadioOnIconSvg } from '../../../assets/images/icons/radio-on.svg';
import { ReactComponent as RadioOnIconNegativeSvg } from '../../../assets/images/icons/radio-on-negative.svg';
import { ReactComponent as RadioOnIconPositiveSvg } from '../../../assets/images/icons/radio-on-positive.svg';
import { ReactComponent as ReportsIconSvg } from '../../../assets/images/icons/reports.svg';
import { ReactComponent as RollBackIconSvg } from '../../../assets/images/icons/roll-back.svg';
import { ReactComponent as RollUpIconSvg } from '../../../assets/images/icons/roll-up.svg';
import { ReactComponent as SearchIconSvg } from '../../../assets/images/icons/search.svg';
import { ReactComponent as SettingsIconSvg } from '../../../assets/images/icons/settings.svg';
import { ReactComponent as StaffIconSvg } from '../../../assets/images/icons/staff.svg';
import { ReactComponent as StarIconSvg } from '../../../assets/images/icons/star.svg';
import { ReactComponent as StudentIconSvg } from '../../../assets/images/icons/student.svg';
import { ReactComponent as SyncIconSvg } from '../../../assets/images/icons/sync.svg';
import { ReactComponent as TickIconSvg } from '../../../assets/images/icons/tick.svg';
import { ReactComponent as UnderlineIconSvg } from '../../../assets/images/icons/underline.svg';
import { ReactComponent as UserRoleIconSvg } from '../../../assets/images/icons/user_role.svg';
import { ReactComponent as ConfigIconSvg } from '../../../assets/images/icons_old/config.svg';
import { ReactComponent as GroupsConfigurationIconSvg } from '../../../assets/images/icons_old/groups_configuration.svg';
import { ReactComponent as HomeIconSvg } from '../../../assets/images/icons_old/home.svg';
import { ReactComponent as OptionsIconSvg } from '../../../assets/images/icons_old/options.svg';
import { ReactComponent as PeopleIconSvg } from '../../../assets/images/icons_old/people.svg';
import { ReactComponent as ChevronUpIconSvg } from '../../../assets/images/icons_old/up_arrow.svg';
import { ReactComponent as WarningIconSvg } from '../../../assets/images/icons_old/warning.svg';
import buildClassName from '../../../utils/buildClassName';

function makeIconComponent(
  IconComponent: React.FC<React.ComponentProps<'svg'>>,
  displayName?: string,
  extraClassName?: string,
) {
  const Component: React.FC<React.ComponentProps<'svg'>> = ({ className }) => (
    <IconComponent className={buildClassName('svg-icon', className, extraClassName)} />
  );
  Component.displayName = displayName;
  return Component;
}

export const ChevronUpIcon = makeIconComponent(ChevronUpIconSvg, 'ChevronUpIcon');
export const CalendarIcon = makeIconComponent(CalendarIconSvg, 'CalendarIcon');
export const CalendarTodayIcon = makeIconComponent(CalendarTodayIconSvg, 'CalendarTodayIcon');
export const ConfigIcon = makeIconComponent(ConfigIconSvg, 'ConfigIcon');
export const CrossIcon = makeIconComponent(CrossIconSvg, 'CrossIcon');
export const CrossSmallIcon = makeIconComponent(CrossSmallIconSvg, 'CrossSmallIcon');
export const CloseIcon = CrossIcon;
export const CloseSmallIcon = CrossSmallIcon;
export const EditIcon = makeIconComponent(EditIconSvg, 'EditIcon');
export const MoreIcon = makeIconComponent(MoreIconSvg, 'MoreIcon');
export const GroupsIcon = makeIconComponent(GroupsIconSvg, 'GroupsIcon');
export const GroupsConfigurationIcon = makeIconComponent(
  GroupsConfigurationIconSvg,
  'GroupsConfigurationIcon',
);
export const HomeIcon = makeIconComponent(HomeIconSvg, 'HomeIcon');
export const OptionsIcon = makeIconComponent(OptionsIconSvg, 'OptionsIcon');
export const ParentIcon = makeIconComponent(ParentIconSvg, 'ParentIcon');
export const PeopleIcon = makeIconComponent(PeopleIconSvg, 'PeopleIcon');
export const PlusIcon = makeIconComponent(PlusIconSvg, 'PlusIcon');
export const StaffIcon = makeIconComponent(StaffIconSvg, 'StaffIcon');
export const StarIcon = makeIconComponent(StarIconSvg, 'StarIcon');
export const StudentIcon = makeIconComponent(StudentIconSvg, 'StudentIcon');
export const SyncIcon = makeIconComponent(SyncIconSvg, 'SyncIcon');
export const TickIcon = makeIconComponent(TickIconSvg, 'TickIcon');
export const WarningIcon = makeIconComponent(WarningIconSvg, 'WarningIcon');

export const ChevronRightIcon = makeIconComponent(
  DropdownIconSvg,
  'DropdownIcon',
  'ChevronRightIcon',
);
export const ChevronLeftIcon = makeIconComponent(
  DropdownIconSvg,
  'DropdownIcon',
  'ChevronLeftIcon',
);
export const ArrowsIcon = makeIconComponent(ArrowsIconSvg, 'ArrowsIcon');
export const ArrowsMenuIcon = makeIconComponent(ArrowsMenuIconSvg, 'ArrowsMenuIcon');
export const ArrowSchoolSelectIcon = makeIconComponent(
  ArrowSchoolSelectSvg,
  'ArrowSchoolSelectIcon',
);
export const ArrowDownIcon = makeIconComponent(ArrowDownIconSvg, 'ArrowDownIcon');
export const ArrowUpIcon = makeIconComponent(ArrowDownIconSvg, 'ArrowUpIcon', 'ArrowUpIcon');
export const ArrowRightIcon = makeIconComponent(ArrowRightIconSvg, 'ArrowRightIcon');
export const ArrowLeftIcon = makeIconComponent(ArrowLeftIconSvg, 'ArrowLeftIcon');
export const ArrowheadDownIcon = makeIconComponent(ArrowheadDownIconSvg, 'ArrowheadDownIconSvg');
export const CheckIcon = makeIconComponent(CheckIconSvg, 'CheckIcon');
export const CheckboxIcon = makeIconComponent(CheckboxIconSvg, 'CheckboxIcon');
export const CommentIcon = makeIconComponent(CommentIconSvg, 'CommentIcon');
export const CommentFilledIcon = makeIconComponent(CommentFilledIconSvg, 'CommentFilledIcon');
export const CookiesIcon = makeIconComponent(CookiesIconSvg, 'CookiesIcon');
export const DoneIcon = makeIconComponent(DoneIconSvg, 'DoneIcon');
export const DeleteIcon = makeIconComponent(DeleteIconSvg, 'DeleteIcon');
export const DeleteFilledIcon = makeIconComponent(DeleteFilledIconSvg, 'DeleteFilledIcon');
export const DocumentIcon = makeIconComponent(DocumentIconSvg, 'DocumentIcon');
export const DropdownIcon = makeIconComponent(DropdownIconSvg, 'DropdownIcon');
export const FilterIcon = makeIconComponent(FilterIconSvg, 'FilterIcon');
export const GridViewIcon = makeIconComponent(GridViewIconSvg, 'GridViewIcon');
export const LockIcon = makeIconComponent(LockIconSvg, 'LockIcon');
export const ImageFormatIcon = makeIconComponent(ImageFormatSvg, 'ImageFormatIcon');
export const ImageSizeIcon = makeIconComponent(ImageSizeSvg, 'ImageSizeIcon');
export const ListViewIcon = makeIconComponent(ListViewIconSvg, 'ListViewIcon');
export const MailIcon = makeIconComponent(MailIconSvg, 'MailIcon');
export const MigrationIcon = makeIconComponent(MigrationIconSvg, 'MigrationIcon');
export const MinusIcon = makeIconComponent(MinusIconSvg, 'MinusIcon');
export const SearchIcon = makeIconComponent(SearchIconSvg, 'SearchIcon');
export const UserRoleIcon = makeIconComponent(UserRoleIconSvg, 'UserRoleIcon');
export const DateIcon = makeIconComponent(DateIconSvg, 'DateIcon');
export const FemaleIcon = makeIconComponent(FemaleIconSvg, 'FemaleIcon');
export const LanguageIcon = makeIconComponent(LanguageIconSvg, 'LanguageIcon');
export const Language2Icon = makeIconComponent(Language2IconSvg, 'Language2Icon');
export const PhoneIcon = makeIconComponent(PhoneIconSvg, 'PhoneIcon');
export const EmailIcon = makeIconComponent(EmailIconSvg, 'EmailIcon');
export const FlagIcon = makeIconComponent(FlagIconSvg, 'FlagIcon');
export const MaleIcon = makeIconComponent(MaleIconSvg, 'MaleIcon');
export const CameraIcon = makeIconComponent(CameraIconSvg, 'CameraIcon');
export const MedicalIcon = makeIconComponent(MedicalIconSvg, 'MedicalIcon');
export const InformationIcon = makeIconComponent(InformationIconSvg, 'InformationIcon');
export const LocationIcon = makeIconComponent(LocationIconSvg, 'LocationIcon');
export const EyeIcon = makeIconComponent(EyeIconSvg, 'EyeIcon');
export const EyeCloseIcon = makeIconComponent(EyeCloseIconSvg, 'EyeCloseIcon');
export const AppstoreIcon = makeIconComponent(AppstoreIconSvg, 'AppstoreIcon');
export const PlaystoreIcon = makeIconComponent(PlaystoreIconSvg, 'PlaystoreIcon');
export const BellIcon = makeIconComponent(BellIconSvg, 'BellIcon');
export const ProfileIcon = makeIconComponent(ProfileIconSvg, 'ProfileIcon');
export const RadioOffIcon = makeIconComponent(RadioOffIconSvg, 'RadioOffIcon');
export const RadioOnIcon = makeIconComponent(RadioOnIconSvg, 'RadioOnIcon');
export const RollUpIcon = makeIconComponent(RollUpIconSvg, 'RollUpIcon');
export const ExpandIcon = makeIconComponent(ExpandIconSvg, 'ExpandIcon');
export const BoldIcon = makeIconComponent(BoldIconSvg, 'BoldIcon');
export const ItalicIcon = makeIconComponent(ItalicIconSvg, 'ItalicIcon');
export const UnderlineIcon = makeIconComponent(UnderlineIconSvg, 'UnderlineIcon');
export const ListOlIcon = makeIconComponent(ListOlIconSvg, 'ListOlIcon');
export const ListUlIcon = makeIconComponent(ListUlIconSvg, 'ListUlIcon');
export const LinkIcon = makeIconComponent(LinkIconSvg, 'LinkIcon');
export const AssessmentsIcon = makeIconComponent(AssessmentsIconSvg, 'AssessmentsIcon');
export const ReportsIcon = makeIconComponent(ReportsIconSvg, 'ReportsIcon');
export const AttachmentIcon = makeIconComponent(AttachmentIconSvg, 'AttachmentIcon');
export const ClipIcon = makeIconComponent(ClipIconSvg, 'ClipIcon');
export const DoubleCheckIcon = makeIconComponent(DoubleCheckIconSvg, 'DoubleCheckIcon');
export const DownloadIcon = makeIconComponent(DownloadIconSvg, 'DownloadIcon');
export const DragIcon = makeIconComponent(DragIconSvg, 'DragIcon');
export const AttentionIcon = makeIconComponent(AttentionIconSvg, 'AttentionIcon');
export const AttendanceIcon = makeIconComponent(AttendanceIconSvg, 'AttendanceIcon');
export const ConductIcon = makeIconComponent(ConductIconSvg, 'ConductIcon');
export const ChartIcon = makeIconComponent(ChartIconSvg, 'ChartIcon');
export const RollBackIcon = makeIconComponent(RollBackIconSvg, 'RollBackIcon');
export const CopyIcon = makeIconComponent(CopyIconSvg, 'CopyIcon');
export const SettingsIcon = makeIconComponent(SettingsIconSvg, 'SettingsIcon');
export const PercentIcon = makeIconComponent(PercentIconSvg, 'PercentIcon');
export const HashIcon = makeIconComponent(HashIconSvg, 'HashIcon');
export const RadioOnIconNegative = makeIconComponent(RadioOnIconNegativeSvg, 'RadioOnIconNegative');
export const RadioOnIconPositive = makeIconComponent(RadioOnIconPositiveSvg, 'RadioOnIconPositive');
