import { Stack, TableRow, Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetSignUpsListQueryFilters,
  GetSignUpsListSort,
  isSignUpWithEvent,
  isSignUpWithoutEvent,
  SIGN_UPS_FILTER_KEYS,
  SignUp,
  SORT_DIRECTION,
  useGetSignUpsListQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  getParticipantLabel,
  getSignUpsTypeLabel,
  PageHeaderSearchInput,
  StoredFilterSections,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { DATE_FORMAT_SHORT } from '@schooly/constants';
import {
  GridBody,
  GridCell,
  MainPageGrid,
  SkeletonGridLoader,
  SkeletonRows,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';
import useSchoolYears from '../../hooks/useSchoolYears';
import AccessDenied from '../AccessDenied';
import { EventsAndSignUpsPageHeader } from '../EventsAndSignUpsPageHeader/EventsAndSignUpsPageHeader';
import { SignUpsFilters } from './SignUpsFilters';
import { SignUpsHeader, StatusCellContent } from './SignUpsGrid';

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 6;

interface SignUpsContentProps {
  initialFilters?: GetSignUpsListQueryFilters;
}

export const SignUpsContent: FC<SignUpsContentProps> = ({ initialFilters }) => {
  const location = useAppLocation();
  const { schoolId } = useAuth();
  const { $t } = useIntl();
  const { defaultValidity } = useSchoolYears();

  const defaultFilters = useMemo<GetSignUpsListQueryFilters>(
    () => ({
      [FilterKeys.Date]: [
        defaultValidity?.start || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        defaultValidity?.end || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [defaultValidity?.end, defaultValidity?.start],
  );

  const { lastAppliedFilter } = useLastAppliedFiltersState({
    type: StoredFilterSections.SignUps,
    filterKeys: SIGN_UPS_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: SIGN_UPS_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    params,
    error,
    setParams,
    fetchNextPage,
  } = useGetSignUpsListQuery(
    {
      query: '',
      schoolId: schoolId || '',
      filters: lastAppliedFilter ?? initialFiltersState,
      pageSize: PAGE_SIZE,
      sort: { columnTextId: 'end', direction: SORT_DIRECTION.ASC },
    },
    { refetchOnMount: 'always' },
  );

  const filtersDateString = params.filters.date?.join('');
  const notActualInitialDate =
    !defaultValidity?.isActual &&
    filtersDateString === defaultFilters?.date?.join('') &&
    filtersDateString !== initialFilters?.date?.join('');

  const total = data?.pages[0]?.count;

  useSyncFiltersStateWithSearchParams({
    pathname: '/signups',
    filters: params.filters,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.SignUps,
    filters: params.filters,
    schoolId: schoolId || '',
  });

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetSignUpsListQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const signups = useMemo(
    () => data?.pages.reduce<SignUp[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  const handleChangeSort = useCallback(
    (sort: GetSignUpsListSort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  if (!schoolId) return null;

  if (error) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack gap={1}>
        <EventsAndSignUpsPageHeader value="signUps">
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={$t(
              { id: 'people-SearchAmongType' },
              {
                userTypePlural: $t({
                  id: 'section-SignUps',
                }).toLowerCase(),
              },
            )}
          />
        </EventsAndSignUpsPageHeader>
        <SignUpsFilters
          defaultFilters={defaultFilters}
          defaultUserFilters={defaultUserFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          notActualInitialDate={notActualInitialDate}
        />
      </Stack>
      <MainPageGrid mt={2}>
        <SignUpsHeader sort={params.sort} onChangeSort={handleChangeSort} />
        <GridBody>
          {signups?.map((signup) => (
            <TableRow
              key={signup.id}
              component={Link}
              to={`/signups/${signup.id}`}
              state={{ backgroundLocation: location }}
              sx={(theme) => ({
                '&:hover td': {
                  '.MuiTypography-root': {
                    color: theme.palette.primary.main,
                  },

                  backgroundColor: theme.palette.background.default,
                },
              })}
            >
              <GridCell>
                <Typography
                  className="date"
                  color="common.grey"
                  sx={{
                    '.MuiTableRow-root:hover td.MuiTableCell-root &': { color: 'text.primary' },
                  }}
                >
                  {signup.end
                    ? `${$t({ id: 'till' })} ${format(
                        newDateTimezoneOffset(signup.end),
                        DATE_FORMAT_SHORT,
                      )}`.toUpperCase()
                    : '-'}
                </Typography>
              </GridCell>
              <GridCell>
                <TypographyWithOverflowHint variant="h3" color="common.grey2">
                  {signup.title}
                </TypographyWithOverflowHint>
              </GridCell>
              <GridCell>
                <Link to={`/events/${signup.event?.id}`}>
                  <TypographyWithOverflowHint
                    color="common.grey2"
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {signup.event?.title ?? '-'}
                  </TypographyWithOverflowHint>
                </Link>
              </GridCell>
              <GridCell>
                <Typography color="common.grey2">
                  <FormattedMessage id={getSignUpsTypeLabel(signup.type)} />
                </Typography>
              </GridCell>
              <GridCell>
                <Typography color="common.grey2">
                  {isSignUpWithEvent(signup) &&
                    (signup.event.invitee_type ? (
                      <FormattedMessage id={getParticipantLabel(signup.event.invitee_type)} />
                    ) : (
                      '-'
                    ))}

                  {isSignUpWithoutEvent(signup) &&
                    (signup.invitee_type ? (
                      <FormattedMessage id={getParticipantLabel(signup.invitee_type)} />
                    ) : (
                      '-'
                    ))}
                </Typography>
              </GridCell>
              <GridCell>
                <StatusCellContent status={signup.status} />
              </GridCell>
            </TableRow>
          ))}
          {isLoading && <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />}
          <SkeletonGridLoader
            isFetching={isLoading || isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            columnsCount={SKELETON_COLS}
            amount={Math.min(
              PAGE_SIZE,
              total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
            )}
          />
          =
        </GridBody>
      </MainPageGrid>
      {!isLoading && !signups.length && <MainGridNoResultsStub textId="eventSignUps-NoMatches" />}
    </>
  );
};
