import { Stack } from '@mui/material';
import { PropsWithChildren, useCallback } from 'react';

import { DropdownSelect, DropdownSelectProps } from './DropdownSelect';
import { renderTags } from './ExpandedSelectFilter';

export type DropdownSelectFilterProps<T> = PropsWithChildren<{
  selected: T[];
  onSelect: (v: T) => void;
  getLabel: (v: T) => string;
  options: T[];
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export function DropdownSelectFilter<T>({
  selected,
  onSelect,
  placeholder,
  getLabel,
  options,
  ...dropdownProps
}: DropdownSelectFilterProps<T>) {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderTags({
          items: options,
          tagProps: (item) => ({
            variant: selected.includes(item) ? 'filled' : undefined,
          }),
          onClick: onSelect,
          getLabel,
        })}
      </Stack>
    );
  }, [getLabel, onSelect, options, selected]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selected.length}
      renderContent={renderContent}
    >
      {(opened) => (
        <>
          {renderTags({
            items: selected,
            tagProps: { size: placeholder ? 'small' : undefined },
            onDelete: opened ? onSelect : undefined,
            getLabel,
          })}
        </>
      )}
    </DropdownSelect>
  );
}
