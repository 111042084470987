import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6288 3.70699L1.04289 9.29289C0.652369 9.68342 0.652369 10.3166 1.04289 10.7071L6.6288 16.293C7.01933 16.6835 7.65249 16.6835 8.04302 16.293L11.336 13.0001L13.2857 14.9498C13.6762 15.3403 14.3094 15.3403 14.6999 14.9498L18.9425 10.7071C19.3331 10.3166 19.3331 9.68342 18.9425 9.2929L14.6999 5.05026C14.3094 4.65974 13.6762 4.65974 13.2857 5.05026L11.336 6.99996L8.04301 3.70699C7.65249 3.31646 7.01932 3.31646 6.6288 3.70699ZM8.33594 10L11.336 6.99996L14.3359 10L11.336 13.0001L8.33594 10Z"
      fill="#24275B"
    />
  </svg>
);
