import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import AttendanceStubImg from '../../../../assets/images/attendance-stub.svg';
import AttendanceStubArrowTallImg from '../../../../assets/images/attendance-stub-arrow-tall.svg';
import {
  GroupAttendanceStatisticsEmptyStubStyled,
  GroupPreviewAttendanceEmptyStubArrow,
} from './GroupAttendanceStatisticsEmptyStub.styled';

export const GroupAttendanceStatisticsEmptyStub: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <GroupAttendanceStatisticsEmptyStubStyled>
      <img src={AttendanceStubImg} alt={formatMessage({ id: 'attendance-NoAttendanceYet' })} />
      <Typography variant="h3" color="text.primary" mt={2.5} width={323} textAlign="center">
        <FormattedMessage id="attendance-NoRegisterForPeriod" />
      </Typography>
      <GroupPreviewAttendanceEmptyStubArrow>
        <img src={AttendanceStubArrowTallImg} alt="arrow" />
      </GroupPreviewAttendanceEmptyStubArrow>
    </GroupAttendanceStatisticsEmptyStubStyled>
  );
};
