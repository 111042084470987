import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.53033 1.22385C8.23744 0.925384 7.76256 0.925384 7.46967 1.22385C7.17678 1.52231 7.17678 2.00621 7.46967 2.30468C7.95783 2.80211 7.95783 3.60862 7.46967 4.10606C7.17678 4.40452 7.17678 4.88842 7.46967 5.18689C7.76256 5.48535 8.23744 5.48535 8.53033 5.18689C9.60427 4.09252 9.60427 2.31821 8.53033 1.22385Z"
      fill="#B3BBCB"
    />
    <path
      d="M14.4793 11.2035C14.1812 11.4966 14.1727 11.9804 14.4602 12.2842C14.7478 12.5879 15.2226 12.5966 15.5207 12.3035C16.0176 11.8151 16.8089 11.8296 17.2882 12.3358C17.5758 12.6396 18.0506 12.6483 18.3487 12.3552C18.6468 12.0622 18.6553 11.5783 18.3677 11.2746C17.3132 10.1608 15.5723 10.1289 14.4793 11.2035Z"
      fill="#B3BBCB"
    />
    <path
      d="M12 1.67681C12 1.95821 11.7761 2.18632 11.5 2.18632C11.2239 2.18632 11 1.95821 11 1.67681C11 1.39542 11.2239 1.16731 11.5 1.16731C11.7761 1.16731 12 1.39542 12 1.67681Z"
      fill="#B3BBCB"
    />
    <path
      d="M19 7.79091C19 8.0723 18.7761 8.30041 18.5 8.30041C18.2239 8.30041 18 8.0723 18 7.79091C18 7.50951 18.2239 7.2814 18.5 7.2814C18.7761 7.2814 19 7.50951 19 7.79091Z"
      fill="#B3BBCB"
    />
    <path
      d="M16.5 14.4145C16.7761 14.4145 17 14.1864 17 13.905C17 13.6236 16.7761 13.3955 16.5 13.3955C16.2239 13.3955 16 13.6236 16 13.905C16 14.1864 16.2239 14.4145 16.5 14.4145Z"
      fill="#B3BBCB"
    />
    <path
      d="M6 3.71484C6 3.99624 5.77614 4.22435 5.5 4.22435C5.22386 4.22435 5 3.99624 5 3.71484C5 3.43345 5.22386 3.20534 5.5 3.20534C5.77614 3.20534 6 3.43345 6 3.71484Z"
      fill="#B3BBCB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4801 2.36651C16.1872 2.06804 15.7123 2.06804 15.4194 2.36651C15.1265 2.66497 15.1265 3.14887 15.4194 3.44734C15.5703 3.60104 15.7587 3.89419 15.8105 4.21863C15.8389 4.39587 15.8317 4.60409 15.7199 4.83592C15.55 4.39543 15.2936 4.03978 15.0659 3.80771C14.8632 3.60113 14.5144 3.36077 14.0727 3.28749C13.5844 3.2065 13.0467 3.34329 12.591 3.80764C12.1353 4.272 12.001 4.81996 12.0805 5.3175C12.1524 5.76765 12.3883 6.12301 12.591 6.32958C12.8188 6.56168 13.1679 6.82292 13.6002 6.99608C13.3727 7.10998 13.1683 7.11733 12.9944 7.08847C12.676 7.03565 12.3883 6.84362 12.2375 6.68992C11.9446 6.39146 11.4697 6.39146 11.1768 6.68992C10.8839 6.98838 10.8839 7.47228 11.1768 7.77075C11.4974 8.09741 12.0582 8.48182 12.7534 8.59713C13.495 8.72016 14.3274 8.52332 15.0659 7.77075C15.3302 7.50143 15.5247 7.21987 15.6599 6.93468C15.9397 6.7969 16.2159 6.59879 16.4801 6.32955C17.2186 5.57697 17.4118 4.72878 17.2911 3.97304C17.1779 3.26469 16.8007 2.69317 16.4801 2.36651ZM13.9304 4.83375C13.9683 4.85518 13.9945 4.87778 14.0046 4.88782C14.153 5.03914 14.3387 5.32631 14.3939 5.64473C14.0813 5.58854 13.8002 5.40005 13.6517 5.24872C13.6422 5.23903 13.6194 5.212 13.5979 5.17257C13.577 5.1341 13.5654 5.09904 13.561 5.07191C13.5572 5.04815 13.5589 5.03143 13.564 5.01515C13.5689 4.99953 13.5859 4.95543 13.6516 4.88847C13.7173 4.82151 13.7606 4.80412 13.776 4.79913C13.7919 4.79393 13.8083 4.79229 13.8317 4.79615C13.8583 4.80057 13.8927 4.81238 13.9304 4.83375Z"
      fill="#B3BBCB"
    />
    <path
      d="M13.5377 13.6271C13.5421 13.6257 13.544 13.6248 13.5438 13.6246C13.5438 13.6245 13.5429 13.6247 13.5414 13.6254C13.5405 13.6257 13.5392 13.6263 13.5377 13.6271Z"
      fill="#B3BBCB"
    />
    <path
      d="M13.4567 13.6383C13.4565 13.6112 13.4546 13.577 13.4498 13.5348C13.4232 13.2973 13.3157 12.9399 13.0834 12.4755C12.623 11.5553 11.774 10.4162 10.6275 9.31215C9.48096 8.20812 8.31689 7.40876 7.38923 6.99238C6.92114 6.78228 6.56487 6.69312 6.33067 6.67946C6.28902 6.67703 6.25549 6.67706 6.22891 6.67831C6.22918 6.70542 6.23106 6.73954 6.23579 6.78177C6.26241 7.01927 6.36991 7.37669 6.60223 7.84105C7.06264 8.7613 7.91164 9.90041 9.05816 11.0044C10.2047 12.1085 11.3687 12.9078 12.2964 13.3242C12.7645 13.5343 13.1208 13.6235 13.355 13.6371C13.3966 13.6396 13.4301 13.6395 13.4567 13.6383Z"
      fill="#B3BBCB"
    />
    <path
      d="M8.37133 11.7451C10.0503 13.3618 11.8268 14.4221 13.0265 14.6232L1 19L5.29238 7.19019C5.56472 8.39866 6.70091 10.1366 8.37133 11.7451Z"
      fill="#B3BBCB"
    />
  </svg>
);
