import { FC, HTMLAttributes } from 'react';

export const LogoSvg: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="112"
    height="28"
    viewBox="0 0 112 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.90383 21.2803C7.09316 21.2803 5.5345 20.9536 4.22783 20.3003C2.93983 19.6283 1.83849 18.6669 0.923828 17.4163L3.47183 14.5323C4.38649 15.8016 5.29183 16.6696 6.18783 17.1363C7.08383 17.5843 8.07316 17.8083 9.15583 17.8083C10.0332 17.8083 10.7705 17.6216 11.3678 17.2483C11.9838 16.8749 12.2918 16.3709 12.2918 15.7363C12.2918 14.9709 11.8998 14.3736 11.1158 13.9443C10.3505 13.4963 9.36116 13.1509 8.14783 12.9083C6.0385 12.4603 4.47049 11.7416 3.44383 10.7523C2.41716 9.76294 1.90383 8.44694 1.90383 6.80427C1.90383 5.66561 2.23049 4.66694 2.88383 3.80827C3.53716 2.93094 4.39583 2.24961 5.45983 1.76427C6.5425 1.27894 7.73716 1.03627 9.04383 1.03627C10.7798 1.03627 12.2172 1.33494 13.3558 1.93227C14.5132 2.51094 15.4278 3.34161 16.0998 4.42427L13.4958 6.97227C12.3385 5.32961 10.8638 4.50827 9.07183 4.50827C8.1385 4.50827 7.38249 4.70427 6.80383 5.09627C6.24383 5.46961 5.96383 5.98294 5.96383 6.63627C5.96383 7.32694 6.2905 7.86827 6.94383 8.26027C7.61583 8.65227 8.51183 8.96027 9.63183 9.18427C11.6852 9.61361 13.3092 10.3043 14.5038 11.2563C15.7172 12.1896 16.3238 13.4496 16.3238 15.0363C16.3238 16.3429 16.0065 17.4629 15.3718 18.3963C14.7558 19.3296 13.8878 20.0483 12.7678 20.5523C11.6665 21.0376 10.3785 21.2803 8.90383 21.2803Z"
      fill="#24275B"
    />
    <path
      d="M25.4765 21.2803C24.0765 21.2803 22.8165 20.9443 21.6965 20.2723C20.5952 19.6003 19.7179 18.6856 19.0645 17.5283C18.4299 16.3709 18.1125 15.0549 18.1125 13.5803C18.1125 12.1243 18.4299 10.8176 19.0645 9.66027C19.7179 8.50294 20.5952 7.58827 21.6965 6.91627C22.8165 6.24427 24.0765 5.90827 25.4765 5.90827C26.8019 5.90827 28.0059 6.15094 29.0885 6.63627C30.1899 7.12161 31.0485 7.80294 31.6645 8.68027L29.5645 11.2003C29.1725 10.6589 28.6312 10.2016 27.9405 9.82827C27.2499 9.43627 26.5499 9.24027 25.8405 9.24027C25.0565 9.24027 24.3565 9.43627 23.7405 9.82827C23.1432 10.2016 22.6672 10.7149 22.3125 11.3683C21.9765 12.0216 21.8085 12.7589 21.8085 13.5803C21.8085 14.4016 21.9859 15.1389 22.3405 15.7923C22.6952 16.4456 23.1805 16.9683 23.7965 17.3603C24.4125 17.7336 25.1032 17.9203 25.8685 17.9203C26.5965 17.9203 27.2685 17.7616 27.8845 17.4443C28.5005 17.1083 29.0605 16.6416 29.5645 16.0443L31.6645 18.5643C31.0299 19.3856 30.1525 20.0483 29.0325 20.5523C27.9125 21.0376 26.7272 21.2803 25.4765 21.2803Z"
      fill="#24275B"
    />
    <path
      d="M33.653 21.0003V0.280273H37.433V8.14827C37.937 7.49494 38.5996 6.96294 39.421 6.55227C40.261 6.12294 41.1383 5.90827 42.053 5.90827C45.4503 5.90827 47.149 7.92427 47.149 11.9563V21.0003H43.341V12.2363C43.341 10.1269 42.4543 9.10961 40.681 9.18427C39.7663 9.18427 39.001 9.46427 38.385 10.0243C37.7877 10.5843 37.489 11.2749 37.489 12.0963V21.0003H33.653Z"
      fill="#24275B"
    />
    <path
      d="M57.2616 21.2803C55.7496 21.2803 54.4056 20.9536 53.2296 20.3003C52.0536 19.6283 51.1296 18.7136 50.4576 17.5563C49.7856 16.3989 49.4496 15.0736 49.4496 13.5803C49.4496 12.1056 49.7856 10.7896 50.4576 9.63227C51.1296 8.47494 52.0536 7.56961 53.2296 6.91627C54.4056 6.24427 55.7496 5.90827 57.2616 5.90827C58.7362 5.90827 60.0616 6.24427 61.2376 6.91627C62.4136 7.56961 63.3376 8.47494 64.0096 9.63227C64.6816 10.7896 65.0176 12.1056 65.0176 13.5803C65.0176 15.0736 64.6816 16.3989 64.0096 17.5563C63.3376 18.7136 62.4136 19.6283 61.2376 20.3003C60.0616 20.9536 58.7362 21.2803 57.2616 21.2803ZM57.2616 17.9483C58.0082 17.9483 58.6802 17.7616 59.2776 17.3883C59.8749 16.9963 60.3322 16.4736 60.6496 15.8203C60.9856 15.1669 61.1536 14.4203 61.1536 13.5803C61.1536 12.7403 60.9856 11.9936 60.6496 11.3403C60.3322 10.6869 59.8749 10.1736 59.2776 9.80027C58.6802 9.42694 58.0082 9.24027 57.2616 9.24027C56.4962 9.24027 55.8149 9.42694 55.2176 9.80027C54.6202 10.1736 54.1536 10.6869 53.8176 11.3403C53.4816 11.9936 53.3136 12.7403 53.3136 13.5803C53.3136 14.4203 53.4816 15.1669 53.8176 15.8203C54.1536 16.4736 54.6202 16.9963 55.2176 17.3883C55.8149 17.7616 56.4962 17.9483 57.2616 17.9483Z"
      fill="#24275B"
    />
    <path
      d="M74.3383 21.2803C72.8263 21.2803 71.4823 20.9536 70.3063 20.3003C69.1303 19.6283 68.2063 18.7136 67.5343 17.5563C66.8623 16.3989 66.5263 15.0736 66.5263 13.5803C66.5263 12.1056 66.8623 10.7896 67.5343 9.63227C68.2063 8.47494 69.1303 7.56961 70.3063 6.91627C71.4823 6.24427 72.8263 5.90827 74.3383 5.90827C75.813 5.90827 77.1383 6.24427 78.3143 6.91627C79.4903 7.56961 80.4143 8.47494 81.0863 9.63227C81.7583 10.7896 82.0943 12.1056 82.0943 13.5803C82.0943 15.0736 81.7583 16.3989 81.0863 17.5563C80.4143 18.7136 79.4903 19.6283 78.3143 20.3003C77.1383 20.9536 75.813 21.2803 74.3383 21.2803ZM74.3383 17.9483C75.085 17.9483 75.757 17.7616 76.3543 17.3883C76.9516 16.9963 77.409 16.4736 77.7263 15.8203C78.0623 15.1669 78.2303 14.4203 78.2303 13.5803C78.2303 12.7403 78.0623 11.9936 77.7263 11.3403C77.409 10.6869 76.9516 10.1736 76.3543 9.80027C75.757 9.42694 75.085 9.24027 74.3383 9.24027C73.573 9.24027 72.8916 9.42694 72.2943 9.80027C71.697 10.1736 71.2303 10.6869 70.8943 11.3403C70.5583 11.9936 70.3903 12.7403 70.3903 13.5803C70.3903 14.4203 70.5583 15.1669 70.8943 15.8203C71.2303 16.4736 71.697 16.9963 72.2943 17.3883C72.8916 17.7616 73.573 17.9483 74.3383 17.9483Z"
      fill="#24275B"
    />
    <path d="M84.611 21.0003V0.280273H88.419V21.0003H84.611Z" fill="#24275B" />
    <path
      d="M93.6152 27.4403L96.3592 21.0843L89.6392 6.21627H93.9792L97.7312 15.0643C97.8992 15.4563 98.0672 15.9136 98.2352 16.4363C98.3099 16.2309 98.3752 16.0349 98.4312 15.8483C98.5059 15.6429 98.5805 15.4283 98.6552 15.2043L101.875 6.21627H106.271L100.055 21.0003L97.5072 27.4403H93.6152Z"
      fill="#24275B"
    />
    <circle cx="109" cy="18" r="3" fill="#68DF83" />
  </svg>
);
