import { Stack, Typography } from '@mui/material';
import { UserRoleList, UserType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import { Counter } from '../../components/uikit/Counter/Counter.styled';
import { useFilters } from '../../context/filters/useFilters';
import { filterUserRole } from '../../helpers/userRoles';
import useAppLocation from '../../hooks/useAppLocation';
import UserRoleCard from './UserRoleCard';

export interface UserRoleCategoryListProps {
  userRoleList: UserRoleList;
  userType: UserType;
}

const UserRoleCategoryList: React.FC<UserRoleCategoryListProps> = ({ userRoleList, userType }) => {
  const { permissions } = useAuth();

  const isUserRoleManager = permissions.includes('userrole_manager');

  const isUserRoleViewer = !isUserRoleManager && permissions.includes('userrole_viewer');

  const location = useAppLocation();
  const { filters } = useFilters(location.pathname);

  const filteredUserRoleList = useMemo(() => {
    const roleList = (Object.keys(userRoleList) as Array<keyof UserRoleList>)?.reduce(
      (acc, key) => {
        return {
          ...acc,
          [key]: [...userRoleList[key]].sort((a, b) => a.name.localeCompare(b.name)),
        };
      },
      {} as UserRoleList,
    );

    if (!filters.appliedQuery) {
      return roleList;
    }

    const filteredList = (Object.keys(roleList) as Array<keyof UserRoleList>).reduce<UserRoleList>(
      (acc, key) => {
        return {
          ...acc,
          [key]: roleList[key].filter((role) => filterUserRole(role.name, filters.appliedQuery!)),
        };
      },
      {} as UserRoleList,
    );

    return filteredList;
  }, [filters.appliedQuery, userRoleList]);

  const emptyList =
    !filteredUserRoleList.available_to_assign.length &&
    !filteredUserRoleList.not_allowed_to_assign.length;

  const emptySearchResult = useMemo(() => {
    if (!!filters.appliedQuery && emptyList) {
      return (
        <NoListItemsStub
          type="student"
          titleText={<FormattedMessage id="filter-NoResults" />}
          subTitleText={<FormattedMessage id="userRoles-NoMatches" />}
        />
      );
    }
  }, [emptyList, filters.appliedQuery]);

  return (
    <Stack flex={1} gap={3}>
      {emptySearchResult}
      {!!filteredUserRoleList.available_to_assign.length && (
        <Stack gap={1}>
          {isUserRoleViewer && (
            <Stack direction="row" alignItems="center">
              <Typography variant="h2">
                <FormattedMessage id="userRoles-AvailableToAssign" />
              </Typography>
              <Counter>{filteredUserRoleList.available_to_assign.length}</Counter>
            </Stack>
          )}
          <Stack direction="row" flexWrap="wrap" gap={2}>
            {filteredUserRoleList.available_to_assign.map((role) => (
              <UserRoleCard key={role.id} role={role} userType={userType} />
            ))}
          </Stack>
        </Stack>
      )}

      {!!filteredUserRoleList.not_allowed_to_assign.length && isUserRoleViewer && (
        <Stack gap={1}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h2">
              <FormattedMessage id="userRoles-NotAllowedToAssign" />
            </Typography>
            <Counter>{filteredUserRoleList.not_allowed_to_assign.length}</Counter>
          </Stack>
          <Stack direction="row" flexWrap="wrap" gap={2}>
            {filteredUserRoleList.not_allowed_to_assign.map((role) => (
              <UserRoleCard key={role.id} role={role} userType={userType} disabled={true} />
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default UserRoleCategoryList;
