import { Avatar, Divider, Icon, MenuItem, Skeleton, Stack } from '@mui/material';
import { School, SyncUserSchoolRelation } from '@schooly/api';
import { ArrowSchoolSelectIcon, CheckIcon } from '@schooly/style';
import React, { FC, useCallback, useState } from 'react';

import { IS_DEMO } from '../../../../config';
import { CreateNewSchool } from './CreateNewSchool';
import { SchoolSelectMenu, SchoolSelectStyled } from './SchoolSelect.styled';
import { SchoolSelectItem } from './SchoolSelectItem';

type SchoolSelectProps = {
  currentSchool?: School;
  schools: SyncUserSchoolRelation[];
  isLoading: boolean;
  onChangeSchool: (id: string) => void;
};

export const SchoolSelect: FC<SchoolSelectProps> = ({
  onChangeSchool,
  isLoading,
  currentSchool,
  schools,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMainClick = useCallback<React.MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuItemClick = useCallback(
    (school: School) => async () => {
      handleClose();
      onChangeSchool(school.id);
    },
    [handleClose, onChangeSchool],
  );

  if (!schools?.length) {
    return (
      <Stack justifyContent="center" alignItems="center" height={40}>
        <CreateNewSchool />
      </Stack>
    );
  }

  if (!currentSchool || isLoading) {
    return (
      <Stack direction="row" gap={1} sx={{ height: (theme) => theme.spacing(5.5) }}>
        <Skeleton width={64} variant="circular">
          <Avatar />
        </Skeleton>
        <Skeleton variant="rectangular" />
      </Stack>
    );
  }

  return (
    <>
      {currentSchool ? (
        <SchoolSelectStyled data-test-id="school-select" onClick={handleMainClick}>
          <SchoolSelectItem school={currentSchool} />

          <Icon sx={{ display: 'flex', color: (theme) => theme.palette.text.secondary }}>
            <ArrowSchoolSelectIcon />
          </Icon>
        </SchoolSelectStyled>
      ) : (
        <CreateNewSchool />
      )}

      {currentSchool && (
        <SchoolSelectMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={handleClose}>
            <SchoolSelectStyled>
              <SchoolSelectItem school={currentSchool} />

              <Icon
                sx={{ display: 'flex', '&&&': { color: (theme) => theme.palette.primary.main } }}
              >
                <ArrowSchoolSelectIcon />
              </Icon>
            </SchoolSelectStyled>
          </MenuItem>

          {schools.map((school_relation) => {
            const isCurrentSchool = school_relation.school.id === currentSchool?.id;

            return (
              <MenuItem
                key={school_relation.school.id}
                onClick={handleMenuItemClick(school_relation.school)}
                sx={{ gap: 1 }}
              >
                <SchoolSelectItem
                  school={school_relation.school}
                  color={isCurrentSchool ? 'primary.main' : undefined}
                  subitem
                />

                {isCurrentSchool && (
                  <Icon
                    sx={{
                      display: 'flex',
                      alignSelf: 'flex-start',
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <CheckIcon />
                  </Icon>
                )}
              </MenuItem>
            );
          })}
          {!IS_DEMO && <Divider />}
          {!IS_DEMO && (
            <MenuItem>
              <CreateNewSchool menu />
            </MenuItem>
          )}
        </SchoolSelectMenu>
      )}
    </>
  );
};
