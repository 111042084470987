import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import {
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { SelectedCriteriaList } from '../../../../components/uikit-components/SelectedCriteriaList/SelectedCriteriaList.styled';
import { useMessage } from '../../../../context/messages/useMessage';
import { getDisplayedCriteria } from '../../helpers';

export const MessagesModalPreviewSendToParents: FC = () => {
  const { parents, parentsCriteria, parentsByIndividualStudent } = useMessage();

  return (
    <>
      {Boolean(parentsCriteria?.length || parentsByIndividualStudent?.length) && (
        <SelectedCriteriaList mb={2.5}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            <FormattedMessage id="messages-ParentsOfStudents" />
          </Typography>

          {
            /* Parents criteria */
            getDisplayedCriteria({ selectedCriteria: parentsCriteria })
          }

          {
            /* Parents by individual students */
            Boolean(parentsByIndividualStudent?.length) && (
              <Box>
                {parentsByIndividualStudent.map((student) => (
                  <GridRowStyled key={student.relation_id}>
                    <GridRowItem noVerticalPadding>
                      <GridRowName>
                        <PersonCardBasic
                          user={student}
                          userType="student"
                          isListItem
                          isUsernameClickable
                        />
                      </GridRowName>
                    </GridRowItem>
                  </GridRowStyled>
                ))}
              </Box>
            )
          }
        </SelectedCriteriaList>
      )}

      {
        /* Individual parents */
        Boolean(parents?.length) && (
          <Box>
            <Typography variant="h4" sx={{ mb: 1 }}>
              <FormattedMessage id="messages-Parents" />
            </Typography>
            <Box>
              {parents.map((parent) => (
                <GridRowStyled key={parent.relation_id}>
                  <GridRowItem noVerticalPadding>
                    <GridRowName>
                      <PersonCardBasic
                        user={parent}
                        userType="parent"
                        isListItem
                        isUsernameClickable
                      />
                    </GridRowName>
                  </GridRowItem>
                </GridRowStyled>
              ))}
            </Box>
          </Box>
        )
      }
    </>
  );
};
