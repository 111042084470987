import { FilterValue, SelectOption } from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { DoneIcon } from '@schooly/style';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { noop } from '../../../helpers/misc';
import { getUserFullName, getUserInitials } from '../../../helpers/users';
import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';

import './SelectPeopleRow.scss';

export interface SelectPeopleRowProps {
  user?: SimpleListResult;
  isSelected?: boolean;
  isMe?: boolean;
  onClick: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    selectOption?: SelectOption<FilterValue>,
  ) => void;
  selectOption?: SelectOption;
}

const $class = `${$UIKitPrefix}SelectPeopleRow`;

export const SelectPeopleRow: React.FC<SelectPeopleRowProps> = ({
  user,
  isSelected,
  isMe,
  selectOption,
  onClick = noop,
}) => {
  const { formatMessage } = useIntl();
  const { profile_picture } = user || {};

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      if (onClick) {
        onClick(e, selectOption);
      }
    },
    [onClick, selectOption],
  );

  if (!user) return null;

  const name = getUserFullName(user);

  const initials = getUserInitials(user);

  return (
    <button
      onClick={handleClick}
      className={buildClassName(
        $class,
        'reset-button-styles',
        isSelected && `${$class}_selected`,
        isMe && `${$class}_me`,
      )}
    >
      {profile_picture ? (
        <img className={`${$class}__avatar`} src={profile_picture} alt={name} />
      ) : (
        <span className={`${$class}__initials`}>{initials}</span>
      )}
      <p className={`${$class}__name`}>{isMe ? formatMessage({ id: 'filter-Me' }) : name}</p>
      {isSelected && <DoneIcon className={`${$class}__done-icon`} />}
    </button>
  );
};
