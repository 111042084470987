import { CompanySave } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Loading, ModalSmall } from '@schooly/style';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';

import AccessDenied from '../../../components/common/AccessDenied';
import { useCompany } from '../../../context/companies/WithCompany';
import { useRouter } from '../../../context/router/useRouter';
import { CompanyCreateModalContent } from './CompanyCreateModalContent';

export const CompanyCreateModal: FC = () => {
  const { goBack } = useRouter();
  const navigate = useNavigate();
  const { schoolId = '' } = useAuth();
  const {
    company,
    id,
    canCreateAndEdit,
    isFetching,
    create,
    update,
    remove,
    isRemoving,
    isSaving,
  } = useCompany();

  const { handleDelete, handleSave } = useMemo(() => {
    return {
      handleDelete: id ? () => remove(id).then(() => navigate('/companies')) : undefined,
      handleSave: (company: CompanySave) => (id ? update : create)(company),
    };
  }, [create, id, navigate, remove, update]);

  if (isFetching) {
    return (
      <ModalSmall open onClose={goBack}>
        <Loading />
      </ModalSmall>
    );
  }

  if (!canCreateAndEdit) {
    return (
      <ModalSmall open onClose={goBack}>
        <AccessDenied />
      </ModalSmall>
    );
  }

  return (
    <CompanyCreateModalContent
      onClose={goBack}
      schoolId={schoolId}
      company={company}
      onSave={handleSave}
      onDelete={handleDelete}
      isDeleting={isRemoving}
      isSaving={isSaving}
    />
  );
};
