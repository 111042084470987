import { Stack, styled, TextField } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { SimpleButton, SimpleButtonProps } from '../../uikit/SimpleButton/SimpleButton';
import { ModalPanel } from './Modal.styled';

export interface ModalHeaderStyledProps {
  withGoBack?: boolean;
}
// Create a copy before making changes, cause ModalHeaderStyled is used in ModalHeaderV2
export const ModalHeaderStyled = styled(ModalPanel, {
  shouldForwardProp: (prop) => prop !== 'withGoBack',
})<ModalHeaderStyledProps>(({ theme, withGoBack }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  overflow: 'unset',

  '& .ModalTitle': {
    flex: '1 1 auto',
    lineHeight: theme.spacing(4),

    '& .MuiInput-root': {
      '& .MuiInput-input': {
        [theme.breakpoints.down('lg')]: {
          height: 'unset',
        },
      },
    },
  },

  ...(withGoBack
    ? {
        paddingTop: theme.spacing(4.5),
        paddingBottom: theme.spacing(2),

        '& .ModalTitle': {
          flex: '1 1 auto',
          lineHeight: theme.spacing(5),
        },

        '& .ModalHeaderActions': {
          minHeight: 'unset',
        },
      }
    : {}),
}));

ModalHeaderStyled.defaultProps = {
  withBorderBottom: true,
};

export const ModalHeaderActions = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  minHeight: theme.spacing(4), // as .ModalTitle lineHeight

  '& .MuiIconButton-root': {
    color: theme.palette.common.grey,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

ModalHeaderActions.defaultProps = {
  className: 'ModalHeaderActions',
};

export const ModalHeaderBackLink = styled(Link)(({ theme }) => ({
  top: theme.spacing(2),
}));

ModalHeaderBackLink.defaultProps = {
  className: 'ModalHeaderBackLink',
};

export const ModalHeaderInput = styled(TextField)(({ theme }) => ({
  textWrap: 'wrap',
}));

ModalHeaderInput.defaultProps = {
  variant: 'standard',
  fullWidth: true,
};

export const ModalHeaderTitle: FC<SimpleButtonProps> = (props) => (
  <SimpleButton
    sx={(theme) => ({ ...theme.typography.h1, margin: theme.spacing(-0.5, 0) })}
    {...props}
  />
);
