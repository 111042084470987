import { SimpleListResult } from './endpoints/people';

export enum UserRolePermissions {
  StaffBase = 'staff_base',
  StudentViewer = 'student_viewer',
  ParentViewer = 'parent_viewer',
  StaffViewer = 'staff_viewer',
  GroupViewer = 'group_viewer',
  MessageViewer = 'message_viewer',
  AssessmentViewer = 'assessment_viewer',
  AttendanceViewer = 'attendance_viewer',
  ConductViewer = 'conduct_viewer',
  UserroleViewer = 'userrole_viewer',
  MessageCreator = 'message_creator',
  AssessmentCreator = 'assessment_creator',
  AttendanceCreator = 'attendance_creator',
  ParentManager = 'parent_manager',
  StudentManager = 'student_manager',
  RegistrationManager = 'registration_manager',
  StaffManager = 'staff_manager',
  EmploymentManager = 'employment_manager',
  GroupManager = 'group_manager',
  MessagePublisher = 'message_publisher',
  AssessmentManager = 'assessment_manager',
  AttendanceManager = 'attendance_manager',
  ConductManager = 'conduct_manager',
  UserroleManager = 'userrole_manager',
  CustomFieldManager = 'custom_field_manager',
  CustomFieldViewer = 'custom_field_viewer',
  ProfileViewer = 'profile_viewer',
  ProfileManager = 'profile_manager',
  SchoolAdmin = 'school_admin',
  ApplicationViewer = 'application_viewer',
  ApplicationManager = 'application_manager',
  EventViewer = 'event_viewer',
  EventCreator = 'event_creator',
  EventManager = 'event_manager',
  PlannerViewer = 'planner_viewer',
  ProductAndInvoiceViewer = 'product_and_invoice_viewer',
  ProductAndInvoiceCreator = 'product_and_invoice_creator',
  ProductAndInvoiceManager = 'product_and_invoice_manager',
  PayerAndProductAssignmentViewer = 'payer_and_product_assignment_viewer',
  PayerAndProductAssignmentCreator = 'payer_and_product_assignment_creator',
  PayerAndProductAssignmentManager = 'payer_and_product_assignment_manager',
}

export type UserRolePermission = `${UserRolePermissions}`;

export interface UserRole {
  editable: boolean;
  description: string | null;
  name: string;
  id: string;
  member_count: number;
  member_type: number;
  is_current: boolean;
  school_property_ids: string[];
}
export interface UserRolePopulated extends UserRole {
  permission_to_assign: null | string[];
  permissions: string[];
  staff: SimpleListResult[];
}

export interface UserRoleList {
  available_to_assign: UserRole[];
  not_allowed_to_assign: UserRole[];
}

export enum UserRoleCategories {
  AnnualPlanner,
  Applications,
  StudentsAndParents,
  Registrations,
  Staff,
  EmploymentCases,
  Groups,
  Messages,
  AssessmentsAndReports,
  Attendance,
  Conduct,
  Events,
  UserRoles,
  CustomFields,
  SchoolSettings,
  ProductsAndInvoices,
  PayersAndProductsAssignment,
}

export type UserRoleCategory = keyof typeof UserRoleCategories;

export enum UserRoleAccessTypes {
  NoAccess,
  Viewer,
  Creator,
  Manager,
}

export type UserRoleAccessType = keyof typeof UserRoleAccessTypes;

type UserRoleBaseRequest = Omit<
  UserRole,
  'member_count' | 'id' | 'staff' | 'editable' | 'member_type' | 'is_current' | 'user_type'
> & { relation_ids: string[] };

export interface UserRoleCreateRequest extends UserRoleBaseRequest {
  school_id: string;
}
export interface UserRoleUpdateRequest extends Partial<UserRoleBaseRequest> {
  relation_ids: string[];
  id: string;
}
export type UserRoleUpdate = Omit<UserRole, 'id' | 'editable' | 'user_count'>;

export interface UserRolePermissionsDateRange {
  start?: string;
  end?: string;
}
