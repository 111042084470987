import { ChipProps } from '@mui/material';
import { DATE_FORMAT } from '@schooly/constants';
import { TagSelect } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, isToday } from 'date-fns';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type DateSelectProps = {
  dateFormat?: string;
  date: string;
} & Omit<ChipProps, 'label'>;

export const DateTagSelect: FC<DateSelectProps> = ({
  date: stringDate,
  dateFormat = DATE_FORMAT,
  ...props
}) => {
  const date = newDateTimezoneOffset(stringDate);

  return (
    <TagSelect
      label={isToday(date) ? <FormattedMessage id="datepicker-Today" /> : format(date, dateFormat)}
      {...props}
    />
  );
};
