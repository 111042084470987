import {
  ApiError,
  CustomField,
  CustomFieldValue,
  useSetCustomFieldValueForRelationMutation,
} from '@schooly/api';
import { ControlBoolean } from '@schooly/components/form-boolean';
import { useNotifications } from '@schooly/components/notifications';
import React, { FC, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form-lts';

import { useProfile } from '../../../../context/profile/useProfile';
import IntlError from '../../../../utils/intlError';
import { PropertyModal2 } from '../PropertyModal2';

export interface CustomFieldBooleanModalProps {
  onClose: () => void;
  isOpen: boolean;
  field: CustomField;
  value?: CustomFieldValue;
}

interface CustomFieldFormBooleanData {
  value?: boolean;
}

export const CustomFieldBooleanModal: FC<CustomFieldBooleanModalProps> = ({
  isOpen,
  onClose,
  field,
  value,
}) => {
  const { showError } = useNotifications();

  const defaultValue = typeof value?.value === 'boolean' ? value?.value : undefined;

  const { user, schoolMembership } = useProfile();
  const setCustomFieldValueForRelation = useSetCustomFieldValueForRelationMutation();

  const form = useForm<CustomFieldFormBooleanData>({
    mode: 'onChange',
    defaultValues: {
      value: defaultValue,
    },
  });

  const {
    reset,
    formState: { isValid },
    watch,
  } = form;

  const handleSubmit: SubmitHandler<CustomFieldFormBooleanData> = useCallback(
    async ({ value }) => {
      if (!schoolMembership) {
        return;
      }

      try {
        const res = await setCustomFieldValueForRelation.mutateAsync({
          relationId: schoolMembership.relation_id,
          field,
          value,
        });

        if (!res) return;
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        onClose();
      }
    },
    [field, onClose, schoolMembership, showError, setCustomFieldValueForRelation],
  );

  useEffect(() => {
    reset({ value: defaultValue });
  }, [reset, defaultValue, isOpen]);

  const formValue = watch('value');
  const disabled = formValue === undefined;

  return (
    <PropertyModal2
      isOpen={isOpen}
      user={user}
      submitDisabled={!isValid || disabled}
      isUpdating={setCustomFieldValueForRelation.isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <ControlBoolean
        name="value"
        label={field.label}
        required={field.required}
        disabled={disabled}
      />
    </PropertyModal2>
  );
};
