import { Stack } from '@mui/material';
import { AssessmentMethodType } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { renderAssessmentMethodTypeTags } from './AssessmentMethodTypeExpandedSelect';

type AssessmentMethodTypeSelectMultipleProps = PropsWithChildren<{
  selectedTypes: AssessmentMethodType[];
  onSelectType: (v: AssessmentMethodType) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

const OPTIONS = [
  AssessmentMethodType.Comment,
  AssessmentMethodType.Grade,
  AssessmentMethodType.Score,
];

export const AssessmentMethodTypeSelectMultiple: FC<AssessmentMethodTypeSelectMultipleProps> = ({
  selectedTypes,
  onSelectType,
  placeholder,
  ...dropdownProps
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderAssessmentMethodTypeTags({
          types: OPTIONS,
          tagProps: (status) => ({
            variant: selectedTypes.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectType,
        })}
      </Stack>
    );
  }, [onSelectType, selectedTypes]);

  return (
    <DropdownSelect
      {...dropdownProps}
      placeholder={placeholder}
      hasValues={!!selectedTypes.length}
      renderContent={renderContent}
    >
      {(opened) => (
        <>
          {renderAssessmentMethodTypeTags({
            types: selectedTypes,
            tagProps: { size: placeholder ? 'small' : undefined },
            onDelete: opened ? onSelectType : undefined,
          })}
        </>
      )}
    </DropdownSelect>
  );
};
