import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { MessagesCreateModal } from './MessagesCreateModal/MessagesCreateModal';
import { MessagesPreviewModal } from './MessagesPreviewModal/MessagesPreviewModal';

export const MessageRoute: FC = () => {
  const { id } = useParams<'id'>();
  return id === 'new' ? <MessagesCreateModal /> : <MessagesPreviewModal />;
};
