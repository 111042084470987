import { ApplicationCustomField, ApplicationDefaultCustomFields } from '@schooly/api';

import { CreateCustomField } from './CreateCustomField.type';

export const getCustomFieldsToShow = <T extends ApplicationCustomField | CreateCustomField>(
  fields: T[],
): T[] => {
  return fields.filter(
    (field) =>
      !!field.value ||
      Object.values(ApplicationDefaultCustomFields).includes(
        field.label as ApplicationDefaultCustomFields,
      ),
  );
};
