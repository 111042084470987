import { Icon, Skeleton, Stack } from '@mui/material';
import {
  FilterSection,
  GetSignUpsListQueryFilters,
  SIGN_UPS_FILTER_KEYS,
  useGetFiltersQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SkeletonRows,
} from '@schooly/style';
import React, { FC, useMemo } from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { EventsAndSignUpsPageHeader } from '../EventsAndSignUpsPageHeader/EventsAndSignUpsPageHeader';
import { PAGE_SIZE, SignUpsContent, SKELETON_COLS } from './SignUpsContent';

export const SignUpsPage: FC = () => {
  const { schoolId = '', relationId = '' } = useAuth();

  const { data: filters, isLoading } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.SignUps },
    { enabled: !!schoolId && !!relationId },
  );

  const initialFilters = useMemo(() => {
    const initialFilters: GetSignUpsListQueryFilters = {};

    if (isLoading) {
      return;
    }

    const defaultFilter = filters?.find((f) => f.is_default);

    return defaultFilter
      ? pickOnlyParamsFromFilterKeys(SIGN_UPS_FILTER_KEYS, defaultFilter.filter)
      : initialFilters;
  }, [filters, isLoading]);

  const renderContent = () => {
    if (!initialFilters || !schoolId) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <EventsAndSignUpsPageHeader value="signUps" loading />
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_COLS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    return <SignUpsContent initialFilters={initialFilters} />;
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};
