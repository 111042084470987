import { Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      ...theme.typography.body1,
      height: theme.spacing(3.75),

      '&&.MuiChip-root': {
        ...(ownerState.flat && {
          border: 'none',
          background: 'none',
          height: 'auto',

          '& .MuiChip-label': {
            padding: 0,
          },
        }),
      },

      '& .MuiChip-label': {
        padding: theme.spacing(0.75, 1.25),
      },

      '&.MuiChip-colorDefault': {
        color: theme.palette.text.primary,
        borderColor: theme.palette.common.light2,
      },

      '&.MuiChip-colorSuccess': {
        borderColor: theme.palette.success.light,
        backgroundColor: theme.palette.success.superLight,
      },

      '&.MuiChip-colorWarning': {
        borderColor: theme.palette.warning.light,
        backgroundColor: theme.palette.warning.superLight,
      },

      '&.MuiChip-colorError': {
        borderColor: theme.palette.error.light,
        backgroundColor: theme.palette.error.superLight,
      },
    }),
  },
};
