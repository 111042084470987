import {
  ApiError,
  CustomField,
  CustomFieldUpdate,
  GET_CUSTOM_FIELDS_FOR_SCHOOL_QUERY,
  useArchiveCustomFieldMutation,
  useCreateCustomFieldForSchoolMutation,
  useUpdateCustomFieldForSchoolMutation,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useRouter } from '../../../context/router/useRouter';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { useSchool } from '../../../hooks/useSchool';
import { SchoolPropertyArchiveConfirmationModal } from '../SchoolPropertyArchiveConfirmationModal';
import { SELECT_LABEL_MAX_BODY_LENGTH } from './constants';
import { SchoolCustomFieldsModalContent } from './SchoolCustomFieldsModalContent';

export const SchoolCustomFieldsModal: FC = () => {
  const { $t } = useIntl();
  const { showError, showNotification } = useNotifications();
  const { id } = useParams<'id'>();
  const { goBack } = useRouter();
  const { schoolId } = useSchool();
  const { customFields } = useCustomFields({ refetchOnMount: 'always' });
  const queryClient = useQueryClient();

  const [
    isArchiveConfirmationModalOpen,
    showArchiveConfirmationModal,
    hideArchiveConfirmationModal,
  ] = useFlag();

  const { mutateAsync: createCustomFieldForSchool, isLoading: loadingCreateCustomField } =
    useCreateCustomFieldForSchoolMutation();
  const { mutateAsync: updateCustomFieldForSchool, isLoading: loadingUpdateCustomField } =
    useUpdateCustomFieldForSchoolMutation();
  const { mutateAsync: archiveCustomFieldForSchool, isLoading: loadingArchiveCustomField } =
    useArchiveCustomFieldMutation();

  const customField = useMemo(
    () => customFields?.find((field) => field.id === id),
    [customFields, id],
  );

  const handleClose = goBack;
  const isNewItem = !id;

  const handleArchive = useCallback(async () => {
    if (!id) {
      return;
    }

    try {
      const updatedField = await archiveCustomFieldForSchool({
        id,
        archived: true,
      });

      showNotification({
        textId: 'school-sections-CustomFields-Message-Archived',
        values: { name: updatedField.label },
        type: 'success',
      });

      queryClient.invalidateQueries([GET_CUSTOM_FIELDS_FOR_SCHOOL_QUERY]);

      goBack();
    } catch (err) {
      console.error(err);
      showError(err as ApiError);
    }
  }, [id, queryClient, goBack, showError, showNotification, archiveCustomFieldForSchool]);

  const handleSubmit = useCallback(
    async (data: CustomFieldUpdate) => {
      if (!schoolId) {
        return;
      }

      if (
        data.select_type_options?.some(
          (option) => option.label.length > SELECT_LABEL_MAX_BODY_LENGTH,
        )
      ) {
        return;
      }

      try {
        if (isNewItem) {
          const payload: Omit<CustomField, 'id'> = {
            ...data,
            place_of_use: data.place_of_use ?? '',
            select_type_options: data.select_type_options?.map((option, i) => ({
              label: option.label,
              order: i,
            })),
            required: data.required ?? false,
          };

          await createCustomFieldForSchool({ schoolId, data: payload });

          showNotification({
            textId: 'school-sections-CustomFields-Message-Created',
            values: { name: data.label },
            type: 'success',
          });
        } else {
          const { id, ...newData } = data;

          await updateCustomFieldForSchool({
            id: id ?? '',
            data: {
              ...newData,
              place_of_use: data.place_of_use ?? '',
              select_type_options: data.select_type_options?.map((option, i) => ({
                label: option.label,
                order: i,
              })),
            },
          });

          showNotification({
            textId: 'school-sections-CustomFields-Message-Edited',
            values: { name: data.label },
            type: 'success',
          });
        }

        queryClient.invalidateQueries([GET_CUSTOM_FIELDS_FOR_SCHOOL_QUERY]);
        handleClose();
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }
    },
    [
      handleClose,
      queryClient,
      isNewItem,
      schoolId,
      showError,
      showNotification,
      createCustomFieldForSchool,
      updateCustomFieldForSchool,
    ],
  );

  return (
    <>
      <SchoolCustomFieldsModalContent
        customFields={customFields || []}
        customField={customField}
        onClose={handleClose}
        onArchiveClick={showArchiveConfirmationModal}
        isNewItem={isNewItem}
        onSubmit={handleSubmit}
        isArchiving={loadingArchiveCustomField}
        isSaving={loadingUpdateCustomField || loadingCreateCustomField}
      />

      {!!customField && (
        <SchoolPropertyArchiveConfirmationModal
          open={isArchiveConfirmationModalOpen}
          title={$t(
            {
              id: 'school-sections-CustomFields-Message-ArchiveConfirmAction',
            },
            { name: customField.label },
          )}
          message={
            <FormattedMessage id="school-sections-CustomFields-Message-ArchiveConfirmActionInformation" />
          }
          onClose={hideArchiveConfirmationModal}
          onArchive={handleArchive}
          isArchiving={loadingArchiveCustomField}
        />
      )}
    </>
  );
};
