import { Stack } from '@mui/material';
import {
  AvailableCriteria,
  FilterSelectOption,
  getTypedObjectKeys,
  GroupLimitations,
  GroupUserType,
  SchoolProperty,
} from '@schooly/api';
import { SimpleListResult } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import React, { useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { getUserTypeTextId } from '../../../../helpers/misc';
import { LimitedToEmptyStub } from '../LimitedToEmptyStub';
import {
  DEFAULT_LIMITED_TO_SECTION_TEXT_IDS,
  LimitedToCategory,
} from '../LimitedToSelect/LimitedToSelect';
import { LimitedToDropdownProps, LimitedToSelect } from '../LimitedToSelect/LimitedToSelect';
import { LimitedToDeletedUsers, useLimitedTo } from '../LimitedToSelect/useLimitedTo';
import { getLimitedToCategoryKey } from '../LimitedToSelect/utils';
import { GroupUsersList } from './GroupUserList';

const DEFAULT_LIMITED_TO: GroupLimitations = {
  school_property_ids: [],
  genders: [],
  nationalities: [],
  subject_ids: [],
};
export interface GroupContentProps {
  groupCriteria?: AvailableCriteria[];
  groupUsers?: SimpleListResult[];
  hasLimitedTo?: boolean;
  limitedTo: GroupLimitations;
  onCriteriaClick?: (criteria: AvailableCriteria) => void;
  onLimitedToChange: (data: GroupLimitations, users?: LimitedToDeletedUsers) => void;
  onUserClick?: (userId: string) => void;
  userType: GroupUserType;
  schoolProperties: SchoolProperty[];
  schoolId: string;
  dateRange: [string, string];
  renderCustomIcon: (userId: string) => React.ReactNode;
  limitedToErrors?: Partial<Record<LimitedToCategory, string>>;
  limitedToOptions: Partial<Record<LimitedToCategory, FilterSelectOption[]>>;
  requiredCategories: LimitedToCategory[];
}

export const GroupContent: React.FC<GroupContentProps> = ({
  groupCriteria,
  groupUsers,
  limitedTo: initialLimitedTo,
  onCriteriaClick,
  onLimitedToChange,
  onUserClick,
  userType,
  schoolProperties,
  schoolId,
  dateRange,
  renderCustomIcon,
  limitedToOptions,
  requiredCategories,
}) => {
  const ref = useRef<LimitedToDropdownProps | null>(null);
  const { $t } = useIntl();
  const { getConfirmation } = useConfirmationDialog();

  const allCategories = getTypedObjectKeys(limitedToOptions);

  const {
    draftLimitedTo,
    setDraftLimitedTo,
    isChecking,
    onLimitedToUpdate,
    onDropdownToggle,
    hasLimitedToSelection,
  } = useLimitedTo({
    limitedTo: initialLimitedTo,
    currentUsers: groupUsers ?? [],
    currentCriteria: groupCriteria,
    schoolId,
    dateRange,
    userType,
  });

  const selectedLimitedToOptions = useMemo(
    () =>
      allCategories.reduce<Partial<Record<LimitedToCategory, FilterSelectOption[]>>>((acc, cat) => {
        const key = getLimitedToCategoryKey(cat);

        const values =
          limitedToOptions[cat]?.filter((o) => draftLimitedTo[key]?.includes(o.value)) || [];

        return values.length ? { ...acc, [cat]: values } : acc;
      }, {}),
    [allCategories, draftLimitedTo, limitedToOptions],
  );

  const isRequiredCategoriesSelected = useMemo(
    () => requiredCategories.every((c) => selectedLimitedToOptions[c]?.length),
    [requiredCategories, selectedLimitedToOptions],
  );

  const handleLimitedToClose = useCallback(async () => {
    const error = await onLimitedToUpdate();

    if (!error) {
      onLimitedToChange(draftLimitedTo);
      return;
    }

    const { deletedCriteriaMemberIds, deletedMemberIds } = error;

    const userTypePlural = $t({
      id: getUserTypeTextId(userType, deletedMemberIds.length > 1),
    }).toLowerCase();

    const onlyCriteriaToRemove = !deletedMemberIds.length && !!deletedCriteriaMemberIds.length;

    const isConfirmed = await getConfirmation({
      textId: onlyCriteriaToRemove ? 'groups-LimitedToCriteriaWarning' : 'groups-LimitedToWarning',
      textValues: {
        number: deletedMemberIds.length,
        userType: userTypePlural,
      },
    });

    if (isConfirmed) {
      onLimitedToChange(draftLimitedTo, { deletedCriteriaMemberIds, deletedMemberIds });
    } else setDraftLimitedTo(initialLimitedTo);
  }, [
    $t,
    draftLimitedTo,
    getConfirmation,
    initialLimitedTo,
    onLimitedToChange,
    onLimitedToUpdate,
    setDraftLimitedTo,
    userType,
  ]);

  const handleLimitedToChange = useCallback(
    async (limitedToUpdate: GroupLimitations) => {
      const isRequiredCategoriesSelected = requiredCategories?.every((c) => {
        const category = limitedToUpdate[getLimitedToCategoryKey(c)];
        const values = limitedToOptions[c]?.filter((o) => category?.includes(o.value)) || [];

        return !!values.length;
      });

      const isGroupEmpty = !groupUsers?.length && !groupCriteria?.length;

      if (isRequiredCategoriesSelected || isGroupEmpty) {
        setDraftLimitedTo(limitedToUpdate);
        return;
      }
      const isConfirmed = await getConfirmation({
        onClick: (e) => {
          e.stopPropagation();
        },
        textId: 'groups-LimitedToWarningSelect',
        textValues: {
          userType: $t({
            id: getUserTypeTextId(userType, true),
          }).toLowerCase(),
        },
      });
      if (isConfirmed) {
        const deletedCriteriaMemberIds = groupCriteria?.flatMap((c) => c.relation_ids ?? []) ?? [];

        onLimitedToChange(DEFAULT_LIMITED_TO, {
          deletedMemberIds: groupUsers?.map((u) => u.relation_id) ?? [],
          deletedCriteriaMemberIds,
        });
        setDraftLimitedTo(DEFAULT_LIMITED_TO);
      } else setDraftLimitedTo(draftLimitedTo);
    },
    [
      $t,
      draftLimitedTo,
      getConfirmation,
      groupCriteria,
      groupUsers,
      limitedToOptions,
      onLimitedToChange,
      requiredCategories,
      setDraftLimitedTo,
      userType,
    ],
  );

  const notSelectedCategoriesText = useMemo(() => {
    const text = !isRequiredCategoriesSelected
      ? requiredCategories.reduce(
          (acc, c, i, arr) =>
            `${acc}${acc && arr.at(-1) ? ' and ' : ''}${$t({
              id: DEFAULT_LIMITED_TO_SECTION_TEXT_IDS[c],
            }).toLowerCase()}`,
          '',
        )
      : '';

    return text.length
      ? $t(
          { id: 'groups-LimitedToEmptyStub' },
          {
            userType: $t({
              id: getUserTypeTextId(userType, true),
            }).toLowerCase(),

            categories: text,
          },
        )
      : undefined;
  }, [$t, isRequiredCategoriesSelected, requiredCategories, userType]);

  const isListEmpty = !groupCriteria?.length && !groupUsers?.length;

  //If we have age groups in required categories we need to also show levels
  const displayedRequiredCategories = useMemo(() => {
    if (
      !(requiredCategories.includes('age_groups') && !requiredCategories.includes('school_levels'))
    ) {
      return requiredCategories;
    }

    const updatedCategories = [...requiredCategories];
    updatedCategories.splice(requiredCategories.indexOf('age_groups'), 0, 'school_levels');

    return updatedCategories;
  }, [requiredCategories]);

  return (
    <Stack sx={{ px: 2.5, height: '100%', overflow: 'hidden' }}>
      <LimitedToSelect
        hasValues={hasLimitedToSelection}
        limitedTo={draftLimitedTo}
        schoolProperties={schoolProperties}
        onChange={handleLimitedToChange}
        onClose={handleLimitedToClose}
        userType={userType}
        ref={ref}
        isLoading={isChecking}
        onToggle={onDropdownToggle}
        categories={isRequiredCategoriesSelected ? allCategories : displayedRequiredCategories}
        options={limitedToOptions}
        selectedOptions={selectedLimitedToOptions}
        helperText={
          !isListEmpty && notSelectedCategoriesText ? notSelectedCategoriesText : undefined
        }
      />
      <GroupUsersList
        groupUsers={groupUsers}
        groupCriteria={groupCriteria}
        userType={userType}
        emptyStub={
          isListEmpty && notSelectedCategoriesText ? (
            <LimitedToEmptyStub userType={userType} customText={notSelectedCategoriesText} />
          ) : undefined
        }
        onCriteriaClick={onCriteriaClick}
        onUserClick={onUserClick}
        renderCustomIcon={renderCustomIcon}
        isEmpty={isListEmpty}
      />
    </Stack>
  );
};
