import { Popover, Stack, TableRow, Typography } from '@mui/material';
import { GetReportsQuerySort, Report, ReportStatuses, SORT_DIRECTION } from '@schooly/api';
import { DATE_FORMAT } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { GridCell, GridHead, SortableCell, TypographyWithOverflowHint } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';

type ReportsHeaderProps = {
  sort?: GetReportsQuerySort;
  onChangeSort: (v: GetReportsQuerySort) => void;
};

const POPOVER_PROPS = {
  open: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
} as const;

export const ReportsHeader: FC<ReportsHeaderProps> = ({ onChangeSort, sort }) => {
  const { formatMessage } = useIntl();
  const handleSort = (columnTextId: GetReportsQuerySort['columnTextId']) => () => {
    onChangeSort({
      columnTextId,
      direction:
        columnTextId === sort?.columnTextId
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  return (
    <GridHead borderBottom>
      <SortableCell
        label={formatMessage({ id: 'reports-Name' })}
        sort={sort}
        columnTextId="name"
        onChangeSort={handleSort}
        pl={2}
      />
      <GridCell
        width="280px"
        sx={(theme) => ({
          [theme.breakpoints.only('md')]: {
            width: 200,
          },
        })}
      >
        {formatMessage({ id: 'reports-Assessments' })}
      </GridCell>
      <SortableCell
        label={formatMessage({ id: 'reports-ReportDate' })}
        sort={sort}
        columnTextId="report_date"
        onChangeSort={handleSort}
        width="180px"
      />
      <SortableCell
        label={formatMessage({ id: 'reports-Status' })}
        sort={sort}
        columnTextId="report_status"
        onChangeSort={handleSort}
        width="180px"
      />
    </GridHead>
  );
};

type ReportRowProps = {
  report: Report;
};

export const ReportRow: FC<ReportRowProps> = ({ report }) => {
  const location = useAppLocation();
  const { scheduled_publish_date } = report;

  return (
    <TableRow
      component={Link}
      to={`/reports/${report.id}`}
      state={{ backgroundLocation: location }}
      sx={(theme) => ({
        '&:hover td': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <GridCell pl={2}>
        <TypographyWithOverflowHint variant="h3" color="common.grey2" noWrap>
          {report.name}
        </TypographyWithOverflowHint>
      </GridCell>
      <GridCell>
        <AssessmentsCellContent report={report} />
      </GridCell>
      <GridCell>
        <Typography color="common.grey2">
          {scheduled_publish_date
            ? format(newDateTimezoneOffset(scheduled_publish_date), DATE_FORMAT)
            : '—'}
        </Typography>
      </GridCell>
      <GridCell>
        <StatusCellContent report={report} />
      </GridCell>
    </TableRow>
  );
};

type StatusCellContentProps = {
  report: Report;
};

export const StatusCellContent: React.FC<StatusCellContentProps> = ({ report }) => {
  const { formatMessage } = useIntl();

  const isPublished = report.report_status === ReportStatuses.Published;
  const isDraft = report.report_status === ReportStatuses.Draft;

  const currentStatus = isPublished ? (
    <>
      {formatMessage({ id: 'status-Published' })}{' '}
      {report.actual_publish_date
        ? format(newDateTimezoneOffset(report.actual_publish_date), DATE_FORMAT)
        : ''}
    </>
  ) : (
    <> {formatMessage({ id: isDraft ? 'status-Draft' : 'status-NotPublished' })}</>
  );

  return (
    <Typography color={isPublished ? 'success.main' : isDraft ? 'common.grey2' : 'warning.main'}>
      {currentStatus}
    </Typography>
  );
};

type CellContentProps = {
  report: Report;
};

export const AssessmentsCellContent: React.FC<CellContentProps> = ({ report }) => {
  const assessment = report.assessments?.[0];
  const ref = useRef<HTMLDivElement>(null);
  const [opened, open, close] = useFlag();

  if (!assessment) return null;

  const restGroupsLength = (report.assessments || []).length - 1;

  return (
    <>
      <Stack flexDirection="row" onMouseEnter={open} onMouseLeave={close}>
        <Typography ref={ref} color="common.grey2" noWrap>
          {assessment.name}
        </Typography>
        {!!restGroupsLength && (
          <Typography color="common.grey2">&nbsp;+{restGroupsLength}</Typography>
        )}
      </Stack>
      {!!restGroupsLength && opened && (
        <Popover
          {...POPOVER_PROPS}
          anchorEl={ref.current}
          sx={{ pointerEvents: 'none' }}
          PaperProps={{
            sx: { width: 200, mt: -3 },
          }}
        >
          <Stack p={1}>
            {report.assessments?.map((g) => (
              <Typography key={g.id}>{g.name}</Typography>
            ))}
          </Stack>
        </Popover>
      )}
    </>
  );
};
