import { FC } from 'react';

export const BasketSvg: FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="5" width="12" height="13" rx="1" fill="#B3BBCB" />
    <path d="M3.5 7.5L16.5 7.5" stroke="white" />
    <rect x="2" y="4" width="16" height="3" rx="1" fill="#B3BBCB" />
    <rect x="8" y="2" width="4" height="4" rx="2" fill="#B3BBCB" />
  </svg>
);
