import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useRouter } from '../context/router/useRouter';

export const RouteRoot: FC = () => {
  const { clean } = useRouter();

  useEffect(() => {
    clean();
  }, [clean]);

  return <Outlet />;
};
