import { Menu as MuiMenu, Popper, styled } from '@mui/material';

export const Menu = styled(MuiMenu)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
}));

export const MenuItemArrow = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
  width: theme.spacing(),

  '& > .MuiPaper-root': {
    position: 'absolute',
    width: theme.spacing(2),
    height: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: 2, // non-standard shape
    transform: 'rotate(-45deg)',
    transformOrigin: 'right bottom',
    boxShadow: 'none',
  },
}));

MenuItemArrow.defaultProps = {
  placement: 'bottom',
};
