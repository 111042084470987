import { Box, Icon, Stack, Typography } from '@mui/material';
import { ConductChartIconButton } from '@schooly/components/charts';
import { SearchInputFilterIconButton } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { ChartIcon, Loading, ModalSearch, PlusIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { GridContainer } from '../../../../components/uikit-components/Grid/Grid';
import { ListViewConductRow } from '../../../../components/uikit-components/ListViewConductRow/ListViewConductRow';
import {
  ModalContentStickyTitle,
  ModalContentStickyWrapper,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { MONTH_NONE } from '../../../../constants/misc';
import { useConductForGroup } from '../../../../context/conduct/useConductForGroup';
import { useSchool } from '../../../../hooks/useSchool';
import { CREATE_CONDUCT_GROUP_SEARCH_PARAM } from '../../../Conduct/ConductCreateModal/ConductCreateModal';
import { ConductEmptyStub } from '../../../Conduct/ConductEmptyStub';
import { GroupPreviewModalConductExport } from './GroupPreviewModalConductExport';
import { GroupPreviewModalConductFiltersPopup } from './GroupPreviewModalConductFiltersPopup';
import { GroupPreviewModalConductStats } from './GroupPreviewModalConductStats';

export const GroupPreviewModalConduct: FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { schoolId } = useSchool();

  const { $t } = useIntl();
  const {
    filters,
    setFilters,
    group,
    entriesByMonth,
    totalCount,
    isLoading,
    isFetchingNextPage,
    searchText,
    setSearchText,
    fetchNextPage,
    hasNextPage,
    params,
  } = useConductForGroup();
  const [filtersModalVisible, openFilterModal, closeFiltersModal] = useFlag(false);
  const [chartsVisible, openCharts, closeCharts] = useFlag(false);

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const handleAddLogClick = useCallback(() => {
    navigate(`/conduct/new?${CREATE_CONDUCT_GROUP_SEARCH_PARAM}=${group?.id}`);
  }, [navigate, group?.id]);

  const noConductEntries = !isLoading && !searchText && !totalCount;

  useEffect(() => {
    if (noConductEntries) {
      closeCharts();
    }
  }, [noConductEntries, closeCharts]);

  const filtersApplied = Boolean(filters.visibility?.length || filters.conduct_type?.length);

  return (
    <ModalContentStickyWrapper>
      <ModalContentStickyTitle>
        <Stack direction="row" justifyContent="space-between" mb={2.5} alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="groups-Conduct" />
            {!!totalCount && <Counter>{totalCount}</Counter>}
          </Typography>

          <Stack direction="row" gap={2.5} alignItems="center">
            <ModalSearch
              value={searchText}
              onChange_MemoizedCallbackOnly={setSearchText}
              placeholder={formatMessage({ id: 'groups-Conduct-Search' })}
              withDebounce
            />

            <Icon
              sx={{
                position: 'relative',
                height: 22,
                '.MuiButtonBase-root': {
                  right: 0,
                  color: 'text.primary',
                  ':hover': {
                    color: 'primary.main',
                  },
                },
              }}
            >
              <SearchInputFilterIconButton
                hasAppliedIndicator={filtersApplied}
                onClick={openFilterModal}
              />
            </Icon>

            <ConductChartIconButton
              schoolId={schoolId || ''}
              conductTypeOptionIds={filters.conduct_type}
              disabled={noConductEntries}
              onClick={chartsVisible ? closeCharts : openCharts}
              inverse
              sx={{
                '&:hover': {
                  color: chartsVisible ? 'common.grey2' : undefined,
                },
                color: chartsVisible ? 'primary.main' : 'common.grey2',
              }}
            >
              <ChartIcon />
            </ConductChartIconButton>
            <GroupPreviewModalConductExport params={params} />
            <SimpleButton
              sx={(theme) => ({
                color: theme.palette.text.primary,
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              })}
              startIcon={<PlusIcon />}
              onClick={handleAddLogClick}
            >
              <FormattedMessage id="groups-Conduct-CreateLog" />
            </SimpleButton>
          </Stack>
        </Stack>
      </ModalContentStickyTitle>

      <Stack gap={2.5} flexGrow={1}>
        {group && (
          <GroupPreviewModalConductStats
            onClose={closeCharts}
            conductTypeOptionIds={filters.conduct_type}
            isHidden={!chartsVisible || isLoading}
            schoolId={schoolId || ''}
            group={group}
          />
        )}
        {isLoading ? (
          <Loading />
        ) : !Object.keys(entriesByMonth).length ? (
          <ConductEmptyStub
            titleText={$t({
              id: searchText.length
                ? 'groups-Conduct-NoLogsMatchingFilters'
                : 'groups-Conduct-NoLogs',
            })}
          />
        ) : (
          <>
            {Object.keys(entriesByMonth).map((month) => (
              <Box key={month}>
                {month !== MONTH_NONE && <Stack mb={1.25}>{month}</Stack>}
                <GridContainer
                  sx={{
                    overflow: 'hidden',
                  }}
                >
                  {entriesByMonth[month].map((c) => (
                    <ListViewConductRow key={c.id} conduct={c} />
                  ))}
                </GridContainer>
              </Box>
            ))}
            {hasNextPage && (
              <Box py={3}>
                <Loading />
                <div ref={loaderRef} />
              </Box>
            )}
          </>
        )}
      </Stack>
      {filtersModalVisible && (
        <GroupPreviewModalConductFiltersPopup
          onSetFilters={setFilters}
          onClose={closeFiltersModal}
          filters={filters}
        />
      )}
    </ModalContentStickyWrapper>
  );
};
