import {
  ApiError,
  SyncUser,
  useGetUserRolesForRelationQuery,
  UserRole,
  useUpdateUserRolesForRelationMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useProfile } from '../../../context/profile/useProfile';
import {
  RouterStateContext,
  RouterStateContextProps,
} from '../../../context/router/RouterStateContext';
import { useRouter } from '../../../context/router/useRouter';

export interface ManageUserRoleModalContextState {
  currentRoles?: UserRole[];
  notAvailableRoles?: UserRole[];
  isFetching: boolean;
  user?: SyncUser;
}

export const CONTEXT_NAME = 'ManageUserRoleModal';

export const getInitialState = (): ManageUserRoleModalContextState => ({
  currentRoles: [],
  notAvailableRoles: [],
  isFetching: false,
  user: undefined,
});

export const useManageUserRoleModal = () => {
  const { state, setContextState, contextName, setContextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<ManageUserRoleModalContextState>;

  const { id: relationId = '' } = useParams<'id'>();
  const { user } = useProfile();

  const { goBack } = useRouter();

  const { permissions: currentUserPermissions } = useAuth();
  const isUserRoleManager = currentUserPermissions.includes('userrole_manager');
  const isUserRoleViewer = !isUserRoleManager && currentUserPermissions.includes('userrole_viewer');
  const [modalActive, setModalActive] = useState(true);

  const { data } = useGetUserRolesForRelationQuery(relationId, {
    enabled: !!relationId,
    refetchOnMount: 'always',
  });

  const { showError } = useNotifications();

  const closeModal = useCallback(() => {
    setModalActive(false);

    goBack();
  }, [goBack]);

  useEffect(() => {
    if (contextName !== CONTEXT_NAME) setContextName(CONTEXT_NAME);
  }, [contextName, setContextName]);

  useEffect(() => {
    if (!user || !data) {
      return;
    }

    setContextState({
      user,
      currentRoles: data?.available_to_assign || [],
      notAvailableRoles: data?.not_allowed_to_assign || [],
    });
  }, [data, setContextState, user]);

  const updateUserRolesForRelation = useUpdateUserRolesForRelationMutation();

  const saveUserRoles = useCallback(async () => {
    if (!relationId) {
      return;
    }

    try {
      const selectedRoleIds = [
        ...(state.currentRoles ?? []),
        ...(state.notAvailableRoles ?? []),
      ].map((r) => r.id);

      await updateUserRolesForRelation.mutateAsync({
        relation_id: relationId,
        userRoleIds: selectedRoleIds,
      });
      closeModal();
    } catch (e) {
      showError(e as ApiError);
    }
  }, [
    closeModal,
    relationId,
    showError,
    state?.currentRoles,
    state?.notAvailableRoles,
    updateUserRolesForRelation,
  ]);

  const onSelectRole = useCallback(
    (role: UserRole) => {
      if (!state?.currentRoles) {
        setContextState({
          currentRoles: [role],
        });
        return;
      }
      const roleAdded = state.currentRoles.some((r) => r.id === role.id);

      setContextState({
        currentRoles: roleAdded
          ? state.currentRoles.filter((r) => r.id !== role.id)
          : [...state.currentRoles, role],
      });
    },
    [setContextState, state?.currentRoles],
  );

  return {
    ...state,
    modalActive,
    isFetching: !data,
    isSaving: updateUserRolesForRelation.isLoading,
    isUserRoleManager,
    isUserRoleViewer,
    relationId,
    actions: {
      closeModal,
      onSelectRole,
      onSubmit: saveUserRoles,
    },
  };
};
