import { FormControlLabel, styled } from '@mui/material';

export interface FormRadioStyledProps {
  error?: any;
}

export const FormRadioStyled = styled(FormControlLabel)<FormRadioStyledProps>(
  ({ theme, error }) => ({
    '&.MuiFormControlLabel-root': {
      '&:hover': {
        '& .MuiButtonBase-root.MuiRadio-root:not(.Mui-disabled)': {
          borderColor: error ? theme.palette.error.main : theme.palette.common.light2,
          '&.Mui-checked': {
            borderColor: error ? theme.palette.error.main : theme.palette.common.light3,
          },
        },
      },

      '& .MuiTypography-root': { color: theme.palette.primary.main },
    },

    '&+.form-group__error': { padding: 0, marginTop: theme.spacing(-1) },
  }),
);
