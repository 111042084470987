import { Box, Stack, styled } from '@mui/material';

export const GroupPreviewAttendanceEmptyStubStyled = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    width: 300,
    height: 300,
  },

  '& .MuiButton-root': {
    marginTop: theme.spacing(2.5),
  },
}));

export const GroupPreviewAttendanceEmptyStubArrow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 'calc(50% + 150px)',
  bottom: theme.spacing(2),

  '& img': {
    width: 'auto',
    height: 'auto',
  },
}));
