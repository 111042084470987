import { MenuItem, MenuList } from '@mui/material';
import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ModalSidebarItem } from '../../../components/ui/ModalSidebar';
import { ModalSideBar } from '../../../components/uikit-components/Modal/Modal.styled';
import { useMessage } from '../../../context/messages/useMessage';
import useAppLocation from '../../../hooks/useAppLocation';
import useQueryStringParams from '../../../hooks/useQueryStringParams';

export enum MessageModalPreviewMode {
  MESSAGE = 'message',
  SEND_TO = 'send_to',
  RECIPIENTS = 'recipients',
  CONSENT_FORM = 'consent_form',
}

export const MessagePreviewModalSideBar: FC = () => {
  const { tab } = useQueryStringParams();
  const { isPublished, linkedConsentForm } = useMessage();
  const location = useAppLocation();

  const sidebarItems = useMemo<ModalSidebarItem<MessageModalPreviewMode>[]>(() => {
    const items = [
      {
        id: MessageModalPreviewMode.MESSAGE,
        title: 'messages-MessageTitle',
      },
      {
        id: MessageModalPreviewMode.SEND_TO,
        title: 'messages-SendTo',
      },
    ];

    if (isPublished) {
      items.push({
        id: MessageModalPreviewMode.RECIPIENTS,
        title: 'messages-ActualRecipients',
      });
    }

    if (linkedConsentForm) {
      items.push({
        id: MessageModalPreviewMode.CONSENT_FORM,
        title: 'consentForms-title',
      });
    }

    return items;
  }, [isPublished, linkedConsentForm]);

  return (
    <ModalSideBar sx={{ paddingTop: (theme) => theme.spacing(1) }}>
      <MenuList>
        {sidebarItems.map((item) => (
          <Link to={{ search: `?tab=${item.id}` }} replace key={item.id} state={location.state}>
            <MenuItem selected={tab === item.id}>
              <FormattedMessage id={item.title} />
            </MenuItem>
          </Link>
        ))}
      </MenuList>
    </ModalSideBar>
  );
};
