import { Box, Card, CardContent, CardHeader } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { SchoolPeriodsContent } from './SchoolPeriodsContent';

export const SchoolPeriods: FC = () => {
  return (
    <MainLayout>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="school-sections-SchoolPeriods" />
      </Box>
      <Card>
        <CardHeader title={<FormattedMessage id="school-sections-SchoolPeriods" />} />
        <CardContent>
          <SchoolPeriodsContent />
        </CardContent>
      </Card>
      <Outlet />
    </MainLayout>
  );
};
