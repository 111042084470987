import { Box, Button, IconButton } from '@mui/material';
import {
  GET_STUDENTS_INCONSISTENCIES_QUERY,
  GET_STUDENTS_INCONSISTENCY_LIST_QUERY,
  getTypedObjectKeys,
  SchoolYear,
  StudentInconsistencyType,
  StudentRolloverRequestParams,
  useGetStudentsInconsistenciesQuery,
} from '@schooly/api';
import { ArrangedByCollapsableSection } from '@schooly/components/filters';
import {
  ArrowRightIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalMain,
  RollBackIcon,
  Spin,
} from '@schooly/style';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RolloverHeader } from './RolloverHeader';
import { RolloverInconsistenciesList } from './RolloverInconsistenciesList';
import {
  CantRolloverNotification,
  NoDataForRolloverNotification,
  NoInconsistenciesNotification,
} from './RolloverNotification';
import { getInconsistencyTypeLabelId } from './utils';

type RolloverInconsistenciesContentProps = {
  params: StudentRolloverRequestParams;
  schoolYears: SchoolYear[];
  schoolId: string;
  onRowClick: (id: string) => void;
  onShowFamily: (id: string) => void;
  onClose: () => void;
  onRollover: () => void;
};

export const RolloverInconsistenciesContent: FC<RolloverInconsistenciesContentProps> = ({
  params,
  schoolYears,
  schoolId,
  onRowClick,
  onClose,
  onRollover,
  onShowFamily,
}) => {
  const { $t } = useIntl();
  const [dataCouldChanged, setDataCouldChanged] = useState(false);
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useGetStudentsInconsistenciesQuery(params, {
    refetchOnMount: 'always',
  });

  const isQueriesFetching = useIsFetching([GET_STUDENTS_INCONSISTENCY_LIST_QUERY]);

  const inconsistenciesData = useMemo(() => {
    if (!data) return null;

    const { inconsistencies } = data;
    if (!inconsistencies) return null;

    return getTypedObjectKeys(inconsistencies).reduce<
      Array<{ type: StudentInconsistencyType; count: number; label: string }>
    >((acc, type) => {
      const yearLabel =
        type === 'have_registration' || type === 'rollover_in_process'
          ? schoolYears.find((y) => y.id === params.year_to_id)?.name
          : undefined;
      const inconsistenciesCount = inconsistencies[type];
      if (!inconsistenciesCount) return acc;

      return [
        ...acc,
        {
          type,
          count: inconsistenciesCount,
          label: $t({ id: getInconsistencyTypeLabelId(type) }, { yearName: yearLabel }),
        },
      ];
    }, []);
  }, [$t, data, params.year_to_id, schoolYears]);

  const isSingleInconsistency = inconsistenciesData?.length === 1;

  const isFirstFetching = isFetching && !dataCouldChanged;
  const isInconsistenciesFixed = !isFetching && dataCouldChanged && !inconsistenciesData?.length;

  if (!data || isLoading || isFirstFetching) return <Loading />;

  const noDataForRollover = !data.inconsistencies && !data.can_rollover && !isLoading;
  const skipInconsistenciesCheck =
    !noDataForRollover &&
    !inconsistenciesData?.length &&
    !isLoading &&
    !isFetching &&
    !dataCouldChanged;

  if (skipInconsistenciesCheck) {
    onRollover();
    return null;
  }

  return (
    <>
      <RolloverHeader
        title={
          data.can_rollover
            ? $t({ id: 'students-AnnualRollover-Inconsistencies' })
            : $t({ id: 'students-AnnualRollover-CannotBeCompleted' })
        }
        onClose={onClose}
      >
        {dataCouldChanged && (
          <IconButton
            disabled={Boolean(isQueriesFetching)}
            onClick={() => {
              queryClient.invalidateQueries([GET_STUDENTS_INCONSISTENCIES_QUERY]);
              queryClient.invalidateQueries([GET_STUDENTS_INCONSISTENCY_LIST_QUERY]);
            }}
          >
            {Boolean(isQueriesFetching) ? <Spin /> : <RollBackIcon />}
          </IconButton>
        )}
      </RolloverHeader>
      <ModalMain>
        <ModalContent
          active
          sx={{
            padding: 0,
          }}
        >
          {!data.can_rollover && (
            <>
              {noDataForRollover ? <NoDataForRolloverNotification /> : <CantRolloverNotification />}
            </>
          )}

          {isInconsistenciesFixed && <NoInconsistenciesNotification />}

          {inconsistenciesData?.map((d) => {
            return (
              <Box
                sx={{
                  '.MuiDivider-root': {
                    mt: 1.75,
                  },
                }}
                key={d.type}
              >
                <ArrangedByCollapsableSection
                  count={d.count}
                  isExpanded={isSingleInconsistency}
                  title={d.label}
                  canExpand={isSingleInconsistency}
                  sx={(theme) => ({
                    px: 2.5,
                    mt: 0.5,
                    cursor: !isSingleInconsistency ? 'pointer' : 'inherit',
                    alignItems: 'center',
                    '.MuiBox-root': {
                      right: theme.spacing(2.5),
                    },
                  })}
                >
                  <RolloverInconsistenciesList
                    params={params}
                    type={d.type}
                    years={schoolYears}
                    schoolId={schoolId}
                    count={d.count}
                    onRowClick={(id) => {
                      setDataCouldChanged(true);
                      onRowClick(id);
                    }}
                    onShowFamily={onShowFamily}
                  />
                </ArrangedByCollapsableSection>
              </Box>
            );
          })}
        </ModalContent>
      </ModalMain>
      {data.can_rollover && (
        <ModalFooter
          active
          sx={(theme) => ({
            zIndex: theme.zIndex.drawer,
          })}
        >
          <Button endIcon={<ArrowRightIcon />} onClick={onRollover}>
            <FormattedMessage id="action-Next" />
          </Button>
        </ModalFooter>
      )}
    </>
  );
};
