import { Icon, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Attention2Icon, GridCell } from '@schooly/style';
import React, { FC } from 'react';

import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import { ManageGroupModalTooltip } from '../../ManageGroupModal/ManageGroupModalTooltip';
import { TakeAttendanceTableRow } from './TakeAttendanceModalContentGrid';

interface TakeAttendanceGridStudentNameCellProps {
  props: GridRenderCellParams<TakeAttendanceTableRow>;
}

export const TakeAttendanceGridStudentNameCell: FC<TakeAttendanceGridStudentNameCellProps> = ({
  props,
}) => {
  const { row } = props;
  const student = row.student;

  return (
    <GridCell width={'100%'} noPadding sx={{ '&.MuiTableCell-root': { background: 'inherit' } }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pr: 1,
          '& .Card--list-item': {
            border: 'none',
          },
        }}
      >
        {student && (
          <>
            <PersonCardBasic user={student} userType="student" isListItem isUsernameClickable />
            {student.absenceRequest.comment && (
              <ManageGroupModalTooltip
                sx={{ p: 1 }}
                text={
                  <Typography color="primary.main">{student.absenceRequest.comment}</Typography>
                }
                arrow
              >
                <Icon
                  sx={(theme) => ({
                    color: theme.palette.background.paper,
                    path: {
                      color: theme.palette.common.orange,
                    },
                  })}
                >
                  <Attention2Icon />
                </Icon>
              </ManageGroupModalTooltip>
            )}
          </>
        )}
      </Stack>
    </GridCell>
  );
};
