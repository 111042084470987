import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppLocation } from '../../hooks/useAppLocation';

export interface ModalStackLocation extends AppLocation {
  basePath: string;
}

export interface ModalState {
  modalsStack: ModalStackLocation[];
}

const initialState: ModalState = {
  modalsStack: [],
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    add: (
      state,
      action: PayloadAction<{
        location: AppLocation;
        getBasePath?: (location: AppLocation) => string;
      }>,
    ) => {
      const lastPath =
        state.modalsStack.length > 0
          ? action.payload.getBasePath?.(state.modalsStack[state.modalsStack.length - 1]) ??
            state.modalsStack[state.modalsStack.length - 1].basePath
          : undefined;

      const basePath =
        action.payload.getBasePath?.(action.payload.location) ?? action.payload.location.pathname;

      if (lastPath === basePath) {
        state.modalsStack[state.modalsStack.length - 1] = { ...action.payload.location, basePath };
      } else {
        state.modalsStack.push({ ...action.payload.location, basePath });
      }
    },
    remove: (state) => {
      const arr = [...state.modalsStack];
      arr.pop();

      return {
        ...state,
        modalsStack: arr,
      };
    },
    reset: (state) => ({
      ...state,
      modalsStack: [],
    }),
  },
});

export const actions = {
  ...slice.actions,
};

export default slice;
