import { Icon, Stack, Typography } from '@mui/material';
import { StudentSchoolRelation, SyncUser } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { NON_BREAKING_SPACE } from '@schooly/constants';
import { TypographyWithOverflowHint } from '@schooly/style';
import { getUserFullNameWithTitle } from '@schooly/utils/get-user-full-name-with-title';
import { FC } from 'react';

import { useProfile } from '../../../context/profile/useProfile';
import { getStudentProfilePropertiesArray } from './utils';

interface CompareDuplicatesUserCardProps {
  user: SyncUser | StudentSchoolRelation;
  isRelationToBePreserved?: boolean;
}

export const CompareDuplicatesUserCard: FC<CompareDuplicatesUserCardProps> = ({
  user,
  isRelationToBePreserved,
}) => {
  const { userType } = useProfile();
  const fullName = getUserFullNameWithTitle(user);
  const profileProperties = userType === 'student' ? getStudentProfilePropertiesArray(user) : [];

  return (
    <Stack
      p={2.5}
      sx={(theme) => ({
        border: theme.mixins.borderValue(),
        borderColor: isRelationToBePreserved ? 'success.main' : undefined,
        outline: isRelationToBePreserved
          ? `2px solid ${theme.palette.success.superLight}`
          : undefined,
        borderRadius: theme.spacing(1.25),
      })}
    >
      <AvatarAuth
        user={user}
        sx={(theme) => ({
          width: 100,
          height: 100,
          ...theme.typography.h1,
          color: 'common.grey3',
        })}
      />
      <Stack gap={0.5} mt={2.5} mb={1.75}>
        <TypographyWithOverflowHint variant="h2" color="text.primary" noWrap>
          {fullName}
        </TypographyWithOverflowHint>
        <TypographyWithOverflowHint variant="h2" color="text.primary" noWrap>
          {user.known_as || NON_BREAKING_SPACE}
        </TypographyWithOverflowHint>
      </Stack>
      <Stack gap={2}>
        {profileProperties.map(({ icon, property, tag }) => {
          return (
            <Stack direction="row" alignItems="center" gap={1}>
              <Icon sx={{ color: 'text.secondary' }}>{icon}</Icon>
              <Typography color="text.primary" variant="h3">
                {property || '-'}
              </Typography>
              {tag}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
