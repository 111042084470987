import { Stack, styled, TextField } from '@mui/material';
import { FC } from 'react';

import { SimpleButton, SimpleButtonProps } from '../SimpleButton/SimpleButton';
import { ModalPanel } from './Modal.styled';

export const ModalHeaderStyled = styled(ModalPanel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  overflow: 'unset',

  '& .ModalTitle': {
    flex: '1 1 auto',
    lineHeight: theme.spacing(4),
  },
}));

ModalHeaderStyled.defaultProps = {
  withBorderBottom: true,
};

export const ModalHeaderActions = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  minHeight: theme.spacing(4), // as .ModalTitle lineHeight

  '& .MuiIconButton-root': {
    color: theme.palette.common.grey,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const ModalHeaderInput = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(-0.5, 0),
}));

ModalHeaderInput.defaultProps = {
  variant: 'standard',
  fullWidth: true,
};

export const ModalHeaderTitle: FC<SimpleButtonProps> = (props) => (
  <SimpleButton
    sx={(theme) => ({ ...theme.typography.h1, margin: theme.spacing(-0.5, 0) })}
    {...props}
  />
);
