import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { ConductVisibility, GetConductEntriesQueryFilters } from '@schooly/api';
import {
  ConductTypeSelectMultiple,
  ConductVisibilitySelectMultiple,
} from '@schooly/components/filters';
import { CrossIcon, ModalContent, ModalFooter, ModalHeader } from '@schooly/style';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useSchool } from '../../../../hooks/useSchool';

type GroupPreviewModalConductFiltersPopupProps = {
  onClose: () => void;
  onSetFilters: (v: GetConductEntriesQueryFilters) => void;
  filters: GetConductEntriesQueryFilters;
};

export const GroupPreviewModalConductFiltersPopup: FC<GroupPreviewModalConductFiltersPopupProps> =
  ({ onClose, onSetFilters, filters: initialFilters }) => {
    const { $t } = useIntl();
    const { schoolId } = useSchool();
    const [filters, setFilters] = useState<GetConductEntriesQueryFilters>(initialFilters);

    const handleApply = useCallback(() => {
      onSetFilters(filters);
      onClose();
    }, [onSetFilters, filters, onClose]);

    const {
      handleClearConductVisibility,
      handleSelectConductVisibility,
      handleSelectConductTypeId,
      handleClearConductType,
    } = useMemo(() => {
      return {
        handleClearConductVisibility: () => setFilters((v) => ({ ...v, visibility: [] })),
        handleClearConductType: () => setFilters((v) => ({ ...v, conduct_type: [] })),
        handleSelectConductTypeId: (id: string) => {
          setFilters((v) => ({
            ...v,
            conduct_type: v.conduct_type
              ? v.conduct_type.includes(id)
                ? v.conduct_type.filter((ct) => ct !== id)
                : [...v.conduct_type, id]
              : [id],
          }));
        },
        handleSelectConductVisibility: (cv: ConductVisibility) => {
          setFilters((v) => ({
            ...v,
            visibility: v.visibility
              ? v.visibility.includes(cv)
                ? v.visibility.filter((ct) => ct !== cv)
                : [...v.visibility, cv]
              : [cv],
          }));
        },
      };
    }, []);

    if (!schoolId) return null;
    return (
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            width: 600,
          },
        }}
        open
        onClose={onClose}
      >
        <ModalHeader active title={$t({ id: 'groups-Conduct-FilterConduct' })}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent active>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Box flex={3}>
              <Typography color="common.grey2">{$t({ id: 'conduct-Status' })}</Typography>
            </Box>
            <Box flex={7}>
              <ConductVisibilitySelectMultiple
                onClear={handleClearConductVisibility}
                borderOnHover
                selectedVisibility={filters.visibility || []}
                onSelectConductVisibility={handleSelectConductVisibility}
              />
            </Box>
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Box flex={3}>
              <Typography color="common.grey2">{$t({ id: 'conduct-Type' })}</Typography>
            </Box>
            <Box flex={7}>
              <ConductTypeSelectMultiple
                onClear={handleClearConductType}
                borderOnHover
                schoolId={schoolId}
                selectedValue={filters.conduct_type || []}
                onSelectId={handleSelectConductTypeId}
              />
            </Box>
          </Stack>
        </ModalContent>
        <ModalFooter active>
          <Button onClick={handleApply}>{$t({ id: 'filter-Apply' })}</Button>
        </ModalFooter>
      </Dialog>
    );
  };
