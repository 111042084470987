import { Typography } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import NoSearchResult from '../../../assets/images/no-search-result.svg';
import { ModalEmptyStyled } from './ModalEmpty.styled';

export interface ModalEmptyProps extends PropsWithChildren {
  alt?: string;
  width?: string;
  height?: string;
}

export const ModalEmpty: FC<ModalEmptyProps> = ({ alt, children, width, height }) => {
  const { formatMessage } = useIntl();
  const displayLabel = children ?? (
    <Typography variant="body1">
      <FormattedMessage id="noSearchResults" />
    </Typography>
  );
  const displayAlt =
    alt ??
    (children && typeof children === 'string'
      ? children
      : formatMessage({ id: 'noSearchResults' }));

  return (
    <ModalEmptyStyled>
      <img height={height} width={width} src={NoSearchResult} alt={displayAlt} />
      {displayLabel}
    </ModalEmptyStyled>
  );
};
