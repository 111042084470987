import { Button } from '@mui/material';
import { Product, useGetProductsListQuery, XeroAccount } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { ArrowLeftIcon, CheckIcon, Loading, ModalFooter, ModalLarge, Spin } from '@schooly/style';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  ReplaceProductsAccountsContent,
  ReplaceProductsAccountsContentProps,
} from './ReplaceProductsAccountsContent';

type ReplaceProductsAccountsModalProps = {
  legalEntityId: string;
  title: ReactNode;
  schoolId: string;
  accounts: XeroAccount[];
  onSubmit: ReplaceProductsAccountsContentProps['onSubmit'];
  onGoBack: () => void;
  isLoading: boolean;
};

export const ReplaceProductsAccountsModal: FC<ReplaceProductsAccountsModalProps> = ({
  onGoBack,
  legalEntityId,
  schoolId,
  ...props
}) => {
  const { $t } = useIntl();
  const { getConfirmation } = useConfirmationDialog();

  const { data, isLoading } = useGetProductsListQuery(
    { query: '', schoolId, legalEntityIds: [legalEntityId], pageSize: 10 },
    { refetchOnMount: 'always' },
  );

  const entries = useMemo(
    () => data?.pages.reduce<Product[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  const handleClose = useCallback(async () => {
    const confirmed = await getConfirmation({
      cancelTextId: 'no',
      confirmTextId: 'yes',
      textId: 'legalEntities-CloseConfirmation',
    });
    if (confirmed) {
      onGoBack();
    }
  }, [getConfirmation, onGoBack]);

  return (
    <ModalLarge open onClose={handleClose}>
      {!data || isLoading ? (
        <Loading />
      ) : (
        <ReplaceProductsAccountsContent products={entries} onClose={handleClose} {...props}>
          <ModalFooter
            active
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Button onClick={onGoBack} variant="outlined" startIcon={<ArrowLeftIcon />}>
              {$t({ id: 'action-Back' })}
            </Button>
            <Button endIcon={isLoading ? <Spin /> : <CheckIcon />} type="submit">
              {$t({ id: 'action-Save' })}
            </Button>
          </ModalFooter>
        </ReplaceProductsAccountsContent>
      )}
    </ModalLarge>
  );
};
