import { Icon, Stack } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { DropdownMenuPopover, PlusIcon, SimpleButton, TwoArrowsIcon } from '@schooly/style';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';
import useSchoolYears from '../../hooks/useSchoolYears';
import { useAnnualPlanner } from './WithAnnualPlanner';

export const AnnualPlannerPageSchoolYear: FC = () => {
  const location = useAppLocation();
  const { schoolYears } = useSchoolYears();
  const { schoolYear, filters, handleSetFilters, hasSchoolAdminPermissions } = useAnnualPlanner();

  const [schoolYearAnchorEl, setSchoolYearAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleSchoolYearOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setSchoolYearAnchorEl(event.currentTarget);
  };

  const handleSchoolYearClose = () => {
    setSchoolYearAnchorEl(null);
  };

  const schoolYearOpen = Boolean(schoolYearAnchorEl);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          cursor: 'pointer',

          '& .MuiIcon-root': {
            color: schoolYearOpen ? 'primary.main' : 'text.secondary',
          },

          '&:hover .MuiIcon-root': {
            color: 'primary.main',
          },
        }}
        onClick={handleSchoolYearOpen}
      >
        <span>{schoolYear?.name}</span>
        <Icon>
          <TwoArrowsIcon />
        </Icon>
      </Stack>

      <DropdownMenuPopover
        open={schoolYearOpen}
        anchorEl={schoolYearAnchorEl}
        onClose={handleSchoolYearClose}
        closeOnSelect
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        isSelected={(year) => year.id === schoolYear?.id}
        options={schoolYears.map((year) => ({
          ...year,
          title: year.name,
          handler: () => {
            handleSetFilters({ ...filters, [FilterKeys.SchoolYear]: [year.id] });
          },
        }))}
        sx={(theme) => ({
          ...theme.mixins.border(),
          '& .MuiMenu-paper': {
            ...theme.mixins.borderControl(),
          },
        })}
      >
        {hasSchoolAdminPermissions && (
          <Link to={{ ...location, pathname: '/planner/school_years' }}>
            <SimpleButton startIcon={<PlusIcon />} sx={{ mt: 1 }} onClick={handleSchoolYearClose}>
              <FormattedMessage id="school-tabs-SchoolYears-AddSchoolYear" />
            </SimpleButton>
          </Link>
        )}
      </DropdownMenuPopover>
    </>
  );
};
