import { Box, Button, Stack, Typography } from '@mui/material';
import { EmptyFolderSvg, PlusIcon, theme } from '@schooly/style';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import GroupStubArrowImg from '../../../assets/images/group-stub-arrow.svg';
import GroupStubArrowNarrowImg from '../../../assets/images/group-stub-arrow-narrow.svg';
import GroupStubArrowWideImg from '../../../assets/images/group-stub-arrow-wide.svg';

interface CompanyPreviewModalEmptyStubProps {
  emptySearchResults: boolean;
  onButtonClick?: () => void;
  messageTextId: string;
  buttonTextId?: string;
}

export const CompanyPreviewModalEmptyStub: FC<CompanyPreviewModalEmptyStubProps> = ({
  emptySearchResults,
  messageTextId,
  buttonTextId,
  onButtonClick,
}) => {
  const { $t } = useIntl();

  const actionContent = useMemo(() => {
    if (emptySearchResults) {
      return (
        <>
          <Box
            sx={(theme) => ({
              position: 'absolute',
              left: 'calc(50% + 170px)',
              bottom: theme.spacing(2),

              '& img': {
                width: 'auto',
                height: 'auto',
              },
            })}
          >
            <picture>
              <source
                media={`(max-width: ${theme.breakpoints.values.md}px)`}
                srcSet={GroupStubArrowNarrowImg}
              />
              <source
                media={`(max-width: ${theme.breakpoints.values.lg}px)`}
                srcSet={GroupStubArrowWideImg}
              />
              <img src={GroupStubArrowImg} alt="arrow" />
            </picture>
          </Box>
        </>
      );
    }

    return (
      <>
        {onButtonClick && buttonTextId && (
          <Button
            startIcon={<PlusIcon />}
            onClick={onButtonClick}
            sx={(theme) => ({ mt: theme.spacing(3) })}
          >
            {$t({ id: buttonTextId })}
          </Button>
        )}
      </>
    );
  }, [$t, buttonTextId, emptySearchResults, onButtonClick]);

  return (
    <Stack mt={5} justifyContent="center" alignItems="center" position="relative">
      <Box
        mb={6}
        sx={(theme) => ({
          svg: {
            width: 300,
            [theme.breakpoints.down('lg')]: {
              width: 200,
            },
          },
        })}
      >
        <EmptyFolderSvg />
      </Box>
      {emptySearchResults && (
        <Typography maxWidth={300} variant="h3" textAlign="center">
          {$t({ id: 'companies-NoResultsMatching' })}
        </Typography>
      )}
      <Typography maxWidth={300} variant="h3" textAlign="center">
        {$t({ id: messageTextId })}
      </Typography>
      {actionContent}
    </Stack>
  );
};
