import { combineReducers } from '@reduxjs/toolkit';

import applicationsSlice, { ICreateApplicationFormState } from './slices/applicationSlice';
import assessmentsSlice, { AssessmentsState } from './slices/assessments/reducer';
import groupStateSlice, { IGroupStateState } from './slices/groupStateSlice';
import messagesSlice, { IMessagesState } from './slices/messagesSlice';
import modalSlice, { ModalState } from './slices/modalSlice';
import profileModalSlice, { IProfileModalState } from './slices/profileModalSlice';
import reportsSlice, { ReportsState } from './slices/reports/reducer';
import requestsStateSlice, { RequestsState } from './slices/requestsStateSlice';
import routerSlice, { IRouterState } from './slices/routerSlice';
import simpleLists, { SimpleListsState } from './slices/simple-lists/reducer';
import userRolesSlice, { IUserRolesState } from './slices/userRolesSlice';

export interface IState {
  assessments: AssessmentsState;
  simpleLists: SimpleListsState;
  messages: IMessagesState;
  profileModal: IProfileModalState;
  router: IRouterState;
  userRoles: IUserRolesState;
  modal: ModalState;
  groupState: IGroupStateState;
  reports: ReportsState;
  requestsState: RequestsState;
  applications: ICreateApplicationFormState;
}

const reducer = combineReducers<IState>({
  assessments: assessmentsSlice,
  reports: reportsSlice,
  simpleLists,
  messages: messagesSlice.reducer,
  profileModal: profileModalSlice.reducer,
  router: routerSlice.reducer,
  userRoles: userRolesSlice.reducer,
  modal: modalSlice.reducer,
  groupState: groupStateSlice.reducer,
  requestsState: requestsStateSlice.reducer,
  applications: applicationsSlice.reducer,
});

export default reducer;
