import { Box, Icon, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { CheckIcon, GridViewIcon, ListViewIcon, SmallListViewIcon } from '@schooly/style';
import React, { MouseEvent, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Menu } from '../../../../../components/common/ContextMenu/ContextMenu.styled';

export type ViewType = 'listView' | 'compressedView' | 'gridView';

interface ListViewSwitchProps {
  viewType: ViewType;
  onListViewChange: (viewType: ViewType) => void;
}

export const ListViewSwitch: React.FC<ListViewSwitchProps> = ({ viewType, onListViewChange }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actions = useMemo(
    () => [
      {
        viewTypeTextId: 'listView' as const,
        icon: <ListViewIcon />,
      },
      { viewTypeTextId: 'gridView' as const, icon: <GridViewIcon /> },
      {
        viewTypeTextId: 'compressedView' as const,
        icon: <SmallListViewIcon />,
      },
    ],
    [],
  );

  const currentViewIcon = useMemo(() => {
    return actions.find((a) => a.viewTypeTextId === viewType)?.icon ?? null;
  }, [actions, viewType]);

  return (
    <>
      <Stack
        sx={(theme) => ({
          ...(open && {
            '.svg-icon': { color: theme.palette.background.default },
          }),
        })}
      >
        <IconButton
          onClick={handleClick}
          sx={(theme) => ({
            color: theme.palette.common.grey2,
            border: `${theme.spacing(0.5)} solid transparent`,
            borderRadius: theme.spacing(0.5),
            ...(open && {
              background: theme.palette.primary.main,
              '.svg-icon': { color: theme.palette.background.default },
            }),
          })}
          inverse
        >
          {currentViewIcon}
        </IconButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ vertical: 18, horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        sx={{
          '& .MuiList-root': {
            width: 180,
            p: 1,
          },
          '& .MuiMenuItem-root': {
            m: 0,
            p: (theme) => theme.spacing(0.25, 0.5),
            '&:hover': {
              '& .MuiTypography-root, .svg-icon': {
                color: 'common.main2',
              },
            },
            color: 'primary.main',
          },
        }}
      >
        {actions.map(({ viewTypeTextId, icon }) => {
          const isSelected = viewTypeTextId === viewType;

          return (
            <MenuItem
              key={viewTypeTextId}
              onClick={() => {
                onListViewChange(viewTypeTextId);
                handleClose();
              }}
            >
              <Stack direction="row" justifyContent="space-between" width="100%" py={0.25}>
                <Stack direction="row">
                  <Icon>{icon}</Icon>
                  <Box pl={1}>
                    <Typography variant="h3">
                      <FormattedMessage id={viewTypeTextId} />
                    </Typography>
                  </Box>
                </Stack>
                {isSelected && (
                  <Icon>
                    <CheckIcon />
                  </Icon>
                )}
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
