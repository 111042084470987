import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DoubleCheckIcon } from '@schooly/style';
import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { useMessage } from '../../../../context/messages/useMessage';
import { getUserTypeTextId } from '../../../../helpers/misc';
import buildClassName from '../../../../utils/buildClassName';
import { MessagesModalPreviewRecipientsParents } from './MessagesModalPreviewRecipientsParents';
import { MessagesModalPreviewRecipientsStaff } from './MessagesModalPreviewRecipientsStaff';

export type TabType = 'parent' | 'staff';

export const MessagesModalPreviewRecipients: FC = () => {
  const { actualParents, actualStaff } = useMessage();
  const [tab, setTab] = useState<TabType>('parent');
  const tabs: TabType[] = ['parent', 'staff'];

  const [read, total] = useMemo(() => {
    switch (tab) {
      case 'parent':
        return [actualParents.filter((item) => item.read).length, actualParents.length];
      case 'staff':
        return [actualStaff.filter((item) => item.read).length, actualStaff.length];
      default:
        return [0, 0];
    }
  }, [actualParents, actualStaff, tab]);

  return (
    <Box>
      <Typography variant="h2" mb={2.5} sx={{ display: 'flex', alignItems: 'center' }}>
        <FormattedMessage id="messages-ActualRecipients" />
        <Counter>{actualParents.length + actualStaff.length}</Counter>
      </Typography>

      <div className="MessagesModalPreview">
        <div className="MessagesModalPreview__tabs">
          {tabs.map((item) => (
            <div
              key={item}
              onClick={() => setTab(item)}
              className={buildClassName('MessagesModalPreview__tab', item === tab && 'active')}
            >
              <FormattedMessage id={getUserTypeTextId(item, true)} />
            </div>
          ))}
          {total > 0 && (
            <Stack
              direction="row"
              alignSelf="center"
              alignItems="center"
              gap={1}
              sx={(theme) => ({
                flex: '1 1 auto',
                justifyContent: 'flex-end',
                pr: 1,

                [theme.breakpoints.down('lg')]: {
                  pr: 0,
                },
              })}
            >
              <IconButton sx={{ color: 'success.main', '&:hover': { color: 'success.main' } }}>
                <DoubleCheckIcon />
              </IconButton>
              <Typography component="div" color="success.main" fontWeight={500}>
                {read}/{total}
              </Typography>
            </Stack>
          )}
        </div>
        <div className="MessagesModalPreview__content">
          {tab === 'parent' && <MessagesModalPreviewRecipientsParents />}
          {tab === 'staff' && <MessagesModalPreviewRecipientsStaff />}
        </div>
      </div>
    </Box>
  );
};
