import {
  GET_PAYMENT_FREQUENCIES_QUERY,
  MonthlyPaymentFrequency,
  PaymentFrequency,
  SchoolYear,
  TermlyPaymentFrequency,
  TermlyWithPeriodGroup,
  useGetSchoolPaymentFrequencies,
  useUpdatePaymentFrequency,
  WeeklyPaymentFrequency,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PeriodKey } from '@schooly/components/form-select';
import { useNotifications } from '@schooly/components/notifications';
import { isBefore, startOfToday } from 'date-fns';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';

import { getSortedFrequencies } from '../../pages/School/SchoolProducts/helpers';
import { queryClient } from '../../queryClient';

type PaymentFrequencyForm<T> = Omit<T, 'has_invoices'>;

export type WeeklyPaymentFrequencyForm = {
  activity_cycles: PeriodKey[];
} & Omit<PaymentFrequencyForm<WeeklyPaymentFrequency>, 'activity_cycles'>;

export type MonthlyPaymentFrequencyForm = PaymentFrequencyForm<WeeklyPaymentFrequency>;

export type TermlyFrequencyForm = {
  billing_periods: TermlyWithPeriodGroup['billing_periods'];
} & PaymentFrequencyForm<TermlyPaymentFrequency>;

export type MonthlyFrequencyForm = {
  activity_cycles: PeriodKey[];
} & Omit<PaymentFrequencyForm<MonthlyPaymentFrequency>, 'activity_cycles'>;

export type FrequenciesContextProps = {
  frequencies?: PaymentFrequency[];
  currentSchoolYear?: SchoolYear;
  isLoading: boolean;
  schoolId: string;
  updateFrequency: (frequency: PaymentFrequency) => Promise<void | PaymentFrequency>;
  updatingFrequency: boolean;
  canEdit: boolean;
  sortedFrequencies?: PaymentFrequency[];
};

export const FrequenciesContext = createContext<FrequenciesContextProps>({
  frequencies: undefined,
  currentSchoolYear: undefined,
  isLoading: false,
  schoolId: '',
  updateFrequency: async () => {},
  updatingFrequency: false,
  canEdit: false,
});

export const WithFrequencies: FC<
  PropsWithChildren<{ schoolYear: SchoolYear; currentFrequencies?: PaymentFrequency[] }>
> = ({ schoolYear, children, currentFrequencies }) => {
  const { schoolId = '', permissions } = useAuth();
  const { showError } = useNotifications();

  const updateFrequencyMutation = useUpdatePaymentFrequency();
  const { isLoading: updatingFrequency } = updateFrequencyMutation;

  const { data, isLoading } = useGetSchoolPaymentFrequencies(
    {
      school_id: schoolId,
      year_id: schoolYear.id,
    },
    { refetchOnMount: 'always', enabled: Boolean(!currentFrequencies) },
  );

  const frequencies = currentFrequencies ?? data?.frequencies;

  const sortedFrequencies = useMemo(
    () => (!!frequencies?.length ? getSortedFrequencies(frequencies) : frequencies),
    [frequencies],
  );

  const canEdit = permissions.includes('product_and_invoice_manager');
  const isPastYear = useMemo(() => {
    const today = startOfToday();
    const isDateInPast = isBefore(new Date(schoolYear.end), today);

    return isDateInPast;
  }, [schoolYear.end]);

  const updateFrequency = useCallback(
    async (frequency: PaymentFrequency) => {
      //TR-6100 Turning off confirmations logic (or some other turning off logic will be implemented later)
      // const isConfirmed = frequency.in_use
      //   ? true
      //   : await getFrequencyTurnOffConfirmation({
      //       frequency,
      //       frequencies: frequencies ?? [],
      //     });

      // if (!isConfirmed) return;

      return updateFrequencyMutation.mutateAsync(frequency, {
        onSuccess: (res) => {
          queryClient.invalidateQueries([
            GET_PAYMENT_FREQUENCIES_QUERY,
            { school_id: schoolId, year_id: schoolYear.id },
          ]);

          return res;
        },
        onError: showError,
      });
    },
    [schoolId, schoolYear.id, showError, updateFrequencyMutation],
  );

  return (
    <FrequenciesContext.Provider
      value={{
        frequencies,
        currentSchoolYear: schoolYear,
        isLoading,
        canEdit: canEdit && !isPastYear,
        schoolId,
        updateFrequency,
        updatingFrequency,
        sortedFrequencies,
      }}
    >
      {children}
    </FrequenciesContext.Provider>
  );
};

export const useFrequencies = () => {
  return useContext(FrequenciesContext);
};
