import {
  ASSESSMENTS_FILTER_KEYS,
  DEFAULT_DATE_FORMAT_FNS,
  DefaultSchoolYear,
  FilterKeys,
  FilterSection,
  GetAssessmentsQueryFilters,
  RepetitionType,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AssessmentRecurrenceExpandedSelect,
  AssessmentRecurrenceTagSelect,
  AssessmentStatusTagSelect,
  DateRangeDropdown,
  filterExistingFilterOptions,
  GroupTagSelect,
  MoreButtonOption,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  ReportTagSelect,
  SubjectTagSelect,
  UserTagSelect,
} from '@schooly/components/filters';
import {
  AssessmentStatusExpandedSelect,
  FilterDropdown,
  FiltersContainer,
  GroupExpandedSelect,
  MoreButton,
  ReportExpandedSelect,
  SubjectExpandedSelect,
  UserExpandedSelect,
} from '@schooly/components/filters';
import { AssessmentStatuses } from '@schooly/constants';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useCheckSchoolSettings } from '../../hooks/useCheckSchoolSettings';

type AssessmentsFiltersProps = {
  schoolId: string;
  filters: GetAssessmentsQueryFilters;
  defaultFilters: GetAssessmentsQueryFilters;
  onSetFilters: (v: GetAssessmentsQueryFilters) => void;
  notActualInitialDate?: boolean;
  defaultSchoolYear?: DefaultSchoolYear;
  defaultUserFilters: GetAssessmentsQueryFilters;
};

export const AssessmentsFilters: FC<AssessmentsFiltersProps> = ({
  defaultFilters,
  onSetFilters,
  filters: actualFilters,
  schoolId,
  notActualInitialDate,
  defaultSchoolYear,
  defaultUserFilters,
}) => {
  const { $t } = useIntl();
  const [dateChanged, setDateChanged] = useState(false);
  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetAssessmentsQueryFilters>(actualFilters);
  const { currentStaff } = useAuth();
  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    setDraftFilters((filters) => ({ ...filters, [v]: filters[v] !== undefined ? undefined : [] }));
  }, []);

  const { hasSubjects } = useCheckSchoolSettings();

  useEffect(() => {
    setDraftFilters(actualFilters);
  }, [actualFilters]);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  const handleApply = useMemo(() => {
    if (
      !ASSESSMENTS_FILTER_KEYS.some((key) => {
        const draftFiltersForKey = [...(draftFilters[key] || [])];
        const actualFiltersForKey = [...(actualFilters[key] || [])];

        return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
      })
    )
      return undefined;

    return () => {
      onSetFilters(draftFilters);
    };
  }, [onSetFilters, actualFilters, draftFilters]);

  const dateFilter = useMemo(() => ({ [FilterKeys.Date]: draftFilters.date }), [draftFilters.date]);

  const recurringFilter = useMemo(
    () => ({
      [FilterKeys.Date]: draftFilters.date,
      [FilterKeys.RepetitionType]: [RepetitionType.FirstOfRecurrence],
    }),
    [draftFilters.date],
  );

  const {
    onSetDate,
    onSelectGroup,
    onClearGroup,
    onClearReport,
    onSelectReportId,
    onClearSubject,
    onSelectSubjectId,
    onSelectStaffId,
    onClearStaff,
  } = useMemo(() => {
    const updateFilter =
      (key: FilterKeys.Group | FilterKeys.Report | FilterKeys.Subject | FilterKeys.Staff) =>
      (id: string) => {
        setDraftFilters((filters) => ({
          ...filters,
          [key]: filters[key]?.includes(id)
            ? filters[key]?.filter((ct) => ct !== id)
            : [...(filters[key] || []), id],
        }));
      };
    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    return {
      onSetDate: (v: [Date, Date]) =>
        setDraftFilters((filters) => {
          const updatedDates = [
            format(v[0], DEFAULT_DATE_FORMAT_FNS),
            format(v[1], DEFAULT_DATE_FORMAT_FNS),
          ];

          if (updatedDates.join('') === filters.date?.join('')) return filters;

          setDateChanged(true);
          return {
            ...filters,
            [FilterKeys.Date]: updatedDates,
          };
        }),
      onSelectSubjectId: updateFilter(FilterKeys.Subject),
      onClearSubject: clearFilter(FilterKeys.Subject),
      onSelectGroup: updateFilter(FilterKeys.Group),
      onClearGroup: clearFilter(FilterKeys.Group),
      onSelectReportId: updateFilter(FilterKeys.Report),
      onClearReport: clearFilter(FilterKeys.Report),
      onSelectStaffId: updateFilter(FilterKeys.Staff),
      onClearStaff: clearFilter(FilterKeys.Staff),
    };
  }, []);

  const { onSelectStatus, onClearStatus } = useMemo(() => {
    return {
      onSelectStatus: (id: AssessmentStatuses) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.AssessmentStatus]: filters[FilterKeys.AssessmentStatus]?.includes(id)
            ? filters[FilterKeys.AssessmentStatus]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.AssessmentStatus] || []), id],
        })),
      onClearStatus: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.AssessmentStatus]: [],
        })),
    };
  }, []);

  const { onSelectRecurrence, onClearRecurrence } = useMemo(() => {
    return {
      onSelectRecurrence: (id: string) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.RecurrenceId]: [id],
        })),
      onClearRecurrence: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.RecurrenceId]: [],
        })),
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    setDraftFilters(defaultFilters);
  }, [defaultFilters]);

  const handleResetToDefault = useCallback(() => {
    setDraftFilters(defaultUserFilters);
  }, [defaultUserFilters]);

  const filtersDate = draftFilters.date;
  const filtersGroup = draftFilters.group;
  const filtersReport = draftFilters.report;
  const filtersStatus = draftFilters.assessment_status;
  const filtersSubject = draftFilters.subject;
  const filtersStaff = draftFilters.staff;
  const filtersRecurring = draftFilters.recurrence_id;

  const subjectLabel = $t({ id: 'groups-GroupSubject' });
  const reportLabel = $t({ id: 'assessments-Report' });
  const groupLabel = $t({ id: 'asssessments-Group' });
  const statusLabel = $t({ id: 'assessments-Status' });
  const dateLabel = $t({ id: 'schoolProperty-Period' });
  const staffLabel = $t({ id: 'schoolProperty-Staff' });
  const recurrenceLabel = $t({ id: 'assessments-recurring' });

  const filterOptions: MoreButtonOption<keyof GetAssessmentsQueryFilters>[] = [
    { value: FilterKeys.Date, label: dateLabel, required: true },
    { value: FilterKeys.Group, label: groupLabel },
    { value: FilterKeys.Report, label: reportLabel },
    { value: FilterKeys.AssessmentStatus, label: statusLabel },
    { value: FilterKeys.Subject, label: subjectLabel },
    { value: FilterKeys.Staff, label: staffLabel },
    { value: FilterKeys.RecurrenceId, label: recurrenceLabel },
  ];

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        onSaveFilter={handleSaveFilter}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        schoolId={schoolId}
        section={FilterSection.Assessment}
        filters={draftFilters}
        defaultSchoolYear={defaultSchoolYear}
        onSetFilters={(v) => {
          onSetFilters(pickOnlyParamsFromFilterKeys(ASSESSMENTS_FILTER_KEYS, v));
        }}
      />
      {filtersDate && (
        <DateRangeDropdown
          schoolId={schoolId}
          date={filtersDate}
          dateChanged={dateChanged}
          onSetDate={onSetDate}
          notActualInitialDate={notActualInitialDate}
          openLabel={dateLabel}
          defaultSchoolYear={defaultSchoolYear}
        />
      )}
      {filtersGroup && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Group)}
          label={groupLabel}
          width={500}
          tags={(open) =>
            filtersGroup.map((id) => (
              <GroupTagSelect key={id} sx={{ maxWidth: 200 }} id={id} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <GroupExpandedSelect
              selectedIds={filtersGroup}
              schoolId={schoolId}
              onSelectGroup={onSelectGroup}
              onClose={onClose}
              onClear={onClearGroup}
              filters={dateFilter}
            />
          )}
        </FilterDropdown>
      )}
      {filtersReport && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Report)}
          label={reportLabel}
          tags={(open) =>
            filtersReport.map((id) => (
              <ReportTagSelect key={id} sx={{ maxWidth: 200 }} id={id} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <ReportExpandedSelect
              selectedIds={filtersReport}
              schoolId={schoolId}
              onSelectReportId={onSelectReportId}
              onClose={onClose}
              onClear={onClearReport}
              filters={dateFilter}
            />
          )}
        </FilterDropdown>
      )}
      {filtersStatus && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.AssessmentStatus)}
          label={statusLabel}
          tags={(open) =>
            filtersStatus.map((status) => (
              <AssessmentStatusTagSelect
                key={status}
                sx={{ maxWidth: 200 }}
                status={status}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <AssessmentStatusExpandedSelect
              selectedStatuses={filtersStatus}
              onSelectStatus={onSelectStatus}
              onClose={onClose}
              onClear={onClearStatus}
            />
          )}
        </FilterDropdown>
      )}
      {filtersSubject && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Subject)}
          label={subjectLabel}
          tags={(open) =>
            filtersSubject.map((id) => (
              <SubjectTagSelect
                schoolId={schoolId}
                sx={{ maxWidth: 200 }}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <SubjectExpandedSelect
              schoolId={schoolId}
              selectedIds={filtersSubject}
              onSelectSubjectId={onSelectSubjectId}
              onClose={onClose}
              onClear={onClearSubject}
            />
          )}
        </FilterDropdown>
      )}
      {filtersStaff && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Staff)}
          label={staffLabel}
          tags={(open) =>
            filtersStaff.map((id) => (
              <UserTagSelect
                userType="staff"
                currentUser={currentStaff}
                sx={{ maxWidth: 200 }}
                schoolId={schoolId}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <UserExpandedSelect
              type="staff"
              schoolId={schoolId}
              currentUser={currentStaff}
              selectedIds={filtersStaff}
              onSelectUserId={onSelectStaffId}
              onClose={onClose}
              onClear={onClearStaff}
              filters={dateFilter}
            />
          )}
        </FilterDropdown>
      )}
      {filtersRecurring && (
        <FilterDropdown
          width={500}
          onClear={() => toggleFiltersVisible(FilterKeys.RecurrenceId)}
          label={recurrenceLabel}
          tags={(open) =>
            filtersRecurring.map((id) => (
              <AssessmentRecurrenceTagSelect
                key={id}
                sx={{ maxWidth: 200 }}
                id={id}
                onClick={open}
                schoolId={schoolId}
                onClear={() => onClearRecurrence()}
              />
            ))
          }
        >
          {(onClose) => (
            <AssessmentRecurrenceExpandedSelect
              selectedId={filtersRecurring[0]}
              schoolId={schoolId}
              onSelectRecurrence={onSelectRecurrence}
              onClose={onClose}
              onClear={onClearRecurrence}
              filters={recurringFilter}
            />
          )}
        </FilterDropdown>
      )}
      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterExistingFilterOptions({ filterOptions, hasSubjects })}
        selectedOptions={ASSESSMENTS_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        onToggleOption={toggleFiltersVisible}
      />
    </FiltersContainer>
  );
};
