import { Box, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

type ReadMoreProps = {
  text: string;
  rows: number;
};

const ROW_HEIGHT = 22;

export const ReadMore: FC<PropsWithChildren<ReadMoreProps>> = ({ text, rows, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { ref } = useResizeDetector<HTMLDivElement>();
  const fullHeight = ref.current?.scrollHeight ?? 0;
  const maxHeight = fullHeight > rows * ROW_HEIGHT ? rows * ROW_HEIGHT : fullHeight;
  const [currentHeight, setCurrentHeight] = useState<number>();
  const { $t } = useIntl();

  const overflowed = ref.current ? fullHeight > maxHeight : false;

  const showExpandButton = overflowed || isExpanded;

  const handleClick = useCallback(() => {
    if (isExpanded) {
      setCurrentHeight(maxHeight);
      setIsExpanded(false);
    } else {
      setCurrentHeight(ref.current?.scrollHeight ?? 0);
      setIsExpanded(true);
    }
  }, [maxHeight, isExpanded, ref]);

  return (
    <Stack
      alignItems="flex-start"
      sx={{
        overflow: 'hidden',
      }}
    >
      <Box
        sx={(theme) => ({
          height: `${currentHeight ?? maxHeight}px`,
          overflow: 'hidden',
          '.ql-editor': theme.typography.h3,
        })}
        ref={ref}
      >
        {children}
      </Box>
      {showExpandButton && (
        <Box
          onClick={handleClick}
          sx={{
            pt: 1,
            cursor: 'pointer',
            color: 'primary.main',
          }}
        >
          <Typography>
            {isExpanded ? $t({ id: 'action-Hide' }) : $t({ id: 'action-ShowMoreButton' })}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
