import { Card, CardContent, CardHeader, Icon, IconButton, Skeleton, Stack } from '@mui/material';
import { DATE_FORMAT_MOMENT as DATE_FORMAT } from '@schooly/constants';
import { CalendarIcon, EditIcon } from '@schooly/style';
import moment from 'moment';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import useSchoolYears from '../../../../hooks/useSchoolYears';

const DISPLAY_ROWS = 2;

export const SchoolGeneralSchoolYears: FC = () => {
  const { schoolYears, isLoading } = useSchoolYears();
  const [expanded, setExpanded] = useState(false);

  const canExpand = !isLoading && schoolYears.length > DISPLAY_ROWS;

  const rows = useMemo(
    () => schoolYears.slice(0, !canExpand || expanded ? undefined : DISPLAY_ROWS),
    [canExpand, expanded, schoolYears],
  );

  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  return (
    <Card sx={{ minHeight: 224 }}>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center">
            <FormattedMessage id="school-tabs-SchoolYears" />
            {isLoading ? (
              <Skeleton
                variant="circular"
                sx={(theme) => ({
                  ml: 1,
                  width: theme.spacing(2.5),
                  height: theme.spacing(2.5),
                })}
              />
            ) : (
              <Counter>{schoolYears.length}</Counter>
            )}
          </Stack>
        }
        action={
          <Link to="/settings/school_years">
            <IconButton inverse>
              <EditIcon />
            </IconButton>
          </Link>
        }
      />
      <CardContent>
        <GridContainer
          sx={{
            borderRadius: 0,
            borderBottom: (theme) => (isLoading ? undefined : theme.mixins.borderValue()),
          }}
        >
          {isLoading ? (
            <Stack>
              {new Array(3).fill(true).map((_, index) => (
                <GridRowStyled key={index} noBorder noBorderRadius>
                  <GridRowItem>
                    <GridRowName>
                      <Skeleton />
                    </GridRowName>
                  </GridRowItem>
                </GridRowStyled>
              ))}
            </Stack>
          ) : (
            rows.map((schoolYear) => (
              <GridRowStyled key={schoolYear.id} noBorderRadius>
                <GridRowItem>
                  <GridRowName>{schoolYear.name}</GridRowName>
                  <GridRowName variant="body1">
                    <Stack direction="row" alignItems="center" gap={1} whiteSpace="nowrap">
                      <Icon sx={{ display: 'flex', color: 'text.secondary' }}>
                        <CalendarIcon />
                      </Icon>
                      <span>
                        {moment(schoolYear.start).format(DATE_FORMAT)} -{' '}
                        {moment(schoolYear.end).format(DATE_FORMAT)}
                      </span>
                    </Stack>
                  </GridRowName>
                </GridRowItem>
              </GridRowStyled>
            ))
          )}
        </GridContainer>

        {canExpand && (
          <SimpleButton sx={{ mt: 2.5 }} onClick={toggleExpand}>
            {expanded ? (
              <FormattedMessage id="action-Hide" />
            ) : (
              <FormattedMessage
                id="action-ViewMore-number"
                values={{ number: schoolYears.length - rows.length }}
              />
            )}
          </SimpleButton>
        )}
      </CardContent>
    </Card>
  );
};
