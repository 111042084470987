const COLOR_CIRCLE = 360;
const HUE_START = 235;
const SATURATION = 60;
const BRIGHTNESS_MIN = 30;
const MIN_BRIGHTEST_HUE = 215;

const START_COLOR = { h: HUE_START, s: SATURATION, b: 38 };

export const HSBToRGB = ({ h, s, b }: { h: number; s: number; b: number }) => {
  s /= 100;
  b /= 100;
  const k = (n: number) => (n + h / 60) % 6;
  const f = (n: number) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
  return `rgb(${Math.round(255 * f(5))}, ${Math.round(255 * f(3))}, ${Math.round(255 * f(1))})`;
};

export function getHSBColors(count: number): Array<{ h: number; s: number; b: number }> {
  const colors = [];
  colors.push(START_COLOR);

  const hueStep = Math.floor(COLOR_CIRCLE / count);

  for (let i = 1; i < count; i++) {
    const hue = (HUE_START + hueStep * i) % COLOR_CIRCLE;
    const brightness = getBrightness(hue);
    colors.push({ h: hue, s: SATURATION, b: brightness });
  }

  return colors;
}

function getBrightness(hue: number) {
  const hueToBrightnessRatio = 8 / 20; // 20 points in hue translates to 8 points in brightness
  const hueValue = Math.abs(MIN_BRIGHTEST_HUE - hue);

  const brightnessAdjustment = hueValue * hueToBrightnessRatio;
  const result = BRIGHTNESS_MIN + brightnessAdjustment;
  return Math.ceil(result);
}
