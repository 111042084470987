import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import './index.scss';

interface IProps {
  title?: string;
}

const GroupEmptyStub: React.FC<IProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="GroupEmptyStub">
      <picture>
        <source srcSet="/images/no-schools.png, /images/no-schools@2x.png 2x" />
        <img
          className="GroupEmptyStub__image"
          src="/images/no-schools.png"
          alt={formatMessage({ id: 'groups-NoMembersYet' })}
        />
      </picture>
      <p className="GroupEmptyStub__text">
        <FormattedMessage id="groups-NoMembersYet" />
      </p>
    </div>
  );
};

export default GroupEmptyStub;
