import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { AnnualPlanRecordTypes } from '@schooly/api';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { ANNUAL_PLANNER_DAY_CELL_WIDTH } from './AnnualPlannerCalendar/scheme';
import { useAnnualPlannerFilter } from './useAnnualPlannerFilter';
import { useAnnualPlanner } from './WithAnnualPlanner';

export const AnnualPlannerPageFilter: FC = () => {
  const { $t } = useIntl();
  const {
    hasEventsPermissions,
    hasAssessmentsPermissions,
    // data, // TODO: uncomment after release due https://schooly.atlassian.net/browse/TR-5534
    recordTypesMap,
    toggleRecordType,
  } = useAnnualPlanner();
  const { getFilterItemProps } = useAnnualPlannerFilter();

  return (
    <Stack direction="row" gap={`${ANNUAL_PLANNER_DAY_CELL_WIDTH}px`} mt={0.5} mb={2}>
      <FormControlLabel
        label={$t({ id: 'annualPlanner-Records-SchoolPeriods-Title' })}
        control={
          <Checkbox
            checked={recordTypesMap[AnnualPlanRecordTypes.SCHOOL_PERIOD]}
            onChange={toggleRecordType(AnnualPlanRecordTypes.SCHOOL_PERIOD)}
          />
        }
        {...getFilterItemProps(AnnualPlanRecordTypes.SCHOOL_PERIOD)}
      />

      {hasEventsPermissions && (
        <>
          <FormControlLabel
            label={$t({ id: 'annualPlanner-Records-Holidays-Title' })}
            control={
              <Checkbox
                sx={(theme) => ({
                  '&.Mui-checked .svg-icon circle': {
                    fill: theme.palette.common.green1,
                  },
                })}
                checked={recordTypesMap[AnnualPlanRecordTypes.HOLIDAY]}
                onChange={toggleRecordType(AnnualPlanRecordTypes.HOLIDAY)}
              />
            }
            {...getFilterItemProps(AnnualPlanRecordTypes.HOLIDAY)}
          />

          <FormControlLabel
            label={$t({ id: 'annualPlanner-Records-Events-Title' })}
            control={
              <Checkbox
                sx={(theme) => ({
                  '&.Mui-checked .svg-icon circle': {
                    fill: theme.palette.common.moss1,
                  },
                })}
                checked={recordTypesMap[AnnualPlanRecordTypes.EVENT]}
                onChange={toggleRecordType(AnnualPlanRecordTypes.EVENT)}
              />
            }
            {...getFilterItemProps(AnnualPlanRecordTypes.EVENT)}
          />
        </>
      )}

      {hasAssessmentsPermissions && (
        <>
          <FormControlLabel
            label={$t({ id: 'annualPlanner-Records-Assessments-Title' })}
            control={
              <Checkbox
                sx={(theme) => ({
                  '&.Mui-checked .svg-icon circle': {
                    fill: theme.palette.common.dijon1,
                  },
                })}
                checked={recordTypesMap[AnnualPlanRecordTypes.ASSESSMENT]}
                onChange={toggleRecordType(AnnualPlanRecordTypes.ASSESSMENT)}
              />
            }
            {...getFilterItemProps(AnnualPlanRecordTypes.ASSESSMENT)}
          />

          <FormControlLabel
            label={$t({ id: 'annualPlanner-Records-Reports-Title' })}
            control={
              <Checkbox
                sx={(theme) => ({
                  '&.Mui-checked .svg-icon circle': {
                    fill: theme.palette.common.wine1,
                  },
                })}
                checked={recordTypesMap[AnnualPlanRecordTypes.REPORT]}
                onChange={toggleRecordType(AnnualPlanRecordTypes.REPORT)}
              />
            }
            {...getFilterItemProps(AnnualPlanRecordTypes.REPORT)}
          />
        </>
      )}

      {/* TODO: uncomment after release due https://schooly.atlassian.net/browse/TR-5534 */}
      {/*<Stack flex="1 1 auto" alignItems="flex-end" justifyContent="center">*/}
      {/*  <Tooltip title={$t({ id: 'annualPlanner-SchoolDays-hint' })}>*/}
      {/*    <Typography variant="h3">*/}
      {/*      <FormattedMessage*/}
      {/*        id="annualPlanner-SchoolDays"*/}
      {/*        values={{ count: data?.school_days ?? 0 }}*/}
      {/*      />*/}
      {/*    </Typography>*/}
      {/*  </Tooltip>*/}
      {/*</Stack>*/}
    </Stack>
  );
};
