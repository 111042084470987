export type Placement = ('top' | 'right' | 'bottom' | 'left')[];

export const getPlacementClassNames = (placement?: Placement) => {
  const defaultClassName = 'bottom';

  if (!placement) {
    return defaultClassName;
  }

  const compare = (place: Placement) => place.every((p) => placement.includes(p));

  if (compare(['top', 'right'])) {
    return 'top-right';
  }

  if (compare(['top', 'left'])) {
    return 'top-left';
  }

  if (compare(['bottom', 'right'])) {
    return 'bottom-right';
  }

  if (compare(['bottom', 'left'])) {
    return 'bottom-left';
  }

  if (compare(['top'])) {
    return 'top';
  }

  if (compare(['bottom'])) {
    return defaultClassName;
  }

  if (compare(['left'])) {
    return 'left';
  }

  if (compare(['right'])) {
    return 'right';
  }

  return defaultClassName;
};
