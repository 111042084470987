import {
  Backdrop,
  Box,
  ClickAwayListener,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { ConductType } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import { ChevronDownSmallIcon, DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { FC } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import { FiltersDropdownButton } from '../FiltersDropdownButton';

type ConductTypeDropdownProps = {
  label: string;
  options: ConductType[];
  selectedOption?: ConductType;
  onSelectOption: (v: ConductType) => void;
} & Partial<TooltipProps>;

export const ConductTypeDropdown: FC<ConductTypeDropdownProps> = ({
  label,
  options,
  selectedOption,
  onSelectOption,
  ...tooltipProps
}) => {
  const [opened, open, close] = useFlag();

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 250,
                  maxWidth: 250,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0.5, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <ExpandedSelect
                hasSelectedValue={!!selectedOption}
                renderContent={() => (
                  <>
                    {options.map((option) => (
                      <Stack
                        onClick={() => {
                          onSelectOption(option);
                          close();
                        }}
                        flexDirection="row"
                        alignItems="center"
                        key={option.id}
                        px={1}
                        gap={2}
                        py={0.5}
                        sx={(theme) => ({
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: theme.palette.background.default,
                          },
                        })}
                      >
                        <TypographyWithOverflowHint
                          maxWidth={200}
                          variant="h3"
                          color="primary.main"
                        >
                          {option.name}
                        </TypographyWithOverflowHint>
                        <Box
                          sx={{
                            visibility: selectedOption === option ? 'visible' : 'hidden',
                            fontSize: 14,
                          }}
                        >
                          <DoneIcon />
                        </Box>
                      </Stack>
                    ))}
                  </>
                )}
                onClose={close}
                onClear={close}
              >
                {selectedOption && (
                  <TypographyWithOverflowHint variant="h3" maxWidth={230}>
                    {selectedOption.name}
                  </TypographyWithOverflowHint>
                )}
              </ExpandedSelect>
            }
            {...tooltipProps}
          >
            <Box
              flexDirection="row"
              sx={{
                cursor: 'pointer',
                position: 'relative',
                zIndex: (theme) => (opened ? theme.zIndex.drawer + 2 : undefined),
              }}
              alignItems="center"
              onClick={open}
            >
              {!opened ? (
                <Stack
                  flexDirection="row"
                  gap={0.5}
                  onClick={open}
                  sx={{ cursor: 'pointer' }}
                  alignItems="center"
                >
                  <Typography variant="h3" color="common.grey3">
                    {label}
                    {selectedOption ? ':' : ''}
                  </Typography>
                  {selectedOption && (
                    <TypographyWithOverflowHint maxWidth={200} variant="h3" onClick={open}>
                      {selectedOption.name}
                    </TypographyWithOverflowHint>
                  )}
                  <ChevronDownSmallIcon color="black" />
                </Stack>
              ) : (
                <FiltersDropdownButton
                  onClick={(e) => {
                    e.stopPropagation();
                    close();
                  }}
                >
                  {label}
                </FiltersDropdownButton>
              )}
            </Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};
