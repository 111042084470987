import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.15785 3.54412C3.70306 2.10011 5.15452 1.06874 6.85765 1.06874C8.72232 1.06874 10.2837 2.3047 10.6867 3.96282L8.95597 3.01177C8.68743 2.8642 8.34406 2.95177 8.18902 3.20736C8.03398 3.46294 8.12599 3.78976 8.39452 3.93732L11.145 5.44874C11.4136 5.5963 11.757 5.50873 11.912 5.25315L13.5 2.63529C13.6551 2.3797 13.563 2.05289 13.2945 1.90533C13.026 1.75776 12.6826 1.84533 12.5276 2.10092L11.7071 3.45346C11.0921 1.45751 9.15369 0 6.85765 0C4.66591 0 2.80144 1.32783 2.10112 3.18269C1.99625 3.46043 2.1478 3.76648 2.43961 3.86629C2.73142 3.96609 3.05299 3.82185 3.15785 3.54412Z"
      fill="white"
    />
    <path
      d="M11.4242 16.3177C10.8844 17.7712 9.42785 18.902 7.71752 18.902C5.86035 18.902 4.30405 17.5853 3.89337 15.9373L5.61937 16.8857C5.8879 17.0333 6.23128 16.9457 6.38632 16.6901C6.54136 16.4345 6.44935 16.1077 6.18081 15.9602L3.43029 14.4487C3.16176 14.3012 2.81838 14.3887 2.66334 14.6443L1.07532 17.2622C0.920285 17.5178 1.01229 17.8446 1.28083 17.9921C1.54937 18.1397 1.89274 18.0521 2.04778 17.7965L2.8717 16.4383C3.49069 18.4283 5.42597 20 7.71752 20C9.91852 20 11.7895 18.541 12.4829 16.6738C12.5862 16.3956 12.433 16.0903 12.1406 15.9919C11.8483 15.8936 11.5275 16.0394 11.4242 16.3177Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 7.5H1V12.5H13V7.5ZM1 6.5C0.447715 6.5 0 6.94772 0 7.5V12.5C0 13.0523 0.447715 13.5 1 13.5H13C13.5523 13.5 14 13.0523 14 12.5V7.5C14 6.94772 13.5523 6.5 13 6.5H1Z"
      fill="white"
    />
    <path
      d="M3 10C3 10.2761 2.77614 10.5 2.5 10.5C2.22386 10.5 2 10.2761 2 10C2 9.72386 2.22386 9.5 2.5 9.5C2.77614 9.5 3 9.72386 3 10Z"
      fill="white"
    />
  </svg>
);
