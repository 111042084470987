import { Stack } from '@mui/material';
import { DropdownIcon } from '@schooly/style';
import moment from 'moment';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import AvailableCriteriaList from '../../../../components/common/MessageModal/AvailableCriteria';
import {
  Dropdown,
  DropdownOption,
} from '../../../../components/uikit-components/Dropdown/Dropdown';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { DEFAULT_DATE_FORMAT } from '../../../../config';
import { StaffSelectBy } from '../../../../context/messages/MessageContext';
import { useMessage } from '../../../../context/messages/useMessage';
import { MessagesCreateModalRecipientsRightSidebarIndividual } from './MessagesCreateModalRecipientsRightSidebarIndividual';
import { MessagesCreateModalRecipientsRightSidebarIndividualStudent } from './MessagesCreateModalRecipientsRightSidebarIndividualStudent';
import { MessagesCreateModalRecipientsRightSidebarContent } from './ModalCreateModalRecipients.styled';

export const MessagesCreateModalRecipientsStaffRightSidebar: FC = () => {
  const { formatMessage } = useIntl();
  const { staffSelectBy, criteriaDate, staff, staffCriteria, staffByIndividualStudent, actions } =
    useMessage();

  const options = useMemo(
    () => [
      {
        value: StaffSelectBy.StaffCriteria,
        label: formatMessage({ id: 'messages-SelectByStaffCriteria' }),
      },
      {
        value: StaffSelectBy.IndividualStaff,
        label: formatMessage({ id: 'messages-SelectIndividualStaff' }),
      },
      {
        value: StaffSelectBy.IndividualStudent,
        label: formatMessage({ id: 'messages-SelectByIndividualStudent' }),
      },
    ],
    [formatMessage],
  );

  const handleSelectByChange = useCallback(
    (option?: DropdownOption<StaffSelectBy>) => {
      if (option) {
        actions.setStaffSelectBy(option.value);
      }
    },
    [actions],
  );

  const renderContent = () => {
    switch (staffSelectBy) {
      case StaffSelectBy.StaffCriteria:
        return (
          <AvailableCriteriaList
            userType="staff"
            onCriteriaClick={actions.addStaffCriteria}
            onGroupCriteriaAddClick={actions.addStaffCriteriaMultiple}
            selectedCriteria={staffCriteria}
            singleDate={criteriaDate ?? moment().format(DEFAULT_DATE_FORMAT)}
            fetchGroups={actions.fetchGroups}
          />
        );
      case StaffSelectBy.IndividualStaff:
        return (
          <MessagesCreateModalRecipientsRightSidebarIndividual
            userType="staff"
            selectedUsers={staff}
            onAdd={actions.addStaff}
          />
        );
      case StaffSelectBy.IndividualStudent:
        return (
          <MessagesCreateModalRecipientsRightSidebarIndividualStudent
            selectedUsers={staffByIndividualStudent}
            onAdd={actions.addStaffByIndividualStudent}
          />
        );
    }

    return null;
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <ModalHeader
        title={formatMessage({ id: 'messages-Staff' })}
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        })}
        active
      />
      <MessagesCreateModalRecipientsRightSidebarContent active>
        <Stack sx={{ height: '100%' }}>
          <Dropdown
            options={options}
            value={staffSelectBy}
            icon={<DropdownIcon />}
            iconPlacement="end"
            canClean={false}
            onChange={handleSelectByChange}
          />
          {renderContent()}
        </Stack>
      </MessagesCreateModalRecipientsRightSidebarContent>
    </Stack>
  );
};
