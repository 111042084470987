import { Box, Icon, Stack } from '@mui/material';
import { Company } from '@schooly/api';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { FC } from 'react';

type CompanySelectRowProps = {
  isSelected: boolean;
  onClick: (v: string) => void;
  company: Company;
};

export const CompanySelectRow: FC<CompanySelectRowProps> = ({ isSelected, company, onClick }) => {
  return (
    <Stack
      onClick={() => onClick(company.id)}
      flexDirection="row"
      alignItems="center"
      px={0.5}
      gap={2}
      mb={0.5}
      sx={(theme) => ({
        borderRadius: theme.spacing(0.5),
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
          '& .MuiTypography-h3': {
            color: theme.palette.primary.main,
          },
        },
      })}
    >
      <Box flex={1}>
        <TypographyWithOverflowHint
          color={!isSelected ? 'common.grey2' : undefined}
          variant="h3"
          noWrap
        >
          {company.name}
        </TypographyWithOverflowHint>
      </Box>

      <Icon sx={{ visibility: isSelected ? 'visible' : 'hidden', color: 'primary.main' }}>
        <DoneIcon />
      </Icon>
    </Stack>
  );
};
