import { FilterKeys, SelectOption } from '@schooly/api';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { GroupArrangeBySection } from '../../../context/filters/scheme';
import { useFilters } from '../../../context/filters/useFilters';
import { getUserTypeTextId } from '../../../helpers/misc';
import ArrangeBySelect from './ArrangeBySelect';

interface IProps {
  isFocusable?: boolean;
}

const ArrangeByControl: React.FC<IProps> = ({ isFocusable }) => {
  const { filters, arrangeByOptions, groupArrangeBy, setDraftArrangeBy } = useFilters();

  const getGroupSectionTitleId = useCallback((section: GroupArrangeBySection) => {
    switch (section) {
      case 'staff':
        return getUserTypeTextId('staff');
      case 'student':
        return getUserTypeTextId('student');
      default:
        return undefined;
    }
  }, []);

  const handleGroupChange = useCallback(
    (section: GroupArrangeBySection, option: SelectOption<FilterKeys>) => {
      setDraftArrangeBy(option.value);
    },
    [setDraftArrangeBy],
  );

  if (!arrangeByOptions) {
    return null;
  }

  return (
    <div className="filter-control">
      <div className="filter-control-name">
        <FormattedMessage id="filter-ArrangeBy" />
      </div>

      <ArrangeBySelect
        getOptionDisabledText={() => ''} // TODO
        value={filters.draftArrangeBy}
        groupValue={groupArrangeBy}
        onChange={setDraftArrangeBy}
        onGroupChange={handleGroupChange}
        onReset={setDraftArrangeBy}
        options={arrangeByOptions}
        getGroupSectionTitleId={getGroupSectionTitleId}
        isFocusable={isFocusable}
      />
    </div>
  );
};

export default ArrangeByControl;
