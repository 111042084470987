import { FilterValue, SelectOption, UserType } from '@schooly/api';
import { SchoolRelation } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, { useMemo, useRef } from 'react';

import { getSchoolUserId } from '../../../helpers/school';
import { $UIKitPrefix } from '../../../styles/variables';
import buildClassName from '../../../utils/buildClassName';
import { TagAvatar } from '../../uikit/TagAvatar/TagAvatar';

import './FilterSchoolRelationTags.scss';

export interface FilterSchoolRelationTagsBaseProps {
  values?: Partial<{
    staff: FilterValue[];
    student: FilterValue[];
  }>;
  type?: UserType;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, selectOption?: SelectOption) => void;
}

export interface FilterSchoolRelationTagsProps extends FilterSchoolRelationTagsBaseProps {
  results: SchoolRelation[];
  savedSelectedOptions: SelectOption<FilterValue>[];
}

const $class = `${$UIKitPrefix}FilterSchoolRelationTags`;

export const FilterSchoolRelationTags: React.FC<FilterSchoolRelationTagsProps> = ({
  results,
  savedSelectedOptions,
  values,
  onClick,
}) => {
  const { currentStaff } = useAuth();
  const ref = useRef<HTMLDivElement>(null);

  const people = useMemo(() => {
    const resultsIds = results.map((r) => r.relation_id);

    const savedOptionsPeople = savedSelectedOptions
      .map((o) => o.item)
      .filter((o) => !resultsIds.includes(o.relation_id));

    const selectedPeople = [
      ...results.filter((r) => values?.student?.includes(r.relation_id)),
      ...savedOptionsPeople,
    ];

    const others = selectedPeople.filter((p) => p.relation_id !== currentStaff?.relation_id);
    const me = selectedPeople.find((p) => p.relation_id === currentStaff?.relation_id);
    const defaultMe = currentStaff;

    if (!me && others.length) return others;
    if (!others.length) return [defaultMe];

    return [...others, me];
  }, [currentStaff, results, savedSelectedOptions, values?.student]);

  return (
    <div
      className={$class}
      ref={ref}
      // todo: fix animation later
      // onMouseEnter={() => {
      //   if (!ref.current) return;

      //   ref.current.style.cssText = `position: absolute;
      //   top: ${(ref.current as any).top + window.scrollY}px;
      //   left: ${(ref.current as any).offsetLeft}px;
      //   top: ${(ref.current as any).offsetTop}px;
      //   `;
      // }}
      // onMouseLeave={() => {
      //   if (!ref.current) return;
      //   ref.current.style.cssText = `position: static;`;
      // }}
    >
      {people.map((p) => {
        const isMe = p && getSchoolUserId(p) === currentStaff?.relation_id;
        return (
          <TagAvatar
            key={p?.relation_id}
            isMe={isMe}
            onClick={onClick}
            className={buildClassName(
              `${$class}__tag`,
              isMe ? `${$class}__tag_me` : `${$class}__tag_common`,
            )}
            user={p}
          />
        );
      })}
    </div>
  );
};
