import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { LegalEntity, useGetLegalEntitiesListQuery } from '@schooly/api';
import { PlusIcon, SimpleButton } from '@schooly/style';
import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  LegalEntityEntriesContent,
  pathToNewLegalEntity,
  SchoolLegalEntitiesList,
} from './SchoolLegalEntitiesList';

type SchoolLegalEntitiesContentProps = {
  schoolId: string;
  archivedCount: number;
};

export const SchoolLegalEntitiesContent: FC<SchoolLegalEntitiesContentProps> = ({
  schoolId,
  archivedCount,
}) => {
  const navigate = useNavigate();
  const [showArchived, setShowArchived] = useState(false);

  const { data, ...props } = useGetLegalEntitiesListQuery(
    {
      schoolId,
      archived: false,
    },
    { enabled: !!schoolId, refetchOnMount: 'always' },
  );
  const entries = useMemo(
    () => data?.pages.reduce<LegalEntity[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  const { formatMessage } = useIntl();
  const { isLoading } = props;

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center">
            <Typography variant="h2">{formatMessage({ id: 'legalEntities-Title' })}</Typography>
          </Stack>
        }
        action={
          <>
            {!isLoading && archivedCount > 0 ? (
              <FormControlLabel
                sx={(theme) => ({
                  m: theme.spacing(0, 3, 0),
                  '&:hover': {
                    color: 'primary.main',
                    ' .MuiSwitch-track, .Mui-checked+.MuiSwitch-track': { opacity: 1 },
                  },
                })}
                disabled={isLoading}
                control={
                  <Switch checked={showArchived} onChange={(_, value) => setShowArchived(value)} />
                }
                label={formatMessage({ id: 'action-ShowArchived' })}
              />
            ) : null}

            <SimpleButton startIcon={<PlusIcon />} onClick={() => navigate(pathToNewLegalEntity)}>
              {formatMessage({ id: 'legalEntities-AddLegalEntity' })}
            </SimpleButton>
          </>
        }
      />

      <CardContent>
        <LegalEntityEntriesContent entries={entries} {...props} />

        {!isLoading && showArchived && (
          <Stack pt={2.5}>
            <SchoolLegalEntitiesList archived schoolId={schoolId}>
              <Typography mt={3} mb={1} variant="h4" color="text.secondary">
                {formatMessage({ id: 'filter-Archived' })}
              </Typography>
            </SchoolLegalEntitiesList>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
