import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6H17V16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16V4C3 2.89543 3.89543 2 5 2H13V5C13 5.55228 13.4477 6 14 6ZM15 2C16.1046 2 17 2.89543 17 4V5H14V2H15ZM8.58067 12.1683H11.4193L12.0694 14H13L10.3952 7H10.1988H9.60016L7 14H7.93531L8.58067 12.1683ZM9.99766 8.14423L11.1528 11.4135H8.84723L9.99766 8.14423Z"
      fill="#24275B"
    />
  </svg>
);
