import { FieldError, FieldErrors, FieldValues, Path } from 'react-hook-form-lts';
import { FieldPath } from 'react-hook-form-lts/dist/types';
import { UseControllerProps } from 'react-hook-form-lts/dist/types/controller';
import { IntlShape } from 'react-intl';

export const getControllerErrorText = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  error?: FieldErrors<TFieldValues>[Path<TFieldValues>] | FieldError,
  rules?: UseControllerProps<TFieldValues, TName>['rules'],
  $t?: IntlShape['$t'],
): string | undefined => {
  if (!error) {
    return undefined;
  }

  if (error.message) {
    return error.message as string;
  }

  if ($t) {
    // TODO: implement other types (see obsolete `getInputErrorText`)
    switch (error.type) {
      case 'required':
        return $t({
          id: 'input-ErrorRequired',
        });
      case 'min':
        return $t(
          {
            id: 'input-ErrorMinValue',
          },
          {
            value:
              typeof rules?.min === 'object' && 'value' in rules?.min
                ? rules?.min?.value ?? ''
                : rules?.min ?? '',
          },
        );
      case 'max':
        return $t(
          {
            id: 'input-ErrorMaxValue',
          },
          {
            value:
              typeof rules?.max === 'object' && 'value' in rules?.max
                ? rules?.max?.value ?? ''
                : rules?.max ?? '',
          },
        );
      case 'minLength':
        return $t(
          {
            id: 'input-ErrorMinLengthValue',
          },
          {
            value:
              typeof rules?.minLength === 'object' && 'value' in rules?.minLength
                ? rules?.minLength?.value ?? ''
                : rules?.minLength ?? '',
          },
        );
      case 'maxLength':
        return $t(
          {
            id: 'input-ErrorMaxLengthValue',
          },
          {
            value:
              typeof rules?.maxLength === 'object' && 'value' in rules?.maxLength
                ? rules?.maxLength?.value ?? ''
                : rules?.maxLength ?? '',
          },
        );
      case 'email':
        return $t({
          id: 'input-ErrorInvalidEmail',
        });
    }
  }

  return;
};
