import { Stack, Tooltip } from '@mui/material';
import { FilterKeys, FilterValue } from '@schooly/api';
import { DATE_FORMAT_MOMENT as DATE_FORMAT } from '@schooly/constants';
import { ArchiveIcon, CalendarIcon, Spin, StaffIcon } from '@schooly/style';
import moment from 'moment';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AppliedGroupFilters } from '../../../context/filters/scheme';
import { useFilters } from '../../../context/filters/useFilters';
import buildClassName from '../../../utils/buildClassName';
import Tag from '../../ui/Tag';
import { FilterStaffTags } from '../../uikit-components/FilterSchoolRelationTags/FilterStaffTags';
import { FilterStudentTags } from '../../uikit-components/FilterSchoolRelationTags/FilterStudentTags';

export interface FilterInlineTagsProps {
  filters: AppliedGroupFilters[];
  max?: number;
  isBig?: boolean;
  className?: string;
  onTagClick?: (key?: FilterKeys) => void;
}

export const FilterInlineTags: FC<FilterInlineTagsProps> = ({
  filters,
  max = 2,
  isBig,
  className,
  onTagClick,
}) => {
  const { $t } = useIntl();

  const {
    filters: { appliedExclude },
    filterType,
    appliedArrangeByOption,
    getDefaultFiltersByType,
    getGroupFilterLabelByValue,
  } = useFilters();

  const handleTagClick = useCallback((key?: FilterKeys) => () => onTagClick?.(key), [onTagClick]);

  const renderTag = useCallback(
    ({ key, values }: AppliedGroupFilters, data: AppliedGroupFilters[]) => {
      const prepareTag = (value: FilterValue, isStaff?: boolean) => {
        const option = getGroupFilterLabelByValue(key, value);

        const label = (
          <>
            {isStaff && <StaffIcon />}
            {option?.labelTextId ? (
              <FormattedMessage id={option?.labelTextId} />
            ) : (
              option?.label ?? <Spin />
            )}
          </>
        );

        return (
          <React.Fragment key={`${key}-${isStaff ? 'staff' : 'student'}-${value}`}>
            <Tag isBig={isBig} onClick={handleTagClick(key)}>
              {option?.archived ? (
                <Tooltip title={$t({ id: `schoolProperty-Archived-${key}` })}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={0.5}
                    sx={{ '& svg': { flex: '0 0 auto' } }}
                  >
                    <ArchiveIcon />
                    {label}
                  </Stack>
                </Tooltip>
              ) : (
                label
              )}
            </Tag>
          </React.Fragment>
        );
      };

      switch (key) {
        case FilterKeys.Date:
          return [
            <Tag className="Tag__date" isBig={isBig} onClick={handleTagClick(key)}>
              <CalendarIcon className="user-type-icon mr-1" />
              {!values.student?.[0] ? (
                <FormattedMessage id="datepicker-Today" />
              ) : (
                moment(values.student?.[0] || undefined).format(DATE_FORMAT)
              )}
            </Tag>,
          ];
        case FilterKeys.Staff:
        case FilterKeys.Creator:
          return [<FilterStaffTags onClick={handleTagClick(key)} values={values} />];
        case FilterKeys.Student:
          return [<FilterStudentTags onClick={handleTagClick(key)} values={values} />];
        case FilterKeys.Subject: {
          const isOnlyTutorGroup = data.some((filter) => filter.key === FilterKeys.OnlyTutorGroups);
          return isOnlyTutorGroup ? [] : values.student?.map((value) => prepareTag(value)) ?? [];
        }
        case FilterKeys.OnlyTutorGroups:
          return [
            <Tag isBig={isBig} onClick={handleTagClick(key)}>
              <FormattedMessage id="groups-OnlyTutorGroups" />
            </Tag>,
          ];
        default: {
          return [
            ...(values.student?.map((value) => prepareTag(value)) ?? []),
            ...(values.staff?.map((value) => prepareTag(value, true)) ?? []),
          ];
        }
      }
    },
    [$t, getGroupFilterLabelByValue, handleTagClick, isBig],
  );

  const prepareFilter = useCallback(
    (filters: FilterInlineTagsProps['filters']) => {
      return filters.map((filter) => ({
        ...filter,
        [filter.key]:
          filter.key === FilterKeys.Date
            ? getDefaultFiltersByType(filterType, true).filters?.[FilterKeys.Date] ?? []
            : filter.values,
      }));
    },
    [filterType, getDefaultFiltersByType],
  );

  const data = useMemo(() => prepareFilter(filters)!, [filters, prepareFilter]);

  const tags = data.reduce((prev, item, index, data) => {
    prev.push(...renderTag(item, data));
    return prev;
  }, [] as JSX.Element[]);

  // Display only first 3 tags for people lists and 1 tag for groups
  const firstTags = tags.splice(0, appliedArrangeByOption?.option ? max - 1 : max);

  const isExcluded = firstTags.length > 0 && appliedExclude;

  return (
    <div
      className={buildClassName(
        'HeaderFilter__bottom-inline-content',
        !isBig && 'HeaderFilter__bottom-inline-content_small',
        isExcluded && 'HeaderFilter__bottom-inline-content_small-not',
        className,
      )}
    >
      {isExcluded && (
        <div className="HeaderFilter__bottom-not-in">
          <FormattedMessage id="filter-NotIn" />
        </div>
      )}
      {firstTags}
      {tags.length > 0 && (
        <Tag isBig={isBig} className="Tag__count" onClick={handleTagClick()}>
          +{tags.length}
        </Tag>
      )}

      {appliedArrangeByOption?.option && (
        <div className="arrange-by-tag">
          <span className="mr-1">
            <FormattedMessage id="filter-ArrangeBy" />:
          </span>
          {appliedArrangeByOption.section === 'staff' && (
            <StaffIcon className="user-type-icon mr-1" />
          )}
          <span>{appliedArrangeByOption.option.label}</span>
        </div>
      )}
    </div>
  );
};
