import { DATE_FORMAT } from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';

const ZERO_VALUE_REGEXP = /^0+(?!$)/g;

interface GetSchoolPeriodLabelProps {
  startDate: string;
  endDate: string;
}

export function getSchoolPeriodLabel({ startDate, endDate }: GetSchoolPeriodLabelProps) {
  const start = format(newDateTimezoneOffset(startDate), DATE_FORMAT);
  const end = format(newDateTimezoneOffset(endDate), DATE_FORMAT);

  return `${start.replace(ZERO_VALUE_REGEXP, '')} - ${end.replace(ZERO_VALUE_REGEXP, '')}`;
}
