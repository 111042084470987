import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RequestsState {
  pending: boolean;
}

const initialState: RequestsState = {
  pending: false,
};

const slice = createSlice({
  name: 'requestsState',
  initialState,
  reducers: {
    setPending(state, action: PayloadAction<boolean>) {
      state.pending = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice;
