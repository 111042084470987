import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L11.75 7.18934L11.75 4C11.75 3.58579 11.4142 3.25 11 3.25C10.5858 3.25 10.25 3.58579 10.25 4L10.25 9C10.25 9.41421 10.5858 9.75 11 9.75H16C16.4142 9.75 16.75 9.41421 16.75 9C16.75 8.58579 16.4142 8.25 16 8.25L12.8107 8.25L18.5303 2.53033ZM1.46967 17.4697C1.17678 17.7626 1.17678 18.2374 1.46967 18.5303C1.76256 18.8232 2.23744 18.8232 2.53033 18.5303L8.25 12.8107L8.25 16C8.25 16.4142 8.58579 16.75 9 16.75C9.41421 16.75 9.75 16.4142 9.75 16L9.75 11C9.75 10.5858 9.41421 10.25 9 10.25L4 10.25C3.58579 10.25 3.25 10.5858 3.25 11C3.25 11.4142 3.58579 11.75 4 11.75L7.18934 11.75L1.46967 17.4697Z"
      fill="#B3BBCB"
    />
  </svg>
);
