import { Button, IconButton, Stack } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect, FarDateWarning } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { DeleteIcon, PlusIcon } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import FormSelect2 from '../../../components/ui/Input/FormSelect2';
import { ApplyStudentsForm } from '../../../context/applications/ApplicationsContext';
import { getPropertiesByType } from '../../../helpers/school';
import { useSchool } from '../../../hooks/useSchool';

interface ApplicationAddStudentStatusProps {
  index: number;
  errors: Record<string, any>[];
}

const getEmptyStatus = () => ({
  school_property_id: '',
  applies_from: '',
  applies_to: '',
});

export const ApplicationAddStudentStatus: FC<ApplicationAddStudentStatusProps> = ({
  index,
  errors,
}) => {
  const { control } = useFormContext<ApplyStudentsForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `children.${index}.statuses`,
    rules: {
      validate: (arr) => {
        return !arr.some((s, statusIndex) => !!errors?.length && errors?.[statusIndex]);
      },
    },
  });

  const { $t } = useIntl();

  const { schoolId } = useSchool();

  const { activePropertiesMap } = useSchoolProperties({
    schoolId: schoolId!,
    userType: SchoolUserRole.Student,
  });

  const options = useMemo(
    () =>
      getPropertiesByType(activePropertiesMap.status, SchoolPropertyType.Status).map((p) => ({
        value: p.id,
        label: p.name,
      })),
    [activePropertiesMap.status],
  );

  useEffect(() => {
    if (!fields.length) {
      append(getEmptyStatus());
    }
  }, [append, fields.length]);

  return (
    <Stack key={index} alignItems="flex-start" gap={2} sx={{ height: '100%' }}>
      {fields?.map((field, statusIndex) => {
        const required = statusIndex === 0;
        const error = !!errors?.length && errors?.[statusIndex]?.['applies_from'];

        return (
          <Stack
            key={field.id}
            direction="row"
            alignItems="flex-start"
            gap={1}
            sx={{ width: '100%' }}
          >
            <FormSelect2
              name={`children.${index}.statuses.${statusIndex}.school_property_id`}
              labelTextId="schoolProperty-Status"
              options={options ?? []}
              rules={{ required }}
            />
            <Stack width="100%">
              <Controller
                control={control}
                name={`children.${index}.statuses.${statusIndex}.applies_from`}
                rules={{ required }}
                render={({ field, fieldState }) => {
                  return (
                    <DateSelect
                      ref={field.ref}
                      onSetDate={(date) => {
                        field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                      }}
                      date={field.value}
                      placeholder={$t({ id: 'schoolProperty-Status-AppliesForm' })}
                      requiredLabel={required ? 'required' : 'optional'}
                      error={fieldState.error || error}
                      onClear={() => field.onChange('')}
                      renderRightIcon={() => <FarDateWarning date={field.value} />}
                    />
                  );
                }}
              />
            </Stack>
            {fields.length > 1 && (
              <IconButton
                sx={{ paddingTop: (theme) => theme.spacing(1.5) }}
                inverse
                onClick={() => {
                  remove(statusIndex);
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        );
      })}

      <Button
        variant="outlined"
        startIcon={<PlusIcon />}
        onClick={() => {
          append(getEmptyStatus(), {
            shouldFocus: false,
          });
        }}
      >
        <FormattedMessage id="schoolProperty-AddStatus" />
      </Button>
    </Stack>
  );
};
