import { Button, styled } from '@mui/material';

export interface SimpleButtonStyledProps {
  iconsOnHover?: boolean;
}

export const SimpleButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'iconsOnHover',
})<SimpleButtonStyledProps>(({ theme, iconsOnHover, inverse, color }) => {
  const primaryColor = color === 'inherit' ? 'inherit' : theme.palette.primary.main;

  return {
    ...theme.typography.h3,
    display: 'inline-flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    minWidth: 0,
    background: 'none',
    border: 0,
    borderRadius: 0,
    color: inverse ? theme.palette.text.secondary : primaryColor,

    transition: theme.transitions.create(['color', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),

    '&:hover': {
      color: inverse ? theme.palette.text.primary : theme.palette.primary.light,
      background: 'none',

      '& .MuiButton-startIcon, & .MuiButton-endIcon': {
        opacity: iconsOnHover ? 1 : undefined,
      },
    },

    '&:active': {
      padding: 0,
      border: 0,
      color: theme.palette.text.primary,
      boxShadow: 'none',
    },

    '&.MuiButton-sizeSmall': {
      padding: 0,
    },

    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
      opacity: iconsOnHover ? 0 : undefined,
      margin: 0,
    },
  };
});

SimpleButtonStyled.defaultProps = {
  variant: 'text',
};
