import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { SchoolYear } from '@schooly/api';
import {
  ArrowLeftIcon,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  TagSelect,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isBefore, startOfToday } from 'date-fns';
import { FC, PropsWithChildren, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Period } from './useSchoolPeriods';
import { getSchoolPeriodLabel } from './utils';

type SchoolPeriodsListProps = {
  onSetPeriod: (period: Period) => void;
  defaultSchoolYear?: SchoolYear;
  schoolYears: SchoolYear[];
  onGoBack: () => void;
  isLoading?: boolean;
  currentPeriod?: Period | null;
  onPeriodOver?: (p: Period) => void;
  onPeriodLeave?: () => void;
  withoutPeriodLabel: (yearId: string) => ReactNode;
  showActualPeriods?: boolean;
};

const SIDEBAR_WIDTH = 130;
export const SchoolPeriodsList: FC<SchoolPeriodsListProps> = ({
  onSetPeriod,
  defaultSchoolYear,
  onGoBack,
  schoolYears: years,
  isLoading,
  currentPeriod,
  onPeriodOver,
  onPeriodLeave,
  withoutPeriodLabel,
  showActualPeriods,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentYear, setCurrentYear] = useState<SchoolYear | undefined>(defaultSchoolYear);

  const withoutPeriods = useMemo(() => {
    if (!currentYear || !currentYear.period_groups) return false;

    const today = startOfToday();

    if (isBefore(newDateTimezoneOffset(currentYear.end), today)) {
      return false;
    }

    return (
      currentYear.period_groups.length <= 1 &&
      currentYear.period_groups.some((p) => p.periods.length <= 1)
    );
  }, [currentYear]);

  const { filteredYear, schoolYears } = useMemo(() => {
    return {
      schoolYears: showActualPeriods
        ? years.filter((y) => !isBefore(newDateTimezoneOffset(y.end), startOfToday()))
        : years,
      filteredYear:
        showActualPeriods && currentYear
          ? {
              ...currentYear,

              period_groups: currentYear.period_groups?.map((g) => ({
                ...g,
                periods: g.periods.filter(
                  (p) => !isBefore(newDateTimezoneOffset(p.date_to), startOfToday()),
                ),
              })),
            }
          : currentYear,
    };
  }, [currentYear, showActualPeriods, years]);

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        overflow: 'hidden',
        width: '100%',
        maxHeight: 400,
      }}
    >
      <Box pt={2} pl={2}>
        <IconButton inverse onClick={onGoBack}>
          <ArrowLeftIcon />
        </IconButton>
      </Box>
      <Stack
        py={2}
        sx={() => ({
          minWidth: SIDEBAR_WIDTH,
          overflow: 'scroll',
          cursor: 'pointer',
        })}
        ref={containerRef}
        gap={0.5}
      >
        {isLoading ? (
          <Stack gap={1.25} pt={0.25} pb={0.75} px={1}>
            {[...new Array(5)].map((_, i) => (
              <YearSkeleton key={i} />
            ))}
          </Stack>
        ) : (
          schoolYears.map((year) => (
            <RowItem
              key={year.id}
              selected={currentYear?.id === year.id}
              onClick={() => {
                setCurrentYear(year);
              }}
            >
              <GridRowName
                paddingLeft={0.75}
                paddingY={0.5}
                variant="body1"
                color={currentYear?.id === year.id ? 'primary.main' : undefined}
              >
                {year.name}
              </GridRowName>
            </RowItem>
          ))
        )}
      </Stack>
      <Box
        sx={(theme) => ({
          borderLeft: `1px solid ${theme.palette.common.light2}`,
        })}
      />

      <Stack
        sx={{
          overflow: 'scroll',
          flexGrow: 1,
        }}
        gap={0.5}
        py={2}
      >
        {filteredYear?.period_groups?.map((group) => {
          return (
            <>
              {group.periods.map((p) => {
                const isSelected = p.id === currentPeriod?.period.id;
                const period = { yearId: filteredYear.id, yearName: filteredYear.name, period: p };
                return (
                  <RowItem
                    key={p.id}
                    selected={isSelected}
                    onClick={() => {
                      onSetPeriod(period);
                    }}
                  >
                    <GridRowItem
                      onMouseOver={() => onPeriodOver?.(period)}
                      onMouseLeave={onPeriodLeave}
                      noPadding
                      sx={{
                        justifyContent: 'flex-start',
                        paddingLeft: 0.75,
                        paddingY: 0.5,
                        width: '100%',
                        gap: 1,
                        cursor: 'pointer',
                      }}
                    >
                      <TypographyWithOverflowHint
                        color={isSelected ? 'primary.main' : 'common.grey2'}
                        noWrap
                      >
                        {p.name}
                      </TypographyWithOverflowHint>

                      <TagSelect
                        size="small"
                        sx={(theme) => ({
                          cursor: 'pointer',
                          maxHeight: 18,
                          bgcolor: isSelected ? theme.palette.background.paper : undefined,
                        })}
                        label={getSchoolPeriodLabel({ startDate: p.date_from, endDate: p.date_to })}
                      />
                    </GridRowItem>
                  </RowItem>
                );
              })}

              {withoutPeriods && (
                <Stack
                  marginX={1}
                  sx={(theme) => ({
                    borderTop: `1px solid ${theme.palette.common.light2}`,
                  })}
                >
                  <Typography
                    sx={() => ({ pl: 1, pt: 0.5 })}
                    color="text.secondary"
                    whiteSpace="pre-wrap"
                  >
                    <FormattedMessage id="school-schoolPeriods-NoPeriods" />
                    {withoutPeriodLabel(filteredYear.id)}
                  </Typography>
                </Stack>
              )}
            </>
          );
        })}
      </Stack>
    </Stack>
  );
};

interface RowItemProps extends PropsWithChildren {
  selected?: boolean;
  onClick: () => void;
}

export const RowItem = ({ selected, onClick, children }: RowItemProps) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !selected || mounted) {
      return;
    }

    const resizeObserver = new IntersectionObserver(([entry]) => {
      setMounted(true);
      if (!entry?.isIntersecting) {
        ref.current?.scrollIntoView({ block: 'nearest', inline: 'start' });
      }
    });
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [mounted, selected]);

  return (
    <GridRowStyled
      noBorder
      ref={ref}
      sx={(theme) => ({
        marginX: 1.25,
        borderRadius: '6px',
        backgroundColor: selected ? theme.palette.common.lightBg : undefined,
      })}
      onClick={onClick}
    >
      {children}
    </GridRowStyled>
  );
};

const YearSkeleton: FC = () => (
  <Skeleton variant="rectangular" height={20} sx={{ borderRadius: 10 }} />
);
