import { Button, IconButton, Stack } from '@mui/material';
import {
  CrossIcon,
  DoneIcon,
  ErrorSvg,
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface MergeDuplicatesErrorModalProps {
  onClose: () => void;
  open: boolean;
}

export const MergeDuplicatesErrorModal: FC<MergeDuplicatesErrorModalProps> = ({
  open,
  onClose,
}) => {
  const { $t } = useIntl();

  return (
    <ModalConfirm open={open} sx={{ '.MuiPaper-root': { maxWidth: 600 } }}>
      <ModalHeader
        active
        title={$t({ id: 'profile-Duplicates-MergeFail' })}
        multiline
        sx={{ py: 2, borderBottom: 'none' }}
      >
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalContent active justifyContent="center" sx={{ p: 0, pb: 3 }}>
        <Stack alignItems="center">
          <ErrorSvg />
        </Stack>
      </ModalContent>
      <ModalFooter active withBorderTop={false} sx={{ justifyContent: 'flex-end' }}>
        <Button startIcon={<DoneIcon />} onClick={onClose}>
          <FormattedMessage id="action-Okay" />
        </Button>
      </ModalFooter>
    </ModalConfirm>
  );
};
