import { useAuth } from '@schooly/components/authentication';
import { Loading, PlusIcon } from '@schooly/style';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../components/common/AccessDenied';
import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import Header from '../../components/ui/Header';
import { UPDATE_MAIN_LIST } from '../../constants/events';
import { useUserRolesForSchool } from '../../context/userRoles/useUserRolesForSchool';
import useAppLocation from '../../hooks/useAppLocation';
import { useSchool } from '../../hooks/useSchool';
import UserRoleCategoryList from './UserRoleCategoryList';

const UserRoles: React.FC = () => {
  const { userRoles, loadUserRoles, isLoading, error } = useUserRolesForSchool();
  const { permissions } = useAuth();

  const { hasSystemRole } = useSchool();

  const showActionButton = permissions.includes('userrole_manager');
  const showSearch =
    permissions.includes('userrole_manager') || permissions.includes('userrole_viewer');

  const location = useAppLocation();
  const navigate = useNavigate();

  const newUserRole = useCallback(() => {
    navigate('/userroles/new', {
      state: { backgroundLocation: location },
    });
  }, [location, navigate]);

  useEffect(() => {
    document.addEventListener(UPDATE_MAIN_LIST, loadUserRoles);

    return () => {
      document.removeEventListener(UPDATE_MAIN_LIST, loadUserRoles);
    };
  }, [loadUserRoles]);

  useEffect(() => {
    if (typeof hasSystemRole === 'boolean' && !hasSystemRole) {
      navigate('/settings');
    }
  }, [hasSystemRole, navigate]);

  if (isLoading || !userRoles) {
    return <Loading />;
  }

  if (error) {
    return <AccessDenied />;
  }

  const noRoles = !userRoles.available_to_assign.length && !userRoles.not_allowed_to_assign.length;

  return (
    <>
      <Header
        pageTitleTextId="section-UserRoles"
        buttonTextId="userRoles-AddRole"
        showActionButton={showActionButton}
        buttonIcon={<PlusIcon />}
        onButtonClick={newUserRole}
        hasSearch={showSearch}
      />
      {noRoles ? (
        <NoListItemsStub titleText={<FormattedMessage id="userRoles-NoRolesYet" />} />
      ) : (
        <UserRoleCategoryList userRoleList={userRoles} userType="staff" />
      )}
    </>
  );
};

export default UserRoles;
