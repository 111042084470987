import { Box, Icon, Stack, styled, Typography } from '@mui/material';
import {
  DELETE_SCHOOL,
  GET_SCHOOL_QUERY,
  SchoolVerificationStatus,
  useGetSchoolQuery,
  useMutationFromKey,
  useRequestSchoolVerificationMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, EditIcon, PlusIcon, Spin } from '@schooly/style';
import { getUserAddress } from '@schooly/utils/user-helpers';
import { useQueryClient } from '@tanstack/react-query';
import { FC, PropsWithChildren, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

export const SchoolGeneralVerify: FC = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showError } = useNotifications();
  const { schoolId = '' } = useAuth();
  const { mutateState } = useMutationFromKey([DELETE_SCHOOL]);

  const isSchoolDeleted =
    mutateState?.state.status === 'success' &&
    !!mutateState.state.variables &&
    mutateState.state.variables === schoolId;

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    refetchOnMount: 'always',
    enabled: !!schoolId && !isSchoolDeleted,
    refetchInterval: 30 * 1000,
  });

  const requestVerification = useRequestSchoolVerificationMutation();

  const handleAddressClick = () => {
    navigate('/settings/address');
  };
  const handleInfoClick = () => {
    navigate('/settings/info');
  };

  const handleVerifyClick = useCallback(() => {
    requestVerification.mutate(schoolId, {
      onError: showError,
      onSuccess: () => {
        queryClient.setQueryData([GET_SCHOOL_QUERY, schoolId], {
          ...currentSchool,
          verification_status: SchoolVerificationStatus.Pending,
        });
      },
    });
  }, [currentSchool, queryClient, requestVerification, schoolId, showError]);

  if (!currentSchool) return null;

  const { verification, description, verification_status } = currentSchool;

  if (verification) return null;

  const address = getUserAddress(currentSchool);

  const hasInfo = !!description;
  const hasAddress = !!address;

  return (
    <VerifyLayout>
      <Typography variant="h3">{$t({ id: 'school-verifyToLet' })}</Typography>
      <Stack flexDirection="row" alignItems="center">
        <Action
          order={1}
          onClick={handleInfoClick}
          isCompleted={hasInfo}
          disabled={verification_status === SchoolVerificationStatus.Pending}
          label={$t({ id: hasInfo ? 'school-editInfo' : 'school-addInfo' })}
        />
        <Action
          order={2}
          onClick={handleAddressClick}
          isCompleted={hasAddress}
          disabled={verification_status === SchoolVerificationStatus.Pending}
          label={$t({ id: hasAddress ? 'school-editAddress' : 'school-addAddress' })}
        />

        {verification_status === SchoolVerificationStatus.Pending ? (
          <Stack
            sx={(theme) => ({
              height: theme.spacing(3.75),
              justifyContent: 'center',
              alignItems: 'center',
              padding: theme.spacing(0, 1.5),
              borderRadius: theme.spacing(2),
              backgroundColor: theme.palette.warning.superLight,
              border: `1px solid ${theme.palette.warning.main}`,
            })}
          >
            <Typography color="warning.main">{$t({ id: 'school-pendingVerification' })}</Typography>
          </Stack>
        ) : (
          <WithLines showLines={!!hasInfo && !!hasAddress}>
            <RoundButton
              disabled={!hasInfo || !hasAddress || requestVerification.isLoading}
              onClick={handleVerifyClick}
            >
              {$t({ id: 'school-verify' })} {requestVerification.isLoading && <Spin />}
            </RoundButton>
          </WithLines>
        )}
      </Stack>
    </VerifyLayout>
  );
};

type ActionProps = {
  order: number;
  isCompleted: boolean;
  onClick: () => void;
  disabled?: boolean;
  label: string;
};
export const Action: FC<ActionProps> = ({ isCompleted, order, disabled, label, onClick }) => {
  return (
    <>
      <Stack
        onClick={onClick}
        sx={(theme) => ({
          pointerEvents: disabled ? 'none' : undefined,
          overflow: 'hidden',
          cursor: 'pointer',
          transition: 'all .2s ease',
          maxWidth: 300,
          '&:not(:hover)': {
            '.button': {
              opacity: 0,
            },
            maxWidth: theme.spacing(3.75),
          },
        })}
      >
        <Stack
          className="check"
          sx={(theme) => ({
            position: 'absolute',
            pointerEvents: 'none',
            width: theme.spacing(3.75),
            height: theme.spacing(3.75),
            lineHeight: theme.spacing(3.75),
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: 'white',
            borderRadius: '50%',
            border: `1px solid ${
              isCompleted ? theme.palette.success.main : theme.palette.common.grey
            }`,
            color: isCompleted ? theme.palette.success.main : theme.palette.primary.main,
          })}
        >
          {isCompleted ? <CheckIcon /> : order}
        </Stack>

        <RoundButton className="button">
          <Stack flexDirection="row" gap={0.5} alignItems="center">
            <Icon>{isCompleted ? <EditIcon /> : <PlusIcon />}</Icon> {label}
          </Stack>
        </RoundButton>
      </Stack>
      <Line isCompleted={isCompleted} />
    </>
  );
};

const Line = styled('div')<{ isCompleted: boolean }>(({ theme, isCompleted }) => ({
  width: 24,
  height: 1,
  borderTop: `1px solid ${isCompleted ? theme.palette.success.main : theme.palette.common.grey}`,
}));

const VerifyLayout = styled(Stack)(({ theme }) => ({
  width: 360,
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1),
}));

const RoundButton = styled(Stack)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  cursor: 'pointer',
  flexDirection: 'row',
  whiteSpace: 'nowrap',
  gap: theme.spacing(1),
  transition: 'all .2s ease',
  position: 'relative',
  pointerEvents: disabled ? 'none' : undefined,
  height: theme.spacing(3.75),
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0, 1.5),
  borderRadius: theme.spacing(2),
  backgroundColor: disabled ? theme.palette.common.grey : theme.palette.primary.main,
  fontSize: theme.spacing(1.5),
  color: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const WithLines: FC<PropsWithChildren<{ showLines: boolean }>> = ({ children, showLines }) => {
  const lines = (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7954 0.544235C18.0474 0.431353 18.3432 0.544144 18.4561 0.79616C18.569 1.04818 18.4562 1.34399 18.2041 1.45687L1.57073 8.90723C1.31871 9.02011 1.0229 8.90732 0.910019 8.6553C0.797137 8.40329 0.909928 8.10748 1.16194 7.9946L17.7954 0.544235ZM13.0225 13.9998C13.2983 13.9874 13.5119 13.7538 13.4996 13.4779C13.4872 13.2021 13.2536 12.9884 12.9777 13.0008L2.97775 13.449C2.70189 13.4614 2.48828 13.695 2.50064 13.9709C2.51301 14.2468 2.74667 14.4604 3.02253 14.448L13.0225 13.9998Z"
        fill="#24275B"
      />
    </svg>
  );

  return (
    <Stack position="relative">
      {showLines && (
        <>
          <Box sx={{ position: 'absolute', pointerEvents: 'none', right: -20, top: -8 }}>
            {lines}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              pointerEvents: 'none',
              left: -20,
              bottom: -10,
              transform: 'rotate(170deg)',
            }}
          >
            {lines}
          </Box>
        </>
      )}

      {children}
    </Stack>
  );
};
