import {
  AgeGroup,
  FilterSelectOption,
  GetSchoolPropertiesProps,
  GetSchoolPropertiesResponse,
  RQUseQueryOptions,
  SchoolLevel,
  useGetSchoolPropertiesQuery,
} from '@schooly/api';
import { useCallback, useMemo } from 'react';

export const useAgeGroups = (
  params: GetSchoolPropertiesProps,
  options?: RQUseQueryOptions<GetSchoolPropertiesResponse>,
) => {
  const response = useGetSchoolPropertiesQuery(params, options);

  const ageGroups: AgeGroup[] = useMemo(
    () => [...(response.data?.age_groups ?? [])],
    [response.data?.age_groups],
  );

  const [activeAgeGroups, archivedAgeGroups] = useMemo(() => {
    return (
      response.data?.age_groups.reduce(
        (prev: AgeGroup[][], ageGroup) => {
          prev[ageGroup.archived ? 1 : 0]?.push(ageGroup);
          return prev;
        },
        [[], []],
      ) ?? [[], []]
    );
  }, [response.data?.age_groups]);

  const ageGroupSelectOptions: FilterSelectOption<string>[] = useMemo(() => {
    return ageGroups.map((g) => ({ value: g.id, archived: g.archived, label: g.name }));
  }, [ageGroups]);

  const schoolLevels: SchoolLevel[] = useMemo(
    () => [...(response.data?.school_levels ?? [])],
    [response.data?.school_levels],
  );

  const schoolLevelsWithAgeGroupsMap = useMemo(() => {
    const map: Record<string, AgeGroup[]> = {};
    activeAgeGroups?.forEach((g) => {
      if (g.level_id) {
        map[g.level_id] = map[g.level_id] ?? [];
        map[g.level_id]?.push(g);
      }
    });
    return map;
  }, [activeAgeGroups]);

  const getAgeGroupsByLevelId = useCallback(
    (id: string) => schoolLevelsWithAgeGroupsMap[id] ?? [],
    [schoolLevelsWithAgeGroupsMap],
  );

  const getSchoolLevelById = useCallback(
    (id: string) => schoolLevels.find((l) => l.id === id),
    [schoolLevels],
  );

  const getAgeGroupById = useCallback(
    (id: string) => ageGroups.find((g) => g.id === id),
    [ageGroups],
  );

  return {
    ...response,
    ageGroups,
    activeAgeGroups,
    archivedAgeGroups,
    schoolLevels,
    schoolLevelsWithAgeGroupsMap,
    ageGroupSelectOptions,
    getAgeGroupsByLevelId,
    getSchoolLevelById,
    getAgeGroupById,
  };
};
