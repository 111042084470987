import { Stack } from '@mui/material';
import { ReportStatuses } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import { ReportStatusTagSelect, ReportStatusTagSelectProps } from './ReportStatusTagSelect';

type ReportStatusExpandedSelectProps = PropsWithChildren<{
  selectedStatuses: ReportStatuses[];
  onSelectStatus: (v: ReportStatuses) => void;
  onClear: () => void;
  onClose: () => void;
}>;

const OPTIONS = [ReportStatuses.Published, ReportStatuses.Unpublished, ReportStatuses.Draft];

export const ReportStatusExpandedSelect: FC<ReportStatusExpandedSelectProps> = ({
  selectedStatuses,
  onSelectStatus,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderReportStatusTags({
          statuses: OPTIONS,
          tagProps: (status) => ({
            variant: selectedStatuses.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectStatus,
        })}
      </Stack>
    );
  }, [onSelectStatus, selectedStatuses]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedStatuses.length > 0}
      onClose={onClose}
      onClear={onClear}
      renderContent={renderContent}
    >
      {renderReportStatusTags({
        tagProps: { size: 'small' },
        statuses: selectedStatuses,
        onDelete: onSelectStatus,
      })}
    </ExpandedSelect>
  );
};

type RenderReportStatusTagsParams = {
  statuses: ReportStatuses[];
  onDelete?: (v: ReportStatuses) => void;
  onClick?: (v: ReportStatuses) => void;
  tagProps?:
    | Omit<ReportStatusTagSelectProps, 'status'>
    | ((v: ReportStatuses) => Omit<ReportStatusTagSelectProps, 'status'>);
};

export const renderReportStatusTags = ({
  statuses,
  onDelete,
  onClick,
  tagProps,
}: RenderReportStatusTagsParams) =>
  statuses.map((v) => (
    <ReportStatusTagSelect
      key={v}
      status={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
