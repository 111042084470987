import { Box, Button, IconButton, Stack } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { CustomFieldsFormContainer } from '@schooly/components/applications';
import { CreateCustomField } from '@schooly/components/applications';
import { DateSelect } from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import {
  AREA_CODE_OPTIONS,
  Countries,
  COUNTRY_OPTIONS,
  GENDER_OPTIONS,
  Genders,
  LANGUAGE_OPTIONS,
  Languages,
  Nationalities,
  NATIONALITY_OPTIONS,
  TITLE_OPTIONS,
  Titles,
  USER_NATIONALITIES_MAX_COUNT,
  USER_OTHER_LANGUAGES_MAX_COUNT,
  VALID_EMAIL_REGEXP,
} from '@schooly/constants';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  CrossIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalPanel,
  ModalSmall,
  ModalTab,
  ModalTabs,
  Spin,
} from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import FormSelect2 from '../../ui/Input/FormSelect2';
import { FormRadioGroup } from '../../uikit-components/FormCheckbox/FormRadioGroup';

export type CreateAdultForm = {
  preferred_name: string;
  other_languages_spoken: Languages[];
  date_of_birth: string;
  last_name: string;
  given_name: string;
  email: string;
  nationalities: Nationalities[];
  area_code: string;
  telephone: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  region: string;
  zip_code: string;
  language: Languages;
  title?: Titles;
  gender?: Genders;
  country?: Countries;
  custom_fields: Array<CreateCustomField>;
};

type AddStaffCreateAdultModalProps = {
  initialState?: CreateAdultForm;
  open: boolean;
  isSaving: boolean;
  formCustomFields?: Array<CreateCustomField>;
  onCreateAdult: (v: CreateAdultForm) => void;
  onClose: () => void;
};

enum ModalState {
  Personal,
  Contact,
  Additional,
}

export const CreateAdultModal: FC<AddStaffCreateAdultModalProps> = ({
  initialState,
  open,
  isSaving,
  formCustomFields,
  onCreateAdult,
  onClose,
}) => {
  const { $t } = useIntl();

  const [state, setState] = useState<ModalState>(ModalState.Personal);

  const form = useForm<CreateAdultForm>({
    defaultValues: {
      preferred_name: '',
      other_languages_spoken: [],
      last_name: '',
      given_name: '',
      email: '',
      nationalities: [],
      area_code: '',
      telephone: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      region: '',
      zip_code: '',
      custom_fields: formCustomFields ?? [],
      ...initialState,
    },
  });

  const date_of_birth = form.watch('date_of_birth');
  const given_name = form.watch('given_name');
  const last_name = form.watch('last_name');
  const language = form.watch('language');
  const email = form.watch('email');

  const showAdditionalInfoTab = formCustomFields?.length;
  const personalInfoFilled = !!date_of_birth && !!given_name && !!last_name;
  const contactInfoFilled = !!email;

  const otherLanguagesVisible = language !== undefined;

  useEffect(() => {
    if (otherLanguagesVisible) return;
    form.setValue('other_languages_spoken', []);
  }, [otherLanguagesVisible, form]);

  const handleNext = useCallback(() => {
    if (!personalInfoFilled) return;

    if (state === ModalState.Contact && (!contactInfoFilled || !email.match(VALID_EMAIL_REGEXP)))
      return;

    if (state === ModalState.Personal) {
      setState(ModalState.Contact);
    } else if (showAdditionalInfoTab) {
      setState(ModalState.Additional);
    }
  }, [personalInfoFilled, state, contactInfoFilled, email, showAdditionalInfoTab]);

  const handleBack = useCallback(() => {
    state === ModalState.Contact ? setState(ModalState.Personal) : setState(ModalState.Contact);
  }, [state]);

  const nationalities = form.watch('nationalities') ?? [];

  return (
    <ModalSmall open={open} onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onCreateAdult)}>
          <ModalHeader
            active
            title={$t({ id: 'migration-CreateNewAdult' })}
            withBorderBottom={false}
          >
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>

          <ModalPanel active sx={{ p: 0, mb: 1 }}>
            <ModalTabs>
              <ModalTab
                label={`1. ${$t({ id: 'applications-PersonalInformation' })}`}
                sx={{ flex: '1 1 50%' }}
                active={state === ModalState.Personal}
                icon={state === ModalState.Contact ? <CheckIcon /> : undefined}
                completed
              />
              <ModalTab
                label={`2. ${$t({ id: 'applications-ContactInformation' })}`}
                sx={{ flex: '1 1 50%' }}
                active={state === ModalState.Contact}
                icon={state === ModalState.Additional ? <CheckIcon /> : undefined}
                completed={state === ModalState.Additional || state === ModalState.Contact}
              />
              {showAdditionalInfoTab && (
                <ModalTab
                  label={`3. ${$t({ id: 'applications-AdditionalInformation' })}`}
                  sx={{ flex: '1 1 50%' }}
                  active={state === ModalState.Additional}
                  completed={state === ModalState.Additional}
                />
              )}
            </ModalTabs>
          </ModalPanel>

          <ModalContent active>
            {state === ModalState.Personal && (
              <Stack gap={2}>
                <Stack direction="row" gap={1.25}>
                  <Box
                    flex={1.5}
                    sx={{
                      '.form-select-search': {
                        maxHeight: 44,
                      },
                    }}
                  >
                    <FormSelect2
                      name="title"
                      labelTextId="peopleDetail-Title"
                      options={TITLE_OPTIONS}
                    />
                  </Box>
                  <Box flex={2.5}>
                    <ControlTextField
                      name="given_name"
                      control={form.control}
                      rules={{ required: true }}
                      label={$t({ id: 'peopleDetail-GivenNames' })}
                      canClear
                      fullWidth
                    />
                  </Box>
                </Stack>
                <ControlTextField
                  name="last_name"
                  control={form.control}
                  rules={{ required: true }}
                  label={$t({ id: 'peopleDetail-FamilyName' })}
                  canClear
                  fullWidth
                />
                <Controller
                  control={form.control}
                  name="date_of_birth"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => {
                    return (
                      <DateSelect
                        ref={field.ref}
                        onSetDate={(date) => {
                          field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                        }}
                        date={field.value}
                        placeholder={$t({ id: 'peopleDetail-DateOfBirth' })}
                        requiredLabel="required"
                        error={fieldState.error}
                        onClear={() => field.onChange('')}
                      />
                    );
                  }}
                />
                <FormRadioGroup options={[...GENDER_OPTIONS].reverse()} name="gender" />
                <FormSelect2
                  name="nationalities"
                  labelTextId={
                    nationalities.length <= 1
                      ? 'peopleDetail-Nationality'
                      : 'peopleDetail-Nationality-plural'
                  }
                  options={NATIONALITY_OPTIONS}
                  multiple
                  maxCount={USER_NATIONALITIES_MAX_COUNT}
                />
                <FormSelect2
                  name="language"
                  labelTextId="peopleDetail-PrimaryLanguage"
                  options={LANGUAGE_OPTIONS}
                />
                {otherLanguagesVisible && (
                  <FormSelect2
                    name="other_languages_spoken"
                    labelTextId={'peopleDetail-AdditionalLanguage'}
                    options={LANGUAGE_OPTIONS}
                    multiple
                    maxCount={USER_OTHER_LANGUAGES_MAX_COUNT}
                  />
                )}
              </Stack>
            )}
            {state === ModalState.Contact && (
              <Stack gap={2}>
                <Stack direction="row" gap={1.25}>
                  <Box flex={1.5}>
                    <FormSelect2
                      name="phoneCode"
                      labelTextId="AreaCode"
                      options={AREA_CODE_OPTIONS}
                      noRequiredLabel
                      showSelectedValue
                      optionClassName="CountryCodeSelectOption"
                    />
                  </Box>
                  <Box flex={2.5}>
                    <ControlTextField
                      name="telephone"
                      control={form.control}
                      label={$t({ id: 'peopleDetail-PhoneNumber' })}
                      canClear
                      fullWidth
                    />
                  </Box>
                </Stack>
                <ControlTextField
                  name="email"
                  control={form.control}
                  label={$t({ id: 'peopleDetail-EmailAddressPersonal' })}
                  canClear
                  fullWidth
                  //TODO: Refactor email validation - TR-4353
                  rules={{
                    required: true,
                    pattern: {
                      value: VALID_EMAIL_REGEXP,
                      message: $t({
                        id: 'input-ErrorInvalidEmail',
                      }),
                    },
                  }}
                />
                <Box mt={1} />
                <FormSelect2
                  name="country"
                  labelTextId="peopleDetail-Country"
                  options={COUNTRY_OPTIONS}
                  optionClassName="CountryCodeSelectOption"
                />
                <ControlTextField
                  name="city"
                  control={form.control}
                  label={$t({ id: 'peopleDetail-City' })}
                  canClear
                  fullWidth
                />
                <ControlTextField
                  name="address_line_1"
                  control={form.control}
                  label={$t({ id: 'peopleDetail-AddressLine1' })}
                  canClear
                  fullWidth
                />
                <ControlTextField
                  name="address_line_2"
                  control={form.control}
                  label={$t({ id: 'peopleDetail-AddressLine2' })}
                  canClear
                  fullWidth
                />
                <Stack direction="row" gap={1.25}>
                  <Box flex={1.5}>
                    <ControlTextField
                      name="zip_code"
                      control={form.control}
                      label={$t({ id: 'peopleDetail-ZipCode' })}
                      canClear
                      fullWidth
                    />
                  </Box>
                  <Box flex={2.5}>
                    <ControlTextField
                      name="region"
                      control={form.control}
                      label={$t({ id: 'peopleDetail-StateProvince' })}
                      canClear
                      fullWidth
                    />
                  </Box>
                </Stack>
              </Stack>
            )}
            {state === ModalState.Additional && (
              <Stack gap={2}>
                <Stack direction="row" gap={1.25}>
                  <Box flex={1.5}>
                    <CustomFieldsFormContainer />
                  </Box>
                </Stack>
              </Stack>
            )}
          </ModalContent>

          <ModalFooter active>
            {state === ModalState.Personal && (
              <Button
                endIcon={<ArrowRightIcon />}
                type={personalInfoFilled ? undefined : 'submit'}
                onClick={personalInfoFilled ? handleNext : undefined}
              >
                <FormattedMessage id="action-Next" />
              </Button>
            )}
            {state === ModalState.Contact && (
              <Stack flex={1} flexDirection="row" justifyContent="space-between">
                <Button variant="outlined" startIcon={<ArrowLeftIcon />} onClick={handleBack}>
                  <FormattedMessage id="action-Back" />
                </Button>
                {showAdditionalInfoTab ? (
                  <Button
                    endIcon={<ArrowRightIcon />}
                    type={contactInfoFilled ? undefined : 'submit'}
                    onClick={contactInfoFilled ? handleNext : undefined}
                  >
                    <FormattedMessage id="action-Next" />
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    endIcon={isSaving ? <Spin /> : <CheckIcon />}
                    disabled={isSaving}
                  >
                    <FormattedMessage id="action-Create" />
                  </Button>
                )}
              </Stack>
            )}
            {state === ModalState.Additional && (
              <Stack flex={1} flexDirection="row" justifyContent="space-between">
                <Button variant="outlined" startIcon={<ArrowLeftIcon />} onClick={handleBack}>
                  <FormattedMessage id="action-Back" />
                </Button>
                <Button
                  type="submit"
                  endIcon={isSaving ? <Spin /> : <CheckIcon />}
                  disabled={isSaving}
                >
                  <FormattedMessage id="action-Create" />
                </Button>
              </Stack>
            )}
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
