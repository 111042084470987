import { Stack, Typography } from '@mui/material';
import {
  ApiError,
  generateGetMiniListQueryKey,
  getMiniList,
  GetMiniListQueryParams,
  GetStudentsQueryFilters,
  SchoolYear,
  SimpleListResult,
  StudentsArrangeBy,
} from '@schooly/api';
import { RolloverButton, RolloverProps } from '@schooly/components/annual-roll-over';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { CheckboxSquareIconButton, SimpleButton, Spin } from '@schooly/style';
import { BulkActionsBottomBar, SelectedIds, SelectedSections } from '@schooly/utils/bulk-actions';
import { useQueryClient } from '@tanstack/react-query';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { SIDEBAR_WIDTH } from '../../components/uikit-components/Sidebar/Sidebar.styled';
import useAppLocation from '../../hooks/useAppLocation';
import { useRouter } from '../../hooks/useRouter';
import useSchoolYears from '../../hooks/useSchoolYears';
import { ConductCreateModalContextLocation } from '../Conduct/ConductCreateModal/useConductCreateModal';
import { useRollover } from '../Rollover/useRollover';

type StudentsBulkActionsProps = {
  schoolId: string;
  selectedIds: SelectedIds;
  query?: string;
  filters: GetStudentsQueryFilters;
  onClear: () => void;
};

type FetchingState = 'conduct' | 'rollover' | false;

export const StudentsBulkActions: FC<StudentsBulkActionsProps> = ({
  selectedIds,
  schoolId,
  onClear,
  filters,
  query,
}) => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const location = useAppLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { defaultValidity, schoolYears } = useSchoolYears();
  const filtersDate = filters.date?.[0];
  const { permissions } = useAuth();
  const { navigateToRollover } = useRollover();

  const { push, stack } = useRouter();

  const isAdmin = permissions.includes('school_admin');
  const isStudentManager = permissions.includes('student_manager');

  const [isFetching, setFetching] = useState<FetchingState>(false);

  const handleCreateConduct = useCallback(async () => {
    setFetching('conduct');

    try {
      const params: GetMiniListQueryParams = {
        schoolId,
        type: 'student',
        filters,
        query,
        pageSize: 999,
      };

      const queryKey = generateGetMiniListQueryKey(params);
      const response = await queryClient.fetchQuery(queryKey, () => getMiniList(params));

      const students = response.results.filter((s) =>
        selectedIds === 'all' ? true : selectedIds.has(s.relation_id),
      );

      navigate(
        {
          pathname: `/conduct/new`,
        },
        {
          state: {
            backgroundLocation: location,
            initialState: {
              selectedStudents: students,
            },
          },
        } as ConductCreateModalContextLocation,
      );
    } catch (e) {
      showError(e as ApiError);
    }

    setFetching(false);
  }, [filters, location, navigate, query, queryClient, schoolId, selectedIds, showError]);

  const handleStudentsRollover = useCallback(
    ({ yearFrom, yearTo }: RolloverProps<SchoolYear>) => {
      navigateToRollover({
        student_ids: selectedIds === 'all' ? selectedIds : Array.from(selectedIds),
        year_from_id: yearFrom.id,
        year_to_id: yearTo.id,
        ...(selectedIds === 'all' && { filters }),
        query,
      });
    },
    [filters, navigateToRollover, query, selectedIds],
  );

  if (selectedIds !== 'all' && !selectedIds.size) return null;

  return (
    <BulkActionsBottomBar
      sx={{ zIndex: 5, pointerEvents: !!isFetching ? 'none' : undefined }}
      sidebarWidth={SIDEBAR_WIDTH}
    >
      <Stack flexDirection="row" alignItems="center" minWidth={240} gap={1}>
        <CheckboxSquareIconButton isActive onClick={onClear} />
        <Typography variant="h3">
          {$t(
            { id: 'countOfTypeSelected' },
            selectedIds === 'all'
              ? {
                  count: $t({ id: 'filter-All' }),
                  userTypePlural: $t({ id: 'userType-student-plural' }),
                }
              : {
                  count: selectedIds.size,
                  userTypePlural: $t({
                    id: selectedIds.size > 1 ? 'userType-student-plural' : 'userType-student',
                  }),
                },
          )}
        </Typography>
      </Stack>
      <StudentsBulkActionsButtons isFetching={isFetching} onCreateConduct={handleCreateConduct}>
        {isStudentManager && filtersDate && !!schoolYears?.length && (
          <RolloverButton
            onRollover={handleStudentsRollover}
            selectedDate={filtersDate}
            schoolYears={schoolYears}
            defaultSchoolYear={defaultValidity}
            canEditYears={isAdmin}
            isFetching={isFetching === 'rollover'}
            onYearsEdit={() => {
              if (!stack.length) {
                push({ location });
              }
              navigate('settings/school_years');
            }}
          />
        )}
      </StudentsBulkActionsButtons>
    </BulkActionsBottomBar>
  );
};

type StudentsSectionsBulkActionsProps = {
  schoolId: string;
  selectedSections: SelectedSections<StudentsArrangeBy>;
  query?: string;
  filters: GetStudentsQueryFilters;
  onClear: () => void;
};

export const StudentsSectionsBulkActions: FC<StudentsSectionsBulkActionsProps> = ({
  selectedSections,
  schoolId,
  onClear,
  filters,
  query,
}) => {
  const { $t } = useIntl();
  const [isFetching, setFetching] = useState<FetchingState>(false);

  const { showError } = useNotifications();

  const location = useAppLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const filtersDate = filters.date?.[0];
  const { defaultValidity, schoolYears } = useSchoolYears();
  const { permissions } = useAuth();
  const { navigateToRollover } = useRollover();

  const { push, stack } = useRouter();

  const isAdmin = permissions.includes('school_admin');
  const isStudentManager = permissions.includes('student_manager');

  const getStudentsBySections = useCallback(async () => {
    const params: GetMiniListQueryParams = {
      schoolId,
      type: 'student',
      filters,
      query,
      pageSize: 999,
    };
    const studentsBySections = await Promise.all(
      Array.from(selectedSections.values()).map(
        async ({ arrangeByKey, arrangeByValue, isNoneSection, selectedIds }) => {
          const sectionParams = {
            ...params,
            filters: arrangeByKey
              ? {
                  ...params.filters,
                  [arrangeByKey]: [isNoneSection ? 'none' : arrangeByValue],
                }
              : params.filters,
          };

          const queryKey = generateGetMiniListQueryKey(sectionParams);
          const response = await queryClient.fetchQuery(queryKey, () => getMiniList(sectionParams));

          return response.results.filter((s) =>
            selectedIds === 'all' ? true : selectedIds.has(s.relation_id),
          );
        },
      ),
    );

    const students = studentsBySections.reduce<SimpleListResult[]>((acc, students) => {
      const existingStudentIds = acc.map((s) => s.relation_id);

      for (const student of students) {
        !existingStudentIds.includes(student.relation_id) && acc.push(student);
      }

      return acc;
    }, []);
    return students;
  }, [filters, query, queryClient, schoolId, selectedSections]);

  const handleCreateConduct = useCallback(async () => {
    setFetching('conduct');

    try {
      const students = await getStudentsBySections();

      navigate(
        {
          pathname: `/conduct/new`,
        },
        {
          state: {
            backgroundLocation: location,
            initialState: {
              selectedStudents: students,
            },
          },
        } as ConductCreateModalContextLocation,
      );
    } catch (e) {
      showError(e as ApiError);
    }

    setFetching(false);
  }, [getStudentsBySections, location, navigate, showError]);

  const handleStudentsRollover = useCallback(
    async ({ yearFrom, yearTo }: RolloverProps<SchoolYear>) => {
      setFetching('rollover');

      try {
        const studentsBySections = await getStudentsBySections();
        const selectedIds = studentsBySections.map((s) => s.relation_id);

        navigateToRollover({
          student_ids: selectedIds,
          year_from_id: yearFrom.id,
          year_to_id: yearTo.id,
        });
      } catch (e) {
        showError(e as ApiError);
      }
      setFetching(false);
    },
    [getStudentsBySections, navigateToRollover, showError],
  );

  const count = Array.from(selectedSections.values()).reduce<number>(
    (a, s) => a + (s.selectedIds === 'all' ? s.count : s.selectedIds.size),
    0,
  );

  if (count === 0) return null;
  return (
    <BulkActionsBottomBar
      sx={{ zIndex: 5, pointerEvents: !!isFetching ? 'none' : undefined }}
      sidebarWidth={SIDEBAR_WIDTH}
    >
      <Stack flexDirection="row" alignItems="center" minWidth={240} gap={1}>
        <CheckboxSquareIconButton isActive onClick={onClear} />
        <Typography variant="h3">
          {$t(
            { id: 'countOfTypeSelected' },

            {
              count,
              userTypePlural: $t({
                id: count > 1 ? 'userType-student-plural' : 'userType-student',
              }),
            },
          )}
        </Typography>
      </Stack>
      <StudentsBulkActionsButtons isFetching={isFetching} onCreateConduct={handleCreateConduct}>
        {isStudentManager && filtersDate && !!schoolYears?.length && (
          <RolloverButton
            onRollover={handleStudentsRollover}
            selectedDate={filtersDate}
            schoolYears={schoolYears}
            defaultSchoolYear={defaultValidity}
            canEditYears={isAdmin}
            onYearsEdit={() => {
              if (!stack.length) {
                push({ location });
              }
              navigate('settings/school_years');
            }}
            isFetching={isFetching === 'rollover'}
          />
        )}
      </StudentsBulkActionsButtons>
    </BulkActionsBottomBar>
  );
};

type StudentsBulkActionsButtonsProps = {
  onCreateConduct: () => void;
  isFetching: FetchingState;
};
export const StudentsBulkActionsButtons: FC<PropsWithChildren<StudentsBulkActionsButtonsProps>> = ({
  onCreateConduct,
  isFetching,
  children,
}) => {
  const { $t } = useIntl();

  return (
    <Stack direction="row" gap={5}>
      <SimpleButton
        startIcon={isFetching === 'conduct' ? <Spin /> : undefined}
        disabled={!!isFetching}
        onClick={onCreateConduct}
      >
        {$t({
          id: 'students-CreateConductLog',
        })}
      </SimpleButton>
      {children}
    </Stack>
  );
};
