import { Box, Stack } from '@mui/material';
import { styled } from '@mui/system';

interface GroupPreviewModalMessagesStyledProps {
  empty?: boolean;
}

export const GroupPreviewModalMessagesStyled = styled(Box, {
  shouldForwardProp: (prop) => !(['empty'] as PropertyKey[]).includes(prop),
})<GroupPreviewModalMessagesStyledProps>(({ theme, empty }) => ({
  height: empty ? '100%' : 'auto',

  '& .AccessDenied': {
    marginTop: 0,
  },
}));

export const GroupPreviewModalAccessDenied = styled(Box)(({ theme }) => ({
  '& .AccessDenied': {
    marginTop: 0,
  },
}));

export const GroupPreviewModalMonths = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2.5),
  width: '100%',
}));

export const GroupPreviewModalMonthName = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1.25),
}));

interface GroupPreviewModalContentProps {
  empty?: boolean;
}

export const GroupPreviewModalContent = styled(Stack, {
  shouldForwardProp: (prop) => !(['empty'] as PropertyKey[]).includes(prop),
})<GroupPreviewModalContentProps>(({ theme, empty }) => ({
  height: empty ? `calc(100% - ${theme.spacing(10)})` : 'auto',
  flexDirection: 'row',
  width: '100%',

  '& .InboxModal__empty-wrapper': {
    margin: 0,
    width: '100%',
  },
}));
