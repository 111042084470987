import { useQuery } from '@tanstack/react-query';

import { ApiError } from './apiTypes/misc';
import { RQUseQueryOptions } from './apiTypes/query';
import { BILLING_URL } from './commonPaths';
import * as api from './requests';

type BillingCountsResponse = {
  legal_entities: { active: number; archived: number };
  //in future: frequencies: [{year_id: string, count: number}];
  //in future: products: [{year_id: string, active: number, archived: number}]
};

export const getBillingCounts = async (schoolId: string): Promise<BillingCountsResponse> => {
  return api.get(`${BILLING_URL}/count`, {
    params: { school_id: schoolId },
  });
};

export const GET_BILLING_COUNTS_QUERY = `${BILLING_URL}/GET_BILLING_COUNTS_QUERY`;

export const useGetBillingCountsQuery = (
  schoolId: string,
  options?: RQUseQueryOptions<BillingCountsResponse>,
) => {
  return useQuery<BillingCountsResponse, ApiError>(
    [GET_BILLING_COUNTS_QUERY, schoolId],
    () => getBillingCounts(schoolId),
    {
      ...options,
    },
  );
};
