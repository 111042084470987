import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { ArchiveIcon } from '../assets/assets';

export interface TagSubjectProps extends PropsWithChildren {
  archived?: boolean;
  renderLabel?: () => ReactNode;
}

export const TagSubject: FC<TagSubjectProps> = ({ archived, children, renderLabel }) => {
  const { $t } = useIntl();

  const renderContent = () => (
    <Stack
      sx={(theme) => ({
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(0.5),
        height: theme.spacing(2.5),
        padding: theme.spacing(0, 0.75),
        border: theme.mixins.borderValue(),
        borderRadius: theme.spacing(0.5),
        cursor: 'default',
        background: theme.palette.background.paper,
        whiteSpace: 'nowrap',
        color: theme.palette.common.grey2,
      })}
    >
      {archived && (
        <Box
          component={ArchiveIcon}
          sx={(theme) => ({
            '&&': {
              width: theme.typography.body1.fontSize,
              height: theme.typography.body1.fontSize,
              flexBasis: theme.typography.body1.fontSize,
            },
          })}
        />
      )}

      {renderLabel ? renderLabel() : <Typography>{children}</Typography>}
    </Stack>
  );

  return archived ? (
    <Tooltip title={$t({ id: 'schoolProperty-Archived-subject' })}>{renderContent()}</Tooltip>
  ) : (
    renderContent()
  );
};
