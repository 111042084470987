import { FormControlLabel, Stack, Switch } from '@mui/material';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useGroupAttendanceStatistics } from '../../../context/groups/useGroupAttendanceStatistics';

export const GroupAttendanceStatisticsModalToggleShowByPresentAbsent: FC = () => {
  const { $t } = useIntl();
  const { showByPresentAbsent, actions } = useGroupAttendanceStatistics();

  const handleToggle = useCallback(() => {
    actions.setShowByPresentAbsent(!showByPresentAbsent);
  }, [actions, showByPresentAbsent]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      p={1}
      border={1}
      borderRadius={1}
      borderColor={(theme) => theme.palette.common.light3}
      height={44}
    >
      <FormControlLabel
        control={<Switch checked={showByPresentAbsent} onChange={handleToggle} />}
        label={$t({ id: 'filter-ShowByPresentAbsent' })}
        sx={{
          m: 0,
          color: showByPresentAbsent ? 'text.primary' : 'text.secondary',
        }}
      />
    </Stack>
  );
};
