import { AvailableCriteria, FilterElementType, GroupUserType } from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';
import { ArrowheadDownIcon, Loading } from '@schooly/style';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import SearchInput from '../../../../components/ui/SearchInput';
import RoundCard from '../../../../components/uikit-components/RoundCard';
import Nationalities from '../../../../constants/nationalities';
import { Genders } from '../../../../constants/people';
import { getCriteriaName, getCriteriaTypeTextId } from '../../../../helpers/misc';
import useScrollLock from '../../../../hooks/useScrollLock';
import buildClassName from '../../../../utils/buildClassName';
import searchWords from '../../../../utils/searchWords';

interface IProps {
  userType: GroupUserType;
  criteria?: AvailableCriteria[];
  groupCriteria: AvailableCriteria[];
  onCriteriaClick?: (criteria: AvailableCriteria) => void;
  isFetching?: boolean;
}

const SORTED_CRITERIA_TYPES = [
  SchoolPropertyType.Status,
  SchoolPropertyType.AgeGroup,
  SchoolPropertyType.House,
  SchoolPropertyType.Campus,
  FilterElementType.Gender,
  FilterElementType.Nationality,
  FilterElementType.Group,
];

export function getCriteriaItemKey(item: AvailableCriteria) {
  let id;

  if (item.type === FilterElementType.Property) {
    id = item.school_property!.id;
  } else if (item.type === FilterElementType.Group) {
    id = item.group!.id;
  } else {
    id = item.enum_index!;
  }

  return `${FilterElementType[item.type as FilterElementType]}-${id}`;
}

const AvailableCriteriaList: React.FC<IProps> = ({
  userType,
  criteria,
  groupCriteria,
  onCriteriaClick,
  isFetching,
}) => {
  const { formatMessage } = useIntl();
  const [filter, setFilter] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>();

  // TODO remove this hook when AvailableUsersWithFilter is removed from AddGroupModal components
  useScrollLock(true);

  const handleFilterChange = useCallback((newFilter: string) => {
    setFilter(newFilter);
  }, []);

  const handleCategoryClick = useCallback(
    (category: string) => {
      setSelectedCategory(category === selectedCategory ? undefined : category);
    },
    [selectedCategory],
  );

  const getCriteriaIsSelected = useCallback(
    (item: AvailableCriteria) =>
      groupCriteria.some((c) => {
        if (c.school_property) {
          return c.school_property.id === item.school_property?.id;
        } else if (c.group) {
          return c.group.id === item.group?.id;
        } else {
          return c.enum_index === item.enum_index && c.type === item.type;
        }
      }),
    [groupCriteria],
  );

  const getGroupCriteriaByType = useCallback((items: AvailableCriteria[]): {
    [typeTextId: string]: AvailableCriteria[];
  } => {
    const defaultGroupCriteriaByType = SORTED_CRITERIA_TYPES.reduce(
      (acc, item) => ({
        ...acc,
        [getCriteriaTypeTextId(item) as string]: [],
      }),
      Object.create(null),
    );

    return items.reduce((acc, item) => {
      const typeTextId = getCriteriaTypeTextId(item.type || item.school_property?.type);

      if (typeTextId) {
        acc[typeTextId].push(item);
      }

      return acc;
    }, defaultGroupCriteriaByType);
  }, []);

  const displayedCriteria = useMemo(() => {
    if (!criteria) {
      return criteria;
    }

    if (!filter) {
      return getGroupCriteriaByType(criteria.filter((c) => !getCriteriaIsSelected(c)));
    }

    return getGroupCriteriaByType(
      criteria
        .filter((c) => !getCriteriaIsSelected(c))
        .filter((c) => {
          switch (c.type) {
            case FilterElementType.Property:
              return searchWords(c.school_property!.name, filter);
            case FilterElementType.Gender:
              return searchWords(Genders[c.enum_index!], filter);
            case FilterElementType.Nationality:
              return searchWords(Nationalities[c.enum_index!], filter);
            case FilterElementType.Group:
              return searchWords(c.group!.name, filter);
            default:
              return true;
          }
        }),
    );
  }, [filter, criteria, getGroupCriteriaByType, getCriteriaIsSelected]);

  const totalCount = useMemo(
    () => (displayedCriteria ? Object.values(displayedCriteria).flat().length : 0),
    [displayedCriteria],
  );

  if (userType !== 'student') {
    return null;
  }

  return (
    <div className="AvailableCriteria" data-test-id="user-available-criteria">
      {displayedCriteria && !isFetching ? (
        <>
          <SearchInput
            value={filter}
            onChange={handleFilterChange}
            isValueRemovable
            placeholder={formatMessage(
              { id: 'groups-AvailableCriteriaSearch' },
              {
                count: totalCount,
              },
            )}
          />

          {filter && !totalCount ? (
            <div className="AvailableCriteria__empty">
              <NoSearchResultsFound />
            </div>
          ) : (
            <div className="AvailableCriteria__list">
              {Object.entries(displayedCriteria).map(
                ([typeTextId, items]) =>
                  !!items.length && (
                    <div
                      key={typeTextId}
                      className={buildClassName(
                        'AvailableCriteria__item',
                        (typeTextId === selectedCategory || !!filter) && 'selected',
                      )}
                    >
                      <div
                        className="AvailableCriteria__type"
                        onClick={() => handleCategoryClick(typeTextId)}
                        role="button"
                        tabIndex={0}
                      >
                        {formatMessage({ id: typeTextId })}
                        <ArrowheadDownIcon />
                      </div>

                      <div className="AvailableCriteria__content">
                        {items.map((item) => (
                          <RoundCard
                            withTooltip
                            generateHref={
                              item.type === FilterElementType.Group
                                ? () => `/groups/${item?.group?.id}`
                                : undefined
                            }
                            count={Number(item?.relation_ids?.length)}
                            key={getCriteriaItemKey(item)}
                            item={item}
                            name={getCriteriaName(item)}
                            onClick={onCriteriaClick}
                          />
                        ))}
                      </div>
                    </div>
                  ),
              )}
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AvailableCriteriaList;
