import { useQuery } from '@tanstack/react-query';

import { AnnualPlan } from './apiTypes/endpoints/annualPlan';
import { ApiError } from './apiTypes/misc';
import { RQUseQueryOptions } from './apiTypes/query';
import * as api from './requests';

const ANNUAL_PLANNER_URL = '/plan/';

export const getAnnualPlan = (schoolId: string, schoolYear: string): Promise<AnnualPlan> => {
  return api.get(`/school_details/${schoolId}${ANNUAL_PLANNER_URL}${schoolYear}`);
};

export const GET_ANNUAL_PLAN_QUERY = `${ANNUAL_PLANNER_URL}/GET_ANNUAL_PLAN_QUERY`;

export const useGetAnnualPlanQuery = (
  schoolId: string,
  schoolYear: string,
  options?: RQUseQueryOptions<AnnualPlan>,
) => {
  return useQuery<AnnualPlan, ApiError>(
    [GET_ANNUAL_PLAN_QUERY, schoolId, schoolYear],
    () => getAnnualPlan(schoolId, schoolYear),
    {
      ...options,
    },
  );
};
