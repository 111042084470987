import { Box, BoxProps } from '@mui/material';
import { FC, forwardRef, PropsWithRef, useEffect, useMemo, useRef } from 'react';
import { useDragLayer } from 'react-dnd';

import {
  AnnualPlannerCalendarRecordCell,
  AnnualPlannerCalendarRecordGroup,
  AnnualPlannerCalendarRecordTitle,
  AnnualPlannerCalendarRecordTitleTypography,
} from '../AnnualPlannerCalendar.styled';
import { UseAnnualPlannerCellItem } from '../useAnnualPlannerCell';
import { getRecordIncompleteFields } from '../utils';

export interface AnnualPlannerRecordsLayoutDragPreviewProps extends BoxProps {
  group: UseAnnualPlannerCellItem;
}

export const AnnualPlannerRecordsLayoutDragPreview: FC<
  PropsWithRef<AnnualPlannerRecordsLayoutDragPreviewProps>
> = forwardRef(({ group, ...props }, previewRef) => {
  const ref = useRef<HTMLDivElement>(null);

  const { item, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem() as UseAnnualPlannerCellItem,
    isDragging: monitor.isDragging(),
  }));

  const [position, dayHeight] = useMemo(() => {
    const position = group.position.reduce<UseAnnualPlannerCellItem['position'][number]>(
      (prev, position, index) => {
        if (index === 0) {
          return prev;
        }

        prev.bottomDateNum += position.bottomDateNum;
        prev.bottomValue += position.bottomValue;
        prev.bottom = `${100 - prev.bottomValue}%`;

        return prev;
      },
      { ...group.position[0] },
    );

    const daysCount = position.bottomDateNum - position.topDateNum;
    const dayHeight = daysCount > 1 ? `calc(${100 / daysCount}% - 1px)` : '100%';

    return [position, dayHeight];
  }, [group.position]);

  const isIncomplete =
    group.records.length === 1 && getRecordIncompleteFields(group.records[0]).length > 0;

  const isCurrentDragging = Boolean(isDragging && item.id === group.id);

  useEffect(() => {
    if (!isCurrentDragging) {
      if (ref.current) {
        ref.current.style.display = '';
      }
      return;
    }

    const overflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    // hide temporary preview element while dragging one a next tick
    // if hide it on the current tick the react-dnd won't be able to detect it correctly and take
    // a snapshot image
    setTimeout(() => {
      if (ref.current) {
        ref.current.style.display = isCurrentDragging ? 'none' : '';
      }
      document.body.style.overflow = overflow;
    }, 0);
  }, [isCurrentDragging]);

  return (
    <Box ref={ref}>
      <AnnualPlannerCalendarRecordGroup
        ref={previewRef}
        sx={() => ({
          display: isCurrentDragging ? undefined : 'none',
          position: 'absolute',
          top: position.top,
          left: position.left,
          right: position.right,
          bottom: position.bottom,
          pointerEvents: 'none',
          bgcolor: 'white',
          zIndex: -1,
        })}
        {...props}
        type={group.type}
        className={[
          AnnualPlannerCalendarRecordGroup.defaultProps?.className,
          `AnnualPlannerCalendarRecordGroup-${group.type}`,
          'AnnualPlannerCalendarRecordGroup-active',
          props.className,
        ]
          .filter(Boolean)
          .join(' ')}
      >
        <AnnualPlannerCalendarRecordCell type={group.type} incomplete={isIncomplete} />
        <AnnualPlannerCalendarRecordTitle
          type={group.type}
          sx={{
            height: dayHeight,
            justifyContent: 'flex-start',
          }}
        >
          <AnnualPlannerCalendarRecordTitleTypography sx={{ ml: 0.25 }}>
            {group.records[0].title}
          </AnnualPlannerCalendarRecordTitleTypography>
        </AnnualPlannerCalendarRecordTitle>
      </AnnualPlannerCalendarRecordGroup>
    </Box>
  );
});
