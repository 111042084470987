import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.81426 11.7565C5.39524 12.1289 5.39524 12.76 5.81426 13.1324L9.31426 16.2435C9.69897 16.5855 10.301 16.5855 10.6857 16.2435L14.1857 13.1324C14.6048 12.76 14.6048 12.1289 14.1857 11.7565C13.801 11.4145 13.199 11.4145 12.8143 11.7565L10 14.258L7.18574 11.7565C6.80103 11.4145 6.19897 11.4145 5.81426 11.7565ZM14.1857 8.24353C14.6048 7.87107 14.6048 7.24004 14.1857 6.86758L10.6857 3.75647C10.301 3.41451 9.69897 3.41451 9.31426 3.75647L5.81427 6.86758C5.39524 7.24004 5.39524 7.87107 5.81426 8.24353C6.19897 8.58549 6.80103 8.58549 7.18574 8.24353L10 5.74196L12.8143 8.24353C13.199 8.58549 13.801 8.58549 14.1857 8.24353Z"
      fill="#B3BBCB"
    />
  </svg>
);
