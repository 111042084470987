import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { GroupUserType } from '@schooly/api';
import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { CrossIcon, EditIcon } from '../../../../../components/ui/Icons';
import {
  ModalContent,
  ModalLarge,
  ModalPanel,
} from '../../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../../components/uikit-components/Modal/ModalHeader';
import { ellipsis } from '../../GroupPreviewModalInfo';
import { GroupStudentListExport } from '../GroupStudentListExport';

export interface GroupMembersPreviewModalProps extends PropsWithChildren {
  userType: GroupUserType;
  topPanelContent: React.ReactNode;
  groupName: string;
  groupId?: string;
  onEdit?: () => void;
  onClose: () => void;
}

export const GroupMembersPreviewModal: FC<GroupMembersPreviewModalProps> = ({
  userType,
  children,
  groupName,
  groupId,
  topPanelContent,
  onClose,
  onEdit,
}) => {
  return (
    <ModalLarge open>
      <ModalHeader
        active
        withBorderBottom={false}
        sx={{ pb: 1 }}
        title={
          <Stack gap={3}>
            <Typography
              color="text.secondary"
              sx={{ position: 'absolute', top: (theme) => theme.spacing() }}
            >
              <FormattedMessage
                id={userType === 'student' ? 'groups-MembersStudents' : 'groups-MembersStaff'}
              />
            </Typography>
            <Box sx={{ pt: 1 }}>{!groupName ? <Skeleton width="55%" /> : ellipsis(groupName)}</Box>
          </Stack>
        }
      >
        <Stack direction="row" gap={2.5}>
          {onEdit && (
            <IconButton inverse onClick={onEdit}>
              <EditIcon />
            </IconButton>
          )}
          <GroupStudentListExport groupId={groupId} groupName={groupName} />
          <IconButton inverse onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </Stack>
      </ModalHeader>

      <ModalPanel active sx={{ pt: 0, pb: 1 }} withBorderBottom>
        {topPanelContent}
      </ModalPanel>

      <ModalContent sx={{ pt: 1.5 }} active>
        {children}
      </ModalContent>
    </ModalLarge>
  );
};
