import { Box, Theme, useMediaQuery } from '@mui/material';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { AbsenceRequest, SimpleListResult, useGetAttendanceCodesQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { DataGridTable } from '@schooly/components/data-grid';
import { Loading } from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';

import { useDataGridHover } from '../../../../context/table/dataGridHover/WithDataGridHover';
import { filterUser } from '../../../../helpers/users';
import { useNotificationSettings } from '../../../../hooks/useNotificationSettings';
import { useTakeAttendanceModal } from '../useTakeAttendanceModal';
import { TakeAttendanceGridCheckboxCell } from './TakeAttendanceGridCheckboxCell';
import { TakeAttendanceGridCodeHeaderCell } from './TakeAttendanceGridCodeHeaderCell';
import { TakeAttendanceGridCommentCell } from './TakeAttendanceGridCommentCell';
import { TakeAttendanceGridMarkBlankHeaderCell } from './TakeAttendanceGridMarkBlankHeaderCell';
import { TakeAttendanceGridStudentNameCell } from './TakeAttendanceGridStudentNameCell';
import { TakeAttendanceHeaderCell } from './TakeAttendanceModalContent.styled';

const STUDENTS_COL_FIELD_KEY = 'attendance-students';
export const COMMENTS_COL_FIELD_KEY = 'attendance-comments';

export interface StudentInRow extends SimpleListResult {
  absenceRequest: AbsenceRequest;
}

export interface TakeAttendanceTableRow {
  id: string;
  student: StudentInRow;
}

export const TakeAttendanceModalContentGrid: FC = () => {
  const { currentStaff, schoolId = '' } = useAuth();
  const { data: codes } = useGetAttendanceCodesQuery(schoolId, { refetchOnMount: 'always' });
  const { query, entries, groupDetails, actions } = useTakeAttendanceModal();
  const { attendanceNotificationsEnabled, fetching: isNotificationsFetching } =
    useNotificationSettings();
  const { onColumnMouseOver, onMouseLeave } = useDataGridHover();

  const apiRef = useGridApiRef();

  const belowLargeScreenWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const columnsToPin = [STUDENTS_COL_FIELD_KEY];

  const students: StudentInRow[] = useMemo(
    () =>
      groupDetails?.students
        ?.filter((student) => !query || filterUser(student.user, query))
        .map((student) => {
          return { ...student.user, absenceRequest: student.absence_request };
        }) ?? [],
    [groupDetails?.students, query],
  );

  const handleCommentChange = useCallback(
    (relationId: string) => (text: string) => {
      actions.setEntry(relationId, {
        ...entries?.[relationId],
        text,
        creator_relation_id: currentStaff?.relation_id,
        creator_title: currentStaff?.title,
        creator_given_name: currentStaff?.given_name,
        creator_last_name: currentStaff?.last_name,
        creator_known_as: currentStaff?.known_as,
      });
    },
    [
      actions,
      currentStaff?.given_name,
      currentStaff?.known_as,
      currentStaff?.last_name,
      currentStaff?.relation_id,
      currentStaff?.title,
      entries,
    ],
  );

  const columns: GridColDef[] = [
    {
      field: STUDENTS_COL_FIELD_KEY,
      sortable: false,
      disableColumnMenu: true,
      width: 260,
      cellClassName: 'studentNameCell',

      renderHeader: () => (
        <TakeAttendanceGridMarkBlankHeaderCell students={students} codes={codes} />
      ),
      renderCell: (props: GridRenderCellParams) => {
        return <TakeAttendanceGridStudentNameCell props={props} />;
      },
    },
    ...(codes || []).map(
      (code): GridColDef => ({
        headerAlign: 'center',
        field: code.id,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: belowLargeScreenWidth ? 100 : undefined,
        cellClassName: 'checkboxCell',
        renderHeader: (props: GridColumnHeaderParams) => {
          return (
            <TakeAttendanceGridCodeHeaderCell
              notificationsEnabled={attendanceNotificationsEnabled}
              code={code}
              onMouseLeave={onMouseLeave}
              onMouseOver={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
                onColumnMouseOver({
                  getCellElement: apiRef.current.getCellElement,
                  colFields: [props.field],
                  cellSelector: '.MuiDataGrid-columnHeader',
                  event,
                  rows,
                })
              }
            />
          );
        },
        renderCell: (props: GridRenderCellParams) => {
          const onCheckboxChange = () => {
            actions.setEntry(props.row.id, {
              ...entries?.[props.row.id],
              relation_id: props.row.id,
              attendance_code_id: code.id,
            });
          };

          return (
            <TakeAttendanceGridCheckboxCell
              onCheckboxChange={onCheckboxChange}
              code={code}
              props={props}
            />
          );
        },
      }),
    ),
    {
      field: COMMENTS_COL_FIELD_KEY,
      cellClassName: 'commentCell',
      width: 40,
      minWidth: belowLargeScreenWidth ? 100 : 40,
      align: 'center',
      renderHeader: () => <TakeAttendanceHeaderCell />,
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      renderCell: (props) => {
        return (
          <TakeAttendanceGridCommentCell props={props} handleCommentChange={handleCommentChange} />
        );
      },
      renderEditCell: (props) => {
        return (
          <TakeAttendanceGridCommentCell
            props={props}
            handleCommentChange={handleCommentChange}
            editMode
          />
        );
      },
    },
  ];

  const rows: TakeAttendanceTableRow[] = useMemo(() => {
    if (!students.length) {
      return [];
    }

    return students.map((student) => ({
      id: student.relation_id,
      student,
    }));
  }, [students]);

  if (isNotificationsFetching) return <Loading />;

  return (
    <Box
      sx={(theme) => ({
        mx: -2.5,
        px: 2.5,
        height: '100%',
        pb: theme.spacing(5.5),

        [theme.breakpoints.down('lg')]: {
          m: theme.spacing(1, -4, 0, 0),
          p: 0,
        },
        ' .MuiDataGrid-withBorderColor': {
          '&.studentNameCell, &.MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            borderRight: theme.mixins.borderValue(),
          },
          '&.commentCell': {
            borderLeft: theme.mixins.borderValue(),
          },
        },
      })}
    >
      <DataGridTable
        rows={rows}
        columns={columns}
        apiRef={apiRef}
        noNavigationOnFirstCol
        columnsToPin={columnsToPin}
        withDataGridHover
        sx={(theme) => ({
          '.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
            '&.studentNameCell': {
              outline: 'none',
            },
          },
          '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: theme.palette.background.paper,
            overflow: 'visible',
            '& .MuiDataGrid-row': {
              overflow: 'visible',
              '& .studentNameCell': {
                overflow: 'visible',
              },
            },
          },
        })}
      />
    </Box>
  );
};
