import { AttentionIcon } from '@schooly/style';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { $UIKitPrefix } from '../../../styles/variables';

import './CloseAccountModal.scss';

export interface CloseAccountInfoProps {
  id?: any;
}

const $class = `${$UIKitPrefix}CloseAccountInfo`;

export const CloseAccountInfo: React.FC<CloseAccountInfoProps> = () => {
  return (
    <>
      <article className={`${$class}__article`}>
        <h3 className={`${$class}__title`}>
          <FormattedMessage id="login-ThisActionWill" />
        </h3>
        <ul className={`${$class}__list`}>
          <li className={`${$class}__item`}>
            <FormattedMessage id="login-DeleteProfiles" />
          </li>
          <li className={`${$class}__item`}>
            <FormattedMessage id="login-DeleteYourOwn" />
          </li>
        </ul>
      </article>
      <article className={`${$class}__article`}>
        <h3 className={`${$class}__title`}>
          <FormattedMessage id="login-ThisActionWillNot" />
        </h3>
        <ul className={`${$class}__list`}>
          <li className={`${$class}__item`}>
            <FormattedMessage id="login-DeleteSchoolRecords" />
          </li>
          <li className={`${$class}__item`}>
            <FormattedMessage id="login-DeleteFamilyMembers" />
          </li>
        </ul>
      </article>
      <p className={`${$class}__warning`}>
        <AttentionIcon /> <FormattedMessage id="login-ThisAction" />
      </p>
    </>
  );
};
