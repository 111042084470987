import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  IconButton,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { ApiError, AssessmentsGrade, useUpdateAssessmentsGradeMutation } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { usePrevious } from '@schooly/hooks/use-previous';
import { EditIcon, Loading, PlusIcon, RollBackIcon, SimpleButton } from '@schooly/style';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { LongTitleVerticalTooltip } from '../../../components/uikit/LongTitleVerticalTooltip/LongTitleVerticalTooltip';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../components/uikit-components/Grid/Grid';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { useAssessmentsGradesForSchool } from '../../../hooks/useAssessmentsGradesForSchool';
import AccessDeniedPage from '../../AccessDenied';

export const SchoolAssessments: FC = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const { permissions } = useAuth();
  const { grades, activeGrades, archivedGrades, fetching, refetching, getAssessmentsGrades } =
    useAssessmentsGradesForSchool();
  const updateAssessmentsGrade = useUpdateAssessmentsGradeMutation();
  const { showError, showNotification } = useNotifications();
  const [showArchived, setShowArchived] = useState(false);
  const [saving, setSaving] = useState(false);
  const loading = saving || refetching;

  const hasActiveGrades = Boolean(activeGrades.length);
  const hasArchivedGrades = Boolean(archivedGrades.length);
  const prevArchivedGradesLength = usePrevious(archivedGrades.length);

  const canManageAssessmentsSelectLists =
    permissions.includes('assessment_manager') && permissions.includes('school_admin');

  useEffect(() => {
    if (archivedGrades.length && !prevArchivedGradesLength) {
      setShowArchived(true);
    }
  }, [archivedGrades.length, prevArchivedGradesLength]);

  const handleRestore = useCallback(
    async (gradeId: string, gradeName: string) => {
      setSaving(true);

      try {
        await updateAssessmentsGrade.mutateAsync({
          listId: gradeId,
          grade: { active: true },
        });
        showNotification({
          textId: 'assessments-GradeRestored',
          values: { gradeName },
          type: 'success',
        });
        await getAssessmentsGrades();
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }

      setSaving(false);
    },
    [updateAssessmentsGrade, showNotification, getAssessmentsGrades, showError],
  );

  const renderGradeRow = useCallback(
    (grade: AssessmentsGrade) => {
      return (
        <GridRowStyled
          key={grade.id}
          sx={
            grade.active
              ? {
                  '&:hover .editIcon': {
                    visibility: 'visible',
                  },
                }
              : {}
          }
          noBorderRadius
        >
          <GridRowItem>
            <GridRowName sx={{ flex: '1 1 25%' }}>{grade.name}</GridRowName>
            <GridRowName sx={{ flex: '1 1 25%' }}>
              {grade.options.map((o) => o.name).join(', ')}
            </GridRowName>
            <GridRowName sx={{ flex: '1 1 50%', minWidth: 0 }}>
              <LongTitleVerticalTooltip
                renderTooltipContent={(longName) => (
                  <Typography variant="h3" color="text.primary">
                    {longName}
                  </Typography>
                )}
              >
                {grade.description}
              </LongTitleVerticalTooltip>
            </GridRowName>
            <GridRowName sx={{ flex: '1 1 100px' }}>
              <Stack alignItems="end">
                {grade.active ? (
                  <IconButton
                    onClick={() => navigate(`/settings/assessments/${grade.id}`)}
                    className="editIcon"
                    sx={{ visibility: 'hidden' }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  <SimpleButton
                    startIcon={<RollBackIcon />}
                    onClick={() => {
                      handleRestore(grade.id, grade.name);
                    }}
                  >
                    {$t({ id: 'action-Restore' })}
                  </SimpleButton>
                )}
              </Stack>
            </GridRowName>
          </GridRowItem>
        </GridRowStyled>
      );
    },
    [$t, handleRestore, navigate],
  );

  if (!canManageAssessmentsSelectLists) {
    return <AccessDeniedPage />;
  }

  const renderContent = () => {
    if (fetching) return <Loading />;

    return (
      <Card>
        <CardHeader
          sx={{ pb: hasActiveGrades || (hasArchivedGrades && showArchived) ? undefined : 2.5 }}
          title={
            <Stack direction="row" alignItems="center">
              <FormattedMessage id="assessments-Grades" />
              {loading ? (
                <Skeleton
                  variant="circular"
                  sx={(theme) => ({
                    ml: 1,
                    width: theme.spacing(2.5),
                    height: theme.spacing(2.5),
                  })}
                />
              ) : (
                hasActiveGrades && <Counter>{activeGrades.length}</Counter>
              )}
            </Stack>
          }
          action={
            <>
              {!loading && hasArchivedGrades && (
                <FormControlLabel
                  sx={(theme) => ({
                    m: theme.spacing(0, 3, 0),
                    '&:hover': {
                      color: 'primary.main',
                      ' .MuiSwitch-track, .Mui-checked+.MuiSwitch-track': { opacity: 1 },
                    },
                  })}
                  control={
                    <Switch
                      checked={showArchived}
                      onChange={(event, value) => setShowArchived(value)}
                    />
                  }
                  label={$t({ id: 'action-ShowArchived' })}
                />
              )}

              {!loading && (
                <SimpleButton
                  onClick={() => navigate('/settings/assessments/new')}
                  startIcon={<PlusIcon />}
                  data-test-id="add-assessment-grade-modal"
                >
                  <FormattedMessage id="assessments-AddGradingSystem" />
                </SimpleButton>
              )}
            </>
          }
        />
        {(hasActiveGrades || (hasArchivedGrades && showArchived)) && (
          <CardContent>
            <GridContainer
              sx={{
                borderRadius: 0,
              }}
            >
              {loading && (
                <Stack>
                  {new Array(grades?.length).fill(true).map((_, index) => (
                    <GridRowStyled
                      key={index}
                      noBorder
                      noBorderRadius
                      sx={{ '&:hover': { backgroundColor: 'background.paper' } }}
                    >
                      <GridRowItem>
                        <GridRowName>
                          <Skeleton />
                        </GridRowName>
                      </GridRowItem>
                    </GridRowStyled>
                  ))}
                </Stack>
              )}
              {!loading && activeGrades.map((grade) => renderGradeRow(grade))}
              {!loading && showArchived && hasArchivedGrades && (
                <>
                  <GridRowStyled
                    noBorderRadius
                    sx={{ '&:hover': { backgroundColor: 'background.paper' } }}
                  >
                    <GridRowItem>
                      <Typography color="text.secondary" variant="h4" mt={hasActiveGrades ? 2 : 0}>
                        <FormattedMessage id="filter-Archived" />
                      </Typography>
                    </GridRowItem>
                  </GridRowStyled>
                  {archivedGrades.map((grade) => renderGradeRow(grade))}
                </>
              )}
            </GridContainer>
          </CardContent>
        )}
      </Card>
    );
  };

  return (
    <MainLayout>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="assessments-AssessmentsSettings" />
      </Box>
      {renderContent()}
      <Outlet />
    </MainLayout>
  );
};
