import { Icon, Stack, Typography } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { SchoolPropertyType } from '@schooly/constants';
import { InformationIcon, SettingsIcon, SimpleButton } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ManageGroupModalTooltip } from 'apps/web/src/pages/Groups/ManageGroupModal/ManageGroupModalTooltip';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

export type SelectOptionsArchivedIconProps = {
  isAdmin: boolean;
  type: SchoolPropertyType | FilterKeys.Subject | FilterKeys.AgeGroup;
  values?: Record<string, string>;
  path?: string;
};

export const SelectOptionsArchivedIcon: FC<SelectOptionsArchivedIconProps> = ({
  isAdmin,
  type,
  values,
  path,
}) => {
  const { $t } = useIntl();
  const navigate = useNavigate();

  return (
    <Icon
      sx={{
        pointerEvents: 'auto',
        cursor: 'auto',
        zIndex: 1,
        '&:hover': {
          color: 'primary.main',
        },
      }}
    >
      <ManageGroupModalTooltip
        offset={[0, -2]}
        sx={{ p: 1.25 }}
        text={
          isAdmin ? (
            <Stack gap={1.25} alignItems="flex-start">
              <Typography color="primary.main">
                <FormattedMessage
                  id={`peopleDetail-EmploymentCaseModal-NoActivePropertiesForSchoolAdmin-${type}`}
                  values={values}
                />
              </Typography>
              <SimpleButton
                startIcon={<SettingsIcon />}
                onClick={() => navigate(path ? path : '/settings')}
                onFocus={(e) => {
                  e.stopPropagation();
                }}
              >
                <Typography>
                  {$t({
                    id: 'peopleDetail-EmploymentCaseModal-SchoolSettings',
                  })}
                </Typography>
              </SimpleButton>
            </Stack>
          ) : (
            <Typography color="primary.main">
              <FormattedMessage
                id={`peopleDetail-EmploymentCaseModal-NoActiveProperties-${type}`}
                values={values}
              />
            </Typography>
          )
        }
        arrow
      >
        <InformationIcon />
      </ManageGroupModalTooltip>
    </Icon>
  );
};
