import { Components, Theme } from '@mui/material';
import { CheckboxIcon, RadioOffIcon } from '@schooly/style';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    icon: <RadioOffIcon className="reset-svg-currentColor" />,
    checkedIcon: <CheckboxIcon className="reset-svg-currentColor" />,
    color: 'default',
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      // padding: 0,
      fontSize: theme.spacing(2.5),

      '& ~ .MuiFormControlLabel-label': {
        ...theme.typography.h3,
        color: undefined,
      },

      '&.Mui-disabled': {
        opacity: 0.5,
      },

      // '.MuiFormControlLabel-labelPlacementStart & ~ .MuiFormControlLabel-label': {
      //   marginRight: theme.spacing(),
      // },
      //
      // '.MuiFormControlLabel-labelPlacementEnd & ~ .MuiFormControlLabel-label': {
      //   marginLeft: theme.spacing(),
      // },
      //
      // '.MuiFormControlLabel-labelPlacementTop & ~ .MuiFormControlLabel-label': {
      //   marginBottom: theme.spacing(),
      // },
      //
      // '.MuiFormControlLabel-labelPlacementBottom & ~ .MuiFormControlLabel-label': {
      //   marginTop: theme.spacing(),
      // },
    }),
  },
};
