import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import useAppLocation from '../../hooks/useAppLocation';
import usePrevious from '../../hooks/usePrevious';
import { RouterStateContext } from './RouterStateContext';

export const WithRouterState: FC<PropsWithChildren> = ({ children }) => {
  const location = useAppLocation();
  const [contextName, setContextName] = useState<string | undefined>();
  const [state, setState] = useState<{ originalData?: unknown } | undefined>();

  const prevLocation = usePrevious(location);

  useEffect(() => {
    const pathRegex = /^([\\/][^\\/]+)(([\\/][^\\/]+)[\\/]?)?(.*)/;

    const m = pathRegex.exec(location?.pathname ?? '');
    const prevM = pathRegex.exec(prevLocation?.pathname ?? '');

    const basePath = m ? `${m[1]}${m[3]}` : undefined;
    const prevBasePath = prevM ? `${prevM[1]}${prevM[3]}` : undefined;

    // TODO: dirty hack for Group edit modal as it relies on a single Context with the Group preview
    //  need to get rid of that later
    if (!(m?.[1] === '/groups' && m?.[4] === 'edit')) {
      // TODO: the same for Group clone route
      if (!m?.[4].startsWith('clone')) {
        // don't reset state if the base path is the same
        if (basePath === prevBasePath) {
          return;
        }
      }
    }

    if (location.state?.reconcile?.contextName) {
      setContextName(location.state.reconcile.contextName);
    }

    setState(
      location.state?.reconcile?.state ? JSON.parse(location.state.reconcile.state) : undefined,
    );
  }, [location, location?.pathname, prevLocation?.pathname]);

  const setContextState = useCallback(
    (newState?: object, saveOriginalData?: boolean) => {
      setState((prevState) => {
        const data: { originalData?: unknown } = { ...(prevState as object), ...newState };

        if (saveOriginalData) {
          const { originalData, ...withoutOriginalData } = data;
          data.originalData = { ...withoutOriginalData };
        }

        return data;
      });
    },
    [setState],
  );

  const onInputChangeHandler = useCallback(
    (stateKey: keyof unknown) => {
      return (e: React.ChangeEvent<HTMLInputElement>) =>
        setContextState({ [stateKey]: e.target.value });
    },
    [setContextState],
  );

  const setStateHandler = useCallback(
    (stateKey: keyof unknown, value?: unknown) => {
      return (callbackValue: unknown) =>
        setContextState({ [stateKey]: value === undefined ? callbackValue : value });
    },
    [setContextState],
  );

  const value = {
    state: state as { originalData?: unknown },
    contextName,
    setState: setState as (newState?: Partial<unknown>, saveOriginalData?: boolean) => void,
    setContextName,
    setContextState,
    onInputChangeHandler,
    setStateHandler,
  };

  return <RouterStateContext.Provider value={value}>{children}</RouterStateContext.Provider>;
};
