import { UserRole } from '@schooly/api';

import { DEFAULT_LIMITED_TO } from './Context/useUserRoleModal';

export function getLimitedToData({
  role,
  defaultPropertyId,
}: {
  role?: UserRole;
  defaultPropertyId?: string;
}) {
  const baseProperties = {
    ...DEFAULT_LIMITED_TO,
    school_property_ids: defaultPropertyId ? [defaultPropertyId] : [],
  };

  if (!role || !role.id) {
    return {
      limited_to: baseProperties,
    };
  }

  const { school_property_ids } = role;

  return {
    limited_to: {
      school_property_ids: school_property_ids ?? baseProperties.school_property_ids,
    },
  };
}
