import { StringOrNull } from '@schooly/api';
import { CreateParentForm } from '@schooly/components/applications';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import { Loading } from '@schooly/style';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ModalLarge } from '../../../components/uikit-components/Modal/Modal.styled';
import { ApplicationRouteState } from '../../../context/applications/ApplicationsContext';
import { useApplication } from '../../../context/applications/useApplication';
import { WithFilters } from '../../../context/filters/WithFilters';
import { getRouteModalPathname } from '../../../helpers/misc';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { useApplicationForm } from '../../../pages/Applications/useApplicationForm';
import { AddParentModal } from './AddParentModal/AddParentModal';
import { ApplicationCreateModalContent } from './ApplicationCreateModalContent';

export const ApplicationCreateModal: React.FC = () => {
  const {
    children,
    parents,
    generalCustomFields,
    addParents,
    removeParent,
    removeChild,
    resetForm,
  } = useApplicationForm('create');

  const { fetching, createApplication } = useApplication();
  const { applicationGeneralCustomFields, fetching: loadingCustomFields } = useCustomFields();

  const { $t } = useIntl();
  const navigate = useNavigate();
  const invalidateQueries = useInvalidateListQueriesFor('student');
  const { showNotification } = useNotifications();
  const [isAddParentModalOpen, showAddParentModal, hideAddParentModal] = useFlag();

  const onChildClick = useCallback(
    (id?: string) => {
      if (id) {
        navigate(`child/${id}`);
      } else {
        navigate('child');
      }
    },
    [navigate],
  );

  const onParentEdit = useCallback(
    ({ profileId, id }: { profileId: StringOrNull; id: string }) => {
      if (profileId) {
        const state: ApplicationRouteState = {
          type: 'application',
          actionType: 'create',
          parents,
        };
        navigate(getRouteModalPathname('adult', { id: profileId }), {
          state,
        });
      } else {
        navigate(`parent/${id}`);
      }
    },
    [navigate, parents],
  );

  const addedParentIds = useMemo(() => {
    return parents.reduce<string[]>((acc, p) => (p.profile_id ? [...acc, p.profile_id] : acc), []);
  }, [parents]);

  const setExistedParents = useCallback(
    (selectedParents: CreateParentForm[]) => {
      const removedParents = addedParentIds.reduce<string[]>((acc, profileId) => {
        const toRemoveId = !selectedParents.some((parent) => profileId === parent.profile_id);
        if (!toRemoveId) return acc;
        const parentId = parents.find((p) => p.profile_id === profileId)?.id;
        return parentId ? [...acc, parentId] : acc;
      }, []);

      if (!!removedParents.length) {
        removedParents.forEach(removeParent);
      }

      addParents(selectedParents);
    },
    [addParents, addedParentIds, parents, removeParent],
  );

  const onContinue = useCallback(() => {
    applicationGeneralCustomFields.length && navigate('general');
  }, [applicationGeneralCustomFields, navigate]);

  const onOpenPreview = useCallback(() => {
    navigate('/applications/new/preview');
  }, [navigate]);

  const onSubmit = useCallback(async () => {
    const applicationId = await createApplication({ parents, children, generalCustomFields });

    if (applicationId) {
      showNotification({
        message: $t({ id: 'applications-SuccessfulCreate' }),
        type: 'success',
        actions: [
          {
            textId: 'applications-Action-View',
            handler: () =>
              navigate(`/applications/${applicationId}`, {
                replace: true,
              }),
            buttonColor: 'light',
          },
        ],
      });
    }

    invalidateQueries();
    navigate('/applications');
    resetForm();
  }, [
    $t,
    children,
    parents,
    generalCustomFields,
    createApplication,
    invalidateQueries,
    navigate,
    resetForm,
    showNotification,
  ]);

  return (
    <>
      <ModalLarge open>
        {fetching || loadingCustomFields ? (
          <Loading />
        ) : (
          <ApplicationCreateModalContent
            children={children}
            parents={parents}
            generalCustomFields={applicationGeneralCustomFields}
            onChildClick={onChildClick}
            onChildRemove={removeChild}
            onContinue={onContinue}
            onOpenPreview={onOpenPreview}
            onSubmit={onSubmit}
            resetApplication={resetForm}
            onParentRemove={removeParent}
            parentActions={[
              { titleTextId: 'applications-AddExisting', handler: showAddParentModal },
              {
                titleTextId: 'applications-CreateNew',
                handler: () => navigate('parent'),
              },
            ]}
            onParentEdit={onParentEdit}
            updateParents={addParents}
            saving={fetching}
          />
        )}
        {isAddParentModalOpen && (
          <WithFilters type="parents">
            <AddParentModal
              onClose={hideAddParentModal}
              addedApplicationParents={parents}
              setApplicationParents={setExistedParents}
            />
          </WithFilters>
        )}
      </ModalLarge>
    </>
  );
};
