import { useCallback, useMemo, useState } from 'react';

export function useFetching() {
  const [fetching, _setFetching] = useState<Record<string, boolean>>({});

  const setFetching = useCallback((id: string, value: boolean) => {
    _setFetching((old) => ({ ...old, [id]: value }));
  }, []);

  return useMemo(() => ({ fetching, setFetching }), [fetching, setFetching]);
}
