import { Stack } from '@mui/material';
import { PageHeader } from '@schooly/components/filters';
import { Loading } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { AnnualPlannerCalendar } from './AnnualPlannerCalendar/AnnualPlannerCalendar';
import { AnnualPlannerPageFilter } from './AnnualPlannerPageFilter';
import { AnnualPlannerPageSchoolYear } from './AnnualPlannerPageSchoolYear';
import { useAnnualPlanner } from './WithAnnualPlanner';

export const AnnualPlannerPageContent: FC = () => {
  const { isLoading, schoolYear, data } = useAnnualPlanner();

  return (
    <MainLayout>
      <PageHeader
        pageTitle={
          <Stack direction="row" gap={1}>
            <FormattedMessage id="annualPlanner-PageTitle" />
            <AnnualPlannerPageSchoolYear />
          </Stack>
        }
      />

      <AnnualPlannerPageFilter />

      {isLoading ? (
        <Loading />
      ) : (
        schoolYear && (
          <AnnualPlannerCalendar
            start={schoolYear.start}
            end={schoolYear.end}
            records={data?.records}
          />
        )
      )}
    </MainLayout>
  );
};
