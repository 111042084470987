import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { format } from 'date-fns';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SignUpForm, useSignUp } from '../../../context/signUps/WithSignUp';
import { InviteesForm } from '../../Events/EventCreateModal/InviteesForm';

export const SignUpInvitees: FC = () => {
  const { $t } = useIntl();
  const { isActive, signUp } = useSignUp();
  const { watch, setValue } = useFormContext<SignUpForm>();

  const withEvent = watch('withEvent');

  useEffect(() => {
    setValue('criteria', withEvent ? undefined : { student_status: [] });
  }, [setValue, withEvent]);

  if (withEvent) {
    return null;
  }

  return (
    <InviteesForm
      isActive={isActive}
      inviteeTypeRules={{
        required: true,
      }}
      isActiveWarning={$t({ id: 'eventSignUps-create-Invitees-isActiveWarning' })}
      inviteeType={signUp?.invitee_type}
      inviteeDescription={$t({ id: 'eventSignUps-create-Invitees-Description' })}
      startDate={format(new Date(), DEFAULT_DATE_FORMAT_FNS)}
      criteriaValidate={(criteria) =>
        !criteria || Object.values(criteria).every((v) => !v?.length)
          ? $t({ id: 'input-ErrorRequired' })
          : undefined
      }
    />
  );
};
