import {
  DELETE_SCHOOL,
  GET_SCHOOL_USER_COUNTS,
  useGetSchoolUserCountsQuery,
  useMutationFromKey,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

const useUserCounts = () => {
  const { schoolId = '' } = useAuth();
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useGetSchoolUserCountsQuery(schoolId, { enabled: !!schoolId });

  const updateCounts = useCallback(() => {
    queryClient.invalidateQueries([GET_SCHOOL_USER_COUNTS]);
  }, [queryClient]);

  return {
    userCounts: data,
    fetching: isLoading,
    error,
    updateCounts,
  };
};

export const USER_COUNTS_UPDATE_INTERVAL = 5 * 60 * 1000;

export const useInitiateUserCountsPolling = () => {
  const { schoolId = '' } = useAuth();

  const { mutateState } = useMutationFromKey([DELETE_SCHOOL]);

  const isSchoolDeleted =
    mutateState?.state.status === 'success' &&
    !!mutateState.state.variables &&
    mutateState.state.variables === schoolId;

  useGetSchoolUserCountsQuery(schoolId, {
    enabled: !!schoolId && !isSchoolDeleted,
    refetchInterval: USER_COUNTS_UPDATE_INTERVAL,
  });
};

export default useUserCounts;
