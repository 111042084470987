import { Button, Dialog, IconButton, styled } from '@mui/material';
import { WithName } from '@schooly/api';
import { CheckIcon, CloseIcon, ModalContent, ModalFooter, ModalHeader, Spin } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { PropsWithChildren } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import FormWrapper, { FormWrapperProps } from '../../../components/ui/FormWrapper';

export const PropertyModalContainer = styled(Dialog)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& .MuiDialog-paper': {
    width: 600,

    [theme.breakpoints.only('lg')]: {
      width: 500,
    },
  },
}));

type PropertyModalProps<T extends FieldValues = FieldValues> = PropsWithChildren<
  {
    user?: WithName;
    isOpen: boolean;
    onClose?: () => void;
    isUpdating: boolean;
    submitDisabled?: boolean;
  } & FormWrapperProps<T>
>;

/**
 * @deprecated Use PropertyModal2 instead
 *
 * This component is not utilizing react-hook-form standard
 * reduce using it as much as possible to fully migrate to utilizing react-hook-form
 */
export const PropertyModal = <T extends FieldValues = FieldValues>({
  children,
  isOpen,
  user,
  form,
  submitDisabled,
  isUpdating,
  onClose,
  onSubmit,
}: PropertyModalProps<T>) => {
  return (
    <PropertyModalContainer
      onClose={isUpdating ? undefined : onClose}
      open={isOpen}
      className="PropertyModal"
    >
      <ModalHeader title={user && getUserFullName(user)} withBorderBottom={false} active>
        <IconButton disabled={isUpdating} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </ModalHeader>
      <FormWrapper form={form} onSubmit={onSubmit}>
        <ModalContent active>{children}</ModalContent>
        <ModalFooter active withBorderTop={false}>
          <Button disabled={isUpdating} variant="outlined" onClick={onClose}>
            <FormattedMessage id="action-Cancel" />
          </Button>
          <Button
            disabled={submitDisabled || isUpdating}
            type="submit"
            endIcon={isUpdating ? <Spin /> : <CheckIcon />}
          >
            <FormattedMessage id="action-Save" />
          </Button>
        </ModalFooter>
      </FormWrapper>
    </PropertyModalContainer>
  );
};
