import { Backdrop, Box, Button, ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { useFlag } from '@schooly/hooks/use-flag';
import React, { FC, PropsWithChildren, useCallback, useRef } from 'react';

import { ExpandedSelect, ExpandedSelectProps } from './ExpandedSelect';
import { SearchInputFilterIconButton } from './SearchInputFilterIconButton';
import { SelectSearchInput } from './SelectSearchInput';

type FilterButtonDropdownProps = {
  onChangeQuery: (v: string) => void;
  query: string;
  onClose?: () => void;
} & Omit<ExpandedSelectProps, 'onClose'>;

export const FilterButtonDropdown: FC<PropsWithChildren<FilterButtonDropdownProps>> = ({
  onChangeQuery,
  query,
  children,
  onClose,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [opened, open, close] = useFlag(false);

  const handleClose = useCallback(() => {
    onClose?.();
    close();
  }, [close, onClose]);

  const icon = (
    <IconButton
      sx={{
        position: 'relative',
        height: 20,
        width: 20,
        margin: 0.25,
        '.MuiButtonBase-root': {
          right: 0,
          color: opened ? 'white' : 'text.primary',
          ':hover': {
            color: 'primary.main',
          },
        },
      }}
    >
      <SearchInputFilterIconButton hasAppliedIndicator={props.hasSelectedValue} onClick={open} />
    </IconButton>
  );

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleClose}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  borderRadius: theme.spacing(1),
                  width: 500,
                  maxWidth: 500,
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0.5, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Box
                sx={{
                  '.header': {
                    minHeight: '36px',
                  },
                }}
              >
                <ExpandedSelect
                  {...props}
                  onClickInputArea={() => inputRef.current?.focus()}
                  width="100%"
                  onClose={close}
                >
                  {children}
                  <SelectSearchInput
                    ref={inputRef}
                    autoFocus
                    value={query}
                    onChangeText={onChangeQuery}
                  />
                </ExpandedSelect>
              </Box>
            }
          >
            {opened ? (
              <Button
                size="small"
                sx={{
                  '&.MuiButton-containedSizeSmall': {
                    color: 'white',
                    overflow: 'visible',
                    padding: 0,
                    minWidth: 30,
                  },
                }}
              >
                {icon}
              </Button>
            ) : (
              icon
            )}
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};
