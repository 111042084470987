import { Box } from '@mui/material';
import {
  AssessmentBase,
  AssessmentForGroup,
  AssessmentForRelation,
  AssessmentsGrade,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { AssessmentStatuses, MD_BREAKPOINT_TABLE_MIN_WIDTH } from '@schooly/constants';
import { SelectedIds } from '@schooly/utils/bulk-actions';
import { mobileDetect } from '@schooly/utils/mobile';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { GridContainer } from '../Grid/Grid';
import { ListViewAssessmentsRowForGroup } from '../ListViewAssessmentsRow/ListViewAssessmentsRowForGroup';
import { ListViewAssessmentsRowForRelation } from '../ListViewAssessmentsRow/ListViewAssessmentsRowForRelation';

import './ListViewAssessments.scss';

export interface ListViewAssessmentsProps {
  type: 'for-group' | 'for-relation';
  assessments: AssessmentBase[];
  lists?: Record<string, AssessmentsGrade>;
  id?: string;
  showAllComments?: boolean;
  wideComments?: boolean;
  onAllCommentsClosed?: () => void;
  onAllCommentsOpen?: () => void;
  onSelectAssessment?: (v: string) => void;
  selectedIds?: SelectedIds;
}

export const ListViewAssessments: FC<ListViewAssessmentsProps> = ({
  type,
  assessments,
  lists,
  id,
  showAllComments,
  wideComments,
  onAllCommentsClosed,
  onAllCommentsOpen,
  onSelectAssessment,
  selectedIds,
}) => {
  const [assessmentsWithComments, setAssessmentsWithComments] = useState<string[]>([]);
  const [assessmentsWithOpenComments, setAssessmentsWithOpenComments] = useState<string[]>([]);
  const { permissions } = useAuth();
  const isMobile = mobileDetect.mobile();

  const addAssessmentWithOpenComment = useCallback((assessmentId: string) => {
    setAssessmentsWithOpenComments((old) => {
      if (old.includes(assessmentId)) {
        return old;
      } else {
        return [...old, assessmentId];
      }
    });
  }, []);

  const addAssessmentWithComment = useCallback(
    (assessmentId: string) =>
      setAssessmentsWithComments((old) => {
        if (old.includes(assessmentId)) {
          return old;
        } else {
          return [...old, assessmentId];
        }
      }),
    [],
  );

  const allCommentsAreOpen = useMemo(() => {
    if (assessmentsWithOpenComments.length) {
      return !assessmentsWithComments.some(
        (assessmentId) => !assessmentsWithOpenComments.includes(assessmentId),
      );
    }
  }, [assessmentsWithComments, assessmentsWithOpenComments]);

  useEffect(() => {
    if (!assessmentsWithOpenComments.length && onAllCommentsClosed) {
      onAllCommentsClosed();
    }
  }, [assessmentsWithOpenComments.length, onAllCommentsClosed]);

  useEffect(() => {
    if (allCommentsAreOpen && onAllCommentsOpen) {
      onAllCommentsOpen();
    }
  }, [allCommentsAreOpen, onAllCommentsOpen]);

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          overflowX: 'scroll',
        },
      })}
    >
      <GridContainer
        sx={(theme) => ({
          overflow: 'visible',
          [theme.breakpoints.down('md')]: {
            minWidth: MD_BREAKPOINT_TABLE_MIN_WIDTH,
            overflowY: 'hidden',
            py: 0.5,
            '@media (orientation: portrait)': {
              minWidth: 0,
            },
          },
        })}
      >
        {assessments.map((assessment) => {
          const canEdit =
            permissions.includes('assessment_manager') &&
            assessment.assessment_status !== AssessmentStatuses.Published &&
            !assessment.autogenerated;

          if (type === 'for-group') {
            return (
              <ListViewAssessmentsRowForGroup
                key={id + assessment.id}
                assessment={assessment as AssessmentForGroup}
                groupId={id}
                canEdit={canEdit}
                onSelect={onSelectAssessment}
                isSelected={selectedIds === 'all' || !!selectedIds?.has(assessment.id)}
                showActions={Boolean(isMobile)}
              />
            );
          }

          const assessmentAndGroupId = `${id}-${assessment.id}-${
            (assessment as AssessmentForRelation).group_id
          }`;

          return (
            <ListViewAssessmentsRowForRelation
              key={assessmentAndGroupId}
              assessment={assessment as AssessmentForRelation}
              lists={lists}
              relationId={id || ''}
              showAllComments={showAllComments}
              wideComments={wideComments}
              isCommentsOpen={assessmentsWithOpenComments.includes(assessmentAndGroupId)}
              onOpenComments={() => addAssessmentWithOpenComment(assessmentAndGroupId)}
              onCloseComments={() =>
                setAssessmentsWithOpenComments((old) =>
                  old.filter((id) => id !== assessmentAndGroupId),
                )
              }
              addAssessmentWithComment={() => addAssessmentWithComment(assessmentAndGroupId)}
            />
          );
        })}
      </GridContainer>
    </Box>
  );
};
