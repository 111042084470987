import { Button, Stack, Typography } from '@mui/material';
import { listProfiles, ProfileSearchResult, WithName } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { SchoolUserRole } from '@schooly/constants';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { ModalContent, ModalHeader, ModalPanel, PlusIcon } from '@schooly/style';
import { Loading } from '@schooly/style';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useLastRefreshTimeContext } from '../../../context/LastRefreshTimeContext';
import { getUserTypeTextId } from '../../../helpers/misc';
import usePagedApiResourceWithFilter, {
  GetResourceFunction,
} from '../../../hooks/usePagedApiResourceWithFilter';
import SearchInput from '../../ui/SearchInput';
import { NoSearchResultsFound } from '../NoSearchResultsFound/NoSearchResultsFound';
import PersonCardSelectable from '../PersonCard/PersonCardSelectable';
import FilteredUsersList from './FilteredUsersList';

import './index.scss';

interface AdultsExternalSidebarProps {
  childProfile?: WithName;
  selectedUserIds: (string | undefined)[];
  onUserSelect: (user: ProfileSearchResult) => void;
  onUserCreate: (prepopulatedName?: string) => void;
}

const AdultsExternalSidebar: React.FC<AdultsExternalSidebarProps> = ({
  childProfile,
  selectedUserIds,
  onUserSelect,
  onUserCreate,
}) => {
  const { formatMessage, $t } = useIntl();
  const { schoolId } = useAuth();
  const userTypePlural = formatMessage({ id: getUserTypeTextId('adult', true) }).toLowerCase();
  const [filter, setFilter] = useState<string>('');
  const { lastRefreshTime } = useLastRefreshTimeContext();

  const getUserList = useCallback<GetResourceFunction<ProfileSearchResult>>(
    ({ pageSize, pageNumber, query }) => {
      if (!schoolId) {
        return undefined;
      }

      return listProfiles({
        schoolId,
        type: 'adult',
        pageSize,
        pageNumber,
        query,
        lastNameForSuggestion: childProfile?.last_name.toLowerCase(),
        min: true,
      });
    },
    [schoolId, childProfile],
  );

  const {
    displayedList: displayedUserList,
    canShowMore,
    totalCount,
    isSearching,
    isFetching,
    handleShowMore,
  } = usePagedApiResourceWithFilter<ProfileSearchResult>({
    getResource: getUserList,
    filter,
    onFilterUpdate: setFilter,
    lastRefreshTime,
  });

  const filteredUsers = useMemo(
    () => displayedUserList?.results.filter((u) => !selectedUserIds.includes(u.user_id)),
    [displayedUserList, selectedUserIds],
  );

  const suggestedUsers = useMemo(
    () => displayedUserList?.suggested?.filter((u) => !selectedUserIds.includes(u.user_id)),
    [displayedUserList, selectedUserIds],
  );

  const totalCountWithSuggested = useMemo(
    () => (totalCount || 0) + (suggestedUsers?.length || 0),
    [totalCount, suggestedUsers],
  );

  const handleCreateUserClick = useCallback(() => {
    onUserCreate(!displayedUserList?.results.length && !!filter ? filter : '');
  }, [displayedUserList, filter, onUserCreate]);

  const loaderRef = useInfiniteScroll(isFetching, handleShowMore);

  const content = useMemo(() => {
    if (!childProfile) {
      return null;
    }

    if (!filter && !totalCount) {
      return (
        <p className="text-center text-muted">
          <FormattedMessage
            id="people-NoUsersExist"
            values={{
              userTypePlural,
            }}
          />
        </p>
      );
    }
    if (!totalCount) {
      return isSearching ? (
        <p className="text-center text-muted">
          <FormattedMessage id="people-Searching" />
        </p>
      ) : (
        <NoSearchResultsFound />
      );
    }

    if (filter) {
      return (
        <FilteredUsersList
          filteredUsers={filteredUsers}
          canShowMore={canShowMore}
          isFetching={isFetching}
          onShowMore={handleShowMore}
          onUserSelect={onUserSelect}
          profileSchoolContext={SchoolUserRole.Parent}
        />
      );
    }

    const suggestedUserIds = suggestedUsers?.map(({ user_id }) => user_id);
    const otherUsers = filteredUsers?.filter((u) => !suggestedUserIds?.includes(u.user_id));

    return (
      <>
        {!!suggestedUsers?.length && (
          <Stack gap={1}>
            <Typography variant="h4">
              <FormattedMessage id="people-MaybeLooking" />
            </Typography>
            {suggestedUsers.map((user) => (
              <PersonCardSelectable
                key={user.user_id}
                user={user}
                userType="adult"
                onClick={() => onUserSelect(user)}
                isUsernameClickable
                isUsernameLinkView
                isListItem
                profileSchoolContext={SchoolUserRole.Parent}
              />
            ))}
          </Stack>
        )}
        {!!otherUsers?.length && (
          <Stack pt={2.5} gap={1}>
            {!!suggestedUsers?.length && (
              <Typography variant="h4">
                <FormattedMessage
                  id="people-OtherType"
                  values={{
                    userTypePlural,
                  }}
                />
              </Typography>
            )}
            <Stack>
              {otherUsers.map((user) => (
                <PersonCardSelectable
                  key={user.user_id}
                  user={user}
                  userType="adult"
                  onClick={() => onUserSelect(user)}
                  isUsernameClickable
                  isUsernameLinkView
                  isListItem
                  profileSchoolContext={SchoolUserRole.Parent}
                />
              ))}
              {canShowMore && (
                <div className="py-3">
                  {isFetching && <Loading />}
                  <div ref={loaderRef} />
                </div>
              )}
            </Stack>
          </Stack>
        )}
      </>
    );
  }, [
    canShowMore,
    childProfile,
    filter,
    filteredUsers,
    handleShowMore,
    isFetching,
    isSearching,
    loaderRef,
    onUserSelect,
    suggestedUsers,
    totalCount,
    userTypePlural,
  ]);

  const isLoading = !displayedUserList || !childProfile;

  return (
    <Stack sx={{ height: '100%' }}>
      <ModalHeader title={$t({ id: 'userType-adult-plural' })} active />

      <Stack sx={{ px: 2.5, pt: 2.5 }}>
        <SearchInput
          value={filter}
          onChange={setFilter}
          isValueRemovable
          isLoading={isSearching}
          placeholder={formatMessage(
            { id: 'people-SearchAmongTypeCount' },
            {
              count: totalCountWithSuggested,
              userTypePlural,
            },
          )}
        />
      </Stack>
      <ModalContent sx={{ height: '100%', pt: 0 }} active>
        {isLoading ? <Loading /> : content}
      </ModalContent>

      <ModalPanel
        active
        withBorderTop
        sx={{
          px: 2.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 63,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderRadius: 1,
            width: '100%',
          }}
        >
          <Button startIcon={<PlusIcon />} onClick={handleCreateUserClick}>
            <FormattedMessage id="migration-CreateNewAdult" />
          </Button>
        </Stack>
      </ModalPanel>
    </Stack>
  );
};

export default AdultsExternalSidebar;
