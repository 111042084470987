import { CreateCustomField } from '@schooly/components/applications';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { Loading, ModalSmall } from '@schooly/style';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useApplication } from '../../../context/applications/useApplication';
import { useRouter } from '../../../context/router/useRouter';
import { useCustomFields } from '../../../hooks/useCustomFields';
import { useApplicationForm } from '../useApplicationForm';
import { ApplicationCreateGeneralInfoModalContent } from './ApplicationCreateGeneralInfoModalContent';

export const ApplicationCreateGeneralInfoModal: React.FC = () => {
  const {
    children,
    parents,
    generalCustomFields,
    resetForm,
    prepareCreateFormData,
    addGeneralCustomFields,
  } = useApplicationForm('create');

  const { createApplication, fetching: loadingCreateApplication } = useApplication();
  const navigate = useNavigate();
  const { showNotification } = useNotifications();
  const { $t } = useIntl();
  const { goBack } = useRouter();
  const { applicationGeneralCustomFields, fetching: loadingCustomFields } = useCustomFields({
    refetchOnMount: 'always',
  });

  const invalidateQueries = useInvalidateListQueriesFor('student');

  useEffect(() => {
    if (!applicationGeneralCustomFields?.length || generalCustomFields?.length) {
      return;
    }

    prepareCreateFormData(applicationGeneralCustomFields || []);
  }, [applicationGeneralCustomFields, generalCustomFields, prepareCreateFormData]);

  const handleSubmit = useCallback(
    (customFields: CreateCustomField[]) => {
      const onSubmit = async () => {
        const applicationId = await createApplication({
          parents,
          children,
          generalCustomFields: customFields,
        });

        if (applicationId) {
          showNotification({
            message: $t({ id: 'applications-SuccessfulCreate' }),
            type: 'success',
            actions: [
              {
                textId: 'applications-Action-View',
                handler: () =>
                  navigate(`/applications/${applicationId}`, {
                    replace: true,
                  }),
                buttonColor: 'light',
              },
            ],
          });
          invalidateQueries();
          navigate('/applications');
          resetForm();
        }
      };

      addGeneralCustomFields(customFields);
      setTimeout(onSubmit);
    },
    [
      $t,
      addGeneralCustomFields,
      children,
      createApplication,
      invalidateQueries,
      navigate,
      parents,
      resetForm,
      showNotification,
    ],
  );

  const hanldeGoBack = useCallback(
    (customFields: CreateCustomField[]) => {
      addGeneralCustomFields(customFields);
      goBack();
    },
    [addGeneralCustomFields, goBack],
  );

  const hanldeOpenPreivew = useCallback(
    (customFields: CreateCustomField[]) => {
      addGeneralCustomFields(customFields);
      navigate('/applications/new/preview');
    },
    [addGeneralCustomFields, navigate],
  );

  return (
    <ModalSmall open>
      {loadingCustomFields ? (
        <Loading />
      ) : (
        <ApplicationCreateGeneralInfoModalContent
          customFields={generalCustomFields || applicationGeneralCustomFields || []}
          onSubmit={handleSubmit}
          onGoBack={hanldeGoBack}
          onOpenPreview={hanldeOpenPreivew}
          resetApplication={resetForm}
          saving={loadingCreateApplication}
        />
      )}
    </ModalSmall>
  );
};
