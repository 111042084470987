import { Icon, IconButton, Stack, Typography } from '@mui/material';
import { Language, LanguageLevel } from '@schooly/api';
import { getLabelIdForOption, Languages } from '@schooly/constants';
import { EditIcon, Language3Icon, theme } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { GridRowName } from 'apps/web/src/components/uikit-components/Grid/Grid';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationPreviewRow } from './ApplicationPreviewRow';

interface LanguageRowProps {
  language: Language;
  editable?: boolean;
  onEdit?: () => void;
  withoutDivider?: boolean;
  updating?: boolean;
  updatingIcon?: React.ReactNode;
  readonlyIcon?: React.ReactNode;
  withoutActionBackground?: boolean;
}

export const LanguageRow: FC<LanguageRowProps> = ({
  language,
  editable,
  onEdit,
  updatingIcon,
  updating,
  readonlyIcon,
  withoutActionBackground,
}) => {
  const { formatMessage } = useIntl();
  const [currentLanguage, speakingLevel, writingLevel] = language;

  const editIcon = useMemo(() => {
    if (!editable) {
      return readonlyIcon;
    }
    if (updating) {
      return updatingIcon;
    }

    return (
      <Stack>
        <IconButton className="languageEditIcon" inverse sx={{ visibility: 'hidden' }}>
          <EditIcon />
        </IconButton>
      </Stack>
    );
  }, [editable, readonlyIcon, updating, updatingIcon]);

  return (
    <ApplicationPreviewRow
      onClick={onEdit}
      sxProps={{
        ...(withoutActionBackground && {
          '&:hover': {
            background: theme.palette.background.paper,
            '& .MuiTypography-root:not(.language-level)': {
              color: `${theme.palette.common.grey2} !important`,
            },
          },
        }),
        ...(editable && { cursor: 'pointer' }),
        '& :hover': {
          '.languageEditIcon': {
            visibility: 'visible',
          },
        },
      }}
    >
      <Icon>
        <Language3Icon className="reset-svg-currentColor" />
      </Icon>
      <Stack direction="row" alignItems="center" flexGrow={1}>
        <GridRowName>
          {Number.isInteger(currentLanguage) &&
            formatMessage({
              id: getLabelIdForOption({
                key: Languages[currentLanguage],
                optionsKey: 'language',
              }),
            })}
        </GridRowName>
        <Stack direction="row" flex="0 0 60%">
          <GridRowName variant="body1">
            <Stack direction="row" gap={0.5}>
              <Typography
                className="language-level"
                sx={{
                  color: (theme) => `${theme.palette.common.grey} !important`,
                }}
              >
                S:
              </Typography>
              {Number.isInteger(speakingLevel) &&
                formatMessage({ id: `applications-Language-${LanguageLevel[speakingLevel]}` })}
            </Stack>
          </GridRowName>
          <GridRowName variant="body1">
            <Stack direction="row" gap={0.5}>
              <Typography
                className="language-level"
                sx={{
                  color: (theme) => `${theme.palette.common.grey} !important`,
                }}
              >
                W:
              </Typography>
              {Number.isInteger(writingLevel) &&
                formatMessage({ id: `applications-Language-${LanguageLevel[writingLevel]}` })}
            </Stack>
          </GridRowName>
          {editIcon}
        </Stack>
      </Stack>
    </ApplicationPreviewRow>
  );
};
