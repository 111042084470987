import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { GroupType, GroupWithMembers } from '@schooly/api';
import { SchoolPeriodLabel } from '@schooly/components/filters';
import { ArchiveIcon, CrossIcon, EditIcon, LockIcon } from '@schooly/style';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LongTitleVerticalTooltip } from '../../../components/uikit/LongTitleVerticalTooltip/LongTitleVerticalTooltip';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import { GroupPreviewModalCloneButton } from './GroupPreviewModalCloneButton';

interface EditableProps {
  onEditClick: (property?: keyof GroupWithMembers) => void;
  editable?: boolean;
}

export interface ViewGroupModalInfoProps extends EditableProps {
  group?: GroupWithMembers;
  isExpired?: boolean;
  validity?: string;
  onModalClose: VoidFunction;
  active?: boolean;
  showEditButton?: boolean;
  showCloneButton?: boolean;
  withGoBack?: boolean;
  periodLabel?: string;
}

export const GroupPreviewModalInfoV2: React.FC<ViewGroupModalInfoProps> = ({
  group,
  validity = '-',
  isExpired,
  onModalClose,
  showEditButton,
  onEditClick,
  active,
  showCloneButton,
  withGoBack,
  periodLabel,
}) => {
  const { $t } = useIntl();

  const subHeaderContent = useMemo(
    () => (
      <Stack gap={5} direction="row" alignItems="baseline" overflow="hidden">
        <Box sx={{ whiteSpace: 'nowrap' }}>
          {periodLabel ? (
            <SchoolPeriodLabel label={periodLabel} dateRange={validity} />
          ) : (
            <Typography color="text.primary">{validity}</Typography>
          )}
        </Box>
        <Box sx={{ overflow: 'hidden' }}>
          <LongTitleVerticalTooltip
            renderTooltipContent={(longName) => (
              <Typography color="text.primary">{longName}</Typography>
            )}
            getOffset={(height) => [-8, -(height + 12)]}
          >
            {group?.description ?? ''}
          </LongTitleVerticalTooltip>
        </Box>
      </Stack>
    ),
    [group?.description, periodLabel, validity],
  );

  const actionsContent = useMemo(
    () => (
      <>
        {(group?.subject?.name || group?.group_type) && (
          <Box
            sx={(theme) => ({
              border: theme.mixins.border(),
              borderRadius: '5px',
            })}
          >
            {(() => {
              switch (group?.group_type) {
                case GroupType.TutorGroup:
                  return (
                    <Box px={1.75} py={0.25} whiteSpace="nowrap">
                      <Typography variant="h3" color="text.primary">
                        <FormattedMessage id="groups-TutorGroup" />
                      </Typography>
                    </Box>
                  );
                case GroupType.Subject:
                default:
                  return (
                    group?.subject?.name && (
                      <Stack direction="row" alignItems="center" px={1.75} py={0.25} gap={1}>
                        {group?.subject?.archived && (
                          <Tooltip title={$t({ id: 'schoolProperty-Archived-subject' })}>
                            <IconButton inverse>
                              <ArchiveIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Box whiteSpace="nowrap">
                          <Typography variant="h3" color="text.primary">
                            {group?.subject?.name}
                          </Typography>
                        </Box>
                      </Stack>
                    )
                  );
              }
            })()}
          </Box>
        )}

        {showEditButton &&
          (isExpired ? (
            <Tooltip title={$t({ id: 'groups-NoModifyExpiredGroup' })}>
              <IconButton sx={{ cursor: 'default !important' }}>
                <LockIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton onClick={() => onEditClick()} data-test-id="modal-edit">
              <EditIcon />
            </IconButton>
          ))}
        {showCloneButton && <GroupPreviewModalCloneButton group={group} />}
        <IconButton data-test-id="modal-close" onClick={onModalClose}>
          <CrossIcon />
        </IconButton>
      </>
    ),
    [$t, group, showEditButton, isExpired, onEditClick, onModalClose, showCloneButton],
  );

  return (
    <Box>
      <ModalHeaderV2
        active={active}
        title={
          <LongTitleVerticalTooltip
            renderTooltipContent={(longName) => <Typography variant="h2">{longName}</Typography>}
            withoutArrow
            getOffset={(height) => [-8, -(height + 12)]}
          >
            {group?.name}
          </LongTitleVerticalTooltip>
        }
        withGoBack={withGoBack}
        subHeader={subHeaderContent}
      >
        {actionsContent}
      </ModalHeaderV2>
    </Box>
  );
};
