import { Box, Icon, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import {
  Guardian,
  GuardianOfRelation,
  useGetStudentMembership,
  useSendInviteMutation,
} from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import {
  DropdownSelect,
  DropdownSelectProps,
  SelectContentSkeleton,
} from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolInviteStatus } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ArrowDownV2Icon,
  Attention2Icon,
  CrossIcon,
  DoneIcon,
  InformationIcon,
  MailIcon,
  SimpleButton,
  Spin,
  StarIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, PropsWithChildren, ReactNode, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

type StudentDefaultPayerSelectProps = PropsWithChildren<{
  schoolId: string;
  relationId: string;
  selectedId?: string;
  onSelectId: (v: string) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const StudentDefaultPayerSelect: FC<StudentDefaultPayerSelectProps> = ({
  schoolId,
  relationId,
  selectedId,
  onSelectId,
  placeholder,
  ...dropdownProps
}) => {
  const [showWarning, setShowWarning] = useState(true);
  const [opened, open, close] = useFlag();
  const { formatMessage } = useIntl();

  const { data, isLoading } = useGetStudentMembership(
    { schoolId, id: relationId },
    {
      refetchOnMount: 'always',
    },
  );

  const dropdownRef = useRef<DropdownSelect | null>(null);
  const selectedGuardian = data?.guardian_relations.find(
    ({ guardian }) => guardian.relation_id === selectedId,
  );

  const renderContent = useCallback(() => {
    if (isLoading || !data?.guardian_relations) return <SelectContentSkeleton />;

    return (
      <Box padding={1}>
        {data?.guardian_relations.map((adult) => {
          const isSelected = selectedId === adult.guardian.relation_id;
          const isNotActive = adult.guardian.invite_status !== SchoolInviteStatus.Active;

          return (
            <Stack
              key={adult.id}
              onClick={() => {
                onSelectId(adult.guardian.relation_id);
                setShowWarning(true);
                dropdownRef.current?.close();
              }}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              px={0.5}
              gap={2}
              mb={0.5}
              sx={(theme) => ({
                borderRadius: theme.spacing(0.5),
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                  '& .MuiTypography-h3': {
                    color: theme.palette.primary.main,
                  },
                },
              })}
            >
              <AdultRow isActive={isSelected} adult={adult} />
              <Stack flexDirection="row" alignItems="center" gap={2}>
                {isNotActive && (
                  <Box
                    sx={(theme) => ({
                      width: theme.spacing(1),
                      height: theme.spacing(1),
                      borderRadius: '50%',
                      backgroundColor: theme.palette.warning.main,
                    })}
                  />
                )}
                <Icon
                  sx={{
                    visibility: isSelected ? 'visible' : 'hidden',
                    color: 'primary.main',
                  }}
                >
                  <DoneIcon />
                </Icon>
              </Stack>
            </Stack>
          );
        })}
      </Box>
    );
  }, [data?.guardian_relations, isLoading, onSelectId, selectedId]);

  return (
    <Box>
      <Stack>
        <DropdownSelect
          {...dropdownProps}
          ref={dropdownRef}
          placeholder={placeholder}
          hasValues={!!selectedId}
          renderContent={renderContent}
          sx={(theme) => ({
            borderColor: opened ? undefined : 'transparent',
            outlineColor: 'transparent !important',
            '&.MuiStack-root': {
              display: opened ? 'flex' : 'inline-block',
              transition: 'all .2s, border-color 0s',
            },
            ' .right-icon': {
              right: opened ? undefined : theme.spacing(2.5),
            },
          })}
          onOpen={open}
          onClose={close}
          renderRightIcon={
            opened
              ? undefined
              : () => (
                  <Icon
                    sx={(theme) => ({
                      width: theme.spacing(1),
                      transform: 'rotate(180deg)',
                      path: { stroke: theme.palette.common.grey },
                    })}
                  >
                    <ArrowDownV2Icon />
                  </Icon>
                )
          }
        >
          {() => (
            <>
              {selectedId && selectedGuardian && !isLoading ? (
                <>
                  <AdultRow
                    adult={selectedGuardian}
                    isActive
                    tooltip={
                      opened ? undefined : (
                        <>
                          <Typography mb={2}>
                            {formatMessage({ id: 'profile-AllNewProducts' })}
                          </Typography>
                          <Typography>
                            {formatMessage({ id: 'profile-IfACompanyStopsPaying' })}
                          </Typography>
                        </>
                      )
                    }
                  />
                </>
              ) : (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Box minWidth={30} height={30} my={-1}>
                    <Skeleton variant="circular" />
                  </Box>
                  <Box width={160}>
                    <Skeleton variant="text" />
                  </Box>
                </Stack>
              )}
            </>
          )}
        </DropdownSelect>
      </Stack>
      {!isLoading && showWarning && (
        <InviteWarning
          guardian={selectedGuardian?.guardian}
          schoolId={schoolId}
          onHide={() => setShowWarning(false)}
        />
      )}
    </Box>
  );
};

interface InviteWarningProps {
  guardian?: Guardian;
  schoolId: string;
  warningText?: ReactNode;
  onHide: () => void;
}

export const InviteWarning: FC<InviteWarningProps> = ({
  guardian,
  schoolId,
  onHide,
  warningText,
}) => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const sendInvite = useSendInviteMutation();
  const { isLoading } = sendInvite;
  const [inviteSentIds, setInviteSentIds] = useState<string[]>([]);

  const userInvited = guardian?.invite_status === SchoolInviteStatus.Invited;
  const inviteSent = guardian && inviteSentIds.includes(guardian.relation_id);

  const buttonTextId = () => {
    if (isLoading) return 'sending-invite';
    if (inviteSent) return 'inviteStatus-Invited';
    if (userInvited) return 'resend-invite';
    return 'send-invite';
  };

  const buttonIcon = () => {
    if (isLoading) return <Spin />;
    if (inviteSent) return <DoneIcon />;
    return <MailIcon />;
  };

  const sendInviteHandler = useCallback(() => {
    if (!guardian) return;

    sendInvite.mutate(
      { schoolId, relationId: guardian.relation_id },
      {
        onError: showError,
        onSuccess: () => {
          setInviteSentIds((prev) => [...prev, guardian.relation_id]);
        },
      },
    );
  }, [guardian, schoolId, sendInvite, showError]);

  if (!guardian || !guardian.email) return null;
  if (guardian.invite_status === SchoolInviteStatus.Active) return null;

  return (
    <Stack
      sx={(theme) => ({
        border: `1px solid ${theme.palette.warning.main}`,
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.warning.superLight,
        flexDirection: 'row',
        gap: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 1.5, 1.5, 2),
      })}
    >
      <Stack flexDirection="row" alignItems="center" gap={1.5}>
        <Icon
          sx={{
            color: 'warning.main',
            '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
          }}
        >
          <Attention2Icon />
        </Icon>
        <Typography variant="h3" color="warning.main">
          {warningText
            ? warningText
            : $t(
                {
                  id: userInvited
                    ? 'profile-HasNotAcceptTheInvitation'
                    : 'profile-NotInvitedToSchooly',
                },
                { name: getUserFullName(guardian) },
              )}
        </Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Box
          sx={(theme) => ({
            borderRadius: theme.spacing(0.5),
            background: theme.palette.background.paper,
          })}
        >
          <SimpleButton
            size="small"
            sx={(theme) => ({
              margin: theme.spacing(0.75, 1.5),
              '&.Mui-disabled': { color: 'primary.main' },
              whiteSpace: 'nowrap',
            })}
            disabled={isLoading || inviteSent}
            startIcon={buttonIcon()}
            onClick={sendInviteHandler}
          >
            {$t({ id: buttonTextId() })}
          </SimpleButton>
        </Box>
        <IconButton inverse onClick={onHide}>
          <CrossIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

type AdultRowProps = {
  adult: GuardianOfRelation;
  isActive: boolean;
  tooltip?: ReactNode;
};

const AdultRow: FC<AdultRowProps> = ({ adult, isActive, tooltip }) => (
  <Stack flexDirection="row" gap={1} alignItems="center" minHeight={34}>
    <AvatarAuth user={adult.guardian} />
    <TypographyWithOverflowHint variant="h3" color={isActive ? 'primary.main' : 'text.primary'}>
      {getUserFullName(adult.guardian)}
    </TypographyWithOverflowHint>
    {adult.primary && !tooltip && (
      <Icon>
        <StarIcon />
      </Icon>
    )}
    {tooltip && (
      <Tooltip title={tooltip} sx={{ zIndex: 10 }}>
        <IconButton inverse>
          <InformationIcon />
        </IconButton>
      </Tooltip>
    )}
  </Stack>
);
