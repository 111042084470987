import { IconButton } from '@mui/material';
import { AssessmentForGroup, DATE_FORMAT_SHORT_FNS } from '@schooly/api';
import { CheckboxSquareIconButton, EditIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC, MouseEventHandler, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AssessmentsReportsList } from '../../../pages/Assessments/AssessmentsReportsList/AssessmentsReportsList';
import { AssessmentStatusTag } from '../AssessmentStatusTag/AssessmentStatusTag';
import { GridRowDate, GridRowItem, GridRowName, GridRowStyled } from '../Grid/Grid';
import {
  ListViewRowActions,
  ListViewRowReports,
  ListViewRowStatus,
} from './ListViewAssessmentsRow.styled';

export interface ListViewAssessmentsRowForGroupProps {
  isSelected?: boolean;
  onSelect?: (v: string) => void;
  assessment: AssessmentForGroup;
  groupId?: string;
  canEdit: boolean;
  showActions?: boolean;
}

export const ListViewAssessmentsRowForGroup: FC<ListViewAssessmentsRowForGroupProps> = ({
  assessment,
  groupId,
  canEdit,
  isSelected,
  onSelect,
  showActions,
}) => {
  const navigate = useNavigate();

  const handleSelect: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      onSelect?.(assessment.id);
    },
    [assessment.id, onSelect],
  );

  return (
    <GridRowStyled
      sx={(theme) => ({
        '.date, .checkbox': {
          transition: 'opacity .2s',
        },
        '.checkbox': {
          position: 'absolute',
          left: theme.spacing(2),
        },
        ...(isSelected
          ? {
              '.date': {
                opacity: 0,
              },
            }
          : {
              '&:not(:hover) .checkbox': {
                opacity: 0,
              },
              '&:hover': {
                '.date': {
                  opacity: 0,
                },
                '.checkbox': {
                  opacity: 1,
                },
              },
            }),
      })}
    >
      <Link to={`/assessments/${assessment.id}${groupId ? `?group_id=${groupId}` : ''}`}>
        <GridRowItem sx={{ position: 'relative' }}>
          <GridRowDate className="date">
            {format(newDateTimezoneOffset(assessment.assessment_date), DATE_FORMAT_SHORT_FNS)}
          </GridRowDate>
          <CheckboxSquareIconButton
            onClick={handleSelect}
            isActive={isSelected}
            className="checkbox"
          />
          <GridRowName>{assessment.name}</GridRowName>
          {assessment.reports?.length ? (
            <ListViewRowReports>
              <AssessmentsReportsList reports={assessment.reports} />
            </ListViewRowReports>
          ) : null}
          <ListViewRowStatus>
            <AssessmentStatusTag assessment={assessment} flat />
          </ListViewRowStatus>
          <ListViewRowActions showActions={showActions}>
            {canEdit && (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/assessments/${assessment.id}/edit`);
                }}
                inverse
              >
                <EditIcon />
              </IconButton>
            )}
          </ListViewRowActions>
        </GridRowItem>
      </Link>
    </GridRowStyled>
  );
};
