import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DateSelect } from '@schooly/components/filters';
import { DATE_FORMAT } from '@schooly/constants';
import { CrossIcon, EditIcon, Loading, ModalSmall, Spin } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { SimpleButton } from '../../../components/uikit/SimpleButton/SimpleButton';
import { ModalMain, ModalPanel } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalConfirmationDialog } from '../../../components/uikit-components/Modal/ModalConfirmationDialog';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalGroupsList } from '../../../components/uikit-components/Modal/ModalGroupsList';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import {
  ModalHeaderInput,
  ModalHeaderTitle,
} from '../../../components/uikit-components/Modal/ModalHeader.styled';
import { ModalPeopleExtensionPanel } from '../../../components/uikit-components/Modal/ModalPeopleExtensionPanel';
import { useFormError } from '../../../hooks/useFormError';
import { formatDateDefault } from '../../../utils/formatDate';
import { AddGroupsExternalSidebar } from './AddGroupsExternalSidebar';
import {
  AttendanceRegisterModalMode,
  useAttendanceRegisterCreateModal,
} from './useAttendanceRegisterCreateModal';

export const AttendanceRegisterCreateModal: React.FC = () => {
  const { form, actions, registerId, ...contextState } = useAttendanceRegisterCreateModal();
  const { renderError } = useFormError();
  const { $t } = useIntl();
  const isActive = contextState.mode === AttendanceRegisterModalMode.Initial;

  const isDateLoading = contextState.groupsLoadingOnDateChange;

  const isDateFieldFocused = contextState.focusedField === 'date';

  const handleOpenDatePicker = useCallback(() => {
    if (isDateFieldFocused) {
      actions.clearFocusField();
    }
  }, [actions, isDateFieldFocused]);

  const date = formatDateDefault(contextState.date);

  return (
    <ModalSmall open onClose={() => actions.closeModal()}>
      {contextState.loading ? (
        <Loading />
      ) : (
        <form onSubmit={form.onSubmit(() => actions.submitRegister())}>
          <ModalHeader
            active={isActive}
            title={
              isActive ? (
                <ModalHeaderInput
                  placeholder={$t({ id: 'attendance-RegisterName' })}
                  value={contextState.name}
                  autoFocus={contextState.focusedField === 'name'}
                  onChange={actions.setName}
                  error={Boolean(contextState.errors?.name)}
                  helperText={renderError(contextState.errors?.name)}
                />
              ) : (
                <Stack direction="row" gap={1} alignItems="center">
                  <Box>
                    <ModalHeaderTitle
                      endIcon={<EditIcon />}
                      iconsOnHover={!!contextState.name}
                      onClick={actions.onFieldEditClickHandler('name')}
                    >
                      {contextState.name}
                    </ModalHeaderTitle>
                  </Box>
                  {contextState.errors?.name && (
                    <Typography variant="body2" color="error">
                      {renderError(contextState.errors.name)}
                    </Typography>
                  )}
                </Stack>
              )
            }
          >
            <IconButton onClick={() => actions.closeModal()}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalPanel withBorderBottom active={isActive}>
            {isActive ? (
              <DateSelect
                date={contextState.date}
                onSetDate={actions.onDateChange}
                onOpen={handleOpenDatePicker}
                requiredLabel="required"
                placeholder={$t({ id: 'attendance-RegisterDate' })}
                isLoading={isDateLoading}
                disabled={isDateLoading}
                opened={isDateFieldFocused || undefined}
                renderRightIcon={isDateLoading ? () => <Spin /> : undefined}
                error={
                  contextState.errors?.date
                    ? {
                        type: 'validate',
                        message: renderError(contextState.errors.date) || '',
                      }
                    : undefined
                }
              />
            ) : (
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage id="attendance-RegisterDate" />
                </Typography>

                <Stack direction="row" gap={1} alignItems="center">
                  <Box>
                    <SimpleButton
                      endIcon={<EditIcon />}
                      iconsOnHover={!!contextState.date}
                      onClick={actions.onFieldEditClickHandler('date')}
                    >
                      {contextState.date &&
                        format(newDateTimezoneOffset(contextState.date), DATE_FORMAT)}
                    </SimpleButton>
                  </Box>
                  {contextState.errors?.date && (
                    <Typography variant="body2" color="error">
                      {renderError(contextState.errors?.date)}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}
          </ModalPanel>

          <ModalPeopleExtensionPanel
            titleId="section-Groups"
            addActionId="groups-AddGroups"
            editActionId="groups-EditGroups"
            count={contextState.selectedGroupIds?.length}
            disabled={!contextState.date || contextState.groupsLoadingOnDateChange}
            active={contextState.mode === AttendanceRegisterModalMode.Groups}
            errorId={(contextState.errors?.selectedGroupIds as MessageDescriptor)?.id}
            onAddClick={actions.setStateHandler('mode', AttendanceRegisterModalMode.Groups)}
            sidebarContent={
              <AddGroupsExternalSidebar
                date={date}
                selectedGroupIds={contextState.selectedGroupIds}
                onSelectGroupIds={actions.addGroupIds}
              />
            }
          >
            <ModalGroupsList
              date={date}
              selectedGroupIds={contextState.selectedGroupIds}
              onRemoveGroupId={actions.removeGroupId}
            />
          </ModalPeopleExtensionPanel>

          <ModalMain />

          <ModalFooterWithActions
            saving={contextState.saving || contextState.groupsLoadingOnDateChange}
            deleting={contextState.deleting || contextState.groupsLoadingOnDateChange}
            showDeleteButton={!!registerId}
            onDeleteClick={() => actions.deleteRegister()}
            isNewItem={!registerId}
            disabled={contextState.saving || contextState.deleting}
          />

          <ModalConfirmationDialog {...contextState.confirmationDialogProps} />
        </form>
      )}
    </ModalSmall>
  );
};
