import { Box, styled } from '@mui/material';
interface RichTextWrapperProps {
  error: boolean;
}

export const RichTextWrapper = styled(Box)<RichTextWrapperProps>(({ theme, error }) => ({
  position: 'relative',
  '.RichText .ql-editor': {
    minHeight: '120px',
  },
  '.ql-container': {
    borderColor: error ? theme.palette.error.main : undefined,
  },
  '.ql-toolbar': {
    margin: theme.spacing(0, 0, 0.5),
  },
  '.MuiDivider-root': {
    height: '20px',
  },
}));
