import { Box, Button, Skeleton, Stack } from '@mui/material';
import {
  SchoolYear,
  StudentInconsistency,
  StudentInconsistencyType,
  StudentRolloverRequestParams,
  useGetStudentsInconsistencyList,
} from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { EyeIcon, randomInt } from '@schooly/style';
import React, { FC, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { RolloverInconsistenciesRow } from './RolloverInconsistenciesRow';

const PAGE_SIZE = 40;

export type RolloverInconsistency = {
  refetch: () => void;
};
type RolloverInconsistenciesListProps = {
  type: StudentInconsistencyType;
  params: StudentRolloverRequestParams;
  years: SchoolYear[];
  onRowClick: (id: string) => void;
  onShowFamily: (id: string) => void;
  schoolId: string;
  count: number;
};

export const RolloverInconsistenciesList: FC<RolloverInconsistenciesListProps> = ({
  type,
  years,
  params,
  onRowClick,
  schoolId,
  count,
  onShowFamily,
}) => {
  const { activePropertiesMap } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const year = useMemo(() => {
    const yearId =
      type === 'have_registration' || type === 'rollover_in_process'
        ? params.year_to_id
        : params.year_from_id;
    return years.find((y) => y.id === yearId);
  }, [params.year_from_id, params.year_to_id, type, years]);

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useGetStudentsInconsistencyList(
      {
        inconsistency_type: type,
        ...params,
      },
      { refetchOnMount: 'always' },
    );

  const items = useMemo(
    () =>
      data?.pages.reduce<StudentInconsistency[]>((prev, curr) => [...prev, ...curr.results], []) ??
      [],
    [data?.pages],
  );

  return (
    <Box px={2.5}>
      <Stack pt={1.25} overflow="hidden">
        {items.map((p) => (
          <RolloverInconsistenciesRow
            key={p.student_id}
            data={p}
            houses={activePropertiesMap['house']}
            year={year}
            ageGroups={activePropertiesMap['age_group']}
            onRowClick={onRowClick}
            inconsistencyType={type}
            onShowFamily={onShowFamily}
          />
        ))}

        {(isLoading || isFetchingNextPage) && (
          <RolloverSkeletonRows rowCount={Math.min(PAGE_SIZE, count - (items?.length || 0))} />
        )}

        {hasNextPage && !isFetchingNextPage && (
          <Stack mt={1} alignItems="center">
            <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
              <FormattedMessage id="action-ShowMoreButton" />
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

type RolloverSkeletonRowsProps = {
  rowCount: number;
};

export const RolloverSkeletonRows: FC<RolloverSkeletonRowsProps> = ({ rowCount }) => {
  const rowWidths = useRef(['30px', '20%', '18%', '12%', '36%']).current;

  return (
    <Stack gap={2} pl={0.5} py={1.25}>
      {[...new Array(rowCount)].map((_, i) => {
        return (
          <Stack key={i} width="100%" direction="row" gap={2}>
            {rowWidths.map((width, i) => (
              <Box width={width} height={30} key={i}>
                <Skeleton
                  variant={i === 0 ? 'circular' : undefined}
                  width={i === 1 ? `${randomInt(40, 100)}%` : undefined}
                />
              </Box>
            ))}
          </Stack>
        );
      })}
    </Stack>
  );
};
