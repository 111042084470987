import { MessageStatus } from '@schooly/api';
import React from 'react';
import { useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

import { TagStatusPublish } from '../../../components/uikit-components/TagStatusPublish/TagStatusPublish';

interface StatusLabelProps {
  status?: MessageStatus;
  publishDate?: Date;
}

export const MESSAGE_STATUSES = $enum(MessageStatus).getKeys();

const StatusLabel: React.FC<StatusLabelProps> = ({ status, publishDate }) => {
  const { formatMessage } = useIntl();

  if (status == null) {
    return null;
  }

  return (
    <TagStatusPublish
      label={formatMessage({ id: `options-message-status-${MESSAGE_STATUSES[status]}` })}
      published={status === MessageStatus.Published}
      publishDate={publishDate}
    />
  );
};

export default StatusLabel;
