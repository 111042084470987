import { Box, Stack, Typography } from '@mui/material';
import { AvailableCriteria } from '@schooly/api';
import { CheckIcon } from '@schooly/style';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../components/ui/Button';
import {
  ModalConfirm,
  ModalContent,
  ModalFooter,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeaderStyled } from '../../../../components/uikit-components/Modal/ModalHeader.styled';
import RoundCard from '../../../../components/uikit-components/RoundCard';

export interface DeleteHistoryDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmTextId: string;
  invalidGroups?: AvailableCriteria[];
}

export const MessagesDeleteGroupModal: React.FC<DeleteHistoryDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  invalidGroups,
  confirmTextId,
}) => {
  return (
    <ModalConfirm open={isOpen}>
      <ModalHeaderStyled active>
        <Box pt={2} pl={5} pr={8}>
          <Typography variant="h1">
            <FormattedMessage id={confirmTextId} />
          </Typography>
        </Box>
      </ModalHeaderStyled>

      <ModalContent active>
        <Stack pl={5} gap={2}>
          {!!invalidGroups?.length && (
            <Typography variant="h4">
              <FormattedMessage id="profile-Groups" />
            </Typography>
          )}

          <Stack pr={1} direction="row" flexWrap="wrap">
            {invalidGroups?.map((gr) => (
              <Box pr={1}>
                <RoundCard item={gr} name={gr.group?.name ?? ''} />
              </Box>
            ))}
          </Stack>
        </Stack>
      </ModalContent>
      <ModalFooter active>
        <Button type="button" color="secondary" onClick={onClose}>
          <FormattedMessage id="action-Cancel" />
        </Button>

        <Button color="primary" rightIcon={<CheckIcon />} type="submit" onClick={onConfirm}>
          <FormattedMessage id="action-Confirm" />
        </Button>
      </ModalFooter>
    </ModalConfirm>
  );
};
