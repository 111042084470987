import { Typography } from '@mui/material';
import { GroupUserType } from '@schooly/api';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import LimitedToStubImg from '../../../assets/images/limited-to-stub.svg';
import LimitedToStubArrowTallImg from '../../../assets/images/limited-to-stub-arrow.svg';
import { getUserTypeTextId } from '../../../helpers/misc';
import { LimitedToEmptyStubArrow, LimitedToEmptyStubStyled } from './LimitedToEmptyStub.styled';

export interface LimitedToEmptyStubProps {
  userType: GroupUserType;
  customText?: string;
}

export const LimitedToEmptyStub: FC<LimitedToEmptyStubProps> = ({ userType, customText }) => {
  const { $t } = useIntl();
  const text =
    customText ??
    $t(
      { id: 'groups-LimitedToEmptyStub' },
      {
        userType: $t({
          id: getUserTypeTextId(userType, true),
        }).toLowerCase(),
        categories: $t({
          id: 'groups-LimitedToByParameters',
        }).toLowerCase(),
      },
    );

  return (
    <LimitedToEmptyStubStyled>
      <img src={LimitedToStubImg} alt={text} />
      <Typography variant="h3" color="text.primary" mt={2.5} textAlign="center">
        {text}
      </Typography>
      <LimitedToEmptyStubArrow>
        <img src={LimitedToStubArrowTallImg} alt="arrow" />
      </LimitedToEmptyStubArrow>
    </LimitedToEmptyStubStyled>
  );
};
