import { Typography } from '@mui/material';
import {
  GetGroupsQueryFilters,
  Group,
  GROUPS_QUERY_FILTER_KEYS,
  useGetGroupsQuery,
} from '@schooly/api';
import { FC, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { pickOnlyParamsFromFilterKeys } from '../utils';
import { renderGroupTags } from './GroupExpandedSelect';
import { GroupSelectRow } from './GroupSelectRow';

type GroupSelectProps = {
  schoolId: string;
  selectedIds: string[];
  onSelectGroupId: (v: string) => void;
  filters?: GetGroupsQueryFilters;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const GroupSelectMultiple: FC<GroupSelectProps> = ({
  schoolId,
  selectedIds,
  onSelectGroupId,
  placeholder,
  filters = {},
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const { data, hasNextPage, params, setParams, isLoading, isFetchingNextPage, fetchNextPage } =
    useGetGroupsQuery(
      {
        schoolId,
        query: '',
        filters: pickOnlyParamsFromFilterKeys(GROUPS_QUERY_FILTER_KEYS, filters),
      },
      { enabled: queryEnabled },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const entries =
      data.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Group[]) ?? [];

    if (!entries.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {entries.map((group) => (
          <GroupSelectRow
            onClick={(id) => {
              onSelectGroupId(id);
              handleChangeQuery('');
            }}
            key={group.id}
            group={group}
            isSelected={selectedIds.includes(group.id)}
          />
        ))}
      </>
    );
  }, [data, handleChangeQuery, onSelectGroupId, selectedIds]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={placeholder}
      hasValues={selectedIds.length > 0}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      onFetchNextPage={fetchNextPage}
    >
      {(opened) => (
        <>
          {renderGroupTags({
            ids: selectedIds,
            tagProps: { size: placeholder ? 'small' : undefined, sx: { maxWidth: 200 } },
            onDelete: opened ? onSelectGroupId : undefined,
          })}
          {opened && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={params.query || ''}
              onChangeText={handleChangeQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
