import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { GroupType, GroupWithMembers } from '@schooly/api';
import { ArchiveIcon, CrossIcon, EditIcon, LockIcon } from '@schooly/style';
import React, { FC, PropsWithChildren, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SimpleButton } from '../../../components/uikit/SimpleButton/SimpleButton';
import { ModalPanel, ModalTitle } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { GroupPreviewModalCloneButton } from './GroupPreviewModalCloneButton';

interface EditableProps {
  onEditClick: (property?: keyof GroupWithMembers) => void;
  editable?: boolean;
}

export interface ViewGroupModalInfoProps extends EditableProps {
  group?: GroupWithMembers;
  validity?: string;
  headerEdit?: boolean;
  onModalClose: VoidFunction;
  locked?: boolean;
  active?: boolean;
  showCloneButton?: boolean;
  withGoBack?: boolean;
  shortDescription?: boolean;
  titleErrorText?: string;
}
//TODO Move ellipsis function to common utils
export function ellipsis(s?: string, threshold = 40) {
  return s && s.length > threshold ? s.substring(0, threshold) + '...' : s;
}

export const Editable: FC<
  PropsWithChildren<
    EditableProps & { prop: keyof GroupWithMembers; hasValue?: boolean; locked?: boolean }
  >
> = ({ editable, hasValue, prop, onEditClick, locked, children }) => {
  const onClick = useCallback(() => {
    !locked && onEditClick(prop);
  }, [onEditClick, prop, locked]);

  return (
    <>
      {!editable && children}
      {editable && (
        <SimpleButton
          sx={{ pointerEvents: locked ? 'none' : 'all' }}
          endIcon={
            locked ? (
              <Box sx={{ opacity: 0.5, mt: -0.5 }}>
                <LockIcon />
              </Box>
            ) : (
              <Box sx={{ ml: hasValue ? 0 : -1 }}>
                <EditIcon />
              </Box>
            )
          }
          onClick={onClick}
          iconsOnHover={hasValue && !locked}
        >
          {children}
        </SimpleButton>
      )}
    </>
  );
};

export const GroupPreviewModalInfo: React.FC<ViewGroupModalInfoProps> = ({
  group,
  validity,
  onModalClose,
  editable,
  headerEdit,
  onEditClick,
  locked,
  active,
  showCloneButton,
  withGoBack,
  shortDescription,
  titleErrorText,
}) => {
  const { $t } = useIntl();

  const hasEdit = editable && !headerEdit;

  return (
    <Box>
      <ModalHeader
        active={active}
        title={
          <Editable
            editable={hasEdit}
            locked={locked}
            hasValue={!!group?.name}
            onEditClick={onEditClick}
            prop="name"
          >
            <ModalTitle>{ellipsis(group?.name ?? '', 25)}</ModalTitle>
          </Editable>
        }
        errorText={titleErrorText}
        withGoBack={withGoBack}
      >
        {headerEdit && (
          <IconButton onClick={() => onEditClick()} data-test-id="modal-edit">
            <EditIcon />
          </IconButton>
        )}
        {showCloneButton && <GroupPreviewModalCloneButton group={group} />}
        <IconButton data-test-id="modal-close" onClick={onModalClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>

      <ModalPanel active={active} withBorderBottom>
        <Stack direction="row" gap={18}>
          <Stack gap={3} direction="row">
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage id="groups-Validity" />
              </Typography>
              <Editable
                editable={hasEdit}
                locked={locked}
                hasValue={!!validity}
                onEditClick={onEditClick}
                prop="validity"
              >
                <Typography variant="h3" color="text.primary">
                  {validity}
                </Typography>
              </Editable>
            </Box>

            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage
                  id={(() => {
                    switch (group?.group_type) {
                      case GroupType.TutorGroup:
                        return 'groups-Type';
                      case GroupType.Subject:
                      default:
                        return group?.subject?.id ? 'groups-GroupSubject' : 'groups-TypeOrSubject';
                    }
                  })()}
                />
              </Typography>
              <Editable
                editable={hasEdit}
                locked={locked}
                hasValue={
                  !!(
                    group?.group_type === GroupType.TutorGroup ||
                    (group?.subject && group.subject.id)
                  )
                }
                onEditClick={onEditClick}
                prop={group?.group_type === GroupType.TutorGroup ? 'group_type' : 'subject'}
              >
                <Stack direction="row" gap={0.75}>
                  {(() => {
                    switch (group?.group_type) {
                      case GroupType.TutorGroup:
                        return (
                          <Typography variant="h3" color="text.primary">
                            <FormattedMessage id="groups-TutorGroup" />
                          </Typography>
                        );
                      case GroupType.Subject:
                      default:
                        return (
                          <>
                            {group?.subject?.archived && (
                              <Tooltip title={$t({ id: 'schoolProperty-Archived-subject' })}>
                                <IconButton inverse>
                                  <ArchiveIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Typography variant="h3" color="text.primary">
                              {group?.subject?.name}
                            </Typography>
                          </>
                        );
                    }
                  })()}
                </Stack>
              </Editable>
            </Box>
          </Stack>

          <Box sx={{ overflow: 'hidden' }}>
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="groups-GroupDescription" />
            </Typography>

            <Editable
              editable={hasEdit}
              hasValue={!!group?.description}
              onEditClick={onEditClick}
              prop="description"
            >
              <Typography
                variant="h3"
                color="text.primary"
                {...(group?.description && {
                  sx: {
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    '-webkit-line-clamp': shortDescription ? '1' : '2',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'start',
                  },
                })}
              >
                {group?.description ?? ''}
              </Typography>
            </Editable>
          </Box>
        </Stack>
      </ModalPanel>
    </Box>
  );
};
