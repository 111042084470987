import { countriesWithAreaCodes as COUNTRIES } from '@schooly/constants';
type Country = typeof COUNTRIES[0];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getPhoneNumberFormat(country?: Country) {
  const id = country ? country.id : 'UNKNOWN';

  switch (id) {
    case 'RU':
    case 'US':
      return /(\d{1,3})(\d{1,3})?(\d{1,2})?(\d{1,2})?()?/;
    case 'UA':
    case 'FI':
    case 'AE':
      return /(\d{1,2})(\d{1,3})?(\d{1,2})?(\d{1,2})?()?/;
    default:
      return /(\d{1,3})(\d{1,3})?(\d{1,3})?(\d{1,3})?(\d{1,3})?/;
  }
}

export function getCountryFromPhoneNumber(input?: string) {
  if (!input) {
    return undefined;
  }

  const phoneNumber = input.replace(/[^\d+]+/g, '');
  if (!phoneNumber.startsWith('+')) {
    return undefined;
  }

  const possibleCountries = COUNTRIES.filter((country: Country) =>
    phoneNumber.startsWith(country.code),
  ).sort((a, b) => a.code.length - b.code.length);

  if (!possibleCountries.length) {
    return undefined;
  }

  return possibleCountries[possibleCountries.length - 1];
}

// TODO: A temporary implementation. Must decide how to format phone numbers. ...
// ... For now the implementation use without "hand" formatting (use flag "withFormatting" for change), ...
// ... and it is returning phone number without country code.
export function formatPhoneNumber(input: string, country?: Country) {
  const withFormatting = false;

  let phoneNumber =
    country && input.startsWith(country.code) ? input.substr(country.code.length) : input;

  if (withFormatting) {
    phoneNumber = phoneNumber.replace(/[^\d]+/g, '');

    // TODO: Re-enable formatting, when target countries and formats are settled.
    // phoneNumber = phoneNumber.replace(getPhoneNumberFormat(country), (_, p1, p2, p3, p4, p5) => {
    //   let output = '';
    //   if (p1) output = `${p1}`;
    //   if (p2) output += ` ${p2}`;
    //   if (p3) output += `-${p3}`;
    //   if (p4) output += `-${p4}`;
    //   if (p5) output += `-${p5}`;
    //   return output;
    // });

    return phoneNumber;
  } else {
    return phoneNumber;
  }
}

export function getFormattedPhoneNumber(input: string) {
  if (!input) {
    return '';
  }

  const country = getCountryFromPhoneNumber(input);

  return formatPhoneNumber(input, country);
}

export function formatPhoneNumberWithCode(phoneNumber: string) {
  const numberWithPlus = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
  const country = getCountryFromPhoneNumber(numberWithPlus);
  if (!country) {
    return numberWithPlus;
  }
  return `${country.code} ${formatPhoneNumber(numberWithPlus, country)}`;
}

export function getStrippedPhoneNumberWithCode(phoneNumber: string, country?: Country) {
  const strippedNumber = phoneNumber.replace(/[^\d]+/g, '');
  if (!country) {
    return strippedNumber;
  }

  return `${country.code}${strippedNumber}`;
}
