import { IconButton, Stack, Tooltip } from '@mui/material';
import { isSignUpRegular, isSignUpWithoutEvent, SignUpStatuses } from '@schooly/api';
import { DATE_FORMAT } from '@schooly/constants';
import {
  CopyIcon,
  CrossIcon,
  EditIcon,
  LockIcon,
  ModalContent,
  ModalMain,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getMinutesDurationLabel, newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ConsentFormPreview } from '../../../components/common/ConsentForms/ConsentFormPreview';
import { ModalAccessDenied } from '../../../components/uikit-components/Modal/ModalAccessDenied';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import { useRouter } from '../../../context/router/useRouter';
import { SignUpTypeLabels, useSignUp } from '../../../context/signUps/WithSignUp';
import { SignUpPreviewActions } from './SignUpPreviewActions';
import { SignUpPreviewWithEvent } from './SignUpPreviewWithEvent';
import { SignUpPreviewWithoutEvent } from './SignUpPreviewWithoutEvent';

export const SignUpPreviewContent: FC = () => {
  const { $t } = useIntl();
  const { closeAndClean } = useRouter();
  const { signUp, canEdit, canView, clone } = useSignUp();

  if (!signUp) {
    return null;
  }

  if (!canView) {
    return <ModalAccessDenied />;
  }

  return (
    <>
      <ModalHeaderV2
        withGoBack
        active
        title={signUp.title}
        subHeader={
          signUp.end && (
            <Stack
              direction="row"
              gap={7}
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: { gap: 4 },
              })}
            >
              <TypographyWithOverflowHint noWrap>
                {$t({ id: 'till' })} {format(newDateTimezoneOffset(signUp.end), DATE_FORMAT)}
              </TypographyWithOverflowHint>
              <TypographyWithOverflowHint noWrap>
                {$t({ id: SignUpTypeLabels[signUp.type] })}
              </TypographyWithOverflowHint>
              <TypographyWithOverflowHint noWrap>
                {isSignUpRegular(signUp)
                  ? signUp.places
                    ? $t({ id: 'eventSignUps-preview-Places' }, { value: signUp.places })
                    : null
                  : getMinutesDurationLabel(signUp.duration)}
              </TypographyWithOverflowHint>
              {signUp.event && (
                <TypographyWithOverflowHint
                  component={Link}
                  to={`/events/${signUp.event.id}`}
                  color="text.primary"
                  sx={{
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  {signUp.event.title}
                </TypographyWithOverflowHint>
              )}
            </Stack>
          )
        }
      >
        <SignUpPreviewActions canGoToEvent />

        {canEdit && (
          <Link to={`/signups/${signUp.id}/edit`}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
        )}

        {signUp.status === SignUpStatuses.Closed && (
          <Tooltip title={$t({ id: 'eventSignUps-preview-LockHint' })}>
            <IconButton inverse>
              <LockIcon />
            </IconButton>
          </Tooltip>
        )}

        {isSignUpWithoutEvent(signUp) && (
          <IconButton onClick={clone}>
            <CopyIcon />
          </IconButton>
        )}

        <IconButton onClick={closeAndClean}>
          <CrossIcon />
        </IconButton>
      </ModalHeaderV2>

      <ModalMain>
        <ModalContent
          sx={(theme) => ({
            pt: theme.spacing(2.5),
            [theme.breakpoints.down('lg')]: {
              p: theme.spacing(4, 3, 3),
            },
            height: '100%',
            flex: 1,
          })}
        >
          <SignUpPreviewWithEvent />
          <SignUpPreviewWithoutEvent />
          {signUp.consent_form && (
            <Stack flex={1} pt={3.75}>
              <ConsentFormPreview
                consentFormShort={signUp.consent_form}
                label={$t({ id: 'consentForms-title' })}
              />
            </Stack>
          )}
        </ModalContent>
      </ModalMain>
    </>
  );
};
