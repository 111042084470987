import { FormControl, FormControlLabel, FormControlLabelProps, Switch } from '@mui/material';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form-lts';

export type ControlSwitchProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> &
  Omit<FormControlLabelProps, 'control'>;

export const ControlSwitch = <TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  label,
  rules,
  ...rest
}: ControlSwitchProps<TFieldValues>) => {
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Controller
            name={name}
            rules={rules}
            control={control}
            render={({ field: props }) => (
              <Switch
                {...props}
                disabled={rest.disabled}
                checked={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            )}
          />
        }
        label={label}
        {...rest}
      />
    </FormControl>
  );
};
