import { getTypedObjectKeys } from './getTypedObjectKeys';

export function removeObjectUndefinedNullValues(obj: object) {
  const newObj = {};
  getTypedObjectKeys(obj).forEach((prop) => {
    if (obj[prop] != null && obj[prop] !== '') {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
}
