import { InboxMessage, Message } from '@schooly/api';

export function getFilteredMessages(value: string, messages?: InboxMessage[]): InboxMessage[] {
  if (!messages) return [];

  return (messages as any)?.filter((msg: Message | InboxMessage) => {
    const { body, title, sender } = msg as InboxMessage;
    const { given_name, last_name } = sender;

    const searchStrings = [body, title, given_name, last_name];
    const splittedValue = value.toLowerCase().trim().split(' ');

    return searchStrings
      .map((s) => s?.toLowerCase())
      .find((str) =>
        splittedValue.find((val, i) => {
          const hasVal = str?.includes(val);

          if (i === 0) {
            return hasVal;
          }

          return val.length > 2 ? hasVal : null;
        }),
      );
  });
}
