import { Stack, styled, Switch, Typography } from '@mui/material';
import { GET_SCHOOL_QUERY, useGetSchoolQuery, useUpdateSchoolMutation } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { Spin } from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback } from 'react';

export const SchoolGeneralListedToggle: FC = () => {
  const queryClient = useQueryClient();
  const { schoolId = '' } = useAuth();
  const { showError } = useNotifications();

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    refetchOnMount: 'always',
    enabled: !!schoolId,
  });

  const updateSchool = useUpdateSchoolMutation();

  const handleVerifyClick = useCallback(() => {
    if (!currentSchool) return;

    updateSchool.mutate(
      {
        school_id: schoolId,
        is_public_listing: !currentSchool.is_public_listing,
      },
      {
        onError: showError,
        onSuccess: () => {
          queryClient.setQueryData([GET_SCHOOL_QUERY, schoolId], {
            ...currentSchool,
            is_public_listing: !currentSchool.is_public_listing,
          });
        },
      },
    );
  }, [currentSchool, queryClient, schoolId, showError, updateSchool]);

  if (!currentSchool) return null;

  const isListed = currentSchool.is_public_listing;

  const { verification } = currentSchool;

  if (!verification) return null;

  return (
    <RoundLayout active={isListed}>
      <Typography color={isListed ? 'success.main' : 'common.grey'} variant="h3">
        {isListed ? 'Visible in listing' : 'Not visible in listing'}
      </Typography>
      {updateSchool.isLoading ? (
        <Spin sx={{ color: isListed ? 'success.main' : 'common.grey' }} />
      ) : (
        <Switch
          sx={(theme) => ({
            '.MuiSwitch-track': {
              backgroundColor: 'white !important',
              border: isListed ? `1px solid ${theme.palette.success.main} !important` : undefined,
            },
            '.MuiSwitch-thumb': {
              backgroundColor: isListed ? `${theme.palette.success.main}  !important` : undefined,
            },
          })}
          checked={isListed}
          disabled={updateSchool.isLoading}
          onChange={handleVerifyClick}
        />
      )}
    </RoundLayout>
  );
};

const RoundLayout = styled(Stack)<{ active?: boolean }>(({ theme, active }) => ({
  whiteSpace: 'nowrap',
  transition: 'all .2s ease',
  flexDirection: 'row',
  gap: theme.spacing(1),
  position: 'relative',
  height: theme.spacing(3.75),
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0, 1.5),
  borderRadius: theme.spacing(2),
  backgroundColor: active ? theme.palette.success.superLight : theme.palette.background.default,
  border: active
    ? `1px solid ${theme.palette.success.main}`
    : `1px solid ${theme.palette.common.grey7}`,
}));
