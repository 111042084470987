import { FormControlLabel, Switch } from '@mui/material';
import { ChartBarData, FilterKeys, useGetChartQuery, UserFilter } from '@schooly/api';
import { ArrangeByDropdown } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { BarSelectProps, ChartBar, ChartBarProps } from './chartBar/ChartBar';
import { ChartsContainer } from './chartBar/ChartsContainer';
import { useRenderTooltip } from './chartBar/ChartTooltip';
import { useGetLabel } from './chartBar/useGetLabel';
import { useCharts } from './context/ChartsContext';

export type ChartsCustomGrid<T> = {
  title: string;
  count: number;
  filters: Partial<T>;
};

const BAR_ITEM_LIMIT = 5;
interface ChartsProps<T> extends Omit<ChartBarProps, 'chartData' | 'getDataName'> {
  entityType: 'student' | 'staff';
  onOpenArrangeByDropdown?: () => void;
  onOpenCustomGrid: (v: ChartsCustomGrid<T> | null) => void;
  breakDownByOptions: FilterKeys[];
  arrangeBy?: FilterKeys | null;
  filters: Partial<T>;
  query?: string;
}

export const StudentsStaffCharts = <T extends Omit<Partial<UserFilter>, 'date'>>({
  entityType,
  filters,
  breakDownByOptions,
  onOpenArrangeByDropdown,
  onOpenCustomGrid,
  arrangeBy,
  query,
}: ChartsProps<T>) => {
  const { $t } = useIntl();
  const {
    isChartsOpened,
    showZeroValues,
    breakDownBy,
    schoolId,
    chartSelectedElement,
    setChartSelectedElement,
    setBreakDownBy,
    onToggleChartsOpened,
    onToggleZeroValues,
  } = useCharts();
  const { showError } = useNotifications();
  const renderTooltip = useRenderTooltip(Boolean(breakDownBy));
  const { schoolProperties, isLoading: isLoadingSchoolProperties } = useSchoolProperties({
    userType: entityType === 'student' ? SchoolUserRole.Student : SchoolUserRole.Staff,
    schoolId,
    showReEnrollmentProperties: true,
  });

  const { data: chartData, isFetching } = useGetChartQuery(
    {
      schoolId,
      filters,
      arrange_by: arrangeBy ?? '',
      breakdown_by: breakDownBy,
      entityType,
      query,
    },
    {
      refetchOnMount: 'always',
      enabled: !!schoolId && !!arrangeBy && isChartsOpened,
      onError: showError,
    },
  );

  const [preparedChartData, hasZeroValues] = useMemo(() => {
    if (!chartData) {
      return [chartData, false];
    }

    let hasZeroValues = false;

    const preparedData: ChartBarData = {
      ...chartData,
      arrange_by: { ...chartData.arrange_by, data: [] },
      series: chartData.series.map((item) => ({ ...item, data: [] })) ?? [],
    };

    chartData.arrange_by.data.forEach((item, index) => {
      const total = chartData.series.reduce((prev, curr) => prev + (curr.data[index] ?? 0), 0);

      if (total === 0) {
        hasZeroValues = true;
      }

      if (!showZeroValues && !total) {
        return;
      }

      preparedData.arrange_by.data.push(item);
      preparedData.series.forEach((seriesItem, seriesIndex) => {
        seriesItem.data.push(chartData.series[seriesIndex]?.data[index] ?? 0);
      });
    });

    return [preparedData, hasZeroValues];
  }, [chartData, showZeroValues]);

  const getDataName = useGetLabel({
    arrangeBy,
    schoolProperties,
  });

  useEffect(() => {
    setBreakDownBy(undefined);
  }, [arrangeBy, setBreakDownBy]);

  useEffect(() => {
    setChartSelectedElement(undefined);
    onOpenCustomGrid(null);
  }, [filters, arrangeBy, breakDownBy, setChartSelectedElement, onOpenCustomGrid]);

  const onBarSelect = useCallback(
    ({ data, bar }: BarSelectProps) => {
      const getFiltersFromBarData = (data: BarSelectProps['data']): Partial<T> => {
        const filters: Partial<T> = {};
        if (!data) return filters;

        for (const { dataKey, dataValue } of data) {
          if (!dataKey) continue;
          if (dataKey === FilterKeys.Date) continue;

          // dataValue === null is "Not in" case
          filters[dataKey] = [dataValue === null ? 'none' : dataValue];
        }

        return filters;
      };

      setChartSelectedElement((old) => {
        const isElementSelected =
          old?.dataIndex === bar.dataIndex && old?.seriesIndex === bar.seriesIndex;
        const newSelectedBar = isElementSelected ? undefined : bar;

        if (newSelectedBar && data) {
          const barFilters = getFiltersFromBarData(data);
          const { name: title, value } = newSelectedBar;

          onOpenCustomGrid({
            title,
            count: typeof value === 'number' ? value : 0,
            filters: { ...filters, ...barFilters },
          });
        } else {
          onOpenCustomGrid(null);
        }

        return newSelectedBar;
      });
    },
    [filters, onOpenCustomGrid, setChartSelectedElement],
  );

  const legendData = useMemo(
    () => preparedChartData?.series?.map(getDataName).reverse() ?? [],
    [preparedChartData?.series, getDataName],
  );

  if (!isChartsOpened) return null;

  return (
    <ChartsContainer
      isEmpty={!preparedChartData?.series.length}
      isLoading={isFetching || isLoadingSchoolProperties}
      onToggleChartsOpened={onToggleChartsOpened}
      arrangeBy={arrangeBy}
      onOpenArrangeByDropdown={onOpenArrangeByDropdown}
      zeroValuesToggler={
        hasZeroValues ? (
          <FormControlLabel
            control={<Switch checked={showZeroValues} onChange={onToggleZeroValues} />}
            label={<FormattedMessage id="showZeroValues" />}
            sx={{
              m: 0,
              color: showZeroValues ? 'text.primary' : 'text.secondary',
            }}
          />
        ) : null
      }
      arrangeByDropdown={
        arrangeBy ? (
          <ArrangeByDropdown
            onSelectOption={setBreakDownBy}
            options={breakDownByOptions?.filter((o) => o !== arrangeBy)}
            selectedOption={breakDownBy}
            label={$t({ id: 'brakeDownBy' })}
            onClear={() => setBreakDownBy(undefined)}
            onRemove={() => setBreakDownBy(undefined)}
          />
        ) : undefined
      }
    >
      {preparedChartData && (
        <ChartBar
          barPosition={
            preparedChartData.arrange_by.data.length < BAR_ITEM_LIMIT ? 'vertical' : 'horizontal'
          }
          key={breakDownBy}
          renderTooltip={renderTooltip}
          chartData={preparedChartData}
          onBarSelect={onBarSelect}
          getDataName={getDataName}
          selectedBar={chartSelectedElement}
          legendData={legendData}
          showZeroValues={showZeroValues}
        />
      )}
    </ChartsContainer>
  );
};
