import { useCallback, useEffect, useRef, useState } from 'react';
import usePortal, { UsePortalOptions } from 'react-useportal';

import captureEscKeyListener from '../utils/captureEscKeyListener';

const NULL_EVENT = { currentTarget: { contains: () => false } };

export default function useDropdown(
  wrapperRef: React.RefObject<HTMLElement>,
  inputRef?: React.RefObject<HTMLElement>,
  forceZIndex = false,
  options?: UsePortalOptions,
) {
  const [isOpenOnce, setIsOpenOnce] = useState(false);

  const getPortalStyles = useCallback(() => {
    if (!wrapperRef.current) {
      return null;
    }

    const offset = wrapperRef.current.getBoundingClientRect();

    return `
      position: absolute;
      z-index: ${forceZIndex ? 1500 : 100};
      width: ${offset.width}px;
      top: ${offset.top + offset.height + window.scrollY}px;
      left: ${offset.left}px;
    `;
  }, [wrapperRef, forceZIndex]);

  const {
    isOpen,
    openPortal,
    closePortal,
    Portal: Popup,
    portalRef,
  } = usePortal({
    closeOnOutsideClick: false,
    ...(options ?? {}),
    onOpen: (event) => {
      requestAnimationFrame(() => {
        const portalStyles = getPortalStyles();
        setIsOpenOnce(true);

        if (portalStyles) {
          // eslint-disable-next-line no-param-reassign
          event.portal.current.style.cssText = portalStyles;
        }
      });

      options?.onOpen?.(event);
    },
  });

  const recalculatePopupPosition = useCallback(() => {
    requestAnimationFrame(() => {
      const portalStyles = getPortalStyles();

      if (portalRef.current && portalStyles) {
        // eslint-disable-next-line no-param-reassign
        portalRef.current.style.cssText = portalStyles;
      }
    });
  }, [portalRef, getPortalStyles]);

  // Refs are needed here because `openPortal` and `closePortal` update on every render
  const openPortalRef = useRef(openPortal);
  const closePortalRef = useRef(closePortal);

  useEffect(() => {
    openPortalRef.current = openPortal;
    closePortalRef.current = closePortal;
  }, [openPortal, closePortal]);

  const openPopup = useCallback((e?: any) => openPortalRef.current(e || NULL_EVENT), []);
  const closePopup = useCallback(
    (e?: any) => {
      closePortalRef.current(e || NULL_EVENT);

      if (inputRef?.current) {
        inputRef.current.blur();
      }
    },
    [inputRef],
  );

  useEffect(
    () => (isOpen ? captureEscKeyListener(closePopup, true) : undefined),
    [isOpen, closePopup],
  );

  return {
    isOpen,
    openPopup,
    closePopup,
    recalculatePopupPosition,
    Popup,
    isOpenOnce,
  };
}
