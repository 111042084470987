import { Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, useCallback } from 'react';

import { ColumnRecordDraft } from '../../scheme';
import { CalendarRecordCellContent, CalendarRecordCellDraftStyled } from '../CalendarWeek.styled';

export interface CalendarRecordCellDraftProps extends ColumnRecordDraft {}

export const CalendarRecordCellDraft: FC<CalendarRecordCellDraftProps> = ({
  start,
  end,
  size,
  record,
}) => {
  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      record.onClick?.(event, record);
    },
    [record],
  );

  const renderContent = () => (
    <CalendarRecordCellDraftStyled size={size} onClick={record.onClick && handleClick}>
      <CalendarRecordCellContent>
        <Typography variant="caption">
          {format(start, 'HH:mm')} - {format(end, 'HH:mm')}
        </Typography>
      </CalendarRecordCellContent>
    </CalendarRecordCellDraftStyled>
  );

  return record.tooltip ? (
    <Tooltip {...record.tooltip}>{renderContent()}</Tooltip>
  ) : (
    renderContent()
  );
};
