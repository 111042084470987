import { Box, Stack, Typography } from '@mui/material';
import { getTypedObjectKeys, GroupLimitations } from '@schooly/api';
import { TagSelectCounter, TagSelectProperty } from '@schooly/style';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DEFAULT_LIMITED_TO_SECTION_TEXT_IDS, LimitedToCategory } from './LimitedToSelect';
import { getLimitedToCategoryKey, getLimitedToRelatedCategory, LimitedToOption } from './utils';

type LimitedToContentProps = {
  isEmpty: boolean;
  limitedTo: GroupLimitations;
  displayedOptionsByCategory: Partial<Record<LimitedToCategory, LimitedToOption[]>> | null;
  onSelect: (option: LimitedToOption, category: LimitedToCategory) => void;
  errors?: Partial<Record<LimitedToCategory, string>>;
  onSelectMultiple: (options: LimitedToOption[], category: LimitedToCategory) => void;
  options: Partial<Record<LimitedToCategory, LimitedToOption[]>>;
  hoveredGroupTagId: string;
  setHoveredGroupTagId: React.Dispatch<React.SetStateAction<string>>;
};

export const LimitedToContent = ({
  isEmpty,
  displayedOptionsByCategory,
  onSelect,
  limitedTo,
  errors,
  onSelectMultiple,
  options,
  hoveredGroupTagId,
  setHoveredGroupTagId,
}: LimitedToContentProps) => {
  if (isEmpty || !displayedOptionsByCategory) {
    return (
      <Typography p={1}>
        <FormattedMessage id="input-NoOptionsFound" />
      </Typography>
    );
  }

  return (
    <Stack gap={2} py={2}>
      {getTypedObjectKeys(displayedOptionsByCategory).map((category) => {
        if (!displayedOptionsByCategory[category]?.length) {
          return null;
        }

        const error = errors && errors[category];

        return (
          <Box key={category} px={1}>
            <Stack direction="row">
              <Typography variant="h4" color={error ? 'error.main' : 'text.secondary'}>
                <FormattedMessage id={DEFAULT_LIMITED_TO_SECTION_TEXT_IDS[category]} />
              </Typography>
            </Stack>

            <Stack direction="row" gap={1} flexWrap="wrap" pt={0.25}>
              {displayedOptionsByCategory[category]?.map((option) => {
                const key = getLimitedToCategoryKey(category);

                if (option.isPropGroup) {
                  const relatedPropsCategory = getLimitedToRelatedCategory(category);
                  const relatedProps =
                    options[relatedPropsCategory]?.filter((o) => o.propGroupId === option.value) ??
                    [];

                  return (
                    <TagSelectProperty
                      variant={
                        relatedProps.every(({ value }) => limitedTo[key]?.includes(value))
                          ? 'filled'
                          : undefined
                      }
                      onClick={() => onSelectMultiple(relatedProps, category)}
                      className={hoveredGroupTagId === option.value ? 'hoveredTag' : undefined}
                      onMouseEnter={() => setHoveredGroupTagId(option.value.toString())}
                      onMouseLeave={() => setHoveredGroupTagId('')}
                      label={
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          {option.label}
                          <TagSelectCounter inverse>{relatedProps.length}</TagSelectCounter>
                        </Stack>
                      }
                      testId="limited-to-tag"
                    />
                  );
                }

                return (
                  <TagSelectProperty
                    variant={limitedTo[key]?.includes(option.value) ? 'filled' : undefined}
                    label={option.label}
                    onClick={() => onSelect(option, category)}
                    outlined={Boolean(
                      hoveredGroupTagId && option.propGroupId === hoveredGroupTagId,
                    )}
                    testId="limited-to-tag"
                  />
                );
              })}
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
};
