import { Box, Stack, StackProps, Typography } from '@mui/material';
import { ApplicationLargeSvg, ArrowSelectSvg, MailBoxSvg, SuccessLargeSvg } from '@schooly/style';
import React, { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

type RolloverNotificationWrapperProps = {
  fullHeight?: boolean;
} & Omit<StackProps, 'children'>;

export const RolloverNotificationWrapper: FC<PropsWithChildren<RolloverNotificationWrapperProps>> =
  ({ children, fullHeight, ...props }) => {
    return (
      <Stack justifyContent="center" height={fullHeight ? '100%' : undefined}>
        <Stack
          sx={() => ({
            pt: 4.5,
            pb: 2.5,
            alignItems: 'center',
            gap: 3,
            whiteSpace: 'pre-line',
            textAlign: 'center',
          })}
          {...props}
        >
          {children}
        </Stack>
      </Stack>
    );
  };

export const NoInconsistenciesNotification: FC = () => (
  <RolloverNotificationWrapper fullHeight>
    <SuccessLargeSvg />
    <Typography variant="h3">
      <FormattedMessage id="students-AnnualRollover-NoInconsistencies" />
    </Typography>
  </RolloverNotificationWrapper>
);

export const CantRolloverNotification: FC = () => (
  <RolloverNotificationWrapper>
    <ApplicationLargeSvg />

    <Stack direction="row" alignSelf="flex-start" marginLeft={'17.5%'} gap={10.75}>
      <Box pt={1}>
        <ArrowSelectSvg />
      </Box>
      <Typography variant="h3">
        <FormattedMessage id="students-AnnualRollover-InconsistenciesCantRollover" />
      </Typography>
      <Stack />
    </Stack>
  </RolloverNotificationWrapper>
);

export const NoDataForRolloverNotification: FC = () => (
  <RolloverNotificationWrapper
    fullHeight
    sx={{
      pb: 6,
      alignItems: 'center',
    }}
  >
    <Stack gap={2} alignItems="center">
      <ApplicationLargeSvg />
      <Typography variant="h2">
        <FormattedMessage id="students-AnnualRollover-NoStudents" />
      </Typography>
      <Typography variant="h3">
        <FormattedMessage id="students-AnnualRollover-SelectAnotherStudents" />
      </Typography>
      <Stack />
    </Stack>
  </RolloverNotificationWrapper>
);

type RolloverInProcessNotificationProps = {
  isReEnrollmentEnabled: boolean;
};

export const RolloverInProcessNotification: FC<RolloverInProcessNotificationProps> = ({
  isReEnrollmentEnabled,
}) => (
  <RolloverNotificationWrapper
    sx={{
      alignItems: 'center',
      gap: 5,
      whiteSpace: 'pre-line',
      paddingBottom: 2.25,
    }}
  >
    <MailBoxSvg />
    <Typography variant="h2">
      <FormattedMessage
        id={
          isReEnrollmentEnabled
            ? 'students-AnnualRollover-ReEnrollmentEmail'
            : 'students-AnnualRollover-Email'
        }
      />
    </Typography>
  </RolloverNotificationWrapper>
);
