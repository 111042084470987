import { useNotifications } from '@schooly/components/notifications';
import { FC, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useSchool } from '../../../hooks/useSchool';

/*

  https://schooly.atlassian.net/browse/TR-3763
  We need to redirect to school settings once the school is completed
  on initial school tune page open
*/
export const SchoolTuneInitiallyCompletedRedirect: FC = () => {
  const navigate = useNavigate();
  const { isCompleted, isFetching } = useSchool();
  const { showNotification } = useNotifications();

  const isSchoolTuneLoaded = useRef<boolean>(false);
  useEffect(() => {
    if (isFetching) return;

    if (isSchoolTuneLoaded.current === false && isCompleted) {
      showNotification({ textId: 'school-tune-SchoolConfiguredRedirecting', type: 'success' });
      navigate('/settings');
      return;
    }

    isSchoolTuneLoaded.current = true;
  }, [navigate, showNotification, isFetching, isCompleted]);

  return (
    <>
      <Outlet />
    </>
  );
};
