import { Icon, Stack, Typography } from '@mui/material';
import { getParticipantLabel } from '@schooly/components/filters';
import { EventsInvite } from '@schooly/constants';
import { ParentIcon, StudentIcon } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useEventColor } from '../useEventColor';

export interface EventPreviewInviteesProps {
  invitee_type: EventsInvite;
}

export const EventPreviewInvitees: FC<EventPreviewInviteesProps> = ({ invitee_type }) => {
  const inviteeColor = useEventColor({ invitee_type });

  return (
    <Stack direction="row" gap={2.5}>
      <Typography variant="h2">
        <FormattedMessage id="events-grid-invitees" />
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        gap={1.25}
        sx={() => ({
          '.svg-icon, .MuiTypography-root': {
            color: inviteeColor,
          },
        })}
      >
        <Icon>{invitee_type === EventsInvite.StudentsOnly ? <StudentIcon /> : <ParentIcon />}</Icon>
        <Typography variant="h3">
          <FormattedMessage id={getParticipantLabel(invitee_type)} />
        </Typography>
      </Stack>
    </Stack>
  );
};
