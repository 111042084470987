import { SelectOption } from '@schooly/api';
import React from 'react';
import { Controller } from 'react-hook-form';

import buildClassName from '../../../utils/buildClassName';
import Checkbox from './Checkbox';
import { renderError } from './helpers';
import { BaseFormInputProps, useFormInput } from './utils';

import './CheckboxArray.scss';

type PickedInputProps = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue'>;

interface IProps extends BaseFormInputProps, PickedInputProps {
  options: SelectOption[];
  withoutUncheck?: boolean;
}

const CheckboxArray: React.FC<IProps> = (props) => {
  const { disabled, className, options, defaultValue = '', withoutUncheck } = props;

  const { control, error, fullName, value } = useFormInput(props);

  const handleClick =
    (onChange: (val: string | null) => void, optionValue: string | number) => () => {
      if (String(value) === String(optionValue) && !withoutUncheck) {
        onChange('');
        return;
      }

      onChange(String(optionValue));
    };

  const fullClassName = buildClassName('form-group CheckboxArray', error && 'error', className);

  return (
    <div className={fullClassName}>
      <Controller
        control={control}
        name={fullName}
        defaultValue={defaultValue}
        render={({ onChange }) => (
          <div className="CheckboxArray__inner">
            {options.map((option) => (
              <Checkbox
                key={option.value}
                isDisabled={!!disabled}
                labelTextId={option.labelTextId}
                label={option.label}
                checked={`${option.value}` === String(value)}
                onClick={handleClick(onChange, option.value)}
              />
            ))}
          </div>
        )}
      />
      {renderError(error)}
    </div>
  );
};

export default CheckboxArray;
