import { Stack } from '@mui/material';
import { cancelInvite, getUserRolesForRelation, sendInvite } from '@schooly/api';
import { Guardian } from '@schooly/api';
import { UserType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { ModalConfirmationDialog } from '../../../components/uikit-components/Modal/ModalConfirmationDialog';
import Environments, { isEnvironment } from '../../../constants/environments';
import { SchoolInviteStatus } from '../../../constants/people';
import { useProfile } from '../../../context/profile/useProfile';
import { getRouteModalPathname } from '../../../helpers/misc';
import useFlag from '../../../hooks/useFlag';
import DuplicatesButton from './DuplicatesButton';
import InviteButton from './InviteButton';

interface ProfileHeaderButtonsProps {
  userType: UserType;
}

const ProfileHeaderButtons: React.FC<ProfileHeaderButtonsProps> = ({ userType }) => {
  const { schoolId = '' } = useAuth();
  const { canEditProfile, schoolMembership, user, actions } = useProfile();
  const [currentStatus, setCurrentStatus] = useState<SchoolInviteStatus | null>(
    (user as unknown as Guardian).invite_status ?? null,
  );
  const { $t } = useIntl();
  const { permissions } = useAuth();

  const canInviteUser = useMemo(() => {
    switch (userType) {
      case 'student':
        return permissions.includes('student_manager');
      case 'parent':
        return permissions.includes('parent_manager');
      case 'staff':
        return permissions.includes('staff_manager');

      default:
        return false;
    }
  }, [permissions, userType]);

  const canManageDuplicates = useMemo(() => {
    switch (userType) {
      case 'student':
        return (
          permissions.includes('student_manager') || permissions.includes('registration_manager')
        );
      default:
        return false;
    }
  }, [permissions, userType]);

  const navigate = useNavigate();

  const [isManageRoleDialogOpen, showManageRoleDialog, hideManageRoleDialog] = useFlag();

  const showInviteStatusButtons = (
    isEnvironment(Environments.PROD, Environments.STG, Environments.UAT)
      ? ['staff', 'parent']
      : ['parent', 'staff', 'student']
  ).includes(userType);

  const sendInviteHandler = useCallback(async () => {
    const isSent = await sendInvite({ schoolId, relationId: schoolMembership?.relation_id! });

    if (isSent) {
      actions.invalidateProfileCache();
      setCurrentStatus(SchoolInviteStatus.Invited);
    }

    return isSent;
  }, [schoolId, schoolMembership?.relation_id, actions]);

  const cancelInviteHandler = useCallback(async () => {
    const isCancelled = await cancelInvite(schoolId!, schoolMembership?.relation_id!);

    if (isCancelled) {
      actions.invalidateProfileCache();
      setCurrentStatus(null);
    }
    return isCancelled;
  }, [schoolId, schoolMembership?.relation_id, actions]);

  const onInviteButtonClick = useCallback(async () => {
    const result = await sendInviteHandler();
    if (userType === 'staff' && schoolMembership?.relation_id) {
      const userRoles = await getUserRolesForRelation(schoolMembership.relation_id);

      if (!userRoles.available_to_assign.length && !userRoles.not_allowed_to_assign.length) {
        showManageRoleDialog();
      }
    }
    return result;
  }, [userType, schoolMembership?.relation_id, sendInviteHandler, showManageRoleDialog]);

  const onManageRoleConfirm = useCallback(async () => {
    navigate(`${getRouteModalPathname(userType, user!)}/user-roles`);
    hideManageRoleDialog();
  }, [hideManageRoleDialog, navigate, user, userType]);

  const onManageRoleCancel = useCallback(async () => {
    hideManageRoleDialog();
  }, [hideManageRoleDialog]);

  return (
    <>
      <Stack direction="row" gap={2.5} alignItems="center">
        {showInviteStatusButtons && canEditProfile && (
          <InviteButton
            onCancelInviteClick={cancelInviteHandler}
            onSendInviteClick={onInviteButtonClick}
            currentStatus={currentStatus}
            canInvite={Boolean(schoolMembership?.email && canInviteUser)}
            tooltipTitle={
              !schoolMembership?.email && currentStatus !== SchoolInviteStatus.Invited
                ? $t({ id: 'inviteTooltip-YouNeedToAdd' })
                : undefined
            }
          />
        )}
        {!!user?.duplicates?.length && canManageDuplicates && <DuplicatesButton />}
      </Stack>
      <ModalConfirmationDialog
        onCancel={onManageRoleCancel}
        onConfirm={onManageRoleConfirm}
        open={isManageRoleDialogOpen}
        confirmLabelId="yes"
        cancelLabelId="no"
        content={
          <FormattedMessage
            id="userRoles-NoRolesInviteSending"
            values={{
              userType,
            }}
          />
        }
      />
    </>
  );
};

export default ProfileHeaderButtons;
