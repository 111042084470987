import { getLabelIdForOption, Nationalities } from '@schooly/constants';

export function getNationalitiesLabelIds(nationalities: Nationalities[]) {
  return nationalities.map((n) =>
    getLabelIdForOption({
      key: Nationalities[n],
      optionsKey: 'nationality',
    }),
  );
}
