import { ApiError } from '@schooly/api';
import { AttendanceEntryForRelation } from '@schooly/api';
import { AttendanceEntriesForRelationResponse } from '@schooly/api';
import { StringOrNull, TitleOrNull } from '@schooly/api';
import { SchoolYear } from '@schooly/api';
import { createContext } from 'react';

export interface AttendanceForRelationContextProps {
  relationId?: string;
  schoolYear?: SchoolYear;
  setSchoolYear: (year: SchoolYear) => void;
  counts: AttendanceEntriesForRelationResponse['counts'];
  entries: AttendanceEntryForRelation[];
  fetching: boolean;
  isFetchingNextPage: boolean;
  error?: ApiError | null;
  canEdit: boolean;
  pendingStatusEntries: Record<string, string | undefined>;
  pendingCommentEntries: Record<
    string,
    | {
        text: StringOrNull;
        creator_title?: TitleOrNull;
        creator_given_name?: StringOrNull;
        creator_last_name?: StringOrNull;
        creator_known_as?: StringOrNull;
      }
    | undefined
  >;
  setEntryStatus: (groupId: string, codeId: string, registerId: string, key: string) => void;
  setEntryComment: ({
    groupId,
    codeId,
    registerId,
    comment,
  }: {
    groupId: string;
    codeId: string;
    registerId: string;
    comment: string;
  }) => void;
  canShowMore: boolean;
  showMore: () => void;
  generateEntryKey: (entry: AttendanceEntryForRelation, index: number) => string;
}

export const AttendanceForRelationContext = createContext<AttendanceForRelationContextProps>({
  relationId: undefined,
  schoolYear: undefined,
  setSchoolYear: () => {},
  counts: [],
  entries: [],
  fetching: false,
  isFetchingNextPage: false,
  error: undefined,
  canEdit: false,
  pendingStatusEntries: {},
  pendingCommentEntries: {},
  setEntryStatus: () => {},
  setEntryComment: () => {},
  canShowMore: false,
  showMore: () => {},
  generateEntryKey: () => '',
});

AttendanceForRelationContext.displayName = 'AttendanceForRelationContext';
