import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface UserNameWrapperProps {
  hasEllipsis: boolean;
  isSmall?: boolean;
  tooltipType: string;
}

interface UserNameProps {
  userNameIsUnderlinedLink: boolean;
  component: string;
  isUsernameClickable?: boolean;
}

export const UserNameWrapper = styled(Box)<UserNameWrapperProps>(
  ({ theme, hasEllipsis, isSmall, tooltipType }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flex: '1 1 100%',
    height: tooltipType === 'horizontal' ? '30px' : '40px',
    ...(hasEllipsis && {
      fontSize: isSmall ? theme.typography.body1.fontSize : theme.typography.h3.fontSize,
      color: theme.palette.text.primary,
    }),
  }),
);

export const UserName = styled(Typography)<UserNameProps>(
  ({ theme, userNameIsUnderlinedLink, isUsernameClickable }) => ({
    color: theme.palette.text.primary,
    ':hover': {
      textDecoration: userNameIsUnderlinedLink ? 'underline' : undefined,
      color: !!isUsernameClickable ? theme.palette.primary.main : undefined,
    },
  }),
);
