import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import {
  ConsentFormWithVersions,
  DATE_FORMAT_SHORT_FNS,
  SchoolYear,
  SHORT_FORMATTED_DATE_FORMAT_FNS,
  useGetConsentFormsHistoryQuery,
} from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import {
  CrossIcon,
  DropdownYears,
  EmptyListSvg,
  GridContainer,
  GridRowCell,
  GridRowItem,
  GridRowName,
  Loading,
  ModalContent,
  ModalHeaderStyled,
  ModalLarge,
  ModalMain,
  ModalSearch,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  ConsentFormSignedInfo,
  ConsentItemsSkeletonRows,
} from '../../components/common/ConsentForms/ConsentFormPreview';
import {
  getConsentFormItemStatusColor,
  getConsentFormItemStatusLabelId,
} from '../../components/common/ConsentForms/helpers';
import { getRouteModalPathname } from '../../helpers/misc';
import { useReferenceInfo } from './hooks';

const PAGE_SIZE = 30;

type ConsentFormsHistoryPreviewModalProps = {
  relationId: string;
  onClose: () => void;
  headerContent: ReactNode;
  schoolYears: SchoolYear[];
  initialSchoolYear: SchoolYear;
};

export const ConsentFormsHistoryPreviewModal: FC<ConsentFormsHistoryPreviewModalProps> = ({
  relationId,
  onClose,
  headerContent,
  schoolYears,
  initialSchoolYear,
}) => {
  const { $t } = useIntl();
  const { data, isLoading, setParams, hasNextPage, params, fetchNextPage, isFetchingNextPage } =
    useGetConsentFormsHistoryQuery(
      {
        relation_id: relationId,
        year_id: initialSchoolYear.id,
        search_query: '',
        page_size: PAGE_SIZE,
      },
      { refetchOnMount: 'always' },
    );

  const entries = useMemo(
    () =>
      data?.pages.reduce<ConsentFormWithVersions[]>(
        (prev, curr) => [...prev, ...curr.results],
        [],
      ) ?? [],

    [data?.pages],
  );

  const entriesByMonth = useMemo(() => {
    return entries?.reduce<Record<string, ConsentFormWithVersions[]>>((acc, { items, ...rest }) => {
      const itemsWithStatus = items.filter((i) => i.status !== 'no_status');

      if (!itemsWithStatus.length) return acc;

      const entry = {
        ...rest,
        items: itemsWithStatus,
      };

      const month = format(newDateTimezoneOffset(entry.publish_date), 'MMM');

      return acc[month]
        ? { ...acc, [month]: [...acc[month], entry] }
        : { ...acc, [month]: [entry] };
    }, {});
  }, [entries]);

  const handleQueryChange = useCallback(
    (search_query: string) => {
      setParams((p) => ({ ...p, search_query }));
    },
    [setParams],
  );

  const selectedYear = useMemo(
    () => schoolYears.find((y) => y.id === params.year_id),
    [params.year_id, schoolYears],
  );

  const handleYearChange = useCallback(
    (schoolYear: SchoolYear) => {
      setParams((p) => ({ ...p, year_id: schoolYear.id }));
    },
    [setParams],
  );

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);
  const total = data?.pages[0].count ?? 0;

  const months = Object.keys(entriesByMonth);

  return (
    <ModalLarge
      open
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <ModalHeaderStyled
        active
        sx={{
          py: 0.5,
          px: 2.5,
          alignItems: 'center',
        }}
      >
        {headerContent}
        <Stack direction="row" gap={2.5} alignItems="center" color="common.grey">
          <ModalSearch
            value={params.search_query ?? ''}
            onChange_MemoizedCallbackOnly={handleQueryChange}
            placeholder={$t({ id: 'people-Search' })}
            withDebounce
            iconProps={{ sx: { color: 'common.grey' } }}
          />

          <DropdownYears
            years={schoolYears}
            defaultYear={initialSchoolYear}
            currentYear={selectedYear}
            onYearChange={handleYearChange}
            color="common.grey"
          />
          <IconButton inverse onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </Stack>
      </ModalHeaderStyled>

      <ModalMain>
        <ModalContent active sx={{ pt: 0 }}>
          {months.map((month) => {
            const consentForms = entriesByMonth[month];
            return (
              <Stack key={month}>
                <Typography variant="h4" pt={3} pb={1}>
                  {month}
                </Typography>

                {consentForms.map((f) => (
                  <ConsentFormWithVersionsPreview key={f.id} consentForm={f} />
                ))}
              </Stack>
            );
          })}

          {!months.length && !isLoading && (
            <Stack alignItems="center" justifyContent="center" width="100%" height="100%" gap={2}>
              <EmptyListSvg />
              <Typography textAlign="center" color="text.primary">
                {$t({ id: 'consentForms-emptyConsentFormHistory' })}
              </Typography>
            </Stack>
          )}

          {isLoading && <Loading />}
          {isFetchingNextPage && (
            <>
              <div ref={loaderRef} />
              <Box pt={2}>
                <ConsentItemsSkeletonRows
                  widths={['43%', '20%', '15%', '10%']}
                  rowCount={Math.min(
                    PAGE_SIZE,
                    total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                  )}
                  sx={{
                    gap: 4,
                  }}
                />
              </Box>
            </>
          )}
        </ModalContent>
      </ModalMain>
    </ModalLarge>
  );
};

type ConsentFormWithVersionsPreviewProps = {
  consentForm: ConsentFormWithVersions;
};

export const ConsentFormWithVersionsPreview: FC<ConsentFormWithVersionsPreviewProps> = ({
  consentForm: { items, reference, publish_date },
}) => {
  const { title, path, icon } = useReferenceInfo(reference);
  const { $t } = useIntl();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={(theme) => ({
        position: 'relative',

        ':hover': {
          backgroundColor: 'common.lightBg',
          '.historyScale,.historyScale:after': {
            backgroundColor: 'common.grey2',
          },
          '.publishDate': {
            color: 'common.grey2',
          },
          '*:not(.status, .publishDate)': {
            color: 'primary.main',
          },
        },
        borderBottom: `1px solid ${theme.palette.common.light2}`,
      })}
    >
      <Stack direction="row" alignItems="center" ml={3} gap={3.25} flex="0 0 432px">
        <Typography variant="h4" color="common.grey" className="publishDate">
          {format(newDateTimezoneOffset(publish_date), DATE_FORMAT_SHORT_FNS)}
        </Typography>
        <Stack
          component={Link}
          to={path}
          direction="row"
          onClick={(e) => e.stopPropagation()}
          color="common.grey2"
          alignItems="center"
          sx={{
            gap: 0.5,
            ':hover': {
              '*': {
                textDecoration: 'underline',
              },
            },
          }}
        >
          <Icon fontSize="small">{icon}</Icon>
          <TypographyWithOverflowHint variant="h3" color="common.grey2" minWidth={200}>
            {title}
          </TypographyWithOverflowHint>
        </Stack>
      </Stack>

      <GridContainer>
        {items.map((item, idx) => {
          const statusColor = getConsentFormItemStatusColor(item.status);

          return (
            <GridRowItem key={item.id} sx={{ gap: 2, py: 1.25, px: 1 }}>
              <Box
                className={idx !== 0 ? 'historyScale' : undefined}
                sx={{
                  height: 8,
                  width: 8,
                  left: 6,
                  position: 'absolute',
                  backgroundColor: idx === 0 ? 'primary.main' : 'common.grey',
                  borderRadius: '50%',
                  ...(idx !== 0 && {
                    '&:after': {
                      content: "''",
                      position: 'absolute',
                      bottom: 8,
                      left: 3.5,
                      display: 'block',
                      width: '1px',
                      height: 30,
                      backgroundColor: 'common.grey',
                    },
                  }),
                }}
              />

              <GridRowName
                sx={{
                  flex: '0 0 200px',
                }}
              >
                {item.signed_by && (
                  <ConsentFormSignedInfo
                    userName={getUserFullName(item.signed_by)}
                    path={getRouteModalPathname('parent', item.signed_by)}
                    label={$t({
                      id: 'consentForms-signedBy',
                    })}
                  />
                )}
              </GridRowName>
              <GridRowName
                sx={{
                  flex: '0 0 152px',
                }}
              >
                {item.sign_date && (
                  <Typography color="text.primary">
                    {format(newDateTimezoneOffset(item.sign_date), SHORT_FORMATTED_DATE_FORMAT_FNS)}
                  </Typography>
                )}
              </GridRowName>
              <GridRowCell sx={{ flex: '0 0 120px' }}>
                <Typography className="status" color={statusColor}>
                  {$t({ id: getConsentFormItemStatusLabelId(item.status) })}
                </Typography>
              </GridRowCell>
            </GridRowItem>
          );
        })}
      </GridContainer>
    </Stack>
  );
};
