import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2796 8.8592L11.871 11.1937H13.4552V12.4025H11.6631L11.1183 15.5H9.89964L10.4444 12.4025H8.86738L8.32258 15.5H7.11111L7.65591 12.4025H6V11.1937H7.8638L8.2724 8.8592H6.53763V7.64286H8.48746L9.03943 4.5H10.2509L9.69892 7.64286H11.2832L11.8351 4.5H13.0466L12.4946 7.64286H14V8.8592H12.2796ZM11.0681 8.8592H9.49104L9.07527 11.1937H10.6595L11.0681 8.8592Z"
      fill="#24275B"
    />
  </svg>
);
