import { useEffect, useRef } from 'react';

export default function usePrevious<T extends any>(next: T, updateOnlyWhenTruthy?: boolean) {
  const ref = useRef<T>();

  useEffect(() => {
    if (!updateOnlyWhenTruthy || (next !== undefined && next !== null)) {
      ref.current = next;
    }
  }, [next, updateOnlyWhenTruthy]);

  return ref.current;
}
