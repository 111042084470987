import { Icon, Stack, StackProps, Typography } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { PROPERTIES_TEXT_IDS } from '@schooly/constants';
import { DoneIcon } from '@schooly/style';
import { useIntl } from 'react-intl';

type ArrangeBySelectRowProps<T> = {
  arrangeBy: T;
  isSelected?: boolean;
  getOptionLabelId?: (o: T) => string;
} & StackProps;
export const ArrangeBySelectRow = <T extends FilterKeys>({
  arrangeBy,
  isSelected,
  getOptionLabelId,
  ...stackProps
}: ArrangeBySelectRowProps<T>) => {
  const { $t } = useIntl();

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      px={1}
      gap={2}
      py={0.5}
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
      {...stackProps}
    >
      <Typography variant="h3" color={!isSelected ? 'common.grey2' : undefined}>
        {$t({
          id: getOptionLabelId ? getOptionLabelId(arrangeBy) : PROPERTIES_TEXT_IDS[arrangeBy],
        })}
      </Typography>
      <Icon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <DoneIcon />
      </Icon>
    </Stack>
  );
};
