import { Stack, Typography } from '@mui/material';
import { Message } from '@schooly/api';
import { DATE_FORMAT_SHORT } from '@schooly/constants';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

type MessagePreviewProps = {
  message: Message;
};

export const MessagePreview: FC<MessagePreviewProps> = ({ message }) => {
  const { $t } = useIntl();

  return (
    <Stack direction="row" alignItems="center" flex="0 0 auto" gap={2.5} mb={2}>
      <Typography color="text.primary">{format(message.created_at, DATE_FORMAT_SHORT)}</Typography>
      {message.creator && (
        <Typography color="text.primary">
          {$t({ id: 'messages-From' })}
          {': '}
          {getUserFullName(message.creator)}
        </Typography>
      )}
      {message.publisher && (
        <Typography color="text.primary">
          {$t({ id: 'messages-Publisher' })}
          {': '}
          {getUserFullName(message.publisher)}
        </Typography>
      )}
    </Stack>
  );
};
