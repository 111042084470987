import { Stack } from '@mui/material';
import {
  ControlRichText,
  ControlRichTextProps,
  DynamicVariables,
  HTML_TAGS_REGEXP,
} from '@schooly/components/form-rich-text';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { CreateConsentForm } from './ConsentForm';

export const FULL_STUDENT_NAME_VARIABLE = '{{full_student_name}}';
export const FULL_PARENT_NAME_VARIABLE = '{{full_parent_name}}';

const variableOptions = [
  {
    titleId: 'consentForms-dynamicVariables-StudentFullNameExample',
    value: ` ${FULL_STUDENT_NAME_VARIABLE} `,
  },
  {
    titleId: 'consentForms-dynamicVariables-ParentFullNameExample',
    value: ` ${FULL_PARENT_NAME_VARIABLE} `,
  },
];

export const ConsentFormEditor: FC<Omit<ControlRichTextProps<CreateConsentForm>, 'name'>> = ({
  RichTextProps,
  ...props
}) => {
  const { $t } = useIntl();

  return (
    <Stack width="100%">
      <ControlRichText
        name="description"
        BoxProps={{
          sx: { overflow: 'hidden', '& .ql-editor': { minHeight: 120 } },
        }}
        rules={{
          required: true,
          validate: (v) => {
            const text = v ? v.replaceAll(HTML_TAGS_REGEXP, '') : '';
            return !text ? $t({ id: 'input-ErrorRequired' }) : true;
          },
        }}
        RichTextProps={{
          toolbarId: 't2',
          placeholder: $t({ id: 'consentForms-formDescription' }),
          withoutAttachments: true,
          toolbarItems: <DynamicVariables options={variableOptions} />,
          ...RichTextProps,
        }}
        {...props}
      />
    </Stack>
  );
};
