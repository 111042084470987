import { CreateChildForm, CreateParentForm } from '@schooly/components/applications';
import { CreateCustomField } from '@schooly/components/applications';
import { useNotifications } from '@schooly/components/notifications';
import { ModalLarge } from '@schooly/style';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useApplication } from '../../../context/applications/useApplication';
import { useRouter } from '../../../context/router/useRouter';
import { useApplicationForm } from '../useApplicationForm';
import { ApplicationEditablePreviewContainer } from './ApplicationEditablePreviewContainer';

export const ApplicationCreatePreviewModal: FC = () => {
  const {
    addChild,
    addParents,
    addGeneralCustomFields,
    parents,
    children,
    generalCustomFields,
    resetForm,
  } = useApplicationForm('create');
  const { createApplication, fetching, isApplicationManager } = useApplication();

  const { showNotification } = useNotifications();
  const { $t } = useIntl();

  const { goBack } = useRouter();
  const navigate = useNavigate();

  const handleConvert = useCallback(async () => {
    const applicationId = await createApplication({ parents, children, generalCustomFields });

    if (applicationId) {
      navigate(`/applications/${applicationId}/convert`, {
        replace: true,
      });
    }

    resetForm();
  }, [children, createApplication, navigate, parents, resetForm, generalCustomFields]);

  const handleSave = useCallback(async () => {
    const applicationId = await createApplication({ parents, children, generalCustomFields });

    navigate(`/applications`);

    if (applicationId) {
      showNotification({
        message: $t({ id: 'applications-SuccessfulCreate' }),
        type: 'success',
        actions: [
          {
            textId: 'applications-Action-View',
            handler: () =>
              navigate(`/applications/${applicationId}`, {
                replace: true,
              }),
            buttonColor: 'light',
          },
        ],
      });
    }

    resetForm();
  }, [
    $t,
    children,
    createApplication,
    navigate,
    parents,
    resetForm,
    showNotification,
    generalCustomFields,
  ]);

  const onChildSubmit = useCallback(
    (data: CreateChildForm) => {
      addChild(data);
    },
    [addChild],
  );

  const onParentSubmit = useCallback(
    (data: CreateParentForm) => {
      addParents([data]);
    },
    [addParents],
  );

  const onGeneralCustomFieldsSubmit = useCallback(
    (data: CreateCustomField[]) => {
      addGeneralCustomFields(data);
    },
    [addGeneralCustomFields],
  );

  return (
    <ModalLarge open>
      <ApplicationEditablePreviewContainer
        onChildSubmit={onChildSubmit}
        onParentSubmit={onParentSubmit}
        onGeneralCustomFieldsSubmit={onGeneralCustomFieldsSubmit}
        onClose={goBack}
        onSave={handleSave}
        onConvert={handleConvert}
        pending={fetching}
        formData={{ children, parents, generalCustomFields }}
        onGoBack={goBack}
        canEdit={isApplicationManager}
        withSaveButton
      />
    </ModalLarge>
  );
};
